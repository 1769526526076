import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/core/services/session.service';

@Component({
  selector: 'app-dialog-cerrar-session',
  templateUrl: './dialog-cerrar-session.component.html',
  styleUrls: ['./dialog-cerrar-session.component.css']
})
export class DialogCerrarSessionComponent implements OnInit {

  valor;

  constructor(private sessionService: SessionService,
     public dialogRef: MatDialogRef<DialogCerrarSessionComponent>,@Inject(MAT_DIALOG_DATA) public data: string, private router: Router) { }

  ngOnInit() {
  }

  confirmarCerrarSesion(resultado: boolean): void {
    if(resultado === true){
    this.dialogRef.close();
    if(this.data === '/flight/flight-booking' || this.data === '/seguros/insurance-booking' ||this.data === '/pack/operation' || this.data === '/hotel/hotel-booking'){
      this.router.navigate(['/']);
    }
    this.sessionService.setIniciarSesion(true);
    this.sessionService.setPartnerClub(false);
    this.sessionService.setEmailFinish(null);
    this.sessionService.setPromotionalCode(false);
  }

    this.dialogRef.close(resultado);
  }

  onClose(){
    this.dialogRef.close();
  }

}
