import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule, MatButtonModule, MatButtonToggleModule, MatCheckboxModule, MatDatepickerModule, MatDialogModule, MatDividerModule, MatExpansionModule, MatFormFieldModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatTabsModule, MatTooltipModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatChipsModule} from '@angular/material/chips';
import {MatCardModule} from '@angular/material/card';

@NgModule({
  imports: [
    MatDividerModule,
    MatChipsModule,
    MatCardModule,
  MatMenuModule,
  MatTabsModule,
  MatSliderModule,
  MatFormFieldModule,
  MatExpansionModule,
  MatSelectModule,
  MatInputModule,
  MatIconModule,
  MatPaginatorModule,
  MatAutocompleteModule,
    MatProgressBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    MatSnackBarModule,
    MatListModule,
    MatRadioModule,
    MatDatepickerModule,
  MatNativeDateModule,
  MatRippleModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    MatDividerModule,
    MatChipsModule,
    MatCardModule,
  MatMenuModule,
  MatTabsModule,
  MatSliderModule,
  MatFormFieldModule,
  MatExpansionModule,
  MatSelectModule,
  MatInputModule,
  MatIconModule,
  MatPaginatorModule,
  MatAutocompleteModule,
    MatProgressBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    MatSnackBarModule,
    MatListModule,
    MatRadioModule,
    MatDatepickerModule,
  MatNativeDateModule,
  MatRippleModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    FormsModule
  ],
})
export class MaterialModule { }
