import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { PackageService } from 'src/app/core/services/package.service';
import { SessionService } from 'src/app/core/services/session.service';
import { ActivatedRoute,Router } from '@angular/router';
import { AES, enc } from 'crypto-js';
import {  MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-coupon-confirm-phone',
  templateUrl: './coupon-confirm-phone.component.html',
  styleUrls: ['./coupon-confirm-phone.component.css'],
  providers: [PackageService]
})
export class CouponConfirmPhoneComponent implements OnInit {
  countries;
  correoExist;
  pais;
  numero;
  nombre ="+51";
  objetoDesencriptado;
  constructor(private snackBar: MatSnackBar,private route: ActivatedRoute,private sessionService: SessionService,private router: Router,private packageService: PackageService,private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.getCountryCodes();
    //console.log(this.data);
    this.ver();
  }

  ver() {
    this.route.queryParams.subscribe(params => {
      const serializedData = params['data'];
      if (serializedData) {
        let objetoDesencriptado = null;
        const bytes = AES.decrypt(serializedData, "domiruth");
        this.objetoDesencriptado = JSON.parse(bytes.toString(enc.Utf8));

      }
    });
  }



  getCountryCodes() {
    
    this.packageService.getCountryCodes().subscribe(
      x => {
        if (x.confirmation && x.data) {
          this.countries = x.data.filter(x => x.id !== null && x.id !== '');
        }
      },
      err => {

      },
      () => {
     
      }
    );
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 5000,
    });
  }
 

  sendSms(valor){
    this.spinner.show();
    this.packageService.registerWithPhone(this.objetoDesencriptado.odata.code,valor).subscribe(
      x=>{
        //console.log(x);
        if(x.status === 200){
          const encryptionKey = 'domiruth';
          const objetoEncriptado = AES.encrypt(JSON.stringify(x), encryptionKey).toString();
          this.router.navigate(['auth/coupon-code-login'], { queryParams: { data: objetoEncriptado } });
        } else {
          this.openSnackBar(x.message);
        }
        this.spinner.hide();
      }
    )
    
  }

  codeConfirm(){
    //console.log(this.numero);
    let pa = this.nombre;
    const asd = pa.slice(1);
    let valor = asd + this.numero;
    this.sessionService.setPhone(valor);
    //console.log(this.data);
    this.sendSms(valor);
    
  }

}
