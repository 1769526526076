import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'dmSplitPipe'})
export class SplitPipe implements PipeTransform {
  transform(value: string, param: string): string[] {
    if (value) {
        const array = value.split(param);
        return array.filter(x => x !== '');
      } else {
        return [];
      }
  }
}
