import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/core/services/session.service';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.css']
})

export class FinishComponent implements OnInit {

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    this.onClose();
  }

  constructor(private sessionService: SessionService,private router: Router,
     public dialogRef: MatDialogRef<FinishComponent>,@Inject(MAT_DIALOG_DATA) public data: string) { dialogRef.disableClose = true; }

  ngOnInit() {
    this.sessionService.setIniciarSesion(false);
  }

  onClose() {
    this.dialogRef.close();
    this.sessionService.setReload(true);
    let url;
    if(this.data === 'VH'){
      url = '/flight-hotel/hotel-flight-booking'
    } else if (this.data === 'V'){
      url = '/flight/flight-booking'
    } else if (this.data === 'S') {
      url = '/seguros/insurance-booking'
    } else if (this.data === 'P'){
      url = '/pack/operation'
    } else if (this.data === 'H'){
      url = '/hotel/hotel-booking'
    } else {
      url = window.location.pathname;
    }
    let val = window.location.origin + url;
    window.location.replace(val);
    /* window.location.reload(); */
  }

}
