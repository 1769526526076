import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  HostListener,
  ElementRef,
} from "@angular/core";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { FilterSearch } from "../hotel-filter-search/shared/filter-search.model";
import {
  MatDatepickerInputEvent,
  MatDatepicker,
} from "@angular/material/datepicker";
import { SessionService } from "src/app/core/services/session.service";
import { FlightService } from "src/app/core/services/flight.service";
import { RoomsCounter } from "src/app/shared/controls/dm-rooms-counter/shared/rooms-counter.model";

import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";

import * as _moment from "moment";
import { MatSnackBar } from "@angular/material";
import { NgxSpinnerService } from "ngx-spinner";
import { airportsData } from "src/app/shared/services/airport.const";

const moment = _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "L",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  // tslint:disable-next-line: component-selector
  selector: "dm-hotel-filter-search",
  templateUrl: "./hotel-filter-search.component.html",
  styleUrls: ["./hotel-filter-search.component.css"],
  providers: [
    FlightService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class HotelFilterSearchComponent implements OnInit, AfterViewInit {
  @Input() data: FilterSearch;
  @Input() expandable: boolean;
  @Input() theme: string;
  @Output() expand = new EventEmitter<boolean>();
  @Output() search = new EventEmitter<FilterSearch>();

  @ViewChild("dpReturnDate", { read: undefined, static: false })
  returnDatePicker: MatDatepicker<Date>;
texvh = "vuhii"
  form: FormGroup;
  minDepartureDate = new Date();
  minCalendar = new Date();
  minReturnDate = new Date();
  fromList = [];
  toList = [];
  validFechas = false
  validOrigen = false;
  validDestino = false;
  origins = [];
  roomModel: RoomsCounter[] = [];
  nights = "";
  roomsData: RoomsCounter[];
  passengers = 0;
  rooms = 0;
  textImg = "newSearch";
  mostrarAutoCompleteOri = false;
  mostrarAutoCompleteDes = false;
  show;
  otherDiv: any;
  originAnterior = null;
  destAnterior = null;
  mostrarAutoComplete = false;
  filtroCollapse= true;
  isPhone = false;
  dataAirport: any;
  public myObject!: { id: number, myObject: { myString: string } };
  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private sessionService: SessionService,
    private flightService: FlightService
  ) { 
    this.dataAirport = airportsData;
  }

  ngOnInit() {
    this.validPhone();
    this.minCalendar.setDate(this.minCalendar.getDate() + 1);
    this.getAirports();
    this.initForm();

  }

  showFilter(valor){
    if(!this.isPhone){
      return;
    }
    if(valor === 1){
      this.originAnterior = this.form.controls.from.value; 
      this.mostrarAutoCompleteOri = true;
    } else {
      this.destAnterior = this.form.controls.to.value; 
      this.mostrarAutoCompleteDes = true;
    }
    
  }

  ocultarAutocomplete(valor){
    if(valor === 1){
      if(this.form.controls.from.value === null){
        this.form.controls.from.setValue(this.originAnterior); 
      }
      
      this.mostrarAutoCompleteOri = false;
    } else {
      if(this.form.controls.to.value === null){
        this.form.controls.to.setValue(this.destAnterior); 
      }
      
      this.mostrarAutoCompleteDes = false;
    }
  }

  validPhone(){
    if( navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)){
     
      this.isPhone = true;
      this.otherDiv = true;
      this.filtroCollapse = false;
      this.textImg = "buscarPhone";
    } else {
      this.expandable = true;
      this.filtroCollapse = true;
    }
  }

  ocultarHabi(valor){
   
    this.mostrarAutoComplete = false;
    this.roomsData = valor;
    this.otherDiv = true;
  }

  mostarHab(){
    if(this.isPhone){
      this.otherDiv = false;
      this.mostrarAutoComplete = true;
    }
  }

  filtroOcultarMostrar() {
    let filtroCollapse = this.filtroCollapse;
    if (filtroCollapse == true) {
      this.filtroCollapse = false;
    } else {
      this.filtroCollapse = true;
    }
  }

  ngAfterViewInit(): void {
    let filter = this.sessionService.getHotelFilter();
    if(filter != null && filter.from != null){
      if (filter.from.name === undefined || filter.from.name === null) {
        let from = this.fillOrigen(filter.from);
        let to = this.fillOrigen(filter.to);
        filter.from = from;
        filter.to = to;
        this.form.controls.from.setValue(from);
        this.form.controls.to.setValue(to);
        this.sessionService.setHotelFilter(filter);
      }
    }
    
  }

  fillOrigen(valor) {
    let origin = null;
    let origins = this.origins;
      let resultado = origins.filter(item => item.code === valor);
      origin = resultado[0];
      return origin;
    
  }



  dividirCadena(cadenaADividir, separador) {
    var arrayDeCadenas = cadenaADividir.split(separador);
    this.show = arrayDeCadenas[3];
    for (var i = 0; i < arrayDeCadenas.length; i++) {
      /* //console.log(arrayDeCadenas[i]) */
    }
  }

  initForm() {
    let from;
    let to;
    let departureDate;
    let returnDate;

    if (this.data) {
      from = this.data.from;
      to = this.data.to;
      departureDate = this.data.departureDate;
      returnDate = this.data.returnDate;

      this.roomsData = this.data.rooms;

      this.updateSummary(this.roomsData);
    }

    this.form = this.fb.group({
      from: new FormControl(from),
      to: new FormControl(to),
      departureDate: new FormControl(departureDate),
      returnDate: new FormControl(returnDate),
    });
  }

 

  updateSummary(rooms) {
    this.roomsData = rooms;
    let passengers = 0;
    rooms.forEach((room) => {
      passengers += room.adults + room.children;
    });
    this.passengers = passengers;
    this.rooms = rooms.length;
  }

  getPriorityAirports() {
    this.flightService.getPriorityAirports().subscribe(
      (result) => {
        if (result) {
          const airports = result.lairports ? result.lairports : [];
          const cities = result.lcities ? result.lcities : [];
          airports.forEach((airport) => {
            this.origins.push({
              code: airport.iataCode,
              countryCode: airport.countryCode,
              name: airport.name,
              searchName: airport.searchName,
              priority: airport.priority,
              /* icon: 'local_airport' */
              icon: "A",
            });
          });
          cities.forEach((city) => {
            this.origins.push({
              code: city.iataCode,
              countryCode: city.countryCode,
              name: city.name,
              searchName: city.searchName,
              priority: city.priority,
              /* icon: 'location_city' */
              icon: "C",
            });
          });
          this.origins.sort((a, b) => b.priority - a.priority);
        }
      },
      (err) => {
        /* //console.log('Error: ', err); */
      },
      () => {
        /* this.enviarLim(); */
        /* this.getAirports(); */
      }
    );
  }

  enviarLim() {
    const objeto = {
      code: "LIM",
      countryCode: "PE",
      icon: "A",
      name: "[LIM] - Jorge Chavez International - LIM - Lima - Peru",
      priority: 2,
      searchName: "LIM-Lima-Jorge Chavez International-J Chavez Intl-Peru-Perú-Peruu-Perù-Pê ru-Pérou"
    }
    this.form.controls.from.setValue(objeto);
  }

  getAirports() {
    const _origins = [];
    let result = this.dataAirport;
    if (result) {
      const airports = result.lairports ? result.lairports : [];
      const cities = result.lcities ? result.lcities : [];
      airports.forEach((airport) => {
        _origins.push({
          code: airport.iataCode,
          countryCode: airport.countryCode,
          name: airport.name,
          searchName: airport.searchName,
          cityName: airport.cityName,
          priority: airport.priority,
          /*  icon: 'local_airport' */
          icon: "A",
        });
      });
      cities.forEach((city) => {
        _origins.push({
          code: city.iataCode,
          countryCode: city.countryCode,
          name: city.name,
          cityName: city.cityName,
          searchName: city.searchName,
          priority: city.priority,
          /* icon: 'location_city' */
          icon: "C",
        });
      });
      _origins.sort((a, b) => b.priority - a.priority);
    }

    this.origins = _origins;
    /* let valor = this.constant.encriptar(this.origins);
    this.constant.addObject(0, valor); */
    
    // this.flightService.getAirports().subscribe(
    //   (result) => {
    //     if (result) {
    //       const airports = result.lairports ? result.lairports : [];
    //       const cities = result.lcities ? result.lcities : [];
    //       airports.forEach((airport) => {
    //         _origins.push({
    //           code: airport.iataCode,
    //           countryCode: airport.countryCode,
    //           name: airport.name,
    //           searchName: airport.searchName,
    //           cityName: airport.cityName,
    //           priority: airport.priority,
    //           /*  icon: 'local_airport' */
    //           icon: "A",
    //         });
    //       });
    //       cities.forEach((city) => {
    //         _origins.push({
    //           code: city.iataCode,
    //           countryCode: city.countryCode,
    //           name: city.name,
    //           cityName: city.cityName,
    //           searchName: city.searchName,
    //           priority: city.priority,
    //           /* icon: 'location_city' */
    //           icon: "C",
    //         });
    //       });
    //       _origins.sort((a, b) => b.priority - a.priority);
    //     }
    //   },
    //   (err) => {
    //     /* //console.log('Error: ', err); */
    //   },
    //   () => {
    //     this.origins = _origins;
    //   }
    // );
  }

  letPeru(list) {
    let lsita = [];
    list.forEach(element => {
      if (element.countryCode === "PE") {
        lsita.push(element);
      }
    });
    return lsita;
  }

  onOriginChange(val: string) {
    if (this.origins.length > 255) {
      const lstPeru = this.letPeru(this.origins);
      if (val.length >= 3 && this.origins) {
        this.fromList = lstPeru.filter(
          (item) => item.searchName.toLowerCase().search(val.toLowerCase()) >= 0
        );
      } else {
        this.fromList = [];
      }
    } else {

        const lstPeru = this.letPeru(this.origins);
        if (val.length >= 3 && this.origins) {
          this.fromList = lstPeru.filter(
            (item) => item.searchName.toLowerCase().search(val.toLowerCase()) >= 0
          );
        } else {
          this.fromList = [];
        }



    }


    this.validOrigen = false;

  }

  onDestinationChange(val: string) {
    if (this.origins.length > 255) {
      if (val.length >= 3 && this.origins) {
        this.toList = this.origins.filter(
          (item) => item.searchName.toLowerCase().search(val.toLowerCase()) >= 0
        );
      } else {
        this.toList = [];
      }
    } else {
  
        if (val.length >= 3 && this.origins) {
          this.toList = this.origins.filter(
            (item) => item.searchName.toLowerCase().search(val.toLowerCase()) >= 0
          );
        } else {
          this.toList = [];
        }
    }
    this.validDestino = false;

  }

  onDepartureDateChange(event: MatDatepickerInputEvent<Date>) {
    if (this.form.controls.returnDate.value) {
      if (
        new Date(this.form.controls.departureDate.value) >=
        new Date(this.form.controls.returnDate.value)
      ) {
        this.form.controls.returnDate.setValue("");
      }
    }
    // this.minReturnDate = this.form.controls.departureDate.value;
    const result = new Date(this.form.controls.departureDate.value);
    result.setDate(result.getDate() + 1);
    this.minReturnDate = result;

    if (!this.form.controls.returnDate.value) {
      this.returnDatePicker.open();
    }
    this.validFechas = false;
  }

  onReturnDateChange(event: MatDatepickerInputEvent<Date>) {
    const days =
      new Date(this.form.controls.returnDate.value).getTime() -
      new Date(this.form.controls.departureDate.value).getTime();
    /*  //console.log(days); */
    /*  //console.log(Math.round(days / (1000 * 60 * 60 * 24))); */
    this.nights = Math.round(days / (1000 * 60 * 60 * 24)).toString();
  }

  onSelectRoom(rooms: RoomsCounter[]) {
    this.roomModel = rooms;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  cambiarsitio(){
    let to = this.form.controls.to.value;
    let from = this.form.controls.from.value;
    
    if(to != null && to != '' && from != null && from != ''){
      this.form.controls.to.setValue(from);
      this.form.controls.from.setValue(to);
    }
  }

  onSearch() {
    let valore = true;
    if(!this.form.controls.returnDate.value || !this.form.controls.departureDate.value){
      this.validFechas = true;
      valore = false;
    }
    if(!this.form.controls.from.value){
      this.validOrigen = true;
      valore = false;
    } else if(!this.form.controls.from.value.name){
      this.validOrigen = true;
      valore = false;
    }
    if(!this.form.controls.to.value){
      this.validDestino = true;
      valore = false;
    } else if(!this.form.controls.to.value.name){
      this.validDestino = true;
      valore = false;
    }

  

    if(valore){
      if (this.theme === "dark" || (this.theme === "normal" && this.expandable)) {
        const filter: FilterSearch = {
          from: this.form.controls.from.value,
          to: this.form.controls.to.value,
          departureDate: this.form.controls.departureDate.value,
          returnDate: this.form.controls.returnDate.value,
          rooms: this.roomModel,
          lBoard: [],
          HotelStars: "",
          lZone: [],
          lTripAdvisor: [],
          lSupplier: [],
          lRating: [],
          firstItem: "1",
        };
       
       /*  if (this.form.controls.from.value === '' || this.form.controls.to.value === '' || this.form.controls.departureDate.value === '' 
        || this.form.controls.returnDate.value === ''
          || this.form.controls.from.value === null || this.form.controls.to.value === null || this.form.controls.departureDate.value === null 
          || this.form.controls.returnDate.value === null) {
          this.openSnackBar("Por favor rellenar todos los campos.", "Ok");
          return;
        } */
  
        //console.log("onSearch");
        //console.log("filter: " + JSON.stringify(filter));
        
        this.data = filter;
        this.updateSummary(this.roomModel);
        var guion = "-";
        this.dividirCadena(this.data.to.name, guion);
        this.sessionService.setHotelFlightRecommendations([]);
        this.sessionService.setContador(1);
        this.sessionService.setFlightTransaction(null);
        this.sessionService.setHotelFlightChange(null);
        this.sessionService.setFlightHotel(null);
        this.sessionService.setHotelOFilter(null);
        this.sessionService.setValidationRoom(null);
        this.sessionService.setFlightBooking(null);
        this.sessionService.setHotelFlightDyn(false);
        this.search.emit(filter);
      }
    }


    
  }

  

  onExpand() {
    this.expandable = !this.expandable;
    this.expand.emit(this.expandable);
  }
}
