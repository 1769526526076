import { Component, OnInit, HostListener, Input, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { DmDialogMapComponent } from '../dm-dialog-map/dm-dialog-map.component';
import { MatDialog } from '@angular/material';
import { SessionService } from 'src/app/core/services/session.service';
import { Router } from '@angular/router';
import { OnlyHotelService } from 'src/app/core/services/only-hotel.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { airportsData } from '../../services/airport.const';

@Component({
  selector: 'app-new-destinations-h-carousel',
  templateUrl: './new-destinations-h-carousel.component.html',
  styleUrls: ['./new-destinations-h-carousel.component.css'],
  providers: [OnlyHotelService]
})

export class NewDestinationsHCarouselComponent implements OnInit, AfterViewInit {

  @Input() listadoDinamico: any[];
  @Input() date;
  @Input() dest;
  @Input() showComponent;
  isAccordionOpen: boolean = false;
  isAccordionOpenName: boolean = false;
  elementSize: number = 400;
  elementMargin: number = 20;
  elementlefth: number = 20;
  origins = [];
  lstStars: any[] = [];
  validHotel = false;
  public carouselWidth: number;
  public elementWidth: number;
  public currentIndex: number = 0;
  public pages: number = 0;
  public progressSize: number = 4;
  public counterLeft: number = 0;
  public counterRight: number = 0;
  public progressMargin: number = 0;
  public movementsToRight: number = 0;
  public movementsToLeft: number = 0;
  loadingPackages = true;
  public currentItemsCarousel: number = 0;
  public scrHeight: any;
  public scrWidth: any;
  public myObject!: { id: number, myObject: { myString: string } };
  defaultTouch = { x: 0, y: 0, time: 0 };
  elements: any[];
  starcount = 5;
  numberc: number = 0;
  public ratingArr = [];
  dataAirport: any;
  /**carousel */
  constructor(private elementRef: ElementRef, private spinner: NgxSpinnerService, private hotelService: OnlyHotelService, private router: Router, private sessionService: SessionService, private dialog: MatDialog) {
    this.dataAirport = airportsData;
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
      this.elementSize = 318;
    }
    this.getScreenSize();
  }

  ngOnInit() {
    // this.formatName();
    // this.formatAddres();
    this.getAirports();
    if(!this.showComponent){
    for (let index = 0; index < this.date.length; index++) {
      const element = this.date[index];
        this.listadoDinamico = element.hotels;
        this.elements = this.listadoDinamico
        this.calculateDimensions();
        this.formatName();
        this.formatAddres();
      
    }

    for (let index = 0; index < this.starcount; index++) {
      this.ratingArr.push(index);
    }
  }else{
    this.formatName();
    this.formatAddres();

    /* this.llenarStars(); */
    this.elements = this.listadoDinamico;
    this.calculateDimensions();
    /* this.llenarNoStars(); */
    for (let index = 0; index < this.starcount; index++) {
      this.ratingArr.push(index);
    }
  }
    
  }

  getAirports() {
    const _origins = [];
    let result = this.dataAirport;
    if (result) {
      const airports = result.lairports ? result.lairports : [];
      const cities = result.lcities ? result.lcities : [];
      airports.forEach((airport) => {
        _origins.push({
          code: airport.iataCode,
          countryCode: airport.countryCode,
          name: airport.name,
          searchName: airport.searchName,
          priority: airport.priority,
          cityName: airport.cityName,
          icon: "A",
        });
      });
      cities.forEach((city) => {
        _origins.push({
          code: city.iataCode,
          countryCode: city.countryCode,
          name: city.name,
          searchName: city.searchName,
          priority: city.priority,
          cityName: city.cityName,
          icon: "C",
        });
      });
    }

    this.origins = _origins;

  }

  formatName() {
    this.listadoDinamico.forEach(element => {
      if (element.ohotelInfo.name.length > 30) {
        element.ohotelInfo.nameBlock = element.ohotelInfo.name.substring(0, 30) + "...";
        element.ohotelInfo.nameShow = element.ohotelInfo.name.substring(0, 30) + "...";
      } else {
        element.ohotelInfo.nameShow = element.ohotelInfo.name;
      }
    });
  }

  formatAddres() {
    this.listadoDinamico.forEach(element => {
      if (element.ohotelInfo.address.length > 37) {
        element.ohotelInfo.addressBlock = element.ohotelInfo.address.substring(0, 37) + "...";
        element.ohotelInfo.addresShow = element.ohotelInfo.address.substring(0, 37) + "...";
      } else {
        element.ohotelInfo.addresShow = element.ohotelInfo.address;
      }
    });
  }


  showName(item) {
    if (item.ohotelInfo.name.length > 30) {
      this.isAccordionOpenName = !this.isAccordionOpenName;
      if (this.isAccordionOpenName) {
        item.ohotelInfo.nameShow = item.ohotelInfo.name;
      } else {
        item.ohotelInfo.nameShow = item.ohotelInfo.nameBlock;
      }
    }

  }

  showAdd(item) {
    if (item.ohotelInfo.address.length > 37) {
      this.isAccordionOpen = !this.isAccordionOpen;
      if (this.isAccordionOpen) {
        item.ohotelInfo.addresShow = item.ohotelInfo.address;
      } else {
        item.ohotelInfo.addresShow = item.ohotelInfo.addressBlock;
      }
    }

  }

  ngAfterViewInit() {

  }

  showIcon(index: number, stars: string) {
    let star = parseInt(stars);
    if (star >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

  llenarStars() {
    for (let index = 0; index < this.listadoDinamico.length; index++) {
      const element = this.listadoDinamico[index];
      this.lstStars = [];
      for (let index = 0; index < parseFloat(element.stars); index++) {
        this.lstStars.push(true);
      }
      this.listadoDinamico[index].lstStar = this.lstStars;
    }
  }

  showMap(hotel) {
    const latitude = parseFloat(hotel.latitude) / 100000;
    const longitude = parseFloat(hotel.longitude) / 100000; 
    const dialogRef = this.dialog.open(DmDialogMapComponent, {
      data: {
        latitude: latitude.toString(),
        longitude: longitude.toString()
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }



  llenarNoStars() {
    for (let index = 0; index < this.listadoDinamico.length; index++) {
      const element = this.listadoDinamico[index];
      for (let index = element.lstStar.length; index < 5; index++) {
        element.lstStar.push(false);
      }
    }
  }

  /**carousel */
  @HostListener('window:resize')
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    let elementsView = this.getElementsView();
    if (this.currentItemsCarousel !== elementsView) {
      this.calculateDimensions();
    }
  }

  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  handleTouch(event) {
    let touch = event.touches[0] || event.changedTouches[0];
    if (event.type === 'touchstart') {
      this.defaultTouch.x = touch.pageX;
      this.defaultTouch.time = event.timeStamp;
    } else if (event.type === 'touchend') {
      let deltaX = touch.pageX - this.defaultTouch.x;
      let deltaTime = event.timeStamp - this.defaultTouch.time;
      if (deltaTime < 500) {
        if (Math.abs(deltaX) > 60) {
          if (deltaX > 0) {
            // this.prev();
          } else {
            // this.next();
          }
        }
      }
    }
  }

  fillOrigen(valor, valor1, valor2) {
    let origin;
    let origins;


    this.origins.forEach(element => {
      if (element.code === valor) {
        origin = element;
      }
    });
    valor1.to = origin;
    this.sessionService.setHotelFlightRecommendations([]);
    this.sessionService.setContador(1);
    this.sessionService.setFlightTransaction(null);
    this.sessionService.setIndiceImage(null);
    this.sessionService.setHotelFlightChange(null);
    this.sessionService.setFlightHotel(null);
    this.sessionService.setHotelOFilter(null);
    this.sessionService.setInfoHabi(null);
    this.sessionService.setValidRoom(null);
    this.sessionService.setOnlyHotel(null);
    this.sessionService.setFirstHabi(null);
    this.sessionService.setSelectRoom(null);
    this.sessionService.setValidationRoom(null);
    this.sessionService.setFlightBooking(null);
    this.sessionService.setHotelFilter(valor1);
    this.selectHotel(valor2, valor1);

  }

  goToHotel(valor, dato, dest) {
    let passengers = [];
    let pass = {
      adults: valor.numberPassengers,
      children: 0,
      ages: [],
      passengers: []
    }
    passengers.push(pass);
    let departure;
    let returnDates;
    let iata;
    if (!this.showComponent) {
      departure = dest.beginDate;
      returnDates = dest.endDate;
      iata = dato.iataCode;
    }
    let obj = {
      departureDate: departure,
      firstItem: "1",
      from: null,
      lBoard: [],
      lRating: [],
      lSupplier: [],
      lTripAdvisor: [],
      lZone: [],
      passengers: valor.numberPassengers,
      returnDate: returnDates,
      roomMod: passengers,
      rooms: 1,
      to: null
    }
    this.fillOrigen(iata, obj, valor)

  }

  goToHotel2(valor) {
    let passengers = [];
    let pass = {
      adults: valor.numberPassengers,
      children: 0,
      ages: [],
      passengers: []
    }
    passengers.push(pass);
    let departure;
    let returnDates;
    let iata;
      departure = valor.departureDate;
      returnDates = valor.arrivalDate;
      iata = valor.odestination.iataCode
    
    let obj = {
      departureDate: departure,
      firstItem: "1",
      from: null,
      lBoard: [],
      lRating: [],
      lSupplier: [],
      lTripAdvisor: [],
      lZone: [],
      passengers: valor.numberPassengers,
      returnDate: returnDates,
      roomMod: passengers,
      rooms: 1,
      to: null
    }
    this.fillOrigen(iata, obj, valor)

  }

  selectHotel(valor, valor1) {
    this.spinner.show();
    let lstGuest = [];
    let lstHotel = [];
    let guestRoom = {
      NumberPassengers: valor.numberPassengers,
      RoomQuantity: 1,
      TypePassenger: "ADT"
    }
    lstGuest.push(guestRoom);
    let departure;
    let returnDates;
    if (this.showComponent) {
      departure = valor.departureDate;
      returnDates = valor.arrivalDate;
    } else {
      departure = valor1.departureDate;
      returnDates = valor1.returnDate;
    }
    let hotel = {
      EndDate: returnDates.substring(0, 10),
      HotelCode: valor.ohotelInfo.code,
      LguestPerRoom: lstGuest,
      StartDate: departure.substring(0, 10)
    }
    lstHotel.push(hotel);
    let obj = {
      TypeSearch: "V",
      Pseudo: "LIMPE2235",
      Ocompany: null,
      Lusers: null,
      LHotel: lstHotel
    }
    this.sessionService.setHotelSelect(obj);
    let url = window.location.origin  + "/hotel/hotel-select";
    this.spinner.hide();
    this.openNewTab(url);
    /* this.hotelService.searchHotelListHabitacion2(obj).subscribe(
      x => {

        if (x.oerror === null && x.lroom!.length > 0) {
          
          x.validHome = true;
          this.sessionService.setInfoHabi(x);
          this.sessionService.setFirstHabi(x.lroom[0]);
    
        } else {
          this.spinner.hide();
          return;
        }
      }
    ) */
  }

  openNewTab(url: string) {
    window.open(url, '_blank');
  }

  getGuarantee(list: any[], valor) {
    let newList = [];
    list.forEach(element => {
      if (element.guaranteeType != null && element.guaranteeType.length > 0) {
        /* if(element.guaranteeType[0] === "GuaranteeRequired"){
          newList.push(element);
        } */
        element.guaranteeType.forEach(type => {
          if (type === "GuaranteeRequired") {
            newList.push(element);
          } else if (valor === "Value Hotel" && type === "Deposit") {
            newList.push(element);
          }
        });
      }

    });
    return newList;
  }

  formatdate(dato){
    // this.listadoDinamico = dato.lhotel;
    // this.elements = this.listadoDinamico;
    // this.calculateDimensions();
    // this.formatName();
    // this.formatAddres();
    return dato.formatDate;
  }

  calculateDimensions() {
    this.currentItemsCarousel = this.getElementsView();
    this.elementWidth = this.elementSize + 2 * this.elementMargin;
    this.carouselWidth = this.elementWidth * this.getElementsView();
    this.currentIndex = 0;
    if (this.elements !== null && this.elements !== undefined) {
      this.pages = this.elements.length - this.getElementsView() + 1;
      if (this.pages <= 0){
        this.pages = 1;
      }
      this.elements.map((i, index) => {
        i.index = index, i.marginLeft = this.elementMargin, i.marginRight = this.elementMargin, i.currentIndex = this.currentIndex, i.pages = this.pages,
        i.progressSize = this.progressSize, i.movementsToLeft = this.movementsToLeft, i.movementsToRight = this.movementsToRight, i.progressMargin = this.progressMargin
      });
      console.log("elementos hotel", this.elements)
    }

    this.progressMargin = 0;
    this.counterLeft = 0;
    this.counterRight = 0;
    this.movementsToRight = 0;

  }

  getElementsView(): number {
    if (this.scrWidth > 1024) {
      return 3;
    } else if (this.scrWidth <= 1024 && this.scrWidth > 700) {
      return 2;
    } else {
      return 1;
    }
  }



  prev(item: any, index: number) {
    this.elements = item.hotels;
    this.numberc = index;
    if (this.elements[0].currentIndex > 0) {
      this.elements[0].currentIndex--;
      this.elements.find(x => x.index === 0).marginLeft = -(this.elementWidth) * this.elements[0].currentIndex + this.elementMargin;

      if (this.counterRight - this.counterLeft <= this.elements[0].pages - this.elements[0].progressSize && this.elements[0].movementsToRight - this.elements[0].movementsToLeft > 0) {
        this.elements[0].progressMargin = this.elements[0].progressMargin + 24;
        this.elements[0].movementsToLeft++;
      }
      this.counterLeft++;
    }


  }

  next(item: any, index: number) {
    this.elements = item.hotels;
      this.numberc = index;
    if (this.elements[0].currentIndex < this.elements[0].pages - 1) {
      this.elements[0].currentIndex++;
      this.elements.find(x => x.index === 0).marginLeft = -(this.elementWidth) * this.elements[0].currentIndex + this.elementMargin;

      if (this.counterRight - this.counterLeft >= this.elements[0].progressSize - 1 && this.elements[0].movementsToRight - this.elements[0].movementsToLeft < this.elements[0].pages - this.elements[0].progressSize) {
        this.elements[0].progressMargin = this.elements[0].progressMargin - 24;
        this.elements[0].movementsToRight++;
      }
      this.counterRight++;
    }
  }

  next2(item: any) {
    this.elements = item;
    if (this.elements[0].currentIndex < this.elements[0].pages - 1) {
      this.elements[0].currentIndex++;
      this.elements.find(x => x.index === 0).marginLeft = -(this.elementWidth) * this.elements[0].currentIndex + this.elementMargin;

      if (this.counterRight - this.counterLeft >= this.progressSize - 1 && this.movementsToRight - this.movementsToLeft < this.elements[0].pages - this.progressSize) {
        this.progressMargin = this.progressMargin - 24;
        this.movementsToRight++;
      }
      this.counterRight++;
    }
  }

  prev2(item: any) {
    this.elements = item;
    if (this.elements[0].currentIndex > 0) {
      this.elements[0].currentIndex--;
      this.elements.find(x => x.index === 0).marginLeft = -(this.elementWidth) * this.elements[0].currentIndex + this.elementMargin;

      if (this.counterRight - this.counterLeft <= this.elements[0].pages - this.progressSize && this.movementsToRight - this.movementsToLeft > 0) {
        this.progressMargin = this.progressMargin + 24;
        this.movementsToLeft++;
      }
      this.counterLeft++;
    }


  }

  esPar(numero: number): boolean {
    return numero % 2 === 0;
  }

}
