import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions } from 'ngx-gallery-9';
import { DmImageGalleryComponent } from '../dm-image-gallery/dm-image-gallery.component';
import { DialogVideosComponent } from '../dialog-videos/dialog-videos.component';
import { MatDialog } from '@angular/material';
import { SessionService } from 'src/app/core/services/session.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { OnlyHotelService } from 'src/app/core/services/only-hotel.service';

@Component({
  selector: 'app-media-viewer-hotel',
  templateUrl: './media-viewer-hotel.component.html',
  styleUrls: ['./media-viewer-hotel.component.css']
})
export class MediaViewerHotelComponent implements OnInit {

  @Input() photos: any[];
  @Input() videos: string[];
  @Input() hotelItem: any;
  @Output() sendPhoto = new EventEmitter<any>();

  imagenList = [];
  public scrHeight:any;
  public scrWidth:any;
  currentScreenMode: number = 0 //1:web 2:phone
  lstImagenes = [];
  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];

  constructor(private dialog: MatDialog,private session: SessionService,private spinner: NgxSpinnerService,private hotelService: OnlyHotelService) {
    this.getScreenSize();
   }

  @HostListener('window:resize')
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    let view = this.getScreenMode();
    if (this.currentScreenMode !== view){
      this.currentScreenMode = view;
      console.log('change mode');
    }
  }

  ngOnInit() {
    this.loadImage();
    this.configGallery();
  }

  getImages(){
    let filer = this.session.getHotelFilter();
    this.spinner.show();
    this.hotelService.searchHotelListHabitacionImage(filer, this.hotelItem).subscribe(
      x => {
        this.recorrerImagenesF(x);
        //console.log(this.imagenList);
       
      }
    )
  }

  valid(numero){
    this.lstImagenes.forEach(element => {
        if(element.HotelId === this.hotelItem.id){
          if(numero === 1){
            this.selectPhotos(element.Imagenes);
          } else {
            this.loadImagePhone(element.Imagenes);
          }
          
        }
    });
  }


  getHabitacion(numero){
    let validar = false;
    let newList = [];
    let list = this.session.getIndiceImage();
    if(list != null){
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        if(element === this.hotelItem.id){
          validar = true;
        } 
      }
      if(validar === true){
        this.valid(numero);
      } else{
        list.push(this.hotelItem.id);
        this.session.setIndiceImage(list);
        this.getImages();
      }
    } else {
      newList.push(this.hotelItem.id);
      this.session.setIndiceImage(newList);
      this.getImages();
    }
  }

  recorrerImagenesF(list) {
    if(list != null && list.length > 1){
      for (let index = 0; index < list.length; index++) {
        const imagen = {
          smallPhoto: list[index].url,
          bigPhoto: list[index].url,
          mediumPhoto: list[index].url,
        };
        this.imagenList.push(imagen);
      }
      let obj = {
        HotelId: this.hotelItem.id,
        Imagenes: this.imagenList
      }
      this.lstImagenes.push(obj);
    }
    this.selectPhotos(this.imagenList);
    this.spinner.hide();
  }


  selectPhotos(imagenList) {
    const dialogRef = this.dialog.open(DmImageGalleryComponent, {
      data: imagenList
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  selectVideos() {
    const dialogRef = this.dialog.open(DialogVideosComponent, { data: this.videos });

    dialogRef.afterClosed().subscribe(x => {
    });
  }

  getScreenMode():number {
    return this.scrWidth > 667 ? 1 : 2;
  }

  configGallery() {
   /*  this.galleryOptions = [
      {
          arrowPrevIcon: "fa fa-chevron-circle-left", 
          arrowNextIcon: "fa fa-chevron-circle-right",
          preview: false,
          width: '300px',
          height: '206px',
          thumbnails: false,
          imageAnimation: NgxGalleryAnimation.Slide
      },
      {
          arrowPrevIcon: "fa fa-chevron-circle-left", 
          arrowNextIcon: "fa fa-chevron-circle-right",
          preview: false,
          breakpoint: 800,
          width: '300px',
          height: '206px',
          thumbnails: false,
          imageAnimation: NgxGalleryAnimation.Slide
      },
      {
          breakpoint: 400,
          preview: false
      }
    ]; */
    this.galleryOptions = [
      {
        width: '100%',
        height: '248px',
        thumbnailsColumns: 4,
        thumbnails : false,
        arrowPrevIcon: 'fa fa-chevron-left',
        arrowNextIcon: 'fa fa-chevron-right',
        imageAnimation: NgxGalleryAnimation.Slide,
       /*  p: () => {
          this.getHabitacion();
        } */
        
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '315px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];
  }

  loadImagePhone(list) {
    for (let index = 0; index < list.length; index++) {
      let imagen: NgxGalleryImage = {
        small: list[index].smallPhoto,
        medium: list[index].bigPhoto,
        big: list[index].bigPhoto,
      };
      this.galleryImages.push(imagen);
    }
  }

  loadImage() {
    for (let index = 0; index < this.photos.length; index++) {
      let imagen: NgxGalleryImage = {
        small: this.photos[index].smallPhoto,
        medium: this.photos[index].bigPhoto,
        big: this.photos[index].bigPhoto,
      };
      this.galleryImages.push(imagen);
    }
  }

}
