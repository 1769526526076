export const CabinClass = [
    {
        id: '',
        description: 'Todas'
    },
    {
        id: 'E',
        description: 'Económica'
    },
    {
        id: 'B',
        description: 'Business'
    },
    {
        id: 'F',
        description: 'First'
    }
];
