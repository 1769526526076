import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../models/dialog-data.model';
import { PackageService } from '../../../core/services/package.service';
import { DomSanitizer } from '@angular/platform-browser';
import { updateLocale } from 'ngx-bootstrap/chronos';

@Component({
  selector: 'app-dialog-detail',
  templateUrl: './dialog-detail.component.html',
  styleUrls: ['./dialog-detail.component.css'],
  providers: [ PackageService ]
})
export class DialogDetailComponent implements OnInit {

  html;
  loading: boolean;

  constructor(
    public dialogRef: MatDialogRef<DialogDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private packageService: PackageService,
    protected sanitizer: DomSanitizer) {}

    ngOnInit() {
      /* console.log(this.data); */
      this.loading = true;
      if (this.data.type === '1') {
        this.loading = false;
        this.html = this.sanitizer.bypassSecurityTrustHtml(this.data.id);
      } else {
        this.getFlightItineraryHtmlDetail();
      }
    }


    getHotelHtmlDetail() {
      this.loading = true;
      this.packageService.getHotelHtml(this.data.id).subscribe(
        x => {
          if (x.confirmation) {
            this.html = this.sanitizer.bypassSecurityTrustHtml(x.data);
          }
        },
        err => { /* console.log('Error: ' + err); */ },
        () => {
          this.loading = false;
        }
      );
    }

    getFlightItineraryHtmlDetail(){
      this.loading = true;
      this.packageService.getFlightItineraryHtml(this.data.id).subscribe(
        x => {
          if (x.confirmation) {
            this.html = this.sanitizer.bypassSecurityTrustHtml(x.data);
          }
        },
        err => { /* console.log('Error: ' + err); */ },
        () => {
          this.loading = false;
        }
      );
    }

  getHtml(value: string){
  /*   console.log(value); */
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  onClose() {
    this.dialogRef.close();
  }

}
