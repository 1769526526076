import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { DialogSocialLoginComponent } from '../../auth/dialog-social-login/dialog-social-login.component';
import { SessionService } from 'src/app/core/services/session.service';
import { Router } from '@angular/router';
import { DialogCerrarSessionComponent } from 'src/app/auth/dialog-social-login/dialog-confirm-cerrarsession/dialog-cerrar-session/dialog-cerrar-session.component';
import { HeaderService } from 'src/app/shared/services/head.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  showOptions: boolean;
  outOfOffice: boolean;
  charter;
  show: boolean;
  user;
  showFiller = false;
  partner;
  nombres;
  panelOpenState = true;
  validSesion = true;
  closePopup = true;
  text = "col-2";
  constructor(
    protected sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
    private router: Router,
    private headerService: HeaderService,
    public dialog: MatDialog,
    private sessionService: SessionService
  ) {
    // iconRegistry.addSvgIcon(
    //   'header-package',
    //   sanitizer.bypassSecurityTrustResourceUrl('../../assets/images/header/pack.svg'));
    // iconRegistry.addSvgIcon(
    //   'header-flight',
    //   sanitizer.bypassSecurityTrustResourceUrl('../../assets/images/header/flight.svg'));
    // iconRegistry.addSvgIcon(
    //   'header-flight',
    //   sanitizer.bypassSecurityTrustResourceUrl('../../assets/images/header/flight.svg'));
    // iconRegistry.addSvgIcon(
    //   'header-hotel',
    //   sanitizer.bypassSecurityTrustResourceUrl('../../assets/images/header/hotel.svg'));
    // iconRegistry.addSvgIcon(
    //   'header-activity',
    //   sanitizer.bypassSecurityTrustResourceUrl('../../assets/images/header/activity.svg'));
   
  }

  ngOnInit() {
    this.validSesion = this.sessionService.getIniciarSesion() != null ? this.sessionService.getIniciarSesion() : true;
     console.log(screen.width);
    this.charter = this.sessionService.getHeader();
    this.partner = this.sessionService.getPartnerClub();
    this.nombres = this.sessionService.getEmailFinish();
    if(this.partner === null){
      this.partner = false;
    }
    const hours = new Date().getHours();
    this.outOfOffice = (hours >= 19 && hours <= 23) || (hours >= 0 && hours <= 8);
   /*  if(this.partner === true){
      let qwe = document.getElementById("headerPartner");
      qwe.style.backgroundImage = "url(assets/images/headerPartner.png)";
    } */
  }
  
  routerhome(){
    this.router.navigate([""]);
    this.headerService.initializeWhatsAppWidget("");
  }

  whatVentas() {
    let direct = "https://vacation.domiruth.com/";
    let qwe = "Hola me puedes ayudar por favor, me encuentro en " + direct;
    let url = "https://api.whatsapp.com/send?phone=+51965329933&text=" + qwe;
    window.open(url, '_blank');
  }

  whatEmergencias() {
    let direct = "https://vacation.domiruth.com/";
    let qwe = "Hola me puedes ayudar por favor, me encuentro en " + direct;
    let url = "https://api.whatsapp.com/send?phone=+51999651520&text=" + qwe;
    window.open(url, '_blank');
  }

  onClose() {
    this.closePopup = false;
  }

  showIniciar(){
    this.router.navigate(['login-logout'])
   /*  let hotphoe = document.getElementById("footPhone");
    hotphoe.style.display = "block"
    let foot = document.getElementById("todoFoot");
    foot.style.display = "none";
    let valor = document.getElementById("todoPhone");
    valor.style.display = "flex"
    let valor1 = document.getElementById("todoWeb");
    valor1.style.display = "none"
    console.log(valor); */
  }

  showIniciarMotor(){
    let hotphoe = document.getElementById("footPhone");
    hotphoe.style.display = "none"
    let foot = document.getElementById("todoFoot");
    foot.style.display = "block";
    let valor = document.getElementById("todoPhone");
    valor.style.display = "none"
    let valor1 = document.getElementById("todoWeb");
    valor1.style.display = "block"
    console.log(valor);
  }

  showList() {
    this.showOptions = true;
  }

  
  openWhats() {
    let direct = "https://vacation.domiruth.com/";
    let qwe = "Hola me puedes ayudar por favor, me encuentro en " + direct;
    let url = "https://api.whatsapp.com/send?phone=+51965329933&text=" + qwe;
    window.open(url, '_blank');
  }

  openDialog(valor) {
    
    if (this.validSesion === false) {
      let val = window.location.pathname;
      const dialogRef = this.dialog.open(DialogCerrarSessionComponent, {
        data: val,
        disableClose: true,
        panelClass: 'custom-dialog-containers',
        maxWidth: 450
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          this.validSesion = result;
        }
      });
      
    } else {
      this.show = false;
      const dialogRef = this.dialog.open(DialogSocialLoginComponent, {
        data: valor,
        disableClose: true,
        panelClass: 'custom-dialog-containers',
        maxWidth: 800
      });

      dialogRef.afterClosed().subscribe(result => {
        /* //console.log('The dialog was closed'); */
        if (result) {
          this.user = result;
        }
      });
    }

  }

  llamar(numero: string) {
    window.open('tel:' + numero);
  }

  hiddenList() {
    this.showOptions = false;
  }

  showModal() {
    const dialogRef = this.dialog.open(DialogSocialLoginComponent, {
      data: ''
    });

    dialogRef.afterClosed().subscribe(result => {
     /*  console.log('The dialog was closed'); */
    });
  }

}
