import { Component, Inject,OnInit } from '@angular/core';
import { LoginService } from '../../../core/services/login.service';
import * as crypto from 'crypto-js';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionService } from 'src/app/core/services/session.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PackageService } from 'src/app/core/services/package.service';
import { DialogCodeVerificationComponent } from 'src/app/auth/dialog-social-login/dialog-code-verification/dialog-code-verification.component';
import { LoginLoadingComponent } from '../login-loading/login-loading.component';

@Component({
  selector: 'app-login-initial',
  templateUrl: './login-initial.component.html',
  styleUrls: ['./login-initial.component.css'],
  providers: [ LoginService,PackageService ]
})
export class LoginInitialComponent implements OnInit {

  countries;
  correoExist;
  pais;
  numero;
  mensaje;
  errorMessage = false;
  nombre ="+51";
  constructor(private sessionService: SessionService,private spinner: NgxSpinnerService,private packageService: PackageService, public dialog: MatDialog
    , public dialogRef: MatDialogRef<LoginInitialComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }
  
  ngOnInit() {
    this.getCountryCodes();
  }

  onClose() {
    this.dialogRef.close();
  }

  getCountryCodes() {
    
    this.packageService.getCountryCodes().subscribe(
      x => {
        if (x.confirmation && x.data) {
          this.countries = x.data.filter(x => x.id !== null && x.id !== '');
        }
      },
      err => {

      },
      () => {
     
      }
    );
  }

  
  openDialogExiste(valor) {
    let objeto = {
      contra: this.data.contra,
      loginUser: this.data.loginUser,
      person: this.data.person,
      code: valor
    }
    this.dialogRef.close();
    const dialogRef = this.dialog.open(LoginLoadingComponent, {
      data: objeto,
      disableClose: true,
      panelClass: 'custom-dialog-container',
      maxWidth: 800,
    
    });

    dialogRef.afterClosed().subscribe(result => {
      /* //console.log('The dialog was closed'); */
      if (result) {
        this.correoExist = result;
      }
    });
  }

  sendSms(valor){
    this.spinner.show();
    this.packageService.registerWithPhone(this.data.person,valor).subscribe(
      x=>{
        //console.log(x);
        if(x.status === 200){
          this.errorMessage = false;
          this.openDialogExiste(x.odata.code);
          this.sessionService.setPartnerClub(true);
        } else {
          this.mensaje = x.message;
          this.errorMessage = true;
        }
        this.spinner.hide();
      }
    )
    
  }

  

  codeConfirm(){
    //console.log(this.numero);
    let pa = this.nombre;
    const asd = pa.slice(1);
    let valor = asd + this.numero;
    this.sessionService.setPhone(valor);
    //console.log(this.data);
    this.sendSms(valor);
    
  }

  
  }
