import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { PackageService } from 'src/app/core/services/package.service';
import { SessionService } from 'src/app/core/services/session.service';
import * as crypto from 'crypto-js';
import { DialogFinishPasswordComponent } from '../dialog-finish-password/dialog-finish-password.component';

@Component({
  selector: 'app-dialog-update-password',
  templateUrl: './dialog-update-password.component.html',
  styleUrls: ['./dialog-update-password.component.css'],
  providers: [PackageService]
})
export class DialogUpdatePasswordComponent implements OnInit {

  contras;
  contraVal;
  valPass = false;
  textcontra = "";
  textpass = "Las contraseñas no coinciden."
  constructor(private sessionService: SessionService, private snackBar: MatSnackBar, private spinner: NgxSpinnerService, private packageService: PackageService, public dialog: MatDialog
    , public dialogRef: MatDialogRef<DialogUpdatePasswordComponent>, @Inject(MAT_DIALOG_DATA) public data: string) { }

  ngOnInit() {
  }

  validCampo(){
    if(this.textcontra != "Muy alto" || this.valPass === true){
      return;
    } else {
      this.updatePass();
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  openDialogReset(valor) {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(DialogFinishPasswordComponent, {
      data: valor,
      panelClass: 'custom-dialog-container',
      maxWidth: 800
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  updatePass(){
    this.spinner.show ();
    const data = {
      UserID: this.data,
      Password: crypto.SHA256(this.contras).toString(),
      AppId: 3
    }
    this.packageService.updatePass(data).subscribe(
      x=> {
        if(x.status === 200){
          this.openDialogReset(x.message);
        }
        this.spinner.hide();
      }
    )
  }

  validpassword() {
    let valid = document.getElementById("conti");
    let stilo = document.getElementById("contra");
    let texta = document.getElementById("textoContra");
    var strongRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");
    var mediumRegex = new RegExp("^(?=.{7,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$", "g");
    var enoughRegex = new RegExp("(?=.{6,}).*", "g");
    if (false == enoughRegex.test(this.contras)) {
      this.textcontra = "Baja";
      texta.style.color = "#E2231A";
      stilo.style.border = "1px solid #E2231A";
      valid.style.backgroundColor = "rgba(232, 45, 86, 0.5)";
      valid.style.pointerEvents = "none";
    } else if (strongRegex.test(this.contras)) {
      this.textcontra = "Muy alto";
      texta.style.color = "#44C851";
      stilo.style.border = "1px solid #44C851";
      if(this.valPass === true){
        valid.style.backgroundColor = "#E82D56";
        valid.style.pointerEvents = "visible";
      }
    } else if (mediumRegex.test(this.contras)) {
      valid.style.backgroundColor = "rgba(232, 45, 86, 0.5)";
      valid.style.pointerEvents = "none";
      this.textcontra = "Media";
      texta.style.color = "#FFC107";
      stilo.style.border = "1px solid #FFC107";
    } else {
      valid.style.backgroundColor = "rgba(232, 45, 86, 0.5)";
      valid.style.pointerEvents = "none";
      this.textcontra = "Baja";
      texta.style.color = "#E2231A";
      stilo.style.border = "1px solid #E2231A";
    }
    return true;
  }

  validpasswordRepeat() {
    let valid = document.getElementById("conti");
    if(this.contras === this.contraVal){
      this.valPass = false;
      if(this.textcontra === "Muy alto"){
        valid.style.backgroundColor = "#E82D56";
        valid.style.pointerEvents = "visible";
      }
    } else {
      
      valid.style.backgroundColor = "rgba(232, 45, 86, 0.5)";
      valid.style.pointerEvents = "none";
      this.valPass = true;
    }
  }

}
