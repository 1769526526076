import { Component, OnInit, HostListener, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { FilterPassenger } from 'src/app/shared/models/filter-passenger.model';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dm-passenger-counter',
  templateUrl: './dm-passenger-counter.component.html',
  styleUrls: ['./dm-passenger-counter.component.css']
})
export class DmPassengerCounterComponent implements OnInit {

  show: boolean;
  form: FormGroup;
  private wasInside = false;
  totalPassengers = 1;
  PASSENGER_LIMIT = 6;
  errorMessage = '';
  filter: FilterPassenger[] = [];

  @Input() value: FilterPassenger[];
  @Output() selection = new EventEmitter<FilterPassenger[]>();

  constructor(
    private eRef: ElementRef,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.show = false;
      this.errorMessage = '';
    }
    this.wasInside = false;
  }

  initForm() {

    let adults = 1;
    let children = 0;
    let infants = 0;
    if (this.value) {
      adults = this.value[0].quantity;
      children = this.value[1].quantity;
      infants = this.value[2].quantity;
    }

    this.totalPassengers = Number(adults) + Number(children) + Number(infants);

    this.form = this.formBuilder.group({
      adults: new FormControl({value: adults, disabled: true}),
      children: new FormControl({value: children, disabled: true}),
      infants: new FormControl({value: infants, disabled: true})
    });

    this.filter.push({
      quantity: adults,
      type: FilterPassenger.TYPE_ADULT
    });
    this.filter.push({
      quantity: children,
      type: FilterPassenger.TYPE_CHILD
    });
    this.filter.push({
      quantity: infants,
      type: FilterPassenger.TYPE_INFANT
    });
    this.selection.emit(this.filter);
  }

  onFocus() {
    this.show = true;
  }

  removePassenger(type) {
    this.errorMessage = '';
    const adults = Number(this.form.controls.adults.value);
    const children = Number(this.form.controls.children.value);
    const infants = Number(this.form.controls.infants.value);
    switch (type) {
      case 'adults':
        if (adults > 1) {
          if (adults - 1 >= infants) {
            this.form.controls.adults.setValue((adults - 1));
            this.totalPassengers--;
          } else {
            this.errorMessage = 'El número de infantes no puede superar al número de adultos';
          }
        }
        break;
      case 'children':
        if (children > 0) {
          this.form.controls.children.setValue((children - 1));
          this.totalPassengers--;
        }
        break;
      case 'infants':
        if (infants > 0) {
          this.form.controls.infants.setValue((infants - 1));
          this.totalPassengers--;
        }
        break;
    }
    this.updateFilter(type);
  }

  addPassenger(type) {
    this.errorMessage = '';
    if (this.totalPassengers < this.PASSENGER_LIMIT) {
      const adults = Number(this.form.controls.adults.value);
      const children = Number(this.form.controls.children.value);
      const infants = Number(this.form.controls.infants.value);
      switch (type) {
        case 'adults':
          this.form.controls.adults.setValue((adults + 1));
          this.totalPassengers++;
          break;
        case 'children':
          this.form.controls.children.setValue((children + 1));
          this.totalPassengers++;
          break;
        case 'infants':
          if (infants + 1 <= adults) {
            this.form.controls.infants.setValue((infants + 1));
            this.totalPassengers++;
          } else {
            this.errorMessage = 'El número de infantes no puede superar al número de adultos';
          }
          break;
      }
    } else {
      this.errorMessage = 'Máximo número de pasajeros permitidos: ' + this.PASSENGER_LIMIT;
    }
    this.updateFilter(type);
  }

  updateFilter(type) {
    switch (type) {
      case 'adults':
        this.filter[0] = { quantity: this.form.controls.adults.value, type: FilterPassenger.TYPE_ADULT };
        break;
      case 'children':
        this.filter[1] = { quantity: this.form.controls.children.value, type: FilterPassenger.TYPE_CHILD };
        break;
      case 'infants':
        this.filter[2] = { quantity: this.form.controls.infants.value, type: FilterPassenger.TYPE_INFANT };
        break;
    }
    this.selection.emit(this.filter);
  }

}
