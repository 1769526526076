import { Component, HostListener, Output, Input, OnInit, EventEmitter, Renderer2, AfterViewInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FlightService } from 'src/app/core/services/flight.service';
import { SessionService } from 'src/app/core/services/session.service';
import { FilterSearch } from 'src/app/hotels/hotel-filter-search/shared/filter-search.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeviceDetectorService } from 'ngx-device-detector';
import { airportsData } from '../../services/airport.const';
import { HotelService } from 'src/app/core/services/hotel.service';

@Component({
  selector: 'dm-new-destinations-vh-carousel',
  templateUrl: './new-destinations-vh-carousel.component.html',
  styleUrls: ['./new-destinations-vh-carousel.component.css'],
  providers: [DeviceDetectorService, HotelService]
})
export class NewDestinationsVhCarouselComponent implements OnInit, AfterViewInit{

  @Input() isPhone: boolean;
  @Input() lstVh: any[] = [];
  @Input() origenes: any[];
  @Output() vh = new EventEmitter<any>();
  listadoDinamico: any[] = [];
  listadoDinamicoN: any[] = [];
  listadoDinamicoI: any[] = [];
  fromFilter: any[] = [];
  toFilter: any[] = [];
  dateFilter: any[] = [];
  stars;
  rpta;

  origins = [];
  /**carousel */
  elementSize: number = 318
  elementMargin: number = 20;
  widthWeb: number;
  elements: any[] = [];
  public myObject!: { id: number, myObject: { myString: string } };
  public carouselWidth: number;
  public elementWidth: number;
  public currentIndex: number = 0;
  public pages: number = 0;
  public progressSize: number = 4;
  public counterLeft: number = 0;
  public counterRight: number = 0;
  public progressMargin: number = 0;
  public movementsToRight: number = 0;
  public movementsToLeft: number = 0;
  public currentItemsCarousel: number = 0;
  public scrHeight: any;
  public scrWidth: any;
  defaultTouch = { x: 0, y: 0, time: 0 };
  /**carousel */
  deviceInfo: any;
  dataAirport: any;
  ratingArr = [];
  constructor(private renderer: Renderer2, private elementRef: ElementRef,private deviceService: DeviceDetectorService, private hotelService: HotelService,
    private spinner: NgxSpinnerService, private flightService: FlightService, private sessionService: SessionService, private router: Router) 
  {
    this.getScreenSize();
    this.dataAirport = airportsData;
  }

  ngOnInit() {
    if(this.origenes != null && this.origenes != undefined){
      if(this.origenes.length > 0){
        this.origins = this.origenes; 
      }
    }
    this.getDynamicSearchW(this.lstVh);
    if(this.origenes === null && this.origenes === undefined){
    this.getAirports();
    }

    for (let index = 0; index < 5; index++) {
      this.ratingArr.push(index);
    }
  }

  showIcon(index: number, stars) {
    let star = parseInt(stars);
    if (star >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

  getPriorityAirports() {
    /* this.spinner.show(); */
    this.flightService.getPriorityAirports().subscribe(
      (result) => {
        /* //console.log(result); */
        if (result) {
          const airports = result.lairports ? result.lairports : [];
          const cities = result.lcities ? result.lcities : [];
          airports.forEach((airport) => {
            this.origins.push({
              code: airport.iataCode,
              countryCode: airport.countryCode,
              name: airport.name,
              searchName: airport.searchName,
              priority: airport.priority,
              /* icon: 'local_airport' */
              icon: "A",
            });
          });
          cities.forEach((city) => {
            this.origins.push({
              code: city.iataCode,
              countryCode: city.countryCode,
              name: city.name,
              searchName: city.searchName,
              priority: city.priority,
              /* icon: 'location_city' */
              icon: "C",
            });
          });
          /* this.origins.sort((a, b) => b.priority - a.priority);  */
        }
      },
      (err) => {
        /*   //console.log('Error: ', err); */
      },
      () => {
      
        this.getAirports();
      }
    );
  }

  getAirports() {
    const _origins = [];
    let result = this.dataAirport;
    if (result) {
      const airports = result.lairports ? result.lairports : [];
      const cities = result.lcities ? result.lcities : [];
      airports.forEach((airport) => {
        _origins.push({
          code: airport.iataCode,
          countryCode: airport.countryCode,
          name: airport.name,
          searchName: airport.searchName,
          priority: airport.priority,
          cityName: airport.cityName,
          /* icon: 'local_airport' */
          icon: "A",
        });
      });
      cities.forEach((city) => {
        _origins.push({
          code: city.iataCode,
          countryCode: city.countryCode,
          name: city.name,
          searchName: city.searchName,
          priority: city.priority,
          cityName: city.cityName,
          /* icon: 'location_city' */
          icon: "C",
        });
      });
      /* _origins.sort((a, b) => b.priority - a.priority); */
    }

    this.origins = _origins;
  }

  calculatePageWidth() {
    return window.innerWidth;
  }

  ngAfterViewInit() {

    let locat = window.location.pathname;
      let locat2 = window.location.search;
      if(locat2 != ""){
      let located2 = locat2.split('?');
      let quitar = located2[1].split('=');
    if(locat + '?' + quitar[0] === '/flight?hotel'){
      /* setTimeout(() => { */
          
        setTimeout(() => {
          document.body.style.overflowY = "hidden";
          const targetElement = this.elementRef.nativeElement.querySelector('#vuelohotel');
          if(targetElement){
            targetElement.scrollIntoView({ behavior: 'smooth' });
            let find = this.elements.find(x => x.odestination.iataCode === quitar[1])
            if(find != null || find != undefined){
              /* let indexnum = find.index - 2; */
              let indexnum;
              this.widthWeb = this.calculatePageWidth();

              switch (true) {
                case (this.widthWeb > 200 && this.widthWeb <= 700):
                  indexnum = find.index;
                  break;
                case (this.widthWeb > 700 && this.widthWeb <= 1028):
                  indexnum = find.index - 1;
                  break;
                case (this.widthWeb > 1028):
                  indexnum = find.index - 2;
                  break;
              }

            if(indexnum > 0){
              for (let index = 0; index < indexnum; index++) {
              this.next();
                }
              }
            }
            setTimeout(() => {
              document.body.style.overflowY = "auto";
            }, 1500);
          }else {
            document.body.style.overflowY = "auto";
          }
        }, 500);
          
      /* }, 2000); */

    }
  }
    
  }

  moverIzq(id_container) {
    //console.log("id_container: " + id_container);

    const container = document.getElementById(id_container);
    this.sideScroll(container, "left", 5, 200, 10);
  }

  fillOrigen(valor) {
    let origin;
    let origins = this.origins;

      origins.forEach(element => {
        if (element.code === valor) {
          origin = element;
        }
      });
      return origin;

  }

  /*  setVH(valor) {
     this.spinner.show();
     this.vh.emit(valor);
 
   } */

  setVH(valor) {
    /*  const userAgent = window.navigator.userAgent.toLowerCase();
     const isMobile = /iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent);
 
     if (isMobile) {
       this.renderer.addClass(document.body, 'mobile');
     } else {
       this.renderer.addClass(document.body, 'desktop');
     } */
    this.deviceInfo = this.deviceService.getDeviceInfo();
    /*  this.spinner.show(); */
    let origen;
    let destino;
    
     if(this.origenes != null && this.origenes != undefined){
      if(this.origenes.length > 0){
        this.origins = this.origenes;
      }
    }

    let resultado = this.origins.filter(item => item.code === valor.oorigin.iataCode);
    origen = resultado[0];
    let resultado1 = this.origins.filter(item => item.code === valor.odestination.iataCode);
    destino = resultado1[0];
    let lstRoom = [];
    const obj = {
      adults: valor.numberPassengers,
      ages: [],
      children: 0,
      passengers: []
    }
    lstRoom.push(obj);
    const objeto = {
      departureDate: valor.departureDate,
      firstItem: "1",
      from: origen,
      lBoard: "",
      code: valor.ohotelInfo.code,
      lRating: "",
      lSupplier: "",
      lTripAdvisor: "",
      lZone: "",
      HotelStars: valor.ohotelInfo.stars.toString(),
      returnDate: valor.arrivalDate,
      rooms: lstRoom,
      to: destino,
    }
    this.sessionService.setHotelFilter(objeto);
    this.sessionService.setHotelFlightDyn(true);

    let qwe = valor.odestination.cityName.replace(/' '/g,"/");

    let url = window.location.origin  + "/flight-hotel/hotel-result/" + qwe;
    this.openNewTab(url);

    /* this.router.navigate(["/flight-hotel/hotel-result", qwe]); */

  }

  openNewTab(url: string) {
    window.open(url, '_blank');
  }



  setVHPhone(valor) {
    console.log(valor);
    let lstRoom = [];
    let obj = {
      adults: valor.numberPassengers,
      ages: [],
      children: 0,
      passengers: []
    }
    lstRoom.push(obj);
    let objeto = {
      departureDate: valor.departureDate,
      firstItem: "1",
      from: this.fillOrigen(valor.oorigin.iataCode),
      lBoard: "",
      lRating: "",
      lSupplier: "",
      lTripAdvisor: "",
      lZone: "",
      returnDate: valor.arrivalDate,
      rooms: lstRoom,
      to: this.fillOrigen(valor.odestination.iataCode),
    }
    this.sessionService.setHotelFilter(objeto);
    this.router.navigate(["flight-hotel/hotel-result"]);
  }

  searcHotel() {
    let cuartos: any[];
    const room = {
      adults: 2,
      ages: [],
      children: 0,
      passengers: []
    }
    const ir = {
      code: null,
      countryCode: null,
      icon: null,
      name: null,
      priority: null,
      searchName: null,
    }
    cuartos.push(room);
    const filter: FilterSearch = {
      from: null,
      to: null,
      departureDate: this.rpta.departureDate,
      returnDate: this.rpta.arrivalDate,
      rooms: cuartos,
      HotelStars: "",
      lBoard: [],
      lZone: [],
      lTripAdvisor: [],
      lSupplier: [],
      lRating: [],
      firstItem: "1",
    };
    this.sessionService.setHotelFilter(filter);
    this.router.navigate(["flight-hotel/hotel-result"]);
  }

  getNameIsTooltip(name: string, max: number): string{
    return name.substring(0, max) + "..."
  }



  sideScroll(element, direction, speed, distance, step) {
    var scrollAmount = 0;
    var slideTimer = window.setInterval(function () {
      if (direction == "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  moverDer(id_container) {
    //console.log("id_container: " + id_container);

    const container = document.getElementById(id_container);
    this.sideScroll(container, "right", 5, 200, 10);
  }

  getDynamicSearchW(result) {
    this.rpta = result;
    this.listadoDinamico = result;
/*     this.listadoDinamicoN = this.listadoDinamico.filter(
      (x) => x.flightNational == true
    );
    this.listadoDinamicoI = this.listadoDinamico.filter(
      (x) => x.flightNational == false
    );
    this.listadoDinamicoI.forEach(element => {
      if (element.ohotel.stars != null) {
        element.ohotel.stars = parseFloat(element.ohotel.stars);
      }
    }); */
    //console.log("Complete getDynamicSearch");
    /* this.listadoDinamico.forEach(element => {
        if(element.ohotel.stars != null){
          element.ohotel.stars = parseFloat(element.ohotel.stars);
        }
    }); */

    this.elements = this.listadoDinamico;
    this.calculateDimensions();
  }


  getDynamicSearch() {
    this.hotelService.getDynamicSearchHotel("VH").subscribe(
      (result: any) => {
        //console.log("result");
        //console.log(JSON.stringify(result));
        this.rpta = result;
        this.listadoDinamico = result.ldata;
      },
      (error) => {
        //console.log("ERROR: " + JSON.stringify(error));
      },
      () => {
        this.listadoDinamicoN = this.listadoDinamico.filter(
          (x) => x.flightNational == true
        );
        this.listadoDinamicoI = this.listadoDinamico.filter(
          (x) => x.flightNational == false
        );
        this.listadoDinamicoI.forEach(element => {
          if (element.ohotel.stars != null) {
            element.ohotel.stars = parseFloat(element.ohotel.stars);
          }
        });
        //console.log("Complete getDynamicSearch");

        this.elements = this.listadoDinamicoI;
        this.calculateDimensions();
      }
    );
  }




  /**carousel */
  @HostListener('window:resize')
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    let elementsView = this.getElementsView();
    if (this.currentItemsCarousel !== elementsView) {
      this.calculateDimensions();
    }
  }

  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  handleTouch(event) {
    let touch = event.touches[0] || event.changedTouches[0];
    if (event.type === 'touchstart') {
      this.defaultTouch.x = touch.pageX;
      this.defaultTouch.time = event.timeStamp;
    } else if (event.type === 'touchend') {
      let deltaX = touch.pageX - this.defaultTouch.x;
      let deltaTime = event.timeStamp - this.defaultTouch.time;
      if (deltaTime < 500) {
        if (Math.abs(deltaX) > 60) {
          if (deltaX > 0) {
            this.prev();
          } else {
            this.next();
          }
        }
      }
    }
  }

  calculateDimensions() {
    this.currentItemsCarousel = this.getElementsView();
    this.elementWidth = this.elementSize + 2 * this.elementMargin;
    this.carouselWidth = this.elementWidth * this.getElementsView();
    this.currentIndex = 0;
    if (this.elements !== null && this.elements !== undefined) {
      this.pages = this.elements.length - this.getElementsView() + 1;
      if (this.pages <= 0)
        this.pages = 1;
      this.elements.map((i, index) => {
        i.index = index, i.marginLeft = this.elementMargin, i.marginRight = this.elementMargin
      });
    }

    this.progressMargin = 0;
    this.counterLeft = 0;
    this.counterRight = 0;
    this.movementsToRight = 0;
    this.movementsToLeft = 0;
  }

  getElementsView(): number {
    if (this.scrWidth > 1450) {
      return 4;
    }else if (this.scrWidth <= 1450 && this.scrWidth > 1024){
      return 3;
    } else if (this.scrWidth <= 1024 && this.scrWidth > 700){
      return 2;
    } else {
      return 1;
    }
  }

  prev() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.elements.find(x => x.index === 0).marginLeft = -(this.elementWidth) * this.currentIndex + this.elementMargin;

      if (this.counterRight - this.counterLeft <= this.pages - this.progressSize && this.movementsToRight - this.movementsToLeft > 0) {
        this.progressMargin = this.progressMargin + 24;
        this.movementsToLeft++;
      }
      this.counterLeft++;
    }


  }

  next() {
    if (this.currentIndex < this.pages - 1) {
      this.currentIndex++;
      this.elements.find(x => x.index === 0).marginLeft = -(this.elementWidth) * this.currentIndex + this.elementMargin;

      if (this.counterRight - this.counterLeft >= this.progressSize - 1 && this.movementsToRight - this.movementsToLeft < this.pages - this.progressSize) {
        this.progressMargin = this.progressMargin - 24;
        this.movementsToRight++;
      }
      this.counterRight++;
    }
  }
  /**carousel */

}
