import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { SessionService } from 'src/app/core/services/session.service';


@Injectable({
    providedIn: 'root'
})


export class HeaderService {
    private dataSubject = new BehaviorSubject<string>('Datos iniciales');
    data$ = this.dataSubject.asObservable();

    setData(data: string) {
        this.dataSubject.next(data);
    }
    showOverlay = false;
    constructor(private http: HttpClient,private sessionService: SessionService) {

    }

    getAirports() {
        return this.http.get<any>('../../../assets/data.json')
            .toPromise()
            .then(res => <any[]>res);
    }


    mostrarSpinner(): void {
        this.showOverlay = true;
    }

    ocultarSpinner(): void {
        this.showOverlay = false;
    }
    replaceHyphensWithSpaces(text: string): string {
        return text.replace(/-/g, ' ');
      }

    initializeWhatsAppWidget(currentUrl: string) {
        let welcomeMessage = 'Hola, Domiruth tengo una consulta acerca de su web de vacaciones, me podrián asesorar';
      
        switch(true) {
          case (currentUrl.includes('/seguros/insurance-select')):
            let objeto = this.sessionService.getLlenarDatos();
            if(objeto != null || objeto != undefined){
              let destino = objeto.Destino === "Nacional" || objeto.Destino === "Internacional" ? objeto.Destino + "es" : "Domiruth";
              welcomeMessage = 'Hola Domiruth, me podrían asesorar con sus opciones de seguros, para *Viajes ' + destino + "*";
            }else {
              welcomeMessage = 'Hola Domiruth, me podrían asesorar con sus opciones de *seguros*';
            }
            break;
          case (currentUrl.includes('/flight/flight-result') && currentUrl === '/flight/flight-result'):
            let fligth = this.sessionService.getFilterFlightWrapper();
            if(fligth != null || fligth != undefined){
              if(fligth.type === "RT"){
                welcomeMessage = 'Hola Domiruth, vengo de la sección de *Búsqueda de Vuelos* ida y vuelta, realice una búsqueda de ' 
                                  + fligth.fromFilter[0].cityName + ' a ' + fligth.toFilter[0].cityName + '. Ida: ' 
                                  + formatDate(fligth.dateFilter[0].toString(), 'EEEE dd MMMM', 'es-PE', 'America/Lima')
                                  + ' ,Vuelta: ' + formatDate(fligth.dateFilter[1].toString(), 'EEEE dd MMMM', 'es-PE', 'America/Lima');
              }else if (fligth.type === "OW"){
                welcomeMessage = 'Hola Domiruth, vengo de la sección de *Búsqueda de Vuelos* sólo ida, realice una búsqueda de '
                                  + fligth.fromFilter[0].cityName + ' a ' + fligth.toFilter[0].cityName + ' para la fecha '
                                  + formatDate(fligth.dateFilter[0].toString(), 'EEEE dd MMMM', 'es-PE', 'America/Lima');
              }else if (fligth.type === "MC"){
                let namesOrigin = ' ';
                fligth.fromFilter.forEach(element => {
                  namesOrigin += element.cityName + ", ";
                });
                let namesDestino = ' ';
                fligth.toFilter.forEach(x => {
                  namesDestino += x.cityName + ", ";
                });
                welcomeMessage = 'Hola Domiruth, vengo de la sección de *Búsqueda de Vuelos* multidestino, realice una búsqueda con los ORIGENES: ' 
                                  + namesOrigin + "y los DESTINOS: " + namesDestino;
              }
            }else {
            welcomeMessage = 'Hola Domiruth, vengo de la sección *Búsqueda de Vuelos* de su web, me podrián asesorar';
            }
            break;
            case (currentUrl.includes('/flight-hotel/hotel-result')):
              let hotelfligth = this.sessionService.getHotelFilter();
              if(hotelfligth != null || hotelfligth != undefined){
                welcomeMessage = 'Hola Domiruth, vengo de la sección de *Búsqueda de Vuelos + Hotel*, realice una búsqueda de ' 
                                  + hotelfligth.from.cityName + ' a ' + hotelfligth.to.cityName + '. Desde el '
                                  + formatDate(hotelfligth.departureDate, 'EEEE dd MMMM', 'es-PE', 'America/Lima') + ' hasta el ' + formatDate(hotelfligth.returnDate, 'EEEE dd MMMM', 'es-PE', 'America/Lima');
              }else {
              welcomeMessage = 'Hola Domiruth, vengo de la sección de *Búsqueda de Vuelos + Hotel* de su web, me podrián asesorar';
              }
              break;
            case (currentUrl.includes('/hotel/hotel-list')):
               let onlyhotel = this.sessionService.getHotelFilter();
               if(onlyhotel != null || onlyhotel != undefined){
                 welcomeMessage = 'Hola Domiruth, vengo de la sección de *Búsqueda de Hoteles*, realice una búsqueda para ' 
                                  + onlyhotel.to.cityName + '. Desde el '
                                  + formatDate(onlyhotel.departureDate, 'EEEE dd MMMM', 'es-PE', 'America/Lima') + ' hasta el ' + formatDate(onlyhotel.returnDate, 'EEEE dd MMMM', 'es-PE', 'America/Lima')
                }else {
                  welcomeMessage = 'Hola Domiruth, vengo de la sección de *Búsqueda de Hoteles* de su web, me podrián asesorar'
                }
                break;
            case (currentUrl.includes('/hotel/hotel-select')):
                let habi = this.sessionService.getInfoHabi();
                if(habi != null || habi != undefined){
                welcomeMessage = 'Hola Domiruth, me podrían brindar más información sobre el hotel - ' + "*" + habi.ohotel.hotelName + " de " + habi.ohotel.cityName + " desde " + habi.ohotel.startDateShow + " al " + habi.ohotel.endDateShow + "*";
                }else {
                  welcomeMessage = 'Hola Domiruth, me podrían brindar más información sobre los hoteles'
                }
                break;
        }
      
        const wa_btnSetting = {
          "btnColor": "#16BE45",
          "ctaText": "WhatsApp",
          "cornerRadius": 40,
          "marginBottom": 20,
          "marginLeft": 20,
          "marginRight": 20,
          "btnPosition": "right",
          "whatsAppNumber": "+51965329933",
          "welcomeMessage": welcomeMessage,
          "zIndex": 1000,
          "btnColorScheme": "light"
        };
      
        const wa_widgetSetting = {
          "title": "Domiruth",
          "subTitle": "",
          "headerBackgroundColor": "#ffffff",
          "headerColorScheme": "dark",
          "greetingText": "Hola.\nTengo una consulta...",
          "ctaText": "Iniciar Chat",
          "btnColor": "#1A1A1A",
          "cornerRadius": 40,
          "welcomeMessage": "Hello",
          "btnColorScheme": "light",
          "brandImage": "https://uploads-ssl.webflow.com/5f68a65cd5188c058e27c898/6204c4267b92625c9770f687_whatsapp-chat-widget-dummy-logo.png",
          "darkHeaderColorScheme": {
            "title": "#333333",
            "subTitle": "#4F4F4F"
          }
        };
      
        const existingWidget = document.getElementById('wa-btn-wrapper');
          if (existingWidget) {
            existingWidget.remove();
          }
      
        const existingWidgetWrapper = document.getElementById('wa-widget-wrapper');
        if (existingWidgetWrapper) {
          existingWidgetWrapper.remove();
        }
      
        // Inicializa el widget de WhatsApp
        window._waEmbed(wa_btnSetting, wa_widgetSetting);
      }
    

}