import { Component, OnInit } from '@angular/core';
import { DialogFinishComponent } from '../../dialog-social-login/dialog-finish/dialog-finish.component';
import { MatDialog } from '@angular/material/dialog';
import { PackageService } from 'src/app/core/services/package.service';
import {  MatDialogRef, MatSnackBar } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute,Router } from '@angular/router';
import { AES, enc } from 'crypto-js';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-coupon-code-login',
  templateUrl: './coupon-code-login.component.html',
  styleUrls: ['./coupon-code-login.component.css'],
  providers: [ PackageService]
})
export class CouponCodeLoginComponent implements OnInit {
  correoExist;
  validcode;
  codigo;
  cunt;
  codeagain;
  numero;
  objetoDesencriptado;
  data;
  validcunt = false;
  constructor(private route: ActivatedRoute,private spinner: NgxSpinnerService,private packageService: PackageService,public dialog: MatDialog,private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.ver();
  }

  onCodeCompleted(code: string) {
    this.codigo = crypto.SHA256(code).toString() ;
    //console.log(code);
 
    if(this.objetoDesencriptado.odata.code === this.codigo){
      this.validcode = true;

    } else {
      this.validcode = false;
    }
    
  }

  ver() {
    this.route.queryParams.subscribe(params => {
      const serializedData = params['data'];
      if (serializedData) {
        let objetoDesencriptado = null;
        const bytes = AES.decrypt(serializedData, "domiruth");
        this.objetoDesencriptado = JSON.parse(bytes.toString(enc.Utf8));

      }
    });
  }

  startCountDown(seconds) {
    this.cunt = 0;
    var counter = seconds;
    var interval = setInterval(() => {
      counter--;
      this.cunt = counter;
      if (counter === 0) {
        let adas = document.getElementById("again");
        adas.style.pointerEvents = "visible";
        this.validcunt = false;
      }

    }, 1000);
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 5000,
    });
  }

  sendAgain(){
    this.spinner.show();
    this.packageService.volverEnviar(this.numero,1).subscribe(
      x=>{
        //console.log(x);
        if(x.status === 200){
          this.data = x.code;
          this.openSnackBar(x.message);
          let adas = document.getElementById("again");
          adas.style.pointerEvents = "none";
          
          this.startCountDown(60);
          this.validcunt = true;
          this.onCodeCompletedAgain();
        }
        this.spinner.hide();
      }
    )
  }

  validarcodigo(){
    if(this.validcode){

      this.openDialogExiste();
    } else {
      return;
    }
  }

  onCodeCompletedAgain() {
    if(this.data === this.codigo){
      this.validcode = true;

    } else {
      this.validcode = false;
    }
    
  }

  openDialogExiste() {

    const dialogRef = this.dialog.open(DialogFinishComponent, {
      data: '',
      panelClass: 'custom-dialog-container',
      maxWidth: 800,
    
    });

    dialogRef.afterClosed().subscribe(result => {
      /* //console.log('The dialog was closed'); */
      if (result) {
        this.correoExist = result;
      }
    });
  }

}
