import { Component, Input, OnInit } from '@angular/core';
import { FlightSummary } from './shared/flight-summary.model';
import { MatDialog } from "@angular/material/dialog";
import { DialogConditionsComponent } from '../dialog-conditions/dialog-conditions.component';

@Component({
  selector: 'dm-flight-summary',
  templateUrl: './dm-flight-summary.component.html',
  styleUrls: ['./dm-flight-summary.component.css']
})
export class DmFlightSummaryComponent implements OnInit {
  flights;
  @Input() flightSummary: FlightSummary;
  @Input() prices: any;
  @Input() regulation: any;
  @Input() condition: any;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    this.flights = this.prices[0].lairLinePricePackage;
  }

 

  openConditions() {
    const dialogRef = this.dialog.open(DialogConditionsComponent, {
      data: {
        regulations: this.regulation,
        conditions: this.condition,
      },
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }

}
