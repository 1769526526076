import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PackageService } from 'src/app/core/services/package.service';
import * as crypto from 'crypto-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogFinishComponent } from '../dialog-finish/dialog-finish.component';
import { DialogResetPasswordComponent } from '../dialog-reset-password/dialog-reset-password.component';
import { SessionService } from 'src/app/core/services/session.service';

@Component({
  selector: 'app-dialog-correo-exist',
  templateUrl: './dialog-correo-exist.component.html',
  styleUrls: ['./dialog-correo-exist.component.css'],
  providers: [PackageService]
})
export class DialogCorreoExistComponent implements OnInit {
  contra;
  validContra = false;

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    this.inciarSesion();
  }

  hide = true;
  passwordInputType: string = 'password';
  constructor(private sessionService:SessionService,public dialog: MatDialog,private spinner: NgxSpinnerService,private packageService: PackageService,
    public dialogRef: MatDialogRef<DialogCorreoExistComponent>, @Inject(MAT_DIALOG_DATA) public data: string) { }

  ngOnInit() {
  }

  

  togglePasswordVisibility() {
    this.validContra = !this.validContra;
    this.passwordInputType = this.validContra ? 'text' : 'password';
  }


  onClose() {
    this.dialogRef.close();
  }

  openDialogExiste() {
    const dialogRef = this.dialog.open(DialogFinishComponent, {
      data: "",
      panelClass: 'custom-dialog-container',
      maxWidth: 800
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openDialogReset() {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(DialogResetPasswordComponent, {
      data: "",
      panelClass: 'custom-dialog-container',
      maxWidth: 800
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }



  inciarSesion(){
    this.spinner.show();
    const data = {
      User: this.data,
      Password: crypto.SHA256(this.contra).toString(),
      AppID: 3
    }
    this.packageService.loginEmail(data).subscribe(
      x=> {
        if(x.status === 500){
          let valor = document.getElementById("contrase");
          valor.style.border = "1px solid #E2231A";
          this.validContra = true;
        } else {
          const obj = {
            userId: x.odata.userID,
            email: x.odata.email,
            nombre: x.odata.name,
            apellido: x.odata.lastName,
            phone: x.odata.phone,
            genero: x.odata.gender,
            countryCode: x.odata.countryOrigin,
            documentNumber : x.odata.documentNumber,
            typeDocument: x.odata.documentType,
            birthDate: x.odata.birthDate
          }
          this.sessionService.setPartnerClub(x.odata.isPartnerClub);
          this.sessionService.setSelected(x.odata.oenterprise.id);
          this.sessionService.setEmailFinish(obj);
          this.validContra = false;
          this.dialogRef.close();
          this.openDialogExiste();
        }
        this.spinner.hide();
      }
    )
  }

}
