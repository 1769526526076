import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FlightService } from 'src/app/core/services/flight.service';
import { FilterSearch } from 'src/app/hotels/hotel-filter-search/shared/filter-search.model';
import { SessionService } from 'src/app/core/services/session.service';
import { airportsData } from '../../services/airport.const';
import { HotelService } from 'src/app/core/services/hotel.service';

@Component({
  selector: 'app-nuevos-destinos-vh',
  templateUrl: './nuevos-destinos-vh.component.html',
  styleUrls: ['./nuevos-destinos-vh.component.css'],
  providers: [HotelService]
})
export class NuevosDestinosVhComponent implements OnInit {
  @Input() listadoDinamico: any[] = [];
  listadoDinamicoN: any[] = [];
  listadoDinamicoI: any[] = [];
  fromFilter: any[] = [];
  toFilter: any[] = [];
  dateFilter: any[] = [];
  stars;
  rpta;
  dataAirport: any;
  origins = [];
  public myObject!: { id: number, myObject: { myString: string } };
  constructor(private hotelService: HotelService,private sessionService: SessionService,private router: Router) { 
    this.dataAirport = airportsData;
  }

  ngOnInit() {
    /* this.getDynamicSearch(); */
    this.getAirports();
  }

  /* moverIzq(id_container) {
    //console.log("id_container: " + id_container);

    const container = document.getElementById(id_container);
    this.sideScroll(container, "left", 5, 200, 10);
  }*/

  getNameIsTooltip(name: string): string{
    return name.substring(0, 23) + "..."
  }

  getAirports() {
    const _origins = [];
    let result = this.dataAirport;
    if (result) {
      const airports = result.lairports ? result.lairports : [];
      const cities = result.lcities ? result.lcities : [];
      airports.forEach((airport) => {
        _origins.push({
          code: airport.iataCode,
          countryCode: airport.countryCode,
          name: airport.name,
          searchName: airport.searchName,
          priority: airport.priority,
          cityName: airport.cityName,
          /* icon: 'local_airport' */
          icon: "A",
        });
      });
      cities.forEach((city) => {
        _origins.push({
          code: city.iataCode,
          countryCode: city.countryCode,
          name: city.name,
          searchName: city.searchName,
          priority: city.priority,
          cityName: city.cityName,
          /* icon: 'location_city' */
          icon: "C",
        });
      });
      /* _origins.sort((a, b) => b.priority - a.priority); */
    }

    this.origins = _origins;
  }

  fillOrigen(valor){
    let origin;
    let origins = this.origins;
      origins.forEach(element => {
        if(element.code === valor){
          origin = element;
        }
      });
      return origin;
    
  }

  setVH(valor) {

    let origen;
    let destino;
    

    let resultado = this.origins.filter(item => item.code === valor.oorigin.iataCode);
    origen = resultado[0];
    let resultado1 = this.origins.filter(item => item.code === valor.odestination.iataCode);
    destino = resultado1[0];
    let lstRoom = [];
    const obj = {
      adults: valor.numberPassengers,
      ages: [],
      children: 0,
      passengers: []
    }
    lstRoom.push(obj);
    const objeto = {
      departureDate: valor.departureDate,
      firstItem: "1",
      from: origen,
      lBoard: "",
      code: valor.ohotelInfo.code,
      lRating: "",
      lSupplier: "",
      lTripAdvisor: "",
      lZone: "",
      HotelStars: valor.ohotelInfo.stars.toString(),
      returnDate: valor.arrivalDate,
      rooms: lstRoom,
      to: destino,
    }
    this.sessionService.setHotelFilter(objeto);
    this.sessionService.setHotelFlightDyn(true);

    let qwe = valor.odestination.cityName.replace(/' '/g,"/");

    let url = window.location.origin  + "/flight-hotel/hotel-result/" + qwe;
    this.openNewTab(url);

    /* this.router.navigate(["/flight-hotel/hotel-result", qwe]); */

  }

  openNewTab(url: string) {
    window.open(url, '_blank');
  }

  searcHotel(){
    let cuartos : any[];
    const room = {
      adults: 2,
      ages: [],
      children: 0,
      passengers: []
    }
    const ir = {
      code: null,
      countryCode: null,
      icon: null,
      name: null,
      priority: null,
      searchName: null,
    }
    cuartos.push(room);
    const filter: FilterSearch = {
      from: null,
      to: null,
      departureDate: this.rpta.departureDate,
      returnDate: this.rpta.arrivalDate,
      HotelStars: "",
      rooms: cuartos,
      lBoard: [],
      lZone: [],
      lTripAdvisor: [],
      lSupplier: [],
      lRating: [],
      firstItem: "1",
    };
    this.sessionService.setHotelFilter(filter);
    this.router.navigate(["flight-hotel/hotel-result"]);
  }

  

  sideScroll(element, direction, speed, distance, step) {
    var scrollAmount = 0;
    var slideTimer = window.setInterval(function () {
      if (direction == "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  /* moverDer(id_container) {
    //console.log("id_container: " + id_container);

    const container = document.getElementById(id_container);
    this.sideScroll(container, "right", 5, 200, 10);
  } */


  getDynamicSearch() {
    this.hotelService.getDynamicSearchHotel("VH").subscribe(
      (result: any) => {
        //console.log("result");
        //console.log(JSON.stringify(result));
        this.rpta = result;
        this.listadoDinamico = result.ldata;
      },
      (error) => {
        //console.log("ERROR: " + JSON.stringify(error));
      },
      () => {
        this.listadoDinamicoN = this.listadoDinamico.filter(
          (x) => x.flightNational == true
        );
        this.listadoDinamicoI = this.listadoDinamico.filter(
          (x) => x.flightNational == false
        );
        this.listadoDinamicoI.forEach(element => {
          if(element.ohotel.stars != null){
            element.ohotel.stars = parseFloat(element.ohotel.stars);
          }
        });
        //console.log("Complete getDynamicSearch");
      }
    );
  }

}
