import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  NgZone,
  ChangeDetectionStrategy,
} from "@angular/core";
import { Select } from "src/app/shared/models/select.model";
import { FormGroup, FormArray, FormControl, FormBuilder } from "@angular/forms";
import { PackageService } from "src/app/core/services/package.service";
import { FlightService } from "../../../core/services/flight.service";
import { FilterFlight } from "../../../flights/shared/filter-flight.model";
import { FilterFlightWrapper } from "../../../flights/shared/filter-flight-wrapper.model";
import { Filter } from "../../../flights/flight-result/shared/filter.model";
import { Options, CustomStepDefinition, LabelType } from "ng5-slider";
import { SessionService } from "src/app/core/services/session.service";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "dm-flight-filter",
  templateUrl: "./flight-filter.component.html",
  styleUrls: ["./flight-filter.component.css"],
  providers: [PackageService, FlightService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlightFilterComponent implements OnInit {
  @Input() airlines: Select[];
  @Input() minPrice: number;
  @Input() maxPrice: number;
  @Input() gds: Select[];
  @Output() changeAddFilter = new EventEmitter<Filter>();
  @Output() changeRemoveFilter = new EventEmitter<Filter>();

  originList = [];
  destinationList = [];
  travel = [];
  cabinClass: Select[] = [];
  flightTypes: Select[] = [];
  flightHours: Select[] = [];
  form: FormGroup;
  origins = [];
  origin = new Filter();
  destination = new Filter();
  filter = new FilterFlight();
  expand: boolean;

  morningControl = new FormControl("");
  nightControl = new FormControl("");

  directControl = new FormControl(true);
  oneStopControl = new FormControl(true);
  moreStopsControl = new FormControl(true);

  carryOnControl = new FormControl(false);
  checkInControl = new FormControl(false);
  filterWrapper: FilterFlightWrapper;

  ranges = [];

  min: number;
  max: number;

  list = [
    "0:00",
    "0:30",
    "1:00",
    "1:30",
    "2:00",
    "2:30",
    "3:00",
    "3:30",
    "4:00",
    "4:30",
    "5:00",
    "5:30",
    "6:00",
    "6:30",
    "7:00",
    "7:30",
    "8:00",
    "8:30",
    "9:00",
    "9:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:59",
  ];
  options: Options = {
    stepsArray: this.list.map((letter: string): CustomStepDefinition => {
      return { value: this.letterToIndex(letter) };
    }),
    translate: (value: number, label: LabelType): string => {
      return "";
    },
  };

  optionsPrice: Options = {
    floor: 0,
    ceil: 1000,
    translate: (value: number, label: LabelType): string => {
      return "$" + value;
    },
  };

  indexToLetter(index: number): string {
    return this.list[index];
  }

  letterToIndex(letter: string): number {
    return this.list.indexOf(letter);
  }

  filtroCollapse = true;

  constructor(
    private packageService: PackageService,
    private flightService: FlightService,
    private formBuilder: FormBuilder,
    private sessionService: SessionService,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    this.filterWrapper = this.sessionService.getFilterFlightWrapper();
    this.carryOnControl.setValue(this.filterWrapper.includeCarryOn);
    this.checkInControl.setValue(this.filterWrapper.includesBaggage);
    this.initForm();
    this.validPhone();
  }

  validPhone() {
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
      this.filtroCollapse = false;
    }
  }

  initForm() {
    this.flightTypes = this.flightService.getFlightTypes();
    this.flightHours = this.flightService.getFlightHours();
    price: new FormControl("0");
    this.initRanges();
    this.initPriceRange();
  }

  initRanges() {
    this.ranges = [];
    if (this.filterWrapper && this.filterWrapper.type) {
      switch (this.filterWrapper.type) {
        case FilterFlightWrapper.TYPE_ONE_WAY:
          this.ranges.push({
            title: "Ida",
            min: this.letterToIndex("0:00"),
            max: this.letterToIndex("23:59"),
          });
          break;
        case FilterFlightWrapper.TYPE_ROUND_TRIP:
          this.ranges.push({
            title: "Ida",
            min: this.letterToIndex("0:00"),
            max: this.letterToIndex("23:59"),
          });
          this.ranges.push({
            title: "Vuelta",
            min: this.letterToIndex("0:00"),
            max: this.letterToIndex("23:59"),
          });
          break;
        case FilterFlightWrapper.TYPE_MULTI:
          this.filterWrapper.fromFilter.forEach((element, index) => {
            this.ranges.push({
              title: "Tramo " + (index + 1),
              min: this.letterToIndex("0:00"),
              max: this.letterToIndex("23:59"),
            });
          });
          break;
      }
    }
  }

  initPriceRange() {
    this.min = this.minPrice;
    this.max = this.maxPrice;
    const newOptions: Options = Object.assign({}, this.optionsPrice);
    newOptions.floor = this.minPrice;
    newOptions.ceil = this.maxPrice;
    this.optionsPrice = newOptions;
  }

  showValue(value: string) {
    return this.list[value];
  }

  changeEvent(event) {
    this.clearMorning();
    this.clearNight();
    this.emitSchedule();
  }

  changeEventPrice(event) {
    let filter = new Filter();
    filter.order = Filter.PRICES;
    if (this.minPrice === this.min && this.maxPrice === this.max) {
      this.changeRemoveFilter.emit(filter);
    } else {
      filter.value = JSON.stringify({ min: this.minPrice, max: this.maxPrice });
      this.changeAddFilter.emit(filter);
    }
  }

  emitSchedule() {
    let filter = new Filter();
    filter.order = Filter.SCHEDULES;
    let value = [];
    let counter = 0;
    this.ranges.forEach((element) => {
      value.push({
        min: Number(this.list[element.min].replace(":", "")),
        max: Number(this.list[element.max].replace(":", "")),
      });
      if (
        this.indexToLetter(element.min) === "0:00" &&
        this.indexToLetter(element.max) === "23:59"
      ) {
        counter++;
      }
    });

    if (counter === this.ranges.length) {
      filter.value = "";
      this.changeRemoveFilter.emit(filter);
    } else {
      filter.value = JSON.stringify(value);
      this.changeAddFilter.emit(filter);
    }
  }

  onSelectAirlines(airlineIds: string[]) {
    let filter = new Filter();
    filter.order = Filter.AIRLINES;
    filter.value = JSON.stringify(airlineIds);
    this.changeAddFilter.emit(filter);
  }

  onSelectGds(gdsIds: string[]) {
    let filter = new Filter();
    filter.order = Filter.GDS;
    filter.value = JSON.stringify(gdsIds);
    this.changeAddFilter.emit(filter);
  }

  onMorningChange(event) {
    this.ngZone.runOutsideAngular(() => {
      if (event.checked) {
        this.clearNight();
        this.setMorning();
      } else {
        this.resetAll();
      }
      this.emitSchedule();
    });

    // if (event.checked) {
    //   this.clearNight();
    //   this.setMorning();
    // } else {
    //   this.resetAll();
    // }
    // this.emitSchedule();
  }

  onNightChange(event) {
    if (event.checked) {
      this.clearMorning();
      this.setNight();
    } else {
      this.resetAll();
    }
    this.emitSchedule();
  }

  clearNight() {
    this.nightControl.setValue(false);
  }

  clearMorning() {
    this.morningControl.setValue(false);
  }

  setMorning() {
    this.ranges.forEach((element) => {
      element.min = this.letterToIndex("5:00");
      element.max = this.letterToIndex("12:00");
    });
  }

  setNight() {
    this.ranges.forEach((element) => {
      element.min = this.letterToIndex("19:00");
      element.max = this.letterToIndex("23:59");
    });
  }

  resetAll() {
    this.ranges.forEach((element) => {
      element.min = this.letterToIndex("0:00");
      element.max = this.letterToIndex("23:59");
    });
  }

  morningOnly() {
    let filter = new Filter();
    filter.order = Filter.MORNING;
    filter.value = JSON.stringify([500, 1159]);
    this.changeAddFilter.emit(filter);
  }

  direct(event) {
    let value = "";
    if (this.directControl.value === true) {
      value += "0";
    }

    if (this.oneStopControl.value === true) {
      value += "1";
    }

    if (this.moreStopsControl.value === true) {
      value += "2";
    }

    let filter = new Filter();
    filter.order = Filter.STOPS;
    filter.value = JSON.stringify(value);

    if (value.length === 3) {
      this.changeRemoveFilter.emit(filter);
    } else {
      this.changeAddFilter.emit(filter);
    }
  }

  baggage(event) {
    let value = "";
    if (this.carryOnControl.value === true) {
      value += "0";
    }

    if (this.checkInControl.value === true) {
      value += "1";
    }
    
    let filter = new Filter();
    filter.order = Filter.BAGGAGE;
    filter.value = JSON.stringify(value);

    if (value.length === 2) {
      this.changeRemoveFilter.emit(filter);
    } else {
      this.changeAddFilter.emit(filter);
    }
  }

  filtroOcultarMostrar() {
    let filtroCollapse = this.filtroCollapse;
    if (filtroCollapse == true) {
      this.filtroCollapse = false;
    } else {
      this.filtroCollapse = true;
    }
  }
}