import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-partner',
  templateUrl: './dialog-partner.component.html',
  styleUrls: ['./dialog-partner.component.css']
})
export class DialogPartnerComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogPartnerComponent>,
              @Inject(MAT_DIALOG_DATA) public data: string,private router: Router
) { }

  ngOnInit() {
  }

  home(){
    this.dialogRef.close();
    this.router.navigate(['']);
  }

}
