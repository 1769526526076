import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { PackageService } from 'src/app/core/services/package.service';
import { SessionService } from 'src/app/core/services/session.service';

@Component({
  selector: 'app-dialog-reset-password',
  templateUrl: './dialog-reset-password.component.html',
  styleUrls: ['./dialog-reset-password.component.css'],
  providers: [PackageService]
})
export class DialogResetPasswordComponent implements OnInit {

  correoVal;
  message;
  valmessage = false;
  valEnviado = false;
  constructor(private sessionService: SessionService,private spinner: NgxSpinnerService, private packageService: PackageService, public dialog: MatDialog
    , public dialogRef: MatDialogRef<DialogResetPasswordComponent>, @Inject(MAT_DIALOG_DATA) public data: string) { }

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }

  olvidarContra(){
    this.spinner.show();
    this.packageService.resetPassword(this.correoVal).subscribe(
      x=>{
        //console.log(x);
        
       
        this.valmessage = true;
        if(x.status === 200){
          this.valEnviado = true;
        } else {
          this.valEnviado = false;
        }
        this.message = x.message;
        this.spinner.hide();
      }
    )
  }

  esEmailValido(): boolean {
    let mailValido = false;
    'use strict';
    let valid = document.getElementById("conti");
    var EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (this.correoVal.match(EMAIL_REGEX)) {
      mailValido = true;
      
      valid.style.backgroundColor = "#E82D56";
      valid.style.pointerEvents = "visible";
    } else {
      valid.style.backgroundColor = "rgba(232, 45, 86, 0.5)";
      valid.style.pointerEvents = "none";
    }
    return mailValido;
  }

}
