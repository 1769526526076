export const months = [
    {
      code: 0,
      name: 'Incluir todos los meses'
    },
    {
      code: 1,
      name: 'Enero'
    },
    {
      code: 2,
      name: 'Febrero'
    },
    {
      code: 3,
      name: 'Marzo'
    },
    {
      code: 4,
      name: 'Abril'
    },
    {
      code: 5,
      name: 'Mayo'
    },
    {
      code: 6,
      name: 'Junio'
    },
    {
      code: 7,
      name: 'Julio'
    },
    {
      code: 8,
      name: 'Agosto'
    },
    {
      code: 9,
      name: 'Setiembre'
    },
    {
      code: 10,
      name: 'Octubre'
    },
    {
      code: 11,
      name: 'Noviembre'
    },
    {
      code: 12,
      name: 'Diciembre'
    }
  ];
  