import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Select } from 'src/app/shared/models/select.model';
import { CabinClass } from '../../flights/shared/cabin-class';
import { FlightTypes } from '../../flights/shared/flight-types';
import { FlightHour } from '../../flights/shared/flight-hour';
import { FilterFlight } from '../../flights/shared/filter-flight.model';
import * as config from '../config';
import { Recommendation } from 'src/app/flights/shared/models/recommendation.model';
import { FlightAvailability } from 'src/app/flights/shared/models/flight-availability.model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';
import { NewFlightFacade } from 'src/app/shared/facade/new-flight.facade';
import { FlightResponse } from 'src/app/flights/shared/models/flights/flight-response.model';
import { Offer } from 'src/app/flights/shared/models/branded-fare/offer.model';
import { BrandedResponse } from 'src/app/flights/shared/models/flights/brandedResponse.model';
import { FlightDataResponse } from 'src/app/flights/shared/models/flights/flightDataResponse.model';
import { ResponseFlightAvailability } from 'src/app/flights/shared/models/flights/flightAvailability.model';
import { AvailabilityResponse } from 'src/app/flights/shared/models/flights/availability-response.model';

let httpOptions = {
    headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": environment.subsKey
    })
};

@Injectable({
    providedIn: "root",
})
export class FlightService {
    private renderer: Renderer2;
    private _url: string = environment.urlFlight;
    private url_confirmacion: string = environment.baseHotelUrl + 'Booking/SelectRoom';
    private url_habitacion: string = environment.baseHotelUrl + 'HotelSearch/SearchRoom';
    private url_reservation: string = environment.baseHotelUrl + 'Booking/GenerateFlightHotel';
    constructor(
        private http: HttpClient, rendererFactory: RendererFactory2,
        private loginService: LoginService
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    getCabinTypes(): Select[] {
        return CabinClass;
    }

    getFlightTypes(): Select[] {
        return FlightTypes;
    }

    getFlightHours(): Select[] {
        return FlightHour;
    }

    getDynamicSearch() {
        // httpOptions.headers = new HttpHeaders({
        //     "Content-Type": "application/json",
        //     "Ocp-Apim-Subscription-Key": environment.subsKey
        // });
        const url =
            this._url + "DynamicSearch/GetDynamicSearch";
        console.log(url);

        return this.http.get(url, httpOptions);
    }

    getDynamicSearchAirline(resultType, preferedAirli) {
        // httpOptions.headers = new HttpHeaders({
        //     "Content-Type": "application/json",
        //     "Ocp-Apim-Subscription-Key": environment.subsKey
        // });
        const url =
            this._url + "DynamicSearch/GetDynamicSearch?resultType=" + resultType + "&preferredAirline=" + preferedAirli;
        console.log(url);

        return this.http.get(url, httpOptions);
    }

    /* getDynamicSearchHotel(type) {
        // httpOptions.headers = new HttpHeaders({
        //     "Content-Type": "application/json",
        //     "Ocp-Apim-Subscription-Key": environment.subsKey
        // });
        const url =
            this._url + "DynamicSearch/GetDynamicSearch?type=" + type;
        console.log(url);

        return this.http.get(url, httpOptions);
    } */

    getPriorityAirports() {
        // httpOptions.headers = new HttpHeaders({
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,

        // });
        return this.http.get<any>(config.getPriorityAirportsUrl(config.PRIORITY_AIRPORTS), httpOptions);
    }

    loadScript(src: string): void {
        const script = this.renderer.createElement('script');
        this.renderer.setAttribute(script, 'src', src);
        this.renderer.setAttribute(script, 'async', '');
        this.renderer.appendChild(document.body, script);
    }

    loadScript1(src: string): void {
        const script = this.renderer.createElement('script');
        this.renderer.appendChild(script, this.renderer.createText(src));
        this.renderer.appendChild(document.body, script);
      }

    getAirports() {
        // httpOptions.headers = new HttpHeaders({
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,

        // });
        return this.http.get<any>(config.getPriorityAirportsUrl(config.AIRPORTS), httpOptions);
    }

    search(filter: FilterFlight) {
        var newFilter = NewFlightFacade.OldFlightFilterToNew(filter);
          let newHeaders = {...httpOptions};
        newHeaders.headers.append("Authorization", `Bearer ${this.loginService.GetToken()}`)
        return this.http.post<FlightDataResponse>(config.getNewSearchFlightUrl('Search/Search'), newFilter, newHeaders);
    }

    searchCalendar(filter: FilterFlight) {
        var newFilter = NewFlightFacade.OldFlightFilterToNew(filter);
        let newHeaders = {...httpOptions};
        newHeaders.headers.append("Authorization", `Bearer ${this.loginService.GetToken()}`)
        return this.http.post<any>(config.getNewSearchFlightUrl('Search/SearchCalendar'), newFilter, newHeaders);
    }

    /*  searchNew(filter: any) {
         return this.http.post<any[]>(config.getSearchNewFlightUrl('Search/SearchFlight'), filter);
     } */

    searchNew(data) {
        // httpOptions.headers = new HttpHeaders({
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,

        // });
        return this.http.post<any>(config.getSearchNewFlightUrl('Search/SearchFlight'), data, httpOptions);

    }


    searchHotelFlight(filter: any) {
        // httpOptions.headers = new HttpHeaders({
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,

        // });
        return this.http.post<any>(config.getSearchFlightHotelUrl('HotelSearch/GetFlightHotel'), filter, httpOptions);

    }

    searchImagesHotel(data) {
        // httpOptions.headers = new HttpHeaders({
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,

        // });
        return this.http.post<any>(config.getSearchFlightHotelUrl('HotelSearch/SearchImagesByHotel'), data, httpOptions);

    }



    getQuoteInsurance(flightAvailability) {
        // httpOptions.headers = new HttpHeaders({
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,

        // });
        return this.http.post<any>(config.getQuote('Insurance/QuoteInsurance'), flightAvailability, httpOptions);
    }

    getBenefits(flightAvailability) {
        // httpOptions.headers = new HttpHeaders({
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,

        // });
        return this.http.post<any>(config.getQuote('Insurance/GetBenefits'), flightAvailability, httpOptions);
    }


    getReservationInsurance(flightAvailability) {
        // httpOptions.headers = new HttpHeaders({
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,

        // });
        return this.http.post<any>(config.getQuote('Reservation/InsuranceReservation'), flightAvailability, httpOptions);
    }

    verifyAvailabilityHotel(flightAvailability: ResponseFlightAvailability) {
        // httpOptions.headers = new HttpHeaders({
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,

        // });
        return this.http.post<AvailabilityResponse>(config.getQuoteF('Booking/GetAvailability'), flightAvailability, httpOptions);
    }

    verifyAvailability(flightAvailability: ResponseFlightAvailability) {
     
       /*  return this.http.post<any>(config.getSearchFlightUrl('Booking/FlightAvailability'), flightAvailability, httpOptions); */

        return this.http.post<AvailabilityResponse>(config.getSearchFlightUrl('Booking/GetAvailability'), flightAvailability, httpOptions);
    }



    GetHabitacion(data): Observable<any> {
        // httpOptions.headers = new HttpHeaders({
        //     'Content-Type': "application/json",
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,

        // });
        return this.http.post<any>(`${this.url_habitacion}`, data, httpOptions);
    }

    GetConfirmacion(data): Observable<any> {
        // httpOptions.headers = new HttpHeaders({
        //     'Content-Type': "application/json",
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,

        // });
        return this.http.post<any>(`${this.url_confirmacion}`, data, httpOptions);
    }

    GetReservation(data): Observable<any> {
        // httpOptions.headers = new HttpHeaders({
        //     'Content-Type': "application/json",
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,

        // });
        return this.http.post<any>(`${this.url_reservation}`, data, httpOptions);
    }

    sendFlightOperation(flightOperation) {
     
        return this.http.post<any>(config.getSearchFlightUrl('FlightHotelTrans/GenerateTransaction'), flightOperation, httpOptions);
    }

    sendInsuranceOperation(InsuranceOperation) {
        return this.http.post<any>(config.getInsuranceOperationUrl('FlightHotelTrans/GenerateTransaction'), InsuranceOperation, httpOptions);
    }

    
    generatePnr(flightOperation) {
     
        return this.http.post<any>(config.getSearchFlightUrl('Booking/GenerateReservation'), flightOperation, httpOptions);
    }




    sendFlightOperationHotel(flightOperation) {
        // httpOptions.headers = new HttpHeaders({
        //     'Content-Type': "application/json",
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,

        // });
        return this.http.post<any>(config.getSearchHotelUrl('GeneratedTransaction/GenerateTransaction'), flightOperation, httpOptions);
    }

    sendFlightOperation1(flightOperation) {
        // httpOptions.headers = new HttpHeaders({
        //     'Content-Type': "application/json",
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,

        // });
        return this.http.post<any>(config.getSearchFlightUrl('FlightHotelTrans/FlightOperation'), flightOperation, httpOptions);
    }

    GetRegulation(regulation) {
        // httpOptions.headers = new HttpHeaders({
        //     'Content-Type': "application/json",
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,

        // });
        return this.http.post<any[]>(config.getRegulationUrl('Search/GetRegulation'), regulation, httpOptions);
    }

    GenerateFlightReservation(transactionCode) {
        // httpOptions.headers = new HttpHeaders({
        //     'Content-Type': "application/json",
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,

        // });
        return this.http.post<any>(config.getSearchFlightUrl('FlightHotelTrans/GenerateFlightReservation'), transactionCode, httpOptions);
    }

    UpdateTransaction(data) {
        // httpOptions.headers = new HttpHeaders({
        //     'Content-Type': "application/json",
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,

        // });
        return this.http.post<any>(config.getSearchFlightUrl('FlightHotelTrans/UpdateTransaction'), data, httpOptions);
    }
    GetBrandedFare(data): Observable<BrandedResponse[]> {
        let newHeaders = {...httpOptions};
        newHeaders.headers.append("Authorization", `Bearer ${this.loginService.GetToken()}`)
        return this.http.post<BrandedResponse[]>(config.getBrandedFareUrl('BrandedFare/GetBrandedFares'), data, httpOptions);
    }
}
