import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Update } from '../../models/update.model';

@Component({
  selector: 'app-dialog-price-updated',
  templateUrl: './dialog-price-updated.component.html',
  styleUrls: ['./dialog-price-updated.component.css']
})
export class DialogPriceUpdatedComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogPriceUpdatedComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Update) { }

  ngOnInit() {
    /* console.log(this.data); */
  }

  onClose() {
    this.dialogRef.close();
  }

}
