import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [  
  {
    path: 'pack',
    loadChildren: () => import('./packages/pack.module').then(m => m.PackModule),
  },
  {
    path: 'paquetes',
    loadChildren: () => import('./packages/pack.module').then(m => m.PackModule)
  },
  {
    path: 'flight-hotel',
    loadChildren: () => import('./hotels/hotel.module').then(m => m.HotelModule)
  },
  {
    path: 'vuelo-hotel',
    loadChildren: () => import('./hotels/hotel.module').then(m => m.HotelModule)
  },
  {
    path: 'hoteles',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'flight',
    loadChildren: () => import('./flights/flight.module').then(m => m.FlightModule)
  },
  {
    path: 'hotel',
    loadChildren: () => import('./only-hotel/only-hotel.module').then(m => m.OnlyHotelModule)
  },
  {
    path: 'vuelos',
    loadChildren: () => import('./flights/flight.module').then(m => m.FlightModule)
  },
  {
    path: 'seguros',
    loadChildren: () => import('./insurance/insurance.module').then(m => m.InsuranceModule)
  },
  {
    path: 'ofertas',
    loadChildren: () => import('./offers/offers.module').then(m => m.OffersModule)
  },
  {
    path: 'charters',
    loadChildren: () => import('./charters/charter.module').then(m => m.CharterModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { } 
