import { Component, OnInit, Input, Output, EventEmitter, ElementRef,Renderer2 , ViewChild, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Filter } from '../dm-autocomplete/shared/ac-filter.model';


@Component({
  selector: 'app-autocomplete-phone',
  templateUrl: './autocomplete-phone.component.html',
  styleUrls: ['./autocomplete-phone.component.css']
})
export class AutocompletePhoneComponent implements OnInit, AfterViewInit {

  indexar = 1;
  ciudadMostrada = false;
  aeropuertoMostrado = false;
  @Input() label: string;
  @Input() list: Filter[];
  @Input() bloquear;
  @Input() isPhone: any;
  @Input() autocompleteControl: FormControl;
  @Input() srcImg: string = '/assets/images/origen.svg';
  @Output() inputChange = new EventEmitter<string>();
  @Output() ocultar = new EventEmitter();
  @Output() itemSelected = new EventEmitter();
  iconosMostrados = new Set<string>();
  // srcImg = '/assets/images/origen.svg';
  // srcImg = '';

  constructor(private elementRef: ElementRef,private renderer: Renderer2) { }

  ngOnInit() {
    if (!this.autocompleteControl) {
      this.autocompleteControl = new FormControl('');
    }
    if(this.isPhone){
      this.autocompleteControl.setValue(null);
      
  
    }
    this.subscribeInput();
    if(this.bloquear === true || this.bloquear === 0){
      /* this.autocompleteControl.disable(); */
      this.list.forEach(element => {
        console.log(element);
      });
    }
    
  }

  ngAfterViewInit() {
    this.renderer.selectRootElement(this.elementRef.nativeElement.querySelector('input')).focus();
 
   }

  mostrarCiudad() {
    this.ciudadMostrada = true;
  }

  mostrarAeropuerto() {
    this.aeropuertoMostrado = true;
  }

  onInputFocus(): void {
    document.body.classList.add('no-scroll');
  }


  adjustScroll(): void {
    // Encuentra el campo de entrada que recibió el foco
    const focusedInput = this.elementRef.nativeElement.querySelector('input:focus');

    // Ajusta el desplazamiento para que el campo de entrada sea visible
    if (focusedInput) {
      focusedInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }



  subscribeInput() {
    this.autocompleteControl.valueChanges.subscribe(
      term => {
          this.inputChange.emit(term);
      }
    );
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    // const item = event.option.value as Filter;
    console.log(this.autocompleteControl);
    this.ocultar.emit();
    this.itemSelected.emit();
  }

  onOptionSelected1(valor) {
    // const item = event.option.value as Filter;
    this.autocompleteControl.setValue(valor);
    this.ocultar.emit();
    this.itemSelected.emit();
  }


  display(item?: Filter): string | undefined {
    return item ? (item.name) : undefined;
  }

  clear() {
    document.body.classList.remove('no-scroll');
    this.autocompleteControl.setValue('');
    this.itemSelected.emit();
  }

}
