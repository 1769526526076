import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode from "jwt-decode";
import { NgxSpinnerService } from 'ngx-spinner';
import { PackageService } from 'src/app/core/services/package.service';
import { SessionService } from 'src/app/core/services/session.service';
import { AES, enc } from 'crypto-js';
import * as crypto from 'crypto-js';
declare var google: any;
@Component({
  selector: 'app-coupon-email',
  templateUrl: './coupon-email.component.html',
  styleUrls: ['./coupon-email.component.css'],
  providers:[ PackageService]
})
export class CouponEmailComponent implements OnInit {
  correoVal;
  correoRegister;
  correoExist;
  datos;
  objeto : any;
  widthgoogle = 440;
  rpta;
  numero;
  data;
  userId;
  validInput = true;
  constructor(private route: ActivatedRoute,private router: Router,private sessionService: SessionService,private spinner: NgxSpinnerService,private packageService: PackageService) { }

  ngOnInit() {
    this.initialgoogle();
    this.ver();
  }

  initialgoogle() {
    google.accounts.id.initialize({
      client_id: "536885259092-r3vfm0r10kj052kmmp56om86nhln0040.apps.googleusercontent.com",

      callback: this.handleCredentialResponse
    });
    google.accounts.id.renderButton(
      document.getElementById("buttonDivs"),
      {
        logo_alignment: "center",
        type: "standard",
        shape: "rectangular",
        theme: "outline",
        text: "continue_with",
        size: "medium",

        width: 400
      }  // customization attributes
    );
  }

  esEmailValido(): boolean {
    let mailValido = false;
    'use strict';
    let valid = document.getElementById("conti");
    var EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (this.correoVal.match(EMAIL_REGEX)) {
      mailValido = true;
      
      valid.style.backgroundColor = "#E82D56";
      valid.style.pointerEvents = "visible";
    } else {
      valid.style.backgroundColor = "rgba(232, 45, 86, 0.5)";
      valid.style.pointerEvents = "none";
    }
    return mailValido;
  }

  ver() {
    this.route.queryParams.subscribe(params => {
      const serializedData = params['data'];
      if (serializedData) {
        let objetoDesencriptado = null;
        const bytes = AES.decrypt(serializedData, "domiruth");
        objetoDesencriptado = JSON.parse(bytes.toString(enc.Utf8));
        this.rpta = objetoDesencriptado;
        this.numero = objetoDesencriptado.number;
        this.data = objetoDesencriptado.data;
        this.userId = objetoDesencriptado.userID;
      }
    });
  }

  correoService() {
    var valor = this.correoVal;
    let data = valor.trim().length;
 
    this.spinner.show();
    this.packageService.validateEmail(this.correoVal,this.userId).subscribe(
      x => {
        //console.log(x);
        if(x.odata != null){
          if(x.odata.opersonVacational != null){
            const obj = {
              userId: x.odata.opersonVacational.userID,
              email: x.odata.opersonVacational.email,
              nombre: x.odata.opersonVacational.name,
              apellido: x.odata.opersonVacational.lastName,
              phone: x.odata.opersonVacational.phone,
              genero: x.odata.opersonVacational.gender,
              countryCode: x.odata.opersonVacational.countryOrigin,
              documentNumber : x.odata.opersonVacational.documentNumber,
              typeDocument: x.odata.opersonVacational.documentType,
              birthDate: x.odata.opersonVacational.birthDate
            }
            
            this.sessionService.setEmailFinish(obj);
          } else {
            sessionStorage.setItem("bygoogle",x.odata.registeredByGoogle);
          }
          
        }
        if (x.odata.existsEmail === false) {
          this.spinner.hide();
          const encryptionKey = 'domiruth';
          const objetoEncriptado = AES.encrypt(JSON.stringify(this.rpta), encryptionKey).toString();
          this.router.navigate(['auth/coupon-register'], { queryParams: { data: objetoEncriptado } });
        } else {
          if(x.registerByGoogle){
            this.spinner.hide();
      /*       this.dialogRef.close();
            this.openDialogExisteasd(); */
          } else {
            this.spinner.hide();
            const encryptionKey = 'domiruth';
          
          const objetoEncriptado = AES.encrypt(JSON.stringify(this.correoVal), encryptionKey).toString();
          this.router.navigate(['auth/coupon-email-exists'], { queryParams: { data: objetoEncriptado } });
            
            /* this.dialogRef.close();
            this.openDialogExiste(); */
          }
        
        }
      }
    )
  }

  handleCredentialResponse(response) {

    var decoded = jwt_decode(response.credential);

   
    this.datos = decoded;
    this.objeto = {
      userId: "",
      nombre: this.datos.given_name,
      apellido: this.datos.family_name,
      email: this.datos.email,
      phone: "",
      genero: "",
      countryCode: "",
      documentNumber : "",
      typeDocument: "",
      birthDate: ""
    }
    
    let codigo = '?' + response.credential;
    let valor = window.location.href + "singIn" + codigo; 
    window.location.replace(valor);
    /* this.registerGoogle(data); */
    
  }

}
