export const gender = [
    {
        code: 'M',
        name: 'Masculino'
    },
    {
        code: 'F',
        name: 'Femenino'
    }
];
