import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogDestiniesComponent } from '../dialog-destinies/dialog-destinies.component';
import { DialogRequisitosComponent } from '../dialog-requisitos/dialog-requisitos.component';
import { DialogViewContentComponent } from '../dialog-view-content/dialog-view-content.component';

@Component({
  selector: 'dm-help-info',
  templateUrl: './dm-help-info.component.html',
  styleUrls: ['./dm-help-info.component.css']
})
export class DmHelpInfoComponent implements OnInit {

  @Input() textInfo: string;
  @Input() listInformation: any;
  @Input() infoList: any;

  left: any[] = []
  right: any[] = []

  isHovered = false;
  isHovered2 = false;
  isHovered3 = false;

  

  constructor(private dialog: MatDialog,) { }

  ngOnInit() {
    this.loadData();
  }
  
  loadData() {
    if(this.infoList != null){
      if(this.infoList.length > 0){
        this.infoList.forEach((element) => {
          if (element.include === true){
            this.left.push(element);
          }else {
            this.right.push(element);
          }
        });
      }
    }
    
  }

  openInfo() {
    const dialogRef = this.dialog.open(DialogViewContentComponent, {data:this.listInformation});
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openToVisit() {
    const dialogRef = this.dialog.open(DialogDestiniesComponent, {data:this.listInformation});
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openTouristPlaces() {
    const dialogRef = this.dialog.open(DialogRequisitosComponent, {data:this.listInformation});
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
