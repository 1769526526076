import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { LoginAccessComponent } from './login-access/login-access.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    this.showDialogNotification();
  }


  showDialogNotification() {
    const dialogRef = this.dialog.open(LoginAccessComponent, {
      data: "",
      disableClose: true,
      panelClass: 'custom-dialog-container',
      maxWidth: 800
    });


    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
