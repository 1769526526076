import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatChipList, MatDatepickerInputEvent } from '@angular/material';
import { MatSnackBar } from "@angular/material/snack-bar";
import * as utils from '../../utils';
import { formatDate } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { SessionService } from 'src/app/core/services/session.service';
import { BsCustomDates } from 'ngx-bootstrap/datepicker/themes/bs/bs-custom-dates-view.component';
import { DeparturePackage } from '../../models/departure-package.model';

@Component({
  selector: 'dm-payment-booking-package',
  templateUrl: './dm-payment-booking-package.component.html',
  styleUrls: ['./dm-payment-booking-package.component.css']
})
export class DmPaymentBookingPackageComponent implements OnInit {

  @Input() passengers: number;
  @Input() price: number;
  @Input() validCalendar: any;
  @Input() validDaily: any;
  @Input() localPrice: number;
  @Input() currencyRate: number;
  @Input() rangoDias: any;
  @Input() minDate: any;
  @Input() maxDate: any;
  @Input() departures: DeparturePackage[];
  @Output() selectdate = new EventEmitter();
  @Output() enviarData = new EventEmitter();
  @Output() selectschedule = new EventEmitter();
  @Input() departureControl: any;
  packDiscount = {
    isApplyQuota: false,
    quotaDoubleDolar: 0,
    quotaDoubleSoles: 0,
    quotaNumber: 0,
    quotaBank: ["bcp", "bbva", "banbif"],
    width: 50,
    height: 21
  };
  seasonPrice = "";
  beginDate = new FormControl('');
  bsInvalidDate: Date = new Date();
  endDate = new FormControl('');
  minCalendar = new Date();
  myForm?: FormGroup;
  valorDepar = 1;
  fechaInitial: any;
  viewrange: boolean;
  fechaFin: any;
  fechainiselect: any;
  fechafiniselect: any;
  fechaida: any;
  fechavuelta: any;
  schedules: any;
  dpSchedule: any;
  viewschedules: boolean = false;
  @ViewChild('chipList', { static: false }) chipList: MatChipList; // Obtén una referencia al chip list
  selectedChipId;
  disabledDates: Date[];
  minDateDaily: Date;
  maxDateDaily: Date;
  dailyString: string = "";
  selected;
  bsConfig;
  @ViewChild('datePickerInput', { static: false }) datePickerInput: ElementRef;

  
  constructor( private formBuilder: FormBuilder,    private snackBar: MatSnackBar, private sessionService: SessionService) { }

  ngOnInit() {
    this.myForm = this.formBuilder.group({
      range: null
    });
    /* console.log(this.departureControl) */
    if(this.departures != null){
      if(!this.validCalendar && !this.validDaily){
          if(this.departures[0].ldepartureSchedule != null && this.departures[0].ldepartureSchedule != undefined){
            this.viewrange = true;
            if(this.departures[0].ldepartureSchedule.length > 0){
              this.departureControl = this.departures[0].id
              this.schedules = this.departures[0].ldepartureSchedule;
              this.viewschedules = true;
              this.selectedChipId = this.departures[0].ldepartureSchedule[0].id;
              /* this.selectdate.emit(this.departureControl); */
              let event = {
                value: 0,
              }
              event.value = this.departureControl;
              this.onSelectionChange(event)
              /* this.onSelectionChange5(event); */
            }else {
              this.viewrange = false;
              this.departureControl = this.departures[0].id;
              this.selectdate.emit(this.departureControl);
            }
          }else {
            this.viewrange = false;
            this.departureControl = this.departures[0].id;
            this.selectdate.emit(this.departureControl);
          }

      }else if(this.validCalendar && !this.validDaily){
            this.viewrange = false;
            this.departureControl = this.departures[0].id;
            /* this.selectdate.emit(this.departureControl); */
            this.onSelectionChange2();
      }else if(!this.validCalendar && this.validDaily){
        this.departureControl = this.departures[0].id
        this.schedules = this.departures[0].ldepartureSchedule;
        this.viewschedules = true;
        this.selectedChipId = this.departures[0].ldepartureSchedule[0].id;
        /* this.selectdate.emit(this.departureControl); */
        this.minDateDaily = new Date(this.departures[0].ldepartureSchedule[0].beginDate);
        this.maxDateDaily = new Date (this.departures[0].ldepartureSchedule[0].endDate);
        let event = {
          value: 0,
        }
        event.value = this.departureControl;
        /* this.onSelectionChange4(event); */
        this.onSelectionChange3(event);
        /* this.onChipDailyClick(this.selectedChipId); */
        let departureDaily: string[] = this.departures[0].ldepartureSchedule[0].departureDaily.split('-');
        let dailySchedule: number[] = this.getDailyDays(departureDaily);
        this.dailyString = this.getDailyDaysString(departureDaily);
        this.disabledDates = this.getDisabledDates(dailySchedule);
      }
      
    }
    this.minCalendar.setDate(this.minCalendar.getDate() + 1);
  }

  getDailyDays(departureDaily: string[]) : number[] {
    let dailySchedule: number[] = [];
        departureDaily.forEach(x => {
          switch(x){
            case "L":
              dailySchedule.push(1);
              break;
            case "MA":
              dailySchedule.push(2);
              break;
            case "MI":
              dailySchedule.push(3);
              break;
            case "J":
              dailySchedule.push(4);
              break;
            case "V":
              dailySchedule.push(5);
              break;
            case "S":
              dailySchedule.push(6);
              break;
            case "D":
              dailySchedule.push(0);
              break;
          }
        })
        return dailySchedule;
  }

  getDailyDaysString(departureDaily: string[]) :  string {
    let dailystring: string = '';
    if(departureDaily.length < 7){
    departureDaily.forEach(x => {
      switch(x){
        case "L":
          dailystring += "Lunes - ";
          break;
        case "MA":
          dailystring += "Martes - ";
          break;
        case "MI":
          dailystring += "Miércoles - ";
          break;
        case "J":
          dailystring += "Jueves - ";
          break;
        case "V":
          dailystring += "Viernes - ";
          break;
        case "S":
          dailystring += "Sábado - ";
          break;
        case "D":
          dailystring += "Domingo - ";
          break;
      }
    });
    let quitar = dailystring.substring(0, dailystring.length -3)
    return quitar;
  }else {
    return "Salidas Diarias";
  }

    
  }

  getDisabledDates(dailySchedule: number[]): Date[] {
    const dates: Date[] = [];
    const startDate = new Date(this.minDateDaily);
    const endDate = new Date(this.maxDateDaily);

    for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
      const day = d.getDay();
      let findschedule: any = null; 
      findschedule = dailySchedule.find(x => x === day);
      if(findschedule === null || findschedule === undefined){
        dates.push(new Date(d));
      }
      /* dailySchedule.forEach(daily => {
        if(day === daily || day === daily){
          dates.push(new Date(d));
        }
      }) */
      /* if (day !== 1 && day !== 3 && day !== 5) {  // Excluye lunes, miércoles y viernes
        dates.push(new Date(d));
      } */
    }
    return dates;
  }

  enviar(){
    let obj = {
      fechaInicial: null,
      fechaFinal: null
    }
    if(!this.validCalendar){
      if(this.fechaInitial === undefined || this.fechaFin === undefined){
        this.openSnackBar(`Por favor seleccione un rango de dias`);
        return;
      } else {
        obj.fechaInicial = this.fechaInitial;
        obj.fechaFinal = this.fechaFin;
      }
    }/* else{
      obj.fechaInicial = this.fechaInitial;
      obj.fechaFinal = this.fechaFin; 
    } */
    this.enviarData.emit(obj);
  }

  onSelectionChange(event: any){
    /* console.log("holaqweqweqew") */
    let departure: DeparturePackage;
    departure = this.departures.find(x => x.id === event.value);
    this.getDiscountPackage(departure);
    this.seasonPrice = departure.seasonPrice;
    if(departure.ldepartureSchedule != null){
      this.schedules = departure.ldepartureSchedule;
      this.viewschedules = true;
      event.value = departure.ldepartureSchedule[0].id;
      this.onSelectionChange5(event);
    }
    this.selectdate.emit(this.departureControl);

  }

  onSelectionChange2(){
    /* console.log("holaqweqweqew") */
    let discountdp: DeparturePackage;
    discountdp = this.departures.find(x => x.id === this.departureControl);
    this.getDiscountPackage(discountdp);
    this.seasonPrice = discountdp.seasonPrice;
    this.selectdate.emit(this.departureControl);

  }

  getDiscountPackage(data: DeparturePackage) {
    this.packDiscount.isApplyQuota = data.isApplyQuota;
    this.packDiscount.quotaDoubleDolar = data.quotaDoubleDolar;
    this.packDiscount.quotaDoubleSoles = data.quotaDoubleSoles;
    this.packDiscount.quotaNumber = data.quotaNumber;
    if(data.quotaBank != null){
      this.packDiscount.quotaBank = data.quotaBank.split('-');
      switch(this.packDiscount.quotaBank.length){
        case 1:
          this.packDiscount.width = 100;
          this.packDiscount.height = 29;
          break;
        case 2:
          this.packDiscount.width = 70;
          this.packDiscount.height = 25;
          break;
        case 3:
          this.packDiscount.width = 50;
          this.packDiscount.height = 21;
          break;
      }
      
    }else{
      this.packDiscount.quotaBank = ["bcp", "bbva", "banbif"];
      this.packDiscount.width = 50;
      this.packDiscount.height = 21;
    }
  }

  onSelectionChange3(event: any) {
    let departure: DeparturePackage;
    departure = this.departures.find(x => x.id === event.value);
    this.getDiscountPackage(departure);
    if(departure.ldepartureSchedule != null) {
      this.rangoDias = departure.rangeDays;
      this.seasonPrice = departure.seasonPrice;
      this.schedules = departure.ldepartureSchedule;
      this.viewschedules = true;
      /* this.onChipDailyClick(departure.ldepartureSchedule[0].id);
      let event = {
        value: 0,
      } */
      event.value = departure.ldepartureSchedule[0].id;
      this.onSelectionChange4(event)
    }
    this.selectdate.emit(this.departureControl);
  }

  cuotaWs() {
    let name = this.sessionService.getNameUrl();
    let qwe = "Hola quiero acceder al beneficio de pago en cuotas del paquete - " + name;
    let url = "https://api.whatsapp.com/send?phone=+51965329933&text=" + qwe;
    window.open(url, '_blank');
  }

  openDatePicker() {
    if (this.datePickerInput) {
      const element = this.datePickerInput.nativeElement;
      element.style.display = 'block';  // Mostrar el input antes de hacer clic
      element.style.position = "absolute";
      element.style.height = "50px"
      element.click();
      
    }
  }

  onSelectionChange4(event: any) {
    if (event.value != null) {
      this.selectedChipId = event.value;
      this.selected = event.value;
      this.departures.forEach(element => {
        if(element.ldepartureSchedule != null){
          element.ldepartureSchedule.forEach(schedule => {
            if(schedule.id === event.value){
              /* this.fechaInitial = schedule.beginDate;
              this.fechaFin = schedule.endDate; */
              this.bsInvalidDate = new Date();
              this.minDateDaily = new Date(schedule.beginDate);
              this.maxDateDaily = new Date(schedule.endDate);
              let departureDaily: string[] = schedule.departureDaily.split('-');
              let dailySchedule: number[] = this.getDailyDays(departureDaily);
              this.dailyString = this.getDailyDaysString(departureDaily);
              this.disabledDates = this.getDisabledDates(dailySchedule);
              this.selectschedule.emit(schedule.id);
            }
          });
        }
      });
    }
  }

  formatFecha(fecha1) {
    let dateString = null;
    if (fecha1 != undefined) {
      const year = fecha1.getFullYear();
      const month = ('0' + (fecha1.getMonth() + 1)).slice(-2);
      const day = ('0' + fecha1.getDate()).slice(-2);
      dateString = `${year}-${month}-${day}T00:00:00`;
    }


    return dateString;
  }

  onFinDateChange(event: MatDatepickerInputEvent<Date>) {
    if (event[0] != undefined) {
      let rango = this.rangoDias;
      this.fechaInitial = this.formatFecha(event[0]);
      this.fechaFin = this.formatFecha(event[1]);
      const diferencia = event[1].getTime() - event[0].getTime();
      const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24)) + 1;
      if (dias > rango || dias < rango) {
        this.bsInvalidDate = new Date();
        this.fechaInitial = undefined;
        this.fechaFin = undefined;
        this.fechaida = null;
        this.fechavuelta = null;
        this.openSnackBar(`El rango a escoger es de ${rango} dias`)
      }else{
        this.fechafiniselect = this.formatearFecha(this.fechaInitial, 'EEE dd MMM') + " al " + this.formatearFecha(this.fechaFin, 'EEE dd MMM y');
        this.fechaida = this.formatearFecha(this.fechaInitial,'EEE dd MMM');
        this.fechavuelta = this.formatearFecha(this.fechaFin, 'EEE dd MMM');
      }
    }else{
      this.fechaInitial = undefined;
      this.fechaFin = undefined
      this.fechafiniselect = null;
      this.fechaida = null;
      this.fechavuelta = null
    }

  }

  /* bsConfig:Partial<BsDatepickerConfig> = {
    containerClass: 'theme-default',
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      selectFromOtherMonth: true,
      isAnimated: true,
      customTodayClass: 'custom-today-class',
      maxDateRange: this.rangoDias
  } */

      ranges:Partial<BsCustomDates>[] = [
        
      ]

  onFinDateChange1(event: any) {
    const valorseleccionado = event.target.value;
        if (valorseleccionado != null) {
        const valselect = parseInt(valorseleccionado);
        const val = this.departures[0].ldepartureSchedule.find(x => x.id === valselect);
        let rango = this.rangoDias;
        this.fechaInitial = val.beginDate;
        this.fechaFin = val.endDate;
        let idSchedule = parseInt(val.id);
        this.selectschedule.emit(idSchedule);
      }
    }

    formatearFecha(fecha: string, format: string): string {
      const fechaini = new Date(fecha);
      return formatDate(fechaini, format, 'es-PE', 'America/Lima');
    }

  onSelectionChange5(event: any){
    if (event.value != null) {
      this.selectedChipId = event.value;
      this.selected = event.value;
      this.departures.forEach(element => {
        if(element.ldepartureSchedule != null){
          element.ldepartureSchedule.forEach(schedule => {
            if(schedule.id === event.value ){
              this.fechaInitial = schedule.beginDate;
              this.fechaFin = schedule.endDate;
              this.fechafiniselect = schedule.format;
              this.selectschedule.emit(schedule.id);
            }
          });
        }
      });
    }
  }

  onChipClick(format: any) {
        if (format != null) {
        this.selectedChipId = format;
        this.departures.forEach(element => {
          if(element.ldepartureSchedule != null){
            element.ldepartureSchedule.forEach(schedule => {
              if(schedule.id === format){
                this.fechaInitial = schedule.beginDate;
                this.fechaFin = schedule.endDate;
                this.fechafiniselect = schedule.format;
                this.selectschedule.emit(schedule.id);
              }
            });
          }
        });
      }
  
    }

    onChipDailyClick(format: any) {
      if (format != null) {
      this.selectedChipId = format;
      this.departures.forEach(element => {
        if(element.ldepartureSchedule != null){
          element.ldepartureSchedule.forEach(schedule => {
            if(schedule.id === format){
              /* this.fechaInitial = schedule.beginDate;
              this.fechaFin = schedule.endDate; */
              this.bsInvalidDate = new Date();
              this.minDateDaily = new Date(schedule.beginDate);
              this.maxDateDaily = new Date(schedule.endDate);
              let departureDaily: string[] = schedule.departureDaily.split('-');
              let dailySchedule: number[] = this.getDailyDays(departureDaily);
              this.dailyString = this.getDailyDaysString(departureDaily);
              this.disabledDates = this.getDisabledDates(dailySchedule);
              this.selectschedule.emit(schedule.id);
            }
          });
        }
      });
    }

  }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 2000,
    });
  }

  onBeginDateChange(event: MatDatepickerInputEvent<Date>) {
  }
}
