import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogDetailComponent } from '../dialog-detail/dialog-detail.component';

export interface Conditions {
  regulations: string;
  conditions: string;
}

@Component({
  selector: 'app-dialog-conditions',
  templateUrl: './dialog-conditions.component.html',
  styleUrls: ['./dialog-conditions.component.css']
})
export class DialogConditionsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Conditions
  ) { }

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }

}
