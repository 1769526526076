import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SessionService } from 'src/app/core/services/session.service';
import { DialogSocialLoginComponent } from '../../auth/dialog-social-login/dialog-social-login.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  charter;
  validSesion = true;

  constructor(public dialog: MatDialog,private sessionService: SessionService) {

    this.charter = this.sessionService.getHeader();
   }

  ngOnInit() {
    this.validSesion = this.sessionService.getIniciarSesion();
  }

  signOut() {
    this.validSesion = true;
    this.sessionService.setIniciarSesion(true);
    window.location.reload();
  }

}
