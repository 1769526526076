import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, throwToolbarMixedModesError } from '@angular/material';
import { PackageService } from 'src/app/core/services/package.service';
import { DialogFinishComponent } from '../dialog-finish/dialog-finish.component';
import * as crypto from 'crypto-js';
import { DialogConfirmPhoneComponent } from '../dialog-confirm-phone/dialog-confirm-phone.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionService } from 'src/app/core/services/session.service';
import { DialogSocialLogComponent } from '../dialog-social-log/dialog-social-log.component';

@Component({
  selector: 'app-dialog-register-code',
  templateUrl: './dialog-register-code.component.html',
  styleUrls: ['./dialog-register-code.component.css'],
  providers: [PackageService]
})
export class DialogRegisterCodeComponent implements OnInit {
  finish;
  nombreVal = "";
  apellidoVal = "";
  numDoc = "";
  documentType;
  typeVal = "F3F05B20-412E-4A1A-BA31-B69B1E6D0392";
  personId;
  fecNac = "";
  gender = "M";
  textcontra = "";
  correoVal = "";
  validarInputs = true;
  valordoc;
  validDoc = true;
  contraVal = "";
  codeInv = "";
  sendMax;
  constructor(private sessionService: SessionService, private snackBar: MatSnackBar, private spinner: NgxSpinnerService, private packageService: PackageService, public dialog: MatDialog
    , public dialogRef: MatDialogRef<DialogRegisterCodeComponent>, @Inject(MAT_DIALOG_DATA) public data: string) { }

  ngOnInit() {
    this.codeInv = this.data;
    this.document();
    let valor = this.sessionService.getCorreo();
    if (valor != null) {
      this.correoVal = valor;
    }
    
  }

  convMayus(event: any){
    this.codeInv = event.target.value.toUpperCase();
  }

  esEmailValido(): boolean {
    let mailValido = false;
    'use strict';
    let valid = document.getElementById("conti");
    var EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (this.correoVal.match(EMAIL_REGEX)) {
      mailValido = true;

      valid.style.backgroundColor = "#E82D56";
      valid.style.pointerEvents = "visible";
    } else {
      valid.style.backgroundColor = "rgba(232, 45, 86, 0.5)";
      valid.style.pointerEvents = "none";
    }
    return mailValido;
  }

  Solotexto(event) {
    var regex = new RegExp("^[a-zA-Z ]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }

  ValidarSoloNumero(event) {
    // tslint:disable-next-line: max-line-length
    if (this.typeVal === "F3F05B20-412E-4A1A-BA31-B69B1E6D0392") {
      if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105) && event.keyCode !== 190 && event.keyCode !== 110 && event.keyCode !== 8 && event.keyCode !== 9) {
        return false;
      }
    } else {
      var regex = new RegExp("^[a-zA-Z1-9 ]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        if (event.code != "Backspace") {
          event.preventDefault();
          return false;
        }

      }
    }

  }

  setIdDoc() {
    //console.log(this.typeVal);
    this.valordoc = document.getElementById("nroDoc");
    this.valordoc.value = "";
    if (this.typeVal === "F3F05B20-412E-4A1A-BA31-B69B1E6D0392") {
      this.valordoc.maxLength = 8;
    } else {
      this.valordoc.maxLength = 12;
    }
  }

  document() {
    this.packageService.getDocumentList(false).subscribe(
      result => {
        this.documentType = result.ldocumentTypeLists;
      },
      err => {

      },
      () => {

      }
    );
  }

  getDocumentType() {
    this.packageService.getDocumentType().subscribe(
      x => {
        if (x.confirmation && x.data) {
          this.documentType = x.data;
        }
      },
      err => {

      },
      () => {

      }
    );
  }

  onClose() {
    this.dialogRef.close();
  }

  validpassword() {
    let stilo = document.getElementById("contra");
    let texta = document.getElementById("textoContra");
    var strongRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");
    var mediumRegex = new RegExp("^(?=.{7,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$", "g");
    var enoughRegex = new RegExp("(?=.{6,}).*", "g");
    if (false == enoughRegex.test(this.contraVal)) {
      this.textcontra = "Baja";
      texta.style.color = "#E2231A";
      stilo.style.border = "1px solid #E2231A";
    } else if (strongRegex.test(this.contraVal)) {
      this.textcontra = "Muy alto";
      texta.style.color = "#44C851";
      stilo.style.border = "1px solid #44C851";
    } else if (mediumRegex.test(this.contraVal)) {
      this.textcontra = "Media";
      texta.style.color = "#FFC107";
      stilo.style.border = "1px solid #FFC107";
    } else {
      this.textcontra = "Baja";
      texta.style.color = "#E2231A";
      stilo.style.border = "1px solid #E2231A";
    }
    return true;
  }



  valid() {
    this.registerUser();
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 5000,
    });
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  registerUser() {
    if (this.nombreVal === "" || this.apellidoVal === "" || this.typeVal === "" || this.numDoc === "" || this.fecNac === "" || this.contraVal === "" || this.correoVal === "") {
      this.openSnackBar("Por favor rellene todos los campos");
      return;
    }
    if (this.textcontra != "Muy alto") {
      this.openSnackBar("Por favor siga las instrucciones al crear contraseña.");
      return;
    }
    let numDocLegn = this.numDoc.length;
    if (numDocLegn < 8) {
      this.openSnackBar("Por favor completar numero de documento.");
      return;
    }

    this.spinner.show();

    let lstDoc = [];

    let docs = {
      DocumentID: this.typeVal,
      Number: this.numDoc,
      IsActive: true
    }

    lstDoc.push(docs);

    let goog: any = sessionStorage.getItem("bygoogle")
    if(goog === 'false'){
      goog = true;
    } else {
      goog = false;
    }

    

    let obj = {
      IsRegister: true,
      Name: this.nombreVal,
      LastName: this.apellidoVal,
      PersonalPhone: "",
      PersonalEmail: this.correoVal,
      BirthDate: this.fecNac,
      Gender: this.gender,
      CountryOrigin: "PE",
      InvitationCode: this.codeInv,
      ByGoogle: goog,
      IsVacational: true,
      LpersonDocuments: lstDoc,
      Ouser: {
        Password: crypto.SHA256(this.contraVal).toString(),
        AccessGranted: true,
        IsActive: true
      }
    }

    this.packageService.sendRegister(obj).subscribe(
      x => {
        if (x.odata != null) {
          const obj = {
            userId: x.odata.userID,
            email: x.odata.email,
            nombre: x.odata.name,
            apellido: x.odata.lastName,
            phone: x.odata.phone,
            genero: x.odata.gender,
            countryCode: x.odata.countryOrigin,
            documentNumber: x.odata.lpersonDocument[0].documentNumber,
            typeDocument: x.odata.lpersonDocument[0].documentID,
            birthDate: x.odata.birthDate
          }
          this.sessionService.setEmailFinish(obj);
        }
        if (x.status === 200) {
          this.personId = x.odata.personID;

          this.openDialogs();
        } else {
          this.openSnackBar(x.message);
        }
        this.spinner.hide();

      }
    )

  }

  registerUserPhone() {
    if (this.nombreVal === "" || this.apellidoVal === "" || this.typeVal === "" || this.numDoc === "" || this.fecNac === "" || this.contraVal === "" || this.correoVal === "") {
      this.openSnackBar("Por favor rellene todos los campos");
      return;
    }
    if (this.textcontra != "Muy alto") {
      this.openSnackBar("Por favor mejor su nivel de seguridad a nivel alto.");
      return;
    }
    let numDocLegn = this.numDoc.length;
    if (numDocLegn < 8) {
      this.openSnackBar("Por favor completar numero de documento.");
      return;
    }

    this.spinner.show();




    let lstDoc = [];

    let docs = {
      DocumentID: this.typeVal,
      Number: this.numDoc,
      IsActive: true
    }

    lstDoc.push(docs);

    let goog: any = sessionStorage.getItem("bygoogle")
    if(goog === 'false'){
      goog = true;
    } else {
      goog = false;
    }

  

    let obj = {
      IsRegister: true,
      Name: this.nombreVal,
      LastName: this.apellidoVal,
      PersonalPhone: "",
      PersonalEmail: this.correoVal,
      BirthDate: this.fecNac,
      Gender: this.gender,
      CountryOrigin: "PE",
      InvitationCode: this.codeInv,
      ByGoogle:goog,
      IsVacational: true,
      LpersonDocuments: lstDoc,
      Ouser: {
        Password: crypto.SHA256(this.contraVal).toString(),
        AccessGranted: true,
        IsActive: true
      }
    }
    this.packageService.sendRegister(obj).subscribe(
      x => {
        if (x.odata != null) {
          const obj = {
            userId: x.odata.userID,
            email: x.odata.email,
            nombre: x.odata.name,
            apellido: x.odata.lastName,
            phone: x.odata.phone,
            genero: x.odata.gender,
            countryCode: x.odata.countryOrigin,
            documentNumber: x.odata.lpersonDocument[0].documentNumber,
            typeDocument: x.odata.lpersonDocument[0].documentID,
            birthDate: x.odata.birthDate
          }
          this.sessionService.setEmailFinish(obj);
        }
        if (x.status === 200) {
          this.personId = x.odata.personID;

          this.isTrue();
        } else {
          this.openSnackBar(x.message);
        }
        this.spinner.hide();

      }
    )

  }

  isTrue() {
    this.spinner.show();
    this.packageService.LoginAccessByPhone(this.data).subscribe(
      x => {
        //console.log(x);
        if (x.status != 200) {
          this.openSnackBar(x.message);
        } else {
          this.dialogRef.close();
          const obj = {
            userId: x.odata.userID,
            email: x.odata.email,
            nombre: x.odata.name,
            apellido: x.odata.lastName,
            phone: x.odata.phone,
            genero: x.odata.gender,
            countryCode: x.odata.countryOrigin,
            documentNumber: x.odata.lpersonDocument[0].documentNumber,
            typeDocument: x.odata.lpersonDocument[0].documentID,
            birthDate: x.odata.birthDate
          }
          this.sessionService.setPartnerClub(x.odata.isPartnerClub);
          this.sessionService.setSelected(x.odata.oenterprise.id);
          this.sessionService.setEmailFinish(obj);
          this.openDialog();
        }
        this.spinner.hide();
      }
    )
  }

  openDialogs() {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(DialogSocialLogComponent, {
      data: this.personId,
      panelClass: 'custom-dialog-container',
      maxWidth: 800

    });

    dialogRef.afterClosed().subscribe(result => {
      /* //console.log('The dialog was closed'); */
      if (result) {
        this.finish = result;
      }
    });
  }

  openDialog() {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(DialogFinishComponent, {
      data: '',
      panelClass: 'custom-dialog-container',
      maxWidth: 800

    });

    dialogRef.afterClosed().subscribe(result => {
      /* //console.log('The dialog was closed'); */
      if (result) {
        this.finish = result;
      }
    });
  }

}