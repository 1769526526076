import { Passenger } from 'src/app/shared/models/passenger.model';
import { Destination } from './destination.model';
import { FilterPassenger } from 'src/app/shared/models/filter-passenger.model';

export class FilterFlight {
    lpassenger: FilterPassenger[];
    numberRecommendations: string;
    cabinType: string;
    scales: string;
    Ouser: any;
    includesBaggage: boolean;
    RedisToken: string;
    origin: string[];
    destination: string[];
    departureArrivalDate: string[];
    departureArrivalTimeFrom: string[];
    departureArrivalTimeTo: string[];
    PartnerClub: boolean;
    selected: any;
    Ocompany: any;
    type: string;

    //nuevo rq

    dates: string[];
    timeFrom: null | any;
    timeTo: null | any;
    numberOfRecomendations: number;
    includeBaggage: boolean;
    includeCarryOn: boolean;
    typeSearch: string;
    luser: null | any;
    oenterprise: null | any;
}

