import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../models/dialog-data.model';
import { PackageService } from '../../../core/services/package.service';
import { FlightItinerary } from 'src/app/packages/shared/models/flight-itinerary.model';

@Component({
  selector: 'app-dialog-itinerary',
  templateUrl: './dialog-itinerary.component.html',
  styleUrls: ['./dialog-itinerary.component.css'],
  providers: [ PackageService ]
})
export class DialogItineraryComponent implements OnInit {

  flightItineraries: FlightItinerary[];
  loading: boolean;

  constructor(
    public dialogRef: MatDialogRef<DialogItineraryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private packageService: PackageService) { }

  ngOnInit() {
  /*   console.log('init', this.data); */
    this.getFlightItineraries();
  }

  getFlightItineraries(){
    this.loading = true;
    this.packageService.getFlightItineraries(this.data.id).subscribe(
      x => {
        if (x.confirmation){
          this.flightItineraries = x.data;
        }
      },
      err => { /* console.log('Error: ' + err); */ },
      () => {
        this.loading = false;
      }
    );
  }

  onClose() {
    this.dialogRef.close();
  }

}
