import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CipDataError } from 'src/app/packages/shared/models/cip-data-error.model';

@Component({
  selector: 'app-dialog-cip-notification',
  templateUrl: './dialog-cip-notification.component.html',
  styleUrls: ['./dialog-cip-notification.component.css']
})
export class DialogCipNotificationComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogCipNotificationComponent>,
              @Inject(MAT_DIALOG_DATA) public info: CipDataError,
) { }

ngOnInit() {
}

onClose() {
this.dialogRef.close();
}

}
