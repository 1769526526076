import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { SessionService } from 'src/app/core/services/session.service';

@Component({
  selector: 'app-dialog-finish-password',
  templateUrl: './dialog-finish-password.component.html',
  styleUrls: ['./dialog-finish-password.component.css']
})
export class DialogFinishPasswordComponent implements OnInit {

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    this.onClose();
  }

  constructor(private sessionService: SessionService, private snackBar: MatSnackBar, 
    public dialog: MatDialog
    , public dialogRef: MatDialogRef<DialogFinishPasswordComponent>, @Inject(MAT_DIALOG_DATA) public data: string) { }


 ngOnInit() {
    /* this.sessionService.setIniciarSesion(false); */
  }



  onClose() {
    this.dialogRef.close();
    let valor = document.location.origin;
    let enviar = valor + "/";
    document.location.replace(enviar);
    /* this.sessionService.setIniciarSesion(false); */
    /* document.location.reload(); */
  }

}
