import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { FilterSearch } from "../../only-hotel/hotel-search/shared/filter.search.model";
import * as utils from '../../shared/utils';
import * as config from 'src/app/core/config'
import { FilterPassenger } from "src/app/shared/models/filter-passenger.model";
import { SessionService } from "src/app/core/services/session.service";
/* import { config } from "process"; */

let httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': environment.subsKey,
    })
};

@Injectable()
export class OnlyHotelService {
    constructor(private http: HttpClient,private sessionService: SessionService) {}

    searchHotelList(filter: any) {
        const rooms = [];
        const passengers = [];

        // httpOptions.headers = new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,
      
        //   });


        passengers.push({
            TypePassenger: 'ADT',
            RoomQuantity: filter.rooms,
            NumberPassengers: filter.passengers
        });

        const body = {
            Lusers: null,
            TypeSearch: 'V',
            LHotel: [
                {
                    HotelCityCode: filter.to.code,
                    Stars: filter.stars,
                    StartDate: utils.formatyyyymmddpare(filter.departureDate.toString()),
                    EndDate: utils.formatyyyymmddpare(filter.returnDate.toString()),
                    LguestPerRoom: passengers,
                }
            ],
           Ocompany: null
        };
   /*      return; */
        return this.http.post<any>(config.getSearchListHotel('HotelSearch/SearchHotel'), body, httpOptions);
    }

    searchHotelListHabitacion2(filter) {
       
        // httpOptions.headers = new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,
      
        //   });


        
        return this.http.post<any>(config.getSearchListHotel('HotelSearch/SearchRoom'), filter, httpOptions);
    }

    searchHotelListHabitacion(filter) {
        let data = this.sessionService.getOnlyHotel();
        const rooms = [];
        const passengers = [];

        // httpOptions.headers = new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,
      
        //   });


        passengers.push({
            TypePassenger: 'ADT',
            RoomQuantity: filter.rooms,
            NumberPassengers: filter.passengers
        });

        const body = {
            Lusers: null,
            Pseudo: data.pseudo,
            TypeSearch: 'V',
            LHotel: [
                {
                    HotelCode: data.code,
                    StartDate: data.startDate,
                    EndDate: data.endDate,
                    LguestPerRoom: passengers,
                }
            ],
           Ocompany: null
        };
        return this.http.post<any>(config.getSearchListHotel('HotelSearch/SearchRoom'), body, httpOptions);
    }

    searchHotelListHabitacionImage(filter,hotel) {
        let data = this.sessionService.getOnlyHotel();
        const rooms = [];
        const passengers = [];

        // httpOptions.headers = new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,
      
        //   });


        passengers.push({
            TypePassenger: 'ADT',
            RoomQuantity: filter.rooms,
            NumberPassengers: filter.passengers
        });

        const body = {
            HotelCode: hotel.code,
            Pseudo: hotel.pseudo,
            TypeSearch: 'V',
           Ocompany: null
        };
        return this.http.post<any>(config.getSearchListHotel('HotelSearch/SearchImagesByHotel'), body, httpOptions);
    }


    selectRoom() {
        let data = this.sessionService.getValidRoom();
        let hotel = this.sessionService.getInfoHabi();
        const rooms = [];
        const passengers = [];

        // httpOptions.headers = new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,
      
        //   });

       /*  passengers.push({
            TypePassenger: 'ADT',
            RoomQuantity: filter.rooms,
            NumberPassengers: filter.passengers
        }); */

        const body = {
            Pseudo: hotel.pseudo,
            GDS: hotel.gds,
            CityCode: hotel.ohotel.cityCode,
            ChainCode: hotel.ohotel.chainCode,
            HotelCode: hotel.ohotel.hotelCode,
            TypeSearch: 'V',
            StartDate: hotel.ohotel.startDate,
            EndDate: hotel.ohotel.endDate,
            Starts: hotel.ohotel.stars,
            RoomType: data.roomType,
            BookingCode: data.bookingCode,
            LguestPerRoom: hotel.ohotel.lguestPerRoom,
            PlanCode: data.ratePlanCode,
            Ocompany: null,
            osession: hotel.osession,
        };
        return this.http.post<any>(config.getSelectRoom('Booking/SelectRoom'), body, httpOptions);
    }


   

}