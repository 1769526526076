import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { PackageService } from 'src/app/core/services/package.service';
import { DialogPartnerComponent } from 'src/app/shared/components/dialog-partner/dialog-partner.component';
import { SessionService } from 'src/app/core/services/session.service';
import { LoginService } from '../../../core/services/login.service';
import * as crypto from 'crypto-js';
import { LoginInitialComponent } from '../login-initial/login-initial.component';

@Component({
  selector: 'app-login-access',
  templateUrl: './login-access.component.html',
  styleUrls: ['./login-access.component.css'],
  providers: [PackageService, LoginService]
})
export class LoginAccessComponent implements OnInit {


  finish;
  nombreVal = "";
  apellidoVal = "";
  numDoc = "";
  documentType;
  typeVal = "F3F05B20-412E-4A1A-BA31-B69B1E6D0392";
  personId;
  fecNac = "";
  textcontra = "";
  correoVal = "";
  phoneVal = "";
  validarInputs = true;
  valordoc;
  validDoc = true;
  contraVal = "";
  texto = 'Usted no esta permitido ingresar a esta pagina.';
  url;
  loginUser;
  sendMax;
  userId;

  constructor(private loginService: LoginService,private sessionService: SessionService, private snackBar: MatSnackBar, private spinner: NgxSpinnerService, private packageService: PackageService, public dialog: MatDialog
    , public dialogRef: MatDialogRef<LoginAccessComponent>, @Inject(MAT_DIALOG_DATA) public data: string) { }


  ngOnInit() {
    this.url = window.location.href;
    this.getDocumentType()
  }

  getDocumentType() {
    this.spinner.show();
    this.packageService.getDocumentList(false).subscribe(
      x => {
        this.documentType = x.ldocumentTypeLists;
        this.getUrl();
      },
      err => {
       /*  console.log('Error: ' + err); */
      },
      () => {

      }
    );
  }

  validpassword() {
    let stilo = document.getElementById("contra");
    let texta = document.getElementById("textoContra");
    var strongRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");
    var mediumRegex = new RegExp("^(?=.{7,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$", "g");
    var enoughRegex = new RegExp("(?=.{6,}).*", "g");
    if (false == enoughRegex.test(this.contraVal)) {
      this.textcontra = "Baja";
      texta.style.color = "#E2231A";
      stilo.style.border = "1px solid #E2231A";
    } else if (strongRegex.test(this.contraVal)) {
      this.textcontra = "Muy alto";
      texta.style.color = "#44C851";
      stilo.style.border = "1px solid #44C851";
    } else if (mediumRegex.test(this.contraVal)) {
      this.textcontra = "Media";
      texta.style.color = "#FFC107";
      stilo.style.border = "1px solid #FFC107";
    } else {
      this.textcontra = "Baja";
      texta.style.color = "#E2231A";
      stilo.style.border = "1px solid #E2231A";
    }
    return true;
  }

  
  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  valid(){
    this.fecNac;
  }

  getUrl() {
    
    this.url = this.url.split('user=');
    this.url = this.url[1];
    this.loginService.GetUser(this.url).subscribe(
      x => {
        this.spinner.hide();
        if (x === null) {
          this.showDialogNotification(this.texto);
          return;
        }
        this.nombreVal = x.firstName;
        this.apellidoVal = x.lastName;
        this.loginUser= x.loginUser;
        this.personId = x.personId;
        this.typeVal = x.lpersonDocuments[0].docTypeId;
        let cumple = x.birthDate.split("T");
        let cumlesed = cumple[0];
        this.numDoc = x.lpersonDocuments[0].docNumber;
        this.fecNac = cumlesed;
   /*      this.correoVal = x.email; */
        this.userId = x.userId;
        this.phoneVal = x.phone;

      }
    )
  }

  setIdDoc() {
    //console.log(this.typeVal);
    this.valordoc = document.getElementById("nroDoc");
    this.valordoc.value = "";
    if (this.typeVal === "F3F05B20-412E-4A1A-BA31-B69B1E6D0392") {
      this.valordoc.maxLength = 8;
    } else {
      this.valordoc.maxLength = 12;
    }
  }

  esEmailValido(): boolean {
    let mailValido = false;
    'use strict';
    let valid = document.getElementById("conti");
    var EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (this.correoVal.match(EMAIL_REGEX)) {
      mailValido = true;
      
      valid.style.backgroundColor = "#E82D56";
      valid.style.pointerEvents = "visible";
    } else {
      valid.style.backgroundColor = "rgba(232, 45, 86, 0.5)";
      valid.style.pointerEvents = "none";
    }
    return mailValido;
  }

  

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 5000,
    });
  }

  registerUser() {
    if (this.nombreVal === "" || this.apellidoVal === "" || this.typeVal === "" || this.numDoc === "" || this.fecNac === "" || this.contraVal === "" || this.correoVal === "") {
      this.openSnackBar("Por favor rellene todos los campos");
      return;
    }
    if (this.textcontra != "Muy alto") {
      this.openSnackBar("Por favor siga las instrucciones al crear contraseña.");
      return;
    }
    let numDocLegn = this.numDoc.length;
    if(numDocLegn < 8){
      this.openSnackBar("Por favor completar numero de documento.");
      return;
    }
 
    this.spinner.show();

    let obj = {
      Name: this.nombreVal,
      LastName: this.apellidoVal,
      DocumentType: this.typeVal,
      DocumentNumber: this.numDoc,
      BirthDate: this.fecNac,
      Email: this.correoVal,
      Password: crypto.SHA256(this.contraVal).toString()
    }
    let datos = {
      PersonID: this.personId,
      UserID: this.userId,
      Password: crypto.SHA256(this.contraVal).toString(),
      Email: this.correoVal,
    }
    this.packageService.updateRegister(datos).subscribe(
      x => {
        if(x.odata != null){
          const obj = {
            userId: x.odata.userID,
            email: x.odata.email,
            nombre: x.odata.name,
            apellido: x.odata.lastName,
            phone: x.odata.phone,
            genero: x.odata.gender,
            countryCode: x.odata.countryOrigin,
            documentNumber : x.odata.documentNumber,
            typeDocument: x.odata.documentType,
            birthDate: x.odata.birthDate
          }
          this.sessionService.setEmailFinish(obj);
          this.sessionService.setSelected(x.odata.selected);
        }
        if (x.status === 200) {
          this.personId = x.odata.code;

          this.openDialogs();
        } else {
          this.openSnackBar(x.message);
        }
        this.spinner.hide();

      }
    )

  }

  onClose() {
    this.dialogRef.close();
  }

  openDialogs() {
    let datas = {
      person: this.personId,
      contra: this.contraVal,
      loginUser: this.loginUser
    }
    this.dialogRef.close();
    const dialogRef = this.dialog.open(LoginInitialComponent, {
      data: datas,
      disableClose: true,
      panelClass: 'custom-dialog-container',
      maxWidth: 800

    });

    dialogRef.afterClosed().subscribe(result => {
      /* //console.log('The dialog was closed'); */
      if (result) {
        this.finish = result;
      }
    });
  }

  Solotexto(event) {
    var regex = new RegExp("^[a-zA-Z ]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }

  ValidarSoloNumero(event) {
    // tslint:disable-next-line: max-line-length
    if (this.typeVal === "F3F05B20-412E-4A1A-BA31-B69B1E6D0392") {
      if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105) && event.keyCode !== 190 && event.keyCode !== 110 && event.keyCode !== 8 && event.keyCode !== 9) {
        return false;
      }
    } else {
      var regex = new RegExp("^[a-zA-Z1-9 ]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        if (event.code != "Backspace") {
          event.preventDefault();
          return false;
        }

      }
    }

  }

  showDialogNotification(message: string) {
    const dialogRef = this.dialog.open(DialogPartnerComponent, {
      disableClose: true,
      data: message
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
