import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MatDialog, MatDialogModule, MatDividerModule } from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FacebookModule } from "ngx-facebook";
import { HeaderPartnerComponent } from './header-partner/header-partner.component';
import { AccountComponent } from '../auth/account/account.component';
import {MatSidenavModule} from '@angular/material/sidenav';

import {MatMenuModule} from '@angular/material/menu';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    HeaderPartnerComponent
  ],
  imports: [
    MatSidenavModule,
    MatMenuModule,
    CommonModule,
    MatDividerModule,
    MatDialogModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FacebookModule.forRoot(),
  ],
  exports: [
    HeaderComponent,
    FooterComponent
  ]
})
export class CoreModule { 
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
