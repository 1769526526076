
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { FilterPassenger } from '../../models/filter-passenger.model';

@Component({
  selector: 'app-passenger-counter-phone',
  templateUrl: './passenger-counter-phone.component.html',
  styleUrls: ['./passenger-counter-phone.component.css']
})
export class PassengerCounterPhoneComponent implements OnInit {

  show: boolean;
  form: FormGroup;
  private wasInside = false;
  totalPassengers = 1;
  PASSENGER_LIMIT = 6;
  errorMessage = '';
  blockPass: any;
  filter: FilterPassenger[] = [];

  tipe: any;
  @Input() isPhone: any;
  @Input() value: FilterPassenger[];
  @Output() selection = new EventEmitter<FilterPassenger[]>();
  @Output() total = new EventEmitter<any>();
  @Output() apply = new EventEmitter<any>();
  constructor(
    private eRef: ElementRef,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    console.log(this.value);
    this.initForm();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    const divElement = this.eRef.nativeElement.querySelector('#idSearchFlight');

    // Comprobar si el clic ocurrió fuera del div
    if (divElement && !divElement.contains(clickedElement)) {


      this.show = false;
      // Aquí puedes realizar las acciones que desees cuando el clic ocurre fuera del div
    }
  }

  mostarfl() {
    if (this.show) {
      let idfl = document.getElementById("idSearchFlight");
      idfl.style.visibility = "visible";
      this.show = true;

    } else {
      let idfl = document.getElementById("idSearchFlight");
      idfl.style.visibility = "visible";
      this.show = true;

    }
  }

  stopEventPropagation(event: MouseEvent) {

    event.stopPropagation();
  }

  /*  @HostListener('click')
   clickInside() {
     this.wasInside = true;
   }
 
   @HostListener('document:click')
   clickout() {
     if (!this.wasInside) {
       this.show = false;
       this.errorMessage = '';
     }
     this.wasInside = false;
   } */

  initForm() {

    let adults = 1;
    let children = 0;
    let infants = 0;
    if (this.value) {
      adults = this.value[0].quantity;
      children = this.value[1].quantity;
      infants = this.value[2].quantity;
    }

    this.totalPassengers = Number(adults) + Number(children) + Number(infants);

    this.form = this.formBuilder.group({
      adults: new FormControl({ value: adults, disabled: true }),
      children: new FormControl({ value: children, disabled: true }),
      infants: new FormControl({ value: infants, disabled: true })
    });

    this.filter.push({
      quantity: adults,
      type: FilterPassenger.TYPE_ADULT
    });
    this.filter.push({
      quantity: children,
      type: FilterPassenger.TYPE_CHILD
    });
    this.filter.push({
      quantity: infants,
      type: FilterPassenger.TYPE_INFANT
    });
    this.selection.emit(this.filter);
    this.total.emit(this.totalPassengers);
  }

  onFocus() {
    this.show = true;
  }

  removePassenger(type) {
    this.errorMessage = '';
    const adults = Number(this.form.controls.adults.value);
    const children = Number(this.form.controls.children.value);
    const infants = Number(this.form.controls.infants.value);
    switch (type) {
      case 'adults':
        if (adults > 1) {
          if (adults - 1 >= infants) {
            this.form.controls.adults.setValue((adults - 1).toString());
            this.totalPassengers--;
          } else {
            this.errorMessage = 'El número de infantes no puede superar al número de adultos';
          }
        }
        break;
      case 'children':
        if (children > 0) {
          this.form.controls.children.setValue((children - 1).toString());
          this.totalPassengers--;
        }
        break;
      case 'infants':
        if (infants > 0) {
          this.form.controls.infants.setValue((infants - 1).toString());
          this.totalPassengers--;
        }
        break;
    }
    this.tipe = type;

    this.updateFilter(type);


  }

  addPassenger(type) {
    this.errorMessage = '';
    if (this.totalPassengers < this.PASSENGER_LIMIT) {
      const adults = Number(this.form.controls.adults.value);
      const children = Number(this.form.controls.children.value);
      const infants = Number(this.form.controls.infants.value);
      switch (type) {
        case 'adults':
          this.form.controls.adults.setValue((adults + 1).toString());
          this.totalPassengers++;
          break;
        case 'children':
          this.form.controls.children.setValue((children + 1).toString());
          this.totalPassengers++;
          break;
        case 'infants':
          if (infants + 1 <= adults) {
            this.form.controls.infants.setValue((infants + 1).toString());
            this.totalPassengers++;
          } else {
            this.errorMessage = 'El número de infantes no puede superar al número de adultos';
          }
          break;
      }
    } else {
      this.errorMessage = 'Máximo número de pasajeros permitidos: ' + this.PASSENGER_LIMIT;
    }
    this.tipe = type;

    this.updateFilter(type);


  }

  updateFilter1() {

    this.selection.emit(this.filter);
    this.total.emit(this.totalPassengers);
    this.apply.emit();
  }

  updateFilter(type) {
    switch (type) {
      case 'adults':
        this.filter[0] = { quantity: this.form.controls.adults.value, type: FilterPassenger.TYPE_ADULT };
        break;
      case 'children':
        this.filter[1] = { quantity: this.form.controls.children.value, type: FilterPassenger.TYPE_CHILD };
        break;
      case 'infants':
        this.filter[2] = { quantity: this.form.controls.infants.value, type: FilterPassenger.TYPE_INFANT };
        break;
    }

  }

}
