import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { PackageService } from 'src/app/core/services/package.service';
import { SessionService } from 'src/app/core/services/session.service';
import { DialogCodeVerificationComponent } from '../dialog-code-verification/dialog-code-verification.component';
import {  MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-dialog-confirm-phone',
  templateUrl: './dialog-confirm-phone.component.html',
  styleUrls: ['./dialog-confirm-phone.component.css'],
  providers: [PackageService,MatDialog]
})
export class DialogConfirmPhoneComponent implements OnInit {
  countries;
  correoExist;
  pais;
  numero;
  nombre ="+51";
  constructor(private snackBar: MatSnackBar,private sessionService: SessionService,private spinner: NgxSpinnerService,private packageService: PackageService, public dialog: MatDialog
    , public dialogRef: MatDialogRef<DialogConfirmPhoneComponent>, @Inject(MAT_DIALOG_DATA) public data: string) { }
  
  ngOnInit() {
    this.getCountryCodes();
    //console.log(this.data);
  }

  onClose() {
    this.dialogRef.close();
  }

  getCountryCodes() {
    
    this.packageService.getCountryCodes().subscribe(
      x => {
        if (x.confirmation && x.data) {
          this.countries = x.data.filter(x => x.id !== null && x.id !== '');
        }
      },
      err => {

      },
      () => {
     
      }
    );
  }

  
  openDialogExiste(valor) {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(DialogCodeVerificationComponent, {
      data: valor,
      panelClass: 'custom-dialog-container',
      maxWidth: 800,
    
    });

    dialogRef.afterClosed().subscribe(result => {
      /* //console.log('The dialog was closed'); */
      if (result) {
        this.correoExist = result;
      }
    });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 5000,
    });
  }

  sendSms(valor){
    this.spinner.show();
    this.packageService.registerWithPhone(this.data,valor).subscribe(
      x=>{
        //console.log(x);
        if(x.status === 200){
          this.openDialogExiste(x.odata.code);
        }else {
          this.openSnackBar(x.message);
        }
        this.spinner.hide();
      }
    )
    
  }

  codeConfirm(){
    //console.log(this.numero);
    let pa = this.nombre;
    const asd = pa.slice(1);
    let valor = asd + this.numero;
    this.sessionService.setPhone(valor);
    //console.log(this.data);
    this.sendSms(valor);
    
  }

}
