import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'dm-new-destinations-item-carousel',
  templateUrl: './new-destinations-item-carousel.component.html',
  styleUrls: ['./new-destinations-item-carousel.component.css']
})
export class NewDestinationsItemCarouselComponent implements OnInit,AfterViewInit {

  @Input() listadoDinamicoI: any[];
  @Output() onSelect = new EventEmitter<any>();
  @Input() urlDinamico: string;
  @Input() validResponse: any;

  /**carousel */
  elementSize: number = 318;
  elementMargin: number = 20;
  widthWeb: number;

  public carouselWidth: number;
  public elementWidth: number;
  public currentIndex: number = 0;
  public pages: number = 0;
  public progressSize: number = 4;
  public counterLeft: number = 0;
  public counterRight: number = 0;
  public progressMargin: number = 0;
  public movementsToRight: number = 0;
  public movementsToLeft: number = 0;
  public currentItemsCarousel: number = 0;
  public scrHeight:any;
  public scrWidth:any;
  findelement: any;
  defaultTouch = { x: 0, y: 0, time: 0 };
  elements: any[];
  idUrl: string;
  iatcode: string;
  /**carousel */

  constructor() { 
    this.getScreenSize();
  }

  calculatePageWidth() {
    return window.innerWidth;
  }

  ngAfterViewInit(): void {
    /* let locat = window.location.hash;
      let located = locat.split("="); */
      let locat = window.location.pathname;
      let locat2 = window.location.search;
      if(locat2 != ""){
      let located2 = locat2.split('?');
      let quitar = located2[1].split('=');
    if(quitar.length > 1 && this.urlDinamico === locat + '?' + quitar[0]){
      this.iatcode = quitar[1];
      if(this.iatcode != null || this.iatcode != undefined){
    setTimeout(() => {
      let find = this.elements.find(x => x.odestination.iataCode === this.iatcode)
      if(find != null || find != undefined){
        let indexnum;
        this.widthWeb = this.calculatePageWidth();

        switch (true) {
          case (this.widthWeb > 200 && this.widthWeb <= 700):
            indexnum = find.index;
            break;
          case (this.widthWeb > 700 && this.widthWeb <= 1028):
            indexnum = find.index - 1;
            break;
          case (this.widthWeb > 1028):
            indexnum = find.index - 2;
            break;
        }

        /* if(this.isPhone){
          indexnum = find.index;
        }else {
          indexnum = find.index - 2;
        } */
        /* let indexnum = find.index - 2; */
      if(indexnum > 0){
        for (let index = 0; index < indexnum; index++) {
        this.next();
        
          }
        }
      }
    }, 1000);
  }
    }
  }
    
  }

  ngOnInit() {
    //carousel
    this.elements = this.listadoDinamicoI;
    this.calculateDimensions();
  }

  /**carousel */
  @HostListener('window:resize')
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    let elementsView = this.getElementsView();
    if (this.currentItemsCarousel !== elementsView){
      this.calculateDimensions();
    }
  }

  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  handleTouch(event) {
    let touch = event.touches[0] || event.changedTouches[0];
    if (event.type === 'touchstart') {
      this.defaultTouch.x = touch.pageX;
      this.defaultTouch.time = event.timeStamp;
    } else if (event.type === 'touchend') {
      let deltaX = touch.pageX - this.defaultTouch.x;
      let deltaTime = event.timeStamp - this.defaultTouch.time;
      if (deltaTime < 500) {
        if (Math.abs(deltaX) > 60) {
          if (deltaX > 0) {
            this.prev();
          } else {
            this.next();
          }
        }
      }
    }
  }

  calculateDimensions() {
    this.currentItemsCarousel = this.getElementsView();
    this.elementWidth = this.elementSize + 2 * this.elementMargin;
    this.carouselWidth = this.elementWidth * this.getElementsView();
    this.currentIndex = 0;
    if (this.elements !== null && this.elements !== undefined){
      this.pages = this.elements.length - this.getElementsView() + 1;
      if (this.pages <= 0)
        this.pages = 1;
      this.elements.map((i, index)=> {
        i.index = index, i.marginLeft = this.elementMargin, i.marginRight = this.elementMargin
      });
    }
    
    this.progressMargin = 0;
    this.counterLeft = 0;
    this.counterRight = 0;
    this.movementsToRight = 0;
    this.movementsToLeft = 0;
  }

  getElementsView():number {
    if (this.scrWidth > 1450) {
      return 4;
    }else if (this.scrWidth <= 1450 && this.scrWidth > 1024){
      return 3;
    } else if (this.scrWidth <= 1024 && this.scrWidth > 700){
      return 2;
    } else {
      return 1;
    }
  }

  prev() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.elements.find(x => x.index === 0).marginLeft = -(this.elementWidth) * this.currentIndex + this.elementMargin;

      if (this.counterRight - this.counterLeft <= this.pages - this.progressSize && this.movementsToRight - this.movementsToLeft > 0){
        this.progressMargin = this.progressMargin + 24;
        this.movementsToLeft++;
      }
      this.counterLeft++;
    }
    
    
  }

  next() {
    if (this.currentIndex < this.pages - 1) {
      this.currentIndex++;
      this.elements.find(x => x.index === 0).marginLeft = -(this.elementWidth) * this.currentIndex + this.elementMargin;

      if (this.counterRight - this.counterLeft >= this.progressSize - 1 && this.movementsToRight - this.movementsToLeft < this.pages - this.progressSize){
        this.progressMargin = this.progressMargin - 24;
        this.movementsToRight++;
      }
      this.counterRight++;
    }
  }
  /**carousel */

  goToVuelos(item) {
    console.log('emit11', item)
    this.onSelect.emit(item);
  }

}
