import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dialog-habitacion',
  templateUrl: './dialog-habitacion.component.html',
  styleUrls: ['./dialog-habitacion.component.css']
})
export class DialogHabitacionComponent implements OnInit {

  hola;
  mostrar;

  constructor(public dialogRef: MatDialogRef<DialogHabitacionComponent>,
              @Inject(MAT_DIALOG_DATA) public data: string, private dom: DomSanitizer
    ) { }

  ngOnInit() {
    this.mostrar = this.data;
    if(this.mostrar === ''){
      this.mostrar = 'No hay mas información en este hotel.';
    }
    this.dom.bypassSecurityTrustHtml(this.mostrar);
  }

  onClose() {
    this.dialogRef.close();
  }

}
