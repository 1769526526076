import { Component, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogSocialLoginComponent } from '../dialog-social-login/dialog-social-login.component';
/* import { AuthService } from 'angularx-social-login'; */
import { SessionService } from 'src/app/core/services/session.service';
import { Router } from '@angular/router';
import { DialogCodeVerificationComponent } from '../dialog-social-login/dialog-code-verification/dialog-code-verification.component';
import { DialogCorreoExistComponent } from '../dialog-social-login/dialog-correo-exist/dialog-correo-exist.component';
import { DialogCorreoComponent } from '../dialog-social-login/dialog-correo/dialog-correo.component';
import { DialogExistsGoogleComponent } from '../dialog-social-login/dialog-exists-google/dialog-exists-google.component';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dm-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit, AfterViewInit {

  show: boolean;
  wasInside: boolean;
  user;
  logindata;
  validSesion = true;
  showData = false;

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.show = false;
    }
    this.wasInside = false;
  }

  constructor(
    public dialog: MatDialog,
/*     private authService: AuthService, */
    private sessionService: SessionService,
    private router: Router
  ) { }

  ngOnInit() {
    this.validSesion = this.sessionService.getIniciarSesion();
    this.logindata = this.sessionService.getLoginData();
    if (this.logindata != null) {
      this.showData = true;
    }
    const u = this.sessionService.getAccount();
    if (u) {
      this.user = JSON.parse(u);
    }
    //console.log(this.validSesion);
  }

  ngAfterViewInit() {
    this.validSesion = this.sessionService.getIniciarSesion();
   }



  openWhats() {
    let direct = "https://vacation.domiruth.com/";
    let qwe = "Hola me puedes ayudar por favor, me encuentro en " + direct;
    let url = "https://api.whatsapp.com/send?phone=+51965329933&text=" + qwe;
    window.open(url, '_blank');
  }

  openDialog() {
    if (this.validSesion === false) {
      this.validSesion = true;
      this.sessionService.setIniciarSesion(true);
    } else {
      this.show = false;
      const dialogRef = this.dialog.open(DialogSocialLoginComponent, {
        data: '',
        disableClose: true,

        panelClass: 'custom-dialog-containers',
        maxWidth: 800
      });

      dialogRef.afterClosed().subscribe(result => {
        /* //console.log('The dialog was closed'); */
        if (result) {
          this.user = result;
        }
      });
    }

  }

  openLogin() {
    this.router.navigate(['login-initial']);
  }

  signOut(): void {
    this.show = false;
    this.sessionService.clearAccount();
    this.user = null;
    this.sessionService.setLoginData(null);
    this.sessionService.setPartner(false);
    this.sessionService.setUserData(null);
    window.location.reload();

    /* this.authService.signOut(); */
  }

}
