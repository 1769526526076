import { Component, HostListener, Input, OnInit ,EventEmitter,Output} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { PackageService } from 'src/app/core/services/package.service';
import { Room } from 'src/app/packages/shared/models/room.model';

@Component({
  selector: 'dm-config-rooms',
  templateUrl: './dm-config-rooms.component.html',
  styleUrls: ['./dm-config-rooms.component.css']
})
export class DmConfigRoomsComponent implements OnInit {
  form: FormGroup;
  /* @Input() rooms: Room[] = []; */
  @Input() rooms;
  @Input() selectedPrice;
  @Output() totalPayment = new EventEmitter<any>();
  roomList: FormArray;
  defaultAdults = 2;
  defaultChildren = 0;
  defaultInfants = 0;
  show: boolean;
  errorMessage = '';
  indexPanel = null;
  private wasInside = false;
  constructor(private fb: FormBuilder,private packageService: PackageService,private spinner: NgxSpinnerService) { }

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.show = false;
      if(this.indexPanel != null){
        let vlaor = document.getElementById("panel_"+ this.indexPanel);
        vlaor.style.display = "none";
      }
      
      this.errorMessage = '';
    }
    this.wasInside = false;
  }

  ngOnInit() {
    this.rooms = this.rooms.value;
    if (this.rooms.length >= 1) {
      this.parsear();
      /* this.dm-config-rooms(); */
    }
  }

  mostrar(index){
    if(this.indexPanel != index && this.indexPanel != null){
      let vlaor = document.getElementById("panel_"+ this.indexPanel);
      vlaor.style.display = "none";
    }
    this.indexPanel = index;
    let vlaor = document.getElementById("panel_"+index);
    vlaor.style.display = "flex";
  }

  parsear() {
    this.rooms.forEach(element => {
      element.adults = parseFloat(element.adults);
      element.childs = parseFloat(element.childs);
      element.infants = parseFloat(element.infants);
      element.total = element.adults + element.childs;
    });
  }




  initForm() {
    this.form = this.fb.group({
      rooms: this.fb.array([]),

    });
    this.roomList = this.form.get("rooms") as FormArray;
    this.roomList.clear;
  }

  addRoomUpdate() {
    /* this.roomList.push(this.createRoom()); */
    let obj = {
      adults: 2,
      childs: 0,
      infants: 0,
      total: 2
    }
    this.rooms.push(obj);
    this.armarRq(null,null,false);
  }

  removeRoomUpdate() {
    if(this.rooms.length > 1){
      this.rooms.pop();
    }
    this.armarRq(null,null,false);
  }

 

  createRoom(): FormGroup {
    return this.fb.group({
      adults: new FormControl(
        { value: this.defaultAdults.toString(), disabled: true },
        Validators.required
      ),
      childs: new FormControl(
        { value: this.defaultChildren.toString(), disabled: true },
        Validators.required
      ),
      infants: new FormControl(
        { value: this.defaultInfants.toString(), disabled: true },
        Validators.required
      ),
    });
  }

  removeRoom() {
    console.log('removeRoom');
    if (this.rooms.length > 1) {
      this.rooms.pop();
    }
  }



  removeAdult(index: number, person) {

    let room = this.rooms[index];
    let generoom = false;
    switch (person) {
      case 'A':
        if (room.adults > 1) {
          this.rooms[index].adults = room.adults - 1;
          generoom = true;
        }
        break;
      case 'N':
        if (room.childs > 0) {
          this.rooms[index].childs = room.childs - 1;
          generoom = true;
        }
        break;
      case 'I':
        if (room.infants > 0) {
          this.rooms[index].infants = room.infants - 1;
          generoom = true;
        }
        break;
    }
    if(generoom){
    this.rooms[index].total = room.adults + room.childs;
    this.armarRq(person,index,true);
    }
  }

  armarRq(person,index,valor){
    this.spinner.show();
    let personRoom = [];
    let total = 0;
    this.rooms.forEach(element => {
      let obj = {
        adults: [],
        children: [],
        numAdult: element.adults,
        numChild: element.childs,
        numInfant: element.infants,
        title: []
      }
      total = total + element.total;
      personRoom.push(obj);
    });
    let roomPrice = {
      lpersonRoomPackage: personRoom,
      adultDoubleDolar: this.selectedPrice.doubleDolar,
      adultSingleDolar: this.selectedPrice.singleDolar,
      adultTripleDolar: this.selectedPrice.tripleDolar,
      childPriceDolar: this.selectedPrice.childDolar,
      infantPriceDolar: this.selectedPrice.infantDolar,
      idPricePackage: this.selectedPrice.id,
      totalPerson: total,
    }
    this.packageService.calculatePrice(roomPrice).subscribe(
      (x) => {
        if (x.confirmation) {
          this.totalPayment.emit(x.data.totalDolar);
          /* this.totalPayment = x.data.totalDolar; */
        } else {
          if(valor){
            this.removeAdult(index,person);
          } else{
            this.removeRoomUpdate();
            
          }
          this.errorMessage = x.message;
          
        }
      },
      (err) => {
        /* //console.log('Error: ' + err); */
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  addAdult(index: number, person) {
    let room = this.rooms[index];
    let generoom = false;
    switch (person) {
      case 'A':
        this.rooms[index].adults = room.adults + 1;
        generoom = true;
        break;
      case 'N':
          if(this.selectedPrice.stockChild > 0){
            this.rooms[index].childs = room.childs + 1;
            generoom = true;
          }
        break;
      case 'I':
          if(this.selectedPrice.stockInfant > 0){
            this.rooms[index].infants = room.infants + 1;
            generoom = true;
          }
        break;
    }
    if(generoom){
    this.rooms[index].total = room.adults + room.childs;
    this.armarRq(person, index,true);
    }
  }
}
