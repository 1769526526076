export const ChildrenAge = [
    {
        id: '0',
        description: 'Menor de 1 año'
    },
    {
        id: '1',
        description: '1 año'
    },
    {
        id: '2',
        description: '2 años'
    },
    {
        id: '3',
        description: '3 años'
    },
    {
        id: '4',
        description: '4 años'
    },
    {
        id: '5',
        description: '5 años'
    },
    {
        id: '6',
        description: '6 años'
    },
    {
        id: '7',
        description: '7 años'
    },
    {
        id: '8',
        description: '8 años'
    },
    {
        id: '9',
        description: '9 años'
    },
    {
        id: '10',
        description: '10 años'
    },
    {
        id: '11',
        description: '11 años'
    },
    {
        id: '12',
        description: '12 años'
    },
    {
        id: '13',
        description: '13 años'
    },
    {
        id: '14',
        description: '14 años'
    },
    {
        id: '15',
        description: '15 años'
    },
    {
        id: '16',
        description: '16 años'
    },
    {
        id: '17',
        description: '17 años'
    }
];

