import { Component, OnInit } from '@angular/core';


@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dm-service-attributes',
  templateUrl: './dm-service-attributes.component.html',
  styleUrls: ['./dm-service-attributes.component.css']
})
export class DmServiceAttributesComponent implements OnInit {

  list = [];

  constructor() { }

  ngOnInit() {
    this.load();
  }

  load() {
    this.list = [
      {
        urlImage: '../../assets/images/common/attributes/productos.png',
        title: 'Productos Selectos ',
        description: 'Encuentra sólo productos seleccionados, los mejores precios, hoteles y experiencias'
      },
      {
        urlImage: '../../assets/images/common/attributes/calidad.png',
        title: 'Calidad',
        description: 'Certificación ISO 9001, que garantiza todos nuestros procesos'
      },
      {
        urlImage: '../../assets/images/common/attributes/asistencia_aeropuerto.png',
        title: 'Asistencia en Aeropuerto',
        description: 'Asistencia personalizada en aeropuertos'
      },
      {
        urlImage: '../../assets/images/common/attributes/experiencia.png',
        title: 'Experiencia',
        description: 'Tenemos más de 35 años de experiencia, brindando servicios de calidad'
      },
      {
        urlImage: '../../assets/images/common/attributes/asesoria.png',
        title: 'Asesoría Personalizada',
        description: 'Te acompañamos y asesoramos en todo momento para que disfrutes de la mejor experiencia Domiruth'
      }
    ];
  }

}
