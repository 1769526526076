import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerComponent, NgxSpinnerModule } from "ngx-spinner";
import { DmServiceAttributesComponent } from "../shared/controls/dm-service-attributes/dm-service-attributes.component";
import { HotelFilterSearchComponent } from '../hotels/hotel-filter-search/hotel-filter-search.component';
import { MaterialModule } from './material.module';
import { DmRoomsCounterComponent } from './controls/dm-rooms-counter/dm-rooms-counter.component';
import { DmCustomAutocompleteComponent } from './controls/dm-custom-autocomplete/dm-custom-autocomplete.component';
import { FlightFilterSearchComponent } from '../flights/flight-filter-search/flight-filter-search.component';
import { DmAutocompleteComponent } from './controls/dm-autocomplete/dm-autocomplete.component';
import { DmPassengerCounterComponent } from './controls/dm-passenger-counter/dm-passenger-counter.component';
import { HotelSearchComponent } from '../only-hotel/hotel-search/hotel-search.component';
import { DmStarsComponent } from './controls/dm-stars/dm-stars.component';
import { InsuranceSearchComponent } from '../insurance/insurance-search/insurance-search.component';
import { DmSegurosComponent } from './controls/dm-seguros/dm-seguros.component';
import { SanitizerPipe } from './pipes/dm-sanitizer.pipe';
import {FilterPipe} from './pipes/dm-filter.pipe';
import { UrlIconPipe } from './pipes/dm-url-icon.pipe';
import { UrlAirlineIconPipe } from './pipes/dm-url-airline-icon.pipe';
import { PackageComponent } from './components/package/package.component';
import { SplitPipe } from './pipes/dm-split.pipe';
import { DialogConditionsComponent } from './components/dialog-conditions/dialog-conditions.component';
import { DmGradientTitleComponent } from './controls/dm-gradient-title/dm-gradient-title.component';
import { DialogDetailComponent } from './components/dialog-detail/dialog-detail.component';
import { DialogValidProductComponent } from './components/dialog-valid-product/dialog-valid-product.component';
import { DialogPagoEfectivoComponent } from './components/dialog-pago-efectivo/dialog-pago-efectivo.component';
import { FlightFilterComponent } from './components/flight-filter/flight-filter.component';
import { Ng5SliderModule } from 'ng5-slider';
import { DmSelectMultipleComponent } from './controls/dm-select-multiple/dm-select-multiple.component';
import { DialogCardComponent } from './components/dialog-card/dialog-card.component';
import { SectionComponent } from './components/section/section.component';
import { SegmentComponent } from './components/segment/segment.component';
import { SegmentGroupComponent } from './components/segment-group/segment-group.component';
import { DialogNotificationComponent } from './components/dialog-notification/dialog-notification.component';
import { DmAutocompleteCharterComponent } from './controls/dm-autocomplete-charter/dm-autocomplete-charter.component';
import { DmTitleCharterComponent } from './controls/dm-title-charter/dm-title-charter.component';
import { DialogPartnerComponent } from './components/dialog-partner/dialog-partner.component';
import { NuevosDestinosVhComponent } from './components/nuevos-destinos-vh/nuevos-destinos-vh.component';
import { NuevosDestinosComponent } from './components/nuevos-destinos/nuevos-destinos.component';
import { DestinoNacionalComponent } from './components/nuevos-destinos/destino-nacional/destino-nacional.component';
import { PackageOrderSummaryComponent } from './components/package-order-summary/package-order-summary.component';
import { FlightItineraryComponent } from './components/flight-itinerary/flight-itinerary.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { CorreoExistsComponent } from './components/dialog-valid-product/correo-exists/correo-exists.component';
import { CorreoComponent } from './components/dialog-valid-product/correo/correo.component';
import { ExistsGoogleComponent } from './components/dialog-valid-product/exists-google/exists-google.component';
import { ResetPasswordComponent } from './components/dialog-valid-product/reset-password/reset-password.component';
import { SocialLoginComponent } from './components/dialog-valid-product/social-login/social-login.component';
import { DmPassengerCharterComponent } from './controls/dm-passenger-charter/dm-passenger-charter.component';
import { FormatoLongitudPipe } from './pipes/formato-longitud.pipe';
import { FlightSearchUrlComponent } from './flight-search-url/flight-search-url.component';
import { SearchFlightUrlComponent } from './components/search-flight-url/search-flight-url.component';
import { DialogLoginComponent } from './components/dialog-login/dialog-login.component';
import { PruebaComponent } from './components/prueba/prueba.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { DmRoomsGuestsComponent } from './controls/dm-rooms-guests-component/dm-rooms-guests.component';
import { DmButtonComponent } from './controls/dm-button/dm-button.component';
import { NewDestinationsCarouselComponent } from './components/new-destinations-carousel/new-destinations-carousel.component';
import { NationalDestinationCarouselComponent } from './components/national-destination-carousel/national-destination-carousel.component';
import { NewDestinationsItemCarouselComponent } from './components/new-destinations-item-carousel/new-destinations-item-carousel.component';
import { NewDestinationsVhCarouselComponent } from './components/new-destinations-vh-carousel/new-destinations-vh-carousel.component';
import { DmPassengerCounterSelectComponent } from './controls/dm-passenger-counter-select/dm-passenger-counter-select.component';
import { DmBackButtonComponent } from './controls/dm-back-button/dm-back-button.component';
import { DmTitleSubtitleComponent } from './controls/dm-title-subtitle/dm-title-subtitle.component';
import { DmMediaViewerComponent } from './components/dm-media-viewer/dm-media-viewer.component';
import { DialogVideosComponent } from './components/dialog-videos/dialog-videos.component';
import { DmHelpInfoComponent } from './components/dm-help-info/dm-help-info.component';
import { DialogViewContentComponent } from './components/dialog-view-content/dialog-view-content.component';
import { DialogDestiniesComponent } from './components/dialog-destinies/dialog-destinies.component';
import { DialogRequisitosComponent } from './components/dialog-requisitos/dialog-requisitos.component';
import { DmPaymentBookingPackageComponent } from './components/dm-payment-booking-package/dm-payment-booking-package.component';
import { MatDivider, MatDividerModule, MatIconModule } from '@angular/material';
import { DmItineraryAndMapComponent } from './components/dm-itinerary-and-map/dm-itinerary-and-map.component';
import { DmAccommodationRoomsComponent } from './components/dm-accommodation-rooms/dm-accommodation-rooms.component';
import { DmConfigRoomsComponent } from './components/dm-config-rooms/dm-config-rooms.component';
import { DmFlightSummaryComponent } from './components/dm-flight-summary/dm-flight-summary.component';
import { DmImageGalleryComponent } from './components/dm-image-gallery/dm-image-gallery.component';
import { NgxGalleryModule } from "ngx-gallery-9";
import { DeviceDetectorModule } from 'ngx-device-detector';
import { DmDialogMapComponent } from './components/dm-dialog-map/dm-dialog-map.component';
import { AgmCoreModule } from "@agm/core";
import { AgmDirectionModule } from 'agm-direction';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MediaViewerHotelComponent } from './components/media-viewer-hotel/media-viewer-hotel.component';
import { NewDestinationsHCarouselComponent } from './components/new-destinations-h-carousel/new-destinations-h-carousel.component';
import { DmFilterPanelComponent } from '../home/dm-filter-panel/dm-filter-panel.component';
import { PackageShowcaseComponent } from '../home/package-showcase/package-showcase.component';
import { PackageCarouselComponent } from '../home/package-carousel/package-carousel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { ImagesLazyloadDirective } from './components/images-lazyload/images-lazyload.directive';
import { ItemFlightInternationalComponent } from './components/item-flight-international/item-flight-international.component';
import { PassengerCounterPhoneComponent } from './controls/passenger-counter-phone/passenger-counter-phone.component';
import { AutocompletePhoneComponent } from './controls/autocomplete-phone/autocomplete-phone.component';
import { RoomsGuestPhoneComponent } from './controls/rooms-guest-phone/rooms-guest-phone.component';
import { DmAutocompleteSelectComponent } from './controls/dm-autocomplete-select/dm-autocomplete-select.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TooltipConfig } from 'ngx-bootstrap/tooltip';

@NgModule({
  declarations: [
    // NgxSpinnerComponent,
    DmServiceAttributesComponent,
    ItemFlightInternationalComponent,
    ImagesLazyloadDirective,
    DmFilterPanelComponent,
    PackageCarouselComponent,
    PackageShowcaseComponent,
    HotelFilterSearchComponent,
    DmRoomsCounterComponent,
    DmCustomAutocompleteComponent,
    DmAutocompleteSelectComponent,
    FlightFilterSearchComponent,
    DmAutocompleteComponent,
    DmPassengerCounterComponent,
    PassengerCounterPhoneComponent,
    HotelSearchComponent,
    DmStarsComponent,
    InsuranceSearchComponent,
    DmSegurosComponent,
    SanitizerPipe,
    FilterPipe,
    UrlIconPipe,
    AutocompletePhoneComponent,
    UrlAirlineIconPipe,
    SplitPipe,
    PackageComponent,
    DialogConditionsComponent,
    DmGradientTitleComponent,
    DialogDetailComponent,
    DialogValidProductComponent,
    DialogPagoEfectivoComponent,
    FlightFilterComponent,
    DmSelectMultipleComponent,
    DialogCardComponent,
    SectionComponent,
    SegmentComponent,
    SegmentGroupComponent,
    DialogNotificationComponent,
    DmAutocompleteCharterComponent,
    DmTitleCharterComponent,
    DialogPartnerComponent,
    NuevosDestinosVhComponent,
    NuevosDestinosComponent,
    DestinoNacionalComponent,
    PackageOrderSummaryComponent,
    FlightItineraryComponent,
    ErrorPageComponent,
    ResetPasswordComponent,
    CorreoExistsComponent,
    SocialLoginComponent,
    ExistsGoogleComponent,
    CorreoComponent,
    DmPassengerCharterComponent,
    FormatoLongitudPipe,
    FlightSearchUrlComponent,
    SearchFlightUrlComponent,
    DialogLoginComponent,
    PruebaComponent,
    NotFoundComponent,
    DmRoomsGuestsComponent,
    DmButtonComponent,
    NewDestinationsCarouselComponent,
    NationalDestinationCarouselComponent,
    NewDestinationsItemCarouselComponent,
    NewDestinationsVhCarouselComponent,
    NewDestinationsHCarouselComponent,
    DmPassengerCounterSelectComponent,
    DmBackButtonComponent,
    DmTitleSubtitleComponent,
    DmMediaViewerComponent,
    DialogVideosComponent,
    DmHelpInfoComponent,
    DialogViewContentComponent,
    RoomsGuestPhoneComponent,
    DialogDestiniesComponent,
    DialogRequisitosComponent,
    DmPaymentBookingPackageComponent,
    DmItineraryAndMapComponent,
    DmAccommodationRoomsComponent,
    DmConfigRoomsComponent,
    DmFlightSummaryComponent,
    DmImageGalleryComponent,
    DmDialogMapComponent,
    MediaViewerHotelComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    DeviceDetectorModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxSpinnerModule,
    MaterialModule,
    BsDatepickerModule.forRoot(), 
    Ng5SliderModule,
    MatDividerModule,
    MatIconModule,
    NgxGalleryModule,
    TooltipModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDsSUS767C6iloesG73fJ_P6KTVhJj7FEk",
      // libraries: ["places"],
    }),
    AgmDirectionModule

  ],
  providers: [TooltipConfig],
  exports: [
    NgxSpinnerComponent,
    DmFilterPanelComponent,
    PackageCarouselComponent,
    ImagesLazyloadDirective,
    PackageShowcaseComponent,
    DmServiceAttributesComponent,
    HotelFilterSearchComponent,
    DmRoomsCounterComponent,
    DmCustomAutocompleteComponent,
    DmAutocompleteSelectComponent,
    FlightFilterSearchComponent,
    DmAutocompleteComponent,
    DmPassengerCounterComponent,
    PassengerCounterPhoneComponent,
    HotelSearchComponent,
    DmStarsComponent,
    InsuranceSearchComponent,
    DmSegurosComponent,
    SanitizerPipe,
    FilterPipe,
    UrlIconPipe,
    UrlAirlineIconPipe,
    SplitPipe,
    NewDestinationsHCarouselComponent,
    PackageComponent,
    DialogConditionsComponent,
    DmGradientTitleComponent,
    DialogDetailComponent,
    DialogValidProductComponent,
    DialogPagoEfectivoComponent,
    FlightFilterComponent,
    DmSelectMultipleComponent,
    DialogCardComponent,
    SectionComponent,
    SegmentComponent,
    SegmentGroupComponent,
    DialogNotificationComponent,
    DmAutocompleteCharterComponent,
    AutocompletePhoneComponent,
    RoomsGuestPhoneComponent,
    DmTitleCharterComponent,
    DialogPartnerComponent,
    NuevosDestinosVhComponent,
    NuevosDestinosComponent,
    DestinoNacionalComponent,
    PackageOrderSummaryComponent,
    FlightItineraryComponent,
    ErrorPageComponent,
    DmButtonComponent,
    NewDestinationsCarouselComponent,
    NewDestinationsVhCarouselComponent,
    DmPassengerCounterSelectComponent,
    DmBackButtonComponent,
    DmTitleSubtitleComponent,
    DmMediaViewerComponent,
    MediaViewerHotelComponent,
    DialogVideosComponent,
    DmHelpInfoComponent,
    DialogViewContentComponent,
    DialogDestiniesComponent,
    DialogRequisitosComponent,
    DmPaymentBookingPackageComponent,
    DmItineraryAndMapComponent,
    DmAccommodationRoomsComponent,
    DmConfigRoomsComponent,
    DmFlightSummaryComponent,
    DmImageGalleryComponent,
    DmDialogMapComponent,
  ]
})
export class SharedModule { }
