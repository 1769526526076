import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'dm-back-button',
  templateUrl: './dm-back-button.component.html',
  styleUrls: ['./dm-back-button.component.css']
})
export class DmBackButtonComponent implements OnInit {

  @Input() label: string;
  @Output() click = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onClick(){
    this.click.emit;
  }

}
