import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { DialogSocialLoginComponent } from '../../auth/dialog-social-login/dialog-social-login.component';
import { SessionService } from 'src/app/core/services/session.service';

@Component({
  selector: 'app-header-partner',
  templateUrl: './header-partner.component.html',
  styleUrls: ['./header-partner.component.css']
})
export class HeaderPartnerComponent implements OnInit {

  showOptions: boolean;
  outOfOffice: boolean;

  constructor(
    protected sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
    public dialog: MatDialog,
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    const hours = new Date().getHours();
    this.outOfOffice = (hours >= 19 && hours <= 23) || (hours >= 0 && hours <= 8);
  }

  showList() {
    this.showOptions = true;
  }

  hiddenList() {
    this.showOptions = false;
  }

  showModal() {
    const dialogRef = this.dialog.open(DialogSocialLoginComponent, {
      data: ''
    });

    dialogRef.afterClosed().subscribe(result => {
      /* console.log('The dialog was closed'); */
    });
  }

}
