import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SessionService } from 'src/app/core/services/session.service';
import { Package } from '../../../packages/shared/models/package.model';
import { parseDate } from 'ngx-bootstrap/chronos';
import * as moment from 'moment';
import * as utils from '../../utils';
import { MatTooltip } from '@angular/material';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dm-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.css']
})
export class PackageComponent implements OnInit {

  currencyRate: number;
fecha1: any;
fecha2: any;
  @Input() pack: Package;
  @Input() category: Package;
  @Input() fromCarousel: boolean = false;
  
  @Output() selectPack = new EventEmitter<Package>();
  @Output() selectCategor = new EventEmitter<Package>();
  mtop = -13;
  validDiscount = false;
  tooltipTimeout: any;

  constructor(
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    this.currencyRate = this.sessionService.getCurrencyRate();
    if(this.pack.isApplyQuota){
      this.mtop = -13;
    }

    if(this.pack.discountDobleDolar === 0 || this.pack.discountDobleDolar === this.pack.adultDoubleDolar){
      this.validDiscount = true;
    }
    let bgDate = this.pack.beginDate;
    var dateParts: any = bgDate.split("/");
    this.fecha1  = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 

    let edDate = this.pack.endDate;
    var datePart: any = edDate.split("/");
    this.fecha2 = new Date(+datePart[2], datePart[1] - 1, +datePart[0]); 
    
  }

  armarListas(list){
    let lista = [];
    let destino = [];
    //console.log(list);
    if(list != null){
      list.forEach(element => {
        const obj = {
          countryCode: element.countryCode,
          ldestinations: element.ldestination
        }
        lista.push(obj);
      });
    }
    destino = this.armarDestino(lista);
    return destino;
  }

  armarDestino(list){
    //console.log(list);
    list.forEach(element => {
      let lista = [];
      element.ldestinations.forEach(dest => {
          const obj = {
            DestinationCode: dest.iataCode
          }
          lista.push(obj);
        });
        element.ldestinations = lista;
    });
    
    return list;
  }

  selectPackage() {
    let list = [];
    let destinies = [];
    this.selectPack.emit(this.pack);
    list =  this.armarListas(this.pack.lpackageDestination);
    this.sessionService.setRqView(list);
    this.selectCategor.emit(this.category);
  }

  selectCategory() {
    this.selectCategor.emit(this.category);
  }

  getTooltipName(name: string): string {
    return name.substring(0, 34) + "..."
  }

  showTooltipForOneSecond(tooltip: MatTooltip) {
    if (this.tooltipTimeout) {
      clearTimeout(this.tooltipTimeout);
      this.tooltipTimeout = null;
      tooltip.hide();
    }else{
      tooltip.show();
      this.tooltipTimeout = setTimeout(() => {
        tooltip.hide();
        this.tooltipTimeout = null; //Resetea el temporizador
      }, 2000);
    }
  }

}
