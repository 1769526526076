import { Pipe, PipeTransform } from '@angular/core';
import * as config from '../../core/config';
import { environment } from '../../../environments/environment';

@Pipe({name: 'dmUrlIcon'})
export class UrlIconPipe implements PipeTransform {
  transform(value: string, params?: string[]): string {
    return environment.urlIcons + value;
  }
}
