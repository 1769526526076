import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dm-title-charter',
  templateUrl: './dm-title-charter.component.html',
  styleUrls: ['./dm-title-charter.component.css']
})
export class DmTitleCharterComponent implements OnInit {

  constructor() { }

  @Input() title: string;
  @Input() fontSize: number;
  @Input() borderHeight: number;

  ngOnInit() {
  }

}
