import { Component, OnInit, Input } from '@angular/core';
import { PackageService } from '../../../core/services/package.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogConditionsComponent } from 'src/app/shared/components/dialog-conditions/dialog-conditions.component';
import { PackageDetail } from '../../../packages/shared/models/package-detail.model';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dm-package-order-summary',
  templateUrl: './package-order-summary.component.html',
  styleUrls: ['./package-order-summary.component.css'],
  providers: [ PackageService ]
})
export class PackageOrderSummaryComponent implements OnInit {

  @Input() summary: PackageDetail;

  constructor(
    private packageService: PackageService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.formatHtml();
  }

  formatHtml() {
    if (this.summary){
      if (this.summary.isHotelHtml && this.summary.hotelHtml) {
        this.summary.hotelHtml = this.summary.hotelHtml.replace('table', 'table style="margin: 0 auto; border: 1px solid #bdbdbd;"');
      }
      if (this.summary.isFlightHtml && this.summary.flightHtml) {
        this.summary.flightHtml = this.summary.flightHtml.replace('table', 'table style="margin: 0 auto; border: 1px solid #bdbdbd;"');
      }
    }
  }

  getSummary() {
    this.packageService.sendReservation(null).subscribe(
      x => {
        this.summary = x.data.dataPackage;
        // this.formatHtml(x.data.dataPackage);
        this.formatHtml();
      },
      err => {
       /*  console.log('Error: ', err); */
      },
      () => {
      }
    );
  }

  openConditionsDialog() {
    const dialogRef = this.dialog.open(DialogConditionsComponent, {
      data: {regulations: this.summary.regulation, conditions: this.summary.condition}
    });

    dialogRef.afterClosed().subscribe(result => {
      /* console.log('The dialog was closed'); */
    });
  }

}



