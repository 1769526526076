import { Component, Inject, OnInit, ViewChildren } from '@angular/core';
import { MatAccordion, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dialog-card',
  templateUrl: './dialog-card.component.html',
  styleUrls: ['./dialog-card.component.css']
})
export class DialogCardComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<DialogCardComponent>, protected sanitizer: DomSanitizer) {}
  @ViewChildren(MatAccordion) accordion: MatAccordion;

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }

}
