import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SessionService } from 'src/app/core/services/session.service';

@Component({
  selector: 'app-dialog-finish',
  templateUrl: './dialog-finish.component.html',
  styleUrls: ['./dialog-finish.component.css']
})
export class DialogFinishComponent implements OnInit {

  valor;

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    this.onClose();
  }

  constructor(private sessionService: SessionService,
     public dialogRef: MatDialogRef<DialogFinishComponent>,@Inject(MAT_DIALOG_DATA) public data: string) { }

  ngOnInit() {
    this.sessionService.setIniciarSesion(false);
  }

  onClose() {
    this.dialogRef.close();
    if( this.data === 'result'){
      this.valor = document.referrer;
    }if( this.data === 'finish'){
      this.valor = window.location.origin;
    }else if(this.data === ''){
      this.valor = window.location.href;
    }
    
    document.location.replace(this.valor);
    
  }

}
