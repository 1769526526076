import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import * as config from '../config';
import { Category } from '../../packages/shared/models/category.model';
import { Response } from '../../shared/models/response.model';
import { Select } from 'src/app/shared/models/select.model';
import { Price } from 'src/app/packages/shared/models/price.model';
import { debounceTime, map } from 'rxjs/operators';
import { PackageSummary } from 'src/app/packages/shared/models/package-summary.model';
import { PaymentType } from 'src/app/shared/models/payment-type.model';
import { PaymentMethod } from 'src/app/shared/models/payment-method.model';
import { VisaSessionResponse } from 'src/app/shared/models/visa/visa-session-response.model';
import { environment } from 'src/environments/environment';
import { OrderDetailResponse } from 'src/app/shared/models/visa/order-detail-response.model';
import { VisaErrorResponse } from 'src/app/shared/models/visa/visa-error-response.model';
import { Module } from 'src/app/shared/models/module.model';
import { Package } from '../../packages/shared/models/package.model';
import { PackagePrice } from '../../packages/shared/models/package-price.model';
import { RoomPrice } from '../../packages/shared/models/room-price.model';
import { Reservation } from '../../packages/shared/models/reservation.model';
import { ItineraryPackage } from '../../packages/shared/models/itinerary-package.model';
import { PackageDetail } from '../../packages/shared/models/package-detail.model';
import { TransactionResult } from '../../packages/shared/models/transaction-result.model';
import { ResultSearch } from '../../packages/shared/models/result-search.model';
import { FlightItinerary } from '../../packages/shared/models/flight-itinerary.model';
import { SelectDocType } from 'src/app/shared/models/selectDocType.model';
import { DeparturePackage } from 'src/app/shared/models/departure-package.model';

let httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': environment.subsKey,
})
};

@Injectable()
export class PackageService {
  url;
  private urlListCountry: string = environment.urlFlight + config.GET_COUNTRIES;
  private urlListDocument: string = environment.urlCustom + config.GET_DOCUMENT;
  private urlFilterPackage: string = environment.baseUrl + config.GET_FILTER_PACKAGES;
  private urlValidateEmail: string = environment.urlSecurity + config.VALIDATE_EMAIL;
  private urlSendSms: string = environment.urlSecurity + config.SEND_SMS;
  private url_resetPassword: string = environment.urlSecurity + config.RESET_PASSWORD;
  private url_updatePassword: string = environment.urlSecurity + config.UPDATE_PASSWORD;
  private urlAccessEmail: string = environment.urlSecurity + config.ACCESS_EMAIL;
  private urlVolverEnviar: string = environment.urlSecurity + config.SMS_AGAIN;
  private urlRegisterPhone: string = environment.urlSecurity + config.REGISTER_PHONE;
  private urlRegister: string = environment.urlSecurity + config.REGISTER_USER;
  private urlUpdate: string = environment.urlSecurity + config.UPDATE_USER;
  private urlTagPackage: string = environment.baseUrl + config.GET_TAG_PACKAGES;
  private url_document: string = environment.urlCustom + 'DocumentType/';
  private url_loginPhone: string = environment.urlSecurity + 'User/';
  private url_validatePhone: string = environment.urlSecurity + 'Person/';
  private urlCupon: string = environment.urlSecurity + "Coupon/ApplyCoupon";
  private urlSection: string = environment.baseUrl + "Secction/SectionList";
  /* 
    private auth2: gapi.auth2.GoogleAuth
    private subject = new ReplaySubject<gapi.auth2.GoogleUser>(1)
   */
  constructor(private http: HttpClient) {
    /*  gapi.load('auth2', () => {
       this.auth2 = gapi.auth2.init({
         client_id: '536885259092-r3vfm0r10kj052kmmp56om86nhln0040.apps.googleusercontent.com'
       })
     }) */
  }

  /*   public signin() {
      this.auth2.signIn({
      
      }).then(user => {
        this.subject.next(user)
      }).catch(() => {
        this.subject.next(null)
      })
    } */

  /*   public signOut() {
      this.auth2.signOut()
        .then(() => {
          this.subject.next(null)
        })
    }
   */
  /*  public observable(): Observable<gapi.auth2.GoogleUser> {
     return this.subject.asObservable()
   }
  */
  getShowCasePackages() {
    // const headers_ = new HttpHeaders({'Ocp-Apim-Subscription-Key': 'eb85131bc9d94c02840aa6961e7f77e9'});
    // return this.http.get<Response<Category[]>>(config.getShowCasePackagesUrl(config.SHOW_CASE_PACKAGE_METHOD), {headers: headers_});
    // httpOptions.headers = new HttpHeaders({
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    return this.http.get<Response<Category[]>>(config.getShowCasePackagesUrl(config.SHOW_CASE_PACKAGE_METHOD),httpOptions);
  }

  listSection(): Observable<any> {
    return this.http.get<any>(`${this.urlSection}`, httpOptions);
  }



  getCountrie(): Observable<any> {
    // httpOptions.headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    return this.http.get<any>(`${this.urlListCountry}`, httpOptions);
  }

  sendRegister(data): Observable<any> {
    // httpOptions.headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    return this.http.post<any>(`${this.urlRegister}`, data, httpOptions);
  }

  applyCoupon(data): Observable<any> {
    // httpOptions.headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    return this.http.post<any>(`${this.urlCupon}`, data, httpOptions);
  }

  updateRegister(data): Observable<any> {
    // httpOptions.headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    return this.http.post<any>(`${this.urlUpdate}`, data, httpOptions);
  }

  updatePass(data): Observable<any> {
    // httpOptions.headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    return this.http.post<any>(`${this.url_updatePassword}`, data, httpOptions);
  }

  loginEmail(data): Observable<any> {
    // httpOptions.headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    return this.http.post<any>(`${this.urlAccessEmail}`, data, httpOptions);
  }

  filterPackage(data): Observable<any> {
    // httpOptions.headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    const url = `${this.urlFilterPackage}?${'url=' + data}`;
    return this.http.get<any>(url, httpOptions);
  }

  validateEmail(data,data1): Observable<any> {
    // httpOptions.headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    const url = `${this.urlValidateEmail}?${'email=' + data}&${'UserID=' + data1}`;
   
    return this.http.get<any>(url, httpOptions);
  }

  resetPassword(data): Observable<any> {
    // httpOptions.headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    const url = `${this.url_resetPassword}?${'email=' + data}`;
    return this.http.get<any>(url, httpOptions);
  }

  registerWithPhone(data, data1): Observable<any> {
    // httpOptions.headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });

    const url = `${this.urlRegisterPhone}?${'personID=' + data}&${'phone=' + data1}`;
    return this.http.get<any>(url, httpOptions);
  }

  volverEnviar(data, data1): Observable<any> {
    // httpOptions.headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });

    const url = `${this.urlVolverEnviar}?${'phone=' + data}&${'type=' + data1}`;
    return this.http.get<any>(url, httpOptions);
  }

  sendMensaje(data): Observable<any> {
    // httpOptions.headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    const url = `${this.urlSendSms}?${'phone=' + data}`;
    return this.http.get<any>(url, httpOptions);
  }

  filterPackageByTag(data): Observable<any> {
    // httpOptions.headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    const url = `${this.urlTagPackage}?${'tag=' + data}`;
    return this.http.get<any>(url, httpOptions);
  }

  getDocument(): Observable<any> {
    // httpOptions.headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    return this.http.get<any>(`${this.urlListDocument}`, httpOptions);
  }

  getDocumentList(data): Observable<any> {

    // httpOptions.headers = new HttpHeaders({
    //   'Content-Type': "application/json",
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    const url = `${this.url_document + 'GetDocumentTypeList'}?${'isAdministrator=' + data}`;
    return this.http.get<any>(url, httpOptions);
  }

  LoginAccessByPhone(data): Observable<any> {

    // httpOptions.headers = new HttpHeaders({
    //   'Content-Type': "application/json",
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    const url = `${this.url_loginPhone + 'LoginAccessByPhone'}?${'phone=' + data}`;
    return this.http.get<any>(url, httpOptions);
  }

  validatePhone(data): Observable<any> {

    // httpOptions.headers = new HttpHeaders({
    //   'Content-Type': "application/json",
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    const url = `${this.url_validatePhone + 'ValidatePersonalPhone'}?${'phone=' + data}`;
    return this.http.get<any>(url, httpOptions);
  }

  getPackageDetail(packagePriceId: string) {
    // httpOptions.headers = new HttpHeaders({
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    const method = config.PACKAGE_DETAIL_METHOD + config.SLASH + packagePriceId;
    return this.http.get<Response<Package>>(config.getPackageDetailUrl(method),httpOptions);
  }

  getDestinies() {
    // httpOptions.headers = new HttpHeaders({
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    return this.http.get<Response<ResultSearch[]>>(config.getDestiniesUrl(config.DESTINIES_METHOD), httpOptions);
  }

  getDepartures(packageId: string) {
    // httpOptions.headers = new HttpHeaders({
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    const method = config.DEPARTURES_METHOD + config.SLASH + packageId;
    return this.http.get<Response<DeparturePackage[]>>(config.getDeparturesUrl(method),httpOptions);
  }

  getPackagePrice(departureId: string) {
    // httpOptions.headers = new HttpHeaders({
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    const method = config.PACKAGE_PRICE_METHOD + config.SLASH + departureId;
    return this.http.get<Response<PackagePrice[]>>(config.getPackagesPriceUrl(method),httpOptions);
  }

  calculatePrice(roomPrice: RoomPrice) {
    // httpOptions.headers = new HttpHeaders({
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    return this.http.post<Response<Price>>(config.getCalculatePriceUrl(config.CALCULATE_PRICE_METHOD), roomPrice,httpOptions);
  }

  /* sendReservation(reservation: any) {
    return this.http.post<Response<any>>(config.getNewVisaSession(config.NEW_VISA_SESSION), reservation);
  } */

  sendReservation(reservation: Reservation) {
    // httpOptions.headers = new HttpHeaders({
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    return this.http.post<Response<any>>(config.getReservationUrl(config.RESERVATION_METHOD), reservation,httpOptions);
  }

  sendPromotionalCode(packageId, code) {
    let datas = packageId + '&promotionalCode=' + code;
    return this.http.get<any>(config.getPromotional(config.PROMOTIONAL_CODE + datas));
  }

  getExchangeRate() {
    // httpOptions.headers = new HttpHeaders({
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,

    // });
    // const headers_ = new HttpHeaders({'Ocp-Apim-Subscription-Key': 'eb85131bc9d94c02840aa6961e7f77e9'});
    // return this.http.get<Response<number>>(config.getExchangeRateUrl(config.EXCHANGE_RATE_METHOD), {headers: headers_});
    return this.http.get<Response<number>>(config.getExchangeRateUrl(config.EXCHANGE_RATE_METHOD), httpOptions);
  }

  getHotelHtml(packagePriceId: string) {

    const method = config.HOTEL_HTML_METHOD + config.SLASH + packagePriceId;
    return this.http.get<Response<string>>(config.getHotelHtmlUrl(method),httpOptions);
  }

  getFlightItineraryHtml(packagePriceId: string) {
    const method = config.FLIGHT_ITINERARY_HTML_METHOD + config.SLASH + packagePriceId;
    return this.http.get<Response<string>>(config.getFlightItineraryHtmlUrl(method),httpOptions);
  }

  getFlightItineraries(packagePriceId: string) {
    const method = config.FLIGHT_ITINERARY_METHOD + config.SLASH + packagePriceId;
    return this.http.get<Response<FlightItinerary[]>>(config.getFlightItinerariesUrl(method),httpOptions);
  }

  getItineraryPackage(packageId: string) {
    const method = config.ITINERARIES_METHOD + config.SLASH + packageId;
    return this.http.get<Response<ItineraryPackage>>(config.getItinerariesUrl(method),httpOptions);
  }

  getAutocomplete(value: string) {
    httpOptions.headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.subsKey,

    });
    const method = config.AUTOCOMPLETE_METHOD + config.SLASH + value;
    return this.http.get<Response<ResultSearch[]>>(config.getAutocompleteUrl(method),httpOptions)
      .pipe(debounceTime(500));
  }

  getAutocompleteList() {
    httpOptions.headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.subsKey,

    });
    return this.http.get<Response<ResultSearch[]>>(config.getAutocompleteUrl(config.AUTOCOMPLETE_METHOD),httpOptions)
      .pipe(debounceTime(500));
  }

  getResult(search: string, month: string, typeSearch: string) {
    httpOptions.headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.subsKey,

    });
    /* const params = new HttpParams()
      .set('search', search)
      .set('month', month)
      .set('typeSearch', typeSearch); */
      let url = config.SEARCH_METHOD + "?" + "search=" + search + "&month=" + month + "&typeSearch=" + typeSearch;
    return this.http.get<Response<Package[]>>(config.getResultUrl(url), httpOptions);
  }

  getMonths(id: string, type: string) {
    httpOptions.headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.subsKey,

    });
   /*  const params = new HttpParams()
      .set('search', id)
      .set('typeSearch', type); */
    let url = config.MONTHS_METHOD + "?" + "search=" + id + "&typeSearch=" + type;
    return this.http.get<Response<number[]>>(config.getMonthsUrl(url), httpOptions);
  }

  getPackageSummary(departurePackageId: string) {
    httpOptions.headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.subsKey,

    });
    const method = config.PACKAGE_SUMMARY_METHOD + config.SLASH + departurePackageId;
    return this.http.get<Response<PackageSummary>>(config.getPackageSummaryUrl(method),httpOptions);
  }

  getDocumentType() {
    /* httpOptions.headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.subsKey,

    }); */
    return this.http.get<Response<Select[]>>(config.getDocumentTypeUrl(config.DOCUMENT_TYPE_METHOD),httpOptions)
  }

  getDocumentTypeFlight() {

    return this.http.get<Response<SelectDocType[]>>(config.getDocumentTypeUrlFlight(config.DOCUMENT_TYPE_METHOD_FLIGHT),httpOptions);
  }

  getBanks() {
    httpOptions.headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.subsKey,

    });
    return this.http.get<Response<Select[]>>(config.getBankUrl(config.BANK_LIST_METHOD),httpOptions);
  }

  getCountries() {
    httpOptions.headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.subsKey,

    });
    return this.http.get<Response<Select[]>>(config.getCountryUrl(config.COUNTRY_LIST_METHOD),httpOptions);
  }

  getCountryCodes() {
    httpOptions.headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.subsKey,

    });
    return this.http.get<Response<Select[]>>(config.getCountryCodesUrl(config.COUNTRY_CODE_LIST_METHOD),httpOptions);
  }

  getPaymentTypes() {
    httpOptions.headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.subsKey,

    });
    return this.http.get<Response<PaymentMethod[]>>(config.getPaymentTypesUrl(config.PAYMENT_TYPE_METHOD),httpOptions);
  }

  getPaymentTypesDetail() {
    httpOptions.headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.subsKey,

    });
    return this.http.get<Response<PaymentType[]>>(config.getPaymentTypesDetailUrl(config.PAYMENT_TYPE_DETAIL_METHOD),httpOptions);
  }

  getIpAddress() {
    return this.http.get(environment.urlIp);
  }

  getSessionToken(data) {
    const url = environment.urlVisa + config.VISA_TOKEN_METHOD;
    return this.http.post<VisaSessionResponse>(url, data);
  }



  setValidateCupon(data): Observable<any> {
    const url = environment.baseUrl + config.VALID_CUPON;
    httpOptions.headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.subsKey,

    });
    return this.http.post<any>(`${url}`, data, httpOptions);
  }



  // getListInformation(data): Observable<any> {
  //   const url = environment.urlViewContent + config.VIEW_CONTENT;
  //   httpOptions.headers = new HttpHeaders({
  //     'Ocp-Apim-Subscription-Key': environment.subsKey,

  //   });
  //   return this.http.post<any>(`${url}`, data, httpOptions);
  // }


  updateOperationStatus(data, module) {
    switch (module) {
      case Module.PACKAGE:
        return this.http.put<Response<any>>(config.updateStatusPaymentUrl(config.TRANSACTION_UPDATE_STATUS_METHOD), data);
        break;
      case Module.FLIGHT:
        return this.http.post<Response<any>>(config.updateStatusPaymentUrl(config.TRANSACTION_FLIGHT_UPDATE_STATUS_METHOD), data);
        break;
    }
  }

  getPurchaseNumber() {
    httpOptions.headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.subsKey,

    });
    return this.http.get<Response<string>>(config.getPurchaseNumberUrl(config.TRANSACTION_GET_PURCHASE_NUMBER_METHOD),httpOptions);
  }

  getVisaDetailService(data) {
    const url = environment.urlVisa + config.VISA_DETAIL_METHOD;
    return this.http.post<OrderDetailResponse>(url, data);
  }

  getVisaDetailNewService(data) {
    const url = environment.urlNewVisa + config.VISA_DETAIL_NEW_METHOD + data;
    return this.http.get<any>(url);
  }

  getOrderDetail(purchaseNumber: string) {
    const method = config.ORDER_DETAIL_PURCHASE + config.SLASH + purchaseNumber;
    return this.http.get<Response<PackageDetail>>(config.getOrderDetailPurchaseUrl(method));
  }

  getVisaErrorDetail(code: string) {
    const method = environment.urlVisa + config.VISA_ERROR_DETAIL_METHOD + config.SLASH + code;
    return this.http.get<VisaErrorResponse>(method);
  }

}
