import { Component, OnInit } from "@angular/core";

import { SessionService } from "src/app/core/services/session.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FlightService } from "src/app/core/services/flight.service";
import { airportsData } from "../../services/airport.const";

declare var jquery: any;
declare var $: any;

@Component({
  selector: "app-nuevos-destinos",
  templateUrl: "./nuevos-destinos.component.html",
  styleUrls: ["./nuevos-destinos.component.css"],
})
export class NuevosDestinosComponent implements OnInit {
  listadoDinamico: any[] = [];
  listadoDinamicoN: any[] = [];
  listadoDinamicoI: any[] = [];
  fromFilter: any[] = [];
  toFilter: any[] = [];
  dateFilter: any[] = [];
  origins = [];
  originList = [];
  destinationList = [];
  url;
  dataAirport: any;
  listAir: any;
  public myObject!: { id: number, myObject: { myString: string } };
  constructor(
    private flightService: FlightService,
    private router: Router,
    private sessionService: SessionService,
  ) {
    this.dataAirport = airportsData;
  }

  ngOnInit() {
    this.getDynamicSearch();
    this.url = window.location.href;
    this.url = this.url.split("/");
    this.url = this.url[1];
    if(this.url === '' || this.url === null || this.url === undefined){
      return;
    } else {
      this.url = this.url.split("$");
      //console.log(this.url)
        /* if(this.listAir === null || this.listAir === undefined){ */
          this.getAirports();
        /* } else {
          this.goToVuelosWord(this.url[0],this.url[1],this.url[2],this.url[3],this.url[4], this.listAir);
        } */
      
    }
    
    
  }

  getPriorityAirports() {
    /* this.spinner.show(); */
    this.flightService.getPriorityAirports().subscribe(
      (result) => {
        /* //console.log(result); */
        if (result) {
          const airports = result.lairports ? result.lairports : [];
          const cities = result.lcities ? result.lcities : [];
          airports.forEach((airport) => {
            this.origins.push({
              code: airport.iataCode,
              countryCode: airport.countryCode,
              name: airport.name,
              searchName: airport.searchName,
              priority: airport.priority,
              /* icon: 'local_airport' */
              icon: "A",
            });
          });
          cities.forEach((city) => {
            this.origins.push({
              code: city.iataCode,
              countryCode: city.countryCode,
              name: city.name,
              searchName: city.searchName,
              priority: city.priority,
              /* icon: 'location_city' */
              icon: "C",
            });
          });
          /* this.origins.sort((a, b) => b.priority - a.priority);  */        
        }
      },
      (err) => {
        /*   //console.log('Error: ', err); */
      },
      () => {
        /* this.goToVuelosWord(this.url[0],this.url[1],this.url[2],this.url[3],this.url[4]); */
        this.getAirports();
      }
    );
  }

  getAirports() {
    const _origins = [];
    let result = this.dataAirport;
    if (result) {
      const airports = result.lairports ? result.lairports : [];
      const cities = result.lcities ? result.lcities : [];
      airports.forEach((airport) => {
        _origins.push({
          code: airport.iataCode,
          countryCode: airport.countryCode,
          name: airport.name,
          searchName: airport.searchName,
          priority: airport.priority,
          cityName: airport.cityName,
          /* icon: 'local_airport' */
          icon: "A",
        });
      });
      cities.forEach((city) => {
        _origins.push({
          code: city.iataCode,
          countryCode: city.countryCode,
          name: city.name,
          cityName: city.cityName,
          searchName: city.searchName,
          priority: city.priority,
          /* icon: 'location_city' */
          icon: "C",
        });
      });
      /* _origins.sort((a, b) => b.priority - a.priority); */
    }

    this.origins = _origins;

    this.goToVuelosWord(this.url[0],this.url[1],this.url[2],this.url[3],this.url[4], this.origins);
          
    // this.flightService.getAirports().subscribe(
    //   (result) => {
    //     if (result) {
    //       const airports = result.lairports ? result.lairports : [];
    //       const cities = result.lcities ? result.lcities : [];
    //       airports.forEach((airport) => {
    //         _origins.push({
    //           code: airport.iataCode,
    //           countryCode: airport.countryCode,
    //           name: airport.name,
    //           searchName: airport.searchName,
    //           priority: airport.priority,
    //           cityName: airport.cityName,
    //           /* icon: 'local_airport' */
    //           icon: "A",
    //         });
    //       });
    //       cities.forEach((city) => {
    //         _origins.push({
    //           code: city.iataCode,
    //           countryCode: city.countryCode,
    //           name: city.name,
    //           cityName: city.cityName,
    //           searchName: city.searchName,
    //           priority: city.priority,
    //           /* icon: 'location_city' */
    //           icon: "C",
    //         });
    //       });
    //       /* _origins.sort((a, b) => b.priority - a.priority); */
    //     }
    //   },
    //   (err) => {
    //     /*  //console.log('Error: ', err); */
    //   },
    //   () => {
    //     this.origins = _origins;
    //   }
    // );
  }

  getDynamicSearch() {
    this.flightService.getDynamicSearch().subscribe(
      (result: any) => {
     

        this.listadoDinamico = result.ldata;
      },
      (error) => {
        //console.log("ERROR: " + JSON.stringify(error));
      },
      () => {
        this.listadoDinamicoN = this.listadoDinamico.filter(
          (x) => x.flightNational == true
        );
        this.listadoDinamicoI = this.listadoDinamico.filter(
          (x) => x.flightNational == false
        );
       
      }
    );
  }

  sideScroll(element, direction, speed, distance, step) {
    var scrollAmount = 0;
    var slideTimer = window.setInterval(function () {
      if (direction == "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  moverIzq(id_container) {
    //console.log("id_container: " + id_container);

    const container = document.getElementById(id_container);
    this.sideScroll(container, "left", 5, 320, 10);
  }

  moverDer(id_container) {
    //console.log("id_container: " + id_container);

    const container = document.getElementById(id_container);
    this.sideScroll(container, "right", 5, 320, 10);
  }

  goToVuelosWord(
    numberPassengers,
    origen,
    destino,
    departureDate,
    arrivalDate,
    origenes
  ) {
    //console.log(departureDate);
    //console.log(arrivalDate);


    let listAir = origenes;
    listAir.forEach(element => {
        if(origen === element.code){
          origen = element;
        }
        if(destino === element.code){
          destino = element;
        }
    });
    const filter = {
      Ocompany: null,
      PartnerClub: false,
      type: "RT",
      lpassenger: [
        {
          numberPassenger: numberPassengers,
          typePassenger: "ADT",
        },
        {
          numberPassenger: "0",
          typePassenger: "CNN",
        },
        {
          numberPassenger: "0",
          typePassenger: "INF",
        },
      ],
      cabinType: {
        id: "",
        description: "Todas",
      },
      scales: {
        id: "",
        description: "Todos",
      },
      includesBaggage: "",
      fromFilter: [
        {
          code: origen.code,
          countryCode: origen.countryCode,
          name: origen.name,
          searchName: origen.searchName,
          priority: origen.priority,
          icon: origen.icon
        },
        {
          code: destino.code,
          countryCode: destino.countryCode,
          name: destino.name,
          searchName: destino.searchName,
          priority: destino.priority,
          icon: destino.icon
        },
      ],
      toFilter: [
        {
          code: destino.code,
          countryCode: destino.countryCode,
          name: destino.name,
          searchName: destino.searchName,
          priority: destino.priority,
          icon: destino.icon,
        },
        {
          code: origen.code,
          countryCode: origen.countryCode,
          name: origen.name,
          searchName: origen.searchName,
          priority: origen.priority,
          icon: origen.icon
        },
      ],
      dateFilter: [departureDate, arrivalDate],
    };
    //dateFilter: [departureDate, arrivalDate],
    //dateFilter: ["2021-09-15T05:00:00.000Z", "2021-09-22T05:00:00.000Z"],

    //console.log(JSON.stringify(filter));

    const jsonfilter = JSON.stringify(filter);

    this.sessionService.setFilterFlightWrapper(JSON.parse(jsonfilter));
    this.router.navigate(["flight/flight-result"]);
  }

  fillOrigen(valor, origenes){
    let origin;
    let origins = origenes;
      origins.forEach(element => {
        if(element.code === valor.iataCode){
          origin = element;
        }
      });
      return origin;
    
  }

  goToVuelos(
    numberPassengers,
    origen,
    destino,
    departureDate,
    arrivalDate,
    item
  ) {
    //console.log(departureDate);
    //console.log(arrivalDate);

    
    let listAir = this.origins;

    origen = this.fillOrigen(origen, listAir);
    destino = this.fillOrigen(destino , listAir);

      listAir.forEach(element => {
        if(origen.code === element.code){
          origen = element;
        }
        if(destino.code === element.code){
          destino = element;
        }
    });
    const filter = {
      Ocompany: null,
      PartnerClub: false,
      type: "RT",
      lpassenger: [
        {
          numberPassenger: numberPassengers,
          typePassenger: "ADT",
        },
        {
          numberPassenger: "0",
          typePassenger: "CNN",
        },
        {
          numberPassenger: "0",
          typePassenger: "INF",
        },
      ],
      cabinType: {
        id: "",
        description: "Todas",
      },
      scales: {
        id: "",
        description: "Todos",
      },
      includesBaggage: "",
      fromFilter: [
        {
          code: origen.code,
          countryCode: origen.countryCode,
          name: origen.name,
          searchName: origen.searchName,
          priority: origen.priority,
          icon: origen.icon
        },
        {
          code: destino.code,
          countryCode: destino.countryCode,
          name: destino.name,
          searchName: destino.searchName,
          priority: destino.priority,
          icon: destino.icon
        },
      ],
      toFilter: [
        {
          code: destino.code,
          countryCode: destino.countryCode,
          name: destino.name,
          searchName: destino.searchName,
          priority: destino.priority,
          icon: destino.icon,
        },
        {
          code: origen.code,
          countryCode: origen.countryCode,
          name: origen.name,
          searchName: origen.searchName,
          priority: origen.priority,
          icon: origen.icon
        },
      ],
      dateFilter: [departureDate, arrivalDate],
    };
 
    const jsonfilter = JSON.stringify(filter);

    this.sessionService.setFilterFlightWrapper(JSON.parse(jsonfilter));
    this.router.navigate(["flight/flight-result"]);
   
  }
}
