import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, ActivationEnd, ActivationStart, ActivatedRoute } from '@angular/router';
import { HeaderService } from './shared/services/head.service';
import { filter } from 'rxjs/operators';
import { LoginService } from './core/services/login.service';
import { ConstantService } from './shared/services/constant.service';
import { SessionService } from './core/services/session.service';
import { formatDate } from '@angular/common';

declare global {
  interface Window {
    _waEmbed: (btnSetting: any, widgetSetting: any) => void;
  }
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [HeaderService]
})
export class AppComponent implements OnInit{

  dataFromParent = 'Datos desde el componente padre';

  routeList = [
    '/operation',
    '/success-payment',
    '/error-payment',
    '/detail',
    '/result',
    '/booking'
  ];
  viewDiscount: boolean = true;

  constructor(private router: Router, public headerService: HeaderService,
    private loginService: LoginService, private constantService: ConstantService, private sessionService: SessionService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.initializeWhatsAppWidget(event.urlAfterRedirects);
        this.viewPackageDiscount(event.urlAfterRedirects);
        (window as any).ga('set', 'page', event.urlAfterRedirects);
        (window as any).ga('send', 'pageview');
        /* (window as any).fbq('track', 'PageView'); */
     }
    
   });
 }
  ngOnInit(): void {
    const token = localStorage.getItem("redis-token");
    const expirationTime = localStorage.getItem("token-expiration");
    const currentTime = new Date().getTime();
  
    if (!token || expirationTime === null || parseInt(expirationTime) < currentTime) {
      // Si no hay token o el token ha expirado, crea uno nuevo
      this.refreshToken();
    } else {
      // El token aún es válido
      console.log("Token válido. No es necesario crear uno nuevo.");
    }
  }

  refreshToken() {
    // Llama al servicio para crear un nuevo token
    this.loginService.CreateToken().subscribe((response) => {
      // Guarda el token en el almacenamiento local
  
      // Calcula el tiempo de expiración del token (30 minutos a partir de ahora)
      const expirationTime = new Date().getTime() + (30 * 60 * 1000); // 30 minutos en milisegundos
      localStorage.setItem("token-expiration", expirationTime.toString());
    });
  }
  
 showOverlay = false;

 containsUrl(urlAfterRedirects: string) {
  let result = '';
  if (urlAfterRedirects === '/') {
    result = '/';
  } else {
    this.routeList.forEach(element => {
      if (urlAfterRedirects.includes(element)) {
        result = element;
      }
    });
  }
  return result;
 }

 enviarDatos(){
  this.headerService.setData('Datos desde el componente padre asdasd');
  let qwe = document.getElementById("fullscreen");

  qwe.style.setProperty("display", "none", "important");

  let abc = document.getElementById("noscreen");
  abc.style.setProperty("display", "initial", "important");
  
 }

 packageDiscount() {
  this.router.navigate(['beneficios-bancos']);
 }

 viewPackageDiscount(currentUrl: string) {
  this.viewDiscount = true;
  switch(true) {
    case (currentUrl.includes('flight/flight-booking')):
      this.viewDiscount = false;
      break;
    case (currentUrl.includes('hotel/hotel-booking')):
      this.viewDiscount = false;
      break;
    case (currentUrl.includes('seguros/insurance-booking')):
      this.viewDiscount = false;
      break;
    case (currentUrl.includes('pack/operation')):
      this.viewDiscount = false;
      break;
    case (currentUrl.includes('flight-hotel/hotel-flight-booking')):
      this.viewDiscount = false;
      break;
  }
 }

 replaceHyphensWithSpaces(text: string): string {
  return text.replace(/-/g, ' ');
}

 initializeWhatsAppWidget(currentUrl: string) {
  let welcomeMessage = 'Hola, Domiruth tengo una consulta acerca de su web de vacaciones';

  let nameUrlPack;

  switch(true) {
    case (currentUrl.includes('/ofertas/ofertas-vuelos-internacionales')):
      welcomeMessage = 'Hola Domiruth, vengo de la sección *VUELOS INTERNACIONALES* de su web y quisiera que me puedan asesorar';
      break;
    case (currentUrl.includes('/ofertas/ofertas-vuelos-nacionales')):
      welcomeMessage = 'Hola Domiruth, vengo de la sección *VUELOS NACIONALES* de su web y quisiera que me puedan asesorar';
      break;
    case (currentUrl.includes('/pack/detail/')):
      nameUrlPack = currentUrl.split('/');
      let name = this.replaceHyphensWithSpaces(nameUrlPack[3] != null || nameUrlPack[3] != undefined ? nameUrlPack[3] : "Paquetes");
      welcomeMessage = 'Hola Domiruth, me podrían brindar más información sobre su paquete - ' + "*" + name + "*";
      break;
    case (currentUrl.includes('/hotel/hotel-select')):
      let habi = this.sessionService.getInfoHabi();
      if(habi != null || habi != undefined){
      welcomeMessage = 'Hola Domiruth, me podrían brindar más información sobre el hotel - ' + "*" + habi.ohotel.hotelName + " de " + habi.ohotel.cityName + " desde " + habi.ohotel.startDateShow + " al " + habi.ohotel.endDateShow + "*";
      }else {
        welcomeMessage = 'Hola Domiruth, me podrían brindar más información sobre los hoteles'
      }
      break;
    case (currentUrl.includes('/seguros/insurance-select')):
      let objeto = this.sessionService.getLlenarDatos();
      if(objeto != null || objeto != undefined){
        let destino = objeto.Destino === "Nacional" || objeto.Destino === "Internacional" ? objeto.Destino + "es" : "Domiruth";
        welcomeMessage = 'Hola Domiruth, me podrían asesorar con sus opciones de seguros, para *Viajes ' + destino + "*";
      }else {
        welcomeMessage = 'Hola Domiruth, me podrían asesorar con sus opciones de *seguros*';
      }
      break;
    case (currentUrl.includes('/ofertas/ofertas-europa')):
      welcomeMessage = 'Hola Domiruth, vengo de la sección *OFERTAS EUROPA* de su web y quisiera que me puedan asesorar';
      break;
    case (currentUrl.includes('/ofertas/ofertas-caribe')):
      welcomeMessage = 'Hola Domiruth, vengo de la sección *OFERTAS CARIBE* de su web y quisiera que me puedan asesorar';
      break;
    case (currentUrl.includes('/beneficios-bancos') && currentUrl === "/beneficios-bancos"):
      welcomeMessage = 'Hola Domiruth, vengo de la sección *BENEFICIOS BANCOS* de su web y quisiera que me puedan asesorar';
      break;
    case (currentUrl.includes('/beneficios-bancos/bcp')):
      welcomeMessage = 'Hola Domiruth, vengo de la sección *BENEFICIOS BCP* de su web y quisiera que me puedan asesorar';
      break;
    case (currentUrl.includes('/beneficios-bancos/bbva')):
      welcomeMessage = 'Hola Domiruth, vengo de la sección *BENEFICIOS BBVA* de su web y quisiera que me puedan asesorar';
      break;
    case (currentUrl.includes('/beneficios-bancos/banbif')):
      welcomeMessage = 'Hola Domiruth, vengo de la sección *BENEFICIOS BANBIF* de su web y quisiera que me puedan asesorar';
      break;
    case (currentUrl.includes('/flight/flight-result') && currentUrl === '/flight/flight-result'):
      let fligth = this.sessionService.getFilterFlightWrapper();
      if(fligth != null || fligth != undefined){
        if(fligth.type === "RT"){
          welcomeMessage = 'Hola Domiruth, vengo de la sección de *Búsqueda de Vuelos* ida y vuelta, realice una búsqueda de ' 
                            + fligth.fromFilter[0].cityName + ' a ' + fligth.toFilter[0].cityName + '. Ida: ' 
                            + formatDate(fligth.dateFilter[0].toString(), 'EEEE dd MMMM', 'es-PE', 'America/Lima')
                            + ' ,Vuelta: ' + formatDate(fligth.dateFilter[1].toString(), 'EEEE dd MMMM', 'es-PE', 'America/Lima');
        }else if (fligth.type === "OW"){
          welcomeMessage = 'Hola Domiruth, vengo de la sección de *Búsqueda de Vuelos* sólo ida, realice una búsqueda de '
                            + fligth.fromFilter[0].cityName + ' a ' + fligth.toFilter[0].cityName + ' para la fecha '
                            + formatDate(fligth.dateFilter[0].toString(), 'EEEE dd MMMM', 'es-PE', 'America/Lima');
        }else if (fligth.type === "MC"){
          let namesOrigin = ' ';
          fligth.fromFilter.forEach(element => {
            namesOrigin += element.cityName + ", ";
          });
          let namesDestino = ' ';
          fligth.toFilter.forEach(x => {
            namesDestino += x.cityName + ", ";
          });
          welcomeMessage = 'Hola Domiruth, vengo de la sección de *Búsqueda de Vuelos* multidestino, realice una búsqueda con los ORIGENES: ' 
                            + namesOrigin + "y los DESTINOS: " + namesDestino;
        }
      }else {
      welcomeMessage = 'Hola Domiruth, vengo de la sección *Búsqueda de Vuelos* de su web y quisiera que me puedan asesorar';
      }
      break;
      case (currentUrl.includes('/flight-hotel/hotel-result')):
        let hotelfligth = this.sessionService.getHotelFilter();
        if(hotelfligth != null || hotelfligth != undefined){
          welcomeMessage = 'Hola Domiruth, vengo de la sección de *Búsqueda de Vuelos + Hotel*, realice una búsqueda de ' 
                            + hotelfligth.from.cityName + ' a ' + hotelfligth.to.cityName + '. Desde el '
                            + formatDate(hotelfligth.departureDate, 'EEEE dd MMMM', 'es-PE', 'America/Lima') + ' hasta el ' + formatDate(hotelfligth.returnDate, 'EEEE dd MMMM', 'es-PE', 'America/Lima');
        }else {
        welcomeMessage = 'Hola Domiruth, vengo de la sección de *Búsqueda de Vuelos + Hotel* de su web y quisiera que me puedan asesorar';
        }
        break;
      case (currentUrl.includes('/hotel/hotel-list')):
        let onlyhotel = this.sessionService.getHotelFilter();
        if(onlyhotel != null || onlyhotel != undefined){
          welcomeMessage = 'Hola Domiruth, vengo de la sección de *Búsqueda de Hoteles*, realice una búsqueda para ' 
          + onlyhotel.to.cityName + '. Desde el '
          + formatDate(onlyhotel.departureDate, 'EEEE dd MMMM', 'es-PE', 'America/Lima') + ' hasta el ' + formatDate(onlyhotel.returnDate, 'EEEE dd MMMM', 'es-PE', 'America/Lima')
        }else {
          welcomeMessage = 'Hola Domiruth, vengo de la sección de *Búsqueda de Hoteles*'
        }
        break;
  }

  const wa_btnSetting = {
    "btnColor": "#16BE45",
    "ctaText": "WhatsApp",
    "cornerRadius": 40,
    "marginBottom": 20,
    "marginLeft": 20,
    "marginRight": 20,
    "btnPosition": "right",
    "whatsAppNumber": "+51965329933",
    "welcomeMessage": welcomeMessage,
    "zIndex": 1000,
    "btnColorScheme": "light"
  };

  const wa_widgetSetting = {
    "title": "Domiruth",
    "subTitle": "",
    "headerBackgroundColor": "#ffffff",
    "headerColorScheme": "dark",
    "greetingText": "Hola.\nTengo una consulta...",
    "ctaText": "Iniciar Chat",
    "btnColor": "#1A1A1A",
    "cornerRadius": 40,
    "welcomeMessage": "Hello",
    "btnColorScheme": "light",
    "brandImage": "https://uploads-ssl.webflow.com/5f68a65cd5188c058e27c898/6204c4267b92625c9770f687_whatsapp-chat-widget-dummy-logo.png",
    "darkHeaderColorScheme": {
      "title": "#333333",
      "subTitle": "#4F4F4F"
    }
  };

  const existingWidget = document.getElementById('wa-btn-wrapper');
    if (existingWidget) {
      existingWidget.remove();
    }

  const existingWidgetWrapper = document.getElementById('wa-widget-wrapper');
  if (existingWidgetWrapper) {
    existingWidgetWrapper.remove();
  }

  // Inicializa el widget de WhatsApp
  window._waEmbed(wa_btnSetting, wa_widgetSetting);
}
}
