import { Component, Inject, OnInit, ViewChildren } from '@angular/core';
import { MatAccordion, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from '../../models/dialog-data.model';

@Component({
  selector: 'app-dialog-requisitos',
  templateUrl: './dialog-requisitos.component.html',
  styleUrls: ['./dialog-requisitos.component.css']
})
export class DialogRequisitosComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  @ViewChildren(MatAccordion) accordion: MatAccordion;
  ngOnInit() {
    
    console.log(this.data);
  }

  abrir(link){
    window.open(link, "_blank");
  }

}
