import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { PackageService } from 'src/app/core/services/package.service';
import { SessionService } from 'src/app/core/services/session.service';
import * as crypto from 'crypto-js';
import { ActivatedRoute, Router } from '@angular/router';
import { AES, enc } from 'crypto-js';
import { DialogFinishComponent } from '../../dialog-social-login/dialog-finish/dialog-finish.component';


@Component({
  selector: 'app-coupon-register',
  templateUrl: './coupon-register.component.html',
  styleUrls: ['./coupon-register.component.css'],
  providers: [PackageService]
})
export class CouponRegisterComponent implements OnInit {
  typeVal = "F3F05B20-412E-4A1A-BA31-B69B1E6D0392";
  documentType;
  finish;
  gender = "M";
  nombreVal = "";
  apellidoVal = "";
  numDoc = "";

  personId;
  userId;
  fecNac = "";
  textcontra = "";
  correoVal = "";
  validarInputs = true;
  valordoc;
  validDoc = true;
  contraVal = "";
  sendMax;
  codeInv = "";
  phone;
  registerGoogle;
  constructor(private router: Router, public dialog: MatDialog, private route: ActivatedRoute, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private sessionService: SessionService, private packageService: PackageService) { }

  ngOnInit() {
    this.document();
    this.ver();
  }

  ver() {
    this.route.queryParams.subscribe(params => {
      const serializedData = params['data'];
      if (serializedData) {
        let objetoDesencriptado = null;
        const bytes = AES.decrypt(serializedData, "domiruth");
        objetoDesencriptado = JSON.parse(bytes.toString(enc.Utf8));
        this.phone = objetoDesencriptado.number;
        this.nombreVal = objetoDesencriptado.validate.opersonVacational.name;
        this.apellidoVal = objetoDesencriptado.validate.opersonVacational.lastName;
        this.numDoc = objetoDesencriptado.validate.opersonVacational.documentNumber;
        this.typeVal = objetoDesencriptado.validate.opersonVacational.documentType;
        this.fecNac = objetoDesencriptado.validate.opersonVacational.birthDate.substring(0, 10);
        this.userId = objetoDesencriptado.validate.opersonVacational.userID;
        this.personId = objetoDesencriptado.validate.opersonVacational.personID;
        this.registerGoogle = objetoDesencriptado.validate.opersonVacational.registeredByGoogle
      }
    });
  }

  esEmailValido(): boolean {
    let mailValido = false;
    'use strict';
    let valid = document.getElementById("conti");
    var EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (this.correoVal.match(EMAIL_REGEX)) {
      mailValido = true;
      valid.style.backgroundColor = "#E82D56";
      valid.style.pointerEvents = "visible";
    } else {
      valid.style.backgroundColor = "rgba(232, 45, 86, 0.5)";
      valid.style.pointerEvents = "none";
    }
    return mailValido;
  }

  document() {
    this.packageService.getDocumentList(false).subscribe(
      result => {
        this.documentType = result.ldocumentTypeLists;
      },
      err => {

      },
      () => {

      }
    );
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 5000,
    });
  }

  Solotexto(event) {
    var regex = new RegExp("^[a-zA-Z ]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }

  ValidarSoloNumero(event) {
    // tslint:disable-next-line: max-line-length
    if (this.typeVal === "F3F05B20-412E-4A1A-BA31-B69B1E6D0392") {
      if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105) && event.keyCode !== 190 && event.keyCode !== 110 && event.keyCode !== 8 && event.keyCode !== 9) {
        return false;
      }
    } else {
      var regex = new RegExp("^[a-zA-Z1-9 ]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        if (event.code != "Backspace") {
          event.preventDefault();
          return false;
        }

      }
    }

  }

  setIdDoc() {
    //console.log(this.typeVal);
    this.valordoc = document.getElementById("nroDoc");
    this.valordoc.value = "";
    if (this.typeVal === "F3F05B20-412E-4A1A-BA31-B69B1E6D0392") {
      this.valordoc.maxLength = 8;
    } else {
      this.valordoc.maxLength = 12;
    }
  }

  validpassword() {
    let stilo = document.getElementById("contra");
    let texta = document.getElementById("textoContra");
    var strongRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");
    var mediumRegex = new RegExp("^(?=.{7,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$", "g");
    var enoughRegex = new RegExp("(?=.{6,}).*", "g");
    if (false == enoughRegex.test(this.contraVal)) {
      this.textcontra = "Baja";
      texta.style.color = "#E2231A";
      stilo.style.border = "1px solid #E2231A";
    } else if (strongRegex.test(this.contraVal)) {
      this.textcontra = "Muy alto";
      texta.style.color = "#44C851";
      stilo.style.border = "1px solid #44C851";
    } else if (mediumRegex.test(this.contraVal)) {
      this.textcontra = "Media";
      texta.style.color = "#FFC107";
      stilo.style.border = "1px solid #FFC107";
    } else {
      this.textcontra = "Baja";
      texta.style.color = "#E2231A";
      stilo.style.border = "1px solid #E2231A";
    }
    return true;
  }

  openDialog() {

    const dialogRef = this.dialog.open(DialogFinishComponent, {
      data: '',
      panelClass: 'custom-dialog-container',
      maxWidth: 800

    });

    dialogRef.afterClosed().subscribe(result => {
      /* //console.log('The dialog was closed'); */
      if (result) {
        this.finish = result;
      }
    });
  }

  registerUser() {
    if (this.nombreVal === "" || this.apellidoVal === "" || this.typeVal === "" || this.numDoc === "" || this.fecNac === "" || this.contraVal === "" || this.correoVal === "") {
      this.openSnackBar("Por favor rellene todos los campos");
      return;
    }
    if (this.textcontra != "Muy alto") {
      this.openSnackBar("Por favor siga las instrucciones al crear contraseña.");
      return;
    }
    let numDocLegn = this.numDoc.length;
    if (numDocLegn === 0) {
      this.openSnackBar("Por favor completar numero de documento.");
      return;
    }

    this.spinner.show();

    let lstDoc = [];

    let docs = {
      DocumentID: this.typeVal,
      Number: this.numDoc,
      IsActive: true
    }

    lstDoc.push(docs);
    if(this.phone === null){
      this.phone = ""; 
    }

    let obj = {
      IsRegister: true,
      Name: this.nombreVal,
      LastName: this.apellidoVal,
      PersonalPhone: this.phone,
      PersonalEmail: this.correoVal,
      BirthDate: this.fecNac,
      Gender: this.gender,
      CountryOrigin: "PE",
      InvitationCode: this.codeInv,
      ByGoogle: this.registerGoogle,
      IsVacational: true,
      LpersonDocuments: lstDoc,
      Ouser: {
        Password:crypto.SHA256(this.contraVal).toString(),
        AccessGranted: true,
        IsActive: true
      }

     /*  UserID: this.userId,
      PersonID: this.personId,
      
      
      
      DocumentType: this.typeVal,
      DocumentNumber: this.numDoc,
      
      
      Password: crypto.SHA256(this.contraVal).toString() */
    }
    this.packageService.sendRegister(obj).subscribe(
      x => {
        if (x.status === 200) {
          if (x.odata != null) {
            const obj = {
              userId: x.odata.userID,
              email: x.odata.email,
              nombre: x.odata.name,
              apellido: x.odata.lastName,
              phone: x.odata.phone,
              genero: x.odata.gender,
              countryCode: x.odata.countryOrigin,
              documentNumber: x.odata.lpersonDocument[0].documentNumber,
              typeDocument: x.odata.lpersonDocument[0].documentID,
              birthDate: x.odata.birthDate
            }
            this.sessionService.setEmailFinish(obj);
            if (this.phone === null) {
              const encryptionKey = 'domiruth';
              const objetoEncriptado = AES.encrypt(JSON.stringify(x), encryptionKey).toString();
              this.router.navigate(['auth/coupon-confirm-phone'], { queryParams: { data: objetoEncriptado } });

            } else {
              this.openDialog();
            }

          }

        } else {
          this.openSnackBar(x.message);
        }


        this.spinner.hide();

      }
    )

  }

}
