import { Component, HostListener, Input, OnInit, ElementRef, AfterViewInit, Type } from '@angular/core';
import { Router } from '@angular/router';
import { FlightService } from 'src/app/core/services/flight.service';
import { SessionService } from 'src/app/core/services/session.service';
import { airportsData } from '../../services/airport.const';

@Component({
  selector: 'dm-new-destinations-carousel',
  templateUrl: './new-destinations-carousel.component.html',
  styleUrls: ['./new-destinations-carousel.component.css']
})
export class NewDestinationsCarouselComponent implements OnInit, AfterViewInit {

  @Input() buscar: any[];
  @Input() validSearch: any;
  @Input() showFlight: any;
  @Input() origenes: any[];
  @Input() validResponse: any;

  listadoDinamico: any[] = [];
  listadoDinamicoN: any[] = [];
  listadoDinamicoI: any[] = [];
  /* listadoDinamicoA: any[] = []; */
  listadoDinamicoS: any[] = [];
  urlDinamicoS: string;
  urlDinamicoI: string;
  urlDinamicoA: string;
  fromFilter: any[] = [];
  toFilter: any[] = [];
  dateFilter: any[] = [];
  origins = [];
  originList = [];
  destinationList = [];
  url;
  validTodo = false;
  dataAirport: any;
  idUrl: string;
  listAir: any;
  public myObject!: { id: number, myObject: { myString: string } };
  constructor(
    private flightService: FlightService,
    private router: Router,
    private elementRef: ElementRef,
    private sessionService: SessionService,
  ) {
    this.dataAirport = airportsData;
  }

  ngOnInit() {
    if(this.origenes != null && this.origenes != undefined){
      if(this.origenes.length > 0){
        this.origins = this.origenes;
      }
    }
    if(this.showFlight === "Todos"){
      this.validTodo = true;
    } else {
      this.validTodo = false;
    }
    /* if(this.buscar === true){
      this.getDynamicSearch();
    } */
    this.listadoDinamico = this.buscar;
    if (this.validSearch) {
      this.listadoDinamicoS = this.listadoDinamico.filter(
        (x) => x.flightNational == true && x.ocarrier.marketingCode == "H2"
      );
      this.urlDinamicoS = "/flight?skyNational";

      this.listadoDinamicoI = this.listadoDinamico.filter(
        (x) => x.flightNational == false && x.ocarrier.marketingCode == "H2"
      );
      this.urlDinamicoI = "/flight?skyInternational";
      
      this.listadoDinamicoI = this.listadoDinamicoI.filter(
        (x) => x.ocarrier.marketingCode == "H2"
      );
      /* this.listadoDinamicoA = this.listadoDinamico.filter(
        (x) => x.ocarrier.marketingCode == "UX"
      );

      this.urlDinamicoA = "/flight?airEuropa"; */

    } else {
      this.listadoDinamicoN = this.listadoDinamico.filter(
        (x) => x.flightNational == true
      );

      this.listadoDinamicoI = this.listadoDinamico.filter(
        (x) => x.flightNational == false
      );
      
      this.urlDinamicoI = "/flight?international";
    }

    this.url = window.location.href;
    this.url = this.url.split("/");
    this.url = this.url[1];
    if (this.url === '' || this.url === null || this.url === undefined) {
      return;
    } else {
      this.url = this.url.split("$");
      //console.log(this.url)
        /* if (this.listAir === null || this.listAir === undefined) { */
          this.getAirports();
        /* } else {
          this.goToVuelosWord(this.url[0], this.url[1], this.url[2], this.url[3], this.url[4]);
        } */


    }
    
  }

  ngAfterViewInit() {
      let locat = window.location.pathname;
      let locat2 = window.location.search;
    if(locat2 != ""){
      let located2 = locat2.split('?');
      let quitar = located2[1].split('=');

    switch(locat + '?' + quitar[0]){
      case "/flight?skyNational":
        this.idUrl = "loenardo";
        break;
      case "/flight?airEuropa":
        this.idUrl = "airEur";
        break;
      case "/flight?skyInternational":
        this.idUrl = "intersky";
        break;
      case "/flight?international":
        this.idUrl = "newdestini";
        break;
    }
    setTimeout(() => {
      if(this.idUrl != null || this.idUrl != undefined){
        document.body.style.overflowY = "hidden";
        const targetElement = this.elementRef.nativeElement.querySelector('#'+this.idUrl);
        if(targetElement){
          targetElement.scrollIntoView({ behavior: 'smooth' });
          setTimeout(() => {
            document.body.style.overflowY = "auto";
          }, 1500);
        }else {
          document.body.style.overflowY = "auto";
        }
      }
    }, 1000);
  }
  }

  getPriorityAirports() {
    /* this.spinner.show(); */
    this.flightService.getPriorityAirports().subscribe(
      (result) => {
        /* //console.log(result); */
        if (result) {
          const airports = result.lairports ? result.lairports : [];
          const cities = result.lcities ? result.lcities : [];
          airports.forEach((airport) => {
            this.origins.push({
              code: airport.iataCode,
              countryCode: airport.countryCode,
              name: airport.name,
              searchName: airport.searchName,
              priority: airport.priority,
              /* icon: 'local_airport' */
              icon: "A",
            });
          });
          cities.forEach((city) => {
            this.origins.push({
              code: city.iataCode,
              countryCode: city.countryCode,
              name: city.name,
              searchName: city.searchName,
              priority: city.priority,
              /* icon: 'location_city' */
              icon: "C",
            });
          });
          /* this.origins.sort((a, b) => b.priority - a.priority);  */
        }
      },
      (err) => {
        /*   //console.log('Error: ', err); */
      },
      () => {
        this.goToVuelosWord(this.url[0], this.url[1], this.url[2], this.url[3], this.url[4]);
        this.getAirports();
      }
    );
  }

  getAirports() {
    const _origins = [];
    let result = this.dataAirport;
    if (result) {
      const airports = result.lairports ? result.lairports : [];
      const cities = result.lcities ? result.lcities : [];
      airports.forEach((airport) => {
        _origins.push({
          code: airport.iataCode,
          countryCode: airport.countryCode,
          name: airport.name,
          searchName: airport.searchName,
          priority: airport.priority,
          cityName: airport.cityName,
          /* icon: 'local_airport' */
          icon: "A",
        });
      });
      cities.forEach((city) => {
        _origins.push({
          code: city.iataCode,
          countryCode: city.countryCode,
          name: city.name,
          searchName: city.searchName,
          priority: city.priority,
          cityName: city.cityName,
          /* icon: 'location_city' */
          icon: "C",
        });
      });
      /* _origins.sort((a, b) => b.priority - a.priority); */
    }

    this.origins = _origins;

    this.goToVuelosWord(this.url[0], this.url[1], this.url[2], this.url[3], this.url[4]);
    
    // this.flightService.getAirports().subscribe(
    //   (result) => {
    //     if (result) {
    //       const airports = result.lairports ? result.lairports : [];
    //       const cities = result.lcities ? result.lcities : [];
    //       airports.forEach((airport) => {
    //         _origins.push({
    //           code: airport.iataCode,
    //           countryCode: airport.countryCode,
    //           name: airport.name,
    //           searchName: airport.searchName,
    //           priority: airport.priority,
    //           cityName: airport.cityName,
    //           /* icon: 'local_airport' */
    //           icon: "A",
    //         });
    //       });
    //       cities.forEach((city) => {
    //         _origins.push({
    //           code: city.iataCode,
    //           countryCode: city.countryCode,
    //           name: city.name,
    //           searchName: city.searchName,
    //           priority: city.priority,
    //           cityName: city.cityName,
    //           /* icon: 'location_city' */
    //           icon: "C",
    //         });
    //       });
    //       /* _origins.sort((a, b) => b.priority - a.priority); */
    //     }
    //   },
    //   (err) => {
    //     /*  //console.log('Error: ', err); */
    //   },
    //   () => {
    //     this.origins = _origins;
    //   }
    // );
  }

  /* getDynamicHotel() {
    this.flightService.getDynamicSearchHotel().subscribe(
      (result: any) => {


        this.listadoDinamico = result;
      },
      (error) => {
        //console.log("ERROR: " + JSON.stringify(error));
      },
      () => {
        this.listadoDinamicoN = this.listadoDinamico.filter(
          (x) => x.flightNational == true
        );

        this.listadoDinamicoI = this.listadoDinamico.filter(
          (x) => x.flightNational == false
        );
      }
    );
  } */

  getDynamicSearch() {

    if (this.validSearch) {
      this.flightService.getDynamicSearchAirline("V", true).subscribe(
        (result: any) => {


          this.listadoDinamico = result.ldata;

        },
        (error) => {
          //console.log("ERROR: " + JSON.stringify(error));
        },
        () => {
          /* this.listadoDinamicoI = this.listadoDinamico; */
          this.listadoDinamicoS = this.listadoDinamico.filter(
            (x) => x.flightNational == true
          );

          this.listadoDinamicoI = this.listadoDinamico.filter(
            (x) => x.flightNational == false
          );
        }
      );
    } else {
      this.flightService.getDynamicSearch().subscribe(
        (result: any) => {


          this.listadoDinamico = result.ldata;
        },
        (error) => {
          //console.log("ERROR: " + JSON.stringify(error));
        },
        () => {
          this.listadoDinamicoN = this.listadoDinamico.filter(
            (x) => x.flightNational == true
          );

          this.listadoDinamicoI = this.listadoDinamico.filter(
            (x) => x.flightNational == false
          );
        }
      );
    }

  }

  sideScroll(element, direction, speed, distance, step) {
    var scrollAmount = 0;
    var slideTimer = window.setInterval(function () {
      if (direction == "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  moverIzq(id_container) {
    //console.log("id_container: " + id_container);

    const container = document.getElementById(id_container);
    this.sideScroll(container, "left", 5, 320, 10);
  }

  moverDer(id_container) {
    //console.log("id_container: " + id_container);

    const container = document.getElementById(id_container);
    this.sideScroll(container, "right", 5, 320, 10);
  }

  goToVuelosWord(
    numberPassengers,
    origen,
    destino,
    departureDate,
    arrivalDate
  ) {
    //console.log(departureDate);
    //console.log(arrivalDate);


    let listAir = this.origins;
    listAir.forEach(element => {
      if (origen === element.code) {
        origen = element;
      }
      if (destino === element.code) {
        destino = element;
      }
    });
    const filter = {
      Ocompany: null,
      PartnerClub: false,
      type: "RT",
      lpassenger: [
        {
          quantity: numberPassengers,
          type: "ADT",
        },
        {
          quantity: 0,
          type: "CHD",
        },
        {
          quantity: 0,
          type: "INF",
        },
      ],
      cabinType: {
        id: "",
        description: "Todas",
      },
      scales: {
        id: "",
        description: "Todos",
      },
      includesBaggage: "",
      fromFilter: [
        {
          code: origen.code,
          countryCode: origen.countryCode,
          name: origen.name,
          searchName: origen.searchName,
          priority: origen.priority,
          icon: origen.icon
        },
        {
          code: destino.code,
          countryCode: destino.countryCode,
          name: destino.name,
          searchName: destino.searchName,
          priority: destino.priority,
          icon: destino.icon
        },
      ],
      toFilter: [
        {
          code: destino.code,
          countryCode: destino.countryCode,
          name: destino.name,
          searchName: destino.searchName,
          priority: destino.priority,
          icon: destino.icon,
        },
        {
          code: origen.code,
          countryCode: origen.countryCode,
          name: origen.name,
          searchName: origen.searchName,
          priority: origen.priority,
          icon: origen.icon
        },
      ],
      dateFilter: [departureDate, arrivalDate],
    };
    //dateFilter: [departureDate, arrivalDate],
    //dateFilter: ["2021-09-15T05:00:00.000Z", "2021-09-22T05:00:00.000Z"],

    //console.log(JSON.stringify(filter));

    const jsonfilter = JSON.stringify(filter);

    this.sessionService.setFilterFlightWrapper(JSON.parse(jsonfilter));
    this.router.navigate(["flight/flight-result"]);
  }

  fillOrigen(valor) {
    let origin = null;
    let origins;


      origins = this.origins;
      let resultado = origins.filter(item => item.code === valor);
      origin = resultado[0];
      return origin;
  }

  onSelectItem(item) {
    console.log('emit', item)
    this.goToVuelos(item.numberPassengers, item.oorigin, item.odestination, item.departureDate, item.arrivalDate, item);
  }

  goToVuelos(
    numberPassengers,
    origen,
    destino,
    departureDate,
    arrivalDate,
    item
  ) {
 
    if(this.origenes != null && this.origenes != undefined){
      if(this.origenes.length > 0){
        this.origins = this.origenes;
      }
    }
   
    let resultado = this.origins.filter(x => x.code === origen.iataCode);
    origen = resultado[0];
    let resultado1 = this.origins.filter(x => x.code === destino.iataCode);
    destino = resultado1[0];
    const filter = {
      Ocompany: null,
      PartnerClub: false,
      type: "RT",
      lpassenger: [
        {
          quantity: numberPassengers,
          type: "ADT",
        },
        {
          quantity: 0,
          type: "CHD",
        },
        {
          quantity: 0,
          type: "INF",
        },
      ],
      cabinType: {
        id: "",
        description: "Todas",
      },
      scales: {
        id: "",
        description: "Todos",
      },
      includesBaggage: "",
      fromFilter: [
        {
          cityName: origen.cityName,
          code: origen.code,
          countryCode: origen.countryCode,
          name: origen.name,
          searchName: origen.searchName,
          priority: origen.priority,
          icon: origen.icon
        },
        {
          cityName: destino.cityName,
          code: destino.code,
          countryCode: destino.countryCode,
          name: destino.name,
          searchName: destino.searchName,
          priority: destino.priority,
          icon: destino.icon
        },
      ],
      toFilter: [
        {
          cityName: destino.cityName,
          code: destino.code,
          countryCode: destino.countryCode,
          name: destino.name,
          searchName: destino.searchName,
          priority: destino.priority,
          icon: destino.icon
        },
        {
          cityName: origen.cityName,
          code: origen.code,
          countryCode: origen.countryCode,
          name: origen.name,
          searchName: origen.searchName,
          priority: origen.priority,
          icon: origen.icon
        },
      ],
      dateFilter: [departureDate, arrivalDate],
    };


    const jsonfilter = JSON.stringify(filter);

    this.sessionService.setFilterFlightWrapper(JSON.parse(jsonfilter));

    let url = window.location.origin  + "/flight/flight-result";
    this.openNewTab(url);
    /* this.router.navigate(["/flight/flight-result"]); */

  }

  openNewTab(url: string) {
    window.open(url, '_blank');
  }




}
