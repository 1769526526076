import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'dm-button',
  templateUrl: './dm-button.component.html',
  styleUrls: ['./dm-button.component.css']
})
export class DmButtonComponent implements OnInit {

  @Input() responsive: boolean;
  @Input() leftIcon: string;
  //assets/images/home/search.svg
  @Input() rightIcon: string;
  @Input() label: string;
  // @Input() width: number;
  // @Input() height: number;
  @Output() selectItem = new EventEmitter();

  // public scrWidth:any;
  // currentResponsiveMode: number = -1;
  // initialWidth: number;

  // @HostListener('window:resize')
  // getScreenSize() {
  //   console.log('width', this.width);
  //   if (this.responsive) {
  //     this.scrWidth = window.innerWidth;
  //     let elementsView = this.getElementsView();
  //     console.log('elementsView', elementsView);
  //     // if (this.currentResponsiveMode !== elementsView){
  //       console.log('change');
  //       if (elementsView === 0){
  //         console.log('change 0');
  //         this.initialWidth = this.scrWidth - 16;
          
  //       }
          
  //       else {
  //         console.log('change 1');
  //         this.initialWidth = this.width;
  //       }
  //       this.currentResponsiveMode = elementsView;
  //     // }
  //   }
  // }

  // getElementsView():number {
  //   if (this.scrWidth > 760) {
  //     return 1;
  //   } else {
  //     return 0;
  //   }
  // }

  constructor() { }

  ngOnInit() {
    // this.initialWidth = this.width;
  }

  onClick(){
    console.log('click on dm-button');
    this.selectItem.emit();
  }
}
