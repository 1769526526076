import { Component, OnInit, Input } from "@angular/core";
import { SessionService } from "src/app/core/services/session.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { airportsData } from "src/app/shared/services/airport.const";

declare var jquery: any;
declare var $: any;

@Component({
  selector: "app-destino-nacional",
  templateUrl: "./destino-nacional.component.html",
  styleUrls: ["./destino-nacional.component.css"],
})
export class DestinoNacionalComponent implements OnInit {
  @Input() listadoDinamicoN: any[] = [];
  fromFilter: any[] = [];
  toFilter: any[] = [];
  dateFilter: any[] = [];
  dataAirport: any;
  origins = [];
  public myObject!: { id: number, myObject: { myString: string } };
  constructor(
    private sessionService: SessionService,
    private router: Router
  ) {
    this.dataAirport = airportsData;
  }

  ngOnInit() {
    this.getAirports();
  }

  getAirports() {
    const _origins = [];
    let result = this.dataAirport;
    if (result) {
      const airports = result.lairports ? result.lairports : [];
      const cities = result.lcities ? result.lcities : [];
      airports.forEach((airport) => {
        _origins.push({
          code: airport.iataCode,
          countryCode: airport.countryCode,
          name: airport.name,
          searchName: airport.searchName,
          priority: airport.priority,
          cityName: airport.cityName,
          icon: "A",
        });
      });
      cities.forEach((city) => {
        _origins.push({
          code: city.iataCode,
          countryCode: city.countryCode,
          name: city.name,
          searchName: city.searchName,
          priority: city.priority,
          cityName: city.cityName,
          icon: "C",
        });
      });
    }

    this.origins = _origins;

  }

  sideScroll(element, direction, speed, distance, step) {
    var scrollAmount = 0;
    var slideTimer = window.setInterval(function () {
      if (direction == "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  moverIzq(id_container) {
    //console.log("id_container: " + id_container);

    const container = document.getElementById(id_container);
    this.sideScroll(container, "left", 5, 320, 10);
  }

  moverDer(id_container) {
    //console.log("id_container: " + id_container);

    const container = document.getElementById(id_container);
    this.sideScroll(container, "right", 5, 320, 10);
  }

  fillDestino(valor){
    let origins = this.origins;
    let destino;
      origins.forEach(element => {
        if(element.code === valor){
          origin = element;
        }
      });
      return destino;
  }

  goToVuelos(numberPassengers, origen, destino, departureDate, arrivalDate) {
    //console.log(departureDate);
    //console.log(arrivalDate);
    origen = this.fillDestino(origen);
    destino = this.fillDestino(destino);
    this.sessionService.setRecomendations(null);
    const filter = {
      Ocompany: null,
      PartnerClub: false,
      type: "RT",
      lpassenger: [
        {
          numberPassenger: numberPassengers,
          typePassenger: "ADT",
        },
        {
          numberPassenger: "0",
          typePassenger: "CNN",
        },
        {
          numberPassenger: "0",
          typePassenger: "INF",
        },
      ],
      cabinType: {
        id: "",
        description: "Todas",
      },
      scales: {
        id: "",
        description: "Todos",
      },
      includesBaggage: "",
      fromFilter: [
        {
          code: origen.code,
          countryCode: origen.countryCode,
          name: origen.name,
          searchName: origen.searchName,
          priority: 2,
          icon: "A",
        },
        {
          code: destino.code,
          countryCode: destino.countryCode,
          name: destino.name,
          searchName: destino.searchName,
          priority: 1,
          icon: "A",
        },
      ],
      toFilter: [
        {
          code: destino.code,
          countryCode: destino.countryCode,
          name: destino.name,
          searchName: destino.searchName,
          priority: 1,
          icon: "A",
        },
        {
          code: origen.code,
          countryCode: origen.countryCode,
          name: origen.name,
          searchName: origen.searchName,
          priority: 2,
          icon: "A",
        },
      ],
      dateFilter: [departureDate, arrivalDate],
    };
    //dateFilter: [departureDate, arrivalDate],
    //dateFilter: ["2021-09-15T05:00:00.000Z", "2021-09-22T05:00:00.000Z"],

    //console.log(JSON.stringify(filter));

    const jsonfilter = JSON.stringify(filter);

    this.sessionService.setFilterFlightWrapper(JSON.parse(jsonfilter));
    this.router.navigate(["flight/flight-result"]);
  }
}
