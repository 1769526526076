import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { PackageService } from 'src/app/core/services/package.service';
import { Socialusers } from '../../models/socialUsers.model';
import { SessionService } from '../../../core/services/session.service';
import jwt_decode from "jwt-decode";
import { DialogRegisterGoogleComponent } from 'src/app/auth/dialog-social-login/dialog-register-google/dialog-register-google.component';
import { DialogValidPhoneComponent } from 'src/app/auth/dialog-social-login/dialog-valid-phone/dialog-valid-phone.component';
import { DialogFinishComponent } from 'src/app/auth/dialog-social-login/dialog-finish/dialog-finish.component';
import { DialogCorreoComponent } from 'src/app/auth/dialog-social-login/dialog-correo/dialog-correo.component';
import { RegisterGoogleComponent } from './register-google/register-google.component';
import { ValidPhoneComponent } from './valid-phone/valid-phone.component';
import { FinishComponent } from './finish/finish.component';
import { CorreoComponent } from './correo/correo.component';
import { Router } from '@angular/router';
import { DialogLoginPhoneComponent } from 'src/app/auth/dialog-social-login/dialog-login-phone/dialog-login-phone.component';
declare var google: any;

@Component({
  selector: 'app-dialog-valid-product',
  templateUrl: './dialog-valid-product.component.html',
  styleUrls: ['./dialog-valid-product.component.css'],
  providers: [PackageService]
})

export class DialogValidProductComponent implements OnInit {
  response;  
  socialusers=new Socialusers();  
  
  countries: any[] = [];
  loggedIn: boolean;
  correoResult;
  sendNumber;
  nombre = "+51";
  numero = "";
  validNumber = true;
  inputvalue;
  errorMessage = false;
  user;
  inputvaluae;
  validMessage;
  datos;
  objeto : any;
  widthgoogle = 440;
  val;
  widthBtn = "large";
  textbtnG = "signup_with";
  textCel = "Regístrate con tu Número Celular"
  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private ref: ChangeDetectorRef,
/*     private authService: AuthService, */
    public dialog: MatDialog,
    private sessionService: SessionService,
    private packageService: PackageService,
    public dialogRef: MatDialogRef<DialogValidProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { dialogRef.disableClose = true; }

  ngOnInit(): void{
    this.val = this.sessionService.getIniciarSesionGoogle();
    this.sessionService.setProductGoogle(true);
    this.validPhone();
    this.initialgoogle();
    this.getCountryCodes();
   /*  this.authService.authState.subscribe((user) => {
      this.user = user;
      //console.log(user);
    }); */
 /*    this.packageService.observable().subscribe (user => {
      this.user = user;
      //console.log(user);
      this.ref.detectChanges();
    }) */

    
  }

  validPhone(){
    if( navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)){
      this.widthgoogle = 323;
    }
  }

  handleCredentialResponse(response) {


    console.log('handleCredentialResponse::::::', response);
    var decoded = jwt_decode(response.credential);

   
    this.datos = decoded;
    this.objeto = {
      userId: "",
      nombre: this.datos.given_name,
      apellido: this.datos.family_name,
      email: this.datos.email,
      phone: "",
      genero: "",
      countryCode: "",
      documentNumber : "",
      typeDocument: "",
      birthDate: ""
    }

    let codigo = '?' + response.credential;
    let valor = window.location.origin + window.location.pathname  + codigo; 
    console.log('handleCredentialResponse:::::: - valor', valor);

    window.location.replace(valor);
    /*  window.location.reload(); */
    
  }

  enviarGoogle(){
    this.sessionService.setIniciarSesionGoogle(true);
  }

  validTab(valor){
    let index = valor.index;
    if(index === 1){
      this.textCel = "Inica sesión con tu Número Celular";
      this.textbtnG = "continue_with";
      this.initialgoogle();
    } else {
      this.textCel = "Regístrate con tu Número Celular";
      this.textbtnG = "signup_with";
      this.initialgoogle();
    }
    console.log(valor);
  }

  withoutLogging(){
    this.dialogRef.close();
  }


  registerGoogle() {
    ////console.log(this.objeto);
    const dialogRef = this.dialog.open(RegisterGoogleComponent, {
      data: {
        name: '',
        lastName: '',
        email: '',
        validPhone: true,
        redirect: ''
      },
      disableClose: true,
      panelClass: 'custom-dialog-container',
      maxWidth: 800
    });

    dialogRef.afterClosed().subscribe(result => {
      /* ////console.log('The dialog was closed'); */
      if (result) {
        this.correoResult = result;
      }
    });
  }

  initialgoogle(){
    google.accounts.id.initialize({
      client_id: "536885259092-r3vfm0r10kj052kmmp56om86nhln0040.apps.googleusercontent.com",
      callback: this.handleCredentialResponse
    });
    const btnGoogle = document.getElementById("registrID");
    btnGoogle.style.display = "none";
    google.accounts.id.renderButton(
      btnGoogle,
      { logo_alignment: "center", text: this.textbtnG,size:"medium",scope:"profile email", width: this.widthgoogle }  // customization attributes
    );
  }

  signInWithGoogle() {
    const btnGoogle = document.getElementById('registrID');
    const googleButton: any = btnGoogle.querySelector('div[role="button"]');
    if (googleButton) {
      googleButton.click(); // Simular el clic en el botón interno de Google
    } else {
      console.error('No se encontró el botón de Google');
    }
  }

/*   signIn(){
    this.packageService.signin();
  }

  signOut(){
    this.packageService.signOut();
  } */

  

  getCountryCodes() {
    this.packageService.getCountryCodes().subscribe(
      x => {
        if (x.confirmation && x.data) {
          this.countries = x.data.filter(x => x.id !== null && x.id !== '');
        }
      },
      err => {

      },
      () => {

      }
    );
  }

  loginPhone2(){
    const dialogRef = this.dialog.open(DialogLoginPhoneComponent, {
      data: this.textCel,
      disableClose: true,
      panelClass: 'custom-dialog-containers',
      maxWidth: 800
    });
    dialogRef.afterClosed().subscribe(result => {
      /* //console.log('The dialog was closed'); */

    });
  }



  ValidarSoloNumero(event) {
    // tslint:disable-next-line: max-line-length
    if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105) && event.keyCode !== 190 && event.keyCode !== 110 && event.keyCode !== 8 && event.keyCode !== 9) {
      return false;
    }
  }

  loginPhone() {
    this.errorMessage = false;
    if (this.numero === "") {
      let valor = document.getElementById("numeros");
      valor.style.border = "1px solid red";
      return;
    }
    this.spinner.show();
    let valor = this.nombre + this.numero;
    valor = valor.slice(1);
    this.sendNumber = valor;
    this.packageService.validatePhone(valor).subscribe(
      x => {
        ////console.log(x);

        if (x.status === 200) {
          this.dialogRef.close();
          this.openDialogExiste2(x.odata.code, x.odata.existsPhone);
        } else {
          this.validMessage = x.message;
          this.errorMessage = true;
        }
        this.spinner.hide();
      }
    )
  }

  setId() {
   
    this.inputvaluae = document.getElementById("numeros");
    this.inputvaluae.value = "";
    if (this.nombre === "+51") {
     
      this.inputvaluae.maxLength = 9;
    } else {
      this.inputvaluae.maxLength = 13;
    }
  }



  openDialogExiste2(valor, valor1) {
    this.dialogRef.close();
    const data = {
      numero: this.sendNumber,
      data: valor,
      validPhone: valor1
    }
    const dialogRef = this.dialog.open(ValidPhoneComponent, {
      data: data,
      panelClass: 'custom-dialog-container',
      maxWidth: 800,

    });

    dialogRef.afterClosed().subscribe(result => {
      /* ////console.log('The dialog was closed'); */

    });
  }

  openDialogExiste() {
    const dialogRef = this.dialog.open(FinishComponent, {
      data: "",
      panelClass: 'custom-dialog-container',
      maxWidth: 800
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  loginEmail() {
    this.dialogRef.close();
    this.openDialog();
  }

  openDialog() {

    const dialogRef = this.dialog.open(CorreoComponent, {
      data: this.data.value,
      disableClose: true,
      panelClass: 'custom-dialog-container',
      maxWidth: 800
    });

    dialogRef.afterClosed().subscribe(result => {
      /* ////console.log('The dialog was closed'); */
      if (result) {
        this.correoResult = result;
      }
    });
  }
  

  

/*   signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => ////console.log(x));
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }
 */


  onClose() {
    this.dialogRef.close();
    /* let valor = window.location.hash;
    let url = ""; */
   /*  switch(this.data.value){
      case "P":
        url = "pack/detail/" + this.sessionService.getPackagePriceId(); 
        break;
      case "S":
        url = 'seguros/insurance-select';
        break;
      case "V":
        url = 'flight/flight-result';
        break;
      case "VH":
        url = 'flight-hotel/hotel-detail/' + this.sessionService.getFlightHotel().hotel.code;
        break;
    } */
    /* this.router.navigate(['']); PSV VH*/
    /* this.router.navigate([url]); */
    }
}
