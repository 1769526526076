import { FilterFlight as OldFilterFlight } from "src/app/flights/shared/filter-flight.model";
import { FilterFlight as NewFilterFlight } from "../models/flights/filter-flight.model";
export class NewFlightFacade{

    static OldFlightFilterToNew(oldFilter: OldFilterFlight): NewFilterFlight{
        console.log(oldFilter);
        const newFilterFlight: NewFilterFlight = {
            lpassenger: oldFilter.lpassenger.map(x => {
                return {
                    quantity: x.quantity,
                    type: x.type
                }
            }).filter(x => x.quantity != 0),
            origin: oldFilter.origin,
            destination: oldFilter.destination,
            dates: oldFilter.dates,
            timefrom: oldFilter.timeFrom,
            timeTo: oldFilter.timeTo,
            cabinType: oldFilter.cabinType,
            scales: oldFilter.scales,
            numberOfRecomendations: oldFilter.numberOfRecomendations,
            includeBaggage: oldFilter.includesBaggage,
            includeCarryOn: oldFilter.includeCarryOn,
            typeSearch: "V",
            luser: oldFilter.luser,
            oenterprise: oldFilter.oenterprise,
            RedisToken: oldFilter.RedisToken,

            
        }
        return newFilterFlight
    }

}