import { Component, OnInit, Input } from '@angular/core';
import { FlightSegmentGroup } from 'src/app/flights/shared/models/flight-segment-group.model';
import { FlightSectionGroup } from 'src/app/flights/shared/models/flight-section-group.model';
import { Segment } from 'src/app/flights/shared/models/flights/segment.model';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dm-segment-group',
  templateUrl: './segment-group.component.html',
  styleUrls: ['./segment-group.component.css']
})
export class SegmentGroupComponent implements OnInit {

  @Input() segment: Segment;
  @Input() flightTime: string;

  constructor() { }

  ngOnInit() {
  }

}

