import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions } from 'ngx-gallery-9';
import { Room } from 'src/app/packages/shared/models/room.model';
import { DmDialogMapComponent } from '../dm-dialog-map/dm-dialog-map.component';
import { DmImageGalleryComponent } from '../dm-image-gallery/dm-image-gallery.component';
import { AccommodationRooms } from './shared/accommodation-rooms.model';
import { DialogDetailComponent } from '../dialog-detail/dialog-detail.component';

@Component({
  selector: 'dm-accommodation-rooms',
  templateUrl: './dm-accommodation-rooms.component.html',
  styleUrls: ['./dm-accommodation-rooms.component.css']
})
export class DmAccommodationRoomsComponent implements OnInit {
  @Input() rooms: any[] = [];

  @Input() departureControl: FormControl;
  @Input() prices : any;
  @Input() validcalendar: any;
  @Input() viewrange: any;
  @Output() totalPayment = new EventEmitter<any>();
 
  flighHtml: any;
  hotel;
  sendPrices;
  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];
  selected = 'option2';
  constructor(private dialog: MatDialog) { }

  ngOnInit() {
    console.log(this.flighHtml);
    this.flighHtml = this.prices[0].flightHtml;
    this.hotel = this.prices[0].ohotelPricePackage;
    this.selected = this.prices[0].id;
    this.sendPrices = this.prices[0];
    this.loadImage();
    this.configGallery();
  /*   this.departureControl.setValue(this.hotel.selectedDates[0].id); */
  }

  showFlightHtml(packagePriceId: number) {
    const dialogRef = this.dialog.open(DialogDetailComponent, {
      data: { id: packagePriceId, type: "2" },
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }

  mostrarHotel(valor){
    console.log(valor)
    this.hotel = valor.ohotelPricePackage;
  }

  cambiarImagenes(){
    let lstImges = [];
    
    this.hotel.limagenes.forEach(element => {
      let img = {
        smallPhoto: element.url,
        mediumPhoto:element.url,
        bigPhoto:element.url,
      }
      lstImges.push(img);
    });
    return lstImges;
  }

  showMap() {
    const dialogRef = this.dialog.open(DmDialogMapComponent, {
      data: {
        latitude: this.hotel.latitude,
        longitude: this.hotel.longitude
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  
  enviarTotal(valor){
    this.totalPayment.emit(valor);
  }

  openPhotos() {

    let objeto = {
      data: this.cambiarImagenes(),
      index: 0
    }
    const dialogRef = this.dialog.open(DmImageGalleryComponent, {
      data: objeto
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  configGallery() {
    this.galleryOptions = [
      {
          arrowPrevIcon: "fa fa-chevron-circle-left", 
          arrowNextIcon: "fa fa-chevron-circle-right",
          preview: false,
          width: '330px',
          height: '331px',
          thumbnails: false,
          imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
          preview: false,
          breakpoint: 800,
          width: '330px',
          height: '180px',
          thumbnails: false,
          imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 400
      {
          breakpoint: 400,
          preview: false
      }
    ];
  }

  loadImage() {
    for (let index = 0; index < this.hotel.limagenes.length; index++) {
      let imagen: NgxGalleryImage = {
        small: this.hotel.limagenes[index].url,
        medium: this.hotel.limagenes[index].url,
        big: this.hotel.limagenes[index].url,
      };
      this.galleryImages.push(imagen);
    }
  }
}
