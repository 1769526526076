import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginAccessComponent } from './login/login-access/login-access.component';
import { LoginInitialComponent } from './login/login-initial/login-initial.component';
import { LoginLoadingComponent } from './login/login-loading/login-loading.component';
import { LoginRegisterComponent } from './login/login-register/login-register.component';
import { LoginComponent } from './login/login.component';
import { CouponComponent } from './coupon/coupon.component';
import { CouponRegisterComponent } from './coupon/coupon-register/coupon-register.component';
import { CouponValidatePhoneComponent } from './coupon/coupon-validate-phone/coupon-validate-phone.component';
import { CouponEmailComponent } from './coupon/coupon-email/coupon-email.component';
import { CouponEmailExistsComponent } from './coupon/coupon-email-exists/coupon-email-exists.component';
import { CouponCodeLoginComponent } from './coupon/coupon-code-login/coupon-code-login.component';
import { CouponConfirmPhoneComponent } from './coupon/coupon-confirm-phone/coupon-confirm-phone.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: "login-access", component: LoginAccessComponent },
      { path: "coupon", component: CouponComponent },
      { path: "coupon-register", component: CouponRegisterComponent },
      { path: "coupon-phone", component: CouponValidatePhoneComponent },
      { path: "coupon-email", component: CouponEmailComponent },
      { path: "coupon-email-exists", component: CouponEmailExistsComponent },
      { path: "coupon-code-login", component: CouponCodeLoginComponent },
      { path: "coupon-confirm-phone", component: CouponConfirmPhoneComponent },
      { path: "login-initial", component: LoginInitialComponent },
      { path: "login-activation", component: LoginRegisterComponent },
      { path: "login-register", component: LoginComponent },
      { path: "login-loader", component: LoginLoadingComponent },
      { path: '**', redirectTo: 'login-access' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
