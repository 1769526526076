
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { DialogSocialLoginComponent } from "./auth/dialog-social-login/dialog-social-login.component";
import { DmInterceptor } from "./core/interceptors/dm.interceptor";
import { DialogCorreoComponent } from './auth/dialog-social-login/dialog-correo/dialog-correo.component';
import { DialogLoginPhoneComponent } from './auth/dialog-social-login/dialog-login-phone/dialog-login-phone.component';
import { DialogFinishComponent } from './auth/dialog-social-login/dialog-finish/dialog-finish.component';
import { DialogCorreoExistComponent } from './auth/dialog-social-login/dialog-correo-exist/dialog-correo-exist.component';
import { DialogValidPhoneComponent } from './auth/dialog-social-login/dialog-valid-phone/dialog-valid-phone.component';
import { DialogResetPasswordComponent } from './auth/dialog-social-login/dialog-reset-password/dialog-reset-password.component';
import { DialogUpdatePasswordComponent } from './auth/dialog-social-login/dialog-update-password/dialog-update-password.component';
import { CoreModule } from "./core/core.module";
import { AuthModule } from "./auth/auth.module";
import { DialogRegisterComponent } from "./auth/dialog-social-login/dialog-register/dialog-register.component";
import { DialogConfirmPhoneComponent } from "./auth/dialog-social-login/dialog-confirm-phone/dialog-confirm-phone.component";
import { DialogCodeVerificationComponent } from "./auth/dialog-social-login/dialog-code-verification/dialog-code-verification.component";
import { ValidPhoneComponent } from "./shared/components/dialog-valid-product/valid-phone/valid-phone.component";
import { FinishComponent } from "./shared/components/dialog-valid-product/finish/finish.component";
import { CorreoComponent } from "./shared/components/dialog-valid-product/correo/correo.component";
import { RegisterComponent } from "./shared/components/dialog-valid-product/register/register.component";
import { ConfirmPhoneComponent } from "./shared/components/dialog-valid-product/confirm-phone/confirm-phone.component";
import { CorreoExistsComponent } from "./shared/components/dialog-valid-product/correo-exists/correo-exists.component";
import { CodeVerificationComponent } from "./shared/components/dialog-valid-product/code-verification/code-verification.component";
import { DialogExistsGoogleComponent } from "./auth/dialog-social-login/dialog-exists-google/dialog-exists-google.component";
import { ExistsGoogleComponent } from "./shared/components/dialog-valid-product/exists-google/exists-google.component";
import { DialogDetailComponent } from "./shared/components/dialog-detail/dialog-detail.component";
import { DmImageGalleryComponent } from "./shared/components/dm-image-gallery/dm-image-gallery.component";
import { RegisterGoogleComponent } from "./shared/components/dialog-valid-product/register-google/register-google.component";
import { ResetPasswordComponent } from "./shared/components/dialog-valid-product/reset-password/reset-password.component";
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { DmDialogMapComponent } from "./shared/components/dm-dialog-map/dm-dialog-map.component";
import { DialogRegisterGoogleComponent } from './auth/dialog-social-login/dialog-register-google/dialog-register-google.component';
import { MatDialogModule } from '@angular/material';
import { DialogSocialLogComponent } from './auth/dialog-social-login/dialog-social-log/dialog-social-log.component';
import { DialogFinishPasswordComponent } from './auth/dialog-social-login/dialog-finish-password/dialog-finish-password.component';
import { DialogRegisterCodeComponent } from './auth/dialog-social-login/dialog-register-code/dialog-register-code.component';
import { DialogCerrarSessionComponent } from './auth/dialog-social-login/dialog-confirm-cerrarsession/dialog-cerrar-session/dialog-cerrar-session.component';
import { LoginService } from './core/services/login.service';
import {LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEsPE from '@angular/common/locales/es-PE';
registerLocaleData(localeEsPE);



@NgModule({
  declarations: [
    AppComponent,
    DialogCerrarSessionComponent,
  ],
  imports: [  
    MatDialogModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    AuthModule,
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: "es-PE",
    },
    /* {provide: LOCALE_ID, useValue: 'es'}, */
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DmInterceptor,
      multi: true,
    },
    /* { 
      provide: LocationStrategy,
       useClass: HashLocationStrategy 
    },*/
    LoginService
  ],
  entryComponents: [
    DialogSocialLoginComponent,
    DialogCorreoComponent,
    DialogLoginPhoneComponent,
    DialogCorreoExistComponent,
    DialogResetPasswordComponent,
    DialogUpdatePasswordComponent,
    DialogValidPhoneComponent,
    DialogFinishComponent,
    DialogCerrarSessionComponent,
    DialogDetailComponent,
    DialogRegisterComponent,
    DialogRegisterGoogleComponent,
    DialogConfirmPhoneComponent,
    DialogRegisterCodeComponent,
    DialogFinishPasswordComponent,
    DialogCodeVerificationComponent,
    ValidPhoneComponent,
    DmDialogMapComponent,
    RegisterGoogleComponent,
    DialogSocialLogComponent,
    ResetPasswordComponent,
    FinishComponent,
    CorreoComponent,
    DmImageGalleryComponent,
    RegisterComponent,
    ConfirmPhoneComponent,
    CorreoExistsComponent,
    CodeVerificationComponent,
    DialogExistsGoogleComponent,
    ExistsGoogleComponent
  ],
  bootstrap: [AppComponent],
  
})
export class AppModule {}
