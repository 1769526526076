import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flight-search-url',
  templateUrl: './flight-search-url.component.html',
  styleUrls: ['./flight-search-url.component.css']
})
export class FlightSearchUrlComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
