import { Select } from 'src/app/shared/models/select.model';
import { FilterPassenger } from 'src/app/shared/models/filter-passenger.model';
import { Filter } from 'src/app/shared/controls/dm-autocomplete/shared/ac-filter.model';

export class FilterFlightWrapper {

    static readonly TYPE_ONE_WAY = 'OW';
    static readonly TYPE_ROUND_TRIP = 'RT';
    static readonly TYPE_MULTI = 'MC';


    fromFilter: Filter[];
    toFilter: Filter[];
    dateFilter: any[];
    passengers: number;
    type: string;
    cabinType: Select;
    scales: Select;
    lpassenger: FilterPassenger[];
    includesBaggage: boolean;
    includeCarryOn: boolean;
    Ocompany: any;
    Ouser: any;
    PartnerClub: boolean;
}
