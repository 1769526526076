import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { PackageService } from 'src/app/core/services/package.service';
import { DialogValidPhoneComponent } from '../dialog-valid-phone/dialog-valid-phone.component';

@Component({
  selector: 'app-dialog-login-phone',
  templateUrl: './dialog-login-phone.component.html',
  styleUrls: ['./dialog-login-phone.component.css'],
  providers: [PackageService]
})
export class DialogLoginPhoneComponent implements OnInit {

  countries: any[] = [];
  sendNumber;
  nombre = "+51";
  numero = "";
  errorMessage = false;
  inputvaluae;
  validMessage;
  textEmail = "";
  textbtnG = "";

  constructor(private spinner: NgxSpinnerService, private packageService: PackageService, public dialog: MatDialog, public dialogRef: MatDialogRef<DialogLoginPhoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,) { }

  ngOnInit() {
    this.getCountryCodes();
  }

  loginPhone() {
    this.errorMessage = false;
    if (this.numero === "") {
      let valor = document.getElementById("numeros");
      valor.style.border = "1px solid red";
      return;
    }
    this.spinner.show();
    let valor = this.nombre + this.numero;
    valor = valor.slice(1);
    this.sendNumber = valor;
    this.packageService.validatePhone(valor).subscribe(
      x => {
        //console.log(x);
        if (x.status === 200) {
          this.dialogRef.close();
          this.openDialogExiste2(x.odata.code, x.odata.existsPhone);
        } else {
          this.validMessage = x.message;
          this.errorMessage = true;
        }
        this.spinner.hide();
      }
    )
  }

  getCountryCodes() {
    this.packageService.getCountryCodes().subscribe(
      x => {
        if (x.confirmation && x.data) {
          this.countries = x.data.filter(x => x.id !== null && x.id !== '');
        }
      },
      err => {

      },
      () => {

      }
    );
  }

  setId() {
   
    this.inputvaluae = document.getElementById("numeros");
    this.inputvaluae.value = "";
    if (this.nombre === "+51") {
     
      this.inputvaluae.maxLength = 9;
    } else {
      this.inputvaluae.maxLength = 13;
    }
  }

  ValidarSoloNumero(event) {
    // tslint:disable-next-line: max-line-length
    if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105) && event.keyCode !== 190 && event.keyCode !== 110 && event.keyCode !== 8 && event.keyCode !== 9) {
      return false;
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  openDialogExiste2(valor, valor1) {
    this.dialogRef.close();
    const data = {
      numero: this.sendNumber,
      data: valor,
      validPhone: valor1
    }
    const dialogRef = this.dialog.open(DialogValidPhoneComponent, {
      data: data,
      panelClass: 'custom-dialog-container',
      maxWidth: 800,

    });

    dialogRef.afterClosed().subscribe(result => {
      /* //console.log('The dialog was closed'); */

    });
  }

}
