import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'dm-dialog-map',
  templateUrl: './dm-dialog-map.component.html',
  styleUrls: ['./dm-dialog-map.component.css']
})
export class DmDialogMapComponent implements OnInit {

/*   @Input() latitude: number;
  @Input() longitude: number; */

  @Input() isList: any;
  zoom = 17;

  constructor(
    public dialogRef: MatDialogRef<DmDialogMapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    console.log(this.data)
    this.data.latitude = parseFloat(this.data.latitude);
    this.data.longitude = parseFloat(this.data.longitude); 
  }

  close(): void {
    this.dialogRef.close();
  }
}
