import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FlightService } from 'src/app/core/services/flight.service';
import { FilterSearch } from 'src/app/hotels/hotel-filter-search/shared/filter-search.model';
import { SessionService } from 'src/app/core/services/session.service';
import { airportsData } from 'src/app/shared/services/airport.const';
import { HeaderService } from 'src/app/shared/services/head.service';
import { formatDate } from '@angular/common';
import { BsDaterangepickerInlineConfig } from 'ngx-bootstrap/datepicker';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-insurance-search',
  templateUrl: './insurance-search.component.html',
  styleUrls: ['./insurance-search.component.css'],
  providers: [FlightService, BsDaterangepickerInlineConfig]
})
export class InsuranceSearchComponent implements OnInit, AfterViewInit {
  @ViewChild("dpReturnDate", { read: undefined, static: false })
  endDatePicker: MatDatepicker<Date>;
  form: FormGroup;
  listCharter;
  char;
  valor;
  validDestino = false;
  validFechas= false;
  validEdad = false;
  minBeginDate = new Date();
  minCalendar = new Date();
  minEndDate = new Date();
  origins = [];
  originList = [];
  validAmountCard;
  dataAssist;
  nroDias = null;
  validDays = null;
  listCharterShow;
  minDepartureDate = new Date();
  edad;
  edadinput: string = "";
  objetoLlenar = {
    Destino: null,
    FechaInicio: null,
    FechaFin: null
  }

  textImg = "newSearch";
  valorinput: string = "";
  minReturnDate = new Date();
  destinoModel = "selecciona";
  destinationList = [];
  listPassenger: any[] = [

  ];
  isDestino = null;
  isNational: boolean;
  @Input() data: any;
  @Input() expandable: any;
  @Input() theme: string;
  @Output() expand = new EventEmitter<boolean>();
  @Output() search = new EventEmitter<FilterSearch>();
  dataAirport: any;
  fechaida: any;
  fechavuelta: any;
  fechaidaSelect: any;
  fechavueltaSelect: any;
  openRangePicker: boolean = false;
  bsInvalidDate: Date = new Date();
  mostrarCalendarRange: boolean = false;
  viewFullScream = false;
  public myObject!: { id: number, myObject: { myString: string } };
  listCountry = [];
  objLlenar;
  constructor(private snackBar: MatSnackBar, private router: Router, private spinner: NgxSpinnerService, private flightService: FlightService, 
              private formBuilder: FormBuilder, private sessionService: SessionService, private headerService: HeaderService) { 
                this.fechaida = null;
                this.fechavuelta = null;
                this.dataAirport = airportsData;
  }

  ngOnInit() {
    this.validPhone();
    if (this.expandable) {
      this.objLlenar = this.sessionService.getLlenarDatos();
      this.listPassenger = this.sessionService.getEdades();
      this.isDestino = this.objLlenar.Destino;
    }
    this.minCalendar.setDate(this.minCalendar.getDate() + 1);
    this.initForm();



    this.origins = [];
    this.getAirports();

  }



  validPhone() {
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
      this.textImg = "buscarPhone";
    }
  }

  ngAfterViewInit() {
    if (this.expandable) {
      /* let elemento = this.listPassenger[0];
      this.edad = document.getElementById('edad');
      this.edad.value = elemento.Edad; */

      if (this.listPassenger.length > 0) {
        /* let pass: any[] = [];
        this.listPassenger.slice(1).forEach(element => {
          pass.push(element);
        }); */
        for (let index = 0; index < this.listPassenger.length; index++) {
          const element = this.listPassenger[index];
          this.edad = document.getElementById('edad_' + index);
          this.edad.value = element.Age;
          /* let i = index + 1;
          if(pass.length === i){
          this.listPassenger.splice(i, 1);
        } */

        }
      }

      this.objLlenar  = this.sessionService.getLlenarDatos();
      this.fechaidaSelect = this.objLlenar.FechaInicio;
      this.fechavueltaSelect = this.objLlenar.FechaFin;
      this.fechaida = this.fechaidaSelect != null ? this.formatearFecha2(this.fechaidaSelect,'EEE dd MMM') : null;
      this.fechavuelta = this.fechavueltaSelect != null ? this.formatearFecha2(this.fechavueltaSelect,'EEE dd MMM') : null;
      if(this.objLlenar.Destino === "Nacional"){
        this.isNational = true;
        this.form.controls.destination.setValue("N");
      }else{
        this.isNational = false;
        this.form.controls.destination.setValue("I")
      }

    }
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 5000,
    });
  }

  onDepartureDateChange(event: MatDatepickerInputEvent<Date>) {
    if (this.form.controls.returnDate.value) {
      if (
        new Date(this.form.controls.departureDate.value) >=
        new Date(this.form.controls.returnDate.value)
      ) {
        this.form.controls.returnDate.setValue("");
      } else {
        const days =
          new Date(this.form.controls.returnDate.value).getTime() -
          new Date(this.form.controls.departureDate.value).getTime();
        const diferenciaDias = Math.floor(days / (1000 * 60 * 60 * 24));
        console.log(diferenciaDias);
        this.nroDias = diferenciaDias;
      }
    }
    // this.minReturnDate = this.form.controls.departureDate.value;
    const result = new Date(this.form.controls.departureDate.value);
    result.setDate(result.getDate() + 1);
    this.minReturnDate = result;

    if (!this.form.controls.returnDate.value) {
      this.endDatePicker.open();
    }
    this.validFechas = false;
  }

  onReturnDateChange(event: MatDatepickerInputEvent<Date>) {
    const days =
      new Date(this.form.controls.returnDate.value).getTime() -
      new Date(this.form.controls.departureDate.value).getTime();
    const diferenciaDias = Math.floor(days / (1000 * 60 * 60 * 24));
    console.log(diferenciaDias);
    this.nroDias = diferenciaDias;
    /*  //console.log(Math.round(days / (1000 * 60 * 60 * 24))); */
    /* this.nights = Math.round(days / (1000 * 60 * 60 * 24)).toString(); */
  }




  getPriorityAirports() {
    /* this.spinner.show(); */
    this.flightService.getPriorityAirports().subscribe(
      (result) => {
        /* //console.log(result); */
        if (result) {
          const airports = result.lairports ? result.lairports : [];
          const cities = result.lcities ? result.lcities : [];
          airports.forEach((airport) => {
            this.origins.push({
              code: airport.iataCode,
              countryCode: airport.countryCode,
              name: airport.name,
              searchName: airport.searchName,
              priority: airport.priority,
              /* icon: 'local_airport' */
              icon: "A",
            });
          });
          cities.forEach((city) => {
            this.origins.push({
              code: city.iataCode,
              countryCode: city.countryCode,
              name: city.name,
              searchName: city.searchName,
              priority: city.priority,
              /* icon: 'location_city' */
              icon: "C",
            });
          });
          /* this.origins.sort((a, b) => b.priority - a.priority);  */
        }
      },
      (err) => {
        /*   //console.log('Error: ', err); */
      },
      () => {
        this.enviarLim();
        this.getAirports();
      }
    );
  }

  enviarLim() {
    const objeto = {
      code: "LIM",
      countryCode: "PE",
      icon: "A",
      name: "[LIM] - Jorge Chavez International - LIM - Lima - Peru",
      priority: 2,
      searchName: "LIM-Lima-Jorge Chavez International-J Chavez Intl-Peru-Perú-Peruu-Perù-Pê ru-Pérou"
    }
    this.form.controls.origin.setValue(objeto);
    /* this.origins.forEach(element => {
      if(element.code === 'LIM'){
        this.form.controls.origin.setValue(element);
      }
    });
    this.spinner.hide(); */
  }

  fullscreenSearch(){
    this.viewFullScream = true;
  }

  fullscreenClose(){
    this.viewFullScream = false;
  }

  openDatePicker() {
    this.openRangePicker = true;
   }
 
   cancelChanges() {
     this.openRangePicker = false;
     this.fechaida = null;
     this.fechavuelta = null;
     this.fechaidaSelect = null;
     this.fechavueltaSelect = null;
     this.nroDias = null;
     this.validDays = null;
   }
 
   onFinDateRangeChange(event: MatDatepickerInputEvent<Date>) {
     if (event[0] != undefined) {
        this.fechaidaSelect = event[0];
        this.fechavueltaSelect = event[1];
        this.fechaida = null;
        this.fechavuelta = null;
     }
   }
 
   applyRangeChanges() {
     if(this.fechaidaSelect != null && this.fechavueltaSelect != null){
      if (this.fechaidaSelect >= this.fechavueltaSelect) {
        this.fechaida = null;
        this.fechavuelta = null;
        this.fechaidaSelect = null;
        this.fechavueltaSelect = null;
        this.openRangePicker = false;
        this.nroDias = null;
        this.validDays = "Seleccione otra fecha";
      }else {
        this.fechaida = this.formatearFecha2(this.fechaidaSelect,'EEE dd MMM');
        this.fechavuelta = this.formatearFecha2(this.fechavueltaSelect,'EEE dd MMM');
        this.openRangePicker = false;
        this.validFechas = false;
        const days = this.fechavueltaSelect.getTime() - this.fechaidaSelect.getTime();
        const diferenciaDias = Math.floor(days / (1000 * 60 * 60 * 24));
        this.validDays = null;
        this.nroDias = diferenciaDias;
      }
     }
   }

   applyRangeChangesMobile() {
    if(this.fechaidaSelect != null && this.fechavueltaSelect != null){
      if (this.fechaidaSelect >= this.fechavueltaSelect) {
        this.fechaida = null;
        this.fechavuelta = null;
        this.fechaidaSelect = null;
        this.fechavueltaSelect = null;
        this.mostrarCalendarRange = false;
        this.nroDias = null;
        this.validDays = "Seleccione otra fecha";
      }else {
        this.fechaida = this.formatearFecha2(this.fechaidaSelect,'EEE dd MMM');
        this.fechavuelta = this.formatearFecha2(this.fechavueltaSelect,'EEE dd MMM');
        this.mostrarCalendarRange = false;
        this.validFechas = false;
        const days = this.fechavueltaSelect.getTime() - this.fechaidaSelect.getTime();
        const diferenciaDias = Math.floor(days / (1000 * 60 * 60 * 24));
        this.validDays = null;
        this.nroDias = diferenciaDias;
      }
    }
  }

  cancelChangesMobile() {
    this.mostrarCalendarRange = false;
    this.fechaida = null;
    this.fechavuelta = null;
    this.fechaidaSelect = null;
    this.fechavueltaSelect = null;
    this.nroDias = null;
    this.validDays = null;
  }

   formatearFecha2(fecha: any, format: string): string {
    const fechaini = new Date(fecha);
    return formatDate(fechaini, format, 'es-PE', 'America/Lima');
  }

  viewCalendarRange() {
    this.mostrarCalendarRange = true;
  }

  getPriorityAirports1() {

    this.flightService.getPriorityAirports().subscribe(
      (result) => {
        /* //console.log(result); */
        if (result) {
          const airports = result.lairports ? result.lairports : [];
          const cities = result.lcities ? result.lcities : [];
          airports.forEach((airport) => {
            this.origins.push({
              code: airport.iataCode,
              countryCode: airport.countryCode,
              name: airport.name,
              searchName: airport.searchName,
              priority: airport.priority,
              /* icon: 'local_airport' */
              icon: "A",
            });
          });
          cities.forEach((city) => {
            this.origins.push({
              code: city.iataCode,
              countryCode: city.countryCode,
              name: city.name,
              searchName: city.searchName,
              priority: city.priority,
              /* icon: 'location_city' */
              icon: "C",
            });
          });
          this.origins.sort((a, b) => b.priority - a.priority);

        }
      },
      (err) => {
        /*   //console.log('Error: ', err); */
      },
      () => {
        this.getAirports1();
      }
    );
  }

  getAirports1() {
    const _origins = [];
    this.flightService.getAirports().subscribe(
      (result) => {
        if (result) {
          const airports = result.lairports ? result.lairports : [];
          const cities = result.lcities ? result.lcities : [];
          airports.forEach((airport) => {
            _origins.push({
              code: airport.iataCode,
              countryCode: airport.countryCode,
              name: airport.name,
              searchName: airport.searchName,
              priority: airport.priority,
              cityName: airport.cityName,
              /* icon: 'local_airport' */
              icon: "A",
            });
          });
          cities.forEach((city) => {
            _origins.push({
              code: city.iataCode,
              countryCode: city.countryCode,
              name: city.name,
              searchName: city.searchName,
              priority: city.priority,
              cityName: city.cityName,
              /* icon: 'location_city' */
              icon: "C",
            });
          });
          _origins.sort((a, b) => b.priority - a.priority);
          /* let valor = this.constant.encriptar(_origins);
          this.constant.addObject(0, valor); */
        }
      },
      (err) => {
        /*  //console.log('Error: ', err); */
      },
      () => {
        this.origins = _origins;

      }
    );
  }

  changeType(list) {
    list.lpassenger.forEach(element => {
      if (element.typePassenger === 'CHD') {
        element.typePassenger = 'CHD';
      }
    });

    return list;
  }

  onInputFocus() {
    this.validEdad = false;
    // Aquí puedes realizar las acciones que desees cuando el campo de entrada obtenga el enfoque.
  }

  ValidarSoloNumero(event) {
   
    const key = event.keyCode;
    if(key === 111){
      return true;
    }
    if (key !== 17 && key != 86) {
      if (key === 32 || key === 110) { // bloquea la tecla de espacio
        event.preventDefault();
      }
      // tslint:disable-next-line: max-line-length
      if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105) && event.keyCode !== 190 && event.keyCode !== 110 && event.keyCode !== 8 && event.keyCode !== 9) {
        return false;
      }
    }

  }

  onInputChange(event) {
    const inputValue = event.target.value;
    this.valorinput = event.target.value;
    event.target.value = this.valorinput.replace(/\s/g, '');
    event.target.value = inputValue.replace(/\s/g, '');
  }


  serviceQuote() {
    
    let valore = true;
    if (!this.fechaidaSelect || !this.fechavueltaSelect) {
      this.validFechas = true;
      valore = false;
    }

    this.destinoModel = $("#cbo_document_new").val();

    if(this.destinoModel === "" || this.destinoModel === null){
      this.validDestino = true;
      valore = false;
    }

   /*  this.valor = document.getElementById('edad');
    if (this.valor.value === "" || this.valor.value.length === 0) {
     this.validEdad = true;
     valore = false;
    } */

   /*  for (let index = 0; index < this.listPassenger.length; index++) {

      this.valor = document.getElementById('edad_' + index);
      if (this.valor.value === "") {
        this.openSnackBar('Por favor llenar la edad del pasajero.');
        return;
      }

    } */

    if(valore){
      let ida = this.fechaidaSelect.toISOString();
      let vuelta = this.fechavueltaSelect.toISOString();
      let passenger = [];
      let enviar = [];
      let memoryPassengerADT = [];
      let memoryPassengerCHD = [];
      let memoryPassengerINF = [];
  
      /* this.valor = document.getElementById('edad');
      let edad = parseFloat(this.valor.value)
      let tipo;
      if (edad <= 2) {
        tipo = 'INF';
  
      } else if (edad > 2 && edad < 12) {
        tipo = 'CHD';
      } else {
        tipo = 'ADT';
      }
  
      let passen = {
        Name: "",
        LastName: "",
        Type: tipo,
        BirthDate: null,
        Edad: edad
      }

      if(edad > 0){
      passenger.push(passen);
      } */
  
      for (let index = 0; index < this.listPassenger.length; index++) {
        const element = this.listPassenger[index];
        this.valor = document.getElementById('edad_' + index);
        let edad = parseFloat(this.valor.value)
        let tipo;
        if (edad <= 2) {
          tipo = 'INF';
  
        } else if (edad > 2 && edad < 12) {
          tipo = 'CHD';
        } else {
          tipo = 'ADT';
        }
        const pass = {
          Name: "",
          LastName: "",
          Type: tipo,
          BirthDate: null,
          Age: edad
        }
        if(edad > 0){
        passenger.push(pass);
        }
      }
      this.spinner.show();
      this.sessionService.setEdades(passenger);
      passenger.forEach(element => {
        if (element.Type === 'ADT') {
          memoryPassengerADT.push(element);
        } else if (element.Type === 'CHD') {
          memoryPassengerCHD.push(element);
        } else {
          memoryPassengerINF.push(element);
        }
      });
      const obj = {
        numberPassenger: memoryPassengerADT.length,
        typePassenger: 'ADT'
      }
      enviar.push(obj);
      const obj1 = {
        numberPassenger: memoryPassengerCHD.length,
        typePassenger: 'CHD'
      }
      enviar.push(obj1);
      const obj2 = {
        numberPassenger: memoryPassengerINF.length,
        typePassenger: 'INF'
      }
      enviar.push(obj2);
      this.sessionService.setPassengerCard(enviar);
  
      const data = {
        InitialDate: ida,
        FinalDate: vuelta,
        Sucursal: '7',
        flightNational: this.isNational,
        SearchType: "V",
        Lpassenger: passenger,
        lsections: null
      }
      this.sessionService.setPasajerosAssist(data.Lpassenger);
      this.objetoLlenar.FechaInicio = ida;
      this.objetoLlenar.FechaFin = vuelta;
      this.sessionService.setLlenarDatos(this.objetoLlenar);
      this.flightService.getQuoteInsurance(data).subscribe(
        x => {
          if (x.status === 200) {
            x.lquoteInsurance.forEach(element => {
              element.lbenefits = null;
            });
            x.lquoteInsurance = this.letPrice(x.lquoteInsurance);
            this.dataAssist = x.lquoteInsurance[0];
  
            this.validAmountCard = x.lquoteInsurance[0].lclientsInsurances[0].totalAmount;
            this.sessionService.setCard(x);
            this.router.navigate(['seguros/insurance-select']);
            this.viewFullScream = false;
            this.headerService.initializeWhatsAppWidget("/seguros/insurance-select");
          } else {
            this.dataAssist = null;
            this.viewFullScream = false;
            this.sessionService.setCard(null);
            this.openSnackBar(x.message);
          }
          this.expand.emit(x);
          this.spinner.hide();
          //console.log(x);
  
        }
      )
    }

    
  }

  letPrice(list) {
    let cant = this.sessionService.getEdades();
    cant = cant.length;
    /* list.forEach(element => {
      element.lpriceByPayments[0].priceByPassenger = element.lpriceByPayments[0].finalAmount / cant;
    }); */

    return list;
  }



  getAirports() {
    this.enviarLim();
    const _origins = [];
    let result = this.dataAirport;
    if (result) {
      const airports = result.lairports ? result.lairports : [];
      const cities = result.lcities ? result.lcities : [];
      airports.forEach((airport) => {
        _origins.push({
          code: airport.iataCode,
          countryCode: airport.countryCode,
          name: airport.name,
          searchName: airport.searchName,
          priority: airport.priority,
          cityName: airport.cityName,
          /* icon: 'local_airport' */
          icon: "A",
        });
      });
      cities.forEach((city) => {
        _origins.push({
          code: city.iataCode,
          countryCode: city.countryCode,
          name: city.name,
          searchName: city.searchName,
          priority: city.priority,
          cityName: city.cityName,
          /* icon: 'location_city' */
          icon: "C",
        });
      });
      /* _origins.sort((a, b) => b.priority - a.priority); */
    }

    this.origins = _origins;
    /* let valor = this.constant.encriptar(this.origins);
    this.constant.addObject(0, valor); */

    // this.flightService.getAirports().subscribe(
    //   (result) => {
    //     if (result) {
    //       const airports = result.lairports ? result.lairports : [];
    //       const cities = result.lcities ? result.lcities : [];
    //       airports.forEach((airport) => {
    //         _origins.push({
    //           code: airport.iataCode,
    //           countryCode: airport.countryCode,
    //           name: airport.name,
    //           searchName: airport.searchName,
    //           priority: airport.priority,
    //           cityName: airport.cityName,
    //           /* icon: 'local_airport' */
    //           icon: "A",
    //         });
    //       });
    //       cities.forEach((city) => {
    //         _origins.push({
    //           code: city.iataCode,
    //           countryCode: city.countryCode,
    //           name: city.name,
    //           searchName: city.searchName,
    //           priority: city.priority,
    //           cityName: city.cityName,
    //           /* icon: 'location_city' */
    //           icon: "C",
    //         });
    //       });
    //       /* _origins.sort((a, b) => b.priority - a.priority); */
    //     }
    //   },
    //   (err) => {
    //     /*  //console.log('Error: ', err); */
    //   },
    //   () => {
    //     this.origins = _origins;
    //   }
    // );
  }

  initForm() {
    let origin;
    let destination;
    let departureDate;
    let returnDate;

    this.form = this.formBuilder.group({
      origin: new FormControl(origin),
      destination: new FormControl(""),
      departureDate: new FormControl(departureDate),
      returnDate: new FormControl(returnDate),
    });


    /* this.form.controls.origin.setValue('Perú'); */
    this.form.controls.origin.setValue({ Pais: 'Perú' });
  }

  enviarLim1() {
    this.origins.forEach(element => {
      if (element.Pais === 'Perú') {
        this.form.controls.origin.setValue(element);
      }
    });

  }

  addPassenger() {
    if(this.valorinput != ""){
      this.edad = document.getElementById('edad');
      this.edad.value = '';
    const obj = {
      Age: this.valorinput
    }
    if (this.listPassenger.length === 5) {
      return;
    } else {
      this.listPassenger.push(obj);
    }
  }
  }

  removePassenger(index) {
    /* let index = this.listPassenger.length - 1; */
    this.listPassenger.splice(index, 1);
  }

  onOriginChange(val: string) {
    if (val.length >= 3 && this.origins) {
      this.originList = this.origins.filter(
        (item) => item.searchName.toLowerCase().search(val.toLowerCase()) >= 0
      );
    } else {
      this.originList = [];
    }
  }

  onDestinationChange(val: any) {
    const document = $("#cbo_document_new").val();
    
    if (document === "N") {
      this.isNational = true;
      this.objetoLlenar.Destino = "Nacional";
    } else {
      this.isNational = false;
      this.objetoLlenar.Destino = "Internacional";
    }
    this.validDestino = false;
  }

}
