import { Component, Input, OnInit } from '@angular/core';
import { ItineraryMap } from '../../models/itinerary-map.model';

interface Location{
  location: { lat: number, lng: number }
}

@Component({
  selector: 'dm-itinerary-and-map',
  templateUrl: './dm-itinerary-and-map.component.html',
  styleUrls: ['./dm-itinerary-and-map.component.css']
})
export class DmItineraryAndMapComponent implements OnInit {

  @Input() data: ItineraryMap;
  @Input() package: any;

  lat: Number = 0;
  lng: Number = 0;

  origin = { lat: 0, lng: 0 }
  destination = { lat: 0, lng: 0 }

  waypoints: Location[] = [];

  constructor() { }

  ngOnInit() {
    this.getDirection();
  }

  getDirection() {
    /* console.log(this.data); */
    /* this.data.days.forEach(day => {
      day.cities.forEach(city => {
        this.waypoints.push({location: { lat: city.latitude, lng: city.longitude }});
      });
    });

    let start = this.waypoints.shift();
    let end = this.waypoints.pop();
    
    this.lat = start.location.lat;
    this.lng = start.location.lng;

    this.origin = { lat: start.location.lat, lng: start.location.lng };
    this.destination = { lat: end.location.lat, lng: end.location.lng }; */

  }
}
