import { Component, OnInit, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dm-gradient-title',
  templateUrl: './dm-gradient-title.component.html',
  styleUrls: ['./dm-gradient-title.component.css']
})
export class DmGradientTitleComponent implements OnInit {

  constructor() { }

  @Input() title: string;
  @Input() fontSize: number;
  @Input() borderHeight: number;

  ngOnInit() {
  }

}
