import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/core/services/session.service';

@Component({
  selector: 'dm-national-destination-carousel',
  templateUrl: './national-destination-carousel.component.html',
  styleUrls: ['./national-destination-carousel.component.css']
})
export class NationalDestinationCarouselComponent implements OnInit, AfterViewInit {

  @Input() listadoDinamicoN: any[] = [];
  @Input() origenes: any[] = [];
  @Input() validResponse: any;
  fromFilter: any[] = [];
  toFilter: any[] = [];
  dateFilter: any[] = [];


  /**carousel */
  elementSize: number = 318;
  elementMargin: number = 20;
  widthWeb: number;
  elements: any[] = [];
  public myObject!: { id: number, myObject: { myString: string } };
  public carouselWidth: number;
  public elementWidth: number;
  public currentIndex: number = 0;
  public pages: number = 0;
  public progressSize: number = 4;
  public counterLeft: number = 0;
  public counterRight: number = 0;
  public progressMargin: number = 0;
  public movementsToRight: number = 0;
  public movementsToLeft: number = 0;
  public currentItemsCarousel: number = 0;
  public scrHeight: any;
  public scrWidth: any;
  defaultTouch = { x: 0, y: 0, time: 0 };
  /**carousel */


  constructor(
    private sessionService: SessionService,
    private router: Router,
    private elementRef: ElementRef
  ) {
    this.getScreenSize();
  }

  calculatePageWidth() {
    return window.innerWidth;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      let locat = window.location.pathname;
      let locat2 = window.location.search;
      if(locat2 != ""){
      let located2 = locat2.split('?');
      let quitar = located2[1].split('=');
    if(locat + '/' + quitar[0] === '/flight/national'){
      setTimeout(() => {
          document.body.style.overflowY = "hidden";
          const targetElement = this.elementRef.nativeElement.querySelector('#nationalperu');
          if(targetElement){
            targetElement.scrollIntoView({ behavior: 'smooth' });
            let find = this.elements.find(x => x.odestination.iataCode === quitar[1])
            if(find != null || find != undefined){
              let indexnum;
              this.widthWeb = this.calculatePageWidth();

              switch (true) {
                case (this.widthWeb > 200 && this.widthWeb <= 700):
                  indexnum = find.index;
                  break;
                case (this.widthWeb > 700 && this.widthWeb <= 1028):
                  indexnum = find.index - 1;
                  break;
                case (this.widthWeb > 1028):
                  indexnum = find.index - 2;
                  break;
              }
              /* let indexnum = find.index - 2; */
            if(indexnum > 0){
              for (let index = 0; index < indexnum; index++) {
              this.next();
                }
              }
            }
            setTimeout(() => {
              document.body.style.overflowY = "auto";
            }, 1500);
          }else {
            document.body.style.overflowY = "auto";
          }
      }, 2000);

    }

  }

      setTimeout(() => {
            document.body.style.overflowY = "auto";
          }, 1200);
          
          }, 2000);
    
  }

  ngOnInit() {
    //carousel
    this.elements = this.listadoDinamicoN;
    this.calculateDimensions();
  }

  sideScroll(element, direction, speed, distance, step) {
    var scrollAmount = 0;
    var slideTimer = window.setInterval(function () {
      if (direction == "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  moverIzq(id_container) {
    //console.log("id_container: " + id_container);

    const container = document.getElementById(id_container);
    this.sideScroll(container, "left", 5, 320, 10);
  }

  moverDer(id_container) {
    //console.log("id_container: " + id_container);

    const container = document.getElementById(id_container);
    this.sideScroll(container, "right", 5, 320, 10);
  }

  fillDestino(valor) {
    let destino = null;
    let origins;


    let resultado = this.origenes.filter(item => item.code === valor);
    destino = resultado[0];
    return destino;



  }

  goToVuelos(numberPassengers, origen, destino, departureDate, arrivalDate) {

    origen = this.fillDestino(origen.iataCode);
    destino = this.fillDestino(destino.iataCode);
    this.sessionService.setRecomendations(null);
    const filter = {
      Ocompany: null,
      PartnerClub: false,
      type: "RT",
      lpassenger: [
        {
          quantity: numberPassengers,
          type: "ADT",
        },
        {
          quantity: 0,
          type: "CHD",
        },
        {
          quantity: 0,
          type: "INF",
        },
      ],
      cabinType: {
        id: "",
        description: "Todas",
      },
      scales: {
        id: "",
        description: "Todos",
      },
      includesBaggage: "",
      fromFilter: [
        {
          cityName: origen.cityName,
          code: origen.code,
          countryCode: origen.countryCode,
          name: origen.name,
          searchName: origen.searchName,
          priority: origen.priority,
          icon: origen.icon
        },
        {
          cityName: destino.cityName,
          code: destino.code,
          countryCode: destino.countryCode,
          name: destino.name,
          searchName: destino.searchName,
          priority: destino.priority,
          icon: destino.icon
        },
      ],
      toFilter: [
        {
          cityName: destino.cityName,
          code: destino.code,
          countryCode: destino.countryCode,
          name: destino.name,
          searchName: destino.searchName,
          priority: destino.priority,
          icon: destino.icon
        },
        {
          cityName: origen.cityName,
          code: origen.code,
          countryCode: origen.countryCode,
          name: origen.name,
          searchName: origen.searchName,
          priority: origen.priority,
          icon: origen.icon
        },
      ],
      dateFilter: [departureDate, arrivalDate],
    };
    //dateFilter: [departureDate, arrivalDate],
    //dateFilter: ["2021-09-15T05:00:00.000Z", "2021-09-22T05:00:00.000Z"],

    //console.log(JSON.stringify(filter));

    const jsonfilter = JSON.stringify(filter);

    this.sessionService.setFilterFlightWrapper(JSON.parse(jsonfilter));
    this.router.navigate(["flight/flight-result"]);
  }

  /**carousel */
  /* @HostListener('window:resize')
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    let elementsView = this.getElementsView();
    if (this.currentItemsCarousel !== elementsView) {
      this.calculateDimensions();
    }
  }

  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  handleTouch(event) {
    let touch = event.touches[0] || event.changedTouches[0];
    if (event.type === 'touchstart') {
      this.defaultTouch.x = touch.pageX;
      this.defaultTouch.time = event.timeStamp;
    } else if (event.type === 'touchend') {
      let deltaX = touch.pageX - this.defaultTouch.x;
      let deltaTime = event.timeStamp - this.defaultTouch.time;
      if (deltaTime < 500) {
        if (Math.abs(deltaX) > 60) {
          if (deltaX > 0) {
            this.prev();
          } else {
            this.next();
          }
        }
      }
    }
  }

  calculateDimensions() {
    this.currentItemsCarousel = this.getElementsView();
    this.elementWidth = this.elementSize + 2 * this.elementMargin;
    this.carouselWidth = this.elementWidth * this.getElementsView();
    this.currentIndex = 0;
    if (this.elements !== null && this.elements !== undefined) {
      this.pages = this.elements.length - this.getElementsView() + 1;
      if (this.pages <= 0)
        this.pages = 1;
      this.elements.map((i, index) => {
        i.index = index, i.marginLeft = this.elementMargin, i.marginRight = this.elementMargin
      });
    }

    this.progressMargin = 0;
    this.counterLeft = 0;
    this.counterRight = 0;
    this.movementsToRight = 0;
    this.movementsToLeft = 0;
  }

  getElementsView(): number {
    if (this.scrWidth > 1024) {
      return 3;
    } else if (this.scrWidth <= 1024 && this.scrWidth > 700) {
      return 2;
    } else {
      return 1;
    }
  }

  prev() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.elements.find(x => x.index === 0).marginLeft = -(this.elementWidth) * this.currentIndex + this.elementMargin;

      if (this.counterRight - this.counterLeft <= this.pages - this.progressSize && this.movementsToRight - this.movementsToLeft > 0) {
        this.progressMargin = this.progressMargin + 24;
        this.movementsToLeft++;
      }
      this.counterLeft++;
    }


  }

  next() {
    if (this.currentIndex < this.pages - 1) {
      this.currentIndex++;
      this.elements.find(x => x.index === 0).marginLeft = -(this.elementWidth) * this.currentIndex + this.elementMargin;

      if (this.counterRight - this.counterLeft >= this.progressSize - 1 && this.movementsToRight - this.movementsToLeft < this.pages - this.progressSize) {
        this.progressMargin = this.progressMargin - 24;
        this.movementsToRight++;
      }
      this.counterRight++;
    }
  } */
  @HostListener('window:resize')
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    let elementsView = this.getElementsView();
    if (this.currentItemsCarousel !== elementsView){
      this.calculateDimensions();
    }
  }

  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  handleTouch(event) {
    let touch = event.touches[0] || event.changedTouches[0];
    if (event.type === 'touchstart') {
      this.defaultTouch.x = touch.pageX;
      this.defaultTouch.time = event.timeStamp;
    } else if (event.type === 'touchend') {
      let deltaX = touch.pageX - this.defaultTouch.x;
      let deltaTime = event.timeStamp - this.defaultTouch.time;
      if (deltaTime < 500) {
        if (Math.abs(deltaX) > 60) {
          if (deltaX > 0) {
            this.prev();
          } else {
            this.next();
          }
        }
      }
    }
  }

  calculateDimensions() {
    this.currentItemsCarousel = this.getElementsView();
    this.elementWidth = this.elementSize + 2 * this.elementMargin;
    this.carouselWidth = this.elementWidth * this.getElementsView();
    this.currentIndex = 0;
    if (this.elements !== null && this.elements !== undefined){
      this.pages = this.elements.length - this.getElementsView() + 1;
      if (this.pages <= 0)
        this.pages = 1;
      this.elements.map((i, index)=> {
        i.index = index, i.marginLeft = this.elementMargin, i.marginRight = this.elementMargin
      });
    }
    
    this.progressMargin = 0;
    this.counterLeft = 0;
    this.counterRight = 0;
    this.movementsToRight = 0;
    this.movementsToLeft = 0;
  }

  getElementsView():number {
    if (this.scrWidth > 1450) {
      return 4;
    }else if (this.scrWidth <= 1450 && this.scrWidth > 1024){
      return 3;
    } else if (this.scrWidth <= 1024 && this.scrWidth > 700){
      return 2;
    } else {
      return 1;
    }
  }

  prev() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.elements.find(x => x.index === 0).marginLeft = -(this.elementWidth) * this.currentIndex + this.elementMargin;

      if (this.counterRight - this.counterLeft <= this.pages - this.progressSize && this.movementsToRight - this.movementsToLeft > 0){
        this.progressMargin = this.progressMargin + 24;
        this.movementsToLeft++;
      }
      this.counterLeft++;
    }
    
    
  }

  next() {
    if (this.currentIndex < this.pages - 1) {
      this.currentIndex++;
      this.elements.find(x => x.index === 0).marginLeft = -(this.elementWidth) * this.currentIndex + this.elementMargin;

      if (this.counterRight - this.counterLeft >= this.progressSize - 1 && this.movementsToRight - this.movementsToLeft < this.pages - this.progressSize){
        this.progressMargin = this.progressMargin - 24;
        this.movementsToRight++;
      }
      this.counterRight++;
    }
  }
  /**carousel */
}

