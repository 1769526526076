import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from "jwt-decode";
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from 'src/app/core/services/login.service';
import { PackageService } from 'src/app/core/services/package.service';
import { AES, enc } from 'crypto-js';

declare var google: any;

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.css'],
  providers: [LoginService, PackageService]
})
export class CouponComponent implements OnInit {
  datos;
  userId;
  errorMessage = false;
  private sub: any;
  objeto: any;
  inputvaluae;
  rptaValidate;
  sendNumber;
  validMessage;
  numero = "";
  widthgoogle = 440;
  countries: any[] = [];
  urlTree: any;
  nombre = "+51";
  
  constructor(private spinner: NgxSpinnerService, private packageService: PackageService, private route: Router, private service: LoginService) { }

  ngOnInit() {
    this.getCountryCodes();
    this.subscribeParams();
    this.initialgoogle();
  }

  initialgoogle() {
    google.accounts.id.initialize({
      client_id: "536885259092-r3vfm0r10kj052kmmp56om86nhln0040.apps.googleusercontent.com",

      callback: this.handleCredentialResponse
    });
    google.accounts.id.renderButton(
      document.getElementById("couponGmail"),
      {
        logo_alignment: "center",
        type: "standard",
        shape: "rectangular",
        theme: "outline",
        text: "continue_with",
        size: "medium",

        width: 400
      }  // customization attributes
    );
  }

  handleCredentialResponse(response) {


    console.log('handleCredentialResponse::::::', response);
    var decoded = jwt_decode(response.credential);


    this.datos = decoded;
    this.objeto = {
      name: this.datos.given_name,
      lastName: this.datos.family_name,
      email: this.datos.email
    }
    let codigo = '?' + response.credential;
    let valor = window.location.href + codigo;
    console.log('handleCredentialResponse:::::: - valor', valor);
    window.location.replace(valor);
    /* window.location.reload(); */
    /* this.registerGoogle(data); */

  }

  setId() {

    this.inputvaluae = document.getElementById("numeros");
    this.inputvaluae.value = "";
    if (this.nombre === "+51") {

      this.inputvaluae.maxLength = 9;
    } else {
      this.inputvaluae.maxLength = 13;
    }
  }

  ValidarSoloNumero(event) {
    // tslint:disable-next-line: max-line-length
    if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105) && event.keyCode !== 190 && event.keyCode !== 110 && event.keyCode !== 8 && event.keyCode !== 9) {
      return false;
    }
  }

  getCountryCodes() {
    this.packageService.getCountryCodes().subscribe(
      x => {
        if (x.confirmation && x.data) {
          this.countries = x.data.filter(x => x.id !== null && x.id !== '');
        }
      },
      err => {

      },
      () => {

      }
    );
  }

  subscribeParams() {
    this.urlTree = this.route.parseUrl(this.route.url);
    this.userId = this.urlTree.queryParams['userid'];
    this.validateUser(this.userId);
  }

  goEmail(){
    const data = {
      userID: this.userId,
      number : null,
      data: null,
      validate : this.rptaValidate
    }
    const encryptionKey = 'domiruth';
    const objetoEncriptado = AES.encrypt(JSON.stringify(data), encryptionKey).toString();
    this.route.navigate(['auth/coupon-email'], { queryParams: { data: objetoEncriptado } });
    /* this.route.navigate(['auth/coupon-email']); */
  }

  loginPhone() {
    this.errorMessage = false;
    if (this.numero === "") {
      let valor = document.getElementById("numeros");
      valor.style.border = "1px solid red";
      return;
    }
    this.spinner.show();
    let valor = this.nombre + this.numero;
    valor = valor.slice(1);
    this.sendNumber = valor;
    this.packageService.validatePhone(valor).subscribe(
      x => {
        //console.log(x);
        if (x.status === 200) {
          const data = {
            number : this.numero,
            data: x.odata,
            validate : this.rptaValidate
          }
          const encryptionKey = 'domiruth';
          const objetoEncriptado = AES.encrypt(JSON.stringify(data), encryptionKey).toString();
          this.route.navigate(['auth/coupon-phone'], { queryParams: { data: objetoEncriptado } });
        } else {
          this.validMessage = x.message;
          this.errorMessage = true;
        }
        this.spinner.hide();
      }
    )
  }


  validateUser(data) {
    this.service.validateUserVacational(data).subscribe(
      x => {
        console.log(x);
        this.rptaValidate = x.odata;
        /*         if(!x.odata.isUserVacational){
                  this.route.navigate(["coupon-register"]);
                } */
      }
    )
  }

}
