import { getUrlScheme, MethodCall } from '@angular/compiler';
import { environment } from '../../environments/environment';

// export const baseUrl = 'http://172.16.7.99:8015/';
// export const baseUrl = 'http://172.16.7.99:63862/';
// export const baseUrl = 'http://52.190.24.114/vacation/'; //uat
// export const baseUrl = 'https://domiruth-uat.azure-api.net/vacation/';


// export const baseUrl = 'http://52.142.32.21/vacation/'; //produccion

// export const urlIcons = 'http://vacationadminuatsa.blob.core.windows.net/icons/'; //uat
// export const urlIcons = 'http://vacationadminprdsa.blob.core.windows.net/icons/'; //prd

// export const urlIp = 'http://api.ipify.org/?format=json';   // uat
// export const urlIp = 'https://api.ipify.org/?format=json';   // produccion


export const SHOW_CASE_PACKAGE_METHOD = 'VacationPackage';
export const DESTINIES_METHOD = 'Destination/GetTags';
export const AUTOCOMPLETE_METHOD = 'VacationPackage/Autocomplete';
export const MONTHS_METHOD = 'VacationPackage/Month';
export const SEARCH_METHOD = 'VacationPackage/Search';
export const PACKAGE_DETAIL_METHOD = 'VacationPackage/detail';
export const PACKAGE_SUMMARY_METHOD = 'VacationPackage/Search';
export const DEPARTURES_METHOD = 'DeparturePackage/Combo';
export const PACKAGE_PRICE_METHOD = 'PricePackage/HotelAirLine';
export const CALCULATE_PRICE_METHOD = 'PricePackage/CalculationFinish';
export const EXCHANGE_RATE_METHOD = 'Currency';
export const HOTEL_HTML_METHOD = 'PricePackage/Hotel/Detail';
export const FLIGHT_ITINERARY_HTML_METHOD = 'PricePackage/Airline/Detail';
export const FLIGHT_ITINERARY_METHOD = 'PricePackage/Flight/Itinerary';
export const ITINERARIES_METHOD = 'VacationPackage/Itinerary';
export const DOCUMENT_TYPE_METHOD = 'TypeDocument';
export const DOCUMENT_TYPE_METHOD_FLIGHT = 'DocumentType/GetDocumentType';
export const BANK_LIST_METHOD = 'Bank';
export const GET_CHARTER = 'Charter/GetCharterFlight?isAdministrator=false';
export const GET_CHARTER_DETAIL = 'Charter/GetCharterDetail';
export const VALIDATE_STOCK = 'Charter/ValidateStock';
export const GET_CHARTER_TRANSACTION = 'Transaction/GenerateTransaction';
export const CONFIRM_TRANSACTION = 'Transaction/ConfirmTransaction';
export const COUNTRY_LIST_METHOD = 'Country';
export const COUNTRY_CODE_LIST_METHOD = 'Country/Code'; 
export const PAYMENT_TYPE_METHOD = 'PaymentMethod/combo';
export const PAYMENT_TYPE_DETAIL_METHOD = 'Payment';
export const TRANSACTION_UPDATE_STATUS_METHOD = 'Transaction/Update/Visa';
export const TRANSACTION_FLIGHT_UPDATE_STATUS_METHOD = 'FlightHotelTrans/VisaPayement';
export const TRANSACTION_GET_PURCHASE_NUMBER_METHOD = 'Transaction/code/visa';
export const RESERVATION_METHOD = 'Transaction';
export const NEW_VISA_SESSION = 'Visa/GetVisaSession';
export const ORDER_DETAIL_PURCHASE = 'Transaction/GetTransactionByPurchaseNumber';
export const GET_COUNTRIES = 'Country/GetCountry';
export const GET_DOCUMENT = 'DocumentType/GetDocumentType';
export const GET_FILTER_PACKAGES = 'VacationPackage/GetPackageByCategoryURL';

export const VALIDATE_EMAIL = 'Person/ValidatePersonalEmail';
export const SEND_SMS = 'Person/SendSms';
export const RESET_PASSWORD = 'User/ResetPassword';
export const UPDATE_PASSWORD = 'User/UpdatePassword';
export const ACCESS_EMAIL = 'User/LoginAccess';
export const SMS_AGAIN = 'Person/SendSms';
export const REGISTER_PHONE = 'Person/UpdatePersonalPhone';
export const REGISTER_USER = 'Person/ManagePerson';
export const UPDATE_USER = 'User/UpdateUser';
export const GET_TAG_PACKAGES = 'VacationPackage/GetPackageByTags';
export const LOGIN_PARTNER = 'Authenticate/LoginUser';
export const VALIDATE_USER_CUOPON = 'User/ValidateUserVacational';
export const VALIDATE_PARTNER = 'PartnerClub/ValidateInvitationPartnerClub';
export const ACTIVATE_PARTNER = 'PartnerClub/ActivationPartnerClub';
export const VALIDATE_ACTIVATE = 'PartnerClub/ValidateActivationPartnerClub';
export const USER_BY_ID = 'User/GetUserById';
export const QUOTE = 'Insurance/QuoteInsurance';
export const PROMOTIONAL_CODE = 'VacationPackage/ValidatePromotionalCode?vacationPackageID='
export const TOKEN = 'Token/GetToken';
/**
 * FLIGHT
 */
export const PRIORITY_AIRPORTS = 'CityAirport/GetCityAirport?priority=true';
export const AIRPORTS = 'CityAirport/GetCityAirport?priority=false';




/**
 * VISA
 */
export const VISA_TOKEN_METHOD = 'CargarToken';
// export const VIEW_CONTENT = 'AllInformation/GetListInformation';
export const VISA_DETAIL_METHOD = 'ListaXpurchaseNumber';
export const VISA_DETAIL_NEW_METHOD = 'Visa/GetPurchase?purchaseNumber=';
// export const VISA_AUTHENTICATION_METHOD = 'https://domiribbon.azurewebsites.net/visa/AutorizarTransaccion';  
export const VISA_AUTHENTICATION_METHOD = 'AutorizarTransaccionVAC ';
export const NEW_VISA_AUTHENTICATION = 'Visa/SendVisaAuthorization?successURL= ';
export const VISA_ERROR_DETAIL_METHOD = 'GetCodAccionxID';
export const VISA_TIMEOUT_METHOD = '';
export const DEBUG_VISA = false;
export const VISA_SESSION_CURRENCY = 'USD';
export const VISA_SESSION_CHANNEL = 'web';
export const VISA_SESSION_SISTEMA = 'Vacaciones';
export const VISA_SESSION_COD_SISTEMA = 'VAC';
export const VISA_SESSION_MDD70 = 'No';
export const VISA_SESSION_MDD75 = 'Invitado';
export const VISA_SESSION_MERCHANTID = '2'; // 1 soles, 2 dolares
export const VISA_SESSION_MERCHANTID_FORM = '115015006'; // 602545705 soles, 115015006 dolares
export const VISA_SESSION_PRODUCTID = '341198214';
export const VISA_SESSION_EMAIL_DEMO = 'accept@cybersource.com';
export const VISA_SESSION_NAME_DEMO = 'Juan';
export const VISA_SESSION_LASTNAME_DEMO = 'Visa';
export const VISA_SESSION_DOC_DEMO = 'DNI';
export const VISA_SESSION_NUMDOC_DEMO = '12345678';
export const VISA_SESSION_AMOUNT_DEMO = 1.00;
export const VISA_SESSION_TIMEOUT_DEMO = '5';
export const VISA_SESSION_TIMEOUT = '20';

/**
 * session
 */
// export const SESSION_BOOKING = 'booking11';
// export const SESSION_TOKEN = 'session_token';
export const SESSION_PACKAGE_SUMMARY = 'package_summary';
export const SESSION_BOOKING_FORM = 'booking_form';
export const SESSION_EXCHANGE_RATE = 'currency';
export const VALID_CUPON = 'Coupon/ValidateCoupon';
export const SLASH = '/';
const DEBUG = false;


export function getShowCasePackagesUrl(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5e05231831000038befd3298';
    } else {
        return environment.baseUrl + method;
    }
}

export function getPackageDetailUrl(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5e05293f310000705ffd32a8';
    } else {
        return environment.baseUrl + method;
    }
}

export function getDestiniesUrl(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5dfd31d7310000c3c9d2c376';
    } else {
        return environment.baseUrl + method;
    }
}

export function getDeparturesUrl(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5dfa9ad32f00007900ff9a7a';
    } else {
        return environment.baseUrl + method;
    }
}

export function getPackagesPriceUrl(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5e00d44e2f00000f0013b730';
    } else {
        return environment.baseUrl + method;
    }
}

export function getCalculatePriceUrl(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5dfa9b0b2f00006800ff9a7b';
    } else {
        return environment.baseUrl + method;
    }
}

export function getExchangeRateUrl(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5dfa9b2c2f00005700ff9a7d';
    } else {
        return environment.baseUrl + method;
    }
}

export function getHotelHtmlUrl(method: string){
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5dfab5022f00006800ff9ac8';
    } else {
        return environment.baseUrl + method;
    }
}

export function getFlightItineraryHtmlUrl(method: string){
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5dfabbc72f00002b00ff9ad5';
    } else {
        return environment.baseUrl + method;
    }
}

export function getFlightItinerariesUrl(method: string){
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5dfac6242f00005d00ff9aee';
    } else {
        return environment.baseUrl + method;
    }
}

export function getItinerariesUrl(method: string){
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5e00f0fe2f00002b0013b7fe';
    } else {
        return environment.baseUrl + method;
    }
}

export function getAutocompleteUrl(method: string){
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5e011e562f00000acc13b89f';
    } else {
        return environment.baseUrl + method;
    }
}

export function getResultUrl(method: string){
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5e0145802f00003400dcd14e';
    } else {
        return environment.baseUrl + method;
    }
}

export function getMonthsUrl(method: string){
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5e02525b2f00007696dcd620';
    } else {
        return environment.baseUrl + method;
    }
}

export function getNewSearchFlightUrl(method: string) {
    if (DEBUG) {
        // return 'http://www.mocky.io/v2/5e6669bb3100000ee8239f9f';
        return 'http://www.mocky.io/v2/5e6906de2f00006bf8d8b1fd'
    } else {
        return environment.urlFlight + method;
    }
}

export function getPackageSummaryUrl(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5e2644da2f00006700ce2eaa';
    } else {
        return environment.baseUrl + method;
    }
}

export function getDocumentTypeUrl(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5e26450b2f00007400ce2eab';
    } else {
        return environment.baseUrl + method;
    }
}

export function getDocumentTypeUrlFlight(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5e26450b2f00007400ce2eab';
    } else {
        return environment.urlSecurity + method;
    }
}

export function getBankUrl(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5e270f572f00005b00a4f987';
    } else {
        return environment.baseUrl + method;
    }
}

export function getCountryUrl(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5e26452a2f0000296cce2eac';
    } else {
        return environment.baseUrl + method;
    }
}

export function getCharter(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5e26452a2f0000296cce2eac';
    } else {
        return environment.baseCharter + method;
    }
}

export function getCountryCodesUrl(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5e2645452f0000b95bce2ead';
    } else {
        return environment.baseUrl + method;
    }
}

export function getPaymentTypesUrl(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5e286a363200005200d84202';
    } else {
        return environment.baseUrl + method;
    }
}

export function getPaymentTypesDetailUrl(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5e27110d2f0000eeb4a4f999';
    } else {
        return environment.baseUrl + method;
    }
}

export function getReservationUrl(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5e3b248c2f0000cbe656c943';
    } else {
        return environment.baseUrl + method;
    }
}

export function getNewVisaSession(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5e3b248c2f0000cbe656c943';
    } else {
        return environment.urlNewVisa + method;
    }
}

export function getPromotional(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5e3b248c2f0000cbe656c943';
    } else {
        return environment.baseUrl + method;
    }
}



export function updateStatusPaymentUrl(method: string) {
    if (DEBUG) {
        return '';
    } else {
        return environment.baseUrl + method;
    }
}

export function getPurchaseNumberUrl(method: string) {
    if (DEBUG) {
        return '';
    } else {
        return environment.baseUrl + method;
    }
}

export function getOrderDetailPurchaseUrl(method: string){
    if (DEBUG) {
        return '';
    } else {
        return environment.baseUrl + method;
    }
}

export function getSearchNewFlightUrl(method: string) {
    if (DEBUG) {
        // return 'http://www.mocky.io/v2/5e6669bb3100000ee8239f9f';
        return 'http://www.mocky.io/v2/5e6906de2f00006bf8d8b1fd'
    } else {
        return environment.urlFlight + method;
    }
}

export function getSearchFlightUrl(method: string) {
    if (DEBUG) {
        // return 'http://www.mocky.io/v2/5e6669bb3100000ee8239f9f';
        return 'http://www.mocky.io/v2/5e6906de2f00006bf8d8b1fd'
    } else {
        return environment.urlFlight + method;
    }
}

export function getInsuranceOperationUrl(method: string) {
    if(DEBUG) {
        return 'http://www.mocky.io/v2/5e6906de2f00006bf8d8b1fd'
    }else {
        return environment.baseUrl + method;
    }
}

export function getSearchFlightHotelUrl(method: string) {
    if (DEBUG) {
        // return 'http://www.mocky.io/v2/5e6669bb3100000ee8239f9f';
        return 'http://www.mocky.io/v2/5e6906de2f00006bf8d8b1fd'
    } else {
        return environment.baseHotelUrl + method;
    }
}

export function getSearchHotelUrl(method: string) {
    if (DEBUG) {
        // return 'http://www.mocky.io/v2/5e6669bb3100000ee8239f9f';
        return 'http://www.mocky.io/v2/5e6906de2f00006bf8d8b1fd'
    } else {
        return environment.baseHotelUrl + method;
    }
}

export function getRegulationUrl(method: string) {
    if (DEBUG) {
        // return 'http://www.mocky.io/v2/5e6669bb3100000ee8239f9f';
        return 'http://www.mocky.io/v2/5e6906de2f00006bf8d8b1fd'
    } else {
        return environment.baseUrl + method;
    }
}

export function getSearchHoltelUrl(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5eb40cbd0e00005400081886'
    } else {
        return environment.baseHotelUrl + method;
    }
}

export function getDynamicSearch(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5eb40cbd0e00005400081886'
    } else {
        return environment.baseHotelUrl + method;
    }
}

export function getSearchListHotel(method: string) {
    return environment.baseHotelUrl + method;
}

export function getSelectRoom(method: string) {
    return environment.baseHotelUrl + method;
}

export function getSearchListHabitacion(method: string) {
    return environment.baseHotelUrl + method;
}

export function getSearchHoltelDetailUrl(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5eb048f83300002b00c68d33'
    } else {
        return environment.baseHotelUrl + method;
    }
}

export function getValidationHotel(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5eb048f83300002b00c68d33'
    } else {
        return environment.baseHotelUrl + method;
    }
}

export function getBookingProduct(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5eb048f83300002b00c68d33'
    } else {
        return environment.baseHotelUrl + method;
    }
}

export function getCancelationFees(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5eb048f83300002b00c68d33'
    } else {
        return environment.baseHotelUrl + method;
    }
}

export function getGenerateHotelReservation(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5eb048f83300002b00c68d33'
    } else {
        return environment.baseUrl + method;
    }
}

export function getPriorityAirportsUrl(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5e594ac82f0000b1fe9625e0';
    } else {
        return environment.urlFlight + method;
    }
}
export function getBrandedFareUrl(method: string) {
    return environment.urlFlight + method;
}

export function getQuote(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5e594ac82f0000b1fe9625e0';
    } else {
        return environment.urlInsurance + method;
    }
}

export function getQuoteF(method: string) {
    if (DEBUG) {
        return 'http://www.mocky.io/v2/5e594ac82f0000b1fe9625e0';
    } else {
        return environment.urlFlight + method;
    }
}
