import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Select } from 'src/app/shared/models/select.model';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'dm-select-multiple',
  templateUrl: './dm-select-multiple.component.html',
  styleUrls: ['./dm-select-multiple.component.css']
})
export class DmSelectMultipleComponent implements OnInit {

  @Input() list: Select[];
  @Input() label: string;
  @Output() selectItems = new EventEmitter<string[]>();

  items = new FormControl();

  constructor() { }

  ngOnInit() {
    this.items.setValue(this.list.map(x => x.id));
  }

  isChecked(): boolean {
    return this.items.value && this.list.length
      && this.items.value.length === this.list.length;
  }

  isIndeterminate(): boolean {
    return this.items.value && this.list.length && this.items.value.length
      && this.items.value.length < this.list.length;
  }

  toggleSelection(change: MatCheckboxChange): void {
    if (change.checked) {
      this.items.setValue(this.list.map(x => x.id));
    } else {
      this.items.setValue([]);
    }    
    this.selectItems.emit(this.items.value);
  }

  onSelectionChange() {
    this.selectItems.emit(this.items.value);
  }

}
