import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions } from 'ngx-gallery-9';
import { DialogVideosComponent } from '../dialog-videos/dialog-videos.component';
import { DmImageGalleryComponent } from '../dm-image-gallery/dm-image-gallery.component';

@Component({
  selector: 'dm-media-viewer',
  templateUrl: './dm-media-viewer.component.html',
  styleUrls: ['./dm-media-viewer.component.css']
})
export class DmMediaViewerComponent implements OnInit {

  @Input() photos: any[];
  @Input() videos: string[];

  public scrHeight:any;
  public scrWidth:any;
  currentScreenMode: number = 0 //1:web 2:phone

  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];

  constructor(private dialog: MatDialog) {
    this.getScreenSize();
   }

  @HostListener('window:resize')
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    let view = this.getScreenMode();
    if (this.currentScreenMode !== view){
      this.currentScreenMode = view;
      console.log('change mode');
    }
  }

  ngOnInit() {
    this.loadImage();
    this.configGallery();
  }

  selectPhotos(index) {
    let objeto = {
      data: this.photos,
      index: index
    }
    const dialogRef = this.dialog.open(DmImageGalleryComponent, {
      data: objeto
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  selectVideos() {
    const dialogRef = this.dialog.open(DialogVideosComponent, { data: this.videos });

    dialogRef.afterClosed().subscribe(x => {
    });
  }

  getScreenMode():number {
    return this.scrWidth > 667 ? 1 : 2;
  }

  configGallery() {
    this.galleryOptions = [
      {
          arrowPrevIcon: "fa fa-chevron-circle-left", 
          arrowNextIcon: "fa fa-chevron-circle-right",
          preview: false,
          width: '300px',
          height: '206px',
          thumbnails: false,
          imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
          arrowPrevIcon: "fa fa-chevron-circle-left", 
          arrowNextIcon: "fa fa-chevron-circle-right",
          preview: false,
          breakpoint: 800,
          width: '300px',
          height: '206px',
          thumbnails: false,
          imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 400
      {
          breakpoint: 400,
          preview: false
      }
    ];
  }

  loadImage() {
    for (let index = 0; index < this.photos.length; index++) {
      let imagen: NgxGalleryImage = {
        small: this.photos[index].small,
        medium: this.photos[index].median,
        big: this.photos[index].big,
      };
      this.galleryImages.push(imagen);
    }
  }

}
