import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as config from '../config';
import { Response } from '../../shared/models/response.model';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

let httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': environment.subsKey,
    })
};

@Injectable()
export class LoginService {
  
    private urlLogin: string = environment.urlSecurity + config.LOGIN_PARTNER;
    private validateInvi: string = environment.urlCustom + config.VALIDATE_PARTNER;
    private validateCouponUser: string = environment.urlSecurity + config.VALIDATE_USER_CUOPON;
    private activatePartner: string = environment.urlCustom + config.ACTIVATE_PARTNER;
    private valiActivation: string = environment.urlCustom + config.VALIDATE_ACTIVATE;
    private userById: string = environment.urlCustom + config.USER_BY_ID;
    private urlToken = environment.urlSecurity + config.TOKEN;
    private sessionKey = "redis-token";
    constructor(private http: HttpClient) {

    }

    loginPartner(datos): Observable<any> {
        // httpOptions.headers = new HttpHeaders({
        //   'Content-Type': "application/json",
        //   'Ocp-Apim-Subscription-Key': environment.subsKey,
      
        // });
        return this.http.post<any>(this.urlLogin , datos, httpOptions);
    }

    validateUserVacational(data): Observable<any> {
      // httpOptions.headers = new HttpHeaders({
      //   'Content-Type': 'application/json',
      //   'Ocp-Apim-Subscription-Key': environment.subsKey,
    
      // });
      const url = `${this.validateCouponUser}?${'userID=' + data}`;
      return this.http.get<any>(url, httpOptions);
  }

    activationPartner(datos): Observable<any> {
        // httpOptions.headers = new HttpHeaders({
        //   'Content-Type': 'application/json',
        //   'Ocp-Apim-Subscription-Key': environment.subsKey,
      
        // });
        return this.http.post<any>(this.activatePartner , datos, httpOptions);
    }

    validatePartner(data): Observable<any> {
        // httpOptions.headers = new HttpHeaders({
        //   'Content-Type': 'application/json',
        //   'Ocp-Apim-Subscription-Key': environment.subsKey,
      
        // });
        const url = `${this.validateInvi}?${'loginUser=' + data}`;
        return this.http.get<any>(url, httpOptions);
    }

    validateActivation(data): Observable<any> {
        // httpOptions.headers = new HttpHeaders({
        //   'Content-Type': 'application/json',
        //   'Ocp-Apim-Subscription-Key': environment.subsKey,
      
        // });
        const url = `${this.valiActivation}?${'loginUser=' + data}`;
        return this.http.get<any>(url, httpOptions);
    }

    GetUser(data): Observable<any> {
      // httpOptions.headers = new HttpHeaders({
      //   'Content-Type': 'application/json',
      //   'Ocp-Apim-Subscription-Key':  environment.subsKey
      // });
      const url = `${this.userById}?${'userId=' + data}`;
      return this.http.get<any>(url, httpOptions);
    }
    CreateToken(): Observable<any> {
        const url = `${this.urlToken}`;
        return this.http.get<any>(url, httpOptions).pipe(map((data) => {
            localStorage.setItem(this.sessionKey, JSON.stringify(data.transactionID))
            return of(data);
        }));
    }
    GetToken(): string{
        const json = localStorage.getItem(this.sessionKey);
       return JSON.parse(json);
     }
}