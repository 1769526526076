import { Component, OnInit, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogDetailComponent } from '../dialog-detail/dialog-detail.component';

@Component({
  selector: 'app-dialog-pago-efectivo',
  templateUrl: './dialog-pago-efectivo.component.html',
  styleUrls: ['./dialog-pago-efectivo.component.css']
})
export class DialogPagoEfectivoComponent implements OnInit {

  urlPagoEfectivo;

  constructor(
    protected sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<DialogDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) { }

  ngOnInit() {
    this.urlPagoEfectivo = this.sanitizer.bypassSecurityTrustResourceUrl(this.data);
  }

  onClose() {
    this.dialogRef.close();
  }

}
