export class FilterPassenger {

    static readonly TYPE_ADULT = 'ADT';
    static readonly TYPE_CHILD = 'CHD';
    static readonly TYPE_CHILD_LAN = 'CNN';
    static readonly TYPE_INFANT = 'INF';
    static readonly TYPE_CHILD_HOTEL = 'CHD';

    quantity: number;
    type: string;
}
