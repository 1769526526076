import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FlightSegment } from 'src/app/flights/shared/models/flight-segment.model';
import { FlightSectionGroup } from 'src/app/flights/shared/models/flight-section-group.model';
import { Schedule } from 'src/app/flights/shared/models/flights/schedule.model';
import { Segment } from 'src/app/flights/shared/models/flights/segment.model';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dm-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.css']
})
export class SegmentComponent implements OnInit {

  @Input() schedule: Schedule;
  @Input() segments: Segment[];
  @Input() bagAllowed: boolean;
  @Input() bagQuantity: number;
  @Input() recommendationId: number;
  @Input() sectionId: number;
  @Input() index: number;
  @Output() selectSegment = new EventEmitter<number>();
  ocarrierHeight = 23;
  ocarrierWidth = 80;
  objectFit = 'inherit';

  constructor() { }

  ngOnInit() {
    this.schedule.selected = this.index === 0;
    switch(this.schedule.ocarrier.marketingCode){
      case 'H2':
        this.ocarrierHeight = 23;
        this.ocarrierWidth = 60;
        this.objectFit = 'cover';
        break;
      case 'LA':
        this.ocarrierHeight = 23;
        this.ocarrierWidth = 80;
        this.objectFit = 'inherit';
        break;
      case 'AF':
        this.ocarrierHeight = 23;
        this.ocarrierWidth = 100;
        this.objectFit = 'cover';
        break;
      case 'AM':
        this.ocarrierHeight = 23;
        this.ocarrierWidth = 100;
        this.objectFit = 'cover';
        break;
      case 'UA':
        this.ocarrierHeight = 23;
        this.ocarrierWidth = 100;
        this.objectFit = 'cover';
        break;
      default:
        this.ocarrierHeight = 23;
        this.ocarrierWidth = 80;
        this.objectFit = 'inherit';
        break;
    }
  }

  expand_collapse(segment: Schedule) {
    segment.expanded = !segment.expanded;
  }

  select() {
    if (!this.schedule.selected) {
      this.selectSegment.emit(this.schedule.id);
    }
  }

}
