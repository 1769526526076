import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import jwt_decode from "jwt-decode";
import { PackageService } from 'src/app/core/services/package.service';
import { SessionService } from 'src/app/core/services/session.service';
import { DialogCorreoExistComponent } from '../dialog-correo-exist/dialog-correo-exist.component';
import { DialogExistsGoogleComponent } from '../dialog-exists-google/dialog-exists-google.component';
import { DialogRegisterComponent } from '../dialog-register/dialog-register.component';
declare var google: any;
@Component({
  selector: 'app-dialog-correo',
  templateUrl: './dialog-correo.component.html',
  styleUrls: ['./dialog-correo.component.css'],
  providers: [PackageService]
})
export class DialogCorreoComponent implements OnInit {

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    this.correoService();
  }
  correoVal;
  correoRegister;
  correoExist;
  datos;
  objeto : any;
  widthgoogle = 440;
  validInput = true;
  constructor(private snackBar: MatSnackBar,private sessionService: SessionService,private spinner: NgxSpinnerService, private packageService: PackageService, public dialog: MatDialog
    , public dialogRef: MatDialogRef<DialogCorreoComponent>, @Inject(MAT_DIALOG_DATA) public data: string) {this.initialgoogle() }

  ngOnInit() {
    this.validPhone();
    this.initialgoogle();
  }

  initialgoogle(){
    google.accounts.id.initialize({
      client_id: "536885259092-r3vfm0r10kj052kmmp56om86nhln0040.apps.googleusercontent.com",
      callback: this.handleCredentialResponse
    });
    google.accounts.id.renderButton(
      document.getElementById("buttonDivs"),
      { logo_alignment: "center", text: "signup_with",size:"medium",scope:"profile email", width: this.widthgoogle }    // customization attributes
    );
  }

  validPhone(){
    if( navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)){
      this.widthgoogle = 280;
    }
  }

  handleCredentialResponse(response) {

    var decoded = jwt_decode(response.credential);

   
    this.datos = decoded;
    this.objeto = {
      userId: "",
      nombre: this.datos.given_name,
      apellido: this.datos.family_name,
      email: this.datos.email,
      phone: "",
      genero: "",
      countryCode: "",
      documentNumber : "",
      typeDocument: "",
      birthDate: ""
    }
    
    let codigo = '?' + response.credential;
    let valor = window.location.href + "singIn" + codigo; 
    window.location.replace(valor);
    /* this.registerGoogle(data); */
    
  }

  onClose() {
    this.dialogRef.close();
  }

  esEmailValido(): boolean {
    let mailValido = false;
    'use strict';
    let valid = document.getElementById("conti");
    var EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (this.correoVal.match(EMAIL_REGEX)) {
      mailValido = true;
      
      valid.style.backgroundColor = "#E82D56";
      valid.style.pointerEvents = "visible";
    } else {
      valid.style.backgroundColor = "rgba(232, 45, 86, 0.5)";
      valid.style.pointerEvents = "none";
    }
    return mailValido;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 5000,
    });
  }

  correoService() {
    var valor = this.correoVal;
    let data = valor.trim().length;
 
    this.spinner.show();
    this.packageService.validateEmail(this.correoVal,"").subscribe(
      x => {
        //console.log(x);
        if(x.odata != null){
          if(x.odata.opersonVacational != null){
            const obj = {
              userId: x.odata.opersonVacational.userID,
              email: x.odata.opersonVacational.email,
              nombre: x.odata.opersonVacational.name,
              apellido: x.odata.opersonVacational.lastName,
              phone: x.odata.opersonVacational.phone,
              genero: x.odata.opersonVacational.gender,
              countryCode: x.odata.opersonVacational.countryOrigin,
              documentNumber : x.odata.opersonVacational.documentNumber,
              typeDocument: x.odata.opersonVacational.documentType,
              birthDate: x.odata.opersonVacational.birthDate
            }
            this.sessionService.setEmailFinish(obj);
          } else {
            sessionStorage.setItem("bygoogle",x.odata.registeredByGoogle);
          }
          
        }
        if (x.odata.existsEmail === false) {
          this.spinner.hide();
          this.dialogRef.close();
          this.openDialog();
        } else {
          if(x.odata.registeredByGoogle){
            this.spinner.hide();
            this.dialogRef.close();
            this.openDialogExisteasd();
          } else {
            this.spinner.hide();
            this.dialogRef.close();
            this.openDialogExiste();
          }
        
        }
      }
    )
  }

  openDialogExisteasd() {
    const dialogRef = this.dialog.open(DialogExistsGoogleComponent, {
      data: this.correoVal,
      panelClass: 'custom-dialog-container',
      maxWidth: 800
    });

    dialogRef.afterClosed().subscribe(result => {
      /* //console.log('The dialog was closed'); */
      if (result) {
        this.correoExist = result;
      }
    });
  }

  openDialogExiste() {
    const dialogRef = this.dialog.open(DialogCorreoExistComponent, {
      data: this.correoVal,
      panelClass: 'custom-dialog-container',
      maxWidth: 800
    });

    dialogRef.afterClosed().subscribe(result => {
      /* //console.log('The dialog was closed'); */
      if (result) {
        this.correoExist = result;
      }
    });
  }

  openDialog() {
    this.sessionService.setCorreo(this.correoVal);
    const dialogRef = this.dialog.open(DialogRegisterComponent, {
      data: null,
      panelClass: 'custom-dialog-container',
      maxWidth: 800,

    });

    dialogRef.afterClosed().subscribe(result => {
      /* //console.log('The dialog was closed'); */
      if (result) {
        this.correoRegister = result;
      }
    });
  }

  validarEmail(valor) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3,4})+$/.test(valor)) {
      alert("La dirección de email " + valor + " es correcta.");
    } else {
      alert("La dirección de email es incorrecta.");
    }
  }

}
