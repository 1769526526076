import { Component, OnInit, Input } from '@angular/core';
import { FilterFlightWrapper } from '../../../flights/shared/filter-flight-wrapper.model';
import { FtItinerary } from '../../../flights/shared/models/flight-transaction/ft-itinerary.model';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dm-flight-itinerary',
  templateUrl: './flight-itinerary.component.html',
  styleUrls: ['./flight-itinerary.component.css']
})
export class FlightItineraryComponent implements OnInit {

  @Input() itinerary: FtItinerary;
  @Input() flightOrHotel = true;

  TYPE_ONE_WAY = FilterFlightWrapper.TYPE_ONE_WAY;
  TYPE_ROUND_TRIP = FilterFlightWrapper.TYPE_ROUND_TRIP;
  TYPE_MULTI = FilterFlightWrapper.TYPE_MULTI;

  constructor() { }

  ngOnInit() {
    console.log(this.itinerary);
  }

}
