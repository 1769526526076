import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Filter } from '../dm-autocomplete/shared/ac-filter.model';

@Component({
  selector: 'app-dm-seguros',
  templateUrl: './dm-seguros.component.html',
  styleUrls: ['./dm-seguros.component.css']
})
export class DmSegurosComponent implements OnInit {
  myControl = new FormControl();
  options: any[] = [
    {name: 'Nacional'},
    {name: 'Internacional'}
  ];
  filteredOptions: Observable<any[]>;
 
  @Input() label: string;
  @Input() llenarDestino;
  @Input() list: Filter[];
  @Input() bloquear;
  @Input() autocompleteControl: FormControl;
  @Output() inputChange = new EventEmitter<string>();
  @Output() itemSelected = new EventEmitter();
  constructor() { }

  ngOnInit() {
    if(this.label === "Origen"){
      this.myControl.setValue({name: 'Perú'});
    }
  
    if(this.llenarDestino != null){
      this.myControl.setValue({name: this.llenarDestino});
    } 
    
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith<string | any>(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.options.slice())
      );

      if(this.bloquear === true || this.bloquear === 0){
        this.myControl.disable();
      }
  }

  displayFn(user?: any): string | undefined {
    return user ? user.name : undefined;
  }

  subscribeInput() {
    this.myControl.valueChanges.subscribe(
      term => {
          this.inputChange.emit(term);
      }
    );
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    // const item = event.option.value as Filter;
    this.inputChange.emit(event.option.value);
  }


}
