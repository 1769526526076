import { Component, Input, OnInit, Output, ViewChild, EventEmitter, AfterViewInit } from '@angular/core';

import * as _moment from "moment";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RoomsCounter } from 'src/app/shared/controls/dm-rooms-counter/shared/rooms-counter.model';
import { FilterSearch } from './shared/filter.search.model';
import { SessionService } from 'src/app/core/services/session.service';
import { FlightService } from 'src/app/core/services/flight.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { OnlyHotelService } from 'src/app/core/services/only-hotel.service';
import { Router } from '@angular/router';
import { MatSnackBar } from "@angular/material";
import { airportsData } from 'src/app/shared/services/airport.const';

const moment = _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "L",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};


@Component({
  selector: 'app-hotel-search',
  templateUrl: './hotel-search.component.html',
  styleUrls: ['./hotel-search.component.css'],
  providers: [
    OnlyHotelService,
    FlightService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class HotelSearchComponent implements OnInit, AfterViewInit {

  @Input() data: any;
  @Input() expandable: any;
  @Input() theme: string;
  @Input() validSearch: any;

  @Output() searchHabi = new EventEmitter<boolean>();
  @Output() expand = new EventEmitter<boolean>();
  @Output() search = new EventEmitter<FilterSearch>();

  @ViewChild("dpReturnDate", { read: undefined, static: false })
  returnDatePicker: MatDatepicker<Date>;
  labelButton = "Buscar"
  form: FormGroup;
  validDestino = false;
  validFechas = false;
  mostrarAutoCompleteDes = false;
  minDepartureDate = new Date();
  minCalendar = new Date();
  minReturnDate = new Date();
  fromList = [];
  toList = [];
  mostrarAutoComplete= false;
  origins = [];
  roomModel: RoomsCounter[] = [];
  nights = "";
  roomsData: RoomsCounter[];
  passengers = 0;
  rooms = 0;
  show;
  searchRooms = false;
  textStar = "Estrellas"
  estrella;
  textImg = "newSearch";
  infoha;
  otherDiv: any;
  isPhone = false;
  nameHotel;
  destAnterior: any;
  dataAirport: any;
  public myObject!: { id: number, myObject: { myString: string } };
  constructor(private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private snackBar: MatSnackBar,
    private hotelService: OnlyHotelService,
    private sessionService: SessionService,
    private flightService: FlightService) 
    { 
      this.dataAirport = airportsData;
    }

  ngOnInit() {
    this.validPhone();
    if (this.validSearch === 2) {
      this.labelButton = "Modificar búsqueda";
    }
    this.minCalendar.setDate(this.minCalendar.getDate() + 1);
    this.initForm();
    this.infoha = this.sessionService.getInfoHabi();
    if (this.infoha != null) {
      this.searchRooms = this.infoha.validHome;
    }

    if (this.searchRooms) {
      this.nameHotel = this.infoha.ohotel.hotelName;
      this.minCalendar = new Date();
      this.minCalendar.setDate(this.minCalendar.getDate() + 1);
    }
    this.getAirports();
  }

  showFilter() {
    if (!this.isPhone) {
      return;
    }
    this.destAnterior = this.form.controls.to.value;
    this.mostrarAutoCompleteDes = true;


  }

  ocultarAutocomplete() {

    if (this.form.controls.to.value === null) {
      this.form.controls.to.setValue(this.destAnterior);
    }

    this.mostrarAutoCompleteDes = false;

  }

  validPhone() {
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
      this.textImg = "buscarPhone";
      this.isPhone = true;
      this.otherDiv = true;
    }
  }

  ngAfterViewInit() {


  }

  mostarHab(){
    if(this.isPhone){
      this.otherDiv = false;
      this.mostrarAutoComplete = true;
    }
  }

  ocultarHabi(valor){
   
    this.mostrarAutoComplete = false;
    this.roomsData = valor;
    this.otherDiv = true;
  }

  setStar(valor) {
    this.estrella = valor;
    if (valor === '5') {
      this.textStar = "5 estrellas"
    } else if (valor === '4') {
      this.textStar = "4 estrellas"
    } else {
      this.textStar = "3 estrellas"
    }
  }



  dividirCadena(cadenaADividir, separador) {
    var arrayDeCadenas = cadenaADividir.split(separador);
    this.show = arrayDeCadenas[3];
    for (var i = 0; i < arrayDeCadenas.length; i++) {
      /* //console.log(arrayDeCadenas[i]) */
    }
  }

  initForm() {
    let from;
    let to;
    let departureDate;
    let returnDate;
    let departureDateHabi;
    let returnDateHabi;
    if (this.data) {
      from = this.data.from;
      to = this.data.to;
      departureDate = this.data.departureDate;
      returnDate = this.data.returnDate;

      this.roomsData = this.data.roomMod;

      this.updateSummary(this.roomsData);
    }

    this.form = this.fb.group({
      from: new FormControl(from),
      to: new FormControl(to),
      departureDate: new FormControl(departureDate),
      returnDate: new FormControl(returnDate),
      departureDateHabi: new FormControl(departureDateHabi),
      returnDateHabi: new FormControl(returnDateHabi),
    });
  }


  updateSummary(rooms) {
    this.roomsData = rooms;
    let passengers = 0;
    rooms.forEach((room) => {
      passengers += room.adults + room.children;
    });
    this.passengers = passengers;
    this.rooms = rooms.length;
  }

  getPriorityAirports() {
    this.flightService.getPriorityAirports().subscribe(
      (result) => {
        if (result) {
          const airports = result.lairports ? result.lairports : [];
          const cities = result.lcities ? result.lcities : [];
          airports.forEach((airport) => {
            this.origins.push({
              code: airport.iataCode,
              countryCode: airport.countryCode,
              name: airport.name,
              searchName: airport.searchName,
              priority: airport.priority,
              /* icon: 'local_airport' */
              icon: "A",
            });
          });
          cities.forEach((city) => {
            this.origins.push({
              code: city.iataCode,
              countryCode: city.countryCode,
              name: city.name,
              searchName: city.searchName,
              priority: city.priority,
              /* icon: 'location_city' */
              icon: "C",
            });
          });
          this.origins.sort((a, b) => b.priority - a.priority);
        }
      },
      (err) => {
        /* //console.log('Error: ', err); */
      },
      () => {
        this.getAirports();
      }
    );
  }

  getAirports() {
    const _origins = [];
    let result = this.dataAirport;
    if (result) {
      const airports = result.lairports ? result.lairports : [];
      const cities = result.lcities ? result.lcities : [];
      airports.forEach((airport) => {
        _origins.push({
          code: airport.iataCode,
          countryCode: airport.countryCode,
          name: airport.name,
          searchName: airport.searchName,
          priority: airport.priority,
          cityName: airport.cityName,
          /*  icon: 'local_airport' */
          icon: "A",
        });
      });
      cities.forEach((city) => {
        _origins.push({
          code: city.iataCode,
          countryCode: city.countryCode,
          name: city.name,
          searchName: city.searchName,
          cityName: city.cityName,
          priority: city.priority,
          /* icon: 'location_city' */
          icon: "C",
        });
      });
      _origins.sort((a, b) => b.priority - a.priority);
    }

    this.origins = _origins;
    /* let valor = this.constant.encriptar(this.origins);
    this.constant.addObject(0, valor); */
    
    // this.flightService.getAirports().subscribe(
    //   (result) => {
    //     if (result) {
    //       const airports = result.lairports ? result.lairports : [];
    //       const cities = result.lcities ? result.lcities : [];
    //       airports.forEach((airport) => {
    //         _origins.push({
    //           code: airport.iataCode,
    //           countryCode: airport.countryCode,
    //           name: airport.name,
    //           searchName: airport.searchName,
    //           priority: airport.priority,
    //           cityName: airport.cityName,
    //           /*  icon: 'local_airport' */
    //           icon: "A",
    //         });
    //       });
    //       cities.forEach((city) => {
    //         _origins.push({
    //           code: city.iataCode,
    //           countryCode: city.countryCode,
    //           name: city.name,
    //           searchName: city.searchName,
    //           cityName: city.cityName,
    //           priority: city.priority,
    //           /* icon: 'location_city' */
    //           icon: "C",
    //         });
    //       });
    //       _origins.sort((a, b) => b.priority - a.priority);
    //     }
    //   },
    //   (err) => {
    //     /* //console.log('Error: ', err); */
    //   },
    //   () => {
    //     this.origins = _origins;
    //   }
    // );
  }

  onOriginChange(val: string) {
    if (val.length >= 3 && this.origins) {
      this.fromList = this.origins.filter(
        (item) => item.searchName.toLowerCase().search(val.toLowerCase()) >= 0
      );
    } else {
      this.fromList = [];
    }

  }

  onDestinationChange(val: string) {
    if(val === null){
      return;
    }
    if (val.length >= 3 && this.origins) {
      this.toList = this.origins.filter(
        (item) => item.searchName.toLowerCase().search(val.toLowerCase()) >= 0
      );
    } else {
      this.toList = [];
    }
    this.toList = this.ordenarHoteles(this.toList);
    this.validDestino = false;
  }

  ordenarHoteles(list) {
    let newList = [];
    list.forEach(element => {
      if (element.icon === 'C' && newList.length <= 4) {
        newList.push(element);
      }
    });

    list.forEach(element => {
      if (element.icon === 'A' && newList.length <= 9) {
        newList.push(element);
      }
    });

    /*  newList.sort((a, b) => a.priority - b.priority); */
    return newList;
  }



  onDepartureDateChange(event: MatDatepickerInputEvent<Date>) {
    if (this.form.controls.returnDate.value) {
      if (
        new Date(this.form.controls.departureDate.value) >=
        new Date(this.form.controls.returnDate.value)
      ) {
        this.form.controls.returnDate.setValue("");
      }
    }
    // this.minReturnDate = this.form.controls.departureDate.value;
    const result = new Date(this.form.controls.departureDate.value);
    result.setDate(result.getDate() + 1);
    this.minReturnDate = result;

    if (!this.form.controls.returnDate.value) {
      this.returnDatePicker.open();
    }
    this.validFechas = false;
  }

  onReturnDateChange(event: MatDatepickerInputEvent<Date>) {
    const days =
      new Date(this.form.controls.returnDate.value).getTime() -
      new Date(this.form.controls.departureDate.value).getTime();
    /*  //console.log(days); */
    /*  //console.log(Math.round(days / (1000 * 60 * 60 * 24))); */
    this.nights = Math.round(days / (1000 * 60 * 60 * 24)).toString();
  }

  onSelectRoom(rooms: RoomsCounter[]) {
    this.roomModel = rooms;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, null, {
      duration: 2000,
    });
  }

  selectHotel(filter) {
    this.spinner.show();
    let lstGuest = [];
    let lstHotel = [];
    let guestRoom = {
      NumberPassengers: this.passengers,
      RoomQuantity: this.rooms,
      TypePassenger: "ADT"
    }
    lstGuest.push(guestRoom);

    let hotel = {
      EndDate: filter.returnDate.substring(0, 10),
      HotelCode: this.infoha.ohotel.hotelCode,
      LguestPerRoom: lstGuest,
      StartDate: filter.departureDate.substring(0, 10),
    }
    lstHotel.push(hotel);
    let obj = {
      TypeSearch: "V",
      Pseudo: "LIMPE2235",
      Ocompany: null,
      Lusers: null,
      LHotel: lstHotel
    }
    this.hotelService.searchHotelListHabitacion2(obj).subscribe(
      x => {
        if (x === null) {
          this.spinner.hide();
          this.openSnackBar("Ocurrió un error al buscar, por favor intente nuevamente", "OK");
          return;
        }

        if (x.oerror === null && x.lroom!.length > 0) {
          /*  let valor = this.sessionService.getOnlyHotel();
           x.lroom = this.getGuarantee(x.lroom,valor.typeHotel); */
          x.validHome = true;
          this.sessionService.setInfoHabi(x);
          this.sessionService.setFirstHabi(x.lroom[0]);
          this.searchHabi.emit(x);
        } else {
          this.searchHabi.emit(x);
        }

      }
    )
  }

  onSearch() {
    this.updateSummary(this.roomModel);
    const filter: any = {
      from: this.form.controls.from.value,
      to: this.form.controls.to.value,
      departureDate: this.form.controls.departureDate.value,
      returnDate: this.form.controls.returnDate.value,
      rooms: this.rooms,
      stars: this.estrella,
      roomMod: this.roomModel,
      passengers: this.passengers,
      lBoard: [],
      lZone: [],
      lTripAdvisor: [],
      lSupplier: [],
      lRating: [],
      firstItem: "1",
    };

    this.data = filter;
    let valore = true;
    if (!this.data.to) {
      this.validDestino = true;
      valore = false;
    } else if (!this.data.to.name) {
      this.validDestino = true;
      valore = false;
    }
    if (!this.form.controls.departureDate.value || !this.form.controls.returnDate.value) {
      this.validFechas = true;
      valore = false;
    }
    if (valore) {


      var guion = "-";
      this.dividirCadena(this.data.to.name, guion);
      this.sessionService.setHotelFlightRecommendations([]);
      this.sessionService.setContador(1);
      this.sessionService.setFlightTransaction(null);
      this.sessionService.setIndiceImage(null);
      this.sessionService.setHotelFlightChange(null);
      this.sessionService.setFlightHotel(null);
      this.sessionService.setHotelOFilter(null);
      this.sessionService.setValidationRoom(null);
      this.sessionService.setFlightBooking(null);
      this.sessionService.setHotelFilter(filter);
      let valor = this.sessionService.getHotelFilter();
      if (this.validSearch === 2) {
        this.selectHotel(valor)
      } else {
        this.spinner.show();
        this.search.emit(filter);
      }
    }


    /* this.search.emit(filter); */

  }

  onExpand() {
    this.expandable = !this.expandable;
    this.expand.emit(this.expandable);
  }

}
