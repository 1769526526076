import { Component, Inject, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { MatAccordion, MAT_DIALOG_DATA } from '@angular/material';

import { DialogData } from '../../models/dialog-data.model';

@Component({
  selector: 'app-dialog-view-content',
  templateUrl: './dialog-view-content.component.html',
  styleUrls: ['./dialog-view-content.component.css']
})
export class DialogViewContentComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  @ViewChildren(MatAccordion) accordion: MatAccordion;
  ngOnInit() {
    
    console.log(this.data);
  }

  abrir(link){
    window.open(link, "_blank");
  }

}
