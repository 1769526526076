import { Component, OnInit, HostListener, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { FilterPassenger } from 'src/app/shared/models/filter-passenger.model';

@Component({
  selector: 'app-dm-passenger-charter',
  templateUrl: './dm-passenger-charter.component.html',
  styleUrls: ['./dm-passenger-charter.component.css']
})
export class DmPassengerCharterComponent implements OnInit {

  show: boolean;
  form: FormGroup;
  private wasInside = false;
  totalPassengers = 1;
  PASSENGER_LIMIT = 6;
  errorMessage = '';
  filter: FilterPassenger[] = [];

  @Input() value: FilterPassenger[];
  @Output() selection = new EventEmitter<FilterPassenger[]>();

  constructor(private eRef: ElementRef,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.initForm();
  }

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.show = false;
      this.errorMessage = '';
    }
    this.wasInside = false;
  }

  initForm() {

    let adults = 1;
    if (this.value) {
      adults = this.value[0].quantity;
    }

    this.totalPassengers = Number(adults);

    this.form = this.formBuilder.group({
      adults: new FormControl({value: adults, disabled: true}),
    });

    this.filter.push({
      quantity: adults,
      type: FilterPassenger.TYPE_ADULT
    });
    this.selection.emit(this.filter);
  }

  onFocus() {
    this.show = true;
  }

  removePassenger(type) {
    this.errorMessage = '';
    const adults = Number(this.form.controls.adults.value);
    switch (type) {
      case 'adults':
        if (adults > 1) {
            this.form.controls.adults.setValue((adults - 1).toString());
            this.totalPassengers--;
        }
        break;
    }
    this.updateFilter(type);
  }

  addPassenger(type) {
    this.errorMessage = '';
    if (this.totalPassengers < this.PASSENGER_LIMIT) {
      const adults = Number(this.form.controls.adults.value);
      switch (type) {
        case 'adults':
          this.form.controls.adults.setValue((adults + 1).toString());
          this.totalPassengers++;
          break;
      }
    } else {
      this.errorMessage = 'Máximo número de pasajeros permitidos: ' + this.PASSENGER_LIMIT;
    }
    this.updateFilter(type);
  }

  updateFilter(type) {
    switch (type) {
      case 'adults':
        this.filter[0] = { quantity: this.form.controls.adults.value, type: FilterPassenger.TYPE_ADULT };
        break;
    }
    this.selection.emit(this.filter);
  }


}
