import { AfterViewInit, Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef, HostListener, Renderer2 } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
} from "@angular/forms";
import { Observable, of } from "rxjs";
import { PackageService } from "src/app/core/services/package.service";
import { DialogPhoneContactComponent } from "src/app/shared/components/dialog-phone-contact/dialog-phone-contact.component";
import { NavigationExtras, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { SessionService } from "src/app/core/services/session.service";
import { FlightService } from "src/app/core/services/flight.service";
import {
  MatDatepickerInputEvent,
  MatDatepicker,
} from "@angular/material/datepicker";
import { FilterFlightWrapper } from "src/app/flights/shared/filter-flight-wrapper.model";
import { FilterSearch } from "src/app/hotels/hotel-filter-search/shared/filter-search.model";
import { ResultSearch } from "src/app/packages/shared/models/result-search.model";
import { PackageFilter } from "src/app/packages/shared/models/package-filter.model";
import { Location } from "@angular/common";
import { airportsData } from "src/app/shared/services/airport.const";
import { map, startWith } from "rxjs/operators";
import { months } from "src/app/shared/models/months";
import { HeaderService } from "src/app/shared/services/head.service";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "dm-filter-panel",
  templateUrl: "./dm-filter-panel.component.html",
  styleUrls: ["./dm-filter-panel.component.css"],
  providers: [PackageService, SessionService, FlightService],
})
export class DmFilterPanelComponent implements OnInit, AfterViewInit {
  @ViewChild("dpEndDeparture", { read: undefined, static: false })
  endDatePicker: MatDatepicker<Date>;
  @Output() selectModule = new EventEmitter<any>();
  @Output() buscar = new EventEmitter<any>();
  monthsData;
  filteredOptions: Observable<string[]>;
  monthControl = new FormControl("");
  // filteredOptions = new Observable<AutoComplete[]>();
  resultSearch: ResultSearch[];
  myControl = new FormControl("");
  autocompleateList: ResultSearch[];
  selectedDestiny: boolean;
  destiny: ResultSearch;
  showMonths: boolean;
  validVuelo = true;
  filterValue: string = '';
  filterValue2: string = '';
  selectedValue: string = '';
  filteredItems: any[];
  showList: boolean = false;
  isDivDisabled: boolean = false;
  validPaquetes = false;
  validVH = false;
  validDestino = false;
  validHotel = false;
  validSeguros = false;
  selectedMonth: any;
  arrayMonths: number[];
  destinies: ResultSearch[];
  options = [];
  viewFilter: boolean = false;
  @Input() selectedOption: number;
  @Input() expandable: boolean;
  monthTypes = [];
  textImg = "newSearch";
  tbanks: any[] = [
    "bcp-tag", "banbif-tag", "bbva-tag"
  ];
  searching = "";
  validcategoria = false;
  validdest = false;
  validFilter = false;
  filtervh = [];
  selectVH = {id: "", name: "", type: ""};
  valor;
  locat;
  lstName: string[] = [];
  partner;
  data: string[];
  valid: boolean = false;
  airportData: any;
  viewFullScream = false;
  filterTop = '45px'
  fitlerSearch = '0px';
  public myObject!: { id: number, myObject: { myString: string } };
  constructor(
    protected sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private packageService: PackageService,
    private sessionService: SessionService,
    private elementRef: ElementRef,
    private router: Router,
    public dialog: MatDialog,
    private renderer: Renderer2,
    private location: Location,
    private headerService: HeaderService
  ) { 
    this.airportData = airportsData;
  }

  ngOnInit() {
    console.log(this.expandable);
    this.validPhone();
    this.sessionService.setInfoHabi(null);
    this.locat = window.location.pathname;
    this.valor = window.location.href;
    this.valor = this.valor.split('/');
    this.monthTypes = months;
    /* this.initOptions(); */
    this.selectOption(this.selectedOption);
    this.getDestinies();
    this.subscribeInput();
    this.partner = this.sessionService.getPartnerClub();
    if (this.partner === null) {
      this.partner = false;
    }
    this.leerURL();
  }

  ngAfterViewInit() {
    /*  let locat = window.location.hash;
     if (locat === 'vuelos?airline=sky') {
       const targetElement = this.elementRef.nativeElement.querySelector('#myDive');
       targetElement.scrollIntoView({ behavior: 'smooth' });
     } */
  }

  @HostListener('click', ['$event'])
  clickInside(event: MouseEvent) {
    const miDiv = document.getElementById('miDiv');

    // Verificar si el clic no ocurrió dentro del div
    if (miDiv && !(miDiv as HTMLElement).contains(event.target as HTMLElement)) {
      this.showList = false;
    }
  }

  // @HostListener('document:click')
  // clickout() {
  //   if (!this.showList) {
  //     this.showList = false;
  //   }
  //   this.showList = false;
  // }

 
 

  leerURL() {
    let locat = window.location.pathname;
    if (locat === '/hoteles' || locat === '/hotel') {
      this.valid = true;
      this.selectOption(4);

    } else if (locat === '/vuelos') {
      /* this.selectModule.emit('Todo'); */
      this.valid = true;
      this.selectOption(3);
    } else if( locat === '/paquetes'){
      this.valid = true;
      this.selectOption(1);
    }else if(locat === "/vuelo-hotel" || locat === "/seguros"){
      this.valid = true;
      this.selectModule.emit('Todo');
    }else if (locat === "/"){
      this.valid = true;
      this.selectOption(6);
    }/* else {
      this.valid = false;
      this.selectOption(6);
    } */
    /* else if (locat === '#/vuelo-hotel'){
      this.selectOption(2);
    }else if (locat ===  '#/paquetes'){
      this.selectOption(1);
    }else if (locat === '#/seguros'){
      this.selectOption(5);
    } */
  }

  searchPack(){
    if(this.searching === "categoria"){

      this.validcategoria = true;
      this.validdest = false;

    }else if( this.searching === "destino"){
      this.validcategoria = false;
      this.validdest = true;
    }else {
      this.validcategoria = false;
      this.validdest = false;
    }
  }


  initOptions() {
    this.selectedOption = 3;

  }

  selectOption(option) {
    this.selectedOption = option;
    this.partner = this.sessionService.getPartnerClub();
    this.onToogleChange("RT");
    switch (option) {
      case 1:

        
        this.validVuelo = false;
        this.validPaquetes = true;
        this.validVH = false;
        this.validSeguros = false;
        this.validHotel = false;
        this.buscar.emit(false);
        this.selectModule.emit('Paquete');
        // this.selectModule.emit('Paquetes');
        if( this.valid){
        this.location.go("/paquetes");
        }
        /* if(this.locat === '#/hotel'){
        this.router.navigate(["/paquetes"]);
        } */
        // this.router.navigateByUrl("/paquetes");
        break;
      case 2:

        
        this.validVuelo = false;
        this.validPaquetes = false;
        this.validVH = true;
        this.validSeguros = false;
        this.validHotel = false;
        this.buscar.emit(false);
        this.selectModule.emit('Todo');
        // this.selectModule.emit('Vuelos-Hotel');
        if(this.valid){
        this.location.go("/vuelo-hotel");
        }
        
        /* if(this.locat === '#/hotel'){
        this.router.navigate(["/vuelo-hotel"]);
        } */
        // this.router.navigateByUrl("/vuelo-hotel");
        /*  this.showModal('3');
         this.selectedOption = 4; */
        break;
      case 3:

      
      this.validVuelo = true;
        this.validPaquetes = false;
        this.validVH = false;
        this.validSeguros = false;
        this.validHotel = false;
        this.buscar.emit(false);
        this.selectModule.emit('Vuelos');
        
        if(this.valid){
        this.location.go("/vuelos");
      }
        /* if(this.locat === '#/hotel'){
        this.router.navigate(["/vuelos"]);
       } */
        // this.router.navigateByUrl("/vuelos");
        // this.router.navigate(["/vuelos"]);
        // if (!this.origins || this.origins.length === 0) {
        //   this.getPriorityAirports();
        // }
        // this.showModal('2');
        // this.selectedOption = 1;
        break;
      case 4:

        this.validVuelo = false;
        this.validPaquetes = false;
        this.validVH = false;
        this.validSeguros = false;
        this.validHotel = true;
        this.buscar.emit(false);
        this.selectModule.emit('Hoteles');
        if(this.valid){
        this.location.go("/hotel");
        }
        /* this.router.navigate(["/hotel"]); */
          // this.router.navigateByUrl('/hotel');
        /* this.showModal("3"); */
        // this.selectedOption = 1;
        break;
      case 5:

        
        this.validVuelo = false;
        this.validPaquetes = false;
        this.validVH = false;
        this.validSeguros = true;
        this.validHotel = false;
        this.buscar.emit(false);
        this.selectModule.emit('Todo');
        if(this.valid){
        this.location.go("/seguros");
        }
        
        /* if(this.locat === '#/hotel'){
        this.router.navigate(["/seguros"]);
        } */
        // this.router.navigateByUrl("/seguros");
        //  this.router.navigate(["/seguros"]);
        /*  this.router.navigate(["charters"]); */
        // this.selectedOption = 1;
        break;
      case 6:

        
        this.validVuelo = true;
        this.validPaquetes = false;
        this.validVH = false;
        this.validSeguros = false;
        this.validHotel = false;
        this.buscar.emit(false);
        this.selectModule.emit('Home');
        
        /* if(this.locat === '#/hotel'){
        this.router.navigate([""]);
       } */

        break;
    }
  }

  validPhone() {
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
      this.textImg = "buscarPhone";
    }
  }

  subscribeInput() {
    this.myControl.valueChanges.subscribe((term) => {
      if (term.length >= 1) {
        if (this.destinies) {
          this.resultSearch = this.destinies.filter(
            (item) => item.name.toLowerCase().indexOf(term.toLowerCase()) === 0
          );
        }
      } else {
        this.resultSearch = [];
        if (term.length === 0) {
          this.resultSearch = this.destinies;
        }
      }
    });

    this.monthsData = [
      {
        code: "",
        name: "¿Cuándo?",
      },
    ];
  }

  getNames(lst_ : any[]){
    lst_.forEach(element => {
      if(element.name != null){
      this.lstName.push(element.name)
      }
    });

    this.filteredItems = this.lstName.filter(item => {
      return item.toLowerCase().includes(this.filterValue.toLowerCase());
    });
    
    /* this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      ); */
  }


  selectItem(item) {
    this.selectedValue = item;
    this.filterValue = item; // Para mostrar el valor seleccionado en el input
    this.showList = false; // Ocultar la lista después de seleccionar un elemento
  }

  selectItem2(item) {
    this.selectedValue = item;
    this.filterValue2 = item;
    this.showList = false; // Ocultar la lista después de seleccionar un elemento
  }

  getDestinies() {
    this.packageService.getDestinies().subscribe(
      (x) => {
        if (x.confirmation) {
          this.destinies = x.data.filter(item => !this.tbanks.includes(item.name));
          this.viewFilter = true;
          this.getNames(this.destinies);

        }
        
      },
      (err) => {
        //console.log("Error:" + err.message);
      },
      () => {
        this.getAutocompleateList();
      }
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.lstName.filter(option => option.toLowerCase().includes(filterValue));
  }

  getAutocompleateList() {
    this.packageService.getAutocompleteList().subscribe(
      (x) => {
        if (x.confirmation) {
          this.autocompleateList = x.data;
        }
      },
      (err) => {
        /* //console.log('error: ' + err); */
      },
      () => { }
    );
  }

  fullscreenSearch(){
    this.viewFullScream = true;
  }

  fullscreenClose(){
    this.viewFullScream = false;
  }

  onFocus() {
    this.filtervh = this.sessionService.getFilterVH();
    if(this.filtervh != null && !this.validFilter){
      this.validFilter = true;
      const combined = [...this.destinies, ...this.filtervh];
      this.destinies = combined.filter((item, index, self) =>
        index === self.findIndex((t) => t.name.toUpperCase() === item.name.toUpperCase())
      );
    }
    this.resultSearch = this.destinies;
  }

  search() {
    let code = this.selectVH != undefined && this.selectVH != null ? this.selectVH.id : "";
    if (this.destiny && this.destiny.id) {
      const filter: PackageFilter = {
        location: this.destiny,
        date: this.monthControl.value,
        months: this.arrayMonths,
        iataCode: code
      };
      this.sessionService.setHomeFilter(filter);
      this.sessionService.setPromotionalCode(false);
      this.router.navigate(['pack/result']);
    } else {
      this.validDestino = true;
    }
  }

  onInputClick() {
    this.showList = true;
  }

  isClickInsideComponent(event: any): boolean {
    const element = event.target as HTMLElement;
    return element.closest('.input-container') !== null;
  }

  onOptionSelected2(selectedOption) {
    const item = selectedOption;
    let dat;
    if (this.destinies != null) {
      dat = this.destinies.find((x) => x.name === item);
      if (dat) {
        this.destiny = dat;
        }else{
          this.destiny = item
        }
        this.selectedDestiny = true;
        this.packageService.getMonths(this.destiny.id, this.destiny.type).subscribe(
          (x) => {
            if (x.confirmation) {
              this.arrayMonths = x.data;
            } else {
              this.arrayMonths = [];
            }
          },
          (err) => {
            /* //console.log(); */
          },
          () => {
            if (this.arrayMonths && this.arrayMonths.length > 0) {
              this.arrayMonths.unshift(0);
              this.monthsData = this.monthTypes.filter((task) =>
                this.arrayMonths.includes(task.code)
              );
            }
            this.monthControl.setValue(0);
          }
        );
        this.validDestino = false;
      
    }
  }

  onOptionSelected3(event: MatAutocompleteSelectedEvent) {
    const item = event.option.value as ResultSearch;
    this.selectedDestiny = true;
    this.destiny = item;
    this.selectVH = this.filtervh.find(x => x.name.toUpperCase() === item.name.toUpperCase());
    this.packageService.getMonths(item.id, item.type).subscribe(
      (x) => {
        if (x.confirmation) {
          this.arrayMonths = x.data;
        } else {
          this.arrayMonths = [0];
        }
      },
      (err) => {
        /* //console.log(); */
      },
      () => {
        if (this.arrayMonths && this.arrayMonths.length > 0) {
          this.arrayMonths.unshift(0);
          this.monthsData = this.monthTypes.filter((task) =>
            this.arrayMonths.includes(task.code)
          );
        }
        this.monthControl.setValue(0);
      }
    );
    this.validDestino = false;
  }
  
  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    const item = event.option.value;
    let dat: any;
    if(this.destinies != null){
    dat = this.destinies.find(
      (x) => x.name === item
    )
    let datos1: string = dat.id;
    let datos2: string = dat.type;
    this.selectedDestiny = true;
    this.destiny = dat;
    this.packageService.getMonths(datos1, datos2).subscribe(
      (x) => {
        if (x.confirmation) {
          this.arrayMonths = x.data;
        } else {
          this.arrayMonths = [];
        }
      },
      (err) => {
        /* //console.log(); */
      },
      () => {
        if (this.arrayMonths && this.arrayMonths.length > 0) {
          this.arrayMonths.unshift(0);
          this.monthsData = this.monthTypes.filter((task) =>
            this.arrayMonths.includes(task.code)
          );
        }
        this.monthControl.setValue(0);
      }
    );
    this.validDestino = false;
  }
  }

  display(item?: ResultSearch): string | undefined {
    return item ? item.name : undefined;
  }

  onShowMonths() {
    this.showMonths = !this.showMonths;
  }

  onSelectMonth(month) {
    this.selectedMonth = month;
    this.showMonths = false;
  }

  get labelMonth() {
    return this.selectedMonth
      ? this.selectedMonth.code !== 0
        ? this.selectedMonth.name
        : "Todos"
      : "¿Cuándo?";
  }

  showModal(type: string) {
    const dialogRef = this.dialog.open(DialogPhoneContactComponent, {
      data: type,
      maxWidth: "95vh",
      maxHeight: "95vh",
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 2000,
    });
  }

  onSearchFlight(filter: FilterFlightWrapper) {
    //console.log("onSearchFlight");
    //console.log("filter: " + JSON.stringify(filter));

    this.sessionService.setFilterFlightWrapper(filter);
    this.headerService.initializeWhatsAppWidget("/flight/flight-result");
    this.router.navigate(["flight/flight-result"]);
  }

  onToogleChange(toogleOpc: string){
    this.filterTop = toogleOpc === "OW" || toogleOpc === "RT" ? "45px" : "-95px";
    this.fitlerSearch = toogleOpc === "OW" || toogleOpc === "RT" ? "0px" : "-140px";
  }

  fillOrigen(valor) {
    let origin;
    let origins = this.airportData;

      origins.forEach(element => {
        if (element.code === valor) {
          origin = element.cityName;
          let qwe = origin.replace(/' '/g, "-");
          this.router.navigate(["flight-hotel/hotel-result", qwe]);
        }
      });

    


  }

  onSearchHotel(filter: any) {
    /*  //console.log('onSearchHotel', filter); */
    //console.log("onSearchHotel");
    //console.log("filter: " + JSON.stringify(filter));
    /* this.fillOrigen(filter.to.code); */
    this.sessionService.setHotelFilter(filter);
    origin = filter.to.cityName;
    let qwe = origin.replace(/' '/g, "-");
    this.headerService.initializeWhatsAppWidget("/flight-hotel/hotel-result");
    this.router.navigate(["flight-hotel/hotel-result", origin]);
  }

  onSearchOnlyHotel(filter: FilterSearch) {
    /*  //console.log('onSearchHotel', filter); */
    //console.log("onSearchHotel");
    //console.log("filter: " + JSON.stringify(filter));
    this.sessionService.setHotelFilter(filter);
    this.headerService.initializeWhatsAppWidget("/hotel/hotel-list")
    this.router.navigate(["hotel/hotel-list"]);
  }
}
