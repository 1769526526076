import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import jwt_decode from "jwt-decode";
import { DialogValidProductComponent } from '../dialog-valid-product.component';
import { SocialLoginComponent } from '../social-login/social-login.component';
import { SessionService } from 'src/app/core/services/session.service';
declare var google: any;
@Component({
  selector: 'app-exists-google',
  templateUrl: './exists-google.component.html',
  styleUrls: ['./exists-google.component.css']
})

export class ExistsGoogleComponent implements OnInit {
  datos;
  objeto;
  widthgoogle = 440;
  constructor(private router: Router, public dialog: MatDialog,private session: SessionService
    , public dialogRef: MatDialogRef<ExistsGoogleComponent>, @Inject(MAT_DIALOG_DATA) public data: string) { dialogRef.disableClose = true; }

  ngOnInit() {
    this.validPhone();
    this.initialgoogle();
  }

  openDialog() {

    const dialogRef = this.dialog.open(DialogValidProductComponent, {
      data: '',
      disableClose: false,
      panelClass: 'custom-dialog-container',
      maxWidth: 800
    });

    dialogRef.afterClosed().subscribe(result => {
      /* console.log('The dialog was closed'); */
      if (result) {

      }
    });


  }

  validPhone() {
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
      this.widthgoogle = 280;
    }
  }

  initialgoogle() {
    google.accounts.id.initialize({
      client_id: "536885259092-r3vfm0r10kj052kmmp56om86nhln0040.apps.googleusercontent.com",
      callback: this.handleCredentialResponse
    });
    google.accounts.id.renderButton(
      document.getElementById("buttonDive"),
      { logo_alignment: "center", text: "signup_with", size: "medium", scope: "profile email", width: this.widthgoogle }    // customization attributes
    );
  }

  onClose() {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(DialogValidProductComponent, {
      data: {
        value: '',
        sesion: false,
      },
      disableClose: true,

      panelClass: 'custom-dialog-containers',
      maxWidth: 800
    });
  }

  handleCredentialResponse(response) {

    var decoded = jwt_decode(response.credential);


    this.datos = decoded;
    this.objeto = {
      userId: "",
      nombre: this.datos.given_name,
      apellido: this.datos.family_name,
      email: this.datos.email,
      phone: "",
      genero: "",
      countryCode: "",
      documentNumber : "",
      typeDocument: "",
      birthDate: ""
    }
    
    let codigo = '?' + response.credential;
    let valor = window.location.href + "singIn" + codigo;
    window.location.replace(valor);
    /* this.registerGoogle(data); */

  }

}