import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { FilterFlightWrapper } from "../shared/filter-flight-wrapper.model";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  Validators,
} from "@angular/forms";
import { FlightService } from "../../core/services/flight.service";
import { Select } from "src/app/shared/models/select.model";
import { FilterPassenger } from "src/app/shared/models/filter-passenger.model";
import {
  MatDatepickerInputEvent,
  MatDatepicker,
} from "@angular/material/datepicker";
import { SessionService } from "src/app/core/services/session.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { HeaderService } from "src/app/shared/services/head.service";
import { airportsData } from "src/app/shared/services/airport.const";
import { formatDate } from "@angular/common";
import { BsDatepickerInlineConfig, BsDaterangepickerInlineConfig, BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { MatButtonToggleChange } from "@angular/material";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "dm-flight-filter-search",
  templateUrl: "./flight-filter-search.component.html",
  styleUrls: ["./flight-filter-search.component.css"],
  providers: [FlightService, SessionService, HeaderService, BsDaterangepickerInlineConfig, BsDatepickerInlineConfig, BsDatepickerConfig ],
})
export class FlightFilterSearchComponent implements OnInit {
  @Input() data: FilterFlightWrapper;
  @Input() expandable: boolean;
  @Input() color: string;
  @Output() search = new EventEmitter<FilterFlightWrapper>();
  @Output() changeToogle = new EventEmitter<string>();


  @Input() inputData: string;

  @ViewChild("dpEndDeparture", { read: undefined, static: false })
  endDatePicker: MatDatepicker<Date>;
  datar: string;
  form: FormGroup;
  cabinClass: Select[] = [];
  flightTypes: Select[] = [];
  origins = [];
  originList = [];
  destinationList = [];
  originControl = new FormControl("");
  destinationControl = new FormControl("");
  expanded: boolean;
  sectionsForm: FormArray;
  sectionsList = [];
  minBeginDate = new Date();
  minCalendar = new Date();
  minEndDate = new Date();
  passengers: FilterPassenger[];
  cabin = "B";
  message = "";
  passengerList: FilterPassenger[];
  textImg = "newSearch";
  TYPE_ONE_WAY = FilterFlightWrapper.TYPE_ONE_WAY;
  TYPE_ROUND_TRIP = FilterFlightWrapper.TYPE_ROUND_TRIP;
  TYPE_MULTI = FilterFlightWrapper.TYPE_MULTI;
  LIMIT_SECTIONS = 6;
  cargar: boolean;
  from: string;
  to: string;
  valPackage;
  keyword = 'name';
  validOrigen = false;
  validDestino = false;
  validFechas = false;
  textOrigen = "";
  textDestino = "";
  textFechas = "";
  valordoc: any;
  totalPass = 1;
  cabina = "E";
  dateFrom: Date;
  dateTo: Date;
  descCabin = "Todas";
  datos = {
    origin: null,
    destiny: null,
    numberPassenger: null,
    typePassenger: null,
    includesBaggage: null,
    cabinType: null,
    startDate: null,
    endDate: null,
    type: null
  }
  tipoVuelo;
  tipoCabina;
  guardarForm: any;
  enviardata;
  mostrarFullScreen = false;
  viewFullScream = false;
  mostrarAutoComplete = false;
  mostrarAutoCompleteDes = false;
  filtroCollapse = true;
  mostrarCalendarRange = false;
  mostrarCalendar = false;

  indexMulti: any;
  mostrarAutoCompleteMultiOri = false;
  mostrarAutoCompleteMultiDes = false;

  originAnterior: any;
  destAnterior: any;
  originAnteriorMulti: any;
  destAnteriorMulti: any;
  originAnteriorMulti1: any;
  destAnteriorMulti1: any;
  originAnteriorMulti2: any;
  destAnteriorMulti2: any;
  originAnteriorMulti3: any;
  destAnteriorMulti3: any;
  originAnteriorMulti4: any;
  destAnteriorMulti4: any;
  originAnteriorMulti5: any;
  destAnteriorMulti5: any;
  dataAirport: any;
  isPhone = false;
  activeSpinner = false;
  fechaida: any;
  fechavuelta: any;
  fechaidaSelect: any;
  fechavueltaSelect: any;
  openRangePicker: boolean = false;
  bsInvalidDate: Date = new Date();
  isLimit: boolean = true;
  @ViewChild('datePickerInput', { static: false }) datePickerInput: ElementRef;
  
  public myObject!: { id: number, myObject: { myString: string } };
  constructor(
    private formBuilder: FormBuilder,
    private flightService: FlightService,
    private headService: HeaderService,
    private sessionService: SessionService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.fechaida = null;
    this.fechavuelta = null;
    this.dataAirport = airportsData;
  }
  // igualarAeropuertos(data: any){
  //   const _origins = [];
  //   const airports = data.lairports ? data.lairports : [];
  //   const cities = data.lcities ? data.lcities : [];
  //   data.lairports.forEach((airport) => {
  //     _origins.push({
  //       code: airport.iataCode,
  //       countryCode: airport.countryCode,
  //       name: airport.name,
  //       searchName: airport.searchName,
  //       priority: airport.priority,
  //       cityName: airport.cityName,
  //       /* icon: 'local_airport' */
  //       icon: "A",
  //     });
  //   });
  //   data.lcities.forEach((city) => {
  //     _origins.push({
  //       code: city.iataCode,
  //       countryCode: city.countryCode,
  //       name: city.name,
  //       searchName: city.searchName,
  //       priority: city.priority,
  //       cityName: city.cityName,
  //       /* icon: 'location_city' */
  //       icon: "C",
  //     });
  //   });
  //   this.origins = _origins;
  // }

  showFilter(valor) {
    if (!this.isPhone) {
      return;
    }
    if (valor === 1) {
      this.originAnterior = this.form.controls.origin.value;
      this.mostrarAutoComplete = true;
    } else {
      this.destAnterior = this.form.controls.destination.value;
      this.mostrarAutoCompleteDes = true;
    }
  }

  guardarOrMul(index, valor) {
    switch (index) {
      case 0:
        this.originAnteriorMulti = valor;
        break;
      case 1:
        this.originAnteriorMulti1 = valor;
        break;
      case 2:
        this.originAnteriorMulti2 = valor;
        break;
      case 3:
        this.originAnteriorMulti3 = valor;
        break;
      case 4:
        this.originAnteriorMulti4 = valor;
        break;
      case 5:
        this.originAnteriorMulti5 = valor;
        break;
    }
  }

  guardarDesMul(index, valor) {
    switch (index) {
      case 0:
        this.destAnteriorMulti = valor;
        break;
      case 1:
        this.destAnteriorMulti1 = valor;
        break;
      case 2:
        this.destAnteriorMulti2 = valor;
        break;
      case 3:
        this.destAnteriorMulti3 = valor;
        break;
      case 4:
        this.destAnteriorMulti4 = valor;
        break;
      case 5:
        this.destAnteriorMulti5 = valor;
        break;
    }
  }

  guardarDesMulAnt(index) {
    switch (index) {
      case 0:
        (this.sectionsForm.controls[index] as FormGroup).controls.destination.setValue(this.destAnteriorMulti); 
        break;
      case 1:
        (this.sectionsForm.controls[index] as FormGroup).controls.destination.setValue(this.destAnteriorMulti1);
        break;
      case 2:
        (this.sectionsForm.controls[index] as FormGroup).controls.destination.setValue(this.destAnteriorMulti2);
        break;
      case 3:
        (this.sectionsForm.controls[index] as FormGroup).controls.destination.setValue(this.destAnteriorMulti3);
        break;
      case 4:
        (this.sectionsForm.controls[index] as FormGroup).controls.destination.setValue(this.destAnteriorMulti4);
        break;
      case 5:
        (this.sectionsForm.controls[index] as FormGroup).controls.destination.setValue(this.destAnteriorMulti5);
        break;
    }
  }

  guardarOrMulAnt(index) {
    switch (index) {
      case 0:
        (this.sectionsForm.controls[index] as FormGroup).controls.origin.setValue(this.originAnteriorMulti);
        break;
      case 1:
        (this.sectionsForm.controls[index] as FormGroup).controls.origin.setValue(this.originAnteriorMulti1);
        break;
      case 2:
        (this.sectionsForm.controls[index] as FormGroup).controls.origin.setValue(this.originAnteriorMulti2);
        break;
      case 3:
        (this.sectionsForm.controls[index] as FormGroup).controls.origin.setValue(this.originAnteriorMulti3);
        break;
      case 4:
        (this.sectionsForm.controls[index] as FormGroup).controls.origin.setValue(this.originAnteriorMulti4);
        break;
      case 5:
        (this.sectionsForm.controls[index] as FormGroup).controls.origin.setValue(this.originAnteriorMulti5);
        break;
    }
  }

  ocultarAutocomplete(valor) {
    if (valor === 1) {
      if (this.form.controls.origin.value === null) {
        this.form.controls.origin.setValue(this.originAnterior);
      }

      this.mostrarAutoComplete = false;
    } else {
      if (this.form.controls.destination.value === null) {
        this.form.controls.destination.setValue(this.destAnterior);
      }

      this.mostrarAutoCompleteDes = false;
    }
  }

  forcontrols(){
    for (let index = 0; index < this.sectionsForm.controls.length; index++) {
      const element = (this.sectionsForm.controls[index] as FormGroup);
      this.guardarOrMul(index,element.controls.origin.value);
      this.guardarDesMul(index,element.controls.destination.value);
    }
  }



  showFilterMulti(valor, index) {
    this.forcontrols();
    let i = index;
    this.indexMulti = index;
    if (!this.isPhone) {
      return;
    }
    if (valor === 1) {
      this.mostrarAutoCompleteMultiOri = true;
    } else {
      this.mostrarAutoCompleteMultiDes = true;
    }

  }

  ocultarAutocompleteMulti(valor, index) {
     let i = this.indexMulti;

    if (valor === 1) {
      let origin = (this.sectionsForm.controls[i] as FormGroup).controls.origin.value;
      (this.sectionsForm.controls[i] as FormGroup).controls.origin.setValue(origin);
      if (origin === null) {
        this.guardarOrMulAnt(i);
       /*  (this.sectionsForm.controls[i] as FormGroup).controls.origin.setValue(this.originAnteriorMulti); */
      }

      this.mostrarAutoCompleteMultiOri = false;
    } else {
      let dest = (this.sectionsForm.controls[i] as FormGroup).controls.destination.value;
      (this.sectionsForm.controls[i] as FormGroup).controls.destination.setValue(dest);
      if (dest === null) {
        this.guardarDesMulAnt(i);
      }

      this.mostrarAutoCompleteMultiDes = false;
    }
  }



  ngOnInit() {
    this.validPhone();
    this.cargar = false;
    //console.log(this.data);
    this.minCalendar.setDate(this.minCalendar.getDate() + 1);
    /* //console.log('init', this.expandable, this.data); */
    this.initForm();
    /* this.enviarLim(); */

    let data = this.rideURL();
    this.enviarLim1();
    if (data === null) {

      this.getAirports();


    } else {
     /*  this.cargar = true;
      this.origins = [];

      this.getPriorityAirports1(); */
      this.getAirports1();
    }

  }

  setId() {
    this.valordoc = document.getElementById("nrcabin");
    /*  switch (this.valordoc) {
       case "B":
         this.descCabin = "Business"
         break;
       case "F":
         this.descCabin = "First"
         break;
       case "E":
         this.descCabin = "Económica"
         break;
       case "":
         this.descCabin = "Todas"
         break;
     } */
  }

  openDatePicker() {
   this.openRangePicker = true;
  }

  cancelChanges() {
    this.openRangePicker = false;
    this.fechaida = null;
      this.fechavuelta = null;
      this.fechaidaSelect = null;
        this.fechavueltaSelect = null;
  }

  onFinDateRangeChange(event: MatDatepickerInputEvent<Date>) {
    if (event[0] != undefined) {
        this.fechaidaSelect = event[0];
        this.fechavueltaSelect = event[1];
        this.fechaida = null;
        this.fechavuelta = null;
    }

  }

  onFinDateChange(event){
    if(event != undefined){
      this.fechaidaSelect = event;
      this.fechavueltaSelect = null;
      this.fechaida = null;
      this.fechavuelta = null;
    }
  }

  applyRangeChanges() {
    if(this.fechaidaSelect != null && this.fechavueltaSelect != null){
      this.fechaida = this.formatearFecha2(this.fechaidaSelect,'EEE dd MMM');
      this.fechavuelta = this.formatearFecha2(this.fechavueltaSelect,'EEE dd MMM');
      this.openRangePicker = false;
      this.validFechas = false;
    }
  }

  applyChanges() {
    if(this.fechaidaSelect != null){
      this.fechaida = this.formatearFecha2(this.fechaidaSelect,'EEE dd MMM');
      this.openRangePicker = false;
      this.validFechas = false;
    }
  }

  applyRangeChangesMobile() {
    if(this.fechaidaSelect != null && this.fechavueltaSelect != null){
      this.fechaida = this.formatearFecha2(this.fechaidaSelect,'EEE dd MMM');
      this.fechavuelta = this.formatearFecha2(this.fechavueltaSelect,'EEE dd MMM');
      this.mostrarCalendarRange = false;
      this.validFechas = false;
    }
  }

  applyChangesMobile() {
    if(this.fechaidaSelect != null){
      this.fechaida = this.formatearFecha2(this.fechaidaSelect,'EEE dd MMM');
      this.mostrarCalendar = false;
      this.validFechas = false;
    }
  }

  cancelChangesMobile() {
    this.mostrarCalendarRange = false;
    this.mostrarCalendar = false;
    this.fechaida = null;
    this.fechavuelta = null;
    this.fechaidaSelect = null;
    this.fechavueltaSelect = null;
  }

  formatearFecha2(fecha: any, format: string): string {
    const fechaini = new Date(fecha);
    return formatDate(fechaini, format, 'es-PE', 'America/Lima');
  }

  selectEvent(item) {
    // do something with selected item
  }

  onChangeSearch(val: string) {
    if (val.length >= 3) {
      const resultFilter = this.destinationList.filter(
        (word) => word.searchName.toLowerCase().search(val.toLowerCase()) >= 0
      );
      this.destinationList = resultFilter;


    }
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
  }

  validPhone() {
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
      this.textImg = "buscarPhone";
      this.isPhone = true;
      this.filtroCollapse = false;
    }
  }

  rideURL() {
    let datos;
    let url = window.location.href;
    let manco = url.split("?");


    this.valPackage = manco[0];

    let valor = this.valPackage.split('#');
    let adic = valor[1];

    if (manco.length === 1 || adic === '/filter') {
      return null;
    } else {
      let enviar = manco[1]
      this.sessionService.setURL(enviar);

      return null;
    }
  }

  // getPriorityAirports() {
  //   /* this.spinner.show(); */
  //   this.enviarLim();
  //   this.flightService.getPriorityAirports().subscribe(
  //     (result) => {
  //       /* //console.log(result); */
  //       if (result) {
  //         const airports = result.lairports ? result.lairports : [];
  //         const cities = result.lcities ? result.lcities : [];
  //         airports.forEach((airport) => {
  //           this.origins.push({
  //             code: airport.iataCode,
  //             countryCode: airport.countryCode,
  //             name: airport.name,
  //             searchName: airport.searchName,
  //             priority: airport.priority,
  //             /* icon: 'local_airport' */
  //             icon: "A",
  //           });
  //         });
  //         cities.forEach((city) => {
  //           this.origins.push({
  //             code: city.iataCode,
  //             countryCode: city.countryCode,
  //             name: city.name,
  //             searchName: city.searchName,
  //             priority: city.priority,
  //             /* icon: 'location_city' */
  //             icon: "C",
  //           });
  //         });
  //         /* this.origins.sort((a, b) => b.priority - a.priority);  */
  //       }
  //     },
  //     (err) => {
  //       /*   //console.log('Error: ', err); */
  //     },
  //     () => {

  //       this.getAirports();
  //     }
  //   );
  // }

  filtroOcultarMostrar() {
    let filtroCollapse = this.filtroCollapse;
    if (filtroCollapse == true) {
      this.filtroCollapse = false;
    } else {
      this.filtroCollapse = true;
    }
  }

  getAirports() {
    this.enviarLim();
    const _origins = [];
    let result = this.dataAirport;
    if (result) {
      const airports = result.lairports ? result.lairports : [];
      const cities = result.lcities ? result.lcities : [];
      airports.forEach((airport) => {
        _origins.push({
          code: airport.iataCode,
          countryCode: airport.countryCode,
          name: airport.name,
          searchName: airport.searchName,
          priority: airport.priority,
          cityName: airport.cityName,
          /* icon: 'local_airport' */
          icon: "A",
        });
      });
      cities.forEach((city) => {
        _origins.push({
          code: city.iataCode,
          countryCode: city.countryCode,
          name: city.name,
          searchName: city.searchName,
          priority: city.priority,
          cityName: city.cityName,
          /* icon: 'location_city' */
          icon: "C",
        });
      });
      /* _origins.sort((a, b) => b.priority - a.priority); */
    }

    this.origins = _origins;
        this.sessionService.clearSession();
        let partner = this.sessionService.getPartnerClub();
        if (partner === null) {
          this.sessionService.setPartnerClub(false);
        }
        /* this.sessionService.setFilterFlightWrapper(this.enviardata); */
        /* let valor = this.constant.encriptar(this.origins);
        this.constant.addObject(0, valor); */

    // this.flightService.getAirports().subscribe(
    //   (result) => {
    //     if (result) {
    //       const airports = result.lairports ? result.lairports : [];
    //       const cities = result.lcities ? result.lcities : [];
    //       airports.forEach((airport) => {
    //         _origins.push({
    //           code: airport.iataCode,
    //           countryCode: airport.countryCode,
    //           name: airport.name,
    //           searchName: airport.searchName,
    //           priority: airport.priority,
    //           cityName: airport.cityName,
    //           /* icon: 'local_airport' */
    //           icon: "A",
    //         });
    //       });
    //       cities.forEach((city) => {
    //         _origins.push({
    //           code: city.iataCode,
    //           countryCode: city.countryCode,
    //           name: city.name,
    //           searchName: city.searchName,
    //           priority: city.priority,
    //           cityName: city.cityName,
    //           /* icon: 'location_city' */
    //           icon: "C",
    //         });
    //       });
    //       /* _origins.sort((a, b) => b.priority - a.priority); */
    //     }
    //   },
    //   (err) => {
    //     /*  //console.log('Error: ', err); */
    //   },
    //   () => {
    //     this.origins = _origins;
    //     this.sessionService.clearSession();
    //     let partner = this.sessionService.getPartnerClub();
    //     if (partner === null) {
    //       this.sessionService.setPartnerClub(false);
    //     }
    //     /* this.sessionService.setFilterFlightWrapper(this.enviardata); */
    //   }
    // );
  }

  enviarLim() {
    if(this.expandable){
      return;
    }
    const objeto = {
      cityName: "Lima",
      code: "LIM",
      countryCode: "PE",
      icon: "A",
      name: "[LIM] - Jorge Chavez International - LIM - Lima - Peru",
      priority: 2,
      searchName: "LIM-Lima-Jorge Chavez International-J Chavez Intl-Peru-Perú-Peruu-Perù-Pê ru-Pérou"
    }
    this.form.controls.origin.setValue(objeto);
    /* this.origins.forEach(element => {
      if(element.code === 'LIM'){
        this.form.controls.origin.setValue(element);
      }
    });
    this.spinner.hide(); */
  }

  enviarLim1() {
    if(this.expandable){
      return;
    }
    this.origins.forEach(element => {
      if (element.code === 'LIM') {
        this.form.controls.origin.setValue(element);
        let room = this.form.controls.sections as FormArray;
        let adult = room.controls[0] as FormGroup;
        adult.controls.origin.setValue(element);
      }
    });

  }

  // getPriorityAirports1() {

  //   this.flightService.getPriorityAirports().subscribe(
  //     (result) => {
  //       /* //console.log(result); */
  //       if (result) {
  //         const airports = result.lairports ? result.lairports : [];
  //         const cities = result.lcities ? result.lcities : [];
  //         airports.forEach((airport) => {
  //           this.origins.push({
  //             code: airport.iataCode,
  //             countryCode: airport.countryCode,
  //             name: airport.name,
  //             searchName: airport.searchName,
  //             priority: airport.priority,
  //             /* icon: 'local_airport' */
  //             icon: "A",
  //           });
  //         });
  //         cities.forEach((city) => {
  //           this.origins.push({
  //             code: city.iataCode,
  //             countryCode: city.countryCode,
  //             name: city.name,
  //             searchName: city.searchName,
  //             priority: city.priority,
  //             /* icon: 'location_city' */
  //             icon: "C",
  //           });
  //         });
  //         this.origins.sort((a, b) => b.priority - a.priority);

  //       }
  //     },
  //     (err) => {
  //       /*   //console.log('Error: ', err); */
  //     },
  //     () => {
  //       this.getAirports1();
  //     }
  //   );
  // }

  getAirports1() {
    const _origins = [];
    let result = this.dataAirport;
    if (result) {
      const airports = result.lairports ? result.lairports : [];
      const cities = result.lcities ? result.lcities : [];
      airports.forEach((airport) => {
        _origins.push({
          code: airport.iataCode,
          countryCode: airport.countryCode,
          name: airport.name,
          searchName: airport.searchName,
          priority: airport.priority,
          cityName: airport.cityName,
          /* icon: 'local_airport' */
          icon: "A",
        });
      });
      cities.forEach((city) => {
        _origins.push({
          code: city.iataCode,
          countryCode: city.countryCode,
          name: city.name,
          searchName: city.searchName,
          priority: city.priority,
          cityName: city.cityName,
          /* icon: 'location_city' */
          icon: "C",
        });
      });
      _origins.sort((a, b) => b.priority - a.priority);
      /*  this.sessionService.clearSession(); */
      this.origins = _origins;
      let partner = this.sessionService.getPartnerClub();
      if (partner === null) {
        this.sessionService.setPartnerClub(false);
      }
      this.setFilterData(_origins);
    }

    this.origins = _origins;

    // this.flightService.getAirports().subscribe(
    //   (result) => {
    //     if (result) {
    //       const airports = result.lairports ? result.lairports : [];
    //       const cities = result.lcities ? result.lcities : [];
    //       airports.forEach((airport) => {
    //         _origins.push({
    //           code: airport.iataCode,
    //           countryCode: airport.countryCode,
    //           name: airport.name,
    //           searchName: airport.searchName,
    //           priority: airport.priority,
    //           cityName: airport.cityName,
    //           /* icon: 'local_airport' */
    //           icon: "A",
    //         });
    //       });
    //       cities.forEach((city) => {
    //         _origins.push({
    //           code: city.iataCode,
    //           countryCode: city.countryCode,
    //           name: city.name,
    //           searchName: city.searchName,
    //           priority: city.priority,
    //           cityName: city.cityName,
    //           /* icon: 'location_city' */
    //           icon: "C",
    //         });
    //       });
    //       _origins.sort((a, b) => b.priority - a.priority);
    //       /*  this.sessionService.clearSession(); */
    //       this.origins = _origins;
    //       let partner = this.sessionService.getPartnerClub();
    //       if (partner === null) {
    //         this.sessionService.setPartnerClub(false);
    //       }
    //       this.setFilterData();
    //     }
    //   },
    //   (err) => {
    //     /*  //console.log('Error: ', err); */
    //   },
    //   () => {
    //     this.origins = _origins;

    //   }
    // );
  }

  selectType(event: Event) {
    //console.log("selectType");
    const typeSel = (event.target as HTMLSelectElement).value;
    //console.log("typeSel: " + typeSel);
    this.tipoVuelo = typeSel;
    this.form.controls.type.setValue(typeSel);

  }



  selectCabin(event: Event) {
    //console.log("selectCabin");
    const cabinSel = (event.target as HTMLSelectElement).value;
    //console.log("cabinSel: " + cabinSel);
    this.tipoCabina = cabinSel;
    this.form.controls.cabin.setValue(cabinSel);

  }

  validAirports(data) {
    data.fromFilter.forEach(element => {
      if (element.name === null) {
        let resultado = this.origins.filter(item => item.code === element.code);
        element = resultado[0];
      }
    });
    data.toFilter.forEach(element => {
      if (element.name === null) {
        let resultado = this.origins.filter(item => item.code === element.code);
        element = resultado[0];
      }
    });
    return data;
  }

  initForm() {

    this.cabinClass = this.flightService.getCabinTypes();
    this.flightTypes = this.flightService.getFlightTypes();

    let type = this.TYPE_ROUND_TRIP;
    let origin;
    let destination;
    let beginDate = null;
    let endDate = null;
    let cabinType = "";
    let scales = "";
    let includeBaggage;
    let carry;

    if (this.data) {
      type = this.data.type;
      /* this.data = this.validAirports(this.data); */
      //console.log("type: " + type);
      this.tipoVuelo = type;
      switch (type) {
        case this.TYPE_ROUND_TRIP:
          origin = this.data.fromFilter[0];
          destination = this.data.toFilter[0];
          beginDate = this.data.dateFilter[0];
          endDate = this.data.dateFilter[1];
          break;
        case this.TYPE_ONE_WAY:
          origin = this.data.fromFilter[0];
          destination = this.data.toFilter[0];
          beginDate = this.data.dateFilter[0];
          break;
        case this.TYPE_MULTI:
          break;
      }
      cabinType = this.data.cabinType.id;
      this.tipoCabina = cabinType;
      scales = this.data.scales.id;
      includeBaggage = this.data.includesBaggage;
      carry = this.data.includeCarryOn;
      this.minEndDate = beginDate;
    } else {
      this.tipoVuelo = type;
      origin = {
        code: "LIM",
        countryCode: "PE",
        name: "[LIM] - Jorge Chavez International - LIM - Lima - Peru",
        searchName: "LIM-Lima-Jorge Chavez International-J Chavez Intl-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
        priority: 2,
        cityName: "Lima",
        /* icon: 'local_airport' */
        icon: "A",
      };
      /* this.origins.forEach(element => {
        if (element.code === 'LIM') {
          origin = element;
        }
      }); */
    }

    this.form = this.formBuilder.group({
      type: new FormControl(type),
      origin: new FormControl(origin),
      destination: new FormControl(destination),
      beginDate: new FormControl(beginDate),
      endDate: new FormControl(endDate),
      cabin: new FormControl(cabinType),
      stop: new FormControl(scales),
      baggage: new FormControl(includeBaggage ? includeBaggage : ""),
      carryon: new FormControl(carry ? carry : ""),
      sections: new FormArray([]),
      passengers: new FormArray([]),
    });

    this.fechaidaSelect = beginDate;
    this.fechavueltaSelect = endDate;
    this.fechaida = this.fechaidaSelect != null ? this.formatearFecha2(this.fechaidaSelect,'EEE dd MMM') : null;
    this.fechavuelta = this.fechavueltaSelect != null ? this.formatearFecha2(this.fechavueltaSelect,'EEE dd MMM') : null;

    this.sectionsForm = this.form.get("sections") as FormArray;

    this.initSections();

    this.initPassengers();

    this.initSummary(this.data);
  }

  mostrarPass() {
    this.passengers = this.passengerList;
    this.mostrarFullScreen = true;
    console.log(this.form.controls.cabin)
  }

  viewCalendarRange() {
    this.mostrarCalendarRange = true;
  }

  viewCalendar() {
    this.mostrarCalendar = true;
  }

  toogleChange(toogle: MatButtonToggleChange){
    this.changeToogle.emit(toogle.value);
  }

  fullscreenSearch(){
    this.viewFullScream = true;
  }

  fullscreenClose(){
    this.viewFullScream = false;
  }

  formatearFecha(fecha: string): string {
    const fechaini = new Date(fecha);
    return formatDate(fechaini, 'EEE dd MMM', 'es-PE', 'America/Lima');
  }

  calculatePassenger(passengers: any[]) {
    let numberPass: number = 0;
    passengers.forEach(x => {
      numberPass += parseInt(x.quantity);
    });
    return numberPass.toString();
  }

  lastDateMultidestino(dates: any[]) {
    let fecha: string = "";
    dates.forEach(element => {
      fecha = element;
    });
    return this.formatearFecha(fecha);
  }

  mostrarPass2() {
    this.passengerList = this.passengers;

    this.mostrarFullScreen = false;

  }

  seleccionarTipoVuelo(valor) {
    this.form.controls.type.setValue(valor);
  }

  mostrarPass1() {
    this.mostrarFullScreen = false;
    this.valordoc = document.getElementById("nrcabin");
    this.form.controls.cabin.setValue(this.valordoc.value);
    if (this.data) {
      this.passengers = this.data.lpassenger;
    }
    switch (this.valordoc.value) {
      case "B":
        this.descCabin = "Business"
        break;
      case "F":
        this.descCabin = "First"
        break;
      case "E":
        this.descCabin = "Económica"
        break;
      case "":
        this.descCabin = "Todas"
        break;
    }
  }

  changess() {
    let datos;

    let showdat = this.sessionService.getURL();
    datos = showdat.split("&");
    this.datos.origin = datos[0].split("=");
    this.datos.destiny = datos[1].split("=");
    this.datos.numberPassenger = datos[2].split("=");
    this.datos.typePassenger = datos[3].split("=");
    this.datos.includesBaggage = datos[4].split("=");
    this.datos.cabinType = datos[5].split("=");
    this.datos.startDate = datos[6].split("=");
    this.datos.endDate = datos[7].split("=");
    this.datos.type = datos[8].split("=");
  }

  setFilterData(origenes) {
    this.changess();
    let data = new FilterFlightWrapper();
    let to = [];
    let origin = [];
    let fecha = [];
    let origins = origenes;

      let pasajeros = [];
      data.Ocompany = null;
      let partner = this.sessionService.getPartnerClub();
      data.PartnerClub = partner;
      const cabina = {
        description: null,
        id: null
      }
      const escala = {
        description: "Todos",
        id: ""
      }
      if (this.datos.cabinType[1] === 'T') {
        cabina.description = "Todas";
        cabina.id = "";
        data.cabinType = cabina;
      } else if (this.datos.cabinType[1] === 'E') {
        cabina.description = "Económica";
        cabina.id = "E";
        data.cabinType = cabina;
      } else if (this.datos.cabinType[1] === 'B') {
        cabina.description = "Business";
        cabina.id = "B";
        data.cabinType = cabina;
      } else {
        cabina.description = "First";
        cabina.id = "F";
        data.cabinType = cabina;
      }
      fecha.push(this.datos.startDate[1]);
      if (this.datos.endDate != null) {
        fecha.push(this.datos.endDate[1]);
      }

      data.dateFilter = fecha;
      if (this.datos.includesBaggage[1] === 'false') {
        data.includesBaggage = false;
      } else {
        data.includesBaggage = true;
      }

      const pasajero = {
        numberPassenger: this.datos.numberPassenger[1],
        typePassenger: "ADT"
      }
      const pasajero1 = {
        numberPassenger: this.datos.numberPassenger[1],
        typePassenger: "CHD"
      }
      const pasajero2 = {
        numberPassenger: this.datos.numberPassenger[1],
        typePassenger: "INF"
      }
      const pasajero3 = {
        numberPassenger: "0",
        typePassenger: "ADT"
      }
      const pasajero4 = {
        numberPassenger: "0",
        typePassenger: "CHD"
      }
      const pasajero5 = {
        numberPassenger: "0",
        typePassenger: "INF"
      }
      if (this.datos.typePassenger[1] === 'ADT') {
        pasajeros.push(pasajero);
        pasajeros.push(pasajero4);
        pasajeros.push(pasajero5);
      } else if (this.datos.typePassenger[1] === 'CHD') {
        pasajeros.push(pasajero1);
        pasajeros.push(pasajero3);
        pasajeros.push(pasajero5);
      } else {
        pasajeros.push(pasajero2);
        pasajeros.push(pasajero4);
        pasajeros.push(pasajero3);
      }

      data.lpassenger = pasajeros;
      data.scales = escala;
      data.type = this.datos.type[1];
      origins.forEach(element => {
        if (this.datos.origin[1] === element.code) {
          this.datos.origin[1] = element;
        }
        if (this.datos.destiny[1] === element.code) {
          this.datos.destiny[1] = element;
        }
      });
      origin.push(this.datos.origin[1]);
      if (data.type === 'RT') {
        origin.push(this.datos.destiny[1]);
      }


      data.fromFilter = origin;
      to.push(this.datos.destiny[1]);
      if (data.type === 'RT') {
        to.push(this.datos.origin[1]);
      }


      data.toFilter = to;
      this.enviardata = data;
      this.sessionService.setFilterFlightWrapper(data);
      this.cargar = false;
      this.sessionService.setRecomendations(null);
      this.router.navigate(['flight/flight-result']);
    
  }

  initSummary(data: FilterFlightWrapper) {
    if (data && this.expandable) {
      switch (data.type) {
        case this.TYPE_ROUND_TRIP:
          this.from = data.fromFilter[0].code;
          this.to = data.toFilter[0].code;
          this.dateFrom = data.dateFilter[0];
          this.dateTo = data.dateFilter[1];
          break;
        case this.TYPE_ONE_WAY:
          this.from = data.fromFilter[0].code;
          this.to = data.toFilter[0].code;
          this.dateFrom = data.dateFilter[0];
          break;
        case this.TYPE_MULTI:
          this.from = data.fromFilter[0].code;
          this.to = data.toFilter[data.toFilter.length - 1].code;
          this.dateFrom = data.dateFilter[0];
          this.dateTo = data.dateFilter[data.dateFilter.length - 1];
          break;
      }
    }
  }

  initSections() {
    if (this.data && this.data.type === this.TYPE_MULTI) {
      this.data.fromFilter.forEach((from, index) => {
        this.addSection(
          from,
          this.data.toFilter[index],
          this.data.dateFilter[index]
        );
      });
    } else {
      this.addSection();
      this.addSection();
    }
  }

  addSection(from?, to?, date?) {
    let index = this.sectionsForm.length;

    if (index < this.LIMIT_SECTIONS) {
      this.sectionsForm.push(
        new FormGroup({
          origin: new FormControl(from ? from : ""),
          destination: new FormControl(to ? to : ""),
          date: new FormControl(date ? date : ""),
        })
      );

      let _minDate = new Date();
      _minDate.setDate(_minDate.getDate() + 1);
      let previousDate;
      if (index > 0) {
        previousDate = this.sectionsForm.controls[index - 1].value.date;
        if (previousDate && previousDate !== "") {
          _minDate = previousDate;
        }
      }

      this.sectionsList.push({
        originList: [],
        destinationList: [],
        minDate: _minDate,
      });

      if (!from && index > 0) {
        (
          this.sectionsForm.controls[index] as FormGroup
        ).controls.origin.setValue(
          this.sectionsForm.controls[index - 1].value.destination
        );
      }
      this.isLimit = index + 1 === this.LIMIT_SECTIONS ? false : true;
    }
  }

  initPassengers() {
    if (this.data) {
      this.passengers = this.data.lpassenger;
    }
  }

  letPeru(list) {
    let lsita = [];
    list.forEach(element => {
      if (element.countryCode === "PE") {
        lsita.push(element);
      }
    });
    return lsita;
  }

  diference() {
    const stateGroups: any[] = [
      {
        letter: 'Aeropuertos',
        names: [],
      },
      {
        letter: 'Ciudades',
        names: [],
      }]
    this.originList.forEach(element => {
      if (element.icon === "A") {
        stateGroups[0].names.push(element.name)
      } else {
        stateGroups[1].names.push(element.name)
      }
    });

    return stateGroups;
  }

  onOriginChange(val: string) {
    if (val === null || val === undefined) {
      return;
    }
    const lstPeru = this.letPeru(this.origins);
    if (val.length >= 3 && lstPeru) {
      this.originList = lstPeru.filter(
        (item) => item.searchName.toLowerCase().search(val.toLowerCase()) >= 0
      );
    } else {
      this.originList = [];
    }

    this.validOrigen = false;
  }

  onOriginListChange(val: string, index: number) {
    if (val === null || val === undefined) {
      return;
    }
    if (this.indexMulti === 0) {
      const lstPeru = this.letPeru(this.origins);
      if (val.length >= 3 && lstPeru) {
        this.sectionsList[index].originList = lstPeru.filter(
          (item) => item.searchName.toLowerCase().search(val.toLowerCase()) >= 0
        );
      } else {
        this.sectionsList[index].originList = [];
      }
    } else {
      if (val.length >= 3 && this.origins) {
        this.sectionsList[index].originList = this.origins.filter(
          (item) => item.searchName.toLowerCase().search(val.toLowerCase()) >= 0
        );
      } else {
        this.sectionsList[index].originList = [];
      }
    }

  }

  onDestinationChange(val: string) {
    if (val === null || val === undefined) {
      return;
    }
    if (val.length >= 3 && this.origins) {
      this.destinationList = this.origins.filter(
        (item) => item.searchName.toLowerCase().search(val.toLowerCase()) >= 0
      );
    } else {
      this.destinationList = [];
    }
    this.destinationList = this.ordenarHoteles(this.destinationList);
    this.validDestino = false;
  }

  ordenarHoteles(list) {
    /* list.sort((a, b) => b.priority - a.priority); */
    let newList = [];
    list.forEach(element => {
      if (element.icon === 'A' && newList.length <= 4) {
        newList.push(element);
      }
    });

    list.forEach(element => {
      if (element.icon === 'C' && newList.length <= 9) {
        newList.push(element);
      }
    });

    /*  newList.sort((a, b) => a.priority - b.priority); */
    return newList;
  }



  onDestinationListChange(val: string, index: number) {
    if (val === null || val === undefined) {
      return;
    }
    if (val.length >= 3 && this.origins) {
      this.sectionsList[index].destinationList = this.origins.filter(
        (item) => item.searchName.toLowerCase().search(val.toLowerCase()) >= 0
      );
    } else {
      this.sectionsList[index].destinationList = [];
    }
  }

  addEvent(event, index: number) {
    if(event != undefined){
      this.sectionsList.forEach((element, i) => {
        if (i > index) {
          if (
            this.sectionsForm.controls[i].value.date &&
            new Date(this.sectionsForm.controls[i].value.date) <
            new Date(this.sectionsForm.controls[index].value.date)
          ) {
            (this.sectionsForm.controls[i] as FormGroup).controls.date.setValue(
              this.sectionsForm.controls[index].value.date
            );
          }
          element.minDate = event;
        }
      });
    }
  }

  remove(index: number) {
    this.sectionsForm.removeAt(index);
    this.isLimit = true;
  }

  selection(passengers: FilterPassenger[]) {
    this.passengerList = passengers;
  }

  total(valor: any) {
    this.totalPass = valor;
  }

  cambiarsitio() {
    let to = this.form.controls.origin.value;
    let from = this.form.controls.destination.value;

    if (to != null && to != '' && from != null && from != '') {
      this.form.controls.origin.setValue(from);
      this.form.controls.destination.setValue(to);
    }
  }

  getPassengers(): number {
    let passengers = 0;
    if (this.data && this.data.lpassenger) {
      this.data.lpassenger.forEach((element) => {
        passengers += Number(element.quantity);
      });
    }
    return passengers;
  }

  onInputFocus() {
    this.validFechas = false;
    // Aquí puedes realizar las acciones que desees cuando el campo de entrada obtenga el enfoque.
  }

  onInputBlur() {
    /*  console.log('El campo de entrada ha perdido el enfoque.'); */
    // Aquí puedes realizar las acciones que desees cuando el campo de entrada pierda el enfoque.
  }

  searching() {
    this.sessionService.setRecomendations(null);
    let valid: boolean = true;

    switch (this.form.controls.type.value) {
      case this.TYPE_ROUND_TRIP:
        if (!this.fechaidaSelect || !this.fechavueltaSelect) {
          valid = false;
          this.validFechas = true;
          this.message = "Fechas incompletas";
          this.textFechas = "Fechas incompletas";
        }
        if (!this.form.controls.origin.value) {
          valid = false;
          this.validOrigen = true;
          this.message = "Origen debe ser seleccionado";
          this.textOrigen = "Origen debe ser seleccionado";
        } else if (!this.form.controls.origin.value.name) {
          valid = false;
          this.validOrigen = true;
          this.message = "Origen debe ser seleccionado";
          this.textOrigen = "Origen debe ser seleccionado";
        }
        if (!this.form.controls.destination.value) {
          valid = false;
          this.validDestino = true;
          this.message = "Destino debe ser seleccionado";
          this.textDestino = "Destino debe ser seleccionado";
        } else if (!this.form.controls.destination.value.name) {
          valid = false;
          this.validDestino = true;
          this.message = "Destino debe ser seleccionado";
          this.textDestino = "Destino debe ser seleccionado";
        }
        if (
          valid &&
          this.form.controls.origin.value.code ===
          this.form.controls.destination.value.code
        ) {
          valid = false;
          this.validDestino = true;
          this.validOrigen = true;
          this.message = "Origen y Destino no pueden ser iguales";
          this.textDestino = "Origen y Destino no pueden ser iguales";
          this.textOrigen = "Origen y Destino no pueden ser iguales";
        }

        break;
      case this.TYPE_ONE_WAY:
        if (!this.fechaidaSelect) {
          valid = false;
          this.validFechas = true;
          this.message = "Fechas incompletas";
          this.textFechas = "Fechas incompletas";
        }
        if (!this.form.controls.origin.value) {
          valid = false;
          this.validOrigen = true;
          this.message = "Origen debe ser seleccionado";
          this.textOrigen = "Origen debe ser seleccionado";
        } else if (!this.form.controls.origin.value.name) {
          valid = false;
          this.validOrigen = true;
          this.message = "Origen debe ser seleccionado";
          this.textOrigen = "Origen debe ser seleccionado";
        }
        if (!this.form.controls.destination.value) {
          valid = false;
          this.validDestino = true;
          this.message = "Destino debe ser seleccionado";
          this.textDestino = "Destino debe ser seleccionado";
        } else if (!this.form.controls.destination.value.name) {
          valid = false;
          this.validDestino = true;
          this.message = "Destino debe ser seleccionado";
          this.textDestino = "Destino debe ser seleccionado";
        }
        if (
          valid &&
          this.form.controls.origin.value.code ===
          this.form.controls.destination.value.code
        ) {
          valid = false;
          this.validDestino = true;
          this.validOrigen = true;
          this.message = "Origen y Destino no pueden ser iguales";
          this.textDestino = "Origen y Destino no pueden ser iguales";
          this.textOrigen = "Origen y Destino no pueden ser iguales";
        }

        break;
      case this.TYPE_MULTI:
        this.sectionsForm.controls.forEach((element) => {
          if (
            !element.value.origin ||
            !element.value.destination ||
            !element.value.date
          ) {
            valid = false;
            this.validDestino = true;
            this.textDestino = "Destino debe ser seleccionado";
            this.message = "Campos incompletos";
            return;
          }

          if (
            valid &&
            element.value.origin.code === element.value.destination.code
          ) {
            valid = false;
            this.validDestino = true;
            this.validOrigen = true;
            this.message = "Origen y Destino no pueden ser iguales";
            this.textDestino = "Origen y Destino no pueden ser iguales";
            this.textOrigen = "Origen y Destino no pueden ser iguales";
            return;
          }
        });
        break;
    }

    if (valid) {
      this.spinner.show();
      let company;
      let partner;
      const data = this.sessionService.getLoginData();
      if (data != null) {
        company = data.ouserLogin.ocompany;
        partner = true;
      } else {
        company = null;
        partner = false;
      }
      let validdd = this.sessionService.getIniciarSesion();
      let obj = {
        UserID: null,
        Email: null,
        FirstName: null,
        LastName: null
      }
      if (validdd === false) {
        let datass = this.sessionService.getEmailFinish();
        obj = {
          UserID: datass.userId,
          Email: datass.email,
          FirstName: datass.nombre,
          LastName: datass.apellido
        }
      } else {
        obj = null
      }
      partner = this.sessionService.getPartnerClub();
      let filter = new FilterFlightWrapper();
      filter.Ocompany = company;
      filter.Ouser = obj;
      filter.PartnerClub = partner;
      filter.type = this.form.controls.type.value;
      filter.lpassenger = this.passengerList;
      const cabin = this.cabinClass.find(
        (x) => x.id === this.form.controls.cabin.value
      );
      filter.cabinType = cabin;
      const bord = this.flightTypes.find(
        (x) => x.id === this.form.controls.stop.value
      );
      filter.scales = bord;
      filter.includesBaggage = this.form.controls.baggage.value;
      filter.includeCarryOn  = this.form.controls.carryon.value;
      filter.fromFilter = [];
      filter.toFilter = [];
      filter.dateFilter = [];
      // this.sectionsForm.controls[index].value.date
      switch (this.form.controls.type.value) {
        case this.TYPE_ROUND_TRIP:
          filter.fromFilter.push(this.form.controls.origin.value);
          filter.toFilter.push(this.form.controls.destination.value);
          filter.fromFilter.push(this.form.controls.destination.value);
          filter.toFilter.push(this.form.controls.origin.value);
          filter.dateFilter.push(this.fechaidaSelect);
          filter.dateFilter.push(this.fechavueltaSelect);
          break;
        case this.TYPE_ONE_WAY:
          filter.fromFilter.push(this.form.controls.origin.value);
          filter.toFilter.push(this.form.controls.destination.value);
          filter.dateFilter.push(this.form.controls.beginDate.value);
          break;
        case this.TYPE_MULTI:
          this.sectionsForm.controls.forEach((element) => {
            filter.fromFilter.push(element.value.origin);
            filter.toFilter.push(element.value.destination);
            filter.dateFilter.push(element.value.date);
          });
          break;
      }
      this.initSummary(filter);
      this.passengers = this.passengerList;
      this.viewFullScream = false;
      this.search.emit(filter);
    } else {
      /* this.openSnackBar(this.message); */
    }
  }

  onDestinationListSelected(index: number) {
    if (index + 2 <= this.sectionsForm.length) {
      (
        this.sectionsForm.controls[index + 1] as FormGroup
      ).controls.origin.setValue(
        this.sectionsForm.controls[index].value.destination
      );
    }
  }

  onBeginDateChange(event: MatDatepickerInputEvent<Date>) {
    /* //console.log(this.form.controls.beginDate.value);
    //console.log(this.form.controls.endDate.value); */
    if (this.form.controls.type.value === this.TYPE_ROUND_TRIP) {
      if (this.form.controls.endDate.value) {
        if (
          new Date(this.form.controls.beginDate.value) >
          new Date(this.form.controls.endDate.value)
        ) {
          this.form.controls.endDate.setValue("");
        }
      }
      this.minEndDate = this.form.controls.beginDate.value;

      if (!this.form.controls.endDate.value) {
        this.endDatePicker.open();
      }
    }
    this.validFechas = false;
  }

  onEndDateChange(event: MatDatepickerInputEvent<Date>) { }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 5000,
    });
  }
}
