export class Filter {

    static readonly PRICE_ORDER = 0;
    static readonly AIRLINES = 1;
    static readonly MORNING = 2;
    static readonly NIGHT = 3;
    static readonly SCHEDULES = 4;
    static readonly PRICES = 5;
    static readonly STOPS = 6;
    static readonly BAGGAGE = 7;
    static readonly GDS = 8;
    order: number;
    value: string;
}

