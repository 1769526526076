import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ResultSearch } from 'src/app/packages/shared/models/result-search.model';

@Component({
  selector: 'dm-autocomplete-select',
  templateUrl: './dm-autocomplete-select.component.html',
  styleUrls: ['./dm-autocomplete-select.component.css']
})
export class DmAutocompleteSelectComponent implements OnInit {
  showList: boolean = false;
  selectedValue: string = '';
  filterValue: string = '';
  lstName: string[] = [];
  filteredItems: any[];
  filteredOptions: Observable<string[]>;
  autocompleteControl: FormControl = new FormControl('');
  @Input() data: any[];
  @Input() srcImg: string = '/assets/images/origen.svg';
  @Input() icon: string;
  @Input() placeholder: string;
  @Output() selectOption = new EventEmitter();;

  constructor(private elementRef: ElementRef){ }

  ngOnInit() {
    this.getNames(this.data);
  }

  onInputChange(event: any){
    let valueinput = event.target.value;
    if(valueinput === ""){
      this.showList = false;
    }else{
      this.showList = true;
    }

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.lstName.filter(option => option.toLowerCase().includes(filterValue));
  }

  onInputClick() {
    this.showList = true;
  }

  clear() {
    document.body.classList.remove('no-scroll');
    this.autocompleteControl.setValue('');
    this.showList = false;
  }

  onchange(event){
    this.showList = false;
  }

  onOptionSelected2(selectedOption: string) {
    this.selectOption.emit(selectedOption);
  }

  selectItem(item: string) {
    this.selectedValue = item;
    this.filterValue = item; // Para mostrar el valor seleccionado en el input
    this.showList = false; // Ocultar la lista después de seleccionar un elemento
  }

  getNames(lst_ : any[]){
    lst_.forEach(element => {
      this.lstName.push(element.name)
    });

    this.filteredItems = this.lstName.filter(item => {
      return item.toLowerCase().includes(this.filterValue.toLowerCase());
    });
    
    // this.filteredOptions = this.myControl.valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(value => this._filter(value))
    //   );
  }
}
