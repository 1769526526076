export const FlightHour = [
    {
        id: '',
        description: '--'
    },
    {
        id: '0000',
        description: '00:00'
    },
    {
        id: '0100',
        description: '01:00'
    },
    {
        id: '0200',
        description: '02:00'
    },
    {
        id: '0300',
        description: '03:00'
    },
    {
        id: '0400',
        description: '04:00'
    },
    {
        id: '0500',
        description: '05:00'
    },
    {
        id: '0600',
        description: '06:00'
    },
    {
        id: '0700',
        description: '07:00'
    },
    {
        id: '0800',
        description: '08:00'
    },
    {
        id: '0900',
        description: '09:00'
    },
    {
        id: '1000',
        description: '10:00'
    },
    {
        id: '1100',
        description: '11:00'
    },
    {
        id: '1200',
        description: '12:00'
    },
    {
        id: '1300',
        description: '13:00'
    },
    {
        id: '1400',
        description: '14:00'
    },
    {
        id: '1500',
        description: '15:00'
    },
    {
        id: '1600',
        description: '16:00'
    },
    {
        id: '1700',
        description: '17:00'
    },
    {
        id: '1800',
        description: '18:00'
    },
    {
        id: '1900',
        description: '19:00'
    },
    {
        id: '2000',
        description: '20:00'
    },
    {
        id: '2100',
        description: '21:00'
    },
    {
        id: '2200',
        description: '22:00'
    },
    {
        id: '2300',
        description: '23:00'
    }
];
