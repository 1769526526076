import { Component, Inject, OnInit, ViewChildren } from '@angular/core';
import { MatAccordion, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dialog-videos',
  templateUrl: './dialog-videos.component.html',
  styleUrls: ['./dialog-videos.component.css']
})
export class DialogVideosComponent implements OnInit {

  listaDestinie = [];
  url;
  videos: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any[], protected sanitizer: DomSanitizer,
  public dialogRef: MatDialogRef<DialogVideosComponent>,) {}
  @ViewChildren(MatAccordion) accordion: MatAccordion;
  ngOnInit() {
    if (this.data.length > 0){
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data[0]);
      this.llenarDestinos();
    }
    
  }

  llenarDestinos(){
    
    this.data.forEach(element => {
      element = this.sanitizer.bypassSecurityTrustResourceUrl(element);
    });
    console.log(this.data);
  }

  abrir(link){
    window.open(link, "_blank");
  }

  close(): void {
    this.dialogRef.close();
  }

}
