export const FlightTypes = [
    {
        id: '',
        description: 'Todos'
    },
    {
        id: 'D',
        description: 'Directo'
    },
    {
        id: 'E',
        description: 'Con escalas'
    }
];
