import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-redirection',
  templateUrl: './dialog-redirection.component.html',
  styleUrls: ['./dialog-redirection.component.css']
})
export class DialogRedirectionComponent implements OnInit {

  texto = '';
  email;
  emailSplit;
  emailShow;
  emailText;
  emailSplitSecond;

  constructor(public dialogRef: MatDialogRef<DialogRedirectionComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private router: Router
) { }

  ngOnInit() {
   /*  console.log(this.data); */
    this.email = this.data.PersonalEmail;
    this.emailSplit = this.email;
    this.emailSplit = this.emailSplit.split('@');
    if (this.emailSplit[1].includes('.')) {
      this.emailSplitSecond = this.emailSplit[1].split('.');
      switch (this.emailSplitSecond[0]) {
        case 'gmail':
          this.emailShow = 'https://gmail.com';
          this.emailText = 'Ir a Gmail';
          break;
        case 'hotmail':
          this.emailShow = 'https://hotmail.com';
          this.emailText = 'Ir a Hotmail';
          break;
        case 'outlook':
          this.emailShow = 'https://outlook.com';
          this.emailText = 'Ir a Outlook';
          break;
        case 'yahoo':
          this.emailShow = 'https://yahoo.com';
          this.emailText = 'Ir a Yahoo';
          break;
        default:
          this.emailShow = '';
          this.emailText = 'Continuar Navegando';
      }
    } else {
      switch (this.emailSplit[1]) {
        case 'gmail':
          this.emailShow = 'https://gmail.com';
          this.emailText = 'Ir a Gmail';
          break;
        case 'hotmail':
          this.emailShow = 'https://hotmail.com';
          this.emailText = 'Ir a Hotmail';
          break;
        case 'outlook':
          this.emailShow = 'https://outlook.com';
          this.emailText = 'Ir a Outlook';
          break;
        case 'yahoo':
          this.emailShow = 'https://yahoo.com';
          this.emailText = 'Ir a Yahoo';
          break;
        default:
          this.emailShow = '';
          this.emailText = 'Continuar Navegando';
      }
    }
  }

  document(){
    /* window.open(this.emailShow , '_blank', 'toolbar=0,location=0,menubar=0'); */
    /* this.router.navigate([this.emailShow]); */
    /* console.log(window.location.href); */
    window.location.href = this.emailShow;
   /*  console.log(window.location.href); */
  }

}
