import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";

import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  Validators,
} from "@angular/forms";

import { Select } from "src/app/shared/models/select.model";
import { FilterPassenger } from "src/app/shared/models/filter-passenger.model";
import {
  MatDatepickerInputEvent,
  MatDatepicker,
} from "@angular/material/datepicker";
import { SessionService } from "src/app/core/services/session.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { FilterFlightWrapper } from "src/app/flights/shared/filter-flight-wrapper.model";
import { FlightService } from "src/app/core/services/flight.service";
import { airportsData } from "../../services/airport.const";


// tslint:disable-next-line: component-selector
@Component({
  selector: 'app-search-flight-url',
  templateUrl: './search-flight-url.component.html',
  styleUrls: ['./search-flight-url.component.css'],
  providers: [FlightService, SessionService]
})

export class SearchFlightUrlComponent implements OnInit {
  @Input() data: FilterFlightWrapper;
  @Input() expandable: boolean;
  @Input() color: string;
  @Output() search = new EventEmitter<FilterFlightWrapper>();

  @ViewChild("dpEndDeparture", { read: undefined, static: false })
  endDatePicker: MatDatepicker<Date>;

  form: FormGroup;
  cabinClass: Select[] = [];
  flightTypes: Select[] = [];
  origins = [];
  originList = [];
  destinationList = [];
  originControl = new FormControl("");
  destinationControl = new FormControl("");
  expanded: boolean;
  sectionsForm: FormArray;
  sectionsList = [];
  minBeginDate = new Date();
  minCalendar = new Date();
  minEndDate = new Date();
  passengers: FilterPassenger[];
  passengerList: FilterPassenger[];
  dataAirport: any;

  TYPE_ONE_WAY = FilterFlightWrapper.TYPE_ONE_WAY;
  TYPE_ROUND_TRIP = FilterFlightWrapper.TYPE_ROUND_TRIP;
  TYPE_MULTI = FilterFlightWrapper.TYPE_MULTI;
  LIMIT_SECTIONS = 6;
  cargar: boolean;
  from: string;
  to: string;
  valPackage;
  dateFrom: Date;
  dateTo: Date;
  datos = {
    origin: null,
    destiny: null,
    numberPassenger: null,
    typePassenger: null,
    includesBaggage: null,
    cabinType: null,
    startDate: null,
    endDate: null,
    type: null
  }
  tipoVuelo;
  tipoCabina;
  public myObject!: { id: number, myObject: { myString: string } };
  constructor(
    private formBuilder: FormBuilder,
    private flightService: FlightService,
    private sessionService: SessionService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { 
    this.dataAirport = airportsData;
  }

  ngOnInit() {
    this.cargar = false;
    //console.log(this.data);
    this.minCalendar.setDate(this.minCalendar.getDate() + 2);
    /* //console.log('init', this.expandable, this.data); */
    this.initForm();

    let data = this.rideURL();

        this.origins = [];
        this.getAirports();
    


  }

  rideURL() {
    let datos;
    let url = window.location.href;
    let manco = url.split("?");


    this.valPackage = manco[0];

    let valor = this.valPackage.split('#');
    let adic = valor[1];

    if (manco.length === 1 || adic === '/filter') {
      return null;
    } else {
      let enviar = manco[1]
      this.sessionService.setURL(enviar);

      return null;
    }
  }

  getPriorityAirports() {
    /* this.spinner.show(); */
    this.flightService.getPriorityAirports().subscribe(
      (result) => {
        /* //console.log(result); */
        if (result) {
          const airports = result.lairports ? result.lairports : [];
          const cities = result.lcities ? result.lcities : [];
          airports.forEach((airport) => {
            this.origins.push({
              code: airport.iataCode,
              countryCode: airport.countryCode,
              name: airport.name,
              searchName: airport.searchName,
              priority: airport.priority,
              /* icon: 'local_airport' */
              icon: "A",
            });
          });
          cities.forEach((city) => {
            this.origins.push({
              code: city.iataCode,
              countryCode: city.countryCode,
              name: city.name,
              searchName: city.searchName,
              priority: city.priority,
              /* icon: 'location_city' */
              icon: "C",
            });
          });
          /* this.origins.sort((a, b) => b.priority - a.priority);  */
        }
      },
      (err) => {
        /*   //console.log('Error: ', err); */
      },
      () => {
        this.enviarLim();
        this.getAirports();
      }
    );
  }

  getAirports() {
    this.enviarLim();
    const _origins = [];
    let result = this.dataAirport;
    if (result) {
      const airports = result.lairports ? result.lairports : [];
      const cities = result.lcities ? result.lcities : [];
      airports.forEach((airport) => {
        _origins.push({
          code: airport.iataCode,
          countryCode: airport.countryCode,
          name: airport.name,
          searchName: airport.searchName,
          priority: airport.priority,
          cityName: airport.cityName,
          /* icon: 'local_airport' */
          icon: "A",
        });
      });
      cities.forEach((city) => {
        _origins.push({
          code: city.iataCode,
          countryCode: city.countryCode,
          name: city.name,
          searchName: city.searchName,
          priority: city.priority,
          cityName: city.cityName,
          /* icon: 'location_city' */
          icon: "C",
        });
      });
      /* _origins.sort((a, b) => b.priority - a.priority); */
    }

    this.origins = _origins;
    
    // this.flightService.getAirports().subscribe(
    //   (result) => {
    //     if (result) {
    //       const airports = result.lairports ? result.lairports : [];
    //       const cities = result.lcities ? result.lcities : [];
    //       airports.forEach((airport) => {
    //         _origins.push({
    //           code: airport.iataCode,
    //           countryCode: airport.countryCode,
    //           name: airport.name,
    //           searchName: airport.searchName,
    //           priority: airport.priority,
    //           cityName: airport.cityName,
    //           /* icon: 'local_airport' */
    //           icon: "A",
    //         });
    //       });
    //       cities.forEach((city) => {
    //         _origins.push({
    //           code: city.iataCode,
    //           countryCode: city.countryCode,
    //           name: city.name,
    //           searchName: city.searchName,
    //           priority: city.priority,
    //           cityName: city.cityName,
    //           /* icon: 'location_city' */
    //           icon: "C",
    //         });
    //       });
    //       /* _origins.sort((a, b) => b.priority - a.priority); */
    //     }
    //   },
    //   (err) => {
    //     /*  //console.log('Error: ', err); */
    //   },
    //   () => {
    //     this.origins = _origins;

    //   }
    // );
  }

  enviarLim() {
    const objeto = {
      code: "LIM",
      countryCode: "PE",
      icon: "A",
      name: "[LIM] - Jorge Chavez International - LIM - Lima - Peru",
      priority: 2,
      searchName: "LIM-Lima-Jorge Chavez International-J Chavez Intl-Peru-Perú-Peruu-Perù-Pê ru-Pérou"
    }
    this.form.controls.origin.setValue(objeto);
    /* this.origins.forEach(element => {
      if(element.code === 'LIM'){
        this.form.controls.origin.setValue(element);
      }
    });
    this.spinner.hide(); */
  }

  enviarLim1() {
    this.origins.forEach(element => {
      if (element.code === 'LIM') {
        this.form.controls.origin.setValue(element);
        let room = this.form.controls.sections as FormArray;
        let adult = room.controls[0] as FormGroup;
        adult.controls.origin.setValue(element);
      }
    });

  }

  getPriorityAirports1() {

    this.flightService.getPriorityAirports().subscribe(
      (result) => {
        /* //console.log(result); */
        if (result) {
          const airports = result.lairports ? result.lairports : [];
          const cities = result.lcities ? result.lcities : [];
          airports.forEach((airport) => {
            this.origins.push({
              code: airport.iataCode,
              countryCode: airport.countryCode,
              name: airport.name,
              searchName: airport.searchName,
              priority: airport.priority,
              /* icon: 'local_airport' */
              icon: "A",
            });
          });
          cities.forEach((city) => {
            this.origins.push({
              code: city.iataCode,
              countryCode: city.countryCode,
              name: city.name,
              searchName: city.searchName,
              priority: city.priority,
              /* icon: 'location_city' */
              icon: "C",
            });
          });
          this.origins.sort((a, b) => b.priority - a.priority);

        }
      },
      (err) => {
        /*   //console.log('Error: ', err); */
      },
      () => {
        this.getAirports1();
      }
    );
  }

  getAirports1() {
    const _origins = [];
    let result = this.dataAirport;
    if (result) {
      const airports = result.lairports ? result.lairports : [];
      const cities = result.lcities ? result.lcities : [];
      airports.forEach((airport) => {
        _origins.push({
          code: airport.iataCode,
          countryCode: airport.countryCode,
          name: airport.name,
          searchName: airport.searchName,
          priority: airport.priority,
          cityName: airport.cityName,
          /* icon: 'local_airport' */
          icon: "A",
        });
      });
      cities.forEach((city) => {
        _origins.push({
          code: city.iataCode,
          countryCode: city.countryCode,
          name: city.name,
          searchName: city.searchName,
          priority: city.priority,
          cityName: city.cityName,
          /* icon: 'location_city' */
          icon: "C",
        });
      });
      _origins.sort((a, b) => b.priority - a.priority);
      this.setFilterData(_origins);
    }

    this.origins = _origins;

    // this.flightService.getAirports().subscribe(
    //   (result) => {
    //     if (result) {
    //       const airports = result.lairports ? result.lairports : [];
    //       const cities = result.lcities ? result.lcities : [];
    //       airports.forEach((airport) => {
    //         _origins.push({
    //           code: airport.iataCode,
    //           countryCode: airport.countryCode,
    //           name: airport.name,
    //           searchName: airport.searchName,
    //           priority: airport.priority,
    //           cityName: airport.cityName,
    //           /* icon: 'local_airport' */
    //           icon: "A",
    //         });
    //       });
    //       cities.forEach((city) => {
    //         _origins.push({
    //           code: city.iataCode,
    //           countryCode: city.countryCode,
    //           name: city.name,
    //           searchName: city.searchName,
    //           priority: city.priority,
    //           cityName: city.cityName,
    //           /* icon: 'location_city' */
    //           icon: "C",
    //         });
    //       });
    //       _origins.sort((a, b) => b.priority - a.priority);
    //       /*  this.sessionService.setOrigins(_origins); */
    //       this.setFilterData();
    //     }
    //   },
    //   (err) => {
    //     /*  //console.log('Error: ', err); */
    //   },
    //   () => {
    //     this.origins = _origins;

    //   }
    // );
  }

  selectType(event: Event) {
    //console.log("selectType");
    const typeSel = (event.target as HTMLSelectElement).value;
    //console.log("typeSel: " + typeSel);
    this.tipoVuelo = typeSel;
    this.form.controls.type.setValue(typeSel);

  }



  selectCabin(event: Event) {
    //console.log("selectCabin");
    const cabinSel = (event.target as HTMLSelectElement).value;
    //console.log("cabinSel: " + cabinSel);
    this.tipoCabina = cabinSel;
    this.form.controls.cabin.setValue(cabinSel);

  }

  initForm() {
    //console.log("initForm");

    this.cabinClass = this.flightService.getCabinTypes();
    this.flightTypes = this.flightService.getFlightTypes();

    let type = this.TYPE_ROUND_TRIP;
    let origin;
    let destination;
    let beginDate;
    let endDate;
    let cabinType = "";
    let scales = "";
    let includeBaggage;

    if (this.data) {
      type = this.data.type;
      //console.log("type: " + type);
      this.tipoVuelo = type;
      switch (type) {
        case this.TYPE_ROUND_TRIP:
          origin = this.data.fromFilter[0];
          destination = this.data.toFilter[0];
          beginDate = this.data.dateFilter[0];
          endDate = this.data.dateFilter[1];
          break;
        case this.TYPE_ONE_WAY:
          origin = this.data.fromFilter[0];
          destination = this.data.toFilter[0];
          beginDate = this.data.dateFilter[0];
          break;
        case this.TYPE_MULTI:
          break;
      }
      cabinType = this.data.cabinType.id;
      this.tipoCabina = cabinType;
      scales = this.data.scales.id;
      includeBaggage = this.data.includesBaggage;
      this.minEndDate = beginDate;
    } else {
      this.origins.forEach(element => {
        if (element.code === 'LIM') {
          origin = element;
        }
      });
    }

    this.form = this.formBuilder.group({
      type: new FormControl(type),
      origin: new FormControl(origin),
      destination: new FormControl(destination),
      beginDate: new FormControl(beginDate),
      endDate: new FormControl(endDate),
      cabin: new FormControl(cabinType),
      stop: new FormControl(scales),
      baggage: new FormControl(includeBaggage ? includeBaggage : ""),
      sections: new FormArray([]),
      passengers: new FormArray([]),
    });

    this.sectionsForm = this.form.get("sections") as FormArray;

    this.initSections();

    this.initPassengers();

    this.initSummary(this.data);
  }

  changess() {
    let datos;

    let showdat = this.sessionService.getURL();
    datos = showdat.split("&");
    this.datos.origin = datos[0].split("=");
    this.datos.destiny = datos[1].split("=");
    this.datos.numberPassenger = datos[2].split("=");
    this.datos.typePassenger = datos[3].split("=");
    this.datos.includesBaggage = datos[4].split("=");
    this.datos.cabinType = datos[5].split("=");
    this.datos.startDate = datos[6].split("=");
    this.datos.endDate = datos[7].split("=");
    this.datos.type = datos[8].split("=");
  }

  setFilterData(origenes) {
    this.changess();
    let data = new FilterFlightWrapper();
    let to = [];
    let origin = [];
    let fecha = [];
    let origins = this.origins;
   
    let pasajeros = [];
    data.Ocompany = null;
    data.PartnerClub = false;
    const cabina = {
      description: null,
      id: null
    }
    const escala = {
      description: "Todos",
      id: ""
    }
    if (this.datos.cabinType[1] === 'T') {
      cabina.description = "Todas";
      cabina.id = "";
      data.cabinType = cabina;
    } else if (this.datos.cabinType[1] === 'E') {
      cabina.description = "Económica";
      cabina.id = "E";
      data.cabinType = cabina;
    } else if (this.datos.cabinType[1] === 'B') {
      cabina.description = "Business";
      cabina.id = "B";
      data.cabinType = cabina;
    } else {
      cabina.description = "First";
      cabina.id = "F";
      data.cabinType = cabina;
    }
    fecha.push(this.datos.startDate[1]);
    if (this.datos.endDate != null) {
      fecha.push(this.datos.endDate[1]);
    }

    data.dateFilter = fecha;
    if (this.datos.includesBaggage[1] === 'false') {
      data.includesBaggage = false;
    } else {
      data.includesBaggage = true;
    }

    const pasajero = {
      numberPassenger: this.datos.numberPassenger[1],
      typePassenger: "ADT"
    }
    const pasajero1 = {
      numberPassenger: this.datos.numberPassenger[1],
      typePassenger: "CHD"
    }
    const pasajero2 = {
      numberPassenger: this.datos.numberPassenger[1],
      typePassenger: "INF"
    }
    const pasajero3 = {
      numberPassenger: "0",
      typePassenger: "ADT"
    }
    const pasajero4 = {
      numberPassenger: "0",
      typePassenger: "CHD"
    }
    const pasajero5 = {
      numberPassenger: "0",
      typePassenger: "INF"
    }
    if (this.datos.typePassenger[1] === 'ADT') {
      pasajeros.push(pasajero);
      pasajeros.push(pasajero4);
      pasajeros.push(pasajero5);
    } else if (this.datos.typePassenger[1] === 'CHD') {
      pasajeros.push(pasajero1);
      pasajeros.push(pasajero3);
      pasajeros.push(pasajero5);
    } else {
      pasajeros.push(pasajero2);
      pasajeros.push(pasajero4);
      pasajeros.push(pasajero3);
    }

    data.lpassenger = pasajeros;
    data.scales = escala;
    data.type = this.datos.type[1];
      origins = origenes;
      origins.forEach(element => {
        if (this.datos.origin[1] === element.code) {
          this.datos.origin[1] = element;
        }
        if (this.datos.destiny[1] === element.code) {
          this.datos.destiny[1] = element;
        }
      });
    
    origin.push(this.datos.origin[1]);
    if (data.type === 'RT') {
      origin.push(this.datos.destiny[1]);
    }


    data.fromFilter = origin;
    to.push(this.datos.destiny[1]);
    if (data.type === 'RT') {
      to.push(this.datos.origin[1]);
    }


    data.toFilter = to;

    this.sessionService.setFilterFlightWrapper(data);
    this.cargar = false;
    this.router.navigate(['flight/flight-result']);
  }

  initSummary(data: FilterFlightWrapper) {
    if (data && this.expandable) {
      switch (data.type) {
        case this.TYPE_ROUND_TRIP:
          this.from = data.fromFilter[0].code;
          this.to = data.toFilter[0].code;
          this.dateFrom = data.dateFilter[0];
          this.dateTo = data.dateFilter[1];
          break;
        case this.TYPE_ONE_WAY:
          this.from = data.fromFilter[0].code;
          this.to = data.toFilter[0].code;
          this.dateFrom = data.dateFilter[0];
          break;
        case this.TYPE_MULTI:
          this.from = data.fromFilter[0].code;
          this.to = data.toFilter[data.toFilter.length - 1].code;
          this.dateFrom = data.dateFilter[0];
          this.dateTo = data.dateFilter[data.dateFilter.length - 1];
          break;
      }
    }
  }

  initSections() {
    if (this.data && this.data.type === this.TYPE_MULTI) {
      this.data.fromFilter.forEach((from, index) => {
        this.addSection(
          from,
          this.data.toFilter[index],
          this.data.dateFilter[index]
        );
      });
    } else {
      this.addSection();
      this.addSection();
    }
  }

  addSection(from?, to?, date?) {
    let index = this.sectionsForm.length;

    if (index < this.LIMIT_SECTIONS) {
      this.sectionsForm.push(
        new FormGroup({
          origin: new FormControl(from ? from : ""),
          destination: new FormControl(to ? to : ""),
          date: new FormControl(date ? date : ""),
        })
      );

      let _minDate = new Date();
      _minDate.setDate(_minDate.getDate() + 1);
      let previousDate;
      if (index > 0) {
        previousDate = this.sectionsForm.controls[index - 1].value.date;
        if (previousDate && previousDate !== "") {
          _minDate = previousDate;
        }
      }

      this.sectionsList.push({
        originList: [],
        destinationList: [],
        minDate: _minDate,
      });

      if (!from && index > 0) {
        (
          this.sectionsForm.controls[index] as FormGroup
        ).controls.origin.setValue(
          this.sectionsForm.controls[index - 1].value.destination
        );
      }
    }
  }

  initPassengers() {
    if (this.data) {
      this.passengers = this.data.lpassenger;
    }
  }

  onOriginChange(val: string) {
    if (val.length >= 3 && this.origins) {
      this.originList = this.origins.filter(
        (item) => item.searchName.toLowerCase().search(val.toLowerCase()) >= 0
      );
    } else {
      this.originList = [];
    }
  }

  onDestinationChange(val: string) {
    if (val.length >= 3 && this.origins) {
      this.destinationList = this.origins.filter(
        (item) => item.searchName.toLowerCase().search(val.toLowerCase()) >= 0
      );
    } else {
      this.destinationList = [];
    }
    this.destinationList = this.ordenarHoteles(this.destinationList);
  }

  ordenarHoteles(list) {
    /* list.sort((a, b) => b.priority - a.priority); */
    let newList = [];
    list.forEach(element => {
      if (element.icon === 'A' && newList.length <= 4) {
        newList.push(element);
      }
    });

    list.forEach(element => {
      if (element.icon === 'C' && newList.length <= 9) {
        newList.push(element);
      }
    });

    /*  newList.sort((a, b) => a.priority - b.priority); */
    return newList;
  }

  onOriginListChange(val: string, index: number) {
    if (val.length >= 3 && this.origins) {
      this.sectionsList[index].originList = this.origins.filter(
        (item) => item.searchName.toLowerCase().search(val.toLowerCase()) >= 0
      );
    } else {
      this.sectionsList[index].originList = [];
    }
  }

  onDestinationListChange(val: string, index: number) {
    if (val.length >= 3 && this.origins) {
      this.sectionsList[index].destinationList = this.origins.filter(
        (item) => item.searchName.toLowerCase().search(val.toLowerCase()) >= 0
      );
    } else {
      this.sectionsList[index].destinationList = [];
    }
  }

  addEvent(event: MatDatepickerInputEvent<Date>, index: number) {
    this.sectionsList.forEach((element, i) => {
      if (i > index) {
        if (
          this.sectionsForm.controls[i].value.date &&
          new Date(this.sectionsForm.controls[i].value.date) <
          new Date(this.sectionsForm.controls[index].value.date)
        ) {
          (this.sectionsForm.controls[i] as FormGroup).controls.date.setValue(
            this.sectionsForm.controls[index].value.date
          );
        }
        element.minDate = event.value;
      }
    });
  }

  remove(index: number) {
    this.sectionsForm.removeAt(index);
  }

  selection(passengers: FilterPassenger[]) {
    this.passengerList = passengers;
  }

  getPassengers(): number {
    let passengers = 0;
    if (this.data && this.data.lpassenger) {
      this.data.lpassenger.forEach((element) => {
        passengers += Number(element.quantity);
      });
    }
    return passengers;
  }

  searching() {
    let valid: boolean = true;
    let message: string = "";
    switch (this.form.controls.type.value) {
      case this.TYPE_ROUND_TRIP:
        if (
          !this.form.controls.origin.value ||
          !this.form.controls.destination.value ||
          !this.form.controls.beginDate.value ||
          !this.form.controls.endDate.value
        ) {
          valid = false;
          message = "Campos incompletos";
        }
        if (
          valid &&
          this.form.controls.origin.value.code ===
          this.form.controls.destination.value.code
        ) {
          valid = false;
          message = "Origen y Destino no pueden ser iguales";
        }
        break;
      case this.TYPE_ONE_WAY:
        if (
          !this.form.controls.origin.value ||
          !this.form.controls.destination.value ||
          !this.form.controls.beginDate.value
        ) {
          valid = false;
          message = "Campos incompletos";
        }

        if (
          valid &&
          this.form.controls.origin.value.code ===
          this.form.controls.destination.value.code
        ) {
          valid = false;
          message = "Origen y Destino no pueden ser iguales";
        }
        break;
      case this.TYPE_MULTI:
        this.sectionsForm.controls.forEach((element) => {
          if (
            !element.value.origin ||
            !element.value.destination ||
            !element.value.date
          ) {
            valid = false;
            message = "Campos incompletos";
            return;
          }

          if (
            valid &&
            element.value.origin.code === element.value.destination.code
          ) {
            valid = false;
            message = "Origen y Destino no pueden ser iguales";
            return;
          }
        });
        break;
    }

    if (valid) {
      let company;
      let partner;
      const data = this.sessionService.getLoginData();
      if (data != null) {
        company = data.ouserLogin.ocompany;
        partner = true;
      } else {
        company = null;
        partner = false;
      }
      let filter = new FilterFlightWrapper();
      filter.Ocompany = company;
      filter.PartnerClub = partner;
      filter.type = this.form.controls.type.value;
      filter.lpassenger = this.passengerList;
      const cabin = this.cabinClass.find(
        (x) => x.id === this.form.controls.cabin.value
      );
      filter.cabinType = cabin;
      const bord = this.flightTypes.find(
        (x) => x.id === this.form.controls.stop.value
      );
      filter.scales = bord;
      filter.includesBaggage = this.form.controls.baggage.value;
      filter.fromFilter = [];
      filter.toFilter = [];
      filter.dateFilter = [];
      // this.sectionsForm.controls[index].value.date
      switch (this.form.controls.type.value) {
        case this.TYPE_ROUND_TRIP:
          filter.fromFilter.push(this.form.controls.origin.value);
          filter.toFilter.push(this.form.controls.destination.value);
          filter.fromFilter.push(this.form.controls.destination.value);
          filter.toFilter.push(this.form.controls.origin.value);
          filter.dateFilter.push(this.form.controls.beginDate.value);
          filter.dateFilter.push(this.form.controls.endDate.value);
          break;
        case this.TYPE_ONE_WAY:
          filter.fromFilter.push(this.form.controls.origin.value);
          filter.toFilter.push(this.form.controls.destination.value);
          filter.dateFilter.push(this.form.controls.beginDate.value);
          break;
        case this.TYPE_MULTI:
          this.sectionsForm.controls.forEach((element) => {
            filter.fromFilter.push(element.value.origin);
            filter.toFilter.push(element.value.destination);
            filter.dateFilter.push(element.value.date);
          });
          break;
      }
      this.initSummary(filter);
      this.passengers = this.passengerList;
      this.search.emit(filter);
    } else {
      this.openSnackBar(message);
    }
  }

  onDestinationListSelected(index: number) {
    if (index + 2 <= this.sectionsForm.length) {
      (
        this.sectionsForm.controls[index + 1] as FormGroup
      ).controls.origin.setValue(
        this.sectionsForm.controls[index].value.destination
      );
    }
  }

  onBeginDateChange(event: MatDatepickerInputEvent<Date>) {
    /* //console.log(this.form.controls.beginDate.value);
    //console.log(this.form.controls.endDate.value); */
    if (this.form.controls.type.value === this.TYPE_ROUND_TRIP) {
      if (this.form.controls.endDate.value) {
        if (
          new Date(this.form.controls.beginDate.value) >
          new Date(this.form.controls.endDate.value)
        ) {
          this.form.controls.endDate.setValue("");
        }
      }
      this.minEndDate = this.form.controls.beginDate.value;

      if (!this.form.controls.endDate.value) {
        this.endDatePicker.open();
      }
    }
  }

  onEndDateChange(event: MatDatepickerInputEvent<Date>) { }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 2000,
    });
  }
}
