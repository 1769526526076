import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Category } from '../../packages/shared/models/category.model';
import { Package } from '../../packages/shared/models/package.model';

@Component({
  selector: 'dm-package-carousel',
  templateUrl: './package-carousel.component.html',
  styleUrls: ['./package-carousel.component.css']
})
export class PackageCarouselComponent implements OnInit, AfterViewInit {

  @Input() elementSize: number;
  @Input() elementMargin: number;
  @Input() category: Category;
  @Input() elements: any[];
  @Output() selectPack1 = new EventEmitter<Package>();
  @Output() selectCategor1 = new EventEmitter<Package>();

  public carouselWidth: number;
  public elementWidth: number;
  widthWeb: number;
  public currentIndex: number = 0;
  public pages: number = 0;
  public progressSize: number = 4;
  public counterLeft: number = 0;
  public counterRight: number = 0;
  public progressMargin: number = 0;
  public movementsToRight: number = 0;
  public movementsToLeft: number = 0;
  public currentItemsCarousel: number = 0;
  public scrHeight:any;
  public scrWidth:any;
  findindex: any[] = [];

  defaultTouch = { x: 0, y: 0, time: 0 };

  @HostListener('window:resize')
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    let elementsView = this.getElementsView();
    if (this.currentItemsCarousel !== elementsView){
      this.calculateDimensions();
    }
  }

  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  handleTouch(event) {
    let touch = event.touches[0] || event.changedTouches[0];
    if (event.type === 'touchstart') {
      this.defaultTouch.x = touch.pageX;
      this.defaultTouch.time = event.timeStamp;
    } else if (event.type === 'touchend') {
      let deltaX = touch.pageX - this.defaultTouch.x;
      let deltaTime = event.timeStamp - this.defaultTouch.time;
      if (deltaTime < 500) {
        if (Math.abs(deltaX) > 60) {
          if (deltaX > 0) {
            this.prev();
          } else {
            this.next();
          }
        }
      }
    }
  }
    
  constructor() {
    this.getScreenSize();
   }

  ngOnInit(): void {
    // console.log(this.elementSize, this.elementMargin, this.elements);
    this.calculateDimensions();
  }

  calculatePageWidth() {
    return window.innerWidth;
  }

  ngAfterViewInit(): void {
    /* for (let index = 0; index < 2; index++) {
      this.next();
      
    } */

    let url = window.location.pathname;
    /* let quitar = url.substring(0, url.length - 1);
    let located = quitar.split("?"); */
    let locat = window.location.search;
    if(locat != ""){
      let locat2 = locat.split('?');
    let quitar2 = locat2[1].substring(0, locat2[1].length - 1);
      if(url === "/pack"){
        this.elements.forEach(pack => {
          if(pack.nameURL === quitar2){
            this.findindex.push(pack);
          }
        });
        setTimeout(() => {
          if(this.findindex.length > 0){
                  let indexnum;
                  this.widthWeb = this.calculatePageWidth();
    
                  switch (true) {
                    case (this.widthWeb > 200 && this.widthWeb <= 700):
                      indexnum = this.findindex[0].index;
                      break;
                    case (this.widthWeb > 700 && this.widthWeb <= 1028):
                      indexnum = this.findindex[0].index - 1;
                      break;
                    case (this.widthWeb > 1028):
                      indexnum = this.findindex[0].index - 2;
                      break;
                  }
            /* let indexnum = this.findindex[0].index - 2; */
                if(indexnum > 0){
                  for (let index = 0; index < indexnum; index++) {
                    this.next();
                    }
                }
              }
        }, 1000);
        
        }
    }
   
  }

  calculateDimensions() {
    this.currentItemsCarousel = this.getElementsView();
    this.elementWidth = this.elementSize + 2 * this.elementMargin;
    this.carouselWidth = this.elementWidth * this.getElementsView();
    this.currentIndex = 0;
    if (this.elements !== null && this.elements !== undefined){
      this.pages = this.elements.length - this.getElementsView() + 1;
      if (this.pages <= 0)
        this.pages = 1;
      this.elements.map((i, index)=> {
        i.index = index, i.marginLeft = this.elementMargin, i.marginRight = this.elementMargin
      });
    }
    
    this.progressMargin = 0;
    this.counterLeft = 0;
    this.counterRight = 0;
    this.movementsToRight = 0;
    this.movementsToLeft = 0;
  }

  getElementsView():number {
    if (this.scrWidth > 1450) {
      return 4;
    }else if (this.scrWidth <= 1450 && this.scrWidth > 1024){
      return 3;
    } else if (this.scrWidth <= 1024 && this.scrWidth > 700){
      return 2;
    } else {
      return 1;
    }
  }

  prev() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.elements.find(x => x.index === 0).marginLeft = -(this.elementWidth) * this.currentIndex + this.elementMargin;

      if (this.counterRight - this.counterLeft <= this.pages - this.progressSize && this.movementsToRight - this.movementsToLeft > 0){
        this.progressMargin = this.progressMargin + 24;
        this.movementsToLeft++;
      }
      this.counterLeft++;
    }
    
    
  }

  next() {
    if (this.currentIndex < this.pages - 1) {
      this.currentIndex++;
      this.elements.find(x => x.index === 0).marginLeft = -(this.elementWidth) * this.currentIndex + this.elementMargin;

      if (this.counterRight - this.counterLeft >= this.progressSize - 1 && this.movementsToRight - this.movementsToLeft < this.pages - this.progressSize){
        this.progressMargin = this.progressMargin - 24;
        this.movementsToRight++;
      }
      this.counterRight++;
    }
    
    
  }

  selectPackage(pack: Package) {
    this.selectPack1.emit(pack);
  }

  selectCate(valor) {
    this.selectCategor1.emit(valor);
  }
}

