import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FilterSearch } from '../../hotels/hotel-filter-search/shared/filter-search.model';
import * as config from '../config';
import * as utils from '../../shared/utils';
import { Passenger } from 'src/app/shared/models/passenger.model';
import { FtPassenger } from 'src/app/flights/shared/models/flight-transaction/ft-passenger.model';
import { FilterPassenger } from 'src/app/shared/models/filter-passenger.model';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/core/services/session.service';


let httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': environment.subsKey,
    })
};


@Injectable()
export class HotelService {
    constructor(private http: HttpClient,private sessionService: SessionService) {}

    searchHotels(filter: FilterSearch) {
        const code = 3;
        const codeOne = 6;
        const lRating = [];
        lRating.push(code);
        lRating.push(codeOne);
        const rooms = [];
        const passengers = [];
        let age = 0;
        // httpOptions.headers = new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,
      
        //   });
        filter.rooms.forEach((room, index) => {
            rooms.push({
                RoomSequence: index + 1
            });

            for (let i = 0; i < room.adults; i++) {
                passengers.push({
                    TypePassenger: FilterPassenger.TYPE_ADULT,
                    RoomSequence: index + 1
                });
            }

            for (let i = 0; i < room.children; i++) {
                age = room.ages[i];
                passengers.push({
                    TypePassenger: age < 2 ? FilterPassenger.TYPE_INFANT : FilterPassenger.TYPE_CHILD_HOTEL,
                    RoomSequence: index + 1,
                    Age: age
                });
            }
        });

        const body = {
            LHotel: [
                {
                    HotelCityCode: filter.to.code,
                    HotelCountryCode: filter.to.countryCode,
                    StartDate: utils.formatyyyymmdd(filter.departureDate.toString()),
                    EndDate: utils.formatyyyymmdd(filter.returnDate.toString()),
                    LRating: lRating,
                    LAvailability: '',
                    LSupplier: filter.lSupplier,
                    LTripAdvisor: filter.lTripAdvisor,
                    LBoard: filter.lBoard,
                    LZone: filter.lZone,
                    ORoom: {
                        LRoom: rooms
                    }
                }
            ],
            OPassengers: {
                LPassenger: passengers
            },
            OPagination: {
                FirstItem: filter.firstItem,
                ItemsPerPage: '10'
            }
        };
        return this.http.post<any>(config.getSearchHoltelUrl('Product/SearchHotel'), body, httpOptions);
    }

    getDynamicSearchHotel(type) {
        // httpOptions.headers = new HttpHeaders({
        //     "Content-Type": "application/json",
        //     "Ocp-Apim-Subscription-Key": environment.subsKey
        // });
        const url ="DynamicSearch/GetDynamicSearch?type=" + type;
        console.log(url);

        return this.http.get<any>(config.getDynamicSearch(url), httpOptions);
    }

    searchHotelFlight(filter: FilterSearch) {
        const code = 3;
        const codeOne = 6;
        const lRating = [];
        lRating.push(code);
        lRating.push(codeOne);
        const rooms = [];
        const passengers = [];
        let age = 0;
        // httpOptions.headers = new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,
      
        //   });
        filter.rooms.forEach((room, index) => {
            rooms.push({
                RoomSequence: index + 1
            });

            for (let i = 0; i < room.adults; i++) {
                passengers.push({
                    TypePassenger: FilterPassenger.TYPE_ADULT,
                    Quantity: index + 1
                });
            }

            for (let i = 0; i < room.children; i++) {
                age = room.ages[i];
                passengers.push({
                    TypePassenger: age < 2 ? FilterPassenger.TYPE_INFANT : FilterPassenger.TYPE_CHILD_HOTEL,
                    Quantity: index + 1
                });
            }
        });

        const body = {
            LHotel: [
                {
                    HotelCityCode: filter.to.code,
                    HotelCountryCode: filter.to.countryCode,
                    StartDate: utils.formatyyyymmdd(filter.departureDate.toString()),
                    EndDate: utils.formatyyyymmdd(filter.returnDate.toString()),
                    LRating: lRating,
                    LAvailability: '',
                    LSupplier: filter.lSupplier,
                    LTripAdvisor: filter.lTripAdvisor,
                    LBoard: filter.lBoard,
                    LZone: filter.lZone,
                    ORoom: {
                        LRoom: rooms
                    }
                }
            ],
            LPassenger: passengers,
            OPagination: {
                FirstItem: filter.firstItem,
                ItemsPerPage: '10'
            }
        };
        return this.http.post<any>(config.getSearchHoltelUrl('Product/SearchHotel'), body, httpOptions);
    }

    searchHotelDetail(filter: FilterSearch, code: string, sections: any) {
        const rooms = [];
        const passengers = [];
        let age = 0;
        // httpOptions.headers = new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,
      
        //   });
        filter.rooms.forEach((room, index) => {
            rooms.push({
                RoomSequence: index + 1
            });

            for (let i = 0; i < room.adults; i++) {
                passengers.push({
                    TypePassenger: FilterPassenger.TYPE_ADULT,
                    RoomSequence: index + 1
                });
            }

            for (let i = 0; i < room.children; i++) {
                age = room.ages[i];
                passengers.push({
                    TypePassenger: age < 2 ? FilterPassenger.TYPE_INFANT : FilterPassenger.TYPE_CHILD_HOTEL,
                    RoomSequence: index + 1,
                    Age: age
                });
            }
        });

        const body = {
            LHotel: [
                {
                    HotelCityCode: filter.to.code,
                    HotelCountryCode: filter.to.countryCode,
                    Lsections: sections,
                    StartDate: utils.formatyyyymmdd(filter.departureDate.toString()),
                    EndDate: utils.formatyyyymmdd(filter.returnDate.toString()),
                    Hotelcode: code,
                    ORoom: {
                        LRoom: rooms
                    }
                }
            ],
            OPassengers: {
                LPassenger: passengers
            }
        };
        return this.http.post<any>(config.getSearchHoltelDetailUrl('Product/SearchRoomByHotel'), body, httpOptions);
    }

    validationHotel(cityCode: string, hotelCode: string, productID: string) {
        // httpOptions.headers = new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,
      
        //   });
        const body = {
            LHotel: [
                {
                    TripProductID: productID
                }
            ],
            CityCode: cityCode,
            HotelCode: hotelCode
        };
        return this.http.post<any>(config.getValidationHotel('Product/Validation'), body, httpOptions);
    }

    bookingProduct(cityCode: string,productID: string, lroom: any[], lpass: any[]) {
        let guest = [];
        const rooms = [];
        // httpOptions.headers = new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,
      
        //   });
        for (let index = 0; index < lroom.length; index++) {
            const element = lroom[index];
            element.lGuest.forEach(element1 => {
                const passenger = {
                    PassengerSequence: element1.passengerSequence
                }
                guest.push(passenger);
            });
            const room = {
                RoomType: element.type,
                LGuest : guest
            }
            rooms.push(room);
            guest = [];
        }
        const body = {
            LHotel: [
                {
                    TripProductID: productID,
                    HotelCityCode: cityCode,
                    ORoom:
                    {
                        LRoom: rooms
                    }
                }
            ],
            OPassengers:
            {
                LPassenger: lpass
            }
        };
        return this.http.post<any>(config.getBookingProduct('Product/BookingProduct'), body, httpOptions);
    }

    CancelationFees(ProductID: string) {
        // httpOptions.headers = new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,
      
        //   });
        const body = {
            LHotel:
            [
                {
                    TripProductID: ProductID,

                }
            ],
        };
        return this.http.post<any>(config.getCancelationFees('Product/CancelationFees'), body, httpOptions);
    }

    GenerateHotelReservation(transactionCode: any, OreservationHotelBooking: any){
        // httpOptions.headers = new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,
      
        //   });
        const body = {
            TransactionCode: transactionCode,
            OReservationHotelBooking: OreservationHotelBooking
        };
        return this.http.post<any>(config.getGenerateHotelReservation('FlightHotelTrans/GenerateHotelReservation'), body, httpOptions);
    }

    QueuePnr(pnr: string) {
        // httpOptions.headers = new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,
      
        //   });
        const body = {
            PNR: pnr,
            Pseudo: 'LIMPE2235'
        };
        return this.http.post<any>(config.getGenerateHotelReservation('Booking/QueuePnr'), body, httpOptions);
    }

    ReserveRoom(valor) {
        let data = this.sessionService.getSelectRoom();
        let hotel = this.sessionService.getInfoHabi();
        let habi = this.sessionService.getValidRoom();
        let pass = this.sessionService.getFlightBooking();
        const rooms = [];
        const passengers = [];
        const phones = [];
        const emails = [];

        let i = 0;

        // httpOptions.headers = new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'Ocp-Apim-Subscription-Key': environment.subsKey,
      
        //   });

       /*  passengers.push({
            TypePassenger: 'ADT',
            RoomQuantity: filter.rooms,
            NumberPassengers: filter.passengers
        }); */

        pass.adults.forEach(element => {
            phones.push(element.phone);
        });

        pass.adults.forEach(element => {
            i++;
            let fec = element.date;
            fec = fec.split('T'); 
            fec = fec[0];
            fec = fec.split('-');
            fec = fec[0] + '/' + fec[1] + '/' + fec[2];
            const doc = {
                Number: element.documentNumber,
                Type: element.documentType
            }
            const passenger = {
                BirthDate: fec,
                Gender: element.gender,
                IsVIP: false,
                LastName: element.lastname,
                Name: element.name,
                Odocument: doc,
                PassengerId: i,
                PersonId: null,
                Prefix: null,
                Type: 'ADT',
                UserId: null,
            }
            passengers.push(passenger);
        });

        pass.adults.forEach(element => {
            emails.push(element.mail);
        });

        const contact = {
            EmailAddress: pass.contactMail,
            Name: pass.contactName,
            Numberphone: pass.contactPhone
        }
        let lati;
        let longi;
        if(hotel.ohotel.oposition != null){
            lati = hotel.ohotel.oposition.latitude;
            longi = hotel.ohotel.oposition.longitude;
        } else {
            lati = 0;
            longi = 0;
        }

        const objHotel = {
            CityCode: data.ohotel.cityCode,
            Hotelcode: data.ohotel.code,
            HotelName: hotel.ohotel.hotelName,
            Latitude: lati,
            Longitude: longi,
            Starts: hotel.ohotel.stars,
            Lamenities: hotel.ohotel.lamenities,
            TypeDistance: hotel.ohotel.oairportDistance.type,
            Distance: hotel.ohotel.oairportDistance.distance,
            Address: hotel.ohotel.address,
            Limagens: hotel.ohotel.limagens,
        }

        const objRoom = {
            Name: data.oroom.name,
            Description: data.oroom.description,
            GuaranteeText: data.oroom.guarantee,
            NumberNights: hotel.ohotel.numberNights,
            CheckIn: data.oroom.checkIn,
            CheckOut: data.oroom.checkOut,
            BookingCode: data.oroom.bookingCode,
            CorporateCode: data.ohotel.chainCode,
            Lamenities: habi.lamenities,
        }

        let objCard = null;

        if (valor === null) {
            objCard = null;
        } else {
            objCard = {
                CardType: valor.CardType,
                CardNumber: valor.CardNumber,
                SecurityId: valor.SecurityId,
                ExpiryDate: valor.ExpiryDate,
                HolderName: valor.HolderName
            }
        }

       

        const body = {
            UserId: null,
            TypeSearch: 'V',
            Pseudo: hotel.pseudo,
            GDS: hotel.gds,
            Ocompany: null,
            osession: data.osession,
            Phones: phones,
            Emails: emails,
            LPassenger: passengers,
            StartDate: hotel.ohotel.startDate,
            EndDate: hotel.ohotel.endDate,
            NumberPassengers: hotel.ohotel.numberPassenger,
            OHotel: objHotel,
            ORoom: objRoom,
            LcancelPenalties: data.oroom.lcancelPenalties,
            OcreditCard: objCard,
            OInformationContact: contact,
            opriceNewPrice: habi.oprice,
        };
        return this.http.post<any>(config.getSelectRoom('Booking/GenerateReservation'), body, httpOptions);
    }
}
