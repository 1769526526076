import { Component, Inject, OnInit, ViewChildren } from '@angular/core';
import { MatAccordion, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from '../../models/dialog-data.model';

@Component({
  selector: 'app-dialog-destinies',
  templateUrl: './dialog-destinies.component.html',
  styleUrls: ['./dialog-destinies.component.css']
})
export class DialogDestiniesComponent implements OnInit {

  listaDestinie = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  @ViewChildren(MatAccordion) accordion: MatAccordion;
  ngOnInit() {
    this.llenarDestinos(this.data);
    console.log(this.data);
  }

  llenarDestinos(list: any){
    list.lcountryInformation.forEach(element => {
      element.ldestination.forEach(dest => {
        this.listaDestinie.push(dest);
      });
    });
  }

  abrir(link){
    window.open(link, "_blank");
  }

}
