import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Filter } from '../dm-autocomplete/shared/ac-filter.model';


@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dm-custom-autocomplete',
  templateUrl: './dm-custom-autocomplete.component.html',
  styleUrls: ['./dm-custom-autocomplete.component.css']
})
export class DmCustomAutocompleteComponent implements OnInit {

  @Input() label: string;
  @Input() placeHolder: string = '';
  @Input() srcImg: string;
  @Input() list: Filter[];
  @Input() autocompleteControl: FormControl;
  @Input() theme: string;
  @Output() inputChange = new EventEmitter<string>();
  @Output() itemSelected = new EventEmitter();

  constructor() { }

  ngOnInit() {
    if (!this.autocompleteControl) {
      this.autocompleteControl = new FormControl('');
    }
    this.subscribeInput();
  }

  subscribeInput() {
    this.autocompleteControl.valueChanges.subscribe(
      term => {
          this.inputChange.emit(term);
      }
    );
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    // const item = event.option.value as Filter;
    this.itemSelected.emit(event.option.value);
  }

  display(item?: Filter): string | undefined {
    return item ? (item.name) : undefined;
  }

  clear() {
    this.autocompleteControl.setValue('');
    this.itemSelected.emit();
  }

}
