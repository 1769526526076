import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-item-flight-international',
  templateUrl: './item-flight-international.component.html',
  styleUrls: ['./item-flight-international.component.css']
})
export class ItemFlightInternationalComponent implements OnInit {


  @Input() listadoDinamicoI: any[];
  @Output() onSelect = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  goToVuelos(item) {
 
    this.onSelect.emit(item);
  }

}
