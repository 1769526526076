import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogSocialLoginComponent } from './dialog-social-login/dialog-social-login.component';
import { MaterialModule } from '../shared/material.module';
import { DialogCorreoComponent } from './dialog-social-login/dialog-correo/dialog-correo.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { DialogCorreoExistComponent } from './dialog-social-login/dialog-correo-exist/dialog-correo-exist.component';
import { DialogResetPasswordComponent } from './dialog-social-login/dialog-reset-password/dialog-reset-password.component';
import { DialogUpdatePasswordComponent } from './dialog-social-login/dialog-update-password/dialog-update-password.component';
import { DialogValidPhoneComponent } from './dialog-social-login/dialog-valid-phone/dialog-valid-phone.component';
import { CodeInputModule } from 'angular-code-input';
import { DialogFinishComponent } from './dialog-social-login/dialog-finish/dialog-finish.component';
import { LoginAccessComponent } from './login/login-access/login-access.component';
import { LoginInitialComponent } from './login/login-initial/login-initial.component';
import { LoginLoadingComponent } from './login/login-loading/login-loading.component';
import { LoginRegisterComponent } from './login/login-register/login-register.component';
import { LoginComponent } from './login/login.component';
import { AuthRoutingModule } from './auth-routing.module';
import { DialogPartnerComponent } from '../shared/components/dialog-partner/dialog-partner.component';
import { SharedModule } from '../shared/shared.module';
import { DialogExistsGoogleComponent } from './dialog-social-login/dialog-exists-google/dialog-exists-google.component';
import { DialogCodeVerificationComponent } from './dialog-social-login/dialog-code-verification/dialog-code-verification.component';
import { DialogConfirmPhoneComponent } from './dialog-social-login/dialog-confirm-phone/dialog-confirm-phone.component';
import { DialogRegisterComponent } from './dialog-social-login/dialog-register/dialog-register.component';
import { DialogFinishPasswordComponent } from './dialog-social-login/dialog-finish-password/dialog-finish-password.component';
import { FinishComponent } from '../shared/components/dialog-valid-product/finish/finish.component';
import { CodeVerificationComponent } from '../shared/components/dialog-valid-product/code-verification/code-verification.component';
import { ConfirmPhoneComponent } from '../shared/components/dialog-valid-product/confirm-phone/confirm-phone.component';
import { RegisterGoogleComponent } from '../shared/components/dialog-valid-product/register-google/register-google.component';
import { RegisterComponent } from '../shared/components/dialog-valid-product/register/register.component';
import { ValidPhoneComponent } from '../shared/components/dialog-valid-product/valid-phone/valid-phone.component';
import { DialogRegisterGoogleComponent } from './dialog-social-login/dialog-register-google/dialog-register-google.component';
import { ResetPasswordComponent } from '../shared/components/dialog-valid-product/reset-password/reset-password.component';
import { CorreoExistsComponent } from '../shared/components/dialog-valid-product/correo-exists/correo-exists.component';
import { SocialLoginComponent } from '../shared/components/dialog-valid-product/social-login/social-login.component';
import { ExistsGoogleComponent } from '../shared/components/dialog-valid-product/exists-google/exists-google.component';
import { CorreoComponent } from '../shared/components/dialog-valid-product/correo/correo.component';
import { DialogItineraryComponent } from '../shared/components/dialog-itinerary/dialog-itinerary.component';
import { DialogViewContentComponent } from '../shared/components/dialog-view-content/dialog-view-content.component';
import { DialogRequisitosComponent } from '../shared/components/dialog-requisitos/dialog-requisitos.component';
import { DialogDestiniesComponent } from '../shared/components/dialog-destinies/dialog-destinies.component';
import { DialogVideosComponent } from '../shared/components/dialog-videos/dialog-videos.component';
import { DialogCipNotificationComponent } from '../shared/components/dialog-cip-notification/dialog-cip-notification.component';
import { DialogCancelationFeesComponent } from '../shared/components/dialog-cancelation-fees/dialog-cancelation-fees.component';
import { DialogHabitacionComponent } from '../shared/components/dialog-habitacion/dialog-habitacion.component';
import { DialogPriceUpdatedComponent } from '../shared/components/dialog-price-updated/dialog-price-updated.component';
import { DialogCancelationFlightComponent } from '../shared/components/dialog-cancelation-flight/dialog-cancelation-flight.component';
import { DialogRedirectionComponent } from '../shared/components/dialog-redirection/dialog-redirection.component';
import { DialogPhoneContactComponent } from '../shared/components/dialog-phone-contact/dialog-phone-contact.component';
import { AccountComponent } from './account/account.component';
import { CouponComponent } from './coupon/coupon.component';
import { CouponRegisterComponent } from './coupon/coupon-register/coupon-register.component';
import { CouponValidatePhoneComponent } from './coupon/coupon-validate-phone/coupon-validate-phone.component';
import { CouponEmailComponent } from './coupon/coupon-email/coupon-email.component';
import { CouponEmailExistsComponent } from './coupon/coupon-email-exists/coupon-email-exists.component';
import { DialogSocialLogComponent } from './dialog-social-login/dialog-social-log/dialog-social-log.component';
import { CouponCodeLoginComponent } from './coupon/coupon-code-login/coupon-code-login.component';
import { CouponConfirmPhoneComponent } from './coupon/coupon-confirm-phone/coupon-confirm-phone.component';
import { DialogRegisterCodeComponent } from './dialog-social-login/dialog-register-code/dialog-register-code.component';
import { DialogLoginPhoneComponent } from './dialog-social-login/dialog-login-phone/dialog-login-phone.component';

@NgModule({
  declarations: [
    DialogSocialLoginComponent,
    DialogCorreoComponent,
    DialogCorreoExistComponent,
    DialogResetPasswordComponent,
    DialogUpdatePasswordComponent,
    DialogValidPhoneComponent,
    DialogFinishComponent,
    LoginAccessComponent,
    LoginInitialComponent,
    LoginRegisterComponent,
    LoginComponent,
    LoginLoadingComponent,
    DialogExistsGoogleComponent,
    DialogCodeVerificationComponent,
    DialogConfirmPhoneComponent,
    DialogRegisterComponent,
    DialogFinishPasswordComponent,
    FinishComponent,
    CodeVerificationComponent,
    ConfirmPhoneComponent,
    RegisterGoogleComponent,
    RegisterComponent,
    ValidPhoneComponent,
    DialogRegisterGoogleComponent,
    
    DialogItineraryComponent,
    // DialogViewContentComponent,
    // DialogRequisitosComponent,
    // DialogDestiniesComponent,
    // DialogVideosComponent,
    DialogCipNotificationComponent,
    DialogCancelationFeesComponent,
    DialogHabitacionComponent,
    DialogPriceUpdatedComponent,
    DialogCancelationFlightComponent,
    DialogRedirectionComponent,
    DialogPhoneContactComponent,
    CouponComponent,
    AccountComponent,
    CouponRegisterComponent,
    CouponValidatePhoneComponent,
    CouponEmailComponent,
    CouponEmailExistsComponent,
    DialogSocialLogComponent,
    CouponCodeLoginComponent,
    CouponConfirmPhoneComponent,
    DialogRegisterCodeComponent,
    DialogLoginPhoneComponent

  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    MaterialModule,
    NgxSpinnerModule,
    CodeInputModule
  ],
  exports: [
    DialogSocialLoginComponent,
    DialogCorreoComponent,
    DialogLoginPhoneComponent,
    DialogCorreoExistComponent,
    DialogCodeVerificationComponent,
    DialogResetPasswordComponent,
    DialogUpdatePasswordComponent,
    DialogRegisterGoogleComponent,
    DialogValidPhoneComponent,
    DialogFinishPasswordComponent,
    DialogFinishComponent,
    AccountComponent,
    DialogRegisterCodeComponent,
    DialogRegisterComponent,
    DialogConfirmPhoneComponent,
    ValidPhoneComponent,
  ],
  entryComponents: [
    DialogPartnerComponent
  ]
})
export class AuthModule { }
