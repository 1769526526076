import { Pipe, PipeTransform } from '@angular/core';
import * as config from '../../core/config';
import { environment } from '../../../environments/environment';

@Pipe({name: 'dmUrlAirlineIcon'})
export class UrlAirlineIconPipe implements PipeTransform {
    transform(value: string, params?: string[]): string {
        return environment.urlIcons + 'airlines/' + value + '.png';
  }
}
