import { Component, OnInit, Input, Output, EventEmitter, ElementRef, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { Package } from '../../packages/shared/models/package.model';
import { Category } from '../../packages/shared/models/category.model';
import { SessionService } from 'src/app/core/services/session.service';
import { FlightService } from 'src/app/core/services/flight.service';
import { parseDate } from 'ngx-bootstrap/chronos';
import * as moment from 'moment';
import { DateInput } from 'ngx-bootstrap/chronos/testing/chain';
import { PackageService } from 'src/app/core/services/package.service';
import { HotelService } from 'src/app/core/services/hotel.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dm-package-showcase',
  templateUrl: './package-showcase.component.html',
  styleUrls: ['./package-showcase.component.css'],
  providers: [ HotelService ]
})
export class PackageShowcaseComponent implements OnInit, OnChanges {
  isPhone = false;
  enviarshow= false;
  @Input() showPackage: boolean;
  sectionPackage: any;
  sectionVuelos: any;
  sectionVueloHotel: any;
  sectionVueloN: any;
  posicion: number = 1; // Asigna el valor inicial de la posición
  getDivOrder(pos: number): number {
    return pos - 1;
  }
  listadoDinamico : any[] = [];
  listadoDinamico2: any[] = [];
  ldestinations :any[] = [];
  ldestinationsHotel: any[] = [];
  @Input() categories: Category[];
  @Input() lstP: any[];
  @Input() lst: any[];
  @Input() origenes: any[];
  /* @Input() title: string; */
  validVH = false;
  @Input() load;
  loadingPackages= true;
  validVP = false;
  validV= false;
  idload;
  validVueN = false;
  hoteles = [];
  VHotels = [];
  listadoDinamicoN: any[] = [];
  @Input() buscar: any[];
  @Input() mostrarTodo;
  @Input() buscarDe;
  @Input() filterPackage: boolean;
  @Output() selectItem = new EventEmitter<Package>();
  @Output() selectVH = new EventEmitter<any>();
  

  constructor(private elementRef: ElementRef,private session: SessionService, private flightService: FlightService,private hotelService: HotelService, private packageService: PackageService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.mostrarTodo != undefined && changes.mostrarTodo != null){
    let valor = changes.mostrarTodo.currentValue;
    if(valor === "Hoteles" && this.ldestinationsHotel === undefined || valor === "Hoteles" && this.ldestinationsHotel === null || valor === "Hoteles" && this.ldestinationsHotel.length === 0){
      this.getDynamicHotel();
    }
  }
  }

  ngOnInit() {
    if(this.lstP === undefined){
      this.getDynamicSearchP();
    }
    if(this.lst === undefined){
      this.getDynamicSearchV();
    }
    this.getDynamicSearch();
     /* this.listadoDinamico2 = this.buscar;
    this.listadoDinamicoN = this.listadoDinamico2.filter(
      (x) => x.flightNational == true
    ); */

    // this.getDynamicSearch();
    this.validPhone();
    this.getListSection();
    console.log(this.mostrarTodo)
    
  }

  ngAfterViewInit() {
    
      let url = window.location.pathname;
      /* let quitar = url.substring(0, url.length - 1);
      let located = quitar.split("?"); */
      let locat = window.location.search;
      if(locat != ""){
      let locat2 = locat.split('?');
      let quitar2 = locat2[1].substring(0, locat2[1].length - 1);
      let urlpackage = [];
      if(url === "/pack"){
        this.categories.forEach(element => {
          element.lvacationPackage.forEach(pack => {
            if(pack.nameURL === quitar2){
              urlpackage.push(element);
            }
          })
        });
        setTimeout(() => {
          
        if(urlpackage.length > 0){
              document.body.style.overflowY = "hidden";
              const targetElement = this.elementRef.nativeElement.querySelector('#'+'p'+urlpackage[0].id);
              if(targetElement){
                targetElement.scrollIntoView({ behavior: 'smooth' });
                setTimeout(() => {
                  document.body.style.overflowY = "auto";
                }, 1500);
              }else {
                document.body.style.overflowY = "auto";

              }
        }

      }, 1000);

      }
    }

  }

  getListSection(){
    this.packageService.listSection().subscribe(
      x => {
        this.sectionPackage = x.ldata[0].priority;
        this.sectionVuelos = x.ldata[1].priority;
        this.sectionVueloN = x.ldata[2].priority;
        this.sectionVueloHotel = x.ldata[3].priority;

      },
      (error) => {
        console.log('error: ' + error);
        this.sectionPackage = 2;
        this.sectionVuelos = 1;
        this.sectionVueloN = 4;
        this.sectionVueloHotel = 3;
      },
      () => {
        
      }
    );
  }

  getDynamicSearchV() {
  
    this.flightService.getDynamicSearch().subscribe(
      (result: any) => {

        
        this.lst = result.ldata;
         this.listadoDinamico2 = result.ldata;
         this.loadingPackages = false;
        
      },
      (error) => {
        //console.log("ERROR: " + JSON.stringify(error));
      },
      () => {
        this.validVueN = true;
        this.listadoDinamicoN = this.listadoDinamico2.filter(
          (x) => x.flightNational == true
        );
    /*     this.listadoDinamicoN = this.listadoDinamico.filter(
          (x) => x.flightNational == true
        );

        this.listadoDinamicoI = this.listadoDinamico.filter(
          (x) => x.flightNational == false
        ); */
        
      }
    );
  

}


getDynamicHotel() {
   this.loadingPackages = true;
  this.hotelService.getDynamicSearchHotel("H").subscribe(
    (result: any) => {
      if (result.status === 200) {
        this.getHotelsIataCode(result.ldata);
          /* this.ldestinationsHotel = result.ldata; */
          

          this.hoteles.forEach(element => {
            const datos = {
              iataCode: element.id,
              cityName: element.description,
              hotels: this.getHotels(element.id, result.ldata),
            }
            this.ldestinationsHotel.push(datos);
          });

          console.log("hoteles filtrados", this.ldestinationsHotel)
        this.loadingPackages = false;
        this.load = ""
        // let locat = window.location.hash;
        // if (locat === '#/hoteles') {
        //   const targetElement = this.elementRef.nativeElement.querySelector('#hotelesDynamicos');
        //   targetElement.scrollIntoView({ behavior: 'smooth' });
        // }

      }

    },
    (error) => {
      //console.log("ERROR: " + JSON.stringify(error));
    },
    () => {
    }
  );
}

getHotelsIataCode(data) {
  let ids = [];
  this.hoteles = [];
  data.forEach((hotel) => {
    if (!ids.includes(hotel.odestination.iataCode)) {
      this.hoteles.push({
        id: hotel.odestination.iataCode,
        description: hotel.odestination.cityName,
      });
      ids.push(hotel.odestination.iataCode);
    }
  });
}

getHotels(iataCode, data){
  let hotels = []
  data.forEach(element => {
    if(element.odestination.iataCode === iataCode){
      hotels.push(element);
    }
  });

  return hotels;
}


  getDynamicSearchP() {
    this.flightService.getDynamicSearchAirline("V", true).subscribe(
      (result: any) => {


        this.lstP = result.ldata;

      },
      (error) => {
        //console.log("ERROR: " + JSON.stringify(error));
      },
      () => {
        /* this.listadoDinamicoI = this.listadoDinamico; */
      /*   this.listadoDinamicoSP = this.listadoDinamico.filter(
          (x) => x.flightNational == true
        );

        this.listadoDinamicoIP = this.listadoDinamico.filter(
          (x) => x.flightNational == false
        ); */
        this.validVP = true;
        this.loadingPackages = false;
      
      }
    );
  }

  selectPackage(pack: Package) {
    this.selectItem.emit(pack);
  }

  validPhone(){
    if( navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)){
      this.isPhone = true;
    }
  }

  selectVueloHotel(valor){
    this.selectVH.emit(valor);
  }

  
  // private scrollDownByPercentage(percentage: number) {
  //   const mainContentElement = document.getElementById('hotelesDynamicos');
  //   if (mainContentElement) {
  //     const totalHeight = mainContentElement.scrollHeight;
  //     const scrollTo = (totalHeight * percentage) / 100;
  //     window.scrollTo(0, scrollTo);
  //   }
  // }

  getDynamicSearch() {
    this.hotelService.getDynamicSearchHotel("VH").subscribe(
      (result: any) => {
        //console.log("result");
        //console.log(JSON.stringify(result));
        
        this.listadoDinamico = result.ldata;
        this.session.setVuelosHotels(this.listadoDinamico);
        this.getVHNameCity(result.ldata);
        this.session.setFilterVH(this.VHotels);
        this.loadingPackages = false;
        // const targetElement = this.elementRef.nativeElement.querySelector('#todo');
        //     targetElement.scrollIntoView({ behavior: 'smooth' });
      },
      (error) => {
        //console.log("ERROR: " + JSON.stringify(error));
      },
      () => {
        this.validVH = true;
      }
    );
  }

  getVHNameCity(data) {
    let ids = [];
    this.VHotels = [];
    data.forEach((hotel) => {
      if (!ids.includes(hotel.odestination.cityName)) {
        this.VHotels.push({
          id: hotel.odestination.iataCode,
          name: hotel.odestination.cityName,
          type: ""
        });
        ids.push(hotel.odestination.cityName);
      }
    });
  }

  selectCate(valor) {
    console.log(valor);
    if(valor.promotionalCode === true){
      this.session.setPromotionalCode(true);
    } else {
      this.session.setPromotionalCode(false);
    }
  }

}
