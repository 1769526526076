import { Component, OnInit, Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PackageService } from 'src/app/core/services/package.service';
import { SessionService } from 'src/app/core/services/session.service';
import { DialogValidProductComponent } from '../dialog-valid-product.component';
import * as crypto from 'crypto-js';
import { DialogFinishComponent } from 'src/app/auth/dialog-social-login/dialog-finish/dialog-finish.component';

@Component({
  selector: 'app-code-verification',
  templateUrl: './code-verification.component.html',
  styleUrls: ['./code-verification.component.css'],
  providers: [PackageService]
})

export class CodeVerificationComponent implements OnInit {
  correoExist;
  validcode;
  codigo;
  cunt;
  codeagain;
  numero;
  validcunt = false;
  constructor( private router: Router,private spinner: NgxSpinnerService,private snackBar: MatSnackBar,private packageService: PackageService, private sessionService: SessionService,public dialog: MatDialog
    , public dialogRef: MatDialogRef<CodeVerificationComponent>, @Inject(MAT_DIALOG_DATA) public data: string) { dialogRef.disableClose = true;}

  ngOnInit() {
    this.numero = this.sessionService.getPhone();
  }

  onCodeCompleted(code: string) {
    //console.log(code);
    this.codigo = crypto.SHA256(code).toString();
    if(this.data === this.codigo){
      this.validcode = true;

    } else {
      this.validcode = false;
    }
    
  }

  startCountDown(seconds) {
    this.cunt = 0;
    var counter = seconds;
    var interval = setInterval(() => {
      counter--;
      this.cunt = counter;
      if (counter === 0) {
        let adas = document.getElementById("again");
        adas.style.pointerEvents = "visible";
        this.validcunt = false;
      }

    }, 1000);
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 5000,
    });
  }

  sendAgain(){
    this.spinner.show();
    this.packageService.volverEnviar(this.numero,1).subscribe(
      x=>{
        //console.log(x);
        if(x.status === 200){
          this.data = x.code;
          this.openSnackBar(x.message);
          let adas = document.getElementById("again");
          adas.style.pointerEvents = "none";
          
          this.startCountDown(60);
          this.validcunt = true;
          this.onCodeCompletedAgain();
        }
        this.spinner.hide();
      }
    )
  }

  onClose() {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(DialogValidProductComponent, {
      data: {
        value: '',
        sesion: false,
      },
      disableClose: true,

      panelClass: 'custom-dialog-containers',
      maxWidth: 800
    });
  }

  validarcodigo(){
    if(this.validcode){

      this.openDialogExiste();
    } else {
      return;
    }
  }

  onCodeCompletedAgain() {
    if(this.data === this.codigo){
      this.validcode = true;

    } else {
      this.validcode = false;
    }
    
  }

  openDialogExiste() {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(DialogFinishComponent, {
      data: 'result',
      panelClass: 'custom-dialog-container',
      maxWidth: 800,
    
    });

    dialogRef.afterClosed().subscribe(result => {
      /* //console.log('The dialog was closed'); */
      if (result) {
        this.correoExist = result;
      }
    });
  }

}