import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Update } from '../../models/update.model';

@Component({
  selector: 'app-dialog-cancelation-fees',
  templateUrl: './dialog-cancelation-fees.component.html',
  styleUrls: ['./dialog-cancelation-fees.component.css']
})
export class DialogCancelationFeesComponent implements OnInit {
  name;
  dataShow;
  constructor(public dialogRef: MatDialogRef<DialogCancelationFeesComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Update) { }

  ngOnInit() {

  }

  onClose() {
    this.dialogRef.close();
  }

}
