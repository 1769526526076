export const airportsData = {
    "lcities": [
        {
            "iataCode": "GZP",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Alanya",
            "name": "[GZP] - Alanya - Turkey",
            "searchName": "GZP-Gazipa-Alanya-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "IEV",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Kiev",
            "name": "[IEV] - Kiev - Ukraine",
            "searchName": "IEV-Kiew-Kiev-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "LED",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Saint Petersburg",
            "name": "[LED] - Saint Petersburg - Russia",
            "searchName": "LED-Sankt Petersburg-Saint Petersburg-San Petersburgo-Saint-P-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NCE",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Nice",
            "name": "[NCE] - Nice - France",
            "searchName": "NCE-Nizza-Nice-Niza-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "GVA",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Geneva",
            "name": "[GVA] - Geneva - Switzerland",
            "searchName": "GVA-Genf-Geneva-Ginebra-Gen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ACE",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Arrecife",
            "name": "[ACE] - Arrecife - Spain",
            "searchName": "ACE-Lanzarote-Arrecife-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "BER",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Berlin",
            "name": "[BER] - Berlin - Germany",
            "searchName": "BER-Berlin-Berl-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ROV",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Rostov",
            "name": "[ROV] - Rostov - Russia",
            "searchName": "ROV-Rostov On Don-Rostov-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "PKC",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Petropavlovsk-Kamchats",
            "name": "[PKC] - Petropavlovsk-Kamchats - Russia",
            "searchName": "PKC-Petropavlovsk-Kamchats-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "CLJ",
            "countryCode": "RO",
            "cityCode": null,
            "cityName": "Cluj-Napoca",
            "name": "[CLJ] - Cluj-Napoca - Romania",
            "searchName": "CLJ-Cluj Napoca-Cluj-Napoca-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "TLV",
            "countryCode": "IL",
            "cityCode": null,
            "cityName": "Tel Aviv Yafo",
            "name": "[TLV] - Tel Aviv Yafo - Israel",
            "searchName": "TLV-Tel Aviv Yafo-Tel Aviv-Yafo-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "KIV",
            "countryCode": "MD",
            "cityCode": null,
            "cityName": "Chisinau",
            "name": "[KIV] - Chisinau - Moldova",
            "searchName": "KIV-Chisinau-Moldova-Moldàvia-Moldavsko-Moldawia-Moldavien-Moldovos-Moldavija-Moldawien-Moldavia-Moldavie",
            "priority": 0
        },
        {
            "iataCode": "BEG",
            "countryCode": "RS",
            "cityCode": null,
            "cityName": "Belgrade",
            "name": "[BEG] - Belgrade - Serbia",
            "searchName": "BEG-Belgrad-Belgrade-Belgrado-Szerbia-Sèrbia-Sirbistan-Srbsko-Serbia-Serbien-Srbija-Serbija-Serbie--Suudi Arabistan",
            "priority": 0
        },
        {
            "iataCode": "ZRH",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Zurich",
            "name": "[ZRH] - Zurich - Switzerland",
            "searchName": "ZRH-Z-Zurich-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "FRA",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Frankfurt",
            "name": "[FRA] - Frankfurt - Germany",
            "searchName": "FRA-Frankfurt am Main-Frankfurt-Francfort-Francoforte-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "HEL",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Helsinki",
            "name": "[HEL] - Helsinki - Finland",
            "searchName": "HEL-Helsinki-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "DUS",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Dusseldorf",
            "name": "[DUS] - Dusseldorf - Germany",
            "searchName": "DUS-D-Dusseldorf-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "OSL",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Oslo",
            "name": "[OSL] - Oslo - Norway",
            "searchName": "OSL-Oslo-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "PMI",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Palma Mallorca",
            "name": "[PMI] - Palma Mallorca - Spain",
            "searchName": "PMI-Palma de Mallorca-Palma Mallorca-Mallorca (Palma)-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "LCA",
            "countryCode": "CY",
            "cityCode": null,
            "cityName": "Larnaca",
            "name": "[LCA] - Larnaca - Cyprus",
            "searchName": "LCA-Larnaka-Larnaca-Citium-Ciprus-Cypr-Siprus-Cyprus-Síp-Kipra-Kypros-Küpros-Cipar-Xipre-Kypr-Cypern-Chipre-Zypern-Chypre-Kibris-Cipru-Kipras-Cipro",
            "priority": 0
        },
        {
            "iataCode": "BRU",
            "countryCode": "BE",
            "cityCode": null,
            "cityName": "Bruselas",
            "name": "[BRU] - Bruselas - Belgium",
            "searchName": "BRU-Br-Bruselas-Belgia-Belgium-Belgien-België-Bélgica-Belgie-Belçika-Bèlgica-Belgique-Belgio",
            "priority": 0
        },
        {
            "iataCode": "RIX",
            "countryCode": "LV",
            "cityCode": null,
            "cityName": "Riga",
            "name": "[RIX] - Riga - Latvia",
            "searchName": "RIX-Riga-Lettország-Letònia-Letonya-Lotyšsko-Latvia-Letland-Lotwa-Läti-Latvija-Lát via-Letonia-Lettland-Lettonia-L-Lettonie",
            "priority": 0
        },
        {
            "iataCode": "ZAG",
            "countryCode": "HR",
            "cityCode": null,
            "cityName": "Zagreb",
            "name": "[ZAG] - Zagreb - Croatia",
            "searchName": "ZAG-Zagreb-Zagabria-Kroatië-Kroatia-Chorvatsko-Hirvatistan-Croàcia-Horvátország-Croatia-Hrvaška-Kroatija-Horvatija-Hrvatska-Horvaatia-Chorwacja-Kroasia-Kroatien-Croatie-Croacia-Croazia",
            "priority": 0
        },
        {
            "iataCode": "ODS",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Odessa",
            "name": "[ODS] - Odessa - Ukraine",
            "searchName": "ODS-Odessa-Odesa-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "NUE",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Nürnberg",
            "name": "[NUE] - Nürnberg - Germany",
            "searchName": "NUE-N-Nürnberg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "CPH",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Copenhagen",
            "name": "[CPH] - Copenhagen - Denmark",
            "searchName": "CPH-Kastrup-Copenhagen-Copenhague-Copenaghen-Copenhag-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "LJU",
            "countryCode": "SI",
            "cityCode": null,
            "cityName": "Ljubljana",
            "name": "[LJU] - Ljubljana - Slovenia",
            "searchName": "LJU-Ljubljana-Liubliana-Lubiana-Ljubliana-Slovenia-Slovénie-Slovenien-Slovinsko-Slovenya-Eslovènia-Szlovénia-Slovenija-Sloveenia--Slowenien-Eslovenia",
            "priority": 0
        },
        {
            "iataCode": "HAV",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "La Habana",
            "name": "[HAV] - La Habana - Cuba",
            "searchName": "HAV-Havanna-Havana-La Habana-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "LUX",
            "countryCode": "LU",
            "cityCode": null,
            "cityName": "Luxembourg",
            "name": "[LUX] - Luxembourg - Luxembourg",
            "searchName": "LUX-Luxemburg-Luxembourg-Luxemburgo-Lussemburgo-L-Lúc xam bua-Lusse-Lüksemburg",
            "priority": 0
        },
        {
            "iataCode": "AER",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Sochi",
            "name": "[AER] - Sochi - Russia",
            "searchName": "AER-Adler-Sochi-Adler\\/Sochi-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "LDY",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Derry",
            "name": "[LDY] - Derry - United Kingdom",
            "searchName": "LDY-Londonderry-Derry-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BUH",
            "countryCode": "RO",
            "cityCode": null,
            "cityName": "Bucharest",
            "name": "[BUH] - Bucharest - Romania",
            "searchName": "BUH-Bucharest-Bucarest-B-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "CBR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Canberra",
            "name": "[CBR] - Canberra - Australia",
            "searchName": "CBR-Canberra-Camberra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MAN",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Manchester",
            "name": "[MAN] - Manchester - United Kingdom",
            "searchName": "MAN-Manchester-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "KUF",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Samara",
            "name": "[KUF] - Samara - Russia",
            "searchName": "KUF-Samara-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "AYT",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Antalya",
            "name": "[AYT] - Antalya - Turkey",
            "searchName": "AYT-Antalya-Adalia-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "ATH",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Athens",
            "name": "[ATH] - Athens - Greece",
            "searchName": "ATH-Athen-Athens-Atenas-Ath-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "FAO",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Faro",
            "name": "[FAO] - Faro - Portugal",
            "searchName": "FAO-Faro-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "SIP",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Simferopol",
            "name": "[SIP] - Simferopol - Ukraine",
            "searchName": "SIP-Simferopol-Akmescit-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "SVX",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Ekaterinburg",
            "name": "[SVX] - Ekaterinburg - Russia",
            "searchName": "SVX-Ekaterinburg-Iekaterinbourg-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "STO",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Stockholm",
            "name": "[STO] - Stockholm - Sweden",
            "searchName": "STO-Stockholm-Estocolmo-Stoccolma-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "PIZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Point Lay",
            "name": "[PIZ] - Point Lay - United States",
            "searchName": "PIZ-Point Lay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SCL",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Santiago",
            "name": "[SCL] - Santiago - Chile",
            "searchName": "SCL-Santiago-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "HOU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Houston",
            "name": "[HOU] - Houston - United States",
            "searchName": "HOU-Houston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DEL",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "New Delhi",
            "name": "[DEL] - New Delhi - India",
            "searchName": "DEL-Neu-Delhi-New Delhi-Nueva Delhi-Nuova Delhi-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "DPS",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Denpasar",
            "name": "[DPS] - Denpasar - Indonesia",
            "searchName": "DPS-Denpasar Bali-Denpasar-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ALG",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Algiers",
            "name": "[ALG] - Algiers - Algeria",
            "searchName": "ALG-Algier-Algiers-Argel-Alger-Algeri-Cezayir-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija",
            "priority": 0
        },
        {
            "iataCode": "BPN",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Balikpapan",
            "name": "[BPN] - Balikpapan - Indonesia",
            "searchName": "BPN-Balikpapan-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BOM",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Mumbai",
            "name": "[BOM] - Mumbai - India",
            "searchName": "BOM-Mumbai (Maharashtra)-Mumbai-Mumbai (Bombay)-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "ALA",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Almaty",
            "name": "[ALA] - Almaty - Kazakhstan",
            "searchName": "ALA-Almaty-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "MRS",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Marseille",
            "name": "[MRS] - Marseille - France",
            "searchName": "MRS-Marseille-Marsella-Marsiglia-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "VLC",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Valencia",
            "name": "[VLC] - Valencia - Spain",
            "searchName": "VLC-Valencia-Manises-Valence-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "KGD",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Kaliningrad",
            "name": "[KGD] - Kaliningrad - Russia",
            "searchName": "KGD-Kaliningrad-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "SSH",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Sharm el-Sheikh",
            "name": "[SSH] - Sharm el-Sheikh - Egypt",
            "searchName": "SSH-Scharm El-Scheich-Sharm el-Sheikh-Sheij-Charm el-Cheikh-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "ORN",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Oran",
            "name": "[ORN] - Oran - Algeria",
            "searchName": "ORN-Oran-Or-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "OPO",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Porto",
            "name": "[OPO] - Porto - Portugal",
            "searchName": "OPO-Porto-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "TIP",
            "countryCode": "LY",
            "cityCode": null,
            "cityName": "Tripoli",
            "name": "[TIP] - Tripoli - Libya",
            "searchName": "TIP-Tripoli-Tr-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "OVB",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Novosibirsk",
            "name": "[OVB] - Novosibirsk - Russia",
            "searchName": "OVB-Nowosibirsk-Novosibirsk-Novossibirsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "CHI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chicago",
            "name": "[CHI] - Chicago - United States",
            "searchName": "CHI-Chicago-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MJV",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Murcia",
            "name": "[MJV] - Murcia - Spain",
            "searchName": "MJV-Murcia-Murcie-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "GOI",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Goa",
            "name": "[GOI] - Goa - India",
            "searchName": "GOI-Goa-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "TIV",
            "countryCode": "ME",
            "cityCode": null,
            "cityName": "Tivat",
            "name": "[TIV] - Tivat - Montenegro",
            "searchName": "TIV-Tivat-Teodo-Montenegro-Cerná Hora-Karadag-Montenegró-Monténégro-Muntenegru-Crna Gora-Juodkalnija-Melnkalne",
            "priority": 0
        },
        {
            "iataCode": "OMS",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Omsk",
            "name": "[OMS] - Omsk - Russia",
            "searchName": "OMS-Omsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "VVO",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Vladivostok",
            "name": "[VVO] - Vladivostok - Russia",
            "searchName": "VVO-Vladivostok-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "TGD",
            "countryCode": "ME",
            "cityCode": null,
            "cityName": "Podgorica",
            "name": "[TGD] - Podgorica - Montenegro",
            "searchName": "TGD-Podgorica-Montenegro-Cerná Hora-Karadag-Montenegró-Monténégro-Muntenegru-Crna Gora-Juodkalnija-Melnkalne",
            "priority": 0
        },
        {
            "iataCode": "VNO",
            "countryCode": "LT",
            "cityCode": null,
            "cityName": "Vilnius",
            "name": "[VNO] - Vilnius - Lithuania",
            "searchName": "VNO-Vilnius-Vilna-Lituânia-Litouwen-Lithuania-Litauen-Lituanie-Lituania-Litwa-Leedu-Li-Litvanya",
            "priority": 0
        },
        {
            "iataCode": "EDI",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Edinburgh",
            "name": "[EDI] - Edinburgh - United Kingdom",
            "searchName": "EDI-Edinburgh-Edimburgo-Edinbourg-Edinburg-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "TSE",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Astana",
            "name": "[TSE] - Astana - Kazakhstan",
            "searchName": "TSE-Astana-Astan-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "MLA",
            "countryCode": "MT",
            "cityCode": null,
            "cityName": "Gudja",
            "name": "[MLA] - Gudja - Malta",
            "searchName": "MLA-Malta-Gudja-Malte-Málta-Mal",
            "priority": 0
        },
        {
            "iataCode": "TYO",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Tokyo",
            "name": "[TYO] - Tokyo - Japan",
            "searchName": "TYO-Tokio-Tokyo-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "DOK",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Donetsk",
            "name": "[DOK] - Donetsk - Ukraine",
            "searchName": "DOK-Donetsk-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "KLV",
            "countryCode": "CZ",
            "cityCode": null,
            "cityName": "Karlovy Vary",
            "name": "[KLV] - Karlovy Vary - Czech Republic",
            "searchName": "KLV-Karlsbad-Karlovy Vary-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "HAM",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Hamburg",
            "name": "[HAM] - Hamburg - Germany",
            "searchName": "HAM-Hamburg-Hambourg-Amburgo-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "HRG",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Hurghada",
            "name": "[HRG] - Hurghada - Egypt",
            "searchName": "HRG-Hurghada-Hurgada-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "CEK",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Chelyabinsk",
            "name": "[CEK] - Chelyabinsk - Russia",
            "searchName": "CEK-Tscheljabinsk-Chelyabinsk-Cheli-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KRR",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Krasnodar",
            "name": "[KRR] - Krasnodar - Russia",
            "searchName": "KRR-Krasnodar-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "UFA",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Ufa",
            "name": "[UFA] - Ufa - Russia",
            "searchName": "UFA-Ufa-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "BOS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Boston",
            "name": "[BOS] - Boston - United States",
            "searchName": "BOS-Boston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UIO",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Quito",
            "name": "[UIO] - Quito - Ecuador",
            "searchName": "UIO-Quito-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "SKG",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Thessaloniki",
            "name": "[SKG] - Thessaloniki - Greece",
            "searchName": "SKG-Thessaloniki-Sal-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "MSQ",
            "countryCode": "BY",
            "cityCode": null,
            "cityName": "Minsk",
            "name": "[MSQ] - Minsk - Belarus",
            "searchName": "MSQ-Minsk-Weißrußland-Belarus-Beyaz Rusya-Bélarus-Bielorrusia-Bielorussia",
            "priority": 0
        },
        {
            "iataCode": "JKT",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Jakarta",
            "name": "[JKT] - Jakarta - Indonesia",
            "searchName": "JKT-Jakarta-Yakarta-Giakarta-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "GLA",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Glasgow",
            "name": "[GLA] - Glasgow - United Kingdom",
            "searchName": "GLA-Glasgow-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "CAS",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Casablanca",
            "name": "[CAS] - Casablanca - Morocco",
            "searchName": "CAS-Casablanca-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "ATL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Atlanta",
            "name": "[ATL] - Atlanta - United States",
            "searchName": "ATL-Atlanta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KZN",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Kazan",
            "name": "[KZN] - Kazan - Russia",
            "searchName": "KZN-Kazan-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "GOJ",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Nizhniy Novgorod",
            "name": "[GOJ] - Nizhniy Novgorod - Russia",
            "searchName": "GOJ-Nizhniy Novgorod-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "PEE",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Perm",
            "name": "[PEE] - Perm - Russia",
            "searchName": "PEE-Perm-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "CGN",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Cologne",
            "name": "[CGN] - Cologne - Germany",
            "searchName": "CGN-K-Cologne-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "BEY",
            "countryCode": "LB",
            "cityCode": null,
            "cityName": "Beirut",
            "name": "[BEY] - Beirut - Lebanon",
            "searchName": "BEY-Beirut-Beyrouth-Liibanon-Libanon-Lübnan-Líban-Liban-Libanas-Libana-Lebanon-Libano-Líbano",
            "priority": 0
        },
        {
            "iataCode": "BLQ",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Bologna",
            "name": "[BLQ] - Bologna - Italy",
            "searchName": "BLQ-Bologna-Bolonia-Bologne-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "PHL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Philadelphia",
            "name": "[PHL] - Philadelphia - United States",
            "searchName": "PHL-Philadelphia-Filadelfia-Philadelphie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VOG",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Volgograd",
            "name": "[VOG] - Volgograd - Russia",
            "searchName": "VOG-Volgograd-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "LYS",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Lyon",
            "name": "[LYS] - Lyon - France",
            "searchName": "LYS-Lyon-Lione-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "ETH",
            "countryCode": "IL",
            "cityCode": null,
            "cityName": "Eilat",
            "name": "[ETH] - Eilat - Israel",
            "searchName": "ETH-Eilat-Elat-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "NAS",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Nassau",
            "name": "[NAS] - Nassau - Bahamas",
            "searchName": "NAS-Nassau-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "CMB",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Colombo",
            "name": "[CMB] - Colombo - Sri Lanka",
            "searchName": "CMB-Colombo-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "MLE",
            "countryCode": "MV",
            "cityCode": null,
            "cityName": "Male",
            "name": "[MLE] - Male - Maldives",
            "searchName": "MLE-Mal-Male-Maldives-Maldív-szigetek-Maldivler-Maledivy-Malediivit-Maldiverne-Maldive-Maldivi-Maldyvai-Maldivija-Maldiivid-Maldivene-Malediwy-Maladewa-Malediven-Maldivas",
            "priority": 0
        },
        {
            "iataCode": "MNL",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Manila",
            "name": "[MNL] - Manila - Philippines",
            "searchName": "MNL-Manila-Manille-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "EWR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Newark",
            "name": "[EWR] - Newark - United States",
            "searchName": "EWR-Newark-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cleveland",
            "name": "[CLE] - Cleveland - United States",
            "searchName": "CLE-Cleveland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CVG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Covington",
            "name": "[CVG] - Covington - United States",
            "searchName": "CVG-Cincinnati-Covington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Charlotte",
            "name": "[CLT] - Charlotte - United States",
            "searchName": "CLT-Charlotte-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BWI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Baltimore",
            "name": "[BWI] - Baltimore - United States",
            "searchName": "BWI-Baltimore-Baltimora-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MEM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Memphis",
            "name": "[MEM] - Memphis - United States",
            "searchName": "MEM-Memphis-Menfis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RDU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Raleigh",
            "name": "[RDU] - Raleigh - United States",
            "searchName": "RDU-Raleigh-Durham-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "STL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "St Louis",
            "name": "[STL] - St Louis - United States",
            "searchName": "STL-St Louis-Saint Louis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MKE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Milwaukee",
            "name": "[MKE] - Milwaukee - United States",
            "searchName": "MKE-Milwaukee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MBJ",
            "countryCode": "JM",
            "cityCode": null,
            "cityName": "Montego Bay",
            "name": "[MBJ] - Montego Bay - Jamaica",
            "searchName": "MBJ-Montego Bay-Jamaica-Jamaika-Jamajka-Jamaïque-Gia-Giamaica",
            "priority": 0
        },
        {
            "iataCode": "ORK",
            "countryCode": "IE",
            "cityCode": null,
            "cityName": "Cork",
            "name": "[ORK] - Cork - Ireland",
            "searchName": "ORK-Cork-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "BFS",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Belfast",
            "name": "[BFS] - Belfast - United Kingdom",
            "searchName": "BFS-Belfast-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ABZ",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Aberdeen",
            "name": "[ABZ] - Aberdeen - United Kingdom",
            "searchName": "ABZ-Aberdeen-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "WAS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Washington",
            "name": "[WAS] - Washington - United States",
            "searchName": "WAS-Washington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TAS",
            "countryCode": "UZ",
            "cityCode": null,
            "cityName": "Tashkent",
            "name": "[TAS] - Tashkent - Uzbekistan",
            "searchName": "TAS-Taschkent-Tashkent-Tachkent-Taskent-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "NCL",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Newcastle",
            "name": "[NCL] - Newcastle - United Kingdom",
            "searchName": "NCL-Newcastle Upon Tyne-Newcastle-upon-Tyne-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "PFO",
            "countryCode": "CY",
            "cityCode": null,
            "cityName": "Paphos",
            "name": "[PFO] - Paphos - Cyprus",
            "searchName": "PFO-Paphos-Pafos-Pafo-Baf-Ciprus-Cypr-Siprus-Cyprus-Síp-Kipra-Kypros-Küpros-Cipar-Xipre-Kypr-Cypern-Chipre-Zypern-Chypre-Kibris-Cipru-Kipras-Cipro",
            "priority": 0
        },
        {
            "iataCode": "REK",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Reykjavik",
            "name": "[REK] - Reykjavik - Iceland",
            "searchName": "REK-Reykjav-Reykjavik-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "ARH",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Arkhangelsk",
            "name": "[ARH] - Arkhangelsk - Russia",
            "searchName": "ARH-Arkhangelsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "LWO",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Lviv",
            "name": "[LWO] - Lviv - Ukraine",
            "searchName": "LWO-Lemberg-Lviv-Leopoli-Lvov-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "MSY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "New Orleans",
            "name": "[MSY] - New Orleans - United States",
            "searchName": "MSY-New Orleans Louisiana-New Orleans-Nueva Orleans-Nouvelle-Orl-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AUH",
            "countryCode": "AE",
            "cityCode": null,
            "cityName": "Abu Dhabi",
            "name": "[AUH] - Abu Dhabi - United Arab Emirates",
            "searchName": "AUH-Abu Dhabi-Abou Dhabi-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 0
        },
        {
            "iataCode": "ADL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Adelaide",
            "name": "[ADL] - Adelaide - Australia",
            "searchName": "ADL-Adelaide-Adelaida-Ad-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SOF",
            "countryCode": "BG",
            "cityCode": null,
            "cityName": "Sofia",
            "name": "[SOF] - Sofia - Bulgaria",
            "searchName": "SOF-Sofia-Sof-Bulgária-Bulgària-Bulgaristan-Bulharsko-Bulgaria-Bulgarien-Bugarska-Bulgaaria-Bulgarija-Bolgarija--Bulgarie",
            "priority": 0
        },
        {
            "iataCode": "VRN",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Verona",
            "name": "[VRN] - Verona - Italy",
            "searchName": "VRN-Verona-V-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "HAN",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Hanoi",
            "name": "[HAN] - Hanoi - Vietnam",
            "searchName": "HAN-Hanoi-Hano-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "BTS",
            "countryCode": "SK",
            "cityCode": null,
            "cityName": "Bratislava",
            "name": "[BTS] - Bratislava - Slovakia",
            "searchName": "BTS-Bratislava-Slowakia-Slowakei-Slovakia-Slovacka-Slovaquie-Slovensko-Slovakya-Eslovàquia-Szlovákia-Slovacia-Slovaška-Slovakija-Slovakkia-Slowacja-S-Eslovaquia-Slovacchia",
            "priority": 0
        },
        {
            "iataCode": "TJM",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Tyumen",
            "name": "[TJM] - Tyumen - Russia",
            "searchName": "TJM-Tyumen-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "CAG",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Cagliari",
            "name": "[CAG] - Cagliari - Italy",
            "searchName": "CAG-Cagliari-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "FRU",
            "countryCode": "KG",
            "cityCode": null,
            "cityName": "Bishkek",
            "name": "[FRU] - Bishkek - Kyrgyzstan",
            "searchName": "FRU-Bischkek-Bishkek-Bichkek-Kirgisistan-Kyrgyzstan-Kirgizistan-Kirghizistan-Kirguistán",
            "priority": 0
        },
        {
            "iataCode": "MEL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Melbourne",
            "name": "[MEL] - Melbourne - Australia",
            "searchName": "MEL-Melbourne-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PWQ",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Pavlodar",
            "name": "[PWQ] - Pavlodar - Kazakhstan",
            "searchName": "PWQ-Pawlodar-Pavlodar-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "KIN",
            "countryCode": "JM",
            "cityCode": null,
            "cityName": "Kingston",
            "name": "[KIN] - Kingston - Jamaica",
            "searchName": "KIN-Kingston-Jamaica-Jamaika-Jamajka-Jamaïque-Gia-Giamaica",
            "priority": 0
        },
        {
            "iataCode": "MTY",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Monterrey",
            "name": "[MTY] - Monterrey - Mexico",
            "searchName": "MTY-Monterrey-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "IKT",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Irkutsk",
            "name": "[IKT] - Irkutsk - Russia",
            "searchName": "IKT-Irkutsk-Irkoutsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KEJ",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Kemerovo",
            "name": "[KEJ] - Kemerovo - Russia",
            "searchName": "KEJ-Kemerowo-Kemerovo-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "HKT",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Phuket",
            "name": "[HKT] - Phuket - Thailand",
            "searchName": "HKT-Phuket-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "ANK",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Ankara",
            "name": "[ANK] - Ankara - Turkey",
            "searchName": "ANK-Ankara-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "WLG",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Wellington",
            "name": "[WLG] - Wellington - New Zealand",
            "searchName": "WLG-Wellington-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "MRV",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Mineralnye Vody",
            "name": "[MRV] - Mineralnye Vody - Russia",
            "searchName": "MRV-Mineralnye Vody-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "SEZ",
            "countryCode": "SC",
            "cityCode": null,
            "cityName": "Mahe Island",
            "name": "[SEZ] - Mahe Island - Escocia",
            "searchName": "SEZ-Mahe Insel-Mahe Island-Mahe Ile-Escocia",
            "priority": 0
        },
        {
            "iataCode": "DTT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Detroit",
            "name": "[DTT] - Detroit - United States",
            "searchName": "DTT-Detroit-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ANC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Anchorage",
            "name": "[ANC] - Anchorage - United States",
            "searchName": "ANC-Anchorage-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CYS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cheyenne",
            "name": "[CYS] - Cheyenne - United States",
            "searchName": "CYS-Cheyenne-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MRU",
            "countryCode": "MU",
            "cityCode": null,
            "cityName": "Plaisance",
            "name": "[MRU] - Plaisance - Mauritius",
            "searchName": "MRU-Mauritius-Plaisance-Port Louis-Port-Louis-Mavricij-Mauricijus-Mauricija-Ile Maurice-Mauriti-Mauritius Adasi-Maurice-Mauricio",
            "priority": 0
        },
        {
            "iataCode": "TRN",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Turin",
            "name": "[TRN] - Turin - Italy",
            "searchName": "TRN-Turin-Tur-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "BRI",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Bari",
            "name": "[BRI] - Bari - Italy",
            "searchName": "BRI-Bari-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "SDQ",
            "countryCode": "DO",
            "cityCode": null,
            "cityName": "Santo Domingo",
            "name": "[SDQ] - Santo Domingo - Dominican Republic",
            "searchName": "SDQ-Santo Domingo-St. Domingue-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "IBZ",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Ibiza",
            "name": "[IBZ] - Ibiza - Spain",
            "searchName": "IBZ-Ibiza-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "ALC",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Alicante",
            "name": "[ALC] - Alicante - Spain",
            "searchName": "ALC-Alicante-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "YMQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Montreal",
            "name": "[YMQ] - Montreal - Canada",
            "searchName": "YMQ-Montreal-Montr-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "OMO",
            "countryCode": "BA",
            "cityCode": null,
            "cityName": "Mostar",
            "name": "[OMO] - Mostar - Bosnia and Herzegovina",
            "searchName": "OMO-Mostar-Bosznia-Hercegovina-Bòsnia i Hercegovina-Bosna Hersek-Bosna a Hercegovina-Bosnia ja Hertsegovina-Bosnien-Bosnia-Bosnia si Hertegovin-Bosnien und Herzegovina-Bosnia and Herzegovina-Bosna-Hersek-Bosnie-Herzégovine-Herzegovina-Bosnia Erzegovina",
            "priority": 0
        },
        {
            "iataCode": "RMF",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Marsa Alam",
            "name": "[RMF] - Marsa Alam - Egypt",
            "searchName": "RMF-Marsa Alam-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "BHX",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Birmingham",
            "name": "[BHX] - Birmingham - United Kingdom",
            "searchName": "BHX-Birmingham-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "HER",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Heraklion",
            "name": "[HER] - Heraklion - Greece",
            "searchName": "HER-Heraklion-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "TBS",
            "countryCode": "GE",
            "cityCode": null,
            "cityName": "Tbilisi",
            "name": "[TBS] - Tbilisi - Georgia",
            "searchName": "TBS-Tiflis-Tbilisi-Tbilissi-Georgia-Georgien-Gruzie-Gürcistan-Geòrgia-Grúzia-Gruzija-Gruusia-Gruzja-Géo-Géorgie",
            "priority": 0
        },
        {
            "iataCode": "NBO",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Nairobi",
            "name": "[NBO] - Nairobi - Kenya",
            "searchName": "NBO-Nairobi-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "SKP",
            "countryCode": "MK",
            "cityCode": null,
            "cityName": "Skopje",
            "name": "[SKP] - Skopje - Macedonia",
            "searchName": "SKP-Skopie-Skopje-Makedonia-Macedonia-Makedonya-Macedònia-Macedónia-Makedonija-Makedonien-Mazedonien-Macédoine--Fyrom-Repubblica ex Jugoslava di Macedonia",
            "priority": 0
        },
        {
            "iataCode": "ULN",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Ulaanbaatar",
            "name": "[ULN] - Ulaanbaatar - Mongolia",
            "searchName": "ULN-Ulaanbaatar-Ul-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "DEN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Denver",
            "name": "[DEN] - Denver - United States",
            "searchName": "DEN-Denver-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RTW",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Saratov",
            "name": "[RTW] - Saratov - Russia",
            "searchName": "RTW-Saratov-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "MJF",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Mosjoen",
            "name": "[MJF] - Mosjoen - Norway",
            "searchName": "MJF-Mosjoen-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "FNC",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Funchal",
            "name": "[FNC] - Funchal - Portugal",
            "searchName": "FNC-Madeira-Funchal-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "PSG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Petersburg",
            "name": "[PSG] - Petersburg - United States",
            "searchName": "PSG-Petersburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OLB",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Olbia",
            "name": "[OLB] - Olbia - Italy",
            "searchName": "OLB-Olbia-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "HFA",
            "countryCode": "IL",
            "cityCode": null,
            "cityName": "Haifa",
            "name": "[HFA] - Haifa - Israel",
            "searchName": "HFA-Haifa-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "IXL",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Leh",
            "name": "[IXL] - Leh - India",
            "searchName": "IXL-Leh-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "TLN",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Hyeres",
            "name": "[TLN] - Hyeres - France",
            "searchName": "TLN-Toulon-Hyeres-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "TCI",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Tenerife",
            "name": "[TCI] - Tenerife - Spain",
            "searchName": "TCI-Teneriffa-Tenerife-Teneriffe-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "KTT",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Kittila",
            "name": "[KTT] - Kittila - Finland",
            "searchName": "KTT-Kittil-Kittila-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "BNE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Brisbane",
            "name": "[BNE] - Brisbane - Australia",
            "searchName": "BNE-Brisbane-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "YVR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Vancouver",
            "name": "[YVR] - Vancouver - Canada",
            "searchName": "YVR-Vancouver-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "SCU",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Santiago",
            "name": "[SCU] - Santiago - Cuba",
            "searchName": "SCU-Santiago-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "IPC",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Easter Island",
            "name": "[IPC] - Easter Island - Chile",
            "searchName": "IPC-Easter Island-Isla de Pascua--Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "VRA",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Varadero",
            "name": "[VRA] - Varadero - Cuba",
            "searchName": "VRA-Varadero-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "YXU",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "London",
            "name": "[YXU] - London - Canada",
            "searchName": "YXU-London-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "INN",
            "countryCode": "AT",
            "cityCode": null,
            "cityName": "Innsbruck",
            "name": "[INN] - Innsbruck - Austria",
            "searchName": "INN-Innsbruck-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "BGI",
            "countryCode": "BB",
            "cityCode": null,
            "cityName": "Bridgetown",
            "name": "[BGI] - Bridgetown - Barbados",
            "searchName": "BGI-Bridgetown-Barbados-Barbade",
            "priority": 0
        },
        {
            "iataCode": "VAR",
            "countryCode": "BG",
            "cityCode": null,
            "cityName": "Varna",
            "name": "[VAR] - Varna - Bulgaria",
            "searchName": "VAR-Warna-Varna-Bulgária-Bulgària-Bulgaristan-Bulharsko-Bulgaria-Bulgarien-Bugarska-Bulgaaria-Bulgarija-Bolgarija--Bulgarie",
            "priority": 0
        },
        {
            "iataCode": "TLL",
            "countryCode": "EE",
            "cityCode": null,
            "cityName": "Tallinn",
            "name": "[TLL] - Tallinn - Estonia",
            "searchName": "TLL-Tallinn-Tallin-Estônia-Estland-Estonia-Estonie-Eesti-Estonya",
            "priority": 0
        },
        {
            "iataCode": "BOD",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Bordeaux",
            "name": "[BOD] - Bordeaux - France",
            "searchName": "BOD-Bordeaux-Burdeos-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CCS",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Caracas",
            "name": "[CCS] - Caracas - Venezuela",
            "searchName": "CCS-Caracas-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "PES",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Petrozavodsk",
            "name": "[PES] - Petrozavodsk - Russia",
            "searchName": "PES-Petrosawodsk-Petrozavodsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "RTM",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Rotterdam",
            "name": "[RTM] - Rotterdam - Netherlands",
            "searchName": "RTM-Rotterdam-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "ASF",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Astrakhan",
            "name": "[ASF] - Astrakhan - Russia",
            "searchName": "ASF-Astrakhan-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "TOF",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Tomsk",
            "name": "[TOF] - Tomsk - Russia",
            "searchName": "TOF-Tomsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KXK",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Komsomolsk Na Amure",
            "name": "[KXK] - Komsomolsk Na Amure - Russia",
            "searchName": "KXK-Komsomolsk Na Amure-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KJA",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Krasnojarsk",
            "name": "[KJA] - Krasnojarsk - Russia",
            "searchName": "KJA-Krasnojarsk-Krasnoyarsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "DLM",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Dalaman",
            "name": "[DLM] - Dalaman - Turkey",
            "searchName": "DLM-Dalaman-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "MIR",
            "countryCode": "TN",
            "cityCode": null,
            "cityName": "Monastir",
            "name": "[MIR] - Monastir - Tunisia",
            "searchName": "MIR-Monastir-Tunisia-Tunézia-Tunísia-Tunus-Tunisko-Tunesien-Tunizija-Tunisas-Tunisija-Tunis-Tuneesia-Tunezja-Tunisie-Túnez",
            "priority": 0
        },
        {
            "iataCode": "DFW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dallas",
            "name": "[DFW] - Dallas - United States",
            "searchName": "DFW-Dallas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HRK",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Kharkov",
            "name": "[HRK] - Kharkov - Ukraine",
            "searchName": "HRK-Charkiw-Kharkov-Jarkov-Kharkiv-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "ADK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Adak Island",
            "name": "[ADK] - Adak Island - United States",
            "searchName": "ADK-Adak Island--Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VSG",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Lugansk",
            "name": "[VSG] - Lugansk - Ukraine",
            "searchName": "VSG-Lugansk-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "USM",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Koh Samui",
            "name": "[USM] - Koh Samui - Thailand",
            "searchName": "USM-Koh Samui-Ko Samui-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "EBB",
            "countryCode": "UG",
            "cityCode": null,
            "cityName": "Entebbe",
            "name": "[EBB] - Entebbe - Uganda",
            "searchName": "EBB-Entebbe-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "BTK",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Bratsk",
            "name": "[BTK] - Bratsk - Russia",
            "searchName": "BTK-Bratsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KTM",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Kathmandu",
            "name": "[KTM] - Kathmandu - Nepal",
            "searchName": "KTM-Kathmandu-Katmand-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "AKL",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Auckland",
            "name": "[AKL] - Auckland - New Zealand",
            "searchName": "AKL-Auckland-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "PRN",
            "countryCode": "RS",
            "cityCode": null,
            "cityName": "Pristina",
            "name": "[PRN] - Pristina - Serbia",
            "searchName": "PRN-Priština-Pristina-Pri-Szerbia-Sèrbia-Sirbistan-Srbsko-Serbia-Serbien-Srbija-Serbija-Serbie--Suudi Arabistan",
            "priority": 0
        },
        {
            "iataCode": "NJC",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Nizhnevartovsk",
            "name": "[NJC] - Nizhnevartovsk - Russia",
            "searchName": "NJC-Nischnewartowsk-Nizhnevartovsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "UUD",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Ulan-ude",
            "name": "[UUD] - Ulan-ude - Russia",
            "searchName": "UUD-Ulan Ude-Ulan-ude-Ude-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "MCM",
            "countryCode": "MC",
            "cityCode": null,
            "cityName": "Monte Carlo",
            "name": "[MCM] - Monte Carlo - Monaco",
            "searchName": "MCM-Monte Carlo-Monaco-Mónaco-Mô na cô-Monako-Principato di Monaco-Monakas-M",
            "priority": 0
        },
        {
            "iataCode": "PHT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Paris",
            "name": "[PHT] - Paris - United States",
            "searchName": "PHT-Paris-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SEA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Seattle",
            "name": "[SEA] - Seattle - United States",
            "searchName": "SEA-Seattle-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LTT",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Saint Tropez",
            "name": "[LTT] - Saint Tropez - France",
            "searchName": "LTT-Saint Tropez-Saint-Tropez-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "RNS",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Rennes",
            "name": "[RNS] - Rennes - France",
            "searchName": "RNS-Rennes-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "NTE",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Nantes",
            "name": "[NTE] - Nantes - France",
            "searchName": "NTE-Nantes-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "HAJ",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Hanover",
            "name": "[HAJ] - Hanover - Germany",
            "searchName": "HAJ-Hannover-Hanover-Han-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "PUJ",
            "countryCode": "DO",
            "cityCode": null,
            "cityName": "Punta Cana",
            "name": "[PUJ] - Punta Cana - Dominican Republic",
            "searchName": "PUJ-Punta Cana-Higuey-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "AAR",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Aarhus",
            "name": "[AAR] - Aarhus - Denmark",
            "searchName": "AAR-Aarhus-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "SPU",
            "countryCode": "HR",
            "cityCode": null,
            "cityName": "Split",
            "name": "[SPU] - Split - Croatia",
            "searchName": "SPU-Split-Spalato-Kroatië-Kroatia-Chorvatsko-Hirvatistan-Croàcia-Horvátország-Croatia-Hrvaška-Kroatija-Horvatija-Hrvatska-Horvaatia-Chorwacja-Kroasia-Kroatien-Croatie-Croacia-Croazia",
            "priority": 0
        },
        {
            "iataCode": "DBV",
            "countryCode": "HR",
            "cityCode": null,
            "cityName": "Dubrovnik",
            "name": "[DBV] - Dubrovnik - Croatia",
            "searchName": "DBV-Dubrovnik-Ragusa-Kroatië-Kroatia-Chorvatsko-Hirvatistan-Croàcia-Horvátország-Croatia-Hrvaška-Kroatija-Horvatija-Hrvatska-Horvaatia-Chorwacja-Kroasia-Kroatien-Croatie-Croacia-Croazia",
            "priority": 0
        },
        {
            "iataCode": "SVQ",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Sevilla",
            "name": "[SVQ] - Sevilla - Spain",
            "searchName": "SVQ-Sevilla-S-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "KGF",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Karaganda",
            "name": "[KGF] - Karaganda - Kazakhstan",
            "searchName": "KGF-Karaganda-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "SZG",
            "countryCode": "AT",
            "cityCode": null,
            "cityName": "Salzburg",
            "name": "[SZG] - Salzburg - Austria",
            "searchName": "SZG-Salzburg-Salzbourg-Salisburgo-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "TIA",
            "countryCode": "AL",
            "cityCode": null,
            "cityName": "Tirana",
            "name": "[TIA] - Tirana - Albania",
            "searchName": "TIA-Tirana-Albânia-Albania-Albanië-Albanien-An ba ni-Albanija-Albaania-Albanie",
            "priority": 0
        },
        {
            "iataCode": "BOJ",
            "countryCode": "BG",
            "cityCode": null,
            "cityName": "Bourgas",
            "name": "[BOJ] - Bourgas - Bulgaria",
            "searchName": "BOJ-Burgas-Bourgas-Bulgária-Bulgària-Bulgaristan-Bulharsko-Bulgaria-Bulgarien-Bugarska-Bulgaaria-Bulgarija-Bolgarija--Bulgarie",
            "priority": 0
        },
        {
            "iataCode": "SID",
            "countryCode": "CV",
            "cityCode": null,
            "cityName": "Sal",
            "name": "[SID] - Sal - Cape Verde",
            "searchName": "SID-Sal-Sal Island-Kap Verde-Cape Verde-Yesil Burun Adalari-Cap-Vert-Cabo Verde-Capo Verde",
            "priority": 0
        },
        {
            "iataCode": "LXA",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Gonggar",
            "name": "[LXA] - Gonggar - China",
            "searchName": "LXA-Lhasa-Gonggar-Lhassa-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "STR",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Stuttgart",
            "name": "[STR] - Stuttgart - Germany",
            "searchName": "STR-Stuttgart-Stoccarda-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "CPO",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Copiapo",
            "name": "[CPO] - Copiapo - Chile",
            "searchName": "CPO-Copiap-Copiapo-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "ABJ",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Abidjan",
            "name": "[ABJ] - Abidjan - Ivory Coast",
            "searchName": "ABJ-Abidjan-Abiy-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "BRN",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Berne",
            "name": "[BRN] - Berne - Switzerland",
            "searchName": "BRN-Bern-Berne-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "EVN",
            "countryCode": "AM",
            "cityCode": null,
            "cityName": "Yerevan",
            "name": "[EVN] - Yerevan - Armenia",
            "searchName": "EVN-Eriwan-Yerevan-Erev-Örményország-Armènia-Ermenistan-Arménie-Armenia-Armenija-Armeenia--Armenien",
            "priority": 0
        },
        {
            "iataCode": "ASP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Alice Springs",
            "name": "[ASP] - Alice Springs - Australia",
            "searchName": "ASP-Alice Springs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DAC",
            "countryCode": "BD",
            "cityCode": null,
            "cityName": "Dhaka",
            "name": "[DAC] - Dhaka - Bangladesh",
            "searchName": "DAC-Dhaka-Dacca-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "OHD",
            "countryCode": "MK",
            "cityCode": null,
            "cityName": "Ohrid",
            "name": "[OHD] - Ohrid - Macedonia",
            "searchName": "OHD-Ohrid-Makedonia-Macedonia-Makedonya-Macedònia-Macedónia-Makedonija-Makedonien-Mazedonien-Macédoine--Fyrom-Repubblica ex Jugoslava di Macedonia",
            "priority": 0
        },
        {
            "iataCode": "RMI",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Rimini",
            "name": "[RMI] - Rimini - Italy",
            "searchName": "RMI-Rimini-R-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "EGS",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Egilsstadir",
            "name": "[EGS] - Egilsstadir - Iceland",
            "searchName": "EGS-Egilsstadir-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "HMO",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Hermosillo",
            "name": "[HMO] - Hermosillo - Mexico",
            "searchName": "HMO-Hermosillo-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "JKH",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Chios",
            "name": "[JKH] - Chios - Greece",
            "searchName": "JKH-Chios-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "PUY",
            "countryCode": "HR",
            "cityCode": null,
            "cityName": "Pula",
            "name": "[PUY] - Pula - Croatia",
            "searchName": "PUY-Pula-Pola-Kroatië-Kroatia-Chorvatsko-Hirvatistan-Croàcia-Horvátország-Croatia-Hrvaška-Kroatija-Horvatija-Hrvatska-Horvaatia-Chorwacja-Kroasia-Kroatien-Croatie-Croacia-Croazia",
            "priority": 0
        },
        {
            "iataCode": "RPN",
            "countryCode": "IL",
            "cityCode": null,
            "cityName": "Rosh Pina",
            "name": "[RPN] - Rosh Pina - Israel",
            "searchName": "RPN-Rosh Pina-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "MSN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Madison",
            "name": "[MSN] - Madison - United States",
            "searchName": "MSN-Madison-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MSP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Minneapolis",
            "name": "[MSP] - Minneapolis - United States",
            "searchName": "MSP-Minneapolis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MKC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kansas City",
            "name": "[MKC] - Kansas City - United States",
            "searchName": "MKC-Kansas City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KAB",
            "countryCode": "ZW",
            "cityCode": null,
            "cityName": "Kariba",
            "name": "[KAB] - Kariba - Zimbabwe",
            "searchName": "KAB-Kariba-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "ASU",
            "countryCode": "PY",
            "cityCode": null,
            "cityName": "Asuncion",
            "name": "[ASU] - Asuncion - Paraguay",
            "searchName": "ASU-Asunci-Asuncion-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "HTA",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Chita",
            "name": "[HTA] - Chita - Russia",
            "searchName": "HTA-Chita-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KHV",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Khabarovsk",
            "name": "[KHV] - Khabarovsk - Russia",
            "searchName": "KHV-Chabarowsk-Khabarovsk-Jab-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ASE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Aspen",
            "name": "[ASE] - Aspen - United States",
            "searchName": "ASE-Aspen-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DOM",
            "countryCode": "DM",
            "cityCode": null,
            "cityName": "Dominica",
            "name": "[DOM] - Dominica - Dominica",
            "searchName": "DOM-Dominica-Marigot-Dominika-Dominique",
            "priority": 0
        },
        {
            "iataCode": "STI",
            "countryCode": "DO",
            "cityCode": null,
            "cityName": "Santiago",
            "name": "[STI] - Santiago - Dominican Republic",
            "searchName": "STI-Santiago-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "LXR",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Luxor",
            "name": "[LXR] - Luxor - Egypt",
            "searchName": "LXR-Luxor-L-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "CUE",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Cuenca",
            "name": "[CUE] - Cuenca - Ecuador",
            "searchName": "CUE-Cuenca-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "LEI",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Almeria",
            "name": "[LEI] - Almeria - Spain",
            "searchName": "LEI-Almer-Almeria-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "LPA",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Las Palmas",
            "name": "[LPA] - Las Palmas - Spain",
            "searchName": "LPA-Palmas de Gran Canaria-Las Palmas-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "BIO",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Bilbao",
            "name": "[BIO] - Bilbao - Spain",
            "searchName": "BIO-Bilbao-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "LEH",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Le Havre",
            "name": "[LEH] - Le Havre - France",
            "searchName": "LEH-Le Havre-El Havre-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LTQ",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Le Touquet",
            "name": "[LTQ] - Le Touquet - France",
            "searchName": "LTQ-Le Touquet-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "TRV",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Thiruvananthapuram",
            "name": "[TRV] - Thiruvananthapuram - India",
            "searchName": "TRV-Thiruvananthapuram-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "JMK",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Mikonos",
            "name": "[JMK] - Mikonos - Greece",
            "searchName": "JMK-Mykonos-Mikonos-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "BUS",
            "countryCode": "GE",
            "cityCode": null,
            "cityName": "Batumi",
            "name": "[BUS] - Batumi - Georgia",
            "searchName": "BUS-Batumi-Batum-Georgia-Georgien-Gruzie-Gürcistan-Geòrgia-Grúzia-Gruzija-Gruusia-Gruzja-Géo-Géorgie",
            "priority": 0
        },
        {
            "iataCode": "CFU",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Kerkyra",
            "name": "[CFU] - Kerkyra - Greece",
            "searchName": "CFU-Korfu-Kerkyra-Corf-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "CHQ",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Chania",
            "name": "[CHQ] - Chania - Greece",
            "searchName": "CHQ-Chania-La Canea-La Can-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "HUY",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Humberside",
            "name": "[HUY] - Humberside - United Kingdom",
            "searchName": "HUY-Humberside-Barnetby-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ZTH",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Zakinthos",
            "name": "[ZTH] - Zakinthos - Greece",
            "searchName": "ZTH-Zakinthos-Zakinthos Island-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "IPH",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Ipoh",
            "name": "[IPH] - Ipoh - Malaysia",
            "searchName": "IPH-Ipoh-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "MGA",
            "countryCode": "NI",
            "cityCode": null,
            "cityName": "Managua",
            "name": "[MGA] - Managua - Nicaragua",
            "searchName": "MGA-Managua-Nicaragua-Nikaragua-Nikaragva",
            "priority": 0
        },
        {
            "iataCode": "KBR",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Kota Bharu",
            "name": "[KBR] - Kota Bharu - Malaysia",
            "searchName": "KBR-Kota Bharu-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "BOB",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Bora Bora",
            "name": "[BOB] - Bora Bora - French Polynesia",
            "searchName": "BOB-Bora Bora-Vaitape-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "BLR",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Bangalore",
            "name": "[BLR] - Bangalore - India",
            "searchName": "BLR-Bengaluru-Bangalore-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "NAP",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Napoli",
            "name": "[NAP] - Napoli - Italy",
            "searchName": "NAP-Neapel-Napoli-N-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "CTA",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Catania",
            "name": "[CTA] - Catania - Italy",
            "searchName": "CTA-Catania-Catane-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "PSA",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Pisa",
            "name": "[PSA] - Pisa - Italy",
            "searchName": "PSA-Pisa-Pise-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "AMM",
            "countryCode": "JO",
            "cityCode": null,
            "cityName": "Amman",
            "name": "[AMM] - Amman - Jordan",
            "searchName": "AMM-Amman-Amm-Jordánia-Jordània-Ürdün-Jordan-Jordánsko-Jordania-Jordaania-Jordanija-Iordania-Yordania-Jordanien-Jordânia-Jordani-Jordanie-Giordania",
            "priority": 0
        },
        {
            "iataCode": "TOB",
            "countryCode": "LY",
            "cityCode": null,
            "cityName": "Tobruk",
            "name": "[TOB] - Tobruk - Libya",
            "searchName": "TOB-Tobruk-Tobrouk-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "KUN",
            "countryCode": "LT",
            "cityCode": null,
            "cityName": "Kaunas",
            "name": "[KUN] - Kaunas - Lithuania",
            "searchName": "KUN-Kaunas-Lituânia-Litouwen-Lithuania-Litauen-Lituanie-Lituania-Litwa-Leedu-Li-Litvanya",
            "priority": 0
        },
        {
            "iataCode": "MBA",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Mombasa",
            "name": "[MBA] - Mombasa - Kenya",
            "searchName": "MBA-Mombasa-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "KWI",
            "countryCode": "KW",
            "cityCode": null,
            "cityName": "Kuwait City",
            "name": "[KWI] - Kuwait City - Kuwait",
            "searchName": "KWI-Kuwait-Kuwait City-Kuweit-Kuveyt-Koeweit-Koweït-Kuvajt-Kuveitas",
            "priority": 0
        },
        {
            "iataCode": "SBH",
            "countryCode": "BL",
            "cityCode": null,
            "cityName": "Gustavia",
            "name": "[SBH] - Gustavia - Saint Barthelemy",
            "searchName": "SBH-Saint Jean-Gustavia-St. Barthelemy-Saint Barthélemy-BLM-Guadeloupe-San Bartolomé-Saint-Barthélémy-San Bartolomeo-Saint Barthelemy",
            "priority": 0
        },
        {
            "iataCode": "ARM",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Armidale",
            "name": "[ARM] - Armidale - Australia",
            "searchName": "ARM-Armidale-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MOV",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Moranbah",
            "name": "[MOV] - Moranbah - Australia",
            "searchName": "MOV-Moranbah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FLN",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Florianopolis",
            "name": "[FLN] - Florianopolis - Brazil",
            "searchName": "FLN-Florian-Florianopolis-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LUG",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Lugano",
            "name": "[LUG] - Lugano - Switzerland",
            "searchName": "LUG-Lugano-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "SAO",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Sao Paulo",
            "name": "[SAO] - Sao Paulo - Brazil",
            "searchName": "SAO-S-Sao Paulo-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "HOG",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Holguin",
            "name": "[HOG] - Holguin - Cuba",
            "searchName": "HOG-Holgu-Holguin-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "DTM",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Dortmund",
            "name": "[DTM] - Dortmund - Germany",
            "searchName": "DTM-Dortmund-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "AAH",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Aachen",
            "name": "[AAH] - Aachen - Germany",
            "searchName": "AAH-Aachen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ECN",
            "countryCode": "CY",
            "cityCode": null,
            "cityName": "Lefkosa",
            "name": "[ECN] - Lefkosa - Cyprus",
            "searchName": "ECN-Ercan-Lefkosa-Ciprus-Cypr-Siprus-Cyprus-Síp-Kipra-Kypros-Küpros-Cipar-Xipre-Kypr-Cypern-Chipre-Zypern-Chypre-Kibris-Cipru-Kipras-Cipro",
            "priority": 0
        },
        {
            "iataCode": "CFG",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Cienfuegos",
            "name": "[CFG] - Cienfuegos - Cuba",
            "searchName": "CFG-Cienfuegos-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "BOG",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Bogota",
            "name": "[BOG] - Bogota - Colombia",
            "searchName": "BOG-Bogot-Bogota-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "DNK",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Dnepropetrovsk",
            "name": "[DNK] - Dnepropetrovsk - Ukraine",
            "searchName": "DNK-Dnepropetrovsk-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "DHN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dothan",
            "name": "[DHN] - Dothan - United States",
            "searchName": "DHN-Dothan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ITO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hilo",
            "name": "[ITO] - Hilo - United States",
            "searchName": "ITO-Hawaii (Big Island)-Hilo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GON",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "New London",
            "name": "[GON] - New London - United States",
            "searchName": "GON-Groton-New London-Groton\\/New London-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PRI",
            "countryCode": "SC",
            "cityCode": null,
            "cityName": "Praslin Island",
            "name": "[PRI] - Praslin Island - Escocia",
            "searchName": "PRI-Praslin Island--Escocia",
            "priority": 0
        },
        {
            "iataCode": "DMM",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Dammam",
            "name": "[DMM] - Dammam - Saudi Arabia",
            "searchName": "DMM-Dammam-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "SFT",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Skelleftea",
            "name": "[SFT] - Skelleftea - Sweden",
            "searchName": "SFT-Skellefte-Skelleftea-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "DAM",
            "countryCode": "SY",
            "cityCode": null,
            "cityName": "Damascus",
            "name": "[DAM] - Damascus - Syria",
            "searchName": "DAM-Damaskus-Damascus-Syrien-Syria-Syrie-Siria",
            "priority": 0
        },
        {
            "iataCode": "ASB",
            "countryCode": "TM",
            "cityCode": null,
            "cityName": "Ashgabat",
            "name": "[ASB] - Ashgabat - Turkmenistan",
            "searchName": "ASB-A-Ashgabat-Turkmenistan-Turkménistan-Turkmenistán",
            "priority": 0
        },
        {
            "iataCode": "IZM",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Izmir",
            "name": "[IZM] - Izmir - Turkey",
            "searchName": "IZM-Izmir-Esmirna-Smirne-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "AAQ",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Anapa",
            "name": "[AAQ] - Anapa - Russia",
            "searchName": "AAQ-Anapa-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "RUH",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Riyadh",
            "name": "[RUH] - Riyadh - Saudi Arabia",
            "searchName": "RUH-Riad-Riyadh-Riyad-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "DOH",
            "countryCode": "QA",
            "cityCode": null,
            "cityName": "Doha",
            "name": "[DOH] - Doha - Qatar",
            "searchName": "DOH-Doha-Katar-Qatar-Kataras-Katara-Quatar",
            "priority": 0
        },
        {
            "iataCode": "MQF",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Magnitogorsk",
            "name": "[MQF] - Magnitogorsk - Russia",
            "searchName": "MQF-Magnitogorsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ISB",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Islamabad",
            "name": "[ISB] - Islamabad - Pakistan",
            "searchName": "ISB-Islamabad-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "KHI",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Karachi",
            "name": "[KHI] - Karachi - Pakistan",
            "searchName": "KHI-Karatschi-Karachi-Kar-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "LHE",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Lahore",
            "name": "[LHE] - Lahore - Pakistan",
            "searchName": "LHE-Lahore-L-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "KRK",
            "countryCode": "PL",
            "cityCode": null,
            "cityName": "Krakow",
            "name": "[KRK] - Krakow - Poland",
            "searchName": "KRK-Cracow-Krakow-Cracovia-Cracovie-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "ZQN",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Queenstown",
            "name": "[ZQN] - Queenstown - New Zealand",
            "searchName": "ZQN-Queenstown-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "MCT",
            "countryCode": "OM",
            "cityCode": null,
            "cityName": "Muscat",
            "name": "[MCT] - Muscat - Oman",
            "searchName": "MCT-Maskat-Muscat-Mascate-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "PTG",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Polokwane",
            "name": "[PTG] - Polokwane - South Africa",
            "searchName": "PTG-Polokwane-Pietersburg-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "APW",
            "countryCode": "WS",
            "cityCode": null,
            "cityName": "Apia",
            "name": "[APW] - Apia - Samoa",
            "searchName": "APW-Apia-Szamoa-Samoa-Bati Samoa",
            "priority": 0
        },
        {
            "iataCode": "MXS",
            "countryCode": "WS",
            "cityCode": null,
            "cityName": "Maota Savaii Island",
            "name": "[MXS] - Maota Savaii Island - Samoa",
            "searchName": "MXS-Maota Savall Island-Maota Savaii Island-Maota Savaii Is-Szamoa-Samoa-Bati Samoa",
            "priority": 0
        },
        {
            "iataCode": "CPT",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Cape Town",
            "name": "[CPT] - Cape Town - South Africa",
            "searchName": "CPT-Kapstadt-Cape Town-Ciudad del Cabo-Le Cap-Citt-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "PRX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Paris",
            "name": "[PRX] - Paris - United States",
            "searchName": "PRX-Paris-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PIT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pittsburgh",
            "name": "[PIT] - Pittsburgh - United States",
            "searchName": "PIT-Pittsburgh-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TVL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "South Lake Tahoe",
            "name": "[TVL] - South Lake Tahoe - United States",
            "searchName": "TVL-Lake Tahoe South-South Lake Tahoe-Lake Tahoe-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OSA",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Osaka",
            "name": "[OSA] - Osaka - Japan",
            "searchName": "OSA-Osaka-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "NGO",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Nagoya",
            "name": "[NGO] - Nagoya - Japan",
            "searchName": "NGO-Nagoya-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "REP",
            "countryCode": "KH",
            "cityCode": null,
            "cityName": "Siem Reap",
            "name": "[REP] - Siem Reap - Cambodia",
            "searchName": "REP-Siem Riep-Siem Reap-Ciudad de Siem Riep-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "PNH",
            "countryCode": "KH",
            "cityCode": null,
            "cityName": "Phnom Penh",
            "name": "[PNH] - Phnom Penh - Cambodia",
            "searchName": "PNH-Phnom Penh-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "AOT",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Aosta",
            "name": "[AOT] - Aosta - Italy",
            "searchName": "AOT-Aosta-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "PNQ",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Pune",
            "name": "[PNQ] - Pune - India",
            "searchName": "PNQ-Pune-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "AEY",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Akureyri",
            "name": "[AEY] - Akureyri - Iceland",
            "searchName": "AEY-Akureyri-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "OCJ",
            "countryCode": "JM",
            "cityCode": null,
            "cityName": "Ocho Rios",
            "name": "[OCJ] - Ocho Rios - Jamaica",
            "searchName": "OCJ-Ocho Rios-Jamaica-Jamaika-Jamajka-Jamaïque-Gia-Giamaica",
            "priority": 0
        },
        {
            "iataCode": "AQJ",
            "countryCode": "JO",
            "cityCode": null,
            "cityName": "Aqaba",
            "name": "[AQJ] - Aqaba - Jordan",
            "searchName": "AQJ-Aqaba-Jordánia-Jordània-Ürdün-Jordan-Jordánsko-Jordania-Jordaania-Jordanija-Iordania-Yordania-Jordanien-Jordânia-Jordani-Jordanie-Giordania",
            "priority": 0
        },
        {
            "iataCode": "MSU",
            "countryCode": "LS",
            "cityCode": null,
            "cityName": "Maseru",
            "name": "[MSU] - Maseru - Lesotho",
            "searchName": "MSU-Maseru-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "GZM",
            "countryCode": "MT",
            "cityCode": null,
            "cityName": "Gozo",
            "name": "[GZM] - Gozo - Malta",
            "searchName": "GZM-Gozo-Malta-Malte-Málta-Mal",
            "priority": 0
        },
        {
            "iataCode": "PZE",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Penzance",
            "name": "[PZE] - Penzance - United Kingdom",
            "searchName": "PZE-Penzance-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "SMI",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Samos",
            "name": "[SMI] - Samos - Greece",
            "searchName": "SMI-Samos-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "JTY",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Astypalaia Island",
            "name": "[JTY] - Astypalaia Island - Greece",
            "searchName": "JTY-Astypalaia Island--Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland-Greece",
            "priority": 0
        },
        {
            "iataCode": "ZAD",
            "countryCode": "HR",
            "cityCode": null,
            "cityName": "Zadar",
            "name": "[ZAD] - Zadar - Croatia",
            "searchName": "ZAD-Zadar-Zara-Kroatië-Kroatia-Chorvatsko-Hirvatistan-Croàcia-Horvátország-Croatia-Hrvaška-Kroatija-Horvatija-Hrvatska-Horvaatia-Chorwacja-Kroasia-Kroatien-Croatie-Croacia-Croazia",
            "priority": 0
        },
        {
            "iataCode": "KSW",
            "countryCode": "IL",
            "cityCode": null,
            "cityName": "Kiryat Shmona",
            "name": "[KSW] - Kiryat Shmona - Israel",
            "searchName": "KSW-Kiryat Shmona-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "IXB",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Bagdogra",
            "name": "[IXB] - Bagdogra - India",
            "searchName": "IXB-Bagdogra-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXM",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Madurai",
            "name": "[IXM] - Madurai - India",
            "searchName": "IXM-Madurai-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "JAI",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Jaipur",
            "name": "[JAI] - Jaipur - India",
            "searchName": "JAI-Jaipur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "SUV",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Suva",
            "name": "[SUV] - Suva - Fiji",
            "searchName": "SUV-Suva-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "ETZ",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Metz",
            "name": "[ETZ] - Metz - France",
            "searchName": "ETZ-Metz/Nancy-Metz-Nancy/Metz-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "ESS",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Essen",
            "name": "[ESS] - Essen - Germany",
            "searchName": "ESS-Essen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "FKB",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Karlsruhe",
            "name": "[FKB] - Karlsruhe - Germany",
            "searchName": "FKB-Karlsruhe/Baden Baden-Karlsruhe-Karlsruhe\\/Baden Baden-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "SJO",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "San José",
            "name": "[SJO] - San José - Costa Rica",
            "searchName": "SJO-San Jos-San José-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "VXE",
            "countryCode": "CV",
            "cityCode": null,
            "cityName": "Sao Vicente",
            "name": "[VXE] - Sao Vicente - Cape Verde",
            "searchName": "VXE-Sao Vicente-Kap Verde-Cape Verde-Yesil Burun Adalari-Cap-Vert-Cabo Verde-Capo Verde",
            "priority": 0
        },
        {
            "iataCode": "OSR",
            "countryCode": "CZ",
            "cityCode": null,
            "cityName": "Ostrava",
            "name": "[OSR] - Ostrava - Czech Republic",
            "searchName": "OSR-Ostrava-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "NGE",
            "countryCode": "CM",
            "cityCode": null,
            "cityName": "Ngaoundéré",
            "name": "[NGE] - Ngaoundéré - Cameroon",
            "searchName": "NGE-Ngaound-Ngaoundéré-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "AUX",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Araguaina",
            "name": "[AUX] - Araguaina - Brazil",
            "searchName": "AUX-Araguaina-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MVF",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Mossoro",
            "name": "[MVF] - Mossoro - Brazil",
            "searchName": "MVF-Mossoro-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "YOW",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Ottawa",
            "name": "[YOW] - Ottawa - Canada",
            "searchName": "YOW-Ottawa-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Québec City",
            "name": "[YQB] - Québec City - Canada",
            "searchName": "YQB-Quebec-Qu-Québec City-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "QLS",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Lausanne",
            "name": "[QLS] - Lausanne - Switzerland",
            "searchName": "QLS-Lausanne-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "BAK",
            "countryCode": "AZ",
            "cityCode": null,
            "cityName": "Baku",
            "name": "[BAK] - Baku - Azerbaijan",
            "searchName": "BAK-Baku-Bakou-Bak-Azerbajdzjan-Aserbaidschan-Azerbaijan-Aserbajdsjan-Azerbejdzan-Azerbaidzjan-Azerbeijão-Azerbaycan-Azerbaïdjan-Azerbaiyán-Azerbaigian",
            "priority": 0
        },
        {
            "iataCode": "CGP",
            "countryCode": "BD",
            "cityCode": null,
            "cityName": "Chittagong",
            "name": "[CGP] - Chittagong - Bangladesh",
            "searchName": "CGP-Chittagong-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "BSB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Brasilia",
            "name": "[BSB] - Brasilia - Brazil",
            "searchName": "BSB-Bras-Brasilia-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "YEK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Arviat",
            "name": "[YEK] - Arviat - Canada",
            "searchName": "YEK-Arviat-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "PPG",
            "countryCode": "AS",
            "cityCode": null,
            "cityName": "Pago Pago",
            "name": "[PPG] - Pago Pago - American Samoa",
            "searchName": "PPG-Pago Pago-Amerikanisch-Samoa-American Samoa-Amerikan Samoasi-Samoa américaines-Samoa Americana-Samoa Americane",
            "priority": 0
        },
        {
            "iataCode": "BUE",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Buenos Aires",
            "name": "[BUE] - Buenos Aires - Argentina",
            "searchName": "BUE-Buenos Aires-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "ZNE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Newman",
            "name": "[ZNE] - Newman - Australia",
            "searchName": "ZNE-Newman-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "USH",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Ushuaia",
            "name": "[USH] - Ushuaia - Argentina",
            "searchName": "USH-Ushuaia-Ushua-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "VNC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Venice",
            "name": "[VNC] - Venice - United States",
            "searchName": "VNC-Venice-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SDF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Louisville",
            "name": "[SDF] - Louisville - United States",
            "searchName": "SDF-Louisville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IND",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Indianapolis",
            "name": "[IND] - Indianapolis - United States",
            "searchName": "IND-Indianapolis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PWM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Portland",
            "name": "[PWM] - Portland - United States",
            "searchName": "PWM-Portland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PUW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pullman",
            "name": "[PUW] - Pullman - United States",
            "searchName": "PUW-Pullman-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MSS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Massena",
            "name": "[MSS] - Massena - United States",
            "searchName": "MSS-Massena-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PDX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Portland",
            "name": "[PDX] - Portland - United States",
            "searchName": "PDX-Portland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MNZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Manassas",
            "name": "[MNZ] - Manassas - United States",
            "searchName": "MNZ-Manassas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SDY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sidney",
            "name": "[SDY] - Sidney - United States",
            "searchName": "SDY-Sidney-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SGN",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Ho Chi Minh City",
            "name": "[SGN] - Ho Chi Minh City - Vietnam",
            "searchName": "SGN-Ho Chi Minh Stadt-Ho Chi Minh City-Ciudad Ho Chi Minh-Ho Chi Minh Ville-Ho Chi Minh-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "MOD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Modesto",
            "name": "[MOD] - Modesto - United States",
            "searchName": "MOD-Modesto-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DAD",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Da Nang",
            "name": "[DAD] - Da Nang - Vietnam",
            "searchName": "DAD-Da Nang-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "PZB",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Pietermaritzburg",
            "name": "[PZB] - Pietermaritzburg - South Africa",
            "searchName": "PZB-Pietermaritzburg-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "SQH",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Son-La",
            "name": "[SQH] - Son-La - Vietnam",
            "searchName": "SQH-Son-La-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "MAG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Madang",
            "name": "[MAG] - Madang - Papua New Guinea",
            "searchName": "MAG-Madang-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PTY",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Panama City",
            "name": "[PTY] - Panama City - Panama",
            "searchName": "PTY-Panama Stadt-Panama City-Panama Ville-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "DAV",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "David",
            "name": "[DAV] - David - Panama",
            "searchName": "DAV-David-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "PPL",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Phaplu",
            "name": "[PPL] - Phaplu - Nepal",
            "searchName": "PPL-Phaplu-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "CHC",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Christchurch",
            "name": "[CHC] - Christchurch - New Zealand",
            "searchName": "CHC-Christchurch-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "KLO",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Kalibo",
            "name": "[KLO] - Kalibo - Philippines",
            "searchName": "KLO-Kalibo-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "MMK",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Murmansk",
            "name": "[MMK] - Murmansk - Russia",
            "searchName": "MMK-Murmansk-M-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "SGC",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Surgut",
            "name": "[SGC] - Surgut - Russia",
            "searchName": "SGC-Surgut-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "PEZ",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Penza",
            "name": "[PEZ] - Penza - Russia",
            "searchName": "PEZ-Pensa-Penza-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "UUS",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Yuzhno-Sakhalinsk",
            "name": "[UUS] - Yuzhno-Sakhalinsk - Russia",
            "searchName": "UUS-Juschno-Sachalinsk-Yuzhno-Sakhalinsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "FLW",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Flores Island",
            "name": "[FLW] - Flores Island - Portugal",
            "searchName": "FLW-Flores Island--Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "PKV",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Pskov",
            "name": "[PKV] - Pskov - Russia",
            "searchName": "PKV-Pskov-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "DYR",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Anadyr",
            "name": "[DYR] - Anadyr - Russia",
            "searchName": "DYR-Anadyr-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "SQO",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Storuman",
            "name": "[SQO] - Storuman - Sweden",
            "searchName": "SQO-Storuman-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "DNZ",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Denizli",
            "name": "[DNZ] - Denizli - Turkey",
            "searchName": "DNZ-Denizli-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "MMA",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Malmo",
            "name": "[MMA] - Malmo - Sweden",
            "searchName": "MMA-Malm-Malmo-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "TAL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tanana",
            "name": "[TAL] - Tanana - United States",
            "searchName": "TAL-Tanana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CMI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Champaign",
            "name": "[CMI] - Champaign - United States",
            "searchName": "CMI-Champaign-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LOZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "London",
            "name": "[LOZ] - London - United States",
            "searchName": "LOZ-London-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ASR",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Kayseri",
            "name": "[ASR] - Kayseri - Turkey",
            "searchName": "ASR-Kayseri-Cesarea in Cappadocia-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "HUN",
            "countryCode": "TW",
            "cityCode": null,
            "cityName": "Hualien",
            "name": "[HUN] - Hualien - Taiwan",
            "searchName": "HUN-Hua lien-Hualien-Hualian-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "MPW",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Mariupol",
            "name": "[MPW] - Mariupol - Ukraine",
            "searchName": "MPW-Mariupol-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "FMN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Farmington",
            "name": "[FMN] - Farmington - United States",
            "searchName": "FMN-Farmington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FKL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Franklin",
            "name": "[FKL] - Franklin - United States",
            "searchName": "FKL-Franklin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CNM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Carlsbad",
            "name": "[CNM] - Carlsbad - United States",
            "searchName": "CNM-Carlsbad-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BAX",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Barnaul",
            "name": "[BAX] - Barnaul - Russia",
            "searchName": "BAX-Barnaul-Barnaoul-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NQT",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Nottingham",
            "name": "[NQT] - Nottingham - United Kingdom",
            "searchName": "NQT-Nottingham-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "NRL",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "North Ronaldsay",
            "name": "[NRL] - North Ronaldsay - United Kingdom",
            "searchName": "NRL-North Ronaldsay-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "OXF",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Oxford",
            "name": "[OXF] - Oxford - United Kingdom",
            "searchName": "OXF-Oxford-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "OUK",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Outer Skerries",
            "name": "[OUK] - Outer Skerries - United Kingdom",
            "searchName": "OUK-Outer Skerries-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ORM",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Northampton",
            "name": "[ORM] - Northampton - United Kingdom",
            "searchName": "ORM-Northampton-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "GND",
            "countryCode": "GD",
            "cityCode": null,
            "cityName": "St. Georges",
            "name": "[GND] - St. Georges - Grenada",
            "searchName": "GND-Grenada-St. Georges-Grenade-Granada",
            "priority": 0
        },
        {
            "iataCode": "CRU",
            "countryCode": "GD",
            "cityCode": null,
            "cityName": "Carriacou Island",
            "name": "[CRU] - Carriacou Island - Grenada",
            "searchName": "CRU-Carriacou Island-Grenada-Grenade-Granada",
            "priority": 0
        },
        {
            "iataCode": "PKH",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Porto Kheli",
            "name": "[PKH] - Porto Kheli - Greece",
            "searchName": "PKH-Porto Kheli-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "PYR",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Pyrgos",
            "name": "[PYR] - Pyrgos - Greece",
            "searchName": "PYR-Pyrgos-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "PVK",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Preveza",
            "name": "[PVK] - Preveza - Greece",
            "searchName": "PVK-Preveza/Lefkas-Preveza-Pr-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "RHO",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Rhodes",
            "name": "[RHO] - Rhodes - Greece",
            "searchName": "RHO-Rhodos-Rhodes-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "HRT",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Harrogate",
            "name": "[HRT] - Harrogate - United Kingdom",
            "searchName": "HRT-Harrogate FL-Harrogate-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "HOY",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Hoy Island",
            "name": "[HOY] - Hoy Island - United Kingdom",
            "searchName": "HOY-Hoy Island-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ISC",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "St Marys, Isles Of Scilly",
            "name": "[ISC] - St Marys, Isles Of Scilly - United Kingdom",
            "searchName": "ISC-Isles Of Scilly-St Marys- Isles Of Scilly-Isles of Scilly-St Marys, Isles Of Scilly-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "NDY",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Sanday",
            "name": "[NDY] - Sanday - United Kingdom",
            "searchName": "NDY-Sanday-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "OKH",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Oakham",
            "name": "[OKH] - Oakham - United Kingdom",
            "searchName": "OKH-Oakham-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "OBN",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Oban",
            "name": "[OBN] - Oban - United Kingdom",
            "searchName": "OBN-Oban-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ODH",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Odiham",
            "name": "[ODH] - Odiham - United Kingdom",
            "searchName": "ODH-Odiham-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "NWI",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Norwich",
            "name": "[NWI] - Norwich - United Kingdom",
            "searchName": "NWI-Norwich-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "NQY",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Newquay",
            "name": "[NQY] - Newquay - United Kingdom",
            "searchName": "NQY-Newquay-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QEW",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Leicester",
            "name": "[QEW] - Leicester - United Kingdom",
            "searchName": "QEW-Leicester-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QCY",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Coningsby",
            "name": "[QCY] - Coningsby - United Kingdom",
            "searchName": "QCY-Coningsby-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QDH",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Ashford",
            "name": "[QDH] - Ashford - United Kingdom",
            "searchName": "QDH-Ashford-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "RCS",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Rochester",
            "name": "[RCS] - Rochester - United Kingdom",
            "searchName": "RCS-Rochester-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "SOY",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Stronsay",
            "name": "[SOY] - Stronsay - United Kingdom",
            "searchName": "SOY-Stronsay-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "SOU",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Eastleigh near Southampton",
            "name": "[SOU] - Eastleigh near Southampton - United Kingdom",
            "searchName": "SOU-Southampton-Eastleigh near Southampton-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "SKL",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Isle Of Skye",
            "name": "[SKL] - Isle Of Skye - United Kingdom",
            "searchName": "SKL-Isle of Skye-Isle Of Skye-Isle Of Skye Hebrides Islands-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "SDZ",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Shetland Islands",
            "name": "[SDZ] - Shetland Islands - United Kingdom",
            "searchName": "SDZ-Shetland Islands-Lerwick-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "MSE",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Manston",
            "name": "[MSE] - Manston - United Kingdom",
            "searchName": "MSE-Manston-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "MME",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Teesside",
            "name": "[MME] - Teesside - United Kingdom",
            "searchName": "MME-Teesside-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "NHT",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Northolt",
            "name": "[NHT] - Northolt - United Kingdom",
            "searchName": "NHT-Northolt-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "INV",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Inverness",
            "name": "[INV] - Inverness - United Kingdom",
            "searchName": "INV-Inverness-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "IPW",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Ipswich",
            "name": "[IPW] - Ipswich - United Kingdom",
            "searchName": "IPW-Ipswich-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ILY",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Islay",
            "name": "[ILY] - Islay - United Kingdom",
            "searchName": "ILY-Islay-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "HYC",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "High Wycombe",
            "name": "[HYC] - High Wycombe - United Kingdom",
            "searchName": "HYC-High Wycombe-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "LMO",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Lossiemouth",
            "name": "[LMO] - Lossiemouth - United Kingdom",
            "searchName": "LMO-Lossiemouth-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "LKZ",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Brandon",
            "name": "[LKZ] - Brandon - United Kingdom",
            "searchName": "LKZ-Brandon-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "MHZ",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Mildenhall",
            "name": "[MHZ] - Mildenhall - United Kingdom",
            "searchName": "MHZ-Mildenhall-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "LYE",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Lyneham",
            "name": "[LYE] - Lyneham - United Kingdom",
            "searchName": "LYE-Lyneham-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "LYX",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Lydd",
            "name": "[LYX] - Lydd - United Kingdom",
            "searchName": "LYX-Lydd-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "GSJ",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "Puerto San Jose",
            "name": "[GSJ] - Puerto San Jose - Guatemala",
            "searchName": "GSJ-Puerto San Jose-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "GUA",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "Guatemala City",
            "name": "[GUA] - Guatemala City - Guatemala",
            "searchName": "GUA-Guatemala-Guatemala City",
            "priority": 0
        },
        {
            "iataCode": "FRS",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "Flores",
            "name": "[FRS] - Flores - Guatemala",
            "searchName": "FRS-Flores-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "HUG",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "Huehuetenango",
            "name": "[HUG] - Huehuetenango - Guatemala",
            "searchName": "HUG-Huehuetenango-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "LCF",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "Rio Dulce",
            "name": "[LCF] - Rio Dulce - Guatemala",
            "searchName": "LCF-Rio Dulce-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "MCR",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "Melchor De Menco",
            "name": "[MCR] - Melchor De Menco - Guatemala",
            "searchName": "MCR-Melchor de Mencos-Melchor De Menco-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "LOX",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "Los Tablones",
            "name": "[LOX] - Los Tablones - Guatemala",
            "searchName": "LOX-Los Tablones-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "CIQ",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "Chiquimula",
            "name": "[CIQ] - Chiquimula - Guatemala",
            "searchName": "CIQ-Chiquimula-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "CMM",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "Carmelita",
            "name": "[CMM] - Carmelita - Guatemala",
            "searchName": "CMM-Carmelita-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "CTF",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "Coatepeque",
            "name": "[CTF] - Coatepeque - Guatemala",
            "searchName": "CTF-Coatepeque-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "DON",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "Dos Lagunas",
            "name": "[DON] - Dos Lagunas - Guatemala",
            "searchName": "DON-Dos Lagunas-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "ENJ",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "El Naranjo",
            "name": "[ENJ] - El Naranjo - Guatemala",
            "searchName": "ENJ-El Naranjo-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "AAZ",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "Quetzaltenango",
            "name": "[AAZ] - Quetzaltenango - Guatemala",
            "searchName": "AAZ-Quetzaltenango-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "AQB",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "Quiche",
            "name": "[AQB] - Quiche - Guatemala",
            "searchName": "AQB-Quiche-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "CBV",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "Coban",
            "name": "[CBV] - Coban - Guatemala",
            "searchName": "CBV-Coban-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "PBR",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "Puerto Barrios",
            "name": "[PBR] - Puerto Barrios - Guatemala",
            "searchName": "PBR-Puerto Barrios-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "PCG",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "Paso Caballos",
            "name": "[PCG] - Paso Caballos - Guatemala",
            "searchName": "PCG-Paso Caballos-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "RUV",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "Rubelsanto",
            "name": "[RUV] - Rubelsanto - Guatemala",
            "searchName": "RUV-Rubelsanto-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "RER",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "Retalhuleu",
            "name": "[RER] - Retalhuleu - Guatemala",
            "searchName": "RER-Retalhuleu-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "PKJ",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "Playa Grande",
            "name": "[PKJ] - Playa Grande - Guatemala",
            "searchName": "PKJ-Playa Grande-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "PON",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "Poptun",
            "name": "[PON] - Poptun - Guatemala",
            "searchName": "PON-Poptun-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "UAX",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "Uaxactun",
            "name": "[UAX] - Uaxactun - Guatemala",
            "searchName": "UAX-Uaxactun-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "TKM",
            "countryCode": "GT",
            "cityCode": null,
            "cityName": "Tikal",
            "name": "[TKM] - Tikal - Guatemala",
            "searchName": "TKM-Tikal-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "GUM",
            "countryCode": "GU",
            "cityCode": null,
            "cityName": "Agana",
            "name": "[GUM] - Agana - Guam",
            "searchName": "GUM-Tamuning-Agana-Guam-Guama-Guamas",
            "priority": 0
        },
        {
            "iataCode": "BQE",
            "countryCode": "GW",
            "cityCode": null,
            "cityName": "Bubaque",
            "name": "[BQE] - Bubaque - Guinea-Bissau",
            "searchName": "BQE-Bubaque-Guinea-Bissau-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "OXB",
            "countryCode": "GW",
            "cityCode": null,
            "cityName": "Bissau",
            "name": "[OXB] - Bissau - Guinea-Bissau",
            "searchName": "OXB-Bissau-Biss-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "ORJ",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Orinduik",
            "name": "[ORJ] - Orinduik - Guyana",
            "searchName": "ORJ-Orinduik-Guyana",
            "priority": 0
        },
        {
            "iataCode": "OGL",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Ogle",
            "name": "[OGL] - Ogle - Guyana",
            "searchName": "OGL-Ogle-Guyana",
            "priority": 0
        },
        {
            "iataCode": "NAI",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Annai",
            "name": "[NAI] - Annai - Guyana",
            "searchName": "NAI-Annai-Guyana",
            "priority": 0
        },
        {
            "iataCode": "MYM",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Monkey Mountain",
            "name": "[MYM] - Monkey Mountain - Guyana",
            "searchName": "MYM-Monkey Mountain-Guyana",
            "priority": 0
        },
        {
            "iataCode": "MWJ",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Matthews Ridge",
            "name": "[MWJ] - Matthews Ridge - Guyana",
            "searchName": "MWJ-Matthews Ridge-Guyana",
            "priority": 0
        },
        {
            "iataCode": "PRR",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Paruima",
            "name": "[PRR] - Paruima - Guyana",
            "searchName": "PRR-Paruima-Guyana",
            "priority": 0
        },
        {
            "iataCode": "PKM",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Port Kaituma",
            "name": "[PKM] - Port Kaituma - Guyana",
            "searchName": "PKM-Port Kaituma-Guyana",
            "priority": 0
        },
        {
            "iataCode": "PIQ",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Pipillipai",
            "name": "[PIQ] - Pipillipai - Guyana",
            "searchName": "PIQ-Pipillipai-Guyana",
            "priority": 0
        },
        {
            "iataCode": "PMT",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Paramakotoi",
            "name": "[PMT] - Paramakotoi - Guyana",
            "searchName": "PMT-Paramakotoi-Guyana",
            "priority": 0
        },
        {
            "iataCode": "SDC",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Sandcreek",
            "name": "[SDC] - Sandcreek - Guyana",
            "searchName": "SDC-Sandcreek-Guyana",
            "priority": 0
        },
        {
            "iataCode": "SKM",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Skeldon",
            "name": "[SKM] - Skeldon - Guyana",
            "searchName": "SKM-Skeldon-Guyana",
            "priority": 0
        },
        {
            "iataCode": "USI",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Mabaruma",
            "name": "[USI] - Mabaruma - Guyana",
            "searchName": "USI-Mabaruma-Guyana",
            "priority": 0
        },
        {
            "iataCode": "VEG",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Maikwak",
            "name": "[VEG] - Maikwak - Guyana",
            "searchName": "VEG-Maikwak-Guyana",
            "priority": 0
        },
        {
            "iataCode": "BMJ",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Baramita",
            "name": "[BMJ] - Baramita - Guyana",
            "searchName": "BMJ-Baramita-Guyana",
            "priority": 0
        },
        {
            "iataCode": "BCG",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Bemichi",
            "name": "[BCG] - Bemichi - Guyana",
            "searchName": "BCG-Bemichi-Guyana",
            "priority": 0
        },
        {
            "iataCode": "AHL",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Aishalton",
            "name": "[AHL] - Aishalton - Guyana",
            "searchName": "AHL-Aishalton-Guyana",
            "priority": 0
        },
        {
            "iataCode": "EKE",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Ekereku",
            "name": "[EKE] - Ekereku - Guyana",
            "searchName": "EKE-Ekereku-Guyana",
            "priority": 0
        },
        {
            "iataCode": "GEO",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Georgetown",
            "name": "[GEO] - Georgetown - Guyana",
            "searchName": "GEO-Georgetown-Guyana",
            "priority": 0
        },
        {
            "iataCode": "GFO",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Bartica",
            "name": "[GFO] - Bartica - Guyana",
            "searchName": "GFO-Bartica-Guyana",
            "priority": 0
        },
        {
            "iataCode": "IMB",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Imbaimadai",
            "name": "[IMB] - Imbaimadai - Guyana",
            "searchName": "IMB-Imbaimadai-Guyana",
            "priority": 0
        },
        {
            "iataCode": "KAI",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Kaieteur",
            "name": "[KAI] - Kaieteur - Guyana",
            "searchName": "KAI-Kaieteur-Guyana",
            "priority": 0
        },
        {
            "iataCode": "KAR",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Kamarang",
            "name": "[KAR] - Kamarang - Guyana",
            "searchName": "KAR-Kamarang-Guyana",
            "priority": 0
        },
        {
            "iataCode": "LTM",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Lethem",
            "name": "[LTM] - Lethem - Guyana",
            "searchName": "LTM-Lethem-Guyana",
            "priority": 0
        },
        {
            "iataCode": "LUB",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Lumid Pau",
            "name": "[LUB] - Lumid Pau - Guyana",
            "searchName": "LUB-Lumid Pau-Guyana",
            "priority": 0
        },
        {
            "iataCode": "MHA",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Mahdia",
            "name": "[MHA] - Mahdia - Guyana",
            "searchName": "MHA-Mahdia-Guyana",
            "priority": 0
        },
        {
            "iataCode": "KTO",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Kato",
            "name": "[KTO] - Kato - Guyana",
            "searchName": "KTO-Kato-Guyana",
            "priority": 0
        },
        {
            "iataCode": "KRM",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Karanambo",
            "name": "[KRM] - Karanambo - Guyana",
            "searchName": "KRM-Karanambo-Guyana",
            "priority": 0
        },
        {
            "iataCode": "KPG",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Kurupung",
            "name": "[KPG] - Kurupung - Guyana",
            "searchName": "KPG-Kurupung-Guyana",
            "priority": 0
        },
        {
            "iataCode": "KRG",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Karasabai",
            "name": "[KRG] - Karasabai - Guyana",
            "searchName": "KRG-Karasabai-Guyana",
            "priority": 0
        },
        {
            "iataCode": "KKG",
            "countryCode": "GY",
            "cityCode": null,
            "cityName": "Konawaruk",
            "name": "[KKG] - Konawaruk - Guyana",
            "searchName": "KKG-Konawaruk-Guyana",
            "priority": 0
        },
        {
            "iataCode": "XAU",
            "countryCode": "GF",
            "cityCode": null,
            "cityName": "Saul",
            "name": "[XAU] - Saul - French Guiana",
            "searchName": "XAU-Saul-Französisch-Guayana-French Guiana-Fransiz Guyanasi-Guyane française-Guayana Francesa-Guiana Francese",
            "priority": 0
        },
        {
            "iataCode": "OYP",
            "countryCode": "GF",
            "cityCode": null,
            "cityName": "St Georges de lOyapock",
            "name": "[OYP] - St Georges de lOyapock - French Guiana",
            "searchName": "OYP-Saint Georges de Loy\\/ Oyapock-St Georges de lOyapock-Französisch-Guayana-French Guiana-Fransiz Guyanasi-Guyane française-Guayana Francesa-Guiana Francese",
            "priority": 0
        },
        {
            "iataCode": "MPY",
            "countryCode": "GF",
            "cityCode": null,
            "cityName": "Maripasoula",
            "name": "[MPY] - Maripasoula - French Guiana",
            "searchName": "MPY-Maripasoula-Französisch-Guayana-French Guiana-Fransiz Guyanasi-Guyane française-Guayana Francesa-Guiana Francese",
            "priority": 0
        },
        {
            "iataCode": "REI",
            "countryCode": "GF",
            "cityCode": null,
            "cityName": "Regina",
            "name": "[REI] - Regina - French Guiana",
            "searchName": "REI-Regina-Französisch-Guayana-French Guiana-Fransiz Guyanasi-Guyane française-Guayana Francesa-Guiana Francese",
            "priority": 0
        },
        {
            "iataCode": "LDX",
            "countryCode": "GF",
            "cityCode": null,
            "cityName": "St Laurent du Maroni",
            "name": "[LDX] - St Laurent du Maroni - French Guiana",
            "searchName": "LDX-St Laurent du Maroni-Saint Laurent du Maroni-Französisch-Guayana-French Guiana-Fransiz Guyanasi-Guyane française-Guayana Francesa-Guiana Francese",
            "priority": 0
        },
        {
            "iataCode": "CAY",
            "countryCode": "GF",
            "cityCode": null,
            "cityName": "Cayenne",
            "name": "[CAY] - Cayenne - French Guiana",
            "searchName": "CAY-Cayenne-Caienna-Französisch-Guayana-French Guiana-Fransiz Guyanasi-Guyane française-Guayana Francesa-Guiana Francese",
            "priority": 0
        },
        {
            "iataCode": "ACI",
            "countryCode": "GG",
            "cityCode": null,
            "cityName": "Alderney",
            "name": "[ACI] - Alderney - Guernsey",
            "searchName": "ACI-Alderney-Guernsey-Großbritannien-Birlesik Krallik-Guernesey",
            "priority": 0
        },
        {
            "iataCode": "GCI",
            "countryCode": "GG",
            "cityCode": null,
            "cityName": "Guernsey",
            "name": "[GCI] - Guernsey - Guernsey",
            "searchName": "GCI-Guernsey-Großbritannien-Birlesik Krallik-Guernesey",
            "priority": 0
        },
        {
            "iataCode": "KMS",
            "countryCode": "GH",
            "cityCode": null,
            "cityName": "Kumasi",
            "name": "[KMS] - Kumasi - Ghana",
            "searchName": "KMS-Kumasi-Ghána-Ghana-Gana",
            "priority": 0
        },
        {
            "iataCode": "ACC",
            "countryCode": "GH",
            "cityCode": null,
            "cityName": "Accra",
            "name": "[ACC] - Accra - Ghana",
            "searchName": "ACC-Accra-Ghána-Ghana-Gana",
            "priority": 0
        },
        {
            "iataCode": "NYI",
            "countryCode": "GH",
            "cityCode": null,
            "cityName": "Sunyani",
            "name": "[NYI] - Sunyani - Ghana",
            "searchName": "NYI-Sunyani-Ghána-Ghana-Gana",
            "priority": 0
        },
        {
            "iataCode": "TML",
            "countryCode": "GH",
            "cityCode": null,
            "cityName": "Tamale",
            "name": "[TML] - Tamale - Ghana",
            "searchName": "TML-Tamale-Ghána-Ghana-Gana",
            "priority": 0
        },
        {
            "iataCode": "TKD",
            "countryCode": "GH",
            "cityCode": null,
            "cityName": "Takoradi",
            "name": "[TKD] - Takoradi - Ghana",
            "searchName": "TKD-Takoradi-Sekondi-Ghána-Ghana-Gana",
            "priority": 0
        },
        {
            "iataCode": "GIB",
            "countryCode": "GI",
            "cityCode": null,
            "cityName": "Gibraltar",
            "name": "[GIB] - Gibraltar - Gibraltar",
            "searchName": "GIB-Gibraltar-Gibilterra-Cebelitarik",
            "priority": 0
        },
        {
            "iataCode": "GOH",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Nuuk",
            "name": "[GOH] - Nuuk - Greenland",
            "searchName": "GOH-Nuuk-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JCH",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Qasigiannguit",
            "name": "[JCH] - Qasigiannguit - Greenland",
            "searchName": "JCH-Qasigiannguit-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JAV",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Ilulissat",
            "name": "[JAV] - Ilulissat - Greenland",
            "searchName": "JAV-Ilulissat-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JEG",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Aasiaat",
            "name": "[JEG] - Aasiaat - Greenland",
            "searchName": "JEG-Aasiaat-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JFR",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Paamiut",
            "name": "[JFR] - Paamiut - Greenland",
            "searchName": "JFR-Paamiut-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JUV",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Upernavik",
            "name": "[JUV] - Upernavik - Greenland",
            "searchName": "JUV-Upernavik-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JSU",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Maniitsoq",
            "name": "[JSU] - Maniitsoq - Greenland",
            "searchName": "JSU-Maniitsoq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JRK",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Arsuk",
            "name": "[JRK] - Arsuk - Greenland",
            "searchName": "JRK-Arsuk-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JQA",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Qaarsut",
            "name": "[JQA] - Qaarsut - Greenland",
            "searchName": "JQA-Qaarsut-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JNN",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Nanortalik",
            "name": "[JNN] - Nanortalik - Greenland",
            "searchName": "JNN-Nanortalik-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JNS",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Narsaq",
            "name": "[JNS] - Narsaq - Greenland",
            "searchName": "JNS-Narsaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JHS",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Sisimiut",
            "name": "[JHS] - Sisimiut - Greenland",
            "searchName": "JHS-Sisimiut-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JGO",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Qeqertarsuaq",
            "name": "[JGO] - Qeqertarsuaq - Greenland",
            "searchName": "JGO-Qeqertarsuaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JGR",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Groennedal",
            "name": "[JGR] - Groennedal - Greenland",
            "searchName": "JGR-Gronnedal-Groennedal-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JJU",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Qaqortoq",
            "name": "[JJU] - Qaqortoq - Greenland",
            "searchName": "JJU-Qaqortoq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "KUS",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Kulusuk",
            "name": "[KUS] - Kulusuk - Greenland",
            "searchName": "KUS-Kulusuk-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "LLU",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Alluitsup Paa",
            "name": "[LLU] - Alluitsup Paa - Greenland",
            "searchName": "LLU-Alluitsup Paa-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "AGM",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Tasiilaq",
            "name": "[AGM] - Tasiilaq - Greenland",
            "searchName": "AGM-Tasiilaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "CNP",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Neerlerit Inaat",
            "name": "[CNP] - Neerlerit Inaat - Greenland",
            "searchName": "CNP-Neerlerit Inaat-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "DUN",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Dundas",
            "name": "[DUN] - Dundas - Greenland",
            "searchName": "DUN-Dundas-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "THU",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Pituffik",
            "name": "[THU] - Pituffik - Greenland",
            "searchName": "THU-Pituffik-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "UMD",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Uummannaq",
            "name": "[UMD] - Uummannaq - Greenland",
            "searchName": "UMD-Uummannaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "UAK",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Narsarsuaq",
            "name": "[UAK] - Narsarsuaq - Greenland",
            "searchName": "UAK-Narsarsuaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "XEO",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Oqatsut",
            "name": "[XEO] - Oqatsut - Greenland",
            "searchName": "XEO-Oqatsut-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "XEQ",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Tasiuasaq",
            "name": "[XEQ] - Tasiuasaq - Greenland",
            "searchName": "XEQ-Tasiuasaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "XIQ",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Ilimanaq",
            "name": "[XIQ] - Ilimanaq - Greenland",
            "searchName": "XIQ-Ilimanaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "OBY",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Ittoqqortoormiit",
            "name": "[OBY] - Ittoqqortoormiit - Greenland",
            "searchName": "OBY-Ittoqqortoormiit-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "NAQ",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Qaanaaq",
            "name": "[NAQ] - Qaanaaq - Greenland",
            "searchName": "NAQ-Qaanaaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "QFI",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Iginniarfik",
            "name": "[QFI] - Iginniarfik - Greenland",
            "searchName": "QFI-Iginniarfik-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "QCU",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Akunnaaq",
            "name": "[QCU] - Akunnaaq - Greenland",
            "searchName": "QCU-Akunnaaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "QGQ",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Attu",
            "name": "[QGQ] - Attu - Greenland",
            "searchName": "QGQ-Attu-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "QJE",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Kitsissuarsuit",
            "name": "[QJE] - Kitsissuarsuit - Greenland",
            "searchName": "QJE-Kitsissuarsuit-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "QJI",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Ikamiut",
            "name": "[QJI] - Ikamiut - Greenland",
            "searchName": "QJI-Ikamiut-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "QMK",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Niaqornaarsuk",
            "name": "[QMK] - Niaqornaarsuk - Greenland",
            "searchName": "QMK-Niaqornaarsuk-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "QUP",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Saqqaq",
            "name": "[QUP] - Saqqaq - Greenland",
            "searchName": "QUP-Saqqaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "QPW",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Kangaatsiaq",
            "name": "[QPW] - Kangaatsiaq - Greenland",
            "searchName": "QPW-Kangaatsiaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "QRY",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Ikerasaarsuk",
            "name": "[QRY] - Ikerasaarsuk - Greenland",
            "searchName": "QRY-Ikerasaarsuk-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "PQT",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Qeqertaq",
            "name": "[PQT] - Qeqertaq - Greenland",
            "searchName": "PQT-Qeqertaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "SFJ",
            "countryCode": "GL",
            "cityCode": null,
            "cityName": "Kangerlussuaq",
            "name": "[SFJ] - Kangerlussuaq - Greenland",
            "searchName": "SFJ-Kangerlussuaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "BJL",
            "countryCode": "GM",
            "cityCode": null,
            "cityName": "Banjul",
            "name": "[BJL] - Banjul - Gambia",
            "searchName": "BJL-Banjul-Gambia-Gambie",
            "priority": 0
        },
        {
            "iataCode": "BKJ",
            "countryCode": "GN",
            "cityCode": null,
            "cityName": "Boke",
            "name": "[BKJ] - Boke - Guinea",
            "searchName": "BKJ-Boke-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "FAA",
            "countryCode": "GN",
            "cityCode": null,
            "cityName": "Faranah",
            "name": "[FAA] - Faranah - Guinea",
            "searchName": "FAA-Faranah-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "FIG",
            "countryCode": "GN",
            "cityCode": null,
            "cityName": "Fria",
            "name": "[FIG] - Fria - Guinea",
            "searchName": "FIG-Fria-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "CKY",
            "countryCode": "GN",
            "cityCode": null,
            "cityName": "Conakry",
            "name": "[CKY] - Conakry - Guinea",
            "searchName": "CKY-Conakry-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "MCA",
            "countryCode": "GN",
            "cityCode": null,
            "cityName": "Macenta",
            "name": "[MCA] - Macenta - Guinea",
            "searchName": "MCA-Macenta-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "KSI",
            "countryCode": "GN",
            "cityCode": null,
            "cityName": "Kissidougou",
            "name": "[KSI] - Kissidougou - Guinea",
            "searchName": "KSI-Kissidougou-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "LEK",
            "countryCode": "GN",
            "cityCode": null,
            "cityName": "Labe",
            "name": "[LEK] - Labe - Guinea",
            "searchName": "LEK-Labe-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "KNN",
            "countryCode": "GN",
            "cityCode": null,
            "cityName": "Kankan",
            "name": "[KNN] - Kankan - Guinea",
            "searchName": "KNN-Kankan-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "GII",
            "countryCode": "GN",
            "cityCode": null,
            "cityName": "Siguiri",
            "name": "[GII] - Siguiri - Guinea",
            "searchName": "GII-Siguiri-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "SBI",
            "countryCode": "GN",
            "cityCode": null,
            "cityName": "Koundara",
            "name": "[SBI] - Koundara - Guinea",
            "searchName": "SBI-Koundara-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "NZE",
            "countryCode": "GN",
            "cityCode": null,
            "cityName": "Nzerekore",
            "name": "[NZE] - Nzerekore - Guinea",
            "searchName": "NZE-Nzerekore-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "SFC",
            "countryCode": "GP",
            "cityCode": null,
            "cityName": "St Francois",
            "name": "[SFC] - St Francois - Guadeloupe",
            "searchName": "SFC-St Francois-Guadeloupe-Guadalupe-Guadalupa",
            "priority": 0
        },
        {
            "iataCode": "PTP",
            "countryCode": "GP",
            "cityCode": null,
            "cityName": "Pointe-a-pitre",
            "name": "[PTP] - Pointe-a-pitre - Guadeloupe",
            "searchName": "PTP-Pointe--a-pitre-Guadeloupe-Guadalupe-Guadalupa",
            "priority": 0
        },
        {
            "iataCode": "GBJ",
            "countryCode": "GP",
            "cityCode": null,
            "cityName": "Marie Galante",
            "name": "[GBJ] - Marie Galante - Guadeloupe",
            "searchName": "GBJ-Marie Galante-Guadeloupe-Guadalupe-Guadalupa",
            "priority": 0
        },
        {
            "iataCode": "HTB",
            "countryCode": "GP",
            "cityCode": null,
            "cityName": "Terre-de-Bas",
            "name": "[HTB] - Terre-de-Bas - Guadeloupe",
            "searchName": "HTB-Terre-de-Bas-Guadeloupe-Guadalupe-Guadalupa",
            "priority": 0
        },
        {
            "iataCode": "LSS",
            "countryCode": "GP",
            "cityCode": null,
            "cityName": "Terre-de-Haut",
            "name": "[LSS] - Terre-de-Haut - Guadeloupe",
            "searchName": "LSS-Terre-de-Haut-Guadeloupe-Guadalupe-Guadalupa",
            "priority": 0
        },
        {
            "iataCode": "DSD",
            "countryCode": "GP",
            "cityCode": null,
            "cityName": "La Desirade",
            "name": "[DSD] - La Desirade - Guadeloupe",
            "searchName": "DSD-La Desirade-Guadeloupe-Guadalupe-Guadalupa",
            "priority": 0
        },
        {
            "iataCode": "BBR",
            "countryCode": "GP",
            "cityCode": null,
            "cityName": "Basse Terre",
            "name": "[BBR] - Basse Terre - Guadeloupe",
            "searchName": "BBR-Basse Terre-Guadeloupe-Guadalupe-Guadalupa",
            "priority": 0
        },
        {
            "iataCode": "BSG",
            "countryCode": "GQ",
            "cityCode": null,
            "cityName": "Bata",
            "name": "[BSG] - Bata - Equatorial Guinea",
            "searchName": "BSG-Bata-Äquatorialguinea-Equatorial Guinea-Ekvator Ginesi-Guinée équatoriale-Guinea Ecuatorial-Guinea Equatoriale",
            "priority": 0
        },
        {
            "iataCode": "SSG",
            "countryCode": "GQ",
            "cityCode": null,
            "cityName": "Malabo",
            "name": "[SSG] - Malabo - Equatorial Guinea",
            "searchName": "SSG-Malabo-Santa Isabel-Äquatorialguinea-Equatorial Guinea-Ekvator Ginesi-Guinée équatoriale-Guinea Ecuatorial-Guinea Equatoriale",
            "priority": 0
        },
        {
            "iataCode": "VOL",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Volos",
            "name": "[VOL] - Volos - Greece",
            "searchName": "VOL-Volos-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "DEB",
            "countryCode": "HU",
            "cityCode": null,
            "cityName": "Debrecen",
            "name": "[DEB] - Debrecen - Hungary",
            "searchName": "DEB-Debrecen-Hongarije-Hungary-Hung ga ry-Ungern-Wegry-Hongaria-Hungria-Ungarn-Hungría-Ungheria-Vengrija-Ungarija-Madarska-Ungar-Macaristan-Hongrie",
            "priority": 0
        },
        {
            "iataCode": "HVZ",
            "countryCode": "HU",
            "cityCode": null,
            "cityName": "Heviz",
            "name": "[HVZ] - Heviz - Hungary",
            "searchName": "HVZ-Heviz-Hongarije-Hungary-Hung ga ry-Ungern-Wegry-Hongaria-Hungria-Ungarn-Hungría-Ungheria-Vengrija-Ungarija-Madarska-Ungar-Macaristan-Hongrie",
            "priority": 0
        },
        {
            "iataCode": "MCQ",
            "countryCode": "HU",
            "cityCode": null,
            "cityName": "Miskolc",
            "name": "[MCQ] - Miskolc - Hungary",
            "searchName": "MCQ-Miskolc-Hongarije-Hungary-Hung ga ry-Ungern-Wegry-Hongaria-Hungria-Ungarn-Hungría-Ungheria-Vengrija-Ungarija-Madarska-Ungar-Macaristan-Hongrie",
            "priority": 0
        },
        {
            "iataCode": "PEV",
            "countryCode": "HU",
            "cityCode": null,
            "cityName": "Pecs",
            "name": "[PEV] - Pecs - Hungary",
            "searchName": "PEV-Pecs/Pecs-Pecs-Hongarije-Hungary-Hung ga ry-Ungern-Wegry-Hongaria-Hungria-Ungarn-Hungría-Ungheria-Vengrija-Ungarija-Madarska-Ungar-Macaristan-Hongrie",
            "priority": 0
        },
        {
            "iataCode": "QGY",
            "countryCode": "HU",
            "cityCode": null,
            "cityName": "Per",
            "name": "[QGY] - Per - Hungary",
            "searchName": "QGY-Per-Hongarije-Hungary-Hung ga ry-Ungern-Wegry-Hongaria-Hungria-Ungarn-Hungría-Ungheria-Vengrija-Ungarija-Madarska-Ungar-Macaristan-Hongrie",
            "priority": 0
        },
        {
            "iataCode": "QZD",
            "countryCode": "HU",
            "cityCode": null,
            "cityName": "Szeged",
            "name": "[QZD] - Szeged - Hungary",
            "searchName": "QZD-Szeged/Szeged-Szeged-Hongarije-Hungary-Hung ga ry-Ungern-Wegry-Hongaria-Hungria-Ungarn-Hungría-Ungheria-Vengrija-Ungarija-Madarska-Ungar-Macaristan-Hongrie",
            "priority": 0
        },
        {
            "iataCode": "SOB",
            "countryCode": "HU",
            "cityCode": null,
            "cityName": "Sármellék",
            "name": "[SOB] - Sármellék - Hungary",
            "searchName": "SOB-Sarmellek-S-Sármellék-Hongarije-Hungary-Hung ga ry-Ungern-Wegry-Hongaria-Hungria-Ungarn-Hungría-Ungheria-Vengrija-Ungarija-Madarska-Ungar-Macaristan-Hongrie",
            "priority": 0
        },
        {
            "iataCode": "ZLG",
            "countryCode": "HU",
            "cityCode": null,
            "cityName": "El Gouera",
            "name": "[ZLG] - El Gouera - Hungary",
            "searchName": "ZLG-El Gouera-Hongarije-Hungary-Hung ga ry-Ungern-Wegry-Hongaria-Hungria-Ungarn-Hungría-Ungheria-Vengrija-Ungarija-Madarska-Ungar-Macaristan-Hongrie",
            "priority": 0
        },
        {
            "iataCode": "ZKL",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Steenkool",
            "name": "[ZKL] - Steenkool - Indonesia",
            "searchName": "ZKL-Steenkool-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ZRI",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Serui",
            "name": "[ZRI] - Serui - Indonesia",
            "searchName": "ZRI-Serui-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ZRM",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Sarmi",
            "name": "[ZRM] - Sarmi - Indonesia",
            "searchName": "ZRM-Sarmi-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ZEG",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Senggo",
            "name": "[ZEG] - Senggo - Indonesia",
            "searchName": "ZEG-Senggo-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "WSR",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Wasior",
            "name": "[WSR] - Wasior - Indonesia",
            "searchName": "WSR-Wasior-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "WGP",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Waingapu",
            "name": "[WGP] - Waingapu - Indonesia",
            "searchName": "WGP-Waingapu-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "WMX",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Wamena",
            "name": "[WMX] - Wamena - Indonesia",
            "searchName": "WMX-Wamena-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TBM",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Tumbang Samba",
            "name": "[TBM] - Tumbang Samba - Indonesia",
            "searchName": "TBM-Tumbang Samba-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TAX",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Taliabu",
            "name": "[TAX] - Taliabu - Indonesia",
            "searchName": "TAX-Taliabu-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SXK",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Saumlaki",
            "name": "[SXK] - Saumlaki - Indonesia",
            "searchName": "SXK-Saumlaki-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SZH",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Senipah",
            "name": "[SZH] - Senipah - Indonesia",
            "searchName": "SZH-Senipah-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SQR",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Soroako",
            "name": "[SQR] - Soroako - Indonesia",
            "searchName": "SQR-Soroako-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SQN",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Sanana",
            "name": "[SQN] - Sanana - Indonesia",
            "searchName": "SQN-Sanana-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SRI",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Samarinda",
            "name": "[SRI] - Samarinda - Indonesia",
            "searchName": "SRI-Samarinda-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SRG",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Semarang",
            "name": "[SRG] - Semarang - Indonesia",
            "searchName": "SRG-Semarang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SUP",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Sumenep",
            "name": "[SUP] - Sumenep - Indonesia",
            "searchName": "SUP-Sumenep-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SUB",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Surabaya",
            "name": "[SUB] - Surabaya - Indonesia",
            "searchName": "SUB-Surabaya-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SWQ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Sumbawa",
            "name": "[SWQ] - Sumbawa - Indonesia",
            "searchName": "SWQ-Sumbawa-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TJG",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Tanjung Warukin",
            "name": "[TJG] - Tanjung Warukin - Indonesia",
            "searchName": "TJG-Tanjung Warukin-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TJQ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Tanjung Pandan",
            "name": "[TJQ] - Tanjung Pandan - Indonesia",
            "searchName": "TJQ-Tanjung Pandan-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TJS",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Tanjung Selor",
            "name": "[TJS] - Tanjung Selor - Indonesia",
            "searchName": "TJS-Tanjung Selor-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TJB",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Tanjung Balai",
            "name": "[TJB] - Tanjung Balai - Indonesia",
            "searchName": "TJB-Tanjung Balai-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TKG",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Bandar Lampung",
            "name": "[TKG] - Bandar Lampung - Indonesia",
            "searchName": "TKG-Bandar Lampung-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TIM",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Tembagapura",
            "name": "[TIM] - Tembagapura - Indonesia",
            "searchName": "TIM-Tembagapura-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TMH",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Tanahmerah",
            "name": "[TMH] - Tanahmerah - Indonesia",
            "searchName": "TMH-Tanahmerah-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TNJ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Tanjung Pinang",
            "name": "[TNJ] - Tanjung Pinang - Indonesia",
            "searchName": "TNJ-Tanjung Pinang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TMY",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Tiom",
            "name": "[TMY] - Tiom - Indonesia",
            "searchName": "TMY-Tiom-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TNB",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Tanah Grogot",
            "name": "[TNB] - Tanah Grogot - Indonesia",
            "searchName": "TNB-Tanah Grogot-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TMC",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Tambolaka",
            "name": "[TMC] - Tambolaka - Indonesia",
            "searchName": "TMC-Tambolaka-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TLI",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Tolitoli",
            "name": "[TLI] - Tolitoli - Indonesia",
            "searchName": "TLI-Tolitoli-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TPK",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Tapaktuan",
            "name": "[TPK] - Tapaktuan - Indonesia",
            "searchName": "TPK-Tapaktuan-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "UGU",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Zugapa",
            "name": "[UGU] - Zugapa - Indonesia",
            "searchName": "UGU-Zugapa-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "UBR",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Ubrub",
            "name": "[UBR] - Ubrub - Indonesia",
            "searchName": "UBR-Ubrub-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "UPG",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Ujung Pandang",
            "name": "[UPG] - Ujung Pandang - Indonesia",
            "searchName": "UPG-Ujung Pandang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "UOL",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Buol",
            "name": "[UOL] - Buol - Indonesia",
            "searchName": "UOL-Buol-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TXM",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Teminabuan",
            "name": "[TXM] - Teminabuan - Indonesia",
            "searchName": "TXM-Teminabuan-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TTR",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Tana Toraja",
            "name": "[TTR] - Tana Toraja - Indonesia",
            "searchName": "TTR-Tana Toraja-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TTE",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Ternate",
            "name": "[TTE] - Ternate - Indonesia",
            "searchName": "TTE-Ternate-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TSX",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Tanjung Santan",
            "name": "[TSX] - Tanjung Santan - Indonesia",
            "searchName": "TSX-Tanjung Santan-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TSY",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Tasikmalaya",
            "name": "[TSY] - Tasikmalaya - Indonesia",
            "searchName": "TSY-Tasikmalaya-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TRK",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Tarakan",
            "name": "[TRK] - Tarakan - Indonesia",
            "searchName": "TRK-Tarakan-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "WET",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Wagethe",
            "name": "[WET] - Wagethe - Indonesia",
            "searchName": "WET-Wagethe-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "WBA",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Wahai",
            "name": "[WBA] - Wahai - Indonesia",
            "searchName": "WBA-Wahai-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "WAR",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Waris",
            "name": "[WAR] - Waris - Indonesia",
            "searchName": "WAR-Waris-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SOC",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Solo City",
            "name": "[SOC] - Solo City - Indonesia",
            "searchName": "SOC-Solo City-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SQG",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Sintang",
            "name": "[SQG] - Sintang - Indonesia",
            "searchName": "SQG-Sintang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SOQ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Sorong",
            "name": "[SOQ] - Sorong - Indonesia",
            "searchName": "SOQ-Sorong-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SMQ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Sampit",
            "name": "[SMQ] - Sampit - Indonesia",
            "searchName": "SMQ-Sampit-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SEH",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Senggeh",
            "name": "[SEH] - Senggeh - Indonesia",
            "searchName": "SEH-Senggeh-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SGQ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Sanggata",
            "name": "[SGQ] - Sanggata - Indonesia",
            "searchName": "SGQ-Sanggata-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SIQ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Singkep",
            "name": "[SIQ] - Singkep - Indonesia",
            "searchName": "SIQ-Singkep Island-Singkep-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SIW",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Sibisa",
            "name": "[SIW] - Sibisa - Indonesia",
            "searchName": "SIW-Sibisa-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "RKI",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Rokot",
            "name": "[RKI] - Rokot - Indonesia",
            "searchName": "RKI-Rokot-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "RKO",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Sipora",
            "name": "[RKO] - Sipora - Indonesia",
            "searchName": "RKO-Sipora-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "RTG",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Ruteng",
            "name": "[RTG] - Ruteng - Indonesia",
            "searchName": "RTG-Ruteng-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "RTI",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Roti",
            "name": "[RTI] - Roti - Indonesia",
            "searchName": "RTI-Roti-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "RUF",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Yuruf",
            "name": "[RUF] - Yuruf - Indonesia",
            "searchName": "RUF-Yuruf-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "RSK",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Ransiki",
            "name": "[RSK] - Ransiki - Indonesia",
            "searchName": "RSK-Ransiki-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SAU",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Sawu",
            "name": "[SAU] - Sawu - Indonesia",
            "searchName": "SAU-Sawu-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SAE",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Sangir",
            "name": "[SAE] - Sangir - Indonesia",
            "searchName": "SAE-Sangir-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SBG",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Sabang",
            "name": "[SBG] - Sabang - Indonesia",
            "searchName": "SBG-Sabang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SEQ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Sungai Pakning",
            "name": "[SEQ] - Sungai Pakning - Indonesia",
            "searchName": "SEQ-Sungaipinang-Sungai Pakning-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "RAQ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Raha",
            "name": "[RAQ] - Raha - Indonesia",
            "searchName": "RAQ-Raha-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "RDE",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Merdey",
            "name": "[RDE] - Merdey - Indonesia",
            "searchName": "RDE-Merdey-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "RGT",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Rengat",
            "name": "[RGT] - Rengat - Indonesia",
            "searchName": "RGT-Rengat-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PWL",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Purwokerto",
            "name": "[PWL] - Purwokerto - Indonesia",
            "searchName": "PWL-Purwokerto-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PNK",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Pontianak",
            "name": "[PNK] - Pontianak - Indonesia",
            "searchName": "PNK-Pontianak-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PLW",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Palu",
            "name": "[PLW] - Palu - Indonesia",
            "searchName": "PLW-Palu-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PLM",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Palembang",
            "name": "[PLM] - Palembang - Indonesia",
            "searchName": "PLM-Palembang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PKN",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Pangkalanbuun",
            "name": "[PKN] - Pangkalanbuun - Indonesia",
            "searchName": "PKN-Pangkalanbuun-Pangkalanboun-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PKU",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Pekanbaru",
            "name": "[PKU] - Pekanbaru - Indonesia",
            "searchName": "PKU-Pekanbaru-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PKY",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Palangkaraya",
            "name": "[PKY] - Palangkaraya - Indonesia",
            "searchName": "PKY-Palangkaraya-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PPR",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Pasir Pangarayan",
            "name": "[PPR] - Pasir Pangarayan - Indonesia",
            "searchName": "PPR-Pasir Pangarayan-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PPJ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Pulau Panjang",
            "name": "[PPJ] - Pulau Panjang - Indonesia",
            "searchName": "PPJ-Pulau Panjang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PUM",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Pomala",
            "name": "[PUM] - Pomala - Indonesia",
            "searchName": "PUM-Pomala-Pomalaa-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PSJ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Poso",
            "name": "[PSJ] - Poso - Indonesia",
            "searchName": "PSJ-Poso-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PSU",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Putussibau",
            "name": "[PSU] - Putussibau - Indonesia",
            "searchName": "PSU-Putussibau-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PDG",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Padang",
            "name": "[PDG] - Padang - Indonesia",
            "searchName": "PDG-Padang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PDO",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Pendopo",
            "name": "[PDO] - Pendopo - Indonesia",
            "searchName": "PDO-Pendopo-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PGK",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Pangkalpinang",
            "name": "[PGK] - Pangkalpinang - Indonesia",
            "searchName": "PGK-Pangkalpinang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PCB",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Pondok Cabe",
            "name": "[PCB] - Pondok Cabe - Indonesia",
            "searchName": "PCB-Pondok Cabe-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PBW",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Palibelo",
            "name": "[PBW] - Palibelo - Indonesia",
            "searchName": "PBW-Palibelo-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ONI",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Moanamani",
            "name": "[ONI] - Moanamani - Indonesia",
            "searchName": "ONI-Moanamani-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "OTI",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Pitu",
            "name": "[OTI] - Pitu - Indonesia",
            "searchName": "OTI-Morotai Island-Pitu-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "NTX",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Natuna Ranai",
            "name": "[NTX] - Natuna Ranai - Indonesia",
            "searchName": "NTX-Ranai-Natuna Ranai-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "NTI",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Bintuni",
            "name": "[NTI] - Bintuni - Indonesia",
            "searchName": "NTI-Bintuni-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "NRE",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Namrole",
            "name": "[NRE] - Namrole - Indonesia",
            "searchName": "NRE-Namrole-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "NNX",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Nunukan",
            "name": "[NNX] - Nunukan - Indonesia",
            "searchName": "NNX-Nunukan-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "NPO",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Nangapinoh",
            "name": "[NPO] - Nangapinoh - Indonesia",
            "searchName": "NPO-Nangapinoh-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "OBD",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Obano",
            "name": "[OBD] - Obano - Indonesia",
            "searchName": "OBD-Obano-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "OKQ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Okaba",
            "name": "[OKQ] - Okaba - Indonesia",
            "searchName": "OKQ-Okaba-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "OKL",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Oksibil",
            "name": "[OKL] - Oksibil - Indonesia",
            "searchName": "OKL-Oksibil-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MSI",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Masalembo",
            "name": "[MSI] - Masalembo - Indonesia",
            "searchName": "MSI-Masalembo-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MUF",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Muting",
            "name": "[MUF] - Muting - Indonesia",
            "searchName": "MUF-Muting-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MJU",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Mamuju",
            "name": "[MJU] - Mamuju - Indonesia",
            "searchName": "MJU-Mamuju-Mamujui-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MKQ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Merauke",
            "name": "[MKQ] - Merauke - Indonesia",
            "searchName": "MKQ-Merauke-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MKW",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Manokwari",
            "name": "[MKW] - Manokwari - Indonesia",
            "searchName": "MKW-Manokwari-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MJY",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Mangunjaya",
            "name": "[MJY] - Mangunjaya - Indonesia",
            "searchName": "MJY-Mangunjaya-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MLG",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Malang",
            "name": "[MLG] - Malang - Indonesia",
            "searchName": "MLG-Malang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MPC",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Muko-Muko",
            "name": "[MPC] - Muko-Muko - Indonesia",
            "searchName": "MPC-Muko-Muko Muko-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MOF",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Maumere",
            "name": "[MOF] - Maumere - Indonesia",
            "searchName": "MOF-Maumere-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MNA",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Melangguane",
            "name": "[MNA] - Melangguane - Indonesia",
            "searchName": "MNA-Melangguane-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MWK",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Matak",
            "name": "[MWK] - Matak - Indonesia",
            "searchName": "MWK-Matak-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MXB",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Masamba",
            "name": "[MXB] - Masamba - Indonesia",
            "searchName": "MXB-Masamba-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "NAH",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Naha",
            "name": "[NAH] - Naha - Indonesia",
            "searchName": "NAH-Naha-Tahuna-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "NAF",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Banaina",
            "name": "[NAF] - Banaina - Indonesia",
            "searchName": "NAF-Banaina-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "NAM",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Namlea",
            "name": "[NAM] - Namlea - Indonesia",
            "searchName": "NAM-Namlea-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "NBX",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Nabire",
            "name": "[NBX] - Nabire - Indonesia",
            "searchName": "NBX-Nabire-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "NDA",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Bandanaira",
            "name": "[NDA] - Bandanaira - Indonesia",
            "searchName": "NDA-Bandanaira-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "NKD",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Sinak",
            "name": "[NKD] - Sinak - Indonesia",
            "searchName": "NKD-Sinak-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MDC",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Manado",
            "name": "[MDC] - Manado - Indonesia",
            "searchName": "MDC-Manado-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LWE",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Lewoleba",
            "name": "[LWE] - Lewoleba - Indonesia",
            "searchName": "LWE-Lewoleba-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MAL",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Mangole",
            "name": "[MAL] - Mangole - Indonesia",
            "searchName": "MAL-Mangole-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LYK",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Lunyuk",
            "name": "[LYK] - Lunyuk - Indonesia",
            "searchName": "LYK-Lunyuk-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MEQ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Meulaboh",
            "name": "[MEQ] - Meulaboh - Indonesia",
            "searchName": "MEQ-Meulaboh-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MES",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Medan",
            "name": "[MES] - Medan - Indonesia",
            "searchName": "MES-Medan-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MDP",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Mindiptana",
            "name": "[MDP] - Mindiptana - Indonesia",
            "searchName": "MDP-Mindiptana-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LSW",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Lhoksumawe",
            "name": "[LSW] - Lhoksumawe - Indonesia",
            "searchName": "LSW-Lhoksumawe-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LSX",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Lhok Sukon",
            "name": "[LSX] - Lhok Sukon - Indonesia",
            "searchName": "LSX-Lhok Sukon-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LUV",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Langgur",
            "name": "[LUV] - Langgur - Indonesia",
            "searchName": "LUV-Langgur-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LUW",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Luwuk",
            "name": "[LUW] - Luwuk - Indonesia",
            "searchName": "LUW-Luwuk-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LOP",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Praya",
            "name": "[LOP] - Praya - Indonesia",
            "searchName": "LOP-Lombok-Praya-Mataram-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LPU",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Long Apung",
            "name": "[LPU] - Long Apung - Indonesia",
            "searchName": "LPU-Long Apung-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LII",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Mulia",
            "name": "[LII] - Mulia - Indonesia",
            "searchName": "LII-Mulia-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LHI",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Lereh",
            "name": "[LHI] - Lereh - Indonesia",
            "searchName": "LHI-Lereh-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LKA",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Larantuka",
            "name": "[LKA] - Larantuka - Indonesia",
            "searchName": "LKA-Larantuka-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LLN",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Kelila",
            "name": "[LLN] - Kelila - Indonesia",
            "searchName": "LLN-Kelila-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LBW",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Long Bawan",
            "name": "[LBW] - Long Bawan - Indonesia",
            "searchName": "LBW-Long Bawan-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LBJ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Labuan Bajo",
            "name": "[LBJ] - Labuan Bajo - Indonesia",
            "searchName": "LBJ-Labuan Bajo-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LAH",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Labuha",
            "name": "[LAH] - Labuha - Indonesia",
            "searchName": "LAH-Labuha-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KRC",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Kerinci",
            "name": "[KRC] - Kerinci - Indonesia",
            "searchName": "KRC-Kerinci-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KTG",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Ketapang",
            "name": "[KTG] - Ketapang - Indonesia",
            "searchName": "KTG-Ketapang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KWB",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Karimunjawa",
            "name": "[KWB] - Karimunjawa - Indonesia",
            "searchName": "KWB-Karimunjawa-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KLQ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Keluang",
            "name": "[KLQ] - Keluang - Indonesia",
            "searchName": "KLQ-Keluang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KMM",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Kimam",
            "name": "[KMM] - Kimam - Indonesia",
            "searchName": "KMM-Kimam-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KNG",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Kaimana",
            "name": "[KNG] - Kaimana - Indonesia",
            "searchName": "KNG-Kaimana-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KOD",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Kotabangun",
            "name": "[KOD] - Kotabangun - Indonesia",
            "searchName": "KOD-Kotabangun-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KOX",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Kokonao",
            "name": "[KOX] - Kokonao - Indonesia",
            "searchName": "KOX-Kokonao-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KEQ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Kebar",
            "name": "[KEQ] - Kebar - Indonesia",
            "searchName": "KEQ-Kebar-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KEI",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Kepi",
            "name": "[KEI] - Kepi - Indonesia",
            "searchName": "KEI-Kepi-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KEA",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Keisah",
            "name": "[KEA] - Keisah - Indonesia",
            "searchName": "KEA-Keisah-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KDI",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Kendari",
            "name": "[KDI] - Kendari - Indonesia",
            "searchName": "KDI-Kendari-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KCI",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Kon",
            "name": "[KCI] - Kon - Indonesia",
            "searchName": "KCI-Kon-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KAZ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Kau",
            "name": "[KAZ] - Kau - Indonesia",
            "searchName": "KAZ-Kau-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KBF",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Karubaga",
            "name": "[KBF] - Karubaga - Indonesia",
            "searchName": "KBF-Karubaga-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KBX",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Kambuaya",
            "name": "[KBX] - Kambuaya - Indonesia",
            "searchName": "KBX-Kambuaya-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KCD",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Kamur",
            "name": "[KCD] - Kamur - Indonesia",
            "searchName": "KCD-Kamur-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KBU",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Kotabaru",
            "name": "[KBU] - Kotabaru - Indonesia",
            "searchName": "KBU-Kotabaru-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "INX",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Inanwatan",
            "name": "[INX] - Inanwatan - Indonesia",
            "searchName": "INX-Inanwatan-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ILA",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Illaga",
            "name": "[ILA] - Illaga - Indonesia",
            "searchName": "ILA-Illaga-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "IUL",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Ilu",
            "name": "[IUL] - Ilu - Indonesia",
            "searchName": "IUL-Ilu-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "JOG",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Yogyakarta",
            "name": "[JOG] - Yogyakarta - Indonesia",
            "searchName": "JOG-Yogyakarta-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "JDH",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Jodhpur",
            "name": "[JDH] - Jodhpur - India",
            "searchName": "JDH-Jodhpur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "JGA",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Jamnagar",
            "name": "[JGA] - Jamnagar - India",
            "searchName": "JGA-Jamnagar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "JGB",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Jagdalpur",
            "name": "[JGB] - Jagdalpur - India",
            "searchName": "JGB-Jagdalpur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "HBX",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Hubli",
            "name": "[HBX] - Hubli - India",
            "searchName": "HBX-Hubli-Dharwad-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "HJR",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Khajuraho",
            "name": "[HJR] - Khajuraho - India",
            "searchName": "HJR-Khajuraho-Khajur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "GWL",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Gwalior",
            "name": "[GWL] - Gwalior - India",
            "searchName": "GWL-Gwalior-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "GUX",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Guna",
            "name": "[GUX] - Guna - India",
            "searchName": "GUX-Guna-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXN",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Khowai",
            "name": "[IXN] - Khowai - India",
            "searchName": "IXN-Khowai-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXP",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Pathankot",
            "name": "[IXP] - Pathankot - India",
            "searchName": "IXP-Pathankot-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXQ",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Kamalpur",
            "name": "[IXQ] - Kamalpur - India",
            "searchName": "IXQ-Kamalpur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXR",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Ranchi",
            "name": "[IXR] - Ranchi - India",
            "searchName": "IXR-Ranchi-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXS",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Silchar",
            "name": "[IXS] - Silchar - India",
            "searchName": "IXS-Silchar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXT",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Pasighat",
            "name": "[IXT] - Pasighat - India",
            "searchName": "IXT-Pasighat-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXU",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Aurangabad",
            "name": "[IXU] - Aurangabad - India",
            "searchName": "IXU-Aurangabad-Aurang-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXV",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Along",
            "name": "[IXV] - Along - India",
            "searchName": "IXV-Along-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXW",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Jamshedpur",
            "name": "[IXW] - Jamshedpur - India",
            "searchName": "IXW-Jamshedpur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXY",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Kandla",
            "name": "[IXY] - Kandla - India",
            "searchName": "IXY-Kandla/Gandhidham-Kandla-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXZ",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Port Blair",
            "name": "[IXZ] - Port Blair - India",
            "searchName": "IXZ-Port Blair-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "GOP",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Gorakhpur",
            "name": "[GOP] - Gorakhpur - India",
            "searchName": "GOP-Gorakhpur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "GAY",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Gaya",
            "name": "[GAY] - Gaya - India",
            "searchName": "GAY-Gaya-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "GAU",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Gawahati",
            "name": "[GAU] - Gawahati - India",
            "searchName": "GAU-Guwahati-Gawahati-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "KTU",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Kota",
            "name": "[KTU] - Kota - India",
            "searchName": "KTU-Kota-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "KUU",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Bhuntar Kullu",
            "name": "[KUU] - Bhuntar Kullu - India",
            "searchName": "KUU-Kulu-Bhuntar Kullu-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "LDA",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Malda",
            "name": "[LDA] - Malda - India",
            "searchName": "LDA-Malda-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "KLH",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Kolhapur",
            "name": "[KLH] - Kolhapur - India",
            "searchName": "KLH-Kolhapur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "KNU",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Kanpur",
            "name": "[KNU] - Kanpur - India",
            "searchName": "KNU-Kanpur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "MAA",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Chennai",
            "name": "[MAA] - Chennai - India",
            "searchName": "MAA-Chennai-Madr-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "LTU",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Latur",
            "name": "[LTU] - Latur - India",
            "searchName": "LTU-Latur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "LUH",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Ludhiana",
            "name": "[LUH] - Ludhiana - India",
            "searchName": "LUH-Ludhiana-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "LKO",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Lucknow",
            "name": "[LKO] - Lucknow - India",
            "searchName": "LKO-Lucknow-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "BDQ",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Vadodara",
            "name": "[BDQ] - Vadodara - India",
            "searchName": "BDQ-Vadodara-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "BEK",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Bareli",
            "name": "[BEK] - Bareli - India",
            "searchName": "BEK-Bareli-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "BEP",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Bellary",
            "name": "[BEP] - Bellary - India",
            "searchName": "BEP-Bellary-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "BHU",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Bhavnagar",
            "name": "[BHU] - Bhavnagar - India",
            "searchName": "BHU-Bhavnagar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "BHO",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Bhopal",
            "name": "[BHO] - Bhopal - India",
            "searchName": "BHO-Bhopal-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "BHJ",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Bhuj",
            "name": "[BHJ] - Bhuj - India",
            "searchName": "BHJ-Bhuj-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXC",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Chandigarh",
            "name": "[IXC] - Chandigarh - India",
            "searchName": "IXC-Chandigarh-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXD",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Allahabad",
            "name": "[IXD] - Allahabad - India",
            "searchName": "IXD-Allahabad-All-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXE",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Mangalore",
            "name": "[IXE] - Mangalore - India",
            "searchName": "IXE-Mangaluru-Mangalore-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXG",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Belgaum",
            "name": "[IXG] - Belgaum - India",
            "searchName": "IXG-Belgaum-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXH",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Kailashahar",
            "name": "[IXH] - Kailashahar - India",
            "searchName": "IXH-Kailashahar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXI",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Lilabari",
            "name": "[IXI] - Lilabari - India",
            "searchName": "IXI-Lilabari-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXJ",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Jammu",
            "name": "[IXJ] - Jammu - India",
            "searchName": "IXJ-Jammu-Cammu-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXK",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Keshod",
            "name": "[IXK] - Keshod - India",
            "searchName": "IXK-Keshod-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "BEV",
            "countryCode": "IL",
            "cityCode": null,
            "cityName": "Beer Sheba",
            "name": "[BEV] - Beer Sheba - Israel",
            "searchName": "BEV-Beer Sheba-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "IOM",
            "countryCode": "IM",
            "cityCode": null,
            "cityName": "Isle Of Man",
            "name": "[IOM] - Isle Of Man - Isle of Man",
            "searchName": "IOM-Isle Of Man-Ile de Man-Isle of Man-Großbritannien-Birlesik Krallik-Isla de Man-Île de Man-Isola di Man",
            "priority": 0
        },
        {
            "iataCode": "IMF",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Imphal",
            "name": "[IMF] - Imphal - India",
            "searchName": "IMF-Imphal-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IDR",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Indore",
            "name": "[IDR] - Indore - India",
            "searchName": "IDR-Indore-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "HYD",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Hyderabad",
            "name": "[HYD] - Hyderabad - India",
            "searchName": "HYD-Hyderabad-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "HSS",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Hissar",
            "name": "[HSS] - Hissar - India",
            "searchName": "HSS-Hissar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "JSA",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Jaisalmer",
            "name": "[JSA] - Jaisalmer - India",
            "searchName": "JSA-Jaisalmer-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "JRH",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Jorhat",
            "name": "[JRH] - Jorhat - India",
            "searchName": "JRH-Jorhat-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "JLR",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Jabalpur",
            "name": "[JLR] - Jabalpur - India",
            "searchName": "JLR-Jabalpur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "ISK",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Nasik",
            "name": "[ISK] - Nasik - India",
            "searchName": "ISK-Nasik-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXA",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Agartala",
            "name": "[IXA] - Agartala - India",
            "searchName": "IXA-Agartala-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "JRS",
            "countryCode": "IL",
            "cityCode": null,
            "cityName": "Jerusalem",
            "name": "[JRS] - Jerusalem - Israel",
            "searchName": "JRS-Jerusalem-Kud-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "EIY",
            "countryCode": "IL",
            "cityCode": null,
            "cityName": "Ein Yahav",
            "name": "[EIY] - Ein Yahav - Israel",
            "searchName": "EIY-Ein Yahav-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "MTZ",
            "countryCode": "IL",
            "cityCode": null,
            "cityName": "Masada",
            "name": "[MTZ] - Masada - Israel",
            "searchName": "MTZ-Masada-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "MIP",
            "countryCode": "IL",
            "cityCode": null,
            "cityName": "Mitspeh Ramon",
            "name": "[MIP] - Mitspeh Ramon - Israel",
            "searchName": "MIP-Mitspeh Ramon-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "SNN",
            "countryCode": "IE",
            "cityCode": null,
            "cityName": "Shannon",
            "name": "[SNN] - Shannon - Ireland",
            "searchName": "SNN-Shannon-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "WAT",
            "countryCode": "IE",
            "cityCode": null,
            "cityName": "Waterford",
            "name": "[WAT] - Waterford - Ireland",
            "searchName": "WAT-Waterford-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "SXL",
            "countryCode": "IE",
            "cityCode": null,
            "cityName": "Sligo",
            "name": "[SXL] - Sligo - Ireland",
            "searchName": "SXL-Sligo-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "VTM",
            "countryCode": "IL",
            "cityCode": null,
            "cityName": "Nevatim",
            "name": "[VTM] - Nevatim - Israel",
            "searchName": "VTM-Nevatim-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "VDA",
            "countryCode": "IL",
            "cityCode": null,
            "cityName": "Ovda",
            "name": "[VDA] - Ovda - Israel",
            "searchName": "VDA-Ovda-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "YOT",
            "countryCode": "IL",
            "cityCode": null,
            "cityName": "Yotvata",
            "name": "[YOT] - Yotvata - Israel",
            "searchName": "YOT-Yotvata-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "SED",
            "countryCode": "IL",
            "cityCode": null,
            "cityName": "Sedom",
            "name": "[SED] - Sedom - Israel",
            "searchName": "SED-Sedom-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "TRZ",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Tiruchirapally",
            "name": "[TRZ] - Tiruchirapally - India",
            "searchName": "TRZ-Tiruchirappalli-Tiruchirapally-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "UDR",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Udaipur",
            "name": "[UDR] - Udaipur - India",
            "searchName": "UDR-Udaipur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "TNI",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Satna",
            "name": "[TNI] - Satna - India",
            "searchName": "TNI-Satna-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "TJV",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Thanjavur",
            "name": "[TJV] - Thanjavur - India",
            "searchName": "TJV-Thanjavur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "TIR",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Tirupati",
            "name": "[TIR] - Tirupati - India",
            "searchName": "TIR-Tirupati-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "TEZ",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Tezpur",
            "name": "[TEZ] - Tezpur - India",
            "searchName": "TEZ-Tezpur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "TEI",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Tezu",
            "name": "[TEI] - Tezu - India",
            "searchName": "TEI-Tezu-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "SXV",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Salem",
            "name": "[SXV] - Salem - India",
            "searchName": "SXV-Salem-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "SXR",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Srinagar",
            "name": "[SXR] - Srinagar - India",
            "searchName": "SXR-Srinagar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "TCR",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Tuticorin",
            "name": "[TCR] - Tuticorin - India",
            "searchName": "TCR-Tuticorin-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "STV",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Surat",
            "name": "[STV] - Surat - India",
            "searchName": "STV-Surat-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "SSE",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Sholapur",
            "name": "[SSE] - Sholapur - India",
            "searchName": "SSE-Sholapur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "TQD",
            "countryCode": "IQ",
            "cityCode": null,
            "cityName": "Al Fallujah",
            "name": "[TQD] - Al Fallujah - Iraq",
            "searchName": "TQD-Al Fallujah-Irak-Iraq",
            "priority": 0
        },
        {
            "iataCode": "RQW",
            "countryCode": "IQ",
            "cityCode": null,
            "cityName": "Qayyarah",
            "name": "[RQW] - Qayyarah - Iraq",
            "searchName": "RQW-Qayyarah-Irak-Iraq",
            "priority": 0
        },
        {
            "iataCode": "OSM",
            "countryCode": "IQ",
            "cityCode": null,
            "cityName": "Mosul",
            "name": "[OSM] - Mosul - Iraq",
            "searchName": "OSM-Mosul-Mossoul-Mossul-Irak-Iraq",
            "priority": 0
        },
        {
            "iataCode": "NJF",
            "countryCode": "IQ",
            "cityCode": null,
            "cityName": "Al Najaf",
            "name": "[NJF] - Al Najaf - Iraq",
            "searchName": "NJF-Bagdad-Al Najaf-Nayaf-Nadjaf-Najaf-Irak-Iraq",
            "priority": 0
        },
        {
            "iataCode": "EBL",
            "countryCode": "IQ",
            "cityCode": null,
            "cityName": "Erbil",
            "name": "[EBL] - Erbil - Iraq",
            "searchName": "EBL-Arbil-Erbil-Irak-Iraq",
            "priority": 0
        },
        {
            "iataCode": "BSR",
            "countryCode": "IQ",
            "cityCode": null,
            "cityName": "Basra",
            "name": "[BSR] - Basra - Iraq",
            "searchName": "BSR-Basra-Basora-Bassorah-B-Irak-Iraq",
            "priority": 0
        },
        {
            "iataCode": "BMN",
            "countryCode": "IQ",
            "cityCode": null,
            "cityName": "Bamerny",
            "name": "[BMN] - Bamerny - Iraq",
            "searchName": "BMN-Bamerny-Irak-Iraq",
            "priority": 0
        },
        {
            "iataCode": "BGW",
            "countryCode": "IQ",
            "cityCode": null,
            "cityName": "Baghdad",
            "name": "[BGW] - Baghdad - Iraq",
            "searchName": "BGW-Bagdad-Baghdad-Irak-Iraq",
            "priority": 0
        },
        {
            "iataCode": "KIK",
            "countryCode": "IQ",
            "cityCode": null,
            "cityName": "Kirkuk",
            "name": "[KIK] - Kirkuk - Iraq",
            "searchName": "KIK-Kirkuk-Irak-Iraq",
            "priority": 0
        },
        {
            "iataCode": "ISU",
            "countryCode": "IQ",
            "cityCode": null,
            "cityName": "Sulaimaniyah",
            "name": "[ISU] - Sulaimaniyah - Iraq",
            "searchName": "ISU-Silemani-Sulaimaniyah-Suleimaniya-Souleymaniah-Sulaymaniyah-Irak-Iraq",
            "priority": 0
        },
        {
            "iataCode": "IQA",
            "countryCode": "IQ",
            "cityCode": null,
            "cityName": "Al Asad",
            "name": "[IQA] - Al Asad - Iraq",
            "searchName": "IQA-Al Asad-Irak-Iraq",
            "priority": 0
        },
        {
            "iataCode": "IFN",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Isfahan",
            "name": "[IFN] - Isfahan - Iran",
            "searchName": "IFN-Isfahan-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "IFH",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Hesa",
            "name": "[IFH] - Hesa - Iran",
            "searchName": "IFH-Hesa-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "IIL",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Ilaam",
            "name": "[IIL] - Ilaam - Iran",
            "searchName": "IIL-Ilaam-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "IHR",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Iran Shahr",
            "name": "[IHR] - Iran Shahr - Iran",
            "searchName": "IHR-Iran Shahr-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "IAQ",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Bahregan",
            "name": "[IAQ] - Bahregan - Iran",
            "searchName": "IAQ-Bahregan-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "JAR",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Jahrom",
            "name": "[JAR] - Jahrom - Iran",
            "searchName": "JAR-Jahrom-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "JWN",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Zanjan",
            "name": "[JWN] - Zanjan - Iran",
            "searchName": "JWN-Zanjan-Zencan-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "JYR",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Jiroft",
            "name": "[JYR] - Jiroft - Iran",
            "searchName": "JYR-Jiroft-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "GBT",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Gorgan",
            "name": "[GBT] - Gorgan - Iran",
            "searchName": "GBT-Gorgan-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "GCH",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Gachsaran",
            "name": "[GCH] - Gachsaran - Iran",
            "searchName": "GCH-Gachsaran-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "GSM",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Gheshm",
            "name": "[GSM] - Gheshm - Iran",
            "searchName": "GSM-Gheshm-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "GZW",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Ghazvin",
            "name": "[GZW] - Ghazvin - Iran",
            "searchName": "GZW-Ghazvin-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "HDR",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Havadarya",
            "name": "[HDR] - Havadarya - Iran",
            "searchName": "HDR-Havadarya-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "HDM",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Hamadan",
            "name": "[HDM] - Hamadan - Iran",
            "searchName": "HDM-Hamadan-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "KIH",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Kish Island",
            "name": "[KIH] - Kish Island - Iran",
            "searchName": "KIH-Kish-Kish Island-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "KLM",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Kalaleh",
            "name": "[KLM] - Kalaleh - Iran",
            "searchName": "KLM-Kalaleh-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "KNR",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Kangan",
            "name": "[KNR] - Kangan - Iran",
            "searchName": "KNR-Kangan-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "KER",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Kerman",
            "name": "[KER] - Kerman - Iran",
            "searchName": "KER-Kerman-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "KHA",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Khaneh",
            "name": "[KHA] - Khaneh - Iran",
            "searchName": "KHA-Khaneh-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "KHD",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Khorramabad",
            "name": "[KHD] - Khorramabad - Iran",
            "searchName": "KHD-Khorramabad-H-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "KHK",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Khark Island",
            "name": "[KHK] - Khark Island - Iran",
            "searchName": "KHK-Khark Island-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "KHY",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Khoy",
            "name": "[KHY] - Khoy - Iran",
            "searchName": "KHY-Khoy-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "KSH",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Kermanshah",
            "name": "[KSH] - Kermanshah - Iran",
            "searchName": "KSH-Kermanshah-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "LFM",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Lamerd",
            "name": "[LFM] - Lamerd - Iran",
            "searchName": "LFM-Lamerd-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "LRR",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Lar",
            "name": "[LRR] - Lar - Iran",
            "searchName": "LRR-Lar-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "LVP",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Lavan",
            "name": "[LVP] - Lavan - Iran",
            "searchName": "LVP-Lavan Island-Lavan-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "MHD",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Mashad",
            "name": "[MHD] - Mashad - Iran",
            "searchName": "MHD-Mashad-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "BJB",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Bojnord",
            "name": "[BJB] - Bojnord - Iran",
            "searchName": "BJB-Bojnord-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "BND",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Bandar Abbas",
            "name": "[BND] - Bandar Abbas - Iran",
            "searchName": "BND-Bandar Abbas-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "BSM",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Bishe-kola",
            "name": "[BSM] - Bishe-kola - Iran",
            "searchName": "BSM-Bishe-kola-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "BUZ",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Bushehr",
            "name": "[BUZ] - Bushehr - Iran",
            "searchName": "BUZ-Bushehr-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "BXR",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Bam",
            "name": "[BXR] - Bam - Iran",
            "searchName": "BXR-Bam-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "AJK",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Araak",
            "name": "[AJK] - Araak - Iran",
            "searchName": "AJK-Araak-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "AKW",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Aghajari",
            "name": "[AKW] - Aghajari - Iran",
            "searchName": "AKW-Aghajari-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "AFZ",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Sabzevar",
            "name": "[AFZ] - Sabzevar - Iran",
            "searchName": "AFZ-Sabzevar-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "AEU",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Abu Musa",
            "name": "[AEU] - Abu Musa - Iran",
            "searchName": "AEU-Abu Musa-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "ADU",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Ardabil",
            "name": "[ADU] - Ardabil - Iran",
            "searchName": "ADU-Ardabil-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "ACZ",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Zabol",
            "name": "[ACZ] - Zabol - Iran",
            "searchName": "ACZ-Zabol-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "ACP",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Sahand",
            "name": "[ACP] - Sahand - Iran",
            "searchName": "ACP-Sahand-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "ABD",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Abadan",
            "name": "[ABD] - Abadan - Iran",
            "searchName": "ABD-Abadan-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "BDH",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Bandar Lengeh",
            "name": "[BDH] - Bandar Lengeh - Iran",
            "searchName": "BDH-Bandar Lengeh-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "AZD",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Yazd",
            "name": "[AZD] - Yazd - Iran",
            "searchName": "AZD-Yazd-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "AWZ",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Ahwaz",
            "name": "[AWZ] - Ahwaz - Iran",
            "searchName": "AWZ-Ahwaz-Ahvaz-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "FAZ",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Fasa",
            "name": "[FAZ] - Fasa - Iran",
            "searchName": "FAZ-Fasa-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "DEF",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Dezful",
            "name": "[DEF] - Dezful - Iran",
            "searchName": "DEF-Dezful-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "CKT",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Sarakhs",
            "name": "[CKT] - Sarakhs - Iran",
            "searchName": "CKT-Sarakhs-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "CQD",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Shahre-kord",
            "name": "[CQD] - Shahre-kord - Iran",
            "searchName": "CQD-Shahre-Kord-kord-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "MRX",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Bandar Mahshahr",
            "name": "[MRX] - Bandar Mahshahr - Iran",
            "searchName": "MRX-Bandar Mahshahr-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "PGU",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Asaloyeh",
            "name": "[PGU] - Asaloyeh - Iran",
            "searchName": "PGU-Asaloyeh-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "PFQ",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Parsabad",
            "name": "[PFQ] - Parsabad - Iran",
            "searchName": "PFQ-Parsabad-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "NUJ",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Nojeh",
            "name": "[NUJ] - Nojeh - Iran",
            "searchName": "NUJ-Nojeh-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "NSH",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Now Shahr",
            "name": "[NSH] - Now Shahr - Iran",
            "searchName": "NSH-Now Shahr-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "OMH",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Urmieh",
            "name": "[OMH] - Urmieh - Iran",
            "searchName": "OMH-Urmieh-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "OMI",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Omidieh",
            "name": "[OMI] - Omidieh - Iran",
            "searchName": "OMI-Omidieh-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "RJN",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Rafsanjan",
            "name": "[RJN] - Rafsanjan - Iran",
            "searchName": "RJN-Rafsanjan-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "RUD",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Shahrud",
            "name": "[RUD] - Shahrud - Iran",
            "searchName": "RUD-Shahrud-Shahroud-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "RZR",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Ramsar",
            "name": "[RZR] - Ramsar - Iran",
            "searchName": "RZR-Ramsar-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "SDG",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Sanandaj",
            "name": "[SDG] - Sanandaj - Iran",
            "searchName": "SDG-Sanandadsch-Sanandaj-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "RAS",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Rasht",
            "name": "[RAS] - Rasht - Iran",
            "searchName": "RAS-Rasht-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "PYK",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Karaj",
            "name": "[PYK] - Karaj - Iran",
            "searchName": "PYK-Karaj-Pyke-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "TCX",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Tabas",
            "name": "[TCX] - Tabas - Iran",
            "searchName": "TCX-Tabas-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "THR",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Tehran",
            "name": "[THR] - Tehran - Iran",
            "searchName": "THR-Teheran-Tehran-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "SRY",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Sary",
            "name": "[SRY] - Sary - Iran",
            "searchName": "SRY-Sari-Sary-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "TBZ",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Tabriz",
            "name": "[TBZ] - Tabriz - Iran",
            "searchName": "TBZ-T-Tabriz-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "SYJ",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Sirjan",
            "name": "[SYJ] - Sirjan - Iran",
            "searchName": "SYJ-Sirjan-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "SXI",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Sirri Island",
            "name": "[SXI] - Sirri Island - Iran",
            "searchName": "SXI-Sirri Island-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "SYZ",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Shiraz",
            "name": "[SYZ] - Shiraz - Iran",
            "searchName": "SYZ-Shiraz-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "ZBR",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Chah-bahar",
            "name": "[ZBR] - Chah-bahar - Iran",
            "searchName": "ZBR-Chah-Bahar-bahar--Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "ZAH",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Zahedan",
            "name": "[ZAH] - Zahedan - Iran",
            "searchName": "ZAH-Zahedan-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "YES",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Yasouj",
            "name": "[YES] - Yasouj - Iran",
            "searchName": "YES-Yasouj-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "XBJ",
            "countryCode": "IR",
            "cityCode": null,
            "cityName": "Birjand",
            "name": "[XBJ] - Birjand - Iran",
            "searchName": "XBJ-Birjand-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "SYK",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Stykkisholmur",
            "name": "[SYK] - Stykkisholmur - Iceland",
            "searchName": "SYK-Stykkisholmur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "THO",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Thorshofn",
            "name": "[THO] - Thorshofn - Iceland",
            "searchName": "THO-Thorshofn-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "TEY",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Thingeyri",
            "name": "[TEY] - Thingeyri - Iceland",
            "searchName": "TEY-Thingeyri-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "VPN",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Vopnafjordur",
            "name": "[VPN] - Vopnafjordur - Iceland",
            "searchName": "VPN-Vopnafjordur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "VEY",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Vestmannaeyjar",
            "name": "[VEY] - Vestmannaeyjar - Iceland",
            "searchName": "VEY-Vestmannaeyjar-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "PAX",
            "countryCode": "HT",
            "cityCode": null,
            "cityName": "Port De Paix",
            "name": "[PAX] - Port De Paix - Haiti",
            "searchName": "PAX-Port de Paix-Port De Paix-Haiti-Haïti-Haití",
            "priority": 0
        },
        {
            "iataCode": "PAP",
            "countryCode": "HT",
            "cityCode": null,
            "cityName": "Port Au Prince",
            "name": "[PAP] - Port Au Prince - Haiti",
            "searchName": "PAP-Port Au Prince-Haiti-Haïti-Haití",
            "priority": 0
        },
        {
            "iataCode": "JEE",
            "countryCode": "HT",
            "cityCode": null,
            "cityName": "Jeremie",
            "name": "[JEE] - Jeremie - Haiti",
            "searchName": "JEE-Jeremie-Haiti-Haïti-Haití",
            "priority": 0
        },
        {
            "iataCode": "JAK",
            "countryCode": "HT",
            "cityCode": null,
            "cityName": "Jacmel",
            "name": "[JAK] - Jacmel - Haiti",
            "searchName": "JAK-Jacmel-Haiti-Haïti-Haití",
            "priority": 0
        },
        {
            "iataCode": "CYA",
            "countryCode": "HT",
            "cityCode": null,
            "cityName": "Les Cayes",
            "name": "[CYA] - Les Cayes - Haiti",
            "searchName": "CYA-Les Cayes-Haiti-Haïti-Haití",
            "priority": 0
        },
        {
            "iataCode": "CAP",
            "countryCode": "HT",
            "cityCode": null,
            "cityName": "Cap Haitien",
            "name": "[CAP] - Cap Haitien - Haiti",
            "searchName": "CAP-Cap Haitien-Haiti-Haïti-Haití",
            "priority": 0
        },
        {
            "iataCode": "CFN",
            "countryCode": "IE",
            "cityCode": null,
            "cityName": "Carrickfinn",
            "name": "[CFN] - Carrickfinn - Ireland",
            "searchName": "CFN-Donegal-Carrickfinn-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "GWY",
            "countryCode": "IE",
            "cityCode": null,
            "cityName": "Galway",
            "name": "[GWY] - Galway - Ireland",
            "searchName": "GWY-Galway-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "IOR",
            "countryCode": "IE",
            "cityCode": null,
            "cityName": "Inishmore",
            "name": "[IOR] - Inishmore - Ireland",
            "searchName": "IOR-Inishmore-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "IIA",
            "countryCode": "IE",
            "cityCode": null,
            "cityName": "Galloway",
            "name": "[IIA] - Galloway - Ireland",
            "searchName": "IIA-Galloway-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "INQ",
            "countryCode": "IE",
            "cityCode": null,
            "cityName": "Inisheer",
            "name": "[INQ] - Inisheer - Ireland",
            "searchName": "INQ-Inisheer-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "KKY",
            "countryCode": "IE",
            "cityCode": null,
            "cityName": "Kilkenny",
            "name": "[KKY] - Kilkenny - Ireland",
            "searchName": "KKY-Kilkenny-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "KIR",
            "countryCode": "IE",
            "cityCode": null,
            "cityName": "Farranfore",
            "name": "[KIR] - Farranfore - Ireland",
            "searchName": "KIR-Kerry County-Farranfore-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "LTR",
            "countryCode": "IE",
            "cityCode": null,
            "cityName": "Letterkenny",
            "name": "[LTR] - Letterkenny - Ireland",
            "searchName": "LTR-Letterkenny-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "NNR",
            "countryCode": "IE",
            "cityCode": null,
            "cityName": "Spiddal",
            "name": "[NNR] - Spiddal - Ireland",
            "searchName": "NNR-Spiddal-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "NOC",
            "countryCode": "IE",
            "cityCode": null,
            "cityName": "Knock",
            "name": "[NOC] - Knock - Ireland",
            "searchName": "NOC-Knock-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "BJW",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Bajawa",
            "name": "[BJW] - Bajawa - Indonesia",
            "searchName": "BJW-Bajawa-Badjawa-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BKS",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Bengkulu",
            "name": "[BKS] - Bengkulu - Indonesia",
            "searchName": "BKS-Bengkulu-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BJK",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Benjina",
            "name": "[BJK] - Benjina - Indonesia",
            "searchName": "BJK-Benjina-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "AHI",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Amahai",
            "name": "[AHI] - Amahai - Indonesia",
            "searchName": "AHI-Amahai-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "AKQ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Astraksetra",
            "name": "[AKQ] - Astraksetra - Indonesia",
            "searchName": "AKQ-Astraksetra-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "AMQ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Ambon",
            "name": "[AMQ] - Ambon - Indonesia",
            "searchName": "AMQ-Ambon-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "AMI",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Mataram",
            "name": "[AMI] - Mataram - Indonesia",
            "searchName": "AMI-Mataram-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "AAS",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Apalapsili",
            "name": "[AAS] - Apalapsili - Indonesia",
            "searchName": "AAS-Apalapsili-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ABU",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Atambua",
            "name": "[ABU] - Atambua - Indonesia",
            "searchName": "ABU-Atambua-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "AGD",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Anggi",
            "name": "[AGD] - Anggi - Indonesia",
            "searchName": "AGD-Anggi-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "AEG",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Aek Godang",
            "name": "[AEG] - Aek Godang - Indonesia",
            "searchName": "AEG-Aek Godang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "AUT",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Atauro",
            "name": "[AUT] - Atauro - Indonesia",
            "searchName": "AUT-Atauro-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "AYW",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Ayawasi",
            "name": "[AYW] - Ayawasi - Indonesia",
            "searchName": "AYW-Ayawasi-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ARD",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Alor Island",
            "name": "[ARD] - Alor Island - Indonesia",
            "searchName": "ARD-Alor Island-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ARJ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Arso",
            "name": "[ARJ] - Arso - Indonesia",
            "searchName": "ARJ-Arso-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BLY",
            "countryCode": "IE",
            "cityCode": null,
            "cityName": "Belmullet",
            "name": "[BLY] - Belmullet - Ireland",
            "searchName": "BLY-Belmullet-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "BYT",
            "countryCode": "IE",
            "cityCode": null,
            "cityName": "Bantry",
            "name": "[BYT] - Bantry - Ireland",
            "searchName": "BYT-Bantry-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "GEB",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Gebe",
            "name": "[GEB] - Gebe - Indonesia",
            "searchName": "GEB-Gebe-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "GAV",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Gag Island",
            "name": "[GAV] - Gag Island - Indonesia",
            "searchName": "GAV-Gag Island-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "GNS",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Gunungsitoli",
            "name": "[GNS] - Gunungsitoli - Indonesia",
            "searchName": "GNS-Gunungsitoli-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "GLX",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Galela",
            "name": "[GLX] - Galela - Indonesia",
            "searchName": "GLX-Galela-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "GTO",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Gorontalo",
            "name": "[GTO] - Gorontalo - Indonesia",
            "searchName": "GTO-Gorontalo-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "DJJ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Jayapura",
            "name": "[DJJ] - Jayapura - Indonesia",
            "searchName": "DJJ-Jayapura-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "DJB",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Jambi",
            "name": "[DJB] - Jambi - Indonesia",
            "searchName": "DJB-Jambi-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "CXP",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Cilacap",
            "name": "[CXP] - Cilacap - Indonesia",
            "searchName": "CXP-Cilacap-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "CPF",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Cepu",
            "name": "[CPF] - Cepu - Indonesia",
            "searchName": "CPF-Cepu-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ENE",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Ende",
            "name": "[ENE] - Ende - Indonesia",
            "searchName": "ENE-Ende-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "EWE",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Ewer",
            "name": "[EWE] - Ewer - Indonesia",
            "searchName": "EWE-Ewer-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "EWI",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Enarotali",
            "name": "[EWI] - Enarotali - Indonesia",
            "searchName": "EWI-Enarotali-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "FKQ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Fak Fak",
            "name": "[FKQ] - Fak Fak - Indonesia",
            "searchName": "FKQ-Fakfak-Fak Fak-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "FOO",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Numfoor",
            "name": "[FOO] - Numfoor - Indonesia",
            "searchName": "FOO-Numfoor-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ELR",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Elelim",
            "name": "[ELR] - Elelim - Indonesia",
            "searchName": "ELR-Elelim-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "DOB",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Dobo",
            "name": "[DOB] - Dobo - Indonesia",
            "searchName": "DOB-Dobo-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "DRH",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Dabra",
            "name": "[DRH] - Dabra - Indonesia",
            "searchName": "DRH-Dabra-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "DUM",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Dumai",
            "name": "[DUM] - Dumai - Indonesia",
            "searchName": "DUM-Dumai-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "DTD",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Datadawai",
            "name": "[DTD] - Datadawai - Indonesia",
            "searchName": "DTD-Datadawai-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BTW",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Batu Licin",
            "name": "[BTW] - Batu Licin - Indonesia",
            "searchName": "BTW-Batu Licin-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BTJ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Banda Aceh",
            "name": "[BTJ] - Banda Aceh - Indonesia",
            "searchName": "BTJ-Banda Aceh-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BTH",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Batam",
            "name": "[BTH] - Batam - Indonesia",
            "searchName": "BTH-Batam-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BUW",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Baubau",
            "name": "[BUW] - Baubau - Indonesia",
            "searchName": "BUW-Baubau-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BUI",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Bokondini",
            "name": "[BUI] - Bokondini - Indonesia",
            "searchName": "BUI-Bokondini-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "CBN",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Cirebon",
            "name": "[CBN] - Cirebon - Indonesia",
            "searchName": "CBN-Cirebon-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BXM",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Batom",
            "name": "[BXM] - Batom - Indonesia",
            "searchName": "BXM-Batom-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BXT",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Bontang",
            "name": "[BXT] - Bontang - Indonesia",
            "searchName": "BXT-Bontang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BXB",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Babo",
            "name": "[BXB] - Babo - Indonesia",
            "searchName": "BXB-Babo-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BXD",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Bade",
            "name": "[BXD] - Bade - Indonesia",
            "searchName": "BXD-Bade-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BYQ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Bunyu",
            "name": "[BYQ] - Bunyu - Indonesia",
            "searchName": "BYQ-Bunyu-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BIK",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Biak",
            "name": "[BIK] - Biak - Indonesia",
            "searchName": "BIK-Biak-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BJG",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Bolaang",
            "name": "[BJG] - Bolaang - Indonesia",
            "searchName": "BJG-Bolaang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BEJ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Tanjung Redep",
            "name": "[BEJ] - Tanjung Redep - Indonesia",
            "searchName": "BEJ-Berau-Tanjung Redep-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BDJ",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Banjarmasin",
            "name": "[BDJ] - Banjarmasin - Indonesia",
            "searchName": "BDJ-Banjarmasin-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BDO",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Bandung",
            "name": "[BDO] - Bandung - Indonesia",
            "searchName": "BDO-Bandung-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BMU",
            "countryCode": "ID",
            "cityCode": null,
            "cityName": "Bima",
            "name": "[BMU] - Bima - Indonesia",
            "searchName": "BMU-Bima-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "GPA",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Patras",
            "name": "[GPA] - Patras - Greece",
            "searchName": "GPA-Patras-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "JKL",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Kalymnos Island",
            "name": "[JKL] - Kalymnos Island - Greece",
            "searchName": "JKL-Kalymnos-Kalymnos Island-Kalimnos-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "JIK",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Ikaria Island",
            "name": "[JIK] - Ikaria Island - Greece",
            "searchName": "JIK-Ikaria Inseln-Ikaria Island--Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland-Greece",
            "priority": 0
        },
        {
            "iataCode": "LSZ",
            "countryCode": "HR",
            "cityCode": null,
            "cityName": "Mali Losinj",
            "name": "[LSZ] - Mali Losinj - Croatia",
            "searchName": "LSZ-Mali Losinj-Kroatië-Kroatia-Chorvatsko-Hirvatistan-Croàcia-Horvátország-Croatia-Hrvaška-Kroatija-Horvatija-Hrvatska-Horvaatia-Chorwacja-Kroasia-Kroatien-Croatie-Croacia-Croazia",
            "priority": 0
        },
        {
            "iataCode": "OSI",
            "countryCode": "HR",
            "cityCode": null,
            "cityName": "Osijek",
            "name": "[OSI] - Osijek - Croatia",
            "searchName": "OSI-Osijek-Kroatië-Kroatia-Chorvatsko-Hirvatistan-Croàcia-Horvátország-Croatia-Hrvaška-Kroatija-Horvatija-Hrvatska-Horvaatia-Chorwacja-Kroasia-Kroatien-Croatie-Croacia-Croazia",
            "priority": 0
        },
        {
            "iataCode": "RJK",
            "countryCode": "HR",
            "cityCode": null,
            "cityName": "Rijeka",
            "name": "[RJK] - Rijeka - Croatia",
            "searchName": "RJK-Rijeka-Fiume-Kroatië-Kroatia-Chorvatsko-Hirvatistan-Croàcia-Horvátország-Croatia-Hrvaška-Kroatija-Horvatija-Hrvatska-Horvaatia-Chorwacja-Kroasia-Kroatien-Croatie-Croacia-Croazia",
            "priority": 0
        },
        {
            "iataCode": "JNX",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Naxos",
            "name": "[JNX] - Naxos - Greece",
            "searchName": "JNX-Naxos-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "JSH",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Sitia",
            "name": "[JSH] - Sitia - Greece",
            "searchName": "JSH-Sitia-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "JSI",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Skiathos",
            "name": "[JSI] - Skiathos - Greece",
            "searchName": "JSI-Skiathos-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "JSY",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Syros Island",
            "name": "[JSY] - Syros Island - Greece",
            "searchName": "JSY-Syros Inseln-Syros Island-Syros--Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland-Greece",
            "priority": 0
        },
        {
            "iataCode": "JTR",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Santorini (Thira)",
            "name": "[JTR] - Santorini (Thira) - Greece",
            "searchName": "JTR-Fira-Santorini (Thira)-Thira-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "AOK",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Karpathos",
            "name": "[AOK] - Karpathos - Greece",
            "searchName": "AOK-Karpathos-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "AGQ",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Agrinion",
            "name": "[AGQ] - Agrinion - Greece",
            "searchName": "AGQ-Agrinion-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "EFL",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Kefalonia",
            "name": "[EFL] - Kefalonia - Greece",
            "searchName": "EFL-Kefallinia-Kefalonia-Argostoli-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "PAS",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Paros",
            "name": "[PAS] - Paros - Greece",
            "searchName": "PAS-Paros-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "MLO",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Milos",
            "name": "[MLO] - Milos - Greece",
            "searchName": "MLO-Milos-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "MJT",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Mytilene",
            "name": "[MJT] - Mytilene - Greece",
            "searchName": "MJT-Mytilene-Midilli-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "AXD",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Alexandroupolis",
            "name": "[AXD] - Alexandroupolis - Greece",
            "searchName": "AXD-Alexandroupolis-Alejandr-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "KUT",
            "countryCode": "GE",
            "cityCode": null,
            "cityName": "Kutaisi",
            "name": "[KUT] - Kutaisi - Georgia",
            "searchName": "KUT-Kutaisi-Georgia-Georgien-Gruzie-Gürcistan-Geòrgia-Grúzia-Gruzija-Gruusia-Gruzja-Géo-Géorgie",
            "priority": 0
        },
        {
            "iataCode": "SUI",
            "countryCode": "GE",
            "cityCode": null,
            "cityName": "Sukhumi",
            "name": "[SUI] - Sukhumi - Georgia",
            "searchName": "SUI-Sukhumi-Georgia-Georgien-Gruzie-Gürcistan-Geòrgia-Grúzia-Gruzija-Gruusia-Gruzja-Géo-Géorgie",
            "priority": 0
        },
        {
            "iataCode": "SPJ",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Sparta",
            "name": "[SPJ] - Sparta - Greece",
            "searchName": "SPJ-Sparta-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "SKU",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Skiros",
            "name": "[SKU] - Skiros - Greece",
            "searchName": "SKU-Skiros-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "LRS",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Leros",
            "name": "[LRS] - Leros - Greece",
            "searchName": "LRS-Leros-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "LRA",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Larisa",
            "name": "[LRA] - Larisa - Greece",
            "searchName": "LRA-Larisa-Larissa-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "LXS",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Limnos",
            "name": "[LXS] - Limnos - Greece",
            "searchName": "LXS-Limnos-Lemnos-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "KLX",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Kalamata",
            "name": "[KLX] - Kalamata - Greece",
            "searchName": "KLX-Kalamata-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "KIT",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Kithira",
            "name": "[KIT] - Kithira - Greece",
            "searchName": "KIT-Kithira-Kythira (-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "KGS",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Kos",
            "name": "[KGS] - Kos - Greece",
            "searchName": "KGS-Kos-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "KZI",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Kozani",
            "name": "[KZI] - Kozani - Greece",
            "searchName": "KZI-Kozani-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "KZS",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Kastelorizo",
            "name": "[KZS] - Kastelorizo - Greece",
            "searchName": "KZS-Kastelorizo-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "KSJ",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Kasos Island",
            "name": "[KSJ] - Kasos Island - Greece",
            "searchName": "KSJ-Kasos Inseln-Kasos Island--Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland-Greece",
            "priority": 0
        },
        {
            "iataCode": "KSO",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Kastoria",
            "name": "[KSO] - Kastoria - Greece",
            "searchName": "KSO-Kastoria-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "KVA",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Kavala",
            "name": "[KVA] - Kavala - Greece",
            "searchName": "KVA-Kavala-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "IOA",
            "countryCode": "GR",
            "cityCode": null,
            "cityName": "Ioannina",
            "name": "[IOA] - Ioannina - Greece",
            "searchName": "IOA-Ioannina-Ioanina-Giannina-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "ZTW",
            "countryCode": "HK",
            "cityCode": null,
            "cityName": "Tsuen Wan",
            "name": "[ZTW] - Tsuen Wan - Hong Kong",
            "searchName": "ZTW-Tsuen Wan-Hongkong-Hong Kong-Honkonga-Honkongas--Hong-Kong",
            "priority": 0
        },
        {
            "iataCode": "XPL",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Comayagua",
            "name": "[XPL] - Comayagua - Honduras",
            "searchName": "XPL-Comayagua-Honduras",
            "priority": 0
        },
        {
            "iataCode": "VTA",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Victoria",
            "name": "[VTA] - Victoria - Honduras",
            "searchName": "VTA-Victoria-Honduras",
            "priority": 0
        },
        {
            "iataCode": "UII",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Utila",
            "name": "[UII] - Utila - Honduras",
            "searchName": "UII-Utila-Honduras",
            "priority": 0
        },
        {
            "iataCode": "TJI",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Trujillo",
            "name": "[TJI] - Trujillo - Honduras",
            "searchName": "TJI-Trujillo-Honduras",
            "priority": 0
        },
        {
            "iataCode": "TEA",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Tela",
            "name": "[TEA] - Tela - Honduras",
            "searchName": "TEA-Tela-Honduras",
            "priority": 0
        },
        {
            "iataCode": "TGU",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Tegucigalpa",
            "name": "[TGU] - Tegucigalpa - Honduras",
            "searchName": "TGU-Tegucigalpa-Honduras",
            "priority": 0
        },
        {
            "iataCode": "TCF",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Tocoa",
            "name": "[TCF] - Tocoa - Honduras",
            "searchName": "TCF-Tocoa-Honduras",
            "priority": 0
        },
        {
            "iataCode": "SET",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "San Esteban",
            "name": "[SET] - San Esteban - Honduras",
            "searchName": "SET-San Esteban-Honduras",
            "priority": 0
        },
        {
            "iataCode": "SDH",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Santa Rosa Copan",
            "name": "[SDH] - Santa Rosa Copan - Honduras",
            "searchName": "SDH-Santa Rosa de Copan-Santa Rosa Copan-Honduras",
            "priority": 0
        },
        {
            "iataCode": "SCD",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Sulaco",
            "name": "[SCD] - Sulaco - Honduras",
            "searchName": "SCD-Sulaco-Honduras",
            "priority": 0
        },
        {
            "iataCode": "RUY",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Copan",
            "name": "[RUY] - Copan - Honduras",
            "searchName": "RUY-Copan-Honduras",
            "priority": 0
        },
        {
            "iataCode": "SAP",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "San Pedro Sula",
            "name": "[SAP] - San Pedro Sula - Honduras",
            "searchName": "SAP-San Pedro Sula-Honduras",
            "priority": 0
        },
        {
            "iataCode": "RTB",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Roatan",
            "name": "[RTB] - Roatan - Honduras",
            "searchName": "RTB-Roatan-Honduras",
            "priority": 0
        },
        {
            "iataCode": "MRJ",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Marcala",
            "name": "[MRJ] - Marcala - Honduras",
            "searchName": "MRJ-Marcala-Honduras",
            "priority": 0
        },
        {
            "iataCode": "OAN",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Olanchito",
            "name": "[OAN] - Olanchito - Honduras",
            "searchName": "OAN-Olanchito-Honduras",
            "priority": 0
        },
        {
            "iataCode": "ORO",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Yoro",
            "name": "[ORO] - Yoro - Honduras",
            "searchName": "ORO-Yoro-Honduras",
            "priority": 0
        },
        {
            "iataCode": "PCH",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Palacios",
            "name": "[PCH] - Palacios - Honduras",
            "searchName": "PCH-Palacios-Honduras",
            "priority": 0
        },
        {
            "iataCode": "PEU",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Puerto Lempira",
            "name": "[PEU] - Puerto Lempira - Honduras",
            "searchName": "PEU-Puerto Lempira-Honduras",
            "priority": 0
        },
        {
            "iataCode": "GUO",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Gualaco",
            "name": "[GUO] - Gualaco - Honduras",
            "searchName": "GUO-Gualaco-Honduras",
            "priority": 0
        },
        {
            "iataCode": "GJA",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Guanaja",
            "name": "[GJA] - Guanaja - Honduras",
            "searchName": "GJA-Guanaja-Honduras",
            "priority": 0
        },
        {
            "iataCode": "GAC",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Gracias",
            "name": "[GAC] - Gracias - Honduras",
            "searchName": "GAC-Gracias-Honduras",
            "priority": 0
        },
        {
            "iataCode": "JUT",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Juticalpa",
            "name": "[JUT] - Juticalpa - Honduras",
            "searchName": "JUT-Juticalpa-Honduras",
            "priority": 0
        },
        {
            "iataCode": "IRN",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Iriona",
            "name": "[IRN] - Iriona - Honduras",
            "searchName": "IRN-Iriona-Honduras",
            "priority": 0
        },
        {
            "iataCode": "LCE",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "La Ceiba",
            "name": "[LCE] - La Ceiba - Honduras",
            "searchName": "LCE-La Ceiba-Honduras",
            "priority": 0
        },
        {
            "iataCode": "LUI",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "La Union",
            "name": "[LUI] - La Union - Honduras",
            "searchName": "LUI-La Union-Honduras",
            "priority": 0
        },
        {
            "iataCode": "LMH",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Limon",
            "name": "[LMH] - Limon - Honduras",
            "searchName": "LMH-Limon-Honduras",
            "priority": 0
        },
        {
            "iataCode": "LLH",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Las Limas",
            "name": "[LLH] - Las Limas - Honduras",
            "searchName": "LLH-Las Limas-Honduras",
            "priority": 0
        },
        {
            "iataCode": "LEZ",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "La Esperanza",
            "name": "[LEZ] - La Esperanza - Honduras",
            "searchName": "LEZ-La Esperanza-Honduras",
            "priority": 0
        },
        {
            "iataCode": "EDQ",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Erandique",
            "name": "[EDQ] - Erandique - Honduras",
            "searchName": "EDQ-Erandique-Honduras",
            "priority": 0
        },
        {
            "iataCode": "ENQ",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Coronel E Soto Cano AB",
            "name": "[ENQ] - Coronel E Soto Cano AB - Honduras",
            "searchName": "ENQ-Coronel E Soto Cano AB-Honduras",
            "priority": 0
        },
        {
            "iataCode": "CYL",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Coyoles",
            "name": "[CYL] - Coyoles - Honduras",
            "searchName": "CYL-Coyoles-Honduras",
            "priority": 0
        },
        {
            "iataCode": "CDD",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Cauquira",
            "name": "[CDD] - Cauquira - Honduras",
            "searchName": "CDD-Cauquira-Honduras",
            "priority": 0
        },
        {
            "iataCode": "AHS",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Ahuas",
            "name": "[AHS] - Ahuas - Honduras",
            "searchName": "AHS-Ahuas-Honduras",
            "priority": 0
        },
        {
            "iataCode": "BHG",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Brus Laguna",
            "name": "[BHG] - Brus Laguna - Honduras",
            "searchName": "BHG-Brus Laguna-Honduras",
            "priority": 0
        },
        {
            "iataCode": "CAA",
            "countryCode": "HN",
            "cityCode": null,
            "cityName": "Catacamas",
            "name": "[CAA] - Catacamas - Honduras",
            "searchName": "CAA-Catacamas-Honduras",
            "priority": 0
        },
        {
            "iataCode": "BWK",
            "countryCode": "HR",
            "cityCode": null,
            "cityName": "Bol",
            "name": "[BWK] - Bol - Croatia",
            "searchName": "BWK-Bol-Kroatië-Kroatia-Chorvatsko-Hirvatistan-Croàcia-Horvátország-Croatia-Hrvaška-Kroatija-Horvatija-Hrvatska-Horvaatia-Chorwacja-Kroasia-Kroatien-Croatie-Croacia-Croazia",
            "priority": 0
        },
        {
            "iataCode": "FLF",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Flensburg",
            "name": "[FLF] - Flensburg - Germany",
            "searchName": "FLF-Flensburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "FEL",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Fuerstenfeldbruck",
            "name": "[FEL] - Fuerstenfeldbruck - Germany",
            "searchName": "FEL-Fuerstenfeldbruck-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "FCN",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Cuxhaven",
            "name": "[FCN] - Cuxhaven - Germany",
            "searchName": "FCN-Cuxhaven-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "FDH",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Friedrichshafen",
            "name": "[FDH] - Friedrichshafen - Germany",
            "searchName": "FDH-Friedrichshafen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "EUM",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Neumuenster",
            "name": "[EUM] - Neumuenster - Germany",
            "searchName": "EUM-Neum-Neumuenster-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ERF",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Erfurt",
            "name": "[ERF] - Erfurt - Germany",
            "searchName": "ERF-Erfurt--Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija-Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "FMM",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Memmingen",
            "name": "[FMM] - Memmingen - Germany",
            "searchName": "FMM-Memmingen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "FMO",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Greven",
            "name": "[FMO] - Greven - Germany",
            "searchName": "FMO-M-Greven-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "FNB",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Neubrandenburg",
            "name": "[FNB] - Neubrandenburg - Germany",
            "searchName": "FNB-Neubandenburg-Neubrandenburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "KLT",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Kaiserslautern",
            "name": "[KLT] - Kaiserslautern - Germany",
            "searchName": "KLT-Kaiserslautern-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "KOQ",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Koethen",
            "name": "[KOQ] - Koethen - Germany",
            "searchName": "KOQ-K-Koethen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "KEL",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Kiel",
            "name": "[KEL] - Kiel - Germany",
            "searchName": "KEL-Kiel-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "LEJ",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Leipzig-Halle",
            "name": "[LEJ] - Leipzig-Halle - Germany",
            "searchName": "LEJ-Leipzig/Halle-Leipzig-Halle-Lipsia-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "KZG",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Kitzingen",
            "name": "[KZG] - Kitzingen - Germany",
            "searchName": "KZG-Kitzingen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "KSF",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Kassel",
            "name": "[KSF] - Kassel - Germany",
            "searchName": "KSF-Kassel-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "MHG",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Mannheim",
            "name": "[MHG] - Mannheim - Germany",
            "searchName": "MHG-Mannheim-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "LHA",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Lahr",
            "name": "[LHA] - Lahr - Germany",
            "searchName": "LHA-Lahr-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "LGO",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Langeoog",
            "name": "[LGO] - Langeoog - Germany",
            "searchName": "LGO-Langeoog-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "GTI",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Guettin",
            "name": "[GTI] - Guettin - Germany",
            "searchName": "GTI-Guettin-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "GUT",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Guetersloh",
            "name": "[GUT] - Guetersloh - Germany",
            "searchName": "GUT-Guetersloh-Gutersloh-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "CSO",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Cochstedt",
            "name": "[CSO] - Cochstedt - Germany",
            "searchName": "CSO-Cochstedt-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "DUI",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Duisburg",
            "name": "[DUI] - Duisburg - Germany",
            "searchName": "DUI-Duisburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "AAL",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Aalborg",
            "name": "[AAL] - Aalborg - Denmark",
            "searchName": "AAL-Aalborg-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "BLL",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Billund",
            "name": "[BLL] - Billund - Denmark",
            "searchName": "BLL-Billund-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "BYR",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Laeso Island",
            "name": "[BYR] - Laeso Island - Denmark",
            "searchName": "BYR-Laeso Island-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "EPS",
            "countryCode": "DO",
            "cityCode": null,
            "cityName": "Samana",
            "name": "[EPS] - Samana - Dominican Republic",
            "searchName": "EPS-Samana-El Portillo\\/Samana-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "COZ",
            "countryCode": "DO",
            "cityCode": null,
            "cityName": "Constanza",
            "name": "[COZ] - Constanza - Dominican Republic",
            "searchName": "COZ-Constanza-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "CBJ",
            "countryCode": "DO",
            "cityCode": null,
            "cityName": "Cabo Rojo",
            "name": "[CBJ] - Cabo Rojo - Dominican Republic",
            "searchName": "CBJ-Cabo Rojo-Pedernales-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "BRX",
            "countryCode": "DO",
            "cityCode": null,
            "cityName": "Barahona",
            "name": "[BRX] - Barahona - Dominican Republic",
            "searchName": "BRX-Barahona-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "AZS",
            "countryCode": "DO",
            "cityCode": null,
            "cityName": "El Catey",
            "name": "[AZS] - El Catey - Dominican Republic",
            "searchName": "AZS-Sanchez-El Catey-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "LRM",
            "countryCode": "DO",
            "cityCode": null,
            "cityName": "La Romana",
            "name": "[LRM] - La Romana - Dominican Republic",
            "searchName": "LRM-La Romana-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "JBQ",
            "countryCode": "DO",
            "cityCode": null,
            "cityName": "Higuero",
            "name": "[JBQ] - Higuero - Dominican Republic",
            "searchName": "JBQ-Higuero-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "TEE",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Tbessa",
            "name": "[TEE] - Tbessa - Algeria",
            "searchName": "TEE-Tbessa-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "TGR",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Touggourt",
            "name": "[TGR] - Touggourt - Algeria",
            "searchName": "TGR-Touggourt-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "TID",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Tiaret",
            "name": "[TID] - Tiaret - Algeria",
            "searchName": "TID-Tiaret-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "TIN",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Tindouf",
            "name": "[TIN] - Tindouf - Algeria",
            "searchName": "TIN-Tindouf-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "TMX",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Timimoun",
            "name": "[TMX] - Timimoun - Algeria",
            "searchName": "TMX-Timimoun-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "TMR",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Tamanrasset",
            "name": "[TMR] - Tamanrasset - Algeria",
            "searchName": "TMR-Tamanrasset-Tamanghasset-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "TLM",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Tlemcen",
            "name": "[TLM] - Tlemcen - Algeria",
            "searchName": "TLM-Tlemcen-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "VVZ",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Illizi",
            "name": "[VVZ] - Illizi - Algeria",
            "searchName": "VVZ-Illizi-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "SKI",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Skikda",
            "name": "[SKI] - Skikda - Algeria",
            "searchName": "SKI-Skikda-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "QSF",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Sétif",
            "name": "[QSF] - Sétif - Algeria",
            "searchName": "QSF-S-Sétif-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "MQV",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Mostaganem",
            "name": "[MQV] - Mostaganem - Algeria",
            "searchName": "MQV-Mostaganem-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "MUW",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Mascara-Ghriss",
            "name": "[MUW] - Mascara-Ghriss - Algeria",
            "searchName": "MUW-Mascara-Ghriss-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "MZW",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Mechria",
            "name": "[MZW] - Mechria - Algeria",
            "searchName": "MZW-Mechria-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "OGX",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Ouargla",
            "name": "[OGX] - Ouargla - Algeria",
            "searchName": "OGX-Ouargla-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "POP",
            "countryCode": "DO",
            "cityCode": null,
            "cityName": "Puerto Plata",
            "name": "[POP] - Puerto Plata - Dominican Republic",
            "searchName": "POP-Puerto Plata-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "SJM",
            "countryCode": "DO",
            "cityCode": null,
            "cityName": "San Juan",
            "name": "[SJM] - San Juan - Dominican Republic",
            "searchName": "SJM-San Juan-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "SNX",
            "countryCode": "DO",
            "cityCode": null,
            "cityName": "Sabana De Mar",
            "name": "[SNX] - Sabana De Mar - Dominican Republic",
            "searchName": "SNX-Sabana de La Mar-Sabana De Mar-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "CNL",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Sindal",
            "name": "[CNL] - Sindal - Denmark",
            "searchName": "CNL-Sindal-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "EBJ",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Esbjerg",
            "name": "[EBJ] - Esbjerg - Denmark",
            "searchName": "EBJ-Esbjerg-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "KRP",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Karup",
            "name": "[KRP] - Karup - Denmark",
            "searchName": "KRP-Karup-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "INF",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "In Guezzam",
            "name": "[INF] - In Guezzam - Algeria",
            "searchName": "INF-In Guezzam-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "INZ",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "In Salah",
            "name": "[INZ] - In Salah - Algeria",
            "searchName": "INZ-In Salah-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "HRM",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Hassi R mel",
            "name": "[HRM] - Hassi R mel - Algeria",
            "searchName": "HRM-Hassi R Mel-Hassi R mel-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "IAM",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "In Amenas",
            "name": "[IAM] - In Amenas - Algeria",
            "searchName": "IAM-In Amenas-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "GHA",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Ghardaïa",
            "name": "[GHA] - Ghardaïa - Algeria",
            "searchName": "GHA-Ghardaia-Gharda-Ghardaïa-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "GJL",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Jijel",
            "name": "[GJL] - Jijel - Algeria",
            "searchName": "GJL-Jijel-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "HME",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Hassi Messaoud",
            "name": "[HME] - Hassi Messaoud - Algeria",
            "searchName": "HME-Hassi Messaoud-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "LOO",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Laghouat",
            "name": "[LOO] - Laghouat - Algeria",
            "searchName": "LOO-Laghouat-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "AZR",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Adrar",
            "name": "[AZR] - Adrar - Algeria",
            "searchName": "AZR-Adrar-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "AAE",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Annaba",
            "name": "[AAE] - Annaba - Algeria",
            "searchName": "AAE-Annaba-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "BHA",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Bahia De Caraquez",
            "name": "[BHA] - Bahia De Caraquez - Ecuador",
            "searchName": "BHA-Bahia de Caraquez-Bahia De Caraquez-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "ATF",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Ambato",
            "name": "[ATF] - Ambato - Ecuador",
            "searchName": "ATF-Ambato-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "LOH",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Catamayo",
            "name": "[LOH] - Catamayo - Ecuador",
            "searchName": "LOH-Loja-Catamayo-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "LTX",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Latacunga",
            "name": "[LTX] - Latacunga - Ecuador",
            "searchName": "LTX-Latacunga-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "LGQ",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Lago Agrio",
            "name": "[LGQ] - Lago Agrio - Ecuador",
            "searchName": "LGQ-Lago Agrio-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "MEC",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Manta",
            "name": "[MEC] - Manta - Ecuador",
            "searchName": "MEC-Manta-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "MCH",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Machala",
            "name": "[MCH] - Machala - Ecuador",
            "searchName": "MCH-Machala-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "GYE",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Guayaquil",
            "name": "[GYE] - Guayaquil - Ecuador",
            "searchName": "GYE-Guayaquil-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "GPS",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Galapagos Island",
            "name": "[GPS] - Galapagos Island - Ecuador",
            "searchName": "GPS-Galapagos Is-Galapagos Island-Galapagos-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "IBB",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Isabela Island",
            "name": "[IBB] - Isabela Island - Ecuador",
            "searchName": "IBB-Isabela Island-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "JIP",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Jipijapa",
            "name": "[JIP] - Jipijapa - Ecuador",
            "searchName": "JIP-Jipijapa-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "OCC",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Coca",
            "name": "[OCC] - Coca - Ecuador",
            "searchName": "OCC-Coca-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "MZD",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Mendez",
            "name": "[MZD] - Mendez - Ecuador",
            "searchName": "MZD-Mendez-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "MRR",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Macara",
            "name": "[MRR] - Macara - Ecuador",
            "searchName": "MRR-Macara-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "PYO",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Putumayo",
            "name": "[PYO] - Putumayo - Ecuador",
            "searchName": "PYO-Putumayo-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "PVO",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Portoviejo",
            "name": "[PVO] - Portoviejo - Ecuador",
            "searchName": "PVO-Portoviejo-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "PTZ",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Pastaza",
            "name": "[PTZ] - Pastaza - Ecuador",
            "searchName": "PTZ-Pastaza-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "SNC",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Salinas",
            "name": "[SNC] - Salinas - Ecuador",
            "searchName": "SNC-Salinas-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "SCY",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Puerto Baquerizo Moreno",
            "name": "[SCY] - Puerto Baquerizo Moreno - Ecuador",
            "searchName": "SCY-San Cristobal-Puerto Baquerizo Moreno-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "BSK",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Biskra",
            "name": "[BSK] - Biskra - Algeria",
            "searchName": "BSK-Biskra-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "BUJ",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Boussaada",
            "name": "[BUJ] - Boussaada - Algeria",
            "searchName": "BUJ-Boussaada-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "CBH",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Béchar",
            "name": "[CBH] - Béchar - Algeria",
            "searchName": "CBH-Bechar-B-Béchar-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "BLJ",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Batna",
            "name": "[BLJ] - Batna - Algeria",
            "searchName": "BLJ-Batna-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "BMW",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Bordj Badji Mokhtar",
            "name": "[BMW] - Bordj Badji Mokhtar - Algeria",
            "searchName": "BMW-Bordj Badji Mokhtar-Borj Badji Mokhtar-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "BFW",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Sidi Belabbes",
            "name": "[BFW] - Sidi Belabbes - Algeria",
            "searchName": "BFW-Sidi Belabbes-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "BJA",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Bejaia",
            "name": "[BJA] - Bejaia - Algeria",
            "searchName": "BJA-Bejaia-Bug-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "CFK",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Chlef",
            "name": "[CFK] - Chlef - Algeria",
            "searchName": "CFK-Chlef-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "CZL",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Constantine",
            "name": "[CZL] - Constantine - Algeria",
            "searchName": "CZL-Constantine-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "DJG",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "Djanet",
            "name": "[DJG] - Djanet - Algeria",
            "searchName": "DJG-Djanet-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "EBH",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "El Bayadh",
            "name": "[EBH] - El Bayadh - Algeria",
            "searchName": "EBH-El Bayadh-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "ELU",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "El Oued",
            "name": "[ELU] - El Oued - Algeria",
            "searchName": "ELU-El Oued-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "ELG",
            "countryCode": "DZ",
            "cityCode": null,
            "cityName": "El Golea",
            "name": "[ELG] - El Golea - Algeria",
            "searchName": "ELG-El Golea-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "ESM",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Esmeraldas",
            "name": "[ESM] - Esmeraldas - Ecuador",
            "searchName": "ESM-Esmeraldas-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "ETR",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Santa Rosa",
            "name": "[ETR] - Santa Rosa - Ecuador",
            "searchName": "ETR-Santa Rosa-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "SQK",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Sidi Barani",
            "name": "[SQK] - Sidi Barani - Egypt",
            "searchName": "SQK-Sidi Barani-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "GSQ",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Shark Elowainat",
            "name": "[GSQ] - Shark Elowainat - Egypt",
            "searchName": "GSQ-Shark Elowainat-Sharq Al-Owainat-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "HMB",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Sohag",
            "name": "[HMB] - Sohag - Egypt",
            "searchName": "HMB-Elmendorf Afb AK-Sohag-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "UVL",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Kharga",
            "name": "[UVL] - Kharga - Egypt",
            "searchName": "UVL-Kharga-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "TFR",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Ramadan",
            "name": "[TFR] - Ramadan - Egypt",
            "searchName": "TFR-Ramadan-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "TCP",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Taba",
            "name": "[TCP] - Taba - Egypt",
            "searchName": "TCP-Taba-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "SKV",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Santa Katarina",
            "name": "[SKV] - Santa Katarina - Egypt",
            "searchName": "SKV-Santa Katarina-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "SEW",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Siwa",
            "name": "[SEW] - Siwa - Egypt",
            "searchName": "SEW-Siwa-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "PSD",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Port Said",
            "name": "[PSD] - Port Said - Egypt",
            "searchName": "PSD-Port Said-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "MUH",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Mersa Matruh",
            "name": "[MUH] - Mersa Matruh - Egypt",
            "searchName": "MUH-Mersa Matruh-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "MSW",
            "countryCode": "ER",
            "cityCode": null,
            "cityName": "Massawa",
            "name": "[MSW] - Massawa - Eritrea",
            "searchName": "MSW-Massawa-Eritrea-Eritre-Érythrée",
            "priority": 0
        },
        {
            "iataCode": "TES",
            "countryCode": "ER",
            "cityCode": null,
            "cityName": "Tessenei",
            "name": "[TES] - Tessenei - Eritrea",
            "searchName": "TES-Tessenei-Eritrea-Eritre-Érythrée",
            "priority": 0
        },
        {
            "iataCode": "ASM",
            "countryCode": "ER",
            "cityCode": null,
            "cityName": "Asmara",
            "name": "[ASM] - Asmara - Eritrea",
            "searchName": "ASM-Asmara-Eritrea-Eritre-Érythrée",
            "priority": 0
        },
        {
            "iataCode": "ASA",
            "countryCode": "ER",
            "cityCode": null,
            "cityName": "Assab",
            "name": "[ASA] - Assab - Eritrea",
            "searchName": "ASA-Assab-Eritrea-Eritre-Érythrée",
            "priority": 0
        },
        {
            "iataCode": "URE",
            "countryCode": "EE",
            "cityCode": null,
            "cityName": "Kuressaare",
            "name": "[URE] - Kuressaare - Estonia",
            "searchName": "URE-Kuressaare-Estônia-Estland-Estonia-Estonie-Eesti-Estonya",
            "priority": 0
        },
        {
            "iataCode": "KDL",
            "countryCode": "EE",
            "cityCode": null,
            "cityName": "Kardla",
            "name": "[KDL] - Kardla - Estonia",
            "searchName": "KDL-Kardla-Estônia-Estland-Estonia-Estonie-Eesti-Estonya",
            "priority": 0
        },
        {
            "iataCode": "EPU",
            "countryCode": "EE",
            "cityCode": null,
            "cityName": "Parnu",
            "name": "[EPU] - Parnu - Estonia",
            "searchName": "EPU-Parnu-Estônia-Estland-Estonia-Estonie-Eesti-Estonya",
            "priority": 0
        },
        {
            "iataCode": "EMY",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "El Minya",
            "name": "[EMY] - El Minya - Egypt",
            "searchName": "EMY-El Minya-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "ELT",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Tour Sinai City",
            "name": "[ELT] - Tour Sinai City - Egypt",
            "searchName": "ELT-Tour Sinai City-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "DAK",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Dakhla Oasis",
            "name": "[DAK] - Dakhla Oasis - Egypt",
            "searchName": "DAK-Dakhla Oasis-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "DBB",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Dabaa City",
            "name": "[DBB] - Dabaa City - Egypt",
            "searchName": "DBB-Dabaa City-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "ASW",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Aswan",
            "name": "[ASW] - Aswan - Egypt",
            "searchName": "ASW-Assuan-Aswan-Asu-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "AUE",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Abu Rudeis",
            "name": "[AUE] - Abu Rudeis - Egypt",
            "searchName": "AUE-Abu Rudeis-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "ATZ",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Assiut",
            "name": "[ATZ] - Assiut - Egypt",
            "searchName": "ATZ-Assiut-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "ALY",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Alexandria",
            "name": "[ALY] - Alexandria - Egypt",
            "searchName": "ALY-Alexandria-Alejandr-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "AAC",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "El Arish",
            "name": "[AAC] - El Arish - Egypt",
            "searchName": "AAC-El Arish-Al Arish-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "ABS",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Abu Simbel",
            "name": "[ABS] - Abu Simbel - Egypt",
            "searchName": "ABS-Abu Simbel-Abou Simbel-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "TUA",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Tulcan",
            "name": "[TUA] - Tulcan - Ecuador",
            "searchName": "TUA-Tulcan-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "TSC",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Taisha",
            "name": "[TSC] - Taisha - Ecuador",
            "searchName": "TSC-Taisha-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "TNW",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Tena",
            "name": "[TNW] - Tena - Ecuador",
            "searchName": "TNW-Tena-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "TPC",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Tarapoa",
            "name": "[TPC] - Tarapoa - Ecuador",
            "searchName": "TPC-Tarapoa-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "TPN",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Tiputini",
            "name": "[TPN] - Tiputini - Ecuador",
            "searchName": "TPN-Tiputini-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "SUQ",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Sucua",
            "name": "[SUQ] - Sucua - Ecuador",
            "searchName": "SUQ-Sucua-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "XMS",
            "countryCode": "EC",
            "cityCode": null,
            "cityName": "Macas",
            "name": "[XMS] - Macas - Ecuador",
            "searchName": "XMS-Macas-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "TAY",
            "countryCode": "EE",
            "cityCode": null,
            "cityName": "Tartu",
            "name": "[TAY] - Tartu - Estonia",
            "searchName": "TAY-Tartu-Estônia-Estland-Estonia-Estonie-Eesti-Estonya",
            "priority": 0
        },
        {
            "iataCode": "BJZ",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Badajoz",
            "name": "[BJZ] - Badajoz - Spain",
            "searchName": "BJZ-Badajoz-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "CQM",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Ciudad Real",
            "name": "[CQM] - Ciudad Real - Spain",
            "searchName": "CQM-Ciudad Real-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "CDT",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Castellon de la Plana",
            "name": "[CDT] - Castellon de la Plana - Spain",
            "searchName": "CDT-Castellon de la Plana-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "CDZ",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Cadiz",
            "name": "[CDZ] - Cadiz - Spain",
            "searchName": "CDZ-C-Cadiz-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "EAS",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "San Sebastian",
            "name": "[EAS] - San Sebastian - Spain",
            "searchName": "EAS-San Sebastian-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "EEL",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Tudela",
            "name": "[EEL] - Tudela - Spain",
            "searchName": "EEL-Tudela-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "FES",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "San Fernando",
            "name": "[FES] - San Fernando - Spain",
            "searchName": "FES-San Fernando-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "FGR",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Fuengirola",
            "name": "[FGR] - Fuengirola - Spain",
            "searchName": "FGR-Fuengirola-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "HEV",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Huelva",
            "name": "[HEV] - Huelva - Spain",
            "searchName": "HEV-Huelva-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "GRX",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Granada",
            "name": "[GRX] - Granada - Spain",
            "searchName": "GRX-Granada-Granade-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "GRO",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Gerona",
            "name": "[GRO] - Gerona - Spain",
            "searchName": "GRO-Gerona-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "GMZ",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "San Sebas De La Gomera",
            "name": "[GMZ] - San Sebas De La Gomera - Spain",
            "searchName": "GMZ-San Sebas de la Gomera-San Sebas De La Gomera-San Sebastian de la Gomera-La Gomera-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "GGA",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "La Graciosa",
            "name": "[GGA] - La Graciosa - Spain",
            "searchName": "GGA-La Graciosa-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "FUE",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Puerto del Rosario",
            "name": "[FUE] - Puerto del Rosario - Spain",
            "searchName": "FUE-Fuerteventura-Puerto del Rosario-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "GEN",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Puente Genil",
            "name": "[GEN] - Puente Genil - Spain",
            "searchName": "GEN-Puente Genil-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "HSK",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Huesca",
            "name": "[HSK] - Huesca - Spain",
            "searchName": "HSK-Huesca-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "LEN",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Leon",
            "name": "[LEN] - Leon - Spain",
            "searchName": "LEN-Leon-Le-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "LCG",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "La Coruna",
            "name": "[LCG] - La Coruna - Spain",
            "searchName": "LCG-La Coruna-La Corogne-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "UTL",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Torremolinos",
            "name": "[UTL] - Torremolinos - Spain",
            "searchName": "UTL-Torremolinos-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "VGO",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Vigo",
            "name": "[VGO] - Vigo - Spain",
            "searchName": "VGO-Vigo-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "VDE",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Valverde",
            "name": "[VDE] - Valverde - Spain",
            "searchName": "VDE-Valverde-El Hierro-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "VLL",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Valladolid",
            "name": "[VLL] - Valladolid - Spain",
            "searchName": "VLL-Valladolid-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "ILD",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Lleida",
            "name": "[ILD] - Lleida - Spain",
            "searchName": "ILD-Lleida-L-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "JCU",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Ceuta",
            "name": "[JCU] - Ceuta - Spain",
            "searchName": "JCU-Ceuta-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "ABC",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Albacete",
            "name": "[ABC] - Albacete - Spain",
            "searchName": "ABC-Albacete-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "AGP",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Malaga",
            "name": "[AGP] - Malaga - Spain",
            "searchName": "AGP-M-Malaga-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "AEI",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Algeciras",
            "name": "[AEI] - Algeciras - Spain",
            "searchName": "AEI-Algeciras-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "KTQ",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Kitee",
            "name": "[KTQ] - Kitee - Finland",
            "searchName": "KTQ-Kitee-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "KEM",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Kemi",
            "name": "[KEM] - Kemi - Finland",
            "searchName": "KEM-Kemi/Tornio-Kemi-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "KEV",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Kuorevesi",
            "name": "[KEV] - Kuorevesi - Finland",
            "searchName": "KEV-Kuorevesi-Kuorevisi-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "KHJ",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Kauhajoki",
            "name": "[KHJ] - Kauhajoki - Finland",
            "searchName": "KHJ-Kauhajoki-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "TMP",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Tampere",
            "name": "[TMP] - Tampere - Finland",
            "searchName": "TMP-Tampere-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "TKU",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Turku",
            "name": "[TKU] - Turku - Finland",
            "searchName": "TKU-Turku-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "SVL",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Savonlinna",
            "name": "[SVL] - Savonlinna - Finland",
            "searchName": "SVL-Savonlinna-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "VRK",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Joroinen",
            "name": "[VRK] - Joroinen - Finland",
            "searchName": "VRK-Varkaus-Joroinen-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "UTI",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Valkeala",
            "name": "[UTI] - Valkeala - Finland",
            "searchName": "UTI-Valkeala-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "VAA",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Vaasa",
            "name": "[VAA] - Vaasa - Finland",
            "searchName": "VAA-Vaasa-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "XGQ",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Saariselka",
            "name": "[XGQ] - Saariselka - Finland",
            "searchName": "XGQ-Saariselka-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "XHA",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Yllasjarvi",
            "name": "[XHA] - Yllasjarvi - Finland",
            "searchName": "XHA-Yllasjarvi-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "XHC",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Akaslompolo",
            "name": "[XHC] - Akaslompolo - Finland",
            "searchName": "XHC-Akaslompolo-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "XHD",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Ruka",
            "name": "[XHD] - Ruka - Finland",
            "searchName": "XHD-Ruka-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "XGX",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Levi",
            "name": "[XGX] - Levi - Finland",
            "searchName": "XGX-Levi-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "XVM",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Vuokatti",
            "name": "[XVM] - Vuokatti - Finland",
            "searchName": "XVM-Vuokatti-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "XQS",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Sotkamo",
            "name": "[XQS] - Sotkamo - Finland",
            "searchName": "XQS-Sotkamo-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "XPY",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Pyha",
            "name": "[XPY] - Pyha - Finland",
            "searchName": "XPY-Pyha-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "ZLV",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Luosto",
            "name": "[ZLV] - Luosto - Finland",
            "searchName": "ZLV-Luosto-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "YLI",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Ylivieska",
            "name": "[YLI] - Ylivieska - Finland",
            "searchName": "YLI-Ylivieska-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "OUL",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Oulu",
            "name": "[OUL] - Oulu - Finland",
            "searchName": "OUL-Oulu-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "QVY",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Kouvola",
            "name": "[QVY] - Kouvola - Finland",
            "searchName": "QVY-Kouvola-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "POR",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Pori",
            "name": "[POR] - Pori - Finland",
            "searchName": "POR-Pori-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "SJY",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Seinajoki",
            "name": "[SJY] - Seinajoki - Finland",
            "searchName": "SJY-Sein-Seinajoki-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "SOT",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Sodankyla",
            "name": "[SOT] - Sodankyla - Finland",
            "searchName": "SOT-Sodankyla-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "RVN",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Rovaniemi",
            "name": "[RVN] - Rovaniemi - Finland",
            "searchName": "RVN-Rovaniemi-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "RTA",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Rotuma Island",
            "name": "[RTA] - Rotuma Island - Fiji",
            "searchName": "RTA-Rotuma Island--Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "PTF",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Malololailai",
            "name": "[PTF] - Malololailai - Fiji",
            "searchName": "PTF-Malololailai-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "PHR",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Pacific Harbor",
            "name": "[PHR] - Pacific Harbor - Fiji",
            "searchName": "PHR-Pacific Harbor-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "RBI",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Rabi",
            "name": "[RBI] - Rabi - Fiji",
            "searchName": "RBI-Rabi-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "ONU",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Ono I Lau",
            "name": "[ONU] - Ono I Lau - Fiji",
            "searchName": "ONU-Ono I Lau-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "NTA",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Natadola",
            "name": "[NTA] - Natadola - Fiji",
            "searchName": "NTA-Natadola-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "MNF",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Mana Island",
            "name": "[MNF] - Mana Island - Fiji",
            "searchName": "MNF-Mana Island-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "NAN",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Nadi",
            "name": "[NAN] - Nadi - Fiji",
            "searchName": "NAN-Nadi-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "NGI",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Ngau Island",
            "name": "[NGI] - Ngau Island - Fiji",
            "searchName": "NGI-Ngau Island-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "YAS",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Yasawa Island",
            "name": "[YAS] - Yasawa Island - Fiji",
            "searchName": "YAS-Yasawa Island-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "VAU",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Vatukoula",
            "name": "[VAU] - Vatukoula - Fiji",
            "searchName": "VAU-Vatukoula-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "VBV",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Vanuabalavu",
            "name": "[VBV] - Vanuabalavu - Fiji",
            "searchName": "VBV-Vanuabalavu-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "VTF",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Vatulele",
            "name": "[VTF] - Vatulele - Fiji",
            "searchName": "VTF-Vatulele-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "TTL",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Turtle Island",
            "name": "[TTL] - Turtle Island - Fiji",
            "searchName": "TTL-Turtle Island-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "TVU",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Taveuni",
            "name": "[TVU] - Taveuni - Fiji",
            "searchName": "TVU-Taveuni-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "SVU",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Savusavu",
            "name": "[SVU] - Savusavu - Fiji",
            "searchName": "SVU-Savusavu-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "PXS",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Puerto De Santa Maria",
            "name": "[PXS] - Puerto De Santa Maria - Spain",
            "searchName": "PXS-Puerto De Santa Maria-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "QGN",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Tarragona",
            "name": "[QGN] - Tarragona - Spain",
            "searchName": "QGN-Tarragona-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "REU",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Reus",
            "name": "[REU] - Reus - Spain",
            "searchName": "REU-Reus-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "RGS",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Burgos",
            "name": "[RGS] - Burgos - Spain",
            "searchName": "RGS-Burgos-Austin-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "SLM",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Salamanca",
            "name": "[SLM] - Salamanca - Spain",
            "searchName": "SLM-Salamanca-Salamanque-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "SPC",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Santa Cruz De La Palma",
            "name": "[SPC] - Santa Cruz De La Palma - Spain",
            "searchName": "SPC-Santa Cruz de La Palma-Santa Cruz De La Palma-Santa Cruz de la Palma-La Palma-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "SPO",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "San Pablo",
            "name": "[SPO] - San Pablo - Spain",
            "searchName": "SPO-San Pablo-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "RMU",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Corvera",
            "name": "[RMU] - Corvera - Spain",
            "searchName": "RMU-Corvera-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "RJL",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Logrono",
            "name": "[RJL] - Logrono - Spain",
            "searchName": "RJL-Logro-Logrono-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "RRA",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Ronda",
            "name": "[RRA] - Ronda - Spain",
            "searchName": "RRA-Ronda-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "ROZ",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Rota",
            "name": "[ROZ] - Rota - Spain",
            "searchName": "ROZ-Rota-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "SDR",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Santander",
            "name": "[SDR] - Santander - Spain",
            "searchName": "SDR-Santander-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "SCQ",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Santiago De Compostela",
            "name": "[SCQ] - Santiago De Compostela - Spain",
            "searchName": "SCQ-Santiago de Compostela-Santiago De Compostela-St Jacques de Compostelle-Santiago di Compostela-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "SKR",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Shakiso",
            "name": "[SKR] - Shakiso - Ethiopia",
            "searchName": "SKR-Shakiso-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "SHC",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Indaselassie",
            "name": "[SHC] - Indaselassie - Ethiopia",
            "searchName": "SHC-Indaselassie-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "PWI",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Pawi",
            "name": "[PWI] - Pawi - Ethiopia",
            "searchName": "PWI-Pawi-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "OTA",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Mota",
            "name": "[OTA] - Mota - Ethiopia",
            "searchName": "OTA-Mota-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "NDM",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Mendi",
            "name": "[NDM] - Mendi - Ethiopia",
            "searchName": "NDM-Mendi-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "NEJ",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Nejjo",
            "name": "[NEJ] - Nejjo - Ethiopia",
            "searchName": "NEJ-Nejjo-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "NEK",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Nekemt",
            "name": "[NEK] - Nekemt - Ethiopia",
            "searchName": "NEK-Nekemt-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "MZX",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Mena",
            "name": "[MZX] - Mena - Ethiopia",
            "searchName": "MZX-Mena-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "MKS",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Mekane Selam",
            "name": "[MKS] - Mekane Selam - Ethiopia",
            "searchName": "MKS-Mekane Selam-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "MTF",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Mizan Teferi",
            "name": "[MTF] - Mizan Teferi - Ethiopia",
            "searchName": "MTF-Mizan Teferi-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "MUJ",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Mui",
            "name": "[MUJ] - Mui - Ethiopia",
            "searchName": "MUJ-Mui-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "MQX",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Makale",
            "name": "[MQX] - Makale - Ethiopia",
            "searchName": "MQX-Makale-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "XBL",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Buno Bedelle",
            "name": "[XBL] - Buno Bedelle - Ethiopia",
            "searchName": "XBL-Buno Bedelle-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "WRA",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Warder",
            "name": "[WRA] - Warder - Ethiopia",
            "searchName": "WRA-Warder-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "TUJ",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Tum",
            "name": "[TUJ] - Tum - Ethiopia",
            "searchName": "TUJ-Tum-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "WAC",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Waca",
            "name": "[WAC] - Waca - Ethiopia",
            "searchName": "WAC-Waca-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "SZE",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Semera",
            "name": "[SZE] - Semera - Ethiopia",
            "searchName": "SZE-Semera-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "SXU",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Soddu",
            "name": "[SXU] - Soddu - Ethiopia",
            "searchName": "SXU-Soddu-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "TIE",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Tippi",
            "name": "[TIE] - Tippi - Ethiopia",
            "searchName": "TIE-Tippi-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "LFO",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Kelafo",
            "name": "[LFO] - Kelafo - Ethiopia",
            "searchName": "LFO-Kelafo-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "LLI",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Lalibela",
            "name": "[LLI] - Lalibela - Ethiopia",
            "searchName": "LLI-Lalibela-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "JIM",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Jimma",
            "name": "[JIM] - Jimma - Ethiopia",
            "searchName": "JIM-Jimma-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "JIJ",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Jijiga",
            "name": "[JIJ] - Jijiga - Ethiopia",
            "searchName": "JIJ-Jijiga-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "HUE",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Humera",
            "name": "[HUE] - Humera - Ethiopia",
            "searchName": "HUE-Humera-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "GDQ",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Gondar",
            "name": "[GDQ] - Gondar - Ethiopia",
            "searchName": "GDQ-Gonder-Gondar-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "GDE",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Gode",
            "name": "[GDE] - Gode - Ethiopia",
            "searchName": "GDE-Gode/Iddidole-Gode-Gode\\/Iddidole-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "GLC",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Geladi",
            "name": "[GLC] - Geladi - Ethiopia",
            "searchName": "GLC-Geladi-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "GNN",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Ghinnir",
            "name": "[GNN] - Ghinnir - Ethiopia",
            "searchName": "GNN-Ghinnir-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "GMB",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Gambela",
            "name": "[GMB] - Gambela - Ethiopia",
            "searchName": "GMB-Gambela-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "GOR",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Gore",
            "name": "[GOR] - Gore - Ethiopia",
            "searchName": "GOR-Gore-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "GOB",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Goba",
            "name": "[GOB] - Goba - Ethiopia",
            "searchName": "GOB-Goba-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "HIL",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Shillavo",
            "name": "[HIL] - Shillavo - Ethiopia",
            "searchName": "HIL-Shillavo-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "FNH",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Fincha",
            "name": "[FNH] - Fincha - Ethiopia",
            "searchName": "FNH-Fincha-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "ETE",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Genda Wuha",
            "name": "[ETE] - Genda Wuha - Ethiopia",
            "searchName": "ETE-Genda Wuha-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "EGL",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Neghelli",
            "name": "[EGL] - Neghelli - Ethiopia",
            "searchName": "EGL-Neghelli-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "DSE",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Dessie",
            "name": "[DSE] - Dessie - Ethiopia",
            "searchName": "DSE-Dessie-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "DBM",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Debra Marcos",
            "name": "[DBM] - Debra Marcos - Ethiopia",
            "searchName": "DBM-Debra Marcos-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "DBT",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Debra Tabor",
            "name": "[DBT] - Debra Tabor - Ethiopia",
            "searchName": "DBT-Debra Tabor-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "DIR",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Dire Dawa",
            "name": "[DIR] - Dire Dawa - Ethiopia",
            "searchName": "DIR-Dire Dawa-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "DEM",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Dembidollo",
            "name": "[DEM] - Dembidollo - Ethiopia",
            "searchName": "DEM-Dembidollo-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "DGC",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Degahbur",
            "name": "[DGC] - Degahbur - Ethiopia",
            "searchName": "DGC-Degahbur-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "BJR",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Bahar Dar",
            "name": "[BJR] - Bahar Dar - Ethiopia",
            "searchName": "BJR-Bahar Dar-Bahir Dar-Baher Dar-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "BEI",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Beica",
            "name": "[BEI] - Beica - Ethiopia",
            "searchName": "BEI-Beica-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "BCY",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Bulchi",
            "name": "[BCY] - Bulchi - Ethiopia",
            "searchName": "BCY-Bulchi-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "AMH",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Arba Mintch",
            "name": "[AMH] - Arba Mintch - Ethiopia",
            "searchName": "AMH-Arba Mintch-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "ALK",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Asela",
            "name": "[ALK] - Asela - Ethiopia",
            "searchName": "ALK-Asela-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "ADD",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Addis Ababa",
            "name": "[ADD] - Addis Ababa - Ethiopia",
            "searchName": "ADD-Addis Abeba-Addis Ababa-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "ABK",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Kabri Dar",
            "name": "[ABK] - Kabri Dar - Ethiopia",
            "searchName": "ABK-Kabri Dar-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "BCO",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Jinka",
            "name": "[BCO] - Jinka - Ethiopia",
            "searchName": "BCO-Jinka-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "AXU",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Axum",
            "name": "[AXU] - Axum - Ethiopia",
            "searchName": "AXU-Aksum-Axum-Aksoum-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "AWA",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Awassa",
            "name": "[AWA] - Awassa - Ethiopia",
            "searchName": "AWA-Awasa-Awassa-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "ASO",
            "countryCode": "ET",
            "cityCode": null,
            "cityName": "Asosa",
            "name": "[ASO] - Asosa - Ethiopia",
            "searchName": "ASO-Asosa-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "ENF",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Enontekio",
            "name": "[ENF] - Enontekio - Finland",
            "searchName": "ENF-Enontekio-Enonteki-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "EVX",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Evreux",
            "name": "[EVX] - Evreux - France",
            "searchName": "EVX-Evreux-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "EPL",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Epinal",
            "name": "[EPL] - Epinal - France",
            "searchName": "EPL-Epinal-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "FNI",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Nimes",
            "name": "[FNI] - Nimes - France",
            "searchName": "FNI-N-Nimes-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "DPE",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Dieppe",
            "name": "[DPE] - Dieppe - France",
            "searchName": "DPE-Dieppe-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "DOL",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Deauville",
            "name": "[DOL] - Deauville - France",
            "searchName": "DOL-Deauville-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "DNR",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Dinard",
            "name": "[DNR] - Dinard - France",
            "searchName": "DNR-Dinard-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "DLE",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Dole",
            "name": "[DLE] - Dole - France",
            "searchName": "DLE-Dole-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "EGC",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Bergerac",
            "name": "[EGC] - Bergerac - France",
            "searchName": "EGC-Bergerac-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "EDM",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "La Roche",
            "name": "[EDM] - La Roche - France",
            "searchName": "EDM-La Roche-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "EBU",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "St Etienne",
            "name": "[EBU] - St Etienne - France",
            "searchName": "EBU-Saint Etienne-St Etienne-Saint-Etienne-de-Valoux--France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CTT",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Le Castellet",
            "name": "[CTT] - Le Castellet - France",
            "searchName": "CTT-Le Castellet-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CSF",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Creil",
            "name": "[CSF] - Creil - France",
            "searchName": "CSF-Creil-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CVF",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Courchevel",
            "name": "[CVF] - Courchevel - France",
            "searchName": "CVF-Courchevel-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "DCM",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Castres",
            "name": "[DCM] - Castres - France",
            "searchName": "DCM-Castres-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "DIJ",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Dijon",
            "name": "[DIJ] - Dijon - France",
            "searchName": "DIJ-Dijon-Digione-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CFE",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Clermont-Ferrand",
            "name": "[CFE] - Clermont-Ferrand - France",
            "searchName": "CFE-Clermont-Ferrand-ferrand-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CFR",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Caen",
            "name": "[CFR] - Caen - France",
            "searchName": "CFR-Caen-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CEQ",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Cannes",
            "name": "[CEQ] - Cannes - France",
            "searchName": "CEQ-Cannes-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CER",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Cherbourg",
            "name": "[CER] - Cherbourg - France",
            "searchName": "CER-Cherbourg-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CET",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Cholet",
            "name": "[CET] - Cholet - France",
            "searchName": "CET-Cholet-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CHR",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Chateauroux",
            "name": "[CHR] - Chateauroux - France",
            "searchName": "CHR-Chateauroux-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CQF",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Calais",
            "name": "[CQF] - Calais - France",
            "searchName": "CQF-Calais-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CNG",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Cognac",
            "name": "[CNG] - Cognac - France",
            "searchName": "CNG-Cognac-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CMR",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Colmar",
            "name": "[CMR] - Colmar - France",
            "searchName": "CMR-Colmar-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CMF",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Chambery",
            "name": "[CMF] - Chambery - France",
            "searchName": "CMF-Chamb-Chambery-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CLY",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Calvi",
            "name": "[CLY] - Calvi - France",
            "searchName": "CLY-Calvi-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "BVE",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Brive-La-Gaillarde",
            "name": "[BVE] - Brive-La-Gaillarde - France",
            "searchName": "BVE-Brive-la-Gaillarde-La-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "BYF",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Albert",
            "name": "[BYF] - Albert - France",
            "searchName": "BYF-Albert-Meaulte-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "BZR",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Beziers",
            "name": "[BZR] - Beziers - France",
            "searchName": "BZR-B-Beziers-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CCF",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Carcassonne",
            "name": "[CCF] - Carcassonne - France",
            "searchName": "CCF-Carcassonne-Carcasona-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "BES",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Brest",
            "name": "[BES] - Brest - France",
            "searchName": "BES-Brest-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "BIQ",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Biarritz",
            "name": "[BIQ] - Biarritz - France",
            "searchName": "BIQ-Biarritz-Biarriz-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "BIA",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Bastia",
            "name": "[BIA] - Bastia - France",
            "searchName": "BIA-Bastia-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "KDV",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Kandavu",
            "name": "[KDV] - Kandavu - Fiji",
            "searchName": "KDV-Kandavu-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "KAY",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Wakaya Island",
            "name": "[KAY] - Wakaya Island - Fiji",
            "searchName": "KAY-Wakaya Island-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "KVU",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Korolevu",
            "name": "[KVU] - Korolevu - Fiji",
            "searchName": "KVU-Korolevu-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "LBS",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Labasa",
            "name": "[LBS] - Labasa - Fiji",
            "searchName": "LBS-Labasa-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "KXF",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Koro Island",
            "name": "[KXF] - Koro Island - Fiji",
            "searchName": "KXF-Koro Island-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "MFJ",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Moala",
            "name": "[MFJ] - Moala - Fiji",
            "searchName": "MFJ-Moala-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "LUC",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Laucala Island",
            "name": "[LUC] - Laucala Island - Fiji",
            "searchName": "LUC-Laucala Island-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "LKB",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Lakeba",
            "name": "[LKB] - Lakeba - Fiji",
            "searchName": "LKB-Lakeba-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "LEV",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Bureta",
            "name": "[LEV] - Bureta - Fiji",
            "searchName": "LEV-Bureta-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "ICI",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Cicia",
            "name": "[ICI] - Cicia - Fiji",
            "searchName": "ICI-Cicia-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "CST",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Castaway",
            "name": "[CST] - Castaway - Fiji",
            "searchName": "CST-Castaway-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "AQS",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Saqani",
            "name": "[AQS] - Saqani - Fiji",
            "searchName": "AQS-Saqani-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "BXL",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Blue Lagoon",
            "name": "[BXL] - Blue Lagoon - Fiji",
            "searchName": "BXL-Blue Lagoon-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "BVF",
            "countryCode": "FJ",
            "cityCode": null,
            "cityName": "Bua",
            "name": "[BVF] - Bua - Fiji",
            "searchName": "BVF-Bua-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "MPN",
            "countryCode": "FK",
            "cityCode": null,
            "cityName": "Mount Pleasant",
            "name": "[MPN] - Mount Pleasant - Falkland Islands",
            "searchName": "MPN-Mount Pleasant-Falkland Islands-Falklandinseln-Falkland Adalari-Islas Malvinas-Îles Malouines-Isole Falkland",
            "priority": 0
        },
        {
            "iataCode": "PSY",
            "countryCode": "FK",
            "cityCode": null,
            "cityName": "Port Stanley",
            "name": "[PSY] - Port Stanley - Falkland Islands",
            "searchName": "PSY-Port Stanley-Falkland Islands-Falklandinseln-Falkland Adalari-Islas Malvinas-Îles Malouines-Isole Falkland",
            "priority": 0
        },
        {
            "iataCode": "PNI",
            "countryCode": "FM",
            "cityCode": null,
            "cityName": "Pohnpei",
            "name": "[PNI] - Pohnpei - Micronesia",
            "searchName": "PNI-Pohnpei-Mikronézia-Micronèsia-Mikronezya-Mikronésie-Mikronesia-Mikronesien-Micronezia-Mikroneesia-Mikronezija-FSM-Micronesia-États fédérés de Micronésie",
            "priority": 0
        },
        {
            "iataCode": "TKK",
            "countryCode": "FM",
            "cityCode": null,
            "cityName": "Truk",
            "name": "[TKK] - Truk - Micronesia",
            "searchName": "TKK-Truk-Mikronézia-Micronèsia-Mikronezya-Mikronésie-Mikronesia-Mikronesien-Micronezia-Mikroneesia-Mikronezija-FSM-Micronesia-États fédérés de Micronésie",
            "priority": 0
        },
        {
            "iataCode": "ULI",
            "countryCode": "FM",
            "cityCode": null,
            "cityName": "Ulithi",
            "name": "[ULI] - Ulithi - Micronesia",
            "searchName": "ULI-Ulithi-Mikronézia-Micronèsia-Mikronezya-Mikronésie-Mikronesia-Mikronesien-Micronezia-Mikroneesia-Mikronezija-FSM-Micronesia-États fédérés de Micronésie",
            "priority": 0
        },
        {
            "iataCode": "YAP",
            "countryCode": "FM",
            "cityCode": null,
            "cityName": "Yap",
            "name": "[YAP] - Yap - Micronesia",
            "searchName": "YAP-Yap-Mikronézia-Micronèsia-Mikronezya-Mikronésie-Mikronesia-Mikronesien-Micronezia-Mikroneesia-Mikronezija-FSM-Micronesia-États fédérés de Micronésie",
            "priority": 0
        },
        {
            "iataCode": "KSA",
            "countryCode": "FM",
            "cityCode": null,
            "cityName": "Kosrae",
            "name": "[KSA] - Kosrae - Micronesia",
            "searchName": "KSA-Kosrae-Mikronézia-Micronèsia-Mikronezya-Mikronésie-Mikronesia-Mikronesien-Micronezia-Mikroneesia-Mikronezija-FSM-Micronesia-États fédérés de Micronésie",
            "priority": 0
        },
        {
            "iataCode": "FAE",
            "countryCode": "FO",
            "cityCode": null,
            "cityName": "Sorvágur",
            "name": "[FAE] - Sorvágur - Faroe Islands",
            "searchName": "FAE-Faroe Islands-Sorv-Sorvágur-Faroerinseln-Faroe Adalari-Islas Feroe-Îles Féroé-Isole Faroe",
            "priority": 0
        },
        {
            "iataCode": "MLN",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Melilla",
            "name": "[MLN] - Melilla - Spain",
            "searchName": "MLN-Melilla-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "NDO",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "La Palma Del Condado",
            "name": "[NDO] - La Palma Del Condado - Spain",
            "searchName": "NDO-La Palma del Condado-La Palma Del Condado-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "ODB",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Cordoba",
            "name": "[ODB] - Cordoba - Spain",
            "searchName": "ODB-Cordoba-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "OVD",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Oviedo",
            "name": "[OVD] - Oviedo - Spain",
            "searchName": "OVD-Asturias-Oviedo-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "OZI",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Bobadilla",
            "name": "[OZI] - Bobadilla - Spain",
            "searchName": "OZI-Bobadilla-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "OZL",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Orzola",
            "name": "[OZL] - Orzola - Spain",
            "searchName": "OZL-Orzola-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "OZP",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Moron",
            "name": "[OZP] - Moron - Spain",
            "searchName": "OZP-Moron-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "OZU",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Montilla",
            "name": "[OZU] - Montilla - Spain",
            "searchName": "OZU-Montilla-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "PNA",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Pamplona",
            "name": "[PNA] - Pamplona - Spain",
            "searchName": "PNA-Pamplona-Pampelune-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "VIT",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Vitoria",
            "name": "[VIT] - Vitoria - Spain",
            "searchName": "VIT-Vitoria-gasteiz-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "UER",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Puertollano",
            "name": "[UER] - Puertollano - Spain",
            "searchName": "UER-Puertollano-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "XOU",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Segovia",
            "name": "[XOU] - Segovia - Spain",
            "searchName": "XOU-Segovia-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "XUF",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Cartagena",
            "name": "[XUF] - Cartagena - Spain",
            "searchName": "XUF-Cartagena-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "XTJ",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Toledo",
            "name": "[XTJ] - Toledo - Spain",
            "searchName": "XTJ-Toledo-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "XRY",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Jerez De La Frontera",
            "name": "[XRY] - Jerez De La Frontera - Spain",
            "searchName": "XRY-Jerez de la Frontera-Jerez De La Frontera-X-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "XJR",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Lorca",
            "name": "[XJR] - Lorca - Spain",
            "searchName": "XJR-Lorca-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "XJO",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Soria",
            "name": "[XJO] - Soria - Spain",
            "searchName": "XJO-Soria-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "ZAZ",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Zaragoza",
            "name": "[ZAZ] - Zaragoza - Spain",
            "searchName": "ZAZ-Saragossa-Zaragoza-Saragosse-Saragozza-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "ZRC",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "San Pedro de Alcantara",
            "name": "[ZRC] - San Pedro de Alcantara - Spain",
            "searchName": "ZRC-San Pedro de Alcantara-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "LRT",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Lorient",
            "name": "[LRT] - Lorient - France",
            "searchName": "LRT-Lorient-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LSO",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Les Sables",
            "name": "[LSO] - Les Sables - France",
            "searchName": "LSO-Les Sables-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LRH",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "La Rochelle",
            "name": "[LRH] - La Rochelle - France",
            "searchName": "LRH-La Rochelle-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LPY",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Le Puy",
            "name": "[LPY] - Le Puy - France",
            "searchName": "LPY-Le Puy-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "MFX",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Meribel",
            "name": "[MFX] - Meribel - France",
            "searchName": "MFX-Meribel-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "MEN",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Mende",
            "name": "[MEN] - Mende - France",
            "searchName": "MEN-Mende-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "TLS",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Toulouse",
            "name": "[TLS] - Toulouse - France",
            "searchName": "TLS-Toulouse-Tolosa-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "TNF",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Toussus-Le-Noble",
            "name": "[TNF] - Toussus-Le-Noble - France",
            "searchName": "TNF-Toussus-Le-Noble-Toussus Le Noble-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "SXB",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Strasbourg",
            "name": "[SXB] - Strasbourg - France",
            "searchName": "SXB-Stra-Strasbourg-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "SYT",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Saint Yan",
            "name": "[SYT] - Saint Yan - France",
            "searchName": "SYT-St. Yan-Saint Yan-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "PIS",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Poitiers",
            "name": "[PIS] - Poitiers - France",
            "searchName": "PIS-Poitiers-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "PUF",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Pau",
            "name": "[PUF] - Pau - France",
            "searchName": "PUF-Pau-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "PRP",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Propriano",
            "name": "[PRP] - Propriano - France",
            "searchName": "PRP-Propriano-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "RCO",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Rochefort",
            "name": "[RCO] - Rochefort - France",
            "searchName": "RCO-Rochefort-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "QZF",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Font-Romeu",
            "name": "[QZF] - Font-Romeu - France",
            "searchName": "QZF-Font-Romeu-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "RHE",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Reims",
            "name": "[RHE] - Reims - France",
            "searchName": "RHE-Reims-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "RDZ",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Rodez",
            "name": "[RDZ] - Rodez - France",
            "searchName": "RDZ-Rodez-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "QXB",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Aix-en-Provence",
            "name": "[QXB] - Aix-en-Provence - France",
            "searchName": "QXB-Aix-en-Provence-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "QXK",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "St-Genis",
            "name": "[QXK] - St-Genis - France",
            "searchName": "QXK-St-Genis-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "RNE",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Roanne",
            "name": "[RNE] - Roanne - France",
            "searchName": "RNE-Roanne-Roane-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LDE",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Lourdes",
            "name": "[LDE] - Lourdes - France",
            "searchName": "LDE-Lourdes/Tarbes-Lourdes-Lourdes\\/Tarbes-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LIL",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Lille",
            "name": "[LIL] - Lille - France",
            "searchName": "LIL-Lille-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LIG",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Limoges",
            "name": "[LIG] - Limoges - France",
            "searchName": "LIG-Limoges-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LME",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Le Mans",
            "name": "[LME] - Le Mans - France",
            "searchName": "LME-Le Mans-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "RYN",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Royan",
            "name": "[RYN] - Royan - France",
            "searchName": "RYN-Royan-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "SBK",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Tremuson",
            "name": "[SBK] - Tremuson - France",
            "searchName": "SBK-Tremuson-St Brieuc-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "SCP",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "St Crepin",
            "name": "[SCP] - St Crepin - France",
            "searchName": "SCP-St Crepin-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "SNR",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "St Nazaire",
            "name": "[SNR] - St Nazaire - France",
            "searchName": "SNR-Saint Nazaire-St Nazaire-Saint-Nazaire-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "SOZ",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Solenzara",
            "name": "[SOZ] - Solenzara - France",
            "searchName": "SOZ-Solenzara-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "MPL",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Montpellier",
            "name": "[MPL] - Montpellier - France",
            "searchName": "MPL-Montpellier-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "NCY",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Annecy",
            "name": "[NCY] - Annecy - France",
            "searchName": "NCY-Annecy-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "MAH",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Menorca",
            "name": "[MAH] - Menorca - Spain",
            "searchName": "MAH-Menorca/Mahon-Menorca-Mah-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "LEU",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Seo de Urgel",
            "name": "[LEU] - Seo de Urgel - Spain",
            "searchName": "LEU-Seo de Urgel-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "NIT",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Niort",
            "name": "[NIT] - Niort - France",
            "searchName": "NIT-Niort-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "MVV",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Megeve",
            "name": "[MVV] - Megeve - France",
            "searchName": "MVV-Megeve-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "MXN",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Morlaix",
            "name": "[MXN] - Morlaix - France",
            "searchName": "MXN-Morlaix-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "BOU",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Bourges",
            "name": "[BOU] - Bourges - France",
            "searchName": "BOU-Bourges-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "AUF",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Auxerre",
            "name": "[AUF] - Auxerre - France",
            "searchName": "AUF-Auxerre-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "AUR",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Aurillac",
            "name": "[AUR] - Aurillac - France",
            "searchName": "AUR-Aurillac-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "AVN",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Avignon",
            "name": "[AVN] - Avignon - France",
            "searchName": "AVN-Avignon-Avi-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "BAE",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Barcelonnette",
            "name": "[BAE] - Barcelonnette - France",
            "searchName": "BAE-Barcelonnette-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "AGF",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Agen",
            "name": "[AGF] - Agen - France",
            "searchName": "AGF-Agen-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "ANE",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Angers",
            "name": "[ANE] - Angers - France",
            "searchName": "ANE-Angers-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "ANG",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Angouleme",
            "name": "[ANG] - Angouleme - France",
            "searchName": "ANG-Angouleme-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "AJA",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Ajaccio",
            "name": "[AJA] - Ajaccio - France",
            "searchName": "AJA-Ajaccio-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "AHZ",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Alpe D Huez",
            "name": "[AHZ] - Alpe D Huez - France",
            "searchName": "AHZ-Alpe D Huez-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LAI",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Lannion",
            "name": "[LAI] - Lannion - France",
            "searchName": "LAI-Lannion-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LBI",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Albi",
            "name": "[LBI] - Albi - France",
            "searchName": "LBI-Albi-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LBY",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "La Baule-Escoublac",
            "name": "[LBY] - La Baule-Escoublac - France",
            "searchName": "LBY-La Baule-Escoublac-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LDV",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Landivisiau",
            "name": "[LDV] - Landivisiau - France",
            "searchName": "LDV-Landivisiau-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "HYV",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Hyvinkaa",
            "name": "[HYV] - Hyvinkaa - Finland",
            "searchName": "HYV-Hyvinkaa-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "JOE",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Joensuu",
            "name": "[JOE] - Joensuu - Finland",
            "searchName": "JOE-Joensuu-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "JYV",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Jyvaskyla",
            "name": "[JYV] - Jyvaskyla - Finland",
            "searchName": "JYV-Jyv-Jyvaskyla-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "KAO",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Kuusamo",
            "name": "[KAO] - Kuusamo - Finland",
            "searchName": "KAO-Kuusamo-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "KAU",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Kauhava",
            "name": "[KAU] - Kauhava - Finland",
            "searchName": "KAU-Kauhava-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "KAJ",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Kajaani",
            "name": "[KAJ] - Kajaani - Finland",
            "searchName": "KAJ-Kajaani-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "IVL",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Ivalo",
            "name": "[IVL] - Ivalo - Finland",
            "searchName": "IVL-Ivalo-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "LPP",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Lappeenranta",
            "name": "[LPP] - Lappeenranta - Finland",
            "searchName": "LPP-Lappeenranta-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "MIK",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Mikkeli",
            "name": "[MIK] - Mikkeli - Finland",
            "searchName": "MIK-Mikkeli-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "KOK",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Kokkola",
            "name": "[KOK] - Kokkola - Finland",
            "searchName": "KOK-Kokkola/Pietarsaari-Kokkola-Kokkola\\/Pietarsaari-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "KUO",
            "countryCode": "FI",
            "cityCode": null,
            "cityName": "Kuopio",
            "name": "[KUO] - Kuopio - Finland",
            "searchName": "KUO-Kuopio-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "LVA",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Laval",
            "name": "[LVA] - Laval - France",
            "searchName": "LVA-Laval-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "MCU",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Montlucon",
            "name": "[MCU] - Montlucon - France",
            "searchName": "MCU-Montlucon-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "HZB",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Hazebrouck",
            "name": "[HZB] - Hazebrouck - France",
            "searchName": "HZB-Hazebrouck-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "IDY",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Ile dYeu",
            "name": "[IDY] - Ile dYeu - France",
            "searchName": "IDY-Ile d Yeu-Ile dYeu-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "GFR",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Granville",
            "name": "[GFR] - Granville - France",
            "searchName": "GFR-Granville-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "GAT",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Gap",
            "name": "[GAT] - Gap - France",
            "searchName": "GAT-Gap-Gap France-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "FSC",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Figari",
            "name": "[FSC] - Figari - France",
            "searchName": "FSC-Figari-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XVS",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Valenciennes",
            "name": "[XVS] - Valenciennes - France",
            "searchName": "XVS-Valenciennes-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XED",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Disneyland Paris",
            "name": "[XED] - Disneyland Paris - France",
            "searchName": "XED-Disneyland Paris-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XCC",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Le Creusot/Montceau",
            "name": "[XCC] - Le Creusot/Montceau - France",
            "searchName": "XCC-Le Creusot/Montceau-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "ZAF",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Arles",
            "name": "[ZAF] - Arles - France",
            "searchName": "ZAF-Arles-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "ZBH",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Severac-le-Chateau",
            "name": "[ZBH] - Severac-le-Chateau - France",
            "searchName": "ZBH-Severac-le-Chateau-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "ZAO",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Cahors",
            "name": "[ZAO] - Cahors - France",
            "searchName": "ZAO-Cahors-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "UIP",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Quimper",
            "name": "[UIP] - Quimper - France",
            "searchName": "UIP-Quimper-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "URO",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Rouen",
            "name": "[URO] - Rouen - France",
            "searchName": "URO-Rouen-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "TUF",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Tours",
            "name": "[TUF] - Tours - France",
            "searchName": "TUF-Tours-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "VTL",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Vittel",
            "name": "[VTL] - Vittel - France",
            "searchName": "VTL-Vittel-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "VNE",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Vannes",
            "name": "[VNE] - Vannes - France",
            "searchName": "VNE-Vannes-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "VAF",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Valence",
            "name": "[VAF] - Valence - France",
            "searchName": "VAF-Valence-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "VHY",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Vichy",
            "name": "[VHY] - Vichy - France",
            "searchName": "VHY-Vichy-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "NVS",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Nevers",
            "name": "[NVS] - Nevers - France",
            "searchName": "NVS-Nevers-Neuwied-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "OBS",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Aubenas",
            "name": "[OBS] - Aubenas - France",
            "searchName": "OBS-Aubenas-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "ORE",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Orleans",
            "name": "[ORE] - Orleans - France",
            "searchName": "ORE-Orleans-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "BEX",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Benson",
            "name": "[BEX] - Benson - United Kingdom",
            "searchName": "BEX-Benson-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BEQ",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Bury St Edmunds",
            "name": "[BEQ] - Bury St Edmunds - United Kingdom",
            "searchName": "BEQ-Bury Saint Edmunds-Bury St Edmunds-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BEB",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Benbecula",
            "name": "[BEB] - Benbecula - United Kingdom",
            "searchName": "BEB-Benbecula-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "DND",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Dundee",
            "name": "[DND] - Dundee - United Kingdom",
            "searchName": "DND-Dundee-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "DOC",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Dornoch",
            "name": "[DOC] - Dornoch - United Kingdom",
            "searchName": "DOC-Dornoch-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "DSA",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Doncaster",
            "name": "[DSA] - Doncaster - United Kingdom",
            "searchName": "DSA-Doncaster-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "COL",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Isle Of Coll",
            "name": "[COL] - Isle Of Coll - United Kingdom",
            "searchName": "COL-Isle Of Coll-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "CEG",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Chester",
            "name": "[CEG] - Chester - United Kingdom",
            "searchName": "CEG-Chester-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "CVT",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Coventry",
            "name": "[CVT] - Coventry - United Kingdom",
            "searchName": "CVT-Coventry-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "CWL",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Cardiff",
            "name": "[CWL] - Cardiff - United Kingdom",
            "searchName": "CWL-Cardiff-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "CSA",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Isle Of Colonsay",
            "name": "[CSA] - Isle Of Colonsay - United Kingdom",
            "searchName": "CSA-Isle Of Colonsay-Colonsay Island-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BOH",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Bournemouth",
            "name": "[BOH] - Bournemouth - United Kingdom",
            "searchName": "BOH-Bournemouth-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BOL",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Ballykelly",
            "name": "[BOL] - Ballykelly - United Kingdom",
            "searchName": "BOL-Ballykelly-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BLK",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Blackpool",
            "name": "[BLK] - Blackpool - United Kingdom",
            "searchName": "BLK-Blackpool-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "CBG",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Cambridge",
            "name": "[CBG] - Cambridge - United Kingdom",
            "searchName": "CBG-Cambridge-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BZZ",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Brize Norton",
            "name": "[BZZ] - Brize Norton - United Kingdom",
            "searchName": "BZZ-Brize Norton-Oxford-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "CAL",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Campbeltown",
            "name": "[CAL] - Campbeltown - United Kingdom",
            "searchName": "CAL-Campbeltown-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "CAX",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Carlisle",
            "name": "[CAX] - Carlisle - United Kingdom",
            "searchName": "CAX-Carlisle-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BWF",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Barrow-In-Furness",
            "name": "[BWF] - Barrow-In-Furness - United Kingdom",
            "searchName": "BWF-Barrow-In-Furness-Barrow In Furness-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BSH",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Brighton",
            "name": "[BSH] - Brighton - United Kingdom",
            "searchName": "BSH-Brighton-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BRR",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Barra",
            "name": "[BRR] - Barra - United Kingdom",
            "searchName": "BRR-Barra-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BRS",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Bristol",
            "name": "[BRS] - Bristol - United Kingdom",
            "searchName": "BRS-Bristol-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BBP",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Bembridge",
            "name": "[BBP] - Bembridge - United Kingdom",
            "searchName": "BBP-Bembridge-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BBS",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Yateley",
            "name": "[BBS] - Yateley - United Kingdom",
            "searchName": "BBS-Blackbush-Yateley-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ADX",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "St Andrews",
            "name": "[ADX] - St Andrews - United Kingdom",
            "searchName": "ADX-St Andrews-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "KOI",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Orkney Island",
            "name": "[KOI] - Orkney Island - United Kingdom",
            "searchName": "KOI-Kirkwall-Orkney Island-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "KNF",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Kings Lynn",
            "name": "[KNF] - Kings Lynn - United Kingdom",
            "searchName": "KNF-Kings Lynn-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "LEQ",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Lands End",
            "name": "[LEQ] - Lands End - United Kingdom",
            "searchName": "LEQ-Lands End-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "LBA",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Leeds",
            "name": "[LBA] - Leeds - United Kingdom",
            "searchName": "LBA-Leeds-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "KYN",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Milton Keynes",
            "name": "[KYN] - Milton Keynes - United Kingdom",
            "searchName": "KYN-Milton Keynes-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "LPL",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Liverpool",
            "name": "[LPL] - Liverpool - United Kingdom",
            "searchName": "LPL-Liverpool-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "PGF",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Perpignan",
            "name": "[PGF] - Perpignan - France",
            "searchName": "PGF-Perpignan-Perpi-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "PGX",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Perigueux",
            "name": "[PGX] - Perigueux - France",
            "searchName": "PGX-P-Perigueux-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "OUU",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Ouanga",
            "name": "[OUU] - Ouanga - Gabon",
            "searchName": "OUU-Ouanga-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "OYE",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Oyem",
            "name": "[OYE] - Oyem - Gabon",
            "searchName": "OYE-Oyem-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "OWE",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Owendo",
            "name": "[OWE] - Owendo - Gabon",
            "searchName": "OWE-Owendo-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "OKN",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Okondja",
            "name": "[OKN] - Okondja - Gabon",
            "searchName": "OKN-Okondja-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "OMB",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Omboue",
            "name": "[OMB] - Omboue - Gabon",
            "searchName": "OMB-Omboue-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MYB",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Mayoumba",
            "name": "[MYB] - Mayoumba - Gabon",
            "searchName": "MYB-Mayoumba-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MVX",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Minvoul",
            "name": "[MVX] - Minvoul - Gabon",
            "searchName": "MVX-Minvoul-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MZC",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Mitzic",
            "name": "[MZC] - Mitzic - Gabon",
            "searchName": "MZC-Mitzic-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "NKA",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Nkan",
            "name": "[NKA] - Nkan - Gabon",
            "searchName": "NKA-Nkan-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MKU",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Makokou",
            "name": "[MKU] - Makokou - Gabon",
            "searchName": "MKU-Makokou-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MKB",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Mekambo",
            "name": "[MKB] - Mekambo - Gabon",
            "searchName": "MKB-Mekambo-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MVG",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Mevang",
            "name": "[MVG] - Mevang - Gabon",
            "searchName": "MVG-Mevang-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MVB",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Franceville",
            "name": "[MVB] - Franceville - Gabon",
            "searchName": "MVB-Franceville-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "POG",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Port Gentil",
            "name": "[POG] - Port Gentil - Gabon",
            "searchName": "POG-Port Gentil-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "TCH",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Tchibanga",
            "name": "[TCH] - Tchibanga - Gabon",
            "searchName": "TCH-Tchibanga-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "WGY",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Wagny",
            "name": "[WGY] - Wagny - Gabon",
            "searchName": "WGY-Wagny-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "ZKM",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Sette Cama",
            "name": "[ZKM] - Sette Cama - Gabon",
            "searchName": "ZKM-Sette Cama-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "WNE",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Wora Na Ye",
            "name": "[WNE] - Wora Na Ye - Gabon",
            "searchName": "WNE-Wora Na Ye-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "GAX",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Gamba",
            "name": "[GAX] - Gamba - Gabon",
            "searchName": "GAX-Gamba-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "GIM",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Miele Mimbale",
            "name": "[GIM] - Miele Mimbale - Gabon",
            "searchName": "GIM-Miele Mimbale-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "GKO",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Kongoboumba",
            "name": "[GKO] - Kongoboumba - Gabon",
            "searchName": "GKO-Kongoboumba-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "IGE",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Iguela",
            "name": "[IGE] - Iguela - Gabon",
            "searchName": "IGE-Iguela-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MBC",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Mbigou",
            "name": "[MBC] - Mbigou - Gabon",
            "searchName": "MBC-Mbigou-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MDV",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Medouneu",
            "name": "[MDV] - Medouneu - Gabon",
            "searchName": "MDV-Medouneu-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MFF",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Moanda",
            "name": "[MFF] - Moanda - Gabon",
            "searchName": "MFF-Moanda-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MJL",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Mouila",
            "name": "[MJL] - Mouila - Gabon",
            "searchName": "MJL-Mouila-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MGO",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Manega",
            "name": "[MGO] - Manega - Gabon",
            "searchName": "MGO-Manega-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MGX",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Moabi",
            "name": "[MGX] - Moabi - Gabon",
            "searchName": "MGX-Moabi-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "LTL",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Lastourville",
            "name": "[LTL] - Lastourville - Gabon",
            "searchName": "LTL-Lastourville-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "LEO",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Leconi",
            "name": "[LEO] - Leconi - Gabon",
            "searchName": "LEO-Leconi-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "LBQ",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Lambarene",
            "name": "[LBQ] - Lambarene - Gabon",
            "searchName": "LBQ-Lambarene-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "LBV",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Libreville",
            "name": "[LBV] - Libreville - Gabon",
            "searchName": "LBV-Libreville-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "KOU",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Koulamoutou",
            "name": "[KOU] - Koulamoutou - Gabon",
            "searchName": "KOU-Koulamoutou-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "KDJ",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Ndjole",
            "name": "[KDJ] - Ndjole - Gabon",
            "searchName": "KDJ-Ndjole-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "KDN",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Ndende",
            "name": "[KDN] - Ndende - Gabon",
            "searchName": "KDN-Ndende-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "AKE",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Akieni",
            "name": "[AKE] - Akieni - Gabon",
            "searchName": "AKE-Akieni-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "AWE",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Alowe",
            "name": "[AWE] - Alowe - Gabon",
            "searchName": "AWE-Alowe-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "BAW",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Biawonque",
            "name": "[BAW] - Biawonque - Gabon",
            "searchName": "BAW-Biawonque-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "BMM",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Bitam",
            "name": "[BMM] - Bitam - Gabon",
            "searchName": "BMM-Bitam-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "BGP",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Bongo",
            "name": "[BGP] - Bongo - Gabon",
            "searchName": "BGP-Bongo-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "BGB",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Booue",
            "name": "[BGB] - Booue - Gabon",
            "searchName": "BGB-Booue-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "FOU",
            "countryCode": "GA",
            "cityCode": null,
            "cityName": "Fougamou",
            "name": "[FOU] - Fougamou - Gabon",
            "searchName": "FOU-Fougamou-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "FOA",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Foula",
            "name": "[FOA] - Foula - United Kingdom",
            "searchName": "FOA-Foula-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "FIE",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Fair Isle",
            "name": "[FIE] - Fair Isle - United Kingdom",
            "searchName": "FIE-Fair Isle-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "FLH",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Flotta",
            "name": "[FLH] - Flotta - United Kingdom",
            "searchName": "FLH-Flotta-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "FFD",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Fairford",
            "name": "[FFD] - Fairford - United Kingdom",
            "searchName": "FFD-Fairford-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "FEA",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Fetlar",
            "name": "[FEA] - Fetlar - United Kingdom",
            "searchName": "FEA-Fetlar-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "EOI",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Eday",
            "name": "[EOI] - Eday - United Kingdom",
            "searchName": "EOI-Eday-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ENK",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Enniskillen",
            "name": "[ENK] - Enniskillen - United Kingdom",
            "searchName": "ENK-Enniskillen-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ESH",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Shoreham By Sea",
            "name": "[ESH] - Shoreham By Sea - United Kingdom",
            "searchName": "ESH-Shoreham By Sea-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "FAB",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Farnborough",
            "name": "[FAB] - Farnborough - United Kingdom",
            "searchName": "FAB-Farnborough-Farnborough Hampshire-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "EXT",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Exeter",
            "name": "[EXT] - Exeter - United Kingdom",
            "searchName": "EXT-Exeter-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "GLO",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Gloucester",
            "name": "[GLO] - Gloucester - United Kingdom",
            "searchName": "GLO-Gloucester-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "FZO",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Filton",
            "name": "[FZO] - Filton - United Kingdom",
            "searchName": "FZO-Filton-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "FWM",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Fort William",
            "name": "[FWM] - Fort William - United Kingdom",
            "searchName": "FWM-Fort William-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "FSS",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Forres",
            "name": "[FSS] - Forres - United Kingdom",
            "searchName": "FSS-Forres-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "HLY",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Holyhead",
            "name": "[HLY] - Holyhead - United Kingdom",
            "searchName": "HLY-Holyhead-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "GSY",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Grimsby",
            "name": "[GSY] - Grimsby - United Kingdom",
            "searchName": "GSY-Grimsby-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "HAW",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Haverfordwest",
            "name": "[HAW] - Haverfordwest - United Kingdom",
            "searchName": "HAW-Haverfordwest-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "WIC",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Wick",
            "name": "[WIC] - Wick - United Kingdom",
            "searchName": "WIC-Wick-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "WHS",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Whalsay",
            "name": "[WHS] - Whalsay - United Kingdom",
            "searchName": "WHS-Whalsay-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "WRY",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Westray",
            "name": "[WRY] - Westray - United Kingdom",
            "searchName": "WRY-Westray-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "WTN",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Waddington",
            "name": "[WTN] - Waddington - United Kingdom",
            "searchName": "WTN-Waddington-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XGM",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Grantham",
            "name": "[XGM] - Grantham - United Kingdom",
            "searchName": "XGM-Grantham-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XHU",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Huntingdon",
            "name": "[XHU] - Huntingdon - United Kingdom",
            "searchName": "XHU-Huntingdon-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XSR",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Salisbury",
            "name": "[XSR] - Salisbury - United Kingdom",
            "searchName": "XSR-Salisbury-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XWB",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Stirling",
            "name": "[XWB] - Stirling - United Kingdom",
            "searchName": "XWB-Stirling-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XWD",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Wakefield",
            "name": "[XWD] - Wakefield - United Kingdom",
            "searchName": "XWD-Wakefield-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XWE",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Wellingborough",
            "name": "[XWE] - Wellingborough - United Kingdom",
            "searchName": "XWE-Wellingborough-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XWH",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Stoke-on-Trent",
            "name": "[XWH] - Stoke-on-Trent - United Kingdom",
            "searchName": "XWH-Stoke-on-Trent-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XWI",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Wigan",
            "name": "[XWI] - Wigan - United Kingdom",
            "searchName": "XWI-Wigan-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XVW",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Wolverhampton",
            "name": "[XVW] - Wolverhampton - United Kingdom",
            "searchName": "XVW-Wolverhampton-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XWS",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Swindon",
            "name": "[XWS] - Swindon - United Kingdom",
            "searchName": "XWS-Swindon-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XWN",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Warrington",
            "name": "[XWN] - Warrington - United Kingdom",
            "searchName": "XWN-Warrington-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XWO",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Woking",
            "name": "[XWO] - Woking - United Kingdom",
            "searchName": "XWO-Woking-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XRU",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Rugby",
            "name": "[XRU] - Rugby - United Kingdom",
            "searchName": "XRU-Rugby-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XTK",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Thirsk",
            "name": "[XTK] - Thirsk - United Kingdom",
            "searchName": "XTK-Thirsk-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XPW",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Didcot",
            "name": "[XPW] - Didcot - United Kingdom",
            "searchName": "XPW-Didcot-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XVA",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Stockport",
            "name": "[XVA] - Stockport - United Kingdom",
            "searchName": "XVA-Stockport-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XVB",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Stafford",
            "name": "[XVB] - Stafford - United Kingdom",
            "searchName": "XVB-Stafford-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XVC",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Crewe",
            "name": "[XVC] - Crewe - United Kingdom",
            "searchName": "XVC-Crewe-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XVG",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Darlington",
            "name": "[XVG] - Darlington - United Kingdom",
            "searchName": "XVG-Darlington-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XVH",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Peterborough",
            "name": "[XVH] - Peterborough - United Kingdom",
            "searchName": "XVH-Peterborough-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XVJ",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Stevenage",
            "name": "[XVJ] - Stevenage - United Kingdom",
            "searchName": "XVJ-Stevenage-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XQB",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Basingstoke",
            "name": "[XQB] - Basingstoke - United Kingdom",
            "searchName": "XQB-Basingstoke-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XQD",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Bedford",
            "name": "[XQD] - Bedford - United Kingdom",
            "searchName": "XQD-Bedford-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XQG",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Berwick-upon-Tweed",
            "name": "[XQG] - Berwick-upon-Tweed - United Kingdom",
            "searchName": "XQG-Berwick-upon-Tweed-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XQH",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Derby",
            "name": "[XQH] - Derby - United Kingdom",
            "searchName": "XQH-Derby-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XQI",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Loughborough",
            "name": "[XQI] - Loughborough - United Kingdom",
            "searchName": "XQI-Loughborough-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XQL",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Lancaster",
            "name": "[XQL] - Lancaster - United Kingdom",
            "searchName": "XQL-Lancaster-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XQM",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Market Harborough",
            "name": "[XQM] - Market Harborough - United Kingdom",
            "searchName": "XQM-Market Harborough-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XQT",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Lichfield",
            "name": "[XQT] - Lichfield - United Kingdom",
            "searchName": "XQT-Lichfield-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XQW",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Motherwell",
            "name": "[XQW] - Motherwell - United Kingdom",
            "searchName": "XQW-Motherwell-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XRC",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Runcorn",
            "name": "[XRC] - Runcorn - United Kingdom",
            "searchName": "XRC-Runcorn-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XRE",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Reading",
            "name": "[XRE] - Reading - United Kingdom",
            "searchName": "XRE-Reading-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XRG",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Rugeley",
            "name": "[XRG] - Rugeley - United Kingdom",
            "searchName": "XRG-Rugeley-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XPF",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Penrith",
            "name": "[XPF] - Penrith - United Kingdom",
            "searchName": "XPF-Penrith-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XPT",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Preston",
            "name": "[XPT] - Preston - United Kingdom",
            "searchName": "XPT-Preston-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XMZ",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Macclesfield",
            "name": "[XMZ] - Macclesfield - United Kingdom",
            "searchName": "XMZ-Macclesfield-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XNO",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Northallerton",
            "name": "[XNO] - Northallerton - United Kingdom",
            "searchName": "XNO-Northallerton-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XNE",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Newport",
            "name": "[XNE] - Newport - United Kingdom",
            "searchName": "XNE-Newport-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XNV",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Nuneaton",
            "name": "[XNV] - Nuneaton - United Kingdom",
            "searchName": "XNV-Nuneaton-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "YEO",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Yeovilton",
            "name": "[YEO] - Yeovilton - United Kingdom",
            "searchName": "YEO-Yeovilton-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ZMA",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Mansfield",
            "name": "[ZMA] - Mansfield - United Kingdom",
            "searchName": "ZMA-Mansfield-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ZEQ",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Dewsbury",
            "name": "[ZEQ] - Dewsbury - United Kingdom",
            "searchName": "ZEQ-Dewsbury-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ZFI",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Chesterfield",
            "name": "[ZFI] - Chesterfield - United Kingdom",
            "searchName": "ZFI-Chesterfield-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "WFD",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Woodford",
            "name": "[WFD] - Woodford - United Kingdom",
            "searchName": "WFD-Woodford-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "VLY",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Anglesey",
            "name": "[VLY] - Anglesey - United Kingdom",
            "searchName": "VLY-Anglesey-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "TTK",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Tottenham",
            "name": "[TTK] - Tottenham - United Kingdom",
            "searchName": "TTK-Tottenham-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "UPV",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Upavon",
            "name": "[UPV] - Upavon - United Kingdom",
            "searchName": "UPV-Upavon-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "UNT",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Unst Shetland Island",
            "name": "[UNT] - Unst Shetland Island - United Kingdom",
            "searchName": "UNT-Unst-Unst Shetland Island-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ULL",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Mull",
            "name": "[ULL] - Mull - United Kingdom",
            "searchName": "ULL-Mull-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "SYY",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Stornoway",
            "name": "[SYY] - Stornoway - United Kingdom",
            "searchName": "SYY-Stornoway-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "SWS",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Swansea",
            "name": "[SWS] - Swansea - United Kingdom",
            "searchName": "SWS-Swansea-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "SQZ",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Scampton",
            "name": "[SQZ] - Scampton - United Kingdom",
            "searchName": "SQZ-Scampton-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "TRE",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Tiree",
            "name": "[TRE] - Tiree - United Kingdom",
            "searchName": "TRE-Tiree-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "PME",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Portsmouth",
            "name": "[PME] - Portsmouth - United Kingdom",
            "searchName": "PME-Portsmouth-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "PLH",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Plymouth",
            "name": "[PLH] - Plymouth - United Kingdom",
            "searchName": "PLH-Plymouth-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "PPW",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Papa Westray",
            "name": "[PPW] - Papa Westray - United Kingdom",
            "searchName": "PPW-Papa Westray-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "PSV",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Papa Stour",
            "name": "[PSV] - Papa Stour - United Kingdom",
            "searchName": "PSV-Papa Stour-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "PSL",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Perth",
            "name": "[PSL] - Perth - United Kingdom",
            "searchName": "PSL-Perth-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QUY",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Wyton",
            "name": "[QUY] - Wyton - United Kingdom",
            "searchName": "QUY-Wyton-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QUG",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Chichester",
            "name": "[QUG] - Chichester - United Kingdom",
            "searchName": "QUG-Chichester-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QQD",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Dover",
            "name": "[QQD] - Dover - United Kingdom",
            "searchName": "QQD-Dover-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QQH",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Harwich",
            "name": "[QQH] - Harwich - United Kingdom",
            "searchName": "QQH-Harwich-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QQR",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Ramsgate",
            "name": "[QQR] - Ramsgate - United Kingdom",
            "searchName": "QQR-Ramsgate-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QQX",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Bath",
            "name": "[QQX] - Bath - United Kingdom",
            "searchName": "QQX-Bath-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QQY",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "York",
            "name": "[QQY] - York - United Kingdom",
            "searchName": "QQY-York-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "JCO",
            "countryCode": "MT",
            "cityCode": null,
            "cityName": "Comino",
            "name": "[JCO] - Comino - Malta",
            "searchName": "JCO-Comino-Malta-Malte-Málta-Mal",
            "priority": 0
        },
        {
            "iataCode": "RRG",
            "countryCode": "MU",
            "cityCode": null,
            "cityName": "Rodrigues Island",
            "name": "[RRG] - Rodrigues Island - Mauritius",
            "searchName": "RRG-Rodrigues Is-Rodrigues Island-Mauritius-Mavricij-Mauricijus-Mauricija-Ile Maurice-Mauriti-Mauritius Adasi-Maurice-Mauricio",
            "priority": 0
        },
        {
            "iataCode": "MID",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Merida",
            "name": "[MID] - Merida - Mexico",
            "searchName": "MID-Merida-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "LAP",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "La Paz",
            "name": "[LAP] - La Paz - Mexico",
            "searchName": "LAP-La Paz-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "GDL",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Guadalajara",
            "name": "[GDL] - Guadalajara - Mexico",
            "searchName": "GDL-Guadalajara-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "GYM",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Guaymas",
            "name": "[GYM] - Guaymas - Mexico",
            "searchName": "GYM-Guaymas-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "GUB",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Guerrero Negro",
            "name": "[GUB] - Guerrero Negro - Mexico",
            "searchName": "GUB-Guerrero Negro-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CVM",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Ciudad Victoria",
            "name": "[CVM] - Ciudad Victoria - Mexico",
            "searchName": "CVM-Ciudad Victoria-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CVJ",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Cuernavaca",
            "name": "[CVJ] - Cuernavaca - Mexico",
            "searchName": "CVJ-Cuernavaca-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CTM",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Chetumal",
            "name": "[CTM] - Chetumal - Mexico",
            "searchName": "CTM-Chetumal-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "ESE",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Ensenada",
            "name": "[ESE] - Ensenada - Mexico",
            "searchName": "ESE-Ensenada-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "BBN",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Bario",
            "name": "[BBN] - Bario - Malaysia",
            "searchName": "BBN-Bario-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "AOR",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Alor Setar",
            "name": "[AOR] - Alor Setar - Malaysia",
            "searchName": "AOR-Alor Setar-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "BKI",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Kota-Kinabalu",
            "name": "[BKI] - Kota-Kinabalu - Malaysia",
            "searchName": "BKI-Kota Kinabalu-Kota-Kinabalu-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "BKM",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Bakalalan",
            "name": "[BKM] - Bakalalan - Malaysia",
            "searchName": "BKM-Bakalalan-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "BLG",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Belaga",
            "name": "[BLG] - Belaga - Malaysia",
            "searchName": "BLG-Belaga-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "BWH",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Butterworth",
            "name": "[BWH] - Butterworth - Malaysia",
            "searchName": "BWH-Butterworth-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "BSE",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Sematan",
            "name": "[BSE] - Sematan - Malaysia",
            "searchName": "BSE-Sematan-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "BTU",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Bintulu",
            "name": "[BTU] - Bintulu - Malaysia",
            "searchName": "BTU-Bintulu-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "WSP",
            "countryCode": "NI",
            "cityCode": null,
            "cityName": "Waspam",
            "name": "[WSP] - Waspam - Nicaragua",
            "searchName": "WSP-Waspam-Nicaragua-Nikaragua-Nikaragva",
            "priority": 0
        },
        {
            "iataCode": "PUZ",
            "countryCode": "NI",
            "cityCode": null,
            "cityName": "Puerto Cabezas",
            "name": "[PUZ] - Puerto Cabezas - Nicaragua",
            "searchName": "PUZ-Puerto Cabezas-Nicaragua-Nikaragua-Nikaragva",
            "priority": 0
        },
        {
            "iataCode": "RFS",
            "countryCode": "NI",
            "cityCode": null,
            "cityName": "Rosita",
            "name": "[RFS] - Rosita - Nicaragua",
            "searchName": "RFS-Rosita-Nicaragua-Nikaragua-Nikaragva",
            "priority": 0
        },
        {
            "iataCode": "SIU",
            "countryCode": "NI",
            "cityCode": null,
            "cityName": "Siuna",
            "name": "[SIU] - Siuna - Nicaragua",
            "searchName": "SIU-Siuna-Nicaragua-Nikaragua-Nikaragva",
            "priority": 0
        },
        {
            "iataCode": "RNI",
            "countryCode": "NI",
            "cityCode": null,
            "cityName": "Corn Island",
            "name": "[RNI] - Corn Island - Nicaragua",
            "searchName": "RNI-Corn Island-Nicaragua-Nikaragua-Nikaragva",
            "priority": 0
        },
        {
            "iataCode": "NCR",
            "countryCode": "NI",
            "cityCode": null,
            "cityName": "San Carlos",
            "name": "[NCR] - San Carlos - Nicaragua",
            "searchName": "NCR-San Carlos-Nicaragua-Nikaragua-Nikaragva",
            "priority": 0
        },
        {
            "iataCode": "NVG",
            "countryCode": "NI",
            "cityCode": null,
            "cityName": "Nueva Guinea",
            "name": "[NVG] - Nueva Guinea - Nicaragua",
            "searchName": "NVG-Nueva Guinea-Nicaragua-Nikaragua-Nikaragva",
            "priority": 0
        },
        {
            "iataCode": "MST",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Maastricht",
            "name": "[MST] - Maastricht - Netherlands",
            "searchName": "MST-Maastricht-Mastrique-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "JHB",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Johor Bahru",
            "name": "[JHB] - Johor Bahru - Malaysia",
            "searchName": "JHB-Johor Bahru-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "GTK",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Sungei Tekai",
            "name": "[GTK] - Sungei Tekai - Malaysia",
            "searchName": "GTK-Sungei Tekai-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "GSA",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Long Pasia",
            "name": "[GSA] - Long Pasia - Malaysia",
            "searchName": "GSA-Long Pasia-Long Paisa-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "GTB",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Genting",
            "name": "[GTB] - Genting - Malaysia",
            "searchName": "GTB-Genting-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LAC",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Pulau Layang-Layang Island",
            "name": "[LAC] - Pulau Layang-Layang Island - Malaysia",
            "searchName": "LAC-Pulau Layang Layang-Pulau Layang-Layang Island-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LDU",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Lahad Datu",
            "name": "[LDU] - Lahad Datu - Malaysia",
            "searchName": "LDU-Lahad Datu-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LBP",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Long Banga",
            "name": "[LBP] - Long Banga - Malaysia",
            "searchName": "LBP-Long Banga-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LBU",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Labuan",
            "name": "[LBU] - Labuan - Malaysia",
            "searchName": "LBU-Labuan-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "KPI",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Kapit",
            "name": "[KPI] - Kapit - Malaysia",
            "searchName": "KPI-Kapit-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "KUA",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Kuantan",
            "name": "[KUA] - Kuantan - Malaysia",
            "searchName": "KUA-Kuantan-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "KUD",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Kudat",
            "name": "[KUD] - Kudat - Malaysia",
            "searchName": "KUD-Kudat-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "KTE",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Kerteh",
            "name": "[KTE] - Kerteh - Malaysia",
            "searchName": "KTE-Kerteh-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "JAL",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Jalapa",
            "name": "[JAL] - Jalapa - Mexico",
            "searchName": "JAL-Jalapa-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "IZT",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Ixtepec",
            "name": "[IZT] - Ixtepec - Mexico",
            "searchName": "IZT-Ixtepec-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "ISJ",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Isla Mujeres",
            "name": "[ISJ] - Isla Mujeres - Mexico",
            "searchName": "ISJ-Isla Mujeres-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "HUX",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Huatulco",
            "name": "[HUX] - Huatulco - Mexico",
            "searchName": "HUX-Huatulco-Santa Mar-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "BJX",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "León",
            "name": "[BJX] - León - Mexico",
            "searchName": "BJX-Leon/Guanajuato-Le-León-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "BHL",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Bahia Angeles",
            "name": "[BHL] - Bahia Angeles - Mexico",
            "searchName": "BHL-Bahia de los Angeles-Bahia Angeles-Bahia De Los Angeles-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "AGU",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Aguascalientes",
            "name": "[AGU] - Aguascalientes - Mexico",
            "searchName": "AGU-Aguascalientes-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "ACN",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Acuna",
            "name": "[ACN] - Acuna - Mexico",
            "searchName": "ACN-Ciudad Acuna-Acuna-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "ACA",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Acapulco",
            "name": "[ACA] - Acapulco - Mexico",
            "searchName": "ACA-Acapulco-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "AJS",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Abreojos",
            "name": "[AJS] - Abreojos - Mexico",
            "searchName": "AJS-Abreojos-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "AZG",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Apatzingan",
            "name": "[AZG] - Apatzingan - Mexico",
            "searchName": "AZG-Apatzingan-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CME",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Ciudad Del Carmen",
            "name": "[CME] - Ciudad Del Carmen - Mexico",
            "searchName": "CME-Ciudad Del Carmen-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CLQ",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Colima",
            "name": "[CLQ] - Colima - Mexico",
            "searchName": "CLQ-Colima-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CJS",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Ciudad Juarez",
            "name": "[CJS] - Ciudad Juarez - Mexico",
            "searchName": "CJS-Ciudad Ju-Ciudad Juarez-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CJT",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Comitan",
            "name": "[CJT] - Comitan - Mexico",
            "searchName": "CJT-Comitan-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CPE",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Campeche",
            "name": "[CPE] - Campeche - Mexico",
            "searchName": "CPE-Campeche-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CNA",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Cananea",
            "name": "[CNA] - Cananea - Mexico",
            "searchName": "CNA-Cananea-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CEN",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Ciudad Obregon",
            "name": "[CEN] - Ciudad Obregon - Mexico",
            "searchName": "CEN-Ciudad Obregon-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "DGO",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Durango",
            "name": "[DGO] - Durango - Mexico",
            "searchName": "DGO-Durango-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CZM",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Cozumel",
            "name": "[CZM] - Cozumel - Mexico",
            "searchName": "CZM-Cozumel-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CZA",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Chichen Itza",
            "name": "[CZA] - Chichen Itza - Mexico",
            "searchName": "CZA-Chichen Itza-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CYW",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Celaya",
            "name": "[CYW] - Celaya - Mexico",
            "searchName": "CYW-Celaya-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CUA",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Ciudad Constitucion",
            "name": "[CUA] - Ciudad Constitucion - Mexico",
            "searchName": "CUA-Ciudad Constitucion-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CUU",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Chihuahua",
            "name": "[CUU] - Chihuahua - Mexico",
            "searchName": "CUU-Chihuahua-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CUL",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Culiacan",
            "name": "[CUL] - Culiacan - Mexico",
            "searchName": "CUL-Culiac-Culiacan-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "UPN",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Uruapan",
            "name": "[UPN] - Uruapan - Mexico",
            "searchName": "UPN-Uruapan-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "TSL",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Tamuin",
            "name": "[TSL] - Tamuin - Mexico",
            "searchName": "TSL-Tamuin-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "TUY",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Tulum",
            "name": "[TUY] - Tulum - Mexico",
            "searchName": "TUY-Tulum-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "TZM",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Tizimin",
            "name": "[TZM] - Tizimin - Mexico",
            "searchName": "TZM-Tizimin-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "UAC",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "San Luis Rio Colorado",
            "name": "[UAC] - San Luis Rio Colorado - Mexico",
            "searchName": "UAC-San Luis Rio Colorado-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "TRC",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Torreon",
            "name": "[TRC] - Torreon - Mexico",
            "searchName": "TRC-Torreon-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "TPQ",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Tepic",
            "name": "[TPQ] - Tepic - Mexico",
            "searchName": "TPQ-Tepic-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "TIJ",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Tijuana",
            "name": "[TIJ] - Tijuana - Mexico",
            "searchName": "TIJ-Tijuana-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "TGZ",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Tuxtla Gutierrez",
            "name": "[TGZ] - Tuxtla Gutierrez - Mexico",
            "searchName": "TGZ-Tuxtla Guti-Tuxtla Gutierrez-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "TAM",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Tampico",
            "name": "[TAM] - Tampico - Mexico",
            "searchName": "TAM-Tampico-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "TAP",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Tapachula",
            "name": "[TAP] - Tapachula - Mexico",
            "searchName": "TAP-Tapachula-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "TCN",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Tehuacan",
            "name": "[TCN] - Tehuacan - Mexico",
            "searchName": "TCN-Tehuacan-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "SZT",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "S.Cristobal deL Casas",
            "name": "[SZT] - S.Cristobal deL Casas - Mexico",
            "searchName": "SZT-S.Cristobal deL Casas-S. Cristobal del Casas-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "SRL",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Santa Rosalia",
            "name": "[SRL] - Santa Rosalia - Mexico",
            "searchName": "SRL-Santa Rosalia-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "XAL",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Alamos",
            "name": "[XAL] - Alamos - Mexico",
            "searchName": "XAL-Alamos-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "ZMM",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Zamora",
            "name": "[ZMM] - Zamora - Mexico",
            "searchName": "ZMM-Zamora-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "ZLO",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Manzanillo",
            "name": "[ZLO] - Manzanillo - Mexico",
            "searchName": "ZLO-Manzanillo-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "ZCL",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Zacatecas",
            "name": "[ZCL] - Zacatecas - Mexico",
            "searchName": "ZCL-Zacatecas-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "ZIH",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Ixtapa",
            "name": "[ZIH] - Ixtapa - Mexico",
            "searchName": "ZIH-Ixtapa/Zihuatanejo-Ixtapa-Ixtapa\\/Zihuatanejo-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "LMM",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Los Mochis",
            "name": "[LMM] - Los Mochis - Mexico",
            "searchName": "LMM-Los Mochis-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "LTO",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Loreto",
            "name": "[LTO] - Loreto - Mexico",
            "searchName": "LTO-Loreto-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "LOM",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Lagos De Moreno",
            "name": "[LOM] - Lagos De Moreno - Mexico",
            "searchName": "LOM-Lagos De Moreno-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "LOV",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Monclova",
            "name": "[LOV] - Monclova - Mexico",
            "searchName": "LOV-Monclova-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "MAM",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Matamoros",
            "name": "[MAM] - Matamoros - Mexico",
            "searchName": "MAM-Matamoros-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "LZC",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Lazaro Cardenas",
            "name": "[LZC] - Lazaro Cardenas - Mexico",
            "searchName": "LZC-Lazaro Cardenas-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "ULO",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Ulaangom",
            "name": "[ULO] - Ulaangom - Mongolia",
            "searchName": "ULO-Ulaangom-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "ULG",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Ulgit",
            "name": "[ULG] - Ulgit - Mongolia",
            "searchName": "ULG-Ulgit-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "UGT",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Umnugobitour",
            "name": "[UGT] - Umnugobitour - Mongolia",
            "searchName": "UGT-Umnugobitour-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "UGA",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Bulgan",
            "name": "[UGA] - Bulgan - Mongolia",
            "searchName": "UGA-Bulgan-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "ULZ",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Uliastai",
            "name": "[ULZ] - Uliastai - Mongolia",
            "searchName": "ULZ-Uliastai-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "UNR",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Underkhaan",
            "name": "[UNR] - Underkhaan - Mongolia",
            "searchName": "UNR-Underkhaan-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "UUN",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Baruun-Urt",
            "name": "[UUN] - Baruun-Urt - Mongolia",
            "searchName": "UUN-Baruun-Urt-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "TNZ",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Tosontsengel",
            "name": "[TNZ] - Tosontsengel - Mongolia",
            "searchName": "TNZ-Tosontsengel-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "HTM",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Khatgal",
            "name": "[HTM] - Khatgal - Mongolia",
            "searchName": "HTM-Khatgal-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "HVD",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Khovd",
            "name": "[HVD] - Khovd - Mongolia",
            "searchName": "HVD-Hovd-Khovd-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "HBU",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Bulgan, Hovd",
            "name": "[HBU] - Bulgan, Hovd - Mongolia",
            "searchName": "HBU-Bulgan- Hovd-Bulgan, Hovd-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "HJT",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Khujirt",
            "name": "[HJT] - Khujirt - Mongolia",
            "searchName": "HJT-Khujirt-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "LTI",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Altai",
            "name": "[LTI] - Altai - Mongolia",
            "searchName": "LTI-Altai-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "KHR",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Kharkhorin",
            "name": "[KHR] - Kharkhorin - Mongolia",
            "searchName": "KHR-Kharkhorin-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "AVK",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Arvaikheer",
            "name": "[AVK] - Arvaikheer - Mongolia",
            "searchName": "AVK-Arvaikheer-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "BYN",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Bayankhongor",
            "name": "[BYN] - Bayankhongor - Mongolia",
            "searchName": "BYN-Bayankhongor-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "ERT",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Erdenet",
            "name": "[ERT] - Erdenet - Mongolia",
            "searchName": "ERT-Erdenet-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "DLZ",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Dalanzadgad",
            "name": "[DLZ] - Dalanzadgad - Mongolia",
            "searchName": "DLZ-Dalanzadgad-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "COQ",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Choibalsan",
            "name": "[COQ] - Choibalsan - Mongolia",
            "searchName": "COQ-Choibalsan-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "YFT",
            "countryCode": "MO",
            "cityCode": null,
            "cityName": "Taipa",
            "name": "[YFT] - Taipa - Macao",
            "searchName": "YFT-Taipa-Macao-Makau-Ma Cao-Macau-Makao-Maka-China",
            "priority": 0
        },
        {
            "iataCode": "TIQ",
            "countryCode": "MP",
            "cityCode": null,
            "cityName": "Tinian",
            "name": "[TIQ] - Tinian - Northern Mariana Islands",
            "searchName": "TIQ-Tinian-Kepulauan Mariana Utara-Ilhas Marianas do Norte-Noorderlijke Marianen-Northern Mariana Islands-MNP-Kuzey Mariana Adalari-Islas Marianas del Norte-Îles Mariannes du Nord-Isole Marianne Settentrionali",
            "priority": 0
        },
        {
            "iataCode": "SPN",
            "countryCode": "MP",
            "cityCode": null,
            "cityName": "Saipan",
            "name": "[SPN] - Saipan - Northern Mariana Islands",
            "searchName": "SPN-Saipan-Kepulauan Mariana Utara-Ilhas Marianas do Norte-Noorderlijke Marianen-Northern Mariana Islands-MNP-Kuzey Mariana Adalari-Islas Marianas del Norte-Îles Mariannes du Nord-Isole Marianne Settentrionali",
            "priority": 0
        },
        {
            "iataCode": "ROP",
            "countryCode": "MP",
            "cityCode": null,
            "cityName": "Rota",
            "name": "[ROP] - Rota - Northern Mariana Islands",
            "searchName": "ROP-Rota-Kepulauan Mariana Utara-Ilhas Marianas do Norte-Noorderlijke Marianen-Northern Mariana Islands-MNP-Kuzey Mariana Adalari-Islas Marianas del Norte-Îles Mariannes du Nord-Isole Marianne Settentrionali",
            "priority": 0
        },
        {
            "iataCode": "FDF",
            "countryCode": "MQ",
            "cityCode": null,
            "cityName": "Fort De France",
            "name": "[FDF] - Fort De France - Martinique",
            "searchName": "FDF-Fort De France-Martinique-Martinik-Martinica",
            "priority": 0
        },
        {
            "iataCode": "FGD",
            "countryCode": "MR",
            "cityCode": null,
            "cityName": "Fderik",
            "name": "[FGD] - Fderik - Mauritania",
            "searchName": "FGD-Fderik-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "EMN",
            "countryCode": "MR",
            "cityCode": null,
            "cityName": "Nema",
            "name": "[EMN] - Nema - Mauritania",
            "searchName": "EMN-Nema-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "CGT",
            "countryCode": "MR",
            "cityCode": null,
            "cityName": "Chinguitti",
            "name": "[CGT] - Chinguitti - Mauritania",
            "searchName": "CGT-Chinguitti-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "BGH",
            "countryCode": "MR",
            "cityCode": null,
            "cityName": "Boghe",
            "name": "[BGH] - Boghe - Mauritania",
            "searchName": "BGH-Boghe-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "ATR",
            "countryCode": "MR",
            "cityCode": null,
            "cityName": "Atar",
            "name": "[ATR] - Atar - Mauritania",
            "searchName": "ATR-Atar-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "AJJ",
            "countryCode": "MR",
            "cityCode": null,
            "cityName": "Akjoujt",
            "name": "[AJJ] - Akjoujt - Mauritania",
            "searchName": "AJJ-Akjoujt-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "AEO",
            "countryCode": "MR",
            "cityCode": null,
            "cityName": "Aioun El Atrouss",
            "name": "[AEO] - Aioun El Atrouss - Mauritania",
            "searchName": "AEO-Aioun El Atrouss-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "MBR",
            "countryCode": "MR",
            "cityCode": null,
            "cityName": "Mbout",
            "name": "[MBR] - Mbout - Mauritania",
            "searchName": "MBR-Mbout-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "KED",
            "countryCode": "MR",
            "cityCode": null,
            "cityName": "Kaedi",
            "name": "[KED] - Kaedi - Mauritania",
            "searchName": "KED-Kaedi-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "KFA",
            "countryCode": "MR",
            "cityCode": null,
            "cityName": "Kiffa",
            "name": "[KFA] - Kiffa - Mauritania",
            "searchName": "KFA-Kiffa-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "LEG",
            "countryCode": "MR",
            "cityCode": null,
            "cityName": "Aleg",
            "name": "[LEG] - Aleg - Mauritania",
            "searchName": "LEG-Aleg-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "SEY",
            "countryCode": "MR",
            "cityCode": null,
            "cityName": "Selibaby",
            "name": "[SEY] - Selibaby - Mauritania",
            "searchName": "SEY-Selibaby-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "NKC",
            "countryCode": "MR",
            "cityCode": null,
            "cityName": "Nouakchott",
            "name": "[NKC] - Nouakchott - Mauritania",
            "searchName": "NKC-Nouakchott-Nuakchott-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "NDB",
            "countryCode": "MR",
            "cityCode": null,
            "cityName": "Nouadhibou",
            "name": "[NDB] - Nouadhibou - Mauritania",
            "searchName": "NDB-Nouadhibou-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "MOM",
            "countryCode": "MR",
            "cityCode": null,
            "cityName": "Moudjeria",
            "name": "[MOM] - Moudjeria - Mauritania",
            "searchName": "MOM-Moudjeria-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "OUZ",
            "countryCode": "MR",
            "cityCode": null,
            "cityName": "Zouerate",
            "name": "[OUZ] - Zouerate - Mauritania",
            "searchName": "OUZ-Zouerate-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "OTL",
            "countryCode": "MR",
            "cityCode": null,
            "cityName": "Boutilimit",
            "name": "[OTL] - Boutilimit - Mauritania",
            "searchName": "OTL-Boutilimit-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "THT",
            "countryCode": "MR",
            "cityCode": null,
            "cityName": "Tamchakett",
            "name": "[THT] - Tamchakett - Mauritania",
            "searchName": "THT-Tamchakett-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "THI",
            "countryCode": "MR",
            "cityCode": null,
            "cityName": "Tichitt",
            "name": "[THI] - Tichitt - Mauritania",
            "searchName": "THI-Tichitt-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "TIY",
            "countryCode": "MR",
            "cityCode": null,
            "cityName": "Tidjikja",
            "name": "[TIY] - Tidjikja - Mauritania",
            "searchName": "TIY-Tidjikja-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "TMD",
            "countryCode": "MR",
            "cityCode": null,
            "cityName": "Timbedra",
            "name": "[TMD] - Timbedra - Mauritania",
            "searchName": "TMD-Timbedra-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "MNI",
            "countryCode": "MS",
            "cityCode": null,
            "cityName": "Gerald s Park",
            "name": "[MNI] - Gerald s Park - Montserrat",
            "searchName": "MNI-Montserrat-Gerald s Park",
            "priority": 0
        },
        {
            "iataCode": "PCO",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Punta Colorada",
            "name": "[PCO] - Punta Colorada - Mexico",
            "searchName": "PCO-Punta Colorada-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PCV",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Punta Chivato",
            "name": "[PCV] - Punta Chivato - Mexico",
            "searchName": "PCV-Punta Chivato-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PCM",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Playa Del Carmen",
            "name": "[PCM] - Playa Del Carmen - Mexico",
            "searchName": "PCM-Playa del Carmen-Playa Del Carmen-Playa De Carmen-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PAZ",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Poza Rica",
            "name": "[PAZ] - Poza Rica - Mexico",
            "searchName": "PAZ-Poza Rica-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PBC",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Puebla Atlixco",
            "name": "[PBC] - Puebla Atlixco - Mexico",
            "searchName": "PBC-Puebla-Puebla Atlixco-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PDS",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Piedras Negras",
            "name": "[PDS] - Piedras Negras - Mexico",
            "searchName": "PDS-Piedras Negras-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "NOG",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Nogales",
            "name": "[NOG] - Nogales - Mexico",
            "searchName": "NOG-Nogales-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "OAX",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Oaxaca",
            "name": "[OAX] - Oaxaca - Mexico",
            "searchName": "OAX-Oaxaca-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "SCX",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Salina Cruz",
            "name": "[SCX] - Salina Cruz - Mexico",
            "searchName": "SCX-Salina Cruz-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "SFH",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "San Felipe",
            "name": "[SFH] - San Felipe - Mexico",
            "searchName": "SFH-San Felipe-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "SGM",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "San Ignacio",
            "name": "[SGM] - San Ignacio - Mexico",
            "searchName": "SGM-San Ignacio-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "SJD",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "San Jose Del Cabo",
            "name": "[SJD] - San Jose Del Cabo - Mexico",
            "searchName": "SJD-San Jose Cabo-San Jose Del Cabo-Los Cabos-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "SNQ",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "San Quintin",
            "name": "[SNQ] - San Quintin - Mexico",
            "searchName": "SNQ-San Quintin-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "SLW",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Saltillo",
            "name": "[SLW] - Saltillo - Mexico",
            "searchName": "SLW-Saltillo Coahuila-Saltillo-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "SLP",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "San Luis Potosi",
            "name": "[SLP] - San Luis Potosi - Mexico",
            "searchName": "SLP-San Luis Potos-San Luis Potosi-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PUH",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Pochutla",
            "name": "[PUH] - Pochutla - Mexico",
            "searchName": "PUH-Pochutla-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PQM",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Palenque",
            "name": "[PQM] - Palenque - Mexico",
            "searchName": "PQM-Palenque-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PPE",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Puerto Penasco",
            "name": "[PPE] - Puerto Penasco - Mexico",
            "searchName": "PPE-Puerto Penasco-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PJZ",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Puerto Juarez",
            "name": "[PJZ] - Puerto Juarez - Mexico",
            "searchName": "PJZ-Puerto Juarez-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PNO",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Pinotepa Nacional",
            "name": "[PNO] - Pinotepa Nacional - Mexico",
            "searchName": "PNO-Pinotepa Nacional-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "REX",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Reynosa",
            "name": "[REX] - Reynosa - Mexico",
            "searchName": "REX-Reynosa-Reynossa-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "QRO",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Queretaro",
            "name": "[QRO] - Queretaro - Mexico",
            "searchName": "QRO-Queretaro-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PXM",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Puerto Escondido",
            "name": "[PXM] - Puerto Escondido - Mexico",
            "searchName": "PXM-Puerto Escondido-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PVR",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Puerto Vallarta",
            "name": "[PVR] - Puerto Vallarta - Mexico",
            "searchName": "PVR-Puerto Vallarta-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "VSA",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Villahermosa",
            "name": "[VSA] - Villahermosa - Mexico",
            "searchName": "VSA-Villahermosa-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "VIB",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Villa Constitucion",
            "name": "[VIB] - Villa Constitucion - Mexico",
            "searchName": "VIB-Villa Constitucion-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "VAM",
            "countryCode": "MV",
            "cityCode": null,
            "cityName": "Maamigili",
            "name": "[VAM] - Maamigili - Maldives",
            "searchName": "VAM-Maamigili-Maldives-Maldív-szigetek-Maldivler-Maledivy-Malediivit-Maldiverne-Maldive-Maldivi-Maldyvai-Maldivija-Maldiivid-Maldivene-Malediwy-Maladewa-Malediven-Maldivas",
            "priority": 0
        },
        {
            "iataCode": "HAQ",
            "countryCode": "MV",
            "cityCode": null,
            "cityName": "Hanimaadhoo",
            "name": "[HAQ] - Hanimaadhoo - Maldives",
            "searchName": "HAQ-Hanimaadhoo-Maldives-Maldív-szigetek-Maldivler-Maledivy-Malediivit-Maldiverne-Maldive-Maldivi-Maldyvai-Maldivija-Maldiivid-Maldivene-Malediwy-Maladewa-Malediven-Maldivas",
            "priority": 0
        },
        {
            "iataCode": "GAN",
            "countryCode": "MV",
            "cityCode": null,
            "cityName": "Gan Island",
            "name": "[GAN] - Gan Island - Maldives",
            "searchName": "GAN-Gan Island--Maldives-Maldív-szigetek-Maldivler-Maledivy-Malediivit-Maldiverne-Maldive-Maldivi-Maldyvai-Maldivija-Maldiivid-Maldivene-Malediwy-Maladewa-Malediven-Maldivas",
            "priority": 0
        },
        {
            "iataCode": "FVM",
            "countryCode": "MV",
            "cityCode": null,
            "cityName": "Fuvahmulak",
            "name": "[FVM] - Fuvahmulak - Maldives",
            "searchName": "FVM-Fuvahmulak-Maldives-Maldív-szigetek-Maldivler-Maledivy-Malediivit-Maldiverne-Maldive-Maldivi-Maldyvai-Maldivija-Maldiivid-Maldivene-Malediwy-Maladewa-Malediven-Maldivas",
            "priority": 0
        },
        {
            "iataCode": "KDM",
            "countryCode": "MV",
            "cityCode": null,
            "cityName": "Kaadedhdhoo",
            "name": "[KDM] - Kaadedhdhoo - Maldives",
            "searchName": "KDM-Kaadedhdhoo-Maldives-Maldív-szigetek-Maldivler-Maledivy-Malediivit-Maldiverne-Maldive-Maldivi-Maldyvai-Maldivija-Maldiivid-Maldivene-Malediwy-Maladewa-Malediven-Maldivas",
            "priority": 0
        },
        {
            "iataCode": "KDO",
            "countryCode": "MV",
            "cityCode": null,
            "cityName": "Kadhdhoo",
            "name": "[KDO] - Kadhdhoo - Maldives",
            "searchName": "KDO-Kadhdhoo-Kadhonoo-Maldives-Maldív-szigetek-Maldivler-Maledivy-Malediivit-Maldiverne-Maldive-Maldivi-Maldyvai-Maldivija-Maldiivid-Maldivene-Malediwy-Maladewa-Malediven-Maldivas",
            "priority": 0
        },
        {
            "iataCode": "DRV",
            "countryCode": "MV",
            "cityCode": null,
            "cityName": "Dharavandhoo Island",
            "name": "[DRV] - Dharavandhoo Island - Maldives",
            "searchName": "DRV-Dharavandhoo Island-Maldives-Maldív-szigetek-Maldivler-Maledivy-Malediivit-Maldiverne-Maldive-Maldivi-Maldyvai-Maldivija-Maldiivid-Maldivene-Malediwy-Maladewa-Malediven-Maldivas",
            "priority": 0
        },
        {
            "iataCode": "DWA",
            "countryCode": "MW",
            "cityCode": null,
            "cityName": "Dwangwa",
            "name": "[DWA] - Dwangwa - Malawi",
            "searchName": "DWA-Dwangwa-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "CEH",
            "countryCode": "MW",
            "cityCode": null,
            "cityName": "Chelinda",
            "name": "[CEH] - Chelinda - Malawi",
            "searchName": "CEH-Chelinda-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "CMK",
            "countryCode": "MW",
            "cityCode": null,
            "cityName": "Club Makokola",
            "name": "[CMK] - Club Makokola - Malawi",
            "searchName": "CMK-Club Makokola-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "BLZ",
            "countryCode": "MW",
            "cityCode": null,
            "cityName": "Blantyre",
            "name": "[BLZ] - Blantyre - Malawi",
            "searchName": "BLZ-Blantyre-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "KBQ",
            "countryCode": "MW",
            "cityCode": null,
            "cityName": "Kasungu",
            "name": "[KBQ] - Kasungu - Malawi",
            "searchName": "KBQ-Kasungu-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "KGJ",
            "countryCode": "MW",
            "cityCode": null,
            "cityName": "Karonga",
            "name": "[KGJ] - Karonga - Malawi",
            "searchName": "KGJ-Karonga-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "MAI",
            "countryCode": "MW",
            "cityCode": null,
            "cityName": "Mangochi",
            "name": "[MAI] - Mangochi - Malawi",
            "searchName": "MAI-Mangochi-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "LIX",
            "countryCode": "MW",
            "cityCode": null,
            "cityName": "Likoma Island",
            "name": "[LIX] - Likoma Island - Malawi",
            "searchName": "LIX-Likoma Island-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "LLW",
            "countryCode": "MW",
            "cityCode": null,
            "cityName": "Lilongwe",
            "name": "[LLW] - Lilongwe - Malawi",
            "searchName": "LLW-Lilongwe-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "LMB",
            "countryCode": "MW",
            "cityCode": null,
            "cityName": "Salima",
            "name": "[LMB] - Salima - Malawi",
            "searchName": "LMB-Salima-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "VUU",
            "countryCode": "MW",
            "cityCode": null,
            "cityName": "Mvuu Camp",
            "name": "[VUU] - Mvuu Camp - Malawi",
            "searchName": "VUU-Mvuu Camp-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "MYZ",
            "countryCode": "MW",
            "cityCode": null,
            "cityName": "Monkey Bay",
            "name": "[MYZ] - Monkey Bay - Malawi",
            "searchName": "MYZ-Monkey Bay-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "ZZU",
            "countryCode": "MW",
            "cityCode": null,
            "cityName": "Mzuzu",
            "name": "[ZZU] - Mzuzu - Malawi",
            "searchName": "ZZU-Mzuzu-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "MZT",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Mazatlan",
            "name": "[MZT] - Mazatlan - Mexico",
            "searchName": "MZT-Mazatl-Mazatlan-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "MXL",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Mexicali",
            "name": "[MXL] - Mexicali - Mexico",
            "searchName": "MXL-Mexicali-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "NCG",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Paloma Grande",
            "name": "[NCG] - Paloma Grande - Mexico",
            "searchName": "NCG-Nuevas Casas Grandes-Paloma Grande-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "NLD",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Nuevo Laredo",
            "name": "[NLD] - Nuevo Laredo - Mexico",
            "searchName": "NLD-Nuevo Laredo-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "MLM",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Morelia",
            "name": "[MLM] - Morelia - Mexico",
            "searchName": "MLM-Morelia Michoacan-Morelia-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "MMC",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Mante",
            "name": "[MMC] - Mante - Mexico",
            "searchName": "MMC-Ciudad Mante-Mante-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "MTT",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Minatitlan",
            "name": "[MTT] - Minatitlan - Mexico",
            "searchName": "MTT-Minatitlan-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "MUG",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Mulege",
            "name": "[MUG] - Mulege - Mexico",
            "searchName": "MUG-Mulege-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "KCH",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Kuching",
            "name": "[KCH] - Kuching - Malaysia",
            "searchName": "KCH-Kuching-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "MEP",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Mersing",
            "name": "[MEP] - Mersing - Malaysia",
            "searchName": "MEP-Mersing-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LWY",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Lawas",
            "name": "[LWY] - Lawas - Malaysia",
            "searchName": "LWY-Lawas-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LSM",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Long Semado",
            "name": "[LSM] - Long Semado - Malaysia",
            "searchName": "LSM-Long Semado-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LSU",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Long Sukang",
            "name": "[LSU] - Long Sukang - Malaysia",
            "searchName": "LSU-Long Sukang-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LMN",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Limbang",
            "name": "[LMN] - Limbang - Malaysia",
            "searchName": "LMN-Limbang-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LLM",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Long Lama",
            "name": "[LLM] - Long Lama - Malaysia",
            "searchName": "LLM-Long Lama-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LKH",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Long Akah",
            "name": "[LKH] - Long Akah - Malaysia",
            "searchName": "LKH-Long Akah-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LGK",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Langkawi",
            "name": "[LGK] - Langkawi - Malaysia",
            "searchName": "LGK-Langkawi-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LGL",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Long Lellang",
            "name": "[LGL] - Long Lellang - Malaysia",
            "searchName": "LGL-Long Lellang-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "ZJB",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Kuantan",
            "name": "[ZJB] - Kuantan - Malaysia",
            "searchName": "ZJB-Kuantan-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "ZJR",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Penang",
            "name": "[ZJR] - Penang - Malaysia",
            "searchName": "ZJR-Penang-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "ZJT",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Tanjung Pelepas",
            "name": "[ZJT] - Tanjung Pelepas - Malaysia",
            "searchName": "ZJT-Tanjung Pelepas-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "ZGY",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Kuching",
            "name": "[ZGY] - Kuching - Malaysia",
            "searchName": "ZGY-Kuching-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "ZLW",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Pasir Gudang",
            "name": "[ZLW] - Pasir Gudang - Malaysia",
            "searchName": "ZLW-Pasir Gudang-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "ZMK",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Bintulu",
            "name": "[ZMK] - Bintulu - Malaysia",
            "searchName": "ZMK-Bintulu-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "ZWR",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Kota Kinabalu",
            "name": "[ZWR] - Kota Kinabalu - Malaysia",
            "searchName": "ZWR-Kota Kinabalu-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "XPQ",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Port Klang",
            "name": "[XPQ] - Port Klang - Malaysia",
            "searchName": "XPQ-Port Klang-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "SWY",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Sitiawan",
            "name": "[SWY] - Sitiawan - Malaysia",
            "searchName": "SWY-Sitiawan-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "SXS",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Sahabat 16",
            "name": "[SXS] - Sahabat 16 - Malaysia",
            "searchName": "SXS-Sahabat 16-Sahabat-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "SXT",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Taman Negara",
            "name": "[SXT] - Taman Negara - Malaysia",
            "searchName": "SXT-Taman Negara-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "TGC",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Tanjung Manis",
            "name": "[TGC] - Tanjung Manis - Malaysia",
            "searchName": "TGC-Tanjung Manis-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "TGG",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Kuala Terengganu",
            "name": "[TGG] - Kuala Terengganu - Malaysia",
            "searchName": "TGG-Kuala Terengganu-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "TEL",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Telupid",
            "name": "[TEL] - Telupid - Malaysia",
            "searchName": "TEL-Telupid-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "TPG",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Taiping",
            "name": "[TPG] - Taiping - Malaysia",
            "searchName": "TPG-Taiping-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "TOD",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Tioman",
            "name": "[TOD] - Tioman - Malaysia",
            "searchName": "TOD-Tioman-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "TMG",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Tomanggong",
            "name": "[TMG] - Tomanggong - Malaysia",
            "searchName": "TMG-Tomanggong-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "TWU",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Tawau",
            "name": "[TWU] - Tawau - Malaysia",
            "searchName": "TWU-Tawau-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "RDN",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Redang",
            "name": "[RDN] - Redang - Malaysia",
            "searchName": "RDN-Redang-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "PKG",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Pangkor",
            "name": "[PKG] - Pangkor - Malaysia",
            "searchName": "PKG-Pangkor-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "SMM",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Semporna",
            "name": "[SMM] - Semporna - Malaysia",
            "searchName": "SMM-Semporna-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "SPE",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Sepulot",
            "name": "[SPE] - Sepulot - Malaysia",
            "searchName": "SPE-Sepulot-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "SPT",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Sipitang",
            "name": "[SPT] - Sipitang - Malaysia",
            "searchName": "SPT-Sipitang-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "SGG",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Simanggang",
            "name": "[SGG] - Simanggang - Malaysia",
            "searchName": "SGG-Simanggang-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "SDK",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Sandakan",
            "name": "[SDK] - Sandakan - Malaysia",
            "searchName": "SDK-Sandakan-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "SBW",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Sibu",
            "name": "[SBW] - Sibu - Malaysia",
            "searchName": "SBW-Sibu-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "RNU",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Ranau",
            "name": "[RNU] - Ranau - Malaysia",
            "searchName": "RNU-Ranau-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "ODN",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Long Seridan",
            "name": "[ODN] - Long Seridan - Malaysia",
            "searchName": "ODN-Long Seridan-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "PEN",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Penang",
            "name": "[PEN] - Penang - Malaysia",
            "searchName": "PEN-Penang-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "PAY",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Pamol",
            "name": "[PAY] - Pamol - Malaysia",
            "searchName": "PAY-Pamol-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "MUR",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Marudi",
            "name": "[MUR] - Marudi - Malaysia",
            "searchName": "MUR-Marudi-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "MKZ",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Malacca",
            "name": "[MKZ] - Malacca - Malaysia",
            "searchName": "MKZ-Malacca-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "MKM",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Mukah",
            "name": "[MKM] - Mukah - Malaysia",
            "searchName": "MKM-Mukah-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "MZV",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Mulu",
            "name": "[MZV] - Mulu - Malaysia",
            "searchName": "MZV-Mulu-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "MYY",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Miri",
            "name": "[MYY] - Miri - Malaysia",
            "searchName": "MYY-Miri-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "MZS",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Mostyn",
            "name": "[MZS] - Mostyn - Malaysia",
            "searchName": "MZS-Mostyn-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "MZB",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Mocimboa Praia",
            "name": "[MZB] - Mocimboa Praia - Mozambique",
            "searchName": "MZB-Mocimboa da Praia-Mocimboa Praia-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "MJS",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Maganja Da Costa",
            "name": "[MJS] - Maganja Da Costa - Mozambique",
            "searchName": "MJS-Maganja Da Costa-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "MNC",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Nacala",
            "name": "[MNC] - Nacala - Mozambique",
            "searchName": "MNC-Nacala-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "MMW",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Moma",
            "name": "[MMW] - Moma - Mozambique",
            "searchName": "MMW-Moma-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "MPM",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Maputo",
            "name": "[MPM] - Maputo - Mozambique",
            "searchName": "MPM-Maputo-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "MUD",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Mueda",
            "name": "[MUD] - Mueda - Mozambique",
            "searchName": "MUD-Mueda-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "MTU",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Montepuez",
            "name": "[MTU] - Montepuez - Mozambique",
            "searchName": "MTU-Montepuez-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "PDD",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Ponta de Ouro",
            "name": "[PDD] - Ponta de Ouro - Mozambique",
            "searchName": "PDD-Ponta de Ouro-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "PEB",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Pebane",
            "name": "[PEB] - Pebane - Mozambique",
            "searchName": "PEB-Pebane-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "NND",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Nangade",
            "name": "[NND] - Nangade - Mozambique",
            "searchName": "NND-Nangade-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "NTC",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Santa Carolina",
            "name": "[NTC] - Santa Carolina - Mozambique",
            "searchName": "NTC-Santa Carolina-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "RRM",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Marromeu",
            "name": "[RRM] - Marromeu - Mozambique",
            "searchName": "RRM-Marromeu-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "POL",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Pemba",
            "name": "[POL] - Pemba - Mozambique",
            "searchName": "POL-Pemba-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "UEL",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Quelimane",
            "name": "[UEL] - Quelimane - Mozambique",
            "searchName": "UEL-Quelimane-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "VJB",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Xai Xai",
            "name": "[VJB] - Xai Xai - Mozambique",
            "searchName": "VJB-Xai Xai-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "VJQ",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Gurue",
            "name": "[VJQ] - Gurue - Mozambique",
            "searchName": "VJQ-Gurue-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "VXC",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Lichinga",
            "name": "[VXC] - Lichinga - Mozambique",
            "searchName": "VXC-Lichinga-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "VNX",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Vilanculos",
            "name": "[VNX] - Vilanculos - Mozambique",
            "searchName": "VNX-Vilanculos-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "VPY",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Chimoio",
            "name": "[VPY] - Chimoio - Mozambique",
            "searchName": "VPY-Chimoio-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "TET",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Tete",
            "name": "[TET] - Tete - Mozambique",
            "searchName": "TET-Tete-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "TGS",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Chokwe",
            "name": "[TGS] - Chokwe - Mozambique",
            "searchName": "TGS-Chokwe-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "LFB",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Lumbo",
            "name": "[LFB] - Lumbo - Mozambique",
            "searchName": "LFB-Lumbo-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "LMZ",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Palma",
            "name": "[LMZ] - Palma - Mozambique",
            "searchName": "LMZ-Palma-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "MFW",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Magaruque",
            "name": "[MFW] - Magaruque - Mozambique",
            "searchName": "MFW-Magaruque-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "LBM",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Luabo",
            "name": "[LBM] - Luabo - Mozambique",
            "searchName": "LBM-Luabo-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "FXO",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Cuamba",
            "name": "[FXO] - Cuamba - Mozambique",
            "searchName": "FXO-Cuamba-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "IMG",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Inhaminga",
            "name": "[IMG] - Inhaminga - Mozambique",
            "searchName": "IMG-Inhaminga-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "INH",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Inhambane",
            "name": "[INH] - Inhambane - Mozambique",
            "searchName": "INH-Inhambane-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "INE",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Chinde",
            "name": "[INE] - Chinde - Mozambique",
            "searchName": "INE-Chinde-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "IHC",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Inhaca",
            "name": "[IHC] - Inhaca - Mozambique",
            "searchName": "IHC-Inhaca-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "IDC",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Ila Da Chilonzuene",
            "name": "[IDC] - Ila Da Chilonzuene - Mozambique",
            "searchName": "IDC-Ila Da Chilonzuene-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "IBL",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Indigo Bay Lodge",
            "name": "[IBL] - Indigo Bay Lodge - Mozambique",
            "searchName": "IBL-Indigo Bay Lodge-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "IBO",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Ibo",
            "name": "[IBO] - Ibo - Mozambique",
            "searchName": "IBO-Ibo-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "BZB",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Bazaruto Island",
            "name": "[BZB] - Bazaruto Island - Mozambique",
            "searchName": "BZB-Bazaruto Island-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "BJN",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Bajone",
            "name": "[BJN] - Bajone - Mozambique",
            "searchName": "BJN-Bajone-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "BEW",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Beira",
            "name": "[BEW] - Beira - Mozambique",
            "searchName": "BEW-Beira-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "APL",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Nampula",
            "name": "[APL] - Nampula - Mozambique",
            "searchName": "APL-Nampula-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "BCW",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Benguera Island",
            "name": "[BCW] - Benguera Island - Mozambique",
            "searchName": "BCW-Benguera Island-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "ANO",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Angoche",
            "name": "[ANO] - Angoche - Mozambique",
            "searchName": "ANO-Angoche-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "AME",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Alto Molocue",
            "name": "[AME] - Alto Molocue - Mozambique",
            "searchName": "AME-Alto Molocue-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "DGK",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Dugong",
            "name": "[DGK] - Dugong - Mozambique",
            "searchName": "DGK-Dugong-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "CMZ",
            "countryCode": "MZ",
            "cityCode": null,
            "cityName": "Caia",
            "name": "[CMZ] - Caia - Mozambique",
            "searchName": "CMZ-Caia-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "AIW",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Ai-ais",
            "name": "[AIW] - Ai-ais - Namibia",
            "searchName": "AIW-Ai Ais-Ai-ais-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "ADI",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Arandis",
            "name": "[ADI] - Arandis - Namibia",
            "searchName": "ADI-Arandis-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "BQI",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Bagani",
            "name": "[BQI] - Bagani - Namibia",
            "searchName": "BQI-Bagani-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "KAS",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Karasburg",
            "name": "[KAS] - Karasburg - Namibia",
            "searchName": "KAS-Karasburg-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "GOG",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Gobabis",
            "name": "[GOG] - Gobabis - Namibia",
            "searchName": "GOG-Gobabis-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "GFY",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Grootfontein",
            "name": "[GFY] - Grootfontein - Namibia",
            "searchName": "GFY-Grootfontein-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "HAL",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Halali",
            "name": "[HAL] - Halali - Namibia",
            "searchName": "HAL-Halali-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "KMP",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Keetmanshoop",
            "name": "[KMP] - Keetmanshoop - Namibia",
            "searchName": "KMP-Keetmanshoop-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "MJO",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Mount Etjo Lodge",
            "name": "[MJO] - Mount Etjo Lodge - Namibia",
            "searchName": "MJO-Mount Etjo Lodge-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "LHU",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Caprivi",
            "name": "[LHU] - Caprivi - Namibia",
            "searchName": "LHU-Caprivi-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "LUD",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Luderitz",
            "name": "[LUD] - Luderitz - Namibia",
            "searchName": "LUD-L-Luderitz-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "TCY",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Terrace Bay",
            "name": "[TCY] - Terrace Bay - Namibia",
            "searchName": "TCY-Terrace Bay-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "SZM",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Sesriem",
            "name": "[SZM] - Sesriem - Namibia",
            "searchName": "SZM-Sesriem-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "SWP",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Swakopmund",
            "name": "[SWP] - Swakopmund - Namibia",
            "searchName": "SWP-Swakopmund-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "WDH",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Windhoek",
            "name": "[WDH] - Windhoek - Namibia",
            "searchName": "WDH-Windhuk-Windhoek-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "TSB",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Tsumeb",
            "name": "[TSB] - Tsumeb - Namibia",
            "searchName": "TSB-Tsumeb-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "WVB",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Walvis Bay",
            "name": "[WVB] - Walvis Bay - Namibia",
            "searchName": "WVB-Walfischbucht-Walvis Bay-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "RHN",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Rosh Pina",
            "name": "[RHN] - Rosh Pina - Namibia",
            "searchName": "RHN-Rosh Pina-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "OGV",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Ongava Game Reserve",
            "name": "[OGV] - Ongava Game Reserve - Namibia",
            "searchName": "OGV-Ongava Game Reserve-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "OKF",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Okaukuejo",
            "name": "[OKF] - Okaukuejo - Namibia",
            "searchName": "OKF-Okaukuejo-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "OKU",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Mokuti Lodge",
            "name": "[OKU] - Mokuti Lodge - Namibia",
            "searchName": "OKU-Mokuti Lodge-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "OHI",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Oshakati",
            "name": "[OHI] - Oshakati - Namibia",
            "searchName": "OHI-Oshakati-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "OMG",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Omega",
            "name": "[OMG] - Omega - Namibia",
            "searchName": "OMG-Omega-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "OMD",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Oranjemund",
            "name": "[OMD] - Oranjemund - Namibia",
            "searchName": "OMD-Oranjemund-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "OTJ",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Otjiwarongo",
            "name": "[OTJ] - Otjiwarongo - Namibia",
            "searchName": "OTJ-Otjiwarongo-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "OPW",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Opuwa",
            "name": "[OPW] - Opuwa - Namibia",
            "searchName": "OPW-Opuwa-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "OND",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Ondangwa",
            "name": "[OND] - Ondangwa - Namibia",
            "searchName": "OND-Ondangwa-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "MQG",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Midgard",
            "name": "[MQG] - Midgard - Namibia",
            "searchName": "MQG-Midgard-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "MPA",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Mpacha",
            "name": "[MPA] - Mpacha - Namibia",
            "searchName": "MPA-Mpacha-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "NNI",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Namutoni",
            "name": "[NNI] - Namutoni - Namibia",
            "searchName": "NNI-Namutoni-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "NDU",
            "countryCode": "NA",
            "cityCode": null,
            "cityName": "Rundu",
            "name": "[NDU] - Rundu - Namibia",
            "searchName": "NDU-Rundu-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "PDC",
            "countryCode": "NC",
            "cityCode": null,
            "cityName": "Mueo",
            "name": "[PDC] - Mueo - New Caledonia",
            "searchName": "PDC-Mueo-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "NOU",
            "countryCode": "NC",
            "cityCode": null,
            "cityName": "Noumea",
            "name": "[NOU] - Noumea - New Caledonia",
            "searchName": "NOU-Noum-Noumea-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "PUV",
            "countryCode": "NC",
            "cityCode": null,
            "cityName": "Poum",
            "name": "[PUV] - Poum - New Caledonia",
            "searchName": "PUV-Poum-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "UVE",
            "countryCode": "NC",
            "cityCode": null,
            "cityName": "Ouvea",
            "name": "[UVE] - Ouvea - New Caledonia",
            "searchName": "UVE-Ouvea-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "TGJ",
            "countryCode": "NC",
            "cityCode": null,
            "cityName": "Tiga",
            "name": "[TGJ] - Tiga - New Caledonia",
            "searchName": "TGJ-Tiga-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "TOU",
            "countryCode": "NC",
            "cityCode": null,
            "cityName": "Touho",
            "name": "[TOU] - Touho - New Caledonia",
            "searchName": "TOU-Touho-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "LIF",
            "countryCode": "NC",
            "cityCode": null,
            "cityName": "Lifou",
            "name": "[LIF] - Lifou - New Caledonia",
            "searchName": "LIF-Lifou-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "MEE",
            "countryCode": "NC",
            "cityCode": null,
            "cityName": "Mare",
            "name": "[MEE] - Mare - New Caledonia",
            "searchName": "MEE-Mare-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "KNQ",
            "countryCode": "NC",
            "cityCode": null,
            "cityName": "Kone",
            "name": "[KNQ] - Kone - New Caledonia",
            "searchName": "KNQ-Kone-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "KOC",
            "countryCode": "NC",
            "cityCode": null,
            "cityName": "Koumac",
            "name": "[KOC] - Koumac - New Caledonia",
            "searchName": "KOC-Koumac-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "HNG",
            "countryCode": "NC",
            "cityCode": null,
            "cityName": "Hienghene",
            "name": "[HNG] - Hienghene - New Caledonia",
            "searchName": "HNG-Hienghene-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "HLU",
            "countryCode": "NC",
            "cityCode": null,
            "cityName": "Houailou",
            "name": "[HLU] - Houailou - New Caledonia",
            "searchName": "HLU-Houailou-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "ILP",
            "countryCode": "NC",
            "cityCode": null,
            "cityName": "Ile Des Pins",
            "name": "[ILP] - Ile Des Pins - New Caledonia",
            "searchName": "ILP-Ile Des Pins-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "IOU",
            "countryCode": "NC",
            "cityCode": null,
            "cityName": "Ile Ouen",
            "name": "[IOU] - Ile Ouen - New Caledonia",
            "searchName": "IOU-Ile Ouen-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "BMY",
            "countryCode": "NC",
            "cityCode": null,
            "cityName": "Belep Island",
            "name": "[BMY] - Belep Island - New Caledonia",
            "searchName": "BMY-Belep Inseln-Belep Island-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "AJY",
            "countryCode": "NE",
            "cityCode": null,
            "cityName": "Agades",
            "name": "[AJY] - Agades - Niger",
            "searchName": "AJY-Agades-Niger-Níger",
            "priority": 0
        },
        {
            "iataCode": "MFQ",
            "countryCode": "NE",
            "cityCode": null,
            "cityName": "Maradi",
            "name": "[MFQ] - Maradi - Niger",
            "searchName": "MFQ-Maradi-Niger-Níger",
            "priority": 0
        },
        {
            "iataCode": "THZ",
            "countryCode": "NE",
            "cityCode": null,
            "cityName": "Tahoua",
            "name": "[THZ] - Tahoua - Niger",
            "searchName": "THZ-Tahoua-Niger-Níger",
            "priority": 0
        },
        {
            "iataCode": "ZND",
            "countryCode": "NE",
            "cityCode": null,
            "cityName": "Zinder",
            "name": "[ZND] - Zinder - Niger",
            "searchName": "ZND-Zinder-Niger-Níger",
            "priority": 0
        },
        {
            "iataCode": "RLT",
            "countryCode": "NE",
            "cityCode": null,
            "cityName": "Arlit",
            "name": "[RLT] - Arlit - Niger",
            "searchName": "RLT-Arlit-Niger-Níger",
            "priority": 0
        },
        {
            "iataCode": "NIM",
            "countryCode": "NE",
            "cityCode": null,
            "cityName": "Niamey",
            "name": "[NIM] - Niamey - Niger",
            "searchName": "NIM-Niamey-Niger-Níger",
            "priority": 0
        },
        {
            "iataCode": "NLK",
            "countryCode": "NF",
            "cityCode": null,
            "cityName": "Norfolk Island",
            "name": "[NLK] - Norfolk Island - Norfolk Island",
            "searchName": "NLK-Norfolk Inseln-Norfolk Island--Norfolkeiland-Pulau Norfolk-Norfolkön-Kepulauan Norfolk-Wyspa Norfolk-Ilha de Norfolk-Isola Norfolk-Norfolk (isla)-Norfolk-Inseln-Insel-Norfolk Adasi-Isla Norfolk-Île Norfolk",
            "priority": 0
        },
        {
            "iataCode": "MXJ",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Minna",
            "name": "[MXJ] - Minna - Nigeria",
            "searchName": "MXJ-Minna-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "SKO",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Sokoto",
            "name": "[SKO] - Sokoto - Nigeria",
            "searchName": "SKO-Sokoto-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "PHC",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Port Harcourt",
            "name": "[PHC] - Port Harcourt - Nigeria",
            "searchName": "PHC-Port Harcourt-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "QOW",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Owerri",
            "name": "[QOW] - Owerri - Nigeria",
            "searchName": "QOW-Owerri-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "QRW",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Warri",
            "name": "[QRW] - Warri - Nigeria",
            "searchName": "QRW-Warri-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "QUO",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Uyo",
            "name": "[QUO] - Uyo - Nigeria",
            "searchName": "QUO-Uyo/Uyo-Uyo-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "ZAR",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Zaria",
            "name": "[ZAR] - Zaria - Nigeria",
            "searchName": "ZAR-Zaria-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "YOL",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Yola",
            "name": "[YOL] - Yola - Nigeria",
            "searchName": "YOL-Yola-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "MIU",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Maiduguri",
            "name": "[MIU] - Maiduguri - Nigeria",
            "searchName": "MIU-Maiduguri-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "MDI",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Makurdi",
            "name": "[MDI] - Makurdi - Nigeria",
            "searchName": "MDI-Makurdi-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "LOS",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Lagos",
            "name": "[LOS] - Lagos - Nigeria",
            "searchName": "LOS-Lagos-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "ILR",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Ilorin",
            "name": "[ILR] - Ilorin - Nigeria",
            "searchName": "ILR-Ilorin-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "IBA",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Ibadan",
            "name": "[IBA] - Ibadan - Nigeria",
            "searchName": "IBA-Ibadan-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "KAN",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Kano",
            "name": "[KAN] - Kano - Nigeria",
            "searchName": "KAN-Kano-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "KAD",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Kaduna",
            "name": "[KAD] - Kaduna - Nigeria",
            "searchName": "KAD-Kaduna-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "JOS",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Jos",
            "name": "[JOS] - Jos - Nigeria",
            "searchName": "JOS-Jos-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "GMO",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Gombe",
            "name": "[GMO] - Gombe - Nigeria",
            "searchName": "GMO-Gombe-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "AKR",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Akure",
            "name": "[AKR] - Akure - Nigeria",
            "searchName": "AKR-Akure-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "ABV",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Abuja",
            "name": "[ABV] - Abuja - Nigeria",
            "searchName": "ABV-Abuja-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "ABB",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Asaba",
            "name": "[ABB] - Asaba - Nigeria",
            "searchName": "ABB-Asaba-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "BCU",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Bauchi",
            "name": "[BCU] - Bauchi - Nigeria",
            "searchName": "BCU-Bauchi-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "BNI",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Benin City",
            "name": "[BNI] - Benin City - Nigeria",
            "searchName": "BNI-Benin Stadt-Benin City-Benin Ville-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "CBQ",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Calabar",
            "name": "[CBQ] - Calabar - Nigeria",
            "searchName": "CBQ-Calabar-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "ENU",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Enugu",
            "name": "[ENU] - Enugu - Nigeria",
            "searchName": "ENU-Enugu-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "DKA",
            "countryCode": "NG",
            "cityCode": null,
            "cityName": "Katsina",
            "name": "[DKA] - Katsina - Nigeria",
            "searchName": "DKA-Katsina-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "BZA",
            "countryCode": "NI",
            "cityCode": null,
            "cityName": "Bonanza",
            "name": "[BZA] - Bonanza - Nicaragua",
            "searchName": "BZA-Bonanza-Nicaragua-Nikaragua-Nikaragva",
            "priority": 0
        },
        {
            "iataCode": "BEF",
            "countryCode": "NI",
            "cityCode": null,
            "cityName": "Bluefields",
            "name": "[BEF] - Bluefields - Nicaragua",
            "searchName": "BEF-Bluefields-Nicaragua-Nikaragua-Nikaragva",
            "priority": 0
        },
        {
            "iataCode": "AXR",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Arutua",
            "name": "[AXR] - Arutua - French Polynesia",
            "searchName": "AXR-Arutua-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "AUQ",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Atuona",
            "name": "[AUQ] - Atuona - French Polynesia",
            "searchName": "AUQ-Atuona-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "APK",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Apataki",
            "name": "[APK] - Apataki - French Polynesia",
            "searchName": "APK-Apataki-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "AHE",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Ahe",
            "name": "[AHE] - Ahe - French Polynesia",
            "searchName": "AHE-Ahe-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "AAA",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Anaa",
            "name": "[AAA] - Anaa - French Polynesia",
            "searchName": "AAA-Anaa-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "ZTA",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Tureira",
            "name": "[ZTA] - Tureira - French Polynesia",
            "searchName": "ZTA-Tureira-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "XMH",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Manihi",
            "name": "[XMH] - Manihi - French Polynesia",
            "searchName": "XMH-Manihi-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "TPX",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Tupai",
            "name": "[TPX] - Tupai - French Polynesia",
            "searchName": "TPX-Tupai-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "TKV",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Tatakoto",
            "name": "[TKV] - Tatakoto - French Polynesia",
            "searchName": "TKV-Tatakoto-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "TKX",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Takaroa",
            "name": "[TKX] - Takaroa - French Polynesia",
            "searchName": "TKX-Takaroa-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "TKP",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Takapoto",
            "name": "[TKP] - Takapoto - French Polynesia",
            "searchName": "TKP-Takapoto-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "TJN",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Takume",
            "name": "[TJN] - Takume - French Polynesia",
            "searchName": "TJN-Takume-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "TIH",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Tikehau Atoll",
            "name": "[TIH] - Tikehau Atoll - French Polynesia",
            "searchName": "TIH-Tikehau Atoll-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "TUB",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Tubuai",
            "name": "[TUB] - Tubuai - French Polynesia",
            "searchName": "TUB-Tubuai-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "TTI",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Tetiaroa Island",
            "name": "[TTI] - Tetiaroa Island - French Polynesia",
            "searchName": "TTI-Tetiaroa Island-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "UAH",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Ua Huka",
            "name": "[UAH] - Ua Huka - French Polynesia",
            "searchName": "UAH-Ua Huka-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "UAP",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Ua Pou",
            "name": "[UAP] - Ua Pou - French Polynesia",
            "searchName": "UAP-Ua Pou-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "VHZ",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Vahitahi",
            "name": "[VHZ] - Vahitahi - French Polynesia",
            "searchName": "VHZ-Vahitahi-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "RUR",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Rurutu",
            "name": "[RUR] - Rurutu - French Polynesia",
            "searchName": "RUR-Rurutu-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "RVV",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Rairua",
            "name": "[RVV] - Rairua - French Polynesia",
            "searchName": "RVV-Rairua-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "RRR",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Raroia",
            "name": "[RRR] - Raroia - French Polynesia",
            "searchName": "RRR-Raroia-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "RKA",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Aratika",
            "name": "[RKA] - Aratika - French Polynesia",
            "searchName": "RKA-Aratika-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "RMT",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Rimatara",
            "name": "[RMT] - Rimatara - French Polynesia",
            "searchName": "RMT-Rimatara-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "RFP",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Raiatea",
            "name": "[RFP] - Raiatea - French Polynesia",
            "searchName": "RFP-Raiatea-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "RGI",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Rangiroa",
            "name": "[RGI] - Rangiroa - French Polynesia",
            "searchName": "RGI-Rangiroa-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "REA",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Reao",
            "name": "[REA] - Reao - French Polynesia",
            "searchName": "REA-Reao-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "PKP",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Puka Puka",
            "name": "[PKP] - Puka Puka - French Polynesia",
            "searchName": "PKP-Puka Puka-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "PUK",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Pukarua",
            "name": "[PUK] - Pukarua - French Polynesia",
            "searchName": "PUK-Pukarua-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "PPT",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Papeete",
            "name": "[PPT] - Papeete - French Polynesia",
            "searchName": "PPT-Papeete-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "NUK",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Nukutavake",
            "name": "[NUK] - Nukutavake - French Polynesia",
            "searchName": "NUK-Nukutavake-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "NHV",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Nuku Hiva",
            "name": "[NHV] - Nuku Hiva - French Polynesia",
            "searchName": "NHV-Nuku Hiva-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "NIU",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Niau",
            "name": "[NIU] - Niau - French Polynesia",
            "searchName": "NIU-Niau-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "NAU",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Napuka Island",
            "name": "[NAU] - Napuka Island - French Polynesia",
            "searchName": "NAU-Napuka Island-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "MVT",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Mataiva",
            "name": "[MVT] - Mataiva - French Polynesia",
            "searchName": "MVT-Mataiva-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "MOZ",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Moorea",
            "name": "[MOZ] - Moorea - French Polynesia",
            "searchName": "MOZ-Moorea-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "MKP",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Makemo",
            "name": "[MKP] - Makemo - French Polynesia",
            "searchName": "MKP-Makemo-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "MKN",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Malekolon",
            "name": "[MKN] - Malekolon - Papua New Guinea",
            "searchName": "MKN-Malekolon-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MLQ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Malalaua",
            "name": "[MLQ] - Malalaua - Papua New Guinea",
            "searchName": "MLQ-Malalaua-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MPF",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Mapoda",
            "name": "[MPF] - Mapoda - Papua New Guinea",
            "searchName": "MPF-Mapoda-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MPG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Makini",
            "name": "[MPG] - Makini - Papua New Guinea",
            "searchName": "MPG-Makini-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MMV",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Mal",
            "name": "[MMV] - Mal - Papua New Guinea",
            "searchName": "MMV-Mal-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MNP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Maron",
            "name": "[MNP] - Maron - Papua New Guinea",
            "searchName": "MNP-Maron-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MPU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Mapua",
            "name": "[MPU] - Mapua - Papua New Guinea",
            "searchName": "MPU-Mapua-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MPX",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Miyanmin",
            "name": "[MPX] - Miyanmin - Papua New Guinea",
            "searchName": "MPX-Miyanmin-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MQO",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Malam",
            "name": "[MQO] - Malam - Papua New Guinea",
            "searchName": "MQO-Malam-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MRH",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "May River",
            "name": "[MRH] - May River - Papua New Guinea",
            "searchName": "MRH-May River-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MRM",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Manare",
            "name": "[MRM] - Manare - Papua New Guinea",
            "searchName": "MRM-Manare-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MVI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Manetai",
            "name": "[MVI] - Manetai - Papua New Guinea",
            "searchName": "MVI-Manetai-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MWI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Maramuni",
            "name": "[MWI] - Maramuni - Papua New Guinea",
            "searchName": "MWI-Maramuni-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MWG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Marawaka",
            "name": "[MWG] - Marawaka - Papua New Guinea",
            "searchName": "MWG-Marawaka-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MXK",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Mindik",
            "name": "[MXK] - Mindik - Papua New Guinea",
            "searchName": "MXK-Mindik-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MXH",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Moro",
            "name": "[MXH] - Moro - Papua New Guinea",
            "searchName": "MXH-Moro-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MWU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Mussau",
            "name": "[MWU] - Mussau - Papua New Guinea",
            "searchName": "MWU-Mussau-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NBA",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Nambaiyufa",
            "name": "[NBA] - Nambaiyufa - Papua New Guinea",
            "searchName": "NBA-Nambaiyufa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MZN",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Minj",
            "name": "[MZN] - Minj - Papua New Guinea",
            "searchName": "MZN-Minj-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MYX",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Menyamya",
            "name": "[MYX] - Menyamya - Papua New Guinea",
            "searchName": "MYX-Menyamya-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NIS",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Simberi Island",
            "name": "[NIS] - Simberi Island - Papua New Guinea",
            "searchName": "NIS-Simberi Island-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NKN",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Nankina",
            "name": "[NKN] - Nankina - Papua New Guinea",
            "searchName": "NKN-Nankina-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NMN",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Nomane",
            "name": "[NMN] - Nomane - Papua New Guinea",
            "searchName": "NMN-Nomane-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NGR",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Ningerum",
            "name": "[NGR] - Ningerum - Papua New Guinea",
            "searchName": "NGR-Ningerum-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NDN",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Nadunumu",
            "name": "[NDN] - Nadunumu - Papua New Guinea",
            "searchName": "NDN-Nadunumu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NDI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Namudi",
            "name": "[NDI] - Namudi - Papua New Guinea",
            "searchName": "NDI-Namudi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NUT",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Nutuve",
            "name": "[NUT] - Nutuve - Papua New Guinea",
            "searchName": "NUT-Nutuve-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NUG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Nuguria",
            "name": "[NUG] - Nuguria - Papua New Guinea",
            "searchName": "NUG-Nuguria-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NWT",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Nowata",
            "name": "[NWT] - Nowata - Papua New Guinea",
            "searchName": "NWT-Nowata-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NPG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Nipa",
            "name": "[NPG] - Nipa - Papua New Guinea",
            "searchName": "NPG-Nipa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NOM",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Nomad River",
            "name": "[NOM] - Nomad River - Papua New Guinea",
            "searchName": "NOM-Nomad River-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NOO",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Naoro",
            "name": "[NOO] - Naoro - Papua New Guinea",
            "searchName": "NOO-Naoro-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OGE",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Ogeranang",
            "name": "[OGE] - Ogeranang - Papua New Guinea",
            "searchName": "OGE-Ogeranang-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OBM",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Morobe",
            "name": "[OBM] - Morobe - Papua New Guinea",
            "searchName": "OBM-Morobe-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OBX",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Obo",
            "name": "[OBX] - Obo - Papua New Guinea",
            "searchName": "OBX-Obo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OKV",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Okao",
            "name": "[OKV] - Okao - Papua New Guinea",
            "searchName": "OKV-Okao-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OKP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Oksapmin",
            "name": "[OKP] - Oksapmin - Papua New Guinea",
            "searchName": "OKP-Oksapmin-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OML",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Omkalai",
            "name": "[OML] - Omkalai - Papua New Guinea",
            "searchName": "OML-Omkalai-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OLQ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Olsobip",
            "name": "[OLQ] - Olsobip - Papua New Guinea",
            "searchName": "OLQ-Olsobip-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PDI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Pindiu",
            "name": "[PDI] - Pindiu - Papua New Guinea",
            "searchName": "PDI-Pindiu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PGE",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Yegepa",
            "name": "[PGE] - Yegepa - Papua New Guinea",
            "searchName": "PGE-Yegepa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PGB",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Pangoa",
            "name": "[PGB] - Pangoa - Papua New Guinea",
            "searchName": "PGB-Pangoa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PGN",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Pangia",
            "name": "[PGN] - Pangia - Papua New Guinea",
            "searchName": "PGN-Pangia-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PAW",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Pambwa",
            "name": "[PAW] - Pambwa - Papua New Guinea",
            "searchName": "PAW-Pambwa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OTY",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Oria",
            "name": "[OTY] - Oria - Papua New Guinea",
            "searchName": "OTY-Oria-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ONB",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Ononge",
            "name": "[ONB] - Ononge - Papua New Guinea",
            "searchName": "ONB-Ononge-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OPB",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Open Bay",
            "name": "[OPB] - Open Bay - Papua New Guinea",
            "searchName": "OPB-Open Bay-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OPU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Balimo",
            "name": "[OPU] - Balimo - Papua New Guinea",
            "searchName": "OPU-Balimo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OSE",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Omora",
            "name": "[OSE] - Omora - Papua New Guinea",
            "searchName": "OSE-Omora-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OSG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Ossima",
            "name": "[OSG] - Ossima - Papua New Guinea",
            "searchName": "OSG-Ossima-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "POM",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Port Moresby",
            "name": "[POM] - Port Moresby - Papua New Guinea",
            "searchName": "POM-Port Moresby-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PPX",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Param",
            "name": "[PPX] - Param - Papua New Guinea",
            "searchName": "PPX-Param-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PUI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Pureni",
            "name": "[PUI] - Pureni - Papua New Guinea",
            "searchName": "PUI-Pureni-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PUA",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Puas",
            "name": "[PUA] - Puas - Papua New Guinea",
            "searchName": "PUA-Puas-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PLE",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Paiela",
            "name": "[PLE] - Paiela - Papua New Guinea",
            "searchName": "PLE-Paiela-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PMP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Pimaga",
            "name": "[PMP] - Pimaga - Papua New Guinea",
            "searchName": "PMP-Pimaga-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PMN",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Pumani",
            "name": "[PMN] - Pumani - Papua New Guinea",
            "searchName": "PMN-Pumani-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PNP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Popondetta",
            "name": "[PNP] - Popondetta - Papua New Guinea",
            "searchName": "PNP-Popondetta-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "RGE",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Porgera",
            "name": "[RGE] - Porgera - Papua New Guinea",
            "searchName": "RGE-Porgera-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "RAA",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Rakanda",
            "name": "[RAA] - Rakanda - Papua New Guinea",
            "searchName": "RAA-Rakanda-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "RAB",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Rabaul",
            "name": "[RAB] - Rabaul - Papua New Guinea",
            "searchName": "RAB-Rabaul-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "RAW",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Arawa",
            "name": "[RAW] - Arawa - Papua New Guinea",
            "searchName": "RAW-Arawa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "RAX",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Oram",
            "name": "[RAX] - Oram - Papua New Guinea",
            "searchName": "RAX-Oram-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "RBP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Rabaraba",
            "name": "[RBP] - Rabaraba - Papua New Guinea",
            "searchName": "RBP-Rabaraba-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "RMN",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Rumginae",
            "name": "[RMN] - Rumginae - Papua New Guinea",
            "searchName": "RMN-Rumginae-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "RNR",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Robinson River",
            "name": "[RNR] - Robinson River - Papua New Guinea",
            "searchName": "RNR-Robinson River-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "RKU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Yule Island",
            "name": "[RKU] - Yule Island - Papua New Guinea",
            "searchName": "RKU-Yule Island-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "RUU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Ruti",
            "name": "[RUU] - Ruti - Papua New Guinea",
            "searchName": "RUU-Ruti-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SBC",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Selbang",
            "name": "[SBC] - Selbang - Papua New Guinea",
            "searchName": "SBC-Selbang-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SBE",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Suabi",
            "name": "[SBE] - Suabi - Papua New Guinea",
            "searchName": "SBE-Suabi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SAM",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Salamo",
            "name": "[SAM] - Salamo - Papua New Guinea",
            "searchName": "SAM-Salamo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SDI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Saidor",
            "name": "[SDI] - Saidor - Papua New Guinea",
            "searchName": "SDI-Saidor-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SBV",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Sabah",
            "name": "[SBV] - Sabah - Papua New Guinea",
            "searchName": "SBV-Sabah-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SMH",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Sapmanga",
            "name": "[SMH] - Sapmanga - Papua New Guinea",
            "searchName": "SMH-Sapmanga-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SMJ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Sim",
            "name": "[SMJ] - Sim - Papua New Guinea",
            "searchName": "SMJ-Sim-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SMP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Stockholm",
            "name": "[SMP] - Stockholm - Papua New Guinea",
            "searchName": "SMP-Stockholm-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SPV",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Sepik Plains",
            "name": "[SPV] - Sepik Plains - Papua New Guinea",
            "searchName": "SPV-Sepik Plains-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SPH",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Sopu",
            "name": "[SPH] - Sopu - Papua New Guinea",
            "searchName": "SPH-Sopu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SIZ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Sissano",
            "name": "[SIZ] - Sissano - Papua New Guinea",
            "searchName": "SIZ-Sissano-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SIL",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Sila",
            "name": "[SIL] - Sila - Papua New Guinea",
            "searchName": "SIL-Sila-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SIM",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Simbai",
            "name": "[SIM] - Simbai - Papua New Guinea",
            "searchName": "SIM-Simbai-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SKC",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Suki",
            "name": "[SKC] - Suki - Papua New Guinea",
            "searchName": "SKC-Suki-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SFU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Safia",
            "name": "[SFU] - Safia - Papua New Guinea",
            "searchName": "SFU-Safia-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SGB",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Singaua",
            "name": "[SGB] - Singaua - Papua New Guinea",
            "searchName": "SGB-Singaua-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SGJ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Sagarai",
            "name": "[SGJ] - Sagarai - Papua New Guinea",
            "searchName": "SGJ-Sagarai-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SGK",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Sangapi",
            "name": "[SGK] - Sangapi - Papua New Guinea",
            "searchName": "SGK-Sangapi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "VIV",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Vivigani",
            "name": "[VIV] - Vivigani - Papua New Guinea",
            "searchName": "VIV-Vivigani-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "UUU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Manumu",
            "name": "[UUU] - Manumu - Papua New Guinea",
            "searchName": "UUU-Manumu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "UVO",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Uvol",
            "name": "[UVO] - Uvol - Papua New Guinea",
            "searchName": "UVO-Uvol-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "VAI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Vanimo",
            "name": "[VAI] - Vanimo - Papua New Guinea",
            "searchName": "VAI-Vanimo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WAB",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Wabag",
            "name": "[WAB] - Wabag - Papua New Guinea",
            "searchName": "WAB-Wabag-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "VMU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Baimuru",
            "name": "[VMU] - Baimuru - Papua New Guinea",
            "searchName": "VMU-Baimuru-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WAJ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Wawoi Falls",
            "name": "[WAJ] - Wawoi Falls - Papua New Guinea",
            "searchName": "WAJ-Wawoi Falls-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WAO",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Wabo",
            "name": "[WAO] - Wabo - Papua New Guinea",
            "searchName": "WAO-Wabo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WBM",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Wapenamanda",
            "name": "[WBM] - Wapenamanda - Papua New Guinea",
            "searchName": "WBM-Wapenamanda-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WBC",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Wapolu",
            "name": "[WBC] - Wapolu - Papua New Guinea",
            "searchName": "WBC-Wapolu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WGU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Wagau",
            "name": "[WGU] - Wagau - Papua New Guinea",
            "searchName": "WGU-Wagau-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WEP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Weam",
            "name": "[WEP] - Weam - Papua New Guinea",
            "searchName": "WEP-Weam-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WED",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Wedau",
            "name": "[WED] - Wedau - Papua New Guinea",
            "searchName": "WED-Wedau-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "UAE",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Mount Aue",
            "name": "[UAE] - Mount Aue - Papua New Guinea",
            "searchName": "UAE-Mount Aue-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "UBI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Buin",
            "name": "[UBI] - Buin - Papua New Guinea",
            "searchName": "UBI-Buin-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TWY",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Tawa",
            "name": "[TWY] - Tawa - Papua New Guinea",
            "searchName": "TWY-Tawa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TUT",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Tauta",
            "name": "[TUT] - Tauta - Papua New Guinea",
            "searchName": "TUT-Tauta-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TRJ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Tarakbits",
            "name": "[TRJ] - Tarakbits - Papua New Guinea",
            "searchName": "TRJ-Tarakbits-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TSW",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Tsewi",
            "name": "[TSW] - Tsewi - Papua New Guinea",
            "searchName": "TSW-Tsewi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TSK",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Taskul",
            "name": "[TSK] - Taskul - Papua New Guinea",
            "searchName": "TSK-Taskul-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TSI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Tsili Tsili",
            "name": "[TSI] - Tsili Tsili - Papua New Guinea",
            "searchName": "TSI-Tsili Tsili-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "UMC",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Umba",
            "name": "[UMC] - Umba - Papua New Guinea",
            "searchName": "UMC-Umba-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ULE",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Sule",
            "name": "[ULE] - Sule - Papua New Guinea",
            "searchName": "ULE-Sule-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "UNG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kiunga",
            "name": "[UNG] - Kiunga - Papua New Guinea",
            "searchName": "UNG-Kiunga-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "UPR",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Upiara",
            "name": "[UPR] - Upiara - Papua New Guinea",
            "searchName": "UPR-Upiara-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "USO",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Usino",
            "name": "[USO] - Usino - Papua New Guinea",
            "searchName": "USO-Usino-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "URU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Uroubi",
            "name": "[URU] - Uroubi - Papua New Guinea",
            "searchName": "URU-Uroubi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "UKU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Nuku",
            "name": "[UKU] - Nuku - Papua New Guinea",
            "searchName": "UKU-Nuku-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TIG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Tingwon",
            "name": "[TIG] - Tingwon - Papua New Guinea",
            "searchName": "TIG-Tingwon-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TKB",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Tekadu",
            "name": "[TKB] - Tekadu - Papua New Guinea",
            "searchName": "TKB-Tekadu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TIZ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Tari",
            "name": "[TIZ] - Tari - Papua New Guinea",
            "searchName": "TIZ-Tari-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TGL",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Tagula",
            "name": "[TGL] - Tagula - Papua New Guinea",
            "searchName": "TGL-Tagula-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TFB",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Tifalmin",
            "name": "[TFB] - Tifalmin - Papua New Guinea",
            "searchName": "TFB-Tifalmin-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TFM",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Telefomin",
            "name": "[TFM] - Telefomin - Papua New Guinea",
            "searchName": "TFM-Telefomin-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TFI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Tufi",
            "name": "[TFI] - Tufi - Papua New Guinea",
            "searchName": "TFI-Tufi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TEO",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Terapo",
            "name": "[TEO] - Terapo - Papua New Guinea",
            "searchName": "TEO-Terapo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TEP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Teptep",
            "name": "[TEP] - Teptep - Papua New Guinea",
            "searchName": "TEP-Teptep-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TDB",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Tetabedi",
            "name": "[TDB] - Tetabedi - Papua New Guinea",
            "searchName": "TDB-Tetabedi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TDS",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Sasereme",
            "name": "[TDS] - Sasereme - Papua New Guinea",
            "searchName": "TDS-Sasereme-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TKW",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Tekin",
            "name": "[TKW] - Tekin - Papua New Guinea",
            "searchName": "TKW-Tekin-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TLW",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Talasea",
            "name": "[TLW] - Talasea - Papua New Guinea",
            "searchName": "TLW-Talasea-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TLO",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Tol",
            "name": "[TLO] - Tol - Papua New Guinea",
            "searchName": "TLO-Tol-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TLP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Tumolbil",
            "name": "[TLP] - Tumolbil - Papua New Guinea",
            "searchName": "TLP-Tumolbil-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TPI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Tapini",
            "name": "[TPI] - Tapini - Papua New Guinea",
            "searchName": "TPI-Tapini-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TON",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Tonu",
            "name": "[TON] - Tonu - Papua New Guinea",
            "searchName": "TON-Tonu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TOK",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Torokina",
            "name": "[TOK] - Torokina - Papua New Guinea",
            "searchName": "TOK-Torokina-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SQT",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Samarai Island",
            "name": "[SQT] - Samarai Island - Papua New Guinea",
            "searchName": "SQT-Samarai Island-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SSS",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Siassi",
            "name": "[SSS] - Siassi - Papua New Guinea",
            "searchName": "SSS-Siassi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SUZ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Suria",
            "name": "[SUZ] - Suria - Papua New Guinea",
            "searchName": "SUZ-Suria-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SWR",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Silur",
            "name": "[SWR] - Silur - Papua New Guinea",
            "searchName": "SWR-Silur-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SWG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Satwag",
            "name": "[SWG] - Satwag - Papua New Guinea",
            "searchName": "SWG-Satwag-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SWE",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Siwea",
            "name": "[SWE] - Siwea - Papua New Guinea",
            "searchName": "SWE-Siwea-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TBQ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Tarabo",
            "name": "[TBQ] - Tarabo - Papua New Guinea",
            "searchName": "TBQ-Tarabo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TCJ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Torembi",
            "name": "[TCJ] - Torembi - Papua New Guinea",
            "searchName": "TCJ-Torembi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TCK",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Tinboli",
            "name": "[TCK] - Tinboli - Papua New Guinea",
            "searchName": "TCK-Tinboli-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TBG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Tabubil",
            "name": "[TBG] - Tabubil - Papua New Guinea",
            "searchName": "TBG-Tabubil-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TBE",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Timbunke",
            "name": "[TBE] - Timbunke - Papua New Guinea",
            "searchName": "TBE-Timbunke-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TBA",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Tabibuga",
            "name": "[TBA] - Tabibuga - Papua New Guinea",
            "searchName": "TBA-Tabibuga-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SXW",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Sauren",
            "name": "[SXW] - Sauren - Papua New Guinea",
            "searchName": "SXW-Sauren-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SXA",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Sialum",
            "name": "[SXA] - Sialum - Papua New Guinea",
            "searchName": "SXA-Sialum-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SXH",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Sehulea",
            "name": "[SXH] - Sehulea - Papua New Guinea",
            "searchName": "SXH-Sehulea-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "XYR",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Yellow River",
            "name": "[XYR] - Yellow River - Papua New Guinea",
            "searchName": "XYR-Yellow River-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WSA",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Wasua",
            "name": "[WSA] - Wasua - Papua New Guinea",
            "searchName": "WSA-Wasua-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WPM",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Wipim",
            "name": "[WPM] - Wipim - Papua New Guinea",
            "searchName": "WPM-Wipim-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WUG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Wau",
            "name": "[WUG] - Wau - Papua New Guinea",
            "searchName": "WUG-Wau-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WUM",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Wasum",
            "name": "[WUM] - Wasum - Papua New Guinea",
            "searchName": "WUM-Wasum-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WSU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Wasu",
            "name": "[WSU] - Wasu - Papua New Guinea",
            "searchName": "WSU-Wasu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WTT",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Wantoat",
            "name": "[WTT] - Wantoat - Papua New Guinea",
            "searchName": "WTT-Wantoat-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WTP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Woitape",
            "name": "[WTP] - Woitape - Papua New Guinea",
            "searchName": "WTP-Woitape-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WIU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Witu",
            "name": "[WIU] - Witu - Papua New Guinea",
            "searchName": "WIU-Witu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WKN",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Wakunai",
            "name": "[WKN] - Wakunai - Papua New Guinea",
            "searchName": "WKN-Wakunai-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WOA",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Wonenara",
            "name": "[WOA] - Wonenara - Papua New Guinea",
            "searchName": "WOA-Wonenara-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WNU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Wanuma",
            "name": "[WNU] - Wanuma - Papua New Guinea",
            "searchName": "WNU-Wanuma-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WUV",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Wuvulu Island",
            "name": "[WUV] - Wuvulu Island - Papua New Guinea",
            "searchName": "WUV-Wuvulu Island-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WWK",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Wewak",
            "name": "[WWK] - Wewak - Papua New Guinea",
            "searchName": "WWK-Wewak-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "XBN",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Biniguni",
            "name": "[XBN] - Biniguni - Papua New Guinea",
            "searchName": "XBN-Biniguni-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ZEN",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Zenag",
            "name": "[ZEN] - Zenag - Papua New Guinea",
            "searchName": "ZEN-Zenag-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "YVD",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Yeva",
            "name": "[YVD] - Yeva - Papua New Guinea",
            "searchName": "YVD-Yeva-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "YEQ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Yenkis",
            "name": "[YEQ] - Yenkis - Papua New Guinea",
            "searchName": "YEQ-Yenkis-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ADC",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Andakombe",
            "name": "[ADC] - Andakombe - Papua New Guinea",
            "searchName": "ADC-Andakombe-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ABW",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Abau",
            "name": "[ABW] - Abau - Papua New Guinea",
            "searchName": "ABW-Abau-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ABP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Atkamba",
            "name": "[ABP] - Atkamba - Papua New Guinea",
            "searchName": "ABP-Atkamba-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AEK",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Aseki",
            "name": "[AEK] - Aseki - Papua New Guinea",
            "searchName": "AEK-Aseki-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AGK",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kagua",
            "name": "[AGK] - Kagua - Papua New Guinea",
            "searchName": "AGK-Kagua-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AGL",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Wanigela",
            "name": "[AGL] - Wanigela - Papua New Guinea",
            "searchName": "AGL-Wanigela-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AGG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Angoram",
            "name": "[AGG] - Angoram - Papua New Guinea",
            "searchName": "AGG-Angoram-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AFR",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Afore",
            "name": "[AFR] - Afore - Papua New Guinea",
            "searchName": "AFR-Afore-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AIE",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Aiome",
            "name": "[AIE] - Aiome - Papua New Guinea",
            "searchName": "AIE-Aiome-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AIH",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Aiambak",
            "name": "[AIH] - Aiambak - Papua New Guinea",
            "searchName": "AIH-Aiambak-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AKG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Anguganak",
            "name": "[AKG] - Anguganak - Papua New Guinea",
            "searchName": "AKG-Anguganak-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AOA",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Aroa",
            "name": "[AOA] - Aroa - Papua New Guinea",
            "searchName": "AOA-Aroa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AOB",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Annanberg",
            "name": "[AOB] - Annanberg - Papua New Guinea",
            "searchName": "AOB-Annanberg-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AMU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Amanab",
            "name": "[AMU] - Amanab - Papua New Guinea",
            "searchName": "AMU-Amanab-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AMF",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Ama",
            "name": "[AMF] - Ama - Papua New Guinea",
            "searchName": "AMF-Ama-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AMG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Amboin",
            "name": "[AMG] - Amboin - Papua New Guinea",
            "searchName": "AMG-Amboin-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "APP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Asapa",
            "name": "[APP] - Asapa - Papua New Guinea",
            "searchName": "APP-Asapa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "APR",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "April River",
            "name": "[APR] - April River - Papua New Guinea",
            "searchName": "APR-April River-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AON",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Arona",
            "name": "[AON] - Arona - Papua New Guinea",
            "searchName": "AON-Arona-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ARP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Aragip",
            "name": "[ARP] - Aragip - Papua New Guinea",
            "searchName": "ARP-Aragip-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AUP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Agaun",
            "name": "[AUP] - Agaun - Papua New Guinea",
            "searchName": "AUP-Agaun-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AUV",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Aumo",
            "name": "[AUV] - Aumo - Papua New Guinea",
            "searchName": "AUV-Aumo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AUI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Aua Island",
            "name": "[AUI] - Aua Island - Papua New Guinea",
            "searchName": "AUI-Aua Island-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AUJ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Ambunti",
            "name": "[AUJ] - Ambunti - Papua New Guinea",
            "searchName": "AUJ-Ambunti-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ATN",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Namatanai",
            "name": "[ATN] - Namatanai - Papua New Guinea",
            "searchName": "ATN-Namatanai-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ATP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Aitape",
            "name": "[ATP] - Aitape - Papua New Guinea",
            "searchName": "ATP-Aitape-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ASZ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Asirim",
            "name": "[ASZ] - Asirim - Papua New Guinea",
            "searchName": "ASZ-Asirim-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AWR",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Awar",
            "name": "[AWR] - Awar - Papua New Guinea",
            "searchName": "AWR-Awar-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AWB",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Awaba",
            "name": "[AWB] - Awaba - Papua New Guinea",
            "searchName": "AWB-Awaba-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AYU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Aiyura",
            "name": "[AYU] - Aiyura - Papua New Guinea",
            "searchName": "AYU-Aiyura-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AZB",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Amazon Bay",
            "name": "[AZB] - Amazon Bay - Papua New Guinea",
            "searchName": "AZB-Amazon Bay-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BAJ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Bali",
            "name": "[BAJ] - Bali - Papua New Guinea",
            "searchName": "BAJ-Bali-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BAA",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Bialla",
            "name": "[BAA] - Bialla - Papua New Guinea",
            "searchName": "BAA-Bialla-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BCP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Bambu",
            "name": "[BCP] - Bambu - Papua New Guinea",
            "searchName": "BCP-Bambu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BAP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Baibara",
            "name": "[BAP] - Baibara - Papua New Guinea",
            "searchName": "BAP-Baibara-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BOQ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Boku",
            "name": "[BOQ] - Boku - Papua New Guinea",
            "searchName": "BOQ-Boku-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BPD",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Bapi",
            "name": "[BPD] - Bapi - Papua New Guinea",
            "searchName": "BPD-Bapi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BOV",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Boang",
            "name": "[BOV] - Boang - Papua New Guinea",
            "searchName": "BOV-Boang-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BOT",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Boset",
            "name": "[BOT] - Boset - Papua New Guinea",
            "searchName": "BOT-Boset-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BPB",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Boridi",
            "name": "[BPB] - Boridi - Papua New Guinea",
            "searchName": "BPB-Boridi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BNM",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Bodinumu",
            "name": "[BNM] - Bodinumu - Papua New Guinea",
            "searchName": "BNM-Bodinumu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BNV",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Boana",
            "name": "[BNV] - Boana - Papua New Guinea",
            "searchName": "BNV-Boana-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BNT",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Bundi",
            "name": "[BNT] - Bundi - Papua New Guinea",
            "searchName": "BNT-Bundi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BNZ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Banz",
            "name": "[BNZ] - Banz - Papua New Guinea",
            "searchName": "BNZ-Banz-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BMZ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Bamu",
            "name": "[BMZ] - Bamu - Papua New Guinea",
            "searchName": "BMZ-Bamu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BMH",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Bomai",
            "name": "[BMH] - Bomai - Papua New Guinea",
            "searchName": "BMH-Bomai-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BDZ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Baindoung",
            "name": "[BDZ] - Baindoung - Papua New Guinea",
            "searchName": "BDZ-Baindoung-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BEA",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Bereina",
            "name": "[BEA] - Bereina - Papua New Guinea",
            "searchName": "BEA-Bereina-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BIJ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Biliau",
            "name": "[BIJ] - Biliau - Papua New Guinea",
            "searchName": "BIJ-Biliau-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BIZ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Bimin",
            "name": "[BIZ] - Bimin - Papua New Guinea",
            "searchName": "BIZ-Bimin-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BWP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Bewani",
            "name": "[BWP] - Bewani - Papua New Guinea",
            "searchName": "BWP-Bewani-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BXZ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Bunsil",
            "name": "[BXZ] - Bunsil - Papua New Guinea",
            "searchName": "BXZ-Bunsil-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BWJ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Bawan",
            "name": "[BWJ] - Bawan - Papua New Guinea",
            "searchName": "BWJ-Bawan-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BVP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Bolovip",
            "name": "[BVP] - Bolovip - Papua New Guinea",
            "searchName": "BVP-Bolovip-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BUL",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Bulolo",
            "name": "[BUL] - Bulolo - Papua New Guinea",
            "searchName": "BUL-Bulolo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BUA",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Buka",
            "name": "[BUA] - Buka - Papua New Guinea",
            "searchName": "BUA-Buka-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BPK",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Biangabip",
            "name": "[BPK] - Biangabip - Papua New Guinea",
            "searchName": "BPK-Biangabip-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BRH",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Brahman",
            "name": "[BRH] - Brahman - Papua New Guinea",
            "searchName": "BRH-Brahman-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BRP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Biaru",
            "name": "[BRP] - Biaru - Papua New Guinea",
            "searchName": "BRP-Biaru-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BSP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Bensbach",
            "name": "[BSP] - Bensbach - Papua New Guinea",
            "searchName": "BSP-Bensbach-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "FIN",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Finschhafen",
            "name": "[FIN] - Finschhafen - Papua New Guinea",
            "searchName": "FIN-Finschhafen-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "FRQ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Feramin",
            "name": "[FRQ] - Feramin - Papua New Guinea",
            "searchName": "FRQ-Feramin-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "FNE",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Fane",
            "name": "[FNE] - Fane - Papua New Guinea",
            "searchName": "FNE-Fane-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "FAQ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Freida River",
            "name": "[FAQ] - Freida River - Papua New Guinea",
            "searchName": "FAQ-Freida River-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "EPT",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Eliptamin",
            "name": "[EPT] - Eliptamin - Papua New Guinea",
            "searchName": "EPT-Eliptamin-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ESA",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Esa Ala",
            "name": "[ESA] - Esa Ala - Papua New Guinea",
            "searchName": "ESA-Esa Ala-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ERU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Erume",
            "name": "[ERU] - Erume - Papua New Guinea",
            "searchName": "ERU-Erume-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ERE",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Erave",
            "name": "[ERE] - Erave - Papua New Guinea",
            "searchName": "ERE-Erave-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "EMO",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Emo",
            "name": "[EMO] - Emo - Papua New Guinea",
            "searchName": "EMO-Emo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "EMS",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Embessa",
            "name": "[EMS] - Embessa - Papua New Guinea",
            "searchName": "EMS-Embessa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "EMI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Emirau",
            "name": "[EMI] - Emirau - Papua New Guinea",
            "searchName": "EMI-Emirau-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "EGA",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Engati",
            "name": "[EGA] - Engati - Papua New Guinea",
            "searchName": "EGA-Engati-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "EIA",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Eia",
            "name": "[EIA] - Eia - Papua New Guinea",
            "searchName": "EIA-Eia-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "EFG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Efogi",
            "name": "[EFG] - Efogi - Papua New Guinea",
            "searchName": "EFG-Efogi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DPU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Dumpu",
            "name": "[DPU] - Dumpu - Papua New Guinea",
            "searchName": "DPU-Dumpu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DNU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Dinangat",
            "name": "[DNU] - Dinangat - Papua New Guinea",
            "searchName": "DNU-Dinangat-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DOO",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Dorobisoro",
            "name": "[DOO] - Dorobisoro - Papua New Guinea",
            "searchName": "DOO-Dorobisoro-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DOS",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Dios",
            "name": "[DOS] - Dios - Papua New Guinea",
            "searchName": "DOS-Dios-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DOI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Doini",
            "name": "[DOI] - Doini - Papua New Guinea",
            "searchName": "DOI-Doini-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DLB",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Dalbertis",
            "name": "[DLB] - Dalbertis - Papua New Guinea",
            "searchName": "DLB-Dalbertis-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "CGC",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Cape Gloucester",
            "name": "[CGC] - Cape Gloucester - Papua New Guinea",
            "searchName": "CGC-Cape Gloucester-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "CMU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kundiawa",
            "name": "[CMU] - Kundiawa - Papua New Guinea",
            "searchName": "CMU-Kundiawa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "CPI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Cape Orford",
            "name": "[CPI] - Cape Orford - Papua New Guinea",
            "searchName": "CPI-Cape Orford-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "CPN",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Cape Rodney",
            "name": "[CPN] - Cape Rodney - Papua New Guinea",
            "searchName": "CPN-Cape Rodney-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "CVB",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Chungribu",
            "name": "[CVB] - Chungribu - Papua New Guinea",
            "searchName": "CVB-Chungribu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "CVL",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Cape Vogel",
            "name": "[CVL] - Cape Vogel - Papua New Guinea",
            "searchName": "CVL-Cape Vogel-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DBP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Debepare",
            "name": "[DBP] - Debepare - Papua New Guinea",
            "searchName": "DBP-Debepare-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DAU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Daru",
            "name": "[DAU] - Daru - Papua New Guinea",
            "searchName": "DAU-Daru-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DAO",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Dabo",
            "name": "[DAO] - Dabo - Papua New Guinea",
            "searchName": "DAO-Dabo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DAF",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Daup",
            "name": "[DAF] - Daup - Papua New Guinea",
            "searchName": "DAF-Daup-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DGG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Daugo",
            "name": "[DGG] - Daugo - Papua New Guinea",
            "searchName": "DGG-Daugo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DER",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Derim",
            "name": "[DER] - Derim - Papua New Guinea",
            "searchName": "DER-Derim-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DDM",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Dodoima",
            "name": "[DDM] - Dodoima - Papua New Guinea",
            "searchName": "DDM-Dodoima-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "HGU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Mount Hagen",
            "name": "[HGU] - Mount Hagen - Papua New Guinea",
            "searchName": "HGU-Mount Hagen-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "HEO",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Haelogo",
            "name": "[HEO] - Haelogo - Papua New Guinea",
            "searchName": "HEO-Haelogo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "HOC",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Komako",
            "name": "[HOC] - Komako - Papua New Guinea",
            "searchName": "HOC-Komako-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "HNN",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Honinabi",
            "name": "[HNN] - Honinabi - Papua New Guinea",
            "searchName": "HNN-Honinabi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "HNI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Heiweni",
            "name": "[HNI] - Heiweni - Papua New Guinea",
            "searchName": "HNI-Heiweni-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "HKN",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Hoskins",
            "name": "[HKN] - Hoskins - Papua New Guinea",
            "searchName": "HKN-Hoskins-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "HIT",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Hivaro",
            "name": "[HIT] - Hivaro - Papua New Guinea",
            "searchName": "HIT-Hivaro-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "HBD",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Habi",
            "name": "[HBD] - Habi - Papua New Guinea",
            "searchName": "HBD-Habi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "HAZ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Hatzfeldthaven",
            "name": "[HAZ] - Hatzfeldthaven - Papua New Guinea",
            "searchName": "HAZ-Hatzfeldthaven-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GWN",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Gnarowein",
            "name": "[GWN] - Gnarowein - Papua New Guinea",
            "searchName": "GWN-Gnarowein-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GVI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Green River",
            "name": "[GVI] - Green River - Papua New Guinea",
            "searchName": "GVI-Green River-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GRL",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Garasa",
            "name": "[GRL] - Garasa - Papua New Guinea",
            "searchName": "GRL-Garasa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GRH",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Garuahi",
            "name": "[GRH] - Garuahi - Papua New Guinea",
            "searchName": "GRH-Garuahi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GUE",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Guriaso",
            "name": "[GUE] - Guriaso - Papua New Guinea",
            "searchName": "GUE-Guriaso-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GUG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Guari",
            "name": "[GUG] - Guari - Papua New Guinea",
            "searchName": "GUG-Guari-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GUV",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Mougulu",
            "name": "[GUV] - Mougulu - Papua New Guinea",
            "searchName": "GUV-Mougulu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GUR",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Alotau",
            "name": "[GUR] - Alotau - Papua New Guinea",
            "searchName": "GUR-Alotau-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GMI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Gasmata Island",
            "name": "[GMI] - Gasmata Island - Papua New Guinea",
            "searchName": "GMI-Gasmata Island-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GOC",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Gora",
            "name": "[GOC] - Gora - Papua New Guinea",
            "searchName": "GOC-Gora-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GOE",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Gonalia",
            "name": "[GOE] - Gonalia - Papua New Guinea",
            "searchName": "GOE-Gonalia-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GEW",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Gewoia",
            "name": "[GEW] - Gewoia - Papua New Guinea",
            "searchName": "GEW-Gewoia-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GKA",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Goroka",
            "name": "[GKA] - Goroka - Papua New Guinea",
            "searchName": "GKA-Goroka-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GLP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Gulgubip",
            "name": "[GLP] - Gulgubip - Papua New Guinea",
            "searchName": "GLP-Gulgubip-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GBC",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Gasuke",
            "name": "[GBC] - Gasuke - Papua New Guinea",
            "searchName": "GBC-Gasuke-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GBF",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Negarbo",
            "name": "[GBF] - Negarbo - Papua New Guinea",
            "searchName": "GBF-Negarbo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GEI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Green Islands",
            "name": "[GEI] - Green Islands - Papua New Guinea",
            "searchName": "GEI-Green Islands-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GAZ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Guasopa",
            "name": "[GAZ] - Guasopa - Papua New Guinea",
            "searchName": "GAZ-Guasopa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GAR",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Garaina",
            "name": "[GAR] - Garaina - Papua New Guinea",
            "searchName": "GAR-Garaina-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GAP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Gusap",
            "name": "[GAP] - Gusap - Papua New Guinea",
            "searchName": "GAP-Gusap-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "FUM",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Fuma",
            "name": "[FUM] - Fuma - Papua New Guinea",
            "searchName": "FUM-Fuma-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "FUB",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Fulleborn",
            "name": "[FUB] - Fulleborn - Papua New Guinea",
            "searchName": "FUB-Fulleborn-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "IBI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Iboki",
            "name": "[IBI] - Iboki - Papua New Guinea",
            "searchName": "IBI-Iboki-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "IAU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Iaura",
            "name": "[IAU] - Iaura - Papua New Guinea",
            "searchName": "IAU-Iaura-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "HYF",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Hayfields",
            "name": "[HYF] - Hayfields - Papua New Guinea",
            "searchName": "HYF-Hayfields-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "HWA",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Hawabango",
            "name": "[HWA] - Hawabango - Papua New Guinea",
            "searchName": "HWA-Hawabango-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ILX",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Ileg",
            "name": "[ILX] - Ileg - Papua New Guinea",
            "searchName": "ILX-Ileg-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "IMA",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Iamalele",
            "name": "[IMA] - Iamalele - Papua New Guinea",
            "searchName": "IMA-Iamalele-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "IMD",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Imonda",
            "name": "[IMD] - Imonda - Papua New Guinea",
            "searchName": "IMD-Imonda-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "IMN",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Imane",
            "name": "[IMN] - Imane - Papua New Guinea",
            "searchName": "IMN-Imane-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "IOP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Ioma",
            "name": "[IOP] - Ioma - Papua New Guinea",
            "searchName": "IOP-Ioma-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "IOK",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Iokea",
            "name": "[IOK] - Iokea - Papua New Guinea",
            "searchName": "IOK-Iokea-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "IDN",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Indagen",
            "name": "[IDN] - Indagen - Papua New Guinea",
            "searchName": "IDN-Indagen-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "IHU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Ihu",
            "name": "[IHU] - Ihu - Papua New Guinea",
            "searchName": "IHU-Ihu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "IIS",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Nissan Island",
            "name": "[IIS] - Nissan Island - Papua New Guinea",
            "searchName": "IIS-Nissan Island-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "JOP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Josephstaal",
            "name": "[JOP] - Josephstaal - Papua New Guinea",
            "searchName": "JOP-Josephstaal-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KAQ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kamulai",
            "name": "[KAQ] - Kamulai - Papua New Guinea",
            "searchName": "KAQ-Kamulai-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KAF",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Karato",
            "name": "[KAF] - Karato - Papua New Guinea",
            "searchName": "KAF-Karato-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KAK",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kar",
            "name": "[KAK] - Kar - Papua New Guinea",
            "searchName": "KAK-Kar-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "JAQ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Jacquinot Bay",
            "name": "[JAQ] - Jacquinot Bay - Papua New Guinea",
            "searchName": "JAQ-Jacquinot Bay-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "IUS",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Inus",
            "name": "[IUS] - Inus - Papua New Guinea",
            "searchName": "IUS-Inus-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ITK",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Itokama",
            "name": "[ITK] - Itokama - Papua New Guinea",
            "searchName": "ITK-Itokama-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KXR",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Karoola",
            "name": "[KXR] - Karoola - Papua New Guinea",
            "searchName": "KXR-Karoola-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KZF",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kaintiba",
            "name": "[KZF] - Kaintiba - Papua New Guinea",
            "searchName": "KZF-Kaintiba-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KYX",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Yalumet",
            "name": "[KYX] - Yalumet - Papua New Guinea",
            "searchName": "KYX-Yalumet-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KWV",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kurwina",
            "name": "[KWV] - Kurwina - Papua New Guinea",
            "searchName": "KWV-Kurwina-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KWX",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kiwai Island",
            "name": "[KWX] - Kiwai Island - Papua New Guinea",
            "searchName": "KWX-Kiwai Island-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KWO",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kawito",
            "name": "[KWO] - Kawito - Papua New Guinea",
            "searchName": "KWO-Kawito-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LAB",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Lablab",
            "name": "[LAB] - Lablab - Papua New Guinea",
            "searchName": "LAB-Lablab-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LAE",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Lae",
            "name": "[LAE] - Lae - Papua New Guinea",
            "searchName": "LAE-Lae-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KSX",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Yasuru",
            "name": "[KSX] - Yasuru - Papua New Guinea",
            "searchName": "KSX-Yasuru-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KTK",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kanua",
            "name": "[KTK] - Kanua - Papua New Guinea",
            "searchName": "KTK-Kanua-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KUX",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kuyol",
            "name": "[KUX] - Kuyol - Papua New Guinea",
            "searchName": "KUX-Kuyol-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KUY",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kamusi",
            "name": "[KUY] - Kamusi - Papua New Guinea",
            "searchName": "KUY-Kamusi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KUP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kupiano",
            "name": "[KUP] - Kupiano - Papua New Guinea",
            "searchName": "KUP-Kupiano-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KUQ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kuri",
            "name": "[KUQ] - Kuri - Papua New Guinea",
            "searchName": "KUQ-Kuri-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KVE",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kitava",
            "name": "[KVE] - Kitava - Papua New Guinea",
            "searchName": "KVE-Kitava-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KVG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kavieng",
            "name": "[KVG] - Kavieng - Papua New Guinea",
            "searchName": "KVG-Kavieng-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KRU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kerau",
            "name": "[KRU] - Kerau - Papua New Guinea",
            "searchName": "KRU-Kerau-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KRX",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kar Kar",
            "name": "[KRX] - Kar Kar - Papua New Guinea",
            "searchName": "KRX-Kar Kar-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KSB",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kasanombe",
            "name": "[KSB] - Kasanombe - Papua New Guinea",
            "searchName": "KSB-Kasanombe-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KSP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kosipe",
            "name": "[KSP] - Kosipe - Papua New Guinea",
            "searchName": "KSP-Kosipe-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KSG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kisengan",
            "name": "[KSG] - Kisengan - Papua New Guinea",
            "searchName": "KSG-Kisengan-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KPL",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kapal",
            "name": "[KPL] - Kapal - Papua New Guinea",
            "searchName": "KPL-Kapal-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KPM",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kompiam",
            "name": "[KPM] - Kompiam - Papua New Guinea",
            "searchName": "KPM-Kompiam-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KPE",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Yapsiei",
            "name": "[KPE] - Yapsiei - Papua New Guinea",
            "searchName": "KPE-Yapsiei-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KPF",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kondubol",
            "name": "[KPF] - Kondubol - Papua New Guinea",
            "searchName": "KPF-Kondubol-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KPA",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kopiago",
            "name": "[KPA] - Kopiago - Papua New Guinea",
            "searchName": "KPA-Kopiago-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KRI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kikori",
            "name": "[KRI] - Kikori - Papua New Guinea",
            "searchName": "KRI-Kikori-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KRJ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Karawari",
            "name": "[KRJ] - Karawari - Papua New Guinea",
            "searchName": "KRJ-Karawari-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KQL",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kol",
            "name": "[KQL] - Kol - Papua New Guinea",
            "searchName": "KQL-Kol-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KKD",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kokoda",
            "name": "[KKD] - Kokoda - Papua New Guinea",
            "searchName": "KKD-Kokoda-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KIZ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kikinonda",
            "name": "[KIZ] - Kikinonda - Papua New Guinea",
            "searchName": "KIZ-Kikinonda-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KJU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kamiraba",
            "name": "[KJU] - Kamiraba - Papua New Guinea",
            "searchName": "KJU-Kamiraba-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KIQ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kira",
            "name": "[KIQ] - Kira - Papua New Guinea",
            "searchName": "KIQ-Kira-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KIE",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kieta",
            "name": "[KIE] - Kieta - Papua New Guinea",
            "searchName": "KIE-Kieta-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KII",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kibuli",
            "name": "[KII] - Kibuli - Papua New Guinea",
            "searchName": "KII-Kibuli-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KOM",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Komo-Manda",
            "name": "[KOM] - Komo-Manda - Papua New Guinea",
            "searchName": "KOM-Komo-Manda-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KOR",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kokoro",
            "name": "[KOR] - Kokoro - Papua New Guinea",
            "searchName": "KOR-Kokoro-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KNL",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kelanoa",
            "name": "[KNL] - Kelanoa - Papua New Guinea",
            "searchName": "KNL-Kelanoa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KNE",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kanainj",
            "name": "[KNE] - Kanainj - Papua New Guinea",
            "searchName": "KNE-Kanainj-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KMA",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kerema",
            "name": "[KMA] - Kerema - Papua New Guinea",
            "searchName": "KMA-Kerema-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KMB",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Koinambe",
            "name": "[KMB] - Koinambe - Papua New Guinea",
            "searchName": "KMB-Koinambe-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KMR",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Karimui",
            "name": "[KMR] - Karimui - Papua New Guinea",
            "searchName": "KMR-Karimui-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KMF",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kamina",
            "name": "[KMF] - Kamina - Papua New Guinea",
            "searchName": "KMF-Kamina-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KIA",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kaiapit",
            "name": "[KIA] - Kaiapit - Papua New Guinea",
            "searchName": "KIA-Kaiapit-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KGW",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kagi",
            "name": "[KGW] - Kagi - Papua New Guinea",
            "searchName": "KGW-Kagi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KGM",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kungum",
            "name": "[KGM] - Kungum - Papua New Guinea",
            "searchName": "KGM-Kungum-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KEG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Keglsugl",
            "name": "[KEG] - Keglsugl - Papua New Guinea",
            "searchName": "KEG-Keglsugl-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KGH",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Yongai",
            "name": "[KGH] - Yongai - Papua New Guinea",
            "searchName": "KGH-Yongai-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KEX",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kanabea",
            "name": "[KEX] - Kanabea - Papua New Guinea",
            "searchName": "KEX-Kanabea-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KGB",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Konge",
            "name": "[KGB] - Konge - Papua New Guinea",
            "searchName": "KGB-Konge-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KBM",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kabwum",
            "name": "[KBM] - Kabwum - Papua New Guinea",
            "searchName": "KBM-Kabwum-Kabwun-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KDE",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Koroba",
            "name": "[KDE] - Koroba - Papua New Guinea",
            "searchName": "KDE-Koroba-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KCJ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Komaio",
            "name": "[KCJ] - Komaio - Papua New Guinea",
            "searchName": "KCJ-Komaio-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KDP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kandep",
            "name": "[KDP] - Kandep - Papua New Guinea",
            "searchName": "KDP-Kandep-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KDQ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kamberatoro",
            "name": "[KDQ] - Kamberatoro - Papua New Guinea",
            "searchName": "KDQ-Kamberatoro-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KDR",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Kandrian",
            "name": "[KDR] - Kandrian - Papua New Guinea",
            "searchName": "KDR-Kandrian-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MAP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Mamai",
            "name": "[MAP] - Mamai - Papua New Guinea",
            "searchName": "MAP-Mamai-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MAS",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Manus Island",
            "name": "[MAS] - Manus Island - Papua New Guinea",
            "searchName": "MAS-Manus Island--Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MBV",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Masa",
            "name": "[MBV] - Masa - Papua New Guinea",
            "searchName": "MBV-Masa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MDM",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Munduku",
            "name": "[MDM] - Munduku - Papua New Guinea",
            "searchName": "MDM-Munduku-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LWI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Lowai",
            "name": "[LWI] - Lowai - Papua New Guinea",
            "searchName": "LWI-Lowai-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MSG",
            "countryCode": "LS",
            "cityCode": null,
            "cityName": "Matsaile",
            "name": "[MSG] - Matsaile - Lesotho",
            "searchName": "MSG-Matsaile-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "PEL",
            "countryCode": "LS",
            "cityCode": null,
            "cityName": "Pelaneng",
            "name": "[PEL] - Pelaneng - Lesotho",
            "searchName": "PEL-Pelaneng-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "TKO",
            "countryCode": "LS",
            "cityCode": null,
            "cityName": "Tlokoeng",
            "name": "[TKO] - Tlokoeng - Lesotho",
            "searchName": "TKO-Tlokoeng-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "THB",
            "countryCode": "LS",
            "cityCode": null,
            "cityName": "Thaba-Tseka",
            "name": "[THB] - Thaba-Tseka - Lesotho",
            "searchName": "THB-Thaba-Tseka-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "UTG",
            "countryCode": "LS",
            "cityCode": null,
            "cityName": "Quthing",
            "name": "[UTG] - Quthing - Lesotho",
            "searchName": "UTG-Quthing-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "UNE",
            "countryCode": "LS",
            "cityCode": null,
            "cityName": "Qachas Nek",
            "name": "[UNE] - Qachas Nek - Lesotho",
            "searchName": "UNE-Qachas-Qachas Nek-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "LEF",
            "countryCode": "LS",
            "cityCode": null,
            "cityName": "Lebakeng",
            "name": "[LEF] - Lebakeng - Lesotho",
            "searchName": "LEF-Lebakeng-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "LRB",
            "countryCode": "LS",
            "cityCode": null,
            "cityName": "Leribe",
            "name": "[LRB] - Leribe - Lesotho",
            "searchName": "LRB-Leribe-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "LES",
            "countryCode": "LS",
            "cityCode": null,
            "cityName": "Lesobeng",
            "name": "[LES] - Lesobeng - Lesotho",
            "searchName": "LES-Lesobeng-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "MFC",
            "countryCode": "LS",
            "cityCode": null,
            "cityName": "Mafeteng",
            "name": "[MFC] - Mafeteng - Lesotho",
            "searchName": "MFC-Mafeteng-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "SZI",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Zaisan",
            "name": "[SZI] - Zaisan - Kazakhstan",
            "searchName": "SZI-Zaisan-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "TDK",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Taldy-Kurgan",
            "name": "[TDK] - Taldy-Kurgan - Kazakhstan",
            "searchName": "TDK-Taldy-Kurgan-Taldy Kurgan-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "UKK",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Ust-kamenogorsk",
            "name": "[UKK] - Ust-kamenogorsk - Kazakhstan",
            "searchName": "UKK-Ust-Kamenogorsk-kamenogorsk-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "RSU",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Yeosu",
            "name": "[RSU] - Yeosu - South Korea",
            "searchName": "RSU-Yeosu-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "MWX",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Muan",
            "name": "[MWX] - Muan - South Korea",
            "searchName": "MWX-Muan-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "MPK",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Mokpo",
            "name": "[MPK] - Mokpo - South Korea",
            "searchName": "MPK-Mokpo-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "OSN",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Osan",
            "name": "[OSN] - Osan - South Korea",
            "searchName": "OSN-Osan-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "XIJ",
            "countryCode": "KW",
            "cityCode": null,
            "cityName": "Ahmed Al Jaber",
            "name": "[XIJ] - Ahmed Al Jaber - Kuwait",
            "searchName": "XIJ-Ahmed Al Jaber-Kuwait-Koeweit-Koweït-Kuvajt-Kuveitas-Kuweit-Kuveyt",
            "priority": 0
        },
        {
            "iataCode": "POT",
            "countryCode": "JM",
            "cityCode": null,
            "cityName": "Port Antonio",
            "name": "[POT] - Port Antonio - Jamaica",
            "searchName": "POT-Port Antonio-Jamaica-Jamaika-Jamajka-Jamaïque-Gia-Giamaica",
            "priority": 0
        },
        {
            "iataCode": "URA",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Uralsk",
            "name": "[URA] - Uralsk - Kazakhstan",
            "searchName": "URA-Uralsk-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "SCO",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Aktau",
            "name": "[SCO] - Aktau - Kazakhstan",
            "searchName": "SCO-Aktau-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "PPK",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Petropavlovsk",
            "name": "[PPK] - Petropavlovsk - Kazakhstan",
            "searchName": "PPK-Petropawl-Petropavlovsk-Petropavl-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "PLX",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Semipalatinsk",
            "name": "[PLX] - Semipalatinsk - Kazakhstan",
            "searchName": "PLX-Semei-Semipalatinsk-Seme-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "SQQ",
            "countryCode": "LT",
            "cityCode": null,
            "cityName": "Siauliai",
            "name": "[SQQ] - Siauliai - Lithuania",
            "searchName": "SQQ-Siauliai-Lituânia-Litouwen-Lithuania-Litauen-Lituanie-Lituania-Litwa-Leedu-Li-Litvanya",
            "priority": 0
        },
        {
            "iataCode": "PLQ",
            "countryCode": "LT",
            "cityCode": null,
            "cityName": "Klaipeda",
            "name": "[PLQ] - Klaipeda - Lithuania",
            "searchName": "PLQ-Klaipeda/Palanga-Klaipeda-Palanga-Lituânia-Litouwen-Lithuania-Litauen-Lituanie-Lituania-Litwa-Leedu-Li-Litvanya",
            "priority": 0
        },
        {
            "iataCode": "PNV",
            "countryCode": "LT",
            "cityCode": null,
            "cityName": "Panevezys",
            "name": "[PNV] - Panevezys - Lithuania",
            "searchName": "PNV-Panevezys-Lituânia-Litouwen-Lithuania-Litauen-Lituanie-Lituania-Litwa-Leedu-Li-Litvanya",
            "priority": 0
        },
        {
            "iataCode": "VNT",
            "countryCode": "LV",
            "cityCode": null,
            "cityName": "Ventspils",
            "name": "[VNT] - Ventspils - Latvia",
            "searchName": "VNT-Ventspils-Lettország-Letònia-Letonya-Lotyšsko-Latvia-Letland-Lotwa-Läti-Latvija-Lát via-Letonia-Lettland-Lettonia-L-Lettonie",
            "priority": 0
        },
        {
            "iataCode": "WAX",
            "countryCode": "LY",
            "cityCode": null,
            "cityName": "Zwara",
            "name": "[WAX] - Zwara - Libya",
            "searchName": "WAX-Zwara-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "SRX",
            "countryCode": "LY",
            "cityCode": null,
            "cityName": "Sert",
            "name": "[SRX] - Sert - Libya",
            "searchName": "SRX-Sert-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "DBU",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Dambula",
            "name": "[DBU] - Dambula - Sri Lanka",
            "searchName": "DBU-Dambula-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "DGM",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Dongguan",
            "name": "[DGM] - Dongguan - Sri Lanka",
            "searchName": "DGM-Dongguan-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "DIW",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Dickwella",
            "name": "[DIW] - Dickwella - Sri Lanka",
            "searchName": "DIW-Dickwella-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "CPA",
            "countryCode": "LR",
            "cityCode": null,
            "cityName": "Cape Palmas",
            "name": "[CPA] - Cape Palmas - Liberia",
            "searchName": "CPA-Cape Palmas-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "FOY",
            "countryCode": "LR",
            "cityCode": null,
            "cityName": "Foya",
            "name": "[FOY] - Foya - Liberia",
            "searchName": "FOY-Foya-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "BYL",
            "countryCode": "LR",
            "cityCode": null,
            "cityName": "Bella Yella",
            "name": "[BYL] - Bella Yella - Liberia",
            "searchName": "BYL-Bella Yella-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "GRC",
            "countryCode": "LR",
            "cityCode": null,
            "cityName": "Grand Cess",
            "name": "[GRC] - Grand Cess - Liberia",
            "searchName": "GRC-Grand Cess-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "UCN",
            "countryCode": "LR",
            "cityCode": null,
            "cityName": "Buchanan",
            "name": "[UCN] - Buchanan - Liberia",
            "searchName": "UCN-Buchanan-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "VOI",
            "countryCode": "LR",
            "cityCode": null,
            "cityName": "Voinjama",
            "name": "[VOI] - Voinjama - Liberia",
            "searchName": "VOI-Voinjama-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "THC",
            "countryCode": "LR",
            "cityCode": null,
            "cityName": "Tchien",
            "name": "[THC] - Tchien - Liberia",
            "searchName": "THC-Tchien-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "TPT",
            "countryCode": "LR",
            "cityCode": null,
            "cityName": "Tapeta",
            "name": "[TPT] - Tapeta - Liberia",
            "searchName": "TPT-Tapeta-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "MLW",
            "countryCode": "LR",
            "cityCode": null,
            "cityName": "Monrovia",
            "name": "[MLW] - Monrovia - Liberia",
            "searchName": "MLW-Monrovia-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "NIA",
            "countryCode": "LR",
            "cityCode": null,
            "cityName": "Nimba",
            "name": "[NIA] - Nimba - Liberia",
            "searchName": "NIA-Nimba-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "SNI",
            "countryCode": "LR",
            "cityCode": null,
            "cityName": "Sinoe",
            "name": "[SNI] - Sinoe - Liberia",
            "searchName": "SNI-Sinoe-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "SAZ",
            "countryCode": "LR",
            "cityCode": null,
            "cityName": "Sasstown",
            "name": "[SAZ] - Sasstown - Liberia",
            "searchName": "SAZ-Sasstown-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "RVC",
            "countryCode": "LR",
            "cityCode": null,
            "cityName": "Rivercess",
            "name": "[RVC] - Rivercess - Liberia",
            "searchName": "RVC-River Cess-Rivercess-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "SKQ",
            "countryCode": "LS",
            "cityCode": null,
            "cityName": "Sekakes",
            "name": "[SKQ] - Sekakes - Lesotho",
            "searchName": "SKQ-Sekakes-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "SOK",
            "countryCode": "LS",
            "cityCode": null,
            "cityName": "Semongkong",
            "name": "[SOK] - Semongkong - Lesotho",
            "searchName": "SOK-Semongkong-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "SHK",
            "countryCode": "LS",
            "cityCode": null,
            "cityName": "Sehonghong",
            "name": "[SHK] - Sehonghong - Lesotho",
            "searchName": "SHK-Sehonghong-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "SHZ",
            "countryCode": "LS",
            "cityCode": null,
            "cityName": "Seshutes",
            "name": "[SHZ] - Seshutes - Lesotho",
            "searchName": "SHZ-Seshutes-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "NKU",
            "countryCode": "LS",
            "cityCode": null,
            "cityName": "Nkaus",
            "name": "[NKU] - Nkaus - Lesotho",
            "searchName": "NKU-Nkaus-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "MKH",
            "countryCode": "LS",
            "cityCode": null,
            "cityName": "Mokhotlong",
            "name": "[MKH] - Mokhotlong - Lesotho",
            "searchName": "MKH-Mokhotlong-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "KYE",
            "countryCode": "LB",
            "cityCode": null,
            "cityName": "Tripoli",
            "name": "[KYE] - Tripoli - Lebanon",
            "searchName": "KYE-Tripoli-Liibanon-Libanon-Lübnan-Líban-Liban-Libanas-Libana-Lebanon-Libano-Líbano",
            "priority": 0
        },
        {
            "iataCode": "SLU",
            "countryCode": "LC",
            "cityCode": null,
            "cityName": "Castries",
            "name": "[SLU] - Castries - Saint Lucia",
            "searchName": "SLU-St Lucia-Castries-Saint Lucia-St. Lucia-Sainte-Lucie-Santa Lucía",
            "priority": 0
        },
        {
            "iataCode": "MNH",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Minneriya",
            "name": "[MNH] - Minneriya - Sri Lanka",
            "searchName": "MNH-Minneriya-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "NUA",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Nuwara Eliya",
            "name": "[NUA] - Nuwara Eliya - Sri Lanka",
            "searchName": "NUA-Nuwara Eliya-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "NUF",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Hatton",
            "name": "[NUF] - Hatton - Sri Lanka",
            "searchName": "NUF-Hatton-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "WRZ",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Wirawila",
            "name": "[WRZ] - Wirawila - Sri Lanka",
            "searchName": "WRZ-Wirawila-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "TRR",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Trincomalee",
            "name": "[TRR] - Trincomalee - Sri Lanka",
            "searchName": "TRR-Trincomalee-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "TTW",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Tissa Tank",
            "name": "[TTW] - Tissa Tank - Sri Lanka",
            "searchName": "TTW-Tissa Tank-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "KTY",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Katukurunda",
            "name": "[KTY] - Katukurunda - Sri Lanka",
            "searchName": "KTY-Katukurunda-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "KEZ",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Kelaniya",
            "name": "[KEZ] - Kelaniya - Sri Lanka",
            "searchName": "KEZ-Kelaniya-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "KCT",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Koggala",
            "name": "[KCT] - Koggala - Sri Lanka",
            "searchName": "KCT-Koggala-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "KDW",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Kandy",
            "name": "[KDW] - Kandy - Sri Lanka",
            "searchName": "KDW-Kandy-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "KDY",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Mahaweli",
            "name": "[KDY] - Mahaweli - Sri Lanka",
            "searchName": "KDY-Mahaweli-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "KDZ",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Katugastota",
            "name": "[KDZ] - Katugastota - Sri Lanka",
            "searchName": "KDZ-Katugastota-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "HIM",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Hingurakgoda",
            "name": "[HIM] - Hingurakgoda - Sri Lanka",
            "searchName": "HIM-Hingurakgoda-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "GIU",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Sigiriya",
            "name": "[GIU] - Sigiriya - Sri Lanka",
            "searchName": "GIU-Sigiriya-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "GOY",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Gal Oya",
            "name": "[GOY] - Gal Oya - Sri Lanka",
            "searchName": "GOY-Gal Oya-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "HRI",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Hambantota",
            "name": "[HRI] - Hambantota - Sri Lanka",
            "searchName": "HRI-Hafr Albatin-Hambantota-Hafar Albatin-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "JAF",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Jaffna",
            "name": "[JAF] - Jaffna - Sri Lanka",
            "searchName": "JAF-Jaffna-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "IRU",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Iranamadu",
            "name": "[IRU] - Iranamadu - Sri Lanka",
            "searchName": "IRU-Iranamadu-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "BJT",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Bentota River",
            "name": "[BJT] - Bentota River - Sri Lanka",
            "searchName": "BJT-Bentota River-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "BTC",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Batticaloa",
            "name": "[BTC] - Batticaloa - Sri Lanka",
            "searchName": "BTC-Batticaloa-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "AYY",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Pothuvil",
            "name": "[AYY] - Pothuvil - Sri Lanka",
            "searchName": "AYY-Pothuvil-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "ACJ",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Anuradhapura",
            "name": "[ACJ] - Anuradhapura - Sri Lanka",
            "searchName": "ACJ-Anuradhapura-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "ADP",
            "countryCode": "LK",
            "cityCode": null,
            "cityName": "Ampara",
            "name": "[ADP] - Ampara - Sri Lanka",
            "searchName": "ADP-Ampara-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "NEG",
            "countryCode": "JM",
            "cityCode": null,
            "cityName": "Negril",
            "name": "[NEG] - Negril - Jamaica",
            "searchName": "NEG-Negril-Jamaica-Jamaika-Jamajka-Jamaïque-Gia-Giamaica",
            "priority": 0
        },
        {
            "iataCode": "MVJ",
            "countryCode": "JM",
            "cityCode": null,
            "cityName": "Mandeville",
            "name": "[MVJ] - Mandeville - Jamaica",
            "searchName": "MVJ-Mandeville-Jamaica-Jamaika-Jamajka-Jamaïque-Gia-Giamaica",
            "priority": 0
        },
        {
            "iataCode": "MPQ",
            "countryCode": "JO",
            "cityCode": null,
            "cityName": "Maan",
            "name": "[MPQ] - Maan - Jordan",
            "searchName": "MPQ-Maan-Jordánia-Jordània-Ürdün-Jordan-Jordánsko-Jordania-Jordaania-Jordanija-Iordania-Yordania-Jordanien-Jordânia-Jordani-Jordanie-Giordania",
            "priority": 0
        },
        {
            "iataCode": "OMF",
            "countryCode": "JO",
            "cityCode": null,
            "cityName": "Mafraq",
            "name": "[OMF] - Mafraq - Jordan",
            "searchName": "OMF-Mafraq-Jordánia-Jordània-Ürdün-Jordan-Jordánsko-Jordania-Jordaania-Jordanija-Iordania-Yordania-Jordanien-Jordânia-Jordani-Jordanie-Giordania",
            "priority": 0
        },
        {
            "iataCode": "OSS",
            "countryCode": "KG",
            "cityCode": null,
            "cityName": "Osh",
            "name": "[OSS] - Osh - Kyrgyzstan",
            "searchName": "OSS-Osh-Kirgisistan-Kyrgyzstan-Kirgizistan-Kirghizistan-Kirguistán",
            "priority": 0
        },
        {
            "iataCode": "OMY",
            "countryCode": "KH",
            "cityCode": null,
            "cityName": "Oddor Meanche",
            "name": "[OMY] - Oddor Meanche - Cambodia",
            "searchName": "OMY-Oddor Meanchey-Oddor Meanche-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "MWV",
            "countryCode": "KH",
            "cityCode": null,
            "cityName": "Mundulkiri",
            "name": "[MWV] - Mundulkiri - Cambodia",
            "searchName": "MWV-Mundulkiri-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "RBE",
            "countryCode": "KH",
            "cityCode": null,
            "cityName": "Ratanakiri",
            "name": "[RBE] - Ratanakiri - Cambodia",
            "searchName": "RBE-Ratanankiri-Ratanakiri-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "LKU",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Lake Rudolf",
            "name": "[LKU] - Lake Rudolf - Kenya",
            "searchName": "LKU-Lake Rudolf-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "LKG",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Lokichoggio",
            "name": "[LKG] - Lokichoggio - Kenya",
            "searchName": "LKG-Lokichoggio-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "LOY",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Loyangalani",
            "name": "[LOY] - Loyangalani - Kenya",
            "searchName": "LOY-Loyangalani-Loiyangalani-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "LOK",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Lodwar",
            "name": "[LOK] - Lodwar - Kenya",
            "searchName": "LOK-Lodwar-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "EYS",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Eliye Springs",
            "name": "[EYS] - Eliye Springs - Kenya",
            "searchName": "EYS-Elive Springs-Eliye Springs-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "EDL",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Eldoret",
            "name": "[EDL] - Eldoret - Kenya",
            "searchName": "EDL-Eldoret-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "BMQ",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Bamburi",
            "name": "[BMQ] - Bamburi - Kenya",
            "searchName": "BMQ-Bamburi-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "ASV",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Amboseli",
            "name": "[ASV] - Amboseli - Kenya",
            "searchName": "ASV-Amboseli-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "MRE",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Mara Lodges",
            "name": "[MRE] - Mara Lodges - Kenya",
            "searchName": "MRE-Mara Lodges-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "OYL",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Moyale",
            "name": "[OYL] - Moyale - Kenya",
            "searchName": "OYL-Moyale-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "NUU",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Nakuru",
            "name": "[NUU] - Nakuru - Kenya",
            "searchName": "NUU-Nakuru-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "NYK",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Nanyuki",
            "name": "[NYK] - Nanyuki - Kenya",
            "searchName": "NYK-Nanyuki-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "NYE",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Nyeri",
            "name": "[NYE] - Nyeri - Kenya",
            "searchName": "NYE-Nyeri-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "GAS",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Garissa",
            "name": "[GAS] - Garissa - Kenya",
            "searchName": "GAS-Garissa-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "GGM",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Kakamega",
            "name": "[GGM] - Kakamega - Kenya",
            "searchName": "GGM-Kakamega-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "HOA",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Hola",
            "name": "[HOA] - Hola - Kenya",
            "searchName": "HOA-Hola-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "ILU",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Kilaguni",
            "name": "[ILU] - Kilaguni - Kenya",
            "searchName": "ILU-Kilaguni-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "JJM",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Meru-Kinna",
            "name": "[JJM] - Meru-Kinna - Kenya",
            "searchName": "JJM-Meru-Kinna-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "KTL",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Kitale",
            "name": "[KTL] - Kitale - Kenya",
            "searchName": "KTL-Kitale-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "KRV",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Kerio Valley",
            "name": "[KRV] - Kerio Valley - Kenya",
            "searchName": "KRV-Kerio Valley-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "LBK",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Liboi",
            "name": "[LBK] - Liboi - Kenya",
            "searchName": "LBK-Liboi-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "LBN",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Lake Baringo",
            "name": "[LBN] - Lake Baringo - Kenya",
            "searchName": "LBN-Lake Baringo-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "LAU",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Lamu",
            "name": "[LAU] - Lamu - Kenya",
            "searchName": "LAU-Lamu-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "KWY",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Kiwayu",
            "name": "[KWY] - Kiwayu - Kenya",
            "searchName": "KWY-Kiwayu-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "KEY",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Kericho",
            "name": "[KEY] - Kericho - Kenya",
            "searchName": "KEY-Kericho-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "KLK",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Kalokol",
            "name": "[KLK] - Kalokol - Kenya",
            "searchName": "KLK-Kalokol-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "KIS",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Kisumu",
            "name": "[KIS] - Kisumu - Kenya",
            "searchName": "KIS-Kisumu-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "KIU",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Kiunga",
            "name": "[KIU] - Kiunga - Kenya",
            "searchName": "KIU-Kiunga-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "LYB",
            "countryCode": "KY",
            "cityCode": null,
            "cityName": "Little Cayman",
            "name": "[LYB] - Little Cayman - Cayman Islands",
            "searchName": "LYB-Little Cayman-Cayman Islands-Kaimaninseln-Cayman Adalari-Islas Caimán-Îles Caïmans-Isole Cayman",
            "priority": 0
        },
        {
            "iataCode": "GCM",
            "countryCode": "KY",
            "cityCode": null,
            "cityName": "Georgetown",
            "name": "[GCM] - Georgetown - Cayman Islands",
            "searchName": "GCM-Grand Cayman Island-Georgetown--Cayman Islands-Kaimaninseln-Cayman Adalari-Islas Caimán-Îles Caïmans-Isole Cayman",
            "priority": 0
        },
        {
            "iataCode": "CYB",
            "countryCode": "KY",
            "cityCode": null,
            "cityName": "Cayman Brac Island",
            "name": "[CYB] - Cayman Brac Island - Cayman Islands",
            "searchName": "CYB-Cayman Brac-Cayman Brac Island-Cayman Brac Is-Cayman Islands-Kaimaninseln-Cayman Adalari-Islas Caimán-Îles Caïmans-Isole Cayman",
            "priority": 0
        },
        {
            "iataCode": "CIT",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Shimkent",
            "name": "[CIT] - Shimkent - Kazakhstan",
            "searchName": "CIT-Shimkent-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "DMB",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Zhambyl",
            "name": "[DMB] - Zhambyl - Kazakhstan",
            "searchName": "DMB-Zhambyl-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "DZN",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Zhezkazgan",
            "name": "[DZN] - Zhezkazgan - Kazakhstan",
            "searchName": "DZN-Zhezkazgan-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "EKB",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Ekibastuz",
            "name": "[EKB] - Ekibastuz - Kazakhstan",
            "searchName": "EKB-Ekibastuz-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "AKX",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Aktyubinsk",
            "name": "[AKX] - Aktyubinsk - Kazakhstan",
            "searchName": "AKX-Aktyubinsk-Aktepe-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "AYK",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Arkalyk",
            "name": "[AYK] - Arkalyk - Kazakhstan",
            "searchName": "AYK-Arkalyk-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "ATX",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Atbasar",
            "name": "[ATX] - Atbasar - Kazakhstan",
            "searchName": "ATX-Atbasar-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "BXH",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Balhash",
            "name": "[BXH] - Balhash - Kazakhstan",
            "searchName": "BXH-Balhash-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "GUW",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Atyrau",
            "name": "[GUW] - Atyrau - Kazakhstan",
            "searchName": "GUW-Atyrau-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "HRC",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Zhairem",
            "name": "[HRC] - Zhairem - Kazakhstan",
            "searchName": "HRC-Zhairem-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "KOV",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Kokshetau",
            "name": "[KOV] - Kokshetau - Kazakhstan",
            "searchName": "KOV-Kokshetau-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "KSN",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Kostanay",
            "name": "[KSN] - Kostanay - Kazakhstan",
            "searchName": "KSN-Kostanai-Kostanay-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "KZO",
            "countryCode": "KZ",
            "cityCode": null,
            "cityName": "Kzyl-Orda",
            "name": "[KZO] - Kzyl-Orda - Kazakhstan",
            "searchName": "KZO-Kzyl-Orda-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "PKS",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Paksane",
            "name": "[PKS] - Paksane - Laos",
            "searchName": "PKS-Paksane-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "PKZ",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Pakse",
            "name": "[PKZ] - Pakse - Laos",
            "searchName": "PKZ-Pakx-Pakse-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "SND",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Seno",
            "name": "[SND] - Seno - Laos",
            "searchName": "SND-Seno-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "OUI",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Ban Houei",
            "name": "[OUI] - Ban Houei - Laos",
            "searchName": "OUI-Ban Houei-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "PCQ",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Phongsaly",
            "name": "[PCQ] - Phongsaly - Laos",
            "searchName": "PCQ-Phongsaly-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ODY",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Oudomxay",
            "name": "[ODY] - Oudomxay - Laos",
            "searchName": "ODY-Oudomxay-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NEU",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Sam Neua",
            "name": "[NEU] - Sam Neua - Laos",
            "searchName": "NEU-Sam Neua-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "VGG",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Vangrieng",
            "name": "[VGG] - Vangrieng - Laos",
            "searchName": "VGG-Vangrieng-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "VNA",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Saravane",
            "name": "[VNA] - Saravane - Laos",
            "searchName": "VNA-Saravane-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "VNG",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Viengxay",
            "name": "[VNG] - Viengxay - Laos",
            "searchName": "VNG-Viengxay-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "VTE",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Vientiane",
            "name": "[VTE] - Vientiane - Laos",
            "searchName": "VTE-Vientiane-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "UDO",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Udomxay",
            "name": "[UDO] - Udomxay - Laos",
            "searchName": "UDO-Udomxay-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "UON",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Muong Sai",
            "name": "[UON] - Muong Sai - Laos",
            "searchName": "UON-Muong Sai-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "THK",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Thakhek",
            "name": "[THK] - Thakhek - Laos",
            "searchName": "THK-Thakhek-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XIE",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Xienglom",
            "name": "[XIE] - Xienglom - Laos",
            "searchName": "XIE-Xienglom-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XKH",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Xieng Khouang",
            "name": "[XKH] - Xieng Khouang - Laos",
            "searchName": "XKH-Xieng Khouang-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XAY",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Xayabury",
            "name": "[XAY] - Xayabury - Laos",
            "searchName": "XAY-Xayabury-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZVK",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Savannakhet",
            "name": "[ZVK] - Savannakhet - Laos",
            "searchName": "ZVK-Savannakhet-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZBY",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Sayaboury",
            "name": "[ZBY] - Sayaboury - Laos",
            "searchName": "ZBY-Sayaboury-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KOG",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Khong",
            "name": "[KOG] - Khong - Laos",
            "searchName": "KOG-Khong-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LXG",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Luang Namtha",
            "name": "[LXG] - Luang Namtha - Laos",
            "searchName": "LXG-Luang Namtha-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LPQ",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Luang Prabang",
            "name": "[LPQ] - Luang Prabang - Laos",
            "searchName": "LPQ-Luang Prabang-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HOE",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Houeisay",
            "name": "[HOE] - Houeisay - Laos",
            "searchName": "HOE-Houeisay-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "AOU",
            "countryCode": "LA",
            "cityCode": null,
            "cityName": "Attopeu",
            "name": "[AOU] - Attopeu - Laos",
            "searchName": "AOU-Attopeu-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "SEB",
            "countryCode": "LY",
            "cityCode": null,
            "cityName": "Sebha",
            "name": "[SEB] - Sebha - Libya",
            "searchName": "SEB-Sebha-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "QUB",
            "countryCode": "LY",
            "cityCode": null,
            "cityName": "Ubari",
            "name": "[QUB] - Ubari - Libya",
            "searchName": "QUB-Ubari-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "MRA",
            "countryCode": "LY",
            "cityCode": null,
            "cityName": "Misurata",
            "name": "[MRA] - Misurata - Libya",
            "searchName": "MRA-Misurata-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "NFR",
            "countryCode": "LY",
            "cityCode": null,
            "cityName": "Nafoora",
            "name": "[NFR] - Nafoora - Libya",
            "searchName": "NFR-Nafoora-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "DNF",
            "countryCode": "LY",
            "cityCode": null,
            "cityName": "Derna",
            "name": "[DNF] - Derna - Libya",
            "searchName": "DNF-Derna-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "BEN",
            "countryCode": "LY",
            "cityCode": null,
            "cityName": "Benghazi",
            "name": "[BEN] - Benghazi - Libya",
            "searchName": "BEN-Banghazi-Benghazi-Bengasi-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "AKF",
            "countryCode": "LY",
            "cityCode": null,
            "cityName": "Kufrah",
            "name": "[AKF] - Kufrah - Libya",
            "searchName": "AKF-Kufrah-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "BCQ",
            "countryCode": "LY",
            "cityCode": null,
            "cityName": "Brack",
            "name": "[BCQ] - Brack - Libya",
            "searchName": "BCQ-Brack-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "LTD",
            "countryCode": "LY",
            "cityCode": null,
            "cityName": "Ghadames",
            "name": "[LTD] - Ghadames - Libya",
            "searchName": "LTD-Ghadames-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "LMQ",
            "countryCode": "LY",
            "cityCode": null,
            "cityName": "Marsa Brega",
            "name": "[LMQ] - Marsa Brega - Libya",
            "searchName": "LMQ-Marsa Brega-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "LAQ",
            "countryCode": "LY",
            "cityCode": null,
            "cityName": "Beida",
            "name": "[LAQ] - Beida - Libya",
            "searchName": "LAQ-Beida-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "GHT",
            "countryCode": "LY",
            "cityCode": null,
            "cityName": "Ghat",
            "name": "[GHT] - Ghat - Libya",
            "searchName": "GHT-Ghat-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "HUQ",
            "countryCode": "LY",
            "cityCode": null,
            "cityName": "Houn",
            "name": "[HUQ] - Houn - Libya",
            "searchName": "HUQ-Houn-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "GLN",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Goulimime",
            "name": "[GLN] - Goulimime - Morocco",
            "searchName": "GLN-Goulimime-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "GMD",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Ben Slimane",
            "name": "[GMD] - Ben Slimane - Morocco",
            "searchName": "GMD-Ben Slimane-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "MEK",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Meknes",
            "name": "[MEK] - Meknes - Morocco",
            "searchName": "MEK-Meknes-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "AHU",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Al Hoceima",
            "name": "[AHU] - Al Hoceima - Morocco",
            "searchName": "AHU-Al Hoceima-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "AGA",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Agadir",
            "name": "[AGA] - Agadir - Morocco",
            "searchName": "AGA-Agadir-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "FEZ",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Fez",
            "name": "[FEZ] - Fez - Morocco",
            "searchName": "FEZ-F-Fez-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "EUN",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Laayoune",
            "name": "[EUN] - Laayoune - Morocco",
            "searchName": "EUN-Laayoune-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "ESU",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Essaouira",
            "name": "[ESU] - Essaouira - Morocco",
            "searchName": "ESU-Essaouira-Suvayr-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "ERH",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Er-Rachidia",
            "name": "[ERH] - Er-Rachidia - Morocco",
            "searchName": "ERH-Errachidia-Er-Rachidia-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "NDR",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Nador",
            "name": "[NDR] - Nador - Morocco",
            "searchName": "NDR-Nador-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "NNA",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Kenitra",
            "name": "[NNA] - Kenitra - Morocco",
            "searchName": "NNA-Kenitra-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "OZZ",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Ouarzazate",
            "name": "[OZZ] - Ouarzazate - Morocco",
            "searchName": "OZZ-Ouarzazate-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "OUD",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Oujda",
            "name": "[OUD] - Oujda - Morocco",
            "searchName": "OUD-Oujda-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "OZG",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Zagora",
            "name": "[OZG] - Zagora - Morocco",
            "searchName": "OZG-Zagora-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "RBA",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Rabat",
            "name": "[RBA] - Rabat - Morocco",
            "searchName": "RBA-Rabat-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "LPX",
            "countryCode": "LV",
            "cityCode": null,
            "cityName": "Liepaja",
            "name": "[LPX] - Liepaja - Latvia",
            "searchName": "LPX-Liepaya-Liepaja-Lettország-Letònia-Letonya-Lotyšsko-Latvia-Letland-Lotwa-Läti-Latvija-Lát via-Letonia-Lettland-Lettonia-L-Lettonie",
            "priority": 0
        },
        {
            "iataCode": "DGP",
            "countryCode": "LV",
            "cityCode": null,
            "cityName": "Daugavpils",
            "name": "[DGP] - Daugavpils - Latvia",
            "searchName": "DGP-Daugavpils-Lettország-Letònia-Letonya-Lotyšsko-Latvia-Letland-Lotwa-Läti-Latvija-Lát via-Letonia-Lettland-Lettonia-L-Lettonie",
            "priority": 0
        },
        {
            "iataCode": "BZY",
            "countryCode": "MD",
            "cityCode": null,
            "cityName": "Beltsy",
            "name": "[BZY] - Beltsy - Moldova",
            "searchName": "BZY-Beltsy-Moldova-Moldàvia-Moldavsko-Moldawia-Moldavien-Moldovos-Moldavija-Moldawien-Moldavia-Moldavie",
            "priority": 0
        },
        {
            "iataCode": "IVG",
            "countryCode": "ME",
            "cityCode": null,
            "cityName": "Berane",
            "name": "[IVG] - Berane - Montenegro",
            "searchName": "IVG-Ivangrad-Berane-Montenegro-Cerná Hora-Karadag-Montenegró-Monténégro-Muntenegru-Crna Gora-Juodkalnija-Melnkalne",
            "priority": 0
        },
        {
            "iataCode": "SII",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Sidi Ifni",
            "name": "[SII] - Sidi Ifni - Morocco",
            "searchName": "SII-Sidi Ifni-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "SMW",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Smara",
            "name": "[SMW] - Smara - Morocco",
            "searchName": "SMW-Smara-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "TNG",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Tangier",
            "name": "[TNG] - Tangier - Morocco",
            "searchName": "TNG-Tanger-Tangier-T-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "VIL",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Dakhla",
            "name": "[VIL] - Dakhla - Morocco",
            "searchName": "VIL-Dakhla-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "TTU",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Tetuan",
            "name": "[TTU] - Tetuan - Morocco",
            "searchName": "TTU-T-Tetuan-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "TTA",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Tan Tan",
            "name": "[TTA] - Tan Tan - Morocco",
            "searchName": "TTA-Tan Tan-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "UAR",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Bouarfa",
            "name": "[UAR] - Bouarfa - Morocco",
            "searchName": "UAR-Bouarfa-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "NRM",
            "countryCode": "ML",
            "cityCode": null,
            "cityName": "Nara",
            "name": "[NRM] - Nara - Mali",
            "searchName": "NRM-Nara-Mali",
            "priority": 0
        },
        {
            "iataCode": "NIX",
            "countryCode": "ML",
            "cityCode": null,
            "cityName": "Nioro",
            "name": "[NIX] - Nioro - Mali",
            "searchName": "NIX-Nioro-Mali",
            "priority": 0
        },
        {
            "iataCode": "MZI",
            "countryCode": "ML",
            "cityCode": null,
            "cityName": "Mopti",
            "name": "[MZI] - Mopti - Mali",
            "searchName": "MZI-Mopti-Mali",
            "priority": 0
        },
        {
            "iataCode": "TOM",
            "countryCode": "ML",
            "cityCode": null,
            "cityName": "Tombouctou",
            "name": "[TOM] - Tombouctou - Mali",
            "searchName": "TOM-Timbuktu-Tombouctou-Mali",
            "priority": 0
        },
        {
            "iataCode": "SZU",
            "countryCode": "ML",
            "cityCode": null,
            "cityName": "Segou",
            "name": "[SZU] - Segou - Mali",
            "searchName": "SZU-Segou-Mali",
            "priority": 0
        },
        {
            "iataCode": "GAQ",
            "countryCode": "ML",
            "cityCode": null,
            "cityName": "Gao",
            "name": "[GAQ] - Gao - Mali",
            "searchName": "GAQ-Gao-Mali",
            "priority": 0
        },
        {
            "iataCode": "GUD",
            "countryCode": "ML",
            "cityCode": null,
            "cityName": "Goundam",
            "name": "[GUD] - Goundam - Mali",
            "searchName": "GUD-Goundam-Mali",
            "priority": 0
        },
        {
            "iataCode": "KTX",
            "countryCode": "ML",
            "cityCode": null,
            "cityName": "Koutiala",
            "name": "[KTX] - Koutiala - Mali",
            "searchName": "KTX-Koutiala-Mali",
            "priority": 0
        },
        {
            "iataCode": "KSS",
            "countryCode": "ML",
            "cityCode": null,
            "cityName": "Sikasso",
            "name": "[KSS] - Sikasso - Mali",
            "searchName": "KSS-Sikasso-Mali",
            "priority": 0
        },
        {
            "iataCode": "KYS",
            "countryCode": "ML",
            "cityCode": null,
            "cityName": "Kayes",
            "name": "[KYS] - Kayes - Mali",
            "searchName": "KYS-Kayes-Mali",
            "priority": 0
        },
        {
            "iataCode": "KNZ",
            "countryCode": "ML",
            "cityCode": null,
            "cityName": "Kenieba",
            "name": "[KNZ] - Kenieba - Mali",
            "searchName": "KNZ-Kenieba-Mali",
            "priority": 0
        },
        {
            "iataCode": "BKO",
            "countryCode": "ML",
            "cityCode": null,
            "cityName": "Bamako",
            "name": "[BKO] - Bamako - Mali",
            "searchName": "BKO-Bamako-Mali",
            "priority": 0
        },
        {
            "iataCode": "EYL",
            "countryCode": "ML",
            "cityCode": null,
            "cityName": "Yelimane",
            "name": "[EYL] - Yelimane - Mali",
            "searchName": "EYL-Yelimane-Mali",
            "priority": 0
        },
        {
            "iataCode": "BMO",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Bhamo",
            "name": "[BMO] - Bhamo - Myanmar",
            "searchName": "BMO-Bhamo-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "BPE",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Bagan",
            "name": "[BPE] - Bagan - Myanmar",
            "searchName": "BPE-Bagan-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "BSX",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Bassein",
            "name": "[BSX] - Bassein - Myanmar",
            "searchName": "BSX-Bassein-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "AKY",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Sittwe",
            "name": "[AKY] - Sittwe - Myanmar",
            "searchName": "AKY-Sittwe-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "KMV",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Kalaymyo",
            "name": "[KMV] - Kalaymyo - Myanmar",
            "searchName": "KMV-Kalaymyo-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "KAW",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Kawthaung",
            "name": "[KAW] - Kawthaung - Myanmar",
            "searchName": "KAW-Kawthaung-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "KET",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Keng Tung",
            "name": "[KET] - Keng Tung - Myanmar",
            "searchName": "KET-Keng Tung-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "KHM",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Khamti",
            "name": "[KHM] - Khamti - Myanmar",
            "searchName": "KHM-Khamtis-Khamti-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "KYT",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Kyauktaw",
            "name": "[KYT] - Kyauktaw - Myanmar",
            "searchName": "KYT-Kyauktaw-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "KYP",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Kyaukpyu",
            "name": "[KYP] - Kyaukpyu - Myanmar",
            "searchName": "KYP-Kyaukpyu-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "LSH",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Lashio",
            "name": "[LSH] - Lashio - Myanmar",
            "searchName": "LSH-Lashio-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "LIW",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Loikaw",
            "name": "[LIW] - Loikaw - Myanmar",
            "searchName": "LIW-Loikaw-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "MDL",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Mandalay",
            "name": "[MDL] - Mandalay - Myanmar",
            "searchName": "MDL-Mandalay-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "MGZ",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Myeik",
            "name": "[MGZ] - Myeik - Myanmar",
            "searchName": "MGZ-Myeik-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "MGU",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Manaung",
            "name": "[MGU] - Manaung - Myanmar",
            "searchName": "MGU-Manaung-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "MGK",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Mong Ton",
            "name": "[MGK] - Mong Ton - Myanmar",
            "searchName": "MGK-Mong Ton-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "GWA",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Gwa",
            "name": "[GWA] - Gwa - Myanmar",
            "searchName": "GWA-Gwa-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "HEB",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Henzada",
            "name": "[HEB] - Henzada - Myanmar",
            "searchName": "HEB-Henzada-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "HEH",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Heho",
            "name": "[HEH] - Heho - Myanmar",
            "searchName": "HEH-Heho-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "GAW",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Gangaw",
            "name": "[GAW] - Gangaw - Myanmar",
            "searchName": "GAW-Gangaw-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "HOX",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Homalin",
            "name": "[HOX] - Homalin - Myanmar",
            "searchName": "HOX-Homalin-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "TIO",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Tilin",
            "name": "[TIO] - Tilin - Myanmar",
            "searchName": "TIO-Tilin-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "THL",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Tachilek",
            "name": "[THL] - Tachilek - Myanmar",
            "searchName": "THL-Tachilek-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "TVY",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Dawe",
            "name": "[TVY] - Dawe - Myanmar",
            "searchName": "TVY-Dawe-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "XYE",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Ye",
            "name": "[XYE] - Ye - Myanmar",
            "searchName": "XYE-Ye-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "MYT",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Myitkyina",
            "name": "[MYT] - Myitkyina - Myanmar",
            "searchName": "MYT-Myitkyina-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "MWQ",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Magwe",
            "name": "[MWQ] - Magwe - Myanmar",
            "searchName": "MWQ-Magwe-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "NMS",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Namsang",
            "name": "[NMS] - Namsang - Myanmar",
            "searchName": "NMS-Namsang-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "NMT",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Namtu",
            "name": "[NMT] - Namtu - Myanmar",
            "searchName": "NMT-Namtu-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "MOE",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Momeik",
            "name": "[MOE] - Momeik - Myanmar",
            "searchName": "MOE-Momeik-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "MOG",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Mong Hsat",
            "name": "[MOG] - Mong Hsat - Myanmar",
            "searchName": "MOG-Mong Hsat-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "MNU",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Maulmyine",
            "name": "[MNU] - Maulmyine - Myanmar",
            "searchName": "MNU-Maulmyine-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "NYT",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Nay Pyi Taw",
            "name": "[NYT] - Nay Pyi Taw - Myanmar",
            "searchName": "NYT-Nay Pyi Taw-Naypyidaw-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "NYU",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Nyaung-u",
            "name": "[NYU] - Nyaung-u - Myanmar",
            "searchName": "NYU-Nyaung-u-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "PAA",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Pa-an",
            "name": "[PAA] - Pa-an - Myanmar",
            "searchName": "PAA-Pa An-Pa-an-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "PAU",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Pauk",
            "name": "[PAU] - Pauk - Myanmar",
            "searchName": "PAU-Pauk-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "PBU",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Putao",
            "name": "[PBU] - Putao - Myanmar",
            "searchName": "PBU-Putao-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "SNW",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Thandwe",
            "name": "[SNW] - Thandwe - Myanmar",
            "searchName": "SNW-Thandwe-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "RGN",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Yangon",
            "name": "[RGN] - Yangon - Myanmar",
            "searchName": "RGN-Rangun-Yangon-Rangoon-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "PKK",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Pakokku",
            "name": "[PKK] - Pakokku - Myanmar",
            "searchName": "PKK-Pakokku-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "PPU",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Papun",
            "name": "[PPU] - Papun - Myanmar",
            "searchName": "PPU-Papun-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "PRU",
            "countryCode": "MM",
            "cityCode": null,
            "cityName": "Prome",
            "name": "[PRU] - Prome - Myanmar",
            "searchName": "PRU-Prome-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "MXV",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Moron",
            "name": "[MXV] - Moron - Mongolia",
            "searchName": "MXV-Moron-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "MXW",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Mandalgobi",
            "name": "[MXW] - Mandalgobi - Mongolia",
            "searchName": "MXW-Mandalgobi-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "TSZ",
            "countryCode": "MN",
            "cityCode": null,
            "cityName": "Tsetserleg",
            "name": "[TSZ] - Tsetserleg - Mongolia",
            "searchName": "TSZ-Tsetserleg-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "ZBK",
            "countryCode": "ME",
            "cityCode": null,
            "cityName": "Zabljak",
            "name": "[ZBK] - Zabljak - Montenegro",
            "searchName": "ZBK-Zabljak-Montenegro-Cerná Hora-Karadag-Montenegró-Monténégro-Muntenegru-Crna Gora-Juodkalnija-Melnkalne",
            "priority": 0
        },
        {
            "iataCode": "SFG",
            "countryCode": "MF",
            "cityCode": null,
            "cityName": "St Martin",
            "name": "[SFG] - St Martin - Saint Martin",
            "searchName": "SFG-Saint Martin-St Martin-Grand Case-San Martín-Saint-Martin (partie française)",
            "priority": 0
        },
        {
            "iataCode": "SMS",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Sainte Marie",
            "name": "[SMS] - Sainte Marie - Madagascar",
            "searchName": "SMS-Ile Sainte-Marie-Sainte Marie-Sante Marie-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "RVA",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Farafangana",
            "name": "[RVA] - Farafangana - Madagascar",
            "searchName": "RVA-Farafangana-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "RLR",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Isalo",
            "name": "[RLR] - Isalo - Madagascar",
            "searchName": "RLR-Isalo-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "OVA",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Bekily",
            "name": "[OVA] - Bekily - Madagascar",
            "searchName": "OVA-Bekily-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "NOS",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Nossi-Be",
            "name": "[NOS] - Nossi-Be - Madagascar",
            "searchName": "NOS-Nossi-be-Be-Nosy Be-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "OHB",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Moramanga",
            "name": "[OHB] - Moramanga - Madagascar",
            "searchName": "OHB-Moramanga-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "NKO",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Ankokoambo",
            "name": "[NKO] - Ankokoambo - Madagascar",
            "searchName": "NKO-Ankokoambo-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "MXM",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Morombe",
            "name": "[MXM] - Morombe - Madagascar",
            "searchName": "MXM-Morombe-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "MXT",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Maintirano",
            "name": "[MXT] - Maintirano - Madagascar",
            "searchName": "MXT-Maintirano-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "MNJ",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Mananjary",
            "name": "[MNJ] - Mananjary - Madagascar",
            "searchName": "MNJ-Mananjary-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "MOQ",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Morondava",
            "name": "[MOQ] - Morondava - Madagascar",
            "searchName": "MOQ-Morondava-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "ZWA",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Andapa",
            "name": "[ZWA] - Andapa - Madagascar",
            "searchName": "ZWA-Andapa-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "ZVA",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Miandrivazo",
            "name": "[ZVA] - Miandrivazo - Madagascar",
            "searchName": "ZVA-Miandrivazo-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WPB",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Port Berge",
            "name": "[WPB] - Port Berge - Madagascar",
            "searchName": "WPB-Port Berge-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WOR",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Ankorefo",
            "name": "[WOR] - Ankorefo - Madagascar",
            "searchName": "WOR-Ankorefo-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WTA",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Tambohorano",
            "name": "[WTA] - Tambohorano - Madagascar",
            "searchName": "WTA-Tambohorano-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WTS",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Tsiroanomandidy",
            "name": "[WTS] - Tsiroanomandidy - Madagascar",
            "searchName": "WTS-Tsiroanomandidy-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WVK",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Manakara",
            "name": "[WVK] - Manakara - Madagascar",
            "searchName": "WVK-Manakara-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WMP",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Mampikony",
            "name": "[WMP] - Mampikony - Madagascar",
            "searchName": "WMP-Mampikony-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WMR",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Mananara",
            "name": "[WMR] - Mananara - Madagascar",
            "searchName": "WMR-Mananara-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WMV",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Madirovalo",
            "name": "[WMV] - Madirovalo - Madagascar",
            "searchName": "WMV-Madirovalo-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WMA",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Mandritsara",
            "name": "[WMA] - Mandritsara - Madagascar",
            "searchName": "WMA-Mandritsara-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WMD",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Mandabe",
            "name": "[WMD] - Mandabe - Madagascar",
            "searchName": "WMD-Mandabe-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WML",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Malaimbandy",
            "name": "[WML] - Malaimbandy - Madagascar",
            "searchName": "WML-Malaimbandy-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WMN",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Maroantsetra",
            "name": "[WMN] - Maroantsetra - Madagascar",
            "searchName": "WMN-Maroantsetra-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "TDV",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Tanandava",
            "name": "[TDV] - Tanandava - Madagascar",
            "searchName": "TDV-Tanandava-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "TMM",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Tamatave",
            "name": "[TMM] - Tamatave - Madagascar",
            "searchName": "TMM-Tamatave-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "TLE",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Tulear",
            "name": "[TLE] - Tulear - Madagascar",
            "searchName": "TLE-Tulear-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "TNR",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Antananarivo",
            "name": "[TNR] - Antananarivo - Madagascar",
            "searchName": "TNR-Antananarivo-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "SVB",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Sambava",
            "name": "[SVB] - Sambava - Madagascar",
            "searchName": "SVB-Sambava-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "TZO",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Ankisatra",
            "name": "[TZO] - Ankisatra - Madagascar",
            "searchName": "TZO-Ankisatra-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "TVA",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Morafenobe",
            "name": "[TVA] - Morafenobe - Madagascar",
            "searchName": "TVA-Morafenobe-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "TTS",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Tsaratanana",
            "name": "[TTS] - Tsaratanana - Madagascar",
            "searchName": "TTS-Tsaratanana-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "VAT",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Vatomandry",
            "name": "[VAT] - Vatomandry - Madagascar",
            "searchName": "VAT-Vatomandry-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WBD",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Befandriana",
            "name": "[WBD] - Befandriana - Madagascar",
            "searchName": "WBD-Befandriana-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WBE",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Bealanana",
            "name": "[WBE] - Bealanana - Madagascar",
            "searchName": "WBE-Bealanana-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WBO",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Beroroha",
            "name": "[WBO] - Beroroha - Madagascar",
            "searchName": "WBO-Beroroha-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WAQ",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Antsalova",
            "name": "[WAQ] - Antsalova - Madagascar",
            "searchName": "WAQ-Antsalova-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WAI",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Antsohihy",
            "name": "[WAI] - Antsohihy - Madagascar",
            "searchName": "WAI-Antsohihy-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WAK",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Ankazoabo",
            "name": "[WAK] - Ankazoabo - Madagascar",
            "searchName": "WAK-Ankazoabo-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WAM",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Ambatondrazaka",
            "name": "[WAM] - Ambatondrazaka - Madagascar",
            "searchName": "WAM-Ambatondrazaka-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WFI",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Fianarantsoa",
            "name": "[WFI] - Fianarantsoa - Madagascar",
            "searchName": "WFI-Fianarantsoa-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "VND",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Vangaindrano",
            "name": "[VND] - Vangaindrano - Madagascar",
            "searchName": "VND-Vangaindrano-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "VOH",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Vohemar",
            "name": "[VOH] - Vohemar - Madagascar",
            "searchName": "VOH-Vohemar-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WAD",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Andriamena",
            "name": "[WAD] - Andriamena - Madagascar",
            "searchName": "WAD-Andriamena-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "VVB",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Mahanoro",
            "name": "[VVB] - Mahanoro - Madagascar",
            "searchName": "VVB-Mahanoro-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "IVA",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Ambanja",
            "name": "[IVA] - Ambanja - Madagascar",
            "searchName": "IVA-Ambanja-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "JVA",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Ankavandra",
            "name": "[JVA] - Ankavandra - Madagascar",
            "searchName": "JVA-Ankavandra-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "HVA",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Analalava",
            "name": "[HVA] - Analalava - Madagascar",
            "searchName": "HVA-Analalava-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "IHO",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Ihosy",
            "name": "[IHO] - Ihosy - Madagascar",
            "searchName": "IHO-Ihosy-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "ILK",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Ilaka",
            "name": "[ILK] - Ilaka - Madagascar",
            "searchName": "ILK-Ilaka-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "FTU",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Fort Dauphin",
            "name": "[FTU] - Fort Dauphin - Madagascar",
            "searchName": "FTU-Fort Dauphin-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "MJN",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Majunga",
            "name": "[MJN] - Majunga - Madagascar",
            "searchName": "MJN-Majunga-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "MJA",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Manja",
            "name": "[MJA] - Manja - Madagascar",
            "searchName": "MJA-Manja-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "BZM",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Bergen Op Zoom",
            "name": "[BZM] - Bergen Op Zoom - Madagascar",
            "searchName": "BZM-Bergen Op Zoom-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "BPY",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Besalampy",
            "name": "[BPY] - Besalampy - Madagascar",
            "searchName": "BPY-Besalampy-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "BSV",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Besakoa",
            "name": "[BSV] - Besakoa - Madagascar",
            "searchName": "BSV-Besakoa-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "BKU",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Betioky",
            "name": "[BKU] - Betioky - Madagascar",
            "searchName": "BKU-Betioky-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "BMD",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Belo",
            "name": "[BMD] - Belo - Madagascar",
            "searchName": "BMD-Belo-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "AHY",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Ambatolahy",
            "name": "[AHY] - Ambatolahy - Madagascar",
            "searchName": "AHY-Ambatolahy-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "AMP",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Ampanihy",
            "name": "[AMP] - Ampanihy - Madagascar",
            "searchName": "AMP-Ampanihy-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "AMB",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Ambilobe",
            "name": "[AMB] - Ambilobe - Madagascar",
            "searchName": "AMB-Ambilobe-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "AMY",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Ambatomainty",
            "name": "[AMY] - Ambatomainty - Madagascar",
            "searchName": "AMY-Ambatomainty-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "ANM",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Antalaha",
            "name": "[ANM] - Antalaha - Madagascar",
            "searchName": "ANM-Antalaha-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "ATJ",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Antsirabe",
            "name": "[ATJ] - Antsirabe - Madagascar",
            "searchName": "ATJ-Antsirabe-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "DOA",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Doany",
            "name": "[DOA] - Doany - Madagascar",
            "searchName": "DOA-Doany-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "DVD",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Andavadoaka",
            "name": "[DVD] - Andavadoaka - Madagascar",
            "searchName": "DVD-Andavadoaka-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "DWB",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Soalala",
            "name": "[DWB] - Soalala - Madagascar",
            "searchName": "DWB-Soalala-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "DIE",
            "countryCode": "MG",
            "cityCode": null,
            "cityName": "Antsiranana",
            "name": "[DIE] - Antsiranana - Madagascar",
            "searchName": "DIE-Antsiranana-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "EAL",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Kwajalein Atoll",
            "name": "[EAL] - Kwajalein Atoll - Marshall Islands",
            "searchName": "EAL-Kwajalein Atoll-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "EBN",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Ebadon",
            "name": "[EBN] - Ebadon - Marshall Islands",
            "searchName": "EBN-Ebadon-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "EBO",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Ebon",
            "name": "[EBO] - Ebon - Marshall Islands",
            "searchName": "EBO-Ebon-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "EJT",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Mili Atoll",
            "name": "[EJT] - Mili Atoll - Marshall Islands",
            "searchName": "EJT-Mili Atoll-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "ENT",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Enewetak Island",
            "name": "[ENT] - Enewetak Island - Marshall Islands",
            "searchName": "ENT-Enewetak Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "AUL",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Aur Island",
            "name": "[AUL] - Aur Island - Marshall Islands",
            "searchName": "AUL-Aur Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "AMR",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Arno",
            "name": "[AMR] - Arno - Marshall Islands",
            "searchName": "AMR-Arno-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "AIC",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Airok",
            "name": "[AIC] - Airok - Marshall Islands",
            "searchName": "AIC-Airok-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "AIM",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Ailuk Island",
            "name": "[AIM] - Ailuk Island - Marshall Islands",
            "searchName": "AIM-Ailuk Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "AIP",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Ailinglapalap Island",
            "name": "[AIP] - Ailinglapalap Island - Marshall Islands",
            "searchName": "AIP-Ailinglapalap Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "BII",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Bikini Atoll",
            "name": "[BII] - Bikini Atoll - Marshall Islands",
            "searchName": "BII-Bikini Atoll-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "MJB",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Mejit Island",
            "name": "[MJB] - Mejit Island - Marshall Islands",
            "searchName": "MJB-Mejit Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "MJE",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Majkin",
            "name": "[MJE] - Majkin - Marshall Islands",
            "searchName": "MJE-Majkin Island-Majkin-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "MIJ",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Mili Island",
            "name": "[MIJ] - Mili Island - Marshall Islands",
            "searchName": "MIJ-Mili Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "MAV",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Maloelap Island",
            "name": "[MAV] - Maloelap Island - Marshall Islands",
            "searchName": "MAV-Maloelap Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "MAJ",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Majuro",
            "name": "[MAJ] - Majuro - Marshall Islands",
            "searchName": "MAJ-Uliga-Majuro-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "LML",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Lae Island",
            "name": "[LML] - Lae Island - Marshall Islands",
            "searchName": "LML-Lae Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "LIK",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Likiep Island",
            "name": "[LIK] - Likiep Island - Marshall Islands",
            "searchName": "LIK-Likiep Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "LOF",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Loen",
            "name": "[LOF] - Loen - Marshall Islands",
            "searchName": "LOF-Loen-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "KIO",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Kili Island",
            "name": "[KIO] - Kili Island - Marshall Islands",
            "searchName": "KIO-Kili Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "KBT",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Kaben",
            "name": "[KBT] - Kaben - Marshall Islands",
            "searchName": "KBT-Kaben-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "KWA",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Kwajalein",
            "name": "[KWA] - Kwajalein - Marshall Islands",
            "searchName": "KWA-Kwajalein-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "IMI",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Ine Island",
            "name": "[IMI] - Ine Island - Marshall Islands",
            "searchName": "IMI-Ine Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "JAT",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Jabot",
            "name": "[JAT] - Jabot - Marshall Islands",
            "searchName": "JAT-Jabot-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "JEJ",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Jeh",
            "name": "[JEJ] - Jeh - Marshall Islands",
            "searchName": "JEJ-Jeh-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "UTK",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Utirik Island",
            "name": "[UTK] - Utirik Island - Marshall Islands",
            "searchName": "UTK-Utirik Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "UJE",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Ujae Island",
            "name": "[UJE] - Ujae Island - Marshall Islands",
            "searchName": "UJE-Ujae Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "UIT",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Jaluit Island",
            "name": "[UIT] - Jaluit Island - Marshall Islands",
            "searchName": "UIT-Jaluit-Jaluit Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "TBV",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Tabal",
            "name": "[TBV] - Tabal - Marshall Islands",
            "searchName": "TBV-Tabal-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "TIC",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Tinak Island",
            "name": "[TIC] - Tinak Island - Marshall Islands",
            "searchName": "TIC-Tinak Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "WJA",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Woja",
            "name": "[WJA] - Woja - Marshall Islands",
            "searchName": "WJA-Woja-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "WTO",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Wotho Island",
            "name": "[WTO] - Wotho Island - Marshall Islands",
            "searchName": "WTO-Wotho-Wotho Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "WTE",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Wotje Island",
            "name": "[WTE] - Wotje Island - Marshall Islands",
            "searchName": "WTE-Wotje Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "NDK",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Namdrik Island",
            "name": "[NDK] - Namdrik Island - Marshall Islands",
            "searchName": "NDK-Namdrik Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "NMU",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Namu",
            "name": "[NMU] - Namu - Marshall Islands",
            "searchName": "NMU-Namu-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "RNP",
            "countryCode": "MH",
            "cityCode": null,
            "cityName": "Rongelap Island",
            "name": "[RNP] - Rongelap Island - Marshall Islands",
            "searchName": "RNP-Rongelap-Rongelap Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "BXV",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Breiddalsvik",
            "name": "[BXV] - Breiddalsvik - Iceland",
            "searchName": "BXV-Breiddalsvik-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "BLO",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Blonduos",
            "name": "[BLO] - Blonduos - Iceland",
            "searchName": "BLO-Blonduos-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "BJD",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Bakkafjordur",
            "name": "[BJD] - Bakkafjordur - Iceland",
            "searchName": "BJD-Bakkafjordur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "BIU",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Bildudalur",
            "name": "[BIU] - Bildudalur - Iceland",
            "searchName": "BIU-Bildudalur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "BGJ",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Borgarfjordur Eystri",
            "name": "[BGJ] - Borgarfjordur Eystri - Iceland",
            "searchName": "BGJ-Borgarfjordur Eystri-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "HFN",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Hornafjordur",
            "name": "[HFN] - Hornafjordur - Iceland",
            "searchName": "HFN-Hornafjordur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "GRY",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Grimsey",
            "name": "[GRY] - Grimsey - Iceland",
            "searchName": "GRY-Grimsey-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "GUU",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Grundarfjordur",
            "name": "[GUU] - Grundarfjordur - Iceland",
            "searchName": "GUU-Grundarfjordur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "GJR",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Gjogur",
            "name": "[GJR] - Gjogur - Iceland",
            "searchName": "GJR-Gjogur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "HZK",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Husavik",
            "name": "[HZK] - Husavik - Iceland",
            "searchName": "HZK-Husavik-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "HVK",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Holmavik",
            "name": "[HVK] - Holmavik - Iceland",
            "searchName": "HVK-Holmavik-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "IFJ",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Isafjordur",
            "name": "[IFJ] - Isafjordur - Iceland",
            "searchName": "IFJ-Isafjordur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "ISH",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Ischia",
            "name": "[ISH] - Ischia - Italy",
            "searchName": "ISH-Ischia-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "GOA",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Genova",
            "name": "[GOA] - Genova - Italy",
            "searchName": "GOA-Genua-Genova-Genoa-G-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "GBN",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "San Giovanni Rotondo",
            "name": "[GBN] - San Giovanni Rotondo - Italy",
            "searchName": "GBN-San Giovanni Rotondo-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "GRS",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Grosseto",
            "name": "[GRS] - Grosseto - Italy",
            "searchName": "GRS-Grosseto-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "PYB",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Jeypore",
            "name": "[PYB] - Jeypore - India",
            "searchName": "PYB-Jeypore-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "RGH",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Balurghat",
            "name": "[RGH] - Balurghat - India",
            "searchName": "RGH-Balurghat-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "REW",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Rewa",
            "name": "[REW] - Rewa - India",
            "searchName": "REW-Rewa-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "RAJ",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Rajkot",
            "name": "[RAJ] - Rajkot - India",
            "searchName": "RAJ-Rajkot-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "ZER",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Zero",
            "name": "[ZER] - Zero - India",
            "searchName": "ZER-Zero-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "VGA",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Vijayawada",
            "name": "[VGA] - Vijayawada - India",
            "searchName": "VGA-Vijayawada-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "VDY",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Vidyanagar",
            "name": "[VDY] - Vidyanagar - India",
            "searchName": "VDY-Vidyanagar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "VTZ",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Vishakhapatnam",
            "name": "[VTZ] - Vishakhapatnam - India",
            "searchName": "VTZ-Visakhapatnam-Vishakhapatnam-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "VNS",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Varanasi",
            "name": "[VNS] - Varanasi - India",
            "searchName": "VNS-Varanasi-Benar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "WGC",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Warangal",
            "name": "[WGC] - Warangal - India",
            "searchName": "WGC-Warangal-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "RFN",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Raufarhofn",
            "name": "[RFN] - Raufarhofn - Iceland",
            "searchName": "RFN-Raufarhofn-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "RHA",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Reykholar",
            "name": "[RHA] - Reykholar - Iceland",
            "searchName": "RHA-Reykholar-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "SAK",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Saudarkrokur",
            "name": "[SAK] - Saudarkrokur - Iceland",
            "searchName": "SAK-Saudarkrokur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "SIJ",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Siglufjordur",
            "name": "[SIJ] - Siglufjordur - Iceland",
            "searchName": "SIJ-Siglufjordur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "OLI",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Olafsvik",
            "name": "[OLI] - Olafsvik - Iceland",
            "searchName": "OLI-Olafsvik-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "OFJ",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Olafsfjordur",
            "name": "[OFJ] - Olafsfjordur - Iceland",
            "searchName": "OFJ-Olafsfjordur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "NOR",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Nordfjordur",
            "name": "[NOR] - Nordfjordur - Iceland",
            "searchName": "NOR-Nordfjordur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "PFJ",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Patreksfjordur",
            "name": "[PFJ] - Patreksfjordur - Iceland",
            "searchName": "PFJ-Patreksfjordur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "OPA",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Kopasker",
            "name": "[OPA] - Kopasker - Iceland",
            "searchName": "OPA-Kopasker-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "MVA",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Myvatn",
            "name": "[MVA] - Myvatn - Iceland",
            "searchName": "MVA-Myvatn-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "FLI",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Flateyri",
            "name": "[FLI] - Flateyri - Iceland",
            "searchName": "FLI-Flateyri-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "FAG",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Fagurholsmyri",
            "name": "[FAG] - Fagurholsmyri - Iceland",
            "searchName": "FAG-Fagurholsmyri-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "FAS",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Faskrudsfjordur",
            "name": "[FAS] - Faskrudsfjordur - Iceland",
            "searchName": "FAS-Faskrudsfjordur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "CBD",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Car Nicobar",
            "name": "[CBD] - Car Nicobar - India",
            "searchName": "CBD-Car Nicobar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "CCJ",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Kozhikode",
            "name": "[CCJ] - Kozhikode - India",
            "searchName": "CCJ-Kozhikode-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "BUP",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Bhatinda",
            "name": "[BUP] - Bhatinda - India",
            "searchName": "BUP-Bhatinda-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "ATQ",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Amritsar",
            "name": "[ATQ] - Amritsar - India",
            "searchName": "ATQ-Amritsar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "BBI",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Bhubaneswar",
            "name": "[BBI] - Bhubaneswar - India",
            "searchName": "BBI-Bhubaneswar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "AGR",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Agra",
            "name": "[AGR] - Agra - India",
            "searchName": "AGR-Agra--India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "AGX",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Agatti Island",
            "name": "[AGX] - Agatti Island - India",
            "searchName": "AGX-Agatti Island-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "AMD",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Ahmedabad",
            "name": "[AMD] - Ahmedabad - India",
            "searchName": "AMD-Ahmedabad-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "AKD",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Akola",
            "name": "[AKD] - Akola - India",
            "searchName": "AKD-Akola-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "AJL",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Aizawl",
            "name": "[AJL] - Aizawl - India",
            "searchName": "AJL-Aizol-Aizawl-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "DMU",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Dimapur",
            "name": "[DMU] - Dimapur - India",
            "searchName": "DMU-Dimapur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "CCU",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Kolkata",
            "name": "[CCU] - Kolkata - India",
            "searchName": "CCU-Kalkutta-Kolkata-Calcuta-Calcutta-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "CDP",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Cuddapah",
            "name": "[CDP] - Cuddapah - India",
            "searchName": "CDP-Cuddapah-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "COH",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Cooch Behar",
            "name": "[COH] - Cooch Behar - India",
            "searchName": "COH-Cooch Behar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "COK",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Kochi",
            "name": "[COK] - Kochi - India",
            "searchName": "COK-Kochi-Cochin-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "CJB",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Coimbatore",
            "name": "[CJB] - Coimbatore - India",
            "searchName": "CJB-Coimbatore-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "DIU",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Diu",
            "name": "[DIU] - Diu - India",
            "searchName": "DIU-Diu-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "DIB",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Dibrugarh",
            "name": "[DIB] - Dibrugarh - India",
            "searchName": "DIB-Dibrugarh-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "DHM",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Dharamsala",
            "name": "[DHM] - Dharamsala - India",
            "searchName": "DHM-Dharamsala-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "DED",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Dehra Dun",
            "name": "[DED] - Dehra Dun - India",
            "searchName": "DED-Dehra Dun-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "DEP",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Deparizo",
            "name": "[DEP] - Deparizo - India",
            "searchName": "DEP-Deparizo-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "DBD",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Dhanbad",
            "name": "[DBD] - Dhanbad - India",
            "searchName": "DBD-Dhanbad-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "NMB",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Daman",
            "name": "[NMB] - Daman - India",
            "searchName": "NMB-Daman-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "NDC",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Nanded",
            "name": "[NDC] - Nanded - India",
            "searchName": "NDC-Nanded-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "NAG",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Nagpur",
            "name": "[NAG] - Nagpur - India",
            "searchName": "NAG-Nagpur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "MZU",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Muzaffarpur",
            "name": "[MZU] - Muzaffarpur - India",
            "searchName": "MZU-Muzaffarpur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "MYQ",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Mysore",
            "name": "[MYQ] - Mysore - India",
            "searchName": "MYQ-Mysore-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "PBD",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Porbandar",
            "name": "[PBD] - Porbandar - India",
            "searchName": "PBD-Porbandar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "PAT",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Patna",
            "name": "[PAT] - Patna - India",
            "searchName": "PAT-Patna-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "PAB",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Bilaspur",
            "name": "[PAB] - Bilaspur - India",
            "searchName": "PAB-Bilaspur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "PGH",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Pantnagar",
            "name": "[PGH] - Pantnagar - India",
            "searchName": "PGH-Pantnagar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "NVY",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Neyveli",
            "name": "[NVY] - Neyveli - India",
            "searchName": "NVY-Neyveli-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "RPR",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Raipur",
            "name": "[RPR] - Raipur - India",
            "searchName": "RPR-Raipur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "RRK",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Rourkela",
            "name": "[RRK] - Rourkela - India",
            "searchName": "RRK-Rourkela-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "RTC",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Ratnagiri",
            "name": "[RTC] - Ratnagiri - India",
            "searchName": "RTC-Ratnagiri-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "RJI",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Rajouri",
            "name": "[RJI] - Rajouri - India",
            "searchName": "RJI-Rajouri-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "RJA",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Rajahmundry",
            "name": "[RJA] - Rajahmundry - India",
            "searchName": "RJA-Rajahmundry-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "RMD",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Ramagundam",
            "name": "[RMD] - Ramagundam - India",
            "searchName": "RMD-Ramagundam-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "RUP",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Rupsi",
            "name": "[RUP] - Rupsi - India",
            "searchName": "RUP-Rupsi-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "SLV",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Simla",
            "name": "[SLV] - Simla - India",
            "searchName": "SLV-Shimla-Simla-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "SHL",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Shillong",
            "name": "[SHL] - Shillong - India",
            "searchName": "SHL-Shillong-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "PUT",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Puttaparthi",
            "name": "[PUT] - Puttaparthi - India",
            "searchName": "PUT-Puttaparthi-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "PNY",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Pondicherry",
            "name": "[PNY] - Pondicherry - India",
            "searchName": "PNY-Pondicherry-Puducherry-Pondich-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "AOI",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Ancona",
            "name": "[AOI] - Ancona - Italy",
            "searchName": "AOI-Ancona-Anc-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "EBA",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Elba Island",
            "name": "[EBA] - Elba Island - Italy",
            "searchName": "EBA-Elba Island--Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie",
            "priority": 0
        },
        {
            "iataCode": "FOG",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Foggia",
            "name": "[FOG] - Foggia - Italy",
            "searchName": "FOG-Foggia-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "FRL",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Forli",
            "name": "[FRL] - Forli - Italy",
            "searchName": "FRL-Forl-Forli-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "FNU",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Oristano",
            "name": "[FNU] - Oristano - Italy",
            "searchName": "FNU-Oristano-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "CTJ",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Carrollton",
            "name": "[CTJ] - Carrollton - Italy",
            "searchName": "CTJ-Carrollton-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "CRV",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Crotone",
            "name": "[CRV] - Crotone - Italy",
            "searchName": "CRV-Crotone-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "CIY",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Comiso",
            "name": "[CIY] - Comiso - Italy",
            "searchName": "CIY-Comiso-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "DCI",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Decimomannu",
            "name": "[DCI] - Decimomannu - Italy",
            "searchName": "DCI-Decimomannu-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "CUF",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Cuneo",
            "name": "[CUF] - Cuneo - Italy",
            "searchName": "CUF-Cuneo-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "PEG",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Perugia",
            "name": "[PEG] - Perugia - Italy",
            "searchName": "PEG-Perugia-P-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "PEJ",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Peschiei",
            "name": "[PEJ] - Peschiei - Italy",
            "searchName": "PEJ-Peschici-Peschiei-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "NSY",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Sigonella",
            "name": "[NSY] - Sigonella - Italy",
            "searchName": "NSY-Sigonella-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "RRO",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Naples",
            "name": "[RRO] - Naples - Italy",
            "searchName": "RRO-Sorrento-Naples-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "SAY",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Siena",
            "name": "[SAY] - Siena - Italy",
            "searchName": "SAY-Siena-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "BKB",
            "countryCode": "IN",
            "cityCode": null,
            "cityName": "Bikaner",
            "name": "[BKB] - Bikaner - India",
            "searchName": "BKB-Bikaner-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "DJU",
            "countryCode": "IS",
            "cityCode": null,
            "cityName": "Djupivogur",
            "name": "[DJU] - Djupivogur - Iceland",
            "searchName": "DJU-Djupivogur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "LMP",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Lampedusa",
            "name": "[LMP] - Lampedusa - Italy",
            "searchName": "LMP-Lampedusa-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "LCV",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Lucca",
            "name": "[LCV] - Lucca - Italy",
            "searchName": "LCV-Lucca-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "LCC",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Lecce",
            "name": "[LCC] - Lecce - Italy",
            "searchName": "LCC-Lecce-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "BDS",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Brindisi",
            "name": "[BDS] - Brindisi - Italy",
            "searchName": "BDS-Brindisi-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "AHO",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Alghero",
            "name": "[AHO] - Alghero - Italy",
            "searchName": "AHO-Alghero-Alguer-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ALL",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Albenga",
            "name": "[ALL] - Albenga - Italy",
            "searchName": "ALL-Albenga-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "AVB",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Aviano",
            "name": "[AVB] - Aviano - Italy",
            "searchName": "AVB-Aviano-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "BRZ",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Borotou",
            "name": "[BRZ] - Borotou - Italy",
            "searchName": "BRZ-Borotou-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "BLX",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Belluno",
            "name": "[BLX] - Belluno - Italy",
            "searchName": "BLX-Belluno-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "BZO",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Bolzano",
            "name": "[BZO] - Bolzano - Italy",
            "searchName": "BZO-Bozen-Bolzano-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "VIC",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Vicenza",
            "name": "[VIC] - Vicenza - Italy",
            "searchName": "VIC-Vicenza-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "VIF",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Vieste",
            "name": "[VIF] - Vieste - Italy",
            "searchName": "VIF-Vieste-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "UDN",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Udine",
            "name": "[UDN] - Udine - Italy",
            "searchName": "UDN-Udine-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "TRS",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Trieste",
            "name": "[TRS] - Trieste - Italy",
            "searchName": "TRS-Triest-Trieste-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "REG",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Reggio Calabria",
            "name": "[REG] - Reggio Calabria - Italy",
            "searchName": "REG-Reggio Calabria-Reggio de Calabre-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "RAN",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Ravenna",
            "name": "[RAN] - Ravenna - Italy",
            "searchName": "RAN-Ravenna-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "QPA",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Padova",
            "name": "[QPA] - Padova - Italy",
            "searchName": "QPA-Padova-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "QSR",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Salerno",
            "name": "[QSR] - Salerno - Italy",
            "searchName": "QSR-Salerno-Salerne-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "PNL",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Pantelleria",
            "name": "[PNL] - Pantelleria - Italy",
            "searchName": "PNL-Pantelleria-Pantelaria-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "PMO",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Palermo",
            "name": "[PMO] - Palermo - Italy",
            "searchName": "PMO-Palermo-Palerme-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "PRJ",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Capri",
            "name": "[PRJ] - Capri - Italy",
            "searchName": "PRJ-Capri-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "PSR",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Pescara",
            "name": "[PSR] - Pescara - Italy",
            "searchName": "PSR-Pescara-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "TTB",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Tortoli",
            "name": "[TTB] - Tortoli - Italy",
            "searchName": "TTB-Tortoli-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "TFC",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Taormina",
            "name": "[TFC] - Taormina - Italy",
            "searchName": "TFC-Taormina-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "TQR",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "San Domino Island",
            "name": "[TQR] - San Domino Island - Italy",
            "searchName": "TQR-San Domino Island-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "TPS",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Trapani",
            "name": "[TPS] - Trapani - Italy",
            "searchName": "TPS-Trapani-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "TAR",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Taranto",
            "name": "[TAR] - Taranto - Italy",
            "searchName": "TAR-Taranto-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "SUF",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Lamezia Terme",
            "name": "[SUF] - Lamezia Terme - Italy",
            "searchName": "SUF-Lamezia Terme-Lamezia-Terme-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZAK",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Chiusa/Klausen",
            "name": "[ZAK] - Chiusa/Klausen - Italy",
            "searchName": "ZAK-Chiusa/Klausen-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZIP",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Lipari",
            "name": "[ZIP] - Lipari - Italy",
            "searchName": "ZIP-Lipari-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZIQ",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Salina Island",
            "name": "[ZIQ] - Salina Island - Italy",
            "searchName": "ZIQ-Salina Island-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZIE",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Vulcano",
            "name": "[ZIE] - Vulcano - Italy",
            "searchName": "ZIE-Vulcano-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZJX",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Stromboli",
            "name": "[ZJX] - Stromboli - Italy",
            "searchName": "ZJX-Stromboli-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZJY",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Ponza",
            "name": "[ZJY] - Ponza - Italy",
            "searchName": "ZJY-Ponza-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZJE",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Panarea",
            "name": "[ZJE] - Panarea - Italy",
            "searchName": "ZJE-Panarea-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZJJ",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Procida",
            "name": "[ZJJ] - Procida - Italy",
            "searchName": "ZJJ-Procida-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZRO",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Reggio nell Emilia",
            "name": "[ZRO] - Reggio nell Emilia - Italy",
            "searchName": "ZRO-Reggio nell Emilia-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZRR",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Fiumicino",
            "name": "[ZRR] - Fiumicino - Italy",
            "searchName": "ZRR-Fiumicino-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZMO",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Modena",
            "name": "[ZMO] - Modena - Italy",
            "searchName": "ZMO-Modena-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZMR",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Merano/Meran",
            "name": "[ZMR] - Merano/Meran - Italy",
            "searchName": "ZMR-Merano/Meran-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "JER",
            "countryCode": "JE",
            "cityCode": null,
            "cityName": "Jersey",
            "name": "[JER] - Jersey - Jersey",
            "searchName": "JER-Jersey-British Isles-Großbritannien",
            "priority": 0
        },
        {
            "iataCode": "TNX",
            "countryCode": "KH",
            "cityCode": null,
            "cityName": "Stung Treng",
            "name": "[TNX] - Stung Treng - Cambodia",
            "searchName": "TNX-Stung Treng-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "BBM",
            "countryCode": "KH",
            "cityCode": null,
            "cityName": "Battambang",
            "name": "[BBM] - Battambang - Cambodia",
            "searchName": "BBM-Battambang-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "KKZ",
            "countryCode": "KH",
            "cityCode": null,
            "cityName": "Koh Kong",
            "name": "[KKZ] - Koh Kong - Cambodia",
            "searchName": "KKZ-Koh Kong-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "KMT",
            "countryCode": "KH",
            "cityCode": null,
            "cityName": "Kampot",
            "name": "[KMT] - Kampot - Cambodia",
            "searchName": "KMT-Kampot-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "KOS",
            "countryCode": "KH",
            "cityCode": null,
            "cityName": "Sihanoukville",
            "name": "[KOS] - Sihanoukville - Cambodia",
            "searchName": "KOS-Sihanoukville-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "KZC",
            "countryCode": "KH",
            "cityCode": null,
            "cityName": "Kompong-Chhna",
            "name": "[KZC] - Kompong-Chhna - Cambodia",
            "searchName": "KZC-Kampong Chhnang-Kompong-Chhna-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "KZD",
            "countryCode": "KH",
            "cityCode": null,
            "cityName": "Krakor",
            "name": "[KZD] - Krakor - Cambodia",
            "searchName": "KZD-Krakor-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "KTI",
            "countryCode": "KH",
            "cityCode": null,
            "cityName": "Kratie",
            "name": "[KTI] - Kratie - Cambodia",
            "searchName": "KTI-Kratie-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "KUC",
            "countryCode": "KI",
            "cityCode": null,
            "cityName": "Kuria",
            "name": "[KUC] - Kuria - Kiribati",
            "searchName": "KUC-Kuria-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "BBG",
            "countryCode": "KI",
            "cityCode": null,
            "cityName": "Butaritari",
            "name": "[BBG] - Butaritari - Kiribati",
            "searchName": "BBG-Butaritari-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "AEA",
            "countryCode": "KI",
            "cityCode": null,
            "cityName": "Abemama Atoll",
            "name": "[AEA] - Abemama Atoll - Kiribati",
            "searchName": "AEA-Abemama Atoll-Abemama-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "AAK",
            "countryCode": "KI",
            "cityCode": null,
            "cityName": "Aranuka",
            "name": "[AAK] - Aranuka - Kiribati",
            "searchName": "AAK-Aranuka-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "ABF",
            "countryCode": "KI",
            "cityCode": null,
            "cityName": "Abaiang",
            "name": "[ABF] - Abaiang - Kiribati",
            "searchName": "ABF-Abaiang-Abaiang Atoll-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "AIS",
            "countryCode": "KI",
            "cityCode": null,
            "cityName": "Arorae Island",
            "name": "[AIS] - Arorae Island - Kiribati",
            "searchName": "AIS-Arorae Island-Arorae-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "BEZ",
            "countryCode": "KI",
            "cityCode": null,
            "cityName": "Beru",
            "name": "[BEZ] - Beru - Kiribati",
            "searchName": "BEZ-Beru-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "CXI",
            "countryCode": "KI",
            "cityCode": null,
            "cityName": "Christmas Island",
            "name": "[CXI] - Christmas Island - Kiribati",
            "searchName": "CXI-Christmas Island--Kiribati",
            "priority": 0
        },
        {
            "iataCode": "CIS",
            "countryCode": "KI",
            "cityCode": null,
            "cityName": "Canton Island",
            "name": "[CIS] - Canton Island - Kiribati",
            "searchName": "CIS-Canton Island-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "TNQ",
            "countryCode": "KI",
            "cityCode": null,
            "cityName": "Teraina",
            "name": "[TNQ] - Teraina - Kiribati",
            "searchName": "TNQ-Teraina-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "TNV",
            "countryCode": "KI",
            "cityCode": null,
            "cityName": "Tabuaeran",
            "name": "[TNV] - Tabuaeran - Kiribati",
            "searchName": "TNV-Taubuaeran-Tabuaeran-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "TMN",
            "countryCode": "KI",
            "cityCode": null,
            "cityName": "Tamana Island",
            "name": "[TMN] - Tamana Island - Kiribati",
            "searchName": "TMN-Tamana Island-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "TBF",
            "countryCode": "KI",
            "cityCode": null,
            "cityName": "Tabiteuea North",
            "name": "[TBF] - Tabiteuea North - Kiribati",
            "searchName": "TBF-Tabiteuea North-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "TRW",
            "countryCode": "KI",
            "cityCode": null,
            "cityName": "Tarawa",
            "name": "[TRW] - Tarawa - Kiribati",
            "searchName": "TRW-Tarawa-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "TSU",
            "countryCode": "KI",
            "cityCode": null,
            "cityName": "Tabiteuea South",
            "name": "[TSU] - Tabiteuea South - Kiribati",
            "searchName": "TSU-Tabiteuea South-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "MZK",
            "countryCode": "KI",
            "cityCode": null,
            "cityName": "Marakei",
            "name": "[MZK] - Marakei - Kiribati",
            "searchName": "MZK-Marakei-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "NIG",
            "countryCode": "KI",
            "cityCode": null,
            "cityName": "Nikunau",
            "name": "[NIG] - Nikunau - Kiribati",
            "searchName": "NIG-Nikunau-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "MTK",
            "countryCode": "KI",
            "cityCode": null,
            "cityName": "Makin Island",
            "name": "[MTK] - Makin Island - Kiribati",
            "searchName": "MTK-Makin Island-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "MNK",
            "countryCode": "KI",
            "cityCode": null,
            "cityName": "Maiana",
            "name": "[MNK] - Maiana - Kiribati",
            "searchName": "MNK-Maiana-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "NON",
            "countryCode": "KI",
            "cityCode": null,
            "cityName": "Rotima",
            "name": "[NON] - Rotima - Kiribati",
            "searchName": "NON-Rotima-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "OOT",
            "countryCode": "KI",
            "cityCode": null,
            "cityName": "Onotoa",
            "name": "[OOT] - Onotoa - Kiribati",
            "searchName": "OOT-Onotoa-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "NWA",
            "countryCode": "KM",
            "cityCode": null,
            "cityName": "Moheli",
            "name": "[NWA] - Moheli - Comoros",
            "searchName": "NWA-Moheli-Komoren-Comoros-Komorlar-Comores-Comoras-Comore",
            "priority": 0
        },
        {
            "iataCode": "YVA",
            "countryCode": "KM",
            "cityCode": null,
            "cityName": "Moroni",
            "name": "[YVA] - Moroni - Comoros",
            "searchName": "YVA-Moroni-Komoren-Comoros-Komorlar-Comores-Comoras-Comore",
            "priority": 0
        },
        {
            "iataCode": "AJN",
            "countryCode": "KM",
            "cityCode": null,
            "cityName": "Anjouan",
            "name": "[AJN] - Anjouan - Comoros",
            "searchName": "AJN-Anjouan-Komoren-Comoros-Komorlar-Comores-Comoras-Comore",
            "priority": 0
        },
        {
            "iataCode": "NEV",
            "countryCode": "KN",
            "cityCode": null,
            "cityName": "Nevis",
            "name": "[NEV] - Nevis - Saint Kitts and Nevis",
            "searchName": "NEV-Nevis-Saint Kitts und Nevis-Saint Kitts and Nevis-Saint Kitts ve Nevis-Saint-Kitts-et-San Cristóbal y Nieves-Saint Kitts e Nevis",
            "priority": 0
        },
        {
            "iataCode": "SKB",
            "countryCode": "KN",
            "cityCode": null,
            "cityName": "Basseterre",
            "name": "[SKB] - Basseterre - Saint Kitts and Nevis",
            "searchName": "SKB-Saint Kitts-Basseterre-St Kitts-Saint Kitts und Nevis-Saint Kitts and Nevis-Saint Kitts ve Nevis-Saint-Kitts-et-Nevis-San Cristóbal y Nieves-Saint Kitts e Nevis",
            "priority": 0
        },
        {
            "iataCode": "RGO",
            "countryCode": "KP",
            "cityCode": null,
            "cityName": "Orang",
            "name": "[RGO] - Orang - North Korea",
            "searchName": "RGO-Orang-North Korea-Nordkorea-Kuzey Kore-Corée du Nord-Corea del Norte-Corea del Nord",
            "priority": 0
        },
        {
            "iataCode": "YJS",
            "countryCode": "KP",
            "cityCode": null,
            "cityName": "Samjiyon",
            "name": "[YJS] - Samjiyon - North Korea",
            "searchName": "YJS-Samjiyon-North Korea-Nordkorea-Kuzey Kore-Corée du Nord-Corea del Norte-Corea del Nord",
            "priority": 0
        },
        {
            "iataCode": "WOS",
            "countryCode": "KP",
            "cityCode": null,
            "cityName": "Wonsan",
            "name": "[WOS] - Wonsan - North Korea",
            "searchName": "WOS-Wonsan-North Korea-Nordkorea-Kuzey Kore-Corée du Nord-Corea del Norte-Corea del Nord",
            "priority": 0
        },
        {
            "iataCode": "FNJ",
            "countryCode": "KP",
            "cityCode": null,
            "cityName": "Pyongyang",
            "name": "[FNJ] - Pyongyang - North Korea",
            "searchName": "FNJ-Pyongyang-North Korea-Nordkorea-Kuzey Kore-Corée du Nord-Corea del Norte-Corea del Nord",
            "priority": 0
        },
        {
            "iataCode": "DSO",
            "countryCode": "KP",
            "cityCode": null,
            "cityName": "Sondok",
            "name": "[DSO] - Sondok - North Korea",
            "searchName": "DSO-Sondok-North Korea-Nordkorea-Kuzey Kore-Corée du Nord-Corea del Norte-Corea del Nord",
            "priority": 0
        },
        {
            "iataCode": "CHF",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Jinhae",
            "name": "[CHF] - Jinhae - South Korea",
            "searchName": "CHF-Jinhae-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "CHN",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Jeonju",
            "name": "[CHN] - Jeonju - South Korea",
            "searchName": "CHN-Jeonju-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "CJJ",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Cheongju",
            "name": "[CJJ] - Cheongju - South Korea",
            "searchName": "CJJ-Cheongju-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "CJU",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Jeju",
            "name": "[CJU] - Jeju - South Korea",
            "searchName": "CJU-Jeju-Cheju-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "KUV",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Gunsan",
            "name": "[KUV] - Gunsan - South Korea",
            "searchName": "KUV-Gunsan-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "KUZ",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Gunsan AB",
            "name": "[KUZ] - Gunsan AB - South Korea",
            "searchName": "KUZ-Gunsan-Gunsan AB-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "KPO",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Pohang",
            "name": "[KPO] - Pohang - South Korea",
            "searchName": "KPO-Pohang-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "KWJ",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Gwangju",
            "name": "[KWJ] - Gwangju - South Korea",
            "searchName": "KWJ-Gwangju-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "JGE",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Geoje",
            "name": "[JGE] - Geoje - South Korea",
            "searchName": "JGE-Geoje-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "KAG",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Gangneung",
            "name": "[KAG] - Gangneung - South Korea",
            "searchName": "KAG-Gangneung-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "JCN",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Incheon",
            "name": "[JCN] - Incheon - South Korea",
            "searchName": "JCN-Incheon-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "JDG",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Jeongseok",
            "name": "[JDG] - Jeongseok - South Korea",
            "searchName": "JDG-Jeongseok-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "HIN",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Jinju",
            "name": "[HIN] - Jinju - South Korea",
            "searchName": "HIN-Jinju-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "WJU",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "WonJu",
            "name": "[WJU] - WonJu - South Korea",
            "searchName": "WJU-Wonju-WonJu-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "YEC",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Yecheon",
            "name": "[YEC] - Yecheon - South Korea",
            "searchName": "YEC-Yecheon-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "YNY",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Yangyang",
            "name": "[YNY] - Yangyang - South Korea",
            "searchName": "YNY-Yangyang-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "UJN",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Uljin",
            "name": "[UJN] - Uljin - South Korea",
            "searchName": "UJN-Uljin-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "USN",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Ulsan",
            "name": "[USN] - Ulsan - South Korea",
            "searchName": "USN-Ulsan-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "TAE",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Daegu",
            "name": "[TAE] - Daegu - South Korea",
            "searchName": "TAE-Daegu-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "SYS",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Suncheon",
            "name": "[SYS] - Suncheon - South Korea",
            "searchName": "SYS-Suncheon-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "SWU",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Suwon",
            "name": "[SWU] - Suwon - South Korea",
            "searchName": "SWU-Suwon-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "SUK",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Samcheok",
            "name": "[SUK] - Samcheok - South Korea",
            "searchName": "SUK-Samcheok-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "PUS",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Busan",
            "name": "[PUS] - Busan - South Korea",
            "searchName": "PUS-Busan-Bus-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "SHO",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Sokcho",
            "name": "[SHO] - Sokcho - South Korea",
            "searchName": "SHO-Sokcho-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "MYJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Matsuyama",
            "name": "[MYJ] - Matsuyama - Japan",
            "searchName": "MYJ-Matsuyama-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "MYE",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Miyake Jima",
            "name": "[MYE] - Miyake Jima - Japan",
            "searchName": "MYE-Miyake Jima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "RBJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Rebun",
            "name": "[RBJ] - Rebun - Japan",
            "searchName": "RBJ-Rebun-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "RNJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Yoronjima",
            "name": "[RNJ] - Yoronjima - Japan",
            "searchName": "RNJ-Yoronjima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "RIS",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Rishiri",
            "name": "[RIS] - Rishiri - Japan",
            "searchName": "RIS-Rishiri-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "SDJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Sendai",
            "name": "[SDJ] - Sendai - Japan",
            "searchName": "SDJ-Sendai-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "SDO",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Ryotsu Sado Island",
            "name": "[SDO] - Ryotsu Sado Island - Japan",
            "searchName": "SDO-Ryotsu Sado Island-Ryotsu Sado island-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "SDS",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Sado Shima",
            "name": "[SDS] - Sado Shima - Japan",
            "searchName": "SDS-Sado Shima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "SHM",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Shirahama",
            "name": "[SHM] - Shirahama - Japan",
            "searchName": "SHM-Shirahama-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "SHB",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Nakashibetsu",
            "name": "[SHB] - Nakashibetsu - Japan",
            "searchName": "SHB-Nakashibetsu-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "SHI",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Shimojishima",
            "name": "[SHI] - Shimojishima - Japan",
            "searchName": "SHI-Shimojishima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "SPK",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Sapporo",
            "name": "[SPK] - Sapporo - Japan",
            "searchName": "SPK-Sapporo-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "YOK",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Yokohama",
            "name": "[YOK] - Yokohama - Japan",
            "searchName": "YOK-Yokohama-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "YGJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Yonago",
            "name": "[YGJ] - Yonago - Japan",
            "searchName": "YGJ-Yonago-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "WKJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Wakkanai",
            "name": "[WKJ] - Wakkanai - Japan",
            "searchName": "WKJ-Wakkanai-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "TAK",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Takamatsu",
            "name": "[TAK] - Takamatsu - Japan",
            "searchName": "TAK-Takamatsu-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "SYO",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Shonai",
            "name": "[SYO] - Shonai - Japan",
            "searchName": "SYO-Shonai-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "TRA",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Taramajima",
            "name": "[TRA] - Taramajima - Japan",
            "searchName": "TRA-Taramajima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "TOY",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Toyama",
            "name": "[TOY] - Toyama - Japan",
            "searchName": "TOY-Toyama-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "TNE",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Tanegashima",
            "name": "[TNE] - Tanegashima - Japan",
            "searchName": "TNE-Tanegashima-Tanega Shima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "TKS",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Tokushima",
            "name": "[TKS] - Tokushima - Japan",
            "searchName": "TKS-Tokushima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "TJH",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Toyooka",
            "name": "[TJH] - Toyooka - Japan",
            "searchName": "TJH-Toyooka-Tajima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "TKN",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Tokunoshima",
            "name": "[TKN] - Tokunoshima - Japan",
            "searchName": "TKN-Tokunoshima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "TTJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Tottori",
            "name": "[TTJ] - Tottori - Japan",
            "searchName": "TTJ-Tottori-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "TSJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Tsushima",
            "name": "[TSJ] - Tsushima - Japan",
            "searchName": "TSJ-Tsushima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "UBJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Ube",
            "name": "[UBJ] - Ube - Japan",
            "searchName": "UBJ-Ube-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "MSJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Misawa",
            "name": "[MSJ] - Misawa - Japan",
            "searchName": "MSJ-Misawa-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "MUS",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Marcus Island",
            "name": "[MUS] - Marcus Island - Japan",
            "searchName": "MUS-Marcus Island-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "MMY",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Miyako Jima",
            "name": "[MMY] - Miyako Jima - Japan",
            "searchName": "MMY-Miyako Jima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "MMJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Matsumoto",
            "name": "[MMJ] - Matsumoto - Japan",
            "searchName": "MMJ-Matsumoto-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "MMB",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Memanbetsu",
            "name": "[MMB] - Memanbetsu - Japan",
            "searchName": "MMB-Memanbetsu-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "MMD",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Minami Daito",
            "name": "[MMD] - Minami Daito - Japan",
            "searchName": "MMD-Minami Daito-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "NGS",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Nagasaki",
            "name": "[NGS] - Nagasaki - Japan",
            "searchName": "NGS-Nagasaki-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "NJA",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Atsugi",
            "name": "[NJA] - Atsugi - Japan",
            "searchName": "NJA-Atsugi-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "UEO",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Kumejima",
            "name": "[UEO] - Kumejima - Japan",
            "searchName": "UEO-Kumejima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "UKY",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Kyoto",
            "name": "[UKY] - Kyoto - Japan",
            "searchName": "UKY-Kyoto-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "UKA",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Ukunda",
            "name": "[UKA] - Ukunda - Kenya",
            "searchName": "UKA-Ukunda-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "UAS",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Samburu",
            "name": "[UAS] - Samburu - Kenya",
            "searchName": "UAS-Samburu-Samburu National Reserve-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "VPG",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Vipingo",
            "name": "[VPG] - Vipingo - Kenya",
            "searchName": "VPG-Vipingo-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "WJR",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Wajir",
            "name": "[WJR] - Wajir - Kenya",
            "searchName": "WJR-Wajir-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "RBT",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Marsabit",
            "name": "[RBT] - Marsabit - Kenya",
            "searchName": "RBT-Marsabit-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "MYD",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Malindi",
            "name": "[MYD] - Malindi - Kenya",
            "searchName": "MYD-Malindi-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "NDE",
            "countryCode": "KE",
            "cityCode": null,
            "cityName": "Mandera",
            "name": "[NDE] - Mandera - Kenya",
            "searchName": "NDE-Mandera-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "AKJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Asahikawa",
            "name": "[AKJ] - Asahikawa - Japan",
            "searchName": "AKJ-Asahikawa-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "AGJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Aguni",
            "name": "[AGJ] - Aguni - Japan",
            "searchName": "AGJ-Aguni-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "AOJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Aomori",
            "name": "[AOJ] - Aomori - Japan",
            "searchName": "AOJ-Aomori-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "ASJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Amami",
            "name": "[ASJ] - Amami - Japan",
            "searchName": "ASJ-Amami O Shima-Amami-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "AXT",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Akita",
            "name": "[AXT] - Akita - Japan",
            "searchName": "AXT-Akita-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "BPU",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Beppu",
            "name": "[BPU] - Beppu - Japan",
            "searchName": "BPU-Beppu-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "FKS",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Fukushima",
            "name": "[FKS] - Fukushima - Japan",
            "searchName": "FKS-Fukushima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "FKJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Fukui",
            "name": "[FKJ] - Fukui - Japan",
            "searchName": "FKJ-Fukui-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "MBE",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Monbetsu",
            "name": "[MBE] - Monbetsu - Japan",
            "searchName": "MBE-Monbetsu-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KIJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Niigata",
            "name": "[KIJ] - Niigata - Japan",
            "searchName": "KIJ-Niigata-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KJP",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Kerama",
            "name": "[KJP] - Kerama - Japan",
            "searchName": "KJP-Kerama-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KKX",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Kikaiga Shima",
            "name": "[KKX] - Kikaiga Shima - Japan",
            "searchName": "KKX-Kikaiga Shima-Kikai-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KKJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Kita Kyushu",
            "name": "[KKJ] - Kita Kyushu - Japan",
            "searchName": "KKJ-Kitakyushu-Kita Kyushu-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KOJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Kagoshima",
            "name": "[KOJ] - Kagoshima - Japan",
            "searchName": "KOJ-Kagoshima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KMQ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Komatsu",
            "name": "[KMQ] - Komatsu - Japan",
            "searchName": "KMQ-Komatsu-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KMI",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Miyazaki",
            "name": "[KMI] - Miyazaki - Japan",
            "searchName": "KMI-Miyazaki-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KMJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Kumamoto",
            "name": "[KMJ] - Kumamoto - Japan",
            "searchName": "KMJ-Kumamoto-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KCZ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Kochi",
            "name": "[KCZ] - Kochi - Japan",
            "searchName": "KCZ-Kochi-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KUJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Kushimoto",
            "name": "[KUJ] - Kushimoto - Japan",
            "searchName": "KUJ-Kushimoto-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KUM",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Yakushima",
            "name": "[KUM] - Yakushima - Japan",
            "searchName": "KUM-Yakushima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KUH",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Kushiro",
            "name": "[KUH] - Kushiro - Japan",
            "searchName": "KUH-Kushiro-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KTD",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Kitadaito",
            "name": "[KTD] - Kitadaito - Japan",
            "searchName": "KTD-Kitadaito-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "IWJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Iwami",
            "name": "[IWJ] - Iwami - Japan",
            "searchName": "IWJ-Iwami-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "IWK",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Iwakuni",
            "name": "[IWK] - Iwakuni - Japan",
            "searchName": "IWK-Iwakuni-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "IWO",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Iwo Jima Vol",
            "name": "[IWO] - Iwo Jima Vol - Japan",
            "searchName": "IWO-Iwo Jima-Iwo Jima Vol-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "IZO",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Izumo",
            "name": "[IZO] - Izumo - Japan",
            "searchName": "IZO-Izumo-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "ISG",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Ishigaki",
            "name": "[ISG] - Ishigaki - Japan",
            "searchName": "ISG-Ishigaki-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "IEJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Iejima",
            "name": "[IEJ] - Iejima - Japan",
            "searchName": "IEJ-Iejima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "IHA",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Niihama",
            "name": "[IHA] - Niihama - Japan",
            "searchName": "IHA-Niihama-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "IIN",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Nishinoomote",
            "name": "[IIN] - Nishinoomote - Japan",
            "searchName": "IIN-Nishino-Nishinoomote-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "IKI",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Iki",
            "name": "[IKI] - Iki - Japan",
            "searchName": "IKI-Iki-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "IBR",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Ibaraki",
            "name": "[IBR] - Ibaraki - Japan",
            "searchName": "IBR-Omitama-Ibaraki-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "HTR",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Hateruma",
            "name": "[HTR] - Hateruma - Japan",
            "searchName": "HTR-Hateruma-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "HSG",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Saga",
            "name": "[HSG] - Saga - Japan",
            "searchName": "HSG-Saga-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "HAC",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Hachijo Jima",
            "name": "[HAC] - Hachijo Jima - Japan",
            "searchName": "HAC-Hachijo Jima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "HHE",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Hachinohe",
            "name": "[HHE] - Hachinohe - Japan",
            "searchName": "HHE-Hachinohe-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "HIJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Hiroshima",
            "name": "[HIJ] - Hiroshima - Japan",
            "searchName": "HIJ-Hiroshima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "HKD",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Hakodate",
            "name": "[HKD] - Hakodate - Japan",
            "searchName": "HKD-Hakodate-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "HNA",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Hanamaki",
            "name": "[HNA] - Hanamaki - Japan",
            "searchName": "HNA-Hanamaki-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "GAJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Yamagata",
            "name": "[GAJ] - Yamagata - Japan",
            "searchName": "GAJ-Yamagata-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "FSZ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Shizuoka",
            "name": "[FSZ] - Shizuoka - Japan",
            "searchName": "FSZ-Shizuoka-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "FUJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Fukue",
            "name": "[FUJ] - Fukue - Japan",
            "searchName": "FUJ-Fukue-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "FUK",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Fukuoka",
            "name": "[FUK] - Fukuoka - Japan",
            "searchName": "FUK-Fukuoka-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "OMJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Omura",
            "name": "[OMJ] - Omura - Japan",
            "searchName": "OMJ-Omura-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "OKE",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Okino Erabu",
            "name": "[OKE] - Okino Erabu - Japan",
            "searchName": "OKE-Okino Erabu-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "OKI",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Oki Island",
            "name": "[OKI] - Oki Island - Japan",
            "searchName": "OKI-Oki Island-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "OKJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Okayama",
            "name": "[OKJ] - Okayama - Japan",
            "searchName": "OKJ-Okayama-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "OIM",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Oshima",
            "name": "[OIM] - Oshima - Japan",
            "searchName": "OIM-Oshima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "OIR",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Okushiri",
            "name": "[OIR] - Okushiri - Japan",
            "searchName": "OIR-Okushiri-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "OIT",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Oita",
            "name": "[OIT] - Oita - Japan",
            "searchName": "OIT-Oita-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "OKA",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Okinawa",
            "name": "[OKA] - Okinawa - Japan",
            "searchName": "OKA-Okinawa-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "OBO",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Obihiro",
            "name": "[OBO] - Obihiro - Japan",
            "searchName": "OBO-Obihiro-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "OGN",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Yonaguni Jima",
            "name": "[OGN] - Yonaguni Jima - Japan",
            "searchName": "OGN-Yonaguni Jima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "NTQ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Wajima",
            "name": "[NTQ] - Wajima - Japan",
            "searchName": "NTQ-Wajima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "ONJ",
            "countryCode": "JP",
            "cityCode": null,
            "cityName": "Odate Noshiro",
            "name": "[ONJ] - Odate Noshiro - Japan",
            "searchName": "ONJ-Odate Noshiro-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "SLA",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Salta",
            "name": "[SLA] - Salta - Argentina",
            "searchName": "SLA-Salta-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "SFN",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Santa Fe",
            "name": "[SFN] - Santa Fe - Argentina",
            "searchName": "SFN-Santa Fe-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "SGV",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Sierra Grande",
            "name": "[SGV] - Sierra Grande - Argentina",
            "searchName": "SGV-Sierra Grande-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "SDE",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Santiago Del Estero",
            "name": "[SDE] - Santiago Del Estero - Argentina",
            "searchName": "SDE-Santiago del Estero-Santiago Del Estero-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RYO",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Rio Turbio",
            "name": "[RYO] - Rio Turbio - Argentina",
            "searchName": "RYO-Rio Turbio-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RZA",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Santa Cruz",
            "name": "[RZA] - Santa Cruz - Argentina",
            "searchName": "RZA-Santa Cruz-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RLO",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Merlo",
            "name": "[RLO] - Merlo - Argentina",
            "searchName": "RLO-Merlo-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "ROS",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Rosario",
            "name": "[ROS] - Rosario - Argentina",
            "searchName": "ROS-Rosario-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "ROY",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Rio Mayo",
            "name": "[ROY] - Rio Mayo - Argentina",
            "searchName": "ROY-Rio Mayo-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RSA",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Santa Rosa",
            "name": "[RSA] - Santa Rosa - Argentina",
            "searchName": "RSA-Santa Rosa-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "PMQ",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Perito Moreno",
            "name": "[PMQ] - Perito Moreno - Argentina",
            "searchName": "PMQ-Perito Moreno-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "PMY",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Puerto Madryn",
            "name": "[PMY] - Puerto Madryn - Argentina",
            "searchName": "PMY-Puerto Madryn-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "PRA",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Parana",
            "name": "[PRA] - Parana - Argentina",
            "searchName": "PRA-Parana-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "PRQ",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Pres. Roque Saenz Pena",
            "name": "[PRQ] - Pres. Roque Saenz Pena - Argentina",
            "searchName": "PRQ-President Roque Saenz Pena-Pres. Roque Saenz Pena-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "PSS",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Posadas",
            "name": "[PSS] - Posadas - Argentina",
            "searchName": "PSS-Posadas-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "PUD",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Puerto Deseado",
            "name": "[PUD] - Puerto Deseado - Argentina",
            "searchName": "PUD-Puerto Deseado-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RAF",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Rafaela",
            "name": "[RAF] - Rafaela - Argentina",
            "searchName": "RAF-Ras An Naqb-Rafaela-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RCQ",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Reconquista",
            "name": "[RCQ] - Reconquista - Argentina",
            "searchName": "RCQ-Reconquista-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RCU",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Rio Cuarto",
            "name": "[RCU] - Rio Cuarto - Argentina",
            "searchName": "RCU-Rio Cuarto-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RDS",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Rincon de los Sauces",
            "name": "[RDS] - Rincon de los Sauces - Argentina",
            "searchName": "RDS-Rincon De Los Sauces-Rincon de los Sauces-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "REL",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Trelew",
            "name": "[REL] - Trelew - Argentina",
            "searchName": "REL-Trelew-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RES",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Resistencia",
            "name": "[RES] - Resistencia - Argentina",
            "searchName": "RES-Resistencia-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RGA",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Rio Grande",
            "name": "[RGA] - Rio Grande - Argentina",
            "searchName": "RGA-Rio Grande-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RGL",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Rio Gallegos",
            "name": "[RGL] - Rio Gallegos - Argentina",
            "searchName": "RGL-R-Rio Gallegos-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RHD",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Rio Hondo",
            "name": "[RHD] - Rio Hondo - Argentina",
            "searchName": "RHD-Rio Hondo-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "MQD",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Maquinchao",
            "name": "[MQD] - Maquinchao - Argentina",
            "searchName": "MQD-Maquinchao-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "MJR",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Miramar",
            "name": "[MJR] - Miramar - Argentina",
            "searchName": "MJR-Miramar-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "NCJ",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Sunchales",
            "name": "[NCJ] - Sunchales - Argentina",
            "searchName": "NCJ-Sunchales-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "NEC",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Necochea",
            "name": "[NEC] - Necochea - Argentina",
            "searchName": "NEC-Necochea-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "PEH",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Pehuajo",
            "name": "[PEH] - Pehuajo - Argentina",
            "searchName": "PEH-Pehuajo-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "ORA",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Oran",
            "name": "[ORA] - Oran - Argentina",
            "searchName": "ORA-Oran-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "OVR",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Olavarria",
            "name": "[OVR] - Olavarria - Argentina",
            "searchName": "OVR-Olavarria-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "OYA",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Goya",
            "name": "[OYA] - Goya - Argentina",
            "searchName": "OYA-Goya-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "OYO",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Tres Arroyos",
            "name": "[OYO] - Tres Arroyos - Argentina",
            "searchName": "OYO-Tres Arroyos-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "NQN",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Neuquen",
            "name": "[NQN] - Neuquen - Argentina",
            "searchName": "NQN-Neuquen-Neuqu-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "OLN",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Colonia Sarmiento",
            "name": "[OLN] - Colonia Sarmiento - Argentina",
            "searchName": "OLN-Colonia Sarmiento-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "OES",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "San Antonio Oeste",
            "name": "[OES] - San Antonio Oeste - Argentina",
            "searchName": "OES-San Antonio Oeste-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "GGS",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Gobernador Gregores",
            "name": "[GGS] - Gobernador Gregores - Argentina",
            "searchName": "GGS-Gobernador Gregores-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "GHU",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Gualeguaychu",
            "name": "[GHU] - Gualeguaychu - Argentina",
            "searchName": "GHU-Gualeguaychu-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "GNR",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "General Roca",
            "name": "[GNR] - General Roca - Argentina",
            "searchName": "GNR-General Roca-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "GPO",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "General Pico",
            "name": "[GPO] - General Pico - Argentina",
            "searchName": "GPO-General Pico-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "FTE",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "El Calafate",
            "name": "[FTE] - El Calafate - Argentina",
            "searchName": "FTE-El Calafate-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "JNI",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Junin",
            "name": "[JNI] - Junin - Argentina",
            "searchName": "JNI-Junin-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "JSM",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Jose De San Martin",
            "name": "[JSM] - Jose De San Martin - Argentina",
            "searchName": "JSM-Jose de San Martin-Jose De San Martin-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "JUJ",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Jujuy",
            "name": "[JUJ] - Jujuy - Argentina",
            "searchName": "JUJ-Jujuy-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "IRJ",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "La Rioja",
            "name": "[IRJ] - La Rioja - Argentina",
            "searchName": "IRJ-La Rioja-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "HOS",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Chos Malal",
            "name": "[HOS] - Chos Malal - Argentina",
            "searchName": "HOS-Chos Malal-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "IGB",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Ingeniero Jacobacci",
            "name": "[IGB] - Ingeniero Jacobacci - Argentina",
            "searchName": "IGB-Ingeniero Jacobacci-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "IGR",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Iguazu",
            "name": "[IGR] - Iguazu - Argentina",
            "searchName": "IGR-Iguazu-Igua-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "ING",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Lago Argentino",
            "name": "[ING] - Lago Argentino - Argentina",
            "searchName": "ING-Lago Argentino-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "LCM",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "La Cumbre",
            "name": "[LCM] - La Cumbre - Argentina",
            "searchName": "LCM-La Cumbre-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "LCP",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Loncopue",
            "name": "[LCP] - Loncopue - Argentina",
            "searchName": "LCP-Loncopue-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "LLS",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Las Lomitas",
            "name": "[LLS] - Las Lomitas - Argentina",
            "searchName": "LLS-Las Lomitas-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "LMD",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Los Menucos",
            "name": "[LMD] - Los Menucos - Argentina",
            "searchName": "LMD-Los Menucos-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "LGS",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Malargue",
            "name": "[LGS] - Malargue - Argentina",
            "searchName": "LGS-Malarg-Malargue-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "LHS",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Las Heras",
            "name": "[LHS] - Las Heras - Argentina",
            "searchName": "LHS-Las Heras-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "LUQ",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "San Luis",
            "name": "[LUQ] - San Luis - Argentina",
            "searchName": "LUQ-San Luis-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "LPG",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "La Plata",
            "name": "[LPG] - La Plata - Argentina",
            "searchName": "LPG-La Plata-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "MDZ",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Mendoza",
            "name": "[MDZ] - Mendoza - Argentina",
            "searchName": "MDZ-Mendoza-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "MDQ",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Mar Del Plata",
            "name": "[MDQ] - Mar Del Plata - Argentina",
            "searchName": "MDQ-Mar del Plata-Mar Del Plata-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "MDX",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Mercedes",
            "name": "[MDX] - Mercedes - Argentina",
            "searchName": "MDX-Mercedes-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "MCS",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Monte Caseros",
            "name": "[MCS] - Monte Caseros - Argentina",
            "searchName": "MCS-Monte Caseros-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "COC",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Concordia",
            "name": "[COC] - Concordia - Argentina",
            "searchName": "COC-Concordia-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CNT",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Charata",
            "name": "[CNT] - Charata - Argentina",
            "searchName": "CNT-Charata-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CNQ",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Corrientes",
            "name": "[CNQ] - Corrientes - Argentina",
            "searchName": "CNQ-Corrientes-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "COR",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Cordoba",
            "name": "[COR] - Cordoba - Argentina",
            "searchName": "COR-C-Cordoba-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CPC",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "San Martin DeLos Andes",
            "name": "[CPC] - San Martin DeLos Andes - Argentina",
            "searchName": "CPC-San Martin DeLos Andes-San Martin De Los Andes-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CPG",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Carmen De Patagones",
            "name": "[CPG] - Carmen De Patagones - Argentina",
            "searchName": "CPG-Carmen de Patagones-Carmen De Patagones-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CLX",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Clorinda",
            "name": "[CLX] - Clorinda - Argentina",
            "searchName": "CLX-Clorinda-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CCT",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Colonia Catriel",
            "name": "[CCT] - Colonia Catriel - Argentina",
            "searchName": "CCT-Colonia Catriel-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CSZ",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Coronel Suarez",
            "name": "[CSZ] - Coronel Suarez - Argentina",
            "searchName": "CSZ-Coronel Suarez-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CTC",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Catamarca",
            "name": "[CTC] - Catamarca - Argentina",
            "searchName": "CTC-Catamarca-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CRD",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Comodoro Rivadavia",
            "name": "[CRD] - Comodoro Rivadavia - Argentina",
            "searchName": "CRD-Comodoro Rivadavia-Camodoro-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CRR",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Ceres",
            "name": "[CRR] - Ceres - Argentina",
            "searchName": "CRR-Ceres-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CUT",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Cutral",
            "name": "[CUT] - Cutral - Argentina",
            "searchName": "CUT-Cutral-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CVH",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Caviahue",
            "name": "[CVH] - Caviahue - Argentina",
            "searchName": "CVH-Caviahue-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CVI",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Caleta Olivia",
            "name": "[CVI] - Caleta Olivia - Argentina",
            "searchName": "CVI-Caleta Olivia-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "EHL",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "El Bolson",
            "name": "[EHL] - El Bolson - Argentina",
            "searchName": "EHL-El Bolson-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "ELO",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Eldorado",
            "name": "[ELO] - Eldorado - Argentina",
            "searchName": "ELO-Eldorado-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "EMX",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "El Maiten",
            "name": "[EMX] - El Maiten - Argentina",
            "searchName": "EMX-El Maiten-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "EPA",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "El Palomar",
            "name": "[EPA] - El Palomar - Argentina",
            "searchName": "EPA-El Palomar-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "EQS",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Esquel",
            "name": "[EQS] - Esquel - Argentina",
            "searchName": "EQS-Esquel-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "FMA",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Formosa",
            "name": "[FMA] - Formosa - Argentina",
            "searchName": "FMA-Formosa-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "FJR",
            "countryCode": "AE",
            "cityCode": null,
            "cityName": "Al-fujairah",
            "name": "[FJR] - Al-fujairah - United Arab Emirates",
            "searchName": "FJR-Al-fujairah-Fujairah-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 0
        },
        {
            "iataCode": "RKT",
            "countryCode": "AE",
            "cityCode": null,
            "cityName": "Ras Al Khaimah",
            "name": "[RKT] - Ras Al Khaimah - United Arab Emirates",
            "searchName": "RKT-Ras Al Khaimah-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 0
        },
        {
            "iataCode": "SHJ",
            "countryCode": "AE",
            "cityCode": null,
            "cityName": "Sharjah",
            "name": "[SHJ] - Sharjah - United Arab Emirates",
            "searchName": "SHJ-Schardscha-Sharjah-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 0
        },
        {
            "iataCode": "SGA",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Sheghnan",
            "name": "[SGA] - Sheghnan - Afghanistan",
            "searchName": "SGA-Sheghnan-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "SBF",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Sardeh Band",
            "name": "[SBF] - Sardeh Band - Afghanistan",
            "searchName": "SBF-Sardeh Band-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "MMZ",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Maimana",
            "name": "[MMZ] - Maimana - Afghanistan",
            "searchName": "MMZ-Maimana-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "MZR",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Mazar-i-sharif",
            "name": "[MZR] - Mazar-i-sharif - Afghanistan",
            "searchName": "MZR-Mazar-I-Sharif-i-sharif-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "OAA",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Shank",
            "name": "[OAA] - Shank - Afghanistan",
            "searchName": "OAA-Shank-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "OAH",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Shindand",
            "name": "[OAH] - Shindand - Afghanistan",
            "searchName": "OAH-Shindand-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "OAI",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Bagram",
            "name": "[OAI] - Bagram - Afghanistan",
            "searchName": "OAI-Bagram-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "OAZ",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Camp Bastion",
            "name": "[OAZ] - Camp Bastion - Afghanistan",
            "searchName": "OAZ-Camp Bastion-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "OAS",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Sharana",
            "name": "[OAS] - Sharana - Afghanistan",
            "searchName": "OAS-Sharana-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "OLR",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Salerno",
            "name": "[OLR] - Salerno - Afghanistan",
            "searchName": "OLR-Salerno-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "TII",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Tirinkot",
            "name": "[TII] - Tirinkot - Afghanistan",
            "searchName": "TII-Tirinkot-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "TQN",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Taluqan",
            "name": "[TQN] - Taluqan - Afghanistan",
            "searchName": "TQN-Taluqan-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "UND",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Kunduz",
            "name": "[UND] - Kunduz - Afghanistan",
            "searchName": "UND-Kunduz-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "URN",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Urgoon",
            "name": "[URN] - Urgoon - Afghanistan",
            "searchName": "URN-Urgoon-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "URZ",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Uruzgan",
            "name": "[URZ] - Uruzgan - Afghanistan",
            "searchName": "URZ-Uruzgan-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "ZAJ",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Zaranj",
            "name": "[ZAJ] - Zaranj - Afghanistan",
            "searchName": "ZAJ-Zaranj-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "FAH",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Farah",
            "name": "[FAH] - Farah - Afghanistan",
            "searchName": "FAH-Farah-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "FBD",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Faizabad",
            "name": "[FBD] - Faizabad - Afghanistan",
            "searchName": "FBD-Faizabad-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "DWR",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Dwyer",
            "name": "[DWR] - Dwyer - Afghanistan",
            "searchName": "DWR-Dwyer-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "CCN",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Chakcharan",
            "name": "[CCN] - Chakcharan - Afghanistan",
            "searchName": "CCN-Chakcharan-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "DAZ",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Darwaz",
            "name": "[DAZ] - Darwaz - Afghanistan",
            "searchName": "DAZ-Darwaz-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "BIN",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Bamiyan",
            "name": "[BIN] - Bamiyan - Afghanistan",
            "searchName": "BIN-Bamiyan-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "BST",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Bost",
            "name": "[BST] - Bost - Afghanistan",
            "searchName": "BST-Bost-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "GRG",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Gardez",
            "name": "[GRG] - Gardez - Afghanistan",
            "searchName": "GRG-Gardez-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "GZI",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Ghazni",
            "name": "[GZI] - Ghazni - Afghanistan",
            "searchName": "GZI-Ghazni-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "HEA",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Herat",
            "name": "[HEA] - Herat - Afghanistan",
            "searchName": "HEA-Herat-H-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "JAA",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Jalalabad",
            "name": "[JAA] - Jalalabad - Afghanistan",
            "searchName": "JAA-Dschalalabad-Jalalabad-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "KBL",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Kabul",
            "name": "[KBL] - Kabul - Afghanistan",
            "searchName": "KBL-Kabul-Kaboul-K-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "KDH",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Kandahar",
            "name": "[KDH] - Kandahar - Afghanistan",
            "searchName": "KDH-Kandahar-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "KHT",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Khost",
            "name": "[KHT] - Khost - Afghanistan",
            "searchName": "KHT-Khost-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "KUR",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Kuran-O-Munjan",
            "name": "[KUR] - Kuran-O-Munjan - Afghanistan",
            "searchName": "KUR-Kuran-O-Munjan-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "KWH",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Khwahan",
            "name": "[KWH] - Khwahan - Afghanistan",
            "searchName": "KWH-Khwahan-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "LQN",
            "countryCode": "AF",
            "cityCode": null,
            "cityName": "Qala Nau",
            "name": "[LQN] - Qala Nau - Afghanistan",
            "searchName": "LQN-Qala-Qala Nau-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "BBQ",
            "countryCode": "AG",
            "cityCode": null,
            "cityName": "Barbuda",
            "name": "[BBQ] - Barbuda - Antigua and Barbuda",
            "searchName": "BBQ-Barbuda-Antigua and Barbuda-Antigua und Barbuda-Antigua y Barbuda-Antigua-et-Antigua e Barbuda",
            "priority": 0
        },
        {
            "iataCode": "ANU",
            "countryCode": "AG",
            "cityCode": null,
            "cityName": "St. John s",
            "name": "[ANU] - St. John s - Antigua and Barbuda",
            "searchName": "ANU-Antigua-St. John s-Antigua & Barbuda-Antigua and Barbuda-Antigua und Barbuda-Antigua y Barbuda-et-Barbuda-Antigua e Barbuda",
            "priority": 0
        },
        {
            "iataCode": "AXA",
            "countryCode": "AI",
            "cityCode": null,
            "cityName": "Anguilla",
            "name": "[AXA] - Anguilla - Anguilla",
            "searchName": "AXA-Anguilla-The Valley-AIA-Anguila",
            "priority": 0
        },
        {
            "iataCode": "ARZ",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Nzeto",
            "name": "[ARZ] - Nzeto - Angola",
            "searchName": "ARZ-N zeto-Nzeto-Angola",
            "priority": 0
        },
        {
            "iataCode": "AZZ",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Ambriz",
            "name": "[AZZ] - Ambriz - Angola",
            "searchName": "AZZ-Ambriz-Angola",
            "priority": 0
        },
        {
            "iataCode": "ANL",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Andulo",
            "name": "[ANL] - Andulo - Angola",
            "searchName": "ANL-Andulo-Angola",
            "priority": 0
        },
        {
            "iataCode": "BUG",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Benguela",
            "name": "[BUG] - Benguela - Angola",
            "searchName": "BUG-Benguela-Angola",
            "priority": 0
        },
        {
            "iataCode": "CAB",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Cabinda",
            "name": "[CAB] - Cabinda - Angola",
            "searchName": "CAB-Cabinda-Angola",
            "priority": 0
        },
        {
            "iataCode": "CAV",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Cazombo",
            "name": "[CAV] - Cazombo - Angola",
            "searchName": "CAV-Cazombo-Angola",
            "priority": 0
        },
        {
            "iataCode": "CBT",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Catumbela",
            "name": "[CBT] - Catumbela - Angola",
            "searchName": "CBT-Catumbela-Angola",
            "priority": 0
        },
        {
            "iataCode": "DRC",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Dirico",
            "name": "[DRC] - Dirico - Angola",
            "searchName": "DRC-Dirico-Angola",
            "priority": 0
        },
        {
            "iataCode": "DUE",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Dundo",
            "name": "[DUE] - Dundo - Angola",
            "searchName": "DUE-Dundo-Angola",
            "priority": 0
        },
        {
            "iataCode": "CTI",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Cuito Cuanavale",
            "name": "[CTI] - Cuito Cuanavale - Angola",
            "searchName": "CTI-Cuito Cuanavale-Angola",
            "priority": 0
        },
        {
            "iataCode": "CEO",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Waco Kungo",
            "name": "[CEO] - Waco Kungo - Angola",
            "searchName": "CEO-Waco Kungo-Angola",
            "priority": 0
        },
        {
            "iataCode": "CFF",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Cafunfo",
            "name": "[CFF] - Cafunfo - Angola",
            "searchName": "CFF-Cafunfo-Angola",
            "priority": 0
        },
        {
            "iataCode": "CNZ",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Cangamba",
            "name": "[CNZ] - Cangamba - Angola",
            "searchName": "CNZ-Cangamba-Angola",
            "priority": 0
        },
        {
            "iataCode": "LZM",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Luzamba",
            "name": "[LZM] - Luzamba - Angola",
            "searchName": "LZM-Luzamba-Angola",
            "priority": 0
        },
        {
            "iataCode": "MEG",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Melanje",
            "name": "[MEG] - Melanje - Angola",
            "searchName": "MEG-Melanje-Angola",
            "priority": 0
        },
        {
            "iataCode": "LUO",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Luena",
            "name": "[LUO] - Luena - Angola",
            "searchName": "LUO-Luena-Angola",
            "priority": 0
        },
        {
            "iataCode": "LLT",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Lobito",
            "name": "[LLT] - Lobito - Angola",
            "searchName": "LLT-Lobito-Angola",
            "priority": 0
        },
        {
            "iataCode": "LAD",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Luanda",
            "name": "[LAD] - Luanda - Angola",
            "searchName": "LAD-Luanda-Angola",
            "priority": 0
        },
        {
            "iataCode": "LBZ",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Lukapa",
            "name": "[LBZ] - Lukapa - Angola",
            "searchName": "LBZ-Lukapa-Angola",
            "priority": 0
        },
        {
            "iataCode": "KNP",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Capanda",
            "name": "[KNP] - Capanda - Angola",
            "searchName": "KNP-Capanda-Angola",
            "priority": 0
        },
        {
            "iataCode": "JMB",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Jamba",
            "name": "[JMB] - Jamba - Angola",
            "searchName": "JMB-Jamba-Angola",
            "priority": 0
        },
        {
            "iataCode": "GXG",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Negage",
            "name": "[GXG] - Negage - Angola",
            "searchName": "GXG-Negage-Angola",
            "priority": 0
        },
        {
            "iataCode": "GGC",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Lumbala",
            "name": "[GGC] - Lumbala - Angola",
            "searchName": "GGC-Lumbala-Angola",
            "priority": 0
        },
        {
            "iataCode": "SSY",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "M banza Congo",
            "name": "[SSY] - M banza Congo - Angola",
            "searchName": "SSY-M banza Congo-M Banza Congo-Angola",
            "priority": 0
        },
        {
            "iataCode": "SVP",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Kuito",
            "name": "[SVP] - Kuito - Angola",
            "searchName": "SVP-Kuito-Angola",
            "priority": 0
        },
        {
            "iataCode": "SZA",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Soyo",
            "name": "[SZA] - Soyo - Angola",
            "searchName": "SZA-Soyo-Angola",
            "priority": 0
        },
        {
            "iataCode": "UGO",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Uige",
            "name": "[UGO] - Uige - Angola",
            "searchName": "UGO-Uige-Angola",
            "priority": 0
        },
        {
            "iataCode": "UAL",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Luau",
            "name": "[UAL] - Luau - Angola",
            "searchName": "UAL-Luau-Angola",
            "priority": 0
        },
        {
            "iataCode": "VHC",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Saurimo",
            "name": "[VHC] - Saurimo - Angola",
            "searchName": "VHC-Saurimo-Angola",
            "priority": 0
        },
        {
            "iataCode": "VPE",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Ondjiva",
            "name": "[VPE] - Ondjiva - Angola",
            "searchName": "VPE-Ondjiva-Ongiva-Angola",
            "priority": 0
        },
        {
            "iataCode": "XGN",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Xangongo",
            "name": "[XGN] - Xangongo - Angola",
            "searchName": "XGN-Xangongo-Angola",
            "priority": 0
        },
        {
            "iataCode": "NZA",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Nzagi",
            "name": "[NZA] - Nzagi - Angola",
            "searchName": "NZA-Nzagi-Angola",
            "priority": 0
        },
        {
            "iataCode": "NOV",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Huambo",
            "name": "[NOV] - Huambo - Angola",
            "searchName": "NOV-Huambo-Angola",
            "priority": 0
        },
        {
            "iataCode": "PBN",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Porto Amboim",
            "name": "[PBN] - Porto Amboim - Angola",
            "searchName": "PBN-Porto Amboim-Angola",
            "priority": 0
        },
        {
            "iataCode": "PGI",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Chitato",
            "name": "[PGI] - Chitato - Angola",
            "searchName": "PGI-Chitato-Angola",
            "priority": 0
        },
        {
            "iataCode": "NDF",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Ndalatandos",
            "name": "[NDF] - Ndalatandos - Angola",
            "searchName": "NDF-Ndalatandos-Angola",
            "priority": 0
        },
        {
            "iataCode": "NDD",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Sumbe",
            "name": "[NDD] - Sumbe - Angola",
            "searchName": "NDD-Sumbe-Angola",
            "priority": 0
        },
        {
            "iataCode": "MSZ",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Namibe",
            "name": "[MSZ] - Namibe - Angola",
            "searchName": "MSZ-Namibe-Angola",
            "priority": 0
        },
        {
            "iataCode": "SDD",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Lubango",
            "name": "[SDD] - Lubango - Angola",
            "searchName": "SDD-Lubango-Angola",
            "priority": 0
        },
        {
            "iataCode": "SPP",
            "countryCode": "AO",
            "cityCode": null,
            "cityName": "Menongue",
            "name": "[SPP] - Menongue - Angola",
            "searchName": "SPP-Menongue-Angola",
            "priority": 0
        },
        {
            "iataCode": "TNM",
            "countryCode": "AQ",
            "cityCode": null,
            "cityName": "Teniente R. Marsh Martin",
            "name": "[TNM] - Teniente R. Marsh Martin - Antarctica",
            "searchName": "TNM-Teniente R. Marsh Martin-Antarctica-Antarctique-Antártida-Antartide",
            "priority": 0
        },
        {
            "iataCode": "TDL",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Tandil",
            "name": "[TDL] - Tandil - Argentina",
            "searchName": "TDL-Tandil-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "SZQ",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Saenz Pena",
            "name": "[SZQ] - Saenz Pena - Argentina",
            "searchName": "SZQ-Saenz Pena-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "SST",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Santa Teresita",
            "name": "[SST] - Santa Teresita - Argentina",
            "searchName": "SST-Santa Teresita-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "VGS",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "General Villegas",
            "name": "[VGS] - General Villegas - Argentina",
            "searchName": "VGS-General Villegas-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "VDM",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Viedma",
            "name": "[VDM] - Viedma - Argentina",
            "searchName": "VDM-Viedma-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "VDR",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Villa Dolores",
            "name": "[VDR] - Villa Dolores - Argentina",
            "searchName": "VDR-Villa Dolores-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "VLG",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Villa Gesell",
            "name": "[VLG] - Villa Gesell - Argentina",
            "searchName": "VLG-Villa Gesell-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "VME",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Villa Mercedes",
            "name": "[VME] - Villa Mercedes - Argentina",
            "searchName": "VME-Villa Mercedes-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "UZU",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Curuzu Cuatia",
            "name": "[UZU] - Curuzu Cuatia - Argentina",
            "searchName": "UZU-Curuzu Cuatia-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "VCF",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Valcheta",
            "name": "[VCF] - Valcheta - Argentina",
            "searchName": "VCF-Valcheta-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "UAQ",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "San Juan",
            "name": "[UAQ] - San Juan - Argentina",
            "searchName": "UAQ-San Juan-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "TTG",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Tartagal",
            "name": "[TTG] - Tartagal - Argentina",
            "searchName": "TTG-Tartagal-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "TUC",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Tucuman",
            "name": "[TUC] - Tucuman - Argentina",
            "searchName": "TUC-Tucuman-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "ULA",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "San Julian",
            "name": "[ULA] - San Julian - Argentina",
            "searchName": "ULA-San Julian-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CBX",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Condobolin",
            "name": "[CBX] - Condobolin - Australia",
            "searchName": "CBX-Condobolin-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CBY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Canobie",
            "name": "[CBY] - Canobie - Australia",
            "searchName": "CBY-Canobie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CAZ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cobar",
            "name": "[CAZ] - Cobar - Australia",
            "searchName": "CAZ-Cobar-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CBC",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cherrabun",
            "name": "[CBC] - Cherrabun - Australia",
            "searchName": "CBC-Cherrabun-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CBI",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cape Barren Island",
            "name": "[CBI] - Cape Barren Island - Australia",
            "searchName": "CBI-Cape Barren Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BZP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Bizant",
            "name": "[BZP] - Bizant - Australia",
            "searchName": "BZP-Bizant-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BWB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Barrow Island",
            "name": "[BWB] - Barrow Island - Australia",
            "searchName": "BWB-Barrow Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BWQ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Brewarrina",
            "name": "[BWQ] - Brewarrina - Australia",
            "searchName": "BWQ-Brewarrina-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BWT",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Burnie",
            "name": "[BWT] - Burnie - Australia",
            "searchName": "BWT-Burnie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BXF",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Belburn",
            "name": "[BXF] - Belburn - Australia",
            "searchName": "BXF-Belburn-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BXG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Bendigo",
            "name": "[BXG] - Bendigo - Australia",
            "searchName": "BXG-Bendigo-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BYX",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Baniyala",
            "name": "[BYX] - Baniyala - Australia",
            "searchName": "BYX-Baniyala-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BZD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Balranald",
            "name": "[BZD] - Balranald - Australia",
            "searchName": "BZD-Balranald-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FLS",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Flinders Island",
            "name": "[FLS] - Flinders Island - Australia",
            "searchName": "FLS-Flinders Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FLY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Finley",
            "name": "[FLY] - Finley - Australia",
            "searchName": "FLY-Finley-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FOS",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Forrest",
            "name": "[FOS] - Forrest - Australia",
            "searchName": "FOS-Forrest-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FOT",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Forster",
            "name": "[FOT] - Forster - Australia",
            "searchName": "FOT-Forster-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FRB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Forbes",
            "name": "[FRB] - Forbes - Australia",
            "searchName": "FRB-Forbes-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FIZ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Fitzroy Crossing",
            "name": "[FIZ] - Fitzroy Crossing - Australia",
            "searchName": "FIZ-Fitzroy Crossing-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FLC",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Falls Creek",
            "name": "[FLC] - Falls Creek - Australia",
            "searchName": "FLC-Falls Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FIK",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Finke",
            "name": "[FIK] - Finke - Australia",
            "searchName": "FIK-Finke-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ERB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Ernabella",
            "name": "[ERB] - Ernabella - Australia",
            "searchName": "ERB-Ernabella-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ERQ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Elrose Mine",
            "name": "[ERQ] - Elrose Mine - Australia",
            "searchName": "ERQ-Elrose Mine-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "EPR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Esperance",
            "name": "[EPR] - Esperance - Australia",
            "searchName": "EPR-Esperance-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ENB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Eneabba West",
            "name": "[ENB] - Eneabba West - Australia",
            "searchName": "ENB-Eneabba-Eneabba West-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "EVH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Evans Head",
            "name": "[EVH] - Evans Head - Australia",
            "searchName": "EVH-Evans Head-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "EVD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Eva Downs",
            "name": "[EVD] - Eva Downs - Australia",
            "searchName": "EVD-Eva Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ETD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Etadunna",
            "name": "[ETD] - Etadunna - Australia",
            "searchName": "ETD-Etadunna-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "EUC",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Eucla",
            "name": "[EUC] - Eucla - Australia",
            "searchName": "EUC-Eucla-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "EXM",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Exmouth Gulf",
            "name": "[EXM] - Exmouth Gulf - Australia",
            "searchName": "EXM-Exmouth Gulf-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "EMD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Emerald",
            "name": "[EMD] - Emerald - Australia",
            "searchName": "EMD-Emerald-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "EIH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Einasleigh",
            "name": "[EIH] - Einasleigh - Australia",
            "searchName": "EIH-Einasleigh-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "EKD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Elkedra",
            "name": "[EKD] - Elkedra - Australia",
            "searchName": "EKD-Elkedra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ELC",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Elcho Island",
            "name": "[ELC] - Elcho Island - Australia",
            "searchName": "ELC-Elcho Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DXD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Dixie",
            "name": "[DXD] - Dixie - Australia",
            "searchName": "DXD-Dixie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DVP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Davenport Downs",
            "name": "[DVP] - Davenport Downs - Australia",
            "searchName": "DVP-Davenport Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DVR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Daly River",
            "name": "[DVR] - Daly River - Australia",
            "searchName": "DVR-Daly River-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DYA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Dysart",
            "name": "[DYA] - Dysart - Australia",
            "searchName": "DYA-Dysart-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DYM",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Diamantina Lakes",
            "name": "[DYM] - Diamantina Lakes - Australia",
            "searchName": "DYM-Diamantina Lakes-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DYW",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Daly Waters",
            "name": "[DYW] - Daly Waters - Australia",
            "searchName": "DYW-Daly Waters-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ECH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Echuca",
            "name": "[ECH] - Echuca - Australia",
            "searchName": "ECH-Echuca-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "EDD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Erldunda",
            "name": "[EDD] - Erldunda - Australia",
            "searchName": "EDD-Erldunda-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "EDR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Edward River",
            "name": "[EDR] - Edward River - Australia",
            "searchName": "EDR-Edward River-Edward Nehri-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DRD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Dorunda Station",
            "name": "[DRD] - Dorunda Station - Australia",
            "searchName": "DRD-Dorunda Station-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DPO",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Devonport",
            "name": "[DPO] - Devonport - Australia",
            "searchName": "DPO-Devonport-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DOX",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Dongara",
            "name": "[DOX] - Dongara - Australia",
            "searchName": "DOX-Dongara-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DRB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Derby",
            "name": "[DRB] - Derby - Australia",
            "searchName": "DRB-Derby-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DRR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Durrie",
            "name": "[DRR] - Durrie - Australia",
            "searchName": "DRR-Durrie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DRN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Dirranbandi",
            "name": "[DRN] - Dirranbandi - Australia",
            "searchName": "DRN-Dirranbandi-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DRW",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Darwin",
            "name": "[DRW] - Darwin - Australia",
            "searchName": "DRW-Darwin-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DRY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Drysdale River",
            "name": "[DRY] - Drysdale River - Australia",
            "searchName": "DRY-Drysdale River-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DKI",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Dunk Island",
            "name": "[DKI] - Dunk Island - Australia",
            "searchName": "DKI-Dunk Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DKV",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Docker River",
            "name": "[DKV] - Docker River - Australia",
            "searchName": "DKV-Docker River-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DLK",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Dulkaninna",
            "name": "[DLK] - Dulkaninna - Australia",
            "searchName": "DLK-Dulkaninna-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DLV",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Delissaville",
            "name": "[DLV] - Delissaville - Australia",
            "searchName": "DLV-Delissaville-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DMD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Doomadgee",
            "name": "[DMD] - Doomadgee - Australia",
            "searchName": "DMD-Doomadgee-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DNG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Doongan",
            "name": "[DNG] - Doongan - Australia",
            "searchName": "DNG-Doongan-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DNB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Dunbar",
            "name": "[DNB] - Dunbar - Australia",
            "searchName": "DNB-Dunbar-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DNM",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Denham",
            "name": "[DNM] - Denham - Australia",
            "searchName": "DNM-Denham-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DNQ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Deniliquin",
            "name": "[DNQ] - Deniliquin - Australia",
            "searchName": "DNQ-Deniliquin-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CVQ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Carnarvon",
            "name": "[CVQ] - Carnarvon - Australia",
            "searchName": "CVQ-Carnarvon-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CUY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cue",
            "name": "[CUY] - Cue - Australia",
            "searchName": "CUY-Cue-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CVC",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cleve",
            "name": "[CVC] - Cleve - Australia",
            "searchName": "CVC-Cleve-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CUQ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Coen",
            "name": "[CUQ] - Coen - Australia",
            "searchName": "CUQ-Coen-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CUD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Caloundra",
            "name": "[CUD] - Caloundra - Australia",
            "searchName": "CUD-Caloundra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CUG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cudal",
            "name": "[CUG] - Cudal - Australia",
            "searchName": "CUG-Cudal-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CWT",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cowra",
            "name": "[CWT] - Cowra - Australia",
            "searchName": "CWT-Cowra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CWW",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Corowa",
            "name": "[CWW] - Corowa - Australia",
            "searchName": "CWW-Corowa-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CWR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cowarie",
            "name": "[CWR] - Cowarie - Australia",
            "searchName": "CWR-Cowarie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CXQ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Christmas Creek",
            "name": "[CXQ] - Christmas Creek - Australia",
            "searchName": "CXQ-Christmas Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CXT",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Charters Towers",
            "name": "[CXT] - Charters Towers - Australia",
            "searchName": "CXT-Charters Towers-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CYG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Corryong",
            "name": "[CYG] - Corryong - Australia",
            "searchName": "CYG-Corryong-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CRY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Carlton Hill",
            "name": "[CRY] - Carlton Hill - Australia",
            "searchName": "CRY-Carlton Hill-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CSD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cresswell Downs",
            "name": "[CSD] - Cresswell Downs - Australia",
            "searchName": "CSD-Cresswell Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CRB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Collarenebri",
            "name": "[CRB] - Collarenebri - Australia",
            "searchName": "CRB-Collarenebri-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CRJ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Coorabie",
            "name": "[CRJ] - Coorabie - Australia",
            "searchName": "CRJ-Coorabie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CRH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cherribah",
            "name": "[CRH] - Cherribah - Australia",
            "searchName": "CRH-Cherribah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CSI",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Casino",
            "name": "[CSI] - Casino - Australia",
            "searchName": "CSI-Casino-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CTL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Charleville",
            "name": "[CTL] - Charleville - Australia",
            "searchName": "CTL-Charleville-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CTN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cooktown",
            "name": "[CTN] - Cooktown - Australia",
            "searchName": "CTN-Cooktown-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CTR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cattle Creek",
            "name": "[CTR] - Cattle Creek - Australia",
            "searchName": "CTR-Cattle Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DBO",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Dubbo",
            "name": "[DBO] - Dubbo - Australia",
            "searchName": "DBO-Dubbo-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DBY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Dalby",
            "name": "[DBY] - Dalby - Australia",
            "searchName": "DBY-Dalby-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CZY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cluny",
            "name": "[CZY] - Cluny - Australia",
            "searchName": "CZY-Cluny-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DAJ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Dauan Island",
            "name": "[DAJ] - Dauan Island - Australia",
            "searchName": "DAJ-Dauan Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DDI",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Daydream Island",
            "name": "[DDI] - Daydream Island - Australia",
            "searchName": "DDI-Daydream Island-Daydream Is-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DDN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Delta Downs",
            "name": "[DDN] - Delta Downs - Australia",
            "searchName": "DDN-Delta Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DFP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Drumduff",
            "name": "[DFP] - Drumduff - Australia",
            "searchName": "DFP-Drumduff-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DGD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Dalgaranga",
            "name": "[DGD] - Dalgaranga - Australia",
            "searchName": "DGD-Dalgaranga-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DGE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mudgee",
            "name": "[DGE] - Mudgee - Australia",
            "searchName": "DGE-Mudgee-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DHD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Durham Downs",
            "name": "[DHD] - Durham Downs - Australia",
            "searchName": "DHD-Durham Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CCL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Chinchilla",
            "name": "[CCL] - Chinchilla - Australia",
            "searchName": "CCL-Chinchilla-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CCW",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cowell",
            "name": "[CCW] - Cowell - Australia",
            "searchName": "CCW-Cowell-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CDA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cooinda",
            "name": "[CDA] - Cooinda - Australia",
            "searchName": "CDA-Cooinda-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CDQ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Croydon",
            "name": "[CDQ] - Croydon - Australia",
            "searchName": "CDQ-Croydon-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CDU",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Camden",
            "name": "[CDU] - Camden - Australia",
            "searchName": "CDU-Camden-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CED",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Ceduna",
            "name": "[CED] - Ceduna - Australia",
            "searchName": "CED-Ceduna-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CFP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Carpentaria Downs",
            "name": "[CFP] - Carpentaria Downs - Australia",
            "searchName": "CFP-Carpentaria Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CFS",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Coffs Harbour",
            "name": "[CFS] - Coffs Harbour - Australia",
            "searchName": "CFS-Coffs Harbour-Coffs Harbor-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CFH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Clifton Hills",
            "name": "[CFH] - Clifton Hills - Australia",
            "searchName": "CFH-Clifton Hills-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CFI",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Camfield",
            "name": "[CFI] - Camfield - Australia",
            "searchName": "CFI-Camfield-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CES",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cessnock",
            "name": "[CES] - Cessnock - Australia",
            "searchName": "CES-Cessnock-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CGV",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Caiguna",
            "name": "[CGV] - Caiguna - Australia",
            "searchName": "CGV-Caiguna-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CIE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Collie",
            "name": "[CIE] - Collie - Australia",
            "searchName": "CIE-Collie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CMA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cunnamulla",
            "name": "[CMA] - Cunnamulla - Australia",
            "searchName": "CMA-Cunnamulla-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CMD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cootamundra",
            "name": "[CMD] - Cootamundra - Australia",
            "searchName": "CMD-Cootamundra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CML",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Camooweal",
            "name": "[CML] - Camooweal - Australia",
            "searchName": "CML-Camooweal-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CMQ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Clermont",
            "name": "[CMQ] - Clermont - Australia",
            "searchName": "CMQ-Clermont-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CKI",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Croker Island",
            "name": "[CKI] - Croker Island - Australia",
            "searchName": "CKI-Croker Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CKW",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Christmas Creek Mine",
            "name": "[CKW] - Christmas Creek Mine - Australia",
            "searchName": "CKW-Christmas Creek Mine-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CLH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Coolah",
            "name": "[CLH] - Coolah - Australia",
            "searchName": "CLH-Coolah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CPD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Coober Pedy",
            "name": "[CPD] - Coober Pedy - Australia",
            "searchName": "CPD-Coober Pedy-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "COY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Coolawanyah",
            "name": "[COY] - Coolawanyah - Australia",
            "searchName": "COY-Coolawanyah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "COB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Coolibah",
            "name": "[COB] - Coolibah - Australia",
            "searchName": "COB-Coolibah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CQP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cape Flattery",
            "name": "[CQP] - Cape Flattery - Australia",
            "searchName": "CQP-Cape Flattery-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CNS",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cairns",
            "name": "[CNS] - Cairns - Australia",
            "searchName": "CNS-Cairns-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CNJ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cloncurry",
            "name": "[CNJ] - Cloncurry - Australia",
            "searchName": "CNJ-Cloncurry-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CNB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Coonamble",
            "name": "[CNB] - Coonamble - Australia",
            "searchName": "CNB-Coonamble-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CNC",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Coconut Island",
            "name": "[CNC] - Coconut Island - Australia",
            "searchName": "CNC-Coconut Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "COJ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Coonabarabran",
            "name": "[COJ] - Coonabarabran - Australia",
            "searchName": "COJ-Coonabarabran-Coonabarabrn-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KKP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Koolburra",
            "name": "[KKP] - Koolburra - Australia",
            "searchName": "KKP-Koolburra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KNS",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "King Island",
            "name": "[KNS] - King Island - Australia",
            "searchName": "KNS-King Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KNX",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Kununurra",
            "name": "[KNX] - Kununurra - Australia",
            "searchName": "KNX-Kununurra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KOH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Koolatah",
            "name": "[KOH] - Koolatah - Australia",
            "searchName": "KOH-Koolatah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KML",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Kamileroi",
            "name": "[KML] - Kamileroi - Australia",
            "searchName": "KML-Kamileroi-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KNI",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Katanning",
            "name": "[KNI] - Katanning - Australia",
            "searchName": "KNI-Katanning-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KGR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Kulgera",
            "name": "[KGR] - Kulgera - Australia",
            "searchName": "KGR-Kulgera-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KGY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Kingaroy",
            "name": "[KGY] - Kingaroy - Australia",
            "searchName": "KGY-Kingaroy-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KFE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cloudbreak",
            "name": "[KFE] - Cloudbreak - Australia",
            "searchName": "KFE-Cloudbreak-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KFG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Kalkurung",
            "name": "[KFG] - Kalkurung - Australia",
            "searchName": "KFG-Kalkurung-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KGC",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Kingscote",
            "name": "[KGC] - Kingscote - Australia",
            "searchName": "KGC-Kingscote-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KGI",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Kalgoorlie",
            "name": "[KGI] - Kalgoorlie - Australia",
            "searchName": "KGI-Kalgoorlie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KDS",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Kamaran Downs",
            "name": "[KDS] - Kamaran Downs - Australia",
            "searchName": "KDS-Kamaran Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KCS",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Kings Creek Station",
            "name": "[KCS] - Kings Creek Station - Australia",
            "searchName": "KCS-Kings Creek Station-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KDB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Kambalda",
            "name": "[KDB] - Kambalda - Australia",
            "searchName": "KDB-Kambalda-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KBJ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Kings Canyon",
            "name": "[KBJ] - Kings Canyon - Australia",
            "searchName": "KBJ-Kings Canyon-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KAX",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Kalbarri",
            "name": "[KAX] - Kalbarri - Australia",
            "searchName": "KAX-Kalbarri-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KBB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Kirkimbie",
            "name": "[KBB] - Kirkimbie - Australia",
            "searchName": "KBB-Kirkimbie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KBD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Kimberley Downs",
            "name": "[KBD] - Kimberley Downs - Australia",
            "searchName": "KBD-Kimberley Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KBY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Streaky Bay",
            "name": "[KBY] - Streaky Bay - Australia",
            "searchName": "KBY-Streaky Bay-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KCE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Collinsville",
            "name": "[KCE] - Collinsville - Australia",
            "searchName": "KCE-Collinsville-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LCN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Balcanoona",
            "name": "[LCN] - Balcanoona - Australia",
            "searchName": "LCN-Balcanoona-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LDC",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Lindeman Island",
            "name": "[LDC] - Lindeman Island - Australia",
            "searchName": "LDC-Lindeman Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LDH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Lord Howe Island",
            "name": "[LDH] - Lord Howe Island - Australia",
            "searchName": "LDH-Lord Howe Island--Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LDW",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Lansdowne",
            "name": "[LDW] - Lansdowne - Australia",
            "searchName": "LDW-Lansdowne-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LEA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Learmonth",
            "name": "[LEA] - Learmonth - Australia",
            "searchName": "LEA-Learmonth-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LEL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Lake Evella",
            "name": "[LEL] - Lake Evella - Australia",
            "searchName": "LEL-Lake Evella-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KWM",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Kowanyama",
            "name": "[KWM] - Kowanyama - Australia",
            "searchName": "KWM-Kowanyama-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KYF",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Yeelirrie",
            "name": "[KYF] - Yeelirrie - Australia",
            "searchName": "KYF-Yeelirrie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KYI",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Yalata Mission",
            "name": "[KYI] - Yalata Mission - Australia",
            "searchName": "KYI-Yalata Mission-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KSV",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Springvale",
            "name": "[KSV] - Springvale - Australia",
            "searchName": "KSV-Springvale-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KTA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Karratha",
            "name": "[KTA] - Karratha - Australia",
            "searchName": "KTA-Karratha-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KTR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Katherine",
            "name": "[KTR] - Katherine - Australia",
            "searchName": "KTR-Katherine-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KUG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Kubin Island",
            "name": "[KUG] - Kubin Island - Australia",
            "searchName": "KUG-Kubin Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KPS",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Kempsey",
            "name": "[KPS] - Kempsey - Australia",
            "searchName": "KPS-Kempsey-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KPP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Kalpowar",
            "name": "[KPP] - Kalpowar - Australia",
            "searchName": "KPP-Kalpowar-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KRA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Kerang",
            "name": "[KRA] - Kerang - Australia",
            "searchName": "KRA-Kerang-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KRB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Karumba",
            "name": "[KRB] - Karumba - Australia",
            "searchName": "KRB-Karumba-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KRD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Kurundi",
            "name": "[KRD] - Kurundi - Australia",
            "searchName": "KRD-Kurundi-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LOA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Lorraine",
            "name": "[LOA] - Lorraine - Australia",
            "searchName": "LOA-Lorraine-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LOC",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Lock",
            "name": "[LOC] - Lock - Australia",
            "searchName": "LOC-Lock-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LNH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Lake Nash",
            "name": "[LNH] - Lake Nash - Australia",
            "searchName": "LNH-Lake Nash-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LNO",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Leonora",
            "name": "[LNO] - Leonora - Australia",
            "searchName": "LNO-Leonora-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LRE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Longreach",
            "name": "[LRE] - Longreach - Australia",
            "searchName": "LRE-Longreach-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LUT",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Laura Station",
            "name": "[LUT] - Laura Station - Australia",
            "searchName": "LUT-Laura Station-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LUU",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Laura",
            "name": "[LUU] - Laura - Australia",
            "searchName": "LUU-Laura-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LTP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Lyndhurst",
            "name": "[LTP] - Lyndhurst - Australia",
            "searchName": "LTP-Lyndhurst-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LTV",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Lotusvale",
            "name": "[LTV] - Lotusvale - Australia",
            "searchName": "LTV-Lotusvale-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LST",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Launceston",
            "name": "[LST] - Launceston - Australia",
            "searchName": "LST-Launceston-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LSY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Lismore",
            "name": "[LSY] - Lismore - Australia",
            "searchName": "LSY-Lismore-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LTB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Latrobe",
            "name": "[LTB] - Latrobe - Australia",
            "searchName": "LTB-Latrobe-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LHG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Lightning Ridge",
            "name": "[LHG] - Lightning Ridge - Australia",
            "searchName": "LHG-Lightning Ridge-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LIB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Limbunya",
            "name": "[LIB] - Limbunya - Australia",
            "searchName": "LIB-Limbunya-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LER",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Leinster",
            "name": "[LER] - Leinster - Australia",
            "searchName": "LER-Leinster-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LFP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Lakefield",
            "name": "[LFP] - Lakefield - Australia",
            "searchName": "LFP-Lakefield-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LGE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Lake Gregory",
            "name": "[LGE] - Lake Gregory - Australia",
            "searchName": "LGE-Lake Gregory-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LGH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Leigh Creek",
            "name": "[LGH] - Leigh Creek - Australia",
            "searchName": "LGH-Leigh Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LLP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Linda Downs",
            "name": "[LLP] - Linda Downs - Australia",
            "searchName": "LLP-Linda Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LLL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Lissadell",
            "name": "[LLL] - Lissadell - Australia",
            "searchName": "LLL-Lissadell-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LKD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Lakeland Downs",
            "name": "[LKD] - Lakeland Downs - Australia",
            "searchName": "LKD-Lakeland Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LLG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Chillagoe",
            "name": "[LLG] - Chillagoe - Australia",
            "searchName": "LLG-Chillagoe-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MCY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Sunshine Coast",
            "name": "[MCY] - Sunshine Coast - Australia",
            "searchName": "MCY-Sunshine Coast-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MCV",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mcarthur River",
            "name": "[MCV] - Mcarthur River - Australia",
            "searchName": "MCV-Mcarthur River-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MBB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Marble Bar",
            "name": "[MBB] - Marble Bar - Australia",
            "searchName": "MBB-Marble Bar-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MBF",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mount Buffalo",
            "name": "[MBF] - Mount Buffalo - Australia",
            "searchName": "MBF-Mount Buffalo-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MBH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Maryborough",
            "name": "[MBH] - Maryborough - Australia",
            "searchName": "MBH-Maryborough-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MBN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mount Barnett",
            "name": "[MBN] - Mount Barnett - Australia",
            "searchName": "MBN-Mount Barnett-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LYT",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Lady Elliot Island",
            "name": "[LYT] - Lady Elliot Island - Australia",
            "searchName": "LYT-Lady Elliot Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LZR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Lizard Island",
            "name": "[LZR] - Lizard Island - Australia",
            "searchName": "LZR-Lizard Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LVO",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Laverton",
            "name": "[LVO] - Laverton - Australia",
            "searchName": "LVO-Laverton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LWH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Lawn Hill",
            "name": "[LWH] - Lawn Hill - Australia",
            "searchName": "LWH-Lawn Hill-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ZBL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Biloela",
            "name": "[ZBL] - Biloela - Australia",
            "searchName": "ZBL-Biloela-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ZBO",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Bowen",
            "name": "[ZBO] - Bowen - Australia",
            "searchName": "ZBO-Bowen-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ZGL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "South Galway",
            "name": "[ZGL] - South Galway - Australia",
            "searchName": "ZGL-South Galway-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "YUE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Yuendumu",
            "name": "[YUE] - Yuendumu - Australia",
            "searchName": "YUE-Yuendumu-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "YNN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Yandicoogina",
            "name": "[YNN] - Yandicoogina - Australia",
            "searchName": "YNN-Yandicoogina-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "YLG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Yalgoo",
            "name": "[YLG] - Yalgoo - Australia",
            "searchName": "YLG-Yalgoo-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XMY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Yam Island",
            "name": "[XMY] - Yam Island - Australia",
            "searchName": "XMY-Yam Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XMC",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mallacoota",
            "name": "[XMC] - Mallacoota - Australia",
            "searchName": "XMC-Mallacoota-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XML",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Minlaton",
            "name": "[XML] - Minlaton - Australia",
            "searchName": "XML-Minlaton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XRH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Richmond",
            "name": "[XRH] - Richmond - Australia",
            "searchName": "XRH-Richmond-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XTG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Thargomindah",
            "name": "[XTG] - Thargomindah - Australia",
            "searchName": "XTG-Thargomindah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XTO",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Taroom",
            "name": "[XTO] - Taroom - Australia",
            "searchName": "XTO-Taroom-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XTR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Tara",
            "name": "[XTR] - Tara - Australia",
            "searchName": "XTR-Tara-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WWI",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Woodie Woodie",
            "name": "[WWI] - Woodie Woodie - Australia",
            "searchName": "WWI-Woodie Woodie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WWY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "West Wyalong",
            "name": "[WWY] - West Wyalong - Australia",
            "searchName": "WWY-West Wyalong-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WYA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Whyalla",
            "name": "[WYA] - Whyalla - Australia",
            "searchName": "WYA-Whyalla-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WYN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Wyndham",
            "name": "[WYN] - Wyndham - Australia",
            "searchName": "WYN-Wyndham-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XCO",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Colac",
            "name": "[XCO] - Colac - Australia",
            "searchName": "XCO-Colac-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WHL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Welshpool",
            "name": "[WHL] - Welshpool - Australia",
            "searchName": "WHL-Welshpool-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WIN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Winton",
            "name": "[WIN] - Winton - Australia",
            "searchName": "WIN-Winton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WIO",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Wilcannia",
            "name": "[WIO] - Wilcannia - Australia",
            "searchName": "WIO-Wilcannia-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WIT",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Wittenoom",
            "name": "[WIT] - Wittenoom - Australia",
            "searchName": "WIT-Wittenoom-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WKB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Warracknabeal",
            "name": "[WKB] - Warracknabeal - Australia",
            "searchName": "WKB-Warracknabeal-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WLA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Wallal",
            "name": "[WLA] - Wallal - Australia",
            "searchName": "WLA-Wallal-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WLC",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Walcha",
            "name": "[WLC] - Walcha - Australia",
            "searchName": "WLC-Walcha-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WLL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Wollogorang",
            "name": "[WLL] - Wollogorang - Australia",
            "searchName": "WLL-Wollogorang-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WLO",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Waterloo",
            "name": "[WLO] - Waterloo - Australia",
            "searchName": "WLO-Waterloo-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WMB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Warrnambool",
            "name": "[WMB] - Warrnambool - Australia",
            "searchName": "WMB-Warrnambool-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WME",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mount Keith",
            "name": "[WME] - Mount Keith - Australia",
            "searchName": "WME-Mount Keith-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WND",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Windarra",
            "name": "[WND] - Windarra - Australia",
            "searchName": "WND-Windarra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WNR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Windorah",
            "name": "[WNR] - Windorah - Australia",
            "searchName": "WNR-Windorah-Windarra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WOL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Wollongong",
            "name": "[WOL] - Wollongong - Australia",
            "searchName": "WOL-Wollongong-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WON",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Wondoola",
            "name": "[WON] - Wondoola - Australia",
            "searchName": "WON-Wondoola-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WRW",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Warrawagine",
            "name": "[WRW] - Warrawagine - Australia",
            "searchName": "WRW-Warrawagine-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WPK",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Wrotham Park",
            "name": "[WPK] - Wrotham Park - Australia",
            "searchName": "WPK-Wrotham-Wrotham Park-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WSY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Airlie Beach",
            "name": "[WSY] - Airlie Beach - Australia",
            "searchName": "WSY-Airlie Beach-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WUD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Wudinna",
            "name": "[WUD] - Wudinna - Australia",
            "searchName": "WUD-Wudinna-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WUN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Wiluna",
            "name": "[WUN] - Wiluna - Australia",
            "searchName": "WUN-Wiluna-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "VCD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Victoria River Downs",
            "name": "[VCD] - Victoria River Downs - Australia",
            "searchName": "VCD-Victoria River Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "UTB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Muttaburra",
            "name": "[UTB] - Muttaburra - Australia",
            "searchName": "UTB-Muttaburra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "UTD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Nutwood Downs",
            "name": "[UTD] - Nutwood Downs - Australia",
            "searchName": "UTD-Nutwood Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "VNR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Vanrook",
            "name": "[VNR] - Vanrook - Australia",
            "searchName": "VNR-Vanrook-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WAN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Waverney",
            "name": "[WAN] - Waverney - Australia",
            "searchName": "WAN-Waverney-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WAU",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Wauchope",
            "name": "[WAU] - Wauchope - Australia",
            "searchName": "WAU-Wauchope-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WAV",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Wave Hill",
            "name": "[WAV] - Wave Hill - Australia",
            "searchName": "WAV-Wave Hill-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WAZ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Warwick",
            "name": "[WAZ] - Warwick - Australia",
            "searchName": "WAZ-Warwick-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WDI",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Wondai",
            "name": "[WDI] - Wondai - Australia",
            "searchName": "WDI-Wondai-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WEI",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Weipa",
            "name": "[WEI] - Weipa - Australia",
            "searchName": "WEI-Weipa-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WEW",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Wee Waa",
            "name": "[WEW] - Wee Waa - Australia",
            "searchName": "WEW-Wee Waa-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WGA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Wagga Wagga",
            "name": "[WGA] - Wagga Wagga - Australia",
            "searchName": "WGA-Wagga Wagga-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WGE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Walgett",
            "name": "[WGE] - Walgett - Australia",
            "searchName": "WGE-Walgett-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WGT",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Wangaratta",
            "name": "[WGT] - Wangaratta - Australia",
            "searchName": "WGT-Wangaratta-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "USL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Useless Loop",
            "name": "[USL] - Useless Loop - Australia",
            "searchName": "USL-Useless Loop-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ULP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Quilpie",
            "name": "[ULP] - Quilpie - Australia",
            "searchName": "ULP-Quilpie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "UMR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Woomera",
            "name": "[UMR] - Woomera - Australia",
            "searchName": "UMR-Woomera-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "UIR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Quirindi",
            "name": "[UIR] - Quirindi - Australia",
            "searchName": "UIR-Quirindi-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "UEE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Queenstown",
            "name": "[UEE] - Queenstown - Australia",
            "searchName": "UEE-Queenstown-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "UBU",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Kalumburu",
            "name": "[UBU] - Kalumburu - Australia",
            "searchName": "UBU-Kalumburu-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "UDA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Undarra",
            "name": "[UDA] - Undarra - Australia",
            "searchName": "UDA-Undarra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TUM",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Tumut",
            "name": "[TUM] - Tumut - Australia",
            "searchName": "TUM-Tumut-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TRO",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Taree",
            "name": "[TRO] - Taree - Australia",
            "searchName": "TRO-Taree-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TSV",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Townsville",
            "name": "[TSV] - Townsville - Australia",
            "searchName": "TSV-Townsville-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "UBB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mabuiag Island",
            "name": "[UBB] - Mabuiag Island - Australia",
            "searchName": "UBB-Mabuiag Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TYG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Thylungra",
            "name": "[TYG] - Thylungra - Australia",
            "searchName": "TYG-Thylungra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TYP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Tobermorey",
            "name": "[TYP] - Tobermorey - Australia",
            "searchName": "TYP-Tobermorey-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TWB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Toowoomba",
            "name": "[TWB] - Toowoomba - Australia",
            "searchName": "TWB-Toowoomba-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TWN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Tewantin",
            "name": "[TWN] - Tewantin - Australia",
            "searchName": "TWN-Tewantin-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TWP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Torwood",
            "name": "[TWP] - Torwood - Australia",
            "searchName": "TWP-Torwood-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TXR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Tanbar",
            "name": "[TXR] - Tanbar - Australia",
            "searchName": "TXR-Tanbar-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TYB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Tibooburra",
            "name": "[TYB] - Tibooburra - Australia",
            "searchName": "TYB-Tibooburra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SYU",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Sue Island",
            "name": "[SYU] - Sue Island - Australia",
            "searchName": "SYU-Warraber Island-Sue Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SXE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Sale",
            "name": "[SXE] - Sale - Australia",
            "searchName": "SXE-Sale-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BRC",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "San Carlos DeBariloche",
            "name": "[BRC] - San Carlos DeBariloche - Argentina",
            "searchName": "BRC-Bariloche-San Carlos DeBariloche-San Carlos de Bariloche-San Carlos-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "BHI",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Bahia Blanca",
            "name": "[BHI] - Bahia Blanca - Argentina",
            "searchName": "BHI-Bah-Bahia Blanca-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "AFA",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "San Rafael",
            "name": "[AFA] - San Rafael - Argentina",
            "searchName": "AFA-San Rafael-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "ARR",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Alto Rio Senguerr",
            "name": "[ARR] - Alto Rio Senguerr - Argentina",
            "searchName": "ARR-Alto Rio Senguerr-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "APZ",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Zapala",
            "name": "[APZ] - Zapala - Argentina",
            "searchName": "APZ-Zapala-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "AOL",
            "countryCode": "AR",
            "cityCode": null,
            "cityName": "Paso De Los Libres",
            "name": "[AOL] - Paso De Los Libres - Argentina",
            "searchName": "AOL-Paso de los Libres-Paso De Los Libres-Paso de Los Libres-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "FTI",
            "countryCode": "AS",
            "cityCode": null,
            "cityName": "Fitiuta",
            "name": "[FTI] - Fitiuta - American Samoa",
            "searchName": "FTI-Fitiuta-Amerikanisch-Samoa-American Samoa-Amerikan Samoasi-Samoa américaines-Samoa Americana-Samoa Americane",
            "priority": 0
        },
        {
            "iataCode": "OFU",
            "countryCode": "AS",
            "cityCode": null,
            "cityName": "Vaoto",
            "name": "[OFU] - Vaoto - American Samoa",
            "searchName": "OFU-Ofu Island-Vaoto-Amerikanisch-Samoa-American Samoa-Amerikan Samoasi-Samoa américaines-Samoa Americana-Samoa Americane",
            "priority": 0
        },
        {
            "iataCode": "TAV",
            "countryCode": "AS",
            "cityCode": null,
            "cityName": "Fiti Uta Village",
            "name": "[TAV] - Fiti Uta Village - American Samoa",
            "searchName": "TAV-Fiti Uta Village-Amerikanisch-Samoa-American Samoa-Amerikan Samoasi-Samoa américaines-Samoa Americana-Samoa Americane",
            "priority": 0
        },
        {
            "iataCode": "LWN",
            "countryCode": "AM",
            "cityCode": null,
            "cityName": "Gyoumri",
            "name": "[LWN] - Gyoumri - Armenia",
            "searchName": "LWN-Gjumri-Gyoumri-Gyumri-G-Örményország-Armènia-Ermenistan-Arménie-Armenia-Armenija-Armeenia--Armenien",
            "priority": 0
        },
        {
            "iataCode": "LNZ",
            "countryCode": "AT",
            "cityCode": null,
            "cityName": "Linz",
            "name": "[LNZ] - Linz - Austria",
            "searchName": "LNZ-Linz-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "KLU",
            "countryCode": "AT",
            "cityCode": null,
            "cityName": "Klagenfurt",
            "name": "[KLU] - Klagenfurt - Austria",
            "searchName": "KLU-Klagenfurt-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "ANT",
            "countryCode": "AT",
            "cityCode": null,
            "cityName": "St Anton",
            "name": "[ANT] - St Anton - Austria",
            "searchName": "ANT-St Anton-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "ANZ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Angus Downs",
            "name": "[ANZ] - Angus Downs - Australia",
            "searchName": "ANZ-Angus Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AMT",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Amata",
            "name": "[AMT] - Amata - Australia",
            "searchName": "AMT-Amata NT-Amata-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AMX",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Ammaroo",
            "name": "[AMX] - Ammaroo - Australia",
            "searchName": "AMX-Ammaroo-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ALH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Albany",
            "name": "[ALH] - Albany - Australia",
            "searchName": "ALH-Albany-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AGW",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Agnew",
            "name": "[AGW] - Agnew - Australia",
            "searchName": "AGW-Agnew-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XGZ",
            "countryCode": "AT",
            "cityCode": null,
            "cityName": "Bregenz",
            "name": "[XGZ] - Bregenz - Austria",
            "searchName": "XGZ-Bregenz-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "XJS",
            "countryCode": "AT",
            "cityCode": null,
            "cityName": "Kitzbuehl",
            "name": "[XJS] - Kitzbuehl - Austria",
            "searchName": "XJS-Kitzbuehl-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "XOI",
            "countryCode": "AT",
            "cityCode": null,
            "cityName": "Ischgl",
            "name": "[XOI] - Ischgl - Austria",
            "searchName": "XOI-Ischgl-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "XOO",
            "countryCode": "AT",
            "cityCode": null,
            "cityName": "Soelden",
            "name": "[XOO] - Soelden - Austria",
            "searchName": "XOO-Soelden-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "XOW",
            "countryCode": "AT",
            "cityCode": null,
            "cityName": "Seefeld",
            "name": "[XOW] - Seefeld - Austria",
            "searchName": "XOW-Seefeld-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "ZRS",
            "countryCode": "AT",
            "cityCode": null,
            "cityName": "Zurs",
            "name": "[ZRS] - Zurs - Austria",
            "searchName": "ZRS-Zurs-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "QXZ",
            "countryCode": "AT",
            "cityCode": null,
            "cityName": "Woergl",
            "name": "[QXZ] - Woergl - Austria",
            "searchName": "QXZ-Woergl-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "GRZ",
            "countryCode": "AT",
            "cityCode": null,
            "cityName": "Graz",
            "name": "[GRZ] - Graz - Austria",
            "searchName": "GRZ-Graz-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "HOH",
            "countryCode": "AT",
            "cityCode": null,
            "cityName": "Hohenems",
            "name": "[HOH] - Hohenems - Austria",
            "searchName": "HOH-Hohenems-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "AYD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Alroy Downs",
            "name": "[AYD] - Alroy Downs - Australia",
            "searchName": "AYD-Alroy Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AYL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Anthony Lagoon",
            "name": "[AYL] - Anthony Lagoon - Australia",
            "searchName": "AYL-Anthony Lagoon-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AYQ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Ayers Rock",
            "name": "[AYQ] - Ayers Rock - Australia",
            "searchName": "AYQ-Ayers Rock-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AYR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Ayr",
            "name": "[AYR] - Ayr - Australia",
            "searchName": "AYR-Ayr-Ayr Au-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AXC",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Aramac",
            "name": "[AXC] - Aramac - Australia",
            "searchName": "AXC-Aramac-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AWN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Alton Downs",
            "name": "[AWN] - Alton Downs - Australia",
            "searchName": "AWN-Alton Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AWP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Austral Downs",
            "name": "[AWP] - Austral Downs - Australia",
            "searchName": "AWP-Austral Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AXL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Alexandria",
            "name": "[AXL] - Alexandria - Australia",
            "searchName": "AXL-Alexandria-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AVG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Auvergne",
            "name": "[AVG] - Auvergne - Australia",
            "searchName": "AVG-Auvergne-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BCI",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Barcaldine",
            "name": "[BCI] - Barcaldine - Australia",
            "searchName": "BCI-Barcaldine-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BCK",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Bolwarra",
            "name": "[BCK] - Bolwarra - Australia",
            "searchName": "BCK-Bolwarra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BCZ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Bickerton Island",
            "name": "[BCZ] - Bickerton Island - Australia",
            "searchName": "BCZ-Bickerton Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BDD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Badu Island",
            "name": "[BDD] - Badu Island - Australia",
            "searchName": "BDD-Badu Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BDB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Bundaberg",
            "name": "[BDB] - Bundaberg - Australia",
            "searchName": "BDB-Bundaberg-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BHQ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Broken Hill",
            "name": "[BHQ] - Broken Hill - Australia",
            "searchName": "BHQ-Broken Hill-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BIW",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Billiluna",
            "name": "[BIW] - Billiluna - Australia",
            "searchName": "BIW-Billiluna Station-Billiluna-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BHS",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Bathurst",
            "name": "[BHS] - Bathurst - Australia",
            "searchName": "BHS-Bathurst-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BHT",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Brighton Downs",
            "name": "[BHT] - Brighton Downs - Australia",
            "searchName": "BHT-Brighton Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BIP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Bulimba",
            "name": "[BIP] - Bulimba - Australia",
            "searchName": "BIP-Bulimba-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BDW",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Bedford Downs",
            "name": "[BDW] - Bedford Downs - Australia",
            "searchName": "BDW-Bedford Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BEE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Beagle Bay",
            "name": "[BEE] - Beagle Bay - Australia",
            "searchName": "BEE-Beagle Bay-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BEU",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Bedourie",
            "name": "[BEU] - Bedourie - Australia",
            "searchName": "BEU-Bedourie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BFC",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Bloomfield",
            "name": "[BFC] - Bloomfield - Australia",
            "searchName": "BFC-Bloomfield-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BKP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Barkly Downs",
            "name": "[BKP] - Barkly Downs - Australia",
            "searchName": "BKP-Barkly Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BKQ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Blackall",
            "name": "[BKQ] - Blackall - Australia",
            "searchName": "BKQ-Blackall-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BLN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Benalla",
            "name": "[BLN] - Benalla - Australia",
            "searchName": "BLN-Benalla-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BLS",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Bollon",
            "name": "[BLS] - Bollon - Australia",
            "searchName": "BLS-Bollon-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BLT",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Blackwater",
            "name": "[BLT] - Blackwater - Australia",
            "searchName": "BLT-Blackwater-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BME",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Broome",
            "name": "[BME] - Broome - Australia",
            "searchName": "BME-Broome-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BMP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Brampton Island",
            "name": "[BMP] - Brampton Island - Australia",
            "searchName": "BMP-Brampton Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BNK",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Ballina",
            "name": "[BNK] - Ballina - Australia",
            "searchName": "BNK-Ballina-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BOX",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Borroloola",
            "name": "[BOX] - Borroloola - Australia",
            "searchName": "BOX-Borroloola-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BQW",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Balgo Hills",
            "name": "[BQW] - Balgo Hills - Australia",
            "searchName": "BQW-Balgo Hills-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BRJ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Bright",
            "name": "[BRJ] - Bright - Australia",
            "searchName": "BRJ-Bright-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BRK",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Bourke",
            "name": "[BRK] - Bourke - Australia",
            "searchName": "BRK-Bourke-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BQB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Bussellton",
            "name": "[BQB] - Bussellton - Australia",
            "searchName": "BQB-Bussellton-Busselton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BQL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Boulia",
            "name": "[BQL] - Boulia - Australia",
            "searchName": "BQL-Boulia-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BSJ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Bairnsdale",
            "name": "[BSJ] - Bairnsdale - Australia",
            "searchName": "BSJ-Bairnsdale-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BRT",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Bathurst Island",
            "name": "[BRT] - Bathurst Island - Australia",
            "searchName": "BRT-Bathurst Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BUC",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Burketown",
            "name": "[BUC] - Burketown - Australia",
            "searchName": "BUC-Burketown-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BTX",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Betoota",
            "name": "[BTX] - Betoota - Australia",
            "searchName": "BTX-Betoota-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BTD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Brunette Downs",
            "name": "[BTD] - Brunette Downs - Australia",
            "searchName": "BTD-Brunette Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BUY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Bunbury",
            "name": "[BUY] - Bunbury - Australia",
            "searchName": "BUY-Bunbury-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BVI",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Birdsville",
            "name": "[BVI] - Birdsville - Australia",
            "searchName": "BVI-Birdsville-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BVW",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Batavia Downs",
            "name": "[BVW] - Batavia Downs - Australia",
            "searchName": "BVW-Batavia Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BVZ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Beverley Springs",
            "name": "[BVZ] - Beverley Springs - Australia",
            "searchName": "BVZ-Beverley Springs-Beverly Springs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MET",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Moreton",
            "name": "[MET] - Moreton - Australia",
            "searchName": "MET-Moreton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MFL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mount Full Stop",
            "name": "[MFL] - Mount Full Stop - Australia",
            "searchName": "MFL-Mount Full Stop-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MFP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Manners Creek",
            "name": "[MFP] - Manners Creek - Australia",
            "searchName": "MFP-Manners Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MGB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mount Gambier",
            "name": "[MGB] - Mount Gambier - Australia",
            "searchName": "MGB-Mount Gambier-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MGT",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Milingimbi",
            "name": "[MGT] - Milingimbi - Australia",
            "searchName": "MGT-Milingimbi-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MGV",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Margaret River Station",
            "name": "[MGV] - Margaret River Station - Australia",
            "searchName": "MGV-Margaret River Station-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MHO",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mount House",
            "name": "[MHO] - Mount House - Australia",
            "searchName": "MHO-Mount House-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MHU",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mount Hotham",
            "name": "[MHU] - Mount Hotham - Australia",
            "searchName": "MHU-Mount Hotham-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MIH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mitchell Plateau",
            "name": "[MIH] - Mitchell Plateau - Australia",
            "searchName": "MIH-Mitchell Plateau-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MIM",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Merimbula",
            "name": "[MIM] - Merimbula - Australia",
            "searchName": "MIM-Merimbula-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MIN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Minnipa",
            "name": "[MIN] - Minnipa - Australia",
            "searchName": "MIN-Minnipa-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MIY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mittiebah",
            "name": "[MIY] - Mittiebah - Australia",
            "searchName": "MIY-Mittiebah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MIZ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mainoru",
            "name": "[MIZ] - Mainoru - Australia",
            "searchName": "MIZ-Mainoru-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MJK",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Monkey Mia",
            "name": "[MJK] - Monkey Mia - Australia",
            "searchName": "MJK-Monkey Mia-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MJP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Manjimup",
            "name": "[MJP] - Manjimup - Australia",
            "searchName": "MJP-Manjimup-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "INJ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Injune",
            "name": "[INJ] - Injune - Australia",
            "searchName": "INJ-Injune-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "INM",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Innamincka",
            "name": "[INM] - Innamincka - Australia",
            "searchName": "INM-Innamincka-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "IGH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Ingham",
            "name": "[IGH] - Ingham - Australia",
            "searchName": "IGH-Ingham-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "IFF",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Iffley",
            "name": "[IFF] - Iffley - Australia",
            "searchName": "IFF-Iffley-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "IFL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Innisfail",
            "name": "[IFL] - Innisfail - Australia",
            "searchName": "IFL-Innisfail-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "IDK",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Indulkana",
            "name": "[IDK] - Indulkana - Australia",
            "searchName": "IDK-Indulkana-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "IKP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Inkerman",
            "name": "[IKP] - Inkerman - Australia",
            "searchName": "IKP-Inkerman-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HPE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Hope Vale",
            "name": "[HPE] - Hope Vale - Australia",
            "searchName": "HPE-Hope Vale-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HRN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Heron Island",
            "name": "[HRN] - Heron Island - Australia",
            "searchName": "HRN-Heron Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HRY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Henbury",
            "name": "[HRY] - Henbury - Australia",
            "searchName": "HRY-Henbury-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HSM",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Horsham",
            "name": "[HSM] - Horsham - Australia",
            "searchName": "HSM-Horsham-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HTI",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Hamilton Island",
            "name": "[HTI] - Hamilton Island - Australia",
            "searchName": "HTI-Hamilton Island--Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HTU",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Hopetoun",
            "name": "[HTU] - Hopetoun - Australia",
            "searchName": "HTU-Hopetown-Hopetoun-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HUB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Humbert River",
            "name": "[HUB] - Humbert River - Australia",
            "searchName": "HUB-Humbert River-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HVB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Hervey Bay",
            "name": "[HVB] - Hervey Bay - Australia",
            "searchName": "HVB-Hervey Bay-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HWK",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Hawker",
            "name": "[HWK] - Hawker - Australia",
            "searchName": "HWK-Hawker-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HXX",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Hay",
            "name": "[HXX] - Hay - Australia",
            "searchName": "HXX-Hay-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "IRG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Lockhart River",
            "name": "[IRG] - Lockhart River - Australia",
            "searchName": "IRG-Lockhart River-Lockhart Nehri-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ISA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mount Isa",
            "name": "[ISA] - Mount Isa - Australia",
            "searchName": "ISA-Mount Isa-Mont Isa-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ISI",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Isisford",
            "name": "[ISI] - Isisford - Australia",
            "searchName": "ISI-Isisford-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "JAB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Jabiru",
            "name": "[JAB] - Jabiru - Australia",
            "searchName": "JAB-Jabiru-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "IVR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Inverell",
            "name": "[IVR] - Inverell - Australia",
            "searchName": "IVR-Inverell-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "IVW",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Inverway",
            "name": "[IVW] - Inverway - Australia",
            "searchName": "IVW-Inverway-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "JCK",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Julia Creek",
            "name": "[JCK] - Julia Creek - Australia",
            "searchName": "JCK-Julia Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "JFM",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Fremantle",
            "name": "[JFM] - Fremantle - Australia",
            "searchName": "JFM-Fremantle-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "JUN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Jundah",
            "name": "[JUN] - Jundah - Australia",
            "searchName": "JUN-Jundah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "JUR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Jurien Bay",
            "name": "[JUR] - Jurien Bay - Australia",
            "searchName": "JUR-Jurien Bay-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HOK",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Hooker Creek",
            "name": "[HOK] - Hooker Creek - Australia",
            "searchName": "HOK-Hooker Creek-Hooker-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HMG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Hermannsburg",
            "name": "[HMG] - Hermannsburg - Australia",
            "searchName": "HMG-Hermannsburg-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HIP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Headingly",
            "name": "[HIP] - Headingly - Australia",
            "searchName": "HIP-Headingly-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HIS",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Hayman Island",
            "name": "[HIS] - Hayman Island - Australia",
            "searchName": "HIS-Hayman Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HLL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Hillside",
            "name": "[HLL] - Hillside - Australia",
            "searchName": "HLL-Hillside-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HLS",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "St Helens",
            "name": "[HLS] - St Helens - Australia",
            "searchName": "HLS-St. Helens-St Helens-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HLT",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Hamilton",
            "name": "[HLT] - Hamilton - Australia",
            "searchName": "HLT-Hamilton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HLV",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Helenvale",
            "name": "[HLV] - Helenvale - Australia",
            "searchName": "HLV-Helenvale-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HCQ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Halls Creek",
            "name": "[HCQ] - Halls Creek - Australia",
            "searchName": "HCQ-Halls Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HGD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Hughenden",
            "name": "[HGD] - Hughenden - Australia",
            "searchName": "HGD-Hughenden-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HID",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Horn Island",
            "name": "[HID] - Horn Island - Australia",
            "searchName": "HID-Horn Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HIG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Highbury",
            "name": "[HIG] - Highbury - Australia",
            "searchName": "HIG-Highbury-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HIH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Hook Island",
            "name": "[HIH] - Hook Island - Australia",
            "searchName": "HIH-Hook Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GSC",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Gascoyne Junction",
            "name": "[GSC] - Gascoyne Junction - Australia",
            "searchName": "GSC-Gascoyne Junction-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GSN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mount Gunson",
            "name": "[GSN] - Mount Gunson - Australia",
            "searchName": "GSN-Mount Gunson-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GTE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Groote Eylandt",
            "name": "[GTE] - Groote Eylandt - Australia",
            "searchName": "GTE-Groote Eylandt-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GTS",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Granites",
            "name": "[GTS] - Granites - Australia",
            "searchName": "GTS-Granites-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GTT",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Georgetown",
            "name": "[GTT] - Georgetown - Australia",
            "searchName": "GTT-Georgetown-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GUL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Goulburn",
            "name": "[GUL] - Goulburn - Australia",
            "searchName": "GUL-Goulburn-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GUH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Gunnedah",
            "name": "[GUH] - Gunnedah - Australia",
            "searchName": "GUH-Gunnedah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GYL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Argyle",
            "name": "[GYL] - Argyle - Australia",
            "searchName": "GYL-Argyle-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GYP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Gympie",
            "name": "[GYP] - Gympie - Australia",
            "searchName": "GYP-Gympie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GVP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Greenvale",
            "name": "[GVP] - Greenvale - Australia",
            "searchName": "GVP-Greenvale-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HAP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Long Island",
            "name": "[HAP] - Long Island - Australia",
            "searchName": "HAP-Long Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HAT",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Heathlands",
            "name": "[HAT] - Heathlands - Australia",
            "searchName": "HAT-Heathlands-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HBA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Hobart",
            "name": "[HBA] - Hobart - Australia",
            "searchName": "HBA-Hobart-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FSL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Fossil Downs",
            "name": "[FSL] - Fossil Downs - Australia",
            "searchName": "FSL-Fossil Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FVL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Flora Valley",
            "name": "[FVL] - Flora Valley - Australia",
            "searchName": "FVL-Flora Valley-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FVR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Forrest River",
            "name": "[FVR] - Forrest River - Australia",
            "searchName": "FVR-Forrest River-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GAH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Gayndah",
            "name": "[GAH] - Gayndah - Australia",
            "searchName": "GAH-Gayndah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GBL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Goulburn Island",
            "name": "[GBL] - Goulburn Island - Australia",
            "searchName": "GBL-Goulburn Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GBP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Gamboola",
            "name": "[GBP] - Gamboola - Australia",
            "searchName": "GBP-Gamboola-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GBV",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Gibb River",
            "name": "[GBV] - Gibb River - Australia",
            "searchName": "GBV-Gibb River-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GDD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Gordon Downs",
            "name": "[GDD] - Gordon Downs - Australia",
            "searchName": "GDD-Gordon Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GEE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "George Town",
            "name": "[GEE] - George Town - Australia",
            "searchName": "GEE-George Town-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GEX",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Geelong",
            "name": "[GEX] - Geelong - Australia",
            "searchName": "GEX-Geelong-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GFE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Grenfell",
            "name": "[GFE] - Grenfell - Australia",
            "searchName": "GFE-Grenfell-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GFF",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Griffith",
            "name": "[GFF] - Griffith - Australia",
            "searchName": "GFF-Griffith-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GOV",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Gove",
            "name": "[GOV] - Gove - Australia",
            "searchName": "GOV-Gove-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GPD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mount Gordon Mine",
            "name": "[GPD] - Mount Gordon Mine - Australia",
            "searchName": "GPD-Mount Gordon Mine-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GPN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Garden Point",
            "name": "[GPN] - Garden Point - Australia",
            "searchName": "GPN-Garden Point-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GOO",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Goondiwindi",
            "name": "[GOO] - Goondiwindi - Australia",
            "searchName": "GOO-Goondiwindi-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GOS",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Gosford",
            "name": "[GOS] - Gosford - Australia",
            "searchName": "GOS-Gosford-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GLT",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Gladstone",
            "name": "[GLT] - Gladstone - Australia",
            "searchName": "GLT-Gladstone-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GLY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Goldsworthy",
            "name": "[GLY] - Goldsworthy - Australia",
            "searchName": "GLY-Goldsworthy-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GIC",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Boigu Island",
            "name": "[GIC] - Boigu Island - Australia",
            "searchName": "GIC-Boigu Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GGD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Gregory Downs",
            "name": "[GGD] - Gregory Downs - Australia",
            "searchName": "GGD-Gregory Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GET",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Geraldton",
            "name": "[GET] - Geraldton - Australia",
            "searchName": "GET-Geraldton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GFN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Grafton",
            "name": "[GFN] - Grafton - Australia",
            "searchName": "GFN-Grafton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GKL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Great Keppel Island",
            "name": "[GKL] - Great Keppel Island - Australia",
            "searchName": "GKL-Great Keppel Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GLG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Glengyle",
            "name": "[GLG] - Glengyle - Australia",
            "searchName": "GLG-Glengyle-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GLI",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Glen Innes",
            "name": "[GLI] - Glen Innes - Australia",
            "searchName": "GLI-Glen Innes-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GLM",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Glenormiston",
            "name": "[GLM] - Glenormiston - Australia",
            "searchName": "GLM-Glenormiston-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "QPV",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Perisher Valley",
            "name": "[QPV] - Perisher Valley - Australia",
            "searchName": "QPV-Perisher Valley-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "QTH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Thredbo",
            "name": "[QTH] - Thredbo - Australia",
            "searchName": "QTH-Thredbo-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "QWL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Crackenback Village",
            "name": "[QWL] - Crackenback Village - Australia",
            "searchName": "QWL-Crackenback Village-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "QLE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Leeton",
            "name": "[QLE] - Leeton - Australia",
            "searchName": "QLE-Leeton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PXH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Prominent Hill",
            "name": "[PXH] - Prominent Hill - Australia",
            "searchName": "PXH-Prominent Hill-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RHL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Roy Hill",
            "name": "[RHL] - Roy Hill - Australia",
            "searchName": "RHL-Roy Hill-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RDA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Rockhampton Downs",
            "name": "[RDA] - Rockhampton Downs - Australia",
            "searchName": "RDA-Rockhampton Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RCM",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Richmond",
            "name": "[RCM] - Richmond - Australia",
            "searchName": "RCM-Richmond-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RCN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "American River",
            "name": "[RCN] - American River - Australia",
            "searchName": "RCN-American River-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RBS",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Orbost",
            "name": "[RBS] - Orbost - Australia",
            "searchName": "RBS-Orbost-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RBU",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Roebourne",
            "name": "[RBU] - Roebourne - Australia",
            "searchName": "RBU-Roebourne-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "QZC",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Smiggin Holes",
            "name": "[QZC] - Smiggin Holes - Australia",
            "searchName": "QZC-Smiggin Holes-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "QZM",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Bullocks Flat",
            "name": "[QZM] - Bullocks Flat - Australia",
            "searchName": "QZM-Bullocks Flat-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RAM",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Ramingining",
            "name": "[RAM] - Ramingining - Australia",
            "searchName": "RAM-Ramingining-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RBC",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Robinvale",
            "name": "[RBC] - Robinvale - Australia",
            "searchName": "RBC-Robinvale-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PPI",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Port Pirie",
            "name": "[PPI] - Port Pirie - Australia",
            "searchName": "PPI-Port Pirie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PPP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Proserpine",
            "name": "[PPP] - Proserpine - Australia",
            "searchName": "PPP-Proserpine-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PQQ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Port Macquarie",
            "name": "[PQQ] - Port Macquarie - Australia",
            "searchName": "PQQ-Port Macquarie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PRD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Pardoo",
            "name": "[PRD] - Pardoo - Australia",
            "searchName": "PRD-Pardoo-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PUG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Port Augusta",
            "name": "[PUG] - Port Augusta - Australia",
            "searchName": "PUG-Port Augusta-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PTI",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Port Douglas",
            "name": "[PTI] - Port Douglas - Australia",
            "searchName": "PTI-Port Douglas-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PTJ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Portland",
            "name": "[PTJ] - Portland - Australia",
            "searchName": "PTJ-Portland-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PMK",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Palm Island",
            "name": "[PMK] - Palm Island - Australia",
            "searchName": "PMK-Palm Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PLO",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Port Lincoln",
            "name": "[PLO] - Port Lincoln - Australia",
            "searchName": "PLO-Port Lincoln-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PHE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Port Hedland",
            "name": "[PHE] - Port Hedland - Australia",
            "searchName": "PHE-Port Hedland-Port Headland-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PKE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Parkes",
            "name": "[PKE] - Parkes - Australia",
            "searchName": "PKE-Parkes-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PKT",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Port Keats",
            "name": "[PKT] - Port Keats - Australia",
            "searchName": "PKT-Port Keats-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RSB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Roseberth",
            "name": "[RSB] - Roseberth - Australia",
            "searchName": "RSB-Roseberth-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RRV",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Robinson River",
            "name": "[RRV] - Robinson River - Australia",
            "searchName": "RRV-Robinson River-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RTP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Rutland Plains",
            "name": "[RTP] - Rutland Plains - Australia",
            "searchName": "RTP-Rutland Plains-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RTS",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Rottnest Island",
            "name": "[RTS] - Rottnest Island - Australia",
            "searchName": "RTS-Rottnest Island-Rottnest-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RTY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Merty",
            "name": "[RTY] - Merty - Australia",
            "searchName": "RTY-Merty-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RPV",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Roper Valley",
            "name": "[RPV] - Roper Valley - Australia",
            "searchName": "RPV-Roper Valley-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RPB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Roper Bar",
            "name": "[RPB] - Roper Bar - Australia",
            "searchName": "RPB-Roper Bar-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RPM",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Ngukurr",
            "name": "[RPM] - Ngukurr - Australia",
            "searchName": "RPM-Ngukurr-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RRE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Marree",
            "name": "[RRE] - Marree - Australia",
            "searchName": "RRE-Marree-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ROH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Robinhood",
            "name": "[ROH] - Robinhood - Australia",
            "searchName": "ROH-Robinhood-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ROK",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Rockhampton",
            "name": "[ROK] - Rockhampton - Australia",
            "searchName": "ROK-Rockhampton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RLP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Rosella Plains",
            "name": "[RLP] - Rosella Plains - Australia",
            "searchName": "RLP-Rosella Plains-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RKY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Rokeby",
            "name": "[RKY] - Rokeby - Australia",
            "searchName": "RKY-Rokeby-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RMA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Roma",
            "name": "[RMA] - Roma - Australia",
            "searchName": "RMA-Roma-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RMK",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Renmark",
            "name": "[RMK] - Renmark - Australia",
            "searchName": "RMK-Renmark-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RVT",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Ravensthorpe",
            "name": "[RVT] - Ravensthorpe - Australia",
            "searchName": "RVT-Ravensthorpe-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SBR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Saibai Island",
            "name": "[SBR] - Saibai Island - Australia",
            "searchName": "SBR-Saibai Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SCG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Spring Creek",
            "name": "[SCG] - Spring Creek - Australia",
            "searchName": "SCG-Spring Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SGO",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "St George",
            "name": "[SGO] - St George - Australia",
            "searchName": "SGO-St George-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SGP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Shay Gap",
            "name": "[SGP] - Shay Gap - Australia",
            "searchName": "SGP-Shay Gap-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SHQ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Southport",
            "name": "[SHQ] - Southport - Australia",
            "searchName": "SHQ-Southport-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SFP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Surfers Paradise",
            "name": "[SFP] - Surfers Paradise - Australia",
            "searchName": "SFP-Surfers Paradise-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SHT",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Shepparton",
            "name": "[SHT] - Shepparton - Australia",
            "searchName": "SHT-Shepparton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SHU",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Smith Point",
            "name": "[SHU] - Smith Point - Australia",
            "searchName": "SHU-Smith Point-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SIO",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Smithton",
            "name": "[SIO] - Smithton - Australia",
            "searchName": "SIO-Smithton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SIX",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Singleton",
            "name": "[SIX] - Singleton - Australia",
            "searchName": "SIX-Singleton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SLJ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Chandler",
            "name": "[SLJ] - Chandler - Australia",
            "searchName": "SLJ-Chandler-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SNB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Snake Bay",
            "name": "[SNB] - Snake Bay - Australia",
            "searchName": "SNB-Snake Bay-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SNH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Stanthorpe",
            "name": "[SNH] - Stanthorpe - Australia",
            "searchName": "SNH-Stanthorpe-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SQC",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Southern Cross",
            "name": "[SQC] - Southern Cross - Australia",
            "searchName": "SQC-Southern Cross-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SOI",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "South Molle Island",
            "name": "[SOI] - South Molle Island - Australia",
            "searchName": "SOI-South Molle Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ODR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Ord River",
            "name": "[ODR] - Ord River - Australia",
            "searchName": "ODR-Ord River-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OBA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Oban",
            "name": "[OBA] - Oban - Australia",
            "searchName": "OBA-Oban-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ODD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Oodnadatta",
            "name": "[ODD] - Oodnadatta - Australia",
            "searchName": "ODD-Oodnadatta-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ODL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cordillo Downs",
            "name": "[ODL] - Cordillo Downs - Australia",
            "searchName": "ODL-Cordillo Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OLP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Olympic Dam",
            "name": "[OLP] - Olympic Dam - Australia",
            "searchName": "OLP-Olympic Dam-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OKB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Orchid Beach",
            "name": "[OKB] - Orchid Beach - Australia",
            "searchName": "OKB-Orchid Beach-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OKR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Yorke Island",
            "name": "[OKR] - Yorke Island - Australia",
            "searchName": "OKR-Yorke Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OKY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Oakey",
            "name": "[OKY] - Oakey - Australia",
            "searchName": "OKY-Oakey-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NRA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Narrandera",
            "name": "[NRA] - Narrandera - Australia",
            "searchName": "NRA-Narrandera-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NRG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Narrogin",
            "name": "[NRG] - Narrogin - Australia",
            "searchName": "NRG-Narrogin-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NRY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Newry",
            "name": "[NRY] - Newry - Australia",
            "searchName": "NRY-Newry-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NSM",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Norseman",
            "name": "[NSM] - Norseman - Australia",
            "searchName": "NSM-Norseman-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NSO",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Scone",
            "name": "[NSO] - Scone - Australia",
            "searchName": "NSO-Scone-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NSV",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Noosaville",
            "name": "[NSV] - Noosaville - Australia",
            "searchName": "NSV-Noosaville-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NPP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Napperby",
            "name": "[NPP] - Napperby - Australia",
            "searchName": "NPP-Napperby-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NOA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Nowra",
            "name": "[NOA] - Nowra - Australia",
            "searchName": "NOA-Nowra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NYN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Nyngan",
            "name": "[NYN] - Nyngan - Australia",
            "searchName": "NYN-Nyngan-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OAG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Orange",
            "name": "[OAG] - Orange - Australia",
            "searchName": "OAG-Orange-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NTL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Newcastle",
            "name": "[NTL] - Newcastle - Australia",
            "searchName": "NTL-Newcastle-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NTN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Normanton",
            "name": "[NTN] - Normanton - Australia",
            "searchName": "NTN-Normanton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NUB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Numbulwar",
            "name": "[NUB] - Numbulwar - Australia",
            "searchName": "NUB-Numbulwar-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NUR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Nullarbor",
            "name": "[NUR] - Nullarbor - Australia",
            "searchName": "NUR-Nullarbor-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OYN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Ouyen",
            "name": "[OYN] - Ouyen - Australia",
            "searchName": "OYN-Ouyen-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OXO",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Orientos",
            "name": "[OXO] - Orientos - Australia",
            "searchName": "OXO-Orientos-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OXY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Morney",
            "name": "[OXY] - Morney - Australia",
            "searchName": "OXY-Morney-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ORR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Yorketown",
            "name": "[ORR] - Yorketown - Australia",
            "searchName": "ORR-Yorktown-Yorketown-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ORS",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Orpheus Island Resort",
            "name": "[ORS] - Orpheus Island Resort - Australia",
            "searchName": "ORS-Orpheus Island Resort-Orpheus Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OSO",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Osborne Mine",
            "name": "[OSO] - Osborne Mine - Australia",
            "searchName": "OSO-Osborne Mine-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ONG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mornington",
            "name": "[ONG] - Mornington - Australia",
            "searchName": "ONG-Mornington-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ONR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Monkira",
            "name": "[ONR] - Monkira - Australia",
            "searchName": "ONR-Monkira-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ONS",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Onslow",
            "name": "[ONS] - Onslow - Australia",
            "searchName": "ONS-Onslow-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OOL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Gold Coast",
            "name": "[OOL] - Gold Coast - Australia",
            "searchName": "OOL-Gold Coast-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OOM",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Cooma",
            "name": "[OOM] - Cooma - Australia",
            "searchName": "OOM-Cooma-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OOR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mooraberree",
            "name": "[OOR] - Mooraberree - Australia",
            "searchName": "OOR-Mooraberree-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OPI",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Oenpelli",
            "name": "[OPI] - Oenpelli - Australia",
            "searchName": "OPI-Oenpelli-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PEA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Penneshaw",
            "name": "[PEA] - Penneshaw - Australia",
            "searchName": "PEA-Penneshaw-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PEP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Peppimenarti",
            "name": "[PEP] - Peppimenarti - Australia",
            "searchName": "PEP-Peppimenarti-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PDE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Pandie Pandie",
            "name": "[PDE] - Pandie Pandie - Australia",
            "searchName": "PDE-Pandie Pandie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PDN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Parndana",
            "name": "[PDN] - Parndana - Australia",
            "searchName": "PDN-Parndana-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PER",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Perth",
            "name": "[PER] - Perth - Australia",
            "searchName": "PER-Perth-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PEY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Penong",
            "name": "[PEY] - Penong - Australia",
            "searchName": "PEY-Penong-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PBO",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Paraburdoo",
            "name": "[PBO] - Paraburdoo - Australia",
            "searchName": "PBO-Paraburdoo-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PBY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Peppers Palm Bay",
            "name": "[PBY] - Peppers Palm Bay - Australia",
            "searchName": "PBY-Peppers Palm Bay-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NGA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Young",
            "name": "[NGA] - Young - Australia",
            "searchName": "NGA-Young-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NDS",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Sandstone",
            "name": "[NDS] - Sandstone - Australia",
            "searchName": "NDS-Sandstone-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NIF",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Nifty",
            "name": "[NIF] - Nifty - Australia",
            "searchName": "NIF-Nifty-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NKB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Noonkanbah",
            "name": "[NKB] - Noonkanbah - Australia",
            "searchName": "NKB-Noonkanbah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NLF",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Darnley Island",
            "name": "[NLF] - Darnley Island - Australia",
            "searchName": "NLF-Darnley Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NLL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Nullagine",
            "name": "[NLL] - Nullagine - Australia",
            "searchName": "NLL-Nullagine-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NLS",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Nicholson",
            "name": "[NLS] - Nicholson - Australia",
            "searchName": "NLS-Nicholson-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NMP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "New Moon",
            "name": "[NMP] - New Moon - Australia",
            "searchName": "NMP-New Moon-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NMR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Nappa Merry",
            "name": "[NMR] - Nappa Merry - Australia",
            "searchName": "NMR-Nappa Merry-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MYI",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Murray Island",
            "name": "[MYI] - Murray Island - Australia",
            "searchName": "MYI-Murray Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MYO",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Myroodah",
            "name": "[MYO] - Myroodah - Australia",
            "searchName": "MYO-Myroodah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NAA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Narrabri",
            "name": "[NAA] - Narrabri - Australia",
            "searchName": "NAA-Narrabri-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NAC",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Naracoorte",
            "name": "[NAC] - Naracoorte - Australia",
            "searchName": "NAC-Naracoorte-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NBH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Nambucca Heads",
            "name": "[NBH] - Nambucca Heads - Australia",
            "searchName": "NBH-Nambucca Heads-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MVK",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mulka",
            "name": "[MVK] - Mulka - Australia",
            "searchName": "MVK-Mulka-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MVU",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Musgrave",
            "name": "[MVU] - Musgrave - Australia",
            "searchName": "MVU-Musgrave-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MWB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Morawa",
            "name": "[MWB] - Morawa - Australia",
            "searchName": "MWB-Morawa-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MWT",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Moolawatana",
            "name": "[MWT] - Moolawatana - Australia",
            "searchName": "MWT-Moolawatana-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MWY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Miranda Downs",
            "name": "[MWY] - Miranda Downs - Australia",
            "searchName": "MWY-Miranda Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MXD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Marion Downs",
            "name": "[MXD] - Marion Downs - Australia",
            "searchName": "MXD-Marion Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MXU",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mullewa",
            "name": "[MXU] - Mullewa - Australia",
            "searchName": "MXU-Mullewa-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MYA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Moruya",
            "name": "[MYA] - Moruya - Australia",
            "searchName": "MYA-Moruya-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MKR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Meekatharra",
            "name": "[MKR] - Meekatharra - Australia",
            "searchName": "MKR-Meekatharra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MKV",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mount Cavenagh",
            "name": "[MKV] - Mount Cavenagh - Australia",
            "searchName": "MKV-Mount Cavenagh-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MKY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mackay",
            "name": "[MKY] - Mackay - Australia",
            "searchName": "MKY-Mackay-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MLR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Millicent",
            "name": "[MLR] - Millicent - Australia",
            "searchName": "MLR-Millicent-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MLV",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Merluna",
            "name": "[MLV] - Merluna - Australia",
            "searchName": "MLV-Merluna-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MMG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mount Magnet",
            "name": "[MMG] - Mount Magnet - Australia",
            "searchName": "MMG-Mount Magnet-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MMM",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Middlemount",
            "name": "[MMM] - Middlemount - Australia",
            "searchName": "MMM-Middlemount-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MNE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mungeranie",
            "name": "[MNE] - Mungeranie - Australia",
            "searchName": "MNE-Mungeranie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MNG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Maningrida",
            "name": "[MNG] - Maningrida - Australia",
            "searchName": "MNG-Maningrida-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MNQ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Monto",
            "name": "[MNQ] - Monto - Australia",
            "searchName": "MNQ-Monto-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MNV",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mountain Valley",
            "name": "[MNV] - Mountain Valley - Australia",
            "searchName": "MNV-Mountain Valley-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MNW",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Macdonald Downs",
            "name": "[MNW] - Macdonald Downs - Australia",
            "searchName": "MNW-MacDonald Downs-Macdonald Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MOO",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Moomba",
            "name": "[MOO] - Moomba - Australia",
            "searchName": "MOO-Moomba-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "YEL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Elliot Lake",
            "name": "[YEL] - Elliot Lake - Canada",
            "searchName": "YEL-Elliot Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YEM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Manitowaning",
            "name": "[YEM] - Manitowaning - Canada",
            "searchName": "YEM-Manitowaning-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YEN",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Estevan",
            "name": "[YEN] - Estevan - Canada",
            "searchName": "YEN-Estevan-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YET",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Edson",
            "name": "[YET] - Edson - Canada",
            "searchName": "YET-Edson-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YEU",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Eureka",
            "name": "[YEU] - Eureka - Canada",
            "searchName": "YEU-Eureka-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YEV",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Inuvik",
            "name": "[YEV] - Inuvik - Canada",
            "searchName": "YEV-Inuvik-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YEY",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Amos",
            "name": "[YEY] - Amos - Canada",
            "searchName": "YEY-Amos-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fort Albany",
            "name": "[YFA] - Fort Albany - Canada",
            "searchName": "YFA-Fort Albany-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Iqaluit",
            "name": "[YFB] - Iqaluit - Canada",
            "searchName": "YFB-Iqaluit-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fredericton",
            "name": "[YFC] - Fredericton - Canada",
            "searchName": "YFC-Fredericton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Forestville",
            "name": "[YFE] - Forestville - Canada",
            "searchName": "YFE-Forestville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fontanges",
            "name": "[YFG] - Fontanges - Canada",
            "searchName": "YFG-Fontanges-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFH",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fort Hope",
            "name": "[YFH] - Fort Hope - Canada",
            "searchName": "YFH-Fort Hope-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFJ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Snare Lake",
            "name": "[YFJ] - Snare Lake - Canada",
            "searchName": "YFJ-Snare Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fort Reliance",
            "name": "[YFL] - Fort Reliance - Canada",
            "searchName": "YFL-Fort Reliance-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Flin Flon",
            "name": "[YFO] - Flin Flon - Canada",
            "searchName": "YFO-Flin Flon-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fort Resolution",
            "name": "[YFR] - Fort Resolution - Canada",
            "searchName": "YFR-Fort Resolution-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fort Simpson",
            "name": "[YFS] - Fort Simpson - Canada",
            "searchName": "YFS-Fort Simpson-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kingston",
            "name": "[YGK] - Kingston - Canada",
            "searchName": "YGK-Kingston-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "La Grande",
            "name": "[YGL] - La Grande - Canada",
            "searchName": "YGL-La Grande-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Gimli",
            "name": "[YGM] - Gimli - Canada",
            "searchName": "YGM-Gimli-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGN",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Greenway Sound",
            "name": "[YGN] - Greenway Sound - Canada",
            "searchName": "YGN-Greenway Sound-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Gods Narrows",
            "name": "[YGO] - Gods Narrows - Canada",
            "searchName": "YGO-Gods Narrows-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Gaspe",
            "name": "[YGP] - Gaspe - Canada",
            "searchName": "YGP-Gasp-Gaspe-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Geraldton",
            "name": "[YGQ] - Geraldton - Canada",
            "searchName": "YGQ-Geraldton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Iles De La Madeleine",
            "name": "[YGR] - Iles De La Madeleine - Canada",
            "searchName": "YGR-Iles De La Madeleine-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Germansen",
            "name": "[YGS] - Germansen - Canada",
            "searchName": "YGS-Germansen-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGT",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Igloolik",
            "name": "[YGT] - Igloolik - Canada",
            "searchName": "YGT-Igloolik-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGV",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Havre St Pierre",
            "name": "[YGV] - Havre St Pierre - Canada",
            "searchName": "YGV-Havre St. Pierre-Havre St Pierre-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGW",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kuujjuarapik",
            "name": "[YGW] - Kuujjuarapik - Canada",
            "searchName": "YGW-Kuujjuarapik-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGX",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Gillam",
            "name": "[YGX] - Gillam - Canada",
            "searchName": "YGX-Gillam-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGY",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Deception",
            "name": "[YGY] - Deception - Canada",
            "searchName": "YGY-Deception-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGZ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Grise Fiord",
            "name": "[YGZ] - Grise Fiord - Canada",
            "searchName": "YGZ-Grise Fiord-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Port Hope Simpson",
            "name": "[YHA] - Port Hope Simpson - Canada",
            "searchName": "YHA-Port Hope Simpson-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Hudson Bay",
            "name": "[YHB] - Hudson Bay - Canada",
            "searchName": "YHB-Hudson Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Hakai Pass",
            "name": "[YHC] - Hakai Pass - Canada",
            "searchName": "YHC-Hakai Pass-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHD",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Dryden",
            "name": "[YHD] - Dryden - Canada",
            "searchName": "YHD-Dryden-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Hope",
            "name": "[YHE] - Hope - Canada",
            "searchName": "YHE-Hope-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Hearst",
            "name": "[YHF] - Hearst - Canada",
            "searchName": "YHF-Hearst-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Charlottetown",
            "name": "[YHG] - Charlottetown - Canada",
            "searchName": "YHG-Charlottetown-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHI",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Holman",
            "name": "[YHI] - Holman - Canada",
            "searchName": "YHI-Holman-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Gjoa Haven",
            "name": "[YHK] - Gjoa Haven - Canada",
            "searchName": "YHK-Gjoa Haven-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHN",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Hornepayne",
            "name": "[YHN] - Hornepayne - Canada",
            "searchName": "YHN-Hornepayne-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Hopedale",
            "name": "[YHO] - Hopedale - Canada",
            "searchName": "YHO-Hopedale-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Poplar Hill",
            "name": "[YHP] - Poplar Hill - Canada",
            "searchName": "YHP-Poplar Hill-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Chevery",
            "name": "[YHR] - Chevery - Canada",
            "searchName": "YHR-Chevery-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Sechelt",
            "name": "[YHS] - Sechelt - Canada",
            "searchName": "YHS-Sechelt-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHT",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Haines Junction",
            "name": "[YHT] - Haines Junction - Canada",
            "searchName": "YHT-Haines Junction-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHY",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Hay River",
            "name": "[YHY] - Hay River - Canada",
            "searchName": "YHY-Hay River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHZ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Halifax",
            "name": "[YHZ] - Halifax - Canada",
            "searchName": "YHZ-Halifax-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YIB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Atikokan",
            "name": "[YIB] - Atikokan - Canada",
            "searchName": "YIB-Atikokan-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XZK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Amherst",
            "name": "[XZK] - Amherst - Canada",
            "searchName": "XZK-Amherst-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Anahim Lake",
            "name": "[YAA] - Anahim Lake - Canada",
            "searchName": "YAA-Anahim Lake-Anahim G-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Arctic Bay",
            "name": "[YAB] - Arctic Bay - Canada",
            "searchName": "YAB-Arctic Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Cat Lake",
            "name": "[YAC] - Cat Lake - Canada",
            "searchName": "YAC-Cat Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAD",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Moose Lake",
            "name": "[YAD] - Moose Lake - Canada",
            "searchName": "YAD-Moose Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Alta Lake",
            "name": "[YAE] - Alta Lake - Canada",
            "searchName": "YAE-Alta Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Asbestos Hill",
            "name": "[YAF] - Asbestos Hill - Canada",
            "searchName": "YAF-Asbestos Hill-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fort Frances",
            "name": "[YAG] - Fort Frances - Canada",
            "searchName": "YAG-Fort Frances-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAH",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Lagrande 4",
            "name": "[YAH] - Lagrande 4 - Canada",
            "searchName": "YAH-Lagrande 4-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAT",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Attawapiskat",
            "name": "[YAT] - Attawapiskat - Canada",
            "searchName": "YAT-Attawapiskat-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAU",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kattiniq",
            "name": "[YAU] - Kattiniq - Canada",
            "searchName": "YAU-Kattiniq\\/Donaldson Lake-Kattiniq-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAV",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Miners Bay",
            "name": "[YAV] - Miners Bay - Canada",
            "searchName": "YAV-Miners Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAX",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Angling Lake",
            "name": "[YAX] - Angling Lake - Canada",
            "searchName": "YAX-Angling Lake-Angling G-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAY",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "St Anthony",
            "name": "[YAY] - St Anthony - Canada",
            "searchName": "YAY-St Anthony-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAZ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Tofino",
            "name": "[YAZ] - Tofino - Canada",
            "searchName": "YAZ-Tofino-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Banff",
            "name": "[YBA] - Banff - Canada",
            "searchName": "YBA-Banff-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kugaaruk",
            "name": "[YBB] - Kugaaruk - Canada",
            "searchName": "YBB-Kugaaruk-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Baie Comeau",
            "name": "[YBC] - Baie Comeau - Canada",
            "searchName": "YBC-Baie Comeau-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBD",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "New Westminster",
            "name": "[YBD] - New Westminster - Canada",
            "searchName": "YBD-New Westminster-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Uranium City",
            "name": "[YBE] - Uranium City - Canada",
            "searchName": "YBE-Uranium City-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Bamfield",
            "name": "[YBF] - Bamfield - Canada",
            "searchName": "YBF-Bamfield-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Bagotville",
            "name": "[YBG] - Bagotville - Canada",
            "searchName": "YBG-Bagotville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBH",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Bull Harbour",
            "name": "[YBH] - Bull Harbour - Canada",
            "searchName": "YBH-Bull Harbour-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBI",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Black Tickle",
            "name": "[YBI] - Black Tickle - Canada",
            "searchName": "YBI-Black Tickle NF-Black Tickle-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBJ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Baie Johan Beetz",
            "name": "[YBJ] - Baie Johan Beetz - Canada",
            "searchName": "YBJ-Baie Johan Beetz-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Baker Lake",
            "name": "[YBK] - Baker Lake - Canada",
            "searchName": "YBK-Baker Lake-Baker G-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Campbell River",
            "name": "[YBL] - Campbell River - Canada",
            "searchName": "YBL-Campbell River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Bronson Creek",
            "name": "[YBM] - Bronson Creek - Canada",
            "searchName": "YBM-Bronson Creek-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBN",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Borden",
            "name": "[YBN] - Borden - Canada",
            "searchName": "YBN-Borden-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Bobquinn Lake",
            "name": "[YBO] - Bobquinn Lake - Canada",
            "searchName": "YBO-Bobquinn Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Telegraph Harbour",
            "name": "[YBQ] - Telegraph Harbour - Canada",
            "searchName": "YBQ-Telegraph Harbour-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Brandon",
            "name": "[YBR] - Brandon - Canada",
            "searchName": "YBR-Brandon-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Opapamiska Lake",
            "name": "[YBS] - Opapamiska Lake - Canada",
            "searchName": "YBS-Opapamiska Lake-Opapamiska G-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBT",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Brochet",
            "name": "[YBT] - Brochet - Canada",
            "searchName": "YBT-Brochet-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBV",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Berens River",
            "name": "[YBV] - Berens River - Canada",
            "searchName": "YBV-Berens River-Berens Nehri-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBW",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Bedwell Harbor",
            "name": "[YBW] - Bedwell Harbor - Canada",
            "searchName": "YBW-Bedwell Harbor-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBX",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Blanc Sablon",
            "name": "[YBX] - Blanc Sablon - Canada",
            "searchName": "YBX-Blanc Sablon-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBY",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Bonnyville",
            "name": "[YBY] - Bonnyville - Canada",
            "searchName": "YBY-Bonnyville ON-Bonnyville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Courtenay",
            "name": "[YCA] - Courtenay - Canada",
            "searchName": "YCA-Courtenay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Cambridge Bay",
            "name": "[YCB] - Cambridge Bay - Canada",
            "searchName": "YCB-Cambridge Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Cornwall",
            "name": "[YCC] - Cornwall - Canada",
            "searchName": "YCC-Cornwall-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCD",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Nanaimo",
            "name": "[YCD] - Nanaimo - Canada",
            "searchName": "YCD-Nanaimo-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Centralia",
            "name": "[YCE] - Centralia - Canada",
            "searchName": "YCE-Centralia-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Cortes Bay",
            "name": "[YCF] - Cortes Bay - Canada",
            "searchName": "YCF-Cortes Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Castlegar",
            "name": "[YCG] - Castlegar - Canada",
            "searchName": "YCG-Castlegar-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCH",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Miramichi",
            "name": "[YCH] - Miramichi - Canada",
            "searchName": "YCH-Miramichi-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCI",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Caribou Island",
            "name": "[YCI] - Caribou Island - Canada",
            "searchName": "YCI-Caribou Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCJ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Cape St James",
            "name": "[YCJ] - Cape St James - Canada",
            "searchName": "YCJ-Cape St. James-Cape St James-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Colville Lake",
            "name": "[YCK] - Colville Lake - Canada",
            "searchName": "YCK-Colville Lake-Colville G-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Charlo",
            "name": "[YCL] - Charlo - Canada",
            "searchName": "YCL-Charlo-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "St Catharines",
            "name": "[YCM] - St Catharines - Canada",
            "searchName": "YCM-St Catharines-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCN",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Cochrane",
            "name": "[YCN] - Cochrane - Canada",
            "searchName": "YCN-Cochrane-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kugluktuk",
            "name": "[YCO] - Kugluktuk - Canada",
            "searchName": "YCO-Kugluktuk-Kugluktuk\\/Coppermine-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Co-op Point",
            "name": "[YCP] - Co-op Point - Canada",
            "searchName": "YCP-Co-op Point-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Chetwynd",
            "name": "[YCQ] - Chetwynd - Canada",
            "searchName": "YCQ-Chetwynd-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Cross Lake",
            "name": "[YCR] - Cross Lake - Canada",
            "searchName": "YCR-Cross Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Chesterfield Inlet",
            "name": "[YCS] - Chesterfield Inlet - Canada",
            "searchName": "YCS-Chesterfield Inlet-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCT",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Coronation",
            "name": "[YCT] - Coronation - Canada",
            "searchName": "YCT-Coronation-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCV",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Cartierville",
            "name": "[YCV] - Cartierville - Canada",
            "searchName": "YCV-Cartierville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCW",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Chilliwack",
            "name": "[YCW] - Chilliwack - Canada",
            "searchName": "YCW-Chilliwack-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCX",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Gagetown",
            "name": "[YCX] - Gagetown - Canada",
            "searchName": "YCX-Gagetown-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCY",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Clyde River",
            "name": "[YCY] - Clyde River - Canada",
            "searchName": "YCY-Clyde River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCZ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fairmont Hot Springs",
            "name": "[YCZ] - Fairmont Hot Springs - Canada",
            "searchName": "YCZ-Fairmont Hot Springs-Fairmont Springs-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Dawson City",
            "name": "[YDA] - Dawson City - Canada",
            "searchName": "YDA-Dawson City-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Burwash Landings",
            "name": "[YDB] - Burwash Landings - Canada",
            "searchName": "YDB-Burwash Landings-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Drayton Valley",
            "name": "[YDC] - Drayton Valley - Canada",
            "searchName": "YDC-Drayton Valley-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Paradise River",
            "name": "[YDE] - Paradise River - Canada",
            "searchName": "YDE-Paradise River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Deer Lake",
            "name": "[YDF] - Deer Lake - Canada",
            "searchName": "YDF-Deer Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Digby",
            "name": "[YDG] - Digby - Canada",
            "searchName": "YDG-Digby-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDH",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Daniels Harbour",
            "name": "[YDH] - Daniels Harbour - Canada",
            "searchName": "YDH-Daniels Harbour-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDI",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Davis Inlet",
            "name": "[YDI] - Davis Inlet - Canada",
            "searchName": "YDI-Davis Inlet-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDJ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Hatchet Lake",
            "name": "[YDJ] - Hatchet Lake - Canada",
            "searchName": "YDJ-Hatchet Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Main Duck Island",
            "name": "[YDK] - Main Duck Island - Canada",
            "searchName": "YDK-Main Duck Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Dease Lake",
            "name": "[YDL] - Dease Lake - Canada",
            "searchName": "YDL-Dease Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDN",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Dauphin",
            "name": "[YDN] - Dauphin - Canada",
            "searchName": "YDN-Dauphin-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Dolbeau",
            "name": "[YDO] - Dolbeau - Canada",
            "searchName": "YDO-Dolbeau-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Nain",
            "name": "[YDP] - Nain - Canada",
            "searchName": "YDP-Nain Newfoundland and Labrador-Nain-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Dawson Creek",
            "name": "[YDQ] - Dawson Creek - Canada",
            "searchName": "YDQ-Dawson Creek-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Broadview",
            "name": "[YDR] - Broadview - Canada",
            "searchName": "YDR-Broadview-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Desolation Sound",
            "name": "[YDS] - Desolation Sound - Canada",
            "searchName": "YDS-Desolation Sound-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDU",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kasba Lake",
            "name": "[YDU] - Kasba Lake - Canada",
            "searchName": "YDU-Kasba Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDV",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Bloodvein",
            "name": "[YDV] - Bloodvein - Canada",
            "searchName": "YDV-Bloodvein-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDW",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Obre Lake",
            "name": "[YDW] - Obre Lake - Canada",
            "searchName": "YDW-Obre Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDX",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Doc Creek",
            "name": "[YDX] - Doc Creek - Canada",
            "searchName": "YDX-Doc Creek-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YEA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Edmonton",
            "name": "[YEA] - Edmonton - Canada",
            "searchName": "YEA-Edmonton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YEB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Bar River",
            "name": "[YEB] - Bar River - Canada",
            "searchName": "YEB-Bar River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XPB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Parksville",
            "name": "[XPB] - Parksville - Canada",
            "searchName": "XPB-Parksville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XON",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Carleton",
            "name": "[XON] - Carleton - Canada",
            "searchName": "XON-Carleton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XPN",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Brampton",
            "name": "[XPN] - Brampton - Canada",
            "searchName": "XPN-Brampton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XPP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Poplar River",
            "name": "[XPP] - Poplar River - Canada",
            "searchName": "XPP-Poplar River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XPH",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Port Hope",
            "name": "[XPH] - Port Hope - Canada",
            "searchName": "XPH-Port Hope-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XPK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Pukatawagan",
            "name": "[XPK] - Pukatawagan - Canada",
            "searchName": "XPK-Pukatawagan-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XPX",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Pointe-aux-Trembles",
            "name": "[XPX] - Pointe-aux-Trembles - Canada",
            "searchName": "XPX-Pointe-aux-Trembles-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XQU",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Qualicum",
            "name": "[XQU] - Qualicum - Canada",
            "searchName": "XQU-Qualicum-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XOK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Oakville",
            "name": "[XOK] - Oakville - Canada",
            "searchName": "XOK-Oakville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XMP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Macmillan Pass",
            "name": "[XMP] - Macmillan Pass - Canada",
            "searchName": "XMP-MacMillan Pass-Macmillan Pass-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XLV",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Niagara Falls",
            "name": "[XLV] - Niagara Falls - Canada",
            "searchName": "XLV-Niagara Falls-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XLP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Matapedia",
            "name": "[XLP] - Matapedia - Canada",
            "searchName": "XLP-Matapedia-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XLB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Lac Brochet",
            "name": "[XLB] - Lac Brochet - Canada",
            "searchName": "XLB-Lac Brochet-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XLF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Leaf Bay",
            "name": "[XLF] - Leaf Bay - Canada",
            "searchName": "XLF-Leaf Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XLY",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Aldershot",
            "name": "[XLY] - Aldershot - Canada",
            "searchName": "XLY-Aldershot-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XLZ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Truro",
            "name": "[XLZ] - Truro - Canada",
            "searchName": "XLZ-Truro-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XSI",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "South Indian Lake",
            "name": "[XSI] - South Indian Lake - Canada",
            "searchName": "XSI-South Indian Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XRP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Riviere-a-Pierre",
            "name": "[XRP] - Riviere-a-Pierre - Canada",
            "searchName": "XRP-Riviere-a-Pierre-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XRR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Ross River",
            "name": "[XRR] - Ross River - Canada",
            "searchName": "XRR-Ross River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XTY",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Strathroy",
            "name": "[XTY] - Strathroy - Canada",
            "searchName": "XTY-Strathroy-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XTL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Tadoule Lake",
            "name": "[XTL] - Tadoule Lake - Canada",
            "searchName": "XTL-Tadoule Lake-Tadoule G-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XWA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Watford",
            "name": "[XWA] - Watford - Canada",
            "searchName": "XWA-Watford-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XVV",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Belleville",
            "name": "[XVV] - Belleville - Canada",
            "searchName": "XVV-Belleville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XWY",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Wyoming",
            "name": "[XWY] - Wyoming - Canada",
            "searchName": "XWY-Wyoming-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZRJ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Round Lake",
            "name": "[ZRJ] - Round Lake - Canada",
            "searchName": "ZRJ-Round Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZSJ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Sandy Lake",
            "name": "[ZSJ] - Sandy Lake - Canada",
            "searchName": "ZSJ-Sandy Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZSP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "St Paul",
            "name": "[ZSP] - St Paul - Canada",
            "searchName": "ZSP-St Paul-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZST",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Stewart",
            "name": "[ZST] - Stewart - Canada",
            "searchName": "ZST-Stewart-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZTB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Tete-a-La Baleine",
            "name": "[ZTB] - Tete-a-La Baleine - Canada",
            "searchName": "ZTB-Tete-a-La Baleine-A-La-Baleine-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZTM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Shamattawa",
            "name": "[ZTM] - Shamattawa - Canada",
            "searchName": "ZTM-Shamattawa-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZUC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Ignace",
            "name": "[ZUC] - Ignace - Canada",
            "searchName": "ZUC-Ignace-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZTS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Tahsis",
            "name": "[ZTS] - Tahsis - Canada",
            "searchName": "ZTS-Tahsis-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZUM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Churchill Falls",
            "name": "[ZUM] - Churchill Falls - Canada",
            "searchName": "ZUM-Churchill Falls-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZWL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Wollaston Lake",
            "name": "[ZWL] - Wollaston Lake - Canada",
            "searchName": "ZWL-Wollaston Lake-Wollaston G-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZMH",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "108 Mile Ranch",
            "name": "[ZMH] - 108 Mile Ranch - Canada",
            "searchName": "ZMH-108 Mile Ranch-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZKE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kaschechewan",
            "name": "[ZKE] - Kaschechewan - Canada",
            "searchName": "ZKE-Kaschechewan-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZLT",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "La Tabatiere",
            "name": "[ZLT] - La Tabatiere - Canada",
            "searchName": "ZLT-La Tabatiere-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZKG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kegaska",
            "name": "[ZKG] - Kegaska - Canada",
            "searchName": "ZKG-Kegaska-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZNG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Negginan",
            "name": "[ZNG] - Negginan - Canada",
            "searchName": "ZNG-Negginan-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZNU",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Namu",
            "name": "[ZNU] - Namu - Canada",
            "searchName": "ZNU-Namu-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZOF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Ocean Falls",
            "name": "[ZOF] - Ocean Falls - Canada",
            "searchName": "ZOF-Ocean Falls-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZMT",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Masset",
            "name": "[ZMT] - Masset - Canada",
            "searchName": "ZMT-Masset-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZPB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Sachigo Lake",
            "name": "[ZPB] - Sachigo Lake - Canada",
            "searchName": "ZPB-Sachigo Lake-Sachigo G-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZPO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Pine House",
            "name": "[ZPO] - Pine House - Canada",
            "searchName": "ZPO-Pine House-Pine House Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZQS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Queen Charlotte Island",
            "name": "[ZQS] - Queen Charlotte Island - Canada",
            "searchName": "ZQS-Queen Charlotte Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZFW",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fairview",
            "name": "[ZFW] - Fairview - Canada",
            "searchName": "ZFW-Fairview-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZGF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Grand Forks",
            "name": "[ZGF] - Grand Forks - Canada",
            "searchName": "ZGF-Grand Forks-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZGI",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Gods River",
            "name": "[ZGI] - Gods River - Canada",
            "searchName": "ZGI-Gods River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZGR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Little Grand Rapids",
            "name": "[ZGR] - Little Grand Rapids - Canada",
            "searchName": "ZGR-Little Grand Rapids-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZGS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Gethsemani",
            "name": "[ZGS] - Gethsemani - Canada",
            "searchName": "ZGS-Gethsemani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZHO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Houston",
            "name": "[ZHO] - Houston - Canada",
            "searchName": "ZHO-Houston-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZHP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "High Prairie",
            "name": "[ZHP] - High Prairie - Canada",
            "searchName": "ZHP-High Prairie-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZJG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Jenpeg",
            "name": "[ZJG] - Jenpeg - Canada",
            "searchName": "ZJG-Jenpeg-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZJN",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Swan River",
            "name": "[ZJN] - Swan River - Canada",
            "searchName": "ZJN-Swan River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZBM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Bromont",
            "name": "[ZBM] - Bromont - Canada",
            "searchName": "ZBM-Bromont-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZEL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Bella Bella",
            "name": "[ZEL] - Bella Bella - Canada",
            "searchName": "ZEL-Bella Bella-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZEM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "East Main",
            "name": "[ZEM] - East Main - Canada",
            "searchName": "ZEM-East Main-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZFA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Faro",
            "name": "[ZFA] - Faro - Canada",
            "searchName": "ZFA-Faro-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZFB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Old Fort Bay",
            "name": "[ZFB] - Old Fort Bay - Canada",
            "searchName": "ZFB-Old Fort Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZFD",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fond Du Lac",
            "name": "[ZFD] - Fond Du Lac - Canada",
            "searchName": "ZFD-Fond du Lac-Fond Du Lac-Fond-Du-Lac-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZFL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "South Trout Lake",
            "name": "[ZFL] - South Trout Lake - Canada",
            "searchName": "ZFL-South Trout Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZFM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fort Mcpherson",
            "name": "[ZFM] - Fort Mcpherson - Canada",
            "searchName": "ZFM-Fort Mcpherson-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZFN",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Tulita",
            "name": "[ZFN] - Tulita - Canada",
            "searchName": "ZFN-Tulita/Fort Norman-Tulita-Tulita\\/Fort Norman-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZBF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Bathurst",
            "name": "[ZBF] - Bathurst - Canada",
            "searchName": "ZBF-Bathurst-Bathhurst-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLH",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Lansdowne House",
            "name": "[YLH] - Lansdowne House - Canada",
            "searchName": "YLH-Lansdowne House-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Waskaganish",
            "name": "[YKQ] - Waskaganish - Canada",
            "searchName": "YKQ-Waskaganish-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLJ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Meadow Lake",
            "name": "[YLJ] - Meadow Lake - Canada",
            "searchName": "YLJ-Meadow Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Oro Station",
            "name": "[YLK] - Oro Station - Canada",
            "searchName": "YLK-Oro Station-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Lloydminster",
            "name": "[YLL] - Lloydminster - Canada",
            "searchName": "YLL-Lloydminster-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Clinton Creek",
            "name": "[YLM] - Clinton Creek - Canada",
            "searchName": "YLM-Clinton Creek-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKT",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Klemtu",
            "name": "[YKT] - Klemtu - Canada",
            "searchName": "YKT-Klemtu-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKU",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Chisasibi",
            "name": "[YKU] - Chisasibi - Canada",
            "searchName": "YKU-Chisasibi-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKX",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kirkland Lake",
            "name": "[YKX] - Kirkland Lake - Canada",
            "searchName": "YKX-Kirkland Lake-Kirkland-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKY",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kindersley",
            "name": "[YKY] - Kindersley - Canada",
            "searchName": "YKY-Kindersley-Saskatoon-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Langara",
            "name": "[YLA] - Langara - Canada",
            "searchName": "YLA-Langara-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Lac Biche",
            "name": "[YLB] - Lac Biche - Canada",
            "searchName": "YLB-Lac Biche-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kimmirut",
            "name": "[YLC] - Kimmirut - Canada",
            "searchName": "YLC-Kimmirut-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLD",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Chapleau",
            "name": "[YLD] - Chapleau - Canada",
            "searchName": "YLD-Chapleau-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Wha Ti",
            "name": "[YLE] - Wha Ti - Canada",
            "searchName": "YLE-Wha Ti-Wha Ti\\/Lac La Martre-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "LaForges",
            "name": "[YLF] - LaForges - Canada",
            "searchName": "YLF-LaForges-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Shilo",
            "name": "[YLO] - Shilo - Canada",
            "searchName": "YLO-Shilo-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Mingan",
            "name": "[YLP] - Mingan - Canada",
            "searchName": "YLP-Mingan-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "La Tuque",
            "name": "[YLQ] - La Tuque - Canada",
            "searchName": "YLQ-La Tuque-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Leaf Rapids",
            "name": "[YLR] - Leaf Rapids - Canada",
            "searchName": "YLR-Leaf Rapids-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Lebel-Sur-Quevillon",
            "name": "[YLS] - Lebel-Sur-Quevillon - Canada",
            "searchName": "YLS-Lebel-Sur-Quevillon-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLT",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Alert",
            "name": "[YLT] - Alert - Canada",
            "searchName": "YLT-Alert-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLW",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kelowna",
            "name": "[YLW] - Kelowna - Canada",
            "searchName": "YLW-Kelowna-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLX",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Long Point",
            "name": "[YLX] - Long Point - Canada",
            "searchName": "YLX-Long Point-Longpoint Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLY",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Langley",
            "name": "[YLY] - Langley - Canada",
            "searchName": "YLY-Langley-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Mayo",
            "name": "[YMA] - Mayo - Canada",
            "searchName": "YMA-Mayo-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Merritt",
            "name": "[YMB] - Merritt - Canada",
            "searchName": "YMB-Merritt-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Maricourt Airstrip",
            "name": "[YMC] - Maricourt Airstrip - Canada",
            "searchName": "YMC-Maricourt Airstrip-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMD",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Mould Bay",
            "name": "[YMD] - Mould Bay - Canada",
            "searchName": "YMD-Mould Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YME",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Matane",
            "name": "[YME] - Matane - Canada",
            "searchName": "YME-Matane-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Montagne Harbor",
            "name": "[YMF] - Montagne Harbor - Canada",
            "searchName": "YMF-Montagne Harbor-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Manitouwadge",
            "name": "[YMG] - Manitouwadge - Canada",
            "searchName": "YMG-Manitouwadge-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMH",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Mary s Harbour",
            "name": "[YMH] - Mary s Harbour - Canada",
            "searchName": "YMH-Mary s Harbour-Harbour-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMI",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Minaki",
            "name": "[YMI] - Minaki - Canada",
            "searchName": "YMI-Minaki-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMJ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Moose Jaw",
            "name": "[YMJ] - Moose Jaw - Canada",
            "searchName": "YMJ-Moose Jaw-Regina-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFX",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fox Harbour (St Lewis)",
            "name": "[YFX] - Fox Harbour (St Lewis) - Canada",
            "searchName": "YFX-Fox Harbour (St Lewis)-Fox Harbour\\/St Lewis-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Gagnon",
            "name": "[YGA] - Gagnon - Canada",
            "searchName": "YGA-Gagnon-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Gillies Bay",
            "name": "[YGB] - Gillies Bay - Canada",
            "searchName": "YGB-Gillies Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Grande Cache",
            "name": "[YGC] - Grande Cache - Canada",
            "searchName": "YGC-Grande Cache-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Gorge Harbor",
            "name": "[YGE] - Gorge Harbor - Canada",
            "searchName": "YGE-Gorge Harbor-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Ganges Harbor",
            "name": "[YGG] - Ganges Harbor - Canada",
            "searchName": "YGG-Ganges Harbor-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGH",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fort Good Hope",
            "name": "[YGH] - Fort Good Hope - Canada",
            "searchName": "YGH-Fort Good Hope-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Maple Bay",
            "name": "[YAQ] - Maple Bay - Canada",
            "searchName": "YAQ-Maple Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Lagrande 3",
            "name": "[YAR] - Lagrande 3 - Canada",
            "searchName": "YAR-Lagrande 3-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YEP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Estevan Point",
            "name": "[YEP] - Estevan Point - Canada",
            "searchName": "YEP-Estevan Point-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YER",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fort Severn",
            "name": "[YER] - Fort Severn - Canada",
            "searchName": "YER-Fort Severn-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YIF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Pakuashipi",
            "name": "[YIF] - Pakuashipi - Canada",
            "searchName": "YIF-Pakuashipi-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YIG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Big Bay Marina",
            "name": "[YIG] - Big Bay Marina - Canada",
            "searchName": "YIG-Big Bay Marina-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YIK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Ivujivik",
            "name": "[YIK] - Ivujivik - Canada",
            "searchName": "YIK-Ivujivik-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YIO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Pond Inlet",
            "name": "[YIO] - Pond Inlet - Canada",
            "searchName": "YIO-Pond Inlet-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YIV",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Island Lake",
            "name": "[YIV] - Island Lake - Canada",
            "searchName": "YIV-Island Lake-Island Lk-Island Lake\\/Garden Hill-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YJA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Jasper",
            "name": "[YJA] - Jasper - Canada",
            "searchName": "YJA-Jasper-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YJF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fort Liard",
            "name": "[YJF] - Fort Liard - Canada",
            "searchName": "YJF-Fort Liard-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YJN",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "St Jean",
            "name": "[YJN] - St Jean - Canada",
            "searchName": "YJN-St Jean-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YJO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Johnny Mountain",
            "name": "[YJO] - Johnny Mountain - Canada",
            "searchName": "YJO-Johnny Mountain-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YJP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Jasper-hinton",
            "name": "[YJP] - Jasper-hinton - Canada",
            "searchName": "YJP-Jasper Hinton-Jasper-hinton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YJT",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Stephenville",
            "name": "[YJT] - Stephenville - Canada",
            "searchName": "YJT-Stephenville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kamloops",
            "name": "[YKA] - Kamloops - Canada",
            "searchName": "YKA-Kamloops-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Collins Bay",
            "name": "[YKC] - Collins Bay - Canada",
            "searchName": "YKC-Collins Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKD",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kincardine",
            "name": "[YKD] - Kincardine - Canada",
            "searchName": "YKD-Kincardine-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Knee Lake",
            "name": "[YKE] - Knee Lake - Canada",
            "searchName": "YKE-Knee Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kitchener-Waterloo",
            "name": "[YKF] - Kitchener-Waterloo - Canada",
            "searchName": "YKF-Kitchener/Waterloo-Kitchener-Waterloo-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kangirsuk",
            "name": "[YKG] - Kangirsuk - Canada",
            "searchName": "YKG-Kangirsuk-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKI",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kennosao Lake",
            "name": "[YKI] - Kennosao Lake - Canada",
            "searchName": "YKI-Kennosao Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKJ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Key Lake",
            "name": "[YKJ] - Key Lake - Canada",
            "searchName": "YKJ-Key Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kitkatla",
            "name": "[YKK] - Kitkatla - Canada",
            "searchName": "YKK-Kitkatla-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Schefferville",
            "name": "[YKL] - Schefferville - Canada",
            "searchName": "YKL-Schefferville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "BSS",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Balsas",
            "name": "[BSS] - Balsas - Brazil",
            "searchName": "BSS-Balsas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BQQ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Barra",
            "name": "[BQQ] - Barra - Brazil",
            "searchName": "BQQ-Barra-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BPS",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Porto Seguro",
            "name": "[BPS] - Porto Seguro - Brazil",
            "searchName": "BPS-Porto Seguro-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BRA",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Barreiras",
            "name": "[BRA] - Barreiras - Brazil",
            "searchName": "BRA-Barreiras-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BRB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Barreirinhas",
            "name": "[BRB] - Barreirinhas - Brazil",
            "searchName": "BRB-Barreirinhas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CAU",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Caruaru",
            "name": "[CAU] - Caruaru - Brazil",
            "searchName": "CAU-Caruaru-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CBW",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Campo Mourao",
            "name": "[CBW] - Campo Mourao - Brazil",
            "searchName": "CBW-Campo Mourao-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CCI",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Concordia",
            "name": "[CCI] - Concordia - Brazil",
            "searchName": "CCI-Concordia-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CAW",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Campos",
            "name": "[CAW] - Campos - Brazil",
            "searchName": "CAW-Campos-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CAC",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Cascavel",
            "name": "[CAC] - Cascavel - Brazil",
            "searchName": "CAC-Cascavel-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CAF",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Carauari",
            "name": "[CAF] - Carauari - Brazil",
            "searchName": "CAF-Carauari-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BZC",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Buzios",
            "name": "[BZC] - Buzios - Brazil",
            "searchName": "BZC-Buzios-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BYO",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Bonito",
            "name": "[BYO] - Bonito - Brazil",
            "searchName": "BYO-Bonito-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ATM",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Altamira",
            "name": "[ATM] - Altamira - Brazil",
            "searchName": "ATM-Altamira-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AUB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Itauba",
            "name": "[AUB] - Itauba - Brazil",
            "searchName": "AUB-Itauba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "APQ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Arapiraca",
            "name": "[APQ] - Arapiraca - Brazil",
            "searchName": "APQ-Arapiraca-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "APS",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Anapolis",
            "name": "[APS] - Anapolis - Brazil",
            "searchName": "APS-Anapolis-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "APU",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Apucarana",
            "name": "[APU] - Apucarana - Brazil",
            "searchName": "APU-Apucarana-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ARS",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Aragarcas",
            "name": "[ARS] - Aragarcas - Brazil",
            "searchName": "ARS-Aragarcas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ARU",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Aracatuba",
            "name": "[ARU] - Aracatuba - Brazil",
            "searchName": "ARU-Ara-Aracatuba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AQA",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Araraquara",
            "name": "[AQA] - Araraquara - Brazil",
            "searchName": "AQA-Araraquara-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "APX",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Arapongas",
            "name": "[APX] - Arapongas - Brazil",
            "searchName": "APX-Arapongas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "APY",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Alto Parnaiba",
            "name": "[APY] - Alto Parnaiba - Brazil",
            "searchName": "APY-Alto Parnaiba-Alto Do Parnaiba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AQM",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Ariquemes",
            "name": "[AQM] - Ariquemes - Brazil",
            "searchName": "AQM-Ariquemes-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BCR",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Boca Do Acre",
            "name": "[BCR] - Boca Do Acre - Brazil",
            "searchName": "BCR-Boca do Acre-Boca Do Acre-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BDC",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Barra Do Corda",
            "name": "[BDC] - Barra Do Corda - Brazil",
            "searchName": "BDC-Barra Do Corda-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BAT",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Barretos",
            "name": "[BAT] - Barretos - Brazil",
            "searchName": "BAT-Barretos-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BAZ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Barbelos",
            "name": "[BAZ] - Barbelos - Brazil",
            "searchName": "BAZ-Barbelos-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AZL",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Sapezal",
            "name": "[AZL] - Sapezal - Brazil",
            "searchName": "AZL-Sapezal-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AAN",
            "countryCode": "AE",
            "cityCode": null,
            "cityName": "Al Ain",
            "name": "[AAN] - Al Ain - United Arab Emirates",
            "searchName": "AAN-Al-Ain-Al Ain-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 0
        },
        {
            "iataCode": "BZL",
            "countryCode": "BD",
            "cityCode": null,
            "cityName": "Barisal",
            "name": "[BZL] - Barisal - Bangladesh",
            "searchName": "BZL-Barisal-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "CLA",
            "countryCode": "BD",
            "cityCode": null,
            "cityName": "Comilla",
            "name": "[CLA] - Comilla - Bangladesh",
            "searchName": "CLA-Comilla-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "TGV",
            "countryCode": "BG",
            "cityCode": null,
            "cityName": "Targovishte",
            "name": "[TGV] - Targovishte - Bulgaria",
            "searchName": "TGV-Targovishte-Bulgária-Bulgària-Bulgaristan-Bulharsko-Bulgaria-Bulgarien-Bugarska-Bulgaaria-Bulgarija-Bolgarija--Bulgarie",
            "priority": 0
        },
        {
            "iataCode": "SZR",
            "countryCode": "BG",
            "cityCode": null,
            "cityName": "Stara Zagora",
            "name": "[SZR] - Stara Zagora - Bulgaria",
            "searchName": "SZR-Stara Zagora-Bulgária-Bulgària-Bulgaristan-Bulharsko-Bulgaria-Bulgarien-Bugarska-Bulgaaria-Bulgarija-Bolgarija--Bulgarie",
            "priority": 0
        },
        {
            "iataCode": "ROU",
            "countryCode": "BG",
            "cityCode": null,
            "cityName": "Rousse",
            "name": "[ROU] - Rousse - Bulgaria",
            "searchName": "ROU-Rousse-Bulgária-Bulgària-Bulgaristan-Bulharsko-Bulgaria-Bulgarien-Bugarska-Bulgaaria-Bulgarija-Bolgarija--Bulgarie",
            "priority": 0
        },
        {
            "iataCode": "CXB",
            "countryCode": "BD",
            "cityCode": null,
            "cityName": "Coxs Bazar",
            "name": "[CXB] - Coxs Bazar - Bangladesh",
            "searchName": "CXB-Cox s Bazar-Bazar-Coxs Bazar-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "JSR",
            "countryCode": "BD",
            "cityCode": null,
            "cityName": "Jessore",
            "name": "[JSR] - Jessore - Bangladesh",
            "searchName": "JSR-Jessore-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "IRD",
            "countryCode": "BD",
            "cityCode": null,
            "cityName": "Ishurdi",
            "name": "[IRD] - Ishurdi - Bangladesh",
            "searchName": "IRD-Ishurdi-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "TKR",
            "countryCode": "BD",
            "cityCode": null,
            "cityName": "Thakurgaon",
            "name": "[TKR] - Thakurgaon - Bangladesh",
            "searchName": "TKR-Thakurgaon-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "ZHM",
            "countryCode": "BD",
            "cityCode": null,
            "cityName": "Shamshernagar",
            "name": "[ZHM] - Shamshernagar - Bangladesh",
            "searchName": "ZHM-Shamshernagar-Shamshernager-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "SPD",
            "countryCode": "BD",
            "cityCode": null,
            "cityName": "Saidpur",
            "name": "[SPD] - Saidpur - Bangladesh",
            "searchName": "SPD-Saidpur-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "RJH",
            "countryCode": "BD",
            "cityCode": null,
            "cityName": "Rajshahi",
            "name": "[RJH] - Rajshahi - Bangladesh",
            "searchName": "RJH-Rajshahi-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "ZYL",
            "countryCode": "BD",
            "cityCode": null,
            "cityName": "Sylhet",
            "name": "[ZYL] - Sylhet - Bangladesh",
            "searchName": "ZYL-Sylhet-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "QMO",
            "countryCode": "BE",
            "cityCode": null,
            "cityName": "Mons",
            "name": "[QMO] - Mons - Belgium",
            "searchName": "QMO-Mons-Belgia-Belgium-Belgien-België-Bélgica-Belgie-Belçika-Bèlgica-Belgique-Belgio",
            "priority": 0
        },
        {
            "iataCode": "QNM",
            "countryCode": "BE",
            "cityCode": null,
            "cityName": "Namur",
            "name": "[QNM] - Namur - Belgium",
            "searchName": "QNM-Namur-Belgia-Belgium-Belgien-België-Bélgica-Belgie-Belçika-Bèlgica-Belgique-Belgio",
            "priority": 0
        },
        {
            "iataCode": "OBL",
            "countryCode": "BE",
            "cityCode": null,
            "cityName": "Zoersel",
            "name": "[OBL] - Zoersel - Belgium",
            "searchName": "OBL-Zoersel-Belgia-Belgium-Belgien-België-Bélgica-Belgie-Belçika-Bèlgica-Belgique-Belgio",
            "priority": 0
        },
        {
            "iataCode": "OST",
            "countryCode": "BE",
            "cityCode": null,
            "cityName": "Ostend",
            "name": "[OST] - Ostend - Belgium",
            "searchName": "OST-Ostend-Belgia-Belgium-Belgien-België-Bélgica-Belgie-Belçika-Bèlgica-Belgique-Belgio",
            "priority": 0
        },
        {
            "iataCode": "KJK",
            "countryCode": "BE",
            "cityCode": null,
            "cityName": "Kortrijk",
            "name": "[KJK] - Kortrijk - Belgium",
            "searchName": "KJK-Kortrijk-Belgia-Belgium-Belgien-België-Bélgica-Belgie-Belçika-Bèlgica-Belgique-Belgio",
            "priority": 0
        },
        {
            "iataCode": "LGG",
            "countryCode": "BE",
            "cityCode": null,
            "cityName": "Liege",
            "name": "[LGG] - Liege - Belgium",
            "searchName": "LGG-L-Liege-Belgia-Belgium-Belgien-België-Bélgica-Belgie-Belçika-Bèlgica-Belgique-Belgio",
            "priority": 0
        },
        {
            "iataCode": "BDA",
            "countryCode": "BM",
            "cityCode": null,
            "cityName": "St. George",
            "name": "[BDA] - St. George - Bermuda",
            "searchName": "BDA-Bermuda/Hamilton-St. George-Hamilton-Bermuda-Bermudes-Bermudas",
            "priority": 0
        },
        {
            "iataCode": "BWN",
            "countryCode": "BN",
            "cityCode": null,
            "cityName": "Bandar Seri Begawan",
            "name": "[BWN] - Bandar Seri Begawan - Brunei",
            "searchName": "BWN-Bandar Seri Begawan-Brunei Darussalam-Brunei-Brunéi Darussalam-Brunéi",
            "priority": 0
        },
        {
            "iataCode": "BYC",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Yacuiba",
            "name": "[BYC] - Yacuiba - Bolivia",
            "searchName": "BYC-Yacuiba-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "CAM",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Camiri",
            "name": "[CAM] - Camiri - Bolivia",
            "searchName": "CAM-Camiri-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "CBB",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Cochabamba",
            "name": "[CBB] - Cochabamba - Bolivia",
            "searchName": "CBB-Cochabamba-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "BVK",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Huacaraje",
            "name": "[BVK] - Huacaraje - Bolivia",
            "searchName": "BVK-Huacaraje-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "BVL",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Baures",
            "name": "[BVL] - Baures - Bolivia",
            "searchName": "BVL-Baures-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "BJO",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Bermejo",
            "name": "[BJO] - Bermejo - Bolivia",
            "searchName": "BJO-Bermejo-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "APB",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Apolo",
            "name": "[APB] - Apolo - Bolivia",
            "searchName": "APB-Apolo-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "ASC",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Ascension",
            "name": "[ASC] - Ascension - Bolivia",
            "searchName": "ASC-Ascension-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "CIJ",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Cobija",
            "name": "[CIJ] - Cobija - Bolivia",
            "searchName": "CIJ-Cobija-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "CEP",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Concepcion",
            "name": "[CEP] - Concepcion - Bolivia",
            "searchName": "CEP-Concepcion-Conception-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "LPB",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "La Paz",
            "name": "[LPB] - La Paz - Bolivia",
            "searchName": "LPB-La Paz-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "MHW",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Monteagudo",
            "name": "[MHW] - Monteagudo - Bolivia",
            "searchName": "MHW-Monteagudo-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "MGD",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Magdalena",
            "name": "[MGD] - Magdalena - Bolivia",
            "searchName": "MGD-Magdalena-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "GYA",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Guayaramerin",
            "name": "[GYA] - Guayaramerin - Bolivia",
            "searchName": "GYA-Guayaramerin-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SBL",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Santa Ana",
            "name": "[SBL] - Santa Ana - Bolivia",
            "searchName": "SBL-Santa Ana-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SNM",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "San Ignacio De M",
            "name": "[SNM] - San Ignacio De M - Bolivia",
            "searchName": "SNM-San Ignacio de Moxos-San Ignacio De M-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SNG",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "San Ignacio De Velasco",
            "name": "[SNG] - San Ignacio De Velasco - Bolivia",
            "searchName": "SNG-San Ignacio de Velasco-San Ignacio De Velasco-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SJB",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "San Joaquin",
            "name": "[SJB] - San Joaquin - Bolivia",
            "searchName": "SJB-San Joaquin-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SJS",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "San Jose",
            "name": "[SJS] - San Jose - Bolivia",
            "searchName": "SJS-San Jose-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SJV",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "San Javier",
            "name": "[SJV] - San Javier - Bolivia",
            "searchName": "SJV-San Javier-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "PUR",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Puerto Rico",
            "name": "[PUR] - Puerto Rico - Bolivia",
            "searchName": "PUR-Puerto Rico-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "PSZ",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Puerto Suarez",
            "name": "[PSZ] - Puerto Suarez - Bolivia",
            "searchName": "PSZ-Puerto Suarez-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "POI",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Potosi",
            "name": "[POI] - Potosi - Bolivia",
            "searchName": "POI-Potosi-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "RBQ",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Rurrenabaque",
            "name": "[RBQ] - Rurrenabaque - Bolivia",
            "searchName": "RBQ-Rurrenabaque-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "RBO",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Robore",
            "name": "[RBO] - Robore - Bolivia",
            "searchName": "RBO-Robore-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "RIB",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Riberalta",
            "name": "[RIB] - Riberalta - Bolivia",
            "searchName": "RIB-Riberalta-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "REY",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Reyes",
            "name": "[REY] - Reyes - Bolivia",
            "searchName": "REY-Reyes-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "MQK",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "San Matias",
            "name": "[MQK] - San Matias - Bolivia",
            "searchName": "MQK-San Matias-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "ORU",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Oruro",
            "name": "[ORU] - Oruro - Bolivia",
            "searchName": "ORU-Oruro-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SRD",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "San Ramon",
            "name": "[SRD] - San Ramon - Bolivia",
            "searchName": "SRD-San Ramon-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SRE",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Sucre",
            "name": "[SRE] - Sucre - Bolivia",
            "searchName": "SRE-Sucre-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SRJ",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "San Borja",
            "name": "[SRJ] - San Borja - Bolivia",
            "searchName": "SRJ-San Borja-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SRB",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Santa Rosa",
            "name": "[SRB] - Santa Rosa - Bolivia",
            "searchName": "SRB-Santa Rosa-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SRZ",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Santa Cruz",
            "name": "[SRZ] - Santa Cruz - Bolivia",
            "searchName": "SRZ-Santa Cruz-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "TDD",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Trinidad",
            "name": "[TDD] - Trinidad - Bolivia",
            "searchName": "TDD-Trinidad-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "TJA",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Tarija",
            "name": "[TJA] - Tarija - Bolivia",
            "searchName": "TJA-Tarija-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "VAH",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Vallegrande",
            "name": "[VAH] - Vallegrande - Bolivia",
            "searchName": "VAH-Vallegrande-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "UYU",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Uyuni",
            "name": "[UYU] - Uyuni - Bolivia",
            "searchName": "UYU-Uyuni-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "VLM",
            "countryCode": "BO",
            "cityCode": null,
            "cityName": "Villamontes",
            "name": "[VLM] - Villamontes - Bolivia",
            "searchName": "VLM-Villamontes-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SAB",
            "countryCode": "BQ",
            "cityCode": null,
            "cityName": "Saba Island",
            "name": "[SAB] - Saba Island - Bonaire, Saint Eustatius and Saba ",
            "searchName": "SAB-Saba Island--Bonaire Sint Eustatius and Saba-BES-Bonaire- Saint Eustatius and Saba -Hollanda Antilleri-Pays-Bas caribéens-Caribe neerlandés-Caraibi Olandesi-Bonaire, Saint Eustatius and Saba ",
            "priority": 0
        },
        {
            "iataCode": "EUX",
            "countryCode": "BQ",
            "cityCode": null,
            "cityName": "St Eustatius",
            "name": "[EUX] - St Eustatius - Bonaire, Saint Eustatius and Saba ",
            "searchName": "EUX-St Eustatius-St. Eustatius-Bonaire Sint Eustatius and Saba-BES-Bonaire- Saint Eustatius and Saba -Hollanda Antilleri-Pays-Bas caribéens-Caribe neerlandés-Caraibi Olandesi-Bonaire, Saint Eustatius and Saba ",
            "priority": 0
        },
        {
            "iataCode": "BON",
            "countryCode": "BQ",
            "cityCode": null,
            "cityName": "Kralendijk",
            "name": "[BON] - Kralendijk - Bonaire, Saint Eustatius and Saba ",
            "searchName": "BON-Kralendijk-Bonaire-Bonaire Sint Eustatius and Saba-BES- Saint Eustatius and Saba -Hollanda Antilleri-Pays-Bas caribéens-Caribe neerlandés-Caraibi Olandesi-Bonaire, Saint Eustatius and Saba ",
            "priority": 0
        },
        {
            "iataCode": "BPG",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Barra Do Garcas",
            "name": "[BPG] - Barra Do Garcas - Brazil",
            "searchName": "BPG-Barra Do Garcas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BNU",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Blumenau",
            "name": "[BNU] - Blumenau - Brazil",
            "searchName": "BNU-Blumenau-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BMS",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Brumado",
            "name": "[BMS] - Brumado - Brazil",
            "searchName": "BMS-Brumado-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BJP",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Braganca Paulista",
            "name": "[BJP] - Braganca Paulista - Brazil",
            "searchName": "BJP-Braganca Paulista-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BFH",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Curitiba.",
            "name": "[BFH] - Curitiba. - Brazil",
            "searchName": "BFH-Curitiba-Curitiba.-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BEL",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Belem",
            "name": "[BEL] - Belem - Brazil",
            "searchName": "BEL-Bel-Belem-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BHZ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Belo Horizonte",
            "name": "[BHZ] - Belo Horizonte - Brazil",
            "searchName": "BHZ-Belo Horizonte-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BGV",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Bento Goncalves",
            "name": "[BGV] - Bento Goncalves - Brazil",
            "searchName": "BGV-Bento Goncalves-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BGX",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Bage",
            "name": "[BGX] - Bage - Brazil",
            "searchName": "BGX-Bage-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BVM",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Belmonte",
            "name": "[BVM] - Belmonte - Brazil",
            "searchName": "BVM-Belmonte-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BVH",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Vilhena",
            "name": "[BVH] - Vilhena - Brazil",
            "searchName": "BVH-Vilhena-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BVS",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Breves",
            "name": "[BVS] - Breves - Brazil",
            "searchName": "BVS-Breves-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BVB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Boa Vista",
            "name": "[BVB] - Boa Vista - Brazil",
            "searchName": "BVB-Boa Vista-Boavista-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ZTU",
            "countryCode": "AZ",
            "cityCode": null,
            "cityName": "Zaqatala",
            "name": "[ZTU] - Zaqatala - Azerbaijan",
            "searchName": "ZTU-Zaqatala-Azerbajdzjan-Aserbaidschan-Azerbaijan-Aserbajdsjan-Azerbejdzan-Azerbaidzjan-Azerbeijão-Azerbaycan-Azerbaïdjan-Azerbaiyán-Azerbaigian",
            "priority": 0
        },
        {
            "iataCode": "NAJ",
            "countryCode": "AZ",
            "cityCode": null,
            "cityName": "Nakhichevan",
            "name": "[NAJ] - Nakhichevan - Azerbaijan",
            "searchName": "NAJ-Nakhichevan-Nah-Azerbajdzjan-Aserbaidschan-Azerbaijan-Aserbajdsjan-Azerbejdzan-Azerbaidzjan-Azerbeijão-Azerbaycan-Azerbaïdjan-Azerbaiyán-Azerbaigian",
            "priority": 0
        },
        {
            "iataCode": "SJJ",
            "countryCode": "BA",
            "cityCode": null,
            "cityName": "Sarajevo",
            "name": "[SJJ] - Sarajevo - Bosnia and Herzegovina",
            "searchName": "SJJ-Sarajevo-Bosznia-Hercegovina-Bòsnia i Hercegovina-Bosna Hersek-Bosna a Hercegovina-Bosnia ja Hertsegovina-Bosnien-Bosnia-Bosnia si Hertegovin-Bosnien und Herzegovina-Bosnia and Herzegovina-Bosna-Hersek-Bosnie-Herzégovine-Herzegovina-Bosnia Erzegovina",
            "priority": 0
        },
        {
            "iataCode": "TZL",
            "countryCode": "BA",
            "cityCode": null,
            "cityName": "Tuzla",
            "name": "[TZL] - Tuzla - Bosnia and Herzegovina",
            "searchName": "TZL-Tuzla-Bosznia-Hercegovina-Bòsnia i Hercegovina-Bosna Hersek-Bosna a Hercegovina-Bosnia ja Hertsegovina-Bosnien-Bosnia-Bosnia si Hertegovin-Bosnien und Herzegovina-Bosnia and Herzegovina-Bosna-Hersek-Bosnie-Herzégovine-Herzegovina-Bosnia Erzegovina",
            "priority": 0
        },
        {
            "iataCode": "BNX",
            "countryCode": "BA",
            "cityCode": null,
            "cityName": "Banja Luka",
            "name": "[BNX] - Banja Luka - Bosnia and Herzegovina",
            "searchName": "BNX-Banja Luka-Bosznia-Hercegovina-Bòsnia i Hercegovina-Bosna Hersek-Bosna a Hercegovina-Bosnia ja Hertsegovina-Bosnien-Bosnia-Bosnia si Hertegovin-Bosnien und Herzegovina-Bosnia and Herzegovina-Bosna-Hersek-Bosnie-Herzégovine-Herzegovina-Bosnia Erzegovina",
            "priority": 0
        },
        {
            "iataCode": "AUU",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Aurukun Mission",
            "name": "[AUU] - Aurukun Mission - Australia",
            "searchName": "AUU-Aurukun Mission-Aurukun-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AUD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Augustus Downs",
            "name": "[AUD] - Augustus Downs - Australia",
            "searchName": "AUD-Augustus Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ARY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Ararat",
            "name": "[ARY] - Ararat - Australia",
            "searchName": "ARY-Ararat-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ADO",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Andamooka",
            "name": "[ADO] - Andamooka - Australia",
            "searchName": "ADO-Andamooka-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AAB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Arrabury",
            "name": "[AAB] - Arrabury - Australia",
            "searchName": "AAB-Arrabury-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ABG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Abingdon",
            "name": "[ABG] - Abingdon - Australia",
            "searchName": "ABG-Abingdon-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ABH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Alpha",
            "name": "[ABH] - Alpha - Australia",
            "searchName": "ABH-Alpha-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ABM",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Bamaga",
            "name": "[ABM] - Bamaga - Australia",
            "searchName": "ABM-Bamaga-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ABX",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Albury",
            "name": "[ABX] - Albury - Australia",
            "searchName": "ABX-Albury-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ANR",
            "countryCode": "BE",
            "cityCode": null,
            "cityName": "Antwerp",
            "name": "[ANR] - Antwerp - Belgium",
            "searchName": "ANR-Antwerp-Amberes-Anvers-Anversa-Ansvers-Belgia-Belgium-Belgien-België-Bélgica-Belgie-Belçika-Bèlgica-Belgique-Belgio",
            "priority": 0
        },
        {
            "iataCode": "ARL",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Arly",
            "name": "[ARL] - Arly - Burkina Faso",
            "searchName": "ARL-Arly-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "BNR",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Banfora",
            "name": "[BNR] - Banfora - Burkina Faso",
            "searchName": "BNR-Banfora-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "BOY",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Bobo Dioulasso",
            "name": "[BOY] - Bobo Dioulasso - Burkina Faso",
            "searchName": "BOY-Bobo-Dioulasso-Bobo Dioulasso-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "DGU",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Dedougou",
            "name": "[DGU] - Dedougou - Burkina Faso",
            "searchName": "DGU-Dedougou-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "DIP",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Diapaga",
            "name": "[DIP] - Diapaga - Burkina Faso",
            "searchName": "DIP-Diapaga-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "DOR",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Dori",
            "name": "[DOR] - Dori - Burkina Faso",
            "searchName": "DOR-Dori-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "FNG",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Fada Ngourma",
            "name": "[FNG] - Fada Ngourma - Burkina Faso",
            "searchName": "FNG-Fada Ngourma-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "OUA",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Ouagadougou",
            "name": "[OUA] - Ouagadougou - Burkina Faso",
            "searchName": "OUA-Ouagadougou-Uagadug-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "OUG",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Ouahigouya",
            "name": "[OUG] - Ouahigouya - Burkina Faso",
            "searchName": "OUG-Ouahigouya-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "PUP",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Po",
            "name": "[PUP] - Po - Burkina Faso",
            "searchName": "PUP-Po-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XDE",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Diebougou",
            "name": "[XDE] - Diebougou - Burkina Faso",
            "searchName": "XDE-Diebougou-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XDJ",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Djibo",
            "name": "[XDJ] - Djibo - Burkina Faso",
            "searchName": "XDJ-Djibo-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XAR",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Aribinda",
            "name": "[XAR] - Aribinda - Burkina Faso",
            "searchName": "XAR-Aribinda-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XBG",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Bogande",
            "name": "[XBG] - Bogande - Burkina Faso",
            "searchName": "XBG-Bogande-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XBO",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Boulsa",
            "name": "[XBO] - Boulsa - Burkina Faso",
            "searchName": "XBO-Boulsa-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XKA",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Kantchari",
            "name": "[XKA] - Kantchari - Burkina Faso",
            "searchName": "XKA-Kantchari-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XGG",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Gorom-Gorom",
            "name": "[XGG] - Gorom-Gorom - Burkina Faso",
            "searchName": "XGG-Gorom-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XGA",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Gaoua",
            "name": "[XGA] - Gaoua - Burkina Faso",
            "searchName": "XGA-Gaoua-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XSE",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Sebba",
            "name": "[XSE] - Sebba - Burkina Faso",
            "searchName": "XSE-Sebba-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XKY",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Kaya",
            "name": "[XKY] - Kaya - Burkina Faso",
            "searchName": "XKY-Kaya-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XLU",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Leo",
            "name": "[XLU] - Leo - Burkina Faso",
            "searchName": "XLU-Leo-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XNU",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Nouna",
            "name": "[XNU] - Nouna - Burkina Faso",
            "searchName": "XNU-Nouna-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XPA",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Pama",
            "name": "[XPA] - Pama - Burkina Faso",
            "searchName": "XPA-Pama-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XZA",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Zabre",
            "name": "[XZA] - Zabre - Burkina Faso",
            "searchName": "XZA-Zabre-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "TMQ",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Tambao",
            "name": "[TMQ] - Tambao - Burkina Faso",
            "searchName": "TMQ-Tambao-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "TEG",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Tenkodogo",
            "name": "[TEG] - Tenkodogo - Burkina Faso",
            "searchName": "TEG-Tenkodogo-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "TUQ",
            "countryCode": "BF",
            "cityCode": null,
            "cityName": "Tougan",
            "name": "[TUQ] - Tougan - Burkina Faso",
            "searchName": "TUQ-Tougan-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "MQE",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Marqua",
            "name": "[MQE] - Marqua - Australia",
            "searchName": "MQE-Marqua-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MQA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mandora",
            "name": "[MQA] - Mandora - Australia",
            "searchName": "MQA-Mandora-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MQL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mildura",
            "name": "[MQL] - Mildura - Australia",
            "searchName": "MQL-Mildura-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MQZ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Margaret River",
            "name": "[MQZ] - Margaret River - Australia",
            "searchName": "MQZ-Margaret River-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MRG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mareeba",
            "name": "[MRG] - Mareeba - Australia",
            "searchName": "MRG-Mareeba-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MRL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Miners Lake",
            "name": "[MRL] - Miners Lake - Australia",
            "searchName": "MRL-Miners Lake-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MRP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Marla",
            "name": "[MRP] - Marla - Australia",
            "searchName": "MRP-Marla-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MRT",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Moroak",
            "name": "[MRT] - Moroak - Australia",
            "searchName": "MRT-Moroak-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MRZ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Moree",
            "name": "[MRZ] - Moree - Australia",
            "searchName": "MRZ-Moree-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MSF",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mount Swan",
            "name": "[MSF] - Mount Swan - Australia",
            "searchName": "MSF-Mount Swan-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MTD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mount Sandford",
            "name": "[MTD] - Mount Sandford - Australia",
            "searchName": "MTD-Mount Sandford-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MTL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Maitland",
            "name": "[MTL] - Maitland - Australia",
            "searchName": "MTL-Maitland-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MTQ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mitchell",
            "name": "[MTQ] - Mitchell - Australia",
            "searchName": "MTQ-Mitchell-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MUP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Mulga Park",
            "name": "[MUP] - Mulga Park - Australia",
            "searchName": "MUP-Mulga Park-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MUQ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Muccan",
            "name": "[MUQ] - Muccan - Australia",
            "searchName": "MUQ-Muccan-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MVH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Macksville",
            "name": "[MVH] - Macksville - Australia",
            "searchName": "MVH-Macksville-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ZVG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Springvale",
            "name": "[ZVG] - Springvale - Australia",
            "searchName": "ZVG-Springvale-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TAN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Tangalooma",
            "name": "[TAN] - Tangalooma - Australia",
            "searchName": "TAN-Tangalooma-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TAQ",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Tarcoola",
            "name": "[TAQ] - Tarcoola - Australia",
            "searchName": "TAQ-Tarcoola-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TBK",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Timber Creek",
            "name": "[TBK] - Timber Creek - Australia",
            "searchName": "TBK-Timber Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TBL",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Tableland",
            "name": "[TBL] - Tableland - Australia",
            "searchName": "TBL-Tableland-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TCA",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Tennant Creek",
            "name": "[TCA] - Tennant Creek - Australia",
            "searchName": "TCA-Tennant Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "STF",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Stephen Island",
            "name": "[STF] - Stephen Island - Australia",
            "searchName": "STF-Stephen Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "STH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Strathmore",
            "name": "[STH] - Strathmore - Australia",
            "searchName": "STH-Strathmore-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SSK",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Sturt Creek",
            "name": "[SSK] - Sturt Creek - Australia",
            "searchName": "SSK-Sturt Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SSP",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Silver Plains",
            "name": "[SSP] - Silver Plains - Australia",
            "searchName": "SSP-Silver Plains-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SRM",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Sandringham",
            "name": "[SRM] - Sandringham - Australia",
            "searchName": "SRM-Sandringham-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SRN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Strahan",
            "name": "[SRN] - Strahan - Australia",
            "searchName": "SRN-Strahan-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SRR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Stradbroke Island",
            "name": "[SRR] - Stradbroke Island - Australia",
            "searchName": "SRR-Stradbroke Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SVM",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "St Pauls",
            "name": "[SVM] - St Pauls - Australia",
            "searchName": "SVM-St Pauls-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SWB",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Shaw River",
            "name": "[SWB] - Shaw River - Australia",
            "searchName": "SWB-Shaw River-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SWC",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Stawell",
            "name": "[SWC] - Stawell - Australia",
            "searchName": "SWC-Stawell-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SWH",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Swan Hill",
            "name": "[SWH] - Swan Hill - Australia",
            "searchName": "SWH-Swan Hill-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TDN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Theda",
            "name": "[TDN] - Theda - Australia",
            "searchName": "TDN-Theda Station-Theda-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TDR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Theodore",
            "name": "[TDR] - Theodore - Australia",
            "searchName": "TDR-Theodore-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TCW",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Tocumwal",
            "name": "[TCW] - Tocumwal - Australia",
            "searchName": "TCW-Tocumwal-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TEF",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Telfer",
            "name": "[TEF] - Telfer - Australia",
            "searchName": "TEF-Telfer-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TEM",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Temora",
            "name": "[TEM] - Temora - Australia",
            "searchName": "TEM-Temora-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TGN",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Traralgon",
            "name": "[TGN] - Traralgon - Australia",
            "searchName": "TGN-Traralgon-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "THG",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Thangool",
            "name": "[THG] - Thangool - Australia",
            "searchName": "THG-Thangool-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TIS",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Thursday Island",
            "name": "[TIS] - Thursday Island - Australia",
            "searchName": "TIS-Thursday Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TMW",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Tamworth",
            "name": "[TMW] - Tamworth - Australia",
            "searchName": "TMW-Tamworth-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TKY",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Turkey Creek",
            "name": "[TKY] - Turkey Creek - Australia",
            "searchName": "TKY-Turkey Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TPR",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Tom Price",
            "name": "[TPR] - Tom Price - Australia",
            "searchName": "TPR-Tom Price-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MHQ",
            "countryCode": "AX",
            "cityCode": null,
            "cityName": "Mariehamn",
            "name": "[MHQ] - Mariehamn - Aland Islands",
            "searchName": "MHQ-Mariehamn-Maarianhamina-Aland Islands-Finnland-Finland-Islas Åland-Îles Åland-Isole Aland",
            "priority": 0
        },
        {
            "iataCode": "LLK",
            "countryCode": "AZ",
            "cityCode": null,
            "cityName": "Lankaran",
            "name": "[LLK] - Lankaran - Azerbaijan",
            "searchName": "LLK-Lankaran-Azerbajdzjan-Aserbaidschan-Azerbaijan-Aserbajdsjan-Azerbejdzan-Azerbaidzjan-Azerbeijão-Azerbaycan-Azerbaïdjan-Azerbaiyán-Azerbaigian",
            "priority": 0
        },
        {
            "iataCode": "KVD",
            "countryCode": "AZ",
            "cityCode": null,
            "cityName": "Gyandzha",
            "name": "[KVD] - Gyandzha - Azerbaijan",
            "searchName": "KVD-Gyandzha-Azerbajdzjan-Aserbaidschan-Azerbaijan-Aserbajdsjan-Azerbejdzan-Azerbaidzjan-Azerbeijão-Azerbaycan-Azerbaïdjan-Azerbaiyán-Azerbaigian",
            "priority": 0
        },
        {
            "iataCode": "GBB",
            "countryCode": "AZ",
            "cityCode": null,
            "cityName": "Gabala",
            "name": "[GBB] - Gabala - Azerbaijan",
            "searchName": "GBB-Gabala-Azerbajdzjan-Aserbaidschan-Azerbaijan-Aserbajdsjan-Azerbejdzan-Azerbaidzjan-Azerbeijão-Azerbaycan-Azerbaïdjan-Azerbaiyán-Azerbaigian",
            "priority": 0
        },
        {
            "iataCode": "FBA",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Fonte Boa",
            "name": "[FBA] - Fonte Boa - Brazil",
            "searchName": "FBA-Fonte Boa-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "FEC",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Feira De Santana",
            "name": "[FEC] - Feira De Santana - Brazil",
            "searchName": "FEC-Feira De Santana-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "FEJ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Feijo",
            "name": "[FEJ] - Feijo - Brazil",
            "searchName": "FEJ-Feijo-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "FEN",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Fernando De Noronha",
            "name": "[FEN] - Fernando De Noronha - Brazil",
            "searchName": "FEN-Fernando De Noronha-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "DNO",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Dianopolis",
            "name": "[DNO] - Dianopolis - Brazil",
            "searchName": "DNO-Dianopolis-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "DMT",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Diamantino",
            "name": "[DMT] - Diamantino - Brazil",
            "searchName": "DMT-Diamantino-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "DOU",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Dourados",
            "name": "[DOU] - Dourados - Brazil",
            "searchName": "DOU-Dourados-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "DTI",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Diamantina",
            "name": "[DTI] - Diamantina - Brazil",
            "searchName": "DTI-Diamantina-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CFB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Cabo Frio",
            "name": "[CFB] - Cabo Frio - Brazil",
            "searchName": "CFB-Cabo Frio-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CFC",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Cacador",
            "name": "[CFC] - Cacador - Brazil",
            "searchName": "CFC-Cacador-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CDI",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Cachoeiro Itapemirim",
            "name": "[CDI] - Cachoeiro Itapemirim - Brazil",
            "searchName": "CDI-Cachoeiro de Itapemirim-Cachoeiro Itapemirim-Cachoeiro De Itapemirim-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CDJ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Conceicao Do Araguaia",
            "name": "[CDJ] - Conceicao Do Araguaia - Brazil",
            "searchName": "CDJ-Conceicao do Araguaia-Conceicao Do Araguaia-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CCX",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Caceres",
            "name": "[CCX] - Caceres - Brazil",
            "searchName": "CCX-Caceres-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CCM",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Criciuma",
            "name": "[CCM] - Criciuma - Brazil",
            "searchName": "CCM-Crici-Criciuma-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CCQ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Cachoeira",
            "name": "[CCQ] - Cachoeira - Brazil",
            "searchName": "CCQ-Cachoeira-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CIZ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Coari",
            "name": "[CIZ] - Coari - Brazil",
            "searchName": "CIZ-Coari-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CGR",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Campo Grande",
            "name": "[CGR] - Campo Grande - Brazil",
            "searchName": "CGR-Campo Grande-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CGB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Cuiaba",
            "name": "[CGB] - Cuiaba - Brazil",
            "searchName": "CGB-Cuiab-Cuiaba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CFO",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Confreza",
            "name": "[CFO] - Confreza - Brazil",
            "searchName": "CFO-Confreza-Confreza Brazil-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CNV",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Canavieiras",
            "name": "[CNV] - Canavieiras - Brazil",
            "searchName": "CNV-Canavieiras-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CQS",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Costa Marques",
            "name": "[CQS] - Costa Marques - Brazil",
            "searchName": "CQS-Costa Marques-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CQA",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Canarana",
            "name": "[CQA] - Canarana - Brazil",
            "searchName": "CQA-Canarana-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CPQ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Campinas",
            "name": "[CPQ] - Campinas - Brazil",
            "searchName": "CPQ-Campinas-Sao Paulo-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CPU",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Cururupu",
            "name": "[CPU] - Cururupu - Brazil",
            "searchName": "CPU-Cururupu-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CPV",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Campina Grande",
            "name": "[CPV] - Campina Grande - Brazil",
            "searchName": "CPV-Campina Grande-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CKO",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Cornelio Procopio",
            "name": "[CKO] - Cornelio Procopio - Brazil",
            "searchName": "CKO-Cornelio Procopio-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CKS",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Carajas",
            "name": "[CKS] - Carajas - Brazil",
            "searchName": "CKS-Carajas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CMT",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Cameta",
            "name": "[CMT] - Cameta - Brazil",
            "searchName": "CMT-Cameta-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CMP",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Santana Do Araguaia",
            "name": "[CMP] - Santana Do Araguaia - Brazil",
            "searchName": "CMP-Santana Do Araguaia-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CMG",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Corumba",
            "name": "[CMG] - Corumba - Brazil",
            "searchName": "CMG-Corumb-Corumba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CMC",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Camocim",
            "name": "[CMC] - Camocim - Brazil",
            "searchName": "CMC-Camocim-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CLN",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Carolina",
            "name": "[CLN] - Carolina - Brazil",
            "searchName": "CLN-Carolina-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CLV",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Caldas Novas",
            "name": "[CLV] - Caldas Novas - Brazil",
            "searchName": "CLV-Caldas Novas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "DIQ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Divinopolis",
            "name": "[DIQ] - Divinopolis - Brazil",
            "searchName": "DIQ-Divinopolis-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CZS",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Cruzeiro Do Sul",
            "name": "[CZS] - Cruzeiro Do Sul - Brazil",
            "searchName": "CZS-Cruzeiro Do Sul-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CZB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Cruz Alta",
            "name": "[CZB] - Cruz Alta - Brazil",
            "searchName": "CZB-Cruz Alta-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CWB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Curitiba",
            "name": "[CWB] - Curitiba - Brazil",
            "searchName": "CWB-Curitiba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CXJ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Caxias Do Sul",
            "name": "[CXJ] - Caxias Do Sul - Brazil",
            "searchName": "CXJ-Caxias do Sul-Caxias Do Sul-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CTP",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Carutapera",
            "name": "[CTP] - Carutapera - Brazil",
            "searchName": "CTP-Carutapera-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CTQ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Santa Vitoria",
            "name": "[CTQ] - Santa Vitoria - Brazil",
            "searchName": "CTQ-Santa Vitoria MG-Santa Vitoria-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CSS",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Cassilandia",
            "name": "[CSS] - Cassilandia - Brazil",
            "searchName": "CSS-Cassilandia-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CSU",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Santa Cruz Do Sul",
            "name": "[CSU] - Santa Cruz Do Sul - Brazil",
            "searchName": "CSU-Santa Cruz Do Sul-Santa Cruz-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CSW",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Colorado do Oeste",
            "name": "[CSW] - Colorado do Oeste - Brazil",
            "searchName": "CSW-Colorado do Oeste-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CRQ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Caravelas",
            "name": "[CRQ] - Caravelas - Brazil",
            "searchName": "CRQ-Caravelas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "GYN",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Goiania",
            "name": "[GYN] - Goiania - Brazil",
            "searchName": "GYN-Goi-Goiania-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "GVR",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Governador Valadares",
            "name": "[GVR] - Governador Valadares - Brazil",
            "searchName": "GVR-Governador Valadares-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "GUJ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Guaratingueta",
            "name": "[GUJ] - Guaratingueta - Brazil",
            "searchName": "GUJ-Guaratingueta-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "GRP",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Gurupi",
            "name": "[GRP] - Gurupi - Brazil",
            "searchName": "GRP-Gurupi-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "GJM",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Guajara-Mirim",
            "name": "[GJM] - Guajara-Mirim - Brazil",
            "searchName": "GJM-Guajara-Mirim-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "GPB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Guarapuava",
            "name": "[GPB] - Guarapuava - Brazil",
            "searchName": "GPB-Guarapuava-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "GNM",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Guanambi",
            "name": "[GNM] - Guanambi - Brazil",
            "searchName": "GNM-Guanambi-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "GMS",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Guimaraes",
            "name": "[GMS] - Guimaraes - Brazil",
            "searchName": "GMS-Guimaraes-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "GCV",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Gravatai",
            "name": "[GCV] - Gravatai - Brazil",
            "searchName": "GCV-Gravatai-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "GEL",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Santo Angelo",
            "name": "[GEL] - Santo Angelo - Brazil",
            "searchName": "GEL-Santo -Santo Angelo-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "GDP",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Guadalupe",
            "name": "[GDP] - Guadalupe - Brazil",
            "searchName": "GDP-Guadalupe-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JEQ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Jequie",
            "name": "[JEQ] - Jequie - Brazil",
            "searchName": "JEQ-Jequie-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JDO",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Juazeiro Do Norte",
            "name": "[JDO] - Juazeiro Do Norte - Brazil",
            "searchName": "JDO-Juazeiro Do Norte-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JDR",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Sao Joao Del Rei",
            "name": "[JDR] - Sao Joao Del Rei - Brazil",
            "searchName": "JDR-S-Sao Joao Del Rei-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JCM",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Jacobina",
            "name": "[JCM] - Jacobina - Brazil",
            "searchName": "JCM-Jacobina-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JCB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Joacaba",
            "name": "[JCB] - Joacaba - Brazil",
            "searchName": "JCB-Joacaba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JCR",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Jacareacanga",
            "name": "[JCR] - Jacareacanga - Brazil",
            "searchName": "JCR-Jacareacanga-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JDF",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Juiz De Fora",
            "name": "[JDF] - Juiz De Fora - Brazil",
            "searchName": "JDF-Juiz De Fora-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "IRE",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Irece",
            "name": "[IRE] - Irece - Brazil",
            "searchName": "IRE-Irece-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "IRZ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Santa Isabel Rio Negro",
            "name": "[IRZ] - Santa Isabel Rio Negro - Brazil",
            "searchName": "IRZ-Santa Isabel Rio Negro-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ITA",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Itacoatiara",
            "name": "[ITA] - Itacoatiara - Brazil",
            "searchName": "ITA-Itacoatiara-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ITB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Itaituba",
            "name": "[ITB] - Itaituba - Brazil",
            "searchName": "ITB-Itaituba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ITE",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Itubera",
            "name": "[ITE] - Itubera - Brazil",
            "searchName": "ITE-Itubera-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ITI",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Itambacuri",
            "name": "[ITI] - Itambacuri - Brazil",
            "searchName": "ITI-Itambacuri-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ITP",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Itaperuna",
            "name": "[ITP] - Itaperuna - Brazil",
            "searchName": "ITP-Itaperuna-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ITQ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Itaqui",
            "name": "[ITQ] - Itaqui - Brazil",
            "searchName": "ITQ-Itaqui-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ITR",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Itumbiara",
            "name": "[ITR] - Itumbiara - Brazil",
            "searchName": "ITR-Itumbiara-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ITN",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Itabuna",
            "name": "[ITN] - Itabuna - Brazil",
            "searchName": "ITN-Itabuna-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JPR",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Ji-Parana",
            "name": "[JPR] - Ji-Parana - Brazil",
            "searchName": "JPR-Ji-Parana-Ji Parana-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JRN",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Juruena",
            "name": "[JRN] - Juruena - Brazil",
            "searchName": "JRN-Juruena-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JTC",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Bauru",
            "name": "[JTC] - Bauru - Brazil",
            "searchName": "JTC-Bauru-Bauru\\/Arealva-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JTI",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Jatai",
            "name": "[JTI] - Jatai - Brazil",
            "searchName": "JTI-Jatai-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JUA",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Juara",
            "name": "[JUA] - Juara - Brazil",
            "searchName": "JUA-Juara-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JNA",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Januaria",
            "name": "[JNA] - Januaria - Brazil",
            "searchName": "JNA-Januaria-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JLS",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Jales",
            "name": "[JLS] - Jales - Brazil",
            "searchName": "JLS-Jales-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JOI",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Joinville",
            "name": "[JOI] - Joinville - Brazil",
            "searchName": "JOI-Joinville-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JPA",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Joao Pessoa",
            "name": "[JPA] - Joao Pessoa - Brazil",
            "searchName": "JPA-Jo-Joao Pessoa-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JIA",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Juina",
            "name": "[JIA] - Juina - Brazil",
            "searchName": "JIA-Juina-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "HUW",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Humaita",
            "name": "[HUW] - Humaita - Brazil",
            "searchName": "HUW-Humaita-Ji-Parana-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "HRZ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Horizontina",
            "name": "[HRZ] - Horizontina - Brazil",
            "searchName": "HRZ-Horizontina-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ILB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Ilha Solteira",
            "name": "[ILB] - Ilha Solteira - Brazil",
            "searchName": "ILB-Ilha Solteira-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "IGU",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Iguassu Falls",
            "name": "[IGU] - Iguassu Falls - Brazil",
            "searchName": "IGU-Iguassu Falls-Foz de Iguazu-Chutes d Igua-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "IJU",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Ijui",
            "name": "[IJU] - Ijui - Brazil",
            "searchName": "IJU-Ijui-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "IDO",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Santa Isabel Do Morro",
            "name": "[IDO] - Santa Isabel Do Morro - Brazil",
            "searchName": "IDO-Santa Isabel do Morro-Santa Isabel Do Morro-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "IOS",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Ilheus",
            "name": "[IOS] - Ilheus - Brazil",
            "searchName": "IOS-Ilh-Ilheus-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "IPG",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Ipiranga",
            "name": "[IPG] - Ipiranga - Brazil",
            "searchName": "IPG-Ipiranga-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "IPN",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Ipatinga",
            "name": "[IPN] - Ipatinga - Brazil",
            "searchName": "IPN-Ipatinga-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "IPU",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Ipiau",
            "name": "[IPU] - Ipiau - Brazil",
            "searchName": "IPU-Ipiau-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "IMP",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Imperatriz",
            "name": "[IMP] - Imperatriz - Brazil",
            "searchName": "IMP-Imperatriz-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MGF",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Maringa",
            "name": "[MGF] - Maringa - Brazil",
            "searchName": "MGF-Maring-Maringa-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MEU",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Monte Dourado",
            "name": "[MEU] - Monte Dourado - Brazil",
            "searchName": "MEU-Monte Dourado-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MEA",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Macae",
            "name": "[MEA] - Macae - Brazil",
            "searchName": "MEA-Macae-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MII",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Marilia",
            "name": "[MII] - Marilia - Brazil",
            "searchName": "MII-Mar-Marilia-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LVR",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Lucas Do Rio Verde",
            "name": "[LVR] - Lucas Do Rio Verde - Brazil",
            "searchName": "LVR-Lucas Do Rio Verde-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LVB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Livramento",
            "name": "[LVB] - Livramento - Brazil",
            "searchName": "LVB-Livramento-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MAB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Maraba",
            "name": "[MAB] - Maraba - Brazil",
            "searchName": "MAB-Maraba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MBK",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Matupa",
            "name": "[MBK] - Matupa - Brazil",
            "searchName": "MBK-Matupa-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MAO",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Manaus",
            "name": "[MAO] - Manaus - Brazil",
            "searchName": "MAO-Manaus-Manaos-Palmia-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MCZ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Maceio",
            "name": "[MCZ] - Maceio - Brazil",
            "searchName": "MCZ-Macei-Maceio-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MCP",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Macapa",
            "name": "[MCP] - Macapa - Brazil",
            "searchName": "MCP-Macap-Macapa-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MBZ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Maues",
            "name": "[MBZ] - Maues - Brazil",
            "searchName": "MBZ-Maues-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LOI",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Lontras",
            "name": "[LOI] - Lontras - Brazil",
            "searchName": "LOI-Lontras-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LIP",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Lins",
            "name": "[LIP] - Lins - Brazil",
            "searchName": "LIP-Lins-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LAJ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Lages",
            "name": "[LAJ] - Lages - Brazil",
            "searchName": "LAJ-Lages-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LAZ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Bom Jesus Da Lapa",
            "name": "[LAZ] - Bom Jesus Da Lapa - Brazil",
            "searchName": "LAZ-Bom Jesus da Lapa-Bom Jesus Da Lapa-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LEP",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Leopoldina",
            "name": "[LEP] - Leopoldina - Brazil",
            "searchName": "LEP-Leopoldina-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LEC",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Lencois",
            "name": "[LEC] - Lencois - Brazil",
            "searchName": "LEC-Lencois-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LDB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Londrina",
            "name": "[LDB] - Londrina - Brazil",
            "searchName": "LDB-Londrina-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LCB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Pontes e Lacerda",
            "name": "[LCB] - Pontes e Lacerda - Brazil",
            "searchName": "LCB-Pontes e Lacerda-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LBR",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Labrea",
            "name": "[LBR] - Labrea - Brazil",
            "searchName": "LBR-Labrea-L-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "RIA",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Santa Maria",
            "name": "[RIA] - Santa Maria - Brazil",
            "searchName": "RIA-Santa Maria-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SJP",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Sao Jose Do Rio Preto",
            "name": "[SJP] - Sao Jose Do Rio Preto - Brazil",
            "searchName": "SJP-S-Sao Jose Do Rio Preto-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SJK",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Sao Jose Dos Campos",
            "name": "[SJK] - Sao Jose Dos Campos - Brazil",
            "searchName": "SJK-S-Sao Jose Dos Campos-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SJL",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Sao Gabriel",
            "name": "[SJL] - Sao Gabriel - Brazil",
            "searchName": "SJL-Sao Gabriel-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SFV",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Santa Fe Do Sul",
            "name": "[SFV] - Santa Fe Do Sul - Brazil",
            "searchName": "SFV-Santa Fe Do Sul-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SFK",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Soure",
            "name": "[SFK] - Soure - Brazil",
            "searchName": "SFK-Soure-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SNZ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Santa Cruz",
            "name": "[SNZ] - Santa Cruz - Brazil",
            "searchName": "SNZ-Santa Cruz-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SOD",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Sorocaba",
            "name": "[SOD] - Sorocaba - Brazil",
            "searchName": "SOD-Sorocaba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SLZ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "São Luis",
            "name": "[SLZ] - São Luis - Brazil",
            "searchName": "SLZ-Sao Luiz-S-São Luis-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "REZ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Resende",
            "name": "[REZ] - Resende - Brazil",
            "searchName": "REZ-Resende-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "RDC",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Redencao",
            "name": "[RDC] - Redencao - Brazil",
            "searchName": "RDC-Redencao-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "REC",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Recife",
            "name": "[REC] - Recife - Brazil",
            "searchName": "REC-Recife-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "RIG",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Rio Grande",
            "name": "[RIG] - Rio Grande - Brazil",
            "searchName": "RIG-Rio Grande-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "RBR",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Rio Branco",
            "name": "[RBR] - Rio Branco - Brazil",
            "searchName": "RBR-Rio Branco-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "RBB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Borba",
            "name": "[RBB] - Borba - Brazil",
            "searchName": "RBB-Borba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "RAO",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Ribeirao Preto",
            "name": "[RAO] - Ribeirao Preto - Brazil",
            "searchName": "RAO-Ribeir-Ribeirao Preto-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "QPT",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Patos De Minas",
            "name": "[QPT] - Patos De Minas - Brazil",
            "searchName": "QPT-Patos De Minas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "QSC",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Sao Carlos",
            "name": "[QSC] - Sao Carlos - Brazil",
            "searchName": "QSC-Sao Carlos-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PVH",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Porto Velho",
            "name": "[PVH] - Porto Velho - Brazil",
            "searchName": "PVH-Porto Velho-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PVI",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Paranavai",
            "name": "[PVI] - Paranavai - Brazil",
            "searchName": "PVI-Paranavai-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "QDL",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Guarapari",
            "name": "[QDL] - Guarapari - Brazil",
            "searchName": "QDL-Guarapari-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "POO",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Pocos De Caldas",
            "name": "[POO] - Pocos De Caldas - Brazil",
            "searchName": "POO-Pocos de Caldas-Pocos De Caldas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PPB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Presidente Prudente",
            "name": "[PPB] - Presidente Prudente - Brazil",
            "searchName": "PPB-Presidente Prudente-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PPY",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Pouso Alegre",
            "name": "[PPY] - Pouso Alegre - Brazil",
            "searchName": "PPY-Pouso Alegre-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PSW",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Passos",
            "name": "[PSW] - Passos - Brazil",
            "searchName": "PSW-Passos-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PTO",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Pato Branco",
            "name": "[PTO] - Pato Branco - Brazil",
            "searchName": "PTO-Pato Branco-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PTQ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Porto de Moz",
            "name": "[PTQ] - Porto de Moz - Brazil",
            "searchName": "PTQ-Porto de Moz-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PHB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Parnaiba",
            "name": "[PHB] - Parnaiba - Brazil",
            "searchName": "PHB-Parnaiba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PHI",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Pinheiro",
            "name": "[PHI] - Pinheiro - Brazil",
            "searchName": "PHI-Pinheiro-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PIG",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Pitinga",
            "name": "[PIG] - Pitinga - Brazil",
            "searchName": "PIG-Pitinga AM-Pitinga-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PIN",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Parintins",
            "name": "[PIN] - Parintins - Brazil",
            "searchName": "PIN-Parintins-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PIV",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Pirapora",
            "name": "[PIV] - Pirapora - Brazil",
            "searchName": "PIV-Pirapora-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PLL",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Ponta Pelada",
            "name": "[PLL] - Ponta Pelada - Brazil",
            "searchName": "PLL-Ponta Pelada-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PMG",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Ponta Pora",
            "name": "[PMG] - Ponta Pora - Brazil",
            "searchName": "PMG-Ponta Pora-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PNB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Porto Nacional",
            "name": "[PNB] - Porto Nacional - Brazil",
            "searchName": "PNB-Porto Nacional-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PMW",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Palmas",
            "name": "[PMW] - Palmas - Brazil",
            "searchName": "PMW-Palmas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PNG",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Paranagua",
            "name": "[PNG] - Paranagua - Brazil",
            "searchName": "PNG-Paranagua-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PNZ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Petrolina",
            "name": "[PNZ] - Petrolina - Brazil",
            "searchName": "PNZ-Petrolina-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "POA",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Porto Alegre",
            "name": "[POA] - Porto Alegre - Brazil",
            "searchName": "POA-Porto Alegre-Puerto Alegre-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ORX",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Oriximina",
            "name": "[ORX] - Oriximina - Brazil",
            "searchName": "ORX-Oriximina-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "OPS",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Sinop",
            "name": "[OPS] - Sinop - Brazil",
            "searchName": "OPS-Sinop-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "OUS",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Ourinhos",
            "name": "[OUS] - Ourinhos - Brazil",
            "searchName": "OUS-Ourinhos-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "OTT",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Cotriguacu",
            "name": "[OTT] - Cotriguacu - Brazil",
            "searchName": "OTT-Cotriguacu-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "OYK",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Oiapoque",
            "name": "[OYK] - Oiapoque - Brazil",
            "searchName": "OYK-Oiapoque-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PDR",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Presidente Dutra",
            "name": "[PDR] - Presidente Dutra - Brazil",
            "searchName": "PDR-Presidente Dutra-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PDF",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Prado",
            "name": "[PDF] - Prado - Brazil",
            "searchName": "PDF-Prado-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PET",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Pelotas",
            "name": "[PET] - Pelotas - Brazil",
            "searchName": "PET-Pelotas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PFB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Passo Fundo",
            "name": "[PFB] - Passo Fundo - Brazil",
            "searchName": "PFB-Passo Fundo-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PGG",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Progresso",
            "name": "[PGG] - Progresso - Brazil",
            "searchName": "PGG-Progresso-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PGZ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Ponta Grossa",
            "name": "[PGZ] - Ponta Grossa - Brazil",
            "searchName": "PGZ-Ponta Grossa-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PBX",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Porto Alegre Do Norte",
            "name": "[PBX] - Porto Alegre Do Norte - Brazil",
            "searchName": "PBX-Porto Alegre Do Norte-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PBQ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Pimenta Bueno",
            "name": "[PBQ] - Pimenta Bueno - Brazil",
            "searchName": "PBQ-Pimenta Bueno-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PBV",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Porto Dos Gauchos",
            "name": "[PBV] - Porto Dos Gauchos - Brazil",
            "searchName": "PBV-Portos Dos Gauchos-Porto Dos Gauchos-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PCS",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Picos",
            "name": "[PCS] - Picos - Brazil",
            "searchName": "PCS-Picos-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PAV",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Paulo Afonso",
            "name": "[PAV] - Paulo Afonso - Brazil",
            "searchName": "PAV-Paulo Afonso-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PBB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Paranaiba",
            "name": "[PBB] - Paranaiba - Brazil",
            "searchName": "PBB-Paranaiba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "OBI",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Obidos",
            "name": "[OBI] - Obidos - Brazil",
            "searchName": "OBI-Obidos-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "OLC",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Sao Paulo de Olivenca",
            "name": "[OLC] - Sao Paulo de Olivenca - Brazil",
            "searchName": "OLC-Sao Paulo de Olivenca-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "OIA",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Ourilandia",
            "name": "[OIA] - Ourilandia - Brazil",
            "searchName": "OIA-Ourilandia-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "NTM",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Miracema Do Norte",
            "name": "[NTM] - Miracema Do Norte - Brazil",
            "searchName": "NTM-Miracema do Norte-Miracema Do Norte-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "OAL",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Cacoal",
            "name": "[OAL] - Cacoal - Brazil",
            "searchName": "OAL-Cacoal-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "NVP",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Novo Aripuana",
            "name": "[NVP] - Novo Aripuana - Brazil",
            "searchName": "NVP-Novo Aripuana-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "NVT",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Navegantes",
            "name": "[NVT] - Navegantes - Brazil",
            "searchName": "NVT-Navegantes-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "NOK",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Nova Xavantina",
            "name": "[NOK] - Nova Xavantina - Brazil",
            "searchName": "NOK-Nova Xavantina-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "NNU",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Nanuque",
            "name": "[NNU] - Nanuque - Brazil",
            "searchName": "NNU-Nanuque-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "NPR",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Novo Progresso",
            "name": "[NPR] - Novo Progresso - Brazil",
            "searchName": "NPR-Novo Progresso-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "NQL",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Niquelandia",
            "name": "[NQL] - Niquelandia - Brazil",
            "searchName": "NQL-Niquelandia-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MQH",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Minacu",
            "name": "[MQH] - Minacu - Brazil",
            "searchName": "MQH-Minacu-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "QGL",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "St Gallen",
            "name": "[QGL] - St Gallen - Switzerland",
            "searchName": "QGL-St Gallen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZAP",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Appenzell",
            "name": "[ZAP] - Appenzell - Switzerland",
            "searchName": "ZAP-Appenzell-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDA",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Aarau",
            "name": "[ZDA] - Aarau - Switzerland",
            "searchName": "ZDA-Aarau-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDB",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Adelboden",
            "name": "[ZDB] - Adelboden - Switzerland",
            "searchName": "ZDB-Adelboden-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDC",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Aigle",
            "name": "[ZDC] - Aigle - Switzerland",
            "searchName": "ZDC-Aigle-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDD",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Arbon",
            "name": "[ZDD] - Arbon - Switzerland",
            "searchName": "ZDD-Arbon-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDE",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Arosa",
            "name": "[ZDE] - Arosa - Switzerland",
            "searchName": "ZDE-Arosa-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDG",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Baden",
            "name": "[ZDG] - Baden - Switzerland",
            "searchName": "ZDG-Baden-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDI",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Bellinzona",
            "name": "[ZDI] - Bellinzona - Switzerland",
            "searchName": "ZDI-Bellinzona-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDK",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Biel/Bienne",
            "name": "[ZDK] - Biel/Bienne - Switzerland",
            "searchName": "ZDK-Biel/Bienne-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDL",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Brig",
            "name": "[ZDL] - Brig - Switzerland",
            "searchName": "ZDL-Brig-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDO",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Buchs SG",
            "name": "[ZDO] - Buchs SG - Switzerland",
            "searchName": "ZDO-Buchs SG-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDP",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Burgdorf",
            "name": "[ZDP] - Burgdorf - Switzerland",
            "searchName": "ZDP-Burgdorf-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDQ",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Champery",
            "name": "[ZDQ] - Champery - Switzerland",
            "searchName": "ZDQ-Champery-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDR",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Chateau-d Oex",
            "name": "[ZDR] - Chateau-d Oex - Switzerland",
            "searchName": "ZDR-Chateau-d Oex-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDS",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Chiasso",
            "name": "[ZDS] - Chiasso - Switzerland",
            "searchName": "ZDS-Chiasso-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDT",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Chur",
            "name": "[ZDT] - Chur - Switzerland",
            "searchName": "ZDT-Chur-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDV",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Davos",
            "name": "[ZDV] - Davos - Switzerland",
            "searchName": "ZDV-Davos-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDW",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Delemont",
            "name": "[ZDW] - Delemont - Switzerland",
            "searchName": "ZDW-Delemont-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDX",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Dietikon",
            "name": "[ZDX] - Dietikon - Switzerland",
            "searchName": "ZDX-Dietikon-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDZ",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Einsiedeln",
            "name": "[ZDZ] - Einsiedeln - Switzerland",
            "searchName": "ZDZ-Einsiedeln-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJP",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Montreux",
            "name": "[ZJP] - Montreux - Switzerland",
            "searchName": "ZJP-Montreux-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJQ",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Morges",
            "name": "[ZJQ] - Morges - Switzerland",
            "searchName": "ZJQ-Morges-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJI",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Locarno",
            "name": "[ZJI] - Locarno - Switzerland",
            "searchName": "ZJI-Locarno-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJC",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Lenzburg",
            "name": "[ZJC] - Lenzburg - Switzerland",
            "searchName": "ZJC-Lenzburg-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJD",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Lenzerheide/Lai",
            "name": "[ZJD] - Lenzerheide/Lai - Switzerland",
            "searchName": "ZJD-Lenzerheide/Lai-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJL",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Lyss",
            "name": "[ZJL] - Lyss - Switzerland",
            "searchName": "ZJL-Lyss-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJM",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Martigny",
            "name": "[ZJM] - Martigny - Switzerland",
            "searchName": "ZJM-Martigny-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJU",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Olten",
            "name": "[ZJU] - Olten - Switzerland",
            "searchName": "ZJU-Olten-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJV",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Pontresina",
            "name": "[ZJV] - Pontresina - Switzerland",
            "searchName": "ZJV-Pontresina-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJW",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Rapperswil",
            "name": "[ZJW] - Rapperswil - Switzerland",
            "searchName": "ZJW-Rapperswil-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHN",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Herzogenbuchsee",
            "name": "[ZHN] - Herzogenbuchsee - Switzerland",
            "searchName": "ZHN-Herzogenbuchsee-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZIN",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Interlaken",
            "name": "[ZIN] - Interlaken - Switzerland",
            "searchName": "ZIN-Interlaken-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJA",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Le Locle",
            "name": "[ZJA] - Le Locle - Switzerland",
            "searchName": "ZJA-Le Locle-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHR",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Kandersteg",
            "name": "[ZHR] - Kandersteg - Switzerland",
            "searchName": "ZHR-Kandersteg-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHS",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Klosters",
            "name": "[ZHS] - Klosters - Switzerland",
            "searchName": "ZHS-Klosters-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHU",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Kreuzlingen",
            "name": "[ZHU] - Kreuzlingen - Switzerland",
            "searchName": "ZHU-Kreuzlingen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHV",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "La Chaux-de-Fonds",
            "name": "[ZHV] - La Chaux-de-Fonds - Switzerland",
            "searchName": "ZHV-La Chaux-de-Fonds-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHW",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Langenthal",
            "name": "[ZHW] - Langenthal - Switzerland",
            "searchName": "ZHW-Langenthal-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHB",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Engelberg",
            "name": "[ZHB] - Engelberg - Switzerland",
            "searchName": "ZHB-Engelberg-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHD",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Fluelen",
            "name": "[ZHD] - Fluelen - Switzerland",
            "searchName": "ZHD-Fluelen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHE",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Frauenfeld",
            "name": "[ZHE] - Frauenfeld - Switzerland",
            "searchName": "ZHE-Frauenfeld-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHF",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Fribourg",
            "name": "[ZHF] - Fribourg - Switzerland",
            "searchName": "ZHF-Fribourg-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHG",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Glarus",
            "name": "[ZHG] - Glarus - Switzerland",
            "searchName": "ZHG-Glarus-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHH",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Gossau SG",
            "name": "[ZHH] - Gossau SG - Switzerland",
            "searchName": "ZHH-Gossau SG-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHI",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Grenchen",
            "name": "[ZHI] - Grenchen - Switzerland",
            "searchName": "ZHI-Grenchen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHJ",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Grindelwald",
            "name": "[ZHJ] - Grindelwald - Switzerland",
            "searchName": "ZHJ-Grindelwald-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHK",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Gstaad",
            "name": "[ZHK] - Gstaad - Switzerland",
            "searchName": "ZHK-Gstaad-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHL",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Heerbrugg",
            "name": "[ZHL] - Heerbrugg - Switzerland",
            "searchName": "ZHL-Heerbrugg-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKI",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Saas-Fee",
            "name": "[ZKI] - Saas-Fee - Switzerland",
            "searchName": "ZKI-Saas-Fee-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKJ",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Schaffhausen",
            "name": "[ZKJ] - Schaffhausen - Switzerland",
            "searchName": "ZKJ-Schaffhausen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKK",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Schwyz",
            "name": "[ZKK] - Schwyz - Switzerland",
            "searchName": "ZKK-Schwyz-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJZ",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Rorschach",
            "name": "[ZJZ] - Rorschach - Switzerland",
            "searchName": "ZJZ-Rorschach-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKA",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Sargans",
            "name": "[ZKA] - Sargans - Switzerland",
            "searchName": "ZKA-Sargans-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKC",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Sarnen",
            "name": "[ZKC] - Sarnen - Switzerland",
            "searchName": "ZKC-Sarnen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKO",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Sierre/Siders",
            "name": "[ZKO] - Sierre/Siders - Switzerland",
            "searchName": "ZKO-Sierre/Siders-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKS",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Solothurn",
            "name": "[ZKS] - Solothurn - Switzerland",
            "searchName": "ZKS-Solothurn-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKU",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Sursee",
            "name": "[ZKU] - Sursee - Switzerland",
            "searchName": "ZKU-Sursee-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKV",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Thalwil",
            "name": "[ZKV] - Thalwil - Switzerland",
            "searchName": "ZKV-Thalwil-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKW",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Wetzikon",
            "name": "[ZKW] - Wetzikon - Switzerland",
            "searchName": "ZKW-Wetzikon-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKX",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Uzwil",
            "name": "[ZKX] - Uzwil - Switzerland",
            "searchName": "ZKX-Uzwil-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKY",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Verbier",
            "name": "[ZKY] - Verbier - Switzerland",
            "searchName": "ZKY-Verbier-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKZ",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Vevey",
            "name": "[ZKZ] - Vevey - Switzerland",
            "searchName": "ZKZ-Vevey-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLA",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Villars-sur-Ollon",
            "name": "[ZLA] - Villars-sur-Ollon - Switzerland",
            "searchName": "ZLA-Villars-sur-Ollon-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLB",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Visp",
            "name": "[ZLB] - Visp - Switzerland",
            "searchName": "ZLB-Visp-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLC",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Waedenswil",
            "name": "[ZLC] - Waedenswil - Switzerland",
            "searchName": "ZLC-Waedenswil-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLD",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Weinfelden",
            "name": "[ZLD] - Weinfelden - Switzerland",
            "searchName": "ZLD-Weinfelden-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLE",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Wengen",
            "name": "[ZLE] - Wengen - Switzerland",
            "searchName": "ZLE-Wengen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLF",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Wettingen",
            "name": "[ZLF] - Wettingen - Switzerland",
            "searchName": "ZLF-Wettingen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLH",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Wil",
            "name": "[ZLH] - Wil - Switzerland",
            "searchName": "ZLH-Wil-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLI",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Winterthur",
            "name": "[ZLI] - Winterthur - Switzerland",
            "searchName": "ZLI-Winterthur-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLJ",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Yverdon-les-Bains",
            "name": "[ZLJ] - Yverdon-les-Bains - Switzerland",
            "searchName": "ZLJ-Yverdon-les-Bains-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLL",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Zofingen",
            "name": "[ZLL] - Zofingen - Switzerland",
            "searchName": "ZLL-Zofingen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLM",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Zug",
            "name": "[ZLM] - Zug - Switzerland",
            "searchName": "ZLM-Zug-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKF",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "St Margrethen",
            "name": "[ZKF] - St Margrethen - Switzerland",
            "searchName": "ZKF-St Margrethen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZTK",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Thun",
            "name": "[ZTK] - Thun - Switzerland",
            "searchName": "ZTK-Thun-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZRN",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Nyon",
            "name": "[ZRN] - Nyon - Switzerland",
            "searchName": "ZRN-Nyon-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "SLS",
            "countryCode": "BG",
            "cityCode": null,
            "cityName": "Silistra",
            "name": "[SLS] - Silistra - Bulgaria",
            "searchName": "SLS-Silistra-Bulgária-Bulgària-Bulgaristan-Bulharsko-Bulgaria-Bulgarien-Bugarska-Bulgaaria-Bulgarija-Bolgarija--Bulgarie",
            "priority": 0
        },
        {
            "iataCode": "PDV",
            "countryCode": "BG",
            "cityCode": null,
            "cityName": "Plovdiv",
            "name": "[PDV] - Plovdiv - Bulgaria",
            "searchName": "PDV-Plowdiw-Plovdiv-Filippopoli-Felibe-Bulgária-Bulgària-Bulgaristan-Bulharsko-Bulgaria-Bulgarien-Bugarska-Bulgaaria-Bulgarija-Bolgarija--Bulgarie",
            "priority": 0
        },
        {
            "iataCode": "JAM",
            "countryCode": "BG",
            "cityCode": null,
            "cityName": "Jambol",
            "name": "[JAM] - Jambol - Bulgaria",
            "searchName": "JAM-Jambol-Bulgária-Bulgària-Bulgaristan-Bulharsko-Bulgaria-Bulgarien-Bugarska-Bulgaaria-Bulgarija-Bolgarija--Bulgarie",
            "priority": 0
        },
        {
            "iataCode": "GOZ",
            "countryCode": "BG",
            "cityCode": null,
            "cityName": "Gorna Oriahovitsa",
            "name": "[GOZ] - Gorna Oriahovitsa - Bulgaria",
            "searchName": "GOZ-Gorna Oryahovitsa-Gorna Oriahovitsa-Bulgária-Bulgària-Bulgaristan-Bulharsko-Bulgaria-Bulgarien-Bugarska-Bulgaaria-Bulgarija-Bolgarija--Bulgarie",
            "priority": 0
        },
        {
            "iataCode": "HKV",
            "countryCode": "BG",
            "cityCode": null,
            "cityName": "Haskovo",
            "name": "[HKV] - Haskovo - Bulgaria",
            "searchName": "HKV-Haskovo-Bulgária-Bulgària-Bulgaristan-Bulharsko-Bulgaria-Bulgarien-Bugarska-Bulgaaria-Bulgarija-Bolgarija--Bulgarie",
            "priority": 0
        },
        {
            "iataCode": "BQO",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Bouna",
            "name": "[BQO] - Bouna - Ivory Coast",
            "searchName": "BQO-Bouna-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "BXI",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Boundiali",
            "name": "[BXI] - Boundiali - Ivory Coast",
            "searchName": "BXI-Boundiali-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "BYK",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Bouake",
            "name": "[BYK] - Bouake - Ivory Coast",
            "searchName": "BYK-Bouake-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "BDK",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Bondoukou",
            "name": "[BDK] - Bondoukou - Ivory Coast",
            "searchName": "BDK-Bondoukou-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "ABO",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Aboisso",
            "name": "[ABO] - Aboisso - Ivory Coast",
            "searchName": "ABO-Aboisso-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "EML",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Luzern",
            "name": "[EML] - Luzern - Switzerland",
            "searchName": "EML-Emmen-Luzern-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "EAP",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Mulhouse",
            "name": "[EAP] - Mulhouse - Switzerland",
            "searchName": "EAP-Basel/Mulhouse-Mulhouse-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ACH",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Altenrhein",
            "name": "[ACH] - Altenrhein - Switzerland",
            "searchName": "ACH-Altenrhein-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "BXO",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Buochs",
            "name": "[BXO] - Buochs - Switzerland",
            "searchName": "BXO-Buochs-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "YMR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Merry Island",
            "name": "[YMR] - Merry Island - Canada",
            "searchName": "YMR-Merry Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMT",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Chibougamau",
            "name": "[YMT] - Chibougamau - Canada",
            "searchName": "YMT-Chibougamau-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMU",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Mansons Landing",
            "name": "[YMU] - Mansons Landing - Canada",
            "searchName": "YMU-Mansons Landing-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMV",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Mary River",
            "name": "[YMV] - Mary River - Canada",
            "searchName": "YMV-Mary River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMW",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Maniwaki",
            "name": "[YMW] - Maniwaki - Canada",
            "searchName": "YMW-Maniwaki-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Natashquan",
            "name": "[YNA] - Natashquan - Canada",
            "searchName": "YNA-Natashquan-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Wemindji",
            "name": "[YNC] - Wemindji - Canada",
            "searchName": "YNC-Wemindji-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Norway House",
            "name": "[YNE] - Norway House - Canada",
            "searchName": "YNE-Norway House-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Corner Brook",
            "name": "[YNF] - Corner Brook - Canada",
            "searchName": "YNF-Corner Brook-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Bonaventure",
            "name": "[YVB] - Bonaventure - Canada",
            "searchName": "YVB-Bonaventure-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "La Ronge",
            "name": "[YVC] - La Ronge - Canada",
            "searchName": "YVC-La Ronge-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YUT",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Repulse Bay",
            "name": "[YUT] - Repulse Bay - Canada",
            "searchName": "YUT-Repulse Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YUX",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Hall Beach",
            "name": "[YUX] - Hall Beach - Canada",
            "searchName": "YUX-Hall Beach-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YUY",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Rouyn-Noranda",
            "name": "[YUY] - Rouyn-Noranda - Canada",
            "searchName": "YUY-Rouyn-Noranda-Noranda\\/Rouyn-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNX",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Snap Lake",
            "name": "[YNX] - Snap Lake - Canada",
            "searchName": "YNX-Snap Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Rainbow Lake",
            "name": "[YOP] - Rainbow Lake - Canada",
            "searchName": "YOP-Rainbow Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Owen Sound",
            "name": "[YOS] - Owen Sound - Canada",
            "searchName": "YOS-Owen Sound-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YUB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Tuktoyaktuk",
            "name": "[YUB] - Tuktoyaktuk - Canada",
            "searchName": "YUB-Tuktoyaktuk-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YUD",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Umiujaq",
            "name": "[YUD] - Umiujaq - Canada",
            "searchName": "YUD-Umiujaq-Umiujag-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZZ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Trail",
            "name": "[YZZ] - Trail - Canada",
            "searchName": "YZZ-Trail-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZAA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Alice Arm",
            "name": "[ZAA] - Alice Arm - Canada",
            "searchName": "ZAA-Alice Arm-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZAC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "York Landing",
            "name": "[ZAC] - York Landing - Canada",
            "searchName": "ZAC-York Landing-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Vernon",
            "name": "[YVE] - Vernon - Canada",
            "searchName": "YVE-Vernon-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Vermilion",
            "name": "[YVG] - Vermilion - Canada",
            "searchName": "YVG-Vermilion-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Qikiqtarjuaq",
            "name": "[YVM] - Qikiqtarjuaq - Canada",
            "searchName": "YVM-Qikiqtarjuaq-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVN",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Cape Dyer",
            "name": "[YVN] - Cape Dyer - Canada",
            "searchName": "YVN-Cape Dyer-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Val-d or",
            "name": "[YVO] - Val-d or - Canada",
            "searchName": "YVO-Val d Or-Val-d or-d Or-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kuujjuaq",
            "name": "[YVP] - Kuujjuaq - Canada",
            "searchName": "YVP-Kuujjuaq-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Norman Wells",
            "name": "[YVQ] - Norman Wells - Canada",
            "searchName": "YVQ-Norman Wells-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVT",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Buffalo Narrows",
            "name": "[YVT] - Buffalo Narrows - Canada",
            "searchName": "YVT-Buffalo Narrows-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVV",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Wiarton",
            "name": "[YVV] - Wiarton - Canada",
            "searchName": "YVV-Wiarton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVZ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Deer Lake",
            "name": "[YVZ] - Deer Lake - Canada",
            "searchName": "YVZ-Deer Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Petawawa",
            "name": "[YWA] - Petawawa - Canada",
            "searchName": "YWA-Petawawa-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kangiqsujuaq",
            "name": "[YWB] - Kangiqsujuaq - Canada",
            "searchName": "YWB-Kangiqsujuaq-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Winnipeg",
            "name": "[YWG] - Winnipeg - Canada",
            "searchName": "YWG-Winnipeg-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWJ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Deline",
            "name": "[YWJ] - Deline - Canada",
            "searchName": "YWJ-Deline-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Wabush",
            "name": "[YWK] - Wabush - Canada",
            "searchName": "YWK-Wabush-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Williams Lake",
            "name": "[YWL] - Williams Lake - Canada",
            "searchName": "YWL-Williams Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Williams Harbour",
            "name": "[YWM] - Williams Harbour - Canada",
            "searchName": "YWM-Williams Harbour-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWN",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Winisk",
            "name": "[YWN] - Winisk - Canada",
            "searchName": "YWN-Winisk-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Lupin",
            "name": "[YWO] - Lupin - Canada",
            "searchName": "YWO-Lupin-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Webequie",
            "name": "[YWP] - Webequie - Canada",
            "searchName": "YWP-Webequie-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Chute-Des-Passes",
            "name": "[YWQ] - Chute-Des-Passes - Canada",
            "searchName": "YWQ-Chute des Passes-Chute-Des-Passes-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "White River",
            "name": "[YWR] - White River - Canada",
            "searchName": "YWR-White River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Whistler",
            "name": "[YWS] - Whistler - Canada",
            "searchName": "YWS-Whistler-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWY",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Wrigley",
            "name": "[YWY] - Wrigley - Canada",
            "searchName": "YWY-Wrigley-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Cranbrook",
            "name": "[YXC] - Cranbrook - Canada",
            "searchName": "YXC-Cranbrook-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Saskatoon",
            "name": "[YXE] - Saskatoon - Canada",
            "searchName": "YXE-Saskatoon-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Snake River",
            "name": "[YXF] - Snake River - Canada",
            "searchName": "YXF-Snake River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXH",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Medicine Hat",
            "name": "[YXH] - Medicine Hat - Canada",
            "searchName": "YXH-Medicine Hat-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXI",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Killaloe",
            "name": "[YXI] - Killaloe - Canada",
            "searchName": "YXI-Killaloe-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXJ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fort St John",
            "name": "[YXJ] - Fort St John - Canada",
            "searchName": "YXJ-Fort St John-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Rimouski",
            "name": "[YXK] - Rimouski - Canada",
            "searchName": "YXK-Rimouski-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Sioux Lookout",
            "name": "[YXL] - Sioux Lookout - Canada",
            "searchName": "YXL-Sioux Lookout-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXN",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Whale Cove",
            "name": "[YXN] - Whale Cove - Canada",
            "searchName": "YXN-Whale Cove-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Pangnirtung",
            "name": "[YXP] - Pangnirtung - Canada",
            "searchName": "YXP-Pangnirtung-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Beaver Creek",
            "name": "[YXQ] - Beaver Creek - Canada",
            "searchName": "YXQ-Beaver Creek-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Earlton",
            "name": "[YXR] - Earlton - Canada",
            "searchName": "YXR-Earlton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Prince George",
            "name": "[YXS] - Prince George - Canada",
            "searchName": "YXS-Prince George-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXT",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Terrace",
            "name": "[YXT] - Terrace - Canada",
            "searchName": "YXT-Terrace-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXX",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Abbotsford",
            "name": "[YXX] - Abbotsford - Canada",
            "searchName": "YXX-Abbotsford-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXY",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Whitehorse",
            "name": "[YXY] - Whitehorse - Canada",
            "searchName": "YXY-Whitehorse-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXZ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Wawa",
            "name": "[YXZ] - Wawa - Canada",
            "searchName": "YXZ-Wawa-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Big Bay Yacht Club",
            "name": "[YYA] - Big Bay Yacht Club - Canada",
            "searchName": "YYA-Big Bay Yacht Club-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "North Bay",
            "name": "[YYB] - North Bay - Canada",
            "searchName": "YYB-North Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Calgary",
            "name": "[YYC] - Calgary - Canada",
            "searchName": "YYC-Calgary-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYD",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Smithers",
            "name": "[YYD] - Smithers - Canada",
            "searchName": "YYD-Smithers-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fort Nelson",
            "name": "[YYE] - Fort Nelson - Canada",
            "searchName": "YYE-Fort Nelson-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Penticton",
            "name": "[YYF] - Penticton - Canada",
            "searchName": "YYF-Penticton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Charlottetown",
            "name": "[YYG] - Charlottetown - Canada",
            "searchName": "YYG-Charlottetown-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYH",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Taloyoak",
            "name": "[YYH] - Taloyoak - Canada",
            "searchName": "YYH-Taloyoak-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYI",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Rivers",
            "name": "[YYI] - Rivers - Canada",
            "searchName": "YYI-Rivers-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYJ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Victoria",
            "name": "[YYJ] - Victoria - Canada",
            "searchName": "YYJ-Victoria-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Lynn Lake",
            "name": "[YYL] - Lynn Lake - Canada",
            "searchName": "YYL-Lynn Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Cowley",
            "name": "[YYM] - Cowley - Canada",
            "searchName": "YYM-Cowley-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYN",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Swift Current",
            "name": "[YYN] - Swift Current - Canada",
            "searchName": "YYN-Swift Current-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Churchill",
            "name": "[YYQ] - Churchill - Canada",
            "searchName": "YYQ-Churchill-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Happy Valley-Goose Bay",
            "name": "[YYR] - Happy Valley-Goose Bay - Canada",
            "searchName": "YYR-Goose Bay-Happy Valley-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYT",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "St. John s",
            "name": "[YYT] - St. John s - Canada",
            "searchName": "YYT-Saint Johns-St. John s-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYU",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kapuskasing",
            "name": "[YYU] - Kapuskasing - Canada",
            "searchName": "YYU-Kapuskasing-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYW",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Armstrong",
            "name": "[YYW] - Armstrong - Canada",
            "searchName": "YYW-Armstrong-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYY",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Mont Joli",
            "name": "[YYY] - Mont Joli - Canada",
            "searchName": "YYY-Mont Joli-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Ashcroft",
            "name": "[YZA] - Ashcroft - Canada",
            "searchName": "YZA-Ashcroft-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Beatton River",
            "name": "[YZC] - Beatton River - Canada",
            "searchName": "YZC-Beatton River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Gore Bay",
            "name": "[YZE] - Gore Bay - Canada",
            "searchName": "YZE-Gore Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Yellowknife",
            "name": "[YZF] - Yellowknife - Canada",
            "searchName": "YZF-Yellowknife-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZH",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Slave Lake",
            "name": "[YZH] - Slave Lake - Canada",
            "searchName": "YZH-Slave Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Buchans",
            "name": "[YZM] - Buchans - Canada",
            "searchName": "YZM-Buchans NF-Buchans-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Sandspit",
            "name": "[YZP] - Sandspit - Canada",
            "searchName": "YZP-Sandspit-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Sarnia",
            "name": "[YZR] - Sarnia - Canada",
            "searchName": "YZR-Sarnia-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Coral Harbour",
            "name": "[YZS] - Coral Harbour - Canada",
            "searchName": "YZS-Coral Harbour-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZT",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Port Hardy",
            "name": "[YZT] - Port Hardy - Canada",
            "searchName": "YZT-Port Hardy-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZU",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Whitecourt",
            "name": "[YZU] - Whitecourt - Canada",
            "searchName": "YZU-Whitecourt-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZV",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Sept-Iles",
            "name": "[YZV] - Sept-Iles - Canada",
            "searchName": "YZV-Sept-Iles-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZW",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Teslin",
            "name": "[YZW] - Teslin - Canada",
            "searchName": "YZW-Teslin-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZX",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Greenwood",
            "name": "[YZX] - Greenwood - Canada",
            "searchName": "YZX-Greenwood-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "PIW",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Pikwitonei",
            "name": "[PIW] - Pikwitonei - Canada",
            "searchName": "PIW-Pikwitonei-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "QBC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Bella Coola",
            "name": "[QBC] - Bella Coola - Canada",
            "searchName": "QBC-Bella Coola-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "QWA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Oshawa",
            "name": "[QWA] - Oshawa - Canada",
            "searchName": "QWA-Oshawa-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "NWP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Argentia",
            "name": "[NWP] - Argentia - Canada",
            "searchName": "NWP-Argentia-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "NML",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Mildred Lake",
            "name": "[NML] - Mildred Lake - Canada",
            "searchName": "NML-Mildred Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "MSA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Muskrat Dam",
            "name": "[MSA] - Muskrat Dam - Canada",
            "searchName": "MSA-Muskrat Dam-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "CCK",
            "countryCode": "CC",
            "cityCode": null,
            "cityName": "Cocos Islands",
            "name": "[CCK] - Cocos Islands - Cocos Islands",
            "searchName": "CCK-Cocos Islands-Cocos (Keeling) Islands-Islas Cocos-Îles Cocos-Isole Cocos",
            "priority": 0
        },
        {
            "iataCode": "BZU",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Buta",
            "name": "[BZU] - Buta - Democratic Republic of the Congo",
            "searchName": "BZU-Buta-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "BSU",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Basankusu",
            "name": "[BSU] - Basankusu - Democratic Republic of the Congo",
            "searchName": "BSU-Basankusu-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "BUX",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Bunia",
            "name": "[BUX] - Bunia - Democratic Republic of the Congo",
            "searchName": "BUX-Bunia-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "BDV",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Moba",
            "name": "[BDV] - Moba - Democratic Republic of the Congo",
            "searchName": "BDV-Moba-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "BDT",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Gbadolite",
            "name": "[BDT] - Gbadolite - Democratic Republic of the Congo",
            "searchName": "BDT-Gbadolite-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "BMB",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Bumba",
            "name": "[BMB] - Bumba - Democratic Republic of the Congo",
            "searchName": "BMB-Bumba-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "BKY",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Bukavu",
            "name": "[BKY] - Bukavu - Democratic Republic of the Congo",
            "searchName": "BKY-Bukavu-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "BNB",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Boende",
            "name": "[BNB] - Boende - Democratic Republic of the Congo",
            "searchName": "BNB-Boende-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "BNC",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Beni",
            "name": "[BNC] - Beni - Democratic Republic of the Congo",
            "searchName": "BNC-Beni-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "BOA",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Boma",
            "name": "[BOA] - Boma - Democratic Republic of the Congo",
            "searchName": "BOA-Boma-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "BAN",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Basongo",
            "name": "[BAN] - Basongo - Democratic Republic of the Congo",
            "searchName": "BAN-Basongo-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "FMI",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Kalemie",
            "name": "[FMI] - Kalemie - Democratic Republic of the Congo",
            "searchName": "FMI-Kalemie-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "FDU",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Bandundu",
            "name": "[FDU] - Bandundu - Democratic Republic of the Congo",
            "searchName": "FDU-Bandundu-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "FBM",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Lubumbashi",
            "name": "[FBM] - Lubumbashi - Democratic Republic of the Congo",
            "searchName": "FBM-Lubumbashi-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "FKI",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Kisangani",
            "name": "[FKI] - Kisangani - Democratic Republic of the Congo",
            "searchName": "FKI-Kisangani-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "FIH",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Kinshasa",
            "name": "[FIH] - Kinshasa - Democratic Republic of the Congo",
            "searchName": "FIH-Kinshasa-Kinsasa-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "GMA",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Gemena",
            "name": "[GMA] - Gemena - Democratic Republic of the Congo",
            "searchName": "GMA-Gemena-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "GOM",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Goma",
            "name": "[GOM] - Goma - Democratic Republic of the Congo",
            "searchName": "GOM-Goma-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "GDJ",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Gandajika",
            "name": "[GDJ] - Gandajika - Democratic Republic of the Congo",
            "searchName": "GDJ-Gandajika-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "IRP",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Isiro",
            "name": "[IRP] - Isiro - Democratic Republic of the Congo",
            "searchName": "IRP-Isiro-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KAP",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Kapanga",
            "name": "[KAP] - Kapanga - Democratic Republic of the Congo",
            "searchName": "KAP-Kapanga-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "IKL",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Ikela",
            "name": "[IKL] - Ikela - Democratic Republic of the Congo",
            "searchName": "IKL-Ikela-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "IDF",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Idiofa",
            "name": "[IDF] - Idiofa - Democratic Republic of the Congo",
            "searchName": "IDF-Idiofa-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "INO",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Inongo",
            "name": "[INO] - Inongo - Democratic Republic of the Congo",
            "searchName": "INO-Inongo-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KWZ",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Kolwezi",
            "name": "[KWZ] - Kolwezi - Democratic Republic of the Congo",
            "searchName": "KWZ-Kolwezi-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "LBO",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Lusambo",
            "name": "[LBO] - Lusambo - Democratic Republic of the Congo",
            "searchName": "LBO-Lusambo-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KRZ",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Kiri",
            "name": "[KRZ] - Kiri - Democratic Republic of the Congo",
            "searchName": "KRZ-Kiri-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KNM",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Kaniama",
            "name": "[KNM] - Kaniama - Democratic Republic of the Congo",
            "searchName": "KNM-Kaniama-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KOO",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Kongolo",
            "name": "[KOO] - Kongolo - Democratic Republic of the Congo",
            "searchName": "KOO-Kongolo-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KND",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Kindu",
            "name": "[KND] - Kindu - Democratic Republic of the Congo",
            "searchName": "KND-Kindu-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KMN",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Kamina",
            "name": "[KMN] - Kamina - Democratic Republic of the Congo",
            "searchName": "KMN-Kamina-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KIL",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Kilwa",
            "name": "[KIL] - Kilwa - Democratic Republic of the Congo",
            "searchName": "KIL-Kilwa-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KKW",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Kikwit",
            "name": "[KKW] - Kikwit - Democratic Republic of the Congo",
            "searchName": "KKW-Kikwit-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KLY",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Kalima",
            "name": "[KLY] - Kalima - Democratic Republic of the Congo",
            "searchName": "KLY-Kalima-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KLI",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Kota Koli",
            "name": "[KLI] - Kota Koli - Democratic Republic of the Congo",
            "searchName": "KLI-Kotakoli-Kota Koli-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KEC",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Kasenga",
            "name": "[KEC] - Kasenga - Democratic Republic of the Congo",
            "searchName": "KEC-Kasenga-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KGA",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Kananga",
            "name": "[KGA] - Kananga - Democratic Republic of the Congo",
            "searchName": "KGA-Kananga-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KGN",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Kasongo Lunda",
            "name": "[KGN] - Kasongo Lunda - Democratic Republic of the Congo",
            "searchName": "KGN-Kasongo Lunda-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KBN",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Kabinda",
            "name": "[KBN] - Kabinda - Democratic Republic of the Congo",
            "searchName": "KBN-Kabinda-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KBO",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Kabalo",
            "name": "[KBO] - Kabalo - Democratic Republic of the Congo",
            "searchName": "KBO-Kabalo-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "LUS",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Lusanga",
            "name": "[LUS] - Lusanga - Democratic Republic of the Congo",
            "searchName": "LUS-Lusanga-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "LIQ",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Lisala",
            "name": "[LIQ] - Lisala - Democratic Republic of the Congo",
            "searchName": "LIQ-Lisala-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "LIE",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Libenge",
            "name": "[LIE] - Libenge - Democratic Republic of the Congo",
            "searchName": "LIE-Libenge-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "LJA",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Lodja",
            "name": "[LJA] - Lodja - Democratic Republic of the Congo",
            "searchName": "LJA-Lodja-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "MDK",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Mbandaka",
            "name": "[MDK] - Mbandaka - Democratic Republic of the Congo",
            "searchName": "MDK-Mbandaka-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "MAT",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Matadi",
            "name": "[MAT] - Matadi - Democratic Republic of the Congo",
            "searchName": "MAT-Matadi-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "LZI",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Luozi",
            "name": "[LZI] - Luozi - Democratic Republic of the Congo",
            "searchName": "LZI-Luozi-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "LZA",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Luiza",
            "name": "[LZA] - Luiza - Democratic Republic of the Congo",
            "searchName": "LZA-Luiza-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "MJM",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Mbuji-Mayi",
            "name": "[MJM] - Mbuji-Mayi - Democratic Republic of the Congo",
            "searchName": "MJM-Mbuji-Mayi-Mbujimayi-Mbuji Mayi-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "MEW",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Mweka",
            "name": "[MEW] - Mweka - Democratic Republic of the Congo",
            "searchName": "MEW-Mweka-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "MSM",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Masi Manimba",
            "name": "[MSM] - Masi Manimba - Democratic Republic of the Congo",
            "searchName": "MSM-Masi Manimba-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "MNO",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Manono",
            "name": "[MNO] - Manono - Democratic Republic of the Congo",
            "searchName": "MNO-Manono-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "MNB",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Moanda",
            "name": "[MNB] - Moanda - Democratic Republic of the Congo",
            "searchName": "MNB-Moanda-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "NKL",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Nkolo",
            "name": "[NKL] - Nkolo - Democratic Republic of the Congo",
            "searchName": "NKL-Nkolo-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "NIO",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Nioki",
            "name": "[NIO] - Nioki - Democratic Republic of the Congo",
            "searchName": "NIO-Nioki-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "PFR",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Ilebo",
            "name": "[PFR] - Ilebo - Democratic Republic of the Congo",
            "searchName": "PFR-Ilebo-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "PWO",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Pweto",
            "name": "[PWO] - Pweto - Democratic Republic of the Congo",
            "searchName": "PWO-Pweto-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "PUN",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Punia",
            "name": "[PUN] - Punia - Democratic Republic of the Congo",
            "searchName": "PUN-Punia-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "RUE",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Butembo",
            "name": "[RUE] - Butembo - Democratic Republic of the Congo",
            "searchName": "RUE-Butembo-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "YAN",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Yangambi",
            "name": "[YAN] - Yangambi - Democratic Republic of the Congo",
            "searchName": "YAN-Yangambi-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "TSH",
            "countryCode": "CD",
            "cityCode": null,
            "cityName": "Tshikapa",
            "name": "[TSH] - Tshikapa - Democratic Republic of the Congo",
            "searchName": "TSH-Tshikapa-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "RFA",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Rafai",
            "name": "[RFA] - Rafai - Central African Republic",
            "searchName": "RFA-Rafai-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "ODA",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Ouadda",
            "name": "[ODA] - Ouadda - Central African Republic",
            "searchName": "ODA-Ouadda-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "ODJ",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Ouanda Djalle",
            "name": "[ODJ] - Ouanda Djalle - Central African Republic",
            "searchName": "ODJ-Ouanda Djalle-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "NDL",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Ndele",
            "name": "[NDL] - Ndele - Central African Republic",
            "searchName": "NDL-Ndele-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "MKI",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Obo",
            "name": "[MKI] - Obo - Central African Republic",
            "searchName": "MKI-Obo-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "KOL",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Koumala",
            "name": "[KOL] - Koumala - Central African Republic",
            "searchName": "KOL-Koumala-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "KWD",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Kawadjia",
            "name": "[KWD] - Kawadjia - Central African Republic",
            "searchName": "KWD-Kawadjia-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "IMO",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Zemio",
            "name": "[IMO] - Zemio - Central African Republic",
            "searchName": "IMO-Zemio-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "IRO",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Birao",
            "name": "[IRO] - Birao - Central African Republic",
            "searchName": "IRO-Birao-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "GDA",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Gounda",
            "name": "[GDA] - Gounda - Central African Republic",
            "searchName": "GDA-Gounda-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "GDI",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Gordil",
            "name": "[GDI] - Gordil - Central African Republic",
            "searchName": "GDI-Gordil-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "CRF",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Carnot",
            "name": "[CRF] - Carnot - Central African Republic",
            "searchName": "CRF-Carnot-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BBT",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Berberati",
            "name": "[BBT] - Berberati - Central African Republic",
            "searchName": "BBT-Berberati-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BCF",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Bouca",
            "name": "[BCF] - Bouca - Central African Republic",
            "searchName": "BCF-Bouca-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BBY",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Bambari",
            "name": "[BBY] - Bambari - Central African Republic",
            "searchName": "BBY-Bambari-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "AIG",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Yalinga",
            "name": "[AIG] - Yalinga - Central African Republic",
            "searchName": "AIG-Yalinga-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BOP",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Bouar",
            "name": "[BOP] - Bouar - Central African Republic",
            "searchName": "BOP-Bouar-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BOZ",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Bozoum",
            "name": "[BOZ] - Bozoum - Central African Republic",
            "searchName": "BOZ-Bozoum-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BMF",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Bakouma",
            "name": "[BMF] - Bakouma - Central African Republic",
            "searchName": "BMF-Bakouma-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BGF",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Bangui",
            "name": "[BGF] - Bangui - Central African Republic",
            "searchName": "BGF-Bangui-Banqui-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BIV",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Bria",
            "name": "[BIV] - Bria - Central African Republic",
            "searchName": "BIV-Bria-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BGU",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Bangassou",
            "name": "[BGU] - Bangassou - Central African Republic",
            "searchName": "BGU-Bangassou-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BTG",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Batangafo",
            "name": "[BTG] - Batangafo - Central African Republic",
            "searchName": "BTG-Batangafo-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BSN",
            "countryCode": "CF",
            "cityCode": null,
            "cityName": "Bossangoa",
            "name": "[BSN] - Bossangoa - Central African Republic",
            "searchName": "BSN-Bossangoa-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BTB",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Betou",
            "name": "[BTB] - Betou - Republic of the Congo",
            "searchName": "BTB-Betou-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "BZV",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Brazzaville",
            "name": "[BZV] - Brazzaville - Republic of the Congo",
            "searchName": "BZV-Brazzaville-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "BOE",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Boundji",
            "name": "[BOE] - Boundji - Republic of the Congo",
            "searchName": "BOE-Boundji-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "ANJ",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Zanaga",
            "name": "[ANJ] - Zanaga - Republic of the Congo",
            "searchName": "ANJ-Zanaga-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "DIS",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Loubomo",
            "name": "[DIS] - Loubomo - Republic of the Congo",
            "searchName": "DIS-Loubomo-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "EPN",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Epena",
            "name": "[EPN] - Epena - Republic of the Congo",
            "searchName": "EPN-Epena-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "EWO",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Ewo",
            "name": "[EWO] - Ewo - Republic of the Congo",
            "searchName": "EWO-Ewo-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "DJM",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Djambala",
            "name": "[DJM] - Djambala - Republic of the Congo",
            "searchName": "DJM-Djambala-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "FTX",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Owando",
            "name": "[FTX] - Owando - Republic of the Congo",
            "searchName": "FTX-Owando-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "GMM",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Gamboma",
            "name": "[GMM] - Gamboma - Republic of the Congo",
            "searchName": "GMM-Gamboma-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "ION",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Impfondo",
            "name": "[ION] - Impfondo - Republic of the Congo",
            "searchName": "ION-Impfondo-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "LCO",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Lague",
            "name": "[LCO] - Lague - Republic of the Congo",
            "searchName": "LCO-Lague-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "KMK",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Makabana",
            "name": "[KMK] - Makabana - Republic of the Congo",
            "searchName": "KMK-Makabana-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "KNJ",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Kindamba",
            "name": "[KNJ] - Kindamba - Republic of the Congo",
            "searchName": "KNJ-Kindamba-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "KEE",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Kelle",
            "name": "[KEE] - Kelle - Republic of the Congo",
            "searchName": "KEE-Kelle-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "LKC",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Lekana",
            "name": "[LKC] - Lekana - Republic of the Congo",
            "searchName": "LKC-Lekana-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "MKJ",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Makoua",
            "name": "[MKJ] - Makoua - Republic of the Congo",
            "searchName": "MKJ-Makoua-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "MSX",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Mossendjo",
            "name": "[MSX] - Mossendjo - Republic of the Congo",
            "searchName": "MSX-Mossendjo-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "MUY",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Mouyondzi",
            "name": "[MUY] - Mouyondzi - Republic of the Congo",
            "searchName": "MUY-Mouyondzi-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "NKY",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Nkayi",
            "name": "[NKY] - Nkayi - Republic of the Congo",
            "searchName": "NKY-Nkayi-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "OKG",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Okoyo",
            "name": "[OKG] - Okoyo - Republic of the Congo",
            "searchName": "OKG-Okoyo-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "OLL",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Oyo",
            "name": "[OLL] - Oyo - Republic of the Congo",
            "searchName": "OLL-Oyo-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "OUE",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Ouesso",
            "name": "[OUE] - Ouesso - Republic of the Congo",
            "searchName": "OUE-Ouesso-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "PNR",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Pointe-Noire",
            "name": "[PNR] - Pointe-Noire - Republic of the Congo",
            "searchName": "PNR-Pointe-Noire-Pointe Noire-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "SIB",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Sibiti",
            "name": "[SIB] - Sibiti - Republic of the Congo",
            "searchName": "SIB-Sibiti-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "SOE",
            "countryCode": "CG",
            "cityCode": null,
            "cityName": "Souanke",
            "name": "[SOE] - Souanke - Republic of the Congo",
            "searchName": "SOE-Souanke-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "SMV",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "St Moritz",
            "name": "[SMV] - St Moritz - Switzerland",
            "searchName": "SMV-St Moritz-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "SIR",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Sion",
            "name": "[SIR] - Sion - Switzerland",
            "searchName": "SIR-Sion-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "QZB",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Zermatt",
            "name": "[QZB] - Zermatt - Switzerland",
            "searchName": "QZB-Zermatt-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "QEQ",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Embrach/Rorbas",
            "name": "[QEQ] - Embrach/Rorbas - Switzerland",
            "searchName": "QEQ-Embrach/Rorbas-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "QNC",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Neuchatel",
            "name": "[QNC] - Neuchatel - Switzerland",
            "searchName": "QNC-Neuchatel-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "QLJ",
            "countryCode": "CH",
            "cityCode": null,
            "cityName": "Lucerne",
            "name": "[QLJ] - Lucerne - Switzerland",
            "searchName": "QLJ-Lucerne-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "YQC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Quaqtaq",
            "name": "[YQC] - Quaqtaq - Canada",
            "searchName": "YQC-Quaqtaq-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQD",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "The Pas",
            "name": "[YQD] - The Pas - Canada",
            "searchName": "YQD-The Pas-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kimberley",
            "name": "[YQE] - Kimberley - Canada",
            "searchName": "YQE-Kimberley-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Red Deer",
            "name": "[YQF] - Red Deer - Canada",
            "searchName": "YQF-Red Deer-Calgary-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Windsor",
            "name": "[YQG] - Windsor - Canada",
            "searchName": "YQG-Windsor-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQH",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Watson Lake",
            "name": "[YQH] - Watson Lake - Canada",
            "searchName": "YQH-Watson Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQI",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Yarmouth",
            "name": "[YQI] - Yarmouth - Canada",
            "searchName": "YQI-Yarmouth-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQJ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Quadra Island",
            "name": "[YQJ] - Quadra Island - Canada",
            "searchName": "YQJ-Quadra Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kenora",
            "name": "[YQK] - Kenora - Canada",
            "searchName": "YQK-Kenora-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Lethbridge",
            "name": "[YQL] - Lethbridge - Canada",
            "searchName": "YQL-Lethbridge-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Moncton",
            "name": "[YQM] - Moncton - Canada",
            "searchName": "YQM-Moncton New Brunswick-Moncton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQN",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Nakina",
            "name": "[YQN] - Nakina - Canada",
            "searchName": "YQN-Nakina-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Comox",
            "name": "[YQQ] - Comox - Canada",
            "searchName": "YQQ-Comox-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Regina",
            "name": "[YQR] - Regina - Canada",
            "searchName": "YQR-Regina-R-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "St Thomas",
            "name": "[YQS] - St Thomas - Canada",
            "searchName": "YQS-St. Thomas-St Thomas-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQT",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Thunder Bay",
            "name": "[YQT] - Thunder Bay - Canada",
            "searchName": "YQT-Thunder Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQU",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Grande Prairie",
            "name": "[YQU] - Grande Prairie - Canada",
            "searchName": "YQU-Grande Prairie-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQV",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Yorkton",
            "name": "[YQV] - Yorkton - Canada",
            "searchName": "YQV-Yorkton-Yoronjima-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQW",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "North Battleford",
            "name": "[YQW] - North Battleford - Canada",
            "searchName": "YQW-North Battleford-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQX",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Gander",
            "name": "[YQX] - Gander - Canada",
            "searchName": "YQX-Gander-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQY",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Sydney",
            "name": "[YQY] - Sydney - Canada",
            "searchName": "YQY-Cape Breton Island-Sydney-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQZ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Quesnel",
            "name": "[YQZ] - Quesnel - Canada",
            "searchName": "YQZ-Quesnel-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Rae Lakes",
            "name": "[YRA] - Rae Lakes - Canada",
            "searchName": "YRA-Rae Lakes-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Resolute",
            "name": "[YRB] - Resolute - Canada",
            "searchName": "YRB-Resolute-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Refuge Cove",
            "name": "[YRC] - Refuge Cove - Canada",
            "searchName": "YRC-Refuge Cove-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRD",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Dean River",
            "name": "[YRD] - Dean River - Canada",
            "searchName": "YRD-Dean River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Resolution Island",
            "name": "[YRE] - Resolution Island - Canada",
            "searchName": "YRE-Resolution Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Cartwright",
            "name": "[YRF] - Cartwright - Canada",
            "searchName": "YRF-Cartwright-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Rigolet",
            "name": "[YRG] - Rigolet - Canada",
            "searchName": "YRG-Rigolet-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRI",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Riviere Du Loup",
            "name": "[YRI] - Riviere Du Loup - Canada",
            "searchName": "YRI-Riviere Du Loup-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRJ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Roberval",
            "name": "[YRJ] - Roberval - Canada",
            "searchName": "YRJ-Roberval-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Red Lake",
            "name": "[YRL] - Red Lake - Canada",
            "searchName": "YRL-Red Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Rocky Mountain House",
            "name": "[YRM] - Rocky Mountain House - Canada",
            "searchName": "YRM-Rocky Mountain House-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRN",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Owekeno Village",
            "name": "[YRN] - Owekeno Village - Canada",
            "searchName": "YRN-Owekeno Village-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Trois-Rivieres",
            "name": "[YRQ] - Trois-Rivieres - Canada",
            "searchName": "YRQ-Trois-Rivieres-Trois Rivieres-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Stuart Island",
            "name": "[YRR] - Stuart Island - Canada",
            "searchName": "YRR-Stuart Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Red Sucker Lake",
            "name": "[YRS] - Red Sucker Lake - Canada",
            "searchName": "YRS-Red Sucker Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRT",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Rankin Inlet",
            "name": "[YRT] - Rankin Inlet - Canada",
            "searchName": "YRT-Rankin Inlet-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRV",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Revelstoke",
            "name": "[YRV] - Revelstoke - Canada",
            "searchName": "YRV-Revelstoke-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Sable Island",
            "name": "[YSA] - Sable Island - Canada",
            "searchName": "YSA-Sable Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Sudbury",
            "name": "[YSB] - Sudbury - Canada",
            "searchName": "YSB-Sudbury-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Sherbrooke",
            "name": "[YSC] - Sherbrooke - Canada",
            "searchName": "YSC-Sherbrooke-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSD",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Suffield",
            "name": "[YSD] - Suffield - Canada",
            "searchName": "YSD-Suffield-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Squamish",
            "name": "[YSE] - Squamish - Canada",
            "searchName": "YSE-Squamish-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Stony Rapids",
            "name": "[YSF] - Stony Rapids - Canada",
            "searchName": "YSF-Stony Rapids-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Lutselke",
            "name": "[YSG] - Lutselke - Canada",
            "searchName": "YSG-Lutselke/Snowdrift-Lutselke-Lutselke\\/Snowdrift-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSH",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Smith Falls",
            "name": "[YSH] - Smith Falls - Canada",
            "searchName": "YSH-Smith Falls-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSI",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Sans Souci",
            "name": "[YSI] - Sans Souci - Canada",
            "searchName": "YSI-Sans Souci-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSJ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Saint John",
            "name": "[YSJ] - Saint John - Canada",
            "searchName": "YSJ-Saint John-Saint-Jean-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Sanikiluaq",
            "name": "[YSK] - Sanikiluaq - Canada",
            "searchName": "YSK-Sanikiluaq-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "St Leonard",
            "name": "[YSL] - St Leonard - Canada",
            "searchName": "YSL-St. Leonard-St Leonard-Saint Leonard-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fort Smith",
            "name": "[YSM] - Fort Smith - Canada",
            "searchName": "YSM-Fort Smith-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSN",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Salmon Arm",
            "name": "[YSN] - Salmon Arm - Canada",
            "searchName": "YSN-Salmon Arm-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Postville",
            "name": "[YSO] - Postville - Canada",
            "searchName": "YSO-Postville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Marathon",
            "name": "[YSP] - Marathon - Canada",
            "searchName": "YSP-Marathon-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Spring Island",
            "name": "[YSQ] - Spring Island - Canada",
            "searchName": "YSQ-Spring Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Nanisivik",
            "name": "[YSR] - Nanisivik - Canada",
            "searchName": "YSR-Nanisivik-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Slate Island",
            "name": "[YSS] - Slate Island - Canada",
            "searchName": "YSS-Slate Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YST",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Ste Therese Point",
            "name": "[YST] - Ste Therese Point - Canada",
            "searchName": "YST-Sainte Therese Point-Ste Therese Point-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSU",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Summerside",
            "name": "[YSU] - Summerside - Canada",
            "searchName": "YSU-Summerside-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSV",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Saglek",
            "name": "[YSV] - Saglek - Canada",
            "searchName": "YSV-Saglek NF-Saglek-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSW",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Salluit",
            "name": "[YSW] - Salluit - Canada",
            "searchName": "YSW-Salluit-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSX",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Shearwater",
            "name": "[YSX] - Shearwater - Canada",
            "searchName": "YSX-Shearwater-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSY",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Sachs Harbour",
            "name": "[YSY] - Sachs Harbour - Canada",
            "searchName": "YSY-Sachs Harbour-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSZ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Squirrel Cove",
            "name": "[YSZ] - Squirrel Cove - Canada",
            "searchName": "YSZ-Squirrel Cove-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Pembroke",
            "name": "[YTA] - Pembroke - Canada",
            "searchName": "YTA-Pembroke-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Hartley Bay",
            "name": "[YTB] - Hartley Bay - Canada",
            "searchName": "YTB-Hartley Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Sturdee",
            "name": "[YTC] - Sturdee - Canada",
            "searchName": "YTC-Sturdee-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTD",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Thicket Portage",
            "name": "[YTD] - Thicket Portage - Canada",
            "searchName": "YTD-Thicket Portage-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Cape Dorset",
            "name": "[YTE] - Cape Dorset - Canada",
            "searchName": "YTE-Cape Dorset-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Alma",
            "name": "[YTF] - Alma - Canada",
            "searchName": "YTF-Alma-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Sullivan Bay",
            "name": "[YTG] - Sullivan Bay - Canada",
            "searchName": "YTG-Sullivan Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTH",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Thompson",
            "name": "[YTH] - Thompson - Canada",
            "searchName": "YTH-Thompson-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTI",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Triple Island",
            "name": "[YTI] - Triple Island - Canada",
            "searchName": "YTI-Triple Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTJ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Terrace Bay",
            "name": "[YTJ] - Terrace Bay - Canada",
            "searchName": "YTJ-Terrace Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Tulugak",
            "name": "[YTK] - Tulugak - Canada",
            "searchName": "YTK-Tulugak-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Big Trout",
            "name": "[YTL] - Big Trout - Canada",
            "searchName": "YTL-Big Trout-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Mont Tremblant",
            "name": "[YTM] - Mont Tremblant - Canada",
            "searchName": "YTM-Mont Tremblant-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTN",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Riviere Au Tonnerre",
            "name": "[YTN] - Riviere Au Tonnerre - Canada",
            "searchName": "YTN-Riviere au Tonnerre-Riviere Au Tonnerre-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOY",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Valcartier",
            "name": "[YOY] - Valcartier - Canada",
            "searchName": "YOY-Valcartier-Valcartier Canada-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Prince Albert",
            "name": "[YPA] - Prince Albert - Canada",
            "searchName": "YPA-Prince Albert-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Port Alberni",
            "name": "[YPB] - Port Alberni - Canada",
            "searchName": "YPB-Port Alberni-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Paulatuk",
            "name": "[YPC] - Paulatuk - Canada",
            "searchName": "YPC-Paulatuk-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPD",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Parry Sound",
            "name": "[YPD] - Parry Sound - Canada",
            "searchName": "YPD-Parry Sound-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Peace River",
            "name": "[YPE] - Peace River - Canada",
            "searchName": "YPE-Peace River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Esquimalt",
            "name": "[YPF] - Esquimalt - Canada",
            "searchName": "YPF-Esquimalt-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Portage La Prairie",
            "name": "[YPG] - Portage La Prairie - Canada",
            "searchName": "YPG-Portage La Prairie-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPH",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Inukjuak",
            "name": "[YPH] - Inukjuak - Canada",
            "searchName": "YPH-Inukjuak-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPI",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Port Simpson",
            "name": "[YPI] - Port Simpson - Canada",
            "searchName": "YPI-Port Simpson-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPJ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Aupaluk",
            "name": "[YPJ] - Aupaluk - Canada",
            "searchName": "YPJ-Aupaluk-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Pickle Lake",
            "name": "[YPL] - Pickle Lake - Canada",
            "searchName": "YPL-Pickle Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Pikangikum",
            "name": "[YPM] - Pikangikum - Canada",
            "searchName": "YPM-Pikangikum-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPN",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Port Menier",
            "name": "[YPN] - Port Menier - Canada",
            "searchName": "YPN-Port Menier-Port Meiner-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Peawanuck",
            "name": "[YPO] - Peawanuck - Canada",
            "searchName": "YPO-Peawanuck-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Pine Point",
            "name": "[YPP] - Pine Point - Canada",
            "searchName": "YPP-Pine Point-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Peterborough",
            "name": "[YPQ] - Peterborough - Canada",
            "searchName": "YPQ-Peterborough-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Prince Rupert",
            "name": "[YPR] - Prince Rupert - Canada",
            "searchName": "YPR-Prince Rupert-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Port Hawkesbury",
            "name": "[YPS] - Port Hawkesbury - Canada",
            "searchName": "YPS-Port Hawkesbury-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPT",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Pender Harbor",
            "name": "[YPT] - Pender Harbor - Canada",
            "searchName": "YPT-Pender Harbor-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPW",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Powell River",
            "name": "[YPW] - Powell River - Canada",
            "searchName": "YPW-Powell River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPX",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Povungnituk",
            "name": "[YPX] - Povungnituk - Canada",
            "searchName": "YPX-Povungnituk-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPY",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fort Chipewyan",
            "name": "[YPY] - Fort Chipewyan - Canada",
            "searchName": "YPY-Fort Chipewyan-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPZ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Burns Lake",
            "name": "[YPZ] - Burns Lake - Canada",
            "searchName": "YPZ-Burns Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Muskoka",
            "name": "[YQA] - Muskoka - Canada",
            "searchName": "YQA-Muskoka-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Tasiujuaq",
            "name": "[YTQ] - Tasiujuaq - Canada",
            "searchName": "YTQ-Tasiujuaq-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Trenton",
            "name": "[YTR] - Trenton - Canada",
            "searchName": "YTR-Trenton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Timmins",
            "name": "[YTS] - Timmins - Canada",
            "searchName": "YTS-Timmins-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTT",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Tisdale",
            "name": "[YTT] - Tisdale - Canada",
            "searchName": "YTT-Tisdale-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTU",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Tasu",
            "name": "[YTU] - Tasu - Canada",
            "searchName": "YTU-Tasu-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTX",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Telegraph Creek",
            "name": "[YTX] - Telegraph Creek - Canada",
            "searchName": "YTX-Telegraph Creek-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "North Spirit Lake",
            "name": "[YNO] - North Spirit Lake - Canada",
            "searchName": "YNO-North Spirit Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Natuashish",
            "name": "[YNP] - Natuashish - Canada",
            "searchName": "YNP-Natuashish-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Arnes",
            "name": "[YNR] - Arnes - Canada",
            "searchName": "YNR-Arnes-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Nemiscau",
            "name": "[YNS] - Nemiscau - Canada",
            "searchName": "YNS-Nemiscau-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNH",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Hudson s  Hope",
            "name": "[YNH] - Hudson s  Hope - Canada",
            "searchName": "YNH-Hudson s  Hope-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNI",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Nitchequon",
            "name": "[YNI] - Nitchequon - Canada",
            "searchName": "YNI-Nitchequon-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Nootka Sound",
            "name": "[YNK] - Nootka Sound - Canada",
            "searchName": "YNK-Nootka Sound-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Points North Landing",
            "name": "[YNL] - Points North Landing - Canada",
            "searchName": "YNL-Points North Landing-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Matagami",
            "name": "[YNM] - Matagami - Canada",
            "searchName": "YNM-Matagami-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Ekati",
            "name": "[YOA] - Ekati - Canada",
            "searchName": "YOA-Ekati-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Old Crow",
            "name": "[YOC] - Old Crow - Canada",
            "searchName": "YOC-Old Crow-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOD",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Cold Lake",
            "name": "[YOD] - Cold Lake - Canada",
            "searchName": "YOD-Cold Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Falher",
            "name": "[YOE] - Falher - Canada",
            "searchName": "YOE-Falher-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Ogoki",
            "name": "[YOG] - Ogoki - Canada",
            "searchName": "YOG-Ogoki-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOH",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Oxford House",
            "name": "[YOH] - Oxford House - Canada",
            "searchName": "YOH-Oxford House-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOI",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Opinaca",
            "name": "[YOI] - Opinaca - Canada",
            "searchName": "YOI-Opinaca-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOJ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "High Level",
            "name": "[YOJ] - High Level - Canada",
            "searchName": "YOJ-High Level-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YML",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Murray Bay",
            "name": "[YML] - Murray Bay - Canada",
            "searchName": "YML-Murray Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fort Mcmurray",
            "name": "[YMM] - Fort Mcmurray - Canada",
            "searchName": "YMM-Fort McMurray-Fort Mcmurray-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMN",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Makkovik",
            "name": "[YMN] - Makkovik - Canada",
            "searchName": "YMN-Makkovik-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Moosonee",
            "name": "[YMO] - Moosonee - Canada",
            "searchName": "YMO-Moosonee-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Port McNeil",
            "name": "[YMP] - Port McNeil - Canada",
            "searchName": "YMP-Port McNeil-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "MSK",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Mastic Point",
            "name": "[MSK] - Mastic Point - Bahamas",
            "searchName": "MSK-Mastic Point-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "PPO",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Powell Point",
            "name": "[PPO] - Powell Point - Bahamas",
            "searchName": "PPO-Powell Point-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "PWN",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Pitts Town",
            "name": "[PWN] - Pitts Town - Bahamas",
            "searchName": "PWN-Pitts Town-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "RCY",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Rum Cay",
            "name": "[RCY] - Rum Cay - Bahamas",
            "searchName": "RCY-Rum Cay-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "SML",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Stella Maris",
            "name": "[SML] - Stella Maris - Bahamas",
            "searchName": "SML-Stella Maris-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "RSD",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Rock Sound",
            "name": "[RSD] - Rock Sound - Bahamas",
            "searchName": "RSD-Rock Sound-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "SAQ",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "San Andros",
            "name": "[SAQ] - San Andros - Bahamas",
            "searchName": "SAQ-San Andros-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "LGI",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Long Island",
            "name": "[LGI] - Long Island - Bahamas",
            "searchName": "LGI-Deadmans Cay-Long Island-Long Island\\/Deadmans Cay-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "MAY",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Mangrove Cay",
            "name": "[MAY] - Mangrove Cay - Bahamas",
            "searchName": "MAY-Mangrove Cay-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "MHH",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Marsh Harbour",
            "name": "[MHH] - Marsh Harbour - Bahamas",
            "searchName": "MHH-Marsh Harbour-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "IGA",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Inagua",
            "name": "[IGA] - Inagua - Bahamas",
            "searchName": "IGA-Inagua-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "GBI",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Grand Bahama",
            "name": "[GBI] - Grand Bahama - Bahamas",
            "searchName": "GBI-Grand Bahama Island-Grand Bahama-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "GGT",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "George Town",
            "name": "[GGT] - George Town - Bahamas",
            "searchName": "GGT-Georgetown-George Town-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "GHB",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Governors Harbour",
            "name": "[GHB] - Governors Harbour - Bahamas",
            "searchName": "GHB-Governors Harbour-Governor s Harbour-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "GHC",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Great Harbour",
            "name": "[GHC] - Great Harbour - Bahamas",
            "searchName": "GHC-Great Harbour-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "GTC",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Green Turtle",
            "name": "[GTC] - Green Turtle - Bahamas",
            "searchName": "GTC-Green Turtle-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "HBI",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Harbour Island",
            "name": "[HBI] - Harbour Island - Bahamas",
            "searchName": "HBI-Harbour Island-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "CRI",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Crooked Island",
            "name": "[CRI] - Crooked Island - Bahamas",
            "searchName": "CRI-Crooked Island-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "CXY",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Cat Cays",
            "name": "[CXY] - Cat Cays - Bahamas",
            "searchName": "CXY-Cat Cay-Cat Cays-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "DCT",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Duncan Town",
            "name": "[DCT] - Duncan Town - Bahamas",
            "searchName": "DCT-Duncan Town-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "COX",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Congo Town",
            "name": "[COX] - Congo Town - Bahamas",
            "searchName": "COX-Congo Town-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "CCZ",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Chub Cay",
            "name": "[CCZ] - Chub Cay - Bahamas",
            "searchName": "CCZ-Chub Cay-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "CEL",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Cape Eleuthera",
            "name": "[CEL] - Cape Eleuthera - Bahamas",
            "searchName": "CEL-Cape Eleuthera-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "ELH",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "North Eleuthera",
            "name": "[ELH] - North Eleuthera - Bahamas",
            "searchName": "ELH-North Eleuthera-Kuzey Eleuthera-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "FPO",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Freeport",
            "name": "[FPO] - Freeport - Bahamas",
            "searchName": "FPO-Freeport-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "AXP",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Spring Point",
            "name": "[AXP] - Spring Point - Bahamas",
            "searchName": "AXP-Spring Point-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "ASD",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Andros Town",
            "name": "[ASD] - Andros Town - Bahamas",
            "searchName": "ASD-Andros Town-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "ATC",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Arthurs Town",
            "name": "[ATC] - Arthurs Town - Bahamas",
            "searchName": "ATC-Arthur s Town-Arthurs Town Airport-Arthurs Town-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "CAT",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Cat Island",
            "name": "[CAT] - Cat Island - Bahamas",
            "searchName": "CAT-Cat Island-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "BIM",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Bimini",
            "name": "[BIM] - Bimini - Bahamas",
            "searchName": "BIM-Bimini-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "PBH",
            "countryCode": "BT",
            "cityCode": null,
            "cityName": "Paro",
            "name": "[PBH] - Paro - Bhutan",
            "searchName": "PBH-Paro-Bhutan-Bhután-Bhútán-Butan-Butanas-Butana-Kraljevina Butan-Bután-Bhoutan-Bu tan",
            "priority": 0
        },
        {
            "iataCode": "ORP",
            "countryCode": "BW",
            "cityCode": null,
            "cityName": "Orapa",
            "name": "[ORP] - Orapa - Botswana",
            "searchName": "ORP-Orapa-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "MUB",
            "countryCode": "BW",
            "cityCode": null,
            "cityName": "Maun",
            "name": "[MUB] - Maun - Botswana",
            "searchName": "MUB-Maun-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "PKW",
            "countryCode": "BW",
            "cityCode": null,
            "cityName": "Selebi-Phikwe",
            "name": "[PKW] - Selebi-Phikwe - Botswana",
            "searchName": "PKW-Selebi-Phikwe-Selebi Phikwe-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "SVT",
            "countryCode": "BW",
            "cityCode": null,
            "cityName": "Savuti",
            "name": "[SVT] - Savuti - Botswana",
            "searchName": "SVT-Savuti-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "TBY",
            "countryCode": "BW",
            "cityCode": null,
            "cityName": "Tsabong",
            "name": "[TBY] - Tsabong - Botswana",
            "searchName": "TBY-Tsabong-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "SWX",
            "countryCode": "BW",
            "cityCode": null,
            "cityName": "Shakawe",
            "name": "[SWX] - Shakawe - Botswana",
            "searchName": "SWX-Shakawe-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "SXN",
            "countryCode": "BW",
            "cityCode": null,
            "cityName": "Suapan",
            "name": "[SXN] - Suapan - Botswana",
            "searchName": "SXN-Suapan-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "TLD",
            "countryCode": "BW",
            "cityCode": null,
            "cityName": "Tuli Block",
            "name": "[TLD] - Tuli Block - Botswana",
            "searchName": "TLD-Tuli Lodge-Tuli Block-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "BBK",
            "countryCode": "BW",
            "cityCode": null,
            "cityName": "Kasane",
            "name": "[BBK] - Kasane - Botswana",
            "searchName": "BBK-Kasane-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "FRW",
            "countryCode": "BW",
            "cityCode": null,
            "cityName": "Francistown",
            "name": "[FRW] - Francistown - Botswana",
            "searchName": "FRW-Francistown-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "GNZ",
            "countryCode": "BW",
            "cityCode": null,
            "cityName": "Ghanzi",
            "name": "[GNZ] - Ghanzi - Botswana",
            "searchName": "GNZ-Ghanzi-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "GBE",
            "countryCode": "BW",
            "cityCode": null,
            "cityName": "Gaborone",
            "name": "[GBE] - Gaborone - Botswana",
            "searchName": "GBE-Gaborone-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "HUK",
            "countryCode": "BW",
            "cityCode": null,
            "cityName": "Hukuntsi",
            "name": "[HUK] - Hukuntsi - Botswana",
            "searchName": "HUK-Hukuntsi-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "JWA",
            "countryCode": "BW",
            "cityCode": null,
            "cityName": "Jwaneng",
            "name": "[JWA] - Jwaneng - Botswana",
            "searchName": "JWA-Jwaneng-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "LOQ",
            "countryCode": "BW",
            "cityCode": null,
            "cityName": "Lobatse",
            "name": "[LOQ] - Lobatse - Botswana",
            "searchName": "LOQ-Lobatse-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "KHW",
            "countryCode": "BW",
            "cityCode": null,
            "cityName": "Khwai River Lodge",
            "name": "[KHW] - Khwai River Lodge - Botswana",
            "searchName": "KHW-Khwai River Lodge-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "GNA",
            "countryCode": "BY",
            "cityCode": null,
            "cityName": "Grodna",
            "name": "[GNA] - Grodna - Belarus",
            "searchName": "GNA-Grodna-Weißrußland-Belarus-Beyaz Rusya-Bélarus-Bielorrusia-Bielorussia",
            "priority": 0
        },
        {
            "iataCode": "GME",
            "countryCode": "BY",
            "cityCode": null,
            "cityName": "Gomel",
            "name": "[GME] - Gomel - Belarus",
            "searchName": "GME-Homel-Gomel-Homiel-Weißrußland-Belarus-Beyaz Rusya-Bélarus-Bielorrusia-Bielorussia",
            "priority": 0
        },
        {
            "iataCode": "BQT",
            "countryCode": "BY",
            "cityCode": null,
            "cityName": "Brest",
            "name": "[BQT] - Brest - Belarus",
            "searchName": "BQT-Brest-Weißrußland-Belarus-Beyaz Rusya-Bélarus-Bielorrusia-Bielorussia",
            "priority": 0
        },
        {
            "iataCode": "VTB",
            "countryCode": "BY",
            "cityCode": null,
            "cityName": "Vitebsk",
            "name": "[VTB] - Vitebsk - Belarus",
            "searchName": "VTB-Vitebsk-Weißrußland-Belarus-Beyaz Rusya-Bélarus-Bielorrusia-Bielorussia",
            "priority": 0
        },
        {
            "iataCode": "MTE",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Monte Alegre",
            "name": "[MTE] - Monte Alegre - Brazil",
            "searchName": "MTE-Monte Alegre-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MTG",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Mato Grosso",
            "name": "[MTG] - Mato Grosso - Brazil",
            "searchName": "MTG-Mato Grosso-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MOC",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Montes Claros",
            "name": "[MOC] - Montes Claros - Brazil",
            "searchName": "MOC-Montes Claros-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MNX",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Manicore",
            "name": "[MNX] - Manicore - Brazil",
            "searchName": "MNX-Manicore-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "NAT",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Natal",
            "name": "[NAT] - Natal - Brazil",
            "searchName": "NAT-Natal-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MVS",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Mucuri",
            "name": "[MVS] - Mucuri - Brazil",
            "searchName": "MVS-Mucuri-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "NBV",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Cana Brava",
            "name": "[NBV] - Cana Brava - Brazil",
            "searchName": "NBV-Cana Brava-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "VLP",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Vila Rica",
            "name": "[VLP] - Vila Rica - Brazil",
            "searchName": "VLP-Vila Rica-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "VIX",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Vitoria",
            "name": "[VIX] - Vitoria - Brazil",
            "searchName": "VIX-Vit-Vitoria-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "VIA",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Videira",
            "name": "[VIA] - Videira - Brazil",
            "searchName": "VIA-Videira-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "VDC",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Vitoria Da Conquista",
            "name": "[VDC] - Vitoria Da Conquista - Brazil",
            "searchName": "VDC-Vitoria Da Conquista-Vit-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "VAG",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Varginha",
            "name": "[VAG] - Varginha - Brazil",
            "searchName": "VAG-Varginha-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "VAL",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Valenca",
            "name": "[VAL] - Valenca - Brazil",
            "searchName": "VAL-Valenca-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "VOT",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Votuporanga",
            "name": "[VOT] - Votuporanga - Brazil",
            "searchName": "VOT-Votuporanga-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TUR",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Tucurui",
            "name": "[TUR] - Tucurui - Brazil",
            "searchName": "TUR-Tucurui-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TUZ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Tucuma",
            "name": "[TUZ] - Tucuma - Brazil",
            "searchName": "TUZ-Tucuma-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TXF",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Teixeira de Freitas",
            "name": "[TXF] - Teixeira de Freitas - Brazil",
            "searchName": "TXF-Teixeira de Freitas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "UBA",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Uberaba",
            "name": "[UBA] - Uberaba - Brazil",
            "searchName": "UBA-Uberaba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TSQ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Torres",
            "name": "[TSQ] - Torres - Brazil",
            "searchName": "TSQ-Torres-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TRQ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Tarauaca",
            "name": "[TRQ] - Tarauaca - Brazil",
            "searchName": "TRQ-Tarauaca-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "UDI",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Uberlandia",
            "name": "[UDI] - Uberlandia - Brazil",
            "searchName": "UDI-Uberl-Uberlandia-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "UBT",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Ubatuba",
            "name": "[UBT] - Ubatuba - Brazil",
            "searchName": "UBT-Ubatuba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "UMU",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Umuarama",
            "name": "[UMU] - Umuarama - Brazil",
            "searchName": "UMU-Umuarama-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "UNA",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Una",
            "name": "[UNA] - Una - Brazil",
            "searchName": "UNA-Una SP-Una-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "URB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Urubupunga",
            "name": "[URB] - Urubupunga - Brazil",
            "searchName": "URB-Urubupunga-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "URG",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Uruguaiana",
            "name": "[URG] - Uruguaiana - Brazil",
            "searchName": "URG-Uruguaiana-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "THE",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Teresina",
            "name": "[THE] - Teresina - Brazil",
            "searchName": "THE-Teresina-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TEC",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Telemaco Borba",
            "name": "[TEC] - Telemaco Borba - Brazil",
            "searchName": "TEC-Telemaco Borba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TGQ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Tangara da Serra",
            "name": "[TGQ] - Tangara da Serra - Brazil",
            "searchName": "TGQ-Tangara da Serra-Tangara Da Serra-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TFF",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Tefe",
            "name": "[TFF] - Tefe - Brazil",
            "searchName": "TFF-Tefe-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TFL",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Teofilo Otoni",
            "name": "[TFL] - Teofilo Otoni - Brazil",
            "searchName": "TFL-Teofilo Otoni-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TMT",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Trombetas",
            "name": "[TMT] - Trombetas - Brazil",
            "searchName": "TMT-Trombetas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TLZ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Catalao",
            "name": "[TLZ] - Catalao - Brazil",
            "searchName": "TLZ-Catalao-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TOW",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Toledo",
            "name": "[TOW] - Toledo - Brazil",
            "searchName": "TOW-Toledo-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SSA",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Salvador",
            "name": "[SSA] - Salvador - Brazil",
            "searchName": "SSA-Salvador-Salvadore-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SSO",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Sao Lourenco",
            "name": "[SSO] - Sao Lourenco - Brazil",
            "searchName": "SSO-Sao Lourenco-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SSZ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Santos",
            "name": "[SSZ] - Santos - Brazil",
            "searchName": "SSZ-Santos-Sao Paulo-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SQX",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Sao Miguel do Oeste",
            "name": "[SQX] - Sao Miguel do Oeste - Brazil",
            "searchName": "SQX-Sao Miguel do Oeste-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SQY",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Sao Lourenco Do Sul",
            "name": "[SQY] - Sao Lourenco Do Sul - Brazil",
            "searchName": "SQY-Sao Lourenco do Sul-Sao Lourenco Do Sul-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SQM",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "São Miguel de Aragao",
            "name": "[SQM] - São Miguel de Aragao - Brazil",
            "searchName": "SQM-Sao Miguel do Araguaia-S-São Miguel de Aragao-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SRA",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Santa Rosa",
            "name": "[SRA] - Santa Rosa - Brazil",
            "searchName": "SRA-Santa Rosa-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SWM",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Suia-Missu",
            "name": "[SWM] - Suia-Missu - Brazil",
            "searchName": "SWM-Suia Missu-Suia-Missu-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "STM",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Santarem",
            "name": "[STM] - Santarem - Brazil",
            "searchName": "STM-Santar-Santarem-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "STZ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Santa Terezinha",
            "name": "[STZ] - Santa Terezinha - Brazil",
            "searchName": "STZ-Santa Terezinha-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SXO",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Sao Felix Do Araguaia",
            "name": "[SXO] - Sao Felix Do Araguaia - Brazil",
            "searchName": "SXO-Sao Felix do Araguaio-Sao Felix Do Araguaia-Sao Felix Araguaio-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SXX",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Sao Felix Do Xingu",
            "name": "[SXX] - Sao Felix Do Xingu - Brazil",
            "searchName": "SXX-Sao Felix do Xingu-Sao Felix Do Xingu-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TBT",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Tabatinga",
            "name": "[TBT] - Tabatinga - Brazil",
            "searchName": "TBT-Tabatinga-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "XIG",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Xinguara",
            "name": "[XIG] - Xinguara - Brazil",
            "searchName": "XIG-Xinguara-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "XAP",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Chapeco",
            "name": "[XAP] - Chapeco - Brazil",
            "searchName": "XAP-Chapeco-Chapec-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ZFU",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Aruja",
            "name": "[ZFU] - Aruja - Brazil",
            "searchName": "ZFU-Aruja-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ZMD",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Sena Madureira",
            "name": "[ZMD] - Sena Madureira - Brazil",
            "searchName": "ZMD-Sena Madureira-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ZSA",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "San Salvador",
            "name": "[ZSA] - San Salvador - Bahamas",
            "searchName": "ZSA-San Salvador-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "WTD",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "West End",
            "name": "[WTD] - West End - Bahamas",
            "searchName": "WTD-West End-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "WKR",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Walker s Cay",
            "name": "[WKR] - Walker s Cay - Bahamas",
            "searchName": "WKR-Walker s Cay-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "TCB",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Treasure Cay",
            "name": "[TCB] - Treasure Cay - Bahamas",
            "searchName": "TCB-Treasure Cay-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "TBI",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "The Bight",
            "name": "[TBI] - The Bight - Bahamas",
            "searchName": "TBI-The Bight-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "SWL",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Spanish Wells",
            "name": "[SWL] - Spanish Wells - Bahamas",
            "searchName": "SWL-Spanish Wells-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "TZN",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "South Andros",
            "name": "[TZN] - South Andros - Bahamas",
            "searchName": "TZN-South Andros-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "TYM",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Staniel Cay",
            "name": "[TYM] - Staniel Cay - Bahamas",
            "searchName": "TYM-Staniel Cay-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "NET",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "New Bight",
            "name": "[NET] - New Bight - Bahamas",
            "searchName": "NET-New Bight-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "NMC",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Normans Cay",
            "name": "[NMC] - Normans Cay - Bahamas",
            "searchName": "NMC-Norman s Cay-Normans Cay-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "MYG",
            "countryCode": "BS",
            "cityCode": null,
            "cityName": "Mayaguana",
            "name": "[MYG] - Mayaguana - Bahamas",
            "searchName": "MYG-Mayaguana-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "AXE",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Xanxere",
            "name": "[AXE] - Xanxere - Brazil",
            "searchName": "AXE-Xanxere-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ALQ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Alegrete",
            "name": "[ALQ] - Alegrete - Brazil",
            "searchName": "ALQ-Alegrete-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ALT",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Alenquer",
            "name": "[ALT] - Alenquer - Brazil",
            "searchName": "ALT-Alenquer-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AMJ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Almenara",
            "name": "[AMJ] - Almenara - Brazil",
            "searchName": "AMJ-Almenara-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AIF",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Assis",
            "name": "[AIF] - Assis - Brazil",
            "searchName": "AIF-Assis-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AIR",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Aripuana",
            "name": "[AIR] - Aripuana - Brazil",
            "searchName": "AIR-Aripuana-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AJU",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Aracaju",
            "name": "[AJU] - Aracaju - Brazil",
            "searchName": "AJU-Aracaju-Araca-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AAX",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Araxa",
            "name": "[AAX] - Araxa - Brazil",
            "searchName": "AAX-Araxa-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AAG",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Arapoti",
            "name": "[AAG] - Arapoti - Brazil",
            "searchName": "AAG-Arapoti-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AAI",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Arraias",
            "name": "[AAI] - Arraias - Brazil",
            "searchName": "AAI-Arraias-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AFL",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Alta Floresta",
            "name": "[AFL] - Alta Floresta - Brazil",
            "searchName": "AFL-Alta Floresta-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "FBE",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Francisco Beltrao",
            "name": "[FBE] - Francisco Beltrao - Brazil",
            "searchName": "FBE-Francisco Beltrao-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ERM",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Erechim",
            "name": "[ERM] - Erechim - Brazil",
            "searchName": "ERM-Erechim-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ERN",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Eirunepe",
            "name": "[ERN] - Eirunepe - Brazil",
            "searchName": "ERN-Eirunepe-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ESI",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Espinosa",
            "name": "[ESI] - Espinosa - Brazil",
            "searchName": "ESI-Espinosa-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "FRC",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Franca",
            "name": "[FRC] - Franca - Brazil",
            "searchName": "FRC-Franca-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "FOR",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Fortaleza",
            "name": "[FOR] - Fortaleza - Brazil",
            "searchName": "FOR-Fortaleza-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "FLB",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Floriano",
            "name": "[FLB] - Floriano - Brazil",
            "searchName": "FLB-Floriano-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "RWS",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Sumare",
            "name": "[RWS] - Sumare - Brazil",
            "searchName": "RWS-Sumare-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "RVD",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Rio Verde",
            "name": "[RVD] - Rio Verde - Brazil",
            "searchName": "RVD-Rio Verde-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SBJ",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Sao Mateus",
            "name": "[SBJ] - Sao Mateus - Brazil",
            "searchName": "SBJ-Sao Mateus-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SEI",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Senhor Do Bonfim",
            "name": "[SEI] - Senhor Do Bonfim - Brazil",
            "searchName": "SEI-Senhor do Bonfim-Senhor Do Bonfim-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ROO",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Rondonopolis",
            "name": "[ROO] - Rondonopolis - Brazil",
            "searchName": "ROO-Rondonopolis-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "RRN",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Serra Norte",
            "name": "[RRN] - Serra Norte - Brazil",
            "searchName": "RRN-Serra Norte-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "RSG",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Serra Pelada",
            "name": "[RSG] - Serra Pelada - Brazil",
            "searchName": "RSG-Serra Pelada-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BJM",
            "countryCode": "BI",
            "cityCode": null,
            "cityName": "Bujumbura",
            "name": "[BJM] - Bujumbura - Burundi",
            "searchName": "BJM-Bujumbura-Burundi",
            "priority": 0
        },
        {
            "iataCode": "GID",
            "countryCode": "BI",
            "cityCode": null,
            "cityName": "Gitega",
            "name": "[GID] - Gitega - Burundi",
            "searchName": "GID-Gitega-Burundi",
            "priority": 0
        },
        {
            "iataCode": "KRE",
            "countryCode": "BI",
            "cityCode": null,
            "cityName": "Kirundo",
            "name": "[KRE] - Kirundo - Burundi",
            "searchName": "KRE-Kirundo-Burundi",
            "priority": 0
        },
        {
            "iataCode": "KDC",
            "countryCode": "BJ",
            "cityCode": null,
            "cityName": "Kandi",
            "name": "[KDC] - Kandi - Benin",
            "searchName": "KDC-Kandi-Benin-Bénin-Benín",
            "priority": 0
        },
        {
            "iataCode": "DJA",
            "countryCode": "BJ",
            "cityCode": null,
            "cityName": "Djougou",
            "name": "[DJA] - Djougou - Benin",
            "searchName": "DJA-Djougou-Benin-Bénin-Benín",
            "priority": 0
        },
        {
            "iataCode": "COO",
            "countryCode": "BJ",
            "cityCode": null,
            "cityName": "Cotonou",
            "name": "[COO] - Cotonou - Benin",
            "searchName": "COO-Cotonou-Benin-Bénin-Benín",
            "priority": 0
        },
        {
            "iataCode": "NAE",
            "countryCode": "BJ",
            "cityCode": null,
            "cityName": "Natitingou",
            "name": "[NAE] - Natitingou - Benin",
            "searchName": "NAE-Natitingou-Benin-Bénin-Benín",
            "priority": 0
        },
        {
            "iataCode": "PKO",
            "countryCode": "BJ",
            "cityCode": null,
            "cityName": "Parakou",
            "name": "[PKO] - Parakou - Benin",
            "searchName": "PKO-Parakou-Benin-Bénin-Benín",
            "priority": 0
        },
        {
            "iataCode": "SVF",
            "countryCode": "BJ",
            "cityCode": null,
            "cityName": "Save",
            "name": "[SVF] - Save - Benin",
            "searchName": "SVF-Save-Benin-Bénin-Benín",
            "priority": 0
        },
        {
            "iataCode": "MVQ",
            "countryCode": "BY",
            "cityCode": null,
            "cityName": "Mogilev",
            "name": "[MVQ] - Mogilev - Belarus",
            "searchName": "MVQ-Mogilev-Weißrußland-Belarus-Beyaz Rusya-Bélarus-Bielorrusia-Bielorussia",
            "priority": 0
        },
        {
            "iataCode": "MZE",
            "countryCode": "BZ",
            "cityCode": null,
            "cityName": "Manatee",
            "name": "[MZE] - Manatee - Belize",
            "searchName": "MZE-Manatee-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "ORZ",
            "countryCode": "BZ",
            "cityCode": null,
            "cityName": "Orange Walk",
            "name": "[ORZ] - Orange Walk - Belize",
            "searchName": "ORZ-Orange Walk-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "PND",
            "countryCode": "BZ",
            "cityCode": null,
            "cityName": "Punta Gorda",
            "name": "[PND] - Punta Gorda - Belize",
            "searchName": "PND-Punta Gorda-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "PLJ",
            "countryCode": "BZ",
            "cityCode": null,
            "cityName": "Placencia",
            "name": "[PLJ] - Placencia - Belize",
            "searchName": "PLJ-Placencia-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "SPR",
            "countryCode": "BZ",
            "cityCode": null,
            "cityName": "San Pedro",
            "name": "[SPR] - San Pedro - Belize",
            "searchName": "SPR-Ambergris Caye-San Pedro-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "SJX",
            "countryCode": "BZ",
            "cityCode": null,
            "cityName": "Sartaneja",
            "name": "[SJX] - Sartaneja - Belize",
            "searchName": "SJX-Sartaneja-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "SVK",
            "countryCode": "BZ",
            "cityCode": null,
            "cityName": "Silver Creek",
            "name": "[SVK] - Silver Creek - Belize",
            "searchName": "SVK-Silver Creek-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "STU",
            "countryCode": "BZ",
            "cityCode": null,
            "cityName": "Santa Cruz",
            "name": "[STU] - Santa Cruz - Belize",
            "searchName": "STU-Santa Cruz-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "SQS",
            "countryCode": "BZ",
            "cityCode": null,
            "cityName": "San Ignacio",
            "name": "[SQS] - San Ignacio - Belize",
            "searchName": "SQS-San Ignacio-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "BZE",
            "countryCode": "BZ",
            "cityCode": null,
            "cityName": "Belize City",
            "name": "[BZE] - Belize City - Belize",
            "searchName": "BZE-Belize Stadt-Belize City-Ciudad de Belice-Belize Ville-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "BGK",
            "countryCode": "BZ",
            "cityCode": null,
            "cityName": "Big Creek",
            "name": "[BGK] - Big Creek - Belize",
            "searchName": "BGK-Big Creek-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "BCV",
            "countryCode": "BZ",
            "cityCode": null,
            "cityName": "Belmopan",
            "name": "[BCV] - Belmopan - Belize",
            "searchName": "BCV-Belmopan-Belmop-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "CZH",
            "countryCode": "BZ",
            "cityCode": null,
            "cityName": "Corozal",
            "name": "[CZH] - Corozal - Belize",
            "searchName": "CZH-Corozal-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "DGA",
            "countryCode": "BZ",
            "cityCode": null,
            "cityName": "Dangriga",
            "name": "[DGA] - Dangriga - Belize",
            "searchName": "DGA-Dangriga-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "CYC",
            "countryCode": "BZ",
            "cityCode": null,
            "cityName": "Caye Chapel",
            "name": "[CYC] - Caye Chapel - Belize",
            "searchName": "CYC-Caye Chapel-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "CUK",
            "countryCode": "BZ",
            "cityCode": null,
            "cityName": "Caye Caulker",
            "name": "[CUK] - Caye Caulker - Belize",
            "searchName": "CUK-Caye Caulker-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "INB",
            "countryCode": "BZ",
            "cityCode": null,
            "cityName": "Independence",
            "name": "[INB] - Independence - Belize",
            "searchName": "INB-Independence-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "MDB",
            "countryCode": "BZ",
            "cityCode": null,
            "cityName": "Melinda",
            "name": "[MDB] - Melinda - Belize",
            "searchName": "MDB-Melinda-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "LRQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Laurie River",
            "name": "[LRQ] - Laurie River - Canada",
            "searchName": "LRQ-Laurie River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "KEW",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Keewaywin",
            "name": "[KEW] - Keewaywin - Canada",
            "searchName": "KEW-Keewaywin-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "KES",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kelsey",
            "name": "[KES] - Kelsey - Canada",
            "searchName": "KES-Kelsey-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "KIF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kingfisher Lake",
            "name": "[KIF] - Kingfisher Lake - Canada",
            "searchName": "KIF-Kingfisher Lake-Kingfisher G-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "KNY",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kinoosao",
            "name": "[KNY] - Kinoosao - Canada",
            "searchName": "KNY-Kinoosao-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "KNV",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Knights Inlet",
            "name": "[KNV] - Knights Inlet - Canada",
            "searchName": "KNV-Knights Inlet-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "LAK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Aklavik",
            "name": "[LAK] - Aklavik - Canada",
            "searchName": "LAK-Aklavik-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ILF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Ilford",
            "name": "[ILF] - Ilford - Canada",
            "searchName": "ILF-Ilford-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "HZP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fort MacKay",
            "name": "[HZP] - Fort MacKay - Canada",
            "searchName": "HZP-Fort MacKay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "IUM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Summit Lake",
            "name": "[IUM] - Summit Lake - Canada",
            "searchName": "IUM-Summit Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "GSL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Taltheilei Narrows",
            "name": "[GSL] - Taltheilei Narrows - Canada",
            "searchName": "GSL-Taltheilei Narrows-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "DGF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Douglas Lake",
            "name": "[DGF] - Douglas Lake - Canada",
            "searchName": "DGF-Douglas Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "DHO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Horn River",
            "name": "[DHO] - Horn River - Canada",
            "searchName": "DHO-Horn River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "DCP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Cabin Plant",
            "name": "[DCP] - Cabin Plant - Canada",
            "searchName": "DCP-Cabin Plant-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "DAS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Great Bear Lake",
            "name": "[DAS] - Great Bear Lake - Canada",
            "searchName": "DAS-Great Bear Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "CFQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Creston",
            "name": "[CFQ] - Creston - Canada",
            "searchName": "CFQ-Creston-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "CJH",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Chilko Lake",
            "name": "[CJH] - Chilko Lake - Canada",
            "searchName": "CJH-Chilko Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "DUQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Duncan/Quam",
            "name": "[DUQ] - Duncan/Quam - Canada",
            "searchName": "DUQ-Duncan/Quam-Duncan\\/Quam-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "DVK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Diavik",
            "name": "[DVK] - Diavik - Canada",
            "searchName": "DVK-Diavik-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "DKL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kiwigana Lodge",
            "name": "[DKL] - Kiwigana Lodge - Canada",
            "searchName": "DKL-Kiwigana Lodge-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "AKV",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Akulivik",
            "name": "[AKV] - Akulivik - Canada",
            "searchName": "AKV-Akulivik-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "SSQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "La Sarre",
            "name": "[SSQ] - La Sarre - Canada",
            "searchName": "SSQ-La Sarre-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "SUR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Summer Beaver",
            "name": "[SUR] - Summer Beaver - Canada",
            "searchName": "SUR-Summer Beaver-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "SYF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Silva Bay",
            "name": "[SYF] - Silva Bay - Canada",
            "searchName": "SYF-Silva Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "TNS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Tungsten",
            "name": "[TNS] - Tungsten - Canada",
            "searchName": "TNS-Tungsten-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "TIL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Inverlake",
            "name": "[TIL] - Inverlake - Canada",
            "searchName": "TIL-Inverlake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "UZM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Hope Bay",
            "name": "[UZM] - Hope Bay - Canada",
            "searchName": "UZM-Hope Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "TUX",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Tumbler Ridge",
            "name": "[TUX] - Tumbler Ridge - Canada",
            "searchName": "TUX-Tumbler Ridge-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "WNN",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Wunnummin Lake",
            "name": "[WNN] - Wunnummin Lake - Canada",
            "searchName": "WNN-Wunnummin Lake-Wunnummin G-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "WPL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Powell Lake",
            "name": "[WPL] - Powell Lake - Canada",
            "searchName": "WPL-Powell Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "WPC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Pincher Creek",
            "name": "[WPC] - Pincher Creek - Canada",
            "searchName": "WPC-Pincher Creek-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XBR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Brockville",
            "name": "[XBR] - Brockville - Canada",
            "searchName": "XBR-Brockville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XBW",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Killineq",
            "name": "[XBW] - Killineq - Canada",
            "searchName": "XBW-Killineq-Killineq Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XCI",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Chambord",
            "name": "[XCI] - Chambord - Canada",
            "searchName": "XCI-Chambord-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XCL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Cluff Lake",
            "name": "[XCL] - Cluff Lake - Canada",
            "searchName": "XCL-Cluff Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XCM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Chatham",
            "name": "[XCM] - Chatham - Canada",
            "searchName": "XCM-Chatham-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XAZ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Campbellton",
            "name": "[XAZ] - Campbellton - Canada",
            "searchName": "XAZ-Campbellton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XBB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Blubber Bay",
            "name": "[XBB] - Blubber Bay - Canada",
            "searchName": "XBB-Blubber Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XBE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Bearskin Lake",
            "name": "[XBE] - Bearskin Lake - Canada",
            "searchName": "XBE-Bearskin Lake-Bearskin G-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XAW",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Capreol",
            "name": "[XAW] - Capreol - Canada",
            "searchName": "XAW-Capreol-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XDL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Chandler",
            "name": "[XDL] - Chandler - Canada",
            "searchName": "XDL-Chandler-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XDM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Drummondville",
            "name": "[XDM] - Drummondville - Canada",
            "searchName": "XDM-Drummondville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XDO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Grande-Riviere",
            "name": "[XDO] - Grande-Riviere - Canada",
            "searchName": "XDO-Grande-Riviere-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XDU",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Hervey",
            "name": "[XDU] - Hervey - Canada",
            "searchName": "XDU-Hervey-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XEE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Lac Edouard",
            "name": "[XEE] - Lac Edouard - Canada",
            "searchName": "XEE-Lac Edouard-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XEH",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Ladysmith",
            "name": "[XEH] - Ladysmith - Canada",
            "searchName": "XEH-Ladysmith-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XEJ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Langford",
            "name": "[XEJ] - Langford - Canada",
            "searchName": "XEJ-Langford-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XEK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Melville",
            "name": "[XEK] - Melville - Canada",
            "searchName": "XEK-Melville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XEL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "New Carlisle",
            "name": "[XEL] - New Carlisle - Canada",
            "searchName": "XEL-New Carlisle-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XEM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "New Richmond",
            "name": "[XEM] - New Richmond - Canada",
            "searchName": "XEM-New Richmond-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XEY",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Miramichi",
            "name": "[XEY] - Miramichi - Canada",
            "searchName": "XEY-Miramichi-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Fredericton Junction",
            "name": "[XFC] - Fredericton Junction - Canada",
            "searchName": "XFC-Fredericton Junction-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFD",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Stratford",
            "name": "[XFD] - Stratford - Canada",
            "searchName": "XFD-Stratford-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFE",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Parent",
            "name": "[XFE] - Parent - Canada",
            "searchName": "XFE-Parent-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFG",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Perce",
            "name": "[XFG] - Perce - Canada",
            "searchName": "XFG-Perce-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFI",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Port-Daniel",
            "name": "[XFI] - Port-Daniel - Canada",
            "searchName": "XFI-Port-Daniel-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Senneterre",
            "name": "[XFK] - Senneterre - Canada",
            "searchName": "XFK-Senneterre-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Shawinigan",
            "name": "[XFL] - Shawinigan - Canada",
            "searchName": "XFL-Shawinigan-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Shawnigan",
            "name": "[XFM] - Shawnigan - Canada",
            "searchName": "XFM-Shawnigan-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Taschereau",
            "name": "[XFO] - Taschereau - Canada",
            "searchName": "XFO-Taschereau-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Weymont",
            "name": "[XFQ] - Weymont - Canada",
            "searchName": "XFQ-Weymont-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Alexandria",
            "name": "[XFS] - Alexandria - Canada",
            "searchName": "XFS-Alexandria-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XII",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Prescott",
            "name": "[XII] - Prescott - Canada",
            "searchName": "XII-Prescott-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XID",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Maxville",
            "name": "[XID] - Maxville - Canada",
            "searchName": "XID-Maxville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XIF",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Napanee",
            "name": "[XIF] - Napanee - Canada",
            "searchName": "XIF-Napanee-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XIM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Saint Hyacinthe",
            "name": "[XIM] - Saint Hyacinthe - Canada",
            "searchName": "XIM-Saint Hyacinthe-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XIO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "St Marys",
            "name": "[XIO] - St Marys - Canada",
            "searchName": "XIO-St Marys-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XIP",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Woodstock",
            "name": "[XIP] - Woodstock - Canada",
            "searchName": "XIP-Woodstock-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XJL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Joliette",
            "name": "[XJL] - Joliette - Canada",
            "searchName": "XJL-Joliette-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XJQ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Jonquiere",
            "name": "[XJQ] - Jonquiere - Canada",
            "searchName": "XJQ-Jonquiere-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XKO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kemano",
            "name": "[XKO] - Kemano - Canada",
            "searchName": "XKO-Kemano-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XKS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kasabonika",
            "name": "[XKS] - Kasabonika - Canada",
            "searchName": "XKS-Kasabonika-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XKV",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Sackville",
            "name": "[XKV] - Sackville - Canada",
            "searchName": "XKV-Sackville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFV",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Brantford",
            "name": "[XFV] - Brantford - Canada",
            "searchName": "XFV-Brantford-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XGJ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Cobourg",
            "name": "[XGJ] - Cobourg - Canada",
            "searchName": "XGJ-Cobourg-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XGK",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Coteau",
            "name": "[XGK] - Coteau - Canada",
            "searchName": "XGK-Coteau-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XGL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Granville Lake",
            "name": "[XGL] - Granville Lake - Canada",
            "searchName": "XGL-Granville Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XGR",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Kangiqsualujjuaq",
            "name": "[XGR] - Kangiqsualujjuaq - Canada",
            "searchName": "XGR-Kangiqsualujjuaq-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XGW",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Gananoque",
            "name": "[XGW] - Gananoque - Canada",
            "searchName": "XGW-Gananoque-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XGY",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Grimsby",
            "name": "[XGY] - Grimsby - Canada",
            "searchName": "XGY-Grimsby-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XHM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Georgetown",
            "name": "[XHM] - Georgetown - Canada",
            "searchName": "XHM-Georgetown-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XHS",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Chemainus",
            "name": "[XHS] - Chemainus - Canada",
            "searchName": "XHS-Chemainus-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XIA",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Guelph",
            "name": "[XIA] - Guelph - Canada",
            "searchName": "XIA-Guelph-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XIB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Ingersoll",
            "name": "[XIB] - Ingersoll - Canada",
            "searchName": "XIB-Ingersoll-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XZB",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Casselman",
            "name": "[XZB] - Casselman - Canada",
            "searchName": "XZB-Casselman-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XZC",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Glencoe",
            "name": "[XZC] - Glencoe - Canada",
            "searchName": "XZC-Glencoe-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAJ",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Lyall Harbour",
            "name": "[YAJ] - Lyall Harbour - Canada",
            "searchName": "YAJ-Lyall Harbour-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAL",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Alert Bay",
            "name": "[YAL] - Alert Bay - Canada",
            "searchName": "YAL-Alert Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAM",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Sault Sainte Marie",
            "name": "[YAM] - Sault Sainte Marie - Canada",
            "searchName": "YAM-Sault Ste Marie-Sault Sainte Marie-Sault Ste-Marie-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "MVR",
            "countryCode": "CM",
            "cityCode": null,
            "cityName": "Maroua",
            "name": "[MVR] - Maroua - Cameroon",
            "searchName": "MVR-Maroua-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "GXX",
            "countryCode": "CM",
            "cityCode": null,
            "cityName": "Yagoua",
            "name": "[GXX] - Yagoua - Cameroon",
            "searchName": "GXX-Yagoua-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "GOU",
            "countryCode": "CM",
            "cityCode": null,
            "cityName": "Garoua",
            "name": "[GOU] - Garoua - Cameroon",
            "searchName": "GOU-Garoua-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "KLE",
            "countryCode": "CM",
            "cityCode": null,
            "cityName": "Kaele",
            "name": "[KLE] - Kaele - Cameroon",
            "searchName": "KLE-Kaele-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "KBI",
            "countryCode": "CM",
            "cityCode": null,
            "cityName": "Kribi",
            "name": "[KBI] - Kribi - Cameroon",
            "searchName": "KBI-Kribi-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "FOM",
            "countryCode": "CM",
            "cityCode": null,
            "cityName": "Foumban",
            "name": "[FOM] - Foumban - Cameroon",
            "searchName": "FOM-Foumban-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "DLA",
            "countryCode": "CM",
            "cityCode": null,
            "cityName": "Douala",
            "name": "[DLA] - Douala - Cameroon",
            "searchName": "DLA-Douala-Duala-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "DSC",
            "countryCode": "CM",
            "cityCode": null,
            "cityName": "Dschang",
            "name": "[DSC] - Dschang - Cameroon",
            "searchName": "DSC-Dschang-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "EBW",
            "countryCode": "CM",
            "cityCode": null,
            "cityName": "Ebolowa",
            "name": "[EBW] - Ebolowa - Cameroon",
            "searchName": "EBW-Ebolowa-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "BTA",
            "countryCode": "CM",
            "cityCode": null,
            "cityName": "Yaoundé",
            "name": "[BTA] - Yaoundé - Cameroon",
            "searchName": "BTA-Bertoua-Yaound-Yaoundé-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "BFX",
            "countryCode": "CM",
            "cityCode": null,
            "cityName": "Bafoussam",
            "name": "[BFX] - Bafoussam - Cameroon",
            "searchName": "BFX-Bafoussam-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "BPC",
            "countryCode": "CM",
            "cityCode": null,
            "cityName": "Bamenda",
            "name": "[BPC] - Bamenda - Cameroon",
            "searchName": "BPC-Bamenda-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "BLC",
            "countryCode": "CM",
            "cityCode": null,
            "cityName": "Bali",
            "name": "[BLC] - Bali - Cameroon",
            "searchName": "BLC-Bali-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "CNR",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Chanaral",
            "name": "[CNR] - Chanaral - Chile",
            "searchName": "CNR-Chanaral-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "CCP",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Concepcion",
            "name": "[CCP] - Concepcion - Chile",
            "searchName": "CCP-Concepci-Concepcion-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "CJC",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Calama",
            "name": "[CJC] - Calama - Chile",
            "searchName": "CJC-Calama-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "FFU",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Futaleufu",
            "name": "[FFU] - Futaleufu - Chile",
            "searchName": "FFU-Futaleufu-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "FRT",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Frutillar",
            "name": "[FRT] - Frutillar - Chile",
            "searchName": "FRT-Frutillar-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "ESR",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "El Salvador",
            "name": "[ESR] - El Salvador - Chile",
            "searchName": "ESR-El Salvador-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "LSC",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "La Serena",
            "name": "[LSC] - La Serena - Chile",
            "searchName": "LSC-La Serena-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "LSQ",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Los Angeles",
            "name": "[LSQ] - Los Angeles - Chile",
            "searchName": "LSQ-Los Angeles-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "LOB",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Los Andes",
            "name": "[LOB] - Los Andes - Chile",
            "searchName": "LOB-Los Andes-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "LGR",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Cochrane",
            "name": "[LGR] - Cochrane - Chile",
            "searchName": "LGR-Cochrane-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "KNA",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Vina del Mar",
            "name": "[KNA] - Vina del Mar - Chile",
            "searchName": "KNA-Vina Del Mar-Vina del Mar-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "GXQ",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Coyhaique",
            "name": "[GXQ] - Coyhaique - Chile",
            "searchName": "GXQ-Coyhaique-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "IQQ",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Iquique",
            "name": "[IQQ] - Iquique - Chile",
            "searchName": "IQQ-Iquique-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "SMB",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Cerro Sombrero",
            "name": "[SMB] - Cerro Sombrero - Chile",
            "searchName": "SMB-Cerro Sombrero-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "OVL",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Ovalle",
            "name": "[OVL] - Ovalle - Chile",
            "searchName": "OVL-Ovalle-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "TOQ",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Tocopilla",
            "name": "[TOQ] - Tocopilla - Chile",
            "searchName": "TOQ-Tocopilla-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "TLX",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Talca",
            "name": "[TLX] - Talca - Chile",
            "searchName": "TLX-Talca-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "TTC",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Taltal",
            "name": "[TTC] - Taltal - Chile",
            "searchName": "TTC-Taltal-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "VAP",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Valparaiso",
            "name": "[VAP] - Valparaiso - Chile",
            "searchName": "VAP-Valparaiso-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "VLR",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Vallenar",
            "name": "[VLR] - Vallenar - Chile",
            "searchName": "VLR-Vallenar-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "WCA",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Castro",
            "name": "[WCA] - Castro - Chile",
            "searchName": "WCA-Castro-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "WCH",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Chaiten",
            "name": "[WCH] - Chaiten - Chile",
            "searchName": "WCH-Chaiten-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "WAP",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Alto Palena",
            "name": "[WAP] - Alto Palena - Chile",
            "searchName": "WAP-Alto Palena-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "ZVC",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Villarrica",
            "name": "[ZVC] - Villarrica - Chile",
            "searchName": "ZVC-Villarrica-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "ZUD",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Ancud",
            "name": "[ZUD] - Ancud - Chile",
            "searchName": "ZUD-Ancud-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "ZPC",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Pucon",
            "name": "[ZPC] - Pucon - Chile",
            "searchName": "ZPC-Pucon-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "ZOS",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Osorno",
            "name": "[ZOS] - Osorno - Chile",
            "searchName": "ZOS-Osorno-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "ZIC",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Victoria",
            "name": "[ZIC] - Victoria - Chile",
            "searchName": "ZIC-Victoria-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "ZAL",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Valdivia",
            "name": "[ZAL] - Valdivia - Chile",
            "searchName": "ZAL-Valdivia-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "ZCO",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Temuco",
            "name": "[ZCO] - Temuco - Chile",
            "searchName": "ZCO-Temuco-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "YAI",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Chillan",
            "name": "[YAI] - Chillan - Chile",
            "searchName": "YAI-Chillan-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "WPA",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Puerto Aisen",
            "name": "[WPA] - Puerto Aisen - Chile",
            "searchName": "WPA-Puerto Aisen-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "WPR",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Porvenir",
            "name": "[WPR] - Porvenir - Chile",
            "searchName": "WPR-Porvenir-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "WPU",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Puerto Williams",
            "name": "[WPU] - Puerto Williams - Chile",
            "searchName": "WPU-Puerto Williams-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "YAO",
            "countryCode": "CM",
            "cityCode": null,
            "cityName": "Yaounde",
            "name": "[YAO] - Yaounde - Cameroon",
            "searchName": "YAO-Yaound-Yaounde-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "TKC",
            "countryCode": "CM",
            "cityCode": null,
            "cityName": "Tiko",
            "name": "[TKC] - Tiko - Cameroon",
            "searchName": "TKC-Tiko-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "OUR",
            "countryCode": "CM",
            "cityCode": null,
            "cityName": "Batouri",
            "name": "[OUR] - Batouri - Cameroon",
            "searchName": "OUR-Batouri-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "MMF",
            "countryCode": "CM",
            "cityCode": null,
            "cityName": "Mamfe",
            "name": "[MMF] - Mamfe - Cameroon",
            "searchName": "MMF-Mamfe-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "NKS",
            "countryCode": "CM",
            "cityCode": null,
            "cityName": "Nkongsamba",
            "name": "[NKS] - Nkongsamba - Cameroon",
            "searchName": "NKS-Nkongsamba-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "BBV",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Bereby",
            "name": "[BBV] - Bereby - Ivory Coast",
            "searchName": "BBV-Bereby-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "ASK",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Yamoussoukro",
            "name": "[ASK] - Yamoussoukro - Ivory Coast",
            "searchName": "ASK-Yamoussoukro-Yamoussouro-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "DJO",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Daloa",
            "name": "[DJO] - Daloa - Ivory Coast",
            "searchName": "DJO-Daloa-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "FEK",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Ferkessedougou",
            "name": "[FEK] - Ferkessedougou - Ivory Coast",
            "searchName": "FEK-Ferkessedougou-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "DIV",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Divo",
            "name": "[DIV] - Divo - Ivory Coast",
            "searchName": "DIV-Divo-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "DIM",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Dimbokro",
            "name": "[DIM] - Dimbokro - Ivory Coast",
            "searchName": "DIM-Dimbokro-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "HGO",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Korhogo",
            "name": "[HGO] - Korhogo - Ivory Coast",
            "searchName": "HGO-Korhogo-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "GGN",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Gagnoa",
            "name": "[GGN] - Gagnoa - Ivory Coast",
            "searchName": "GGN-Gagnoa-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "GGO",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Guiglo",
            "name": "[GGO] - Guiglo - Ivory Coast",
            "searchName": "GGO-Guiglo-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "MJC",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Man",
            "name": "[MJC] - Man - Ivory Coast",
            "searchName": "MJC-Man-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "KEO",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Odienne",
            "name": "[KEO] - Odienne - Ivory Coast",
            "searchName": "KEO-Odienne-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "KTC",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Katiola",
            "name": "[KTC] - Katiola - Ivory Coast",
            "searchName": "KTC-Katiola-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "ZSS",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Sassandra",
            "name": "[ZSS] - Sassandra - Ivory Coast",
            "searchName": "ZSS-Sassandra-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "TXU",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Tabou",
            "name": "[TXU] - Tabou - Ivory Coast",
            "searchName": "TXU-Tabou-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "TOZ",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Touba",
            "name": "[TOZ] - Touba - Ivory Coast",
            "searchName": "TOZ-Touba-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "SPY",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "San Pedro",
            "name": "[SPY] - San Pedro - Ivory Coast",
            "searchName": "SPY-San Pedro-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "SEO",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Seguela",
            "name": "[SEO] - Seguela - Ivory Coast",
            "searchName": "SEO-Seguela-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "OFI",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Ouango Fitini",
            "name": "[OFI] - Ouango Fitini - Ivory Coast",
            "searchName": "OFI-Ouango-Ouango Fitini-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "OGO",
            "countryCode": "CI",
            "cityCode": null,
            "cityName": "Abengourou",
            "name": "[OGO] - Abengourou - Ivory Coast",
            "searchName": "OGO-Abengourou-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "MUK",
            "countryCode": "CK",
            "cityCode": null,
            "cityName": "Mauke Island",
            "name": "[MUK] - Mauke Island - Cook Islands",
            "searchName": "MUK-Mauke Island--Ilhas Cook-Cook-eilanden-Kepulauan Cook-Cooki saared-Islas Cook-Isole Cook-Inseln-Îles Cook-Cook Islands-Cookinseln-Cook Adalari",
            "priority": 0
        },
        {
            "iataCode": "MOI",
            "countryCode": "CK",
            "cityCode": null,
            "cityName": "Mitiaro Island",
            "name": "[MOI] - Mitiaro Island - Cook Islands",
            "searchName": "MOI-Mitiaro Island--Ilhas Cook-Cook-eilanden-Kepulauan Cook-Cooki saared-Islas Cook-Isole Cook-Inseln-Îles Cook-Cook Islands-Cookinseln-Cook Adalari",
            "priority": 0
        },
        {
            "iataCode": "PZK",
            "countryCode": "CK",
            "cityCode": null,
            "cityName": "Puka Puka",
            "name": "[PZK] - Puka Puka - Cook Islands",
            "searchName": "PZK-Puka Puka Island-Puka Puka-Ilhas Cook-Cook-eilanden-Kepulauan Cook-Cooki saared-Islas Cook-Isole Cook-Inseln-Îles Cook-Cook Islands-Cookinseln-Cook Adalari",
            "priority": 0
        },
        {
            "iataCode": "PYE",
            "countryCode": "CK",
            "cityCode": null,
            "cityName": "Penrhyn Island",
            "name": "[PYE] - Penrhyn Island - Cook Islands",
            "searchName": "PYE-Penrhyn Island-Ilhas Cook-Cook-eilanden-Kepulauan Cook-Cooki saared-Islas Cook-Isole Cook-Inseln-Îles Cook-Cook Islands-Cookinseln-Cook Adalari",
            "priority": 0
        },
        {
            "iataCode": "RAR",
            "countryCode": "CK",
            "cityCode": null,
            "cityName": "Rarotonga",
            "name": "[RAR] - Rarotonga - Cook Islands",
            "searchName": "RAR-Rarotonga-Ilhas Cook-Cook-eilanden-Kepulauan Cook-Cooki saared-Islas Cook-Isole Cook-Inseln-Îles Cook-Cook Islands-Cookinseln-Cook Adalari",
            "priority": 0
        },
        {
            "iataCode": "MGS",
            "countryCode": "CK",
            "cityCode": null,
            "cityName": "Mangaia Island",
            "name": "[MGS] - Mangaia Island - Cook Islands",
            "searchName": "MGS-Mangaia Island--Ilhas Cook-Cook-eilanden-Kepulauan Cook-Cooki saared-Islas Cook-Isole Cook-Inseln-Îles Cook-Cook Islands-Cookinseln-Cook Adalari",
            "priority": 0
        },
        {
            "iataCode": "MHX",
            "countryCode": "CK",
            "cityCode": null,
            "cityName": "Manihiki Island",
            "name": "[MHX] - Manihiki Island - Cook Islands",
            "searchName": "MHX-Manihiki Island-Ilhas Cook-Cook-eilanden-Kepulauan Cook-Cooki saared-Islas Cook-Isole Cook-Inseln-Îles Cook-Cook Islands-Cookinseln-Cook Adalari",
            "priority": 0
        },
        {
            "iataCode": "AIT",
            "countryCode": "CK",
            "cityCode": null,
            "cityName": "Aitutaki",
            "name": "[AIT] - Aitutaki - Cook Islands",
            "searchName": "AIT-Aitutaki-Ilhas Cook-Cook-eilanden-Kepulauan Cook-Cooki saared-Islas Cook-Isole Cook-Inseln-Îles Cook-Cook Islands-Cookinseln-Cook Adalari",
            "priority": 0
        },
        {
            "iataCode": "AIU",
            "countryCode": "CK",
            "cityCode": null,
            "cityName": "Atiu Island",
            "name": "[AIU] - Atiu Island - Cook Islands",
            "searchName": "AIU-Atiu Island-Ilhas Cook-Cook-eilanden-Kepulauan Cook-Cooki saared-Islas Cook-Isole Cook-Inseln-Îles Cook-Cook Islands-Cookinseln-Cook Adalari",
            "priority": 0
        },
        {
            "iataCode": "ANF",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Antofagasta",
            "name": "[ANF] - Antofagasta - Chile",
            "searchName": "ANF-Antofagasta-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "ARI",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Arica",
            "name": "[ARI] - Arica - Chile",
            "searchName": "ARI-Arica-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "BBA",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Balmaceda",
            "name": "[BBA] - Balmaceda - Chile",
            "searchName": "BBA-Balmaceda-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "CCH",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Chile Chico",
            "name": "[CCH] - Chile Chico - Chile",
            "searchName": "CCH-Chile Chico-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "COW",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Coquimbo",
            "name": "[COW] - Coquimbo - Chile",
            "searchName": "COW-Coquimbo-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "BAV",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Baotou",
            "name": "[BAV] - Baotou - China",
            "searchName": "BAV-Baotou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "AVA",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "An Shun",
            "name": "[AVA] - An Shun - China",
            "searchName": "AVA-An Shun-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "AYN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Anyang",
            "name": "[AYN] - Anyang - China",
            "searchName": "AYN-Anyang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "AQG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Anqing",
            "name": "[AQG] - Anqing - China",
            "searchName": "AQG-Anqing-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "AOG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Anshan",
            "name": "[AOG] - Anshan - China",
            "searchName": "AOG-Anshan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "AKU",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Aksu",
            "name": "[AKU] - Aksu - China",
            "searchName": "AKU-Aksu-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "AKA",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Ankang",
            "name": "[AKA] - Ankang - China",
            "searchName": "AKA-Ankang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "AAT",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Altay",
            "name": "[AAT] - Altay - China",
            "searchName": "AAT-Altay-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ACX",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Xingyi",
            "name": "[ACX] - Xingyi - China",
            "searchName": "ACX-Xingyi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "AEB",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Baise",
            "name": "[AEB] - Baise - China",
            "searchName": "AEB-Baise-Baise Youjiang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DZU",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Dazu",
            "name": "[DZU] - Dazu - China",
            "searchName": "DZU-Dazu-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DYG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Dayong",
            "name": "[DYG] - Dayong - China",
            "searchName": "DYG-Dayong-Dayang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DOY",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Dongying",
            "name": "[DOY] - Dongying - China",
            "searchName": "DOY-Dongying-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DQA",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Daqing",
            "name": "[DQA] - Daqing - China",
            "searchName": "DQA-Daqing-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DSN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Ordos (Dongsheng)",
            "name": "[DSN] - Ordos (Dongsheng) - China",
            "searchName": "DSN-Dongsheng-Ordos (Dongsheng)-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DLC",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Dalian",
            "name": "[DLC] - Dalian - China",
            "searchName": "DLC-Dalian-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DLU",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Dali City",
            "name": "[DLU] - Dali City - China",
            "searchName": "DLU-Dali City-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DNH",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Dunhuang",
            "name": "[DNH] - Dunhuang - China",
            "searchName": "DNH-Dunhuang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "FOC",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Fuzhou",
            "name": "[FOC] - Fuzhou - China",
            "searchName": "FOC-Fuzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ERL",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Erenhot",
            "name": "[ERL] - Erenhot - China",
            "searchName": "ERL-Erenhot-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ENY",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Yanan",
            "name": "[ENY] - Yanan - China",
            "searchName": "ENY-Yan an-Yan an Airport-Yanan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ENH",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Enshi",
            "name": "[ENH] - Enshi - China",
            "searchName": "ENH-Enshi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CIH",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Changzhi",
            "name": "[CIH] - Changzhi - China",
            "searchName": "CIH-Changzhi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CIF",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Chifeng",
            "name": "[CIF] - Chifeng - China",
            "searchName": "CIF-Chifeng-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CHW",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Jiuquan",
            "name": "[CHW] - Jiuquan - China",
            "searchName": "CHW-Jiuquan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CGD",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Changde",
            "name": "[CGD] - Changde - China",
            "searchName": "CGD-Changde-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CGQ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Changchun",
            "name": "[CGQ] - Changchun - China",
            "searchName": "CGQ-Changchun-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CGO",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Zhengzhou",
            "name": "[CGO] - Zhengzhou - China",
            "searchName": "CGO-Zhengzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CHG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Chaoyang",
            "name": "[CHG] - Chaoyang - China",
            "searchName": "CHG-Chaoyang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CNI",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Changhai",
            "name": "[CNI] - Changhai - China",
            "searchName": "CNI-Changhai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CKG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Chongqing",
            "name": "[CKG] - Chongqing - China",
            "searchName": "CKG-Chongqing-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DIG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Diqing",
            "name": "[DIG] - Diqing - China",
            "searchName": "DIG-Diqing-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DEY",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Deyang",
            "name": "[DEY] - Deyang - China",
            "searchName": "DEY-Deyang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DDG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Dandong",
            "name": "[DDG] - Dandong - China",
            "searchName": "DDG-Dandong-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DAT",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Datong",
            "name": "[DAT] - Datong - China",
            "searchName": "DAT-Datong-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CZX",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Changzhou",
            "name": "[CZX] - Changzhou - China",
            "searchName": "CZX-Changzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DAX",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Daxian",
            "name": "[DAX] - Daxian - China",
            "searchName": "DAX-Daxian-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DCY",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Daocheng",
            "name": "[DCY] - Daocheng - China",
            "searchName": "DCY-Daocheng-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CSX",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Changsha",
            "name": "[CSX] - Changsha - China",
            "searchName": "CSX-Changsha-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CTU",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Chengdu",
            "name": "[CTU] - Chengdu - China",
            "searchName": "CTU-Chengdu-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KCA",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Kuqa",
            "name": "[KCA] - Kuqa - China",
            "searchName": "KCA-Kuqa-Koutcha-Kuga-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KGT",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Kangding",
            "name": "[KGT] - Kangding - China",
            "searchName": "KGT-Kangding-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KHG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Kashi",
            "name": "[KHG] - Kashi - China",
            "searchName": "KHG-Kaschgar-Kashi-Kashgar-Kachgar-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KHN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Nanchang",
            "name": "[KHN] - Nanchang - China",
            "searchName": "KHN-Nanchang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KJI",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Burqin",
            "name": "[KJI] - Burqin - China",
            "searchName": "KJI-Burqin-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KMG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Kunming",
            "name": "[KMG] - Kunming - China",
            "searchName": "KMG-Kunming-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KOW",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Ganzhou",
            "name": "[KOW] - Ganzhou - China",
            "searchName": "KOW-Ganzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KWE",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Guiyang",
            "name": "[KWE] - Guiyang - China",
            "searchName": "KWE-Guiyang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KRL",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Korla",
            "name": "[KRL] - Korla - China",
            "searchName": "KRL-Korla-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KRY",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Karamay",
            "name": "[KRY] - Karamay - China",
            "searchName": "KRY-Karamay-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LCX",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Longyan",
            "name": "[LCX] - Longyan - China",
            "searchName": "LCX-Longyan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LDS",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Yichun",
            "name": "[LDS] - Yichun - China",
            "searchName": "LDS-Yichun-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KWL",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Guilin",
            "name": "[KWL] - Guilin - China",
            "searchName": "KWL-Guilin-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LHW",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Lanzhou",
            "name": "[LHW] - Lanzhou - China",
            "searchName": "LHW-Lanzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LIA",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Liangping",
            "name": "[LIA] - Liangping - China",
            "searchName": "LIA-Liangping-Lianping-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LHK",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Guanghua",
            "name": "[LHK] - Guanghua - China",
            "searchName": "LHK-Guanghua-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LJG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Lijiang City",
            "name": "[LJG] - Lijiang City - China",
            "searchName": "LJG-Lijiang City-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LLF",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Lingling",
            "name": "[LLF] - Lingling - China",
            "searchName": "LLF-Lingling-Ling Ling-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LLB",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Libo",
            "name": "[LLB] - Libo - China",
            "searchName": "LLB-Libo-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LNJ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Lincang",
            "name": "[LNJ] - Lincang - China",
            "searchName": "LNJ-Lincang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LQS",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Lingshui",
            "name": "[LQS] - Lingshui - China",
            "searchName": "LQS-Lingshui-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LUM",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Mangshi",
            "name": "[LUM] - Mangshi - China",
            "searchName": "LUM-Luxi-Mangshi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "MIG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Mian Yang",
            "name": "[MIG] - Mian Yang - China",
            "searchName": "MIG-Mian Yang-Mianyang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LZH",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Liuzhou",
            "name": "[LZH] - Liuzhou - China",
            "searchName": "LZH-Liuzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LZO",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Luzhou",
            "name": "[LZO] - Luzhou - China",
            "searchName": "LZO-Luzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LYI",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Linyi",
            "name": "[LYI] - Linyi - China",
            "searchName": "LYI-Linyi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LZY",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Lin Zhi",
            "name": "[LZY] - Lin Zhi - China",
            "searchName": "LZY-Lin Zhi-Mainling-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "SYX",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Sanya",
            "name": "[SYX] - Sanya - China",
            "searchName": "SYX-Sanya-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "SYM",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Simao",
            "name": "[SYM] - Simao - China",
            "searchName": "SYM-Simao-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TCG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Tacheng",
            "name": "[TCG] - Tacheng - China",
            "searchName": "TCG-Tacheng-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TAO",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Qingdao",
            "name": "[TAO] - Qingdao - China",
            "searchName": "TAO-Qingdao-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "SZV",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Suzhou",
            "name": "[SZV] - Suzhou - China",
            "searchName": "SZV-Suzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "SWA",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Shantou",
            "name": "[SWA] - Shantou - China",
            "searchName": "SWA-Shantou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WEF",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Weifang",
            "name": "[WEF] - Weifang - China",
            "searchName": "WEF-Weifang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WEH",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Weihai",
            "name": "[WEH] - Weihai - China",
            "searchName": "WEH-Weihai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WEC",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Wenchang",
            "name": "[WEC] - Wenchang - China",
            "searchName": "WEC-Wenchang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "UYN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Yulin",
            "name": "[UYN] - Yulin - China",
            "searchName": "UYN-Yulin-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TSN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Tianjin",
            "name": "[TSN] - Tianjin - China",
            "searchName": "TSN-Tianjin-Tianjn-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TVS",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Tangshan",
            "name": "[TVS] - Tangshan - China",
            "searchName": "TVS-Tangshan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TYN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Taiyuan",
            "name": "[TYN] - Taiyuan - China",
            "searchName": "TYN-Taiyuan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TXN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Tunxi",
            "name": "[TXN] - Tunxi - China",
            "searchName": "TXN-Tunxi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "URC",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Urumqi",
            "name": "[URC] - Urumqi - China",
            "searchName": "URC-Urumqi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YIE",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Aershan",
            "name": "[YIE] - Aershan - China",
            "searchName": "YIE-Aershan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YBP",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Yibin",
            "name": "[YBP] - Yibin - China",
            "searchName": "YBP-Yibin-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YCU",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Yun Cheng",
            "name": "[YCU] - Yun Cheng - China",
            "searchName": "YCU-Yuncheng-Yun Cheng-Cullaton G-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XUZ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Xuzhou",
            "name": "[XUZ] - Xuzhou - China",
            "searchName": "XUZ-Xuzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XMN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Xiamen",
            "name": "[XMN] - Xiamen - China",
            "searchName": "XMN-Xiamen-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XNN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Xining",
            "name": "[XNN] - Xining - China",
            "searchName": "XNN-Xining-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XNT",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Xingtai",
            "name": "[XNT] - Xingtai - China",
            "searchName": "XNT-Xingtai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WUS",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Wuyishan",
            "name": "[WUS] - Wuyishan - China",
            "searchName": "WUS-Wuyishan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WUH",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Wuhan",
            "name": "[WUH] - Wuhan - China",
            "searchName": "WUH-Wuhan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WUA",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Wu Hai",
            "name": "[WUA] - Wu Hai - China",
            "searchName": "WUA-Wu Hai-Wuhai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WUX",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Wuxi",
            "name": "[WUX] - Wuxi - China",
            "searchName": "WUX-Wuxi Jiangsu-Wuxi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WUZ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Wuzhou",
            "name": "[WUZ] - Wuzhou - China",
            "searchName": "WUZ-Wuzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WNH",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Wenshan",
            "name": "[WNH] - Wenshan - China",
            "searchName": "WNH-Wenshan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WNZ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Wenzhou",
            "name": "[WNZ] - Wenzhou - China",
            "searchName": "WNZ-Wenzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WHU",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Wuhu",
            "name": "[WHU] - Wuhu - China",
            "searchName": "WHU-Wuhu-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XIC",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Xichang",
            "name": "[XIC] - Xichang - China",
            "searchName": "XIC-Xichang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XHO",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Dongguan",
            "name": "[XHO] - Dongguan - China",
            "searchName": "XHO-Dongguan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XIN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Xingning",
            "name": "[XIN] - Xingning - China",
            "searchName": "XIN-Xingning-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XIL",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Xilinhot",
            "name": "[XIL] - Xilinhot - China",
            "searchName": "XIL-Xilin Hot-Xilinhot-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XFN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Xiangfan",
            "name": "[XFN] - Xiangfan - China",
            "searchName": "XFN-Xiangfan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XEN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Xingcheng",
            "name": "[XEN] - Xingcheng - China",
            "searchName": "XEN-Xingcheng-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WXJ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Wanning",
            "name": "[WXJ] - Wanning - China",
            "searchName": "WXJ-Wanning-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WXN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Wanxian",
            "name": "[WXN] - Wanxian - China",
            "searchName": "WXN-Wanxian-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZEF",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Nanhai",
            "name": "[ZEF] - Nanhai - China",
            "searchName": "ZEF-Nanhai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZBZ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Xin Hui",
            "name": "[ZBZ] - Xin Hui - China",
            "searchName": "ZBZ-Xin Hui-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZCA",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Tai Shan",
            "name": "[ZCA] - Tai Shan - China",
            "searchName": "ZCA-Tai Shan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YUS",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Yushu",
            "name": "[YUS] - Yushu - China",
            "searchName": "YUS-Yushu-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZAS",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Shunde",
            "name": "[ZAS] - Shunde - China",
            "searchName": "ZAS-Shunde-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZAT",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Zhaotong",
            "name": "[ZAT] - Zhaotong - China",
            "searchName": "ZAT-Zhaotong-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZAX",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Pan Yu",
            "name": "[ZAX] - Pan Yu - China",
            "searchName": "ZAX-Pan Yu-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZBD",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Jiang Men",
            "name": "[ZBD] - Jiang Men - China",
            "searchName": "ZBD-Jiang Men-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZIY",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Lianhuashan",
            "name": "[ZIY] - Lianhuashan - China",
            "searchName": "ZIY-Lianhuashan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZHY",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Zhongwei",
            "name": "[ZHY] - Zhongwei - China",
            "searchName": "ZHY-Zhongwei-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZGN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Zhongshan",
            "name": "[ZGN] - Zhongshan - China",
            "searchName": "ZGN-Zhongshan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZHA",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Zhanjiang",
            "name": "[ZHA] - Zhanjiang - China",
            "searchName": "ZHA-Zhanjiang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZMY",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Huangpu",
            "name": "[ZMY] - Huangpu - China",
            "searchName": "ZMY-Huangpu-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZUH",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Zhuhai",
            "name": "[ZUH] - Zhuhai - China",
            "searchName": "ZUH-Zhuhai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZYI",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Zunyi",
            "name": "[ZYI] - Zunyi - China",
            "searchName": "ZYI-Zunyi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZYK",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Shekou",
            "name": "[ZYK] - Shekou - China",
            "searchName": "ZYK-Shekou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZTI",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Humen",
            "name": "[ZTI] - Humen - China",
            "searchName": "ZTI-Humen-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZQZ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Zhangjiakou",
            "name": "[ZQZ] - Zhangjiakou - China",
            "searchName": "ZQZ-Zhangjiakou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YZY",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Zhangye",
            "name": "[YZY] - Zhangye - China",
            "searchName": "YZY-Zhangye-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YNZ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Yancheng",
            "name": "[YNZ] - Yancheng - China",
            "searchName": "YNZ-Yancheng-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YNJ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Yanji",
            "name": "[YNJ] - Yanji - China",
            "searchName": "YNJ-Yanji-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YNT",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Yantai",
            "name": "[YNT] - Yantai - China",
            "searchName": "YNT-Yantai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YTY",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Yangzhou",
            "name": "[YTY] - Yangzhou - China",
            "searchName": "YTY-Yangzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YUA",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Yuanmou",
            "name": "[YUA] - Yuanmou - China",
            "searchName": "YUA-Yuanmou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YIW",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Yiwu",
            "name": "[YIW] - Yiwu - China",
            "searchName": "YIW-Yiwu-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YIN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Yining",
            "name": "[YIN] - Yining - China",
            "searchName": "YIN-Yining-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YIH",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Yichang",
            "name": "[YIH] - Yichang - China",
            "searchName": "YIH-Yichang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YLN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Yilan",
            "name": "[YLN] - Yilan - China",
            "searchName": "YLN-Yilan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "URI",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Uribe",
            "name": "[URI] - Uribe - Colombia",
            "searchName": "URI-Uribe-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "URR",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Urrao",
            "name": "[URR] - Urrao - Colombia",
            "searchName": "URR-Urrao-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "UNC",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Unguia",
            "name": "[UNC] - Unguia - Colombia",
            "searchName": "UNC-Unguia-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ULQ",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Tulua",
            "name": "[ULQ] - Tulua - Colombia",
            "searchName": "ULQ-Tulua-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ULS",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Mulatos",
            "name": "[ULS] - Mulatos - Colombia",
            "searchName": "ULS-Mulatos-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "UIB",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Quibdo",
            "name": "[UIB] - Quibdo - Colombia",
            "searchName": "UIB-Quibdo-Quibd-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "TTM",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Tablon De Tamara",
            "name": "[TTM] - Tablon De Tamara - Colombia",
            "searchName": "TTM-Tablon de Tamara-Tablon De Tamara-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "VAB",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Yavarate",
            "name": "[VAB] - Yavarate - Colombia",
            "searchName": "VAB-Yavarate-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "VGZ",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Villagarzon",
            "name": "[VGZ] - Villagarzon - Colombia",
            "searchName": "VGZ-Villagarzon-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "VUP",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Valledupar",
            "name": "[VUP] - Valledupar - Colombia",
            "searchName": "VUP-Valledupar-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "VVC",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Villavicencio",
            "name": "[VVC] - Villavicencio - Colombia",
            "searchName": "VVC-Villavicencio-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SVI",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "San Vicente",
            "name": "[SVI] - San Vicente - Colombia",
            "searchName": "SVI-San Vicente-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SSL",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Santa Rosalia",
            "name": "[SSL] - Santa Rosalia - Colombia",
            "searchName": "SSL-Santa Rosalia-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SSD",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "San Felipe",
            "name": "[SSD] - San Felipe - Colombia",
            "searchName": "SSD-San Felipe-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SRS",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "San Marcos",
            "name": "[SRS] - San Marcos - Colombia",
            "searchName": "SRS-San Marcos-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SRO",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Santana Ramos",
            "name": "[SRO] - Santana Ramos - Colombia",
            "searchName": "SRO-Santana Ramos-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "TBD",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Timbiqui",
            "name": "[TBD] - Timbiqui - Colombia",
            "searchName": "TBD-Timbiqui-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "TAU",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Tauramena",
            "name": "[TAU] - Tauramena - Colombia",
            "searchName": "TAU-Tauramena-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "TCD",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Tarapaca",
            "name": "[TCD] - Tarapaca - Colombia",
            "searchName": "TCD-Tarapaca-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "TCO",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Tumaco",
            "name": "[TCO] - Tumaco - Colombia",
            "searchName": "TCO-Tumaco-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "TME",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Tame",
            "name": "[TME] - Tame - Colombia",
            "searchName": "TME-Tame-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "TLU",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Tolu",
            "name": "[TLU] - Tolu - Colombia",
            "searchName": "TLU-Tolu-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "TQS",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Tres Esquinas",
            "name": "[TQS] - Tres Esquinas - Colombia",
            "searchName": "TQS-Tres Esquinas-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "TRB",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Turbo",
            "name": "[TRB] - Turbo - Colombia",
            "searchName": "TRB-Turbo-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "TDA",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Trinidad",
            "name": "[TDA] - Trinidad - Colombia",
            "searchName": "TDA-Trinidad-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "TIB",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Tibu",
            "name": "[TIB] - Tibu - Colombia",
            "searchName": "TIB-Tibu-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PZA",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Paz De Ariporo",
            "name": "[PZA] - Paz De Ariporo - Colombia",
            "searchName": "PZA-Paz de Ariporo-Paz De Ariporo-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PYN",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Payan",
            "name": "[PYN] - Payan - Colombia",
            "searchName": "PYN-Payan-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PVA",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Providencia",
            "name": "[PVA] - Providencia - Colombia",
            "searchName": "PVA-Providencia-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PYA",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Puerto Boyaca",
            "name": "[PYA] - Puerto Boyaca - Colombia",
            "searchName": "PYA-Puerto Boyaca-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "RAV",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Cravo Norte",
            "name": "[RAV] - Cravo Norte - Colombia",
            "searchName": "RAV-Cravo Norte-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "RCH",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Riohacha",
            "name": "[RCH] - Riohacha - Colombia",
            "searchName": "RCH-Riohacha-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PLT",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Plato",
            "name": "[PLT] - Plato - Colombia",
            "searchName": "PLT-Plato-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PLA",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Planadas",
            "name": "[PLA] - Planadas - Colombia",
            "searchName": "PLA-Planadas-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PLC",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Planeta Rica",
            "name": "[PLC] - Planeta Rica - Colombia",
            "searchName": "PLC-Planeta Rica-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PUU",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Puerto Asis",
            "name": "[PUU] - Puerto Asis - Colombia",
            "searchName": "PUU-Puerto Asis-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PTX",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Pitalito",
            "name": "[PTX] - Pitalito - Colombia",
            "searchName": "PTX-Pitalito-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PSO",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Pasto",
            "name": "[PSO] - Pasto - Colombia",
            "searchName": "PSO-Pasto-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PPN",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Popayan",
            "name": "[PPN] - Popayan - Colombia",
            "searchName": "PPN-Popayan-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PRE",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Pore",
            "name": "[PRE] - Pore - Colombia",
            "searchName": "PRE-Pore-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "RON",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Rondon",
            "name": "[RON] - Rondon - Colombia",
            "searchName": "RON-Rondon-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "RVE",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Saravena",
            "name": "[RVE] - Saravena - Colombia",
            "searchName": "RVE-Saravena-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SCA",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Santa Catalina",
            "name": "[SCA] - Santa Catalina - Colombia",
            "searchName": "SCA-Santa Catalina-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SJR",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "San Juan D Ur",
            "name": "[SJR] - San Juan D Ur - Colombia",
            "searchName": "SJR-San Juan de Uraba-San Juan D Ur-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SJE",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "San Jose Del Gua",
            "name": "[SJE] - San Jose Del Gua - Colombia",
            "searchName": "SJE-San Jose Del Gua-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SJG",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "San Pedro Jagua",
            "name": "[SJG] - San Pedro Jagua - Colombia",
            "searchName": "SJG-San Pedro Jagua-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SJH",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "San Juan Del Cesar",
            "name": "[SJH] - San Juan Del Cesar - Colombia",
            "searchName": "SJH-San Juan del Cesar-San Juan Del Cesar-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SMC",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Santa Maria",
            "name": "[SMC] - Santa Maria - Colombia",
            "searchName": "SMC-Santa Maria-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SMR",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Santa Marta",
            "name": "[SMR] - Santa Marta - Colombia",
            "searchName": "SMR-Santa Marta-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SQB",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Santa Ana",
            "name": "[SQB] - Santa Ana - Colombia",
            "searchName": "SQB-Santa Ana-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SQE",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "San Luis De Pale",
            "name": "[SQE] - San Luis De Pale - Colombia",
            "searchName": "SQE-San Luis De Pale-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SQF",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Solano",
            "name": "[SQF] - Solano - Colombia",
            "searchName": "SQF-Solano-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SOX",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Sogamoso",
            "name": "[SOX] - Sogamoso - Colombia",
            "searchName": "SOX-Sogamoso-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SNT",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Sabana De Torres",
            "name": "[SNT] - Sabana De Torres - Colombia",
            "searchName": "SNT-Sabana de Torres-Sabana De Torres-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SOH",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Solita",
            "name": "[SOH] - Solita - Colombia",
            "searchName": "SOH-Solita-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "NPU",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "San Pedro Uraba",
            "name": "[NPU] - San Pedro Uraba - Colombia",
            "searchName": "NPU-San Pedro Uraba-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "NQU",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Nuqui",
            "name": "[NQU] - Nuqui - Colombia",
            "searchName": "NQU-Nuqui-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "NUH",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Nunchia",
            "name": "[NUH] - Nunchia - Colombia",
            "searchName": "NUH-Nunchia-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "NVA",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Neiva",
            "name": "[NVA] - Neiva - Colombia",
            "searchName": "NVA-Neiva-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "OCV",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Ocana",
            "name": "[OCV] - Ocana - Colombia",
            "searchName": "OCV-Ocana-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "OTU",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Otu",
            "name": "[OTU] - Otu - Colombia",
            "searchName": "OTU-Otu-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ORC",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Orocue",
            "name": "[ORC] - Orocue - Colombia",
            "searchName": "ORC-Orocue-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PEI",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Pereira",
            "name": "[PEI] - Pereira - Colombia",
            "searchName": "PEI-Pereira-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PDA",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Puerto Inirida",
            "name": "[PDA] - Puerto Inirida - Colombia",
            "searchName": "PDA-Puerto Inirida-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PBE",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Puerto Berrio",
            "name": "[PBE] - Puerto Berrio - Colombia",
            "searchName": "PBE-Puerto Berrio-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PAL",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Palanquero",
            "name": "[PAL] - Palanquero - Colombia",
            "searchName": "PAL-Palanquero-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PCR",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Puerto Carreno",
            "name": "[PCR] - Puerto Carreno - Colombia",
            "searchName": "PCR-Puerto Carreno-Puerto Carre-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PCC",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Puerto Rico",
            "name": "[PCC] - Puerto Rico - Colombia",
            "searchName": "PCC-Puerto Rico-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "NCI",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Necocli",
            "name": "[NCI] - Necocli - Colombia",
            "searchName": "NCI-Necocli-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "NAR",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Nare",
            "name": "[NAR] - Nare - Colombia",
            "searchName": "NAR-Nare-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "NBB",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Barrancominas",
            "name": "[NBB] - Barrancominas - Colombia",
            "searchName": "NBB-Barrancominas-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "NAD",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Macanal",
            "name": "[NAD] - Macanal - Colombia",
            "searchName": "NAD-Macanal-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MZL",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Manizales",
            "name": "[MZL] - Manizales - Colombia",
            "searchName": "MZL-Manizales-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MVP",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Mitu",
            "name": "[MVP] - Mitu - Colombia",
            "searchName": "MVP-Mitu-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MIX",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Miriti",
            "name": "[MIX] - Miriti - Colombia",
            "searchName": "MIX-Miriti-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MOY",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Monterrey",
            "name": "[MOY] - Monterrey - Colombia",
            "searchName": "MOY-Monterrey-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MMP",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Mompos",
            "name": "[MMP] - Mompos - Colombia",
            "searchName": "MMP-Mompos-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MND",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Medina",
            "name": "[MND] - Medina - Colombia",
            "searchName": "MND-Medina-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MTB",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Monte Libano",
            "name": "[MTB] - Monte Libano - Colombia",
            "searchName": "MTB-Monte Libano-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MTR",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Monteria",
            "name": "[MTR] - Monteria - Colombia",
            "searchName": "MTR-Monteria-Monter-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MQR",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Mosquera",
            "name": "[MQR] - Mosquera - Colombia",
            "searchName": "MQR-Mosquera-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MQU",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Mariquita",
            "name": "[MQU] - Mariquita - Colombia",
            "searchName": "MQU-Mariquita-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "JUO",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Jurado",
            "name": "[JUO] - Jurado - Colombia",
            "searchName": "JUO-Jurado-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "IVO",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Chivolo",
            "name": "[IVO] - Chivolo - Colombia",
            "searchName": "IVO-Chivolo-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ISD",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Iscuande",
            "name": "[ISD] - Iscuande - Colombia",
            "searchName": "ISD-Iscuande-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "HRR",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Herrera",
            "name": "[HRR] - Herrera - Colombia",
            "searchName": "HRR-Herrera-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "HTZ",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Hato Corozal",
            "name": "[HTZ] - Hato Corozal - Colombia",
            "searchName": "HTZ-Hato Corozal-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "IBE",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Ibague",
            "name": "[IBE] - Ibague - Colombia",
            "searchName": "IBE-Ibague-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "IPI",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Ipiales",
            "name": "[IPI] - Ipiales - Colombia",
            "searchName": "IPI-Ipiales-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "IGO",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Chigorodo",
            "name": "[IGO] - Chigorodo - Colombia",
            "searchName": "IGO-Chigorodo-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "GAA",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Guamal",
            "name": "[GAA] - Guamal - Colombia",
            "searchName": "GAA-Guamal-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "GCA",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Guacamaya",
            "name": "[GCA] - Guacamaya - Colombia",
            "searchName": "GCA-Guacamaya-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "GGL",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Gilgal",
            "name": "[GGL] - Gilgal - Colombia",
            "searchName": "GGL-Gilgal-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "GIR",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Girardot",
            "name": "[GIR] - Girardot - Colombia",
            "searchName": "GIR-Girardot-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "GPI",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Guapi",
            "name": "[GPI] - Guapi - Colombia",
            "searchName": "GPI-Guapi-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "GRA",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Gamarra",
            "name": "[GRA] - Gamarra - Colombia",
            "searchName": "GRA-Gamarra-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "GMC",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Guerima",
            "name": "[GMC] - Guerima - Colombia",
            "searchName": "GMC-Guerima-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MDE",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Medellin",
            "name": "[MDE] - Medellin - Colombia",
            "searchName": "MDE-Medell-Medellin-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MCJ",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Maicao",
            "name": "[MCJ] - Maicao - Colombia",
            "searchName": "MCJ-Maicao-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MHF",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Morichal",
            "name": "[MHF] - Morichal - Colombia",
            "searchName": "MHF-Morichal-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MGN",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Magangue",
            "name": "[MGN] - Magangue - Colombia",
            "searchName": "MGN-Magangue-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MFS",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Miraflores",
            "name": "[MFS] - Miraflores - Colombia",
            "searchName": "MFS-Miraflores-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MFB",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Monfort",
            "name": "[MFB] - Monfort - Colombia",
            "searchName": "MFB-Monfort-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "LQM",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Puerto Leguizamo",
            "name": "[LQM] - Puerto Leguizamo - Colombia",
            "searchName": "LQM-Puerto Leguizamo-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "LRI",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Lorica",
            "name": "[LRI] - Lorica - Colombia",
            "searchName": "LRI-Lorica-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "LPD",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "La Pedrera",
            "name": "[LPD] - La Pedrera - Colombia",
            "searchName": "LPD-La Pedrera-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "LPE",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "La Primavera",
            "name": "[LPE] - La Primavera - Colombia",
            "searchName": "LPE-La Primavera-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "LMC",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Lamacarena",
            "name": "[LMC] - Lamacarena - Colombia",
            "searchName": "LMC-Lamacarena-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "LMX",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Lopez De Micay",
            "name": "[LMX] - Lopez De Micay - Colombia",
            "searchName": "LMX-Lopez De Micay-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "LGZ",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Leguizamo",
            "name": "[LGZ] - Leguizamo - Colombia",
            "searchName": "LGZ-Leguizamo-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "LGT",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Las Gaviotas",
            "name": "[LGT] - Las Gaviotas - Colombia",
            "searchName": "LGT-Las Gaviotas-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "LET",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Leticia",
            "name": "[LET] - Leticia - Colombia",
            "searchName": "LET-Leticia-L-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "LAT",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "La Uribe",
            "name": "[LAT] - La Uribe - Colombia",
            "searchName": "LAT-La Uribe-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "LCR",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "La Chorrera",
            "name": "[LCR] - La Chorrera - Colombia",
            "searchName": "LCR-La Chorrera-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CTG",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Cartagena",
            "name": "[CTG] - Cartagena - Colombia",
            "searchName": "CTG-Bocagrande-Cartagena-Cartag-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CSR",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Casuarito",
            "name": "[CSR] - Casuarito - Colombia",
            "searchName": "CSR-Casuarito-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CRC",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Cartago",
            "name": "[CRC] - Cartago - Colombia",
            "searchName": "CRC-Cartago-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CUO",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Caruru",
            "name": "[CUO] - Caruru - Colombia",
            "searchName": "CUO-Caruru-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CUI",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Currillo",
            "name": "[CUI] - Currillo - Colombia",
            "searchName": "CUI-Currillo-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CUC",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Cucuta",
            "name": "[CUC] - Cucuta - Colombia",
            "searchName": "CUC-C-Cucuta-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CVE",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Covenas",
            "name": "[CVE] - Covenas - Colombia",
            "searchName": "CVE-Covenas-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CZU",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Corozal",
            "name": "[CZU] - Corozal - Colombia",
            "searchName": "CZU-Corozal-Las Brujas-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CLO",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Cali",
            "name": "[CLO] - Cali - Colombia",
            "searchName": "CLO-Cali-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "COG",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Condoto",
            "name": "[COG] - Condoto - Colombia",
            "searchName": "COG-Condoto-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CQT",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Caquetania",
            "name": "[CQT] - Caquetania - Colombia",
            "searchName": "CQT-Caquetania-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CPL",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Chaparral",
            "name": "[CPL] - Chaparral - Colombia",
            "searchName": "CPL-Chaparral-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CPB",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Capurgana",
            "name": "[CPB] - Capurgana - Colombia",
            "searchName": "CPB-Capurgana-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CJD",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Candilejas",
            "name": "[CJD] - Candilejas - Colombia",
            "searchName": "CJD-Candilejas-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CIM",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Cimitarra",
            "name": "[CIM] - Cimitarra - Colombia",
            "searchName": "CIM-Cimitarra-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CCO",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Carimagua",
            "name": "[CCO] - Carimagua - Colombia",
            "searchName": "CCO-Carimagua-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "EUO",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Paratebueno",
            "name": "[EUO] - Paratebueno - Colombia",
            "searchName": "EUO-Paratebueno-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "EYP",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "El Yopal",
            "name": "[EYP] - El Yopal - Colombia",
            "searchName": "EYP-El Yopal-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "FLA",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Florencia",
            "name": "[FLA] - Florencia - Colombia",
            "searchName": "FLA-Florencia-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "DZI",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Codazzi",
            "name": "[DZI] - Codazzi - Colombia",
            "searchName": "DZI-Codazzi-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "EBG",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "El Bagre",
            "name": "[EBG] - El Bagre - Colombia",
            "searchName": "EBG-El Bagre-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ECO",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "El Encanto",
            "name": "[ECO] - El Encanto - Colombia",
            "searchName": "ECO-El Encanto-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ECR",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "El Charco",
            "name": "[ECR] - El Charco - Colombia",
            "searchName": "ECR-El Charco-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ELJ",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "El Recreo",
            "name": "[ELJ] - El Recreo - Colombia",
            "searchName": "ELJ-El Recreo-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ELB",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "El Banco",
            "name": "[ELB] - El Banco - Colombia",
            "searchName": "ELB-El Banco-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "EJA",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Barrancabermeja",
            "name": "[EJA] - Barrancabermeja - Colombia",
            "searchName": "EJA-Barrancabermeja-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ADZ",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "San Andres Island",
            "name": "[ADZ] - San Andres Island - Colombia",
            "searchName": "ADZ-San Andres Island--Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ADN",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Andes",
            "name": "[ADN] - Andes - Colombia",
            "searchName": "ADN-Andes-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "AFI",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Amalfi",
            "name": "[AFI] - Amalfi - Colombia",
            "searchName": "AFI-Amalfi-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ACR",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Araracuara",
            "name": "[ACR] - Araracuara - Colombia",
            "searchName": "ACR-Araracuara-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ACD",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Acandi",
            "name": "[ACD] - Acandi - Colombia",
            "searchName": "ACD-Acandi-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ACL",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Aguaclara",
            "name": "[ACL] - Aguaclara - Colombia",
            "searchName": "ACL-Aguaclara-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ACM",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Arica",
            "name": "[ACM] - Arica - Colombia",
            "searchName": "ACM-Arica-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "APO",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Apartado",
            "name": "[APO] - Apartado - Colombia",
            "searchName": "APO-Apartado-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "API",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Apiay",
            "name": "[API] - Apiay - Colombia",
            "searchName": "API-Apiay-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ARF",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Acaricuara",
            "name": "[ARF] - Acaricuara - Colombia",
            "searchName": "ARF-Acaricuara-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ARQ",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Arauquita",
            "name": "[ARQ] - Arauquita - Colombia",
            "searchName": "ARQ-Arauquita-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ARO",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Arboletas",
            "name": "[ARO] - Arboletas - Colombia",
            "searchName": "ARO-Arboletas-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "AUC",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Arauca",
            "name": "[AUC] - Arauca - Colombia",
            "searchName": "AUC-Arauca-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "AYG",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Yaguara",
            "name": "[AYG] - Yaguara - Colombia",
            "searchName": "AYG-Yaguara-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "AYI",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Yari",
            "name": "[AYI] - Yari - Colombia",
            "searchName": "AYI-Yari-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "AYA",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Ayapel",
            "name": "[AYA] - Ayapel - Colombia",
            "searchName": "AYA-Ayapel-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "AYC",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Ayacucho",
            "name": "[AYC] - Ayacucho - Colombia",
            "searchName": "AYC-Ayacucho-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "BAC",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Barranca De Upia",
            "name": "[BAC] - Barranca De Upia - Colombia",
            "searchName": "BAC-Barranca de Upia-Barranca De Upia-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "AZT",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Zapatoca",
            "name": "[AZT] - Zapatoca - Colombia",
            "searchName": "AZT-Zapatoca-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "AXM",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Armenia",
            "name": "[AXM] - Armenia - Colombia",
            "searchName": "AXM-Armenia-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "BAQ",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Barranquilla",
            "name": "[BAQ] - Barranquilla - Colombia",
            "searchName": "BAQ-Barranquilla-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CAQ",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Caucasia",
            "name": "[CAQ] - Caucasia - Colombia",
            "searchName": "CAQ-Caucasia-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "BSC",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Bahia Solano",
            "name": "[BSC] - Bahia Solano - Colombia",
            "searchName": "BSC-Bahia Solano-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "BUN",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Buenaventura",
            "name": "[BUN] - Buenaventura - Colombia",
            "searchName": "BUN-Buenaventura-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "BHF",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Bahia Cupica",
            "name": "[BHF] - Bahia Cupica - Colombia",
            "searchName": "BHF-Bahia Cupica-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "BGA",
            "countryCode": "CO",
            "cityCode": null,
            "cityName": "Bucaramanga",
            "name": "[BGA] - Bucaramanga - Colombia",
            "searchName": "BGA-Bucaramanga-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "OLO",
            "countryCode": "CZ",
            "cityCode": null,
            "cityName": "Olomouc",
            "name": "[OLO] - Olomouc - Czech Republic",
            "searchName": "OLO-Olomouc-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "GEC",
            "countryCode": "CY",
            "cityCode": null,
            "cityName": "Gecitkale",
            "name": "[GEC] - Gecitkale - Cyprus",
            "searchName": "GEC-Gecitkale-Ciprus-Cypr-Siprus-Cyprus-Síp-Kipra-Kypros-Küpros-Cipar-Xipre-Kypr-Cypern-Chipre-Zypern-Chypre-Kibris-Cipru-Kipras-Cipro",
            "priority": 0
        },
        {
            "iataCode": "GTW",
            "countryCode": "CZ",
            "cityCode": null,
            "cityName": "Zlin",
            "name": "[GTW] - Zlin - Czech Republic",
            "searchName": "GTW-Zlin-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "NOD",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Norden",
            "name": "[NOD] - Norden - Germany",
            "searchName": "NOD-Norden-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "NRD",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Norderney",
            "name": "[NRD] - Norderney - Germany",
            "searchName": "NRD-Norderney-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "PEF",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Peenemuende",
            "name": "[PEF] - Peenemuende - Germany",
            "searchName": "PEF-Peenem-Peenemuende-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "PAD",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Paderborn",
            "name": "[PAD] - Paderborn - Germany",
            "searchName": "PAD-Paderborn-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "PRV",
            "countryCode": "CZ",
            "cityCode": null,
            "cityName": "Prerov",
            "name": "[PRV] - Prerov - Czech Republic",
            "searchName": "PRV-Prerov-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "ZBE",
            "countryCode": "CZ",
            "cityCode": null,
            "cityName": "Zabreh",
            "name": "[ZBE] - Zabreh - Czech Republic",
            "searchName": "ZBE-Zabreh-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "UHE",
            "countryCode": "CZ",
            "cityCode": null,
            "cityName": "Uherske Hradiste",
            "name": "[UHE] - Uherske Hradiste - Czech Republic",
            "searchName": "UHE-Uherske Hradiste-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "URD",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Burg Feuerstein",
            "name": "[URD] - Burg Feuerstein - Germany",
            "searchName": "URD-Burg Feuerstein-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "WBG",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Schleswig-jagel",
            "name": "[WBG] - Schleswig-jagel - Germany",
            "searchName": "WBG-Schleswig-jagel-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "VAC",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Varrelbusch",
            "name": "[VAC] - Varrelbusch - Germany",
            "searchName": "VAC-Varrelbusch-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "UWP",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Wuppertal",
            "name": "[UWP] - Wuppertal - Germany",
            "searchName": "UWP-Wuppertal-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "SZW",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Schwerin",
            "name": "[SZW] - Schwerin - Germany",
            "searchName": "SZW-Schwerin-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "AGE",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Wangerooge",
            "name": "[AGE] - Wangerooge - Germany",
            "searchName": "AGE-Wangerooge-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "BBJ",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Bitburg",
            "name": "[BBJ] - Bitburg - Germany",
            "searchName": "BBJ-Bitburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "BBH",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Barth",
            "name": "[BBH] - Barth - Germany",
            "searchName": "BBH-Barth-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "EIB",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Eisenach",
            "name": "[EIB] - Eisenach - Germany",
            "searchName": "EIB-Eisenach-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "EME",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Emden",
            "name": "[EME] - Emden - Germany",
            "searchName": "EME-Emden-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "DRS",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Dresden",
            "name": "[DRS] - Dresden - Germany",
            "searchName": "DRS-Dresden-Dresde-Dresda-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "AOC",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Altenburg",
            "name": "[AOC] - Altenburg - Germany",
            "searchName": "AOC-Altenburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "BRV",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Bremerhaven",
            "name": "[BRV] - Bremerhaven - Germany",
            "searchName": "BRV-Bremerhaven-Braavos-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "BRE",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Bremen",
            "name": "[BRE] - Bremen - Germany",
            "searchName": "BRE-Bremen-Br-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "BWE",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Braunschweig",
            "name": "[BWE] - Braunschweig - Germany",
            "searchName": "BWE-Braunschweig-Brunswick-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "BYU",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Bayreuth",
            "name": "[BYU] - Bayreuth - Germany",
            "searchName": "BYU-Bayreuth-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "CBU",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Cottbus",
            "name": "[CBU] - Cottbus - Germany",
            "searchName": "CBU-Cottbus-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "BMR",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Baltrum Island",
            "name": "[BMR] - Baltrum Island - Germany",
            "searchName": "BMR-Baltrum-Baltrum Island-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "BMK",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Borkum",
            "name": "[BMK] - Borkum - Germany",
            "searchName": "BMK-Borkum-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "BFE",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Bielefeld",
            "name": "[BFE] - Bielefeld - Germany",
            "searchName": "BFE-Bielefeld-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "GWT",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Westerland",
            "name": "[GWT] - Westerland - Germany",
            "searchName": "GWT-Westerland-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "HDB",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Heidelberg",
            "name": "[HDB] - Heidelberg - Germany",
            "searchName": "HDB-Heidelberg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "HDF",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Heringsdorf",
            "name": "[HDF] - Heringsdorf - Germany",
            "searchName": "HDF-Heringsdorf-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "HEI",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Heide-Buesum",
            "name": "[HEI] - Heide-Buesum - Germany",
            "searchName": "HEI-Heide\\/ B-Heide-Buesum-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "HGL",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Helgoland",
            "name": "[HGL] - Helgoland - Germany",
            "searchName": "HGL-Helgoland-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "FRZ",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Fritzlar",
            "name": "[FRZ] - Fritzlar - Germany",
            "searchName": "FRZ-Fritzlar-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "GKE",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Geilenkirchen",
            "name": "[GKE] - Geilenkirchen - Germany",
            "searchName": "GKE-Geilenkirchen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "GHF",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Giebelstadt",
            "name": "[GHF] - Giebelstadt - Germany",
            "searchName": "GHF-Giebelstadt-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "HOQ",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Hof",
            "name": "[HOQ] - Hof - Germany",
            "searchName": "HOQ-Hof-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ILH",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Illisheim",
            "name": "[ILH] - Illisheim - Germany",
            "searchName": "ILH-Illisheim-Illishiem-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "IES",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Riesa",
            "name": "[IES] - Riesa - Germany",
            "searchName": "IES-Riesa-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "IGS",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Ingolstadt-manching",
            "name": "[IGS] - Ingolstadt-manching - Germany",
            "searchName": "IGS-Ingolstadt-manching-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "JUI",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Juist",
            "name": "[JUI] - Juist - Germany",
            "searchName": "JUI-Juist-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "JIB",
            "countryCode": "DJ",
            "cityCode": null,
            "cityName": "Djibouti",
            "name": "[JIB] - Djibouti - Djibouti",
            "searchName": "JIB-Dschibuti-Djibouti-Yibuti-Gibuti-Cibuti-Džibutija-Džibutsko-Dzibuti-Džibutis-Džibuti-Dzsibuti-",
            "priority": 0
        },
        {
            "iataCode": "MHI",
            "countryCode": "DJ",
            "cityCode": null,
            "cityName": "Musha",
            "name": "[MHI] - Musha - Djibouti",
            "searchName": "MHI-Musha-Djibouti-Džibutija-Cibuti-Džibutsko-Dzibuti-Džibutis-Džibuti-Dzsibuti-Dschibuti-Yibuti--Gibuti",
            "priority": 0
        },
        {
            "iataCode": "AII",
            "countryCode": "DJ",
            "cityCode": null,
            "cityName": "Alisabieh",
            "name": "[AII] - Alisabieh - Djibouti",
            "searchName": "AII-Ali Sabieh-Alisabieh-Djibouti-Džibutija-Cibuti-Džibutsko-Dzibuti-Džibutis-Džibuti-Dzsibuti-Dschibuti-Yibuti--Gibuti",
            "priority": 0
        },
        {
            "iataCode": "OBC",
            "countryCode": "DJ",
            "cityCode": null,
            "cityName": "Obock",
            "name": "[OBC] - Obock - Djibouti",
            "searchName": "OBC-Obock-Djibouti-Džibutija-Cibuti-Džibutsko-Dzibuti-Džibutis-Džibuti-Dzsibuti-Dschibuti-Yibuti--Gibuti",
            "priority": 0
        },
        {
            "iataCode": "TDJ",
            "countryCode": "DJ",
            "cityCode": null,
            "cityName": "Tadjoura",
            "name": "[TDJ] - Tadjoura - Djibouti",
            "searchName": "TDJ-Tadjoura-Djibouti-Džibutija-Cibuti-Džibutsko-Dzibuti-Džibutis-Džibuti-Dzsibuti-Dschibuti-Yibuti--Gibuti",
            "priority": 0
        },
        {
            "iataCode": "TED",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Thisted",
            "name": "[TED] - Thisted - Denmark",
            "searchName": "TED-Thisted-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "SQW",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Skive",
            "name": "[SQW] - Skive - Denmark",
            "searchName": "SQW-Skive-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "STA",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Skjern",
            "name": "[STA] - Skjern - Denmark",
            "searchName": "STA-Skjern-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "XNR",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Aabenraa",
            "name": "[XNR] - Aabenraa - Denmark",
            "searchName": "XNR-Aabenraa-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "XOA",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Aalbaek",
            "name": "[XOA] - Aalbaek - Denmark",
            "searchName": "XOA-Aalbaek-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "XRA",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Graasten",
            "name": "[XRA] - Graasten - Denmark",
            "searchName": "XRA-Graasten-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "XAQ",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Bronderslev",
            "name": "[XAQ] - Bronderslev - Denmark",
            "searchName": "XAQ-Bronderslev-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "XAH",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Silkeborg",
            "name": "[XAH] - Silkeborg - Denmark",
            "searchName": "XAH-Silkeborg-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "XAJ",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Hirtshals",
            "name": "[XAJ] - Hirtshals - Denmark",
            "searchName": "XAJ-Hirtshals-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZMW",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Brande",
            "name": "[ZMW] - Brande - Denmark",
            "searchName": "ZMW-Brande-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZRY",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Herning",
            "name": "[ZRY] - Herning - Denmark",
            "searchName": "ZRY-Herning-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZIJ",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Nykobing Sjaelland",
            "name": "[ZIJ] - Nykobing Sjaelland - Denmark",
            "searchName": "ZIJ-Nykobing Sjaelland-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZIL",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Horsens",
            "name": "[ZIL] - Horsens - Denmark",
            "searchName": "ZIL-Horsens-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZIR",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Randers",
            "name": "[ZIR] - Randers - Denmark",
            "searchName": "ZIR-Randers-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZGX",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Viborg",
            "name": "[ZGX] - Viborg - Denmark",
            "searchName": "ZGX-Viborg-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZFK",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Guderup",
            "name": "[ZFK] - Guderup - Denmark",
            "searchName": "ZFK-Guderup-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZIB",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Nyborg",
            "name": "[ZIB] - Nyborg - Denmark",
            "searchName": "ZIB-Nyborg-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZBJ",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Fredericia",
            "name": "[ZBJ] - Fredericia - Denmark",
            "searchName": "ZBJ-Fredericia-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZBT",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Kolding",
            "name": "[ZBT] - Kolding - Denmark",
            "searchName": "ZBT-Kolding-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZAW",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Nykobing Mors",
            "name": "[ZAW] - Nykobing Mors - Denmark",
            "searchName": "ZAW-Nykobing Mors-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "XVX",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Vejle",
            "name": "[XVX] - Vejle - Denmark",
            "searchName": "XVX-Vejle-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ODE",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Odense",
            "name": "[ODE] - Odense - Denmark",
            "searchName": "ODE-Odense-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "MRW",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Maribo",
            "name": "[MRW] - Maribo - Denmark",
            "searchName": "MRW-Maribo-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "RNN",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Rønne",
            "name": "[RNN] - Rønne - Denmark",
            "searchName": "RNN-Bornholm-R-Rønne-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "SKS",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Vojens",
            "name": "[SKS] - Vojens - Denmark",
            "searchName": "SKS-Vojens-Skrydstrup-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "SGD",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Sonderborg",
            "name": "[SGD] - Sonderborg - Denmark",
            "searchName": "SGD-Sonderburg-Sonderborg-S-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "QWO",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Holstebro",
            "name": "[QWO] - Holstebro - Denmark",
            "searchName": "QWO-Holstebro-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "QWQ",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Struer",
            "name": "[QWQ] - Struer - Denmark",
            "searchName": "QWQ-Struer-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "QXF",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Vestbjerg",
            "name": "[QXF] - Vestbjerg - Denmark",
            "searchName": "QXF-Vestbjerg-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "QXV",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Svendborg",
            "name": "[QXV] - Svendborg - Denmark",
            "searchName": "QXV-Svendborg-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "QYQ",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Sulsted",
            "name": "[QYQ] - Sulsted - Denmark",
            "searchName": "QYQ-Sulsted-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "QHJ",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Hjorring",
            "name": "[QHJ] - Hjorring - Denmark",
            "searchName": "QHJ-Hjorring-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "QLZ",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Ikast",
            "name": "[QLZ] - Ikast - Denmark",
            "searchName": "QLZ-Ikast-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "QJV",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Skagen Z7",
            "name": "[QJV] - Skagen Z7 - Denmark",
            "searchName": "QJV-Skagen Z7-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "QJW",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Kjellerup",
            "name": "[QJW] - Kjellerup - Denmark",
            "searchName": "QJW-Kjellerup-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "QFH",
            "countryCode": "DK",
            "cityCode": null,
            "cityName": "Frederikshavn",
            "name": "[QFH] - Frederikshavn - Denmark",
            "searchName": "QFH-Frederikshavn-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZAU",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Aue",
            "name": "[ZAU] - Aue - Germany",
            "searchName": "ZAU-Aue-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZCB",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Aschaffenburg",
            "name": "[ZCB] - Aschaffenburg - Germany",
            "searchName": "ZCB-Aschaffenburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZEE",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Fulda",
            "name": "[ZEE] - Fulda - Germany",
            "searchName": "ZEE-Fulda-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZFR",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Frankfurt (Oder)",
            "name": "[ZFR] - Frankfurt (Oder) - Germany",
            "searchName": "ZFR-Frankfurt (Oder)-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZEI",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Garmisch-Partenkirchen",
            "name": "[ZEI] - Garmisch-Partenkirchen - Germany",
            "searchName": "ZEI-Garmisch-Partenkirchen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZEJ",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Gelsenkirchen",
            "name": "[ZEJ] - Gelsenkirchen - Germany",
            "searchName": "ZEJ-Gelsenkirchen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZEU",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Goettingen",
            "name": "[ZEU] - Goettingen - Germany",
            "searchName": "ZEU-Goettingen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZEY",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Hagen",
            "name": "[ZEY] - Hagen - Germany",
            "searchName": "ZEY-Hagen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZGW",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Greifswald",
            "name": "[ZGW] - Greifswald - Germany",
            "searchName": "ZGW-Greifswald-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZGO",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Gotha",
            "name": "[ZGO] - Gotha - Germany",
            "searchName": "ZGO-Gotha-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZGA",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Gera",
            "name": "[ZGA] - Gera - Germany",
            "searchName": "ZGA-Gera-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZGE",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Goerlitz",
            "name": "[ZGE] - Goerlitz - Germany",
            "searchName": "ZGE-Goerlitz-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZIO",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Solingen",
            "name": "[ZIO] - Solingen - Germany",
            "searchName": "ZIO-Solingen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZJS",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Jena",
            "name": "[ZJS] - Jena - Germany",
            "searchName": "ZJS-Jena-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZSC",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Schoena",
            "name": "[ZSC] - Schoena - Germany",
            "searchName": "ZSC-Schoena-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZSD",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Schwanheide",
            "name": "[ZSD] - Schwanheide - Germany",
            "searchName": "ZSD-Schwanheide-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZRW",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Rastatt",
            "name": "[ZRW] - Rastatt - Germany",
            "searchName": "ZRW-Rastatt-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZSG",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Sonneberg",
            "name": "[ZSG] - Sonneberg - Germany",
            "searchName": "ZSG-Sonneberg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZSU",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Dessau",
            "name": "[ZSU] - Dessau - Germany",
            "searchName": "ZSU-Dessau-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZSI",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Sassnitz",
            "name": "[ZSI] - Sassnitz - Germany",
            "searchName": "ZSI-Sassnitz-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZSQ",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Salzwedel",
            "name": "[ZSQ] - Salzwedel - Germany",
            "searchName": "ZSQ-Salzwedel-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZSK",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Pasewalk",
            "name": "[ZSK] - Pasewalk - Germany",
            "searchName": "ZSK-Pasewalk-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZSN",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Stendal",
            "name": "[ZSN] - Stendal - Germany",
            "searchName": "ZSN-Stendal-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZSO",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Suhl",
            "name": "[ZSO] - Suhl - Germany",
            "searchName": "ZSO-Suhl-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZWT",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Lutherstadt Wittenberg",
            "name": "[ZWT] - Lutherstadt Wittenberg - Germany",
            "searchName": "ZWT-Lutherstadt Wittenberg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZWM",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Wismar",
            "name": "[ZWM] - Wismar - Germany",
            "searchName": "ZWM-Wismar-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZWN",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Wittenberge",
            "name": "[ZWN] - Wittenberge - Germany",
            "searchName": "ZWN-Wittenberge-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZWD",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Warnemuende",
            "name": "[ZWD] - Warnemuende - Germany",
            "searchName": "ZWD-Warnemuende-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZTZ",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Chemnitz",
            "name": "[ZTZ] - Chemnitz - Germany",
            "searchName": "ZTZ-Chemnitz-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZNB",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Hamm",
            "name": "[ZNB] - Hamm - Germany",
            "searchName": "ZNB-Hamm-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZOG",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Lueneburg",
            "name": "[ZOG] - Lueneburg - Germany",
            "searchName": "ZOG-Lueneburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZOM",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Minden",
            "name": "[ZOM] - Minden - Germany",
            "searchName": "ZOM-Minden-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZOO",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Muelheim an der Ruhr",
            "name": "[ZOO] - Muelheim an der Ruhr - Germany",
            "searchName": "ZOO-Muelheim an der Ruhr-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZNV",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Koblenz",
            "name": "[ZNV] - Koblenz - Germany",
            "searchName": "ZNV-Koblenz-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZNW",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Limburg",
            "name": "[ZNW] - Limburg - Germany",
            "searchName": "ZNW-Limburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZOY",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Oberhausen",
            "name": "[ZOY] - Oberhausen - Germany",
            "searchName": "ZOY-Oberhausen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZPA",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Offenburg",
            "name": "[ZPA] - Offenburg - Germany",
            "searchName": "ZPA-Offenburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZPD",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Oldenburg",
            "name": "[ZPD] - Oldenburg - Germany",
            "searchName": "ZPD-Oldenburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZPF",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Passau",
            "name": "[ZPF] - Passau - Germany",
            "searchName": "ZPF-Passau-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZQV",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Worms",
            "name": "[ZQV] - Worms - Germany",
            "searchName": "ZQV-Worms-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZQY",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Giessen",
            "name": "[ZQY] - Giessen - Germany",
            "searchName": "ZQY-Giessen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZPL",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Recklinghausen",
            "name": "[ZPL] - Recklinghausen - Germany",
            "searchName": "ZPL-Recklinghausen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZPM",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Regensburg",
            "name": "[ZPM] - Regensburg - Germany",
            "searchName": "ZPM-Regensburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZPY",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Siegburg",
            "name": "[ZPY] - Siegburg - Germany",
            "searchName": "ZPY-Siegburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZQF",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Trier",
            "name": "[ZQF] - Trier - Germany",
            "searchName": "ZQF-Trier-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZLU",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Ludwigslust",
            "name": "[ZLU] - Ludwigslust - Germany",
            "searchName": "ZLU-Ludwigslust-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZIT",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Zittau",
            "name": "[ZIT] - Zittau - Germany",
            "searchName": "ZIT-Zittau-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "XHI",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Herzogenaurach",
            "name": "[XHI] - Herzogenaurach - Germany",
            "searchName": "XHI-Herzogenaurach-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "WIE",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Wiesbaden",
            "name": "[WIE] - Wiesbaden - Germany",
            "searchName": "WIE-Wiesbaden-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "WVN",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Wilhelmshaven",
            "name": "[WVN] - Wilhelmshaven - Germany",
            "searchName": "WVN-Wilhelmshaven-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "XLW",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Lemwerder",
            "name": "[XLW] - Lemwerder - Germany",
            "searchName": "XLW-Lemwerder-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "PSH",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "St Peter",
            "name": "[PSH] - St Peter - Germany",
            "searchName": "PSH-St Peter-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "RBM",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Straubing",
            "name": "[RBM] - Straubing - Germany",
            "searchName": "RBM-Straubing-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "REB",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Rechlin",
            "name": "[REB] - Rechlin - Germany",
            "searchName": "REB-Rechlin-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "QBO",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Bochum",
            "name": "[QBO] - Bochum - Germany",
            "searchName": "QBO-Bochum-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "QFB",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Freiburg",
            "name": "[QFB] - Freiburg - Germany",
            "searchName": "QFB-Freiburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "QII",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Lindau",
            "name": "[QII] - Lindau - Germany",
            "searchName": "QII-Lindau-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "QOB",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Ansbach",
            "name": "[QOB] - Ansbach - Germany",
            "searchName": "QOB-Ansbach-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "QHU",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Husum",
            "name": "[QHU] - Husum - Germany",
            "searchName": "QHU-Husum-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "QMZ",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Mainz",
            "name": "[QMZ] - Mainz - Germany",
            "searchName": "QMZ-Mainz-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "QWU",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Wuerzburg",
            "name": "[QWU] - Wuerzburg - Germany",
            "searchName": "QWU-Wuerzburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "QUL",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Ulm",
            "name": "[QUL] - Ulm - Germany",
            "searchName": "QUL-Ulm-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "SGE",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Siegen",
            "name": "[SGE] - Siegen - Germany",
            "searchName": "SGE-Siegen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "SPM",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Spangdahlem",
            "name": "[SPM] - Spangdahlem - Germany",
            "searchName": "SPM-Spangdahlem-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "SCN",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Saarbruecken",
            "name": "[SCN] - Saarbruecken - Germany",
            "searchName": "SCN-Saarbr-Saarbruecken-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "RMS",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Ramstein",
            "name": "[RMS] - Ramstein - Germany",
            "searchName": "RMS-Ramstein-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "RLG",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Rostock-laage",
            "name": "[RLG] - Rostock-laage - Germany",
            "searchName": "RLG-Rostock-Laage-laage-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "OHR",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Wyk Auf Foehr",
            "name": "[OHR] - Wyk Auf Foehr - Germany",
            "searchName": "OHR-Wyk Auf Foehr-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "OBF",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Oberpfaffenhofen",
            "name": "[OBF] - Oberpfaffenhofen - Germany",
            "searchName": "OBF-Oberpfaffenhofen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "CUR",
            "countryCode": "CW",
            "cityCode": null,
            "cityName": "Curacao",
            "name": "[CUR] - Curacao - Curacao",
            "searchName": "CUR-Willemstad-Curacao-C-Curaçao-Hollanda Antilleri-Curazao",
            "priority": 0
        },
        {
            "iataCode": "XCH",
            "countryCode": "CX",
            "cityCode": null,
            "cityName": "Christmas Island",
            "name": "[XCH] - Christmas Island - Christmas Island",
            "searchName": "XCH-Christmas Island--Christmas Adasi-Isla Christmas-Île Christmas-Isola di Christmas",
            "priority": 0
        },
        {
            "iataCode": "SFL",
            "countryCode": "CV",
            "cityCode": null,
            "cityName": "São Filipe",
            "name": "[SFL] - São Filipe - Cape Verde",
            "searchName": "SFL-S-São Filipe-Kap Verde-Cape Verde-Yesil Burun Adalari-Cap-Vert-Cabo Verde-Capo Verde",
            "priority": 0
        },
        {
            "iataCode": "RAI",
            "countryCode": "CV",
            "cityCode": null,
            "cityName": "Praia",
            "name": "[RAI] - Praia - Cape Verde",
            "searchName": "RAI-Praia-Kap Verde-Cape Verde-Yesil Burun Adalari-Cap-Vert-Cabo Verde-Capo Verde",
            "priority": 0
        },
        {
            "iataCode": "MJG",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Mayajigua",
            "name": "[MJG] - Mayajigua - Cuba",
            "searchName": "MJG-Mayajigua-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "LCL",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "La Coloma",
            "name": "[LCL] - La Coloma - Cuba",
            "searchName": "LCL-La Coloma-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "CYO",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Cayo Largo Del Sur",
            "name": "[CYO] - Cayo Largo Del Sur - Cuba",
            "searchName": "CYO-Cayo Largo Del Sur-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "AKT",
            "countryCode": "CY",
            "cityCode": null,
            "cityName": "Akrotiri",
            "name": "[AKT] - Akrotiri - Cyprus",
            "searchName": "AKT-Akrotiri-Ciprus-Cypr-Siprus-Cyprus-Síp-Kipra-Kypros-Küpros-Cipar-Xipre-Kypr-Cypern-Chipre-Zypern-Chypre-Kibris-Cipru-Kipras-Cipro",
            "priority": 0
        },
        {
            "iataCode": "NIC",
            "countryCode": "CY",
            "cityCode": null,
            "cityName": "Nicosia",
            "name": "[NIC] - Nicosia - Cyprus",
            "searchName": "NIC-Nicosia-Ciprus-Cypr-Siprus-Cyprus-Síp-Kipra-Kypros-Küpros-Cipar-Xipre-Kypr-Cypern-Chipre-Zypern-Chypre-Kibris-Cipru-Kipras-Cipro",
            "priority": 0
        },
        {
            "iataCode": "BRQ",
            "countryCode": "CZ",
            "cityCode": null,
            "cityName": "Brno",
            "name": "[BRQ] - Brno - Czech Republic",
            "searchName": "BRQ-Brno-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "MKA",
            "countryCode": "CZ",
            "cityCode": null,
            "cityName": "Marianske Lazne",
            "name": "[MKA] - Marianske Lazne - Czech Republic",
            "searchName": "MKA-Marianske Lazne-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "PED",
            "countryCode": "CZ",
            "cityCode": null,
            "cityName": "Pardubice",
            "name": "[PED] - Pardubice - Czech Republic",
            "searchName": "PED-Pardubice-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "CMW",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Camaguey",
            "name": "[CMW] - Camaguey - Cuba",
            "searchName": "CMW-Camag-Camaguey-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "AVI",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Ciego De Avila",
            "name": "[AVI] - Ciego De Avila - Cuba",
            "searchName": "AVI-Ciego De Avila-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "BCA",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Baracoa",
            "name": "[BCA] - Baracoa - Cuba",
            "searchName": "BCA-Baracoa-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "CCC",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Cayo Coco",
            "name": "[CCC] - Cayo Coco - Cuba",
            "searchName": "CCC-Cayo Coco-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "BYM",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Bayamo",
            "name": "[BYM] - Bayamo - Cuba",
            "searchName": "BYM-Bayamo-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "BWW",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Cayo Las Brujas",
            "name": "[BWW] - Cayo Las Brujas - Cuba",
            "searchName": "BWW-Cayo Las Brujas-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "BVC",
            "countryCode": "CV",
            "cityCode": null,
            "cityName": "Boa Vista",
            "name": "[BVC] - Boa Vista - Cape Verde",
            "searchName": "BVC-Boa Vista-Kap Verde-Cape Verde-Yesil Burun Adalari-Cap-Vert-Cabo Verde-Capo Verde",
            "priority": 0
        },
        {
            "iataCode": "BVR",
            "countryCode": "CV",
            "cityCode": null,
            "cityName": "Brava",
            "name": "[BVR] - Brava - Cape Verde",
            "searchName": "BVR-Brava-Kap Verde-Cape Verde-Yesil Burun Adalari-Cap-Vert-Cabo Verde-Capo Verde",
            "priority": 0
        },
        {
            "iataCode": "MMO",
            "countryCode": "CV",
            "cityCode": null,
            "cityName": "Maio",
            "name": "[MMO] - Maio - Cape Verde",
            "searchName": "MMO-Maio-Kap Verde-Cape Verde-Yesil Burun Adalari-Cap-Vert-Cabo Verde-Capo Verde",
            "priority": 0
        },
        {
            "iataCode": "MTI",
            "countryCode": "CV",
            "cityCode": null,
            "cityName": "Mosteiros",
            "name": "[MTI] - Mosteiros - Cape Verde",
            "searchName": "MTI-Mosteiros-Kap Verde-Cape Verde-Yesil Burun Adalari-Cap-Vert-Cabo Verde-Capo Verde",
            "priority": 0
        },
        {
            "iataCode": "NTO",
            "countryCode": "CV",
            "cityCode": null,
            "cityName": "Santo Antao",
            "name": "[NTO] - Santo Antao - Cape Verde",
            "searchName": "NTO-Santo Antao-Kap Verde-Cape Verde-Yesil Burun Adalari-Cap-Vert-Cabo Verde-Capo Verde",
            "priority": 0
        },
        {
            "iataCode": "SNE",
            "countryCode": "CV",
            "cityCode": null,
            "cityName": "Sao Nicolau",
            "name": "[SNE] - Sao Nicolau - Cape Verde",
            "searchName": "SNE-Sao Nicolau-Kap Verde-Cape Verde-Yesil Burun Adalari-Cap-Vert-Cabo Verde-Capo Verde",
            "priority": 0
        },
        {
            "iataCode": "VRO",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Matanzas",
            "name": "[VRO] - Matanzas - Cuba",
            "searchName": "VRO-Matanzas-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "SZJ",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Siguanea",
            "name": "[SZJ] - Siguanea - Cuba",
            "searchName": "SZJ-Siguanea-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "TND",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Trinidad",
            "name": "[TND] - Trinidad - Cuba",
            "searchName": "TND-Trinidad-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "PST",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Preston",
            "name": "[PST] - Preston - Cuba",
            "searchName": "PST-Preston-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "SNU",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Santa Clara",
            "name": "[SNU] - Santa Clara - Cuba",
            "searchName": "SNU-Santa Clara-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "SNJ",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "San Julian",
            "name": "[SNJ] - San Julian - Cuba",
            "searchName": "SNJ-San Julian-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "MZO",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Manzanillo",
            "name": "[MZO] - Manzanillo - Cuba",
            "searchName": "MZO-Manzanillo-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "MOA",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Moa",
            "name": "[MOA] - Moa - Cuba",
            "searchName": "MOA-Moa-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "ICR",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Nicaro",
            "name": "[ICR] - Nicaro - Cuba",
            "searchName": "ICR-Nicaro-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "GER",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Nueva Gerona",
            "name": "[GER] - Nueva Gerona - Cuba",
            "searchName": "GER-Nueva Gerona-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "GAO",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Guantanamo",
            "name": "[GAO] - Guantanamo - Cuba",
            "searchName": "GAO-Guant-Guantanamo-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "PLD",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Playa Samara",
            "name": "[PLD] - Playa Samara - Costa Rica",
            "searchName": "PLD-Playa Samara-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "PJM",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Puerto Jimenez",
            "name": "[PJM] - Puerto Jimenez - Costa Rica",
            "searchName": "PJM-Puerto Jimenez-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "PMZ",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Palmar",
            "name": "[PMZ] - Palmar - Costa Rica",
            "searchName": "PMZ-Palmar-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "RIK",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Carrillo",
            "name": "[RIK] - Carrillo - Costa Rica",
            "searchName": "RIK-Carrillo-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "RFR",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Rio Frio",
            "name": "[RFR] - Rio Frio - Costa Rica",
            "searchName": "RFR-Rio Frio-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "TOO",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "San Vito",
            "name": "[TOO] - San Vito - Costa Rica",
            "searchName": "TOO-San Vito-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "TNO",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Tamarindo",
            "name": "[TNO] - Tamarindo - Costa Rica",
            "searchName": "TNO-Tamarindo-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "TMU",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Tambor",
            "name": "[TMU] - Tambor - Costa Rica",
            "searchName": "TMU-Tambor-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "SYQ",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "San Jose",
            "name": "[SYQ] - San Jose - Costa Rica",
            "searchName": "SYQ-San Jos-San Jose-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "SZC",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Santa Cruz",
            "name": "[SZC] - Santa Cruz - Costa Rica",
            "searchName": "SZC-Santa Cruz-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "TTQ",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Tortuquero",
            "name": "[TTQ] - Tortuquero - Costa Rica",
            "searchName": "TTQ-Tortuquero-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "UPL",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Upala",
            "name": "[UPL] - Upala - Costa Rica",
            "searchName": "UPL-Upala-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "XQP",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Pavones",
            "name": "[XQP] - Pavones - Costa Rica",
            "searchName": "XQP-Quepos-Pavones-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "UPA",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Punta Alegre",
            "name": "[UPA] - Punta Alegre - Cuba",
            "searchName": "UPA-Punta Alegre-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "UPB",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Havana",
            "name": "[UPB] - Havana - Cuba",
            "searchName": "UPB-Havana-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "USS",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Sancti Spiritus",
            "name": "[USS] - Sancti Spiritus - Cuba",
            "searchName": "USS-Sancti Spiritus-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "UMA",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Punta De Maisi",
            "name": "[UMA] - Punta De Maisi - Cuba",
            "searchName": "UMA-Punta de Maisi-Punta De Maisi-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "VTU",
            "countryCode": "CU",
            "cityCode": null,
            "cityName": "Las Tunas",
            "name": "[VTU] - Las Tunas - Cuba",
            "searchName": "VTU-Las Tunas-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "BCL",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Barra Colorado",
            "name": "[BCL] - Barra Colorado - Costa Rica",
            "searchName": "BCL-Barra Colorado-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "BAI",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Buenos Aires",
            "name": "[BAI] - Buenos Aires - Costa Rica",
            "searchName": "BAI-Buenos Aires-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "DRK",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Drake Bay",
            "name": "[DRK] - Drake Bay - Costa Rica",
            "searchName": "DRK-Drake Bay-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "FMG",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Flamingo",
            "name": "[FMG] - Flamingo - Costa Rica",
            "searchName": "FMG-Flamingo-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "FON",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Fortuna",
            "name": "[FON] - Fortuna - Costa Rica",
            "searchName": "FON-La Fortuna-Fortuna-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "CSC",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Canas",
            "name": "[CSC] - Canas - Costa Rica",
            "searchName": "CSC-Canas-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "LCS",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Las Canas",
            "name": "[LCS] - Las Canas - Costa Rica",
            "searchName": "LCS-Las Canas-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "LIR",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Liberia",
            "name": "[LIR] - Liberia - Costa Rica",
            "searchName": "LIR-Liberia-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "LIO",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Limon",
            "name": "[LIO] - Limon - Costa Rica",
            "searchName": "LIO-Limon-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "LSL",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Los Chiles",
            "name": "[LSL] - Los Chiles - Costa Rica",
            "searchName": "LSL-Los Chiles-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "GPL",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Guapiles",
            "name": "[GPL] - Guapiles - Costa Rica",
            "searchName": "GPL-Guapiles-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "GLF",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Golfito",
            "name": "[GLF] - Golfito - Costa Rica",
            "searchName": "GLF-Golfito-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "JAP",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Punta Renes",
            "name": "[JAP] - Punta Renes - Costa Rica",
            "searchName": "JAP-Punta Renes-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "NCT",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Nicoya",
            "name": "[NCT] - Nicoya - Costa Rica",
            "searchName": "NCT-Nicoya-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "PBP",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Punta Islita",
            "name": "[PBP] - Punta Islita - Costa Rica",
            "searchName": "PBP-Punta Islita-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "OTR",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Coto 47",
            "name": "[OTR] - Coto 47 - Costa Rica",
            "searchName": "OTR-Coto 47-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "NOB",
            "countryCode": "CR",
            "cityCode": null,
            "cityName": "Nosara Beach",
            "name": "[NOB] - Nosara Beach - Costa Rica",
            "searchName": "NOB-Nosara Beach-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "LYA",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Luoyang",
            "name": "[LYA] - Luoyang - China",
            "searchName": "LYA-Luoyang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LYG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Lianyungang",
            "name": "[LYG] - Lianyungang - China",
            "searchName": "LYG-Lianyungang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "MDG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Mudanjiang",
            "name": "[MDG] - Mudanjiang - China",
            "searchName": "MDG-Mudanjiang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "GOQ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Golmud",
            "name": "[GOQ] - Golmud - China",
            "searchName": "GOQ-Golmud-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "GHN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Guanghan",
            "name": "[GHN] - Guanghan - China",
            "searchName": "GHN-Guanghan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "FUG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Fuyang",
            "name": "[FUG] - Fuyang - China",
            "searchName": "FUG-Fuyang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "FUO",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Fuoshan",
            "name": "[FUO] - Fuoshan - China",
            "searchName": "FUO-Fuoshan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "GYS",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Guang Yuan",
            "name": "[GYS] - Guang Yuan - China",
            "searchName": "GYS-Guang Yuan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "GYU",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Guyuan",
            "name": "[GYU] - Guyuan - China",
            "searchName": "GYU-Guyuan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HAK",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Haikou",
            "name": "[HAK] - Haikou - China",
            "searchName": "HAK-Haikou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "PUX",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Puerto Varas",
            "name": "[PUX] - Puerto Varas - Chile",
            "searchName": "PUX-Puerto Varas-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "PNT",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Puerto Natales",
            "name": "[PNT] - Puerto Natales - Chile",
            "searchName": "PNT-Puerto Natales-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "PMC",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Puerto Montt",
            "name": "[PMC] - Puerto Montt - Chile",
            "searchName": "PMC-Puerto Montt-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "PUQ",
            "countryCode": "CL",
            "cityCode": null,
            "cityName": "Punta Arenas",
            "name": "[PUQ] - Punta Arenas - Chile",
            "searchName": "PUQ-Punta Arenas-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "BPL",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Bole",
            "name": "[BPL] - Bole - China",
            "searchName": "BPL-Bole-Bole Alashankou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "BFU",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Bengbu",
            "name": "[BFU] - Bengbu - China",
            "searchName": "BFU-Bengbu-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "BHY",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Beihai",
            "name": "[BHY] - Beihai - China",
            "searchName": "BHY-Beihai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "BPO",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Boao",
            "name": "[BPO] - Boao - China",
            "searchName": "BPO-Bo ao-Boao-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "BPX",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Bangda",
            "name": "[BPX] - Bangda - China",
            "searchName": "BPX-Bangda-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "BSD",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Baoshan",
            "name": "[BSD] - Baoshan - China",
            "searchName": "BSD-Baoshan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "SHE",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Shenyang",
            "name": "[SHE] - Shenyang - China",
            "searchName": "SHE-Shenyang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "RUG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Rugao",
            "name": "[RUG] - Rugao - China",
            "searchName": "RUG-Rugao-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "RKZ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Xigaze/Rikaze",
            "name": "[RKZ] - Xigaze/Rikaze - China",
            "searchName": "RKZ-Xigaze/Rikaze-Shigatse Peace Airport-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "RLK",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Bayannur",
            "name": "[RLK] - Bayannur - China",
            "searchName": "RLK-Bayannur-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "PNJ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Peng Lai",
            "name": "[PNJ] - Peng Lai - China",
            "searchName": "PNJ-Peng Lai-Penglai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "PZI",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Panzhihua",
            "name": "[PZI] - Panzhihua - China",
            "searchName": "PZI-Pan Zhi Hua-Panzhihua-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "QHX",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Qionghai",
            "name": "[QHX] - Qionghai - China",
            "searchName": "QHX-Qionghai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "THQ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Tianshui",
            "name": "[THQ] - Tianshui - China",
            "searchName": "THQ-Tianshui-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TGO",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Tongliao",
            "name": "[TGO] - Tongliao - China",
            "searchName": "TGO-Tongliao-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TEN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Tongren",
            "name": "[TEN] - Tongren - China",
            "searchName": "TEN-Tongren-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TCZ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Tengchong",
            "name": "[TCZ] - Tengchong - China",
            "searchName": "TCZ-Tengchong-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TLQ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Turpan",
            "name": "[TLQ] - Turpan - China",
            "searchName": "TLQ-Turpan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TNA",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Jinan",
            "name": "[TNA] - Jinan - China",
            "searchName": "TNA-Jinan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TNH",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Tonghua",
            "name": "[TNH] - Tonghua - China",
            "searchName": "TNH-Tonghua-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "SXJ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Shanshan",
            "name": "[SXJ] - Shanshan - China",
            "searchName": "SXJ-Shanshan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HET",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Hohhot",
            "name": "[HET] - Hohhot - China",
            "searchName": "HET-Hohhot-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HFE",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Hefei (Luogang Airport)",
            "name": "[HFE] - Hefei (Luogang Airport) - China",
            "searchName": "HFE-Hefei-Hefei (Luogang Airport)-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HIA",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Huaian",
            "name": "[HIA] - Huaian - China",
            "searchName": "HIA-Huaiyin-Huai an-Huaian-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HDG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Handan",
            "name": "[HDG] - Handan - China",
            "searchName": "HDG-Handan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HEK",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Heihe",
            "name": "[HEK] - Heihe - China",
            "searchName": "HEK-Heihe-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HLD",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Hailar",
            "name": "[HLD] - Hailar - China",
            "searchName": "HLD-Hailar-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HLH",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Ulanhot",
            "name": "[HLH] - Ulanhot - China",
            "searchName": "HLH-Ulanhot-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HJJ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Zhi Jiang",
            "name": "[HJJ] - Zhi Jiang - China",
            "searchName": "HJJ-Zhijiang-Zhi Jiang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HMI",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Hami",
            "name": "[HMI] - Hami - China",
            "searchName": "HMI-Kumul-Hami-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HNY",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Hengyang",
            "name": "[HNY] - Hengyang - China",
            "searchName": "HNY-Hengyang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "IQM",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Qiemo",
            "name": "[IQM] - Qiemo - China",
            "searchName": "IQM-Qiemo-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "IQN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Qingyang",
            "name": "[IQN] - Qingyang - China",
            "searchName": "IQN-Qingyang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "INC",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Yinchuan",
            "name": "[INC] - Yinchuan - China",
            "searchName": "INC-Yinchuan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HZG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Hanzhong",
            "name": "[HZG] - Hanzhong - China",
            "searchName": "HZG-Hanzhong-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HZH",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Liping City",
            "name": "[HZH] - Liping City - China",
            "searchName": "HZH-Liping City-Liping-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HUZ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Huizhou",
            "name": "[HUZ] - Huizhou - China",
            "searchName": "HUZ-Huizhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HYN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Taizhou",
            "name": "[HYN] - Taizhou - China",
            "searchName": "HYN-Huangyan-Taizhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HTN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Hotan",
            "name": "[HTN] - Hotan - China",
            "searchName": "HTN-Hotan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HSN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Zhoushan",
            "name": "[HSN] - Zhoushan - China",
            "searchName": "HSN-Zhoushan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HSC",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Shaoguan",
            "name": "[HSC] - Shaoguan - China",
            "searchName": "HSC-Shaoguan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HRB",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Harbin",
            "name": "[HRB] - Harbin - China",
            "searchName": "HRB-Haerbin-Harbin-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JDZ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Jingdezhen",
            "name": "[JDZ] - Jingdezhen - China",
            "searchName": "JDZ-Jingdezhen-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JUZ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Juzhou",
            "name": "[JUZ] - Juzhou - China",
            "searchName": "JUZ-Juzhou-Quzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JXA",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Jixi",
            "name": "[JXA] - Jixi - China",
            "searchName": "JXA-Jixi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JZH",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Song Pan",
            "name": "[JZH] - Song Pan - China",
            "searchName": "JZH-Song Pan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JUH",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Chizhou",
            "name": "[JUH] - Chizhou - China",
            "searchName": "JUH-Chizhou-Chizhou Jiuhuashan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JIC",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Jinchang",
            "name": "[JIC] - Jinchang - China",
            "searchName": "JIC-Jinchang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JHG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Jinghong",
            "name": "[JHG] - Jinghong - China",
            "searchName": "JHG-Jinghong-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JGD",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Jiagedaqi",
            "name": "[JGD] - Jiagedaqi - China",
            "searchName": "JGD-Jiagedaqi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JGN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Jiayuguan",
            "name": "[JGN] - Jiayuguan - China",
            "searchName": "JGN-Jiuquan-Jiayuguan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JGS",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Ji An",
            "name": "[JGS] - Ji An - China",
            "searchName": "JGS-Ji An-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JIL",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Jilin",
            "name": "[JIL] - Jilin - China",
            "searchName": "JIL-Jilin-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JIQ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Qianjiang",
            "name": "[JIQ] - Qianjiang - China",
            "searchName": "JIQ-Qianjiang-Youyang-Zhoubai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JIU",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Jiujiang",
            "name": "[JIU] - Jiujiang - China",
            "searchName": "JIU-Jiujiang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JJN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Jinjiang",
            "name": "[JJN] - Jinjiang - China",
            "searchName": "JJN-Quanzhou-Jinjiang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JNG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Jining",
            "name": "[JNG] - Jining - China",
            "searchName": "JNG-Jining-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JNZ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Jinzhou",
            "name": "[JNZ] - Jinzhou - China",
            "searchName": "JNZ-Jinzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JMU",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Jiamusi",
            "name": "[JMU] - Jiamusi - China",
            "searchName": "JMU-Jiamusi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "MXZ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Meixian",
            "name": "[MXZ] - Meixian - China",
            "searchName": "MXZ-Meizhou-Meixian-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NAO",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Nanchong",
            "name": "[NAO] - Nanchong - China",
            "searchName": "NAO-Nanchong-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NGB",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Ningbo",
            "name": "[NGB] - Ningbo - China",
            "searchName": "NGB-Ningbo-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NGQ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Shiquanhe",
            "name": "[NGQ] - Shiquanhe - China",
            "searchName": "NGQ-Shiquanhe-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NDG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Qiqihar",
            "name": "[NDG] - Qiqihar - China",
            "searchName": "NDG-Qiqihar-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NBS",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Baishan",
            "name": "[NBS] - Baishan - China",
            "searchName": "NBS-Baishan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NLT",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Xinyuan",
            "name": "[NLT] - Xinyuan - China",
            "searchName": "NLT-Xinyuan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NNG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Nanning",
            "name": "[NNG] - Nanning - China",
            "searchName": "NNG-Nanning-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NKG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Nanjing",
            "name": "[NKG] - Nanjing - China",
            "searchName": "NKG-Nanjing-Nank-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "OHE",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Mohe",
            "name": "[OHE] - Mohe - China",
            "searchName": "OHE-Mohe-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NZH",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Manzhouli",
            "name": "[NZH] - Manzhouli - China",
            "searchName": "NZH-Manzhouli-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NTG",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Nantong",
            "name": "[NTG] - Nantong - China",
            "searchName": "NTG-NanTong-Nantong-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NSZ",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Nansha",
            "name": "[NSZ] - Nansha - China",
            "searchName": "NSZ-Nansha-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NNY",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Nanyang",
            "name": "[NNY] - Nanyang - China",
            "searchName": "NNY-Nanyang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "SHS",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Shashi",
            "name": "[SHS] - Shashi - China",
            "searchName": "SHS-Shashi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "SIA",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Xi an",
            "name": "[SIA] - Xi an - China",
            "searchName": "SIA-Xian-Xi an-Xi An-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "SJW",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Shijiazhuang",
            "name": "[SJW] - Shijiazhuang - China",
            "searchName": "SJW-Shijiazhuang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "SHP",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Qinhuangdao",
            "name": "[SHP] - Qinhuangdao - China",
            "searchName": "SHP-Qinhuangdao-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "BCX",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Beloreck",
            "name": "[BCX] - Beloreck - Russia",
            "searchName": "BCX-Beloreck-Boca Raton-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "AMV",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Amderma",
            "name": "[AMV] - Amderma - Russia",
            "searchName": "AMV-Amderma-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ABA",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Abakan",
            "name": "[ABA] - Abakan - Russia",
            "searchName": "ABA-Abakan-Abak-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "BQJ",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Batagay",
            "name": "[BQJ] - Batagay - Russia",
            "searchName": "BQJ-Batagay-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "BQS",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Blagoveschensk",
            "name": "[BQS] - Blagoveschensk - Russia",
            "searchName": "BQS-Blagoveschensk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "BQG",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Bogorodskoye",
            "name": "[BQG] - Bogorodskoye - Russia",
            "searchName": "BQG-Bogorodskoye-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "BZK",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Briansk",
            "name": "[BZK] - Briansk - Russia",
            "searchName": "BZK-Briansk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "BVV",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Iturup Island",
            "name": "[BVV] - Iturup Island - Russia",
            "searchName": "BVV-Iturup Island-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "BWO",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Balakovo",
            "name": "[BWO] - Balakovo - Russia",
            "searchName": "BWO-Balakovo-Bandar Seri Begawan-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ADH",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Aldan",
            "name": "[ADH] - Aldan - Russia",
            "searchName": "ADH-Aldan-Adrian-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "CSH",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Solovetsky",
            "name": "[CSH] - Solovetsky - Russia",
            "searchName": "CSH-Solovetsky-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "CSY",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Cheboksary",
            "name": "[CSY] - Cheboksary - Russia",
            "searchName": "CSY-Cheboksary-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "DEE",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Yuzhno-Kurilsk",
            "name": "[DEE] - Yuzhno-Kurilsk - Russia",
            "searchName": "DEE-Yuzhno-Kurilsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "CYX",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Cherskiy",
            "name": "[CYX] - Cherskiy - Russia",
            "searchName": "CYX-Cherskiy-Cheyenne-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "CKH",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Chokurdah",
            "name": "[CKH] - Chokurdah - Russia",
            "searchName": "CKH-Chokurdah--Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie-Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "CKL",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Chkalovsky",
            "name": "[CKL] - Chkalovsky - Russia",
            "searchName": "CKL-Chkalovsky--Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie-Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "TOX",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Tobolsk",
            "name": "[TOX] - Tobolsk - Russia",
            "searchName": "TOX-Tobolsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "TQL",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Tarko-Sale",
            "name": "[TQL] - Tarko-Sale - Russia",
            "searchName": "TQL-Tarko-Sale-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "YKS",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Yakutsk",
            "name": "[YKS] - Yakutsk - Russia",
            "searchName": "YKS-Yakutsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "YMK",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Mys-Kamenny",
            "name": "[YMK] - Mys-Kamenny - Russia",
            "searchName": "YMK-Mys-Kamenny-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "TBW",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Tambov",
            "name": "[TBW] - Tambov - Russia",
            "searchName": "TBW-Tambow-Tambov-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "SWT",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Strzhewoi",
            "name": "[SWT] - Strzhewoi - Russia",
            "searchName": "SWT-Strzhewoi-Swansea-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "TGP",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Bor",
            "name": "[TGP] - Bor - Russia",
            "searchName": "TGP-Bor-Podkamennaya Tunguska-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "TGK",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Taganrog",
            "name": "[TGK] - Taganrog - Russia",
            "searchName": "TGK-Taganrog-Taganrong-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "UEN",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Urengoy",
            "name": "[UEN] - Urengoy - Russia",
            "searchName": "UEN-Urengoy-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "UCT",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Ukhta",
            "name": "[UCT] - Ukhta - Russia",
            "searchName": "UCT-Uchta-Ukhta-Ujt-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "UKX",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Ust-Kut",
            "name": "[UKX] - Ust-Kut - Russia",
            "searchName": "UKX-Ust-Kut-Nuku-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ULK",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Lensk",
            "name": "[ULK] - Lensk - Russia",
            "searchName": "ULK-Lensk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "UIK",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Ust-Ilimsk",
            "name": "[UIK] - Ust-Ilimsk - Russia",
            "searchName": "UIK-Ust-Ilimsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "USK",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Usinsk",
            "name": "[USK] - Usinsk - Russia",
            "searchName": "USK-Usinsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "URS",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Kursk",
            "name": "[URS] - Kursk - Russia",
            "searchName": "URS-Kursk-Koursk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "URJ",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Uraj",
            "name": "[URJ] - Uraj - Russia",
            "searchName": "URJ-Uraj-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ULY",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Ulyanovsk",
            "name": "[ULY] - Ulyanovsk - Russia",
            "searchName": "ULY-Ulyanovsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "STW",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Stavropol",
            "name": "[STW] - Stavropol - Russia",
            "searchName": "STW-Stavropol-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "EIE",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Eniseysk",
            "name": "[EIE] - Eniseysk - Russia",
            "searchName": "EIE-Eniseysk-Eisenach-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "EIK",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Yeysk",
            "name": "[EIK] - Yeysk - Russia",
            "searchName": "EIK-Yeysk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "EGO",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Belgorod",
            "name": "[EGO] - Belgorod - Russia",
            "searchName": "EGO-Belgorod-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "EKS",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Shakhtersk",
            "name": "[EKS] - Shakhtersk - Russia",
            "searchName": "EKS-Shakhtersk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ERG",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Yerbogachen",
            "name": "[ERG] - Yerbogachen - Russia",
            "searchName": "ERG-Yerbogachen-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "EZV",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Berezovo",
            "name": "[EZV] - Berezovo - Russia",
            "searchName": "EZV-Berezovo-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ESL",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Elista",
            "name": "[ESL] - Elista - Russia",
            "searchName": "ESL-Elista-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "EYK",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Beloyarsky",
            "name": "[EYK] - Beloyarsky - Russia",
            "searchName": "EYK-Beloyarsky-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "INA",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Inta",
            "name": "[INA] - Inta - Russia",
            "searchName": "INA-Inta-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "IGT",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Nazran",
            "name": "[IGT] - Nazran - Russia",
            "searchName": "IGT-Nazran-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "IKS",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Tiksi",
            "name": "[IKS] - Tiksi - Russia",
            "searchName": "IKS-Tiksi-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ACS",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Achinsk",
            "name": "[ACS] - Achinsk - Russia",
            "searchName": "ACS-Achinsk-Ascona-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "THX",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Turukhansk",
            "name": "[THX] - Turukhansk - Russia",
            "searchName": "THX-Turukhansk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "TLY",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Plastun",
            "name": "[TLY] - Plastun - Russia",
            "searchName": "TLY-Plastun-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "IJK",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Izhevsk",
            "name": "[IJK] - Izhevsk - Russia",
            "searchName": "IJK-Ischewsk-Izhevsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "IAR",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Yaroslavl",
            "name": "[IAR] - Yaroslavl - Russia",
            "searchName": "IAR-Yaroslavl-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "IAA",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Igarka",
            "name": "[IAA] - Igarka - Russia",
            "searchName": "IAA-Igarka-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "HTG",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Hatanga",
            "name": "[HTG] - Hatanga - Russia",
            "searchName": "HTG-Hatanga-Hatfield-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "IWA",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Ivanova",
            "name": "[IWA] - Ivanova - Russia",
            "searchName": "IWA-Ivanova-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "IRM",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Igrim",
            "name": "[IRM] - Igrim - Russia",
            "searchName": "IRM-Igrim-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "JOK",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Yoshkar-Ola",
            "name": "[JOK] - Yoshkar-Ola - Russia",
            "searchName": "JOK-Joshkar-Ola-Yoshkar-Joinville-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "GRV",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Groznyj",
            "name": "[GRV] - Groznyj - Russia",
            "searchName": "GRV-Groznyj-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "GVN",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Sovetskaya Gavan",
            "name": "[GVN] - Sovetskaya Gavan - Russia",
            "searchName": "GVN-Sovetskaya Gavan-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "HMA",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Khanty-Mansiysk",
            "name": "[HMA] - Khanty-Mansiysk - Russia",
            "searchName": "HMA-Khanty-Mansiysk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "GDX",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Magadan",
            "name": "[GDX] - Magadan - Russia",
            "searchName": "GDX-Magadan-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "GDZ",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Gelendzik",
            "name": "[GDZ] - Gelendzik - Russia",
            "searchName": "GDZ-Gelendzik-Gelendzhik-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "GDG",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Magdagachi",
            "name": "[GDG] - Magdagachi - Russia",
            "searchName": "GDG-Magdagachi-Gode-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "LPK",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Lipetsk",
            "name": "[LPK] - Lipetsk - Russia",
            "searchName": "LPK-Lipetsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "LNX",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Smolensk",
            "name": "[LNX] - Smolensk - Russia",
            "searchName": "LNX-Smolensk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "MCX",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Makhachkala",
            "name": "[MCX] - Makhachkala - Russia",
            "searchName": "MCX-Makhachkala-Makhatchkala-Maha-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KSZ",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Kotlas",
            "name": "[KSZ] - Kotlas - Russia",
            "searchName": "KSZ-Kotlas-Kars-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KVK",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Kirovsk",
            "name": "[KVK] - Kirovsk - Russia",
            "searchName": "KVK-Kirovsk-Kavieng-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KVX",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Kirov",
            "name": "[KVX] - Kirov - Russia",
            "searchName": "KVX-Kirow-Kirov-K-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KVR",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Kavalerovo",
            "name": "[KVR] - Kavalerovo - Russia",
            "searchName": "KVR-Kavalerovo-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KRO",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Kurgan",
            "name": "[KRO] - Kurgan - Russia",
            "searchName": "KRO-Kurgan-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "YNB",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Yanbu",
            "name": "[YNB] - Yanbu - Saudi Arabia",
            "searchName": "YNB-Yanbu-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "ZUL",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Zilfi",
            "name": "[ZUL] - Zilfi - Saudi Arabia",
            "searchName": "ZUL-Zilfi-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "TIF",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Taif",
            "name": "[TIF] - Taif - Saudi Arabia",
            "searchName": "TIF-Taif-Ta-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "URY",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Gurayat",
            "name": "[URY] - Gurayat - Saudi Arabia",
            "searchName": "URY-Gurayat-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "ULH",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Al Ula",
            "name": "[ULH] - Al Ula - Saudi Arabia",
            "searchName": "ULH-Al Ula-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "TUU",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Tabuk",
            "name": "[TUU] - Tabuk - Saudi Arabia",
            "searchName": "TUU-Tabuk-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "TUI",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Turaif",
            "name": "[TUI] - Turaif - Saudi Arabia",
            "searchName": "TUI-Turaif-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "WAE",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Wadi Ad Dawasir",
            "name": "[WAE] - Wadi Ad Dawasir - Saudi Arabia",
            "searchName": "WAE-Wadi Ad Dawasir-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "UZH",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Unayzah",
            "name": "[UZH] - Unayzah - Saudi Arabia",
            "searchName": "UZH-Unayzah-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "BHH",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Bisha",
            "name": "[BHH] - Bisha - Saudi Arabia",
            "searchName": "BHH-Bisha-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "AHB",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Abha",
            "name": "[AHB] - Abha - Saudi Arabia",
            "searchName": "AHB-Abha-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "ABT",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Al-Baha",
            "name": "[ABT] - Al-Baha - Saudi Arabia",
            "searchName": "ABT-Al-Baha-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "AJF",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Sakaka Al Jouf",
            "name": "[AJF] - Sakaka Al Jouf - Saudi Arabia",
            "searchName": "AJF-Jouf-Sakaka Al Jouf-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "AKH",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Al Kharj",
            "name": "[AKH] - Al Kharj - Saudi Arabia",
            "searchName": "AKH-Al Kharj-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "AQI",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Qaisumah",
            "name": "[AQI] - Qaisumah - Saudi Arabia",
            "searchName": "AQI-Qaisumah-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "EJH",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Wedjh",
            "name": "[EJH] - Wedjh - Saudi Arabia",
            "searchName": "EJH-Wedjh-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "ELQ",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Buraidah",
            "name": "[ELQ] - Buraidah - Saudi Arabia",
            "searchName": "ELQ-Gassim-Buraidah-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "DWD",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Dawadmi",
            "name": "[DWD] - Dawadmi - Saudi Arabia",
            "searchName": "DWD-Dawadmi-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "EAM",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Nejran",
            "name": "[EAM] - Nejran - Saudi Arabia",
            "searchName": "EAM-Nejran-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "KMW",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Kostroma",
            "name": "[KMW] - Kostroma - Russia",
            "searchName": "KMW-Kostroma-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ZZO",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Zonalnoye",
            "name": "[ZZO] - Zonalnoye - Russia",
            "searchName": "ZZO-Zonalnoye-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KME",
            "countryCode": "RW",
            "cityCode": null,
            "cityName": "Kamembe",
            "name": "[KME] - Kamembe - Rwanda",
            "searchName": "KME-Kamembe-Rwanda-Ruanda",
            "priority": 0
        },
        {
            "iataCode": "KGL",
            "countryCode": "RW",
            "cityCode": null,
            "cityName": "Kigali",
            "name": "[KGL] - Kigali - Rwanda",
            "searchName": "KGL-Kigali-Rwanda-Ruanda",
            "priority": 0
        },
        {
            "iataCode": "GYI",
            "countryCode": "RW",
            "cityCode": null,
            "cityName": "Gisenyi",
            "name": "[GYI] - Gisenyi - Rwanda",
            "searchName": "GYI-Gisenyi-Rwanda-Ruanda",
            "priority": 0
        },
        {
            "iataCode": "BTQ",
            "countryCode": "RW",
            "cityCode": null,
            "cityName": "Butare",
            "name": "[BTQ] - Butare - Rwanda",
            "searchName": "BTQ-Butare-Rwanda-Ruanda",
            "priority": 0
        },
        {
            "iataCode": "RHG",
            "countryCode": "RW",
            "cityCode": null,
            "cityName": "Ruhengeri",
            "name": "[RHG] - Ruhengeri - Rwanda",
            "searchName": "RHG-Ruhengeri-Rwanda-Ruanda",
            "priority": 0
        },
        {
            "iataCode": "RAH",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Rafha",
            "name": "[RAH] - Rafha - Saudi Arabia",
            "searchName": "RAH-Rafha-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "RAE",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Arar",
            "name": "[RAE] - Arar - Saudi Arabia",
            "searchName": "RAE-Arar-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "QCA",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Makkah",
            "name": "[QCA] - Makkah - Saudi Arabia",
            "searchName": "QCA-Makkah-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "SHW",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Sharurah",
            "name": "[SHW] - Sharurah - Saudi Arabia",
            "searchName": "SHW-Sharurah-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "SLF",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Sulayel",
            "name": "[SLF] - Sulayel - Saudi Arabia",
            "searchName": "SLF-Sulayel-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "KYZ",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Kyzyl",
            "name": "[KYZ] - Kyzyl - Russia",
            "searchName": "KYZ-Kyzyl-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KGP",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Kogalym",
            "name": "[KGP] - Kogalym - Russia",
            "searchName": "KGP-Kogalym-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "LDG",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Leshukonskoye",
            "name": "[LDG] - Leshukonskoye - Russia",
            "searchName": "LDG-Leshukonskoye-Lourdes-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "CEE",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Cherepovets",
            "name": "[CEE] - Cherepovets - Russia",
            "searchName": "CEE-Tscherepowez-Cherepovets-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "DKS",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Dikson",
            "name": "[DKS] - Dikson - Russia",
            "searchName": "DKS-Dikson-Dakar-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KMC",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "King Khalid Mil. City",
            "name": "[KMC] - King Khalid Mil. City - Saudi Arabia",
            "searchName": "KMC-King Khalid Mil. City-King Khalid Military City-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "KMX",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Khamis Mushait",
            "name": "[KMX] - Khamis Mushait - Saudi Arabia",
            "searchName": "KMX-Khamis Mushait-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "MED",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Madinah",
            "name": "[MED] - Madinah - Saudi Arabia",
            "searchName": "MED-Madinah-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "MBU",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Mbambanakira",
            "name": "[MBU] - Mbambanakira - Solomon Islands",
            "searchName": "MBU-Mbambanakira-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "KGE",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Kagau",
            "name": "[KGE] - Kagau - Solomon Islands",
            "searchName": "KGE-Kagau-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "KWR",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Kwai Harbour",
            "name": "[KWR] - Kwai Harbour - Solomon Islands",
            "searchName": "KWR-Kwai Harbour-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "KWS",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Kwailabesi",
            "name": "[KWS] - Kwailabesi - Solomon Islands",
            "searchName": "KWS-Kwailabesi-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "KUE",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Kukundu",
            "name": "[KUE] - Kukundu - Solomon Islands",
            "searchName": "KUE-Kukundu-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "JJA",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Jajao",
            "name": "[JJA] - Jajao - Solomon Islands",
            "searchName": "JJA-Jajao-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "IRA",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Kirakira",
            "name": "[IRA] - Kirakira - Solomon Islands",
            "searchName": "IRA-Kirakira-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "GEF",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Geva",
            "name": "[GEF] - Geva - Solomon Islands",
            "searchName": "GEF-Geva-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "HIR",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Honiara",
            "name": "[HIR] - Honiara - Solomon Islands",
            "searchName": "HIR-Honiara-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "GZO",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Gizo",
            "name": "[GZO] - Gizo - Solomon Islands",
            "searchName": "GZO-Gizo-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "GTA",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Gatokae",
            "name": "[GTA] - Gatokae - Solomon Islands",
            "searchName": "GTA-Gatokae-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "GSI",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Guadalcanal",
            "name": "[GSI] - Guadalcanal - Solomon Islands",
            "searchName": "GSI-Guadalcanal-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "CHY",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Choiseul Bay",
            "name": "[CHY] - Choiseul Bay - Solomon Islands",
            "searchName": "CHY-Choiseul Bay-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "EGM",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Sege",
            "name": "[EGM] - Sege - Solomon Islands",
            "searchName": "EGM-Sege-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "FRE",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Fera Island",
            "name": "[FRE] - Fera Island - Solomon Islands",
            "searchName": "FRE-Fera Island--Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "ATD",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Atoifi",
            "name": "[ATD] - Atoifi - Solomon Islands",
            "searchName": "ATD-Atoifi-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "BAS",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Balalae",
            "name": "[BAS] - Balalae - Solomon Islands",
            "searchName": "BAS-Balalae-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "AVU",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Avu Avu",
            "name": "[AVU] - Avu Avu - Solomon Islands",
            "searchName": "AVU-Avu Avu-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "AKS",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Auki",
            "name": "[AKS] - Auki - Solomon Islands",
            "searchName": "AKS-Auki-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "ANH",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Anuha Island Resort",
            "name": "[ANH] - Anuha Island Resort - Solomon Islands",
            "searchName": "ANH-Anuha Island Resort-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "AFT",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Afutara",
            "name": "[AFT] - Afutara - Solomon Islands",
            "searchName": "AFT-Afutara-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "BNY",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Bellona",
            "name": "[BNY] - Bellona - Solomon Islands",
            "searchName": "BNY-Bellona-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "BPF",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Batuna",
            "name": "[BPF] - Batuna - Solomon Islands",
            "searchName": "BPF-Batuna-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "VAO",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Suavanao",
            "name": "[VAO] - Suavanao - Solomon Islands",
            "searchName": "VAO-Suavanao-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "VEV",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Barakoma",
            "name": "[VEV] - Barakoma - Solomon Islands",
            "searchName": "VEV-Barakoma-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "VIU",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Viru",
            "name": "[VIU] - Viru - Solomon Islands",
            "searchName": "VIU-Viru-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "TLG",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Tulagi Island",
            "name": "[TLG] - Tulagi Island - Solomon Islands",
            "searchName": "TLG-Tulagi Island-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "TAA",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Tarapaina",
            "name": "[TAA] - Tarapaina - Solomon Islands",
            "searchName": "TAA-Tarapaina-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "SVY",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Savo",
            "name": "[SVY] - Savo - Solomon Islands",
            "searchName": "SVY-Savo-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "XYA",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Yandina",
            "name": "[XYA] - Yandina - Solomon Islands",
            "searchName": "XYA-Yandina-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "RUS",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Marau Sound",
            "name": "[RUS] - Marau Sound - Solomon Islands",
            "searchName": "RUS-Marau Sound-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "SCZ",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Santa Cruz Island",
            "name": "[SCZ] - Santa Cruz Island - Solomon Islands",
            "searchName": "SCZ-Santa Cruz Is-Santa Cruz Island-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "RRI",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Barora",
            "name": "[RRI] - Barora - Solomon Islands",
            "searchName": "RRI-Barora-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "RNL",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Rennell",
            "name": "[RNL] - Rennell - Solomon Islands",
            "searchName": "RNL-Rennell-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "RNA",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Arona",
            "name": "[RNA] - Arona - Solomon Islands",
            "searchName": "RNA-Arona-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "RBV",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Ramata",
            "name": "[RBV] - Ramata - Solomon Islands",
            "searchName": "RBV-Ramata-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "RIN",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Ringi Cove",
            "name": "[RIN] - Ringi Cove - Solomon Islands",
            "searchName": "RIN-Ringi Cove-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "PRS",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Parasi",
            "name": "[PRS] - Parasi - Solomon Islands",
            "searchName": "PRS-Parasi-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "NAZ",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Star Habour",
            "name": "[NAZ] - Star Habour - Solomon Islands",
            "searchName": "NAZ-Star Habour-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "NNB",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Santa Ana",
            "name": "[NNB] - Santa Ana - Solomon Islands",
            "searchName": "NNB-Santa Ana-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "MNY",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Mono",
            "name": "[MNY] - Mono - Solomon Islands",
            "searchName": "MNY-Mono-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "MUA",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Munda",
            "name": "[MUA] - Munda - Solomon Islands",
            "searchName": "MUA-Munda-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "OTV",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Ontong Java",
            "name": "[OTV] - Ontong Java - Solomon Islands",
            "searchName": "OTV-Ontong Java-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "ONE",
            "countryCode": "SB",
            "cityCode": null,
            "cityName": "Onepusu",
            "name": "[ONE] - Onepusu - Solomon Islands",
            "searchName": "ONE-Onepusu-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "THN",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Trollhattan",
            "name": "[THN] - Trollhattan - Sweden",
            "searchName": "THN-Trollhattan-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XGC",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Lund",
            "name": "[XGC] - Lund - Sweden",
            "searchName": "XGC-Lund-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XEW",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Flemingsberg",
            "name": "[XEW] - Flemingsberg - Sweden",
            "searchName": "XEW-Flemingsberg-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XFH",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Strangnas",
            "name": "[XFH] - Strangnas - Sweden",
            "searchName": "XFH-Strangnas-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XFU",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Tierp",
            "name": "[XFU] - Tierp - Sweden",
            "searchName": "XFU-Tierp-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYC",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Herrljunga",
            "name": "[XYC] - Herrljunga - Sweden",
            "searchName": "XYC-Herrljunga-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYU",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Solvesborg",
            "name": "[XYU] - Solvesborg - Sweden",
            "searchName": "XYU-Solvesborg-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYX",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Sala",
            "name": "[XYX] - Sala - Sweden",
            "searchName": "XYX-Sala-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYY",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Arvika",
            "name": "[XYY] - Arvika - Sweden",
            "searchName": "XYY-Arvika-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYF",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Falkoping",
            "name": "[XYF] - Falkoping - Sweden",
            "searchName": "XYF-Falkoping-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYI",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Flen",
            "name": "[XYI] - Flen - Sweden",
            "searchName": "XYI-Flen-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYM",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Falkenberg",
            "name": "[XYM] - Falkenberg - Sweden",
            "searchName": "XYM-Falkenberg-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYN",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Kristinehamn",
            "name": "[XYN] - Kristinehamn - Sweden",
            "searchName": "XYN-Kristinehamn-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYO",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Karlshamn",
            "name": "[XYO] - Karlshamn - Sweden",
            "searchName": "XYO-Karlshamn-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYP",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Avesta Krylbo",
            "name": "[XYP] - Avesta Krylbo - Sweden",
            "searchName": "XYP-Avesta Krylbo-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XXT",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Arboga",
            "name": "[XXT] - Arboga - Sweden",
            "searchName": "XXT-Arboga-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XXU",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Hedemora",
            "name": "[XXU] - Hedemora - Sweden",
            "searchName": "XXU-Hedemora-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XXV",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Kumla",
            "name": "[XXV] - Kumla - Sweden",
            "searchName": "XXV-Kumla-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XXM",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Mjolby",
            "name": "[XXM] - Mjolby - Sweden",
            "searchName": "XXM-Mjolby-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XXN",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Kil",
            "name": "[XXN] - Kil - Sweden",
            "searchName": "XXN-Kil-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XXO",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Leksand",
            "name": "[XXO] - Leksand - Sweden",
            "searchName": "XXO-Leksand-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XXI",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Koping",
            "name": "[XXI] - Koping - Sweden",
            "searchName": "XXI-Koping-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XXK",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Katrineholm",
            "name": "[XXK] - Katrineholm - Sweden",
            "searchName": "XXK-Katrineholm-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XXD",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Degerfors",
            "name": "[XXD] - Degerfors - Sweden",
            "searchName": "XXD-Degerfors-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XWZ",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Nykoping",
            "name": "[XWZ] - Nykoping - Sweden",
            "searchName": "XWZ-Nykoping-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XXA",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Alvesta",
            "name": "[XXA] - Alvesta - Sweden",
            "searchName": "XXA-Alvesta-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XWP",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Hassleholm",
            "name": "[XWP] - Hassleholm - Sweden",
            "searchName": "XWP-H-Hassleholm-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XWQ",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Enkoping",
            "name": "[XWQ] - Enkoping - Sweden",
            "searchName": "XWQ-Enkoping-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XWV",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Varberg",
            "name": "[XWV] - Varberg - Sweden",
            "searchName": "XWV-Varberg-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XWX",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Nassjo",
            "name": "[XWX] - Nassjo - Sweden",
            "searchName": "XWX-Nassjo-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XWM",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Hallsberg",
            "name": "[XWM] - Hallsberg - Sweden",
            "searchName": "XWM-Hallsberg-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "MXX",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Mora",
            "name": "[MXX] - Mora - Sweden",
            "searchName": "MXX-Mora-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "BDI",
            "countryCode": "SC",
            "cityCode": null,
            "cityName": "Bird Island",
            "name": "[BDI] - Bird Island - Escocia",
            "searchName": "BDI-Bird Island-Escocia",
            "priority": 0
        },
        {
            "iataCode": "FRK",
            "countryCode": "SC",
            "cityCode": null,
            "cityName": "Fregate Island",
            "name": "[FRK] - Fregate Island - Escocia",
            "searchName": "FRK-Fregate Island-Escocia",
            "priority": 0
        },
        {
            "iataCode": "DEI",
            "countryCode": "SC",
            "cityCode": null,
            "cityName": "Denis Island",
            "name": "[DEI] - Denis Island - Escocia",
            "searchName": "DEI-Denis Island-Escocia",
            "priority": 0
        },
        {
            "iataCode": "DES",
            "countryCode": "SC",
            "cityCode": null,
            "cityName": "Desroches",
            "name": "[DES] - Desroches - Escocia",
            "searchName": "DES-Desroches-Escocia",
            "priority": 0
        },
        {
            "iataCode": "EGN",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "Geneina",
            "name": "[EGN] - Geneina - Sudan",
            "searchName": "EGN-Geneina-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "ELF",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "El Fasher",
            "name": "[ELF] - El Fasher - Sudan",
            "searchName": "ELF-El Fasher-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "EBD",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "El Obeid",
            "name": "[EBD] - El Obeid - Sudan",
            "searchName": "EBD-Al-Ubayyid-El Obeid-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "EDB",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "Eldebba",
            "name": "[EDB] - Eldebba - Sudan",
            "searchName": "EDB-Eldebba-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "DOG",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "Dongola",
            "name": "[DOG] - Dongola - Sudan",
            "searchName": "DOG-Dongola-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "DNX",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "Dinder",
            "name": "[DNX] - Dinder - Sudan",
            "searchName": "DNX-Dinder-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "DNI",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "Wad Medani",
            "name": "[DNI] - Wad Medani - Sudan",
            "searchName": "DNI-Wad Medani-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "ATB",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "Atbara",
            "name": "[ATB] - Atbara - Sudan",
            "searchName": "ATB-Atbara-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "BJE",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "Baleela",
            "name": "[BJE] - Baleela - Sudan",
            "searchName": "BJE-Baleela-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "GSU",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "Gedaref",
            "name": "[GSU] - Gedaref - Sudan",
            "searchName": "GSU-Gedaref-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "HGI",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "Higlieg",
            "name": "[HGI] - Higlieg - Sudan",
            "searchName": "HGI-Higlieg-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "GBU",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "Khashm El Girba",
            "name": "[GBU] - Khashm El Girba - Sudan",
            "searchName": "GBU-Khashm El Girba-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "KRT",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "Khartoum",
            "name": "[KRT] - Khartoum - Sudan",
            "searchName": "KRT-Khartum-Khartoum-Hartum-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "KSL",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "Kassala",
            "name": "[KSL] - Kassala - Sudan",
            "searchName": "KSL-Kassala-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "KST",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "Kosti",
            "name": "[KST] - Kosti - Sudan",
            "searchName": "KST-Kosti-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "KDX",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "Kadugli",
            "name": "[KDX] - Kadugli - Sudan",
            "searchName": "KDX-Kadugli-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "RSS",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "Roseires",
            "name": "[RSS] - Roseires - Sudan",
            "searchName": "RSS-Roseires-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "PZU",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "Port Sudan",
            "name": "[PZU] - Port Sudan - Sudan",
            "searchName": "PZU-Port Sudan-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "NUD",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "En Nahud",
            "name": "[NUD] - En Nahud - Sudan",
            "searchName": "NUD-En Nahud-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "NHF",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "New Halfa",
            "name": "[NHF] - New Halfa - Sudan",
            "searchName": "NHF-New Halfa-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "MWE",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "Merowe",
            "name": "[MWE] - Merowe - Sudan",
            "searchName": "MWE-Merowe-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "WHF",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "Wadi Halfa",
            "name": "[WHF] - Wadi Halfa - Sudan",
            "searchName": "WHF-Wadi Halfa-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "UYL",
            "countryCode": "SD",
            "cityCode": null,
            "cityName": "Nyala",
            "name": "[UYL] - Nyala - Sudan",
            "searchName": "UYL-Nyala-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "VBY",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Visby",
            "name": "[VBY] - Visby - Sweden",
            "searchName": "VBY-Visby-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "VHM",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Vilhelmina",
            "name": "[VHM] - Vilhelmina - Sweden",
            "searchName": "VHM-Vilhelmina-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "VXO",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Växjö",
            "name": "[VXO] - Växjö - Sweden",
            "searchName": "VXO-V-Växjö-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "VVK",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Vastervik",
            "name": "[VVK] - Vastervik - Sweden",
            "searchName": "VVK-Vastervik-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "TYF",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Torsby",
            "name": "[TYF] - Torsby - Sweden",
            "searchName": "TYF-Torsby-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "UME",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Umea",
            "name": "[UME] - Umea - Sweden",
            "searchName": "UME-Ume-Umea-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "POV",
            "countryCode": "SK",
            "cityCode": null,
            "cityName": "Presov",
            "name": "[POV] - Presov - Slovakia",
            "searchName": "POV-Presov-Slowakia-Slowakei-Slovakia-Slovacka-Slovaquie-Slovensko-Slovakya-Eslovàquia-Szlovákia-Slovacia-Slovaška-Slovakija-Slovakkia-Slowacja-S-Eslovaquia-Slovacchia",
            "priority": 0
        },
        {
            "iataCode": "PZY",
            "countryCode": "SK",
            "cityCode": null,
            "cityName": "Piestany",
            "name": "[PZY] - Piestany - Slovakia",
            "searchName": "PZY-Piestany-Slowakia-Slowakei-Slovakia-Slovacka-Slovaquie-Slovensko-Slovakya-Eslovàquia-Szlovákia-Slovacia-Slovaška-Slovakija-Slovakkia-Slowacja-S-Eslovaquia-Slovacchia",
            "priority": 0
        },
        {
            "iataCode": "SLD",
            "countryCode": "SK",
            "cityCode": null,
            "cityName": "Sliac",
            "name": "[SLD] - Sliac - Slovakia",
            "searchName": "SLD-Sliac-Slowakia-Slowakei-Slovakia-Slovacka-Slovaquie-Slovensko-Slovakya-Eslovàquia-Szlovákia-Slovacia-Slovaška-Slovakija-Slovakkia-Slowacja-S-Eslovaquia-Slovacchia",
            "priority": 0
        },
        {
            "iataCode": "TAT",
            "countryCode": "SK",
            "cityCode": null,
            "cityName": "Poprad",
            "name": "[TAT] - Poprad - Slovakia",
            "searchName": "TAT-Poprad/Tatry-Poprad-Poprad\\/Tatry-Slowakia-Slowakei-Slovakia-Slovacka-Slovaquie-Slovensko-Slovakya-Eslovàquia-Szlovákia-Slovacia-Slovaška-Slovakija-Slovakkia-Slowacja-S-Eslovaquia-Slovacchia",
            "priority": 0
        },
        {
            "iataCode": "WYE",
            "countryCode": "SL",
            "cityCode": null,
            "cityName": "Yengema",
            "name": "[WYE] - Yengema - Sierra Leone",
            "searchName": "WYE-Yengema-Sierra Leone-Sierra Leona",
            "priority": 0
        },
        {
            "iataCode": "BTE",
            "countryCode": "SL",
            "cityCode": null,
            "cityName": "Bonthe",
            "name": "[BTE] - Bonthe - Sierra Leone",
            "searchName": "BTE-Bonthe-Sierra Leone-Sierra Leona",
            "priority": 0
        },
        {
            "iataCode": "FNA",
            "countryCode": "SL",
            "cityCode": null,
            "cityName": "Freetown",
            "name": "[FNA] - Freetown - Sierra Leone",
            "searchName": "FNA-Freetown-Sierra Leone-Sierra Leona",
            "priority": 0
        },
        {
            "iataCode": "GBK",
            "countryCode": "SL",
            "cityCode": null,
            "cityName": "Gbangbatok",
            "name": "[GBK] - Gbangbatok - Sierra Leone",
            "searchName": "GBK-Gbangbatok-Sierra Leone-Sierra Leona",
            "priority": 0
        },
        {
            "iataCode": "KBS",
            "countryCode": "SL",
            "cityCode": null,
            "cityName": "Bo",
            "name": "[KBS] - Bo - Sierra Leone",
            "searchName": "KBS-Bo-Sierra Leone-Sierra Leona",
            "priority": 0
        },
        {
            "iataCode": "KBA",
            "countryCode": "SL",
            "cityCode": null,
            "cityName": "Kabala",
            "name": "[KBA] - Kabala - Sierra Leone",
            "searchName": "KBA-Kabala-Sierra Leone-Sierra Leona",
            "priority": 0
        },
        {
            "iataCode": "KEN",
            "countryCode": "SL",
            "cityCode": null,
            "cityName": "Kenema",
            "name": "[KEN] - Kenema - Sierra Leone",
            "searchName": "KEN-Kenema-Sierra Leone-Sierra Leona",
            "priority": 0
        },
        {
            "iataCode": "KGG",
            "countryCode": "SN",
            "cityCode": null,
            "cityName": "Kedougou",
            "name": "[KGG] - Kedougou - Senegal",
            "searchName": "KGG-Kedougou-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "KDA",
            "countryCode": "SN",
            "cityCode": null,
            "cityName": "Kolda",
            "name": "[KDA] - Kolda - Senegal",
            "searchName": "KDA-Kolda-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "KLC",
            "countryCode": "SN",
            "cityCode": null,
            "cityName": "Kaolack",
            "name": "[KLC] - Kaolack - Senegal",
            "searchName": "KLC-Kaolack-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "MAX",
            "countryCode": "SN",
            "cityCode": null,
            "cityName": "Matam",
            "name": "[MAX] - Matam - Senegal",
            "searchName": "MAX-Matam-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "DKR",
            "countryCode": "SN",
            "cityCode": null,
            "cityName": "Dakar",
            "name": "[DKR] - Dakar - Senegal",
            "searchName": "DKR-Dakar-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "CSK",
            "countryCode": "SN",
            "cityCode": null,
            "cityName": "Cap Skirring",
            "name": "[CSK] - Cap Skirring - Senegal",
            "searchName": "CSK-Cap Skirring-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "BXE",
            "countryCode": "SN",
            "cityCode": null,
            "cityName": "Bakel",
            "name": "[BXE] - Bakel - Senegal",
            "searchName": "BXE-Bakel-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "XLS",
            "countryCode": "SN",
            "cityCode": null,
            "cityName": "St Louis",
            "name": "[XLS] - St Louis - Senegal",
            "searchName": "XLS-St Louis-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "ZIG",
            "countryCode": "SN",
            "cityCode": null,
            "cityName": "Ziguinchor",
            "name": "[ZIG] - Ziguinchor - Senegal",
            "searchName": "ZIG-Ziguinchor-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "TUD",
            "countryCode": "SN",
            "cityCode": null,
            "cityName": "Tambacounda",
            "name": "[TUD] - Tambacounda - Senegal",
            "searchName": "TUD-Tambacounda-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "SMY",
            "countryCode": "SN",
            "cityCode": null,
            "cityName": "Simenti",
            "name": "[SMY] - Simenti - Senegal",
            "searchName": "SMY-Simenti-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "RDT",
            "countryCode": "SN",
            "cityCode": null,
            "cityName": "Richard Toll",
            "name": "[RDT] - Richard Toll - Senegal",
            "searchName": "RDT-Richard Toll-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "POD",
            "countryCode": "SN",
            "cityCode": null,
            "cityName": "Podor",
            "name": "[POD] - Podor - Senegal",
            "searchName": "POD-Podor-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "NIK",
            "countryCode": "SN",
            "cityCode": null,
            "cityName": "Niokolo Koba",
            "name": "[NIK] - Niokolo Koba - Senegal",
            "searchName": "NIK-Niokolo Koba-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "BXX",
            "countryCode": "SO",
            "cityCode": null,
            "cityName": "Borama",
            "name": "[BXX] - Borama - Somalia",
            "searchName": "BXX-Borama-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "BUO",
            "countryCode": "SO",
            "cityCode": null,
            "cityName": "Burao",
            "name": "[BUO] - Burao - Somalia",
            "searchName": "BUO-Burao-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "BSY",
            "countryCode": "SO",
            "cityCode": null,
            "cityName": "Bardera",
            "name": "[BSY] - Bardera - Somalia",
            "searchName": "BSY-Bardera-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "BSA",
            "countryCode": "SO",
            "cityCode": null,
            "cityName": "Bossaso",
            "name": "[BSA] - Bossaso - Somalia",
            "searchName": "BSA-Bossaso-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "BIB",
            "countryCode": "SO",
            "cityCode": null,
            "cityName": "Baidoa",
            "name": "[BIB] - Baidoa - Somalia",
            "searchName": "BIB-Baidoa-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "BBO",
            "countryCode": "SO",
            "cityCode": null,
            "cityName": "Berbera",
            "name": "[BBO] - Berbera - Somalia",
            "searchName": "BBO-Berbera-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "ALU",
            "countryCode": "SO",
            "cityCode": null,
            "cityName": "Alula",
            "name": "[ALU] - Alula - Somalia",
            "searchName": "ALU-Alula-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "CXN",
            "countryCode": "SO",
            "cityCode": null,
            "cityName": "Candala",
            "name": "[CXN] - Candala - Somalia",
            "searchName": "CXN-Candala-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "CMS",
            "countryCode": "SO",
            "cityCode": null,
            "cityName": "Scusciuban",
            "name": "[CMS] - Scusciuban - Somalia",
            "searchName": "CMS-Scusciuban-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "CMO",
            "countryCode": "SO",
            "cityCode": null,
            "cityName": "Obbia",
            "name": "[CMO] - Obbia - Somalia",
            "searchName": "CMO-Obbia-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "ERA",
            "countryCode": "SO",
            "cityCode": null,
            "cityName": "Erigavo",
            "name": "[ERA] - Erigavo - Somalia",
            "searchName": "ERA-Erigavo-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "MGQ",
            "countryCode": "SO",
            "cityCode": null,
            "cityName": "Mogadishu",
            "name": "[MGQ] - Mogadishu - Somalia",
            "searchName": "MGQ-Muqdisho-Mogadishu-Mogadiscio-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "LKR",
            "countryCode": "SO",
            "cityCode": null,
            "cityName": "Las Khoreh",
            "name": "[LKR] - Las Khoreh - Somalia",
            "searchName": "LKR-Las Khoreh-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "LGX",
            "countryCode": "SO",
            "cityCode": null,
            "cityName": "Lugh Ganane",
            "name": "[LGX] - Lugh Ganane - Somalia",
            "searchName": "LGX-Lugh Ganane-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "KMU",
            "countryCode": "SO",
            "cityCode": null,
            "cityName": "Kismayu",
            "name": "[KMU] - Kismayu - Somalia",
            "searchName": "KMU-Kismayu-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "GBM",
            "countryCode": "SO",
            "cityCode": null,
            "cityName": "Garbaharey",
            "name": "[GBM] - Garbaharey - Somalia",
            "searchName": "GBM-Garbaharey-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "GGR",
            "countryCode": "SO",
            "cityCode": null,
            "cityName": "Garoe",
            "name": "[GGR] - Garoe - Somalia",
            "searchName": "GGR-Garoe-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "GLK",
            "countryCode": "SO",
            "cityCode": null,
            "cityName": "Galcaio",
            "name": "[GLK] - Galcaio - Somalia",
            "searchName": "GLK-Gaalkacyo-Galcaio-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "GSR",
            "countryCode": "SO",
            "cityCode": null,
            "cityName": "Gardo",
            "name": "[GSR] - Gardo - Somalia",
            "searchName": "GSR-Gardo-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "HGA",
            "countryCode": "SO",
            "cityCode": null,
            "cityName": "Hargeisa",
            "name": "[HGA] - Hargeisa - Somalia",
            "searchName": "HGA-Hargeisa-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "HCM",
            "countryCode": "SO",
            "cityCode": null,
            "cityName": "Eil",
            "name": "[HCM] - Eil - Somalia",
            "searchName": "HCM-Eil-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "ICK",
            "countryCode": "SR",
            "cityCode": null,
            "cityName": "Nieuw Nickerie",
            "name": "[ICK] - Nieuw Nickerie - Suriname",
            "searchName": "ICK-Nieuw Nickerie-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "KCB",
            "countryCode": "SR",
            "cityCode": null,
            "cityName": "Kasikasima",
            "name": "[KCB] - Kasikasima - Suriname",
            "searchName": "KCB-Kasikasima-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "LDO",
            "countryCode": "SR",
            "cityCode": null,
            "cityName": "Ladouanie",
            "name": "[LDO] - Ladouanie - Suriname",
            "searchName": "LDO-Ladouanie-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "DOE",
            "countryCode": "SR",
            "cityCode": null,
            "cityName": "Djoemoe",
            "name": "[DOE] - Djoemoe - Suriname",
            "searchName": "DOE-Djoemoe-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "DRJ",
            "countryCode": "SR",
            "cityCode": null,
            "cityName": "Drietabbetje",
            "name": "[DRJ] - Drietabbetje - Suriname",
            "searchName": "DRJ-Drietabbetje-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "AGI",
            "countryCode": "SR",
            "cityCode": null,
            "cityName": "Wageningen",
            "name": "[AGI] - Wageningen - Suriname",
            "searchName": "AGI-Wageningen-Wagenigen-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "ABN",
            "countryCode": "SR",
            "cityCode": null,
            "cityName": "Albina",
            "name": "[ABN] - Albina - Suriname",
            "searchName": "ABN-Albina-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "AAJ",
            "countryCode": "SR",
            "cityCode": null,
            "cityName": "Awaradam",
            "name": "[AAJ] - Awaradam - Suriname",
            "searchName": "AAJ-Awaradam-Cayana-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "BTO",
            "countryCode": "SR",
            "cityCode": null,
            "cityName": "Botopasie",
            "name": "[BTO] - Botopasie - Suriname",
            "searchName": "BTO-Botopasie-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "MOJ",
            "countryCode": "SR",
            "cityCode": null,
            "cityName": "Moengo",
            "name": "[MOJ] - Moengo - Suriname",
            "searchName": "MOJ-Moengo-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "PBM",
            "countryCode": "SR",
            "cityCode": null,
            "cityName": "Paramaribo",
            "name": "[PBM] - Paramaribo - Suriname",
            "searchName": "PBM-Paramaribo-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "OEM",
            "countryCode": "SR",
            "cityCode": null,
            "cityName": "Paloemeu",
            "name": "[OEM] - Paloemeu - Suriname",
            "searchName": "OEM-Paloemeu-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "SMZ",
            "countryCode": "SR",
            "cityCode": null,
            "cityName": "Stoelmans Eiland",
            "name": "[SMZ] - Stoelmans Eiland - Suriname",
            "searchName": "SMZ-Stoelmans Eiland-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "TOT",
            "countryCode": "SR",
            "cityCode": null,
            "cityName": "Totness",
            "name": "[TOT] - Totness - Suriname",
            "searchName": "TOT-Totness-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "WSO",
            "countryCode": "SR",
            "cityCode": null,
            "cityName": "Washabo",
            "name": "[WSO] - Washabo - Suriname",
            "searchName": "WSO-Washabo-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "WUU",
            "countryCode": "SS",
            "cityCode": null,
            "cityName": "Wau",
            "name": "[WUU] - Wau - South Sudan",
            "searchName": "WUU-Wau-South Sudan-Sudan-Sudán del Sur-Soudan du Sud-Sudan del Sud",
            "priority": 0
        },
        {
            "iataCode": "RBX",
            "countryCode": "SS",
            "cityCode": null,
            "cityName": "Rumbek",
            "name": "[RBX] - Rumbek - South Sudan",
            "searchName": "RBX-Rumbek-South Sudan-Sudan-Sudán del Sur-Soudan du Sud-Sudan del Sud",
            "priority": 0
        },
        {
            "iataCode": "AEE",
            "countryCode": "SS",
            "cityCode": null,
            "cityName": "Adareil",
            "name": "[AEE] - Adareil - South Sudan",
            "searchName": "AEE-Adareil-South Sudan-Sudan-Sudán del Sur-Soudan du Sud-Sudan del Sud",
            "priority": 0
        },
        {
            "iataCode": "MAK",
            "countryCode": "SS",
            "cityCode": null,
            "cityName": "Malakal",
            "name": "[MAK] - Malakal - South Sudan",
            "searchName": "MAK-Malakal-South Sudan-Sudan-Sudán del Sur-Soudan du Sud-Sudan del Sud",
            "priority": 0
        },
        {
            "iataCode": "JUB",
            "countryCode": "SS",
            "cityCode": null,
            "cityName": "Juba",
            "name": "[JUB] - Juba - South Sudan",
            "searchName": "JUB-Juba-South Sudan-Sudan-Sudán del Sur-Soudan du Sud-Sudan del Sud",
            "priority": 0
        },
        {
            "iataCode": "PCP",
            "countryCode": "ST",
            "cityCode": null,
            "cityName": "Principe",
            "name": "[PCP] - Principe - Sao Tome and Principe",
            "searchName": "PCP-Principe-São Tomé und Príncipe-Sao Tome and Principe-Sao Tomé-et-Príncipe-Santo Tomé y Príncipe-Sao Tomé e Príncipe",
            "priority": 0
        },
        {
            "iataCode": "PGP",
            "countryCode": "ST",
            "cityCode": null,
            "cityName": "Porto Alegre",
            "name": "[PGP] - Porto Alegre - Sao Tome and Principe",
            "searchName": "PGP-Porto Alegre-São Tomé und Príncipe-Sao Tome and Principe-Sao Tomé-et-Príncipe-Santo Tomé y Príncipe-Sao Tomé e Príncipe",
            "priority": 0
        },
        {
            "iataCode": "TMS",
            "countryCode": "ST",
            "cityCode": null,
            "cityName": "Sao Tome Island",
            "name": "[TMS] - Sao Tome Island - Sao Tome and Principe",
            "searchName": "TMS-S-Sao Tome Island-São Tomé und Príncipe-Sao Tome and Principe-Sao Tomé-et-Príncipe-Santo Tomé y Príncipe-Sao Tomé e Príncipe",
            "priority": 0
        },
        {
            "iataCode": "SAL",
            "countryCode": "SV",
            "cityCode": null,
            "cityName": "San Salvador",
            "name": "[SAL] - San Salvador - El Salvador",
            "searchName": "SAL-San Salvador-Salvador-El Salvador",
            "priority": 0
        },
        {
            "iataCode": "SXM",
            "countryCode": "SX",
            "cityCode": null,
            "cityName": "Philipsburg",
            "name": "[SXM] - Philipsburg - Sint Maarten",
            "searchName": "SXM-Simpson Bay-Philipsburg-Saint Martin-Saint-Martin-Saint Maarten-Sint Maarten (Netherlands)-Sint Maarten-Hollanda Antilleri-Martin (partie néerlandaise)",
            "priority": 0
        },
        {
            "iataCode": "SOR",
            "countryCode": "SY",
            "cityCode": null,
            "cityName": "Al Thaurah",
            "name": "[SOR] - Al Thaurah - Syria",
            "searchName": "SOR-Al Thaurah-Syrien-Syria-Syrie-Siria",
            "priority": 0
        },
        {
            "iataCode": "PMS",
            "countryCode": "SY",
            "cityCode": null,
            "cityName": "Palmyra",
            "name": "[PMS] - Palmyra - Syria",
            "searchName": "PMS-Palmyra-Palmira-Syrien-Syria-Syrie-Siria",
            "priority": 0
        },
        {
            "iataCode": "KAC",
            "countryCode": "SY",
            "cityCode": null,
            "cityName": "Kameshli",
            "name": "[KAC] - Kameshli - Syria",
            "searchName": "KAC-Kameshli-Syrien-Syria-Syrie-Siria",
            "priority": 0
        },
        {
            "iataCode": "LTK",
            "countryCode": "SY",
            "cityCode": null,
            "cityName": "Latakia",
            "name": "[LTK] - Latakia - Syria",
            "searchName": "LTK-Latakia-Syrien-Syria-Syrie-Siria",
            "priority": 0
        },
        {
            "iataCode": "ALP",
            "countryCode": "SY",
            "cityCode": null,
            "cityName": "Aleppo",
            "name": "[ALP] - Aleppo - Syria",
            "searchName": "ALP-Aleppo-Syrien-Syria-Syrie-Siria",
            "priority": 0
        },
        {
            "iataCode": "DEZ",
            "countryCode": "SY",
            "cityCode": null,
            "cityName": "Deirezzor",
            "name": "[DEZ] - Deirezzor - Syria",
            "searchName": "DEZ-Deirezzor-Syrien-Syria-Syrie-Siria",
            "priority": 0
        },
        {
            "iataCode": "NRK",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Norrkoping",
            "name": "[NRK] - Norrkoping - Sweden",
            "searchName": "NRK-Norrk-Norrkoping-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "OER",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Ornskoldsvik",
            "name": "[OER] - Ornskoldsvik - Sweden",
            "searchName": "OER-Ornskoldsvik-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "OSD",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Ostersund",
            "name": "[OSD] - Ostersund - Sweden",
            "searchName": "OSD-Ostersund-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "OSK",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Oskarshamn",
            "name": "[OSK] - Oskarshamn - Sweden",
            "searchName": "OSK-Oskarshamn-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "ORB",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Orebro",
            "name": "[ORB] - Orebro - Sweden",
            "searchName": "ORB-Orebro-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "QYX",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Uppsala",
            "name": "[QYX] - Uppsala - Sweden",
            "searchName": "QYX-Uppsala-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "PJA",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Pajala",
            "name": "[PJA] - Pajala - Sweden",
            "searchName": "PJA-Pajala-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "RNB",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Ronneby",
            "name": "[RNB] - Ronneby - Sweden",
            "searchName": "RNB-Ronneby-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "SDL",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Sundsvall",
            "name": "[SDL] - Sundsvall - Sweden",
            "searchName": "SDL-Sundsvall-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "DHA",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Dhahran",
            "name": "[DHA] - Dhahran - Saudi Arabia",
            "searchName": "DHA-Dhahran-Zahran-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "HAS",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Hail",
            "name": "[HAS] - Hail - Saudi Arabia",
            "searchName": "HAS-Ha il-Hail-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "HOF",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Alahsa",
            "name": "[HOF] - Alahsa - Saudi Arabia",
            "searchName": "HOF-Alahsa-Hofuf-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "GIZ",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Jazan",
            "name": "[GIZ] - Jazan - Saudi Arabia",
            "searchName": "GIZ-Jazan-Gizan-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "KCK",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Kirensk",
            "name": "[KCK] - Kirensk - Russia",
            "searchName": "KCK-Kirensk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KLF",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Kaluga",
            "name": "[KLF] - Kaluga - Russia",
            "searchName": "KLF-Kaluga-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KKQ",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Krasnoselkup",
            "name": "[KKQ] - Krasnoselkup - Russia",
            "searchName": "KKQ-Krasnoselkup-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KLD",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Tver",
            "name": "[KLD] - Tver - Russia",
            "searchName": "KLD-Kalinin-Tver-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "SOO",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Soderhamn",
            "name": "[SOO] - Soderhamn - Sweden",
            "searchName": "SOO-Soderhamn-S-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "KID",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Kristianstad",
            "name": "[KID] - Kristianstad - Sweden",
            "searchName": "KID-Kristianstad-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "KLR",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Kalmar",
            "name": "[KLR] - Kalmar - Sweden",
            "searchName": "KLR-Kalmar-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "KSD",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Karlstad",
            "name": "[KSD] - Karlstad - Sweden",
            "searchName": "KSD-Karlstad-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "KSK",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Karlskoga",
            "name": "[KSK] - Karlskoga - Sweden",
            "searchName": "KSK-Karlskoga-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "KRN",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Kiruna",
            "name": "[KRN] - Kiruna - Sweden",
            "searchName": "KRN-Kiruna-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "KRF",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Kramfors",
            "name": "[KRF] - Kramfors - Sweden",
            "searchName": "KRF-Kramfors-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "KVB",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Skovde",
            "name": "[KVB] - Skovde - Sweden",
            "searchName": "KVB-Skovde-Sk-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "LDK",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Lidkoping",
            "name": "[LDK] - Lidkoping - Sweden",
            "searchName": "LDK-Lidkoping-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "LYC",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Lycksele",
            "name": "[LYC] - Lycksele - Sweden",
            "searchName": "LYC-Lycksele-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "LPI",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Linkoping",
            "name": "[LPI] - Linkoping - Sweden",
            "searchName": "LPI-Link-Linkoping-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "LLA",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Lulea",
            "name": "[LLA] - Lulea - Sweden",
            "searchName": "LLA-Lule-Lulea-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "GEV",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Gallivare",
            "name": "[GEV] - Gallivare - Sweden",
            "searchName": "GEV-Gallivare-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "GOT",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Gothenburg",
            "name": "[GOT] - Gothenburg - Sweden",
            "searchName": "GOT-Goeteborg-Gothenburg-Gotemburgo-G-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "HFS",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Hagfors",
            "name": "[HFS] - Hagfors - Sweden",
            "searchName": "HFS-Hagfors-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "HLF",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Hultsfred",
            "name": "[HLF] - Hultsfred - Sweden",
            "searchName": "HLF-Hultsfred-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "HMV",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Hemavan",
            "name": "[HMV] - Hemavan - Sweden",
            "searchName": "HMV-Hemavan-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "HAD",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Halmstad",
            "name": "[HAD] - Halmstad - Sweden",
            "searchName": "HAD-Halmstad-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "GVX",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Gavle",
            "name": "[GVX] - Gavle - Sweden",
            "searchName": "GVX-Gavle-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "IDB",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Idre",
            "name": "[IDB] - Idre - Sweden",
            "searchName": "IDB-Idre-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "HUV",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Hudiksvall",
            "name": "[HUV] - Hudiksvall - Sweden",
            "searchName": "HUV-Hudiksvall-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "JKG",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Jonkoping",
            "name": "[JKG] - Jonkoping - Sweden",
            "searchName": "JKG-J-Jonkoping-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "JLD",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Landskrona",
            "name": "[JLD] - Landskrona - Sweden",
            "searchName": "JLD-Landskrona-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "JSO",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Sodertalje",
            "name": "[JSO] - Sodertalje - Sweden",
            "searchName": "JSO-Sodertalje-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "BLE",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Borlange",
            "name": "[BLE] - Borlange - Sweden",
            "searchName": "BLE-Borlange/Falun-Borlange-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "AGH",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Angelholm",
            "name": "[AGH] - Angelholm - Sweden",
            "searchName": "AGH-Angelholm/Helsingborg-Angelholm-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "AJR",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Arvidsjaur",
            "name": "[AJR] - Arvidsjaur - Sweden",
            "searchName": "AJR-Arvidsjaur-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "EKT",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Eskilstuna",
            "name": "[EKT] - Eskilstuna - Sweden",
            "searchName": "EKT-Eskilstuna-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "EVG",
            "countryCode": "SE",
            "cityCode": null,
            "cityName": "Sveg",
            "name": "[EVG] - Sveg - Sweden",
            "searchName": "EVG-Sveg-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "MTS",
            "countryCode": "SZ",
            "cityCode": null,
            "cityName": "Manzini",
            "name": "[MTS] - Manzini - Swaziland",
            "searchName": "MTS-Manzini-Suazilândia-Swaziland-Suazilandia-Swasiland-Suazi-Svaasim",
            "priority": 0
        },
        {
            "iataCode": "NCA",
            "countryCode": "TC",
            "cityCode": null,
            "cityName": "North Caicos",
            "name": "[NCA] - North Caicos - Turks and Caicos Islands",
            "searchName": "NCA-North Caicos-Turks and Caicos Islands-Turks- und Caicosinseln-Turks ve Caicos Adalari-Islas Turcas y Caicos-Îles Turks et Caïques-Isole Turks e Caicos",
            "priority": 0
        },
        {
            "iataCode": "PLS",
            "countryCode": "TC",
            "cityCode": null,
            "cityName": "Providenciales",
            "name": "[PLS] - Providenciales - Turks and Caicos Islands",
            "searchName": "PLS-Providenciales-Turks and Caicos Islands-Turks- und Caicosinseln-Turks ve Caicos Adalari-Islas Turcas y Caicos-Îles Turks et Caïques-Isole Turks e Caicos",
            "priority": 0
        },
        {
            "iataCode": "PIC",
            "countryCode": "TC",
            "cityCode": null,
            "cityName": "Pine Cay",
            "name": "[PIC] - Pine Cay - Turks and Caicos Islands",
            "searchName": "PIC-Pine Cay-Turks and Caicos Islands-Turks- und Caicosinseln-Turks ve Caicos Adalari-Islas Turcas y Caicos-Îles Turks et Caïques-Isole Turks e Caicos",
            "priority": 0
        },
        {
            "iataCode": "SLX",
            "countryCode": "TC",
            "cityCode": null,
            "cityName": "Salt Cay",
            "name": "[SLX] - Salt Cay - Turks and Caicos Islands",
            "searchName": "SLX-Salt Cay-Balfour Town-Turks and Caicos Islands-Turks- und Caicosinseln-Turks ve Caicos Adalari-Islas Turcas y Caicos-Îles Turks et Caïques-Isole Turks e Caicos",
            "priority": 0
        },
        {
            "iataCode": "XSC",
            "countryCode": "TC",
            "cityCode": null,
            "cityName": "South Caicos",
            "name": "[XSC] - South Caicos - Turks and Caicos Islands",
            "searchName": "XSC-Cockburn Town-South Caicos-Turks and Caicos Islands-Turks- und Caicosinseln-Turks ve Caicos Adalari-Islas Turcas y Caicos-Îles Turks et Caïques-Isole Turks e Caicos",
            "priority": 0
        },
        {
            "iataCode": "MDS",
            "countryCode": "TC",
            "cityCode": null,
            "cityName": "Middle Caicos",
            "name": "[MDS] - Middle Caicos - Turks and Caicos Islands",
            "searchName": "MDS-Middle Caicos-Turks and Caicos Islands-Turks- und Caicosinseln-Turks ve Caicos Adalari-Islas Turcas y Caicos-Îles Turks et Caïques-Isole Turks e Caicos",
            "priority": 0
        },
        {
            "iataCode": "GDT",
            "countryCode": "TC",
            "cityCode": null,
            "cityName": "Grand Turk Island",
            "name": "[GDT] - Grand Turk Island - Turks and Caicos Islands",
            "searchName": "GDT-Grand Turk Is-Grand Turk Island-Cockburn Town-Turks and Caicos Islands-Turks- und Caicosinseln-Turks ve Caicos Adalari-Islas Turcas y Caicos-Îles Turks et Caïques-Isole Turks e Caicos",
            "priority": 0
        },
        {
            "iataCode": "FYT",
            "countryCode": "TD",
            "cityCode": null,
            "cityName": "Faya",
            "name": "[FYT] - Faya - Chad",
            "searchName": "FYT-Faya-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "MEF",
            "countryCode": "TD",
            "cityCode": null,
            "cityName": "Melfi",
            "name": "[MEF] - Melfi - Chad",
            "searchName": "MEF-Melfi-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "LTC",
            "countryCode": "TD",
            "cityCode": null,
            "cityName": "Lai",
            "name": "[LTC] - Lai - Chad",
            "searchName": "LTC-Lai-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "AMC",
            "countryCode": "TD",
            "cityCode": null,
            "cityName": "Am Timan",
            "name": "[AMC] - Am Timan - Chad",
            "searchName": "AMC-Am Timan-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "AMO",
            "countryCode": "TD",
            "cityCode": null,
            "cityName": "Mao",
            "name": "[AMO] - Mao - Chad",
            "searchName": "AMO-Mao-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "AOD",
            "countryCode": "TD",
            "cityCode": null,
            "cityName": "Abou Deia",
            "name": "[AOD] - Abou Deia - Chad",
            "searchName": "AOD-Abou Deia-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "AKM",
            "countryCode": "TD",
            "cityCode": null,
            "cityName": "Zakouma",
            "name": "[AKM] - Zakouma - Chad",
            "searchName": "AKM-Zakouma-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "AEH",
            "countryCode": "TD",
            "cityCode": null,
            "cityName": "Abéché",
            "name": "[AEH] - Abéché - Chad",
            "searchName": "AEH-Ab-Abéché-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "ATV",
            "countryCode": "TD",
            "cityCode": null,
            "cityName": "Ati",
            "name": "[ATV] - Ati - Chad",
            "searchName": "ATV-Ati-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "BKR",
            "countryCode": "TD",
            "cityCode": null,
            "cityName": "Bokoro",
            "name": "[BKR] - Bokoro - Chad",
            "searchName": "BKR-Bokoro-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "SRH",
            "countryCode": "TD",
            "cityCode": null,
            "cityName": "Sarh",
            "name": "[SRH] - Sarh - Chad",
            "searchName": "SRH-Sarh-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "PLF",
            "countryCode": "TD",
            "cityCode": null,
            "cityName": "Pala",
            "name": "[PLF] - Pala - Chad",
            "searchName": "PLF-Pala-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "NDJ",
            "countryCode": "TD",
            "cityCode": null,
            "cityName": "N Djamena",
            "name": "[NDJ] - N Djamena - Chad",
            "searchName": "NDJ-N Djamena-Yamena-Ndjamena-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "MVO",
            "countryCode": "TD",
            "cityCode": null,
            "cityName": "Mongo",
            "name": "[MVO] - Mongo - Chad",
            "searchName": "MVO-Mongo-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "MQQ",
            "countryCode": "TD",
            "cityCode": null,
            "cityName": "Moundou",
            "name": "[MQQ] - Moundou - Chad",
            "searchName": "MQQ-Moundou-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "OTC",
            "countryCode": "TD",
            "cityCode": null,
            "cityName": "Bol",
            "name": "[OTC] - Bol - Chad",
            "searchName": "OTC-Bol-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "OUM",
            "countryCode": "TD",
            "cityCode": null,
            "cityName": "Oum Hadjer",
            "name": "[OUM] - Oum Hadjer - Chad",
            "searchName": "OUM-Oum Hadjer-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "OUT",
            "countryCode": "TD",
            "cityCode": null,
            "cityName": "Bousso",
            "name": "[OUT] - Bousso - Chad",
            "searchName": "OUT-Bousso-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "OGR",
            "countryCode": "TD",
            "cityCode": null,
            "cityName": "Bongor",
            "name": "[OGR] - Bongor - Chad",
            "searchName": "OGR-Bongor-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "LRL",
            "countryCode": "TG",
            "cityCode": null,
            "cityName": "Niamtougou",
            "name": "[LRL] - Niamtougou - Togo",
            "searchName": "LRL-Niamtougou-Togo",
            "priority": 0
        },
        {
            "iataCode": "LFW",
            "countryCode": "TG",
            "cityCode": null,
            "cityName": "Lome",
            "name": "[LFW] - Lome - Togo",
            "searchName": "LFW-Lom-Lome-Togo",
            "priority": 0
        },
        {
            "iataCode": "LPT",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Lampang",
            "name": "[LPT] - Lampang - Thailand",
            "searchName": "LPT-Lampang-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "LOE",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Loei",
            "name": "[LOE] - Loei - Thailand",
            "searchName": "LOE-Loei-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "MAQ",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Mae Sot",
            "name": "[MAQ] - Mae Sot - Thailand",
            "searchName": "MAQ-Mae Sot-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "KBV",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Krabi",
            "name": "[KBV] - Krabi - Thailand",
            "searchName": "KBV-Krabi-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "KDT",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Kamphangsaen",
            "name": "[KDT] - Kamphangsaen - Thailand",
            "searchName": "KDT-Kamphangsaen-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "KOP",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Nakhon Phanom",
            "name": "[KOP] - Nakhon Phanom - Thailand",
            "searchName": "KOP-Nakorn Panom-Nakhon Phanom-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "KKM",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Lop Buri",
            "name": "[KKM] - Lop Buri - Thailand",
            "searchName": "KKM-Lop Buri-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "KKC",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Khon Kaen",
            "name": "[KKC] - Khon Kaen - Thailand",
            "searchName": "KKC-Khon Kaen-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "HDY",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Hat Yai",
            "name": "[HDY] - Hat Yai - Thailand",
            "searchName": "HDY-Hat Yai-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "HGN",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Mae Hong Son",
            "name": "[HGN] - Mae Hong Son - Thailand",
            "searchName": "HGN-Mae Hong Son-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "HHQ",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Hua Hin",
            "name": "[HHQ] - Hua Hin - Thailand",
            "searchName": "HHQ-Hua Hin-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "MBX",
            "countryCode": "SI",
            "cityCode": null,
            "cityName": "Maribor",
            "name": "[MBX] - Maribor - Slovenia",
            "searchName": "MBX-Maribor-Slovenia-Slovénie-Slovenien-Slovinsko-Slovenya-Eslovènia-Szlovénia-Slovenija-Sloveenia--Slowenien-Eslovenia",
            "priority": 0
        },
        {
            "iataCode": "POW",
            "countryCode": "SI",
            "cityCode": null,
            "cityName": "Portoroz",
            "name": "[POW] - Portoroz - Slovenia",
            "searchName": "POW-Portoroz-Slovenia-Slovénie-Slovenien-Slovinsko-Slovenya-Eslovènia-Szlovénia-Slovenija-Sloveenia--Slowenien-Eslovenia",
            "priority": 0
        },
        {
            "iataCode": "LYR",
            "countryCode": "SJ",
            "cityCode": null,
            "cityName": "Longyearbyen",
            "name": "[LYR] - Longyearbyen - Svalbard and Jan Mayen",
            "searchName": "LYR-Longyearbyen-Svalbard and Jan Mayen-Svalbard und Jan-Mayen-Inseln-Svalbard & Jan Mayen Island-Svalbard y Jan Mayen-Svalbard et Île Jan Mayen-Svalbard e Jan Mayen",
            "priority": 0
        },
        {
            "iataCode": "LUE",
            "countryCode": "SK",
            "cityCode": null,
            "cityName": "Lucenec",
            "name": "[LUE] - Lucenec - Slovakia",
            "searchName": "LUE-Lucenec-Slowakia-Slowakei-Slovakia-Slovacka-Slovaquie-Slovensko-Slovakya-Eslovàquia-Szlovákia-Slovacia-Slovaška-Slovakija-Slovakkia-Slowacja-S-Eslovaquia-Slovacchia",
            "priority": 0
        },
        {
            "iataCode": "KSC",
            "countryCode": "SK",
            "cityCode": null,
            "cityName": "Kosice",
            "name": "[KSC] - Kosice - Slovakia",
            "searchName": "KSC-Košice-Kosice-Ko-Slowakia-Slowakei-Slovakia-Slovacka-Slovaquie-Slovensko-Slovakya-Eslovàquia-Szlovákia-Slovacia-Slovaška-Slovakija-Slovakkia-Slowacja-S-Eslovaquia-Slovacchia",
            "priority": 0
        },
        {
            "iataCode": "ILZ",
            "countryCode": "SK",
            "cityCode": null,
            "cityName": "Zilina",
            "name": "[ILZ] - Zilina - Slovakia",
            "searchName": "ILZ-Žilina-Zilina-Slowakia-Slowakei-Slovakia-Slovacka-Slovaquie-Slovensko-Slovakya-Eslovàquia-Szlovákia-Slovacia-Slovaška-Slovakija-Slovakkia-Slowacja-S-Eslovaquia-Slovacchia",
            "priority": 0
        },
        {
            "iataCode": "GZT",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Gaziantep",
            "name": "[GZT] - Gaziantep - Turkey",
            "searchName": "GZT-Gaziantep-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "KSY",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Kars",
            "name": "[KSY] - Kars - Turkey",
            "searchName": "KSY-Kars-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "KZR",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Kutahya",
            "name": "[KZR] - Kutahya - Turkey",
            "searchName": "KZR-Kutahya-K-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "KYA",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Konya",
            "name": "[KYA] - Konya - Turkey",
            "searchName": "KYA-Konya-Iconio-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "KCM",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Kahramanmaras",
            "name": "[KCM] - Kahramanmaras - Turkey",
            "searchName": "KCM-Kahramanmara-Kahramanmaras-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "KCO",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Kocaeli",
            "name": "[KCO] - Kocaeli - Turkey",
            "searchName": "KCO-Kocaeli-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "EZS",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Elazig",
            "name": "[EZS] - Elazig - Turkey",
            "searchName": "EZS-Elaz-Elazig-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "ERC",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Erzincan",
            "name": "[ERC] - Erzincan - Turkey",
            "searchName": "ERC-Erzincan-Erzincam-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "ERZ",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Erzurum",
            "name": "[ERZ] - Erzurum - Turkey",
            "searchName": "ERZ-Erzurum-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "ESK",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Eskisehir",
            "name": "[ESK] - Eskisehir - Turkey",
            "searchName": "ESK-Doryl-Eskisehir-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "EDO",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Edremit",
            "name": "[EDO] - Edremit - Turkey",
            "searchName": "EDO-Edremit/Korfez-Edremit-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "GAE",
            "countryCode": "TN",
            "cityCode": null,
            "cityName": "Gabes",
            "name": "[GAE] - Gabes - Tunisia",
            "searchName": "GAE-Gab-Gabes-Tunisia-Tunézia-Tunísia-Tunus-Tunisko-Tunesien-Tunizija-Tunisas-Tunisija-Tunis-Tuneesia-Tunezja-Tunisie-Túnez",
            "priority": 0
        },
        {
            "iataCode": "GAF",
            "countryCode": "TN",
            "cityCode": null,
            "cityName": "Gafsa",
            "name": "[GAF] - Gafsa - Tunisia",
            "searchName": "GAF-Gafsa-Tunisia-Tunézia-Tunísia-Tunus-Tunisko-Tunesien-Tunizija-Tunisas-Tunisija-Tunis-Tuneesia-Tunezja-Tunisie-Túnez",
            "priority": 0
        },
        {
            "iataCode": "DJE",
            "countryCode": "TN",
            "cityCode": null,
            "cityName": "Djerba",
            "name": "[DJE] - Djerba - Tunisia",
            "searchName": "DJE-Djerba-Tunisia-Tunézia-Tunísia-Tunus-Tunisko-Tunesien-Tunizija-Tunisas-Tunisija-Tunis-Tuneesia-Tunezja-Tunisie-Túnez",
            "priority": 0
        },
        {
            "iataCode": "EBM",
            "countryCode": "TN",
            "cityCode": null,
            "cityName": "El Borma",
            "name": "[EBM] - El Borma - Tunisia",
            "searchName": "EBM-El Borma-Tunisia-Tunézia-Tunísia-Tunus-Tunisko-Tunesien-Tunizija-Tunisas-Tunisija-Tunis-Tuneesia-Tunezja-Tunisie-Túnez",
            "priority": 0
        },
        {
            "iataCode": "TBJ",
            "countryCode": "TN",
            "cityCode": null,
            "cityName": "Tabarka",
            "name": "[TBJ] - Tabarka - Tunisia",
            "searchName": "TBJ-Tabarka-Tunisia-Tunézia-Tunísia-Tunus-Tunisko-Tunesien-Tunizija-Tunisas-Tunisija-Tunis-Tuneesia-Tunezja-Tunisie-Túnez",
            "priority": 0
        },
        {
            "iataCode": "TOE",
            "countryCode": "TN",
            "cityCode": null,
            "cityName": "Tozeur",
            "name": "[TOE] - Tozeur - Tunisia",
            "searchName": "TOE-Tozeur-Tunisia-Tunézia-Tunísia-Tunus-Tunisko-Tunesien-Tunizija-Tunisas-Tunisija-Tunis-Tuneesia-Tunezja-Tunisie-Túnez",
            "priority": 0
        },
        {
            "iataCode": "TUN",
            "countryCode": "TN",
            "cityCode": null,
            "cityName": "Tunis",
            "name": "[TUN] - Tunis - Tunisia",
            "searchName": "TUN-Tunis-Tunez-Tunisia-Tunézia-Tunísia-Tunus-Tunisko-Tunesien-Tunizija-Tunisas-Tunisija-Tuneesia-Tunezja-Tunisie-Túnez",
            "priority": 0
        },
        {
            "iataCode": "NBE",
            "countryCode": "TN",
            "cityCode": null,
            "cityName": "Enfidha",
            "name": "[NBE] - Enfidha - Tunisia",
            "searchName": "NBE-Dar-el-Bey-Enfidha-Tunisia-Tunézia-Tunísia-Tunus-Tunisko-Tunesien-Tunizija-Tunisas-Tunisija-Tunis-Tuneesia-Tunezja-Tunisie-Túnez",
            "priority": 0
        },
        {
            "iataCode": "SFA",
            "countryCode": "TN",
            "cityCode": null,
            "cityName": "Sfax",
            "name": "[SFA] - Sfax - Tunisia",
            "searchName": "SFA-Sfax-Tunisia-Tunézia-Tunísia-Tunus-Tunisko-Tunesien-Tunizija-Tunisas-Tunisija-Tunis-Tuneesia-Tunezja-Tunisie-Túnez",
            "priority": 0
        },
        {
            "iataCode": "NFO",
            "countryCode": "TO",
            "cityCode": null,
            "cityName": "Niuafo ou",
            "name": "[NFO] - Niuafo ou - Tonga",
            "searchName": "NFO-Niuafo ou-Niuafo Ou-Tonga-Tông ga",
            "priority": 0
        },
        {
            "iataCode": "NTT",
            "countryCode": "TO",
            "cityCode": null,
            "cityName": "Niuatoputapu",
            "name": "[NTT] - Niuatoputapu - Tonga",
            "searchName": "NTT-Niuatoputapu-Tonga-Tông ga",
            "priority": 0
        },
        {
            "iataCode": "VAV",
            "countryCode": "TO",
            "cityCode": null,
            "cityName": "Vava u",
            "name": "[VAV] - Vava u - Tonga",
            "searchName": "VAV-Vava u-Tonga-Tông ga",
            "priority": 0
        },
        {
            "iataCode": "TBU",
            "countryCode": "TO",
            "cityCode": null,
            "cityName": "Nuku alofa",
            "name": "[TBU] - Nuku alofa - Tonga",
            "searchName": "TBU-Nuku alofa-Alofa-Tonga-Tông ga",
            "priority": 0
        },
        {
            "iataCode": "EUA",
            "countryCode": "TO",
            "cityCode": null,
            "cityName": "Eua",
            "name": "[EUA] - Eua - Tonga",
            "searchName": "EUA-Eua-Tonga-Tông ga",
            "priority": 0
        },
        {
            "iataCode": "HPA",
            "countryCode": "TO",
            "cityCode": null,
            "cityName": "Ha Apai",
            "name": "[HPA] - Ha Apai - Tonga",
            "searchName": "HPA-Ha Apai-Tonga-Tông ga",
            "priority": 0
        },
        {
            "iataCode": "HTY",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Hatay",
            "name": "[HTY] - Hatay - Turkey",
            "searchName": "HTY-Antakya-Hatay-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "IGD",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Igdir",
            "name": "[IGD] - Igdir - Turkey",
            "searchName": "IGD-Igdir-Alikamerli-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "URT",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Surat Thani",
            "name": "[URT] - Surat Thani - Thailand",
            "searchName": "URT-Surat Thani-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "UTP",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Pattaya",
            "name": "[UTP] - Pattaya - Thailand",
            "searchName": "UTP-Pattaya-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "UTR",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Uttaradit",
            "name": "[UTR] - Uttaradit - Thailand",
            "searchName": "UTR-Uttaradit-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "UTH",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Udon Thani",
            "name": "[UTH] - Udon Thani - Thailand",
            "searchName": "UTH-Udon Thani-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "TJU",
            "countryCode": "TJ",
            "cityCode": null,
            "cityName": "Kulyab",
            "name": "[TJU] - Kulyab - Tajikistan",
            "searchName": "TJU-Kulyab-Kulob-Kulob\\/Kulyab-Tadschikistan-Tajikistan-Tacikistan-Tadjikistan-Tayikistán-Tagikistan",
            "priority": 0
        },
        {
            "iataCode": "DYU",
            "countryCode": "TJ",
            "cityCode": null,
            "cityName": "Dushanbe",
            "name": "[DYU] - Dushanbe - Tajikistan",
            "searchName": "DYU-Dushanbe-Tadschikistan-Tajikistan-Tacikistan-Tadjikistan-Tayikistán-Tagikistan",
            "priority": 0
        },
        {
            "iataCode": "LBD",
            "countryCode": "TJ",
            "cityCode": null,
            "cityName": "Khudzhand",
            "name": "[LBD] - Khudzhand - Tajikistan",
            "searchName": "LBD-Chudschand-Khudzhand-Khodjent-Khujand-Tadschikistan-Tajikistan-Tacikistan-Tadjikistan-Tayikistán-Tagikistan",
            "priority": 0
        },
        {
            "iataCode": "KQT",
            "countryCode": "TJ",
            "cityCode": null,
            "cityName": "Qurghonteppa",
            "name": "[KQT] - Qurghonteppa - Tajikistan",
            "searchName": "KQT-Qurghonteppa-Kurgon-Tyube-Tadschikistan-Tajikistan-Tacikistan-Tadjikistan-Tayikistán-Tagikistan",
            "priority": 0
        },
        {
            "iataCode": "KOE",
            "countryCode": "TL",
            "cityCode": null,
            "cityName": "Kupang",
            "name": "[KOE] - Kupang - East Timor",
            "searchName": "KOE-Kupang-Osttimor-East Timor-Dogu Timor-Timor oriental-Timor Oriental-Timor Est",
            "priority": 0
        },
        {
            "iataCode": "DIL",
            "countryCode": "TL",
            "cityCode": null,
            "cityName": "Dili",
            "name": "[DIL] - Dili - East Timor",
            "searchName": "DIL-Dili-Osttimor-East Timor-Dogu Timor-Timor oriental-Timor Oriental-Timor Est",
            "priority": 0
        },
        {
            "iataCode": "BCH",
            "countryCode": "TL",
            "cityCode": null,
            "cityName": "Baucau",
            "name": "[BCH] - Baucau - East Timor",
            "searchName": "BCH-Baucau-Osttimor-East Timor-Dogu Timor-Timor oriental-Timor Oriental-Timor Est",
            "priority": 0
        },
        {
            "iataCode": "VIQ",
            "countryCode": "TL",
            "cityCode": null,
            "cityName": "Viqueque",
            "name": "[VIQ] - Viqueque - East Timor",
            "searchName": "VIQ-Viqueque-Osttimor-East Timor-Dogu Timor-Timor oriental-Timor Oriental-Timor Est",
            "priority": 0
        },
        {
            "iataCode": "UAI",
            "countryCode": "TL",
            "cityCode": null,
            "cityName": "Suai",
            "name": "[UAI] - Suai - East Timor",
            "searchName": "UAI-Suai-Osttimor-East Timor-Dogu Timor-Timor oriental-Timor Oriental-Timor Est",
            "priority": 0
        },
        {
            "iataCode": "MPT",
            "countryCode": "TL",
            "cityCode": null,
            "cityName": "Maliana",
            "name": "[MPT] - Maliana - East Timor",
            "searchName": "MPT-Maliana-Osttimor-East Timor-Dogu Timor-Timor oriental-Timor Oriental-Timor Est",
            "priority": 0
        },
        {
            "iataCode": "OEC",
            "countryCode": "TL",
            "cityCode": null,
            "cityName": "Ocussi",
            "name": "[OEC] - Ocussi - East Timor",
            "searchName": "OEC-Ocussi-Osttimor-East Timor-Dogu Timor-Timor oriental-Timor Oriental-Timor Est",
            "priority": 0
        },
        {
            "iataCode": "MYP",
            "countryCode": "TM",
            "cityCode": null,
            "cityName": "Mary",
            "name": "[MYP] - Mary - Turkmenistan",
            "searchName": "MYP-Mary-Turkmenistan-Turkménistan-Turkmenistán",
            "priority": 0
        },
        {
            "iataCode": "TAZ",
            "countryCode": "TM",
            "cityCode": null,
            "cityName": "Dashoguz",
            "name": "[TAZ] - Dashoguz - Turkmenistan",
            "searchName": "TAZ-Dashoguz-Turkmenistan-Turkménistan-Turkmenistán",
            "priority": 0
        },
        {
            "iataCode": "BFV",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Buri Ram",
            "name": "[BFV] - Buri Ram - Thailand",
            "searchName": "BFV-Buri Ram-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "BAO",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Ban Mak Khaen",
            "name": "[BAO] - Ban Mak Khaen - Thailand",
            "searchName": "BAO-Mueang Udon Thani\\/ Ban Mak Khaeng-Ban Mak Khaen-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "CJM",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Chumphon",
            "name": "[CJM] - Chumphon - Thailand",
            "searchName": "CJM-Chumphon-Chumpon-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "CNX",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Chiang Mai",
            "name": "[CNX] - Chiang Mai - Thailand",
            "searchName": "CNX-Chiang Mai-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "CEI",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Chiang Rai",
            "name": "[CEI] - Chiang Rai - Thailand",
            "searchName": "CEI-Chiang Rai-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "NST",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Nakhon Si Thammarat",
            "name": "[NST] - Nakhon Si Thammarat - Thailand",
            "searchName": "NST-Nakhon Si Tammarat-Nakhon Si Thammarat-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "NNT",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Nan",
            "name": "[NNT] - Nan - Thailand",
            "searchName": "NNT-Nan-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "PBS",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Patong Beach",
            "name": "[PBS] - Patong Beach - Thailand",
            "searchName": "PBS-Patong Beach-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "PAN",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Pattani",
            "name": "[PAN] - Pattani - Thailand",
            "searchName": "PAN-Pattani-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "NAW",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Narathiwat",
            "name": "[NAW] - Narathiwat - Thailand",
            "searchName": "NAW-Narathiwat-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "NAK",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Nakhon Ratchasima",
            "name": "[NAK] - Nakhon Ratchasima - Thailand",
            "searchName": "NAK-Nakhon Ratchasima-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "PHY",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Phetchabun",
            "name": "[PHY] - Phetchabun - Thailand",
            "searchName": "PHY-Phetchabun-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "PHZ",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Phi Phi Island",
            "name": "[PHZ] - Phi Phi Island - Thailand",
            "searchName": "PHZ-Phi Phi Island-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "PHS",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Phitsanulok",
            "name": "[PHS] - Phitsanulok - Thailand",
            "searchName": "PHS-Phitsanulok-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "PMM",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Phanom Sarakham",
            "name": "[PMM] - Phanom Sarakham - Thailand",
            "searchName": "PMM-Phanom Sarakham-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "PRH",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Phrae",
            "name": "[PRH] - Phrae - Thailand",
            "searchName": "PRH-Phrae-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "PYX",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Pattaya",
            "name": "[PYX] - Pattaya - Thailand",
            "searchName": "PYX-Pattaya-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "PYY",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Pai",
            "name": "[PYY] - Pai - Thailand",
            "searchName": "PYY-Pai-Pai City-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "PXR",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Surin",
            "name": "[PXR] - Surin - Thailand",
            "searchName": "PXR-Surin-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "SNO",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Sakon Nakhon",
            "name": "[SNO] - Sakon Nakhon - Thailand",
            "searchName": "SNO-Sakon Nakhon-Sakhon Nakhon-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "SGZ",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Songkhla",
            "name": "[SGZ] - Songkhla - Thailand",
            "searchName": "SGZ-Songkhla-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "ROI",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Roi Et",
            "name": "[ROI] - Roi Et - Thailand",
            "searchName": "ROI-Roi Et-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "TKT",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Tak",
            "name": "[TKT] - Tak - Thailand",
            "searchName": "TKT-Tak-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "THS",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Sukhothai (sawankolok)",
            "name": "[THS] - Sukhothai (sawankolok) - Thailand",
            "searchName": "THS-Sukhothai-Sukhothai (sawankolok)-Sukhotthai-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "TKH",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Takhli",
            "name": "[TKH] - Takhli - Thailand",
            "searchName": "TKH-Takhli-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "TDX",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Trat",
            "name": "[TDX] - Trat - Thailand",
            "searchName": "TDX-Trat-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "TST",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Trang",
            "name": "[TST] - Trang - Thailand",
            "searchName": "TST-Trang-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "UBP",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Ubon Ratchathni",
            "name": "[UBP] - Ubon Ratchathni - Thailand",
            "searchName": "UBP-Ubon Ratchathani-Ubon Ratchathni-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "UNN",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Ranong",
            "name": "[UNN] - Ranong - Thailand",
            "searchName": "UNN-Ranong-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "ASI",
            "countryCode": "SH",
            "cityCode": null,
            "cityName": "Georgetown",
            "name": "[ASI] - Georgetown - Saint Helena",
            "searchName": "ASI-Georgetown-St. Helena-Saint Helena-Sainte-Hélène-Santa Elena-Sant’Elena",
            "priority": 0
        },
        {
            "iataCode": "ISE",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Isparta",
            "name": "[ISE] - Isparta - Turkey",
            "searchName": "ISE-Isparta-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "GKD",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Gokceada",
            "name": "[GKD] - Gokceada - Turkey",
            "searchName": "GKD-Gokceada-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "CRZ",
            "countryCode": "TM",
            "cityCode": null,
            "cityName": "Turkmenabad",
            "name": "[CRZ] - Turkmenabad - Turkmenistan",
            "searchName": "CRZ-Turkmenahad-Turkmenabad-T-Turkmenistan-Turkménistan-Turkmenistán",
            "priority": 0
        },
        {
            "iataCode": "KRW",
            "countryCode": "TM",
            "cityCode": null,
            "cityName": "Turkmenbashi",
            "name": "[KRW] - Turkmenbashi - Turkmenistan",
            "searchName": "KRW-Turkmenbashi-T-Turkmenistan-Turkménistan-Turkmenistán",
            "priority": 0
        },
        {
            "iataCode": "DIY",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Diyarbakir",
            "name": "[DIY] - Diyarbakir - Turkey",
            "searchName": "DIY-Diyarbakir-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "CII",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Aydin",
            "name": "[CII] - Aydin - Turkey",
            "searchName": "CII-Aydin-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "CKZ",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Canakkale",
            "name": "[CKZ] - Canakkale - Turkey",
            "searchName": "CKZ-Canakkale--Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "ADF",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Adiyaman",
            "name": "[ADF] - Adiyaman - Turkey",
            "searchName": "ADF-Adiyaman-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "AFY",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Afyon",
            "name": "[AFY] - Afyon - Turkey",
            "searchName": "AFY-Afyon-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "ADA",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Adana",
            "name": "[ADA] - Adana - Turkey",
            "searchName": "ADA-Adana-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "AJI",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Agri",
            "name": "[AJI] - Agri - Turkey",
            "searchName": "AJI-Agri.-Agri-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "BGG",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Bongouanou",
            "name": "[BGG] - Bongouanou - Turkey",
            "searchName": "BGG-Bongouanou-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "BDM",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Bandirma",
            "name": "[BDM] - Bandirma - Turkey",
            "searchName": "BDM-Bandirma-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "BJV",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Milas",
            "name": "[BJV] - Milas - Turkey",
            "searchName": "BJV-Bodrum-Milas-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "BZI",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Balikesir",
            "name": "[BZI] - Balikesir - Turkey",
            "searchName": "BZI-Balikesir-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "SXZ",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Siirt",
            "name": "[SXZ] - Siirt - Turkey",
            "searchName": "SXZ-Siirt-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "SZF",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Samsun",
            "name": "[SZF] - Samsun - Turkey",
            "searchName": "SZF-Samsun-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "TJK",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Tokat",
            "name": "[TJK] - Tokat - Turkey",
            "searchName": "TJK-Tokat-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "TEQ",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Tekirdag",
            "name": "[TEQ] - Tekirdag - Turkey",
            "searchName": "TEQ-Tekirda-Tekirdag-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "VAS",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Sivas",
            "name": "[VAS] - Sivas - Turkey",
            "searchName": "VAS-Sivas-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "VAN",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Van",
            "name": "[VAN] - Van - Turkey",
            "searchName": "VAN-Van-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "TZX",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Trabzon",
            "name": "[TZX] - Trabzon - Turkey",
            "searchName": "TZX-Trabzon-Trapisonda-Trebisonda-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "USQ",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Usak",
            "name": "[USQ] - Usak - Turkey",
            "searchName": "USQ-Usak-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "YEI",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Bursa",
            "name": "[YEI] - Bursa - Turkey",
            "searchName": "YEI-Bursa-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "XHQ",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Artvin",
            "name": "[XHQ] - Artvin - Turkey",
            "searchName": "XHQ-Artvin-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "NOP",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Sinop",
            "name": "[NOP] - Sinop - Turkey",
            "searchName": "NOP-Sinop-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "ONQ",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Zonguldak",
            "name": "[ONQ] - Zonguldak - Turkey",
            "searchName": "ONQ-Zonguldak-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "NAV",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Nevsehir",
            "name": "[NAV] - Nevsehir - Turkey",
            "searchName": "NAV-Nev-Nevsehir-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "MZH",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Merzifon",
            "name": "[MZH] - Merzifon - Turkey",
            "searchName": "MZH-Merzifon-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "MQM",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Mardin",
            "name": "[MQM] - Mardin - Turkey",
            "searchName": "MQM-Mardin-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "MSR",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Mus",
            "name": "[MSR] - Mus - Turkey",
            "searchName": "MSR-Mu-Mus-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "MLX",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Malatya",
            "name": "[MLX] - Malatya - Turkey",
            "searchName": "MLX-Malatya-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "SFQ",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Sanliurfa",
            "name": "[SFQ] - Sanliurfa - Turkey",
            "searchName": "SFQ-Sanliurfa-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "POS",
            "countryCode": "TT",
            "cityCode": null,
            "cityName": "Port Of Spain",
            "name": "[POS] - Port Of Spain - Trinidad and Tobago",
            "searchName": "POS-Piarco-Port Of Spain-Port of Spain-Trinidad und Tobago-Trinidad and Tobago-Trinité-et-Tobago-Trinidad y Tobago-Trinidad e Tobago",
            "priority": 0
        },
        {
            "iataCode": "TAB",
            "countryCode": "TT",
            "cityCode": null,
            "cityName": "Tobago",
            "name": "[TAB] - Tobago - Trinidad and Tobago",
            "searchName": "TAB-Tobago-Crown Point-Trinidad und Tobago-Trinidad and Tobago-Trinité-et-Trinidad y Tobago-Trinidad e Tobago",
            "priority": 0
        },
        {
            "iataCode": "FUN",
            "countryCode": "TV",
            "cityCode": null,
            "cityName": "Funafuti Atoll",
            "name": "[FUN] - Funafuti Atoll - Tuvalu",
            "searchName": "FUN-Funafuti Atol-Funafuti Atoll-Tuvalu",
            "priority": 0
        },
        {
            "iataCode": "GNI",
            "countryCode": "TW",
            "cityCode": null,
            "cityName": "Green Island",
            "name": "[GNI] - Green Island - Taiwan",
            "searchName": "GNI-Green Island-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "HCN",
            "countryCode": "TW",
            "cityCode": null,
            "cityName": "Hengchun",
            "name": "[HCN] - Hengchun - Taiwan",
            "searchName": "HCN-Hengchun-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "TNN",
            "countryCode": "TW",
            "cityCode": null,
            "cityName": "Tainan",
            "name": "[TNN] - Tainan - Taiwan",
            "searchName": "TNN-Tainan-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "TXG",
            "countryCode": "TW",
            "cityCode": null,
            "cityName": "Taichung",
            "name": "[TXG] - Taichung - Taiwan",
            "searchName": "TXG-Taichung-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "TTT",
            "countryCode": "TW",
            "cityCode": null,
            "cityName": "Taitung",
            "name": "[TTT] - Taitung - Taiwan",
            "searchName": "TTT-Taitung-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "WOT",
            "countryCode": "TW",
            "cityCode": null,
            "cityName": "Wonan",
            "name": "[WOT] - Wonan - Taiwan",
            "searchName": "WOT-Wonan-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "PIF",
            "countryCode": "TW",
            "cityCode": null,
            "cityName": "Pingtung",
            "name": "[PIF] - Pingtung - Taiwan",
            "searchName": "PIF-Pingtung-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "SMT",
            "countryCode": "TW",
            "cityCode": null,
            "cityName": "Sun Moon Lake",
            "name": "[SMT] - Sun Moon Lake - Taiwan",
            "searchName": "SMT-Sun Moon Lake-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "RMQ",
            "countryCode": "TW",
            "cityCode": null,
            "cityName": "Taichung",
            "name": "[RMQ] - Taichung - Taiwan",
            "searchName": "RMQ-Taichung-Taichung City-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "MZG",
            "countryCode": "TW",
            "cityCode": null,
            "cityName": "Makung",
            "name": "[MZG] - Makung - Taiwan",
            "searchName": "MZG-Makung-Magong-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "MYW",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Mtwara",
            "name": "[MYW] - Mtwara - Tanzania",
            "searchName": "MYW-Mtwara-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "MWN",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Mwadui",
            "name": "[MWN] - Mwadui - Tanzania",
            "searchName": "MWN-Mwadui-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "MWZ",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Mwanza",
            "name": "[MWZ] - Mwanza - Tanzania",
            "searchName": "MWZ-Mwanza-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "NCH",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Nachingwea",
            "name": "[NCH] - Nachingwea - Tanzania",
            "searchName": "NCH-Nachingwea-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "MUZ",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Musoma",
            "name": "[MUZ] - Musoma - Tanzania",
            "searchName": "MUZ-Musoma-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "SEU",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Seronera",
            "name": "[SEU] - Seronera - Tanzania",
            "searchName": "SEU-Seronera-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "SGX",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Songea",
            "name": "[SGX] - Songea - Tanzania",
            "searchName": "SGX-Songea-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "SHY",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Shinyanga",
            "name": "[SHY] - Shinyanga - Tanzania",
            "searchName": "SHY-Shinyanga-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "PMA",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Pemba",
            "name": "[PMA] - Pemba - Tanzania",
            "searchName": "PMA-Pemba-Wete-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "XMI",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Masasi",
            "name": "[XMI] - Masasi - Tanzania",
            "searchName": "XMI-Masasi-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "ZNZ",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Zanzibar",
            "name": "[ZNZ] - Zanzibar - Tanzania",
            "searchName": "ZNZ-Zanzibar-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "TGT",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Tanga",
            "name": "[TGT] - Tanga - Tanzania",
            "searchName": "TGT-Tanga-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "TKQ",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Kigoma",
            "name": "[TKQ] - Kigoma - Tanzania",
            "searchName": "TKQ-Kigoma-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "TBO",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Tabora",
            "name": "[TBO] - Tabora - Tanzania",
            "searchName": "TBO-Tabora-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "SUT",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Sumbawanga",
            "name": "[SUT] - Sumbawanga - Tanzania",
            "searchName": "SUT-Sumbawanga-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "DAR",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Dar Es Salaam",
            "name": "[DAR] - Dar Es Salaam - Tanzania",
            "searchName": "DAR-Daressalam-Dar Es Salaam-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "DOD",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Dodoma",
            "name": "[DOD] - Dodoma - Tanzania",
            "searchName": "DOD-Dodoma-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "BKZ",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Bukoba",
            "name": "[BKZ] - Bukoba - Tanzania",
            "searchName": "BKZ-Bukoba-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "ARK",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Arusha",
            "name": "[ARK] - Arusha - Tanzania",
            "searchName": "ARK-Arusha-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "LKY",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Lake Manyara",
            "name": "[LKY] - Lake Manyara - Tanzania",
            "searchName": "LKY-Lake Manyara-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "MBI",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Mbeya",
            "name": "[MBI] - Mbeya - Tanzania",
            "searchName": "MBI-Mbeya-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "MFA",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Mafia",
            "name": "[MFA] - Mafia - Tanzania",
            "searchName": "MFA-Mafia Island-Mafia-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "LDI",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Lindi",
            "name": "[LDI] - Lindi - Tanzania",
            "searchName": "LDI-Lindi-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "KIY",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Kilwa",
            "name": "[KIY] - Kilwa - Tanzania",
            "searchName": "KIY-Kilwa-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "IRI",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Iringa",
            "name": "[IRI] - Iringa - Tanzania",
            "searchName": "IRI-Iringa-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "JRO",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Kilimanjaro",
            "name": "[JRO] - Kilimanjaro - Tanzania",
            "searchName": "JRO-Kilimanjaro-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "JOM",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Njombe",
            "name": "[JOM] - Njombe - Tanzania",
            "searchName": "JOM-Njombe-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "GTZ",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Grumeti",
            "name": "[GTZ] - Grumeti - Tanzania",
            "searchName": "GTZ-Grumeti-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "GIT",
            "countryCode": "TZ",
            "cityCode": null,
            "cityName": "Geita",
            "name": "[GIT] - Geita - Tanzania",
            "searchName": "GIT-Geita-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "HMJ",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Khmelnitskiy",
            "name": "[HMJ] - Khmelnitskiy - Ukraine",
            "searchName": "HMJ-Chmelnyzkyj-Khmelnitskiy-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "MBQ",
            "countryCode": "UG",
            "cityCode": null,
            "cityName": "Mbarara",
            "name": "[MBQ] - Mbarara - Uganda",
            "searchName": "MBQ-Mbarara-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "KSE",
            "countryCode": "UG",
            "cityCode": null,
            "cityName": "Kasese",
            "name": "[KSE] - Kasese - Uganda",
            "searchName": "KSE-Kasese-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "KCU",
            "countryCode": "UG",
            "cityCode": null,
            "cityName": "Masindi",
            "name": "[KCU] - Masindi - Uganda",
            "searchName": "KCU-Masindi-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "KBG",
            "countryCode": "UG",
            "cityCode": null,
            "cityName": "Kabalega Falls",
            "name": "[KBG] - Kabalega Falls - Uganda",
            "searchName": "KBG-Kabalega Falls-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "JIN",
            "countryCode": "UG",
            "cityCode": null,
            "cityName": "Jinja",
            "name": "[JIN] - Jinja - Uganda",
            "searchName": "JIN-Jinja-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "JON",
            "countryCode": "UM",
            "cityCode": null,
            "cityName": "Johnston Island",
            "name": "[JON] - Johnston Island - United States Minor Outlying Islands",
            "searchName": "JON-Johnston Island-United States Minor Outlying Islands-USA-ABD Küçük Dis Adalari-Islas menores alejadas de los Estados Unidos-Îles éloignées des États-Unis-Isole periferiche agli USA",
            "priority": 0
        },
        {
            "iataCode": "MDY",
            "countryCode": "UM",
            "cityCode": null,
            "cityName": "Midway Island",
            "name": "[MDY] - Midway Island - United States Minor Outlying Islands",
            "searchName": "MDY-Midway Island-United States Minor Outlying Islands-USA-ABD Küçük Dis Adalari-Islas menores alejadas de los Estados Unidos-Îles éloignées des États-Unis-Isole periferiche agli USA",
            "priority": 0
        },
        {
            "iataCode": "AWK",
            "countryCode": "UM",
            "cityCode": null,
            "cityName": "Wake Island",
            "name": "[AWK] - Wake Island - United States Minor Outlying Islands",
            "searchName": "AWK-Wake Island-United States Minor Outlying Islands-USA-ABD Küçük Dis Adalari-Islas menores alejadas de los Estados Unidos-Îles éloignées des États-Unis-Isole periferiche agli USA",
            "priority": 0
        },
        {
            "iataCode": "AWM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "West Memphis",
            "name": "[AWM] - West Memphis - United States",
            "searchName": "AWM-West Memphis-Memphis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AXB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Alexandria Bay",
            "name": "[AXB] - Alexandria Bay - United States",
            "searchName": "AXB-Alexandria Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AXN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Alexandria",
            "name": "[AXN] - Alexandria - United States",
            "searchName": "AXN-Alexandria-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AXG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Algona",
            "name": "[AXG] - Algona - United States",
            "searchName": "AXG-Algona-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AVX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Avalon",
            "name": "[AVX] - Avalon - United States",
            "searchName": "AVX-Catalina Island-Avalon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AVO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Avon Park",
            "name": "[AVO] - Avon Park - United States",
            "searchName": "AVO-Avon Park-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AVP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wilkes-Barre",
            "name": "[AVP] - Wilkes-Barre - United States",
            "searchName": "AVP-Scranton/Wilkes-Barre-Wilkes-Scranton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AVL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fletcher",
            "name": "[AVL] - Fletcher - United States",
            "searchName": "AVL-Asheville/Hendersonville-Fletcher-Asheville-Hendersonville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AUZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Aurora",
            "name": "[AUZ] - Aurora - United States",
            "searchName": "AUZ-Aurora-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AYZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Amityville",
            "name": "[AYZ] - Amityville - United States",
            "searchName": "AYZ-Amityville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AYS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Waycross",
            "name": "[AYS] - Waycross - United States",
            "searchName": "AYS-Waycross-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AYE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Devens",
            "name": "[AYE] - Fort Devens - United States",
            "searchName": "AYE-Fort Devens-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AXV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wapakoneta",
            "name": "[AXV] - Wapakoneta - United States",
            "searchName": "AXV-Wapakoneta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AXX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Angel Fire",
            "name": "[AXX] - Angel Fire - United States",
            "searchName": "AXX-Angel Fire-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BAF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Westfield",
            "name": "[BAF] - Westfield - United States",
            "searchName": "BAF-Westfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AZO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kalamazoo",
            "name": "[AZO] - Kalamazoo - United States",
            "searchName": "AZO-Kalamazoo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BAM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Battle Mountain",
            "name": "[BAM] - Battle Mountain - United States",
            "searchName": "BAM-Battle Mountain-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BAR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Baker Island",
            "name": "[BAR] - Baker Island - United States",
            "searchName": "BAR-Baker Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BBB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Benson",
            "name": "[BBB] - Benson - United States",
            "searchName": "BBB-Benson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BBC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bay City",
            "name": "[BBC] - Bay City - United States",
            "searchName": "BBC-Bay City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BBD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Brady",
            "name": "[BBD] - Brady - United States",
            "searchName": "BBD-Brady-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BBF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Burlington",
            "name": "[BBF] - Burlington - United States",
            "searchName": "BBF-Burlington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BBW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Broken Bow",
            "name": "[BBW] - Broken Bow - United States",
            "searchName": "BBW-Broken Bow-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BBX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Blue Bell",
            "name": "[BBX] - Blue Bell - United States",
            "searchName": "BBX-Blue Bell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BCJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Baca Grande",
            "name": "[BCJ] - Baca Grande - United States",
            "searchName": "BCJ-Baca Grande-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BCE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bryce",
            "name": "[BCE] - Bryce - United States",
            "searchName": "BCE-Bryce-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BCB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Blacksburg",
            "name": "[BCB] - Blacksburg - United States",
            "searchName": "BCB-Blacksburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BCC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bear Creek",
            "name": "[BCC] - Bear Creek - United States",
            "searchName": "BCC-Bear Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BDE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Baudette",
            "name": "[BDE] - Baudette - United States",
            "searchName": "BDE-Baudette-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BDF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bradford",
            "name": "[BDF] - Bradford - United States",
            "searchName": "BDF-Bradford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BDG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Blanding",
            "name": "[BDG] - Blanding - United States",
            "searchName": "BDG-Blanding-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BCS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Belle Chasse",
            "name": "[BCS] - Belle Chasse - United States",
            "searchName": "BCS-Belle Chasse-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BCT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Boca Raton",
            "name": "[BCT] - Boca Raton - United States",
            "searchName": "BCT-Boca Raton-Ft Lauderdale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ARX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Neptune",
            "name": "[ARX] - Neptune - United States",
            "searchName": "ARX-Neptune-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ARV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Minocqua",
            "name": "[ARV] - Minocqua - United States",
            "searchName": "ARV-Minocqua-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ART",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Watertown",
            "name": "[ART] - Watertown - United States",
            "searchName": "ART-Watertown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ARG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Walnut Ridge",
            "name": "[ARG] - Walnut Ridge - United States",
            "searchName": "ARG-Walnut Ridge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AQY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Alyeska",
            "name": "[AQY] - Alyeska - United States",
            "searchName": "AQY-Alyeska-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ARA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "New Iberia",
            "name": "[ARA] - New Iberia - United States",
            "searchName": "ARA-New Iberia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ARB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ann Arbor",
            "name": "[ARB] - Ann Arbor - United States",
            "searchName": "ARB-Ann Arbor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ARC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Arctic Village",
            "name": "[ARC] - Arctic Village - United States",
            "searchName": "ARC-Arctic Village-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AOO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Martinsburg",
            "name": "[AOO] - Martinsburg - United States",
            "searchName": "AOO-Altoona/Martinsburg-Martinsburg-Altoona-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AOH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lima",
            "name": "[AOH] - Lima - United States",
            "searchName": "AOH-Lima-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AOS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Amook",
            "name": "[AOS] - Amook - United States",
            "searchName": "AOS-Amook-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "APC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Napa",
            "name": "[APC] - Napa - United States",
            "searchName": "APC-Napa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "APV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Apple Valley",
            "name": "[APV] - Apple Valley - United States",
            "searchName": "APV-Apple Valley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "APT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Jasper",
            "name": "[APT] - Jasper - United States",
            "searchName": "APT-Jasper-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "APN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Alpena",
            "name": "[APN] - Alpena - United States",
            "searchName": "APN-Alpena-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "APF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Naples",
            "name": "[APF] - Naples - United States",
            "searchName": "APF-Naples-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "APG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Aberdeen",
            "name": "[APG] - Aberdeen - United States",
            "searchName": "APG-Aberdeen-Baltimore-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "APH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bowling Green",
            "name": "[APH] - Bowling Green - United States",
            "searchName": "APH-Bowling Green-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AST",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Astoria",
            "name": "[AST] - Astoria - United States",
            "searchName": "AST-Astoria-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ASQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Austin",
            "name": "[ASQ] - Austin - United States",
            "searchName": "ASQ-Austin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ASN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Talladega",
            "name": "[ASN] - Talladega - United States",
            "searchName": "ASN-Talladega-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ASL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Marshall",
            "name": "[ASL] - Marshall - United States",
            "searchName": "ASL-Marshall-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ASH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Nashua",
            "name": "[ASH] - Nashua - United States",
            "searchName": "ASH-Nashua-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PVS",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Provideniya",
            "name": "[PVS] - Provideniya - Russia",
            "searchName": "PVS-Prowidenija-Provideniya-Puerto Vallarta-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "PWE",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Pevek",
            "name": "[PWE] - Pevek - Russia",
            "searchName": "PWE-Pevek-Painesville-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "PYJ",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Polyarnyj",
            "name": "[PYJ] - Polyarnyj - Russia",
            "searchName": "PYJ-Polyarnyj-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "REN",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Orenburg",
            "name": "[REN] - Orenburg - Russia",
            "searchName": "REN-Orenburg-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "RGK",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Gorno-Altaysk",
            "name": "[RGK] - Gorno-Altaysk - Russia",
            "searchName": "RGK-Gorno-Altaysk-Alta-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "RAT",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Raduzhnyi",
            "name": "[RAT] - Raduzhnyi - Russia",
            "searchName": "RAT-Raduzhnyi-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "SCW",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Syktyvkar",
            "name": "[SCW] - Syktyvkar - Russia",
            "searchName": "SCW-Syktywkar-Syktyvkar-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "RYB",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Rybinsk",
            "name": "[RYB] - Rybinsk - Russia",
            "searchName": "RYB-Rybinsk-Roxas City-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "RZN",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Ryazan",
            "name": "[RZN] - Ryazan - Russia",
            "searchName": "RZN-Ryazan-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "UZC",
            "countryCode": "RS",
            "cityCode": null,
            "cityName": "Uzice",
            "name": "[UZC] - Uzice - Serbia",
            "searchName": "UZC-Uzice-Szerbia-Sèrbia-Sirbistan-Srbsko-Serbia-Serbien-Srbija-Serbija-Serbie--Suudi Arabistan",
            "priority": 0
        },
        {
            "iataCode": "LGJ",
            "countryCode": "RO",
            "cityCode": null,
            "cityName": "Lugoj",
            "name": "[LGJ] - Lugoj - Romania",
            "searchName": "LGJ-Lugoj-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "IAS",
            "countryCode": "RO",
            "cityCode": null,
            "cityName": "Iasi",
            "name": "[IAS] - Iasi - Romania",
            "searchName": "IAS-Ia-Iasi-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "INI",
            "countryCode": "RS",
            "cityCode": null,
            "cityName": "Nis",
            "name": "[INI] - Nis - Serbia",
            "searchName": "INI-Nis-Nis Yu-Szerbia-Sèrbia-Sirbistan-Srbsko-Serbia-Serbien-Srbija-Serbija-Serbie--Suudi Arabistan",
            "priority": 0
        },
        {
            "iataCode": "OHO",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Okhotsk",
            "name": "[OHO] - Okhotsk - Russia",
            "searchName": "OHO-Ochotsk-Okhotsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "OKT",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Oktiabrskij",
            "name": "[OKT] - Oktiabrskij - Russia",
            "searchName": "OKT-Oktiabrskij-Yorke Island-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ODO",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Bodaybo",
            "name": "[ODO] - Bodaybo - Russia",
            "searchName": "ODO-Bodaybo-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "OGZ",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Vladikavkaz",
            "name": "[OGZ] - Vladikavkaz - Russia",
            "searchName": "OGZ-Wladikawkas-Vladikavkaz-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "OHH",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Okha",
            "name": "[OHH] - Okha - Russia",
            "searchName": "OHH-Okha-Hohhot-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "OEL",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Orel",
            "name": "[OEL] - Orel - Russia",
            "searchName": "OEL-Orjol-Orel-Vincennes-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NSK",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Norilsk",
            "name": "[NSK] - Norilsk - Russia",
            "searchName": "NSK-Noril sk-Norilsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NOZ",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Novokuznetsk",
            "name": "[NOZ] - Novokuznetsk - Russia",
            "searchName": "NOZ-Nowokusnezk-Novokuznetsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NNM",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Naryan-Mar",
            "name": "[NNM] - Naryan-Mar - Russia",
            "searchName": "NNM-Naryan-Mar-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NOI",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Novorossijsk",
            "name": "[NOI] - Novorossijsk - Russia",
            "searchName": "NOI-Novorossijsk-Nogales-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NOJ",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Nojabrxsk",
            "name": "[NOJ] - Nojabrxsk - Russia",
            "searchName": "NOJ-Nojabr sk-Nojabrxsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NYA",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Nyagan",
            "name": "[NYA] - Nyagan - Russia",
            "searchName": "NYA-Nyagan-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NYM",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Nadym",
            "name": "[NYM] - Nadym - Russia",
            "searchName": "NYM-Nadym-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NUX",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Novy Urengoy",
            "name": "[NUX] - Novy Urengoy - Russia",
            "searchName": "NUX-Novy Urengoy-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NVR",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Novgorod",
            "name": "[NVR] - Novgorod - Russia",
            "searchName": "NVR-Novgorod-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "PEX",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Pechora",
            "name": "[PEX] - Pechora - Russia",
            "searchName": "PEX-Petschora-Pechora-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "UUA",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Bugulma",
            "name": "[UUA] - Bugulma - Russia",
            "searchName": "UUA-Bugulma-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "VRI",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Varandey",
            "name": "[VRI] - Varandey - Russia",
            "searchName": "VRI-Varandey-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "VOZ",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Voronezh",
            "name": "[VOZ] - Voronezh - Russia",
            "searchName": "VOZ-Voronezh-Voronej-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "TYA",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Tula",
            "name": "[TYA] - Tula - Russia",
            "searchName": "TYA-Tula-Tunxi-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "TYD",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Tynda",
            "name": "[TYD] - Tynda - Russia",
            "searchName": "TYD-Tynda-Tunxi-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "UTS",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Ust-Tsilma",
            "name": "[UTS] - Ust-Tsilma - Russia",
            "searchName": "UTS-Ust-Tsilma-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "VLU",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Velikiye Luki",
            "name": "[VLU] - Velikiye Luki - Russia",
            "searchName": "VLU-Welikije Luki-Velikiye Luki-Vallejo-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "VLK",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Volgodonsk",
            "name": "[VLK] - Volgodonsk - Russia",
            "searchName": "VLK-Wolgodonsk-Volgodonsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "VKT",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Vorkuta",
            "name": "[VKT] - Vorkuta - Russia",
            "searchName": "VKT-Workuta-Vorkuta-Vorkouta-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "VGD",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Vologda",
            "name": "[VGD] - Vologda - Russia",
            "searchName": "VGD-Wologda-Vologda-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "VUS",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Velikij Ustyug",
            "name": "[VUS] - Velikij Ustyug - Russia",
            "searchName": "VUS-Velikij Ustyug-Valleduper-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NGK",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Nogliki",
            "name": "[NGK] - Nogliki - Russia",
            "searchName": "NGK-Nogliki-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NFG",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Nefteyugansk",
            "name": "[NFG] - Nefteyugansk - Russia",
            "searchName": "NFG-Nefteyugansk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NER",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Neryungri",
            "name": "[NER] - Neryungri - Russia",
            "searchName": "NER-Neryungri-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NEF",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Neftekamsk",
            "name": "[NEF] - Neftekamsk - Russia",
            "searchName": "NEF-Neftekamsk-Necochea-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NBC",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Naberevnye Chelny",
            "name": "[NBC] - Naberevnye Chelny - Russia",
            "searchName": "NBC-Naberezhnye Chelny-Naberevnye Chelny-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NAL",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Nalchik",
            "name": "[NAL] - Nalchik - Russia",
            "searchName": "NAL-Nalchik-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "SKX",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Saransk",
            "name": "[SKX] - Saransk - Russia",
            "searchName": "SKX-Saransk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "SLY",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Salekhard",
            "name": "[SLY] - Salekhard - Russia",
            "searchName": "SLY-Salechard-Salekhard-Salehard-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "MJZ",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Mirnyj",
            "name": "[MJZ] - Mirnyj - Russia",
            "searchName": "MJZ-Mirnyj-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "OSW",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Orsk",
            "name": "[OSW] - Orsk - Russia",
            "searchName": "OSW-Orsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "OVS",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Sovetsky",
            "name": "[OVS] - Sovetsky - Russia",
            "searchName": "OVS-Boscobel-Sovetsky-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "XJD",
            "countryCode": "QA",
            "cityCode": null,
            "cityName": "Al Udeid",
            "name": "[XJD] - Al Udeid - Qatar",
            "searchName": "XJD-Al Udeid-Katar-Qatar-Kataras-Katara-Quatar",
            "priority": 0
        },
        {
            "iataCode": "ZSE",
            "countryCode": "RE",
            "cityCode": null,
            "cityName": "St Pierre dela Reunion",
            "name": "[ZSE] - St Pierre dela Reunion - Reunion",
            "searchName": "ZSE-Saint-pierre-St Pierre dela Reunion-Pierre-St. Pierre de la Reunion-Réunion-Reunion-sziget-Illa Reunion-Reunion Adasi-Reunion Island-saari-øya-Insula Reunion-Reinjona-Reunjonas-Oto-Reunión",
            "priority": 0
        },
        {
            "iataCode": "RUN",
            "countryCode": "RE",
            "cityCode": null,
            "cityName": "St Denis de la Reunion",
            "name": "[RUN] - St Denis de la Reunion - Reunion",
            "searchName": "RUN-Saint Denis-St Denis de la Reunion-Réunion-Reunion-sziget-Illa Reunion-Reunion Adasi-Reunion Island-saari-øya-Insula Reunion-Reinjona-Reunjonas-Oto-Reunión",
            "priority": 0
        },
        {
            "iataCode": "SCV",
            "countryCode": "RO",
            "cityCode": null,
            "cityName": "Suceava",
            "name": "[SCV] - Suceava - Romania",
            "searchName": "SCV-Suceava-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "SBZ",
            "countryCode": "RO",
            "cityCode": null,
            "cityName": "Sibiu",
            "name": "[SBZ] - Sibiu - Romania",
            "searchName": "SBZ-Sibiu-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "QRS",
            "countryCode": "RO",
            "cityCode": null,
            "cityName": "Resita",
            "name": "[QRS] - Resita - Romania",
            "searchName": "QRS-Resita-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "QPL",
            "countryCode": "RO",
            "cityCode": null,
            "cityName": "Ploiesti",
            "name": "[QPL] - Ploiesti - Romania",
            "searchName": "QPL-Ploiesti-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "OMR",
            "countryCode": "RO",
            "cityCode": null,
            "cityName": "Oradea",
            "name": "[OMR] - Oradea - Romania",
            "searchName": "OMR-Oradea-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "XHV",
            "countryCode": "RO",
            "cityCode": null,
            "cityName": "Brasov",
            "name": "[XHV] - Brasov - Romania",
            "searchName": "XHV-Brasov-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "TSR",
            "countryCode": "RO",
            "cityCode": null,
            "cityName": "Timisoara",
            "name": "[TSR] - Timisoara - Romania",
            "searchName": "TSR-Freidorf-Timisoara-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "TGM",
            "countryCode": "RO",
            "cityCode": null,
            "cityName": "Tirgu Mures",
            "name": "[TGM] - Tirgu Mures - Romania",
            "searchName": "TGM-Tirgu Mures-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "TCE",
            "countryCode": "RO",
            "cityCode": null,
            "cityName": "Tulcea",
            "name": "[TCE] - Tulcea - Romania",
            "searchName": "TCE-Tulcea-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "SUJ",
            "countryCode": "RO",
            "cityCode": null,
            "cityName": "Satu Mare",
            "name": "[SUJ] - Satu Mare - Romania",
            "searchName": "SUJ-Satu Mare-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "CRA",
            "countryCode": "RO",
            "cityCode": null,
            "cityName": "Craiova",
            "name": "[CRA] - Craiova - Romania",
            "searchName": "CRA-Craiova-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "CND",
            "countryCode": "RO",
            "cityCode": null,
            "cityName": "Constanta",
            "name": "[CND] - Constanta - Romania",
            "searchName": "CND-Constanta-Constanza-Costanza-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "AYO",
            "countryCode": "PY",
            "cityCode": null,
            "cityName": "Ayolas",
            "name": "[AYO] - Ayolas - Paraguay",
            "searchName": "AYO-Ayolas-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "AGT",
            "countryCode": "PY",
            "cityCode": null,
            "cityName": "Ciudad Del Este",
            "name": "[AGT] - Ciudad Del Este - Paraguay",
            "searchName": "AGT-Ciudad Del Este-Ciudad del Este-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "CIO",
            "countryCode": "PY",
            "cityCode": null,
            "cityName": "Concepcion",
            "name": "[CIO] - Concepcion - Paraguay",
            "searchName": "CIO-Concepcion-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "FLM",
            "countryCode": "PY",
            "cityCode": null,
            "cityName": "Filadelfia",
            "name": "[FLM] - Filadelfia - Paraguay",
            "searchName": "FLM-Filadelfia-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "ENO",
            "countryCode": "PY",
            "cityCode": null,
            "cityName": "Encarnacion",
            "name": "[ENO] - Encarnacion - Paraguay",
            "searchName": "ENO-Encarnacion-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "ESG",
            "countryCode": "PY",
            "cityCode": null,
            "cityName": "Mariscal Estigarribia",
            "name": "[ESG] - Mariscal Estigarribia - Paraguay",
            "searchName": "ESG-Mariscal Estigarribia-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "CSB",
            "countryCode": "RO",
            "cityCode": null,
            "cityName": "Caransebes",
            "name": "[CSB] - Caransebes - Romania",
            "searchName": "CSB-Caransebes-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "BCM",
            "countryCode": "RO",
            "cityCode": null,
            "cityName": "Bacau",
            "name": "[BCM] - Bacau - Romania",
            "searchName": "BCM-Bac-Bacau-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "BAY",
            "countryCode": "RO",
            "cityCode": null,
            "cityName": "Baia Mare",
            "name": "[BAY] - Baia Mare - Romania",
            "searchName": "BAY-Baia Mare-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "ARW",
            "countryCode": "RO",
            "cityCode": null,
            "cityName": "Arad",
            "name": "[ARW] - Arad - Romania",
            "searchName": "ARW-Arad-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "PDL",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Ponta Delgada",
            "name": "[PDL] - Ponta Delgada - Portugal",
            "searchName": "PDL-Azores-Ponta Delgada-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "ROR",
            "countryCode": "PW",
            "cityCode": null,
            "cityName": "Koror",
            "name": "[ROR] - Koror - Palau",
            "searchName": "ROR-Koror-Palau-Palaos",
            "priority": 0
        },
        {
            "iataCode": "PIL",
            "countryCode": "PY",
            "cityCode": null,
            "cityName": "Pilar",
            "name": "[PIL] - Pilar - Paraguay",
            "searchName": "PIL-Pilar-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "PJC",
            "countryCode": "PY",
            "cityCode": null,
            "cityName": "Pedro Juan Caballero",
            "name": "[PJC] - Pedro Juan Caballero - Paraguay",
            "searchName": "PJC-Pedro Juan Caballero-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "PCJ",
            "countryCode": "PY",
            "cityCode": null,
            "cityName": "Puerto La Victoria",
            "name": "[PCJ] - Puerto La Victoria - Paraguay",
            "searchName": "PCJ-Puerto La Victoria-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "PBT",
            "countryCode": "PY",
            "cityCode": null,
            "cityName": "Puerto Leda",
            "name": "[PBT] - Puerto Leda - Paraguay",
            "searchName": "PBT-Puerto Leda-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "OLK",
            "countryCode": "PY",
            "cityCode": null,
            "cityName": "Fuerte Olimpo",
            "name": "[OLK] - Fuerte Olimpo - Paraguay",
            "searchName": "OLK-Fuerte Olimpo-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "VMI",
            "countryCode": "PY",
            "cityCode": null,
            "cityName": "Vallemi",
            "name": "[VMI] - Vallemi - Paraguay",
            "searchName": "VMI-Vallemi-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "BFA",
            "countryCode": "PY",
            "cityCode": null,
            "cityName": "Bahia Negra",
            "name": "[BFA] - Bahia Negra - Paraguay",
            "searchName": "BFA-Bahia Negra MN-Bahia Negra-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "COV",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Covilha",
            "name": "[COV] - Covilha - Portugal",
            "searchName": "COV-Covilha-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "CHV",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Chaves",
            "name": "[CHV] - Chaves - Portugal",
            "searchName": "CHV-Chaves-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "CVU",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Corvo Island",
            "name": "[CVU] - Corvo Island - Portugal",
            "searchName": "CVU-Corvo Island-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "BYJ",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Beja",
            "name": "[BYJ] - Beja - Portugal",
            "searchName": "BYJ-Beja-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "CBP",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Coimbra",
            "name": "[CBP] - Coimbra - Portugal",
            "searchName": "CBP-Coimbra-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "BGC",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Braganca",
            "name": "[BGC] - Braganca - Portugal",
            "searchName": "BGC-Braganca-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "BGZ",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Braga",
            "name": "[BGZ] - Braga - Portugal",
            "searchName": "BGZ-Braga-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "VRL",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Vila Real",
            "name": "[VRL] - Vila Real - Portugal",
            "searchName": "VRL-Vila Real-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "VSE",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Viseu",
            "name": "[VSE] - Viseu - Portugal",
            "searchName": "VSE-Viseu-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "TER",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Terceira Island",
            "name": "[TER] - Terceira Island - Portugal",
            "searchName": "TER-Terceira Island--Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "SJZ",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Sao Jorge Island",
            "name": "[SJZ] - Sao Jorge Island - Portugal",
            "searchName": "SJZ-Sao Jorge Island--Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "SIE",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Sines",
            "name": "[SIE] - Sines - Portugal",
            "searchName": "SIE-Sines-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "SMA",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Santa Maria",
            "name": "[SMA] - Santa Maria - Portugal",
            "searchName": "SMA-Santa Maria-)-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "PRM",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Portimao",
            "name": "[PRM] - Portimao - Portugal",
            "searchName": "PRM-Portimao-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "PIX",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Pico Island",
            "name": "[PIX] - Pico Island - Portugal",
            "searchName": "PIX-Pico Island--Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "PXO",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Porto Santo",
            "name": "[PXO] - Porto Santo - Portugal",
            "searchName": "PXO-Porto Santo-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "RZE",
            "countryCode": "PL",
            "cityCode": null,
            "cityName": "Rzeszow",
            "name": "[RZE] - Rzeszow - Poland",
            "searchName": "RZE-Rzesz-Rzeszow-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "QJY",
            "countryCode": "PL",
            "cityCode": null,
            "cityName": "Kolobrzeg",
            "name": "[QJY] - Kolobrzeg - Poland",
            "searchName": "QJY-Kolobrzeg-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "POZ",
            "countryCode": "PL",
            "cityCode": null,
            "cityName": "Poznan",
            "name": "[POZ] - Poznan - Poland",
            "searchName": "POZ-Poznan-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "OSZ",
            "countryCode": "PL",
            "cityCode": null,
            "cityName": "Koszalin",
            "name": "[OSZ] - Koszalin - Poland",
            "searchName": "OSZ-Koszalin-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "MQC",
            "countryCode": "PM",
            "cityCode": null,
            "cityName": "Miquelon",
            "name": "[MQC] - Miquelon - Saint Pierre and Miquelon",
            "searchName": "MQC-Miquelon-Saint Pierre and Miquelon-St. Pierre und Miquelon-Pierre ve Miquelon-San Pedro y Miquelón-Saint-Pierre-et-Saint Pierre e Miquelon",
            "priority": 0
        },
        {
            "iataCode": "FSP",
            "countryCode": "PM",
            "cityCode": null,
            "cityName": "St Pierre",
            "name": "[FSP] - St Pierre - Saint Pierre and Miquelon",
            "searchName": "FSP-St Pierre-Saint-Pierre-Saint Pierre and Miquelon-St. Pierre und Miquelon-Pierre ve Miquelon-San Pedro y Miquelón-et-Miquelon-Saint Pierre e Miquelon",
            "priority": 0
        },
        {
            "iataCode": "HUC",
            "countryCode": "PR",
            "cityCode": null,
            "cityName": "Humacao",
            "name": "[HUC] - Humacao - Puerto Rico",
            "searchName": "HUC-Humacao-Puerto Rico-Porto Riko-Portoriko-Puerto Rikas-Puertoriko--Porto Rico-Portorico",
            "priority": 0
        },
        {
            "iataCode": "MAZ",
            "countryCode": "PR",
            "cityCode": null,
            "cityName": "Mayaguez",
            "name": "[MAZ] - Mayaguez - Puerto Rico",
            "searchName": "MAZ-Mayaguez-Puerto Rico-Porto Riko-Portoriko-Puerto Rikas-Puertoriko--Porto Rico-Portorico",
            "priority": 0
        },
        {
            "iataCode": "BQN",
            "countryCode": "PR",
            "cityCode": null,
            "cityName": "Aguadilla",
            "name": "[BQN] - Aguadilla - Puerto Rico",
            "searchName": "BQN-Aguadilla-Puerto Rico-Porto Riko-Portoriko-Puerto Rikas-Puertoriko--Porto Rico-Portorico",
            "priority": 0
        },
        {
            "iataCode": "ARE",
            "countryCode": "PR",
            "cityCode": null,
            "cityName": "Arecibo",
            "name": "[ARE] - Arecibo - Puerto Rico",
            "searchName": "ARE-Arecibo-Puerto Rico-Porto Riko-Portoriko-Puerto Rikas-Puertoriko--Porto Rico-Portorico",
            "priority": 0
        },
        {
            "iataCode": "DDP",
            "countryCode": "PR",
            "cityCode": null,
            "cityName": "Dorado",
            "name": "[DDP] - Dorado - Puerto Rico",
            "searchName": "DDP-Dorado-Puerto Rico-Porto Riko-Portoriko-Puerto Rikas-Puertoriko--Porto Rico-Portorico",
            "priority": 0
        },
        {
            "iataCode": "CPX",
            "countryCode": "PR",
            "cityCode": null,
            "cityName": "Culebra",
            "name": "[CPX] - Culebra - Puerto Rico",
            "searchName": "CPX-Culebra-Puerto Rico-Porto Riko-Portoriko-Puerto Rikas-Puertoriko--Porto Rico-Portorico",
            "priority": 0
        },
        {
            "iataCode": "FAJ",
            "countryCode": "PR",
            "cityCode": null,
            "cityName": "Fajardo",
            "name": "[FAJ] - Fajardo - Puerto Rico",
            "searchName": "FAJ-Fajardo-Puerto Rico-Porto Riko-Portoriko-Puerto Rikas-Puertoriko--Porto Rico-Portorico",
            "priority": 0
        },
        {
            "iataCode": "NRR",
            "countryCode": "PR",
            "cityCode": null,
            "cityName": "Roosevelt Roads",
            "name": "[NRR] - Roosevelt Roads - Puerto Rico",
            "searchName": "NRR-Roosevelt Roads-Puerto Rico-Porto Riko-Portoriko-Puerto Rikas-Puertoriko--Porto Rico-Portorico",
            "priority": 0
        },
        {
            "iataCode": "PSE",
            "countryCode": "PR",
            "cityCode": null,
            "cityName": "Ponce",
            "name": "[PSE] - Ponce - Puerto Rico",
            "searchName": "PSE-Ponce-Puerto Rico-Porto Riko-Portoriko-Puerto Rikas-Puertoriko--Porto Rico-Portorico",
            "priority": 0
        },
        {
            "iataCode": "SJU",
            "countryCode": "PR",
            "cityCode": null,
            "cityName": "San Juan",
            "name": "[SJU] - San Juan - Puerto Rico",
            "searchName": "SJU-San Juan-Puerto Rico-Porto Riko-Portoriko-Puerto Rikas-Puertoriko--Porto Rico-Portorico",
            "priority": 0
        },
        {
            "iataCode": "VQS",
            "countryCode": "PR",
            "cityCode": null,
            "cityName": "Vieques",
            "name": "[VQS] - Vieques - Puerto Rico",
            "searchName": "VQS-Vieques-Puerto Rico-Porto Riko-Portoriko-Puerto Rikas-Puertoriko--Porto Rico-Portorico",
            "priority": 0
        },
        {
            "iataCode": "GZA",
            "countryCode": "PS",
            "cityCode": null,
            "cityName": "Rafah",
            "name": "[GZA] - Rafah - Palestinian Territory",
            "searchName": "GZA-Gaza-Rafah-Gazze-Palestinian Territory-Palestinian T-Palästina-Palestine-Palestina",
            "priority": 0
        },
        {
            "iataCode": "GRW",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Graciosa Island",
            "name": "[GRW] - Graciosa Island - Portugal",
            "searchName": "GRW-Graciosa Island-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "HOR",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Horta",
            "name": "[HOR] - Horta - Portugal",
            "searchName": "HOR-Horta-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "LAO",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Laoag",
            "name": "[LAO] - Laoag - Philippines",
            "searchName": "LAO-Laoag-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "LBX",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Lubang",
            "name": "[LBX] - Lubang - Philippines",
            "searchName": "LBX-Lubang-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "JOL",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Jolo",
            "name": "[JOL] - Jolo - Philippines",
            "searchName": "JOL-Jolo-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "ILO",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Iloilo",
            "name": "[ILO] - Iloilo - Philippines",
            "searchName": "ILO-Iloilo-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "IGN",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Iligan",
            "name": "[IGN] - Iligan - Philippines",
            "searchName": "IGN-Iligan-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "IPE",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Ipil",
            "name": "[IPE] - Ipil - Philippines",
            "searchName": "IPE-Ipil-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "IAO",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Del Carmen",
            "name": "[IAO] - Del Carmen - Philippines",
            "searchName": "IAO-Del Carmen-General Luna-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "ICO",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Sicogon Island",
            "name": "[ICO] - Sicogon Island - Philippines",
            "searchName": "ICO-Sicogon Island-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "GES",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "General Santos",
            "name": "[GES] - General Santos - Philippines",
            "searchName": "GES-General Santos-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "DGT",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Dumaguete",
            "name": "[DGT] - Dumaguete - Philippines",
            "searchName": "DGT-Dumaguete-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CYU",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Cuyo",
            "name": "[CYU] - Cuyo - Philippines",
            "searchName": "CYU-Cuyo-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CYZ",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Cauayan",
            "name": "[CYZ] - Cauayan - Philippines",
            "searchName": "CYZ-Cauayan-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CYP",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Calbayog",
            "name": "[CYP] - Calbayog - Philippines",
            "searchName": "CYP-Calbayog-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CUJ",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Culion",
            "name": "[CUJ] - Culion - Philippines",
            "searchName": "CUJ-Culion-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CRM",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Catarman",
            "name": "[CRM] - Catarman - Philippines",
            "searchName": "CRM-Catarman-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CGM",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Camiguin",
            "name": "[CGM] - Camiguin - Philippines",
            "searchName": "CGM-Camiguin-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CGG",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Casiguran",
            "name": "[CGG] - Casiguran - Philippines",
            "searchName": "CGG-Casiguran-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CGY",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Cagayan De Oro",
            "name": "[CGY] - Cagayan De Oro - Philippines",
            "searchName": "CGY-Cagayan De Oro-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CEB",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Cebu",
            "name": "[CEB] - Cebu - Philippines",
            "searchName": "CEB-Cebu-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CDY",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Cagayan De Sulu",
            "name": "[CDY] - Cagayan De Sulu - Philippines",
            "searchName": "CDY-Cagayan de Sulu-Cagayan De Sulu-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "DPL",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Dipolog",
            "name": "[DPL] - Dipolog - Philippines",
            "searchName": "DPL-Dipolog-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "DSG",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Dilasag",
            "name": "[DSG] - Dilasag - Philippines",
            "searchName": "DSG-Dilasag-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "DTE",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Daet",
            "name": "[DTE] - Daet - Philippines",
            "searchName": "DTE-Daet-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "DVO",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Davao",
            "name": "[DVO] - Davao - Philippines",
            "searchName": "DVO-Davao-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "ENI",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "El Nido",
            "name": "[ENI] - El Nido - Philippines",
            "searchName": "ENI-El Nido-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "EUQ",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Antique",
            "name": "[EUQ] - Antique - Philippines",
            "searchName": "EUQ-Antique-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "BSO",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Basco",
            "name": "[BSO] - Basco - Philippines",
            "searchName": "BSO-Basco-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "BQA",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Baler",
            "name": "[BQA] - Baler - Philippines",
            "searchName": "BQA-Baler-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "BXU",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Butuan",
            "name": "[BXU] - Butuan - Philippines",
            "searchName": "BXU-Butuan City-Butuan-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CBO",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Cotabato",
            "name": "[CBO] - Cotabato - Philippines",
            "searchName": "CBO-Cotabato-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "BNQ",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Baganga",
            "name": "[BNQ] - Baganga - Philippines",
            "searchName": "BNQ-Baganga-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "BPH",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Bislig",
            "name": "[BPH] - Bislig - Philippines",
            "searchName": "BPH-Bislig-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "BAG",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Baguio",
            "name": "[BAG] - Baguio - Philippines",
            "searchName": "BAG-Baguio-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "BCD",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Bacolod",
            "name": "[BCD] - Bacolod - Philippines",
            "searchName": "BCD-Bacolod-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "AAV",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Surallah",
            "name": "[AAV] - Surallah - Philippines",
            "searchName": "AAV-Alah-Surallah-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "ZAM",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Zamboanga",
            "name": "[ZAM] - Zamboanga - Philippines",
            "searchName": "ZAM-Zamboanga-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "XCN",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Puerto Princesa City",
            "name": "[XCN] - Puerto Princesa City - Philippines",
            "searchName": "XCN-Puerto Princesa City-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "WNP",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Naga",
            "name": "[WNP] - Naga - Philippines",
            "searchName": "WNP-Naga City-Naga-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "XMA",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Maramag",
            "name": "[XMA] - Maramag - Philippines",
            "searchName": "XMA-Maramag-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "XSO",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Siocon",
            "name": "[XSO] - Siocon - Philippines",
            "searchName": "XSO-Siocon-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "TBH",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Tablas",
            "name": "[TBH] - Tablas - Philippines",
            "searchName": "TBH-Tablas-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "TAG",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Tagbilaran",
            "name": "[TAG] - Tagbilaran - Philippines",
            "searchName": "TAG-Tagbilaran-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "TAC",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Tacloban",
            "name": "[TAC] - Tacloban - Philippines",
            "searchName": "TAC-Tacloban City-Tacloban-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "SUG",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Surigao",
            "name": "[SUG] - Surigao - Philippines",
            "searchName": "SUG-Surigao-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "SSV",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Siasi",
            "name": "[SSV] - Siasi - Philippines",
            "searchName": "SSV-Siasi-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "TDG",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Tandag",
            "name": "[TDG] - Tandag - Philippines",
            "searchName": "TDG-Tandag-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "TGB",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Tagbita",
            "name": "[TGB] - Tagbita - Philippines",
            "searchName": "TGB-Tagbita-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "TUG",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Tuguegarao",
            "name": "[TUG] - Tuguegarao - Philippines",
            "searchName": "TUG-Tuguegarao-Tuguegarao City-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "TWT",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Tawitawi",
            "name": "[TWT] - Tawitawi - Philippines",
            "searchName": "TWT-Tawitawi-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "VRC",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Virac",
            "name": "[VRC] - Virac - Philippines",
            "searchName": "VRC-Virac-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "USU",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Busuanga",
            "name": "[USU] - Busuanga - Philippines",
            "searchName": "USU-Busuanga-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "SGS",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Sanga Sanga",
            "name": "[SGS] - Sanga Sanga - Philippines",
            "searchName": "SGS-Sanga Sanga-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "SFS",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Subic Bay",
            "name": "[SFS] - Subic Bay - Philippines",
            "searchName": "SFS-Subic Bay-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "SFE",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "San Fernando",
            "name": "[SFE] - San Fernando - Philippines",
            "searchName": "SFE-San Fernando-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "SJI",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "San Jose",
            "name": "[SJI] - San Jose - Philippines",
            "searchName": "SJI-San Jose-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "RZP",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Taytay Sandoval",
            "name": "[RZP] - Taytay Sandoval - Philippines",
            "searchName": "RZP-Taytay Sandoval-Taytay-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "RXS",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Roxas City",
            "name": "[RXS] - Roxas City - Philippines",
            "searchName": "RXS-Roxas-Roxas City-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "PPS",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Puerto Princesa",
            "name": "[PPS] - Puerto Princesa - Philippines",
            "searchName": "PPS-Puerto Princesa-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "OZC",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Ozamis City",
            "name": "[OZC] - Ozamis City - Philippines",
            "searchName": "OZC-Ozamis City-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "PAG",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Pagadian",
            "name": "[PAG] - Pagadian - Philippines",
            "searchName": "PAG-Pagadian-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "OMC",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Ormoc",
            "name": "[OMC] - Ormoc - Philippines",
            "searchName": "OMC-Ormoc-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "NSP",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Sangley Point",
            "name": "[NSP] - Sangley Point - Philippines",
            "searchName": "NSP-Sangley Point-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "NCP",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Luzon Island",
            "name": "[NCP] - Luzon Island - Philippines",
            "searchName": "NCP-Luzon Is-Luzon Island-Angeles City-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "MXI",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Mati",
            "name": "[MXI] - Mati - Philippines",
            "searchName": "MXI-Mati-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "MRQ",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Marinduque",
            "name": "[MRQ] - Marinduque - Philippines",
            "searchName": "MRQ-Marinduque-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "KTW",
            "countryCode": "PL",
            "cityCode": null,
            "cityName": "Katowice",
            "name": "[KTW] - Katowice - Poland",
            "searchName": "KTW-Kattowitz-Katowice-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "IEG",
            "countryCode": "PL",
            "cityCode": null,
            "cityName": "Zielona Gora",
            "name": "[IEG] - Zielona Gora - Poland",
            "searchName": "IEG-Zielona Gora-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "GDN",
            "countryCode": "PL",
            "cityCode": null,
            "cityName": "Gdansk",
            "name": "[GDN] - Gdansk - Poland",
            "searchName": "GDN-Danzig-Gdansk-Danzica-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "CZW",
            "countryCode": "PL",
            "cityCode": null,
            "cityName": "Czestochowa",
            "name": "[CZW] - Czestochowa - Poland",
            "searchName": "CZW-Czestochowa-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "BXP",
            "countryCode": "PL",
            "cityCode": null,
            "cityName": "Biala Podlaska",
            "name": "[BXP] - Biala Podlaska - Poland",
            "searchName": "BXP-Biala Podlaska-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "BZG",
            "countryCode": "PL",
            "cityCode": null,
            "cityName": "Bydgoszcz",
            "name": "[BZG] - Bydgoszcz - Poland",
            "searchName": "BZG-Bydgoszcz-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "ZMC",
            "countryCode": "PL",
            "cityCode": null,
            "cityName": "Miedzyzdroje",
            "name": "[ZMC] - Miedzyzdroje - Poland",
            "searchName": "ZMC-Miedzyzdroje-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "WMI",
            "countryCode": "PL",
            "cityCode": null,
            "cityName": "Nowy Dwor Mazowiecki",
            "name": "[WMI] - Nowy Dwor Mazowiecki - Poland",
            "searchName": "WMI-Nowy Dw-Nowy Dwor Mazowiecki-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "WRO",
            "countryCode": "PL",
            "cityCode": null,
            "cityName": "Wroclaw",
            "name": "[WRO] - Wroclaw - Poland",
            "searchName": "WRO-Breslau-Wroclaw-Breslavia-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "SZY",
            "countryCode": "PL",
            "cityCode": null,
            "cityName": "Szymany",
            "name": "[SZY] - Szymany - Poland",
            "searchName": "SZY-Szymany-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "SZZ",
            "countryCode": "PL",
            "cityCode": null,
            "cityName": "Szczecin",
            "name": "[SZZ] - Szczecin - Poland",
            "searchName": "SZZ-Szczecin-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "LRG",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Lora Lai",
            "name": "[LRG] - Lora Lai - Pakistan",
            "searchName": "LRG-Loralai-Lora Lai-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "LUZ",
            "countryCode": "PL",
            "cityCode": null,
            "cityName": "Lushan",
            "name": "[LUZ] - Lushan - Poland",
            "searchName": "LUZ-Lushan-Lublin-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "LCJ",
            "countryCode": "PL",
            "cityCode": null,
            "cityName": "Lodz",
            "name": "[LCJ] - Lodz - Poland",
            "searchName": "LCJ-Lodz-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "LYP",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Faisalabad",
            "name": "[LYP] - Faisalabad - Pakistan",
            "searchName": "LYP-Faisalabad-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "MJD",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Mohenjodaro",
            "name": "[MJD] - Mohenjodaro - Pakistan",
            "searchName": "MJD-Mohenjodaro-Mohenjo-Daro-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "MFG",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Muzaffarabad",
            "name": "[MFG] - Muzaffarabad - Pakistan",
            "searchName": "MFG-Muzaffarabad-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "JAG",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Jacobabad",
            "name": "[JAG] - Jacobabad - Pakistan",
            "searchName": "JAG-Jacobabad-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "KDU",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Skardu",
            "name": "[KDU] - Skardu - Pakistan",
            "searchName": "KDU-Skardu-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "KDD",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Khuzdar",
            "name": "[KDD] - Khuzdar - Pakistan",
            "searchName": "KDD-Khuzdar-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "KBH",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Kalat",
            "name": "[KBH] - Kalat - Pakistan",
            "searchName": "KBH-Kalat-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "KCF",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Kadanwari",
            "name": "[KCF] - Kadanwari - Pakistan",
            "searchName": "KCF-Kadanwari-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "KGU",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Keningau",
            "name": "[KGU] - Keningau - Malaysia",
            "searchName": "KGU-Keningau-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "MAU",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Maupiti",
            "name": "[MAU] - Maupiti - French Polynesia",
            "searchName": "MAU-Maupiti-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "KHZ",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Kauehi",
            "name": "[KHZ] - Kauehi - French Polynesia",
            "searchName": "KHZ-Kauehi-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "KKR",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Kaukura Atoll",
            "name": "[KKR] - Kaukura Atoll - French Polynesia",
            "searchName": "KKR-Kaukura Atoll-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "KXU",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Katiu",
            "name": "[KXU] - Katiu - French Polynesia",
            "searchName": "KXU-Katiu-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "HUH",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Huahine",
            "name": "[HUH] - Huahine - French Polynesia",
            "searchName": "HUH-Huahine-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "GMR",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Gambier Island",
            "name": "[GMR] - Gambier Island - French Polynesia",
            "searchName": "GMR-Gambier Is-Gambier Island-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "HOI",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Hao Island",
            "name": "[HOI] - Hao Island - French Polynesia",
            "searchName": "HOI-Hao Island--Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "HHZ",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Hikueru",
            "name": "[HHZ] - Hikueru - French Polynesia",
            "searchName": "HHZ-Hikueru-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "FAV",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Fakarava",
            "name": "[FAV] - Fakarava - French Polynesia",
            "searchName": "FAV-Fakarava-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "FAC",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Faaite",
            "name": "[FAC] - Faaite - French Polynesia",
            "searchName": "FAC-Faaite-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "FGU",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Fangatau",
            "name": "[FGU] - Fangatau - French Polynesia",
            "searchName": "FGU-Fangatau-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "FHZ",
            "countryCode": "PF",
            "cityCode": null,
            "cityName": "Fakahina",
            "name": "[FHZ] - Fakahina - French Polynesia",
            "searchName": "FHZ-Fakahina-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "MPH",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Caticlan",
            "name": "[MPH] - Caticlan - Philippines",
            "searchName": "MPH-Caticlan-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "MLP",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Malabang",
            "name": "[MLP] - Malabang - Philippines",
            "searchName": "MLP-Malabang-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "MPD",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Mirpur Khas",
            "name": "[MPD] - Mirpur Khas - Pakistan",
            "searchName": "MPD-Mirpur Khas-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "MUX",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Multan",
            "name": "[MUX] - Multan - Pakistan",
            "searchName": "MUX-Multan-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "MWD",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Mianwali",
            "name": "[MWD] - Mianwali - Pakistan",
            "searchName": "MWD-Mianwali-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "NHS",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Nushki",
            "name": "[NHS] - Nushki - Pakistan",
            "searchName": "NHS-Nushki-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "OHT",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Kohat",
            "name": "[OHT] - Kohat - Pakistan",
            "searchName": "OHT-Kohat-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "PAJ",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Para Chinar",
            "name": "[PAJ] - Para Chinar - Pakistan",
            "searchName": "PAJ-Para Chinar-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "PEW",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Peshawar",
            "name": "[PEW] - Peshawar - Pakistan",
            "searchName": "PEW-Peschawar-Peshawar-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "ORW",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Ormara",
            "name": "[ORW] - Ormara - Pakistan",
            "searchName": "ORW-Ormara-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "PSI",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Pasni",
            "name": "[PSI] - Pasni - Pakistan",
            "searchName": "PSI-Pasni-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "PJG",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Panjgur",
            "name": "[PJG] - Panjgur - Pakistan",
            "searchName": "PJG-Panjgur-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "RAZ",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Rawala Kot",
            "name": "[RAZ] - Rawala Kot - Pakistan",
            "searchName": "RAZ-Rawala Kot-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "REQ",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Reko Diq",
            "name": "[REQ] - Reko Diq - Pakistan",
            "searchName": "REQ-Reko Diq-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "PZH",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Zhob",
            "name": "[PZH] - Zhob - Pakistan",
            "searchName": "PZH-Zhob-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "RYK",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Rahim Yar Khan",
            "name": "[RYK] - Rahim Yar Khan - Pakistan",
            "searchName": "RYK-Rahimyar Khan-Rahim Yar Khan-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "RZS",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Sawan",
            "name": "[RZS] - Sawan - Pakistan",
            "searchName": "RZS-Sawan-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "SBQ",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Sibi",
            "name": "[SBQ] - Sibi - Pakistan",
            "searchName": "SBQ-Sibi-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "SDT",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Saidu Sharif",
            "name": "[SDT] - Saidu Sharif - Pakistan",
            "searchName": "SDT-Saidu Sharif-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "SGI",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Sargodha",
            "name": "[SGI] - Sargodha - Pakistan",
            "searchName": "SGI-Sargodha-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "SKZ",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Sukkur",
            "name": "[SKZ] - Sukkur - Pakistan",
            "searchName": "SKZ-Sukkur-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "SKT",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Sialkot",
            "name": "[SKT] - Sialkot - Pakistan",
            "searchName": "SKT-Sialkot-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "WGB",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Bahawalnagar",
            "name": "[WGB] - Bahawalnagar - Pakistan",
            "searchName": "WGB-Bahawalnagar-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "WAF",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Wana",
            "name": "[WAF] - Wana - Pakistan",
            "searchName": "WAF-Wana-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "TUK",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Turbat",
            "name": "[TUK] - Turbat - Pakistan",
            "searchName": "TUK-Turbat-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "UET",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Quetta",
            "name": "[UET] - Quetta - Pakistan",
            "searchName": "UET-Quetta-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "TFT",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Taftan",
            "name": "[TFT] - Taftan - Pakistan",
            "searchName": "TFT-Taftan-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "TLB",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Tarbela",
            "name": "[TLB] - Tarbela - Pakistan",
            "searchName": "TLB-Tarbela-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "SUL",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Sui",
            "name": "[SUL] - Sui - Pakistan",
            "searchName": "SUL-Sui-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "SWN",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Sahiwal",
            "name": "[SWN] - Sahiwal - Pakistan",
            "searchName": "SWN-Sahiwal-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "SWV",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Shikarpur",
            "name": "[SWV] - Shikarpur - Pakistan",
            "searchName": "SWV-Shikarpur-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "SYW",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Sehwen Sharif",
            "name": "[SYW] - Sehwen Sharif - Pakistan",
            "searchName": "SYW-Sehwen-Sehwen Sharif-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "WNS",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Nawabshah",
            "name": "[WNS] - Nawabshah - Pakistan",
            "searchName": "WNS-Nawabshah-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "ZIZ",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Zamzama",
            "name": "[ZIZ] - Zamzama - Pakistan",
            "searchName": "ZIZ-Zamzama-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "AAW",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Abbottabad",
            "name": "[AAW] - Abbottabad - Pakistan",
            "searchName": "AAW-Abbottabad-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "ATG",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Attock",
            "name": "[ATG] - Attock - Pakistan",
            "searchName": "ATG-Attock-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "BNP",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Bannu",
            "name": "[BNP] - Bannu - Pakistan",
            "searchName": "BNP-Bannu-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "BHV",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Bahawalpur",
            "name": "[BHV] - Bahawalpur - Pakistan",
            "searchName": "BHV-Bahawalpur-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "BHC",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Bhurban",
            "name": "[BHC] - Bhurban - Pakistan",
            "searchName": "BHC-Bhurban-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "BDN",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Badin",
            "name": "[BDN] - Badin - Pakistan",
            "searchName": "BDN-Badin-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "DSK",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Dera Ismail Khan",
            "name": "[DSK] - Dera Ismail Khan - Pakistan",
            "searchName": "DSK-Dera Ismail Khan-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "CHB",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Chilas",
            "name": "[CHB] - Chilas - Pakistan",
            "searchName": "CHB-Chilas-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "CJL",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Chitral",
            "name": "[CJL] - Chitral - Pakistan",
            "searchName": "CJL-Chitral-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "CWP",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Campbellpore",
            "name": "[CWP] - Campbellpore - Pakistan",
            "searchName": "CWP-Campbellpore-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "DBA",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Dalbandin",
            "name": "[DBA] - Dalbandin - Pakistan",
            "searchName": "DBA-Dalbandin-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "DDU",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Dadu",
            "name": "[DDU] - Dadu - Pakistan",
            "searchName": "DDU-Dadu-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "DEA",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Dera Ghazi Khan",
            "name": "[DEA] - Dera Ghazi Khan - Pakistan",
            "searchName": "DEA-Dera Ghazi Khan-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "GIL",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Gilgit",
            "name": "[GIL] - Gilgit - Pakistan",
            "searchName": "GIL-Gilgit-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "GRT",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Gujrat",
            "name": "[GRT] - Gujrat - Pakistan",
            "searchName": "GRT-Gujrat-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "GWD",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Gwadar",
            "name": "[GWD] - Gwadar - Pakistan",
            "searchName": "GWD-Gwadar-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "HDD",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Hyderabad",
            "name": "[HDD] - Hyderabad - Pakistan",
            "searchName": "HDD-Hyderabad-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "HRA",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Mansehra",
            "name": "[HRA] - Mansehra - Pakistan",
            "searchName": "HRA-Mansehra-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "JIW",
            "countryCode": "PK",
            "cityCode": null,
            "cityName": "Jiwani",
            "name": "[JIW] - Jiwani - Pakistan",
            "searchName": "JIW-Jiwani-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "CHT",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Chatham Island",
            "name": "[CHT] - Chatham Island - New Zealand",
            "searchName": "CHT-Chatham Island--Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda-Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "DUD",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Dunedin",
            "name": "[DUD] - Dunedin - New Zealand",
            "searchName": "DUD-Dunedin-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "FGL",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Fox Glacier",
            "name": "[FGL] - Fox Glacier - New Zealand",
            "searchName": "FGL-Fox Glacier-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "BHE",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Blenheim",
            "name": "[BHE] - Blenheim - New Zealand",
            "searchName": "BHE-Blenheim-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "ALR",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Alexandra",
            "name": "[ALR] - Alexandra - New Zealand",
            "searchName": "ALR-Alexandra-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "AMZ",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Ardmore",
            "name": "[AMZ] - Ardmore - New Zealand",
            "searchName": "AMZ-Ardmore-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "XMG",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Mahendranagar",
            "name": "[XMG] - Mahendranagar - Nepal",
            "searchName": "XMG-Mahendranagar-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "SYH",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Syangboche",
            "name": "[SYH] - Syangboche - Nepal",
            "searchName": "SYH-Syangboche-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "TPU",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Tikapur",
            "name": "[TPU] - Tikapur - Nepal",
            "searchName": "TPU-Tikapur-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "TPJ",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Taplejung",
            "name": "[TPJ] - Taplejung - Nepal",
            "searchName": "TPJ-Taplejung-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "TMI",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Tumling Tar",
            "name": "[TMI] - Tumling Tar - Nepal",
            "searchName": "TMI-Tumling Tar-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "NGX",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Manang",
            "name": "[NGX] - Manang - Nepal",
            "searchName": "NGX-Manang-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "MWP",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Mountain",
            "name": "[MWP] - Mountain - Nepal",
            "searchName": "MWP-Mountain-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "SKH",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Surkhet",
            "name": "[SKH] - Surkhet - Nepal",
            "searchName": "SKH-Surkhet-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "SIF",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Simara",
            "name": "[SIF] - Simara - Nepal",
            "searchName": "SIF-Simara-Pipara Simara-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "SIH",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Silgadi Doti",
            "name": "[SIH] - Silgadi Doti - Nepal",
            "searchName": "SIH-Silgarhi Doti-Silgadi Doti-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "RUK",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Rukumkot",
            "name": "[RUK] - Rukumkot - Nepal",
            "searchName": "RUK-Rukumkot-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "RUM",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Rumjatar",
            "name": "[RUM] - Rumjatar - Nepal",
            "searchName": "RUM-Rumjatar-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "RPA",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Rolpa",
            "name": "[RPA] - Rolpa - Nepal",
            "searchName": "RPA-Rolpa-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "RJB",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Rajbiraj",
            "name": "[RJB] - Rajbiraj - Nepal",
            "searchName": "RJB-Rajbiraj-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "RHP",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Ramechhap",
            "name": "[RHP] - Ramechhap - Nepal",
            "searchName": "RHP-Ramechhap-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "PKR",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Pokhara",
            "name": "[PKR] - Pokhara - Nepal",
            "searchName": "PKR-Pokhara-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "ASG",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Ashburton",
            "name": "[ASG] - Ashburton - New Zealand",
            "searchName": "ASG-Ashburton-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "TMZ",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Thames",
            "name": "[TMZ] - Thames - New Zealand",
            "searchName": "TMZ-Thames-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "TKZ",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Tokoroa",
            "name": "[TKZ] - Tokoroa - New Zealand",
            "searchName": "TKZ-Tokoroa-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "TRG",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Tauranga",
            "name": "[TRG] - Tauranga - New Zealand",
            "searchName": "TRG-Tauranga-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "THH",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Taharoa",
            "name": "[THH] - Taharoa - New Zealand",
            "searchName": "THH-Taharoa-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "TIU",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Timaru",
            "name": "[TIU] - Timaru - New Zealand",
            "searchName": "TIU-Timaru-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "TEU",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Te Anau",
            "name": "[TEU] - Te Anau - New Zealand",
            "searchName": "TEU-Te Anau-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "SZS",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Stewart Island",
            "name": "[SZS] - Stewart Island - New Zealand",
            "searchName": "SZS-Stewart Island-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "WGN",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Waitangi",
            "name": "[WGN] - Waitangi - New Zealand",
            "searchName": "WGN-Waitangi-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "WAG",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Wanganui",
            "name": "[WAG] - Wanganui - New Zealand",
            "searchName": "WAG-Wanganui-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "TUO",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Taupo",
            "name": "[TUO] - Taupo - New Zealand",
            "searchName": "TUO-Taupo-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "WKA",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Wanaka",
            "name": "[WKA] - Wanaka - New Zealand",
            "searchName": "WKA-Wanaka-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "WHO",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Franz Josef",
            "name": "[WHO] - Franz Josef - New Zealand",
            "searchName": "WHO-Franz Josef-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "WHK",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Whakatane",
            "name": "[WHK] - Whakatane - New Zealand",
            "searchName": "WHK-Whakatane-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "WIK",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Surfdale",
            "name": "[WIK] - Surfdale - New Zealand",
            "searchName": "WIK-Surfdale-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "WIR",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Wairoa",
            "name": "[WIR] - Wairoa - New Zealand",
            "searchName": "WIR-Wairoa-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "WSZ",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Westport",
            "name": "[WSZ] - Westport - New Zealand",
            "searchName": "WSZ-Westport-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "WTZ",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Whitianga",
            "name": "[WTZ] - Whitianga - New Zealand",
            "searchName": "WTZ-Whitianga-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "WRE",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Whangarei",
            "name": "[WRE] - Whangarei - New Zealand",
            "searchName": "WRE-Whangarei-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "CZJ",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Corazon De Jesus",
            "name": "[CZJ] - Corazon De Jesus - Panama",
            "searchName": "CZJ-Corazon de Jesus-Corazon De Jesus-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "CTD",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Chitre",
            "name": "[CTD] - Chitre - Panama",
            "searchName": "CTD-Chitre-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "CTE",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Carti",
            "name": "[CTE] - Carti - Panama",
            "searchName": "CTE-Carti-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "BLB",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Balboa",
            "name": "[BLB] - Balboa - Panama",
            "searchName": "BLB-Balboa-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "BOC",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Bocas Del Toro",
            "name": "[BOC] - Bocas Del Toro - Panama",
            "searchName": "BOC-Bocas Del Toro-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "BFQ",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Bahia Pinas",
            "name": "[BFQ] - Bahia Pinas - Panama",
            "searchName": "BFQ-Bahia Pinas-Puerto Pina-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "AIL",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Ailigandi",
            "name": "[AIL] - Ailigandi - Panama",
            "searchName": "AIL-Ailigandi-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "AML",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Puerto Armuellas",
            "name": "[AML] - Puerto Armuellas - Panama",
            "searchName": "AML-Puerto Armuellas-Puerto Armuella-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "ACU",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Achutupo",
            "name": "[ACU] - Achutupo - Panama",
            "searchName": "ACU-Achutupo-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "SYP",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Santiago",
            "name": "[SYP] - Santiago - Panama",
            "searchName": "SYP-Santiago-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "TJC",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Ticantiki",
            "name": "[TJC] - Ticantiki - Panama",
            "searchName": "TJC-Ticantiki-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "TUE",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Tupile",
            "name": "[TUE] - Tupile - Panama",
            "searchName": "TUE-Tupile-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "TUW",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Tubala",
            "name": "[TUW] - Tubala - Panama",
            "searchName": "TUW-Tubala-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "UTU",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Ustupo",
            "name": "[UTU] - Ustupo - Panama",
            "searchName": "UTU-Ustupo-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "SIC",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Sinop",
            "name": "[SIC] - Sinop - Panama",
            "searchName": "SIC-Sinop-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "SFW",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Santa Fe",
            "name": "[SFW] - Santa Fe - Panama",
            "searchName": "SFW-Santa Fe-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "RIZ",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Rio Alzucar",
            "name": "[RIZ] - Rio Alzucar - Panama",
            "searchName": "RIZ-Rio Alzucar-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "RIT",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Rio Tigre",
            "name": "[RIT] - Rio Tigre - Panama",
            "searchName": "RIT-Rio Tigre-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "RSI",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Rio Sidra",
            "name": "[RSI] - Rio Sidra - Panama",
            "searchName": "RSI-Rio Sidra-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "SAX",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Sambu",
            "name": "[SAX] - Sambu - Panama",
            "searchName": "SAX-Sambu-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "PYV",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Yaviza",
            "name": "[PYV] - Yaviza - Panama",
            "searchName": "PYV-Yaviza-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "PVE",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "El Porvenir",
            "name": "[PVE] - El Porvenir - Panama",
            "searchName": "PVE-El Porvenir-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "PYC",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Playon Chico",
            "name": "[PYC] - Playon Chico - Panama",
            "searchName": "PYC-Playon Chico-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "PLP",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "La Palma",
            "name": "[PLP] - La Palma - Panama",
            "searchName": "PLP-La Palma-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "PPQ",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Paraparaumu",
            "name": "[PPQ] - Paraparaumu - New Zealand",
            "searchName": "PPQ-Paraparaumu-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "PKL",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Pakatoa Island",
            "name": "[PKL] - Pakatoa Island - New Zealand",
            "searchName": "PKL-Pakatoa Island-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "PMR",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Palmerston North",
            "name": "[PMR] - Palmerston North - New Zealand",
            "searchName": "PMR-Palmerston North-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "RAG",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Raglan",
            "name": "[RAG] - Raglan - New Zealand",
            "searchName": "RAG-Raglan-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "ROT",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Rotorua",
            "name": "[ROT] - Rotorua - New Zealand",
            "searchName": "ROT-Rotorua-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "MZP",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Motueka",
            "name": "[MZP] - Motueka - New Zealand",
            "searchName": "MZP-Motueka-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "MON",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Mount Cook",
            "name": "[MON] - Mount Cook - New Zealand",
            "searchName": "MON-Mount Cook-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "MRO",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Masterton",
            "name": "[MRO] - Masterton - New Zealand",
            "searchName": "MRO-Masterton-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "MTA",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Matamata",
            "name": "[MTA] - Matamata - New Zealand",
            "searchName": "MTA-Matamata-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "PCN",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Picton",
            "name": "[PCN] - Picton - New Zealand",
            "searchName": "PCN-Picton-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "OHA",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Ohakea",
            "name": "[OHA] - Ohakea - New Zealand",
            "searchName": "OHA-Ohakea-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "NSN",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Nelson",
            "name": "[NSN] - Nelson - New Zealand",
            "searchName": "NSN-Nelson-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "NPL",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "New Plymouth",
            "name": "[NPL] - New Plymouth - New Zealand",
            "searchName": "NPL-New Plymouth-Nueva Plymouth-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "NPE",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Napier",
            "name": "[NPE] - Napier - New Zealand",
            "searchName": "NPE-Napier-Hastings-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "OAM",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Oamaru",
            "name": "[OAM] - Oamaru - New Zealand",
            "searchName": "OAM-Oamaru-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "OMM",
            "countryCode": "OM",
            "cityCode": null,
            "cityName": "Marmul",
            "name": "[OMM] - Marmul - Oman",
            "searchName": "OMM-Marmul-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "MSH",
            "countryCode": "OM",
            "cityCode": null,
            "cityName": "Masirah",
            "name": "[MSH] - Masirah - Oman",
            "searchName": "MSH-Masirah-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "RNM",
            "countryCode": "OM",
            "cityCode": null,
            "cityName": "Qarn Alam",
            "name": "[RNM] - Qarn Alam - Oman",
            "searchName": "RNM-Qarn Alam-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "RMB",
            "countryCode": "OM",
            "cityCode": null,
            "cityName": "Buraimi",
            "name": "[RMB] - Buraimi - Oman",
            "searchName": "RMB-Buraimi-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "SLL",
            "countryCode": "OM",
            "cityCode": null,
            "cityName": "Salalah",
            "name": "[SLL] - Salalah - Oman",
            "searchName": "SLL-Salala-Salalah-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "TTH",
            "countryCode": "OM",
            "cityCode": null,
            "cityName": "Thumrait",
            "name": "[TTH] - Thumrait - Oman",
            "searchName": "TTH-Thumrait-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "UKH",
            "countryCode": "OM",
            "cityCode": null,
            "cityName": "Mukhaizna",
            "name": "[UKH] - Mukhaizna - Oman",
            "searchName": "UKH-Mukhaizna-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "SUH",
            "countryCode": "OM",
            "cityCode": null,
            "cityName": "Sur",
            "name": "[SUH] - Sur - Oman",
            "searchName": "SUH-Sur-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "AOM",
            "countryCode": "OM",
            "cityCode": null,
            "cityName": "Adam",
            "name": "[AOM] - Adam - Oman",
            "searchName": "AOM-Adam-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "AHW",
            "countryCode": "OM",
            "cityCode": null,
            "cityName": "Saih Rawl",
            "name": "[AHW] - Saih Rawl - Oman",
            "searchName": "AHW-Saih Rawl-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "BJQ",
            "countryCode": "OM",
            "cityCode": null,
            "cityName": "Bahja",
            "name": "[BJQ] - Bahja - Oman",
            "searchName": "BJQ-Bahja-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "BYB",
            "countryCode": "OM",
            "cityCode": null,
            "cityName": "Dibaa",
            "name": "[BYB] - Dibaa - Oman",
            "searchName": "BYB-Dibaa-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "FAU",
            "countryCode": "OM",
            "cityCode": null,
            "cityName": "Fahud",
            "name": "[FAU] - Fahud - Oman",
            "searchName": "FAU-Fahud-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "KHS",
            "countryCode": "OM",
            "cityCode": null,
            "cityName": "Khasab",
            "name": "[KHS] - Khasab - Oman",
            "searchName": "KHS-Khasab-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "PUE",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Puerto Obaldia",
            "name": "[PUE] - Puerto Obaldia - Panama",
            "searchName": "PUE-Puerto Obaldia-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "MPP",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Mulatupo",
            "name": "[MPP] - Mulatupo - Panama",
            "searchName": "MPP-Mulatupo-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "MPI",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Mamitupo",
            "name": "[MPI] - Mamitupo - Panama",
            "searchName": "MPI-Mamitupo-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "NBL",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "San Blas",
            "name": "[NBL] - San Blas - Panama",
            "searchName": "NBL-San Blas-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "NGN",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Nargana",
            "name": "[NGN] - Nargana - Panama",
            "searchName": "NGN-Nargana-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "NMG",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "San Miguel",
            "name": "[NMG] - San Miguel - Panama",
            "searchName": "NMG-San Miguel-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "ONX",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Colon",
            "name": "[ONX] - Colon - Panama",
            "searchName": "ONX-Colon-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "OTD",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Contadora",
            "name": "[OTD] - Contadora - Panama",
            "searchName": "OTD-Contadora-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "OGM",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Ustupu Island",
            "name": "[OGM] - Ustupu Island - Panama",
            "searchName": "OGM-Ustupu Island-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "MFZ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Mesalia",
            "name": "[MFZ] - Mesalia - Papua New Guinea",
            "searchName": "MFZ-Meselia-Mesalia-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MGG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Margarima",
            "name": "[MGG] - Margarima - Papua New Guinea",
            "searchName": "MGG-Margarima-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MFO",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Manguna",
            "name": "[MFO] - Manguna - Papua New Guinea",
            "searchName": "MFO-Manguna-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MDU",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Mendi",
            "name": "[MDU] - Mendi - Papua New Guinea",
            "searchName": "MDU-Mendi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MJJ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Moki",
            "name": "[MJJ] - Moki - Papua New Guinea",
            "searchName": "MJJ-Moki-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MIS",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Misima Island",
            "name": "[MIS] - Misima Island - Papua New Guinea",
            "searchName": "MIS-Misima Island--Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MGP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Manga",
            "name": "[MGP] - Manga - Papua New Guinea",
            "searchName": "MGP-Manga-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MHY",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Morehead",
            "name": "[MHY] - Morehead - Papua New Guinea",
            "searchName": "MHY-Morehead-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LHP",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Lehu",
            "name": "[LHP] - Lehu - Papua New Guinea",
            "searchName": "LHP-Lehu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LGM",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Laiagam",
            "name": "[LGM] - Laiagam - Papua New Guinea",
            "searchName": "LGM-Laiagam-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LGN",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Linga Linga",
            "name": "[LGN] - Linga Linga - Papua New Guinea",
            "searchName": "LGN-Linga Linga-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LMY",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Lake Murray",
            "name": "[LMY] - Lake Murray - Papua New Guinea",
            "searchName": "LMY-Lake Murray-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LNC",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Lengbati",
            "name": "[LNC] - Lengbati - Papua New Guinea",
            "searchName": "LNC-Lengbati-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LNF",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Munbil",
            "name": "[LNF] - Munbil - Papua New Guinea",
            "searchName": "LNF-Munbil-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LNG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Lese",
            "name": "[LNG] - Lese - Papua New Guinea",
            "searchName": "LNG-Lese-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LMI",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Lumi",
            "name": "[LMI] - Lumi - Papua New Guinea",
            "searchName": "LMI-Lumi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LMG",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Lamassa",
            "name": "[LMG] - Lamassa - Papua New Guinea",
            "searchName": "LMG-Lamassa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LTF",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Leitre",
            "name": "[LTF] - Leitre - Papua New Guinea",
            "searchName": "LTF-Leitre-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LSJ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Long Island",
            "name": "[LSJ] - Long Island - Papua New Guinea",
            "searchName": "LSJ-Long Island-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LSA",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Losuia",
            "name": "[LSA] - Losuia - Papua New Guinea",
            "searchName": "LSA-Losuia-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LNM",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Langimar",
            "name": "[LNM] - Langimar - Papua New Guinea",
            "searchName": "LNM-Langimar-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LNQ",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Loani",
            "name": "[LNQ] - Loani - Papua New Guinea",
            "searchName": "LNQ-Loani-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LNV",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Lihir Island",
            "name": "[LNV] - Lihir Island - Papua New Guinea",
            "searchName": "LNV-Lihir Island--Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LPN",
            "countryCode": "PG",
            "cityCode": null,
            "cityName": "Leron Plains",
            "name": "[LPN] - Leron Plains - Papua New Guinea",
            "searchName": "LPN-Leron Plains-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LGP",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Legazpi",
            "name": "[LGP] - Legazpi - Philippines",
            "searchName": "LGP-Legazpi City-Legazpi-Legaspi-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "LWA",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Lwbak",
            "name": "[LWA] - Lwbak - Philippines",
            "searchName": "LWA-Lwbak-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "MBT",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Masbate",
            "name": "[MBT] - Masbate - Philippines",
            "searchName": "MBT-Masbate-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "MBO",
            "countryCode": "PH",
            "cityCode": null,
            "cityName": "Mamburao",
            "name": "[MBO] - Mamburao - Philippines",
            "searchName": "MBO-Mamburao-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "LKW",
            "countryCode": "OM",
            "cityCode": null,
            "cityName": "Lekhwair",
            "name": "[LKW] - Lekhwair - Oman",
            "searchName": "LKW-Lekhawair-Lekhwair-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "JNJ",
            "countryCode": "OM",
            "cityCode": null,
            "cityName": "Duqm",
            "name": "[JNJ] - Duqm - Oman",
            "searchName": "JNJ-Duqm-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "JQE",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Jaque",
            "name": "[JQE] - Jaque - Panama",
            "searchName": "JQE-Jaque-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "IVI",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Isla Viveros",
            "name": "[IVI] - Isla Viveros - Panama",
            "searchName": "IVI-Isla Viveros-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "HOW",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Fort Kobbe",
            "name": "[HOW] - Fort Kobbe - Panama",
            "searchName": "HOW-Fort Kobbe-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "GHE",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Garachine",
            "name": "[GHE] - Garachine - Panama",
            "searchName": "GHE-Garachine-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "ELE",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "El Real",
            "name": "[ELE] - El Real - Panama",
            "searchName": "ELE-El Real-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "CHX",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Changuinola",
            "name": "[CHX] - Changuinola - Panama",
            "searchName": "CHX-Changuinola-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "CDE",
            "countryCode": "PA",
            "cityCode": null,
            "cityName": "Caledonia",
            "name": "[CDE] - Caledonia - Panama",
            "searchName": "CDE-Caledonia-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "LDN",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Lamidanda",
            "name": "[LDN] - Lamidanda - Nepal",
            "searchName": "LDN-Lamidanda-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "KEP",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Nepalganj",
            "name": "[KEP] - Nepalganj - Nepal",
            "searchName": "KEP-Nepalganj-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "MEY",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Meghauli",
            "name": "[MEY] - Meghauli - Nepal",
            "searchName": "MEY-Meghauli-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "LUA",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Lukla",
            "name": "[LUA] - Lukla - Nepal",
            "searchName": "LUA-Lukla-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "LTG",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Langtang",
            "name": "[LTG] - Langtang - Nepal",
            "searchName": "LTG-Langtang-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "HRJ",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Chaurjhari",
            "name": "[HRJ] - Chaurjhari - Nepal",
            "searchName": "HRJ-Chaurjhari-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "IMK",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Simikot",
            "name": "[IMK] - Simikot - Nepal",
            "searchName": "IMK-Simikot-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "JMO",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Jomsom",
            "name": "[JMO] - Jomsom - Nepal",
            "searchName": "JMO-Jomsom-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "JKR",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Janakpur",
            "name": "[JKR] - Janakpur - Nepal",
            "searchName": "JKR-Janakpur-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "JIR",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Jiri",
            "name": "[JIR] - Jiri - Nepal",
            "searchName": "JIR-Jiri-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "JUM",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Jumla",
            "name": "[JUM] - Jumla - Nepal",
            "searchName": "JUM-Jumla-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "GKH",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Gorkha",
            "name": "[GKH] - Gorkha - Nepal",
            "searchName": "GKH-Gorkha-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "BWA",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Bhairawa",
            "name": "[BWA] - Bhairawa - Nepal",
            "searchName": "BWA-Bhairawa-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "BJU",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Bajura",
            "name": "[BJU] - Bajura - Nepal",
            "searchName": "BJU-Bajura-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "BHP",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Bhojpur",
            "name": "[BHP] - Bhojpur - Nepal",
            "searchName": "BHP-Bhojpur-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "BHR",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Bharatpur",
            "name": "[BHR] - Bharatpur - Nepal",
            "searchName": "BHR-Bharatpur-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "BIR",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Biratnagar",
            "name": "[BIR] - Biratnagar - Nepal",
            "searchName": "BIR-Biratnagar-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "BIT",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Baitadi",
            "name": "[BIT] - Baitadi - Nepal",
            "searchName": "BIT-Baitadi-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "BJH",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Bajhang",
            "name": "[BJH] - Bajhang - Nepal",
            "searchName": "BJH-Bajhang-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "BDP",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Bhadrapur",
            "name": "[BDP] - Bhadrapur - Nepal",
            "searchName": "BDP-Bhadrapur-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "BGL",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Baglung",
            "name": "[BGL] - Baglung - Nepal",
            "searchName": "BGL-Baglung-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "FEB",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Sanfebagar",
            "name": "[FEB] - Sanfebagar - Nepal",
            "searchName": "FEB-Sanfebagar-Sanfebagar Airport-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "DNP",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Dang",
            "name": "[DNP] - Dang - Nepal",
            "searchName": "DNP-Dang-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "DOP",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Dolpa",
            "name": "[DOP] - Dolpa - Nepal",
            "searchName": "DOP-Dolpa-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "DHI",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Dhangarhi",
            "name": "[DHI] - Dhangarhi - Nepal",
            "searchName": "DHI-Dhangarhi-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "DAP",
            "countryCode": "NP",
            "cityCode": null,
            "cityName": "Gokuleshwar",
            "name": "[DAP] - Gokuleshwar - Nepal",
            "searchName": "DAP-Darchula-Gokuleshwar-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "INU",
            "countryCode": "NR",
            "cityCode": null,
            "cityName": "Nauru Island",
            "name": "[INU] - Nauru Island - Nauru",
            "searchName": "INU-Nauru Island--Nauru",
            "priority": 0
        },
        {
            "iataCode": "IUE",
            "countryCode": "NU",
            "cityCode": null,
            "cityName": "Niue Island",
            "name": "[IUE] - Niue Island - Niue",
            "searchName": "IUE-Niue Island--Niue-Isla Niue",
            "priority": 0
        },
        {
            "iataCode": "IVC",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Invercargill",
            "name": "[IVC] - Invercargill - New Zealand",
            "searchName": "IVC-Invercargill-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "KAT",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Kaitaia",
            "name": "[KAT] - Kaitaia - New Zealand",
            "searchName": "KAT-Kaitaia-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "GIS",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Gisborne",
            "name": "[GIS] - Gisborne - New Zealand",
            "searchName": "GIS-Gisborne-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "GMN",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Greymouth",
            "name": "[GMN] - Greymouth - New Zealand",
            "searchName": "GMN-Greymouth-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "GBS",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Port Fitzroy",
            "name": "[GBS] - Port Fitzroy - New Zealand",
            "searchName": "GBS-Port Fitzroy-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "GBZ",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Great Barrier Island",
            "name": "[GBZ] - Great Barrier Island - New Zealand",
            "searchName": "GBZ-Great Barrier Island-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "HLZ",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Hamilton",
            "name": "[HLZ] - Hamilton - New Zealand",
            "searchName": "HLZ-Hamilton-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "HKK",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Hokitika",
            "name": "[HKK] - Hokitika - New Zealand",
            "searchName": "HKK-Hokitika-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "MFN",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Milford Sound",
            "name": "[MFN] - Milford Sound - New Zealand",
            "searchName": "MFN-Milford Sound-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "KBZ",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Kaikoura",
            "name": "[KBZ] - Kaikoura - New Zealand",
            "searchName": "KBZ-Kaikoura-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "KKO",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Kaikohe",
            "name": "[KKO] - Kaikohe - New Zealand",
            "searchName": "KKO-Kaikohe-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "KKE",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Kerikeri",
            "name": "[KKE] - Kerikeri - New Zealand",
            "searchName": "KKE-Kerikeri-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "KWU",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Mansion House",
            "name": "[KWU] - Mansion House - New Zealand",
            "searchName": "KWU-Mansion House-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "KTF",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Takaka",
            "name": "[KTF] - Takaka - New Zealand",
            "searchName": "KTF-Takaka-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "KUI",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Kawau Island",
            "name": "[KUI] - Kawau Island - New Zealand",
            "searchName": "KUI-Kawau Island-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "DGR",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Dargaville",
            "name": "[DGR] - Dargaville - New Zealand",
            "searchName": "DGR-Dargaville-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "CMV",
            "countryCode": "NZ",
            "cityCode": null,
            "cityName": "Coromandel",
            "name": "[CMV] - Coromandel - New Zealand",
            "searchName": "CMV-Coromandel-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "QYZ",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Heerenveen",
            "name": "[QYZ] - Heerenveen - Netherlands",
            "searchName": "QYZ-Heerenveen-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "QWZ",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Best",
            "name": "[QWZ] - Best - Netherlands",
            "searchName": "QWZ-Best-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "QYT",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Paterswolde",
            "name": "[QYT] - Paterswolde - Netherlands",
            "searchName": "QYT-Paterswolde-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "QYV",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Deventer",
            "name": "[QYV] - Deventer - Netherlands",
            "searchName": "QYV-Deventer-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "QYC",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Drachten",
            "name": "[QYC] - Drachten - Netherlands",
            "searchName": "QYC-Drachten-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "QYH",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Hengelo",
            "name": "[QYH] - Hengelo - Netherlands",
            "searchName": "QYH-Hengelo-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "QYI",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Hilversum",
            "name": "[QYI] - Hilversum - Netherlands",
            "searchName": "QYI-Hilversum-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "QYL",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Almelo",
            "name": "[QYL] - Almelo - Netherlands",
            "searchName": "QYL-Almelo-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "QYM",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Amersfoort",
            "name": "[QYM] - Amersfoort - Netherlands",
            "searchName": "QYM-Amersfoort-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "QYP",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Apeldoorn",
            "name": "[QYP] - Apeldoorn - Netherlands",
            "searchName": "QYP-Apeldoorn-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "WOE",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Woensdrecht",
            "name": "[WOE] - Woensdrecht - Netherlands",
            "searchName": "WOE-Woensdrecht-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "UTC",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Utrecht",
            "name": "[UTC] - Utrecht - Netherlands",
            "searchName": "UTC-Utrecht-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "UDE",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Uden",
            "name": "[UDE] - Uden - Netherlands",
            "searchName": "UDE-Uden-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "LWR",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Leeuwarden",
            "name": "[LWR] - Leeuwarden - Netherlands",
            "searchName": "LWR-Leeuwarden-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "LEY",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Lelystad",
            "name": "[LEY] - Lelystad - Netherlands",
            "searchName": "LEY-Lelystad-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "GLZ",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Breda",
            "name": "[GLZ] - Breda - Netherlands",
            "searchName": "GLZ-Breda-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "HAG",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "The Hague",
            "name": "[HAG] - The Hague - Netherlands",
            "searchName": "HAG-The Hague-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "GRQ",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Groningen",
            "name": "[GRQ] - Groningen - Netherlands",
            "searchName": "GRQ-Groningen-Groninga-Groningue-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "EIN",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Eindhoven",
            "name": "[EIN] - Eindhoven - Netherlands",
            "searchName": "EIN-Eindhoven-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "ENS",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Enschede",
            "name": "[ENS] - Enschede - Netherlands",
            "searchName": "ENS-Enschede-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "DHR",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Den Helder",
            "name": "[DHR] - Den Helder - Netherlands",
            "searchName": "DHR-Den Helder-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "ZYM",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Arnhem",
            "name": "[ZYM] - Arnhem - Netherlands",
            "searchName": "ZYM-Arnhem-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "ZYO",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Roosendaal",
            "name": "[ZYO] - Roosendaal - Netherlands",
            "searchName": "ZYO-Roosendaal-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "ZYS",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Sandefjord",
            "name": "[ZYS] - Sandefjord - Norway",
            "searchName": "ZYS-Sandefjord-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZYV",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Vegarshei",
            "name": "[ZYV] - Vegarshei - Norway",
            "searchName": "ZYV-Vegarshei-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZYW",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Sandvika",
            "name": "[ZYW] - Sandvika - Norway",
            "searchName": "ZYW-Sandvika-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZYY",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Marnardal",
            "name": "[ZYY] - Marnardal - Norway",
            "searchName": "ZYY-Marnardal-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "EVE",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Harstad-narvik",
            "name": "[EVE] - Harstad-narvik - Norway",
            "searchName": "EVE-Harstad-Narvik-narvik-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "FDE",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Forde",
            "name": "[FDE] - Forde - Norway",
            "searchName": "FDE-Forde-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "FRO",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Floro",
            "name": "[FRO] - Floro - Norway",
            "searchName": "FRO-Floro-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ANX",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Andenes",
            "name": "[ANX] - Andenes - Norway",
            "searchName": "ANX-Andenes-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ALF",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Alta",
            "name": "[ALF] - Alta - Norway",
            "searchName": "ALF-Alta-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "AES",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Aalesund",
            "name": "[AES] - Aalesund - Norway",
            "searchName": "AES-Aalesund-Alesund-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "BDU",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Bardufoss",
            "name": "[BDU] - Bardufoss - Norway",
            "searchName": "BDU-Bardufoss-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "BGO",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Bergen",
            "name": "[BGO] - Bergen - Norway",
            "searchName": "BGO-Bergen-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "BNN",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Bronnoysund",
            "name": "[BNN] - Bronnoysund - Norway",
            "searchName": "BNN-Bronnoysund-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "BOO",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Bodo",
            "name": "[BOO] - Bodo - Norway",
            "searchName": "BOO-Bod-Bodo-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "BJF",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Batsfjord",
            "name": "[BJF] - Batsfjord - Norway",
            "searchName": "BJF-Berlev-Batsfjord-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "BVG",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Berlevag",
            "name": "[BVG] - Berlevag - Norway",
            "searchName": "BVG-Berlev-Berlevag-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "HAA",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Hasvik",
            "name": "[HAA] - Hasvik - Norway",
            "searchName": "HAA-Hasvik-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "HAU",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Haugesund",
            "name": "[HAU] - Haugesund - Norway",
            "searchName": "HAU-Haugesund-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "HMR",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Hamar",
            "name": "[HMR] - Hamar - Norway",
            "searchName": "HMR-Hamar-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "HFT",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Hammerfest",
            "name": "[HFT] - Hammerfest - Norway",
            "searchName": "HFT-Hammerfest-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "GLL",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Gol",
            "name": "[GLL] - Gol - Norway",
            "searchName": "GLL-Gol-Gol City-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "HVG",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Honningsvag",
            "name": "[HVG] - Honningsvag - Norway",
            "searchName": "HVG-Honningsv-Honningsvag-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "HOV",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Orsta-Volda",
            "name": "[HOV] - Orsta-Volda - Norway",
            "searchName": "HOV-Orsta-Volda-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "LKL",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Lakselv",
            "name": "[LKL] - Lakselv - Norway",
            "searchName": "LKL-Lakselv-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "LKN",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Leknes",
            "name": "[LKN] - Leknes - Norway",
            "searchName": "LKN-Leknes-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "MEH",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Mehamn",
            "name": "[MEH] - Mehamn - Norway",
            "searchName": "MEH-Mehamn-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "KKN",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Kirkenes",
            "name": "[KKN] - Kirkenes - Norway",
            "searchName": "KKN-Kirkenes-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "KRS",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Kristiansand",
            "name": "[KRS] - Kristiansand - Norway",
            "searchName": "KRS-Kristiansand-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "VAW",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Vardoe",
            "name": "[VAW] - Vardoe - Norway",
            "searchName": "VAW-Vardoe-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "VDB",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Fagernes",
            "name": "[VDB] - Fagernes - Norway",
            "searchName": "VDB-Fagernes-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "VDS",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Vadso",
            "name": "[VDS] - Vadso - Norway",
            "searchName": "VDS-Vadso-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "VRY",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Vaeroy",
            "name": "[VRY] - Vaeroy - Norway",
            "searchName": "VRY-Vaeroy-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "TOS",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Tromso",
            "name": "[TOS] - Tromso - Norway",
            "searchName": "TOS-Troms-Tromso-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "TRD",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Trondheim",
            "name": "[TRD] - Trondheim - Norway",
            "searchName": "TRD-Trondheim-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "SVJ",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Svolvaer",
            "name": "[SVJ] - Svolvaer - Norway",
            "searchName": "SVJ-Svolvaer-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "SVG",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Stavanger",
            "name": "[SVG] - Stavanger - Norway",
            "searchName": "SVG-Stavanger-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "SRP",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Stord",
            "name": "[SRP] - Stord - Norway",
            "searchName": "SRP-Stord-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "SSJ",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Sandnessjoen",
            "name": "[SSJ] - Sandnessjoen - Norway",
            "searchName": "SSJ-Sandnessjoen-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKI",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Lillestrom",
            "name": "[XKI] - Lillestrom - Norway",
            "searchName": "XKI-Lillestrom-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKJ",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Steinkjer",
            "name": "[XKJ] - Steinkjer - Norway",
            "searchName": "XKJ-Steinkjer-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKK",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Larvik",
            "name": "[XKK] - Larvik - Norway",
            "searchName": "XKK-Larvik-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKM",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Moss",
            "name": "[XKM] - Moss - Norway",
            "searchName": "XKM-Moss-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKN",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Finse",
            "name": "[XKN] - Finse - Norway",
            "searchName": "XKN-Finse-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKW",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Tonsberg",
            "name": "[XKW] - Tonsberg - Norway",
            "searchName": "XKW-Tonsberg-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKP",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Porsgrunn",
            "name": "[XKP] - Porsgrunn - Norway",
            "searchName": "XKP-Porsgrunn-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKQ",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Sarpsborg",
            "name": "[XKQ] - Sarpsborg - Norway",
            "searchName": "XKQ-Sarpsborg-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKB",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Kongsberg",
            "name": "[XKB] - Kongsberg - Norway",
            "searchName": "XKB-Kongsberg-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKC",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Sandnes",
            "name": "[XKC] - Sandnes - Norway",
            "searchName": "XKC-Sandnes-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKD",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Halden",
            "name": "[XKD] - Halden - Norway",
            "searchName": "XKD-Halden-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKE",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Rena",
            "name": "[XKE] - Rena - Norway",
            "searchName": "XKE-Rena-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKF",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Fredrikstad",
            "name": "[XKF] - Fredrikstad - Norway",
            "searchName": "XKF-Fredrikstad-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKG",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Grong",
            "name": "[XKG] - Grong - Norway",
            "searchName": "XKG-Grong-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XJA",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Alvdal",
            "name": "[XJA] - Alvdal - Norway",
            "searchName": "XJA-Alvdal-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XHT",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Arna",
            "name": "[XHT] - Arna - Norway",
            "searchName": "XHT-Arna-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XHF",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Honefoss",
            "name": "[XHF] - Honefoss - Norway",
            "searchName": "XHF-Honefoss-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XHL",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Nelaug",
            "name": "[XHL] - Nelaug - Norway",
            "searchName": "XHL-Nelaug-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XGD",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Arendal",
            "name": "[XGD] - Arendal - Norway",
            "searchName": "XGD-Arendal-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XGS",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Gjerstad",
            "name": "[XGS] - Gjerstad - Norway",
            "searchName": "XGS-Gjerstad-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XGU",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Asker",
            "name": "[XGU] - Asker - Norway",
            "searchName": "XGU-Asker-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XGH",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Flam",
            "name": "[XGH] - Flam - Norway",
            "searchName": "XGH-Flam-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XGI",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Andalsnes",
            "name": "[XGI] - Andalsnes - Norway",
            "searchName": "XGI-Andalsnes-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XGP",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Dombas",
            "name": "[XGP] - Dombas - Norway",
            "searchName": "XGP-Dombas-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XOQ",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Sira",
            "name": "[XOQ] - Sira - Norway",
            "searchName": "XOQ-Sira-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XOR",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Otta",
            "name": "[XOR] - Otta - Norway",
            "searchName": "XOR-Otta-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XRD",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Egersund",
            "name": "[XRD] - Egersund - Norway",
            "searchName": "XRD-Egersund-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XOB",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Bryne",
            "name": "[XOB] - Bryne - Norway",
            "searchName": "XOB-Bryne-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XOD",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Oppdal",
            "name": "[XOD] - Oppdal - Norway",
            "searchName": "XOD-Oppdal-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XOL",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Myrdal",
            "name": "[XOL] - Myrdal - Norway",
            "searchName": "XOL-Myrdal-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XND",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Drammen",
            "name": "[XND] - Drammen - Norway",
            "searchName": "XND-Drammen-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKZ",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Vinstra",
            "name": "[XKZ] - Vinstra - Norway",
            "searchName": "XKZ-Vinstra-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XVK",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Voss",
            "name": "[XVK] - Voss - Norway",
            "searchName": "XVK-Voss-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUG",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Holmestrand",
            "name": "[XUG] - Holmestrand - Norway",
            "searchName": "XUG-Holmestrand-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUH",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Levanger",
            "name": "[XUH] - Levanger - Norway",
            "searchName": "XUH-Levanger-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUI",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Lysaker",
            "name": "[XUI] - Lysaker - Norway",
            "searchName": "XUI-Lysaker-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUJ",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Moelv",
            "name": "[XUJ] - Moelv - Norway",
            "searchName": "XUJ-Moelv-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUL",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Nesbyen",
            "name": "[XUL] - Nesbyen - Norway",
            "searchName": "XUL-Nesbyen-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUM",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Neslandsvatn",
            "name": "[XUM] - Neslandsvatn - Norway",
            "searchName": "XUM-Neslandsvatn-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUO",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Nordagutu",
            "name": "[XUO] - Nordagutu - Norway",
            "searchName": "XUO-Nordagutu-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUQ",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Ringebu",
            "name": "[XUQ] - Ringebu - Norway",
            "searchName": "XUQ-Ringebu-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUR",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Skoppum",
            "name": "[XUR] - Skoppum - Norway",
            "searchName": "XUR-Skoppum-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUS",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Snartemo",
            "name": "[XUS] - Snartemo - Norway",
            "searchName": "XUS-Snartemo-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUU",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Stjordal",
            "name": "[XUU] - Stjordal - Norway",
            "searchName": "XUU-Stjordal-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUV",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Storekvina",
            "name": "[XUV] - Storekvina - Norway",
            "searchName": "XUV-Storekvina-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUW",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Storen",
            "name": "[XUW] - Storen - Norway",
            "searchName": "XUW-Storen-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUX",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Ustaoset",
            "name": "[XUX] - Ustaoset - Norway",
            "searchName": "XUX-Ustaoset-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUB",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Berkak",
            "name": "[XUB] - Berkak - Norway",
            "searchName": "XUB-Berkak-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUC",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Elverum",
            "name": "[XUC] - Elverum - Norway",
            "searchName": "XUC-Elverum-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUE",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Heimdal",
            "name": "[XUE] - Heimdal - Norway",
            "searchName": "XUE-Heimdal-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XXC",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Bo",
            "name": "[XXC] - Bo - Norway",
            "searchName": "XXC-Bo-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XXE",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Vennesla",
            "name": "[XXE] - Vennesla - Norway",
            "searchName": "XXE-Vennesla-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XXG",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Verdal",
            "name": "[XXG] - Verdal - Norway",
            "searchName": "XXG-Verdal-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XXL",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Lillehammer",
            "name": "[XXL] - Lillehammer - Norway",
            "searchName": "XXL-Lillehammer-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XXR",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Al",
            "name": "[XXR] - Al - Norway",
            "searchName": "XXR-Al-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XZD",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Kongsvinger",
            "name": "[XZD] - Kongsvinger - Norway",
            "searchName": "XZD-Kongsvinger-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "YVS",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Ski",
            "name": "[YVS] - Ski - Norway",
            "searchName": "YVS-Ski-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "YVH",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Hjerkinn",
            "name": "[YVH] - Hjerkinn - Norway",
            "searchName": "YVH-Hjerkinn-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "YVK",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Koppang",
            "name": "[YVK] - Koppang - Norway",
            "searchName": "YVK-Koppang-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZMQ",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Raufoss",
            "name": "[ZMQ] - Raufoss - Norway",
            "searchName": "ZMQ-Raufoss-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZMX",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Tynset",
            "name": "[ZMX] - Tynset - Norway",
            "searchName": "ZMX-Tynset-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZMZ",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Bjerka",
            "name": "[ZMZ] - Bjerka - Norway",
            "searchName": "ZMZ-Bjerka-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZWJ",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Haugastol",
            "name": "[ZWJ] - Haugastol - Norway",
            "searchName": "ZWJ-Haugastol-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZXM",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Rognan",
            "name": "[ZXM] - Rognan - Norway",
            "searchName": "ZXM-Rognan-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZXO",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Fauske",
            "name": "[ZXO] - Fauske - Norway",
            "searchName": "ZXO-Fauske-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZXU",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Rygge",
            "name": "[ZXU] - Rygge - Norway",
            "searchName": "ZXU-Rygge-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZXX",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Rade",
            "name": "[ZXX] - Rade - Norway",
            "searchName": "ZXX-Rade-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZYG",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Gjovik",
            "name": "[ZYG] - Gjovik - Norway",
            "searchName": "ZYG-Gjovik-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZVB",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Brumunddal",
            "name": "[ZVB] - Brumunddal - Norway",
            "searchName": "ZVB-Brumunddal-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZVD",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Drangedal",
            "name": "[ZVD] - Drangedal - Norway",
            "searchName": "ZVD-Drangedal-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "QFK",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Selje",
            "name": "[QFK] - Selje - Norway",
            "searchName": "QFK-Selje-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "QFQ",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Maloy",
            "name": "[QFQ] - Maloy - Norway",
            "searchName": "QFQ-Maloy-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "RET",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Rost",
            "name": "[RET] - Rost - Norway",
            "searchName": "RET-Rost-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "RRS",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Roros",
            "name": "[RRS] - Roros - Norway",
            "searchName": "RRS-Roros-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "SDN",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Sandane",
            "name": "[SDN] - Sandane - Norway",
            "searchName": "SDN-Sandane-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "RVK",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Roervik",
            "name": "[RVK] - Roervik - Norway",
            "searchName": "RVK-Roervik-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "SKE",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Skien",
            "name": "[SKE] - Skien - Norway",
            "searchName": "SKE-Skien-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "SKN",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Stokmarknes",
            "name": "[SKN] - Stokmarknes - Norway",
            "searchName": "SKN-Stokmarknes-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "SOG",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Sogndal",
            "name": "[SOG] - Sogndal - Norway",
            "searchName": "SOG-Sogndal-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "SOJ",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Sorkjosen",
            "name": "[SOJ] - Sorkjosen - Norway",
            "searchName": "SOJ-Sorkjosen-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "MQN",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Mo I Rana",
            "name": "[MQN] - Mo I Rana - Norway",
            "searchName": "MQN-Mo i Rana-Mo I Rana-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "MOL",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Molde",
            "name": "[MOL] - Molde - Norway",
            "searchName": "MOL-Molde-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "NVK",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Narvik",
            "name": "[NVK] - Narvik - Norway",
            "searchName": "NVK-Narvik-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "NTB",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Notodden",
            "name": "[NTB] - Notodden - Norway",
            "searchName": "NTB-Notodden-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "OLA",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Orland",
            "name": "[OLA] - Orland - Norway",
            "searchName": "OLA-Orland-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "OSY",
            "countryCode": "NO",
            "cityCode": null,
            "cityName": "Namsos",
            "name": "[OSY] - Namsos - Norway",
            "searchName": "OSY-Namsos-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "CNO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chino",
            "name": "[CNO] - Chino - United States",
            "searchName": "CNO-Chino-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CNK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Concordia",
            "name": "[CNK] - Concordia - United States",
            "searchName": "CNK-Concordia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CPR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Casper",
            "name": "[CPR] - Casper - United States",
            "searchName": "CPR-Casper-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CPM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Compton",
            "name": "[CPM] - Compton - United States",
            "searchName": "CPM-Compton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "COS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Colorado Springs",
            "name": "[COS] - Colorado Springs - United States",
            "searchName": "COS-Colorado Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "COT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cotulla",
            "name": "[COT] - Cotulla - United States",
            "searchName": "COT-Cotulla-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "COU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Columbia",
            "name": "[COU] - Columbia - United States",
            "searchName": "COU-Columbia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CIK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chalkyitsik",
            "name": "[CIK] - Chalkyitsik - United States",
            "searchName": "CIK-Chalkyitsik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CIL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Council",
            "name": "[CIL] - Council - United States",
            "searchName": "CIL-Council-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CIG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Craig",
            "name": "[CIG] - Craig - United States",
            "searchName": "CIG-Craig-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CHZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chiloquin",
            "name": "[CHZ] - Chiloquin - United States",
            "searchName": "CHZ-Chiloquin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CIC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chico",
            "name": "[CIC] - Chico - United States",
            "searchName": "CIC-Chico-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CID",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cedar Rapids",
            "name": "[CID] - Cedar Rapids - United States",
            "searchName": "CID-Cedar Rapids-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CHU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chuathbaluk",
            "name": "[CHU] - Chuathbaluk - United States",
            "searchName": "CHU-Chuathbaluk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CHS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Charleston",
            "name": "[CHS] - Charleston - United States",
            "searchName": "CHS-Charleston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CIN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Carroll",
            "name": "[CIN] - Carroll - United States",
            "searchName": "CIN-Carroll-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CIV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chomley",
            "name": "[CIV] - Chomley - United States",
            "searchName": "CIV-Chomley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CIR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cairo",
            "name": "[CIR] - Cairo - United States",
            "searchName": "CIR-Cairo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CHK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chickasha",
            "name": "[CHK] - Chickasha - United States",
            "searchName": "CHK-Chickasha-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CHL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Challis",
            "name": "[CHL] - Challis - United States",
            "searchName": "CHL-Challis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CHO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Charlottesville",
            "name": "[CHO] - Charlottesville - United States",
            "searchName": "CHO-Charlottesville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CHP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Circle Hot Springs",
            "name": "[CHP] - Circle Hot Springs - United States",
            "searchName": "CHP-Circle Hot Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CGZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Casa Grande",
            "name": "[CGZ] - Casa Grande - United States",
            "searchName": "CGZ-Casa Grande-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CHA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chattanooga",
            "name": "[CHA] - Chattanooga - United States",
            "searchName": "CHA-Chattanooga-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CYT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Yakataga",
            "name": "[CYT] - Yakataga - United States",
            "searchName": "CYT-Yakataga-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CZF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cape Romanzof",
            "name": "[CZF] - Cape Romanzof - United States",
            "searchName": "CZF-Cape Romanzof-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CZC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Copper Centre",
            "name": "[CZC] - Copper Centre - United States",
            "searchName": "CZC-Copper Center-Copper Centre-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CZN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chisana",
            "name": "[CZN] - Chisana - United States",
            "searchName": "CZN-Chisana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CZO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chistochina",
            "name": "[CZO] - Chistochina - United States",
            "searchName": "CZO-Chistochina-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CZP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cape Pole",
            "name": "[CZP] - Cape Pole - United States",
            "searchName": "CZP-Cape Pole-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DIK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dickinson",
            "name": "[DIK] - Dickinson - United States",
            "searchName": "DIK-Dickinson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DHB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Deer Harbor",
            "name": "[DHB] - Deer Harbor - United States",
            "searchName": "DHB-Deer Harbor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DGW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Douglas",
            "name": "[DGW] - Douglas - United States",
            "searchName": "DGW-Douglas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DGN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dahlgren",
            "name": "[DGN] - Dahlgren - United States",
            "searchName": "DGN-Dahlgren-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Columbus",
            "name": "[CLU] - Columbus - United States",
            "searchName": "CLU-Columbus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Clarks Point",
            "name": "[CLP] - Clarks Point - United States",
            "searchName": "CLP-Clarks Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Clearwater",
            "name": "[CLW] - Clearwater - United States",
            "searchName": "CLW-Clearwater-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CNY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Moab",
            "name": "[CNY] - Moab - United States",
            "searchName": "CNY-Moab-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CNU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chanute",
            "name": "[CNU] - Chanute - United States",
            "searchName": "CNU-Chanute-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "COD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cody",
            "name": "[COD] - Cody - United States",
            "searchName": "COD-Cody/Yellowstone-Cody-Cody\\/Yellowstone-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "COE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Coeur D Alene",
            "name": "[COE] - Coeur D Alene - United States",
            "searchName": "COE-Coeur D Alene-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "COA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Columbia",
            "name": "[COA] - Columbia - United States",
            "searchName": "COA-Columbia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "COI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cocoa",
            "name": "[COI] - Cocoa - United States",
            "searchName": "COI-Cocoa-Cocoa Metro Area-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "COM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Coleman",
            "name": "[COM] - Coleman - United States",
            "searchName": "COM-Coleman-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CON",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Concord",
            "name": "[CON] - Concord - United States",
            "searchName": "CON-Concord-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "COP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cooperstown",
            "name": "[COP] - Cooperstown - United States",
            "searchName": "COP-Cooperstown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CNE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Canon City",
            "name": "[CNE] - Canon City - United States",
            "searchName": "CNE-Canon City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CNH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Claremont",
            "name": "[CNH] - Claremont - United States",
            "searchName": "CNH-Claremont-L-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CRS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Corsicana",
            "name": "[CRS] - Corsicana - United States",
            "searchName": "CRS-Corsicana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CRT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Crossett",
            "name": "[CRT] - Crossett - United States",
            "searchName": "CRT-Crossett-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CSE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Crested Butte",
            "name": "[CSE] - Crested Butte - United States",
            "searchName": "CSE-Crested Butte-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CSL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "San Luis Obispo",
            "name": "[CSL] - San Luis Obispo - United States",
            "searchName": "CSL-San Luis Obispo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CSM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Clinton",
            "name": "[CSM] - Clinton - United States",
            "searchName": "CSM-Clinton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CSN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Carson City",
            "name": "[CSN] - Carson City - United States",
            "searchName": "CSN-Carson City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CSG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Columbus",
            "name": "[CSG] - Columbus - United States",
            "searchName": "CSG-Columbus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CRO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Corcoran",
            "name": "[CRO] - Corcoran - United States",
            "searchName": "CRO-Corcoran-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CRP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Corpus Christi",
            "name": "[CRP] - Corpus Christi - United States",
            "searchName": "CRP-Corpus Christi-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CRW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Charleston",
            "name": "[CRW] - Charleston - United States",
            "searchName": "CRW-Charleston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CRX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Corinth",
            "name": "[CRX] - Corinth - United States",
            "searchName": "CRX-Corinth-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CTB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cut Bank",
            "name": "[CTB] - Cut Bank - United States",
            "searchName": "CTB-Cut Bank-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CSV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Crossville",
            "name": "[CSV] - Crossville - United States",
            "searchName": "CSV-Crossville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CSP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cape Spencer",
            "name": "[CSP] - Cape Spencer - United States",
            "searchName": "CSP-Cape Spencer-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CSQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Creston",
            "name": "[CSQ] - Creston - United States",
            "searchName": "CSQ-Creston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CTO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Calverton",
            "name": "[CTO] - Calverton - United States",
            "searchName": "CTO-Calverton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CTK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Canton",
            "name": "[CTK] - Canton - United States",
            "searchName": "CTK-Canton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CTH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Coatesville",
            "name": "[CTH] - Coatesville - United States",
            "searchName": "CTH-Coatesville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CTW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cottonwood",
            "name": "[CTW] - Cottonwood - United States",
            "searchName": "CTW-Cottonwood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CTX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cortland",
            "name": "[CTX] - Cortland - United States",
            "searchName": "CTX-Cortland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CTY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cross City",
            "name": "[CTY] - Cross City - United States",
            "searchName": "CTY-Cross City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CTZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Clinton",
            "name": "[CTZ] - Clinton - United States",
            "searchName": "CTZ-Clinton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DAG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Daggett",
            "name": "[DAG] - Daggett - United States",
            "searchName": "DAG-Daggett-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DAY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dayton",
            "name": "[DAY] - Dayton - United States",
            "searchName": "DAY-Dayton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DBN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dublin",
            "name": "[DBN] - Dublin - United States",
            "searchName": "DBN-Dublin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DBQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dubuque",
            "name": "[DBQ] - Dubuque - United States",
            "searchName": "DBQ-Dubuque-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DBS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dubois",
            "name": "[DBS] - Dubois - United States",
            "searchName": "DBS-Dubois-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DCK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dahl Creek",
            "name": "[DCK] - Dahl Creek - United States",
            "searchName": "DCK-Dahl Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DCR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Decatur",
            "name": "[DCR] - Decatur - United States",
            "searchName": "DCR-Decatur-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DDC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dodge City",
            "name": "[DDC] - Dodge City - United States",
            "searchName": "DDC-Dodge City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DCU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Decatur",
            "name": "[DCU] - Decatur - United States",
            "searchName": "DCU-Decatur-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DAN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Danville",
            "name": "[DAN] - Danville - United States",
            "searchName": "DAN-Danville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CZK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cascade Locks",
            "name": "[CZK] - Cascade Locks - United States",
            "searchName": "CZK-Cascade Locks-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CZT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Carrizo Springs",
            "name": "[CZT] - Carrizo Springs - United States",
            "searchName": "CZT-Carrizo Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CZZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Campo",
            "name": "[CZZ] - Campo - United States",
            "searchName": "CZZ-Campo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DAA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Belvoir",
            "name": "[DAA] - Fort Belvoir - United States",
            "searchName": "DAA-Fort Belvoir-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DAB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Daytona Beach",
            "name": "[DAB] - Daytona Beach - United States",
            "searchName": "DAB-Daytona Beach-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MHV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mojave",
            "name": "[MHV] - Mojave - United States",
            "searchName": "MHV-Mojave-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MHK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Manhattan",
            "name": "[MHK] - Manhattan - United States",
            "searchName": "MHK-Manhattan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MHL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Marshall",
            "name": "[MHL] - Marshall - United States",
            "searchName": "MHL-Marshall-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MHM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Minchumina",
            "name": "[MHM] - Minchumina - United States",
            "searchName": "MHM-Minchumina-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MHN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mullen",
            "name": "[MHN] - Mullen - United States",
            "searchName": "MHN-Mullen-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MHS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mount Shasta",
            "name": "[MHS] - Mount Shasta - United States",
            "searchName": "MHS-Mount Shasta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MHT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Manchester",
            "name": "[MHT] - Manchester - United States",
            "searchName": "MHT-Manchester-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MBS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Saginaw",
            "name": "[MBS] - Saginaw - United States",
            "searchName": "MBS-Bay City/Midland/Saginaw-Saginaw-Bay City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MBY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Moberly",
            "name": "[MBY] - Moberly - United States",
            "searchName": "MBY-Moberly-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MBG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mobridge",
            "name": "[MBG] - Mobridge - United States",
            "searchName": "MBG-Mobridge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MBL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Manistee",
            "name": "[MBL] - Manistee - United States",
            "searchName": "MBL-Manistee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MAW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Malden",
            "name": "[MAW] - Malden - United States",
            "searchName": "MAW-Malden-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MDN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Madison",
            "name": "[MDN] - Madison - United States",
            "searchName": "MDN-Madison-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MDO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Middleton Island",
            "name": "[MDO] - Middleton Island - United States",
            "searchName": "MDO-Middleton Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MDJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Madras",
            "name": "[MDJ] - Madras - United States",
            "searchName": "MDJ-Madras-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MDH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Carbondale",
            "name": "[MDH] - Carbondale - United States",
            "searchName": "MDH-Carbondale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MDF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Medford",
            "name": "[MDF] - Medford - United States",
            "searchName": "MDF-Medford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MCW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mason City",
            "name": "[MCW] - Mason City - United States",
            "searchName": "MCW-Mason City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MCN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Macon",
            "name": "[MCN] - Macon - United States",
            "searchName": "MCN-Macon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MCK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mccook",
            "name": "[MCK] - Mccook - United States",
            "searchName": "MCK-McCook-Mccook-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MCL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mount Mckinley",
            "name": "[MCL] - Mount Mckinley - United States",
            "searchName": "MCL-Mount McKinley-Mount Mckinley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MCB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mccomb",
            "name": "[MCB] - Mccomb - United States",
            "searchName": "MCB-Mccomb-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MCD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mackinac Island",
            "name": "[MCD] - Mackinac Island - United States",
            "searchName": "MCD-Mackinac Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MCE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Merced",
            "name": "[MCE] - Merced - United States",
            "searchName": "MCE-Merced-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MCG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mcgrath",
            "name": "[MCG] - Mcgrath - United States",
            "searchName": "MCG-Mcgrath-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LXN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lexington",
            "name": "[LXN] - Lexington - United States",
            "searchName": "LXN-Lexington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LWS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lewiston",
            "name": "[LWS] - Lewiston - United States",
            "searchName": "LWS-Lewiston Idaho-Lewiston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LWT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lewistown",
            "name": "[LWT] - Lewistown - United States",
            "searchName": "LWT-Lewistown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LWV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lawrenceville",
            "name": "[LWV] - Lawrenceville - United States",
            "searchName": "LWV-Lawrenceville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LYH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lynchburg",
            "name": "[LYH] - Lynchburg - United States",
            "searchName": "LYH-Lynchburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LXV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Leadville",
            "name": "[LXV] - Leadville - United States",
            "searchName": "LXV-Leadville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LWB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lewisburg",
            "name": "[LWB] - Lewisburg - United States",
            "searchName": "LWB-Lewisburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LWC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lawrence",
            "name": "[LWC] - Lawrence - United States",
            "searchName": "LWC-Lawrence-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LWL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wells",
            "name": "[LWL] - Wells - United States",
            "searchName": "LWL-Wells-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LWM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lawrence",
            "name": "[LWM] - Lawrence - United States",
            "searchName": "LWM-Lawrence-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LVS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Las Vegas",
            "name": "[LVS] - Las Vegas - United States",
            "searchName": "LVS-Las Vegas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LVD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lime Village",
            "name": "[LVD] - Lime Village - United States",
            "searchName": "LVD-Lime Village-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LVK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Livermore",
            "name": "[LVK] - Livermore - United States",
            "searchName": "LVK-Livermore-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LVL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lawrenceville",
            "name": "[LVL] - Lawrenceville - United States",
            "searchName": "LVL-Lawrenceville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LVM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Livingston",
            "name": "[LVM] - Livingston - United States",
            "searchName": "LVM-Livingston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LYO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lyons",
            "name": "[LYO] - Lyons - United States",
            "searchName": "LYO-Lyons-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LYU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ely",
            "name": "[LYU] - Ely - United States",
            "searchName": "LYU-Ely-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LZU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lawrenceville",
            "name": "[LZU] - Lawrenceville - United States",
            "searchName": "LZU-Lawrenceville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MAE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Madera",
            "name": "[MAE] - Madera - United States",
            "searchName": "MAE-Madera-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MAF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Midland",
            "name": "[MAF] - Midland - United States",
            "searchName": "MAF-Midland-Midland\\/Odessa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LKV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lakeview",
            "name": "[LKV] - Lakeview - United States",
            "searchName": "LKV-Lakeview-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LKS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lakeside",
            "name": "[LKS] - Lakeside - United States",
            "searchName": "LKS-Lakeside-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LKP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lake Placid",
            "name": "[LKP] - Lake Placid - United States",
            "searchName": "LKP-Lake Placid-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LJN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lake Jackson",
            "name": "[LJN] - Lake Jackson - United States",
            "searchName": "LJN-Lake Jackson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LIY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hinesville",
            "name": "[LIY] - Hinesville - United States",
            "searchName": "LIY-Hinesville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LIZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Limestone",
            "name": "[LIZ] - Limestone - United States",
            "searchName": "LIZ-Limestone-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LKK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kulik Lake",
            "name": "[LKK] - Kulik Lake - United States",
            "searchName": "LKK-Kulik Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LLX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lyndonville",
            "name": "[LLX] - Lyndonville - United States",
            "searchName": "LLX-Lyndonville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LLY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mount Holly",
            "name": "[LLY] - Mount Holly - United States",
            "searchName": "LLY-Mount Holly-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LMA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lake Minchumina",
            "name": "[LMA] - Lake Minchumina - United States",
            "searchName": "LMA-Lake Minchumina-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LND",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lander",
            "name": "[LND] - Lander - United States",
            "searchName": "LND-Lander-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LMS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Louisville",
            "name": "[LMS] - Louisville - United States",
            "searchName": "LMS-Louisville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LMT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Klamath Falls",
            "name": "[LMT] - Klamath Falls - United States",
            "searchName": "LMT-Klamath Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LHV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lock Haven",
            "name": "[LHV] - Lock Haven - United States",
            "searchName": "LHV-Lock Haven-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LGU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Logan",
            "name": "[LGU] - Logan - United States",
            "searchName": "LGU-Logan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LHB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lost Harbor",
            "name": "[LHB] - Lost Harbor - United States",
            "searchName": "LHB-Lost Harbor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LIT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Little Rock",
            "name": "[LIT] - Little Rock - United States",
            "searchName": "LIT-Little Rock-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LIV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Livengood",
            "name": "[LIV] - Livengood - United States",
            "searchName": "LIV-Livengood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LIC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Limon",
            "name": "[LIC] - Limon - United States",
            "searchName": "LIC-Limon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LIJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Long Island",
            "name": "[LIJ] - Long Island - United States",
            "searchName": "LIJ-Long Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LIH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lihue",
            "name": "[LIH] - Lihue - United States",
            "searchName": "LIH-Lihue-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LGB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Long Beach",
            "name": "[LGB] - Long Beach - United States",
            "searchName": "LGB-Long Beach-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LGC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "La Grange",
            "name": "[LGC] - La Grange - United States",
            "searchName": "LGC-La Grange-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LGD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "La Grande",
            "name": "[LGD] - La Grande - United States",
            "searchName": "LGD-La Grande-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LEW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lewiston",
            "name": "[LEW] - Lewiston - United States",
            "searchName": "LEW-Lewiston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LEX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lexington",
            "name": "[LEX] - Lexington - United States",
            "searchName": "LEX-Lexington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LFN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Louisburg",
            "name": "[LFN] - Louisburg - United States",
            "searchName": "LFN-Louisburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LFT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lafayette",
            "name": "[LFT] - Lafayette - United States",
            "searchName": "LFT-Lafayette-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LOG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Longview",
            "name": "[LOG] - Longview - United States",
            "searchName": "LOG-Longview-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LOL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lovelock",
            "name": "[LOL] - Lovelock - United States",
            "searchName": "LOL-Lovelock-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LOT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lockport",
            "name": "[LOT] - Lockport - United States",
            "searchName": "LOT-Lockport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LOW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Louisa",
            "name": "[LOW] - Louisa - United States",
            "searchName": "LOW-Louisa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FKN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Franklin",
            "name": "[FKN] - Franklin - United States",
            "searchName": "FKN-Franklin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FIC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fire Cove",
            "name": "[FIC] - Fire Cove - United States",
            "searchName": "FIC-Fire Cove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FID",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fishers Island",
            "name": "[FID] - Fishers Island - United States",
            "searchName": "FID-Fishers Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FHU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sierra Vista",
            "name": "[FHU] - Sierra Vista - United States",
            "searchName": "FHU-Fort Huachuca\\/ Sierra Vista-Sierra Vista-Ft Huachuca-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FCY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Forrest City",
            "name": "[FCY] - Forrest City - United States",
            "searchName": "FCY-Forrest City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FBR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Bridger",
            "name": "[FBR] - Fort Bridger - United States",
            "searchName": "FBR-Fort Bridger-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FBS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Friday Harbor",
            "name": "[FBS] - Friday Harbor - United States",
            "searchName": "FBS-Friday Harbor SPB-Friday Harbor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FBY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fairbury",
            "name": "[FBY] - Fairbury - United States",
            "searchName": "FBY-Fairbury-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FCA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kalispell",
            "name": "[FCA] - Kalispell - United States",
            "searchName": "FCA-Kalispell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FDY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Findlay",
            "name": "[FDY] - Findlay - United States",
            "searchName": "FDY-Findlay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FDK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Frederick",
            "name": "[FDK] - Frederick - United States",
            "searchName": "FDK-Frederick-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FDR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Frederick",
            "name": "[FDR] - Frederick - United States",
            "searchName": "FDR-Frederick-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FFA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kill Devil Hills",
            "name": "[FFA] - Kill Devil Hills - United States",
            "searchName": "FFA-Kill Devil Hills-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FFL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fairfield",
            "name": "[FFL] - Fairfield - United States",
            "searchName": "FFL-Fairfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FFM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fergus Falls",
            "name": "[FFM] - Fergus Falls - United States",
            "searchName": "FFM-Fergus Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FFT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Frankfort",
            "name": "[FFT] - Frankfort - United States",
            "searchName": "FFT-Frankfort-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FET",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fremont",
            "name": "[FET] - Fremont - United States",
            "searchName": "FET-Fremont-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FEP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Freeport",
            "name": "[FEP] - Freeport - United States",
            "searchName": "FEP-Freeport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CKD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Crooked Creek",
            "name": "[CKD] - Crooked Creek - United States",
            "searchName": "CKD-Crooked Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CKE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Clear Lake",
            "name": "[CKE] - Clear Lake - United States",
            "searchName": "CKE-Clear Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CKM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Clarksdale",
            "name": "[CKM] - Clarksdale - United States",
            "searchName": "CKM-Clarksdale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CKN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Crookston",
            "name": "[CKN] - Crookston - United States",
            "searchName": "CKN-Crookston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CKK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cherokee",
            "name": "[CKK] - Cherokee - United States",
            "searchName": "CKK-Cherokee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CJN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "El Cajon",
            "name": "[CJN] - El Cajon - United States",
            "searchName": "CJN-El Cajon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CKA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cherokee",
            "name": "[CKA] - Cherokee - United States",
            "searchName": "CKA-Cherokee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CKB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Clarksburg",
            "name": "[CKB] - Clarksburg - United States",
            "searchName": "CKB-Clarksburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CKX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chicken",
            "name": "[CKX] - Chicken - United States",
            "searchName": "CKX-Chicken-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CKV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Clarksville",
            "name": "[CKV] - Clarksville - United States",
            "searchName": "CKV-Clarksville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CKR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Crane Island",
            "name": "[CKR] - Crane Island - United States",
            "searchName": "CKR-Crane Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "College Station",
            "name": "[CLL] - College Station - United States",
            "searchName": "CLL-College Station-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Port Angeles",
            "name": "[CLM] - Port Angeles - United States",
            "searchName": "CLM-Port Angeles-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Clintonville",
            "name": "[CLI] - Clintonville - United States",
            "searchName": "CLI-Clintonville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Clearlake",
            "name": "[CLC] - Clearlake - United States",
            "searchName": "CLC-Clearlake-Clear Lake City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FMS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Madison",
            "name": "[FMS] - Fort Madison - United States",
            "searchName": "FMS-Fort Madison-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FMU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Florence",
            "name": "[FMU] - Florence - United States",
            "searchName": "FMU-Florence-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FMY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Myers",
            "name": "[FMY] - Fort Myers - United States",
            "searchName": "FMY-Fort Myers-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FLT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Flat",
            "name": "[FLT] - Flat - United States",
            "searchName": "FLT-Flat-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FLV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Leavenworth",
            "name": "[FLV] - Fort Leavenworth - United States",
            "searchName": "FLV-Fort Leavenworth-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FMH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Falmouth",
            "name": "[FMH] - Falmouth - United States",
            "searchName": "FMH-Falmouth-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FMC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Five Mile",
            "name": "[FMC] - Five Mile - United States",
            "searchName": "FMC-Five Mile-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FME",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Meade",
            "name": "[FME] - Fort Meade - United States",
            "searchName": "FME-Fort Meade-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FOB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Bragg",
            "name": "[FOB] - Fort Bragg - United States",
            "searchName": "FOB-Fort Bragg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FOD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Dodge",
            "name": "[FOD] - Fort Dodge - United States",
            "searchName": "FOD-Fort Dodge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FNK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fin Creek",
            "name": "[FNK] - Fin Creek - United States",
            "searchName": "FNK-Fin Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FNL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Collins",
            "name": "[FNL] - Fort Collins - United States",
            "searchName": "FNL-Fort Collins/Loveland-Fort Collins-Fort Collins\\/Loveland-Loveland Bus Service-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FNR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Funter Bay",
            "name": "[FNR] - Funter Bay - United States",
            "searchName": "FNR-Funter Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FNT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Flint",
            "name": "[FNT] - Flint - United States",
            "searchName": "FNT-Flint-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FRM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fairmont",
            "name": "[FRM] - Fairmont - United States",
            "searchName": "FRM-Fairmont-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FRN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Richardson",
            "name": "[FRN] - Fort Richardson - United States",
            "searchName": "FRN-Fort Richardson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FRG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Farmingdale",
            "name": "[FRG] - Farmingdale - United States",
            "searchName": "FRG-Farmingdale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FRH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "French Lick",
            "name": "[FRH] - French Lick - United States",
            "searchName": "FRH-French Lick-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FRI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Riley",
            "name": "[FRI] - Fort Riley - United States",
            "searchName": "FRI-Fort Riley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FRR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Front Royal",
            "name": "[FRR] - Front Royal - United States",
            "searchName": "FRR-Front Royal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FRP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fresh Water Bay",
            "name": "[FRP] - Fresh Water Bay - United States",
            "searchName": "FRP-Fresh Water Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FRY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fryeburg",
            "name": "[FRY] - Fryeburg - United States",
            "searchName": "FRY-Fryeburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FOK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Westhampton",
            "name": "[FOK] - Westhampton - United States",
            "searchName": "FOK-Westhampton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FPR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Pierce",
            "name": "[FPR] - Fort Pierce - United States",
            "searchName": "FPR-Fort Pierce-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FPY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Perry",
            "name": "[FPY] - Perry - United States",
            "searchName": "FPY-Perry-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FRD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Friday Harbor",
            "name": "[FRD] - Friday Harbor - United States",
            "searchName": "FRD-Friday Harbor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FOP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Forest Park",
            "name": "[FOP] - Forest Park - United States",
            "searchName": "FOP-Forest Park-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FOX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fox",
            "name": "[FOX] - Fox - United States",
            "searchName": "FOX-Fox-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FLO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Florence",
            "name": "[FLO] - Florence - United States",
            "searchName": "FLO-Florence-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FLP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Flippin",
            "name": "[FLP] - Flippin - United States",
            "searchName": "FLP-Flippin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FLJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Falls Bay",
            "name": "[FLJ] - Falls Bay - United States",
            "searchName": "FLJ-Falls Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FLL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Lauderdale",
            "name": "[FLL] - Fort Lauderdale - United States",
            "searchName": "FLL-Fort Lauderdale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FLD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fond Du Lac",
            "name": "[FLD] - Fond Du Lac - United States",
            "searchName": "FLD-Fond Du Lac-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FIV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Five Finger Coast Guard Heliport",
            "name": "[FIV] - Five Finger Coast Guard Heliport - United States",
            "searchName": "FIV-Five Finger Coast-Five Finger Coast Guard Heliport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FIL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fillmore",
            "name": "[FIL] - Fillmore - United States",
            "searchName": "FIL-Fillmore-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NLV",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Nikolaev",
            "name": "[NLV] - Nikolaev - Ukraine",
            "searchName": "NLV-Nikolaev-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "MXR",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Mirgorod",
            "name": "[MXR] - Mirgorod - Ukraine",
            "searchName": "MXR-Mirgorod-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "OZH",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Zaporozhye",
            "name": "[OZH] - Zaporozhye - Ukraine",
            "searchName": "OZH-Zaporozhye-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "OYG",
            "countryCode": "UG",
            "cityCode": null,
            "cityName": "Moyo",
            "name": "[OYG] - Moyo - Uganda",
            "searchName": "OYG-Moyo-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "PAF",
            "countryCode": "UG",
            "cityCode": null,
            "cityName": "Pakuba",
            "name": "[PAF] - Pakuba - Uganda",
            "searchName": "PAF-Pakuba-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "RUA",
            "countryCode": "UG",
            "cityCode": null,
            "cityName": "Arua",
            "name": "[RUA] - Arua - Uganda",
            "searchName": "RUA-Arua-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "ULU",
            "countryCode": "UG",
            "cityCode": null,
            "cityName": "Gulu",
            "name": "[ULU] - Gulu - Uganda",
            "searchName": "ULU-Gulu-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "TRY",
            "countryCode": "UG",
            "cityCode": null,
            "cityName": "Tororo",
            "name": "[TRY] - Tororo - Uganda",
            "searchName": "TRY-Tororo-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "SRT",
            "countryCode": "UG",
            "cityCode": null,
            "cityName": "Soroti",
            "name": "[SRT] - Soroti - Uganda",
            "searchName": "SRT-Soroti-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "ASX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ashland",
            "name": "[ASX] - Ashland - United States",
            "searchName": "ASX-Ashland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ASY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ashley",
            "name": "[ASY] - Ashley - United States",
            "searchName": "ASY-Ashley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ATE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Antlers",
            "name": "[ATE] - Antlers - United States",
            "searchName": "ATE-Antlers-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ATK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Atqasuk",
            "name": "[ATK] - Atqasuk - United States",
            "searchName": "ATK-Atqasuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Coalinga",
            "name": "[CLG] - Coalinga - United States",
            "searchName": "CLG-Coalinga-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CMH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Columbus",
            "name": "[CMH] - Columbus - United States",
            "searchName": "CMH-Columbus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EXI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Excursion Inlet",
            "name": "[EXI] - Excursion Inlet - United States",
            "searchName": "EXI-Excursion Inlet-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ATW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Appleton",
            "name": "[ATW] - Appleton - United States",
            "searchName": "ATW-Appleton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ATO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Athens",
            "name": "[ATO] - Athens - United States",
            "searchName": "ATO-Athens-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ATS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Artesia",
            "name": "[ATS] - Artesia - United States",
            "searchName": "ATS-Artesia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ATT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Atmautluak",
            "name": "[ATT] - Atmautluak - United States",
            "searchName": "ATT-Atmautluak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ATU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Attu Island",
            "name": "[ATU] - Attu Island - United States",
            "searchName": "ATU-Attu Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ATY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Watertown",
            "name": "[ATY] - Watertown - United States",
            "searchName": "ATY-Watertown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AUG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Augusta",
            "name": "[AUG] - Augusta - United States",
            "searchName": "AUG-Augusta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AUK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Alakanuk",
            "name": "[AUK] - Alakanuk - United States",
            "searchName": "AUK-Alakanuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AUM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Austin",
            "name": "[AUM] - Austin - United States",
            "searchName": "AUM-Austin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AUN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Auburn",
            "name": "[AUN] - Auburn - United States",
            "searchName": "AUN-Auburn-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AUO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Auburn",
            "name": "[AUO] - Auburn - United States",
            "searchName": "AUO-Auburn-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AUW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wausau",
            "name": "[AUW] - Wausau - United States",
            "searchName": "AUW-Wausau-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AUS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Austin",
            "name": "[AUS] - Austin - United States",
            "searchName": "AUS-Austin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ANI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Aniak",
            "name": "[ANI] - Aniak - United States",
            "searchName": "ANI-Aniak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AMW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ames",
            "name": "[AMW] - Ames - United States",
            "searchName": "AMW-Ames-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ANA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Anaheim",
            "name": "[ANA] - Anaheim - United States",
            "searchName": "ANA-Anaheim-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ANB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Anniston",
            "name": "[ANB] - Anniston - United States",
            "searchName": "ANB-Anniston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CGI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cape Girardeau",
            "name": "[CGI] - Cape Girardeau - United States",
            "searchName": "CGI-Cape Girardeau-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CGE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cambridge",
            "name": "[CGE] - Cambridge - United States",
            "searchName": "CGE-Cambridge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CGS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "College Park",
            "name": "[CGS] - College Park - United States",
            "searchName": "CGS-College Park-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CEF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chicopee",
            "name": "[CEF] - Chicopee - United States",
            "searchName": "CEF-Springfield-Chicopee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CEC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Crescent City",
            "name": "[CEC] - Crescent City - United States",
            "searchName": "CEC-Crescent City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CDV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cordova",
            "name": "[CDV] - Cordova - United States",
            "searchName": "CDV-Cordova-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CDW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Caldwell",
            "name": "[CDW] - Caldwell - United States",
            "searchName": "CDW-Caldwell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CDR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chadron",
            "name": "[CDR] - Chadron - United States",
            "searchName": "CDR-Chadron-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CDS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Childress",
            "name": "[CDS] - Childress - United States",
            "searchName": "CDS-Childress-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CDK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cedar Key",
            "name": "[CDK] - Cedar Key - United States",
            "searchName": "CDK-Cedar Key-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CDL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Candle",
            "name": "[CDL] - Candle - United States",
            "searchName": "CDL-Candle-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CDN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Camden",
            "name": "[CDN] - Camden - United States",
            "searchName": "CDN-Camden-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CDH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Camden",
            "name": "[CDH] - Camden - United States",
            "searchName": "CDH-Camden-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CDB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cold Bay",
            "name": "[CDB] - Cold Bay - United States",
            "searchName": "CDB-Cold Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CDC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cedar City",
            "name": "[CDC] - Cedar City - United States",
            "searchName": "CDC-Cedar City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CCY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Charles City",
            "name": "[CCY] - Charles City - United States",
            "searchName": "CCY-Charles City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CCR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Concord",
            "name": "[CCR] - Concord - United States",
            "searchName": "CCR-Concord-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CEM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Central",
            "name": "[CEM] - Central - United States",
            "searchName": "CEM-Central-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CEU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Clemson",
            "name": "[CEU] - Clemson - United States",
            "searchName": "CEU-Clemson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CEV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Connersville",
            "name": "[CEV] - Connersville - United States",
            "searchName": "CEV-Connersville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CEW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Crestview",
            "name": "[CEW] - Crestview - United States",
            "searchName": "CEW-Crestview-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CEX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chena Hot Springs",
            "name": "[CEX] - Chena Hot Springs - United States",
            "searchName": "CEX-Chena Hot Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CEY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Murray",
            "name": "[CEY] - Murray - United States",
            "searchName": "CEY-Murray-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CEZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cortez",
            "name": "[CEZ] - Cortez - United States",
            "searchName": "CEZ-Cortez-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CFA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Coffee Point",
            "name": "[CFA] - Coffee Point - United States",
            "searchName": "CFA-Coffee Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CFD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bryan",
            "name": "[CFD] - Bryan - United States",
            "searchName": "CFD-Bryan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CFV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Coffeyville",
            "name": "[CFV] - Coffeyville - United States",
            "searchName": "CFV-Coffeyville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CGA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Craig",
            "name": "[CGA] - Craig - United States",
            "searchName": "CGA-Craig-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CFT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Clifton",
            "name": "[CFT] - Clifton - United States",
            "searchName": "CFT-Clifton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CWG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Callaway Gardens",
            "name": "[CWG] - Callaway Gardens - United States",
            "searchName": "CWG-Callaway Gardens-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CWI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Clinton",
            "name": "[CWI] - Clinton - United States",
            "searchName": "CWI-Clinton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CXC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chitina",
            "name": "[CXC] - Chitina - United States",
            "searchName": "CXC-Chitina-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CXF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Coldfoot",
            "name": "[CXF] - Coldfoot - United States",
            "searchName": "CXF-Coldfoot-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CWS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Center Island",
            "name": "[CWS] - Center Island - United States",
            "searchName": "CWS-Center Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CWX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Willcox",
            "name": "[CWX] - Willcox - United States",
            "searchName": "CWX-Willcox-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CYM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chatham",
            "name": "[CYM] - Chatham - United States",
            "searchName": "CYM-Chatham-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CYE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Crystal Lake",
            "name": "[CYE] - Crystal Lake - United States",
            "searchName": "CYE-Chrystal Lake-Crystal Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CYF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chefornak",
            "name": "[CYF] - Chefornak - United States",
            "searchName": "CYF-Chefornak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CXO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Conroe",
            "name": "[CXO] - Conroe - United States",
            "searchName": "CXO-Conroe-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CXL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Calexico",
            "name": "[CXL] - Calexico - United States",
            "searchName": "CXL-Calexico-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CUH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cushing",
            "name": "[CUH] - Cushing - United States",
            "searchName": "CUH-Cushing-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CUS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Columbus",
            "name": "[CUS] - Columbus - United States",
            "searchName": "CUS-Columbus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CVN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Clovis",
            "name": "[CVN] - Clovis - United States",
            "searchName": "CVN-Clovis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CVO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Albany",
            "name": "[CVO] - Albany - United States",
            "searchName": "CVO-Albany-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CVR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Culver City",
            "name": "[CVR] - Culver City - United States",
            "searchName": "CVR-Culver City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CUW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cube Cove",
            "name": "[CUW] - Cube Cove - United States",
            "searchName": "CUW-Cube Cove TX-Cube Cove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HSZ",
            "countryCode": "TW",
            "cityCode": null,
            "cityName": "Hsinchu",
            "name": "[HSZ] - Hsinchu - Taiwan",
            "searchName": "HSZ-Hsinchu-Hsinchun-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "KHH",
            "countryCode": "TW",
            "cityCode": null,
            "cityName": "Kaohsiung",
            "name": "[KHH] - Kaohsiung - Taiwan",
            "searchName": "KHH-Kaohsiung-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "KNH",
            "countryCode": "TW",
            "cityCode": null,
            "cityName": "Kinmen",
            "name": "[KNH] - Kinmen - Taiwan",
            "searchName": "KNH-Kinmen-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "KYD",
            "countryCode": "TW",
            "cityCode": null,
            "cityName": "Orchid Island",
            "name": "[KYD] - Orchid Island - Taiwan",
            "searchName": "KYD-Orchid Island-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "MFK",
            "countryCode": "TW",
            "cityCode": null,
            "cityName": "Matsu",
            "name": "[MFK] - Matsu - Taiwan",
            "searchName": "MFK-Matsu-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "LZN",
            "countryCode": "TW",
            "cityCode": null,
            "cityName": "Nangan",
            "name": "[LZN] - Nangan - Taiwan",
            "searchName": "LZN-Nangan-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "LHN",
            "countryCode": "TW",
            "cityCode": null,
            "cityName": "Lishan",
            "name": "[LHN] - Lishan - Taiwan",
            "searchName": "LHN-Lishan-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "CMJ",
            "countryCode": "TW",
            "cityCode": null,
            "cityName": "Chi Mei",
            "name": "[CMJ] - Chi Mei - Taiwan",
            "searchName": "CMJ-Chi Mei-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "CYI",
            "countryCode": "TW",
            "cityCode": null,
            "cityName": "Chiayi",
            "name": "[CYI] - Chiayi - Taiwan",
            "searchName": "CYI-Chiayi-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "KHU",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Kremenchug",
            "name": "[KHU] - Kremenchug - Ukraine",
            "searchName": "KHU-Kremenchug-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "KGO",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Kirovograd",
            "name": "[KGO] - Kirovograd - Ukraine",
            "searchName": "KGO-Kirovograd-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "KHE",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Kherson",
            "name": "[KHE] - Kherson - Ukraine",
            "searchName": "KHE-Kherson-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "KHC",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Kerch",
            "name": "[KHC] - Kerch - Ukraine",
            "searchName": "KHC-Kertsch-Kerch-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "KCP",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Kamenets-podolskiy",
            "name": "[KCP] - Kamenets-podolskiy - Ukraine",
            "searchName": "KCP-Kamjanez-Kamenets-podolskiy-Kamyanets Podilski-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "KWG",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Krivoy Rog",
            "name": "[KWG] - Krivoy Rog - Ukraine",
            "searchName": "KWG-Krivoy Rog-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "KRQ",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Kramatorsk",
            "name": "[KRQ] - Kramatorsk - Ukraine",
            "searchName": "KRQ-Kramatorsk-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "BAL",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Batman",
            "name": "[BAL] - Batman - Turkey",
            "searchName": "BAL-Batman-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "IFO",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Ivano-Frankovsk",
            "name": "[IFO] - Ivano-Frankovsk - Ukraine",
            "searchName": "IFO-Ivano-Frankovsk-Frankivsk-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "ZTR",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Zhitomir",
            "name": "[ZTR] - Zhitomir - Ukraine",
            "searchName": "ZTR-Schytomyr-Zhitomir-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "PLV",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Poltava",
            "name": "[PLV] - Poltava - Ukraine",
            "searchName": "PLV-Poltava-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "SEV",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Severodoneck",
            "name": "[SEV] - Severodoneck - Ukraine",
            "searchName": "SEV-Severodoneck-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "RWN",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Rovno",
            "name": "[RWN] - Rovno - Ukraine",
            "searchName": "RWN-Riwne-Rovno-Rivne-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "ERD",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Berdyansk",
            "name": "[ERD] - Berdyansk - Ukraine",
            "searchName": "ERD-Berdyansk-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "CWC",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Chernovtsy",
            "name": "[CWC] - Chernovtsy - Ukraine",
            "searchName": "CWC-Chernovtsy-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "CKC",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Cherkassy",
            "name": "[CKC] - Cherkassy - Ukraine",
            "searchName": "CKC-Tscherkassy-Cherkassy-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "TNL",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Ternopol",
            "name": "[TNL] - Ternopol - Ukraine",
            "searchName": "TNL-Ternopil-Ternopol-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "UMY",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Sumy",
            "name": "[UMY] - Sumy - Ukraine",
            "searchName": "UMY-Sumy-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "UKS",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Sevastopol",
            "name": "[UKS] - Sevastopol - Ukraine",
            "searchName": "UKS-Sevastopol-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "UDJ",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Uzhgorod",
            "name": "[UDJ] - Uzhgorod - Ukraine",
            "searchName": "UDJ-Uzhgorod-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "UCK",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Lutsk",
            "name": "[UCK] - Lutsk - Ukraine",
            "searchName": "UCK-Lutsk-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "VIN",
            "countryCode": "UA",
            "cityCode": null,
            "cityName": "Vinnica",
            "name": "[VIN] - Vinnica - Ukraine",
            "searchName": "VIN-Winnyzja-Vinnica-Vinnitsya-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "AND",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Anderson",
            "name": "[AND] - Anderson - United States",
            "searchName": "AND-Anderson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ANY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Anthony",
            "name": "[ANY] - Anthony - United States",
            "searchName": "ANY-Anthony-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ANV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Anvik",
            "name": "[ANV] - Anvik - United States",
            "searchName": "ANV-Anvik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ANW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ainsworth",
            "name": "[ANW] - Ainsworth - United States",
            "searchName": "ANW-Ainsworth-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ANP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Annapolis",
            "name": "[ANP] - Annapolis - United States",
            "searchName": "ANP-Annapolis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ANQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Angola",
            "name": "[ANQ] - Angola - United States",
            "searchName": "ANQ-Angola-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ANN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Annette Island",
            "name": "[ANN] - Annette Island - United States",
            "searchName": "ANN-Annette Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AMN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Alma",
            "name": "[AMN] - Alma - United States",
            "searchName": "AMN-Alma-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ALZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Alitak",
            "name": "[ALZ] - Alitak - United States",
            "searchName": "ALZ-Alitak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AMA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Amarillo",
            "name": "[AMA] - Amarillo - United States",
            "searchName": "AMA-Amarillo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ALS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Alamosa",
            "name": "[ALS] - Alamosa - United States",
            "searchName": "ALS-Alamosa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ALW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Walla Walla",
            "name": "[ALW] - Walla Walla - United States",
            "searchName": "ALW-Walla Walla-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ALX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Alexander City",
            "name": "[ALX] - Alexander City - United States",
            "searchName": "ALX-Alexander City-Alexander-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ALI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Alice",
            "name": "[ALI] - Alice - United States",
            "searchName": "ALI-Alice-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ALM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Alamogordo",
            "name": "[ALM] - Alamogordo - United States",
            "searchName": "ALM-Alamogordo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ALN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Alton",
            "name": "[ALN] - Alton - United States",
            "searchName": "ALN-Alton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ALO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Waterloo",
            "name": "[ALO] - Waterloo - United States",
            "searchName": "ALO-Waterloo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AIY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Atlantic City",
            "name": "[AIY] - Atlantic City - United States",
            "searchName": "AIY-Atlantic City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AIZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kaiser",
            "name": "[AIZ] - Kaiser - United States",
            "searchName": "AIZ-Kaiser-Lake Of The Ozarks-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AIV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Aliceville",
            "name": "[AIV] - Aliceville - United States",
            "searchName": "AIV-Aliceville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AIK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Aiken",
            "name": "[AIK] - Aiken - United States",
            "searchName": "AIK-Aiken-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AIN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wainwright",
            "name": "[AIN] - Wainwright - United States",
            "searchName": "AIN-Wainwright-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AIO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Atlantic",
            "name": "[AIO] - Atlantic - United States",
            "searchName": "AIO-Atlantic-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AHF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Arapahoe",
            "name": "[AHF] - Arapahoe - United States",
            "searchName": "AHF-Arapahoe-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AHH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Amery",
            "name": "[AHH] - Amery - United States",
            "searchName": "AHH-Amery-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AHT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Amchitka",
            "name": "[AHT] - Amchitka - United States",
            "searchName": "AHT-Amchitka-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AHM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ashland",
            "name": "[AHM] - Ashland - United States",
            "searchName": "AHM-Ashland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AHN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Athens",
            "name": "[AHN] - Athens - United States",
            "searchName": "AHN-Athens-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AID",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Anderson",
            "name": "[AID] - Anderson - United States",
            "searchName": "AID-Anderson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AIA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Alliance",
            "name": "[AIA] - Alliance - United States",
            "searchName": "AIA-Alliance-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AIB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Anita Bay",
            "name": "[AIB] - Anita Bay - United States",
            "searchName": "AIB-Anita Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AKN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "King Salmon",
            "name": "[AKN] - King Salmon - United States",
            "searchName": "AKN-King Salmon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AKO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Akron",
            "name": "[AKO] - Akron - United States",
            "searchName": "AKO-Akron-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AKP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Anaktuvuk",
            "name": "[AKP] - Anaktuvuk - United States",
            "searchName": "AKP-Anaktuvuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ALE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Alpine",
            "name": "[ALE] - Alpine - United States",
            "searchName": "ALE-Alpine-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ALB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Albany",
            "name": "[ALB] - Albany - United States",
            "searchName": "ALB-Albany-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AKI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Akiak",
            "name": "[AKI] - Akiak - United States",
            "searchName": "AKI-Akiak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AKK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Akhiok",
            "name": "[AKK] - Akhiok - United States",
            "searchName": "AKK-Akhiok-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AKB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Atka",
            "name": "[AKB] - Atka - United States",
            "searchName": "AKB-Atka-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ACT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Waco",
            "name": "[ACT] - Waco - United States",
            "searchName": "ACT-Waco-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ACV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Arcata",
            "name": "[ACV] - Arcata - United States",
            "searchName": "ACV-Arcata-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ACK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Nantucket",
            "name": "[ACK] - Nantucket - United States",
            "searchName": "ACK-Nantucket-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ABQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Albuquerque",
            "name": "[ABQ] - Albuquerque - United States",
            "searchName": "ABQ-Albuquerque-Alburquerque-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ABR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Aberdeen",
            "name": "[ABR] - Aberdeen - United States",
            "searchName": "ABR-Aberdeen-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ABY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Albany",
            "name": "[ABY] - Albany - United States",
            "searchName": "ABY-Albany-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ACB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bellaire",
            "name": "[ACB] - Bellaire - United States",
            "searchName": "ACB-Bellaire-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AAF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Apalachicola",
            "name": "[AAF] - Apalachicola - United States",
            "searchName": "AAF-Apalachicola-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ABE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Allentown",
            "name": "[ABE] - Allentown - United States",
            "searchName": "ABE-Allentown/Bethlehem/Easton-Allentown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ABL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ambler",
            "name": "[ABL] - Ambler - United States",
            "searchName": "ABL-Ambler-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ABI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Abilene",
            "name": "[ABI] - Abilene - United States",
            "searchName": "ABI-Abilene-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AFN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Jaffrey",
            "name": "[AFN] - Jaffrey - United States",
            "searchName": "AFN-Jaffrey-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AFO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Afton",
            "name": "[AFO] - Afton - United States",
            "searchName": "AFO-Afton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AHC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Herlong",
            "name": "[AHC] - Herlong - United States",
            "searchName": "AHC-Herlong-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AGN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Angoon",
            "name": "[AGN] - Angoon - United States",
            "searchName": "AGN-Angoon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AGO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Magnolia",
            "name": "[AGO] - Magnolia - United States",
            "searchName": "AGO-Magnolia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AGS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Augusta",
            "name": "[AGS] - Augusta - United States",
            "searchName": "AGS-Augusta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ADG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Adrian",
            "name": "[ADG] - Adrian - United States",
            "searchName": "ADG-Adrian-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BOK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Brookings",
            "name": "[BOK] - Brookings - United States",
            "searchName": "BOK-Brookings-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BOI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Boise",
            "name": "[BOI] - Boise - United States",
            "searchName": "BOI-Boise-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BNW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Boone",
            "name": "[BNW] - Boone - United States",
            "searchName": "BNW-Boone-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BNO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Burns",
            "name": "[BNO] - Burns - United States",
            "searchName": "BNO-Burns-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BNL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Barnwell",
            "name": "[BNL] - Barnwell - United States",
            "searchName": "BNL-Barnwell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BNA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Nashville",
            "name": "[BNA] - Nashville - United States",
            "searchName": "BNA-Nashville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BMX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Big Mountain",
            "name": "[BMX] - Big Mountain - United States",
            "searchName": "BMX-Big Mountain-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BNF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Baranof",
            "name": "[BNF] - Baranof - United States",
            "searchName": "BNF-Baranof-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BNG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Banning",
            "name": "[BNG] - Banning - United States",
            "searchName": "BNG-Banning-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BDL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Windsor Locks",
            "name": "[BDL] - Windsor Locks - United States",
            "searchName": "BDL-Hartford-Windsor Locks-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BDR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bridgeport",
            "name": "[BDR] - Bridgeport - United States",
            "searchName": "BDR-Bridgeport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BDX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Broadus",
            "name": "[BDX] - Broadus - United States",
            "searchName": "BDX-Broadus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BDY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bandon",
            "name": "[BDY] - Bandon - United States",
            "searchName": "BDY-Bandon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BEH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Benton Harbor",
            "name": "[BEH] - Benton Harbor - United States",
            "searchName": "BEH-Benton Harbor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BED",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bedford",
            "name": "[BED] - Bedford - United States",
            "searchName": "BED-Bedford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BET",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bethel",
            "name": "[BET] - Bethel - United States",
            "searchName": "BET-Bethel-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BFP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Beaver Falls",
            "name": "[BFP] - Beaver Falls - United States",
            "searchName": "BFP-Beaver Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BGM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Binghamton",
            "name": "[BGM] - Binghamton - United States",
            "searchName": "BGM-Binghamton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BFT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Beaufort",
            "name": "[BFT] - Beaufort - United States",
            "searchName": "BFT-Beaufort-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BGD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Borger",
            "name": "[BGD] - Borger - United States",
            "searchName": "BGD-Borger-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BGE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bainbridge",
            "name": "[BGE] - Bainbridge - United States",
            "searchName": "BGE-Bainbridge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BFB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Blue Fox Bay",
            "name": "[BFB] - Blue Fox Bay - United States",
            "searchName": "BFB-Blue Fox Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BFD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bradford",
            "name": "[BFD] - Bradford - United States",
            "searchName": "BFD-Bradford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BFR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bedford",
            "name": "[BFR] - Bedford - United States",
            "searchName": "BFR-Bedford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BFF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Scottsbluff",
            "name": "[BFF] - Scottsbluff - United States",
            "searchName": "BFF-Scottsbluff-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BFG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bullfrog Basin",
            "name": "[BFG] - Bullfrog Basin - United States",
            "searchName": "BFG-Bullfrog Basin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BFL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bakersfield",
            "name": "[BFL] - Bakersfield - United States",
            "searchName": "BFL-Bakersfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BIC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Big Creek",
            "name": "[BIC] - Big Creek - United States",
            "searchName": "BIC-Big Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BID",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Block Island",
            "name": "[BID] - Block Island - United States",
            "searchName": "BID-Block Island--Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BIE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Beatrice",
            "name": "[BIE] - Beatrice - United States",
            "searchName": "BIE-Beatrice-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BIG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Big Delta",
            "name": "[BIG] - Big Delta - United States",
            "searchName": "BIG-Big Delta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BIH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bishop",
            "name": "[BIH] - Bishop - United States",
            "searchName": "BIH-Bishop-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BHM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Birmingham",
            "name": "[BHM] - Birmingham - United States",
            "searchName": "BHM-Birmingham-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BHB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bar Harbor",
            "name": "[BHB] - Bar Harbor - United States",
            "searchName": "BHB-Bar Harbor-Bar Harbour-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BGQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Big Lake",
            "name": "[BGQ] - Big Lake - United States",
            "searchName": "BGQ-Big Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BGR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bangor",
            "name": "[BGR] - Bangor - United States",
            "searchName": "BGR-Bangor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BGT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bagdad",
            "name": "[BGT] - Bagdad - United States",
            "searchName": "BGT-Bagdad-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BJC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Broomfield",
            "name": "[BJC] - Broomfield - United States",
            "searchName": "BJC-Broomfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BJI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bemidji",
            "name": "[BJI] - Bemidji - United States",
            "searchName": "BJI-Bemidji-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BJJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wooster",
            "name": "[BJJ] - Wooster - United States",
            "searchName": "BJJ-Wooster-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BIX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Biloxi",
            "name": "[BIX] - Biloxi - United States",
            "searchName": "BIX-Biloxi-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BIS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bismarck",
            "name": "[BIS] - Bismarck - United States",
            "searchName": "BIS-Bismarck-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BIL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Billings",
            "name": "[BIL] - Billings - United States",
            "searchName": "BIL-Billings-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BZN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bozeman",
            "name": "[BZN] - Bozeman - United States",
            "searchName": "BZN-Bozeman-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BZT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Brazoria",
            "name": "[BZT] - Brazoria - United States",
            "searchName": "BZT-Brazoria-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CAD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cadillac",
            "name": "[CAD] - Cadillac - United States",
            "searchName": "CAD-Cadillac-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CAE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Columbia",
            "name": "[CAE] - Columbia - United States",
            "searchName": "CAE-Columbia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CAR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Caribou",
            "name": "[CAR] - Caribou - United States",
            "searchName": "CAR-Caribou-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CAK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Akron/Canton",
            "name": "[CAK] - Akron/Canton - United States",
            "searchName": "CAK-Akron/Canton-Akron-Akron\\/Canton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CAO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Clayton",
            "name": "[CAO] - Clayton - United States",
            "searchName": "CAO-Clayton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CBK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Colby",
            "name": "[CBK] - Colby - United States",
            "searchName": "CBK-Colby-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CBE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cumberland",
            "name": "[CBE] - Cumberland - United States",
            "searchName": "CBE-Cumberland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CBF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Council Bluffs",
            "name": "[CBF] - Council Bluffs - United States",
            "searchName": "CBF-Council Bluffs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CBA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Corner Bay",
            "name": "[CBA] - Corner Bay - United States",
            "searchName": "CBA-Corner Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CCG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Crane",
            "name": "[CCG] - Crane - United States",
            "searchName": "CCG-Crane-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CBZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cabin Creek",
            "name": "[CBZ] - Cabin Creek - United States",
            "searchName": "CBZ-Cabin Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CCA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Chaffee",
            "name": "[CCA] - Fort Chaffee - United States",
            "searchName": "CCA-Fort Chaffee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CCB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Upland",
            "name": "[CCB] - Upland - United States",
            "searchName": "CCB-Upland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BYA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Boundary",
            "name": "[BYA] - Boundary - United States",
            "searchName": "BYA-Boundary-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BYG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Buffalo",
            "name": "[BYG] - Buffalo - United States",
            "searchName": "BYG-Buffalo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BYH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Blytheville",
            "name": "[BYH] - Blytheville - United States",
            "searchName": "BYH-Blytheville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BYI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rupert",
            "name": "[BYI] - Rupert - United States",
            "searchName": "BYI-Rupert-Burley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BYW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Blakely Island",
            "name": "[BYW] - Blakely Island - United States",
            "searchName": "BYW-Blakely Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BYS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Irwin",
            "name": "[BYS] - Fort Irwin - United States",
            "searchName": "BYS-Fort Irwin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BXC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Boxborough",
            "name": "[BXC] - Boxborough - United States",
            "searchName": "BXC-Boxborough-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BXK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Buckeye",
            "name": "[BXK] - Buckeye - United States",
            "searchName": "BXK-Buckeye-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BXS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Borrego Springs",
            "name": "[BXS] - Borrego Springs - United States",
            "searchName": "BXS-Borrego Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BWL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Blackwell",
            "name": "[BWL] - Blackwell - United States",
            "searchName": "BWL-Blackwell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BWM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bowman",
            "name": "[BWM] - Bowman - United States",
            "searchName": "BWM-Bowman-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BXA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bogalusa",
            "name": "[BXA] - Bogalusa - United States",
            "searchName": "BXA-Bogalusa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BWS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Blaine",
            "name": "[BWS] - Blaine - United States",
            "searchName": "BWS-Blaine-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ADM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ardmore",
            "name": "[ADM] - Ardmore - United States",
            "searchName": "ADM-Ardmore-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ADW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Camp Springs",
            "name": "[ADW] - Camp Springs - United States",
            "searchName": "ADW-Camp Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ADQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kodiak",
            "name": "[ADQ] - Kodiak - United States",
            "searchName": "ADQ-Kodiak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ADR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Andrews",
            "name": "[ADR] - Andrews - United States",
            "searchName": "ADR-Andrews-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ADT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ada",
            "name": "[ADT] - Ada - United States",
            "searchName": "ADT-Ada-Ada OK-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AED",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Aleneva",
            "name": "[AED] - Aleneva - United States",
            "searchName": "AED-Aleneva-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AEL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Albert Lea",
            "name": "[AEL] - Albert Lea - United States",
            "searchName": "AEL-Albert Lea-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AET",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Allakaket",
            "name": "[AET] - Allakaket - United States",
            "searchName": "AET-Allakaket-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AEX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Alexandria",
            "name": "[AEX] - Alexandria - United States",
            "searchName": "AEX-Alexandria-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BLF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Princeton",
            "name": "[BLF] - Princeton - United States",
            "searchName": "BLF-Bluefield-Princeton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BLH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Blythe",
            "name": "[BLH] - Blythe - United States",
            "searchName": "BLH-Blythe-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BLI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bellingham",
            "name": "[BLI] - Bellingham - United States",
            "searchName": "BLI-Bellingham-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BLM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Belmar - Farmingdale",
            "name": "[BLM] - Belmar - Farmingdale - United States",
            "searchName": "BLM-Belmar - Farmingdale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BMI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bloomington",
            "name": "[BMI] - Bloomington - United States",
            "searchName": "BMI-Bloomington-Normal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BML",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Berlin",
            "name": "[BML] - Berlin - United States",
            "searchName": "BML-Berlin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BMG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bloomington",
            "name": "[BMG] - Bloomington - United States",
            "searchName": "BMG-Bloomington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BMC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Brigham City",
            "name": "[BMC] - Brigham City - United States",
            "searchName": "BMC-Brigham City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BLU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Blue Canyon",
            "name": "[BLU] - Blue Canyon - United States",
            "searchName": "BLU-Blue Canyon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BLV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Belleville",
            "name": "[BLV] - Belleville - United States",
            "searchName": "BLV-Belleville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BLW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Waimanalo",
            "name": "[BLW] - Waimanalo - United States",
            "searchName": "BLW-Waimanalo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BKW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Beckley",
            "name": "[BKW] - Beckley - United States",
            "searchName": "BKW-Beckley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BKX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Brookings",
            "name": "[BKX] - Brookings - United States",
            "searchName": "BKX-Brookings-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BKT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Blackstone",
            "name": "[BKT] - Blackstone - United States",
            "searchName": "BKT-Blackstone-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BKC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Buckland",
            "name": "[BKC] - Buckland - United States",
            "searchName": "BKC-Buckland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BKD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Breckenridge",
            "name": "[BKD] - Breckenridge - United States",
            "searchName": "BKD-Breckenridge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BKE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Baker",
            "name": "[BKE] - Baker - United States",
            "searchName": "BKE-Baker-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BKF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Katmai National Park",
            "name": "[BKF] - Katmai National Park - United States",
            "searchName": "BKF-Brooks Lake-Katmai National Park-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BKG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Branson",
            "name": "[BKG] - Branson - United States",
            "searchName": "BKG-Branson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BKH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kekaha",
            "name": "[BKH] - Kekaha - United States",
            "searchName": "BKH-Kekaha-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BPI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Big Piney",
            "name": "[BPI] - Big Piney - United States",
            "searchName": "BPI-Big Piney-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BOW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bartow",
            "name": "[BOW] - Bartow - United States",
            "searchName": "BOW-Bartow-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BPA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bethpage",
            "name": "[BPA] - Bethpage - United States",
            "searchName": "BPA-Bethpage-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DUA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Durant",
            "name": "[DUA] - Durant - United States",
            "searchName": "DUA-Durant-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DUJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Du Bois",
            "name": "[DUJ] - Du Bois - United States",
            "searchName": "DUJ-Dubois-Du Bois-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DUC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Duncan",
            "name": "[DUC] - Duncan - United States",
            "searchName": "DUC-Duncan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DUF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Corolla",
            "name": "[DUF] - Corolla - United States",
            "searchName": "DUF-Corolla-Duck-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DUG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Douglas",
            "name": "[DUG] - Douglas - United States",
            "searchName": "DUG-Douglas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DVL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Devils Lake",
            "name": "[DVL] - Devils Lake - United States",
            "searchName": "DVL-Devils Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DVN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Davenport",
            "name": "[DVN] - Davenport - United States",
            "searchName": "DVN-Davenport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DUT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dutch Harbor",
            "name": "[DUT] - Dutch Harbor - United States",
            "searchName": "DUT-Dutch Harbor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ERI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Erie",
            "name": "[ERI] - Erie - United States",
            "searchName": "ERI-Erie--Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ERO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Eldred Rock",
            "name": "[ERO] - Eldred Rock - United States",
            "searchName": "ERO-Eldred Rock-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ERV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kerrville",
            "name": "[ERV] - Kerrville - United States",
            "searchName": "ERV-Kerrville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ERR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Errol",
            "name": "[ERR] - Errol - United States",
            "searchName": "ERR-Errol-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ESC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Escanaba",
            "name": "[ESC] - Escanaba - United States",
            "searchName": "ESC-Escanaba-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ESD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Eastsound",
            "name": "[ESD] - Eastsound - United States",
            "searchName": "ESD-Eastsound-East Sound-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ESW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Easton",
            "name": "[ESW] - Easton - United States",
            "searchName": "ESW-Easton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ETB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "West Bend",
            "name": "[ETB] - West Bend - United States",
            "searchName": "ETB-West Bend-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EST",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Estherville",
            "name": "[EST] - Estherville - United States",
            "searchName": "EST-Estherville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ESN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Easton",
            "name": "[ESN] - Easton - United States",
            "searchName": "ESN-Easton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ESO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Espanola",
            "name": "[ESO] - Espanola - United States",
            "searchName": "ESO-Espanola-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ESP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "East Stroudsburg",
            "name": "[ESP] - East Stroudsburg - United States",
            "searchName": "ESP-East Stroudsburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EOS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Neosho",
            "name": "[EOS] - Neosho - United States",
            "searchName": "EOS-Neosho-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EOK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Keokuk",
            "name": "[EOK] - Keokuk - United States",
            "searchName": "EOK-Keokuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EPG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Weeping Water",
            "name": "[EPG] - Weeping Water - United States",
            "searchName": "EPG-Weeping Water-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EPH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ephrata",
            "name": "[EPH] - Ephrata - United States",
            "searchName": "EPH-Ephrata-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ENA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kenai",
            "name": "[ENA] - Kenai - United States",
            "searchName": "ENA-Kenai-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ENL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Centralia",
            "name": "[ENL] - Centralia - United States",
            "searchName": "ENL-Centralia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ENN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Nenana",
            "name": "[ENN] - Nenana - United States",
            "searchName": "ENN-Nenana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ENV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wendover",
            "name": "[ENV] - Wendover - United States",
            "searchName": "ENV-Wendover-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ENW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kenosha",
            "name": "[ENW] - Kenosha - United States",
            "searchName": "ENW-Kenosha-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FAF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Eustis",
            "name": "[FAF] - Fort Eustis - United States",
            "searchName": "FAF-Fort Eustis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EYW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Key West",
            "name": "[EYW] - Key West - United States",
            "searchName": "EYW-Key West-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EYR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Yerington",
            "name": "[EYR] - Yerington - United States",
            "searchName": "EYR-Yerington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FAK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "False Island",
            "name": "[FAK] - False Island - United States",
            "searchName": "FAK-False Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FAL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Roma",
            "name": "[FAL] - Roma - United States",
            "searchName": "FAL-Roma-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FAM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Farmington",
            "name": "[FAM] - Farmington - United States",
            "searchName": "FAM-Farmington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FAR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fargo",
            "name": "[FAR] - Fargo - United States",
            "searchName": "FAR-Fargo North Dakota-Fargo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FAI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fairbanks",
            "name": "[FAI] - Fairbanks - United States",
            "searchName": "FAI-Fairbanks-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FAY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fayetteville",
            "name": "[FAY] - Fayetteville - United States",
            "searchName": "FAY-Fayetteville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FAT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fresno",
            "name": "[FAT] - Fresno - United States",
            "searchName": "FAT-Fresno-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FBG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Bragg",
            "name": "[FBG] - Fort Bragg - United States",
            "searchName": "FBG-Fort Bragg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FBL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Faribault",
            "name": "[FBL] - Faribault - United States",
            "searchName": "FBL-Faribault-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ETS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Enterprise",
            "name": "[ETS] - Enterprise - United States",
            "searchName": "ETS-Enterprise-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ETN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Eastland",
            "name": "[ETN] - Eastland - United States",
            "searchName": "ETN-Eastland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EUE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Eureka",
            "name": "[EUE] - Eureka - United States",
            "searchName": "EUE-Eureka-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EUF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Eufaula",
            "name": "[EUF] - Eufaula - United States",
            "searchName": "EUF-Eufaula-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EUG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Eugene",
            "name": "[EUG] - Eugene - United States",
            "searchName": "EUG-Eugene-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EVA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Evadale",
            "name": "[EVA] - Evadale - United States",
            "searchName": "EVA-Evadale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EVM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Eveleth",
            "name": "[EVM] - Eveleth - United States",
            "searchName": "EVM-Eveleth-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EVV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Evansville",
            "name": "[EVV] - Evansville - United States",
            "searchName": "EVV-Evansville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EVW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Evanston",
            "name": "[EVW] - Evanston - United States",
            "searchName": "EVW-Evanston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EWK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Newton",
            "name": "[EWK] - Newton - United States",
            "searchName": "EWK-Newton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EWN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "New Bern",
            "name": "[EWN] - New Bern - United States",
            "searchName": "EWN-New Bern-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EWB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "New Bedford",
            "name": "[EWB] - New Bedford - United States",
            "searchName": "EWB-Fall River/New Bedford-New Bedford-Fall River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EWD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wildman Lake",
            "name": "[EWD] - Wildman Lake - United States",
            "searchName": "EWD-Wildman Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BWG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bowling Green",
            "name": "[BWG] - Bowling Green - United States",
            "searchName": "BWG-Bowling Green-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BWC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Brawley",
            "name": "[BWC] - Brawley - United States",
            "searchName": "BWC-Brawley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BWD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Brownwood",
            "name": "[BWD] - Brownwood - United States",
            "searchName": "BWD-Brownwood-Brawnwood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BTP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Butler",
            "name": "[BTP] - Butler - United States",
            "searchName": "BTP-Butler-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BTI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Barter Island",
            "name": "[BTI] - Barter Island - United States",
            "searchName": "BTI-Barter Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BTT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bettles",
            "name": "[BTT] - Bettles - United States",
            "searchName": "BTT-Bettles-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BTR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Baton Rouge",
            "name": "[BTR] - Baton Rouge - United States",
            "searchName": "BTR-Baton Rouge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BTF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bountiful",
            "name": "[BTF] - Bountiful - United States",
            "searchName": "BTF-Bountiful-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BTL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Battle Creek",
            "name": "[BTL] - Battle Creek - United States",
            "searchName": "BTL-Battle Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BTM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Butte",
            "name": "[BTM] - Butte - United States",
            "searchName": "BTM-Butte-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BTN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bennettsville",
            "name": "[BTN] - Bennettsville - United States",
            "searchName": "BTN-Bennettsville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BUF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Buffalo",
            "name": "[BUF] - Buffalo - United States",
            "searchName": "BUF-Buffalo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BUB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Burwell",
            "name": "[BUB] - Burwell - United States",
            "searchName": "BUB-Burwell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BTY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Beatty",
            "name": "[BTY] - Beatty - United States",
            "searchName": "BTY-Beatty-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BTV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Burlington",
            "name": "[BTV] - Burlington - United States",
            "searchName": "BTV-Burlington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BUM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Butler",
            "name": "[BUM] - Butler - United States",
            "searchName": "BUM-Butler-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BUR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Burbank",
            "name": "[BUR] - Burbank - United States",
            "searchName": "BUR-Burbank-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BVD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Beaver Inlet",
            "name": "[BVD] - Beaver Inlet - United States",
            "searchName": "BVD-Beaver Inlet-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BVU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Beluga",
            "name": "[BVU] - Beluga - United States",
            "searchName": "BVU-Beluga-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BVX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Batesville",
            "name": "[BVX] - Batesville - United States",
            "searchName": "BVX-Batesville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BVY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Beverly",
            "name": "[BVY] - Beverly - United States",
            "searchName": "BVY-Beverly-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BVO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bartlesville",
            "name": "[BVO] - Bartlesville - United States",
            "searchName": "BVO-Bartlesville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BSF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pohakuloa",
            "name": "[BSF] - Pohakuloa - United States",
            "searchName": "BSF-Pohakuloa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BRY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bardstown",
            "name": "[BRY] - Bardstown - United States",
            "searchName": "BRY-Bardstown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BRW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Barrow",
            "name": "[BRW] - Barrow - United States",
            "searchName": "BRW-Barrow-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BSZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bartletts",
            "name": "[BSZ] - Bartletts - United States",
            "searchName": "BSZ-Bartletts-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BSW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Boswell Bay",
            "name": "[BSW] - Boswell Bay - United States",
            "searchName": "BSW-Boswell Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BSQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bisbee",
            "name": "[BSQ] - Bisbee - United States",
            "searchName": "BSQ-Bisbee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BSI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Blairsville",
            "name": "[BSI] - Blairsville - United States",
            "searchName": "BSI-Blairsville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BPT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Beaumont",
            "name": "[BPT] - Beaumont - United States",
            "searchName": "BPT-Beaumont-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BRO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Brownsville",
            "name": "[BRO] - Brownsville - United States",
            "searchName": "BRO-Brownsville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BRL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Burlington",
            "name": "[BRL] - Burlington - United States",
            "searchName": "BRL-Burlington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BRG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Whitesburg",
            "name": "[BRG] - Whitesburg - United States",
            "searchName": "BRG-Whitesburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BRD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Brainerd",
            "name": "[BRD] - Brainerd - United States",
            "searchName": "BRD-Brainerd-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EAN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wheatland",
            "name": "[EAN] - Wheatland - United States",
            "searchName": "EAN-Wheatland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EAA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Eagle",
            "name": "[EAA] - Eagle - United States",
            "searchName": "EAA-Eagle-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EAT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wenatchee",
            "name": "[EAT] - Wenatchee - United States",
            "searchName": "EAT-Wenatchee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EAU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Eau Claire",
            "name": "[EAU] - Eau Claire - United States",
            "searchName": "EAU-Eau Claire-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EAR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kearney",
            "name": "[EAR] - Kearney - United States",
            "searchName": "EAR-Kearney-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DYL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Doylestown",
            "name": "[DYL] - Doylestown - United States",
            "searchName": "DYL-Doylestown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DXR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Danbury",
            "name": "[DXR] - Danbury - United States",
            "searchName": "DXR-Danbury-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EDW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Edwards",
            "name": "[EDW] - Edwards - United States",
            "searchName": "EDW-Edwards-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EED",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Needles",
            "name": "[EED] - Needles - United States",
            "searchName": "EED-Needles-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EEK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Eek",
            "name": "[EEK] - Eek - United States",
            "searchName": "EEK-Eek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EFK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Newport",
            "name": "[EFK] - Newport - United States",
            "searchName": "EFK-Newport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EEN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Keene",
            "name": "[EEN] - Keene - United States",
            "searchName": "EEN-Keene NH-Keene-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EFB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Eight Fathom Bight",
            "name": "[EFB] - Eight Fathom Bight - United States",
            "searchName": "EFB-Eight Fathom Bight-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EFO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "East Fork",
            "name": "[EFO] - East Fork - United States",
            "searchName": "EFO-East Fork-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EFW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Jefferson",
            "name": "[EFW] - Jefferson - United States",
            "searchName": "EFW-Jefferson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EGE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Vail",
            "name": "[EGE] - Vail - United States",
            "searchName": "EGE-Vail/Eagle-Vail-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EDK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "El Dorado",
            "name": "[EDK] - El Dorado - United States",
            "searchName": "EDK-El Dorado-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EDE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Edenton",
            "name": "[EDE] - Edenton - United States",
            "searchName": "EDE-Edenton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EDG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Edgewood",
            "name": "[EDG] - Edgewood - United States",
            "searchName": "EDG-Edgewood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EBS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Webster City",
            "name": "[EBS] - Webster City - United States",
            "searchName": "EBS-Webster City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ECA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "East Tawas",
            "name": "[ECA] - East Tawas - United States",
            "searchName": "ECA-East Tawas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ECG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Elizabeth City",
            "name": "[ECG] - Elizabeth City - United States",
            "searchName": "ECG-Elizabeth City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ECS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Newcastle",
            "name": "[ECS] - Newcastle - United States",
            "searchName": "ECS-Newcastle-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EDA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Edna Bay",
            "name": "[EDA] - Edna Bay - United States",
            "searchName": "EDA-Edna Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EKX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Elizabethtown",
            "name": "[EKX] - Elizabethtown - United States",
            "searchName": "EKX-Elizabethtown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ELA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Eagle Lake",
            "name": "[ELA] - Eagle Lake - United States",
            "searchName": "ELA-Eagle Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ELD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "El Dorado",
            "name": "[ELD] - El Dorado - United States",
            "searchName": "ELD-El Dorado-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EKA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Eureka",
            "name": "[EKA] - Eureka - United States",
            "searchName": "EKA-Eureka-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EKI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Elkhart",
            "name": "[EKI] - Elkhart - United States",
            "searchName": "EKI-Elkhart-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EKN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Elkins",
            "name": "[EKN] - Elkins - United States",
            "searchName": "EKN-Elkins-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EKO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Elko",
            "name": "[EKO] - Elko - United States",
            "searchName": "EKO-Elko-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EGP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Eagle Pass",
            "name": "[EGP] - Eagle Pass - United States",
            "searchName": "EGP-Eagle Pass-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EGV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Eagle River",
            "name": "[EGV] - Eagle River - United States",
            "searchName": "EGV-Eagle River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EGX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Egegik",
            "name": "[EGX] - Egegik - United States",
            "searchName": "EGX-Egegik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EHM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cape Newenham",
            "name": "[EHM] - Cape Newenham - United States",
            "searchName": "EHM-Cape Newenham-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EHT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "East Hartford",
            "name": "[EHT] - East Hartford - United States",
            "searchName": "EHT-East Hartford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ELK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Elk City",
            "name": "[ELK] - Elk City - United States",
            "searchName": "ELK-Elk City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ELI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Elim",
            "name": "[ELI] - Elim - United States",
            "searchName": "ELI-Elim-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ELP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "El Paso",
            "name": "[ELP] - El Paso - United States",
            "searchName": "ELP-El Paso-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ELM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Elmira",
            "name": "[ELM] - Elmira - United States",
            "searchName": "ELM-Corning/Elmira-Elmira-Corning-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ELN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ellensburg",
            "name": "[ELN] - Ellensburg - United States",
            "searchName": "ELN-Ellensburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EMK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Emmonak",
            "name": "[EMK] - Emmonak - United States",
            "searchName": "EMK-Emmonak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EMM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kemerer",
            "name": "[EMM] - Kemerer - United States",
            "searchName": "EMM-Kemerer-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EMT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "El Monte",
            "name": "[EMT] - El Monte - United States",
            "searchName": "EMT-El Monte-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EMP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Emporia",
            "name": "[EMP] - Emporia - United States",
            "searchName": "EMP-Emporia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ELV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Elfin Cove",
            "name": "[ELV] - Elfin Cove - United States",
            "searchName": "ELV-Elfin Cove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ELW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ellamar",
            "name": "[ELW] - Ellamar - United States",
            "searchName": "ELW-Ellamar-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ELY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ely",
            "name": "[ELY] - Ely - United States",
            "searchName": "ELY-Ely-Ely Nv-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ELZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wellsville",
            "name": "[ELZ] - Wellsville - United States",
            "searchName": "ELZ-Wellsville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DNN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dalton",
            "name": "[DNN] - Dalton - United States",
            "searchName": "DNN-Dalton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DNV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Danville",
            "name": "[DNV] - Danville - United States",
            "searchName": "DNV-Danville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DNS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Denison",
            "name": "[DNS] - Denison - United States",
            "searchName": "DNS-Denison-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DOF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dora Bay",
            "name": "[DOF] - Dora Bay - United States",
            "searchName": "DOF-Dora Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DLN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dillon",
            "name": "[DLN] - Dillon - United States",
            "searchName": "DLN-Dillon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DLO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dolomi",
            "name": "[DLO] - Dolomi - United States",
            "searchName": "DLO-Dolomi-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DLS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "The Dalles",
            "name": "[DLS] - The Dalles - United States",
            "searchName": "DLS-The Dalles-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DLL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dillon",
            "name": "[DLL] - Dillon - United States",
            "searchName": "DLL-Dillon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DMN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Deming",
            "name": "[DMN] - Deming - United States",
            "searchName": "DMN-Deming-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DMO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sedalia",
            "name": "[DMO] - Sedalia - United States",
            "searchName": "DMO-Sedalia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DKK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dunkirk",
            "name": "[DKK] - Dunkirk - United States",
            "searchName": "DKK-Dunkirk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DJN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Delta Junction",
            "name": "[DJN] - Delta Junction - United States",
            "searchName": "DJN-Delta Junction-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DIO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Diomede Island",
            "name": "[DIO] - Diomede Island - United States",
            "searchName": "DIO-Diomede Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DLG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dillingham",
            "name": "[DLG] - Dillingham - United States",
            "searchName": "DLG-Dillingham-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DLH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Duluth",
            "name": "[DLH] - Duluth - United States",
            "searchName": "DLH-Duluth-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DRI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "De Ridder",
            "name": "[DRI] - De Ridder - United States",
            "searchName": "DRI-De Ridder-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DRT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Del Rio",
            "name": "[DRT] - Del Rio - United States",
            "searchName": "DRT-Del Rio-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DRU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Drummond",
            "name": "[DRU] - Drummond - United States",
            "searchName": "DRU-Drummond-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DRO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Durango",
            "name": "[DRO] - Durango - United States",
            "searchName": "DRO-Durango-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DOV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dover-Cheswold",
            "name": "[DOV] - Dover-Cheswold - United States",
            "searchName": "DOV-Dover-Cheswold-DOVER-CHESWOLD-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DPG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dugway",
            "name": "[DPG] - Dugway - United States",
            "searchName": "DPG-Dugway-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DPK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Deer Park",
            "name": "[DPK] - Deer Park - United States",
            "searchName": "DPK-Deer Park-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DRA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mercury",
            "name": "[DRA] - Mercury - United States",
            "searchName": "DRA-Mercury-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DRE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Drummond Island",
            "name": "[DRE] - Drummond Island - United States",
            "searchName": "DRE-Drummond Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DRF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Drift River",
            "name": "[DRF] - Drift River - United States",
            "searchName": "DRF-Drift River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DRG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Deering",
            "name": "[DRG] - Deering - United States",
            "searchName": "DRG-Deering-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DSM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Des Moines",
            "name": "[DSM] - Des Moines - United States",
            "searchName": "DSM-Des Moines-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DSI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Destin",
            "name": "[DSI] - Destin - United States",
            "searchName": "DSI-Destin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DSV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dansville",
            "name": "[DSV] - Dansville - United States",
            "searchName": "DSV-Dansville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DTA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Delta",
            "name": "[DTA] - Delta - United States",
            "searchName": "DTA-Delta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DTH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Death Valley",
            "name": "[DTH] - Death Valley - United States",
            "searchName": "DTH-Death Valley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DTL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Detroit Lakes",
            "name": "[DTL] - Detroit Lakes - United States",
            "searchName": "DTL-Detroit Lakes-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DTR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Decatur Island",
            "name": "[DTR] - Decatur Island - United States",
            "searchName": "DTR-Decatur Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LNY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lanai City",
            "name": "[LNY] - Lanai City - United States",
            "searchName": "LNY-Lanai City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LNR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lone Rock",
            "name": "[LNR] - Lone Rock - United States",
            "searchName": "LNR-Lone Rock-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LNS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lancaster",
            "name": "[LNS] - Lancaster - United States",
            "searchName": "LNS-Lancaster-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LNN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Willoughby",
            "name": "[LNN] - Willoughby - United States",
            "searchName": "LNN-Willoughby-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LNP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wise",
            "name": "[LNP] - Wise - United States",
            "searchName": "LNP-Wise-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LNK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lincoln",
            "name": "[LNK] - Lincoln - United States",
            "searchName": "LNK-Lincoln-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LNI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lonely",
            "name": "[LNI] - Lonely - United States",
            "searchName": "LNI-Lonely-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LQK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pickens",
            "name": "[LQK] - Pickens - United States",
            "searchName": "LQK-Pickens-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LRD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Laredo",
            "name": "[LRD] - Laredo - United States",
            "searchName": "LRD-Laredo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LRJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lemars",
            "name": "[LRJ] - Lemars - United States",
            "searchName": "LRJ-Lemars-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LRK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lincoln Rock",
            "name": "[LRK] - Lincoln Rock - United States",
            "searchName": "LRK-Lincoln Rock-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LRF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Jacksonville",
            "name": "[LRF] - Jacksonville - United States",
            "searchName": "LRF-Jacksonville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LPC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lompoc",
            "name": "[LPC] - Lompoc - United States",
            "searchName": "LPC-Lompoc-Santa Maria-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LPO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Laporte",
            "name": "[LPO] - Laporte - United States",
            "searchName": "LPO-Laporte-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LPS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lopez Island",
            "name": "[LPS] - Lopez Island - United States",
            "searchName": "LPS-Lopez Island--Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LPW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Little Port Walter",
            "name": "[LPW] - Little Port Walter - United States",
            "searchName": "LPW-Little Port Walter-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LTH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lathrop Wells",
            "name": "[LTH] - Lathrop Wells - United States",
            "searchName": "LTH-Lathrop Wells-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LSR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lost River",
            "name": "[LSR] - Lost River - United States",
            "searchName": "LSR-Lost River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LSB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lordsburg",
            "name": "[LSB] - Lordsburg - United States",
            "searchName": "LSB-Lordsburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LRU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Las Cruces",
            "name": "[LRU] - Las Cruces - United States",
            "searchName": "LRU-Las Cruces-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LRO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lathrop",
            "name": "[LRO] - Lathrop - United States",
            "searchName": "LRO-Lathrop-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LSK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lusk",
            "name": "[LSK] - Lusk - United States",
            "searchName": "LSK-Lusk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LSE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "La Crosse",
            "name": "[LSE] - La Crosse - United States",
            "searchName": "LSE-La Crosse-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LSF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Benning",
            "name": "[LSF] - Fort Benning - United States",
            "searchName": "LSF-Fort Benning-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LSN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Los Banos",
            "name": "[LSN] - Los Banos - United States",
            "searchName": "LSN-Los Banos-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LTS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Altus",
            "name": "[LTS] - Altus - United States",
            "searchName": "LTS-Altus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LTW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Leonardtown",
            "name": "[LTW] - Leonardtown - United States",
            "searchName": "LTW-Leonardtown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LUR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cape Lisburne",
            "name": "[LUR] - Cape Lisburne - United States",
            "searchName": "LUR-Cape Lisburne-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LUL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Laurel",
            "name": "[LUL] - Laurel - United States",
            "searchName": "LUL-Laurel-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LUP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kalaupapa",
            "name": "[LUP] - Kalaupapa - United States",
            "searchName": "LUP-Kalaupapa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KBK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Klag Bay",
            "name": "[KBK] - Klag Bay - United States",
            "searchName": "KBK-Klag Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KBE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bell Island",
            "name": "[KBE] - Bell Island - United States",
            "searchName": "KBE-Bell Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KBC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Birch Creek",
            "name": "[KBC] - Birch Creek - United States",
            "searchName": "KBC-Birch Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KCC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Coffman Cove",
            "name": "[KCC] - Coffman Cove - United States",
            "searchName": "KCC-Coffman Cove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KCR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Colorado Creek",
            "name": "[KCR] - Colorado Creek - United States",
            "searchName": "KCR-Colorado Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KCN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chernofski",
            "name": "[KCN] - Chernofski - United States",
            "searchName": "KCN-Chernofski-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KCL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chignik",
            "name": "[KCL] - Chignik - United States",
            "searchName": "KCL-Chignik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KGZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Glacier Creek",
            "name": "[KGZ] - Glacier Creek - United States",
            "searchName": "KGZ-Glacier Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KGX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Grayling",
            "name": "[KGX] - Grayling - United States",
            "searchName": "KGX-Grayling-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KIB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ivanof Bay",
            "name": "[KIB] - Ivanof Bay - United States",
            "searchName": "KIB-Ivanof Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KIC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "King City",
            "name": "[KIC] - King City - United States",
            "searchName": "KIC-King City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KGK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "New Koliganek",
            "name": "[KGK] - New Koliganek - United States",
            "searchName": "KGK-New Koliganek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KFP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "False Pass",
            "name": "[KFP] - False Pass - United States",
            "searchName": "KFP-False Pass-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KEU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kelly Bar",
            "name": "[KEU] - Kelly Bar - United States",
            "searchName": "KEU-Kelly Bar-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KEK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ekwok",
            "name": "[KEK] - Ekwok - United States",
            "searchName": "KEK-Ekwok-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KEH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kenmore Air Harbor",
            "name": "[KEH] - Kenmore Air Harbor - United States",
            "searchName": "KEH-Kenmore Air Harbor-Seattle-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KEB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Nanwalek",
            "name": "[KEB] - Nanwalek - United States",
            "searchName": "KEB-Nanwalek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KKA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Koyuk",
            "name": "[KKA] - Koyuk - United States",
            "searchName": "KKA-Koyuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KKB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kitoi Bay",
            "name": "[KKB] - Kitoi Bay - United States",
            "searchName": "KKB-Kitoi Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KKF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kagvik Creek",
            "name": "[KKF] - Kagvik Creek - United States",
            "searchName": "KKF-Kagvik Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KKH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kongiganak",
            "name": "[KKH] - Kongiganak - United States",
            "searchName": "KKH-Kongiganak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KKI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Akiachak",
            "name": "[KKI] - Akiachak - United States",
            "searchName": "KKI-Akiachak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KKK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kalakaket",
            "name": "[KKK] - Kalakaket - United States",
            "searchName": "KKK-Kalakaket-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KKL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Karluk Lake",
            "name": "[KKL] - Karluk Lake - United States",
            "searchName": "KKL-Karluk Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KKT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kentland",
            "name": "[KKT] - Kentland - United States",
            "searchName": "KKT-Kentland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KKU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ekuk",
            "name": "[KKU] - Ekuk - United States",
            "searchName": "KKU-Ekuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KLS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kelso",
            "name": "[KLS] - Kelso - United States",
            "searchName": "KLS-Kelso-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KLW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Klawock",
            "name": "[KLW] - Klawock - United States",
            "searchName": "KLW-Klawock-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KLG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kalskag",
            "name": "[KLG] - Kalskag - United States",
            "searchName": "KLG-Kalskag-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KLL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Levelock",
            "name": "[KLL] - Levelock - United States",
            "searchName": "KLL-Levelock-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KLP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kelp Bay",
            "name": "[KLP] - Kelp Bay - United States",
            "searchName": "KLP-Kelp Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KLN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Larsen Bay",
            "name": "[KLN] - Larsen Bay - United States",
            "searchName": "KLN-Larsen Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KNK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kakhonak",
            "name": "[KNK] - Kakhonak - United States",
            "searchName": "KNK-Kakhonak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KNB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kanab",
            "name": "[KNB] - Kanab - United States",
            "searchName": "KNB-Kanab-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KMY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Moser Bay",
            "name": "[KMY] - Moser Bay - United States",
            "searchName": "KMY-Moser Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KMO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Manokotak",
            "name": "[KMO] - Manokotak - United States",
            "searchName": "KMO-Manokotak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KNW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "New Stuyahok",
            "name": "[KNW] - New Stuyahok - United States",
            "searchName": "KNW-New Stuyahok-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KOA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kona",
            "name": "[KOA] - Kona - United States",
            "searchName": "KOA-Kona Hawaii-Kona-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KNT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kennett",
            "name": "[KNT] - Kennett - United States",
            "searchName": "KNT-Kennett-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KOT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kotlik",
            "name": "[KOT] - Kotlik - United States",
            "searchName": "KOT-Kotlik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KOY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Olga Bay",
            "name": "[KOY] - Olga Bay - United States",
            "searchName": "KOY-Olga Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KOZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ouzinkie",
            "name": "[KOZ] - Ouzinkie - United States",
            "searchName": "KOZ-Ouzinkie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KSR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sandy River",
            "name": "[KSR] - Sandy River - United States",
            "searchName": "KSR-Sandy River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KSM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Saint Marys",
            "name": "[KSM] - Saint Marys - United States",
            "searchName": "KSM-Saint Marys-St. Marys-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KPR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Port Williams",
            "name": "[KPR] - Port Williams - United States",
            "searchName": "KPR-Port Williams-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KPT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Jackpot",
            "name": "[KPT] - Jackpot - United States",
            "searchName": "KPT-Jackpot-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KPV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Perryville",
            "name": "[KPV] - Perryville - United States",
            "searchName": "KPV-Perryville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KPY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Port Bailey",
            "name": "[KPY] - Port Bailey - United States",
            "searchName": "KPY-Port Bailey-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KQA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Akutan",
            "name": "[KQA] - Akutan - United States",
            "searchName": "KQA-Akutan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KPB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Point Baker",
            "name": "[KPB] - Point Baker - United States",
            "searchName": "KPB-Point Baker-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KPC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Port Clarence",
            "name": "[KPC] - Port Clarence - United States",
            "searchName": "KPC-Port Clarence-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KPD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "King Of Prussia",
            "name": "[KPD] - King Of Prussia - United States",
            "searchName": "KPD-King of Prussia-King Of Prussia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KPN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kipnuk",
            "name": "[KPN] - Kipnuk - United States",
            "searchName": "KPN-Kipnuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KPK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Parks",
            "name": "[KPK] - Parks - United States",
            "searchName": "KPK-Parks-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KPH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pauloff Harbor",
            "name": "[KPH] - Pauloff Harbor - United States",
            "searchName": "KPH-Pauloff Harbor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KWF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Waterfall",
            "name": "[KWF] - Waterfall - United States",
            "searchName": "KWF-Waterfall-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KVC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "King Cove",
            "name": "[KVC] - King Cove - United States",
            "searchName": "KVC-King Cove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KVL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kivalina",
            "name": "[KVL] - Kivalina - United States",
            "searchName": "KVL-Kivalina-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KUK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kasigluk",
            "name": "[KUK] - Kasigluk - United States",
            "searchName": "KUK-Kasigluk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KUW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kugururok River",
            "name": "[KUW] - Kugururok River - United States",
            "searchName": "KUW-Kugururok River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KTN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ketchikan",
            "name": "[KTN] - Ketchikan - United States",
            "searchName": "KTN-Ketchikan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KTH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tikchik",
            "name": "[KTH] - Tikchik - United States",
            "searchName": "KTH-Tikchik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KTB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Thorne Bay",
            "name": "[KTB] - Thorne Bay - United States",
            "searchName": "KTB-Thorne Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KTS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Teller Mission",
            "name": "[KTS] - Teller Mission - United States",
            "searchName": "KTS-Teller Mission-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LDJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Linden",
            "name": "[LDJ] - Linden - United States",
            "searchName": "LDJ-Linden-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LDM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ludington",
            "name": "[LDM] - Ludington - United States",
            "searchName": "LDM-Ludington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LEE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Leesburg",
            "name": "[LEE] - Leesburg - United States",
            "searchName": "LEE-Leesburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LEB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "White River",
            "name": "[LEB] - White River - United States",
            "searchName": "LEB-Hanover-White River-Lebanon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LEM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lemmon",
            "name": "[LEM] - Lemmon - United States",
            "searchName": "LEM-Lemmon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LCH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lake Charles",
            "name": "[LCH] - Lake Charles - United States",
            "searchName": "LCH-Lake Charles-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LCI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Laconia",
            "name": "[LCI] - Laconia - United States",
            "searchName": "LCI-Laconia OH-Laconia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LCQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lake City",
            "name": "[LCQ] - Lake City - United States",
            "searchName": "LCQ-Lake City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LBT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lumberton",
            "name": "[LBT] - Lumberton - United States",
            "searchName": "LBT-Lumberton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LBL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Liberal",
            "name": "[LBL] - Liberal - United States",
            "searchName": "LBL-Liberal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KYK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Karluk",
            "name": "[KYK] - Karluk - United States",
            "searchName": "KYK-Karluk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KYL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Key Largo",
            "name": "[KYL] - Key Largo - United States",
            "searchName": "KYL-Key Largo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KZB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Zachar Bay",
            "name": "[KZB] - Zachar Bay - United States",
            "searchName": "KZB-Zachar Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KYU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Koyukuk",
            "name": "[KYU] - Koyukuk - United States",
            "searchName": "KYU-Koyukuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KWT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kwethluk",
            "name": "[KWT] - Kwethluk - United States",
            "searchName": "KWT-Kwethluk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KWP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "West Point",
            "name": "[KWP] - West Point - United States",
            "searchName": "KWP-West Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KWK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kwigillingok",
            "name": "[KWK] - Kwigillingok - United States",
            "searchName": "KWK-Kwigillingok-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KWN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Quinhagak",
            "name": "[KWN] - Quinhagak - United States",
            "searchName": "KWN-Quinhagak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KXA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kasaan",
            "name": "[KXA] - Kasaan - United States",
            "searchName": "KXA-Kasaan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LAA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lamar",
            "name": "[LAA] - Lamar - United States",
            "searchName": "LAA-Lamar-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KZH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kizhuyak",
            "name": "[KZH] - Kizhuyak - United States",
            "searchName": "KZH-Kizhuyak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LAF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lafayette",
            "name": "[LAF] - Lafayette - United States",
            "searchName": "LAF-Lafayette-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LBE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Latrobe",
            "name": "[LBE] - Latrobe - United States",
            "searchName": "LBE-Latrobe-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LBF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "North Platte",
            "name": "[LBF] - North Platte - United States",
            "searchName": "LBF-North Platte-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LBB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lubbock",
            "name": "[LBB] - Lubbock - United States",
            "searchName": "LBB-Lubbock-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LAW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lawton",
            "name": "[LAW] - Lawton - United States",
            "searchName": "LAW-Lawton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CMX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hancock",
            "name": "[CMX] - Hancock - United States",
            "searchName": "CMX-Hancock-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CMY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sparta",
            "name": "[CMY] - Sparta - United States",
            "searchName": "CMY-Sparta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Calipatria",
            "name": "[CLR] - Calipatria - United States",
            "searchName": "CLR-Calipatria-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chehalis",
            "name": "[CLS] - Chehalis - United States",
            "searchName": "CLS-Chehalis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DHT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dalhart",
            "name": "[DHT] - Dalhart - United States",
            "searchName": "DHT-Dalhart-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DFI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Defiance",
            "name": "[DFI] - Defiance - United States",
            "searchName": "DFI-Defiance-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DEO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dearborn",
            "name": "[DEO] - Dearborn - United States",
            "searchName": "DEO-Dearborn-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DGB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Danger Bay",
            "name": "[DGB] - Danger Bay - United States",
            "searchName": "DGB-Danger Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DEH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Decorah",
            "name": "[DEH] - Decorah - United States",
            "searchName": "DEH-Decorah-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DEC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Decatur",
            "name": "[DEC] - Decatur - United States",
            "searchName": "DEC-Decatur-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MDR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Medfra",
            "name": "[MDR] - Medfra - United States",
            "searchName": "MDR-Medfra-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MEI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Meridian",
            "name": "[MEI] - Meridian - United States",
            "searchName": "MEI-Meridian-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MEJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Meadville",
            "name": "[MEJ] - Meadville - United States",
            "searchName": "MEJ-Meadville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MEO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Manteo",
            "name": "[MEO] - Manteo - United States",
            "searchName": "MEO-Manteo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MEV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Minden",
            "name": "[MEV] - Minden - United States",
            "searchName": "MEV-Minden-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MFH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mesquite",
            "name": "[MFH] - Mesquite - United States",
            "searchName": "MFH-Mesquite-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MFI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Marshfield",
            "name": "[MFI] - Marshfield - United States",
            "searchName": "MFI-Marshfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MFD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mansfield",
            "name": "[MFD] - Mansfield - United States",
            "searchName": "MFD-Mansfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MFE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mc Allen",
            "name": "[MFE] - Mc Allen - United States",
            "searchName": "MFE-Mcallen/Mission-Mc Allen-McAllen-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MGE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Marietta",
            "name": "[MGE] - Marietta - United States",
            "searchName": "MGE-Marietta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MGC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Michigan City",
            "name": "[MGC] - Michigan City - United States",
            "searchName": "MGC-Michigan City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MFR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Medford",
            "name": "[MFR] - Medford - United States",
            "searchName": "MFR-Medford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MFV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Melfa",
            "name": "[MFV] - Melfa - United States",
            "searchName": "MFV-Melfa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MIO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Miami",
            "name": "[MIO] - Miami - United States",
            "searchName": "MIO-Miami-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MIT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Shafter",
            "name": "[MIT] - Shafter - United States",
            "searchName": "MIT-Shafter-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MIV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Millville",
            "name": "[MIV] - Millville - United States",
            "searchName": "MIV-Millville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MIW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Marshalltown",
            "name": "[MIW] - Marshalltown - United States",
            "searchName": "MIW-Marshalltown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MIE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Muncie",
            "name": "[MIE] - Muncie - United States",
            "searchName": "MIE-Muncie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MIF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Monahans",
            "name": "[MIF] - Monahans - United States",
            "searchName": "MIF-Monahans-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MJQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Jackson",
            "name": "[MJQ] - Jackson - United States",
            "searchName": "MJQ-Jackson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MGR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Moultrie",
            "name": "[MGR] - Moultrie - United States",
            "searchName": "MGR-Moultrie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MGM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Montgomery",
            "name": "[MGM] - Montgomery - United States",
            "searchName": "MGM-Montgomery-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MGI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Matagorda Island",
            "name": "[MGI] - Matagorda Island - United States",
            "searchName": "MGI-Matagorda Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MGJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Montgomery",
            "name": "[MGJ] - Montgomery - United States",
            "searchName": "MGJ-Montgomery-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MGW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Morgantown",
            "name": "[MGW] - Morgantown - United States",
            "searchName": "MGW-Morgantown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MHE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mitchell",
            "name": "[MHE] - Mitchell - United States",
            "searchName": "MHE-Mitchell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LAL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lakeland",
            "name": "[LAL] - Lakeland - United States",
            "searchName": "LAL-Lakeland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LAM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Los Alamos",
            "name": "[LAM] - Los Alamos - United States",
            "searchName": "LAM-Los Alamos-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LAN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lansing",
            "name": "[LAN] - Lansing - United States",
            "searchName": "LAN-Lansing-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JOR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Orange",
            "name": "[JOR] - Orange - United States",
            "searchName": "JOR-Orange-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JOT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Joliet",
            "name": "[JOT] - Joliet - United States",
            "searchName": "JOT-Joliet-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JPD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pasadena",
            "name": "[JPD] - Pasadena - United States",
            "searchName": "JPD-Pasadena-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JNU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Juneau",
            "name": "[JNU] - Juneau - United States",
            "searchName": "JNU-Juneau-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JNP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Newport Beach",
            "name": "[JNP] - Newport Beach - United States",
            "searchName": "JNP-Newport Beach-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JMS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Jamestown",
            "name": "[JMS] - Jamestown - United States",
            "searchName": "JMS-Jamestown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JMC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sausalito",
            "name": "[JMC] - Sausalito - United States",
            "searchName": "JMC-Sausalito-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JMH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Schaumburg",
            "name": "[JMH] - Schaumburg - United States",
            "searchName": "JMH-Schaumburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JLH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Arlington Heights",
            "name": "[JLH] - Arlington Heights - United States",
            "searchName": "JLH-Arlington Heights-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JLN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Joplin",
            "name": "[JLN] - Joplin - United States",
            "searchName": "JLN-Joplin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JKV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Jacksonville",
            "name": "[JKV] - Jacksonville - United States",
            "searchName": "JKV-Jacksonville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JLA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cooper Lodge",
            "name": "[JLA] - Cooper Lodge - United States",
            "searchName": "JLA-Cooper Lodge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JHC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Garden City",
            "name": "[JHC] - Garden City - United States",
            "searchName": "JHC-Garden City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JGX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Glendale",
            "name": "[JGX] - Glendale - United States",
            "searchName": "JGX-Glendale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JHW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Jamestown",
            "name": "[JHW] - Jamestown - United States",
            "searchName": "JHW-Jamestown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JHY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cambridge",
            "name": "[JHY] - Cambridge - United States",
            "searchName": "JHY-Cambridge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JHM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lahaina",
            "name": "[JHM] - Lahaina - United States",
            "searchName": "JHM-Kapalua Hawaii-Lahaina-Kapalua-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JRF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kapolei",
            "name": "[JRF] - Kapolei - United States",
            "searchName": "JRF-Kapolei-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JST",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Johnstown",
            "name": "[JST] - Johnstown - United States",
            "searchName": "JST-Johnstown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JSD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Stratford",
            "name": "[JSD] - Stratford - United States",
            "searchName": "JSD-Stratford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JTO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Thousand Oaks",
            "name": "[JTO] - Thousand Oaks - United States",
            "searchName": "JTO-Thousand Oaks-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KAL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kaltag",
            "name": "[KAL] - Kaltag - United States",
            "searchName": "KAL-Kaltag-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JVI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Manville",
            "name": "[JVI] - Manville - United States",
            "searchName": "JVI-Manville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JVL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Janesville",
            "name": "[JVL] - Janesville - United States",
            "searchName": "JVL-Janesville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KAE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kake",
            "name": "[KAE] - Kake - United States",
            "searchName": "KAE-Kake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JXN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Jackson",
            "name": "[JXN] - Jackson - United States",
            "searchName": "JXN-Jackson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IRK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kirksville",
            "name": "[IRK] - Kirksville - United States",
            "searchName": "IRK-Kirksville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IRS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sturgis",
            "name": "[IRS] - Sturgis - United States",
            "searchName": "IRS-Sturgis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ISL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Isabel Pass",
            "name": "[ISL] - Isabel Pass - United States",
            "searchName": "ISL-Isabel Pass-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ISM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kissimmee",
            "name": "[ISM] - Kissimmee - United States",
            "searchName": "ISM-Kissimmee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ISN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Williston",
            "name": "[ISN] - Williston - United States",
            "searchName": "ISN-Williston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ISO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kinston",
            "name": "[ISO] - Kinston - United States",
            "searchName": "ISO-Kinston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ISP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Islip",
            "name": "[ISP] - Islip - United States",
            "searchName": "ISP-Islip-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ISQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Manistique",
            "name": "[ISQ] - Manistique - United States",
            "searchName": "ISQ-Manistique-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ISS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wiscasset",
            "name": "[ISS] - Wiscasset - United States",
            "searchName": "ISS-Wiscasset-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ISW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wisconsin Rapids",
            "name": "[ISW] - Wisconsin Rapids - United States",
            "searchName": "ISW-Wisconsin Rapids-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ITH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ithaca",
            "name": "[ITH] - Ithaca - United States",
            "searchName": "ITH-Ithaca-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IYK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Inyokern",
            "name": "[IYK] - Inyokern - United States",
            "searchName": "IYK-Inyokern-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JAC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Jackson",
            "name": "[JAC] - Jackson - United States",
            "searchName": "JAC-Jackson-Jackson Hole-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JAN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Jackson",
            "name": "[JAN] - Jackson - United States",
            "searchName": "JAN-Jackson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JAS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Jasper",
            "name": "[JAS] - Jasper - United States",
            "searchName": "JAS-Jasper-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IWD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ironwood",
            "name": "[IWD] - Ironwood - United States",
            "searchName": "IWD-Ironwood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IVH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ivishak",
            "name": "[IVH] - Ivishak - United States",
            "searchName": "IVH-Ivishak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JEF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Jefferson City",
            "name": "[JEF] - Jefferson City - United States",
            "searchName": "JEF-Jefferson City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JDN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Jordan",
            "name": "[JDN] - Jordan - United States",
            "searchName": "JDN-Jordan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JDY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Downey",
            "name": "[JDY] - Downey - United States",
            "searchName": "JDY-Downey-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JEM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Emeryville",
            "name": "[JEM] - Emeryville - United States",
            "searchName": "JEM-Emeryville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JFN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Jefferson",
            "name": "[JFN] - Jefferson - United States",
            "searchName": "JFN-Jefferson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JAX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Jacksonville",
            "name": "[JAX] - Jacksonville - United States",
            "searchName": "JAX-Jacksonville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JBK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Berkeley",
            "name": "[JBK] - Berkeley - United States",
            "searchName": "JBK-Berkeley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JBR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Jonesboro",
            "name": "[JBR] - Jonesboro - United States",
            "searchName": "JBR-Jonesboro Arkansas-Jonesboro-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JBS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pleasanton",
            "name": "[JBS] - Pleasanton - United States",
            "searchName": "JBS-Pleasanton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JCY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Johnson",
            "name": "[JCY] - Johnson - United States",
            "searchName": "JCY-Johnson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JDA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "John Day",
            "name": "[JDA] - John Day - United States",
            "searchName": "JDA-John Day-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JCT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Junction",
            "name": "[JCT] - Junction - United States",
            "searchName": "JCT-Junction-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IFA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Iowa Falls",
            "name": "[IFA] - Iowa Falls - United States",
            "searchName": "IFA-Iowa Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IDP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Independence",
            "name": "[IDP] - Independence - United States",
            "searchName": "IDP-Independence-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IDG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ida Grove",
            "name": "[IDG] - Ida Grove - United States",
            "searchName": "IDG-Ida Grove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IDH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Grangeville",
            "name": "[IDH] - Grangeville - United States",
            "searchName": "IDH-Grangeville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IDI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Indiana",
            "name": "[IDI] - Indiana - United States",
            "searchName": "IDI-Indiana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IFP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bullhead City",
            "name": "[IFP] - Bullhead City - United States",
            "searchName": "IFP-Bullhead City-Bullhead City\\/Laughlin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IGG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Igiugig",
            "name": "[IGG] - Igiugig - United States",
            "searchName": "IGG-Igiugig-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IGM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kingman",
            "name": "[IGM] - Kingman - United States",
            "searchName": "IGM-Kingman-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IKK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kankakee",
            "name": "[IKK] - Kankakee - United States",
            "searchName": "IKK-Kankakee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IJX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Jacksonville",
            "name": "[IJX] - Jacksonville - United States",
            "searchName": "IJX-Jacksonville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IKB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wilkesboro",
            "name": "[IKB] - Wilkesboro - United States",
            "searchName": "IKB-Wilkesboro-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IKO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Nikolski",
            "name": "[IKO] - Nikolski - United States",
            "searchName": "IKO-Nikolski-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ILE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Killeen",
            "name": "[ILE] - Killeen - United States",
            "searchName": "ILE-Killeen-Fort Hood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ILG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wilmington",
            "name": "[ILG] - Wilmington - United States",
            "searchName": "ILG-Wilmington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ILI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Iliamna",
            "name": "[ILI] - Iliamna - United States",
            "searchName": "ILI-Iliamna-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ILL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Willmar",
            "name": "[ILL] - Willmar - United States",
            "searchName": "ILL-Willmar-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ILM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wilmington",
            "name": "[ILM] - Wilmington - United States",
            "searchName": "ILM-Wilmington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ILN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wilmington",
            "name": "[ILN] - Wilmington - United States",
            "searchName": "ILN-Wilmington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IML",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Imperial",
            "name": "[IML] - Imperial - United States",
            "searchName": "IML-Imperial-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IMM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Immokalee",
            "name": "[IMM] - Immokalee - United States",
            "searchName": "IMM-Immokalee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HOP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hopkinsville",
            "name": "[HOP] - Hopkinsville - United States",
            "searchName": "HOP-Hopkinsville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HST",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Homestead",
            "name": "[HST] - Homestead - United States",
            "searchName": "HST-Homestead-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HSV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Huntsville",
            "name": "[HSV] - Huntsville - United States",
            "searchName": "HSV-Huntsville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HSL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Huslia",
            "name": "[HSL] - Huslia - United States",
            "searchName": "HSL-Huslia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HSP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hot Springs",
            "name": "[HSP] - Hot Springs - United States",
            "searchName": "HSP-Hot Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HTH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hawthorne",
            "name": "[HTH] - Hawthorne - United States",
            "searchName": "HTH-Hawthorne-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HTL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Houghton",
            "name": "[HTL] - Houghton - United States",
            "searchName": "HTL-Houghton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HTO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "East Hampton",
            "name": "[HTO] - East Hampton - United States",
            "searchName": "HTO-East Hampton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HUJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hugo",
            "name": "[HUJ] - Hugo - United States",
            "searchName": "HUJ-Hugo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HUL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Houlton",
            "name": "[HUL] - Houlton - United States",
            "searchName": "HUL-Houlton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HUM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Houma",
            "name": "[HUM] - Houma - United States",
            "searchName": "HUM-Houma-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HUF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Terre Haute",
            "name": "[HUF] - Terre Haute - United States",
            "searchName": "HUF-Terre Haute-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HUD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Humboldt",
            "name": "[HUD] - Humboldt - United States",
            "searchName": "HUD-Humboldt-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HTS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Huntington",
            "name": "[HTS] - Huntington - United States",
            "searchName": "HTS-Huntington/Ashland-Huntington-Ashland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HTV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Huntsville",
            "name": "[HTV] - Huntsville - United States",
            "searchName": "HTV-Huntsville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HTW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Huntington",
            "name": "[HTW] - Huntington - United States",
            "searchName": "HTW-Huntington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ICL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Clarinda",
            "name": "[ICL] - Clarinda - United States",
            "searchName": "ICL-Clarinda-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ICS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cascade",
            "name": "[ICS] - Cascade - United States",
            "searchName": "ICS-Cascade-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ICT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wichita",
            "name": "[ICT] - Wichita - United States",
            "searchName": "ICT-Wichita-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ICY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Icy Bay",
            "name": "[ICY] - Icy Bay - United States",
            "searchName": "ICY-Icy Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IDA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Idaho Falls",
            "name": "[IDA] - Idaho Falls - United States",
            "searchName": "IDA-Idaho Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IAG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Niagara Falls",
            "name": "[IAG] - Niagara Falls - United States",
            "searchName": "IAG-Niagara Falls-Cataratas del Niagara-Chutes du Niagara-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HZL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hazleton",
            "name": "[HZL] - Hazleton - United States",
            "searchName": "HZL-Hazleton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HWO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hollywood",
            "name": "[HWO] - Hollywood - United States",
            "searchName": "HWO-Hollywood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IAN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kiana",
            "name": "[IAN] - Kiana - United States",
            "searchName": "IAN-Kiana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HUS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hughes",
            "name": "[HUS] - Hughes - United States",
            "searchName": "HUS-Hughes-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HUT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hutchinson",
            "name": "[HUT] - Hutchinson - United States",
            "searchName": "HUT-Hutchinson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HVE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hanksville",
            "name": "[HVE] - Hanksville - United States",
            "searchName": "HVE-Hanksville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HVN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "New Haven",
            "name": "[HVN] - New Haven - United States",
            "searchName": "HVN-New Haven-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HVR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Havre",
            "name": "[HVR] - Havre - United States",
            "searchName": "HVR-Havre-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HVS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hartsville",
            "name": "[HVS] - Hartsville - United States",
            "searchName": "HVS-Hartsville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HWD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hayward",
            "name": "[HWD] - Hayward - United States",
            "searchName": "HWD-Hayward-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HWI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hawk Inlet",
            "name": "[HWI] - Hawk Inlet - United States",
            "searchName": "HWI-Hawk Inlet-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HYA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hyannis",
            "name": "[HYA] - Hyannis - United States",
            "searchName": "HYA-Hyannis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HYG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hydaburg",
            "name": "[HYG] - Hydaburg - United States",
            "searchName": "HYG-Hydaburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HYL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hollis",
            "name": "[HYL] - Hollis - United States",
            "searchName": "HYL-Hollis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HYR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hayward",
            "name": "[HYR] - Hayward - United States",
            "searchName": "HYR-Hayward-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HYS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hays",
            "name": "[HYS] - Hays - United States",
            "searchName": "HYS-Hays-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HMS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Homeshore",
            "name": "[HMS] - Homeshore - United States",
            "searchName": "HMS-Homeshore-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HMT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hemet",
            "name": "[HMT] - Hemet - United States",
            "searchName": "HMT-Hemet-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HNM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hana",
            "name": "[HNM] - Hana - United States",
            "searchName": "HNM-Hana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HNH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hoonah",
            "name": "[HNH] - Hoonah - United States",
            "searchName": "HNH-Hoonah-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HNB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Huntingburg",
            "name": "[HNB] - Huntingburg - United States",
            "searchName": "HNB-Huntingburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HNC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hatteras",
            "name": "[HNC] - Hatteras - United States",
            "searchName": "HNC-Hatteras-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HNE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tahneta Pass Lodge",
            "name": "[HNE] - Tahneta Pass Lodge - United States",
            "searchName": "HNE-Tahneta Pass Lodge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HOL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Holikachu",
            "name": "[HOL] - Holikachu - United States",
            "searchName": "HOL-Holikachu-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HOM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Homer",
            "name": "[HOM] - Homer - United States",
            "searchName": "HOM-Homer-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HON",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Huron",
            "name": "[HON] - Huron - United States",
            "searchName": "HON-Huron South Dakota-Huron-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HNS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Haines",
            "name": "[HNS] - Haines - United States",
            "searchName": "HNS-Haines-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HNX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hanna",
            "name": "[HNX] - Hanna - United States",
            "searchName": "HNX-Hanna-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HOB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hobbs",
            "name": "[HOB] - Hobbs - United States",
            "searchName": "HOB-Hobbs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HIO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hillsboro",
            "name": "[HIO] - Hillsboro - United States",
            "searchName": "HIO-Hillsboro-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HKB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Healy Lake",
            "name": "[HKB] - Healy Lake - United States",
            "searchName": "HKB-Healy Lake-Healy G-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HLG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wheeling",
            "name": "[HLG] - Wheeling - United States",
            "searchName": "HLG-Wheeling-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HLI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hollister",
            "name": "[HLI] - Hollister - United States",
            "searchName": "HLI-Hollister-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HKY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hickory",
            "name": "[HKY] - Hickory - United States",
            "searchName": "HKY-Hickory-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HLB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Batesville",
            "name": "[HLB] - Batesville - United States",
            "searchName": "HLB-Batesville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HLC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hill City",
            "name": "[HLC] - Hill City - United States",
            "searchName": "HLC-Hill City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HLM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Holland",
            "name": "[HLM] - Holland - United States",
            "searchName": "HLM-Holland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HLN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Helena",
            "name": "[HLN] - Helena - United States",
            "searchName": "HLN-Helena-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HBO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Humboldt",
            "name": "[HBO] - Humboldt - United States",
            "searchName": "HBO-Humboldt-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HBR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hobart",
            "name": "[HBR] - Hobart - United States",
            "searchName": "HBR-Hobart-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HCA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Big Spring",
            "name": "[HCA] - Big Spring - United States",
            "searchName": "HCA-Big Spring-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HCB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Shoal Cove",
            "name": "[HCB] - Shoal Cove - United States",
            "searchName": "HCB-Shoal Cove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HCC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hudson",
            "name": "[HCC] - Hudson - United States",
            "searchName": "HCC-Hudson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HDE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Holdrege",
            "name": "[HDE] - Holdrege - United States",
            "searchName": "HDE-Holdrege-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HCR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Holy Cross",
            "name": "[HCR] - Holy Cross - United States",
            "searchName": "HCR-Holy Cross-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HCW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cheraw",
            "name": "[HCW] - Cheraw - United States",
            "searchName": "HCW-Cheraw-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HDA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hidden Falls",
            "name": "[HDA] - Hidden Falls - United States",
            "searchName": "HDA-Hidden Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HES",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hermiston",
            "name": "[HES] - Hermiston - United States",
            "searchName": "HES-Hermiston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HED",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Herendeen",
            "name": "[HED] - Herendeen - United States",
            "searchName": "HED-Herendeen-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HEE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Helena",
            "name": "[HEE] - Helena - United States",
            "searchName": "HEE-Helena-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HDN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hayden",
            "name": "[HDN] - Hayden - United States",
            "searchName": "HDN-Hayden-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HDH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Oahu",
            "name": "[HDH] - Oahu - United States",
            "searchName": "HDH-Oahu-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HHR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hawthorne",
            "name": "[HHR] - Hawthorne - United States",
            "searchName": "HHR-Hawthorne-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HGZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hogatza",
            "name": "[HGZ] - Hogatza - United States",
            "searchName": "HGZ-Hogatza-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HHH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hilton Head",
            "name": "[HHH] - Hilton Head - United States",
            "searchName": "HHH-Hilton Head-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HHI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wahiawa",
            "name": "[HHI] - Wahiawa - United States",
            "searchName": "HHI-Wahiawa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HII",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lake Havasu City",
            "name": "[HII] - Lake Havasu City - United States",
            "searchName": "HII-Lake Havasu City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HIB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chisholm",
            "name": "[HIB] - Chisholm - United States",
            "searchName": "HIB-Chisholm/Hibbing-Chisholm-Hibbing-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HIE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Whitefield",
            "name": "[HIE] - Whitefield - United States",
            "searchName": "HIE-Whitefield UT-Whitefield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HGR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hagerstown",
            "name": "[HGR] - Hagerstown - United States",
            "searchName": "HGR-Hagerstown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HGT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Jolon",
            "name": "[HGT] - Jolon - United States",
            "searchName": "HGT-Jolon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HFF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hoffman",
            "name": "[HFF] - Hoffman - United States",
            "searchName": "HFF-Hoffman-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HFD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hartford",
            "name": "[HFD] - Hartford - United States",
            "searchName": "HFD-Hartford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HEZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Natchez",
            "name": "[HEZ] - Natchez - United States",
            "searchName": "HEZ-Natchez-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GUF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gulf Shores",
            "name": "[GUF] - Gulf Shores - United States",
            "searchName": "GUF-Gulf Shores-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GUC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gunnison",
            "name": "[GUC] - Gunnison - United States",
            "searchName": "GUC-Gunnison-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GTP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Grants Pass",
            "name": "[GTP] - Grants Pass - United States",
            "searchName": "GTP-Grants Pass-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GTF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Great Falls",
            "name": "[GTF] - Great Falls - United States",
            "searchName": "GTF-Great Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GTG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Grantsburg",
            "name": "[GTG] - Grantsburg - United States",
            "searchName": "GTG-Grantsburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GTY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gettysburg",
            "name": "[GTY] - Gettysburg - United States",
            "searchName": "GTY-Gettysburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GUS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Peru",
            "name": "[GUS] - Peru - United States",
            "searchName": "GUS-Peru-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GUP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gallup",
            "name": "[GUP] - Gallup - United States",
            "searchName": "GUP-Gallup-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GUY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Guymon",
            "name": "[GUY] - Guymon - United States",
            "searchName": "GUY-Guymon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GVE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gordonsville",
            "name": "[GVE] - Gordonsville - United States",
            "searchName": "GVE-Gordonsville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GST",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gustavus",
            "name": "[GST] - Gustavus - United States",
            "searchName": "GST-Gustavus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GSH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Goshen",
            "name": "[GSH] - Goshen - United States",
            "searchName": "GSH-Goshen-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GSO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Greensboro",
            "name": "[GSO] - Greensboro - United States",
            "searchName": "GSO-Greensboro-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GSP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Greenville",
            "name": "[GSP] - Greenville - United States",
            "searchName": "GSP-Greenville/Greer-Greenville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GSB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Goldsboro",
            "name": "[GSB] - Goldsboro - United States",
            "searchName": "GSB-Goldsboro-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GRI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Grand Island",
            "name": "[GRI] - Grand Island - United States",
            "searchName": "GRI-Grand Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GRM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Grand Marais",
            "name": "[GRM] - Grand Marais - United States",
            "searchName": "GRM-Grand Marais-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GRN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gordon",
            "name": "[GRN] - Gordon - United States",
            "searchName": "GRN-Gordon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GRR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Grand Rapids",
            "name": "[GRR] - Grand Rapids - United States",
            "searchName": "GRR-Grand Rapids-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HAE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Havasupai",
            "name": "[HAE] - Havasupai - United States",
            "searchName": "HAE-Havasupai-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HAF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Half Moon",
            "name": "[HAF] - Half Moon - United States",
            "searchName": "HAF-Half Moon Bay-Half Moon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HAB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hamilton",
            "name": "[HAB] - Hamilton - United States",
            "searchName": "HAB-Hamilton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HAI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Three Rivers",
            "name": "[HAI] - Three Rivers - United States",
            "searchName": "HAI-Three Rivers-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HBC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hanus Bay",
            "name": "[HBC] - Hanus Bay - United States",
            "searchName": "HBC-Hanus Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HBG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hattiesburg",
            "name": "[HBG] - Hattiesburg - United States",
            "searchName": "HBG-Hattiesburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HBH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hobart Bay",
            "name": "[HBH] - Hobart Bay - United States",
            "searchName": "HBH-Hobart Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HBK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Holbrook",
            "name": "[HBK] - Holbrook - United States",
            "searchName": "HBK-Holbrook-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HAY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Haycock",
            "name": "[HAY] - Haycock - United States",
            "searchName": "HAY-Haycock-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HAR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Harrisburg",
            "name": "[HAR] - Harrisburg - United States",
            "searchName": "HAR-Harrisburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HAO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hamilton",
            "name": "[HAO] - Hamilton - United States",
            "searchName": "HAO-Hamilton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GVL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gainesville",
            "name": "[GVL] - Gainesville - United States",
            "searchName": "GVL-Gainesville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GVT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Greenville",
            "name": "[GVT] - Greenville - United States",
            "searchName": "GVT-Greenville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GVW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Grandview",
            "name": "[GVW] - Grandview - United States",
            "searchName": "GVW-Grandview-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GWV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Glendale",
            "name": "[GWV] - Glendale - United States",
            "searchName": "GWV-Glendale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GWO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Greenwood",
            "name": "[GWO] - Greenwood - United States",
            "searchName": "GWO-Greenwood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GWS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Glenwood Springs",
            "name": "[GWS] - Glenwood Springs - United States",
            "searchName": "GWS-Glenwood Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GXY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Greeley",
            "name": "[GXY] - Greeley - United States",
            "searchName": "GXY-Greeley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GYY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gary",
            "name": "[GYY] - Gary - United States",
            "searchName": "GYY-Chicago IL-Gary-Chicago-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GYR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Goodyear",
            "name": "[GYR] - Goodyear - United States",
            "searchName": "GYR-Goodyear-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GJT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Grand Junction",
            "name": "[GJT] - Grand Junction - United States",
            "searchName": "GJT-Grand Junction-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GKT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gatlinburg",
            "name": "[GKT] - Gatlinburg - United States",
            "searchName": "GKT-Gatlinburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GKN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gulkana",
            "name": "[GKN] - Gulkana - United States",
            "searchName": "GKN-Gulkana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GLQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Glennallen",
            "name": "[GLQ] - Glennallen - United States",
            "searchName": "GLQ-Glennallen-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GLR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gaylord",
            "name": "[GLR] - Gaylord - United States",
            "searchName": "GLR-Gaylord-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GLS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Galveston",
            "name": "[GLS] - Galveston - United States",
            "searchName": "GLS-Galveston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GLB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Globe",
            "name": "[GLB] - Globe - United States",
            "searchName": "GLB-Globe-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GLD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Goodland",
            "name": "[GLD] - Goodland - United States",
            "searchName": "GLD-Goodland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GLE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gainesville",
            "name": "[GLE] - Gainesville - United States",
            "searchName": "GLE-Gainesville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GLH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Greenville",
            "name": "[GLH] - Greenville - United States",
            "searchName": "GLH-Greenville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GGE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Georgetown",
            "name": "[GGE] - Georgetown - United States",
            "searchName": "GGE-Georgetown-Myrtle Beach-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GGG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kilgore",
            "name": "[GGG] - Kilgore - United States",
            "searchName": "GGG-Longview-Kilgore-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GIF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Winter Haven",
            "name": "[GIF] - Winter Haven - United States",
            "searchName": "GIF-Winter Haven-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GGW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Glasgow",
            "name": "[GGW] - Glasgow - United States",
            "searchName": "GGW-Glasgow-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GHM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Centerville",
            "name": "[GHM] - Centerville - United States",
            "searchName": "GHM-Centerville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GMT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Granite Mountain",
            "name": "[GMT] - Granite Mountain - United States",
            "searchName": "GMT-Granite Mountain-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GMV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Monument Valley Gldngs",
            "name": "[GMV] - Monument Valley Gldngs - United States",
            "searchName": "GMV-Monument Valley Gldngs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GNF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Quincy",
            "name": "[GNF] - Quincy - United States",
            "searchName": "GNF-Quincy-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GNG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gooding",
            "name": "[GNG] - Gooding - United States",
            "searchName": "GNG-Gooding-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GNT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Grants",
            "name": "[GNT] - Grants - United States",
            "searchName": "GNT-Grants-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GNU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Goodnews Bay",
            "name": "[GNU] - Goodnews Bay - United States",
            "searchName": "GNU-Goodnews Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GNV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gainesville",
            "name": "[GNV] - Gainesville - United States",
            "searchName": "GNV-Gainesville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GLV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Golovin",
            "name": "[GLV] - Golovin - United States",
            "searchName": "GLV-Golovin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GLW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Glasgow",
            "name": "[GLW] - Glasgow - United States",
            "searchName": "GLW-Glasgow-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GOK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Guthrie",
            "name": "[GOK] - Guthrie - United States",
            "searchName": "GOK-Guthrie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GOL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gold Beach",
            "name": "[GOL] - Gold Beach - United States",
            "searchName": "GOL-Gold Beach-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SZL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kansas",
            "name": "[SZL] - Kansas - United States",
            "searchName": "SZL-Warrenburg-Kansas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SZP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Santa Paula",
            "name": "[SZP] - Santa Paula - United States",
            "searchName": "SZP-Santa Paula-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SYV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sylvester",
            "name": "[SYV] - Sylvester - United States",
            "searchName": "SYV-Sylvester-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SYR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Syracuse",
            "name": "[SYR] - Syracuse - United States",
            "searchName": "SYR-Syracuse-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SYN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Stanton",
            "name": "[SYN] - Stanton - United States",
            "searchName": "SYN-Stanton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SYA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Shemya",
            "name": "[SYA] - Shemya - United States",
            "searchName": "SYA-Shemya-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SYB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Seal Bay",
            "name": "[SYB] - Seal Bay - United States",
            "searchName": "SYB-Seal Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SYI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Shelbyville",
            "name": "[SYI] - Shelbyville - United States",
            "searchName": "SYI-Shelbyville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SYL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "San Miguel",
            "name": "[SYL] - San Miguel - United States",
            "searchName": "SYL-San Miguel-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SXY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sidney",
            "name": "[SXY] - Sidney - United States",
            "searchName": "SXY-Sidney-Titreyengol-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SXP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sheldon Point",
            "name": "[SXP] - Sheldon Point - United States",
            "searchName": "SXP-Sheldon Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SXQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Soldotna",
            "name": "[SXQ] - Soldotna - United States",
            "searchName": "SXQ-Soldotna-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SWW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sweetwater",
            "name": "[SWW] - Sweetwater - United States",
            "searchName": "SWW-Sweetwater-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TNU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Newton",
            "name": "[TNU] - Newton - United States",
            "searchName": "TNU-Newton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TNP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Twentynine Palms",
            "name": "[TNP] - Twentynine Palms - United States",
            "searchName": "TNP-Twentynine Palms-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TOC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Toccoa",
            "name": "[TOC] - Toccoa - United States",
            "searchName": "TOC-Toccoa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TOA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Torrance",
            "name": "[TOA] - Torrance - United States",
            "searchName": "TOA-Torrance-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TOR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Torrington",
            "name": "[TOR] - Torrington - United States",
            "searchName": "TOR-Torrington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TOG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Togiak Village",
            "name": "[TOG] - Togiak Village - United States",
            "searchName": "TOG-Togiak Village-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TOL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Toledo",
            "name": "[TOL] - Toledo - United States",
            "searchName": "TOL-Toledo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TOP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Topeka",
            "name": "[TOP] - Topeka - United States",
            "searchName": "TOP-Topeka-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TPH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tonopah",
            "name": "[TPH] - Tonopah - United States",
            "searchName": "TPH-Tonopah-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TOI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Troy",
            "name": "[TOI] - Troy - United States",
            "searchName": "TOI-Troy-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TPA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tampa",
            "name": "[TPA] - Tampa - United States",
            "searchName": "TPA-Tampa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TPL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Temple",
            "name": "[TPL] - Temple - United States",
            "searchName": "TPL-Temple-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TPO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tanalian Point",
            "name": "[TPO] - Tanalian Point - United States",
            "searchName": "TPO-Tanalian Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TRH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Trona",
            "name": "[TRH] - Trona - United States",
            "searchName": "TRH-Trona-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TRI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Blountville",
            "name": "[TRI] - Blountville - United States",
            "searchName": "TRI-Blountville TN-Blountville-Bristol-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TNK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tununak",
            "name": "[TNK] - Tununak - United States",
            "searchName": "TNK-Tununak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TNC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tin City",
            "name": "[TNC] - Tin City - United States",
            "searchName": "TNC-Tin City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TKL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Taku Lodge",
            "name": "[TKL] - Taku Lodge - United States",
            "searchName": "TKL-Taku Lodge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TLA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Teller",
            "name": "[TLA] - Teller - United States",
            "searchName": "TLA-Teller-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TLH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tallahassee",
            "name": "[TLH] - Tallahassee - United States",
            "searchName": "TLH-Tallahassee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TLJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tatalina",
            "name": "[TLJ] - Tatalina - United States",
            "searchName": "TLJ-Tatalina-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TLF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Telida",
            "name": "[TLF] - Telida - United States",
            "searchName": "TLF-Telida-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TMA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tifton",
            "name": "[TMA] - Tifton - United States",
            "searchName": "TMA-Tifton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TLT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tuluksak",
            "name": "[TLT] - Tuluksak - United States",
            "searchName": "TLT-Tuluksak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TLR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tulare",
            "name": "[TLR] - Tulare - United States",
            "searchName": "TLR-Tulare-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TKI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tokeen",
            "name": "[TKI] - Tokeen - United States",
            "searchName": "TKI-Tokeen-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TKJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tok",
            "name": "[TKJ] - Tok - United States",
            "searchName": "TKJ-Tok Alaska-Tok-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TKA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Talkeetna",
            "name": "[TKA] - Talkeetna - United States",
            "searchName": "TKA-Talkeetna-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TKE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tenakee Springs",
            "name": "[TKE] - Tenakee Springs - United States",
            "searchName": "TKE-Tenakee Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TKF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Truckee",
            "name": "[TKF] - Truckee - United States",
            "searchName": "TKF-Truckee-Reno-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TIW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tacoma",
            "name": "[TIW] - Tacoma - United States",
            "searchName": "TIW-Tacoma-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TIX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Titusville",
            "name": "[TIX] - Titusville - United States",
            "searchName": "TIX-Titusville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "THP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Thermopolis",
            "name": "[THP] - Thermopolis - United States",
            "searchName": "THP-Thermopolis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "THV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "York",
            "name": "[THV] - York - United States",
            "searchName": "THV-York-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "THM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Thompsonfield",
            "name": "[THM] - Thompsonfield - United States",
            "searchName": "THM-Thompsonfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "THA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tullahoma",
            "name": "[THA] - Tullahoma - United States",
            "searchName": "THA-Tullahoma-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TGE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tuskegee",
            "name": "[TGE] - Tuskegee - United States",
            "searchName": "TGE-Tuskegee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TEX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Telluride",
            "name": "[TEX] - Telluride - United States",
            "searchName": "TEX-Telluride-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TEK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tatitlek",
            "name": "[TEK] - Tatitlek - United States",
            "searchName": "TEK-Tatitlek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TEH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tetlin",
            "name": "[TEH] - Tetlin - United States",
            "searchName": "TEH-Tetlin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TEB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Teterboro",
            "name": "[TEB] - Teterboro - United States",
            "searchName": "TEB-Teterboro-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TDO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Toledo",
            "name": "[TDO] - Toledo - United States",
            "searchName": "TDO-Toledo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TRX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Trenton",
            "name": "[TRX] - Trenton - United States",
            "searchName": "TRX-Trenton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TRT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tremonton",
            "name": "[TRT] - Tremonton - United States",
            "searchName": "TRT-Tremonton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TRL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Terrell",
            "name": "[TRL] - Terrell - United States",
            "searchName": "TRL-Terrell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TRM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Thermal",
            "name": "[TRM] - Thermal - United States",
            "searchName": "TRM-Thermal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TRP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tree Point",
            "name": "[TRP] - Tree Point - United States",
            "searchName": "TRP-Tree Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TSM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Taos",
            "name": "[TSM] - Taos - United States",
            "searchName": "TSM-Taos-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TSP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tehachapi",
            "name": "[TSP] - Tehachapi - United States",
            "searchName": "TSP-Tehachapi-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TSG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tanacross",
            "name": "[TSG] - Tanacross - United States",
            "searchName": "TSG-Tanacross-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TTD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Troutdale",
            "name": "[TTD] - Troutdale - United States",
            "searchName": "TTD-Troutdale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TUL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tulsa",
            "name": "[TUL] - Tulsa - United States",
            "searchName": "TUL-Tulsa Oklahoma-Tulsa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TTO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Britton",
            "name": "[TTO] - Britton - United States",
            "searchName": "TTO-Britton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TXK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Texarkana",
            "name": "[TXK] - Texarkana - United States",
            "searchName": "TXK-Texarkana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TYE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tyonek",
            "name": "[TYE] - Tyonek - United States",
            "searchName": "TYE-Tyonek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TWD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Port Townsend",
            "name": "[TWD] - Port Townsend - United States",
            "searchName": "TWD-Port Townsend-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TWE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Taylor",
            "name": "[TWE] - Taylor - United States",
            "searchName": "TWE-Taylor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TWF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Twin Falls",
            "name": "[TWF] - Twin Falls - United States",
            "searchName": "TWF-Twin Falls Idaho-Twin Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TUP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tupelo",
            "name": "[TUP] - Tupelo - United States",
            "searchName": "TUP-Tupelo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TUS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tucson",
            "name": "[TUS] - Tucson - United States",
            "searchName": "TUS-Tucson Arizona-Tucson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TWA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Twin Hills",
            "name": "[TWA] - Twin Hills - United States",
            "searchName": "TWA-Twin Hills-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TVC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Traverse City",
            "name": "[TVC] - Traverse City - United States",
            "searchName": "TVC-Traverse City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TVF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Thief River Falls",
            "name": "[TVF] - Thief River Falls - United States",
            "searchName": "TVF-Thief River Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TVI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Thomasville",
            "name": "[TVI] - Thomasville - United States",
            "searchName": "TVI-Thomasville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GRD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Greenwood",
            "name": "[GRD] - Greenwood - United States",
            "searchName": "GRD-Greenwood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GRE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Greenville",
            "name": "[GRE] - Greenville - United States",
            "searchName": "GRE-Greenville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GRB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Green Bay",
            "name": "[GRB] - Green Bay - United States",
            "searchName": "GRB-Green Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GPT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gulfport",
            "name": "[GPT] - Gulfport - United States",
            "searchName": "GPT-Gulfport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GPZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Grand Rapids",
            "name": "[GPZ] - Grand Rapids - United States",
            "searchName": "GPZ-Grand Rapids-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GQQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Galion",
            "name": "[GQQ] - Galion - United States",
            "searchName": "GQQ-Galion-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FWA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Wayne",
            "name": "[FWA] - Fort Wayne - United States",
            "searchName": "FWA-Fort Wayne-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FWL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Farewell",
            "name": "[FWL] - Farewell - United States",
            "searchName": "FWL-Farewell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FTW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Worth",
            "name": "[FTW] - Fort Worth - United States",
            "searchName": "FTW-Dallas-Fort Worth-Ft Worth-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FUL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fullerton",
            "name": "[FUL] - Fullerton - United States",
            "searchName": "FUL-Fullerton-Fulleborn-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FSM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Smith",
            "name": "[FSM] - Fort Smith - United States",
            "searchName": "FSM-Fort Smith Arkansas-Fort Smith-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FSN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Sheridan",
            "name": "[FSN] - Fort Sheridan - United States",
            "searchName": "FSN-Fort Sheridan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FSD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sioux Falls",
            "name": "[FSD] - Sioux Falls - United States",
            "searchName": "FSD-Sioux Falls South Dakota-Sioux Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FSI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Sill",
            "name": "[FSI] - Fort Sill - United States",
            "searchName": "FSI-Fort Sill-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FSK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Scott",
            "name": "[FSK] - Fort Scott - United States",
            "searchName": "FSK-Fort Scott-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FST",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Stockton",
            "name": "[FST] - Fort Stockton - United States",
            "searchName": "FST-Fort Stockton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FSU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Sumner",
            "name": "[FSU] - Fort Sumner - United States",
            "searchName": "FSU-Fort Sumner-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FTK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Knox",
            "name": "[FTK] - Fort Knox - United States",
            "searchName": "FTK-Fort Knox-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FTL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fortuna Ledge",
            "name": "[FTL] - Fortuna Ledge - United States",
            "searchName": "FTL-Fortuna Ledge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GAG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gage",
            "name": "[GAG] - Gage - United States",
            "searchName": "GAG-Gage-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GAI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gaithersburg",
            "name": "[GAI] - Gaithersburg - United States",
            "searchName": "GAI-Gaithersburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GAD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gadsden",
            "name": "[GAD] - Gadsden - United States",
            "searchName": "GAD-Daggett-Gadsden-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GAB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gabbs",
            "name": "[GAB] - Gabbs - United States",
            "searchName": "GAB-Gabbs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FYU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Yukon",
            "name": "[FYU] - Fort Yukon - United States",
            "searchName": "FYU-Fort Yukon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FYV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fayetteville",
            "name": "[FYV] - Fayetteville - United States",
            "searchName": "FYV-Fayetteville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FXY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Forest City",
            "name": "[FXY] - Forest City - United States",
            "searchName": "FXY-Forest City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FYM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fayetteville",
            "name": "[FYM] - Fayetteville - United States",
            "searchName": "FYM-Fayetteville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FXM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Flaxman Island",
            "name": "[FXM] - Flaxman Island - United States",
            "searchName": "FXM-Flaxman Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GAK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gakona",
            "name": "[GAK] - Gakona - United States",
            "searchName": "GAK-Gakona-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GAL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Galena",
            "name": "[GAL] - Galena - United States",
            "searchName": "GAL-Galena-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GAM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gambell",
            "name": "[GAM] - Gambell - United States",
            "searchName": "GAM-Gambell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GDV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Glendive",
            "name": "[GDV] - Glendive - United States",
            "searchName": "GDV-Glendive-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GDW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gladwin",
            "name": "[GDW] - Gladwin - United States",
            "searchName": "GDW-Gladwin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GDM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gardner",
            "name": "[GDM] - Gardner - United States",
            "searchName": "GDM-Gardner-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GED",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Georgetown",
            "name": "[GED] - Georgetown - United States",
            "searchName": "GED-Georgetown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GEG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Spokane",
            "name": "[GEG] - Spokane - United States",
            "searchName": "GEG-Spokane-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GEK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ganes Creek",
            "name": "[GEK] - Ganes Creek - United States",
            "searchName": "GEK-Ganes Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GFK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Grand Forks",
            "name": "[GFK] - Grand Forks - United States",
            "searchName": "GFK-Grand Forks North Dakota-Grand Forks-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GFL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Glens Falls",
            "name": "[GFL] - Glens Falls - United States",
            "searchName": "GFL-Glens Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GEY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Greybull",
            "name": "[GEY] - Greybull - United States",
            "searchName": "GEY-Greybull MT-Greybull-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GFB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Togiak Fish",
            "name": "[GFB] - Togiak Fish - United States",
            "searchName": "GFB-Togiak Fishery-Togiak Fish-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GFD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Greenfield",
            "name": "[GFD] - Greenfield - United States",
            "searchName": "GFD-Greenfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GBR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Great Barrington",
            "name": "[GBR] - Great Barrington - United States",
            "searchName": "GBR-Great Barrington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GBG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Galesburg",
            "name": "[GBG] - Galesburg - United States",
            "searchName": "GBG-Galesburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GBH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Galbraith Lake",
            "name": "[GBH] - Galbraith Lake - United States",
            "searchName": "GBH-Galbraith Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GBD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Great Bend",
            "name": "[GBD] - Great Bend - United States",
            "searchName": "GBD-Great Bend-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GCK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Garden City",
            "name": "[GCK] - Garden City - United States",
            "searchName": "GCK-Garden City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GCC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Gillette",
            "name": "[GCC] - Gillette - United States",
            "searchName": "GCC-Gillette-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GCD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Electric City",
            "name": "[GCD] - Electric City - United States",
            "searchName": "GCD-Electric City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GDH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Golden Horn Lodge",
            "name": "[GDH] - Golden Horn Lodge - United States",
            "searchName": "GDH-Golden Horn-Golden Horn Lodge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GCN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Grand Canyon",
            "name": "[GCN] - Grand Canyon - United States",
            "searchName": "GCN-Grand Canyon-Grand Canyon Village-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GCW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Grand Canyon West",
            "name": "[GCW] - Grand Canyon West - United States",
            "searchName": "GCW-Grand Canyon West-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GCY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Greenville",
            "name": "[GCY] - Greenville - United States",
            "searchName": "GCY-Greenville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SRU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Santa Cruz",
            "name": "[SRU] - Santa Cruz - United States",
            "searchName": "SRU-Santa Cruz-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SRV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Stony River",
            "name": "[SRV] - Stony River - United States",
            "searchName": "SRV-Stony River-Stony Nehri-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SRW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Salisbury",
            "name": "[SRW] - Salisbury - United States",
            "searchName": "SRW-Salisbury-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SRQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sarasota",
            "name": "[SRQ] - Sarasota - United States",
            "searchName": "SRQ-Bradenton/Sarasota-Sarasota-Bradenton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SRF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "San Rafael",
            "name": "[SRF] - San Rafael - United States",
            "searchName": "SRF-San Rafael-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SQL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "San Carlos",
            "name": "[SQL] - San Carlos - United States",
            "searchName": "SQL-San Carlos-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SQI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sterling Rockfalls",
            "name": "[SQI] - Sterling Rockfalls - United States",
            "searchName": "SQI-Sterling Rockfalls-Sterling-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SQV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sequim",
            "name": "[SQV] - Sequim - United States",
            "searchName": "SQV-Sequim-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SRC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Searcy",
            "name": "[SRC] - Searcy - United States",
            "searchName": "SRC-Searcy-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SSW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Stuart Island",
            "name": "[SSW] - Stuart Island - United States",
            "searchName": "SSW-Stuart Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SSU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "White Sulphur Springs",
            "name": "[SSU] - White Sulphur Springs - United States",
            "searchName": "SSU-White Sulphur Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "STJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "St Joseph",
            "name": "[STJ] - St Joseph - United States",
            "searchName": "STJ-St Joseph-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "STK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sterling",
            "name": "[STK] - Sterling - United States",
            "searchName": "STK-Sterling-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WAL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chincoteague",
            "name": "[WAL] - Chincoteague - United States",
            "searchName": "WAL-Chincoteague-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WAH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wahpeton",
            "name": "[WAH] - Wahpeton - United States",
            "searchName": "WAH-Wahpeton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WAY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Waynesburg",
            "name": "[WAY] - Waynesburg - United States",
            "searchName": "WAY-Waynesburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WBB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Stebbins",
            "name": "[WBB] - Stebbins - United States",
            "searchName": "WBB-Stebbins-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WBN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Woburn",
            "name": "[WBN] - Woburn - United States",
            "searchName": "WBN-Woburn-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WBK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "West Branch",
            "name": "[WBK] - West Branch - United States",
            "searchName": "WBK-West Branch-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WBQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Beaver",
            "name": "[WBQ] - Beaver - United States",
            "searchName": "WBQ-Beaver-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WBR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Big Rapids",
            "name": "[WBR] - Big Rapids - United States",
            "searchName": "WBR-Big Rapids-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WBU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Boulder",
            "name": "[WBU] - Boulder - United States",
            "searchName": "WBU-Boulder-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WFK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Frenchville",
            "name": "[WFK] - Frenchville - United States",
            "searchName": "WFK-Frenchville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WHD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hyder",
            "name": "[WHD] - Hyder - United States",
            "searchName": "WHD-Hyder-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WGO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Winchester",
            "name": "[WGO] - Winchester - United States",
            "searchName": "WGO-Winchester-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WDB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Deep Bay",
            "name": "[WDB] - Deep Bay - United States",
            "searchName": "WDB-Deep Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WDG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Enid",
            "name": "[WDG] - Enid - United States",
            "searchName": "WDG-Enid-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WCR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chandalar",
            "name": "[WCR] - Chandalar - United States",
            "searchName": "WCR-Chandalar-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WDN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Waldron Island",
            "name": "[WDN] - Waldron Island - United States",
            "searchName": "WDN-Waldron Island-Waldrun Island Wa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WDR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Winder",
            "name": "[WDR] - Winder - United States",
            "searchName": "WDR-Winder-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WEA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Weatherford",
            "name": "[WEA] - Weatherford - United States",
            "searchName": "WEA-Weatherford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VIK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kavik",
            "name": "[VIK] - Kavik - United States",
            "searchName": "VIK-Kavik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VIS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Visalia",
            "name": "[VIS] - Visalia - United States",
            "searchName": "VIS-Visalia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VKW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "West Kavik",
            "name": "[VKW] - West Kavik - United States",
            "searchName": "VKW-West Kavik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VLA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Vandalia",
            "name": "[VLA] - Vandalia - United States",
            "searchName": "VLA-Vandalia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VKS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Vicksburg",
            "name": "[VKS] - Vicksburg - United States",
            "searchName": "VKS-Vicksburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VJI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Abingdon",
            "name": "[VJI] - Abingdon - United States",
            "searchName": "VJI-Abingdon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VLD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Valdosta",
            "name": "[VLD] - Valdosta - United States",
            "searchName": "VLD-Valdosta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VLE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Valle",
            "name": "[VLE] - Valle - United States",
            "searchName": "VLE-Valle-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VLO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Vallejo",
            "name": "[VLO] - Vallejo - United States",
            "searchName": "VLO-Vallejo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VHN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Van Horn",
            "name": "[VHN] - Van Horn - United States",
            "searchName": "VHN-Van Horn-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VDI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Vidalia",
            "name": "[VDI] - Vidalia - United States",
            "searchName": "VDI-Vidalia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VIH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Vichy",
            "name": "[VIH] - Vichy - United States",
            "searchName": "VIH-Vichy-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VEX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tioga",
            "name": "[VEX] - Tioga - United States",
            "searchName": "VEX-Tioga-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VEL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Vernal",
            "name": "[VEL] - Vernal - United States",
            "searchName": "VEL-Vernal Utah-Vernal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VDZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Valdez",
            "name": "[VDZ] - Valdez - United States",
            "searchName": "VDZ-Valdez-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VEE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Venetie",
            "name": "[VEE] - Venetie - United States",
            "searchName": "VEE-Venetie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VAK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Chevak",
            "name": "[VAK] - Chevak - United States",
            "searchName": "VAK-Chevak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VBM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Blue Mountain",
            "name": "[VBM] - Blue Mountain - United States",
            "searchName": "VBM-Blue Mountain-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VCB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "View Cove",
            "name": "[VCB] - View Cove - United States",
            "searchName": "VCB-View Cove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VCT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Victoria",
            "name": "[VCT] - Victoria - United States",
            "searchName": "VCT-Victoria-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VCV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Victorville",
            "name": "[VCV] - Victorville - United States",
            "searchName": "VCV-Victorville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UTO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Utopia Creek",
            "name": "[UTO] - Utopia Creek - United States",
            "searchName": "UTO-Utopia Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UTM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tunica",
            "name": "[UTM] - Tunica - United States",
            "searchName": "UTM-Tunica-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UWA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ware",
            "name": "[UWA] - Ware - United States",
            "searchName": "UWA-Ware-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UUK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kuparuk",
            "name": "[UUK] - Kuparuk - United States",
            "searchName": "UUK-Kuparuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UVA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Uvalde",
            "name": "[UVA] - Uvalde - United States",
            "searchName": "UVA-Uvalde-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZTV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sturtevant",
            "name": "[ZTV] - Sturtevant - United States",
            "searchName": "ZTV-Sturtevant-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZUK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lake Cook Road",
            "name": "[ZUK] - Lake Cook Road - United States",
            "searchName": "ZUK-Lake Cook Road-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZWV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Glenview",
            "name": "[ZWV] - Glenview - United States",
            "searchName": "ZWV-Glenview-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZSH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Santa Fe",
            "name": "[ZSH] - Santa Fe - United States",
            "searchName": "ZSH-Santa Fe-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZSY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Scottsdale",
            "name": "[ZSY] - Scottsdale - United States",
            "searchName": "ZSY-Scottsdale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZTF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Stamford",
            "name": "[ZTF] - Stamford - United States",
            "searchName": "ZTF-Stamford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZSM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Santa Clara",
            "name": "[ZSM] - Santa Clara - United States",
            "searchName": "ZSM-Santa Clara-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZNC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Nyac",
            "name": "[ZNC] - Nyac - United States",
            "searchName": "ZNC-Nyac-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZPH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Zephyrhills",
            "name": "[ZPH] - Zephyrhills - United States",
            "searchName": "ZPH-Zephyrhills-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "YNG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Youngstown",
            "name": "[YNG] - Youngstown - United States",
            "searchName": "YNG-Youngstown Ohio-Youngstown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "YUM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Yuma",
            "name": "[YUM] - Yuma - United States",
            "searchName": "YUM-Yuma Arizona-Yuma-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "YKM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Yakima",
            "name": "[YKM] - Yakima - United States",
            "searchName": "YKM-Yakima-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "YKN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Yankton",
            "name": "[YKN] - Yankton - United States",
            "searchName": "YKN-Yankton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "XMD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Madison",
            "name": "[XMD] - Madison - United States",
            "searchName": "XMD-Madison-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "XPR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pine Ridge",
            "name": "[XPR] - Pine Ridge - United States",
            "searchName": "XPR-Pine Ridge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "XPU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "West Kuparuk",
            "name": "[XPU] - West Kuparuk - United States",
            "searchName": "XPU-West Kuparuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "XSM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "St Marys",
            "name": "[XSM] - St Marys - United States",
            "searchName": "XSM-St Marys-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "YAK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Yakutat",
            "name": "[YAK] - Yakutat - United States",
            "searchName": "YAK-Yakutat-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WOW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Willow",
            "name": "[WOW] - Willow - United States",
            "searchName": "WOW-Willow-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WOO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Woodchopper",
            "name": "[WOO] - Woodchopper - United States",
            "searchName": "WOO-Woodchopper-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WRG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wrangell",
            "name": "[WRG] - Wrangell - United States",
            "searchName": "WRG-Wrangell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WRH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wrench Creek",
            "name": "[WRH] - Wrench Creek - United States",
            "searchName": "WRH-Wrench Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WRI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wrightstown",
            "name": "[WRI] - Wrightstown - United States",
            "searchName": "WRI-Wrightstown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WRL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Worland",
            "name": "[WRL] - Worland - United States",
            "searchName": "WRL-Worland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WST",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Westerly",
            "name": "[WST] - Westerly - United States",
            "searchName": "WST-Westerly Rhode Island-Westerly-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WPO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Paonia",
            "name": "[WPO] - Paonia - United States",
            "searchName": "WPO-Paonia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WSB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Steamboat Bay",
            "name": "[WSB] - Steamboat Bay - United States",
            "searchName": "WSB-Steamboat Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WSD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "White Sands",
            "name": "[WSD] - White Sands - United States",
            "searchName": "WSD-White Sands-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WSF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sarichef",
            "name": "[WSF] - Sarichef - United States",
            "searchName": "WSF-Sarichef-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WSG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Washington",
            "name": "[WSG] - Washington - United States",
            "searchName": "WSG-Washington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WSH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Shirley",
            "name": "[WSH] - Shirley - United States",
            "searchName": "WSH-Shirley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WSJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "San Juan",
            "name": "[WSJ] - San Juan - United States",
            "searchName": "WSJ-San Juan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WSM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wiseman",
            "name": "[WSM] - Wiseman - United States",
            "searchName": "WSM-Wiseman-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WSN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "South Naknek",
            "name": "[WSN] - South Naknek - United States",
            "searchName": "WSN-South Naknek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WVI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Watsonville",
            "name": "[WVI] - Watsonville - United States",
            "searchName": "WVI-Watsonville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WVL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Waterville",
            "name": "[WVL] - Waterville - United States",
            "searchName": "WVL-Waterville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WWA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wasilla",
            "name": "[WWA] - Wasilla - United States",
            "searchName": "WWA-Wasilla-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WWD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wildwood",
            "name": "[WWD] - Wildwood - United States",
            "searchName": "WWD-Wildwood-Cape May-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WTR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "White River",
            "name": "[WTR] - White River - United States",
            "searchName": "WTR-White River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WSX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Westsound",
            "name": "[WSX] - Westsound - United States",
            "searchName": "WSX-Westsound-West Sound-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WTK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Noatak",
            "name": "[WTK] - Noatak - United States",
            "searchName": "WTK-Noatak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WTL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tuntutuliak",
            "name": "[WTL] - Tuntutuliak - United States",
            "searchName": "WTL-Tuntutuliak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WHT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wharton",
            "name": "[WHT] - Wharton - United States",
            "searchName": "WHT-Wharton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WIB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wilbarger",
            "name": "[WIB] - Wilbarger - United States",
            "searchName": "WIB-Wilbarger-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WLB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Labouchere Bay",
            "name": "[WLB] - Labouchere Bay - United States",
            "searchName": "WLB-Labouchere Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WLD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Winfield",
            "name": "[WLD] - Winfield - United States",
            "searchName": "WLD-Winfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WJF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lancaster",
            "name": "[WJF] - Lancaster - United States",
            "searchName": "WJF-Lancaster-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WKK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Aleknagik",
            "name": "[WKK] - Aleknagik - United States",
            "searchName": "WKK-Aleknagik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WKL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Waikoloa",
            "name": "[WKL] - Waikoloa - United States",
            "searchName": "WKL-Waikoloa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WMK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Meyers Chuck",
            "name": "[WMK] - Meyers Chuck - United States",
            "searchName": "WMK-Meyers Chuck-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WMO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "White Mountain",
            "name": "[WMO] - White Mountain - United States",
            "searchName": "WMO-White Mountain-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WMH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mountain Home",
            "name": "[WMH] - Mountain Home - United States",
            "searchName": "WMH-Mountain Home-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WMC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Winnemucca",
            "name": "[WMC] - Winnemucca - United States",
            "searchName": "WMC-Winnemucca-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WLK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Selawik",
            "name": "[WLK] - Selawik - United States",
            "searchName": "WLK-Selawik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WLW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Willows",
            "name": "[WLW] - Willows - United States",
            "searchName": "WLW-Willows-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WLR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Loring",
            "name": "[WLR] - Loring - United States",
            "searchName": "WLR-Loring-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WLM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Waltham",
            "name": "[WLM] - Waltham - United States",
            "searchName": "WLM-Waltham-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WLN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Little Naukati",
            "name": "[WLN] - Little Naukati - United States",
            "searchName": "WLN-Little Naukati-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WOD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wood River",
            "name": "[WOD] - Wood River - United States",
            "searchName": "WOD-Wood River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WNA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Napakiak",
            "name": "[WNA] - Napakiak - United States",
            "searchName": "WNA-Napakiak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WNC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tuxekan Island",
            "name": "[WNC] - Tuxekan Island - United States",
            "searchName": "WNC-Tuxekan Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WYS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "West Yellowstone",
            "name": "[WYS] - West Yellowstone - United States",
            "searchName": "WYS-West Yellowstone-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WWP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Whale Pass",
            "name": "[WWP] - Whale Pass - United States",
            "searchName": "WWP-Whale Pass-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WWR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Woodward",
            "name": "[WWR] - Woodward - United States",
            "searchName": "WWR-Woodward-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WWS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wildwood",
            "name": "[WWS] - Wildwood - United States",
            "searchName": "WWS-Wildwood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WWT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Newtok",
            "name": "[WWT] - Newtok - United States",
            "searchName": "WWT-Newtok-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WYB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Yes Bay",
            "name": "[WYB] - Yes Bay - United States",
            "searchName": "WYB-Yes Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "XES",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lake Geneva",
            "name": "[XES] - Lake Geneva - United States",
            "searchName": "XES-Lake Geneva-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RTN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Raton",
            "name": "[RTN] - Raton - United States",
            "searchName": "RTN-Raton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RSX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rouses Point",
            "name": "[RSX] - Rouses Point - United States",
            "searchName": "RSX-Rouses Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RTL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Spirit Lake",
            "name": "[RTL] - Spirit Lake - United States",
            "searchName": "RTL-Spirit Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RTD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rotunda",
            "name": "[RTD] - Rotunda - United States",
            "searchName": "RTD-Rotunda-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RTE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Marguerite Bay",
            "name": "[RTE] - Marguerite Bay - United States",
            "searchName": "RTE-Marguerite Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RST",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rochester",
            "name": "[RST] - Rochester - United States",
            "searchName": "RST-Rochester-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RSJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rosario",
            "name": "[RSJ] - Rosario - United States",
            "searchName": "RSJ-Rosario-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RSL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Russell",
            "name": "[RSL] - Russell - United States",
            "searchName": "RSL-Russell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RSN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ruston",
            "name": "[RSN] - Ruston - United States",
            "searchName": "RSN-Ruston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RSP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Raspberry Strait",
            "name": "[RSP] - Raspberry Strait - United States",
            "searchName": "RSP-Raspberry Strait-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RRT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Warroad",
            "name": "[RRT] - Warroad - United States",
            "searchName": "RRT-Warroad-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RSH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Russian Mission",
            "name": "[RSH] - Russian Mission - United States",
            "searchName": "RSH-Russian Mission-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ROG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rogers",
            "name": "[ROG] - Rogers - United States",
            "searchName": "ROG-Rogers-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ROL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Roosevelt",
            "name": "[ROL] - Roosevelt - United States",
            "searchName": "ROL-Roosevelt-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ROW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Roswell",
            "name": "[ROW] - Roswell - United States",
            "searchName": "ROW-Roswell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ROX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Roseau",
            "name": "[ROX] - Roseau - United States",
            "searchName": "ROX-Roseau-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RRL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Merrill",
            "name": "[RRL] - Merrill - United States",
            "searchName": "RRL-Merrill-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RPX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Roundup",
            "name": "[RPX] - Roundup - United States",
            "searchName": "RPX-Roundup-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RNG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rangely",
            "name": "[RNG] - Rangely - United States",
            "searchName": "RNG-Rangely-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RNH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "New Richmond",
            "name": "[RNH] - New Richmond - United States",
            "searchName": "RNH-New Richmond-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RNO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Reno",
            "name": "[RNO] - Reno - United States",
            "searchName": "RNO-Reno Nevada-Reno-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RNT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Renton",
            "name": "[RNT] - Renton - United States",
            "searchName": "RNT-Renton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RNZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rensselaer",
            "name": "[RNZ] - Rensselaer - United States",
            "searchName": "RNZ-Rensselaer-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ROA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Roanoke",
            "name": "[ROA] - Roanoke - United States",
            "searchName": "ROA-Roanoke-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ROC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rochester",
            "name": "[ROC] - Rochester - United States",
            "searchName": "ROC-Rochester-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RNC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mcminnville",
            "name": "[RNC] - Mcminnville - United States",
            "searchName": "RNC-Mcminnville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RMY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mariposa",
            "name": "[RMY] - Mariposa - United States",
            "searchName": "RMY-Mariposa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RMP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rampart",
            "name": "[RMP] - Rampart - United States",
            "searchName": "RMP-Rampart-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RMC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rockford",
            "name": "[RMC] - Rockford - United States",
            "searchName": "RMC-Rockford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RME",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rome",
            "name": "[RME] - Rome - United States",
            "searchName": "RME-Rome-Roma-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RMG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rome",
            "name": "[RMG] - Rome - United States",
            "searchName": "RMG-Rome-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RKW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rockwood",
            "name": "[RKW] - Rockwood - United States",
            "searchName": "RKW-Rockwood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RLU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bornite",
            "name": "[RLU] - Bornite - United States",
            "searchName": "RLU-Bornite-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RKP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rockport",
            "name": "[RKP] - Rockport - United States",
            "searchName": "RKP-Rockport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RKR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Poteau",
            "name": "[RKR] - Poteau - United States",
            "searchName": "RKR-Poteau-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RKS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rock Springs",
            "name": "[RKS] - Rock Springs - United States",
            "searchName": "RKS-Rock Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RLA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rolla",
            "name": "[RLA] - Rolla - United States",
            "searchName": "RLA-Rolla-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RLD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Richland",
            "name": "[RLD] - Richland - United States",
            "searchName": "RLD-Richland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RKC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Yreka",
            "name": "[RKC] - Yreka - United States",
            "searchName": "RKC-Yreka-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RKD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rockland",
            "name": "[RKD] - Rockland - United States",
            "searchName": "RKD-Rockland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RKH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rock Hill",
            "name": "[RKH] - Rock Hill - United States",
            "searchName": "RKH-Rock Hill-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RIW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Riverton",
            "name": "[RIW] - Riverton - United States",
            "searchName": "RIW-Riverton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RXE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rexburg",
            "name": "[RXE] - Rexburg - United States",
            "searchName": "RXE-Rexburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RVR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Green River",
            "name": "[RVR] - Green River - United States",
            "searchName": "RVR-Green River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RWB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rowan Bay",
            "name": "[RWB] - Rowan Bay - United States",
            "searchName": "RWB-Rowan Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RWF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Redwood Falls",
            "name": "[RWF] - Redwood Falls - United States",
            "searchName": "RWF-Redwood Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RWI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rocky Mount",
            "name": "[RWI] - Rocky Mount - United States",
            "searchName": "RWI-Rocky Mount-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RWL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rawlins",
            "name": "[RWL] - Rawlins - United States",
            "searchName": "RWL-Rawlins-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RUT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rutland",
            "name": "[RUT] - Rutland - United States",
            "searchName": "RUT-Rutland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RUI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ruidoso",
            "name": "[RUI] - Ruidoso - United States",
            "searchName": "RUI-Ruidoso-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SAN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "San Diego",
            "name": "[SAN] - San Diego - United States",
            "searchName": "SAN-San Diego-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SAG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sagwon",
            "name": "[SAG] - Sagwon - United States",
            "searchName": "SAG-Sagwon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SAC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sacramento",
            "name": "[SAC] - Sacramento - United States",
            "searchName": "SAC-Sacramento-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SAD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Safford",
            "name": "[SAD] - Safford - United States",
            "searchName": "SAD-Safford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RZZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Roanoke Rapids",
            "name": "[RZZ] - Roanoke Rapids - United States",
            "searchName": "RZZ-Roanoke Rapids-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SAA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Saratoga",
            "name": "[SAA] - Saratoga - United States",
            "searchName": "SAA-Saratoga-Laramie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SBA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Santa Barbara",
            "name": "[SBA] - Santa Barbara - United States",
            "searchName": "SBA-Santa Barbara-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SAV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Savannah",
            "name": "[SAV] - Savannah - United States",
            "searchName": "SAV-Savannah-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SAR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sparta",
            "name": "[SAR] - Sparta - United States",
            "searchName": "SAR-Sparta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SAS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Salton City",
            "name": "[SAS] - Salton City - United States",
            "searchName": "SAS-Salton City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SAT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "San Antonio",
            "name": "[SAT] - San Antonio - United States",
            "searchName": "SAT-San Antonio-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SDP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sand Point",
            "name": "[SDP] - Sand Point - United States",
            "searchName": "SDP-Sand Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PXU",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Pleiku",
            "name": "[PXU] - Pleiku - Vietnam",
            "searchName": "PXU-Plei Cu-Pleiku-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "PQC",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Phu Quoc",
            "name": "[PQC] - Phu Quoc - Vietnam",
            "searchName": "PQC-Phu Quoc-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "PHH",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Phan Thiet",
            "name": "[PHH] - Phan Thiet - Vietnam",
            "searchName": "PHH-Phan Thiet-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "PHU",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Phu Vinh",
            "name": "[PHU] - Phu Vinh - Vietnam",
            "searchName": "PHU-Phu Vinh-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "NHA",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Nha Trang",
            "name": "[NHA] - Nha Trang - Vietnam",
            "searchName": "NHA-Nha Trang-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "PHA",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Phan Rang",
            "name": "[PHA] - Phan Rang - Vietnam",
            "searchName": "PHA-Phan Rang-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "TBB",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Tuy Hoa",
            "name": "[TBB] - Tuy Hoa - Vietnam",
            "searchName": "TBB-Tuy Hoa-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "TMK",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Tamky",
            "name": "[TMK] - Tamky - Vietnam",
            "searchName": "TMK-Tamky-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "VKG",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Rach Gia",
            "name": "[VKG] - Rach Gia - Vietnam",
            "searchName": "VKG-Rach Gia-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "VII",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Vinh City",
            "name": "[VII] - Vinh City - Vietnam",
            "searchName": "VII-Vinh City-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "VDH",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Dong Hoi",
            "name": "[VDH] - Dong Hoi - Vietnam",
            "searchName": "VDH-Dong Hoi-ong Hoi-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "VCS",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Con Dao",
            "name": "[VCS] - Con Dao - Vietnam",
            "searchName": "VCS-Con Dao-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "VCL",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Tamky",
            "name": "[VCL] - Tamky - Vietnam",
            "searchName": "VCL-Tamky-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "VCA",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Can Tho",
            "name": "[VCA] - Can Tho - Vietnam",
            "searchName": "VCA-Can Tho-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "VTG",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Vung Tau",
            "name": "[VTG] - Vung Tau - Vietnam",
            "searchName": "VTG-Vung Tau-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "VSO",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Phuoclong",
            "name": "[VSO] - Phuoclong - Vietnam",
            "searchName": "VSO-Phuoclong-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "UIH",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Qui Nhon",
            "name": "[UIH] - Qui Nhon - Vietnam",
            "searchName": "UIH-Qui Nhon-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "XVL",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Vinh Long",
            "name": "[XVL] - Vinh Long - Vietnam",
            "searchName": "XVL-Vinh Long-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "XLO",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Long Xuyen",
            "name": "[XLO] - Long Xuyen - Vietnam",
            "searchName": "XLO-Long Xuyen-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "XNG",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Quang Ngai",
            "name": "[XNG] - Quang Ngai - Vietnam",
            "searchName": "XNG-Quang Ngai-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "DLI",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Dalat",
            "name": "[DLI] - Dalat - Vietnam",
            "searchName": "DLI-Dalat-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "CSJ",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Cape St Jacques",
            "name": "[CSJ] - Cape St Jacques - Vietnam",
            "searchName": "CSJ-Cape St Jacques-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "PZL",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Phinda",
            "name": "[PZL] - Phinda - South Africa",
            "searchName": "PZL-Phinda-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "PHW",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Phalaborwa",
            "name": "[PHW] - Phalaborwa - South Africa",
            "searchName": "PHW-Phalaborwa-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "PLZ",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Port Elizabeth",
            "name": "[PLZ] - Port Elizabeth - South Africa",
            "searchName": "PLZ-Port Elizabeth-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "PRY",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Pretoria",
            "name": "[PRY] - Pretoria - South Africa",
            "searchName": "PRY-Pretoria-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "PRK",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Prieska",
            "name": "[PRK] - Prieska - South Africa",
            "searchName": "PRK-Prieska-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "DIN",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Dien Bien Phu",
            "name": "[DIN] - Dien Bien Phu - Vietnam",
            "searchName": "DIN-Bien Phu-Dien Bien Phu-Di-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "BMV",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Banmethuot",
            "name": "[BMV] - Banmethuot - Vietnam",
            "searchName": "BMV-Banmethuot-Buon Ma Thuot-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "CAH",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Ca Mau",
            "name": "[CAH] - Ca Mau - Vietnam",
            "searchName": "CAH-Ca Mau-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "HUI",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Hue",
            "name": "[HUI] - Hue - Vietnam",
            "searchName": "HUI-Hue-Hu-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "HPH",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Haiphong",
            "name": "[HPH] - Haiphong - Vietnam",
            "searchName": "HPH-Haiphong-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "HBN",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Phu-bon",
            "name": "[HBN] - Phu-bon - Vietnam",
            "searchName": "HBN-Phu-bon-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "HWN",
            "countryCode": "ZW",
            "cityCode": null,
            "cityName": "Hwange Nat Park",
            "name": "[HWN] - Hwange Nat Park - Zimbabwe",
            "searchName": "HWN-Hwange-Hwange Nat Park-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "HRE",
            "countryCode": "ZW",
            "cityCode": null,
            "cityName": "Harare",
            "name": "[HRE] - Harare - Zimbabwe",
            "searchName": "HRE-Harare-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "GWE",
            "countryCode": "ZW",
            "cityCode": null,
            "cityName": "Gweru",
            "name": "[GWE] - Gweru - Zimbabwe",
            "searchName": "GWE-Gweru-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "UTA",
            "countryCode": "ZW",
            "cityCode": null,
            "cityName": "Mutare",
            "name": "[UTA] - Mutare - Zimbabwe",
            "searchName": "UTA-Mutare-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "VFA",
            "countryCode": "ZW",
            "cityCode": null,
            "cityName": "Victoria Falls",
            "name": "[VFA] - Victoria Falls - Zimbabwe",
            "searchName": "VFA-Victoria Falls-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "WKI",
            "countryCode": "ZW",
            "cityCode": null,
            "cityName": "Hwange",
            "name": "[WKI] - Hwange - Zimbabwe",
            "searchName": "WKI-Hwange-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "MJW",
            "countryCode": "ZW",
            "cityCode": null,
            "cityName": "Mahenye",
            "name": "[MJW] - Mahenye - Zimbabwe",
            "searchName": "MJW-Mahenye-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "MVZ",
            "countryCode": "ZW",
            "cityCode": null,
            "cityName": "Masvingo",
            "name": "[MVZ] - Masvingo - Zimbabwe",
            "searchName": "MVZ-Masvingo-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "MZF",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Mzamba(Wild Coast Sun)",
            "name": "[MZF] - Mzamba(Wild Coast Sun) - South Africa",
            "searchName": "MZF-Mzamba-Mzamba(Wild Coast Sun)-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "MZQ",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Mkuze",
            "name": "[MZQ] - Mkuze - South Africa",
            "searchName": "MZQ-Mkuze-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "MZY",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Mossel Bay",
            "name": "[MZY] - Mossel Bay - South Africa",
            "searchName": "MZY-Mossel Bay-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "MWR",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Motswari",
            "name": "[MWR] - Motswari - South Africa",
            "searchName": "MWR-Motswari-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "NGL",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Ngala",
            "name": "[NGL] - Ngala - South Africa",
            "searchName": "NGL-Ngala-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "NCS",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Newcastle",
            "name": "[NCS] - Newcastle - South Africa",
            "searchName": "NCS-Newcastle-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "NLP",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Nelspruit",
            "name": "[NLP] - Nelspruit - South Africa",
            "searchName": "NLP-Nelspruit-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "NTY",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Sun City",
            "name": "[NTY] - Sun City - South Africa",
            "searchName": "NTY-Sun City-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "PCF",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Potchefstroom",
            "name": "[PCF] - Potchefstroom - South Africa",
            "searchName": "PCF-Potchefstroom-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "PBZ",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Plettenberg Bay",
            "name": "[PBZ] - Plettenberg Bay - South Africa",
            "searchName": "PBZ-Plettenberg Bay-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "OUH",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Oudtshoorn",
            "name": "[OUH] - Oudtshoorn - South Africa",
            "searchName": "OUH-Oudtshoorn-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "OVG",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Overberg",
            "name": "[OVG] - Overberg - South Africa",
            "searchName": "OVG-Overberg-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "NLA",
            "countryCode": "ZM",
            "cityCode": null,
            "cityName": "Ndola",
            "name": "[NLA] - Ndola - Zambia",
            "searchName": "NLA-Ndola-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "MMQ",
            "countryCode": "ZM",
            "cityCode": null,
            "cityName": "Mbala",
            "name": "[MMQ] - Mbala - Zambia",
            "searchName": "MMQ-Mbala-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "MNR",
            "countryCode": "ZM",
            "cityCode": null,
            "cityName": "Mongu",
            "name": "[MNR] - Mongu - Zambia",
            "searchName": "MNR-Mongu-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "MNS",
            "countryCode": "ZM",
            "cityCode": null,
            "cityName": "Mansa",
            "name": "[MNS] - Mansa - Zambia",
            "searchName": "MNS-Mansa-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "SJQ",
            "countryCode": "ZM",
            "cityCode": null,
            "cityName": "Sesheke",
            "name": "[SJQ] - Sesheke - Zambia",
            "searchName": "SJQ-Sesheke-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "SLI",
            "countryCode": "ZM",
            "cityCode": null,
            "cityName": "Solwezi",
            "name": "[SLI] - Solwezi - Zambia",
            "searchName": "SLI-Solwezi-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "ZGM",
            "countryCode": "ZM",
            "cityCode": null,
            "cityName": "Ngoma",
            "name": "[ZGM] - Ngoma - Zambia",
            "searchName": "ZGM-Ngoma-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "ZKB",
            "countryCode": "ZM",
            "cityCode": null,
            "cityName": "Kasaba Bay",
            "name": "[ZKB] - Kasaba Bay - Zambia",
            "searchName": "ZKB-Kasaba Bay-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "SXG",
            "countryCode": "ZM",
            "cityCode": null,
            "cityName": "Senanga",
            "name": "[SXG] - Senanga - Zambia",
            "searchName": "SXG-Senanga-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "KIW",
            "countryCode": "ZM",
            "cityCode": null,
            "cityName": "Kitwe",
            "name": "[KIW] - Kitwe - Zambia",
            "searchName": "KIW-Kitwe-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "KLB",
            "countryCode": "ZM",
            "cityCode": null,
            "cityName": "Kalabo",
            "name": "[KLB] - Kalabo - Zambia",
            "searchName": "KLB-Kalabo-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "KMZ",
            "countryCode": "ZM",
            "cityCode": null,
            "cityName": "Kaoma",
            "name": "[KMZ] - Kaoma - Zambia",
            "searchName": "KMZ-Kaoma-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "LUN",
            "countryCode": "ZM",
            "cityCode": null,
            "cityName": "Lusaka",
            "name": "[LUN] - Lusaka - Zambia",
            "searchName": "LUN-Lusaka-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "LVI",
            "countryCode": "ZM",
            "cityCode": null,
            "cityName": "Livingstone",
            "name": "[LVI] - Livingstone - Zambia",
            "searchName": "LVI-Livingstone-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "LXU",
            "countryCode": "ZM",
            "cityCode": null,
            "cityName": "Lukulu",
            "name": "[LXU] - Lukulu - Zambia",
            "searchName": "LXU-Lukulu-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "MFU",
            "countryCode": "ZM",
            "cityCode": null,
            "cityName": "Mfuwe",
            "name": "[MFU] - Mfuwe - Zambia",
            "searchName": "MFU-Mfuwe-A-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "KAA",
            "countryCode": "ZM",
            "cityCode": null,
            "cityName": "Kasama",
            "name": "[KAA] - Kasama - Zambia",
            "searchName": "KAA-Kasama-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "BBZ",
            "countryCode": "ZM",
            "cityCode": null,
            "cityName": "Zambezi",
            "name": "[BBZ] - Zambezi - Zambia",
            "searchName": "BBZ-Zambezi-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "CGJ",
            "countryCode": "ZM",
            "cityCode": null,
            "cityName": "Chingola",
            "name": "[CGJ] - Chingola - Zambia",
            "searchName": "CGJ-Chingola-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "CIP",
            "countryCode": "ZM",
            "cityCode": null,
            "cityName": "Chipata",
            "name": "[CIP] - Chipata - Zambia",
            "searchName": "CIP-Chipata-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "CHJ",
            "countryCode": "ZW",
            "cityCode": null,
            "cityName": "Chipinge",
            "name": "[CHJ] - Chipinge - Zimbabwe",
            "searchName": "CHJ-Chipinge-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "BFO",
            "countryCode": "ZW",
            "cityCode": null,
            "cityName": "Buffalo Range",
            "name": "[BFO] - Buffalo Range - Zimbabwe",
            "searchName": "BFO-Buffalo Range-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "BUQ",
            "countryCode": "ZW",
            "cityCode": null,
            "cityName": "Bulawayo",
            "name": "[BUQ] - Bulawayo - Zimbabwe",
            "searchName": "BUQ-Bulawayo-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "BZH",
            "countryCode": "ZW",
            "cityCode": null,
            "cityName": "Bumi Hills",
            "name": "[BZH] - Bumi Hills - Zimbabwe",
            "searchName": "BZH-Bumi Hills-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "AAU",
            "countryCode": "WS",
            "cityCode": null,
            "cityName": "Asau",
            "name": "[AAU] - Asau - Samoa",
            "searchName": "AAU-Asau-Szamoa-Samoa-Bati Samoa",
            "priority": 0
        },
        {
            "iataCode": "MOR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Morristown",
            "name": "[MOR] - Morristown - United States",
            "searchName": "MOR-Morristown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MOS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Moses Point",
            "name": "[MOS] - Moses Point - United States",
            "searchName": "MOS-Moses Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MOT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Minot",
            "name": "[MOT] - Minot - United States",
            "searchName": "MOT-Minot North Dakota-Minot-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MOU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mountain Village",
            "name": "[MOU] - Mountain Village - United States",
            "searchName": "MOU-Mountain Village-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MOX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Morris",
            "name": "[MOX] - Morris - United States",
            "searchName": "MOX-Morris-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MOP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mount Pleasant",
            "name": "[MOP] - Mount Pleasant - United States",
            "searchName": "MOP-Mount Pleasant-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MPE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Madison",
            "name": "[MPE] - Madison - United States",
            "searchName": "MPE-Madison-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MPJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Morrilton",
            "name": "[MPJ] - Morrilton - United States",
            "searchName": "MPJ-Morrilton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SOA",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Soc Trang",
            "name": "[SOA] - Soc Trang - Vietnam",
            "searchName": "SOA-Soc Trang-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "MKG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Muskegon",
            "name": "[MKG] - Muskegon - United States",
            "searchName": "MKG-Muskegon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MKK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hoolehua",
            "name": "[MKK] - Hoolehua - United States",
            "searchName": "MKK-Hoolehua-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MKL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Jackson",
            "name": "[MKL] - Jackson - United States",
            "searchName": "MKL-Jackson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MOB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mobile",
            "name": "[MOB] - Mobile - United States",
            "searchName": "MOB-Mobile-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TMJ",
            "countryCode": "UZ",
            "cityCode": null,
            "cityName": "Termez",
            "name": "[TMJ] - Termez - Uzbekistan",
            "searchName": "TMJ-Termiz-Termez-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "UGC",
            "countryCode": "UZ",
            "cityCode": null,
            "cityName": "Urgench",
            "name": "[UGC] - Urgench - Uzbekistan",
            "searchName": "UGC-Urganch-Urgench-Ourguentch-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "SKD",
            "countryCode": "UZ",
            "cityCode": null,
            "cityName": "Samarkand",
            "name": "[SKD] - Samarkand - Uzbekistan",
            "searchName": "SKD-Samarkand-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "NVI",
            "countryCode": "UZ",
            "cityCode": null,
            "cityName": "Navoi",
            "name": "[NVI] - Navoi - Uzbekistan",
            "searchName": "NVI-Navoiy-Navoi-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "NCU",
            "countryCode": "UZ",
            "cityCode": null,
            "cityName": "Nukus",
            "name": "[NCU] - Nukus - Uzbekistan",
            "searchName": "NCU-Nukus-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "NMA",
            "countryCode": "UZ",
            "cityCode": null,
            "cityName": "Namangan",
            "name": "[NMA] - Namangan - Uzbekistan",
            "searchName": "NMA-Namangan-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "MQS",
            "countryCode": "VC",
            "cityCode": null,
            "cityName": "Mustique Island",
            "name": "[MQS] - Mustique Island - Saint Vincent and the Grenadines",
            "searchName": "MQS-Mustique-Mustique Island-St. Vincent und die Grenadinen-Saint Vincent and the Grenadines-Saint-Vincent-et-les Grenadines-San Vicente y las Granadinas-Saint Vincent e Grenadines",
            "priority": 0
        },
        {
            "iataCode": "PLI",
            "countryCode": "VC",
            "cityCode": null,
            "cityName": "Palm Island",
            "name": "[PLI] - Palm Island - Saint Vincent and the Grenadines",
            "searchName": "PLI-Palm Island-St. Vincent und die Grenadinen-Saint Vincent and the Grenadines-Saint-Vincent-et-les Grenadines-San Vicente y las Granadinas-Saint Vincent e Grenadines",
            "priority": 0
        },
        {
            "iataCode": "UNI",
            "countryCode": "VC",
            "cityCode": null,
            "cityName": "Union Island",
            "name": "[UNI] - Union Island - Saint Vincent and the Grenadines",
            "searchName": "UNI-Union Island-St. Vincent und die Grenadinen-Saint Vincent and the Grenadines-Saint-Vincent-et-les Grenadines-San Vicente y las Granadinas-Saint Vincent e Grenadines",
            "priority": 0
        },
        {
            "iataCode": "SVD",
            "countryCode": "VC",
            "cityCode": null,
            "cityName": "St Vincent",
            "name": "[SVD] - St Vincent - Saint Vincent and the Grenadines",
            "searchName": "SVD-St Vincent-St. Vincent und die Grenadinen-Saint Vincent and the Grenadines-Saint-Vincent-et-les Grenadines-San Vicente y las Granadinas-Saint Vincent e Grenadines",
            "priority": 0
        },
        {
            "iataCode": "CIW",
            "countryCode": "VC",
            "cityCode": null,
            "cityName": "Canouan Island",
            "name": "[CIW] - Canouan Island - Saint Vincent and the Grenadines",
            "searchName": "CIW-Canouan Island--St. Vincent und die Grenadinen-Saint Vincent and the Grenadines-Saint-Vincent-et-les Grenadines-San Vicente y las Granadinas-Saint Vincent e Grenadines",
            "priority": 0
        },
        {
            "iataCode": "BQU",
            "countryCode": "VC",
            "cityCode": null,
            "cityName": "Bequia",
            "name": "[BQU] - Bequia - Saint Vincent and the Grenadines",
            "searchName": "BQU-Port Elizabeth-Bequia-St. Vincent und die Grenadinen-Saint Vincent and the Grenadines-Saint-Vincent-et-les Grenadines-San Vicente y las Granadinas-Saint Vincent e Grenadines",
            "priority": 0
        },
        {
            "iataCode": "BRM",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Barquisimeto",
            "name": "[BRM] - Barquisimeto - Venezuela",
            "searchName": "BRM-Barquisimeto-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "CBS",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Cabimas",
            "name": "[CBS] - Cabimas - Venezuela",
            "searchName": "CBS-Cabimas-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "CBL",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Ciudad Bolivar",
            "name": "[CBL] - Ciudad Bolivar - Venezuela",
            "searchName": "CBL-Ciudad Bolivar-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "CAJ",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Canaima",
            "name": "[CAJ] - Canaima - Venezuela",
            "searchName": "CAJ-Canaima-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "BNS",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Barinas",
            "name": "[BNS] - Barinas - Venezuela",
            "searchName": "BNS-Barinas-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "BLA",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Barcelona",
            "name": "[BLA] - Barcelona - Venezuela",
            "searchName": "BLA-Barcelona-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "AGV",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Acarigua",
            "name": "[AGV] - Acarigua - Venezuela",
            "searchName": "AGV-Acarigua-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "AAO",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Anaco",
            "name": "[AAO] - Anaco - Venezuela",
            "searchName": "AAO-Anaco-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "CGU",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Ciudad Guayana",
            "name": "[CGU] - Ciudad Guayana - Venezuela",
            "searchName": "CGU-Ciudad Guayana-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "HOO",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Quanduc",
            "name": "[HOO] - Quanduc - Vietnam",
            "searchName": "HOO-Quanduc-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "KON",
            "countryCode": "VN",
            "cityCode": null,
            "cityName": "Kontum",
            "name": "[KON] - Kontum - Vietnam",
            "searchName": "KON-Kontum-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "LNE",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Lonorore",
            "name": "[LNE] - Lonorore - Vanuatu",
            "searchName": "LNE-Lonorore-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "LNB",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Lamen Bay",
            "name": "[LNB] - Lamen Bay - Vanuatu",
            "searchName": "LNB-Lamen Bay-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "LPM",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Lamap",
            "name": "[LPM] - Lamap - Vanuatu",
            "searchName": "LPM-Lamap-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "LOD",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Longana",
            "name": "[LOD] - Longana - Vanuatu",
            "searchName": "LOD-Longana-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "GBA",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Big Bay",
            "name": "[GBA] - Big Bay - Vanuatu",
            "searchName": "GBA-Big Bay-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "FTA",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Futuna Island",
            "name": "[FTA] - Futuna Island - Vanuatu",
            "searchName": "FTA-Futuna Inseln-Futuna Island--Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "IPA",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Ipota",
            "name": "[IPA] - Ipota - Vanuatu",
            "searchName": "IPA-Ipota-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "AUY",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Aneityum",
            "name": "[AUY] - Aneityum - Vanuatu",
            "searchName": "AUY-Aneityum-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "AWD",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Aniwa",
            "name": "[AWD] - Aniwa - Vanuatu",
            "searchName": "AWD-Aniwa-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "CCV",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Craig Cove",
            "name": "[CCV] - Craig Cove - Vanuatu",
            "searchName": "CCV-Craig Cove-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "DLY",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Dillons Bay",
            "name": "[DLY] - Dillons Bay - Vanuatu",
            "searchName": "DLY-Dillons Bay-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "EAE",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Emae",
            "name": "[EAE] - Emae - Vanuatu",
            "searchName": "EAE-Emae-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "EPI",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Epi",
            "name": "[EPI] - Epi - Vanuatu",
            "searchName": "EPI-Epi-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "WLH",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Walaha",
            "name": "[WLH] - Walaha - Vanuatu",
            "searchName": "WLH-Walaha-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "ZGU",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Gaua",
            "name": "[ZGU] - Gaua - Vanuatu",
            "searchName": "ZGU-Gaua-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "UIQ",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Quine Hill",
            "name": "[UIQ] - Quine Hill - Vanuatu",
            "searchName": "UIQ-Quine Hill-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "ULB",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Ulei",
            "name": "[ULB] - Ulei - Vanuatu",
            "searchName": "ULB-Ulei-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "VLI",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Port Vila",
            "name": "[VLI] - Port Vila - Vanuatu",
            "searchName": "VLI-Port Vila-Port-Vila-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "VLS",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Valesdir",
            "name": "[VLS] - Valesdir - Vanuatu",
            "searchName": "VLS-Valesdir-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "TOH",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Torres",
            "name": "[TOH] - Torres - Vanuatu",
            "searchName": "TOH-Torres-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "TGH",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Tongoa",
            "name": "[TGH] - Tongoa - Vanuatu",
            "searchName": "TGH-Tongoa-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "TAH",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Tanna",
            "name": "[TAH] - Tanna - Vanuatu",
            "searchName": "TAH-Tanna-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "SWJ",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "South West Bay",
            "name": "[SWJ] - South West Bay - Vanuatu",
            "searchName": "SWJ-South West Bay-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "SSR",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Sara",
            "name": "[SSR] - Sara - Vanuatu",
            "searchName": "SSR-Sara-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "PBJ",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Paama",
            "name": "[PBJ] - Paama - Vanuatu",
            "searchName": "PBJ-Paama-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "NUS",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Norsup",
            "name": "[NUS] - Norsup - Vanuatu",
            "searchName": "NUS-Norsup-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "OLJ",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Olpoi",
            "name": "[OLJ] - Olpoi - Vanuatu",
            "searchName": "OLJ-Olpoi-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "MWF",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Maewo",
            "name": "[MWF] - Maewo - Vanuatu",
            "searchName": "MWF-Maewo-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "MTV",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Mota Lava",
            "name": "[MTV] - Mota Lava - Vanuatu",
            "searchName": "MTV-Mota Lava-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "RCL",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Redcliffe",
            "name": "[RCL] - Redcliffe - Vanuatu",
            "searchName": "RCL-Redcliffe-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "SON",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Espiritu Santo",
            "name": "[SON] - Espiritu Santo - Vanuatu",
            "searchName": "SON-Espiritu Santo-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "SLH",
            "countryCode": "VU",
            "cityCode": null,
            "cityName": "Sola",
            "name": "[SLH] - Sola - Vanuatu",
            "searchName": "SLH-Sola-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "WLS",
            "countryCode": "WF",
            "cityCode": null,
            "cityName": "Wallis Island",
            "name": "[WLS] - Wallis Island - Wallis and Futuna",
            "searchName": "WLS-Wallis Inseln-Wallis Island-Wallis and Futuna-Wallis und Futuna-Wallis ve Futuna Adalari-Wallis y Futuna-Wallis-et-Futuna-Wallis e Futuna",
            "priority": 0
        },
        {
            "iataCode": "FUT",
            "countryCode": "WF",
            "cityCode": null,
            "cityName": "Futuna Island",
            "name": "[FUT] - Futuna Island - Wallis and Futuna",
            "searchName": "FUT-Futuna Island--Wallis and Futuna-Wallis und Futuna-Wallis ve Futuna Adalari-Wallis y Futuna-Wallis-et-Futuna-Wallis e Futuna",
            "priority": 0
        },
        {
            "iataCode": "LAV",
            "countryCode": "WS",
            "cityCode": null,
            "cityName": "Lalomalava",
            "name": "[LAV] - Lalomalava - Samoa",
            "searchName": "LAV-Lalomalava-Szamoa-Samoa-Bati Samoa",
            "priority": 0
        },
        {
            "iataCode": "BIY",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Bisho",
            "name": "[BIY] - Bisho - South Africa",
            "searchName": "BIY-Bisho-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "BFN",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Bloemfontein",
            "name": "[BFN] - Bloemfontein - South Africa",
            "searchName": "BFN-Bloemfontein-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "ADY",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Alldays",
            "name": "[ADY] - Alldays - South Africa",
            "searchName": "ADY-Alldays-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "AGZ",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Aggeneys",
            "name": "[AGZ] - Aggeneys - South Africa",
            "searchName": "AGZ-Aggeneys-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "AFD",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Port Alfred",
            "name": "[AFD] - Port Alfred - South Africa",
            "searchName": "AFD-Port Alfred-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "AAM",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Mala Mala",
            "name": "[AAM] - Mala Mala - South Africa",
            "searchName": "AAM-Mala Mala-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "ALJ",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Alexander Bay",
            "name": "[ALJ] - Alexander Bay - South Africa",
            "searchName": "ALJ-Alexander Bay-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "JNB",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Johannesburg",
            "name": "[JNB] - Johannesburg - South Africa",
            "searchName": "JNB-Johannesburg-Johannesbourg-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "JOH",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Port Saint Johns",
            "name": "[JOH] - Port Saint Johns - South Africa",
            "searchName": "JOH-Port Saint Johns-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "INY",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Inyati",
            "name": "[INY] - Inyati - South Africa",
            "searchName": "INY-Inyati-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "HRS",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Harrismith",
            "name": "[HRS] - Harrismith - South Africa",
            "searchName": "HRS-Harrismith-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "HZV",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Hazyview",
            "name": "[HZV] - Hazyview - South Africa",
            "searchName": "HZV-Hazyview-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "GRJ",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "George",
            "name": "[GRJ] - George - South Africa",
            "searchName": "GRJ-George-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "GSS",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Sabi Sabi",
            "name": "[GSS] - Sabi Sabi - South Africa",
            "searchName": "GSS-Sabi Sabi-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "HLW",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Hluhluwe",
            "name": "[HLW] - Hluhluwe - South Africa",
            "searchName": "HLW-Hluhluwe-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "HDS",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Hoedspruit",
            "name": "[HDS] - Hoedspruit - South Africa",
            "searchName": "HDS-Hoedspruit-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "GIY",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Giyani",
            "name": "[GIY] - Giyani - South Africa",
            "searchName": "GIY-Giyani-Giyana-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "MGH",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Margate",
            "name": "[MGH] - Margate - South Africa",
            "searchName": "MGH-Margate-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "MEZ",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Messina",
            "name": "[MEZ] - Messina - South Africa",
            "searchName": "MEZ-Musina\\/ Messina-Messina-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "MBD",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Mmabatho",
            "name": "[MBD] - Mmabatho - South Africa",
            "searchName": "MBD-Mahikeng\\/ Mmabatho-Mmabatho-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "MBM",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Mkambati",
            "name": "[MBM] - Mkambati - South Africa",
            "searchName": "MBM-Mkambati-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "LTA",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Tzaneen",
            "name": "[LTA] - Tzaneen - South Africa",
            "searchName": "LTA-Tzaneen-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "LMR",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Lime Acres",
            "name": "[LMR] - Lime Acres - South Africa",
            "searchName": "LMR-Lime Acres-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "LLE",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Malelane",
            "name": "[LLE] - Malelane - South Africa",
            "searchName": "LLE-Malelane-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "LDZ",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Londolozi",
            "name": "[LDZ] - Londolozi - South Africa",
            "searchName": "LDZ-Londolozi-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "LCD",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Louis Trichardt",
            "name": "[LCD] - Louis Trichardt - South Africa",
            "searchName": "LCD-Louis Trichardt-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "LAY",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Ladysmith",
            "name": "[LAY] - Ladysmith - South Africa",
            "searchName": "LAY-Ladysmith-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "KXE",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Klerksdorp",
            "name": "[KXE] - Klerksdorp - South Africa",
            "searchName": "KXE-Klerksdorp-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "KOF",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Komatipoort",
            "name": "[KOF] - Komatipoort - South Africa",
            "searchName": "KOF-Komatipoort-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "KMH",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Kuruman",
            "name": "[KMH] - Kuruman - South Africa",
            "searchName": "KMH-Kuruman-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "KLZ",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Kleinzee",
            "name": "[KLZ] - Kleinzee - South Africa",
            "searchName": "KLZ-Kleinzee-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "KIG",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Koinghaas",
            "name": "[KIG] - Koinghaas - South Africa",
            "searchName": "KIG-Koingnaas-Koinghaas-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "KIM",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Kimberley",
            "name": "[KIM] - Kimberley - South Africa",
            "searchName": "KIM-Kimberley-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "KHO",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Khoka Moya",
            "name": "[KHO] - Khoka Moya - South Africa",
            "searchName": "KHO-Khoka Moya-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "ULD",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Ulundi",
            "name": "[ULD] - Ulundi - South Africa",
            "searchName": "ULD-Ulundi-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "ULX",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Ulusaba",
            "name": "[ULX] - Ulusaba - South Africa",
            "searchName": "ULX-Ulusaba-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "TSD",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Tshipise",
            "name": "[TSD] - Tshipise - South Africa",
            "searchName": "TSD-Tshipise-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "WEL",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Welkom",
            "name": "[WEL] - Welkom - South Africa",
            "searchName": "WEL-Welkom-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "VYD",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Vryheid",
            "name": "[VYD] - Vryheid - South Africa",
            "searchName": "VYD-Vryheid-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "VRU",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Vryburg",
            "name": "[VRU] - Vryburg - South Africa",
            "searchName": "VRU-Vryburg-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "VRE",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Vredendal",
            "name": "[VRE] - Vredendal - South Africa",
            "searchName": "VRE-Vredendal-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "UTW",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Queenstown",
            "name": "[UTW] - Queenstown - South Africa",
            "searchName": "UTW-Queenstown-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "UTN",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Upington",
            "name": "[UTN] - Upington - South Africa",
            "searchName": "UTN-Upington-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "UTT",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Umtata",
            "name": "[UTT] - Umtata - South Africa",
            "searchName": "UTT-Umtata-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "SZK",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Skukuza",
            "name": "[SZK] - Skukuza - South Africa",
            "searchName": "SZK-Skukuza-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "TCU",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Thaba Nchu",
            "name": "[TCU] - Thaba Nchu - South Africa",
            "searchName": "TCU-Thaba Nchu-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "TDT",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Timbavati",
            "name": "[TDT] - Timbavati - South Africa",
            "searchName": "TDT-Timbavati-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "THY",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Thohoyandou",
            "name": "[THY] - Thohoyandou - South Africa",
            "searchName": "THY-Thohoyandou-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "ZEC",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Secunda",
            "name": "[ZEC] - Secunda - South Africa",
            "searchName": "ZEC-Secunda-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "ROD",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Robertson",
            "name": "[ROD] - Robertson - South Africa",
            "searchName": "ROD-Robertson-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "RVO",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Reivilo",
            "name": "[RVO] - Reivilo - South Africa",
            "searchName": "RVO-Reivilo-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "SBU",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Springbok",
            "name": "[SBU] - Springbok - South Africa",
            "searchName": "SBU-Springbok-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "SDB",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Saldanha Bay",
            "name": "[SDB] - Saldanha Bay - South Africa",
            "searchName": "SDB-Saldanha Bay-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "SIS",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Sishen",
            "name": "[SIS] - Sishen - South Africa",
            "searchName": "SIS-Sishen-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "RCB",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Richards Bay",
            "name": "[RCB] - Richards Bay - South Africa",
            "searchName": "RCB-Richards Bay-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "MYN",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Mareb",
            "name": "[MYN] - Mareb - Yemen",
            "searchName": "MYN-Mareb-Marib-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "SCT",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Socotra",
            "name": "[SCT] - Socotra - Yemen",
            "searchName": "SCT-Socotra-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "SAH",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Sanaa",
            "name": "[SAH] - Sanaa - Yemen",
            "searchName": "SAH-Sana a-Sanaa-San-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "RXA",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Raudha",
            "name": "[RXA] - Raudha - Yemen",
            "searchName": "RXA-Raudha-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "RIY",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Riyan Mukalla",
            "name": "[RIY] - Riyan Mukalla - Yemen",
            "searchName": "RIY-Riyan Mukalla-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "TAI",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Taiz",
            "name": "[TAI] - Taiz - Yemen",
            "searchName": "TAI-Taiz-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "SYE",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Sadah",
            "name": "[SYE] - Sadah - Yemen",
            "searchName": "SYE-Sadah-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "WDA",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Wadi Ain",
            "name": "[WDA] - Wadi Ain - Yemen",
            "searchName": "WDA-Wadi Ain-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "UKR",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Mukeiras",
            "name": "[UKR] - Mukeiras - Yemen",
            "searchName": "UKR-Mukeiras-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "AAY",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Al Ghaydah",
            "name": "[AAY] - Al Ghaydah - Yemen",
            "searchName": "AAY-Al Ghaydah-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "ADE",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Aden",
            "name": "[ADE] - Aden - Yemen",
            "searchName": "ADE-Aden-Ad-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "AXK",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Ataq",
            "name": "[AXK] - Ataq - Yemen",
            "searchName": "AXK-Ataq-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "BYD",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Beidah",
            "name": "[BYD] - Beidah - Yemen",
            "searchName": "BYD-Beidah-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "BUK",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Albuq",
            "name": "[BUK] - Albuq - Yemen",
            "searchName": "BUK-Albuq-Al-Bough-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "BHN",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Beihan",
            "name": "[BHN] - Beihan - Yemen",
            "searchName": "BHN-Beihan-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "EAB",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Abbse",
            "name": "[EAB] - Abbse - Yemen",
            "searchName": "EAB-Abbse-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "DMR",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Dhamar",
            "name": "[DMR] - Dhamar - Yemen",
            "searchName": "DMR-Dhamar-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "DHL",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Dhala",
            "name": "[DHL] - Dhala - Yemen",
            "searchName": "DHL-Dhala-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "DAH",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Dathina",
            "name": "[DAH] - Dathina - Yemen",
            "searchName": "DAH-Dathina-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "LDR",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Lodar",
            "name": "[LDR] - Lodar - Yemen",
            "searchName": "LDR-Lodar-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "MFY",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Mayfa ah",
            "name": "[MFY] - Mayfa ah - Yemen",
            "searchName": "MFY-Mayfa ah-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "HOD",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Hodeidah",
            "name": "[HOD] - Hodeidah - Yemen",
            "searchName": "HOD-Hodeidah-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "GXF",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Seiyun",
            "name": "[GXF] - Seiyun - Yemen",
            "searchName": "GXF-Seiyun-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "IHN",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Qishn",
            "name": "[IHN] - Qishn - Yemen",
            "searchName": "IHN-Qishn-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "KAM",
            "countryCode": "YE",
            "cityCode": null,
            "cityName": "Kamaran Island",
            "name": "[KAM] - Kamaran Island - Yemen",
            "searchName": "KAM-Kamaran Island-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "DZA",
            "countryCode": "YT",
            "cityCode": null,
            "cityName": "Dzaoudzi",
            "name": "[DZA] - Dzaoudzi - Mayotte",
            "searchName": "DZA-Dzaoudzi-Mayotte",
            "priority": 0
        },
        {
            "iataCode": "EMG",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Empangeni",
            "name": "[EMG] - Empangeni - South Africa",
            "searchName": "EMG-Empangeni-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "ELS",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "East London",
            "name": "[ELS] - East London - South Africa",
            "searchName": "ELS-East london-East London-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "ELL",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Ellisras",
            "name": "[ELL] - Ellisras - South Africa",
            "searchName": "ELL-Lephalale\\/ ehemals Ellisras-Ellisras-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "DUR",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Durban",
            "name": "[DUR] - Durban - South Africa",
            "searchName": "DUR-Durban-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "DUK",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Dukuduk",
            "name": "[DUK] - Dukuduk - South Africa",
            "searchName": "DUK-Dukuduk-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "FCB",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Ficksburg",
            "name": "[FCB] - Ficksburg - South Africa",
            "searchName": "FCB-Ficksburg-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "CDO",
            "countryCode": "ZA",
            "cityCode": null,
            "cityName": "Cradock",
            "name": "[CDO] - Cradock - South Africa",
            "searchName": "CDO-Cradock-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "MNT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Minto",
            "name": "[MNT] - Minto - United States",
            "searchName": "MNT-Minto-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MNM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Menominee",
            "name": "[MNM] - Menominee - United States",
            "searchName": "MNM-Menominee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MNN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Marion",
            "name": "[MNN] - Marion - United States",
            "searchName": "MNN-Marion-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MMS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Marks",
            "name": "[MMS] - Marks - United States",
            "searchName": "MMS-Marks-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MMU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Morristown",
            "name": "[MMU] - Morristown - United States",
            "searchName": "MMU-Morristown New Jersey-Morristown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZZV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Zanesville",
            "name": "[ZZV] - Zanesville - United States",
            "searchName": "ZZV-Zanesville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLZ",
            "countryCode": "UY",
            "cityCode": null,
            "cityName": "Melo",
            "name": "[MLZ] - Melo - Uruguay",
            "searchName": "MLZ-Melo-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "MVD",
            "countryCode": "UY",
            "cityCode": null,
            "cityName": "Montevideo",
            "name": "[MVD] - Montevideo - Uruguay",
            "searchName": "MVD-Montevideo-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "PDP",
            "countryCode": "UY",
            "cityCode": null,
            "cityName": "Maldonado",
            "name": "[PDP] - Maldonado - Uruguay",
            "searchName": "PDP-Punta del Este-Maldonado-Punta Del Este-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "PDU",
            "countryCode": "UY",
            "cityCode": null,
            "cityName": "Paysandu",
            "name": "[PDU] - Paysandu - Uruguay",
            "searchName": "PDU-Paysandú-Paysandu-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "RVY",
            "countryCode": "UY",
            "cityCode": null,
            "cityName": "Rivera",
            "name": "[RVY] - Rivera - Uruguay",
            "searchName": "RVY-Rivera-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "VCH",
            "countryCode": "UY",
            "cityCode": null,
            "cityName": "Vichadero",
            "name": "[VCH] - Vichadero - Uruguay",
            "searchName": "VCH-Vichadero-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "TYT",
            "countryCode": "UY",
            "cityCode": null,
            "cityName": "Treinta-y-Tres",
            "name": "[TYT] - Treinta-y-Tres - Uruguay",
            "searchName": "TYT-Treinta y Tres-Treinta-y-Tres-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "TAW",
            "countryCode": "UY",
            "cityCode": null,
            "cityName": "Tacuarembo",
            "name": "[TAW] - Tacuarembo - Uruguay",
            "searchName": "TAW-Tacuarembó-Tacuarembo-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "STY",
            "countryCode": "UY",
            "cityCode": null,
            "cityName": "Salto",
            "name": "[STY] - Salto - Uruguay",
            "searchName": "STY-Salto-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "CYR",
            "countryCode": "UY",
            "cityCode": null,
            "cityName": "Colonia",
            "name": "[CYR] - Colonia - Uruguay",
            "searchName": "CYR-Colonia-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "DZO",
            "countryCode": "UY",
            "cityCode": null,
            "cityName": "Durazno",
            "name": "[DZO] - Durazno - Uruguay",
            "searchName": "DZO-Durazno-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "BUV",
            "countryCode": "UY",
            "cityCode": null,
            "cityName": "Bella Union",
            "name": "[BUV] - Bella Union - Uruguay",
            "searchName": "BUV-Bella Union-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "ATI",
            "countryCode": "UY",
            "cityCode": null,
            "cityName": "Artigas",
            "name": "[ATI] - Artigas - Uruguay",
            "searchName": "ATI-Artigas-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "AZN",
            "countryCode": "UZ",
            "cityCode": null,
            "cityName": "Andizhan",
            "name": "[AZN] - Andizhan - Uzbekistan",
            "searchName": "AZN-Andizhan-Andican-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "AFS",
            "countryCode": "UZ",
            "cityCode": null,
            "cityName": "Zarafshan",
            "name": "[AFS] - Zarafshan - Uzbekistan",
            "searchName": "AFS-Zarafshan-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "BHK",
            "countryCode": "UZ",
            "cityCode": null,
            "cityName": "Bukhara",
            "name": "[BHK] - Bukhara - Uzbekistan",
            "searchName": "BHK-Buchara-Bukhara-Bujara-Boukhara-Buhara-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "FEG",
            "countryCode": "UZ",
            "cityCode": null,
            "cityName": "Fergana",
            "name": "[FEG] - Fergana - Uzbekistan",
            "searchName": "FEG-Fergana-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "KSQ",
            "countryCode": "UZ",
            "cityCode": null,
            "cityName": "Karshi",
            "name": "[KSQ] - Karshi - Uzbekistan",
            "searchName": "KSQ-Karshi-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "MTJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Montrose",
            "name": "[MTJ] - Montrose - United States",
            "searchName": "MTJ-Montrose-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MTW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Manitowoc",
            "name": "[MTW] - Manitowoc - United States",
            "searchName": "MTW-Manitowoc-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MTH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Marathon",
            "name": "[MTH] - Marathon - United States",
            "searchName": "MTH-Marathon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MTM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Metlakatla",
            "name": "[MTM] - Metlakatla - United States",
            "searchName": "MTM-Metlakatla-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MTO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mattoon",
            "name": "[MTO] - Mattoon - United States",
            "searchName": "MTO-Mattoon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MTP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Montauk",
            "name": "[MTP] - Montauk - United States",
            "searchName": "MTP-Montauk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Manley Hot Springs",
            "name": "[MLY] - Manley Hot Springs - United States",
            "searchName": "MLY-Manley Hot Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MML",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Marshall",
            "name": "[MML] - Marshall - United States",
            "searchName": "MML-Marshall-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MMN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Stow",
            "name": "[MMN] - Stow - United States",
            "searchName": "MMN-Stow-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MMH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mammoth Lakes",
            "name": "[MMH] - Mammoth Lakes - United States",
            "searchName": "MMH-Mammoth Lakes-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MMI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Athens",
            "name": "[MMI] - Athens - United States",
            "searchName": "MMI-Athens-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Miles City",
            "name": "[MLS] - Miles City - United States",
            "searchName": "MLS-Miles City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Millinocket",
            "name": "[MLT] - Millinocket - United States",
            "searchName": "MLT-Millinocket-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Monroe",
            "name": "[MLU] - Monroe - United States",
            "searchName": "MLU-Monroe-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Milford",
            "name": "[MLF] - Milford - United States",
            "searchName": "MLF-Milford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Moline",
            "name": "[MLI] - Moline - United States",
            "searchName": "MLI-Moline Illinois-Moline-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Milledgeville",
            "name": "[MLJ] - Milledgeville - United States",
            "searchName": "MLJ-Milledgeville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Malta",
            "name": "[MLK] - Malta - United States",
            "searchName": "MLK-Malta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Marshall",
            "name": "[MLL] - Marshall - United States",
            "searchName": "MLL-Marshall-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MKO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Muskogee",
            "name": "[MKO] - Muskogee - United States",
            "searchName": "MKO-Muskogee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MKT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mankato",
            "name": "[MKT] - Mankato - United States",
            "searchName": "MKT-Mankato-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Melbourne",
            "name": "[MLB] - Melbourne - United States",
            "searchName": "MLB-Melbourne-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mc Alester",
            "name": "[MLC] - Mc Alester - United States",
            "searchName": "MLC-Mc Alester-McAlester-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Malad City",
            "name": "[MLD] - Malad City - United States",
            "searchName": "MLD-Malad City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MJX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Toms River",
            "name": "[MJX] - Toms River - United States",
            "searchName": "MJX-Toms River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLZ",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Calabozo",
            "name": "[CLZ] - Calabozo - Venezuela",
            "searchName": "CLZ-Calabozo-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "CZE",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Coro",
            "name": "[CZE] - Coro - Venezuela",
            "searchName": "CZE-Coro-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "CUV",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Casigua",
            "name": "[CUV] - Casigua - Venezuela",
            "searchName": "CUV-Casigua-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "CUM",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Cumana",
            "name": "[CUM] - Cumana - Venezuela",
            "searchName": "CUM-Cumana-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "CUP",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Carupano",
            "name": "[CUP] - Carupano - Venezuela",
            "searchName": "CUP-Carupano-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "CXA",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Caicara Del Orinoco",
            "name": "[CXA] - Caicara Del Orinoco - Venezuela",
            "searchName": "CXA-Caicara de Oro-Caicara Del Orinoco-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "EOR",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "El Dorado",
            "name": "[EOR] - El Dorado - Venezuela",
            "searchName": "EOR-El Dorado-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "EOZ",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Elorza",
            "name": "[EOZ] - Elorza - Venezuela",
            "searchName": "EOZ-Elorza-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "ELX",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "El Tigre",
            "name": "[ELX] - El Tigre - Venezuela",
            "searchName": "ELX-El Tigre-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "KTV",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Kamarata",
            "name": "[KTV] - Kamarata - Venezuela",
            "searchName": "KTV-Kamarata-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "LAG",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "La Guaira",
            "name": "[LAG] - La Guaira - Venezuela",
            "searchName": "LAG-La Guaira-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "LSP",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Las Piedras",
            "name": "[LSP] - Las Piedras - Venezuela",
            "searchName": "LSP-Las Piedras-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "LRV",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Los Roques",
            "name": "[LRV] - Los Roques - Venezuela",
            "searchName": "LRV-Los Roques-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "LPJ",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Pijiguaos",
            "name": "[LPJ] - Pijiguaos - Venezuela",
            "searchName": "LPJ-Pijiguaos-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "LFR",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "La Fria",
            "name": "[LFR] - La Fria - Venezuela",
            "searchName": "LFR-La Fria-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "LGY",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Lagunillas",
            "name": "[LGY] - Lagunillas - Venezuela",
            "searchName": "LGY-Lagunillas-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "MAR",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Maracaibo",
            "name": "[MAR] - Maracaibo - Venezuela",
            "searchName": "MAR-Maracaibo-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "GDO",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Guasdualito",
            "name": "[GDO] - Guasdualito - Venezuela",
            "searchName": "GDO-Guasdualito-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "GUQ",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Guanare",
            "name": "[GUQ] - Guanare - Venezuela",
            "searchName": "GUQ-Guanare-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "GUI",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Guiria",
            "name": "[GUI] - Guiria - Venezuela",
            "searchName": "GUI-Guiria-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "ICA",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Icabaru",
            "name": "[ICA] - Icabaru - Venezuela",
            "searchName": "ICA-Icabaru-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "KAV",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Kavanayen",
            "name": "[KAV] - Kavanayen - Venezuela",
            "searchName": "KAV-Kavanayen-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "SVV",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "San Salvador De",
            "name": "[SVV] - San Salvador De - Venezuela",
            "searchName": "SVV-San Salvador De-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "SVZ",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "San Antonio",
            "name": "[SVZ] - San Antonio - Venezuela",
            "searchName": "SVZ-San Antonio-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "STD",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Santo Domingo",
            "name": "[STD] - Santo Domingo - Venezuela",
            "searchName": "STD-Santo Domingo-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "STB",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Santa Barbara Ed",
            "name": "[STB] - Santa Barbara Ed - Venezuela",
            "searchName": "STB-Santa Barbara Ed-Santa Barbara-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "TMO",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Tumeremo",
            "name": "[TMO] - Tumeremo - Venezuela",
            "searchName": "TMO-Tumeremo-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "URM",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Uriman",
            "name": "[URM] - Uriman - Venezuela",
            "searchName": "URM-Uriman-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "TUV",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Tucupita",
            "name": "[TUV] - Tucupita - Venezuela",
            "searchName": "TUV-Tucupita-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "VCR",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Carora",
            "name": "[VCR] - Carora - Venezuela",
            "searchName": "VCR-Carora-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "VDP",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Valle De Pascua",
            "name": "[VDP] - Valle De Pascua - Venezuela",
            "searchName": "VDP-Valle De Pascua-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "VIG",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "El Vigia",
            "name": "[VIG] - El Vigia - Venezuela",
            "searchName": "VIG-El Vigia-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "VLV",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Valera",
            "name": "[VLV] - Valera - Venezuela",
            "searchName": "VLV-Valera-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "VLN",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Valencia",
            "name": "[VLN] - Valencia - Venezuela",
            "searchName": "VLN-Valencia-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "WOK",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Wonken",
            "name": "[WOK] - Wonken - Venezuela",
            "searchName": "WOK-Wonken-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "PMV",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Porlamar",
            "name": "[PMV] - Porlamar - Venezuela",
            "searchName": "PMV-Porlamar-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "PTM",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Palmarito",
            "name": "[PTM] - Palmarito - Venezuela",
            "searchName": "PTM-Palmarito-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "PPZ",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Puerto Paez",
            "name": "[PPZ] - Puerto Paez - Venezuela",
            "searchName": "PPZ-Puerto Páez-Puerto Paez-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "PPH",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Peraitepuy",
            "name": "[PPH] - Peraitepuy - Venezuela",
            "searchName": "PPH-Peraitepuy-Parai-tepui-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "PZO",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Puerto Ordaz",
            "name": "[PZO] - Puerto Ordaz - Venezuela",
            "searchName": "PZO-Puerto Ordaz-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "PYH",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Puerto Ayacucho",
            "name": "[PYH] - Puerto Ayacucho - Venezuela",
            "searchName": "PYH-Puerto Ayacucho-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "SFX",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "San Felix",
            "name": "[SFX] - San Felix - Venezuela",
            "searchName": "SFX-San Felix-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "SFD",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "San Fernando De Apure",
            "name": "[SFD] - San Fernando De Apure - Venezuela",
            "searchName": "SFD-San Fernando de Apure-San Fernando De Apure-San Fernando-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "SNV",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Santa Elena",
            "name": "[SNV] - Santa Elena - Venezuela",
            "searchName": "SNV-Santa Elena-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "SOM",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "San Tome",
            "name": "[SOM] - San Tome - Venezuela",
            "searchName": "SOM-San Tome-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "SNF",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "San Felipe",
            "name": "[SNF] - San Felipe - Venezuela",
            "searchName": "SNF-San Felipe-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "SBB",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Santa Barbara de Barinas",
            "name": "[SBB] - Santa Barbara de Barinas - Venezuela",
            "searchName": "SBB-Santa Barbara de Barinas-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "SCI",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "San Cristobal",
            "name": "[SCI] - San Cristobal - Venezuela",
            "searchName": "SCI-San Cristobal-San Crist-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "MRD",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Merida",
            "name": "[MRD] - Merida - Venezuela",
            "searchName": "MRD-Merida-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "MUN",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Maturin",
            "name": "[MUN] - Maturin - Venezuela",
            "searchName": "MUN-Matur-Maturin-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "MYC",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Maracay",
            "name": "[MYC] - Maracay - Venezuela",
            "searchName": "MYC-Maracay-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "PDZ",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Pedernales",
            "name": "[PDZ] - Pedernales - Venezuela",
            "searchName": "PDZ-Pedernales-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "PBL",
            "countryCode": "VE",
            "cityCode": null,
            "cityName": "Puerto Cabello",
            "name": "[PBL] - Puerto Cabello - Venezuela",
            "searchName": "PBL-Puerto Cabello-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "NSX",
            "countryCode": "VG",
            "cityCode": null,
            "cityName": "Nord Sound",
            "name": "[NSX] - Nord Sound - British Virgin Islands",
            "searchName": "NSX-Nord Sound-British Virgin Islands-Britische Jungferninseln-Ingiliz Virgin Adalari-Islas Vírgenes Británicas-Îles Vierges britanniques-Isole Vergini Britanniche",
            "priority": 0
        },
        {
            "iataCode": "NGD",
            "countryCode": "VG",
            "cityCode": null,
            "cityName": "Anegada",
            "name": "[NGD] - Anegada - British Virgin Islands",
            "searchName": "NGD-Anegada-British Virgin Islands-Britische Jungferninseln-Ingiliz Virgin Adalari-Islas Vírgenes Británicas-Îles Vierges britanniques-Isole Vergini Britanniche",
            "priority": 0
        },
        {
            "iataCode": "VIJ",
            "countryCode": "VG",
            "cityCode": null,
            "cityName": "Spanish Town",
            "name": "[VIJ] - Spanish Town - British Virgin Islands",
            "searchName": "VIJ-Spanish Town-Virgin Gorda-British Virgin Islands-Britische Jungferninseln-Ingiliz Virgin Adalari-Islas Vírgenes Británicas-Îles Vierges britanniques-Isole Vergini Britanniche",
            "priority": 0
        },
        {
            "iataCode": "TOV",
            "countryCode": "VG",
            "cityCode": null,
            "cityName": "Tortola",
            "name": "[TOV] - Tortola - British Virgin Islands",
            "searchName": "TOV-Tortola-British Virgin Islands-Britische Jungferninseln-Ingiliz Virgin Adalari-Islas Vírgenes Británicas-Îles Vierges britanniques-Isole Vergini Britanniche",
            "priority": 0
        },
        {
            "iataCode": "EIS",
            "countryCode": "VG",
            "cityCode": null,
            "cityName": "Beef Island",
            "name": "[EIS] - Beef Island - British Virgin Islands",
            "searchName": "EIS-Beef Island--British Virgin Islands-Britische Jungferninseln-Ingiliz Virgin Adalari-Islas Vírgenes Británicas-Îles Vierges britanniques-Isole Vergini Britanniche",
            "priority": 0
        },
        {
            "iataCode": "STT",
            "countryCode": "VI",
            "cityCode": null,
            "cityName": "Charlotte Amalie",
            "name": "[STT] - Charlotte Amalie - U.S. Virgin Islands",
            "searchName": "STT-St Thomas Island-Charlotte Amalie--Ilhas Virgens (E.U.A.)-Maagdeneilanden(VS)-Kepulauan Virgin Amerika Serikat-Amerikanska Jungfruöarna-Kepulauan Virgin-U.S. Virgin Islands-Amerikanische Jungferninseln-ABD Virgin Adalari-Islas Vírgenes de los Estados Unidos-Îles Vierges des États-Unis-Isole Vergini Americane",
            "priority": 0
        },
        {
            "iataCode": "STX",
            "countryCode": "VI",
            "cityCode": null,
            "cityName": "Christiansted",
            "name": "[STX] - Christiansted - U.S. Virgin Islands",
            "searchName": "STX-St Croix Island-Christiansted-Saint Croix-Ilhas Virgens (E.U.A.)-Maagdeneilanden(VS)-Kepulauan Virgin Amerika Serikat-Amerikanska Jungfruöarna-Kepulauan Virgin-U.S. Virgin Islands-Amerikanische Jungferninseln-ABD Virgin Adalari-Islas Vírgenes de los Estados Unidos-Îles Vierges des États-Unis-Isole Vergini Americane",
            "priority": 0
        },
        {
            "iataCode": "SJF",
            "countryCode": "VI",
            "cityCode": null,
            "cityName": "St John Island",
            "name": "[SJF] - St John Island - U.S. Virgin Islands",
            "searchName": "SJF-St John Island-St John-Ilhas Virgens (E.U.A.)-Maagdeneilanden(VS)-Kepulauan Virgin Amerika Serikat-Amerikanska Jungfruöarna-Kepulauan Virgin-U.S. Virgin Islands-Amerikanische Jungferninseln-ABD Virgin Adalari-Islas Vírgenes de los Estados Unidos-Îles Vierges des États-Unis-Isole Vergini Americane",
            "priority": 0
        },
        {
            "iataCode": "OYS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Yosemite",
            "name": "[OYS] - Yosemite - United States",
            "searchName": "OYS-Yosemite-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OZA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ozona",
            "name": "[OZA] - Ozona - United States",
            "searchName": "OZA-Ozona-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OZR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ozark",
            "name": "[OZR] - Ozark - United States",
            "searchName": "OZR-Ozark-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OWA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Owatonna",
            "name": "[OWA] - Owatonna - United States",
            "searchName": "OWA-Owatonna-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OWB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Owensboro",
            "name": "[OWB] - Owensboro - United States",
            "searchName": "OWB-Owensboro-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OWD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Norwood",
            "name": "[OWD] - Norwood - United States",
            "searchName": "OWD-Norwood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OVE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Oroville",
            "name": "[OVE] - Oroville - United States",
            "searchName": "OVE-Oroville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OXC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Oxford",
            "name": "[OXC] - Oxford - United States",
            "searchName": "OXC-Oxford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OXD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Oxford",
            "name": "[OXD] - Oxford - United States",
            "searchName": "OXD-Oxford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OWK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Norridgewock",
            "name": "[OWK] - Norridgewock - United States",
            "searchName": "OWK-Norrigdewock-Norridgewock-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OXR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ventura",
            "name": "[OXR] - Ventura - United States",
            "searchName": "OXR-Ventura-Oxnard-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OUN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Norman",
            "name": "[OUN] - Norman - United States",
            "searchName": "OUN-Norman-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OTG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Worthington",
            "name": "[OTG] - Worthington - United States",
            "searchName": "OTG-Worthington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OTH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "North Bend",
            "name": "[OTH] - North Bend - United States",
            "searchName": "OTH-North Bend Oregon-North Bend-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OTM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ottumwa",
            "name": "[OTM] - Ottumwa - United States",
            "searchName": "OTM-Ottumwa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OTN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Oaktown",
            "name": "[OTN] - Oaktown - United States",
            "searchName": "OTN-Oaktown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OTO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Otto",
            "name": "[OTO] - Otto - United States",
            "searchName": "OTO-Otto-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OTZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kotzebue",
            "name": "[OTZ] - Kotzebue - United States",
            "searchName": "OTZ-Kotzebue-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OTK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tillamook",
            "name": "[OTK] - Tillamook - United States",
            "searchName": "OTK-Tillamook-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OTS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Anacortes",
            "name": "[OTS] - Anacortes - United States",
            "searchName": "OTS-Anacortes-Ancortes-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ORF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Norfolk",
            "name": "[ORF] - Norfolk - United States",
            "searchName": "ORF-Norfolk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ORH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Worcester",
            "name": "[ORH] - Worcester - United States",
            "searchName": "ORH-Worcester-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ORI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Port Lions",
            "name": "[ORI] - Port Lions - United States",
            "searchName": "ORI-Port Lions-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OPL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Opelousas",
            "name": "[OPL] - Opelousas - United States",
            "searchName": "OPL-Opelousas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ONL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Oneill",
            "name": "[ONL] - Oneill - United States",
            "searchName": "ONL-Oneill-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ONM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Socorro",
            "name": "[ONM] - Socorro - United States",
            "searchName": "ONM-Socorro-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ONN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Onion Bay",
            "name": "[ONN] - Onion Bay - United States",
            "searchName": "ONN-Onion Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ONO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ontario",
            "name": "[ONO] - Ontario - United States",
            "searchName": "ONO-Ontario-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ONP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Newport",
            "name": "[ONP] - Newport - United States",
            "searchName": "ONP-Newport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ONY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Olney",
            "name": "[ONY] - Olney - United States",
            "searchName": "ONY-Olney-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OOA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Oskaloosa",
            "name": "[OOA] - Oskaloosa - United States",
            "searchName": "OOA-Oskaloosa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OOK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Toksook Bay",
            "name": "[OOK] - Toksook Bay - United States",
            "searchName": "OOK-Toksook Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ONT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ontario",
            "name": "[ONT] - Ontario - United States",
            "searchName": "ONT-Ontario-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ONH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Oneonta",
            "name": "[ONH] - Oneonta - United States",
            "searchName": "ONH-Oneonta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MSV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Monticello",
            "name": "[MSV] - Monticello - United States",
            "searchName": "MSV-Monticello-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PWR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Port Walter",
            "name": "[PWR] - Port Walter - United States",
            "searchName": "PWR-Port Walter-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PWT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bremerton",
            "name": "[PWT] - Bremerton - United States",
            "searchName": "PWT-Bremerton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MTC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mt Clemens",
            "name": "[MTC] - Mt Clemens - United States",
            "searchName": "MTC-Mt Clemens-Detroit-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MSO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Missoula",
            "name": "[MSO] - Missoula - United States",
            "searchName": "MSO-Missoula-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PSF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pittsfield",
            "name": "[PSF] - Pittsfield - United States",
            "searchName": "PSF-Pittsfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PHX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Phoenix",
            "name": "[PHX] - Phoenix - United States",
            "searchName": "PHX-Phoenix-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PHD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "New Philadelphia",
            "name": "[PHD] - New Philadelphia - United States",
            "searchName": "PHD-New Philadelphia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PHF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Newport News",
            "name": "[PHF] - Newport News - United States",
            "searchName": "PHF-Newport News/Williamsburg-Newport News-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PHK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pahokee",
            "name": "[PHK] - Pahokee - United States",
            "searchName": "PHK-Pahokee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PRZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Prineville",
            "name": "[PRZ] - Prineville - United States",
            "searchName": "PRZ-Prineville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PSB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Bellefonte",
            "name": "[PSB] - Bellefonte - United States",
            "searchName": "PSB-Bellefonte-Philipsburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PSC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pasco",
            "name": "[PSC] - Pasco - United States",
            "searchName": "PSC-Pasco-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PSK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Dublin",
            "name": "[PSK] - Dublin - United States",
            "searchName": "PSK-Dublin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PSN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Palestine",
            "name": "[PSN] - Palestine - United States",
            "searchName": "PSN-Palestine-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PSP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Palm Springs",
            "name": "[PSP] - Palm Springs - United States",
            "searchName": "PSP-Palm Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Port Alsworth",
            "name": "[PTA] - Port Alsworth - United States",
            "searchName": "PTA-Port Alsworth-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Petersburg",
            "name": "[PTB] - Petersburg - United States",
            "searchName": "PTB-Petersburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Port Alice",
            "name": "[PTC] - Port Alice - United States",
            "searchName": "PTC-Port Alice-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Port Alexander",
            "name": "[PTD] - Port Alexander - United States",
            "searchName": "PTD-Port Alexander-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PSX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Palacios",
            "name": "[PSX] - Palacios - United States",
            "searchName": "PSX-Palacios-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pontiac",
            "name": "[PTK] - Pontiac - United States",
            "searchName": "PTK-Pontiac-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Port Armstrong",
            "name": "[PTL] - Port Armstrong - United States",
            "searchName": "PTL-Port Armstrong-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PUB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pueblo",
            "name": "[PUB] - Pueblo - United States",
            "searchName": "PUB-Pueblo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PUC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Price",
            "name": "[PUC] - Price - United States",
            "searchName": "PUC-Price-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Port Heiden",
            "name": "[PTH] - Port Heiden - United States",
            "searchName": "PTH-Port Heiden-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pleasant Harbour",
            "name": "[PTR] - Pleasant Harbour - United States",
            "searchName": "PTR-Pleasant Harbor-Pleasant Harbour-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pittsburg",
            "name": "[PTS] - Pittsburg - United States",
            "searchName": "PTS-Pittsburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pratt",
            "name": "[PTT] - Pratt - United States",
            "searchName": "PTT-Pratt-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Platinum",
            "name": "[PTU] - Platinum - United States",
            "searchName": "PTU-Platinum-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Porterville",
            "name": "[PTV] - Porterville - United States",
            "searchName": "PTV-Porterville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pottstown",
            "name": "[PTW] - Pottstown - United States",
            "searchName": "PTW-Pottstown-Potsdam-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Patterson",
            "name": "[PTN] - Patterson - United States",
            "searchName": "PTN-Patterson-Morgan City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PUL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Poulsbo",
            "name": "[PUL] - Poulsbo - United States",
            "searchName": "PUL-Poulsbo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PUO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Prudhoe Bay",
            "name": "[PUO] - Prudhoe Bay - United States",
            "searchName": "PUO-Prudhoe Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OSB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Osage Beach",
            "name": "[OSB] - Osage Beach - United States",
            "searchName": "OSB-Osage Beach-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OSC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Oscoda",
            "name": "[OSC] - Oscoda - United States",
            "searchName": "OSC-Oscoda-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ORQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Norwalk",
            "name": "[ORQ] - Norwalk - United States",
            "searchName": "ORQ-Norwalk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ORT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Northway",
            "name": "[ORT] - Northway - United States",
            "searchName": "ORT-Northway-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OSH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Oshkosh",
            "name": "[OSH] - Oshkosh - United States",
            "searchName": "OSH-Oshkosh-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ORV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Noorvik",
            "name": "[ORV] - Noorvik - United States",
            "searchName": "ORV-Noorvik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OSX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kosciusko",
            "name": "[OSX] - Kosciusko - United States",
            "searchName": "OSX-Kosciusko-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ODW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Oak Harbor",
            "name": "[ODW] - Oak Harbor - United States",
            "searchName": "ODW-Oak Harbor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OEA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Vincennes",
            "name": "[OEA] - Vincennes - United States",
            "searchName": "OEA-Vincennes-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OEO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Osceola",
            "name": "[OEO] - Osceola - United States",
            "searchName": "OEO-Osceola-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OFK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Norfolk",
            "name": "[OFK] - Norfolk - United States",
            "searchName": "OFK-Norfolk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OGA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ogallala",
            "name": "[OGA] - Ogallala - United States",
            "searchName": "OGA-Ogallala-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OGB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Orangeburg",
            "name": "[OGB] - Orangeburg - United States",
            "searchName": "OGB-Orangeburg-Columbia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OGD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ogden",
            "name": "[OGD] - Ogden - United States",
            "searchName": "OGD-Ogden-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OGS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ogdensburg",
            "name": "[OGS] - Ogdensburg - United States",
            "searchName": "OGS-Ogdensburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OGG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Maui",
            "name": "[OGG] - Maui - United States",
            "searchName": "OGG-Kahului Hawaii-Maui-Kahului-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OHC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Northeast Cape",
            "name": "[OHC] - Northeast Cape - United States",
            "searchName": "OHC-Northeast Cape-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ODC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Oakdale",
            "name": "[ODC] - Oakdale - United States",
            "searchName": "ODC-Oakdale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OCW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Washington",
            "name": "[OCW] - Washington - United States",
            "searchName": "OCW-Washington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OCN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Oceanside",
            "name": "[OCN] - Oceanside - United States",
            "searchName": "OCN-Oceanside-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OCA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ocean Reef",
            "name": "[OCA] - Ocean Reef - United States",
            "searchName": "OCA-Ocean Reef-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OCE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ocean City",
            "name": "[OCE] - Ocean City - United States",
            "searchName": "OCE-Ocean City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OCF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ocala",
            "name": "[OCF] - Ocala - United States",
            "searchName": "OCF-Ocala-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OCH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Nacogdoches",
            "name": "[OCH] - Nacogdoches - United States",
            "searchName": "OCH-Nacogdoches-Lufkin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OCI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Oceanic",
            "name": "[OCI] - Oceanic - United States",
            "searchName": "OCI-Oceanic-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OBT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Oakland",
            "name": "[OBT] - Oakland - United States",
            "searchName": "OBT-Oakland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OBU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kobuk",
            "name": "[OBU] - Kobuk - United States",
            "searchName": "OBU-Kobuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OBK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Northbrook",
            "name": "[OBK] - Northbrook - United States",
            "searchName": "OBK-Northbrook-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OBE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Okeechobee",
            "name": "[OBE] - Okeechobee - United States",
            "searchName": "OBE-Okeechobee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OKS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Oshkosh",
            "name": "[OKS] - Oshkosh - United States",
            "searchName": "OKS-Oshkosh-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OKM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Okmulgee",
            "name": "[OKM] - Okmulgee - United States",
            "searchName": "OKM-Okmulgee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OKK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kokomo",
            "name": "[OKK] - Kokomo - United States",
            "searchName": "OKK-Kokomo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OIC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Norwich",
            "name": "[OIC] - Norwich - United States",
            "searchName": "OIC-Norwich-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OIL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Oil City",
            "name": "[OIL] - Oil City - United States",
            "searchName": "OIL-Oil City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OKC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Oklahoma City",
            "name": "[OKC] - Oklahoma City - United States",
            "searchName": "OKC-Oklahoma City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ONA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Winona",
            "name": "[ONA] - Winona - United States",
            "searchName": "ONA-Winona-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OME",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Nome",
            "name": "[OME] - Nome - United States",
            "searchName": "OME-Nome-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OMK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Omak",
            "name": "[OMK] - Omak - United States",
            "searchName": "OMK-Omak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OLS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Nogales",
            "name": "[OLS] - Nogales - United States",
            "searchName": "OLS-Nogales-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OLU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Columbus",
            "name": "[OLU] - Columbus - United States",
            "searchName": "OLU-Columbus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OLV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Olive Branch",
            "name": "[OLV] - Olive Branch - United States",
            "searchName": "OLV-Olive Branch-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OLY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Olney",
            "name": "[OLY] - Olney - United States",
            "searchName": "OLY-Olney-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OMA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Omaha",
            "name": "[OMA] - Omaha - United States",
            "searchName": "OMA-Omaha-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OLM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Olympia",
            "name": "[OLM] - Olympia - United States",
            "searchName": "OLM-Olympia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OLD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Old Town",
            "name": "[OLD] - Old Town - United States",
            "searchName": "OLD-Old Town-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OLE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Olean",
            "name": "[OLE] - Olean - United States",
            "searchName": "OLE-Olean-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OLF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wolf Point",
            "name": "[OLF] - Wolf Point - United States",
            "searchName": "OLF-Wolf Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OLH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Old Harbor",
            "name": "[OLH] - Old Harbor - United States",
            "searchName": "OLH-Old Harbor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NOT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Novato",
            "name": "[NOT] - Novato - United States",
            "searchName": "NOT-Novato-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NPH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Nephi",
            "name": "[NPH] - Nephi - United States",
            "searchName": "NPH-Nephi-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NQI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kingsville",
            "name": "[NQI] - Kingsville - United States",
            "searchName": "NQI-Kingsville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NPT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Newport",
            "name": "[NPT] - Newport - United States",
            "searchName": "NPT-Newport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NOL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Nakolik River",
            "name": "[NOL] - Nakolik River - United States",
            "searchName": "NOL-Nakolik River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NSL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Slayton",
            "name": "[NSL] - Slayton - United States",
            "searchName": "NSL-Slayton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NSE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Milton",
            "name": "[NSE] - Milton - United States",
            "searchName": "NSE-Milton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NTD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Port Hueneme",
            "name": "[NTD] - Port Hueneme - United States",
            "searchName": "NTD-Port Hueneme-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NRI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Shangri-la",
            "name": "[NRI] - Shangri-la - United States",
            "searchName": "NRI-Shangri-la-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NRS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Imperial Beach",
            "name": "[NRS] - Imperial Beach - United States",
            "searchName": "NRS-Imperial Beach-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NRB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mayport",
            "name": "[NRB] - Mayport - United States",
            "searchName": "NRB-Mayport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NRC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Crows Landing",
            "name": "[NRC] - Crows Landing - United States",
            "searchName": "NRC-Crows Landing-CrowS Landing-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NTU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Oceana",
            "name": "[NTU] - Oceana - United States",
            "searchName": "NTU-Oceana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NTJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Manti",
            "name": "[NTJ] - Manti - United States",
            "searchName": "NTJ-Manti-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NUI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Nuiqsut",
            "name": "[NUI] - Nuiqsut - United States",
            "searchName": "NUI-Nuiqsut-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NUL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Nulato",
            "name": "[NUL] - Nulato - United States",
            "searchName": "NUL-Nulato-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NUP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Nunapitchuk",
            "name": "[NUP] - Nunapitchuk - United States",
            "searchName": "NUP-Nunapitchuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NUQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mountain View",
            "name": "[NUQ] - Mountain View - United States",
            "searchName": "NUQ-Mountain View-Mountain Village-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NVN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Beckwourth",
            "name": "[NVN] - Beckwourth - United States",
            "searchName": "NVN-Beckwourth-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NUW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Whidbey Island",
            "name": "[NUW] - Whidbey Island - United States",
            "searchName": "NUW-Whidbey Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NVD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Nevada",
            "name": "[NVD] - Nevada - United States",
            "searchName": "NVD-Nevada-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NWH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Newport",
            "name": "[NWH] - Newport - United States",
            "searchName": "NWH-Newport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NYG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Quantico",
            "name": "[NYG] - Quantico - United States",
            "searchName": "NYG-Quantico-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NXX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Willow Grove",
            "name": "[NXX] - Willow Grove - United States",
            "searchName": "NXX-Willow Grove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OAJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Jacksonville",
            "name": "[OAJ] - Jacksonville - United States",
            "searchName": "OAJ-Jacksonville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OAK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Oakland",
            "name": "[OAK] - Oakland - United States",
            "searchName": "OAK-Oakland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NZW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "South Weymouth",
            "name": "[NZW] - South Weymouth - United States",
            "searchName": "NZW-South Weymouth-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MXO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Monticello",
            "name": "[MXO] - Monticello - United States",
            "searchName": "MXO-Monticello-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MXY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mccarthy",
            "name": "[MXY] - Mccarthy - United States",
            "searchName": "MXY-McCarthy-Mccarthy-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MYH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Marble Canyon",
            "name": "[MYH] - Marble Canyon - United States",
            "searchName": "MYH-Marble Canyon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MXA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Manila",
            "name": "[MXA] - Manila - United States",
            "searchName": "MXA-Manila-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MXC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Monticello",
            "name": "[MXC] - Monticello - United States",
            "searchName": "MXC-Monticello-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MXE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Maxton",
            "name": "[MXE] - Maxton - United States",
            "searchName": "MXE-Maxton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MXG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Marlborough",
            "name": "[MXG] - Marlborough - United States",
            "searchName": "MXG-Marlborough-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MWO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Middletown",
            "name": "[MWO] - Middletown - United States",
            "searchName": "MWO-Middletown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MWA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Marion",
            "name": "[MWA] - Marion - United States",
            "searchName": "MWA-Marion-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MWS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mount Wilson",
            "name": "[MWS] - Mount Wilson - United States",
            "searchName": "MWS-Mount Wilson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MWH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Moses Lake",
            "name": "[MWH] - Moses Lake - United States",
            "searchName": "MWH-Moses Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MVY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Vineyard Haven",
            "name": "[MVY] - Vineyard Haven - United States",
            "searchName": "MVY-Martha s Vineyard-Vineyard Haven-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MWL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mineral Wells",
            "name": "[MWL] - Mineral Wells - United States",
            "searchName": "MWL-Mineral Wells-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MWM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Windom",
            "name": "[MWM] - Windom - United States",
            "searchName": "MWM-Windom-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MVL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Stowe",
            "name": "[MVL] - Stowe - United States",
            "searchName": "MVL-Stowe-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MVM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kayenta",
            "name": "[MVM] - Kayenta - United States",
            "searchName": "MVM-Kayenta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MVN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mount Vernon",
            "name": "[MVN] - Mount Vernon - United States",
            "searchName": "MVN-Mount Vernon-Mt Vernon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MVW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mount Vernon",
            "name": "[MVW] - Mount Vernon - United States",
            "searchName": "MVW-Mount Vernon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MYU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mekoryuk",
            "name": "[MYU] - Mekoryuk - United States",
            "searchName": "MYU-Mekoryuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MYV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Marysville",
            "name": "[MYV] - Marysville - United States",
            "searchName": "MYV-Marysville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MYR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Myrtle Beach",
            "name": "[MYR] - Myrtle Beach - United States",
            "searchName": "MYR-Myrtle Beach-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MYK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "May Creek",
            "name": "[MYK] - May Creek - United States",
            "searchName": "MYK-May Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MYL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mccall",
            "name": "[MYL] - Mccall - United States",
            "searchName": "MYL-Mccall-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MZJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Marana",
            "name": "[MZJ] - Marana - United States",
            "searchName": "MZJ-Marana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NAX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kapolei",
            "name": "[NAX] - Kapolei - United States",
            "searchName": "NAX-Kapolei-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MZZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Marion",
            "name": "[MZZ] - Marion - United States",
            "searchName": "MZZ-Marion-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NLC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lemoore",
            "name": "[NLC] - Lemoore - United States",
            "searchName": "NLC-Lemoore-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NLN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kneeland",
            "name": "[NLN] - Kneeland - United States",
            "searchName": "NLN-Kneeland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NLE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Niles",
            "name": "[NLE] - Niles - United States",
            "searchName": "NLE-Niles-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NLG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Nelson Lagoon",
            "name": "[NLG] - Nelson Lagoon - United States",
            "searchName": "NLG-Nelson Lagoon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NKV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Nichen Cove",
            "name": "[NKV] - Nichen Cove - United States",
            "searchName": "NKV-Nichen Cove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NNK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Naknek",
            "name": "[NNK] - Naknek - United States",
            "searchName": "NNK-Naknek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NNL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Nondalton",
            "name": "[NNL] - Nondalton - United States",
            "searchName": "NNL-Nondalton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NME",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Nightmute",
            "name": "[NME] - Nightmute - United States",
            "searchName": "NME-Nightmute-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NIE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Niblack",
            "name": "[NIE] - Niblack - United States",
            "searchName": "NIE-Niblack-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NIN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ninilchik",
            "name": "[NIN] - Ninilchik - United States",
            "searchName": "NIN-Ninilchik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NIR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Beeville",
            "name": "[NIR] - Beeville - United States",
            "searchName": "NIR-Beeville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NIB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Nikolai",
            "name": "[NIB] - Nikolai - United States",
            "searchName": "NIB-Nikolai-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NHX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Foley",
            "name": "[NHX] - Foley - United States",
            "searchName": "NHX-Foley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NHZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Brunswick",
            "name": "[NHZ] - Brunswick - United States",
            "searchName": "NHZ-Brunswick-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NKI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Naukiti",
            "name": "[NKI] - Naukiti - United States",
            "searchName": "NKI-Naukiti-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NBU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Glenview",
            "name": "[NBU] - Glenview - United States",
            "searchName": "NBU-Glenview-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NCN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "New Chenega",
            "name": "[NCN] - New Chenega - United States",
            "searchName": "NCN-New Chenega-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NCO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Quonset Point",
            "name": "[NCO] - Quonset Point - United States",
            "searchName": "NCO-Quonset Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NDV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Anacostia",
            "name": "[NDV] - Anacostia - United States",
            "searchName": "NDV-Anacostia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NFL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fallon",
            "name": "[NFL] - Fallon - United States",
            "searchName": "NFL-Fallon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NEA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Glynco",
            "name": "[NEA] - Glynco - United States",
            "searchName": "NEA-Glynco-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NEL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Lakehurst",
            "name": "[NEL] - Lakehurst - United States",
            "searchName": "NEL-Lakehurst-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NEN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Whitehouse",
            "name": "[NEN] - Whitehouse - United States",
            "searchName": "NEN-Whitehouse-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NHK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Patuxent River",
            "name": "[NHK] - Patuxent River - United States",
            "searchName": "NHK-Patuxent River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NGZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Alameda",
            "name": "[NGZ] - Alameda - United States",
            "searchName": "NGZ-Alameda-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MPZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mount Pleasant",
            "name": "[MPZ] - Mount Pleasant - United States",
            "searchName": "MPZ-Mount Pleasant-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MQB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Macomb",
            "name": "[MQB] - Macomb - United States",
            "searchName": "MQB-Macomb-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MPV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Montpelier",
            "name": "[MPV] - Montpelier - United States",
            "searchName": "MPV-Montpelier-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MPR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mcpherson",
            "name": "[MPR] - Mcpherson - United States",
            "searchName": "MPR-Mcpherson-McPherson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MPS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mount Pleasant",
            "name": "[MPS] - Mount Pleasant - United States",
            "searchName": "MPS-Mount Pleasant-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MPO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mount Pocono",
            "name": "[MPO] - Mount Pocono - United States",
            "searchName": "MPO-Mount Pocono-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MQI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Quincy",
            "name": "[MQI] - Quincy - United States",
            "searchName": "MQI-Quincy-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MQY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Smyrna",
            "name": "[MQY] - Smyrna - United States",
            "searchName": "MQY-Smyrna-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MQW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mc Rae",
            "name": "[MQW] - Mc Rae - United States",
            "searchName": "MQW-Mc Rae-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MQT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Marquette",
            "name": "[MQT] - Marquette - United States",
            "searchName": "MQT-Marquette-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MRY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Monterey",
            "name": "[MRY] - Monterey - United States",
            "searchName": "MRY-Carmel/Monterey-Monterey-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MSC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mesa",
            "name": "[MSC] - Mesa - United States",
            "searchName": "MSC-Mesa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MSD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mount Pleasant",
            "name": "[MSD] - Mount Pleasant - United States",
            "searchName": "MSD-Mount Pleasant-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MSL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sheffield",
            "name": "[MSL] - Sheffield - United States",
            "searchName": "MSL-Florence-Sheffield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MRN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Morganton",
            "name": "[MRN] - Morganton - United States",
            "searchName": "MRN-Morganton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MRK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Marco Island",
            "name": "[MRK] - Marco Island - United States",
            "searchName": "MRK-Marco Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MRF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Marfa",
            "name": "[MRF] - Marfa - United States",
            "searchName": "MRF-Marfa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MRB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Martinsburg",
            "name": "[MRB] - Martinsburg - United States",
            "searchName": "MRB-Martinsburg-Martinsburgh-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MRC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Columbia",
            "name": "[MRC] - Columbia - United States",
            "searchName": "MRC-Columbia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MUO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mountain Home",
            "name": "[MUO] - Mountain Home - United States",
            "searchName": "MUO-Mountain Home-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MUT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Muscatine",
            "name": "[MUT] - Muscatine - United States",
            "searchName": "MUT-Muscatine-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MUU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Mount Union",
            "name": "[MUU] - Mount Union - United States",
            "searchName": "MUU-Mount Union-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MVE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Montevideo",
            "name": "[MVE] - Montevideo - United States",
            "searchName": "MVE-Montevideo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MVC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Monroeville",
            "name": "[MVC] - Monroeville - United States",
            "searchName": "MVC-Monroeville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MUE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kamuela",
            "name": "[MUE] - Kamuela - United States",
            "searchName": "MUE-Kamuela-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MUI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Indiantown",
            "name": "[MUI] - Fort Indiantown - United States",
            "searchName": "MUI-Fort Indiantown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PXL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Polacca",
            "name": "[PXL] - Polacca - United States",
            "searchName": "PXL-Polacca-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PVU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Provo",
            "name": "[PVU] - Provo - United States",
            "searchName": "PVU-Provo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PVW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Plainview",
            "name": "[PVW] - Plainview - United States",
            "searchName": "PVW-Plainview-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PVY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pope Vanoy",
            "name": "[PVY] - Pope Vanoy - United States",
            "searchName": "PVY-Pope Vanoy-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PVZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Painesville",
            "name": "[PVZ] - Painesville - United States",
            "searchName": "PVZ-Painesville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PWD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Plentywood",
            "name": "[PWD] - Plentywood - United States",
            "searchName": "PWD-Plentywood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PVF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Placerville",
            "name": "[PVF] - Placerville - United States",
            "searchName": "PVF-Placerville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PVC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Provincetown",
            "name": "[PVC] - Provincetown - United States",
            "searchName": "PVC-Provincetown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PVD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Providence",
            "name": "[PVD] - Providence - United States",
            "searchName": "PVD-Providence-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PYL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Perry Island",
            "name": "[PYL] - Perry Island - United States",
            "searchName": "PYL-Perry Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PYM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Plymouth",
            "name": "[PYM] - Plymouth - United States",
            "searchName": "PYM-Plymouth-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PYS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Paradise",
            "name": "[PYS] - Paradise - United States",
            "searchName": "PYS-Paradise-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "QCE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Copper Mountain",
            "name": "[QCE] - Copper Mountain - United States",
            "searchName": "QCE-Copper Mountain-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "QWP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Winter Park",
            "name": "[QWP] - Winter Park - United States",
            "searchName": "QWP-Winter Park-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "QMV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Montvale",
            "name": "[QMV] - Montvale - United States",
            "searchName": "QMV-Montvale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "QKB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Breckenridge",
            "name": "[QKB] - Breckenridge - United States",
            "searchName": "QKB-Breckenridge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "QKS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Keystone",
            "name": "[QKS] - Keystone - United States",
            "searchName": "QKS-Keystone-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RBY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ruby",
            "name": "[RBY] - Ruby - United States",
            "searchName": "RBY-Ruby-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RBW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Walterboro",
            "name": "[RBW] - Walterboro - United States",
            "searchName": "RBW-Walterboro-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RBN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Jefferson",
            "name": "[RBN] - Fort Jefferson - United States",
            "searchName": "RBN-Fort Jefferson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RBK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rancho",
            "name": "[RBK] - Rancho - United States",
            "searchName": "RBK-Rancho-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RBL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Red Bluff",
            "name": "[RBL] - Red Bluff - United States",
            "searchName": "RBL-Red Bluff-Redding-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RCT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Reed City",
            "name": "[RCT] - Reed City - United States",
            "searchName": "RCT-Reed City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RCR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rochester",
            "name": "[RCR] - Rochester - United States",
            "searchName": "RCR-Rochester-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RDB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Red Dog",
            "name": "[RDB] - Red Dog - United States",
            "searchName": "RDB-Red Dog-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RCP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Cinder River",
            "name": "[RCP] - Cinder River - United States",
            "searchName": "RCP-Cinder River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RCK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rockdale",
            "name": "[RCK] - Rockdale - United States",
            "searchName": "RCK-Rockdale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RCE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Roche Harbor",
            "name": "[RCE] - Roche Harbor - United States",
            "searchName": "RCE-Roche Harbor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RAL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Riverside",
            "name": "[RAL] - Riverside - United States",
            "searchName": "RAL-Riverside-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RAC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Racine",
            "name": "[RAC] - Racine - United States",
            "searchName": "RAC-Racine-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RBF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Big Bear",
            "name": "[RBF] - Big Bear - United States",
            "searchName": "RBF-Big Bear-Big Bear City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RBG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Roseburg",
            "name": "[RBG] - Roseburg - United States",
            "searchName": "RBG-Roseburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RBH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Brooks Lodge",
            "name": "[RBH] - Brooks Lodge - United States",
            "searchName": "RBH-Brooks Lodge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RAP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rapid City",
            "name": "[RAP] - Rapid City - United States",
            "searchName": "RAP-Rapid City-Rapid city-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RDV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Red Devil",
            "name": "[RDV] - Red Devil - United States",
            "searchName": "RDV-Red Devil-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RDG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Reading",
            "name": "[RDG] - Reading - United States",
            "searchName": "RDG-Reading-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RDM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Redmond",
            "name": "[RDM] - Redmond - United States",
            "searchName": "RDM-Bend/Redmond-Redmond-Bend-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RDD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Redding",
            "name": "[RDD] - Redding - United States",
            "searchName": "RDD-Redding-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RDR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Red River",
            "name": "[RDR] - Red River - United States",
            "searchName": "RDR-Red River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "REH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rehoboth Beach",
            "name": "[REH] - Rehoboth Beach - United States",
            "searchName": "REH-Rehoboth Beach-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RED",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Reedsville",
            "name": "[RED] - Reedsville - United States",
            "searchName": "RED-Reedsville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "REO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rome",
            "name": "[REO] - Rome - United States",
            "searchName": "REO-Rome-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RFG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Refugio",
            "name": "[RFG] - Refugio - United States",
            "searchName": "RFG-Refugio-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RFK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Anguilla",
            "name": "[RFK] - Anguilla - United States",
            "searchName": "RFK-Anguilla-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RIL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rifle",
            "name": "[RIL] - Rifle - United States",
            "searchName": "RIL-Rifle-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RIC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Richmond",
            "name": "[RIC] - Richmond - United States",
            "searchName": "RIC-Richmond-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RID",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Richmond",
            "name": "[RID] - Richmond - United States",
            "searchName": "RID-Richmond-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RIE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rice Lake",
            "name": "[RIE] - Rice Lake - United States",
            "searchName": "RIE-Rice Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RIF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Richfield",
            "name": "[RIF] - Richfield - United States",
            "searchName": "RIF-Richfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RHI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Rhinelander",
            "name": "[RHI] - Rhinelander - United States",
            "searchName": "RHI-Rhinelander-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RGR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ranger",
            "name": "[RGR] - Ranger - United States",
            "searchName": "RGR-Ranger-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PAH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Paducah",
            "name": "[PAH] - Paducah - United States",
            "searchName": "PAH-Paducah-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PAE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Everett",
            "name": "[PAE] - Everett - United States",
            "searchName": "PAE-Everett-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PAO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Palo Alto",
            "name": "[PAO] - Palo Alto - United States",
            "searchName": "PAO-Palo Alto-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PAK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hanapepe",
            "name": "[PAK] - Hanapepe - United States",
            "searchName": "PAK-Hanapepe-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PAQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Palmer",
            "name": "[PAQ] - Palmer - United States",
            "searchName": "PAQ-Palmer-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PBF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pine Bluff",
            "name": "[PBF] - Pine Bluff - United States",
            "searchName": "PBF-Pine Bluff-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PBG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Plattsburgh",
            "name": "[PBG] - Plattsburgh - United States",
            "searchName": "PBG-Plattsburgh-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PBI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "West Palm Beach",
            "name": "[PBI] - West Palm Beach - United States",
            "searchName": "PBI-West Palm Beach-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PBK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pack Creek",
            "name": "[PBK] - Pack Creek - United States",
            "searchName": "PBK-Pack Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PCD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Prairie Du Chien",
            "name": "[PCD] - Prairie Du Chien - United States",
            "searchName": "PCD-Prairie Du Chien-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PCE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Painter Creek",
            "name": "[PCE] - Painter Creek - United States",
            "searchName": "PCE-Painter Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PCT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Princeton",
            "name": "[PCT] - Princeton - United States",
            "searchName": "PCT-Princeton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PCU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Picayune",
            "name": "[PCU] - Picayune - United States",
            "searchName": "PCU-Picayune-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PCK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Porcupine Creek",
            "name": "[PCK] - Porcupine Creek - United States",
            "searchName": "PCK-Porcupine Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PCA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Portage Creek",
            "name": "[PCA] - Portage Creek - United States",
            "searchName": "PCA-Portage Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PGR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Paragould",
            "name": "[PGR] - Paragould - United States",
            "searchName": "PGR-Paragould-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PGS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Peach Springs",
            "name": "[PGS] - Peach Springs - United States",
            "searchName": "PGS-Peach Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PGV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Greenville",
            "name": "[PGV] - Greenville - United States",
            "searchName": "PGV-Greenville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PGO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pagosa Springs",
            "name": "[PGO] - Pagosa Springs - United States",
            "searchName": "PGO-Pagosa Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PGL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pascagoula",
            "name": "[PGL] - Pascagoula - United States",
            "searchName": "PGL-Pascagoula-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PGM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Port Graham",
            "name": "[PGM] - Port Graham - United States",
            "searchName": "PGM-Port Graham-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PFA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Paf Warren",
            "name": "[PFA] - Paf Warren - United States",
            "searchName": "PFA-Paf Warren-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PFN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Panama City",
            "name": "[PFN] - Panama City - United States",
            "searchName": "PFN-Panama City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PFC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pacific City",
            "name": "[PFC] - Pacific City - United States",
            "searchName": "PFC-Pacific City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PFD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Port Frederick",
            "name": "[PFD] - Port Frederick - United States",
            "searchName": "PFD-Port Frederick-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PGC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Petersburg",
            "name": "[PGC] - Petersburg - United States",
            "searchName": "PGC-Petersburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PGD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Punta Gorda",
            "name": "[PGD] - Punta Gorda - United States",
            "searchName": "PGD-Punta Gorda-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PGA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Page",
            "name": "[PGA] - Page - United States",
            "searchName": "PGA-Page Arizona-Page-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PEC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pelican",
            "name": "[PEC] - Pelican - United States",
            "searchName": "PEC-Pelican-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PEQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pecos City",
            "name": "[PEQ] - Pecos City - United States",
            "searchName": "PEQ-Pecos City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PDB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pedro Bay",
            "name": "[PDB] - Pedro Bay - United States",
            "searchName": "PDB-Pedro Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PDT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pendleton",
            "name": "[PDT] - Pendleton - United States",
            "searchName": "PDT-Pendleton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IMT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Iron Mountain",
            "name": "[IMT] - Iron Mountain - United States",
            "searchName": "IMT-Iron Mountain-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "INK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wink",
            "name": "[INK] - Wink - United States",
            "searchName": "INK-Wink-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "INL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "International Falls",
            "name": "[INL] - International Falls - United States",
            "searchName": "INL-International Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "INS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Indian Springs",
            "name": "[INS] - Indian Springs - United States",
            "searchName": "INS-Indian Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "INT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Winston Salem",
            "name": "[INT] - Winston Salem - United States",
            "searchName": "INT-Winston-Winston Salem-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IRB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Iraan",
            "name": "[IRB] - Iraan - United States",
            "searchName": "IRB-Iraan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IRC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Circle",
            "name": "[IRC] - Circle - United States",
            "searchName": "IRC-Circle-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IPL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Imperial",
            "name": "[IPL] - Imperial - United States",
            "searchName": "IPL-El Centro/Imperial-Imperial-El Centro-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IPT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Williamsport",
            "name": "[IPT] - Williamsport - United States",
            "searchName": "IPT-Williamsport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "INW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Winslow",
            "name": "[INW] - Winslow - United States",
            "searchName": "INW-Winslow-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IOW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Iowa City",
            "name": "[IOW] - Iowa City - United States",
            "searchName": "IOW-Iowa City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HRL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Harlingen",
            "name": "[HRL] - Harlingen - United States",
            "searchName": "HRL-Harlingen-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HSI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hastings",
            "name": "[HSI] - Hastings - United States",
            "searchName": "HSI-Hastings-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HSB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Harrisburg",
            "name": "[HSB] - Harrisburg - United States",
            "searchName": "HSB-Harrisburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HRO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Harrison",
            "name": "[HRO] - Harrison - United States",
            "searchName": "HRO-Harrison Arkansas-Harrison-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HPB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hooper Bay",
            "name": "[HPB] - Hooper Bay - United States",
            "searchName": "HPB-Hooper Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HPN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Westchester County",
            "name": "[HPN] - Westchester County - United States",
            "searchName": "HPN-Westchester County-White Plains-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HPT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hampton",
            "name": "[HPT] - Hampton - United States",
            "searchName": "HPT-Hampton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HPY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Baytown",
            "name": "[HPY] - Baytown - United States",
            "searchName": "HPY-Baytown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HQM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hoquiam",
            "name": "[HQM] - Hoquiam - United States",
            "searchName": "HQM-Hoquiam-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HOT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Hot Springs",
            "name": "[HOT] - Hot Springs - United States",
            "searchName": "HOT-Hot Springs Arkansas-Hot Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SEF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sebring",
            "name": "[SEF] - Sebring - United States",
            "searchName": "SEF-Sebring-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SEG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Selinsgrove",
            "name": "[SEG] - Selinsgrove - United States",
            "searchName": "SEG-Selinsgrove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SER",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Seymour",
            "name": "[SER] - Seymour - United States",
            "searchName": "SER-Seymour-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SES",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Selma",
            "name": "[SES] - Selma - United States",
            "searchName": "SES-Selma-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SEP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Stephenville",
            "name": "[SEP] - Stephenville - United States",
            "searchName": "SEP-Stephenville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SCJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Smith Cove",
            "name": "[SCJ] - Smith Cove - United States",
            "searchName": "SCJ-Smith Cove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SCM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Scammon Bay",
            "name": "[SCM] - Scammon Bay - United States",
            "searchName": "SCM-Scammon Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SBX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Shelby",
            "name": "[SBX] - Shelby - United States",
            "searchName": "SBX-Shelby-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SBY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Salisbury-Ocean City",
            "name": "[SBY] - Salisbury-Ocean City - United States",
            "searchName": "SBY-Salisbury-Ocean City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SCB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Scribner",
            "name": "[SCB] - Scribner - United States",
            "searchName": "SCB-Scribner-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SCC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Prudhoe Bay/Deadhorse",
            "name": "[SCC] - Prudhoe Bay/Deadhorse - United States",
            "searchName": "SCC-Prudhoe Bay/Deadhorse-Prudhoe Bay-Prudhoe Bay\\/Deadhorse-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SCE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "State College",
            "name": "[SCE] - State College - United States",
            "searchName": "SCE-State College-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SCH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Schenectady",
            "name": "[SCH] - Schenectady - United States",
            "searchName": "SCH-Schenectady-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SBS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Steamboat Springs",
            "name": "[SBS] - Steamboat Springs - United States",
            "searchName": "SBS-Steamboat Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SBT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "San Bernardino",
            "name": "[SBT] - San Bernardino - United States",
            "searchName": "SBT-San Bernardino-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SBM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sheboygan",
            "name": "[SBM] - Sheboygan - United States",
            "searchName": "SBM-Sheboygan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SBN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "South Bend",
            "name": "[SBN] - South Bend - United States",
            "searchName": "SBN-South Bend-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SBO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Salina",
            "name": "[SBO] - Salina - United States",
            "searchName": "SBO-Salina-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SFM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sanford",
            "name": "[SFM] - Sanford - United States",
            "searchName": "SFM-Sanford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SDX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sedona",
            "name": "[SDX] - Sedona - United States",
            "searchName": "SDX-Sedona-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LAR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Laramie",
            "name": "[LAR] - Laramie - United States",
            "searchName": "LAR-Laramie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VRS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Versailles",
            "name": "[VRS] - Versailles - United States",
            "searchName": "VRS-Versailles-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VPZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Valparaiso",
            "name": "[VPZ] - Valparaiso - United States",
            "searchName": "VPZ-Valparaiso-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VRB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Vero Beach",
            "name": "[VRB] - Vero Beach - United States",
            "searchName": "VRB-Vero Beach-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VPS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Valparaiso",
            "name": "[VPS] - Valparaiso - United States",
            "searchName": "VPS-Valparaiso-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PHN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Port Huron",
            "name": "[PHN] - Port Huron - United States",
            "searchName": "PHN-Port Huron-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PHO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Point Hope",
            "name": "[PHO] - Point Hope - United States",
            "searchName": "PHO-Point Hope-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PHP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Philip",
            "name": "[PHP] - Philip - United States",
            "searchName": "PHP-Philip-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PIM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pine Mountain",
            "name": "[PIM] - Pine Mountain - United States",
            "searchName": "PIM-Pine Mountain-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PIH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pocatello",
            "name": "[PIH] - Pocatello - United States",
            "searchName": "PIH-Pocatello Idaho-Pocatello-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PIP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pilot Point",
            "name": "[PIP] - Pilot Point - United States",
            "searchName": "PIP-Pilot Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PIR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pierre",
            "name": "[PIR] - Pierre - United States",
            "searchName": "PIR-Pierre-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PJB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Payson",
            "name": "[PJB] - Payson - United States",
            "searchName": "PJB-Payson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PKA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Napaskiak",
            "name": "[PKA] - Napaskiak - United States",
            "searchName": "PKA-Napaskiak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PKB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Parkersburg",
            "name": "[PKB] - Parkersburg - United States",
            "searchName": "PKB-Parkersburg/Parkersburg WV-Parkersburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PJS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Port San Juan",
            "name": "[PJS] - Port San Juan - United States",
            "searchName": "PJS-Port San Juan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PKD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Park Rapids",
            "name": "[PKD] - Park Rapids - United States",
            "searchName": "PKD-Park Rapids-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PKF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Park Falls",
            "name": "[PKF] - Park Falls - United States",
            "searchName": "PKF-Park Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "POQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Polk Inlet",
            "name": "[POQ] - Polk Inlet - United States",
            "searchName": "POQ-Polk Inlet-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "POY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Powell",
            "name": "[POY] - Powell - United States",
            "searchName": "POY-Powell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "POU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Poughkeepsie",
            "name": "[POU] - Poughkeepsie - United States",
            "searchName": "POU-Poughkeepsie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PPA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pampa",
            "name": "[PPA] - Pampa - United States",
            "searchName": "PPA-Pampa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PPC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Prospect Creek",
            "name": "[PPC] - Prospect Creek - United States",
            "searchName": "PPC-Prospect Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PPM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pompano Beach",
            "name": "[PPM] - Pompano Beach - United States",
            "searchName": "PPM-Pompano Beach-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PPF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Parsons",
            "name": "[PPF] - Parsons - United States",
            "searchName": "PPF-Parsons-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PRF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Port Johnson",
            "name": "[PRF] - Port Johnson - United States",
            "searchName": "PRF-Port Johnson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PQS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pilot Station",
            "name": "[PQS] - Pilot Station - United States",
            "searchName": "PQS-Pilot Station-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PRB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Paso Robles",
            "name": "[PRB] - Paso Robles - United States",
            "searchName": "PRB-Paso Robles-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PRC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Prescott",
            "name": "[PRC] - Prescott - United States",
            "searchName": "PRC-Prescott-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PPV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Port Protection",
            "name": "[PPV] - Port Protection - United States",
            "searchName": "PPV-Port Protection-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PQI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Presque Isle",
            "name": "[PQI] - Presque Isle - United States",
            "searchName": "PQI-Presque Isle-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PRO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Perry",
            "name": "[PRO] - Perry - United States",
            "searchName": "PRO-Perry-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PRL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Port Oceanic",
            "name": "[PRL] - Port Oceanic - United States",
            "searchName": "PRL-Port Oceanic-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PRT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Point Retreat",
            "name": "[PRT] - Point Retreat - United States",
            "searchName": "PRT-Point Retreat-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PRW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Prentice",
            "name": "[PRW] - Prentice - United States",
            "searchName": "PRW-Prentice-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TYZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Taylor",
            "name": "[TYZ] - Taylor - United States",
            "searchName": "TYZ-Taylor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TZC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Caro",
            "name": "[TZC] - Caro - United States",
            "searchName": "TZC-Caro-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TYR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tyler",
            "name": "[TYR] - Tyler - United States",
            "searchName": "TYR-Tyler-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TYS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Knoxville",
            "name": "[TYS] - Knoxville - United States",
            "searchName": "TYS-Knoxville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UMB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Umnak Island",
            "name": "[UMB] - Umnak Island - United States",
            "searchName": "UMB-Umnak Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UMM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Summit",
            "name": "[UMM] - Summit - United States",
            "searchName": "UMM-Summit-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UMT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Umiat",
            "name": "[UMT] - Umiat - United States",
            "searchName": "UMT-Umiat-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UNK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Unalakleet",
            "name": "[UNK] - Unalakleet - United States",
            "searchName": "UNK-Unalakleet-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UNU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Juneau",
            "name": "[UNU] - Juneau - United States",
            "searchName": "UNU-Juneau-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UOS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sewanee",
            "name": "[UOS] - Sewanee - United States",
            "searchName": "UOS-Sewanee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UOX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Oxford",
            "name": "[UOX] - Oxford - United States",
            "searchName": "UOX-Oxford-University Oxford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UST",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "St Augustine",
            "name": "[UST] - St Augustine - United States",
            "searchName": "UST-Saint Augustine-St Augustine-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UQE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Queen",
            "name": "[UQE] - Queen - United States",
            "searchName": "UQE-Queen-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UPP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Upolu Point",
            "name": "[UPP] - Upolu Point - United States",
            "searchName": "UPP-Upolu Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UBS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Columbus",
            "name": "[UBS] - Columbus - United States",
            "searchName": "UBS-Columbus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UCA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Utica",
            "name": "[UCA] - Utica - United States",
            "searchName": "UCA-Utica-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UCC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Yucca Flat",
            "name": "[UCC] - Yucca Flat - United States",
            "searchName": "UCC-Yucca Flat-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UCE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Eunice",
            "name": "[UCE] - Eunice - United States",
            "searchName": "UCE-Eunice-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UCY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Union City",
            "name": "[UCY] - Union City - United States",
            "searchName": "UCY-Union City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UES",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Waukesha",
            "name": "[UES] - Waukesha - United States",
            "searchName": "UES-Waukesha-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UGI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Uganik",
            "name": "[UGI] - Uganik - United States",
            "searchName": "UGI-Uganik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UGN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Waukegan",
            "name": "[UGN] - Waukegan - United States",
            "searchName": "UGN-Waukegan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UGS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ugashik",
            "name": "[UGS] - Ugashik - United States",
            "searchName": "UGS-Ugashik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UKT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Quakertown",
            "name": "[UKT] - Quakertown - United States",
            "searchName": "UKT-Quakertown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ULM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "New Ulm",
            "name": "[ULM] - New Ulm - United States",
            "searchName": "ULM-New Ulm-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UIL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Quillayute",
            "name": "[UIL] - Quillayute - United States",
            "searchName": "UIL-Quillayute-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UIN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Quincy",
            "name": "[UIN] - Quincy - United States",
            "searchName": "UIN-Quincy Illinois-Quincy-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UKI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ukiah",
            "name": "[UKI] - Ukiah - United States",
            "searchName": "UKI-Ukiah-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UKN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Waukon",
            "name": "[UKN] - Waukon - United States",
            "searchName": "UKN-Waukon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UIZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Utica",
            "name": "[UIZ] - Utica - United States",
            "searchName": "UIZ-Utica-Detroit-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VSK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Kennewick",
            "name": "[VSK] - Kennewick - United States",
            "searchName": "VSK-Kennewick-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VSF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Springfield",
            "name": "[VSF] - Springfield - United States",
            "searchName": "VSF-Springfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VTN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Valentine",
            "name": "[VTN] - Valentine - United States",
            "searchName": "VTN-Valentine-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VYS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Peru",
            "name": "[VYS] - Peru - United States",
            "searchName": "VYS-Peru-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WAA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wales",
            "name": "[WAA] - Wales - United States",
            "searchName": "WAA-Wales-Galler-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VOK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Camp Douglas",
            "name": "[VOK] - Camp Douglas - United States",
            "searchName": "VOK-Camp Douglas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "STG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "St George Island",
            "name": "[STG] - St George Island - United States",
            "searchName": "STG-Saint George Island-St George Island--Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "STC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Saint Cloud",
            "name": "[STC] - Saint Cloud - United States",
            "searchName": "STC-St. Cloud-Saint Cloud-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SSM",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sault Sainte Marie",
            "name": "[SSM] - Sault Sainte Marie - United States",
            "searchName": "SSM-Sault Ste Marie-Sault Sainte Marie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SSC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sumter",
            "name": "[SSC] - Sumter - United States",
            "searchName": "SSC-Sumter-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SSI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Brunswick",
            "name": "[SSI] - Brunswick - United States",
            "searchName": "SSI-Brunswick-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SUU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fairfield",
            "name": "[SUU] - Fairfield - United States",
            "searchName": "SUU-Fairfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SVA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Savoonga",
            "name": "[SVA] - Savoonga - United States",
            "searchName": "SVA-Savoonga-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SUW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Superior",
            "name": "[SUW] - Superior - United States",
            "searchName": "SUW-Duluth-Superior-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SUX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sioux City",
            "name": "[SUX] - Sioux City - United States",
            "searchName": "SUX-Sioux City Iowa-Sioux City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SUN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sun Valley",
            "name": "[SUN] - Sun Valley - United States",
            "searchName": "SUN-Hailey/Sun Valley-Sun Valley-Hailey-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SUO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sun River",
            "name": "[SUO] - Sun River - United States",
            "searchName": "SUO-Sun River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "STQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "St Marys",
            "name": "[STQ] - St Marys - United States",
            "searchName": "STQ-St Marys-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "STS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Santa Rosa",
            "name": "[STS] - Santa Rosa - United States",
            "searchName": "STS-Santa Rosa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SUC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sundance",
            "name": "[SUC] - Sundance - United States",
            "searchName": "SUC-Sundance-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SUD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Stroud",
            "name": "[SUD] - Stroud - United States",
            "searchName": "SUD-Stroud-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SUE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sturgeon Bay",
            "name": "[SUE] - Sturgeon Bay - United States",
            "searchName": "SUE-Sturgeon Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SUA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Stuart",
            "name": "[SUA] - Stuart - United States",
            "searchName": "SUA-Stuart-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "STE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Stevens Point",
            "name": "[STE] - Stevens Point - United States",
            "searchName": "STE-Stevens Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SWD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Seward",
            "name": "[SWD] - Seward - United States",
            "searchName": "SWD-Seward-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SWF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Newburgh",
            "name": "[SWF] - Newburgh - United States",
            "searchName": "SWF-Newburgh-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SWO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Stillwater",
            "name": "[SWO] - Stillwater - United States",
            "searchName": "SWO-Stillwater-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SVH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Statesville",
            "name": "[SVH] - Statesville - United States",
            "searchName": "SVH-Statesville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SVC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Silver City",
            "name": "[SVC] - Silver City - United States",
            "searchName": "SVC-Silver City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SVE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Susanville",
            "name": "[SVE] - Susanville - United States",
            "searchName": "SVE-Susanville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SVW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sparrevohn",
            "name": "[SVW] - Sparrevohn - United States",
            "searchName": "SVW-Sparrevohn-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SVS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Stevens Village",
            "name": "[SVS] - Stevens Village - United States",
            "searchName": "SVS-Stevens Village-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TBR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Statesboro",
            "name": "[TBR] - Statesboro - United States",
            "searchName": "TBR-Statesboro-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TBN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Leonard Wood",
            "name": "[TBN] - Fort Leonard Wood - United States",
            "searchName": "TBN-Fort Leonard Wood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TCC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tucumcari",
            "name": "[TCC] - Tucumcari - United States",
            "searchName": "TCC-Tucumcari-Las Vegas-Tucamcari-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TCL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tuscaloosa",
            "name": "[TCL] - Tuscaloosa - United States",
            "searchName": "TCL-Tuscaloosa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TCS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Truth Or Consequences",
            "name": "[TCS] - Truth Or Consequences - United States",
            "searchName": "TCS-Truth Or Consequences-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TCT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Takotna",
            "name": "[TCT] - Takotna - United States",
            "searchName": "TCT-Takotna-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TBC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Tuba City",
            "name": "[TBC] - Tuba City - United States",
            "searchName": "TBC-Tuba City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TAD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Trinidad",
            "name": "[TAD] - Trinidad - United States",
            "searchName": "TAD-Trinidad-Pueblo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SFR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "San Fernando",
            "name": "[SFR] - San Fernando - United States",
            "searchName": "SFR-San Fernando-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SFY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Springfield",
            "name": "[SFY] - Springfield - United States",
            "searchName": "SFY-Springfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SFZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pawtucket",
            "name": "[SFZ] - Pawtucket - United States",
            "searchName": "SFZ-Smithfield-Pawtucket-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SGH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Springfield",
            "name": "[SGH] - Springfield - United States",
            "searchName": "SGH-Springfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SGF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Springfield",
            "name": "[SGF] - Springfield - United States",
            "searchName": "SGF-Springfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SGY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Skagway",
            "name": "[SGY] - Skagway - United States",
            "searchName": "SGY-Skagway-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SGW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Saginaw Bay",
            "name": "[SGW] - Saginaw Bay - United States",
            "searchName": "SGW-Saginaw Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SGT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Stuttgart",
            "name": "[SGT] - Stuttgart - United States",
            "searchName": "SGT-Stuttgart-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SGU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Saint George",
            "name": "[SGU] - Saint George - United States",
            "searchName": "SGU-Saint George-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SGR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sugar Land",
            "name": "[SGR] - Sugar Land - United States",
            "searchName": "SGR-Sugar Land-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SHG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Shungnak",
            "name": "[SHG] - Shungnak - United States",
            "searchName": "SHG-Shungnak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SHH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Shishmaref",
            "name": "[SHH] - Shishmaref - United States",
            "searchName": "SHH-Shishmaref-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SHD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Staunton",
            "name": "[SHD] - Staunton - United States",
            "searchName": "SHD-Staunton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SHN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Shelton",
            "name": "[SHN] - Shelton - United States",
            "searchName": "SHN-Shelton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SHR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sheridan",
            "name": "[SHR] - Sheridan - United States",
            "searchName": "SHR-Sheridan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SIY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Montague",
            "name": "[SIY] - Montague - United States",
            "searchName": "SIY-Montague-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SIV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sullivan",
            "name": "[SIV] - Sullivan - United States",
            "searchName": "SIV-Sullivan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SIT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sitka",
            "name": "[SIT] - Sitka - United States",
            "searchName": "SIT-Sitka-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SHX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Shageluk",
            "name": "[SHX] - Shageluk - United States",
            "searchName": "SHX-Shageluk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SHV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Shreveport",
            "name": "[SHV] - Shreveport - United States",
            "searchName": "SHV-Shreveport Louisiana-Shreveport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SIK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sikeston",
            "name": "[SIK] - Sikeston - United States",
            "searchName": "SIK-Sikeston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SKJ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sitkinak Island",
            "name": "[SKJ] - Sitkinak Island - United States",
            "searchName": "SKJ-Sitkinak Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SKK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Shaktoolik",
            "name": "[SKK] - Shaktoolik - United States",
            "searchName": "SKK-Shaktoolik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SJT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "San Angelo",
            "name": "[SJT] - San Angelo - United States",
            "searchName": "SJT-San Angelo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SJN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "St Johns",
            "name": "[SJN] - St Johns - United States",
            "searchName": "SJN-St Johns-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SJC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "San Jose",
            "name": "[SJC] - San Jose - United States",
            "searchName": "SJC-San Jos-San Jose-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SMU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sheep Mountain",
            "name": "[SMU] - Sheep Mountain - United States",
            "searchName": "SMU-Sheep Mountain-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SMN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Salmon",
            "name": "[SMN] - Salmon - United States",
            "searchName": "SMN-Salmon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SMO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Santa Monica",
            "name": "[SMO] - Santa Monica - United States",
            "searchName": "SMO-Santa Monica-Los Angeles-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SME",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Somerset",
            "name": "[SME] - Somerset - United States",
            "searchName": "SME-Somerset-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SMK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "St Michael",
            "name": "[SMK] - St Michael - United States",
            "searchName": "SMK-Saint Michael-St Michael-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SNP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Saint Paul Island",
            "name": "[SNP] - Saint Paul Island - United States",
            "searchName": "SNP-Saint Paul Island--Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SNA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Santa Ana",
            "name": "[SNA] - Santa Ana - United States",
            "searchName": "SNA-Santa Ana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SMX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Santa Maria",
            "name": "[SMX] - Santa Maria - United States",
            "searchName": "SMX-Santa Maria-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SLQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sleetmute",
            "name": "[SLQ] - Sleetmute - United States",
            "searchName": "SLQ-Sleetmute-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SLR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sulphur Springs",
            "name": "[SLR] - Sulphur Springs - United States",
            "searchName": "SLR-Sulphur Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SLT",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Salida",
            "name": "[SLT] - Salida - United States",
            "searchName": "SLT-Salida-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SLE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Salem",
            "name": "[SLE] - Salem - United States",
            "searchName": "SLE-Salem-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SLK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Saranac Lake",
            "name": "[SLK] - Saranac Lake - United States",
            "searchName": "SLK-Saranac Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SLN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Salina",
            "name": "[SLN] - Salina - United States",
            "searchName": "SLN-Salina-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SLO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Salem",
            "name": "[SLO] - Salem - United States",
            "searchName": "SLO-Salem-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SLG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Siloam Springs",
            "name": "[SLG] - Siloam Springs - United States",
            "searchName": "SLG-Siloam Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SKY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sandusky",
            "name": "[SKY] - Sandusky - United States",
            "searchName": "SKY-Sandusky-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SLB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Storm Lake",
            "name": "[SLB] - Storm Lake - United States",
            "searchName": "SLB-Storm Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SLC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Salt Lake City",
            "name": "[SLC] - Salt Lake City - United States",
            "searchName": "SLC-Salt Lake City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SKW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Skwentna",
            "name": "[SKW] - Skwentna - United States",
            "searchName": "SKW-Skwentna-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SPF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Spearfish",
            "name": "[SPF] - Spearfish - United States",
            "searchName": "SPF-Spearfish-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SPG",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Saint Petersburg",
            "name": "[SPG] - Saint Petersburg - United States",
            "searchName": "SPG-Saint Petersburg-St Petersburg-Whitted-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SPI",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Springfield",
            "name": "[SPI] - Springfield - United States",
            "searchName": "SPI-Springfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SPA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Spartanburg",
            "name": "[SPA] - Spartanburg - United States",
            "searchName": "SPA-Spartanburg-Greenville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SPW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Spencer",
            "name": "[SPW] - Spencer - United States",
            "searchName": "SPW-Spencer-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SPS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Wichita Falls",
            "name": "[SPS] - Wichita Falls - United States",
            "searchName": "SPS-Wichita Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SPQ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "San Pedro",
            "name": "[SPQ] - San Pedro - United States",
            "searchName": "SPQ-San Pedro-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SPZ",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Springdale",
            "name": "[SPZ] - Springdale - United States",
            "searchName": "SPZ-Springdale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SQA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Santa Ynez",
            "name": "[SQA] - Santa Ynez - United States",
            "searchName": "SQA-Santa Ynez-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SOP",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Southern Pines",
            "name": "[SOP] - Southern Pines - United States",
            "searchName": "SOP-Southern Pines-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SOV",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Seldovia",
            "name": "[SOV] - Seldovia - United States",
            "searchName": "SOV-Seldovia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SOW",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Show Low",
            "name": "[SOW] - Show Low - United States",
            "searchName": "SOW-Show Low-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SOL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Solomon",
            "name": "[SOL] - Solomon - United States",
            "searchName": "SOL-Solomon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SNK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Snyder",
            "name": "[SNK] - Snyder - United States",
            "searchName": "SNK-Snyder-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SNL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Shawnee",
            "name": "[SNL] - Shawnee - United States",
            "searchName": "SNL-Shawnee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SNS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Salinas",
            "name": "[SNS] - Salinas - United States",
            "searchName": "SNS-Salinas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SNY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sidney",
            "name": "[SNY] - Sidney - United States",
            "searchName": "SNY-Sidney-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PLY",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Plymouth",
            "name": "[PLY] - Plymouth - United States",
            "searchName": "PLY-Plymouth-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PLR",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pell City",
            "name": "[PLR] - Pell City - United States",
            "searchName": "PLR-Pell City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PLN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pellston",
            "name": "[PLN] - Pellston - United States",
            "searchName": "PLN-Pellston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PLK",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Point Lookout",
            "name": "[PLK] - Point Lookout - United States",
            "searchName": "PLK-Point Lookout-Branson Point Lookout-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PMB",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pembina",
            "name": "[PMB] - Pembina - United States",
            "searchName": "PMB-Pembina-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PMD",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Palmdale",
            "name": "[PMD] - Palmdale - United States",
            "searchName": "PMD-Palmdale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PMH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Portsmouth",
            "name": "[PMH] - Portsmouth - United States",
            "searchName": "PMH-Portsmouth-Huntington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PML",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Port Moller",
            "name": "[PML] - Port Moller - United States",
            "searchName": "PML-Port Moller-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "POE",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Fort Polk",
            "name": "[POE] - Fort Polk - United States",
            "searchName": "POE-Fort Polk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "POF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Poplar Bluff",
            "name": "[POF] - Poplar Bluff - United States",
            "searchName": "POF-Poplar Bluff-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "POH",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pocahontas",
            "name": "[POH] - Pocahontas - United States",
            "searchName": "POH-Pocahontas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PNX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Sherman-Denison",
            "name": "[PNX] - Sherman-Denison - United States",
            "searchName": "PNX-Sherman-Denison-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "POC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "La Verne",
            "name": "[POC] - La Verne - United States",
            "searchName": "POC-La Verne-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PNU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Panguitch",
            "name": "[PNU] - Panguitch - United States",
            "searchName": "PNU-Panguitch-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PNS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Pensacola",
            "name": "[PNS] - Pensacola - United States",
            "searchName": "PNS-Pensacola-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PNF",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Petersons Point",
            "name": "[PNF] - Petersons Point - United States",
            "searchName": "PNF-Peterson s Point-Petersons Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PNN",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Princeton",
            "name": "[PNN] - Princeton - United States",
            "searchName": "PNN-Princeton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PMU",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Paimiut",
            "name": "[PMU] - Paimiut - United States",
            "searchName": "PMU-Paimiut-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PMX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Palmer",
            "name": "[PMX] - Palmer - United States",
            "searchName": "PMX-Palmer-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PNC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Ponca City",
            "name": "[PNC] - Ponca City - United States",
            "searchName": "PNC-Ponca City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PIA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Peoria",
            "name": "[PIA] - Peoria - United States",
            "searchName": "PIA-Peoria Illinois-Peoria-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MOW",
            "countryCode": "RU",
            "cityCode": null,
            "cityName": "Moscow",
            "name": "[MOW] - Moscow - Russia",
            "searchName": "MOW-Moskau-Moscow-Mosc-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 1
        },
        {
            "iataCode": "LON",
            "countryCode": "GB",
            "cityCode": null,
            "cityName": "Londres",
            "name": "[LON] - Londres - United Kingdom",
            "searchName": "LON-London-Londres-Londra-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 1
        },
        {
            "iataCode": "PAR",
            "countryCode": "FR",
            "cityCode": null,
            "cityName": "Paris",
            "name": "[PAR] - Paris - France",
            "searchName": "PAR-Paris-Par-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 1
        },
        {
            "iataCode": "MAD",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Madrid",
            "name": "[MAD] - Madrid - Spain",
            "searchName": "MAD-Madrid-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 1
        },
        {
            "iataCode": "NYC",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "New York",
            "name": "[NYC] - New York - United States",
            "searchName": "NYC-New York-Nueva York-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "AMS",
            "countryCode": "NL",
            "cityCode": null,
            "cityName": "Amsterdam",
            "name": "[AMS] - Amsterdam - Netherlands",
            "searchName": "AMS-Amsterdam-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 1
        },
        {
            "iataCode": "PRG",
            "countryCode": "CZ",
            "cityCode": null,
            "cityName": "Praga",
            "name": "[PRG] - Praga - Czech Republic",
            "searchName": "PRG-Prag-Prague-Praga-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 1
        },
        {
            "iataCode": "MUC",
            "countryCode": "DE",
            "cityCode": null,
            "cityName": "Munich",
            "name": "[MUC] - Munich - Germany",
            "searchName": "MUC-M-Munich-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 1
        },
        {
            "iataCode": "IST",
            "countryCode": "TR",
            "cityCode": null,
            "cityName": "Estambul",
            "name": "[IST] - Estambul - Turkey",
            "searchName": "IST-Istanbul-Estambul-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 1
        },
        {
            "iataCode": "BCN",
            "countryCode": "ES",
            "cityCode": null,
            "cityName": "Barcelona",
            "name": "[BCN] - Barcelona - Spain",
            "searchName": "BCN-Barcelona-Barcelone-Barcellona-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 1
        },
        {
            "iataCode": "VIE",
            "countryCode": "AT",
            "cityCode": null,
            "cityName": "Vienna",
            "name": "[VIE] - Vienna - Austria",
            "searchName": "VIE-Wien-Vienna-Viena-Vienne-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 1
        },
        {
            "iataCode": "LIS",
            "countryCode": "PT",
            "cityCode": null,
            "cityName": "Lisboa",
            "name": "[LIS] - Lisboa - Portugal",
            "searchName": "LIS-Lissabon-Lisbon-Lisboa-Lisbonne-Lisbona-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 1
        },
        {
            "iataCode": "LAX",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Los Angeles",
            "name": "[LAX] - Los Angeles - United States",
            "searchName": "LAX-Los Angeles-Los -Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "BUD",
            "countryCode": "HU",
            "cityCode": null,
            "cityName": "Budapest",
            "name": "[BUD] - Budapest - Hungary",
            "searchName": "BUD-Budapest-Hongarije-Hungary-Hung ga ry-Ungern-Wegry-Hongaria-Hungria-Ungarn-Hungría-Ungheria-Vengrija-Ungarija-Madarska-Ungar-Macaristan-Hongrie",
            "priority": 1
        },
        {
            "iataCode": "WAW",
            "countryCode": "PL",
            "cityCode": null,
            "cityName": "Warsaw",
            "name": "[WAW] - Warsaw - Poland",
            "searchName": "WAW-Warschau-Warsaw-Varsovia-Varsovie-Varsavia-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 1
        },
        {
            "iataCode": "BKK",
            "countryCode": "TH",
            "cityCode": null,
            "cityName": "Bangkok",
            "name": "[BKK] - Bangkok - Thailand",
            "searchName": "BKK-Bangkok-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 1
        },
        {
            "iataCode": "SFO",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "San Francisco",
            "name": "[SFO] - San Francisco - United States",
            "searchName": "SFO-San Francisco-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "DUB",
            "countryCode": "IE",
            "cityCode": null,
            "cityName": "Dublin",
            "name": "[DUB] - Dublin - Ireland",
            "searchName": "DUB-Dublin-Dubl-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 1
        },
        {
            "iataCode": "ROM",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Roma",
            "name": "[ROM] - Roma - Italy",
            "searchName": "ROM-Rom-Rome-Roma-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 1
        },
        {
            "iataCode": "CAI",
            "countryCode": "EG",
            "cityCode": null,
            "cityName": "Cairo",
            "name": "[CAI] - Cairo - Egypt",
            "searchName": "CAI-Kairo-Cairo-El Cairo-Le Caire-Il Cairo-Kahire-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 1
        },
        {
            "iataCode": "SYD",
            "countryCode": "AU",
            "cityCode": null,
            "cityName": "Sydney",
            "name": "[SYD] - Sydney - Australia",
            "searchName": "SYD-Sydney-Sidney-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 1
        },
        {
            "iataCode": "DXB",
            "countryCode": "AE",
            "cityCode": null,
            "cityName": "Dubai",
            "name": "[DXB] - Dubai - United Arab Emirates",
            "searchName": "DXB-Dubai-Duba-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 1
        },
        {
            "iataCode": "HKG",
            "countryCode": "HK",
            "cityCode": null,
            "cityName": "Hong Kong",
            "name": "[HKG] - Hong Kong - Hong Kong",
            "searchName": "HKG-Hong Kong-Hongkong-Honkonga-Honkongas--Hong-Kong",
            "priority": 1
        },
        {
            "iataCode": "VCE",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Venice",
            "name": "[VCE] - Venice - Italy",
            "searchName": "VCE-Venedig-Venice-Venecia-Venise-Venezia-Venedik-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 1
        },
        {
            "iataCode": "SIN",
            "countryCode": "SG",
            "cityCode": null,
            "cityName": "Singapore",
            "name": "[SIN] - Singapore - Singapore",
            "searchName": "SIN-Singapur-Singapore-Singapour-Singapura-Sing ga po-",
            "priority": 1
        },
        {
            "iataCode": "MIA",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Miami",
            "name": "[MIA] - Miami - United States",
            "searchName": "MIA-Miami-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "CUN",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Cancun",
            "name": "[CUN] - Cancun - Mexico",
            "searchName": "CUN-Canc-Cancun-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 1
        },
        {
            "iataCode": "MIL",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Milan",
            "name": "[MIL] - Milan - Italy",
            "searchName": "MIL-Mailand-Milan-Mil-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 1
        },
        {
            "iataCode": "LIM",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Lima",
            "name": "[LIM] - Lima - Peru",
            "searchName": "LIM-Lima-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "LAS",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Las Vegas",
            "name": "[LAS] - Las Vegas - United States",
            "searchName": "LAS-Las Vegas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "HNL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Honolulu",
            "name": "[HNL] - Honolulu - United States",
            "searchName": "HNL-Honolulu Hawaii-Honolulu-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "KUL",
            "countryCode": "MY",
            "cityCode": null,
            "cityName": "Kuala Lumpur",
            "name": "[KUL] - Kuala Lumpur - Malaysia",
            "searchName": "KUL-Kuala Lumpur-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 1
        },
        {
            "iataCode": "BJS",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Beijing",
            "name": "[BJS] - Beijing - China",
            "searchName": "BJS-Beijing-Pek-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 1
        },
        {
            "iataCode": "YTO",
            "countryCode": "CA",
            "cityCode": null,
            "cityName": "Toronto",
            "name": "[YTO] - Toronto - Canada",
            "searchName": "YTO-Toronto-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 1
        },
        {
            "iataCode": "RIO",
            "countryCode": "BR",
            "cityCode": null,
            "cityName": "Rio de Janeiro",
            "name": "[RIO] - Rio de Janeiro - Brazil",
            "searchName": "RIO-Rio de Janeiro-R-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 1
        },
        {
            "iataCode": "SEL",
            "countryCode": "KR",
            "cityCode": null,
            "cityName": "Seoul",
            "name": "[SEL] - Seoul - South Korea",
            "searchName": "SEL-Seoul-Se-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 1
        },
        {
            "iataCode": "SHA",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Shanghai",
            "name": "[SHA] - Shanghai - China",
            "searchName": "SHA-Shanghai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 1
        },
        {
            "iataCode": "CIX",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Chiclayo",
            "name": "[CIX] - Chiclayo - Peru",
            "searchName": "CIX-Chiclayo-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "RAK",
            "countryCode": "MA",
            "cityCode": null,
            "cityName": "Marrakech",
            "name": "[RAK] - Marrakech - Morocco",
            "searchName": "RAK-Marrakesh-Marrakech-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 1
        },
        {
            "iataCode": "BAH",
            "countryCode": "BH",
            "cityCode": null,
            "cityName": "Manama",
            "name": "[BAH] - Manama - Bahrain",
            "searchName": "BAH-Bahrain-Manama-Bahrein-Bahrajn-Bahreyn-Bahreinas-Bahreina-Bahreïn-Bahréin",
            "priority": 1
        },
        {
            "iataCode": "AUA",
            "countryCode": "AN",
            "cityCode": null,
            "cityName": "Aruba",
            "name": "[AUA] - Aruba - Aruba",
            "searchName": "AUA-Aruba-Oranjestad-ABW",
            "priority": 1
        },
        {
            "iataCode": "JED",
            "countryCode": "SA",
            "cityCode": null,
            "cityName": "Jeddah",
            "name": "[JED] - Jeddah - Saudi Arabia",
            "searchName": "JED-Jeddah-Djeddah-Cidde-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 1
        },
        {
            "iataCode": "ORL",
            "countryCode": "US",
            "cityCode": null,
            "cityName": "Orlando",
            "name": "[ORL] - Orlando - United States",
            "searchName": "ORL-Orlando-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "TPE",
            "countryCode": "TW",
            "cityCode": null,
            "cityName": "Taipei",
            "name": "[TPE] - Taipei - Taiwan",
            "searchName": "TPE-Taipeh-Taipei-Taip-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 1
        },
        {
            "iataCode": "MFM",
            "countryCode": "MO",
            "cityCode": null,
            "cityName": "Macau",
            "name": "[MFM] - Macau - Macao",
            "searchName": "MFM-Macau-Macao-Makau-Ma Cao-Makao-Maka-China",
            "priority": 1
        },
        {
            "iataCode": "VER",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Veracruz",
            "name": "[VER] - Veracruz - Mexico",
            "searchName": "VER-Veracruz-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 1
        },
        {
            "iataCode": "FLR",
            "countryCode": "IT",
            "cityCode": null,
            "cityName": "Florence",
            "name": "[FLR] - Florence - Italy",
            "searchName": "FLR-Florenz-Florence-Florencia-Firenze-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 1
        },
        {
            "iataCode": "SZX",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Shenzhen",
            "name": "[SZX] - Shenzhen - China",
            "searchName": "SZX-Shenzhen-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 1
        },
        {
            "iataCode": "CAN",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Guangzhou",
            "name": "[CAN] - Guangzhou - China",
            "searchName": "CAN-Guangzhou-Canton-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 1
        },
        {
            "iataCode": "HGH",
            "countryCode": "CN",
            "cityCode": null,
            "cityName": "Hangzhou",
            "name": "[HGH] - Hangzhou - China",
            "searchName": "HGH-Hangzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 1
        },
        {
            "iataCode": "CJA",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Cajamarca",
            "name": "[CJA] - Cajamarca - Peru",
            "searchName": "CJA-Cajamarca-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "CHM",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Chimbote",
            "name": "[CHM] - Chimbote - Peru",
            "searchName": "CHM-Chimbote-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "CHH",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Chachapoyas",
            "name": "[CHH] - Chachapoyas - Peru",
            "searchName": "CHH-Chachapoyas-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "HUU",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Huanuco",
            "name": "[HUU] - Huanuco - Peru",
            "searchName": "HUU-Hu-Huanuco-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "IBP",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Iberia",
            "name": "[IBP] - Iberia - Peru",
            "searchName": "IBP-Iberia-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "IQT",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Iquitos",
            "name": "[IQT] - Iquitos - Peru",
            "searchName": "IQT-Iquitos-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "ILQ",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Ilo",
            "name": "[ILQ] - Ilo - Peru",
            "searchName": "ILQ-Ilo-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "JAU",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Jauja",
            "name": "[JAU] - Jauja - Peru",
            "searchName": "JAU-Jauja-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "JUL",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Juliaca",
            "name": "[JUL] - Juliaca - Peru",
            "searchName": "JUL-Juliaca-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "JJI",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Juanjui",
            "name": "[JJI] - Juanjui - Peru",
            "searchName": "JJI-Juanjui-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "PCL",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Pucallpa",
            "name": "[PCL] - Pucallpa - Peru",
            "searchName": "PCL-Pucallpa-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "PEM",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Puerto Maldonado",
            "name": "[PEM] - Puerto Maldonado - Peru",
            "searchName": "PEM-Puerto Maldonado-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "PIO",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Pisco",
            "name": "[PIO] - Pisco - Peru",
            "searchName": "PIO-Pisco-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "PIU",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Piura",
            "name": "[PIU] - Piura - Peru",
            "searchName": "PIU-Piura-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "RIJ",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Rioja",
            "name": "[RIJ] - Rioja - Peru",
            "searchName": "RIJ-Rioja-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "RIM",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Rodriguez De Mendoza",
            "name": "[RIM] - Rodriguez De Mendoza - Peru",
            "searchName": "RIM-Rodriguez de Mendoza-Rodriguez De Mendoza-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "SJA",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "San Juan",
            "name": "[SJA] - San Juan - Peru",
            "searchName": "SJA-San Juan-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "SMG",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Santa Maria",
            "name": "[SMG] - Santa Maria - Peru",
            "searchName": "SMG-Santa Maria-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "VVN",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Malvinas",
            "name": "[VVN] - Malvinas - Peru",
            "searchName": "VVN-Malvinas-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "TYL",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Talara",
            "name": "[TYL] - Talara - Peru",
            "searchName": "TYL-Talara-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "TRU",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Trujillo",
            "name": "[TRU] - Trujillo - Peru",
            "searchName": "TRU-Trujillo-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "UMI",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Quincemil",
            "name": "[UMI] - Quincemil - Peru",
            "searchName": "UMI-Quincemil-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "TDP",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Trompeteros",
            "name": "[TDP] - Trompeteros - Peru",
            "searchName": "TDP-Trompeteros-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "TGI",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Tingo Maria",
            "name": "[TGI] - Tingo Maria - Peru",
            "searchName": "TGI-Tingo Maria-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "TPP",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Tarapoto",
            "name": "[TPP] - Tarapoto - Peru",
            "searchName": "TPP-Tarapoto-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "SYC",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Shiringayoc",
            "name": "[SYC] - Shiringayoc - Peru",
            "searchName": "SYC-Shiringayoc-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "TCQ",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Tacna",
            "name": "[TCQ] - Tacna - Peru",
            "searchName": "TCQ-Tacna-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "TBP",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Tumbes",
            "name": "[TBP] - Tumbes - Peru",
            "searchName": "TBP-Tumbes-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "SQU",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Saposoa",
            "name": "[SQU] - Saposoa - Peru",
            "searchName": "SQU-Saposoa-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "YMS",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Yurimaguas",
            "name": "[YMS] - Yurimaguas - Peru",
            "searchName": "YMS-Yurimaguas-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "ANS",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Andahuaylas",
            "name": "[ANS] - Andahuaylas - Peru",
            "searchName": "ANS-Andahuaylas-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "ALD",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Alerta",
            "name": "[ALD] - Alerta - Peru",
            "searchName": "ALD-Alerta-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "AOP",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Andoas",
            "name": "[AOP] - Andoas - Peru",
            "searchName": "AOP-Andoas-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "APE",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "San Juan Aposento",
            "name": "[APE] - San Juan Aposento - Peru",
            "searchName": "APE-San Juan Aposento-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "AQP",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Arequipa",
            "name": "[AQP] - Arequipa - Peru",
            "searchName": "AQP-Arequipa-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "ATA",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Anta",
            "name": "[ATA] - Anta - Peru",
            "searchName": "ATA-Anta-Huaraz-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "AYP",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Ayacucho",
            "name": "[AYP] - Ayacucho - Peru",
            "searchName": "AYP-Ayacucho-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "BLP",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Bellavista",
            "name": "[BLP] - Bellavista - Peru",
            "searchName": "BLP-Bellavista-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "CUZ",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Cuzco",
            "name": "[CUZ] - Cuzco - Peru",
            "searchName": "CUZ-Cusco-Cuzco-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "MBP",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Moyobamba",
            "name": "[MBP] - Moyobamba - Peru",
            "searchName": "MBP-Moyobamba-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "MFT",
            "countryCode": "PE",
            "cityCode": null,
            "cityName": "Machu Picchu",
            "name": "[MFT] - Machu Picchu - Peru",
            "searchName": "MFT-Machu Picchu-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "MEX",
            "countryCode": "MX",
            "cityCode": null,
            "cityName": "Mexico City",
            "name": "[MEX] - Mexico City - Mexico",
            "searchName": "MEX-Mexiko-Stadt-Mexico City-Ciudad de M-Mexique-Meksiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 2
        }
    ],
    "lairports": [
        {
            "iataCode": "JFK",
            "countryCode": "US",
            "cityCode": "NYC",
            "cityName": "New York",
            "name": "[JFK] - John F Kennedy International - NYC - New York - United States",
            "searchName": "JFK-NYC-John F. Kennedy International-John F Kennedy International-John F Kennedy Intl-John F. Kennedy-New York-Nueva York-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 2
        },
        {
            "iataCode": "LIM",
            "countryCode": "PE",
            "cityCode": "LIM",
            "cityName": "Lima",
            "name": "[LIM] - Jorge Chavez International - LIM - Lima - Peru",
            "searchName": "LIM-Lima-Jorge Chavez International-J Chavez Intl-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 2
        },
        {
            "iataCode": "EWR",
            "countryCode": "US",
            "cityCode": "NYC",
            "cityName": "New York",
            "name": "[EWR] - Newark Liberty International - NYC - New York - United States",
            "searchName": "EWR-NYC-Newark Liberty International-Newark-New York-Nueva York-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 2
        },
        {
            "iataCode": "LGA",
            "countryCode": "US",
            "cityCode": "NYC",
            "cityName": "New York",
            "name": "[LGA] - La Guardia - NYC - New York - United States",
            "searchName": "LGA-NYC-New York La Guardia-La Guardia-LaGuardia-New York-Nueva York-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 2
        },
        {
            "iataCode": "DME",
            "countryCode": "RU",
            "cityCode": "MOW",
            "cityName": "Moscow",
            "name": "[DME] - Domodedovo - MOW - Moscow - Russia",
            "searchName": "DME-MOW-Domodedowo-Domodedovo-Moskau-Moscow-Mosc-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 1
        },
        {
            "iataCode": "SVO",
            "countryCode": "RU",
            "cityCode": "MOW",
            "cityName": "Moscow",
            "name": "[SVO] - Sheremetyevo International - MOW - Moscow - Russia",
            "searchName": "SVO-MOW-Moskau-Scheremetjewo-Sheremetyevo International-Sheremetyevo-Seremetyevo Uluslararasi Havalimani-Moscow-Mosc-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 1
        },
        {
            "iataCode": "MAD",
            "countryCode": "ES",
            "cityCode": "MAD",
            "cityName": "Madrid",
            "name": "[MAD] - Barajas - MAD - Madrid - Spain",
            "searchName": "MAD-Madrid-Barajas-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 1
        },
        {
            "iataCode": "AMS",
            "countryCode": "NL",
            "cityCode": "AMS",
            "cityName": "Amsterdam",
            "name": "[AMS] - Schiphol - AMS - Amsterdam - Netherlands",
            "searchName": "AMS-Schiphol-Amsterdam-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 1
        },
        {
            "iataCode": "PRG",
            "countryCode": "CZ",
            "cityCode": "PRG",
            "cityName": "Praga",
            "name": "[PRG] - Prague Vaclav Havel Airport - PRG - Praga - Czech Republic",
            "searchName": "PRG-Václav Havel Prag-Prague Vaclav Havel Airport-Ruzyne-Prag-Prague-Praga-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 1
        },
        {
            "iataCode": "MUC",
            "countryCode": "DE",
            "cityCode": "MUC",
            "cityName": "Munich",
            "name": "[MUC] - Franz Josef Strauss - MUC - Munich - Germany",
            "searchName": "MUC-Franz Josef Strauß-Franz Josef Strauss-Monaco di Baviera-M-Munich-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 1
        },
        {
            "iataCode": "IST",
            "countryCode": "TR",
            "cityCode": "IST",
            "cityName": "Estambul",
            "name": "[IST] - Ataturk Airport - IST - Estambul - Turkey",
            "searchName": "IST-Atatürk-Ataturk-Ataturk Airport-Istanbul-Estambul-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 1
        },
        {
            "iataCode": "BCN",
            "countryCode": "ES",
            "cityCode": "BCN",
            "cityName": "Barcelona",
            "name": "[BCN] - El Prat De Llobregat - BCN - Barcelona - Spain",
            "searchName": "BCN-Barcelona El Prat-El Prat De Llobregat-Barcelona-Barcelona Int. Apt-Barcelone-Barcellona-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 1
        },
        {
            "iataCode": "LHR",
            "countryCode": "GB",
            "cityCode": "LON",
            "cityName": "Londres",
            "name": "[LHR] - Heathrow - LON - Londres - United Kingdom",
            "searchName": "LHR-LON-London Heathrow-Heathrow-Londres-London-Londra-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 1
        },
        {
            "iataCode": "VIE",
            "countryCode": "AT",
            "cityCode": "VIE",
            "cityName": "Vienna",
            "name": "[VIE] - Vienna International - VIE - Vienna - Austria",
            "searchName": "VIE-Wien-Schwechat-Vienna International-Vienna Int. Apt-Vienna-Viena-Vienne-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 1
        },
        {
            "iataCode": "LIS",
            "countryCode": "PT",
            "cityCode": "LIS",
            "cityName": "Lisboa",
            "name": "[LIS] - Lisbon Portela - LIS - Lisboa - Portugal",
            "searchName": "LIS-Lissabon-Portela-Lisbon Portela-Lisboa-Lisboa Int. Apt-Lisbon-Lisbonne-Lisbona-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 1
        },
        {
            "iataCode": "FRA",
            "countryCode": "DE",
            "cityCode": "FRA",
            "cityName": "Frankfurt",
            "name": "[FRA] - Frankfurt International Airport - FRA - Frankfurt - Germany",
            "searchName": "FRA-Frankfurt Main-Frankfurt International Airport-Frankfurt-Rhein-Main-Frankfurt am Main-Francfort-Francoforte-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 1
        },
        {
            "iataCode": "CDG",
            "countryCode": "FR",
            "cityCode": "PAR",
            "cityName": "Paris",
            "name": "[CDG] - Charles De Gaulle - PAR - Paris - France",
            "searchName": "CDG-PAR-Paris-Charles-de-Gaulle-Charles De Gaulle-Ch. De Gaulle-Charles Degaulle-Par-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 1
        },
        {
            "iataCode": "BRU",
            "countryCode": "BE",
            "cityCode": "BRU",
            "cityName": "Bruselas",
            "name": "[BRU] - Brussels Airport - BRU - Bruselas - Belgium",
            "searchName": "BRU-Brüssel-Zaventem-Brussels Airport-National-Br-Bruselas-Belgia-Belgium-Belgien-België-Bélgica-Belgie-Belçika-Bèlgica-Belgique-Belgio",
            "priority": 1
        },
        {
            "iataCode": "LAX",
            "countryCode": "US",
            "cityCode": "LAX",
            "cityName": "Los Angeles",
            "name": "[LAX] - Los Angeles International - LAX - Los Angeles - United States",
            "searchName": "LAX-Los Angeles International-Los Angeles-Los -Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "BUD",
            "countryCode": "HU",
            "cityCode": "BUD",
            "cityName": "Budapest",
            "name": "[BUD] - Liszt Ferenc International - BUD - Budapest - Hungary",
            "searchName": "BUD-Budapest Liszt Ferenc-Liszt Ferenc International-Ferihegy-Ferihegy Int. Apt.-Budapest-Hongarije-Hungary-Hung ga ry-Ungern-Wegry-Hongaria-Hungria-Ungarn-Hungría-Ungheria-Vengrija-Ungarija-Madarska-Ungar-Macaristan-Hongrie",
            "priority": 1
        },
        {
            "iataCode": "WAW",
            "countryCode": "PL",
            "cityCode": "WAW",
            "cityName": "Warsaw",
            "name": "[WAW] - Frederic Chopin - WAW - Warsaw - Poland",
            "searchName": "WAW-Chopin-Flughafen Warschau-Frederic Chopin-Frederic Chopin & other airports-Chopin Airport-Warschau-Warsaw-Varsovia-Varsovie-Varsavia-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 1
        },
        {
            "iataCode": "BKK",
            "countryCode": "TH",
            "cityCode": "BKK",
            "cityName": "Bangkok",
            "name": "[BKK] - Suvarnabhumi International - BKK - Bangkok - Thailand",
            "searchName": "BKK-Suvarnabhumi International-Suvarnabhum-Suvarnabhumi-Bangkok-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 1
        },
        {
            "iataCode": "HAV",
            "countryCode": "CU",
            "cityCode": "HAV",
            "cityName": "La Habana",
            "name": "[HAV] - Jose Marti International - HAV - La Habana - Cuba",
            "searchName": "HAV-Aeropuerto Internacional José Martí -Jose Marti International-La Habana-La Havane-L Avana-Jose Marti-Havanna-Havana-Kuba-Cuba",
            "priority": 1
        },
        {
            "iataCode": "DUB",
            "countryCode": "IE",
            "cityCode": "DUB",
            "cityName": "Dublin",
            "name": "[DUB] - Dublin International - DUB - Dublin - Ireland",
            "searchName": "DUB-Dublin International-Dublin-Dubl-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 1
        },
        {
            "iataCode": "SFO",
            "countryCode": "US",
            "cityCode": "SFO",
            "cityName": "San Francisco",
            "name": "[SFO] - San Francisco International - SFO - San Francisco - United States",
            "searchName": "SFO-San Francisco International-San Francisco-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "CAI",
            "countryCode": "EG",
            "cityCode": "CAI",
            "cityName": "Cairo",
            "name": "[CAI] - Cairo International Airport - CAI - Cairo - Egypt",
            "searchName": "CAI-Kairo-Cairo International Airport-Aeropuerto Internacional de El Cairo-Aéroport international du Caire-Aeroporto Internazionale del Cairo-Cairo-El Cairo-Le Caire-Il Cairo-Kahire-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 1
        },
        {
            "iataCode": "SCL",
            "countryCode": "CL",
            "cityCode": "SCL",
            "cityName": "Santiago",
            "name": "[SCL] - Arturo Merino Benitez - SCL - Santiago - Chile",
            "searchName": "SCL-Santiago de Chile-Arturo Merino Benitez-Arturo M. Benitez-Santiago-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 1
        },
        {
            "iataCode": "SYD",
            "countryCode": "AU",
            "cityCode": "SYD",
            "cityName": "Sydney",
            "name": "[SYD] - Kingsford Smith - SYD - Sydney - Australia",
            "searchName": "SYD-Kingsford Smith International-Kingsford Smith-Sydney Kingsford Smith-Sydney-Sidney-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 1
        },
        {
            "iataCode": "DXB",
            "countryCode": "AE",
            "cityCode": "DXB",
            "cityName": "Dubai",
            "name": "[DXB] - Dubai - DXB - Dubai - United Arab Emirates",
            "searchName": "DXB-Dubai-Dubai International-Duba-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 1
        },
        {
            "iataCode": "HKG",
            "countryCode": "HK",
            "cityCode": "HKG",
            "cityName": "Hong Kong",
            "name": "[HKG] - Hong Kong International - HKG - Hong Kong - Hong Kong",
            "searchName": "HKG-Chek Lap Kok-Hong Kong International-Hong Kong Intl-Hong Kong-Hongkong-Honkonga-Honkongas--Hong-Kong",
            "priority": 1
        },
        {
            "iataCode": "VLC",
            "countryCode": "ES",
            "cityCode": "VLC",
            "cityName": "Valencia",
            "name": "[VLC] - Valencia - VLC - Valencia - Spain",
            "searchName": "VLC-Valencia-Manises-Valence-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 1
        },
        {
            "iataCode": "VCE",
            "countryCode": "IT",
            "cityCode": "VCE",
            "cityName": "Venice",
            "name": "[VCE] - Marco Polo - VCE - Venice - Italy",
            "searchName": "VCE-Venedig M.P.-Marco Polo-Aeroporto Marco Polo-Venedig-Venice-Venecia-Venise-Venezia-Venedik-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 1
        },
        {
            "iataCode": "SIN",
            "countryCode": "SG",
            "cityCode": "SIN",
            "cityName": "Singapore",
            "name": "[SIN] - Singapore Changi - SIN - Singapore - Singapore",
            "searchName": "SIN-Singapur-Singapore Changi-Changi-Singapore-Singapour-Singapura-Sing ga po-",
            "priority": 1
        },
        {
            "iataCode": "MIA",
            "countryCode": "US",
            "cityCode": "MIA",
            "cityName": "Miami",
            "name": "[MIA] - Miami International Airport - MIA - Miami - United States",
            "searchName": "MIA-Miami International Airport-Miami-International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "CUN",
            "countryCode": "MX",
            "cityCode": "CUN",
            "cityName": "Cancun",
            "name": "[CUN] - Cancún International - CUN - Cancun - Mexico",
            "searchName": "CUN-Aeropuerto Internacional de Cancún-Cancún International-Cancun-Canc-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 1
        },
        {
            "iataCode": "MEX",
            "countryCode": "MX",
            "cityCode": "MEX",
            "cityName": "Mexico City",
            "name": "[MEX] - Internacional Benito Juarez - MEX - Mexico City - Mexico",
            "searchName": "MEX-Internationale Flughafen von Mexiko-Stadt-Internacional Benito Juarez-Juarez & other airports-Juarez-Mexiko-Mexico City-Ciudad de M-Mexique-Meksiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 1
        },
        {
            "iataCode": "UIO",
            "countryCode": "EC",
            "cityCode": "UIO",
            "cityName": "Quito",
            "name": "[UIO] - Mariscal Sucre - UIO - Quito - Ecuador",
            "searchName": "UIO-Quito-Mariscal Sucre-Mariscal Sucr-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 1
        },
        {
            "iataCode": "ATL",
            "countryCode": "US",
            "cityCode": "ATL",
            "cityName": "Atlanta",
            "name": "[ATL] - Hartsfield-jackson Atlanta International - ATL - Atlanta - United States",
            "searchName": "ATL-Hartsfield–Jackson Atlanta International-Hartsfield-jackson Atlanta International-Jackson-Hartsfield Jackson-Atlanta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "LAS",
            "countryCode": "US",
            "cityCode": "LAS",
            "cityName": "Las Vegas",
            "name": "[LAS] - Mc Carran International - LAS - Las Vegas - United States",
            "searchName": "LAS-McCarran International Airport Las Vegas-Mc Carran International-McCarran-Las Vegas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "HNL",
            "countryCode": "US",
            "cityCode": "HNL",
            "cityName": "Honolulu",
            "name": "[HNL] - Honolulu International - HNL - Honolulu - United States",
            "searchName": "HNL-Internationale Flughafen Honolulu-Honolulu International-Honolulu-Oahu-Hawaii-Honolulu Hawaii-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "BLQ",
            "countryCode": "IT",
            "cityCode": "BLQ",
            "cityName": "Bologna",
            "name": "[BLQ] - Guglielmo Marconi - BLQ - Bologna - Italy",
            "searchName": "BLQ-Bologna-Guglielmo Marconi-Aeroporto Guglielmo Marconi-Bolonia-Bologne-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 1
        },
        {
            "iataCode": "ORD",
            "countryCode": "US",
            "cityCode": "CHI",
            "cityName": "Chicago",
            "name": "[ORD] - Chicago O hare International - CHI - Chicago - United States",
            "searchName": "ORD-CHI-O’Hare International-Chicago O hare International-O Hare International-O Hare-Chicago-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "KUL",
            "countryCode": "MY",
            "cityCode": "KUL",
            "cityName": "Kuala Lumpur",
            "name": "[KUL] - Kuala Lumpur International Airport (klia) - KUL - Kuala Lumpur - Malaysia",
            "searchName": "KUL-Kuala Lumpur International Airport (KLIA)-Kuala Lumpur International Airport (klia)-Kuala Lumpur Intl-Kuala Lumpur-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 1
        },
        {
            "iataCode": "YYZ",
            "countryCode": "CA",
            "cityCode": "YTO",
            "cityName": "Toronto",
            "name": "[YYZ] - Lester B. Pearson International - YTO - Toronto - Canada",
            "searchName": "YYZ-YTO-Toronto Pearson International-Lester B. Pearson International-Pearson Intl-Pearson Int. Apt.-Toronto-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 1
        },
        {
            "iataCode": "YUL",
            "countryCode": "CA",
            "cityCode": "YMQ",
            "cityName": "Montreal",
            "name": "[YUL] - Pierre Elliott Trudeau International - YMQ - Montreal - Canada",
            "searchName": "YUL-YMQ-Montreal Pierre Elliott Trudeau-Pierre Elliott Trudeau International-Elliott Trudeau-Dorval-Montreal-Montr-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 1
        },
        {
            "iataCode": "JQO",
            "countryCode": "RU",
            "cityCode": "MOW",
            "cityName": "Moscow",
            "name": "[JQO] - Belorussky Railway Station - MOW - Moscow - Russia",
            "searchName": "JQO-MOW-Belorussky Railway Station-Moskau-Moscow-Mosc-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 1
        },
        {
            "iataCode": "VKO",
            "countryCode": "RU",
            "cityCode": "MOW",
            "cityName": "Moscow",
            "name": "[VKO] - Vnukovo - MOW - Moscow - Russia",
            "searchName": "VKO-MOW-Moskau Wnukowo-Vnukovo-Moskova-Moskau-Moscow-Mosc-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 1
        },
        {
            "iataCode": "MTY",
            "countryCode": "MX",
            "cityCode": "MTY",
            "cityName": "Monterrey",
            "name": "[MTY] - Gen Mariano Escobedo - MTY - Monterrey - Mexico",
            "searchName": "MTY-Monterrey-Gen Mariano Escobedo-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 1
        },
        {
            "iataCode": "FCO",
            "countryCode": "IT",
            "cityCode": "ROM",
            "cityName": "Roma",
            "name": "[FCO] - Leonardo Da Vinci (Fiumicino) - ROM - Roma - Italy",
            "searchName": "FCO-ROM-Rom Fiumicino-Leonardo Da Vinci (Fiumicino)-Fiumicino-Rom-Rome-Roma-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 1
        },
        {
            "iataCode": "TRN",
            "countryCode": "IT",
            "cityCode": "TRN",
            "cityName": "Turin",
            "name": "[TRN] - Sandro Pertini (caselle) - TRN - Turin - Italy",
            "searchName": "TRN-Turin-Sandro Pertini (caselle)-Citta Di Torino-Aeroporto Città di Torino-Caselle  Apt-Tur-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 1
        },
        {
            "iataCode": "SDQ",
            "countryCode": "DO",
            "cityCode": "SDQ",
            "cityName": "Santo Domingo",
            "name": "[SDQ] - Aeropuerto de las AmÃ©ricas - SDQ - Santo Domingo - Dominican Republic",
            "searchName": "SDQ-Santo Domingo Dom Republik-Aeropuerto de las Américas-Las Americas-Aeropuerto de las AmÃ©ricas-Santo Domingo-St. Domingue-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 1
        },
        {
            "iataCode": "PEK",
            "countryCode": "CN",
            "cityCode": "BJS",
            "cityName": "Beijing",
            "name": "[PEK] - Beijing Capital International - BJS - Beijing - China",
            "searchName": "PEK-BJS-Peking-Beijing Capital International-Capital-Beijing-Pek-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 1
        },
        {
            "iataCode": "SHA",
            "countryCode": "CN",
            "cityCode": "SHA",
            "cityName": "Shanghai",
            "name": "[SHA] - Shanghai Hongqiao International - SHA - Shanghai - China",
            "searchName": "SHA-Shanghai Hongqiao International-Hongqiao-Hongqiao Airport-Shanghai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 1
        },
        {
            "iataCode": "CIX",
            "countryCode": "PE",
            "cityCode": "CIX",
            "cityName": "Chiclayo",
            "name": "[CIX] - Cornel Ruiz - CIX - Chiclayo - Peru",
            "searchName": "CIX-Chiclayo-Cornel Ruiz-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "DFW",
            "countryCode": "US",
            "cityCode": "DFW",
            "cityName": "Dallas",
            "name": "[DFW] - Dallas/Fort Worth International - DFW - Dallas - United States",
            "searchName": "DFW-Dallas/Fort Worth-Dallas/Fort Worth International-Dallas/Ft Worth Intl-Dallas Fort Worth International-Dallas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "CCS",
            "countryCode": "VE",
            "cityCode": "CCS",
            "cityName": "Caracas",
            "name": "[CCS] - Simon Bolivar International Airport - CCS - Caracas - Venezuela",
            "searchName": "CCS-Caracas-Simon Bolivar International Airport-Simon Bolivar-Wenezuela-Venezuela-Ven",
            "priority": 1
        },
        {
            "iataCode": "NRT",
            "countryCode": "JP",
            "cityCode": "TYO",
            "cityName": "Tokyo",
            "name": "[NRT] - Narita International Airport - TYO - Tokyo - Japan",
            "searchName": "NRT-TYO-Tokio-Narita International-Narita International Airport-Narita-Tokyo-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 1
        },
        {
            "iataCode": "RAK",
            "countryCode": "MA",
            "cityCode": "RAK",
            "cityName": "Marrakech",
            "name": "[RAK] - Menara - RAK - Marrakech - Morocco",
            "searchName": "RAK-Marrakesch-Menara-Menara Airport-Marrakesh-Marrakech-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 1
        },
        {
            "iataCode": "ASU",
            "countryCode": "PY",
            "cityCode": "ASU",
            "cityName": "Asuncion",
            "name": "[ASU] - Silvio Pettirossi - ASU - Asuncion - Paraguay",
            "searchName": "ASU-Asuncion-Silvio Pettirossi-Asunci-Paraguay",
            "priority": 1
        },
        {
            "iataCode": "JED",
            "countryCode": "SA",
            "cityCode": "JED",
            "cityName": "Jeddah",
            "name": "[JED] - King Abdulaziz International - JED - Jeddah - Saudi Arabia",
            "searchName": "JED-Jeddah King Abdulaziz International-King Abdulaziz International-King Abdulaziz Intl-Jeddah-Djeddah-Cidde-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 1
        },
        {
            "iataCode": "ORL",
            "countryCode": "US",
            "cityCode": "ORL",
            "cityName": "Orlando",
            "name": "[ORL] - Orlando Executive - ORL - Orlando - United States",
            "searchName": "ORL-Orlando Executive-Herndon-Orlando-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "BIO",
            "countryCode": "ES",
            "cityCode": "BIO",
            "cityName": "Bilbao",
            "name": "[BIO] - Bilbao - BIO - Bilbao - Spain",
            "searchName": "BIO-Bilbao-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 1
        },
        {
            "iataCode": "BOG",
            "countryCode": "CO",
            "cityCode": "BOG",
            "cityName": "Bogota",
            "name": "[BOG] - El Nuevo Dorado International - BOG - Bogota - Colombia",
            "searchName": "BOG-Bogota-El Nuevo Dorado International-Eldorado-Bogot-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 1
        },
        {
            "iataCode": "BAH",
            "countryCode": "BH",
            "cityCode": "BAH",
            "cityName": "Manama",
            "name": "[BAH] - Bahrain International - BAH - Manama - Bahrain",
            "searchName": "BAH-Bahrain-Bahrain International-Bahrain Intl-Manama-Bahrein-Bahrajn-Bahreyn-Bahreinas-Bahreina-Bahreïn-Bahréin",
            "priority": 1
        },
        {
            "iataCode": "BSB",
            "countryCode": "BR",
            "cityCode": "BSB",
            "cityName": "Brasilia",
            "name": "[BSB] - Presidente Juscelino Kubitschek - BSB - Brasilia - Brazil",
            "searchName": "BSB-Brasilia-Presidente Juscelino Kubitschek-Bras-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 1
        },
        {
            "iataCode": "SJO",
            "countryCode": "CR",
            "cityCode": "SJO",
            "cityName": "San José",
            "name": "[SJO] - Juan SantamarÃ­a International - SJO - San José - Costa Rica",
            "searchName": "SJO-San Jose Costa Rica-Juan Santamaría International-Juan Santamaria Intl-Juan Santa Maria-Juan SantamarÃ­a International-San Jos-San José-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 1
        },
        {
            "iataCode": "MFM",
            "countryCode": "MO",
            "cityCode": "MFM",
            "cityName": "Macau",
            "name": "[MFM] - Macau International - MFM - Macau - Macao",
            "searchName": "MFM-Macao-Macau International-Macau-Makau-Ma Cao-Makao-Maka-China",
            "priority": 1
        },
        {
            "iataCode": "TPE",
            "countryCode": "TW",
            "cityCode": "TPE",
            "cityName": "Taipei",
            "name": "[TPE] - Taiwan Taoyuan International (Chiang Kai Shek International) - TPE - Taipei - Taiwan",
            "searchName": "TPE-Internationale Flughafen Taiwan Taoyuan-Taiwan Taoyuan International (Chiang Kai Shek International)-Taoyuan Intl-Chiang Kai Shek-Taipeh-Taipei-Taip-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 1
        },
        {
            "iataCode": "PTY",
            "countryCode": "PA",
            "cityCode": "PTY",
            "cityName": "Panama City",
            "name": "[PTY] - Tocumen International - PTY - Panama City - Panama",
            "searchName": "PTY-Panama City Tocumen Intl-Tocumen International-Tocumen Intl-Panama Stadt-Panama City-Panama Ville-Panama-Panamà-Panamá",
            "priority": 1
        },
        {
            "iataCode": "IAH",
            "countryCode": "US",
            "cityCode": "HOU",
            "cityName": "Houston",
            "name": "[IAH] - George Bush Intercontinental - HOU - Houston - United States",
            "searchName": "IAH-HOU-George Bush Intercontinental-George Bush Intcntl-Houston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "LSV",
            "countryCode": "US",
            "cityCode": "LAS",
            "cityName": "Las Vegas",
            "name": "[LSV] - Nellis AFB - LAS - Las Vegas - United States",
            "searchName": "LSV-LAS-Nellis AFB-Las Vegas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "HSH",
            "countryCode": "US",
            "cityCode": "LAS",
            "cityName": "Las Vegas",
            "name": "[HSH] - Henderson Executive - LAS - Las Vegas - United States",
            "searchName": "HSH-LAS-Henderson Executive-Henderson Sky Harbor Havalimani-Las Vegas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "BLD",
            "countryCode": "US",
            "cityCode": "LAS",
            "cityName": "Las Vegas",
            "name": "[BLD] - Boulder City - LAS - Las Vegas - United States",
            "searchName": "BLD-LAS-Boulder City-Boulder City  Havalimani-Las Vegas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "VGT",
            "countryCode": "US",
            "cityCode": "LAS",
            "cityName": "Las Vegas",
            "name": "[VGT] - North Air Terminal - LAS - Las Vegas - United States",
            "searchName": "VGT-LAS-North Air Terminal-Las Vegas North Uçak terminali Havalimani-Las Vegas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "DCA",
            "countryCode": "US",
            "cityCode": "WAS",
            "cityName": "Washington",
            "name": "[DCA] - Ronald Reagan Washington National Airport - WAS - Washington - United States",
            "searchName": "DCA-WAS-Washington Ronald Reagan-Ronald Reagan Washington National Airport-Reagan-National-Washington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "HIK",
            "countryCode": "US",
            "cityCode": "HNL",
            "cityName": "Honolulu",
            "name": "[HIK] - Hickam AFB - HNL - Honolulu - United States",
            "searchName": "HIK-HNL-Hickam AFB-Honolulu Hawaii-Honolulu-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "JCX",
            "countryCode": "US",
            "cityCode": "LAX",
            "cityName": "Los Angeles",
            "name": "[JCX] - Citicorp Plaza Heliport - LAX - Los Angeles - United States",
            "searchName": "JCX-LAX-Citicorp Plaza Heliport-Los Angeles-Los -Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "JBP",
            "countryCode": "US",
            "cityCode": "LAX",
            "cityName": "Los Angeles",
            "name": "[JBP] - Commerce Bus. Plaza - LAX - Los Angeles - United States",
            "searchName": "JBP-LAX-Commerce Bus. Plaza-Los Angeles-Los -Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "JWC",
            "countryCode": "US",
            "cityCode": "LAX",
            "cityName": "Los Angeles",
            "name": "[JWC] - Warner Cntr Bus. Plaza - LAX - Los Angeles - United States",
            "searchName": "JWC-LAX-Warner Cntr Bus. Plaza-Los Angeles-Los -Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "JUC",
            "countryCode": "US",
            "cityCode": "LAX",
            "cityName": "Los Angeles",
            "name": "[JUC] - Universal City Heliport - LAX - Los Angeles - United States",
            "searchName": "JUC-LAX-Universal City Heliport-Los Angeles-Los -Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "JSN",
            "countryCode": "US",
            "cityCode": "LAX",
            "cityName": "Los Angeles",
            "name": "[JSN] - Sherman Oaks Heliport - LAX - Los Angeles - United States",
            "searchName": "JSN-LAX-Sherman Oaks Heliport-Los Angeles-Los -Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "JLX",
            "countryCode": "US",
            "cityCode": "LAX",
            "cityName": "Los Angeles",
            "name": "[JLX] - Union Station Heliport - LAX - Los Angeles - United States",
            "searchName": "JLX-LAX-Union Station Heliport-Los Angeles-Los -Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "JID",
            "countryCode": "US",
            "cityCode": "LAX",
            "cityName": "Los Angeles",
            "name": "[JID] - City Of Industry Heliport - LAX - Los Angeles - United States",
            "searchName": "JID-LAX-City Of Industry Heliport-Los Angeles-Los -Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "CCD",
            "countryCode": "US",
            "cityCode": "LAX",
            "cityName": "Los Angeles",
            "name": "[CCD] - Century City - LAX - Los Angeles - United States",
            "searchName": "CCD-LAX-Century City-Los Angeles-Los -Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "WHP",
            "countryCode": "US",
            "cityCode": "LAX",
            "cityName": "Los Angeles",
            "name": "[WHP] - Whiteman - LAX - Los Angeles - United States",
            "searchName": "WHP-LAX-Whiteman-Los Angeles-Los -Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "VNY",
            "countryCode": "US",
            "cityCode": "LAX",
            "cityName": "Los Angeles",
            "name": "[VNY] - Van Nuys - LAX - Los Angeles - United States",
            "searchName": "VNY-LAX-Van Nuys-Los Angeles Van Nuys Havalimani-Los Angeles-Los -Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "QLO",
            "countryCode": "US",
            "cityCode": "LAX",
            "cityName": "Los Angeles",
            "name": "[QLO] - Los Angeles Ferry Port - LAX - Los Angeles - United States",
            "searchName": "QLO-LAX-Los Angeles Ferry Port-Los Angeles-Los -Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "EMB",
            "countryCode": "US",
            "cityCode": "SFO",
            "cityName": "San Francisco",
            "name": "[EMB] - Embarkadero - SFO - San Francisco - United States",
            "searchName": "EMB-SFO-Embarkadero-San Francisco-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "JCC",
            "countryCode": "US",
            "cityCode": "SFO",
            "cityName": "San Francisco",
            "name": "[JCC] - China Basin Heliport - SFO - San Francisco - United States",
            "searchName": "JCC-SFO-China Basin Heliport-San Francisco-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "JRE",
            "countryCode": "US",
            "cityCode": "NYC",
            "cityName": "New York",
            "name": "[JRE] - East 60th Street Heliport - NYC - New York - United States",
            "searchName": "JRE-NYC-East 60th Street Heliport-New York-Nueva York-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "NES",
            "countryCode": "US",
            "cityCode": "NYC",
            "cityName": "New York",
            "name": "[NES] - East 34th St Landing - NYC - New York - United States",
            "searchName": "NES-NYC-East 34th St Landing-New York-Nueva York-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "NBP",
            "countryCode": "US",
            "cityCode": "NYC",
            "cityName": "New York",
            "name": "[NBP] - Battery Pk City/N. Cov - NYC - New York - United States",
            "searchName": "NBP-NYC-Battery Pk City/N. Cov-New York-Nueva York-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "ICA",
            "countryCode": "VE",
            "cityCode": "ICA",
            "cityName": "Icabaru",
            "name": "[ICA] - Icabaru - ICA - Icabaru - Venezuela",
            "searchName": "ICA-Icabaru-Wenezuela-Venezuela-Ven",
            "priority": 1
        },
        {
            "iataCode": "MVD",
            "countryCode": "UY",
            "cityCode": "MVD",
            "cityName": "Montevideo",
            "name": "[MVD] - Carrasco International - MVD - Montevideo - Uruguay",
            "searchName": "MVD-Montevideo-Carrasco International-Carrasco-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 1
        },
        {
            "iataCode": "PIU",
            "countryCode": "PE",
            "cityCode": "PIU",
            "cityName": "Piura",
            "name": "[PIU] - Piura - PIU - Piura - Peru",
            "searchName": "PIU-Piura-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "CJA",
            "countryCode": "PE",
            "cityCode": "CJA",
            "cityName": "Cajamarca",
            "name": "[CJA] - Cajamarca - CJA - Cajamarca - Peru",
            "searchName": "CJA-Cajamarca-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "JAE",
            "countryCode": "PE",
            "cityCode": "CJA",
            "cityName": "Cajamarca",
            "name": "[JAE] - Jaen - CJA - Cajamarca - Peru",
            "searchName": "JAE-CJA-Jaen- Jaén-Cajamarca-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "IQT",
            "countryCode": "PE",
            "cityCode": "IQT",
            "cityName": "Iquitos",
            "name": "[IQT] - C.F. Secada - IQT - Iquitos - Peru",
            "searchName": "IQT-Iquitos-C.F. Secada-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "AQP",
            "countryCode": "PE",
            "cityCode": "AQP",
            "cityName": "Arequipa",
            "name": "[AQP] - Rodriguez Ballon - AQP - Arequipa - Peru",
            "searchName": "AQP-Arequipa-Rodriguez Ballon-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "AYP",
            "countryCode": "PE",
            "cityCode": "AYP",
            "cityName": "Ayacucho",
            "name": "[AYP] - Yanamilla - AYP - Ayacucho - Peru",
            "searchName": "AYP-Yanamilla-Alfredo Mendívil Duarte Havalimani-Ayacucho-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "CUZ",
            "countryCode": "PE",
            "cityCode": "CUZ",
            "cityName": "Cuzco",
            "name": "[CUZ] - Velazco Astete - CUZ - Cuzco - Peru",
            "searchName": "CUZ-Cuzco-Velazco Astete-Cusco-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "CHM",
            "countryCode": "PE",
            "cityCode": "CHM",
            "cityName": "Chimbote",
            "name": "[CHM] - Chimbote - CHM - Chimbote - Peru",
            "searchName": "CHM-Chimbote-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "TGI",
            "countryCode": "PE",
            "cityCode": "TGI",
            "cityName": "Tingo Maria",
            "name": "[TGI] - Tingo Maria - TGI - Tingo Maria - Peru",
            "searchName": "TGI-Tingo Maria-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "TYL",
            "countryCode": "PE",
            "cityCode": "TYL",
            "cityName": "Talara",
            "name": "[TYL] - Talara - TYL - Talara - Peru",
            "searchName": "TYL-Talara-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "TRU",
            "countryCode": "PE",
            "cityCode": "TRU",
            "cityName": "Trujillo",
            "name": "[TRU] - Trujillo - TRU - Trujillo - Peru",
            "searchName": "TRU-Trujillo Peru-Trujillo-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "TPP",
            "countryCode": "PE",
            "cityCode": "TPP",
            "cityName": "Tarapoto",
            "name": "[TPP] - Tarapoto - TPP - Tarapoto - Peru",
            "searchName": "TPP-Tarapoto-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "PEM",
            "countryCode": "PE",
            "cityCode": "PEM",
            "cityName": "Puerto Maldonado",
            "name": "[PEM] - Puerto Maldonado - PEM - Puerto Maldonado - Peru",
            "searchName": "PEM-Puerto Maldonado-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "PCL",
            "countryCode": "PE",
            "cityCode": "PCL",
            "cityName": "Pucallpa",
            "name": "[PCL] - Captain Rolden International Airport - PCL - Pucallpa - Peru",
            "searchName": "PCL-Pucallpa-Captain Rolden International Airport-Capitan Rolden-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "TCQ",
            "countryCode": "PE",
            "cityCode": "TCQ",
            "cityName": "Tacna",
            "name": "[TCQ] - Tacna - TCQ - Tacna - Peru",
            "searchName": "TCQ-Tacna-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "TBP",
            "countryCode": "PE",
            "cityCode": "TBP",
            "cityName": "Tumbes",
            "name": "[TBP] - Tumbes - TBP - Tumbes - Peru",
            "searchName": "TBP-Tumbes-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "ANS",
            "countryCode": "PE",
            "cityCode": "ANS",
            "cityName": "Andahuaylas",
            "name": "[ANS] - Andahuaylas - ANS - Andahuaylas - Peru",
            "searchName": "ANS-Andahuaylas-Andahuaylas Havalimani-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "ATA",
            "countryCode": "PE",
            "cityCode": "ATA",
            "cityName": "Anta",
            "name": "[ATA] - Anta - ATA - Anta - Peru",
            "searchName": "ATA-Anta-Germán Arias Graziani Havalimani-Huaraz-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "ZYD",
            "countryCode": "PT",
            "cityCode": "LIS",
            "cityName": "Lisboa",
            "name": "[ZYD] - Lisbon TP - LIS - Lisboa - Portugal",
            "searchName": "ZYD-LIS-Lisbon TP-Lissabon-Lisbon-Lisboa-Lisbonne-Lisbona-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 1
        },
        {
            "iataCode": "HUU",
            "countryCode": "PE",
            "cityCode": "HUU",
            "cityName": "Huanuco",
            "name": "[HUU] - Huanuco - HUU - Huanuco - Peru",
            "searchName": "HUU-Huanuco-Huanuco Arpt-Hu-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "JAU",
            "countryCode": "PE",
            "cityCode": "JAU",
            "cityName": "Jauja",
            "name": "[JAU] - Jauja - JAU - Jauja - Peru",
            "searchName": "JAU-Jauja-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "JUL",
            "countryCode": "PE",
            "cityCode": "JUL",
            "cityName": "Juliaca",
            "name": "[JUL] - Juliaca - JUL - Juliaca - Peru",
            "searchName": "JUL-Juliaca-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 1
        },
        {
            "iataCode": "SJU",
            "countryCode": "PR",
            "cityCode": "SJU",
            "cityName": "San Juan",
            "name": "[SJU] - Luis Munoz Marin International - SJU - San Juan - Puerto Rico",
            "searchName": "SJU-San Juan Luis Munoz Marin-Luis Munoz Marin International-Luis Munoz Marin-Luis Munoz Marin Int.-San Juan-Puerto Rico-Porto Riko-Portoriko-Puerto Rikas-Puertoriko--Porto Rico-Portorico",
            "priority": 1
        },
        {
            "iataCode": "ZKD",
            "countryCode": "RU",
            "cityCode": "MOW",
            "cityName": "Moscow",
            "name": "[ZKD] - Leningradskiy Railway - MOW - Moscow - Russia",
            "searchName": "ZKD-MOW-Leningradskiy Railway-Moskau-Moscow-Mosc-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 1
        },
        {
            "iataCode": "JQF",
            "countryCode": "RU",
            "cityCode": "MOW",
            "cityName": "Moscow",
            "name": "[JQF] - Savelovsky Railway Station - MOW - Moscow - Russia",
            "searchName": "JQF-MOW-Savelovsky Railway Station-Moskau-Moscow-Mosc-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 1
        },
        {
            "iataCode": "XRK",
            "countryCode": "RU",
            "cityCode": "MOW",
            "cityName": "Moscow",
            "name": "[XRK] - Paveletskiy Railway. - MOW - Moscow - Russia",
            "searchName": "XRK-MOW-Paveletskiy Railway.-Moskau-Moscow-Mosc-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 1
        },
        {
            "iataCode": "VER",
            "countryCode": "MX",
            "cityCode": "VER",
            "cityName": "Veracruz",
            "name": "[VER] - Las Bajadas - VER - Veracruz - Mexico",
            "searchName": "VER-Veracruz-Las Bajadas-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 1
        },
        {
            "iataCode": "XKL",
            "countryCode": "MY",
            "cityCode": "KUL",
            "cityName": "Kuala Lumpur",
            "name": "[XKL] - Sentral Railway Station - KUL - Kuala Lumpur - Malaysia",
            "searchName": "XKL-KUL-Sentral Railway Station-Kuala Lumpur-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 1
        },
        {
            "iataCode": "SZB",
            "countryCode": "MY",
            "cityCode": "KUL",
            "cityName": "Kuala Lumpur",
            "name": "[SZB] - Sultan Abdul Aziz Shah - KUL - Kuala Lumpur - Malaysia",
            "searchName": "SZB-KUL-Kuala Lumpur-Sultan Abdul Aziz Shah-Abdul Aziz Shah-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 1
        },
        {
            "iataCode": "NLU",
            "countryCode": "MX",
            "cityCode": "MEX",
            "cityName": "Mexico City",
            "name": "[NLU] - Santa Lucia - MEX - Mexico City - Mexico",
            "searchName": "NLU-MEX-Santa Lucia-Mexiko-Stadt-Mexico City-Ciudad de M-Mexique-Meksiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 1
        },
        {
            "iataCode": "AZP",
            "countryCode": "MX",
            "cityCode": "MEX",
            "cityName": "Mexico City",
            "name": "[AZP] - Atizapan - MEX - Mexico City - Mexico",
            "searchName": "AZP-MEX-Atizapan-Mexiko-Stadt-Mexico City-Ciudad de M-Mexique-Meksiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 1
        },
        {
            "iataCode": "TLC",
            "countryCode": "MX",
            "cityCode": "MEX",
            "cityName": "Mexico City",
            "name": "[TLC] - Licenciado Adolfo Lopez Mateos International - MEX - Mexico City - Mexico",
            "searchName": "TLC-MEX-Toluca-Licenciado Adolfo Lopez Mateos International-Toluca International-Mexiko-Stadt-Mexico City-Ciudad de M-Mexique-Meksiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 1
        },
        {
            "iataCode": "GDL",
            "countryCode": "MX",
            "cityCode": "GDL",
            "cityName": "Guadalajara",
            "name": "[GDL] - Don Miguel Hidalgo y Costilla International - GDL - Guadalajara - Mexico",
            "searchName": "GDL-Guadalajara-Don Miguel Hidalgo y Costilla International-Miguel Hidal-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 1
        },
        {
            "iataCode": "ZYA",
            "countryCode": "NL",
            "cityCode": "AMS",
            "cityName": "Amsterdam",
            "name": "[ZYA] - Amsterdam Centraal Railway Station - AMS - Amsterdam - Netherlands",
            "searchName": "ZYA-AMS-Amsterdam Centraal-Amsterdam Centraal Railway Station-Amsterdam-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 1
        },
        {
            "iataCode": "TSA",
            "countryCode": "TW",
            "cityCode": "TPE",
            "cityName": "Taipei",
            "name": "[TSA] - Taipei Songshan (Sung Shan) - TPE - Taipei - Taiwan",
            "searchName": "TSA-TPE-Taipeh Sung Shan-Taipei Songshan (Sung Shan)-Sung Shan-Taipeh-Taipei-Taip-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 1
        },
        {
            "iataCode": "MPB",
            "countryCode": "US",
            "cityCode": "MIA",
            "cityName": "Miami",
            "name": "[MPB] - Miami Sea Plane Base - MIA - Miami - United States",
            "searchName": "MPB-MIA-Miami Sea Plane Base-Sea Plane Base-Miami-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "JDM",
            "countryCode": "US",
            "cityCode": "MIA",
            "cityName": "Miami",
            "name": "[JDM] - Miami Downtown Heliport - MIA - Miami - United States",
            "searchName": "JDM-MIA-Miami Downtown Heliport-Miami-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "TMB",
            "countryCode": "US",
            "cityCode": "MIA",
            "cityName": "Miami",
            "name": "[TMB] - Tamiami - MIA - Miami - United States",
            "searchName": "TMB-MIA-Tamiami-Tamiami Havalimani-Miami-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "TNT",
            "countryCode": "US",
            "cityCode": "MIA",
            "cityName": "Miami",
            "name": "[TNT] - Dade Collier - MIA - Miami - United States",
            "searchName": "TNT-MIA-Dade Collier-Miami-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "OPF",
            "countryCode": "US",
            "cityCode": "MIA",
            "cityName": "Miami",
            "name": "[OPF] - Opa Locka - MIA - Miami - United States",
            "searchName": "OPF-MIA-Opa Locka-Opa Locka Havalimani-Miami-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "DMK",
            "countryCode": "TH",
            "cityCode": "BKK",
            "cityName": "Bangkok",
            "name": "[DMK] - Don Muang - BKK - Bangkok - Thailand",
            "searchName": "DMK-BKK-Bangkok Dong Muang-Don Muang-Don Mueang Intl-Don Mueang Airport-Bangkok-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 1
        },
        {
            "iataCode": "SAL",
            "countryCode": "SV",
            "cityCode": "SAL",
            "cityName": "San Salvador",
            "name": "[SAL] - El Salvador International - SAL - San Salvador - El Salvador",
            "searchName": "SAL-San Salvador El Salvador-El Salvador International-Comalapa Intl-San Salvador-Salvador-El Salvador",
            "priority": 1
        },
        {
            "iataCode": "SAW",
            "countryCode": "TR",
            "cityCode": "IST",
            "cityName": "Estambul",
            "name": "[SAW] - Sabiha Gokcen - IST - Estambul - Turkey",
            "searchName": "SAW-IST-Istanbul-Sabiha Gökçen-Sabiha Gokcen-Sabiha Gökçen Uluslararasi Havalimani-Estambul-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 1
        },
        {
            "iataCode": "QPG",
            "countryCode": "SG",
            "cityCode": "SIN",
            "cityName": "Singapore",
            "name": "[QPG] - Paya Lebar - SIN - Singapore - Singapore",
            "searchName": "QPG-SIN-Paya Lebar-Singapur-Singapore-Singapour-Singapura-Sing ga po-",
            "priority": 1
        },
        {
            "iataCode": "XSP",
            "countryCode": "SG",
            "cityCode": "SIN",
            "cityName": "Singapore",
            "name": "[XSP] - Seletar - SIN - Singapore - Singapore",
            "searchName": "XSP-SIN-Seletar-Singapur-Singapore-Singapour-Singapura-Sing ga po-",
            "priority": 1
        },
        {
            "iataCode": "SFB",
            "countryCode": "US",
            "cityCode": "ORL",
            "cityName": "Orlando",
            "name": "[SFB] - Orlando Sanford International - ORL - Orlando - United States",
            "searchName": "SFB-ORL-Orlando Sanford-Orlando Sanford International-Ramey-Orlando-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "MCO",
            "countryCode": "US",
            "cityCode": "ORL",
            "cityName": "Orlando",
            "name": "[MCO] - Orlando International - ORL - Orlando - United States",
            "searchName": "MCO-ORL-Orlando-Orlando International-International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "NWS",
            "countryCode": "US",
            "cityCode": "NYC",
            "cityName": "New York",
            "name": "[NWS] - Pier 11/Wall St. SPB - NYC - New York - United States",
            "searchName": "NWS-NYC-Pier 11/Wall St. SPB-New York-Nueva York-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "QNY",
            "countryCode": "US",
            "cityCode": "NYC",
            "cityName": "New York",
            "name": "[QNY] - Marine Air Terminal - NYC - New York - United States",
            "searchName": "QNY-NYC-Marine Air Terminal-New York-Nueva York-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "TSS",
            "countryCode": "US",
            "cityCode": "NYC",
            "cityName": "New York",
            "name": "[TSS] - East 34th St Heliport - NYC - New York - United States",
            "searchName": "TSS-NYC-East 34th St Heliport-New York-Nueva York-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "ZYP",
            "countryCode": "US",
            "cityCode": "NYC",
            "cityName": "New York",
            "name": "[ZYP] - Penn Railway Station - NYC - New York - United States",
            "searchName": "ZYP-NYC-Penn Railway Station-New York-Nueva York-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "XNY",
            "countryCode": "US",
            "cityCode": "NYC",
            "cityName": "New York",
            "name": "[XNY] - 39th Street Ferry - NYC - New York - United States",
            "searchName": "XNY-NYC-39th Street Ferry-New York-Nueva York-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "WTC",
            "countryCode": "US",
            "cityCode": "NYC",
            "cityName": "New York",
            "name": "[WTC] - World Trade Center - NYC - New York - United States",
            "searchName": "WTC-NYC-World Trade Center-New York-Nueva York-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "JRA",
            "countryCode": "US",
            "cityCode": "NYC",
            "cityName": "New York",
            "name": "[JRA] - West 30th St Heliport - NYC - New York - United States",
            "searchName": "JRA-NYC-West 30th St Heliport-New York-Nueva York-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "JRB",
            "countryCode": "US",
            "cityCode": "NYC",
            "cityName": "New York",
            "name": "[JRB] - Downtown Manhattan Heliport - NYC - New York - United States",
            "searchName": "JRB-NYC-Downtown Manhattan Heliport-New York-Nueva York-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 1
        },
        {
            "iataCode": "XZM",
            "countryCode": "MO",
            "cityCode": "MFM",
            "cityName": "Macau",
            "name": "[XZM] - Macau Ferry - MFM - Macau - Macao",
            "searchName": "XZM-MFM-Ferry-Macau Ferry-Macau-Macao-Makau-Ma Cao-Makao-Maka-China",
            "priority": 1
        },
        {
            "iataCode": "TSF",
            "countryCode": "IT",
            "cityCode": "VCE",
            "cityName": "Venice",
            "name": "[TSF] - S. Angelo - VCE - Venice - Italy",
            "searchName": "TSF-VCE-Venedig Treviso-S. Angelo-Treviso-Venedig-Venice-Venecia-Venise-Venezia-Venedik-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 1
        },
        {
            "iataCode": "XVQ",
            "countryCode": "IT",
            "cityCode": "VCE",
            "cityName": "Venice",
            "name": "[XVQ] - Santa Lucia Railway - VCE - Venice - Italy",
            "searchName": "XVQ-VCE-Santa Lucia Railway-Venedig-Venice-Venecia-Venise-Venezia-Venedik-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 1
        },
        {
            "iataCode": "XVY",
            "countryCode": "IT",
            "cityCode": "VCE",
            "cityName": "Venice",
            "name": "[XVY] - Mestre Railway. - VCE - Venice - Italy",
            "searchName": "XVY-VCE-Mestre Railway.-Venedig-Venice-Venecia-Venise-Venezia-Venedik-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 1
        },
        {
            "iataCode": "SSN",
            "countryCode": "KR",
            "cityCode": "SEL",
            "cityName": "Seoul",
            "name": "[SSN] - Seoul AB - SEL - Seoul - South Korea",
            "searchName": "SSN-SEL-Seoul AB-Seoul-Se-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 1
        },
        {
            "iataCode": "GMP",
            "countryCode": "KR",
            "cityCode": "SEL",
            "cityName": "Seoul",
            "name": "[GMP] - Gimpo Airport - SEL - Seoul - South Korea",
            "searchName": "GMP-SEL-Seoul Gimpo Intl-Gimpo Airport-Gimpo Intl-Gimpo International-Seoul-Se-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 1
        },
        {
            "iataCode": "ICN",
            "countryCode": "KR",
            "cityCode": "SEL",
            "cityName": "Seoul",
            "name": "[ICN] - Seoul (Incheon) - SEL - Seoul - South Korea",
            "searchName": "ICN-SEL-Incheon-Seoul (Incheon)-Incheon Intl Airport-Incheon International-Seoul-Se-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 1
        },
        {
            "iataCode": "MXP",
            "countryCode": "IT",
            "cityCode": "MIL",
            "cityName": "Milan",
            "name": "[MXP] - Milano Malpensa - MIL - Milan - Italy",
            "searchName": "MXP-MIL-Mailand Malpensa-Milano Malpensa-Malpensa-Aeroporto Malpensa-Mailand-Milan-Mil-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 1
        },
        {
            "iataCode": "LIN",
            "countryCode": "IT",
            "cityCode": "MIL",
            "cityName": "Milan",
            "name": "[LIN] - Linate - MIL - Milan - Italy",
            "searchName": "LIN-MIL-Mailand Linate-Linate-Aeroporto Milano Linate-Mailand-Milan-Mil-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 1
        },
        {
            "iataCode": "IPR",
            "countryCode": "IT",
            "cityCode": "MIL",
            "cityName": "Milan",
            "name": "[IPR] - Porta Garibaldi Station - MIL - Milan - Italy",
            "searchName": "IPR-MIL-Porta Garibaldi Station-Mailand-Milan-Mil-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 1
        },
        {
            "iataCode": "IMR",
            "countryCode": "IT",
            "cityCode": "MIL",
            "cityName": "Milan",
            "name": "[IMR] - Rogoredo Railway Station - MIL - Milan - Italy",
            "searchName": "IMR-MIL-Rogoredo Railway Station-Mailand-Milan-Mil-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 1
        },
        {
            "iataCode": "BGY",
            "countryCode": "IT",
            "cityCode": "MIL",
            "cityName": "Milan",
            "name": "[BGY] - Bergamo - Orio al Serio - MIL - Milan - Italy",
            "searchName": "BGY-MIL-Mailand Bergamo-Bergamo - Orio al Serio-Orio Al Serio-Aeroporto di Bergamo Orio al Serio- Orio Al Serio Airport-Mailand-Milan-Mil-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 1
        },
        {
            "iataCode": "PMF",
            "countryCode": "IT",
            "cityCode": "MIL",
            "cityName": "Milan",
            "name": "[PMF] - Giuseppe Verdi - MIL - Milan - Italy",
            "searchName": "PMF-MIL-Parma-Giuseppe Verdi-Parma Airport-Mailand-Milan-Mil-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 1
        },
        {
            "iataCode": "XNC",
            "countryCode": "IT",
            "cityCode": "MIL",
            "cityName": "Milan",
            "name": "[XNC] - Cadorna Railway Station - MIL - Milan - Italy",
            "searchName": "XNC-MIL-Cadorna Railway Station-Mailand-Milan-Mil-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 1
        },
        {
            "iataCode": "XIK",
            "countryCode": "IT",
            "cityCode": "MIL",
            "cityName": "Milan",
            "name": "[XIK] - Central Station - MIL - Milan - Italy",
            "searchName": "XIK-MIL-Central Station-Mailand-Milan-Mil-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 1
        },
        {
            "iataCode": "CIA",
            "countryCode": "IT",
            "cityCode": "ROM",
            "cityName": "Roma",
            "name": "[CIA] - Ciampino - ROM - Roma - Italy",
            "searchName": "CIA-ROM-Rom Ciampino-Ciampino-Rom-Rome-Roma-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 1
        },
        {
            "iataCode": "FLR",
            "countryCode": "IT",
            "cityCode": "FLR",
            "cityName": "Florence",
            "name": "[FLR] - Peretola - FLR - Florence - Italy",
            "searchName": "FLR-Florenz Peretola-Peretola-Aeroporto Peretola-Florenz-Florence-Florencia-Firenze-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 1
        },
        {
            "iataCode": "ZMS",
            "countryCode": "IT",
            "cityCode": "FLR",
            "cityName": "Florence",
            "name": "[ZMS] - S.M. Novella Rail.Svc - FLR - Florence - Italy",
            "searchName": "ZMS-FLR-S.M. Novella Rail.Svc-Florenz-Florence-Florencia-Firenze-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 1
        },
        {
            "iataCode": "XXQ",
            "countryCode": "HU",
            "cityCode": "BUD",
            "cityName": "Budapest",
            "name": "[XXQ] - Keleti Railway Station - BUD - Budapest - Hungary",
            "searchName": "XXQ-BUD-Keleti Railway Station-Budapest-Hongarije-Hungary-Hung ga ry-Ungern-Wegry-Hongaria-Hungria-Ungarn-Hungría-Ungheria-Vengrija-Ungarija-Madarska-Ungar-Macaristan-Hongrie",
            "priority": 1
        },
        {
            "iataCode": "XXJ",
            "countryCode": "HU",
            "cityCode": "BUD",
            "cityName": "Budapest",
            "name": "[XXJ] - Deli Railway Station - BUD - Budapest - Hungary",
            "searchName": "XXJ-BUD-Deli Railway Station-Budapest-Hongarije-Hungary-Hung ga ry-Ungern-Wegry-Hongaria-Hungria-Ungarn-Hungría-Ungheria-Vengrija-Ungarija-Madarska-Ungar-Macaristan-Hongrie",
            "priority": 1
        },
        {
            "iataCode": "XJB",
            "countryCode": "ES",
            "cityCode": "BCN",
            "cityName": "Barcelona",
            "name": "[XJB] - Barcelona Bus Station - BCN - Barcelona - Spain",
            "searchName": "XJB-BCN-Barcelona Bus Station-Barcelona-Barcelone-Barcellona-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 1
        },
        {
            "iataCode": "YJB",
            "countryCode": "ES",
            "cityCode": "BCN",
            "cityName": "Barcelona",
            "name": "[YJB] - Sants Railway Station - BCN - Barcelona - Spain",
            "searchName": "YJB-BCN-Sants Railway Station-Barcelona-Barcelone-Barcellona-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 1
        },
        {
            "iataCode": "YJD",
            "countryCode": "ES",
            "cityCode": "BCN",
            "cityName": "Barcelona",
            "name": "[YJD] - Franca Railway Station - BCN - Barcelona - Spain",
            "searchName": "YJD-BCN-Franca Railway Station-Barcelona-Barcelone-Barcellona-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 1
        },
        {
            "iataCode": "LCG",
            "countryCode": "ES",
            "cityCode": "LCG",
            "cityName": "La Coruna",
            "name": "[LCG] - La Coruna - LCG - La Coruna - Spain",
            "searchName": "LCG-La Coruna-La Corogne-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 1
        },
        {
            "iataCode": "GYE",
            "countryCode": "EC",
            "cityCode": "GYE",
            "cityName": "Guayaquil",
            "name": "[GYE] - Jose Joaquin De Olmedo - GYE - Guayaquil - Ecuador",
            "searchName": "GYE-Guayaquil-Jose Joaquin De Olmedo-Simon Bolivar-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 1
        },
        {
            "iataCode": "XTI",
            "countryCode": "ES",
            "cityCode": "MAD",
            "cityName": "Madrid",
            "name": "[XTI] - Chamartin Railway St. - MAD - Madrid - Spain",
            "searchName": "XTI-MAD-Chamartin Railway St.-Madrid-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 1
        },
        {
            "iataCode": "XOC",
            "countryCode": "ES",
            "cityCode": "MAD",
            "cityName": "Madrid",
            "name": "[XOC] - Atocha Railway St. - MAD - Madrid - Spain",
            "searchName": "XOC-MAD-Atocha Railway St.-Xoxocotlan-Madrid-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 1
        },
        {
            "iataCode": "TOJ",
            "countryCode": "ES",
            "cityCode": "MAD",
            "cityName": "Madrid",
            "name": "[TOJ] - Torrejon AFB - MAD - Madrid - Spain",
            "searchName": "TOJ-MAD-Torrejon AFB-Madrid-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 1
        },
        {
            "iataCode": "NAY",
            "countryCode": "CN",
            "cityCode": "BJS",
            "cityName": "Beijing",
            "name": "[NAY] - Nanyuan Airport - BJS - Beijing - China",
            "searchName": "NAY-BJS-Peking Nan Yuan-Nanyuan Airport-Nanyuan Havalimani-Beijing-Pek-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 1
        },
        {
            "iataCode": "CLO",
            "countryCode": "CO",
            "cityCode": "CLO",
            "cityName": "Cali",
            "name": "[CLO] - Alfonso B. Aragon - CLO - Cali - Colombia",
            "searchName": "CLO-Cali-Alfonso B. Aragon-Alfonso Bonilla Aragon-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 1
        },
        {
            "iataCode": "AGB",
            "countryCode": "DE",
            "cityCode": "MUC",
            "cityName": "Munich",
            "name": "[AGB] - Augsburg - Muehlhausen - MUC - Munich - Germany",
            "searchName": "AGB-MUC-Augsburg-Mühlhausen Flughafen-Augsburg - Muehlhausen-M-Munich-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 1
        },
        {
            "iataCode": "ZMU",
            "countryCode": "DE",
            "cityCode": "MUC",
            "cityName": "Munich",
            "name": "[ZMU] - Munich Hbf Railway Station - MUC - Munich - Germany",
            "searchName": "ZMU-MUC-Munich Hbf Railway Station-M-Munich-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 1
        },
        {
            "iataCode": "PFL",
            "countryCode": "CZ",
            "cityCode": "PRG",
            "cityName": "Praga",
            "name": "[PFL] - Florenc Bus Station - PRG - Praga - Czech Republic",
            "searchName": "PFL-PRG-Florenc Bus Station-Prag-Prague-Praga-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 1
        },
        {
            "iataCode": "VOD",
            "countryCode": "CZ",
            "cityCode": "PRG",
            "cityName": "Praga",
            "name": "[VOD] - Vodochody - PRG - Praga - Czech Republic",
            "searchName": "VOD-PRG-Vodochody-Prag-Prague-Praga-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 1
        },
        {
            "iataCode": "XYG",
            "countryCode": "CZ",
            "cityCode": "PRG",
            "cityName": "Praga",
            "name": "[XYG] - Prague Main Railway Station - PRG - Praga - Czech Republic",
            "searchName": "XYG-PRG-Prague Main Railway Station-Prag-Prague-Praga-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 1
        },
        {
            "iataCode": "XYJ",
            "countryCode": "CZ",
            "cityCode": "PRG",
            "cityName": "Praga",
            "name": "[XYJ] - Holesovice Railway Station - PRG - Praga - Czech Republic",
            "searchName": "XYJ-PRG-Holesovice Railway Station-Prag-Prague-Praga-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 1
        },
        {
            "iataCode": "HHP",
            "countryCode": "HK",
            "cityCode": "HKG",
            "cityName": "Hong Kong",
            "name": "[HHP] - H K Heliport - HKG - Hong Kong - Hong Kong",
            "searchName": "HHP-HKG-H K Heliport-Hong Kong-Hongkong-Honkonga-Honkongas--Hong-Kong",
            "priority": 1
        },
        {
            "iataCode": "XJH",
            "countryCode": "HK",
            "cityCode": "HKG",
            "cityName": "Hong Kong",
            "name": "[XJH] - Hong Kong Harbour - HKG - Hong Kong - Hong Kong",
            "searchName": "XJH-HKG-Hong Kong Harbour-Hong Kong-Hongkong-Honkonga-Honkongas--Hong-Kong",
            "priority": 1
        },
        {
            "iataCode": "GEO",
            "countryCode": "GY",
            "cityCode": "GEO",
            "cityName": "Georgetown",
            "name": "[GEO] - Cheddi Jagan International - GEO - Georgetown - Guyana",
            "searchName": "GEO-Georgetown Guyana-Cheddi Jagan International-Cheddi Jagan Intl-Georgetown-Guyana",
            "priority": 1
        },
        {
            "iataCode": "GUA",
            "countryCode": "GT",
            "cityCode": "GUA",
            "cityName": "Guatemala City",
            "name": "[GUA] - La Aurora - GUA - Guatemala City - Guatemala",
            "searchName": "GUA-Guatemala City-La Aurora-Guatemala",
            "priority": 1
        },
        {
            "iataCode": "CAN",
            "countryCode": "CN",
            "cityCode": "CAN",
            "cityName": "Guangzhou",
            "name": "[CAN] - Guangzhou Baiyun International - CAN - Guangzhou - China",
            "searchName": "CAN-Guangzhou Baiyun International-Baiyun-Guangzhou-Canton-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 1
        },
        {
            "iataCode": "CTG",
            "countryCode": "CO",
            "cityCode": "CTG",
            "cityName": "Cartagena",
            "name": "[CTG] - Rafael Nunez - CTG - Cartagena - Colombia",
            "searchName": "CTG-Cartagena de Indiens-Rafael Nunez-Bocagrande-Cartagena-Cartag-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 1
        },
        {
            "iataCode": "IQQ",
            "countryCode": "CL",
            "cityCode": "IQQ",
            "cityName": "Iquique",
            "name": "[IQQ] - Cavancha - IQQ - Iquique - Chile",
            "searchName": "IQQ-Iquique-Cavancha-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 1
        },
        {
            "iataCode": "ZYX",
            "countryCode": "CN",
            "cityCode": "SZX",
            "cityName": "Shenzhen",
            "name": "[ZYX] - Yantian Ferry Port - SZX - Shenzhen - China",
            "searchName": "ZYX-SZX-Yantian Ferry Port-Shenzhen-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 1
        },
        {
            "iataCode": "SZX",
            "countryCode": "CN",
            "cityCode": "SZX",
            "cityName": "Shenzhen",
            "name": "[SZX] - Shenzhen - SZX - Shenzhen - China",
            "searchName": "SZX-Shenzhen-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 1
        },
        {
            "iataCode": "FYG",
            "countryCode": "CN",
            "cityCode": "SZX",
            "cityName": "Shenzhen",
            "name": "[FYG] - Fuyong Ferry Port - SZX - Shenzhen - China",
            "searchName": "FYG-SZX-Fuyong Ferry Port-Shenzhen-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 1
        },
        {
            "iataCode": "MDE",
            "countryCode": "CO",
            "cityCode": "MDE",
            "cityName": "Medellin",
            "name": "[MDE] - Jose Maria Cordova - MDE - Medellin - Colombia",
            "searchName": "MDE-Medellin Jose Marie Cordova-Jose Maria Cordova-Jose Marie Cordova-Jose Maria Cordova INTL Airport-Medell-Medellin-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 1
        },
        {
            "iataCode": "PVG",
            "countryCode": "CN",
            "cityCode": "SHA",
            "cityName": "Shanghai",
            "name": "[PVG] - Shanghai Pudong International - SHA - Shanghai - China",
            "searchName": "PVG-SHA-Shanghai Pudong International-Pu Dong-Pu Dong Airport-Shanghai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 1
        },
        {
            "iataCode": "HGH",
            "countryCode": "CN",
            "cityCode": "HGH",
            "cityName": "Hangzhou",
            "name": "[HGH] - Hangzhou - HGH - Hangzhou - China",
            "searchName": "HGH-Hangzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 1
        },
        {
            "iataCode": "LPB",
            "countryCode": "BO",
            "cityCode": "LPB",
            "cityName": "La Paz",
            "name": "[LPB] - El Alto - LPB - La Paz - Bolivia",
            "searchName": "LPB-La Paz Bolivien-El Alto-La Paz-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 1
        },
        {
            "iataCode": "SDU",
            "countryCode": "BR",
            "cityCode": "RIO",
            "cityName": "Rio de Janeiro",
            "name": "[SDU] - Santos Dumont - RIO - Rio de Janeiro - Brazil",
            "searchName": "SDU-RIO-Rio de Janeiro Santos Dumont-Santos Dumont-Santos Dumont Airport-Rio de Janeiro-R-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 1
        },
        {
            "iataCode": "GIG",
            "countryCode": "BR",
            "cityCode": "RIO",
            "cityName": "Rio de Janeiro",
            "name": "[GIG] - Galeao Antonio Carlos Jobim - RIO - Rio de Janeiro - Brazil",
            "searchName": "GIG-RIO-Rio de Janeiro AC Jobim-Galeao Antonio Carlos Jobim- Intl-Rio de Janeiro-R-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 1
        },
        {
            "iataCode": "YKZ",
            "countryCode": "CA",
            "cityCode": "YTO",
            "cityName": "Toronto",
            "name": "[YKZ] - Buttonville Municipalcipal - YTO - Toronto - Canada",
            "searchName": "YKZ-YTO-Buttonville Municipalcipal-Buttonville Havalimani-Toronto-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 1
        },
        {
            "iataCode": "YHM",
            "countryCode": "CA",
            "cityCode": "YTO",
            "cityName": "Toronto",
            "name": "[YHM] - John C. Munroe - YTO - Toronto - Canada",
            "searchName": "YHM-YTO-Hamilton Kanada-John C. Munroe-Hamilton-Toronto-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 1
        },
        {
            "iataCode": "YBZ",
            "countryCode": "CA",
            "cityCode": "YTO",
            "cityName": "Toronto",
            "name": "[YBZ] - Toronto Downtown - YTO - Toronto - Canada",
            "searchName": "YBZ-YTO-Toronto Downtown-Toronto-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 1
        },
        {
            "iataCode": "XLQ",
            "countryCode": "CA",
            "cityCode": "YTO",
            "cityName": "Toronto",
            "name": "[XLQ] - Guildwood Railway Station - YTO - Toronto - Canada",
            "searchName": "XLQ-YTO-Guildwood Railway Station-Toronto-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 1
        },
        {
            "iataCode": "ARI",
            "countryCode": "CL",
            "cityCode": "ARI",
            "cityName": "Arica",
            "name": "[ARI] - Chacalluta - ARI - Arica - Chile",
            "searchName": "ARI-Arica Chile-Chacalluta-Arica-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 1
        },
        {
            "iataCode": "YTZ",
            "countryCode": "CA",
            "cityCode": "YTO",
            "cityName": "Toronto",
            "name": "[YTZ] - Toronto City Centre Airport - YTO - Toronto - Canada",
            "searchName": "YTZ-YTO-Toronto Island-Toronto City Centre Airport-Toronto Island Airport-Toronto-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 1
        },
        {
            "iataCode": "IGU",
            "countryCode": "BR",
            "cityCode": "IGU",
            "cityName": "Iguassu Falls",
            "name": "[IGU] - Cataratas - IGU - Iguassu Falls - Brazil",
            "searchName": "IGU-Foz do Iguacu-Cataratas-Iguassu Falls-Foz de Iguazu-Chutes d Igua-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 1
        },
        {
            "iataCode": "CWB",
            "countryCode": "BR",
            "cityCode": "CWB",
            "cityName": "Curitiba",
            "name": "[CWB] - Afonso Pena International Airport - CWB - Curitiba - Brazil",
            "searchName": "CWB-Curitiba-Afonso Pena International Airport-Afonso Pena-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 1
        },
        {
            "iataCode": "CGH",
            "countryCode": "BR",
            "cityCode": "SAO",
            "cityName": "Sao Paulo",
            "name": "[CGH] - Congonhas International Airport - SAO - Sao Paulo - Brazil",
            "searchName": "CGH-SAO-Sao Paulo Congonhas-Congonhas International Airport-Congonhas-S-Sao Paulo-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 1
        },
        {
            "iataCode": "GRU",
            "countryCode": "BR",
            "cityCode": "SAO",
            "cityName": "Sao Paulo",
            "name": "[GRU] - Aeroporto Internacional Guarulhos - SAO - Sao Paulo - Brazil",
            "searchName": "GRU-SAO-Sao Paulo Guarulhos-Aeroporto Internacional Guarulhos-Guarulhos Intl-S-Sao Paulo-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 1
        },
        {
            "iataCode": "RAO",
            "countryCode": "BR",
            "cityCode": "RAO",
            "cityName": "Ribeirao Preto",
            "name": "[RAO] - Leite Lopes - RAO - Ribeirao Preto - Brazil",
            "searchName": "RAO-Riberao Preto-Leite Lopes-Ribeir-Ribeirao Preto-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 1
        },
        {
            "iataCode": "POA",
            "countryCode": "BR",
            "cityCode": "POA",
            "cityName": "Porto Alegre",
            "name": "[POA] - Salgado Filho - POA - Porto Alegre - Brazil",
            "searchName": "POA-Porto Alegre-Salgado Filho-Puerto Alegre-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 1
        },
        {
            "iataCode": "VVI",
            "countryCode": "BO",
            "cityCode": "SRZ",
            "cityName": "Santa Cruz",
            "name": "[VVI] - Viru Viru International - SRZ - Santa Cruz - Bolivia",
            "searchName": "VVI-SRZ-Santa Cruz Bolivien Viru Viru-Viru Viru International-Viru Viru Intl-Viru Viru INTL-Santa Cruz-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 1
        },
        {
            "iataCode": "CNF",
            "countryCode": "BR",
            "cityCode": "BHZ",
            "cityName": "Belo Horizonte",
            "name": "[CNF] - Tancredo Neves International Airport - BHZ - Belo Horizonte - Brazil",
            "searchName": "CNF-BHZ-Belo Horizonte Tancredo Neves-Tancredo Neves International Airport-Tancredo Neves Intl-Tancredo Neves Intl.-Belo Horizonte-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 1
        },
        {
            "iataCode": "DWC",
            "countryCode": "AE",
            "cityCode": "DXB",
            "cityName": "Dubai",
            "name": "[DWC] - Dubai World Central - DXB - Dubai - United Arab Emirates",
            "searchName": "DWC-DXB-Dubai World Central-Al Maktoum International Airport-Al Maktoum Intl Havalimani-Dubai-Duba-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 1
        },
        {
            "iataCode": "DCG",
            "countryCode": "AE",
            "cityCode": "DXB",
            "cityName": "Dubai",
            "name": "[DCG] - Dubai Creek SPB - DXB - Dubai - United Arab Emirates",
            "searchName": "DCG-DXB-Dubai Creek SPB-Dubai-Duba-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 1
        },
        {
            "iataCode": "NHD",
            "countryCode": "AE",
            "cityCode": "DXB",
            "cityName": "Dubai",
            "name": "[NHD] - Minhad AB - DXB - Dubai - United Arab Emirates",
            "searchName": "NHD-DXB-Minhad AB-Dubai-Duba-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 1
        },
        {
            "iataCode": "XMB",
            "countryCode": "AE",
            "cityCode": "DXB",
            "cityName": "Dubai",
            "name": "[XMB] - Marina Mall EY Bus Station - DXB - Dubai - United Arab Emirates",
            "searchName": "XMB-DXB-M Bahiakro-Marina Mall EY Bus Station-Estación de autobuses de Dubai Marina Mall-Dubai Marina Mall Bus Station-Mbahiakoro Airport-Dubai-Duba-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 1
        },
        {
            "iataCode": "XNB",
            "countryCode": "AE",
            "cityCode": "DXB",
            "cityName": "Dubai",
            "name": "[XNB] - Chelsea Twr EY Bus Station - DXB - Dubai - United Arab Emirates",
            "searchName": "XNB-DXB-Dubai Bus Station-Chelsea Twr EY Bus Station-Dubai-Duba-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 1
        },
        {
            "iataCode": "XEU",
            "countryCode": "AE",
            "cityCode": "DXB",
            "cityName": "Dubai",
            "name": "[XEU] - Hyatt Rgncy EY Bus Station - DXB - Dubai - United Arab Emirates",
            "searchName": "XEU-DXB-Hyatt Rgncy EY Bus Station-Dubai-Duba-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 1
        },
        {
            "iataCode": "ZXZ",
            "countryCode": "AE",
            "cityCode": "DXB",
            "cityName": "Dubai",
            "name": "[ZXZ] - Jumeirah Beach Bus Station - DXB - Dubai - United Arab Emirates",
            "searchName": "ZXZ-DXB-Jumeirah Beach Bus Station-Dubai-Duba-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 1
        },
        {
            "iataCode": "MDZ",
            "countryCode": "AR",
            "cityCode": "MDZ",
            "cityName": "Mendoza",
            "name": "[MDZ] - El Plumerillo - MDZ - Mendoza - Argentina",
            "searchName": "MDZ-Mendoza-El Plumerillo-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 1
        },
        {
            "iataCode": "XWT",
            "countryCode": "AT",
            "cityCode": "VIE",
            "cityName": "Vienna",
            "name": "[XWT] - Mitte CAT Rail Station - VIE - Vienna - Austria",
            "searchName": "XWT-VIE-Mitte CAT Rail Station-Wien-Vienna-Viena-Vienne-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 1
        },
        {
            "iataCode": "XWC",
            "countryCode": "AT",
            "cityCode": "VIE",
            "cityName": "Vienna",
            "name": "[XWC] - Suedbahnhof Railway - VIE - Vienna - Austria",
            "searchName": "XWC-VIE-Suedbahnhof Railway-Wien-Vienna-Viena-Vienne-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 1
        },
        {
            "iataCode": "XWW",
            "countryCode": "AT",
            "cityCode": "VIE",
            "cityName": "Vienna",
            "name": "[XWW] - Westbahnhof Railway - VIE - Vienna - Austria",
            "searchName": "XWW-VIE-Westbahnhof Railway-Wien-Vienna-Viena-Vienne-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 1
        },
        {
            "iataCode": "AEP",
            "countryCode": "AR",
            "cityCode": "BUE",
            "cityName": "Buenos Aires",
            "name": "[AEP] - Aeroparque Jorge Newbery - BUE - Buenos Aires - Argentina",
            "searchName": "AEP-BUE-Buenos Aires Jorge Newbery-Aeroparque Jorge Newbery-Arpt. Jorge Newbery-Jorge Newbery-Buenos Aires-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 1
        },
        {
            "iataCode": "EZE",
            "countryCode": "AR",
            "cityCode": "BUE",
            "cityName": "Buenos Aires",
            "name": "[EZE] - Ezeiza Ministro Pistarini - BUE - Buenos Aires - Argentina",
            "searchName": "EZE-BUE-Buenos Aires Ministro Pistarini-Ezeiza Ministro Pistarini-Ministro Pistarini-Buenos Aires-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 1
        },
        {
            "iataCode": "COR",
            "countryCode": "AR",
            "cityCode": "COR",
            "cityName": "Cordoba",
            "name": "[COR] - Pajas Blancas - COR - Cordoba - Argentina",
            "searchName": "COR-Cordoba Argentinien-Pajas Blancas-C-Cordoba-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 1
        },
        {
            "iataCode": "RSE",
            "countryCode": "AU",
            "cityCode": "SYD",
            "cityName": "Sydney",
            "name": "[RSE] - Rose Bay - SYD - Sydney - Australia",
            "searchName": "RSE-SYD-Au-Rose Bay-Au Rose Bay Havalimani-Sydney-Sidney-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 1
        },
        {
            "iataCode": "XYS",
            "countryCode": "AU",
            "cityCode": "SYD",
            "cityName": "Sydney",
            "name": "[XYS] - Sydney Ferry Port - SYD - Sydney - Australia",
            "searchName": "XYS-SYD-Sydney Ferry Port-Sydney-Sidney-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 1
        },
        {
            "iataCode": "LBH",
            "countryCode": "AU",
            "cityCode": "SYD",
            "cityName": "Sydney",
            "name": "[LBH] - Palm Beach SPB - SYD - Sydney - Australia",
            "searchName": "LBH-SYD-Palm Beach SPB-Sydney-Sidney-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 1
        },
        {
            "iataCode": "BWU",
            "countryCode": "AU",
            "cityCode": "SYD",
            "cityName": "Sydney",
            "name": "[BWU] - Sydney Bankstown - SYD - Sydney - Australia",
            "searchName": "BWU-SYD-Sydney Bankstown-Bankstown Havalimani-Sydney-Sidney-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 1
        },
        {
            "iataCode": "GZP",
            "countryCode": "TR",
            "cityCode": "GZP",
            "cityName": "Alanya",
            "name": "[GZP] - Gazipasa Airport - GZP - Alanya - Turkey",
            "searchName": "GZP-Gazipa¿a-Gazipasa Airport-Gazipa-Alanya-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "LCY",
            "countryCode": "GB",
            "cityCode": "LON",
            "cityName": "Londres",
            "name": "[LCY] - London City Airport - LON - Londres - United Kingdom",
            "searchName": "LCY-LON-London City-London City Airport-City Airport-London-Londres-Londra-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ORY",
            "countryCode": "FR",
            "cityCode": "PAR",
            "cityName": "Paris",
            "name": "[ORY] - Orly - PAR - Paris - France",
            "searchName": "ORY-PAR-Paris Orly-Orly-Paris-Par-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "IEV",
            "countryCode": "UA",
            "cityCode": "IEV",
            "cityName": "Kiev",
            "name": "[IEV] - Zhulyany - IEV - Kiev - Ukraine",
            "searchName": "IEV-Kiew Zhulhany-Zhulyany-Zhulhany y otros aeropuertos-Zhulhany et autres aéroports-Zhulhany e altri aeroporti-Kiev-Kiew-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "LED",
            "countryCode": "RU",
            "cityCode": "LED",
            "cityName": "Saint Petersburg",
            "name": "[LED] - Pulkovo - LED - Saint Petersburg - Russia",
            "searchName": "LED-Pulkowo-Pulkovo-Sankt Petersburg-Saint Petersburg-San Petersburgo-Saint-P-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NCE",
            "countryCode": "FR",
            "cityCode": "NCE",
            "cityName": "Nice",
            "name": "[NCE] - Cote Dazur International Airport - NCE - Nice - France",
            "searchName": "NCE-Nizza-Cote D azur International Airport-Cote D Azur-Côte d Azur-Cote d Azur Int. Apt.-Cote Dazur International Airport-Nice-Niza-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "GVA",
            "countryCode": "CH",
            "cityCode": "GVA",
            "cityName": "Geneva",
            "name": "[GVA] - Geneve-cointrin - GVA - Geneva - Switzerland",
            "searchName": "GVA-Genf-Geneve-cointrin-Cointrin-Cointrin Int. Apt-Geneva-Ginebra-Gen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ACE",
            "countryCode": "ES",
            "cityCode": "ACE",
            "cityName": "Arrecife",
            "name": "[ACE] - Lanzarote - ACE - Arrecife - Spain",
            "searchName": "ACE-Arrecife-Lanzarote-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "ROV",
            "countryCode": "RU",
            "cityCode": "ROV",
            "cityName": "Rostov",
            "name": "[ROV] - Rostov - ROV - Rostov - Russia",
            "searchName": "ROV-Rostow-Rostov-on-Don Apt-Rostov On Don-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "PKC",
            "countryCode": "RU",
            "cityCode": "PKC",
            "cityName": "Petropavlovsk-Kamchats",
            "name": "[PKC] - Petropavlovsk-Kamchatsky - PKC - Petropavlovsk-Kamchats - Russia",
            "searchName": "PKC-Petropawlowsk-Kamtschatski-Petropavlovsk-Kamchatsky-Kamchats-Yelizovo Apt.-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "CLJ",
            "countryCode": "RO",
            "cityCode": "CLJ",
            "cityName": "Cluj-Napoca",
            "name": "[CLJ] - Cluj Napoca International Airport - CLJ - Cluj-Napoca - Romania",
            "searchName": "CLJ-Cluj-Cluj Napoca International Airport-Napoca-Cluj Napoca-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "TLV",
            "countryCode": "IL",
            "cityCode": "TLV",
            "cityName": "Tel Aviv Yafo",
            "name": "[TLV] - Ben Gurion International - TLV - Tel Aviv Yafo - Israel",
            "searchName": "TLV-Ben Gurion-Ben Gurion International-Ben Gurion Intl-Tel Aviv Yafo-Tel Aviv-Yafo-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "KIV",
            "countryCode": "MD",
            "cityCode": "KIV",
            "cityName": "Chisinau",
            "name": "[KIV] - International Airport Chisinau - KIV - Chisinau - Moldova",
            "searchName": "KIV-International Airport Chisinau-Chisinau-Moldova-Moldàvia-Moldavsko-Moldawia-Moldavien-Moldovos-Moldavija-Moldawien-Moldavia-Moldavie",
            "priority": 0
        },
        {
            "iataCode": "KBP",
            "countryCode": "UA",
            "cityCode": "IEV",
            "cityName": "Kiev",
            "name": "[KBP] - Boryspil (Borispol) - IEV - Kiev - Ukraine",
            "searchName": "KBP-IEV-Kiew-Boryspil-Boryspil (Borispol)-Borispol-Kiev-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "BER",
            "countryCode": "DE",
            "cityCode": "BER",
            "cityName": "Berlin",
            "name": "[BER] - Berlin - BER - Berlin - Germany",
            "searchName": "BER-Berlin Tegel-Berlin-tegel / Otto Lilienthal-Tegel-Tegel \\/ Otto Lilienthal-Berl-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "BEG",
            "countryCode": "RS",
            "cityCode": "BEG",
            "cityName": "Belgrade",
            "name": "[BEG] - Belgrade Nikola Tesla - BEG - Belgrade - Serbia",
            "searchName": "BEG-Nikola-Tesla-Flughafen Belgrad-Belgrade Nikola Tesla-Nikola Tesla-Beograd-Belgrad-Belgrade-Belgrado-Szerbia-Sèrbia-Sirbistan-Srbsko-Serbia-Serbien-Srbija-Serbija-Serbie--Suudi Arabistan",
            "priority": 0
        },
        {
            "iataCode": "ZRH",
            "countryCode": "CH",
            "cityCode": "ZRH",
            "cityName": "Zurich",
            "name": "[ZRH] - Zurich - ZRH - Zurich - Switzerland",
            "searchName": "ZRH-Zürich-Kloten-Zurich-Zurich Intl-Zurich Int. Apt-Z-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "HEL",
            "countryCode": "FI",
            "cityCode": "HEL",
            "cityName": "Helsinki",
            "name": "[HEL] - Helsinki-vantaa - HEL - Helsinki - Finland",
            "searchName": "HEL-Helsinki-Vantaa-vantaa-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "DUS",
            "countryCode": "DE",
            "cityCode": "DUS",
            "cityName": "Dusseldorf",
            "name": "[DUS] - DÃ¼sseldorf International Airport - DUS - Dusseldorf - Germany",
            "searchName": "DUS-Düsseldorf International-Düsseldorf International Airport-Düsseldorf-Rhein-Ruhr International-DÃ¼sseldorf International Airport-D-Dusseldorf-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "OSL",
            "countryCode": "NO",
            "cityCode": "OSL",
            "cityName": "Oslo",
            "name": "[OSL] - Gardermoen - OSL - Oslo - Norway",
            "searchName": "OSL-Oslo Gardermoen-Gardermoen-Oslo Airport-Intl. Apt-Oslo-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "LCA",
            "countryCode": "CY",
            "cityCode": "LCA",
            "cityName": "Larnaca",
            "name": "[LCA] - Larnaca - LCA - Larnaca - Cyprus",
            "searchName": "LCA-Larnaca-Larnaka-Citium-Ciprus-Cypr-Siprus-Cyprus-Síp-Kipra-Kypros-Küpros-Cipar-Xipre-Kypr-Cypern-Chipre-Zypern-Chypre-Kibris-Cipru-Kipras-Cipro",
            "priority": 0
        },
        {
            "iataCode": "PMI",
            "countryCode": "ES",
            "cityCode": "PMI",
            "cityName": "Palma Mallorca",
            "name": "[PMI] - Son Sant Joan Airport - PMI - Palma Mallorca - Spain",
            "searchName": "PMI-Palma de Mallorca-Son Sant Joan Airport-Palma Mallorca-Mallorca (Palma)-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "OTP",
            "countryCode": "RO",
            "cityCode": "BUH",
            "cityName": "Bucharest",
            "name": "[OTP] - Henri Coanda International - BUH - Bucharest - Romania",
            "searchName": "OTP-BUH-Bukarest Otopeni-Henri Coanda International-Otopeni Intl-Otopeni International-Bucharest-Bucarest-B-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "RIX",
            "countryCode": "LV",
            "cityCode": "RIX",
            "cityName": "Riga",
            "name": "[RIX] - Riga International - RIX - Riga - Latvia",
            "searchName": "RIX-Riga-Riga International-Riga Int. Apt.-Lettország-Letònia-Letonya-Lotyšsko-Latvia-Letland-Lotwa-Läti-Latvija-Lát via-Letonia-Lettland-Lettonia-L-Lettonie",
            "priority": 0
        },
        {
            "iataCode": "ZAG",
            "countryCode": "HR",
            "cityCode": "ZAG",
            "cityName": "Zagreb",
            "name": "[ZAG] - Pleso - ZAG - Zagreb - Croatia",
            "searchName": "ZAG-Zagreb-Pleso-Pleso Int. Apt-Zagabria-Kroatië-Kroatia-Chorvatsko-Hirvatistan-Croàcia-Horvátország-Croatia-Hrvaška-Kroatija-Horvatija-Hrvatska-Horvaatia-Chorwacja-Kroasia-Kroatien-Croatie-Croacia-Croazia",
            "priority": 0
        },
        {
            "iataCode": "ODS",
            "countryCode": "UA",
            "cityCode": "ODS",
            "cityName": "Odessa",
            "name": "[ODS] - Odessa International - ODS - Odessa - Ukraine",
            "searchName": "ODS-Odessa-Odessa International-Central-Odessa Central Apt-Odesa-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "NUE",
            "countryCode": "DE",
            "cityCode": "NUE",
            "cityName": "Nürnberg",
            "name": "[NUE] - NÃ¼rnberg - NUE - Nürnberg - Germany",
            "searchName": "NUE-Nürnberg-NÃ¼rnberg-N-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "LJU",
            "countryCode": "SI",
            "cityCode": "LJU",
            "cityName": "Ljubljana",
            "name": "[LJU] - Brnik - LJU - Ljubljana - Slovenia",
            "searchName": "LJU-Ljubljana-Brnik-Brnik Apt.-Liubliana-Lubiana-Ljubliana-Slovenia-Slovénie-Slovenien-Slovinsko-Slovenya-Eslovènia-Szlovénia-Slovenija-Sloveenia--Slowenien-Eslovenia",
            "priority": 0
        },
        {
            "iataCode": "CPH",
            "countryCode": "DK",
            "cityCode": "CPH",
            "cityName": "Copenhagen",
            "name": "[CPH] - Kastrup - CPH - Copenhagen - Denmark",
            "searchName": "CPH-Kopenhagen-Kastrup-Kastrup Copenhagen-Copenhagen-Copenhague-Copenaghen-Copenhag-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "LUX",
            "countryCode": "LU",
            "cityCode": "LUX",
            "cityName": "Luxembourg",
            "name": "[LUX] - Findel - LUX - Luxembourg - Luxembourg",
            "searchName": "LUX-Luxemburg Findel-Findel-Luxembourg-Luksemburg  Int. Apt-Luxemburg-Luxemburgo-Lussemburgo-L-Lúc xam bua-Lusse-Lüksemburg",
            "priority": 0
        },
        {
            "iataCode": "AER",
            "countryCode": "RU",
            "cityCode": "AER",
            "cityName": "Sochi",
            "name": "[AER] - Sochi/Adler International Airport - AER - Sochi - Russia",
            "searchName": "AER-Sochi/Adler-Sochi/Adler International Airport-Adler/Sochi-Adler-Sochi Int. Apt-Sochi-Adler\\/Sochi-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "LGW",
            "countryCode": "GB",
            "cityCode": "LON",
            "cityName": "Londres",
            "name": "[LGW] - Gatwick - LON - Londres - United Kingdom",
            "searchName": "LGW-LON-London Gatwick-Gatwick-London-Londres-Londra-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "LDY",
            "countryCode": "GB",
            "cityCode": "LDY",
            "cityName": "Derry",
            "name": "[LDY] - Eglinton (City of Derry) - LDY - Derry - United Kingdom",
            "searchName": "LDY-Londonderry-Eglinton (City of Derry)-Eglinton-Eglinton Apt-Derry-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ARN",
            "countryCode": "SE",
            "cityCode": "STO",
            "cityName": "Stockholm",
            "name": "[ARN] - Arlanda - STO - Stockholm - Sweden",
            "searchName": "ARN-STO-Stockholm Arlanda-Arlanda-Stockholm-Estocolmo-Stoccolma-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "CBR",
            "countryCode": "AU",
            "cityCode": "CBR",
            "cityName": "Canberra",
            "name": "[CBR] - Canberra - CBR - Canberra - Australia",
            "searchName": "CBR-Canberra-Camberra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MAN",
            "countryCode": "GB",
            "cityCode": "MAN",
            "cityName": "Manchester",
            "name": "[MAN] - Ringway International Airport - MAN - Manchester - United Kingdom",
            "searchName": "MAN-Manchester-Ringway International Airport-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "KUF",
            "countryCode": "RU",
            "cityCode": "KUF",
            "cityName": "Samara",
            "name": "[KUF] - Kurumoch - KUF - Samara - Russia",
            "searchName": "KUF-Kurumoch-Samara-Kurumoch Apt-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "AYT",
            "countryCode": "TR",
            "cityCode": "AYT",
            "cityName": "Antalya",
            "name": "[AYT] - Antalya - AYT - Antalya - Turkey",
            "searchName": "AYT-Antalya-Adalia-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "ATH",
            "countryCode": "GR",
            "cityCode": "ATH",
            "cityName": "Athens",
            "name": "[ATH] - Athens International Airport \"Eleftherios Venizelos\" - ATH - Athens - Greece",
            "searchName": "ATH-Athen-Eleftherios Venizelos-Athens International Airport \"Eleftherios Venizelos\"-Eleftherios V.-Atina-Athens-Atenas-Ath-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "SIP",
            "countryCode": "UA",
            "cityCode": "SIP",
            "cityName": "Simferopol",
            "name": "[SIP] - Simferopol - SIP - Simferopol - Ukraine",
            "searchName": "SIP-Simferopol-Akmescit Havalimani-Akmescit-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "FAO",
            "countryCode": "PT",
            "cityCode": "FAO",
            "cityName": "Faro",
            "name": "[FAO] - Faro - FAO - Faro - Portugal",
            "searchName": "FAO-Faro-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "SVX",
            "countryCode": "RU",
            "cityCode": "SVX",
            "cityName": "Ekaterinburg",
            "name": "[SVX] - Koltsovo International - SVX - Ekaterinburg - Russia",
            "searchName": "SVX-Jekaterinburg-Koltsovo International-Ekaterinburg-Koltsovo Int. Apt-Iekaterinbourg-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "PIZ",
            "countryCode": "US",
            "cityCode": "PIZ",
            "cityName": "Point Lay",
            "name": "[PIZ] - Dew Station - PIZ - Point Lay - United States",
            "searchName": "PIZ-Dew Station-Point Lay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HOU",
            "countryCode": "US",
            "cityCode": "HOU",
            "cityName": "Houston",
            "name": "[HOU] - William P Hobby - HOU - Houston - United States",
            "searchName": "HOU-Houston Hobby-William P Hobby-Hobby y otros aeropuertos-Hobby et autres aéroports-Hobby e altri aeroporti-All airports-Houston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DEL",
            "countryCode": "IN",
            "cityCode": "DEL",
            "cityName": "New Delhi",
            "name": "[DEL] - Indira Gandhi International - DEL - New Delhi - India",
            "searchName": "DEL-Indira Gandhi International -Indira Gandhi International-Indira Gandhi Intl-Neu-Delhi-New Delhi-Nueva Delhi-Nuova Delhi-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "DPS",
            "countryCode": "ID",
            "cityCode": "DPS",
            "cityName": "Denpasar",
            "name": "[DPS] - Ngurah Rai International - DPS - Denpasar - Indonesia",
            "searchName": "DPS-Internationale Flughafen Ngurah Rai-Ngurah Rai International-Ngurah Rai-Denpasar Bali-Denpasar-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ALG",
            "countryCode": "DZ",
            "cityCode": "ALG",
            "cityName": "Algiers",
            "name": "[ALG] - Houari Boumediene - ALG - Algiers - Algeria",
            "searchName": "ALG-Algiers-Houari Boumediene-Algier-Argel-Alger-Algeri-Cezayir-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija",
            "priority": 0
        },
        {
            "iataCode": "BOM",
            "countryCode": "IN",
            "cityCode": "BOM",
            "cityName": "Mumbai",
            "name": "[BOM] - Chhatrapati Shivaji International (Sahar International) - BOM - Mumbai - India",
            "searchName": "BOM-Chhatrapati Shivaji International-Chhatrapati Shivaji International (Sahar International)-Chhatrapati Shivaji-Mumbai (Maharashtra)-Mumbai-Mumbai (Bombay)-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "ALA",
            "countryCode": "KZ",
            "cityCode": "ALA",
            "cityName": "Almaty",
            "name": "[ALA] - Almaty - ALA - Almaty - Kazakhstan",
            "searchName": "ALA-Almaty-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "MRS",
            "countryCode": "FR",
            "cityCode": "MRS",
            "cityName": "Marseille",
            "name": "[MRS] - Marseille Provence Airport - MRS - Marseille - France",
            "searchName": "MRS-Marseille-Marseille Provence Airport-Marseille Provence Apt.-Marsella-Marsiglia-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "KGD",
            "countryCode": "RU",
            "cityCode": "KGD",
            "cityName": "Kaliningrad",
            "name": "[KGD] - Khrabrovo - KGD - Kaliningrad - Russia",
            "searchName": "KGD-Kaliningrad-Khrabrovo-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "CMN",
            "countryCode": "MA",
            "cityCode": "CAS",
            "cityName": "Casablanca",
            "name": "[CMN] - Mohamed V - CAS - Casablanca - Morocco",
            "searchName": "CMN-CAS-Casablanca-Mohamed V-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "SSH",
            "countryCode": "EG",
            "cityCode": "SSH",
            "cityName": "Sharm el-Sheikh",
            "name": "[SSH] - Sharm el-Sheikh International Airport - SSH - Sharm el-Sheikh - Egypt",
            "searchName": "SSH-Flughafen Scharm El-Scheich-Sharm el-Sheikh International Airport-Aeropuerto Internacional de Sharm el-Sheij-Aéroport international de Charm el-Cheikh-Aeroporto Internazionale di Sharm el-Sheikh-Scharm El-Charm el-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "ORN",
            "countryCode": "DZ",
            "cityCode": "ORN",
            "cityName": "Oran",
            "name": "[ORN] - Es Senia - ORN - Oran - Algeria",
            "searchName": "ORN-Oran Algerien-Es Senia-Oran-Or-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "OPO",
            "countryCode": "PT",
            "cityCode": "OPO",
            "cityName": "Porto",
            "name": "[OPO] - Francisco SÃ¡ Carneiro - OPO - Porto - Portugal",
            "searchName": "OPO-Aeroporto de Pedras Rubras-Francisco Sá Carneiro-Porto-Porto Int. Apt.-Francisco SÃ¡ Carneiro-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "OVB",
            "countryCode": "RU",
            "cityCode": "OVB",
            "cityName": "Novosibirsk",
            "name": "[OVB] - Tolmachevo - OVB - Novosibirsk - Russia",
            "searchName": "OVB-Nowosibirsk-Tolmachevo-Novosibirsk-Novossibirsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "MJV",
            "countryCode": "ES",
            "cityCode": "MJV",
            "cityName": "Murcia",
            "name": "[MJV] - San Javier - MJV - Murcia - Spain",
            "searchName": "MJV-Murcia-San Javier-San Javier Apt-Murcie-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "GOI",
            "countryCode": "IN",
            "cityCode": "GOI",
            "cityName": "Goa",
            "name": "[GOI] - Goa International - GOI - Goa - India",
            "searchName": "GOI-Dabolim-Goa International-Goa-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "TIV",
            "countryCode": "ME",
            "cityCode": "TIV",
            "cityName": "Tivat",
            "name": "[TIV] - Tivat - TIV - Tivat - Montenegro",
            "searchName": "TIV-Tivat-Teodo-Montenegro-Cerná Hora-Karadag-Montenegró-Monténégro-Muntenegru-Crna Gora-Juodkalnija-Melnkalne",
            "priority": 0
        },
        {
            "iataCode": "OMS",
            "countryCode": "RU",
            "cityCode": "OMS",
            "cityName": "Omsk",
            "name": "[OMS] - Omsk - OMS - Omsk - Russia",
            "searchName": "OMS-Omsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "VVO",
            "countryCode": "RU",
            "cityCode": "VVO",
            "cityName": "Vladivostok",
            "name": "[VVO] - Vladivostok - VVO - Vladivostok - Russia",
            "searchName": "VVO-Wladiwostok-Vladivostok-Vladivostok Int. Apt-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "TGD",
            "countryCode": "ME",
            "cityCode": "TGD",
            "cityName": "Podgorica",
            "name": "[TGD] - Golubovci - TGD - Podgorica - Montenegro",
            "searchName": "TGD-Podgorica-Golubovci-Montenegro-Cerná Hora-Karadag-Montenegró-Monténégro-Muntenegru-Crna Gora-Juodkalnija-Melnkalne",
            "priority": 0
        },
        {
            "iataCode": "VNO",
            "countryCode": "LT",
            "cityCode": "VNO",
            "cityName": "Vilnius",
            "name": "[VNO] - Vilnius - VNO - Vilnius - Lithuania",
            "searchName": "VNO-Vilnius-Vilnius Havalimani-Vilna-Lituânia-Litouwen-Lithuania-Litauen-Lituanie-Lituania-Litwa-Leedu-Li-Litvanya",
            "priority": 0
        },
        {
            "iataCode": "EDI",
            "countryCode": "GB",
            "cityCode": "EDI",
            "cityName": "Edinburgh",
            "name": "[EDI] - Edinburgh - EDI - Edinburgh - United Kingdom",
            "searchName": "EDI-Edinburgh-Turnhouse-Edinburg Apt-Edimburgo-Edinbourg-Edinburg-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "TSE",
            "countryCode": "KZ",
            "cityCode": "TSE",
            "cityName": "Astana",
            "name": "[TSE] - Astana - TSE - Astana - Kazakhstan",
            "searchName": "TSE-Astana-Astan-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "MLA",
            "countryCode": "MT",
            "cityCode": "MLA",
            "cityName": "Gudja",
            "name": "[MLA] - Malta International - MLA - Gudja - Malta",
            "searchName": "MLA-Malta Luqa-Malta International-Luqa-Luqa Int. Apt.-Malta-Gudja-Malte-Málta-Mal",
            "priority": 0
        },
        {
            "iataCode": "DOK",
            "countryCode": "UA",
            "cityCode": "DOK",
            "cityName": "Donetsk",
            "name": "[DOK] - Donetsk International Airport - DOK - Donetsk - Ukraine",
            "searchName": "DOK-Donetsk-Donetsk International Airport-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "KLV",
            "countryCode": "CZ",
            "cityCode": "KLV",
            "cityName": "Karlovy Vary",
            "name": "[KLV] - Karlovy Vary - KLV - Karlovy Vary - Czech Republic",
            "searchName": "KLV-Karlsbad-Karlovy Vary-Karlovy Vary Airport-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "HAM",
            "countryCode": "DE",
            "cityCode": "HAM",
            "cityName": "Hamburg",
            "name": "[HAM] - Hamburg Airport - HAM - Hamburg - Germany",
            "searchName": "HAM-Hamburg-Fuhlsbüttel-Hamburg Airport-Fuhlsbuettel-Hambourg-Amburgo-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "HRG",
            "countryCode": "EG",
            "cityCode": "HRG",
            "cityName": "Hurghada",
            "name": "[HRG] - Hurghada - HRG - Hurghada - Egypt",
            "searchName": "HRG-Hurghada-Hurgada-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "KRR",
            "countryCode": "RU",
            "cityCode": "KRR",
            "cityName": "Krasnodar",
            "name": "[KRR] - Pashkovsky - KRR - Krasnodar - Russia",
            "searchName": "KRR-Krasnodar-Pashkovsky-Pashkovsky Int. Apt-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "CEK",
            "countryCode": "RU",
            "cityCode": "CEK",
            "cityName": "Chelyabinsk",
            "name": "[CEK] - Chelyabinsk - CEK - Chelyabinsk - Russia",
            "searchName": "CEK-Tscheljabinsk-Chelyabinsk-Balandino Apt-Cheli-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "UFA",
            "countryCode": "RU",
            "cityCode": "UFA",
            "cityName": "Ufa",
            "name": "[UFA] - Ufa International Airport - UFA - Ufa - Russia",
            "searchName": "UFA-Ufa Russland-Ufa International Airport-Ufa-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "BOS",
            "countryCode": "US",
            "cityCode": "BOS",
            "cityName": "Boston",
            "name": "[BOS] - Logan International - BOS - Boston - United States",
            "searchName": "BOS-Edward Lawrence Logan International-Logan International-Logan-Boston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SKG",
            "countryCode": "GR",
            "cityCode": "SKG",
            "cityName": "Thessaloniki",
            "name": "[SKG] - Macedonia International - SKG - Thessaloniki - Greece",
            "searchName": "SKG-Thessaloniki-Macedonia International-Makedonia Apt.-Sal-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "GLA",
            "countryCode": "GB",
            "cityCode": "GLA",
            "cityName": "Glasgow",
            "name": "[GLA] - Glasgow International - GLA - Glasgow - United Kingdom",
            "searchName": "GLA-Glasgow Intl-Glasgow International-Glasgow-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "KZN",
            "countryCode": "RU",
            "cityCode": "KZN",
            "cityName": "Kazan",
            "name": "[KZN] - Kazan - KZN - Kazan - Russia",
            "searchName": "KZN-Kazan-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "GOJ",
            "countryCode": "RU",
            "cityCode": "GOJ",
            "cityName": "Nizhniy Novgorod",
            "name": "[GOJ] - Nizhniy Novgorod - GOJ - Nizhniy Novgorod - Russia",
            "searchName": "GOJ-Nischni Nowgorod-Nizhniy Novgorod-Strigino Apt.-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "PEE",
            "countryCode": "RU",
            "cityCode": "PEE",
            "cityName": "Perm",
            "name": "[PEE] - Bolshoye Savino - PEE - Perm - Russia",
            "searchName": "PEE-Perm-Bolshoye Savino-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "CGN",
            "countryCode": "DE",
            "cityCode": "CGN",
            "cityName": "Cologne",
            "name": "[CGN] - Cologne/bonn - CGN - Cologne - Germany",
            "searchName": "CGN-Köln Bonn-Cologne/bonn-Köln/Bonn-Colonia-Koln\\/Bonn Apt.-K-Cologne-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "BEY",
            "countryCode": "LB",
            "cityCode": "BEY",
            "cityName": "Beirut",
            "name": "[BEY] - Beirut Rafic Hariri Airport - BEY - Beirut - Lebanon",
            "searchName": "BEY-Rafiq-Hariri-Flughafen-Beirut Rafic Hariri Airport-Beirut-Beyrouth-Liibanon-Libanon-Lübnan-Líban-Liban-Libanas-Libana-Lebanon-Libano-Líbano",
            "priority": 0
        },
        {
            "iataCode": "PHL",
            "countryCode": "US",
            "cityCode": "PHL",
            "cityName": "Philadelphia",
            "name": "[PHL] - Philadelphia International - PHL - Philadelphia - United States",
            "searchName": "PHL-Philadelphia Intl-Philadelphia International-Philadelphia-International-Filadelfia-Philadelphie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VOG",
            "countryCode": "RU",
            "cityCode": "VOG",
            "cityName": "Volgograd",
            "name": "[VOG] - Volgograd - VOG - Volgograd - Russia",
            "searchName": "VOG-Wolgograd-Volgograd-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "LYS",
            "countryCode": "FR",
            "cityCode": "LYS",
            "cityName": "Lyon",
            "name": "[LYS] - St-ExupÃ©ry - LYS - Lyon - France",
            "searchName": "LYS-Lyon-St-Exupéry-Saint Exupéry-Satolas-ExupÃ©ry-Lione-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "ETH",
            "countryCode": "IL",
            "cityCode": "ETH",
            "cityName": "Eilat",
            "name": "[ETH] - Eilat - ETH - Eilat - Israel",
            "searchName": "ETH-Elat-Eilat-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "ESB",
            "countryCode": "TR",
            "cityCode": "ANK",
            "cityName": "Ankara",
            "name": "[ESB] - Esenboga International - ANK - Ankara - Turkey",
            "searchName": "ESB-ANK-Ankara-Esenboga International-Esenboga-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "MNL",
            "countryCode": "PH",
            "cityCode": "MNL",
            "cityName": "Manila",
            "name": "[MNL] - Ninoy Aquino International - MNL - Manila - Philippines",
            "searchName": "MNL-Ninoy Aquino International-Ninoy Aquino Intl-Ninoy Aquino-Manila-Manille-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "IAD",
            "countryCode": "US",
            "cityCode": "WAS",
            "cityName": "Washington",
            "name": "[IAD] - Washington Dulles International - WAS - Washington - United States",
            "searchName": "IAD-WAS-Washington Dulles International-Dulles Intl-Dulles-Washington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CMB",
            "countryCode": "LK",
            "cityCode": "CMB",
            "cityName": "Colombo",
            "name": "[CMB] - Bandaranaike International - CMB - Colombo - Sri Lanka",
            "searchName": "CMB-Colombo Bandaranaike International-Bandaranaike International-Bandaranayake Intl-Bandaranaike-Colombo-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "MLE",
            "countryCode": "MV",
            "cityCode": "MLE",
            "cityName": "Male",
            "name": "[MLE] - MalÃ© International Airport - MLE - Male - Maldives",
            "searchName": "MLE-Male-Malé International Airport-MalÃ© International Airport-Mal-Maldives-Maldív-szigetek-Maldivler-Maledivy-Malediivit-Maldiverne-Maldive-Maldivi-Maldyvai-Maldivija-Maldiivid-Maldivene-Malediwy-Maladewa-Malediven-Maldivas",
            "priority": 0
        },
        {
            "iataCode": "MBJ",
            "countryCode": "JM",
            "cityCode": "MBJ",
            "cityName": "Montego Bay",
            "name": "[MBJ] - Sangster International - MBJ - Montego Bay - Jamaica",
            "searchName": "MBJ-Montego Bay-Sangster International-Sangster Intl-Jamaica-Jamaika-Jamajka-Jamaïque-Gia-Giamaica",
            "priority": 0
        },
        {
            "iataCode": "KEF",
            "countryCode": "IS",
            "cityCode": "REK",
            "cityName": "Reykjavik",
            "name": "[KEF] - Keflavik International - REK - Reykjavik - Iceland",
            "searchName": "KEF-REK-Reykjavik Keflavik Intl-Keflavik International-Keflavik Intl-Keflavik Int. Apt.-Reykjav-Reykjavik-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "ORK",
            "countryCode": "IE",
            "cityCode": "ORK",
            "cityName": "Cork",
            "name": "[ORK] - Cork - ORK - Cork - Ireland",
            "searchName": "ORK-Cork-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "BFS",
            "countryCode": "GB",
            "cityCode": "BFS",
            "cityName": "Belfast",
            "name": "[BFS] - Aldergrove International Airport - BFS - Belfast - United Kingdom",
            "searchName": "BFS-Belfast Intl-Aldergrove International Airport-Belfast Intl Arpt-Belfast-International-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ABZ",
            "countryCode": "GB",
            "cityCode": "ABZ",
            "cityName": "Aberdeen",
            "name": "[ABZ] - Dyce - ABZ - Aberdeen - United Kingdom",
            "searchName": "ABZ-Aberdeen-Dyce-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "NAS",
            "countryCode": "BS",
            "cityCode": "NAS",
            "cityName": "Nassau",
            "name": "[NAS] - Nassau International - NAS - Nassau - Bahamas",
            "searchName": "NAS-Nassau-Nassau International-Intl-International-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "DTW",
            "countryCode": "US",
            "cityCode": "DTT",
            "cityName": "Detroit",
            "name": "[DTW] - Detroit Metropolitan Wayne County - DTT - Detroit - United States",
            "searchName": "DTW-DTT-Detroit-Detroit Metropolitan Wayne County-Detroit Metro-Metro Wayne County Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLE",
            "countryCode": "US",
            "cityCode": "CLE",
            "cityName": "Cleveland",
            "name": "[CLE] - Hopkins International - CLE - Cleveland - United States",
            "searchName": "CLE-Cleveland-Hopkins International-Hopkins Intl-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BWI",
            "countryCode": "US",
            "cityCode": "BWI",
            "cityName": "Baltimore",
            "name": "[BWI] - Baltimore/Washington International Thurgood Marshall - BWI - Baltimore - United States",
            "searchName": "BWI-Baltimore-Baltimore/Washington International Thurgood Marshall-Baltimore/Washington-Baltimora-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CVG",
            "countryCode": "US",
            "cityCode": "CVG",
            "cityName": "Covington",
            "name": "[CVG] - Cincinnati/Northern Kentucky - CVG - Covington - United States",
            "searchName": "CVG-Cincinnati-Cincinnati/Northern Kentucky-Cinci./N. Kentucky-Covington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MDW",
            "countryCode": "US",
            "cityCode": "CHI",
            "cityName": "Chicago",
            "name": "[MDW] - Midway - CHI - Chicago - United States",
            "searchName": "MDW-CHI-Chicago Midway-Midway-Chicago-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLT",
            "countryCode": "US",
            "cityCode": "CLT",
            "cityName": "Charlotte",
            "name": "[CLT] - Charlotte Douglas - CLT - Charlotte - United States",
            "searchName": "CLT-Charlotte-Charlotte Douglas-Douglas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MKE",
            "countryCode": "US",
            "cityCode": "MKE",
            "cityName": "Milwaukee",
            "name": "[MKE] - General Mitchell International - MKE - Milwaukee - United States",
            "searchName": "MKE-Milwaukee-General Mitchell International-Mitchell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MEM",
            "countryCode": "US",
            "cityCode": "MEM",
            "cityName": "Memphis",
            "name": "[MEM] - Memphis International - MEM - Memphis - United States",
            "searchName": "MEM-Memphis-Memphis International-Menfis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RDU",
            "countryCode": "US",
            "cityCode": "RDU",
            "cityName": "Raleigh",
            "name": "[RDU] - Raleigh-durham International Airport - RDU - Raleigh - United States",
            "searchName": "RDU-Raleigh Durham-Raleigh-durham International Airport-Durham-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "STL",
            "countryCode": "US",
            "cityCode": "STL",
            "cityName": "St Louis",
            "name": "[STL] - Lambert-St. Louis International - STL - St Louis - United States",
            "searchName": "STL-St Louis-Lambert-St. Louis International-Saint Louis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TAS",
            "countryCode": "UZ",
            "cityCode": "TAS",
            "cityName": "Tashkent",
            "name": "[TAS] - Tashkent International - TAS - Tashkent - Uzbekistan",
            "searchName": "TAS-Taschkent-Tashkent International-Vostochny-Tashkent-Tachkent-Taskent-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "NCL",
            "countryCode": "GB",
            "cityCode": "NCL",
            "cityName": "Newcastle",
            "name": "[NCL] - Newcastle Airport - NCL - Newcastle - United Kingdom",
            "searchName": "NCL-Newcastle-Newcastle Airport-Newcastle Int.Apt-Newcastle Upon Tyne-upon-Tyne-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "PFO",
            "countryCode": "CY",
            "cityCode": "PFO",
            "cityName": "Paphos",
            "name": "[PFO] - International - PFO - Paphos - Cyprus",
            "searchName": "PFO-Paphos-International-Pafos-Pafo-Baf-Ciprus-Cypr-Siprus-Cyprus-Síp-Kipra-Kypros-Küpros-Cipar-Xipre-Kypr-Cypern-Chipre-Zypern-Chypre-Kibris-Cipru-Kipras-Cipro",
            "priority": 0
        },
        {
            "iataCode": "ARH",
            "countryCode": "RU",
            "cityCode": "ARH",
            "cityName": "Arkhangelsk",
            "name": "[ARH] - Arkhangelsk - ARH - Arkhangelsk - Russia",
            "searchName": "ARH-Archangelsk-Arkhangelsk-Talagi Apt-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "LWO",
            "countryCode": "UA",
            "cityCode": "LWO",
            "cityName": "Lviv",
            "name": "[LWO] - Snilow - LWO - Lviv - Ukraine",
            "searchName": "LWO-Lwiw-Snilow-Lviv Int. Apt.-Lemberg-Lviv-Leopoli-Lvov-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "MSY",
            "countryCode": "US",
            "cityCode": "MSY",
            "cityName": "New Orleans",
            "name": "[MSY] - Louis Armstrong New OrlÃ©ans International Airport - MSY - New Orleans - United States",
            "searchName": "MSY-New Orleans-Louis Armstrong New Orléans International Airport-Louis Armstrong-Louis Armstrong New OrlÃ©ans International Airport-New Orleans Louisiana-Nueva Orleans-Nouvelle-Orl-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AUH",
            "countryCode": "AE",
            "cityCode": "AUH",
            "cityName": "Abu Dhabi",
            "name": "[AUH] - Abu Dhabi International - AUH - Abu Dhabi - United Arab Emirates",
            "searchName": "AUH-Abu Dhabi Internationale-Abu Dhabi International-Abu Dhabi Intl-Abu Dhabi-Abou Dhabi-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 0
        },
        {
            "iataCode": "ADL",
            "countryCode": "AU",
            "cityCode": "ADL",
            "cityName": "Adelaide",
            "name": "[ADL] - Adelaide International Airport - ADL - Adelaide - Australia",
            "searchName": "ADL-Adelaide-Adelaide International Airport-Adelaida-Ad-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SOF",
            "countryCode": "BG",
            "cityCode": "SOF",
            "cityName": "Sofia",
            "name": "[SOF] - Sofia - SOF - Sofia - Bulgaria",
            "searchName": "SOF-Sofia-Sof-Bulgária-Bulgària-Bulgaristan-Bulharsko-Bulgaria-Bulgarien-Bugarska-Bulgaaria-Bulgarija-Bolgarija--Bulgarie",
            "priority": 0
        },
        {
            "iataCode": "VRN",
            "countryCode": "IT",
            "cityCode": "VRN",
            "cityName": "Verona",
            "name": "[VRN] - Valerio Catullo - VRN - Verona - Italy",
            "searchName": "VRN-Verona-Valerio Catullo-V-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "BTS",
            "countryCode": "SK",
            "cityCode": "BTS",
            "cityName": "Bratislava",
            "name": "[BTS] - M. R. Å\u00A0tefÃ¡nika - BTS - Bratislava - Slovakia",
            "searchName": "BTS-Bratislava-M. R. Štefánika-Ivanka-Ivanka Int. Apt-M. R. Å\u00A0tefÃ¡nika-Slowakia-Slowakei-Slovakia-Slovacka-Slovaquie-Slovensko-Slovakya-Eslovàquia-Szlovákia-Slovacia-Slovaška-Slovakija-Slovakkia-Slowacja-S-Eslovaquia-Slovacchia",
            "priority": 0
        },
        {
            "iataCode": "HAN",
            "countryCode": "VN",
            "cityCode": "HAN",
            "cityName": "Hanoi",
            "name": "[HAN] - Noibai International - HAN - Hanoi - Vietnam",
            "searchName": "HAN-Hanoi-Noibai International-Noibai-Hano-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "TJM",
            "countryCode": "RU",
            "cityCode": "TJM",
            "cityName": "Tyumen",
            "name": "[TJM] - Roschino - TJM - Tyumen - Russia",
            "searchName": "TJM-Tjumen-Roschino-Tyumen-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "CAG",
            "countryCode": "IT",
            "cityCode": "CAG",
            "cityName": "Cagliari",
            "name": "[CAG] - Elmas - CAG - Cagliari - Italy",
            "searchName": "CAG-Cagliari-Elmas-Elmas Apt-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "FRU",
            "countryCode": "KG",
            "cityCode": "FRU",
            "cityName": "Bishkek",
            "name": "[FRU] - Manas (Bishkek) - FRU - Bishkek - Kyrgyzstan",
            "searchName": "FRU-Manas Intl-Manas (Bishkek)-Bischkek-Bishkek-Bichkek-Kirgisistan-Kyrgyzstan-Kirgizistan-Kirghizistan-Kirguistán",
            "priority": 0
        },
        {
            "iataCode": "MEL",
            "countryCode": "AU",
            "cityCode": "MEL",
            "cityName": "Melbourne",
            "name": "[MEL] - Tullamarine - MEL - Melbourne - Australia",
            "searchName": "MEL-Melbourne-Tullamarine-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KIN",
            "countryCode": "JM",
            "cityCode": "KIN",
            "cityName": "Kingston",
            "name": "[KIN] - Norman Manley - KIN - Kingston - Jamaica",
            "searchName": "KIN-Kingston-Norman Manley-Jamaica-Jamaika-Jamajka-Jamaïque-Gia-Giamaica",
            "priority": 0
        },
        {
            "iataCode": "IKT",
            "countryCode": "RU",
            "cityCode": "IKT",
            "cityName": "Irkutsk",
            "name": "[IKT] - Irkutsk - IKT - Irkutsk - Russia",
            "searchName": "IKT-Irkutsk-Irkutsk Int. Apt-Irkoutsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "WLG",
            "countryCode": "NZ",
            "cityCode": "WLG",
            "cityName": "Wellington",
            "name": "[WLG] - Wellington International - WLG - Wellington - New Zealand",
            "searchName": "WLG-Wellington-Wellington International-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "KEJ",
            "countryCode": "RU",
            "cityCode": "KEJ",
            "cityName": "Kemerovo",
            "name": "[KEJ] - Kemerovo - KEJ - Kemerovo - Russia",
            "searchName": "KEJ-Kemerowo-Kemerovo-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "HKT",
            "countryCode": "TH",
            "cityCode": "HKT",
            "cityName": "Phuket",
            "name": "[HKT] - Phuket International - HKT - Phuket - Thailand",
            "searchName": "HKT-Phuket-Phuket International-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "SEZ",
            "countryCode": "SC",
            "cityCode": "SEZ",
            "cityName": "Mahe Island",
            "name": "[SEZ] - Seychelles International Airport - SEZ - Mahe Island - Escocia",
            "searchName": "SEZ-Mahe-Seychelles International Airport-Seychelles Intl-Mahe Insel-Mahe Island-Mahe Ile-Escocia",
            "priority": 0
        },
        {
            "iataCode": "MRV",
            "countryCode": "RU",
            "cityCode": "MRV",
            "cityName": "Mineralnye Vody",
            "name": "[MRV] - Mineralnye Vodyj - MRV - Mineralnye Vody - Russia",
            "searchName": "MRV-Mineralniye Vody-Mineralnye Vodyj-Mineralnye Vody-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "MRU",
            "countryCode": "MU",
            "cityCode": "MRU",
            "cityName": "Plaisance",
            "name": "[MRU] - Sir Seewoosagur Ramgoolam International - MRU - Plaisance - Mauritius",
            "searchName": "MRU-Mauritius Plaisance-Sir Seewoosagur Ramgoolam International-Sir Seewoosagur Ram.-Mauritius-Plaisance-Port Louis-Port-Louis-Mavricij-Mauricijus-Mauricija-Ile Maurice-Mauriti-Mauritius Adasi-Maurice-Mauricio",
            "priority": 0
        },
        {
            "iataCode": "CYS",
            "countryCode": "US",
            "cityCode": "CYS",
            "cityName": "Cheyenne",
            "name": "[CYS] - Cheyenne - CYS - Cheyenne - United States",
            "searchName": "CYS-Cheyenne-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ANC",
            "countryCode": "US",
            "cityCode": "ANC",
            "cityName": "Anchorage",
            "name": "[ANC] - Ted Stevens Anchorage International Airport - ANC - Anchorage - United States",
            "searchName": "ANC-Anchorage-Ted Stevens Anchorage International Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BRI",
            "countryCode": "IT",
            "cityCode": "BRI",
            "cityName": "Bari",
            "name": "[BRI] - Palese - BRI - Bari - Italy",
            "searchName": "BRI-Bari-Palese-Palese Macchie-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "IBZ",
            "countryCode": "ES",
            "cityCode": "IBZ",
            "cityName": "Ibiza",
            "name": "[IBZ] - Ibiza - IBZ - Ibiza - Spain",
            "searchName": "IBZ-Ibiza-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "ALC",
            "countryCode": "ES",
            "cityCode": "ALC",
            "cityName": "Alicante",
            "name": "[ALC] - Alicante El Altet - ALC - Alicante - Spain",
            "searchName": "ALC-Alicante-Alicante El Altet-El Altet Apt-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "GNB",
            "countryCode": "FR",
            "cityCode": "LYS",
            "cityName": "Lyon",
            "name": "[GNB] - Grenoble-isere Airport (St Geoirs) - LYS - Lyon - France",
            "searchName": "GNB-LYS-Grenoble-isere Airport (St Geoirs)-Grenoble St. Geoirs Apt-Lyon-Lione-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "BHX",
            "countryCode": "GB",
            "cityCode": "BHX",
            "cityName": "Birmingham",
            "name": "[BHX] - Birmingham International Airport - BHX - Birmingham - United Kingdom",
            "searchName": "BHX-Birmingham-Birmingham International Airport-Birmingham Int. Apt.-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "TBS",
            "countryCode": "GE",
            "cityCode": "TBS",
            "cityName": "Tbilisi",
            "name": "[TBS] - Novo Alexeyevka International - TBS - Tbilisi - Georgia",
            "searchName": "TBS-Tiflis Internationale-Novo Alexeyevka International-Novo Alexeyevka-Tiflis-Tbilisi-Tbilissi-Georgia-Georgien-Gruzie-Gürcistan-Geòrgia-Grúzia-Gruzija-Gruusia-Gruzja-Géo-Géorgie",
            "priority": 0
        },
        {
            "iataCode": "HER",
            "countryCode": "GR",
            "cityCode": "HER",
            "cityName": "Heraklion",
            "name": "[HER] - Nikos Kazantzakis Airport - HER - Heraklion - Greece",
            "searchName": "HER-Heraklion-Nikos Kazantzakis Airport-N. Kazantzakis Apt.-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "RMF",
            "countryCode": "EG",
            "cityCode": "RMF",
            "cityName": "Marsa Alam",
            "name": "[RMF] - Marsa Alam International - RMF - Marsa Alam - Egypt",
            "searchName": "RMF-Marsa Alam-Marsa Alam International-Marsa Alam International Airport-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "NBO",
            "countryCode": "KE",
            "cityCode": "NBO",
            "cityName": "Nairobi",
            "name": "[NBO] - Jomo Kenyatta International - NBO - Nairobi - Kenya",
            "searchName": "NBO-Jomo Kenyatta International-Jomo Kenyatta Intl-Jomo Kenyatta-Nairobi-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "ULN",
            "countryCode": "MN",
            "cityCode": "ULN",
            "cityName": "Ulaanbaatar",
            "name": "[ULN] - Chinggis Khaan International - ULN - Ulaanbaatar - Mongolia",
            "searchName": "ULN-Ulan Bator-Chinggis Khaan International-Buyant Uhaa-Ulaanbaatar-Ul-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "SKP",
            "countryCode": "MK",
            "cityCode": "SKP",
            "cityName": "Skopje",
            "name": "[SKP] - Alexander The Great Airport - SKP - Skopje - Macedonia",
            "searchName": "SKP-Skopje-Alexander The Great Airport-Skopie-Makedonia-Macedonia-Makedonya-Macedònia-Macedónia-Makedonija-Makedonien-Mazedonien-Macédoine--Fyrom-Repubblica ex Jugoslava di Macedonia",
            "priority": 0
        },
        {
            "iataCode": "OMO",
            "countryCode": "BA",
            "cityCode": "OMO",
            "cityName": "Mostar",
            "name": "[OMO] - Mostar - OMO - Mostar - Bosnia and Herzegovina",
            "searchName": "OMO-Mostar-Bosznia-Hercegovina-Bòsnia i Hercegovina-Bosna Hersek-Bosna a Hercegovina-Bosnia ja Hertsegovina-Bosnien-Bosnia-Bosnia si Hertegovin-Bosnien und Herzegovina-Bosnia and Herzegovina-Bosna-Hersek-Bosnie-Herzégovine-Herzegovina-Bosnia Erzegovina",
            "priority": 0
        },
        {
            "iataCode": "DEN",
            "countryCode": "US",
            "cityCode": "DEN",
            "cityName": "Denver",
            "name": "[DEN] - Denver International - DEN - Denver - United States",
            "searchName": "DEN-Denver International-International-Denver-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MJF",
            "countryCode": "NO",
            "cityCode": "MJF",
            "cityName": "Mosjoen",
            "name": "[MJF] - Kjaerstad - MJF - Mosjoen - Norway",
            "searchName": "MJF-Mosjøen-Kjaerstad-Mosjoen-Kjaerstad Apt-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "RTW",
            "countryCode": "RU",
            "cityCode": "RTW",
            "cityName": "Saratov",
            "name": "[RTW] - Saratov - RTW - Saratov - Russia",
            "searchName": "RTW-Saratow-Saratov-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "FNC",
            "countryCode": "PT",
            "cityCode": "FNC",
            "cityName": "Funchal",
            "name": "[FNC] - Madeira - FNC - Funchal - Portugal",
            "searchName": "FNC-Funchal-Madeira-Funchal Airport-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "PSG",
            "countryCode": "US",
            "cityCode": "PSG",
            "cityName": "Petersburg",
            "name": "[PSG] - Petersburg James A Johnson - PSG - Petersburg - United States",
            "searchName": "PSG-Petersburg-Petersburg James A Johnson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BNE",
            "countryCode": "AU",
            "cityCode": "BNE",
            "cityName": "Brisbane",
            "name": "[BNE] - Brisbane International - BNE - Brisbane - Australia",
            "searchName": "BNE-Brisbane-Brisbane International-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "YVR",
            "countryCode": "CA",
            "cityCode": "YVR",
            "cityName": "Vancouver",
            "name": "[YVR] - Vancouver International - YVR - Vancouver - Canada",
            "searchName": "YVR-Vancouver international -Vancouver International-Vancouver Intl-International-Vancouver-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "PWQ",
            "countryCode": "KZ",
            "cityCode": "PWQ",
            "cityName": "Pavlodar",
            "name": "[PWQ] - Pavlodar - PWQ - Pavlodar - Kazakhstan",
            "searchName": "PWQ-Pavlodar-Pawlodar-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "HFA",
            "countryCode": "IL",
            "cityCode": "HFA",
            "cityName": "Haifa",
            "name": "[HFA] - Haifa - HFA - Haifa - Israel",
            "searchName": "HFA-Haifa-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "OLB",
            "countryCode": "IT",
            "cityCode": "OLB",
            "cityName": "Olbia",
            "name": "[OLB] - Costa Smeralda - OLB - Olbia - Italy",
            "searchName": "OLB-Olbia-Costa Smeralda-Costa Smeralda Apt-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "IXL",
            "countryCode": "IN",
            "cityCode": "IXL",
            "cityName": "Leh",
            "name": "[IXL] - Bakula Rimpoche - IXL - Leh - India",
            "searchName": "IXL-Leh Indien-Bakula Rimpoche-Leh-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "KTT",
            "countryCode": "FI",
            "cityCode": "KTT",
            "cityName": "Kittila",
            "name": "[KTT] - Kittila - KTT - Kittila - Finland",
            "searchName": "KTT-Kittila-Kittil-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "IPC",
            "countryCode": "CL",
            "cityCode": "IPC",
            "cityName": "Easter Island",
            "name": "[IPC] - Mataveri International - IPC - Easter Island - Chile",
            "searchName": "IPC-Isla de Pascua-Mataveri International-Mataveri Intl-Mataveri International Airport-Easter Island--Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "SCU",
            "countryCode": "CU",
            "cityCode": "SCU",
            "cityName": "Santiago",
            "name": "[SCU] - Antonio Maceo - SCU - Santiago - Cuba",
            "searchName": "SCU-Antonio Maceo-Santiago-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "TLN",
            "countryCode": "FR",
            "cityCode": "TLN",
            "cityName": "Hyeres",
            "name": "[TLN] - Le Palyvestre - TLN - Hyeres - France",
            "searchName": "TLN-Toulon-Le Palyvestre-Hyeres-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "BOD",
            "countryCode": "FR",
            "cityCode": "BOD",
            "cityName": "Bordeaux",
            "name": "[BOD] - MÃ©rignac - BOD - Bordeaux - France",
            "searchName": "BOD-Bordeaux-Mérignac-Bordeaux Airport-MÃ©rignac-Burdeos-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "VRA",
            "countryCode": "CU",
            "cityCode": "VRA",
            "cityName": "Varadero",
            "name": "[VRA] - Juan Gualberto Gomez - VRA - Varadero - Cuba",
            "searchName": "VRA-Juan Gualberto Gomez-Varadero-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "TLL",
            "countryCode": "EE",
            "cityCode": "TLL",
            "cityName": "Tallinn",
            "name": "[TLL] - Ulemiste - TLL - Tallinn - Estonia",
            "searchName": "TLL-Tallinn-Ulemiste-Tallin-Estônia-Estland-Estonia-Estonie-Eesti-Estonya",
            "priority": 0
        },
        {
            "iataCode": "YXU",
            "countryCode": "CA",
            "cityCode": "YXU",
            "cityName": "London",
            "name": "[YXU] - London International - YXU - London - Canada",
            "searchName": "YXU-London Kanada-London International-London Airport-Municipal Airport-London-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "VAR",
            "countryCode": "BG",
            "cityCode": "VAR",
            "cityName": "Varna",
            "name": "[VAR] - Varna - VAR - Varna - Bulgaria",
            "searchName": "VAR-Varna-Warna-Bulgária-Bulgària-Bulgaristan-Bulharsko-Bulgaria-Bulgarien-Bugarska-Bulgaaria-Bulgarija-Bolgarija--Bulgarie",
            "priority": 0
        },
        {
            "iataCode": "BGI",
            "countryCode": "BB",
            "cityCode": "BGI",
            "cityName": "Bridgetown",
            "name": "[BGI] - Grantley Adams International - BGI - Bridgetown - Barbados",
            "searchName": "BGI-Barbados Barbados-Grantley Adams International-Grantley Adams Intl-Bridgetown-Barbados-Barbade",
            "priority": 0
        },
        {
            "iataCode": "INN",
            "countryCode": "AT",
            "cityCode": "INN",
            "cityName": "Innsbruck",
            "name": "[INN] - Innsbruck-kranebitten - INN - Innsbruck - Austria",
            "searchName": "INN-Innsbruck-kranebitten-Kranebitten-Kranebitten Apt-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "KJA",
            "countryCode": "RU",
            "cityCode": "KJA",
            "cityName": "Krasnojarsk",
            "name": "[KJA] - Krasnojarsk - KJA - Krasnojarsk - Russia",
            "searchName": "KJA-Krasnojarsk-Krasnofarsk Airport-Krasnoyarsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "DLM",
            "countryCode": "TR",
            "cityCode": "DLM",
            "cityName": "Dalaman",
            "name": "[DLM] - Dalaman - DLM - Dalaman - Turkey",
            "searchName": "DLM-Dalaman-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "MIR",
            "countryCode": "TN",
            "cityCode": "MIR",
            "cityName": "Monastir",
            "name": "[MIR] - Habib Bourguiba - MIR - Monastir - Tunisia",
            "searchName": "MIR-Monastir-Habib Bourguiba-Tunisia-Tunézia-Tunísia-Tunus-Tunisko-Tunesien-Tunizija-Tunisas-Tunisija-Tunis-Tuneesia-Tunezja-Tunisie-Túnez",
            "priority": 0
        },
        {
            "iataCode": "HRK",
            "countryCode": "UA",
            "cityCode": "HRK",
            "cityName": "Kharkov",
            "name": "[HRK] - Kharkov - HRK - Kharkov - Ukraine",
            "searchName": "HRK-Charkiw-Kharkov-Osnova Int. Apt-Jarkov-Kharkiv-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "ASF",
            "countryCode": "RU",
            "cityCode": "ASF",
            "cityName": "Astrakhan",
            "name": "[ASF] - Astrakhan - ASF - Astrakhan - Russia",
            "searchName": "ASF-Astrachan-Astrakhan-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "TOF",
            "countryCode": "RU",
            "cityCode": "TOF",
            "cityName": "Tomsk",
            "name": "[TOF] - Tomsk - TOF - Tomsk - Russia",
            "searchName": "TOF-Tomsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KXK",
            "countryCode": "RU",
            "cityCode": "KXK",
            "cityName": "Komsomolsk Na Amure",
            "name": "[KXK] - Komsomolsk Na Amure - KXK - Komsomolsk Na Amure - Russia",
            "searchName": "KXK-Komsomolsk Na Amure-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "RTM",
            "countryCode": "NL",
            "cityCode": "RTM",
            "cityName": "Rotterdam",
            "name": "[RTM] - Rotterdam Zestienhoven - RTM - Rotterdam - Netherlands",
            "searchName": "RTM-Rotterdam-Rotterdam Zestienhoven-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "ADK",
            "countryCode": "US",
            "cityCode": "ADK",
            "cityName": "Adak Island",
            "name": "[ADK] - Adak Island Ns - ADK - Adak Island - United States",
            "searchName": "ADK-Adak Island-Adak Island Ns-Adak Island NS--Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SEA",
            "countryCode": "US",
            "cityCode": "SEA",
            "cityName": "Seattle",
            "name": "[SEA] - Seattle-Tacoma International - SEA - Seattle - United States",
            "searchName": "SEA-Seattle-Tacoma International-Seattle/Tacoma Intl-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BTK",
            "countryCode": "RU",
            "cityCode": "BTK",
            "cityName": "Bratsk",
            "name": "[BTK] - Bratsk - BTK - Bratsk - Russia",
            "searchName": "BTK-Bratsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NJC",
            "countryCode": "RU",
            "cityCode": "NJC",
            "cityName": "Nizhnevartovsk",
            "name": "[NJC] - Nizhnevartovsk - NJC - Nizhnevartovsk - Russia",
            "searchName": "NJC-Nizhevartovsk-Nizhnevartovsk-Nischnewartowsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "UUD",
            "countryCode": "RU",
            "cityCode": "UUD",
            "cityName": "Ulan-ude",
            "name": "[UUD] - Ulan-ude, Baykal - UUD - Ulan-ude - Russia",
            "searchName": "UUD-Ulan-Ude-ude- Baykal-ude, Baykal-Ulan Ude-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "PRN",
            "countryCode": "RS",
            "cityCode": "PRN",
            "cityName": "Pristina",
            "name": "[PRN] - Pristina International - PRN - Pristina - Serbia",
            "searchName": "PRN-Pristina-Pristina International-Prishtina Int. Apt-Priština-Pri-Szerbia-Sèrbia-Sirbistan-Srbsko-Serbia-Serbien-Srbija-Serbija-Serbie--Suudi Arabistan",
            "priority": 0
        },
        {
            "iataCode": "AKL",
            "countryCode": "NZ",
            "cityCode": "AKL",
            "cityName": "Auckland",
            "name": "[AKL] - Auckland International - AKL - Auckland - New Zealand",
            "searchName": "AKL-Auckland-Auckland International-Auckland Intl-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "KTM",
            "countryCode": "NP",
            "cityCode": "KTM",
            "cityName": "Kathmandu",
            "name": "[KTM] - Tribhuvan International - KTM - Kathmandu - Nepal",
            "searchName": "KTM-Tribhuvan International-Tribhuvan-Tribuvan-Kathmandu-Katmand-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "VSG",
            "countryCode": "UA",
            "cityCode": "VSG",
            "cityName": "Lugansk",
            "name": "[VSG] - Lugansk - VSG - Lugansk - Ukraine",
            "searchName": "VSG-Luhansk-Lugansk-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "USM",
            "countryCode": "TH",
            "cityCode": "USM",
            "cityName": "Koh Samui",
            "name": "[USM] - Koh Samui - USM - Koh Samui - Thailand",
            "searchName": "USM-Samui International -Koh Samui-Ko Samui-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "EBB",
            "countryCode": "UG",
            "cityCode": "EBB",
            "cityName": "Entebbe",
            "name": "[EBB] - Entebbe - EBB - Entebbe - Uganda",
            "searchName": "EBB-Entebbe-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "SZG",
            "countryCode": "AT",
            "cityCode": "SZG",
            "cityName": "Salzburg",
            "name": "[SZG] - W. A. Mozart - SZG - Salzburg - Austria",
            "searchName": "SZG-Salzburg-W. A. Mozart-W.A. Mozart-Salzburg  Apt-Salzbourg-Salisburgo-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "TIA",
            "countryCode": "AL",
            "cityCode": "TIA",
            "cityName": "Tirana",
            "name": "[TIA] - Rinas Mother Teresa - TIA - Tirana - Albania",
            "searchName": "TIA-Tirana-Rinas Mother Teresa-Rinas-Tirana Int. Apt-Albânia-Albania-Albanië-Albanien-An ba ni-Albanija-Albaania-Albanie",
            "priority": 0
        },
        {
            "iataCode": "BOJ",
            "countryCode": "BG",
            "cityCode": "BOJ",
            "cityName": "Bourgas",
            "name": "[BOJ] - Bourgas - BOJ - Bourgas - Bulgaria",
            "searchName": "BOJ-Burgas-Bourgas-Bulgária-Bulgària-Bulgaristan-Bulharsko-Bulgaria-Bulgarien-Bugarska-Bulgaaria-Bulgarija-Bolgarija--Bulgarie",
            "priority": 0
        },
        {
            "iataCode": "LXA",
            "countryCode": "CN",
            "cityCode": "LXA",
            "cityName": "Gonggar",
            "name": "[LXA] - Gonggar/Lhasa - LXA - Gonggar - China",
            "searchName": "LXA-Lhasa-Gonggar/Lhasa-Gonggar-Lhassa-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TFN",
            "countryCode": "ES",
            "cityCode": "TCI",
            "cityName": "Tenerife",
            "name": "[TFN] - Norte Los Rodeos - TCI - Tenerife - Spain",
            "searchName": "TFN-TCI-Teneriffa Nord-Norte Los Rodeos-Los Rodeos-Teneriffa-Tenerife-Teneriffe-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "SVQ",
            "countryCode": "ES",
            "cityCode": "SVQ",
            "cityName": "Sevilla",
            "name": "[SVQ] - San Pablo - SVQ - Sevilla - Spain",
            "searchName": "SVQ-Sevilla-San Pablo-San Pablo Apt-S-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "SID",
            "countryCode": "CV",
            "cityCode": "SID",
            "cityName": "Sal",
            "name": "[SID] - Amilcar Cabral International - SID - Sal - Cape Verde",
            "searchName": "SID-Sal Kapverdische Inseln-Amilcar Cabral International-Amilcar Cabral Intl-Amilcar Cabral Int. Airport-Sal-Sal Island-Kap Verde-Cape Verde-Yesil Burun Adalari-Cap-Vert-Cabo Verde-Capo Verde",
            "priority": 0
        },
        {
            "iataCode": "PUJ",
            "countryCode": "DO",
            "cityCode": "PUJ",
            "cityName": "Punta Cana",
            "name": "[PUJ] - Punta Cana International - PUJ - Punta Cana - Dominican Republic",
            "searchName": "PUJ-Punta Cana-Punta Cana International-Higuey-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "AAR",
            "countryCode": "DK",
            "cityCode": "AAR",
            "cityName": "Aarhus",
            "name": "[AAR] - Aarhus Airport - AAR - Aarhus - Denmark",
            "searchName": "AAR-Aarhus-Aarhus Airport-Tirstrup-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "HAJ",
            "countryCode": "DE",
            "cityCode": "HAJ",
            "cityName": "Hanover",
            "name": "[HAJ] - Hanover Airport - HAJ - Hanover - Germany",
            "searchName": "HAJ-Hannover-Hanover Airport-Hannover Airport-Hanover Int. Apt-Hanover-Han-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "RNS",
            "countryCode": "FR",
            "cityCode": "RNS",
            "cityName": "Rennes",
            "name": "[RNS] - St Jacques - RNS - Rennes - France",
            "searchName": "RNS-Rennes-St Jacques-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "NTE",
            "countryCode": "FR",
            "cityCode": "NTE",
            "cityName": "Nantes",
            "name": "[NTE] - Nantes Atlantique - NTE - Nantes - France",
            "searchName": "NTE-Nantes-Nantes Atlantique-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "SPU",
            "countryCode": "HR",
            "cityCode": "SPU",
            "cityName": "Split",
            "name": "[SPU] - Split - SPU - Split - Croatia",
            "searchName": "SPU-Split-Spalato-Kroatië-Kroatia-Chorvatsko-Hirvatistan-Croàcia-Horvátország-Croatia-Hrvaška-Kroatija-Horvatija-Hrvatska-Horvaatia-Chorwacja-Kroasia-Kroatien-Croatie-Croacia-Croazia",
            "priority": 0
        },
        {
            "iataCode": "DBV",
            "countryCode": "HR",
            "cityCode": "DBV",
            "cityName": "Dubrovnik",
            "name": "[DBV] - Dubrovnik - DBV - Dubrovnik - Croatia",
            "searchName": "DBV-Dubrovnik-Ragusa-Kroatië-Kroatia-Chorvatsko-Hirvatistan-Croàcia-Horvátország-Croatia-Hrvaška-Kroatija-Horvatija-Hrvatska-Horvaatia-Chorwacja-Kroasia-Kroatien-Croatie-Croacia-Croazia",
            "priority": 0
        },
        {
            "iataCode": "KGF",
            "countryCode": "KZ",
            "cityCode": "KGF",
            "cityName": "Karaganda",
            "name": "[KGF] - Karaganda - KGF - Karaganda - Kazakhstan",
            "searchName": "KGF-Waraghandy-Karaganda-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "MCM",
            "countryCode": "MC",
            "cityCode": "MCM",
            "cityName": "Monte Carlo",
            "name": "[MCM] - Fontvieille Heliport - MCM - Monte Carlo - Monaco",
            "searchName": "MCM-Fontvieille Heliport-Monte Carlo-Monte-Carlo-Monaco-Mónaco-Mô na cô-Monako-Principato di Monaco-Monakas-M",
            "priority": 0
        },
        {
            "iataCode": "PUY",
            "countryCode": "HR",
            "cityCode": "PUY",
            "cityName": "Pula",
            "name": "[PUY] - Pula - PUY - Pula - Croatia",
            "searchName": "PUY-Pula-Pola-Kroatië-Kroatia-Chorvatsko-Hirvatistan-Croàcia-Horvátország-Croatia-Hrvaška-Kroatija-Horvatija-Hrvatska-Horvaatia-Chorwacja-Kroasia-Kroatien-Croatie-Croacia-Croazia",
            "priority": 0
        },
        {
            "iataCode": "RMI",
            "countryCode": "IT",
            "cityCode": "RMI",
            "cityName": "Rimini",
            "name": "[RMI] - Miramare - RMI - Rimini - Italy",
            "searchName": "RMI-Rimini-Miramare-Aeroporto Miramare-Federico Fellin Apt-R-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "EGS",
            "countryCode": "IS",
            "cityCode": "EGS",
            "cityName": "Egilsstadir",
            "name": "[EGS] - Egilsstadir - EGS - Egilsstadir - Iceland",
            "searchName": "EGS-Egilsstadir-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "JKH",
            "countryCode": "GR",
            "cityCode": "JKH",
            "cityName": "Chios",
            "name": "[JKH] - Chios - JKH - Chios - Greece",
            "searchName": "JKH-Chios-Chios Airport-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "STR",
            "countryCode": "DE",
            "cityCode": "STR",
            "cityName": "Stuttgart",
            "name": "[STR] - Stuttgart Echterdingen - STR - Stuttgart - Germany",
            "searchName": "STR-Stuttgart-Stuttgart Echterdingen-Echterdingen-Stoccarda-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "CPO",
            "countryCode": "CL",
            "cityCode": "CPO",
            "cityName": "Copiapo",
            "name": "[CPO] - Chamonate - CPO - Copiapo - Chile",
            "searchName": "CPO-Copiapo-Chamonate-Copiap-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "BRN",
            "countryCode": "CH",
            "cityCode": "BRN",
            "cityName": "Berne",
            "name": "[BRN] - Belp - BRN - Berne - Switzerland",
            "searchName": "BRN-Bern-Belp-Belp Airport-Berne-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ABJ",
            "countryCode": "CI",
            "cityCode": "ABJ",
            "cityName": "Abidjan",
            "name": "[ABJ] - Felix Houphouet Boigny - ABJ - Abidjan - Ivory Coast",
            "searchName": "ABJ-Abidjan-Felix Houphouet Boigny-Felix H. Boigny-Abiy-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "EVN",
            "countryCode": "AM",
            "cityCode": "EVN",
            "cityName": "Yerevan",
            "name": "[EVN] - Zvartnots International - EVN - Yerevan - Armenia",
            "searchName": "EVN-Internationale Flughafen Swartnoz-Zvartnots International-Yerevan-Eriwan-Erev-Örményország-Armènia-Ermenistan-Arménie-Armenia-Armenija-Armeenia--Armenien",
            "priority": 0
        },
        {
            "iataCode": "DAC",
            "countryCode": "BD",
            "cityCode": "DAC",
            "cityName": "Dhaka",
            "name": "[DAC] - Zia International - DAC - Dhaka - Bangladesh",
            "searchName": "DAC-Dhaka-Zia International-Dacca-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "ASP",
            "countryCode": "AU",
            "cityCode": "ASP",
            "cityName": "Alice Springs",
            "name": "[ASP] - Alice Springs - ASP - Alice Springs - Australia",
            "searchName": "ASP-Alice Springs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MKC",
            "countryCode": "US",
            "cityCode": "MKC",
            "cityName": "Kansas City",
            "name": "[MKC] - Downtown - MKC - Kansas City - United States",
            "searchName": "MKC-Kommunaler Flughafen Downtown-Downtown-Kansas City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MSN",
            "countryCode": "US",
            "cityCode": "MSN",
            "cityName": "Madison",
            "name": "[MSN] - Dane County Regional - MSN - Madison - United States",
            "searchName": "MSN-Madison-Dane County Regional-Truax Field-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MSP",
            "countryCode": "US",
            "cityCode": "MSP",
            "cityName": "Minneapolis",
            "name": "[MSP] - Minneapolis - St. Paul International - MSP - Minneapolis - United States",
            "searchName": "MSP-Minneapolis-Minneapolis - St. Paul International-Minneapolis St Paul-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KHV",
            "countryCode": "RU",
            "cityCode": "KHV",
            "cityName": "Khabarovsk",
            "name": "[KHV] - Novyy - KHV - Khabarovsk - Russia",
            "searchName": "KHV-Chabarowsk-Novyy-Novyy Apt.-Khabarovsk-Jab-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "HTA",
            "countryCode": "RU",
            "cityCode": "HTA",
            "cityName": "Chita",
            "name": "[HTA] - Chita - HTA - Chita - Russia",
            "searchName": "HTA-Tschita-Chita-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "HMO",
            "countryCode": "MX",
            "cityCode": "HMO",
            "cityName": "Hermosillo",
            "name": "[HMO] - Gen Pesqueira Garcia - HMO - Hermosillo - Mexico",
            "searchName": "HMO-Hermosillo-Gen Pesqueira Garcia-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "ASE",
            "countryCode": "US",
            "cityCode": "ASE",
            "cityName": "Aspen",
            "name": "[ASE] - Aspen - ASE - Aspen - United States",
            "searchName": "ASE-Aspen-Pitkin County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DHN",
            "countryCode": "US",
            "cityCode": "DHN",
            "cityName": "Dothan",
            "name": "[DHN] - Dothan Airport - DHN - Dothan - United States",
            "searchName": "DHN-Dothan-Dothan Airport-Dothan Arpt-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ITO",
            "countryCode": "US",
            "cityCode": "ITO",
            "cityName": "Hilo",
            "name": "[ITO] - Hilo International - ITO - Hilo - United States",
            "searchName": "ITO-Hilo-Hilo International-Hilo Hawaii International-Hawaii (Big Island)-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "APW",
            "countryCode": "WS",
            "cityCode": "APW",
            "cityName": "Apia",
            "name": "[APW] - Faleolo - APW - Apia - Samoa",
            "searchName": "APW-Apia-Faleolo-Szamoa-Samoa-Bati Samoa",
            "priority": 0
        },
        {
            "iataCode": "CPT",
            "countryCode": "ZA",
            "cityCode": "CPT",
            "cityName": "Cape Town",
            "name": "[CPT] - Cape Town International - CPT - Cape Town - South Africa",
            "searchName": "CPT-Cape Town International-Cape Town Intl-Kapstadt-Cape Town-Ciudad del Cabo-Le Cap-Citt-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "PRI",
            "countryCode": "SC",
            "cityCode": "PRI",
            "cityName": "Praslin Island",
            "name": "[PRI] - Praslin Island - PRI - Praslin Island - Escocia",
            "searchName": "PRI-Praslin Island-Praslin Adasi Havalimani--Escocia",
            "priority": 0
        },
        {
            "iataCode": "PTG",
            "countryCode": "ZA",
            "cityCode": "PTG",
            "cityName": "Polokwane",
            "name": "[PTG] - Polokwane - PTG - Polokwane - South Africa",
            "searchName": "PTG-Pietersburg-Polokwane-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "MGA",
            "countryCode": "NI",
            "cityCode": "MGA",
            "cityName": "Managua",
            "name": "[MGA] - Augusto C Sandino - MGA - Managua - Nicaragua",
            "searchName": "MGA-Managua-Augusto C Sandino-Nicaragua-Nikaragua-Nikaragva",
            "priority": 0
        },
        {
            "iataCode": "AAQ",
            "countryCode": "RU",
            "cityCode": "AAQ",
            "cityName": "Anapa",
            "name": "[AAQ] - Anapa - AAQ - Anapa - Russia",
            "searchName": "AAQ-Anapa-Anapa Havalimani-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "RUH",
            "countryCode": "SA",
            "cityCode": "RUH",
            "cityName": "Riyadh",
            "name": "[RUH] - King Khaled International - RUH - Riyadh - Saudi Arabia",
            "searchName": "RUH-Riyadh-King Khaled International-King Khaled Intl-Riad-Riyad-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "MQF",
            "countryCode": "RU",
            "cityCode": "MQF",
            "cityName": "Magnitogorsk",
            "name": "[MQF] - Magnitogorsk - MQF - Magnitogorsk - Russia",
            "searchName": "MQF-Magnitogorsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "DOH",
            "countryCode": "QA",
            "cityCode": "DOH",
            "cityName": "Doha",
            "name": "[DOH] - Doha International - DOH - Doha - Qatar",
            "searchName": "DOH-Doha International-Doha-Katar-Qatar-Kataras-Katara-Quatar",
            "priority": 0
        },
        {
            "iataCode": "KHI",
            "countryCode": "PK",
            "cityCode": "KHI",
            "cityName": "Karachi",
            "name": "[KHI] - Quaid-e-azam International - KHI - Karachi - Pakistan",
            "searchName": "KHI-Karachi-Quaid-e-azam International-E-Azam Intl-Azam International-Karatschi-Kar-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "KRK",
            "countryCode": "PL",
            "cityCode": "KRK",
            "cityName": "Krakow",
            "name": "[KRK] - John Paul II Balice International - KRK - Krakow - Poland",
            "searchName": "KRK-Krakau-John Paul II Balice International-J. Paul II Balice-Balice-Cracow-Krakow-Cracovia-Cracovie-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "ISB",
            "countryCode": "PK",
            "cityCode": "ISB",
            "cityName": "Islamabad",
            "name": "[ISB] - Islamabad International - ISB - Islamabad - Pakistan",
            "searchName": "ISB-Islamabad-Islamabad International-Islamabad Intl-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "LHE",
            "countryCode": "PK",
            "cityCode": "LHE",
            "cityName": "Lahore",
            "name": "[LHE] - Alama Iqbal International - LHE - Lahore - Pakistan",
            "searchName": "LHE-Lahore-Alama Iqbal International-L-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "MCT",
            "countryCode": "OM",
            "cityCode": "MCT",
            "cityName": "Muscat",
            "name": "[MCT] - Seeb - MCT - Muscat - Oman",
            "searchName": "MCT-Muscat-Seeb-Maskat-Mascate-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "ZQN",
            "countryCode": "NZ",
            "cityCode": "ZQN",
            "cityName": "Queenstown",
            "name": "[ZQN] - Queenstown - ZQN - Queenstown - New Zealand",
            "searchName": "ZQN-Queenstown Neuseeland-Queenstown-Queenstown Intl-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "BOB",
            "countryCode": "PF",
            "cityCode": "BOB",
            "cityName": "Bora Bora",
            "name": "[BOB] - Motu Mute - BOB - Bora Bora - French Polynesia",
            "searchName": "BOB-Bora Bora-Motu Mute-Motu-mute-Vaitape-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "PIT",
            "countryCode": "US",
            "cityCode": "PIT",
            "cityName": "Pittsburgh",
            "name": "[PIT] - Pittsburgh International - PIT - Pittsburgh - United States",
            "searchName": "PIT-Pittsburgh-Pittsburgh International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ASB",
            "countryCode": "TM",
            "cityCode": "ASB",
            "cityName": "Ashgabat",
            "name": "[ASB] - Ashgabat - ASB - Ashgabat - Turkmenistan",
            "searchName": "ASB-Ashgabat-A-Turkmenistan-Turkménistan-Turkmenistán",
            "priority": 0
        },
        {
            "iataCode": "BJV",
            "countryCode": "TR",
            "cityCode": "BJV",
            "cityName": "Milas",
            "name": "[BJV] - Milas - BJV - Milas - Turkey",
            "searchName": "BJV-Bodrum-Milas-Milas Airport-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "DNK",
            "countryCode": "UA",
            "cityCode": "DNK",
            "cityName": "Dnepropetrovsk",
            "name": "[DNK] - Dnepropetrovsk - DNK - Dnepropetrovsk - Ukraine",
            "searchName": "DNK-Dnipropetrowsk-Dnepropetrovsk-Dnipropetrovsk Int. Apt-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "SFT",
            "countryCode": "SE",
            "cityCode": "SFT",
            "cityName": "Skelleftea",
            "name": "[SFT] - Skelleftea - SFT - Skelleftea - Sweden",
            "searchName": "SFT-Skelleftea-Skelleftea Airport-Skellefte-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "DMM",
            "countryCode": "SA",
            "cityCode": "DMM",
            "cityName": "Dammam",
            "name": "[DMM] - King Fahd International Airport - DMM - Dammam - Saudi Arabia",
            "searchName": "DMM-Dammam-King Fahd International Airport-King Fahad Intl-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "DAM",
            "countryCode": "SY",
            "cityCode": "DAM",
            "cityName": "Damascus",
            "name": "[DAM] - International - DAM - Damascus - Syria",
            "searchName": "DAM-Damaskus-International-Damascus-Syrien-Syria-Syrie-Siria",
            "priority": 0
        },
        {
            "iataCode": "ARM",
            "countryCode": "AU",
            "cityCode": "ARM",
            "cityName": "Armidale",
            "name": "[ARM] - Armidale - ARM - Armidale - Australia",
            "searchName": "ARM-Armidale-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MOV",
            "countryCode": "AU",
            "cityCode": "MOV",
            "cityName": "Moranbah",
            "name": "[MOV] - Moranbah - MOV - Moranbah - Australia",
            "searchName": "MOV-Moranbah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SBH",
            "countryCode": "BL",
            "cityCode": "SBH",
            "cityName": "Gustavia",
            "name": "[SBH] - Gustaf III (St-Jean) - SBH - Gustavia - Saint Barthelemy",
            "searchName": "SBH-Saint Barthelemy-Gustaf III (St-Jean)-St Barthelemy-Saint Jean-Gustavia-St. Barthelemy-Saint Barthélemy-BLM-Guadeloupe-San Bartolomé-Saint-Barthélémy-San Bartolomeo",
            "priority": 0
        },
        {
            "iataCode": "FLN",
            "countryCode": "BR",
            "cityCode": "FLN",
            "cityName": "Florianopolis",
            "name": "[FLN] - Hercilio Luz - FLN - Florianopolis - Brazil",
            "searchName": "FLN-Florianopolis-Hercilio Luz-Florian-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AUA",
            "countryCode": "AN",
            "cityCode": "AUA",
            "cityName": "Aruba",
            "name": "[AUA] - Reina Beatrix - AUA - Aruba - Aruba",
            "searchName": "AUA-Aruba Reina Beatrix-Reina Beatrix-Aruba-Oranjestad-ABW",
            "priority": 0
        },
        {
            "iataCode": "LUG",
            "countryCode": "CH",
            "cityCode": "LUG",
            "cityName": "Lugano",
            "name": "[LUG] - Lugano - LUG - Lugano - Switzerland",
            "searchName": "LUG-Lugano-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "CFG",
            "countryCode": "CU",
            "cityCode": "CFG",
            "cityName": "Cienfuegos",
            "name": "[CFG] - Cienfuegos - CFG - Cienfuegos - Cuba",
            "searchName": "CFG-Cienfuegos-Cienfuegos Airport-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "ECN",
            "countryCode": "CY",
            "cityCode": "ECN",
            "cityName": "Lefkosa",
            "name": "[ECN] - Ercan - ECN - Lefkosa - Cyprus",
            "searchName": "ECN-Ercan-Ercan International Airport-Lefkosa-Ciprus-Cypr-Siprus-Cyprus-Síp-Kipra-Kypros-Küpros-Cipar-Xipre-Kypr-Cypern-Chipre-Zypern-Chypre-Kibris-Cipru-Kipras-Cipro",
            "priority": 0
        },
        {
            "iataCode": "DTM",
            "countryCode": "DE",
            "cityCode": "DTM",
            "cityName": "Dortmund",
            "name": "[DTM] - Dortmund - DTM - Dortmund - Germany",
            "searchName": "DTM-Dortmund-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "DOM",
            "countryCode": "DM",
            "cityCode": "DOM",
            "cityName": "Dominica",
            "name": "[DOM] - Melville Hall - DOM - Dominica - Dominica",
            "searchName": "DOM-Dominica Melville Hall-Melville Hall-Dominica-Marigot-Dominika-Dominique",
            "priority": 0
        },
        {
            "iataCode": "LPA",
            "countryCode": "ES",
            "cityCode": "LPA",
            "cityName": "Las Palmas",
            "name": "[LPA] - Gran Canaria - LPA - Las Palmas - Spain",
            "searchName": "LPA-Las Palmas-Gran Canaria-Gran Canaria Airport-Gran Canaria Int. Apt-Palmas de Gran Canaria-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "LXR",
            "countryCode": "EG",
            "cityCode": "LXR",
            "cityName": "Luxor",
            "name": "[LXR] - Luxor - LXR - Luxor - Egypt",
            "searchName": "LXR-Luxor-L-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "CUE",
            "countryCode": "EC",
            "cityCode": "CUE",
            "cityName": "Cuenca",
            "name": "[CUE] - Cuenca - CUE - Cuenca - Ecuador",
            "searchName": "CUE-Cuenca-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "STI",
            "countryCode": "DO",
            "cityCode": "STI",
            "cityName": "Santiago",
            "name": "[STI] - Cibao International Airport - STI - Santiago - Dominican Republic",
            "searchName": "STI-Santiago Dom. Rep.-Cibao International Airport-Santiago-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "CHQ",
            "countryCode": "GR",
            "cityCode": "CHQ",
            "cityName": "Chania",
            "name": "[CHQ] - Souda - CHQ - Chania - Greece",
            "searchName": "CHQ-Chania-Souda-Souda Airport-La Canea-La Can-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "CFU",
            "countryCode": "GR",
            "cityCode": "CFU",
            "cityName": "Kerkyra",
            "name": "[CFU] - Ioannis Kapodistrias - CFU - Kerkyra - Greece",
            "searchName": "CFU-Korfu-Ioannis Kapodistrias-I. Kapodistrias-Corfu-Kerkyra-Corf-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "CGK",
            "countryCode": "ID",
            "cityCode": "JKT",
            "cityName": "Jakarta",
            "name": "[CGK] - Soekarno-Hatta International - JKT - Jakarta - Indonesia",
            "searchName": "CGK-JKT-Jakarta Soekarno-Hatta-Soekarno-Hatta International-Hatta Intl-Soekarno–Hatta International Airport-Jakarta-Yakarta-Giakarta-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "HOG",
            "countryCode": "CU",
            "cityCode": "HOG",
            "cityName": "Holguin",
            "name": "[HOG] - Frank Pais - HOG - Holguin - Cuba",
            "searchName": "HOG-Frank Pais-Holguín-Holguin-Holgu-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "BUS",
            "countryCode": "GE",
            "cityCode": "BUS",
            "cityName": "Batumi",
            "name": "[BUS] - Batum - BUS - Batumi - Georgia",
            "searchName": "BUS-Batumi-Batum-Georgia-Georgien-Gruzie-Gürcistan-Geòrgia-Grúzia-Gruzija-Gruusia-Gruzja-Géo-Géorgie",
            "priority": 0
        },
        {
            "iataCode": "LEI",
            "countryCode": "ES",
            "cityCode": "LEI",
            "cityName": "Almeria",
            "name": "[LEI] - Almeria - LEI - Almeria - Spain",
            "searchName": "LEI-Almeria-Almeria Havalimani-Almer-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "LEH",
            "countryCode": "FR",
            "cityCode": "LEH",
            "cityName": "Le Havre",
            "name": "[LEH] - Octeville - LEH - Le Havre - France",
            "searchName": "LEH-Le Havre-Octeville-Octeville Apt.-El Havre-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "HUY",
            "countryCode": "GB",
            "cityCode": "HUY",
            "cityName": "Humberside",
            "name": "[HUY] - Humberside International - HUY - Humberside - United Kingdom",
            "searchName": "HUY-Humberside-Humberside International-Humberside Airport-Barnetby-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ZEP",
            "countryCode": "GB",
            "cityCode": "LON",
            "cityName": "Londres",
            "name": "[ZEP] - Victoria Railway - LON - Londres - United Kingdom",
            "searchName": "ZEP-LON-Victoria Railway-London-Londres-Londra-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "CTA",
            "countryCode": "IT",
            "cityCode": "CTA",
            "cityName": "Catania",
            "name": "[CTA] - Fontanarossa - CTA - Catania - Italy",
            "searchName": "CTA-Catania-Fontanarossa-Aeroporto Fontanarossa-Fontanarossa Apt-Catane-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "BLR",
            "countryCode": "IN",
            "cityCode": "BLR",
            "cityName": "Bangalore",
            "name": "[BLR] - Bangalore International Airport - BLR - Bangalore - India",
            "searchName": "BLR-Bangalore-Bangalore International Airport-Bengaluru Intl-Bengaluru-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "AMM",
            "countryCode": "JO",
            "cityCode": "AMM",
            "cityName": "Amman",
            "name": "[AMM] - Queen Alia International - AMM - Amman - Jordan",
            "searchName": "AMM-Queen Alia International-Queen Alia Intl-Queen Alia-Amman-Amm-Jordánia-Jordània-Ürdün-Jordan-Jordánsko-Jordania-Jordaania-Jordanija-Iordania-Yordania-Jordanien-Jordânia-Jordani-Jordanie-Giordania",
            "priority": 0
        },
        {
            "iataCode": "NAP",
            "countryCode": "IT",
            "cityCode": "NAP",
            "cityName": "Napoli",
            "name": "[NAP] - Capodichino - NAP - Napoli - Italy",
            "searchName": "NAP-Neapel-Capodichino-Naples-Aeroporto internazionale di Napoli-Capodichino Apt.-Napoli-N-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "PSA",
            "countryCode": "IT",
            "cityCode": "PSA",
            "cityName": "Pisa",
            "name": "[PSA] - Galileo Galilei - PSA - Pisa - Italy",
            "searchName": "PSA-Pisa Florenz-Galileo Galilei-Gal Galilei-Pisa-Pise-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "JMK",
            "countryCode": "GR",
            "cityCode": "JMK",
            "cityName": "Mikonos",
            "name": "[JMK] - Mikonos - JMK - Mikonos - Greece",
            "searchName": "JMK-Mykonos-Mikonos-Mykonos Airport-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "ZTH",
            "countryCode": "GR",
            "cityCode": "ZTH",
            "cityName": "Zakinthos",
            "name": "[ZTH] - Zakinthos International Airport - ZTH - Zakinthos - Greece",
            "searchName": "ZTH-Zakynthos-Zakinthos International Airport-Zakinthos Is-Zakinthos-Zakinthos Island-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "TRV",
            "countryCode": "IN",
            "cityCode": "TRV",
            "cityName": "Thiruvananthapuram",
            "name": "[TRV] - Thiruvananthapuram International - TRV - Thiruvananthapuram - India",
            "searchName": "TRV-Trivandrum-Thiruvananthapuram International-Thiruvananthapuram-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "KUN",
            "countryCode": "LT",
            "cityCode": "KUN",
            "cityName": "Kaunas",
            "name": "[KUN] - Kaunas - KUN - Kaunas - Lithuania",
            "searchName": "KUN-Kaunas-Lituânia-Litouwen-Lithuania-Litauen-Lituanie-Lituania-Litwa-Leedu-Li-Litvanya",
            "priority": 0
        },
        {
            "iataCode": "KWI",
            "countryCode": "KW",
            "cityCode": "KWI",
            "cityName": "Kuwait City",
            "name": "[KWI] - Kuwait International - KWI - Kuwait City - Kuwait",
            "searchName": "KWI-Kuwait Intl-Kuwait International-Kuwait-Kuwait City-Kuweit-Kuveyt-Koeweit-Koweït-Kuvajt-Kuveitas",
            "priority": 0
        },
        {
            "iataCode": "MBA",
            "countryCode": "KE",
            "cityCode": "MBA",
            "cityName": "Mombasa",
            "name": "[MBA] - Moi International - MBA - Mombasa - Kenya",
            "searchName": "MBA-Mombasa-Moi International-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "KBR",
            "countryCode": "MY",
            "cityCode": "KBR",
            "cityName": "Kota Bharu",
            "name": "[KBR] - Sultan Ismail Petra - KBR - Kota Bharu - Malaysia",
            "searchName": "KBR-Kota Bharu-Sultan Ismail Petra-Pengkalan Chepa-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "MSU",
            "countryCode": "LS",
            "cityCode": "MSU",
            "cityName": "Maseru",
            "name": "[MSU] - Moshoeshoe International - MSU - Maseru - Lesotho",
            "searchName": "MSU-Maseru-Moshoeshoe International-Moshoeshoe Intl-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "PNH",
            "countryCode": "KH",
            "cityCode": "PNH",
            "cityName": "Phnom Penh",
            "name": "[PNH] - Phnom Penh International - PNH - Phnom Penh - Cambodia",
            "searchName": "PNH-Phnom Penh-Phnom Penh International-Pochentong-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "IXB",
            "countryCode": "IN",
            "cityCode": "IXB",
            "cityName": "Bagdogra",
            "name": "[IXB] - Bagdogra - IXB - Bagdogra - India",
            "searchName": "IXB-Bagdogra-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "PNQ",
            "countryCode": "IN",
            "cityCode": "PNQ",
            "cityName": "Pune",
            "name": "[PNQ] - Lohegaon - PNQ - Pune - India",
            "searchName": "PNQ-Pune-Lohegaon-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "ZAD",
            "countryCode": "HR",
            "cityCode": "ZAD",
            "cityName": "Zadar",
            "name": "[ZAD] - Zadar - ZAD - Zadar - Croatia",
            "searchName": "ZAD-Zadar-Zara-Kroatië-Kroatia-Chorvatsko-Hirvatistan-Croàcia-Horvátország-Croatia-Hrvaška-Kroatija-Horvatija-Hrvatska-Horvaatia-Chorwacja-Kroasia-Kroatien-Croatie-Croacia-Croazia",
            "priority": 0
        },
        {
            "iataCode": "IXM",
            "countryCode": "IN",
            "cityCode": "IXM",
            "cityName": "Madurai",
            "name": "[IXM] - Madurai - IXM - Madurai - India",
            "searchName": "IXM-Madurai-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "NGO",
            "countryCode": "JP",
            "cityCode": "NGO",
            "cityName": "Nagoya",
            "name": "[NGO] - Chu-Bu Centrair International (Central Japan International) - NGO - Nagoya - Japan",
            "searchName": "NGO-Nagoya ChÅ«bu Centrair Int-Chu-Bu Centrair International (Central Japan International)-Central Japan Intl-Chubu CentrAir Intl-Nagoya-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "REP",
            "countryCode": "KH",
            "cityCode": "REP",
            "cityName": "Siem Reap",
            "name": "[REP] - Siem Reap - REP - Siem Reap - Cambodia",
            "searchName": "REP-Siem Reap-Siem Riep-Ciudad de Siem Riep-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "AEY",
            "countryCode": "IS",
            "cityCode": "AEY",
            "cityName": "Akureyri",
            "name": "[AEY] - Akureyri - AEY - Akureyri - Iceland",
            "searchName": "AEY-Akureyri-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "JAI",
            "countryCode": "IN",
            "cityCode": "JAI",
            "cityName": "Jaipur",
            "name": "[JAI] - Sanganeer - JAI - Jaipur - India",
            "searchName": "JAI-Jaipur-Sanganeer-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "SMI",
            "countryCode": "GR",
            "cityCode": "SMI",
            "cityName": "Samos",
            "name": "[SMI] - Samos - SMI - Samos - Greece",
            "searchName": "SMI-Samos-Samos Airport-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "EMA",
            "countryCode": "GB",
            "cityCode": "NQT",
            "cityName": "Nottingham",
            "name": "[EMA] - East Midlands - NQT - Nottingham - United Kingdom",
            "searchName": "EMA-NQT-Nottingham-East Midlands-East Midlands Apt-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "JTY",
            "countryCode": "GR",
            "cityCode": "JTY",
            "cityName": "Astypalaia Island",
            "name": "[JTY] - Astypalaia - JTY - Astypalaia Island - Greece",
            "searchName": "JTY-Astypalea-Astypalaia-Astipalaia Airport-Astypalaia Island--Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland-Greece",
            "priority": 0
        },
        {
            "iataCode": "SUV",
            "countryCode": "FJ",
            "cityCode": "SUV",
            "cityName": "Suva",
            "name": "[SUV] - Nausori - SUV - Suva - Fiji",
            "searchName": "SUV-Suva-Nausori-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "ETZ",
            "countryCode": "FR",
            "cityCode": "ETZ",
            "cityName": "Metz",
            "name": "[ETZ] - Metz-nancy-lorraine - ETZ - Metz - France",
            "searchName": "ETZ-Metz Nancy Lorraine-Metz-nancy-lorraine-Nancy-Lorraine-Metz/Nancy-Nancy/Metz-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "DCF",
            "countryCode": "DM",
            "cityCode": "DOM",
            "cityName": "Dominica",
            "name": "[DCF] - Cane Field - DOM - Dominica - Dominica",
            "searchName": "DCF-DOM-Landeplatz Cane-Cane Field-Dominica-Marigot-Dominika-Dominique",
            "priority": 0
        },
        {
            "iataCode": "FKB",
            "countryCode": "DE",
            "cityCode": "FKB",
            "cityName": "Karlsruhe",
            "name": "[FKB] - Karlsruhe/Baden-Baden - FKB - Karlsruhe - Germany",
            "searchName": "FKB-Karlsruhe Baden-Baden-Karlsruhe/Baden-Söllingen-Baden Apt-Karlsruhe/Baden Baden-Karlsruhe-Karlsruhe\\/Baden Baden-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ESS",
            "countryCode": "DE",
            "cityCode": "ESS",
            "cityName": "Essen",
            "name": "[ESS] - Essen - ESS - Essen - Germany",
            "searchName": "ESS-Essen-Essen Havalimani-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "VXE",
            "countryCode": "CV",
            "cityCode": "VXE",
            "cityName": "Sao Vicente",
            "name": "[VXE] - San Pedro - VXE - Sao Vicente - Cape Verde",
            "searchName": "VXE-Sao Vicente-San Pedro-Kap Verde-Cape Verde-Yesil Burun Adalari-Cap-Vert-Cabo Verde-Capo Verde",
            "priority": 0
        },
        {
            "iataCode": "OSR",
            "countryCode": "CZ",
            "cityCode": "OSR",
            "cityName": "Ostrava",
            "name": "[OSR] - Mosnov - OSR - Ostrava - Czech Republic",
            "searchName": "OSR-Ostrava-Mosnov-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "YEK",
            "countryCode": "CA",
            "cityCode": "YEK",
            "cityName": "Arviat",
            "name": "[YEK] - Arviat - YEK - Arviat - Canada",
            "searchName": "YEK-Arviat-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "BSL",
            "countryCode": "CH",
            "cityCode": "EAP",
            "cityName": "Mulhouse",
            "name": "[BSL] - Euroairport Swiss - EAP - Mulhouse - Switzerland",
            "searchName": "BSL-EAP-Basel EuroAirport-Euroairport Swiss-EuroAirport Swiss-Mulhouse - Freiburg - Euro Airport-Basel/Mulhouse-Mulhouse-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "YOW",
            "countryCode": "CA",
            "cityCode": "YOW",
            "cityName": "Ottawa",
            "name": "[YOW] - Ottawa Macdonald-Cartier International - YOW - Ottawa - Canada",
            "searchName": "YOW-Ottawa-Ottawa Macdonald-Cartier International-International-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQB",
            "countryCode": "CA",
            "cityCode": "YQB",
            "cityName": "Québec City",
            "name": "[YQB] - QuÃ©bec - YQB - Québec City - Canada",
            "searchName": "YQB-Québec City Jean Lesage-Québec-Quebec-International-QuÃ©bec-Qu-Québec City-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "USH",
            "countryCode": "AR",
            "cityCode": "USH",
            "cityName": "Ushuaia",
            "name": "[USH] - Islas Malvinas Argentinas International - USH - Ushuaia - Argentina",
            "searchName": "USH-Ushuaia-Islas Malvinas Argentinas International-Islas Malvinas-Ushua-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "ZNE",
            "countryCode": "AU",
            "cityCode": "ZNE",
            "cityName": "Newman",
            "name": "[ZNE] - Newman - ZNE - Newman - Australia",
            "searchName": "ZNE-Newman-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PPG",
            "countryCode": "AS",
            "cityCode": "PPG",
            "cityName": "Pago Pago",
            "name": "[PPG] - International - PPG - Pago Pago - American Samoa",
            "searchName": "PPG-Pago Pago-International-Amerikanisch-Samoa-American Samoa-Amerikan Samoasi-Samoa américaines-Samoa Americana-Samoa Americane",
            "priority": 0
        },
        {
            "iataCode": "CGP",
            "countryCode": "BD",
            "cityCode": "CGP",
            "cityName": "Chittagong",
            "name": "[CGP] - Patenga - CGP - Chittagong - Bangladesh",
            "searchName": "CGP-Chittagong-Patenga-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "SQO",
            "countryCode": "SE",
            "cityCode": "SQO",
            "cityName": "Storuman",
            "name": "[SQO] - Gunnarn - SQO - Storuman - Sweden",
            "searchName": "SQO-Gunnarn-Storuman-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "ASR",
            "countryCode": "TR",
            "cityCode": "ASR",
            "cityName": "Kayseri",
            "name": "[ASR] - Erkilet International Airport - ASR - Kayseri - Turkey",
            "searchName": "ASR-Kayseri-Erkilet International Airport-Cesarea in Cappadocia-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "HUN",
            "countryCode": "TW",
            "cityCode": "HUN",
            "cityName": "Hualien",
            "name": "[HUN] - Hualien - HUN - Hualien - Taiwan",
            "searchName": "HUN-Hualien-Phi Bai-Hua lien-Hualian-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "DNZ",
            "countryCode": "TR",
            "cityCode": "DNZ",
            "cityName": "Denizli",
            "name": "[DNZ] - Cardak - DNZ - Denizli - Turkey",
            "searchName": "DNZ-Denizli-Cardak-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "PDX",
            "countryCode": "US",
            "cityCode": "PDX",
            "cityName": "Portland",
            "name": "[PDX] - Portland International - PDX - Portland - United States",
            "searchName": "PDX-Portland Oregon-Portland International-Portland-Portland OR International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MSS",
            "countryCode": "US",
            "cityCode": "MSS",
            "cityName": "Massena",
            "name": "[MSS] - Richards Field - MSS - Massena - United States",
            "searchName": "MSS-Massena-Richards Field-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MOD",
            "countryCode": "US",
            "cityCode": "MOD",
            "cityName": "Modesto",
            "name": "[MOD] - Municipal - MOD - Modesto - United States",
            "searchName": "MOD-Modesto-Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KLO",
            "countryCode": "PH",
            "cityCode": "KLO",
            "cityName": "Kalibo",
            "name": "[KLO] - Kalibo - KLO - Kalibo - Philippines",
            "searchName": "KLO-Kalibo-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CHC",
            "countryCode": "NZ",
            "cityCode": "CHC",
            "cityName": "Christchurch",
            "name": "[CHC] - Christchurch International - CHC - Christchurch - New Zealand",
            "searchName": "CHC-Christchurch-Christchurch International-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "FLW",
            "countryCode": "PT",
            "cityCode": "FLW",
            "cityName": "Flores Island",
            "name": "[FLW] - Santa Cruz - FLW - Flores Island - Portugal",
            "searchName": "FLW-Santa Cruz Portugal-Santa Cruz-Flores Island--Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "PKV",
            "countryCode": "RU",
            "cityCode": "PKV",
            "cityName": "Pskov",
            "name": "[PKV] - Pskov - PKV - Pskov - Russia",
            "searchName": "PKV-Pskov-Pskov Havalimani-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "PEZ",
            "countryCode": "RU",
            "cityCode": "PEZ",
            "cityName": "Penza",
            "name": "[PEZ] - Penza - PEZ - Penza - Russia",
            "searchName": "PEZ-Pensa-Penza-Penza Havalimani-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "AQJ",
            "countryCode": "JO",
            "cityCode": "AQJ",
            "cityName": "Aqaba",
            "name": "[AQJ] - King Hussein International - AQJ - Aqaba - Jordan",
            "searchName": "AQJ-Aqaba-King Hussein International-King Hussein Intl-Jordánia-Jordània-Ürdün-Jordan-Jordánsko-Jordania-Jordaania-Jordanija-Iordania-Yordania-Jordanien-Jordânia-Jordani-Jordanie-Giordania",
            "priority": 0
        },
        {
            "iataCode": "MMK",
            "countryCode": "RU",
            "cityCode": "MMK",
            "cityName": "Murmansk",
            "name": "[MMK] - Murmansk - MMK - Murmansk - Russia",
            "searchName": "MMK-Murmansk-M-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "UUS",
            "countryCode": "RU",
            "cityCode": "UUS",
            "cityName": "Yuzhno-Sakhalinsk",
            "name": "[UUS] - Yuzhno-Sakhalinsk - UUS - Yuzhno-Sakhalinsk - Russia",
            "searchName": "UUS-Juschno Sachalinsk-Yuzhno-Sakhalinsk-Juschno-Sachalinsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "SGC",
            "countryCode": "RU",
            "cityCode": "SGC",
            "cityName": "Surgut",
            "name": "[SGC] - Surgut - SGC - Surgut - Russia",
            "searchName": "SGC-Surgut-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "DYR",
            "countryCode": "RU",
            "cityCode": "DYR",
            "cityName": "Anadyr",
            "name": "[DYR] - Anadyr - DYR - Anadyr - Russia",
            "searchName": "DYR-Anadyr-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "PPL",
            "countryCode": "NP",
            "cityCode": "PPL",
            "cityName": "Phaplu",
            "name": "[PPL] - Phaplu - PPL - Phaplu - Nepal",
            "searchName": "PPL-Phaplu-Phaplu Havalimani-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "MAG",
            "countryCode": "PG",
            "cityCode": "MAG",
            "cityName": "Madang",
            "name": "[MAG] - Madang - MAG - Madang - Papua New Guinea",
            "searchName": "MAG-Madang-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PZB",
            "countryCode": "ZA",
            "cityCode": "PZB",
            "cityName": "Pietermaritzburg",
            "name": "[PZB] - Pietermaritzburg Oribi - PZB - Pietermaritzburg - South Africa",
            "searchName": "PZB-Pietermaritzburg-Pietermaritzburg Oribi-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "DAD",
            "countryCode": "VN",
            "cityCode": "DAD",
            "cityName": "Da Nang",
            "name": "[DAD] - Da Nang - DAD - Da Nang - Vietnam",
            "searchName": "DAD-Da Nang-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "SGN",
            "countryCode": "VN",
            "cityCode": "SGN",
            "cityName": "Ho Chi Minh City",
            "name": "[SGN] - Tan Son Nhat International - SGN - Ho Chi Minh City - Vietnam",
            "searchName": "SGN-Tan Son Nhat International -Tan Son Nhat International-Ho Chi Minh City-Tan Son Nhut-Ho Chi Minh Stadt-Ciudad Ho Chi Minh-Ho Chi Minh Ville-Ho Chi Minh-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "FMN",
            "countryCode": "US",
            "cityCode": "FMN",
            "cityName": "Farmington",
            "name": "[FMN] - Municipal - FMN - Farmington - United States",
            "searchName": "FMN-Farmington-Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IND",
            "countryCode": "US",
            "cityCode": "IND",
            "cityName": "Indianapolis",
            "name": "[IND] - Indianapolis International - IND - Indianapolis - United States",
            "searchName": "IND-Indianapolis-Indianapolis International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FKL",
            "countryCode": "US",
            "cityCode": "FKL",
            "cityName": "Franklin",
            "name": "[FKL] - Chess-Lambertin - FKL - Franklin - United States",
            "searchName": "FKL-Franklin-Chess-Lambertin-Chess Lamberton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CMI",
            "countryCode": "US",
            "cityCode": "CMI",
            "cityName": "Champaign",
            "name": "[CMI] - University Of Illinois Willard - CMI - Champaign - United States",
            "searchName": "CMI-Champaign-University Of Illinois Willard-University-Willard-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CNM",
            "countryCode": "US",
            "cityCode": "CNM",
            "cityName": "Carlsbad",
            "name": "[CNM] - Carlsbad - CNM - Carlsbad - United States",
            "searchName": "CNM-Carlsbad New Mexico-Carlsbad-Cavern City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PWM",
            "countryCode": "US",
            "cityCode": "PWM",
            "cityName": "Portland",
            "name": "[PWM] - International Jetport - PWM - Portland - United States",
            "searchName": "PWM-Portland Maine-International Jetport-Portland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SDY",
            "countryCode": "US",
            "cityCode": "SDY",
            "cityName": "Sidney",
            "name": "[SDY] - Richland Municipalcipal - SDY - Sidney - United States",
            "searchName": "SDY-Sidney-Richland Municipalcipal-Richland Municipal-Sindey Richland  Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PUW",
            "countryCode": "US",
            "cityCode": "PUW",
            "cityName": "Pullman",
            "name": "[PUW] - Pullman-Moscow Regional Airport - PUW - Pullman - United States",
            "searchName": "PUW-Pullman-Moscow Regional Airport-Moscow Regional-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TAL",
            "countryCode": "US",
            "cityCode": "TAL",
            "cityName": "Tanana",
            "name": "[TAL] - Ralph Calhoun - TAL - Tanana - United States",
            "searchName": "TAL-Ralph Calhoun-Tanana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SDF",
            "countryCode": "US",
            "cityCode": "SDF",
            "cityName": "Louisville",
            "name": "[SDF] - Louisville International (Standiford Field) - SDF - Louisville - United States",
            "searchName": "SDF-Louisville-Louisville International (Standiford Field)-Standiford Field-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LOU",
            "countryCode": "US",
            "cityCode": "SDF",
            "cityName": "Louisville",
            "name": "[LOU] - Bowman Field Airport - SDF - Louisville - United States",
            "searchName": "LOU-SDF-Bowman Field Airport-Bowman Field Havalimani-Louisville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LJC",
            "countryCode": "US",
            "cityCode": "SDF",
            "cityName": "Louisville",
            "name": "[LJC] - Intercontinental - SDF - Louisville - United States",
            "searchName": "LJC-SDF-Intercontinental-Louisville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SCM",
            "countryCode": "US",
            "cityCode": "SCM",
            "cityName": "Scammon Bay",
            "name": "[SCM] - SPB - SCM - Scammon Bay - United States",
            "searchName": "SCM-SPB-Scammon Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SCJ",
            "countryCode": "US",
            "cityCode": "SCJ",
            "cityName": "Smith Cove",
            "name": "[SCJ] - Smith Cove - SCJ - Smith Cove - United States",
            "searchName": "SCJ-Smith Cove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SCE",
            "countryCode": "US",
            "cityCode": "SCE",
            "cityName": "State College",
            "name": "[SCE] - University Park Airport - SCE - State College - United States",
            "searchName": "SCE-State College-University Park Airport-University Park-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SCH",
            "countryCode": "US",
            "cityCode": "SCH",
            "cityName": "Schenectady",
            "name": "[SCH] - Schenectady County - SCH - Schenectady - United States",
            "searchName": "SCH-Bezirksflughafen-Schenectady County-Schenectady County Havalimani-Schenectady-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZTD",
            "countryCode": "US",
            "cityCode": "SCH",
            "cityName": "Schenectady",
            "name": "[ZTD] - Schenectady Railway - SCH - Schenectady - United States",
            "searchName": "ZTD-SCH-Schenectady Railway-Schenectady-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SBX",
            "countryCode": "US",
            "cityCode": "SBX",
            "cityName": "Shelby",
            "name": "[SBX] - Shelby - SBX - Shelby - United States",
            "searchName": "SBX-Shelby-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SBY",
            "countryCode": "US",
            "cityCode": "SBY",
            "cityName": "Salisbury-Ocean City",
            "name": "[SBY] - Wicomico Regional - SBY - Salisbury-Ocean City - United States",
            "searchName": "SBY-Salisbury-Wicomico Regional-Ocean City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SCB",
            "countryCode": "US",
            "cityCode": "SCB",
            "cityName": "Scribner",
            "name": "[SCB] - State - SCB - Scribner - United States",
            "searchName": "SCB-State-Scribner-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SCC",
            "countryCode": "US",
            "cityCode": "SCC",
            "cityName": "Prudhoe Bay/Deadhorse",
            "name": "[SCC] - Prudhoe Bay/Deadhorse - SCC - Prudhoe Bay/Deadhorse - United States",
            "searchName": "SCC-Prudhoe Bay-Prudhoe Bay/Deadhorse-Prudhoe Bay\\/Deadhorse-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SIK",
            "countryCode": "US",
            "cityCode": "SIK",
            "cityName": "Sikeston",
            "name": "[SIK] - Memorial - SIK - Sikeston - United States",
            "searchName": "SIK-Memorial-Sikeston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SHX",
            "countryCode": "US",
            "cityCode": "SHX",
            "cityName": "Shageluk",
            "name": "[SHX] - Shageluk - SHX - Shageluk - United States",
            "searchName": "SHX-Shageluk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SHR",
            "countryCode": "US",
            "cityCode": "SHR",
            "cityName": "Sheridan",
            "name": "[SHR] - Sheridan - SHR - Sheridan - United States",
            "searchName": "SHR-Sheridan-Sheridan Cty-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SHV",
            "countryCode": "US",
            "cityCode": "SHV",
            "cityName": "Shreveport",
            "name": "[SHV] - Regional - SHV - Shreveport - United States",
            "searchName": "SHV-Shreveport-Regional-Shreveport Louisiana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BAD",
            "countryCode": "US",
            "cityCode": "SHV",
            "cityName": "Shreveport",
            "name": "[BAD] - Barksdale AFB - SHV - Shreveport - United States",
            "searchName": "BAD-SHV-Barksdale AFB-Barksdale Air Force Base Havalimani-Shreveport Louisiana-Shreveport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DTN",
            "countryCode": "US",
            "cityCode": "SHV",
            "cityName": "Shreveport",
            "name": "[DTN] - Shreveport Downtown - SHV - Shreveport - United States",
            "searchName": "DTN-SHV-Shreveport Downtown-Shreveport Louisiana-Shreveport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SGY",
            "countryCode": "US",
            "cityCode": "SGY",
            "cityName": "Skagway",
            "name": "[SGY] - Skagway Municipal - SGY - Skagway - United States",
            "searchName": "SGY-Skagway-Skagway Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SGW",
            "countryCode": "US",
            "cityCode": "SGW",
            "cityName": "Saginaw Bay",
            "name": "[SGW] - Saginaw Bay - SGW - Saginaw Bay - United States",
            "searchName": "SGW-Saginaw Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SHD",
            "countryCode": "US",
            "cityCode": "SHD",
            "cityName": "Staunton",
            "name": "[SHD] - Shenandoah Valley - SHD - Staunton - United States",
            "searchName": "SHD-Staunton Harrisonburg-Shenandoah Valley-Staunton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SHG",
            "countryCode": "US",
            "cityCode": "SHG",
            "cityName": "Shungnak",
            "name": "[SHG] - Shungnak - SHG - Shungnak - United States",
            "searchName": "SHG-Shungnak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SHH",
            "countryCode": "US",
            "cityCode": "SHH",
            "cityName": "Shishmaref",
            "name": "[SHH] - Shishmaref - SHH - Shishmaref - United States",
            "searchName": "SHH-Shishmaref-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SHN",
            "countryCode": "US",
            "cityCode": "SHN",
            "cityName": "Shelton",
            "name": "[SHN] - Sanderson Field - SHN - Shelton - United States",
            "searchName": "SHN-Sanderson Field-Shelton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SGH",
            "countryCode": "US",
            "cityCode": "SGH",
            "cityName": "Springfield",
            "name": "[SGH] - Springfield - SGH - Springfield - United States",
            "searchName": "SGH-Springfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SGF",
            "countryCode": "US",
            "cityCode": "SGF",
            "cityName": "Springfield",
            "name": "[SGF] - Springfield-Branson Rg - SGF - Springfield - United States",
            "searchName": "SGF-Springfield Missouri-Springfield-Branson Rg-Branson-Springfield - Branson Rg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SGT",
            "countryCode": "US",
            "cityCode": "SGT",
            "cityName": "Stuttgart",
            "name": "[SGT] - Stuttgart Municipalcipal - SGT - Stuttgart - United States",
            "searchName": "SGT-Stuttgart Municipalcipal-Stuttgart-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SGU",
            "countryCode": "US",
            "cityCode": "SGU",
            "cityName": "Saint George",
            "name": "[SGU] - St. George Municipal - SGU - Saint George - United States",
            "searchName": "SGU-St George-St. George Municipal-Saint George-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SGR",
            "countryCode": "US",
            "cityCode": "SGR",
            "cityName": "Sugar Land",
            "name": "[SGR] - Sugar Land Regional - SGR - Sugar Land - United States",
            "searchName": "SGR-Kommunaler Flughafen Sugar Land-Sugar Land Regional-Hull Field Havalimani-Sugar Land-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SLE",
            "countryCode": "US",
            "cityCode": "SLE",
            "cityName": "Salem",
            "name": "[SLE] - Mcnary Field - SLE - Salem - United States",
            "searchName": "SLE-Landeplatz McNary-Mcnary Field-Salem-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SLK",
            "countryCode": "US",
            "cityCode": "SLK",
            "cityName": "Saranac Lake",
            "name": "[SLK] - Adirondack - SLK - Saranac Lake - United States",
            "searchName": "SLK-Saranac Lake-Adirondack-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SLN",
            "countryCode": "US",
            "cityCode": "SLN",
            "cityName": "Salina",
            "name": "[SLN] - Salina - SLN - Salina - United States",
            "searchName": "SLN-Salina-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SLO",
            "countryCode": "US",
            "cityCode": "SLO",
            "cityName": "Salem",
            "name": "[SLO] - Leckrone - SLO - Salem - United States",
            "searchName": "SLO-Leckrone-Salem-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SLG",
            "countryCode": "US",
            "cityCode": "SLG",
            "cityName": "Siloam Springs",
            "name": "[SLG] - Smith Field - SLG - Siloam Springs - United States",
            "searchName": "SLG-Landeplatz Smith-Smith Field-Siloam Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SLB",
            "countryCode": "US",
            "cityCode": "SLB",
            "cityName": "Storm Lake",
            "name": "[SLB] - Storm Lake Municipal Airport - SLB - Storm Lake - United States",
            "searchName": "SLB-Storm Lake Municipal Airport-Storm Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SLC",
            "countryCode": "US",
            "cityCode": "SLC",
            "cityName": "Salt Lake City",
            "name": "[SLC] - Salt Lake City International - SLC - Salt Lake City - United States",
            "searchName": "SLC-Salt Lake City Intl-Salt Lake City International-Salt Lake City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SKW",
            "countryCode": "US",
            "cityCode": "SKW",
            "cityName": "Skwentna",
            "name": "[SKW] - Intermediate - SKW - Skwentna - United States",
            "searchName": "SKW-Intermediate-Skwentna-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SKK",
            "countryCode": "US",
            "cityCode": "SKK",
            "cityName": "Shaktoolik",
            "name": "[SKK] - Shaktoolik - SKK - Shaktoolik - United States",
            "searchName": "SKK-Shaktoolik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SJT",
            "countryCode": "US",
            "cityCode": "SJT",
            "cityName": "San Angelo",
            "name": "[SJT] - Mathis Field - SJT - San Angelo - United States",
            "searchName": "SJT-San Angelo-Mathis Field-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GOF",
            "countryCode": "US",
            "cityCode": "SJT",
            "cityName": "San Angelo",
            "name": "[GOF] - Goodfellow AFB - SJT - San Angelo - United States",
            "searchName": "GOF-SJT-Goodfellow AFB-San Angelo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SJN",
            "countryCode": "US",
            "cityCode": "SJN",
            "cityName": "St Johns",
            "name": "[SJN] - St. Johns Industrial Air Park - SJN - St Johns - United States",
            "searchName": "SJN-St. Johns Industrial Air Park-St Johns-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SJC",
            "countryCode": "US",
            "cityCode": "SJC",
            "cityName": "San Jose",
            "name": "[SJC] - Mineta San Jose International Airport - SJC - San Jose - United States",
            "searchName": "SJC-San Jose USA-Mineta San Jose International Airport-San Jose-San Jos-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RHV",
            "countryCode": "US",
            "cityCode": "SJC",
            "cityName": "San Jose",
            "name": "[RHV] - Reid-Hillview - SJC - San Jose - United States",
            "searchName": "RHV-SJC-Reid-Hillview-Reid Hillview Of Santa Clara County Havalimani-San Jos-San Jose-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZJO",
            "countryCode": "US",
            "cityCode": "SJC",
            "cityName": "San Jose",
            "name": "[ZJO] - San Jose Bus Station - SJC - San Jose - United States",
            "searchName": "ZJO-SJC-San Jose Bus Station-San Jos-San Jose-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SIY",
            "countryCode": "US",
            "cityCode": "SIY",
            "cityName": "Montague",
            "name": "[SIY] - Siskiyou County - SIY - Montague - United States",
            "searchName": "SIY-Siskiyou County-Montague-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ROF",
            "countryCode": "US",
            "cityCode": "SIY",
            "cityName": "Montague",
            "name": "[ROF] - Yreka Rohrer Field - SIY - Montague - United States",
            "searchName": "ROF-SIY-Yreka Rohrer Field-Montague-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SIV",
            "countryCode": "US",
            "cityCode": "SIV",
            "cityName": "Sullivan",
            "name": "[SIV] - County - SIV - Sullivan - United States",
            "searchName": "SIV-County-Sullivan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SIT",
            "countryCode": "US",
            "cityCode": "SIT",
            "cityName": "Sitka",
            "name": "[SIT] - Sitka - SIT - Sitka - United States",
            "searchName": "SIT-Sitka-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ULM",
            "countryCode": "US",
            "cityCode": "ULM",
            "cityName": "New Ulm",
            "name": "[ULM] - New Ulm - ULM - New Ulm - United States",
            "searchName": "ULM-New Ulm-New Ulm Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UMB",
            "countryCode": "US",
            "cityCode": "UMB",
            "cityName": "Umnak Island",
            "name": "[UMB] - North Shore - UMB - Umnak Island - United States",
            "searchName": "UMB-North Shore-Umnak Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UNS",
            "countryCode": "US",
            "cityCode": "UMB",
            "cityName": "Umnak Island",
            "name": "[UNS] - Umnak - UMB - Umnak Island - United States",
            "searchName": "UNS-UMB-Umnak-Umnak Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UKT",
            "countryCode": "US",
            "cityCode": "UKT",
            "cityName": "Quakertown",
            "name": "[UKT] - Upper Bucks - UKT - Quakertown - United States",
            "searchName": "UKT-Upper Bucks-Upper Bucks Havalimani-Quakertown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UKI",
            "countryCode": "US",
            "cityCode": "UKI",
            "cityName": "Ukiah",
            "name": "[UKI] - Ukiah - UKI - Ukiah - United States",
            "searchName": "UKI-Ukiah-Ukiah Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UKN",
            "countryCode": "US",
            "cityCode": "UKN",
            "cityName": "Waukon",
            "name": "[UKN] - Waukon Municipal Airport - UKN - Waukon - United States",
            "searchName": "UKN-Waukon Municipal Airport-Waukon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UMM",
            "countryCode": "US",
            "cityCode": "UMM",
            "cityName": "Summit",
            "name": "[UMM] - Summit - UMM - Summit - United States",
            "searchName": "UMM-Summit-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UMT",
            "countryCode": "US",
            "cityCode": "UMT",
            "cityName": "Umiat",
            "name": "[UMT] - Umiat - UMT - Umiat - United States",
            "searchName": "UMT-Umiat-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UNK",
            "countryCode": "US",
            "cityCode": "UNK",
            "cityName": "Unalakleet",
            "name": "[UNK] - Unalakleet - UNK - Unalakleet - United States",
            "searchName": "UNK-Unalakleet-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UPP",
            "countryCode": "US",
            "cityCode": "UPP",
            "cityName": "Upolu Point",
            "name": "[UPP] - Upolu Point - UPP - Upolu Point - United States",
            "searchName": "UPP-Upolu Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UQE",
            "countryCode": "US",
            "cityCode": "UQE",
            "cityName": "Queen",
            "name": "[UQE] - Queen - UQE - Queen - United States",
            "searchName": "UQE-Queen-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UOS",
            "countryCode": "US",
            "cityCode": "UOS",
            "cityName": "Sewanee",
            "name": "[UOS] - Franklin County - UOS - Sewanee - United States",
            "searchName": "UOS-Franklin County-Sewanee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UOX",
            "countryCode": "US",
            "cityCode": "UOX",
            "cityName": "Oxford",
            "name": "[UOX] - University-Oxford - UOX - Oxford - United States",
            "searchName": "UOX-University-Oxford-University Oxford Havalimani-University Oxford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UNU",
            "countryCode": "US",
            "cityCode": "UNU",
            "cityName": "Juneau",
            "name": "[UNU] - Dodge County - UNU - Juneau - United States",
            "searchName": "UNU-Dodge County-Juneau-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UES",
            "countryCode": "US",
            "cityCode": "UES",
            "cityName": "Waukesha",
            "name": "[UES] - Waukesha - UES - Waukesha - United States",
            "searchName": "UES-Waukesha-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UGI",
            "countryCode": "US",
            "cityCode": "UGI",
            "cityName": "Uganik",
            "name": "[UGI] - Uganik - UGI - Uganik - United States",
            "searchName": "UGI-Uganik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UGN",
            "countryCode": "US",
            "cityCode": "UGN",
            "cityName": "Waukegan",
            "name": "[UGN] - Memorial - UGN - Waukegan - United States",
            "searchName": "UGN-Memorial-Memorial Havalimani-Waukegan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UIL",
            "countryCode": "US",
            "cityCode": "UIL",
            "cityName": "Quillayute",
            "name": "[UIL] - Quillayute State - UIL - Quillayute - United States",
            "searchName": "UIL-Regierungsflughafen-Quillayute State-Quillayute-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UIN",
            "countryCode": "US",
            "cityCode": "UIN",
            "cityName": "Quincy",
            "name": "[UIN] - Quincy M Baldwin Field - UIN - Quincy - United States",
            "searchName": "UIN-Quincy-Quincy M Baldwin Field-Baldwin Field-Quincy Illinois-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UGS",
            "countryCode": "US",
            "cityCode": "UGS",
            "cityName": "Ugashik",
            "name": "[UGS] - Ugashik - UGS - Ugashik - United States",
            "searchName": "UGS-Ugashik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UBS",
            "countryCode": "US",
            "cityCode": "UBS",
            "cityName": "Columbus",
            "name": "[UBS] - Lowndes County - UBS - Columbus - United States",
            "searchName": "UBS-Lowndes County-Lowndes Cty Havalimani-Columbus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GTR",
            "countryCode": "US",
            "cityCode": "UBS",
            "cityName": "Columbus",
            "name": "[GTR] - Golden Triangle Reg. - UBS - Columbus - United States",
            "searchName": "GTR-UBS-Columbus Golden Triangle-Golden Triangle Reg.-Columbus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CBM",
            "countryCode": "US",
            "cityCode": "UBS",
            "cityName": "Columbus",
            "name": "[CBM] - Columbus AFB - UBS - Columbus - United States",
            "searchName": "CBM-UBS-Columbus AFB-Columbus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UCY",
            "countryCode": "US",
            "cityCode": "UCY",
            "cityName": "Union City",
            "name": "[UCY] - Everett-Stewart - UCY - Union City - United States",
            "searchName": "UCY-Everett-Stewart-Union City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZUA",
            "countryCode": "US",
            "cityCode": "UCA",
            "cityName": "Utica",
            "name": "[ZUA] - Utica N Railway - UCA - Utica - United States",
            "searchName": "ZUA-UCA-Utica N Railway-Utica-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UCC",
            "countryCode": "US",
            "cityCode": "UCC",
            "cityName": "Yucca Flat",
            "name": "[UCC] - Yucca Flat - UCC - Yucca Flat - United States",
            "searchName": "UCC-Yucca Flat-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UCE",
            "countryCode": "US",
            "cityCode": "UCE",
            "cityName": "Eunice",
            "name": "[UCE] - Eunice - UCE - Eunice - United States",
            "searchName": "UCE-Eunice-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VIS",
            "countryCode": "US",
            "cityCode": "VIS",
            "cityName": "Visalia",
            "name": "[VIS] - Visalia - VIS - Visalia - United States",
            "searchName": "VIS-Visalia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VIH",
            "countryCode": "US",
            "cityCode": "VIH",
            "cityName": "Vichy",
            "name": "[VIH] - Rolla National - VIH - Vichy - United States",
            "searchName": "VIH-Nationaler Flughafen Rolla-Rolla National-Vichy-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VIK",
            "countryCode": "US",
            "cityCode": "VIK",
            "cityName": "Kavik",
            "name": "[VIK] - Airstrip - VIK - Kavik - United States",
            "searchName": "VIK-Landebahn-Airstrip-Kavik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VHN",
            "countryCode": "US",
            "cityCode": "VHN",
            "cityName": "Van Horn",
            "name": "[VHN] - Culberson County - VHN - Van Horn - United States",
            "searchName": "VHN-Culberson County-Culberson Cty Havalimani-Van Horn-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VDI",
            "countryCode": "US",
            "cityCode": "VDI",
            "cityName": "Vidalia",
            "name": "[VDI] - Vidalia Municipal Airport - VDI - Vidalia - United States",
            "searchName": "VDI-Vidalia Municipal Airport-Vidalia  Havalimani-Vidalia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VEX",
            "countryCode": "US",
            "cityCode": "VEX",
            "cityName": "Tioga",
            "name": "[VEX] - Tioga Municipal Airport - VEX - Tioga - United States",
            "searchName": "VEX-Kommunaler Flughafen-Tioga Municipal Airport-Tioga-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VEL",
            "countryCode": "US",
            "cityCode": "VEL",
            "cityName": "Vernal",
            "name": "[VEL] - Vernal - VEL - Vernal - United States",
            "searchName": "VEL-Vernal-Vernal Utah-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VBM",
            "countryCode": "US",
            "cityCode": "VBM",
            "cityName": "Blue Mountain",
            "name": "[VBM] - Blue Mountain - VBM - Blue Mountain - United States",
            "searchName": "VBM-Blue Mountain-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VCB",
            "countryCode": "US",
            "cityCode": "VCB",
            "cityName": "View Cove",
            "name": "[VCB] - View Cove - VCB - View Cove - United States",
            "searchName": "VCB-View Cove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VCT",
            "countryCode": "US",
            "cityCode": "VCT",
            "cityName": "Victoria",
            "name": "[VCT] - County-Foster - VCT - Victoria - United States",
            "searchName": "VCT-County-Foster-Victoria Regional-Victoria-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VCV",
            "countryCode": "US",
            "cityCode": "VCV",
            "cityName": "Victorville",
            "name": "[VCV] - Southern California Logistics - VCV - Victorville - United States",
            "searchName": "VCV-Southern California Logistics-Victorville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VDZ",
            "countryCode": "US",
            "cityCode": "VDZ",
            "cityName": "Valdez",
            "name": "[VDZ] - Valdez Municipal - VDZ - Valdez - United States",
            "searchName": "VDZ-Valdez-Valdez Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VEE",
            "countryCode": "US",
            "cityCode": "VEE",
            "cityName": "Venetie",
            "name": "[VEE] - Venetie - VEE - Venetie - United States",
            "searchName": "VEE-Venetie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VAK",
            "countryCode": "US",
            "cityCode": "VAK",
            "cityName": "Chevak",
            "name": "[VAK] - Chevak - VAK - Chevak - United States",
            "searchName": "VAK-Chevak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UWA",
            "countryCode": "US",
            "cityCode": "UWA",
            "cityName": "Ware",
            "name": "[UWA] - Ware - UWA - Ware - United States",
            "searchName": "UWA-Ware-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UVA",
            "countryCode": "US",
            "cityCode": "UVA",
            "cityName": "Uvalde",
            "name": "[UVA] - Garner Field - UVA - Uvalde - United States",
            "searchName": "UVA-Garner Field-Garner Field Havalimani-Uvalde-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UTO",
            "countryCode": "US",
            "cityCode": "UTO",
            "cityName": "Utopia Creek",
            "name": "[UTO] - Indian Mountain AFS - UTO - Utopia Creek - United States",
            "searchName": "UTO-Indian Mountain AFS-Utopia Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UUK",
            "countryCode": "US",
            "cityCode": "UUK",
            "cityName": "Kuparuk",
            "name": "[UUK] - Kuparuk - UUK - Kuparuk - United States",
            "searchName": "UUK-Kuparuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UST",
            "countryCode": "US",
            "cityCode": "UST",
            "cityName": "St Augustine",
            "name": "[UST] - St Augustine - UST - St Augustine - United States",
            "searchName": "UST-St Augustine-St Augustine Havalimani-Saint Augustine-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UTM",
            "countryCode": "US",
            "cityCode": "UTM",
            "cityName": "Tunica",
            "name": "[UTM] - Tunica Municipalcipal Airport - UTM - Tunica - United States",
            "searchName": "UTM-Tunica Municipalcipal Airport-Tunica-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VSK",
            "countryCode": "US",
            "cityCode": "VSK",
            "cityName": "Kennewick",
            "name": "[VSK] - Vista Field - VSK - Kennewick - United States",
            "searchName": "VSK-Vista Field-Kennewick-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VSF",
            "countryCode": "US",
            "cityCode": "VSF",
            "cityName": "Springfield",
            "name": "[VSF] - State - VSF - Springfield - United States",
            "searchName": "VSF-State-Springfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VTN",
            "countryCode": "US",
            "cityCode": "VTN",
            "cityName": "Valentine",
            "name": "[VTN] - Miller Field - VTN - Valentine - United States",
            "searchName": "VTN-Miller Field-Valentine-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VYS",
            "countryCode": "US",
            "cityCode": "VYS",
            "cityName": "Peru",
            "name": "[VYS] - Illinois Valley Regnl - VYS - Peru - United States",
            "searchName": "VYS-Illinois Valley Regnl-Illinois Valley Yerel Havalimani-Peru-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WAA",
            "countryCode": "US",
            "cityCode": "WAA",
            "cityName": "Wales",
            "name": "[WAA] - Wales - WAA - Wales - United States",
            "searchName": "WAA-Wales-Galler-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WAH",
            "countryCode": "US",
            "cityCode": "WAH",
            "cityName": "Wahpeton",
            "name": "[WAH] - Wahpeton - WAH - Wahpeton - United States",
            "searchName": "WAH-Wahpeton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZWU",
            "countryCode": "US",
            "cityCode": "WAS",
            "cityName": "Washington",
            "name": "[ZWU] - Washington Union Railway Station - WAS - Washington - United States",
            "searchName": "ZWU-WAS-Washington Union Railway Station-Washington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZRZ",
            "countryCode": "US",
            "cityCode": "WAS",
            "cityName": "Washington",
            "name": "[ZRZ] - New Carrolton RR - WAS - Washington - United States",
            "searchName": "ZRZ-WAS-New Carrolton RR-Washington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SZP",
            "countryCode": "US",
            "cityCode": "SZP",
            "cityName": "Santa Paula",
            "name": "[SZP] - Oxnard - SZP - Santa Paula - United States",
            "searchName": "SZP-Oxnard-Santa Paula Havalimani-Santa Paula-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SZL",
            "countryCode": "US",
            "cityCode": "SZL",
            "cityName": "Kansas",
            "name": "[SZL] - Whiteman AFB - SZL - Kansas - United States",
            "searchName": "SZL-Luftwaffenstützpunkt Whiteman-Whiteman AFB-Warrenburg-Kansas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SYV",
            "countryCode": "US",
            "cityCode": "SYV",
            "cityName": "Sylvester",
            "name": "[SYV] - Sylvester - SYV - Sylvester - United States",
            "searchName": "SYV-Sylvester-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SYN",
            "countryCode": "US",
            "cityCode": "SYN",
            "cityName": "Stanton",
            "name": "[SYN] - Carleton - SYN - Stanton - United States",
            "searchName": "SYN-Carleton-Stanton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SYR",
            "countryCode": "US",
            "cityCode": "SYR",
            "cityName": "Syracuse",
            "name": "[SYR] - Syracuse Hancock International Airport - SYR - Syracuse - United States",
            "searchName": "SYR-Syracuse-Syracuse Hancock International Airport-Hancock Intl-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZYQ",
            "countryCode": "US",
            "cityCode": "SYR",
            "cityName": "Syracuse",
            "name": "[ZYQ] - Syracuse  Railway - SYR - Syracuse - United States",
            "searchName": "ZYQ-SYR-Syracuse  Railway-Syracuse-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SYA",
            "countryCode": "US",
            "cityCode": "SYA",
            "cityName": "Shemya",
            "name": "[SYA] - Shemya AFB - SYA - Shemya - United States",
            "searchName": "SYA-Shemya AFB-Shemya-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SYB",
            "countryCode": "US",
            "cityCode": "SYB",
            "cityName": "Seal Bay",
            "name": "[SYB] - Seal Bay - SYB - Seal Bay - United States",
            "searchName": "SYB-Seal Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SXY",
            "countryCode": "US",
            "cityCode": "SXY",
            "cityName": "Sidney",
            "name": "[SXY] - Sidney - SXY - Sidney - United States",
            "searchName": "SXY-Sidney-Titreyengol-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SYI",
            "countryCode": "US",
            "cityCode": "SYI",
            "cityName": "Shelbyville",
            "name": "[SYI] - Bomar Field - SYI - Shelbyville - United States",
            "searchName": "SYI-Bomar Field-Shelbyville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SYL",
            "countryCode": "US",
            "cityCode": "SYL",
            "cityName": "San Miguel",
            "name": "[SYL] - Roberts AAF - SYL - San Miguel - United States",
            "searchName": "SYL-Militärflughafen Roberts-Roberts AAF-San Miguel-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SXP",
            "countryCode": "US",
            "cityCode": "SXP",
            "cityName": "Sheldon Point",
            "name": "[SXP] - Sheldon SPB - SXP - Sheldon Point - United States",
            "searchName": "SXP-Sheldon SPB-Sheldon Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SXQ",
            "countryCode": "US",
            "cityCode": "SXQ",
            "cityName": "Soldotna",
            "name": "[SXQ] - Soldotna - SXQ - Soldotna - United States",
            "searchName": "SXQ-Soldotna-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SRV",
            "countryCode": "US",
            "cityCode": "SRV",
            "cityName": "Stony River",
            "name": "[SRV] - Stony River - SRV - Stony River - United States",
            "searchName": "SRV-Stony River-Stony Nehri-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SRW",
            "countryCode": "US",
            "cityCode": "SRW",
            "cityName": "Salisbury",
            "name": "[SRW] - Rowan County - SRW - Salisbury - United States",
            "searchName": "SRW-Rowan County-Salisbury-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SRQ",
            "countryCode": "US",
            "cityCode": "SRQ",
            "cityName": "Sarasota",
            "name": "[SRQ] - Sarasota/Bradenton International - SRQ - Sarasota - United States",
            "searchName": "SRQ-Sarasota Bradenton-Sarasota/Bradenton International-Bradenton/Sarasota-Sarasota-Bradenton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CIU",
            "countryCode": "US",
            "cityCode": "SSM",
            "cityName": "Sault Sainte Marie",
            "name": "[CIU] - Chippewa County - SSM - Sault Sainte Marie - United States",
            "searchName": "CIU-SSM-Sault Sainte Marie USA-Chippewa County-Sault Ste Marie-Sault Sainte Marie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "INR",
            "countryCode": "US",
            "cityCode": "SSM",
            "cityName": "Sault Sainte Marie",
            "name": "[INR] - Kincheloe AFB - SSM - Sault Sainte Marie - United States",
            "searchName": "INR-SSM-Kincheloe AFB-Sault Ste Marie-Sault Sainte Marie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SSI",
            "countryCode": "US",
            "cityCode": "SSI",
            "cityName": "Brunswick",
            "name": "[SSI] - Malcolm Mckinnon - SSI - Brunswick - United States",
            "searchName": "SSI-McKinnon-Malcolm Mckinnon-McKinnon Havalimani-Brunswick-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BQK",
            "countryCode": "US",
            "cityCode": "SSI",
            "cityName": "Brunswick",
            "name": "[BQK] - Glynco Jetport - SSI - Brunswick - United States",
            "searchName": "BQK-SSI-Brunswick-Glynco Jetport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SSC",
            "countryCode": "US",
            "cityCode": "SSC",
            "cityName": "Sumter",
            "name": "[SSC] - Shaw AFB - SSC - Sumter - United States",
            "searchName": "SSC-Shaw AFB-Sumter-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SUM",
            "countryCode": "US",
            "cityCode": "SSC",
            "cityName": "Sumter",
            "name": "[SUM] - Sumter Municipal Airport - SSC - Sumter - United States",
            "searchName": "SUM-SSC-Sumter Municipal Airport-Sumter Belediyesi Havalimani-Sumter-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "STQ",
            "countryCode": "US",
            "cityCode": "STQ",
            "cityName": "St Marys",
            "name": "[STQ] - St Marys - STQ - St Marys - United States",
            "searchName": "STQ-St Marys-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "STG",
            "countryCode": "US",
            "cityCode": "STG",
            "cityName": "St George Island",
            "name": "[STG] - St George Island - STG - St George Island - United States",
            "searchName": "STG-Saint George Island-St George Island--Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "STC",
            "countryCode": "US",
            "cityCode": "STC",
            "cityName": "Saint Cloud",
            "name": "[STC] - Saint Cloud Regional - STC - Saint Cloud - United States",
            "searchName": "STC-Kommunaler Flughafen-Saint Cloud Regional-Saint Cloud-St. Cloud-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JSK",
            "countryCode": "US",
            "cityCode": "STC",
            "cityName": "Saint Cloud",
            "name": "[JSK] - Municipalcipal Heliport - STC - Saint Cloud - United States",
            "searchName": "JSK-STC-Municipalcipal Heliport-St. Cloud-Saint Cloud-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "STS",
            "countryCode": "US",
            "cityCode": "STS",
            "cityName": "Santa Rosa",
            "name": "[STS] - Sonoma County - STS - Santa Rosa - United States",
            "searchName": "STS-Santa Rosa-Sonoma County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SSW",
            "countryCode": "US",
            "cityCode": "SSW",
            "cityName": "Stuart Island",
            "name": "[SSW] - Stuart Island - SSW - Stuart Island - United States",
            "searchName": "SSW-Stuart Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "STJ",
            "countryCode": "US",
            "cityCode": "STJ",
            "cityName": "St Joseph",
            "name": "[STJ] - Rosecrans Memorial - STJ - St Joseph - United States",
            "searchName": "STJ-Rosecrans Memorial-Rosecrans Memorial Havalimani-St Joseph-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "STK",
            "countryCode": "US",
            "cityCode": "STK",
            "cityName": "Sterling",
            "name": "[STK] - Crosson Field - STK - Sterling - United States",
            "searchName": "STK-Crosson Field-Sterling-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PVC",
            "countryCode": "US",
            "cityCode": "PVC",
            "cityName": "Provincetown",
            "name": "[PVC] - Provincetown - PVC - Provincetown - United States",
            "searchName": "PVC-Provincetown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PVD",
            "countryCode": "US",
            "cityCode": "PVD",
            "cityName": "Providence",
            "name": "[PVD] - T. F. Green Airport - PVD - Providence - United States",
            "searchName": "PVD-Providence-T. F. Green Airport-TFGreen-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZRV",
            "countryCode": "US",
            "cityCode": "PVD",
            "cityName": "Providence",
            "name": "[ZRV] - Providence Railway - PVD - Providence - United States",
            "searchName": "ZRV-PVD-Providence Railway-Providence-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PUO",
            "countryCode": "US",
            "cityCode": "PUO",
            "cityName": "Prudhoe Bay",
            "name": "[PUO] - Prudhoe Bay - PUO - Prudhoe Bay - United States",
            "searchName": "PUO-Prudhoe Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PRT",
            "countryCode": "US",
            "cityCode": "PRT",
            "cityName": "Point Retreat",
            "name": "[PRT] - Coast Guard Heliport - PRT - Point Retreat - United States",
            "searchName": "PRT-Hubschrauberlandeplatz der Küstenwache-Coast Guard Heliport-Point Retreat-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PRW",
            "countryCode": "US",
            "cityCode": "PRW",
            "cityName": "Prentice",
            "name": "[PRW] - Prentice - PRW - Prentice - United States",
            "searchName": "PRW-Prentice-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PRX",
            "countryCode": "US",
            "cityCode": "PRX",
            "cityName": "Paris",
            "name": "[PRX] - Cox Field - PRX - Paris - United States",
            "searchName": "PRX-Landeplatz Cox-Cox Field-Paris Cox Field Havalimani-Paris-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PSF",
            "countryCode": "US",
            "cityCode": "PSF",
            "cityName": "Pittsfield",
            "name": "[PSF] - Pittsfield - PSF - Pittsfield - United States",
            "searchName": "PSF-Pittsfield-Pittsfield Belediyesi Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SFZ",
            "countryCode": "US",
            "cityCode": "SFZ",
            "cityName": "Pawtucket",
            "name": "[SFZ] - North Central - SFZ - Pawtucket - United States",
            "searchName": "SFZ-North Central-Smithfield Havalimani-Smithfield-Pawtucket-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SDX",
            "countryCode": "US",
            "cityCode": "SDX",
            "cityName": "Sedona",
            "name": "[SDX] - Sedona - SDX - Sedona - United States",
            "searchName": "SDX-Sedona-Sedona Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LKE",
            "countryCode": "US",
            "cityCode": "SEA",
            "cityName": "Seattle",
            "name": "[LKE] - Kenmore Air Harbor SPB - SEA - Seattle - United States",
            "searchName": "LKE-SEA-Seattle Lake Union-Kenmore Air Harbor SPB-Lake Union SPB-Seattle-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BFI",
            "countryCode": "US",
            "cityCode": "SEA",
            "cityName": "Seattle",
            "name": "[BFI] - Boeing Field/King County International Airport - SEA - Seattle - United States",
            "searchName": "BFI-SEA-Seattle Boeing Field-Boeing Field/King County International Airport-King County/Boeing-Boeing Field\\/King County International Airport-Seattle-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PXL",
            "countryCode": "US",
            "cityCode": "PXL",
            "cityName": "Polacca",
            "name": "[PXL] - Polacca - PXL - Polacca - United States",
            "searchName": "PXL-Polacca-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ASX",
            "countryCode": "US",
            "cityCode": "ASX",
            "cityName": "Ashland",
            "name": "[ASX] - Ashland - ASX - Ashland - United States",
            "searchName": "ASX-Ashland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ASY",
            "countryCode": "US",
            "cityCode": "ASY",
            "cityName": "Ashley",
            "name": "[ASY] - Ashley - ASY - Ashley - United States",
            "searchName": "ASY-Ashley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ATK",
            "countryCode": "US",
            "cityCode": "ATK",
            "cityName": "Atqasuk",
            "name": "[ATK] - Atqasuk - ATK - Atqasuk - United States",
            "searchName": "ATK-Atqasuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FTY",
            "countryCode": "US",
            "cityCode": "ATL",
            "cityName": "Atlanta",
            "name": "[FTY] - Fulton County - ATL - Atlanta - United States",
            "searchName": "FTY-ATL-Fulton County-Fulton Cty Havalimani-Atlanta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JAO",
            "countryCode": "US",
            "cityCode": "ATL",
            "cityName": "Atlanta",
            "name": "[JAO] - Beaver Ruin - ATL - Atlanta - United States",
            "searchName": "JAO-ATL-Beaver Ruin-Atlanta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JAJ",
            "countryCode": "US",
            "cityCode": "ATL",
            "cityName": "Atlanta",
            "name": "[JAJ] - Perimeter Mall - ATL - Atlanta - United States",
            "searchName": "JAJ-ATL-Perimeter Mall-Atlanta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JGL",
            "countryCode": "US",
            "cityCode": "ATL",
            "cityName": "Atlanta",
            "name": "[JGL] - Galleria Heliport - ATL - Atlanta - United States",
            "searchName": "JGL-ATL-Galleria Heliport-Atlanta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PDK",
            "countryCode": "US",
            "cityCode": "ATL",
            "cityName": "Atlanta",
            "name": "[PDK] - Dekalb-Peachtree - ATL - Atlanta - United States",
            "searchName": "PDK-ATL-Dekalb-Peachtree-Dekalb Peachtree Havalimani-De Kalb-Atlanta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ANN",
            "countryCode": "US",
            "cityCode": "ANN",
            "cityName": "Annette Island",
            "name": "[ANN] - Annette Island - ANN - Annette Island - United States",
            "searchName": "ANN-Annette Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ANI",
            "countryCode": "US",
            "cityCode": "ANI",
            "cityName": "Aniak",
            "name": "[ANI] - Aniak - ANI - Aniak - United States",
            "searchName": "ANI-Aniak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ANA",
            "countryCode": "US",
            "cityCode": "ANA",
            "cityName": "Anaheim",
            "name": "[ANA] - John Wayne International - ANA - Anaheim - United States",
            "searchName": "ANA-John Wayne International-Anaheim-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ANB",
            "countryCode": "US",
            "cityCode": "ANB",
            "cityName": "Anniston",
            "name": "[ANB] - Anniston Metropolitan - ANB - Anniston - United States",
            "searchName": "ANB-Anniston Metropolitan-Anniston  Havalimani-Anniston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "QAW",
            "countryCode": "US",
            "cityCode": "ANB",
            "cityName": "Anniston",
            "name": "[QAW] - Ft Mcclellan Bus Station - ANB - Anniston - United States",
            "searchName": "QAW-ANB-Ft Mcclellan Bus Station-Anniston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RLI",
            "countryCode": "US",
            "cityCode": "ANB",
            "cityName": "Anniston",
            "name": "[RLI] - Reilly AHP - ANB - Anniston - United States",
            "searchName": "RLI-ANB-Reilly AHP-Anniston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SEP",
            "countryCode": "US",
            "cityCode": "SEP",
            "cityName": "Stephenville",
            "name": "[SEP] - Clark Field - SEP - Stephenville - United States",
            "searchName": "SEP-Clark Field-Clark Field Havalimani-Stephenville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SER",
            "countryCode": "US",
            "cityCode": "SER",
            "cityName": "Seymour",
            "name": "[SER] - Freeman Municipalcipal - SER - Seymour - United States",
            "searchName": "SER-Freeman Municipalcipal-Seymour-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SES",
            "countryCode": "US",
            "cityCode": "SES",
            "cityName": "Selma",
            "name": "[SES] - Selfield - SES - Selma - United States",
            "searchName": "SES-Selfield-Selma-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SEM",
            "countryCode": "US",
            "cityCode": "SES",
            "cityName": "Selma",
            "name": "[SEM] - Craig AFB - SES - Selma - United States",
            "searchName": "SEM-SES-Craig AFB-Selma-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SDP",
            "countryCode": "US",
            "cityCode": "SDP",
            "cityName": "Sand Point",
            "name": "[SDP] - Sand Point Municipal - SDP - Sand Point - United States",
            "searchName": "SDP-Sand Point-Sand Point Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SEF",
            "countryCode": "US",
            "cityCode": "SEF",
            "cityName": "Sebring",
            "name": "[SEF] - Sebring Regional Airport - SEF - Sebring - United States",
            "searchName": "SEF-Sebring Regional Airport-Uçak terminali Havalimani-Sebring-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SEG",
            "countryCode": "US",
            "cityCode": "SEG",
            "cityName": "Selinsgrove",
            "name": "[SEG] - Penn Valley - SEG - Selinsgrove - United States",
            "searchName": "SEG-Penn Valley-Selinsgrove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SAV",
            "countryCode": "US",
            "cityCode": "SAV",
            "cityName": "Savannah",
            "name": "[SAV] - Savannah/Hilton Head - SAV - Savannah - United States",
            "searchName": "SAV-Savannah-Savannah/Hilton Head-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SVN",
            "countryCode": "US",
            "cityCode": "SAV",
            "cityName": "Savannah",
            "name": "[SVN] - Hunter AAF - SAV - Savannah - United States",
            "searchName": "SVN-SAV-Hunter AAF-Savannah-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SBA",
            "countryCode": "US",
            "cityCode": "SBA",
            "cityName": "Santa Barbara",
            "name": "[SBA] - Santa Barbara Metropolitan Area - SBA - Santa Barbara - United States",
            "searchName": "SBA-Santa Barbara USA-Santa Barbara Metropolitan Area-Santa Barbara-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SZN",
            "countryCode": "US",
            "cityCode": "SBA",
            "cityName": "Santa Barbara",
            "name": "[SZN] - Santa Cruz Island - SBA - Santa Barbara - United States",
            "searchName": "SZN-SBA-Santa Cruz Island-Santa Barbara-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SAR",
            "countryCode": "US",
            "cityCode": "SAR",
            "cityName": "Sparta",
            "name": "[SAR] - Sparta Community - SAR - Sparta - United States",
            "searchName": "SAR-Sparta Community-Sparta Community Havalimani-Sparta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SAS",
            "countryCode": "US",
            "cityCode": "SAS",
            "cityName": "Salton City",
            "name": "[SAS] - Salton City - SAS - Salton City - United States",
            "searchName": "SAS-Salton City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SAT",
            "countryCode": "US",
            "cityCode": "SAT",
            "cityName": "San Antonio",
            "name": "[SAT] - San Antonio International - SAT - San Antonio - United States",
            "searchName": "SAT-San Antonio USA-San Antonio International-San Antonio-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SKF",
            "countryCode": "US",
            "cityCode": "SAT",
            "cityName": "San Antonio",
            "name": "[SKF] - Kelly AFB - SAT - San Antonio - United States",
            "searchName": "SKF-SAT-Kelly AFB-San Antonio-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SSF",
            "countryCode": "US",
            "cityCode": "SAT",
            "cityName": "San Antonio",
            "name": "[SSF] - Stinson Municipalcipal - SAT - San Antonio - United States",
            "searchName": "SSF-SAT-Stinson Municipalcipal-Stinson  Havalimani-San Antonio-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RND",
            "countryCode": "US",
            "cityCode": "SAT",
            "cityName": "San Antonio",
            "name": "[RND] - Randolph AFB - SAT - San Antonio - United States",
            "searchName": "RND-SAT-Randolph AFB-San Antonio-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MDA",
            "countryCode": "US",
            "cityCode": "SAT",
            "cityName": "San Antonio",
            "name": "[MDA] - Martindale Army Heliport - SAT - San Antonio - United States",
            "searchName": "MDA-SAT-Martindale Army Heliport-San Antonio-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SBM",
            "countryCode": "US",
            "cityCode": "SBM",
            "cityName": "Sheboygan",
            "name": "[SBM] - Memorial - SBM - Sheboygan - United States",
            "searchName": "SBM-Memorial-Sheboygan Havalimani-Sheboygan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SBN",
            "countryCode": "US",
            "cityCode": "SBN",
            "cityName": "South Bend",
            "name": "[SBN] - South Bend Regional - SBN - South Bend - United States",
            "searchName": "SBN-South Bend-South Bend Regional-South Bend/Michiana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SBO",
            "countryCode": "US",
            "cityCode": "SBO",
            "cityName": "Salina",
            "name": "[SBO] - Salina - SBO - Salina - United States",
            "searchName": "SBO-Salina-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SBS",
            "countryCode": "US",
            "cityCode": "SBS",
            "cityName": "Steamboat Springs",
            "name": "[SBS] - Steamboat Springs - SBS - Steamboat Springs - United States",
            "searchName": "SBS-Steamboat Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SBD",
            "countryCode": "US",
            "cityCode": "SBT",
            "cityName": "San Bernardino",
            "name": "[SBD] - Norton AFB - SBT - San Bernardino - United States",
            "searchName": "SBD-SBT-San Bernardino Int-Norton AFB-San Bernardino Uluslararasi Havalimani-San Bernardino-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WAL",
            "countryCode": "US",
            "cityCode": "WAL",
            "cityName": "Chincoteague",
            "name": "[WAL] - Wallops Flight Center - WAL - Chincoteague - United States",
            "searchName": "WAL-Wallops Flight Center-Wallops Havalimani-Chincoteague-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WBQ",
            "countryCode": "US",
            "cityCode": "WBQ",
            "cityName": "Beaver",
            "name": "[WBQ] - Beaver - WBQ - Beaver - United States",
            "searchName": "WBQ-Beaver-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WBR",
            "countryCode": "US",
            "cityCode": "WBR",
            "cityName": "Big Rapids",
            "name": "[WBR] - Big Rapids - WBR - Big Rapids - United States",
            "searchName": "WBR-Big Rapids-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WBU",
            "countryCode": "US",
            "cityCode": "WBU",
            "cityName": "Boulder",
            "name": "[WBU] - Boulder Municipal - WBU - Boulder - United States",
            "searchName": "WBU-Kommunaler Flughafen-Boulder Municipal-Municipal Airport-Boulder-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WBI",
            "countryCode": "US",
            "cityCode": "WBU",
            "cityName": "Boulder",
            "name": "[WBI] - Broker Inn - WBU - Boulder - United States",
            "searchName": "WBI-WBU-Broker Inn-Broker INN-Boulder-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "XHH",
            "countryCode": "US",
            "cityCode": "WBU",
            "cityName": "Boulder",
            "name": "[XHH] - Boulder Bus Station - WBU - Boulder - United States",
            "searchName": "XHH-WBU-Boulder Bus Station-Boulder-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WHH",
            "countryCode": "US",
            "cityCode": "WBU",
            "cityName": "Boulder",
            "name": "[WHH] - Hiltons Har H - WBU - Boulder - United States",
            "searchName": "WHH-WBU-Hiltons Har H-Boulder-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WCR",
            "countryCode": "US",
            "cityCode": "WCR",
            "cityName": "Chandalar",
            "name": "[WCR] - Chandalar - WCR - Chandalar - United States",
            "searchName": "WCR-Chandalar-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WBK",
            "countryCode": "US",
            "cityCode": "WBK",
            "cityName": "West Branch",
            "name": "[WBK] - Community - WBK - West Branch - United States",
            "searchName": "WBK-Community-West Branch-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WAY",
            "countryCode": "US",
            "cityCode": "WAY",
            "cityName": "Waynesburg",
            "name": "[WAY] - Greene County - WAY - Waynesburg - United States",
            "searchName": "WAY-Greene County-Green County Havalimani-Waynesburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WBB",
            "countryCode": "US",
            "cityCode": "WBB",
            "cityName": "Stebbins",
            "name": "[WBB] - Stebbins - WBB - Stebbins - United States",
            "searchName": "WBB-Stebbins-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VJI",
            "countryCode": "US",
            "cityCode": "VJI",
            "cityName": "Abingdon",
            "name": "[VJI] - Virginia Highlands - VJI - Abingdon - United States",
            "searchName": "VJI-Virginia Highlands-Virginia Highlands Havalimani-Abingdon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VKS",
            "countryCode": "US",
            "cityCode": "VKS",
            "cityName": "Vicksburg",
            "name": "[VKS] - Vicksburg - VKS - Vicksburg - United States",
            "searchName": "VKS-Vicksburg-Vicksburg Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VKW",
            "countryCode": "US",
            "cityCode": "VKW",
            "cityName": "West Kavik",
            "name": "[VKW] - West Kavik - VKW - West Kavik - United States",
            "searchName": "VKW-West Kavik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VLA",
            "countryCode": "US",
            "cityCode": "VLA",
            "cityName": "Vandalia",
            "name": "[VLA] - Vandalia - VLA - Vandalia - United States",
            "searchName": "VLA-Vandalia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VLD",
            "countryCode": "US",
            "cityCode": "VLD",
            "cityName": "Valdosta",
            "name": "[VLD] - Regional - VLD - Valdosta - United States",
            "searchName": "VLD-Valdosta-Regional-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VAD",
            "countryCode": "US",
            "cityCode": "VLD",
            "cityName": "Valdosta",
            "name": "[VAD] - Moody AFB - VLD - Valdosta - United States",
            "searchName": "VAD-VLD-Moody AFB-Valdosta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VLE",
            "countryCode": "US",
            "cityCode": "VLE",
            "cityName": "Valle",
            "name": "[VLE] - J t Robidoux - VLE - Valle - United States",
            "searchName": "VLE-J t Robidoux-Valle-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VLO",
            "countryCode": "US",
            "cityCode": "VLO",
            "cityName": "Vallejo",
            "name": "[VLO] - Stolport - VLO - Vallejo - United States",
            "searchName": "VLO-Stolport-Stolport Havalimani-Vallejo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VNC",
            "countryCode": "US",
            "cityCode": "VNC",
            "cityName": "Venice",
            "name": "[VNC] - Venice Municipalcipal - VNC - Venice - United States",
            "searchName": "VNC-Venice Municipalcipal-Venice-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VOK",
            "countryCode": "US",
            "cityCode": "VOK",
            "cityName": "Camp Douglas",
            "name": "[VOK] - Volk Field - VOK - Camp Douglas - United States",
            "searchName": "VOK-Landeplatz Volk-Volk Field-Camp Douglas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VPS",
            "countryCode": "US",
            "cityCode": "VPS",
            "cityName": "Valparaiso",
            "name": "[VPS] - Eglin AFB - VPS - Valparaiso - United States",
            "searchName": "VPS-Fort Walton Beach-Eglin AFB-Northwest Florida-Valparaiso-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EGI",
            "countryCode": "US",
            "cityCode": "VPS",
            "cityName": "Valparaiso",
            "name": "[EGI] - Duke Field - VPS - Valparaiso - United States",
            "searchName": "EGI-VPS-Duke Field-Duke Field Havalimani-Valparaiso-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VRS",
            "countryCode": "US",
            "cityCode": "VRS",
            "cityName": "Versailles",
            "name": "[VRS] - Versailles - VRS - Versailles - United States",
            "searchName": "VRS-Versailles-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VPZ",
            "countryCode": "US",
            "cityCode": "VPZ",
            "cityName": "Valparaiso",
            "name": "[VPZ] - Porter County - VPZ - Valparaiso - United States",
            "searchName": "VPZ-Porter County-Porter County Havalimani-Valparaiso-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VRB",
            "countryCode": "US",
            "cityCode": "VRB",
            "cityName": "Vero Beach",
            "name": "[VRB] - Vero Beach Municipal - VRB - Vero Beach - United States",
            "searchName": "VRB-Kommunaler Flughafen-Vero Beach Municipal-Vero Beach  Havalimani-Vero Beach-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WOW",
            "countryCode": "US",
            "cityCode": "WOW",
            "cityName": "Willow",
            "name": "[WOW] - Willow - WOW - Willow - United States",
            "searchName": "WOW-Willow-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WPO",
            "countryCode": "US",
            "cityCode": "WPO",
            "cityName": "Paonia",
            "name": "[WPO] - North Fork Valley - WPO - Paonia - United States",
            "searchName": "WPO-North Fork Valley-Paonia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WRG",
            "countryCode": "US",
            "cityCode": "WRG",
            "cityName": "Wrangell",
            "name": "[WRG] - Wrangell SPB - WRG - Wrangell - United States",
            "searchName": "WRG-Wrangell-Wrangell SPB-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WRH",
            "countryCode": "US",
            "cityCode": "WRH",
            "cityName": "Wrench Creek",
            "name": "[WRH] - Wrench Creek - WRH - Wrench Creek - United States",
            "searchName": "WRH-Wrench Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WRI",
            "countryCode": "US",
            "cityCode": "WRI",
            "cityName": "Wrightstown",
            "name": "[WRI] - Mc Guire AFB - WRI - Wrightstown - United States",
            "searchName": "WRI-Mc Guire AFB-Wrightstown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WRL",
            "countryCode": "US",
            "cityCode": "WRL",
            "cityName": "Worland",
            "name": "[WRL] - Worland Municipalcipal - WRL - Worland - United States",
            "searchName": "WRL-Worland-Worland Municipalcipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WSX",
            "countryCode": "US",
            "cityCode": "WSX",
            "cityName": "Westsound",
            "name": "[WSX] - Westsound - WSX - Westsound - United States",
            "searchName": "WSX-Westsound-West Sound-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WST",
            "countryCode": "US",
            "cityCode": "WST",
            "cityName": "Westerly",
            "name": "[WST] - Westerly State - WST - Westerly - United States",
            "searchName": "WST-Westerly State-Westerly Rhode Island-Westerly-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WSB",
            "countryCode": "US",
            "cityCode": "WSB",
            "cityName": "Steamboat Bay",
            "name": "[WSB] - SPB - WSB - Steamboat Bay - United States",
            "searchName": "WSB-SPB-Steamboat Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WSD",
            "countryCode": "US",
            "cityCode": "WSD",
            "cityName": "White Sands",
            "name": "[WSD] - Condron AAF - WSD - White Sands - United States",
            "searchName": "WSD-Condron AAF-White Sands-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WSF",
            "countryCode": "US",
            "cityCode": "WSF",
            "cityName": "Sarichef",
            "name": "[WSF] - Sarichef - WSF - Sarichef - United States",
            "searchName": "WSF-Sarichef-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WSG",
            "countryCode": "US",
            "cityCode": "WSG",
            "cityName": "Washington",
            "name": "[WSG] - County - WSG - Washington - United States",
            "searchName": "WSG-County-Washington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WSH",
            "countryCode": "US",
            "cityCode": "WSH",
            "cityName": "Shirley",
            "name": "[WSH] - Brookhaven - WSH - Shirley - United States",
            "searchName": "WSH-Brookhaven-Shirley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WSJ",
            "countryCode": "US",
            "cityCode": "WSJ",
            "cityName": "San Juan",
            "name": "[WSJ] - San Juan SPB - WSJ - San Juan - United States",
            "searchName": "WSJ-San Juan SPB-San Juan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WSM",
            "countryCode": "US",
            "cityCode": "WSM",
            "cityName": "Wiseman",
            "name": "[WSM] - Wiseman - WSM - Wiseman - United States",
            "searchName": "WSM-Wiseman-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WSN",
            "countryCode": "US",
            "cityCode": "WSN",
            "cityName": "South Naknek",
            "name": "[WSN] - South Naknek - WSN - South Naknek - United States",
            "searchName": "WSN-South Naknek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WMK",
            "countryCode": "US",
            "cityCode": "WMK",
            "cityName": "Meyers Chuck",
            "name": "[WMK] - SPB - WMK - Meyers Chuck - United States",
            "searchName": "WMK-SPB-Meyers Chuck-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WMH",
            "countryCode": "US",
            "cityCode": "WMH",
            "cityName": "Mountain Home",
            "name": "[WMH] - Mountain Home - WMH - Mountain Home - United States",
            "searchName": "WMH-Mountain Home-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WMO",
            "countryCode": "US",
            "cityCode": "WMO",
            "cityName": "White Mountain",
            "name": "[WMO] - White Mountain - WMO - White Mountain - United States",
            "searchName": "WMO-White Mountain-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WMC",
            "countryCode": "US",
            "cityCode": "WMC",
            "cityName": "Winnemucca",
            "name": "[WMC] - Winnemucca - WMC - Winnemucca - United States",
            "searchName": "WMC-Winnemucca-Winnemucca  Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WLR",
            "countryCode": "US",
            "cityCode": "WLR",
            "cityName": "Loring",
            "name": "[WLR] - Loring - WLR - Loring - United States",
            "searchName": "WLR-Loring-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WLK",
            "countryCode": "US",
            "cityCode": "WLK",
            "cityName": "Selawik",
            "name": "[WLK] - Selawik - WLK - Selawik - United States",
            "searchName": "WLK-Selawik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WLW",
            "countryCode": "US",
            "cityCode": "WLW",
            "cityName": "Willows",
            "name": "[WLW] - Glenn County - WLW - Willows - United States",
            "searchName": "WLW-Glenn County-Willows-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WNA",
            "countryCode": "US",
            "cityCode": "WNA",
            "cityName": "Napakiak",
            "name": "[WNA] - Napakiak SPB - WNA - Napakiak - United States",
            "searchName": "WNA-Napakiak SPB-Napakiak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WNC",
            "countryCode": "US",
            "cityCode": "WNC",
            "cityName": "Tuxekan Island",
            "name": "[WNC] - Naukati Bay SPB - WNC - Tuxekan Island - United States",
            "searchName": "WNC-Nichen Cove-Naukati Bay SPB-Tuxekan Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WOD",
            "countryCode": "US",
            "cityCode": "WOD",
            "cityName": "Wood River",
            "name": "[WOD] - Wood River - WOD - Wood River - United States",
            "searchName": "WOD-Wood River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WOO",
            "countryCode": "US",
            "cityCode": "WOO",
            "cityName": "Woodchopper",
            "name": "[WOO] - Woodchopper - WOO - Woodchopper - United States",
            "searchName": "WOO-Woodchopper-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WHD",
            "countryCode": "US",
            "cityCode": "WHD",
            "cityName": "Hyder",
            "name": "[WHD] - SPB - WHD - Hyder - United States",
            "searchName": "WHD-SPB-Hyder-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WGO",
            "countryCode": "US",
            "cityCode": "WGO",
            "cityName": "Winchester",
            "name": "[WGO] - Winchester Regional - WGO - Winchester - United States",
            "searchName": "WGO-Winchester Regional-Winchester Havalimani-Winchester-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WFK",
            "countryCode": "US",
            "cityCode": "WFK",
            "cityName": "Frenchville",
            "name": "[WFK] - Frenchville - WFK - Frenchville - United States",
            "searchName": "WFK-Frenchville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WBN",
            "countryCode": "US",
            "cityCode": "WBN",
            "cityName": "Woburn",
            "name": "[WBN] - Cummings Park - WBN - Woburn - United States",
            "searchName": "WBN-Cummings Park-Woburn-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WDB",
            "countryCode": "US",
            "cityCode": "WDB",
            "cityName": "Deep Bay",
            "name": "[WDB] - Deep Bay - WDB - Deep Bay - United States",
            "searchName": "WDB-Deep Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WDG",
            "countryCode": "US",
            "cityCode": "WDG",
            "cityName": "Enid",
            "name": "[WDG] - Enid Woodring Mun. - WDG - Enid - United States",
            "searchName": "WDG-Kommunaler Flughafen Woodring-Enid Woodring Mun.-Enid-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "END",
            "countryCode": "US",
            "cityCode": "WDG",
            "cityName": "Enid",
            "name": "[END] - Vance AFB - WDG - Enid - United States",
            "searchName": "END-WDG-Vance AFB-Enid-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WDN",
            "countryCode": "US",
            "cityCode": "WDN",
            "cityName": "Waldron Island",
            "name": "[WDN] - Waldron Island - WDN - Waldron Island - United States",
            "searchName": "WDN-Waldron Island-Waldrun Island Wa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WDR",
            "countryCode": "US",
            "cityCode": "WDR",
            "cityName": "Winder",
            "name": "[WDR] - Winder - WDR - Winder - United States",
            "searchName": "WDR-Winder-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WEA",
            "countryCode": "US",
            "cityCode": "WEA",
            "cityName": "Weatherford",
            "name": "[WEA] - Parker County - WEA - Weatherford - United States",
            "searchName": "WEA-Parker County-Parker County Havalimani-Weatherford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WJF",
            "countryCode": "US",
            "cityCode": "WJF",
            "cityName": "Lancaster",
            "name": "[WJF] - William J Fox - WJF - Lancaster - United States",
            "searchName": "WJF-William J Fox-tüm havaalanlari-Lancaster-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WIB",
            "countryCode": "US",
            "cityCode": "WIB",
            "cityName": "Wilbarger",
            "name": "[WIB] - Wilbarger County - WIB - Wilbarger - United States",
            "searchName": "WIB-Wilbarger County-Wilbarger-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WHT",
            "countryCode": "US",
            "cityCode": "WHT",
            "cityName": "Wharton",
            "name": "[WHT] - Wharton - WHT - Wharton - United States",
            "searchName": "WHT-Wharton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WKK",
            "countryCode": "US",
            "cityCode": "WKK",
            "cityName": "Aleknagik",
            "name": "[WKK] - Aleknagik - WKK - Aleknagik - United States",
            "searchName": "WKK-Aleknagik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WKL",
            "countryCode": "US",
            "cityCode": "WKL",
            "cityName": "Waikoloa",
            "name": "[WKL] - Waikoloa Airport - WKL - Waikoloa - United States",
            "searchName": "WKL-Waikoloa Airport-Waikoloa Havalimani-Waikoloa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WLM",
            "countryCode": "US",
            "cityCode": "WLM",
            "cityName": "Waltham",
            "name": "[WLM] - Waltham - WLM - Waltham - United States",
            "searchName": "WLM-Waltham-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WLN",
            "countryCode": "US",
            "cityCode": "WLN",
            "cityName": "Little Naukati",
            "name": "[WLN] - Little Naukati - WLN - Little Naukati - United States",
            "searchName": "WLN-Little Naukati-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WLD",
            "countryCode": "US",
            "cityCode": "WLD",
            "cityName": "Winfield",
            "name": "[WLD] - Arkansas City - WLD - Winfield - United States",
            "searchName": "WLD-Arkansas City-Arkansas City Havalimani-Winfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WLB",
            "countryCode": "US",
            "cityCode": "WLB",
            "cityName": "Labouchere Bay",
            "name": "[WLB] - Labouchere Bay - WLB - Labouchere Bay - United States",
            "searchName": "WLB-Labouchere Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "XMD",
            "countryCode": "US",
            "cityCode": "XMD",
            "cityName": "Madison",
            "name": "[XMD] - Madison - XMD - Madison - United States",
            "searchName": "XMD-Madison-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "XSM",
            "countryCode": "US",
            "cityCode": "XSM",
            "cityName": "St Marys",
            "name": "[XSM] - St Marys - XSM - St Marys - United States",
            "searchName": "XSM-St Marys-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "XPR",
            "countryCode": "US",
            "cityCode": "XPR",
            "cityName": "Pine Ridge",
            "name": "[XPR] - Pine Ridge - XPR - Pine Ridge - United States",
            "searchName": "XPR-Pine Ridge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "XPU",
            "countryCode": "US",
            "cityCode": "XPU",
            "cityName": "West Kuparuk",
            "name": "[XPU] - West Kuparuk - XPU - West Kuparuk - United States",
            "searchName": "XPU-West Kuparuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "XES",
            "countryCode": "US",
            "cityCode": "XES",
            "cityName": "Lake Geneva",
            "name": "[XES] - Lake Geneva Aire Estates Airport - XES - Lake Geneva - United States",
            "searchName": "XES-Lake Geneva Aire Estates Airport-Lake Geneva-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WVI",
            "countryCode": "US",
            "cityCode": "WVI",
            "cityName": "Watsonville",
            "name": "[WVI] - Watsonville - WVI - Watsonville - United States",
            "searchName": "WVI-Watsonville-Watsonville  Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WWP",
            "countryCode": "US",
            "cityCode": "WWP",
            "cityName": "Whale Pass",
            "name": "[WWP] - Whale Pass - WWP - Whale Pass - United States",
            "searchName": "WWP-Whale Pass-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WWR",
            "countryCode": "US",
            "cityCode": "WWR",
            "cityName": "Woodward",
            "name": "[WWR] - West Woodward - WWR - Woodward - United States",
            "searchName": "WWR-West Woodward-West Woodward Havalimani-Woodward-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WWS",
            "countryCode": "US",
            "cityCode": "WWS",
            "cityName": "Wildwood",
            "name": "[WWS] - USAF Heliport - WWS - Wildwood - United States",
            "searchName": "WWS-Hubschrauberlandeplatz USAF-USAF Heliport-Wildwood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WWT",
            "countryCode": "US",
            "cityCode": "WWT",
            "cityName": "Newtok",
            "name": "[WWT] - Newtok - WWT - Newtok - United States",
            "searchName": "WWT-Newtok-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WVL",
            "countryCode": "US",
            "cityCode": "WVL",
            "cityName": "Waterville",
            "name": "[WVL] - Robert Lafleur - WVL - Waterville - United States",
            "searchName": "WVL-Robert Lafleur-Robert La Fleur Havalimani-Waterville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WTR",
            "countryCode": "US",
            "cityCode": "WTR",
            "cityName": "White River",
            "name": "[WTR] - White River - WTR - White River - United States",
            "searchName": "WTR-White River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WTK",
            "countryCode": "US",
            "cityCode": "WTK",
            "cityName": "Noatak",
            "name": "[WTK] - Noatak - WTK - Noatak - United States",
            "searchName": "WTK-Noatak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WTL",
            "countryCode": "US",
            "cityCode": "WTL",
            "cityName": "Tuntutuliak",
            "name": "[WTL] - Tuntutuliak - WTL - Tuntutuliak - United States",
            "searchName": "WTL-Tuntutuliak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WWA",
            "countryCode": "US",
            "cityCode": "WWA",
            "cityName": "Wasilla",
            "name": "[WWA] - Wasilla - WWA - Wasilla - United States",
            "searchName": "WWA-Wasilla-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WWD",
            "countryCode": "US",
            "cityCode": "WWD",
            "cityName": "Wildwood",
            "name": "[WWD] - Cape May County - WWD - Wildwood - United States",
            "searchName": "WWD-Cape May County-Cape May Havalimani-Wildwood-Cape May-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WYS",
            "countryCode": "US",
            "cityCode": "WYS",
            "cityName": "West Yellowstone",
            "name": "[WYS] - Yellowstone - WYS - West Yellowstone - United States",
            "searchName": "WYS-West Yellowstone-Yellowstone-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WYB",
            "countryCode": "US",
            "cityCode": "WYB",
            "cityName": "Yes Bay",
            "name": "[WYB] - SPB - WYB - Yes Bay - United States",
            "searchName": "WYB-SPB-Yes Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "YAK",
            "countryCode": "US",
            "cityCode": "YAK",
            "cityName": "Yakutat",
            "name": "[YAK] - Yakutat - YAK - Yakutat - United States",
            "searchName": "YAK-Yakutat-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AWM",
            "countryCode": "US",
            "cityCode": "AWM",
            "cityName": "West Memphis",
            "name": "[AWM] - Municipal - AWM - West Memphis - United States",
            "searchName": "AWM-Municipal-West Memphis  Havalimani-West Memphis-Memphis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AVX",
            "countryCode": "US",
            "cityCode": "AVX",
            "cityName": "Avalon",
            "name": "[AVX] - Avalon Bay - AVX - Avalon - United States",
            "searchName": "AVX-Avalon Bay-Catalina Island-Avalon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CIB",
            "countryCode": "US",
            "cityCode": "AVX",
            "cityName": "Avalon",
            "name": "[CIB] - Ap In The Sky - AVX - Avalon - United States",
            "searchName": "CIB-AVX-Airport in the Sky-Ap In The Sky-Catalina Island-Avalon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SXC",
            "countryCode": "US",
            "cityCode": "AVX",
            "cityName": "Avalon",
            "name": "[SXC] - Avalo Vor/WP - AVX - Avalon - United States",
            "searchName": "SXC-AVX-Avalo Vor\\/ WP-Avalo Vor/WP-Catalina Havalimani-Catalina Island-Avalon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TWH",
            "countryCode": "US",
            "cityCode": "AVX",
            "cityName": "Avalon",
            "name": "[TWH] - Two Harbors - AVX - Avalon - United States",
            "searchName": "TWH-AVX-Two Harbors-Catalina Island-Avalon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AVO",
            "countryCode": "US",
            "cityCode": "AVO",
            "cityName": "Avon Park",
            "name": "[AVO] - Municipal - AVO - Avon Park - United States",
            "searchName": "AVO-Municipal-Avon Park-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AVP",
            "countryCode": "US",
            "cityCode": "AVP",
            "cityName": "Wilkes-Barre",
            "name": "[AVP] - Wilkes-Barre/Scranton International Airport - AVP - Wilkes-Barre - United States",
            "searchName": "AVP-Wilkes-Barre-Barre/Scranton International Airport-Barre Scranton-Willkes Barre Scranton Int. Airport-Scranton/Wilkes-Scranton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WBW",
            "countryCode": "US",
            "cityCode": "AVP",
            "cityName": "Wilkes-Barre",
            "name": "[WBW] - Wilkes-Barre/Wyoming Valley - AVP - Wilkes-Barre - United States",
            "searchName": "WBW-AVP-Wilkes-Barre/Wyoming Valley-Scranton/Wilkes-Barre-Scranton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AUW",
            "countryCode": "US",
            "cityCode": "AUW",
            "cityName": "Wausau",
            "name": "[AUW] - Municipal - AUW - Wausau - United States",
            "searchName": "AUW-Kommunaler Flughafen-Municipal-tüm havaalanlari-Wausau-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CWA",
            "countryCode": "US",
            "cityCode": "AUW",
            "cityName": "Wausau",
            "name": "[CWA] - Central Wisconsin - AUW - Wausau - United States",
            "searchName": "CWA-AUW-Wausau-Central Wisconsin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AUS",
            "countryCode": "US",
            "cityCode": "AUS",
            "cityName": "Austin",
            "name": "[AUS] - Austin-bergstrom International - AUS - Austin - United States",
            "searchName": "AUS-Austin Bergstrom-Austin-bergstrom International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MMR",
            "countryCode": "US",
            "cityCode": "AUS",
            "cityName": "Austin",
            "name": "[MMR] - Camp Maybry AHP - AUS - Austin - United States",
            "searchName": "MMR-AUS-Camp Maybry AHP-Austin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AVL",
            "countryCode": "US",
            "cityCode": "AVL",
            "cityName": "Fletcher",
            "name": "[AVL] - Asheville Regional Airport - AVL - Fletcher - United States",
            "searchName": "AVL-Asheville-Asheville Regional Airport-Asheville Regional-Asheville/Hendersonville-Fletcher-Hendersonville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ATW",
            "countryCode": "US",
            "cityCode": "ATW",
            "cityName": "Appleton",
            "name": "[ATW] - Outagamie County - ATW - Appleton - United States",
            "searchName": "ATW-Appleton-Outagamie County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ATS",
            "countryCode": "US",
            "cityCode": "ATS",
            "cityName": "Artesia",
            "name": "[ATS] - Artesia - ATS - Artesia - United States",
            "searchName": "ATS-Artesia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ATT",
            "countryCode": "US",
            "cityCode": "ATT",
            "cityName": "Atmautluak",
            "name": "[ATT] - Atmautluak - ATT - Atmautluak - United States",
            "searchName": "ATT-Atmautluak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ATE",
            "countryCode": "US",
            "cityCode": "ATE",
            "cityName": "Antlers",
            "name": "[ATE] - Antlers - ATE - Antlers - United States",
            "searchName": "ATE-Antlers-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ATO",
            "countryCode": "US",
            "cityCode": "ATO",
            "cityName": "Athens",
            "name": "[ATO] - Ohio University - ATO - Athens - United States",
            "searchName": "ATO-Ohio University-Ohio University Havalimani-Athens-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AUK",
            "countryCode": "US",
            "cityCode": "AUK",
            "cityName": "Alakanuk",
            "name": "[AUK] - Alakanuk - AUK - Alakanuk - United States",
            "searchName": "AUK-Alakanuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AUM",
            "countryCode": "US",
            "cityCode": "AUM",
            "cityName": "Austin",
            "name": "[AUM] - Austin - AUM - Austin - United States",
            "searchName": "AUM-Austin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AUN",
            "countryCode": "US",
            "cityCode": "AUN",
            "cityName": "Auburn",
            "name": "[AUN] - Auburn - AUN - Auburn - United States",
            "searchName": "AUN-Auburn-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AUO",
            "countryCode": "US",
            "cityCode": "AUO",
            "cityName": "Auburn",
            "name": "[AUO] - Auburn-Opelika - AUO - Auburn - United States",
            "searchName": "AUO-Auburn-Opelika-Auburn Opelika Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ATY",
            "countryCode": "US",
            "cityCode": "ATY",
            "cityName": "Watertown",
            "name": "[ATY] - Watertown - ATY - Watertown - United States",
            "searchName": "ATY-Watertown Municipal-Watertown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AUG",
            "countryCode": "US",
            "cityCode": "AUG",
            "cityName": "Augusta",
            "name": "[AUG] - Augusta - AUG - Augusta - United States",
            "searchName": "AUG-Augusta State-Augusta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AYS",
            "countryCode": "US",
            "cityCode": "AYS",
            "cityName": "Waycross",
            "name": "[AYS] - Ware County - AYS - Waycross - United States",
            "searchName": "AYS-Ware County-Ware County Havalimani-Waycross-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AUZ",
            "countryCode": "US",
            "cityCode": "AUZ",
            "cityName": "Aurora",
            "name": "[AUZ] - Municipal Airport - AUZ - Aurora - United States",
            "searchName": "AUZ-Kommunaler Flughafen-Municipal Airport-Aurora  Havalimani-Aurora-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AXV",
            "countryCode": "US",
            "cityCode": "AXV",
            "cityName": "Wapakoneta",
            "name": "[AXV] - Neil Armstrong - AXV - Wapakoneta - United States",
            "searchName": "AXV-Neil Armstrong-Neil Armstrong Havalimani-Wapakoneta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AXX",
            "countryCode": "US",
            "cityCode": "AXX",
            "cityName": "Angel Fire",
            "name": "[AXX] - Angel Fire - AXX - Angel Fire - United States",
            "searchName": "AXX-Angel Fire-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AXB",
            "countryCode": "US",
            "cityCode": "AXB",
            "cityName": "Alexandria Bay",
            "name": "[AXB] - Alexandria Bay - AXB - Alexandria Bay - United States",
            "searchName": "AXB-Alexandria Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AXN",
            "countryCode": "US",
            "cityCode": "AXN",
            "cityName": "Alexandria",
            "name": "[AXN] - Alexandria - AXN - Alexandria - United States",
            "searchName": "AXN-Alexandria-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AXG",
            "countryCode": "US",
            "cityCode": "AXG",
            "cityName": "Algona",
            "name": "[AXG] - Algona - AXG - Algona - United States",
            "searchName": "AXG-Algona-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BAM",
            "countryCode": "US",
            "cityCode": "BAM",
            "cityName": "Battle Mountain",
            "name": "[BAM] - Lander County - BAM - Battle Mountain - United States",
            "searchName": "BAM-Lander County-Battle Mountain-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AZO",
            "countryCode": "US",
            "cityCode": "AZO",
            "cityName": "Kalamazoo",
            "name": "[AZO] - Kalamazoo/Battle Creek International - AZO - Kalamazoo - United States",
            "searchName": "AZO-Kalamazoo-Kalamazoo/Battle Creek International-Kalamazoo Intl-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BAF",
            "countryCode": "US",
            "cityCode": "BAF",
            "cityName": "Westfield",
            "name": "[BAF] - Barnes - BAF - Westfield - United States",
            "searchName": "BAF-Barnes-Westfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BAR",
            "countryCode": "US",
            "cityCode": "BAR",
            "cityName": "Baker Island",
            "name": "[BAR] - Baker AAF - BAR - Baker Island - United States",
            "searchName": "BAR-Armeelandeplatz Baker-Baker AAF-Baker Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BBB",
            "countryCode": "US",
            "cityCode": "BBB",
            "cityName": "Benson",
            "name": "[BBB] - Municipal - BBB - Benson - United States",
            "searchName": "BBB-Municipal-Benson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BBC",
            "countryCode": "US",
            "cityCode": "BBC",
            "cityName": "Bay City",
            "name": "[BBC] - Bay City - BBC - Bay City - United States",
            "searchName": "BBC-Bay City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BBD",
            "countryCode": "US",
            "cityCode": "BBD",
            "cityName": "Brady",
            "name": "[BBD] - Curtis Field - BBD - Brady - United States",
            "searchName": "BBD-Curtis Field-Brady-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BBF",
            "countryCode": "US",
            "cityCode": "BBF",
            "cityName": "Burlington",
            "name": "[BBF] - Burlington - BBF - Burlington - United States",
            "searchName": "BBF-Burlington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ARG",
            "countryCode": "US",
            "cityCode": "ARG",
            "cityName": "Walnut Ridge",
            "name": "[ARG] - Walnut Ridge - ARG - Walnut Ridge - United States",
            "searchName": "ARG-Walnut Ridge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ARV",
            "countryCode": "US",
            "cityCode": "ARV",
            "cityName": "Minocqua",
            "name": "[ARV] - Noble F. Lee - ARV - Minocqua - United States",
            "searchName": "ARV-Noble F. Lee-Noble F Lee Memorial Field Havalimani-Minocqua-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ART",
            "countryCode": "US",
            "cityCode": "ART",
            "cityName": "Watertown",
            "name": "[ART] - Watertown - ART - Watertown - United States",
            "searchName": "ART-Watertown Int.-Watertown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "APT",
            "countryCode": "US",
            "cityCode": "APT",
            "cityName": "Jasper",
            "name": "[APT] - Marion County - APT - Jasper - United States",
            "searchName": "APT-Marion County-Jasper-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AQY",
            "countryCode": "US",
            "cityCode": "AQY",
            "cityName": "Alyeska",
            "name": "[AQY] - Alyeska - AQY - Alyeska - United States",
            "searchName": "AQY-Alyeska-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ARA",
            "countryCode": "US",
            "cityCode": "ARA",
            "cityName": "New Iberia",
            "name": "[ARA] - Acadiana Regional - ARA - New Iberia - United States",
            "searchName": "ARA-Regionaler Flughafen Acadiana-Acadiana Regional-New Iberia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ARB",
            "countryCode": "US",
            "cityCode": "ARB",
            "cityName": "Ann Arbor",
            "name": "[ARB] - Municipal - ARB - Ann Arbor - United States",
            "searchName": "ARB-Ann Arbor-Municipal-Ann Arbor Belediyesi Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ARC",
            "countryCode": "US",
            "cityCode": "ARC",
            "cityName": "Arctic Village",
            "name": "[ARC] - Arctic Village - ARC - Arctic Village - United States",
            "searchName": "ARC-Wien-Arctic Village-Arctic Village Arpt-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AST",
            "countryCode": "US",
            "cityCode": "AST",
            "cityName": "Astoria",
            "name": "[AST] - Astoria - AST - Astoria - United States",
            "searchName": "AST-Astoria-Astoria Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ASQ",
            "countryCode": "US",
            "cityCode": "ASQ",
            "cityName": "Austin",
            "name": "[ASQ] - Austin - ASQ - Austin - United States",
            "searchName": "ASQ-Austin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ASN",
            "countryCode": "US",
            "cityCode": "ASN",
            "cityName": "Talladega",
            "name": "[ASN] - Talladega - ASN - Talladega - United States",
            "searchName": "ASN-Talladega-Talladega Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ASL",
            "countryCode": "US",
            "cityCode": "ASL",
            "cityName": "Marshall",
            "name": "[ASL] - Harrison County - ASL - Marshall - United States",
            "searchName": "ASL-Harrison County-Marshall-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ASH",
            "countryCode": "US",
            "cityCode": "ASH",
            "cityName": "Nashua",
            "name": "[ASH] - Boire Field - ASH - Nashua - United States",
            "searchName": "ASH-Landeplatz Boire-Boire Field-Nashua-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DFI",
            "countryCode": "US",
            "cityCode": "DFI",
            "cityName": "Defiance",
            "name": "[DFI] - Memorial - DFI - Defiance - United States",
            "searchName": "DFI-Memorial-Defiance Memorial Havalimani-Defiance-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DGW",
            "countryCode": "US",
            "cityCode": "DGW",
            "cityName": "Douglas",
            "name": "[DGW] - Converse County - DGW - Douglas - United States",
            "searchName": "DGW-Converse County-Douglas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DGN",
            "countryCode": "US",
            "cityCode": "DGN",
            "cityName": "Dahlgren",
            "name": "[DGN] - NAF - DGN - Dahlgren - United States",
            "searchName": "DGN-Marinefliegerstützpunkt-NAF-Dahlgren-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DGB",
            "countryCode": "US",
            "cityCode": "DGB",
            "cityName": "Danger Bay",
            "name": "[DGB] - Danger Bay - DGB - Danger Bay - United States",
            "searchName": "DGB-Danger Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DEH",
            "countryCode": "US",
            "cityCode": "DEH",
            "cityName": "Decorah",
            "name": "[DEH] - Municipal - DEH - Decorah - United States",
            "searchName": "DEH-Municipal-Decorah Belediyesi Havalimani-Decorah-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BFK",
            "countryCode": "US",
            "cityCode": "DEN",
            "cityName": "Denver",
            "name": "[BFK] - Buffalo Municipal Airport - DEN - Denver - United States",
            "searchName": "BFK-DEN-Buffalo Municipal Airport-Denver-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "APA",
            "countryCode": "US",
            "cityCode": "DEN",
            "cityName": "Denver",
            "name": "[APA] - Arapahoe Co - DEN - Denver - United States",
            "searchName": "APA-DEN-Arapahoe Co-Centennial Havalimani-Denver-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "QWM",
            "countryCode": "US",
            "cityCode": "DEN",
            "cityName": "Denver",
            "name": "[QWM] - Longmont Bus Station - DEN - Denver - United States",
            "searchName": "QWM-DEN-Longmont Bus Station-Denver-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DEO",
            "countryCode": "US",
            "cityCode": "DEO",
            "cityName": "Dearborn",
            "name": "[DEO] - Hyatt Regency Heliport - DEO - Dearborn - United States",
            "searchName": "DEO-Hyatt Regency Heliport-Dearborn-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CGF",
            "countryCode": "US",
            "cityCode": "CLE",
            "cityName": "Cleveland",
            "name": "[CGF] - Cuyahoga County - CLE - Cleveland - United States",
            "searchName": "CGF-CLE-Cuyahoga County-Cuyahoga County Havalimani-Cleveland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BKL",
            "countryCode": "US",
            "cityCode": "CLE",
            "cityName": "Cleveland",
            "name": "[BKL] - Burke Lakefront - CLE - Cleveland - United States",
            "searchName": "BKL-CLE-Burke Lakefront-Burke Lakefront Havalimani-Cleveland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLG",
            "countryCode": "US",
            "cityCode": "CLG",
            "cityName": "Coalinga",
            "name": "[CLG] - Coalinga - CLG - Coalinga - United States",
            "searchName": "CLG-Coalinga-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLL",
            "countryCode": "US",
            "cityCode": "CLL",
            "cityName": "College Station",
            "name": "[CLL] - Easterwood Field - CLL - College Station - United States",
            "searchName": "CLL-College Station-Easterwood Field-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLM",
            "countryCode": "US",
            "cityCode": "CLM",
            "cityName": "Port Angeles",
            "name": "[CLM] - Fairchild International - CLM - Port Angeles - United States",
            "searchName": "CLM-Fairchild Intl-Fairchild International-Port Angeles-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLI",
            "countryCode": "US",
            "cityCode": "CLI",
            "cityName": "Clintonville",
            "name": "[CLI] - Clintonville - CLI - Clintonville - United States",
            "searchName": "CLI-Clintonville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLR",
            "countryCode": "US",
            "cityCode": "CLR",
            "cityName": "Calipatria",
            "name": "[CLR] - Calipatria - CLR - Calipatria - United States",
            "searchName": "CLR-Calipatria-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLS",
            "countryCode": "US",
            "cityCode": "CLS",
            "cityName": "Chehalis",
            "name": "[CLS] - Centralia - CLS - Chehalis - United States",
            "searchName": "CLS-Centralia-Chehalis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DET",
            "countryCode": "US",
            "cityCode": "DTT",
            "cityName": "Detroit",
            "name": "[DET] - Detroit City - DTT - Detroit - United States",
            "searchName": "DET-DTT-Städtischer Flughafen-Detroit City-Detroit City Apt Havalimani-Detroit-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "YIP",
            "countryCode": "US",
            "cityCode": "DTT",
            "cityName": "Detroit",
            "name": "[YIP] - Willow Run - DTT - Detroit - United States",
            "searchName": "YIP-DTT-Willow Run-Willow Run Havalimani-Detroit-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DUA",
            "countryCode": "US",
            "cityCode": "DUA",
            "cityName": "Durant",
            "name": "[DUA] - Eaker - DUA - Durant - United States",
            "searchName": "DUA-Eaker-Durant-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DUJ",
            "countryCode": "US",
            "cityCode": "DUJ",
            "cityName": "Du Bois",
            "name": "[DUJ] - Du Bois-Jefferson County - DUJ - Du Bois - United States",
            "searchName": "DUJ-Du Bois-Jefferson County-Dubois Jefferson Cty-Dubois-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DUF",
            "countryCode": "US",
            "cityCode": "DUF",
            "cityName": "Corolla",
            "name": "[DUF] - Pine Island Airport - DUF - Corolla - United States",
            "searchName": "DUF-Pine Island Airport-Corolla-Duck-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DUG",
            "countryCode": "US",
            "cityCode": "DUG",
            "cityName": "Douglas",
            "name": "[DUG] - Bisbee-Douglas International - DUG - Douglas - United States",
            "searchName": "DUG-Bisbee-Douglas International-Bisbee Douglas Uluslararasi Havalimani-Douglas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DGL",
            "countryCode": "US",
            "cityCode": "DUG",
            "cityName": "Douglas",
            "name": "[DGL] - Douglas Municipal - DUG - Douglas - United States",
            "searchName": "DGL-DUG-Douglas Municipal-Douglas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DUT",
            "countryCode": "US",
            "cityCode": "DUT",
            "cityName": "Dutch Harbor",
            "name": "[DUT] - Emergency Field - DUT - Dutch Harbor - United States",
            "searchName": "DUT-Dutch Harbor-Emergency Field-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CKD",
            "countryCode": "US",
            "cityCode": "CKD",
            "cityName": "Crooked Creek",
            "name": "[CKD] - Crooked Creek - CKD - Crooked Creek - United States",
            "searchName": "CKD-Crooked Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CKE",
            "countryCode": "US",
            "cityCode": "CKE",
            "cityName": "Clear Lake",
            "name": "[CKE] - Clear Lake - CKE - Clear Lake - United States",
            "searchName": "CKE-Clear Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CKA",
            "countryCode": "US",
            "cityCode": "CKA",
            "cityName": "Cherokee",
            "name": "[CKA] - Kegelman Af - CKA - Cherokee - United States",
            "searchName": "CKA-Flugplatz Kegelman-Kegelman Af-Cherokee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CKB",
            "countryCode": "US",
            "cityCode": "CKB",
            "cityName": "Clarksburg",
            "name": "[CKB] - Benedum - CKB - Clarksburg - United States",
            "searchName": "CKB-Clarksburg-Benedum-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CKM",
            "countryCode": "US",
            "cityCode": "CKM",
            "cityName": "Clarksdale",
            "name": "[CKM] - Fletcher Field - CKM - Clarksdale - United States",
            "searchName": "CKM-Fletcher Field-Fletcher Field Havalimani-Clarksdale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CKN",
            "countryCode": "US",
            "cityCode": "CKN",
            "cityName": "Crookston",
            "name": "[CKN] - Municipal - CKN - Crookston - United States",
            "searchName": "CKN-Municipal-Crookston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CKK",
            "countryCode": "US",
            "cityCode": "CKK",
            "cityName": "Cherokee",
            "name": "[CKK] - Cherokee - CKK - Cherokee - United States",
            "searchName": "CKK-Cherokee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CKV",
            "countryCode": "US",
            "cityCode": "CKV",
            "cityName": "Clarksville",
            "name": "[CKV] - Outlaw Field - CKV - Clarksville - United States",
            "searchName": "CKV-Landeplatz Outlaw-Outlaw Field-Outlaw Field Havalimani-Clarksville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CKR",
            "countryCode": "US",
            "cityCode": "CKR",
            "cityName": "Crane Island",
            "name": "[CKR] - Crane Island - CKR - Crane Island - United States",
            "searchName": "CKR-Crane Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CJN",
            "countryCode": "US",
            "cityCode": "CJN",
            "cityName": "El Cajon",
            "name": "[CJN] - Gillespie Field - CJN - El Cajon - United States",
            "searchName": "CJN-Gillespie Field-El Cajon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CIV",
            "countryCode": "US",
            "cityCode": "CIV",
            "cityName": "Chomley",
            "name": "[CIV] - Chomley - CIV - Chomley - United States",
            "searchName": "CIV-Chomley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CKX",
            "countryCode": "US",
            "cityCode": "CKX",
            "cityName": "Chicken",
            "name": "[CKX] - Chicken - CKX - Chicken - United States",
            "searchName": "CKX-Chicken-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DNE",
            "countryCode": "US",
            "cityCode": "DFW",
            "cityName": "Dallas",
            "name": "[DNE] - Dallas North Airport - DFW - Dallas - United States",
            "searchName": "DNE-DFW-Dallas North Airport-Dallas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DAL",
            "countryCode": "US",
            "cityCode": "DFW",
            "cityName": "Dallas",
            "name": "[DAL] - Love Field - DFW - Dallas - United States",
            "searchName": "DAL-DFW-Dallas Love Field-Love Field-Dallas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FWH",
            "countryCode": "US",
            "cityCode": "DFW",
            "cityName": "Dallas",
            "name": "[FWH] - Fort Worth NAS Jrb/Carswell Field - DFW - Dallas - United States",
            "searchName": "FWH-DFW-Fort Worth NAS Jrb/Carswell Field-Dallas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AFW",
            "countryCode": "US",
            "cityCode": "DFW",
            "cityName": "Dallas",
            "name": "[AFW] - Fort Worth Alliance - DFW - Dallas - United States",
            "searchName": "AFW-DFW-Fort Worth Alliance-Fort Worth Alliance Havalimani-Dallas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ADS",
            "countryCode": "US",
            "cityCode": "DFW",
            "cityName": "Dallas",
            "name": "[ADS] - Addison Airport - DFW - Dallas - United States",
            "searchName": "ADS-DFW-Addison Airport-Addison Havalimani-Dallas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JNH",
            "countryCode": "US",
            "cityCode": "DFW",
            "cityName": "Dallas",
            "name": "[JNH] - North Park Inn Heliport - DFW - Dallas - United States",
            "searchName": "JNH-DFW-North Park Inn Heliport-North Park INN-Dallas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JMD",
            "countryCode": "US",
            "cityCode": "DFW",
            "cityName": "Dallas",
            "name": "[JMD] - Market Centre Heliport - DFW - Dallas - United States",
            "searchName": "JMD-DFW-Market Centre Heliport-Dallas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JDB",
            "countryCode": "US",
            "cityCode": "DFW",
            "cityName": "Dallas",
            "name": "[JDB] - Downtown Heliport - DFW - Dallas - United States",
            "searchName": "JDB-DFW-Downtown Heliport-Dallas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RBD",
            "countryCode": "US",
            "cityCode": "DFW",
            "cityName": "Dallas",
            "name": "[RBD] - Redbird - DFW - Dallas - United States",
            "searchName": "RBD-DFW-Redbird-Dallas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DEC",
            "countryCode": "US",
            "cityCode": "DEC",
            "cityName": "Decatur",
            "name": "[DEC] - Decatur Airport - DEC - Decatur - United States",
            "searchName": "DEC-Decatur-Decatur Airport-Decatur Arpt-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DCU",
            "countryCode": "US",
            "cityCode": "DCU",
            "cityName": "Decatur",
            "name": "[DCU] - Pyor - DCU - Decatur - United States",
            "searchName": "DCU-Pyor-Pyor Havalimani-Decatur-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DDC",
            "countryCode": "US",
            "cityCode": "DDC",
            "cityName": "Dodge City",
            "name": "[DDC] - Dodge City Municipal - DDC - Dodge City - United States",
            "searchName": "DDC-Dodge City-Dodge City Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DBN",
            "countryCode": "US",
            "cityCode": "DBN",
            "cityName": "Dublin",
            "name": "[DBN] - Municipal - DBN - Dublin - United States",
            "searchName": "DBN-Municipal-Dublin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DBQ",
            "countryCode": "US",
            "cityCode": "DBQ",
            "cityName": "Dubuque",
            "name": "[DBQ] - Municipal - DBQ - Dubuque - United States",
            "searchName": "DBQ-Dubuque-Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DBS",
            "countryCode": "US",
            "cityCode": "DBS",
            "cityName": "Dubois",
            "name": "[DBS] - Dubois - DBS - Dubois - United States",
            "searchName": "DBS-Dubois-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DCK",
            "countryCode": "US",
            "cityCode": "DCK",
            "cityName": "Dahl Creek",
            "name": "[DCK] - Dahl Creek Airport - DCK - Dahl Creek - United States",
            "searchName": "DCK-Dahl Creek Airport-Dahl Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DOF",
            "countryCode": "US",
            "cityCode": "DOF",
            "cityName": "Dora Bay",
            "name": "[DOF] - Dora Bay - DOF - Dora Bay - United States",
            "searchName": "DOF-Dora Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DPG",
            "countryCode": "US",
            "cityCode": "DPG",
            "cityName": "Dugway",
            "name": "[DPG] - Michael AAF - DPG - Dugway - United States",
            "searchName": "DPG-Militärflughafen Michael-Michael AAF-Dugway-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DNV",
            "countryCode": "US",
            "cityCode": "DNV",
            "cityName": "Danville",
            "name": "[DNV] - Vermilion County - DNV - Danville - United States",
            "searchName": "DNV-Vermilion County-Vermillion Cty Havalimani-Danville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DNS",
            "countryCode": "US",
            "cityCode": "DNS",
            "cityName": "Denison",
            "name": "[DNS] - Municipal - DNS - Denison - United States",
            "searchName": "DNS-Municipal-Denison-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DNN",
            "countryCode": "US",
            "cityCode": "DNN",
            "cityName": "Dalton",
            "name": "[DNN] - Municipal - DNN - Dalton - United States",
            "searchName": "DNN-Municipal-Dalton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "QJJ",
            "countryCode": "US",
            "cityCode": "DNN",
            "cityName": "Dalton",
            "name": "[QJJ] - FlightLink Bus Station - DNN - Dalton - United States",
            "searchName": "QJJ-DNN-FlightLink Bus Station-Dalton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DRO",
            "countryCode": "US",
            "cityCode": "DRO",
            "cityName": "Durango",
            "name": "[DRO] - La Plata - DRO - Durango - United States",
            "searchName": "DRO-Durango USA-La Plata-La Plata County-Durango-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AMK",
            "countryCode": "US",
            "cityCode": "DRO",
            "cityName": "Durango",
            "name": "[AMK] - Animas Airpark - DRO - Durango - United States",
            "searchName": "AMK-DRO-Animas Airpark-Animas Airpark Havalimani-Durango-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DRT",
            "countryCode": "US",
            "cityCode": "DRT",
            "cityName": "Del Rio",
            "name": "[DRT] - Del Rio International - DRT - Del Rio - United States",
            "searchName": "DRT-Del Rio-Del Rio International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DLF",
            "countryCode": "US",
            "cityCode": "DRT",
            "cityName": "Del Rio",
            "name": "[DLF] - Laughlin AFB - DRT - Del Rio - United States",
            "searchName": "DLF-DRT-Laughlin AFB-Del Rio-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DRU",
            "countryCode": "US",
            "cityCode": "DRU",
            "cityName": "Drummond",
            "name": "[DRU] - Drummond - DRU - Drummond - United States",
            "searchName": "DRU-Drummond-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DSI",
            "countryCode": "US",
            "cityCode": "DSI",
            "cityName": "Destin",
            "name": "[DSI] - Destin-Fort Walton Beach Airport - DSI - Destin - United States",
            "searchName": "DSI-Destin-Fort Walton Beach Airport-Destin Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DOV",
            "countryCode": "US",
            "cityCode": "DOV",
            "cityName": "Dover-Cheswold",
            "name": "[DOV] - Dover AFB - DOV - Dover-Cheswold - United States",
            "searchName": "DOV-Dover AFB-Dover-Cheswold-DOVER-CHESWOLD-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DRA",
            "countryCode": "US",
            "cityCode": "DRA",
            "cityName": "Mercury",
            "name": "[DRA] - Desert Rock - DRA - Mercury - United States",
            "searchName": "DRA-Desert Rock-Mercury-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DRI",
            "countryCode": "US",
            "cityCode": "DRI",
            "cityName": "De Ridder",
            "name": "[DRI] - Beauregard Parish - DRI - De Ridder - United States",
            "searchName": "DRI-Airport Beauregard Parish-Beauregard Parish-De Ridder-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DRE",
            "countryCode": "US",
            "cityCode": "DRE",
            "cityName": "Drummond Island",
            "name": "[DRE] - Drummond Island - DRE - Drummond Island - United States",
            "searchName": "DRE-Drummond Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DRF",
            "countryCode": "US",
            "cityCode": "DRF",
            "cityName": "Drift River",
            "name": "[DRF] - Drift River - DRF - Drift River - United States",
            "searchName": "DRF-Drift River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DRG",
            "countryCode": "US",
            "cityCode": "DRG",
            "cityName": "Deering",
            "name": "[DRG] - Deering - DRG - Deering - United States",
            "searchName": "DRG-Deering-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DYL",
            "countryCode": "US",
            "cityCode": "DYL",
            "cityName": "Doylestown",
            "name": "[DYL] - Doylestown - DYL - Doylestown - United States",
            "searchName": "DYL-Doylestown-Doylestown Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EAN",
            "countryCode": "US",
            "cityCode": "EAN",
            "cityName": "Wheatland",
            "name": "[EAN] - Phifer Field - EAN - Wheatland - United States",
            "searchName": "EAN-Phifer Field-Wheatland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EAA",
            "countryCode": "US",
            "cityCode": "EAA",
            "cityName": "Eagle",
            "name": "[EAA] - Eagle - EAA - Eagle - United States",
            "searchName": "EAA-Eagle-Eagle Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EAR",
            "countryCode": "US",
            "cityCode": "EAR",
            "cityName": "Kearney",
            "name": "[EAR] - Kearney - EAR - Kearney - United States",
            "searchName": "EAR-Kearney-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DVL",
            "countryCode": "US",
            "cityCode": "DVL",
            "cityName": "Devils Lake",
            "name": "[DVL] - Devils Lake - DVL - Devils Lake - United States",
            "searchName": "DVL-Devils Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DVN",
            "countryCode": "US",
            "cityCode": "DVN",
            "cityName": "Davenport",
            "name": "[DVN] - Davenport - DVN - Davenport - United States",
            "searchName": "DVN-Davenport-Davenport Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DXR",
            "countryCode": "US",
            "cityCode": "DXR",
            "cityName": "Danbury",
            "name": "[DXR] - Danbury Municipal Airport - DXR - Danbury - United States",
            "searchName": "DXR-Danbury Municipal Airport-Danbury  Havalimani-Danbury-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DSM",
            "countryCode": "US",
            "cityCode": "DSM",
            "cityName": "Des Moines",
            "name": "[DSM] - Des Moines International - DSM - Des Moines - United States",
            "searchName": "DSM-Des Moines-Des Moines International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DTH",
            "countryCode": "US",
            "cityCode": "DTH",
            "cityName": "Death Valley",
            "name": "[DTH] - Death Valley - DTH - Death Valley - United States",
            "searchName": "DTH-Death Valley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DSV",
            "countryCode": "US",
            "cityCode": "DSV",
            "cityName": "Dansville",
            "name": "[DSV] - Dansville - DSV - Dansville - United States",
            "searchName": "DSV-Dansville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DTA",
            "countryCode": "US",
            "cityCode": "DTA",
            "cityName": "Delta",
            "name": "[DTA] - Delta - DTA - Delta - United States",
            "searchName": "DTA-Delta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DUC",
            "countryCode": "US",
            "cityCode": "DUC",
            "cityName": "Duncan",
            "name": "[DUC] - Halliburton Field - DUC - Duncan - United States",
            "searchName": "DUC-Halliburton Field-Halliburton Havalimani-Duncan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DTL",
            "countryCode": "US",
            "cityCode": "DTL",
            "cityName": "Detroit Lakes",
            "name": "[DTL] - Municipal - DTL - Detroit Lakes - United States",
            "searchName": "DTL-Municipal-Detroit Lakes-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DTR",
            "countryCode": "US",
            "cityCode": "DTR",
            "cityName": "Decatur Island",
            "name": "[DTR] - Decatur Island - DTR - Decatur Island - United States",
            "searchName": "DTR-Decatur Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JBC",
            "countryCode": "US",
            "cityCode": "BOS",
            "cityName": "Boston",
            "name": "[JBC] - Boston City Heliport - BOS - Boston - United States",
            "searchName": "JBC-BOS-Boston City Heliport-Boston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZTO",
            "countryCode": "US",
            "cityCode": "BOS",
            "cityName": "Boston",
            "name": "[ZTO] - Boston South Railway - BOS - Boston - United States",
            "searchName": "ZTO-BOS-Boston South Railway-Boston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZTY",
            "countryCode": "US",
            "cityCode": "BOS",
            "cityName": "Boston",
            "name": "[ZTY] - Boston BKBAY Railway - BOS - Boston - United States",
            "searchName": "ZTY-BOS-Boston BKBAY Railway-Boston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZRU",
            "countryCode": "US",
            "cityCode": "BOS",
            "cityName": "Boston",
            "name": "[ZRU] - Boston RT128 Railway - BOS - Boston - United States",
            "searchName": "ZRU-BOS-Boston RT128 Railway-Boston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PSM",
            "countryCode": "US",
            "cityCode": "BOS",
            "cityName": "Boston",
            "name": "[PSM] - Pease International - BOS - Boston - United States",
            "searchName": "PSM-BOS-Luftwaffenstützpunkt Portsmouth Pease-Pease International-Boston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EAT",
            "countryCode": "US",
            "cityCode": "EAT",
            "cityName": "Wenatchee",
            "name": "[EAT] - Pangborn Field - EAT - Wenatchee - United States",
            "searchName": "EAT-Wenatchee-Pangborn Field-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EAU",
            "countryCode": "US",
            "cityCode": "EAU",
            "cityName": "Eau Claire",
            "name": "[EAU] - Chippewa Valley Regional Airport - EAU - Eau Claire - United States",
            "searchName": "EAU-Eau Claire-Chippewa Valley Regional Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EBS",
            "countryCode": "US",
            "cityCode": "EBS",
            "cityName": "Webster City",
            "name": "[EBS] - Municipal - EBS - Webster City - United States",
            "searchName": "EBS-Municipal-Webster City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EDK",
            "countryCode": "US",
            "cityCode": "EDK",
            "cityName": "El Dorado",
            "name": "[EDK] - El Dorado - EDK - El Dorado - United States",
            "searchName": "EDK-El Dorado-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EDE",
            "countryCode": "US",
            "cityCode": "EDE",
            "cityName": "Edenton",
            "name": "[EDE] - Municipal - EDE - Edenton - United States",
            "searchName": "EDE-Municipal-Edenton  Havalimani-Edenton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EDG",
            "countryCode": "US",
            "cityCode": "EDG",
            "cityName": "Edgewood",
            "name": "[EDG] - Weide AAF - EDG - Edgewood - United States",
            "searchName": "EDG-Weide AAF-Edgewood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ECA",
            "countryCode": "US",
            "cityCode": "ECA",
            "cityName": "East Tawas",
            "name": "[ECA] - Emmet County Airport - ECA - East Tawas - United States",
            "searchName": "ECA-Emmet County Airport-East Tawas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ECG",
            "countryCode": "US",
            "cityCode": "ECG",
            "cityName": "Elizabeth City",
            "name": "[ECG] - Elizabeth City - ECG - Elizabeth City - United States",
            "searchName": "ECG-Elizabeth City-Elizabeth BelediyesiCgas Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ECS",
            "countryCode": "US",
            "cityCode": "ECS",
            "cityName": "Newcastle",
            "name": "[ECS] - Mondell - ECS - Newcastle - United States",
            "searchName": "ECS-Mondell-Newcastle-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EDA",
            "countryCode": "US",
            "cityCode": "EDA",
            "cityName": "Edna Bay",
            "name": "[EDA] - Edna Bay - EDA - Edna Bay - United States",
            "searchName": "EDA-Edna Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EGP",
            "countryCode": "US",
            "cityCode": "EGP",
            "cityName": "Eagle Pass",
            "name": "[EGP] - Maverick Co - EGP - Eagle Pass - United States",
            "searchName": "EGP-Maverick Co-Maverick County Havalimani-Eagle Pass-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EGV",
            "countryCode": "US",
            "cityCode": "EGV",
            "cityName": "Eagle River",
            "name": "[EGV] - Eagle River - EGV - Eagle River - United States",
            "searchName": "EGV-Eagle River-Eagle River Union Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EGX",
            "countryCode": "US",
            "cityCode": "EGX",
            "cityName": "Egegik",
            "name": "[EGX] - Egegik - EGX - Egegik - United States",
            "searchName": "EGX-Egegik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EHM",
            "countryCode": "US",
            "cityCode": "EHM",
            "cityName": "Cape Newenham",
            "name": "[EHM] - Cape Newenham - EHM - Cape Newenham - United States",
            "searchName": "EHM-Cape Newenham-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EHT",
            "countryCode": "US",
            "cityCode": "EHT",
            "cityName": "East Hartford",
            "name": "[EHT] - Rentschler - EHT - East Hartford - United States",
            "searchName": "EHT-Rentschler-Rentschler Havalimani-East Hartford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EFK",
            "countryCode": "US",
            "cityCode": "EFK",
            "cityName": "Newport",
            "name": "[EFK] - Newport - EFK - Newport - United States",
            "searchName": "EFK-Newport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EFO",
            "countryCode": "US",
            "cityCode": "EFO",
            "cityName": "East Fork",
            "name": "[EFO] - East Fork - EFO - East Fork - United States",
            "searchName": "EFO-East Fork-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EFW",
            "countryCode": "US",
            "cityCode": "EFW",
            "cityName": "Jefferson",
            "name": "[EFW] - Municipal - EFW - Jefferson - United States",
            "searchName": "EFW-Municipal-Jefferson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EGE",
            "countryCode": "US",
            "cityCode": "EGE",
            "cityName": "Vail",
            "name": "[EGE] - Eagle County - EGE - Vail - United States",
            "searchName": "EGE-Vail Eagle-Eagle County-Eagle County Airport-Vail/Eagle-Vail-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZBV",
            "countryCode": "US",
            "cityCode": "EGE",
            "cityName": "Vail",
            "name": "[ZBV] - Beaver Creek Bus Station - EGE - Vail - United States",
            "searchName": "ZBV-EGE-Beaver Creek Bus Station-Vail/Eagle-Vail-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "QBF",
            "countryCode": "US",
            "cityCode": "EGE",
            "cityName": "Vail",
            "name": "[QBF] - Vail Bus Station - EGE - Vail - United States",
            "searchName": "QBF-EGE-Vail Bus Station-Vail Van Service Havalimani-Vail/Eagle-Vail-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EEN",
            "countryCode": "US",
            "cityCode": "EEN",
            "cityName": "Keene",
            "name": "[EEN] - Dillant-Hopkins - EEN - Keene - United States",
            "searchName": "EEN-Dillant Hopkins-Dillant-Hopkins-Keene NH-Keene-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EFB",
            "countryCode": "US",
            "cityCode": "EFB",
            "cityName": "Eight Fathom Bight",
            "name": "[EFB] - Eight Fathom Bight - EFB - Eight Fathom Bight - United States",
            "searchName": "EFB-Eight Fathom Bight-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EDW",
            "countryCode": "US",
            "cityCode": "EDW",
            "cityName": "Edwards",
            "name": "[EDW] - Edwards AFB - EDW - Edwards - United States",
            "searchName": "EDW-Edwards AFB-Edwards-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EED",
            "countryCode": "US",
            "cityCode": "EED",
            "cityName": "Needles",
            "name": "[EED] - Needles - EED - Needles - United States",
            "searchName": "EED-Needles-Needles Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EEK",
            "countryCode": "US",
            "cityCode": "EEK",
            "cityName": "Eek",
            "name": "[EEK] - Eek - EEK - Eek - United States",
            "searchName": "EEK-Eek-Eek Arpt-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EKX",
            "countryCode": "US",
            "cityCode": "EKX",
            "cityName": "Elizabethtown",
            "name": "[EKX] - Elizabethtown - EKX - Elizabethtown - United States",
            "searchName": "EKX-Elizabethtown-Addington Field Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ELA",
            "countryCode": "US",
            "cityCode": "ELA",
            "cityName": "Eagle Lake",
            "name": "[ELA] - Eagle Lake - ELA - Eagle Lake - United States",
            "searchName": "ELA-Eagle Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ELD",
            "countryCode": "US",
            "cityCode": "ELD",
            "cityName": "El Dorado",
            "name": "[ELD] - Goodwin Field - ELD - El Dorado - United States",
            "searchName": "ELD-Goodwin Field-El Dorado-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EKA",
            "countryCode": "US",
            "cityCode": "EKA",
            "cityName": "Eureka",
            "name": "[EKA] - Murray Field - EKA - Eureka - United States",
            "searchName": "EKA-Landeplatz Murray-Murray Field-Eureka-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EKI",
            "countryCode": "US",
            "cityCode": "EKI",
            "cityName": "Elkhart",
            "name": "[EKI] - Municipal - EKI - Elkhart - United States",
            "searchName": "EKI-Municipal-Elkhart  Havalimani-Elkhart-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EKN",
            "countryCode": "US",
            "cityCode": "EKN",
            "cityName": "Elkins",
            "name": "[EKN] - Elkins - EKN - Elkins - United States",
            "searchName": "EKN-Elkins-Randolph County Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EKO",
            "countryCode": "US",
            "cityCode": "EKO",
            "cityName": "Elko",
            "name": "[EKO] - Elko Regional - EKO - Elko - United States",
            "searchName": "EKO-Elko-Elko Regional-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ELK",
            "countryCode": "US",
            "cityCode": "ELK",
            "cityName": "Elk City",
            "name": "[ELK] - Municipal - ELK - Elk City - United States",
            "searchName": "ELK-Municipal-Elk City Belediyesi Havalimani-Elk City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ELI",
            "countryCode": "US",
            "cityCode": "ELI",
            "cityName": "Elim",
            "name": "[ELI] - Elim - ELI - Elim - United States",
            "searchName": "ELI-Elim-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ELP",
            "countryCode": "US",
            "cityCode": "ELP",
            "cityName": "El Paso",
            "name": "[ELP] - El Paso International Airport - ELP - El Paso - United States",
            "searchName": "ELP-El Paso-El Paso International Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BIF",
            "countryCode": "US",
            "cityCode": "ELP",
            "cityName": "El Paso",
            "name": "[BIF] - Biggs AAF - ELP - El Paso - United States",
            "searchName": "BIF-ELP-Biggs AAF-Biggs Army Air Field Havalimani-El Paso-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ELM",
            "countryCode": "US",
            "cityCode": "ELM",
            "cityName": "Elmira",
            "name": "[ELM] - Elmira Corning Regional - ELM - Elmira - United States",
            "searchName": "ELM-Elmira-Elmira Corning Regional-Corning-Corning/Elmira-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ELN",
            "countryCode": "US",
            "cityCode": "ELN",
            "cityName": "Ellensburg",
            "name": "[ELN] - Bowers Field - ELN - Ellensburg - United States",
            "searchName": "ELN-Bowers Field-Ellensburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ENA",
            "countryCode": "US",
            "cityCode": "ENA",
            "cityName": "Kenai",
            "name": "[ENA] - Kenai - ENA - Kenai - United States",
            "searchName": "ENA-Kenai-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ENL",
            "countryCode": "US",
            "cityCode": "ENL",
            "cityName": "Centralia",
            "name": "[ENL] - Municipal - ENL - Centralia - United States",
            "searchName": "ENL-Municipal-Centralia  Havalimani-Centralia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ENN",
            "countryCode": "US",
            "cityCode": "ENN",
            "cityName": "Nenana",
            "name": "[ENN] - Municipal - ENN - Nenana - United States",
            "searchName": "ENN-Municipal-Nenana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ENV",
            "countryCode": "US",
            "cityCode": "ENV",
            "cityName": "Wendover",
            "name": "[ENV] - Wendover - ENV - Wendover - United States",
            "searchName": "ENV-Wendover-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ENW",
            "countryCode": "US",
            "cityCode": "ENW",
            "cityName": "Kenosha",
            "name": "[ENW] - Kenosha Regional Airport - ENW - Kenosha - United States",
            "searchName": "ENW-Kommunaler Flughafen-Kenosha Regional Airport-Kenosha  Havalimani-Kenosha-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EMK",
            "countryCode": "US",
            "cityCode": "EMK",
            "cityName": "Emmonak",
            "name": "[EMK] - Emmonak - EMK - Emmonak - United States",
            "searchName": "EMK-Emmonak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EMM",
            "countryCode": "US",
            "cityCode": "EMM",
            "cityName": "Kemerer",
            "name": "[EMM] - Kemerer - EMM - Kemerer - United States",
            "searchName": "EMM-Kemerer-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EMT",
            "countryCode": "US",
            "cityCode": "EMT",
            "cityName": "El Monte",
            "name": "[EMT] - El Monte - EMT - El Monte - United States",
            "searchName": "EMT-El Monte-El Monte Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EMP",
            "countryCode": "US",
            "cityCode": "EMP",
            "cityName": "Emporia",
            "name": "[EMP] - Emporia - EMP - Emporia - United States",
            "searchName": "EMP-Emporia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ELV",
            "countryCode": "US",
            "cityCode": "ELV",
            "cityName": "Elfin Cove",
            "name": "[ELV] - Elfin Cove SPB - ELV - Elfin Cove - United States",
            "searchName": "ELV-Elfin Cove SPB-Elfin Cove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ELW",
            "countryCode": "US",
            "cityCode": "ELW",
            "cityName": "Ellamar",
            "name": "[ELW] - Ellamar - ELW - Ellamar - United States",
            "searchName": "ELW-Ellamar-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ELY",
            "countryCode": "US",
            "cityCode": "ELY",
            "cityName": "Ely",
            "name": "[ELY] - Yelland - ELY - Ely - United States",
            "searchName": "ELY-Ely Nevada-Yelland-Yelland Field-Yelland Field Havalimani-Ely-Ely Nv-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ELZ",
            "countryCode": "US",
            "cityCode": "ELZ",
            "cityName": "Wellsville",
            "name": "[ELZ] - Municipal - ELZ - Wellsville - United States",
            "searchName": "ELZ-Municipal-Wellsville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ERI",
            "countryCode": "US",
            "cityCode": "ERI",
            "cityName": "Erie",
            "name": "[ERI] - International - ERI - Erie - United States",
            "searchName": "ERI-Erie-International--Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ERO",
            "countryCode": "US",
            "cityCode": "ERO",
            "cityName": "Eldred Rock",
            "name": "[ERO] - Coast Guard - ERO - Eldred Rock - United States",
            "searchName": "ERO-Hubschrauberlandeplatz der Küstenwache-Coast Guard-Eldred Rock-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ERV",
            "countryCode": "US",
            "cityCode": "ERV",
            "cityName": "Kerrville",
            "name": "[ERV] - Kerrville - ERV - Kerrville - United States",
            "searchName": "ERV-Kerrville-Kerrville Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ERR",
            "countryCode": "US",
            "cityCode": "ERR",
            "cityName": "Errol",
            "name": "[ERR] - Errol - ERR - Errol - United States",
            "searchName": "ERR-Errol-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EOS",
            "countryCode": "US",
            "cityCode": "EOS",
            "cityName": "Neosho",
            "name": "[EOS] - Neosho - EOS - Neosho - United States",
            "searchName": "EOS-Neosho-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EOK",
            "countryCode": "US",
            "cityCode": "EOK",
            "cityName": "Keokuk",
            "name": "[EOK] - Keokuk - EOK - Keokuk - United States",
            "searchName": "EOK-Keokuk-Keokuk Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EPG",
            "countryCode": "US",
            "cityCode": "EPG",
            "cityName": "Weeping Water",
            "name": "[EPG] - Browns - EPG - Weeping Water - United States",
            "searchName": "EPG-Browns-Weeping Water-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EPH",
            "countryCode": "US",
            "cityCode": "EPH",
            "cityName": "Ephrata",
            "name": "[EPH] - Ephrata - EPH - Ephrata - United States",
            "searchName": "EPH-Ephrata-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ESC",
            "countryCode": "US",
            "cityCode": "ESC",
            "cityName": "Escanaba",
            "name": "[ESC] - Delta County Airport - ESC - Escanaba - United States",
            "searchName": "ESC-Escanaba-Delta County Airport-Delta County Arpt-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ESD",
            "countryCode": "US",
            "cityCode": "ESD",
            "cityName": "Eastsound",
            "name": "[ESD] - Orcas Island - ESD - Eastsound - United States",
            "searchName": "ESD-Orcas Island-Orcas Island Havalimani-Eastsound-East Sound-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ESW",
            "countryCode": "US",
            "cityCode": "ESW",
            "cityName": "Easton",
            "name": "[ESW] - State - ESW - Easton - United States",
            "searchName": "ESW-Regierungsflughafen-State-Easton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ETB",
            "countryCode": "US",
            "cityCode": "ETB",
            "cityName": "West Bend",
            "name": "[ETB] - West Bend - ETB - West Bend - United States",
            "searchName": "ETB-West Bend-West Bend Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EST",
            "countryCode": "US",
            "cityCode": "EST",
            "cityName": "Estherville",
            "name": "[EST] - Municipal - EST - Estherville - United States",
            "searchName": "EST-Municipal-Estherville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ESN",
            "countryCode": "US",
            "cityCode": "ESN",
            "cityName": "Easton",
            "name": "[ESN] - Easton - ESN - Easton - United States",
            "searchName": "ESN-Easton-Easton  Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ESO",
            "countryCode": "US",
            "cityCode": "ESO",
            "cityName": "Espanola",
            "name": "[ESO] - Espanola - ESO - Espanola - United States",
            "searchName": "ESO-Espanola-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ESP",
            "countryCode": "US",
            "cityCode": "ESP",
            "cityName": "East Stroudsburg",
            "name": "[ESP] - Birchwood-Pocono - ESP - East Stroudsburg - United States",
            "searchName": "ESP-Birchwood-Pocono-Birchwood Pocono Havalimani-East Stroudsburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ETN",
            "countryCode": "US",
            "cityCode": "ETN",
            "cityName": "Eastland",
            "name": "[ETN] - Municipal - ETN - Eastland - United States",
            "searchName": "ETN-Municipal-Eastland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ETS",
            "countryCode": "US",
            "cityCode": "ETS",
            "cityName": "Enterprise",
            "name": "[ETS] - Municipal - ETS - Enterprise - United States",
            "searchName": "ETS-Municipal-Enterprise Belediyesi Havalimani-Enterprise-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EUE",
            "countryCode": "US",
            "cityCode": "EUE",
            "cityName": "Eureka",
            "name": "[EUE] - Eureka - EUE - Eureka - United States",
            "searchName": "EUE-Eureka-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EUF",
            "countryCode": "US",
            "cityCode": "EUF",
            "cityName": "Eufaula",
            "name": "[EUF] - Weedon Field - EUF - Eufaula - United States",
            "searchName": "EUF-Weedon Field-Weedon Field Havalimani-Eufaula-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EUG",
            "countryCode": "US",
            "cityCode": "EUG",
            "cityName": "Eugene",
            "name": "[EUG] - Eugene - EUG - Eugene - United States",
            "searchName": "EUG-Eugene-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EYW",
            "countryCode": "US",
            "cityCode": "EYW",
            "cityName": "Key West",
            "name": "[EYW] - International - EYW - Key West - United States",
            "searchName": "EYW-Key West-International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NQX",
            "countryCode": "US",
            "cityCode": "EYW",
            "cityName": "Key West",
            "name": "[NQX] - Key West NAS /Boca Chica Field (private U. S. Navy ) - EYW - Key West - United States",
            "searchName": "NQX-EYW-Key West NAS /Boca Chica Field (private U. S. Navy )-Key West-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EYR",
            "countryCode": "US",
            "cityCode": "EYR",
            "cityName": "Yerington",
            "name": "[EYR] - Yerington - EYR - Yerington - United States",
            "searchName": "EYR-Yerington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZRP",
            "countryCode": "US",
            "cityCode": "EWR",
            "cityName": "Newark",
            "name": "[ZRP] - Pennsylvania Railroad Station - EWR - Newark - United States",
            "searchName": "ZRP-EWR-Pennsylvania Railroad Station-Newark-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZME",
            "countryCode": "US",
            "cityCode": "EWR",
            "cityName": "Newark",
            "name": "[ZME] - Metropark Railway - EWR - Newark - United States",
            "searchName": "ZME-EWR-Metropark Railway-Newark-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EXI",
            "countryCode": "US",
            "cityCode": "EXI",
            "cityName": "Excursion Inlet",
            "name": "[EXI] - SPB - EXI - Excursion Inlet - United States",
            "searchName": "EXI-SPB-Excursion Inlet Belediyesi Havalimani-Excursion Inlet-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EVM",
            "countryCode": "US",
            "cityCode": "EVM",
            "cityName": "Eveleth",
            "name": "[EVM] - Eveleth - EVM - Eveleth - United States",
            "searchName": "EVM-Eveleth-Eveleth Virginia  Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EWK",
            "countryCode": "US",
            "cityCode": "EWK",
            "cityName": "Newton",
            "name": "[EWK] - City-County - EWK - Newton - United States",
            "searchName": "EWK-City-County-Newton City County Havalimani-Newton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EWN",
            "countryCode": "US",
            "cityCode": "EWN",
            "cityName": "New Bern",
            "name": "[EWN] - Simmons Nott - EWN - New Bern - United States",
            "searchName": "EWN-New Bern-Simmons Nott-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EWB",
            "countryCode": "US",
            "cityCode": "EWB",
            "cityName": "New Bedford",
            "name": "[EWB] - New Bedford - EWB - New Bedford - United States",
            "searchName": "EWB-New Bedford-Bedford/Fall River-Fall River/New Bedford-Fall River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EWD",
            "countryCode": "US",
            "cityCode": "EWD",
            "cityName": "Wildman Lake",
            "name": "[EWD] - Wildman Lake - EWD - Wildman Lake - United States",
            "searchName": "EWD-Wildman Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EVV",
            "countryCode": "US",
            "cityCode": "EVV",
            "cityName": "Evansville",
            "name": "[EVV] - Evansville Regional Airport - EVV - Evansville - United States",
            "searchName": "EVV-Evansville-Evansville Regional Airport-Dress Regional-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EVW",
            "countryCode": "US",
            "cityCode": "EVW",
            "cityName": "Evanston",
            "name": "[EVW] - Evanston - EVW - Evanston - United States",
            "searchName": "EVW-Evanston-Evanston Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FAR",
            "countryCode": "US",
            "cityCode": "FAR",
            "cityName": "Fargo",
            "name": "[FAR] - Hector Field - FAR - Fargo - United States",
            "searchName": "FAR-Fargo-Hector Field-Fargo North Dakota-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FAY",
            "countryCode": "US",
            "cityCode": "FAY",
            "cityName": "Fayetteville",
            "name": "[FAY] - Municipal - FAY - Fayetteville - United States",
            "searchName": "FAY-Fayetteville Regional-Municipal-Fayetteville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "POB",
            "countryCode": "US",
            "cityCode": "FAY",
            "cityName": "Fayetteville",
            "name": "[POB] - Pope AFB - FAY - Fayetteville - United States",
            "searchName": "POB-FAY-Pope AFB-Fayetteville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FAK",
            "countryCode": "US",
            "cityCode": "FAK",
            "cityName": "False Island",
            "name": "[FAK] - False Island - FAK - False Island - United States",
            "searchName": "FAK-False Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FAM",
            "countryCode": "US",
            "cityCode": "FAM",
            "cityName": "Farmington",
            "name": "[FAM] - Regional - FAM - Farmington - United States",
            "searchName": "FAM-Regional-Farmington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FAF",
            "countryCode": "US",
            "cityCode": "FAF",
            "cityName": "Fort Eustis",
            "name": "[FAF] - Felker AAF - FAF - Fort Eustis - United States",
            "searchName": "FAF-Militärflughafen Felker-Felker AAF-Fort Eustis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FAI",
            "countryCode": "US",
            "cityCode": "FAI",
            "cityName": "Fairbanks",
            "name": "[FAI] - Fairbanks International Airport - FAI - Fairbanks - United States",
            "searchName": "FAI-Fairbanks-Fairbanks International Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FBK",
            "countryCode": "US",
            "cityCode": "FAI",
            "cityName": "Fairbanks",
            "name": "[FBK] - Ft Wainwright - FAI - Fairbanks - United States",
            "searchName": "FBK-FAI-Ft Wainwright-Fairbanks-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EIL",
            "countryCode": "US",
            "cityCode": "FAI",
            "cityName": "Fairbanks",
            "name": "[EIL] - Eielson AFB - FAI - Fairbanks - United States",
            "searchName": "EIL-FAI-Eielson AFB-Fairbanks-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MTX",
            "countryCode": "US",
            "cityCode": "FAI",
            "cityName": "Fairbanks",
            "name": "[MTX] - Metro Field - FAI - Fairbanks - United States",
            "searchName": "MTX-FAI-Metro Field-Fairbanks-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PII",
            "countryCode": "US",
            "cityCode": "FAI",
            "cityName": "Fairbanks",
            "name": "[PII] - Phillips Field - FAI - Fairbanks - United States",
            "searchName": "PII-FAI-Phillips Field-Fairbanks-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FCY",
            "countryCode": "US",
            "cityCode": "FCY",
            "cityName": "Forrest City",
            "name": "[FCY] - Municipal - FCY - Forrest City - United States",
            "searchName": "FCY-Municipal-Forrest City  Havalimani-Forrest City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FBR",
            "countryCode": "US",
            "cityCode": "FBR",
            "cityName": "Fort Bridger",
            "name": "[FBR] - Fort Bridger - FBR - Fort Bridger - United States",
            "searchName": "FBR-Fort Bridger-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FBS",
            "countryCode": "US",
            "cityCode": "FBS",
            "cityName": "Friday Harbor",
            "name": "[FBS] - Friday Harbor SPB - FBS - Friday Harbor - United States",
            "searchName": "FBS-Friday Harbor SPB-Friday Harbor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FBY",
            "countryCode": "US",
            "cityCode": "FBY",
            "cityName": "Fairbury",
            "name": "[FBY] - Municipal - FBY - Fairbury - United States",
            "searchName": "FBY-Municipal-Fairbury-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FCA",
            "countryCode": "US",
            "cityCode": "FCA",
            "cityName": "Kalispell",
            "name": "[FCA] - Glacier Park International - FCA - Kalispell - United States",
            "searchName": "FCA-Kalispell-Glacier Park International-Glacier Park-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FAT",
            "countryCode": "US",
            "cityCode": "FAT",
            "cityName": "Fresno",
            "name": "[FAT] - Fresno Air Terminal Airport - FAT - Fresno - United States",
            "searchName": "FAT-Fresno-Fresno Air Terminal Airport-Airterminal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FCH",
            "countryCode": "US",
            "cityCode": "FAT",
            "cityName": "Fresno",
            "name": "[FCH] - Fresno-Chandler - FAT - Fresno - United States",
            "searchName": "FCH-FAT-Fresno-Chandler-Fresno Chandler Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FBG",
            "countryCode": "US",
            "cityCode": "FBG",
            "cityName": "Fort Bragg",
            "name": "[FBG] - Simmons AAF - FBG - Fort Bragg - United States",
            "searchName": "FBG-Simmons AAF-Fort Bragg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FBL",
            "countryCode": "US",
            "cityCode": "FBL",
            "cityName": "Faribault",
            "name": "[FBL] - Municipal - FBL - Faribault - United States",
            "searchName": "FBL-Municipal-Faribault-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DMN",
            "countryCode": "US",
            "cityCode": "DMN",
            "cityName": "Deming",
            "name": "[DMN] - Deming - DMN - Deming - United States",
            "searchName": "DMN-Deming-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DMO",
            "countryCode": "US",
            "cityCode": "DMO",
            "cityName": "Sedalia",
            "name": "[DMO] - Sedalia - DMO - Sedalia - United States",
            "searchName": "DMO-Sedalia-Sedalia Memorial Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DLN",
            "countryCode": "US",
            "cityCode": "DLN",
            "cityName": "Dillon",
            "name": "[DLN] - Dillon - DLN - Dillon - United States",
            "searchName": "DLN-Dillon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DLO",
            "countryCode": "US",
            "cityCode": "DLO",
            "cityName": "Dolomi",
            "name": "[DLO] - Dolomi - DLO - Dolomi - United States",
            "searchName": "DLO-Dolomi-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DLS",
            "countryCode": "US",
            "cityCode": "DLS",
            "cityName": "The Dalles",
            "name": "[DLS] - Columbia Gorge Regional/The Dalles Municipal Airport - DLS - The Dalles - United States",
            "searchName": "DLS-Columbia Gorge Regional/The Dalles Municipal Airport-The Dalles  Havalimani-The Dalles-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DLL",
            "countryCode": "US",
            "cityCode": "DLL",
            "cityName": "Dillon",
            "name": "[DLL] - Dillon - DLL - Dillon - United States",
            "searchName": "DLL-Dillon-Dillon Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DIO",
            "countryCode": "US",
            "cityCode": "DIO",
            "cityName": "Diomede Island",
            "name": "[DIO] - Diomede Island - DIO - Diomede Island - United States",
            "searchName": "DIO-Diomede Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DLG",
            "countryCode": "US",
            "cityCode": "DLG",
            "cityName": "Dillingham",
            "name": "[DLG] - Municipal - DLG - Dillingham - United States",
            "searchName": "DLG-Dillingham-Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DLH",
            "countryCode": "US",
            "cityCode": "DLH",
            "cityName": "Duluth",
            "name": "[DLH] - Duluth International - DLH - Duluth - United States",
            "searchName": "DLH-Duluth-Duluth International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LKI",
            "countryCode": "US",
            "cityCode": "DLH",
            "cityName": "Duluth",
            "name": "[LKI] - Lakeside USAF - DLH - Duluth - United States",
            "searchName": "LKI-DLH-Lakeside USAF-Duluth-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DKK",
            "countryCode": "US",
            "cityCode": "DKK",
            "cityName": "Dunkirk",
            "name": "[DKK] - Dunkirk - DKK - Dunkirk - United States",
            "searchName": "DKK-Dunkirk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DJN",
            "countryCode": "US",
            "cityCode": "DJN",
            "cityName": "Delta Junction",
            "name": "[DJN] - Delta Junction - DJN - Delta Junction - United States",
            "searchName": "DJN-Delta Junction-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DIK",
            "countryCode": "US",
            "cityCode": "DIK",
            "cityName": "Dickinson",
            "name": "[DIK] - Dickinson - DIK - Dickinson - United States",
            "searchName": "DIK-Dickinson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DHB",
            "countryCode": "US",
            "cityCode": "DHB",
            "cityName": "Deer Harbor",
            "name": "[DHB] - Deer Harbor - DHB - Deer Harbor - United States",
            "searchName": "DHB-Deer Harbor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DHT",
            "countryCode": "US",
            "cityCode": "DHT",
            "cityName": "Dalhart",
            "name": "[DHT] - Dalhart - DHT - Dalhart - United States",
            "searchName": "DHT-Dalhart-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ADM",
            "countryCode": "US",
            "cityCode": "ADM",
            "cityName": "Ardmore",
            "name": "[ADM] - Ardmore Municipal Airport - ADM - Ardmore - United States",
            "searchName": "ADM-Ardmore Municipal Airport-Ardmore  Havalimani-Ardmore-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AHD",
            "countryCode": "US",
            "cityCode": "ADM",
            "cityName": "Ardmore",
            "name": "[AHD] - Downtown - ADM - Ardmore - United States",
            "searchName": "AHD-ADM-Downtown-Ardmore-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ADQ",
            "countryCode": "US",
            "cityCode": "ADQ",
            "cityName": "Kodiak",
            "name": "[ADQ] - Kodiak Airport - ADQ - Kodiak - United States",
            "searchName": "ADQ-Kodiak-Kodiak Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KDK",
            "countryCode": "US",
            "cityCode": "ADQ",
            "cityName": "Kodiak",
            "name": "[KDK] - Municipal - ADQ - Kodiak - United States",
            "searchName": "KDK-ADQ-Kommunaler Flughafen-Municipal-Kodiak Belediyesi Havalimani-Kodiak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WDY",
            "countryCode": "US",
            "cityCode": "ADQ",
            "cityName": "Kodiak",
            "name": "[WDY] - Kodiak Fss - ADQ - Kodiak - United States",
            "searchName": "WDY-ADQ-Flugservice-Station-Kodiak Fss-Kodiak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ADR",
            "countryCode": "US",
            "cityCode": "ADR",
            "cityName": "Andrews",
            "name": "[ADR] - Andrews - ADR - Andrews - United States",
            "searchName": "ADR-Andrews-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ADT",
            "countryCode": "US",
            "cityCode": "ADT",
            "cityName": "Ada",
            "name": "[ADT] - Ada Municipal - ADT - Ada - United States",
            "searchName": "ADT-Ada Municipal-Ada  Havalimani-Ada-Ada OK-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AEX",
            "countryCode": "US",
            "cityCode": "AEX",
            "cityName": "Alexandria",
            "name": "[AEX] - Alexandria International - AEX - Alexandria - United States",
            "searchName": "AEX-Alexandria Intl USA-Alexandria International-Alexandria-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ESF",
            "countryCode": "US",
            "cityCode": "AEX",
            "cityName": "Alexandria",
            "name": "[ESF] - Esler Field - AEX - Alexandria - United States",
            "searchName": "ESF-AEX-Landeplatz Esler-Esler Field-Esler Field Havalimani-Alexandria-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AFN",
            "countryCode": "US",
            "cityCode": "AFN",
            "cityName": "Jaffrey",
            "name": "[AFN] - Municipal - AFN - Jaffrey - United States",
            "searchName": "AFN-Municipal-Jaffrey airport-Jaffrey-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AFO",
            "countryCode": "US",
            "cityCode": "AFO",
            "cityName": "Afton",
            "name": "[AFO] - Municipal - AFO - Afton - United States",
            "searchName": "AFO-Municipal-Afton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AEL",
            "countryCode": "US",
            "cityCode": "AEL",
            "cityName": "Albert Lea",
            "name": "[AEL] - Albert Lea - AEL - Albert Lea - United States",
            "searchName": "AEL-Albert Lea-Albert Lea Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AET",
            "countryCode": "US",
            "cityCode": "AET",
            "cityName": "Allakaket",
            "name": "[AET] - Allakaket - AET - Allakaket - United States",
            "searchName": "AET-Allakaket-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AED",
            "countryCode": "US",
            "cityCode": "AED",
            "cityName": "Aleneva",
            "name": "[AED] - Aleneva - AED - Aleneva - United States",
            "searchName": "AED-Aleneva-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ADW",
            "countryCode": "US",
            "cityCode": "ADW",
            "cityName": "Camp Springs",
            "name": "[ADW] - Andrews AFB - ADW - Camp Springs - United States",
            "searchName": "ADW-Andrews AFB-Andrews Air Force Base Havalimani-Camp Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NSF",
            "countryCode": "US",
            "cityCode": "ADW",
            "cityName": "Camp Springs",
            "name": "[NSF] - Andrews NAF - ADW - Camp Springs - United States",
            "searchName": "NSF-ADW-Andrews NAF-Camp Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AAF",
            "countryCode": "US",
            "cityCode": "AAF",
            "cityName": "Apalachicola",
            "name": "[AAF] - Apalachicola Regional - AAF - Apalachicola - United States",
            "searchName": "AAF-Apalachicola Regional-Apalachicola  Havalimani-Apalachicola-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ABE",
            "countryCode": "US",
            "cityCode": "ABE",
            "cityName": "Allentown",
            "name": "[ABE] - Lehigh Valley International - ABE - Allentown - United States",
            "searchName": "ABE-Allentown-Lehigh Valley International-Bethlehem-Lehigh Valley-Allentown/Bethlehem/Easton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ABL",
            "countryCode": "US",
            "cityCode": "ABL",
            "cityName": "Ambler",
            "name": "[ABL] - Ambler - ABL - Ambler - United States",
            "searchName": "ABL-Ambler-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ABI",
            "countryCode": "US",
            "cityCode": "ABI",
            "cityName": "Abilene",
            "name": "[ABI] - Municipal - ABI - Abilene - United States",
            "searchName": "ABI-Abilene-Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DYS",
            "countryCode": "US",
            "cityCode": "ABI",
            "cityName": "Abilene",
            "name": "[DYS] - Dyess AFB - ABI - Abilene - United States",
            "searchName": "DYS-ABI-Dyess AFB-Abilene-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ABQ",
            "countryCode": "US",
            "cityCode": "ABQ",
            "cityName": "Albuquerque",
            "name": "[ABQ] - Albuquerque International - ABQ - Albuquerque - United States",
            "searchName": "ABQ-Albuquerque-Albuquerque International-Alburquerque-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ABR",
            "countryCode": "US",
            "cityCode": "ABR",
            "cityName": "Aberdeen",
            "name": "[ABR] - Municipal - ABR - Aberdeen - United States",
            "searchName": "ABR-Aberdeen USA-Municipal-Aberdeen-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ACB",
            "countryCode": "US",
            "cityCode": "ACB",
            "cityName": "Bellaire",
            "name": "[ACB] - Antrim County - ACB - Bellaire - United States",
            "searchName": "ACB-Antrim County-Bellaire-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ABY",
            "countryCode": "US",
            "cityCode": "ABY",
            "cityName": "Albany",
            "name": "[ABY] - Dougherty County - ABY - Albany - United States",
            "searchName": "ABY-Albany Georgia-Dougherty County-Albany-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NAB",
            "countryCode": "US",
            "cityCode": "ABY",
            "cityName": "Albany",
            "name": "[NAB] - Albany NAS - ABY - Albany - United States",
            "searchName": "NAB-ABY-Albany NAS-Albany-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ACK",
            "countryCode": "US",
            "cityCode": "ACK",
            "cityName": "Nantucket",
            "name": "[ACK] - Nantucket Memorial - ACK - Nantucket - United States",
            "searchName": "ACK-Nantucket-Nantucket Memorial-Memorial-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BKW",
            "countryCode": "US",
            "cityCode": "BKW",
            "cityName": "Beckley",
            "name": "[BKW] - Beckley - BKW - Beckley - United States",
            "searchName": "BKW-Beckley-Raleigh County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BKX",
            "countryCode": "US",
            "cityCode": "BKX",
            "cityName": "Brookings",
            "name": "[BKX] - Brookings - BKX - Brookings - United States",
            "searchName": "BKX-Brookings-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BKT",
            "countryCode": "US",
            "cityCode": "BKT",
            "cityName": "Blackstone",
            "name": "[BKT] - Blackstone AAF - BKT - Blackstone - United States",
            "searchName": "BKT-Blackstone AAF-Blackstone-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BLF",
            "countryCode": "US",
            "cityCode": "BLF",
            "cityName": "Princeton",
            "name": "[BLF] - Princeton - BLF - Princeton - United States",
            "searchName": "BLF-Mercer County-Princeton-Bluefield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BLH",
            "countryCode": "US",
            "cityCode": "BLH",
            "cityName": "Blythe",
            "name": "[BLH] - Blythe - BLH - Blythe - United States",
            "searchName": "BLH-Blythe-Blythe Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BLI",
            "countryCode": "US",
            "cityCode": "BLI",
            "cityName": "Bellingham",
            "name": "[BLI] - Bellingham - BLI - Bellingham - United States",
            "searchName": "BLI-Bellingham-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BKC",
            "countryCode": "US",
            "cityCode": "BKC",
            "cityName": "Buckland",
            "name": "[BKC] - Buckland - BKC - Buckland - United States",
            "searchName": "BKC-Buckland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BKD",
            "countryCode": "US",
            "cityCode": "BKD",
            "cityName": "Breckenridge",
            "name": "[BKD] - Stephens County - BKD - Breckenridge - United States",
            "searchName": "BKD-Stephens County-Breckenridge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BKE",
            "countryCode": "US",
            "cityCode": "BKE",
            "cityName": "Baker",
            "name": "[BKE] - Baker - BKE - Baker - United States",
            "searchName": "BKE-Baker-Baker City Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BKF",
            "countryCode": "US",
            "cityCode": "BKF",
            "cityName": "Katmai National Park",
            "name": "[BKF] - Lake Brooks SPB - BKF - Katmai National Park - United States",
            "searchName": "BKF-Lake Brooks SPB-Brooks Lake-Katmai National Park-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BKG",
            "countryCode": "US",
            "cityCode": "BKG",
            "cityName": "Branson",
            "name": "[BKG] - Branson - BKG - Branson - United States",
            "searchName": "BKG-Branson-Branson Yerel Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BKH",
            "countryCode": "US",
            "cityCode": "BKH",
            "cityName": "Kekaha",
            "name": "[BKH] - Barking Sands PMRF - BKH - Kekaha - United States",
            "searchName": "BKH-Barking Sands PMRF-Kekaha-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BMI",
            "countryCode": "US",
            "cityCode": "BMI",
            "cityName": "Bloomington",
            "name": "[BMI] - Central Illinois Regional Airport at Bloomington-Normal - BMI - Bloomington - United States",
            "searchName": "BMI-Bloomington Illinois-Central Illinois Regional Airport at Bloomington-Normal-Bloomington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BMG",
            "countryCode": "US",
            "cityCode": "BMG",
            "cityName": "Bloomington",
            "name": "[BMG] - Bloomington - BMG - Bloomington - United States",
            "searchName": "BMG-Bloomington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BML",
            "countryCode": "US",
            "cityCode": "BML",
            "cityName": "Berlin",
            "name": "[BML] - Berlin Municipal Airport - BML - Berlin - United States",
            "searchName": "BML-Berlin Municipal Airport-Berlin-Berlin Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BMX",
            "countryCode": "US",
            "cityCode": "BMX",
            "cityName": "Big Mountain",
            "name": "[BMX] - Big Mountain - BMX - Big Mountain - United States",
            "searchName": "BMX-Big Mountain-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BLM",
            "countryCode": "US",
            "cityCode": "BLM",
            "cityName": "Belmar - Farmingdale",
            "name": "[BLM] - Monmouth Executive Airport - BLM - Belmar - Farmingdale - United States",
            "searchName": "BLM-Monmouth Executive Airport-Belmar - Farmingdale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BLU",
            "countryCode": "US",
            "cityCode": "BLU",
            "cityName": "Blue Canyon",
            "name": "[BLU] - Blue Canyon - BLU - Blue Canyon - United States",
            "searchName": "BLU-Blue Canyon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BLV",
            "countryCode": "US",
            "cityCode": "BLV",
            "cityName": "Belleville",
            "name": "[BLV] - Belleville - BLV - Belleville - United States",
            "searchName": "BLV-Belleville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BMC",
            "countryCode": "US",
            "cityCode": "BMC",
            "cityName": "Brigham City",
            "name": "[BMC] - Brigham City - BMC - Brigham City - United States",
            "searchName": "BMC-Brigham City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BJI",
            "countryCode": "US",
            "cityCode": "BJI",
            "cityName": "Bemidji",
            "name": "[BJI] - Bemidji - BJI - Bemidji - United States",
            "searchName": "BJI-Bemidji-Beltrami County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BJJ",
            "countryCode": "US",
            "cityCode": "BJJ",
            "cityName": "Wooster",
            "name": "[BJJ] - Wayne County - BJJ - Wooster - United States",
            "searchName": "BJJ-Wayne County-Wooster-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BJC",
            "countryCode": "US",
            "cityCode": "BJC",
            "cityName": "Broomfield",
            "name": "[BJC] - Jeffco - BJC - Broomfield - United States",
            "searchName": "BJC-Jeffco-Jeffco Havalimani-Broomfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BIX",
            "countryCode": "US",
            "cityCode": "BIX",
            "cityName": "Biloxi",
            "name": "[BIX] - Keesler AFB - BIX - Biloxi - United States",
            "searchName": "BIX-Keesler AFB-Biloxi-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BIS",
            "countryCode": "US",
            "cityCode": "BIS",
            "cityName": "Bismarck",
            "name": "[BIS] - Bismarck - BIS - Bismarck - United States",
            "searchName": "BIS-Bismarck-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BIC",
            "countryCode": "US",
            "cityCode": "BIC",
            "cityName": "Big Creek",
            "name": "[BIC] - Big Creek - BIC - Big Creek - United States",
            "searchName": "BIC-Big Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BID",
            "countryCode": "US",
            "cityCode": "BID",
            "cityName": "Block Island",
            "name": "[BID] - Block Island - BID - Block Island - United States",
            "searchName": "BID-Block Island--Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BIE",
            "countryCode": "US",
            "cityCode": "BIE",
            "cityName": "Beatrice",
            "name": "[BIE] - Beatrice - BIE - Beatrice - United States",
            "searchName": "BIE-Beatrice-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BIG",
            "countryCode": "US",
            "cityCode": "BIG",
            "cityName": "Big Delta",
            "name": "[BIG] - Intermediate Field - BIG - Big Delta - United States",
            "searchName": "BIG-Intermediate Field-Big Delta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BIH",
            "countryCode": "US",
            "cityCode": "BIH",
            "cityName": "Bishop",
            "name": "[BIH] - Bishop - BIH - Bishop - United States",
            "searchName": "BIH-Bishop-Bishop Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BIL",
            "countryCode": "US",
            "cityCode": "BIL",
            "cityName": "Billings",
            "name": "[BIL] - Billings - BIL - Billings - United States",
            "searchName": "BIL-Billings-Billings Logan Intl-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BHM",
            "countryCode": "US",
            "cityCode": "BHM",
            "cityName": "Birmingham",
            "name": "[BHM] - Birmingham - BHM - Birmingham - United States",
            "searchName": "BHM-Birmingham USA-Birmingham-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BGD",
            "countryCode": "US",
            "cityCode": "BGD",
            "cityName": "Borger",
            "name": "[BGD] - Borger - BGD - Borger - United States",
            "searchName": "BGD-Borger-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BGE",
            "countryCode": "US",
            "cityCode": "BGE",
            "cityName": "Bainbridge",
            "name": "[BGE] - Decatur County - BGE - Bainbridge - United States",
            "searchName": "BGE-Decatur County-Bainbridge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BFT",
            "countryCode": "US",
            "cityCode": "BFT",
            "cityName": "Beaufort",
            "name": "[BFT] - County - BFT - Beaufort - United States",
            "searchName": "BFT-Bezirksflughafen-County-Beaufort County Havalimani-Beaufort-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BFR",
            "countryCode": "US",
            "cityCode": "BFR",
            "cityName": "Bedford",
            "name": "[BFR] - Virgil I Grissom Municipalcipal - BFR - Bedford - United States",
            "searchName": "BFR-Virgil I Grissom Municipalcipal-Virgil I Grissom Belediyesi Havalimani-Bedford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BFP",
            "countryCode": "US",
            "cityCode": "BFP",
            "cityName": "Beaver Falls",
            "name": "[BFP] - Beaver Falls - BFP - Beaver Falls - United States",
            "searchName": "BFP-Beaver Falls-Beaver Falls Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BHB",
            "countryCode": "US",
            "cityCode": "BHB",
            "cityName": "Bar Harbor",
            "name": "[BHB] - Bar Harbor - BHB - Bar Harbor - United States",
            "searchName": "BHB-Bar Harbor-Bar Harbour-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BGM",
            "countryCode": "US",
            "cityCode": "BGM",
            "cityName": "Binghamton",
            "name": "[BGM] - Greater Binghamton - BGM - Binghamton - United States",
            "searchName": "BGM-Binghamton-Greater Binghamton-Broome County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BGQ",
            "countryCode": "US",
            "cityCode": "BGQ",
            "cityName": "Big Lake",
            "name": "[BGQ] - Big Lake - BGQ - Big Lake - United States",
            "searchName": "BGQ-Big Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BGR",
            "countryCode": "US",
            "cityCode": "BGR",
            "cityName": "Bangor",
            "name": "[BGR] - Bangor International Airport - BGR - Bangor - United States",
            "searchName": "BGR-Bangor-Bangor International Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BGT",
            "countryCode": "US",
            "cityCode": "BGT",
            "cityName": "Bagdad",
            "name": "[BGT] - Bagdad - BGT - Bagdad - United States",
            "searchName": "BGT-Bagdad-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BEH",
            "countryCode": "US",
            "cityCode": "BEH",
            "cityName": "Benton Harbor",
            "name": "[BEH] - Ross Field - BEH - Benton Harbor - United States",
            "searchName": "BEH-Ross Field-Benton Harbor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BET",
            "countryCode": "US",
            "cityCode": "BET",
            "cityName": "Bethel",
            "name": "[BET] - Bethel Airport - BET - Bethel - United States",
            "searchName": "BET-Bethel-Bethel Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JBT",
            "countryCode": "US",
            "cityCode": "BET",
            "cityName": "Bethel",
            "name": "[JBT] - City Landing - BET - Bethel - United States",
            "searchName": "JBT-BET-City Landing-Bethel-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BFB",
            "countryCode": "US",
            "cityCode": "BFB",
            "cityName": "Blue Fox Bay",
            "name": "[BFB] - Blue Fox Bay - BFB - Blue Fox Bay - United States",
            "searchName": "BFB-Blue Fox Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BFF",
            "countryCode": "US",
            "cityCode": "BFF",
            "cityName": "Scottsbluff",
            "name": "[BFF] - Western Neb. Regional - BFF - Scottsbluff - United States",
            "searchName": "BFF-Scottsbluff-Western Neb. Regional-Scotts Bluff County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BFG",
            "countryCode": "US",
            "cityCode": "BFG",
            "cityName": "Bullfrog Basin",
            "name": "[BFG] - Bullfrog Basin - BFG - Bullfrog Basin - United States",
            "searchName": "BFG-Bullfrog Basin-Bullfrog Basin Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BFD",
            "countryCode": "US",
            "cityCode": "BFD",
            "cityName": "Bradford",
            "name": "[BFD] - Bradford - BFD - Bradford - United States",
            "searchName": "BFD-Bradford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BFL",
            "countryCode": "US",
            "cityCode": "BFL",
            "cityName": "Bakersfield",
            "name": "[BFL] - Meadows Field - BFL - Bakersfield - United States",
            "searchName": "BFL-Bakersfield-Meadows Field-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BDL",
            "countryCode": "US",
            "cityCode": "BDL",
            "cityName": "Windsor Locks",
            "name": "[BDL] - Bradley International - BDL - Windsor Locks - United States",
            "searchName": "BDL-Hartford Bradley Intl-Bradley International-Bradley Intl-Hartford-Windsor Locks-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BDR",
            "countryCode": "US",
            "cityCode": "BDR",
            "cityName": "Bridgeport",
            "name": "[BDR] - Igor I. Sikorsky Memorial - BDR - Bridgeport - United States",
            "searchName": "BDR-Igor I Sikorsky-Igor I. Sikorsky Memorial-Sikorsky Memorial Havalimani-Bridgeport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BED",
            "countryCode": "US",
            "cityCode": "BED",
            "cityName": "Bedford",
            "name": "[BED] - Hanscom Field - BED - Bedford - United States",
            "searchName": "BED-Hanscom Field-Bedford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BDX",
            "countryCode": "US",
            "cityCode": "BDX",
            "cityName": "Broadus",
            "name": "[BDX] - Broadus - BDX - Broadus - United States",
            "searchName": "BDX-Broadus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BDY",
            "countryCode": "US",
            "cityCode": "BDY",
            "cityName": "Bandon",
            "name": "[BDY] - State - BDY - Bandon - United States",
            "searchName": "BDY-State-Bandon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BDE",
            "countryCode": "US",
            "cityCode": "BDE",
            "cityName": "Baudette",
            "name": "[BDE] - Baudette - BDE - Baudette - United States",
            "searchName": "BDE-Baudette-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BDF",
            "countryCode": "US",
            "cityCode": "BDF",
            "cityName": "Bradford",
            "name": "[BDF] - Rinkenberger - BDF - Bradford - United States",
            "searchName": "BDF-Rinkenberger-Bradford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BDG",
            "countryCode": "US",
            "cityCode": "BDG",
            "cityName": "Blanding",
            "name": "[BDG] - Blanding - BDG - Blanding - United States",
            "searchName": "BDG-Blanding-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BCS",
            "countryCode": "US",
            "cityCode": "BCS",
            "cityName": "Belle Chasse",
            "name": "[BCS] - Southern Seaplane - BCS - Belle Chasse - United States",
            "searchName": "BCS-Southern Seaplane-Belle Chasse-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BCT",
            "countryCode": "US",
            "cityCode": "BCT",
            "cityName": "Boca Raton",
            "name": "[BCT] - Public - BCT - Boca Raton - United States",
            "searchName": "BCT-öffentlicher nicht kommerzieller Flughafen-Public-Boca Raton Public Havalimani-Boca Raton-Ft Lauderdale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BCE",
            "countryCode": "US",
            "cityCode": "BCE",
            "cityName": "Bryce",
            "name": "[BCE] - Bryce - BCE - Bryce - United States",
            "searchName": "BCE-Bryce-Bryce Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BCB",
            "countryCode": "US",
            "cityCode": "BCB",
            "cityName": "Blacksburg",
            "name": "[BCB] - Virginia Tech - BCB - Blacksburg - United States",
            "searchName": "BCB-Virginia Tech-Virginia Tech Havalimani-Blacksburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BCC",
            "countryCode": "US",
            "cityCode": "BCC",
            "cityName": "Bear Creek",
            "name": "[BCC] - Bear Creek - BCC - Bear Creek - United States",
            "searchName": "BCC-Bear Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BBW",
            "countryCode": "US",
            "cityCode": "BBW",
            "cityName": "Broken Bow",
            "name": "[BBW] - Broken Bow - BBW - Broken Bow - United States",
            "searchName": "BBW-Broken Bow-Broken Bow Belediyesi Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BBX",
            "countryCode": "US",
            "cityCode": "BBX",
            "cityName": "Blue Bell",
            "name": "[BBX] - Wings Field - BBX - Blue Bell - United States",
            "searchName": "BBX-Wings Field-Blue Bell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BYW",
            "countryCode": "US",
            "cityCode": "BYW",
            "cityName": "Blakely Island",
            "name": "[BYW] - Blakely Island - BYW - Blakely Island - United States",
            "searchName": "BYW-Blakely Island-Blakely Is Belediyesi Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BYS",
            "countryCode": "US",
            "cityCode": "BYS",
            "cityName": "Fort Irwin",
            "name": "[BYS] - Bicycle Lake AAF - BYS - Fort Irwin - United States",
            "searchName": "BYS-Bicycle Lake AAF-Fort Irwin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BYA",
            "countryCode": "US",
            "cityCode": "BYA",
            "cityName": "Boundary",
            "name": "[BYA] - Boundary - BYA - Boundary - United States",
            "searchName": "BYA-Boundary-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BYG",
            "countryCode": "US",
            "cityCode": "BYG",
            "cityName": "Buffalo",
            "name": "[BYG] - Municipal - BYG - Buffalo - United States",
            "searchName": "BYG-Municipal-Buffalo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BYH",
            "countryCode": "US",
            "cityCode": "BYH",
            "cityName": "Blytheville",
            "name": "[BYH] - Blytheville AFB - BYH - Blytheville - United States",
            "searchName": "BYH-Blytheville AFB-Blytheville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HKA",
            "countryCode": "US",
            "cityCode": "BYH",
            "cityName": "Blytheville",
            "name": "[HKA] - Municipal - BYH - Blytheville - United States",
            "searchName": "HKA-BYH-Municipal-Blytheville  Havalimani-Blytheville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BYI",
            "countryCode": "US",
            "cityCode": "BYI",
            "cityName": "Rupert",
            "name": "[BYI] - Rupert - BYI - Rupert - United States",
            "searchName": "BYI-Rupert-Burley Havalimani-Burley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BXC",
            "countryCode": "US",
            "cityCode": "BXC",
            "cityName": "Boxborough",
            "name": "[BXC] - Boxborough - BXC - Boxborough - United States",
            "searchName": "BXC-Boxborough-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BXK",
            "countryCode": "US",
            "cityCode": "BXK",
            "cityName": "Buckeye",
            "name": "[BXK] - Buckeye Municipal - BXK - Buckeye - United States",
            "searchName": "BXK-Buckeye Municipal-Buckeye-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BXS",
            "countryCode": "US",
            "cityCode": "BXS",
            "cityName": "Borrego Springs",
            "name": "[BXS] - Borrego Springs - BXS - Borrego Springs - United States",
            "searchName": "BXS-Borrego Springs-Borrego Valley Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BXA",
            "countryCode": "US",
            "cityCode": "BXA",
            "cityName": "Bogalusa",
            "name": "[BXA] - George R Carr - BXA - Bogalusa - United States",
            "searchName": "BXA-George R Carr-Bogalusa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EDF",
            "countryCode": "US",
            "cityCode": "ANC",
            "cityName": "Anchorage",
            "name": "[EDF] - Elmendorf AFB - ANC - Anchorage - United States",
            "searchName": "EDF-ANC-Elmendorf AFB-Anchorage-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MRI",
            "countryCode": "US",
            "cityCode": "ANC",
            "cityName": "Anchorage",
            "name": "[MRI] - Merrill Field - ANC - Anchorage - United States",
            "searchName": "MRI-ANC-Merrill Field-Anchorage-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AND",
            "countryCode": "US",
            "cityCode": "AND",
            "cityName": "Anderson",
            "name": "[AND] - Anderson - AND - Anderson - United States",
            "searchName": "AND-Anderson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ANY",
            "countryCode": "US",
            "cityCode": "ANY",
            "cityName": "Anthony",
            "name": "[ANY] - Anthony - ANY - Anthony - United States",
            "searchName": "ANY-Anthony-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ANV",
            "countryCode": "US",
            "cityCode": "ANV",
            "cityName": "Anvik",
            "name": "[ANV] - Anvik - ANV - Anvik - United States",
            "searchName": "ANV-Anvik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ANW",
            "countryCode": "US",
            "cityCode": "ANW",
            "cityName": "Ainsworth",
            "name": "[ANW] - Ainsworth - ANW - Ainsworth - United States",
            "searchName": "ANW-Ainsworth-Ainsworth Minicipal Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ANP",
            "countryCode": "US",
            "cityCode": "ANP",
            "cityName": "Annapolis",
            "name": "[ANP] - Lee - ANP - Annapolis - United States",
            "searchName": "ANP-Lee-Lee Annapolis Havalimani-Annapolis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ANQ",
            "countryCode": "US",
            "cityCode": "ANQ",
            "cityName": "Angola",
            "name": "[ANQ] - Tri-State Steuben County - ANQ - Angola - United States",
            "searchName": "ANQ-Tri-State Steuben County-Angola-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AOO",
            "countryCode": "US",
            "cityCode": "AOO",
            "cityName": "Martinsburg",
            "name": "[AOO] - Martinsburg - AOO - Martinsburg - United States",
            "searchName": "AOO-Altoona-Martinsburg-Blair County-Altoona/Martinsburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AOH",
            "countryCode": "US",
            "cityCode": "AOH",
            "cityName": "Lima",
            "name": "[AOH] - Allen County - AOH - Lima - United States",
            "searchName": "AOH-Allen County-Allen County Havalimani-Lima-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AOS",
            "countryCode": "US",
            "cityCode": "AOS",
            "cityName": "Amook",
            "name": "[AOS] - Amook - AOS - Amook - United States",
            "searchName": "AOS-Amook-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "APC",
            "countryCode": "US",
            "cityCode": "APC",
            "cityName": "Napa",
            "name": "[APC] - Napa County - APC - Napa - United States",
            "searchName": "APC-Bezirksflughafen-Napa County-Napa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "APV",
            "countryCode": "US",
            "cityCode": "APV",
            "cityName": "Apple Valley",
            "name": "[APV] - Apple Valley - APV - Apple Valley - United States",
            "searchName": "APV-Apple Valley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "APN",
            "countryCode": "US",
            "cityCode": "APN",
            "cityName": "Alpena",
            "name": "[APN] - Alpena County Regional - APN - Alpena - United States",
            "searchName": "APN-Alpena-Alpena County Regional-Phelps/Collins-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "APF",
            "countryCode": "US",
            "cityCode": "APF",
            "cityName": "Naples",
            "name": "[APF] - Naples - APF - Naples - United States",
            "searchName": "APF-Naples-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "APG",
            "countryCode": "US",
            "cityCode": "APG",
            "cityName": "Aberdeen",
            "name": "[APG] - Phillips AAF - APG - Aberdeen - United States",
            "searchName": "APG-Armeeflughafen Phillips-Phillips AAF-Phillips Army Air Field Havalimani-Aberdeen-Baltimore-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "APH",
            "countryCode": "US",
            "cityCode": "APH",
            "cityName": "Bowling Green",
            "name": "[APH] - Camp A P Hill - APH - Bowling Green - United States",
            "searchName": "APH-Camp A P Hill-Bowling Green-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ACY",
            "countryCode": "US",
            "cityCode": "AIY",
            "cityName": "Atlantic City",
            "name": "[ACY] - Atlantic City International - AIY - Atlantic City - United States",
            "searchName": "ACY-AIY-Atlantic City-Atlantic City International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JSL",
            "countryCode": "US",
            "cityCode": "AIY",
            "cityName": "Atlantic City",
            "name": "[JSL] - Steel Pier Heliport - AIY - Atlantic City - United States",
            "searchName": "JSL-AIY-Steel Pier Heliport-Atlantic City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZRA",
            "countryCode": "US",
            "cityCode": "AIY",
            "cityName": "Atlantic City",
            "name": "[ZRA] - Atlantic City Railway Station - AIY - Atlantic City - United States",
            "searchName": "ZRA-AIY-Atlantic City Railway Station-Atlantic City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AIZ",
            "countryCode": "US",
            "cityCode": "AIZ",
            "cityName": "Kaiser",
            "name": "[AIZ] - Lee C Fine Memorial - AIZ - Kaiser - United States",
            "searchName": "AIZ-Lee C Fine Memorial-Lee C Fine Memorial Havalimani-Kaiser-Lake Of The Ozarks-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AKB",
            "countryCode": "US",
            "cityCode": "AKB",
            "cityName": "Atka",
            "name": "[AKB] - Atka - AKB - Atka - United States",
            "searchName": "AKB-Atka-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AIK",
            "countryCode": "US",
            "cityCode": "AIK",
            "cityName": "Aiken",
            "name": "[AIK] - Municipal - AIK - Aiken - United States",
            "searchName": "AIK-Municipal-Aiken  Havalimani-Aiken-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AID",
            "countryCode": "US",
            "cityCode": "AID",
            "cityName": "Anderson",
            "name": "[AID] - Municipal - AID - Anderson - United States",
            "searchName": "AID-Municipal-Anderson  Havalimani-Anderson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AIA",
            "countryCode": "US",
            "cityCode": "AIA",
            "cityName": "Alliance",
            "name": "[AIA] - Alliance - AIA - Alliance - United States",
            "searchName": "AIA-Alliance-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AIB",
            "countryCode": "US",
            "cityCode": "AIB",
            "cityName": "Anita Bay",
            "name": "[AIB] - Anita Bay - AIB - Anita Bay - United States",
            "searchName": "AIB-Anita Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AIN",
            "countryCode": "US",
            "cityCode": "AIN",
            "cityName": "Wainwright",
            "name": "[AIN] - Wainwright - AIN - Wainwright - United States",
            "searchName": "AIN-Wainwright-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AIO",
            "countryCode": "US",
            "cityCode": "AIO",
            "cityName": "Atlantic",
            "name": "[AIO] - Municipal - AIO - Atlantic - United States",
            "searchName": "AIO-Municipal-Atlantic-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AIV",
            "countryCode": "US",
            "cityCode": "AIV",
            "cityName": "Aliceville",
            "name": "[AIV] - George Downer - AIV - Aliceville - United States",
            "searchName": "AIV-George Downer-Aliceville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AGN",
            "countryCode": "US",
            "cityCode": "AGN",
            "cityName": "Angoon",
            "name": "[AGN] - Angoon - AGN - Angoon - United States",
            "searchName": "AGN-Angoon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AGO",
            "countryCode": "US",
            "cityCode": "AGO",
            "cityName": "Magnolia",
            "name": "[AGO] - Municipal - AGO - Magnolia - United States",
            "searchName": "AGO-Municipal-Magnolia Belediyesi Havalimani-Magnolia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AGS",
            "countryCode": "US",
            "cityCode": "AGS",
            "cityName": "Augusta",
            "name": "[AGS] - Bush Field - AGS - Augusta - United States",
            "searchName": "AGS-Augusta Bush Field-Bush Field-Augusta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DNL",
            "countryCode": "US",
            "cityCode": "AGS",
            "cityName": "Augusta",
            "name": "[DNL] - Daniel Field - AGS - Augusta - United States",
            "searchName": "DNL-AGS-Landeplatz Daniel-Daniel Field-Daniel Havalimani-Augusta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AHC",
            "countryCode": "US",
            "cityCode": "AHC",
            "cityName": "Herlong",
            "name": "[AHC] - Amedee AAF - AHC - Herlong - United States",
            "searchName": "AHC-Amedee AAF-Herlong-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AHF",
            "countryCode": "US",
            "cityCode": "AHF",
            "cityName": "Arapahoe",
            "name": "[AHF] - Municipal - AHF - Arapahoe - United States",
            "searchName": "AHF-Municipal-Arapahoe-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AHH",
            "countryCode": "US",
            "cityCode": "AHH",
            "cityName": "Amery",
            "name": "[AHH] - Municipal - AHH - Amery - United States",
            "searchName": "AHH-Municipal-Amery-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AHM",
            "countryCode": "US",
            "cityCode": "AHM",
            "cityName": "Ashland",
            "name": "[AHM] - Sumner Parker Field - AHM - Ashland - United States",
            "searchName": "AHM-Sumner Parker Field-Ashland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AHN",
            "countryCode": "US",
            "cityCode": "AHN",
            "cityName": "Athens",
            "name": "[AHN] - Athens-Ben Epps - AHN - Athens - United States",
            "searchName": "AHN-Athens-Ben Epps-Ben Epps Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AKN",
            "countryCode": "US",
            "cityCode": "AKN",
            "cityName": "King Salmon",
            "name": "[AKN] - King Salmon - AKN - King Salmon - United States",
            "searchName": "AKN-King Salmon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AKO",
            "countryCode": "US",
            "cityCode": "AKO",
            "cityName": "Akron",
            "name": "[AKO] - Colorado Plains Regional Airport - AKO - Akron - United States",
            "searchName": "AKO-Colorado Plains Regional Airport-Akron-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AKP",
            "countryCode": "US",
            "cityCode": "AKP",
            "cityName": "Anaktuvuk",
            "name": "[AKP] - Anaktuvuk - AKP - Anaktuvuk - United States",
            "searchName": "AKP-Anaktuvuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AKI",
            "countryCode": "US",
            "cityCode": "AKI",
            "cityName": "Akiak",
            "name": "[AKI] - Akiak - AKI - Akiak - United States",
            "searchName": "AKI-Akiak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AKK",
            "countryCode": "US",
            "cityCode": "AKK",
            "cityName": "Akhiok",
            "name": "[AKK] - Akhiok SPB - AKK - Akhiok - United States",
            "searchName": "AKK-Akhiok SPB-Akhiok-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ALE",
            "countryCode": "US",
            "cityCode": "ALE",
            "cityName": "Alpine",
            "name": "[ALE] - Alpine - ALE - Alpine - United States",
            "searchName": "ALE-Alpine-Alpine Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ALB",
            "countryCode": "US",
            "cityCode": "ALB",
            "cityName": "Albany",
            "name": "[ALB] - Albany International - ALB - Albany - United States",
            "searchName": "ALB-Albany NY-Albany International-Albany-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ALI",
            "countryCode": "US",
            "cityCode": "ALI",
            "cityName": "Alice",
            "name": "[ALI] - International - ALI - Alice - United States",
            "searchName": "ALI-International-Alice-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ALM",
            "countryCode": "US",
            "cityCode": "ALM",
            "cityName": "Alamogordo",
            "name": "[ALM] - Municipal - ALM - Alamogordo - United States",
            "searchName": "ALM-Kommunaler Flughafen-Municipal-Alamogordo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HMN",
            "countryCode": "US",
            "cityCode": "ALM",
            "cityName": "Alamogordo",
            "name": "[HMN] - Holloman AFB - ALM - Alamogordo - United States",
            "searchName": "HMN-ALM-Holloman AFB-Alamogordo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ALN",
            "countryCode": "US",
            "cityCode": "ALN",
            "cityName": "Alton",
            "name": "[ALN] - Alton - ALN - Alton - United States",
            "searchName": "ALN-Alton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ALO",
            "countryCode": "US",
            "cityCode": "ALO",
            "cityName": "Waterloo",
            "name": "[ALO] - Waterloo - ALO - Waterloo - United States",
            "searchName": "ALO-Waterloo USA-Waterloo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AMN",
            "countryCode": "US",
            "cityCode": "AMN",
            "cityName": "Alma",
            "name": "[AMN] - Gratiot Community - AMN - Alma - United States",
            "searchName": "AMN-Gratiot Community-Alma-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AMW",
            "countryCode": "US",
            "cityCode": "AMW",
            "cityName": "Ames",
            "name": "[AMW] - Ames - AMW - Ames - United States",
            "searchName": "AMW-Ames-Ames Minicipal Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ALS",
            "countryCode": "US",
            "cityCode": "ALS",
            "cityName": "Alamosa",
            "name": "[ALS] - Municipal - ALS - Alamosa - United States",
            "searchName": "ALS-Alamosa-Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ALW",
            "countryCode": "US",
            "cityCode": "ALW",
            "cityName": "Walla Walla",
            "name": "[ALW] - Walla Walla - ALW - Walla Walla - United States",
            "searchName": "ALW-Walla Walla-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ALX",
            "countryCode": "US",
            "cityCode": "ALX",
            "cityName": "Alexander City",
            "name": "[ALX] - Thomas C Russell Field - ALX - Alexander City - United States",
            "searchName": "ALX-Thomas C Russell Field-Thomas C Russell Field Havalimani-Alexander City-Alexander-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ALZ",
            "countryCode": "US",
            "cityCode": "ALZ",
            "cityName": "Alitak",
            "name": "[ALZ] - Alitak SPB - ALZ - Alitak - United States",
            "searchName": "ALZ-Alitak SPB-Alitak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AMA",
            "countryCode": "US",
            "cityCode": "AMA",
            "cityName": "Amarillo",
            "name": "[AMA] - Rick Husband Amarillo International - AMA - Amarillo - United States",
            "searchName": "AMA-Amarillo-Rick Husband Amarillo International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TDW",
            "countryCode": "US",
            "cityCode": "AMA",
            "cityName": "Amarillo",
            "name": "[TDW] - Tradewind - AMA - Amarillo - United States",
            "searchName": "TDW-AMA-Tradewind-Tradewind Havalimani-Amarillo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ADG",
            "countryCode": "US",
            "cityCode": "ADG",
            "cityName": "Adrian",
            "name": "[ADG] - Lenawee County - ADG - Adrian - United States",
            "searchName": "ADG-Lenawee County-Lenawee County Havalimani-Adrian-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ACV",
            "countryCode": "US",
            "cityCode": "ACV",
            "cityName": "Arcata",
            "name": "[ACV] - Arcata - ACV - Arcata - United States",
            "searchName": "ACV-Arcata Eureka-Arcata-Eureka Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ACT",
            "countryCode": "US",
            "cityCode": "ACT",
            "cityName": "Waco",
            "name": "[ACT] - Municipal - ACT - Waco - United States",
            "searchName": "ACT-Waco-Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CNW",
            "countryCode": "US",
            "cityCode": "ACT",
            "cityName": "Waco",
            "name": "[CNW] - TSTC Waco Airport - ACT - Waco - United States",
            "searchName": "CNW-ACT-James Connell-TSTC Waco Airport-Waco-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GBO",
            "countryCode": "US",
            "cityCode": "BWI",
            "cityName": "Baltimore",
            "name": "[GBO] - Baltimore Greenbelt T - BWI - Baltimore - United States",
            "searchName": "GBO-BWI-Baltimore Greenbelt T-Baltimore-Baltimora-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MTN",
            "countryCode": "US",
            "cityCode": "BWI",
            "cityName": "Baltimore",
            "name": "[MTN] - Glenn L Martin - BWI - Baltimore - United States",
            "searchName": "MTN-BWI-Glenn L Martin-Baltimore-Baltimora-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZBP",
            "countryCode": "US",
            "cityCode": "BWI",
            "cityName": "Baltimore",
            "name": "[ZBP] - Baltimore Railway - BWI - Baltimore - United States",
            "searchName": "ZBP-BWI-Baltimore Railway-Baltimore-Baltimora-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BWL",
            "countryCode": "US",
            "cityCode": "BWL",
            "cityName": "Blackwell",
            "name": "[BWL] - Blackwell - BWL - Blackwell - United States",
            "searchName": "BWL-Blackwell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BWM",
            "countryCode": "US",
            "cityCode": "BWM",
            "cityName": "Bowman",
            "name": "[BWM] - Bowman - BWM - Bowman - United States",
            "searchName": "BWM-Bowman-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BWC",
            "countryCode": "US",
            "cityCode": "BWC",
            "cityName": "Brawley",
            "name": "[BWC] - Brawley - BWC - Brawley - United States",
            "searchName": "BWC-Brawley-Brawley Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BWD",
            "countryCode": "US",
            "cityCode": "BWD",
            "cityName": "Brownwood",
            "name": "[BWD] - Brownwood - BWD - Brownwood - United States",
            "searchName": "BWD-Brownwood-Brawnwood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BTP",
            "countryCode": "US",
            "cityCode": "BTP",
            "cityName": "Butler",
            "name": "[BTP] - Butler County - Kenny Scholter Field - BTP - Butler - United States",
            "searchName": "BTP-Butler County - Kenny Scholter Field-Butler-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BTR",
            "countryCode": "US",
            "cityCode": "BTR",
            "cityName": "Baton Rouge",
            "name": "[BTR] - Ryan - BTR - Baton Rouge - United States",
            "searchName": "BTR-Baton Rouge-Ryan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EBR",
            "countryCode": "US",
            "cityCode": "BTR",
            "cityName": "Baton Rouge",
            "name": "[EBR] - Downtown - BTR - Baton Rouge - United States",
            "searchName": "EBR-BTR-Downtown-Baton Rouge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BTL",
            "countryCode": "US",
            "cityCode": "BTL",
            "cityName": "Battle Creek",
            "name": "[BTL] - WK Kellogg Regional - BTL - Battle Creek - United States",
            "searchName": "BTL-WK Kellogg Regional-WK Kellogg Regional Havalimani-W. K. Kellogg-Battle Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BTM",
            "countryCode": "US",
            "cityCode": "BTM",
            "cityName": "Butte",
            "name": "[BTM] - Bert Mooney Airport - BTM - Butte - United States",
            "searchName": "BTM-Butte-Bert Mooney Airport-Silver Bow Co-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BTN",
            "countryCode": "US",
            "cityCode": "BTN",
            "cityName": "Bennettsville",
            "name": "[BTN] - Bennettsville - BTN - Bennettsville - United States",
            "searchName": "BTN-Bennettsville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BTT",
            "countryCode": "US",
            "cityCode": "BTT",
            "cityName": "Bettles",
            "name": "[BTT] - Bettles - BTT - Bettles - United States",
            "searchName": "BTT-Bettles-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BTV",
            "countryCode": "US",
            "cityCode": "BTV",
            "cityName": "Burlington",
            "name": "[BTV] - Burlington International - BTV - Burlington - United States",
            "searchName": "BTV-Burlington Vermont-Burlington International-Burlington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BTY",
            "countryCode": "US",
            "cityCode": "BTY",
            "cityName": "Beatty",
            "name": "[BTY] - Beatty - BTY - Beatty - United States",
            "searchName": "BTY-Beatty-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BUB",
            "countryCode": "US",
            "cityCode": "BUB",
            "cityName": "Burwell",
            "name": "[BUB] - Municipal - BUB - Burwell - United States",
            "searchName": "BUB-Municipal-Burwell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BUM",
            "countryCode": "US",
            "cityCode": "BUM",
            "cityName": "Butler",
            "name": "[BUM] - Butler - BUM - Butler - United States",
            "searchName": "BUM-Butler-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BUF",
            "countryCode": "US",
            "cityCode": "BUF",
            "cityName": "Buffalo",
            "name": "[BUF] - Buffalo Niagara International - BUF - Buffalo - United States",
            "searchName": "BUF-Buffalo-Buffalo Niagara International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZFZ",
            "countryCode": "US",
            "cityCode": "BUF",
            "cityName": "Buffalo",
            "name": "[ZFZ] - Buffalo Depew Rr - BUF - Buffalo - United States",
            "searchName": "ZFZ-BUF-Buffalo Depew Rr-Buffalo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZXS",
            "countryCode": "US",
            "cityCode": "BUF",
            "cityName": "Buffalo",
            "name": "[ZXS] - Exchangest Railway - BUF - Buffalo - United States",
            "searchName": "ZXS-BUF-Exchangest Railway-Buffalo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BUR",
            "countryCode": "US",
            "cityCode": "BUR",
            "cityName": "Burbank",
            "name": "[BUR] - Bob Hope - BUR - Burbank - United States",
            "searchName": "BUR-Burbank-Bob Hope-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BVD",
            "countryCode": "US",
            "cityCode": "BVD",
            "cityName": "Beaver Inlet",
            "name": "[BVD] - Sea Port - BVD - Beaver Inlet - United States",
            "searchName": "BVD-Sea Port-Beaver Inlet-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BVX",
            "countryCode": "US",
            "cityCode": "BVX",
            "cityName": "Batesville",
            "name": "[BVX] - Municipal - BVX - Batesville - United States",
            "searchName": "BVX-Municipal-Batesville Belediyesi Havalimani-Batesville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BVY",
            "countryCode": "US",
            "cityCode": "BVY",
            "cityName": "Beverly",
            "name": "[BVY] - Beverly Municipal Airport - BVY - Beverly - United States",
            "searchName": "BVY-Beverly Municipal Airport-Beverly  Havalimani-Beverly-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BWG",
            "countryCode": "US",
            "cityCode": "BWG",
            "cityName": "Bowling Green",
            "name": "[BWG] - Warren County - BWG - Bowling Green - United States",
            "searchName": "BWG-Warren County-Bowling Green-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BVU",
            "countryCode": "US",
            "cityCode": "BVU",
            "cityName": "Beluga",
            "name": "[BVU] - Beluga - BVU - Beluga - United States",
            "searchName": "BVU-Beluga-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BVO",
            "countryCode": "US",
            "cityCode": "BVO",
            "cityName": "Bartlesville",
            "name": "[BVO] - Bartlesville - BVO - Bartlesville - United States",
            "searchName": "BVO-Bartlesville-Bartlesville  Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BRY",
            "countryCode": "US",
            "cityCode": "BRY",
            "cityName": "Bardstown",
            "name": "[BRY] - Samuels Field - BRY - Bardstown - United States",
            "searchName": "BRY-Samuels Field-Bardstown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BSF",
            "countryCode": "US",
            "cityCode": "BSF",
            "cityName": "Pohakuloa",
            "name": "[BSF] - Bradshaw AAF - BSF - Pohakuloa - United States",
            "searchName": "BSF-Militärflughafen Bradshaw-Bradshaw AAF-Pohakuloa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BSI",
            "countryCode": "US",
            "cityCode": "BSI",
            "cityName": "Blairsville",
            "name": "[BSI] - Blairsville - BSI - Blairsville - United States",
            "searchName": "BSI-Blairsville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BSZ",
            "countryCode": "US",
            "cityCode": "BSZ",
            "cityName": "Bartletts",
            "name": "[BSZ] - Bartletts - BSZ - Bartletts - United States",
            "searchName": "BSZ-Bartletts-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BTF",
            "countryCode": "US",
            "cityCode": "BTF",
            "cityName": "Bountiful",
            "name": "[BTF] - Salt Lake Skypark - BTF - Bountiful - United States",
            "searchName": "BTF-Salt Lake Skypark-Bountiful-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BTI",
            "countryCode": "US",
            "cityCode": "BTI",
            "cityName": "Barter Island",
            "name": "[BTI] - Barter Island - BTI - Barter Island - United States",
            "searchName": "BTI-Barter Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BSQ",
            "countryCode": "US",
            "cityCode": "BSQ",
            "cityName": "Bisbee",
            "name": "[BSQ] - Bisbee Municipal Airport - BSQ - Bisbee - United States",
            "searchName": "BSQ-Bisbee Municipal Airport-Bisbee  Havalimani-Bisbee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BSW",
            "countryCode": "US",
            "cityCode": "BSW",
            "cityName": "Boswell Bay",
            "name": "[BSW] - Boswell Bay - BSW - Boswell Bay - United States",
            "searchName": "BSW-Boswell Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BRD",
            "countryCode": "US",
            "cityCode": "BRD",
            "cityName": "Brainerd",
            "name": "[BRD] - Brainerd Lakes Regional - BRD - Brainerd - United States",
            "searchName": "BRD-Brainerd-Brainerd Lakes Regional-Crow Wing County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BRO",
            "countryCode": "US",
            "cityCode": "BRO",
            "cityName": "Brownsville",
            "name": "[BRO] - South Padre Is. International - BRO - Brownsville - United States",
            "searchName": "BRO-Brownsville-South Padre Is. International-South Padre Is. Intl-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BRL",
            "countryCode": "US",
            "cityCode": "BRL",
            "cityName": "Burlington",
            "name": "[BRL] - Burlington - BRL - Burlington - United States",
            "searchName": "BRL-Burlington Iowa-Burlington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BRW",
            "countryCode": "US",
            "cityCode": "BRW",
            "cityName": "Barrow",
            "name": "[BRW] - Wiley Post/W.Rogers M - BRW - Barrow - United States",
            "searchName": "BRW-Barrow-Wiley Post/W.Rogers M-Wiley Post/W.Rogers-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PBA",
            "countryCode": "US",
            "cityCode": "BRW",
            "cityName": "Barrow",
            "name": "[PBA] - Point Barrow - BRW - Barrow - United States",
            "searchName": "PBA-BRW-Point Barrow-Barrow-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BNW",
            "countryCode": "US",
            "cityCode": "BNW",
            "cityName": "Boone",
            "name": "[BNW] - Boone - BNW - Boone - United States",
            "searchName": "BNW-Boone-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BOI",
            "countryCode": "US",
            "cityCode": "BOI",
            "cityName": "Boise",
            "name": "[BOI] - Boise Air Terminal/Gowen Field - BOI - Boise - United States",
            "searchName": "BOI-Boise-Boise Air Terminal/Gowen Field-Air Term. Gowen Fld-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BNO",
            "countryCode": "US",
            "cityCode": "BNO",
            "cityName": "Burns",
            "name": "[BNO] - Burns - BNO - Burns - United States",
            "searchName": "BNO-Burns-Burns  Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BNL",
            "countryCode": "US",
            "cityCode": "BNL",
            "cityName": "Barnwell",
            "name": "[BNL] - Barnwell Regional - BNL - Barnwell - United States",
            "searchName": "BNL-Barnwell Regional-Barnwell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BNA",
            "countryCode": "US",
            "cityCode": "BNA",
            "cityName": "Nashville",
            "name": "[BNA] - Nashville International - BNA - Nashville - United States",
            "searchName": "BNA-Nashville-Nashville International-Nashville Intl-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BNF",
            "countryCode": "US",
            "cityCode": "BNF",
            "cityName": "Baranof",
            "name": "[BNF] - Warm Spring Bay SPB - BNF - Baranof - United States",
            "searchName": "BNF-Warm Spring Bay SPB-Baranof-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BNG",
            "countryCode": "US",
            "cityCode": "BNG",
            "cityName": "Banning",
            "name": "[BNG] - Banning - BNG - Banning - United States",
            "searchName": "BNG-Banning-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BPI",
            "countryCode": "US",
            "cityCode": "BPI",
            "cityName": "Big Piney",
            "name": "[BPI] - Big Piney-marbleton - BPI - Big Piney - United States",
            "searchName": "BPI-Marbleton-Big Piney-marbleton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BPT",
            "countryCode": "US",
            "cityCode": "BPT",
            "cityName": "Beaumont",
            "name": "[BPT] - Jefferson County - BPT - Beaumont - United States",
            "searchName": "BPT-Beaumont-Jefferson County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BMT",
            "countryCode": "US",
            "cityCode": "BPT",
            "cityName": "Beaumont",
            "name": "[BMT] - Beaumont Municipalcipal - BPT - Beaumont - United States",
            "searchName": "BMT-BPT-Beaumont Municipalcipal-Beaumont-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BOW",
            "countryCode": "US",
            "cityCode": "BOW",
            "cityName": "Bartow",
            "name": "[BOW] - Bartow - BOW - Bartow - United States",
            "searchName": "BOW-Bartow-Bartow Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BOK",
            "countryCode": "US",
            "cityCode": "BOK",
            "cityName": "Brookings",
            "name": "[BOK] - Brookings State - BOK - Brookings - United States",
            "searchName": "BOK-Brookings State-Brookings State Havalimani-Brookings-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CNO",
            "countryCode": "US",
            "cityCode": "CNO",
            "cityName": "Chino",
            "name": "[CNO] - Chino - CNO - Chino - United States",
            "searchName": "CNO-Chino-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CNK",
            "countryCode": "US",
            "cityCode": "CNK",
            "cityName": "Concordia",
            "name": "[CNK] - Blosser Municipal - CNK - Concordia - United States",
            "searchName": "CNK-Blosser Municipal-Concordia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CNY",
            "countryCode": "US",
            "cityCode": "CNY",
            "cityName": "Moab",
            "name": "[CNY] - Canyonlands Field - CNY - Moab - United States",
            "searchName": "CNY-Moab-Canyonlands Field-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CNU",
            "countryCode": "US",
            "cityCode": "CNU",
            "cityName": "Chanute",
            "name": "[CNU] - Martin Johnson - CNU - Chanute - United States",
            "searchName": "CNU-Martin Johnson-Chanute-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "COD",
            "countryCode": "US",
            "cityCode": "COD",
            "cityName": "Cody",
            "name": "[COD] - Yellowstone Regional - COD - Cody - United States",
            "searchName": "COD-Cody Yellowstone-Yellowstone Regional-E.E. Faust-Cody/Yellowstone-Cody-Cody\\/Yellowstone-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "COE",
            "countryCode": "US",
            "cityCode": "COE",
            "cityName": "Coeur D Alene",
            "name": "[COE] - Coeur DAlene - COE - Coeur D Alene - United States",
            "searchName": "COE-Coeur D Alene-Coeur d Alene  Havalimani-Coeur DAlene-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "COA",
            "countryCode": "US",
            "cityCode": "COA",
            "cityName": "Columbia",
            "name": "[COA] - Columbia - COA - Columbia - United States",
            "searchName": "COA-Columbia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "COI",
            "countryCode": "US",
            "cityCode": "COI",
            "cityName": "Cocoa",
            "name": "[COI] - Merritt Island - COI - Cocoa - United States",
            "searchName": "COI-Merritt Island-Merrit Adasi Havalimani-Cocoa-Cocoa Metro Area-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "COF",
            "countryCode": "US",
            "cityCode": "COI",
            "cityName": "Cocoa",
            "name": "[COF] - Patrick AFB - COI - Cocoa - United States",
            "searchName": "COF-COI-Patrick AFB-Cocoa-Cocoa Metro Area-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "COM",
            "countryCode": "US",
            "cityCode": "COM",
            "cityName": "Coleman",
            "name": "[COM] - Coleman - COM - Coleman - United States",
            "searchName": "COM-Coleman-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CON",
            "countryCode": "US",
            "cityCode": "CON",
            "cityName": "Concord",
            "name": "[CON] - Concord - CON - Concord - United States",
            "searchName": "CON-Concord-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "COP",
            "countryCode": "US",
            "cityCode": "COP",
            "cityName": "Cooperstown",
            "name": "[COP] - Cooperstown - Westville Airport - COP - Cooperstown - United States",
            "searchName": "COP-Cooperstown - Westville Airport-Cooperstown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "COS",
            "countryCode": "US",
            "cityCode": "COS",
            "cityName": "Colorado Springs",
            "name": "[COS] - Colorado Springs - COS - Colorado Springs - United States",
            "searchName": "COS-Colorado Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FCS",
            "countryCode": "US",
            "cityCode": "COS",
            "cityName": "Colorado Springs",
            "name": "[FCS] - Butts AAF - COS - Colorado Springs - United States",
            "searchName": "FCS-COS-Butts AAF-Colorado Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AFF",
            "countryCode": "US",
            "cityCode": "COS",
            "cityName": "Colorado Springs",
            "name": "[AFF] - USAF Academy Airstrip - COS - Colorado Springs - United States",
            "searchName": "AFF-COS-USAF Academy Airstrip-Colorado Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "COT",
            "countryCode": "US",
            "cityCode": "COT",
            "cityName": "Cotulla",
            "name": "[COT] - Cotulla - COT - Cotulla - United States",
            "searchName": "COT-Cotulla-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "COU",
            "countryCode": "US",
            "cityCode": "COU",
            "cityName": "Columbia",
            "name": "[COU] - Columbia Regional - COU - Columbia - United States",
            "searchName": "COU-Columbia Regional-Columbia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CRS",
            "countryCode": "US",
            "cityCode": "CRS",
            "cityName": "Corsicana",
            "name": "[CRS] - Corsicana - CRS - Corsicana - United States",
            "searchName": "CRS-Corsicana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CRT",
            "countryCode": "US",
            "cityCode": "CRT",
            "cityName": "Crossett",
            "name": "[CRT] - Municipal - CRT - Crossett - United States",
            "searchName": "CRT-Municipal-Z. M. Jack Stell Field-Crossett-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CRW",
            "countryCode": "US",
            "cityCode": "CRW",
            "cityName": "Charleston",
            "name": "[CRW] - Yeager - CRW - Charleston - United States",
            "searchName": "CRW-Charleston WV-Yeager-Charleston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CRX",
            "countryCode": "US",
            "cityCode": "CRX",
            "cityName": "Corinth",
            "name": "[CRX] - Roscoe Turner - CRX - Corinth - United States",
            "searchName": "CRX-Roscoe Turner-Roscoe Turner Havalimani-Corinth-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MKF",
            "countryCode": "US",
            "cityCode": "CSG",
            "cityName": "Columbus",
            "name": "[MKF] - Mckenna AAF - CSG - Columbus - United States",
            "searchName": "MKF-CSG-Mckenna AAF-Columbus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CSE",
            "countryCode": "US",
            "cityCode": "CSE",
            "cityName": "Crested Butte",
            "name": "[CSE] - Crested Butte - CSE - Crested Butte - United States",
            "searchName": "CSE-Crested Butte-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CSL",
            "countryCode": "US",
            "cityCode": "CSL",
            "cityName": "San Luis Obispo",
            "name": "[CSL] - O Sullivan Army Air Field - CSL - San Luis Obispo - United States",
            "searchName": "CSL-O Sullivan Army Air Field-San Luis Obispo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SBP",
            "countryCode": "US",
            "cityCode": "CSL",
            "cityName": "San Luis Obispo",
            "name": "[SBP] - San Luis County Regional Airport - CSL - San Luis Obispo - United States",
            "searchName": "SBP-CSL-San Luis Obispo-San Luis County Regional Airport-County Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CSM",
            "countryCode": "US",
            "cityCode": "CSM",
            "cityName": "Clinton",
            "name": "[CSM] - Sherman - CSM - Clinton - United States",
            "searchName": "CSM-Sherman-tüm havaalanlari-Clinton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLK",
            "countryCode": "US",
            "cityCode": "CSM",
            "cityName": "Clinton",
            "name": "[CLK] - Municipal - CSM - Clinton - United States",
            "searchName": "CLK-CSM-Municipal-Clinton  Havalimani-Clinton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CSN",
            "countryCode": "US",
            "cityCode": "CSN",
            "cityName": "Carson City",
            "name": "[CSN] - Carson City - CSN - Carson City - United States",
            "searchName": "CSN-Carson City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CSP",
            "countryCode": "US",
            "cityCode": "CSP",
            "cityName": "Cape Spencer",
            "name": "[CSP] - Coast Guard Heliport - CSP - Cape Spencer - United States",
            "searchName": "CSP-Hubschrauberlandeplatz der Küstenwache-Coast Guard Heliport-Cape Spencer-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CSQ",
            "countryCode": "US",
            "cityCode": "CSQ",
            "cityName": "Creston",
            "name": "[CSQ] - Municipal - CSQ - Creston - United States",
            "searchName": "CSQ-Kommunaler Flughafen-Municipal-Creston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CSV",
            "countryCode": "US",
            "cityCode": "CSV",
            "cityName": "Crossville",
            "name": "[CSV] - Memorial - CSV - Crossville - United States",
            "searchName": "CSV-Memorial-Crossville Memorial Havalimani-Crossville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CRO",
            "countryCode": "US",
            "cityCode": "CRO",
            "cityName": "Corcoran",
            "name": "[CRO] - Corcoran - CRO - Corcoran - United States",
            "searchName": "CRO-Corcoran-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CRP",
            "countryCode": "US",
            "cityCode": "CRP",
            "cityName": "Corpus Christi",
            "name": "[CRP] - Corpus Christi International Airport - CRP - Corpus Christi - United States",
            "searchName": "CRP-Corpus Christi-Corpus Christi International Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CUX",
            "countryCode": "US",
            "cityCode": "CRP",
            "cityName": "Corpus Christi",
            "name": "[CUX] - Cuddihy Field - CRP - Corpus Christi - United States",
            "searchName": "CUX-CRP-Cuddihy Field-Corpus Christi-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NGP",
            "countryCode": "US",
            "cityCode": "CRP",
            "cityName": "Corpus Christi",
            "name": "[NGP] - NAS - CRP - Corpus Christi - United States",
            "searchName": "NGP-CRP-NAS-Corpus Christi-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NGW",
            "countryCode": "US",
            "cityCode": "CRP",
            "cityName": "Corpus Christi",
            "name": "[NGW] - Cabaniss Field - CRP - Corpus Christi - United States",
            "searchName": "NGW-CRP-Cabaniss Field-Corpus Christi-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CPR",
            "countryCode": "US",
            "cityCode": "CPR",
            "cityName": "Casper",
            "name": "[CPR] - Natrona County International Airport - CPR - Casper - United States",
            "searchName": "CPR-Casper-Natrona County International Airport-Natrona County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CPM",
            "countryCode": "US",
            "cityCode": "CPM",
            "cityName": "Compton",
            "name": "[CPM] - Compton - CPM - Compton - United States",
            "searchName": "CPM-Compton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JHW",
            "countryCode": "US",
            "cityCode": "JHW",
            "cityName": "Jamestown",
            "name": "[JHW] - Jamestown - JHW - Jamestown - United States",
            "searchName": "JHW-Jamestown-Chautauqua County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JHY",
            "countryCode": "US",
            "cityCode": "JHY",
            "cityName": "Cambridge",
            "name": "[JHY] - Hyatt Regency Heliport - JHY - Cambridge - United States",
            "searchName": "JHY-Hyatt Regency Heliport-Cambridge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JHC",
            "countryCode": "US",
            "cityCode": "JHC",
            "cityName": "Garden City",
            "name": "[JHC] - Island Heliport - JHC - Garden City - United States",
            "searchName": "JHC-Island Heliport-Garden City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JHM",
            "countryCode": "US",
            "cityCode": "JHM",
            "cityName": "Lahaina",
            "name": "[JHM] - Kapalua West Maui - JHM - Lahaina - United States",
            "searchName": "JHM-Kapalua-Kapalua West Maui-West Maui-Kapalua Havalimani-Kapalua Hawaii-Lahaina-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JGX",
            "countryCode": "US",
            "cityCode": "JGX",
            "cityName": "Glendale",
            "name": "[JGX] - Heliport - JGX - Glendale - United States",
            "searchName": "JGX-Heliport-Glendale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JFN",
            "countryCode": "US",
            "cityCode": "JFN",
            "cityName": "Jefferson",
            "name": "[JFN] - Ashtabula - JFN - Jefferson - United States",
            "searchName": "JFN-Ashtabula-Jefferson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JDY",
            "countryCode": "US",
            "cityCode": "JDY",
            "cityName": "Downey",
            "name": "[JDY] - Heliport - JDY - Downey - United States",
            "searchName": "JDY-Heliport-Downey-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JEM",
            "countryCode": "US",
            "cityCode": "JEM",
            "cityName": "Emeryville",
            "name": "[JEM] - Heliport - JEM - Emeryville - United States",
            "searchName": "JEM-Heliport-Emeryville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JEF",
            "countryCode": "US",
            "cityCode": "JEF",
            "cityName": "Jefferson City",
            "name": "[JEF] - Memorial - JEF - Jefferson City - United States",
            "searchName": "JEF-Memorial-Jefferson City Memorial Havalimani-Jefferson City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JDN",
            "countryCode": "US",
            "cityCode": "JDN",
            "cityName": "Jordan",
            "name": "[JDN] - Jordan - JDN - Jordan - United States",
            "searchName": "JDN-Jordan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JCY",
            "countryCode": "US",
            "cityCode": "JCY",
            "cityName": "Johnson",
            "name": "[JCY] - Johnson - JCY - Johnson - United States",
            "searchName": "JCY-Johnson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JDA",
            "countryCode": "US",
            "cityCode": "JDA",
            "cityName": "John Day",
            "name": "[JDA] - John Day - JDA - John Day - United States",
            "searchName": "JDA-John Day-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JCT",
            "countryCode": "US",
            "cityCode": "JCT",
            "cityName": "Junction",
            "name": "[JCT] - Kimble County - JCT - Junction - United States",
            "searchName": "JCT-Kimble County-Junction-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JAS",
            "countryCode": "US",
            "cityCode": "JAS",
            "cityName": "Jasper",
            "name": "[JAS] - County - JAS - Jasper - United States",
            "searchName": "JAS-County-Jasper-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JAX",
            "countryCode": "US",
            "cityCode": "JAX",
            "cityName": "Jacksonville",
            "name": "[JAX] - Jacksonville, - JAX - Jacksonville - United States",
            "searchName": "JAX-Jacksonville--Jacksonville,-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NIP",
            "countryCode": "US",
            "cityCode": "JAX",
            "cityName": "Jacksonville",
            "name": "[NIP] - Jacksonville NAS - JAX - Jacksonville - United States",
            "searchName": "NIP-JAX-Jacksonville NAS-Jacksonville Nas Havalimani-Jacksonville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CRG",
            "countryCode": "US",
            "cityCode": "JAX",
            "cityName": "Jacksonville",
            "name": "[CRG] - Craig Municipal - JAX - Jacksonville - United States",
            "searchName": "CRG-JAX-Craig Municipal-Craig  Havalimani-Jacksonville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VQQ",
            "countryCode": "US",
            "cityCode": "JAX",
            "cityName": "Jacksonville",
            "name": "[VQQ] - Cecil Field - JAX - Jacksonville - United States",
            "searchName": "VQQ-JAX-Cecil Field-Jacksonville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JBR",
            "countryCode": "US",
            "cityCode": "JBR",
            "cityName": "Jonesboro",
            "name": "[JBR] - Jonesboro - JBR - Jonesboro - United States",
            "searchName": "JBR-Jonesboro-Jonesboro Arkansas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JBS",
            "countryCode": "US",
            "cityCode": "JBS",
            "cityName": "Pleasanton",
            "name": "[JBS] - Hacienda Bus.Park Heliport - JBS - Pleasanton - United States",
            "searchName": "JBS-Hubschrauberlandeplatz Hacienda Business Park-Hacienda Bus.Park Heliport-Hacienda Business Park helikopter pisti Havalimani-Pleasanton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JOR",
            "countryCode": "US",
            "cityCode": "JOR",
            "cityName": "Orange",
            "name": "[JOR] - The City Heliport - JOR - Orange - United States",
            "searchName": "JOR-The City Heliport-Orange-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JOT",
            "countryCode": "US",
            "cityCode": "JOT",
            "cityName": "Joliet",
            "name": "[JOT] - Municipal - JOT - Joliet - United States",
            "searchName": "JOT-Kommunaler Flughafen-Municipal-Joliet Park District Havalimani-Joliet-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JRF",
            "countryCode": "US",
            "cityCode": "JRF",
            "cityName": "Kapolei",
            "name": "[JRF] - Kalaeloa - JRF - Kapolei - United States",
            "searchName": "JRF-Kalaeloa-Kapolei-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JPD",
            "countryCode": "US",
            "cityCode": "JPD",
            "cityName": "Pasadena",
            "name": "[JPD] - Heliport - JPD - Pasadena - United States",
            "searchName": "JPD-Heliport-Pasadena-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JSD",
            "countryCode": "US",
            "cityCode": "JSD",
            "cityName": "Stratford",
            "name": "[JSD] - Sikorsky Heliport - JSD - Stratford - United States",
            "searchName": "JSD-Sikorsky Heliport-Stratford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JTO",
            "countryCode": "US",
            "cityCode": "JTO",
            "cityName": "Thousand Oaks",
            "name": "[JTO] - Heliport - JTO - Thousand Oaks - United States",
            "searchName": "JTO-Heliport-Thousand Oaks-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JST",
            "countryCode": "US",
            "cityCode": "JST",
            "cityName": "Johnstown",
            "name": "[JST] - Cambria County - JST - Johnstown - United States",
            "searchName": "JST-Johnstown-Cambria County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JLH",
            "countryCode": "US",
            "cityCode": "JLH",
            "cityName": "Arlington Heights",
            "name": "[JLH] - US Army Heliport - JLH - Arlington Heights - United States",
            "searchName": "JLH-US Army Heliport-Arlington Heights-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JLN",
            "countryCode": "US",
            "cityCode": "JLN",
            "cityName": "Joplin",
            "name": "[JLN] - Joplin Regional - JLN - Joplin - United States",
            "searchName": "JLN-Joplin-Joplin Regional-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JKV",
            "countryCode": "US",
            "cityCode": "JKV",
            "cityName": "Jacksonville",
            "name": "[JKV] - Cherokee County - JKV - Jacksonville - United States",
            "searchName": "JKV-Cherokee County-Cherokee County Havalimani-Jacksonville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JLA",
            "countryCode": "US",
            "cityCode": "JLA",
            "cityName": "Cooper Lodge",
            "name": "[JLA] - Quartz Creek - JLA - Cooper Lodge - United States",
            "searchName": "JLA-Quartz Creek-Cooper Lodge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JMS",
            "countryCode": "US",
            "cityCode": "JMS",
            "cityName": "Jamestown",
            "name": "[JMS] - Jamestown - JMS - Jamestown - United States",
            "searchName": "JMS-Jamestown Municipal-Jamestown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JMC",
            "countryCode": "US",
            "cityCode": "JMC",
            "cityName": "Sausalito",
            "name": "[JMC] - Marin County - JMC - Sausalito - United States",
            "searchName": "JMC-Marin County-Sausalito-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JMH",
            "countryCode": "US",
            "cityCode": "JMH",
            "cityName": "Schaumburg",
            "name": "[JMH] - Marriott Heliport - JMH - Schaumburg - United States",
            "searchName": "JMH-Marriott Heliport-Schaumburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JNP",
            "countryCode": "US",
            "cityCode": "JNP",
            "cityName": "Newport Beach",
            "name": "[JNP] - Heliport - JNP - Newport Beach - United States",
            "searchName": "JNP-Heliport-Newport Beach-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JNU",
            "countryCode": "US",
            "cityCode": "JNU",
            "cityName": "Juneau",
            "name": "[JNU] - Boundary Bay - JNU - Juneau - United States",
            "searchName": "JNU-Juneau-Boundary Bay-Juneau International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KBE",
            "countryCode": "US",
            "cityCode": "KBE",
            "cityName": "Bell Island",
            "name": "[KBE] - Hot Springs SPB - KBE - Bell Island - United States",
            "searchName": "KBE-Hot Springs-Hot Springs SPB-Bell Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KBK",
            "countryCode": "US",
            "cityCode": "KBK",
            "cityName": "Klag Bay",
            "name": "[KBK] - Klag Bay - KBK - Klag Bay - United States",
            "searchName": "KBK-Klag Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KBC",
            "countryCode": "US",
            "cityCode": "KBC",
            "cityName": "Birch Creek",
            "name": "[KBC] - Birch Creek - KBC - Birch Creek - United States",
            "searchName": "KBC-Birch Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KAE",
            "countryCode": "US",
            "cityCode": "KAE",
            "cityName": "Kake",
            "name": "[KAE] - SPB - KAE - Kake - United States",
            "searchName": "KAE-SPB-Kake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KAL",
            "countryCode": "US",
            "cityCode": "KAL",
            "cityName": "Kaltag",
            "name": "[KAL] - Kaltag - KAL - Kaltag - United States",
            "searchName": "KAL-Kaltag-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JXN",
            "countryCode": "US",
            "cityCode": "JXN",
            "cityName": "Jackson",
            "name": "[JXN] - Reynolds Municipal - JXN - Jackson - United States",
            "searchName": "JXN-Reynolds Municipal-Jackson Reynolds Belediyesi Havalimani-Jackson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JVI",
            "countryCode": "US",
            "cityCode": "JVI",
            "cityName": "Manville",
            "name": "[JVI] - Central Jersey Regional Airport - JVI - Manville - United States",
            "searchName": "JVI-Central Jersey Regional Airport-Kupper-Manville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JVL",
            "countryCode": "US",
            "cityCode": "JVL",
            "cityName": "Janesville",
            "name": "[JVL] - Rock County - JVL - Janesville - United States",
            "searchName": "JVL-Rock County-Janesville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KCR",
            "countryCode": "US",
            "cityCode": "KCR",
            "cityName": "Colorado Creek",
            "name": "[KCR] - Colorado Creek - KCR - Colorado Creek - United States",
            "searchName": "KCR-Colorado Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KCN",
            "countryCode": "US",
            "cityCode": "KCN",
            "cityName": "Chernofski",
            "name": "[KCN] - SPB - KCN - Chernofski - United States",
            "searchName": "KCN-SPB-Chernofski-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KCL",
            "countryCode": "US",
            "cityCode": "KCL",
            "cityName": "Chignik",
            "name": "[KCL] - Lagoon - KCL - Chignik - United States",
            "searchName": "KCL-Lagoon-Chignik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KCQ",
            "countryCode": "US",
            "cityCode": "KCL",
            "cityName": "Chignik",
            "name": "[KCQ] - Chignik - KCL - Chignik - United States",
            "searchName": "KCQ-KCL-Chignik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KCG",
            "countryCode": "US",
            "cityCode": "KCL",
            "cityName": "Chignik",
            "name": "[KCG] - Fisheries - KCL - Chignik - United States",
            "searchName": "KCG-KCL-Fisheries-Chignik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KBW",
            "countryCode": "US",
            "cityCode": "KCL",
            "cityName": "Chignik",
            "name": "[KBW] - Chignik Bay - KCL - Chignik - United States",
            "searchName": "KBW-KCL-Chignik Bay-Chignik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KCC",
            "countryCode": "US",
            "cityCode": "KCC",
            "cityName": "Coffman Cove",
            "name": "[KCC] - Coffman Cove SPB - KCC - Coffman Cove - United States",
            "searchName": "KCC-Coffman Cove SPB-Coffman Cove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KEH",
            "countryCode": "US",
            "cityCode": "KEH",
            "cityName": "Kenmore Air Harbor",
            "name": "[KEH] - Kenmore Air Harbor - KEH - Kenmore Air Harbor - United States",
            "searchName": "KEH-Kenmore Air Harbor-Kenmore Air Harbor Havalimani-Seattle-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KEB",
            "countryCode": "US",
            "cityCode": "KEB",
            "cityName": "Nanwalek",
            "name": "[KEB] - Nanwalek - KEB - Nanwalek - United States",
            "searchName": "KEB-Nanwalek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KEK",
            "countryCode": "US",
            "cityCode": "KEK",
            "cityName": "Ekwok",
            "name": "[KEK] - Ekwok - KEK - Ekwok - United States",
            "searchName": "KEK-Ekwok-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KEU",
            "countryCode": "US",
            "cityCode": "KEU",
            "cityName": "Kelly Bar",
            "name": "[KEU] - Kelly Bar - KEU - Kelly Bar - United States",
            "searchName": "KEU-Kelly Bar-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KFP",
            "countryCode": "US",
            "cityCode": "KFP",
            "cityName": "False Pass",
            "name": "[KFP] - False Pass - KFP - False Pass - United States",
            "searchName": "KFP-False Pass-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KSM",
            "countryCode": "US",
            "cityCode": "KSM",
            "cityName": "Saint Marys",
            "name": "[KSM] - Saint Marys - KSM - Saint Marys - United States",
            "searchName": "KSM-Saint Marys-St. Marys-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KSR",
            "countryCode": "US",
            "cityCode": "KSR",
            "cityName": "Sandy River",
            "name": "[KSR] - Federal No 1 - KSR - Sandy River - United States",
            "searchName": "KSR-Federal No 1-Sandy River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KTB",
            "countryCode": "US",
            "cityCode": "KTB",
            "cityName": "Thorne Bay",
            "name": "[KTB] - Thorne Bay - KTB - Thorne Bay - United States",
            "searchName": "KTB-Thorne Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KTN",
            "countryCode": "US",
            "cityCode": "KTN",
            "cityName": "Ketchikan",
            "name": "[KTN] - International - KTN - Ketchikan - United States",
            "searchName": "KTN-Ketchikan-International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WFB",
            "countryCode": "US",
            "cityCode": "KTN",
            "cityName": "Ketchikan",
            "name": "[WFB] - Waterfront SPB - KTN - Ketchikan - United States",
            "searchName": "WFB-KTN-Waterfront SPB-Ketchikan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KTH",
            "countryCode": "US",
            "cityCode": "KTH",
            "cityName": "Tikchik",
            "name": "[KTH] - SPB - KTH - Tikchik - United States",
            "searchName": "KTH-SPB-Tikchik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KNK",
            "countryCode": "US",
            "cityCode": "KNK",
            "cityName": "Kakhonak",
            "name": "[KNK] - Kakhonak - KNK - Kakhonak - United States",
            "searchName": "KNK-Kakhonak-Kakhonak Arpt-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KMY",
            "countryCode": "US",
            "cityCode": "KMY",
            "cityName": "Moser Bay",
            "name": "[KMY] - Moser Bay - KMY - Moser Bay - United States",
            "searchName": "KMY-Moser Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KNB",
            "countryCode": "US",
            "cityCode": "KNB",
            "cityName": "Kanab",
            "name": "[KNB] - Kanab - KNB - Kanab - United States",
            "searchName": "KNB-Kanab-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KNW",
            "countryCode": "US",
            "cityCode": "KNW",
            "cityName": "New Stuyahok",
            "name": "[KNW] - New Stuyahok - KNW - New Stuyahok - United States",
            "searchName": "KNW-New Stuyahok-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KOA",
            "countryCode": "US",
            "cityCode": "KOA",
            "cityName": "Kona",
            "name": "[KOA] - Kona International Airport - KOA - Kona - United States",
            "searchName": "KOA-Kailua Kona-Kona International Airport-Kailua-Kona-Keahole Airport-Kona Hawaii-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KNT",
            "countryCode": "US",
            "cityCode": "KNT",
            "cityName": "Kennett",
            "name": "[KNT] - Municipal - KNT - Kennett - United States",
            "searchName": "KNT-Municipal-Kennett  Havalimani-Kennett-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KOT",
            "countryCode": "US",
            "cityCode": "KOT",
            "cityName": "Kotlik",
            "name": "[KOT] - Kotlik - KOT - Kotlik - United States",
            "searchName": "KOT-Kotlik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KPB",
            "countryCode": "US",
            "cityCode": "KPB",
            "cityName": "Point Baker",
            "name": "[KPB] - Point Baker SPB - KPB - Point Baker - United States",
            "searchName": "KPB-Point Baker SPB-Point Baker-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KPC",
            "countryCode": "US",
            "cityCode": "KPC",
            "cityName": "Port Clarence",
            "name": "[KPC] - Port Clarence - KPC - Port Clarence - United States",
            "searchName": "KPC-Port Clarence-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KPD",
            "countryCode": "US",
            "cityCode": "KPD",
            "cityName": "King Of Prussia",
            "name": "[KPD] - King Of Prussia - KPD - King Of Prussia - United States",
            "searchName": "KPD-King Of Prussia-King of Prussia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KOY",
            "countryCode": "US",
            "cityCode": "KOY",
            "cityName": "Olga Bay",
            "name": "[KOY] - Olga Bay SPB - KOY - Olga Bay - United States",
            "searchName": "KOY-Olga Bay SPB-Olga Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KOZ",
            "countryCode": "US",
            "cityCode": "KOZ",
            "cityName": "Ouzinkie",
            "name": "[KOZ] - Ouzinkie SPB - KOZ - Ouzinkie - United States",
            "searchName": "KOZ-Ouzinkie SPB-Ouzinkie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KPN",
            "countryCode": "US",
            "cityCode": "KPN",
            "cityName": "Kipnuk",
            "name": "[KPN] - Kipnuk SPB - KPN - Kipnuk - United States",
            "searchName": "KPN-Kipnuk SPB-Kipnuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KPR",
            "countryCode": "US",
            "cityCode": "KPR",
            "cityName": "Port Williams",
            "name": "[KPR] - Port Williams SPB - KPR - Port Williams - United States",
            "searchName": "KPR-Port Williams SPB-Port Williams-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KPT",
            "countryCode": "US",
            "cityCode": "KPT",
            "cityName": "Jackpot",
            "name": "[KPT] - Jackpot - KPT - Jackpot - United States",
            "searchName": "KPT-Jackpot-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KPV",
            "countryCode": "US",
            "cityCode": "KPV",
            "cityName": "Perryville",
            "name": "[KPV] - Perryville SPB - KPV - Perryville - United States",
            "searchName": "KPV-Perryville SPB-Perryville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KPY",
            "countryCode": "US",
            "cityCode": "KPY",
            "cityName": "Port Bailey",
            "name": "[KPY] - Port Bailey SPB - KPY - Port Bailey - United States",
            "searchName": "KPY-Port Bailey SPB-Port Bailey-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KQA",
            "countryCode": "US",
            "cityCode": "KQA",
            "cityName": "Akutan",
            "name": "[KQA] - Akutan - KQA - Akutan - United States",
            "searchName": "KQA-Akutan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KPK",
            "countryCode": "US",
            "cityCode": "KPK",
            "cityName": "Parks",
            "name": "[KPK] - Parks SPB - KPK - Parks - United States",
            "searchName": "KPK-Parks SPB-Parks-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KPH",
            "countryCode": "US",
            "cityCode": "KPH",
            "cityName": "Pauloff Harbor",
            "name": "[KPH] - Pauloff Harbor SPB - KPH - Pauloff Harbor - United States",
            "searchName": "KPH-Pauloff-Pauloff Harbor SPB-Pauloff Harbor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KGK",
            "countryCode": "US",
            "cityCode": "KGK",
            "cityName": "New Koliganek",
            "name": "[KGK] - New Koliganek - KGK - New Koliganek - United States",
            "searchName": "KGK-New Koliganek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KGZ",
            "countryCode": "US",
            "cityCode": "KGZ",
            "cityName": "Glacier Creek",
            "name": "[KGZ] - Glacier Creek - KGZ - Glacier Creek - United States",
            "searchName": "KGZ-Glacier Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KGX",
            "countryCode": "US",
            "cityCode": "KGX",
            "cityName": "Grayling",
            "name": "[KGX] - Grayling - KGX - Grayling - United States",
            "searchName": "KGX-Grayling-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KIB",
            "countryCode": "US",
            "cityCode": "KIB",
            "cityName": "Ivanof Bay",
            "name": "[KIB] - SPB - KIB - Ivanof Bay - United States",
            "searchName": "KIB-SPB-Ivanof Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KIC",
            "countryCode": "US",
            "cityCode": "KIC",
            "cityName": "King City",
            "name": "[KIC] - Mesa Del Rey - KIC - King City - United States",
            "searchName": "KIC-Mesa Del Rey-Mesa Del Rey Havalimani-King City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KKA",
            "countryCode": "US",
            "cityCode": "KKA",
            "cityName": "Koyuk",
            "name": "[KKA] - Koyuk - KKA - Koyuk - United States",
            "searchName": "KKA-Koyuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KKB",
            "countryCode": "US",
            "cityCode": "KKB",
            "cityName": "Kitoi Bay",
            "name": "[KKB] - SPB - KKB - Kitoi Bay - United States",
            "searchName": "KKB-SPB-Kitoi Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KKF",
            "countryCode": "US",
            "cityCode": "KKF",
            "cityName": "Kagvik Creek",
            "name": "[KKF] - Kagvik Creek - KKF - Kagvik Creek - United States",
            "searchName": "KKF-Kagvik Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KKH",
            "countryCode": "US",
            "cityCode": "KKH",
            "cityName": "Kongiganak",
            "name": "[KKH] - Kongiganak - KKH - Kongiganak - United States",
            "searchName": "KKH-Kongiganak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KKI",
            "countryCode": "US",
            "cityCode": "KKI",
            "cityName": "Akiachak",
            "name": "[KKI] - Spb - KKI - Akiachak - United States",
            "searchName": "KKI-Spb-Akiachak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KKK",
            "countryCode": "US",
            "cityCode": "KKK",
            "cityName": "Kalakaket",
            "name": "[KKK] - Kalakaket AFS - KKK - Kalakaket - United States",
            "searchName": "KKK-Luftwaffenstützpunkt-Kalakaket AFS-Kalakaket-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KKL",
            "countryCode": "US",
            "cityCode": "KKL",
            "cityName": "Karluk Lake",
            "name": "[KKL] - Karluk Lake SPB - KKL - Karluk Lake - United States",
            "searchName": "KKL-Karluk Lake SPB-Karluk Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KLG",
            "countryCode": "US",
            "cityCode": "KLG",
            "cityName": "Kalskag",
            "name": "[KLG] - Municipal - KLG - Kalskag - United States",
            "searchName": "KLG-Kalskag-Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KLL",
            "countryCode": "US",
            "cityCode": "KLL",
            "cityName": "Levelock",
            "name": "[KLL] - Levelock - KLL - Levelock - United States",
            "searchName": "KLL-Levelock-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KKT",
            "countryCode": "US",
            "cityCode": "KKT",
            "cityName": "Kentland",
            "name": "[KKT] - Kentland - KKT - Kentland - United States",
            "searchName": "KKT-Kentland-Kentland Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KKU",
            "countryCode": "US",
            "cityCode": "KKU",
            "cityName": "Ekuk",
            "name": "[KKU] - Ekuk - KKU - Ekuk - United States",
            "searchName": "KKU-Ekuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KMO",
            "countryCode": "US",
            "cityCode": "KMO",
            "cityName": "Manokotak",
            "name": "[KMO] - Manokotak SPB - KMO - Manokotak - United States",
            "searchName": "KMO-Manokotak SPB-Manokotak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KLS",
            "countryCode": "US",
            "cityCode": "KLS",
            "cityName": "Kelso",
            "name": "[KLS] - Longview - KLS - Kelso - United States",
            "searchName": "KLS-Longview-Kelso Longview Yerel Havalimani-Kelso-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KLP",
            "countryCode": "US",
            "cityCode": "KLP",
            "cityName": "Kelp Bay",
            "name": "[KLP] - Kelp Bay - KLP - Kelp Bay - United States",
            "searchName": "KLP-Kelp Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KLN",
            "countryCode": "US",
            "cityCode": "KLN",
            "cityName": "Larsen Bay",
            "name": "[KLN] - Larsen SPB - KLN - Larsen Bay - United States",
            "searchName": "KLN-Larsen SPB-Larsen Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KLW",
            "countryCode": "US",
            "cityCode": "KLW",
            "cityName": "Klawock",
            "name": "[KLW] - Klawock - KLW - Klawock - United States",
            "searchName": "KLW-Klawock-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LXN",
            "countryCode": "US",
            "cityCode": "LXN",
            "cityName": "Lexington",
            "name": "[LXN] - Lexington - LXN - Lexington - United States",
            "searchName": "LXN-Lexington-Jim Kelley Field Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LXV",
            "countryCode": "US",
            "cityCode": "LXV",
            "cityName": "Leadville",
            "name": "[LXV] - Leadville - LXV - Leadville - United States",
            "searchName": "LXV-Leadville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LYO",
            "countryCode": "US",
            "cityCode": "LYO",
            "cityName": "Lyons",
            "name": "[LYO] - Lyons-Rice County Municipal - LYO - Lyons - United States",
            "searchName": "LYO-Lyons-Rice County Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LYH",
            "countryCode": "US",
            "cityCode": "LYH",
            "cityName": "Lynchburg",
            "name": "[LYH] - Preston-glenn Field - LYH - Lynchburg - United States",
            "searchName": "LYH-Lynchburg-Preston-glenn Field-Glenn Field-Lynchburg Belediyesi Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LZU",
            "countryCode": "US",
            "cityCode": "LZU",
            "cityName": "Lawrenceville",
            "name": "[LZU] - Gwinnett County Airport - LZU - Lawrenceville - United States",
            "searchName": "LZU-Gwinnett County Airport-Lawrenceville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LYU",
            "countryCode": "US",
            "cityCode": "LYU",
            "cityName": "Ely",
            "name": "[LYU] - Ely - LYU - Ely - United States",
            "searchName": "LYU-Ely-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LVD",
            "countryCode": "US",
            "cityCode": "LVD",
            "cityName": "Lime Village",
            "name": "[LVD] - Lime Village - LVD - Lime Village - United States",
            "searchName": "LVD-Lime Village-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LVK",
            "countryCode": "US",
            "cityCode": "LVK",
            "cityName": "Livermore",
            "name": "[LVK] - Livermore - LVK - Livermore - United States",
            "searchName": "LVK-Livermore-Bron Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LVL",
            "countryCode": "US",
            "cityCode": "LVL",
            "cityName": "Lawrenceville",
            "name": "[LVL] - Lawrenceville - LVL - Lawrenceville - United States",
            "searchName": "LVL-Lawrenceville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LVM",
            "countryCode": "US",
            "cityCode": "LVM",
            "cityName": "Livingston",
            "name": "[LVM] - Mission Field - LVM - Livingston - United States",
            "searchName": "LVM-Mission Field-Mission Field Havalimani-Livingston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LUR",
            "countryCode": "US",
            "cityCode": "LUR",
            "cityName": "Cape Lisburne",
            "name": "[LUR] - Cape Lisburne - LUR - Cape Lisburne - United States",
            "searchName": "LUR-Cape Lisburne-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LUP",
            "countryCode": "US",
            "cityCode": "LUP",
            "cityName": "Kalaupapa",
            "name": "[LUP] - Kalaupapa - LUP - Kalaupapa - United States",
            "searchName": "LUP-Kalaupapa-Kalaupapa Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LWB",
            "countryCode": "US",
            "cityCode": "LWB",
            "cityName": "Lewisburg",
            "name": "[LWB] - Greenbrier Valley - LWB - Lewisburg - United States",
            "searchName": "LWB-Greenbrier-Greenbrier Valley-Lewisburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LWC",
            "countryCode": "US",
            "cityCode": "LWC",
            "cityName": "Lawrence",
            "name": "[LWC] - Lawrence - LWC - Lawrence - United States",
            "searchName": "LWC-Lawrence-Lawrence Belediyesi Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LVS",
            "countryCode": "US",
            "cityCode": "LVS",
            "cityName": "Las Vegas",
            "name": "[LVS] - Las Vegas - LVS - Las Vegas - United States",
            "searchName": "LVS-Las Vegas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LWL",
            "countryCode": "US",
            "cityCode": "LWL",
            "cityName": "Wells",
            "name": "[LWL] - Harriet Field - LWL - Wells - United States",
            "searchName": "LWL-Harriet Field-Wells-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LWM",
            "countryCode": "US",
            "cityCode": "LWM",
            "cityName": "Lawrence",
            "name": "[LWM] - Lawrence - LWM - Lawrence - United States",
            "searchName": "LWM-Lawrence-Lawrence Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LWS",
            "countryCode": "US",
            "cityCode": "LWS",
            "cityName": "Lewiston",
            "name": "[LWS] - Nez Perce County Rgnl - LWS - Lewiston - United States",
            "searchName": "LWS-Lewiston-Nez Perce County Rgnl-Nez Perce Rgnl-Lewiston Idaho-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LWT",
            "countryCode": "US",
            "cityCode": "LWT",
            "cityName": "Lewistown",
            "name": "[LWT] - Municipal - LWT - Lewistown - United States",
            "searchName": "LWT-Lewistown-Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LWV",
            "countryCode": "US",
            "cityCode": "LWV",
            "cityName": "Lawrenceville",
            "name": "[LWV] - Municipal - LWV - Lawrenceville - United States",
            "searchName": "LWV-Municipal-Lawrenceville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LRJ",
            "countryCode": "US",
            "cityCode": "LRJ",
            "cityName": "Lemars",
            "name": "[LRJ] - Municipal - LRJ - Lemars - United States",
            "searchName": "LRJ-Municipal-Lemars-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LRK",
            "countryCode": "US",
            "cityCode": "LRK",
            "cityName": "Lincoln Rock",
            "name": "[LRK] - Coast Guard - LRK - Lincoln Rock - United States",
            "searchName": "LRK-der Küstenwache-Coast Guard-Lincoln Rock-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LRD",
            "countryCode": "US",
            "cityCode": "LRD",
            "cityName": "Laredo",
            "name": "[LRD] - International - LRD - Laredo - United States",
            "searchName": "LRD-Laredo-International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LQK",
            "countryCode": "US",
            "cityCode": "LQK",
            "cityName": "Pickens",
            "name": "[LQK] - Pickens - LQK - Pickens - United States",
            "searchName": "LQK-Pickens-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LRF",
            "countryCode": "US",
            "cityCode": "LRF",
            "cityName": "Jacksonville",
            "name": "[LRF] - Little Rock AFB - LRF - Jacksonville - United States",
            "searchName": "LRF-Luftwaffenstützpunkt Little Rock-Little Rock AFB-Jacksonville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LSB",
            "countryCode": "US",
            "cityCode": "LSB",
            "cityName": "Lordsburg",
            "name": "[LSB] - Lordsburg - LSB - Lordsburg - United States",
            "searchName": "LSB-Lordsburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LSE",
            "countryCode": "US",
            "cityCode": "LSE",
            "cityName": "La Crosse",
            "name": "[LSE] - Municipal - LSE - La Crosse - United States",
            "searchName": "LSE-La Crosse-Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LSF",
            "countryCode": "US",
            "cityCode": "LSF",
            "cityName": "Fort Benning",
            "name": "[LSF] - Lawson AAF - LSF - Fort Benning - United States",
            "searchName": "LSF-Lawson AAF-Fort Benning-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LRU",
            "countryCode": "US",
            "cityCode": "LRU",
            "cityName": "Las Cruces",
            "name": "[LRU] - Municipal - LRU - Las Cruces - United States",
            "searchName": "LRU-Municipal-Las Cruces Uluslararasi Havalimani-Las Cruces-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LSK",
            "countryCode": "US",
            "cityCode": "LSK",
            "cityName": "Lusk",
            "name": "[LSK] - Lusk - LSK - Lusk - United States",
            "searchName": "LSK-Lusk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LSN",
            "countryCode": "US",
            "cityCode": "LSN",
            "cityName": "Los Banos",
            "name": "[LSN] - Los Banos - LSN - Los Banos - United States",
            "searchName": "LSN-Los Banos-Los Banos Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LSR",
            "countryCode": "US",
            "cityCode": "LSR",
            "cityName": "Lost River",
            "name": "[LSR] - Lost River - LSR - Lost River - United States",
            "searchName": "LSR-Lost River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LUL",
            "countryCode": "US",
            "cityCode": "LUL",
            "cityName": "Laurel",
            "name": "[LUL] - Hesler-Noble Field - LUL - Laurel - United States",
            "searchName": "LUL-Hesler-Noble Field-Laurel-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PIB",
            "countryCode": "US",
            "cityCode": "LUL",
            "cityName": "Laurel",
            "name": "[PIB] - Hattiesburg-Laurel Regional - LUL - Laurel - United States",
            "searchName": "PIB-LUL-Laurel Hattiesburg-Hattiesburg-Laurel Regional-PineBelt-Laurel-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LTW",
            "countryCode": "US",
            "cityCode": "LTW",
            "cityName": "Leonardtown",
            "name": "[LTW] - St Marys County - LTW - Leonardtown - United States",
            "searchName": "LTW-St Marys County-Leonardtown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LTS",
            "countryCode": "US",
            "cityCode": "LTS",
            "cityName": "Altus",
            "name": "[LTS] - Altus AFB - LTS - Altus - United States",
            "searchName": "LTS-Altus AFB-Altus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AXS",
            "countryCode": "US",
            "cityCode": "LTS",
            "cityName": "Altus",
            "name": "[AXS] - Municipal - LTS - Altus - United States",
            "searchName": "AXS-LTS-Municipal-Altus  Havalimani-Altus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LKV",
            "countryCode": "US",
            "cityCode": "LKV",
            "cityName": "Lakeview",
            "name": "[LKV] - Lake County - LKV - Lakeview - United States",
            "searchName": "LKV-Lake County-Lakeview-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LKS",
            "countryCode": "US",
            "cityCode": "LKS",
            "cityName": "Lakeside",
            "name": "[LKS] - Lakeside - LKS - Lakeside - United States",
            "searchName": "LKS-Lakeside-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LKP",
            "countryCode": "US",
            "cityCode": "LKP",
            "cityName": "Lake Placid",
            "name": "[LKP] - Lake Placid - LKP - Lake Placid - United States",
            "searchName": "LKP-Lake Placid-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LJN",
            "countryCode": "US",
            "cityCode": "LJN",
            "cityName": "Lake Jackson",
            "name": "[LJN] - Brazoria County - LJN - Lake Jackson - United States",
            "searchName": "LJN-Brazoria County-Lake Jackson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LKK",
            "countryCode": "US",
            "cityCode": "LKK",
            "cityName": "Kulik Lake",
            "name": "[LKK] - Kulik Lake - LKK - Kulik Lake - United States",
            "searchName": "LKK-Kulik Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LLX",
            "countryCode": "US",
            "cityCode": "LLX",
            "cityName": "Lyndonville",
            "name": "[LLX] - Lyndonville - LLX - Lyndonville - United States",
            "searchName": "LLX-Lyndonville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LLY",
            "countryCode": "US",
            "cityCode": "LLY",
            "cityName": "Mount Holly",
            "name": "[LLY] - Burlington County - LLY - Mount Holly - United States",
            "searchName": "LLY-Burlington County-Burlington Country Havalimani-Mount Holly-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LMA",
            "countryCode": "US",
            "cityCode": "LMA",
            "cityName": "Lake Minchumina",
            "name": "[LMA] - Lake Minchumina - LMA - Lake Minchumina - United States",
            "searchName": "LMA-Lake Minchumina-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LMS",
            "countryCode": "US",
            "cityCode": "LMS",
            "cityName": "Louisville",
            "name": "[LMS] - Winston County - LMS - Louisville - United States",
            "searchName": "LMS-Winston County-Louisville Winston County Havalimani-Louisville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LMT",
            "countryCode": "US",
            "cityCode": "LMT",
            "cityName": "Klamath Falls",
            "name": "[LMT] - Kingsley Field - LMT - Klamath Falls - United States",
            "searchName": "LMT-Klamath Falls-Kingsley Field-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LNI",
            "countryCode": "US",
            "cityCode": "LNI",
            "cityName": "Lonely",
            "name": "[LNI] - Dew Station - LNI - Lonely - United States",
            "searchName": "LNI-Dew Station-Lonely-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LND",
            "countryCode": "US",
            "cityCode": "LND",
            "cityName": "Lander",
            "name": "[LND] - Hunt Field - LND - Lander - United States",
            "searchName": "LND-Hunt Field-Lander-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LNY",
            "countryCode": "US",
            "cityCode": "LNY",
            "cityName": "Lanai City",
            "name": "[LNY] - Lanai City - LNY - Lanai City - United States",
            "searchName": "LNY-Lanai-Lanai City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LNN",
            "countryCode": "US",
            "cityCode": "LNN",
            "cityName": "Willoughby",
            "name": "[LNN] - Lost Nation - LNN - Willoughby - United States",
            "searchName": "LNN-Lost Nation-Willoughby-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LNK",
            "countryCode": "US",
            "cityCode": "LNK",
            "cityName": "Lincoln",
            "name": "[LNK] - Lincoln - LNK - Lincoln - United States",
            "searchName": "LNK-Lincoln-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LNP",
            "countryCode": "US",
            "cityCode": "LNP",
            "cityName": "Wise",
            "name": "[LNP] - Wise - LNP - Wise - United States",
            "searchName": "LNP-Wise-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LNR",
            "countryCode": "US",
            "cityCode": "LNR",
            "cityName": "Lone Rock",
            "name": "[LNR] - Tri County - LNR - Lone Rock - United States",
            "searchName": "LNR-Tri County-Lone Rock-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LNS",
            "countryCode": "US",
            "cityCode": "LNS",
            "cityName": "Lancaster",
            "name": "[LNS] - Lancaster - LNS - Lancaster - United States",
            "searchName": "LNS-Lancaster-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZRL",
            "countryCode": "US",
            "cityCode": "LNS",
            "cityName": "Lancaster",
            "name": "[ZRL] - Lancaster  Railway - LNS - Lancaster - United States",
            "searchName": "ZRL-LNS-Lancaster  Railway-Lancaster-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LPW",
            "countryCode": "US",
            "cityCode": "LPW",
            "cityName": "Little Port Walter",
            "name": "[LPW] - Little Port Walter - LPW - Little Port Walter - United States",
            "searchName": "LPW-Little Port Walter-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LPO",
            "countryCode": "US",
            "cityCode": "LPO",
            "cityName": "Laporte",
            "name": "[LPO] - Municipal - LPO - Laporte - United States",
            "searchName": "LPO-Municipal-Laporte-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LPS",
            "countryCode": "US",
            "cityCode": "LPS",
            "cityName": "Lopez Island",
            "name": "[LPS] - Lopez Island - LPS - Lopez Island - United States",
            "searchName": "LPS-Lopez Island--Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LOT",
            "countryCode": "US",
            "cityCode": "LOT",
            "cityName": "Lockport",
            "name": "[LOT] - Lewis Lockport - LOT - Lockport - United States",
            "searchName": "LOT-Lewis Lockport-Lockport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LOW",
            "countryCode": "US",
            "cityCode": "LOW",
            "cityName": "Louisa",
            "name": "[LOW] - Louisa - LOW - Louisa - United States",
            "searchName": "LOW-Louisa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LOZ",
            "countryCode": "US",
            "cityCode": "LOZ",
            "cityName": "London",
            "name": "[LOZ] - Corbin-london - LOZ - London - United States",
            "searchName": "LOZ-Corbin-london-Corbin London Havalimani-London-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LPC",
            "countryCode": "US",
            "cityCode": "LPC",
            "cityName": "Lompoc",
            "name": "[LPC] - Lompoc Airport - LPC - Lompoc - United States",
            "searchName": "LPC-Lompoc Airport-Lompoc Havalimani-Lompoc-Santa Maria-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VBG",
            "countryCode": "US",
            "cityCode": "LPC",
            "cityName": "Lompoc",
            "name": "[VBG] - Vandenberg AFB - LPC - Lompoc - United States",
            "searchName": "VBG-LPC-Vandenberg AFB-Lompoc-Santa Maria-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LOG",
            "countryCode": "US",
            "cityCode": "LOG",
            "cityName": "Longview",
            "name": "[LOG] - Longview - LOG - Longview - United States",
            "searchName": "LOG-Longview-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LOL",
            "countryCode": "US",
            "cityCode": "LOL",
            "cityName": "Lovelock",
            "name": "[LOL] - Derby Field - LOL - Lovelock - United States",
            "searchName": "LOL-Derby Field-Lovelock-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KWF",
            "countryCode": "US",
            "cityCode": "KWF",
            "cityName": "Waterfall",
            "name": "[KWF] - Waterfall SPB - KWF - Waterfall - United States",
            "searchName": "KWF-Waterfall SPB-Waterfall Spb-Waterfall-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KVC",
            "countryCode": "US",
            "cityCode": "KVC",
            "cityName": "King Cove",
            "name": "[KVC] - King Cove - KVC - King Cove - United States",
            "searchName": "KVC-King Cove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KVL",
            "countryCode": "US",
            "cityCode": "KVL",
            "cityName": "Kivalina",
            "name": "[KVL] - Kivalina - KVL - Kivalina - United States",
            "searchName": "KVL-Kivalina-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KXA",
            "countryCode": "US",
            "cityCode": "KXA",
            "cityName": "Kasaan",
            "name": "[KXA] - Kasaan SPB - KXA - Kasaan - United States",
            "searchName": "KXA-Kasaan SPB-Kasaan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KWT",
            "countryCode": "US",
            "cityCode": "KWT",
            "cityName": "Kwethluk",
            "name": "[KWT] - Kwethluk - KWT - Kwethluk - United States",
            "searchName": "KWT-Kwethluk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KWP",
            "countryCode": "US",
            "cityCode": "KWP",
            "cityName": "West Point",
            "name": "[KWP] - Village SPB - KWP - West Point - United States",
            "searchName": "KWP-Village SPB-West Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KWK",
            "countryCode": "US",
            "cityCode": "KWK",
            "cityName": "Kwigillingok",
            "name": "[KWK] - Kwigillingok - KWK - Kwigillingok - United States",
            "searchName": "KWK-Kwigillingok-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KWN",
            "countryCode": "US",
            "cityCode": "KWN",
            "cityName": "Quinhagak",
            "name": "[KWN] - Kwinhagak - KWN - Quinhagak - United States",
            "searchName": "KWN-Kwinhagak-Quinhagak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KUK",
            "countryCode": "US",
            "cityCode": "KUK",
            "cityName": "Kasigluk",
            "name": "[KUK] - Kasigluk - KUK - Kasigluk - United States",
            "searchName": "KUK-Kasigluk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KTS",
            "countryCode": "US",
            "cityCode": "KTS",
            "cityName": "Teller Mission",
            "name": "[KTS] - Brevig Mission - KTS - Teller Mission - United States",
            "searchName": "KTS-Brevig Mission-Teller Mission-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KUW",
            "countryCode": "US",
            "cityCode": "KUW",
            "cityName": "Kugururok River",
            "name": "[KUW] - Kugururok River - KUW - Kugururok River - United States",
            "searchName": "KUW-Kugururok River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LAA",
            "countryCode": "US",
            "cityCode": "LAA",
            "cityName": "Lamar",
            "name": "[LAA] - Lamar Field - LAA - Lamar - United States",
            "searchName": "LAA-Lamar Field-Lamar Havalimani-Lamar-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LAF",
            "countryCode": "US",
            "cityCode": "LAF",
            "cityName": "Lafayette",
            "name": "[LAF] - Purdue University - LAF - Lafayette - United States",
            "searchName": "LAF-Purdue University-Lafayette-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LAL",
            "countryCode": "US",
            "cityCode": "LAL",
            "cityName": "Lakeland",
            "name": "[LAL] - Municipal - LAL - Lakeland - United States",
            "searchName": "LAL-Municipal-Lakeland Havalimani-Lakeland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LAM",
            "countryCode": "US",
            "cityCode": "LAM",
            "cityName": "Los Alamos",
            "name": "[LAM] - Los Alamos - LAM - Los Alamos - United States",
            "searchName": "LAM-Los Alamos-Los Alamos Belediyesi Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LAN",
            "countryCode": "US",
            "cityCode": "LAN",
            "cityName": "Lansing",
            "name": "[LAN] - Capital City - LAN - Lansing - United States",
            "searchName": "LAN-Lansing-Capital City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KZH",
            "countryCode": "US",
            "cityCode": "KZH",
            "cityName": "Kizhuyak",
            "name": "[KZH] - Kizhuyak - KZH - Kizhuyak - United States",
            "searchName": "KZH-Kizhuyak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KZB",
            "countryCode": "US",
            "cityCode": "KZB",
            "cityName": "Zachar Bay",
            "name": "[KZB] - Zachar Bay SPB - KZB - Zachar Bay - United States",
            "searchName": "KZB-Zachar Bay SPB-Zachar Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KYU",
            "countryCode": "US",
            "cityCode": "KYU",
            "cityName": "Koyukuk",
            "name": "[KYU] - Koyukuk - KYU - Koyukuk - United States",
            "searchName": "KYU-Koyukuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KYK",
            "countryCode": "US",
            "cityCode": "KYK",
            "cityName": "Karluk",
            "name": "[KYK] - Karluk - KYK - Karluk - United States",
            "searchName": "KYK-Karluk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LBE",
            "countryCode": "US",
            "cityCode": "LBE",
            "cityName": "Latrobe",
            "name": "[LBE] - Westmoreland County - LBE - Latrobe - United States",
            "searchName": "LBE-Latrobe USA-Westmoreland County-Latrobe-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LBF",
            "countryCode": "US",
            "cityCode": "LBF",
            "cityName": "North Platte",
            "name": "[LBF] - Lee Bird Field - LBF - North Platte - United States",
            "searchName": "LBF-North Platte-Lee Bird Field-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LBT",
            "countryCode": "US",
            "cityCode": "LBT",
            "cityName": "Lumberton",
            "name": "[LBT] - Lumberton - LBT - Lumberton - United States",
            "searchName": "LBT-Lumberton-Lumberton Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LBL",
            "countryCode": "US",
            "cityCode": "LBL",
            "cityName": "Liberal",
            "name": "[LBL] - Municipal - LBL - Liberal - United States",
            "searchName": "LBL-Liberal-Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "REE",
            "countryCode": "US",
            "cityCode": "LBB",
            "cityName": "Lubbock",
            "name": "[REE] - Reese AFB - LBB - Lubbock - United States",
            "searchName": "REE-LBB-Reese AFB-Lubbock-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LBB",
            "countryCode": "US",
            "cityCode": "LBB",
            "cityName": "Lubbock",
            "name": "[LBB] - Lubbock Preston Smith International - LBB - Lubbock - United States",
            "searchName": "LBB-Lubbock-Lubbock Preston Smith International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LAR",
            "countryCode": "US",
            "cityCode": "LAR",
            "cityName": "Laramie",
            "name": "[LAR] - General Brees Field - LAR - Laramie - United States",
            "searchName": "LAR-Laramie-General Brees Field-Gen. Brees Field-General Brees Fld-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CIG",
            "countryCode": "US",
            "cityCode": "CIG",
            "cityName": "Craig",
            "name": "[CIG] - Craig-Moffat - CIG - Craig - United States",
            "searchName": "CIG-Craig-Moffat-Craig Moffat Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CHZ",
            "countryCode": "US",
            "cityCode": "CHZ",
            "cityName": "Chiloquin",
            "name": "[CHZ] - State - CHZ - Chiloquin - United States",
            "searchName": "CHZ-State-Chiloquin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CIC",
            "countryCode": "US",
            "cityCode": "CIC",
            "cityName": "Chico",
            "name": "[CIC] - Chico - CIC - Chico - United States",
            "searchName": "CIC-Chico-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CID",
            "countryCode": "US",
            "cityCode": "CID",
            "cityName": "Cedar Rapids",
            "name": "[CID] - Eastern Iowa Airport - CID - Cedar Rapids - United States",
            "searchName": "CID-Cedar Rapids-Eastern Iowa Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CIR",
            "countryCode": "US",
            "cityCode": "CIR",
            "cityName": "Cairo",
            "name": "[CIR] - Cairo - CIR - Cairo - United States",
            "searchName": "CIR-Cairo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CIN",
            "countryCode": "US",
            "cityCode": "CIN",
            "cityName": "Carroll",
            "name": "[CIN] - Carroll - CIN - Carroll - United States",
            "searchName": "CIN-Carroll-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CIK",
            "countryCode": "US",
            "cityCode": "CIK",
            "cityName": "Chalkyitsik",
            "name": "[CIK] - Chalkyitsik - CIK - Chalkyitsik - United States",
            "searchName": "CIK-Chalkyitsik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CIL",
            "countryCode": "US",
            "cityCode": "CIL",
            "cityName": "Council",
            "name": "[CIL] - Melsing Creek - CIL - Council - United States",
            "searchName": "CIL-Melsing Creek-Melsing Creek Havalimani-Council-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CHU",
            "countryCode": "US",
            "cityCode": "CHU",
            "cityName": "Chuathbaluk",
            "name": "[CHU] - Chuathbaluk - CHU - Chuathbaluk - United States",
            "searchName": "CHU-Chuathbaluk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CHO",
            "countryCode": "US",
            "cityCode": "CHO",
            "cityName": "Charlottesville",
            "name": "[CHO] - Albemarle - CHO - Charlottesville - United States",
            "searchName": "CHO-Charlottesville-Albemarle-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CHP",
            "countryCode": "US",
            "cityCode": "CHP",
            "cityName": "Circle Hot Springs",
            "name": "[CHP] - Circle Hot Springs - CHP - Circle Hot Springs - United States",
            "searchName": "CHP-Circle Hot Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CHS",
            "countryCode": "US",
            "cityCode": "CHS",
            "cityName": "Charleston",
            "name": "[CHS] - Charleston, AFB Municipal - CHS - Charleston - United States",
            "searchName": "CHS-Charleston SC-Charleston- AFB Municipal-AFB-Municipal-Charleston, AFB Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CHK",
            "countryCode": "US",
            "cityCode": "CHK",
            "cityName": "Chickasha",
            "name": "[CHK] - Municipal - CHK - Chickasha - United States",
            "searchName": "CHK-Municipal-Chickasha-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CHL",
            "countryCode": "US",
            "cityCode": "CHL",
            "cityName": "Challis",
            "name": "[CHL] - Challis - CHL - Challis - United States",
            "searchName": "CHL-Challis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CGZ",
            "countryCode": "US",
            "cityCode": "CGZ",
            "cityName": "Casa Grande",
            "name": "[CGZ] - Municipal - CGZ - Casa Grande - United States",
            "searchName": "CGZ-Municipal-Casa Grande-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CHA",
            "countryCode": "US",
            "cityCode": "CHA",
            "cityName": "Chattanooga",
            "name": "[CHA] - Lovell Field - CHA - Chattanooga - United States",
            "searchName": "CHA-Chattanooga-Lovell Field-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "QDK",
            "countryCode": "US",
            "cityCode": "CHA",
            "cityName": "Chattanooga",
            "name": "[QDK] - Greyhound Bus Station - CHA - Chattanooga - United States",
            "searchName": "QDK-CHA-Greyhound Bus Station-Chattanooga-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CGS",
            "countryCode": "US",
            "cityCode": "CGS",
            "cityName": "College Park",
            "name": "[CGS] - College Park - CGS - College Park - United States",
            "searchName": "CGS-College Park-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CHI",
            "countryCode": "US",
            "cityCode": "CHI",
            "cityName": "Chicago",
            "name": "[CHI] - Chicago FSS - CHI - Chicago - United States",
            "searchName": "CHI-Chicago FSS-Chicago-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DPA",
            "countryCode": "US",
            "cityCode": "CHI",
            "cityName": "Chicago",
            "name": "[DPA] - Dupage County - CHI - Chicago - United States",
            "searchName": "DPA-CHI-Dupage County-Dupage County Havalimani-Chicago-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NOH",
            "countryCode": "US",
            "cityCode": "CHI",
            "cityName": "Chicago",
            "name": "[NOH] - Chicago NAS - CHI - Chicago - United States",
            "searchName": "NOH-CHI-Chicago NAS-Chicago-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LUK",
            "countryCode": "US",
            "cityCode": "CVG",
            "cityName": "Covington",
            "name": "[LUK] - Cincinnati Municipal-Lunken Field - CVG - Covington - United States",
            "searchName": "LUK-CVG-Cincinnati-Cincinnati Municipal-Lunken Field-Cincinnati  Havalimani-Covington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CUS",
            "countryCode": "US",
            "cityCode": "CUS",
            "cityName": "Columbus",
            "name": "[CUS] - Municipal - CUS - Columbus - United States",
            "searchName": "CUS-Municipal-Columbus Belediyesi Havalimani-Columbus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CUH",
            "countryCode": "US",
            "cityCode": "CUH",
            "cityName": "Cushing",
            "name": "[CUH] - Municipal - CUH - Cushing - United States",
            "searchName": "CUH-Municipal-Cushing-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CTW",
            "countryCode": "US",
            "cityCode": "CTW",
            "cityName": "Cottonwood",
            "name": "[CTW] - Cottonwood - CTW - Cottonwood - United States",
            "searchName": "CTW-Cottonwood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CTX",
            "countryCode": "US",
            "cityCode": "CTX",
            "cityName": "Cortland",
            "name": "[CTX] - Cortland - CTX - Cortland - United States",
            "searchName": "CTX-Cortland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CTY",
            "countryCode": "US",
            "cityCode": "CTY",
            "cityName": "Cross City",
            "name": "[CTY] - Cross City - CTY - Cross City - United States",
            "searchName": "CTY-Cross City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CTZ",
            "countryCode": "US",
            "cityCode": "CTZ",
            "cityName": "Clinton",
            "name": "[CTZ] - Sampson County - CTZ - Clinton - United States",
            "searchName": "CTZ-Sampson County-Clinton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CTB",
            "countryCode": "US",
            "cityCode": "CTB",
            "cityName": "Cut Bank",
            "name": "[CTB] - Cut Bank - CTB - Cut Bank - United States",
            "searchName": "CTB-Cut Bank-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CTK",
            "countryCode": "US",
            "cityCode": "CTK",
            "cityName": "Canton",
            "name": "[CTK] - Canton - CTK - Canton - United States",
            "searchName": "CTK-Canton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CTH",
            "countryCode": "US",
            "cityCode": "CTH",
            "cityName": "Coatesville",
            "name": "[CTH] - Chestercounty Carlson - CTH - Coatesville - United States",
            "searchName": "CTH-Chestercounty Carlson-Coatesville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CTO",
            "countryCode": "US",
            "cityCode": "CTO",
            "cityName": "Calverton",
            "name": "[CTO] - Peconic River - CTO - Calverton - United States",
            "searchName": "CTO-Peconic River-Calverton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DAY",
            "countryCode": "US",
            "cityCode": "DAY",
            "cityName": "Dayton",
            "name": "[DAY] - James Cox Dayton International - DAY - Dayton - United States",
            "searchName": "DAY-Dayton-James Cox Dayton International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FFO",
            "countryCode": "US",
            "cityCode": "DAY",
            "cityName": "Dayton",
            "name": "[FFO] - Patterson AFB - DAY - Dayton - United States",
            "searchName": "FFO-DAY-Patterson AFB-Dayton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MGY",
            "countryCode": "US",
            "cityCode": "DAY",
            "cityName": "Dayton",
            "name": "[MGY] - Dayton-Wright Brothers Airport - DAY - Dayton - United States",
            "searchName": "MGY-DAY-Dayton-Wright Brothers Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DAN",
            "countryCode": "US",
            "cityCode": "DAN",
            "cityName": "Danville",
            "name": "[DAN] - Municipal - DAN - Danville - United States",
            "searchName": "DAN-Municipal-Danville Belediyesi Havalimani-Danville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DAG",
            "countryCode": "US",
            "cityCode": "DAG",
            "cityName": "Daggett",
            "name": "[DAG] - Barstow-Daggett - DAG - Daggett - United States",
            "searchName": "DAG-Barstow-Daggett-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CZF",
            "countryCode": "US",
            "cityCode": "CZF",
            "cityName": "Cape Romanzof",
            "name": "[CZF] - Cape Romanzof - CZF - Cape Romanzof - United States",
            "searchName": "CZF-Cape Romanzof-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CZZ",
            "countryCode": "US",
            "cityCode": "CZZ",
            "cityName": "Campo",
            "name": "[CZZ] - Campo - CZZ - Campo - United States",
            "searchName": "CZZ-Campo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DAA",
            "countryCode": "US",
            "cityCode": "DAA",
            "cityName": "Fort Belvoir",
            "name": "[DAA] - Davison AAF - DAA - Fort Belvoir - United States",
            "searchName": "DAA-Militärflughafen Davison-Davison AAF-Fort Belvoir-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DAB",
            "countryCode": "US",
            "cityCode": "DAB",
            "cityName": "Daytona Beach",
            "name": "[DAB] - Regional - DAB - Daytona Beach - United States",
            "searchName": "DAB-Daytona Beach-Regional-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CZK",
            "countryCode": "US",
            "cityCode": "CZK",
            "cityName": "Cascade Locks",
            "name": "[CZK] - Cascade Locks/Stevens - CZK - Cascade Locks - United States",
            "searchName": "CZK-Cascade Locks/Stevens-Cascade Locks-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CZT",
            "countryCode": "US",
            "cityCode": "CZT",
            "cityName": "Carrizo Springs",
            "name": "[CZT] - Carrizo Springs - CZT - Carrizo Springs - United States",
            "searchName": "CZT-Carrizo Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CZN",
            "countryCode": "US",
            "cityCode": "CZN",
            "cityName": "Chisana",
            "name": "[CZN] - Chisana Field - CZN - Chisana - United States",
            "searchName": "CZN-Chisana Field-Chisana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CZO",
            "countryCode": "US",
            "cityCode": "CZO",
            "cityName": "Chistochina",
            "name": "[CZO] - Chistochina - CZO - Chistochina - United States",
            "searchName": "CZO-Chistochina-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CZP",
            "countryCode": "US",
            "cityCode": "CZP",
            "cityName": "Cape Pole",
            "name": "[CZP] - Cape Pole - CZP - Cape Pole - United States",
            "searchName": "CZP-Cape Pole-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CYM",
            "countryCode": "US",
            "cityCode": "CYM",
            "cityName": "Chatham",
            "name": "[CYM] - Chatham SPB - CYM - Chatham - United States",
            "searchName": "CYM-Chatham SPB-Chatham-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZUN",
            "countryCode": "US",
            "cityCode": "CHI",
            "cityName": "Chicago",
            "name": "[ZUN] - Chicago Union Railway Station - CHI - Chicago - United States",
            "searchName": "ZUN-CHI-Chicago Union Railway Station-Chicago-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CGI",
            "countryCode": "US",
            "cityCode": "CGI",
            "cityName": "Cape Girardeau",
            "name": "[CGI] - Cape Girardeau - CGI - Cape Girardeau - United States",
            "searchName": "CGI-Cape Girardeau-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CGE",
            "countryCode": "US",
            "cityCode": "CGE",
            "cityName": "Cambridge",
            "name": "[CGE] - Cambridge - CGE - Cambridge - United States",
            "searchName": "CGE-Cambridge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CFV",
            "countryCode": "US",
            "cityCode": "CFV",
            "cityName": "Coffeyville",
            "name": "[CFV] - Municipal - CFV - Coffeyville - United States",
            "searchName": "CFV-Municipal-Coffeyville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CGA",
            "countryCode": "US",
            "cityCode": "CGA",
            "cityName": "Craig",
            "name": "[CGA] - Craig SPB - CGA - Craig - United States",
            "searchName": "CGA-Craig SPB-Craig-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CFT",
            "countryCode": "US",
            "cityCode": "CFT",
            "cityName": "Clifton",
            "name": "[CFT] - Morenci - CFT - Clifton - United States",
            "searchName": "CFT-Morenci-Clifton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CEM",
            "countryCode": "US",
            "cityCode": "CEM",
            "cityName": "Central",
            "name": "[CEM] - Central - CEM - Central - United States",
            "searchName": "CEM-Central-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CEF",
            "countryCode": "US",
            "cityCode": "CEF",
            "cityName": "Chicopee",
            "name": "[CEF] - Westover ARB/Metropolitan - CEF - Chicopee - United States",
            "searchName": "CEF-Ballungsgebiet Westover-Westover ARB/Metropolitan-Westover Metropolitan Havalimani-Springfield-Chicopee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CEC",
            "countryCode": "US",
            "cityCode": "CEC",
            "cityName": "Crescent City",
            "name": "[CEC] - Mc Namara Field - CEC - Crescent City - United States",
            "searchName": "CEC-Crescent City-Mc Namara Field-McNamara Field-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CEU",
            "countryCode": "US",
            "cityCode": "CEU",
            "cityName": "Clemson",
            "name": "[CEU] - Oconee County - CEU - Clemson - United States",
            "searchName": "CEU-Oconee County-Clemson Oconee Cty Havalimani-Clemson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CEV",
            "countryCode": "US",
            "cityCode": "CEV",
            "cityName": "Connersville",
            "name": "[CEV] - Mettle Field - CEV - Connersville - United States",
            "searchName": "CEV-Mettle Field-Connersville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CEW",
            "countryCode": "US",
            "cityCode": "CEW",
            "cityName": "Crestview",
            "name": "[CEW] - Bob Sikes - CEW - Crestview - United States",
            "searchName": "CEW-Bob Sikes-Crestview-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CEX",
            "countryCode": "US",
            "cityCode": "CEX",
            "cityName": "Chena Hot Springs",
            "name": "[CEX] - Chena Hot Springs - CEX - Chena Hot Springs - United States",
            "searchName": "CEX-Chena Hot Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CEY",
            "countryCode": "US",
            "cityCode": "CEY",
            "cityName": "Murray",
            "name": "[CEY] - Calloway County - CEY - Murray - United States",
            "searchName": "CEY-Calloway County-Calloway Cty Havalimani-Murray-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CEZ",
            "countryCode": "US",
            "cityCode": "CEZ",
            "cityName": "Cortez",
            "name": "[CEZ] - Montezuma County - CEZ - Cortez - United States",
            "searchName": "CEZ-Cortez-Montezuma County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CFA",
            "countryCode": "US",
            "cityCode": "CFA",
            "cityName": "Coffee Point",
            "name": "[CFA] - Coffee Point - CFA - Coffee Point - United States",
            "searchName": "CFA-Coffee Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CFD",
            "countryCode": "US",
            "cityCode": "CFD",
            "cityName": "Bryan",
            "name": "[CFD] - Coulter Field - CFD - Bryan - United States",
            "searchName": "CFD-Coulter Field-Bryan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CDH",
            "countryCode": "US",
            "cityCode": "CDH",
            "cityName": "Camden",
            "name": "[CDH] - Harrell Field - CDH - Camden - United States",
            "searchName": "CDH-Harrell Field-Harrell Fieldsandro Havalimani-Camden-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CDB",
            "countryCode": "US",
            "cityCode": "CDB",
            "cityName": "Cold Bay",
            "name": "[CDB] - Cold Bay - CDB - Cold Bay - United States",
            "searchName": "CDB-Cold Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CDC",
            "countryCode": "US",
            "cityCode": "CDC",
            "cityName": "Cedar City",
            "name": "[CDC] - Cedar City - CDC - Cedar City - United States",
            "searchName": "CDC-Cedar City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CDK",
            "countryCode": "US",
            "cityCode": "CDK",
            "cityName": "Cedar Key",
            "name": "[CDK] - Lewis - CDK - Cedar Key - United States",
            "searchName": "CDK-George T Lewis-Lewis-Cedar Key-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CDL",
            "countryCode": "US",
            "cityCode": "CDL",
            "cityName": "Candle",
            "name": "[CDL] - Candle - CDL - Candle - United States",
            "searchName": "CDL-Candle-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CDN",
            "countryCode": "US",
            "cityCode": "CDN",
            "cityName": "Camden",
            "name": "[CDN] - Woodward Field - CDN - Camden - United States",
            "searchName": "CDN-Woodward Field-Camden-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CDR",
            "countryCode": "US",
            "cityCode": "CDR",
            "cityName": "Chadron",
            "name": "[CDR] - Chadron - CDR - Chadron - United States",
            "searchName": "CDR-Chadron-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CDS",
            "countryCode": "US",
            "cityCode": "CDS",
            "cityName": "Childress",
            "name": "[CDS] - Childress - CDS - Childress - United States",
            "searchName": "CDS-Childress-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CDV",
            "countryCode": "US",
            "cityCode": "CDV",
            "cityName": "Cordova",
            "name": "[CDV] - Mudhole Smith - CDV - Cordova - United States",
            "searchName": "CDV-Cordova-Mudhole Smith-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CKU",
            "countryCode": "US",
            "cityCode": "CDV",
            "cityName": "Cordova",
            "name": "[CKU] - City - CDV - Cordova - United States",
            "searchName": "CKU-CDV-City-Cordova-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CDW",
            "countryCode": "US",
            "cityCode": "CDW",
            "cityName": "Caldwell",
            "name": "[CDW] - Caldwell Wright - CDW - Caldwell - United States",
            "searchName": "CDW-Wright-Caldwell Wright-Caldwell Wright Havalimani-Caldwell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CCR",
            "countryCode": "US",
            "cityCode": "CCR",
            "cityName": "Concord",
            "name": "[CCR] - Buchanan Field - CCR - Concord - United States",
            "searchName": "CCR-Landeplatz Buchanan-Buchanan Field-Concord-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CCY",
            "countryCode": "US",
            "cityCode": "CCY",
            "cityName": "Charles City",
            "name": "[CCY] - Municipal - CCY - Charles City - United States",
            "searchName": "CCY-Municipal-Charles City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CAO",
            "countryCode": "US",
            "cityCode": "CAO",
            "cityName": "Clayton",
            "name": "[CAO] - Clayton - CAO - Clayton - United States",
            "searchName": "CAO-Clayton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CAK",
            "countryCode": "US",
            "cityCode": "CAK",
            "cityName": "Akron/Canton",
            "name": "[CAK] - Akron/canton Regional - CAK - Akron/Canton - United States",
            "searchName": "CAK-Akron Canton-Akron/canton Regional-Akron/Canton-Akron-Akron\\/Canton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AKC",
            "countryCode": "US",
            "cityCode": "CAK",
            "cityName": "Akron/Canton",
            "name": "[AKC] - Fulton International - CAK - Akron/Canton - United States",
            "searchName": "AKC-CAK-Fulton International-Akron/Canton-Akron-Akron\\/Canton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CAR",
            "countryCode": "US",
            "cityCode": "CAR",
            "cityName": "Caribou",
            "name": "[CAR] - Municipal - CAR - Caribou - United States",
            "searchName": "CAR-Municipal-Caribou  Havalimani-Caribou-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BZN",
            "countryCode": "US",
            "cityCode": "BZN",
            "cityName": "Bozeman",
            "name": "[BZN] - Gallatin Field - BZN - Bozeman - United States",
            "searchName": "BZN-Bozeman-Gallatin Field-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BZT",
            "countryCode": "US",
            "cityCode": "BZT",
            "cityName": "Brazoria",
            "name": "[BZT] - Hinkles Ferry - BZT - Brazoria - United States",
            "searchName": "BZT-Hinkels Ferry-Hinkles Ferry-Brazoria County Havalimani-Brazoria-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CAD",
            "countryCode": "US",
            "cityCode": "CAD",
            "cityName": "Cadillac",
            "name": "[CAD] - Cadillac - CAD - Cadillac - United States",
            "searchName": "CAD-Cadillac-Wexford County Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CAE",
            "countryCode": "US",
            "cityCode": "CAE",
            "cityName": "Columbia",
            "name": "[CAE] - Metropolitan Airport - CAE - Columbia - United States",
            "searchName": "CAE-Columbia Metropolitan-Metropolitan Airport-Columbia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CUB",
            "countryCode": "US",
            "cityCode": "CAE",
            "cityName": "Columbia",
            "name": "[CUB] - Owens Field - CAE - Columbia - United States",
            "searchName": "CUB-CAE-Owens Field-Columbia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MMT",
            "countryCode": "US",
            "cityCode": "CAE",
            "cityName": "Columbia",
            "name": "[MMT] - Mc Entire ANG Base - CAE - Columbia - United States",
            "searchName": "MMT-CAE-Mc Entire ANG Base-Mc Entire-Columbia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CCG",
            "countryCode": "US",
            "cityCode": "CCG",
            "cityName": "Crane",
            "name": "[CCG] - Crane County Airport - CCG - Crane - United States",
            "searchName": "CCG-Crane County Airport-Crane-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CBZ",
            "countryCode": "US",
            "cityCode": "CBZ",
            "cityName": "Cabin Creek",
            "name": "[CBZ] - Cabin Creek - CBZ - Cabin Creek - United States",
            "searchName": "CBZ-Cabin Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CCA",
            "countryCode": "US",
            "cityCode": "CCA",
            "cityName": "Fort Chaffee",
            "name": "[CCA] - Chaffee AFB - CCA - Fort Chaffee - United States",
            "searchName": "CCA-Luftwaffenstützpunkt Chaffee-Chaffee AFB-Fort Chaffee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CCB",
            "countryCode": "US",
            "cityCode": "CCB",
            "cityName": "Upland",
            "name": "[CCB] - Cable Airport - CCB - Upland - United States",
            "searchName": "CCB-Cable Airport-Upland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JUP",
            "countryCode": "US",
            "cityCode": "CCB",
            "cityName": "Upland",
            "name": "[JUP] - Cable Heliport - CCB - Upland - United States",
            "searchName": "JUP-CCB-Cable Heliport-Upland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CBE",
            "countryCode": "US",
            "cityCode": "CBE",
            "cityName": "Cumberland",
            "name": "[CBE] - Greater Cumberland Regional - CBE - Cumberland - United States",
            "searchName": "CBE-Wiley Ford-Greater Cumberland Regional-Cumberland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CBF",
            "countryCode": "US",
            "cityCode": "CBF",
            "cityName": "Council Bluffs",
            "name": "[CBF] - Municipal - CBF - Council Bluffs - United States",
            "searchName": "CBF-Municipal-Council Bluffs  Havalimani-Council Bluffs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CBA",
            "countryCode": "US",
            "cityCode": "CBA",
            "cityName": "Corner Bay",
            "name": "[CBA] - Corner Bay - CBA - Corner Bay - United States",
            "searchName": "CBA-Corner Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CBK",
            "countryCode": "US",
            "cityCode": "CBK",
            "cityName": "Colby",
            "name": "[CBK] - Municipal - CBK - Colby - United States",
            "searchName": "CBK-Municipal-Colby  Havalimani-Colby-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CWG",
            "countryCode": "US",
            "cityCode": "CWG",
            "cityName": "Callaway Gardens",
            "name": "[CWG] - Callaway Gardens - CWG - Callaway Gardens - United States",
            "searchName": "CWG-Callaway Gardens-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CWI",
            "countryCode": "US",
            "cityCode": "CWI",
            "cityName": "Clinton",
            "name": "[CWI] - Clinton - CWI - Clinton - United States",
            "searchName": "CWI-Clinton-Clinton Belediyesi Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CVN",
            "countryCode": "US",
            "cityCode": "CVN",
            "cityName": "Clovis",
            "name": "[CVN] - Municipal - CVN - Clovis - United States",
            "searchName": "CVN-Clovis-Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CVS",
            "countryCode": "US",
            "cityCode": "CVN",
            "cityName": "Clovis",
            "name": "[CVS] - Cannon AFB - CVN - Clovis - United States",
            "searchName": "CVS-CVN-Cannon AFB-Clovis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CVO",
            "countryCode": "US",
            "cityCode": "CVO",
            "cityName": "Albany",
            "name": "[CVO] - Albany - CVO - Albany - United States",
            "searchName": "CVO-Kommunaler Flughafen Corvallis-Albany-Corvallis  Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "QWY",
            "countryCode": "US",
            "cityCode": "CVO",
            "cityName": "Albany",
            "name": "[QWY] - Albany Bus Station - CVO - Albany - United States",
            "searchName": "QWY-CVO-Albany Bus Station-Albany-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CWX",
            "countryCode": "US",
            "cityCode": "CWX",
            "cityName": "Willcox",
            "name": "[CWX] - Cochise County - CWX - Willcox - United States",
            "searchName": "CWX-Cochise County-Willcox-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LDJ",
            "countryCode": "US",
            "cityCode": "LDJ",
            "cityName": "Linden",
            "name": "[LDJ] - Linden - LDJ - Linden - United States",
            "searchName": "LDJ-Linden-Linden Belediyesi Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LCQ",
            "countryCode": "US",
            "cityCode": "LCQ",
            "cityName": "Lake City",
            "name": "[LCQ] - Lake City - LCQ - Lake City - United States",
            "searchName": "LCQ-Lake City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LCH",
            "countryCode": "US",
            "cityCode": "LCH",
            "cityName": "Lake Charles",
            "name": "[LCH] - Municipal - LCH - Lake Charles - United States",
            "searchName": "LCH-Lake Charles-Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CWF",
            "countryCode": "US",
            "cityCode": "LCH",
            "cityName": "Lake Charles",
            "name": "[CWF] - Chennault International. - LCH - Lake Charles - United States",
            "searchName": "CWF-LCH-Chennault International.-Lake Charles-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LCI",
            "countryCode": "US",
            "cityCode": "LCI",
            "cityName": "Laconia",
            "name": "[LCI] - Municipal - LCI - Laconia - United States",
            "searchName": "LCI-Kommunaler Flughafen-Municipal-Laconia  Havalimani-Laconia OH-Laconia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LDM",
            "countryCode": "US",
            "cityCode": "LDM",
            "cityName": "Ludington",
            "name": "[LDM] - Mason County - LDM - Ludington - United States",
            "searchName": "LDM-Mason County-Ludington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LEE",
            "countryCode": "US",
            "cityCode": "LEE",
            "cityName": "Leesburg",
            "name": "[LEE] - Leesburg - LEE - Leesburg - United States",
            "searchName": "LEE-Leesburg-Leesburg  Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LEB",
            "countryCode": "US",
            "cityCode": "LEB",
            "cityName": "White River",
            "name": "[LEB] - White River - LEB - White River - United States",
            "searchName": "LEB-Lebanon-White River-Lebanon Municipal-Lebanon Regional-Hanover-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LEM",
            "countryCode": "US",
            "cityCode": "LEM",
            "cityName": "Lemmon",
            "name": "[LEM] - Lemmon - LEM - Lemmon - United States",
            "searchName": "LEM-Lemmon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LHB",
            "countryCode": "US",
            "cityCode": "LHB",
            "cityName": "Lost Harbor",
            "name": "[LHB] - Lost Harbor Sea Port - LHB - Lost Harbor - United States",
            "searchName": "LHB-Lost Harbor Sea Port-Lost Harbor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LIH",
            "countryCode": "US",
            "cityCode": "LIH",
            "cityName": "Lihue",
            "name": "[LIH] - Lihue - LIH - Lihue - United States",
            "searchName": "LIH-Lihue-Lihue Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HPV",
            "countryCode": "US",
            "cityCode": "LIH",
            "cityName": "Lihue",
            "name": "[HPV] - Princeville - LIH - Lihue - United States",
            "searchName": "HPV-LIH-Princeville-Princeville Havalimani-Lihue-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LHV",
            "countryCode": "US",
            "cityCode": "LHV",
            "cityName": "Lock Haven",
            "name": "[LHV] - W T Piper Memorial - LHV - Lock Haven - United States",
            "searchName": "LHV-W T Piper Memorial-Lock Haven-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LIC",
            "countryCode": "US",
            "cityCode": "LIC",
            "cityName": "Limon",
            "name": "[LIC] - Municipal - LIC - Limon - United States",
            "searchName": "LIC-Municipal-Limon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LIT",
            "countryCode": "US",
            "cityCode": "LIT",
            "cityName": "Little Rock",
            "name": "[LIT] - Adams Field Airport - LIT - Little Rock - United States",
            "searchName": "LIT-Little Rock-Adams Field Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LIV",
            "countryCode": "US",
            "cityCode": "LIV",
            "cityName": "Livengood",
            "name": "[LIV] - Livengood - LIV - Livengood - United States",
            "searchName": "LIV-Livengood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LIY",
            "countryCode": "US",
            "cityCode": "LIY",
            "cityName": "Hinesville",
            "name": "[LIY] - Wright AAF - LIY - Hinesville - United States",
            "searchName": "LIY-Militärflughafen Wright-Wright AAF-Hinesville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LIZ",
            "countryCode": "US",
            "cityCode": "LIZ",
            "cityName": "Limestone",
            "name": "[LIZ] - Loring AFB - LIZ - Limestone - United States",
            "searchName": "LIZ-Luftwaffenstützpunkt Loring-Loring AFB-Limestone-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LIJ",
            "countryCode": "US",
            "cityCode": "LIJ",
            "cityName": "Long Island",
            "name": "[LIJ] - Long Island - LIJ - Long Island - United States",
            "searchName": "LIJ-Long Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LGB",
            "countryCode": "US",
            "cityCode": "LGB",
            "cityName": "Long Beach",
            "name": "[LGB] - Long Beach Municipal - LGB - Long Beach - United States",
            "searchName": "LGB-Long Beach-Long Beach Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JLB",
            "countryCode": "US",
            "cityCode": "LGB",
            "cityName": "Long Beach",
            "name": "[JLB] - Heliport - LGB - Long Beach - United States",
            "searchName": "JLB-LGB-Heliport-Long Beach-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LGC",
            "countryCode": "US",
            "cityCode": "LGC",
            "cityName": "La Grange",
            "name": "[LGC] - Calloway - LGC - La Grange - United States",
            "searchName": "LGC-Calloway-Calloway Havalimani-La Grange-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LGD",
            "countryCode": "US",
            "cityCode": "LGD",
            "cityName": "La Grande",
            "name": "[LGD] - La Grande - LGD - La Grande - United States",
            "searchName": "LGD-La Grande-La Grande Union County Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LFN",
            "countryCode": "US",
            "cityCode": "LFN",
            "cityName": "Louisburg",
            "name": "[LFN] - Franklin - LFN - Louisburg - United States",
            "searchName": "LFN-Franklin-Louisburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LEW",
            "countryCode": "US",
            "cityCode": "LEW",
            "cityName": "Lewiston",
            "name": "[LEW] - Auburn - LEW - Lewiston - United States",
            "searchName": "LEW-Auburn-Auburn Lewiston Havalimani-Lewiston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LEX",
            "countryCode": "US",
            "cityCode": "LEX",
            "cityName": "Lexington",
            "name": "[LEX] - Blue Grass - LEX - Lexington - United States",
            "searchName": "LEX-Lexington-Blue Grass-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LSD",
            "countryCode": "US",
            "cityCode": "LEX",
            "cityName": "Lexington",
            "name": "[LSD] - Blue Grass Station Army Heliport - LEX - Lexington - United States",
            "searchName": "LSD-LEX-Blue Grass Station Army Heliport-Lexington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LGU",
            "countryCode": "US",
            "cityCode": "LGU",
            "cityName": "Logan",
            "name": "[LGU] - Cache - LGU - Logan - United States",
            "searchName": "LGU-Cache-Logan Havalimani-Logan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LFT",
            "countryCode": "US",
            "cityCode": "LFT",
            "cityName": "Lafayette",
            "name": "[LFT] - Lafayette Regional - LFT - Lafayette - United States",
            "searchName": "LFT-Lafayette Regional-Lafayette-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HRL",
            "countryCode": "US",
            "cityCode": "HRL",
            "cityName": "Harlingen",
            "name": "[HRL] - Valley International - HRL - Harlingen - United States",
            "searchName": "HRL-Harlingen-Valley International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HPB",
            "countryCode": "US",
            "cityCode": "HPB",
            "cityName": "Hooper Bay",
            "name": "[HPB] - Hooper Bay - HPB - Hooper Bay - United States",
            "searchName": "HPB-Hooper Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HPN",
            "countryCode": "US",
            "cityCode": "HPN",
            "cityName": "Westchester County",
            "name": "[HPN] - Westchester County Apt - HPN - Westchester County - United States",
            "searchName": "HPN-White Plains-Westchester County Apt-Westchester County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HPT",
            "countryCode": "US",
            "cityCode": "HPT",
            "cityName": "Hampton",
            "name": "[HPT] - Municipal - HPT - Hampton - United States",
            "searchName": "HPT-Municipal-Hampton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HPY",
            "countryCode": "US",
            "cityCode": "HPY",
            "cityName": "Baytown",
            "name": "[HPY] - Baytown Airport - HPY - Baytown - United States",
            "searchName": "HPY-Baytown Airport-Baytown Havalimani-Baytown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HQM",
            "countryCode": "US",
            "cityCode": "HQM",
            "cityName": "Hoquiam",
            "name": "[HQM] - Bowerman - HQM - Hoquiam - United States",
            "searchName": "HQM-Bowerman-Hoquiam-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HSI",
            "countryCode": "US",
            "cityCode": "HSI",
            "cityName": "Hastings",
            "name": "[HSI] - Hastings - HSI - Hastings - United States",
            "searchName": "HSI-Hastings-Hastings Belediyesi Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HSL",
            "countryCode": "US",
            "cityCode": "HSL",
            "cityName": "Huslia",
            "name": "[HSL] - Huslia - HSL - Huslia - United States",
            "searchName": "HSL-Huslia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HST",
            "countryCode": "US",
            "cityCode": "HST",
            "cityName": "Homestead",
            "name": "[HST] - AFB - HST - Homestead - United States",
            "searchName": "HST-AFB-Homestead Belediyesi Havalimani-Homestead-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HSV",
            "countryCode": "US",
            "cityCode": "HSV",
            "cityName": "Huntsville",
            "name": "[HSV] - Huntsville International - Carl T. Jones Field - HSV - Huntsville - United States",
            "searchName": "HSV-Huntsville-Huntsville International - Carl T. Jones Field-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HUA",
            "countryCode": "US",
            "cityCode": "HSV",
            "cityName": "Huntsville",
            "name": "[HUA] - Redstone AAF - HSV - Huntsville - United States",
            "searchName": "HUA-HSV-Redstone AAF-Huntsville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HSB",
            "countryCode": "US",
            "cityCode": "HSB",
            "cityName": "Harrisburg",
            "name": "[HSB] - Raleigh - HSB - Harrisburg - United States",
            "searchName": "HSB-Raleigh-Harrisburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HRO",
            "countryCode": "US",
            "cityCode": "HRO",
            "cityName": "Harrison",
            "name": "[HRO] - Boone County - HRO - Harrison - United States",
            "searchName": "HRO-Harrison-Boone County-Harrison Arkansas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HOL",
            "countryCode": "US",
            "cityCode": "HOL",
            "cityName": "Holikachu",
            "name": "[HOL] - Holikachu - HOL - Holikachu - United States",
            "searchName": "HOL-Holikachu-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HOM",
            "countryCode": "US",
            "cityCode": "HOM",
            "cityName": "Homer",
            "name": "[HOM] - Homer - HOM - Homer - United States",
            "searchName": "HOM-Homer-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HON",
            "countryCode": "US",
            "cityCode": "HON",
            "cityName": "Huron",
            "name": "[HON] - Howes - HON - Huron - United States",
            "searchName": "HON-Huron-Howes-Huron South Dakota-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HNS",
            "countryCode": "US",
            "cityCode": "HNS",
            "cityName": "Haines",
            "name": "[HNS] - Municipal - HNS - Haines - United States",
            "searchName": "HNS-Haines-Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HNX",
            "countryCode": "US",
            "cityCode": "HNX",
            "cityName": "Hanna",
            "name": "[HNX] - Hanna - HNX - Hanna - United States",
            "searchName": "HNX-Hanna-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HNH",
            "countryCode": "US",
            "cityCode": "HNH",
            "cityName": "Hoonah",
            "name": "[HNH] - Hoonah - HNH - Hoonah - United States",
            "searchName": "HNH-Hoonah-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HOB",
            "countryCode": "US",
            "cityCode": "HOB",
            "cityName": "Hobbs",
            "name": "[HOB] - Lea County - HOB - Hobbs - United States",
            "searchName": "HOB-Hobbs-Lea County-Lea County Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HBB",
            "countryCode": "US",
            "cityCode": "HOB",
            "cityName": "Hobbs",
            "name": "[HBB] - Industrial Airpark - HOB - Hobbs - United States",
            "searchName": "HBB-HOB-Industrie-Airpark-Industrial Airpark-Hobbs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HOP",
            "countryCode": "US",
            "cityCode": "HOP",
            "cityName": "Hopkinsville",
            "name": "[HOP] - Campbell AAF - HOP - Hopkinsville - United States",
            "searchName": "HOP-Campbell AAF-Hopkinsville Christian Country Havalimani-Hopkinsville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HOT",
            "countryCode": "US",
            "cityCode": "HOT",
            "cityName": "Hot Springs",
            "name": "[HOT] - Memorial Field - HOT - Hot Springs - United States",
            "searchName": "HOT-Hot Springs-Memorial Field-Hot Springs Arkansas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ISW",
            "countryCode": "US",
            "cityCode": "ISW",
            "cityName": "Wisconsin Rapids",
            "name": "[ISW] - Alexander Field - ISW - Wisconsin Rapids - United States",
            "searchName": "ISW-Alexander Field-Alexander Field Havalimani-Wisconsin Rapids-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ITH",
            "countryCode": "US",
            "cityCode": "ITH",
            "cityName": "Ithaca",
            "name": "[ITH] - Tompkins County - ITH - Ithaca - United States",
            "searchName": "ITH-Ithaca-Tompkins County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IWD",
            "countryCode": "US",
            "cityCode": "IWD",
            "cityName": "Ironwood",
            "name": "[IWD] - Gogebic County - IWD - Ironwood - United States",
            "searchName": "IWD-Ironwood-Gogebic County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GUF",
            "countryCode": "US",
            "cityCode": "GUF",
            "cityName": "Gulf Shores",
            "name": "[GUF] - Edwards - GUF - Gulf Shores - United States",
            "searchName": "GUF-Edwards-Edwards Havalimani-Gulf Shores-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GUC",
            "countryCode": "US",
            "cityCode": "GUC",
            "cityName": "Gunnison",
            "name": "[GUC] - Gunnison - GUC - Gunnison - United States",
            "searchName": "GUC-Gunnison-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GTY",
            "countryCode": "US",
            "cityCode": "GTY",
            "cityName": "Gettysburg",
            "name": "[GTY] - Gettysburg - GTY - Gettysburg - United States",
            "searchName": "GTY-Gettysburg-Gettysburg Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GUS",
            "countryCode": "US",
            "cityCode": "GUS",
            "cityName": "Peru",
            "name": "[GUS] - Grissom AFB - GUS - Peru - United States",
            "searchName": "GUS-Grissom AFB-Peru-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GUP",
            "countryCode": "US",
            "cityCode": "GUP",
            "cityName": "Gallup",
            "name": "[GUP] - Senator Clark - GUP - Gallup - United States",
            "searchName": "GUP-Senator Clark-Gallup-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GST",
            "countryCode": "US",
            "cityCode": "GST",
            "cityName": "Gustavus",
            "name": "[GST] - Gustavus Airport - GST - Gustavus - United States",
            "searchName": "GST-Gustavus-Gustavus Airport-Gustavus Arpt-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BQV",
            "countryCode": "US",
            "cityCode": "GST",
            "cityName": "Gustavus",
            "name": "[BQV] - Bartlett SPB - GST - Gustavus - United States",
            "searchName": "BQV-GST-Bartlett SPB-Gustavus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GTP",
            "countryCode": "US",
            "cityCode": "GTP",
            "cityName": "Grants Pass",
            "name": "[GTP] - Grants Pass - GTP - Grants Pass - United States",
            "searchName": "GTP-Grants Pass-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GTF",
            "countryCode": "US",
            "cityCode": "GTF",
            "cityName": "Great Falls",
            "name": "[GTF] - International - GTF - Great Falls - United States",
            "searchName": "GTF-Great Falls-International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GFA",
            "countryCode": "US",
            "cityCode": "GTF",
            "cityName": "Great Falls",
            "name": "[GFA] - Malmstrom AFB - GTF - Great Falls - United States",
            "searchName": "GFA-GTF-Malmstrom AFB-Great Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GTG",
            "countryCode": "US",
            "cityCode": "GTG",
            "cityName": "Grantsburg",
            "name": "[GTG] - Municipal - GTG - Grantsburg - United States",
            "searchName": "GTG-Municipal-Grantsburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GSO",
            "countryCode": "US",
            "cityCode": "GSO",
            "cityName": "Greensboro",
            "name": "[GSO] - Piedmont Triad International - GSO - Greensboro - United States",
            "searchName": "GSO-Greensboro High Point-Piedmont Triad International-Greensboro/H. Point-Piedmond Triad Int.-Greensboro-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GSP",
            "countryCode": "US",
            "cityCode": "GSP",
            "cityName": "Greenville",
            "name": "[GSP] - Greenville Spartanburg International Airport - GSP - Greenville - United States",
            "searchName": "GSP-Greenville Spartanburg-Greenville Spartanburg International Airport-Greenville-Greenville/Greer-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GMU",
            "countryCode": "US",
            "cityCode": "GSP",
            "cityName": "Greenville",
            "name": "[GMU] - Greenville Downtown - GSP - Greenville - United States",
            "searchName": "GMU-GSP-Greenville Downtown-Greenville Downtown Havalimani-Greenville/Greer-Greenville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GDC",
            "countryCode": "US",
            "cityCode": "GSP",
            "cityName": "Greenville",
            "name": "[GDC] - Donaldson Center - GSP - Greenville - United States",
            "searchName": "GDC-GSP-Donaldson Center-Greenville/Greer-Greenville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GSB",
            "countryCode": "US",
            "cityCode": "GSB",
            "cityName": "Goldsboro",
            "name": "[GSB] - Seymour Johnson AFB - GSB - Goldsboro - United States",
            "searchName": "GSB-Seymour Johns-Seymour Johnson AFB-Goldsboro-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GSH",
            "countryCode": "US",
            "cityCode": "GSH",
            "cityName": "Goshen",
            "name": "[GSH] - Goshen - GSH - Goshen - United States",
            "searchName": "GSH-Goshen-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GRI",
            "countryCode": "US",
            "cityCode": "GRI",
            "cityName": "Grand Island",
            "name": "[GRI] - Grand Island - GRI - Grand Island - United States",
            "searchName": "GRI-Grand Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GRB",
            "countryCode": "US",
            "cityCode": "GRB",
            "cityName": "Green Bay",
            "name": "[GRB] - Austin-straubel Field - GRB - Green Bay - United States",
            "searchName": "GRB-Green Bay-Austin-straubel Field-Straubel-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GRM",
            "countryCode": "US",
            "cityCode": "GRM",
            "cityName": "Grand Marais",
            "name": "[GRM] - Devils Track - GRM - Grand Marais - United States",
            "searchName": "GRM-Devils Track-Grand Marias Devils Track Havalimani-Grand Marais-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GRN",
            "countryCode": "US",
            "cityCode": "GRN",
            "cityName": "Gordon",
            "name": "[GRN] - Gordon - GRN - Gordon - United States",
            "searchName": "GRN-Gordon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GRD",
            "countryCode": "US",
            "cityCode": "GRD",
            "cityName": "Greenwood",
            "name": "[GRD] - Greenwood - GRD - Greenwood - United States",
            "searchName": "GRD-Greenwood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GRE",
            "countryCode": "US",
            "cityCode": "GRE",
            "cityName": "Greenville",
            "name": "[GRE] - Municipal - GRE - Greenville - United States",
            "searchName": "GRE-Municipal-Greenville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GRR",
            "countryCode": "US",
            "cityCode": "GRR",
            "cityName": "Grand Rapids",
            "name": "[GRR] - Gerald R. Ford International - GRR - Grand Rapids - United States",
            "searchName": "GRR-Grand Rapids-Gerald R. Ford International-Gerald R. Ford Intl-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GYR",
            "countryCode": "US",
            "cityCode": "GYR",
            "cityName": "Goodyear",
            "name": "[GYR] - Litchfield - GYR - Goodyear - United States",
            "searchName": "GYR-Litchfield-Litchfield Goodyear Havalimani-Goodyear-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HAE",
            "countryCode": "US",
            "cityCode": "HAE",
            "cityName": "Havasupai",
            "name": "[HAE] - Havasupai - HAE - Havasupai - United States",
            "searchName": "HAE-Havasupai-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HAF",
            "countryCode": "US",
            "cityCode": "HAF",
            "cityName": "Half Moon",
            "name": "[HAF] - Half Moon - HAF - Half Moon - United States",
            "searchName": "HAF-Half Moon-Half Moon Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HAB",
            "countryCode": "US",
            "cityCode": "HAB",
            "cityName": "Hamilton",
            "name": "[HAB] - Marion County - HAB - Hamilton - United States",
            "searchName": "HAB-Marion County-Hamilton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GYY",
            "countryCode": "US",
            "cityCode": "GYY",
            "cityName": "Gary",
            "name": "[GYY] - Gary/Chicago International - GYY - Gary - United States",
            "searchName": "GYY-Gary Regional-Gary/Chicago International-Chicago IL-Gary-Chicago-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HAO",
            "countryCode": "US",
            "cityCode": "HAO",
            "cityName": "Hamilton",
            "name": "[HAO] - Hamilton - HAO - Hamilton - United States",
            "searchName": "HAO-Hamilton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HAR",
            "countryCode": "US",
            "cityCode": "HAR",
            "cityName": "Harrisburg",
            "name": "[HAR] - Capital City - HAR - Harrisburg - United States",
            "searchName": "HAR-Skyport-Capital City-Harrisburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MDT",
            "countryCode": "US",
            "cityCode": "HAR",
            "cityName": "Harrisburg",
            "name": "[MDT] - Harrisburg International - HAR - Harrisburg - United States",
            "searchName": "MDT-HAR-Harrisburg-Harrisburg International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZUG",
            "countryCode": "US",
            "cityCode": "HAR",
            "cityName": "Harrisburg",
            "name": "[ZUG] - Harrisburg Railway - HAR - Harrisburg - United States",
            "searchName": "ZUG-HAR-Harrisburg Railway-Harrisburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HAI",
            "countryCode": "US",
            "cityCode": "HAI",
            "cityName": "Three Rivers",
            "name": "[HAI] - Dr Haines - HAI - Three Rivers - United States",
            "searchName": "HAI-Dr. Haines-Dr Haines-Three Rivers-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GVT",
            "countryCode": "US",
            "cityCode": "GVT",
            "cityName": "Greenville",
            "name": "[GVT] - Majors Field - GVT - Greenville - United States",
            "searchName": "GVT-Majors Field-Greenville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GVL",
            "countryCode": "US",
            "cityCode": "GVL",
            "cityName": "Gainesville",
            "name": "[GVL] - Lee Gilmer Memorial - GVL - Gainesville - United States",
            "searchName": "GVL-Lee Gilmer Memorial-Gainesville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GVE",
            "countryCode": "US",
            "cityCode": "GVE",
            "cityName": "Gordonsville",
            "name": "[GVE] - Municipal - GVE - Gordonsville - United States",
            "searchName": "GVE-Municipal-Gordonsville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GUY",
            "countryCode": "US",
            "cityCode": "GUY",
            "cityName": "Guymon",
            "name": "[GUY] - Guymon - GUY - Guymon - United States",
            "searchName": "GUY-Guymon-Guymon  Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GXY",
            "countryCode": "US",
            "cityCode": "GXY",
            "cityName": "Greeley",
            "name": "[GXY] - Weld County - GXY - Greeley - United States",
            "searchName": "GXY-Weld County-Weld County Yerel Havalimani-Greeley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GWO",
            "countryCode": "US",
            "cityCode": "GWO",
            "cityName": "Greenwood",
            "name": "[GWO] - Leflore - GWO - Greenwood - United States",
            "searchName": "GWO-Leflore-Leflore Havalimani-Greenwood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GWS",
            "countryCode": "US",
            "cityCode": "GWS",
            "cityName": "Glenwood Springs",
            "name": "[GWS] - Glenwood Springs - GWS - Glenwood Springs - United States",
            "searchName": "GWS-Glenwood Springs-Glenwood Springs Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GWV",
            "countryCode": "US",
            "cityCode": "GWV",
            "cityName": "Glendale",
            "name": "[GWV] - Glendale - GWV - Glendale - United States",
            "searchName": "GWV-Glendale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GIF",
            "countryCode": "US",
            "cityCode": "GIF",
            "cityName": "Winter Haven",
            "name": "[GIF] - Gilbert Field - GIF - Winter Haven - United States",
            "searchName": "GIF-Landeplatz Gilbert-Gilbert Field-Gilbert Fld Havalimani-Winter Haven-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GJT",
            "countryCode": "US",
            "cityCode": "GJT",
            "cityName": "Grand Junction",
            "name": "[GJT] - Walker Field - GJT - Grand Junction - United States",
            "searchName": "GJT-Grand Junction-Walker Field-Grand Junction Regnl-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GLD",
            "countryCode": "US",
            "cityCode": "GLD",
            "cityName": "Goodland",
            "name": "[GLD] - Renner Field - GLD - Goodland - United States",
            "searchName": "GLD-Renner Field-Goodland Belediyesi Havalimani-Goodland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GLE",
            "countryCode": "US",
            "cityCode": "GLE",
            "cityName": "Gainesville",
            "name": "[GLE] - Municipal - GLE - Gainesville - United States",
            "searchName": "GLE-Municipal-Gainesville  Havalimani-Gainesville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GLH",
            "countryCode": "US",
            "cityCode": "GLH",
            "cityName": "Greenville",
            "name": "[GLH] - Greenville - GLH - Greenville - United States",
            "searchName": "GLH-Greenville Municipal-Greenville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GKN",
            "countryCode": "US",
            "cityCode": "GKN",
            "cityName": "Gulkana",
            "name": "[GKN] - Gulkana - GKN - Gulkana - United States",
            "searchName": "GKN-Gulkana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GLB",
            "countryCode": "US",
            "cityCode": "GLB",
            "cityName": "Globe",
            "name": "[GLB] - San Calos Apache - GLB - Globe - United States",
            "searchName": "GLB-San Calos Apache-Globe-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GKT",
            "countryCode": "US",
            "cityCode": "GKT",
            "cityName": "Gatlinburg",
            "name": "[GKT] - Gatlinburg - GKT - Gatlinburg - United States",
            "searchName": "GKT-Gatlinburg-Gatlinburg Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GGW",
            "countryCode": "US",
            "cityCode": "GGW",
            "cityName": "Glasgow",
            "name": "[GGW] - International - GGW - Glasgow - United States",
            "searchName": "GGW-Glasgow USA-International-Glasgow-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GHM",
            "countryCode": "US",
            "cityCode": "GHM",
            "cityName": "Centerville",
            "name": "[GHM] - Municipal - GHM - Centerville - United States",
            "searchName": "GHM-Municipal-Centerville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GGE",
            "countryCode": "US",
            "cityCode": "GGE",
            "cityName": "Georgetown",
            "name": "[GGE] - Georgetown - GGE - Georgetown - United States",
            "searchName": "GGE-Georgetown-George Town Havalimani-Myrtle Beach-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GGG",
            "countryCode": "US",
            "cityCode": "GGG",
            "cityName": "Kilgore",
            "name": "[GGG] - Kilgore - GGG - Kilgore - United States",
            "searchName": "GGG-Gladewater-Kilgore-East Texas Regional-Greg County Airport-Longview-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GFK",
            "countryCode": "US",
            "cityCode": "GFK",
            "cityName": "Grand Forks",
            "name": "[GFK] - Grand Forks - GFK - Grand Forks - United States",
            "searchName": "GFK-Grand Forks-Grand Forks North Dakota-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GFL",
            "countryCode": "US",
            "cityCode": "GFL",
            "cityName": "Glens Falls",
            "name": "[GFL] - Warren County - GFL - Glens Falls - United States",
            "searchName": "GFL-Warren County-Warren County Havalimani-Glens Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GEY",
            "countryCode": "US",
            "cityCode": "GEY",
            "cityName": "Greybull",
            "name": "[GEY] - South Big Horn County - GEY - Greybull - United States",
            "searchName": "GEY-South Big Horn County-Greybull MT-Greybull-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GFB",
            "countryCode": "US",
            "cityCode": "GFB",
            "cityName": "Togiak Fish",
            "name": "[GFB] - Togiak Fish - GFB - Togiak Fish - United States",
            "searchName": "GFB-Togiak Fish-Togiak Fishery-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GFD",
            "countryCode": "US",
            "cityCode": "GFD",
            "cityName": "Greenfield",
            "name": "[GFD] - Pope Field - GFD - Greenfield - United States",
            "searchName": "GFD-Pope Field-Pope Field Havalimani-Greenfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GOK",
            "countryCode": "US",
            "cityCode": "GOK",
            "cityName": "Guthrie",
            "name": "[GOK] - Guthrie - GOK - Guthrie - United States",
            "searchName": "GOK-Guthrie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GOL",
            "countryCode": "US",
            "cityCode": "GOL",
            "cityName": "Gold Beach",
            "name": "[GOL] - State - GOL - Gold Beach - United States",
            "searchName": "GOL-State-Gold Beach-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GON",
            "countryCode": "US",
            "cityCode": "GON",
            "cityName": "New London",
            "name": "[GON] - New London - GON - New London - United States",
            "searchName": "GON-Groton-New London-Groton\\/New London-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZGD",
            "countryCode": "US",
            "cityCode": "GON",
            "cityName": "New London",
            "name": "[ZGD] - New London Railway - GON - New London - United States",
            "searchName": "ZGD-GON-New London Railway-Groton-New London-Groton\\/New London-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GPT",
            "countryCode": "US",
            "cityCode": "GPT",
            "cityName": "Gulfport",
            "name": "[GPT] - Gulfport-Biloxi International - GPT - Gulfport - United States",
            "searchName": "GPT-Gulfport Biloxi Regional-Gulfport-Biloxi International-Biloxi Regional-Biloxi Regional Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GPZ",
            "countryCode": "US",
            "cityCode": "GPZ",
            "cityName": "Grand Rapids",
            "name": "[GPZ] - Grand Rapids - GPZ - Grand Rapids - United States",
            "searchName": "GPZ-Grand Rapids-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GQQ",
            "countryCode": "US",
            "cityCode": "GQQ",
            "cityName": "Galion",
            "name": "[GQQ] - Galion - GQQ - Galion - United States",
            "searchName": "GQQ-Galion-Galion  Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GLQ",
            "countryCode": "US",
            "cityCode": "GLQ",
            "cityName": "Glennallen",
            "name": "[GLQ] - Glennallen - GLQ - Glennallen - United States",
            "searchName": "GLQ-Glennallen-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GLR",
            "countryCode": "US",
            "cityCode": "GLR",
            "cityName": "Gaylord",
            "name": "[GLR] - Otsego County - GLR - Gaylord - United States",
            "searchName": "GLR-Otsego County-Otsego Havalimani-Gaylord-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GLS",
            "countryCode": "US",
            "cityCode": "GLS",
            "cityName": "Galveston",
            "name": "[GLS] - Scholes Field - GLS - Galveston - United States",
            "searchName": "GLS-Scholes Field-Scholes Field Havalimani-Galveston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GLV",
            "countryCode": "US",
            "cityCode": "GLV",
            "cityName": "Golovin",
            "name": "[GLV] - Golovin - GLV - Golovin - United States",
            "searchName": "GLV-Golovin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GLW",
            "countryCode": "US",
            "cityCode": "GLW",
            "cityName": "Glasgow",
            "name": "[GLW] - Municipal - GLW - Glasgow - United States",
            "searchName": "GLW-Municipal-Glasgow  Havalimani-Glasgow-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GMT",
            "countryCode": "US",
            "cityCode": "GMT",
            "cityName": "Granite Mountain",
            "name": "[GMT] - Granite Mountain - GMT - Granite Mountain - United States",
            "searchName": "GMT-Granite Mountain-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GMV",
            "countryCode": "US",
            "cityCode": "GMV",
            "cityName": "Monument Valley Gldngs",
            "name": "[GMV] - Monument Valley - GMV - Monument Valley Gldngs - United States",
            "searchName": "GMV-Monument Valley-Monument Valley Gldngs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GNT",
            "countryCode": "US",
            "cityCode": "GNT",
            "cityName": "Grants",
            "name": "[GNT] - Milan - GNT - Grants - United States",
            "searchName": "GNT-Milan-Grants Milan  Havalimani-Grants-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GNU",
            "countryCode": "US",
            "cityCode": "GNU",
            "cityName": "Goodnews Bay",
            "name": "[GNU] - Goodnews Bay - GNU - Goodnews Bay - United States",
            "searchName": "GNU-Goodnews Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GNV",
            "countryCode": "US",
            "cityCode": "GNV",
            "cityName": "Gainesville",
            "name": "[GNV] - Gainesville Regional - GNV - Gainesville - United States",
            "searchName": "GNV-Gainesville-Gainesville Regional-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GNF",
            "countryCode": "US",
            "cityCode": "GNF",
            "cityName": "Quincy",
            "name": "[GNF] - Gansner Field - GNF - Quincy - United States",
            "searchName": "GNF-Gansner Field-Quincy-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GNG",
            "countryCode": "US",
            "cityCode": "GNG",
            "cityName": "Gooding",
            "name": "[GNG] - Gooding - GNG - Gooding - United States",
            "searchName": "GNG-Gooding-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FWA",
            "countryCode": "US",
            "cityCode": "FWA",
            "cityName": "Fort Wayne",
            "name": "[FWA] - Municipal/Baer Field - FWA - Fort Wayne - United States",
            "searchName": "FWA-Fort Wayne-Municipal/Baer Field-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SMD",
            "countryCode": "US",
            "cityCode": "FWA",
            "cityName": "Fort Wayne",
            "name": "[SMD] - Smith Field - FWA - Fort Wayne - United States",
            "searchName": "SMD-FWA-Smith Field-Fort Wayne-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FWL",
            "countryCode": "US",
            "cityCode": "FWL",
            "cityName": "Farewell",
            "name": "[FWL] - Farewell - FWL - Farewell - United States",
            "searchName": "FWL-Farewell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FUL",
            "countryCode": "US",
            "cityCode": "FUL",
            "cityName": "Fullerton",
            "name": "[FUL] - Municipal - FUL - Fullerton - United States",
            "searchName": "FUL-Municipal-Fullerton  Havalimani-Fullerton-Fulleborn-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FSM",
            "countryCode": "US",
            "cityCode": "FSM",
            "cityName": "Fort Smith",
            "name": "[FSM] - Regional - FSM - Fort Smith - United States",
            "searchName": "FSM-Fort Smith USA-Regional-Fort Smith-Fort Smith Arkansas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FST",
            "countryCode": "US",
            "cityCode": "FST",
            "cityName": "Fort Stockton",
            "name": "[FST] - Pecos County - FST - Fort Stockton - United States",
            "searchName": "FST-Pecos County-Pecos County Havalimani-Fort Stockton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FSU",
            "countryCode": "US",
            "cityCode": "FSU",
            "cityName": "Fort Sumner",
            "name": "[FSU] - Fort Sumner - FSU - Fort Sumner - United States",
            "searchName": "FSU-Fort Sumner-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FTW",
            "countryCode": "US",
            "cityCode": "FTW",
            "cityName": "Fort Worth",
            "name": "[FTW] - Meacham Field - FTW - Fort Worth - United States",
            "searchName": "FTW-Landeplatz Meacham-Meacham Field-Meacham Field Havalimani-Dallas-Fort Worth-Ft Worth-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FTK",
            "countryCode": "US",
            "cityCode": "FTK",
            "cityName": "Fort Knox",
            "name": "[FTK] - Godman AAF - FTK - Fort Knox - United States",
            "searchName": "FTK-Godman AAF-Fort Knox-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FTL",
            "countryCode": "US",
            "cityCode": "FTL",
            "cityName": "Fortuna Ledge",
            "name": "[FTL] - Fortuna Ledge - FTL - Fortuna Ledge - United States",
            "searchName": "FTL-Fortuna Ledge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GAG",
            "countryCode": "US",
            "cityCode": "GAG",
            "cityName": "Gage",
            "name": "[GAG] - Gage - GAG - Gage - United States",
            "searchName": "GAG-Gage-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GAI",
            "countryCode": "US",
            "cityCode": "GAI",
            "cityName": "Gaithersburg",
            "name": "[GAI] - Montgomery County - GAI - Gaithersburg - United States",
            "searchName": "GAI-Montgomery County-Montgomery Cty Havalimani-Gaithersburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GAD",
            "countryCode": "US",
            "cityCode": "GAD",
            "cityName": "Gadsden",
            "name": "[GAD] - Municipal - GAD - Gadsden - United States",
            "searchName": "GAD-Barstow Daggett-Municipal-Gadsden Belediyesi Havalimani-Daggett-Gadsden-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GAB",
            "countryCode": "US",
            "cityCode": "GAB",
            "cityName": "Gabbs",
            "name": "[GAB] - Gabbs - GAB - Gabbs - United States",
            "searchName": "GAB-Gabbs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FYU",
            "countryCode": "US",
            "cityCode": "FYU",
            "cityName": "Fort Yukon",
            "name": "[FYU] - Fort Yukon - FYU - Fort Yukon - United States",
            "searchName": "FYU-Fort Yukon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FYV",
            "countryCode": "US",
            "cityCode": "FYV",
            "cityName": "Fayetteville",
            "name": "[FYV] - Municipal (Drake Field) - FYV - Fayetteville - United States",
            "searchName": "FYV-Kommunaler Flughafen-Municipal (Drake Field)-Municipal Drake-Fayetteville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "XNA",
            "countryCode": "US",
            "cityCode": "FYV",
            "cityName": "Fayetteville",
            "name": "[XNA] - Northwest Arkansas Regional - FYV - Fayetteville - United States",
            "searchName": "XNA-FYV-Bentonville-Northwest Arkansas Regional-Northwest Arkansas-Fayetteville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FXY",
            "countryCode": "US",
            "cityCode": "FXY",
            "cityName": "Forest City",
            "name": "[FXY] - Municipal - FXY - Forest City - United States",
            "searchName": "FXY-Municipal-Forest City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FYM",
            "countryCode": "US",
            "cityCode": "FYM",
            "cityName": "Fayetteville",
            "name": "[FYM] - Municipal - FYM - Fayetteville - United States",
            "searchName": "FYM-Municipal-Fayetteville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FXM",
            "countryCode": "US",
            "cityCode": "FXM",
            "cityName": "Flaxman Island",
            "name": "[FXM] - Flaxman Island - FXM - Flaxman Island - United States",
            "searchName": "FXM-Flaxman Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GAK",
            "countryCode": "US",
            "cityCode": "GAK",
            "cityName": "Gakona",
            "name": "[GAK] - Gakona - GAK - Gakona - United States",
            "searchName": "GAK-Gakona-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GAL",
            "countryCode": "US",
            "cityCode": "GAL",
            "cityName": "Galena",
            "name": "[GAL] - Galena - GAL - Galena - United States",
            "searchName": "GAL-Galena-Edward G. Pitka Sr. Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GAM",
            "countryCode": "US",
            "cityCode": "GAM",
            "cityName": "Gambell",
            "name": "[GAM] - Gambell - GAM - Gambell - United States",
            "searchName": "GAM-Gambell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GDV",
            "countryCode": "US",
            "cityCode": "GDV",
            "cityName": "Glendive",
            "name": "[GDV] - Dawson Community - GDV - Glendive - United States",
            "searchName": "GDV-Glendive-Dawson Community- Mont Dawson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GDW",
            "countryCode": "US",
            "cityCode": "GDW",
            "cityName": "Gladwin",
            "name": "[GDW] - Gladwin - GDW - Gladwin - United States",
            "searchName": "GDW-Gladwin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GDH",
            "countryCode": "US",
            "cityCode": "GDH",
            "cityName": "Golden Horn Lodge",
            "name": "[GDH] - Golden Horn Lodge SPB - GDH - Golden Horn Lodge - United States",
            "searchName": "GDH-Golden Horn Lodge SPB-Golden Horn-Golden Horn Lodge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GDM",
            "countryCode": "US",
            "cityCode": "GDM",
            "cityName": "Gardner",
            "name": "[GDM] - Municipal - GDM - Gardner - United States",
            "searchName": "GDM-Municipal-Gardner-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GEG",
            "countryCode": "US",
            "cityCode": "GEG",
            "cityName": "Spokane",
            "name": "[GEG] - Spokane International - GEG - Spokane - United States",
            "searchName": "GEG-Spokane-Spokane International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SKA",
            "countryCode": "US",
            "cityCode": "GEG",
            "cityName": "Spokane",
            "name": "[SKA] - Fairchild AFB - GEG - Spokane - United States",
            "searchName": "SKA-GEG-Fairchild AFB-Spokane-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SFF",
            "countryCode": "US",
            "cityCode": "GEG",
            "cityName": "Spokane",
            "name": "[SFF] - Felts Field - GEG - Spokane - United States",
            "searchName": "SFF-GEG-Landeplatz Felts-Felts Field-Felts Field Havalimani-Spokane-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GEK",
            "countryCode": "US",
            "cityCode": "GEK",
            "cityName": "Ganes Creek",
            "name": "[GEK] - Ganes Creek - GEK - Ganes Creek - United States",
            "searchName": "GEK-Ganes Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GED",
            "countryCode": "US",
            "cityCode": "GED",
            "cityName": "Georgetown",
            "name": "[GED] - Sussex County - GED - Georgetown - United States",
            "searchName": "GED-Sussex County-Sussex County Havalimani-Georgetown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GBR",
            "countryCode": "US",
            "cityCode": "GBR",
            "cityName": "Great Barrington",
            "name": "[GBR] - Walter J. Koladza Airport - GBR - Great Barrington - United States",
            "searchName": "GBR-Walter J. Koladza Airport-Great Barrington Havalimani-Great Barrington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GBG",
            "countryCode": "US",
            "cityCode": "GBG",
            "cityName": "Galesburg",
            "name": "[GBG] - Galesburg - GBG - Galesburg - United States",
            "searchName": "GBG-Galesburg-Galesburg Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GBH",
            "countryCode": "US",
            "cityCode": "GBH",
            "cityName": "Galbraith Lake",
            "name": "[GBH] - Galbraith Lake - GBH - Galbraith Lake - United States",
            "searchName": "GBH-Galbraith Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GBD",
            "countryCode": "US",
            "cityCode": "GBD",
            "cityName": "Great Bend",
            "name": "[GBD] - Great Bend - GBD - Great Bend - United States",
            "searchName": "GBD-Great Bend-Greate Bend Belediyesi Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GCC",
            "countryCode": "US",
            "cityCode": "GCC",
            "cityName": "Gillette",
            "name": "[GCC] - Campbell County - GCC - Gillette - United States",
            "searchName": "GCC-Gillette-Campbell County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GCD",
            "countryCode": "US",
            "cityCode": "GCD",
            "cityName": "Electric City",
            "name": "[GCD] - Grand Coulee Dam - GCD - Electric City - United States",
            "searchName": "GCD-Grand Coulee Dam-Electric City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GCN",
            "countryCode": "US",
            "cityCode": "GCN",
            "cityName": "Grand Canyon",
            "name": "[GCN] - National Park - GCN - Grand Canyon - United States",
            "searchName": "GCN-National Park-Grand Canyon-Grand Canyon Village-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FLG",
            "countryCode": "US",
            "cityCode": "GCN",
            "cityName": "Grand Canyon",
            "name": "[FLG] - Pulliam Field - GCN - Grand Canyon - United States",
            "searchName": "FLG-GCN-Flagstaff-Pulliam Field-Grand Canyon-Grand Canyon Village-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NGC",
            "countryCode": "US",
            "cityCode": "GCN",
            "cityName": "Grand Canyon",
            "name": "[NGC] - North Rim - GCN - Grand Canyon - United States",
            "searchName": "NGC-GCN-North Rim-Grand Canyon-Grand Canyon Village-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JGC",
            "countryCode": "US",
            "cityCode": "GCN",
            "cityName": "Grand Canyon",
            "name": "[JGC] - Heliport - GCN - Grand Canyon - United States",
            "searchName": "JGC-GCN-Heliport-Grand Canyon-Grand Canyon Village-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GCK",
            "countryCode": "US",
            "cityCode": "GCK",
            "cityName": "Garden City",
            "name": "[GCK] - Garden City Municipal Airport - GCK - Garden City - United States",
            "searchName": "GCK-Garden City-Garden City Municipal Airport-Municipal Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GCW",
            "countryCode": "US",
            "cityCode": "GCW",
            "cityName": "Grand Canyon West",
            "name": "[GCW] - Grand Canyon West - GCW - Grand Canyon West - United States",
            "searchName": "GCW-Grand Canyon West-Peach Springs Gran Cañón Oeste-Peach Springs Grand Canyon West-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GCY",
            "countryCode": "US",
            "cityCode": "GCY",
            "cityName": "Greenville",
            "name": "[GCY] - Municipal - GCY - Greenville - United States",
            "searchName": "GCY-Municipal-BelediyesiGreenville Havalimani-Greenville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FOB",
            "countryCode": "US",
            "cityCode": "FOB",
            "cityName": "Fort Bragg",
            "name": "[FOB] - Fort Bragg - FOB - Fort Bragg - United States",
            "searchName": "FOB-Fort Bragg-Fort Bragg Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FOK",
            "countryCode": "US",
            "cityCode": "FOK",
            "cityName": "Westhampton",
            "name": "[FOK] - Suffolk County - FOK - Westhampton - United States",
            "searchName": "FOK-Suffolk County-Suffolk County Havalimani-Westhampton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FOD",
            "countryCode": "US",
            "cityCode": "FOD",
            "cityName": "Fort Dodge",
            "name": "[FOD] - Fort Dodge Regional - FOD - Fort Dodge - United States",
            "searchName": "FOD-Fort Dodge-Fort Dodge Regional-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FNK",
            "countryCode": "US",
            "cityCode": "FNK",
            "cityName": "Fin Creek",
            "name": "[FNK] - Fin Creek - FNK - Fin Creek - United States",
            "searchName": "FNK-Fin Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FNL",
            "countryCode": "US",
            "cityCode": "FNL",
            "cityName": "Fort Collins",
            "name": "[FNL] - Municipalcipal Airport - FNL - Fort Collins - United States",
            "searchName": "FNL-Municipal Airport-Municipalcipal Airport-Fort Collins/Loveland-Fort Collins-Fort Collins\\/Loveland-Loveland Bus Service-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "QWF",
            "countryCode": "US",
            "cityCode": "FNL",
            "cityName": "Fort Collins",
            "name": "[QWF] - Fort Collins Bus Servi - FNL - Fort Collins - United States",
            "searchName": "QWF-FNL-Fort Collins Bus Servi-Fort Collins/Loveland-Fort Collins-Fort Collins\\/Loveland-Loveland Bus Service-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "QWH",
            "countryCode": "US",
            "cityCode": "FNL",
            "cityName": "Fort Collins",
            "name": "[QWH] - Loveland Bus Station - FNL - Fort Collins - United States",
            "searchName": "QWH-FNL-Loveland Bus Station-Fort Collins/Loveland-Fort Collins-Fort Collins\\/Loveland-Loveland Bus Service-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FNR",
            "countryCode": "US",
            "cityCode": "FNR",
            "cityName": "Funter Bay",
            "name": "[FNR] - SPB - FNR - Funter Bay - United States",
            "searchName": "FNR-SPB-Funter Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FNT",
            "countryCode": "US",
            "cityCode": "FNT",
            "cityName": "Flint",
            "name": "[FNT] - Bishop International Airport - FNT - Flint - United States",
            "searchName": "FNT-Flint-Bishop International Airport-Bishop-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FMS",
            "countryCode": "US",
            "cityCode": "FMS",
            "cityName": "Fort Madison",
            "name": "[FMS] - Municipal - FMS - Fort Madison - United States",
            "searchName": "FMS-Municipal-Fort Madison Belediyesi Havalimani-Fort Madison-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FMU",
            "countryCode": "US",
            "cityCode": "FMU",
            "cityName": "Florence",
            "name": "[FMU] - Florence Municipal - FMU - Florence - United States",
            "searchName": "FMU-Florence Municipal-Florence-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FMY",
            "countryCode": "US",
            "cityCode": "FMY",
            "cityName": "Fort Myers",
            "name": "[FMY] - Page Field - FMY - Fort Myers - United States",
            "searchName": "FMY-Landeplatz Page-Page Field-tüm havaalanlari-Fort Myers-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RSW",
            "countryCode": "US",
            "cityCode": "FMY",
            "cityName": "Fort Myers",
            "name": "[RSW] - Southwest Florida International - FMY - Fort Myers - United States",
            "searchName": "RSW-FMY-Fort Myers-Southwest Florida International-SW Florida Intl-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FEW",
            "countryCode": "US",
            "cityCode": "CYS",
            "cityName": "Cheyenne",
            "name": "[FEW] - Warren AFB - CYS - Cheyenne - United States",
            "searchName": "FEW-CYS-Warren AFB-Cheyenne-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CYT",
            "countryCode": "US",
            "cityCode": "CYT",
            "cityName": "Yakataga",
            "name": "[CYT] - Intermediate - CYT - Yakataga - United States",
            "searchName": "CYT-Intermediate Airport-Intermediate-Yakataga-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CZC",
            "countryCode": "US",
            "cityCode": "CZC",
            "cityName": "Copper Centre",
            "name": "[CZC] - Copper Centre - CZC - Copper Centre - United States",
            "searchName": "CZC-Copper Centre-Copper Center-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CXO",
            "countryCode": "US",
            "cityCode": "CXO",
            "cityName": "Conroe",
            "name": "[CXO] - Montgomery Co - CXO - Conroe - United States",
            "searchName": "CXO-Montgomery Co-Montgomery Co Havalimani-Conroe-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CYE",
            "countryCode": "US",
            "cityCode": "CYE",
            "cityName": "Crystal Lake",
            "name": "[CYE] - Crystal Lake - CYE - Crystal Lake - United States",
            "searchName": "CYE-Crystal Lake-Chrystal Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CYF",
            "countryCode": "US",
            "cityCode": "CYF",
            "cityName": "Chefornak",
            "name": "[CYF] - Chefornak SPB - CYF - Chefornak - United States",
            "searchName": "CYF-Chefornak-Chefornak SPB-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CUW",
            "countryCode": "US",
            "cityCode": "CUW",
            "cityName": "Cube Cove",
            "name": "[CUW] - Cube Cove - CUW - Cube Cove - United States",
            "searchName": "CUW-Cube Cove-Cube Cove TX-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CXL",
            "countryCode": "US",
            "cityCode": "CXL",
            "cityName": "Calexico",
            "name": "[CXL] - International - CXL - Calexico - United States",
            "searchName": "CXL-International-Calexico Uluslararasi Havalimani-Calexico-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CXC",
            "countryCode": "US",
            "cityCode": "CXC",
            "cityName": "Chitina",
            "name": "[CXC] - Chitina Airport - CXC - Chitina - United States",
            "searchName": "CXC-Chitina Airport-Chitina-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CXF",
            "countryCode": "US",
            "cityCode": "CXF",
            "cityName": "Coldfoot",
            "name": "[CXF] - Coldfoot - CXF - Coldfoot - United States",
            "searchName": "CXF-Coldfoot-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CWS",
            "countryCode": "US",
            "cityCode": "CWS",
            "cityName": "Center Island",
            "name": "[CWS] - Center Island - CWS - Center Island - United States",
            "searchName": "CWS-Center Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CMX",
            "countryCode": "US",
            "cityCode": "CMX",
            "cityName": "Hancock",
            "name": "[CMX] - Houghton County - CMX - Hancock - United States",
            "searchName": "CMX-Hancock-Houghton County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CMY",
            "countryCode": "US",
            "cityCode": "CMY",
            "cityName": "Sparta",
            "name": "[CMY] - Fort Mccoy - CMY - Sparta - United States",
            "searchName": "CMY-Fort Mccoy-Camp Mccoy Aaf Havalimani-Sparta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CNE",
            "countryCode": "US",
            "cityCode": "CNE",
            "cityName": "Canon City",
            "name": "[CNE] - Canon City - CNE - Canon City - United States",
            "searchName": "CNE-Canon City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CNH",
            "countryCode": "US",
            "cityCode": "CNH",
            "cityName": "Claremont",
            "name": "[CNH] - Municipal - CNH - Claremont - United States",
            "searchName": "CNH-Municipal-Claremont Belediyesi Havalimani-Claremont-L-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "QWG",
            "countryCode": "US",
            "cityCode": "CLT",
            "cityName": "Charlotte",
            "name": "[QWG] - Wilgrove Air Park - CLT - Charlotte - United States",
            "searchName": "QWG-CLT-Wilgrove Air Park-Charlotte-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLU",
            "countryCode": "US",
            "cityCode": "CLU",
            "cityName": "Columbus",
            "name": "[CLU] - Columbus Municipal - CLU - Columbus - United States",
            "searchName": "CLU-Columbus Municipal-Columbus  Havalimani-Columbus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLP",
            "countryCode": "US",
            "cityCode": "CLP",
            "cityName": "Clarks Point",
            "name": "[CLP] - Clarks Point - CLP - Clarks Point - United States",
            "searchName": "CLP-Clarks Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLW",
            "countryCode": "US",
            "cityCode": "CLW",
            "cityName": "Clearwater",
            "name": "[CLW] - Executive - CLW - Clearwater - United States",
            "searchName": "CLW-Executive Airport-Executive-Clearwater Executive Airpark Havalimani-Clearwater-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CMH",
            "countryCode": "US",
            "cityCode": "CMH",
            "cityName": "Columbus",
            "name": "[CMH] - Port Columbus International - CMH - Columbus - United States",
            "searchName": "CMH-Port Columbus-Port Columbus International-Columbus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LCK",
            "countryCode": "US",
            "cityCode": "CMH",
            "cityName": "Columbus",
            "name": "[LCK] - Rickenbacker - CMH - Columbus - United States",
            "searchName": "LCK-CMH-Rickenbacker-Rickenbacker Uluslararasi Havalimani-Rickenbaker-Columbus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OSU",
            "countryCode": "US",
            "cityCode": "CMH",
            "cityName": "Columbus",
            "name": "[OSU] - Ohio State University - CMH - Columbus - United States",
            "searchName": "OSU-CMH-Ohio State University-Ohio State Univ Havalimani-Columbus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PWK",
            "countryCode": "US",
            "cityCode": "CHI",
            "cityName": "Chicago",
            "name": "[PWK] - Pal-Waukee - CHI - Chicago - United States",
            "searchName": "PWK-CHI-Pal-Waukee-Pal Waukee Havalimani-Chicago-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RFD",
            "countryCode": "US",
            "cityCode": "CHI",
            "cityName": "Chicago",
            "name": "[RFD] - Greater Rockford Airport - CHI - Chicago - United States",
            "searchName": "RFD-CHI-Rockford-Greater Rockford Airport-Greater Rockford-Chicago-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BOF",
            "countryCode": "US",
            "cityCode": "WAS",
            "cityName": "Washington",
            "name": "[BOF] - Bolling AFB - WAS - Washington - United States",
            "searchName": "BOF-WAS-Bolling AFB-Washington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JPN",
            "countryCode": "US",
            "cityCode": "WAS",
            "cityName": "Washington",
            "name": "[JPN] - Pentagon Army - WAS - Washington - United States",
            "searchName": "JPN-WAS-Pentagon Army-Washington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LAW",
            "countryCode": "US",
            "cityCode": "LAW",
            "cityName": "Lawton",
            "name": "[LAW] - Municipal - LAW - Lawton - United States",
            "searchName": "LAW-Lawton-Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IWS",
            "countryCode": "US",
            "cityCode": "HOU",
            "cityName": "Houston",
            "name": "[IWS] - West Houston - HOU - Houston - United States",
            "searchName": "IWS-HOU-West Houston-West Houston Havalimani-Houston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JGP",
            "countryCode": "US",
            "cityCode": "HOU",
            "cityName": "Houston",
            "name": "[JGP] - Greenway Plaza Heliport - HOU - Houston - United States",
            "searchName": "JGP-HOU-Greenway Plaza Heliport-Houston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JGQ",
            "countryCode": "US",
            "cityCode": "HOU",
            "cityName": "Houston",
            "name": "[JGQ] - Transco Twr Galleria - HOU - Houston - United States",
            "searchName": "JGQ-HOU-Transco Twr Galleria-Houston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JDX",
            "countryCode": "US",
            "cityCode": "HOU",
            "cityName": "Houston",
            "name": "[JDX] - Central Bus. District - HOU - Houston - United States",
            "searchName": "JDX-HOU-Central Bus. District-Houston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JMA",
            "countryCode": "US",
            "cityCode": "HOU",
            "cityName": "Houston",
            "name": "[JMA] - Marriot Astrodome - HOU - Houston - United States",
            "searchName": "JMA-HOU-Marriot Astrodome-Houston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JPT",
            "countryCode": "US",
            "cityCode": "HOU",
            "cityName": "Houston",
            "name": "[JPT] - Park Ten Heliport - HOU - Houston - United States",
            "searchName": "JPT-HOU-Park Ten Heliport-Houston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JWH",
            "countryCode": "US",
            "cityCode": "HOU",
            "cityName": "Houston",
            "name": "[JWH] - Westchase Hilton Heliport - HOU - Houston - United States",
            "searchName": "JWH-HOU-Westchase Hilton Heliport-Houston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JWL",
            "countryCode": "US",
            "cityCode": "HOU",
            "cityName": "Houston",
            "name": "[JWL] - Woodlawns - HOU - Houston - United States",
            "searchName": "JWL-HOU-Woodlawns-Houston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "EFD",
            "countryCode": "US",
            "cityCode": "HOU",
            "cityName": "Houston",
            "name": "[EFD] - Ellington Field - HOU - Houston - United States",
            "searchName": "EFD-HOU-Landeplatz Ellington-Ellington Field-Ellington Field Havalimani-Houston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DWH",
            "countryCode": "US",
            "cityCode": "HOU",
            "cityName": "Houston",
            "name": "[DWH] - David Wayne Hooks - HOU - Houston - United States",
            "searchName": "DWH-HOU-David Wayne Hooks-David Wayne Hooks Havalimani-Houston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HVN",
            "countryCode": "US",
            "cityCode": "HVN",
            "cityName": "New Haven",
            "name": "[HVN] - New Haven - HVN - New Haven - United States",
            "searchName": "HVN-New Haven-Tweed-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZVE",
            "countryCode": "US",
            "cityCode": "HVN",
            "cityName": "New Haven",
            "name": "[ZVE] - New Haven Railway - HVN - New Haven - United States",
            "searchName": "ZVE-HVN-New Haven Railway-New Haven-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HVR",
            "countryCode": "US",
            "cityCode": "HVR",
            "cityName": "Havre",
            "name": "[HVR] - City County - HVR - Havre - United States",
            "searchName": "HVR-Havre-City County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HVS",
            "countryCode": "US",
            "cityCode": "HVS",
            "cityName": "Hartsville",
            "name": "[HVS] - Municipal - HVS - Hartsville - United States",
            "searchName": "HVS-Municipal-Hartsville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HWD",
            "countryCode": "US",
            "cityCode": "HWD",
            "cityName": "Hayward",
            "name": "[HWD] - Air Terminal - HWD - Hayward - United States",
            "searchName": "HWD-Air Terminal-Hayward Uçak terminali Havalimani-Hayward-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HWI",
            "countryCode": "US",
            "cityCode": "HWI",
            "cityName": "Hawk Inlet",
            "name": "[HWI] - SPB - HWI - Hawk Inlet - United States",
            "searchName": "HWI-SPB-Hawk Inlet-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HVE",
            "countryCode": "US",
            "cityCode": "HVE",
            "cityName": "Hanksville",
            "name": "[HVE] - Intermediate - HVE - Hanksville - United States",
            "searchName": "HVE-Intermediate Airport-Intermediate-Intermediate Havalimani-Hanksville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HYG",
            "countryCode": "US",
            "cityCode": "HYG",
            "cityName": "Hydaburg",
            "name": "[HYG] - SPB - HYG - Hydaburg - United States",
            "searchName": "HYG-Hydaburg-SPB-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HYL",
            "countryCode": "US",
            "cityCode": "HYL",
            "cityName": "Hollis",
            "name": "[HYL] - SPB - HYL - Hollis - United States",
            "searchName": "HYL-Hollis-SPB-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HWO",
            "countryCode": "US",
            "cityCode": "HWO",
            "cityName": "Hollywood",
            "name": "[HWO] - North Perry - HWO - Hollywood - United States",
            "searchName": "HWO-Nord-Perry-North Perry-Hollywood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HYA",
            "countryCode": "US",
            "cityCode": "HYA",
            "cityName": "Hyannis",
            "name": "[HYA] - Barnstable - HYA - Hyannis - United States",
            "searchName": "HYA-Hyannis-Barnstable-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HZL",
            "countryCode": "US",
            "cityCode": "HZL",
            "cityName": "Hazleton",
            "name": "[HZL] - Hazleton - HZL - Hazleton - United States",
            "searchName": "HZL-Hazleton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HYR",
            "countryCode": "US",
            "cityCode": "HYR",
            "cityName": "Hayward",
            "name": "[HYR] - Municipal - HYR - Hayward - United States",
            "searchName": "HYR-Municipal-Hayward Belediyesi Havalimani-Hayward-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HYS",
            "countryCode": "US",
            "cityCode": "HYS",
            "cityName": "Hays",
            "name": "[HYS] - Municipal - HYS - Hays - United States",
            "searchName": "HYS-Hays-Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HSP",
            "countryCode": "US",
            "cityCode": "HSP",
            "cityName": "Hot Springs",
            "name": "[HSP] - Ingalls Field - HSP - Hot Springs - United States",
            "searchName": "HSP-Ingalls Field-Ingalls Field Havalimani-Hot Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HTH",
            "countryCode": "US",
            "cityCode": "HTH",
            "cityName": "Hawthorne",
            "name": "[HTH] - Hawthorne - HTH - Hawthorne - United States",
            "searchName": "HTH-Hawthorne-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HTL",
            "countryCode": "US",
            "cityCode": "HTL",
            "cityName": "Houghton",
            "name": "[HTL] - Roscommon County - HTL - Houghton - United States",
            "searchName": "HTL-Roscommon County-Houghton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HTV",
            "countryCode": "US",
            "cityCode": "HTV",
            "cityName": "Huntsville",
            "name": "[HTV] - Huntsville - HTV - Huntsville - United States",
            "searchName": "HTV-Huntsville-Huntsville Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HTW",
            "countryCode": "US",
            "cityCode": "HTW",
            "cityName": "Huntington",
            "name": "[HTW] - Lawrence County Airpark - HTW - Huntington - United States",
            "searchName": "HTW-Lawrence County Airpark-Huntington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HTS",
            "countryCode": "US",
            "cityCode": "HTS",
            "cityName": "Huntington",
            "name": "[HTS] - Tri-state/Milton - HTS - Huntington - United States",
            "searchName": "HTS-Huntington-Tri-state/Milton-Tri State Airport-Tri State Milton Airport-Huntington/Ashland-Ashland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HTO",
            "countryCode": "US",
            "cityCode": "HTO",
            "cityName": "East Hampton",
            "name": "[HTO] - East Hampton - HTO - East Hampton - United States",
            "searchName": "HTO-East Hampton-East Hampton Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HUS",
            "countryCode": "US",
            "cityCode": "HUS",
            "cityName": "Hughes",
            "name": "[HUS] - Municipal - HUS - Hughes - United States",
            "searchName": "HUS-Hughes-Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HUT",
            "countryCode": "US",
            "cityCode": "HUT",
            "cityName": "Hutchinson",
            "name": "[HUT] - Hutchinson - HUT - Hutchinson - United States",
            "searchName": "HUT-Hutchinson-Hutchinson Belediyesi Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HUL",
            "countryCode": "US",
            "cityCode": "HUL",
            "cityName": "Houlton",
            "name": "[HUL] - International - HUL - Houlton - United States",
            "searchName": "HUL-International-Houlton Uluslararasi Havalimani-Houlton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HUM",
            "countryCode": "US",
            "cityCode": "HUM",
            "cityName": "Houma",
            "name": "[HUM] - Terrebonne - HUM - Houma - United States",
            "searchName": "HUM-Terrebonne-Terrebonne Havalimani-Houma-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HUD",
            "countryCode": "US",
            "cityCode": "HUD",
            "cityName": "Humboldt",
            "name": "[HUD] - Humboldt - HUD - Humboldt - United States",
            "searchName": "HUD-Humboldt-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HUF",
            "countryCode": "US",
            "cityCode": "HUF",
            "cityName": "Terre Haute",
            "name": "[HUF] - Hulman Field - HUF - Terre Haute - United States",
            "searchName": "HUF-Landeplatz Hulman-Hulman Field-Terre Haute-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HUJ",
            "countryCode": "US",
            "cityCode": "HUJ",
            "cityName": "Hugo",
            "name": "[HUJ] - Hugo - HUJ - Hugo - United States",
            "searchName": "HUJ-Hugo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HMS",
            "countryCode": "US",
            "cityCode": "HMS",
            "cityName": "Homeshore",
            "name": "[HMS] - Homeshore - HMS - Homeshore - United States",
            "searchName": "HMS-Homeshore-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HMT",
            "countryCode": "US",
            "cityCode": "HMT",
            "cityName": "Hemet",
            "name": "[HMT] - Ryan Field - HMT - Hemet - United States",
            "searchName": "HMT-Ryan Field-Hemet-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HNM",
            "countryCode": "US",
            "cityCode": "HNM",
            "cityName": "Hana",
            "name": "[HNM] - Hana - HNM - Hana - United States",
            "searchName": "HNM-Hana-Maui-Hawaii-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HNB",
            "countryCode": "US",
            "cityCode": "HNB",
            "cityName": "Huntingburg",
            "name": "[HNB] - Municipal - HNB - Huntingburg - United States",
            "searchName": "HNB-Municipal-Huntingburg Belediyesi Havalimani-Huntingburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HNC",
            "countryCode": "US",
            "cityCode": "HNC",
            "cityName": "Hatteras",
            "name": "[HNC] - Hatteras - HNC - Hatteras - United States",
            "searchName": "HNC-Hatteras-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HNE",
            "countryCode": "US",
            "cityCode": "HNE",
            "cityName": "Tahneta Pass Lodge",
            "name": "[HNE] - Tahneta Pass - HNE - Tahneta Pass Lodge - United States",
            "searchName": "HNE-Tahneta-Tahneta Pass-Tahneta Pass Lodge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HLG",
            "countryCode": "US",
            "cityCode": "HLG",
            "cityName": "Wheeling",
            "name": "[HLG] - Ohio County - HLG - Wheeling - United States",
            "searchName": "HLG-Ohio County-Wheeling-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HLI",
            "countryCode": "US",
            "cityCode": "HLI",
            "cityName": "Hollister",
            "name": "[HLI] - Hollister - HLI - Hollister - United States",
            "searchName": "HLI-Hollister-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HLM",
            "countryCode": "US",
            "cityCode": "HLM",
            "cityName": "Holland",
            "name": "[HLM] - Park Township - HLM - Holland - United States",
            "searchName": "HLM-Park Township-Holland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HLN",
            "countryCode": "US",
            "cityCode": "HLN",
            "cityName": "Helena",
            "name": "[HLN] - Helena - HLN - Helena - United States",
            "searchName": "HLN-Helena-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HHR",
            "countryCode": "US",
            "cityCode": "HHR",
            "cityName": "Hawthorne",
            "name": "[HHR] - Hawthorne - HHR - Hawthorne - United States",
            "searchName": "HHR-Hawthorne-Hawthorne Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HHH",
            "countryCode": "US",
            "cityCode": "HHH",
            "cityName": "Hilton Head",
            "name": "[HHH] - Hilton Head - HHH - Hilton Head - United States",
            "searchName": "HHH-Hilton Head Island-Hilton Head-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HHI",
            "countryCode": "US",
            "cityCode": "HHI",
            "cityName": "Wahiawa",
            "name": "[HHI] - Wheeler AFB - HHI - Wahiawa - United States",
            "searchName": "HHI-Wheeler AFB-Wahiawa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HIB",
            "countryCode": "US",
            "cityCode": "HIB",
            "cityName": "Chisholm",
            "name": "[HIB] - Chisholm - HIB - Chisholm - United States",
            "searchName": "HIB-Hibbing-Chisholm-Chisholm/Hibbing-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HII",
            "countryCode": "US",
            "cityCode": "HII",
            "cityName": "Lake Havasu City",
            "name": "[HII] - Municipal - HII - Lake Havasu City - United States",
            "searchName": "HII-Municipal-Lake Havasu City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HIE",
            "countryCode": "US",
            "cityCode": "HIE",
            "cityName": "Whitefield",
            "name": "[HIE] - Regional - HIE - Whitefield - United States",
            "searchName": "HIE-Regionaler Flughafen-Regional-Mt washington Yerel Havalimani-Whitefield UT-Whitefield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HKY",
            "countryCode": "US",
            "cityCode": "HKY",
            "cityName": "Hickory",
            "name": "[HKY] - Hickory - HKY - Hickory - United States",
            "searchName": "HKY-Hickory-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HLB",
            "countryCode": "US",
            "cityCode": "HLB",
            "cityName": "Batesville",
            "name": "[HLB] - Hillenbrand - HLB - Batesville - United States",
            "searchName": "HLB-Hillenbrand-Hillenbrand Havalimani-Batesville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HLC",
            "countryCode": "US",
            "cityCode": "HLC",
            "cityName": "Hill City",
            "name": "[HLC] - Hill City - HLC - Hill City - United States",
            "searchName": "HLC-Hill City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HIO",
            "countryCode": "US",
            "cityCode": "HIO",
            "cityName": "Hillsboro",
            "name": "[HIO] - Portland - HIO - Hillsboro - United States",
            "searchName": "HIO-Portland-Portland Hillsboro Havalimani-Hillsboro-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HKB",
            "countryCode": "US",
            "cityCode": "HKB",
            "cityName": "Healy Lake",
            "name": "[HKB] - Healy Lake - HKB - Healy Lake - United States",
            "searchName": "HKB-Healy Lake-Healy G-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HDE",
            "countryCode": "US",
            "cityCode": "HDE",
            "cityName": "Holdrege",
            "name": "[HDE] - Brewster Field - HDE - Holdrege - United States",
            "searchName": "HDE-Brewster Field-Holdrege-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HCR",
            "countryCode": "US",
            "cityCode": "HCR",
            "cityName": "Holy Cross",
            "name": "[HCR] - Holy Cross - HCR - Holy Cross - United States",
            "searchName": "HCR-Holy Cross-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HCW",
            "countryCode": "US",
            "cityCode": "HCW",
            "cityName": "Cheraw",
            "name": "[HCW] - Cheraw - HCW - Cheraw - United States",
            "searchName": "HCW-Cheraw-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HDA",
            "countryCode": "US",
            "cityCode": "HDA",
            "cityName": "Hidden Falls",
            "name": "[HDA] - Hidden Falls - HDA - Hidden Falls - United States",
            "searchName": "HDA-Hidden Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HDH",
            "countryCode": "US",
            "cityCode": "HDH",
            "cityName": "Oahu",
            "name": "[HDH] - Dillingham Airfield - HDH - Oahu - United States",
            "searchName": "HDH-Dillingham Airfield-Oahu-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HDN",
            "countryCode": "US",
            "cityCode": "HDN",
            "cityName": "Hayden",
            "name": "[HDN] - Yampa Valley - HDN - Hayden - United States",
            "searchName": "HDN-Steamboat Springs-Yampa Valley-Hayden-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HBG",
            "countryCode": "US",
            "cityCode": "HBG",
            "cityName": "Hattiesburg",
            "name": "[HBG] - The Hattiesburg-Bobby L. Chain Mun. - HBG - Hattiesburg - United States",
            "searchName": "HBG-Kommunaler Flughafen Bobby L Chai-The Hattiesburg-Bobby L. Chain Mun.-Bobby L Chain Belediyesi Havalimani-Hattiesburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HBH",
            "countryCode": "US",
            "cityCode": "HBH",
            "cityName": "Hobart Bay",
            "name": "[HBH] - Hobart Bay - HBH - Hobart Bay - United States",
            "searchName": "HBH-Hobart Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HBC",
            "countryCode": "US",
            "cityCode": "HBC",
            "cityName": "Hanus Bay",
            "name": "[HBC] - Hanus Bay - HBC - Hanus Bay - United States",
            "searchName": "HBC-Hanus Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HAY",
            "countryCode": "US",
            "cityCode": "HAY",
            "cityName": "Haycock",
            "name": "[HAY] - Haycock - HAY - Haycock - United States",
            "searchName": "HAY-Haycock-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HCA",
            "countryCode": "US",
            "cityCode": "HCA",
            "cityName": "Big Spring",
            "name": "[HCA] - Howard County - HCA - Big Spring - United States",
            "searchName": "HCA-Howard County-Big Spring-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BGS",
            "countryCode": "US",
            "cityCode": "HCA",
            "cityName": "Big Spring",
            "name": "[BGS] - Webb AFB - HCA - Big Spring - United States",
            "searchName": "BGS-HCA-Webb AFB-Big Spring-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HCB",
            "countryCode": "US",
            "cityCode": "HCB",
            "cityName": "Shoal Cove",
            "name": "[HCB] - Shoal Cove - HCB - Shoal Cove - United States",
            "searchName": "HCB-Shoal Cove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HCC",
            "countryCode": "US",
            "cityCode": "HCC",
            "cityName": "Hudson",
            "name": "[HCC] - Columbia County - HCC - Hudson - United States",
            "searchName": "HCC-Columbia County-Hudson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HBK",
            "countryCode": "US",
            "cityCode": "HBK",
            "cityName": "Holbrook",
            "name": "[HBK] - Holbrook Municipal - HBK - Holbrook - United States",
            "searchName": "HBK-Holbrook Municipal-Holbrook-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HBO",
            "countryCode": "US",
            "cityCode": "HBO",
            "cityName": "Humboldt",
            "name": "[HBO] - Municipal - HBO - Humboldt - United States",
            "searchName": "HBO-Kommunaler Flughafen-Municipal-Humboldt-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HBR",
            "countryCode": "US",
            "cityCode": "HBR",
            "cityName": "Hobart",
            "name": "[HBR] - Hobart - HBR - Hobart - United States",
            "searchName": "HBR-Hobart-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HES",
            "countryCode": "US",
            "cityCode": "HES",
            "cityName": "Hermiston",
            "name": "[HES] - State - HES - Hermiston - United States",
            "searchName": "HES-State-Hermiston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HFD",
            "countryCode": "US",
            "cityCode": "HFD",
            "cityName": "Hartford",
            "name": "[HFD] - Brainard - HFD - Hartford - United States",
            "searchName": "HFD-Brainard-tüm havaalanlari-Hartford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BNH",
            "countryCode": "US",
            "cityCode": "HFD",
            "cityName": "Hartford",
            "name": "[BNH] - Barnes - HFD - Hartford - United States",
            "searchName": "BNH-HFD-Barnes-Hartford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZRT",
            "countryCode": "US",
            "cityCode": "HFD",
            "cityName": "Hartford",
            "name": "[ZRT] - Hartford  Railway - HFD - Hartford - United States",
            "searchName": "ZRT-HFD-Hartford  Railway-Hartford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HED",
            "countryCode": "US",
            "cityCode": "HED",
            "cityName": "Herendeen",
            "name": "[HED] - Herendeen - HED - Herendeen - United States",
            "searchName": "HED-Herendeen-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HEE",
            "countryCode": "US",
            "cityCode": "HEE",
            "cityName": "Helena",
            "name": "[HEE] - Thompson-Robbins - HEE - Helena - United States",
            "searchName": "HEE-Thompson-Robbins-Helena-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HGZ",
            "countryCode": "US",
            "cityCode": "HGZ",
            "cityName": "Hogatza",
            "name": "[HGZ] - Hogatza - HGZ - Hogatza - United States",
            "searchName": "HGZ-Hogatza-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HGR",
            "countryCode": "US",
            "cityCode": "HGR",
            "cityName": "Hagerstown",
            "name": "[HGR] - Wash. County Regional - HGR - Hagerstown - United States",
            "searchName": "HGR-Hagerstown-Wash. County Regional-Wash. County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HGT",
            "countryCode": "US",
            "cityCode": "HGT",
            "cityName": "Jolon",
            "name": "[HGT] - Hunter AAF - HGT - Jolon - United States",
            "searchName": "HGT-Militärflughafen Hunter-Hunter AAF-Jolon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HEZ",
            "countryCode": "US",
            "cityCode": "HEZ",
            "cityName": "Natchez",
            "name": "[HEZ] - Hardy-Anders - HEZ - Natchez - United States",
            "searchName": "HEZ-Hardy-Anders-Natchez Adams Cty Havalimani-Natchez-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HFF",
            "countryCode": "US",
            "cityCode": "HFF",
            "cityName": "Hoffman",
            "name": "[HFF] - Mackall AAF - HFF - Hoffman - United States",
            "searchName": "HFF-Militärflughafen Mackall-Mackall AAF-Hoffman-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IFA",
            "countryCode": "US",
            "cityCode": "IFA",
            "cityName": "Iowa Falls",
            "name": "[IFA] - Iowa Falls - IFA - Iowa Falls - United States",
            "searchName": "IFA-Iowa Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IDP",
            "countryCode": "US",
            "cityCode": "IDP",
            "cityName": "Independence",
            "name": "[IDP] - Independence - IDP - Independence - United States",
            "searchName": "IDP-Independence-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IDG",
            "countryCode": "US",
            "cityCode": "IDG",
            "cityName": "Ida Grove",
            "name": "[IDG] - Municipal - IDG - Ida Grove - United States",
            "searchName": "IDG-Municipal-Ida Grove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IDH",
            "countryCode": "US",
            "cityCode": "IDH",
            "cityName": "Grangeville",
            "name": "[IDH] - Idaho County - IDH - Grangeville - United States",
            "searchName": "IDH-Idaho County-Grangeville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IDI",
            "countryCode": "US",
            "cityCode": "IDI",
            "cityName": "Indiana",
            "name": "[IDI] - ndiana County Airport (Jimmy Stewart Field) - IDI - Indiana - United States",
            "searchName": "IDI-ndiana County Airport (Jimmy Stewart Field)-Indiana County Havalimani-Indiana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IFP",
            "countryCode": "US",
            "cityCode": "IFP",
            "cityName": "Bullhead City",
            "name": "[IFP] - Laughlin Bullhead International - IFP - Bullhead City - United States",
            "searchName": "IFP-Internationaler Flughafen Laughlin\\/Bullhead-Laughlin Bullhead International-Laughlin Bullhead-Bullhead City-Bullhead City\\/Laughlin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IGG",
            "countryCode": "US",
            "cityCode": "IGG",
            "cityName": "Igiugig",
            "name": "[IGG] - Igiugig - IGG - Igiugig - United States",
            "searchName": "IGG-Igiugig-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IGM",
            "countryCode": "US",
            "cityCode": "IGM",
            "cityName": "Kingman",
            "name": "[IGM] - Kingman - IGM - Kingman - United States",
            "searchName": "IGM-Kingman-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ICL",
            "countryCode": "US",
            "cityCode": "ICL",
            "cityName": "Clarinda",
            "name": "[ICL] - Municipal - ICL - Clarinda - United States",
            "searchName": "ICL-Municipal-Clarinda-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ICS",
            "countryCode": "US",
            "cityCode": "ICS",
            "cityName": "Cascade",
            "name": "[ICS] - Cascade - ICS - Cascade - United States",
            "searchName": "ICS-Cascade-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ICT",
            "countryCode": "US",
            "cityCode": "ICT",
            "cityName": "Wichita",
            "name": "[ICT] - Mid-Continent - ICT - Wichita - United States",
            "searchName": "ICT-Wichita-Mid-Continent-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IAB",
            "countryCode": "US",
            "cityCode": "ICT",
            "cityName": "Wichita",
            "name": "[IAB] - Mcconnell AFB - ICT - Wichita - United States",
            "searchName": "IAB-ICT-Mcconnell AFB-Wichita-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CEA",
            "countryCode": "US",
            "cityCode": "ICT",
            "cityName": "Wichita",
            "name": "[CEA] - Cessna Aircraft Field - ICT - Wichita - United States",
            "searchName": "CEA-ICT-Cessna Aircraft Field-Wichita-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BEC",
            "countryCode": "US",
            "cityCode": "ICT",
            "cityName": "Wichita",
            "name": "[BEC] - Beech - ICT - Wichita - United States",
            "searchName": "BEC-ICT-Beech-Wichita-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ICY",
            "countryCode": "US",
            "cityCode": "ICY",
            "cityName": "Icy Bay",
            "name": "[ICY] - Icy Bay - ICY - Icy Bay - United States",
            "searchName": "ICY-Icy Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IDA",
            "countryCode": "US",
            "cityCode": "IDA",
            "cityName": "Idaho Falls",
            "name": "[IDA] - Fanning Field - IDA - Idaho Falls - United States",
            "searchName": "IDA-Idaho Falls-Fanning Field-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IAG",
            "countryCode": "US",
            "cityCode": "IAG",
            "cityName": "Niagara Falls",
            "name": "[IAG] - International - IAG - Niagara Falls - United States",
            "searchName": "IAG-Niagara Falls-International-Niagara Falls Uluslararasi Havalimani-Cataratas del Niagara-Chutes du Niagara-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IAN",
            "countryCode": "US",
            "cityCode": "IAN",
            "cityName": "Kiana",
            "name": "[IAN] - Bob Barker Memorial - IAN - Kiana - United States",
            "searchName": "IAN-Kiana-Bob Barker Memorial-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ILI",
            "countryCode": "US",
            "cityCode": "ILI",
            "cityName": "Iliamna",
            "name": "[ILI] - Iliamna - ILI - Iliamna - United States",
            "searchName": "ILI-Iliamna-Limna-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ILL",
            "countryCode": "US",
            "cityCode": "ILL",
            "cityName": "Willmar",
            "name": "[ILL] - Willmar - ILL - Willmar - United States",
            "searchName": "ILL-Willmar-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ILM",
            "countryCode": "US",
            "cityCode": "ILM",
            "cityName": "Wilmington",
            "name": "[ILM] - Wilmington International - ILM - Wilmington - United States",
            "searchName": "ILM-Wilmington North Carolina-Wilmington International-New Hanover County-Wilmington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ILN",
            "countryCode": "US",
            "cityCode": "ILN",
            "cityName": "Wilmington",
            "name": "[ILN] - Clinton Field - ILN - Wilmington - United States",
            "searchName": "ILN-Clinton Field-Wilmington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FKN",
            "countryCode": "US",
            "cityCode": "FKN",
            "cityName": "Franklin",
            "name": "[FKN] - Municipal - FKN - Franklin - United States",
            "searchName": "FKN-Municipal-Franklin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FIC",
            "countryCode": "US",
            "cityCode": "FIC",
            "cityName": "Fire Cove",
            "name": "[FIC] - Fire Cove - FIC - Fire Cove - United States",
            "searchName": "FIC-Fire Cove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FID",
            "countryCode": "US",
            "cityCode": "FID",
            "cityName": "Fishers Island",
            "name": "[FID] - Elizabeth Field - FID - Fishers Island - United States",
            "searchName": "FID-Elizabeth Field-Fishers Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FIL",
            "countryCode": "US",
            "cityCode": "FIL",
            "cityName": "Fillmore",
            "name": "[FIL] - Municipal - FIL - Fillmore - United States",
            "searchName": "FIL-Municipal-Fillmore-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FHU",
            "countryCode": "US",
            "cityCode": "FHU",
            "cityName": "Sierra Vista",
            "name": "[FHU] - Municipal - FHU - Sierra Vista - United States",
            "searchName": "FHU-Kommunaler Flughafen-Municipal-Sierra Vista Belediyesi Havalimani-Fort Huachuca\\/ Sierra Vista-Sierra Vista-Ft Huachuca-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FFL",
            "countryCode": "US",
            "cityCode": "FFL",
            "cityName": "Fairfield",
            "name": "[FFL] - Municipal - FFL - Fairfield - United States",
            "searchName": "FFL-Municipal-Fairfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FFM",
            "countryCode": "US",
            "cityCode": "FFM",
            "cityName": "Fergus Falls",
            "name": "[FFM] - Fergus Falls - FFM - Fergus Falls - United States",
            "searchName": "FFM-Fergus Falls-Fergus Falls Belediyesi Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FFT",
            "countryCode": "US",
            "cityCode": "FFT",
            "cityName": "Frankfort",
            "name": "[FFT] - Capital City - FFT - Frankfort - United States",
            "searchName": "FFT-Capital City-Frankfort-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FDK",
            "countryCode": "US",
            "cityCode": "FDK",
            "cityName": "Frederick",
            "name": "[FDK] - Frederick Municipal - FDK - Frederick - United States",
            "searchName": "FDK-Frederick Municipal-Frederick Belediyesi Havalimani-Frederick-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FDR",
            "countryCode": "US",
            "cityCode": "FDR",
            "cityName": "Frederick",
            "name": "[FDR] - Municipal - FDR - Frederick - United States",
            "searchName": "FDR-Municipal-Frederick-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FDY",
            "countryCode": "US",
            "cityCode": "FDY",
            "cityName": "Findlay",
            "name": "[FDY] - Findlay - FDY - Findlay - United States",
            "searchName": "FDY-Findlay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FFA",
            "countryCode": "US",
            "cityCode": "FFA",
            "cityName": "Kill Devil Hills",
            "name": "[FFA] - First Flight - FFA - Kill Devil Hills - United States",
            "searchName": "FFA-First Flight-Kill Devil Hills-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FET",
            "countryCode": "US",
            "cityCode": "FET",
            "cityName": "Fremont",
            "name": "[FET] - Municipal - FET - Fremont - United States",
            "searchName": "FET-Municipal-Fremont-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FEP",
            "countryCode": "US",
            "cityCode": "FEP",
            "cityName": "Freeport",
            "name": "[FEP] - Albertus - FEP - Freeport - United States",
            "searchName": "FEP-Albertus-Freeport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZTF",
            "countryCode": "US",
            "cityCode": "ZTF",
            "cityName": "Stamford",
            "name": "[ZTF] - Stamford Railway Station - ZTF - Stamford - United States",
            "searchName": "ZTF-Stamford Railway Station-Stamford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZSM",
            "countryCode": "US",
            "cityCode": "ZSM",
            "cityName": "Santa Clara",
            "name": "[ZSM] - Santa Clara Bus Station - ZSM - Santa Clara - United States",
            "searchName": "ZSM-Santa Clara Bus Station-Santa Clara-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZSY",
            "countryCode": "US",
            "cityCode": "ZSY",
            "cityName": "Scottsdale",
            "name": "[ZSY] - Scottsdale Bus Station - ZSY - Scottsdale - United States",
            "searchName": "ZSY-Scottsdale Bus Station-Scottsdale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZSH",
            "countryCode": "US",
            "cityCode": "ZSH",
            "cityName": "Santa Fe",
            "name": "[ZSH] - Santa Fe Bus Station - ZSH - Santa Fe - United States",
            "searchName": "ZSH-Santa Fe Bus Station-Santa Fe-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SAF",
            "countryCode": "US",
            "cityCode": "ZSH",
            "cityName": "Santa Fe",
            "name": "[SAF] - Santa Fe - ZSH - Santa Fe - United States",
            "searchName": "SAF-ZSH-Santa Fe-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZNC",
            "countryCode": "US",
            "cityCode": "ZNC",
            "cityName": "Nyac",
            "name": "[ZNC] - Nyac - ZNC - Nyac - United States",
            "searchName": "ZNC-Nyac-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZPH",
            "countryCode": "US",
            "cityCode": "ZPH",
            "cityName": "Zephyrhills",
            "name": "[ZPH] - Zephyrhills - ZPH - Zephyrhills - United States",
            "searchName": "ZPH-Zephyrhills-Zephyrhills Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZZV",
            "countryCode": "US",
            "cityCode": "ZZV",
            "cityName": "Zanesville",
            "name": "[ZZV] - Zanesville - ZZV - Zanesville - United States",
            "searchName": "ZZV-Zanesville-Zanesville Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZWV",
            "countryCode": "US",
            "cityCode": "ZWV",
            "cityName": "Glenview",
            "name": "[ZWV] - Glenview Railway Station - ZWV - Glenview - United States",
            "searchName": "ZWV-Glenview Railway Station-Glenview-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZUK",
            "countryCode": "US",
            "cityCode": "ZUK",
            "cityName": "Lake Cook Road",
            "name": "[ZUK] - Lake Cook Road Railway Station - ZUK - Lake Cook Road - United States",
            "searchName": "ZUK-Lake Cook Road Railway Station-Lake Cook Road-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZTV",
            "countryCode": "US",
            "cityCode": "ZTV",
            "cityName": "Sturtevant",
            "name": "[ZTV] - Sturtevant Railway Station - ZTV - Sturtevant - United States",
            "searchName": "ZTV-Sturtevant Railway Station-Sturtevant-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "YNG",
            "countryCode": "US",
            "cityCode": "YNG",
            "cityName": "Youngstown",
            "name": "[YNG] - Youngstown - YNG - Youngstown - United States",
            "searchName": "YNG-Youngstown-Youngstown Ohio-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "YKM",
            "countryCode": "US",
            "cityCode": "YKM",
            "cityName": "Yakima",
            "name": "[YKM] - Yakima Air Terminal - YKM - Yakima - United States",
            "searchName": "YKM-Yakima-Yakima Air Terminal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FCT",
            "countryCode": "US",
            "cityCode": "YKM",
            "cityName": "Yakima",
            "name": "[FCT] - Firing Center AAF - YKM - Yakima - United States",
            "searchName": "FCT-YKM-Firing Center AAF-Yakima-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "YKN",
            "countryCode": "US",
            "cityCode": "YKN",
            "cityName": "Yankton",
            "name": "[YKN] - Chan Gurney - YKN - Yankton - United States",
            "searchName": "YKN-Chan Gurney-Chan Gurney Belediyesi Havalimani-Yankton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "YUM",
            "countryCode": "US",
            "cityCode": "YUM",
            "cityName": "Yuma",
            "name": "[YUM] - International - YUM - Yuma - United States",
            "searchName": "YUM-Yuma-International-Yuma Arizona-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LGF",
            "countryCode": "US",
            "cityCode": "YUM",
            "cityName": "Yuma",
            "name": "[LGF] - Laguna AAF - YUM - Yuma - United States",
            "searchName": "LGF-YUM-Laguna AAF-Yuma Arizona-Yuma-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CYR",
            "countryCode": "UY",
            "cityCode": "CYR",
            "cityName": "Colonia",
            "name": "[CYR] - Laguna De Los Patos - CYR - Colonia - Uruguay",
            "searchName": "CYR-Laguna De Los Patos-Laguna de los Patos Havalimani-Colonia-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "DZO",
            "countryCode": "UY",
            "cityCode": "DZO",
            "cityName": "Durazno",
            "name": "[DZO] - Santa Bernardina - DZO - Durazno - Uruguay",
            "searchName": "DZO-Santa Bernardina-Durazno-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "BUV",
            "countryCode": "UY",
            "cityCode": "BUV",
            "cityName": "Bella Union",
            "name": "[BUV] - Bella Union - BUV - Bella Union - Uruguay",
            "searchName": "BUV-Bella Union-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "ATI",
            "countryCode": "UY",
            "cityCode": "ATI",
            "cityName": "Artigas",
            "name": "[ATI] - Artigas - ATI - Artigas - Uruguay",
            "searchName": "ATI-Artigas-Artigas Havalimani-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "VCH",
            "countryCode": "UY",
            "cityCode": "VCH",
            "cityName": "Vichadero",
            "name": "[VCH] - Vichadero - VCH - Vichadero - Uruguay",
            "searchName": "VCH-Vichadero-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "STY",
            "countryCode": "UY",
            "cityCode": "STY",
            "cityName": "Salto",
            "name": "[STY] - Salto - STY - Salto - Uruguay",
            "searchName": "STY-Salto-Salto Havalimani-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "TAW",
            "countryCode": "UY",
            "cityCode": "TAW",
            "cityName": "Tacuarembo",
            "name": "[TAW] - Tacuarembo - TAW - Tacuarembo - Uruguay",
            "searchName": "TAW-Tacuarembo-Tacuarembó-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "TYT",
            "countryCode": "UY",
            "cityCode": "TYT",
            "cityName": "Treinta-y-Tres",
            "name": "[TYT] - Treinta-y-Tres - TYT - Treinta-y-Tres - Uruguay",
            "searchName": "TYT-Treinta-y-Tres-Treinta y Tres-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "RVY",
            "countryCode": "UY",
            "cityCode": "RVY",
            "cityName": "Rivera",
            "name": "[RVY] - Rivera - RVY - Rivera - Uruguay",
            "searchName": "RVY-Rivera-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "PDU",
            "countryCode": "UY",
            "cityCode": "PDU",
            "cityName": "Paysandu",
            "name": "[PDU] - Paysandu - PDU - Paysandu - Uruguay",
            "searchName": "PDU-Paysandu-Paysandu Havalimani-Paysandú-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "PDP",
            "countryCode": "UY",
            "cityCode": "PDP",
            "cityName": "Maldonado",
            "name": "[PDP] - Capitan Corbeta CA Curbelo International Airport - PDP - Maldonado - Uruguay",
            "searchName": "PDP-Punta Del Este-Capitan Corbeta CA Curbelo International Airport-Punta del Este-Maldonado-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "PVB",
            "countryCode": "UY",
            "cityCode": "PDP",
            "cityName": "Maldonado",
            "name": "[PVB] - Punta Del Este Bus Station - PDP - Maldonado - Uruguay",
            "searchName": "PVB-PDP-Punta Del Este Bus Station-Punta del Este-Maldonado-Punta Del Este-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "IMT",
            "countryCode": "US",
            "cityCode": "IMT",
            "cityName": "Iron Mountain",
            "name": "[IMT] - Ford - IMT - Iron Mountain - United States",
            "searchName": "IMT-Iron Mountain-Ford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IML",
            "countryCode": "US",
            "cityCode": "IML",
            "cityName": "Imperial",
            "name": "[IML] - Imperial - IML - Imperial - United States",
            "searchName": "IML-Imperial-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IMM",
            "countryCode": "US",
            "cityCode": "IMM",
            "cityName": "Immokalee",
            "name": "[IMM] - Immokalee - IMM - Immokalee - United States",
            "searchName": "IMM-Immokalee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IKO",
            "countryCode": "US",
            "cityCode": "IKO",
            "cityName": "Nikolski",
            "name": "[IKO] - Nikolski AFS - IKO - Nikolski - United States",
            "searchName": "IKO-Nikolski-Nikolski AFS-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IKK",
            "countryCode": "US",
            "cityCode": "IKK",
            "cityName": "Kankakee",
            "name": "[IKK] - Greater Kankakee - IKK - Kankakee - United States",
            "searchName": "IKK-Greater Kankakee-Greater Kankakee Havalimani-Kankakee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IJX",
            "countryCode": "US",
            "cityCode": "IJX",
            "cityName": "Jacksonville",
            "name": "[IJX] - Municipal - IJX - Jacksonville - United States",
            "searchName": "IJX-Municipal-Jacksonville  Havalimani-Jacksonville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IKB",
            "countryCode": "US",
            "cityCode": "IKB",
            "cityName": "Wilkesboro",
            "name": "[IKB] - Wilkes County - IKB - Wilkesboro - United States",
            "searchName": "IKB-Wilkes County-Wilkesboro-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ILG",
            "countryCode": "US",
            "cityCode": "ILG",
            "cityName": "Wilmington",
            "name": "[ILG] - Greater Wilmington - ILG - Wilmington - United States",
            "searchName": "ILG-Great Wilmington-Greater Wilmington-Wilmington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZWI",
            "countryCode": "US",
            "cityCode": "ILG",
            "cityName": "Wilmington",
            "name": "[ZWI] - Wilmington Railway - ILG - Wilmington - United States",
            "searchName": "ZWI-ILG-Wilmington Railway-Wilmington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ILE",
            "countryCode": "US",
            "cityCode": "ILE",
            "cityName": "Killeen",
            "name": "[ILE] - Municipal - ILE - Killeen - United States",
            "searchName": "ILE-Kommunaler Flughafen-Municipal-Killeen-Fort Hood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HLR",
            "countryCode": "US",
            "cityCode": "ILE",
            "cityName": "Killeen",
            "name": "[HLR] - Fort Hood AAF - ILE - Killeen - United States",
            "searchName": "HLR-ILE-Fort Hood AAF-Killeen-Fort Hood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GRK",
            "countryCode": "US",
            "cityCode": "ILE",
            "cityName": "Killeen",
            "name": "[GRK] - Robert Gray AAF - ILE - Killeen - United States",
            "searchName": "GRK-ILE-Killeen-Fort Hood-Robert Gray AAF-Fort Hood Regional-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IRK",
            "countryCode": "US",
            "cityCode": "IRK",
            "cityName": "Kirksville",
            "name": "[IRK] - Municipal - IRK - Kirksville - United States",
            "searchName": "IRK-Cannon Memorial-Municipal-Kirksville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IRB",
            "countryCode": "US",
            "cityCode": "IRB",
            "cityName": "Iraan",
            "name": "[IRB] - Municipal - IRB - Iraan - United States",
            "searchName": "IRB-Kommunaler Flughafen-Municipal-Iraan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IRC",
            "countryCode": "US",
            "cityCode": "IRC",
            "cityName": "Circle",
            "name": "[IRC] - Circle City - IRC - Circle - United States",
            "searchName": "IRC-Circle City-Circle-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IRS",
            "countryCode": "US",
            "cityCode": "IRS",
            "cityName": "Sturgis",
            "name": "[IRS] - Kirsch Municipal - IRS - Sturgis - United States",
            "searchName": "IRS-Kirsch Municipal-Kirsch Belediyesi Havalimani-Sturgis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ISL",
            "countryCode": "US",
            "cityCode": "ISL",
            "cityName": "Isabel Pass",
            "name": "[ISL] - Isabel Pass - ISL - Isabel Pass - United States",
            "searchName": "ISL-Isabel Pass-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ISM",
            "countryCode": "US",
            "cityCode": "ISM",
            "cityName": "Kissimmee",
            "name": "[ISM] - Kissimmee Gateway - ISM - Kissimmee - United States",
            "searchName": "ISM-Kommunaler Flughafen-Kissimmee Gateway-Kissimmee  Havalimani-Kissimmee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ISN",
            "countryCode": "US",
            "cityCode": "ISN",
            "cityName": "Williston",
            "name": "[ISN] - Sloulin Field International - ISN - Williston - United States",
            "searchName": "ISN-Williston-Sloulin Field International-Sloulin Field Intl-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ISO",
            "countryCode": "US",
            "cityCode": "ISO",
            "cityName": "Kinston",
            "name": "[ISO] - Stallings Field - ISO - Kinston - United States",
            "searchName": "ISO-Stallings Field-Kinston-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ISP",
            "countryCode": "US",
            "cityCode": "ISP",
            "cityName": "Islip",
            "name": "[ISP] - Long Island Mac Arthur - ISP - Islip - United States",
            "searchName": "ISP-Islip-Long Island Mac Arthur-Long Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ISQ",
            "countryCode": "US",
            "cityCode": "ISQ",
            "cityName": "Manistique",
            "name": "[ISQ] - Schoolcraft County - ISQ - Manistique - United States",
            "searchName": "ISQ-Schoolcraft County-Schoolcraft County Havalimani-Manistique-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ISS",
            "countryCode": "US",
            "cityCode": "ISS",
            "cityName": "Wiscasset",
            "name": "[ISS] - Wiscasset - ISS - Wiscasset - United States",
            "searchName": "ISS-Wiscasset-Wiscasset Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IOW",
            "countryCode": "US",
            "cityCode": "IOW",
            "cityName": "Iowa City",
            "name": "[IOW] - Iowa City - IOW - Iowa City - United States",
            "searchName": "IOW-Iowa City-Iowa City  Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IPL",
            "countryCode": "US",
            "cityCode": "IPL",
            "cityName": "Imperial",
            "name": "[IPL] - Imperial County - IPL - Imperial - United States",
            "searchName": "IPL-El Centro-Imperial County-El Centro/Imperial-Imperial-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NJK",
            "countryCode": "US",
            "cityCode": "IPL",
            "cityName": "Imperial",
            "name": "[NJK] - El Centro NAF - IPL - Imperial - United States",
            "searchName": "NJK-IPL-El Centro NAF-El Centro/Imperial-Imperial-El Centro-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IPT",
            "countryCode": "US",
            "cityCode": "IPT",
            "cityName": "Williamsport",
            "name": "[IPT] - Lycoming County - IPT - Williamsport - United States",
            "searchName": "IPT-Williamsport-Lycoming County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "INW",
            "countryCode": "US",
            "cityCode": "INW",
            "cityName": "Winslow",
            "name": "[INW] - Winslow-Lindbergh - INW - Winslow - United States",
            "searchName": "INW-Winslow-Lindbergh-Winslow Belediyesi Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "INS",
            "countryCode": "US",
            "cityCode": "INS",
            "cityName": "Indian Springs",
            "name": "[INS] - Af Aux - INS - Indian Springs - United States",
            "searchName": "INS-Af Aux-Indian Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "INT",
            "countryCode": "US",
            "cityCode": "INT",
            "cityName": "Winston Salem",
            "name": "[INT] - Smith-Reynolds - INT - Winston Salem - United States",
            "searchName": "INT-Salem NC - Flughafen Smith Reynolds-Smith-Reynolds-Winston-Winston Salem-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "INK",
            "countryCode": "US",
            "cityCode": "INK",
            "cityName": "Wink",
            "name": "[INK] - Wink - INK - Wink - United States",
            "searchName": "INK-Wink-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "INL",
            "countryCode": "US",
            "cityCode": "INL",
            "cityName": "International Falls",
            "name": "[INL] - Falls International - INL - International Falls - United States",
            "searchName": "INL-Intl Falls-Falls International-Falls Intl-International Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JAC",
            "countryCode": "US",
            "cityCode": "JAC",
            "cityName": "Jackson",
            "name": "[JAC] - Jackson Hole - JAC - Jackson - United States",
            "searchName": "JAC-Jackson Hole-Jackson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JAN",
            "countryCode": "US",
            "cityCode": "JAN",
            "cityName": "Jackson",
            "name": "[JAN] - Jackson-evers - JAN - Jackson - United States",
            "searchName": "JAN-Jackson-evers-Evers Intl-Jackson International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HKS",
            "countryCode": "US",
            "cityCode": "JAN",
            "cityName": "Jackson",
            "name": "[HKS] - Hawkins Field - JAN - Jackson - United States",
            "searchName": "HKS-JAN-Hawkins Field-Hawkins Field Havalimani-Jackson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "IYK",
            "countryCode": "US",
            "cityCode": "IYK",
            "cityName": "Inyokern",
            "name": "[IYK] - Kern County - IYK - Inyokern - United States",
            "searchName": "IYK-Inyokern-Kern County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FMH",
            "countryCode": "US",
            "cityCode": "FMH",
            "cityName": "Falmouth",
            "name": "[FMH] - Otis AFB - FMH - Falmouth - United States",
            "searchName": "FMH-Otis AFB-Falmouth-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FRM",
            "countryCode": "US",
            "cityCode": "FRM",
            "cityName": "Fairmont",
            "name": "[FRM] - Fairmont - FRM - Fairmont - United States",
            "searchName": "FRM-Fairmont-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FRN",
            "countryCode": "US",
            "cityCode": "FRN",
            "cityName": "Fort Richardson",
            "name": "[FRN] - Bryant AAF - FRN - Fort Richardson - United States",
            "searchName": "FRN-Bryant-Bryant AAF-Fort Richardson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FRR",
            "countryCode": "US",
            "cityCode": "FRR",
            "cityName": "Front Royal",
            "name": "[FRR] - Front Royal-Warren County - FRR - Front Royal - United States",
            "searchName": "FRR-Front Royal-Warren County-Warren County Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FRP",
            "countryCode": "US",
            "cityCode": "FRP",
            "cityName": "Fresh Water Bay",
            "name": "[FRP] - Fresh Water Bay - FRP - Fresh Water Bay - United States",
            "searchName": "FRP-Fresh Water Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FRY",
            "countryCode": "US",
            "cityCode": "FRY",
            "cityName": "Fryeburg",
            "name": "[FRY] - Fryeburg - FRY - Fryeburg - United States",
            "searchName": "FRY-Fryeburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FSD",
            "countryCode": "US",
            "cityCode": "FSD",
            "cityName": "Sioux Falls",
            "name": "[FSD] - Joe Foss Field Airport - FSD - Sioux Falls - United States",
            "searchName": "FSD-Sioux Falls-Joe Foss Field Airport-Joe Foss Field-Sioux Falls South Dakota-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FSI",
            "countryCode": "US",
            "cityCode": "FSI",
            "cityName": "Fort Sill",
            "name": "[FSI] - Henry Post AAF - FSI - Fort Sill - United States",
            "searchName": "FSI-Militärflughafen Henry Post-Henry Post AAF-Fort Sill-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FSK",
            "countryCode": "US",
            "cityCode": "FSK",
            "cityName": "Fort Scott",
            "name": "[FSK] - Municipal - FSK - Fort Scott - United States",
            "searchName": "FSK-Municipal-Fort Scott-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FRG",
            "countryCode": "US",
            "cityCode": "FRG",
            "cityName": "Farmingdale",
            "name": "[FRG] - Republic Field - FRG - Farmingdale - United States",
            "searchName": "FRG-Republiklandeplatz-Republic Field-Republic Havalimani-Farmingdale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FRH",
            "countryCode": "US",
            "cityCode": "FRH",
            "cityName": "French Lick",
            "name": "[FRH] - Municipal - FRH - French Lick - United States",
            "searchName": "FRH-Municipal-French Lick  Havalimani-French Lick-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FRI",
            "countryCode": "US",
            "cityCode": "FRI",
            "cityName": "Fort Riley",
            "name": "[FRI] - Marshall AAF - FRI - Fort Riley - United States",
            "searchName": "FRI-Marshall AAF-Fort Riley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FPR",
            "countryCode": "US",
            "cityCode": "FPR",
            "cityName": "Fort Pierce",
            "name": "[FPR] - St Lucie County - FPR - Fort Pierce - United States",
            "searchName": "FPR-St Lucie County-St Lucie County Havalimani-Fort Pierce-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FPY",
            "countryCode": "US",
            "cityCode": "FPY",
            "cityName": "Perry",
            "name": "[FPY] - Perry-Foley - FPY - Perry - United States",
            "searchName": "FPY-Perry-Foley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FRD",
            "countryCode": "US",
            "cityCode": "FRD",
            "cityName": "Friday Harbor",
            "name": "[FRD] - Friday Harbor - FRD - Friday Harbor - United States",
            "searchName": "FRD-Friday Harbor-Friday Harbor Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FOX",
            "countryCode": "US",
            "cityCode": "FOX",
            "cityName": "Fox",
            "name": "[FOX] - Fox - FOX - Fox - United States",
            "searchName": "FOX-Fox-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FMC",
            "countryCode": "US",
            "cityCode": "FMC",
            "cityName": "Five Mile",
            "name": "[FMC] - Five Mile - FMC - Five Mile - United States",
            "searchName": "FMC-Five Mile-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FME",
            "countryCode": "US",
            "cityCode": "FME",
            "cityName": "Fort Meade",
            "name": "[FME] - Tipton AAF - FME - Fort Meade - United States",
            "searchName": "FME-Tipton AAF-Fort Meade-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FLT",
            "countryCode": "US",
            "cityCode": "FLT",
            "cityName": "Flat",
            "name": "[FLT] - Flat - FLT - Flat - United States",
            "searchName": "FLT-Flat-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FLV",
            "countryCode": "US",
            "cityCode": "FLV",
            "cityName": "Fort Leavenworth",
            "name": "[FLV] - Sherman AAF - FLV - Fort Leavenworth - United States",
            "searchName": "FLV-Sherman-Sherman AAF-Fort Leavenworth-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FLJ",
            "countryCode": "US",
            "cityCode": "FLJ",
            "cityName": "Falls Bay",
            "name": "[FLJ] - Falls Bay - FLJ - Falls Bay - United States",
            "searchName": "FLJ-Falls Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FLL",
            "countryCode": "US",
            "cityCode": "FLL",
            "cityName": "Fort Lauderdale",
            "name": "[FLL] - Fort Lauderdaleâ€“Hollywood International - FLL - Fort Lauderdale - United States",
            "searchName": "FLL-Fort Lauderdale-Fort Lauderdale–Hollywood International-Ft Lauderdale Hollywood-Fort Lauderdaleâ€“Hollywood International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FXE",
            "countryCode": "US",
            "cityCode": "FLL",
            "cityName": "Fort Lauderdale",
            "name": "[FXE] - Fort Lauderdale Executive - FLL - Fort Lauderdale - United States",
            "searchName": "FXE-FLL-Fort Lauderdale Executive-Ft Lauderdale Excutive Havalimani-Fort Lauderdale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZFT",
            "countryCode": "US",
            "cityCode": "FLL",
            "cityName": "Fort Lauderdale",
            "name": "[ZFT] - Fort Lauderdale Railway Station - FLL - Fort Lauderdale - United States",
            "searchName": "ZFT-FLL-Fort Lauderdale Railway Station-Fort Lauderdale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FLO",
            "countryCode": "US",
            "cityCode": "FLO",
            "cityName": "Florence",
            "name": "[FLO] - Florence - FLO - Florence - United States",
            "searchName": "FLO-Florence-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FLP",
            "countryCode": "US",
            "cityCode": "FLP",
            "cityName": "Flippin",
            "name": "[FLP] - Flippin - FLP - Flippin - United States",
            "searchName": "FLP-Flippin-Flippin Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FLD",
            "countryCode": "US",
            "cityCode": "FLD",
            "cityName": "Fond Du Lac",
            "name": "[FLD] - Fond Du Lac - FLD - Fond Du Lac - United States",
            "searchName": "FLD-Fond Du Lac-Fond Du Lac Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FIV",
            "countryCode": "US",
            "cityCode": "FIV",
            "cityName": "Five Finger Coast Guard Heliport",
            "name": "[FIV] - Five Finger - FIV - Five Finger Coast Guard Heliport - United States",
            "searchName": "FIV-Five Finger-Five Finger Coast-Five Finger Coast Guard Heliport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CZE",
            "countryCode": "VE",
            "cityCode": "CZE",
            "cityName": "Coro",
            "name": "[CZE] - Coro - CZE - Coro - Venezuela",
            "searchName": "CZE-Coro-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "CUM",
            "countryCode": "VE",
            "cityCode": "CUM",
            "cityName": "Cumana",
            "name": "[CUM] - Cumana - CUM - Cumana - Venezuela",
            "searchName": "CUM-Cumana-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "CUP",
            "countryCode": "VE",
            "cityCode": "CUP",
            "cityName": "Carupano",
            "name": "[CUP] - Carupano - CUP - Carupano - Venezuela",
            "searchName": "CUP-Carupano-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "CUV",
            "countryCode": "VE",
            "cityCode": "CUV",
            "cityName": "Casigua",
            "name": "[CUV] - Casigua - CUV - Casigua - Venezuela",
            "searchName": "CUV-Casigua-El Cubo Airport-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "CXA",
            "countryCode": "VE",
            "cityCode": "CXA",
            "cityName": "Caicara Del Orinoco",
            "name": "[CXA] - Caicara Del Orinoco - CXA - Caicara Del Orinoco - Venezuela",
            "searchName": "CXA-Caicara Del Orinoco-Caicara de Oro-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "EOR",
            "countryCode": "VE",
            "cityCode": "EOR",
            "cityName": "El Dorado",
            "name": "[EOR] - El Dorado - EOR - El Dorado - Venezuela",
            "searchName": "EOR-El Dorado-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "EOZ",
            "countryCode": "VE",
            "cityCode": "EOZ",
            "cityName": "Elorza",
            "name": "[EOZ] - Elorza - EOZ - Elorza - Venezuela",
            "searchName": "EOZ-Elorza-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "ELX",
            "countryCode": "VE",
            "cityCode": "ELX",
            "cityName": "El Tigre",
            "name": "[ELX] - El Tigre - ELX - El Tigre - Venezuela",
            "searchName": "ELX-El Tigre-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "BRM",
            "countryCode": "VE",
            "cityCode": "BRM",
            "cityName": "Barquisimeto",
            "name": "[BRM] - Barquisimeto - BRM - Barquisimeto - Venezuela",
            "searchName": "BRM-Barquisimeto-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "BNS",
            "countryCode": "VE",
            "cityCode": "BNS",
            "cityName": "Barinas",
            "name": "[BNS] - Barinas - BNS - Barinas - Venezuela",
            "searchName": "BNS-Barinas-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "BLA",
            "countryCode": "VE",
            "cityCode": "BLA",
            "cityName": "Barcelona",
            "name": "[BLA] - Gen J A Anzoategui - BLA - Barcelona - Venezuela",
            "searchName": "BLA-Barcelona Venezuela-Gen J A Anzoategui-Barcelona-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "AAO",
            "countryCode": "VE",
            "cityCode": "AAO",
            "cityName": "Anaco",
            "name": "[AAO] - Anaco - AAO - Anaco - Venezuela",
            "searchName": "AAO-Anaco-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "AGV",
            "countryCode": "VE",
            "cityCode": "AGV",
            "cityName": "Acarigua",
            "name": "[AGV] - Acarigua - AGV - Acarigua - Venezuela",
            "searchName": "AGV-Acarigua-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "KAV",
            "countryCode": "VE",
            "cityCode": "KAV",
            "cityName": "Kavanayen",
            "name": "[KAV] - Kavanayen - KAV - Kavanayen - Venezuela",
            "searchName": "KAV-Kavanayen-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "LGY",
            "countryCode": "VE",
            "cityCode": "LGY",
            "cityName": "Lagunillas",
            "name": "[LGY] - Lagunillas - LGY - Lagunillas - Venezuela",
            "searchName": "LGY-Lagunillas-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "LFR",
            "countryCode": "VE",
            "cityCode": "LFR",
            "cityName": "La Fria",
            "name": "[LFR] - La Fria - LFR - La Fria - Venezuela",
            "searchName": "LFR-La Fria-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "LAG",
            "countryCode": "VE",
            "cityCode": "LAG",
            "cityName": "La Guaira",
            "name": "[LAG] - La Guaira - LAG - La Guaira - Venezuela",
            "searchName": "LAG-La Guaira-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "KTV",
            "countryCode": "VE",
            "cityCode": "KTV",
            "cityName": "Kamarata",
            "name": "[KTV] - Kamarata - KTV - Kamarata - Venezuela",
            "searchName": "KTV-Kamarata-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "LPJ",
            "countryCode": "VE",
            "cityCode": "LPJ",
            "cityName": "Pijiguaos",
            "name": "[LPJ] - Pijiguaos - LPJ - Pijiguaos - Venezuela",
            "searchName": "LPJ-Pijiguaos-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "LSP",
            "countryCode": "VE",
            "cityCode": "LSP",
            "cityName": "Las Piedras",
            "name": "[LSP] - Josefa Camejo - LSP - Las Piedras - Venezuela",
            "searchName": "LSP-Las Piedras-Josefa Camejo-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "LRV",
            "countryCode": "VE",
            "cityCode": "LRV",
            "cityName": "Los Roques",
            "name": "[LRV] - Los Roques - LRV - Los Roques - Venezuela",
            "searchName": "LRV-Los Roques-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "GDO",
            "countryCode": "VE",
            "cityCode": "GDO",
            "cityName": "Guasdualito",
            "name": "[GDO] - Vare Maria - GDO - Guasdualito - Venezuela",
            "searchName": "GDO-Vare Maria-Guasdualito-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "GUQ",
            "countryCode": "VE",
            "cityCode": "GUQ",
            "cityName": "Guanare",
            "name": "[GUQ] - Guanare - GUQ - Guanare - Venezuela",
            "searchName": "GUQ-Guanare-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "GUI",
            "countryCode": "VE",
            "cityCode": "GUI",
            "cityName": "Guiria",
            "name": "[GUI] - Guiria - GUI - Guiria - Venezuela",
            "searchName": "GUI-Guiria-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "SKD",
            "countryCode": "UZ",
            "cityCode": "SKD",
            "cityName": "Samarkand",
            "name": "[SKD] - Samarkand - SKD - Samarkand - Uzbekistan",
            "searchName": "SKD-Samarqand-Samarkand-Samarkand Airport-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "UGC",
            "countryCode": "UZ",
            "cityCode": "UGC",
            "cityName": "Urgench",
            "name": "[UGC] - Urgench - UGC - Urgench - Uzbekistan",
            "searchName": "UGC-Urgench-Urganch-Ourguentch-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "AZN",
            "countryCode": "UZ",
            "cityCode": "AZN",
            "cityName": "Andizhan",
            "name": "[AZN] - Andizhan - AZN - Andizhan - Uzbekistan",
            "searchName": "AZN-Andizhan-Andican-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "AFS",
            "countryCode": "UZ",
            "cityCode": "AFS",
            "cityName": "Zarafshan",
            "name": "[AFS] - Zarafshan - AFS - Zarafshan - Uzbekistan",
            "searchName": "AFS-Zarafshan-Zarafshan Airport-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "BHK",
            "countryCode": "UZ",
            "cityCode": "BHK",
            "cityName": "Bukhara",
            "name": "[BHK] - Bukhara - BHK - Bukhara - Uzbekistan",
            "searchName": "BHK-Bukhoro-Bukhara-Buchara-Bujara-Boukhara-Buhara-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "FEG",
            "countryCode": "UZ",
            "cityCode": "FEG",
            "cityName": "Fergana",
            "name": "[FEG] - Fergana - FEG - Fergana - Uzbekistan",
            "searchName": "FEG-Fergana-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "KSQ",
            "countryCode": "UZ",
            "cityCode": "KSQ",
            "cityName": "Karshi",
            "name": "[KSQ] - Karshi - KSQ - Karshi - Uzbekistan",
            "searchName": "KSQ-Karshi-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "BQU",
            "countryCode": "VC",
            "cityCode": "BQU",
            "cityName": "Bequia",
            "name": "[BQU] - J. F. Mitchell - BQU - Bequia - Saint Vincent and the Grenadines",
            "searchName": "BQU-Bequia-J. F. Mitchell-Port Elizabeth-St. Vincent und die Grenadinen-Saint Vincent and the Grenadines-Saint-Vincent-et-les Grenadines-San Vicente y las Granadinas-Saint Vincent e Grenadines",
            "priority": 0
        },
        {
            "iataCode": "CIW",
            "countryCode": "VC",
            "cityCode": "CIW",
            "cityName": "Canouan Island",
            "name": "[CIW] - Canouan Island - CIW - Canouan Island - Saint Vincent and the Grenadines",
            "searchName": "CIW-Canouan Island--St. Vincent und die Grenadinen-Saint Vincent and the Grenadines-Saint-Vincent-et-les Grenadines-San Vicente y las Granadinas-Saint Vincent e Grenadines",
            "priority": 0
        },
        {
            "iataCode": "UNI",
            "countryCode": "VC",
            "cityCode": "UNI",
            "cityName": "Union Island",
            "name": "[UNI] - Union Island - UNI - Union Island - Saint Vincent and the Grenadines",
            "searchName": "UNI-Union Island-Union Adasi Havalimani-St. Vincent und die Grenadinen-Saint Vincent and the Grenadines-Saint-Vincent-et-les Grenadines-San Vicente y las Granadinas-Saint Vincent e Grenadines",
            "priority": 0
        },
        {
            "iataCode": "SVD",
            "countryCode": "VC",
            "cityCode": "SVD",
            "cityName": "St Vincent",
            "name": "[SVD] - E. T. Joshua - SVD - St Vincent - Saint Vincent and the Grenadines",
            "searchName": "SVD-Kingstown-E. T. Joshua-E.T. Joshua-St Vincent-St. Vincent und die Grenadinen-Saint Vincent and the Grenadines-Saint-Vincent-et-les Grenadines-San Vicente y las Granadinas-Saint Vincent e Grenadines",
            "priority": 0
        },
        {
            "iataCode": "MQS",
            "countryCode": "VC",
            "cityCode": "MQS",
            "cityName": "Mustique Island",
            "name": "[MQS] - Mustique - MQS - Mustique Island - Saint Vincent and the Grenadines",
            "searchName": "MQS-Mustique-Mustique Havalimani-Mustique Island-St. Vincent und die Grenadinen-Saint Vincent and the Grenadines-Saint-Vincent-et-les Grenadines-San Vicente y las Granadinas-Saint Vincent e Grenadines",
            "priority": 0
        },
        {
            "iataCode": "PLI",
            "countryCode": "VC",
            "cityCode": "PLI",
            "cityName": "Palm Island",
            "name": "[PLI] - Palm Island - PLI - Palm Island - Saint Vincent and the Grenadines",
            "searchName": "PLI-Palm Island-St. Vincent und die Grenadinen-Saint Vincent and the Grenadines-Saint-Vincent-et-les Grenadines-San Vicente y las Granadinas-Saint Vincent e Grenadines",
            "priority": 0
        },
        {
            "iataCode": "PMV",
            "countryCode": "VE",
            "cityCode": "PMV",
            "cityName": "Porlamar",
            "name": "[PMV] - Del Caribe International (Gen. Santiago Marino) - PMV - Porlamar - Venezuela",
            "searchName": "PMV-Isla Margarita-Del Caribe International (Gen. Santiago Marino)-DelCaribe Marino-Porlamar-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "PPZ",
            "countryCode": "VE",
            "cityCode": "PPZ",
            "cityName": "Puerto Paez",
            "name": "[PPZ] - Puerto Paez - PPZ - Puerto Paez - Venezuela",
            "searchName": "PPZ-Puerto Paez-Puerto Páez-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "PPH",
            "countryCode": "VE",
            "cityCode": "PPH",
            "cityName": "Peraitepuy",
            "name": "[PPH] - Peraitepuy - PPH - Peraitepuy - Venezuela",
            "searchName": "PPH-Peraitepuy-Parai-Tepui-tepui-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "PDZ",
            "countryCode": "VE",
            "cityCode": "PDZ",
            "cityName": "Pedernales",
            "name": "[PDZ] - Pedernales - PDZ - Pedernales - Venezuela",
            "searchName": "PDZ-Pedernales-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "PBL",
            "countryCode": "VE",
            "cityCode": "PBL",
            "cityName": "Puerto Cabello",
            "name": "[PBL] - Puerto Cabello - PBL - Puerto Cabello - Venezuela",
            "searchName": "PBL-Puerto Cabello-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "PTM",
            "countryCode": "VE",
            "cityCode": "PTM",
            "cityName": "Palmarito",
            "name": "[PTM] - Palmarito - PTM - Palmarito - Venezuela",
            "searchName": "PTM-Palmarito-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "PZO",
            "countryCode": "VE",
            "cityCode": "PZO",
            "cityName": "Puerto Ordaz",
            "name": "[PZO] - Puerto Ordaz - PZO - Puerto Ordaz - Venezuela",
            "searchName": "PZO-Puerto Ordaz-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "PYH",
            "countryCode": "VE",
            "cityCode": "PYH",
            "cityName": "Puerto Ayacucho",
            "name": "[PYH] - Puerto Ayacucho - PYH - Puerto Ayacucho - Venezuela",
            "searchName": "PYH-Puerto Ayacucho-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "MRD",
            "countryCode": "VE",
            "cityCode": "MRD",
            "cityName": "Merida",
            "name": "[MRD] - A Carnevalli - MRD - Merida - Venezuela",
            "searchName": "MRD-A Carnevalli-Merida-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "MUN",
            "countryCode": "VE",
            "cityCode": "MUN",
            "cityName": "Maturin",
            "name": "[MUN] - Quiriquire - MUN - Maturin - Venezuela",
            "searchName": "MUN-Maturin-Quiriquire-Matur-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "MAR",
            "countryCode": "VE",
            "cityCode": "MAR",
            "cityName": "Maracaibo",
            "name": "[MAR] - La Chinita - MAR - Maracaibo - Venezuela",
            "searchName": "MAR-Maracaibo-La Chinita-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "MYC",
            "countryCode": "VE",
            "cityCode": "MYC",
            "cityName": "Maracay",
            "name": "[MYC] - Maracay - MYC - Maracay - Venezuela",
            "searchName": "MYC-Maracay-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "SVV",
            "countryCode": "VE",
            "cityCode": "SVV",
            "cityName": "San Salvador De",
            "name": "[SVV] - San Salvador De - SVV - San Salvador De - Venezuela",
            "searchName": "SVV-San Salvador De-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "SVZ",
            "countryCode": "VE",
            "cityCode": "SVZ",
            "cityName": "San Antonio",
            "name": "[SVZ] - San Antonio - SVZ - San Antonio - Venezuela",
            "searchName": "SVZ-San Antonio-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "STB",
            "countryCode": "VE",
            "cityCode": "STB",
            "cityName": "Santa Barbara Ed",
            "name": "[STB] - L Delicias - STB - Santa Barbara Ed - Venezuela",
            "searchName": "STB-L Delicias-Santa Barbara Ed-Santa Barbara-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "STD",
            "countryCode": "VE",
            "cityCode": "STD",
            "cityName": "Santo Domingo",
            "name": "[STD] - Mayo Guerrero - STD - Santo Domingo - Venezuela",
            "searchName": "STD-Santo Domingo Venezuela-Mayo Guerrero-Santo Domingo-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "SOM",
            "countryCode": "VE",
            "cityCode": "SOM",
            "cityName": "San Tome",
            "name": "[SOM] - Edmundo Barrios - SOM - San Tome - Venezuela",
            "searchName": "SOM-El Tigre-Edmundo Barrios-San Tome-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "SNV",
            "countryCode": "VE",
            "cityCode": "SNV",
            "cityName": "Santa Elena",
            "name": "[SNV] - Santa Elena - SNV - Santa Elena - Venezuela",
            "searchName": "SNV-Santa Elena-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "SNF",
            "countryCode": "VE",
            "cityCode": "SNF",
            "cityName": "San Felipe",
            "name": "[SNF] - San Felipe - SNF - San Felipe - Venezuela",
            "searchName": "SNF-San Felipe-San Felipe Havalimani-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "SCI",
            "countryCode": "VE",
            "cityCode": "SCI",
            "cityName": "San Cristobal",
            "name": "[SCI] - San Cristobal - SCI - San Cristobal - Venezuela",
            "searchName": "SCI-San Cristobal-Aeropuerto Santo Domingo Havalimani-San Crist-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "SBB",
            "countryCode": "VE",
            "cityCode": "SBB",
            "cityName": "Santa Barbara de Barinas",
            "name": "[SBB] - Santa Barbara Ba - SBB - Santa Barbara de Barinas - Venezuela",
            "searchName": "SBB-Santa Barbara Ba-Santa Barbara de Barinas-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "SFX",
            "countryCode": "VE",
            "cityCode": "SFX",
            "cityName": "San Felix",
            "name": "[SFX] - San Felix - SFX - San Felix - Venezuela",
            "searchName": "SFX-San Felix-San Felix Havalimani-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "SFD",
            "countryCode": "VE",
            "cityCode": "SFD",
            "cityName": "San Fernando De Apure",
            "name": "[SFD] - Las Flecheras - SFD - San Fernando De Apure - Venezuela",
            "searchName": "SFD-Las Flecheras-Las Flecheras Havalimani-San Fernando de Apure-San Fernando De Apure-San Fernando-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "TMO",
            "countryCode": "VE",
            "cityCode": "TMO",
            "cityName": "Tumeremo",
            "name": "[TMO] - Tumeremo - TMO - Tumeremo - Venezuela",
            "searchName": "TMO-Tumeremo-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "TUV",
            "countryCode": "VE",
            "cityCode": "TUV",
            "cityName": "Tucupita",
            "name": "[TUV] - Tucupita - TUV - Tucupita - Venezuela",
            "searchName": "TUV-Tucupita-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "VCR",
            "countryCode": "VE",
            "cityCode": "VCR",
            "cityName": "Carora",
            "name": "[VCR] - Carora - VCR - Carora - Venezuela",
            "searchName": "VCR-Carora-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "VDP",
            "countryCode": "VE",
            "cityCode": "VDP",
            "cityName": "Valle De Pascua",
            "name": "[VDP] - Valle De Pascua - VDP - Valle De Pascua - Venezuela",
            "searchName": "VDP-Valle De Pascua-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "VIG",
            "countryCode": "VE",
            "cityCode": "VIG",
            "cityName": "El Vigia",
            "name": "[VIG] - El Vigia - VIG - El Vigia - Venezuela",
            "searchName": "VIG-El Vigia-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "URM",
            "countryCode": "VE",
            "cityCode": "URM",
            "cityName": "Uriman",
            "name": "[URM] - Uriman - URM - Uriman - Venezuela",
            "searchName": "URM-Uriman-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "WOK",
            "countryCode": "VE",
            "cityCode": "WOK",
            "cityName": "Wonken",
            "name": "[WOK] - Wonken - WOK - Wonken - Venezuela",
            "searchName": "WOK-Wonken-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "VLV",
            "countryCode": "VE",
            "cityCode": "VLV",
            "cityName": "Valera",
            "name": "[VLV] - Carvajal - VLV - Valera - Venezuela",
            "searchName": "VLV-Carvajal-Valera-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "VLN",
            "countryCode": "VE",
            "cityCode": "VLN",
            "cityName": "Valencia",
            "name": "[VLN] - Arturo Michelena International Airport - VLN - Valencia - Venezuela",
            "searchName": "VLN-Valencia Venezuela-Arturo Michelena International Airport-Valencia-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "CLZ",
            "countryCode": "VE",
            "cityCode": "CLZ",
            "cityName": "Calabozo",
            "name": "[CLZ] - Calabozo - CLZ - Calabozo - Venezuela",
            "searchName": "CLZ-Calabozo-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "CGU",
            "countryCode": "VE",
            "cityCode": "CGU",
            "cityName": "Ciudad Guayana",
            "name": "[CGU] - Ciudad Guayana - CGU - Ciudad Guayana - Venezuela",
            "searchName": "CGU-Ciudad Guayana-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "CBL",
            "countryCode": "VE",
            "cityCode": "CBL",
            "cityName": "Ciudad Bolivar",
            "name": "[CBL] - Ciudad Bolivar - CBL - Ciudad Bolivar - Venezuela",
            "searchName": "CBL-Ciudad Bolivar-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "CBS",
            "countryCode": "VE",
            "cityCode": "CBS",
            "cityName": "Cabimas",
            "name": "[CBS] - Oro Negro - CBS - Cabimas - Venezuela",
            "searchName": "CBS-Oro Negro-Oro Negro Havalimani-Cabimas-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "CAJ",
            "countryCode": "VE",
            "cityCode": "CAJ",
            "cityName": "Canaima",
            "name": "[CAJ] - Canaima - CAJ - Canaima - Venezuela",
            "searchName": "CAJ-Canaima-Wenezuela-Venezuela-Ven",
            "priority": 0
        },
        {
            "iataCode": "TMK",
            "countryCode": "VN",
            "cityCode": "TMK",
            "cityName": "Tamky",
            "name": "[TMK] - Tamky - TMK - Tamky - Vietnam",
            "searchName": "TMK-Tamky-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "TBB",
            "countryCode": "VN",
            "cityCode": "TBB",
            "cityName": "Tuy Hoa",
            "name": "[TBB] - Dong Tac - TBB - Tuy Hoa - Vietnam",
            "searchName": "TBB-Tuy Hoa-Dong Tac-Dong Tac Airport-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "VII",
            "countryCode": "VN",
            "cityCode": "VII",
            "cityName": "Vinh City",
            "name": "[VII] - Vinh City - VII - Vinh City - Vietnam",
            "searchName": "VII-Vinh City-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "VDH",
            "countryCode": "VN",
            "cityCode": "VDH",
            "cityName": "Dong Hoi",
            "name": "[VDH] - Dong Hoi - VDH - Dong Hoi - Vietnam",
            "searchName": "VDH-Dong Hoi Airport-Dong Hoi-ong Hoi-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "VCS",
            "countryCode": "VN",
            "cityCode": "VCS",
            "cityName": "Con Dao",
            "name": "[VCS] - Coong - VCS - Con Dao - Vietnam",
            "searchName": "VCS-Con Dao-Coong-Coong Airport-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "VCL",
            "countryCode": "VN",
            "cityCode": "VCL",
            "cityName": "Tamky",
            "name": "[VCL] - Chulai - VCL - Tamky - Vietnam",
            "searchName": "VCL-Tamky-Chulai-Chulai Airport-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "VCA",
            "countryCode": "VN",
            "cityCode": "VCA",
            "cityName": "Can Tho",
            "name": "[VCA] - Can Tho - VCA - Can Tho - Vietnam",
            "searchName": "VCA-Can Tho-Can Tho Airport-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "UIH",
            "countryCode": "VN",
            "cityCode": "UIH",
            "cityName": "Qui Nhon",
            "name": "[UIH] - Qui Nhon - UIH - Qui Nhon - Vietnam",
            "searchName": "UIH-Quy Nhon-Qui Nhon-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "VKG",
            "countryCode": "VN",
            "cityCode": "VKG",
            "cityName": "Rach Gia",
            "name": "[VKG] - Rach Gia - VKG - Rach Gia - Vietnam",
            "searchName": "VKG-Rach Gia-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "VSO",
            "countryCode": "VN",
            "cityCode": "VSO",
            "cityName": "Phuoclong",
            "name": "[VSO] - Phuoclong - VSO - Phuoclong - Vietnam",
            "searchName": "VSO-Phuoclong-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "VTG",
            "countryCode": "VN",
            "cityCode": "VTG",
            "cityName": "Vung Tau",
            "name": "[VTG] - Vung Tau - VTG - Vung Tau - Vietnam",
            "searchName": "VTG-Vung Tau-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "XVL",
            "countryCode": "VN",
            "cityCode": "XVL",
            "cityName": "Vinh Long",
            "name": "[XVL] - Vinh Long - XVL - Vinh Long - Vietnam",
            "searchName": "XVL-Vinh Long-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "XLO",
            "countryCode": "VN",
            "cityCode": "XLO",
            "cityName": "Long Xuyen",
            "name": "[XLO] - Long Xuyen - XLO - Long Xuyen - Vietnam",
            "searchName": "XLO-Long Xuyen-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "XNG",
            "countryCode": "VN",
            "cityCode": "XNG",
            "cityName": "Quang Ngai",
            "name": "[XNG] - Quang Ngai - XNG - Quang Ngai - Vietnam",
            "searchName": "XNG-Quang Ngai-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "NHA",
            "countryCode": "VN",
            "cityCode": "NHA",
            "cityName": "Nha Trang",
            "name": "[NHA] - Nha Trang - NHA - Nha Trang - Vietnam",
            "searchName": "NHA-Nha Vietnam-Nha Trang-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "CXR",
            "countryCode": "VN",
            "cityCode": "NHA",
            "cityName": "Nha Trang",
            "name": "[CXR] - Cam Ranh - NHA - Nha Trang - Vietnam",
            "searchName": "CXR-NHA-Cam Ranh-Nha Trang-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "PXU",
            "countryCode": "VN",
            "cityCode": "PXU",
            "cityName": "Pleiku",
            "name": "[PXU] - Pleiku - PXU - Pleiku - Vietnam",
            "searchName": "PXU-Pleiku-Plei Cu-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "PHH",
            "countryCode": "VN",
            "cityCode": "PHH",
            "cityName": "Phan Thiet",
            "name": "[PHH] - Phan Thiet - PHH - Phan Thiet - Vietnam",
            "searchName": "PHH-Phan Thiet-Phan Thiet Havalimani-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "PHU",
            "countryCode": "VN",
            "cityCode": "PHU",
            "cityName": "Phu Vinh",
            "name": "[PHU] - Phu Vinh - PHU - Phu Vinh - Vietnam",
            "searchName": "PHU-Phu Vinh-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "PHA",
            "countryCode": "VN",
            "cityCode": "PHA",
            "cityName": "Phan Rang",
            "name": "[PHA] - Phan Rang - PHA - Phan Rang - Vietnam",
            "searchName": "PHA-Phan Rang-Phanrang-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "PQC",
            "countryCode": "VN",
            "cityCode": "PQC",
            "cityName": "Phu Quoc",
            "name": "[PQC] - Duong Dong - PQC - Phu Quoc - Vietnam",
            "searchName": "PQC-Phu Quoc-Duong Dong-Duong Dang-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "DIN",
            "countryCode": "VN",
            "cityCode": "DIN",
            "cityName": "Dien Bien Phu",
            "name": "[DIN] - Dien Bien - DIN - Dien Bien Phu - Vietnam",
            "searchName": "DIN-Dien Bien Phu-Dien Bien-Gialam-Bien Phu-Di-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "DLI",
            "countryCode": "VN",
            "cityCode": "DLI",
            "cityName": "Dalat",
            "name": "[DLI] - Lienkhang - DLI - Dalat - Vietnam",
            "searchName": "DLI-Da Lat-Lienkhang-Lien Khuong Intl-Dalat-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "CSJ",
            "countryCode": "VN",
            "cityCode": "CSJ",
            "cityName": "Cape St Jacques",
            "name": "[CSJ] - Cape St Jacques - CSJ - Cape St Jacques - Vietnam",
            "searchName": "CSJ-Cape St Jacques-Cape Saint Jacques-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "CAH",
            "countryCode": "VN",
            "cityCode": "CAH",
            "cityName": "Ca Mau",
            "name": "[CAH] - Ca Mau - CAH - Ca Mau - Vietnam",
            "searchName": "CAH-Ca Mau-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "BMV",
            "countryCode": "VN",
            "cityCode": "BMV",
            "cityName": "Banmethuot",
            "name": "[BMV] - Phung-Duc - BMV - Banmethuot - Vietnam",
            "searchName": "BMV-Ban Me Thout-Phung-Duc-Doc-Banmethuot-Buon Ma Thuot-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "HBN",
            "countryCode": "VN",
            "cityCode": "HBN",
            "cityName": "Phu-bon",
            "name": "[HBN] - Flamingo - HBN - Phu-bon - Vietnam",
            "searchName": "HBN-Bon - Flughafen-Flamingo-Phu-bon-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "HUI",
            "countryCode": "VN",
            "cityCode": "HUI",
            "cityName": "Hue",
            "name": "[HUI] - Phu Bai - HUI - Hue - Vietnam",
            "searchName": "HUI-Hue Vietnam-Phu Bai-Hue-Hu-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "HOO",
            "countryCode": "VN",
            "cityCode": "HOO",
            "cityName": "Quanduc",
            "name": "[HOO] - Nhon Co - HOO - Quanduc - Vietnam",
            "searchName": "HOO-Nhon Co-Quanduc-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "HPH",
            "countryCode": "VN",
            "cityCode": "HPH",
            "cityName": "Haiphong",
            "name": "[HPH] - Catbi - HPH - Haiphong - Vietnam",
            "searchName": "HPH-Hai Phong-Catbi-Haiphong-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "EIS",
            "countryCode": "VG",
            "cityCode": "EIS",
            "cityName": "Beef Island",
            "name": "[EIS] - Beef Island - EIS - Beef Island - British Virgin Islands",
            "searchName": "EIS-Beef Island--British Virgin Islands-Britische Jungferninseln-Ingiliz Virgin Adalari-Islas Vírgenes Británicas-Îles Vierges britanniques-Isole Vergini Britanniche",
            "priority": 0
        },
        {
            "iataCode": "VIJ",
            "countryCode": "VG",
            "cityCode": "VIJ",
            "cityName": "Spanish Town",
            "name": "[VIJ] - Spanish Town - VIJ - Spanish Town - British Virgin Islands",
            "searchName": "VIJ-Spanish Town-Virgin Gorda-British Virgin Islands-Britische Jungferninseln-Ingiliz Virgin Adalari-Islas Vírgenes Británicas-Îles Vierges britanniques-Isole Vergini Britanniche",
            "priority": 0
        },
        {
            "iataCode": "TOV",
            "countryCode": "VG",
            "cityCode": "TOV",
            "cityName": "Tortola",
            "name": "[TOV] - West End SPB - TOV - Tortola - British Virgin Islands",
            "searchName": "TOV-West End-West End SPB-Tortola-British Virgin Islands-Britische Jungferninseln-Ingiliz Virgin Adalari-Islas Vírgenes Británicas-Îles Vierges britanniques-Isole Vergini Britanniche",
            "priority": 0
        },
        {
            "iataCode": "RAD",
            "countryCode": "VG",
            "cityCode": "TOV",
            "cityName": "Tortola",
            "name": "[RAD] - Road Town - TOV - Tortola - British Virgin Islands",
            "searchName": "RAD-TOV-Road Town-Road Town Havalimani-Tortola-British Virgin Islands-Britische Jungferninseln-Ingiliz Virgin Adalari-Islas Vírgenes Británicas-Îles Vierges britanniques-Isole Vergini Britanniche",
            "priority": 0
        },
        {
            "iataCode": "NGD",
            "countryCode": "VG",
            "cityCode": "NGD",
            "cityName": "Anegada",
            "name": "[NGD] - Captain Auguste George - NGD - Anegada - British Virgin Islands",
            "searchName": "NGD-Captain Auguste George-Anegada-British Virgin Islands-Britische Jungferninseln-Ingiliz Virgin Adalari-Islas Vírgenes Británicas-Îles Vierges britanniques-Isole Vergini Britanniche",
            "priority": 0
        },
        {
            "iataCode": "NSX",
            "countryCode": "VG",
            "cityCode": "NSX",
            "cityName": "Nord Sound",
            "name": "[NSX] - Hovercraft/Launch Pt - NSX - Nord Sound - British Virgin Islands",
            "searchName": "NSX-Hovercraft/Launch Pt-North Sound Water Aerodrome-Nord Sound-British Virgin Islands-Britische Jungferninseln-Ingiliz Virgin Adalari-Islas Vírgenes Británicas-Îles Vierges britanniques-Isole Vergini Britanniche",
            "priority": 0
        },
        {
            "iataCode": "SJF",
            "countryCode": "VI",
            "cityCode": "SJF",
            "cityName": "St John Island",
            "name": "[SJF] - St John Island - SJF - St John Island - U.S. Virgin Islands",
            "searchName": "SJF-St John Island-St John Adasi Havalimani-St John-Ilhas Virgens (E.U.A.)-Maagdeneilanden(VS)-Kepulauan Virgin Amerika Serikat-Amerikanska Jungfruöarna-Kepulauan Virgin-U.S. Virgin Islands-Amerikanische Jungferninseln-ABD Virgin Adalari-Islas Vírgenes de los Estados Unidos-Îles Vierges des États-Unis-Isole Vergini Americane",
            "priority": 0
        },
        {
            "iataCode": "STT",
            "countryCode": "VI",
            "cityCode": "STT",
            "cityName": "Charlotte Amalie",
            "name": "[STT] - Cyril E King Airport - STT - Charlotte Amalie - U.S. Virgin Islands",
            "searchName": "STT-Charlotte Amalie-Cyril E King Airport-St Thomas Island--Ilhas Virgens (E.U.A.)-Maagdeneilanden(VS)-Kepulauan Virgin Amerika Serikat-Amerikanska Jungfruöarna-Kepulauan Virgin-U.S. Virgin Islands-Amerikanische Jungferninseln-ABD Virgin Adalari-Islas Vírgenes de los Estados Unidos-Îles Vierges des États-Unis-Isole Vergini Americane",
            "priority": 0
        },
        {
            "iataCode": "SPB",
            "countryCode": "VI",
            "cityCode": "STT",
            "cityName": "Charlotte Amalie",
            "name": "[SPB] - SPB - STT - Charlotte Amalie - U.S. Virgin Islands",
            "searchName": "SPB-STT-St Thomas Island-Charlotte Amalie--Ilhas Virgens (E.U.A.)-Maagdeneilanden(VS)-Kepulauan Virgin Amerika Serikat-Amerikanska Jungfruöarna-Kepulauan Virgin-U.S. Virgin Islands-Amerikanische Jungferninseln-ABD Virgin Adalari-Islas Vírgenes de los Estados Unidos-Îles Vierges des États-Unis-Isole Vergini Americane",
            "priority": 0
        },
        {
            "iataCode": "STX",
            "countryCode": "VI",
            "cityCode": "STX",
            "cityName": "Christiansted",
            "name": "[STX] - Henry E Rohlsen - STX - Christiansted - U.S. Virgin Islands",
            "searchName": "STX-Christiansted-Henry E Rohlsen-Henry Rohlsen-Henry E. Rohlsen Airport-St Croix Island-Saint Croix-Ilhas Virgens (E.U.A.)-Maagdeneilanden(VS)-Kepulauan Virgin Amerika Serikat-Amerikanska Jungfruöarna-Kepulauan Virgin-U.S. Virgin Islands-Amerikanische Jungferninseln-ABD Virgin Adalari-Islas Vírgenes de los Estados Unidos-Îles Vierges des États-Unis-Isole Vergini Americane",
            "priority": 0
        },
        {
            "iataCode": "SSB",
            "countryCode": "VI",
            "cityCode": "STX",
            "cityName": "Christiansted",
            "name": "[SSB] - SPB - STX - Christiansted - U.S. Virgin Islands",
            "searchName": "SSB-STX-SPB-Sea Plane Base-St Croix Island-Christiansted-Saint Croix-Ilhas Virgens (E.U.A.)-Maagdeneilanden(VS)-Kepulauan Virgin Amerika Serikat-Amerikanska Jungfruöarna-Kepulauan Virgin-U.S. Virgin Islands-Amerikanische Jungferninseln-ABD Virgin Adalari-Islas Vírgenes de los Estados Unidos-Îles Vierges des États-Unis-Isole Vergini Americane",
            "priority": 0
        },
        {
            "iataCode": "JCD",
            "countryCode": "VI",
            "cityCode": "STX",
            "cityName": "Christiansted",
            "name": "[JCD] - Downtown Heliport - STX - Christiansted - U.S. Virgin Islands",
            "searchName": "JCD-STX-Downtown Heliport-St Croix Island-Christiansted-Saint Croix-Ilhas Virgens (E.U.A.)-Maagdeneilanden(VS)-Kepulauan Virgin Amerika Serikat-Amerikanska Jungfruöarna-Kepulauan Virgin-U.S. Virgin Islands-Amerikanische Jungferninseln-ABD Virgin Adalari-Islas Vírgenes de los Estados Unidos-Îles Vierges des États-Unis-Isole Vergini Americane",
            "priority": 0
        },
        {
            "iataCode": "SOA",
            "countryCode": "VN",
            "cityCode": "SOA",
            "cityName": "Soc Trang",
            "name": "[SOA] - Soc Trang - SOA - Soc Trang - Vietnam",
            "searchName": "SOA-Soc Trang-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "SQH",
            "countryCode": "VN",
            "cityCode": "SQH",
            "cityName": "Son-La",
            "name": "[SQH] - Na-San - SQH - Son-La - Vietnam",
            "searchName": "SQH-La - Flughafen Na-San-Na-Son-La-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "KON",
            "countryCode": "VN",
            "cityCode": "KON",
            "cityName": "Kontum",
            "name": "[KON] - Kontum - KON - Kontum - Vietnam",
            "searchName": "KON-Kontum-Vietnam-Viêt Nam-Wietnam-Vijetnam-Vjetnama-Vietna",
            "priority": 0
        },
        {
            "iataCode": "LPM",
            "countryCode": "VU",
            "cityCode": "LPM",
            "cityName": "Lamap",
            "name": "[LPM] - Lamap - LPM - Lamap - Vanuatu",
            "searchName": "LPM-Lamap-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "LOD",
            "countryCode": "VU",
            "cityCode": "LOD",
            "cityName": "Longana",
            "name": "[LOD] - Longana - LOD - Longana - Vanuatu",
            "searchName": "LOD-Longana-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "LNE",
            "countryCode": "VU",
            "cityCode": "LNE",
            "cityName": "Lonorore",
            "name": "[LNE] - Lonorore - LNE - Lonorore - Vanuatu",
            "searchName": "LNE-Londrore-Lonorore-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "LNB",
            "countryCode": "VU",
            "cityCode": "LNB",
            "cityName": "Lamen Bay",
            "name": "[LNB] - Lamen Bay - LNB - Lamen Bay - Vanuatu",
            "searchName": "LNB-Lamen Bay-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "GBA",
            "countryCode": "VU",
            "cityCode": "GBA",
            "cityName": "Big Bay",
            "name": "[GBA] - Big Bay - GBA - Big Bay - Vanuatu",
            "searchName": "GBA-Big Bay-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "FTA",
            "countryCode": "VU",
            "cityCode": "FTA",
            "cityName": "Futuna Island",
            "name": "[FTA] - Futuna Airport - FTA - Futuna Island - Vanuatu",
            "searchName": "FTA-Futuna Airport-Futuna Inseln-Futuna Island--Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "IPA",
            "countryCode": "VU",
            "cityCode": "IPA",
            "cityName": "Ipota",
            "name": "[IPA] - Ipota - IPA - Ipota - Vanuatu",
            "searchName": "IPA-Ipota-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "AUY",
            "countryCode": "VU",
            "cityCode": "AUY",
            "cityName": "Aneityum",
            "name": "[AUY] - Aneityum - AUY - Aneityum - Vanuatu",
            "searchName": "AUY-Aneityum-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "AWD",
            "countryCode": "VU",
            "cityCode": "AWD",
            "cityName": "Aniwa",
            "name": "[AWD] - Aniwa - AWD - Aniwa - Vanuatu",
            "searchName": "AWD-Aniwa-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "CCV",
            "countryCode": "VU",
            "cityCode": "CCV",
            "cityName": "Craig Cove",
            "name": "[CCV] - Craig Cove - CCV - Craig Cove - Vanuatu",
            "searchName": "CCV-Craig Cove-Craig Cove Arpt-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "DLY",
            "countryCode": "VU",
            "cityCode": "DLY",
            "cityName": "Dillons Bay",
            "name": "[DLY] - Dillons Bay - DLY - Dillons Bay - Vanuatu",
            "searchName": "DLY-Dillons Bay-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "EAE",
            "countryCode": "VU",
            "cityCode": "EAE",
            "cityName": "Emae",
            "name": "[EAE] - Emae - EAE - Emae - Vanuatu",
            "searchName": "EAE-Emae-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "EPI",
            "countryCode": "VU",
            "cityCode": "EPI",
            "cityName": "Epi",
            "name": "[EPI] - Epi - EPI - Epi - Vanuatu",
            "searchName": "EPI-Epi-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "PBJ",
            "countryCode": "VU",
            "cityCode": "PBJ",
            "cityName": "Paama",
            "name": "[PBJ] - Paama - PBJ - Paama - Vanuatu",
            "searchName": "PBJ-Paama-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "RCL",
            "countryCode": "VU",
            "cityCode": "RCL",
            "cityName": "Redcliffe",
            "name": "[RCL] - Redcliffe - RCL - Redcliffe - Vanuatu",
            "searchName": "RCL-Redcliffe-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "MWF",
            "countryCode": "VU",
            "cityCode": "MWF",
            "cityName": "Maewo",
            "name": "[MWF] - Maewo - MWF - Maewo - Vanuatu",
            "searchName": "MWF-Maewo-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "NUS",
            "countryCode": "VU",
            "cityCode": "NUS",
            "cityName": "Norsup",
            "name": "[NUS] - Norsup - NUS - Norsup - Vanuatu",
            "searchName": "NUS-Norsup-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "OLJ",
            "countryCode": "VU",
            "cityCode": "OLJ",
            "cityName": "Olpoi",
            "name": "[OLJ] - Olpoi - OLJ - Olpoi - Vanuatu",
            "searchName": "OLJ-West Cost Santo-Olpoi-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "MTV",
            "countryCode": "VU",
            "cityCode": "MTV",
            "cityName": "Mota Lava",
            "name": "[MTV] - Mota Lava - MTV - Mota Lava - Vanuatu",
            "searchName": "MTV-Mota Lava-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "VLI",
            "countryCode": "VU",
            "cityCode": "VLI",
            "cityName": "Port Vila",
            "name": "[VLI] - Bauerfield - VLI - Port Vila - Vanuatu",
            "searchName": "VLI-Port Vila-Bauerfield-Port-Vila-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "VLS",
            "countryCode": "VU",
            "cityCode": "VLS",
            "cityName": "Valesdir",
            "name": "[VLS] - Valesdir - VLS - Valesdir - Vanuatu",
            "searchName": "VLS-Valesdir-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "WLH",
            "countryCode": "VU",
            "cityCode": "WLH",
            "cityName": "Walaha",
            "name": "[WLH] - Walaha - WLH - Walaha - Vanuatu",
            "searchName": "WLH-Walaha-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "UIQ",
            "countryCode": "VU",
            "cityCode": "UIQ",
            "cityName": "Quine Hill",
            "name": "[UIQ] - Quine Hill - UIQ - Quine Hill - Vanuatu",
            "searchName": "UIQ-Quine Hill-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "ULB",
            "countryCode": "VU",
            "cityCode": "ULB",
            "cityName": "Ulei",
            "name": "[ULB] - Ulei - ULB - Ulei - Vanuatu",
            "searchName": "ULB-Ulei-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "TAH",
            "countryCode": "VU",
            "cityCode": "TAH",
            "cityName": "Tanna",
            "name": "[TAH] - Tanna - TAH - Tanna - Vanuatu",
            "searchName": "TAH-Tanna Island-Tanna-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "TGH",
            "countryCode": "VU",
            "cityCode": "TGH",
            "cityName": "Tongoa",
            "name": "[TGH] - Tongoa - TGH - Tongoa - Vanuatu",
            "searchName": "TGH-Tongoa-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "TOH",
            "countryCode": "VU",
            "cityCode": "TOH",
            "cityName": "Torres",
            "name": "[TOH] - Torres Airstrip - TOH - Torres - Vanuatu",
            "searchName": "TOH-Torres Airstrip-Torres-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "SLH",
            "countryCode": "VU",
            "cityCode": "SLH",
            "cityName": "Sola",
            "name": "[SLH] - Sola - SLH - Sola - Vanuatu",
            "searchName": "SLH-Sola-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "SON",
            "countryCode": "VU",
            "cityCode": "SON",
            "cityName": "Espiritu Santo",
            "name": "[SON] - Pekoa - SON - Espiritu Santo - Vanuatu",
            "searchName": "SON-Espirito Santo-Pekoa-Espiritu Santo-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "SSR",
            "countryCode": "VU",
            "cityCode": "SSR",
            "cityName": "Sara",
            "name": "[SSR] - Sara - SSR - Sara - Vanuatu",
            "searchName": "SSR-Sara-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "SWJ",
            "countryCode": "VU",
            "cityCode": "SWJ",
            "cityName": "South West Bay",
            "name": "[SWJ] - South West Bay - SWJ - South West Bay - Vanuatu",
            "searchName": "SWJ-South West Bay-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "ZGU",
            "countryCode": "VU",
            "cityCode": "ZGU",
            "cityName": "Gaua",
            "name": "[ZGU] - Gaua - ZGU - Gaua - Vanuatu",
            "searchName": "ZGU-Gaua-Vanuatu",
            "priority": 0
        },
        {
            "iataCode": "WLS",
            "countryCode": "WF",
            "cityCode": "WLS",
            "cityName": "Wallis Island",
            "name": "[WLS] - Wallis Island - WLS - Wallis Island - Wallis and Futuna",
            "searchName": "WLS-Wallis Island-Wallis Inseln-Wallis and Futuna-Wallis und Futuna-Wallis ve Futuna Adalari-Wallis y Futuna-Wallis-et-Futuna-Wallis e Futuna",
            "priority": 0
        },
        {
            "iataCode": "FUT",
            "countryCode": "WF",
            "cityCode": "FUT",
            "cityName": "Futuna Island",
            "name": "[FUT] - Futuna Island - FUT - Futuna Island - Wallis and Futuna",
            "searchName": "FUT-Futuna Island--Wallis and Futuna-Wallis und Futuna-Wallis ve Futuna Adalari-Wallis y Futuna-Wallis-et-Futuna-Wallis e Futuna",
            "priority": 0
        },
        {
            "iataCode": "LAV",
            "countryCode": "WS",
            "cityCode": "LAV",
            "cityName": "Lalomalava",
            "name": "[LAV] - Lalomalava - LAV - Lalomalava - Samoa",
            "searchName": "LAV-Lalomalava-Szamoa-Samoa-Bati Samoa",
            "priority": 0
        },
        {
            "iataCode": "CDO",
            "countryCode": "ZA",
            "cityCode": "CDO",
            "cityName": "Cradock",
            "name": "[CDO] - Cradock - CDO - Cradock - South Africa",
            "searchName": "CDO-Cradock-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "BIY",
            "countryCode": "ZA",
            "cityCode": "BIY",
            "cityName": "Bisho",
            "name": "[BIY] - Bisho - BIY - Bisho - South Africa",
            "searchName": "BIY-Bisho-Bisho Havalimani-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "BFN",
            "countryCode": "ZA",
            "cityCode": "BFN",
            "cityName": "Bloemfontein",
            "name": "[BFN] - Bloemfontein - BFN - Bloemfontein - South Africa",
            "searchName": "BFN-Bloemfontein-Bloemfontein Intl-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "AFD",
            "countryCode": "ZA",
            "cityCode": "AFD",
            "cityName": "Port Alfred",
            "name": "[AFD] - Port Alfred - AFD - Port Alfred - South Africa",
            "searchName": "AFD-Port Alfred-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "ADY",
            "countryCode": "ZA",
            "cityCode": "ADY",
            "cityName": "Alldays",
            "name": "[ADY] - Alldays - ADY - Alldays - South Africa",
            "searchName": "ADY-Alldays-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "AAM",
            "countryCode": "ZA",
            "cityCode": "AAM",
            "cityName": "Mala Mala",
            "name": "[AAM] - Mala Mala - AAM - Mala Mala - South Africa",
            "searchName": "AAM-Mala Mala-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "AGZ",
            "countryCode": "ZA",
            "cityCode": "AGZ",
            "cityName": "Aggeneys",
            "name": "[AGZ] - Aggeneys - AGZ - Aggeneys - South Africa",
            "searchName": "AGZ-Aggeneys-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "ALJ",
            "countryCode": "ZA",
            "cityCode": "ALJ",
            "cityName": "Alexander Bay",
            "name": "[ALJ] - Kortdoorn - ALJ - Alexander Bay - South Africa",
            "searchName": "ALJ-Kortdoorn-Alexander Bay-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "HRS",
            "countryCode": "ZA",
            "cityCode": "HRS",
            "cityName": "Harrismith",
            "name": "[HRS] - Harrismith Airport - HRS - Harrismith - South Africa",
            "searchName": "HRS-Harrismith Airport-Harrismith Havalimani-Harrismith-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "HDS",
            "countryCode": "ZA",
            "cityCode": "HDS",
            "cityName": "Hoedspruit",
            "name": "[HDS] - Hoedspruit Airport - HDS - Hoedspruit - South Africa",
            "searchName": "HDS-Hoedspruit-Hoedspruit Airport-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "HLW",
            "countryCode": "ZA",
            "cityCode": "HLW",
            "cityName": "Hluhluwe",
            "name": "[HLW] - Hluhluwe - HLW - Hluhluwe - South Africa",
            "searchName": "HLW-Hluhluwe-Hluhluwe Havalimani-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "HZV",
            "countryCode": "ZA",
            "cityCode": "HZV",
            "cityName": "Hazyview",
            "name": "[HZV] - Hazyview - HZV - Hazyview - South Africa",
            "searchName": "HZV-Hazyview-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "INY",
            "countryCode": "ZA",
            "cityCode": "INY",
            "cityName": "Inyati",
            "name": "[INY] - Inyati - INY - Inyati - South Africa",
            "searchName": "INY-Inyati-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "GSS",
            "countryCode": "ZA",
            "cityCode": "GSS",
            "cityName": "Sabi Sabi",
            "name": "[GSS] - Sabi Sabi - GSS - Sabi Sabi - South Africa",
            "searchName": "GSS-Sabi Sabi-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "GRJ",
            "countryCode": "ZA",
            "cityCode": "GRJ",
            "cityName": "George",
            "name": "[GRJ] - George - GRJ - George - South Africa",
            "searchName": "GRJ-George-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "GIY",
            "countryCode": "ZA",
            "cityCode": "GIY",
            "cityName": "Giyani",
            "name": "[GIY] - Giyani - GIY - Giyani - South Africa",
            "searchName": "GIY-Giyani-Giyana-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "JOH",
            "countryCode": "ZA",
            "cityCode": "JOH",
            "cityName": "Port Saint Johns",
            "name": "[JOH] - Port Saint Johns - JOH - Port Saint Johns - South Africa",
            "searchName": "JOH-Port Saint Johns-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "JNB",
            "countryCode": "ZA",
            "cityCode": "JNB",
            "cityName": "Johannesburg",
            "name": "[JNB] - Oliver Reginald Tambo International (Jan Smuts International) - JNB - Johannesburg - South Africa",
            "searchName": "JNB-Johannesburg Internationale-Oliver Reginald Tambo International (Jan Smuts International)-OR Tambo-Johannesburg-Johannesbourg-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "HLA",
            "countryCode": "ZA",
            "cityCode": "JNB",
            "cityName": "Johannesburg",
            "name": "[HLA] - Lanseria - JNB - Johannesburg - South Africa",
            "searchName": "HLA-JNB-Lanseria-Lanseria Airport-Johannesburg-Johannesbourg-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "GCJ",
            "countryCode": "ZA",
            "cityCode": "JNB",
            "cityName": "Johannesburg",
            "name": "[GCJ] - Grand Central - JNB - Johannesburg - South Africa",
            "searchName": "GCJ-JNB-Grand Central-Grand Central Havalimani-Johannesburg-Johannesbourg-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "QRA",
            "countryCode": "ZA",
            "cityCode": "JNB",
            "cityName": "Johannesburg",
            "name": "[QRA] - Rand/Germiston - JNB - Johannesburg - South Africa",
            "searchName": "QRA-JNB-Rand/Germiston-Randgermiston Havalimani-Johannesburg-Johannesbourg-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "KOF",
            "countryCode": "ZA",
            "cityCode": "KOF",
            "cityName": "Komatipoort",
            "name": "[KOF] - Komatipoort - KOF - Komatipoort - South Africa",
            "searchName": "KOF-Komatipoort-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "KLZ",
            "countryCode": "ZA",
            "cityCode": "KLZ",
            "cityName": "Kleinzee",
            "name": "[KLZ] - Kleinzee - KLZ - Kleinzee - South Africa",
            "searchName": "KLZ-Kleinzee-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "KMH",
            "countryCode": "ZA",
            "cityCode": "KMH",
            "cityName": "Kuruman",
            "name": "[KMH] - Kuruman - KMH - Kuruman - South Africa",
            "searchName": "KMH-Kuruman-Kuruman Havalimani-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "KHO",
            "countryCode": "ZA",
            "cityCode": "KHO",
            "cityName": "Khoka Moya",
            "name": "[KHO] - Khoka Moya - KHO - Khoka Moya - South Africa",
            "searchName": "KHO-Khoka Moya-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "KIG",
            "countryCode": "ZA",
            "cityCode": "KIG",
            "cityName": "Koinghaas",
            "name": "[KIG] - Koinghaas - KIG - Koinghaas - South Africa",
            "searchName": "KIG-Koinghaas-Koingnaas-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "KIM",
            "countryCode": "ZA",
            "cityCode": "KIM",
            "cityName": "Kimberley",
            "name": "[KIM] - Kimberley - KIM - Kimberley - South Africa",
            "searchName": "KIM-Kimberley-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "LDZ",
            "countryCode": "ZA",
            "cityCode": "LDZ",
            "cityName": "Londolozi",
            "name": "[LDZ] - Londolozi - LDZ - Londolozi - South Africa",
            "searchName": "LDZ-Londolozi-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "LCD",
            "countryCode": "ZA",
            "cityCode": "LCD",
            "cityName": "Louis Trichardt",
            "name": "[LCD] - Louis Trichardt - LCD - Louis Trichardt - South Africa",
            "searchName": "LCD-Louis Trichardt-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "LAY",
            "countryCode": "ZA",
            "cityCode": "LAY",
            "cityName": "Ladysmith",
            "name": "[LAY] - Ladysmith - LAY - Ladysmith - South Africa",
            "searchName": "LAY-Ladysmith-Ladysmith Havalimani-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "KXE",
            "countryCode": "ZA",
            "cityCode": "KXE",
            "cityName": "Klerksdorp",
            "name": "[KXE] - Klerksdorp - KXE - Klerksdorp - South Africa",
            "searchName": "KXE-Klerksdorp-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "LMR",
            "countryCode": "ZA",
            "cityCode": "LMR",
            "cityName": "Lime Acres",
            "name": "[LMR] - Lime Acres - LMR - Lime Acres - South Africa",
            "searchName": "LMR-Lime Acres-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "LLE",
            "countryCode": "ZA",
            "cityCode": "LLE",
            "cityName": "Malelane",
            "name": "[LLE] - Malelane - LLE - Malelane - South Africa",
            "searchName": "LLE-Malelane-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "LTA",
            "countryCode": "ZA",
            "cityCode": "LTA",
            "cityName": "Tzaneen",
            "name": "[LTA] - Letaba - LTA - Tzaneen - South Africa",
            "searchName": "LTA-Letaba-Letaba Havalimani-Tzaneen-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "SZK",
            "countryCode": "ZA",
            "cityCode": "SZK",
            "cityName": "Skukuza",
            "name": "[SZK] - Skukuza - SZK - Skukuza - South Africa",
            "searchName": "SZK-Skukuza-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "TCU",
            "countryCode": "ZA",
            "cityCode": "TCU",
            "cityName": "Thaba Nchu",
            "name": "[TCU] - Thaba Nchu - TCU - Thaba Nchu - South Africa",
            "searchName": "TCU-Thaba Nchu-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "TDT",
            "countryCode": "ZA",
            "cityCode": "TDT",
            "cityName": "Timbavati",
            "name": "[TDT] - Tanda Tula - TDT - Timbavati - South Africa",
            "searchName": "TDT-Tanda Tula-Timbavati-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "THY",
            "countryCode": "ZA",
            "cityCode": "THY",
            "cityName": "Thohoyandou",
            "name": "[THY] - Thohoyandou - THY - Thohoyandou - South Africa",
            "searchName": "THY-Thohoyandou-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "TSD",
            "countryCode": "ZA",
            "cityCode": "TSD",
            "cityName": "Tshipise",
            "name": "[TSD] - Tshipise - TSD - Tshipise - South Africa",
            "searchName": "TSD-Tshipise-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "SDB",
            "countryCode": "ZA",
            "cityCode": "SDB",
            "cityName": "Saldanha Bay",
            "name": "[SDB] - Langebaanweg - SDB - Saldanha Bay - South Africa",
            "searchName": "SDB-Langebaanweg-Langebaanweg Havalimani-Saldanha Bay-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "SBU",
            "countryCode": "ZA",
            "cityCode": "SBU",
            "cityName": "Springbok",
            "name": "[SBU] - Springbok - SBU - Springbok - South Africa",
            "searchName": "SBU-Springbok-Springbok Havalimani-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "SIS",
            "countryCode": "ZA",
            "cityCode": "SIS",
            "cityName": "Sishen",
            "name": "[SIS] - Sishen - SIS - Sishen - South Africa",
            "searchName": "SIS-Sishen-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "ULD",
            "countryCode": "ZA",
            "cityCode": "ULD",
            "cityName": "Ulundi",
            "name": "[ULD] - Ulundi - ULD - Ulundi - South Africa",
            "searchName": "ULD-Ulundi-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "ULX",
            "countryCode": "ZA",
            "cityCode": "ULX",
            "cityName": "Ulusaba",
            "name": "[ULX] - Ulusaba - ULX - Ulusaba - South Africa",
            "searchName": "ULX-Ulusaba-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "UTN",
            "countryCode": "ZA",
            "cityCode": "UTN",
            "cityName": "Upington",
            "name": "[UTN] - Upington - UTN - Upington - South Africa",
            "searchName": "UTN-Upington-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "UTT",
            "countryCode": "ZA",
            "cityCode": "UTT",
            "cityName": "Umtata",
            "name": "[UTT] - Umtata - UTT - Umtata - South Africa",
            "searchName": "UTT-Umtata-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "UTW",
            "countryCode": "ZA",
            "cityCode": "UTW",
            "cityName": "Queenstown",
            "name": "[UTW] - Queenstown - UTW - Queenstown - South Africa",
            "searchName": "UTW-Queenstown-Queenstown Havalimani-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "VYD",
            "countryCode": "ZA",
            "cityCode": "VYD",
            "cityName": "Vryheid",
            "name": "[VYD] - Vryheid - VYD - Vryheid - South Africa",
            "searchName": "VYD-Vryheid-Vryheid Havalimani-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "VRE",
            "countryCode": "ZA",
            "cityCode": "VRE",
            "cityName": "Vredendal",
            "name": "[VRE] - Vredendal - VRE - Vredendal - South Africa",
            "searchName": "VRE-Vredendal-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "VRU",
            "countryCode": "ZA",
            "cityCode": "VRU",
            "cityName": "Vryburg",
            "name": "[VRU] - Vryburg - VRU - Vryburg - South Africa",
            "searchName": "VRU-Vryburg-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "WEL",
            "countryCode": "ZA",
            "cityCode": "WEL",
            "cityName": "Welkom",
            "name": "[WEL] - Welkom - WEL - Welkom - South Africa",
            "searchName": "WEL-Welkom-Welkom Havalimani-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "RVO",
            "countryCode": "ZA",
            "cityCode": "RVO",
            "cityName": "Reivilo",
            "name": "[RVO] - Reivilo - RVO - Reivilo - South Africa",
            "searchName": "RVO-Reivilo-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "ROD",
            "countryCode": "ZA",
            "cityCode": "ROD",
            "cityName": "Robertson",
            "name": "[ROD] - Robertson - ROD - Robertson - South Africa",
            "searchName": "ROD-Robertson-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "RCB",
            "countryCode": "ZA",
            "cityCode": "RCB",
            "cityName": "Richards Bay",
            "name": "[RCB] - Richards Bay - RCB - Richards Bay - South Africa",
            "searchName": "RCB-Richards Bay-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "FGI",
            "countryCode": "WS",
            "cityCode": "APW",
            "cityName": "Apia",
            "name": "[FGI] - Fagali I - APW - Apia - Samoa",
            "searchName": "FGI-APW-Fagali I-Apia Fagali I-Fagali’i Airport-Apia-Szamoa-Samoa-Bati Samoa",
            "priority": 0
        },
        {
            "iataCode": "AAU",
            "countryCode": "WS",
            "cityCode": "AAU",
            "cityName": "Asau",
            "name": "[AAU] - Asau - AAU - Asau - Samoa",
            "searchName": "AAU-Asau-Szamoa-Samoa-Bati Samoa",
            "priority": 0
        },
        {
            "iataCode": "MXS",
            "countryCode": "WS",
            "cityCode": "MXS",
            "cityName": "Maota Savaii Island",
            "name": "[MXS] - Maota Savaii Island - MXS - Maota Savaii Island - Samoa",
            "searchName": "MXS-Maota Savaii Island-Maota Savall Island-Maota Savaii Is-Szamoa-Samoa-Bati Samoa",
            "priority": 0
        },
        {
            "iataCode": "MYN",
            "countryCode": "YE",
            "cityCode": "MYN",
            "cityName": "Mareb",
            "name": "[MYN] - Mareb - MYN - Mareb - Yemen",
            "searchName": "MYN-Mareb-Marib-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "MFY",
            "countryCode": "YE",
            "cityCode": "MFY",
            "cityName": "Mayfa ah",
            "name": "[MFY] - Mayfaah - MFY - Mayfa ah - Yemen",
            "searchName": "MFY-Mayfa ah-Mayfaah-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "SAH",
            "countryCode": "YE",
            "cityCode": "SAH",
            "cityName": "Sanaa",
            "name": "[SAH] - El Rahaba Airport (Sanaa Intenational) - SAH - Sanaa - Yemen",
            "searchName": "SAH-Sanaa-El Rahaba Airport (Sanaa Intenational)-Sana a International-Sana a-San-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "RXA",
            "countryCode": "YE",
            "cityCode": "RXA",
            "cityName": "Raudha",
            "name": "[RXA] - Raudha - RXA - Raudha - Yemen",
            "searchName": "RXA-Raudha-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "RIY",
            "countryCode": "YE",
            "cityCode": "RIY",
            "cityName": "Riyan Mukalla",
            "name": "[RIY] - Riyan Mukalla - RIY - Riyan Mukalla - Yemen",
            "searchName": "RIY-Riyan-Riyan Mukalla-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "WDA",
            "countryCode": "YE",
            "cityCode": "WDA",
            "cityName": "Wadi Ain",
            "name": "[WDA] - Wadi Ain - WDA - Wadi Ain - Yemen",
            "searchName": "WDA-Wadi Ain-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "UKR",
            "countryCode": "YE",
            "cityCode": "UKR",
            "cityName": "Mukeiras",
            "name": "[UKR] - Mukeiras - UKR - Mukeiras - Yemen",
            "searchName": "UKR-Mukeiras-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "SCT",
            "countryCode": "YE",
            "cityCode": "SCT",
            "cityName": "Socotra",
            "name": "[SCT] - Socotra - SCT - Socotra - Yemen",
            "searchName": "SCT-Socotra-Socotra\u00A0 Havalimani-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "TAI",
            "countryCode": "YE",
            "cityCode": "TAI",
            "cityName": "Taiz",
            "name": "[TAI] - Al Janad - TAI - Taiz - Yemen",
            "searchName": "TAI-Al Janad-Taiz-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "SYE",
            "countryCode": "YE",
            "cityCode": "SYE",
            "cityName": "Sadah",
            "name": "[SYE] - Sadah - SYE - Sadah - Yemen",
            "searchName": "SYE-Sadah-Saadah Airport-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "AAY",
            "countryCode": "YE",
            "cityCode": "AAY",
            "cityName": "Al Ghaydah",
            "name": "[AAY] - Al Ghaydah - AAY - Al Ghaydah - Yemen",
            "searchName": "AAY-Al Ghaydah-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "ADE",
            "countryCode": "YE",
            "cityCode": "ADE",
            "cityName": "Aden",
            "name": "[ADE] - International - ADE - Aden - Yemen",
            "searchName": "ADE-Aden-International-Ad-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "AXK",
            "countryCode": "YE",
            "cityCode": "AXK",
            "cityName": "Ataq",
            "name": "[AXK] - Ataq - AXK - Ataq - Yemen",
            "searchName": "AXK-Ataq-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "BHN",
            "countryCode": "YE",
            "cityCode": "BHN",
            "cityName": "Beihan",
            "name": "[BHN] - Beihan - BHN - Beihan - Yemen",
            "searchName": "BHN-Beihan-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "BUK",
            "countryCode": "YE",
            "cityCode": "BUK",
            "cityName": "Albuq",
            "name": "[BUK] - Albuq - BUK - Albuq - Yemen",
            "searchName": "BUK-Albuq-Al-Bough-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "BYD",
            "countryCode": "YE",
            "cityCode": "BYD",
            "cityName": "Beidah",
            "name": "[BYD] - Beidah - BYD - Beidah - Yemen",
            "searchName": "BYD-Beidah-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "DMR",
            "countryCode": "YE",
            "cityCode": "DMR",
            "cityName": "Dhamar",
            "name": "[DMR] - Dhamar - DMR - Dhamar - Yemen",
            "searchName": "DMR-Dhamar-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "DHL",
            "countryCode": "YE",
            "cityCode": "DHL",
            "cityName": "Dhala",
            "name": "[DHL] - Dhala - DHL - Dhala - Yemen",
            "searchName": "DHL-Dhala-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "EAB",
            "countryCode": "YE",
            "cityCode": "EAB",
            "cityName": "Abbse",
            "name": "[EAB] - Abbse - EAB - Abbse - Yemen",
            "searchName": "EAB-Abbse-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "DAH",
            "countryCode": "YE",
            "cityCode": "DAH",
            "cityName": "Dathina",
            "name": "[DAH] - Dathina - DAH - Dathina - Yemen",
            "searchName": "DAH-Dathina-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "LDR",
            "countryCode": "YE",
            "cityCode": "LDR",
            "cityName": "Lodar",
            "name": "[LDR] - Lodar - LDR - Lodar - Yemen",
            "searchName": "LDR-Lodar-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "KAM",
            "countryCode": "YE",
            "cityCode": "KAM",
            "cityName": "Kamaran Island",
            "name": "[KAM] - Kamaran Island - KAM - Kamaran Island - Yemen",
            "searchName": "KAM-Kamaran Island-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "GXF",
            "countryCode": "YE",
            "cityCode": "GXF",
            "cityName": "Seiyun",
            "name": "[GXF] - Seiyun - GXF - Seiyun - Yemen",
            "searchName": "GXF-Seiyun-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "IHN",
            "countryCode": "YE",
            "cityCode": "IHN",
            "cityName": "Qishn",
            "name": "[IHN] - Qishn - IHN - Qishn - Yemen",
            "searchName": "IHN-Qishn-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "HOD",
            "countryCode": "YE",
            "cityCode": "HOD",
            "cityName": "Hodeidah",
            "name": "[HOD] - Hodeidah Airport - HOD - Hodeidah - Yemen",
            "searchName": "HOD-Hodeida-Hodeidah Airport-Hodeidah Arpt-Hodeidah-Jemen-Yemen-Yémen",
            "priority": 0
        },
        {
            "iataCode": "DZA",
            "countryCode": "YT",
            "cityCode": "DZA",
            "cityName": "Dzaoudzi",
            "name": "[DZA] - Dzaoudzi - DZA - Dzaoudzi - Mayotte",
            "searchName": "DZA-Dzaoudzi-Mayotte",
            "priority": 0
        },
        {
            "iataCode": "DUR",
            "countryCode": "ZA",
            "cityCode": "DUR",
            "cityName": "Durban",
            "name": "[DUR] - Durban International - DUR - Durban - South Africa",
            "searchName": "DUR-Durban-Durban International-King Shaka Intl-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "VIR",
            "countryCode": "ZA",
            "cityCode": "DUR",
            "cityName": "Durban",
            "name": "[VIR] - Virginia - DUR - Durban - South Africa",
            "searchName": "VIR-DUR-Virginia-Virginia Havalimani-Durban-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "DUK",
            "countryCode": "ZA",
            "cityCode": "DUK",
            "cityName": "Dukuduk",
            "name": "[DUK] - Dukuduk - DUK - Dukuduk - South Africa",
            "searchName": "DUK-Dukuduk-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "FCB",
            "countryCode": "ZA",
            "cityCode": "FCB",
            "cityName": "Ficksburg",
            "name": "[FCB] - Ficksburg Sentra Oes - FCB - Ficksburg - South Africa",
            "searchName": "FCB-Sentra Oes-Ficksburg Sentra Oes-Ficksburg Sentra Oes Havalimani-Ficksburg-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "EMG",
            "countryCode": "ZA",
            "cityCode": "EMG",
            "cityName": "Empangeni",
            "name": "[EMG] - Empangeni - EMG - Empangeni - South Africa",
            "searchName": "EMG-Empangeni-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "ELS",
            "countryCode": "ZA",
            "cityCode": "ELS",
            "cityName": "East London",
            "name": "[ELS] - East London - ELS - East London - South Africa",
            "searchName": "ELS-East London-East london-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "ELL",
            "countryCode": "ZA",
            "cityCode": "ELL",
            "cityName": "Ellisras",
            "name": "[ELL] - Ellisras - ELL - Ellisras - South Africa",
            "searchName": "ELL-Ellisras-Lephalale\\/ ehemals Ellisras-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "PZL",
            "countryCode": "ZA",
            "cityCode": "PZL",
            "cityName": "Phinda",
            "name": "[PZL] - Zulu Inyala - PZL - Phinda - South Africa",
            "searchName": "PZL-Zulu Inyala-Phinda-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "VDT",
            "countryCode": "UY",
            "cityCode": "MVD",
            "cityName": "Montevideo",
            "name": "[VDT] - Montevideo Bus Station - MVD - Montevideo - Uruguay",
            "searchName": "VDT-MVD-Montevideo Bus Station-Montevideo-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "MLZ",
            "countryCode": "UY",
            "cityCode": "MLZ",
            "cityName": "Melo",
            "name": "[MLZ] - Cerro Largo - MLZ - Melo - Uruguay",
            "searchName": "MLZ-Cerro Largo-Melo Havalimani-Melo-Uruguai-Uruguay-Urugwaj-Urugvaj-Urugvaja",
            "priority": 0
        },
        {
            "iataCode": "NCU",
            "countryCode": "UZ",
            "cityCode": "NCU",
            "cityName": "Nukus",
            "name": "[NCU] - Nukus - NCU - Nukus - Uzbekistan",
            "searchName": "NCU-Nukus-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "NMA",
            "countryCode": "UZ",
            "cityCode": "NMA",
            "cityName": "Namangan",
            "name": "[NMA] - Namangan - NMA - Namangan - Uzbekistan",
            "searchName": "NMA-Namangan-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "NVI",
            "countryCode": "UZ",
            "cityCode": "NVI",
            "cityName": "Navoi",
            "name": "[NVI] - Navoi International Airport - NVI - Navoi - Uzbekistan",
            "searchName": "NVI-Navoiy-Navoi International Airport-Navoi-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "TMJ",
            "countryCode": "UZ",
            "cityCode": "TMJ",
            "cityName": "Termez",
            "name": "[TMJ] - Termez - TMJ - Termez - Uzbekistan",
            "searchName": "TMJ-Termez-Termiz-Ouzbékistan-Usbekistan-Uzbekistan-Uzbekistán-Üzbegisztán-Uzbekistanas-Uzbekistana-Özbekistan",
            "priority": 0
        },
        {
            "iataCode": "PRY",
            "countryCode": "ZA",
            "cityCode": "PRY",
            "cityName": "Pretoria",
            "name": "[PRY] - Wonderboom Airport. - PRY - Pretoria - South Africa",
            "searchName": "PRY-Wonderboom-Wonderboom Airport.-tüm havaalanlari-Pretoria-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "WKF",
            "countryCode": "ZA",
            "cityCode": "PRY",
            "cityName": "Pretoria",
            "name": "[WKF] - Waterkloof AFB - PRY - Pretoria - South Africa",
            "searchName": "WKF-PRY-Waterkloof AFB-Waterkloof Fawk Havalimani-Pretoria-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "PBZ",
            "countryCode": "ZA",
            "cityCode": "PBZ",
            "cityName": "Plettenberg Bay",
            "name": "[PBZ] - Plettenberg Bay - PBZ - Plettenberg Bay - South Africa",
            "searchName": "PBZ-Plettenberg Bay-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "PCF",
            "countryCode": "ZA",
            "cityCode": "PCF",
            "cityName": "Potchefstroom",
            "name": "[PCF] - Potchefstroom - PCF - Potchefstroom - South Africa",
            "searchName": "PCF-Potchefstroom-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "OVG",
            "countryCode": "ZA",
            "cityCode": "OVG",
            "cityName": "Overberg",
            "name": "[OVG] - Overberg (FAOB) - OVG - Overberg - South Africa",
            "searchName": "OVG-Overberg (FAOB)-Overberg-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "OUH",
            "countryCode": "ZA",
            "cityCode": "OUH",
            "cityName": "Oudtshoorn",
            "name": "[OUH] - Oudtshoorn - OUH - Oudtshoorn - South Africa",
            "searchName": "OUH-Oudtshoorn-Oudtshoorn Havalimani-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "PRK",
            "countryCode": "ZA",
            "cityCode": "PRK",
            "cityName": "Prieska",
            "name": "[PRK] - Prieska - PRK - Prieska - South Africa",
            "searchName": "PRK-Prieska-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "PLZ",
            "countryCode": "ZA",
            "cityCode": "PLZ",
            "cityName": "Port Elizabeth",
            "name": "[PLZ] - Port Elizabeth - PLZ - Port Elizabeth - South Africa",
            "searchName": "PLZ-Port Elizabeth Südafrika-Port Elizabeth-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "PHW",
            "countryCode": "ZA",
            "cityCode": "PHW",
            "cityName": "Phalaborwa",
            "name": "[PHW] - Phalaborwa - PHW - Phalaborwa - South Africa",
            "searchName": "PHW-Phalaborwa-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "MGH",
            "countryCode": "ZA",
            "cityCode": "MGH",
            "cityName": "Margate",
            "name": "[MGH] - Margate - MGH - Margate - South Africa",
            "searchName": "MGH-Margate-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "MBD",
            "countryCode": "ZA",
            "cityCode": "MBD",
            "cityName": "Mmabatho",
            "name": "[MBD] - Mafikeng International Airport - MBD - Mmabatho - South Africa",
            "searchName": "MBD-Mafikeng International Airport-Mmabatho Uluslararasi Havalimani-Mahikeng\\/ Mmabatho-Mmabatho-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "MBM",
            "countryCode": "ZA",
            "cityCode": "MBM",
            "cityName": "Mkambati",
            "name": "[MBM] - Mkambati - MBM - Mkambati - South Africa",
            "searchName": "MBM-Mkambati-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "MEZ",
            "countryCode": "ZA",
            "cityCode": "MEZ",
            "cityName": "Messina",
            "name": "[MEZ] - Messina - MEZ - Messina - South Africa",
            "searchName": "MEZ-Messina-Messina Havalimani-Musina\\/ Messina-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "MZF",
            "countryCode": "ZA",
            "cityCode": "MZF",
            "cityName": "Mzamba(Wild Coast Sun)",
            "name": "[MZF] - Mzamba(Wild Coast Sun) - MZF - Mzamba(Wild Coast Sun) - South Africa",
            "searchName": "MZF-Wild Coast Sun-Mzamba(Wild Coast Sun)-Mzamba-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "MZQ",
            "countryCode": "ZA",
            "cityCode": "MZQ",
            "cityName": "Mkuze",
            "name": "[MZQ] - Mkuze - MZQ - Mkuze - South Africa",
            "searchName": "MZQ-Mkuze-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "MZY",
            "countryCode": "ZA",
            "cityCode": "MZY",
            "cityName": "Mossel Bay",
            "name": "[MZY] - Mossel Bay - MZY - Mossel Bay - South Africa",
            "searchName": "MZY-Mossel Bay-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "MWR",
            "countryCode": "ZA",
            "cityCode": "MWR",
            "cityName": "Motswari",
            "name": "[MWR] - Motswari Airfield - MWR - Motswari - South Africa",
            "searchName": "MWR-Motswari Airfield-Motswari-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "NLP",
            "countryCode": "ZA",
            "cityCode": "NLP",
            "cityName": "Nelspruit",
            "name": "[NLP] - Nelspruit Airport - NLP - Nelspruit - South Africa",
            "searchName": "NLP-Nelspruit Airport-Nelspruit-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "MQP",
            "countryCode": "ZA",
            "cityCode": "NLP",
            "cityName": "Nelspruit",
            "name": "[MQP] - Kruger Mpumalanga International Airport - NLP - Nelspruit - South Africa",
            "searchName": "MQP-NLP-Nelspruit-Kruger Mpumalanga International Airport-Kruger Mpumalanga-International Airport-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "NGL",
            "countryCode": "ZA",
            "cityCode": "NGL",
            "cityName": "Ngala",
            "name": "[NGL] - Ngala Airfield - NGL - Ngala - South Africa",
            "searchName": "NGL-Ngala Airfield-Ngala-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "NCS",
            "countryCode": "ZA",
            "cityCode": "NCS",
            "cityName": "Newcastle",
            "name": "[NCS] - Newcastle - NCS - Newcastle - South Africa",
            "searchName": "NCS-Newcastle-Newcastle Uluslararasi Havalimani-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "NTY",
            "countryCode": "ZA",
            "cityCode": "NTY",
            "cityName": "Sun City",
            "name": "[NTY] - Pilanesberg - NTY - Sun City - South Africa",
            "searchName": "NTY-Pilansberg-Pilanesberg-Sun City-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "ZEC",
            "countryCode": "ZA",
            "cityCode": "ZEC",
            "cityName": "Secunda",
            "name": "[ZEC] - Secunda - ZEC - Secunda - South Africa",
            "searchName": "ZEC-Secunda-Secunda Havalimani-Etelä-Afrikka-Republika Poludniowej Afryki-Sør-Afrika-Sydafrika-Jihoafrická republika-Güney Afrika-Sud-Àfrica-Dél-Africa de Sud-Južna Afrika-Südafrika-South Africa-Afrique du Sud-Sudáfrica-Sudafrica",
            "priority": 0
        },
        {
            "iataCode": "ZGM",
            "countryCode": "ZM",
            "cityCode": "ZGM",
            "cityName": "Ngoma",
            "name": "[ZGM] - Ngoma - ZGM - Ngoma - Zambia",
            "searchName": "ZGM-Ngoma-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "ZKB",
            "countryCode": "ZM",
            "cityCode": "ZKB",
            "cityName": "Kasaba Bay",
            "name": "[ZKB] - Kasaba Bay - ZKB - Kasaba Bay - Zambia",
            "searchName": "ZKB-Kasaba Bay-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "NLA",
            "countryCode": "ZM",
            "cityCode": "NLA",
            "cityName": "Ndola",
            "name": "[NLA] - Ndola - NLA - Ndola - Zambia",
            "searchName": "NLA-N Dola-Ndola-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "MFU",
            "countryCode": "ZM",
            "cityCode": "MFU",
            "cityName": "Mfuwe",
            "name": "[MFU] - Mfuwe - MFU - Mfuwe - Zambia",
            "searchName": "MFU-Mfuwe-A-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "MMQ",
            "countryCode": "ZM",
            "cityCode": "MMQ",
            "cityName": "Mbala",
            "name": "[MMQ] - Mbala - MMQ - Mbala - Zambia",
            "searchName": "MMQ-Mbala-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "MNR",
            "countryCode": "ZM",
            "cityCode": "MNR",
            "cityName": "Mongu",
            "name": "[MNR] - Mongu - MNR - Mongu - Zambia",
            "searchName": "MNR-Mongu-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "MNS",
            "countryCode": "ZM",
            "cityCode": "MNS",
            "cityName": "Mansa",
            "name": "[MNS] - Mansa - MNS - Mansa - Zambia",
            "searchName": "MNS-Mansa-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "SLI",
            "countryCode": "ZM",
            "cityCode": "SLI",
            "cityName": "Solwezi",
            "name": "[SLI] - Solwezi - SLI - Solwezi - Zambia",
            "searchName": "SLI-Solwezi-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "SJQ",
            "countryCode": "ZM",
            "cityCode": "SJQ",
            "cityName": "Sesheke",
            "name": "[SJQ] - Sesheke - SJQ - Sesheke - Zambia",
            "searchName": "SJQ-Sesheke-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "SXG",
            "countryCode": "ZM",
            "cityCode": "SXG",
            "cityName": "Senanga",
            "name": "[SXG] - Senanga - SXG - Senanga - Zambia",
            "searchName": "SXG-Senanga-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "LUN",
            "countryCode": "ZM",
            "cityCode": "LUN",
            "cityName": "Lusaka",
            "name": "[LUN] - Lusaka - LUN - Lusaka - Zambia",
            "searchName": "LUN-Lusaka-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "LVI",
            "countryCode": "ZM",
            "cityCode": "LVI",
            "cityName": "Livingstone",
            "name": "[LVI] - Livingstone - LVI - Livingstone - Zambia",
            "searchName": "LVI-Livingstone-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "LXU",
            "countryCode": "ZM",
            "cityCode": "LXU",
            "cityName": "Lukulu",
            "name": "[LXU] - Lukulu - LXU - Lukulu - Zambia",
            "searchName": "LXU-Lukulu-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "KIW",
            "countryCode": "ZM",
            "cityCode": "KIW",
            "cityName": "Kitwe",
            "name": "[KIW] - Southdowns - KIW - Kitwe - Zambia",
            "searchName": "KIW-Southdowns-Kitwe-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "KLB",
            "countryCode": "ZM",
            "cityCode": "KLB",
            "cityName": "Kalabo",
            "name": "[KLB] - Kalabo - KLB - Kalabo - Zambia",
            "searchName": "KLB-Kalabo-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "KMZ",
            "countryCode": "ZM",
            "cityCode": "KMZ",
            "cityName": "Kaoma",
            "name": "[KMZ] - Kaoma - KMZ - Kaoma - Zambia",
            "searchName": "KMZ-Kaoma-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "KAA",
            "countryCode": "ZM",
            "cityCode": "KAA",
            "cityName": "Kasama",
            "name": "[KAA] - Kasama - KAA - Kasama - Zambia",
            "searchName": "KAA-Kasama-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "BBZ",
            "countryCode": "ZM",
            "cityCode": "BBZ",
            "cityName": "Zambezi",
            "name": "[BBZ] - Zambezi - BBZ - Zambezi - Zambia",
            "searchName": "BBZ-Zambezi-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "CIP",
            "countryCode": "ZM",
            "cityCode": "CIP",
            "cityName": "Chipata",
            "name": "[CIP] - Chipata - CIP - Chipata - Zambia",
            "searchName": "CIP-Chipata-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "CGJ",
            "countryCode": "ZM",
            "cityCode": "CGJ",
            "cityName": "Chingola",
            "name": "[CGJ] - Chingola - CGJ - Chingola - Zambia",
            "searchName": "CGJ-Chingola-Zâmbia-Zambia-Zam bia-Zambie-Sambia-Zambija-Zamb",
            "priority": 0
        },
        {
            "iataCode": "CHJ",
            "countryCode": "ZW",
            "cityCode": "CHJ",
            "cityName": "Chipinge",
            "name": "[CHJ] - Chipinge - CHJ - Chipinge - Zimbabwe",
            "searchName": "CHJ-Chipinge-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "BFO",
            "countryCode": "ZW",
            "cityCode": "BFO",
            "cityName": "Buffalo Range",
            "name": "[BFO] - Buffalo Range - BFO - Buffalo Range - Zimbabwe",
            "searchName": "BFO-Buffalo Range-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "BZH",
            "countryCode": "ZW",
            "cityCode": "BZH",
            "cityName": "Bumi Hills",
            "name": "[BZH] - Bumi Hills - BZH - Bumi Hills - Zimbabwe",
            "searchName": "BZH-Bumi Hills-Bumi Hills Havalimani-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "BUQ",
            "countryCode": "ZW",
            "cityCode": "BUQ",
            "cityName": "Bulawayo",
            "name": "[BUQ] - Bulawayo - BUQ - Bulawayo - Zimbabwe",
            "searchName": "BUQ-Bulawayo-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "KAB",
            "countryCode": "ZW",
            "cityCode": "KAB",
            "cityName": "Kariba",
            "name": "[KAB] - Kariba - KAB - Kariba - Zimbabwe",
            "searchName": "KAB-Kariba-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "GWE",
            "countryCode": "ZW",
            "cityCode": "GWE",
            "cityName": "Gweru",
            "name": "[GWE] - Gweru - GWE - Gweru - Zimbabwe",
            "searchName": "GWE-Gweru-Gweru Havalimani-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "HRE",
            "countryCode": "ZW",
            "cityCode": "HRE",
            "cityName": "Harare",
            "name": "[HRE] - Harare - HRE - Harare - Zimbabwe",
            "searchName": "HRE-Harare-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "HWN",
            "countryCode": "ZW",
            "cityCode": "HWN",
            "cityName": "Hwange Nat Park",
            "name": "[HWN] - Hwange Nat Park - HWN - Hwange Nat Park - Zimbabwe",
            "searchName": "HWN-des Nationalparks-Hwange Nat Park-Hwange-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "WKI",
            "countryCode": "ZW",
            "cityCode": "WKI",
            "cityName": "Hwange",
            "name": "[WKI] - Hwange - WKI - Hwange - Zimbabwe",
            "searchName": "WKI-Hwange-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "UTA",
            "countryCode": "ZW",
            "cityCode": "UTA",
            "cityName": "Mutare",
            "name": "[UTA] - Mutare - UTA - Mutare - Zimbabwe",
            "searchName": "UTA-Mutare-Mutare Havalimani-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "VFA",
            "countryCode": "ZW",
            "cityCode": "VFA",
            "cityName": "Victoria Falls",
            "name": "[VFA] - Victoria Falls - VFA - Victoria Falls - Zimbabwe",
            "searchName": "VFA-Victoria Falls-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "MJW",
            "countryCode": "ZW",
            "cityCode": "MJW",
            "cityName": "Mahenye",
            "name": "[MJW] - Mahenye - MJW - Mahenye - Zimbabwe",
            "searchName": "MJW-Mahenye-Mahenye Airfield Havalimani-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "MVZ",
            "countryCode": "ZW",
            "cityCode": "MVZ",
            "cityName": "Masvingo",
            "name": "[MVZ] - Masvingo - MVZ - Masvingo - Zimbabwe",
            "searchName": "MVZ-Masvingo-Ft Victoria Havalimani-Zimbabwe-Zim ba buê-Zimbábue-Zimbabue-Simbabwe-Zimbabve",
            "priority": 0
        },
        {
            "iataCode": "MBV",
            "countryCode": "PG",
            "cityCode": "MBV",
            "cityName": "Masa",
            "name": "[MBV] - Masa - MBV - Masa - Papua New Guinea",
            "searchName": "MBV-Masa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MDM",
            "countryCode": "PG",
            "cityCode": "MDM",
            "cityName": "Munduku",
            "name": "[MDM] - Munduku - MDM - Munduku - Papua New Guinea",
            "searchName": "MDM-Munduku-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MDU",
            "countryCode": "PG",
            "cityCode": "MDU",
            "cityName": "Mendi",
            "name": "[MDU] - Mendi - MDU - Mendi - Papua New Guinea",
            "searchName": "MDU-Mendi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MFO",
            "countryCode": "PG",
            "cityCode": "MFO",
            "cityName": "Manguna",
            "name": "[MFO] - Manguna - MFO - Manguna - Papua New Guinea",
            "searchName": "MFO-Manguna-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MFZ",
            "countryCode": "PG",
            "cityCode": "MFZ",
            "cityName": "Mesalia",
            "name": "[MFZ] - Mesalia - MFZ - Mesalia - Papua New Guinea",
            "searchName": "MFZ-Mesalia-Meselia-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MHY",
            "countryCode": "PG",
            "cityCode": "MHY",
            "cityName": "Morehead",
            "name": "[MHY] - Morehead - MHY - Morehead - Papua New Guinea",
            "searchName": "MHY-Morehead-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MGP",
            "countryCode": "PG",
            "cityCode": "MGP",
            "cityName": "Manga",
            "name": "[MGP] - Manga - MGP - Manga - Papua New Guinea",
            "searchName": "MGP-Manga-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MJJ",
            "countryCode": "PG",
            "cityCode": "MJJ",
            "cityName": "Moki",
            "name": "[MJJ] - Moki - MJJ - Moki - Papua New Guinea",
            "searchName": "MJJ-Moki-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MIS",
            "countryCode": "PG",
            "cityCode": "MIS",
            "cityName": "Misima Island",
            "name": "[MIS] - Misima Island - MIS - Misima Island - Papua New Guinea",
            "searchName": "MIS-Misima Island--Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MKN",
            "countryCode": "PG",
            "cityCode": "MKN",
            "cityName": "Malekolon",
            "name": "[MKN] - Malekolon - MKN - Malekolon - Papua New Guinea",
            "searchName": "MKN-Malekolon-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PPX",
            "countryCode": "PG",
            "cityCode": "PPX",
            "cityName": "Param",
            "name": "[PPX] - Param - PPX - Param - Papua New Guinea",
            "searchName": "PPX-Param-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "POM",
            "countryCode": "PG",
            "cityCode": "POM",
            "cityName": "Port Moresby",
            "name": "[POM] - Jackson Field - POM - Port Moresby - Papua New Guinea",
            "searchName": "POM-Port Moresby-Jackson Field-Jackson Fld-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PLE",
            "countryCode": "PG",
            "cityCode": "PLE",
            "cityName": "Paiela",
            "name": "[PLE] - Paiela - PLE - Paiela - Papua New Guinea",
            "searchName": "PLE-Paiela-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PMP",
            "countryCode": "PG",
            "cityCode": "PMP",
            "cityName": "Pimaga",
            "name": "[PMP] - Pimaga - PMP - Pimaga - Papua New Guinea",
            "searchName": "PMP-Pimaga-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PMN",
            "countryCode": "PG",
            "cityCode": "PMN",
            "cityName": "Pumani",
            "name": "[PMN] - Pumani - PMN - Pumani - Papua New Guinea",
            "searchName": "PMN-Pumani-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PNP",
            "countryCode": "PG",
            "cityCode": "PNP",
            "cityName": "Popondetta",
            "name": "[PNP] - Girua - PNP - Popondetta - Papua New Guinea",
            "searchName": "PNP-Popondetta-Girua-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PGN",
            "countryCode": "PG",
            "cityCode": "PGN",
            "cityName": "Pangia",
            "name": "[PGN] - Pangia - PGN - Pangia - Papua New Guinea",
            "searchName": "PGN-Pangia-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PGE",
            "countryCode": "PG",
            "cityCode": "PGE",
            "cityName": "Yegepa",
            "name": "[PGE] - Yegepa - PGE - Yegepa - Papua New Guinea",
            "searchName": "PGE-Yegepa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PGB",
            "countryCode": "PG",
            "cityCode": "PGB",
            "cityName": "Pangoa",
            "name": "[PGB] - Pangoa - PGB - Pangoa - Papua New Guinea",
            "searchName": "PGB-Pangoa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PDI",
            "countryCode": "PG",
            "cityCode": "PDI",
            "cityName": "Pindiu",
            "name": "[PDI] - Pindiu - PDI - Pindiu - Papua New Guinea",
            "searchName": "PDI-Pindiu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PAW",
            "countryCode": "PG",
            "cityCode": "PAW",
            "cityName": "Pambwa",
            "name": "[PAW] - Pambwa - PAW - Pambwa - Papua New Guinea",
            "searchName": "PAW-Pambwa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OTY",
            "countryCode": "PG",
            "cityCode": "OTY",
            "cityName": "Oria",
            "name": "[OTY] - Oria - OTY - Oria - Papua New Guinea",
            "searchName": "OTY-Oria-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OSE",
            "countryCode": "PG",
            "cityCode": "OSE",
            "cityName": "Omora",
            "name": "[OSE] - Omora - OSE - Omora - Papua New Guinea",
            "searchName": "OSE-Omora-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OSG",
            "countryCode": "PG",
            "cityCode": "OSG",
            "cityName": "Ossima",
            "name": "[OSG] - Ossima - OSG - Ossima - Papua New Guinea",
            "searchName": "OSG-Ossima-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ONB",
            "countryCode": "PG",
            "cityCode": "ONB",
            "cityName": "Ononge",
            "name": "[ONB] - Ononge - ONB - Ononge - Papua New Guinea",
            "searchName": "ONB-Ononge-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OPB",
            "countryCode": "PG",
            "cityCode": "OPB",
            "cityName": "Open Bay",
            "name": "[OPB] - Open Bay - OPB - Open Bay - Papua New Guinea",
            "searchName": "OPB-Open Bay-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OPU",
            "countryCode": "PG",
            "cityCode": "OPU",
            "cityName": "Balimo",
            "name": "[OPU] - Balimo - OPU - Balimo - Papua New Guinea",
            "searchName": "OPU-Balimo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OLQ",
            "countryCode": "PG",
            "cityCode": "OLQ",
            "cityName": "Olsobip",
            "name": "[OLQ] - Olsobip - OLQ - Olsobip - Papua New Guinea",
            "searchName": "OLQ-Olsobip-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "PUA",
            "countryCode": "PG",
            "cityCode": "PUA",
            "cityName": "Puas",
            "name": "[PUA] - Puas - PUA - Puas - Papua New Guinea",
            "searchName": "PUA-Puas-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "RAW",
            "countryCode": "PG",
            "cityCode": "RAW",
            "cityName": "Arawa",
            "name": "[RAW] - Arawa - RAW - Arawa - Papua New Guinea",
            "searchName": "RAW-Arawa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "RAX",
            "countryCode": "PG",
            "cityCode": "RAX",
            "cityName": "Oram",
            "name": "[RAX] - Oram - RAX - Oram - Papua New Guinea",
            "searchName": "RAX-Oram-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "RAA",
            "countryCode": "PG",
            "cityCode": "RAA",
            "cityName": "Rakanda",
            "name": "[RAA] - Rakanda - RAA - Rakanda - Papua New Guinea",
            "searchName": "RAA-Rakanda-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "RBP",
            "countryCode": "PG",
            "cityCode": "RBP",
            "cityName": "Rabaraba",
            "name": "[RBP] - Rabaraba - RBP - Rabaraba - Papua New Guinea",
            "searchName": "RBP-Rabaraba-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "RUU",
            "countryCode": "PG",
            "cityCode": "RUU",
            "cityName": "Ruti",
            "name": "[RUU] - Ruti - RUU - Ruti - Papua New Guinea",
            "searchName": "RUU-Ruti-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SAM",
            "countryCode": "PG",
            "cityCode": "SAM",
            "cityName": "Salamo",
            "name": "[SAM] - Salamo - SAM - Salamo - Papua New Guinea",
            "searchName": "SAM-Salamo-Salamo Havalimani-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "RMN",
            "countryCode": "PG",
            "cityCode": "RMN",
            "cityName": "Rumginae",
            "name": "[RMN] - Rumginae - RMN - Rumginae - Papua New Guinea",
            "searchName": "RMN-Rumginae-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "RKU",
            "countryCode": "PG",
            "cityCode": "RKU",
            "cityName": "Yule Island",
            "name": "[RKU] - Kairuku - RKU - Yule Island - Papua New Guinea",
            "searchName": "RKU-Kairuku-Yule Island-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "RGE",
            "countryCode": "PG",
            "cityCode": "RGE",
            "cityName": "Porgera",
            "name": "[RGE] - Porgera - RGE - Porgera - Papua New Guinea",
            "searchName": "RGE-Porgera-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TKB",
            "countryCode": "PG",
            "cityCode": "TKB",
            "cityName": "Tekadu",
            "name": "[TKB] - Tekadu - TKB - Tekadu - Papua New Guinea",
            "searchName": "TKB-Tekadu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TIZ",
            "countryCode": "PG",
            "cityCode": "TIZ",
            "cityName": "Tari",
            "name": "[TIZ] - Tari - TIZ - Tari - Papua New Guinea",
            "searchName": "TIZ-Tari-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TGL",
            "countryCode": "PG",
            "cityCode": "TGL",
            "cityName": "Tagula",
            "name": "[TGL] - Tagula - TGL - Tagula - Papua New Guinea",
            "searchName": "TGL-Tagula-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TFI",
            "countryCode": "PG",
            "cityCode": "TFI",
            "cityName": "Tufi",
            "name": "[TFI] - Tufi - TFI - Tufi - Papua New Guinea",
            "searchName": "TFI-Tufi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TFM",
            "countryCode": "PG",
            "cityCode": "TFM",
            "cityName": "Telefomin",
            "name": "[TFM] - Telefomin - TFM - Telefomin - Papua New Guinea",
            "searchName": "TFM-Telefomin-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TDS",
            "countryCode": "PG",
            "cityCode": "TDS",
            "cityName": "Sasereme",
            "name": "[TDS] - Sasereme - TDS - Sasereme - Papua New Guinea",
            "searchName": "TDS-Sasereme-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TFB",
            "countryCode": "PG",
            "cityCode": "TFB",
            "cityName": "Tifalmin",
            "name": "[TFB] - Tifalmin - TFB - Tifalmin - Papua New Guinea",
            "searchName": "TFB-Tifalmin-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TEO",
            "countryCode": "PG",
            "cityCode": "TEO",
            "cityName": "Terapo",
            "name": "[TEO] - Terapo - TEO - Terapo - Papua New Guinea",
            "searchName": "TEO-Terapo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TEP",
            "countryCode": "PG",
            "cityCode": "TEP",
            "cityName": "Teptep",
            "name": "[TEP] - Teptep - TEP - Teptep - Papua New Guinea",
            "searchName": "TEP-Teptep-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TBQ",
            "countryCode": "PG",
            "cityCode": "TBQ",
            "cityName": "Tarabo",
            "name": "[TBQ] - Tarabo - TBQ - Tarabo - Papua New Guinea",
            "searchName": "TBQ-Tarabo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TCJ",
            "countryCode": "PG",
            "cityCode": "TCJ",
            "cityName": "Torembi",
            "name": "[TCJ] - Torembi Airport - TCJ - Torembi - Papua New Guinea",
            "searchName": "TCJ-Torembi Airport-Torembi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TCK",
            "countryCode": "PG",
            "cityCode": "TCK",
            "cityName": "Tinboli",
            "name": "[TCK] - Tinboli Airport - TCK - Tinboli - Papua New Guinea",
            "searchName": "TCK-Tinboli Airport-Tinboli-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TDB",
            "countryCode": "PG",
            "cityCode": "TDB",
            "cityName": "Tetabedi",
            "name": "[TDB] - Tetabedi - TDB - Tetabedi - Papua New Guinea",
            "searchName": "TDB-Tetabedi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TBG",
            "countryCode": "PG",
            "cityCode": "TBG",
            "cityName": "Tabubil",
            "name": "[TBG] - Tabubil - TBG - Tabubil - Papua New Guinea",
            "searchName": "TBG-Tabubil-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TBE",
            "countryCode": "PG",
            "cityCode": "TBE",
            "cityName": "Timbunke",
            "name": "[TBE] - Timbunke - TBE - Timbunke - Papua New Guinea",
            "searchName": "TBE-Timbunke-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SXW",
            "countryCode": "PG",
            "cityCode": "SXW",
            "cityName": "Sauren",
            "name": "[SXW] - Sauren - SXW - Sauren - Papua New Guinea",
            "searchName": "SXW-Sauren-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SXA",
            "countryCode": "PG",
            "cityCode": "SXA",
            "cityName": "Sialum",
            "name": "[SXA] - Sialum - SXA - Sialum - Papua New Guinea",
            "searchName": "SXA-Sialum-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SXH",
            "countryCode": "PG",
            "cityCode": "SXH",
            "cityName": "Sehulea",
            "name": "[SXH] - Sehulea - SXH - Sehulea - Papua New Guinea",
            "searchName": "SXH-Sehulea-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TWY",
            "countryCode": "PG",
            "cityCode": "TWY",
            "cityName": "Tawa",
            "name": "[TWY] - Tawa - TWY - Tawa - Papua New Guinea",
            "searchName": "TWY-Tawa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TSI",
            "countryCode": "PG",
            "cityCode": "TSI",
            "cityName": "Tsili Tsili",
            "name": "[TSI] - Tsili Tsili - TSI - Tsili Tsili - Papua New Guinea",
            "searchName": "TSI-Tsili Tsili-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TSW",
            "countryCode": "PG",
            "cityCode": "TSW",
            "cityName": "Tsewi",
            "name": "[TSW] - Tsewi - TSW - Tsewi - Papua New Guinea",
            "searchName": "TSW-Tsewi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TRJ",
            "countryCode": "PG",
            "cityCode": "TRJ",
            "cityName": "Tarakbits",
            "name": "[TRJ] - Tarakbits - TRJ - Tarakbits - Papua New Guinea",
            "searchName": "TRJ-Tarakbits-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TKW",
            "countryCode": "PG",
            "cityCode": "TKW",
            "cityName": "Tekin",
            "name": "[TKW] - Tekin - TKW - Tekin - Papua New Guinea",
            "searchName": "TKW-Tekin-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TLW",
            "countryCode": "PG",
            "cityCode": "TLW",
            "cityName": "Talasea",
            "name": "[TLW] - Talasea - TLW - Talasea - Papua New Guinea",
            "searchName": "TLW-Talasea-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TLO",
            "countryCode": "PG",
            "cityCode": "TLO",
            "cityName": "Tol",
            "name": "[TLO] - Tol - TLO - Tol - Papua New Guinea",
            "searchName": "TLO-Tol-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TLP",
            "countryCode": "PG",
            "cityCode": "TLP",
            "cityName": "Tumolbil",
            "name": "[TLP] - Tumolbil - TLP - Tumolbil - Papua New Guinea",
            "searchName": "TLP-Tumolbil-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TPI",
            "countryCode": "PG",
            "cityCode": "TPI",
            "cityName": "Tapini",
            "name": "[TPI] - Tapini - TPI - Tapini - Papua New Guinea",
            "searchName": "TPI-Tapini-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TON",
            "countryCode": "PG",
            "cityCode": "TON",
            "cityName": "Tonu",
            "name": "[TON] - Tonu - TON - Tonu - Papua New Guinea",
            "searchName": "TON-Tonu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TOK",
            "countryCode": "PG",
            "cityCode": "TOK",
            "cityName": "Torokina",
            "name": "[TOK] - Torokina - TOK - Torokina - Papua New Guinea",
            "searchName": "TOK-Torokina-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SQT",
            "countryCode": "PG",
            "cityCode": "SQT",
            "cityName": "Samarai Island",
            "name": "[SQT] - China Straits Airstrip - SQT - Samarai Island - Papua New Guinea",
            "searchName": "SQT-China Straits Airstrip-Samarai Island-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SPV",
            "countryCode": "PG",
            "cityCode": "SPV",
            "cityName": "Sepik Plains",
            "name": "[SPV] - Sepik Plains - SPV - Sepik Plains - Papua New Guinea",
            "searchName": "SPV-Sepik Plains-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SPH",
            "countryCode": "PG",
            "cityCode": "SPH",
            "cityName": "Sopu",
            "name": "[SPH] - Sopu - SPH - Sopu - Papua New Guinea",
            "searchName": "SPH-Sopu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SMH",
            "countryCode": "PG",
            "cityCode": "SMH",
            "cityName": "Sapmanga",
            "name": "[SMH] - Sapmanga - SMH - Sapmanga - Papua New Guinea",
            "searchName": "SMH-Sapmanga-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SMJ",
            "countryCode": "PG",
            "cityCode": "SMJ",
            "cityName": "Sim",
            "name": "[SMJ] - Sim - SMJ - Sim - Papua New Guinea",
            "searchName": "SMJ-Sim-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SMP",
            "countryCode": "PG",
            "cityCode": "SMP",
            "cityName": "Stockholm",
            "name": "[SMP] - Stockholm - SMP - Stockholm - Papua New Guinea",
            "searchName": "SMP-Stockholm-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SWR",
            "countryCode": "PG",
            "cityCode": "SWR",
            "cityName": "Silur",
            "name": "[SWR] - Silur - SWR - Silur - Papua New Guinea",
            "searchName": "SWR-Silur-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SWG",
            "countryCode": "PG",
            "cityCode": "SWG",
            "cityName": "Satwag",
            "name": "[SWG] - Satwag - SWG - Satwag - Papua New Guinea",
            "searchName": "SWG-Satwag-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SWE",
            "countryCode": "PG",
            "cityCode": "SWE",
            "cityName": "Siwea",
            "name": "[SWE] - Siwea - SWE - Siwea - Papua New Guinea",
            "searchName": "SWE-Siwea-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SSS",
            "countryCode": "PG",
            "cityCode": "SSS",
            "cityName": "Siassi",
            "name": "[SSS] - Siassi - SSS - Siassi - Papua New Guinea",
            "searchName": "SSS-Siassi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SIZ",
            "countryCode": "PG",
            "cityCode": "SIZ",
            "cityName": "Sissano",
            "name": "[SIZ] - Sissano - SIZ - Sissano - Papua New Guinea",
            "searchName": "SIZ-Sissano-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SKC",
            "countryCode": "PG",
            "cityCode": "SKC",
            "cityName": "Suki",
            "name": "[SKC] - Suki - SKC - Suki - Papua New Guinea",
            "searchName": "SKC-Suki-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SIL",
            "countryCode": "PG",
            "cityCode": "SIL",
            "cityName": "Sila",
            "name": "[SIL] - Sila - SIL - Sila - Papua New Guinea",
            "searchName": "SIL-Sila-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SIM",
            "countryCode": "PG",
            "cityCode": "SIM",
            "cityName": "Simbai",
            "name": "[SIM] - Simbai - SIM - Simbai - Papua New Guinea",
            "searchName": "SIM-Simbai-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SGJ",
            "countryCode": "PG",
            "cityCode": "SGJ",
            "cityName": "Sagarai",
            "name": "[SGJ] - Sagarai - SGJ - Sagarai - Papua New Guinea",
            "searchName": "SGJ-Sagarai-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SGK",
            "countryCode": "PG",
            "cityCode": "SGK",
            "cityName": "Sangapi",
            "name": "[SGK] - Sangapi - SGK - Sangapi - Papua New Guinea",
            "searchName": "SGK-Sangapi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SBC",
            "countryCode": "PG",
            "cityCode": "SBC",
            "cityName": "Selbang",
            "name": "[SBC] - Selbang - SBC - Selbang - Papua New Guinea",
            "searchName": "SBC-Selbang-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SBE",
            "countryCode": "PG",
            "cityCode": "SBE",
            "cityName": "Suabi",
            "name": "[SBE] - Suabi - SBE - Suabi - Papua New Guinea",
            "searchName": "SBE-Suabi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SBV",
            "countryCode": "PG",
            "cityCode": "SBV",
            "cityName": "Sabah",
            "name": "[SBV] - Sabah - SBV - Sabah - Papua New Guinea",
            "searchName": "SBV-Sabah-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SFU",
            "countryCode": "PG",
            "cityCode": "SFU",
            "cityName": "Safia",
            "name": "[SFU] - Safia - SFU - Safia - Papua New Guinea",
            "searchName": "SFU-Safia-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SGB",
            "countryCode": "PG",
            "cityCode": "SGB",
            "cityName": "Singaua",
            "name": "[SGB] - Singaua - SGB - Singaua - Papua New Guinea",
            "searchName": "SGB-Singaua-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "SDI",
            "countryCode": "PG",
            "cityCode": "SDI",
            "cityName": "Saidor",
            "name": "[SDI] - Saidor - SDI - Saidor - Papua New Guinea",
            "searchName": "SDI-Saidor-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WUG",
            "countryCode": "PG",
            "cityCode": "WUG",
            "cityName": "Wau",
            "name": "[WUG] - Wau - WUG - Wau - Papua New Guinea",
            "searchName": "WUG-Wau-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WTT",
            "countryCode": "PG",
            "cityCode": "WTT",
            "cityName": "Wantoat",
            "name": "[WTT] - Wantoat - WTT - Wantoat - Papua New Guinea",
            "searchName": "WTT-Wantoat-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WTP",
            "countryCode": "PG",
            "cityCode": "WTP",
            "cityName": "Woitape",
            "name": "[WTP] - Woitape - WTP - Woitape - Papua New Guinea",
            "searchName": "WTP-Woitape-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WUM",
            "countryCode": "PG",
            "cityCode": "WUM",
            "cityName": "Wasum",
            "name": "[WUM] - Wasum - WUM - Wasum - Papua New Guinea",
            "searchName": "WUM-West New Britain-Wasum-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WUV",
            "countryCode": "PG",
            "cityCode": "WUV",
            "cityName": "Wuvulu Island",
            "name": "[WUV] - Wuvulu Island - WUV - Wuvulu Island - Papua New Guinea",
            "searchName": "WUV-Wuvulu Island-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WWK",
            "countryCode": "PG",
            "cityCode": "WWK",
            "cityName": "Wewak",
            "name": "[WWK] - Boram - WWK - Wewak - Papua New Guinea",
            "searchName": "WWK-Boram-Wewak-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "XBN",
            "countryCode": "PG",
            "cityCode": "XBN",
            "cityName": "Biniguni",
            "name": "[XBN] - Biniguni - XBN - Biniguni - Papua New Guinea",
            "searchName": "XBN-Biniguni-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "XYR",
            "countryCode": "PG",
            "cityCode": "XYR",
            "cityName": "Yellow River",
            "name": "[XYR] - Yellow River - XYR - Yellow River - Papua New Guinea",
            "searchName": "XYR-Yellow River-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "YEQ",
            "countryCode": "PG",
            "cityCode": "YEQ",
            "cityName": "Yenkis",
            "name": "[YEQ] - Yenkis - YEQ - Yenkis - Papua New Guinea",
            "searchName": "YEQ-Yenkis-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "UUU",
            "countryCode": "PG",
            "cityCode": "UUU",
            "cityName": "Manumu",
            "name": "[UUU] - Manumu - UUU - Manumu - Papua New Guinea",
            "searchName": "UUU-Manumu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "UVO",
            "countryCode": "PG",
            "cityCode": "UVO",
            "cityName": "Uvol",
            "name": "[UVO] - Uvol - UVO - Uvol - Papua New Guinea",
            "searchName": "UVO-Uvol-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "VAI",
            "countryCode": "PG",
            "cityCode": "VAI",
            "cityName": "Vanimo",
            "name": "[VAI] - Vanimo - VAI - Vanimo - Papua New Guinea",
            "searchName": "VAI-Vanimo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "URU",
            "countryCode": "PG",
            "cityCode": "URU",
            "cityName": "Uroubi",
            "name": "[URU] - Uroubi - URU - Uroubi - Papua New Guinea",
            "searchName": "URU-Oro Province-Uroubi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "UBI",
            "countryCode": "PG",
            "cityCode": "UBI",
            "cityName": "Buin",
            "name": "[UBI] - Buin - UBI - Buin - Papua New Guinea",
            "searchName": "UBI-Buin-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "UAE",
            "countryCode": "PG",
            "cityCode": "UAE",
            "cityName": "Mount Aue",
            "name": "[UAE] - Mount Aue - UAE - Mount Aue - Papua New Guinea",
            "searchName": "UAE-Mount Aue-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "UMC",
            "countryCode": "PG",
            "cityCode": "UMC",
            "cityName": "Umba",
            "name": "[UMC] - Umba - UMC - Umba - Papua New Guinea",
            "searchName": "UMC-Umba-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "UNG",
            "countryCode": "PG",
            "cityCode": "UNG",
            "cityName": "Kiunga",
            "name": "[UNG] - Kiunga - UNG - Kiunga - Papua New Guinea",
            "searchName": "UNG-Kiunga-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "UPR",
            "countryCode": "PG",
            "cityCode": "UPR",
            "cityName": "Upiara",
            "name": "[UPR] - Upiara - UPR - Upiara - Papua New Guinea",
            "searchName": "UPR-Upiara-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "UKU",
            "countryCode": "PG",
            "cityCode": "UKU",
            "cityName": "Nuku",
            "name": "[UKU] - Nuku - UKU - Nuku - Papua New Guinea",
            "searchName": "UKU-Nuku-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ULE",
            "countryCode": "PG",
            "cityCode": "ULE",
            "cityName": "Sule",
            "name": "[ULE] - Sule - ULE - Sule - Papua New Guinea",
            "searchName": "ULE-Sule-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WAJ",
            "countryCode": "PG",
            "cityCode": "WAJ",
            "cityName": "Wawoi Falls",
            "name": "[WAJ] - Wawoi Falls - WAJ - Wawoi Falls - Papua New Guinea",
            "searchName": "WAJ-Wawoi Falls-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WAO",
            "countryCode": "PG",
            "cityCode": "WAO",
            "cityName": "Wabo",
            "name": "[WAO] - Wabo - WAO - Wabo - Papua New Guinea",
            "searchName": "WAO-Wabo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "VIV",
            "countryCode": "PG",
            "cityCode": "VIV",
            "cityName": "Vivigani",
            "name": "[VIV] - Vivigani - VIV - Vivigani - Papua New Guinea",
            "searchName": "VIV-Vivigani-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "VMU",
            "countryCode": "PG",
            "cityCode": "VMU",
            "cityName": "Baimuru",
            "name": "[VMU] - Baimuru - VMU - Baimuru - Papua New Guinea",
            "searchName": "VMU-Baimuru-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WKN",
            "countryCode": "PG",
            "cityCode": "WKN",
            "cityName": "Wakunai",
            "name": "[WKN] - Wakunai - WKN - Wakunai - Papua New Guinea",
            "searchName": "WKN-Wakunai-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WIU",
            "countryCode": "PG",
            "cityCode": "WIU",
            "cityName": "Witu",
            "name": "[WIU] - Witu - WIU - Witu - Papua New Guinea",
            "searchName": "WIU-Witu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WBC",
            "countryCode": "PG",
            "cityCode": "WBC",
            "cityName": "Wapolu",
            "name": "[WBC] - Wapolu - WBC - Wapolu - Papua New Guinea",
            "searchName": "WBC-Wapolu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WEP",
            "countryCode": "PG",
            "cityCode": "WEP",
            "cityName": "Weam",
            "name": "[WEP] - Weam - WEP - Weam - Papua New Guinea",
            "searchName": "WEP-Weam-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WED",
            "countryCode": "PG",
            "cityCode": "WED",
            "cityName": "Wedau",
            "name": "[WED] - Wedau - WED - Wedau - Papua New Guinea",
            "searchName": "WED-Wedau-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WBM",
            "countryCode": "PG",
            "cityCode": "WBM",
            "cityName": "Wapenamanda",
            "name": "[WBM] - Wapenamanda - WBM - Wapenamanda - Papua New Guinea",
            "searchName": "WBM-Wapenamanda-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WPM",
            "countryCode": "PG",
            "cityCode": "WPM",
            "cityName": "Wipim",
            "name": "[WPM] - Wipim - WPM - Wipim - Papua New Guinea",
            "searchName": "WPM-Wipim-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WSU",
            "countryCode": "PG",
            "cityCode": "WSU",
            "cityName": "Wasu",
            "name": "[WSU] - Wasu - WSU - Wasu - Papua New Guinea",
            "searchName": "WSU-Wasu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WSA",
            "countryCode": "PG",
            "cityCode": "WSA",
            "cityName": "Wasua",
            "name": "[WSA] - Wasua - WSA - Wasua - Papua New Guinea",
            "searchName": "WSA-Wasua-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WOA",
            "countryCode": "PG",
            "cityCode": "WOA",
            "cityName": "Wonenara",
            "name": "[WOA] - Wonenara - WOA - Wonenara - Papua New Guinea",
            "searchName": "WOA-Wonenara-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "WNU",
            "countryCode": "PG",
            "cityCode": "WNU",
            "cityName": "Wanuma",
            "name": "[WNU] - Wanuma - WNU - Wanuma - Papua New Guinea",
            "searchName": "WNU-Wanuma-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ABW",
            "countryCode": "PG",
            "cityCode": "ABW",
            "cityName": "Abau",
            "name": "[ABW] - Abau - ABW - Abau - Papua New Guinea",
            "searchName": "ABW-Abau-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ABP",
            "countryCode": "PG",
            "cityCode": "ABP",
            "cityName": "Atkamba",
            "name": "[ABP] - Atkamba - ABP - Atkamba - Papua New Guinea",
            "searchName": "ABP-Atkamba-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ADC",
            "countryCode": "PG",
            "cityCode": "ADC",
            "cityName": "Andakombe",
            "name": "[ADC] - Andakombe - ADC - Andakombe - Papua New Guinea",
            "searchName": "ADC-Andakombe-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AEK",
            "countryCode": "PG",
            "cityCode": "AEK",
            "cityName": "Aseki",
            "name": "[AEK] - Aseki - AEK - Aseki - Papua New Guinea",
            "searchName": "AEK-Aseki-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AFR",
            "countryCode": "PG",
            "cityCode": "AFR",
            "cityName": "Afore",
            "name": "[AFR] - Afore - AFR - Afore - Papua New Guinea",
            "searchName": "AFR-Afore-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AGK",
            "countryCode": "PG",
            "cityCode": "AGK",
            "cityName": "Kagua",
            "name": "[AGK] - Kagua - AGK - Kagua - Papua New Guinea",
            "searchName": "AGK-Kagua-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AGL",
            "countryCode": "PG",
            "cityCode": "AGL",
            "cityName": "Wanigela",
            "name": "[AGL] - Wanigela - AGL - Wanigela - Papua New Guinea",
            "searchName": "AGL-Wanigela-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AGG",
            "countryCode": "PG",
            "cityCode": "AGG",
            "cityName": "Angoram",
            "name": "[AGG] - Angoram - AGG - Angoram - Papua New Guinea",
            "searchName": "AGG-Angoram-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AIE",
            "countryCode": "PG",
            "cityCode": "AIE",
            "cityName": "Aiome",
            "name": "[AIE] - Aiome - AIE - Aiome - Papua New Guinea",
            "searchName": "AIE-Aiome-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AIH",
            "countryCode": "PG",
            "cityCode": "AIH",
            "cityName": "Aiambak",
            "name": "[AIH] - Aiambak - AIH - Aiambak - Papua New Guinea",
            "searchName": "AIH-Aiambak-Aiambak Airport-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AKG",
            "countryCode": "PG",
            "cityCode": "AKG",
            "cityName": "Anguganak",
            "name": "[AKG] - Anguganak - AKG - Anguganak - Papua New Guinea",
            "searchName": "AKG-Anguganak-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AMF",
            "countryCode": "PG",
            "cityCode": "AMF",
            "cityName": "Ama",
            "name": "[AMF] - Ama - AMF - Ama - Papua New Guinea",
            "searchName": "AMF-Ama-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AMG",
            "countryCode": "PG",
            "cityCode": "AMG",
            "cityName": "Amboin",
            "name": "[AMG] - Amboin - AMG - Amboin - Papua New Guinea",
            "searchName": "AMG-Amboin-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AMU",
            "countryCode": "PG",
            "cityCode": "AMU",
            "cityName": "Amanab",
            "name": "[AMU] - Amanab - AMU - Amanab - Papua New Guinea",
            "searchName": "AMU-Amanab-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "APP",
            "countryCode": "PG",
            "cityCode": "APP",
            "cityName": "Asapa",
            "name": "[APP] - Asapa - APP - Asapa - Papua New Guinea",
            "searchName": "APP-Asapa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "APR",
            "countryCode": "PG",
            "cityCode": "APR",
            "cityName": "April River",
            "name": "[APR] - April River - APR - April River - Papua New Guinea",
            "searchName": "APR-April River-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AON",
            "countryCode": "PG",
            "cityCode": "AON",
            "cityName": "Arona",
            "name": "[AON] - Arona - AON - Arona - Papua New Guinea",
            "searchName": "AON-Arona-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AOB",
            "countryCode": "PG",
            "cityCode": "AOB",
            "cityName": "Annanberg",
            "name": "[AOB] - Annanberg - AOB - Annanberg - Papua New Guinea",
            "searchName": "AOB-Annanberg-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ARP",
            "countryCode": "PG",
            "cityCode": "ARP",
            "cityName": "Aragip",
            "name": "[ARP] - Aragip - ARP - Aragip - Papua New Guinea",
            "searchName": "ARP-Aragip-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ASZ",
            "countryCode": "PG",
            "cityCode": "ASZ",
            "cityName": "Asirim",
            "name": "[ASZ] - Asirim - ASZ - Asirim - Papua New Guinea",
            "searchName": "ASZ-Asirim-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AUP",
            "countryCode": "PG",
            "cityCode": "AUP",
            "cityName": "Agaun",
            "name": "[AUP] - Agaun - AUP - Agaun - Papua New Guinea",
            "searchName": "AUP-Agaun-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AUV",
            "countryCode": "PG",
            "cityCode": "AUV",
            "cityName": "Aumo",
            "name": "[AUV] - Aumo - AUV - Aumo - Papua New Guinea",
            "searchName": "AUV-Aumo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AWB",
            "countryCode": "PG",
            "cityCode": "AWB",
            "cityName": "Awaba",
            "name": "[AWB] - Awaba - AWB - Awaba - Papua New Guinea",
            "searchName": "AWB-Awaba-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ATP",
            "countryCode": "PG",
            "cityCode": "ATP",
            "cityName": "Aitape",
            "name": "[ATP] - Airstrip - ATP - Aitape - Papua New Guinea",
            "searchName": "ATP-Landebahn-Airstrip-Aitape-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "TAJ",
            "countryCode": "PG",
            "cityCode": "ATP",
            "cityName": "Aitape",
            "name": "[TAJ] - Tadji - ATP - Aitape - Papua New Guinea",
            "searchName": "TAJ-ATP-Tadji-Aitape-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ATN",
            "countryCode": "PG",
            "cityCode": "ATN",
            "cityName": "Namatanai",
            "name": "[ATN] - Namatanai - ATN - Namatanai - Papua New Guinea",
            "searchName": "ATN-Namatanai-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AUI",
            "countryCode": "PG",
            "cityCode": "AUI",
            "cityName": "Aua Island",
            "name": "[AUI] - Aua Island - AUI - Aua Island - Papua New Guinea",
            "searchName": "AUI-Aua Island-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AUJ",
            "countryCode": "PG",
            "cityCode": "AUJ",
            "cityName": "Ambunti",
            "name": "[AUJ] - Ambunti - AUJ - Ambunti - Papua New Guinea",
            "searchName": "AUJ-Ambunti-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AZB",
            "countryCode": "PG",
            "cityCode": "AZB",
            "cityName": "Amazon Bay",
            "name": "[AZB] - Amazon Bay - AZB - Amazon Bay - Papua New Guinea",
            "searchName": "AZB-Amazon Bay-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BAA",
            "countryCode": "PG",
            "cityCode": "BAA",
            "cityName": "Bialla",
            "name": "[BAA] - Bialla - BAA - Bialla - Papua New Guinea",
            "searchName": "BAA-Bialla-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "AYU",
            "countryCode": "PG",
            "cityCode": "AYU",
            "cityName": "Aiyura",
            "name": "[AYU] - Aiyura - AYU - Aiyura - Papua New Guinea",
            "searchName": "AYU-Aiyura-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BAJ",
            "countryCode": "PG",
            "cityCode": "BAJ",
            "cityName": "Bali",
            "name": "[BAJ] - Bali - BAJ - Bali - Papua New Guinea",
            "searchName": "BAJ-Bali-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BNT",
            "countryCode": "PG",
            "cityCode": "BNT",
            "cityName": "Bundi",
            "name": "[BNT] - Bundi - BNT - Bundi - Papua New Guinea",
            "searchName": "BNT-Bundi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BNM",
            "countryCode": "PG",
            "cityCode": "BNM",
            "cityName": "Bodinumu",
            "name": "[BNM] - Bodinumu - BNM - Bodinumu - Papua New Guinea",
            "searchName": "BNM-Bodinumu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BMZ",
            "countryCode": "PG",
            "cityCode": "BMZ",
            "cityName": "Bamu",
            "name": "[BMZ] - Bamu - BMZ - Bamu - Papua New Guinea",
            "searchName": "BMZ-Bamu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BOV",
            "countryCode": "PG",
            "cityCode": "BOV",
            "cityName": "Boang",
            "name": "[BOV] - Boang - BOV - Boang - Papua New Guinea",
            "searchName": "BOV-Boang-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BPB",
            "countryCode": "PG",
            "cityCode": "BPB",
            "cityName": "Boridi",
            "name": "[BPB] - Boridi - BPB - Boridi - Papua New Guinea",
            "searchName": "BPB-Boridi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BPD",
            "countryCode": "PG",
            "cityCode": "BPD",
            "cityName": "Bapi",
            "name": "[BPD] - Bapi - BPD - Bapi - Papua New Guinea",
            "searchName": "BPD-Bapi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BOT",
            "countryCode": "PG",
            "cityCode": "BOT",
            "cityName": "Boset",
            "name": "[BOT] - Boset - BOT - Boset - Papua New Guinea",
            "searchName": "BOT-Boset-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BPK",
            "countryCode": "PG",
            "cityCode": "BPK",
            "cityName": "Biangabip",
            "name": "[BPK] - Biangabip - BPK - Biangabip - Papua New Guinea",
            "searchName": "BPK-Biangabip-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BRH",
            "countryCode": "PG",
            "cityCode": "BRH",
            "cityName": "Brahman",
            "name": "[BRH] - Brahman - BRH - Brahman - Papua New Guinea",
            "searchName": "BRH-Brahman-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BRP",
            "countryCode": "PG",
            "cityCode": "BRP",
            "cityName": "Biaru",
            "name": "[BRP] - Biaru - BRP - Biaru - Papua New Guinea",
            "searchName": "BRP-Biaru-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BSP",
            "countryCode": "PG",
            "cityCode": "BSP",
            "cityName": "Bensbach",
            "name": "[BSP] - Bensbach - BSP - Bensbach - Papua New Guinea",
            "searchName": "BSP-Bensbach-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BXZ",
            "countryCode": "PG",
            "cityCode": "BXZ",
            "cityName": "Bunsil",
            "name": "[BXZ] - Bunsil - BXZ - Bunsil - Papua New Guinea",
            "searchName": "BXZ-Bunsil-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BWJ",
            "countryCode": "PG",
            "cityCode": "BWJ",
            "cityName": "Bawan",
            "name": "[BWJ] - Bawan - BWJ - Bawan - Papua New Guinea",
            "searchName": "BWJ-Bawan-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BWP",
            "countryCode": "PG",
            "cityCode": "BWP",
            "cityName": "Bewani",
            "name": "[BWP] - Bewani - BWP - Bewani - Papua New Guinea",
            "searchName": "BWP-Bewani-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BUL",
            "countryCode": "PG",
            "cityCode": "BUL",
            "cityName": "Bulolo",
            "name": "[BUL] - Bulolo - BUL - Bulolo - Papua New Guinea",
            "searchName": "BUL-Bulolo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BUA",
            "countryCode": "PG",
            "cityCode": "BUA",
            "cityName": "Buka",
            "name": "[BUA] - Buka - BUA - Buka - Papua New Guinea",
            "searchName": "BUA-Buka-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BMH",
            "countryCode": "PG",
            "cityCode": "BMH",
            "cityName": "Bomai",
            "name": "[BMH] - Bomai - BMH - Bomai - Papua New Guinea",
            "searchName": "BMH-Bomai-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BDZ",
            "countryCode": "PG",
            "cityCode": "BDZ",
            "cityName": "Baindoung",
            "name": "[BDZ] - Baindoung - BDZ - Baindoung - Papua New Guinea",
            "searchName": "BDZ-Baindoung-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "BCP",
            "countryCode": "PG",
            "cityCode": "BCP",
            "cityName": "Bambu",
            "name": "[BCP] - Bambu - BCP - Bambu - Papua New Guinea",
            "searchName": "BCP-Bambu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "FAQ",
            "countryCode": "PG",
            "cityCode": "FAQ",
            "cityName": "Freida River",
            "name": "[FAQ] - Freida River - FAQ - Freida River - Papua New Guinea",
            "searchName": "FAQ-Freida River-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ESA",
            "countryCode": "PG",
            "cityCode": "ESA",
            "cityName": "Esa Ala",
            "name": "[ESA] - EsaAla - ESA - Esa Ala - Papua New Guinea",
            "searchName": "ESA-Esa Ala-EsaAla-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ERU",
            "countryCode": "PG",
            "cityCode": "ERU",
            "cityName": "Erume",
            "name": "[ERU] - Erume - ERU - Erume - Papua New Guinea",
            "searchName": "ERU-Erume-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ERE",
            "countryCode": "PG",
            "cityCode": "ERE",
            "cityName": "Erave",
            "name": "[ERE] - Erave - ERE - Erave - Papua New Guinea",
            "searchName": "ERE-Erave-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "EPT",
            "countryCode": "PG",
            "cityCode": "EPT",
            "cityName": "Eliptamin",
            "name": "[EPT] - Eliptamin - EPT - Eliptamin - Papua New Guinea",
            "searchName": "EPT-Eliptamin-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "EMO",
            "countryCode": "PG",
            "cityCode": "EMO",
            "cityName": "Emo",
            "name": "[EMO] - Emo - EMO - Emo - Papua New Guinea",
            "searchName": "EMO-Emo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "EMS",
            "countryCode": "PG",
            "cityCode": "EMS",
            "cityName": "Embessa",
            "name": "[EMS] - Embessa - EMS - Embessa - Papua New Guinea",
            "searchName": "EMS-Embessa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "EMI",
            "countryCode": "PG",
            "cityCode": "EMI",
            "cityName": "Emirau",
            "name": "[EMI] - Emirau - EMI - Emirau - Papua New Guinea",
            "searchName": "EMI-Emirau-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "EGA",
            "countryCode": "PG",
            "cityCode": "EGA",
            "cityName": "Engati",
            "name": "[EGA] - Engati - EGA - Engati - Papua New Guinea",
            "searchName": "EGA-Engati-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "EIA",
            "countryCode": "PG",
            "cityCode": "EIA",
            "cityName": "Eia",
            "name": "[EIA] - Popondetta - EIA - Eia - Papua New Guinea",
            "searchName": "EIA-Popondetta-Eia-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "EFG",
            "countryCode": "PG",
            "cityCode": "EFG",
            "cityName": "Efogi",
            "name": "[EFG] - Efogi - EFG - Efogi - Papua New Guinea",
            "searchName": "EFG-Efogi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DAO",
            "countryCode": "PG",
            "cityCode": "DAO",
            "cityName": "Dabo",
            "name": "[DAO] - Dabo - DAO - Dabo - Papua New Guinea",
            "searchName": "DAO-Dabo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DBP",
            "countryCode": "PG",
            "cityCode": "DBP",
            "cityName": "Debepare",
            "name": "[DBP] - Debepare - DBP - Debepare - Papua New Guinea",
            "searchName": "DBP-Debepare-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DDM",
            "countryCode": "PG",
            "cityCode": "DDM",
            "cityName": "Dodoima",
            "name": "[DDM] - Dodoima - DDM - Dodoima - Papua New Guinea",
            "searchName": "DDM-Dodoima-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DER",
            "countryCode": "PG",
            "cityCode": "DER",
            "cityName": "Derim",
            "name": "[DER] - Derim - DER - Derim - Papua New Guinea",
            "searchName": "DER-Derim-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DLB",
            "countryCode": "PG",
            "cityCode": "DLB",
            "cityName": "Dalbertis",
            "name": "[DLB] - Dalbertis - DLB - Dalbertis - Papua New Guinea",
            "searchName": "DLB-Dalbertis-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DNU",
            "countryCode": "PG",
            "cityCode": "DNU",
            "cityName": "Dinangat",
            "name": "[DNU] - Dinangat - DNU - Dinangat - Papua New Guinea",
            "searchName": "DNU-Dinangat-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DOI",
            "countryCode": "PG",
            "cityCode": "DOI",
            "cityName": "Doini",
            "name": "[DOI] - Doini - DOI - Doini - Papua New Guinea",
            "searchName": "DOI-Doini-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DOO",
            "countryCode": "PG",
            "cityCode": "DOO",
            "cityName": "Dorobisoro",
            "name": "[DOO] - Dorobisoro - DOO - Dorobisoro - Papua New Guinea",
            "searchName": "DOO-Dorobisoro-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "CGC",
            "countryCode": "PG",
            "cityCode": "CGC",
            "cityName": "Cape Gloucester",
            "name": "[CGC] - Cape Gloucester - CGC - Cape Gloucester - Papua New Guinea",
            "searchName": "CGC-Cape Gloucester-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "CVB",
            "countryCode": "PG",
            "cityCode": "CVB",
            "cityName": "Chungribu",
            "name": "[CVB] - Chungribu - CVB - Chungribu - Papua New Guinea",
            "searchName": "CVB-Chungribu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "CVL",
            "countryCode": "PG",
            "cityCode": "CVL",
            "cityName": "Cape Vogel",
            "name": "[CVL] - Cape Vogel - CVL - Cape Vogel - Papua New Guinea",
            "searchName": "CVL-Cape Vogel-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DAU",
            "countryCode": "PG",
            "cityCode": "DAU",
            "cityName": "Daru",
            "name": "[DAU] - Daru - DAU - Daru - Papua New Guinea",
            "searchName": "DAU-Daru-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "DAF",
            "countryCode": "PG",
            "cityCode": "DAF",
            "cityName": "Daup",
            "name": "[DAF] - Daup - DAF - Daup - Papua New Guinea",
            "searchName": "DAF-Daup-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "CMU",
            "countryCode": "PG",
            "cityCode": "CMU",
            "cityName": "Kundiawa",
            "name": "[CMU] - Chimbu - CMU - Kundiawa - Papua New Guinea",
            "searchName": "CMU-Chimbu-Kundiawa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "CPN",
            "countryCode": "PG",
            "cityCode": "CPN",
            "cityName": "Cape Rodney",
            "name": "[CPN] - Cape Rodney - CPN - Cape Rodney - Papua New Guinea",
            "searchName": "CPN-Cape Rodney-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "CPI",
            "countryCode": "PG",
            "cityCode": "CPI",
            "cityName": "Cape Orford",
            "name": "[CPI] - Cape Orford - CPI - Cape Orford - Papua New Guinea",
            "searchName": "CPI-Cape Orford-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "FIN",
            "countryCode": "PG",
            "cityCode": "FIN",
            "cityName": "Finschhafen",
            "name": "[FIN] - Finschhafen - FIN - Finschhafen - Papua New Guinea",
            "searchName": "FIN-Finschhafen-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "FRQ",
            "countryCode": "PG",
            "cityCode": "FRQ",
            "cityName": "Feramin",
            "name": "[FRQ] - Feramin - FRQ - Feramin - Papua New Guinea",
            "searchName": "FRQ-Feramin-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "FNE",
            "countryCode": "PG",
            "cityCode": "FNE",
            "cityName": "Fane",
            "name": "[FNE] - Fane - FNE - Fane - Papua New Guinea",
            "searchName": "FNE-Fane-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GBC",
            "countryCode": "PG",
            "cityCode": "GBC",
            "cityName": "Gasuke",
            "name": "[GBC] - Gasuke - GBC - Gasuke - Papua New Guinea",
            "searchName": "GBC-Gasuke-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GBF",
            "countryCode": "PG",
            "cityCode": "GBF",
            "cityName": "Negarbo",
            "name": "[GBF] - Negarbo - GBF - Negarbo - Papua New Guinea",
            "searchName": "GBF-Negarbo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GEW",
            "countryCode": "PG",
            "cityCode": "GEW",
            "cityName": "Gewoia",
            "name": "[GEW] - Gewoia - GEW - Gewoia - Papua New Guinea",
            "searchName": "GEW-Gewoia-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GEI",
            "countryCode": "PG",
            "cityCode": "GEI",
            "cityName": "Green Islands",
            "name": "[GEI] - Green Islands - GEI - Green Islands - Papua New Guinea",
            "searchName": "GEI-Green Islands-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GAP",
            "countryCode": "PG",
            "cityCode": "GAP",
            "cityName": "Gusap",
            "name": "[GAP] - Gusap - GAP - Gusap - Papua New Guinea",
            "searchName": "GAP-Gusap-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GAR",
            "countryCode": "PG",
            "cityCode": "GAR",
            "cityName": "Garaina",
            "name": "[GAR] - Garaina - GAR - Garaina - Papua New Guinea",
            "searchName": "GAR-Garaina-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GAZ",
            "countryCode": "PG",
            "cityCode": "GAZ",
            "cityName": "Guasopa",
            "name": "[GAZ] - Guasopa - GAZ - Guasopa - Papua New Guinea",
            "searchName": "GAZ-Guasopa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "FUM",
            "countryCode": "PG",
            "cityCode": "FUM",
            "cityName": "Fuma",
            "name": "[FUM] - Fuma - FUM - Fuma - Papua New Guinea",
            "searchName": "FUM-Fuma-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "FUB",
            "countryCode": "PG",
            "cityCode": "FUB",
            "cityName": "Fulleborn",
            "name": "[FUB] - Fulleborn - FUB - Fulleborn - Papua New Guinea",
            "searchName": "FUB-Fulleborn-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GMI",
            "countryCode": "PG",
            "cityCode": "GMI",
            "cityName": "Gasmata Island",
            "name": "[GMI] - Gasmata Island - GMI - Gasmata Island - Papua New Guinea",
            "searchName": "GMI-Gasmata Island-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GLP",
            "countryCode": "PG",
            "cityCode": "GLP",
            "cityName": "Gulgubip",
            "name": "[GLP] - Gulgubip - GLP - Gulgubip - Papua New Guinea",
            "searchName": "GLP-Gulgubip-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GOC",
            "countryCode": "PG",
            "cityCode": "GOC",
            "cityName": "Gora",
            "name": "[GOC] - Gora - GOC - Gora - Papua New Guinea",
            "searchName": "GOC-Gora-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GOE",
            "countryCode": "PG",
            "cityCode": "GOE",
            "cityName": "Gonalia",
            "name": "[GOE] - Gonalia - GOE - Gonalia - Papua New Guinea",
            "searchName": "GOE-Gonalia-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GKA",
            "countryCode": "PG",
            "cityCode": "GKA",
            "cityName": "Goroka",
            "name": "[GKA] - Goroka - GKA - Goroka - Papua New Guinea",
            "searchName": "GKA-Goroka-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GUV",
            "countryCode": "PG",
            "cityCode": "GUV",
            "cityName": "Mougulu",
            "name": "[GUV] - Mougulu - GUV - Mougulu - Papua New Guinea",
            "searchName": "GUV-Mougulu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GVI",
            "countryCode": "PG",
            "cityCode": "GVI",
            "cityName": "Green River",
            "name": "[GVI] - Green River - GVI - Green River - Papua New Guinea",
            "searchName": "GVI-Green River-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GRL",
            "countryCode": "PG",
            "cityCode": "GRL",
            "cityName": "Garasa",
            "name": "[GRL] - Garasa - GRL - Garasa - Papua New Guinea",
            "searchName": "GRL-Garasa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GRH",
            "countryCode": "PG",
            "cityCode": "GRH",
            "cityName": "Garuahi",
            "name": "[GRH] - Garuahi - GRH - Garuahi - Papua New Guinea",
            "searchName": "GRH-Garuahi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GUE",
            "countryCode": "PG",
            "cityCode": "GUE",
            "cityName": "Guriaso",
            "name": "[GUE] - Guriaso - GUE - Guriaso - Papua New Guinea",
            "searchName": "GUE-Guriaso-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GUG",
            "countryCode": "PG",
            "cityCode": "GUG",
            "cityName": "Guari",
            "name": "[GUG] - Guari - GUG - Guari - Papua New Guinea",
            "searchName": "GUG-Guari-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "GUR",
            "countryCode": "PG",
            "cityCode": "GUR",
            "cityName": "Alotau",
            "name": "[GUR] - Gurney - GUR - Alotau - Papua New Guinea",
            "searchName": "GUR-Gurney Alotau-Gurney-Alotau-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "HKN",
            "countryCode": "PG",
            "cityCode": "HKN",
            "cityName": "Hoskins",
            "name": "[HKN] - Hoskins - HKN - Hoskins - Papua New Guinea",
            "searchName": "HKN-Hoskins-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "HIT",
            "countryCode": "PG",
            "cityCode": "HIT",
            "cityName": "Hivaro",
            "name": "[HIT] - Haivaro - HIT - Hivaro - Papua New Guinea",
            "searchName": "HIT-Haivaro-Hivaro-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "HNI",
            "countryCode": "PG",
            "cityCode": "HNI",
            "cityName": "Heiweni",
            "name": "[HNI] - Heiweni - HNI - Heiweni - Papua New Guinea",
            "searchName": "HNI-Heiweni-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "HGU",
            "countryCode": "PG",
            "cityCode": "HGU",
            "cityName": "Mount Hagen",
            "name": "[HGU] - Kagamuga - HGU - Mount Hagen - Papua New Guinea",
            "searchName": "HGU-Mt Hagen-Kagamuga-Mount Hagen-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "HEO",
            "countryCode": "PG",
            "cityCode": "HEO",
            "cityName": "Haelogo",
            "name": "[HEO] - Haelogo - HEO - Haelogo - Papua New Guinea",
            "searchName": "HEO-Haelogo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "HAZ",
            "countryCode": "PG",
            "cityCode": "HAZ",
            "cityName": "Hatzfeldthaven",
            "name": "[HAZ] - Hatzfeldthaven - HAZ - Hatzfeldthaven - Papua New Guinea",
            "searchName": "HAZ-Hatzfeldthaven-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "HBD",
            "countryCode": "PG",
            "cityCode": "HBD",
            "cityName": "Habi",
            "name": "[HBD] - Habi - HBD - Habi - Papua New Guinea",
            "searchName": "HBD-Habi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "HNN",
            "countryCode": "PG",
            "cityCode": "HNN",
            "cityName": "Honinabi",
            "name": "[HNN] - Honinabi - HNN - Honinabi - Papua New Guinea",
            "searchName": "HNN-Honinabi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "HOC",
            "countryCode": "PG",
            "cityCode": "HOC",
            "cityName": "Komako",
            "name": "[HOC] - Komako - HOC - Komako - Papua New Guinea",
            "searchName": "HOC-Komako-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "HYF",
            "countryCode": "PG",
            "cityCode": "HYF",
            "cityName": "Hayfields",
            "name": "[HYF] - Hayfields - HYF - Hayfields - Papua New Guinea",
            "searchName": "HYF-Hayfields-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "HWA",
            "countryCode": "PG",
            "cityCode": "HWA",
            "cityName": "Hawabango",
            "name": "[HWA] - Hawabango - HWA - Hawabango - Papua New Guinea",
            "searchName": "HWA-Hawabango-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "IAU",
            "countryCode": "PG",
            "cityCode": "IAU",
            "cityName": "Iaura",
            "name": "[IAU] - Iaura - IAU - Iaura - Papua New Guinea",
            "searchName": "IAU-Iaura-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "IBI",
            "countryCode": "PG",
            "cityCode": "IBI",
            "cityName": "Iboki",
            "name": "[IBI] - Iboki - IBI - Iboki - Papua New Guinea",
            "searchName": "IBI-Iboki-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "IDN",
            "countryCode": "PG",
            "cityCode": "IDN",
            "cityName": "Indagen",
            "name": "[IDN] - Indagen - IDN - Indagen - Papua New Guinea",
            "searchName": "IDN-Indagen-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "IMA",
            "countryCode": "PG",
            "cityCode": "IMA",
            "cityName": "Iamalele",
            "name": "[IMA] - Iamalele - IMA - Iamalele - Papua New Guinea",
            "searchName": "IMA-Iamalele-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "IMD",
            "countryCode": "PG",
            "cityCode": "IMD",
            "cityName": "Imonda",
            "name": "[IMD] - Imonda - IMD - Imonda - Papua New Guinea",
            "searchName": "IMD-Imonda-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "IMN",
            "countryCode": "PG",
            "cityCode": "IMN",
            "cityName": "Imane",
            "name": "[IMN] - Imane - IMN - Imane - Papua New Guinea",
            "searchName": "IMN-Imane-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "IIS",
            "countryCode": "PG",
            "cityCode": "IIS",
            "cityName": "Nissan Island",
            "name": "[IIS] - Nissan Island - IIS - Nissan Island - Papua New Guinea",
            "searchName": "IIS-Nissan Island-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "IHU",
            "countryCode": "PG",
            "cityCode": "IHU",
            "cityName": "Ihu",
            "name": "[IHU] - Ihu - IHU - Ihu - Papua New Guinea",
            "searchName": "IHU-Ihu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "IOK",
            "countryCode": "PG",
            "cityCode": "IOK",
            "cityName": "Iokea",
            "name": "[IOK] - Iokea - IOK - Iokea - Papua New Guinea",
            "searchName": "IOK-Iokea-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "IOP",
            "countryCode": "PG",
            "cityCode": "IOP",
            "cityName": "Ioma",
            "name": "[IOP] - Ioma - IOP - Ioma - Papua New Guinea",
            "searchName": "IOP-Ioma-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ITK",
            "countryCode": "PG",
            "cityCode": "ITK",
            "cityName": "Itokama",
            "name": "[ITK] - Itokama - ITK - Itokama - Papua New Guinea",
            "searchName": "ITK-Itokama-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "JAQ",
            "countryCode": "PG",
            "cityCode": "JAQ",
            "cityName": "Jacquinot Bay",
            "name": "[JAQ] - Jacquinot Bay - JAQ - Jacquinot Bay - Papua New Guinea",
            "searchName": "JAQ-Jacquinot Bay-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KKD",
            "countryCode": "PG",
            "cityCode": "KKD",
            "cityName": "Kokoda",
            "name": "[KKD] - Kokoda - KKD - Kokoda - Papua New Guinea",
            "searchName": "KKD-Kokoda-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KMR",
            "countryCode": "PG",
            "cityCode": "KMR",
            "cityName": "Karimui",
            "name": "[KMR] - Karimui - KMR - Karimui - Papua New Guinea",
            "searchName": "KMR-Karimui-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KMF",
            "countryCode": "PG",
            "cityCode": "KMF",
            "cityName": "Kamina",
            "name": "[KMF] - Kamina - KMF - Kamina - Papua New Guinea",
            "searchName": "KMF-Kamina-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KMA",
            "countryCode": "PG",
            "cityCode": "KMA",
            "cityName": "Kerema",
            "name": "[KMA] - Kerema - KMA - Kerema - Papua New Guinea",
            "searchName": "KMA-Kerema-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KMB",
            "countryCode": "PG",
            "cityCode": "KMB",
            "cityName": "Koinambe",
            "name": "[KMB] - Koinambe - KMB - Koinambe - Papua New Guinea",
            "searchName": "KMB-Koinambe-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KII",
            "countryCode": "PG",
            "cityCode": "KII",
            "cityName": "Kibuli",
            "name": "[KII] - Kibuli - KII - Kibuli - Papua New Guinea",
            "searchName": "KII-Kibuli-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KIQ",
            "countryCode": "PG",
            "cityCode": "KIQ",
            "cityName": "Kira",
            "name": "[KIQ] - Kira - KIQ - Kira - Papua New Guinea",
            "searchName": "KIQ-Kira-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KIE",
            "countryCode": "PG",
            "cityCode": "KIE",
            "cityName": "Kieta",
            "name": "[KIE] - Aropa - KIE - Kieta - Papua New Guinea",
            "searchName": "KIE-Aropa-Kieta-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KGW",
            "countryCode": "PG",
            "cityCode": "KGW",
            "cityName": "Kagi",
            "name": "[KGW] - Kagi - KGW - Kagi - Papua New Guinea",
            "searchName": "KGW-Kagi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KGM",
            "countryCode": "PG",
            "cityCode": "KGM",
            "cityName": "Kungum",
            "name": "[KGM] - Kungum - KGM - Kungum - Papua New Guinea",
            "searchName": "KGM-Kungum-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KGH",
            "countryCode": "PG",
            "cityCode": "KGH",
            "cityName": "Yongai",
            "name": "[KGH] - Yongai - KGH - Yongai - Papua New Guinea",
            "searchName": "KGH-Yongai-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KGB",
            "countryCode": "PG",
            "cityCode": "KGB",
            "cityName": "Konge",
            "name": "[KGB] - Konge - KGB - Konge - Papua New Guinea",
            "searchName": "KGB-Konge-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KSP",
            "countryCode": "PG",
            "cityCode": "KSP",
            "cityName": "Kosipe",
            "name": "[KSP] - Kosipe - KSP - Kosipe - Papua New Guinea",
            "searchName": "KSP-Kosipe-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KSX",
            "countryCode": "PG",
            "cityCode": "KSX",
            "cityName": "Yasuru",
            "name": "[KSX] - Yasuru - KSX - Yasuru - Papua New Guinea",
            "searchName": "KSX-Yasuru-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KRX",
            "countryCode": "PG",
            "cityCode": "KRX",
            "cityName": "Kar Kar",
            "name": "[KRX] - Kar Kar - KRX - Kar Kar - Papua New Guinea",
            "searchName": "KRX-Kar Kar-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KRU",
            "countryCode": "PG",
            "cityCode": "KRU",
            "cityName": "Kerau",
            "name": "[KRU] - Kerau - KRU - Kerau - Papua New Guinea",
            "searchName": "KRU-Kerau-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KSB",
            "countryCode": "PG",
            "cityCode": "KSB",
            "cityName": "Kasanombe",
            "name": "[KSB] - Kasanombe - KSB - Kasanombe - Papua New Guinea",
            "searchName": "KSB-Kasanombe-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KQL",
            "countryCode": "PG",
            "cityCode": "KQL",
            "cityName": "Kol",
            "name": "[KQL] - Kol - KQL - Kol - Papua New Guinea",
            "searchName": "KQL-Kol-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KRI",
            "countryCode": "PG",
            "cityCode": "KRI",
            "cityName": "Kikori",
            "name": "[KRI] - Kikori - KRI - Kikori - Papua New Guinea",
            "searchName": "KRI-Kikori-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KRJ",
            "countryCode": "PG",
            "cityCode": "KRJ",
            "cityName": "Karawari",
            "name": "[KRJ] - Karawari - KRJ - Karawari - Papua New Guinea",
            "searchName": "KRJ-Karawari-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KNE",
            "countryCode": "PG",
            "cityCode": "KNE",
            "cityName": "Kanainj",
            "name": "[KNE] - Kanainj - KNE - Kanainj - Papua New Guinea",
            "searchName": "KNE-Kanainj-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KPE",
            "countryCode": "PG",
            "cityCode": "KPE",
            "cityName": "Yapsiei",
            "name": "[KPE] - Yapsiei - KPE - Yapsiei - Papua New Guinea",
            "searchName": "KPE-Yapsiei-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KPF",
            "countryCode": "PG",
            "cityCode": "KPF",
            "cityName": "Kondubol",
            "name": "[KPF] - Kondubol - KPF - Kondubol - Papua New Guinea",
            "searchName": "KPF-Kondubol-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KPL",
            "countryCode": "PG",
            "cityCode": "KPL",
            "cityName": "Kapal",
            "name": "[KPL] - Kapal - KPL - Kapal - Papua New Guinea",
            "searchName": "KPL-Kapal-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KPM",
            "countryCode": "PG",
            "cityCode": "KPM",
            "cityName": "Kompiam",
            "name": "[KPM] - Kompiam - KPM - Kompiam - Papua New Guinea",
            "searchName": "KPM-Kompiam-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KOR",
            "countryCode": "PG",
            "cityCode": "KOR",
            "cityName": "Kokoro",
            "name": "[KOR] - Kokoro - KOR - Kokoro - Papua New Guinea",
            "searchName": "KOR-Kokoro-Kokoro Havalimani-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KOM",
            "countryCode": "PG",
            "cityCode": "KOM",
            "cityName": "Komo-Manda",
            "name": "[KOM] - Komo-Manda - KOM - Komo-Manda - Papua New Guinea",
            "searchName": "KOM-Komo-Manda-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KPA",
            "countryCode": "PG",
            "cityCode": "KPA",
            "cityName": "Kopiago",
            "name": "[KPA] - Kopiago - KPA - Kopiago - Papua New Guinea",
            "searchName": "KPA-Kopiago-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KAF",
            "countryCode": "PG",
            "cityCode": "KAF",
            "cityName": "Karato",
            "name": "[KAF] - Karato - KAF - Karato - Papua New Guinea",
            "searchName": "KAF-Karato-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KAK",
            "countryCode": "PG",
            "cityCode": "KAK",
            "cityName": "Kar",
            "name": "[KAK] - Kar - KAK - Kar - Papua New Guinea",
            "searchName": "KAK-Kar-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KAQ",
            "countryCode": "PG",
            "cityCode": "KAQ",
            "cityName": "Kamulai",
            "name": "[KAQ] - Kamulai - KAQ - Kamulai - Papua New Guinea",
            "searchName": "KAQ-Kamulai-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KBM",
            "countryCode": "PG",
            "cityCode": "KBM",
            "cityName": "Kabwum",
            "name": "[KBM] - Kabwum - KBM - Kabwum - Papua New Guinea",
            "searchName": "KBM-Kabwum-Kabwun-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KCJ",
            "countryCode": "PG",
            "cityCode": "KCJ",
            "cityName": "Komaio",
            "name": "[KCJ] - Komaio - KCJ - Komaio - Papua New Guinea",
            "searchName": "KCJ-Komaio-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KDE",
            "countryCode": "PG",
            "cityCode": "KDE",
            "cityName": "Koroba",
            "name": "[KDE] - Koroba - KDE - Koroba - Papua New Guinea",
            "searchName": "KDE-Koroba-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KDP",
            "countryCode": "PG",
            "cityCode": "KDP",
            "cityName": "Kandep",
            "name": "[KDP] - Kandep - KDP - Kandep - Papua New Guinea",
            "searchName": "KDP-Kandep-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KDQ",
            "countryCode": "PG",
            "cityCode": "KDQ",
            "cityName": "Kamberatoro",
            "name": "[KDQ] - Kamberatoro - KDQ - Kamberatoro - Papua New Guinea",
            "searchName": "KDQ-Kamberatoro-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KDR",
            "countryCode": "PG",
            "cityCode": "KDR",
            "cityName": "Kandrian",
            "name": "[KDR] - Kandrian - KDR - Kandrian - Papua New Guinea",
            "searchName": "KDR-Kandrian-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KEX",
            "countryCode": "PG",
            "cityCode": "KEX",
            "cityName": "Kanabea",
            "name": "[KEX] - Kanabea - KEX - Kanabea - Papua New Guinea",
            "searchName": "KEX-Kanabea-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KEG",
            "countryCode": "PG",
            "cityCode": "KEG",
            "cityName": "Keglsugl",
            "name": "[KEG] - Keglsugl - KEG - Keglsugl - Papua New Guinea",
            "searchName": "KEG-Keglsugl-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "JOP",
            "countryCode": "PG",
            "cityCode": "JOP",
            "cityName": "Josephstaal",
            "name": "[JOP] - Josephstaal - JOP - Josephstaal - Papua New Guinea",
            "searchName": "JOP-Josephstaal-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KWO",
            "countryCode": "PG",
            "cityCode": "KWO",
            "cityName": "Kawito",
            "name": "[KWO] - Kawito - KWO - Kawito - Papua New Guinea",
            "searchName": "KWO-Kawito-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KVE",
            "countryCode": "PG",
            "cityCode": "KVE",
            "cityName": "Kitava",
            "name": "[KVE] - Kitava - KVE - Kitava - Papua New Guinea",
            "searchName": "KVE-Kitava-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KVG",
            "countryCode": "PG",
            "cityCode": "KVG",
            "cityName": "Kavieng",
            "name": "[KVG] - Kavieng - KVG - Kavieng - Papua New Guinea",
            "searchName": "KVG-Kavieng-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KUP",
            "countryCode": "PG",
            "cityCode": "KUP",
            "cityName": "Kupiano",
            "name": "[KUP] - Kupiano - KUP - Kupiano - Papua New Guinea",
            "searchName": "KUP-Kupiano-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KUQ",
            "countryCode": "PG",
            "cityCode": "KUQ",
            "cityName": "Kuri",
            "name": "[KUQ] - Kuri - KUQ - Kuri - Papua New Guinea",
            "searchName": "KUQ-Kuri-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KUX",
            "countryCode": "PG",
            "cityCode": "KUX",
            "cityName": "Kuyol",
            "name": "[KUX] - Kuyol - KUX - Kuyol - Papua New Guinea",
            "searchName": "KUX-Kuyol-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KUY",
            "countryCode": "PG",
            "cityCode": "KUY",
            "cityName": "Kamusi",
            "name": "[KUY] - Kamusi Airport - KUY - Kamusi - Papua New Guinea",
            "searchName": "KUY-Kamusi Airport-Kamusi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LAB",
            "countryCode": "PG",
            "cityCode": "LAB",
            "cityName": "Lablab",
            "name": "[LAB] - Lablab - LAB - Lablab - Papua New Guinea",
            "searchName": "LAB-Lablab-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LAE",
            "countryCode": "PG",
            "cityCode": "LAE",
            "cityName": "Lae",
            "name": "[LAE] - Nadzab - LAE - Lae - Papua New Guinea",
            "searchName": "LAE-Lae Papua Neu Guinea-Nadzab-Lae-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KZF",
            "countryCode": "PG",
            "cityCode": "KZF",
            "cityName": "Kaintiba",
            "name": "[KZF] - Kaintiba - KZF - Kaintiba - Papua New Guinea",
            "searchName": "KZF-Kaintiba-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "KYX",
            "countryCode": "PG",
            "cityCode": "KYX",
            "cityName": "Yalumet",
            "name": "[KYX] - Yalumet - KYX - Yalumet - Papua New Guinea",
            "searchName": "KYX-Yalumet-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LHP",
            "countryCode": "PG",
            "cityCode": "LHP",
            "cityName": "Lehu",
            "name": "[LHP] - Lehu - LHP - Lehu - Papua New Guinea",
            "searchName": "LHP-Lehu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LGN",
            "countryCode": "PG",
            "cityCode": "LGN",
            "cityName": "Linga Linga",
            "name": "[LGN] - Linga Linga - LGN - Linga Linga - Papua New Guinea",
            "searchName": "LGN-Linga Linga-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LNC",
            "countryCode": "PG",
            "cityCode": "LNC",
            "cityName": "Lengbati",
            "name": "[LNC] - Lengbati - LNC - Lengbati - Papua New Guinea",
            "searchName": "LNC-Lengbati-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LNF",
            "countryCode": "PG",
            "cityCode": "LNF",
            "cityName": "Munbil",
            "name": "[LNF] - Munbil - LNF - Munbil - Papua New Guinea",
            "searchName": "LNF-Munbil-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LNG",
            "countryCode": "PG",
            "cityCode": "LNG",
            "cityName": "Lese",
            "name": "[LNG] - Lese - LNG - Lese - Papua New Guinea",
            "searchName": "LNG-Lese-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LMY",
            "countryCode": "PG",
            "cityCode": "LMY",
            "cityName": "Lake Murray",
            "name": "[LMY] - Lake Murray - LMY - Lake Murray - Papua New Guinea",
            "searchName": "LMY-Lake Murray-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LMI",
            "countryCode": "PG",
            "cityCode": "LMI",
            "cityName": "Lumi",
            "name": "[LMI] - Lumi - LMI - Lumi - Papua New Guinea",
            "searchName": "LMI-Lumi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LMG",
            "countryCode": "PG",
            "cityCode": "LMG",
            "cityName": "Lamassa",
            "name": "[LMG] - Lamassa - LMG - Lamassa - Papua New Guinea",
            "searchName": "LMG-Lamassa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LPN",
            "countryCode": "PG",
            "cityCode": "LPN",
            "cityName": "Leron Plains",
            "name": "[LPN] - Leron Plains - LPN - Leron Plains - Papua New Guinea",
            "searchName": "LPN-Leron Plains-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LNV",
            "countryCode": "PG",
            "cityCode": "LNV",
            "cityName": "Lihir Island",
            "name": "[LNV] - Lihir Island - LNV - Lihir Island - Papua New Guinea",
            "searchName": "LNV-Londolovit-Lihir Island--Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LNM",
            "countryCode": "PG",
            "cityCode": "LNM",
            "cityName": "Langimar",
            "name": "[LNM] - Langimar - LNM - Langimar - Papua New Guinea",
            "searchName": "LNM-Langimar-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LTF",
            "countryCode": "PG",
            "cityCode": "LTF",
            "cityName": "Leitre",
            "name": "[LTF] - Leitre - LTF - Leitre - Papua New Guinea",
            "searchName": "LTF-Leitre-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LSJ",
            "countryCode": "PG",
            "cityCode": "LSJ",
            "cityName": "Long Island",
            "name": "[LSJ] - Long Island - LSJ - Long Island - Papua New Guinea",
            "searchName": "LSJ-Long Island-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LSA",
            "countryCode": "PG",
            "cityCode": "LSA",
            "cityName": "Losuia",
            "name": "[LSA] - Losuia - LSA - Losuia - Papua New Guinea",
            "searchName": "LSA-Losuia-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "LWI",
            "countryCode": "PG",
            "cityCode": "LWI",
            "cityName": "Lowai",
            "name": "[LWI] - Lowai - LWI - Lowai - Papua New Guinea",
            "searchName": "LWI-Lowai-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ZEN",
            "countryCode": "PG",
            "cityCode": "ZEN",
            "cityName": "Zenag",
            "name": "[ZEN] - Zenag - ZEN - Zenag - Papua New Guinea",
            "searchName": "ZEN-Zenag-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "YVD",
            "countryCode": "PG",
            "cityCode": "YVD",
            "cityName": "Yeva",
            "name": "[YVD] - Yeva - YVD - Yeva - Papua New Guinea",
            "searchName": "YVD-Yeva-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "ZAM",
            "countryCode": "PH",
            "cityCode": "ZAM",
            "cityName": "Zamboanga",
            "name": "[ZAM] - Zamboanga International - ZAM - Zamboanga - Philippines",
            "searchName": "ZAM-Zamboanga-Zamboanga International-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "LWA",
            "countryCode": "PH",
            "cityCode": "LWA",
            "cityName": "Lwbak",
            "name": "[LWA] - Lwbak - LWA - Lwbak - Philippines",
            "searchName": "LWA-Lwbak-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "LGP",
            "countryCode": "PH",
            "cityCode": "LGP",
            "cityName": "Legazpi",
            "name": "[LGP] - Legazpi - LGP - Legazpi - Philippines",
            "searchName": "LGP-Legaspi-Legazpi-Legazpi City-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "LBX",
            "countryCode": "PH",
            "cityCode": "LBX",
            "cityName": "Lubang",
            "name": "[LBX] - Lubang - LBX - Lubang - Philippines",
            "searchName": "LBX-Lubang-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "LAO",
            "countryCode": "PH",
            "cityCode": "LAO",
            "cityName": "Laoag",
            "name": "[LAO] - Laoag International Airport - LAO - Laoag - Philippines",
            "searchName": "LAO-Laoag-Laoag International Airport-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "JOL",
            "countryCode": "PH",
            "cityCode": "JOL",
            "cityName": "Jolo",
            "name": "[JOL] - Jolo - JOL - Jolo - Philippines",
            "searchName": "JOL-Jolo-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "RHP",
            "countryCode": "NP",
            "cityCode": "RHP",
            "cityName": "Ramechhap",
            "name": "[RHP] - Ramechhap - RHP - Ramechhap - Nepal",
            "searchName": "RHP-Ramechhap-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "RJB",
            "countryCode": "NP",
            "cityCode": "RJB",
            "cityName": "Rajbiraj",
            "name": "[RJB] - Rajbiraj - RJB - Rajbiraj - Nepal",
            "searchName": "RJB-Rajbiraj-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "RPA",
            "countryCode": "NP",
            "cityCode": "RPA",
            "cityName": "Rolpa",
            "name": "[RPA] - Rolpa - RPA - Rolpa - Nepal",
            "searchName": "RPA-Rolpa-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "RUK",
            "countryCode": "NP",
            "cityCode": "RUK",
            "cityName": "Rukumkot",
            "name": "[RUK] - Rukumkot - RUK - Rukumkot - Nepal",
            "searchName": "RUK-Rukumkot-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "RUM",
            "countryCode": "NP",
            "cityCode": "RUM",
            "cityName": "Rumjatar",
            "name": "[RUM] - Rumjatar - RUM - Rumjatar - Nepal",
            "searchName": "RUM-Rumjatar-Rumjatar Airport-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "TPJ",
            "countryCode": "NP",
            "cityCode": "TPJ",
            "cityName": "Taplejung",
            "name": "[TPJ] - Taplejung Suketar - TPJ - Taplejung - Nepal",
            "searchName": "TPJ-Taplejung-Taplejung Suketar-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "TPU",
            "countryCode": "NP",
            "cityCode": "TPU",
            "cityName": "Tikapur",
            "name": "[TPU] - Tikapur - TPU - Tikapur - Nepal",
            "searchName": "TPU-Tikapur-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "TMI",
            "countryCode": "NP",
            "cityCode": "TMI",
            "cityName": "Tumling Tar",
            "name": "[TMI] - Tumling Tar - TMI - Tumling Tar - Nepal",
            "searchName": "TMI-Tumling Tar-Tumlingtar Airport (Tumling Tar Airport)-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "SYH",
            "countryCode": "NP",
            "cityCode": "SYH",
            "cityName": "Syangboche",
            "name": "[SYH] - Syangboche - SYH - Syangboche - Nepal",
            "searchName": "SYH-Syangboche-Syanboche Airport-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "SKH",
            "countryCode": "NP",
            "cityCode": "SKH",
            "cityName": "Surkhet",
            "name": "[SKH] - Kadmandu - SKH - Surkhet - Nepal",
            "searchName": "SKH-Kadmandu-Surkhet-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "SIF",
            "countryCode": "NP",
            "cityCode": "SIF",
            "cityName": "Simara",
            "name": "[SIF] - Simara - SIF - Simara - Nepal",
            "searchName": "SIF-Simara-Simara\u00A0 Havalimani-Pipara Simara-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "SIH",
            "countryCode": "NP",
            "cityCode": "SIH",
            "cityName": "Silgadi Doti",
            "name": "[SIH] - Silgadi Doti - SIH - Silgadi Doti - Nepal",
            "searchName": "SIH-Silgadi Doti-Silgarhi Doti-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "XMG",
            "countryCode": "NP",
            "cityCode": "XMG",
            "cityName": "Mahendranagar",
            "name": "[XMG] - Mahendranagar - XMG - Mahendranagar - Nepal",
            "searchName": "XMG-Mahendranagar-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "BJH",
            "countryCode": "NP",
            "cityCode": "BJH",
            "cityName": "Bajhang",
            "name": "[BJH] - Bajhang - BJH - Bajhang - Nepal",
            "searchName": "BJH-Bajhang-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "BJU",
            "countryCode": "NP",
            "cityCode": "BJU",
            "cityName": "Bajura",
            "name": "[BJU] - Bajura Airport - BJU - Bajura - Nepal",
            "searchName": "BJU-Bajura Airport-Bajura-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "BIT",
            "countryCode": "NP",
            "cityCode": "BIT",
            "cityName": "Baitadi",
            "name": "[BIT] - Baitadi - BIT - Baitadi - Nepal",
            "searchName": "BIT-Baitadi-Baitadai Airport-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "BIR",
            "countryCode": "NP",
            "cityCode": "BIR",
            "cityName": "Biratnagar",
            "name": "[BIR] - Biratnagar - BIR - Biratnagar - Nepal",
            "searchName": "BIR-Biratnagar-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "BHP",
            "countryCode": "NP",
            "cityCode": "BHP",
            "cityName": "Bhojpur",
            "name": "[BHP] - Bhojpur - BHP - Bhojpur - Nepal",
            "searchName": "BHP-Bhojpur-Bhojpur Havalimani-Bhojpur Airport-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "BHR",
            "countryCode": "NP",
            "cityCode": "BHR",
            "cityName": "Bharatpur",
            "name": "[BHR] - Bharatpur Airport - BHR - Bharatpur - Nepal",
            "searchName": "BHR-Bharatpur-Bharatpur Airport-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "BDP",
            "countryCode": "NP",
            "cityCode": "BDP",
            "cityName": "Bhadrapur",
            "name": "[BDP] - Bhadrapur - BDP - Bhadrapur - Nepal",
            "searchName": "BDP-Bhadrapur-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "BGL",
            "countryCode": "NP",
            "cityCode": "BGL",
            "cityName": "Baglung",
            "name": "[BGL] - Baglung - BGL - Baglung - Nepal",
            "searchName": "BGL-Baglung-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "BWA",
            "countryCode": "NP",
            "cityCode": "BWA",
            "cityName": "Bhairawa",
            "name": "[BWA] - Bhairawa - BWA - Bhairawa - Nepal",
            "searchName": "BWA-Bhairawa-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "DHI",
            "countryCode": "NP",
            "cityCode": "DHI",
            "cityName": "Dhangarhi",
            "name": "[DHI] - Dhangarhi - DHI - Dhangarhi - Nepal",
            "searchName": "DHI-Dhangarhi-Dhangadi Airport-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "DNP",
            "countryCode": "NP",
            "cityCode": "DNP",
            "cityName": "Dang",
            "name": "[DNP] - Dang - DNP - Dang - Nepal",
            "searchName": "DNP-Dang-Tribhuvannagar Airport-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "DOP",
            "countryCode": "NP",
            "cityCode": "DOP",
            "cityName": "Dolpa",
            "name": "[DOP] - Dolpa - DOP - Dolpa - Nepal",
            "searchName": "DOP-Dolpa-Dolpa Havalimani-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "DAP",
            "countryCode": "NP",
            "cityCode": "DAP",
            "cityName": "Gokuleshwar",
            "name": "[DAP] - Darchula - DAP - Gokuleshwar - Nepal",
            "searchName": "DAP-Darchula-Gokuleshwar-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "QRH",
            "countryCode": "NL",
            "cityCode": "RTM",
            "cityName": "Rotterdam",
            "name": "[QRH] - Rotterdam Centraal Railway Station - RTM - Rotterdam - Netherlands",
            "searchName": "QRH-RTM-Rotterdam Centraal Railway Station-Rotterdam-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "QWZ",
            "countryCode": "NL",
            "cityCode": "QWZ",
            "cityName": "Best",
            "name": "[QWZ] - Best Bus Station - QWZ - Best - Netherlands",
            "searchName": "QWZ-Best Bus Station-Best-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "QYC",
            "countryCode": "NL",
            "cityCode": "QYC",
            "cityName": "Drachten",
            "name": "[QYC] - Drachten Bus Station - QYC - Drachten - Netherlands",
            "searchName": "QYC-Drachten Bus Station-Drachten-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "QYH",
            "countryCode": "NL",
            "cityCode": "QYH",
            "cityName": "Hengelo",
            "name": "[QYH] - Hengelo Railway Station - QYH - Hengelo - Netherlands",
            "searchName": "QYH-Hengelo Railway Station-Hengelo-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "QYI",
            "countryCode": "NL",
            "cityCode": "QYI",
            "cityName": "Hilversum",
            "name": "[QYI] - Hilversum Railway Station - QYI - Hilversum - Netherlands",
            "searchName": "QYI-Hilversum Railway Station-Hilversum-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "QYL",
            "countryCode": "NL",
            "cityCode": "QYL",
            "cityName": "Almelo",
            "name": "[QYL] - Almelo Railway Station - QYL - Almelo - Netherlands",
            "searchName": "QYL-Almelo Railway Station-Almelo-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "QYM",
            "countryCode": "NL",
            "cityCode": "QYM",
            "cityName": "Amersfoort",
            "name": "[QYM] - Amersfoort Railway Station - QYM - Amersfoort - Netherlands",
            "searchName": "QYM-Amersfoort Railway Station-Amersfoort-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "QYP",
            "countryCode": "NL",
            "cityCode": "QYP",
            "cityName": "Apeldoorn",
            "name": "[QYP] - Apeldoorn Railway Station - QYP - Apeldoorn - Netherlands",
            "searchName": "QYP-Apeldoorn Railway Station-Apeldoorn-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "QYZ",
            "countryCode": "NL",
            "cityCode": "QYZ",
            "cityName": "Heerenveen",
            "name": "[QYZ] - Heerenveen Bus Station - QYZ - Heerenveen - Netherlands",
            "searchName": "QYZ-Heerenveen Bus Station-Heerenveen-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "QYT",
            "countryCode": "NL",
            "cityCode": "QYT",
            "cityName": "Paterswolde",
            "name": "[QYT] - Paterswolde Bus Station - QYT - Paterswolde - Netherlands",
            "searchName": "QYT-Paterswolde Bus Station-Paterswolde-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "QYV",
            "countryCode": "NL",
            "cityCode": "QYV",
            "cityName": "Deventer",
            "name": "[QYV] - Deventer Railway Station - QYV - Deventer - Netherlands",
            "searchName": "QYV-Deventer Railway Station-Deventer-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "ZYU",
            "countryCode": "NL",
            "cityCode": "UTC",
            "cityName": "Utrecht",
            "name": "[ZYU] - Utrecht Centraal Railway Station - UTC - Utrecht - Netherlands",
            "searchName": "ZYU-UTC-Utrecht Centraal Railway Station-Utrecht-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "UDE",
            "countryCode": "NL",
            "cityCode": "UDE",
            "cityName": "Uden",
            "name": "[UDE] - Volkel - UDE - Uden - Netherlands",
            "searchName": "UDE-Volkel-Volkel Havalimani-Uden-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "WOE",
            "countryCode": "NL",
            "cityCode": "WOE",
            "cityName": "Woensdrecht",
            "name": "[WOE] - Woensdrecht - WOE - Woensdrecht - Netherlands",
            "searchName": "WOE-Woensdrecht-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "RYG",
            "countryCode": "NO",
            "cityCode": "OSL",
            "cityName": "Oslo",
            "name": "[RYG] - Moss Airport - OSL - Oslo - Norway",
            "searchName": "RYG-OSL-Oslo Rygge-Moss Airport-Moss Rygge Havalimani-Oslo-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "TRF",
            "countryCode": "NO",
            "cityCode": "OSL",
            "cityName": "Oslo",
            "name": "[TRF] - Sandefjord - OSL - Oslo - Norway",
            "searchName": "TRF-OSL-Oslo Torp Sandefjord-Sandefjord-Sandefjord Airport-Oslo-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XZO",
            "countryCode": "NO",
            "cityCode": "OSL",
            "cityName": "Oslo",
            "name": "[XZO] - Oslo S Railway Station - OSL - Oslo - Norway",
            "searchName": "XZO-OSL-Oslo S Railway Station-Oslo-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZZN",
            "countryCode": "NO",
            "cityCode": "OSL",
            "cityName": "Oslo",
            "name": "[ZZN] - Nationaltheatret RailS - OSL - Oslo - Norway",
            "searchName": "ZZN-OSL-Nationaltheatret RailS-Oslo-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "OSY",
            "countryCode": "NO",
            "cityCode": "OSY",
            "cityName": "Namsos",
            "name": "[OSY] - Namsos - OSY - Namsos - Norway",
            "searchName": "OSY-Namsos-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "MQN",
            "countryCode": "NO",
            "cityCode": "MQN",
            "cityName": "Mo I Rana",
            "name": "[MQN] - Mo I Rana - MQN - Mo I Rana - Norway",
            "searchName": "MQN-Mo I Rana-Mol I Rana Rossvoll-Mo i Rana-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "MOL",
            "countryCode": "NO",
            "cityCode": "MOL",
            "cityName": "Molde",
            "name": "[MOL] - Aro - MOL - Molde - Norway",
            "searchName": "MOL-Molde-Aro-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "NCR",
            "countryCode": "NI",
            "cityCode": "NCR",
            "cityName": "San Carlos",
            "name": "[NCR] - San Carlos - NCR - San Carlos - Nicaragua",
            "searchName": "NCR-San Carlos-Nicaragua-Nikaragua-Nikaragva",
            "priority": 0
        },
        {
            "iataCode": "NVG",
            "countryCode": "NI",
            "cityCode": "NVG",
            "cityName": "Nueva Guinea",
            "name": "[NVG] - Nueva Guinea - NVG - Nueva Guinea - Nicaragua",
            "searchName": "NVG-Nueva Guinea-Nicaragua-Nikaragua-Nikaragva",
            "priority": 0
        },
        {
            "iataCode": "MST",
            "countryCode": "NL",
            "cityCode": "MST",
            "cityName": "Maastricht",
            "name": "[MST] - Maastricht/aachen - MST - Maastricht - Netherlands",
            "searchName": "MST-Maastricht Aachen-Maastricht/aachen-Maastricht/Aachen-Maastricht Aachen Apt.-Maastricht-Mastrique-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "ZYT",
            "countryCode": "NL",
            "cityCode": "MST",
            "cityName": "Maastricht",
            "name": "[ZYT] - Maastricht Railway Station - MST - Maastricht - Netherlands",
            "searchName": "ZYT-MST-Maastricht Railway Station-Maastricht-Mastrique-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "GYM",
            "countryCode": "MX",
            "cityCode": "GYM",
            "cityName": "Guaymas",
            "name": "[GYM] - Gen Jose M Yanez - GYM - Guaymas - Mexico",
            "searchName": "GYM-Guaymas-Gen Jose M Yanez-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "GUB",
            "countryCode": "MX",
            "cityCode": "GUB",
            "cityName": "Guerrero Negro",
            "name": "[GUB] - Guerrero Negro - GUB - Guerrero Negro - Mexico",
            "searchName": "GUB-Guerrero Negro-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "HUX",
            "countryCode": "MX",
            "cityCode": "HUX",
            "cityName": "Huatulco",
            "name": "[HUX] - Huatulco - HUX - Huatulco - Mexico",
            "searchName": "HUX-Santa Cruz Huatulco-Huatulco-Santa Mar-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "RRG",
            "countryCode": "MU",
            "cityCode": "RRG",
            "cityName": "Rodrigues Island",
            "name": "[RRG] - Rodrigues Island - RRG - Rodrigues Island - Mauritius",
            "searchName": "RRG-Rodrigues Is-Rodrigues Island-Mauritius-Mavricij-Mauricijus-Mauricija-Ile Maurice-Mauriti-Mauritius Adasi-Maurice-Mauricio",
            "priority": 0
        },
        {
            "iataCode": "JCO",
            "countryCode": "MT",
            "cityCode": "JCO",
            "cityName": "Comino",
            "name": "[JCO] - Heliport - JCO - Comino - Malta",
            "searchName": "JCO-Heliport-Comino-Malta-Malte-Málta-Mal",
            "priority": 0
        },
        {
            "iataCode": "GZM",
            "countryCode": "MT",
            "cityCode": "GZM",
            "cityName": "Gozo",
            "name": "[GZM] - Gozo Heliport - GZM - Gozo - Malta",
            "searchName": "GZM-Gozo Heliport-Gozo-Malta-Malte-Málta-Mal",
            "priority": 0
        },
        {
            "iataCode": "MID",
            "countryCode": "MX",
            "cityCode": "MID",
            "cityName": "Merida",
            "name": "[MID] - Rejon - MID - Merida - Mexico",
            "searchName": "MID-Merida Mexiko-Rejon-Merida-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "MMC",
            "countryCode": "MX",
            "cityCode": "MMC",
            "cityName": "Mante",
            "name": "[MMC] - Ciudad Mante - MMC - Mante - Mexico",
            "searchName": "MMC-Ciudad Mante-Ciudad Mante Havalimani-Mante-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "MLM",
            "countryCode": "MX",
            "cityCode": "MLM",
            "cityName": "Morelia",
            "name": "[MLM] - Francisco J. MÃºgica Internacional - MLM - Morelia - Mexico",
            "searchName": "MLM-Morelia-Francisco J. Múgica Internacional-Francisco J. MÃºgica Internacional-Morelia Michoacan-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "LGK",
            "countryCode": "MY",
            "cityCode": "LGK",
            "cityName": "Langkawi",
            "name": "[LGK] - Langakawi International - LGK - Langkawi - Malaysia",
            "searchName": "LGK-Langkawi-Langakawi International-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LGL",
            "countryCode": "MY",
            "cityCode": "LGL",
            "cityName": "Long Lellang",
            "name": "[LGL] - Long Lellang - LGL - Long Lellang - Malaysia",
            "searchName": "LGL-Long Lellang-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LDU",
            "countryCode": "MY",
            "cityCode": "LDU",
            "cityName": "Lahad Datu",
            "name": "[LDU] - Lahad Datu - LDU - Lahad Datu - Malaysia",
            "searchName": "LDU-Lahad Datu-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LBU",
            "countryCode": "MY",
            "cityCode": "LBU",
            "cityName": "Labuan",
            "name": "[LBU] - Labuan - LBU - Labuan - Malaysia",
            "searchName": "LBU-Labuan-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LWY",
            "countryCode": "MY",
            "cityCode": "LWY",
            "cityName": "Lawas",
            "name": "[LWY] - Lawas - LWY - Lawas - Malaysia",
            "searchName": "LWY-Lawas-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LSU",
            "countryCode": "MY",
            "cityCode": "LSU",
            "cityName": "Long Sukang",
            "name": "[LSU] - Long Sukang - LSU - Long Sukang - Malaysia",
            "searchName": "LSU-Long Sukang-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LSM",
            "countryCode": "MY",
            "cityCode": "LSM",
            "cityName": "Long Semado",
            "name": "[LSM] - Lawas - LSM - Long Semado - Malaysia",
            "searchName": "LSM-Lawas-Long Semado-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LMN",
            "countryCode": "MY",
            "cityCode": "LMN",
            "cityName": "Limbang",
            "name": "[LMN] - Limbang - LMN - Limbang - Malaysia",
            "searchName": "LMN-Limbang-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LLM",
            "countryCode": "MY",
            "cityCode": "LLM",
            "cityName": "Long Lama",
            "name": "[LLM] - Long Lama - LLM - Long Lama - Malaysia",
            "searchName": "LLM-Long Lama-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LKH",
            "countryCode": "MY",
            "cityCode": "LKH",
            "cityName": "Long Akah",
            "name": "[LKH] - Long Akah - LKH - Long Akah - Malaysia",
            "searchName": "LKH-Long Akah-Long Akah Havalimani-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "KGU",
            "countryCode": "MY",
            "cityCode": "KGU",
            "cityName": "Keningau",
            "name": "[KGU] - Keningau - KGU - Keningau - Malaysia",
            "searchName": "KGU-Keningau-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "KPI",
            "countryCode": "MY",
            "cityCode": "KPI",
            "cityName": "Kapit",
            "name": "[KPI] - Kapit - KPI - Kapit - Malaysia",
            "searchName": "KPI-Kapit-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "KTE",
            "countryCode": "MY",
            "cityCode": "KTE",
            "cityName": "Kerteh",
            "name": "[KTE] - Kerteh - KTE - Kerteh - Malaysia",
            "searchName": "KTE-Kerteh-Kertech Havalimani-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "ISJ",
            "countryCode": "MX",
            "cityCode": "ISJ",
            "cityName": "Isla Mujeres",
            "name": "[ISJ] - Isla Mujeres - ISJ - Isla Mujeres - Mexico",
            "searchName": "ISJ-Isla Mujeres-Isla Mujeres Havalimani-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "IZT",
            "countryCode": "MX",
            "cityCode": "IZT",
            "cityName": "Ixtepec",
            "name": "[IZT] - Ixtepec - IZT - Ixtepec - Mexico",
            "searchName": "IZT-Ixtepec-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "JAL",
            "countryCode": "MX",
            "cityCode": "JAL",
            "cityName": "Jalapa",
            "name": "[JAL] - Jalapa - JAL - Jalapa - Mexico",
            "searchName": "JAL-Jalapa-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "BJX",
            "countryCode": "MX",
            "cityCode": "BJX",
            "cityName": "León",
            "name": "[BJX] - Del BajÃ­o International - BJX - León - Mexico",
            "searchName": "BJX-León Guanajuato-Del Bajío International-Del Bajio-Del BajÃ­o International-Leon/Guanajuato-Le-León-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "BHL",
            "countryCode": "MX",
            "cityCode": "BHL",
            "cityName": "Bahia Angeles",
            "name": "[BHL] - Bahia Angeles - BHL - Bahia Angeles - Mexico",
            "searchName": "BHL-Bahia Angeles-Bahia De Los Angeles Havalimani-Bahia de los Angeles-Bahia De Los Angeles-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "ACN",
            "countryCode": "MX",
            "cityCode": "ACN",
            "cityName": "Acuna",
            "name": "[ACN] - International - ACN - Acuna - Mexico",
            "searchName": "ACN-International-Ciudad Acuna-Acuna-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "ACA",
            "countryCode": "MX",
            "cityCode": "ACA",
            "cityName": "Acapulco",
            "name": "[ACA] - General Juan N. Alvarez International - ACA - Acapulco - Mexico",
            "searchName": "ACA-Acapulco-General Juan N. Alvarez International-Alvarez Intl-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "AJS",
            "countryCode": "MX",
            "cityCode": "AJS",
            "cityName": "Abreojos",
            "name": "[AJS] - Abreojos - AJS - Abreojos - Mexico",
            "searchName": "AJS-Abreojos-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "AGU",
            "countryCode": "MX",
            "cityCode": "AGU",
            "cityName": "Aguascalientes",
            "name": "[AGU] - Aguascalients - AGU - Aguascalientes - Mexico",
            "searchName": "AGU-Aguascalientes-Aguascalients-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "AZG",
            "countryCode": "MX",
            "cityCode": "AZG",
            "cityName": "Apatzingan",
            "name": "[AZG] - Apatzingan - AZG - Apatzingan - Mexico",
            "searchName": "AZG-Apatzingan-Pablo L Sidar Havalimani-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CME",
            "countryCode": "MX",
            "cityCode": "CME",
            "cityName": "Ciudad Del Carmen",
            "name": "[CME] - Ciudad Del Carmen - CME - Ciudad Del Carmen - Mexico",
            "searchName": "CME-Ciudad Del Carmen-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CNA",
            "countryCode": "MX",
            "cityCode": "CNA",
            "cityName": "Cananea",
            "name": "[CNA] - Cananea - CNA - Cananea - Mexico",
            "searchName": "CNA-Cananea-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CPE",
            "countryCode": "MX",
            "cityCode": "CPE",
            "cityName": "Campeche",
            "name": "[CPE] - Campeche International - CPE - Campeche - Mexico",
            "searchName": "CPE-Campeche-Campeche International-Campeche Intl-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CYW",
            "countryCode": "MX",
            "cityCode": "CYW",
            "cityName": "Celaya",
            "name": "[CYW] - Celaya - CYW - Celaya - Mexico",
            "searchName": "CYW-Celaya-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CZA",
            "countryCode": "MX",
            "cityCode": "CZA",
            "cityName": "Chichen Itza",
            "name": "[CZA] - Chichen Itza - CZA - Chichen Itza - Mexico",
            "searchName": "CZA-Chichen Itza-Chichen Itza Havalimani-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CZM",
            "countryCode": "MX",
            "cityCode": "CZM",
            "cityName": "Cozumel",
            "name": "[CZM] - Cozumel - CZM - Cozumel - Mexico",
            "searchName": "CZM-Cozumel-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CTM",
            "countryCode": "MX",
            "cityCode": "CTM",
            "cityName": "Chetumal",
            "name": "[CTM] - Chetumal - CTM - Chetumal - Mexico",
            "searchName": "CTM-Chetumal-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CUA",
            "countryCode": "MX",
            "cityCode": "CUA",
            "cityName": "Ciudad Constitucion",
            "name": "[CUA] - Ciudad Constitucion - CUA - Ciudad Constitucion - Mexico",
            "searchName": "CUA-Ciudad Constitucion-Ciudad Constitucion Havalimani-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CUL",
            "countryCode": "MX",
            "cityCode": "CUL",
            "cityName": "Culiacan",
            "name": "[CUL] - Fedl De Bachigualato - CUL - Culiacan - Mexico",
            "searchName": "CUL-Culiacan-Fedl De Bachigualato-Culiac-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CVM",
            "countryCode": "MX",
            "cityCode": "CVM",
            "cityName": "Ciudad Victoria",
            "name": "[CVM] - Ciudad Victoria - CVM - Ciudad Victoria - Mexico",
            "searchName": "CVM-Ciudad Victoria-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CVJ",
            "countryCode": "MX",
            "cityCode": "CVJ",
            "cityName": "Cuernavaca",
            "name": "[CVJ] - General Mariano Matamoros Airport - CVJ - Cuernavaca - Mexico",
            "searchName": "CVJ-Cuernavaca-General Mariano Matamoros Airport-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CUU",
            "countryCode": "MX",
            "cityCode": "CUU",
            "cityName": "Chihuahua",
            "name": "[CUU] - Gen Fierro Villalobos - CUU - Chihuahua - Mexico",
            "searchName": "CUU-Chihuahua-Gen Fierro Villalobos-Fierro Villalobos-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CEN",
            "countryCode": "MX",
            "cityCode": "CEN",
            "cityName": "Ciudad Obregon",
            "name": "[CEN] - Ciudad Obregon - CEN - Ciudad Obregon - Mexico",
            "searchName": "CEN-Ciudad Obregon-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CLQ",
            "countryCode": "MX",
            "cityCode": "CLQ",
            "cityName": "Colima",
            "name": "[CLQ] - Colima - CLQ - Colima - Mexico",
            "searchName": "CLQ-Colima-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CJS",
            "countryCode": "MX",
            "cityCode": "CJS",
            "cityName": "Ciudad Juarez",
            "name": "[CJS] - Abraham Gonzalez International - CJS - Ciudad Juarez - Mexico",
            "searchName": "CJS-Ciudad Juarez-Abraham Gonzalez International- Abraham Gonzalez-Ciudad Ju-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "CJT",
            "countryCode": "MX",
            "cityCode": "CJT",
            "cityName": "Comitan",
            "name": "[CJT] - Copalar - CJT - Comitan - Mexico",
            "searchName": "CJT-Copalar-Comitan-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "DGO",
            "countryCode": "MX",
            "cityCode": "DGO",
            "cityName": "Durango",
            "name": "[DGO] - Guadalupe Victoria - DGO - Durango - Mexico",
            "searchName": "DGO-Durango Mexiko-Guadalupe Victoria-Durango-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "ESE",
            "countryCode": "MX",
            "cityCode": "ESE",
            "cityName": "Ensenada",
            "name": "[ESE] - Ensenada - ESE - Ensenada - Mexico",
            "searchName": "ESE-Ensenada-Ensenada Havalimani-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "AOR",
            "countryCode": "MY",
            "cityCode": "AOR",
            "cityName": "Alor Setar",
            "name": "[AOR] - Alor Setar - AOR - Alor Setar - Malaysia",
            "searchName": "AOR-Alor Setar-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "BKI",
            "countryCode": "MY",
            "cityCode": "BKI",
            "cityName": "Kota-Kinabalu",
            "name": "[BKI] - Kota-Kinabalu International Airport - BKI - Kota-Kinabalu - Malaysia",
            "searchName": "BKI-Kota Kinabalu-Kota-Kinabalu International Airport-Kinabalu-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "BKM",
            "countryCode": "MY",
            "cityCode": "BKM",
            "cityName": "Bakalalan",
            "name": "[BKM] - Bakalalan - BKM - Bakalalan - Malaysia",
            "searchName": "BKM-Bakalalan-Bakalalan Havalimani-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "BLG",
            "countryCode": "MY",
            "cityCode": "BLG",
            "cityName": "Belaga",
            "name": "[BLG] - Belaga - BLG - Belaga - Malaysia",
            "searchName": "BLG-Belaga-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "BBN",
            "countryCode": "MY",
            "cityCode": "BBN",
            "cityName": "Bario",
            "name": "[BBN] - Bario - BBN - Bario - Malaysia",
            "searchName": "BBN-Bario-Bario Arpt-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "BWH",
            "countryCode": "MY",
            "cityCode": "BWH",
            "cityName": "Butterworth",
            "name": "[BWH] - Butterworth - BWH - Butterworth - Malaysia",
            "searchName": "BWH-Butterworth-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "BTU",
            "countryCode": "MY",
            "cityCode": "BTU",
            "cityName": "Bintulu",
            "name": "[BTU] - Bintulu - BTU - Bintulu - Malaysia",
            "searchName": "BTU-Bintulu-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "BSE",
            "countryCode": "MY",
            "cityCode": "BSE",
            "cityName": "Sematan",
            "name": "[BSE] - Sematan - BSE - Sematan - Malaysia",
            "searchName": "BSE-Sematan-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "IPH",
            "countryCode": "MY",
            "cityCode": "IPH",
            "cityName": "Ipoh",
            "name": "[IPH] - Sultan Azlan Shah Airport - IPH - Ipoh - Malaysia",
            "searchName": "IPH-Ipoh-Sultan Azlan Shah Airport-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "GTK",
            "countryCode": "MY",
            "cityCode": "GTK",
            "cityName": "Sungei Tekai",
            "name": "[GTK] - Sungei Tekai - GTK - Sungei Tekai - Malaysia",
            "searchName": "GTK-Sungei Tekai-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "GSA",
            "countryCode": "MY",
            "cityCode": "GSA",
            "cityName": "Long Pasia",
            "name": "[GSA] - Long Pasia - GSA - Long Pasia - Malaysia",
            "searchName": "GSA-Long Pasia-Long Paisa-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LBP",
            "countryCode": "MY",
            "cityCode": "LBP",
            "cityName": "Long Banga",
            "name": "[LBP] - Long Banga Airfield - LBP - Long Banga - Malaysia",
            "searchName": "LBP-Long Banga Airfield-Long Banga-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "LAC",
            "countryCode": "MY",
            "cityCode": "LAC",
            "cityName": "Pulau Layang-Layang Island",
            "name": "[LAC] - Layang-Layang Airstrip - LAC - Pulau Layang-Layang Island - Malaysia",
            "searchName": "LAC-Landebahn Layang-Layang-Layang Airstrip-Pulau Layang Layang-Pulau Layang-Layang Island-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "KUA",
            "countryCode": "MY",
            "cityCode": "KUA",
            "cityName": "Kuantan",
            "name": "[KUA] - Kuantan - KUA - Kuantan - Malaysia",
            "searchName": "KUA-Kuantan-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "KUD",
            "countryCode": "MY",
            "cityCode": "KUD",
            "cityName": "Kudat",
            "name": "[KUD] - Kudat - KUD - Kudat - Malaysia",
            "searchName": "KUD-Kudat-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "UPN",
            "countryCode": "MX",
            "cityCode": "UPN",
            "cityName": "Uruapan",
            "name": "[UPN] - Uruapan - UPN - Uruapan - Mexico",
            "searchName": "UPN-Uruapan-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "UAC",
            "countryCode": "MX",
            "cityCode": "UAC",
            "cityName": "San Luis Rio Colorado",
            "name": "[UAC] - San Luis Rio Colorado - UAC - San Luis Rio Colorado - Mexico",
            "searchName": "UAC-San Luis Rio Colorado-San Luis Rio Colorado Belediyesi Havalimani-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "XAL",
            "countryCode": "MX",
            "cityCode": "XAL",
            "cityName": "Alamos",
            "name": "[XAL] - Alamos - XAL - Alamos - Mexico",
            "searchName": "XAL-Alamos-Alamos Havalimani-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "TPQ",
            "countryCode": "MX",
            "cityCode": "TPQ",
            "cityName": "Tepic",
            "name": "[TPQ] - Tepic - TPQ - Tepic - Mexico",
            "searchName": "TPQ-Tepic-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "TSL",
            "countryCode": "MX",
            "cityCode": "TSL",
            "cityName": "Tamuin",
            "name": "[TSL] - Tamuin - TSL - Tamuin - Mexico",
            "searchName": "TSL-Tamuin-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "TRC",
            "countryCode": "MX",
            "cityCode": "TRC",
            "cityName": "Torreon",
            "name": "[TRC] - Francisco Sarabia - TRC - Torreon - Mexico",
            "searchName": "TRC-Torreon-Francisco Sarabia-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "TZM",
            "countryCode": "MX",
            "cityCode": "TZM",
            "cityName": "Tizimin",
            "name": "[TZM] - Tizimin - TZM - Tizimin - Mexico",
            "searchName": "TZM-Tizimin-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "TUY",
            "countryCode": "MX",
            "cityCode": "TUY",
            "cityName": "Tulum",
            "name": "[TUY] - Tulum - TUY - Tulum - Mexico",
            "searchName": "TUY-Tulum-Tulum Havalimani-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "TIJ",
            "countryCode": "MX",
            "cityCode": "TIJ",
            "cityName": "Tijuana",
            "name": "[TIJ] - General A. L. Rodriguez International - TIJ - Tijuana - Mexico",
            "searchName": "TIJ-Tijuana-General A. L. Rodriguez International-Rodriguez-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "TCN",
            "countryCode": "MX",
            "cityCode": "TCN",
            "cityName": "Tehuacan",
            "name": "[TCN] - Tehuacan - TCN - Tehuacan - Mexico",
            "searchName": "TCN-Tehuacan-Tehuacan Havalimani-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "TGZ",
            "countryCode": "MX",
            "cityCode": "TGZ",
            "cityName": "Tuxtla Gutierrez",
            "name": "[TGZ] - Llano San Juan - TGZ - Tuxtla Gutierrez - Mexico",
            "searchName": "TGZ-Tuxtla Gutierrez-Llano San Juan-Tuxtla Guti-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "TAM",
            "countryCode": "MX",
            "cityCode": "TAM",
            "cityName": "Tampico",
            "name": "[TAM] - Gen F Javier Mina - TAM - Tampico - Mexico",
            "searchName": "TAM-Tampico-Gen F Javier Mina-General F Javier Mina-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "TAP",
            "countryCode": "MX",
            "cityCode": "TAP",
            "cityName": "Tapachula",
            "name": "[TAP] - Tapachula International - TAP - Tapachula - Mexico",
            "searchName": "TAP-Tapachula-Tapachula International-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "SFH",
            "countryCode": "MX",
            "cityCode": "SFH",
            "cityName": "San Felipe",
            "name": "[SFH] - San Felipe - SFH - San Felipe - Mexico",
            "searchName": "SFH-San Felipe-San Felipe Havalimani-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "SCX",
            "countryCode": "MX",
            "cityCode": "SCX",
            "cityName": "Salina Cruz",
            "name": "[SCX] - Salina Cruz - SCX - Salina Cruz - Mexico",
            "searchName": "SCX-Salina Cruz-Salina Cruz Havalimani-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "SJD",
            "countryCode": "MX",
            "cityCode": "SJD",
            "cityName": "San Jose Del Cabo",
            "name": "[SJD] - Los Cabos - SJD - San Jose Del Cabo - Mexico",
            "searchName": "SJD-Los Cabos-San Jose Cabo-San Jose Del Cabo-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "SGM",
            "countryCode": "MX",
            "cityCode": "SGM",
            "cityName": "San Ignacio",
            "name": "[SGM] - San Ignacio - SGM - San Ignacio - Mexico",
            "searchName": "SGM-San Ignacio-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "SNQ",
            "countryCode": "MX",
            "cityCode": "SNQ",
            "cityName": "San Quintin",
            "name": "[SNQ] - San Quintin - SNQ - San Quintin - Mexico",
            "searchName": "SNQ-San Quintin-San Quintin Havalimani-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "SLW",
            "countryCode": "MX",
            "cityCode": "SLW",
            "cityName": "Saltillo",
            "name": "[SLW] - Saltillo - SLW - Saltillo - Mexico",
            "searchName": "SLW-Saltillo-Saltillo Coahuila-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "SLP",
            "countryCode": "MX",
            "cityCode": "SLP",
            "cityName": "San Luis Potosi",
            "name": "[SLP] - San Luis Potosi - SLP - San Luis Potosi - Mexico",
            "searchName": "SLP-San Luis Potosi-San Luis Potos-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "SRL",
            "countryCode": "MX",
            "cityCode": "SRL",
            "cityName": "Santa Rosalia",
            "name": "[SRL] - Santa Rosalia - SRL - Santa Rosalia - Mexico",
            "searchName": "SRL-Santa Rosalia-Santa Rosalia Havalimani-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "LMM",
            "countryCode": "MX",
            "cityCode": "LMM",
            "cityName": "Los Mochis",
            "name": "[LMM] - Federal (Valle Del Fuerte) - LMM - Los Mochis - Mexico",
            "searchName": "LMM-Los Mochis-Federal (Valle Del Fuerte)-Federal-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "LOM",
            "countryCode": "MX",
            "cityCode": "LOM",
            "cityName": "Lagos De Moreno",
            "name": "[LOM] - Francisco P. V. Y R. - LOM - Lagos De Moreno - Mexico",
            "searchName": "LOM-Francisco P. V. Y R.-Francisco P V Y R Havalimani-Lagos De Moreno-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "LOV",
            "countryCode": "MX",
            "cityCode": "LOV",
            "cityName": "Monclova",
            "name": "[LOV] - Monclova - LOV - Monclova - Mexico",
            "searchName": "LOV-Monclova-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "LTO",
            "countryCode": "MX",
            "cityCode": "LTO",
            "cityName": "Loreto",
            "name": "[LTO] - Loreto - LTO - Loreto - Mexico",
            "searchName": "LTO-Loreto-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "LZC",
            "countryCode": "MX",
            "cityCode": "LZC",
            "cityName": "Lazaro Cardenas",
            "name": "[LZC] - Lazaro Cardenas - LZC - Lazaro Cardenas - Mexico",
            "searchName": "LZC-Lazaro Cardenas-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "LAP",
            "countryCode": "MX",
            "cityCode": "LAP",
            "cityName": "La Paz",
            "name": "[LAP] - General Manuel MÃ¡rquez De LeÃ³n International Airport - LAP - La Paz - Mexico",
            "searchName": "LAP-La Paz Mexiko-General Manuel Márquez De León International Airport-Leon-General Manuel MÃ¡rquez De LeÃ³n International Airport-La Paz-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "NTR",
            "countryCode": "MX",
            "cityCode": "MTY",
            "cityName": "Monterrey",
            "name": "[NTR] - Aeropuerto del Norte International Airport - MTY - Monterrey - Mexico",
            "searchName": "NTR-MTY-Nord-Aeropuerto del Norte International Airport-Aeropuerto Del Norte Havalimani-Monterrey-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "MTT",
            "countryCode": "MX",
            "cityCode": "MTT",
            "cityName": "Minatitlan",
            "name": "[MTT] - Minatitlan - MTT - Minatitlan - Mexico",
            "searchName": "MTT-Minatitlan-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "MUG",
            "countryCode": "MX",
            "cityCode": "MUG",
            "cityName": "Mulege",
            "name": "[MUG] - Mulege - MUG - Mulege - Mexico",
            "searchName": "MUG-Mulege-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "REX",
            "countryCode": "MX",
            "cityCode": "REX",
            "cityName": "Reynosa",
            "name": "[REX] - Gen Lucio Blanco - REX - Reynosa - Mexico",
            "searchName": "REX-Reynosa-Gen Lucio Blanco-Reynossa-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PUH",
            "countryCode": "MX",
            "cityCode": "PUH",
            "cityName": "Pochutla",
            "name": "[PUH] - Pochutla - PUH - Pochutla - Mexico",
            "searchName": "PUH-Pochutla-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PVR",
            "countryCode": "MX",
            "cityCode": "PVR",
            "cityName": "Puerto Vallarta",
            "name": "[PVR] - Licenciado Gustavo DÃ­az Ordaz International - PVR - Puerto Vallarta - Mexico",
            "searchName": "PVR-Puerto Vallarta-Licenciado Gustavo Díaz Ordaz International-Ordaz-Licenciado Gustavo DÃ­az Ordaz International-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PXM",
            "countryCode": "MX",
            "cityCode": "PXM",
            "cityName": "Puerto Escondido",
            "name": "[PXM] - Puerto Escondido - PXM - Puerto Escondido - Mexico",
            "searchName": "PXM-Puerto Escondido-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "QRO",
            "countryCode": "MX",
            "cityCode": "QRO",
            "cityName": "Queretaro",
            "name": "[QRO] - Ingeniero Fernando Espinoza GutiÃ©rrez International - QRO - Queretaro - Mexico",
            "searchName": "QRO-Queretaro-Ingeniero Fernando Espinoza Gutiérrez International-Ingeniero Fernando Espinoza GutiÃ©rrez International-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PAZ",
            "countryCode": "MX",
            "cityCode": "PAZ",
            "cityName": "Poza Rica",
            "name": "[PAZ] - Tajin - PAZ - Poza Rica - Mexico",
            "searchName": "PAZ-Poza Rica-Tajin-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PBC",
            "countryCode": "MX",
            "cityCode": "PBC",
            "cityName": "Puebla Atlixco",
            "name": "[PBC] - Hermanos Serdan International - PBC - Puebla Atlixco - Mexico",
            "searchName": "PBC-Puebla-Hermanos Serdan International-Huejotsingo-Puebla Atlixco-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PCO",
            "countryCode": "MX",
            "cityCode": "PCO",
            "cityName": "Punta Colorada",
            "name": "[PCO] - Punta Colorada - PCO - Punta Colorada - Mexico",
            "searchName": "PCO-Punta Colorada-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PCM",
            "countryCode": "MX",
            "cityCode": "PCM",
            "cityName": "Playa Del Carmen",
            "name": "[PCM] - Playa Del Carmen - PCM - Playa Del Carmen - Mexico",
            "searchName": "PCM-Playa Del Carmen-Playa Del Carmen Havalimani-Playa del Carmen-Playa De Carmen-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PCV",
            "countryCode": "MX",
            "cityCode": "PCV",
            "cityName": "Punta Chivato",
            "name": "[PCV] - Punta Chivato - PCV - Punta Chivato - Mexico",
            "searchName": "PCV-Punta Chivato-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PJZ",
            "countryCode": "MX",
            "cityCode": "PJZ",
            "cityName": "Puerto Juarez",
            "name": "[PJZ] - Puerto Juarez - PJZ - Puerto Juarez - Mexico",
            "searchName": "PJZ-Puerto Juarez-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PDS",
            "countryCode": "MX",
            "cityCode": "PDS",
            "cityName": "Piedras Negras",
            "name": "[PDS] - Piedras Negras - PDS - Piedras Negras - Mexico",
            "searchName": "PDS-Piedras Negras-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PQM",
            "countryCode": "MX",
            "cityCode": "PQM",
            "cityName": "Palenque",
            "name": "[PQM] - Palenque - PQM - Palenque - Mexico",
            "searchName": "PQM-Palenque-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "PPE",
            "countryCode": "MX",
            "cityCode": "PPE",
            "cityName": "Puerto Penasco",
            "name": "[PPE] - Puerto Penasco - PPE - Puerto Penasco - Mexico",
            "searchName": "PPE-Puerto Penasco-Puerto Penasco Belediyesi Havalimani-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "VSA",
            "countryCode": "MX",
            "cityCode": "VSA",
            "cityName": "Villahermosa",
            "name": "[VSA] - Capitan Carlos Rovirosa - VSA - Villahermosa - Mexico",
            "searchName": "VSA-Villahermosa-Capitan Carlos Rovirosa-Capitan Carlos Perez-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "ENS",
            "countryCode": "NL",
            "cityCode": "ENS",
            "cityName": "Enschede",
            "name": "[ENS] - Twente - ENS - Enschede - Netherlands",
            "searchName": "ENS-Twente-Twente Havalimani-Enschede-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "QYE",
            "countryCode": "NL",
            "cityCode": "ENS",
            "cityName": "Enschede",
            "name": "[QYE] - Enschede Railway Station - ENS - Enschede - Netherlands",
            "searchName": "QYE-ENS-Enschede Railway Station-Enschede-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "EIN",
            "countryCode": "NL",
            "cityCode": "EIN",
            "cityName": "Eindhoven",
            "name": "[EIN] - Eindhoven - EIN - Eindhoven - Netherlands",
            "searchName": "EIN-Eindhoven-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "ZYE",
            "countryCode": "NL",
            "cityCode": "EIN",
            "cityName": "Eindhoven",
            "name": "[ZYE] - Eindhoven Railway Station - EIN - Eindhoven - Netherlands",
            "searchName": "ZYE-EIN-Eindhoven Railway Station-Eindhoven-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "DHR",
            "countryCode": "NL",
            "cityCode": "DHR",
            "cityName": "Den Helder",
            "name": "[DHR] - De Kooy/Den Helder Airport - DHR - Den Helder - Netherlands",
            "searchName": "DHR-De Kooy/Den Helder Airport-Den Helder-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "LWR",
            "countryCode": "NL",
            "cityCode": "LWR",
            "cityName": "Leeuwarden",
            "name": "[LWR] - Leeuwarden - LWR - Leeuwarden - Netherlands",
            "searchName": "LWR-Leeuwarden-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "LEY",
            "countryCode": "NL",
            "cityCode": "LEY",
            "cityName": "Lelystad",
            "name": "[LEY] - Lelystad - LEY - Lelystad - Netherlands",
            "searchName": "LEY-Lelystad-Lelystad Havalimani-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "GLZ",
            "countryCode": "NL",
            "cityCode": "GLZ",
            "cityName": "Breda",
            "name": "[GLZ] - Gilze-Rijen - GLZ - Breda - Netherlands",
            "searchName": "GLZ-Gilze-Rijen-Breda-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "ZYH",
            "countryCode": "NL",
            "cityCode": "HAG",
            "cityName": "The Hague",
            "name": "[ZYH] - The Hague Centraal Railway Station - HAG - The Hague - Netherlands",
            "searchName": "ZYH-HAG-The Hague Centraal Railway Station-The Hague-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "ZXY",
            "countryCode": "NL",
            "cityCode": "HAG",
            "cityName": "The Hague",
            "name": "[ZXY] - Holland Spoor Rail Station - HAG - The Hague - Netherlands",
            "searchName": "ZXY-HAG-Holland Spoor Rail Station-The Hague-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "GRQ",
            "countryCode": "NL",
            "cityCode": "GRQ",
            "cityName": "Groningen",
            "name": "[GRQ] - Eelde - GRQ - Groningen - Netherlands",
            "searchName": "GRQ-Groningen-Eelde-Eelde Apt-Groninga-Groningue-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "ZYM",
            "countryCode": "NL",
            "cityCode": "ZYM",
            "cityName": "Arnhem",
            "name": "[ZYM] - Arnhem Railway Station - ZYM - Arnhem - Netherlands",
            "searchName": "ZYM-Arnhem Railway Station-Arnhem-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "ZYO",
            "countryCode": "NL",
            "cityCode": "ZYO",
            "cityName": "Roosendaal",
            "name": "[ZYO] - Roosendaal Railway Station - ZYO - Roosendaal - Netherlands",
            "searchName": "ZYO-Roosendaal Railway Station-Roosendaal-Pays-Bas-Holland-Alankomaat-Hollanda-Països Baixos-Hollandia-Olanda-Nizozemska-Nyderlandai-Niderlande-Netherlands-Nederland-Holandia-Niederlande-Países Bajos-Paesi Bassi",
            "priority": 0
        },
        {
            "iataCode": "ZYS",
            "countryCode": "NO",
            "cityCode": "ZYS",
            "cityName": "Sandefjord",
            "name": "[ZYS] - Sandefjord Railway Station - ZYS - Sandefjord - Norway",
            "searchName": "ZYS-Sandefjord Railway Station-Sandefjord-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZYV",
            "countryCode": "NO",
            "cityCode": "ZYV",
            "cityName": "Vegarshei",
            "name": "[ZYV] - Vegarshei Railway Station - ZYV - Vegarshei - Norway",
            "searchName": "ZYV-Vegarshei Railway Station-Vegarshei-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZYW",
            "countryCode": "NO",
            "cityCode": "ZYW",
            "cityName": "Sandvika",
            "name": "[ZYW] - Sandvika Railway Station - ZYW - Sandvika - Norway",
            "searchName": "ZYW-Sandvika Railway Station-Sandvika-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZYY",
            "countryCode": "NO",
            "cityCode": "ZYY",
            "cityName": "Marnardal",
            "name": "[ZYY] - Marnardal Railway Station - ZYY - Marnardal - Norway",
            "searchName": "ZYY-Marnardal Railway Station-Marnardal-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZWJ",
            "countryCode": "NO",
            "cityCode": "ZWJ",
            "cityName": "Haugastol",
            "name": "[ZWJ] - Haugastol Railway Station - ZWJ - Haugastol - Norway",
            "searchName": "ZWJ-Haugastol Railway Station-Haugastol-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZVB",
            "countryCode": "NO",
            "cityCode": "ZVB",
            "cityName": "Brumunddal",
            "name": "[ZVB] - Brumunddal Railway Station - ZVB - Brumunddal - Norway",
            "searchName": "ZVB-Brumunddal Railway Station-Brumunddal-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZVD",
            "countryCode": "NO",
            "cityCode": "ZVD",
            "cityName": "Drangedal",
            "name": "[ZVD] - Drangedal Railway Station - ZVD - Drangedal - Norway",
            "searchName": "ZVD-Drangedal Railway Station-Drangedal-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZXM",
            "countryCode": "NO",
            "cityCode": "ZXM",
            "cityName": "Rognan",
            "name": "[ZXM] - Rognan Railway Station - ZXM - Rognan - Norway",
            "searchName": "ZXM-Rognan Railway Station-Rognan-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZXO",
            "countryCode": "NO",
            "cityCode": "ZXO",
            "cityName": "Fauske",
            "name": "[ZXO] - Fauske Railway Station - ZXO - Fauske - Norway",
            "searchName": "ZXO-Fauske Railway Station-Fauske-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZXU",
            "countryCode": "NO",
            "cityCode": "ZXU",
            "cityName": "Rygge",
            "name": "[ZXU] - Rygge Railway Station - ZXU - Rygge - Norway",
            "searchName": "ZXU-Rygge Railway Station-Rygge-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZXX",
            "countryCode": "NO",
            "cityCode": "ZXX",
            "cityName": "Rade",
            "name": "[ZXX] - Rade Railway Station - ZXX - Rade - Norway",
            "searchName": "ZXX-Rade Railway Station-Rade-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZYG",
            "countryCode": "NO",
            "cityCode": "ZYG",
            "cityName": "Gjovik",
            "name": "[ZYG] - Gjovik Railway Station - ZYG - Gjovik - Norway",
            "searchName": "ZYG-Gjovik Railway Station-Gjovik-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZMZ",
            "countryCode": "NO",
            "cityCode": "ZMZ",
            "cityName": "Bjerka",
            "name": "[ZMZ] - Bjerka Railway Station - ZMZ - Bjerka - Norway",
            "searchName": "ZMZ-Bjerka Railway Station-Bjerka-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZMQ",
            "countryCode": "NO",
            "cityCode": "ZMQ",
            "cityName": "Raufoss",
            "name": "[ZMQ] - Raufoss Railway Station - ZMQ - Raufoss - Norway",
            "searchName": "ZMQ-Raufoss Railway Station-Raufoss-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZMX",
            "countryCode": "NO",
            "cityCode": "ZMX",
            "cityName": "Tynset",
            "name": "[ZMX] - Tynset Railway Station - ZMX - Tynset - Norway",
            "searchName": "ZMX-Tynset Railway Station-Tynset-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "YVH",
            "countryCode": "NO",
            "cityCode": "YVH",
            "cityName": "Hjerkinn",
            "name": "[YVH] - Hjerkinn Railway Station - YVH - Hjerkinn - Norway",
            "searchName": "YVH-Hjerkinn Railway Station-Hjerkinn-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "YVK",
            "countryCode": "NO",
            "cityCode": "YVK",
            "cityName": "Koppang",
            "name": "[YVK] - Koppang Railway Station - YVK - Koppang - Norway",
            "searchName": "YVK-Koppang Railway Station-Koppang-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "YVS",
            "countryCode": "NO",
            "cityCode": "YVS",
            "cityName": "Ski",
            "name": "[YVS] - Ski Railway Station - YVS - Ski - Norway",
            "searchName": "YVS-Ski Railway Station-Ski-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "HAA",
            "countryCode": "NO",
            "cityCode": "HAA",
            "cityName": "Hasvik",
            "name": "[HAA] - Hasvik - HAA - Hasvik - Norway",
            "searchName": "HAA-Hasvik-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "GLL",
            "countryCode": "NO",
            "cityCode": "GLL",
            "cityName": "Gol",
            "name": "[GLL] - Klanten Airport - GLL - Gol - Norway",
            "searchName": "GLL-Klanten Airport-Klanten Havalimani-Gol-Gol City-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "FRO",
            "countryCode": "NO",
            "cityCode": "FRO",
            "cityName": "Floro",
            "name": "[FRO] - Flora - FRO - Floro - Norway",
            "searchName": "FRO-Floro-Flora-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "HOV",
            "countryCode": "NO",
            "cityCode": "HOV",
            "cityName": "Orsta-Volda",
            "name": "[HOV] - Hovden - HOV - Orsta-Volda - Norway",
            "searchName": "HOV-Orsta-Volda-Hovden-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "HVG",
            "countryCode": "NO",
            "cityCode": "HVG",
            "cityName": "Honningsvag",
            "name": "[HVG] - Valan - HVG - Honningsvag - Norway",
            "searchName": "HVG-Honningsvag-Valan-Honningsv-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "HMR",
            "countryCode": "NO",
            "cityCode": "HMR",
            "cityName": "Hamar",
            "name": "[HMR] - Hamar Airport - HMR - Hamar - Norway",
            "searchName": "HMR-Hamar Airport-Hamar Havalimani-Hamar-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "HAU",
            "countryCode": "NO",
            "cityCode": "HAU",
            "cityName": "Haugesund",
            "name": "[HAU] - Haugesund - HAU - Haugesund - Norway",
            "searchName": "HAU-Haugesund-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "HFT",
            "countryCode": "NO",
            "cityCode": "HFT",
            "cityName": "Hammerfest",
            "name": "[HFT] - Hammerfest - HFT - Hammerfest - Norway",
            "searchName": "HFT-Hammerfest-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "LKL",
            "countryCode": "NO",
            "cityCode": "LKL",
            "cityName": "Lakselv",
            "name": "[LKL] - Banak - LKL - Lakselv - Norway",
            "searchName": "LKL-Lakselv-Banak-Lakselv Banak Apt.-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "LKN",
            "countryCode": "NO",
            "cityCode": "LKN",
            "cityName": "Leknes",
            "name": "[LKN] - Leknes - LKN - Leknes - Norway",
            "searchName": "LKN-Leknes-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "KRS",
            "countryCode": "NO",
            "cityCode": "KRS",
            "cityName": "Kristiansand",
            "name": "[KRS] - Kjevik - KRS - Kristiansand - Norway",
            "searchName": "KRS-Kristiansand-Kjevik-Kjevik Airport-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "KSU",
            "countryCode": "NO",
            "cityCode": "KRS",
            "cityName": "Kristiansand",
            "name": "[KSU] - Kvernberget - KRS - Kristiansand - Norway",
            "searchName": "KSU-KRS-Kristiansund-Kvernberget-Kristiansund Apt-Kristiansand-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKR",
            "countryCode": "NO",
            "cityCode": "KRS",
            "cityName": "Kristiansand",
            "name": "[XKR] - Kristiansand Railway Station - KRS - Kristiansand - Norway",
            "searchName": "XKR-KRS-Kristiansand Railway Station-Kristiansand-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "KKN",
            "countryCode": "NO",
            "cityCode": "KKN",
            "cityName": "Kirkenes",
            "name": "[KKN] - Hoeybuktmoen - KKN - Kirkenes - Norway",
            "searchName": "KKN-Kirkenes-Hoeybuktmoen-Hoeybuktmoen Airport-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "EVE",
            "countryCode": "NO",
            "cityCode": "EVE",
            "cityName": "Harstad-narvik",
            "name": "[EVE] - Evenes - EVE - Harstad-narvik - Norway",
            "searchName": "EVE-Evenes-Evenes Apt-Harstad-Narvik-narvik-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "FDE",
            "countryCode": "NO",
            "cityCode": "FDE",
            "cityName": "Forde",
            "name": "[FDE] - Bringeland - FDE - Forde - Norway",
            "searchName": "FDE-Førde-Bringeland-Bringeland Apt-Forde-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ALF",
            "countryCode": "NO",
            "cityCode": "ALF",
            "cityName": "Alta",
            "name": "[ALF] - Alta - ALF - Alta - Norway",
            "searchName": "ALF-Alta-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "AES",
            "countryCode": "NO",
            "cityCode": "AES",
            "cityName": "Aalesund",
            "name": "[AES] - Vigra - AES - Aalesund - Norway",
            "searchName": "AES-Alesund-Vigra-Aalesund-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ANX",
            "countryCode": "NO",
            "cityCode": "ANX",
            "cityName": "Andenes",
            "name": "[ANX] - AndÃ¸ya Lufthavn, Andenes - ANX - Andenes - Norway",
            "searchName": "ANX-Andoya-Andøya Lufthavn- Andenes-Andenes-AndÃ¸ya Lufthavn, Andenes-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "BVG",
            "countryCode": "NO",
            "cityCode": "BVG",
            "cityName": "Berlevag",
            "name": "[BVG] - Berlevag - BVG - Berlevag - Norway",
            "searchName": "BVG-Berlevag-Berlev-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "BNN",
            "countryCode": "NO",
            "cityCode": "BNN",
            "cityName": "Bronnoysund",
            "name": "[BNN] - Bronnoy - BNN - Bronnoysund - Norway",
            "searchName": "BNN-Bronnoysund-Bronnoy-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "BOO",
            "countryCode": "NO",
            "cityCode": "BOO",
            "cityName": "Bodo",
            "name": "[BOO] - Bodo - BOO - Bodo - Norway",
            "searchName": "BOO-Bodo-Bod-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "BGO",
            "countryCode": "NO",
            "cityCode": "BGO",
            "cityName": "Bergen",
            "name": "[BGO] - Bergen Airport, Flesland - BGO - Bergen - Norway",
            "searchName": "BGO-Bergen-Bergen Airport- Flesland-Flesland-Flesland Apt-Bergen Airport, Flesland-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "QFV",
            "countryCode": "NO",
            "cityCode": "BGO",
            "cityName": "Bergen",
            "name": "[QFV] - Bergen Railway Station - BGO - Bergen - Norway",
            "searchName": "QFV-BGO-Bergen Railway Station-Bergen-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "BDU",
            "countryCode": "NO",
            "cityCode": "BDU",
            "cityName": "Bardufoss",
            "name": "[BDU] - Bardufoss - BDU - Bardufoss - Norway",
            "searchName": "BDU-Bardufoss-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "BJF",
            "countryCode": "NO",
            "cityCode": "BJF",
            "cityName": "Batsfjord",
            "name": "[BJF] - Batsfjord - BJF - Batsfjord - Norway",
            "searchName": "BJF-Batsfjord-Berlev-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "VRY",
            "countryCode": "NO",
            "cityCode": "VRY",
            "cityName": "Vaeroy",
            "name": "[VRY] - Stolport (Not operational) - VRY - Vaeroy - Norway",
            "searchName": "VRY-Væroy-Stolport (Not operational)-Stolport-Vaeroy-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "VAW",
            "countryCode": "NO",
            "cityCode": "VAW",
            "cityName": "Vardoe",
            "name": "[VAW] - Vardoe - VAW - Vardoe - Norway",
            "searchName": "VAW-Vardø-Vardoe-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "VDS",
            "countryCode": "NO",
            "cityCode": "VDS",
            "cityName": "Vadso",
            "name": "[VDS] - Vadso - VDS - Vadso - Norway",
            "searchName": "VDS-Vadsø-Vadso-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "VDB",
            "countryCode": "NO",
            "cityCode": "VDB",
            "cityName": "Fagernes",
            "name": "[VDB] - Valdres Lufthavn Leirin - VDB - Fagernes - Norway",
            "searchName": "VDB-Fagernes-Valdres Lufthavn Leirin-Valdres-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XHT",
            "countryCode": "NO",
            "cityCode": "XHT",
            "cityName": "Arna",
            "name": "[XHT] - Arna Railway Station - XHT - Arna - Norway",
            "searchName": "XHT-Arna Railway Station-Arna-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XHL",
            "countryCode": "NO",
            "cityCode": "XHL",
            "cityName": "Nelaug",
            "name": "[XHL] - Nelaug Railway Station - XHL - Nelaug - Norway",
            "searchName": "XHL-Nelaug Railway Station-Nelaug-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XGH",
            "countryCode": "NO",
            "cityCode": "XGH",
            "cityName": "Flam",
            "name": "[XGH] - Flam Railway Station - XGH - Flam - Norway",
            "searchName": "XGH-Flam Railway Station-Flam-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XGI",
            "countryCode": "NO",
            "cityCode": "XGI",
            "cityName": "Andalsnes",
            "name": "[XGI] - Andalsnes Railway Station - XGI - Andalsnes - Norway",
            "searchName": "XGI-Andalsnes Railway Station-Andalsnes-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XGP",
            "countryCode": "NO",
            "cityCode": "XGP",
            "cityName": "Dombas",
            "name": "[XGP] - Dombas Railway Station - XGP - Dombas - Norway",
            "searchName": "XGP-Dombas Railway Station-Dombas-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XHF",
            "countryCode": "NO",
            "cityCode": "XHF",
            "cityName": "Honefoss",
            "name": "[XHF] - Honefoss Railway Station - XHF - Honefoss - Norway",
            "searchName": "XHF-Honefoss Railway Station-Honefoss-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XGD",
            "countryCode": "NO",
            "cityCode": "XGD",
            "cityName": "Arendal",
            "name": "[XGD] - Arendal Railway Station - XGD - Arendal - Norway",
            "searchName": "XGD-Arendal Railway Station-Arendal-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XGS",
            "countryCode": "NO",
            "cityCode": "XGS",
            "cityName": "Gjerstad",
            "name": "[XGS] - Gjerstad Railway Station - XGS - Gjerstad - Norway",
            "searchName": "XGS-Gjerstad Railway Station-Gjerstad-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XGU",
            "countryCode": "NO",
            "cityCode": "XGU",
            "cityName": "Asker",
            "name": "[XGU] - Asker Railway Station - XGU - Asker - Norway",
            "searchName": "XGU-Asker Railway Station-Asker-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XOQ",
            "countryCode": "NO",
            "cityCode": "XOQ",
            "cityName": "Sira",
            "name": "[XOQ] - Sira Railway Station - XOQ - Sira - Norway",
            "searchName": "XOQ-Sira Railway Station-Sira-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XOR",
            "countryCode": "NO",
            "cityCode": "XOR",
            "cityName": "Otta",
            "name": "[XOR] - Otta Railway Station - XOR - Otta - Norway",
            "searchName": "XOR-Otta Railway Station-Otta-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XRD",
            "countryCode": "NO",
            "cityCode": "XRD",
            "cityName": "Egersund",
            "name": "[XRD] - Egersund Railway Station - XRD - Egersund - Norway",
            "searchName": "XRD-Egersund Railway Station-Egersund-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XVK",
            "countryCode": "NO",
            "cityCode": "XVK",
            "cityName": "Voss",
            "name": "[XVK] - Voss Railway Station - XVK - Voss - Norway",
            "searchName": "XVK-Voss Railway Station-Voss-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUG",
            "countryCode": "NO",
            "cityCode": "XUG",
            "cityName": "Holmestrand",
            "name": "[XUG] - Holmestrand Railway Station - XUG - Holmestrand - Norway",
            "searchName": "XUG-Holmestrand Railway Station-Holmestrand-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUH",
            "countryCode": "NO",
            "cityCode": "XUH",
            "cityName": "Levanger",
            "name": "[XUH] - Levanger Railway Station - XUH - Levanger - Norway",
            "searchName": "XUH-Levanger Railway Station-Levanger-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUI",
            "countryCode": "NO",
            "cityCode": "XUI",
            "cityName": "Lysaker",
            "name": "[XUI] - Lysaker Railway Station - XUI - Lysaker - Norway",
            "searchName": "XUI-Lysaker Railway Station-Lysaker-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUJ",
            "countryCode": "NO",
            "cityCode": "XUJ",
            "cityName": "Moelv",
            "name": "[XUJ] - Moelv Railway Station - XUJ - Moelv - Norway",
            "searchName": "XUJ-Moelv Railway Station-Moelv-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUL",
            "countryCode": "NO",
            "cityCode": "XUL",
            "cityName": "Nesbyen",
            "name": "[XUL] - Nesbyen Railway Station - XUL - Nesbyen - Norway",
            "searchName": "XUL-Nesbyen Railway Station-Nesbyen-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUM",
            "countryCode": "NO",
            "cityCode": "XUM",
            "cityName": "Neslandsvatn",
            "name": "[XUM] - Neslandsvatn Railway Station - XUM - Neslandsvatn - Norway",
            "searchName": "XUM-Neslandsvatn Railway Station-Neslandsvatn-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUO",
            "countryCode": "NO",
            "cityCode": "XUO",
            "cityName": "Nordagutu",
            "name": "[XUO] - Nordagutu Railway Station - XUO - Nordagutu - Norway",
            "searchName": "XUO-Nordagutu Railway Station-Nordagutu-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUQ",
            "countryCode": "NO",
            "cityCode": "XUQ",
            "cityName": "Ringebu",
            "name": "[XUQ] - Ringebu Railway Station - XUQ - Ringebu - Norway",
            "searchName": "XUQ-Ringebu Railway Station-Ringebu-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUR",
            "countryCode": "NO",
            "cityCode": "XUR",
            "cityName": "Skoppum",
            "name": "[XUR] - Skoppum Railway Station - XUR - Skoppum - Norway",
            "searchName": "XUR-Skoppum Railway Station-Skoppum-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUS",
            "countryCode": "NO",
            "cityCode": "XUS",
            "cityName": "Snartemo",
            "name": "[XUS] - Snartemo Railway Station - XUS - Snartemo - Norway",
            "searchName": "XUS-Snartemo Railway Station-Snartemo-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUU",
            "countryCode": "NO",
            "cityCode": "XUU",
            "cityName": "Stjordal",
            "name": "[XUU] - Stjordal Railway Station - XUU - Stjordal - Norway",
            "searchName": "XUU-Stjordal Railway Station-Stjordal-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUV",
            "countryCode": "NO",
            "cityCode": "XUV",
            "cityName": "Storekvina",
            "name": "[XUV] - Storekvina Railway - XUV - Storekvina - Norway",
            "searchName": "XUV-Storekvina Railway-Storekvina-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUW",
            "countryCode": "NO",
            "cityCode": "XUW",
            "cityName": "Storen",
            "name": "[XUW] - Storen Railway - XUW - Storen - Norway",
            "searchName": "XUW-Storen Railway-Storen-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUX",
            "countryCode": "NO",
            "cityCode": "XUX",
            "cityName": "Ustaoset",
            "name": "[XUX] - Ustaoset Railway Station - XUX - Ustaoset - Norway",
            "searchName": "XUX-Ustaoset Railway Station-Ustaoset-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUB",
            "countryCode": "NO",
            "cityCode": "XUB",
            "cityName": "Berkak",
            "name": "[XUB] - Berkak Railway Station - XUB - Berkak - Norway",
            "searchName": "XUB-Berkak Railway Station-Berkak-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUC",
            "countryCode": "NO",
            "cityCode": "XUC",
            "cityName": "Elverum",
            "name": "[XUC] - Elverum Railway Station - XUC - Elverum - Norway",
            "searchName": "XUC-Elverum Railway Station-Elverum-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XUE",
            "countryCode": "NO",
            "cityCode": "XUE",
            "cityName": "Heimdal",
            "name": "[XUE] - Heimdal Railway Station - XUE - Heimdal - Norway",
            "searchName": "XUE-Heimdal Railway Station-Heimdal-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKI",
            "countryCode": "NO",
            "cityCode": "XKI",
            "cityName": "Lillestrom",
            "name": "[XKI] - Lillestrom Railway Station - XKI - Lillestrom - Norway",
            "searchName": "XKI-Lillestrom Railway Station-Lillestrom-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKJ",
            "countryCode": "NO",
            "cityCode": "XKJ",
            "cityName": "Steinkjer",
            "name": "[XKJ] - Steinkjer Railway Station - XKJ - Steinkjer - Norway",
            "searchName": "XKJ-Steinkjer Railway Station-Steinkjer-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKK",
            "countryCode": "NO",
            "cityCode": "XKK",
            "cityName": "Larvik",
            "name": "[XKK] - Larvik Railway Station - XKK - Larvik - Norway",
            "searchName": "XKK-Larvik Railway Station-Larvik-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKM",
            "countryCode": "NO",
            "cityCode": "XKM",
            "cityName": "Moss",
            "name": "[XKM] - Moss Railway Station - XKM - Moss - Norway",
            "searchName": "XKM-Moss Railway Station-Moss-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKN",
            "countryCode": "NO",
            "cityCode": "XKN",
            "cityName": "Finse",
            "name": "[XKN] - Finse Railway Station - XKN - Finse - Norway",
            "searchName": "XKN-Finse Railway Station-Finse-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKP",
            "countryCode": "NO",
            "cityCode": "XKP",
            "cityName": "Porsgrunn",
            "name": "[XKP] - Porsgrunn Railway Station - XKP - Porsgrunn - Norway",
            "searchName": "XKP-Porsgrunn Railway Station-Porsgrunn-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKQ",
            "countryCode": "NO",
            "cityCode": "XKQ",
            "cityName": "Sarpsborg",
            "name": "[XKQ] - Sarpsborg Railway Station - XKQ - Sarpsborg - Norway",
            "searchName": "XKQ-Sarpsborg Railway Station-Sarpsborg-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKW",
            "countryCode": "NO",
            "cityCode": "XKW",
            "cityName": "Tonsberg",
            "name": "[XKW] - Tonsberg Railway Station - XKW - Tonsberg - Norway",
            "searchName": "XKW-Tonsberg Railway Station-Tonsberg-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKZ",
            "countryCode": "NO",
            "cityCode": "XKZ",
            "cityName": "Vinstra",
            "name": "[XKZ] - Vinstra Railway Station - XKZ - Vinstra - Norway",
            "searchName": "XKZ-Vinstra Railway Station-Vinstra-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XJA",
            "countryCode": "NO",
            "cityCode": "XJA",
            "cityName": "Alvdal",
            "name": "[XJA] - Alvdal Railway Station - XJA - Alvdal - Norway",
            "searchName": "XJA-Alvdal Railway Station-Alvdal-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKB",
            "countryCode": "NO",
            "cityCode": "XKB",
            "cityName": "Kongsberg",
            "name": "[XKB] - Kongsberg Railway Station - XKB - Kongsberg - Norway",
            "searchName": "XKB-Kongsberg Railway Station-Kongsberg-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKC",
            "countryCode": "NO",
            "cityCode": "XKC",
            "cityName": "Sandnes",
            "name": "[XKC] - Sandnes Railway - XKC - Sandnes - Norway",
            "searchName": "XKC-Sandnes Railway-Sandnes-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKD",
            "countryCode": "NO",
            "cityCode": "XKD",
            "cityName": "Halden",
            "name": "[XKD] - Halden Railway Station - XKD - Halden - Norway",
            "searchName": "XKD-Halden Railway Station-Halden-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKE",
            "countryCode": "NO",
            "cityCode": "XKE",
            "cityName": "Rena",
            "name": "[XKE] - Rena Railway Station - XKE - Rena - Norway",
            "searchName": "XKE-Rena Railway Station-Rena-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKF",
            "countryCode": "NO",
            "cityCode": "XKF",
            "cityName": "Fredrikstad",
            "name": "[XKF] - Fredrikstad Railway Station - XKF - Fredrikstad - Norway",
            "searchName": "XKF-Fredrikstad Railway Station-Fredrikstad-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XKG",
            "countryCode": "NO",
            "cityCode": "XKG",
            "cityName": "Grong",
            "name": "[XKG] - Grong Railway Station - XKG - Grong - Norway",
            "searchName": "XKG-Grong Railway Station-Grong-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XND",
            "countryCode": "NO",
            "cityCode": "XND",
            "cityName": "Drammen",
            "name": "[XND] - Drammen Railway Station - XND - Drammen - Norway",
            "searchName": "XND-Drammen Railway Station-Drammen-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XOB",
            "countryCode": "NO",
            "cityCode": "XOB",
            "cityName": "Bryne",
            "name": "[XOB] - Bryne Railway Station - XOB - Bryne - Norway",
            "searchName": "XOB-Bryne Railway Station-Bryne-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XOD",
            "countryCode": "NO",
            "cityCode": "XOD",
            "cityName": "Oppdal",
            "name": "[XOD] - Oppdal Railway Station - XOD - Oppdal - Norway",
            "searchName": "XOD-Oppdal Railway Station-Oppdal-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XOL",
            "countryCode": "NO",
            "cityCode": "XOL",
            "cityName": "Myrdal",
            "name": "[XOL] - Myrdal Railway Station - XOL - Myrdal - Norway",
            "searchName": "XOL-Myrdal Railway Station-Myrdal-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XXR",
            "countryCode": "NO",
            "cityCode": "XXR",
            "cityName": "Al",
            "name": "[XXR] - Al Railway Station - XXR - Al - Norway",
            "searchName": "XXR-Al Railway Station-Al-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XXE",
            "countryCode": "NO",
            "cityCode": "XXE",
            "cityName": "Vennesla",
            "name": "[XXE] - Vennesla Railway Station - XXE - Vennesla - Norway",
            "searchName": "XXE-Vennesla Railway Station-Vennesla-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XXG",
            "countryCode": "NO",
            "cityCode": "XXG",
            "cityName": "Verdal",
            "name": "[XXG] - Verdal Railway Station - XXG - Verdal - Norway",
            "searchName": "XXG-Verdal Railway Station-Verdal-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XXC",
            "countryCode": "NO",
            "cityCode": "XXC",
            "cityName": "Bo",
            "name": "[XXC] - Bo Railway Station - XXC - Bo - Norway",
            "searchName": "XXC-Bo Railway Station-Bo-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XXL",
            "countryCode": "NO",
            "cityCode": "XXL",
            "cityName": "Lillehammer",
            "name": "[XXL] - Lillehammer Railway Station - XXL - Lillehammer - Norway",
            "searchName": "XXL-Lillehammer Railway Station-Lillehammer-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XZD",
            "countryCode": "NO",
            "cityCode": "XZD",
            "cityName": "Kongsvinger",
            "name": "[XZD] - Kongsvinger Railway Station - XZD - Kongsvinger - Norway",
            "searchName": "XZD-Kongsvinger Railway Station-Kongsvinger-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "SKE",
            "countryCode": "NO",
            "cityCode": "SKE",
            "cityName": "Skien",
            "name": "[SKE] - Skien - SKE - Skien - Norway",
            "searchName": "SKE-Skein-Skien-Skien Havalimani-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "SKN",
            "countryCode": "NO",
            "cityCode": "SKN",
            "cityName": "Stokmarknes",
            "name": "[SKN] - Skagen - SKN - Stokmarknes - Norway",
            "searchName": "SKN-Stokmarknes-Skagen-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "SDN",
            "countryCode": "NO",
            "cityCode": "SDN",
            "cityName": "Sandane",
            "name": "[SDN] - Sandane - SDN - Sandane - Norway",
            "searchName": "SDN-Sandane-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "SVJ",
            "countryCode": "NO",
            "cityCode": "SVJ",
            "cityName": "Svolvaer",
            "name": "[SVJ] - Helle - SVJ - Svolvaer - Norway",
            "searchName": "SVJ-Svolvær-Helle-Svolvar Apt-Svolvaer-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "SVG",
            "countryCode": "NO",
            "cityCode": "SVG",
            "cityName": "Stavanger",
            "name": "[SVG] - Sola - SVG - Stavanger - Norway",
            "searchName": "SVG-Stavanger-Sola-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "ZWC",
            "countryCode": "NO",
            "cityCode": "SVG",
            "cityName": "Stavanger",
            "name": "[ZWC] - Stavanger Railway Station - SVG - Stavanger - Norway",
            "searchName": "ZWC-SVG-Stavanger Railway Station-Stavanger-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "SRP",
            "countryCode": "NO",
            "cityCode": "SRP",
            "cityName": "Stord",
            "name": "[SRP] - Stord Airport - SRP - Stord - Norway",
            "searchName": "SRP-Stord Airport-Stord Havalimani-Stord-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "SSJ",
            "countryCode": "NO",
            "cityCode": "SSJ",
            "cityName": "Sandnessjoen",
            "name": "[SSJ] - Stokka - SSJ - Sandnessjoen - Norway",
            "searchName": "SSJ-Sandnessjøn-Stokka-Stokka Airport-Sandnessjoen-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "SOJ",
            "countryCode": "NO",
            "cityCode": "SOJ",
            "cityName": "Sorkjosen",
            "name": "[SOJ] - Sorkjosen - SOJ - Sorkjosen - Norway",
            "searchName": "SOJ-Sørkjosen-Sorkjosen-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "SOG",
            "countryCode": "NO",
            "cityCode": "SOG",
            "cityName": "Sogndal",
            "name": "[SOG] - Haukasen - SOG - Sogndal - Norway",
            "searchName": "SOG-Sogndal-Haukasen-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "TOS",
            "countryCode": "NO",
            "cityCode": "TOS",
            "cityName": "Tromso",
            "name": "[TOS] - Tromso/langnes - TOS - Tromso - Norway",
            "searchName": "TOS-Tromsø-Tromso/langnes-Tromso/Langnes-Troms-Tromso-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "TRD",
            "countryCode": "NO",
            "cityCode": "TRD",
            "cityName": "Trondheim",
            "name": "[TRD] - VÃ¦rnes - TRD - Trondheim - Norway",
            "searchName": "TRD-Trondheim-Værnes-Vaernes-VÃ¦rnes-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "XZT",
            "countryCode": "NO",
            "cityCode": "TRD",
            "cityName": "Trondheim",
            "name": "[XZT] - Trondheim S Rail Station - TRD - Trondheim - Norway",
            "searchName": "XZT-TRD-Trondheim S Rail Station-Trondheim-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "QFK",
            "countryCode": "NO",
            "cityCode": "QFK",
            "cityName": "Selje",
            "name": "[QFK] - Harbour - QFK - Selje - Norway",
            "searchName": "QFK-Harbour-Selje-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "QFQ",
            "countryCode": "NO",
            "cityCode": "QFQ",
            "cityName": "Maloy",
            "name": "[QFQ] - Harbour - QFQ - Maloy - Norway",
            "searchName": "QFQ-Harbour-Harbour Havalimani-Maloy-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "RVK",
            "countryCode": "NO",
            "cityCode": "RVK",
            "cityName": "Roervik",
            "name": "[RVK] - Ryumsjoen - RVK - Roervik - Norway",
            "searchName": "RVK-Rorvik-Ryumsjoen-Ryumsjoen Aprt-Roervik-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "RRS",
            "countryCode": "NO",
            "cityCode": "RRS",
            "cityName": "Roros",
            "name": "[RRS] - Roros - RRS - Roros - Norway",
            "searchName": "RRS-Roros-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "RET",
            "countryCode": "NO",
            "cityCode": "RET",
            "cityName": "Rost",
            "name": "[RET] - Stolport - RET - Rost - Norway",
            "searchName": "RET-Rost-Stolport-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "DEE",
            "countryCode": "RU",
            "cityCode": "DEE",
            "cityName": "Yuzhno-Kurilsk",
            "name": "[DEE] - Mendeleyevo - DEE - Yuzhno-Kurilsk - Russia",
            "searchName": "DEE-Mendeleyevo-Deer Harbor Seaplane-Red Deer-Yuzhno-Kurilsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "DKS",
            "countryCode": "RU",
            "cityCode": "DKS",
            "cityName": "Dikson",
            "name": "[DKS] - Dikson - DKS - Dikson - Russia",
            "searchName": "DKS-Dikson-Dikson Havalimani-Dakar-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ERG",
            "countryCode": "RU",
            "cityCode": "ERG",
            "cityName": "Yerbogachen",
            "name": "[ERG] - Yerbogachen - ERG - Yerbogachen - Russia",
            "searchName": "ERG-Yerbogachen-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ESL",
            "countryCode": "RU",
            "cityCode": "ESL",
            "cityName": "Elista",
            "name": "[ESL] - Elista - ESL - Elista - Russia",
            "searchName": "ESL-Elista-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "EZV",
            "countryCode": "RU",
            "cityCode": "EZV",
            "cityName": "Berezovo",
            "name": "[EZV] - Berezovo - EZV - Berezovo - Russia",
            "searchName": "EZV-Berezovo-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "EYK",
            "countryCode": "RU",
            "cityCode": "EYK",
            "cityName": "Beloyarsky",
            "name": "[EYK] - Beloyarsky - EYK - Beloyarsky - Russia",
            "searchName": "EYK-Beloyarsky-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "EKS",
            "countryCode": "RU",
            "cityCode": "EKS",
            "cityName": "Shakhtersk",
            "name": "[EKS] - Shakhtersk - EKS - Shakhtersk - Russia",
            "searchName": "EKS-Shakhtersk-Shakhtyorsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "EIE",
            "countryCode": "RU",
            "cityCode": "EIE",
            "cityName": "Eniseysk",
            "name": "[EIE] - Eniseysk - EIE - Eniseysk - Russia",
            "searchName": "EIE-Eniseysk-Yeniseysk Havalimani-Eisenach-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "EIK",
            "countryCode": "RU",
            "cityCode": "EIK",
            "cityName": "Yeysk",
            "name": "[EIK] - Yeysk - EIK - Yeysk - Russia",
            "searchName": "EIK-Yeysk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "EGO",
            "countryCode": "RU",
            "cityCode": "EGO",
            "cityName": "Belgorod",
            "name": "[EGO] - Belgorod - EGO - Belgorod - Russia",
            "searchName": "EGO-Belgorod-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "INA",
            "countryCode": "RU",
            "cityCode": "INA",
            "cityName": "Inta",
            "name": "[INA] - Inta - INA - Inta - Russia",
            "searchName": "INA-Inta-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "IJK",
            "countryCode": "RU",
            "cityCode": "IJK",
            "cityName": "Izhevsk",
            "name": "[IJK] - Izhevsk - IJK - Izhevsk - Russia",
            "searchName": "IJK-Izhevsk-Ijevsk Havalimani-Ischewsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "IGT",
            "countryCode": "RU",
            "cityCode": "IGT",
            "cityName": "Nazran",
            "name": "[IGT] - Sleptsovskaya - IGT - Nazran - Russia",
            "searchName": "IGT-Sleptsovskaya-Nazran-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "IKS",
            "countryCode": "RU",
            "cityCode": "IKS",
            "cityName": "Tiksi",
            "name": "[IKS] - Tiksi - IKS - Tiksi - Russia",
            "searchName": "IKS-Tiksi-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ABA",
            "countryCode": "RU",
            "cityCode": "ABA",
            "cityName": "Abakan",
            "name": "[ABA] - Abakan - ABA - Abakan - Russia",
            "searchName": "ABA-Abakan-Abak-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "THX",
            "countryCode": "RU",
            "cityCode": "THX",
            "cityName": "Turukhansk",
            "name": "[THX] - Turukhansk - THX - Turukhansk - Russia",
            "searchName": "THX-Turukhansk-Turukhansk\u00A0 Havalimani-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "BCX",
            "countryCode": "RU",
            "cityCode": "BCX",
            "cityName": "Beloreck",
            "name": "[BCX] - Beloreck - BCX - Beloreck - Russia",
            "searchName": "BCX-Beloreck-Beloreck  Havalimani-Boca Raton-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "IAR",
            "countryCode": "RU",
            "cityCode": "IAR",
            "cityName": "Yaroslavl",
            "name": "[IAR] - Yaroslavl - IAR - Yaroslavl - Russia",
            "searchName": "IAR-Yaroslavl-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "IAA",
            "countryCode": "RU",
            "cityCode": "IAA",
            "cityName": "Igarka",
            "name": "[IAA] - Igarka - IAA - Igarka - Russia",
            "searchName": "IAA-Igarka-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "IWA",
            "countryCode": "RU",
            "cityCode": "IWA",
            "cityName": "Ivanova",
            "name": "[IWA] - Ivanova - IWA - Ivanova - Russia",
            "searchName": "IWA-Ivanova-Ivanovo Havalimani-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "IRM",
            "countryCode": "RU",
            "cityCode": "IRM",
            "cityName": "Igrim",
            "name": "[IRM] - Igrim - IRM - Igrim - Russia",
            "searchName": "IRM-Igrim-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "HTG",
            "countryCode": "RU",
            "cityCode": "HTG",
            "cityName": "Hatanga",
            "name": "[HTG] - Hatanga - HTG - Hatanga - Russia",
            "searchName": "HTG-Hatanga-Hatanga Havalimani-Hatfield-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "HMA",
            "countryCode": "RU",
            "cityCode": "HMA",
            "cityName": "Khanty-Mansiysk",
            "name": "[HMA] - Khanty-Mansiysk - HMA - Khanty-Mansiysk - Russia",
            "searchName": "HMA-Khanty-Mansiysk-Khanty Mansiysk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "GDX",
            "countryCode": "RU",
            "cityCode": "GDX",
            "cityName": "Magadan",
            "name": "[GDX] - Magadan - GDX - Magadan - Russia",
            "searchName": "GDX-Magadan-Sokol Apt.-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "GDZ",
            "countryCode": "RU",
            "cityCode": "GDZ",
            "cityName": "Gelendzik",
            "name": "[GDZ] - Gelendzik - GDZ - Gelendzik - Russia",
            "searchName": "GDZ-Gelendschik-Gelendzik-Gelendzhik-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "GDG",
            "countryCode": "RU",
            "cityCode": "GDG",
            "cityName": "Magdagachi",
            "name": "[GDG] - Magdagachi - GDG - Magdagachi - Russia",
            "searchName": "GDG-Magdagachi-Magdagachi Havalimani-Gode-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "GRV",
            "countryCode": "RU",
            "cityCode": "GRV",
            "cityName": "Groznyj",
            "name": "[GRV] - Groznyj - GRV - Groznyj - Russia",
            "searchName": "GRV-Grosny-Groznyj-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "GVN",
            "countryCode": "RU",
            "cityCode": "GVN",
            "cityName": "Sovetskaya Gavan",
            "name": "[GVN] - May-Gatka - GVN - Sovetskaya Gavan - Russia",
            "searchName": "GVN-May-Gatka-Sovetskaya Gavan-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "LDG",
            "countryCode": "RU",
            "cityCode": "LDG",
            "cityName": "Leshukonskoye",
            "name": "[LDG] - Leshukonskoye - LDG - Leshukonskoye - Russia",
            "searchName": "LDG-Leshukonskoye-Leshukonskoye Havalimani-Lourdes-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ZLK",
            "countryCode": "RU",
            "cityCode": "LED",
            "cityName": "Saint Petersburg",
            "name": "[ZLK] - Moscovskiy Railway Stn - LED - Saint Petersburg - Russia",
            "searchName": "ZLK-LED-Moscovskiy Railway Stn-Sankt Petersburg-Saint Petersburg-San Petersburgo-Saint-P-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "QCA",
            "countryCode": "SA",
            "cityCode": "QCA",
            "cityName": "Makkah",
            "name": "[QCA] - Makkah Bus Station - QCA - Makkah - Saudi Arabia",
            "searchName": "QCA-Makkah Bus Station-Makkah-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "RAH",
            "countryCode": "SA",
            "cityCode": "RAH",
            "cityName": "Rafha",
            "name": "[RAH] - Rafha - RAH - Rafha - Saudi Arabia",
            "searchName": "RAH-Rafha-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "RAE",
            "countryCode": "SA",
            "cityCode": "RAE",
            "cityName": "Arar",
            "name": "[RAE] - Arar - RAE - Arar - Saudi Arabia",
            "searchName": "RAE-Arar-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "MED",
            "countryCode": "SA",
            "cityCode": "MED",
            "cityName": "Madinah",
            "name": "[MED] - Mohammad Bin Abdulaziz - MED - Madinah - Saudi Arabia",
            "searchName": "MED-Medina-Mohammad Bin Abdulaziz-Mohammad Abdulaziz-Madinah-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "TIF",
            "countryCode": "SA",
            "cityCode": "TIF",
            "cityName": "Taif",
            "name": "[TIF] - Taif - TIF - Taif - Saudi Arabia",
            "searchName": "TIF-Taif-Ta-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "TUI",
            "countryCode": "SA",
            "cityCode": "TUI",
            "cityName": "Turaif",
            "name": "[TUI] - Turaif - TUI - Turaif - Saudi Arabia",
            "searchName": "TUI-Turaif-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "TUU",
            "countryCode": "SA",
            "cityCode": "TUU",
            "cityName": "Tabuk",
            "name": "[TUU] - Tabuk - TUU - Tabuk - Saudi Arabia",
            "searchName": "TUU-Tabuk-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "SLF",
            "countryCode": "SA",
            "cityCode": "SLF",
            "cityName": "Sulayel",
            "name": "[SLF] - Sulayel - SLF - Sulayel - Saudi Arabia",
            "searchName": "SLF-Sulayel-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "SHW",
            "countryCode": "SA",
            "cityCode": "SHW",
            "cityName": "Sharurah",
            "name": "[SHW] - Sharurah - SHW - Sharurah - Saudi Arabia",
            "searchName": "SHW-Sharurah-Sharurah Airport-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "WAE",
            "countryCode": "SA",
            "cityCode": "WAE",
            "cityName": "Wadi Ad Dawasir",
            "name": "[WAE] - Wadi Ad Dawasir - WAE - Wadi Ad Dawasir - Saudi Arabia",
            "searchName": "WAE-Wadi Ad Dawasir-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "ULH",
            "countryCode": "SA",
            "cityCode": "ULH",
            "cityName": "Al Ula",
            "name": "[ULH] - Majeed Bin Abdulaziz - ULH - Al Ula - Saudi Arabia",
            "searchName": "ULH-Majeed Bin Abdulaziz-Al `Ula-Al Ula-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "URY",
            "countryCode": "SA",
            "cityCode": "URY",
            "cityName": "Gurayat",
            "name": "[URY] - Gurayat - URY - Gurayat - Saudi Arabia",
            "searchName": "URY-Gurayat-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "UZH",
            "countryCode": "SA",
            "cityCode": "UZH",
            "cityName": "Unayzah",
            "name": "[UZH] - Unayzah - UZH - Unayzah - Saudi Arabia",
            "searchName": "UZH-Unayzah-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "BHH",
            "countryCode": "SA",
            "cityCode": "BHH",
            "cityName": "Bisha",
            "name": "[BHH] - Bisha - BHH - Bisha - Saudi Arabia",
            "searchName": "BHH-Bisha-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "ABT",
            "countryCode": "SA",
            "cityCode": "ABT",
            "cityName": "Al-Baha",
            "name": "[ABT] - Al-Aqiq - ABT - Al-Baha - Saudi Arabia",
            "searchName": "ABT-Al Baha-Al-Aqiq-Baha-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "AKH",
            "countryCode": "SA",
            "cityCode": "AKH",
            "cityName": "Al Kharj",
            "name": "[AKH] - Prince Sultan Air Base - AKH - Al Kharj - Saudi Arabia",
            "searchName": "AKH-Prince Sultan Air Base-Al Kharj-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "AHB",
            "countryCode": "SA",
            "cityCode": "AHB",
            "cityName": "Abha",
            "name": "[AHB] - Abha - AHB - Abha - Saudi Arabia",
            "searchName": "AHB-Abha-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "AJF",
            "countryCode": "SA",
            "cityCode": "AJF",
            "cityName": "Sakaka Al Jouf",
            "name": "[AJF] - Jouf - AJF - Sakaka Al Jouf - Saudi Arabia",
            "searchName": "AJF-Jouf-Sakaka Al Jouf-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "AQI",
            "countryCode": "SA",
            "cityCode": "AQI",
            "cityName": "Qaisumah",
            "name": "[AQI] - Qaisumah - AQI - Qaisumah - Saudi Arabia",
            "searchName": "AQI-Qaisumah-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "EJH",
            "countryCode": "SA",
            "cityCode": "EJH",
            "cityName": "Wedjh",
            "name": "[EJH] - Wedjh - EJH - Wedjh - Saudi Arabia",
            "searchName": "EJH-Wedjh-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "ELQ",
            "countryCode": "SA",
            "cityCode": "ELQ",
            "cityName": "Buraidah",
            "name": "[ELQ] - Gassim Regional Airport - ELQ - Buraidah - Saudi Arabia",
            "searchName": "ELQ-Gassim-Gassim Regional Airport-Buraidah-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "DMS",
            "countryCode": "SA",
            "cityCode": "DMM",
            "cityName": "Dammam",
            "name": "[DMS] - SABTCO Bus Station - DMM - Dammam - Saudi Arabia",
            "searchName": "DMS-DMM-SABTCO Bus Station-Dammam-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "KYZ",
            "countryCode": "RU",
            "cityCode": "KYZ",
            "cityName": "Kyzyl",
            "name": "[KYZ] - Kyzyl - KYZ - Kyzyl - Russia",
            "searchName": "KYZ-Kyzyl-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KSZ",
            "countryCode": "RU",
            "cityCode": "KSZ",
            "cityName": "Kotlas",
            "name": "[KSZ] - Kotlas - KSZ - Kotlas - Russia",
            "searchName": "KSZ-Kotlas-Kotlas Havalimani-Kars-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KMW",
            "countryCode": "RU",
            "cityCode": "KMW",
            "cityName": "Kostroma",
            "name": "[KMW] - Sokerkino - KMW - Kostroma - Russia",
            "searchName": "KMW-Sokerkino-Kostroma-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KGP",
            "countryCode": "RU",
            "cityCode": "KGP",
            "cityName": "Kogalym",
            "name": "[KGP] - Kogalym International - KGP - Kogalym - Russia",
            "searchName": "KGP-Kogalym-Kogalym International-Kogalym Intl-Kogalym Intl Havalimani-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "LNX",
            "countryCode": "RU",
            "cityCode": "LNX",
            "cityName": "Smolensk",
            "name": "[LNX] - Smolensk - LNX - Smolensk - Russia",
            "searchName": "LNX-Smolensk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "LPK",
            "countryCode": "RU",
            "cityCode": "LPK",
            "cityName": "Lipetsk",
            "name": "[LPK] - Lipetsk - LPK - Lipetsk - Russia",
            "searchName": "LPK-Lipezk-Lipetsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KRO",
            "countryCode": "RU",
            "cityCode": "KRO",
            "cityName": "Kurgan",
            "name": "[KRO] - Kurgan - KRO - Kurgan - Russia",
            "searchName": "KRO-Kurgan-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KVK",
            "countryCode": "RU",
            "cityCode": "KVK",
            "cityName": "Kirovsk",
            "name": "[KVK] - Kirovsk - KVK - Kirovsk - Russia",
            "searchName": "KVK-Kirovsk-Kirovsk Havalimani-Kavieng-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KVX",
            "countryCode": "RU",
            "cityCode": "KVX",
            "cityName": "Kirov",
            "name": "[KVX] - Kirov - KVX - Kirov - Russia",
            "searchName": "KVX-Kirov-Kirov Havalimani-Kirow-K-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KVR",
            "countryCode": "RU",
            "cityCode": "KVR",
            "cityName": "Kavalerovo",
            "name": "[KVR] - Kavalerovo - KVR - Kavalerovo - Russia",
            "searchName": "KVR-Kavalerovo-Kristiansund Kvrnberget-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "SKX",
            "countryCode": "RU",
            "cityCode": "SKX",
            "cityName": "Saransk",
            "name": "[SKX] - Saransk - SKX - Saransk - Russia",
            "searchName": "SKX-Saransk-Saransk Havalimani-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "SWT",
            "countryCode": "RU",
            "cityCode": "SWT",
            "cityName": "Strzhewoi",
            "name": "[SWT] - Strzhewoi - SWT - Strzhewoi - Russia",
            "searchName": "SWT-Strzhewoi-Strzhewoi Havalimani-Swansea-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "SCW",
            "countryCode": "RU",
            "cityCode": "SCW",
            "cityName": "Syktyvkar",
            "name": "[SCW] - Syktyvkar - SCW - Syktyvkar - Russia",
            "searchName": "SCW-Syktywkar-Syktyvkar-Syktyvkar Airport-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "UUA",
            "countryCode": "RU",
            "cityCode": "UUA",
            "cityName": "Bugulma",
            "name": "[UUA] - Bugulma - UUA - Bugulma - Russia",
            "searchName": "UUA-Bugulma-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "VOZ",
            "countryCode": "RU",
            "cityCode": "VOZ",
            "cityName": "Voronezh",
            "name": "[VOZ] - Chertovitskoye - VOZ - Voronezh - Russia",
            "searchName": "VOZ-Woronesch-Chertovitskoye-Voronezh-Voronej-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "VUS",
            "countryCode": "RU",
            "cityCode": "VUS",
            "cityName": "Velikij Ustyug",
            "name": "[VUS] - Velikij Ustyug - VUS - Velikij Ustyug - Russia",
            "searchName": "VUS-Velikij Ustyug-Veliky Ustyug Havalimani-Valleduper-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "TQL",
            "countryCode": "RU",
            "cityCode": "TQL",
            "cityName": "Tarko-Sale",
            "name": "[TQL] - Tarko-Sale - TQL - Tarko-Sale - Russia",
            "searchName": "TQL-Tarko-Sale-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "TOX",
            "countryCode": "RU",
            "cityCode": "TOX",
            "cityName": "Tobolsk",
            "name": "[TOX] - Tobolsk - TOX - Tobolsk - Russia",
            "searchName": "TOX-Tobolsk-Tobolsk Havalimani-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "TBW",
            "countryCode": "RU",
            "cityCode": "TBW",
            "cityName": "Tambov",
            "name": "[TBW] - Tambov - TBW - Tambov - Russia",
            "searchName": "TBW-Tambow-Tambov-Tambov Havalimani-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "TGP",
            "countryCode": "RU",
            "cityCode": "TGP",
            "cityName": "Bor",
            "name": "[TGP] - Podkamennaya Tunguska - TGP - Bor - Russia",
            "searchName": "TGP-Podkamennaya Tunguska-Bor-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "TGK",
            "countryCode": "RU",
            "cityCode": "TGK",
            "cityName": "Taganrog",
            "name": "[TGK] - Taganrog - TGK - Taganrog - Russia",
            "searchName": "TGK-Taganrog-Taganrog Yuzhny-Taganrog Yuzhny Havalimani-Taganrong-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "STW",
            "countryCode": "RU",
            "cityCode": "STW",
            "cityName": "Stavropol",
            "name": "[STW] - Stavropol - STW - Stavropol - Russia",
            "searchName": "STW-Stawropol-Stavropol-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "SLY",
            "countryCode": "RU",
            "cityCode": "SLY",
            "cityName": "Salekhard",
            "name": "[SLY] - Salekhard - SLY - Salekhard - Russia",
            "searchName": "SLY-Salekhard-Salechard-Salehard-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "TYA",
            "countryCode": "RU",
            "cityCode": "TYA",
            "cityName": "Tula",
            "name": "[TYA] - Tula - TYA - Tula - Russia",
            "searchName": "TYA-Tula-Tula Havalimani-Tunxi-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "TYD",
            "countryCode": "RU",
            "cityCode": "TYD",
            "cityName": "Tynda",
            "name": "[TYD] - Tynda - TYD - Tynda - Russia",
            "searchName": "TYD-Tynda-Tynda Havalimani-Tunxi-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "TLY",
            "countryCode": "RU",
            "cityCode": "TLY",
            "cityName": "Plastun",
            "name": "[TLY] - Plastun - TLY - Plastun - Russia",
            "searchName": "TLY-Plastun-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "BVV",
            "countryCode": "RU",
            "cityCode": "BVV",
            "cityName": "Iturup Island",
            "name": "[BVV] - Burevestnik AFB - BVV - Iturup Island - Russia",
            "searchName": "BVV-Burevestnik AFB-Iturup Island-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "BWO",
            "countryCode": "RU",
            "cityCode": "BWO",
            "cityName": "Balakovo",
            "name": "[BWO] - Balakovo - BWO - Balakovo - Russia",
            "searchName": "BWO-Balakovo-Balakovo Havalimani-Bandar Seri Begawan-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "BQJ",
            "countryCode": "RU",
            "cityCode": "BQJ",
            "cityName": "Batagay",
            "name": "[BQJ] - Batagay - BQJ - Batagay - Russia",
            "searchName": "BQJ-Batagay-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "BQS",
            "countryCode": "RU",
            "cityCode": "BQS",
            "cityName": "Blagoveschensk",
            "name": "[BQS] - Blagoveschensk - BQS - Blagoveschensk - Russia",
            "searchName": "BQS-Blagoweschtschensk-Blagoveschensk-Ignatyevo Apt.-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "BQG",
            "countryCode": "RU",
            "cityCode": "BQG",
            "cityName": "Bogorodskoye",
            "name": "[BQG] - Bogorodskoye - BQG - Bogorodskoye - Russia",
            "searchName": "BQG-Bogorodskoye-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "BAX",
            "countryCode": "RU",
            "cityCode": "BAX",
            "cityName": "Barnaul",
            "name": "[BAX] - Barnaul - BAX - Barnaul - Russia",
            "searchName": "BAX-Barnaul-Barnaoul-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "AMV",
            "countryCode": "RU",
            "cityCode": "AMV",
            "cityName": "Amderma",
            "name": "[AMV] - Amderma - AMV - Amderma - Russia",
            "searchName": "AMV-Amderma-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ACS",
            "countryCode": "RU",
            "cityCode": "ACS",
            "cityName": "Achinsk",
            "name": "[ACS] - Achinsk - ACS - Achinsk - Russia",
            "searchName": "ACS-Achinsk-Achinsk Havalimani-Ascona-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ADH",
            "countryCode": "RU",
            "cityCode": "ADH",
            "cityName": "Aldan",
            "name": "[ADH] - Aldan - ADH - Aldan - Russia",
            "searchName": "ADH-Aldan-Aldan Havalimani-Adrian-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "CSH",
            "countryCode": "RU",
            "cityCode": "CSH",
            "cityName": "Solovetsky",
            "name": "[CSH] - Solovky - CSH - Solovetsky - Russia",
            "searchName": "CSH-Solovky-Solovski Havalimani-Solovetsky-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "CSY",
            "countryCode": "RU",
            "cityCode": "CSY",
            "cityName": "Cheboksary",
            "name": "[CSY] - Cheboksary - CSY - Cheboksary - Russia",
            "searchName": "CSY-Tscheboksary-Cheboksary-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "CYX",
            "countryCode": "RU",
            "cityCode": "CYX",
            "cityName": "Cherskiy",
            "name": "[CYX] - Cherskiy - CYX - Cherskiy - Russia",
            "searchName": "CYX-Cherskiy-Cherskiy Havalimani-Cheyenne-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "CKH",
            "countryCode": "RU",
            "cityCode": "CKH",
            "cityName": "Chokurdah",
            "name": "[CKH] - Chokurdah - CKH - Chokurdah - Russia",
            "searchName": "CKH-Chokurdah-Chokurdah Havalimani--Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie-Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "CKL",
            "countryCode": "RU",
            "cityCode": "CKL",
            "cityName": "Chkalovsky",
            "name": "[CKL] - Chkalovsky - CKL - Chkalovsky - Russia",
            "searchName": "CKL-Chkalovsky-Chkalovsky Havalimani--Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie-Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "BZK",
            "countryCode": "RU",
            "cityCode": "BZK",
            "cityName": "Briansk",
            "name": "[BZK] - Briansk - BZK - Briansk - Russia",
            "searchName": "BZK-Briansk-Briansk Havalimani-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "CEE",
            "countryCode": "RU",
            "cityCode": "CEE",
            "cityName": "Cherepovets",
            "name": "[CEE] - Cherepovets - CEE - Cherepovets - Russia",
            "searchName": "CEE-Tscherepowez-Cherepovets-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "UTS",
            "countryCode": "RU",
            "cityCode": "UTS",
            "cityName": "Ust-Tsilma",
            "name": "[UTS] - Ust-Tsilma - UTS - Ust-Tsilma - Russia",
            "searchName": "UTS-Ust-Tsilma-Kamenogorsk-Kut-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "USK",
            "countryCode": "RU",
            "cityCode": "USK",
            "cityName": "Usinsk",
            "name": "[USK] - Usinsk - USK - Usinsk - Russia",
            "searchName": "USK-Ussinsk-Usinsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "URS",
            "countryCode": "RU",
            "cityCode": "URS",
            "cityName": "Kursk",
            "name": "[URS] - Kursk - URS - Kursk - Russia",
            "searchName": "URS-Kursk-Kursk Havalimani-Koursk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "URJ",
            "countryCode": "RU",
            "cityCode": "URJ",
            "cityName": "Uraj",
            "name": "[URJ] - Uraj - URJ - Uraj - Russia",
            "searchName": "URJ-Uraj-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "VGD",
            "countryCode": "RU",
            "cityCode": "VGD",
            "cityName": "Vologda",
            "name": "[VGD] - Vologda - VGD - Vologda - Russia",
            "searchName": "VGD-Vologda-Vologda Havalimani-Wologda-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "UEN",
            "countryCode": "RU",
            "cityCode": "UEN",
            "cityName": "Urengoy",
            "name": "[UEN] - Urengoy - UEN - Urengoy - Russia",
            "searchName": "UEN-Urengoy-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "UIK",
            "countryCode": "RU",
            "cityCode": "UIK",
            "cityName": "Ust-Ilimsk",
            "name": "[UIK] - Ust-Ilimsk - UIK - Ust-Ilimsk - Russia",
            "searchName": "UIK-Ilimsk - Flughafen-Ust-Ilimsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "UCT",
            "countryCode": "RU",
            "cityCode": "UCT",
            "cityName": "Ukhta",
            "name": "[UCT] - Ukhta - UCT - Ukhta - Russia",
            "searchName": "UCT-Uchta-Ukhta-Ujt-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ULY",
            "countryCode": "RU",
            "cityCode": "ULY",
            "cityName": "Ulyanovsk",
            "name": "[ULY] - Vostochny - ULY - Ulyanovsk - Russia",
            "searchName": "ULY-Uljanowsk-Vostochny-Ulyanovsk-Ulyanovsk Havalimani-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ULV",
            "countryCode": "RU",
            "cityCode": "ULY",
            "cityName": "Ulyanovsk",
            "name": "[ULV] - Baratayevka - ULY - Ulyanovsk - Russia",
            "searchName": "ULV-ULY-Baratayevka-Ulyanovsk Baratayevka-Ulyanovsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "UKX",
            "countryCode": "RU",
            "cityCode": "UKX",
            "cityName": "Ust-Kut",
            "name": "[UKX] - Ust-Kut - UKX - Ust-Kut - Russia",
            "searchName": "UKX-Ust-Kut-Kut Havalimani-Nuku-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ULK",
            "countryCode": "RU",
            "cityCode": "ULK",
            "cityName": "Lensk",
            "name": "[ULK] - Lensk - ULK - Lensk - Russia",
            "searchName": "ULK-Lensk-Lensk Airport-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "VLU",
            "countryCode": "RU",
            "cityCode": "VLU",
            "cityName": "Velikiye Luki",
            "name": "[VLU] - Velikiye Luki - VLU - Velikiye Luki - Russia",
            "searchName": "VLU-Velikiye Luki-Velikiye Luki Havalimani-Welikije Luki-Vallejo-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "VLK",
            "countryCode": "RU",
            "cityCode": "VLK",
            "cityName": "Volgodonsk",
            "name": "[VLK] - Volgodonsk - VLK - Volgodonsk - Russia",
            "searchName": "VLK-Volgodonsk-Wolgodonsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "VKT",
            "countryCode": "RU",
            "cityCode": "VKT",
            "cityName": "Vorkuta",
            "name": "[VKT] - Vorkuta - VKT - Vorkuta - Russia",
            "searchName": "VKT-Workuta-Vorkuta-Vorkouta-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "VRI",
            "countryCode": "RU",
            "cityCode": "VRI",
            "cityName": "Varandey",
            "name": "[VRI] - Varandey - VRI - Varandey - Russia",
            "searchName": "VRI-Varandey-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "RZN",
            "countryCode": "RU",
            "cityCode": "RZN",
            "cityName": "Ryazan",
            "name": "[RZN] - Ryazan - RZN - Ryazan - Russia",
            "searchName": "RZN-Ryazan-Ryazan Havalimani-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "RYB",
            "countryCode": "RU",
            "cityCode": "RYB",
            "cityName": "Rybinsk",
            "name": "[RYB] - Rybinsk - RYB - Rybinsk - Russia",
            "searchName": "RYB-Rybinsk-Rybinsk Havalimani-Roxas City-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NGK",
            "countryCode": "RU",
            "cityCode": "NGK",
            "cityName": "Nogliki",
            "name": "[NGK] - Nogliki - NGK - Nogliki - Russia",
            "searchName": "NGK-Nogliki-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NFG",
            "countryCode": "RU",
            "cityCode": "NFG",
            "cityName": "Nefteyugansk",
            "name": "[NFG] - Nefteyugansk - NFG - Nefteyugansk - Russia",
            "searchName": "NFG-Nefteyugansk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NER",
            "countryCode": "RU",
            "cityCode": "NER",
            "cityName": "Neryungri",
            "name": "[NER] - Neryungri - NER - Neryungri - Russia",
            "searchName": "NER-Neryungri-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NEF",
            "countryCode": "RU",
            "cityCode": "NEF",
            "cityName": "Neftekamsk",
            "name": "[NEF] - Neftekamsk - NEF - Neftekamsk - Russia",
            "searchName": "NEF-Neftekamsk-Neftekamsk Havalimani-Necochea-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NBC",
            "countryCode": "RU",
            "cityCode": "NBC",
            "cityName": "Naberevnye Chelny",
            "name": "[NBC] - Naberevnye Chelny - NBC - Naberevnye Chelny - Russia",
            "searchName": "NBC-Nabereschnyje Tschelny-Naberevnye Chelny-Naberezhnye Chelny-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NAL",
            "countryCode": "RU",
            "cityCode": "NAL",
            "cityName": "Nalchik",
            "name": "[NAL] - Nalchik - NAL - Nalchik - Russia",
            "searchName": "NAL-Nalchik-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "MCX",
            "countryCode": "RU",
            "cityCode": "MCX",
            "cityName": "Makhachkala",
            "name": "[MCX] - Makhachkala - MCX - Makhachkala - Russia",
            "searchName": "MCX-Machatschkala-Makhachkala-Makhatchkala-Maha-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "MJZ",
            "countryCode": "RU",
            "cityCode": "MJZ",
            "cityName": "Mirnyj",
            "name": "[MJZ] - Mirnyj - MJZ - Mirnyj - Russia",
            "searchName": "MJZ-Mirnyy-Mirnyj-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "OSW",
            "countryCode": "RU",
            "cityCode": "OSW",
            "cityName": "Orsk",
            "name": "[OSW] - Orsk - OSW - Orsk - Russia",
            "searchName": "OSW-Orsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "OVS",
            "countryCode": "RU",
            "cityCode": "OVS",
            "cityName": "Sovetsky",
            "name": "[OVS] - Sovetsky - OVS - Sovetsky - Russia",
            "searchName": "OVS-Sovetsky-Boscobel-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "RAT",
            "countryCode": "RU",
            "cityCode": "RAT",
            "cityName": "Raduzhnyi",
            "name": "[RAT] - Raduzhnyi - RAT - Raduzhnyi - Russia",
            "searchName": "RAT-Raduzhnyi-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "PWE",
            "countryCode": "RU",
            "cityCode": "PWE",
            "cityName": "Pevek",
            "name": "[PWE] - Pevek - PWE - Pevek - Russia",
            "searchName": "PWE-Pevek-Pevek  Havalimani-Painesville-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "PYJ",
            "countryCode": "RU",
            "cityCode": "PYJ",
            "cityName": "Polyarnyj",
            "name": "[PYJ] - Polyarnyj - PYJ - Polyarnyj - Russia",
            "searchName": "PYJ-Polyarnyj-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "PVS",
            "countryCode": "RU",
            "cityCode": "PVS",
            "cityName": "Provideniya",
            "name": "[PVS] - Provideniya - PVS - Provideniya - Russia",
            "searchName": "PVS-Provideniya-Provideniya Havalimani-Prowidenija-Puerto Vallarta-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "PES",
            "countryCode": "RU",
            "cityCode": "PES",
            "cityName": "Petrozavodsk",
            "name": "[PES] - Petrozavodsk - PES - Petrozavodsk - Russia",
            "searchName": "PES-Petrosawodsk-Petrozavodsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "PEX",
            "countryCode": "RU",
            "cityCode": "PEX",
            "cityName": "Pechora",
            "name": "[PEX] - Pechora - PEX - Pechora - Russia",
            "searchName": "PEX-Pechora-Petschora-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "BJY",
            "countryCode": "RS",
            "cityCode": "BEG",
            "cityName": "Belgrade",
            "name": "[BJY] - Batajnica - BEG - Belgrade - Serbia",
            "searchName": "BJY-BEG-Batajnica Airbase-Batajnica-Batajnika-Belgrad-Belgrade-Belgrado-Szerbia-Sèrbia-Sirbistan-Srbsko-Serbia-Serbien-Srbija-Serbija-Serbie--Suudi Arabistan",
            "priority": 0
        },
        {
            "iataCode": "UZC",
            "countryCode": "RS",
            "cityCode": "UZC",
            "cityName": "Uzice",
            "name": "[UZC] - Uzice-Ponikve - UZC - Uzice - Serbia",
            "searchName": "UZC-Ponikve-Uzice-Szerbia-Sèrbia-Sirbistan-Srbsko-Serbia-Serbien-Srbija-Serbija-Serbie--Suudi Arabistan",
            "priority": 0
        },
        {
            "iataCode": "REN",
            "countryCode": "RU",
            "cityCode": "REN",
            "cityName": "Orenburg",
            "name": "[REN] - Orenburg - REN - Orenburg - Russia",
            "searchName": "REN-Orenburg-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "RGK",
            "countryCode": "RU",
            "cityCode": "RGK",
            "cityName": "Gorno-Altaysk",
            "name": "[RGK] - Gorno-Altaysk - RGK - Gorno-Altaysk - Russia",
            "searchName": "RGK-Gorno-Altaysk-Alta-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NSK",
            "countryCode": "RU",
            "cityCode": "NSK",
            "cityName": "Norilsk",
            "name": "[NSK] - Norilsk - NSK - Norilsk - Russia",
            "searchName": "NSK-Norilsk-Noril sk-Alykel Airport-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NOI",
            "countryCode": "RU",
            "cityCode": "NOI",
            "cityName": "Novorossijsk",
            "name": "[NOI] - Novorossijsk - NOI - Novorossijsk - Russia",
            "searchName": "NOI-Novorossijsk-Novorossisk Havalimani-Nogales-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NOJ",
            "countryCode": "RU",
            "cityCode": "NOJ",
            "cityName": "Nojabrxsk",
            "name": "[NOJ] - Nojabrxsk - NOJ - Nojabrxsk - Russia",
            "searchName": "NOJ-Noyabr sk-Nojabrxsk-Nojabr sk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NOZ",
            "countryCode": "RU",
            "cityCode": "NOZ",
            "cityName": "Novokuznetsk",
            "name": "[NOZ] - Novokuznetsk - NOZ - Novokuznetsk - Russia",
            "searchName": "NOZ-Nowokusnezk-Novokuznetsk-Spichenkovo Apt-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NNM",
            "countryCode": "RU",
            "cityCode": "NNM",
            "cityName": "Naryan-Mar",
            "name": "[NNM] - Naryan-Mar - NNM - Naryan-Mar - Russia",
            "searchName": "NNM-Naryan-Mar-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NUX",
            "countryCode": "RU",
            "cityCode": "NUX",
            "cityName": "Novy Urengoy",
            "name": "[NUX] - Novy Urengoy - NUX - Novy Urengoy - Russia",
            "searchName": "NUX-Novyy Urengoy-Novy Urengoy-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NVR",
            "countryCode": "RU",
            "cityCode": "NVR",
            "cityName": "Novgorod",
            "name": "[NVR] - Novgorod - NVR - Novgorod - Russia",
            "searchName": "NVR-Novgorod-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NYA",
            "countryCode": "RU",
            "cityCode": "NYA",
            "cityName": "Nyagan",
            "name": "[NYA] - Nyagan - NYA - Nyagan - Russia",
            "searchName": "NYA-Nyagan-Nyagan Airport-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "OHO",
            "countryCode": "RU",
            "cityCode": "OHO",
            "cityName": "Okhotsk",
            "name": "[OHO] - Okhotsk - OHO - Okhotsk - Russia",
            "searchName": "OHO-Okhotsk-Ochotsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "OGZ",
            "countryCode": "RU",
            "cityCode": "OGZ",
            "cityName": "Vladikavkaz",
            "name": "[OGZ] - Vladikavkaz - OGZ - Vladikavkaz - Russia",
            "searchName": "OGZ-Wladikawkas-Vladikavkaz-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "OHH",
            "countryCode": "RU",
            "cityCode": "OHH",
            "cityName": "Okha",
            "name": "[OHH] - Novostroyka - OHH - Okha - Russia",
            "searchName": "OHH-Novostroyka-Okha-Hohhot-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "OKT",
            "countryCode": "RU",
            "cityCode": "OKT",
            "cityName": "Oktiabrskij",
            "name": "[OKT] - Oktiabrskij - OKT - Oktiabrskij - Russia",
            "searchName": "OKT-Oktiabrskij-Oktiabrskij Havalimani-Oktyabrskiy-Yorke Island-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ODO",
            "countryCode": "RU",
            "cityCode": "ODO",
            "cityName": "Bodaybo",
            "name": "[ODO] - Bodaybo - ODO - Bodaybo - Russia",
            "searchName": "ODO-Bodaybo-Aeropuerto de Bodaybo-Bodaybo Airport-Bodaybo Havalimani-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "OEL",
            "countryCode": "RU",
            "cityCode": "OEL",
            "cityName": "Orel",
            "name": "[OEL] - Orel - OEL - Orel - Russia",
            "searchName": "OEL-Orel-Oryol Havalimani-Orjol-Vincennes-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "NYM",
            "countryCode": "RU",
            "cityCode": "NYM",
            "cityName": "Nadym",
            "name": "[NYM] - Nadym - NYM - Nadym - Russia",
            "searchName": "NYM-Nadym-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "XJD",
            "countryCode": "QA",
            "cityCode": "XJD",
            "cityName": "Al Udeid",
            "name": "[XJD] - Al Udeid AB - XJD - Al Udeid - Qatar",
            "searchName": "XJD-Al Udeid AB-Al Udeid-Katar-Qatar-Kataras-Katara-Quatar",
            "priority": 0
        },
        {
            "iataCode": "RUN",
            "countryCode": "RE",
            "cityCode": "RUN",
            "cityName": "St Denis de la Reunion",
            "name": "[RUN] - Gillot - RUN - St Denis de la Reunion - Reunion",
            "searchName": "RUN-Saint Denis de la Reunion-Gillot-Saint Denis-St Denis de la Reunion-Réunion-Reunion-sziget-Illa Reunion-Reunion Adasi-Reunion Island-saari-øya-Insula Reunion-Reinjona-Reunjonas-Oto-Reunión",
            "priority": 0
        },
        {
            "iataCode": "ZSE",
            "countryCode": "RE",
            "cityCode": "ZSE",
            "cityName": "St Pierre dela Reunion",
            "name": "[ZSE] - St Pierre dela Reunion - ZSE - St Pierre dela Reunion - Reunion",
            "searchName": "ZSE-Pierrefonds Airport-St Pierre dela Reunion-Saint-pierre-Pierre-St. Pierre de la Reunion-Réunion-Reunion-sziget-Illa Reunion-Reunion Adasi-Reunion Island-saari-øya-Insula Reunion-Reinjona-Reunjonas-Oto-Reunión",
            "priority": 0
        },
        {
            "iataCode": "QPL",
            "countryCode": "RO",
            "cityCode": "QPL",
            "cityName": "Ploiesti",
            "name": "[QPL] - Ploiesti Bus Station - QPL - Ploiesti - Romania",
            "searchName": "QPL-Ploiesti Bus Station-Ploiesti-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "QRS",
            "countryCode": "RO",
            "cityCode": "QRS",
            "cityName": "Resita",
            "name": "[QRS] - Resita Bus Station - QRS - Resita - Romania",
            "searchName": "QRS-Resita Bus Station-Resita-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "OMR",
            "countryCode": "RO",
            "cityCode": "OMR",
            "cityName": "Oradea",
            "name": "[OMR] - Oradea - OMR - Oradea - Romania",
            "searchName": "OMR-Oradea-Oradea Int. Apt-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "XHV",
            "countryCode": "RO",
            "cityCode": "XHV",
            "cityName": "Brasov",
            "name": "[XHV] - Brasov Bus Station - XHV - Brasov - Romania",
            "searchName": "XHV-Brasov Bus Station-Brasov-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "TGM",
            "countryCode": "RO",
            "cityCode": "TGM",
            "cityName": "Tirgu Mures",
            "name": "[TGM] - Transilvania - TGM - Tirgu Mures - Romania",
            "searchName": "TGM-Tirgu Mures-Transilvania-Tirgu Mures Airport-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "TCE",
            "countryCode": "RO",
            "cityCode": "TCE",
            "cityName": "Tulcea",
            "name": "[TCE] - Tulcea - TCE - Tulcea - Romania",
            "searchName": "TCE-Tulcea-Tulcea Havalimani-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "TSR",
            "countryCode": "RO",
            "cityCode": "TSR",
            "cityName": "Timisoara",
            "name": "[TSR] - Timisoara (traian Vuia) International - TSR - Timisoara - Romania",
            "searchName": "TSR-Temeswar-Timisoara (traian Vuia) International-Timisoara-Temesvar-Freidorf-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "SUJ",
            "countryCode": "RO",
            "cityCode": "SUJ",
            "cityName": "Satu Mare",
            "name": "[SUJ] - Satu Mare - SUJ - Satu Mare - Romania",
            "searchName": "SUJ-Satu Mare-Satu Mare Int. Apt-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "SBZ",
            "countryCode": "RO",
            "cityCode": "SBZ",
            "cityName": "Sibiu",
            "name": "[SBZ] - Sibiu - SBZ - Sibiu - Romania",
            "searchName": "SBZ-Sibiu-All airports-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "SCV",
            "countryCode": "RO",
            "cityCode": "SCV",
            "cityName": "Suceava",
            "name": "[SCV] - Salcea - SCV - Suceava - Romania",
            "searchName": "SCV-Suceava-Salcea-Salcea Havalimani-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "ROR",
            "countryCode": "PW",
            "cityCode": "ROR",
            "cityName": "Koror",
            "name": "[ROR] - Babelthuap/Koror - ROR - Koror - Palau",
            "searchName": "ROR-Koror-Babelthuap/Koror-Airai-Palau-Palaos",
            "priority": 0
        },
        {
            "iataCode": "PCJ",
            "countryCode": "PY",
            "cityCode": "PCJ",
            "cityName": "Puerto La Victoria",
            "name": "[PCJ] - Puerto La Victoria - PCJ - Puerto La Victoria - Paraguay",
            "searchName": "PCJ-Puerto La Victoria-Puerto La Victoria Havalimani-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "PBT",
            "countryCode": "PY",
            "cityCode": "PBT",
            "cityName": "Puerto Leda",
            "name": "[PBT] - Puerto Leda - PBT - Puerto Leda - Paraguay",
            "searchName": "PBT-Puerto Leda-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "OLK",
            "countryCode": "PY",
            "cityCode": "OLK",
            "cityName": "Fuerte Olimpo",
            "name": "[OLK] - Fuerte Olimpo - OLK - Fuerte Olimpo - Paraguay",
            "searchName": "OLK-Fuerte Olimpo-Fuerte Olimpo Havalimani-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "PJC",
            "countryCode": "PY",
            "cityCode": "PJC",
            "cityName": "Pedro Juan Caballero",
            "name": "[PJC] - Pedro Juan Caballero - PJC - Pedro Juan Caballero - Paraguay",
            "searchName": "PJC-Pedro Juan Caballero-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "PIL",
            "countryCode": "PY",
            "cityCode": "PIL",
            "cityName": "Pilar",
            "name": "[PIL] - Pilar - PIL - Pilar - Paraguay",
            "searchName": "PIL-Pilar-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "VMI",
            "countryCode": "PY",
            "cityCode": "VMI",
            "cityName": "Vallemi",
            "name": "[VMI] - INC - VMI - Vallemi - Paraguay",
            "searchName": "VMI-INC-Inc Havalimani-Vallemi-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "FLM",
            "countryCode": "PY",
            "cityCode": "FLM",
            "cityName": "Filadelfia",
            "name": "[FLM] - Filadelfia - FLM - Filadelfia - Paraguay",
            "searchName": "FLM-Filadelfia-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "BFA",
            "countryCode": "PY",
            "cityCode": "BFA",
            "cityName": "Bahia Negra",
            "name": "[BFA] - Bahia Negra - BFA - Bahia Negra - Paraguay",
            "searchName": "BFA-Bahia Negra-Bahia Negra MN-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "IAS",
            "countryCode": "RO",
            "cityCode": "IAS",
            "cityName": "Iasi",
            "name": "[IAS] - Iasi - IAS - Iasi - Romania",
            "searchName": "IAS-Iasi-Iai Int. Apt.-Ia-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "LGJ",
            "countryCode": "RO",
            "cityCode": "LGJ",
            "cityName": "Lugoj",
            "name": "[LGJ] - Lugoj Bus Station - LGJ - Lugoj - Romania",
            "searchName": "LGJ-Lugoj Bus Station-Lugoj-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "INI",
            "countryCode": "RS",
            "cityCode": "INI",
            "cityName": "Nis",
            "name": "[INI] - Constantine The Great International - INI - Nis - Serbia",
            "searchName": "INI-Nis Serbien-Constantine The Great International-Nis-Nis Havalimani-Nis Yu-Szerbia-Sèrbia-Sirbistan-Srbsko-Serbia-Serbien-Srbija-Serbija-Serbie--Suudi Arabistan",
            "priority": 0
        },
        {
            "iataCode": "AYO",
            "countryCode": "PY",
            "cityCode": "AYO",
            "cityName": "Ayolas",
            "name": "[AYO] - Ayolas - AYO - Ayolas - Paraguay",
            "searchName": "AYO-Ayolas-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "AGT",
            "countryCode": "PY",
            "cityCode": "AGT",
            "cityName": "Ciudad Del Este",
            "name": "[AGT] - Alejo Garcia - AGT - Ciudad Del Este - Paraguay",
            "searchName": "AGT-Ciudad del Este-Alejo Garcia-Ciudad Del Este-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "CIO",
            "countryCode": "PY",
            "cityCode": "CIO",
            "cityName": "Concepcion",
            "name": "[CIO] - MCAL Lopez - CIO - Concepcion - Paraguay",
            "searchName": "CIO-MCAL Lopez-Mcal Lopez Havalimani-Concepcion-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "ENO",
            "countryCode": "PY",
            "cityCode": "ENO",
            "cityName": "Encarnacion",
            "name": "[ENO] - Teniente Prim Alarcon - ENO - Encarnacion - Paraguay",
            "searchName": "ENO-Teniente Prim Alarcon-Encarnacion-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "ESG",
            "countryCode": "PY",
            "cityCode": "ESG",
            "cityName": "Mariscal Estigarribia",
            "name": "[ESG] - Mariscal Estigarribia - ESG - Mariscal Estigarribia - Paraguay",
            "searchName": "ESG-Mariscal Estigarribia-Paraguay",
            "priority": 0
        },
        {
            "iataCode": "CND",
            "countryCode": "RO",
            "cityCode": "CND",
            "cityName": "Constanta",
            "name": "[CND] - Kogalniceanu - CND - Constanta - Romania",
            "searchName": "CND-Constanza Rumänien-Kogalniceanu-Constanta-Constanza-Costanza-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "CRA",
            "countryCode": "RO",
            "cityCode": "CRA",
            "cityName": "Craiova",
            "name": "[CRA] - Craiova - CRA - Craiova - Romania",
            "searchName": "CRA-Craiova-Craiova Airport-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "CSB",
            "countryCode": "RO",
            "cityCode": "CSB",
            "cityName": "Caransebes",
            "name": "[CSB] - Caransebes - CSB - Caransebes - Romania",
            "searchName": "CSB-Caransebes-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "BAY",
            "countryCode": "RO",
            "cityCode": "BAY",
            "cityName": "Baia Mare",
            "name": "[BAY] - Baia Mare - BAY - Baia Mare - Romania",
            "searchName": "BAY-Baia Mare-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "ARW",
            "countryCode": "RO",
            "cityCode": "ARW",
            "cityName": "Arad",
            "name": "[ARW] - Arad - ARW - Arad - Romania",
            "searchName": "ARW-Arad-Arad Int. Apt-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "BCM",
            "countryCode": "RO",
            "cityCode": "BCM",
            "cityName": "Bacau",
            "name": "[BCM] - Bacau - BCM - Bacau - Romania",
            "searchName": "BCM-Bacau-George Enestu International Airport-Bac-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "BBU",
            "countryCode": "RO",
            "cityCode": "BUH",
            "cityName": "Bucharest",
            "name": "[BBU] - Baneasa - BUH - Bucharest - Romania",
            "searchName": "BBU-BUH-Baneasa-Baneasa Havalimani-Bucharest-Bucarest-B-Romania-Rumänien-Rumani-Roemenië-Rumanía-Rumania-Rumunia-Rumeen-Roumanie",
            "priority": 0
        },
        {
            "iataCode": "GRW",
            "countryCode": "PT",
            "cityCode": "GRW",
            "cityName": "Graciosa Island",
            "name": "[GRW] - Graciosa Island - GRW - Graciosa Island - Portugal",
            "searchName": "GRW-Graciosa Island-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "ZYJ",
            "countryCode": "PT",
            "cityCode": "OPO",
            "cityName": "Porto",
            "name": "[ZYJ] - Porto TP Bus Station - OPO - Porto - Portugal",
            "searchName": "ZYJ-OPO-Porto TP Bus Station-Porto-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "PDL",
            "countryCode": "PT",
            "cityCode": "PDL",
            "cityName": "Ponta Delgada",
            "name": "[PDL] - Nordela (SÃ£o Miguel Island) - PDL - Ponta Delgada - Portugal",
            "searchName": "PDL-Ponta Delgada-Nordela (São Miguel Island)-Nordela-Nordela (SÃ£o Miguel Island)-Azores-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "JIW",
            "countryCode": "PK",
            "cityCode": "JIW",
            "cityName": "Jiwani",
            "name": "[JIW] - Jiwani - JIW - Jiwani - Pakistan",
            "searchName": "JIW-Jiwani-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "KDU",
            "countryCode": "PK",
            "cityCode": "KDU",
            "cityName": "Skardu",
            "name": "[KDU] - Skardu - KDU - Skardu - Pakistan",
            "searchName": "KDU-Skardu-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "KDD",
            "countryCode": "PK",
            "cityCode": "KDD",
            "cityName": "Khuzdar",
            "name": "[KDD] - Khuzdar - KDD - Khuzdar - Pakistan",
            "searchName": "KDD-Khuzdar-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "KCF",
            "countryCode": "PK",
            "cityCode": "KCF",
            "cityName": "Kadanwari",
            "name": "[KCF] - Kadanwari - KCF - Kadanwari - Pakistan",
            "searchName": "KCF-Kadanwari-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "KBH",
            "countryCode": "PK",
            "cityCode": "KBH",
            "cityName": "Kalat",
            "name": "[KBH] - Kalat - KBH - Kalat - Pakistan",
            "searchName": "KBH-Kalat-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "ZYF",
            "countryCode": "PT",
            "cityCode": "FAO",
            "cityName": "Faro",
            "name": "[ZYF] - Faro TP - FAO - Faro - Portugal",
            "searchName": "ZYF-FAO-Faro TP-Faro-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "COV",
            "countryCode": "PT",
            "cityCode": "COV",
            "cityName": "Covilha",
            "name": "[COV] - Covilha - COV - Covilha - Portugal",
            "searchName": "COV-Covilha-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "CVU",
            "countryCode": "PT",
            "cityCode": "CVU",
            "cityName": "Corvo Island",
            "name": "[CVU] - Corvo Island - CVU - Corvo Island - Portugal",
            "searchName": "CVU-Corvo Island-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "CHV",
            "countryCode": "PT",
            "cityCode": "CHV",
            "cityName": "Chaves",
            "name": "[CHV] - Chaves - CHV - Chaves - Portugal",
            "searchName": "CHV-Chaves-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "CBP",
            "countryCode": "PT",
            "cityCode": "CBP",
            "cityName": "Coimbra",
            "name": "[CBP] - Coimbra - CBP - Coimbra - Portugal",
            "searchName": "CBP-Coimbra-Coimbra Havalimani-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "BYJ",
            "countryCode": "PT",
            "cityCode": "BYJ",
            "cityName": "Beja",
            "name": "[BYJ] - Beja International - BYJ - Beja - Portugal",
            "searchName": "BYJ-Beja International-Beja-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "BGC",
            "countryCode": "PT",
            "cityCode": "BGC",
            "cityName": "Braganca",
            "name": "[BGC] - Braganca - BGC - Braganca - Portugal",
            "searchName": "BGC-Braganca-Bragance-Braganza-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "BGZ",
            "countryCode": "PT",
            "cityCode": "BGZ",
            "cityName": "Braga",
            "name": "[BGZ] - Braga - BGZ - Braga - Portugal",
            "searchName": "BGZ-Braga-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "HOR",
            "countryCode": "PT",
            "cityCode": "HOR",
            "cityName": "Horta",
            "name": "[HOR] - Horta - HOR - Horta - Portugal",
            "searchName": "HOR-Horta-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "IEG",
            "countryCode": "PL",
            "cityCode": "IEG",
            "cityName": "Zielona Gora",
            "name": "[IEG] - Babimost - IEG - Zielona Gora - Poland",
            "searchName": "IEG-Babimost-Zielona Gora-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "GDN",
            "countryCode": "PL",
            "cityCode": "GDN",
            "cityName": "Gdansk",
            "name": "[GDN] - Lech Walesa - GDN - Gdansk - Poland",
            "searchName": "GDN-Danzig-Lech Walesa-Rebiechowo-Gdansk-Danzica-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "CZW",
            "countryCode": "PL",
            "cityCode": "CZW",
            "cityName": "Czestochowa",
            "name": "[CZW] - Czestochowa - CZW - Czestochowa - Poland",
            "searchName": "CZW-Czestochowa-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "BXP",
            "countryCode": "PL",
            "cityCode": "BXP",
            "cityName": "Biala Podlaska",
            "name": "[BXP] - Biala Podlaska - BXP - Biala Podlaska - Poland",
            "searchName": "BXP-Biala Podlaska-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "BZG",
            "countryCode": "PL",
            "cityCode": "BZG",
            "cityName": "Bydgoszcz",
            "name": "[BZG] - Bydgoszcz I. J. Paderewski Airport - BZG - Bydgoszcz - Poland",
            "searchName": "BZG-Bromberg-Bydgoszcz I. J. Paderewski Airport-Bydgoszcz-Szwederowo-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "WMI",
            "countryCode": "PL",
            "cityCode": "WMI",
            "cityName": "Nowy Dwor Mazowiecki",
            "name": "[WMI] - Modlin - WMI - Nowy Dwor Mazowiecki - Poland",
            "searchName": "WMI-Modlin Airport-Modlin-Warsaw Modlin-Warsaw-Modlin Havalimani-Nowy Dw-Nowy Dwor Mazowiecki-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "WRO",
            "countryCode": "PL",
            "cityCode": "WRO",
            "cityName": "Wroclaw",
            "name": "[WRO] - Copernicus Airport - WRO - Wroclaw - Poland",
            "searchName": "WRO-Breslau-Copernicus Airport-Strachowice-Wroclaw-Breslavia-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "SIG",
            "countryCode": "PR",
            "cityCode": "SJU",
            "cityName": "San Juan",
            "name": "[SIG] - Isla Grande - SJU - San Juan - Puerto Rico",
            "searchName": "SIG-SJU-Isla Grande-San Juan-Puerto Rico-Porto Riko-Portoriko-Puerto Rikas-Puertoriko--Porto Rico-Portorico",
            "priority": 0
        },
        {
            "iataCode": "VQS",
            "countryCode": "PR",
            "cityCode": "VQS",
            "cityName": "Vieques",
            "name": "[VQS] - Vieques - VQS - Vieques - Puerto Rico",
            "searchName": "VQS-Vieques-Puerto Rico-Porto Riko-Portoriko-Puerto Rikas-Puertoriko--Porto Rico-Portorico",
            "priority": 0
        },
        {
            "iataCode": "VRL",
            "countryCode": "PT",
            "cityCode": "VRL",
            "cityName": "Vila Real",
            "name": "[VRL] - Vila Real - VRL - Vila Real - Portugal",
            "searchName": "VRL-Vila Real-Vila Real Havalimani-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "VSE",
            "countryCode": "PT",
            "cityCode": "VSE",
            "cityName": "Viseu",
            "name": "[VSE] - Viseu - VSE - Viseu - Portugal",
            "searchName": "VSE-Viseu-Viseu Havalimani-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "SJZ",
            "countryCode": "PT",
            "cityCode": "SJZ",
            "cityName": "Sao Jorge Island",
            "name": "[SJZ] - Sao Jorge Island - SJZ - Sao Jorge Island - Portugal",
            "searchName": "SJZ-Sao Jorge-Sao Jorge Island-Sao Jorge Island Airport--Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "SIE",
            "countryCode": "PT",
            "cityCode": "SIE",
            "cityName": "Sines",
            "name": "[SIE] - Sines - SIE - Sines - Portugal",
            "searchName": "SIE-Sines-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "SMA",
            "countryCode": "PT",
            "cityCode": "SMA",
            "cityName": "Santa Maria",
            "name": "[SMA] - Vila Do Porto - SMA - Santa Maria - Portugal",
            "searchName": "SMA-Santa Maria Portugal-Vila Do Porto-Aeroporto de Santa Maria-Santa Maria-)-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "TER",
            "countryCode": "PT",
            "cityCode": "TER",
            "cityName": "Terceira Island",
            "name": "[TER] - Lajes Ab - TER - Terceira Island - Portugal",
            "searchName": "TER-Terceira-Lajes Ab-Lajes-Lajes  Apt-Terceira Island--Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "PXO",
            "countryCode": "PT",
            "cityCode": "PXO",
            "cityName": "Porto Santo",
            "name": "[PXO] - Porto Santo - PXO - Porto Santo - Portugal",
            "searchName": "PXO-Porto Santo-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "PRM",
            "countryCode": "PT",
            "cityCode": "PRM",
            "cityName": "Portimao",
            "name": "[PRM] - Portimao - PRM - Portimao - Portugal",
            "searchName": "PRM-Portimao-Portimao Havalimani-Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "PIX",
            "countryCode": "PT",
            "cityCode": "PIX",
            "cityName": "Pico Island",
            "name": "[PIX] - Pico Island - PIX - Pico Island - Portugal",
            "searchName": "PIX-Pico Island--Portugal-Portugali-Portugalsko-Portekiz-Portugália-Portugalia-Portugalska-Portugalija-Portugale-Portogallo-Portug",
            "priority": 0
        },
        {
            "iataCode": "LYP",
            "countryCode": "PK",
            "cityCode": "LYP",
            "cityName": "Faisalabad",
            "name": "[LYP] - Faisalabad - LYP - Faisalabad - Pakistan",
            "searchName": "LYP-Faisalabad-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "LRG",
            "countryCode": "PK",
            "cityCode": "LRG",
            "cityName": "Lora Lai",
            "name": "[LRG] - Lora Lai - LRG - Lora Lai - Pakistan",
            "searchName": "LRG-Lora Lai-Loralai-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "ZIZ",
            "countryCode": "PK",
            "cityCode": "ZIZ",
            "cityName": "Zamzama",
            "name": "[ZIZ] - Zamzama - ZIZ - Zamzama - Pakistan",
            "searchName": "ZIZ-Zamzama-Ta izz-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "ZMC",
            "countryCode": "PL",
            "cityCode": "ZMC",
            "cityName": "Miedzyzdroje",
            "name": "[ZMC] - Miedzyzdroje Bus Station - ZMC - Miedzyzdroje - Poland",
            "searchName": "ZMC-Miedzyzdroje Bus Station-Miedzyzdroje-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "LUZ",
            "countryCode": "PL",
            "cityCode": "LUZ",
            "cityName": "Lushan",
            "name": "[LUZ] - Lushan - LUZ - Lushan - Poland",
            "searchName": "LUZ-Lushan-Lublin Airport-Lublin-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "LCJ",
            "countryCode": "PL",
            "cityCode": "LCJ",
            "cityName": "Lodz",
            "name": "[LCJ] - Lodz Lublinek - LCJ - Lodz - Poland",
            "searchName": "LCJ-Lodz-Lodz Lublinek-Lublinek-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "KTW",
            "countryCode": "PL",
            "cityCode": "KTW",
            "cityName": "Katowice",
            "name": "[KTW] - Pyrzowice - KTW - Katowice - Poland",
            "searchName": "KTW-Kattowitz-Pyrzowice-Katowice-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "ZFY",
            "countryCode": "PL",
            "cityCode": "KTW",
            "cityName": "Katowice",
            "name": "[ZFY] - Katowice Bus Station - KTW - Katowice - Poland",
            "searchName": "ZFY-KTW-Katowice Bus Station-Kattowitz-Katowice-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "JAG",
            "countryCode": "PK",
            "cityCode": "JAG",
            "cityName": "Jacobabad",
            "name": "[JAG] - Jacobabad - JAG - Jacobabad - Pakistan",
            "searchName": "JAG-Jacobabad-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "HRA",
            "countryCode": "PK",
            "cityCode": "HRA",
            "cityName": "Mansehra",
            "name": "[HRA] - Mansehra - HRA - Mansehra - Pakistan",
            "searchName": "HRA-Mansehra-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "HDD",
            "countryCode": "PK",
            "cityCode": "HDD",
            "cityName": "Hyderabad",
            "name": "[HDD] - Hyderabad - HDD - Hyderabad - Pakistan",
            "searchName": "HDD-Hyderabad-Haydarabad  Havalimani-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "JJI",
            "countryCode": "PE",
            "cityCode": "JJI",
            "cityName": "Juanjui",
            "name": "[JJI] - Juanjui - JJI - Juanjui - Peru",
            "searchName": "JJI-Juanjui-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 0
        },
        {
            "iataCode": "SZY",
            "countryCode": "PL",
            "cityCode": "SZY",
            "cityName": "Szymany",
            "name": "[SZY] - Mazury - SZY - Szymany - Poland",
            "searchName": "SZY-Mazury-Mazury Havalimani-Szymany-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "SZZ",
            "countryCode": "PL",
            "cityCode": "SZZ",
            "cityName": "Szczecin",
            "name": "[SZZ] - Goleniow - SZZ - Szczecin - Poland",
            "searchName": "SZZ-Stettin-Goleniow-Goleniów-Szczecin-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "ZFX",
            "countryCode": "PL",
            "cityCode": "SZZ",
            "cityName": "Szczecin",
            "name": "[ZFX] - Szczecin Bus Station - SZZ - Szczecin - Poland",
            "searchName": "ZFX-SZZ-Szczecin Bus Station-Szczecin-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "QJY",
            "countryCode": "PL",
            "cityCode": "QJY",
            "cityName": "Kolobrzeg",
            "name": "[QJY] - Kolobrzeg Bus Station - QJY - Kolobrzeg - Poland",
            "searchName": "QJY-Kolobrzeg Bus Station-Kolobrzeg-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "RZE",
            "countryCode": "PL",
            "cityCode": "RZE",
            "cityName": "Rzeszow",
            "name": "[RZE] - Jasionka - RZE - Rzeszow - Poland",
            "searchName": "RZE-Rzeszów-Jasionka-Rzesz-Rzeszow-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "OSZ",
            "countryCode": "PL",
            "cityCode": "OSZ",
            "cityName": "Koszalin",
            "name": "[OSZ] - Koszalin - OSZ - Koszalin - Poland",
            "searchName": "OSZ-Koszalin-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "POZ",
            "countryCode": "PL",
            "cityCode": "POZ",
            "cityName": "Poznan",
            "name": "[POZ] - Lawica - POZ - Poznan - Poland",
            "searchName": "POZ-Posen-Lawica-Poznan-Polska-Polen-Puola-Polsko-Polonya-Polònia-Lengyelország-Polonia-Poljska-Lenkija-Polija-Poola-Polandia-Ba Lan-Poland-Pologne-",
            "priority": 0
        },
        {
            "iataCode": "MQC",
            "countryCode": "PM",
            "cityCode": "MQC",
            "cityName": "Miquelon",
            "name": "[MQC] - Miquelon Airport - MQC - Miquelon - Saint Pierre and Miquelon",
            "searchName": "MQC-Miquelon Airport-Miquelon Havalimani-Miquelon-Saint Pierre and Miquelon-St. Pierre und Miquelon-Pierre ve Miquelon-San Pedro y Miquelón-Saint-Pierre-et-Saint Pierre e Miquelon",
            "priority": 0
        },
        {
            "iataCode": "FSP",
            "countryCode": "PM",
            "cityCode": "FSP",
            "cityName": "St Pierre",
            "name": "[FSP] - Saint-pierre Pointe-blanche - FSP - St Pierre - Saint Pierre and Miquelon",
            "searchName": "FSP-St Pierre-Saint-pierre Pointe-blanche-Pierre-Saint Pierre and Miquelon-St. Pierre und Miquelon-Pierre ve Miquelon-San Pedro y Miquelón-et-Miquelon-Saint Pierre e Miquelon",
            "priority": 0
        },
        {
            "iataCode": "HUC",
            "countryCode": "PR",
            "cityCode": "HUC",
            "cityName": "Humacao",
            "name": "[HUC] - Humacao Airport - HUC - Humacao - Puerto Rico",
            "searchName": "HUC-Humacao Airport-Humacao Havalimani-Humacao-Puerto Rico-Porto Riko-Portoriko-Puerto Rikas-Puertoriko--Porto Rico-Portorico",
            "priority": 0
        },
        {
            "iataCode": "BQN",
            "countryCode": "PR",
            "cityCode": "BQN",
            "cityName": "Aguadilla",
            "name": "[BQN] - Borinquen - BQN - Aguadilla - Puerto Rico",
            "searchName": "BQN-Aguadilla-Borinquen-Puerto Rico-Porto Riko-Portoriko-Puerto Rikas-Puertoriko--Porto Rico-Portorico",
            "priority": 0
        },
        {
            "iataCode": "ARE",
            "countryCode": "PR",
            "cityCode": "ARE",
            "cityName": "Arecibo",
            "name": "[ARE] - Arecibo - ARE - Arecibo - Puerto Rico",
            "searchName": "ARE-Arecibo-Puerto Rico-Porto Riko-Portoriko-Puerto Rikas-Puertoriko--Porto Rico-Portorico",
            "priority": 0
        },
        {
            "iataCode": "CPX",
            "countryCode": "PR",
            "cityCode": "CPX",
            "cityName": "Culebra",
            "name": "[CPX] - Culebra - CPX - Culebra - Puerto Rico",
            "searchName": "CPX-Culebra-Benjamín Rivera Noriega Havalimani-Puerto Rico-Porto Riko-Portoriko-Puerto Rikas-Puertoriko--Porto Rico-Portorico",
            "priority": 0
        },
        {
            "iataCode": "FAJ",
            "countryCode": "PR",
            "cityCode": "FAJ",
            "cityName": "Fajardo",
            "name": "[FAJ] - Diego Jimenez Torres - FAJ - Fajardo - Puerto Rico",
            "searchName": "FAJ-Diego Jimenez Torres-Fajardo-Puerto Rico-Porto Riko-Portoriko-Puerto Rikas-Puertoriko--Porto Rico-Portorico",
            "priority": 0
        },
        {
            "iataCode": "MAZ",
            "countryCode": "PR",
            "cityCode": "MAZ",
            "cityName": "Mayaguez",
            "name": "[MAZ] - Eugenio M De Hostos - MAZ - Mayaguez - Puerto Rico",
            "searchName": "MAZ-Mayaguez-Eugenio M De Hostos-Puerto Rico-Porto Riko-Portoriko-Puerto Rikas-Puertoriko--Porto Rico-Portorico",
            "priority": 0
        },
        {
            "iataCode": "NRR",
            "countryCode": "PR",
            "cityCode": "NRR",
            "cityName": "Roosevelt Roads",
            "name": "[NRR] - Roosevelt NAS - NRR - Roosevelt Roads - Puerto Rico",
            "searchName": "NRR-Roosevelt NAS-Roosevelt Roads-Puerto Rico-Porto Riko-Portoriko-Puerto Rikas-Puertoriko--Porto Rico-Portorico",
            "priority": 0
        },
        {
            "iataCode": "PSE",
            "countryCode": "PR",
            "cityCode": "PSE",
            "cityName": "Ponce",
            "name": "[PSE] - Mercedita - PSE - Ponce - Puerto Rico",
            "searchName": "PSE-Ponce-Mercedita-Puerto Rico-Porto Riko-Portoriko-Puerto Rikas-Puertoriko--Porto Rico-Portorico",
            "priority": 0
        },
        {
            "iataCode": "CHT",
            "countryCode": "NZ",
            "cityCode": "CHT",
            "cityName": "Chatham Island",
            "name": "[CHT] - Karewa - CHT - Chatham Island - New Zealand",
            "searchName": "CHT-Karewa-Chatham Island--Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda-Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "DUD",
            "countryCode": "NZ",
            "cityCode": "DUD",
            "cityName": "Dunedin",
            "name": "[DUD] - Dunedin International - DUD - Dunedin - New Zealand",
            "searchName": "DUD-Dunedin-Dunedin International-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "DGR",
            "countryCode": "NZ",
            "cityCode": "DGR",
            "cityName": "Dargaville",
            "name": "[DGR] - Dargaville - DGR - Dargaville - New Zealand",
            "searchName": "DGR-Dargaville-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "BHE",
            "countryCode": "NZ",
            "cityCode": "BHE",
            "cityName": "Blenheim",
            "name": "[BHE] - Woodbourne Air Station - BHE - Blenheim - New Zealand",
            "searchName": "BHE-Blenheim-Woodbourne Air Station-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "AMZ",
            "countryCode": "NZ",
            "cityCode": "AMZ",
            "cityName": "Ardmore",
            "name": "[AMZ] - Ardmore - AMZ - Ardmore - New Zealand",
            "searchName": "AMZ-Ardmore-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "ASG",
            "countryCode": "NZ",
            "cityCode": "ASG",
            "cityName": "Ashburton",
            "name": "[ASG] - Ashburton - ASG - Ashburton - New Zealand",
            "searchName": "ASG-Ashburton-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "MSH",
            "countryCode": "OM",
            "cityCode": "MSH",
            "cityName": "Masirah",
            "name": "[MSH] - Masirah - MSH - Masirah - Oman",
            "searchName": "MSH-Masirah-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "PIO",
            "countryCode": "PE",
            "cityCode": "PIO",
            "cityName": "Pisco",
            "name": "[PIO] - Pisco - PIO - Pisco - Peru",
            "searchName": "PIO-Pisco-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 0
        },
        {
            "iataCode": "PAC",
            "countryCode": "PA",
            "cityCode": "PTY",
            "cityName": "Panama City",
            "name": "[PAC] - Paitilla-Marcos A. Gelabert - PTY - Panama City - Panama",
            "searchName": "PAC-PTY-Panama City Paitilla-Paitilla-Marcos A. Gelabert-Panama Stadt-Panama City-Panama Ville-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "ONX",
            "countryCode": "PA",
            "cityCode": "ONX",
            "cityName": "Colon",
            "name": "[ONX] - Enrique Adolfo Jimenez - ONX - Colon - Panama",
            "searchName": "ONX-Enrique Adolfo Jimenez-Colon-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "OTD",
            "countryCode": "PA",
            "cityCode": "OTD",
            "cityName": "Contadora",
            "name": "[OTD] - Contadora - OTD - Contadora - Panama",
            "searchName": "OTD-Contadora-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "PLP",
            "countryCode": "PA",
            "cityCode": "PLP",
            "cityName": "La Palma",
            "name": "[PLP] - La Palma - PLP - La Palma - Panama",
            "searchName": "PLP-La Palma-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "MPI",
            "countryCode": "PA",
            "cityCode": "MPI",
            "cityName": "Mamitupo",
            "name": "[MPI] - Mamitupo - MPI - Mamitupo - Panama",
            "searchName": "MPI-Mamitupo-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "MPP",
            "countryCode": "PA",
            "cityCode": "MPP",
            "cityName": "Mulatupo",
            "name": "[MPP] - Mulatupo - MPP - Mulatupo - Panama",
            "searchName": "MPP-Mulatupo-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "OGM",
            "countryCode": "PA",
            "cityCode": "OGM",
            "cityName": "Ustupu Island",
            "name": "[OGM] - Ogobsucum - OGM - Ustupu Island - Panama",
            "searchName": "OGM-Ogobsucum-Ustupu Island-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "NMG",
            "countryCode": "PA",
            "cityCode": "NMG",
            "cityName": "San Miguel",
            "name": "[NMG] - San Miguel - NMG - San Miguel - Panama",
            "searchName": "NMG-San Miguel-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "NBL",
            "countryCode": "PA",
            "cityCode": "NBL",
            "cityName": "San Blas",
            "name": "[NBL] - San Blas - NBL - San Blas - Panama",
            "searchName": "NBL-San Blas-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "NGN",
            "countryCode": "PA",
            "cityCode": "NGN",
            "cityName": "Nargana",
            "name": "[NGN] - Nargana - NGN - Nargana - Panama",
            "searchName": "NGN-Nargana-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "MBP",
            "countryCode": "PE",
            "cityCode": "MBP",
            "cityName": "Moyobamba",
            "name": "[MBP] - Moyobamba - MBP - Moyobamba - Peru",
            "searchName": "MBP-Moyobamba-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 0
        },
        {
            "iataCode": "MFT",
            "countryCode": "PE",
            "cityCode": "MFT",
            "cityName": "Machu Picchu",
            "name": "[MFT] - Machu Picchu - MFT - Machu Picchu - Peru",
            "searchName": "MFT-Machu Picchu-Machu Picchu Havalimani-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 0
        },
        {
            "iataCode": "SZS",
            "countryCode": "NZ",
            "cityCode": "SZS",
            "cityName": "Stewart Island",
            "name": "[SZS] - Stewart Island - SZS - Stewart Island - New Zealand",
            "searchName": "SZS-Stewart Island-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "THH",
            "countryCode": "NZ",
            "cityCode": "THH",
            "cityName": "Taharoa",
            "name": "[THH] - Taharoa - THH - Taharoa - New Zealand",
            "searchName": "THH-Taharoa-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "TIU",
            "countryCode": "NZ",
            "cityCode": "TIU",
            "cityName": "Timaru",
            "name": "[TIU] - Timaru - TIU - Timaru - New Zealand",
            "searchName": "TIU-Timaru-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "TEU",
            "countryCode": "NZ",
            "cityCode": "TEU",
            "cityName": "Te Anau",
            "name": "[TEU] - Manapouri - TEU - Te Anau - New Zealand",
            "searchName": "TEU-Manapouri-Te Anau-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "TMZ",
            "countryCode": "NZ",
            "cityCode": "TMZ",
            "cityName": "Thames",
            "name": "[TMZ] - Thames - TMZ - Thames - New Zealand",
            "searchName": "TMZ-Thames-Thames Havalimani-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "TKZ",
            "countryCode": "NZ",
            "cityCode": "TKZ",
            "cityName": "Tokoroa",
            "name": "[TKZ] - Tokoroa - TKZ - Tokoroa - New Zealand",
            "searchName": "TKZ-Tokoroa-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "TRG",
            "countryCode": "NZ",
            "cityCode": "TRG",
            "cityName": "Tauranga",
            "name": "[TRG] - Tauranga - TRG - Tauranga - New Zealand",
            "searchName": "TRG-Tauranga-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "TUO",
            "countryCode": "NZ",
            "cityCode": "TUO",
            "cityName": "Taupo",
            "name": "[TUO] - Taupo - TUO - Taupo - New Zealand",
            "searchName": "TUO-Taupo-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "ROT",
            "countryCode": "NZ",
            "cityCode": "ROT",
            "cityName": "Rotorua",
            "name": "[ROT] - Rotorua - ROT - Rotorua - New Zealand",
            "searchName": "ROT-Rotorua-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "RAG",
            "countryCode": "NZ",
            "cityCode": "RAG",
            "cityName": "Raglan",
            "name": "[RAG] - Raglan - RAG - Raglan - New Zealand",
            "searchName": "RAG-Raglan-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "PPQ",
            "countryCode": "NZ",
            "cityCode": "PPQ",
            "cityName": "Paraparaumu",
            "name": "[PPQ] - Paraparaumu - PPQ - Paraparaumu - New Zealand",
            "searchName": "PPQ-Paraparaumu-Paraparaumu Havalimani-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "PMR",
            "countryCode": "NZ",
            "cityCode": "PMR",
            "cityName": "Palmerston North",
            "name": "[PMR] - Palmerston North - PMR - Palmerston North - New Zealand",
            "searchName": "PMR-Palmerston North-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "PKL",
            "countryCode": "NZ",
            "cityCode": "PKL",
            "cityName": "Pakatoa Island",
            "name": "[PKL] - Pakatoa Island - PKL - Pakatoa Island - New Zealand",
            "searchName": "PKL-Pakatoa Island-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "PCN",
            "countryCode": "NZ",
            "cityCode": "PCN",
            "cityName": "Picton",
            "name": "[PCN] - Koromiko - PCN - Picton - New Zealand",
            "searchName": "PCN-Koromiko-Koromiko Havalimani-Picton-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "MFN",
            "countryCode": "NZ",
            "cityCode": "MFN",
            "cityName": "Milford Sound",
            "name": "[MFN] - Milford Sound - MFN - Milford Sound - New Zealand",
            "searchName": "MFN-Milford Sound-Milford Sound Havalimani-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "MON",
            "countryCode": "NZ",
            "cityCode": "MON",
            "cityName": "Mount Cook",
            "name": "[MON] - Mount Cook Airport - MON - Mount Cook - New Zealand",
            "searchName": "MON-Mount Cook Airport-Mount Cook-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "GTN",
            "countryCode": "NZ",
            "cityCode": "MON",
            "cityName": "Mount Cook",
            "name": "[GTN] - Glentanner - MON - Mount Cook - New Zealand",
            "searchName": "GTN-MON-Glentanner-Mount Cook-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "TWZ",
            "countryCode": "NZ",
            "cityCode": "MON",
            "cityName": "Mount Cook",
            "name": "[TWZ] - Pukaki/Twizel - MON - Mount Cook - New Zealand",
            "searchName": "TWZ-MON-Pukaki\\/Twizel-Pukaki/Twizel-Mount Cook-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "MRO",
            "countryCode": "NZ",
            "cityCode": "MRO",
            "cityName": "Masterton",
            "name": "[MRO] - Masterton/Hood Aerodrome - MRO - Masterton - New Zealand",
            "searchName": "MRO-Masterton-Masterton/Hood Aerodrome-Masterton Havalimani-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "MTA",
            "countryCode": "NZ",
            "cityCode": "MTA",
            "cityName": "Matamata",
            "name": "[MTA] - Matamata - MTA - Matamata - New Zealand",
            "searchName": "MTA-Matamata-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "MZP",
            "countryCode": "NZ",
            "cityCode": "MZP",
            "cityName": "Motueka",
            "name": "[MZP] - Motueka - MZP - Motueka - New Zealand",
            "searchName": "MZP-Motueka-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "OHA",
            "countryCode": "NZ",
            "cityCode": "OHA",
            "cityName": "Ohakea",
            "name": "[OHA] - Royal Air Force Base - OHA - Ohakea - New Zealand",
            "searchName": "OHA-Royal Air Force Base-Ohakea-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "OAM",
            "countryCode": "NZ",
            "cityCode": "OAM",
            "cityName": "Oamaru",
            "name": "[OAM] - Oamaru - OAM - Oamaru - New Zealand",
            "searchName": "OAM-Oamaru-Oamaru Havalimani-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "NPE",
            "countryCode": "NZ",
            "cityCode": "NPE",
            "cityName": "Napier",
            "name": "[NPE] - Hawkes Bay - NPE - Napier - New Zealand",
            "searchName": "NPE-Napier Hastings-Hawkes Bay-Napier-Hastings-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "NSN",
            "countryCode": "NZ",
            "cityCode": "NSN",
            "cityName": "Nelson",
            "name": "[NSN] - Nelson - NSN - Nelson - New Zealand",
            "searchName": "NSN-Nelson-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "NPL",
            "countryCode": "NZ",
            "cityCode": "NPL",
            "cityName": "New Plymouth",
            "name": "[NPL] - New Plymouth - NPL - New Plymouth - New Zealand",
            "searchName": "NPL-New Plymouth-Nueva Plymouth-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "AOP",
            "countryCode": "PE",
            "cityCode": "AOP",
            "cityName": "Andoas",
            "name": "[AOP] - Andoas - AOP - Andoas - Peru",
            "searchName": "AOP-Andoas-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 0
        },
        {
            "iataCode": "TDP",
            "countryCode": "PE",
            "cityCode": "TDP",
            "cityName": "Trompeteros",
            "name": "[TDP] - Trompeteros - TDP - Trompeteros - Peru",
            "searchName": "TDP-Trompeteros-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 0
        },
        {
            "iataCode": "RIM",
            "countryCode": "PE",
            "cityCode": "RIM",
            "cityName": "Rodriguez De Mendoza",
            "name": "[RIM] - Rodriguez De Mendoza - RIM - Rodriguez De Mendoza - Peru",
            "searchName": "RIM-Rodriguez De Mendoza-Rodriguez de Mendoza-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 0
        },
        {
            "iataCode": "RIJ",
            "countryCode": "PE",
            "cityCode": "RIJ",
            "cityName": "Rioja",
            "name": "[RIJ] - Rioja - RIJ - Rioja - Peru",
            "searchName": "RIJ-Rioja-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 0
        },
        {
            "iataCode": "SYC",
            "countryCode": "PE",
            "cityCode": "SYC",
            "cityName": "Shiringayoc",
            "name": "[SYC] - Shiringayoc - SYC - Shiringayoc - Peru",
            "searchName": "SYC-Shiringayoc-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 0
        },
        {
            "iataCode": "SJA",
            "countryCode": "PE",
            "cityCode": "SJA",
            "cityName": "San Juan",
            "name": "[SJA] - San Juan - SJA - San Juan - Peru",
            "searchName": "SJA-San Juan-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 0
        },
        {
            "iataCode": "SMG",
            "countryCode": "PE",
            "cityCode": "SMG",
            "cityName": "Santa Maria",
            "name": "[SMG] - Santa Maria - SMG - Santa Maria - Peru",
            "searchName": "SMG-Santa Maria-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 0
        },
        {
            "iataCode": "SQU",
            "countryCode": "PE",
            "cityCode": "SQU",
            "cityName": "Saposoa",
            "name": "[SQU] - Saposoa - SQU - Saposoa - Peru",
            "searchName": "SQU-Saposoa-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 0
        },
        {
            "iataCode": "UMI",
            "countryCode": "PE",
            "cityCode": "UMI",
            "cityName": "Quincemil",
            "name": "[UMI] - Quincemil - UMI - Quincemil - Peru",
            "searchName": "UMI-Quincemil-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 0
        },
        {
            "iataCode": "VVN",
            "countryCode": "PE",
            "cityCode": "VVN",
            "cityName": "Malvinas",
            "name": "[VVN] - Malvinas - VVN - Malvinas - Peru",
            "searchName": "VVN-Malvinas-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 0
        },
        {
            "iataCode": "ALD",
            "countryCode": "PE",
            "cityCode": "ALD",
            "cityName": "Alerta",
            "name": "[ALD] - Alerta - ALD - Alerta - Peru",
            "searchName": "ALD-Alerta-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 0
        },
        {
            "iataCode": "APE",
            "countryCode": "PE",
            "cityCode": "APE",
            "cityName": "San Juan Aposento",
            "name": "[APE] - San Juan Aposento - APE - San Juan Aposento - Peru",
            "searchName": "APE-San Juan Aposento-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 0
        },
        {
            "iataCode": "CHH",
            "countryCode": "PE",
            "cityCode": "CHH",
            "cityName": "Chachapoyas",
            "name": "[CHH] - Chachapoyas - CHH - Chachapoyas - Peru",
            "searchName": "CHH-Chachapoyas-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 0
        },
        {
            "iataCode": "BLP",
            "countryCode": "PE",
            "cityCode": "BLP",
            "cityName": "Bellavista",
            "name": "[BLP] - Bellavista - BLP - Bellavista - Peru",
            "searchName": "BLP-Bellavista-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 0
        },
        {
            "iataCode": "ILQ",
            "countryCode": "PE",
            "cityCode": "ILQ",
            "cityName": "Ilo",
            "name": "[ILQ] - Ilo - ILQ - Ilo - Peru",
            "searchName": "ILQ-Ilo-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 0
        },
        {
            "iataCode": "IBP",
            "countryCode": "PE",
            "cityCode": "IBP",
            "cityName": "Iberia",
            "name": "[IBP] - Iberia - IBP - Iberia - Peru",
            "searchName": "IBP-Iberia-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 0
        },
        {
            "iataCode": "YMS",
            "countryCode": "PE",
            "cityCode": "YMS",
            "cityName": "Yurimaguas",
            "name": "[YMS] - Yurimaguas - YMS - Yurimaguas - Peru",
            "searchName": "YMS-Kommunaler Flughafen-Yurimaguas-Peru-Perú-Peruu-Perù-Pê ru-Pérou",
            "priority": 0
        },
        {
            "iataCode": "ZTA",
            "countryCode": "PF",
            "cityCode": "ZTA",
            "cityName": "Tureira",
            "name": "[ZTA] - Tureira - ZTA - Tureira - French Polynesia",
            "searchName": "ZTA-Tureira-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "KXU",
            "countryCode": "PF",
            "cityCode": "KXU",
            "cityName": "Katiu",
            "name": "[KXU] - Katiu - KXU - Katiu - French Polynesia",
            "searchName": "KXU-Katiu-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "KHZ",
            "countryCode": "PF",
            "cityCode": "KHZ",
            "cityName": "Kauehi",
            "name": "[KHZ] - Kauehi - KHZ - Kauehi - French Polynesia",
            "searchName": "KHZ-Kauehi-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "KKR",
            "countryCode": "PF",
            "cityCode": "KKR",
            "cityName": "Kaukura Atoll",
            "name": "[KKR] - Kaukura Atoll - KKR - Kaukura Atoll - French Polynesia",
            "searchName": "KKR-Kaukura-Kaukura Atoll-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "HUH",
            "countryCode": "PF",
            "cityCode": "HUH",
            "cityName": "Huahine",
            "name": "[HUH] - Huahine - HUH - Huahine - French Polynesia",
            "searchName": "HUH-Huahine-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "HOI",
            "countryCode": "PF",
            "cityCode": "HOI",
            "cityName": "Hao Island",
            "name": "[HOI] - Hao Island - HOI - Hao Island - French Polynesia",
            "searchName": "HOI-Hao Island-Hao Adasi Havalimani--Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "HHZ",
            "countryCode": "PF",
            "cityCode": "HHZ",
            "cityName": "Hikueru",
            "name": "[HHZ] - Hikueru - HHZ - Hikueru - French Polynesia",
            "searchName": "HHZ-Hikueru-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "GMR",
            "countryCode": "PF",
            "cityCode": "GMR",
            "cityName": "Gambier Island",
            "name": "[GMR] - Gambier Island - GMR - Gambier Island - French Polynesia",
            "searchName": "GMR-Gambier Is-Gambier Island-Gambier Adasi Havalimani-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "FGU",
            "countryCode": "PF",
            "cityCode": "FGU",
            "cityName": "Fangatau",
            "name": "[FGU] - Fangatau - FGU - Fangatau - French Polynesia",
            "searchName": "FGU-Fangatau-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "FHZ",
            "countryCode": "PF",
            "cityCode": "FHZ",
            "cityName": "Fakahina",
            "name": "[FHZ] - Fakahina - FHZ - Fakahina - French Polynesia",
            "searchName": "FHZ-Fakahina-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "FAV",
            "countryCode": "PF",
            "cityCode": "FAV",
            "cityName": "Fakarava",
            "name": "[FAV] - Fakarava - FAV - Fakarava - French Polynesia",
            "searchName": "FAV-Fakarava-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "FAC",
            "countryCode": "PF",
            "cityCode": "FAC",
            "cityName": "Faaite",
            "name": "[FAC] - Faaite - FAC - Faaite - French Polynesia",
            "searchName": "FAC-Faaite-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "OMM",
            "countryCode": "OM",
            "cityCode": "OMM",
            "cityName": "Marmul",
            "name": "[OMM] - Marmul - OMM - Marmul - Oman",
            "searchName": "OMM-Marmul-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "RNM",
            "countryCode": "OM",
            "cityCode": "RNM",
            "cityName": "Qarn Alam",
            "name": "[RNM] - Qarn Alam - RNM - Qarn Alam - Oman",
            "searchName": "RNM-Qarn Alam-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "RMB",
            "countryCode": "OM",
            "cityCode": "RMB",
            "cityName": "Buraimi",
            "name": "[RMB] - Buraimi - RMB - Buraimi - Oman",
            "searchName": "RMB-Buraimi-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "TTH",
            "countryCode": "OM",
            "cityCode": "TTH",
            "cityName": "Thumrait",
            "name": "[TTH] - Thumrait - TTH - Thumrait - Oman",
            "searchName": "TTH-Thumrait-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "SLL",
            "countryCode": "OM",
            "cityCode": "SLL",
            "cityName": "Salalah",
            "name": "[SLL] - Salalah - SLL - Salalah - Oman",
            "searchName": "SLL-Salalah-Salalah Airport-Salala-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "SUH",
            "countryCode": "OM",
            "cityCode": "SUH",
            "cityName": "Sur",
            "name": "[SUH] - Sur - SUH - Sur - Oman",
            "searchName": "SUH-Sur-Sur Havalimani-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "UKH",
            "countryCode": "OM",
            "cityCode": "UKH",
            "cityName": "Mukhaizna",
            "name": "[UKH] - Mukhaizna - UKH - Mukhaizna - Oman",
            "searchName": "UKH-Mukhaizna-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "AHW",
            "countryCode": "OM",
            "cityCode": "AHW",
            "cityName": "Saih Rawl",
            "name": "[AHW] - Saih Rawl - AHW - Saih Rawl - Oman",
            "searchName": "AHW-Saih Rawl-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "AOM",
            "countryCode": "OM",
            "cityCode": "AOM",
            "cityName": "Adam",
            "name": "[AOM] - Adam - AOM - Adam - Oman",
            "searchName": "AOM-Adam-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "BJQ",
            "countryCode": "OM",
            "cityCode": "BJQ",
            "cityName": "Bahja",
            "name": "[BJQ] - Bahja - BJQ - Bahja - Oman",
            "searchName": "BJQ-Bahja-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "BYB",
            "countryCode": "OM",
            "cityCode": "BYB",
            "cityName": "Dibaa",
            "name": "[BYB] - Dibaa - BYB - Dibaa - Oman",
            "searchName": "BYB-Dibaa-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "FAU",
            "countryCode": "OM",
            "cityCode": "FAU",
            "cityName": "Fahud",
            "name": "[FAU] - Fahud - FAU - Fahud - Oman",
            "searchName": "FAU-Fahud-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "KHS",
            "countryCode": "OM",
            "cityCode": "KHS",
            "cityName": "Khasab",
            "name": "[KHS] - Khasab - KHS - Khasab - Oman",
            "searchName": "KHS-Khasab-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "JNJ",
            "countryCode": "OM",
            "cityCode": "JNJ",
            "cityName": "Duqm",
            "name": "[JNJ] - Jaaluni - JNJ - Duqm - Oman",
            "searchName": "JNJ-Jaaluni-Duqm-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "LKW",
            "countryCode": "OM",
            "cityCode": "LKW",
            "cityName": "Lekhwair",
            "name": "[LKW] - Lekhwair - LKW - Lekhwair - Oman",
            "searchName": "LKW-Lekhawair-Lekhwair-Oman-Ô man-Omã-Omán-Omaan-Omana-Omanas-Umman-",
            "priority": 0
        },
        {
            "iataCode": "JQE",
            "countryCode": "PA",
            "cityCode": "JQE",
            "cityName": "Jaque",
            "name": "[JQE] - Jaque - JQE - Jaque - Panama",
            "searchName": "JQE-Jaque-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "GHE",
            "countryCode": "PA",
            "cityCode": "GHE",
            "cityName": "Garachine",
            "name": "[GHE] - Garachine - GHE - Garachine - Panama",
            "searchName": "GHE-Garachine-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "HOW",
            "countryCode": "PA",
            "cityCode": "HOW",
            "cityName": "Fort Kobbe",
            "name": "[HOW] - Howard AFB - HOW - Fort Kobbe - Panama",
            "searchName": "HOW-Howard AFB-Fort Kobbe-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "IVI",
            "countryCode": "PA",
            "cityCode": "IVI",
            "cityName": "Isla Viveros",
            "name": "[IVI] - Isla Viveros - IVI - Isla Viveros - Panama",
            "searchName": "IVI-Isla Viveros-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "ELE",
            "countryCode": "PA",
            "cityCode": "ELE",
            "cityName": "El Real",
            "name": "[ELE] - El Real - ELE - El Real - Panama",
            "searchName": "ELE-El Real-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "DAV",
            "countryCode": "PA",
            "cityCode": "DAV",
            "cityName": "David",
            "name": "[DAV] - Enrique Malek - DAV - David - Panama",
            "searchName": "DAV-Enrique Malek-David-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "CHX",
            "countryCode": "PA",
            "cityCode": "CHX",
            "cityName": "Changuinola",
            "name": "[CHX] - Changuinola - CHX - Changuinola - Panama",
            "searchName": "CHX-Changuinola-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "CDE",
            "countryCode": "PA",
            "cityCode": "CDE",
            "cityName": "Caledonia",
            "name": "[CDE] - Caledonia - CDE - Caledonia - Panama",
            "searchName": "CDE-Caledonia-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "CZJ",
            "countryCode": "PA",
            "cityCode": "CZJ",
            "cityName": "Corazon De Jesus",
            "name": "[CZJ] - Corazon De Jesus - CZJ - Corazon De Jesus - Panama",
            "searchName": "CZJ-Corazon De Jesus-Corazon de Jesus-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "CTD",
            "countryCode": "PA",
            "cityCode": "CTD",
            "cityName": "Chitre",
            "name": "[CTD] - Chitre - CTD - Chitre - Panama",
            "searchName": "CTD-Chitre-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "CTE",
            "countryCode": "PA",
            "cityCode": "CTE",
            "cityName": "Carti",
            "name": "[CTE] - Carti - CTE - Carti - Panama",
            "searchName": "CTE-Carti-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "BOC",
            "countryCode": "PA",
            "cityCode": "BOC",
            "cityName": "Bocas Del Toro",
            "name": "[BOC] - Bocas Del Toro - BOC - Bocas Del Toro - Panama",
            "searchName": "BOC-Bocas Del Toro-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "BLB",
            "countryCode": "PA",
            "cityCode": "BLB",
            "cityName": "Balboa",
            "name": "[BLB] - Balboa - BLB - Balboa - Panama",
            "searchName": "BLB-Balboa-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "BFQ",
            "countryCode": "PA",
            "cityCode": "BFQ",
            "cityName": "Bahia Pinas",
            "name": "[BFQ] - Bahia Pinas - BFQ - Bahia Pinas - Panama",
            "searchName": "BFQ-Bahia Pinas-Puerto Pina-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "AIL",
            "countryCode": "PA",
            "cityCode": "AIL",
            "cityName": "Ailigandi",
            "name": "[AIL] - Ailigandi - AIL - Ailigandi - Panama",
            "searchName": "AIL-Ailigandi-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "ACU",
            "countryCode": "PA",
            "cityCode": "ACU",
            "cityName": "Achutupo",
            "name": "[ACU] - Achutupo - ACU - Achutupo - Panama",
            "searchName": "ACU-Achutupo-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "UTU",
            "countryCode": "PA",
            "cityCode": "UTU",
            "cityName": "Ustupo",
            "name": "[UTU] - Ustupo - UTU - Ustupo - Panama",
            "searchName": "UTU-Ustupo-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "SIC",
            "countryCode": "PA",
            "cityCode": "SIC",
            "cityName": "Sinop",
            "name": "[SIC] - Sinop Airport - SIC - Sinop - Panama",
            "searchName": "SIC-Sinop Airport-Sinop Arpt-Sinop-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "SFW",
            "countryCode": "PA",
            "cityCode": "SFW",
            "cityName": "Santa Fe",
            "name": "[SFW] - Santa Fe - SFW - Santa Fe - Panama",
            "searchName": "SFW-Santa Fe-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "SAX",
            "countryCode": "PA",
            "cityCode": "SAX",
            "cityName": "Sambu",
            "name": "[SAX] - Sambu - SAX - Sambu - Panama",
            "searchName": "SAX-Sambu-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "TUE",
            "countryCode": "PA",
            "cityCode": "TUE",
            "cityName": "Tupile",
            "name": "[TUE] - Tupile - TUE - Tupile - Panama",
            "searchName": "TUE-Tupile-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "TUW",
            "countryCode": "PA",
            "cityCode": "TUW",
            "cityName": "Tubala",
            "name": "[TUW] - Tubala - TUW - Tubala - Panama",
            "searchName": "TUW-Tubala-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "TJC",
            "countryCode": "PA",
            "cityCode": "TJC",
            "cityName": "Ticantiki",
            "name": "[TJC] - Ticantiki - TJC - Ticantiki - Panama",
            "searchName": "TJC-Ticantiki-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "SYP",
            "countryCode": "PA",
            "cityCode": "SYP",
            "cityName": "Santiago",
            "name": "[SYP] - Santiago - SYP - Santiago - Panama",
            "searchName": "SYP-Santiago-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "RIZ",
            "countryCode": "PA",
            "cityCode": "RIZ",
            "cityName": "Rio Alzucar",
            "name": "[RIZ] - Rio Alzucar - RIZ - Rio Alzucar - Panama",
            "searchName": "RIZ-Rio Alzucar-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "RIT",
            "countryCode": "PA",
            "cityCode": "RIT",
            "cityName": "Rio Tigre",
            "name": "[RIT] - Rio Tigre - RIT - Rio Tigre - Panama",
            "searchName": "RIT-Rio Tigre-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "RSI",
            "countryCode": "PA",
            "cityCode": "RSI",
            "cityName": "Rio Sidra",
            "name": "[RSI] - Rio Sidra - RSI - Rio Sidra - Panama",
            "searchName": "RSI-Rio Sidra-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "PVE",
            "countryCode": "PA",
            "cityCode": "PVE",
            "cityName": "El Porvenir",
            "name": "[PVE] - El Porvenir - PVE - El Porvenir - Panama",
            "searchName": "PVE-El Porvenir-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "PYC",
            "countryCode": "PA",
            "cityCode": "PYC",
            "cityName": "Playon Chico",
            "name": "[PYC] - Playon Chico - PYC - Playon Chico - Panama",
            "searchName": "PYC-Playon Chico-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "PYV",
            "countryCode": "PA",
            "cityCode": "PYV",
            "cityName": "Yaviza",
            "name": "[PYV] - Yaviza - PYV - Yaviza - Panama",
            "searchName": "PYV-Yaviza-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "PUE",
            "countryCode": "PA",
            "cityCode": "PUE",
            "cityName": "Puerto Obaldia",
            "name": "[PUE] - Puerto Obaldia - PUE - Puerto Obaldia - Panama",
            "searchName": "PUE-Puerto Obaldia-Panama-Panamà-Panamá",
            "priority": 0
        },
        {
            "iataCode": "JMO",
            "countryCode": "NP",
            "cityCode": "JMO",
            "cityName": "Jomsom",
            "name": "[JMO] - Jomsom - JMO - Jomsom - Nepal",
            "searchName": "JMO-Jomsom-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "JKR",
            "countryCode": "NP",
            "cityCode": "JKR",
            "cityName": "Janakpur",
            "name": "[JKR] - Janakpur - JKR - Janakpur - Nepal",
            "searchName": "JKR-Janakpur-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "JIR",
            "countryCode": "NP",
            "cityCode": "JIR",
            "cityName": "Jiri",
            "name": "[JIR] - Jiri - JIR - Jiri - Nepal",
            "searchName": "JIR-Jiri-Jiri Airport-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "JUM",
            "countryCode": "NP",
            "cityCode": "JUM",
            "cityName": "Jumla",
            "name": "[JUM] - Jumla - JUM - Jumla - Nepal",
            "searchName": "JUM-Jumla-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "KEP",
            "countryCode": "NP",
            "cityCode": "KEP",
            "cityName": "Nepalganj",
            "name": "[KEP] - Nepalganj - KEP - Nepalganj - Nepal",
            "searchName": "KEP-Nepalganj-Nepalgunj Airport-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "LUA",
            "countryCode": "NP",
            "cityCode": "LUA",
            "cityName": "Lukla",
            "name": "[LUA] - Lukla - LUA - Lukla - Nepal",
            "searchName": "LUA-Lukla-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "LTG",
            "countryCode": "NP",
            "cityCode": "LTG",
            "cityName": "Langtang",
            "name": "[LTG] - Langtang - LTG - Langtang - Nepal",
            "searchName": "LTG-Langtang-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "LDN",
            "countryCode": "NP",
            "cityCode": "LDN",
            "cityName": "Lamidanda",
            "name": "[LDN] - Lamidanda - LDN - Lamidanda - Nepal",
            "searchName": "LDN-Lamidanda-Lamidanda Airport-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "HRJ",
            "countryCode": "NP",
            "cityCode": "HRJ",
            "cityName": "Chaurjhari",
            "name": "[HRJ] - Chaurjhari - HRJ - Chaurjhari - Nepal",
            "searchName": "HRJ-Chaurjhari-Chaurjhari Airport-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "IMK",
            "countryCode": "NP",
            "cityCode": "IMK",
            "cityName": "Simikot",
            "name": "[IMK] - Simikot - IMK - Simikot - Nepal",
            "searchName": "IMK-Simikot-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "FEB",
            "countryCode": "NP",
            "cityCode": "FEB",
            "cityName": "Sanfebagar",
            "name": "[FEB] - Sanfebagar - FEB - Sanfebagar - Nepal",
            "searchName": "FEB-Sanfebagar-Sanfebagar Airport-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "GKH",
            "countryCode": "NP",
            "cityCode": "GKH",
            "cityName": "Gorkha",
            "name": "[GKH] - Gorkha - GKH - Gorkha - Nepal",
            "searchName": "GKH-Gorkha-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "INU",
            "countryCode": "NR",
            "cityCode": "INU",
            "cityName": "Nauru Island",
            "name": "[INU] - Nauru International - INU - Nauru Island - Nauru",
            "searchName": "INU-Nauru Island-Nauru International--Nauru",
            "priority": 0
        },
        {
            "iataCode": "IUE",
            "countryCode": "NU",
            "cityCode": "IUE",
            "cityName": "Niue Island",
            "name": "[IUE] - Hanan - IUE - Niue Island - Niue",
            "searchName": "IUE-Hanan-Niue Island--Niue-Isla Niue",
            "priority": 0
        },
        {
            "iataCode": "IVC",
            "countryCode": "NZ",
            "cityCode": "IVC",
            "cityName": "Invercargill",
            "name": "[IVC] - Invercargill - IVC - Invercargill - New Zealand",
            "searchName": "IVC-Invercargill-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "HLZ",
            "countryCode": "NZ",
            "cityCode": "HLZ",
            "cityName": "Hamilton",
            "name": "[HLZ] - Hamilton - HLZ - Hamilton - New Zealand",
            "searchName": "HLZ-Hamilton Neuseeland-Hamilton-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "HKK",
            "countryCode": "NZ",
            "cityCode": "HKK",
            "cityName": "Hokitika",
            "name": "[HKK] - Hokitika Airport - HKK - Hokitika - New Zealand",
            "searchName": "HKK-Hokitika-Hokitika Airport-Hokitika Arpt-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "GIS",
            "countryCode": "NZ",
            "cityCode": "GIS",
            "cityName": "Gisborne",
            "name": "[GIS] - Gisborne - GIS - Gisborne - New Zealand",
            "searchName": "GIS-Gisborne-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "GMN",
            "countryCode": "NZ",
            "cityCode": "GMN",
            "cityName": "Greymouth",
            "name": "[GMN] - Greymouth - GMN - Greymouth - New Zealand",
            "searchName": "GMN-Greymouth-Greymouth Havalimani-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "FGL",
            "countryCode": "NZ",
            "cityCode": "FGL",
            "cityName": "Fox Glacier",
            "name": "[FGL] - Fox Glacier - FGL - Fox Glacier - New Zealand",
            "searchName": "FGL-Fox Glacier-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "GBZ",
            "countryCode": "NZ",
            "cityCode": "GBZ",
            "cityName": "Great Barrier Island",
            "name": "[GBZ] - Great Barrier Island - GBZ - Great Barrier Island - New Zealand",
            "searchName": "GBZ-Great Barrier Island-Great Barrier Adasi Havalimani-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "GBS",
            "countryCode": "NZ",
            "cityCode": "GBS",
            "cityName": "Port Fitzroy",
            "name": "[GBS] - Port Fitzroy - GBS - Port Fitzroy - New Zealand",
            "searchName": "GBS-Port Fitzroy-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "KUI",
            "countryCode": "NZ",
            "cityCode": "KUI",
            "cityName": "Kawau Island",
            "name": "[KUI] - Kawau Island - KUI - Kawau Island - New Zealand",
            "searchName": "KUI-Kawau Island-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "KWU",
            "countryCode": "NZ",
            "cityCode": "KWU",
            "cityName": "Mansion House",
            "name": "[KWU] - Mansion House - KWU - Mansion House - New Zealand",
            "searchName": "KWU-Mansion House-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "KBZ",
            "countryCode": "NZ",
            "cityCode": "KBZ",
            "cityName": "Kaikoura",
            "name": "[KBZ] - Kaikoura - KBZ - Kaikoura - New Zealand",
            "searchName": "KBZ-Kaikoura-Kaikoura Havalimani-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "KAT",
            "countryCode": "NZ",
            "cityCode": "KAT",
            "cityName": "Kaitaia",
            "name": "[KAT] - Kaitaia - KAT - Kaitaia - New Zealand",
            "searchName": "KAT-Kaitaia-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "KTF",
            "countryCode": "NZ",
            "cityCode": "KTF",
            "cityName": "Takaka",
            "name": "[KTF] - Takaka - KTF - Takaka - New Zealand",
            "searchName": "KTF-Takaka-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "KKO",
            "countryCode": "NZ",
            "cityCode": "KKO",
            "cityName": "Kaikohe",
            "name": "[KKO] - Kaikohe - KKO - Kaikohe - New Zealand",
            "searchName": "KKO-Kaikohe-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "KKE",
            "countryCode": "NZ",
            "cityCode": "KKE",
            "cityName": "Kerikeri",
            "name": "[KKE] - Kerikeri/Bay of Islands - KKE - Kerikeri - New Zealand",
            "searchName": "KKE-Kerikeri-Kerikeri/Bay of Islands-Kerikeri Havalimani-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "CMV",
            "countryCode": "NZ",
            "cityCode": "CMV",
            "cityName": "Coromandel",
            "name": "[CMV] - Coromandel - CMV - Coromandel - New Zealand",
            "searchName": "CMV-Coromandel-Coromandel Havalimani-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "MHB",
            "countryCode": "NZ",
            "cityCode": "AKL",
            "cityName": "Auckland",
            "name": "[MHB] - Mechanics Bay - AKL - Auckland - New Zealand",
            "searchName": "MHB-AKL-Mechanics Bay-Auckland-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "XNZ",
            "countryCode": "NZ",
            "cityCode": "AKL",
            "cityName": "Auckland",
            "name": "[XNZ] - Auckland Ferry Port - AKL - Auckland - New Zealand",
            "searchName": "XNZ-AKL-Auckland Ferry Port-Auckland-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "ALR",
            "countryCode": "NZ",
            "cityCode": "ALR",
            "cityName": "Alexandra",
            "name": "[ALR] - Alexandra - ALR - Alexandra - New Zealand",
            "searchName": "ALR-Alexandra-Alexandra Havalimani-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "WSZ",
            "countryCode": "NZ",
            "cityCode": "WSZ",
            "cityName": "Westport",
            "name": "[WSZ] - Westport - WSZ - Westport - New Zealand",
            "searchName": "WSZ-Westport-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "WTZ",
            "countryCode": "NZ",
            "cityCode": "WTZ",
            "cityName": "Whitianga",
            "name": "[WTZ] - Whitianga - WTZ - Whitianga - New Zealand",
            "searchName": "WTZ-Whitianga-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "WAG",
            "countryCode": "NZ",
            "cityCode": "WAG",
            "cityName": "Wanganui",
            "name": "[WAG] - Wanganui - WAG - Wanganui - New Zealand",
            "searchName": "WAG-Wanganui-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "WRE",
            "countryCode": "NZ",
            "cityCode": "WRE",
            "cityName": "Whangarei",
            "name": "[WRE] - Whangarei - WRE - Whangarei - New Zealand",
            "searchName": "WRE-Whangarei-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "WGN",
            "countryCode": "NZ",
            "cityCode": "WGN",
            "cityName": "Waitangi",
            "name": "[WGN] - Waitangi - WGN - Waitangi - New Zealand",
            "searchName": "WGN-Waitangi-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "WIK",
            "countryCode": "NZ",
            "cityCode": "WIK",
            "cityName": "Surfdale",
            "name": "[WIK] - Surfdale - WIK - Surfdale - New Zealand",
            "searchName": "WIK-Surfdale-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "WIR",
            "countryCode": "NZ",
            "cityCode": "WIR",
            "cityName": "Wairoa",
            "name": "[WIR] - Wairoa - WIR - Wairoa - New Zealand",
            "searchName": "WIR-Wairoa-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "WHO",
            "countryCode": "NZ",
            "cityCode": "WHO",
            "cityName": "Franz Josef",
            "name": "[WHO] - Franz Josef - WHO - Franz Josef - New Zealand",
            "searchName": "WHO-Franz Josef-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "WHK",
            "countryCode": "NZ",
            "cityCode": "WHK",
            "cityName": "Whakatane",
            "name": "[WHK] - Whakatane - WHK - Whakatane - New Zealand",
            "searchName": "WHK-Whakatane-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "WKA",
            "countryCode": "NZ",
            "cityCode": "WKA",
            "cityName": "Wanaka",
            "name": "[WKA] - Wanaka - WKA - Wanaka - New Zealand",
            "searchName": "WKA-Wanaka-Nova Zelândia-Nieuw-Zeeland-New Zealand-Nya Zeeland-Uusi-Seelanti-Nouvelle-Zélande-Neuseeland-Nuova Zelanda-Nueva Zelanda--Yeni Zelanda",
            "priority": 0
        },
        {
            "iataCode": "MEH",
            "countryCode": "NO",
            "cityCode": "MEH",
            "cityName": "Mehamn",
            "name": "[MEH] - Mehamn - MEH - Mehamn - Norway",
            "searchName": "MEH-Mehamn-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "NVK",
            "countryCode": "NO",
            "cityCode": "NVK",
            "cityName": "Narvik",
            "name": "[NVK] - Framnes - NVK - Narvik - Norway",
            "searchName": "NVK-Narvik-Framnes-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "NTB",
            "countryCode": "NO",
            "cityCode": "NTB",
            "cityName": "Notodden",
            "name": "[NTB] - Notodden - NTB - Notodden - Norway",
            "searchName": "NTB-Notodden-Notodden Havalimani-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "OLA",
            "countryCode": "NO",
            "cityCode": "OLA",
            "cityName": "Orland",
            "name": "[OLA] - Orland - OLA - Orland - Norway",
            "searchName": "OLA-Orland-Orland\u00A0 Havalimani-Noruega-Noorwegen-Na Uy-Norway-Norwegen-Norvège-Norvegia-Norge-Norwegia-Norvegija-Norve-Norveç",
            "priority": 0
        },
        {
            "iataCode": "NGX",
            "countryCode": "NP",
            "cityCode": "NGX",
            "cityName": "Manang",
            "name": "[NGX] - Manang - NGX - Manang - Nepal",
            "searchName": "NGX-Manang-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "MWP",
            "countryCode": "NP",
            "cityCode": "MWP",
            "cityName": "Mountain",
            "name": "[MWP] - Mountain - MWP - Mountain - Nepal",
            "searchName": "MWP-Mountain-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "MEY",
            "countryCode": "NP",
            "cityCode": "MEY",
            "cityName": "Meghauli",
            "name": "[MEY] - Meghauli - MEY - Meghauli - Nepal",
            "searchName": "MEY-Meghauli-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "PKR",
            "countryCode": "NP",
            "cityCode": "PKR",
            "cityName": "Pokhara",
            "name": "[PKR] - Pokhara - PKR - Pokhara - Nepal",
            "searchName": "PKR-Pokhara-Nepál-Nepal-Nepalas-Nepala-Népal-Nê pan",
            "priority": 0
        },
        {
            "iataCode": "IPE",
            "countryCode": "PH",
            "cityCode": "IPE",
            "cityName": "Ipil",
            "name": "[IPE] - Ipil - IPE - Ipil - Philippines",
            "searchName": "IPE-Ipil-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "IGN",
            "countryCode": "PH",
            "cityCode": "IGN",
            "cityName": "Iligan",
            "name": "[IGN] - Maria Cristina - IGN - Iligan - Philippines",
            "searchName": "IGN-Maria Cristina-Iligan-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "ILO",
            "countryCode": "PH",
            "cityCode": "ILO",
            "cityName": "Iloilo",
            "name": "[ILO] - Iloilo International - ILO - Iloilo - Philippines",
            "searchName": "ILO-Iloilo-Iloilo International-Mandurriao-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "ICO",
            "countryCode": "PH",
            "cityCode": "ICO",
            "cityName": "Sicogon Island",
            "name": "[ICO] - Sicogon Island - ICO - Sicogon Island - Philippines",
            "searchName": "ICO-Sicogon Island-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "IAO",
            "countryCode": "PH",
            "cityCode": "IAO",
            "cityName": "Del Carmen",
            "name": "[IAO] - Sayak Airport - IAO - Del Carmen - Philippines",
            "searchName": "IAO-Sayak Airport-Del Carmen-General Luna-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "GES",
            "countryCode": "PH",
            "cityCode": "GES",
            "cityName": "General Santos",
            "name": "[GES] - General Santos International - GES - General Santos - Philippines",
            "searchName": "GES-General Santos-General Santos International-Buayan-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CRM",
            "countryCode": "PH",
            "cityCode": "CRM",
            "cityName": "Catarman",
            "name": "[CRM] - National - CRM - Catarman - Philippines",
            "searchName": "CRM-Catarman-National-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CYZ",
            "countryCode": "PH",
            "cityCode": "CYZ",
            "cityName": "Cauayan",
            "name": "[CYZ] - Cauayan - CYZ - Cauayan - Philippines",
            "searchName": "CYZ-Cauayan-Cauayan Havalimani-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CYU",
            "countryCode": "PH",
            "cityCode": "CYU",
            "cityName": "Cuyo",
            "name": "[CYU] - Cuyo - CYU - Cuyo - Philippines",
            "searchName": "CYU-Cuyo-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CYP",
            "countryCode": "PH",
            "cityCode": "CYP",
            "cityName": "Calbayog",
            "name": "[CYP] - Calbayog - CYP - Calbayog - Philippines",
            "searchName": "CYP-Calbayog-Calbayog Havalimani-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CUJ",
            "countryCode": "PH",
            "cityCode": "CUJ",
            "cityName": "Culion",
            "name": "[CUJ] - Culion - CUJ - Culion - Philippines",
            "searchName": "CUJ-Culion-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CGM",
            "countryCode": "PH",
            "cityCode": "CGM",
            "cityName": "Camiguin",
            "name": "[CGM] - Mambajao - CGM - Camiguin - Philippines",
            "searchName": "CGM-Mambajao-Camiguin-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CGG",
            "countryCode": "PH",
            "cityCode": "CGG",
            "cityName": "Casiguran",
            "name": "[CGG] - Casiguran - CGG - Casiguran - Philippines",
            "searchName": "CGG-Casiguran-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CGY",
            "countryCode": "PH",
            "cityCode": "CGY",
            "cityName": "Cagayan De Oro",
            "name": "[CGY] - Cagayan De Oro Domestic Airport - CGY - Cagayan De Oro - Philippines",
            "searchName": "CGY-Cagayan de Oro-Cagayan De Oro Domestic Airport-Lumbia-Cagayan De Oro-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CDY",
            "countryCode": "PH",
            "cityCode": "CDY",
            "cityName": "Cagayan De Sulu",
            "name": "[CDY] - Cagayan De Sulu - CDY - Cagayan De Sulu - Philippines",
            "searchName": "CDY-Cagayan De Sulu-Cagayan de Sulu-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CEB",
            "countryCode": "PH",
            "cityCode": "CEB",
            "cityName": "Cebu",
            "name": "[CEB] - Mactan-Cebu International - CEB - Cebu - Philippines",
            "searchName": "CEB-Cebu-Mactan-Cebu International-Mactan International-Matan International-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CBO",
            "countryCode": "PH",
            "cityCode": "CBO",
            "cityName": "Cotabato",
            "name": "[CBO] - Awang - CBO - Cotabato - Philippines",
            "searchName": "CBO-Cotabato-Awang-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "DPL",
            "countryCode": "PH",
            "cityCode": "DPL",
            "cityName": "Dipolog",
            "name": "[DPL] - Dipolog - DPL - Dipolog - Philippines",
            "searchName": "DPL-Dipolog-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "DSG",
            "countryCode": "PH",
            "cityCode": "DSG",
            "cityName": "Dilasag",
            "name": "[DSG] - Dilasag - DSG - Dilasag - Philippines",
            "searchName": "DSG-Dilasag-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "DTE",
            "countryCode": "PH",
            "cityCode": "DTE",
            "cityName": "Daet",
            "name": "[DTE] - Naga - DTE - Daet - Philippines",
            "searchName": "DTE-Camarines Norte-Naga-Daet-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "DVO",
            "countryCode": "PH",
            "cityCode": "DVO",
            "cityName": "Davao",
            "name": "[DVO] - Francisco Bangoy International - DVO - Davao - Philippines",
            "searchName": "DVO-Davao City-Francisco Bangoy International-Francisco Bangoy-Mati-Davao-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "DGT",
            "countryCode": "PH",
            "cityCode": "DGT",
            "cityName": "Dumaguete",
            "name": "[DGT] - Dumaguete - DGT - Dumaguete - Philippines",
            "searchName": "DGT-Dumaguete-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "ENI",
            "countryCode": "PH",
            "cityCode": "ENI",
            "cityName": "El Nido",
            "name": "[ENI] - El Nido - ENI - El Nido - Philippines",
            "searchName": "ENI-El Nido-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "EUQ",
            "countryCode": "PH",
            "cityCode": "EUQ",
            "cityName": "Antique",
            "name": "[EUQ] - Evelio Javier - EUQ - Antique - Philippines",
            "searchName": "EUQ-Evelio Javier-Antique-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "BCD",
            "countryCode": "PH",
            "cityCode": "BCD",
            "cityName": "Bacolod",
            "name": "[BCD] - Bacolod - BCD - Bacolod - Philippines",
            "searchName": "BCD-Bacolod-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "BXU",
            "countryCode": "PH",
            "cityCode": "BXU",
            "cityName": "Butuan",
            "name": "[BXU] - Butuan - BXU - Butuan - Philippines",
            "searchName": "BXU-Butuan-Butuan City-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "BSO",
            "countryCode": "PH",
            "cityCode": "BSO",
            "cityName": "Basco",
            "name": "[BSO] - Basco - BSO - Basco - Philippines",
            "searchName": "BSO-Basco-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "BPH",
            "countryCode": "PH",
            "cityCode": "BPH",
            "cityName": "Bislig",
            "name": "[BPH] - Bislig - BPH - Bislig - Philippines",
            "searchName": "BPH-Bislig-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "BQA",
            "countryCode": "PH",
            "cityCode": "BQA",
            "cityName": "Baler",
            "name": "[BQA] - Baler - BQA - Baler - Philippines",
            "searchName": "BQA-Baler-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "BAG",
            "countryCode": "PH",
            "cityCode": "BAG",
            "cityName": "Baguio",
            "name": "[BAG] - Loakan - BAG - Baguio - Philippines",
            "searchName": "BAG-Loakan-Loakan Havalimani-Baguio-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "AAV",
            "countryCode": "PH",
            "cityCode": "AAV",
            "cityName": "Surallah",
            "name": "[AAV] - Allah Valley - AAV - Surallah - Philippines",
            "searchName": "AAV-Allah Valley-Alah-Surallah-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "WNP",
            "countryCode": "PH",
            "cityCode": "WNP",
            "cityName": "Naga",
            "name": "[WNP] - Naga - WNP - Naga - Philippines",
            "searchName": "WNP-Naga-Naga City-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "VRC",
            "countryCode": "PH",
            "cityCode": "VRC",
            "cityName": "Virac",
            "name": "[VRC] - Virac - VRC - Virac - Philippines",
            "searchName": "VRC-Virac-Virac\u00A0 Havalimani-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "USU",
            "countryCode": "PH",
            "cityCode": "USU",
            "cityName": "Busuanga",
            "name": "[USU] - Francisco Reyes - USU - Busuanga - Philippines",
            "searchName": "USU-Busuanga-Francisco Reyes-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "XCN",
            "countryCode": "PH",
            "cityCode": "XCN",
            "cityName": "Puerto Princesa City",
            "name": "[XCN] - Coron Airport - XCN - Puerto Princesa City - Philippines",
            "searchName": "XCN-Coron Airport-Puerto Princesa City-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "XMA",
            "countryCode": "PH",
            "cityCode": "XMA",
            "cityName": "Maramag",
            "name": "[XMA] - Maramag - XMA - Maramag - Philippines",
            "searchName": "XMA-Maramag-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "XSO",
            "countryCode": "PH",
            "cityCode": "XSO",
            "cityName": "Siocon",
            "name": "[XSO] - Siocon - XSO - Siocon - Philippines",
            "searchName": "XSO-Siocon-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "SFS",
            "countryCode": "PH",
            "cityCode": "SFS",
            "cityName": "Subic Bay",
            "name": "[SFS] - Subic Bay International - SFS - Subic Bay - Philippines",
            "searchName": "SFS-Internationaler Flughafen-Subic Bay International-Subic Bay Uluslararasi Havalimani-Subic Bay-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "SFE",
            "countryCode": "PH",
            "cityCode": "SFE",
            "cityName": "San Fernando",
            "name": "[SFE] - San Fernando - SFE - San Fernando - Philippines",
            "searchName": "SFE-San Fernando-San Fernando Havalimani-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "RZP",
            "countryCode": "PH",
            "cityCode": "RZP",
            "cityName": "Taytay Sandoval",
            "name": "[RZP] - CLR Airport - RZP - Taytay Sandoval - Philippines",
            "searchName": "RZP-CLR Airport-Taytay Havalimani-Taytay Sandoval-Taytay-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "SGS",
            "countryCode": "PH",
            "cityCode": "SGS",
            "cityName": "Sanga Sanga",
            "name": "[SGS] - Sanga Sanga - SGS - Sanga Sanga - Philippines",
            "searchName": "SGS-Sanga Sanga-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "SJI",
            "countryCode": "PH",
            "cityCode": "SJI",
            "cityName": "San Jose",
            "name": "[SJI] - San Jose - SJI - San Jose - Philippines",
            "searchName": "SJI-San Jose-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "SSV",
            "countryCode": "PH",
            "cityCode": "SSV",
            "cityName": "Siasi",
            "name": "[SSV] - Siasi - SSV - Siasi - Philippines",
            "searchName": "SSV-Siasi-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "SUG",
            "countryCode": "PH",
            "cityCode": "SUG",
            "cityName": "Surigao",
            "name": "[SUG] - Surigao - SUG - Surigao - Philippines",
            "searchName": "SUG-Surigao-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "TWT",
            "countryCode": "PH",
            "cityCode": "TWT",
            "cityName": "Tawitawi",
            "name": "[TWT] - Tawitawi - TWT - Tawitawi - Philippines",
            "searchName": "TWT-Tawitawi-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "TUG",
            "countryCode": "PH",
            "cityCode": "TUG",
            "cityName": "Tuguegarao",
            "name": "[TUG] - Tuguegarao - TUG - Tuguegarao - Philippines",
            "searchName": "TUG-Tuguegarao-Tuguegarao City-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "TBH",
            "countryCode": "PH",
            "cityCode": "TBH",
            "cityName": "Tablas",
            "name": "[TBH] - Romblon - TBH - Tablas - Philippines",
            "searchName": "TBH-Tablas-Romblon-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "TAG",
            "countryCode": "PH",
            "cityCode": "TAG",
            "cityName": "Tagbilaran",
            "name": "[TAG] - Tagbilaran - TAG - Tagbilaran - Philippines",
            "searchName": "TAG-Tagbilaran-Tagbilaran Havalimani-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "TAC",
            "countryCode": "PH",
            "cityCode": "TAC",
            "cityName": "Tacloban",
            "name": "[TAC] - D.Z. Romualdez - TAC - Tacloban - Philippines",
            "searchName": "TAC-Tacloban-D.Z. Romualdez-Tacloban City-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "TDG",
            "countryCode": "PH",
            "cityCode": "TDG",
            "cityName": "Tandag",
            "name": "[TDG] - Tandag - TDG - Tandag - Philippines",
            "searchName": "TDG-Tandag-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "TGB",
            "countryCode": "PH",
            "cityCode": "TGB",
            "cityName": "Tagbita",
            "name": "[TGB] - Tagbita - TGB - Tagbita - Philippines",
            "searchName": "TGB-Tagbita-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "RXS",
            "countryCode": "PH",
            "cityCode": "RXS",
            "cityName": "Roxas City",
            "name": "[RXS] - Roxas City - RXS - Roxas City - Philippines",
            "searchName": "RXS-Roxas City-Roxas City Havalimani-Roxas-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "OMC",
            "countryCode": "PH",
            "cityCode": "OMC",
            "cityName": "Ormoc",
            "name": "[OMC] - Ormoc - OMC - Ormoc - Philippines",
            "searchName": "OMC-Ormoc-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "OZC",
            "countryCode": "PH",
            "cityCode": "OZC",
            "cityName": "Ozamis City",
            "name": "[OZC] - Labo - OZC - Ozamis City - Philippines",
            "searchName": "OZC-Ozamis City-Labo-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "PAG",
            "countryCode": "PH",
            "cityCode": "PAG",
            "cityName": "Pagadian",
            "name": "[PAG] - Pagadian - PAG - Pagadian - Philippines",
            "searchName": "PAG-Pagadian-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "PPS",
            "countryCode": "PH",
            "cityCode": "PPS",
            "cityName": "Puerto Princesa",
            "name": "[PPS] - Puerto Princesa - PPS - Puerto Princesa - Philippines",
            "searchName": "PPS-Puerto Princesa-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "MBT",
            "countryCode": "PH",
            "cityCode": "MBT",
            "cityName": "Masbate",
            "name": "[MBT] - Masbate - MBT - Masbate - Philippines",
            "searchName": "MBT-Masbate-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "MBO",
            "countryCode": "PH",
            "cityCode": "MBO",
            "cityName": "Mamburao",
            "name": "[MBO] - Mamburao - MBO - Mamburao - Philippines",
            "searchName": "MBO-Mamburao-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "MRQ",
            "countryCode": "PH",
            "cityCode": "MRQ",
            "cityName": "Marinduque",
            "name": "[MRQ] - Marinduque - MRQ - Marinduque - Philippines",
            "searchName": "MRQ-Marinduque-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "MPH",
            "countryCode": "PH",
            "cityCode": "MPH",
            "cityName": "Caticlan",
            "name": "[MPH] - Malay - MPH - Caticlan - Philippines",
            "searchName": "MPH-Malay-Caticlan-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "SGL",
            "countryCode": "PH",
            "cityCode": "MNL",
            "cityName": "Manila",
            "name": "[SGL] - Sangley Point NAS - MNL - Manila - Philippines",
            "searchName": "SGL-MNL-Sangley Point NAS-Manila-Manille-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "MLP",
            "countryCode": "PH",
            "cityCode": "MLP",
            "cityName": "Malabang",
            "name": "[MLP] - Malabang - MLP - Malabang - Philippines",
            "searchName": "MLP-Malabang-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "NCP",
            "countryCode": "PH",
            "cityCode": "NCP",
            "cityName": "Luzon Island",
            "name": "[NCP] - Cubi Pt NAS - NCP - Luzon Island - Philippines",
            "searchName": "NCP-Cubi Pt NAS-Luzon Is-Luzon Island-Angeles City-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "CRK",
            "countryCode": "PH",
            "cityCode": "NCP",
            "cityName": "Luzon Island",
            "name": "[CRK] - Diosdado Macapagal International (Clark International) - NCP - Luzon Island - Philippines",
            "searchName": "CRK-NCP-Luzon-Diosdado Macapagal International (Clark International)-Clark Field-Diosdado Macapagal\\/Clark-Luzon Is-Luzon Island-Angeles City-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "MXI",
            "countryCode": "PH",
            "cityCode": "MXI",
            "cityName": "Mati",
            "name": "[MXI] - Imelda Romualdez Marcos - MXI - Mati - Philippines",
            "searchName": "MXI-Imelda Romualdez Marcos-Mati-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "NSP",
            "countryCode": "PH",
            "cityCode": "NSP",
            "cityName": "Sangley Point",
            "name": "[NSP] - NAF - NSP - Sangley Point - Philippines",
            "searchName": "NSP-NAF-Sangley Point-Filippinerne-Filippiinit-Filipíny-Filipinler-Filipines-Fülöp-szigetek-Filipine-Filippinene-Filipinai-Filipinas-Filipini-Filipiinid-Phil-Philippinen-Philippines-Filippine",
            "priority": 0
        },
        {
            "iataCode": "OHT",
            "countryCode": "PK",
            "cityCode": "OHT",
            "cityName": "Kohat",
            "name": "[OHT] - Kohat - OHT - Kohat - Pakistan",
            "searchName": "OHT-Kohat-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "MWD",
            "countryCode": "PK",
            "cityCode": "MWD",
            "cityName": "Mianwali",
            "name": "[MWD] - Mianwali - MWD - Mianwali - Pakistan",
            "searchName": "MWD-Mianwali-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "NHS",
            "countryCode": "PK",
            "cityCode": "NHS",
            "cityName": "Nushki",
            "name": "[NHS] - Nushki - NHS - Nushki - Pakistan",
            "searchName": "NHS-Nushki-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "MPD",
            "countryCode": "PK",
            "cityCode": "MPD",
            "cityName": "Mirpur Khas",
            "name": "[MPD] - Mirpur Khas - MPD - Mirpur Khas - Pakistan",
            "searchName": "MPD-Mirpur Khas-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "MUX",
            "countryCode": "PK",
            "cityCode": "MUX",
            "cityName": "Multan",
            "name": "[MUX] - Multan International - MUX - Multan - Pakistan",
            "searchName": "MUX-Multan-Multan International-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "MJD",
            "countryCode": "PK",
            "cityCode": "MJD",
            "cityName": "Mohenjodaro",
            "name": "[MJD] - Mohenjodaro - MJD - Mohenjodaro - Pakistan",
            "searchName": "MJD-Mohenjodaro-Mohenjo-Daro-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "MFG",
            "countryCode": "PK",
            "cityCode": "MFG",
            "cityName": "Muzaffarabad",
            "name": "[MFG] - Muzaffarabad - MFG - Muzaffarabad - Pakistan",
            "searchName": "MFG-Muzaffarabad-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "PEW",
            "countryCode": "PK",
            "cityCode": "PEW",
            "cityName": "Peshawar",
            "name": "[PEW] - Peshawar - PEW - Peshawar - Pakistan",
            "searchName": "PEW-Peshawar-Peschawar-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "PJG",
            "countryCode": "PK",
            "cityCode": "PJG",
            "cityName": "Panjgur",
            "name": "[PJG] - Panjgur - PJG - Panjgur - Pakistan",
            "searchName": "PJG-Panjgur-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "PAJ",
            "countryCode": "PK",
            "cityCode": "PAJ",
            "cityName": "Para Chinar",
            "name": "[PAJ] - Para Chinar - PAJ - Para Chinar - Pakistan",
            "searchName": "PAJ-Para Chinar-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "ORW",
            "countryCode": "PK",
            "cityCode": "ORW",
            "cityName": "Ormara",
            "name": "[ORW] - Ormara - ORW - Ormara - Pakistan",
            "searchName": "ORW-Ormara-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "RYK",
            "countryCode": "PK",
            "cityCode": "RYK",
            "cityName": "Rahim Yar Khan",
            "name": "[RYK] - Sheikh Zayed International - RYK - Rahim Yar Khan - Pakistan",
            "searchName": "RYK-Rahim Yar Khan-Sheikh Zayed International-Rahimyar Khan-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "RZS",
            "countryCode": "PK",
            "cityCode": "RZS",
            "cityName": "Sawan",
            "name": "[RZS] - Sawan - RZS - Sawan - Pakistan",
            "searchName": "RZS-Sawan-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "REQ",
            "countryCode": "PK",
            "cityCode": "REQ",
            "cityName": "Reko Diq",
            "name": "[REQ] - Reko Diq - REQ - Reko Diq - Pakistan",
            "searchName": "REQ-Reko Diq-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "RAZ",
            "countryCode": "PK",
            "cityCode": "RAZ",
            "cityName": "Rawala Kot",
            "name": "[RAZ] - Rawala Kot - RAZ - Rawala Kot - Pakistan",
            "searchName": "RAZ-Rawala Kot-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "PSI",
            "countryCode": "PK",
            "cityCode": "PSI",
            "cityName": "Pasni",
            "name": "[PSI] - Pasni - PSI - Pasni - Pakistan",
            "searchName": "PSI-Pasni-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "PZH",
            "countryCode": "PK",
            "cityCode": "PZH",
            "cityName": "Zhob",
            "name": "[PZH] - Zhob - PZH - Zhob - Pakistan",
            "searchName": "PZH-Zhob-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "TFT",
            "countryCode": "PK",
            "cityCode": "TFT",
            "cityName": "Taftan",
            "name": "[TFT] - Taftan - TFT - Taftan - Pakistan",
            "searchName": "TFT-Taftan-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "SYW",
            "countryCode": "PK",
            "cityCode": "SYW",
            "cityName": "Sehwen Sharif",
            "name": "[SYW] - Sehwen Sharif - SYW - Sehwen Sharif - Pakistan",
            "searchName": "SYW-Sharif-Sehwen Sharif-Sehwen-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "TUK",
            "countryCode": "PK",
            "cityCode": "TUK",
            "cityName": "Turbat",
            "name": "[TUK] - Turbat International Airport - TUK - Turbat - Pakistan",
            "searchName": "TUK-Turbat-Turbat International Airport-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "TLB",
            "countryCode": "PK",
            "cityCode": "TLB",
            "cityName": "Tarbela",
            "name": "[TLB] - Tarbela - TLB - Tarbela - Pakistan",
            "searchName": "TLB-Tarbela-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "SUL",
            "countryCode": "PK",
            "cityCode": "SUL",
            "cityName": "Sui",
            "name": "[SUL] - Sui - SUL - Sui - Pakistan",
            "searchName": "SUL-Sui-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "SWN",
            "countryCode": "PK",
            "cityCode": "SWN",
            "cityName": "Sahiwal",
            "name": "[SWN] - Sahiwal - SWN - Sahiwal - Pakistan",
            "searchName": "SWN-Sahiwal-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "SWV",
            "countryCode": "PK",
            "cityCode": "SWV",
            "cityName": "Shikarpur",
            "name": "[SWV] - Shikarpur - SWV - Shikarpur - Pakistan",
            "searchName": "SWV-Shikarpur-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "SKT",
            "countryCode": "PK",
            "cityCode": "SKT",
            "cityName": "Sialkot",
            "name": "[SKT] - Sialkot International - SKT - Sialkot - Pakistan",
            "searchName": "SKT-Sialkot-Sialkot International-Sialkot Havalimani-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "SKZ",
            "countryCode": "PK",
            "cityCode": "SKZ",
            "cityName": "Sukkur",
            "name": "[SKZ] - Sukkur - SKZ - Sukkur - Pakistan",
            "searchName": "SKZ-Sukkur-Sukkur\u00A0 Havalimani-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "SGI",
            "countryCode": "PK",
            "cityCode": "SGI",
            "cityName": "Sargodha",
            "name": "[SGI] - Sargodha Airport - SGI - Sargodha - Pakistan",
            "searchName": "SGI-Sargodha Airport-Sargodha-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "BHW",
            "countryCode": "PK",
            "cityCode": "SGI",
            "cityName": "Sargodha",
            "name": "[BHW] - Bhagatanwala Airport - SGI - Sargodha - Pakistan",
            "searchName": "BHW-SGI-Bhagatanwala-Bhagatanwala Airport-Sargodha-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "SBQ",
            "countryCode": "PK",
            "cityCode": "SBQ",
            "cityName": "Sibi",
            "name": "[SBQ] - Sibi - SBQ - Sibi - Pakistan",
            "searchName": "SBQ-Sibi-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "SDT",
            "countryCode": "PK",
            "cityCode": "SDT",
            "cityName": "Saidu Sharif",
            "name": "[SDT] - Saidu Sharif - SDT - Saidu Sharif - Pakistan",
            "searchName": "SDT-Saidu Sharif-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "UET",
            "countryCode": "PK",
            "cityCode": "UET",
            "cityName": "Quetta",
            "name": "[UET] - Quetta - UET - Quetta - Pakistan",
            "searchName": "UET-Quetta-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "WAF",
            "countryCode": "PK",
            "cityCode": "WAF",
            "cityName": "Wana",
            "name": "[WAF] - Wana - WAF - Wana - Pakistan",
            "searchName": "WAF-Wana-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "WNS",
            "countryCode": "PK",
            "cityCode": "WNS",
            "cityName": "Nawabshah",
            "name": "[WNS] - Nawabshah - WNS - Nawabshah - Pakistan",
            "searchName": "WNS-Nawabshah-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "WGB",
            "countryCode": "PK",
            "cityCode": "WGB",
            "cityName": "Bahawalnagar",
            "name": "[WGB] - Bahawalnagar - WGB - Bahawalnagar - Pakistan",
            "searchName": "WGB-Bahawalnagar-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "AAW",
            "countryCode": "PK",
            "cityCode": "AAW",
            "cityName": "Abbottabad",
            "name": "[AAW] - Abbottabad - AAW - Abbottabad - Pakistan",
            "searchName": "AAW-Abbottabad-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "ATG",
            "countryCode": "PK",
            "cityCode": "ATG",
            "cityName": "Attock",
            "name": "[ATG] - Attock - ATG - Attock - Pakistan",
            "searchName": "ATG-Attock-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "BNP",
            "countryCode": "PK",
            "cityCode": "BNP",
            "cityName": "Bannu",
            "name": "[BNP] - Bannu - BNP - Bannu - Pakistan",
            "searchName": "BNP-Bannu-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "BDN",
            "countryCode": "PK",
            "cityCode": "BDN",
            "cityName": "Badin",
            "name": "[BDN] - Talhar - BDN - Badin - Pakistan",
            "searchName": "BDN-Talhar-Badin-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "BHV",
            "countryCode": "PK",
            "cityCode": "BHV",
            "cityName": "Bahawalpur",
            "name": "[BHV] - Bahawalpur - BHV - Bahawalpur - Pakistan",
            "searchName": "BHV-Bahawalpur-Bahavalpur-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "BHC",
            "countryCode": "PK",
            "cityCode": "BHC",
            "cityName": "Bhurban",
            "name": "[BHC] - Bhurban Heliport - BHC - Bhurban - Pakistan",
            "searchName": "BHC-Bhurban Heliport-Bhurban-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "DDU",
            "countryCode": "PK",
            "cityCode": "DDU",
            "cityName": "Dadu",
            "name": "[DDU] - Dadu - DDU - Dadu - Pakistan",
            "searchName": "DDU-Dadu-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "DEA",
            "countryCode": "PK",
            "cityCode": "DEA",
            "cityName": "Dera Ghazi Khan",
            "name": "[DEA] - Dera Ghazi Khan - DEA - Dera Ghazi Khan - Pakistan",
            "searchName": "DEA-Dera Ghazi Khan-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "DBA",
            "countryCode": "PK",
            "cityCode": "DBA",
            "cityName": "Dalbandin",
            "name": "[DBA] - Dalbandin - DBA - Dalbandin - Pakistan",
            "searchName": "DBA-Dalbandin-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "DSK",
            "countryCode": "PK",
            "cityCode": "DSK",
            "cityName": "Dera Ismail Khan",
            "name": "[DSK] - Dera Ismail Khan - DSK - Dera Ismail Khan - Pakistan",
            "searchName": "DSK-Dera Ismail Khan-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "CHB",
            "countryCode": "PK",
            "cityCode": "CHB",
            "cityName": "Chilas",
            "name": "[CHB] - Chilas - CHB - Chilas - Pakistan",
            "searchName": "CHB-Chilas-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "CJL",
            "countryCode": "PK",
            "cityCode": "CJL",
            "cityName": "Chitral",
            "name": "[CJL] - Chitral - CJL - Chitral - Pakistan",
            "searchName": "CJL-Chitral-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "CWP",
            "countryCode": "PK",
            "cityCode": "CWP",
            "cityName": "Campbellpore",
            "name": "[CWP] - Campbellpore - CWP - Campbellpore - Pakistan",
            "searchName": "CWP-Campbellpore-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "GRT",
            "countryCode": "PK",
            "cityCode": "GRT",
            "cityName": "Gujrat",
            "name": "[GRT] - Gujrat - GRT - Gujrat - Pakistan",
            "searchName": "GRT-Gujrat-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "GWD",
            "countryCode": "PK",
            "cityCode": "GWD",
            "cityName": "Gwadar",
            "name": "[GWD] - Gwadar - GWD - Gwadar - Pakistan",
            "searchName": "GWD-Gwadar-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "GIL",
            "countryCode": "PK",
            "cityCode": "GIL",
            "cityName": "Gilgit",
            "name": "[GIL] - Gilgit - GIL - Gilgit - Pakistan",
            "searchName": "GIL-Gilgit-Paquistão-Pakistan-Pakistán-",
            "priority": 0
        },
        {
            "iataCode": "AXR",
            "countryCode": "PF",
            "cityCode": "AXR",
            "cityName": "Arutua",
            "name": "[AXR] - Arutua - AXR - Arutua - French Polynesia",
            "searchName": "AXR-Arutha-Arutua-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "AUQ",
            "countryCode": "PF",
            "cityCode": "AUQ",
            "cityName": "Atuona",
            "name": "[AUQ] - Atuona - AUQ - Atuona - French Polynesia",
            "searchName": "AUQ-Atuona-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "APK",
            "countryCode": "PF",
            "cityCode": "APK",
            "cityName": "Apataki",
            "name": "[APK] - Apataki - APK - Apataki - French Polynesia",
            "searchName": "APK-Apataki-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "AHE",
            "countryCode": "PF",
            "cityCode": "AHE",
            "cityName": "Ahe",
            "name": "[AHE] - Ahe Airport - AHE - Ahe - French Polynesia",
            "searchName": "AHE-Ahe Französisch Polynesien-Ahe Airport-Ahe-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "AAA",
            "countryCode": "PF",
            "cityCode": "AAA",
            "cityName": "Anaa",
            "name": "[AAA] - Anaa - AAA - Anaa - French Polynesia",
            "searchName": "AAA-Anaa-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "UAH",
            "countryCode": "PF",
            "cityCode": "UAH",
            "cityName": "Ua Huka",
            "name": "[UAH] - Ua Huka - UAH - Ua Huka - French Polynesia",
            "searchName": "UAH-Ua Huka-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "UAP",
            "countryCode": "PF",
            "cityCode": "UAP",
            "cityName": "Ua Pou",
            "name": "[UAP] - Ua Pou - UAP - Ua Pou - French Polynesia",
            "searchName": "UAP-Ua Pou-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "VHZ",
            "countryCode": "PF",
            "cityCode": "VHZ",
            "cityName": "Vahitahi",
            "name": "[VHZ] - Vahitahi - VHZ - Vahitahi - French Polynesia",
            "searchName": "VHZ-Vahitahi-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "XMH",
            "countryCode": "PF",
            "cityCode": "XMH",
            "cityName": "Manihi",
            "name": "[XMH] - Manihi - XMH - Manihi - French Polynesia",
            "searchName": "XMH-Manihi-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "TPX",
            "countryCode": "PF",
            "cityCode": "TPX",
            "cityName": "Tupai",
            "name": "[TPX] - Tupai - TPX - Tupai - French Polynesia",
            "searchName": "TPX-Tupai-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "TKX",
            "countryCode": "PF",
            "cityCode": "TKX",
            "cityName": "Takaroa",
            "name": "[TKX] - Takaroa - TKX - Takaroa - French Polynesia",
            "searchName": "TKX-Takaroa-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "TKP",
            "countryCode": "PF",
            "cityCode": "TKP",
            "cityName": "Takapoto",
            "name": "[TKP] - Takapoto - TKP - Takapoto - French Polynesia",
            "searchName": "TKP-Takapoto-Takapoto\u00A0 Havalimani-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "TKV",
            "countryCode": "PF",
            "cityCode": "TKV",
            "cityName": "Tatakoto",
            "name": "[TKV] - Tatakoto - TKV - Tatakoto - French Polynesia",
            "searchName": "TKV-Tuamoto-Tatakoto-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "TTI",
            "countryCode": "PF",
            "cityCode": "TTI",
            "cityName": "Tetiaroa Island",
            "name": "[TTI] - Tetiaroa Island - TTI - Tetiaroa Island - French Polynesia",
            "searchName": "TTI-Tetiaroa Island-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "TUB",
            "countryCode": "PF",
            "cityCode": "TUB",
            "cityName": "Tubuai",
            "name": "[TUB] - Tubuai - TUB - Tubuai - French Polynesia",
            "searchName": "TUB-Tubuai-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "TJN",
            "countryCode": "PF",
            "cityCode": "TJN",
            "cityName": "Takume",
            "name": "[TJN] - Takume - TJN - Takume - French Polynesia",
            "searchName": "TJN-Takume-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "TIH",
            "countryCode": "PF",
            "cityCode": "TIH",
            "cityName": "Tikehau Atoll",
            "name": "[TIH] - Tikehau Atoll - TIH - Tikehau Atoll - French Polynesia",
            "searchName": "TIH-Tikehau Atoll-Tikehau-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "RFP",
            "countryCode": "PF",
            "cityCode": "RFP",
            "cityName": "Raiatea",
            "name": "[RFP] - Raiatea - RFP - Raiatea - French Polynesia",
            "searchName": "RFP-Raiatea-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "RGI",
            "countryCode": "PF",
            "cityCode": "RGI",
            "cityName": "Rangiroa",
            "name": "[RGI] - Rangiroa - RGI - Rangiroa - French Polynesia",
            "searchName": "RGI-Rangiroa-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "REA",
            "countryCode": "PF",
            "cityCode": "REA",
            "cityName": "Reao",
            "name": "[REA] - Reao - REA - Reao - French Polynesia",
            "searchName": "REA-Reao-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "RKA",
            "countryCode": "PF",
            "cityCode": "RKA",
            "cityName": "Aratika",
            "name": "[RKA] - Aratika-Nord - RKA - Aratika - French Polynesia",
            "searchName": "RKA-Aratika-Nord-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "RMT",
            "countryCode": "PF",
            "cityCode": "RMT",
            "cityName": "Rimatara",
            "name": "[RMT] - Rimatara - RMT - Rimatara - French Polynesia",
            "searchName": "RMT-Rimatara-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "RRR",
            "countryCode": "PF",
            "cityCode": "RRR",
            "cityName": "Raroia",
            "name": "[RRR] - Raroia - RRR - Raroia - French Polynesia",
            "searchName": "RRR-Raroia-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "RUR",
            "countryCode": "PF",
            "cityCode": "RUR",
            "cityName": "Rurutu",
            "name": "[RUR] - Rurutu - RUR - Rurutu - French Polynesia",
            "searchName": "RUR-Rurutu-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "RVV",
            "countryCode": "PF",
            "cityCode": "RVV",
            "cityName": "Rairua",
            "name": "[RVV] - Raivavae - RVV - Rairua - French Polynesia",
            "searchName": "RVV-Raivavae-Rairua-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "PUK",
            "countryCode": "PF",
            "cityCode": "PUK",
            "cityName": "Pukarua",
            "name": "[PUK] - Pukarua - PUK - Pukarua - French Polynesia",
            "searchName": "PUK-Pukarua-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "PKP",
            "countryCode": "PF",
            "cityCode": "PKP",
            "cityName": "Puka Puka",
            "name": "[PKP] - Puka Puka - PKP - Puka Puka - French Polynesia",
            "searchName": "PKP-Puka Puka-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "PPT",
            "countryCode": "PF",
            "cityCode": "PPT",
            "cityName": "Papeete",
            "name": "[PPT] - Faaa - PPT - Papeete - French Polynesia",
            "searchName": "PPT-Papeete Tahiti-Faa a-Faaa-Papeete-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "MAU",
            "countryCode": "PF",
            "cityCode": "MAU",
            "cityName": "Maupiti",
            "name": "[MAU] - Maupiti - MAU - Maupiti - French Polynesia",
            "searchName": "MAU-Maupiti-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "MOZ",
            "countryCode": "PF",
            "cityCode": "MOZ",
            "cityName": "Moorea",
            "name": "[MOZ] - Temae - MOZ - Moorea - French Polynesia",
            "searchName": "MOZ-Moorea-Temae-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "MKP",
            "countryCode": "PF",
            "cityCode": "MKP",
            "cityName": "Makemo",
            "name": "[MKP] - Makemo - MKP - Makemo - French Polynesia",
            "searchName": "MKP-Makemo-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "NHV",
            "countryCode": "PF",
            "cityCode": "NHV",
            "cityName": "Nuku Hiva",
            "name": "[NHV] - Nuku Hiva - NHV - Nuku Hiva - French Polynesia",
            "searchName": "NHV-Nuku Hiva-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "NIU",
            "countryCode": "PF",
            "cityCode": "NIU",
            "cityName": "Niau",
            "name": "[NIU] - Niau - NIU - Niau - French Polynesia",
            "searchName": "NIU-Niau-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "NAU",
            "countryCode": "PF",
            "cityCode": "NAU",
            "cityName": "Napuka Island",
            "name": "[NAU] - Napuka Island - NAU - Napuka Island - French Polynesia",
            "searchName": "NAU-Napuka Island-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "MVT",
            "countryCode": "PF",
            "cityCode": "MVT",
            "cityName": "Mataiva",
            "name": "[MVT] - Mataiva - MVT - Mataiva - French Polynesia",
            "searchName": "MVT-Mataiva-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "NUK",
            "countryCode": "PF",
            "cityCode": "NUK",
            "cityName": "Nukutavake",
            "name": "[NUK] - Nukutavake - NUK - Nukutavake - French Polynesia",
            "searchName": "NUK-Nukutavake-Prancuzijos Polinezija-Frans Polynesië-Polinésia Francesa-Francuska Polinezija-Prantsuse Polüneesia-French Polynesia-Französisch-Polynesien-Fransiz Polinezyasi-Polinesia Francesa-Polynésie française-Polinesia Francese",
            "priority": 0
        },
        {
            "iataCode": "NUT",
            "countryCode": "PG",
            "cityCode": "NUT",
            "cityName": "Nutuve",
            "name": "[NUT] - Nutuve - NUT - Nutuve - Papua New Guinea",
            "searchName": "NUT-Nutuve-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NUG",
            "countryCode": "PG",
            "cityCode": "NUG",
            "cityName": "Nuguria",
            "name": "[NUG] - Nuguria - NUG - Nuguria - Papua New Guinea",
            "searchName": "NUG-Nuguria-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NOM",
            "countryCode": "PG",
            "cityCode": "NOM",
            "cityName": "Nomad River",
            "name": "[NOM] - Nomad River - NOM - Nomad River - Papua New Guinea",
            "searchName": "NOM-Nomad River-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NPG",
            "countryCode": "PG",
            "cityCode": "NPG",
            "cityName": "Nipa",
            "name": "[NPG] - Nipa - NPG - Nipa - Papua New Guinea",
            "searchName": "NPG-Nipa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NOO",
            "countryCode": "PG",
            "cityCode": "NOO",
            "cityName": "Naoro",
            "name": "[NOO] - Naoro - NOO - Naoro - Papua New Guinea",
            "searchName": "NOO-Naoro-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OGE",
            "countryCode": "PG",
            "cityCode": "OGE",
            "cityName": "Ogeranang",
            "name": "[OGE] - Ogeranang - OGE - Ogeranang - Papua New Guinea",
            "searchName": "OGE-Ogeranang-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OKV",
            "countryCode": "PG",
            "cityCode": "OKV",
            "cityName": "Okao",
            "name": "[OKV] - Okao - OKV - Okao - Papua New Guinea",
            "searchName": "OKV-Okao-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OKP",
            "countryCode": "PG",
            "cityCode": "OKP",
            "cityName": "Oksapmin",
            "name": "[OKP] - Oksapmin - OKP - Oksapmin - Papua New Guinea",
            "searchName": "OKP-Oksapmin-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OBM",
            "countryCode": "PG",
            "cityCode": "OBM",
            "cityName": "Morobe",
            "name": "[OBM] - Morobe - OBM - Morobe - Papua New Guinea",
            "searchName": "OBM-Morobe-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NWT",
            "countryCode": "PG",
            "cityCode": "NWT",
            "cityName": "Nowata",
            "name": "[NWT] - Nowata - NWT - Nowata - Papua New Guinea",
            "searchName": "NWT-Nowata-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "OBX",
            "countryCode": "PG",
            "cityCode": "OBX",
            "cityName": "Obo",
            "name": "[OBX] - Obo - OBX - Obo - Papua New Guinea",
            "searchName": "OBX-Obo-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MWI",
            "countryCode": "PG",
            "cityCode": "MWI",
            "cityName": "Maramuni",
            "name": "[MWI] - Maramuni - MWI - Maramuni - Papua New Guinea",
            "searchName": "MWI-Maramuni-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MWU",
            "countryCode": "PG",
            "cityCode": "MWU",
            "cityName": "Mussau",
            "name": "[MWU] - Mussau - MWU - Mussau - Papua New Guinea",
            "searchName": "MWU-Mussau-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MWG",
            "countryCode": "PG",
            "cityCode": "MWG",
            "cityName": "Marawaka",
            "name": "[MWG] - Marawaka - MWG - Marawaka - Papua New Guinea",
            "searchName": "MWG-Marawaka-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MXK",
            "countryCode": "PG",
            "cityCode": "MXK",
            "cityName": "Mindik",
            "name": "[MXK] - Mindik - MXK - Mindik - Papua New Guinea",
            "searchName": "MXK-Mindik-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MXH",
            "countryCode": "PG",
            "cityCode": "MXH",
            "cityName": "Moro",
            "name": "[MXH] - Moro - MXH - Moro - Papua New Guinea",
            "searchName": "MXH-Moro-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NBA",
            "countryCode": "PG",
            "cityCode": "NBA",
            "cityName": "Nambaiyufa",
            "name": "[NBA] - Nambaiyufa - NBA - Nambaiyufa - Papua New Guinea",
            "searchName": "NBA-Nambaiyufa-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MZN",
            "countryCode": "PG",
            "cityCode": "MZN",
            "cityName": "Minj",
            "name": "[MZN] - Minj - MZN - Minj - Papua New Guinea",
            "searchName": "MZN-Minj-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MYX",
            "countryCode": "PG",
            "cityCode": "MYX",
            "cityName": "Menyamya",
            "name": "[MYX] - Menyamya - MYX - Menyamya - Papua New Guinea",
            "searchName": "MYX-Menyamya-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NIS",
            "countryCode": "PG",
            "cityCode": "NIS",
            "cityName": "Simberi Island",
            "name": "[NIS] - Simberi - NIS - Simberi Island - Papua New Guinea",
            "searchName": "NIS-Simberi-Simberi Island-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NGR",
            "countryCode": "PG",
            "cityCode": "NGR",
            "cityName": "Ningerum",
            "name": "[NGR] - Ningerum - NGR - Ningerum - Papua New Guinea",
            "searchName": "NGR-Ningerum-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NMN",
            "countryCode": "PG",
            "cityCode": "NMN",
            "cityName": "Nomane",
            "name": "[NMN] - Nomane - NMN - Nomane - Papua New Guinea",
            "searchName": "NMN-Nomane-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NKN",
            "countryCode": "PG",
            "cityCode": "NKN",
            "cityName": "Nankina",
            "name": "[NKN] - Nankina - NKN - Nankina - Papua New Guinea",
            "searchName": "NKN-Nankina-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NDN",
            "countryCode": "PG",
            "cityCode": "NDN",
            "cityName": "Nadunumu",
            "name": "[NDN] - Nadunumu - NDN - Nadunumu - Papua New Guinea",
            "searchName": "NDN-Nadunumu-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "NDI",
            "countryCode": "PG",
            "cityCode": "NDI",
            "cityName": "Namudi",
            "name": "[NDI] - Namudi - NDI - Namudi - Papua New Guinea",
            "searchName": "NDI-Namudi-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MMV",
            "countryCode": "PG",
            "cityCode": "MMV",
            "cityName": "Mal",
            "name": "[MMV] - Mal - MMV - Mal - Papua New Guinea",
            "searchName": "MMV-Mal-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MLQ",
            "countryCode": "PG",
            "cityCode": "MLQ",
            "cityName": "Malalaua",
            "name": "[MLQ] - Malalaua - MLQ - Malalaua - Papua New Guinea",
            "searchName": "MLQ-Malalaua-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MPF",
            "countryCode": "PG",
            "cityCode": "MPF",
            "cityName": "Mapoda",
            "name": "[MPF] - Mapoda - MPF - Mapoda - Papua New Guinea",
            "searchName": "MPF-Mapoda-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MPG",
            "countryCode": "PG",
            "cityCode": "MPG",
            "cityName": "Makini",
            "name": "[MPG] - Makini - MPG - Makini - Papua New Guinea",
            "searchName": "MPG-Makini-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MPU",
            "countryCode": "PG",
            "cityCode": "MPU",
            "cityName": "Mapua",
            "name": "[MPU] - Mapua - MPU - Mapua - Papua New Guinea",
            "searchName": "MPU-Mapua-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MRM",
            "countryCode": "PG",
            "cityCode": "MRM",
            "cityName": "Manare",
            "name": "[MRM] - Manare - MRM - Manare - Papua New Guinea",
            "searchName": "MRM-Manare-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MRH",
            "countryCode": "PG",
            "cityCode": "MRH",
            "cityName": "May River",
            "name": "[MRH] - May River - MRH - May River - Papua New Guinea",
            "searchName": "MRH-May River-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MQO",
            "countryCode": "PG",
            "cityCode": "MQO",
            "cityName": "Malam",
            "name": "[MQO] - Malam - MQO - Malam - Papua New Guinea",
            "searchName": "MQO-Malam-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MPX",
            "countryCode": "PG",
            "cityCode": "MPX",
            "cityName": "Miyanmin",
            "name": "[MPX] - Miyanmin - MPX - Miyanmin - Papua New Guinea",
            "searchName": "MPX-Miyanmin-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MAS",
            "countryCode": "PG",
            "cityCode": "MAS",
            "cityName": "Manus Island",
            "name": "[MAS] - Momote - MAS - Manus Island - Papua New Guinea",
            "searchName": "MAS-Manus Island-Momote--Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MAP",
            "countryCode": "PG",
            "cityCode": "MAP",
            "cityName": "Mamai",
            "name": "[MAP] - Mamai - MAP - Mamai - Papua New Guinea",
            "searchName": "MAP-Mamai-Papua-Nová Guinea-Uusi-Guinea-Papua Yeni Gine-Pápua Új-Papua Ny Guinea-Papua Nova Guinea-Papua Noua Guinee-Papua Nova Gvineja-Neuguinea-Papua New Guinea-Papouasie-Nouvelle-Guinée-Papúa Nueva Guinea-Papua Nuova Guinea",
            "priority": 0
        },
        {
            "iataCode": "MNM",
            "countryCode": "US",
            "cityCode": "MNM",
            "cityName": "Menominee",
            "name": "[MNM] - Menominee - MNM - Menominee - United States",
            "searchName": "MNM-Menominee-Menominee County Havalimani-Marinette-Menomin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MNN",
            "countryCode": "US",
            "cityCode": "MNN",
            "cityName": "Marion",
            "name": "[MNN] - Municipal - MNN - Marion - United States",
            "searchName": "MNN-Municipal-Marion-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MNT",
            "countryCode": "US",
            "cityCode": "MNT",
            "cityName": "Minto",
            "name": "[MNT] - Minto - MNT - Minto - United States",
            "searchName": "MNT-Minto-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLY",
            "countryCode": "US",
            "cityCode": "MLY",
            "cityName": "Manley Hot Springs",
            "name": "[MLY] - Manley Hot Springs - MLY - Manley Hot Springs - United States",
            "searchName": "MLY-Manley Hot Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLF",
            "countryCode": "US",
            "cityCode": "MLF",
            "cityName": "Milford",
            "name": "[MLF] - Milford - MLF - Milford - United States",
            "searchName": "MLF-Milford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLS",
            "countryCode": "US",
            "cityCode": "MLS",
            "cityName": "Miles City",
            "name": "[MLS] - Municipal - MLS - Miles City - United States",
            "searchName": "MLS-Miles City-Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLT",
            "countryCode": "US",
            "cityCode": "MLT",
            "cityName": "Millinocket",
            "name": "[MLT] - Millinocket - MLT - Millinocket - United States",
            "searchName": "MLT-Millinocket-Millinocket Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLU",
            "countryCode": "US",
            "cityCode": "MLU",
            "cityName": "Monroe",
            "name": "[MLU] - Municipal - MLU - Monroe - United States",
            "searchName": "MLU-Monroe-Municipal-Huenefeld-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLB",
            "countryCode": "US",
            "cityCode": "MLB",
            "cityName": "Melbourne",
            "name": "[MLB] - Melbourne International Airport - MLB - Melbourne - United States",
            "searchName": "MLB-Melbourne USA-Melbourne International Airport-Melbourne-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLC",
            "countryCode": "US",
            "cityCode": "MLC",
            "cityName": "Mc Alester",
            "name": "[MLC] - Mc Alester - MLC - Mc Alester - United States",
            "searchName": "MLC-Mc Alester-McAlester  Havalimani-McAlester-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLD",
            "countryCode": "US",
            "cityCode": "MLD",
            "cityName": "Malad City",
            "name": "[MLD] - Malad City - MLD - Malad City - United States",
            "searchName": "MLD-Malad City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLI",
            "countryCode": "US",
            "cityCode": "MLI",
            "cityName": "Moline",
            "name": "[MLI] - Quad-City - MLI - Moline - United States",
            "searchName": "MLI-Moline-Quad-City-Moline Illinois-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLJ",
            "countryCode": "US",
            "cityCode": "MLJ",
            "cityName": "Milledgeville",
            "name": "[MLJ] - Baldwin County - MLJ - Milledgeville - United States",
            "searchName": "MLJ-Baldwin County-Milledgeville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLK",
            "countryCode": "US",
            "cityCode": "MLK",
            "cityName": "Malta",
            "name": "[MLK] - Malta - MLK - Malta - United States",
            "searchName": "MLK-Malta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MLL",
            "countryCode": "US",
            "cityCode": "MLL",
            "cityName": "Marshall",
            "name": "[MLL] - Marshall - MLL - Marshall - United States",
            "searchName": "MLL-Marshall-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MML",
            "countryCode": "US",
            "cityCode": "MML",
            "cityName": "Marshall",
            "name": "[MML] - Municipal-Ryan Field - MML - Marshall - United States",
            "searchName": "MML-Municipal-Ryan Field-Marshall Belediyesi Havalimani-Marshall-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MMH",
            "countryCode": "US",
            "cityCode": "MMH",
            "cityName": "Mammoth Lakes",
            "name": "[MMH] - Mammoth Lakes - MMH - Mammoth Lakes - United States",
            "searchName": "MMH-Mammoth Lakes-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MMI",
            "countryCode": "US",
            "cityCode": "MMI",
            "cityName": "Athens",
            "name": "[MMI] - McMinn County - MMI - Athens - United States",
            "searchName": "MMI-McMinn County-McMinn County Havalimani-Athens-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MMN",
            "countryCode": "US",
            "cityCode": "MMN",
            "cityName": "Stow",
            "name": "[MMN] - Minute Man Airfield - MMN - Stow - United States",
            "searchName": "MMN-Minute Man Airfield-Stow-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MMS",
            "countryCode": "US",
            "cityCode": "MMS",
            "cityName": "Marks",
            "name": "[MMS] - Selfs - MMS - Marks - United States",
            "searchName": "MMS-Selfs-Marks-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MMU",
            "countryCode": "US",
            "cityCode": "MMU",
            "cityName": "Morristown",
            "name": "[MMU] - Municipal - MMU - Morristown - United States",
            "searchName": "MMU-Morristown-Municipal-Morristown Havalimani-Morristown New Jersey-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MVC",
            "countryCode": "US",
            "cityCode": "MVC",
            "cityName": "Monroeville",
            "name": "[MVC] - Monroe - Walton County - MVC - Monroeville - United States",
            "searchName": "MVC-Monroe - Walton County-Monroe County Havalimani-Monroeville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MUO",
            "countryCode": "US",
            "cityCode": "MUO",
            "cityName": "Mountain Home",
            "name": "[MUO] - Mountain Home AFB - MUO - Mountain Home - United States",
            "searchName": "MUO-Mountain Home AFB-Mountain Home AFB Havalimani-Mountain Home-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MVL",
            "countryCode": "US",
            "cityCode": "MVL",
            "cityName": "Stowe",
            "name": "[MVL] - Morrisville-Stowe - MVL - Stowe - United States",
            "searchName": "MVL-Morrisville-Stowe-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MVM",
            "countryCode": "US",
            "cityCode": "MVM",
            "cityName": "Kayenta",
            "name": "[MVM] - Monument Valley - MVM - Kayenta - United States",
            "searchName": "MVM-Monument Valley-Kayenta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MVN",
            "countryCode": "US",
            "cityCode": "MVN",
            "cityName": "Mount Vernon",
            "name": "[MVN] - Mt Vernon-Outland - MVN - Mount Vernon - United States",
            "searchName": "MVN-Mt Vernon-Outland-Mt Vernon Outland Havalimani-Mount Vernon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MUE",
            "countryCode": "US",
            "cityCode": "MUE",
            "cityName": "Kamuela",
            "name": "[MUE] - Kamuela - MUE - Kamuela - United States",
            "searchName": "MUE-Kamuela-Waimea Kohala-Waimea Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MUI",
            "countryCode": "US",
            "cityCode": "MUI",
            "cityName": "Fort Indiantown",
            "name": "[MUI] - Muir AAF - MUI - Fort Indiantown - United States",
            "searchName": "MUI-Muir AAF-Fort Indiantown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MUT",
            "countryCode": "US",
            "cityCode": "MUT",
            "cityName": "Muscatine",
            "name": "[MUT] - Muscatine - MUT - Muscatine - United States",
            "searchName": "MUT-Muscatine-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MUU",
            "countryCode": "US",
            "cityCode": "MUU",
            "cityName": "Mount Union",
            "name": "[MUU] - Mount Union - MUU - Mount Union - United States",
            "searchName": "MUU-Mount Union-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MTW",
            "countryCode": "US",
            "cityCode": "MTW",
            "cityName": "Manitowoc",
            "name": "[MTW] - Municipal - MTW - Manitowoc - United States",
            "searchName": "MTW-Municipal-Manitowoc  Havalimani-Manitowoc-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MTJ",
            "countryCode": "US",
            "cityCode": "MTJ",
            "cityName": "Montrose",
            "name": "[MTJ] - Montrose Regional Airport - MTJ - Montrose - United States",
            "searchName": "MTJ-Montrose-Montrose Regional Airport-Montrose County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MTM",
            "countryCode": "US",
            "cityCode": "MTM",
            "cityName": "Metlakatla",
            "name": "[MTM] - SPB - MTM - Metlakatla - United States",
            "searchName": "MTM-SPB-Metlakatla-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MTO",
            "countryCode": "US",
            "cityCode": "MTO",
            "cityName": "Mattoon",
            "name": "[MTO] - Coles County Memorial - MTO - Mattoon - United States",
            "searchName": "MTO-Coles County Memorial-Mattoon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MTP",
            "countryCode": "US",
            "cityCode": "MTP",
            "cityName": "Montauk",
            "name": "[MTP] - Montauk Airport - MTP - Montauk - United States",
            "searchName": "MTP-Sky Portal-Montauk Airport-Sky Portal Havalimani-Montauk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MTC",
            "countryCode": "US",
            "cityCode": "MTC",
            "cityName": "Mt Clemens",
            "name": "[MTC] - Selfridge ANGB - MTC - Mt Clemens - United States",
            "searchName": "MTC-Selfridge ANGB-Selfridge Air Natl Guard Havalimani-Mt Clemens-Detroit-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NFB",
            "countryCode": "US",
            "cityCode": "MTC",
            "cityName": "Mt Clemens",
            "name": "[NFB] - Detroit NAF - MTC - Mt Clemens - United States",
            "searchName": "NFB-MTC-Detroit NAF-Mt Clemens-Detroit-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MTH",
            "countryCode": "US",
            "cityCode": "MTH",
            "cityName": "Marathon",
            "name": "[MTH] - The Florida Keys Marathon - MTH - Marathon - United States",
            "searchName": "MTH-The Florida Keys Marathon-Marathon Havalimani-Marathon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MSV",
            "countryCode": "US",
            "cityCode": "MSV",
            "cityName": "Monticello",
            "name": "[MSV] - Sullivan County International - MSV - Monticello - United States",
            "searchName": "MSV-Sullivan County International-Catskills Sulivan Havalimani-Monticello-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MSL",
            "countryCode": "US",
            "cityCode": "MSL",
            "cityName": "Sheffield",
            "name": "[MSL] - Sheffield - MSL - Sheffield - United States",
            "searchName": "MSL-Muscle Shoals-Sheffield-Muscle Shoals Airport-Florence-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MSO",
            "countryCode": "US",
            "cityCode": "MSO",
            "cityName": "Missoula",
            "name": "[MSO] - Missoula International - MSO - Missoula - United States",
            "searchName": "MSO-Missoula-Missoula International-Johnson-Bell Field-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PNE",
            "countryCode": "US",
            "cityCode": "PHL",
            "cityName": "Philadelphia",
            "name": "[PNE] - North Philadelphia - PHL - Philadelphia - United States",
            "searchName": "PNE-PHL-North Philadelphia-N Philadelphia Havalimani-Philadelphia-Filadelfia-Philadelphie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PSQ",
            "countryCode": "US",
            "cityCode": "PHL",
            "cityName": "Philadelphia",
            "name": "[PSQ] - SPB - PHL - Philadelphia - United States",
            "searchName": "PSQ-PHL-SPB-Philadelphia-Filadelfia-Philadelphie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TTN",
            "countryCode": "US",
            "cityCode": "PHL",
            "cityName": "Philadelphia",
            "name": "[TTN] - Trenton Mercer Airport - PHL - Philadelphia - United States",
            "searchName": "TTN-PHL-Mercer County-Trenton Mercer Airport-Trenton-Mercer-Philadelphia-Filadelfia-Philadelphie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZTN",
            "countryCode": "US",
            "cityCode": "PHL",
            "cityName": "Philadelphia",
            "name": "[ZTN] - Trenton RailwaySTN - PHL - Philadelphia - United States",
            "searchName": "ZTN-PHL-Trenton RailwaySTN-Philadelphia-Filadelfia-Philadelphie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZFV",
            "countryCode": "US",
            "cityCode": "PHL",
            "cityName": "Philadelphia",
            "name": "[ZFV] - Philadelphia Railway Station - PHL - Philadelphia - United States",
            "searchName": "ZFV-PHL-Philadelphia Railway Station-Philadelphia-Filadelfia-Philadelphie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZHC",
            "countryCode": "US",
            "cityCode": "PHL",
            "cityName": "Philadelphia",
            "name": "[ZHC] - N Philadelphia RailwaySt - PHL - Philadelphia - United States",
            "searchName": "ZHC-PHL-N Philadelphia RailwaySt-Philadelphia-Filadelfia-Philadelphie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MUV",
            "countryCode": "US",
            "cityCode": "PHL",
            "cityName": "Philadelphia",
            "name": "[MUV] - Mustin Alf - PHL - Philadelphia - United States",
            "searchName": "MUV-PHL-Mustin Alf-Philadelphia-Filadelfia-Philadelphie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PHN",
            "countryCode": "US",
            "cityCode": "PHN",
            "cityName": "Port Huron",
            "name": "[PHN] - St Clair County International - PHN - Port Huron - United States",
            "searchName": "PHN-St Clair County International-St Clair County Uluslararasi Havalimani-Port Huron-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PHO",
            "countryCode": "US",
            "cityCode": "PHO",
            "cityName": "Point Hope",
            "name": "[PHO] - Point Hope - PHO - Point Hope - United States",
            "searchName": "PHO-Point Hope-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PHP",
            "countryCode": "US",
            "cityCode": "PHP",
            "cityName": "Philip",
            "name": "[PHP] - Philip - PHP - Philip - United States",
            "searchName": "PHP-Philip-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PJB",
            "countryCode": "US",
            "cityCode": "PJB",
            "cityName": "Payson",
            "name": "[PJB] - Payson - PJB - Payson - United States",
            "searchName": "PJB-Payson-Payson Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PKA",
            "countryCode": "US",
            "cityCode": "PKA",
            "cityName": "Napaskiak",
            "name": "[PKA] - SPB - PKA - Napaskiak - United States",
            "searchName": "PKA-SPB-Napaskiak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PKB",
            "countryCode": "US",
            "cityCode": "PKB",
            "cityName": "Parkersburg",
            "name": "[PKB] - Wood County - PKB - Parkersburg - United States",
            "searchName": "PKB-Parkersburg-Wood County-Parkersburg/Parkersburg WV-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PJS",
            "countryCode": "US",
            "cityCode": "PJS",
            "cityName": "Port San Juan",
            "name": "[PJS] - Port San Juan - PJS - Port San Juan - United States",
            "searchName": "PJS-Port San Juan-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MCI",
            "countryCode": "US",
            "cityCode": "MKC",
            "cityName": "Kansas City",
            "name": "[MCI] - Kansas City International Airport - MKC - Kansas City - United States",
            "searchName": "MCI-MKC-Kansas City-Kansas City International Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JCI",
            "countryCode": "US",
            "cityCode": "MKC",
            "cityName": "Kansas City",
            "name": "[JCI] - New Century Aircenter Airport - MKC - Kansas City - United States",
            "searchName": "JCI-MKC-New Century Aircenter Airport-Johnson Industrial Havalimani-Kansas City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OJC",
            "countryCode": "US",
            "cityCode": "MKC",
            "cityName": "Kansas City",
            "name": "[OJC] - Johnson Executive - MKC - Kansas City - United States",
            "searchName": "OJC-MKC-Johnson Executive-Johnson Executive Havalimani-Kansas City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZML",
            "countryCode": "US",
            "cityCode": "MKE",
            "cityName": "Milwaukee",
            "name": "[ZML] - Milwaukee General Mitchell - MKE - Milwaukee - United States",
            "searchName": "ZML-MKE-Milwaukee General Mitchell-Milwaukee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NBG",
            "countryCode": "US",
            "cityCode": "MSY",
            "cityName": "New Orleans",
            "name": "[NBG] - NAS/Alvin Callendar - MSY - New Orleans - United States",
            "searchName": "NBG-MSY-NAS/Alvin Callendar-New Orleans Louisiana-New Orleans-Nueva Orleans-Nouvelle-Orl-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NEW",
            "countryCode": "US",
            "cityCode": "MSY",
            "cityName": "New Orleans",
            "name": "[NEW] - Lakefront - MSY - New Orleans - United States",
            "searchName": "NEW-MSY-Lakefront-New Lakefront Havalimani-New Orleans Louisiana-New Orleans-Nueva Orleans-Nouvelle-Orl-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SEV",
            "countryCode": "UA",
            "cityCode": "SEV",
            "cityName": "Severodoneck",
            "name": "[SEV] - Severodoneck - SEV - Severodoneck - Ukraine",
            "searchName": "SEV-Severodoneck-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "KSE",
            "countryCode": "UG",
            "cityCode": "KSE",
            "cityName": "Kasese",
            "name": "[KSE] - Kasese - KSE - Kasese - Uganda",
            "searchName": "KSE-Kasese-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "KCU",
            "countryCode": "UG",
            "cityCode": "KCU",
            "cityName": "Masindi",
            "name": "[KCU] - Masindi - KCU - Masindi - Uganda",
            "searchName": "KCU-Masindi-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "KBG",
            "countryCode": "UG",
            "cityCode": "KBG",
            "cityName": "Kabalega Falls",
            "name": "[KBG] - Kabalega Falls - KBG - Kabalega Falls - Uganda",
            "searchName": "KBG-Kabalega Falls-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "JIN",
            "countryCode": "UG",
            "cityCode": "JIN",
            "cityName": "Jinja",
            "name": "[JIN] - Jinja - JIN - Jinja - Uganda",
            "searchName": "JIN-Jinja-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "AWK",
            "countryCode": "UM",
            "cityCode": "AWK",
            "cityName": "Wake Island",
            "name": "[AWK] - Wake Island - AWK - Wake Island - United States Minor Outlying Islands",
            "searchName": "AWK-Landeplatz-Wake Island-United States Minor Outlying Islands-USA-ABD Küçük Dis Adalari-Islas menores alejadas de los Estados Unidos-Îles éloignées des États-Unis-Isole periferiche agli USA",
            "priority": 0
        },
        {
            "iataCode": "MDY",
            "countryCode": "UM",
            "cityCode": "MDY",
            "cityName": "Midway Island",
            "name": "[MDY] - Sand Island Field - MDY - Midway Island - United States Minor Outlying Islands",
            "searchName": "MDY-Sand Island Field-Midway Island-United States Minor Outlying Islands-USA-ABD Küçük Dis Adalari-Islas menores alejadas de los Estados Unidos-Îles éloignées des États-Unis-Isole periferiche agli USA",
            "priority": 0
        },
        {
            "iataCode": "MEI",
            "countryCode": "US",
            "cityCode": "MEI",
            "cityName": "Meridian",
            "name": "[MEI] - Key Field - MEI - Meridian - United States",
            "searchName": "MEI-Meridian-Key Field-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MEJ",
            "countryCode": "US",
            "cityCode": "MEJ",
            "cityName": "Meadville",
            "name": "[MEJ] - Meadville - MEJ - Meadville - United States",
            "searchName": "MEJ-Meadville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ORF",
            "countryCode": "US",
            "cityCode": "ORF",
            "cityName": "Norfolk",
            "name": "[ORF] - Norfolk International Airport - ORF - Norfolk - United States",
            "searchName": "ORF-Norfolk Intl-Norfolk International Airport-Norfolk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NGU",
            "countryCode": "US",
            "cityCode": "ORF",
            "cityName": "Norfolk",
            "name": "[NGU] - NAS Chambers - ORF - Norfolk - United States",
            "searchName": "NGU-ORF-NAS Chambers-Naval Air Station Chambers Havalimani-Norfolk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ORH",
            "countryCode": "US",
            "cityCode": "ORH",
            "cityName": "Worcester",
            "name": "[ORH] - Worcester Regional Airport - ORH - Worcester - United States",
            "searchName": "ORH-Worcester-Worcester Regional Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ORI",
            "countryCode": "US",
            "cityCode": "ORI",
            "cityName": "Port Lions",
            "name": "[ORI] - Port Lions SPB - ORI - Port Lions - United States",
            "searchName": "ORI-Port Lions SPB-Port Lions-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ONA",
            "countryCode": "US",
            "cityCode": "ONA",
            "cityName": "Winona",
            "name": "[ONA] - Municipal - ONA - Winona - United States",
            "searchName": "ONA-Municipal-Winona-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ONL",
            "countryCode": "US",
            "cityCode": "ONL",
            "cityName": "Oneill",
            "name": "[ONL] - Municipal - ONL - Oneill - United States",
            "searchName": "ONL-Municipal-Oneill-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ONM",
            "countryCode": "US",
            "cityCode": "ONM",
            "cityName": "Socorro",
            "name": "[ONM] - Socorro - ONM - Socorro - United States",
            "searchName": "ONM-Socorro-Socorro  Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ONN",
            "countryCode": "US",
            "cityCode": "ONN",
            "cityName": "Onion Bay",
            "name": "[ONN] - Onion Bay - ONN - Onion Bay - United States",
            "searchName": "ONN-Onion Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ONO",
            "countryCode": "US",
            "cityCode": "ONO",
            "cityName": "Ontario",
            "name": "[ONO] - Ontario Municipal Airport - ONO - Ontario - United States",
            "searchName": "ONO-Ontario Municipal Airport-Ontario-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ONP",
            "countryCode": "US",
            "cityCode": "ONP",
            "cityName": "Newport",
            "name": "[ONP] - Newport - ONP - Newport - United States",
            "searchName": "ONP-Newport-Newport  Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OMK",
            "countryCode": "US",
            "cityCode": "OMK",
            "cityName": "Omak",
            "name": "[OMK] - Municipal - OMK - Omak - United States",
            "searchName": "OMK-Municipal-Omak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OME",
            "countryCode": "US",
            "cityCode": "OME",
            "cityName": "Nome",
            "name": "[OME] - Nome - OME - Nome - United States",
            "searchName": "OME-Nome-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OLM",
            "countryCode": "US",
            "cityCode": "OLM",
            "cityName": "Olympia",
            "name": "[OLM] - Olympia - OLM - Olympia - United States",
            "searchName": "OLM-Olympia-Olympia Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OLS",
            "countryCode": "US",
            "cityCode": "OLS",
            "cityName": "Nogales",
            "name": "[OLS] - International - OLS - Nogales - United States",
            "searchName": "OLS-International-Uluslararasi Havalimani-Nogales-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OLU",
            "countryCode": "US",
            "cityCode": "OLU",
            "cityName": "Columbus",
            "name": "[OLU] - Columbus Municipal - OLU - Columbus - United States",
            "searchName": "OLU-Columbus Municipal-Columbus Havalimani-Columbus-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OLV",
            "countryCode": "US",
            "cityCode": "OLV",
            "cityName": "Olive Branch",
            "name": "[OLV] - Olive Branch Airport - OLV - Olive Branch - United States",
            "searchName": "OLV-Olive Branch Airport-Olive Branch-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OLY",
            "countryCode": "US",
            "cityCode": "OLY",
            "cityName": "Olney",
            "name": "[OLY] - Olney-Noble - OLY - Olney - United States",
            "searchName": "OLY-Olney-Noble-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OMA",
            "countryCode": "US",
            "cityCode": "OMA",
            "cityName": "Omaha",
            "name": "[OMA] - Eppley Airfield - OMA - Omaha - United States",
            "searchName": "OMA-Omaha-Eppley Airfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MIQ",
            "countryCode": "US",
            "cityCode": "OMA",
            "cityName": "Omaha",
            "name": "[MIQ] - SimÃ³n BolÃ­var International - OMA - Omaha - United States",
            "searchName": "MIQ-OMA-Simón Bolívar International-Millard Havalimani-SimÃ³n BolÃ­var International-Omaha-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OUN",
            "countryCode": "US",
            "cityCode": "OUN",
            "cityName": "Norman",
            "name": "[OUN] - Max Westheimer - OUN - Norman - United States",
            "searchName": "OUN-Max Westheimer-Norman-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OTZ",
            "countryCode": "US",
            "cityCode": "OTZ",
            "cityName": "Kotzebue",
            "name": "[OTZ] - Kotzebue - OTZ - Kotzebue - United States",
            "searchName": "OTZ-Kotzebue-Ralph Wien-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OTS",
            "countryCode": "US",
            "cityCode": "OTS",
            "cityName": "Anacortes",
            "name": "[OTS] - Anacortes - OTS - Anacortes - United States",
            "searchName": "OTS-Anacortes-Ancortes Havalimani-Ancortes-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OSX",
            "countryCode": "US",
            "cityCode": "OSX",
            "cityName": "Kosciusko",
            "name": "[OSX] - Attala County - OSX - Kosciusko - United States",
            "searchName": "OSX-Attala County-Kosciusko-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OTG",
            "countryCode": "US",
            "cityCode": "OTG",
            "cityName": "Worthington",
            "name": "[OTG] - Worthington - OTG - Worthington - United States",
            "searchName": "OTG-Worthington-Worthington Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OTH",
            "countryCode": "US",
            "cityCode": "OTH",
            "cityName": "North Bend",
            "name": "[OTH] - North Bend - OTH - North Bend - United States",
            "searchName": "OTH-North Bend-North Bend Oregon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OTK",
            "countryCode": "US",
            "cityCode": "OTK",
            "cityName": "Tillamook",
            "name": "[OTK] - Tillamook - OTK - Tillamook - United States",
            "searchName": "OTK-Tillamook-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OTM",
            "countryCode": "US",
            "cityCode": "OTM",
            "cityName": "Ottumwa",
            "name": "[OTM] - Industrial - OTM - Ottumwa - United States",
            "searchName": "OTM-Industrieflugplatz-Industrial-Ottumwa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OTN",
            "countryCode": "US",
            "cityCode": "OTN",
            "cityName": "Oaktown",
            "name": "[OTN] - Green - OTN - Oaktown - United States",
            "searchName": "OTN-Green-Oaktown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OTO",
            "countryCode": "US",
            "cityCode": "OTO",
            "cityName": "Otto",
            "name": "[OTO] - Vor - OTO - Otto - United States",
            "searchName": "OTO-Vor-Otto-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ORQ",
            "countryCode": "US",
            "cityCode": "ORQ",
            "cityName": "Norwalk",
            "name": "[ORQ] - Heliport - ORQ - Norwalk - United States",
            "searchName": "ORQ-Heliport-Norwalk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OSB",
            "countryCode": "US",
            "cityCode": "OSB",
            "cityName": "Osage Beach",
            "name": "[OSB] - Osage Beach - OSB - Osage Beach - United States",
            "searchName": "OSB-Osage Beach-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OSC",
            "countryCode": "US",
            "cityCode": "OSC",
            "cityName": "Oscoda",
            "name": "[OSC] - Wurtsmith AFB - OSC - Oscoda - United States",
            "searchName": "OSC-Wurtsmith AFB-Oscoda-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ORV",
            "countryCode": "US",
            "cityCode": "ORV",
            "cityName": "Noorvik",
            "name": "[ORV] - Curtis Memorial - ORV - Noorvik - United States",
            "searchName": "ORV-Curtis Memorial-Noorvik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ORT",
            "countryCode": "US",
            "cityCode": "ORT",
            "cityName": "Northway",
            "name": "[ORT] - Northway - ORT - Northway - United States",
            "searchName": "ORT-Northway-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OSH",
            "countryCode": "US",
            "cityCode": "OSH",
            "cityName": "Oshkosh",
            "name": "[OSH] - Wittman Field - OSH - Oshkosh - United States",
            "searchName": "OSH-Landeplatz Wittman-Wittman Field-Oshkosh-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PMB",
            "countryCode": "US",
            "cityCode": "PMB",
            "cityName": "Pembina",
            "name": "[PMB] - Intermediate - PMB - Pembina - United States",
            "searchName": "PMB-Intermediate Airport-Intermediate-Pembina-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PLY",
            "countryCode": "US",
            "cityCode": "PLY",
            "cityName": "Plymouth",
            "name": "[PLY] - Plymouth - PLY - Plymouth - United States",
            "searchName": "PLY-Plymouth-Plymouth  Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PMD",
            "countryCode": "US",
            "cityCode": "PMD",
            "cityName": "Palmdale",
            "name": "[PMD] - La Palmdale Regional Airport - PMD - Palmdale - United States",
            "searchName": "PMD-La Palmdale Regional Airport-Palmdale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PMH",
            "countryCode": "US",
            "cityCode": "PMH",
            "cityName": "Portsmouth",
            "name": "[PMH] - Greater Portsmouth Regional - PMH - Portsmouth - United States",
            "searchName": "PMH-Greater Portsmouth Regional-Portsmith Regional Havalimani-Portsmouth-Huntington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PML",
            "countryCode": "US",
            "cityCode": "PML",
            "cityName": "Port Moller",
            "name": "[PML] - AFS - PML - Port Moller - United States",
            "searchName": "PML-AFS-Port Moller-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PLR",
            "countryCode": "US",
            "cityCode": "PLR",
            "cityName": "Pell City",
            "name": "[PLR] - St Clair County - PLR - Pell City - United States",
            "searchName": "PLR-St Clair County-Pell City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PLN",
            "countryCode": "US",
            "cityCode": "PLN",
            "cityName": "Pellston",
            "name": "[PLN] - Emmet County - PLN - Pellston - United States",
            "searchName": "PLN-Pellston-Emmet County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PLK",
            "countryCode": "US",
            "cityCode": "PLK",
            "cityName": "Point Lookout",
            "name": "[PLK] - M. Graham Clark - Taney County - PLK - Point Lookout - United States",
            "searchName": "PLK-M Graham Clark-M. Graham Clark - Taney County-M Graham Clark Havalimani-Point Lookout-Branson Point Lookout-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PMU",
            "countryCode": "US",
            "cityCode": "PMU",
            "cityName": "Paimiut",
            "name": "[PMU] - SPB - PMU - Paimiut - United States",
            "searchName": "PMU-SPB-Paimiut-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PMX",
            "countryCode": "US",
            "cityCode": "PMX",
            "cityName": "Palmer",
            "name": "[PMX] - Metropolitan - PMX - Palmer - United States",
            "searchName": "PMX-Metropolitan-Palmer-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PNF",
            "countryCode": "US",
            "cityCode": "PNF",
            "cityName": "Petersons Point",
            "name": "[PNF] - Petersons Point - PNF - Petersons Point - United States",
            "searchName": "PNF-Peterson s Point-Petersons Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PNC",
            "countryCode": "US",
            "cityCode": "PNC",
            "cityName": "Ponca City",
            "name": "[PNC] - Ponca City - PNC - Ponca City - United States",
            "searchName": "PNC-Ponca City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PNU",
            "countryCode": "US",
            "cityCode": "PNU",
            "cityName": "Panguitch",
            "name": "[PNU] - Panguitch - PNU - Panguitch - United States",
            "searchName": "PNU-Panguitch-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PNS",
            "countryCode": "US",
            "cityCode": "PNS",
            "cityName": "Pensacola",
            "name": "[PNS] - Regional - PNS - Pensacola - United States",
            "searchName": "PNS-Pensacola-Regional-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NUN",
            "countryCode": "US",
            "cityCode": "PNS",
            "cityName": "Pensacola",
            "name": "[NUN] - Saufley NAS - PNS - Pensacola - United States",
            "searchName": "NUN-PNS-Saufley NAS-Pensacola-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NDP",
            "countryCode": "US",
            "cityCode": "PNS",
            "cityName": "Pensacola",
            "name": "[NDP] - Ellyson NAS - PNS - Pensacola - United States",
            "searchName": "NDP-PNS-Ellyson NAS-Pensacola-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NPA",
            "countryCode": "US",
            "cityCode": "PNS",
            "cityName": "Pensacola",
            "name": "[NPA] - Pensacola NAS - PNS - Pensacola - United States",
            "searchName": "NPA-PNS-Pensacola NAS-Pensacola-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PNN",
            "countryCode": "US",
            "cityCode": "PNN",
            "cityName": "Princeton",
            "name": "[PNN] - Princeton - PNN - Princeton - United States",
            "searchName": "PNN-Princeton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "POQ",
            "countryCode": "US",
            "cityCode": "POQ",
            "cityName": "Polk Inlet",
            "name": "[POQ] - Polk Inlet - POQ - Polk Inlet - United States",
            "searchName": "POQ-Polk Inlet-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "POE",
            "countryCode": "US",
            "cityCode": "POE",
            "cityName": "Fort Polk",
            "name": "[POE] - Polk AAF - POE - Fort Polk - United States",
            "searchName": "POE-Polk AAF-Fort Polk Havalimani-Fort Polk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "POF",
            "countryCode": "US",
            "cityCode": "POF",
            "cityName": "Poplar Bluff",
            "name": "[POF] - Earl Fields Memorial - POF - Poplar Bluff - United States",
            "searchName": "POF-Earl Fields Memorial-Earl Fields Memorial Havalimani-Poplar Bluff-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "POH",
            "countryCode": "US",
            "cityCode": "POH",
            "cityName": "Pocahontas",
            "name": "[POH] - Pocahontas Municipal - POH - Pocahontas - United States",
            "searchName": "POH-Pocahontas Municipal-Pocahontas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PNX",
            "countryCode": "US",
            "cityCode": "PNX",
            "cityName": "Sherman-Denison",
            "name": "[PNX] - Grayson County - PNX - Sherman-Denison - United States",
            "searchName": "PNX-Grayson County-Grayson County Havalimani-Sherman-Denison-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "POC",
            "countryCode": "US",
            "cityCode": "POC",
            "cityName": "La Verne",
            "name": "[POC] - Brackett Field - POC - La Verne - United States",
            "searchName": "POC-Brackett Field-La Verne-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "POY",
            "countryCode": "US",
            "cityCode": "POY",
            "cityName": "Powell",
            "name": "[POY] - Powell - POY - Powell - United States",
            "searchName": "POY-Powell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "POU",
            "countryCode": "US",
            "cityCode": "POU",
            "cityName": "Poughkeepsie",
            "name": "[POU] - Dutchess County - POU - Poughkeepsie - United States",
            "searchName": "POU-Dutchess County-Poughkeepsie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PPA",
            "countryCode": "US",
            "cityCode": "PPA",
            "cityName": "Pampa",
            "name": "[PPA] - Perry Lefors Field - PPA - Pampa - United States",
            "searchName": "PPA-Perry Lefors Field-Perry Lefors Field Havalimani-Pampa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PPC",
            "countryCode": "US",
            "cityCode": "PPC",
            "cityName": "Prospect Creek",
            "name": "[PPC] - Prospect Creek - PPC - Prospect Creek - United States",
            "searchName": "PPC-Prospect Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PPM",
            "countryCode": "US",
            "cityCode": "PPM",
            "cityName": "Pompano Beach",
            "name": "[PPM] - Pompano Beach - PPM - Pompano Beach - United States",
            "searchName": "PPM-Pompano Beach-Pompano Beach Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PPF",
            "countryCode": "US",
            "cityCode": "PPF",
            "cityName": "Parsons",
            "name": "[PPF] - Tri-City - PPF - Parsons - United States",
            "searchName": "PPF-Tri-City-Parsons-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PQS",
            "countryCode": "US",
            "cityCode": "PQS",
            "cityName": "Pilot Station",
            "name": "[PQS] - Pilot Station - PQS - Pilot Station - United States",
            "searchName": "PQS-Pilot Station-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PRB",
            "countryCode": "US",
            "cityCode": "PRB",
            "cityName": "Paso Robles",
            "name": "[PRB] - Paso Robles - PRB - Paso Robles - United States",
            "searchName": "PRB-Paso Robles-Paso Robles Cty Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PRC",
            "countryCode": "US",
            "cityCode": "PRC",
            "cityName": "Prescott",
            "name": "[PRC] - Prescott - PRC - Prescott - United States",
            "searchName": "PRC-Prescott-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PRF",
            "countryCode": "US",
            "cityCode": "PRF",
            "cityName": "Port Johnson",
            "name": "[PRF] - Port Johnson - PRF - Port Johnson - United States",
            "searchName": "PRF-Port Johnson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PRO",
            "countryCode": "US",
            "cityCode": "PRO",
            "cityName": "Perry",
            "name": "[PRO] - Perry Municipal - PRO - Perry - United States",
            "searchName": "PRO-Kommunaler Flughafen-Perry Municipal-Perry-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PPV",
            "countryCode": "US",
            "cityCode": "PPV",
            "cityName": "Port Protection",
            "name": "[PPV] - Port Protection - PPV - Port Protection - United States",
            "searchName": "PPV-Port Protection-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PQI",
            "countryCode": "US",
            "cityCode": "PQI",
            "cityName": "Presque Isle",
            "name": "[PQI] - Presque Isle Municipal - PQI - Presque Isle - United States",
            "searchName": "PQI-Presque Isle-Presque Isle Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PIH",
            "countryCode": "US",
            "cityCode": "PIH",
            "cityName": "Pocatello",
            "name": "[PIH] - Pocatello - PIH - Pocatello - United States",
            "searchName": "PIH-Pocatello-Pocatello Idaho-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PIA",
            "countryCode": "US",
            "cityCode": "PIA",
            "cityName": "Peoria",
            "name": "[PIA] - Greater Peoria - PIA - Peoria - United States",
            "searchName": "PIA-Peoria-Greater Peoria-Peoria Illinois-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PIM",
            "countryCode": "US",
            "cityCode": "PIM",
            "cityName": "Pine Mountain",
            "name": "[PIM] - Garden Harris County - PIM - Pine Mountain - United States",
            "searchName": "PIM-Garden Harris County-Pine Mountain-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PIR",
            "countryCode": "US",
            "cityCode": "PIR",
            "cityName": "Pierre",
            "name": "[PIR] - Pierre - PIR - Pierre - United States",
            "searchName": "PIR-Pierre-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PIP",
            "countryCode": "US",
            "cityCode": "PIP",
            "cityName": "Pilot Point",
            "name": "[PIP] - Pilot Point - PIP - Pilot Point - United States",
            "searchName": "PIP-Pilot Point Arpt-Pilot Point-Pilot Point Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UGB",
            "countryCode": "US",
            "cityCode": "PIP",
            "cityName": "Pilot Point",
            "name": "[UGB] - Ugashik Bay - PIP - Pilot Point - United States",
            "searchName": "UGB-PIP-Ugashik Bay-Ugashnik Bay-Pilot Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PHT",
            "countryCode": "US",
            "cityCode": "PHT",
            "cityName": "Paris",
            "name": "[PHT] - Henry County - PHT - Paris - United States",
            "searchName": "PHT-Henry County-Henry County Havalimani-Paris-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PHX",
            "countryCode": "US",
            "cityCode": "PHX",
            "cityName": "Phoenix",
            "name": "[PHX] - Sky Harbor International - PHX - Phoenix - United States",
            "searchName": "PHX-Phoenix-Sky Harbor International-Sky Harbor Intl-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SCF",
            "countryCode": "US",
            "cityCode": "PHX",
            "cityName": "Phoenix",
            "name": "[SCF] - Scottsdale Municipalcipal - PHX - Phoenix - United States",
            "searchName": "SCF-PHX-Kommunaler Flughafen Scottsdale-Scottsdale Municipalcipal-Scottsdale  Havalimani-Phoenix-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LUF",
            "countryCode": "US",
            "cityCode": "PHX",
            "cityName": "Phoenix",
            "name": "[LUF] - Luke AFB - PHX - Phoenix - United States",
            "searchName": "LUF-PHX-Luke AFB-Phoenix-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DVT",
            "countryCode": "US",
            "cityCode": "PHX",
            "cityName": "Phoenix",
            "name": "[DVT] - Phoenix Deer Valley Airport - PHX - Phoenix - United States",
            "searchName": "DVT-PHX-Phoenix Deer Valley Airport-Phoenix Deer Valley Havalimani-Phoenix-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AZA",
            "countryCode": "US",
            "cityCode": "PHX",
            "cityName": "Phoenix",
            "name": "[AZA] - Williams Gateway - PHX - Phoenix - United States",
            "searchName": "AZA-PHX-Phoenix-Mesa Gateway-Williams Gateway-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PHK",
            "countryCode": "US",
            "cityCode": "PHK",
            "cityName": "Pahokee",
            "name": "[PHK] - Palm Beach Co Glades - PHK - Pahokee - United States",
            "searchName": "PHK-Palm Beach Co Glades-Palm Beach County Glades Havalimani-Pahokee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MIC",
            "countryCode": "US",
            "cityCode": "MSP",
            "cityName": "Minneapolis",
            "name": "[MIC] - Crystal - MSP - Minneapolis - United States",
            "searchName": "MIC-MSP-Crystal-Crystal Havalimani-Minneapolis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JDT",
            "countryCode": "US",
            "cityCode": "MSP",
            "cityName": "Minneapolis",
            "name": "[JDT] - Downtown Heliport - MSP - Minneapolis - United States",
            "searchName": "JDT-MSP-Downtown Heliport-Minneapolis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FCM",
            "countryCode": "US",
            "cityCode": "MSP",
            "cityName": "Minneapolis",
            "name": "[FCM] - Flying Cloud - MSP - Minneapolis - United States",
            "searchName": "FCM-MSP-Flying Cloud-Flying Cloud Havalimani-Minneapolis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "STP",
            "countryCode": "US",
            "cityCode": "MSP",
            "cityName": "Minneapolis",
            "name": "[STP] - Downtown - MSP - Minneapolis - United States",
            "searchName": "STP-MSP-Downtown-Downtown St Paul Havalimani-Minneapolis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MSC",
            "countryCode": "US",
            "cityCode": "MSC",
            "cityName": "Mesa",
            "name": "[MSC] - Falcon Field - MSC - Mesa - United States",
            "searchName": "MSC-Landeplatz Falcon-Falcon Field-Falcon Field Havalimani-Mesa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZBS",
            "countryCode": "US",
            "cityCode": "MSC",
            "cityName": "Mesa",
            "name": "[ZBS] - Mesa Bus Station - MSC - Mesa - United States",
            "searchName": "ZBS-MSC-Mesa Bus Station-Mesa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MSD",
            "countryCode": "US",
            "cityCode": "MSD",
            "cityName": "Mount Pleasant",
            "name": "[MSD] - Mt Pleasant - MSD - Mount Pleasant - United States",
            "searchName": "MSD-Mt Pleasant-Mount Pleasant-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MRY",
            "countryCode": "US",
            "cityCode": "MRY",
            "cityName": "Monterey",
            "name": "[MRY] - Monterey Peninsula - MRY - Monterey - United States",
            "searchName": "MRY-Monterey-Monterey Peninsula-Monterey Regional-Carmel/Monterey-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OAR",
            "countryCode": "US",
            "cityCode": "MRY",
            "cityName": "Monterey",
            "name": "[OAR] - Marina Municipal - MRY - Monterey - United States",
            "searchName": "OAR-MRY-Marina Municipal-Carmel/Monterey-Monterey-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MQI",
            "countryCode": "US",
            "cityCode": "MQI",
            "cityName": "Quincy",
            "name": "[MQI] - Quincy - MQI - Quincy - United States",
            "searchName": "MQI-Quincy-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MQB",
            "countryCode": "US",
            "cityCode": "MQB",
            "cityName": "Macomb",
            "name": "[MQB] - Municipal - MQB - Macomb - United States",
            "searchName": "MQB-Municipal-Macomb-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MRK",
            "countryCode": "US",
            "cityCode": "MRK",
            "cityName": "Marco Island",
            "name": "[MRK] - Marco Island - MRK - Marco Island - United States",
            "searchName": "MRK-Marco Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MRF",
            "countryCode": "US",
            "cityCode": "MRF",
            "cityName": "Marfa",
            "name": "[MRF] - Municipal - MRF - Marfa - United States",
            "searchName": "MRF-Municipal-Marfa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MRN",
            "countryCode": "US",
            "cityCode": "MRN",
            "cityName": "Morganton",
            "name": "[MRN] - Lenoir - MRN - Morganton - United States",
            "searchName": "MRN-Lenoir-Morganton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MQT",
            "countryCode": "US",
            "cityCode": "MQT",
            "cityName": "Marquette",
            "name": "[MQT] - Sawyer International - MQT - Marquette - United States",
            "searchName": "MQT-Marquette-Sawyer International-Sawyer Intl-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MRB",
            "countryCode": "US",
            "cityCode": "MRB",
            "cityName": "Martinsburg",
            "name": "[MRB] - Eastern Wv Regional Airport/Shepherd Field - MRB - Martinsburg - United States",
            "searchName": "MRB-Eastern Wv Regional Airport/Shepherd Field-Martinsburgh Yerel Havalimani-Martinsburg-Martinsburgh-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MRC",
            "countryCode": "US",
            "cityCode": "MRC",
            "cityName": "Columbia",
            "name": "[MRC] - Maury County - MRC - Columbia - United States",
            "searchName": "MRC-Maury County-Maury Country Havalimani-Columbia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MQY",
            "countryCode": "US",
            "cityCode": "MQY",
            "cityName": "Smyrna",
            "name": "[MQY] - Smyrna - MQY - Smyrna - United States",
            "searchName": "MQY-Smyrna-Smyrna Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MQW",
            "countryCode": "US",
            "cityCode": "MQW",
            "cityName": "Mc Rae",
            "name": "[MQW] - Telfair-Wheeler - MQW - Mc Rae - United States",
            "searchName": "MQW-Telfair-Wheeler-Mc Rae-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ODW",
            "countryCode": "US",
            "cityCode": "ODW",
            "cityName": "Oak Harbor",
            "name": "[ODW] - Oak Harbor - ODW - Oak Harbor - United States",
            "searchName": "ODW-Oak Harbor-Oak Harbour Belediyesi Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OEA",
            "countryCode": "US",
            "cityCode": "OEA",
            "cityName": "Vincennes",
            "name": "[OEA] - Oneal - OEA - Vincennes - United States",
            "searchName": "OEA-Oneal-Oneal Havalimani-Vincennes-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OEO",
            "countryCode": "US",
            "cityCode": "OEO",
            "cityName": "Osceola",
            "name": "[OEO] - Municipal - OEO - Osceola - United States",
            "searchName": "OEO-Municipal-Osceola-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OCW",
            "countryCode": "US",
            "cityCode": "OCW",
            "cityName": "Washington",
            "name": "[OCW] - Warren Field - OCW - Washington - United States",
            "searchName": "OCW-Warren Field-Washington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OBT",
            "countryCode": "US",
            "cityCode": "OBT",
            "cityName": "Oakland",
            "name": "[OBT] - Oakland/Coliseum Stat - OBT - Oakland - United States",
            "searchName": "OBT-Oakland/Coliseum Stat-Oakland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OBU",
            "countryCode": "US",
            "cityCode": "OBU",
            "cityName": "Kobuk",
            "name": "[OBU] - Kobuk/Wien - OBU - Kobuk - United States",
            "searchName": "OBU-Kobuk/Wien-Kobuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OCE",
            "countryCode": "US",
            "cityCode": "OCE",
            "cityName": "Ocean City",
            "name": "[OCE] - Municipal - OCE - Ocean City - United States",
            "searchName": "OCE-Municipal-Ocean City Havalimani-Ocean City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OCF",
            "countryCode": "US",
            "cityCode": "OCF",
            "cityName": "Ocala",
            "name": "[OCF] - Taylor Field - OCF - Ocala - United States",
            "searchName": "OCF-Landeplatz Taylor-Taylor Field-Taylor Field Havalimani-Ocala-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OCH",
            "countryCode": "US",
            "cityCode": "OCH",
            "cityName": "Nacogdoches",
            "name": "[OCH] - A L Mangham Jr. Regional - OCH - Nacogdoches - United States",
            "searchName": "OCH-A L Mangham Jr. Regional-Nacogdoches-Lufkin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LFK",
            "countryCode": "US",
            "cityCode": "OCH",
            "cityName": "Nacogdoches",
            "name": "[LFK] - Lufkin Angelina County - OCH - Nacogdoches - United States",
            "searchName": "LFK-OCH-Lufkin Angelina County-Angelina Cty Havalimani-Nacogdoches-Lufkin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OCI",
            "countryCode": "US",
            "cityCode": "OCI",
            "cityName": "Oceanic",
            "name": "[OCI] - Oceanic - OCI - Oceanic - United States",
            "searchName": "OCI-Oceanic-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OCN",
            "countryCode": "US",
            "cityCode": "OCN",
            "cityName": "Oceanside",
            "name": "[OCN] - Municipal - OCN - Oceanside - United States",
            "searchName": "OCN-Kommunaler Flughafen-Municipal-Oceanside  Havalimani-Oceanside-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OCA",
            "countryCode": "US",
            "cityCode": "OCA",
            "cityName": "Ocean Reef",
            "name": "[OCA] - Ocean Reef - OCA - Ocean Reef - United States",
            "searchName": "OCA-Ocean Reef-Ocean Reef Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ODC",
            "countryCode": "US",
            "cityCode": "ODC",
            "cityName": "Oakdale",
            "name": "[ODC] - Oakdale - ODC - Oakdale - United States",
            "searchName": "ODC-Oakdale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NXX",
            "countryCode": "US",
            "cityCode": "NXX",
            "cityName": "Willow Grove",
            "name": "[NXX] - Willow Grove NAS - NXX - Willow Grove - United States",
            "searchName": "NXX-Willow Grove NAS-Willow Grove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NYG",
            "countryCode": "US",
            "cityCode": "NYG",
            "cityName": "Quantico",
            "name": "[NYG] - Quantico NAS - NYG - Quantico - United States",
            "searchName": "NYG-Quantico NAS-Quantico-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NZW",
            "countryCode": "US",
            "cityCode": "NZW",
            "cityName": "South Weymouth",
            "name": "[NZW] - South Weymouth - NZW - South Weymouth - United States",
            "searchName": "NZW-South Weymouth-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OAJ",
            "countryCode": "US",
            "cityCode": "OAJ",
            "cityName": "Jacksonville",
            "name": "[OAJ] - Albert J Ellis - OAJ - Jacksonville - United States",
            "searchName": "OAJ-Jacksonville Albert J Ellis-Albert J Ellis-Jacksonville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OAK",
            "countryCode": "US",
            "cityCode": "OAK",
            "cityName": "Oakland",
            "name": "[OAK] - Metropolitan Oak International - OAK - Oakland - United States",
            "searchName": "OAK-Oakland-Metropolitan Oak International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JCE",
            "countryCode": "US",
            "cityCode": "OAK",
            "cityName": "Oakland",
            "name": "[JCE] - Convention Center Heliport - OAK - Oakland - United States",
            "searchName": "JCE-OAK-Convention Center Heliport-Oakland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OBE",
            "countryCode": "US",
            "cityCode": "OBE",
            "cityName": "Okeechobee",
            "name": "[OBE] - County - OBE - Okeechobee - United States",
            "searchName": "OBE-County-Okeechobee County Havalimani-Okeechobee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OGS",
            "countryCode": "US",
            "cityCode": "OGS",
            "cityName": "Ogdensburg",
            "name": "[OGS] - Ogdensburg - OGS - Ogdensburg - United States",
            "searchName": "OGS-Ogdensburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OGG",
            "countryCode": "US",
            "cityCode": "OGG",
            "cityName": "Maui",
            "name": "[OGG] - Kahului - OGG - Maui - United States",
            "searchName": "OGG-Kahului-Kahului Hawaii-Maui-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OFK",
            "countryCode": "US",
            "cityCode": "OFK",
            "cityName": "Norfolk",
            "name": "[OFK] - Stefan Field - OFK - Norfolk - United States",
            "searchName": "OFK-Stefan Field-Norfolk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OGA",
            "countryCode": "US",
            "cityCode": "OGA",
            "cityName": "Ogallala",
            "name": "[OGA] - Searle Field - OGA - Ogallala - United States",
            "searchName": "OGA-Searle Field-Searle Field Havalimani-Ogallala-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OGB",
            "countryCode": "US",
            "cityCode": "OGB",
            "cityName": "Orangeburg",
            "name": "[OGB] - Municipal - OGB - Orangeburg - United States",
            "searchName": "OGB-Municipal-Orangeburg Municpal Havalimani-Orangeburg-Columbia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OGD",
            "countryCode": "US",
            "cityCode": "OGD",
            "cityName": "Ogden",
            "name": "[OGD] - Municipal - OGD - Ogden - United States",
            "searchName": "OGD-Municipal-Ogden-Ogden Belediyesi Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HIF",
            "countryCode": "US",
            "cityCode": "OGD",
            "cityName": "Ogden",
            "name": "[HIF] - Hill AFB - OGD - Ogden - United States",
            "searchName": "HIF-OGD-Hill AFB-Ogden-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OHC",
            "countryCode": "US",
            "cityCode": "OHC",
            "cityName": "Northeast Cape",
            "name": "[OHC] - AFS - OHC - Northeast Cape - United States",
            "searchName": "OHC-Luftwaffenstützpunkt-AFS-Northeast Cape-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OLD",
            "countryCode": "US",
            "cityCode": "OLD",
            "cityName": "Old Town",
            "name": "[OLD] - Old Town - OLD - Old Town - United States",
            "searchName": "OLD-Old Town-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OLE",
            "countryCode": "US",
            "cityCode": "OLE",
            "cityName": "Olean",
            "name": "[OLE] - Municipal - OLE - Olean - United States",
            "searchName": "OLE-Municipal-Olean-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OLF",
            "countryCode": "US",
            "cityCode": "OLF",
            "cityName": "Wolf Point",
            "name": "[OLF] - International - OLF - Wolf Point - United States",
            "searchName": "OLF-Wolf Point-International-Wolf Point International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OLH",
            "countryCode": "US",
            "cityCode": "OLH",
            "cityName": "Old Harbor",
            "name": "[OLH] - Old Harbor SPB - OLH - Old Harbor - United States",
            "searchName": "OLH-Old Harbor SPB-Old Harbor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OKS",
            "countryCode": "US",
            "cityCode": "OKS",
            "cityName": "Oshkosh",
            "name": "[OKS] - Oshkosh - OKS - Oshkosh - United States",
            "searchName": "OKS-Oshkosh-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OKM",
            "countryCode": "US",
            "cityCode": "OKM",
            "cityName": "Okmulgee",
            "name": "[OKM] - Okmulgee - OKM - Okmulgee - United States",
            "searchName": "OKM-Okmulgee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OKK",
            "countryCode": "US",
            "cityCode": "OKK",
            "cityName": "Kokomo",
            "name": "[OKK] - Kokomo - OKK - Kokomo - United States",
            "searchName": "OKK-Kokomo-Kokomo Belediyesi Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OIC",
            "countryCode": "US",
            "cityCode": "OIC",
            "cityName": "Norwich",
            "name": "[OIC] - Eaton - OIC - Norwich - United States",
            "searchName": "OIC-Eaton-Norwich-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OKC",
            "countryCode": "US",
            "cityCode": "OKC",
            "cityName": "Oklahoma City",
            "name": "[OKC] - Will Rogers World Airport - OKC - Oklahoma City - United States",
            "searchName": "OKC-Oklahoma City-Will Rogers World Airport-Will Rogers World-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PWA",
            "countryCode": "US",
            "cityCode": "OKC",
            "cityName": "Oklahoma City",
            "name": "[PWA] - Wiley Post - OKC - Oklahoma City - United States",
            "searchName": "PWA-OKC-Wiley Post-Wiley Post Havalimani-Oklahoma City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TIK",
            "countryCode": "US",
            "cityCode": "OKC",
            "cityName": "Oklahoma City",
            "name": "[TIK] - Tinker AFB - OKC - Oklahoma City - United States",
            "searchName": "TIK-OKC-Tinker AFB-Oklahoma City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NOL",
            "countryCode": "US",
            "cityCode": "NOL",
            "cityName": "Nakolik River",
            "name": "[NOL] - Nakolik River - NOL - Nakolik River - United States",
            "searchName": "NOL-Nakolik River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NPH",
            "countryCode": "US",
            "cityCode": "NPH",
            "cityName": "Nephi",
            "name": "[NPH] - Nephi - NPH - Nephi - United States",
            "searchName": "NPH-Nephi-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NOT",
            "countryCode": "US",
            "cityCode": "NOT",
            "cityName": "Novato",
            "name": "[NOT] - Novato - NOT - Novato - United States",
            "searchName": "NOT-Novato-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NNK",
            "countryCode": "US",
            "cityCode": "NNK",
            "cityName": "Naknek",
            "name": "[NNK] - Naknek - NNK - Naknek - United States",
            "searchName": "NNK-Naknek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NNL",
            "countryCode": "US",
            "cityCode": "NNL",
            "cityName": "Nondalton",
            "name": "[NNL] - Nondalton - NNL - Nondalton - United States",
            "searchName": "NNL-Nondalton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NSE",
            "countryCode": "US",
            "cityCode": "NSE",
            "cityName": "Milton",
            "name": "[NSE] - Whiting Field NAS - NSE - Milton - United States",
            "searchName": "NSE-Marinefliegerstützpunkt Whiting Field-Whiting Field NAS-Whiting Field Nas Havalimani-Milton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NSL",
            "countryCode": "US",
            "cityCode": "NSL",
            "cityName": "Slayton",
            "name": "[NSL] - Slayton Municipal - NSL - Slayton - United States",
            "searchName": "NSL-Slayton Municipal-Slayton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NRS",
            "countryCode": "US",
            "cityCode": "NRS",
            "cityName": "Imperial Beach",
            "name": "[NRS] - NAS - NRS - Imperial Beach - United States",
            "searchName": "NRS-NAS-Imperial Beach-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NRI",
            "countryCode": "US",
            "cityCode": "NRI",
            "cityName": "Shangri-la",
            "name": "[NRI] - Shangri-la - NRI - Shangri-la - United States",
            "searchName": "NRI-Shangri-la-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NPT",
            "countryCode": "US",
            "cityCode": "NPT",
            "cityName": "Newport",
            "name": "[NPT] - State - NPT - Newport - United States",
            "searchName": "NPT-State-Newport State Havalimani-Newport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NQI",
            "countryCode": "US",
            "cityCode": "NQI",
            "cityName": "Kingsville",
            "name": "[NQI] - NAS - NQI - Kingsville - United States",
            "searchName": "NQI-NAS-Naval Havalimani-Kingsville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NRB",
            "countryCode": "US",
            "cityCode": "NRB",
            "cityName": "Mayport",
            "name": "[NRB] - Ns - NRB - Mayport - United States",
            "searchName": "NRB-Ns-Mayport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NTU",
            "countryCode": "US",
            "cityCode": "NTU",
            "cityName": "Oceana",
            "name": "[NTU] - NAS - NTU - Oceana - United States",
            "searchName": "NTU-Marinefliegerstützpunkt-NAS-Oceana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NUI",
            "countryCode": "US",
            "cityCode": "NUI",
            "cityName": "Nuiqsut",
            "name": "[NUI] - Nuiqsut - NUI - Nuiqsut - United States",
            "searchName": "NUI-Nuiqsut-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NTD",
            "countryCode": "US",
            "cityCode": "NTD",
            "cityName": "Port Hueneme",
            "name": "[NTD] - Point Mugu NAS (Naval Base Ventura County - NTD - Port Hueneme - United States",
            "searchName": "NTD-Point Mugu NAS (Naval Base Ventura County-Port Hueneme-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NTJ",
            "countryCode": "US",
            "cityCode": "NTJ",
            "cityName": "Manti",
            "name": "[NTJ] - Manti-Ephraim - NTJ - Manti - United States",
            "searchName": "NTJ-Manti-Ephraim-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NWH",
            "countryCode": "US",
            "cityCode": "NWH",
            "cityName": "Newport",
            "name": "[NWH] - Parlin Field - NWH - Newport - United States",
            "searchName": "NWH-Parlin Field-Newport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NVD",
            "countryCode": "US",
            "cityCode": "NVD",
            "cityName": "Nevada",
            "name": "[NVD] - Nevada - NVD - Nevada - United States",
            "searchName": "NVD-Nevada-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NVN",
            "countryCode": "US",
            "cityCode": "NVN",
            "cityName": "Beckwourth",
            "name": "[NVN] - Nervino - NVN - Beckwourth - United States",
            "searchName": "NVN-Nervino-Beckwourth-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NUL",
            "countryCode": "US",
            "cityCode": "NUL",
            "cityName": "Nulato",
            "name": "[NUL] - Nulato - NUL - Nulato - United States",
            "searchName": "NUL-Nulato-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NUP",
            "countryCode": "US",
            "cityCode": "NUP",
            "cityName": "Nunapitchuk",
            "name": "[NUP] - Nunapitchuk - NUP - Nunapitchuk - United States",
            "searchName": "NUP-Nunapitchuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NUQ",
            "countryCode": "US",
            "cityCode": "NUQ",
            "cityName": "Mountain View",
            "name": "[NUQ] - Moffett Field - NUQ - Mountain View - United States",
            "searchName": "NUQ-Moffett Field-Moffett Field Havalimani-Mountain View-Mountain Village-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NUW",
            "countryCode": "US",
            "cityCode": "NUW",
            "cityName": "Whidbey Island",
            "name": "[NUW] - Whidbey Island NAS (Ault Field) - NUW - Whidbey Island - United States",
            "searchName": "NUW-Whidbey Island NAS (Ault Field)-Whidbey Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MXO",
            "countryCode": "US",
            "cityCode": "MXO",
            "cityName": "Monticello",
            "name": "[MXO] - Municipal - MXO - Monticello - United States",
            "searchName": "MXO-Municipal-Monticello-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MWS",
            "countryCode": "US",
            "cityCode": "MWS",
            "cityName": "Mount Wilson",
            "name": "[MWS] - Mount Wilson - MWS - Mount Wilson - United States",
            "searchName": "MWS-Mount Wilson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MXA",
            "countryCode": "US",
            "cityCode": "MXA",
            "cityName": "Manila",
            "name": "[MXA] - Municipal - MXA - Manila - United States",
            "searchName": "MXA-Municipal-Manila-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MXC",
            "countryCode": "US",
            "cityCode": "MXC",
            "cityName": "Monticello",
            "name": "[MXC] - San Juan County - MXC - Monticello - United States",
            "searchName": "MXC-San Juan County-San Juan County Havalimani-Monticello-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MXE",
            "countryCode": "US",
            "cityCode": "MXE",
            "cityName": "Maxton",
            "name": "[MXE] - Maxton - MXE - Maxton - United States",
            "searchName": "MXE-Maxton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MXG",
            "countryCode": "US",
            "cityCode": "MXG",
            "cityName": "Marlborough",
            "name": "[MXG] - Marlborough - MXG - Marlborough - United States",
            "searchName": "MXG-Marlborough-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MXY",
            "countryCode": "US",
            "cityCode": "MXY",
            "cityName": "Mccarthy",
            "name": "[MXY] - Mccarthy - MXY - Mccarthy - United States",
            "searchName": "MXY-Mccarthy-McCarthy Havalimani-McCarthy-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MYH",
            "countryCode": "US",
            "cityCode": "MYH",
            "cityName": "Marble Canyon",
            "name": "[MYH] - Marble Canyon - MYH - Marble Canyon - United States",
            "searchName": "MYH-Marble Canyon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MYK",
            "countryCode": "US",
            "cityCode": "MYK",
            "cityName": "May Creek",
            "name": "[MYK] - May Creek - MYK - May Creek - United States",
            "searchName": "MYK-May Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MYL",
            "countryCode": "US",
            "cityCode": "MYL",
            "cityName": "Mccall",
            "name": "[MYL] - Mccall - MYL - Mccall - United States",
            "searchName": "MYL-Mccall-Mccall Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MWO",
            "countryCode": "US",
            "cityCode": "MWO",
            "cityName": "Middletown",
            "name": "[MWO] - Hook Field - MWO - Middletown - United States",
            "searchName": "MWO-Hook Field-Hook Field Havalimani-Middletown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MWH",
            "countryCode": "US",
            "cityCode": "MWH",
            "cityName": "Moses Lake",
            "name": "[MWH] - Grant County - MWH - Moses Lake - United States",
            "searchName": "MWH-Grant County-Moses Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LRN",
            "countryCode": "US",
            "cityCode": "MWH",
            "cityName": "Moses Lake",
            "name": "[LRN] - Larson AFB - MWH - Moses Lake - United States",
            "searchName": "LRN-MWH-Larson AFB-Moses Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MWL",
            "countryCode": "US",
            "cityCode": "MWL",
            "cityName": "Mineral Wells",
            "name": "[MWL] - Mineral Wells Airport - MWL - Mineral Wells - United States",
            "searchName": "MWL-Mineral Wells Airport-Mineral Wells-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CWO",
            "countryCode": "US",
            "cityCode": "MWL",
            "cityName": "Mineral Wells",
            "name": "[CWO] - Ft Wolter AAF - MWL - Mineral Wells - United States",
            "searchName": "CWO-MWL-Ft Wolter AAF-Mineral Wells-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MWM",
            "countryCode": "US",
            "cityCode": "MWM",
            "cityName": "Windom",
            "name": "[MWM] - Windom Municipal - MWM - Windom - United States",
            "searchName": "MWM-Windom Municipal-Windom-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MVE",
            "countryCode": "US",
            "cityCode": "MVE",
            "cityName": "Montevideo",
            "name": "[MVE] - Montevideo-Chippewa - MVE - Montevideo - United States",
            "searchName": "MVE-Montevideo-Chippewa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MVW",
            "countryCode": "US",
            "cityCode": "MVW",
            "cityName": "Mount Vernon",
            "name": "[MVW] - Skagit Regional - MVW - Mount Vernon - United States",
            "searchName": "MVW-Skagit Regional-Mount Vernon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MVY",
            "countryCode": "US",
            "cityCode": "MVY",
            "cityName": "Vineyard Haven",
            "name": "[MVY] - Marthas Vineyard - MVY - Vineyard Haven - United States",
            "searchName": "MVY-Martha s Vineyard-Marthas Vineyard-Vineyard Haven-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MWA",
            "countryCode": "US",
            "cityCode": "MWA",
            "cityName": "Marion",
            "name": "[MWA] - Williamson County - MWA - Marion - United States",
            "searchName": "MWA-Marion-Williamson County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MYR",
            "countryCode": "US",
            "cityCode": "MYR",
            "cityName": "Myrtle Beach",
            "name": "[MYR] - Myrtle Beach AFB - MYR - Myrtle Beach - United States",
            "searchName": "MYR-Myrtle Beach-Myrtle Beach AFB-Metropolitan Area-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CRE",
            "countryCode": "US",
            "cityCode": "MYR",
            "cityName": "Myrtle Beach",
            "name": "[CRE] - Grand Strand Airport - MYR - Myrtle Beach - United States",
            "searchName": "CRE-MYR-Grand Strand Airport-Grand Strand Havalimani-Myrtle Beach-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MYU",
            "countryCode": "US",
            "cityCode": "MYU",
            "cityName": "Mekoryuk",
            "name": "[MYU] - Ellis Field - MYU - Mekoryuk - United States",
            "searchName": "MYU-Ellis Field-Mekoryuk-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MYV",
            "countryCode": "US",
            "cityCode": "MYV",
            "cityName": "Marysville",
            "name": "[MYV] - Yuba County - MYV - Marysville - United States",
            "searchName": "MYV-Yuba County-Yuba County Havalimani-Marysville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BAB",
            "countryCode": "US",
            "cityCode": "MYV",
            "cityName": "Marysville",
            "name": "[BAB] - Beale AFB - MYV - Marysville - United States",
            "searchName": "BAB-MYV-Beale AFB-Marysville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MZJ",
            "countryCode": "US",
            "cityCode": "MZJ",
            "cityName": "Marana",
            "name": "[MZJ] - Marana - MZJ - Marana - United States",
            "searchName": "MZJ-Marana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NAX",
            "countryCode": "US",
            "cityCode": "NAX",
            "cityName": "Kapolei",
            "name": "[NAX] - Kalaeloa John Rodgers Field - NAX - Kapolei - United States",
            "searchName": "NAX-Kalaeloa John Rodgers Field-Kapolei-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MZZ",
            "countryCode": "US",
            "cityCode": "MZZ",
            "cityName": "Marion",
            "name": "[MZZ] - Marion - MZZ - Marion - United States",
            "searchName": "MZZ-Marion-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NLC",
            "countryCode": "US",
            "cityCode": "NLC",
            "cityName": "Lemoore",
            "name": "[NLC] - Lemoore NAS (Reeves Field) - NLC - Lemoore - United States",
            "searchName": "NLC-Lemoore NAS (Reeves Field)-Nas Reeves Field Havalimani-Lemoore-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NME",
            "countryCode": "US",
            "cityCode": "NME",
            "cityName": "Nightmute",
            "name": "[NME] - Nightmute - NME - Nightmute - United States",
            "searchName": "NME-Nightmute-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NKV",
            "countryCode": "US",
            "cityCode": "NKV",
            "cityName": "Nichen Cove",
            "name": "[NKV] - Nichen Cove - NKV - Nichen Cove - United States",
            "searchName": "NKV-Nichen Cove-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NLE",
            "countryCode": "US",
            "cityCode": "NLE",
            "cityName": "Niles",
            "name": "[NLE] - Jerry Tyler Memorial - NLE - Niles - United States",
            "searchName": "NLE-Jerry Tyler Memorial-Niles-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NLG",
            "countryCode": "US",
            "cityCode": "NLG",
            "cityName": "Nelson Lagoon",
            "name": "[NLG] - Nelson Lagoon - NLG - Nelson Lagoon - United States",
            "searchName": "NLG-Nelson Lagoon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NLN",
            "countryCode": "US",
            "cityCode": "NLN",
            "cityName": "Kneeland",
            "name": "[NLN] - Kneeland - NLN - Kneeland - United States",
            "searchName": "NLN-Kneeland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NIB",
            "countryCode": "US",
            "cityCode": "NIB",
            "cityName": "Nikolai",
            "name": "[NIB] - Nikolai - NIB - Nikolai - United States",
            "searchName": "NIB-Nikolai-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NIE",
            "countryCode": "US",
            "cityCode": "NIE",
            "cityName": "Niblack",
            "name": "[NIE] - Niblack - NIE - Niblack - United States",
            "searchName": "NIE-Niblack-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NHX",
            "countryCode": "US",
            "cityCode": "NHX",
            "cityName": "Foley",
            "name": "[NHX] - Barin Olf Osn - NHX - Foley - United States",
            "searchName": "NHX-Barin Olf Osn-Foley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NHZ",
            "countryCode": "US",
            "cityCode": "NHZ",
            "cityName": "Brunswick",
            "name": "[NHZ] - NAS - NHZ - Brunswick - United States",
            "searchName": "NHZ-NAS-Naval Air Station Havalimani-Brunswick-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NHK",
            "countryCode": "US",
            "cityCode": "NHK",
            "cityName": "Patuxent River",
            "name": "[NHK] - Patuxent River NAS - NHK - Patuxent River - United States",
            "searchName": "NHK-Patuxent River NAS-Patuxent River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NKI",
            "countryCode": "US",
            "cityCode": "NKI",
            "cityName": "Naukiti",
            "name": "[NKI] - Naukiti - NKI - Naukiti - United States",
            "searchName": "NKI-Naukiti-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NIN",
            "countryCode": "US",
            "cityCode": "NIN",
            "cityName": "Ninilchik",
            "name": "[NIN] - Ninilchik - NIN - Ninilchik - United States",
            "searchName": "NIN-Ninilchik-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NIR",
            "countryCode": "US",
            "cityCode": "NIR",
            "cityName": "Beeville",
            "name": "[NIR] - Chase Field NAS - NIR - Beeville - United States",
            "searchName": "NIR-Marinefliegerstützpunkt Chase Field-Chase Field NAS-Beeville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NCN",
            "countryCode": "US",
            "cityCode": "NCN",
            "cityName": "New Chenega",
            "name": "[NCN] - New Chenega - NCN - New Chenega - United States",
            "searchName": "NCN-New Chenega-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NCO",
            "countryCode": "US",
            "cityCode": "NCO",
            "cityName": "Quonset Point",
            "name": "[NCO] - NAS - NCO - Quonset Point - United States",
            "searchName": "NCO-NAS-Quonset Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NBU",
            "countryCode": "US",
            "cityCode": "NBU",
            "cityName": "Glenview",
            "name": "[NBU] - NAS - NBU - Glenview - United States",
            "searchName": "NBU-NAS-Glenview-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NFL",
            "countryCode": "US",
            "cityCode": "NFL",
            "cityName": "Fallon",
            "name": "[NFL] - NAS - NFL - Fallon - United States",
            "searchName": "NFL-NAS-Fallon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FLX",
            "countryCode": "US",
            "cityCode": "NFL",
            "cityName": "Fallon",
            "name": "[FLX] - Municipal - NFL - Fallon - United States",
            "searchName": "FLX-NFL-Kommunaler Flughafen-Municipal-Fallon  Havalimani-Fallon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NEA",
            "countryCode": "US",
            "cityCode": "NEA",
            "cityName": "Glynco",
            "name": "[NEA] - Brunswick Golden Isles - NEA - Glynco - United States",
            "searchName": "NEA-Brunswick Golden Isles-Glynco-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NDV",
            "countryCode": "US",
            "cityCode": "NDV",
            "cityName": "Anacostia",
            "name": "[NDV] - USN Heliport - NDV - Anacostia - United States",
            "searchName": "NDV-USN Heliport-Anacostia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NEL",
            "countryCode": "US",
            "cityCode": "NEL",
            "cityName": "Lakehurst",
            "name": "[NEL] - Naec - NEL - Lakehurst - United States",
            "searchName": "NEL-Naec-Lakehurst-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NEN",
            "countryCode": "US",
            "cityCode": "NEN",
            "cityName": "Whitehouse",
            "name": "[NEN] - Olf Usn - NEN - Whitehouse - United States",
            "searchName": "NEN-Olf USN-Olf Usn-Whitehouse-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PAQ",
            "countryCode": "US",
            "cityCode": "PAQ",
            "cityName": "Palmer",
            "name": "[PAQ] - Palmer Municipalcipal - PAQ - Palmer - United States",
            "searchName": "PAQ-Palmer Municipalcipal-Palmer-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PBF",
            "countryCode": "US",
            "cityCode": "PBF",
            "cityName": "Pine Bluff",
            "name": "[PBF] - Grider Field - PBF - Pine Bluff - United States",
            "searchName": "PBF-Grider Field-Pine Bluff Havalimani-Pine Bluff-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PBG",
            "countryCode": "US",
            "cityCode": "PBG",
            "cityName": "Plattsburgh",
            "name": "[PBG] - Plattsburgh International - PBG - Plattsburgh - United States",
            "searchName": "PBG-Plattsburgh Intl-Plattsburgh International-Plattsburgh Uluslararasi Havalimani-Plattsburgh-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PBI",
            "countryCode": "US",
            "cityCode": "PBI",
            "cityName": "West Palm Beach",
            "name": "[PBI] - International - PBI - West Palm Beach - United States",
            "searchName": "PBI-West Palm Beach-International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZWP",
            "countryCode": "US",
            "cityCode": "PBI",
            "cityName": "West Palm Beach",
            "name": "[ZWP] - West Palm Beach Railway Station - PBI - West Palm Beach - United States",
            "searchName": "ZWP-PBI-West Palm Beach Railway Station-West Palm Beach-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LNA",
            "countryCode": "US",
            "cityCode": "PBI",
            "cityName": "West Palm Beach",
            "name": "[LNA] - Palm Beach County Park - PBI - West Palm Beach - United States",
            "searchName": "LNA-PBI-Palm Beach County Park-Palm Beach County Havalimani-West Palm Beach-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PBK",
            "countryCode": "US",
            "cityCode": "PBK",
            "cityName": "Pack Creek",
            "name": "[PBK] - Pack Creek - PBK - Pack Creek - United States",
            "searchName": "PBK-Pack Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PCA",
            "countryCode": "US",
            "cityCode": "PCA",
            "cityName": "Portage Creek",
            "name": "[PCA] - Portage Creek - PCA - Portage Creek - United States",
            "searchName": "PCA-Portage Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PCK",
            "countryCode": "US",
            "cityCode": "PCK",
            "cityName": "Porcupine Creek",
            "name": "[PCK] - Porcupine Creek - PCK - Porcupine Creek - United States",
            "searchName": "PCK-Porcupine Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PCD",
            "countryCode": "US",
            "cityCode": "PCD",
            "cityName": "Prairie Du Chien",
            "name": "[PCD] - Prairie du Chien Municipal Airport - PCD - Prairie Du Chien - United States",
            "searchName": "PCD-Prairie du Chien Municipal Airport-Prairie Du Chien  Havalimani-Prairie Du Chien-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PCE",
            "countryCode": "US",
            "cityCode": "PCE",
            "cityName": "Painter Creek",
            "name": "[PCE] - Painter Creek - PCE - Painter Creek - United States",
            "searchName": "PCE-Painter Creek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PDB",
            "countryCode": "US",
            "cityCode": "PDB",
            "cityName": "Pedro Bay",
            "name": "[PDB] - Pedro Bay - PDB - Pedro Bay - United States",
            "searchName": "PDB-Pedro Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PCT",
            "countryCode": "US",
            "cityCode": "PCT",
            "cityName": "Princeton",
            "name": "[PCT] - Princeton - PCT - Princeton - United States",
            "searchName": "PCT-Princeton-Princeton Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZTJ",
            "countryCode": "US",
            "cityCode": "PCT",
            "cityName": "Princeton",
            "name": "[ZTJ] - Princeton JT Railway - PCT - Princeton - United States",
            "searchName": "ZTJ-PCT-Princeton JT Railway-Princeton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PCU",
            "countryCode": "US",
            "cityCode": "PCU",
            "cityName": "Picayune",
            "name": "[PCU] - Pearl River County - PCU - Picayune - United States",
            "searchName": "PCU-Pearl River County-Picayune-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OXR",
            "countryCode": "US",
            "cityCode": "OXR",
            "cityName": "Ventura",
            "name": "[OXR] - Ventura - OXR - Ventura - United States",
            "searchName": "OXR-Ventura-Oxnard Ventura Airport-Oxnard-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OYS",
            "countryCode": "US",
            "cityCode": "OYS",
            "cityName": "Yosemite",
            "name": "[OYS] - Yosemite Ntl Park - OYS - Yosemite - United States",
            "searchName": "OYS-Yosemite Ntl Park-Yosemite-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OZA",
            "countryCode": "US",
            "cityCode": "OZA",
            "cityName": "Ozona",
            "name": "[OZA] - Ozona Municipalcipal - OZA - Ozona - United States",
            "searchName": "OZA-Ozona Municipalcipal-Ozona-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OVE",
            "countryCode": "US",
            "cityCode": "OVE",
            "cityName": "Oroville",
            "name": "[OVE] - Oroville - OVE - Oroville - United States",
            "searchName": "OVE-Oroville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OWA",
            "countryCode": "US",
            "cityCode": "OWA",
            "cityName": "Owatonna",
            "name": "[OWA] - Owatonna - OWA - Owatonna - United States",
            "searchName": "OWA-Owatonna-Owatonna Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OWB",
            "countryCode": "US",
            "cityCode": "OWB",
            "cityName": "Owensboro",
            "name": "[OWB] - Daviess County - OWB - Owensboro - United States",
            "searchName": "OWB-Daviess County-Owensboro-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OWD",
            "countryCode": "US",
            "cityCode": "OWD",
            "cityName": "Norwood",
            "name": "[OWD] - Memorial - OWD - Norwood - United States",
            "searchName": "OWD-Memorial-Norwood Ma Havalimani-Norwood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OXC",
            "countryCode": "US",
            "cityCode": "OXC",
            "cityName": "Oxford",
            "name": "[OXC] - Waterbury-oxford - OXC - Oxford - United States",
            "searchName": "OXC-Waterbury-oxford-Waterbury Oxford Havalimani-Oxford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OXD",
            "countryCode": "US",
            "cityCode": "OXD",
            "cityName": "Oxford",
            "name": "[OXD] - Miami University - OXD - Oxford - United States",
            "searchName": "OXD-Miami University-Oxford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OWK",
            "countryCode": "US",
            "cityCode": "OWK",
            "cityName": "Norridgewock",
            "name": "[OWK] - Central Maine Airport of Norridgewock - OWK - Norridgewock - United States",
            "searchName": "OWK-Zentral-Maine-Central Maine Airport of Norridgewock-Norrigdewock-Norridgewock-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PAO",
            "countryCode": "US",
            "cityCode": "PAO",
            "cityName": "Palo Alto",
            "name": "[PAO] - Palo Alto - PAO - Palo Alto - United States",
            "searchName": "PAO-Palo Alto-Palo Alto Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PAK",
            "countryCode": "US",
            "cityCode": "PAK",
            "cityName": "Hanapepe",
            "name": "[PAK] - Port Allen - PAK - Hanapepe - United States",
            "searchName": "PAK-Port Allen-Hanapepe-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PAH",
            "countryCode": "US",
            "cityCode": "PAH",
            "cityName": "Paducah",
            "name": "[PAH] - Barkley Regional - PAH - Paducah - United States",
            "searchName": "PAH-Paducah-Barkley Regional-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PAE",
            "countryCode": "US",
            "cityCode": "PAE",
            "cityName": "Everett",
            "name": "[PAE] - Snohomish County - Paine Field - PAE - Everett - United States",
            "searchName": "PAE-Snohomish County-Snohomish County - Paine Field-Snohomish Cty Havalimani-Everett-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OZR",
            "countryCode": "US",
            "cityCode": "OZR",
            "cityName": "Ozark",
            "name": "[OZR] - Cairns AAF - OZR - Ozark - United States",
            "searchName": "OZR-Cairns AAF-Ozark-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "HEY",
            "countryCode": "US",
            "cityCode": "OZR",
            "cityName": "Ozark",
            "name": "[HEY] - Hanchey Army Heliport - OZR - Ozark - United States",
            "searchName": "HEY-OZR-Hanchey Army Heliport-Ozark-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LOR",
            "countryCode": "US",
            "cityCode": "OZR",
            "cityName": "Ozark",
            "name": "[LOR] - Lowe Army Heliport - OZR - Ozark - United States",
            "searchName": "LOR-OZR-Lowe Army Heliport-Ozark-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ONH",
            "countryCode": "US",
            "cityCode": "ONH",
            "cityName": "Oneonta",
            "name": "[ONH] - Municipal - ONH - Oneonta - United States",
            "searchName": "ONH-Municipal-Oneonta Municpal Havalimani-Oneonta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ONT",
            "countryCode": "US",
            "cityCode": "ONT",
            "cityName": "Ontario",
            "name": "[ONT] - Ontario International - ONT - Ontario - United States",
            "searchName": "ONT-Ontario Intl-Ontario International-Ontario-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JIO",
            "countryCode": "US",
            "cityCode": "ONT",
            "cityName": "Ontario",
            "name": "[JIO] - International Heliport - ONT - Ontario - United States",
            "searchName": "JIO-ONT-International Heliport-Ontario-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ONY",
            "countryCode": "US",
            "cityCode": "ONY",
            "cityName": "Olney",
            "name": "[ONY] - Olney - ONY - Olney - United States",
            "searchName": "ONY-Olney-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OOA",
            "countryCode": "US",
            "cityCode": "OOA",
            "cityName": "Oskaloosa",
            "name": "[OOA] - Municipal - OOA - Oskaloosa - United States",
            "searchName": "OOA-Municipal-Oskaloosa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OOK",
            "countryCode": "US",
            "cityCode": "OOK",
            "cityName": "Toksook Bay",
            "name": "[OOK] - Toksook Bay - OOK - Toksook Bay - United States",
            "searchName": "OOK-Toksook Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "OPL",
            "countryCode": "US",
            "cityCode": "OPL",
            "cityName": "Opelousas",
            "name": "[OPL] - St Landry Parish - OPL - Opelousas - United States",
            "searchName": "OPL-St Landry Parish-Opelousas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PDT",
            "countryCode": "US",
            "cityCode": "PDT",
            "cityName": "Pendleton",
            "name": "[PDT] - Pendleton - PDT - Pendleton - United States",
            "searchName": "PDT-Pendleton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PEQ",
            "countryCode": "US",
            "cityCode": "PEQ",
            "cityName": "Pecos City",
            "name": "[PEQ] - Pecos City - PEQ - Pecos City - United States",
            "searchName": "PEQ-Pecos City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PFA",
            "countryCode": "US",
            "cityCode": "PFA",
            "cityName": "Paf Warren",
            "name": "[PFA] - Paf Warren - PFA - Paf Warren - United States",
            "searchName": "PFA-Paf Warren-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PFC",
            "countryCode": "US",
            "cityCode": "PFC",
            "cityName": "Pacific City",
            "name": "[PFC] - Pacific City State - PFC - Pacific City - United States",
            "searchName": "PFC-Regierungsflughafen-Pacific City State-Pacific City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PFD",
            "countryCode": "US",
            "cityCode": "PFD",
            "cityName": "Port Frederick",
            "name": "[PFD] - Port Frederick - PFD - Port Frederick - United States",
            "searchName": "PFD-Port Frederick-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RTN",
            "countryCode": "US",
            "cityCode": "RTN",
            "cityName": "Raton",
            "name": "[RTN] - Crews Field - RTN - Raton - United States",
            "searchName": "RTN-Crews Field-Crews Fld Havalimani-Raton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RUT",
            "countryCode": "US",
            "cityCode": "RUT",
            "cityName": "Rutland",
            "name": "[RUT] - Rutland - RUT - Rutland - United States",
            "searchName": "RUT-Rutland-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RUI",
            "countryCode": "US",
            "cityCode": "RUI",
            "cityName": "Ruidoso",
            "name": "[RUI] - Ruidoso Sierra Blanca Regional Airport - RUI - Ruidoso - United States",
            "searchName": "RUI-Ruidoso Sierra Blanca Regional Airport-Ruidoso  Havalimani-Ruidoso-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RVR",
            "countryCode": "US",
            "cityCode": "RVR",
            "cityName": "Green River",
            "name": "[RVR] - Green River - RVR - Green River - United States",
            "searchName": "RVR-Green River-Green River Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RWB",
            "countryCode": "US",
            "cityCode": "RWB",
            "cityName": "Rowan Bay",
            "name": "[RWB] - Rowan Bay - RWB - Rowan Bay - United States",
            "searchName": "RWB-Rowan Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RWF",
            "countryCode": "US",
            "cityCode": "RWF",
            "cityName": "Redwood Falls",
            "name": "[RWF] - Redwood Falls Municipal - RWF - Redwood Falls - United States",
            "searchName": "RWF-Redwood Falls Municipal-Redwood Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RWI",
            "countryCode": "US",
            "cityCode": "RWI",
            "cityName": "Rocky Mount",
            "name": "[RWI] - Rocky Mount-wilson - RWI - Rocky Mount - United States",
            "searchName": "RWI-Rocky Mount-wilson-Wilson Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RWL",
            "countryCode": "US",
            "cityCode": "RWL",
            "cityName": "Rawlins",
            "name": "[RWL] - Rawlins - RWL - Rawlins - United States",
            "searchName": "RWL-Rawlins-Rawlins Belediyesi Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RXE",
            "countryCode": "US",
            "cityCode": "RXE",
            "cityName": "Rexburg",
            "name": "[RXE] - Madison County - RXE - Rexburg - United States",
            "searchName": "RXE-Madison County-Rexburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SAC",
            "countryCode": "US",
            "cityCode": "SAC",
            "cityName": "Sacramento",
            "name": "[SAC] - Executive - SAC - Sacramento - United States",
            "searchName": "SAC-Executive Airport-Executive-Sacramento-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SCK",
            "countryCode": "US",
            "cityCode": "SAC",
            "cityName": "Sacramento",
            "name": "[SCK] - Stockton Metropolitan Airport - SAC - Sacramento - United States",
            "searchName": "SCK-SAC-Stockton-Stockton Metropolitan Airport-Sacramento-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SMF",
            "countryCode": "US",
            "cityCode": "SAC",
            "cityName": "Sacramento",
            "name": "[SMF] - Sacramento International - SAC - Sacramento - United States",
            "searchName": "SMF-SAC-Sacramento-Sacramento International-Sacramento Intl-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MHR",
            "countryCode": "US",
            "cityCode": "SAC",
            "cityName": "Sacramento",
            "name": "[MHR] - Sacramento Mather Airport - SAC - Sacramento - United States",
            "searchName": "MHR-SAC-Sacramento Mather Airport-Sacramento Mather Havalimani-Sacramento-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MCC",
            "countryCode": "US",
            "cityCode": "SAC",
            "cityName": "Sacramento",
            "name": "[MCC] - Mcclellan AFB - SAC - Sacramento - United States",
            "searchName": "MCC-SAC-Mcclellan AFB-Sacramento-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SAD",
            "countryCode": "US",
            "cityCode": "SAD",
            "cityName": "Safford",
            "name": "[SAD] - Safford - SAD - Safford - United States",
            "searchName": "SAD-Safford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SAA",
            "countryCode": "US",
            "cityCode": "SAA",
            "cityName": "Saratoga",
            "name": "[SAA] - Shively Field - SAA - Saratoga - United States",
            "searchName": "SAA-Shively Field-Lar Shively Havalimani-Saratoga-Laramie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SAN",
            "countryCode": "US",
            "cityCode": "SAN",
            "cityName": "San Diego",
            "name": "[SAN] - San Diego International Airport - SAN - San Diego - United States",
            "searchName": "SAN-San Diego Intl-San Diego International Airport-San Diego-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SDM",
            "countryCode": "US",
            "cityCode": "SAN",
            "cityName": "San Diego",
            "name": "[SDM] - Brown Field Municipalcipal - SAN - San Diego - United States",
            "searchName": "SDM-SAN-Brown Field Municipalcipal-Brown Field Belediyesi Havalimani-San Diego-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SEE",
            "countryCode": "US",
            "cityCode": "SAN",
            "cityName": "San Diego",
            "name": "[SEE] - Gillespie Field - SAN - San Diego - United States",
            "searchName": "SEE-SAN-Gillespie Field-Gillespie Field Havalimani-San Diego-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NZY",
            "countryCode": "US",
            "cityCode": "SAN",
            "cityName": "San Diego",
            "name": "[NZY] - North Island NAS - SAN - San Diego - United States",
            "searchName": "NZY-SAN-North Island NAS-San Diego-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NKX",
            "countryCode": "US",
            "cityCode": "SAN",
            "cityName": "San Diego",
            "name": "[NKX] - Miramar MS - SAN - San Diego - United States",
            "searchName": "NKX-SAN-Miramar MS-San Diego-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MYF",
            "countryCode": "US",
            "cityCode": "SAN",
            "cityName": "San Diego",
            "name": "[MYF] - Montgomery Field - SAN - San Diego - United States",
            "searchName": "MYF-SAN-Montgomery Field-Montgomery Field Havalimani-San Diego-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CLD",
            "countryCode": "US",
            "cityCode": "SAN",
            "cityName": "San Diego",
            "name": "[CLD] - Mc Clellan-Palomar Airport - SAN - San Diego - United States",
            "searchName": "CLD-SAN-Carlsbad Kalifornien-Mc Clellan-Palomar Airport-Carlsbad-Palomar-San Diego-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ROL",
            "countryCode": "US",
            "cityCode": "ROL",
            "cityName": "Roosevelt",
            "name": "[ROL] - Roosevelt - ROL - Roosevelt - United States",
            "searchName": "ROL-Roosevelt-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RNT",
            "countryCode": "US",
            "cityCode": "RNT",
            "cityName": "Renton",
            "name": "[RNT] - Renton - RNT - Renton - United States",
            "searchName": "RNT-Renton-Renton Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RNZ",
            "countryCode": "US",
            "cityCode": "RNZ",
            "cityName": "Rensselaer",
            "name": "[RNZ] - Rensselaer - RNZ - Rensselaer - United States",
            "searchName": "RNZ-Rensselaer-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ROA",
            "countryCode": "US",
            "cityCode": "ROA",
            "cityName": "Roanoke",
            "name": "[ROA] - Roanoke Regional Airport - ROA - Roanoke - United States",
            "searchName": "ROA-Roanoke-Roanoke Regional Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ROC",
            "countryCode": "US",
            "cityCode": "ROC",
            "cityName": "Rochester",
            "name": "[ROC] - Greater Rochester International - ROC - Rochester - United States",
            "searchName": "ROC-Rochester NY-Greater Rochester International-Monroe County-Rochester-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZTE",
            "countryCode": "US",
            "cityCode": "ROC",
            "cityName": "Rochester",
            "name": "[ZTE] - Rochester NY Railway - ROC - Rochester - United States",
            "searchName": "ZTE-ROC-Rochester NY Railway-Rochester-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ROW",
            "countryCode": "US",
            "cityCode": "ROW",
            "cityName": "Roswell",
            "name": "[ROW] - Industrial - ROW - Roswell - United States",
            "searchName": "ROW-Roswell-Industrial-Roswell Intl-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ROX",
            "countryCode": "US",
            "cityCode": "ROX",
            "cityName": "Roseau",
            "name": "[ROX] - Roseau Municipal Airport - ROX - Roseau - United States",
            "searchName": "ROX-Roseau Municipal Airport-Roseau-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ROG",
            "countryCode": "US",
            "cityCode": "ROG",
            "cityName": "Rogers",
            "name": "[ROG] - Rogers - ROG - Rogers - United States",
            "searchName": "ROG-Rogers-Rogers Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RPX",
            "countryCode": "US",
            "cityCode": "RPX",
            "cityName": "Roundup",
            "name": "[RPX] - Roundup - RPX - Roundup - United States",
            "searchName": "RPX-Roundup-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RST",
            "countryCode": "US",
            "cityCode": "RST",
            "cityName": "Rochester",
            "name": "[RST] - International - RST - Rochester - United States",
            "searchName": "RST-Rochester Minnesota-International-Rochester-Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JRC",
            "countryCode": "US",
            "cityCode": "RST",
            "cityName": "Rochester",
            "name": "[JRC] - Municipalcipal Heliport - RST - Rochester - United States",
            "searchName": "JRC-RST-Municipalcipal Heliport-Rochester-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RSJ",
            "countryCode": "US",
            "cityCode": "RSJ",
            "cityName": "Rosario",
            "name": "[RSJ] - Rosario SPB - RSJ - Rosario - United States",
            "searchName": "RSJ-Rosario SPB-Rosario-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RSL",
            "countryCode": "US",
            "cityCode": "RSL",
            "cityName": "Russell",
            "name": "[RSL] - Russell - RSL - Russell - United States",
            "searchName": "RSL-Russell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RSN",
            "countryCode": "US",
            "cityCode": "RSN",
            "cityName": "Ruston",
            "name": "[RSN] - Ruston - RSN - Ruston - United States",
            "searchName": "RSN-Ruston-Ruston Yerel Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RSP",
            "countryCode": "US",
            "cityCode": "RSP",
            "cityName": "Raspberry Strait",
            "name": "[RSP] - Raspberry Strait - RSP - Raspberry Strait - United States",
            "searchName": "RSP-Raspberry Strait-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RSX",
            "countryCode": "US",
            "cityCode": "RSX",
            "cityName": "Rouses Point",
            "name": "[RSX] - Rouses Point - RSX - Rouses Point - United States",
            "searchName": "RSX-Rouses Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RTL",
            "countryCode": "US",
            "cityCode": "RTL",
            "cityName": "Spirit Lake",
            "name": "[RTL] - Spirit Lake - RTL - Spirit Lake - United States",
            "searchName": "RTL-Spirit Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RTD",
            "countryCode": "US",
            "cityCode": "RTD",
            "cityName": "Rotunda",
            "name": "[RTD] - Rotunda - RTD - Rotunda - United States",
            "searchName": "RTD-Rotunda-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RTE",
            "countryCode": "US",
            "cityCode": "RTE",
            "cityName": "Marguerite Bay",
            "name": "[RTE] - Marguerite Bay - RTE - Marguerite Bay - United States",
            "searchName": "RTE-Marguerite Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RRL",
            "countryCode": "US",
            "cityCode": "RRL",
            "cityName": "Merrill",
            "name": "[RRL] - Merrill Municipal Airport - RRL - Merrill - United States",
            "searchName": "RRL-Merrill Municipal Airport-Merrill-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RRT",
            "countryCode": "US",
            "cityCode": "RRT",
            "cityName": "Warroad",
            "name": "[RRT] - Warroad - RRT - Warroad - United States",
            "searchName": "RRT-Warroad-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RSH",
            "countryCode": "US",
            "cityCode": "RSH",
            "cityName": "Russian Mission",
            "name": "[RSH] - Russian SPB - RSH - Russian Mission - United States",
            "searchName": "RSH-Russian SPB-Russian Mission-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RNG",
            "countryCode": "US",
            "cityCode": "RNG",
            "cityName": "Rangely",
            "name": "[RNG] - Rangely - RNG - Rangely - United States",
            "searchName": "RNG-Rangely-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RNH",
            "countryCode": "US",
            "cityCode": "RNH",
            "cityName": "New Richmond",
            "name": "[RNH] - New Richmond Municipal Airport - RNH - New Richmond - United States",
            "searchName": "RNH-New Richmond Municipal Airport-New Richmond Belediyesi Havalimani-New Richmond-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RNO",
            "countryCode": "US",
            "cityCode": "RNO",
            "cityName": "Reno",
            "name": "[RNO] - Reno-Tahoe International - RNO - Reno - United States",
            "searchName": "RNO-Reno-Tahoe International-Reno Tahoe-Reno Nevada-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RNC",
            "countryCode": "US",
            "cityCode": "RNC",
            "cityName": "Mcminnville",
            "name": "[RNC] - Warren County - RNC - Mcminnville - United States",
            "searchName": "RNC-Warren County-Mcminnville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RMY",
            "countryCode": "US",
            "cityCode": "RMY",
            "cityName": "Mariposa",
            "name": "[RMY] - Mariposa-Yosemite - RMY - Mariposa - United States",
            "searchName": "RMY-Mariposa-Yosemite-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RMP",
            "countryCode": "US",
            "cityCode": "RMP",
            "cityName": "Rampart",
            "name": "[RMP] - Rampart - RMP - Rampart - United States",
            "searchName": "RMP-Rampart-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RME",
            "countryCode": "US",
            "cityCode": "RME",
            "cityName": "Rome",
            "name": "[RME] - Griffiss AFB - RME - Rome - United States",
            "searchName": "RME-Luftwaffenstützpunkt Griffiss-Griffiss AFB-Griffiss Air Force Base Havalimani-Rome-Roma-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RMG",
            "countryCode": "US",
            "cityCode": "RMG",
            "cityName": "Rome",
            "name": "[RMG] - Richard B Russell - RMG - Rome - United States",
            "searchName": "RMG-Richard B Russell-Richard B Russell Havalimani-Rome-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZRF",
            "countryCode": "US",
            "cityCode": "RMC",
            "cityName": "Rockford",
            "name": "[ZRF] - Park & Ride Bus Svc - RMC - Rockford - United States",
            "searchName": "ZRF-RMC-Park & Ride Bus Svc-Rockford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZRK",
            "countryCode": "US",
            "cityCode": "RMC",
            "cityName": "Rockford",
            "name": "[ZRK] - VanGalder Bus Termina - RMC - Rockford - United States",
            "searchName": "ZRK-RMC-VanGalder Bus Termina-Rockford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RLU",
            "countryCode": "US",
            "cityCode": "RLU",
            "cityName": "Bornite",
            "name": "[RLU] - Bornite Upper - RLU - Bornite - United States",
            "searchName": "RLU-Bornite Upper-Bornite-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RIL",
            "countryCode": "US",
            "cityCode": "RIL",
            "cityName": "Rifle",
            "name": "[RIL] - Garfield County - RIL - Rifle - United States",
            "searchName": "RIL-Garfield County-Garfield Cty Havalimani-Rifle-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RIW",
            "countryCode": "US",
            "cityCode": "RIW",
            "cityName": "Riverton",
            "name": "[RIW] - Riverton Regional - RIW - Riverton - United States",
            "searchName": "RIW-Riverton-Riverton Regional-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RKW",
            "countryCode": "US",
            "cityCode": "RKW",
            "cityName": "Rockwood",
            "name": "[RKW] - Rockwood Municipal Airport - RKW - Rockwood - United States",
            "searchName": "RKW-Rockwood Municipal Airport-Rockwood  Havalimani-Rockwood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RLA",
            "countryCode": "US",
            "cityCode": "RLA",
            "cityName": "Rolla",
            "name": "[RLA] - National - RLA - Rolla - United States",
            "searchName": "RLA-National-National Havalimani-Rolla-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RLD",
            "countryCode": "US",
            "cityCode": "RLD",
            "cityName": "Richland",
            "name": "[RLD] - Richland - RLD - Richland - United States",
            "searchName": "RLD-Richland-Richland Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RKC",
            "countryCode": "US",
            "cityCode": "RKC",
            "cityName": "Yreka",
            "name": "[RKC] - Yreka - RKC - Yreka - United States",
            "searchName": "RKC-Yreka-Yreka Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RKD",
            "countryCode": "US",
            "cityCode": "RKD",
            "cityName": "Rockland",
            "name": "[RKD] - Knox County Regional - RKD - Rockland - United States",
            "searchName": "RKD-Rockland-Knox County Regional-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RKH",
            "countryCode": "US",
            "cityCode": "RKH",
            "cityName": "Rock Hill",
            "name": "[RKH] - Rock Hill - RKH - Rock Hill - United States",
            "searchName": "RKH-Rock Hill-Rock Hill York County Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RKP",
            "countryCode": "US",
            "cityCode": "RKP",
            "cityName": "Rockport",
            "name": "[RKP] - Aransas County - RKP - Rockport - United States",
            "searchName": "RKP-Aransas County-Aransas County Havalimani-Rockport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RKR",
            "countryCode": "US",
            "cityCode": "RKR",
            "cityName": "Poteau",
            "name": "[RKR] - Robert S Kerr - RKR - Poteau - United States",
            "searchName": "RKR-Robert S Kerr-Poteau-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RKS",
            "countryCode": "US",
            "cityCode": "RKS",
            "cityName": "Rock Springs",
            "name": "[RKS] - Sweetwater County - RKS - Rock Springs - United States",
            "searchName": "RKS-Rock Springs-Sweetwater County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AGC",
            "countryCode": "US",
            "cityCode": "PIT",
            "cityName": "Pittsburgh",
            "name": "[AGC] - Allegheny County - PIT - Pittsburgh - United States",
            "searchName": "AGC-PIT-Allegheny County-Allegheny Cty Havalimani-Pittsburgh-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CVA",
            "countryCode": "US",
            "cityCode": "PIT",
            "cityName": "Pittsburgh",
            "name": "[CVA] - Civic Ar Heli - PIT - Pittsburgh - United States",
            "searchName": "CVA-PIT-Civic Ar Heli-Pittsburgh-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PKD",
            "countryCode": "US",
            "cityCode": "PKD",
            "cityName": "Park Rapids",
            "name": "[PKD] - Park Rapids - PKD - Park Rapids - United States",
            "searchName": "PKD-Park Rapids-Park Rapids  Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PKF",
            "countryCode": "US",
            "cityCode": "PKF",
            "cityName": "Park Falls",
            "name": "[PKF] - Park Falls - PKF - Park Falls - United States",
            "searchName": "PKF-Park Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PGR",
            "countryCode": "US",
            "cityCode": "PGR",
            "cityName": "Paragould",
            "name": "[PGR] - Paragould Kirk Field - PGR - Paragould - United States",
            "searchName": "PGR-Paragould Kirk Field-Paragould-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PGS",
            "countryCode": "US",
            "cityCode": "PGS",
            "cityName": "Peach Springs",
            "name": "[PGS] - Peach Springs - PGS - Peach Springs - United States",
            "searchName": "PGS-Peach Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PGO",
            "countryCode": "US",
            "cityCode": "PGO",
            "cityName": "Pagosa Springs",
            "name": "[PGO] - Stevens Field - PGO - Pagosa Springs - United States",
            "searchName": "PGO-Landeplatz Stevens-Stevens Field-Stevens Field Havalimani-Pagosa Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PGV",
            "countryCode": "US",
            "cityCode": "PGV",
            "cityName": "Greenville",
            "name": "[PGV] - Pitt-Greenville - PGV - Greenville - United States",
            "searchName": "PGV-Greenville-Pitt-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PHD",
            "countryCode": "US",
            "cityCode": "PHD",
            "cityName": "New Philadelphia",
            "name": "[PHD] - Harry Clever Field - PHD - New Philadelphia - United States",
            "searchName": "PHD-Harry Clever Field-New Philadelphia-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PHF",
            "countryCode": "US",
            "cityCode": "PHF",
            "cityName": "Newport News",
            "name": "[PHF] - Newport News/williamsb - PHF - Newport News - United States",
            "searchName": "PHF-Newport News-Newport News/williamsb- Intl Airport-Newport News/Williamsburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZWW",
            "countryCode": "US",
            "cityCode": "PHF",
            "cityName": "Newport News",
            "name": "[ZWW] - Newport News Railway - PHF - Newport News - United States",
            "searchName": "ZWW-PHF-Newport News Railway-Newport News/Williamsburg-Newport News-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZWB",
            "countryCode": "US",
            "cityCode": "PHF",
            "cityName": "Newport News",
            "name": "[ZWB] - Williamsburg Railway - PHF - Newport News - United States",
            "searchName": "ZWB-PHF-Williamsburg Railway-Newport News/Williamsburg-Newport News-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "LFI",
            "countryCode": "US",
            "cityCode": "PHF",
            "cityName": "Newport News",
            "name": "[LFI] - Langley AFB - PHF - Newport News - United States",
            "searchName": "LFI-PHF-Langley AFB-Newport News/Williamsburg-Newport News-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PGC",
            "countryCode": "US",
            "cityCode": "PGC",
            "cityName": "Petersburg",
            "name": "[PGC] - Grant County - PGC - Petersburg - United States",
            "searchName": "PGC-Grant County-Petersburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PGD",
            "countryCode": "US",
            "cityCode": "PGD",
            "cityName": "Punta Gorda",
            "name": "[PGD] - Charlotte County - PGD - Punta Gorda - United States",
            "searchName": "PGD-Charlotte County-Charlotte County Havalimani-Punta Gorda-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PGA",
            "countryCode": "US",
            "cityCode": "PGA",
            "cityName": "Page",
            "name": "[PGA] - Page - PGA - Page - United States",
            "searchName": "PGA-Page-Page Arizona-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PAM",
            "countryCode": "US",
            "cityCode": "PFN",
            "cityName": "Panama City",
            "name": "[PAM] - Tyndall AFB - PFN - Panama City - United States",
            "searchName": "PAM-PFN-Tyndall AFB-Panama City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ECP",
            "countryCode": "US",
            "cityCode": "PFN",
            "cityName": "Panama City",
            "name": "[ECP] - NW Florida Beaches Int - PFN - Panama City - United States",
            "searchName": "ECP-PFN-Northwest Florida Beaches International Airport-NW Florida Beaches Int-NW Florida Beaches-Panama City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PGL",
            "countryCode": "US",
            "cityCode": "PGL",
            "cityName": "Pascagoula",
            "name": "[PGL] - Jackson County - PGL - Pascagoula - United States",
            "searchName": "PGL-Jackson County-Jackson Cnty Havalimani-Pascagoula-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PGM",
            "countryCode": "US",
            "cityCode": "PGM",
            "cityName": "Port Graham",
            "name": "[PGM] - Port Graham - PGM - Port Graham - United States",
            "searchName": "PGM-Port Graham-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PEC",
            "countryCode": "US",
            "cityCode": "PEC",
            "cityName": "Pelican",
            "name": "[PEC] - Pelican SPB - PEC - Pelican - United States",
            "searchName": "PEC-Pelican SPB-Pelican-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RDV",
            "countryCode": "US",
            "cityCode": "RDV",
            "cityName": "Red Devil",
            "name": "[RDV] - Red Devil - RDV - Red Devil - United States",
            "searchName": "RDV-Red Devil-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RDG",
            "countryCode": "US",
            "cityCode": "RDG",
            "cityName": "Reading",
            "name": "[RDG] - Municipalcipal/Spaatz Field - RDG - Reading - United States",
            "searchName": "RDG-Regionaler Flughafen-Municipalcipal/Spaatz Field-Reading-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RDM",
            "countryCode": "US",
            "cityCode": "RDM",
            "cityName": "Redmond",
            "name": "[RDM] - Roberts Field - RDM - Redmond - United States",
            "searchName": "RDM-Redmond-Roberts Field-Bend/Redmond-Bend-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RDD",
            "countryCode": "US",
            "cityCode": "RDD",
            "cityName": "Redding",
            "name": "[RDD] - Redding - RDD - Redding - United States",
            "searchName": "RDD-Redding-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BZF",
            "countryCode": "US",
            "cityCode": "RDD",
            "cityName": "Redding",
            "name": "[BZF] - Benton Field - RDD - Redding - United States",
            "searchName": "BZF-RDD-Benton Field-Redding-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RDR",
            "countryCode": "US",
            "cityCode": "RDR",
            "cityName": "Red River",
            "name": "[RDR] - Grand Forks AFB - RDR - Red River - United States",
            "searchName": "RDR-Luftwaffenstützpunkt Grand Force-Grand Forks AFB-Red River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RED",
            "countryCode": "US",
            "cityCode": "RED",
            "cityName": "Reedsville",
            "name": "[RED] - Mifflin County - RED - Reedsville - United States",
            "searchName": "RED-Mifflin County-Reedsville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "REO",
            "countryCode": "US",
            "cityCode": "REO",
            "cityName": "Rome",
            "name": "[REO] - Rome State - REO - Rome - United States",
            "searchName": "REO-Regierungsflughafen-Rome State-Rome-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RFG",
            "countryCode": "US",
            "cityCode": "RFG",
            "cityName": "Refugio",
            "name": "[RFG] - Rooke Field - RFG - Refugio - United States",
            "searchName": "RFG-Landeplatz Rooke-Rooke Field-Refugio-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "VDU",
            "countryCode": "US",
            "cityCode": "RFG",
            "cityName": "Refugio",
            "name": "[VDU] - Tom OConnor Oilfield - RFG - Refugio - United States",
            "searchName": "VDU-RFG-Tom O Connor Oilfield-Tom OConnor Oilfield-Refugio-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RFK",
            "countryCode": "US",
            "cityCode": "RFK",
            "cityName": "Anguilla",
            "name": "[RFK] - Rollang Field - RFK - Anguilla - United States",
            "searchName": "RFK-Landeplatz Rollang-Rollang Field-Anguilla-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RHI",
            "countryCode": "US",
            "cityCode": "RHI",
            "cityName": "Rhinelander",
            "name": "[RHI] - Oneida County - RHI - Rhinelander - United States",
            "searchName": "RHI-Rhinelander-Oneida County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RGR",
            "countryCode": "US",
            "cityCode": "RGR",
            "cityName": "Ranger",
            "name": "[RGR] - Ranger Municipalcipal - RGR - Ranger - United States",
            "searchName": "RGR-Ranger Municipalcipal-Ranger  Havalimani-Ranger-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RIC",
            "countryCode": "US",
            "cityCode": "RIC",
            "cityName": "Richmond",
            "name": "[RIC] - Richmond International Airport - RIC - Richmond - United States",
            "searchName": "RIC-Richmond USA-Richmond International Airport-Byrd International-International (Byrd Field)-Richmond-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZRD",
            "countryCode": "US",
            "cityCode": "RIC",
            "cityName": "Richmond",
            "name": "[ZRD] - Richmond VA Railway - RIC - Richmond - United States",
            "searchName": "ZRD-RIC-Richmond VA Railway-Richmond-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RID",
            "countryCode": "US",
            "cityCode": "RID",
            "cityName": "Richmond",
            "name": "[RID] - Richmond Municipalcipal Airport - RID - Richmond - United States",
            "searchName": "RID-Richmond Municipalcipal Airport-Richmond-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RIE",
            "countryCode": "US",
            "cityCode": "RIE",
            "cityName": "Rice Lake",
            "name": "[RIE] - Rice Lake - RIE - Rice Lake - United States",
            "searchName": "RIE-Rice Lake-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RIF",
            "countryCode": "US",
            "cityCode": "RIF",
            "cityName": "Richfield",
            "name": "[RIF] - Reynolds - RIF - Richfield - United States",
            "searchName": "RIF-Reynolds-Reynolds  Havalimani-Richfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PYL",
            "countryCode": "US",
            "cityCode": "PYL",
            "cityName": "Perry Island",
            "name": "[PYL] - Perry SPB - PYL - Perry Island - United States",
            "searchName": "PYL-Perry SPB-Perry Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PYM",
            "countryCode": "US",
            "cityCode": "PYM",
            "cityName": "Plymouth",
            "name": "[PYM] - Plymouth - PYM - Plymouth - United States",
            "searchName": "PYM-Plymouth-Plymouth Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PYS",
            "countryCode": "US",
            "cityCode": "PYS",
            "cityName": "Paradise",
            "name": "[PYS] - Skypark - PYS - Paradise - United States",
            "searchName": "PYS-Skypark-Paradise-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "QCE",
            "countryCode": "US",
            "cityCode": "QCE",
            "cityName": "Copper Mountain",
            "name": "[QCE] - Bus Station - QCE - Copper Mountain - United States",
            "searchName": "QCE-Bus Station-Copper Mountain Van Service Havalimani-Copper Mountain-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PVU",
            "countryCode": "US",
            "cityCode": "PVU",
            "cityName": "Provo",
            "name": "[PVU] - Provo - PVU - Provo - United States",
            "searchName": "PVU-Provo-Provo Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PVW",
            "countryCode": "US",
            "cityCode": "PVW",
            "cityName": "Plainview",
            "name": "[PVW] - Hale County - PVW - Plainview - United States",
            "searchName": "PVW-Hale County-Hale County Havalimani-Plainview-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PVY",
            "countryCode": "US",
            "cityCode": "PVY",
            "cityName": "Pope Vanoy",
            "name": "[PVY] - Pope Vanoy - PVY - Pope Vanoy - United States",
            "searchName": "PVY-Pope Vanoy-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PWD",
            "countryCode": "US",
            "cityCode": "PWD",
            "cityName": "Plentywood",
            "name": "[PWD] - Sherwood - PWD - Plentywood - United States",
            "searchName": "PWD-Sherwood-Sher-Wood Airport-Plentywood-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PVF",
            "countryCode": "US",
            "cityCode": "PVF",
            "cityName": "Placerville",
            "name": "[PVF] - Placerville - PVF - Placerville - United States",
            "searchName": "PVF-Placerville-Placerville Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PWR",
            "countryCode": "US",
            "cityCode": "PWR",
            "cityName": "Port Walter",
            "name": "[PWR] - Port Walter - PWR - Port Walter - United States",
            "searchName": "PWR-Port Walter-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PWT",
            "countryCode": "US",
            "cityCode": "PWT",
            "cityName": "Bremerton",
            "name": "[PWT] - Bremerton - PWT - Bremerton - United States",
            "searchName": "PWT-Nationaler Flughafen-Bremerton-Bremerton Belediyesi Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SUS",
            "countryCode": "US",
            "cityCode": "STL",
            "cityName": "St Louis",
            "name": "[SUS] - Spirit Of St Louis - STL - St Louis - United States",
            "searchName": "SUS-STL-Spirit Of St Louis-Spirit Of St Louis Havalimani-St Louis-Saint Louis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZSV",
            "countryCode": "US",
            "cityCode": "STL",
            "cityName": "St Louis",
            "name": "[ZSV] - St Louis Railway Station - STL - St Louis - United States",
            "searchName": "ZSV-STL-St Louis Railway Station-St Louis-Saint Louis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CPS",
            "countryCode": "US",
            "cityCode": "STL",
            "cityName": "St Louis",
            "name": "[CPS] - St. Louis Downtown Airport - STL - St Louis - United States",
            "searchName": "CPS-STL-St. Louis Downtown Airport-East St Louis Havalimani-St Louis-Saint Louis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SUU",
            "countryCode": "US",
            "cityCode": "SUU",
            "cityName": "Fairfield",
            "name": "[SUU] - Travis AFB - SUU - Fairfield - United States",
            "searchName": "SUU-Travis AFB-Travis AFB Havalimani-Fairfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SUW",
            "countryCode": "US",
            "cityCode": "SUW",
            "cityName": "Superior",
            "name": "[SUW] - Richard I Bong Airport - SUW - Superior - United States",
            "searchName": "SUW-Richard I Bong-Richard I Bong Airport-Richard I Bong Havalimani-Duluth-Superior-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SUX",
            "countryCode": "US",
            "cityCode": "SUX",
            "cityName": "Sioux City",
            "name": "[SUX] - Sioux Gateway - SUX - Sioux City - United States",
            "searchName": "SUX-Sioux City-Sioux Gateway-Sioux City Iowa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SVA",
            "countryCode": "US",
            "cityCode": "SVA",
            "cityName": "Savoonga",
            "name": "[SVA] - Savoonga - SVA - Savoonga - United States",
            "searchName": "SVA-Savoonga-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SVH",
            "countryCode": "US",
            "cityCode": "SVH",
            "cityName": "Statesville",
            "name": "[SVH] - Statesville Municipal - SVH - Statesville - United States",
            "searchName": "SVH-Statesville Municipal-Statesville  Havalimani-Statesville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SVC",
            "countryCode": "US",
            "cityCode": "SVC",
            "cityName": "Silver City",
            "name": "[SVC] - Grant County - SVC - Silver City - United States",
            "searchName": "SVC-Silver City-Grant County-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SVE",
            "countryCode": "US",
            "cityCode": "SVE",
            "cityName": "Susanville",
            "name": "[SVE] - Susanville - SVE - Susanville - United States",
            "searchName": "SVE-Susanville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SUN",
            "countryCode": "US",
            "cityCode": "SUN",
            "cityName": "Sun Valley",
            "name": "[SUN] - Sun Valley - SUN - Sun Valley - United States",
            "searchName": "SUN-Sun Valley-Friedman-Hailey/Sun Valley-Hailey-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "XUN",
            "countryCode": "US",
            "cityCode": "SUN",
            "cityName": "Sun Valley",
            "name": "[XUN] - Hailey Bus Station - SUN - Sun Valley - United States",
            "searchName": "XUN-SUN-Hailey Bus Station-Hailey/Sun Valley-Sun Valley-Hailey-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SUO",
            "countryCode": "US",
            "cityCode": "SUO",
            "cityName": "Sun River",
            "name": "[SUO] - Sun River - SUO - Sun River - United States",
            "searchName": "SUO-Sun River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SUC",
            "countryCode": "US",
            "cityCode": "SUC",
            "cityName": "Sundance",
            "name": "[SUC] - Schloredt - SUC - Sundance - United States",
            "searchName": "SUC-Schloredt-Sundance-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SUD",
            "countryCode": "US",
            "cityCode": "SUD",
            "cityName": "Stroud",
            "name": "[SUD] - Stroud - SUD - Stroud - United States",
            "searchName": "SUD-Stroud-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SUE",
            "countryCode": "US",
            "cityCode": "SUE",
            "cityName": "Sturgeon Bay",
            "name": "[SUE] - Door County - SUE - Sturgeon Bay - United States",
            "searchName": "SUE-Door County-Door Country Havalimani-Sturgeon Bay-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SUA",
            "countryCode": "US",
            "cityCode": "SUA",
            "cityName": "Stuart",
            "name": "[SUA] - Witham Field - SUA - Stuart - United States",
            "searchName": "SUA-Landeplatz Witham-Witham Field-Witham Field Havalimani-Stuart-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "STE",
            "countryCode": "US",
            "cityCode": "STE",
            "cityName": "Stevens Point",
            "name": "[STE] - Stevens Point - STE - Stevens Point - United States",
            "searchName": "STE-Stevens Point-Stevens Point Belediyesi Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SWD",
            "countryCode": "US",
            "cityCode": "SWD",
            "cityName": "Seward",
            "name": "[SWD] - Seward - SWD - Seward - United States",
            "searchName": "SWD-Seward-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SVW",
            "countryCode": "US",
            "cityCode": "SVW",
            "cityName": "Sparrevohn",
            "name": "[SVW] - Sparrevohn AFS - SVW - Sparrevohn - United States",
            "searchName": "SVW-Luftwaffenstützpunkt-Sparrevohn AFS-Sparrevohn-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SVS",
            "countryCode": "US",
            "cityCode": "SVS",
            "cityName": "Stevens Village",
            "name": "[SVS] - Stevens Village - SVS - Stevens Village - United States",
            "searchName": "SVS-Stevens Village-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SWW",
            "countryCode": "US",
            "cityCode": "SWW",
            "cityName": "Sweetwater",
            "name": "[SWW] - Sweetwater - SWW - Sweetwater - United States",
            "searchName": "SWW-Sweetwater-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SWO",
            "countryCode": "US",
            "cityCode": "SWO",
            "cityName": "Stillwater",
            "name": "[SWO] - Searcy Field - SWO - Stillwater - United States",
            "searchName": "SWO-Landeplatz Searcy-Searcy Field-Searcy Fld Havalimani-Stillwater-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SWF",
            "countryCode": "US",
            "cityCode": "SWF",
            "cityName": "Newburgh",
            "name": "[SWF] - Stewart - SWF - Newburgh - United States",
            "searchName": "SWF-Newburgh-Stewart-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SMU",
            "countryCode": "US",
            "cityCode": "SMU",
            "cityName": "Sheep Mountain",
            "name": "[SMU] - Sheep Mountain - SMU - Sheep Mountain - United States",
            "searchName": "SMU-Sheep Mountain-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SMN",
            "countryCode": "US",
            "cityCode": "SMN",
            "cityName": "Salmon",
            "name": "[SMN] - Salmon - SMN - Salmon - United States",
            "searchName": "SMN-Salmon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SMO",
            "countryCode": "US",
            "cityCode": "SMO",
            "cityName": "Santa Monica",
            "name": "[SMO] - Santa Monica - SMO - Santa Monica - United States",
            "searchName": "SMO-Santa Monica-Santa Monica  Havalimani-Los Angeles-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SNA",
            "countryCode": "US",
            "cityCode": "SNA",
            "cityName": "Santa Ana",
            "name": "[SNA] - John Wayne - SNA - Santa Ana - United States",
            "searchName": "SNA-Santa Ana USA-John Wayne-J. Wayne/Orange Cnty-Santa Ana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DNT",
            "countryCode": "US",
            "cityCode": "SNA",
            "cityName": "Santa Ana",
            "name": "[DNT] - Downtown Heliport - SNA - Santa Ana - United States",
            "searchName": "DNT-SNA-Downtown Heliport-Santa Ana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JOC",
            "countryCode": "US",
            "cityCode": "SNA",
            "cityName": "Santa Ana",
            "name": "[JOC] - Centerport Heliport - SNA - Santa Ana - United States",
            "searchName": "JOC-SNA-Centerport Heliport-Santa Ana-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SMX",
            "countryCode": "US",
            "cityCode": "SMX",
            "cityName": "Santa Maria",
            "name": "[SMX] - Public - SMX - Santa Maria - United States",
            "searchName": "SMX-Santa Maria-Public-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SLQ",
            "countryCode": "US",
            "cityCode": "SLQ",
            "cityName": "Sleetmute",
            "name": "[SLQ] - Sleetmute - SLQ - Sleetmute - United States",
            "searchName": "SLQ-Sleetmute-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SLR",
            "countryCode": "US",
            "cityCode": "SLR",
            "cityName": "Sulphur Springs",
            "name": "[SLR] - Sulphur Springs - SLR - Sulphur Springs - United States",
            "searchName": "SLR-Sulphur Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SLT",
            "countryCode": "US",
            "cityCode": "SLT",
            "cityName": "Salida",
            "name": "[SLT] - Salida - SLT - Salida - United States",
            "searchName": "SLT-Salida-Salida Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SME",
            "countryCode": "US",
            "cityCode": "SME",
            "cityName": "Somerset",
            "name": "[SME] - Pulaski County - SME - Somerset - United States",
            "searchName": "SME-Pulaski County-Pulaski County Havalimani-Somerset-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SMK",
            "countryCode": "US",
            "cityCode": "SMK",
            "cityName": "St Michael",
            "name": "[SMK] - St Michael - SMK - St Michael - United States",
            "searchName": "SMK-St Michael-Saint Michael-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SNP",
            "countryCode": "US",
            "cityCode": "SNP",
            "cityName": "Saint Paul Island",
            "name": "[SNP] - Saint Paul Island - SNP - Saint Paul Island - United States",
            "searchName": "SNP-Saint Paul Island--Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SNK",
            "countryCode": "US",
            "cityCode": "SNK",
            "cityName": "Snyder",
            "name": "[SNK] - Winston Field - SNK - Snyder - United States",
            "searchName": "SNK-Winston Field-Snyder-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SNL",
            "countryCode": "US",
            "cityCode": "SNL",
            "cityName": "Shawnee",
            "name": "[SNL] - Shawnee Municipal - SNL - Shawnee - United States",
            "searchName": "SNL-Shawnee Municipal-Shawnee Havalimani-Shawnee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SNY",
            "countryCode": "US",
            "cityCode": "SNY",
            "cityName": "Sidney",
            "name": "[SNY] - Sidney - SNY - Sidney - United States",
            "searchName": "SNY-Sidney-Sidney Belediyesi Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SNS",
            "countryCode": "US",
            "cityCode": "SNS",
            "cityName": "Salinas",
            "name": "[SNS] - Salinas - SNS - Salinas - United States",
            "searchName": "SNS-Salinas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SOV",
            "countryCode": "US",
            "cityCode": "SOV",
            "cityName": "Seldovia",
            "name": "[SOV] - Seldovia - SOV - Seldovia - United States",
            "searchName": "SOV-Seldovia-Seldovia Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SOW",
            "countryCode": "US",
            "cityCode": "SOW",
            "cityName": "Show Low",
            "name": "[SOW] - Show Low - SOW - Show Low - United States",
            "searchName": "SOW-Show Low-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SOP",
            "countryCode": "US",
            "cityCode": "SOP",
            "cityName": "Southern Pines",
            "name": "[SOP] - Pinehurst-S. Pines - SOP - Southern Pines - United States",
            "searchName": "SOP-Pinehurst-S. Pines-Southern Pines-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SOL",
            "countryCode": "US",
            "cityCode": "SOL",
            "cityName": "Solomon",
            "name": "[SOL] - Solomon - SOL - Solomon - United States",
            "searchName": "SOL-Solomon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SPI",
            "countryCode": "US",
            "cityCode": "SPI",
            "cityName": "Springfield",
            "name": "[SPI] - Capital - SPI - Springfield - United States",
            "searchName": "SPI-Springfield Illinois-Capital-Springfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SPQ",
            "countryCode": "US",
            "cityCode": "SPQ",
            "cityName": "San Pedro",
            "name": "[SPQ] - Catalina SPB - SPQ - San Pedro - United States",
            "searchName": "SPQ-Catalina SPB-San Pedro-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SPF",
            "countryCode": "US",
            "cityCode": "SPF",
            "cityName": "Spearfish",
            "name": "[SPF] - Black Hills - SPF - Spearfish - United States",
            "searchName": "SPF-Black Hills-Black Hills Clyde Ice Field Havalimani-Spearfish-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SPG",
            "countryCode": "US",
            "cityCode": "SPG",
            "cityName": "Saint Petersburg",
            "name": "[SPG] - Whitted - SPG - Saint Petersburg - United States",
            "searchName": "SPG-Whitted-Whitted Havalimani-Saint Petersburg-St Petersburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SPA",
            "countryCode": "US",
            "cityCode": "SPA",
            "cityName": "Spartanburg",
            "name": "[SPA] - Spartanburg, Downtown Memorial - SPA - Spartanburg - United States",
            "searchName": "SPA-Spartanburg- Downtown Memorial-Downtown Memorial Havalimani-Spartanburg, Downtown Memorial-Greenville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SQV",
            "countryCode": "US",
            "cityCode": "SQV",
            "cityName": "Sequim",
            "name": "[SQV] - Sequim Valley Airport - SQV - Sequim - United States",
            "searchName": "SQV-Valley-Sequim Valley Airport-Sequim-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JSG",
            "countryCode": "US",
            "cityCode": "SRF",
            "cityName": "San Rafael",
            "name": "[JSG] - San Rafael Heliport - SRF - San Rafael - United States",
            "searchName": "JSG-SRF-San Rafael Heliport-San Rafael-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SRC",
            "countryCode": "US",
            "cityCode": "SRC",
            "cityName": "Searcy",
            "name": "[SRC] - Searcy - SRC - Searcy - United States",
            "searchName": "SRC-Searcy-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SPW",
            "countryCode": "US",
            "cityCode": "SPW",
            "cityName": "Spencer",
            "name": "[SPW] - Spencer Municipal - SPW - Spencer - United States",
            "searchName": "SPW-Spencer Municipal-Spencer Belediyesi Havalimani-Spencer-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SPZ",
            "countryCode": "US",
            "cityCode": "SPZ",
            "cityName": "Springdale",
            "name": "[SPZ] - Springdale Municipal - SPZ - Springdale - United States",
            "searchName": "SPZ-Springdale Municipal-Springdale  Havalimani-Springdale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SQA",
            "countryCode": "US",
            "cityCode": "SQA",
            "cityName": "Santa Ynez",
            "name": "[SQA] - Santa Ynez - SQA - Santa Ynez - United States",
            "searchName": "SQA-Santa Ynez-Santa Ynez Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SPS",
            "countryCode": "US",
            "cityCode": "SPS",
            "cityName": "Wichita Falls",
            "name": "[SPS] - Sheppard AFB - SPS - Wichita Falls - United States",
            "searchName": "SPS-Wichita Falls-Sheppard AFB-Metropolitan Area-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KIP",
            "countryCode": "US",
            "cityCode": "SPS",
            "cityName": "Wichita Falls",
            "name": "[KIP] - Kickapoo - SPS - Wichita Falls - United States",
            "searchName": "KIP-SPS-Kickapoo-Wichita Falls-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SQL",
            "countryCode": "US",
            "cityCode": "SQL",
            "cityName": "San Carlos",
            "name": "[SQL] - San Carlos - SQL - San Carlos - United States",
            "searchName": "SQL-San Carlos-San Carlos Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SQI",
            "countryCode": "US",
            "cityCode": "SQI",
            "cityName": "Sterling Rockfalls",
            "name": "[SQI] - Whiteside County - SQI - Sterling Rockfalls - United States",
            "searchName": "SQI-Whiteside County-Whiteside Cty Belediyesi Havalimani-Sterling Rockfalls-Sterling-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "SFM",
            "countryCode": "US",
            "cityCode": "SFM",
            "cityName": "Sanford",
            "name": "[SFM] - Sanford - SFM - Sanford - United States",
            "searchName": "SFM-Sanford-Sanford Yerel Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PRZ",
            "countryCode": "US",
            "cityCode": "PRZ",
            "cityName": "Prineville",
            "name": "[PRZ] - Prineville - PRZ - Prineville - United States",
            "searchName": "PRZ-Prineville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PRL",
            "countryCode": "US",
            "cityCode": "PRL",
            "cityName": "Port Oceanic",
            "name": "[PRL] - Port Oceanic - PRL - Port Oceanic - United States",
            "searchName": "PRL-Port Oceanic-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PSB",
            "countryCode": "US",
            "cityCode": "PSB",
            "cityName": "Bellefonte",
            "name": "[PSB] - Bellefonte - PSB - Bellefonte - United States",
            "searchName": "PSB-Bellefonte-Midstate Havalimani-Philipsburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PSC",
            "countryCode": "US",
            "cityCode": "PSC",
            "cityName": "Pasco",
            "name": "[PSC] - Tri-cities - PSC - Pasco - United States",
            "searchName": "PSC-Pasco-Tri-cities-Tri Cities-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PSK",
            "countryCode": "US",
            "cityCode": "PSK",
            "cityName": "Dublin",
            "name": "[PSK] - New River Valley - PSK - Dublin - United States",
            "searchName": "PSK-New River Valley-New River Valley Havalimani-Dublin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PSN",
            "countryCode": "US",
            "cityCode": "PSN",
            "cityName": "Palestine",
            "name": "[PSN] - Palestine Municipalcipal Airport - PSN - Palestine - United States",
            "searchName": "PSN-Palestine Municipalcipal Airport-Palestine Havalimani-Palestine-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PSX",
            "countryCode": "US",
            "cityCode": "PSX",
            "cityName": "Palacios",
            "name": "[PSX] - Palacios - PSX - Palacios - United States",
            "searchName": "PSX-Palacios-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PSP",
            "countryCode": "US",
            "cityCode": "PSP",
            "cityName": "Palm Springs",
            "name": "[PSP] - Palm Springs Metropolitan Area - PSP - Palm Springs - United States",
            "searchName": "PSP-Palm Springs-Palm Springs Metropolitan Area-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "UDD",
            "countryCode": "US",
            "cityCode": "PSP",
            "cityName": "Palm Springs",
            "name": "[UDD] - Bermuda Dunes - PSP - Palm Springs - United States",
            "searchName": "UDD-PSP-Bermuda Dunes-Bermuda Dunes Havalimani-Palm Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PUB",
            "countryCode": "US",
            "cityCode": "PUB",
            "cityName": "Pueblo",
            "name": "[PUB] - Memorial - PUB - Pueblo - United States",
            "searchName": "PUB-Pueblo-Memorial-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PUC",
            "countryCode": "US",
            "cityCode": "PUC",
            "cityName": "Price",
            "name": "[PUC] - Carbon County - PUC - Price - United States",
            "searchName": "PUC-Carbon County-Carbon Cty  Havalimani-Price-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PUL",
            "countryCode": "US",
            "cityCode": "PUL",
            "cityName": "Poulsbo",
            "name": "[PUL] - Poulsbo - PUL - Poulsbo - United States",
            "searchName": "PUL-Poulsbo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTN",
            "countryCode": "US",
            "cityCode": "PTN",
            "cityName": "Patterson",
            "name": "[PTN] - Williams Memorial - PTN - Patterson - United States",
            "searchName": "PTN-Williams Memorial-Williams Memorial Havalimani-Patterson-Morgan City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTH",
            "countryCode": "US",
            "cityCode": "PTH",
            "cityName": "Port Heiden",
            "name": "[PTH] - Port Heiden - PTH - Port Heiden - United States",
            "searchName": "PTH-Port Heiden-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTA",
            "countryCode": "US",
            "cityCode": "PTA",
            "cityName": "Port Alsworth",
            "name": "[PTA] - Port Alsworth - PTA - Port Alsworth - United States",
            "searchName": "PTA-Port Alsworth-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTB",
            "countryCode": "US",
            "cityCode": "PTB",
            "cityName": "Petersburg",
            "name": "[PTB] - Dinwiddie County Airport - PTB - Petersburg - United States",
            "searchName": "PTB-Dinwiddie County Airport-Petersburg  Havalimani-Petersburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTC",
            "countryCode": "US",
            "cityCode": "PTC",
            "cityName": "Port Alice",
            "name": "[PTC] - Port Alice - PTC - Port Alice - United States",
            "searchName": "PTC-Port Alice-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTD",
            "countryCode": "US",
            "cityCode": "PTD",
            "cityName": "Port Alexander",
            "name": "[PTD] - Port Alexander - PTD - Port Alexander - United States",
            "searchName": "PTD-Port Alexander-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTR",
            "countryCode": "US",
            "cityCode": "PTR",
            "cityName": "Pleasant Harbour",
            "name": "[PTR] - Pleasant Harbour - PTR - Pleasant Harbour - United States",
            "searchName": "PTR-Pleasant Harbour-Pleasant Harbor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTS",
            "countryCode": "US",
            "cityCode": "PTS",
            "cityName": "Pittsburg",
            "name": "[PTS] - Atkinson Municipal Airport - PTS - Pittsburg - United States",
            "searchName": "PTS-Atkinson Municipal Airport-Atkinson  Havalimani-Pittsburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTT",
            "countryCode": "US",
            "cityCode": "PTT",
            "cityName": "Pratt",
            "name": "[PTT] - Pratt - PTT - Pratt - United States",
            "searchName": "PTT-Pratt-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTU",
            "countryCode": "US",
            "cityCode": "PTU",
            "cityName": "Platinum",
            "name": "[PTU] - Platinum - PTU - Platinum - United States",
            "searchName": "PTU-Platinum-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTV",
            "countryCode": "US",
            "cityCode": "PTV",
            "cityName": "Porterville",
            "name": "[PTV] - Porterville - PTV - Porterville - United States",
            "searchName": "PTV-Porterville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTW",
            "countryCode": "US",
            "cityCode": "PTW",
            "cityName": "Pottstown",
            "name": "[PTW] - Pottstown/Limerick - PTW - Pottstown - United States",
            "searchName": "PTW-Pottstown/Limerick-Pottstown Limerick Havalimani-Pottstown-Potsdam-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTK",
            "countryCode": "US",
            "cityCode": "PTK",
            "cityName": "Pontiac",
            "name": "[PTK] - Pontiac - PTK - Pontiac - United States",
            "searchName": "PTK-Pontiac-Oakland Pontiac Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PTL",
            "countryCode": "US",
            "cityCode": "PTL",
            "cityName": "Port Armstrong",
            "name": "[PTL] - Port Armstrong - PTL - Port Armstrong - United States",
            "searchName": "PTL-Port Armstrong-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RBY",
            "countryCode": "US",
            "cityCode": "RBY",
            "cityName": "Ruby",
            "name": "[RBY] - Ruby - RBY - Ruby - United States",
            "searchName": "RBY-Ruby-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RCE",
            "countryCode": "US",
            "cityCode": "RCE",
            "cityName": "Roche Harbor",
            "name": "[RCE] - Roche Harbor - RCE - Roche Harbor - United States",
            "searchName": "RCE-Roche Harbor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RCP",
            "countryCode": "US",
            "cityCode": "RCP",
            "cityName": "Cinder River",
            "name": "[RCP] - Cinder River - RCP - Cinder River - United States",
            "searchName": "RCP-Cinder River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RCK",
            "countryCode": "US",
            "cityCode": "RCK",
            "cityName": "Rockdale",
            "name": "[RCK] - Coffield - RCK - Rockdale - United States",
            "searchName": "RCK-Coffield-Rockdale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RCT",
            "countryCode": "US",
            "cityCode": "RCT",
            "cityName": "Reed City",
            "name": "[RCT] - Miller Field - RCT - Reed City - United States",
            "searchName": "RCT-Miller Field-Reed City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RCR",
            "countryCode": "US",
            "cityCode": "RCR",
            "cityName": "Rochester",
            "name": "[RCR] - Fulton County - RCR - Rochester - United States",
            "searchName": "RCR-Fulton County-Rochester-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RDB",
            "countryCode": "US",
            "cityCode": "RDB",
            "cityName": "Red Dog",
            "name": "[RDB] - Red Dog - RDB - Red Dog - United States",
            "searchName": "RDB-Red Dog-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RBW",
            "countryCode": "US",
            "cityCode": "RBW",
            "cityName": "Walterboro",
            "name": "[RBW] - Lowcountry Regional - RBW - Walterboro - United States",
            "searchName": "RBW-Lowcountry Regional-Walterboro  Havalimani-Walterboro-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RBN",
            "countryCode": "US",
            "cityCode": "RBN",
            "cityName": "Fort Jefferson",
            "name": "[RBN] - Fort Jefferson - RBN - Fort Jefferson - United States",
            "searchName": "RBN-Fort Jefferson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RBK",
            "countryCode": "US",
            "cityCode": "RBK",
            "cityName": "Rancho",
            "name": "[RBK] - French Valley - RBK - Rancho - United States",
            "searchName": "RBK-French Valley-Rancho-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RBL",
            "countryCode": "US",
            "cityCode": "RBL",
            "cityName": "Red Bluff",
            "name": "[RBL] - Red Bluff Fss - RBL - Red Bluff - United States",
            "searchName": "RBL-Red Bluff Fss-Flight Service Station Havalimani-Red Bluff-Redding-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RAC",
            "countryCode": "US",
            "cityCode": "RAC",
            "cityName": "Racine",
            "name": "[RAC] - John H. Batten Field - RAC - Racine - United States",
            "searchName": "RAC-John H. Batten Field-Horlick Havalimani-Racine-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RAL",
            "countryCode": "US",
            "cityCode": "RAL",
            "cityName": "Riverside",
            "name": "[RAL] - Riverside Municipalcipal - RAL - Riverside - United States",
            "searchName": "RAL-Riverside Municipalcipal-Riverside Belediyesi Havalimani-Riverside-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RIV",
            "countryCode": "US",
            "cityCode": "RAL",
            "cityName": "Riverside",
            "name": "[RIV] - March ARB - RAL - Riverside - United States",
            "searchName": "RIV-RAL-March ARB-March Havalimani-Riverside-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RIR",
            "countryCode": "US",
            "cityCode": "RAL",
            "cityName": "Riverside",
            "name": "[RIR] - Riverside Fla-Bob - RAL - Riverside - United States",
            "searchName": "RIR-RAL-Riverside Fla-Bob-Riverside-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JRD",
            "countryCode": "US",
            "cityCode": "RAL",
            "cityName": "Riverside",
            "name": "[JRD] - Heliport - RAL - Riverside - United States",
            "searchName": "JRD-RAL-Heliport-Riverside-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RAP",
            "countryCode": "US",
            "cityCode": "RAP",
            "cityName": "Rapid City",
            "name": "[RAP] - Regional - RAP - Rapid City - United States",
            "searchName": "RAP-Rapid City-Regional-Rapid city-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RCA",
            "countryCode": "US",
            "cityCode": "RAP",
            "cityName": "Rapid City",
            "name": "[RCA] - Ellsworth AFB - RAP - Rapid City - United States",
            "searchName": "RCA-RAP-Ellsworth AFB-Rapid City-Rapid city-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RBF",
            "countryCode": "US",
            "cityCode": "RBF",
            "cityName": "Big Bear",
            "name": "[RBF] - Big Bear City Airport - RBF - Big Bear - United States",
            "searchName": "RBF-Big Bear City Airport-Big Bear City Havalimani-Big Bear-Big Bear City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RBG",
            "countryCode": "US",
            "cityCode": "RBG",
            "cityName": "Roseburg",
            "name": "[RBG] - Roseburg Municipal Airport - RBG - Roseburg - United States",
            "searchName": "RBG-Roseburg Municipal Airport-Roseburg Belediyesi Havalimani-Roseburg-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RBH",
            "countryCode": "US",
            "cityCode": "RBH",
            "cityName": "Brooks Lodge",
            "name": "[RBH] - Brooks Lodge - RBH - Brooks Lodge - United States",
            "searchName": "RBH-Brooks Lodge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "QWP",
            "countryCode": "US",
            "cityCode": "QWP",
            "cityName": "Winter Park",
            "name": "[QWP] - Bus Station - QWP - Winter Park - United States",
            "searchName": "QWP-Bus Station-Winter Park-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "QMV",
            "countryCode": "US",
            "cityCode": "QMV",
            "cityName": "Montvale",
            "name": "[QMV] - Montvale - QMV - Montvale - United States",
            "searchName": "QMV-Montvale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "QKB",
            "countryCode": "US",
            "cityCode": "QKB",
            "cityName": "Breckenridge",
            "name": "[QKB] - Bus Station - QKB - Breckenridge - United States",
            "searchName": "QKB-Bus Station-Breckenridge Van Service Havalimani-Breckenridge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "QKS",
            "countryCode": "US",
            "cityCode": "QKS",
            "cityName": "Keystone",
            "name": "[QKS] - Bus Station - QKS - Keystone - United States",
            "searchName": "QKS-Bus Station-Keystone Van Service Havalimani-Keystone-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TSP",
            "countryCode": "US",
            "cityCode": "TSP",
            "cityName": "Tehachapi",
            "name": "[TSP] - Kern County - TSP - Tehachapi - United States",
            "searchName": "TSP-Kern County-Tehachapi-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TSM",
            "countryCode": "US",
            "cityCode": "TSM",
            "cityName": "Taos",
            "name": "[TSM] - Taos - TSM - Taos - United States",
            "searchName": "TSM-Taos-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TTO",
            "countryCode": "US",
            "cityCode": "TTO",
            "cityName": "Britton",
            "name": "[TTO] - Britton Municipal Airport - TTO - Britton - United States",
            "searchName": "TTO-Britton Municipal Airport-Britton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TTD",
            "countryCode": "US",
            "cityCode": "TTD",
            "cityName": "Troutdale",
            "name": "[TTD] - Troutdale - TTD - Troutdale - United States",
            "searchName": "TTD-Troutdale-Troutdale Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TRT",
            "countryCode": "US",
            "cityCode": "TRT",
            "cityName": "Tremonton",
            "name": "[TRT] - Tremonton - TRT - Tremonton - United States",
            "searchName": "TRT-Tremonton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TRP",
            "countryCode": "US",
            "cityCode": "TRP",
            "cityName": "Tree Point",
            "name": "[TRP] - Coast Guard Heliport - TRP - Tree Point - United States",
            "searchName": "TRP-Hubschrauberlandeplatz der Küstenwache-Coast Guard Heliport-Tree Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TRX",
            "countryCode": "US",
            "cityCode": "TRX",
            "cityName": "Trenton",
            "name": "[TRX] - Memorial - TRX - Trenton - United States",
            "searchName": "TRX-Memorial-Trenton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TSG",
            "countryCode": "US",
            "cityCode": "TSG",
            "cityName": "Tanacross",
            "name": "[TSG] - Intermediate - TSG - Tanacross - United States",
            "searchName": "TSG-Intermediate-Tanacross-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TRH",
            "countryCode": "US",
            "cityCode": "TRH",
            "cityName": "Trona",
            "name": "[TRH] - Trona - TRH - Trona - United States",
            "searchName": "TRH-Trona-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TRI",
            "countryCode": "US",
            "cityCode": "TRI",
            "cityName": "Blountville",
            "name": "[TRI] - Tri-cities Regional - TRI - Blountville - United States",
            "searchName": "TRI-Bristol Tri City-Tri-cities Regional-Tri Cities TN/VA-Tri City Regional Airport-Blountville TN-Blountville-Bristol-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TRL",
            "countryCode": "US",
            "cityCode": "TRL",
            "cityName": "Terrell",
            "name": "[TRL] - Terrell Field - TRL - Terrell - United States",
            "searchName": "TRL-Terrell Field-Terrell Field Havalimani-Terrell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TRM",
            "countryCode": "US",
            "cityCode": "TRM",
            "cityName": "Thermal",
            "name": "[TRM] - Thermal - TRM - Thermal - United States",
            "searchName": "TRM-Thermal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TXK",
            "countryCode": "US",
            "cityCode": "TXK",
            "cityName": "Texarkana",
            "name": "[TXK] - Texarkana Municipal - TXK - Texarkana - United States",
            "searchName": "TXK-Texarkana-Texarkana Municipal-Webb Field-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TWD",
            "countryCode": "US",
            "cityCode": "TWD",
            "cityName": "Port Townsend",
            "name": "[TWD] - Port Townsend - TWD - Port Townsend - United States",
            "searchName": "TWD-Port Townsend-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TWE",
            "countryCode": "US",
            "cityCode": "TWE",
            "cityName": "Taylor",
            "name": "[TWE] - Taylor - TWE - Taylor - United States",
            "searchName": "TWE-Taylor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TWF",
            "countryCode": "US",
            "cityCode": "TWF",
            "cityName": "Twin Falls",
            "name": "[TWF] - Joslin Field - Magic Valley Regional - TWF - Twin Falls - United States",
            "searchName": "TWF-Twin Falls-Joslin Field - Magic Valley Regional-Joslin-Twin Falls City County-Twin Falls Idaho-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TYE",
            "countryCode": "US",
            "cityCode": "TYE",
            "cityName": "Tyonek",
            "name": "[TYE] - Tyonek - TYE - Tyonek - United States",
            "searchName": "TYE-Tyonek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TYZ",
            "countryCode": "US",
            "cityCode": "TYZ",
            "cityName": "Taylor",
            "name": "[TYZ] - Taylor - TYZ - Taylor - United States",
            "searchName": "TYZ-Taylor-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TZC",
            "countryCode": "US",
            "cityCode": "TZC",
            "cityName": "Caro",
            "name": "[TZC] - Tuscola Area - TZC - Caro - United States",
            "searchName": "TZC-Tuscola Area-Caro-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TYR",
            "countryCode": "US",
            "cityCode": "TYR",
            "cityName": "Tyler",
            "name": "[TYR] - Tyler Pounds Regional Airport - TYR - Tyler - United States",
            "searchName": "TYR-Tyler-Tyler Pounds Regional Airport-Pounds Field-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TYS",
            "countryCode": "US",
            "cityCode": "TYS",
            "cityName": "Knoxville",
            "name": "[TYS] - Mc Ghee Tyson - TYS - Knoxville - United States",
            "searchName": "TYS-Knoxville-Mc Ghee Tyson-McGhee Tyson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TUP",
            "countryCode": "US",
            "cityCode": "TUP",
            "cityName": "Tupelo",
            "name": "[TUP] - Lemons Municipalcipal - TUP - Tupelo - United States",
            "searchName": "TUP-Tupelo-Lemons Municipalcipal-Lemons Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TUL",
            "countryCode": "US",
            "cityCode": "TUL",
            "cityName": "Tulsa",
            "name": "[TUL] - Tulsa International - TUL - Tulsa - United States",
            "searchName": "TUL-Tulsa-Tulsa International-Tulsa Oklahoma-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "RVS",
            "countryCode": "US",
            "cityCode": "TUL",
            "cityName": "Tulsa",
            "name": "[RVS] - R.Lloyd Jones - TUL - Tulsa - United States",
            "searchName": "RVS-TUL-R.Lloyd Jones-Tulsa Oklahoma-Tulsa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TUS",
            "countryCode": "US",
            "cityCode": "TUS",
            "cityName": "Tucson",
            "name": "[TUS] - Tucson International Airport - TUS - Tucson - United States",
            "searchName": "TUS-Tucson-Tucson International Airport-Tucson Arizona-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "DMA",
            "countryCode": "US",
            "cityCode": "TUS",
            "cityName": "Tucson",
            "name": "[DMA] - Davis Monthan AFB - TUS - Tucson - United States",
            "searchName": "DMA-TUS-Davis Monthan AFB-Tucson Arizona-Tucson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "AVW",
            "countryCode": "US",
            "cityCode": "TUS",
            "cityName": "Tucson",
            "name": "[AVW] - Avra Valley - TUS - Tucson - United States",
            "searchName": "AVW-TUS-Avra Valley-Tucson Arizona-Tucson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TWA",
            "countryCode": "US",
            "cityCode": "TWA",
            "cityName": "Twin Hills",
            "name": "[TWA] - Twin Hills - TWA - Twin Hills - United States",
            "searchName": "TWA-Twin Hills-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TVC",
            "countryCode": "US",
            "cityCode": "TVC",
            "cityName": "Traverse City",
            "name": "[TVC] - Cherry Capital Airport - TVC - Traverse City - United States",
            "searchName": "TVC-Traverse City-Cherry Capital Airport-Cherry Capital-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TVF",
            "countryCode": "US",
            "cityCode": "TVF",
            "cityName": "Thief River Falls",
            "name": "[TVF] - Regional - TVF - Thief River Falls - United States",
            "searchName": "TVF-Thief River Falls-Regional-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TVI",
            "countryCode": "US",
            "cityCode": "TVI",
            "cityName": "Thomasville",
            "name": "[TVI] - Thomasville Municipal Airport - TVI - Thomasville - United States",
            "searchName": "TVI-Kommunaler Flughafen-Thomasville Municipal Airport-Thomasville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TVL",
            "countryCode": "US",
            "cityCode": "TVL",
            "cityName": "South Lake Tahoe",
            "name": "[TVL] - South Lake Tahoe - TVL - South Lake Tahoe - United States",
            "searchName": "TVL-South Lake Tahoe-Lake Tahoe Havalimani-Lake Tahoe South-Lake Tahoe-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TOC",
            "countryCode": "US",
            "cityCode": "TOC",
            "cityName": "Toccoa",
            "name": "[TOC] - Toccoa RG Letourneau Field - TOC - Toccoa - United States",
            "searchName": "TOC-Toccoa RG Letourneau Field-R.G. LeTourneau Field Airport-Toccoa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TOG",
            "countryCode": "US",
            "cityCode": "TOG",
            "cityName": "Togiak Village",
            "name": "[TOG] - Togiak Village - TOG - Togiak Village - United States",
            "searchName": "TOG-Togiak Village-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TOL",
            "countryCode": "US",
            "cityCode": "TOL",
            "cityName": "Toledo",
            "name": "[TOL] - Toledo Express - TOL - Toledo - United States",
            "searchName": "TOL-Toledo USA-Toledo Express-Express-Toledo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TDZ",
            "countryCode": "US",
            "cityCode": "TOL",
            "cityName": "Toledo",
            "name": "[TDZ] - Toledo - TOL - Toledo - United States",
            "searchName": "TDZ-TOL-Toledo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TOP",
            "countryCode": "US",
            "cityCode": "TOP",
            "cityName": "Topeka",
            "name": "[TOP] - Philip Billard - TOP - Topeka - United States",
            "searchName": "TOP-Philip Billard-Topeka-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "FOE",
            "countryCode": "US",
            "cityCode": "TOP",
            "cityName": "Topeka",
            "name": "[FOE] - Forbes Field - TOP - Topeka - United States",
            "searchName": "FOE-TOP-Forbes Field-Topeka-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TOR",
            "countryCode": "US",
            "cityCode": "TOR",
            "cityName": "Torrington",
            "name": "[TOR] - Torrington Municipal Airport - TOR - Torrington - United States",
            "searchName": "TOR-Torrington Municipal Airport-Torrington-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TNP",
            "countryCode": "US",
            "cityCode": "TNP",
            "cityName": "Twentynine Palms",
            "name": "[TNP] - Twentynine Palms - TNP - Twentynine Palms - United States",
            "searchName": "TNP-Twentynine Palms-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TNK",
            "countryCode": "US",
            "cityCode": "TNK",
            "cityName": "Tununak",
            "name": "[TNK] - Tununak - TNK - Tununak - United States",
            "searchName": "TNK-Tununak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TOA",
            "countryCode": "US",
            "cityCode": "TOA",
            "cityName": "Torrance",
            "name": "[TOA] - Torrance Airport - TOA - Torrance - United States",
            "searchName": "TOA-Torrance Airport-Torrance-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TNU",
            "countryCode": "US",
            "cityCode": "TNU",
            "cityName": "Newton",
            "name": "[TNU] - Newton Municipal Airport - TNU - Newton - United States",
            "searchName": "TNU-Newton Municipal Airport-BelediyesiNewton Havalimani-Newton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TPH",
            "countryCode": "US",
            "cityCode": "TPH",
            "cityName": "Tonopah",
            "name": "[TPH] - Tonopah Airport - TPH - Tonopah - United States",
            "searchName": "TPH-Tonopah Airport-Tonopah Havalimani-Tonopah-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "XSD",
            "countryCode": "US",
            "cityCode": "TPH",
            "cityName": "Tonopah",
            "name": "[XSD] - Test Range - TPH - Tonopah - United States",
            "searchName": "XSD-TPH-Test Range-Tonopah-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TPA",
            "countryCode": "US",
            "cityCode": "TPA",
            "cityName": "Tampa",
            "name": "[TPA] - Tampa International - TPA - Tampa - United States",
            "searchName": "TPA-Tampa-Tampa International-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TPF",
            "countryCode": "US",
            "cityCode": "TPA",
            "cityName": "Tampa",
            "name": "[TPF] - Peter OKnight - TPA - Tampa - United States",
            "searchName": "TPF-TPA-Peter O Knight-Peter O Knight Havalimani-Peter OKnight-Tampa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "PIE",
            "countryCode": "US",
            "cityCode": "TPA",
            "cityName": "Tampa",
            "name": "[PIE] - St. Petersburg-Clearwater International - TPA - Tampa - United States",
            "searchName": "PIE-TPA-Saint Petersburg-St. Petersburg-Clearwater International-Tampa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MCF",
            "countryCode": "US",
            "cityCode": "TPA",
            "cityName": "Tampa",
            "name": "[MCF] - Mac Dill AFB - TPA - Tampa - United States",
            "searchName": "MCF-TPA-Mac Dill AFB-Tampa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "KYO",
            "countryCode": "US",
            "cityCode": "TPA",
            "cityName": "Tampa",
            "name": "[KYO] - Topp Of Tampa - TPA - Tampa - United States",
            "searchName": "KYO-TPA-Topp Of Tampa-Tampa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TOI",
            "countryCode": "US",
            "cityCode": "TOI",
            "cityName": "Troy",
            "name": "[TOI] - Troy Municipal Airport - TOI - Troy - United States",
            "searchName": "TOI-Troy Municipal Airport-Troy-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TPL",
            "countryCode": "US",
            "cityCode": "TPL",
            "cityName": "Temple",
            "name": "[TPL] - Draughon-miller Central Texas Regional - TPL - Temple - United States",
            "searchName": "TPL-Draughon Miller-Draughon-miller Central Texas Regional-Temple Havalimani-Temple-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TPO",
            "countryCode": "US",
            "cityCode": "TPO",
            "cityName": "Tanalian Point",
            "name": "[TPO] - Tanalian Point - TPO - Tanalian Point - United States",
            "searchName": "TPO-Tanalian Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TLH",
            "countryCode": "US",
            "cityCode": "TLH",
            "cityName": "Tallahassee",
            "name": "[TLH] - Tallahassee Regional Airport - TLH - Tallahassee - United States",
            "searchName": "TLH-Tallahassee-Tallahassee Regional Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TLF",
            "countryCode": "US",
            "cityCode": "TLF",
            "cityName": "Telida",
            "name": "[TLF] - Telida - TLF - Telida - United States",
            "searchName": "TLF-Telida-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TLA",
            "countryCode": "US",
            "cityCode": "TLA",
            "cityName": "Teller",
            "name": "[TLA] - Teller - TLA - Teller - United States",
            "searchName": "TLA-Teller-Teller Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TLJ",
            "countryCode": "US",
            "cityCode": "TLJ",
            "cityName": "Tatalina",
            "name": "[TLJ] - Tatalina AFS - TLJ - Tatalina - United States",
            "searchName": "TLJ-Tatalina AFS-Tatalina-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TLT",
            "countryCode": "US",
            "cityCode": "TLT",
            "cityName": "Tuluksak",
            "name": "[TLT] - Tuluksak - TLT - Tuluksak - United States",
            "searchName": "TLT-Tuluksak-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TLR",
            "countryCode": "US",
            "cityCode": "TLR",
            "cityName": "Tulare",
            "name": "[TLR] - Mefford Field - TLR - Tulare - United States",
            "searchName": "TLR-Mefford Field-Mefford Field Havalimani-Tulare-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TNC",
            "countryCode": "US",
            "cityCode": "TNC",
            "cityName": "Tin City",
            "name": "[TNC] - Tin City Afs - TNC - Tin City - United States",
            "searchName": "TNC-Tin City AFS-Tin City Afs-Tin City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TMA",
            "countryCode": "US",
            "cityCode": "TMA",
            "cityName": "Tifton",
            "name": "[TMA] - Henry Tift Myers - TMA - Tifton - United States",
            "searchName": "TMA-Henry Tift Myers-Henry Tift Myers Havalimani-Tifton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "THA",
            "countryCode": "US",
            "cityCode": "THA",
            "cityName": "Tullahoma",
            "name": "[THA] - Tullahoma/William Northern Field - THA - Tullahoma - United States",
            "searchName": "THA-Tullahoma/William Northern Field-Tullahoma-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TUH",
            "countryCode": "US",
            "cityCode": "THA",
            "cityName": "Tullahoma",
            "name": "[TUH] - Arnold AFS - THA - Tullahoma - United States",
            "searchName": "TUH-THA-Arnold AFS-Tullahoma-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TGE",
            "countryCode": "US",
            "cityCode": "TGE",
            "cityName": "Tuskegee",
            "name": "[TGE] - Sharpe Field - TGE - Tuskegee - United States",
            "searchName": "TGE-Sharpe Field-Tuskegee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TEK",
            "countryCode": "US",
            "cityCode": "TEK",
            "cityName": "Tatitlek",
            "name": "[TEK] - Tatitlek - TEK - Tatitlek - United States",
            "searchName": "TEK-Tatitlek-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TEH",
            "countryCode": "US",
            "cityCode": "TEH",
            "cityName": "Tetlin",
            "name": "[TEH] - Tetlin - TEH - Tetlin - United States",
            "searchName": "TEH-Tetlin-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TEX",
            "countryCode": "US",
            "cityCode": "TEX",
            "cityName": "Telluride",
            "name": "[TEX] - Telluride Regional - TEX - Telluride - United States",
            "searchName": "TEX-Telluride-Telluride Regional-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "ZTL",
            "countryCode": "US",
            "cityCode": "TEX",
            "cityName": "Telluride",
            "name": "[ZTL] - Telluride Bus Station - TEX - Telluride - United States",
            "searchName": "ZTL-TEX-Telluride Bus Station-Telluride-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TEB",
            "countryCode": "US",
            "cityCode": "TEB",
            "cityName": "Teterboro",
            "name": "[TEB] - Teterboro - TEB - Teterboro - United States",
            "searchName": "TEB-Teterboro-Teterboro Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TDO",
            "countryCode": "US",
            "cityCode": "TDO",
            "cityName": "Toledo",
            "name": "[TDO] - Winlock - TDO - Toledo - United States",
            "searchName": "TDO-Winlock-Toledo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TIW",
            "countryCode": "US",
            "cityCode": "TIW",
            "cityName": "Tacoma",
            "name": "[TIW] - Industrial - TIW - Tacoma - United States",
            "searchName": "TIW-Industrieflugplatz-Industrial-Tacoma Industrial Havalimani-Tacoma-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TCM",
            "countryCode": "US",
            "cityCode": "TIW",
            "cityName": "Tacoma",
            "name": "[TCM] - McChord AFB - TIW - Tacoma - United States",
            "searchName": "TCM-TIW-McChord AFB-Tacoma-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GRF",
            "countryCode": "US",
            "cityCode": "TIW",
            "cityName": "Tacoma",
            "name": "[GRF] - Gray AAF - TIW - Tacoma - United States",
            "searchName": "GRF-TIW-Gray AAF-Tacoma-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TIX",
            "countryCode": "US",
            "cityCode": "TIX",
            "cityName": "Titusville",
            "name": "[TIX] - Space Coast Regional - TIX - Titusville - United States",
            "searchName": "TIX-Space Coast Regional-Space Center Executive Havalimani-Titusville-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TKA",
            "countryCode": "US",
            "cityCode": "TKA",
            "cityName": "Talkeetna",
            "name": "[TKA] - Talkeetna - TKA - Talkeetna - United States",
            "searchName": "TKA-Talkeetna-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TKE",
            "countryCode": "US",
            "cityCode": "TKE",
            "cityName": "Tenakee Springs",
            "name": "[TKE] - Tenakee SPB - TKE - Tenakee Springs - United States",
            "searchName": "TKE-Tenakee SPB-Tenakee Springs-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TKF",
            "countryCode": "US",
            "cityCode": "TKF",
            "cityName": "Truckee",
            "name": "[TKF] - Truckee-Tahoe - TKF - Truckee - United States",
            "searchName": "TKF-Truckee-Tahoe-Truckee Havalimani-Reno-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TKI",
            "countryCode": "US",
            "cityCode": "TKI",
            "cityName": "Tokeen",
            "name": "[TKI] - Tokeen - TKI - Tokeen - United States",
            "searchName": "TKI-Tokeen-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TKJ",
            "countryCode": "US",
            "cityCode": "TKJ",
            "cityName": "Tok",
            "name": "[TKJ] - Tok - TKJ - Tok - United States",
            "searchName": "TKJ-Tok-Tok Alaska-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TKL",
            "countryCode": "US",
            "cityCode": "TKL",
            "cityName": "Taku Lodge",
            "name": "[TKL] - Taku SPB - TKL - Taku Lodge - United States",
            "searchName": "TKL-Flughafem Taku-Taku SPB-Taku Lodge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "THV",
            "countryCode": "US",
            "cityCode": "THV",
            "cityName": "York",
            "name": "[THV] - York Airport - THV - York - United States",
            "searchName": "THV-York Airport-York Havalimani-York-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "THM",
            "countryCode": "US",
            "cityCode": "THM",
            "cityName": "Thompsonfield",
            "name": "[THM] - Thompsonfield - THM - Thompsonfield - United States",
            "searchName": "THM-Thompsonfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "THP",
            "countryCode": "US",
            "cityCode": "THP",
            "cityName": "Thermopolis",
            "name": "[THP] - Hot Springs - THP - Thermopolis - United States",
            "searchName": "THP-Hot Springs-Hot Springs Havalimani-Thermopolis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TBC",
            "countryCode": "US",
            "cityCode": "TBC",
            "cityName": "Tuba City",
            "name": "[TBC] - Tuba City - TBC - Tuba City - United States",
            "searchName": "TBC-Tuba City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TBR",
            "countryCode": "US",
            "cityCode": "TBR",
            "cityName": "Statesboro",
            "name": "[TBR] - Statesboro-Bulloch County Airport - TBR - Statesboro - United States",
            "searchName": "TBR-Statesboro-Bulloch County Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TCC",
            "countryCode": "US",
            "cityCode": "TCC",
            "cityName": "Tucumcari",
            "name": "[TCC] - Tucumcari - TCC - Tucumcari - United States",
            "searchName": "TCC-Tucumcari-Tucumcari Havalimani-Las Vegas-Tucamcari-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TCS",
            "countryCode": "US",
            "cityCode": "TCS",
            "cityName": "Truth Or Consequences",
            "name": "[TCS] - Truth or Consequences - TCS - Truth Or Consequences - United States",
            "searchName": "TCS-Truth or Consequences-Truth Or Consequences-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TCT",
            "countryCode": "US",
            "cityCode": "TCT",
            "cityName": "Takotna",
            "name": "[TCT] - Takotna - TCT - Takotna - United States",
            "searchName": "TCT-Takotna-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TCL",
            "countryCode": "US",
            "cityCode": "TCL",
            "cityName": "Tuscaloosa",
            "name": "[TCL] - Van De Graaf - TCL - Tuscaloosa - United States",
            "searchName": "TCL-Van De Graaf-Tuscaloosa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TBN",
            "countryCode": "US",
            "cityCode": "TBN",
            "cityName": "Fort Leonard Wood",
            "name": "[TBN] - Forney AAF - TBN - Fort Leonard Wood - United States",
            "searchName": "TBN-Fort Leonard Wood-Forney AAF-Waynesville Regional-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "TAD",
            "countryCode": "US",
            "cityCode": "TAD",
            "cityName": "Trinidad",
            "name": "[TAD] - Las Animas - TAD - Trinidad - United States",
            "searchName": "TAD-Las Animas-Las Animas Havalimani-Trinidad-Pueblo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "CKZ",
            "countryCode": "TR",
            "cityCode": "CKZ",
            "cityName": "Canakkale",
            "name": "[CKZ] - Canakkale - CKZ - Canakkale - Turkey",
            "searchName": "CKZ-Canakkale-Çanakkale Havalimani--Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "CII",
            "countryCode": "TR",
            "cityCode": "CII",
            "cityName": "Aydin",
            "name": "[CII] - Cildir - CII - Aydin - Turkey",
            "searchName": "CII-Cildir-Aydin-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "ISE",
            "countryCode": "TR",
            "cityCode": "ISE",
            "cityName": "Isparta",
            "name": "[ISE] - Isparta - ISE - Isparta - Turkey",
            "searchName": "ISE-Isparta-Isparta Suleyman Demirel Havalimani-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "IGL",
            "countryCode": "TR",
            "cityCode": "IZM",
            "cityName": "Izmir",
            "name": "[IGL] - Cigli AB - IZM - Izmir - Turkey",
            "searchName": "IGL-IZM-Cigli AB-Izmir Çigli Askeri Havalimani-Izmir-Esmirna-Smirne-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "ADB",
            "countryCode": "TR",
            "cityCode": "IZM",
            "cityName": "Izmir",
            "name": "[ADB] - Adnan Menderes Airport - IZM - Izmir - Turkey",
            "searchName": "ADB-IZM-Izmir-Adnan Menderes Airport-Adnan Menderes Arpt-Esmirna-Smirne-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "GZT",
            "countryCode": "TR",
            "cityCode": "GZT",
            "cityName": "Gaziantep",
            "name": "[GZT] - Gaziantep - GZT - Gaziantep - Turkey",
            "searchName": "GZT-Gaziantep-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "GKD",
            "countryCode": "TR",
            "cityCode": "GKD",
            "cityName": "Gokceada",
            "name": "[GKD] - Gokceada - GKD - Gokceada - Turkey",
            "searchName": "GKD-Gokceada-Gokceada Havalimani-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "KSY",
            "countryCode": "TR",
            "cityCode": "KSY",
            "cityName": "Kars",
            "name": "[KSY] - Kars - KSY - Kars - Turkey",
            "searchName": "KSY-Kars-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "KCM",
            "countryCode": "TR",
            "cityCode": "KCM",
            "cityName": "Kahramanmaras",
            "name": "[KCM] - Kahramanmaras - KCM - Kahramanmaras - Turkey",
            "searchName": "KCM-Kahramanmaras-Kahramanmara-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "KCO",
            "countryCode": "TR",
            "cityCode": "KCO",
            "cityName": "Kocaeli",
            "name": "[KCO] - Cengiz Topel - KCO - Kocaeli - Turkey",
            "searchName": "KCO-Cengiz Topel-Kocaeli-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "KZR",
            "countryCode": "TR",
            "cityCode": "KZR",
            "cityName": "Kutahya",
            "name": "[KZR] - Zafer - KZR - Kutahya - Turkey",
            "searchName": "KZR-Zafer-Zafer Airport-Kütahya-Kutahya-K-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "KYA",
            "countryCode": "TR",
            "cityCode": "KYA",
            "cityName": "Konya",
            "name": "[KYA] - Konya - KYA - Konya - Turkey",
            "searchName": "KYA-Konya-Iconio-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "ERZ",
            "countryCode": "TR",
            "cityCode": "ERZ",
            "cityName": "Erzurum",
            "name": "[ERZ] - Erzurum Hava Alani - ERZ - Erzurum - Turkey",
            "searchName": "ERZ-Erzurum-Erzurum Hava Alani-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "ESK",
            "countryCode": "TR",
            "cityCode": "ESK",
            "cityName": "Eskisehir",
            "name": "[ESK] - Eskisehir - ESK - Eskisehir - Turkey",
            "searchName": "ESK-Eskisehir-Doryl-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "AOE",
            "countryCode": "TR",
            "cityCode": "ESK",
            "cityName": "Eskisehir",
            "name": "[AOE] - Anadolu University - ESK - Eskisehir - Turkey",
            "searchName": "AOE-ESK-Eskisehir-Anadolu University-Anadolu University Havalimani-Doryl-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "ERC",
            "countryCode": "TR",
            "cityCode": "ERC",
            "cityName": "Erzincan",
            "name": "[ERC] - Erzincan - ERC - Erzincan - Turkey",
            "searchName": "ERC-Erzincan-Erzincam-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "EZS",
            "countryCode": "TR",
            "cityCode": "EZS",
            "cityName": "Elazig",
            "name": "[EZS] - Elazig - EZS - Elazig - Turkey",
            "searchName": "EZS-Elazig-Elaz-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "EDO",
            "countryCode": "TR",
            "cityCode": "EDO",
            "cityName": "Edremit",
            "name": "[EDO] - Edremit/korfez - EDO - Edremit - Turkey",
            "searchName": "EDO-Edremit/Korfez-Edremit/korfez-Edremit-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "DIY",
            "countryCode": "TR",
            "cityCode": "DIY",
            "cityName": "Diyarbakir",
            "name": "[DIY] - Diyarbakir - DIY - Diyarbakir - Turkey",
            "searchName": "DIY-Diyarbakir-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "CRZ",
            "countryCode": "TM",
            "cityCode": "CRZ",
            "cityName": "Turkmenabad",
            "name": "[CRZ] - Turkmenabad - CRZ - Turkmenabad - Turkmenistan",
            "searchName": "CRZ-Turkmenabad-Türkmenabat Havalimani-Turkmenahad-T-Turkmenistan-Turkménistan-Turkmenistán",
            "priority": 0
        },
        {
            "iataCode": "KRW",
            "countryCode": "TM",
            "cityCode": "KRW",
            "cityName": "Turkmenbashi",
            "name": "[KRW] - Turkmanbashi - KRW - Turkmenbashi - Turkmenistan",
            "searchName": "KRW-Turkmanbashi-Türkmenbasi Havalimani-Turkmenbashi-T-Turkmenistan-Turkménistan-Turkmenistán",
            "priority": 0
        },
        {
            "iataCode": "GAE",
            "countryCode": "TN",
            "cityCode": "GAE",
            "cityName": "Gabes",
            "name": "[GAE] - Gabes - GAE - Gabes - Tunisia",
            "searchName": "GAE-Gabes-Gabes Airport-Gab-Tunisia-Tunézia-Tunísia-Tunus-Tunisko-Tunesien-Tunizija-Tunisas-Tunisija-Tunis-Tuneesia-Tunezja-Tunisie-Túnez",
            "priority": 0
        },
        {
            "iataCode": "GAF",
            "countryCode": "TN",
            "cityCode": "GAF",
            "cityName": "Gafsa",
            "name": "[GAF] - Gafsa - GAF - Gafsa - Tunisia",
            "searchName": "GAF-Gafsa-Tunisia-Tunézia-Tunísia-Tunus-Tunisko-Tunesien-Tunizija-Tunisas-Tunisija-Tunis-Tuneesia-Tunezja-Tunisie-Túnez",
            "priority": 0
        },
        {
            "iataCode": "DJE",
            "countryCode": "TN",
            "cityCode": "DJE",
            "cityName": "Djerba",
            "name": "[DJE] - Djerba-Zarzis - DJE - Djerba - Tunisia",
            "searchName": "DJE-Djerba-Zarzis-Melita-Tunisia-Tunézia-Tunísia-Tunus-Tunisko-Tunesien-Tunizija-Tunisas-Tunisija-Tunis-Tuneesia-Tunezja-Tunisie-Túnez",
            "priority": 0
        },
        {
            "iataCode": "EBM",
            "countryCode": "TN",
            "cityCode": "EBM",
            "cityName": "El Borma",
            "name": "[EBM] - El Borma - EBM - El Borma - Tunisia",
            "searchName": "EBM-El Borma-Tunisia-Tunézia-Tunísia-Tunus-Tunisko-Tunesien-Tunizija-Tunisas-Tunisija-Tunis-Tuneesia-Tunezja-Tunisie-Túnez",
            "priority": 0
        },
        {
            "iataCode": "TBJ",
            "countryCode": "TN",
            "cityCode": "TBJ",
            "cityName": "Tabarka",
            "name": "[TBJ] - Tabarka - TBJ - Tabarka - Tunisia",
            "searchName": "TBJ-Tabarka-Tunisia-Tunézia-Tunísia-Tunus-Tunisko-Tunesien-Tunizija-Tunisas-Tunisija-Tunis-Tuneesia-Tunezja-Tunisie-Túnez",
            "priority": 0
        },
        {
            "iataCode": "TUN",
            "countryCode": "TN",
            "cityCode": "TUN",
            "cityName": "Tunis",
            "name": "[TUN] - Carthage - TUN - Tunis - Tunisia",
            "searchName": "TUN-Tunis-Carthage-Tunez-Tunisia-Tunézia-Tunísia-Tunus-Tunisko-Tunesien-Tunizija-Tunisas-Tunisija-Tuneesia-Tunezja-Tunisie-Túnez",
            "priority": 0
        },
        {
            "iataCode": "TOE",
            "countryCode": "TN",
            "cityCode": "TOE",
            "cityName": "Tozeur",
            "name": "[TOE] - Tozeur - TOE - Tozeur - Tunisia",
            "searchName": "TOE-Tozeur-Tunisia-Tunézia-Tunísia-Tunus-Tunisko-Tunesien-Tunizija-Tunisas-Tunisija-Tunis-Tuneesia-Tunezja-Tunisie-Túnez",
            "priority": 0
        },
        {
            "iataCode": "SFA",
            "countryCode": "TN",
            "cityCode": "SFA",
            "cityName": "Sfax",
            "name": "[SFA] - Sfax Thyna - SFA - Sfax - Tunisia",
            "searchName": "SFA-Sfax-Sfax Thyna-Sfax El Maou-Tunisia-Tunézia-Tunísia-Tunus-Tunisko-Tunesien-Tunizija-Tunisas-Tunisija-Tunis-Tuneesia-Tunezja-Tunisie-Túnez",
            "priority": 0
        },
        {
            "iataCode": "NBE",
            "countryCode": "TN",
            "cityCode": "NBE",
            "cityName": "Enfidha",
            "name": "[NBE] - Zine El Abidine Ben Ali International Airport - NBE - Enfidha - Tunisia",
            "searchName": "NBE-Enfidha-Zine El Abidine Ben Ali International Airport-Hammamet Int-Dar-el-Bey-Tunisia-Tunézia-Tunísia-Tunus-Tunisko-Tunesien-Tunizija-Tunisas-Tunisija-Tunis-Tuneesia-Tunezja-Tunisie-Túnez",
            "priority": 0
        },
        {
            "iataCode": "NFO",
            "countryCode": "TO",
            "cityCode": "NFO",
            "cityName": "Niuafo ou",
            "name": "[NFO] - Mataaho - NFO - Niuafo ou - Tonga",
            "searchName": "NFO-Mata aho-Mataaho-Niuafo ou-Niuafo Ou-Tonga-Tông ga",
            "priority": 0
        },
        {
            "iataCode": "NTT",
            "countryCode": "TO",
            "cityCode": "NTT",
            "cityName": "Niuatoputapu",
            "name": "[NTT] - Kuini Lavenia - NTT - Niuatoputapu - Tonga",
            "searchName": "NTT-Kuini Lavenia-Niuatoputapu-Tonga-Tông ga",
            "priority": 0
        },
        {
            "iataCode": "TBU",
            "countryCode": "TO",
            "cityCode": "TBU",
            "cityName": "Nuku alofa",
            "name": "[TBU] - Fuaamotu International - TBU - Nuku alofa - Tonga",
            "searchName": "TBU-Nuku Alofa-Fua amotu International-Fua Amotu Intl-Fuaamotu International-Nuku alofa-Alofa-Tonga-Tông ga",
            "priority": 0
        },
        {
            "iataCode": "VAV",
            "countryCode": "TO",
            "cityCode": "VAV",
            "cityName": "Vava u",
            "name": "[VAV] - Lupepauu - VAV - Vava u - Tonga",
            "searchName": "VAV-Lupepau u-Lupepauu-Vava u-Tonga-Tông ga",
            "priority": 0
        },
        {
            "iataCode": "EUA",
            "countryCode": "TO",
            "cityCode": "EUA",
            "cityName": "Eua",
            "name": "[EUA] - Kaufana - EUA - Eua - Tonga",
            "searchName": "EUA-Kaufana-Eua-Tonga-Tông ga",
            "priority": 0
        },
        {
            "iataCode": "HPA",
            "countryCode": "TO",
            "cityCode": "HPA",
            "cityName": "Ha Apai",
            "name": "[HPA] - Salote Pilolevu - HPA - Ha Apai - Tonga",
            "searchName": "HPA-Salote Pilolevu-Ha Apai-Tonga-Tông ga",
            "priority": 0
        },
        {
            "iataCode": "HTY",
            "countryCode": "TR",
            "cityCode": "HTY",
            "cityName": "Hatay",
            "name": "[HTY] - Hatay - HTY - Hatay - Turkey",
            "searchName": "HTY-Hatay-Hatay Airport-Antakya-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "IGD",
            "countryCode": "TR",
            "cityCode": "IGD",
            "cityName": "Igdir",
            "name": "[IGD] - Igdir - IGD - Igdir - Turkey",
            "searchName": "IGD-Igdir-Igdir Airport-Alikamerli-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "HCN",
            "countryCode": "TW",
            "cityCode": "HCN",
            "cityName": "Hengchun",
            "name": "[HCN] - Hengchun - HCN - Hengchun - Taiwan",
            "searchName": "HCN-Hengchun-Hengchun Havalimani-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "KYD",
            "countryCode": "TW",
            "cityCode": "KYD",
            "cityName": "Orchid Island",
            "name": "[KYD] - Orchid Island - KYD - Orchid Island - Taiwan",
            "searchName": "KYD-Orchid Island-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "LHN",
            "countryCode": "TW",
            "cityCode": "LHN",
            "cityName": "Lishan",
            "name": "[LHN] - Lishan - LHN - Lishan - Taiwan",
            "searchName": "LHN-Lishan-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "LZN",
            "countryCode": "TW",
            "cityCode": "LZN",
            "cityName": "Nangan",
            "name": "[LZN] - Nangan - LZN - Nangan - Taiwan",
            "searchName": "LZN-Nangan-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "KNH",
            "countryCode": "TW",
            "cityCode": "KNH",
            "cityName": "Kinmen",
            "name": "[KNH] - Shang-Yi - KNH - Kinmen - Taiwan",
            "searchName": "KNH-Kinmen-Shang-Yi-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "KHH",
            "countryCode": "TW",
            "cityCode": "KHH",
            "cityName": "Kaohsiung",
            "name": "[KHH] - Kaoshiung International Airport - KHH - Kaohsiung - Taiwan",
            "searchName": "KHH-Kaohsiung-Kaoshiung International Airport-Kaohsiung Int. Airport-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "CMJ",
            "countryCode": "TW",
            "cityCode": "CMJ",
            "cityName": "Chi Mei",
            "name": "[CMJ] - Chi Mei - CMJ - Chi Mei - Taiwan",
            "searchName": "CMJ-Chi Mei-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "CYI",
            "countryCode": "TW",
            "cityCode": "CYI",
            "cityName": "Chiayi",
            "name": "[CYI] - Chiayi - CYI - Chiayi - Taiwan",
            "searchName": "CYI-Chiayi-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "TNN",
            "countryCode": "TW",
            "cityCode": "TNN",
            "cityName": "Tainan",
            "name": "[TNN] - Tainan - TNN - Tainan - Taiwan",
            "searchName": "TNN-Tainan-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "AFY",
            "countryCode": "TR",
            "cityCode": "AFY",
            "cityName": "Afyon",
            "name": "[AFY] - Afyon - AFY - Afyon - Turkey",
            "searchName": "AFY-Afyon-Afyon Havalimani-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "ADA",
            "countryCode": "TR",
            "cityCode": "ADA",
            "cityName": "Adana",
            "name": "[ADA] - Adana - ADA - Adana - Turkey",
            "searchName": "ADA-Adana-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "UAB",
            "countryCode": "TR",
            "cityCode": "ADA",
            "cityName": "Adana",
            "name": "[UAB] - Adana-Incirlik Airbase - ADA - Adana - Turkey",
            "searchName": "UAB-ADA-Adana-Incirlik Airbase-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "ADF",
            "countryCode": "TR",
            "cityCode": "ADF",
            "cityName": "Adiyaman",
            "name": "[ADF] - Adiyaman - ADF - Adiyaman - Turkey",
            "searchName": "ADF-Adiyaman-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "AJI",
            "countryCode": "TR",
            "cityCode": "AJI",
            "cityName": "Agri",
            "name": "[AJI] - Agri - AJI - Agri - Turkey",
            "searchName": "AJI-Agri-Agri.-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "BDM",
            "countryCode": "TR",
            "cityCode": "BDM",
            "cityName": "Bandirma",
            "name": "[BDM] - Bandirma - BDM - Bandirma - Turkey",
            "searchName": "BDM-Bandirma-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "BGG",
            "countryCode": "TR",
            "cityCode": "BGG",
            "cityName": "Bongouanou",
            "name": "[BGG] - Bongouanou - BGG - Bongouanou - Turkey",
            "searchName": "BGG-Bongouanou-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "BXN",
            "countryCode": "TR",
            "cityCode": "BJV",
            "cityName": "Milas",
            "name": "[BXN] - Imsik - BJV - Milas - Turkey",
            "searchName": "BXN-BJV-Imsik-Bodrum-Milas-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "BZI",
            "countryCode": "TR",
            "cityCode": "BZI",
            "cityName": "Balikesir",
            "name": "[BZI] - Balikesir - BZI - Balikesir - Turkey",
            "searchName": "BZI-Balikesir-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "VAS",
            "countryCode": "TR",
            "cityCode": "VAS",
            "cityName": "Sivas",
            "name": "[VAS] - Sivas - VAS - Sivas - Turkey",
            "searchName": "VAS-Sivas-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "USQ",
            "countryCode": "TR",
            "cityCode": "USQ",
            "cityName": "Usak",
            "name": "[USQ] - Usak - USQ - Usak - Turkey",
            "searchName": "USQ-Usak-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "VAN",
            "countryCode": "TR",
            "cityCode": "VAN",
            "cityName": "Van",
            "name": "[VAN] - Ferit Melen Airport - VAN - Van - Turkey",
            "searchName": "VAN-Van Türkei-Ferit Melen Airport-Van-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "TZX",
            "countryCode": "TR",
            "cityCode": "TZX",
            "cityName": "Trabzon",
            "name": "[TZX] - Trabzon - TZX - Trabzon - Turkey",
            "searchName": "TZX-Trabzon-Trapisonda-Trebisonda-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "XHQ",
            "countryCode": "TR",
            "cityCode": "XHQ",
            "cityName": "Artvin",
            "name": "[XHQ] - Hopa Bus Station - XHQ - Artvin - Turkey",
            "searchName": "XHQ-Hopa Bus Station-Artvin-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "YEI",
            "countryCode": "TR",
            "cityCode": "YEI",
            "cityName": "Bursa",
            "name": "[YEI] - Yenisehir - YEI - Bursa - Turkey",
            "searchName": "YEI-Yenisehir-Bursa-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "SXZ",
            "countryCode": "TR",
            "cityCode": "SXZ",
            "cityName": "Siirt",
            "name": "[SXZ] - Siirt - SXZ - Siirt - Turkey",
            "searchName": "SXZ-Siirt-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "SZF",
            "countryCode": "TR",
            "cityCode": "SZF",
            "cityName": "Samsun",
            "name": "[SZF] - Carsamba - SZF - Samsun - Turkey",
            "searchName": "SZF-Samsun Carsamba-Carsamba-Carsamba Airport-Samsun-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "TJK",
            "countryCode": "TR",
            "cityCode": "TJK",
            "cityName": "Tokat",
            "name": "[TJK] - Tokat - TJK - Tokat - Turkey",
            "searchName": "TJK-Tokat-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "TEQ",
            "countryCode": "TR",
            "cityCode": "TEQ",
            "cityName": "Tekirdag",
            "name": "[TEQ] - Corlu - TEQ - Tekirdag - Turkey",
            "searchName": "TEQ-Corlu-Tekirdag-Tekirda-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "SFQ",
            "countryCode": "TR",
            "cityCode": "SFQ",
            "cityName": "Sanliurfa",
            "name": "[SFQ] - Sanliurfa - SFQ - Sanliurfa - Turkey",
            "searchName": "SFQ-Sanliurfa-Sanli Urfa Havalimani-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "GNY",
            "countryCode": "TR",
            "cityCode": "SFQ",
            "cityName": "Sanliurfa",
            "name": "[GNY] - Guney Anadolu Projesi - SFQ - Sanliurfa - Turkey",
            "searchName": "GNY-SFQ-Sanliurfa-Guney Anadolu Projesi-GAP International-Guney Anadolu Airport-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "NOP",
            "countryCode": "TR",
            "cityCode": "NOP",
            "cityName": "Sinop",
            "name": "[NOP] - Sinop - NOP - Sinop - Turkey",
            "searchName": "NOP-Sinop-Aeropuerto de Sinope-Sinop Airport-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "NAV",
            "countryCode": "TR",
            "cityCode": "NAV",
            "cityName": "Nevsehir",
            "name": "[NAV] - Nevsehir - NAV - Nevsehir - Turkey",
            "searchName": "NAV-Nevsehir-Nevsehir Havalimani-Nev-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "MZH",
            "countryCode": "TR",
            "cityCode": "MZH",
            "cityName": "Merzifon",
            "name": "[MZH] - Merzifon - MZH - Merzifon - Turkey",
            "searchName": "MZH-Merzifon-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "MLX",
            "countryCode": "TR",
            "cityCode": "MLX",
            "cityName": "Malatya",
            "name": "[MLX] - Malatya - MLX - Malatya - Turkey",
            "searchName": "MLX-Malatya-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "MQM",
            "countryCode": "TR",
            "cityCode": "MQM",
            "cityName": "Mardin",
            "name": "[MQM] - Mardin - MQM - Mardin - Turkey",
            "searchName": "MQM-Mardin-Mardin Airport-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "MSR",
            "countryCode": "TR",
            "cityCode": "MSR",
            "cityName": "Mus",
            "name": "[MSR] - Mus - MSR - Mus - Turkey",
            "searchName": "MSR-Mus Türkei-Mus-Mu-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "ONQ",
            "countryCode": "TR",
            "cityCode": "ONQ",
            "cityName": "Zonguldak",
            "name": "[ONQ] - Zonguldak - ONQ - Zonguldak - Turkey",
            "searchName": "ONQ-Zonguldak-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "POS",
            "countryCode": "TT",
            "cityCode": "POS",
            "cityName": "Port Of Spain",
            "name": "[POS] - Piarco International - POS - Port Of Spain - Trinidad and Tobago",
            "searchName": "POS-Port of Spanien-Piarco International-Piarco-Port Of Spain-Port of Spain-Trinidad und Tobago-Trinidad and Tobago-Trinité-et-Tobago-Trinidad y Tobago-Trinidad e Tobago",
            "priority": 0
        },
        {
            "iataCode": "TAB",
            "countryCode": "TT",
            "cityCode": "TAB",
            "cityName": "Tobago",
            "name": "[TAB] - Crown Point Airport - TAB - Tobago - Trinidad and Tobago",
            "searchName": "TAB-Tobago-Crown Point Airport-ANR Robinson Intl-Crown Point-Trinidad und Tobago-Trinidad and Tobago-Trinité-et-Trinidad y Tobago-Trinidad e Tobago",
            "priority": 0
        },
        {
            "iataCode": "FUN",
            "countryCode": "TV",
            "cityCode": "FUN",
            "cityName": "Funafuti Atoll",
            "name": "[FUN] - International - FUN - Funafuti Atoll - Tuvalu",
            "searchName": "FUN-Funafuti-International-Funafuti Atol-Funafuti Atoll-Tuvalu",
            "priority": 0
        },
        {
            "iataCode": "GNI",
            "countryCode": "TW",
            "cityCode": "GNI",
            "cityName": "Green Island",
            "name": "[GNI] - Green Island - GNI - Green Island - Taiwan",
            "searchName": "GNI-Green Island-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "HSZ",
            "countryCode": "TW",
            "cityCode": "HSZ",
            "cityName": "Hsinchu",
            "name": "[HSZ] - Hsinchu - HSZ - Hsinchu - Taiwan",
            "searchName": "HSZ-Hsinchu-Hsinchun Havalimani-Hsinchun-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "URT",
            "countryCode": "TH",
            "cityCode": "URT",
            "cityName": "Surat Thani",
            "name": "[URT] - Surat Thani - URT - Surat Thani - Thailand",
            "searchName": "URT-Surat Thani-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "UTH",
            "countryCode": "TH",
            "cityCode": "UTH",
            "cityName": "Udon Thani",
            "name": "[UTH] - Udon Thani - UTH - Udon Thani - Thailand",
            "searchName": "UTH-Udon Thani-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "UTP",
            "countryCode": "TH",
            "cityCode": "UTP",
            "cityName": "Pattaya",
            "name": "[UTP] - Utapao - UTP - Pattaya - Thailand",
            "searchName": "UTP-Utapao-Pattaya-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "UTR",
            "countryCode": "TH",
            "cityCode": "UTR",
            "cityName": "Uttaradit",
            "name": "[UTR] - Uttaradit - UTR - Uttaradit - Thailand",
            "searchName": "UTR-Uttaradit-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "TJU",
            "countryCode": "TJ",
            "cityCode": "TJU",
            "cityName": "Kulyab",
            "name": "[TJU] - Kulyab - TJU - Kulyab - Tajikistan",
            "searchName": "TJU-Kulyab-Kulob Airport-Kulob-Kulob\\/Kulyab-Tadschikistan-Tajikistan-Tacikistan-Tadjikistan-Tayikistán-Tagikistan",
            "priority": 0
        },
        {
            "iataCode": "DYU",
            "countryCode": "TJ",
            "cityCode": "DYU",
            "cityName": "Dushanbe",
            "name": "[DYU] - Dushanbe - DYU - Dushanbe - Tajikistan",
            "searchName": "DYU-Dushanbe-dyu-Tadschikistan-Tajikistan-Tacikistan-Tadjikistan-Tayikistán-Tagikistan",
            "priority": 0
        },
        {
            "iataCode": "KQT",
            "countryCode": "TJ",
            "cityCode": "KQT",
            "cityName": "Qurghonteppa",
            "name": "[KQT] - Kurgan Tube - KQT - Qurghonteppa - Tajikistan",
            "searchName": "KQT-Qurghonteppa-Kurgan Tube-Kurgon-Tyube-Tadschikistan-Tajikistan-Tacikistan-Tadjikistan-Tayikistán-Tagikistan",
            "priority": 0
        },
        {
            "iataCode": "LBD",
            "countryCode": "TJ",
            "cityCode": "LBD",
            "cityName": "Khudzhand",
            "name": "[LBD] - Khudzhand - LBD - Khudzhand - Tajikistan",
            "searchName": "LBD-Khudzhand-Chudschand-Khodjent-Khujand-Tadschikistan-Tajikistan-Tacikistan-Tadjikistan-Tayikistán-Tagikistan",
            "priority": 0
        },
        {
            "iataCode": "KOE",
            "countryCode": "TL",
            "cityCode": "KOE",
            "cityName": "Kupang",
            "name": "[KOE] - Eltari - KOE - Kupang - East Timor",
            "searchName": "KOE-Eltari-Kupang-Osttimor-East Timor-Dogu Timor-Timor oriental-Timor Oriental-Timor Est",
            "priority": 0
        },
        {
            "iataCode": "DIL",
            "countryCode": "TL",
            "cityCode": "DIL",
            "cityName": "Dili",
            "name": "[DIL] - Presidente Nicolau Lobato International - DIL - Dili - East Timor",
            "searchName": "DIL-Dili Ost Timor-Presidente Nicolau Lobato International-Comoro-Dili-Osttimor-East Timor-Dogu Timor-Timor oriental-Timor Oriental-Timor Est",
            "priority": 0
        },
        {
            "iataCode": "BCH",
            "countryCode": "TL",
            "cityCode": "BCH",
            "cityName": "Baucau",
            "name": "[BCH] - Cakung International - BCH - Baucau - East Timor",
            "searchName": "BCH-English Madeira-Cakung International-English Madeira Havalimani-Baucau-Osttimor-East Timor-Dogu Timor-Timor oriental-Timor Oriental-Timor Est",
            "priority": 0
        },
        {
            "iataCode": "VIQ",
            "countryCode": "TL",
            "cityCode": "VIQ",
            "cityName": "Viqueque",
            "name": "[VIQ] - Viqueque - VIQ - Viqueque - East Timor",
            "searchName": "VIQ-Viqueque-Osttimor-East Timor-Dogu Timor-Timor oriental-Timor Oriental-Timor Est",
            "priority": 0
        },
        {
            "iataCode": "UAI",
            "countryCode": "TL",
            "cityCode": "UAI",
            "cityName": "Suai",
            "name": "[UAI] - Suai - UAI - Suai - East Timor",
            "searchName": "UAI-Suai-Osttimor-East Timor-Dogu Timor-Timor oriental-Timor Oriental-Timor Est",
            "priority": 0
        },
        {
            "iataCode": "OEC",
            "countryCode": "TL",
            "cityCode": "OEC",
            "cityName": "Ocussi",
            "name": "[OEC] - Ocussi - OEC - Ocussi - East Timor",
            "searchName": "OEC-Ocussi-Osttimor-East Timor-Dogu Timor-Timor oriental-Timor Oriental-Timor Est",
            "priority": 0
        },
        {
            "iataCode": "MPT",
            "countryCode": "TL",
            "cityCode": "MPT",
            "cityName": "Maliana",
            "name": "[MPT] - Maliana - MPT - Maliana - East Timor",
            "searchName": "MPT-Maliana-Osttimor-East Timor-Dogu Timor-Timor oriental-Timor Oriental-Timor Est",
            "priority": 0
        },
        {
            "iataCode": "MYP",
            "countryCode": "TM",
            "cityCode": "MYP",
            "cityName": "Mary",
            "name": "[MYP] - Mary - MYP - Mary - Turkmenistan",
            "searchName": "MYP-Mary-Mary Havalimani-Turkmenistan-Turkménistan-Turkmenistán",
            "priority": 0
        },
        {
            "iataCode": "TAZ",
            "countryCode": "TM",
            "cityCode": "TAZ",
            "cityName": "Dashoguz",
            "name": "[TAZ] - Dashoguz - TAZ - Dashoguz - Turkmenistan",
            "searchName": "TAZ-Dashoguz-Dasoguz Havalimani-Turkmenistan-Turkménistan-Turkmenistán",
            "priority": 0
        },
        {
            "iataCode": "HHQ",
            "countryCode": "TH",
            "cityCode": "HHQ",
            "cityName": "Hua Hin",
            "name": "[HHQ] - Hua Hin Airport - HHQ - Hua Hin - Thailand",
            "searchName": "HHQ-Hua Hin Airport-Hua Hin-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "SOO",
            "countryCode": "SE",
            "cityCode": "SOO",
            "cityName": "Soderhamn",
            "name": "[SOO] - Soderhamn - SOO - Soderhamn - Sweden",
            "searchName": "SOO-Soderhamn-S-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "POW",
            "countryCode": "SI",
            "cityCode": "POW",
            "cityName": "Portoroz",
            "name": "[POW] - Portoroz - POW - Portoroz - Slovenia",
            "searchName": "POW-Portoroz-Portoroz Havalimani-Slovenia-Slovénie-Slovenien-Slovinsko-Slovenya-Eslovènia-Szlovénia-Slovenija-Sloveenia--Slowenien-Eslovenia",
            "priority": 0
        },
        {
            "iataCode": "MBX",
            "countryCode": "SI",
            "cityCode": "MBX",
            "cityName": "Maribor",
            "name": "[MBX] - Maribor International Airport - MBX - Maribor - Slovenia",
            "searchName": "MBX-Maribor International Airport-Maribor-Slovenia-Slovénie-Slovenien-Slovinsko-Slovenya-Eslovènia-Szlovénia-Slovenija-Sloveenia--Slowenien-Eslovenia",
            "priority": 0
        },
        {
            "iataCode": "LYR",
            "countryCode": "SJ",
            "cityCode": "LYR",
            "cityName": "Longyearbyen",
            "name": "[LYR] - Svalbard - LYR - Longyearbyen - Svalbard and Jan Mayen",
            "searchName": "LYR-Longyearbyen-Svalbard-Svalbard and Jan Mayen-Svalbard und Jan-Mayen-Inseln-Svalbard & Jan Mayen Island-Svalbard y Jan Mayen-Svalbard et Île Jan Mayen-Svalbard e Jan Mayen",
            "priority": 0
        },
        {
            "iataCode": "LUE",
            "countryCode": "SK",
            "cityCode": "LUE",
            "cityName": "Lucenec",
            "name": "[LUE] - Lucenec - LUE - Lucenec - Slovakia",
            "searchName": "LUE-Lucenec-Slowakia-Slowakei-Slovakia-Slovacka-Slovaquie-Slovensko-Slovakya-Eslovàquia-Szlovákia-Slovacia-Slovaška-Slovakija-Slovakkia-Slowacja-S-Eslovaquia-Slovacchia",
            "priority": 0
        },
        {
            "iataCode": "KSC",
            "countryCode": "SK",
            "cityCode": "KSC",
            "cityName": "Kosice",
            "name": "[KSC] - Barca - KSC - Kosice - Slovakia",
            "searchName": "KSC-Kosice-Barca-Barca Airport-Košice-Ko-Slowakia-Slowakei-Slovakia-Slovacka-Slovaquie-Slovensko-Slovakya-Eslovàquia-Szlovákia-Slovacia-Slovaška-Slovakija-Slovakkia-Slowacja-S-Eslovaquia-Slovacchia",
            "priority": 0
        },
        {
            "iataCode": "ILZ",
            "countryCode": "SK",
            "cityCode": "ILZ",
            "cityName": "Zilina",
            "name": "[ILZ] - Zilina - ILZ - Zilina - Slovakia",
            "searchName": "ILZ-Zilina-Zilina D. Hricov-Zilina Havalimani-Žilina-Slowakia-Slowakei-Slovakia-Slovacka-Slovaquie-Slovensko-Slovakya-Eslovàquia-Szlovákia-Slovacia-Slovaška-Slovakija-Slovakkia-Slowacja-S-Eslovaquia-Slovacchia",
            "priority": 0
        },
        {
            "iataCode": "SXM",
            "countryCode": "SX",
            "cityCode": "SXM",
            "cityName": "Philipsburg",
            "name": "[SXM] - Princess Juliana International - SXM - Philipsburg - Sint Maarten",
            "searchName": "SXM-Saint Maarten-Princess Juliana International-Princess Juliana-Simpson Bay-Philipsburg-Saint Martin-Saint-Martin-Sint Maarten (Netherlands)-Sint Maarten-Hollanda Antilleri-Martin (partie néerlandaise)",
            "priority": 0
        },
        {
            "iataCode": "SOR",
            "countryCode": "SY",
            "cityCode": "SOR",
            "cityName": "Al Thaurah",
            "name": "[SOR] - Al Thaurah - SOR - Al Thaurah - Syria",
            "searchName": "SOR-Al Thaurah-Syrien-Syria-Syrie-Siria",
            "priority": 0
        },
        {
            "iataCode": "PMS",
            "countryCode": "SY",
            "cityCode": "PMS",
            "cityName": "Palmyra",
            "name": "[PMS] - Palmyra - PMS - Palmyra - Syria",
            "searchName": "PMS-Palmyra-Palmyra Havalimani-Palmira-Syrien-Syria-Syrie-Siria",
            "priority": 0
        },
        {
            "iataCode": "KAC",
            "countryCode": "SY",
            "cityCode": "KAC",
            "cityName": "Kameshli",
            "name": "[KAC] - Kameshli - KAC - Kameshli - Syria",
            "searchName": "KAC-Kameshli-Kahmishli-Syrien-Syria-Syrie-Siria",
            "priority": 0
        },
        {
            "iataCode": "LTK",
            "countryCode": "SY",
            "cityCode": "LTK",
            "cityName": "Latakia",
            "name": "[LTK] - Latakia - LTK - Latakia - Syria",
            "searchName": "LTK-Latakia-Lattaquié-Syrien-Syria-Syrie-Siria",
            "priority": 0
        },
        {
            "iataCode": "ALP",
            "countryCode": "SY",
            "cityCode": "ALP",
            "cityName": "Aleppo",
            "name": "[ALP] - Nejrab - ALP - Aleppo - Syria",
            "searchName": "ALP-Aleppo-Nejrab-Syrien-Syria-Syrie-Siria",
            "priority": 0
        },
        {
            "iataCode": "DEZ",
            "countryCode": "SY",
            "cityCode": "DEZ",
            "cityName": "Deirezzor",
            "name": "[DEZ] - Al Jafrah - DEZ - Deirezzor - Syria",
            "searchName": "DEZ-Al Jafrah-Deirezzor-Syrien-Syria-Syrie-Siria",
            "priority": 0
        },
        {
            "iataCode": "BFV",
            "countryCode": "TH",
            "cityCode": "BFV",
            "cityName": "Buri Ram",
            "name": "[BFV] - Buri Ram - BFV - Buri Ram - Thailand",
            "searchName": "BFV-Buri Ram-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "BAO",
            "countryCode": "TH",
            "cityCode": "BAO",
            "cityName": "Ban Mak Khaen",
            "name": "[BAO] - Udorn - BAO - Ban Mak Khaen - Thailand",
            "searchName": "BAO-Udorn-Udonthani Uluslararasi Havalimani-Mueang Udon Thani\\/ Ban Mak Khaeng-Ban Mak Khaen-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "CNX",
            "countryCode": "TH",
            "cityCode": "CNX",
            "cityName": "Chiang Mai",
            "name": "[CNX] - Chiang Mai International - CNX - Chiang Mai - Thailand",
            "searchName": "CNX-Chiang Mai-Chiang Mai International-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "CJM",
            "countryCode": "TH",
            "cityCode": "CJM",
            "cityName": "Chumphon",
            "name": "[CJM] - Chumphon Airport - CJM - Chumphon - Thailand",
            "searchName": "CJM-Chumphon Airport-Chumphon Havalimani-Chumphon-Chumpon-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "CEI",
            "countryCode": "TH",
            "cityCode": "CEI",
            "cityName": "Chiang Rai",
            "name": "[CEI] - Chiang Rai - CEI - Chiang Rai - Thailand",
            "searchName": "CEI-Chiang Rai-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "MAQ",
            "countryCode": "TH",
            "cityCode": "MAQ",
            "cityName": "Mae Sot",
            "name": "[MAQ] - Mae Sot - MAQ - Mae Sot - Thailand",
            "searchName": "MAQ-Mae Sot-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "NNT",
            "countryCode": "TH",
            "cityCode": "NNT",
            "cityName": "Nan",
            "name": "[NNT] - Nan - NNT - Nan - Thailand",
            "searchName": "NNT-Nan-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "NST",
            "countryCode": "TH",
            "cityCode": "NST",
            "cityName": "Nakhon Si Thammarat",
            "name": "[NST] - Nakhon Si Thammarat - NST - Nakhon Si Thammarat - Thailand",
            "searchName": "NST-Nakhon Si Thammarat-Nakhon Si Tammarat-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "NAW",
            "countryCode": "TH",
            "cityCode": "NAW",
            "cityName": "Narathiwat",
            "name": "[NAW] - Narathiwat - NAW - Narathiwat - Thailand",
            "searchName": "NAW-Narathiwat-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "NAK",
            "countryCode": "TH",
            "cityCode": "NAK",
            "cityName": "Nakhon Ratchasima",
            "name": "[NAK] - Nakhon Ratchasima - NAK - Nakhon Ratchasima - Thailand",
            "searchName": "NAK-Nakhon Ratchasima-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "PAN",
            "countryCode": "TH",
            "cityCode": "PAN",
            "cityName": "Pattani",
            "name": "[PAN] - Pattani - PAN - Pattani - Thailand",
            "searchName": "PAN-Pattani-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "PBS",
            "countryCode": "TH",
            "cityCode": "PBS",
            "cityName": "Patong Beach",
            "name": "[PBS] - Patong Beach - PBS - Patong Beach - Thailand",
            "searchName": "PBS-Patong Beach-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "PMM",
            "countryCode": "TH",
            "cityCode": "PMM",
            "cityName": "Phanom Sarakham",
            "name": "[PMM] - Phanom Sarakham - PMM - Phanom Sarakham - Thailand",
            "searchName": "PMM-Phanom Sarakham-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "PRH",
            "countryCode": "TH",
            "cityCode": "PRH",
            "cityName": "Phrae",
            "name": "[PRH] - Phrae - PRH - Phrae - Thailand",
            "searchName": "PRH-Phrae-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "PHY",
            "countryCode": "TH",
            "cityCode": "PHY",
            "cityName": "Phetchabun",
            "name": "[PHY] - Phetchabun - PHY - Phetchabun - Thailand",
            "searchName": "PHY-Phetchabun-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "PHZ",
            "countryCode": "TH",
            "cityCode": "PHZ",
            "cityName": "Phi Phi Island",
            "name": "[PHZ] - Phi Phi Island - PHZ - Phi Phi Island - Thailand",
            "searchName": "PHZ-Phi Phi Island-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "PHS",
            "countryCode": "TH",
            "cityCode": "PHS",
            "cityName": "Phitsanulok",
            "name": "[PHS] - Phitsanulok - PHS - Phitsanulok - Thailand",
            "searchName": "PHS-Phitsanulok-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "PYX",
            "countryCode": "TH",
            "cityCode": "PYX",
            "cityName": "Pattaya",
            "name": "[PYX] - Pattaya - PYX - Pattaya - Thailand",
            "searchName": "PYX-Pattaya-Pattaya Havalimani-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "PYY",
            "countryCode": "TH",
            "cityCode": "PYY",
            "cityName": "Pai",
            "name": "[PYY] - Pai - PYY - Pai - Thailand",
            "searchName": "PYY-Pai-Pai (Mae Hong Son)-Pai Havalimani-Pai City-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "PXR",
            "countryCode": "TH",
            "cityCode": "PXR",
            "cityName": "Surin",
            "name": "[PXR] - Surin - PXR - Surin - Thailand",
            "searchName": "PXR-Surin-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "ROI",
            "countryCode": "TH",
            "cityCode": "ROI",
            "cityName": "Roi Et",
            "name": "[ROI] - Roi Et Airport - ROI - Roi Et - Thailand",
            "searchName": "ROI-Roi Et Airport-Roi Et Havalimani-Roi Et-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "SNO",
            "countryCode": "TH",
            "cityCode": "SNO",
            "cityName": "Sakon Nakhon",
            "name": "[SNO] - Sakon Nakhon - SNO - Sakon Nakhon - Thailand",
            "searchName": "SNO-Sakon Nakhon-Sakhon Nakhon-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "SGZ",
            "countryCode": "TH",
            "cityCode": "SGZ",
            "cityName": "Songkhla",
            "name": "[SGZ] - Songkhla - SGZ - Songkhla - Thailand",
            "searchName": "SGZ-Songkhla-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "THS",
            "countryCode": "TH",
            "cityCode": "THS",
            "cityName": "Sukhothai (sawankolok)",
            "name": "[THS] - Sukhothai - THS - Sukhothai (sawankolok) - Thailand",
            "searchName": "THS-Sukhothai-Sukhothai (sawankolok)-Sukhotthai-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "TKH",
            "countryCode": "TH",
            "cityCode": "TKH",
            "cityName": "Takhli",
            "name": "[TKH] - Nakhon Sawan - TKH - Takhli - Thailand",
            "searchName": "TKH-Nakhon Sawan-Takhli-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "TDX",
            "countryCode": "TH",
            "cityCode": "TDX",
            "cityName": "Trat",
            "name": "[TDX] - Trat - TDX - Trat - Thailand",
            "searchName": "TDX-Trat-Trat Airport-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "TKT",
            "countryCode": "TH",
            "cityCode": "TKT",
            "cityName": "Tak",
            "name": "[TKT] - Tak - TKT - Tak - Thailand",
            "searchName": "TKT-Tak-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "TST",
            "countryCode": "TH",
            "cityCode": "TST",
            "cityName": "Trang",
            "name": "[TST] - Trang - TST - Trang - Thailand",
            "searchName": "TST-Trang-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "UBP",
            "countryCode": "TH",
            "cityCode": "UBP",
            "cityName": "Ubon Ratchathni",
            "name": "[UBP] - Muang Ubon - UBP - Ubon Ratchathni - Thailand",
            "searchName": "UBP-Ubon Ratchathni-Muang Ubon-Ubon Ratchathani-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "UNN",
            "countryCode": "TH",
            "cityCode": "UNN",
            "cityName": "Ranong",
            "name": "[UNN] - Ranong - UNN - Ranong - Thailand",
            "searchName": "UNN-Ranong-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "ANK",
            "countryCode": "TR",
            "cityCode": "ANK",
            "cityName": "Ankara",
            "name": "[ANK] - Etimesgut - ANK - Ankara - Turkey",
            "searchName": "ANK-Etimesgut-Etimesgut Havaalani-Ankara-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "BAL",
            "countryCode": "TR",
            "cityCode": "BAL",
            "cityName": "Batman",
            "name": "[BAL] - Batman - BAL - Batman - Turkey",
            "searchName": "BAL-Batman-Türkiye-Turquía-Türkei-Turecko-Turquia-Törökország-Turcia-Turcija-Turkija-Turska-Türgi-Turquie-Turkki-Tyrkiet-Tu-Turkey-Turchia",
            "priority": 0
        },
        {
            "iataCode": "KWG",
            "countryCode": "UA",
            "cityCode": "KWG",
            "cityName": "Krivoy Rog",
            "name": "[KWG] - Krivoy Rog - KWG - Krivoy Rog - Ukraine",
            "searchName": "KWG-Krywyj Rih-Krivoy Rog-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "HMJ",
            "countryCode": "UA",
            "cityCode": "HMJ",
            "cityName": "Khmelnitskiy",
            "name": "[HMJ] - Khmelnitskiy - HMJ - Khmelnitskiy - Ukraine",
            "searchName": "HMJ-Khmelnitskiy-Chmelnyzkyj-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "ERD",
            "countryCode": "UA",
            "cityCode": "ERD",
            "cityName": "Berdyansk",
            "name": "[ERD] - Berdyansk - ERD - Berdyansk - Ukraine",
            "searchName": "ERD-Berdyansk-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "CWC",
            "countryCode": "UA",
            "cityCode": "CWC",
            "cityName": "Chernovtsy",
            "name": "[CWC] - Chernovtsy - CWC - Chernovtsy - Ukraine",
            "searchName": "CWC-Czernowitz-Chernovtsy-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "CKC",
            "countryCode": "UA",
            "cityCode": "CKC",
            "cityName": "Cherkassy",
            "name": "[CKC] - Cherkassy - CKC - Cherkassy - Ukraine",
            "searchName": "CKC-Cherkassy-Tscherkassy-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "TNL",
            "countryCode": "UA",
            "cityCode": "TNL",
            "cityName": "Ternopol",
            "name": "[TNL] - Ternopol - TNL - Ternopol - Ukraine",
            "searchName": "TNL-Ternopol-Ternopil-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "KCP",
            "countryCode": "UA",
            "cityCode": "KCP",
            "cityName": "Kamenets-podolskiy",
            "name": "[KCP] - Kamenets-podolskiy - KCP - Kamenets-podolskiy - Ukraine",
            "searchName": "KCP-Podilskyj - Flughafen-Kamenets-podolskiy-Kamenets Podolski Havalimani-Kamjanez-Kamyanets Podilski-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "KHU",
            "countryCode": "UA",
            "cityCode": "KHU",
            "cityName": "Kremenchug",
            "name": "[KHU] - Kremenchug - KHU - Kremenchug - Ukraine",
            "searchName": "KHU-Kremenchug-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "KHE",
            "countryCode": "UA",
            "cityCode": "KHE",
            "cityName": "Kherson",
            "name": "[KHE] - Kherson - KHE - Kherson - Ukraine",
            "searchName": "KHE-Kherson-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "KHC",
            "countryCode": "UA",
            "cityCode": "KHC",
            "cityName": "Kerch",
            "name": "[KHC] - Kerch - KHC - Kerch - Ukraine",
            "searchName": "KHC-Kerch-Kertsch-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "KGO",
            "countryCode": "UA",
            "cityCode": "KGO",
            "cityName": "Kirovograd",
            "name": "[KGO] - Kirovograd - KGO - Kirovograd - Ukraine",
            "searchName": "KGO-Kirovograd-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "KRQ",
            "countryCode": "UA",
            "cityCode": "KRQ",
            "cityName": "Kramatorsk",
            "name": "[KRQ] - Kramatorsk - KRQ - Kramatorsk - Ukraine",
            "searchName": "KRQ-Kramatorsk-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "UCK",
            "countryCode": "UA",
            "cityCode": "UCK",
            "cityName": "Lutsk",
            "name": "[UCK] - Lutsk - UCK - Lutsk - Ukraine",
            "searchName": "UCK-Lutsk-Lutsk Havalimani-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "UDJ",
            "countryCode": "UA",
            "cityCode": "UDJ",
            "cityName": "Uzhgorod",
            "name": "[UDJ] - Uzhgorod - UDJ - Uzhgorod - Ukraine",
            "searchName": "UDJ-Uschhorod-Uzhgorod-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "UMY",
            "countryCode": "UA",
            "cityCode": "UMY",
            "cityName": "Sumy",
            "name": "[UMY] - Sumy - UMY - Sumy - Ukraine",
            "searchName": "UMY-Sumy-Sumy Havalimani-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "UKS",
            "countryCode": "UA",
            "cityCode": "UKS",
            "cityName": "Sevastopol",
            "name": "[UKS] - Belbek - UKS - Sevastopol - Ukraine",
            "searchName": "UKS-Belbek-Sevastopol-Sébastopol-Sebastopoli-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "VIN",
            "countryCode": "UA",
            "cityCode": "VIN",
            "cityName": "Vinnica",
            "name": "[VIN] - Vinnica - VIN - Vinnica - Ukraine",
            "searchName": "VIN-Vinnica-Vinnitsa Havalimani-Winnyzja-Vinnitsya-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "PLV",
            "countryCode": "UA",
            "cityCode": "PLV",
            "cityName": "Poltava",
            "name": "[PLV] - Poltava - PLV - Poltava - Ukraine",
            "searchName": "PLV-Poltava-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "OZH",
            "countryCode": "UA",
            "cityCode": "OZH",
            "cityName": "Zaporozhye",
            "name": "[OZH] - Zaporozhye - OZH - Zaporozhye - Ukraine",
            "searchName": "OZH-Saporischschja-Zaporozhye-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "RWN",
            "countryCode": "UA",
            "cityCode": "RWN",
            "cityName": "Rovno",
            "name": "[RWN] - Rovno - RWN - Rovno - Ukraine",
            "searchName": "RWN-Rovno-Rovno Havalimani-Riwne-Rivne-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "MPW",
            "countryCode": "UA",
            "cityCode": "MPW",
            "cityName": "Mariupol",
            "name": "[MPW] - Mariupol - MPW - Mariupol - Ukraine",
            "searchName": "MPW-Mariupol-Marioupol-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "NLV",
            "countryCode": "UA",
            "cityCode": "NLV",
            "cityName": "Nikolaev",
            "name": "[NLV] - Nikolaev (Mykolaiv) - NLV - Nikolaev - Ukraine",
            "searchName": "NLV-Mykolayiv-Nikolaev (Mykolaiv)-Nikolaev-Nikolaev\\/Mykolaiv-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "MXR",
            "countryCode": "UA",
            "cityCode": "MXR",
            "cityName": "Mirgorod",
            "name": "[MXR] - Mirgorod - MXR - Mirgorod - Ukraine",
            "searchName": "MXR-Mirgorod-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "MBQ",
            "countryCode": "UG",
            "cityCode": "MBQ",
            "cityName": "Mbarara",
            "name": "[MBQ] - Mbarara - MBQ - Mbarara - Uganda",
            "searchName": "MBQ-Mbarara-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "RUA",
            "countryCode": "UG",
            "cityCode": "RUA",
            "cityName": "Arua",
            "name": "[RUA] - Arua - RUA - Arua - Uganda",
            "searchName": "RUA-Arua-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "OYG",
            "countryCode": "UG",
            "cityCode": "OYG",
            "cityName": "Moyo",
            "name": "[OYG] - Moyo - OYG - Moyo - Uganda",
            "searchName": "OYG-Moyo-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "PAF",
            "countryCode": "UG",
            "cityCode": "PAF",
            "cityName": "Pakuba",
            "name": "[PAF] - Pakuba - PAF - Pakuba - Uganda",
            "searchName": "PAF-Pakuba-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "ULU",
            "countryCode": "UG",
            "cityCode": "ULU",
            "cityName": "Gulu",
            "name": "[ULU] - Gulu - ULU - Gulu - Uganda",
            "searchName": "ULU-Gulu-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "SRT",
            "countryCode": "UG",
            "cityCode": "SRT",
            "cityName": "Soroti",
            "name": "[SRT] - Soroti - SRT - Soroti - Uganda",
            "searchName": "SRT-Soroti-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "TRY",
            "countryCode": "UG",
            "cityCode": "TRY",
            "cityName": "Tororo",
            "name": "[TRY] - Tororo - TRY - Tororo - Uganda",
            "searchName": "TRY-Tororo-Ouganda-Uganda",
            "priority": 0
        },
        {
            "iataCode": "TXG",
            "countryCode": "TW",
            "cityCode": "TXG",
            "cityName": "Taichung",
            "name": "[TXG] - Taichung - TXG - Taichung - Taiwan",
            "searchName": "TXG-Taichung-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "TTT",
            "countryCode": "TW",
            "cityCode": "TTT",
            "cityName": "Taitung",
            "name": "[TTT] - Taitung - TTT - Taitung - Taiwan",
            "searchName": "TTT-Taitung-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "SMT",
            "countryCode": "TW",
            "cityCode": "SMT",
            "cityName": "Sun Moon Lake",
            "name": "[SMT] - Sun Moon Lake - SMT - Sun Moon Lake - Taiwan",
            "searchName": "SMT-Sun Moon Lake-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "WOT",
            "countryCode": "TW",
            "cityCode": "WOT",
            "cityName": "Wonan",
            "name": "[WOT] - Wonan - WOT - Wonan - Taiwan",
            "searchName": "WOT-Wonan-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "PIF",
            "countryCode": "TW",
            "cityCode": "PIF",
            "cityName": "Pingtung",
            "name": "[PIF] - Pingtung - PIF - Pingtung - Taiwan",
            "searchName": "PIF-Pingtung-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "RMQ",
            "countryCode": "TW",
            "cityCode": "RMQ",
            "cityName": "Taichung",
            "name": "[RMQ] - Chingchuankang - RMQ - Taichung - Taiwan",
            "searchName": "RMQ-Taichung-Chingchuankang-ChingChuanKang-Ching Chuan Kang Airport-Taichung City-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "MFK",
            "countryCode": "TW",
            "cityCode": "MFK",
            "cityName": "Matsu",
            "name": "[MFK] - Matsu - MFK - Matsu - Taiwan",
            "searchName": "MFK-Mafeking-Matsu-Matsu Havalimani-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "MZG",
            "countryCode": "TW",
            "cityCode": "MZG",
            "cityName": "Makung",
            "name": "[MZG] - Makung - MZG - Makung - Taiwan",
            "searchName": "MZG-Makung-Magong-Tajvan-Taiwan-Tayvan-Tchaj-wan-Taïwan-Taivanas-Taivana-Tajwan-Taiwán",
            "priority": 0
        },
        {
            "iataCode": "MYW",
            "countryCode": "TZ",
            "cityCode": "MYW",
            "cityName": "Mtwara",
            "name": "[MYW] - Mtwara - MYW - Mtwara - Tanzania",
            "searchName": "MYW-Mtwara-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "MWN",
            "countryCode": "TZ",
            "cityCode": "MWN",
            "cityName": "Mwadui",
            "name": "[MWN] - Mwadui - MWN - Mwadui - Tanzania",
            "searchName": "MWN-Mwadui-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "MWZ",
            "countryCode": "TZ",
            "cityCode": "MWZ",
            "cityName": "Mwanza",
            "name": "[MWZ] - Mwanza - MWZ - Mwanza - Tanzania",
            "searchName": "MWZ-Mwanza-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "NCH",
            "countryCode": "TZ",
            "cityCode": "NCH",
            "cityName": "Nachingwea",
            "name": "[NCH] - Nachingwea - NCH - Nachingwea - Tanzania",
            "searchName": "NCH-Nachingwea-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "MFA",
            "countryCode": "TZ",
            "cityCode": "MFA",
            "cityName": "Mafia",
            "name": "[MFA] - Mafia - MFA - Mafia - Tanzania",
            "searchName": "MFA-Mafia-Mafia Island-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "MBI",
            "countryCode": "TZ",
            "cityCode": "MBI",
            "cityName": "Mbeya",
            "name": "[MBI] - Mbeya - MBI - Mbeya - Tanzania",
            "searchName": "MBI-Mbeya-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "MUZ",
            "countryCode": "TZ",
            "cityCode": "MUZ",
            "cityName": "Musoma",
            "name": "[MUZ] - Musoma - MUZ - Musoma - Tanzania",
            "searchName": "MUZ-Musoma-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "PMA",
            "countryCode": "TZ",
            "cityCode": "PMA",
            "cityName": "Pemba",
            "name": "[PMA] - Wawi - PMA - Pemba - Tanzania",
            "searchName": "PMA-Wawi-Pemba Airport-Pemba-Wete-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "XMI",
            "countryCode": "TZ",
            "cityCode": "XMI",
            "cityName": "Masasi",
            "name": "[XMI] - Masasi - XMI - Masasi - Tanzania",
            "searchName": "XMI-Masasi-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "SUT",
            "countryCode": "TZ",
            "cityCode": "SUT",
            "cityName": "Sumbawanga",
            "name": "[SUT] - Sumbawanga - SUT - Sumbawanga - Tanzania",
            "searchName": "SUT-Sumbawanga-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "SEU",
            "countryCode": "TZ",
            "cityCode": "SEU",
            "cityName": "Seronera",
            "name": "[SEU] - Seronera - SEU - Seronera - Tanzania",
            "searchName": "SEU-Seronera-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "SGX",
            "countryCode": "TZ",
            "cityCode": "SGX",
            "cityName": "Songea",
            "name": "[SGX] - Songea - SGX - Songea - Tanzania",
            "searchName": "SGX-Songea-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "SHY",
            "countryCode": "TZ",
            "cityCode": "SHY",
            "cityName": "Shinyanga",
            "name": "[SHY] - Shinyanga - SHY - Shinyanga - Tanzania",
            "searchName": "SHY-Shinyanga-Shinyanga Airport-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "TKQ",
            "countryCode": "TZ",
            "cityCode": "TKQ",
            "cityName": "Kigoma",
            "name": "[TKQ] - Kigoma - TKQ - Kigoma - Tanzania",
            "searchName": "TKQ-Kigoma-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "TBO",
            "countryCode": "TZ",
            "cityCode": "TBO",
            "cityName": "Tabora",
            "name": "[TBO] - Tabora - TBO - Tabora - Tanzania",
            "searchName": "TBO-Tabora-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "TGT",
            "countryCode": "TZ",
            "cityCode": "TGT",
            "cityName": "Tanga",
            "name": "[TGT] - Tanga - TGT - Tanga - Tanzania",
            "searchName": "TGT-Tanga-Tanga Havalimani-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "DAR",
            "countryCode": "TZ",
            "cityCode": "DAR",
            "cityName": "Dar Es Salaam",
            "name": "[DAR] - Dar Es Salam International Airport - DAR - Dar Es Salaam - Tanzania",
            "searchName": "DAR-Dar Es Salaam-Dar Es Salam International Airport-Daressalam-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "DOD",
            "countryCode": "TZ",
            "cityCode": "DOD",
            "cityName": "Dodoma",
            "name": "[DOD] - Dodoma - DOD - Dodoma - Tanzania",
            "searchName": "DOD-Dodoma-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "BKZ",
            "countryCode": "TZ",
            "cityCode": "BKZ",
            "cityName": "Bukoba",
            "name": "[BKZ] - Bukoba - BKZ - Bukoba - Tanzania",
            "searchName": "BKZ-Bukoba-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "ARK",
            "countryCode": "TZ",
            "cityCode": "ARK",
            "cityName": "Arusha",
            "name": "[ARK] - Arusha - ARK - Arusha - Tanzania",
            "searchName": "ARK-Arusha-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "KIY",
            "countryCode": "TZ",
            "cityCode": "KIY",
            "cityName": "Kilwa",
            "name": "[KIY] - Kilwa - KIY - Kilwa - Tanzania",
            "searchName": "KIY-Kilwa-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "JRO",
            "countryCode": "TZ",
            "cityCode": "JRO",
            "cityName": "Kilimanjaro",
            "name": "[JRO] - Kilimanjaro - JRO - Kilimanjaro - Tanzania",
            "searchName": "JRO-Kilimanjaro-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "JOM",
            "countryCode": "TZ",
            "cityCode": "JOM",
            "cityName": "Njombe",
            "name": "[JOM] - Njombe - JOM - Njombe - Tanzania",
            "searchName": "JOM-Njombe-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "LKY",
            "countryCode": "TZ",
            "cityCode": "LKY",
            "cityName": "Lake Manyara",
            "name": "[LKY] - Lake Manyara - LKY - Lake Manyara - Tanzania",
            "searchName": "LKY-Lake Manyara-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "LDI",
            "countryCode": "TZ",
            "cityCode": "LDI",
            "cityName": "Lindi",
            "name": "[LDI] - Kikwetu - LDI - Lindi - Tanzania",
            "searchName": "LDI-Kikwetu-Lindi-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "IRI",
            "countryCode": "TZ",
            "cityCode": "IRI",
            "cityName": "Iringa",
            "name": "[IRI] - Nduli - IRI - Iringa - Tanzania",
            "searchName": "IRI-Nduli-Iringa-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "GIT",
            "countryCode": "TZ",
            "cityCode": "GIT",
            "cityName": "Geita",
            "name": "[GIT] - Geita - GIT - Geita - Tanzania",
            "searchName": "GIT-Geita-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "GTZ",
            "countryCode": "TZ",
            "cityCode": "GTZ",
            "cityName": "Grumeti",
            "name": "[GTZ] - Kirawira B - GTZ - Grumeti - Tanzania",
            "searchName": "GTZ-Kirawira B-Grumeti-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "ZNZ",
            "countryCode": "TZ",
            "cityCode": "ZNZ",
            "cityName": "Zanzibar",
            "name": "[ZNZ] - Kisauni - ZNZ - Zanzibar - Tanzania",
            "searchName": "ZNZ-Sansibar-Kisauni-Zanzibar-Tanzania-Tanzanya-Tanzània-Tanzánia-Tanzanija-Tansaania-Tanzanie-Tansania",
            "priority": 0
        },
        {
            "iataCode": "ZTR",
            "countryCode": "UA",
            "cityCode": "ZTR",
            "cityName": "Zhitomir",
            "name": "[ZTR] - Zhitomir Airport - ZTR - Zhitomir - Ukraine",
            "searchName": "ZTR-Zhitomir Airport-Schytomyr-Zhitomir-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "IFO",
            "countryCode": "UA",
            "cityCode": "IFO",
            "cityName": "Ivano-Frankovsk",
            "name": "[IFO] - Ivano-Frankovsk - IFO - Ivano-Frankovsk - Ukraine",
            "searchName": "IFO-Iwano Frankiwsk-Ivano-Frankovsk-Frankivsk-Frankivsk Apt.-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "QOF",
            "countryCode": "UA",
            "cityCode": "IEV",
            "cityName": "Kiev",
            "name": "[QOF] - Darnitsa Bus Station - IEV - Kiev - Ukraine",
            "searchName": "QOF-IEV-Darnitsa Bus Station-Kiew-Kiev-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "QOH",
            "countryCode": "UA",
            "cityCode": "IEV",
            "cityName": "Kiev",
            "name": "[QOH] - Hotel Rus Bus Station - IEV - Kiev - Ukraine",
            "searchName": "QOH-IEV-Hotel Rus Bus Station-Kiew-Kiev-Ucraïna-Ukrayna-Ukrajina-Ukraina-Ukraine-Ukrajna-Ucraina-Ucrania",
            "priority": 0
        },
        {
            "iataCode": "NQA",
            "countryCode": "US",
            "cityCode": "MEM",
            "cityName": "Memphis",
            "name": "[NQA] - Millington Regional Jetport - MEM - Memphis - United States",
            "searchName": "NQA-MEM-Millington Regional Jetport-Memphis Naval Air Station Havalimani-Memphis-Menfis-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MFD",
            "countryCode": "US",
            "cityCode": "MFD",
            "cityName": "Mansfield",
            "name": "[MFD] - Lahm Municipal - MFD - Mansfield - United States",
            "searchName": "MFD-Kommunaler Flughafen Lahm-Lahm Municipal-Mansfield Belediyesi Havalimani-Mansfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MFE",
            "countryCode": "US",
            "cityCode": "MFE",
            "cityName": "Mc Allen",
            "name": "[MFE] - Mc Allen Miller International - MFE - Mc Allen - United States",
            "searchName": "MFE-Mcallen Mission-Mc Allen Miller International-Miller Intl-Mcallen/Mission-Mc Allen-McAllen-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MEO",
            "countryCode": "US",
            "cityCode": "MEO",
            "cityName": "Manteo",
            "name": "[MEO] - Dare County Regional - MEO - Manteo - United States",
            "searchName": "MEO-Dare County Regional-Dare County Yerel Havalimani-Manteo-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MEV",
            "countryCode": "US",
            "cityCode": "MEV",
            "cityName": "Minden",
            "name": "[MEV] - Douglas County - MEV - Minden - United States",
            "searchName": "MEV-Douglas County-Minden-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MDR",
            "countryCode": "US",
            "cityCode": "MDR",
            "cityName": "Medfra",
            "name": "[MDR] - Medfra - MDR - Medfra - United States",
            "searchName": "MDR-Medfra-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MDJ",
            "countryCode": "US",
            "cityCode": "MDJ",
            "cityName": "Madras",
            "name": "[MDJ] - City-county - MDJ - Madras - United States",
            "searchName": "MDJ-City-county-Madras-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MDN",
            "countryCode": "US",
            "cityCode": "MDN",
            "cityName": "Madison",
            "name": "[MDN] - Jefferson Proving Grd - MDN - Madison - United States",
            "searchName": "MDN-Jefferson Proving Grd-Madison-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MDO",
            "countryCode": "US",
            "cityCode": "MDO",
            "cityName": "Middleton Island",
            "name": "[MDO] - Intermediate - MDO - Middleton Island - United States",
            "searchName": "MDO-Intermediate Airport-Intermediate-Middleton Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MCW",
            "countryCode": "US",
            "cityCode": "MCW",
            "cityName": "Mason City",
            "name": "[MCW] - Mason City Municipal - MCW - Mason City - United States",
            "searchName": "MCW-Mason City-Mason City Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MDF",
            "countryCode": "US",
            "cityCode": "MDF",
            "cityName": "Medford",
            "name": "[MDF] - Medford - MDF - Medford - United States",
            "searchName": "MDF-Medford-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MDH",
            "countryCode": "US",
            "cityCode": "MDH",
            "cityName": "Carbondale",
            "name": "[MDH] - Southern Illinois - MDH - Carbondale - United States",
            "searchName": "MDH-Southern Illinois-Carbondale-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MBS",
            "countryCode": "US",
            "cityCode": "MBS",
            "cityName": "Saginaw",
            "name": "[MBS] - MBS International - MBS - Saginaw - United States",
            "searchName": "MBS-Saginaw-MBS International-Bay City/Midland/Saginaw-Bay City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MBL",
            "countryCode": "US",
            "cityCode": "MBL",
            "cityName": "Manistee",
            "name": "[MBL] - Blacker - MBL - Manistee - United States",
            "searchName": "MBL-Manistee-Blacker-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MBG",
            "countryCode": "US",
            "cityCode": "MBG",
            "cityName": "Mobridge",
            "name": "[MBG] - Mobridge - MBG - Mobridge - United States",
            "searchName": "MBG-Mobridge-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MBY",
            "countryCode": "US",
            "cityCode": "MBY",
            "cityName": "Moberly",
            "name": "[MBY] - Moberly - MBY - Moberly - United States",
            "searchName": "MBY-Moberly-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MCB",
            "countryCode": "US",
            "cityCode": "MCB",
            "cityName": "Mccomb",
            "name": "[MCB] - Pike County - MCB - Mccomb - United States",
            "searchName": "MCB-Pike County-Mccomb-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MCD",
            "countryCode": "US",
            "cityCode": "MCD",
            "cityName": "Mackinac Island",
            "name": "[MCD] - Mackinac Island - MCD - Mackinac Island - United States",
            "searchName": "MCD-Mackinac Island-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MCE",
            "countryCode": "US",
            "cityCode": "MCE",
            "cityName": "Merced",
            "name": "[MCE] - Merced Municipal Airport - MCE - Merced - United States",
            "searchName": "MCE-Merced-Merced Municipal Airport-Merced Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MER",
            "countryCode": "US",
            "cityCode": "MCE",
            "cityName": "Merced",
            "name": "[MER] - Castle AFB - MCE - Merced - United States",
            "searchName": "MER-MCE-Castle AFB-Merced-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MCG",
            "countryCode": "US",
            "cityCode": "MCG",
            "cityName": "Mcgrath",
            "name": "[MCG] - Mcgrath - MCG - Mcgrath - United States",
            "searchName": "MCG-Mcgrath-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MCN",
            "countryCode": "US",
            "cityCode": "MCN",
            "cityName": "Macon",
            "name": "[MCN] - Lewis B Wilson - MCN - Macon - United States",
            "searchName": "MCN-Macon-Lewis B Wilson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MAC",
            "countryCode": "US",
            "cityCode": "MCN",
            "cityName": "Macon",
            "name": "[MAC] - Smart - MCN - Macon - United States",
            "searchName": "MAC-MCN-Smart-Macon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "WRB",
            "countryCode": "US",
            "cityCode": "MCN",
            "cityName": "Macon",
            "name": "[WRB] - Robins AFB - MCN - Macon - United States",
            "searchName": "WRB-MCN-Robins AFB-Macon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "QMP",
            "countryCode": "US",
            "cityCode": "MCN",
            "cityName": "Macon",
            "name": "[QMP] - FlightLink Bus Station - MCN - Macon - United States",
            "searchName": "QMP-MCN-FlightLink Bus Station-Macon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MCK",
            "countryCode": "US",
            "cityCode": "MCK",
            "cityName": "Mccook",
            "name": "[MCK] - Mccook - MCK - Mccook - United States",
            "searchName": "MCK-Mccook-McCook-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MCL",
            "countryCode": "US",
            "cityCode": "MCL",
            "cityName": "Mount Mckinley",
            "name": "[MCL] - Mt Mckinley - MCL - Mount Mckinley - United States",
            "searchName": "MCL-Mt Mckinley-Mount McKinley-Mount Mckinley-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MAW",
            "countryCode": "US",
            "cityCode": "MAW",
            "cityName": "Malden",
            "name": "[MAW] - Malden - MAW - Malden - United States",
            "searchName": "MAW-Malden-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MAE",
            "countryCode": "US",
            "cityCode": "MAE",
            "cityName": "Madera",
            "name": "[MAE] - Madera - MAE - Madera - United States",
            "searchName": "MAE-Madera-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MAF",
            "countryCode": "US",
            "cityCode": "MAF",
            "cityName": "Midland",
            "name": "[MAF] - Midland International - MAF - Midland - United States",
            "searchName": "MAF-Midland-Midland International-Midland Intl-Midland\\/Odessa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MDD",
            "countryCode": "US",
            "cityCode": "MAF",
            "cityName": "Midland",
            "name": "[MDD] - Airpark - MAF - Midland - United States",
            "searchName": "MDD-MAF-Airpark-Midland-Midland\\/Odessa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MFH",
            "countryCode": "US",
            "cityCode": "MFH",
            "cityName": "Mesquite",
            "name": "[MFH] - Mesquite - MFH - Mesquite - United States",
            "searchName": "MFH-Mesquite-Mesquite Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MFI",
            "countryCode": "US",
            "cityCode": "MFI",
            "cityName": "Marshfield",
            "name": "[MFI] - Municipal - MFI - Marshfield - United States",
            "searchName": "MFI-Municipal-Marshfield  Havalimani-Marshfield-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MFR",
            "countryCode": "US",
            "cityCode": "MFR",
            "cityName": "Medford",
            "name": "[MFR] - Rogue Valley International - Medford Airport - MFR - Medford - United States",
            "searchName": "MFR-Medford-Rogue Valley International - Medford Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MFV",
            "countryCode": "US",
            "cityCode": "MFV",
            "cityName": "Melfa",
            "name": "[MFV] - Accomack County - MFV - Melfa - United States",
            "searchName": "MFV-Accomack County-Melfa-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MGE",
            "countryCode": "US",
            "cityCode": "MGE",
            "cityName": "Marietta",
            "name": "[MGE] - Dobbins AFB - MGE - Marietta - United States",
            "searchName": "MGE-Dobbins AFB-Marietta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "NCQ",
            "countryCode": "US",
            "cityCode": "MGE",
            "cityName": "Marietta",
            "name": "[NCQ] - Atlanta NAS - MGE - Marietta - United States",
            "searchName": "NCQ-MGE-Atlanta NAS-Marietta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MGC",
            "countryCode": "US",
            "cityCode": "MGC",
            "cityName": "Michigan City",
            "name": "[MGC] - Michigan City Airport - MGC - Michigan City - United States",
            "searchName": "MGC-Michigan City Airport-Michigan City Havalimani-Michigan City-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MGJ",
            "countryCode": "US",
            "cityCode": "MGJ",
            "cityName": "Montgomery",
            "name": "[MGJ] - Orange County - MGJ - Montgomery - United States",
            "searchName": "MGJ-Orange County-Orange County Havalimani-Montgomery-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MGM",
            "countryCode": "US",
            "cityCode": "MGM",
            "cityName": "Montgomery",
            "name": "[MGM] - Dannelly Field - MGM - Montgomery - United States",
            "searchName": "MGM-Montgomery-Dannelly Field-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MXF",
            "countryCode": "US",
            "cityCode": "MGM",
            "cityName": "Montgomery",
            "name": "[MXF] - Maxwell AFB - MGM - Montgomery - United States",
            "searchName": "MXF-MGM-Maxwell AFB-Montgomery-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "GUN",
            "countryCode": "US",
            "cityCode": "MGM",
            "cityName": "Montgomery",
            "name": "[GUN] - Gunter AFB - MGM - Montgomery - United States",
            "searchName": "GUN-MGM-Gunter AFB-Gulltown-Montgomery-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MGR",
            "countryCode": "US",
            "cityCode": "MGR",
            "cityName": "Moultrie",
            "name": "[MGR] - Moultrie Municipal - MGR - Moultrie - United States",
            "searchName": "MGR-Moultrie Municipal-Moultrie Havalimani-Moultrie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MUL",
            "countryCode": "US",
            "cityCode": "MGR",
            "cityName": "Moultrie",
            "name": "[MUL] - Spence - MGR - Moultrie - United States",
            "searchName": "MUL-MGR-Spence-Moultrie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MGW",
            "countryCode": "US",
            "cityCode": "MGW",
            "cityName": "Morgantown",
            "name": "[MGW] - Morgantown - MGW - Morgantown - United States",
            "searchName": "MGW-Morgantown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MHE",
            "countryCode": "US",
            "cityCode": "MHE",
            "cityName": "Mitchell",
            "name": "[MHE] - Municipal - MHE - Mitchell - United States",
            "searchName": "MHE-Municipal-Mitchell Belediyesi Havalimani-Mitchell-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MHK",
            "countryCode": "US",
            "cityCode": "MHK",
            "cityName": "Manhattan",
            "name": "[MHK] - Municipal - MHK - Manhattan - United States",
            "searchName": "MHK-Manhattan-Municipal-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MHL",
            "countryCode": "US",
            "cityCode": "MHL",
            "cityName": "Marshall",
            "name": "[MHL] - Memorial Municipal - MHL - Marshall - United States",
            "searchName": "MHL-Memorial Municipal-Marshall-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MHM",
            "countryCode": "US",
            "cityCode": "MHM",
            "cityName": "Minchumina",
            "name": "[MHM] - Intermediate - MHM - Minchumina - United States",
            "searchName": "MHM-Intermediate-Minchumina-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MHN",
            "countryCode": "US",
            "cityCode": "MHN",
            "cityName": "Mullen",
            "name": "[MHN] - Mullen - MHN - Mullen - United States",
            "searchName": "MHN-Mullen-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MIE",
            "countryCode": "US",
            "cityCode": "MIE",
            "cityName": "Muncie",
            "name": "[MIE] - Delaware County - MIE - Muncie - United States",
            "searchName": "MIE-Delaware County-Johnson Field Havalimani-Muncie-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MIF",
            "countryCode": "US",
            "cityCode": "MIF",
            "cityName": "Monahans",
            "name": "[MIF] - Roy Hurd Memorial - MIF - Monahans - United States",
            "searchName": "MIF-Roy Hurd Memorial-Monahans-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MWC",
            "countryCode": "US",
            "cityCode": "MKE",
            "cityName": "Milwaukee",
            "name": "[MWC] - Lawrence J Timmerman - MKE - Milwaukee - United States",
            "searchName": "MWC-MKE-Lawrence J Timmerman-Milwaukee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MKG",
            "countryCode": "US",
            "cityCode": "MKG",
            "cityName": "Muskegon",
            "name": "[MKG] - Muskegon - MKG - Muskegon - United States",
            "searchName": "MKG-Muskegon-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MPZ",
            "countryCode": "US",
            "cityCode": "MPZ",
            "cityName": "Mount Pleasant",
            "name": "[MPZ] - Municipal - MPZ - Mount Pleasant - United States",
            "searchName": "MPZ-Municipal-Mount Pleasant-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MPV",
            "countryCode": "US",
            "cityCode": "MPV",
            "cityName": "Montpelier",
            "name": "[MPV] - Edward F Knapp State - MPV - Montpelier - United States",
            "searchName": "MPV-Edward F Knapp State-E F Knapp Havalimani-Montpelier-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MPR",
            "countryCode": "US",
            "cityCode": "MPR",
            "cityName": "Mcpherson",
            "name": "[MPR] - Mcpherson - MPR - Mcpherson - United States",
            "searchName": "MPR-Mcpherson-McPherson Havalimani-McPherson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MPS",
            "countryCode": "US",
            "cityCode": "MPS",
            "cityName": "Mount Pleasant",
            "name": "[MPS] - Mount Pleasant - MPS - Mount Pleasant - United States",
            "searchName": "MPS-Mount Pleasant-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MPO",
            "countryCode": "US",
            "cityCode": "MPO",
            "cityName": "Mount Pocono",
            "name": "[MPO] - Mt Pocono - MPO - Mount Pocono - United States",
            "searchName": "MPO-Mt Pocono-Mount Pocono-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MPJ",
            "countryCode": "US",
            "cityCode": "MPJ",
            "cityName": "Morrilton",
            "name": "[MPJ] - Petit Jean Park - MPJ - Morrilton - United States",
            "searchName": "MPJ-Petit Jean Park-Morrilton-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MOX",
            "countryCode": "US",
            "cityCode": "MOX",
            "cityName": "Morris",
            "name": "[MOX] - Municipal - MOX - Morris - United States",
            "searchName": "MOX-Municipal-Morris-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MOP",
            "countryCode": "US",
            "cityCode": "MOP",
            "cityName": "Mount Pleasant",
            "name": "[MOP] - Municipal - MOP - Mount Pleasant - United States",
            "searchName": "MOP-Municipal-Mt Pleasant Belediyesi Havalimani-Mount Pleasant-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MOR",
            "countryCode": "US",
            "cityCode": "MOR",
            "cityName": "Morristown",
            "name": "[MOR] - Moore-Murrell - MOR - Morristown - United States",
            "searchName": "MOR-Moore-Murrell-Morristown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MOS",
            "countryCode": "US",
            "cityCode": "MOS",
            "cityName": "Moses Point",
            "name": "[MOS] - Intermediate - MOS - Moses Point - United States",
            "searchName": "MOS-Intermediate-Moses Point-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MOT",
            "countryCode": "US",
            "cityCode": "MOT",
            "cityName": "Minot",
            "name": "[MOT] - International - MOT - Minot - United States",
            "searchName": "MOT-Minot-International-Minot North Dakota-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MIB",
            "countryCode": "US",
            "cityCode": "MOT",
            "cityName": "Minot",
            "name": "[MIB] - Minot AFB - MOT - Minot - United States",
            "searchName": "MIB-MOT-Minot AFB-Minot North Dakota-Minot-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MOU",
            "countryCode": "US",
            "cityCode": "MOU",
            "cityName": "Mountain Village",
            "name": "[MOU] - Mountain Village - MOU - Mountain Village - United States",
            "searchName": "MOU-Mountain Village-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MNZ",
            "countryCode": "US",
            "cityCode": "MNZ",
            "cityName": "Manassas",
            "name": "[MNZ] - Manassas Regional Airport/Harry P. Davis Field - MNZ - Manassas - United States",
            "searchName": "MNZ-Manassas Regional Airport/Harry P. Davis Field-Manassas-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MOB",
            "countryCode": "US",
            "cityCode": "MOB",
            "cityName": "Mobile",
            "name": "[MOB] - Mobile Regional Airport - MOB - Mobile - United States",
            "searchName": "MOB-Mobile-Mobile Regional Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "BFM",
            "countryCode": "US",
            "cityCode": "MOB",
            "cityName": "Mobile",
            "name": "[BFM] - Mobile Downtown - MOB - Mobile - United States",
            "searchName": "BFM-MOB-Mobile Downtown-Mobile-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MHV",
            "countryCode": "US",
            "cityCode": "MHV",
            "cityName": "Mojave",
            "name": "[MHV] - Kern County - MHV - Mojave - United States",
            "searchName": "MHV-Kern County-Kern County Havalimani-Mojave-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MHS",
            "countryCode": "US",
            "cityCode": "MHS",
            "cityName": "Mount Shasta",
            "name": "[MHS] - Rep - MHS - Mount Shasta - United States",
            "searchName": "MHS-Rep-Mount Shasta-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MHT",
            "countryCode": "US",
            "cityCode": "MHT",
            "cityName": "Manchester",
            "name": "[MHT] - Manchester-boston Regional Airport - MHT - Manchester - United States",
            "searchName": "MHT-Manchester USA-Manchester-boston Regional Airport-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MIO",
            "countryCode": "US",
            "cityCode": "MIO",
            "cityName": "Miami",
            "name": "[MIO] - Miami - MIO - Miami - United States",
            "searchName": "MIO-Miami-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MIT",
            "countryCode": "US",
            "cityCode": "MIT",
            "cityName": "Shafter",
            "name": "[MIT] - Kern County - MIT - Shafter - United States",
            "searchName": "MIT-Kern County-Shafter-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MIV",
            "countryCode": "US",
            "cityCode": "MIV",
            "cityName": "Millville",
            "name": "[MIV] - Millville - MIV - Millville - United States",
            "searchName": "MIV-Millville-Millville Havalimani-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MIW",
            "countryCode": "US",
            "cityCode": "MIW",
            "cityName": "Marshalltown",
            "name": "[MIW] - Municipal - MIW - Marshalltown - United States",
            "searchName": "MIW-Municipal-Marshalltown Belediyesi Havalimani-Marshalltown-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MKO",
            "countryCode": "US",
            "cityCode": "MKO",
            "cityName": "Muskogee",
            "name": "[MKO] - Davis Field - MKO - Muskogee - United States",
            "searchName": "MKO-Davis Field-Davis Field Havalimani-Muskogee-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MKT",
            "countryCode": "US",
            "cityCode": "MKT",
            "cityName": "Mankato",
            "name": "[MKT] - Municipal - MKT - Mankato - United States",
            "searchName": "MKT-Municipal-Mankato-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "JMN",
            "countryCode": "US",
            "cityCode": "MKT",
            "cityName": "Mankato",
            "name": "[JMN] - Municipal Heliport - MKT - Mankato - United States",
            "searchName": "JMN-MKT-Municipal Heliport-Mankato-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MJX",
            "countryCode": "US",
            "cityCode": "MJX",
            "cityName": "Toms River",
            "name": "[MJX] - Robert J. Miller - MJX - Toms River - United States",
            "searchName": "MJX-Robert J. Miller-Toms River-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MKK",
            "countryCode": "US",
            "cityCode": "MKK",
            "cityName": "Hoolehua",
            "name": "[MKK] - Molokai - MKK - Hoolehua - United States",
            "searchName": "MKK-Molokai Hoolehua-Molokai-Hoolehua-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MKL",
            "countryCode": "US",
            "cityCode": "MKL",
            "cityName": "Jackson",
            "name": "[MKL] - Mc Kellar-Sipes Regional - MKL - Jackson - United States",
            "searchName": "MKL-Mckellar Field-Mc Kellar-Sipes Regional-Mckellar-Jackson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "MJQ",
            "countryCode": "US",
            "cityCode": "MJQ",
            "cityName": "Jackson",
            "name": "[MJQ] - Jackson - MJQ - Jackson - United States",
            "searchName": "MJQ-Jackson-Estados Unidos da América-Verenigde Staten-Amerika Syarikat-USA-Amerika Serikat-United States-Stati Uniti-Estados Unidos-Vereinigte Staaten-Amerika Birlesik Devletleri-EE. UU.-É-U",
            "priority": 0
        },
        {
            "iataCode": "THN",
            "countryCode": "SE",
            "cityCode": "THN",
            "cityName": "Trollhattan",
            "name": "[THN] - Trollhattan - THN - Trollhattan - Sweden",
            "searchName": "THN-Trollhattan-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "AJR",
            "countryCode": "SE",
            "cityCode": "AJR",
            "cityName": "Arvidsjaur",
            "name": "[AJR] - Arvidsjaur - AJR - Arvidsjaur - Sweden",
            "searchName": "AJR-Arvidsjaur-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "AGH",
            "countryCode": "SE",
            "cityCode": "AGH",
            "cityName": "Angelholm",
            "name": "[AGH] - Angelholm - AGH - Angelholm - Sweden",
            "searchName": "AGH-Helsingborg Angelholm-Angelholm-Angelholm/Helsingborg-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "JHE",
            "countryCode": "SE",
            "cityCode": "AGH",
            "cityName": "Angelholm",
            "name": "[JHE] - Heliport - AGH - Angelholm - Sweden",
            "searchName": "JHE-AGH-Heliport-Angelholm/Helsingborg-Angelholm-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYH",
            "countryCode": "SE",
            "cityCode": "AGH",
            "cityName": "Angelholm",
            "name": "[XYH] - Helsingborg Railway - AGH - Angelholm - Sweden",
            "searchName": "XYH-AGH-Helsingborg Railway-Angelholm/Helsingborg-Angelholm-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYQ",
            "countryCode": "SE",
            "cityCode": "AGH",
            "cityName": "Angelholm",
            "name": "[XYQ] - Angelholm Railway - AGH - Angelholm - Sweden",
            "searchName": "XYQ-AGH-Angelholm Railway-Angelholm/Helsingborg-Angelholm-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "BLE",
            "countryCode": "SE",
            "cityCode": "BLE",
            "cityName": "Borlange",
            "name": "[BLE] - Dala Airport - BLE - Borlange - Sweden",
            "searchName": "BLE-Borlange Falun-Dala Airport-Dala-Dala Apt-Borlange/Falun-Borlange-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYB",
            "countryCode": "SE",
            "cityCode": "BLE",
            "cityName": "Borlange",
            "name": "[XYB] - Borlange Railway - BLE - Borlange - Sweden",
            "searchName": "XYB-BLE-Borlange Railway-Borlange/Falun-Borlange-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XWF",
            "countryCode": "SE",
            "cityCode": "BLE",
            "cityName": "Borlange",
            "name": "[XWF] - Falun C Railway Station - BLE - Borlange - Sweden",
            "searchName": "XWF-BLE-Falun C Railway Station-Borlange/Falun-Borlange-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XJF",
            "countryCode": "SE",
            "cityCode": "BLE",
            "cityName": "Borlange",
            "name": "[XJF] - Falun Bus Station - BLE - Borlange - Sweden",
            "searchName": "XJF-BLE-Falun Bus Station-Borlange/Falun-Borlange-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "EKT",
            "countryCode": "SE",
            "cityCode": "EKT",
            "cityName": "Eskilstuna",
            "name": "[EKT] - Eskilstuna - EKT - Eskilstuna - Sweden",
            "searchName": "EKT-Eskilstuna-Eskilstuna Havalimani-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XFJ",
            "countryCode": "SE",
            "cityCode": "EKT",
            "cityName": "Eskilstuna",
            "name": "[XFJ] - Eskilstuna C Rail Station - EKT - Eskilstuna - Sweden",
            "searchName": "XFJ-EKT-Eskilstuna C Rail Station-Eskilstuna-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "EVG",
            "countryCode": "SE",
            "cityCode": "EVG",
            "cityName": "Sveg",
            "name": "[EVG] - Sveg - EVG - Sveg - Sweden",
            "searchName": "EVG-Sveg-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "JKG",
            "countryCode": "SE",
            "cityCode": "JKG",
            "cityName": "Jonkoping",
            "name": "[JKG] - Axamo - JKG - Jonkoping - Sweden",
            "searchName": "JKG-Jönköping-Axamo-J-Jonkoping-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "JSO",
            "countryCode": "SE",
            "cityCode": "JSO",
            "cityName": "Sodertalje",
            "name": "[JSO] - Sodertalje Heliport - JSO - Sodertalje - Sweden",
            "searchName": "JSO-Sodertalje Heliport-Sodertalje-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XEZ",
            "countryCode": "SE",
            "cityCode": "JSO",
            "cityName": "Sodertalje",
            "name": "[XEZ] - Sodertalje S Railway - JSO - Sodertalje - Sweden",
            "searchName": "XEZ-JSO-Sodertalje S Railway-Sodertalje-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "JLD",
            "countryCode": "SE",
            "cityCode": "JLD",
            "cityName": "Landskrona",
            "name": "[JLD] - Heliport - JLD - Landskrona - Sweden",
            "searchName": "JLD-Heliport-Landskrona-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "KSD",
            "countryCode": "SE",
            "cityCode": "KSD",
            "cityName": "Karlstad",
            "name": "[KSD] - Karlstad - KSD - Karlstad - Sweden",
            "searchName": "KSD-Karlstad-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "KRN",
            "countryCode": "SE",
            "cityCode": "KRN",
            "cityName": "Kiruna",
            "name": "[KRN] - Kiruna - KRN - Kiruna - Sweden",
            "searchName": "KRN-Kiruna-Kiruna Airport-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "KRF",
            "countryCode": "SE",
            "cityCode": "KRF",
            "cityName": "Kramfors",
            "name": "[KRF] - Kramfors - KRF - Kramfors - Sweden",
            "searchName": "KRF-Kramfors-Kramfors Airport-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "KSK",
            "countryCode": "SE",
            "cityCode": "KSK",
            "cityName": "Karlskoga",
            "name": "[KSK] - Karlskoga - KSK - Karlskoga - Sweden",
            "searchName": "KSK-Karlskoga-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "KID",
            "countryCode": "SE",
            "cityCode": "KID",
            "cityName": "Kristianstad",
            "name": "[KID] - Kristianstad - KID - Kristianstad - Sweden",
            "searchName": "KID-Kristianstad-Kristianstad Havalimani-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "KLR",
            "countryCode": "SE",
            "cityCode": "KLR",
            "cityName": "Kalmar",
            "name": "[KLR] - Kalmar - KLR - Kalmar - Sweden",
            "searchName": "KLR-Kalmar-Kalmar Havalimani-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "KVB",
            "countryCode": "SE",
            "cityCode": "KVB",
            "cityName": "Skovde",
            "name": "[KVB] - Skovde - KVB - Skovde - Sweden",
            "searchName": "KVB-Skovde-Sk-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "LDK",
            "countryCode": "SE",
            "cityCode": "LDK",
            "cityName": "Lidkoping",
            "name": "[LDK] - Hovby - LDK - Lidkoping - Sweden",
            "searchName": "LDK-Hovby-Hovby Havalimani-Lidkoping-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "LPI",
            "countryCode": "SE",
            "cityCode": "LPI",
            "cityName": "Linkoping",
            "name": "[LPI] - Linkoping - LPI - Linkoping - Sweden",
            "searchName": "LPI-Linkoping-Linkoping Saab Apt-Link-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "LLA",
            "countryCode": "SE",
            "cityCode": "LLA",
            "cityName": "Lulea",
            "name": "[LLA] - Kallax - LLA - Lulea - Sweden",
            "searchName": "LLA-Lulea-Kallax-Lule-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "LYC",
            "countryCode": "SE",
            "cityCode": "LYC",
            "cityName": "Lycksele",
            "name": "[LYC] - Lycksele - LYC - Lycksele - Sweden",
            "searchName": "LYC-Lycksele-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "HFS",
            "countryCode": "SE",
            "cityCode": "HFS",
            "cityName": "Hagfors",
            "name": "[HFS] - Hagfors - HFS - Hagfors - Sweden",
            "searchName": "HFS-Hagfors-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "HLF",
            "countryCode": "SE",
            "cityCode": "HLF",
            "cityName": "Hultsfred",
            "name": "[HLF] - Hultsfred - Vimmerby - HLF - Hultsfred - Sweden",
            "searchName": "HLF-Hultsfred - Vimmerby-Hultsfred-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "HMV",
            "countryCode": "SE",
            "cityCode": "HMV",
            "cityName": "Hemavan",
            "name": "[HMV] - Hemavan - HMV - Hemavan - Sweden",
            "searchName": "HMV-Hemavan-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "HUV",
            "countryCode": "SE",
            "cityCode": "HUV",
            "cityName": "Hudiksvall",
            "name": "[HUV] - Hudiksvall - HUV - Hudiksvall - Sweden",
            "searchName": "HUV-Hudiksvall-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "IDB",
            "countryCode": "SE",
            "cityCode": "IDB",
            "cityName": "Idre",
            "name": "[IDB] - Idre - IDB - Idre - Sweden",
            "searchName": "IDB-Idre-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "HAD",
            "countryCode": "SE",
            "cityCode": "HAD",
            "cityName": "Halmstad",
            "name": "[HAD] - Halmstad - HAD - Halmstad - Sweden",
            "searchName": "HAD-Halmstad-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "GVX",
            "countryCode": "SE",
            "cityCode": "GVX",
            "cityName": "Gavle",
            "name": "[GVX] - Sandviken - GVX - Gavle - Sweden",
            "searchName": "GVX-Sandviken-Sandviken Havalimani-Gavle-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "QYU",
            "countryCode": "SE",
            "cityCode": "GVX",
            "cityName": "Gavle",
            "name": "[QYU] - Gavle C Railway Station - GVX - Gavle - Sweden",
            "searchName": "QYU-GVX-Gavle C Railway Station-Gavle-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "GOT",
            "countryCode": "SE",
            "cityCode": "GOT",
            "cityName": "Gothenburg",
            "name": "[GOT] - Landvetter - GOT - Gothenburg - Sweden",
            "searchName": "GOT-Göteborg Landvetter-Landvetter-Goeteborg-Gothenburg-Gotemburgo-G-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "GSE",
            "countryCode": "SE",
            "cityCode": "GOT",
            "cityName": "Gothenburg",
            "name": "[GSE] - Gothenburg City Airport - GOT - Gothenburg - Sweden",
            "searchName": "GSE-GOT-Göteborg Saeve-Gothenburg City Airport-Saeve-Goeteborg-Gothenburg-Gotemburgo-G-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XWL",
            "countryCode": "SE",
            "cityCode": "GOT",
            "cityName": "Gothenburg",
            "name": "[XWL] - Goteborg C Railway Station - GOT - Gothenburg - Sweden",
            "searchName": "XWL-GOT-Goteborg C Railway Station-Goeteborg-Gothenburg-Gotemburgo-G-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "GEV",
            "countryCode": "SE",
            "cityCode": "GEV",
            "cityName": "Gallivare",
            "name": "[GEV] - Gallivare - GEV - Gallivare - Sweden",
            "searchName": "GEV-Gallivare-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "YNB",
            "countryCode": "SA",
            "cityCode": "YNB",
            "cityName": "Yanbu",
            "name": "[YNB] - Yanbu - YNB - Yanbu - Saudi Arabia",
            "searchName": "YNB-Yanbo-Yanbu-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "ZUL",
            "countryCode": "SA",
            "cityCode": "ZUL",
            "cityName": "Zilfi",
            "name": "[ZUL] - Zilfi - ZUL - Zilfi - Saudi Arabia",
            "searchName": "ZUL-Zilfi-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "JJA",
            "countryCode": "SB",
            "cityCode": "JJA",
            "cityName": "Jajao",
            "name": "[JJA] - Jajao - JJA - Jajao - Solomon Islands",
            "searchName": "JJA-Jajao-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "KGE",
            "countryCode": "SB",
            "cityCode": "KGE",
            "cityName": "Kagau",
            "name": "[KGE] - Kagau - KGE - Kagau - Solomon Islands",
            "searchName": "KGE-Kagau-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "KWR",
            "countryCode": "SB",
            "cityCode": "KWR",
            "cityName": "Kwai Harbour",
            "name": "[KWR] - Kwai Harbour - KWR - Kwai Harbour - Solomon Islands",
            "searchName": "KWR-Kwai Harbour-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "KWS",
            "countryCode": "SB",
            "cityCode": "KWS",
            "cityName": "Kwailabesi",
            "name": "[KWS] - Kwailabesi Aerodrom - KWS - Kwailabesi - Solomon Islands",
            "searchName": "KWS-Kwailabesi Aerodrom-Kwailabesi-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "KUE",
            "countryCode": "SB",
            "cityCode": "KUE",
            "cityName": "Kukundu",
            "name": "[KUE] - Kukundu - KUE - Kukundu - Solomon Islands",
            "searchName": "KUE-Kukundu-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "HIR",
            "countryCode": "SB",
            "cityCode": "HIR",
            "cityName": "Honiara",
            "name": "[HIR] - Henderson International - HIR - Honiara - Solomon Islands",
            "searchName": "HIR-Honiara-Henderson International-Henderson Intl-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "IRA",
            "countryCode": "SB",
            "cityCode": "IRA",
            "cityName": "Kirakira",
            "name": "[IRA] - Kirakira - IRA - Kirakira - Solomon Islands",
            "searchName": "IRA-Kirakira-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "GZO",
            "countryCode": "SB",
            "cityCode": "GZO",
            "cityName": "Gizo",
            "name": "[GZO] - Gizo - GZO - Gizo - Solomon Islands",
            "searchName": "GZO-Gizo-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "GSI",
            "countryCode": "SB",
            "cityCode": "GSI",
            "cityName": "Guadalcanal",
            "name": "[GSI] - Guadalcanal - GSI - Guadalcanal - Solomon Islands",
            "searchName": "GSI-Guadalcanal-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "GTA",
            "countryCode": "SB",
            "cityCode": "GTA",
            "cityName": "Gatokae",
            "name": "[GTA] - Gatokae Aerodrom - GTA - Gatokae - Solomon Islands",
            "searchName": "GTA-Gatokae-Gatokae Aerodrom-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "GEF",
            "countryCode": "SB",
            "cityCode": "GEF",
            "cityName": "Geva",
            "name": "[GEF] - Geva Airstrip - GEF - Geva - Solomon Islands",
            "searchName": "GEF-Geva Airstrip-Geva-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "FRE",
            "countryCode": "SB",
            "cityCode": "FRE",
            "cityName": "Fera Island",
            "name": "[FRE] - Fera Island - FRE - Fera Island - Solomon Islands",
            "searchName": "FRE-Fera Island--Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "EGM",
            "countryCode": "SB",
            "cityCode": "EGM",
            "cityName": "Sege",
            "name": "[EGM] - Sege - EGM - Sege - Solomon Islands",
            "searchName": "EGM-Sege-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "CHY",
            "countryCode": "SB",
            "cityCode": "CHY",
            "cityName": "Choiseul Bay",
            "name": "[CHY] - Choiseul Bay - CHY - Choiseul Bay - Solomon Islands",
            "searchName": "CHY-Choiseul Bay-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "BAS",
            "countryCode": "SB",
            "cityCode": "BAS",
            "cityName": "Balalae",
            "name": "[BAS] - Balalae - BAS - Balalae - Solomon Islands",
            "searchName": "BAS-Balalae-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "ATD",
            "countryCode": "SB",
            "cityCode": "ATD",
            "cityName": "Atoifi",
            "name": "[ATD] - Atoifi - ATD - Atoifi - Solomon Islands",
            "searchName": "ATD-Atoifi-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "AVU",
            "countryCode": "SB",
            "cityCode": "AVU",
            "cityName": "Avu Avu",
            "name": "[AVU] - Avu Avu - AVU - Avu Avu - Solomon Islands",
            "searchName": "AVU-Avu Avu-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "AKS",
            "countryCode": "SB",
            "cityCode": "AKS",
            "cityName": "Auki",
            "name": "[AKS] - Gwaunaruu - AKS - Auki - Solomon Islands",
            "searchName": "AKS-Gwaunaru u-Gwaunaruu-Auki-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "AFT",
            "countryCode": "SB",
            "cityCode": "AFT",
            "cityName": "Afutara",
            "name": "[AFT] - Afutara Aerodrome - AFT - Afutara - Solomon Islands",
            "searchName": "AFT-Afutara Aerodrome-Afutara-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "BPF",
            "countryCode": "SB",
            "cityCode": "BPF",
            "cityName": "Batuna",
            "name": "[BPF] - Batuna Aerodrome - BPF - Batuna - Solomon Islands",
            "searchName": "BPF-Batuna Aerodrome-Batuna-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "BNY",
            "countryCode": "SB",
            "cityCode": "BNY",
            "cityName": "Bellona",
            "name": "[BNY] - Bellona - BNY - Bellona - Solomon Islands",
            "searchName": "BNY-Bellona-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "VEV",
            "countryCode": "SB",
            "cityCode": "VEV",
            "cityName": "Barakoma",
            "name": "[VEV] - Barakoma - VEV - Barakoma - Solomon Islands",
            "searchName": "VEV-Barakoma-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "VAO",
            "countryCode": "SB",
            "cityCode": "VAO",
            "cityName": "Suavanao",
            "name": "[VAO] - Suavanao Airstrip - VAO - Suavanao - Solomon Islands",
            "searchName": "VAO-Suavanao Airstrip-Suavanao-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "VIU",
            "countryCode": "SB",
            "cityCode": "VIU",
            "cityName": "Viru",
            "name": "[VIU] - Viru Harbour Airstrip - VIU - Viru - Solomon Islands",
            "searchName": "VIU-Harbour-Viru Harbour Airstrip-Viru-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "XYA",
            "countryCode": "SB",
            "cityCode": "XYA",
            "cityName": "Yandina",
            "name": "[XYA] - Yandina - XYA - Yandina - Solomon Islands",
            "searchName": "XYA-Yandina-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "SCZ",
            "countryCode": "SB",
            "cityCode": "SCZ",
            "cityName": "Santa Cruz Island",
            "name": "[SCZ] - Santa Cruz Island - SCZ - Santa Cruz Island - Solomon Islands",
            "searchName": "SCZ-Santa Cruz Is-Santa Cruz Island-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "SVY",
            "countryCode": "SB",
            "cityCode": "SVY",
            "cityName": "Savo",
            "name": "[SVY] - Savo - SVY - Savo - Solomon Islands",
            "searchName": "SVY-Savo-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "TLG",
            "countryCode": "SB",
            "cityCode": "TLG",
            "cityName": "Tulagi Island",
            "name": "[TLG] - Tulagi Island - TLG - Tulagi Island - Solomon Islands",
            "searchName": "TLG-Tulagi Island-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "MBU",
            "countryCode": "SB",
            "cityCode": "MBU",
            "cityName": "Mbambanakira",
            "name": "[MBU] - Mbambanakira - MBU - Mbambanakira - Solomon Islands",
            "searchName": "MBU-Mbambanakira-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "MNY",
            "countryCode": "SB",
            "cityCode": "MNY",
            "cityName": "Mono",
            "name": "[MNY] - Mono - MNY - Mono - Solomon Islands",
            "searchName": "MNY-Mono-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "MUA",
            "countryCode": "SB",
            "cityCode": "MUA",
            "cityName": "Munda",
            "name": "[MUA] - Munda - MUA - Munda - Solomon Islands",
            "searchName": "MUA-Munda-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "NAZ",
            "countryCode": "SB",
            "cityCode": "NAZ",
            "cityName": "Star Habour",
            "name": "[NAZ] - Nana - NAZ - Star Habour - Solomon Islands",
            "searchName": "NAZ-Nana-Star Habour-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "NNB",
            "countryCode": "SB",
            "cityCode": "NNB",
            "cityName": "Santa Ana",
            "name": "[NNB] - Santa Ana - NNB - Santa Ana - Solomon Islands",
            "searchName": "NNB-Santa Ana-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "RBV",
            "countryCode": "SB",
            "cityCode": "RBV",
            "cityName": "Ramata",
            "name": "[RBV] - Ramata - RBV - Ramata - Solomon Islands",
            "searchName": "RBV-Ramata-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "PRS",
            "countryCode": "SB",
            "cityCode": "PRS",
            "cityName": "Parasi",
            "name": "[PRS] - Parasi - PRS - Parasi - Solomon Islands",
            "searchName": "PRS-Parasi-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "RUS",
            "countryCode": "SB",
            "cityCode": "RUS",
            "cityName": "Marau Sound",
            "name": "[RUS] - Marau Sound - RUS - Marau Sound - Solomon Islands",
            "searchName": "RUS-Marau Sound-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "RRI",
            "countryCode": "SB",
            "cityCode": "RRI",
            "cityName": "Barora",
            "name": "[RRI] - Barora - RRI - Barora - Solomon Islands",
            "searchName": "RRI-Barora-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "RIN",
            "countryCode": "SB",
            "cityCode": "RIN",
            "cityName": "Ringi Cove",
            "name": "[RIN] - Ringi Cove - RIN - Ringi Cove - Solomon Islands",
            "searchName": "RIN-Ringi Cove-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "RNA",
            "countryCode": "SB",
            "cityCode": "RNA",
            "cityName": "Arona",
            "name": "[RNA] - Ulawa Airport - RNA - Arona - Solomon Islands",
            "searchName": "RNA-Ulawa Airport-Ulawa Havalimani-Arona-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "RNL",
            "countryCode": "SB",
            "cityCode": "RNL",
            "cityName": "Rennell",
            "name": "[RNL] - Rennell - RNL - Rennell - Solomon Islands",
            "searchName": "RNL-Rennell-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "ONE",
            "countryCode": "SB",
            "cityCode": "ONE",
            "cityName": "Onepusu",
            "name": "[ONE] - Onepusu - ONE - Onepusu - Solomon Islands",
            "searchName": "ONE-Onepusu-Solomon Islands-Salomonen-Soloman Islands-Islas Salomón-Îles Salomon-Isole Solomon",
            "priority": 0
        },
        {
            "iataCode": "BDI",
            "countryCode": "SC",
            "cityCode": "BDI",
            "cityName": "Bird Island",
            "name": "[BDI] - Bird Island - BDI - Bird Island - Escocia",
            "searchName": "BDI-Bird Island-Escocia",
            "priority": 0
        },
        {
            "iataCode": "DES",
            "countryCode": "SC",
            "cityCode": "DES",
            "cityName": "Desroches",
            "name": "[DES] - Desroches - DES - Desroches - Escocia",
            "searchName": "DES-Desroches-Escocia",
            "priority": 0
        },
        {
            "iataCode": "DEI",
            "countryCode": "SC",
            "cityCode": "DEI",
            "cityName": "Denis Island",
            "name": "[DEI] - Denis Island - DEI - Denis Island - Escocia",
            "searchName": "DEI-Denis Island-Escocia",
            "priority": 0
        },
        {
            "iataCode": "FRK",
            "countryCode": "SC",
            "cityCode": "FRK",
            "cityName": "Fregate Island",
            "name": "[FRK] - Fregate Island - FRK - Fregate Island - Escocia",
            "searchName": "FRK-Fregate Island-Escocia",
            "priority": 0
        },
        {
            "iataCode": "GBU",
            "countryCode": "SD",
            "cityCode": "GBU",
            "cityName": "Khashm El Girba",
            "name": "[GBU] - Khashm El Girba - GBU - Khashm El Girba - Sudan",
            "searchName": "GBU-Khashm El Girba-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "GSU",
            "countryCode": "SD",
            "cityCode": "GSU",
            "cityName": "Gedaref",
            "name": "[GSU] - Gedaref - GSU - Gedaref - Sudan",
            "searchName": "GSU-Gedaref-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "HGI",
            "countryCode": "SD",
            "cityCode": "HGI",
            "cityName": "Higlieg",
            "name": "[HGI] - Higlieg - HGI - Higlieg - Sudan",
            "searchName": "HGI-Higlieg-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "KSL",
            "countryCode": "SD",
            "cityCode": "KSL",
            "cityName": "Kassala",
            "name": "[KSL] - Kassala - KSL - Kassala - Sudan",
            "searchName": "KSL-Kassala-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "KST",
            "countryCode": "SD",
            "cityCode": "KST",
            "cityName": "Kosti",
            "name": "[KST] - Kosti - KST - Kosti - Sudan",
            "searchName": "KST-Kosti-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "KRT",
            "countryCode": "SD",
            "cityCode": "KRT",
            "cityName": "Khartoum",
            "name": "[KRT] - Civil - KRT - Khartoum - Sudan",
            "searchName": "KRT-Khartoum-Civil-Civil Airport-Khartum-Hartum-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "KDX",
            "countryCode": "SD",
            "cityCode": "KDX",
            "cityName": "Kadugli",
            "name": "[KDX] - Kadugli - KDX - Kadugli - Sudan",
            "searchName": "KDX-Kadugli-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "DNI",
            "countryCode": "SD",
            "cityCode": "DNI",
            "cityName": "Wad Medani",
            "name": "[DNI] - Wad Medani - DNI - Wad Medani - Sudan",
            "searchName": "DNI-Wad Medani-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "DOG",
            "countryCode": "SD",
            "cityCode": "DOG",
            "cityName": "Dongola",
            "name": "[DOG] - Dongola - DOG - Dongola - Sudan",
            "searchName": "DOG-Dongola-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "DNX",
            "countryCode": "SD",
            "cityCode": "DNX",
            "cityName": "Dinder",
            "name": "[DNX] - Galegu - DNX - Dinder - Sudan",
            "searchName": "DNX-Galegu-Dinder-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "EGN",
            "countryCode": "SD",
            "cityCode": "EGN",
            "cityName": "Geneina",
            "name": "[EGN] - Geneina - EGN - Geneina - Sudan",
            "searchName": "EGN-Geneina-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "EBD",
            "countryCode": "SD",
            "cityCode": "EBD",
            "cityName": "El Obeid",
            "name": "[EBD] - El Obeid - EBD - El Obeid - Sudan",
            "searchName": "EBD-El Obeid-Al-Ubayyid-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "EDB",
            "countryCode": "SD",
            "cityCode": "EDB",
            "cityName": "Eldebba",
            "name": "[EDB] - Eldebba - EDB - Eldebba - Sudan",
            "searchName": "EDB-Eldebba-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "ELF",
            "countryCode": "SD",
            "cityCode": "ELF",
            "cityName": "El Fasher",
            "name": "[ELF] - El Fasher - ELF - El Fasher - Sudan",
            "searchName": "ELF-El Fasher-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "BJE",
            "countryCode": "SD",
            "cityCode": "BJE",
            "cityName": "Baleela",
            "name": "[BJE] - Baleela - BJE - Baleela - Sudan",
            "searchName": "BJE-Baleela-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "ATB",
            "countryCode": "SD",
            "cityCode": "ATB",
            "cityName": "Atbara",
            "name": "[ATB] - Atbara - ATB - Atbara - Sudan",
            "searchName": "ATB-Atbara-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "WHF",
            "countryCode": "SD",
            "cityCode": "WHF",
            "cityName": "Wadi Halfa",
            "name": "[WHF] - Wadi Halfa - WHF - Wadi Halfa - Sudan",
            "searchName": "WHF-Wadi Halfa-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "UYL",
            "countryCode": "SD",
            "cityCode": "UYL",
            "cityName": "Nyala",
            "name": "[UYL] - Nyala - UYL - Nyala - Sudan",
            "searchName": "UYL-Nyala-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "RSS",
            "countryCode": "SD",
            "cityCode": "RSS",
            "cityName": "Roseires",
            "name": "[RSS] - Roseires - RSS - Roseires - Sudan",
            "searchName": "RSS-Roseires-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "PZU",
            "countryCode": "SD",
            "cityCode": "PZU",
            "cityName": "Port Sudan",
            "name": "[PZU] - Port Sudan - PZU - Port Sudan - Sudan",
            "searchName": "PZU-Port Sudan-Port Sudan New intl Arpt-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "NUD",
            "countryCode": "SD",
            "cityCode": "NUD",
            "cityName": "En Nahud",
            "name": "[NUD] - En Nahud - NUD - En Nahud - Sudan",
            "searchName": "NUD-En Nahud-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "MWE",
            "countryCode": "SD",
            "cityCode": "MWE",
            "cityName": "Merowe",
            "name": "[MWE] - Merowe - MWE - Merowe - Sudan",
            "searchName": "MWE-Merowe-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "NHF",
            "countryCode": "SD",
            "cityCode": "NHF",
            "cityName": "New Halfa",
            "name": "[NHF] - New Halfa - NHF - New Halfa - Sudan",
            "searchName": "NHF-New Halfa-Sudan-Soudan-Sudán",
            "priority": 0
        },
        {
            "iataCode": "MXX",
            "countryCode": "SE",
            "cityCode": "MXX",
            "cityName": "Mora",
            "name": "[MXX] - Mora - MXX - Mora - Sweden",
            "searchName": "MXX-Mora-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "NRK",
            "countryCode": "SE",
            "cityCode": "NRK",
            "cityName": "Norrkoping",
            "name": "[NRK] - Kungsangen - NRK - Norrkoping - Sweden",
            "searchName": "NRK-Norrköping-Kungsangen-Kungsangen Apt.-Norrk-Norrkoping-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYK",
            "countryCode": "SE",
            "cityCode": "NRK",
            "cityName": "Norrkoping",
            "name": "[XYK] - Norrkoping C Rail Station - NRK - Norrkoping - Sweden",
            "searchName": "XYK-NRK-Norrkoping C Rail Station-Norrk-Norrkoping-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "OER",
            "countryCode": "SE",
            "cityCode": "OER",
            "cityName": "Ornskoldsvik",
            "name": "[OER] - Ornskoldsvik - OER - Ornskoldsvik - Sweden",
            "searchName": "OER-Ornskoldsvik-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "MMX",
            "countryCode": "SE",
            "cityCode": "MMA",
            "cityName": "Malmo",
            "name": "[MMX] - Sturup - MMA - Malmo - Sweden",
            "searchName": "MMX-MMA-Malmö-Sturup-Malm-Malmo-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "JMM",
            "countryCode": "SE",
            "cityCode": "MMA",
            "cityName": "Malmo",
            "name": "[JMM] - Malmo Harbour Heliport - MMA - Malmo - Sweden",
            "searchName": "JMM-MMA-Malmo Harbour Heliport-Malm-Malmo-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XFP",
            "countryCode": "SE",
            "cityCode": "MMA",
            "cityName": "Malmo",
            "name": "[XFP] - Malmo C Railway Station - MMA - Malmo - Sweden",
            "searchName": "XFP-MMA-Malmo C Railway Station-Malm-Malmo-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XFR",
            "countryCode": "SE",
            "cityCode": "MMA",
            "cityName": "Malmo",
            "name": "[XFR] - Malmo Syd Railway Station - MMA - Malmo - Sweden",
            "searchName": "XFR-MMA-Malmo Syd Railway Station-Malmo South Railway-Malm-Malmo-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "QYX",
            "countryCode": "SE",
            "cityCode": "QYX",
            "cityName": "Uppsala",
            "name": "[QYX] - Uppsala C Railway Station - QYX - Uppsala - Sweden",
            "searchName": "QYX-Uppsala C Railway Station-Uppsala-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "RNB",
            "countryCode": "SE",
            "cityCode": "RNB",
            "cityName": "Ronneby",
            "name": "[RNB] - Kallinge - RNB - Ronneby - Sweden",
            "searchName": "RNB-Ronneby-Kallinge-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XWK",
            "countryCode": "SE",
            "cityCode": "RNB",
            "cityName": "Ronneby",
            "name": "[XWK] - Karlskrona RailwaySvc. - RNB - Ronneby - Sweden",
            "searchName": "XWK-RNB-Karlskrona RailwaySvc.-Ronneby-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XXY",
            "countryCode": "SE",
            "cityCode": "RNB",
            "cityName": "Ronneby",
            "name": "[XXY] - Ronneby Railway Station - RNB - Ronneby - Sweden",
            "searchName": "XXY-RNB-Ronneby Railway Station-Ronneby-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "PJA",
            "countryCode": "SE",
            "cityCode": "PJA",
            "cityName": "Pajala",
            "name": "[PJA] - Pajala - PJA - Pajala - Sweden",
            "searchName": "PJA-Pajala-Pajala Havalimani-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "OSD",
            "countryCode": "SE",
            "cityCode": "OSD",
            "cityName": "Ostersund",
            "name": "[OSD] - Ã…re Ã–stersund Airport - OSD - Ostersund - Sweden",
            "searchName": "OSD-Ostersund-Åre Östersund Airport-Froesoe-Are-Ostersund Apt.-Ã…re Ã–stersund Airport-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "OSK",
            "countryCode": "SE",
            "cityCode": "OSK",
            "cityName": "Oskarshamn",
            "name": "[OSK] - Oskarshamn - OSK - Oskarshamn - Sweden",
            "searchName": "OSK-Oskarshamn-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "ORB",
            "countryCode": "SE",
            "cityCode": "ORB",
            "cityName": "Orebro",
            "name": "[ORB] - Orebro-bofors - ORB - Orebro - Sweden",
            "searchName": "ORB-Örebro-Orebro-bofors-Bofors-Obrebro-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XWR",
            "countryCode": "SE",
            "cityCode": "ORB",
            "cityName": "Orebro",
            "name": "[XWR] - Orebro C Railway Station - ORB - Orebro - Sweden",
            "searchName": "XWR-ORB-Orebro C Railway Station-Orebro-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "VBY",
            "countryCode": "SE",
            "cityCode": "VBY",
            "cityName": "Visby",
            "name": "[VBY] - Visby - VBY - Visby - Sweden",
            "searchName": "VBY-Visby-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "VHM",
            "countryCode": "SE",
            "cityCode": "VHM",
            "cityName": "Vilhelmina",
            "name": "[VHM] - Vilhelmina - VHM - Vilhelmina - Sweden",
            "searchName": "VHM-Vilhelmina-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "UME",
            "countryCode": "SE",
            "cityCode": "UME",
            "cityName": "Umea",
            "name": "[UME] - Umea - UME - Umea - Sweden",
            "searchName": "UME-Umea-Alvik Apt-Ume-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "TYF",
            "countryCode": "SE",
            "cityCode": "TYF",
            "cityName": "Torsby",
            "name": "[TYF] - Torsby Airport - TYF - Torsby - Sweden",
            "searchName": "TYF-Torsby-Torsby Airport-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "VVK",
            "countryCode": "SE",
            "cityCode": "VVK",
            "cityName": "Vastervik",
            "name": "[VVK] - Vastervik - VVK - Vastervik - Sweden",
            "searchName": "VVK-Vastervik-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "VXO",
            "countryCode": "SE",
            "cityCode": "VXO",
            "cityName": "Växjö",
            "name": "[VXO] - Smaland Airport - VXO - Växjö - Sweden",
            "searchName": "VXO-Vaxjo-Smaland Airport-V-Växjö-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYC",
            "countryCode": "SE",
            "cityCode": "XYC",
            "cityName": "Herrljunga",
            "name": "[XYC] - Herrljunga - XYC - Herrljunga - Sweden",
            "searchName": "XYC-Herrljunga-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYU",
            "countryCode": "SE",
            "cityCode": "XYU",
            "cityName": "Solvesborg",
            "name": "[XYU] - Solvesborg - XYU - Solvesborg - Sweden",
            "searchName": "XYU-Solvesborg-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYX",
            "countryCode": "SE",
            "cityCode": "XYX",
            "cityName": "Sala",
            "name": "[XYX] - Sala - XYX - Sala - Sweden",
            "searchName": "XYX-Sala-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYY",
            "countryCode": "SE",
            "cityCode": "XYY",
            "cityName": "Arvika",
            "name": "[XYY] - Arvika Railway Station - XYY - Arvika - Sweden",
            "searchName": "XYY-Arvika Railway Station-Arvika-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XXM",
            "countryCode": "SE",
            "cityCode": "XXM",
            "cityName": "Mjolby",
            "name": "[XXM] - Mjolby Railway Station - XXM - Mjolby - Sweden",
            "searchName": "XXM-Mjolby Railway Station-Mjolby-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XXO",
            "countryCode": "SE",
            "cityCode": "XXO",
            "cityName": "Leksand",
            "name": "[XXO] - Leksand Railway Station - XXO - Leksand - Sweden",
            "searchName": "XXO-Leksand Railway Station-Leksand-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XXD",
            "countryCode": "SE",
            "cityCode": "XXD",
            "cityName": "Degerfors",
            "name": "[XXD] - Degerfors Railway Station - XXD - Degerfors - Sweden",
            "searchName": "XXD-Degerfors Railway Station-Degerfors-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XXI",
            "countryCode": "SE",
            "cityCode": "XXI",
            "cityName": "Koping",
            "name": "[XXI] - Koping Railway Station - XXI - Koping - Sweden",
            "searchName": "XXI-Koping Railway Station-Koping-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XXK",
            "countryCode": "SE",
            "cityCode": "XXK",
            "cityName": "Katrineholm",
            "name": "[XXK] - Katrineholm - XXK - Katrineholm - Sweden",
            "searchName": "XXK-Katrineholm-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XXT",
            "countryCode": "SE",
            "cityCode": "XXT",
            "cityName": "Arboga",
            "name": "[XXT] - Arboga Railway Station - XXT - Arboga - Sweden",
            "searchName": "XXT-Arboga Railway Station-Arboga-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XXU",
            "countryCode": "SE",
            "cityCode": "XXU",
            "cityName": "Hedemora",
            "name": "[XXU] - Hedemora - XXU - Hedemora - Sweden",
            "searchName": "XXU-Hedemora-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XXV",
            "countryCode": "SE",
            "cityCode": "XXV",
            "cityName": "Kumla",
            "name": "[XXV] - Kumla Railway Station - XXV - Kumla - Sweden",
            "searchName": "XXV-Kumla Railway Station-Kumla-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYF",
            "countryCode": "SE",
            "cityCode": "XYF",
            "cityName": "Falkoping",
            "name": "[XYF] - Falkoping C Rail Station - XYF - Falkoping - Sweden",
            "searchName": "XYF-Falkoping C Rail Station-Falkoping-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYI",
            "countryCode": "SE",
            "cityCode": "XYI",
            "cityName": "Flen",
            "name": "[XYI] - Flen - XYI - Flen - Sweden",
            "searchName": "XYI-Flen-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYM",
            "countryCode": "SE",
            "cityCode": "XYM",
            "cityName": "Falkenberg",
            "name": "[XYM] - Falkenberg Railway - XYM - Falkenberg - Sweden",
            "searchName": "XYM-Falkenberg Railway-Falkenberg-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYN",
            "countryCode": "SE",
            "cityCode": "XYN",
            "cityName": "Kristinehamn",
            "name": "[XYN] - Kristinehamn Railway - XYN - Kristinehamn - Sweden",
            "searchName": "XYN-Kristinehamn Railway-Kristinehamn-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYO",
            "countryCode": "SE",
            "cityCode": "XYO",
            "cityName": "Karlshamn",
            "name": "[XYO] - Karlshamn - XYO - Karlshamn - Sweden",
            "searchName": "XYO-Karlshamn-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYP",
            "countryCode": "SE",
            "cityCode": "XYP",
            "cityName": "Avesta Krylbo",
            "name": "[XYP] - Avesta Krylbo - XYP - Avesta Krylbo - Sweden",
            "searchName": "XYP-Avesta Krylbo-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XWP",
            "countryCode": "SE",
            "cityCode": "XWP",
            "cityName": "Hassleholm",
            "name": "[XWP] - Hassleholm - XWP - Hassleholm - Sweden",
            "searchName": "XWP-Hassleholm-H-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XWQ",
            "countryCode": "SE",
            "cityCode": "XWQ",
            "cityName": "Enkoping",
            "name": "[XWQ] - Enkoping - XWQ - Enkoping - Sweden",
            "searchName": "XWQ-Enkoping-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XWV",
            "countryCode": "SE",
            "cityCode": "XWV",
            "cityName": "Varberg",
            "name": "[XWV] - Varberg Railway Station - XWV - Varberg - Sweden",
            "searchName": "XWV-Varberg Railway Station-Varberg-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XWX",
            "countryCode": "SE",
            "cityCode": "XWX",
            "cityName": "Nassjo",
            "name": "[XWX] - Nassjo C Railway Station - XWX - Nassjo - Sweden",
            "searchName": "XWX-Nassjo C Railway Station-Nassjo-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XWZ",
            "countryCode": "SE",
            "cityCode": "XWZ",
            "cityName": "Nykoping",
            "name": "[XWZ] - Nykoping - XWZ - Nykoping - Sweden",
            "searchName": "XWZ-Nykoping-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XXA",
            "countryCode": "SE",
            "cityCode": "XXA",
            "cityName": "Alvesta",
            "name": "[XXA] - Alvesta - XXA - Alvesta - Sweden",
            "searchName": "XXA-Alvesta-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XWM",
            "countryCode": "SE",
            "cityCode": "XWM",
            "cityName": "Hallsberg",
            "name": "[XWM] - Hallsberg Railway Station - XWM - Hallsberg - Sweden",
            "searchName": "XWM-Hallsberg Railway Station-Hallsberg-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XFH",
            "countryCode": "SE",
            "cityCode": "XFH",
            "cityName": "Strangnas",
            "name": "[XFH] - Strangnas Railway Station - XFH - Strangnas - Sweden",
            "searchName": "XFH-Strangnas Railway Station-Strangnas-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XFU",
            "countryCode": "SE",
            "cityCode": "XFU",
            "cityName": "Tierp",
            "name": "[XFU] - Tierp Railway Station - XFU - Tierp - Sweden",
            "searchName": "XFU-Tierp Railway Station-Tierp-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XEW",
            "countryCode": "SE",
            "cityCode": "XEW",
            "cityName": "Flemingsberg",
            "name": "[XEW] - Flemingsberg Railway Station - XEW - Flemingsberg - Sweden",
            "searchName": "XEW-Flemingsberg Railway Station-Flemingsberg-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XGC",
            "countryCode": "SE",
            "cityCode": "XGC",
            "cityName": "Lund",
            "name": "[XGC] - Lund C Railway Station - XGC - Lund - Sweden",
            "searchName": "XGC-Lund C Railway Station-Lund-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "MTS",
            "countryCode": "SZ",
            "cityCode": "MTS",
            "cityName": "Manzini",
            "name": "[MTS] - Matsapha International - MTS - Manzini - Swaziland",
            "searchName": "MTS-Manzini-Matsapha International-Matsapha Intl-Suazilândia-Swaziland-Suazilandia-Swasiland-Suazi-Svaasim",
            "priority": 0
        },
        {
            "iataCode": "MDS",
            "countryCode": "TC",
            "cityCode": "MDS",
            "cityName": "Middle Caicos",
            "name": "[MDS] - Middle Caicos - MDS - Middle Caicos - Turks and Caicos Islands",
            "searchName": "MDS-Middle Caicos-Turks and Caicos Islands-Turks- und Caicosinseln-Turks ve Caicos Adalari-Islas Turcas y Caicos-Îles Turks et Caïques-Isole Turks e Caicos",
            "priority": 0
        },
        {
            "iataCode": "NCA",
            "countryCode": "TC",
            "cityCode": "NCA",
            "cityName": "North Caicos",
            "name": "[NCA] - North Caicos - NCA - North Caicos - Turks and Caicos Islands",
            "searchName": "NCA-North Caicos-Turks and Caicos Islands-Turks- und Caicosinseln-Turks ve Caicos Adalari-Islas Turcas y Caicos-Îles Turks et Caïques-Isole Turks e Caicos",
            "priority": 0
        },
        {
            "iataCode": "PLS",
            "countryCode": "TC",
            "cityCode": "PLS",
            "cityName": "Providenciales",
            "name": "[PLS] - International - PLS - Providenciales - Turks and Caicos Islands",
            "searchName": "PLS-Providenciales-International-Turks and Caicos Islands-Turks- und Caicosinseln-Turks ve Caicos Adalari-Islas Turcas y Caicos-Îles Turks et Caïques-Isole Turks e Caicos",
            "priority": 0
        },
        {
            "iataCode": "PIC",
            "countryCode": "TC",
            "cityCode": "PIC",
            "cityName": "Pine Cay",
            "name": "[PIC] - Pine Cay - PIC - Pine Cay - Turks and Caicos Islands",
            "searchName": "PIC-Pine Cay-Turks and Caicos Islands-Turks- und Caicosinseln-Turks ve Caicos Adalari-Islas Turcas y Caicos-Îles Turks et Caïques-Isole Turks e Caicos",
            "priority": 0
        },
        {
            "iataCode": "SLX",
            "countryCode": "TC",
            "cityCode": "SLX",
            "cityName": "Salt Cay",
            "name": "[SLX] - Salt Cay - SLX - Salt Cay - Turks and Caicos Islands",
            "searchName": "SLX-Salt Cay-Salt Cay Airport-Balfour Town-Turks and Caicos Islands-Turks- und Caicosinseln-Turks ve Caicos Adalari-Islas Turcas y Caicos-Îles Turks et Caïques-Isole Turks e Caicos",
            "priority": 0
        },
        {
            "iataCode": "XSC",
            "countryCode": "TC",
            "cityCode": "XSC",
            "cityName": "South Caicos",
            "name": "[XSC] - International - XSC - South Caicos - Turks and Caicos Islands",
            "searchName": "XSC-South Caicos-International-South Caicos Intl Arpt-Cockburn Town-Turks and Caicos Islands-Turks- und Caicosinseln-Turks ve Caicos Adalari-Islas Turcas y Caicos-Îles Turks et Caïques-Isole Turks e Caicos",
            "priority": 0
        },
        {
            "iataCode": "GDT",
            "countryCode": "TC",
            "cityCode": "GDT",
            "cityName": "Grand Turk Island",
            "name": "[GDT] - Grand Turk Island - GDT - Grand Turk Island - Turks and Caicos Islands",
            "searchName": "GDT-Grand Turk Is-Grand Turk Island-Grand Turk Intl-Cockburn Town-Turks and Caicos Islands-Turks- und Caicosinseln-Turks ve Caicos Adalari-Islas Turcas y Caicos-Îles Turks et Caïques-Isole Turks e Caicos",
            "priority": 0
        },
        {
            "iataCode": "FYT",
            "countryCode": "TD",
            "cityCode": "FYT",
            "cityName": "Faya",
            "name": "[FYT] - Faya - FYT - Faya - Chad",
            "searchName": "FYT-Faya-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "LTC",
            "countryCode": "TD",
            "cityCode": "LTC",
            "cityName": "Lai",
            "name": "[LTC] - Lai - LTC - Lai - Chad",
            "searchName": "LTC-Lai-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "AMC",
            "countryCode": "TD",
            "cityCode": "AMC",
            "cityName": "Am Timan",
            "name": "[AMC] - Am Timan - AMC - Am Timan - Chad",
            "searchName": "AMC-Am Timan-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "AMO",
            "countryCode": "TD",
            "cityCode": "AMO",
            "cityName": "Mao",
            "name": "[AMO] - Mao - AMO - Mao - Chad",
            "searchName": "AMO-Mao-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "AKM",
            "countryCode": "TD",
            "cityCode": "AKM",
            "cityName": "Zakouma",
            "name": "[AKM] - Zakouma - AKM - Zakouma - Chad",
            "searchName": "AKM-Zakouma-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "AEH",
            "countryCode": "TD",
            "cityCode": "AEH",
            "cityName": "Abéché",
            "name": "[AEH] - AbÃ©chÃ© - AEH - Abéché - Chad",
            "searchName": "AEH-Abéché-AbÃ©chÃ©-Ab-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "AOD",
            "countryCode": "TD",
            "cityCode": "AOD",
            "cityName": "Abou Deia",
            "name": "[AOD] - Abou Deia - AOD - Abou Deia - Chad",
            "searchName": "AOD-Abou Deia-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "ATV",
            "countryCode": "TD",
            "cityCode": "ATV",
            "cityName": "Ati",
            "name": "[ATV] - Ati - ATV - Ati - Chad",
            "searchName": "ATV-Ati-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "BKR",
            "countryCode": "TD",
            "cityCode": "BKR",
            "cityName": "Bokoro",
            "name": "[BKR] - Bokoro - BKR - Bokoro - Chad",
            "searchName": "BKR-Bokoro-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "SRH",
            "countryCode": "TD",
            "cityCode": "SRH",
            "cityName": "Sarh",
            "name": "[SRH] - Sarh - SRH - Sarh - Chad",
            "searchName": "SRH-Sarh-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "PLF",
            "countryCode": "TD",
            "cityCode": "PLF",
            "cityName": "Pala",
            "name": "[PLF] - Pala - PLF - Pala - Chad",
            "searchName": "PLF-Pala-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "OUT",
            "countryCode": "TD",
            "cityCode": "OUT",
            "cityName": "Bousso",
            "name": "[OUT] - Bousso - OUT - Bousso - Chad",
            "searchName": "OUT-Bousso-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "OTC",
            "countryCode": "TD",
            "cityCode": "OTC",
            "cityName": "Bol",
            "name": "[OTC] - Bol - OTC - Bol - Chad",
            "searchName": "OTC-Bol-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "OUM",
            "countryCode": "TD",
            "cityCode": "OUM",
            "cityName": "Oum Hadjer",
            "name": "[OUM] - Oum Hadjer - OUM - Oum Hadjer - Chad",
            "searchName": "OUM-Oum Hadjer-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "NDJ",
            "countryCode": "TD",
            "cityCode": "NDJ",
            "cityName": "N Djamena",
            "name": "[NDJ] - NDjamena - NDJ - N Djamena - Chad",
            "searchName": "NDJ-N Djamena-Yamena-Ndjamena-NDjamena-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "MVO",
            "countryCode": "TD",
            "cityCode": "MVO",
            "cityName": "Mongo",
            "name": "[MVO] - Mongo - MVO - Mongo - Chad",
            "searchName": "MVO-Mongo-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "OGR",
            "countryCode": "TD",
            "cityCode": "OGR",
            "cityName": "Bongor",
            "name": "[OGR] - Bongor - OGR - Bongor - Chad",
            "searchName": "OGR-Bongor-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "MEF",
            "countryCode": "TD",
            "cityCode": "MEF",
            "cityName": "Melfi",
            "name": "[MEF] - Melfi - MEF - Melfi - Chad",
            "searchName": "MEF-Melfi-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "MQQ",
            "countryCode": "TD",
            "cityCode": "MQQ",
            "cityName": "Moundou",
            "name": "[MQQ] - Moundou - MQQ - Moundou - Chad",
            "searchName": "MQQ-Moundou-Cad-Tchad-Tšad-Çad-Txad-Csád-Ciad-Cadas-Cada-Chade-Czad-Chad-Tsjad-Tšaad-Tschad-Tsjaad",
            "priority": 0
        },
        {
            "iataCode": "LRL",
            "countryCode": "TG",
            "cityCode": "LRL",
            "cityName": "Niamtougou",
            "name": "[LRL] - Niamtougou - LRL - Niamtougou - Togo",
            "searchName": "LRL-Niamtougou-Togo",
            "priority": 0
        },
        {
            "iataCode": "LFW",
            "countryCode": "TG",
            "cityCode": "LFW",
            "cityName": "Lome",
            "name": "[LFW] - Lome - LFW - Lome - Togo",
            "searchName": "LFW-Lome-Lom-Togo",
            "priority": 0
        },
        {
            "iataCode": "LPT",
            "countryCode": "TH",
            "cityCode": "LPT",
            "cityName": "Lampang",
            "name": "[LPT] - Lampang - LPT - Lampang - Thailand",
            "searchName": "LPT-Lampang-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "LOE",
            "countryCode": "TH",
            "cityCode": "LOE",
            "cityName": "Loei",
            "name": "[LOE] - Loei - LOE - Loei - Thailand",
            "searchName": "LOE-Loei-Loei Havalimani-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "KBV",
            "countryCode": "TH",
            "cityCode": "KBV",
            "cityName": "Krabi",
            "name": "[KBV] - Krabi - KBV - Krabi - Thailand",
            "searchName": "KBV-Krabi-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "KDT",
            "countryCode": "TH",
            "cityCode": "KDT",
            "cityName": "Kamphangsaen",
            "name": "[KDT] - Kamphangsaen - KDT - Kamphangsaen - Thailand",
            "searchName": "KDT-Kamphangsaen-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "KKM",
            "countryCode": "TH",
            "cityCode": "KKM",
            "cityName": "Lop Buri",
            "name": "[KKM] - Lop Buri - KKM - Lop Buri - Thailand",
            "searchName": "KKM-Lop Buri-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "KKC",
            "countryCode": "TH",
            "cityCode": "KKC",
            "cityName": "Khon Kaen",
            "name": "[KKC] - Khon Kaen - KKC - Khon Kaen - Thailand",
            "searchName": "KKC-Khon Kaen-Khon Kaen Havalimani-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "KOP",
            "countryCode": "TH",
            "cityCode": "KOP",
            "cityName": "Nakhon Phanom",
            "name": "[KOP] - Nakhon Phanom - KOP - Nakhon Phanom - Thailand",
            "searchName": "KOP-Nakhon Phanom-Nakhon Phanom Havalimani-Nakorn Panom-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "HGN",
            "countryCode": "TH",
            "cityCode": "HGN",
            "cityName": "Mae Hong Son",
            "name": "[HGN] - Mae Hong Son - HGN - Mae Hong Son - Thailand",
            "searchName": "HGN-Mae Hong Son-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "HDY",
            "countryCode": "TH",
            "cityCode": "HDY",
            "cityName": "Hat Yai",
            "name": "[HDY] - Hat Yai - HDY - Hat Yai - Thailand",
            "searchName": "HDY-Hatyai-Hat Yai-Thailand-Thaimaa-Thajsko-Tayland-Tailàndia-Thaiföld-Thailanda-Tajska-Taizeme-Tajland-Tai-Tajlandia-Tailandia-Thailandia-Thail-Thaïlande",
            "priority": 0
        },
        {
            "iataCode": "XZF",
            "countryCode": "SA",
            "cityCode": "DMM",
            "cityName": "Dammam",
            "name": "[XZF] - Dammam Port - DMM - Dammam - Saudi Arabia",
            "searchName": "XZF-DMM-Dammam Port-Dammam Port Havalimani-Dammam-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "DHA",
            "countryCode": "SA",
            "cityCode": "DHA",
            "cityName": "Dhahran",
            "name": "[DHA] - King Abdulaziz AB - DHA - Dhahran - Saudi Arabia",
            "searchName": "DHA-King Abdulaziz AB-Zahran Uluslararasi Havalimani-Dhahran-Zahran-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "EAM",
            "countryCode": "SA",
            "cityCode": "EAM",
            "cityName": "Nejran",
            "name": "[EAM] - Nejran - EAM - Nejran - Saudi Arabia",
            "searchName": "EAM-Nejran-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "DWD",
            "countryCode": "SA",
            "cityCode": "DWD",
            "cityName": "Dawadmi",
            "name": "[DWD] - Dawadmi - DWD - Dawadmi - Saudi Arabia",
            "searchName": "DWD-Dawadmi-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "HAS",
            "countryCode": "SA",
            "cityCode": "HAS",
            "cityName": "Hail",
            "name": "[HAS] - Hail - HAS - Hail - Saudi Arabia",
            "searchName": "HAS-Hail-Ha il-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "GIZ",
            "countryCode": "SA",
            "cityCode": "GIZ",
            "cityName": "Jazan",
            "name": "[GIZ] - Jazan - GIZ - Jazan - Saudi Arabia",
            "searchName": "GIZ-Gizan-Jazan-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "HOF",
            "countryCode": "SA",
            "cityCode": "HOF",
            "cityName": "Alahsa",
            "name": "[HOF] - Alahsa - HOF - Alahsa - Saudi Arabia",
            "searchName": "HOF-Hofuf-Alahsa-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "KMC",
            "countryCode": "SA",
            "cityCode": "KMC",
            "cityName": "King Khalid Mil. City",
            "name": "[KMC] - King Khalid Military - KMC - King Khalid Mil. City - Saudi Arabia",
            "searchName": "KMC-King Khalid Military-King Khalid Askeri Havalimani-King Khalid Mil. City-King Khalid Military City-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "KMX",
            "countryCode": "SA",
            "cityCode": "KMX",
            "cityName": "Khamis Mushait",
            "name": "[KMX] - Khamis Mushait - KMX - Khamis Mushait - Saudi Arabia",
            "searchName": "KMX-Khamis Mushait-Khamis Mushait Havalimani-Arábia Saudita-Saoedi-Arabië-Saudi Arabia-Arab Saudi-Arabie Saoudite-Saudi A-Saudi-Arabien-Arabie saoudite-Arabia Saudí-Arabia Saudita",
            "priority": 0
        },
        {
            "iataCode": "JOK",
            "countryCode": "RU",
            "cityCode": "JOK",
            "cityName": "Yoshkar-Ola",
            "name": "[JOK] - Yoshkar-Ola - JOK - Yoshkar-Ola - Russia",
            "searchName": "JOK-Joshkar-Ola-Yoshkar-Yoskar-Ola Havalimani-Joinville-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ZZO",
            "countryCode": "RU",
            "cityCode": "ZZO",
            "cityName": "Zonalnoye",
            "name": "[ZZO] - Zonalnoye - ZZO - Zonalnoye - Russia",
            "searchName": "ZZO-Zonalnoye-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "YKS",
            "countryCode": "RU",
            "cityCode": "YKS",
            "cityName": "Yakutsk",
            "name": "[YKS] - Yakutsk - YKS - Yakutsk - Russia",
            "searchName": "YKS-Yakutsk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "YMK",
            "countryCode": "RU",
            "cityCode": "YMK",
            "cityName": "Mys-Kamenny",
            "name": "[YMK] - Mys-Kamenny - YMK - Mys-Kamenny - Russia",
            "searchName": "YMK-Mys-Kamenny-Mys Kamennyy-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KME",
            "countryCode": "RW",
            "cityCode": "KME",
            "cityName": "Kamembe",
            "name": "[KME] - Kamembe - KME - Kamembe - Rwanda",
            "searchName": "KME-Kamembe-Rwanda-Ruanda",
            "priority": 0
        },
        {
            "iataCode": "KGL",
            "countryCode": "RW",
            "cityCode": "KGL",
            "cityName": "Kigali",
            "name": "[KGL] - Kigalin International - KGL - Kigali - Rwanda",
            "searchName": "KGL-Kigali-Kigalin International-Gregoire Kayibanda-Rwanda-Ruanda",
            "priority": 0
        },
        {
            "iataCode": "GYI",
            "countryCode": "RW",
            "cityCode": "GYI",
            "cityName": "Gisenyi",
            "name": "[GYI] - Gisenyi - GYI - Gisenyi - Rwanda",
            "searchName": "GYI-Gisenyi-Gisenyi Havalimani-Rwanda-Ruanda",
            "priority": 0
        },
        {
            "iataCode": "BTQ",
            "countryCode": "RW",
            "cityCode": "BTQ",
            "cityName": "Butare",
            "name": "[BTQ] - Butare - BTQ - Butare - Rwanda",
            "searchName": "BTQ-Butare-Rwanda-Ruanda",
            "priority": 0
        },
        {
            "iataCode": "RHG",
            "countryCode": "RW",
            "cityCode": "RHG",
            "cityName": "Ruhengeri",
            "name": "[RHG] - Ruhengeri - RHG - Ruhengeri - Rwanda",
            "searchName": "RHG-Ruhengeri-Rwanda-Ruanda",
            "priority": 0
        },
        {
            "iataCode": "SDL",
            "countryCode": "SE",
            "cityCode": "SDL",
            "cityName": "Sundsvall",
            "name": "[SDL] - Sundsvall/harnosand - SDL - Sundsvall - Sweden",
            "searchName": "SDL-Sundsvall-Sundsvall/harnosand-Sundsvall/Harnosand-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XXZ",
            "countryCode": "SE",
            "cityCode": "SDL",
            "cityName": "Sundsvall",
            "name": "[XXZ] - Sundsvall C Rail Station - SDL - Sundsvall - Sweden",
            "searchName": "XXZ-SDL-Sundsvall C Rail Station-Sundsvall-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XYZ",
            "countryCode": "SE",
            "cityCode": "SDL",
            "cityName": "Sundsvall",
            "name": "[XYZ] - Harnosand Railway Station - SDL - Sundsvall - Sweden",
            "searchName": "XYZ-SDL-Harnosand Railway Station-Sundsvall-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "NYO",
            "countryCode": "SE",
            "cityCode": "STO",
            "cityName": "Stockholm",
            "name": "[NYO] - Skavsta - STO - Stockholm - Sweden",
            "searchName": "NYO-STO-Stockholm Skavsta-Skavsta-Stockholm-Estocolmo-Stoccolma-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "VST",
            "countryCode": "SE",
            "cityCode": "STO",
            "cityName": "Stockholm",
            "name": "[VST] - Vasteras/Hasslo - STO - Stockholm - Sweden",
            "searchName": "VST-STO-Stockholm Västerås-Vasteras/Hasslo-Stockholm-Vasteras Apt-Estocolmo-Stoccolma-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "XEV",
            "countryCode": "SE",
            "cityCode": "STO",
            "cityName": "Stockholm",
            "name": "[XEV] - Stockholm C Rail Station - STO - Stockholm - Sweden",
            "searchName": "XEV-STO-Stockholm C Rail Station-Stockholm-Estocolmo-Stoccolma-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "BMA",
            "countryCode": "SE",
            "cityCode": "STO",
            "cityName": "Stockholm",
            "name": "[BMA] - Bromma - STO - Stockholm - Sweden",
            "searchName": "BMA-STO-Stockholm Bromma-Bromma-Stockholm-Estocolmo-Stoccolma-Svédország-Suècia-Isveç-Švédsko-Ruotsi-Sverige-Rootsi-Suedia-Švedska-Švedija-Zviedrija-Szwecja-Swedia-Suède-Schweden-Svezia-Suecia--Sweden",
            "priority": 0
        },
        {
            "iataCode": "KKQ",
            "countryCode": "RU",
            "cityCode": "KKQ",
            "cityName": "Krasnoselkup",
            "name": "[KKQ] - Krasnoselkup - KKQ - Krasnoselkup - Russia",
            "searchName": "KKQ-Krasnoselkup-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KLD",
            "countryCode": "RU",
            "cityCode": "KLD",
            "cityName": "Tver",
            "name": "[KLD] - Migalovo - KLD - Tver - Russia",
            "searchName": "KLD-Migalovo-Kalinin-Tver-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KLF",
            "countryCode": "RU",
            "cityCode": "KLF",
            "cityName": "Kaluga",
            "name": "[KLF] - Kaluga - KLF - Kaluga - Russia",
            "searchName": "KLF-Kaluga-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "KCK",
            "countryCode": "RU",
            "cityCode": "KCK",
            "cityName": "Kirensk",
            "name": "[KCK] - Kirensk - KCK - Kirensk - Russia",
            "searchName": "KCK-Kirensk-Rusija-Oroszország-Rússia-Rusya-Venäjä-Rusland-Russland-Rusia-Rusko-Venemaa-Krievija-Ryssland-Russia-Russie--Federazione Russa",
            "priority": 0
        },
        {
            "iataCode": "ASI",
            "countryCode": "SH",
            "cityCode": "ASI",
            "cityName": "Georgetown",
            "name": "[ASI] - Wideawake Field - ASI - Georgetown - Saint Helena",
            "searchName": "ASI-Wideawake Fld-Wideawake Field-Georgetown-St. Helena-Saint Helena-Sainte-Hélène-Santa Elena-Sant’Elena",
            "priority": 0
        },
        {
            "iataCode": "ZRG",
            "countryCode": "SK",
            "cityCode": "BTS",
            "cityName": "Bratislava",
            "name": "[ZRG] - Bratislava Bus Station - BTS - Bratislava - Slovakia",
            "searchName": "ZRG-BTS-Bratislava Bus Station-Bratislava-Slowakia-Slowakei-Slovakia-Slovacka-Slovaquie-Slovensko-Slovakya-Eslovàquia-Szlovákia-Slovacia-Slovaška-Slovakija-Slovakkia-Slowacja-S-Eslovaquia-Slovacchia",
            "priority": 0
        },
        {
            "iataCode": "POV",
            "countryCode": "SK",
            "cityCode": "POV",
            "cityName": "Presov",
            "name": "[POV] - Presov - POV - Presov - Slovakia",
            "searchName": "POV-Presov-Slowakia-Slowakei-Slovakia-Slovacka-Slovaquie-Slovensko-Slovakya-Eslovàquia-Szlovákia-Slovacia-Slovaška-Slovakija-Slovakkia-Slowacja-S-Eslovaquia-Slovacchia",
            "priority": 0
        },
        {
            "iataCode": "PZY",
            "countryCode": "SK",
            "cityCode": "PZY",
            "cityName": "Piestany",
            "name": "[PZY] - Piestany - PZY - Piestany - Slovakia",
            "searchName": "PZY-Piestany-Piestany Havalimani-Slowakia-Slowakei-Slovakia-Slovacka-Slovaquie-Slovensko-Slovakya-Eslovàquia-Szlovákia-Slovacia-Slovaška-Slovakija-Slovakkia-Slowacja-S-Eslovaquia-Slovacchia",
            "priority": 0
        },
        {
            "iataCode": "SLD",
            "countryCode": "SK",
            "cityCode": "SLD",
            "cityName": "Sliac",
            "name": "[SLD] - Sliac - SLD - Sliac - Slovakia",
            "searchName": "SLD-Sliac-Sliac Havalimani-Sliak-Slowakia-Slowakei-Slovakia-Slovacka-Slovaquie-Slovensko-Slovakya-Eslovàquia-Szlovákia-Slovacia-Slovaška-Slovakija-Slovakkia-Slowacja-S-Eslovaquia-Slovacchia",
            "priority": 0
        },
        {
            "iataCode": "TAT",
            "countryCode": "SK",
            "cityCode": "TAT",
            "cityName": "Poprad",
            "name": "[TAT] - Poprad/Tatry - TAT - Poprad - Slovakia",
            "searchName": "TAT-Poprad-Poprad/Tatry-Poprad\\/Tatry-Slowakia-Slowakei-Slovakia-Slovacka-Slovaquie-Slovensko-Slovakya-Eslovàquia-Szlovákia-Slovacia-Slovaška-Slovakija-Slovakkia-Slowacja-S-Eslovaquia-Slovacchia",
            "priority": 0
        },
        {
            "iataCode": "WYE",
            "countryCode": "SL",
            "cityCode": "WYE",
            "cityName": "Yengema",
            "name": "[WYE] - Yengema - WYE - Yengema - Sierra Leone",
            "searchName": "WYE-Yengema-Sierra Leone-Sierra Leona",
            "priority": 0
        },
        {
            "iataCode": "BTE",
            "countryCode": "SL",
            "cityCode": "BTE",
            "cityName": "Bonthe",
            "name": "[BTE] - Bonthe - BTE - Bonthe - Sierra Leone",
            "searchName": "BTE-Bonthe-Sierra Leone-Sierra Leona",
            "priority": 0
        },
        {
            "iataCode": "GBK",
            "countryCode": "SL",
            "cityCode": "GBK",
            "cityName": "Gbangbatok",
            "name": "[GBK] - Gbangbatok - GBK - Gbangbatok - Sierra Leone",
            "searchName": "GBK-Gbangbatok-Gbangbatok Havalimani-Sierra Leone-Sierra Leona",
            "priority": 0
        },
        {
            "iataCode": "FNA",
            "countryCode": "SL",
            "cityCode": "FNA",
            "cityName": "Freetown",
            "name": "[FNA] - Lungi International - FNA - Freetown - Sierra Leone",
            "searchName": "FNA-Freetown Lungi Intl-Lungi International-Lungi Intl-Freetown-Sierra Leone-Sierra Leona",
            "priority": 0
        },
        {
            "iataCode": "HGS",
            "countryCode": "SL",
            "cityCode": "FNA",
            "cityName": "Freetown",
            "name": "[HGS] - Hastings - FNA - Freetown - Sierra Leone",
            "searchName": "HGS-FNA-Hastings-Freetown-Sierra Leone-Sierra Leona",
            "priority": 0
        },
        {
            "iataCode": "JMY",
            "countryCode": "SL",
            "cityCode": "FNA",
            "cityName": "Freetown",
            "name": "[JMY] - Mammy Yoko Heliport - FNA - Freetown - Sierra Leone",
            "searchName": "JMY-FNA-Mammy Yoko Heliport-Freetown-Sierra Leone-Sierra Leona",
            "priority": 0
        },
        {
            "iataCode": "KEN",
            "countryCode": "SL",
            "cityCode": "KEN",
            "cityName": "Kenema",
            "name": "[KEN] - Kenema - KEN - Kenema - Sierra Leone",
            "searchName": "KEN-Kenema-Sierra Leone-Sierra Leona",
            "priority": 0
        },
        {
            "iataCode": "KBS",
            "countryCode": "SL",
            "cityCode": "KBS",
            "cityName": "Bo",
            "name": "[KBS] - Bo - KBS - Bo - Sierra Leone",
            "searchName": "KBS-Bo-Sierra Leone-Sierra Leona",
            "priority": 0
        },
        {
            "iataCode": "KBA",
            "countryCode": "SL",
            "cityCode": "KBA",
            "cityName": "Kabala",
            "name": "[KBA] - Kabala - KBA - Kabala - Sierra Leone",
            "searchName": "KBA-Kabala-Sierra Leone-Sierra Leona",
            "priority": 0
        },
        {
            "iataCode": "KDA",
            "countryCode": "SN",
            "cityCode": "KDA",
            "cityName": "Kolda",
            "name": "[KDA] - Kolda - KDA - Kolda - Senegal",
            "searchName": "KDA-Kolda-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "KLC",
            "countryCode": "SN",
            "cityCode": "KLC",
            "cityName": "Kaolack",
            "name": "[KLC] - Kaolack - KLC - Kaolack - Senegal",
            "searchName": "KLC-Kaolack-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "KGG",
            "countryCode": "SN",
            "cityCode": "KGG",
            "cityName": "Kedougou",
            "name": "[KGG] - Kedougou - KGG - Kedougou - Senegal",
            "searchName": "KGG-Kedougou-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "BXE",
            "countryCode": "SN",
            "cityCode": "BXE",
            "cityName": "Bakel",
            "name": "[BXE] - Bakel - BXE - Bakel - Senegal",
            "searchName": "BXE-Bakel-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "DKR",
            "countryCode": "SN",
            "cityCode": "DKR",
            "cityName": "Dakar",
            "name": "[DKR] - Dakar-Yoff-LÃ©opold SÃ©dar Senghor International - DKR - Dakar - Senegal",
            "searchName": "DKR-Dakar-Yoff-Léopold Sédar Senghor International-LÃ©opold SÃ©dar Senghor International-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "CSK",
            "countryCode": "SN",
            "cityCode": "CSK",
            "cityName": "Cap Skirring",
            "name": "[CSK] - Cap Skirring - CSK - Cap Skirring - Senegal",
            "searchName": "CSK-Cap Skirring-Cap Skirring Havalimani-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "XLS",
            "countryCode": "SN",
            "cityCode": "XLS",
            "cityName": "St Louis",
            "name": "[XLS] - St Louis - XLS - St Louis - Senegal",
            "searchName": "XLS-St Louis-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "TUD",
            "countryCode": "SN",
            "cityCode": "TUD",
            "cityName": "Tambacounda",
            "name": "[TUD] - Tambacounda - TUD - Tambacounda - Senegal",
            "searchName": "TUD-Tambacounda-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "SMY",
            "countryCode": "SN",
            "cityCode": "SMY",
            "cityName": "Simenti",
            "name": "[SMY] - Simenti - SMY - Simenti - Senegal",
            "searchName": "SMY-Simenti-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "RDT",
            "countryCode": "SN",
            "cityCode": "RDT",
            "cityName": "Richard Toll",
            "name": "[RDT] - Richard Toll - RDT - Richard Toll - Senegal",
            "searchName": "RDT-Richard Toll-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "POD",
            "countryCode": "SN",
            "cityCode": "POD",
            "cityName": "Podor",
            "name": "[POD] - Podor - POD - Podor - Senegal",
            "searchName": "POD-Podor-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "MAX",
            "countryCode": "SN",
            "cityCode": "MAX",
            "cityName": "Matam",
            "name": "[MAX] - Matam - MAX - Matam - Senegal",
            "searchName": "MAX-Matam-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "NIK",
            "countryCode": "SN",
            "cityCode": "NIK",
            "cityName": "Niokolo Koba",
            "name": "[NIK] - Niokolo Koba - NIK - Niokolo Koba - Senegal",
            "searchName": "NIK-Niokolo Koba-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "ZIG",
            "countryCode": "SN",
            "cityCode": "ZIG",
            "cityName": "Ziguinchor",
            "name": "[ZIG] - Ziguinchor - ZIG - Ziguinchor - Senegal",
            "searchName": "ZIG-Ziguinchor-Ziguinchor Havalimani-Senegal-Sê nê gan-Szenegál-Sene-Sénégal",
            "priority": 0
        },
        {
            "iataCode": "MGQ",
            "countryCode": "SO",
            "cityCode": "MGQ",
            "cityName": "Mogadishu",
            "name": "[MGQ] - Mogadishu International Airport - MGQ - Mogadishu - Somalia",
            "searchName": "MGQ-Mogadishu-Mogadishu International Airport-Muqdisho-Mogadiscio-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "CMS",
            "countryCode": "SO",
            "cityCode": "CMS",
            "cityName": "Scusciuban",
            "name": "[CMS] - Scusciuban - CMS - Scusciuban - Somalia",
            "searchName": "CMS-Scusciuban-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "CMO",
            "countryCode": "SO",
            "cityCode": "CMO",
            "cityName": "Obbia",
            "name": "[CMO] - Obbia - CMO - Obbia - Somalia",
            "searchName": "CMO-Obbia-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "CXN",
            "countryCode": "SO",
            "cityCode": "CXN",
            "cityName": "Candala",
            "name": "[CXN] - Candala - CXN - Candala - Somalia",
            "searchName": "CXN-Candala-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "ERA",
            "countryCode": "SO",
            "cityCode": "ERA",
            "cityName": "Erigavo",
            "name": "[ERA] - Erigavo - ERA - Erigavo - Somalia",
            "searchName": "ERA-Erigavo-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "BXX",
            "countryCode": "SO",
            "cityCode": "BXX",
            "cityName": "Borama",
            "name": "[BXX] - Borama International - BXX - Borama - Somalia",
            "searchName": "BXX-Borama International-Borama-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "BUO",
            "countryCode": "SO",
            "cityCode": "BUO",
            "cityName": "Burao",
            "name": "[BUO] - Burao - BUO - Burao - Somalia",
            "searchName": "BUO-Burao-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "BSY",
            "countryCode": "SO",
            "cityCode": "BSY",
            "cityName": "Bardera",
            "name": "[BSY] - Bardera - BSY - Bardera - Somalia",
            "searchName": "BSY-Bardera-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "BSA",
            "countryCode": "SO",
            "cityCode": "BSA",
            "cityName": "Bossaso",
            "name": "[BSA] - Bossaso - BSA - Bossaso - Somalia",
            "searchName": "BSA-Bossaso-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "BIB",
            "countryCode": "SO",
            "cityCode": "BIB",
            "cityName": "Baidoa",
            "name": "[BIB] - Baidoa - BIB - Baidoa - Somalia",
            "searchName": "BIB-Baidoa-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "BBO",
            "countryCode": "SO",
            "cityCode": "BBO",
            "cityName": "Berbera",
            "name": "[BBO] - Berbera - BBO - Berbera - Somalia",
            "searchName": "BBO-Berbera-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "ALU",
            "countryCode": "SO",
            "cityCode": "ALU",
            "cityName": "Alula",
            "name": "[ALU] - Alula - ALU - Alula - Somalia",
            "searchName": "ALU-Alula-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "KMU",
            "countryCode": "SO",
            "cityCode": "KMU",
            "cityName": "Kismayu",
            "name": "[KMU] - Kismayu - KMU - Kismayu - Somalia",
            "searchName": "KMU-Kismayu-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "LKR",
            "countryCode": "SO",
            "cityCode": "LKR",
            "cityName": "Las Khoreh",
            "name": "[LKR] - Las Khoreh - LKR - Las Khoreh - Somalia",
            "searchName": "LKR-Las Khoreh-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "LGX",
            "countryCode": "SO",
            "cityCode": "LGX",
            "cityName": "Lugh Ganane",
            "name": "[LGX] - Lugh Ganane - LGX - Lugh Ganane - Somalia",
            "searchName": "LGX-Lugh Ganane-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "GBM",
            "countryCode": "SO",
            "cityCode": "GBM",
            "cityName": "Garbaharey",
            "name": "[GBM] - Garbaharey - GBM - Garbaharey - Somalia",
            "searchName": "GBM-Garbaharey-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "GLK",
            "countryCode": "SO",
            "cityCode": "GLK",
            "cityName": "Galcaio",
            "name": "[GLK] - Galcaio - GLK - Galcaio - Somalia",
            "searchName": "GLK-Galcaio-Gaalkacyo-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "GGR",
            "countryCode": "SO",
            "cityCode": "GGR",
            "cityName": "Garoe",
            "name": "[GGR] - Garoe - GGR - Garoe - Somalia",
            "searchName": "GGR-Garoe-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "GSR",
            "countryCode": "SO",
            "cityCode": "GSR",
            "cityName": "Gardo",
            "name": "[GSR] - Gardo - GSR - Gardo - Somalia",
            "searchName": "GSR-Gardo-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "HGA",
            "countryCode": "SO",
            "cityCode": "HGA",
            "cityName": "Hargeisa",
            "name": "[HGA] - Hargeisa - HGA - Hargeisa - Somalia",
            "searchName": "HGA-Hargeisa-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "HCM",
            "countryCode": "SO",
            "cityCode": "HCM",
            "cityName": "Eil",
            "name": "[HCM] - Eil - HCM - Eil - Somalia",
            "searchName": "HCM-Eil-Somalia-Somali-Somalie",
            "priority": 0
        },
        {
            "iataCode": "ICK",
            "countryCode": "SR",
            "cityCode": "ICK",
            "cityName": "Nieuw Nickerie",
            "name": "[ICK] - Nieuw Nickerie - ICK - Nieuw Nickerie - Suriname",
            "searchName": "ICK-Nieuw Nickerie-Nieuw Nickerie\u00A0 Havalimani-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "LDO",
            "countryCode": "SR",
            "cityCode": "LDO",
            "cityName": "Ladouanie",
            "name": "[LDO] - Ladouanie - LDO - Ladouanie - Suriname",
            "searchName": "LDO-Ladouanie-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "KCB",
            "countryCode": "SR",
            "cityCode": "KCB",
            "cityName": "Kasikasima",
            "name": "[KCB] - Tepoe Airstrip - KCB - Kasikasima - Suriname",
            "searchName": "KCB-Tepoe-Tepoe Airstrip-Kasikasima-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "AGI",
            "countryCode": "SR",
            "cityCode": "AGI",
            "cityName": "Wageningen",
            "name": "[AGI] - Wageningen - AGI - Wageningen - Suriname",
            "searchName": "AGI-Wageningen-Wagenigen Airstrip Havalimani-Wagenigen-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "ABN",
            "countryCode": "SR",
            "cityCode": "ABN",
            "cityName": "Albina",
            "name": "[ABN] - Albina - ABN - Albina - Suriname",
            "searchName": "ABN-Albina-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "AAJ",
            "countryCode": "SR",
            "cityCode": "AAJ",
            "cityName": "Awaradam",
            "name": "[AAJ] - Cayana Airstrip - AAJ - Awaradam - Suriname",
            "searchName": "AAJ-Cayana Airstrip-Cayana Airstrip Havalimani-Awaradam-Cayana-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "BTO",
            "countryCode": "SR",
            "cityCode": "BTO",
            "cityName": "Botopasie",
            "name": "[BTO] - Botopasie - BTO - Botopasie - Suriname",
            "searchName": "BTO-Botopasie-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "DOE",
            "countryCode": "SR",
            "cityCode": "DOE",
            "cityName": "Djoemoe",
            "name": "[DOE] - Djoemoe - DOE - Djoemoe - Suriname",
            "searchName": "DOE-Djoemoe-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "DRJ",
            "countryCode": "SR",
            "cityCode": "DRJ",
            "cityName": "Drietabbetje",
            "name": "[DRJ] - Drietabbetje - DRJ - Drietabbetje - Suriname",
            "searchName": "DRJ-Drietabbetje-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "MOJ",
            "countryCode": "SR",
            "cityCode": "MOJ",
            "cityName": "Moengo",
            "name": "[MOJ] - Moengo - MOJ - Moengo - Suriname",
            "searchName": "MOJ-Moengo-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "OEM",
            "countryCode": "SR",
            "cityCode": "OEM",
            "cityName": "Paloemeu",
            "name": "[OEM] - Vincent Fayks - OEM - Paloemeu - Suriname",
            "searchName": "OEM-Vincent Fayks-Paloemeu-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "PBM",
            "countryCode": "SR",
            "cityCode": "PBM",
            "cityName": "Paramaribo",
            "name": "[PBM] - Zanderij International - PBM - Paramaribo - Suriname",
            "searchName": "PBM-Paramaribo-Zanderij International-Zanderij Intl-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "ORG",
            "countryCode": "SR",
            "cityCode": "PBM",
            "cityName": "Paramaribo",
            "name": "[ORG] - Zorg En Hoop - PBM - Paramaribo - Suriname",
            "searchName": "ORG-PBM-Zorg en Hoop-Zorg En Hoop-Suriname-Paramaribo-Surinam",
            "priority": 0
        },
        {
            "iataCode": "SMZ",
            "countryCode": "SR",
            "cityCode": "SMZ",
            "cityName": "Stoelmans Eiland",
            "name": "[SMZ] - Stoelmans Eiland - SMZ - Stoelmans Eiland - Suriname",
            "searchName": "SMZ-Stoelmans Eiland-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "TOT",
            "countryCode": "SR",
            "cityCode": "TOT",
            "cityName": "Totness",
            "name": "[TOT] - Coronie - TOT - Totness - Suriname",
            "searchName": "TOT-Coronie-Totness-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "WSO",
            "countryCode": "SR",
            "cityCode": "WSO",
            "cityName": "Washabo",
            "name": "[WSO] - Washabo - WSO - Washabo - Suriname",
            "searchName": "WSO-Washabo-Suriname-Surinam",
            "priority": 0
        },
        {
            "iataCode": "WUU",
            "countryCode": "SS",
            "cityCode": "WUU",
            "cityName": "Wau",
            "name": "[WUU] - Wau - WUU - Wau - South Sudan",
            "searchName": "WUU-Wau-Wau airport-South Sudan-Sudan-Sudán del Sur-Soudan du Sud-Sudan del Sud",
            "priority": 0
        },
        {
            "iataCode": "RBX",
            "countryCode": "SS",
            "cityCode": "RBX",
            "cityName": "Rumbek",
            "name": "[RBX] - Rumbek - RBX - Rumbek - South Sudan",
            "searchName": "RBX-Rumbek-South Sudan-Sudan-Sudán del Sur-Soudan du Sud-Sudan del Sud",
            "priority": 0
        },
        {
            "iataCode": "MAK",
            "countryCode": "SS",
            "cityCode": "MAK",
            "cityName": "Malakal",
            "name": "[MAK] - Malakal - MAK - Malakal - South Sudan",
            "searchName": "MAK-Malakal-Malakal airport-South Sudan-Sudan-Sudán del Sur-Soudan du Sud-Sudan del Sud",
            "priority": 0
        },
        {
            "iataCode": "AEE",
            "countryCode": "SS",
            "cityCode": "AEE",
            "cityName": "Adareil",
            "name": "[AEE] - Adareil - AEE - Adareil - South Sudan",
            "searchName": "AEE-Adareil-South Sudan-Sudan-Sudán del Sur-Soudan du Sud-Sudan del Sud",
            "priority": 0
        },
        {
            "iataCode": "JUB",
            "countryCode": "SS",
            "cityCode": "JUB",
            "cityName": "Juba",
            "name": "[JUB] - Juba - JUB - Juba - South Sudan",
            "searchName": "JUB-Juba-Juba airport-South Sudan-Sudan-Sudán del Sur-Soudan du Sud-Sudan del Sud",
            "priority": 0
        },
        {
            "iataCode": "PCP",
            "countryCode": "ST",
            "cityCode": "PCP",
            "cityName": "Principe",
            "name": "[PCP] - Principe - PCP - Principe - Sao Tome and Principe",
            "searchName": "PCP-Principe-São Tomé und Príncipe-Sao Tome and Principe-Sao Tomé-et-Príncipe-Santo Tomé y Príncipe-Sao Tomé e Príncipe",
            "priority": 0
        },
        {
            "iataCode": "PGP",
            "countryCode": "ST",
            "cityCode": "PGP",
            "cityName": "Porto Alegre",
            "name": "[PGP] - Porto Alegre - PGP - Porto Alegre - Sao Tome and Principe",
            "searchName": "PGP-Porto Alegre-São Tomé und Príncipe-Sao Tome and Principe-Sao Tomé-et-Príncipe-Santo Tomé y Príncipe-Sao Tomé e Príncipe",
            "priority": 0
        },
        {
            "iataCode": "TMS",
            "countryCode": "ST",
            "cityCode": "TMS",
            "cityName": "Sao Tome Island",
            "name": "[TMS] - Sao Tome Island - TMS - Sao Tome Island - Sao Tome and Principe",
            "searchName": "TMS-Sao Tome Is-Sao Tome Island-S-São Tomé und Príncipe-Sao Tome and Principe-Sao Tomé-et-Príncipe-Santo Tomé y Príncipe-Sao Tomé e Príncipe",
            "priority": 0
        },
        {
            "iataCode": "CLA",
            "countryCode": "BD",
            "cityCode": "CLA",
            "cityName": "Comilla",
            "name": "[CLA] - Comilla - CLA - Comilla - Bangladesh",
            "searchName": "CLA-Comilla-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "JSR",
            "countryCode": "BD",
            "cityCode": "JSR",
            "cityName": "Jessore",
            "name": "[JSR] - Jessore - JSR - Jessore - Bangladesh",
            "searchName": "JSR-Jessore-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "IRD",
            "countryCode": "BD",
            "cityCode": "IRD",
            "cityName": "Ishurdi",
            "name": "[IRD] - Ishurdi - IRD - Ishurdi - Bangladesh",
            "searchName": "IRD-Ishurdi-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "TKR",
            "countryCode": "BD",
            "cityCode": "TKR",
            "cityName": "Thakurgaon",
            "name": "[TKR] - Thakurgaon - TKR - Thakurgaon - Bangladesh",
            "searchName": "TKR-Thakurgaon-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "SPD",
            "countryCode": "BD",
            "cityCode": "SPD",
            "cityName": "Saidpur",
            "name": "[SPD] - Saidpur - SPD - Saidpur - Bangladesh",
            "searchName": "SPD-Saidpur-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "RJH",
            "countryCode": "BD",
            "cityCode": "RJH",
            "cityName": "Rajshahi",
            "name": "[RJH] - Rajshahi - RJH - Rajshahi - Bangladesh",
            "searchName": "RJH-Rajshahi-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "ZYL",
            "countryCode": "BD",
            "cityCode": "ZYL",
            "cityName": "Sylhet",
            "name": "[ZYL] - Osmani International - ZYL - Sylhet - Bangladesh",
            "searchName": "ZYL-Sylhet-Osmani International-Civil-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "ZHM",
            "countryCode": "BD",
            "cityCode": "ZHM",
            "cityName": "Shamshernagar",
            "name": "[ZHM] - Shamshernagar - ZHM - Shamshernagar - Bangladesh",
            "searchName": "ZHM-Shamshernagar-Shamshernager-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "QMO",
            "countryCode": "BE",
            "cityCode": "QMO",
            "cityName": "Mons",
            "name": "[QMO] - Mons Railway Station - QMO - Mons - Belgium",
            "searchName": "QMO-Mons Railway Station-Mons-Belgia-Belgium-Belgien-België-Bélgica-Belgie-Belçika-Bèlgica-Belgique-Belgio",
            "priority": 0
        },
        {
            "iataCode": "OST",
            "countryCode": "BE",
            "cityCode": "OST",
            "cityName": "Ostend",
            "name": "[OST] - Ostend/Bruges International Airport - OST - Ostend - Belgium",
            "searchName": "OST-Ostende-Ostend/Bruges International Airport-Ostend-Ostend\\/Bruges-Belgia-Belgium-Belgien-België-Bélgica-Belgie-Belçika-Bèlgica-Belgique-Belgio",
            "priority": 0
        },
        {
            "iataCode": "ZGJ",
            "countryCode": "BE",
            "cityCode": "OST",
            "cityName": "Ostend",
            "name": "[ZGJ] - Brugge Railway Station - OST - Ostend - Belgium",
            "searchName": "ZGJ-OST-Brugge Railway Station-Ostend-Belgia-Belgium-Belgien-België-Bélgica-Belgie-Belçika-Bèlgica-Belgique-Belgio",
            "priority": 0
        },
        {
            "iataCode": "OBL",
            "countryCode": "BE",
            "cityCode": "OBL",
            "cityName": "Zoersel",
            "name": "[OBL] - Zoersel - OBL - Zoersel - Belgium",
            "searchName": "OBL-Zoersel-Belgia-Belgium-Belgien-België-Bélgica-Belgie-Belçika-Bèlgica-Belgique-Belgio",
            "priority": 0
        },
        {
            "iataCode": "KJK",
            "countryCode": "BE",
            "cityCode": "KJK",
            "cityName": "Kortrijk",
            "name": "[KJK] - Kortrijk - KJK - Kortrijk - Belgium",
            "searchName": "KJK-Kortrijk-Kortrijk Havalimani-Belgia-Belgium-Belgien-België-Bélgica-Belgie-Belçika-Bèlgica-Belgique-Belgio",
            "priority": 0
        },
        {
            "iataCode": "LGG",
            "countryCode": "BE",
            "cityCode": "LGG",
            "cityName": "Liege",
            "name": "[LGG] - Liege Airport - LGG - Liege - Belgium",
            "searchName": "LGG-Liege-Liege Airport-Bierset-L-Belgia-Belgium-Belgien-België-Bélgica-Belgie-Belçika-Bèlgica-Belgique-Belgio",
            "priority": 0
        },
        {
            "iataCode": "XHN",
            "countryCode": "BE",
            "cityCode": "LGG",
            "cityName": "Liege",
            "name": "[XHN] - Guillemins, Raiway Stn - LGG - Liege - Belgium",
            "searchName": "XHN-LGG-Guillemins- Raiway Stn-Guillemins, Raiway Stn-L-Liege-Belgia-Belgium-Belgien-België-Bélgica-Belgie-Belçika-Bèlgica-Belgique-Belgio",
            "priority": 0
        },
        {
            "iataCode": "TGV",
            "countryCode": "BG",
            "cityCode": "TGV",
            "cityName": "Targovishte",
            "name": "[TGV] - Targovishte - TGV - Targovishte - Bulgaria",
            "searchName": "TGV-Targovishte-Bulgária-Bulgària-Bulgaristan-Bulharsko-Bulgaria-Bulgarien-Bugarska-Bulgaaria-Bulgarija-Bolgarija--Bulgarie",
            "priority": 0
        },
        {
            "iataCode": "SZR",
            "countryCode": "BG",
            "cityCode": "SZR",
            "cityName": "Stara Zagora",
            "name": "[SZR] - Stara Zagora - SZR - Stara Zagora - Bulgaria",
            "searchName": "SZR-Stara Zagora-Stara Zagora Havalimani-Bulgária-Bulgària-Bulgaristan-Bulharsko-Bulgaria-Bulgarien-Bugarska-Bulgaaria-Bulgarija-Bolgarija--Bulgarie",
            "priority": 0
        },
        {
            "iataCode": "SJJ",
            "countryCode": "BA",
            "cityCode": "SJJ",
            "cityName": "Sarajevo",
            "name": "[SJJ] - Sarajevo - SJJ - Sarajevo - Bosnia and Herzegovina",
            "searchName": "SJJ-Sarajevo-Butmir-Sarajevo Int. Apt.-Bosznia-Hercegovina-Bòsnia i Hercegovina-Bosna Hersek-Bosna a Hercegovina-Bosnia ja Hertsegovina-Bosnien-Bosnia-Bosnia si Hertegovin-Bosnien und Herzegovina-Bosnia and Herzegovina-Bosna-Hersek-Bosnie-Herzégovine-Herzegovina-Bosnia Erzegovina",
            "priority": 0
        },
        {
            "iataCode": "TZL",
            "countryCode": "BA",
            "cityCode": "TZL",
            "cityName": "Tuzla",
            "name": "[TZL] - Tuzla International - TZL - Tuzla - Bosnia and Herzegovina",
            "searchName": "TZL-Tuzla International-Tuzla Uluslararasi Havalimani-Tuzla-Bosznia-Hercegovina-Bòsnia i Hercegovina-Bosna Hersek-Bosna a Hercegovina-Bosnia ja Hertsegovina-Bosnien-Bosnia-Bosnia si Hertegovin-Bosnien und Herzegovina-Bosnia and Herzegovina-Bosna-Hersek-Bosnie-Herzégovine-Herzegovina-Bosnia Erzegovina",
            "priority": 0
        },
        {
            "iataCode": "SLS",
            "countryCode": "BG",
            "cityCode": "SLS",
            "cityName": "Silistra",
            "name": "[SLS] - Silistra - SLS - Silistra - Bulgaria",
            "searchName": "SLS-Silistra-Bulgária-Bulgària-Bulgaristan-Bulharsko-Bulgaria-Bulgarien-Bugarska-Bulgaaria-Bulgarija-Bolgarija--Bulgarie",
            "priority": 0
        },
        {
            "iataCode": "PDV",
            "countryCode": "BG",
            "cityCode": "PDV",
            "cityName": "Plovdiv",
            "name": "[PDV] - Plovdiv - PDV - Plovdiv - Bulgaria",
            "searchName": "PDV-Plowdiw-Plovdiv-Plovdiv Havalimani-Filippopoli-Felibe-Bulgária-Bulgària-Bulgaristan-Bulharsko-Bulgaria-Bulgarien-Bugarska-Bulgaaria-Bulgarija-Bolgarija--Bulgarie",
            "priority": 0
        },
        {
            "iataCode": "GOZ",
            "countryCode": "BG",
            "cityCode": "GOZ",
            "cityName": "Gorna Oriahovitsa",
            "name": "[GOZ] - Gorna Oriahovitsa - GOZ - Gorna Oriahovitsa - Bulgaria",
            "searchName": "GOZ-Gorna Oriahovitsa-Gorna Oryahovitsa-Bulgária-Bulgària-Bulgaristan-Bulharsko-Bulgaria-Bulgarien-Bugarska-Bulgaaria-Bulgarija-Bolgarija--Bulgarie",
            "priority": 0
        },
        {
            "iataCode": "JAM",
            "countryCode": "BG",
            "cityCode": "JAM",
            "cityName": "Jambol",
            "name": "[JAM] - Jambol - JAM - Jambol - Bulgaria",
            "searchName": "JAM-Jambol-Bulgária-Bulgària-Bulgaristan-Bulharsko-Bulgaria-Bulgarien-Bugarska-Bulgaaria-Bulgarija-Bolgarija--Bulgarie",
            "priority": 0
        },
        {
            "iataCode": "HKV",
            "countryCode": "BG",
            "cityCode": "HKV",
            "cityName": "Haskovo",
            "name": "[HKV] - Haskovo - HKV - Haskovo - Bulgaria",
            "searchName": "HKV-Haskovo-Bulgária-Bulgària-Bulgaristan-Bulharsko-Bulgaria-Bulgarien-Bugarska-Bulgaaria-Bulgarija-Bolgarija--Bulgarie",
            "priority": 0
        },
        {
            "iataCode": "BJM",
            "countryCode": "BI",
            "cityCode": "BJM",
            "cityName": "Bujumbura",
            "name": "[BJM] - International - BJM - Bujumbura - Burundi",
            "searchName": "BJM-Bujumbura-International-Burundi",
            "priority": 0
        },
        {
            "iataCode": "GID",
            "countryCode": "BI",
            "cityCode": "GID",
            "cityName": "Gitega",
            "name": "[GID] - Gitega - GID - Gitega - Burundi",
            "searchName": "GID-Gitega-Burundi",
            "priority": 0
        },
        {
            "iataCode": "KRE",
            "countryCode": "BI",
            "cityCode": "KRE",
            "cityName": "Kirundo",
            "name": "[KRE] - Kirundo - KRE - Kirundo - Burundi",
            "searchName": "KRE-Kirundo-Burundi",
            "priority": 0
        },
        {
            "iataCode": "KDC",
            "countryCode": "BJ",
            "cityCode": "KDC",
            "cityName": "Kandi",
            "name": "[KDC] - Kandi - KDC - Kandi - Benin",
            "searchName": "KDC-Kandi-Benin-Bénin-Benín",
            "priority": 0
        },
        {
            "iataCode": "DJA",
            "countryCode": "BJ",
            "cityCode": "DJA",
            "cityName": "Djougou",
            "name": "[DJA] - Djougou - DJA - Djougou - Benin",
            "searchName": "DJA-Djougou-Benin-Bénin-Benín",
            "priority": 0
        },
        {
            "iataCode": "COO",
            "countryCode": "BJ",
            "cityCode": "COO",
            "cityName": "Cotonou",
            "name": "[COO] - Cotonou - COO - Cotonou - Benin",
            "searchName": "COO-Cotonou-Benin-Bénin-Benín",
            "priority": 0
        },
        {
            "iataCode": "PKO",
            "countryCode": "BJ",
            "cityCode": "PKO",
            "cityName": "Parakou",
            "name": "[PKO] - Parakou - PKO - Parakou - Benin",
            "searchName": "PKO-Parakou-Benin-Bénin-Benín",
            "priority": 0
        },
        {
            "iataCode": "NAE",
            "countryCode": "BJ",
            "cityCode": "NAE",
            "cityName": "Natitingou",
            "name": "[NAE] - Natitingou - NAE - Natitingou - Benin",
            "searchName": "NAE-Natitingou-Benin-Bénin-Benín",
            "priority": 0
        },
        {
            "iataCode": "SVF",
            "countryCode": "BJ",
            "cityCode": "SVF",
            "cityName": "Save",
            "name": "[SVF] - Save - SVF - Save - Benin",
            "searchName": "SVF-Save-Benin-Bénin-Benín",
            "priority": 0
        },
        {
            "iataCode": "CRL",
            "countryCode": "BE",
            "cityCode": "BRU",
            "cityName": "Bruselas",
            "name": "[CRL] - Charleroi Brussels South - BRU - Bruselas - Belgium",
            "searchName": "CRL-BRU-Brüssel Charleroi-Charleroi Brussels South-Charleroi Brussels-Charleroi-Br-Bruselas-Belgia-Belgium-Belgien-België-Bélgica-Belgie-Belçika-Bèlgica-Belgique-Belgio",
            "priority": 0
        },
        {
            "iataCode": "ZYR",
            "countryCode": "BE",
            "cityCode": "BRU",
            "cityName": "Bruselas",
            "name": "[ZYR] - Midi Railway Station - BRU - Bruselas - Belgium",
            "searchName": "ZYR-BRU-Gare du Midi-Midi Railway Station-Br-Bruselas-Belgia-Belgium-Belgien-België-Bélgica-Belgie-Belçika-Bèlgica-Belgique-Belgio",
            "priority": 0
        },
        {
            "iataCode": "ANR",
            "countryCode": "BE",
            "cityCode": "ANR",
            "cityName": "Antwerp",
            "name": "[ANR] - Deurne/ Antwerp Airport - ANR - Antwerp - Belgium",
            "searchName": "ANR-Antwerpen-Deurne/ Antwerp Airport-Deurne-Deurne Airport-Antwerp-Amberes-Anvers-Anversa-Ansvers-Belgia-Belgium-Belgien-België-Bélgica-Belgie-Belçika-Bèlgica-Belgique-Belgio",
            "priority": 0
        },
        {
            "iataCode": "ZYZ",
            "countryCode": "BE",
            "cityCode": "ANR",
            "cityName": "Antwerp",
            "name": "[ZYZ] - Berchem Railway - ANR - Antwerp - Belgium",
            "searchName": "ZYZ-ANR-Berchem Railway-Berchem Railway Stn.-Berchem Railway Station-Antwerp-Amberes-Anvers-Anversa-Ansvers-Belgia-Belgium-Belgien-België-Bélgica-Belgie-Belçika-Bèlgica-Belgique-Belgio",
            "priority": 0
        },
        {
            "iataCode": "ZWE",
            "countryCode": "BE",
            "cityCode": "ANR",
            "cityName": "Antwerp",
            "name": "[ZWE] - Antwerp Central Railway Station - ANR - Antwerp - Belgium",
            "searchName": "ZWE-ANR-Antwerp Central Railway Station-Antwerpen-Centraal-Zweibruecken Airport-Antwerp-Amberes-Anvers-Anversa-Ansvers-Belgia-Belgium-Belgien-België-Bélgica-Belgie-Belçika-Bèlgica-Belgique-Belgio",
            "priority": 0
        },
        {
            "iataCode": "ZAY",
            "countryCode": "BE",
            "cityCode": "ANR",
            "cityName": "Antwerp",
            "name": "[ZAY] - De Keyserlei Bus Station - ANR - Antwerp - Belgium",
            "searchName": "ZAY-ANR-De Keyserlei Bus Station-Antwerp-Amberes-Anvers-Anversa-Ansvers-Belgia-Belgium-Belgien-België-Bélgica-Belgie-Belçika-Bèlgica-Belgique-Belgio",
            "priority": 0
        },
        {
            "iataCode": "ARL",
            "countryCode": "BF",
            "cityCode": "ARL",
            "cityName": "Arly",
            "name": "[ARL] - Arly - ARL - Arly - Burkina Faso",
            "searchName": "ARL-Arly-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "BNR",
            "countryCode": "BF",
            "cityCode": "BNR",
            "cityName": "Banfora",
            "name": "[BNR] - Banfora - BNR - Banfora - Burkina Faso",
            "searchName": "BNR-Banfora-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "BOY",
            "countryCode": "BF",
            "cityCode": "BOY",
            "cityName": "Bobo Dioulasso",
            "name": "[BOY] - Borgo - BOY - Bobo Dioulasso - Burkina Faso",
            "searchName": "BOY-Borgo-Bobo-Dioulasso-Bobo Dioulasso-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "DIP",
            "countryCode": "BF",
            "cityCode": "DIP",
            "cityName": "Diapaga",
            "name": "[DIP] - Diapaga - DIP - Diapaga - Burkina Faso",
            "searchName": "DIP-Diapaga-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "DGU",
            "countryCode": "BF",
            "cityCode": "DGU",
            "cityName": "Dedougou",
            "name": "[DGU] - Dedougou - DGU - Dedougou - Burkina Faso",
            "searchName": "DGU-Dedougou-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "DOR",
            "countryCode": "BF",
            "cityCode": "DOR",
            "cityName": "Dori",
            "name": "[DOR] - Dori - DOR - Dori - Burkina Faso",
            "searchName": "DOR-Dori-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "FNG",
            "countryCode": "BF",
            "cityCode": "FNG",
            "cityName": "Fada Ngourma",
            "name": "[FNG] - Fada Ngourma - FNG - Fada Ngourma - Burkina Faso",
            "searchName": "FNG-Fada Ngourma-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "OUA",
            "countryCode": "BF",
            "cityCode": "OUA",
            "cityName": "Ouagadougou",
            "name": "[OUA] - Ouagadougou - OUA - Ouagadougou - Burkina Faso",
            "searchName": "OUA-Ouagadougou-Uagadug-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "OUG",
            "countryCode": "BF",
            "cityCode": "OUG",
            "cityName": "Ouahigouya",
            "name": "[OUG] - Ouahigouya - OUG - Ouahigouya - Burkina Faso",
            "searchName": "OUG-Ouahigouya-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "PUP",
            "countryCode": "BF",
            "cityCode": "PUP",
            "cityName": "Po",
            "name": "[PUP] - Po - PUP - Po - Burkina Faso",
            "searchName": "PUP-Po-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "TMQ",
            "countryCode": "BF",
            "cityCode": "TMQ",
            "cityName": "Tambao",
            "name": "[TMQ] - Tambao - TMQ - Tambao - Burkina Faso",
            "searchName": "TMQ-Tambao-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "TUQ",
            "countryCode": "BF",
            "cityCode": "TUQ",
            "cityName": "Tougan",
            "name": "[TUQ] - Tougan - TUQ - Tougan - Burkina Faso",
            "searchName": "TUQ-Tougan-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "TEG",
            "countryCode": "BF",
            "cityCode": "TEG",
            "cityName": "Tenkodogo",
            "name": "[TEG] - Tenkodogo - TEG - Tenkodogo - Burkina Faso",
            "searchName": "TEG-Tenkodogo-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XAR",
            "countryCode": "BF",
            "cityCode": "XAR",
            "cityName": "Aribinda",
            "name": "[XAR] - Aribinda - XAR - Aribinda - Burkina Faso",
            "searchName": "XAR-Aribinda-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XBG",
            "countryCode": "BF",
            "cityCode": "XBG",
            "cityName": "Bogande",
            "name": "[XBG] - Bogande - XBG - Bogande - Burkina Faso",
            "searchName": "XBG-Bogande-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XBO",
            "countryCode": "BF",
            "cityCode": "XBO",
            "cityName": "Boulsa",
            "name": "[XBO] - Boulsa - XBO - Boulsa - Burkina Faso",
            "searchName": "XBO-Boulsa-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XDE",
            "countryCode": "BF",
            "cityCode": "XDE",
            "cityName": "Diebougou",
            "name": "[XDE] - Diebougou - XDE - Diebougou - Burkina Faso",
            "searchName": "XDE-Diebougou-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XDJ",
            "countryCode": "BF",
            "cityCode": "XDJ",
            "cityName": "Djibo",
            "name": "[XDJ] - Djibo - XDJ - Djibo - Burkina Faso",
            "searchName": "XDJ-Djibo-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XGA",
            "countryCode": "BF",
            "cityCode": "XGA",
            "cityName": "Gaoua",
            "name": "[XGA] - Gaoua - XGA - Gaoua - Burkina Faso",
            "searchName": "XGA-Gaoua-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XGG",
            "countryCode": "BF",
            "cityCode": "XGG",
            "cityName": "Gorom-Gorom",
            "name": "[XGG] - Gorom-Gorom - XGG - Gorom-Gorom - Burkina Faso",
            "searchName": "XGG-Gorom - Flughafen-Gorom-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XLU",
            "countryCode": "BF",
            "cityCode": "XLU",
            "cityName": "Leo",
            "name": "[XLU] - Leo - XLU - Leo - Burkina Faso",
            "searchName": "XLU-Leo-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XNU",
            "countryCode": "BF",
            "cityCode": "XNU",
            "cityName": "Nouna",
            "name": "[XNU] - Nouna - XNU - Nouna - Burkina Faso",
            "searchName": "XNU-Nouna-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XKA",
            "countryCode": "BF",
            "cityCode": "XKA",
            "cityName": "Kantchari",
            "name": "[XKA] - Kantchari - XKA - Kantchari - Burkina Faso",
            "searchName": "XKA-Kantchari-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XKY",
            "countryCode": "BF",
            "cityCode": "XKY",
            "cityName": "Kaya",
            "name": "[XKY] - Kaya - XKY - Kaya - Burkina Faso",
            "searchName": "XKY-Kaya-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XSE",
            "countryCode": "BF",
            "cityCode": "XSE",
            "cityName": "Sebba",
            "name": "[XSE] - Sebba - XSE - Sebba - Burkina Faso",
            "searchName": "XSE-Sebba-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XPA",
            "countryCode": "BF",
            "cityCode": "XPA",
            "cityName": "Pama",
            "name": "[XPA] - Pama - XPA - Pama - Burkina Faso",
            "searchName": "XPA-Pama-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "XZA",
            "countryCode": "BF",
            "cityCode": "XZA",
            "cityName": "Zabre",
            "name": "[XZA] - Zabre - XZA - Zabre - Burkina Faso",
            "searchName": "XZA-Zabre-Burkina Faso",
            "priority": 0
        },
        {
            "iataCode": "QXZ",
            "countryCode": "AT",
            "cityCode": "QXZ",
            "cityName": "Woergl",
            "name": "[QXZ] - Woergl Bus Station - QXZ - Woergl - Austria",
            "searchName": "QXZ-Woergl Bus Station-Woergl-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "ARY",
            "countryCode": "AU",
            "cityCode": "ARY",
            "cityName": "Ararat",
            "name": "[ARY] - Ararat - ARY - Ararat - Australia",
            "searchName": "ARY-Ararat-Ararat Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CXB",
            "countryCode": "BD",
            "cityCode": "CXB",
            "cityName": "Coxs Bazar",
            "name": "[CXB] - Coxs Bazar - CXB - Coxs Bazar - Bangladesh",
            "searchName": "CXB-Cox s Bazar-Coxs Bazar-Bazar-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "BZL",
            "countryCode": "BD",
            "cityCode": "BZL",
            "cityName": "Barisal",
            "name": "[BZL] - Barisal - BZL - Barisal - Bangladesh",
            "searchName": "BZL-Barisal-Bangladesh-Bangladesch-Bangladesz-Bangladéš-Banglades-Bangla Desh-Bangladeš-Ban",
            "priority": 0
        },
        {
            "iataCode": "XHW",
            "countryCode": "AU",
            "cityCode": "ASP",
            "cityName": "Alice Springs",
            "name": "[XHW] - Alice Springs Railway - ASP - Alice Springs - Australia",
            "searchName": "XHW-ASP-Alice Springs Railway-Alice Springs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AXC",
            "countryCode": "AU",
            "cityCode": "AXC",
            "cityName": "Aramac",
            "name": "[AXC] - Aramac - AXC - Aramac - Australia",
            "searchName": "AXC-Aramac-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AXL",
            "countryCode": "AU",
            "cityCode": "AXL",
            "cityName": "Alexandria",
            "name": "[AXL] - Alexandria - AXL - Alexandria - Australia",
            "searchName": "AXL-Alexandria-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AYD",
            "countryCode": "AU",
            "cityCode": "AYD",
            "cityName": "Alroy Downs",
            "name": "[AYD] - Alroy Downs - AYD - Alroy Downs - Australia",
            "searchName": "AYD-Alroy Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AYL",
            "countryCode": "AU",
            "cityCode": "AYL",
            "cityName": "Anthony Lagoon",
            "name": "[AYL] - Anthony Lagoon - AYL - Anthony Lagoon - Australia",
            "searchName": "AYL-Anthony Lagoon-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AYQ",
            "countryCode": "AU",
            "cityCode": "AYQ",
            "cityName": "Ayers Rock",
            "name": "[AYQ] - Connellan - AYQ - Ayers Rock - Australia",
            "searchName": "AYQ-Ayers Rock-Connellan-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AYR",
            "countryCode": "AU",
            "cityCode": "AYR",
            "cityName": "Ayr",
            "name": "[AYR] - Ayr - AYR - Ayr - Australia",
            "searchName": "AYR-Ayr-Ayr Havalimani-Ayr Au-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AUD",
            "countryCode": "AU",
            "cityCode": "AUD",
            "cityName": "Augustus Downs",
            "name": "[AUD] - Augustus Downs - AUD - Augustus Downs - Australia",
            "searchName": "AUD-Augustus Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AUU",
            "countryCode": "AU",
            "cityCode": "AUU",
            "cityName": "Aurukun Mission",
            "name": "[AUU] - Aurukun Mission - AUU - Aurukun Mission - Australia",
            "searchName": "AUU-Aurukun Mission-Aurukun-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AVG",
            "countryCode": "AU",
            "cityCode": "AVG",
            "cityName": "Auvergne",
            "name": "[AVG] - Auvergne - AVG - Auvergne - Australia",
            "searchName": "AVG-Auvergne-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AWN",
            "countryCode": "AU",
            "cityCode": "AWN",
            "cityName": "Alton Downs",
            "name": "[AWN] - Alton Downs - AWN - Alton Downs - Australia",
            "searchName": "AWN-Alton Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AWP",
            "countryCode": "AU",
            "cityCode": "AWP",
            "cityName": "Austral Downs",
            "name": "[AWP] - Austral Downs - AWP - Austral Downs - Australia",
            "searchName": "AWP-Austral Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MEB",
            "countryCode": "AU",
            "cityCode": "MEL",
            "cityName": "Melbourne",
            "name": "[MEB] - Essendon - MEL - Melbourne - Australia",
            "searchName": "MEB-MEL-Essendon-Essendon Havalimani-Melbourne-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MBW",
            "countryCode": "AU",
            "cityCode": "MEL",
            "cityName": "Melbourne",
            "name": "[MBW] - Moorabbin - MEL - Melbourne - Australia",
            "searchName": "MBW-MEL-Moorabbin-Moorabbin Havalimani-Melbourne-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KAH",
            "countryCode": "AU",
            "cityCode": "MEL",
            "cityName": "Melbourne",
            "name": "[KAH] - City Heliport - MEL - Melbourne - Australia",
            "searchName": "KAH-MEL-City Heliport-Melbourne-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AVV",
            "countryCode": "AU",
            "cityCode": "MEL",
            "cityName": "Melbourne",
            "name": "[AVV] - Avalon - MEL - Melbourne - Australia",
            "searchName": "AVV-MEL-Victoria Australien-Avalon-Avalon Airport-Melbourne-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XOM",
            "countryCode": "AU",
            "cityCode": "MEL",
            "cityName": "Melbourne",
            "name": "[XOM] - Melbourne Ferry Port - MEL - Melbourne - Australia",
            "searchName": "XOM-MEL-Melbourne Ferry Port-Melbourne-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MET",
            "countryCode": "AU",
            "cityCode": "MET",
            "cityName": "Moreton",
            "name": "[MET] - Moreton - MET - Moreton - Australia",
            "searchName": "MET-Moreton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MBB",
            "countryCode": "AU",
            "cityCode": "MBB",
            "cityName": "Marble Bar",
            "name": "[MBB] - Marble Bar - MBB - Marble Bar - Australia",
            "searchName": "MBB-Marble Bar-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MBF",
            "countryCode": "AU",
            "cityCode": "MBF",
            "cityName": "Mount Buffalo",
            "name": "[MBF] - Mount Buffalo - MBF - Mount Buffalo - Australia",
            "searchName": "MBF-Mount Buffalo-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MBH",
            "countryCode": "AU",
            "cityCode": "MBH",
            "cityName": "Maryborough",
            "name": "[MBH] - Maryborough - MBH - Maryborough - Australia",
            "searchName": "MBH-Maryborough-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MBN",
            "countryCode": "AU",
            "cityCode": "MBN",
            "cityName": "Mount Barnett",
            "name": "[MBN] - Mt Barnett - MBN - Mount Barnett - Australia",
            "searchName": "MBN-Mt Barnett-Mount Barnett-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MHQ",
            "countryCode": "AX",
            "cityCode": "MHQ",
            "cityName": "Mariehamn",
            "name": "[MHQ] - Mariehamn - MHQ - Mariehamn - Aland Islands",
            "searchName": "MHQ-Mariehamn-Maarianhamina-Aland Islands-Finnland-Finland-Islas Åland-Îles Åland-Isole Aland",
            "priority": 0
        },
        {
            "iataCode": "NAJ",
            "countryCode": "AZ",
            "cityCode": "NAJ",
            "cityName": "Nakhichevan",
            "name": "[NAJ] - Nakhichevan - NAJ - Nakhichevan - Azerbaijan",
            "searchName": "NAJ-Nakhichevan-Nah-Azerbajdzjan-Aserbaidschan-Azerbaijan-Aserbajdsjan-Azerbejdzan-Azerbaidzjan-Azerbeijão-Azerbaycan-Azerbaïdjan-Azerbaiyán-Azerbaigian",
            "priority": 0
        },
        {
            "iataCode": "GBB",
            "countryCode": "AZ",
            "cityCode": "GBB",
            "cityName": "Gabala",
            "name": "[GBB] - Gabala - GBB - Gabala - Azerbaijan",
            "searchName": "GBB-Gabala-Azerbajdzjan-Aserbaidschan-Azerbaijan-Aserbajdsjan-Azerbejdzan-Azerbaidzjan-Azerbeijão-Azerbaycan-Azerbaïdjan-Azerbaiyán-Azerbaigian",
            "priority": 0
        },
        {
            "iataCode": "KVD",
            "countryCode": "AZ",
            "cityCode": "KVD",
            "cityName": "Gyandzha",
            "name": "[KVD] - Gyandzha - KVD - Gyandzha - Azerbaijan",
            "searchName": "KVD-Gyandzha-Gence-Azerbajdzjan-Aserbaidschan-Azerbaijan-Aserbajdsjan-Azerbejdzan-Azerbaidzjan-Azerbeijão-Azerbaycan-Azerbaïdjan-Azerbaiyán-Azerbaigian",
            "priority": 0
        },
        {
            "iataCode": "LLK",
            "countryCode": "AZ",
            "cityCode": "LLK",
            "cityName": "Lankaran",
            "name": "[LLK] - Lankaran - LLK - Lankaran - Azerbaijan",
            "searchName": "LLK-Lankaran-Lankaran International-Azerbajdzjan-Aserbaidschan-Azerbaijan-Aserbajdsjan-Azerbejdzan-Azerbaidzjan-Azerbeijão-Azerbaycan-Azerbaïdjan-Azerbaiyán-Azerbaigian",
            "priority": 0
        },
        {
            "iataCode": "GYD",
            "countryCode": "AZ",
            "cityCode": "BAK",
            "cityName": "Baku",
            "name": "[GYD] - Heydar Aliyev International (Bina International) - BAK - Baku - Azerbaijan",
            "searchName": "GYD-BAK-Baku-Heydar Aliyev International (Bina International)-Heydar Aliyev-Bakou-Bak-Azerbajdzjan-Aserbaidschan-Azerbaijan-Aserbajdsjan-Azerbejdzan-Azerbaidzjan-Azerbeijão-Azerbaycan-Azerbaïdjan-Azerbaiyán-Azerbaigian",
            "priority": 0
        },
        {
            "iataCode": "ZXT",
            "countryCode": "AZ",
            "cityCode": "BAK",
            "cityName": "Baku",
            "name": "[ZXT] - Heydar Aliyev - BAK - Baku - Azerbaijan",
            "searchName": "ZXT-BAK-Heydar Aliyev-Zabrat Havalimani-Baku-Bakou-Bak-Azerbajdzjan-Aserbaidschan-Azerbaijan-Aserbajdsjan-Azerbejdzan-Azerbaidzjan-Azerbeijão-Azerbaycan-Azerbaïdjan-Azerbaiyán-Azerbaigian",
            "priority": 0
        },
        {
            "iataCode": "ZTU",
            "countryCode": "AZ",
            "cityCode": "ZTU",
            "cityName": "Zaqatala",
            "name": "[ZTU] - Zaqatala - ZTU - Zaqatala - Azerbaijan",
            "searchName": "ZTU-Zaqatala-Azerbajdzjan-Aserbaidschan-Azerbaijan-Aserbajdsjan-Azerbejdzan-Azerbaidzjan-Azerbeijão-Azerbaycan-Azerbaïdjan-Azerbaiyán-Azerbaigian",
            "priority": 0
        },
        {
            "iataCode": "BNX",
            "countryCode": "BA",
            "cityCode": "BNX",
            "cityName": "Banja Luka",
            "name": "[BNX] - Banja Luka - BNX - Banja Luka - Bosnia and Herzegovina",
            "searchName": "BNX-Banja Luka-Banja Luka Int. Apt-Bosznia-Hercegovina-Bòsnia i Hercegovina-Bosna Hersek-Bosna a Hercegovina-Bosnia ja Hertsegovina-Bosnien-Bosnia-Bosnia si Hertegovin-Bosnien und Herzegovina-Bosnia and Herzegovina-Bosna-Hersek-Bosnie-Herzégovine-Herzegovina-Bosnia Erzegovina",
            "priority": 0
        },
        {
            "iataCode": "ZVG",
            "countryCode": "AU",
            "cityCode": "ZVG",
            "cityName": "Springvale",
            "name": "[ZVG] - Springvale - ZVG - Springvale - Australia",
            "searchName": "ZVG-Springvale-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ZGL",
            "countryCode": "AU",
            "cityCode": "ZGL",
            "cityName": "South Galway",
            "name": "[ZGL] - South Galway - ZGL - South Galway - Australia",
            "searchName": "ZGL-South Galway-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "YUE",
            "countryCode": "AU",
            "cityCode": "YUE",
            "cityName": "Yuendumu",
            "name": "[YUE] - Yuendumu - YUE - Yuendumu - Australia",
            "searchName": "YUE-Yuendumu-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ZBL",
            "countryCode": "AU",
            "cityCode": "ZBL",
            "cityName": "Biloela",
            "name": "[ZBL] - Biloela - ZBL - Biloela - Australia",
            "searchName": "ZBL-Biloela-Biloela Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ZBO",
            "countryCode": "AU",
            "cityCode": "ZBO",
            "cityName": "Bowen",
            "name": "[ZBO] - Bowen - ZBO - Bowen - Australia",
            "searchName": "ZBO-Bowen-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "YLG",
            "countryCode": "AU",
            "cityCode": "YLG",
            "cityName": "Yalgoo",
            "name": "[YLG] - Yalgoo - YLG - Yalgoo - Australia",
            "searchName": "YLG-Yalgoo-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "YNN",
            "countryCode": "AU",
            "cityCode": "YNN",
            "cityName": "Yandicoogina",
            "name": "[YNN] - Yandi - YNN - Yandicoogina - Australia",
            "searchName": "YNN-Yandi-Yandi Havalimani-Yandicoogina-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ANZ",
            "countryCode": "AU",
            "cityCode": "ANZ",
            "cityName": "Angus Downs",
            "name": "[ANZ] - Angus Downs - ANZ - Angus Downs - Australia",
            "searchName": "ANZ-Angus Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AMX",
            "countryCode": "AU",
            "cityCode": "AMX",
            "cityName": "Ammaroo",
            "name": "[AMX] - Ammaroo - AMX - Ammaroo - Australia",
            "searchName": "AMX-Ammaroo-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ZSB",
            "countryCode": "AT",
            "cityCode": "SZG",
            "cityName": "Salzburg",
            "name": "[ZSB] - Salzburg Hbf Railway Station - SZG - Salzburg - Austria",
            "searchName": "ZSB-SZG-Salzburg Hbf Railway Station-Salzburg-Salzbourg-Salisburgo-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "GRZ",
            "countryCode": "AT",
            "cityCode": "GRZ",
            "cityName": "Graz",
            "name": "[GRZ] - Thalerhof - GRZ - Graz - Austria",
            "searchName": "GRZ-Graz-Thalerhof-Thalerhof Apt-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "HOH",
            "countryCode": "AT",
            "cityCode": "HOH",
            "cityName": "Hohenems",
            "name": "[HOH] - Hohenems-Dornbirn - HOH - Hohenems - Austria",
            "searchName": "HOH-Hohenems-Dornbirn-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "QDI",
            "countryCode": "AT",
            "cityCode": "HOH",
            "cityName": "Hohenems",
            "name": "[QDI] - Dornbirn Bus Station - HOH - Hohenems - Austria",
            "searchName": "QDI-HOH-Dornbirn Bus Station-Hohenems-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "LNZ",
            "countryCode": "AT",
            "cityCode": "LNZ",
            "cityName": "Linz",
            "name": "[LNZ] - Blue Danube - LNZ - Linz - Austria",
            "searchName": "LNZ-Linz-Blue Danube-Hoersching-Hoersching Apt-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "KLU",
            "countryCode": "AT",
            "cityCode": "KLU",
            "cityName": "Klagenfurt",
            "name": "[KLU] - Alpe Adria - KLU - Klagenfurt - Austria",
            "searchName": "KLU-Klagenfurt-Alpe Adria-Klagenfurt Airport-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "ANT",
            "countryCode": "AT",
            "cityCode": "ANT",
            "cityName": "St Anton",
            "name": "[ANT] - St Anton - ANT - St Anton - Austria",
            "searchName": "ANT-St Anton-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "XOV",
            "countryCode": "AT",
            "cityCode": "ANT",
            "cityName": "St Anton",
            "name": "[XOV] - St Anton Bus Station - ANT - St Anton - Austria",
            "searchName": "XOV-ANT-St Anton Bus Station-St Anton-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "ZRS",
            "countryCode": "AT",
            "cityCode": "ZRS",
            "cityName": "Zurs",
            "name": "[ZRS] - Flexenpass Heliport - ZRS - Zurs - Austria",
            "searchName": "ZRS-Hubschrauberlandeplatz-Flexenpass Heliport-Zurs-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "MJP",
            "countryCode": "AU",
            "cityCode": "MJP",
            "cityName": "Manjimup",
            "name": "[MJP] - Manjimup - MJP - Manjimup - Australia",
            "searchName": "MJP-Manjimup-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MKR",
            "countryCode": "AU",
            "cityCode": "MKR",
            "cityName": "Meekatharra",
            "name": "[MKR] - Meekatharra - MKR - Meekatharra - Australia",
            "searchName": "MKR-Meekatharra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MIH",
            "countryCode": "AU",
            "cityCode": "MIH",
            "cityName": "Mitchell Plateau",
            "name": "[MIH] - Mitchell Plateau - MIH - Mitchell Plateau - Australia",
            "searchName": "MIH-Mitchell Plateau-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MIM",
            "countryCode": "AU",
            "cityCode": "MIM",
            "cityName": "Merimbula",
            "name": "[MIM] - Merimbula - MIM - Merimbula - Australia",
            "searchName": "MIM-Merimbula-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MIN",
            "countryCode": "AU",
            "cityCode": "MIN",
            "cityName": "Minnipa",
            "name": "[MIN] - Minnipa - MIN - Minnipa - Australia",
            "searchName": "MIN-Minnipa-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MIY",
            "countryCode": "AU",
            "cityCode": "MIY",
            "cityName": "Mittiebah",
            "name": "[MIY] - Mittiebah - MIY - Mittiebah - Australia",
            "searchName": "MIY-Mittiebah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MIZ",
            "countryCode": "AU",
            "cityCode": "MIZ",
            "cityName": "Mainoru",
            "name": "[MIZ] - Mainoru - MIZ - Mainoru - Australia",
            "searchName": "MIZ-Mainoru-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MJK",
            "countryCode": "AU",
            "cityCode": "MJK",
            "cityName": "Monkey Mia",
            "name": "[MJK] - Shark Bay - MJK - Monkey Mia - Australia",
            "searchName": "MJK-Shark Bay-Monkey Mia-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MFL",
            "countryCode": "AU",
            "cityCode": "MFL",
            "cityName": "Mount Full Stop",
            "name": "[MFL] - Mount Full Stop - MFL - Mount Full Stop - Australia",
            "searchName": "MFL-Mount Full Stop-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MFP",
            "countryCode": "AU",
            "cityCode": "MFP",
            "cityName": "Manners Creek",
            "name": "[MFP] - Manners Creek - MFP - Manners Creek - Australia",
            "searchName": "MFP-Manners Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MGB",
            "countryCode": "AU",
            "cityCode": "MGB",
            "cityName": "Mount Gambier",
            "name": "[MGB] - Mount Gambier - MGB - Mount Gambier - Australia",
            "searchName": "MGB-Mount Gambier-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MGT",
            "countryCode": "AU",
            "cityCode": "MGT",
            "cityName": "Milingimbi",
            "name": "[MGT] - Milingimbi - MGT - Milingimbi - Australia",
            "searchName": "MGT-Millingimbi-Milingimbi-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MGV",
            "countryCode": "AU",
            "cityCode": "MGV",
            "cityName": "Margaret River Station",
            "name": "[MGV] - Margaret River - MGV - Margaret River Station - Australia",
            "searchName": "MGV-Margaret River-Margaret River Station Havalimani-Margaret River Station-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MHO",
            "countryCode": "AU",
            "cityCode": "MHO",
            "cityName": "Mount House",
            "name": "[MHO] - Mount House - MHO - Mount House - Australia",
            "searchName": "MHO-Mount House-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MHU",
            "countryCode": "AU",
            "cityCode": "MHU",
            "cityName": "Mount Hotham",
            "name": "[MHU] - Mount Hotham - MHU - Mount Hotham - Australia",
            "searchName": "MHU-Mount Hotham-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MCV",
            "countryCode": "AU",
            "cityCode": "MCV",
            "cityName": "Mcarthur River",
            "name": "[MCV] - Mcarthur River - MCV - Mcarthur River - Australia",
            "searchName": "MCV-Mcarthur River-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MCY",
            "countryCode": "AU",
            "cityCode": "MCY",
            "cityName": "Sunshine Coast",
            "name": "[MCY] - Maroochydore - MCY - Sunshine Coast - Australia",
            "searchName": "MCY-Maroochydore-Sunshine Coast-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GCV",
            "countryCode": "BR",
            "cityCode": "GCV",
            "cityName": "Gravatai",
            "name": "[GCV] - Gravatai - GCV - Gravatai - Brazil",
            "searchName": "GCV-Gravatai-Gravatai Havalimani-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "GEL",
            "countryCode": "BR",
            "cityCode": "GEL",
            "cityName": "Santo Angelo",
            "name": "[GEL] - Sepe Tiaraju - GEL - Santo Angelo - Brazil",
            "searchName": "GEL-Sepe Tiaraju-Santo -Santo Angelo-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "GDP",
            "countryCode": "BR",
            "cityCode": "GDP",
            "cityName": "Guadalupe",
            "name": "[GDP] - Guadalupe - GDP - Guadalupe - Brazil",
            "searchName": "GDP-Guadalupe-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "HUW",
            "countryCode": "BR",
            "cityCode": "HUW",
            "cityName": "Humaita",
            "name": "[HUW] - Humaita - HUW - Humaita - Brazil",
            "searchName": "HUW-Humaita-Francisco Correa da Cr-Ji-Parana-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "HRZ",
            "countryCode": "BR",
            "cityCode": "HRZ",
            "cityName": "Horizontina",
            "name": "[HRZ] - Horizontina - HRZ - Horizontina - Brazil",
            "searchName": "HRZ-Horizontina-Horizontina Havalimani-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ITP",
            "countryCode": "BR",
            "cityCode": "ITP",
            "cityName": "Itaperuna",
            "name": "[ITP] - Itaperuna - ITP - Itaperuna - Brazil",
            "searchName": "ITP-Itaperuna-Itaperuna Havalimani-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ITQ",
            "countryCode": "BR",
            "cityCode": "ITQ",
            "cityName": "Itaqui",
            "name": "[ITQ] - Itaqui - ITQ - Itaqui - Brazil",
            "searchName": "ITQ-Itaqui-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ITR",
            "countryCode": "BR",
            "cityCode": "ITR",
            "cityName": "Itumbiara",
            "name": "[ITR] - Hidroeletrica - ITR - Itumbiara - Brazil",
            "searchName": "ITR-Hidroeletrica-Itumbiara-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ITA",
            "countryCode": "BR",
            "cityCode": "ITA",
            "cityName": "Itacoatiara",
            "name": "[ITA] - Itacoatiara - ITA - Itacoatiara - Brazil",
            "searchName": "ITA-Itacoatiara-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ITB",
            "countryCode": "BR",
            "cityCode": "ITB",
            "cityName": "Itaituba",
            "name": "[ITB] - Itaituba - ITB - Itaituba - Brazil",
            "searchName": "ITB-Itaituba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ITE",
            "countryCode": "BR",
            "cityCode": "ITE",
            "cityName": "Itubera",
            "name": "[ITE] - Itubera - ITE - Itubera - Brazil",
            "searchName": "ITE-Itubera-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ITI",
            "countryCode": "BR",
            "cityCode": "ITI",
            "cityName": "Itambacuri",
            "name": "[ITI] - Itambacuri - ITI - Itambacuri - Brazil",
            "searchName": "ITI-Itambacuri-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "IRE",
            "countryCode": "BR",
            "cityCode": "IRE",
            "cityName": "Irece",
            "name": "[IRE] - Irece - IRE - Irece - Brazil",
            "searchName": "IRE-Irece-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "IRZ",
            "countryCode": "BR",
            "cityCode": "IRZ",
            "cityName": "Santa Isabel Rio Negro",
            "name": "[IRZ] - Tapuruquara - IRZ - Santa Isabel Rio Negro - Brazil",
            "searchName": "IRZ-Tapuruquara-Santa Isabel do Rio Negro-Santa Isabel Rio Negro-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "IOS",
            "countryCode": "BR",
            "cityCode": "IOS",
            "cityName": "Ilheus",
            "name": "[IOS] - Eduardo Gomes - IOS - Ilheus - Brazil",
            "searchName": "IOS-Ilheus-Eduardo Gomes-Ilh-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "IPG",
            "countryCode": "BR",
            "cityCode": "IPG",
            "cityName": "Ipiranga",
            "name": "[IPG] - Ipiranga - IPG - Ipiranga - Brazil",
            "searchName": "IPG-Ipiranga-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "IPN",
            "countryCode": "BR",
            "cityCode": "IPN",
            "cityName": "Ipatinga",
            "name": "[IPN] - Usiminas - IPN - Ipatinga - Brazil",
            "searchName": "IPN-Usiminas-Ipatinga-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "IPU",
            "countryCode": "BR",
            "cityCode": "IPU",
            "cityName": "Ipiau",
            "name": "[IPU] - Ipiau - IPU - Ipiau - Brazil",
            "searchName": "IPU-Ipiau-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "IDO",
            "countryCode": "BR",
            "cityCode": "IDO",
            "cityName": "Santa Isabel Do Morro",
            "name": "[IDO] - Rio - IDO - Santa Isabel Do Morro - Brazil",
            "searchName": "IDO-Rio-Santa Isabel Do Morro-Santa Isabel do Morro-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "IJU",
            "countryCode": "BR",
            "cityCode": "IJU",
            "cityName": "Ijui",
            "name": "[IJU] - J. Batista Bos Filho Airport - IJU - Ijui - Brazil",
            "searchName": "IJU-J. Batista Bos Filho-J. Batista Bos Filho Airport-Ijui-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ILB",
            "countryCode": "BR",
            "cityCode": "ILB",
            "cityName": "Ilha Solteira",
            "name": "[ILB] - Ilha Solteira - ILB - Ilha Solteira - Brazil",
            "searchName": "ILB-Ilha Solteira-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BDA",
            "countryCode": "BM",
            "cityCode": "BDA",
            "cityName": "St. George",
            "name": "[BDA] - L. F. Wade International - BDA - St. George - Bermuda",
            "searchName": "BDA-Bermuda Intl-L. F. Wade International-L.F Wade Intl-International-Bermuda/Hamilton-St. George-Hamilton-Bermuda-Bermudes-Bermudas",
            "priority": 0
        },
        {
            "iataCode": "NWU",
            "countryCode": "BM",
            "cityCode": "BDA",
            "cityName": "St. George",
            "name": "[NWU] - Bermuda NAS - BDA - St. George - Bermuda",
            "searchName": "NWU-BDA-Bermuda NAS-Bermuda/Hamilton-St. George-Hamilton-Bermuda-Bermudes-Bermudas",
            "priority": 0
        },
        {
            "iataCode": "BWN",
            "countryCode": "BN",
            "cityCode": "BWN",
            "cityName": "Bandar Seri Begawan",
            "name": "[BWN] - Bandar Seri Begwan International Airport - BWN - Bandar Seri Begawan - Brunei",
            "searchName": "BWN-Bandar Seri Begwan-Bandar Seri Begwan International Airport-Brunei International-Bandar Seri Begawan-Brunei Darussalam-Brunei-Brunéi Darussalam-Brunéi",
            "priority": 0
        },
        {
            "iataCode": "BYC",
            "countryCode": "BO",
            "cityCode": "BYC",
            "cityName": "Yacuiba",
            "name": "[BYC] - Yacuiba - BYC - Yacuiba - Bolivia",
            "searchName": "BYC-Yacuiba-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "BVK",
            "countryCode": "BO",
            "cityCode": "BVK",
            "cityName": "Huacaraje",
            "name": "[BVK] - Huacaraje - BVK - Huacaraje - Bolivia",
            "searchName": "BVK-Huacaraje-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "BVL",
            "countryCode": "BO",
            "cityCode": "BVL",
            "cityName": "Baures",
            "name": "[BVL] - Baures - BVL - Baures - Bolivia",
            "searchName": "BVL-Baures-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "BJO",
            "countryCode": "BO",
            "cityCode": "BJO",
            "cityName": "Bermejo",
            "name": "[BJO] - Bermejo - BJO - Bermejo - Bolivia",
            "searchName": "BJO-Bermejo-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "ASC",
            "countryCode": "BO",
            "cityCode": "ASC",
            "cityName": "Ascension",
            "name": "[ASC] - Ascension - ASC - Ascension - Bolivia",
            "searchName": "ASC-Ascension-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "APB",
            "countryCode": "BO",
            "cityCode": "APB",
            "cityName": "Apolo",
            "name": "[APB] - Apolo - APB - Apolo - Bolivia",
            "searchName": "APB-Apolo-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "CIJ",
            "countryCode": "BO",
            "cityCode": "CIJ",
            "cityName": "Cobija",
            "name": "[CIJ] - Capitan Anibal Arab - CIJ - Cobija - Bolivia",
            "searchName": "CIJ-E. Beltram-Capitan Anibal Arab-Cobija-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "CAM",
            "countryCode": "BO",
            "cityCode": "CAM",
            "cityName": "Camiri",
            "name": "[CAM] - Camiri - CAM - Camiri - Bolivia",
            "searchName": "CAM-Camiri-Choreti Havalimani-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "CBB",
            "countryCode": "BO",
            "cityCode": "CBB",
            "cityName": "Cochabamba",
            "name": "[CBB] - J Wilsterman - CBB - Cochabamba - Bolivia",
            "searchName": "CBB-Cochabamba-J Wilsterman-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "CEP",
            "countryCode": "BO",
            "cityCode": "CEP",
            "cityName": "Concepcion",
            "name": "[CEP] - Concepcion - CEP - Concepcion - Bolivia",
            "searchName": "CEP-Concepcion-Conception-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "BSS",
            "countryCode": "BR",
            "cityCode": "BSS",
            "cityName": "Balsas",
            "name": "[BSS] - Balsas - BSS - Balsas - Brazil",
            "searchName": "BSS-Balsas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BVM",
            "countryCode": "BR",
            "cityCode": "BVM",
            "cityName": "Belmonte",
            "name": "[BVM] - Belmonte - BVM - Belmonte - Brazil",
            "searchName": "BVM-Belmonte-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BVH",
            "countryCode": "BR",
            "cityCode": "BVH",
            "cityName": "Vilhena",
            "name": "[BVH] - Vilhena - BVH - Vilhena - Brazil",
            "searchName": "BVH-Vilhena-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BVS",
            "countryCode": "BR",
            "cityCode": "BVS",
            "cityName": "Breves",
            "name": "[BVS] - Breves - BVS - Breves - Brazil",
            "searchName": "BVS-Breves-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BVB",
            "countryCode": "BR",
            "cityCode": "BVB",
            "cityName": "Boa Vista",
            "name": "[BVB] - Boa Vista - BVB - Boa Vista - Brazil",
            "searchName": "BVB-Boa Vista-Boavista-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BZC",
            "countryCode": "BR",
            "cityCode": "BZC",
            "cityName": "Buzios",
            "name": "[BZC] - Buzios - BZC - Buzios - Brazil",
            "searchName": "BZC-Buzios-Buzios Havalimani-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BYO",
            "countryCode": "BR",
            "cityCode": "BYO",
            "cityName": "Bonito",
            "name": "[BYO] - Bonito Airport - BYO - Bonito - Brazil",
            "searchName": "BYO-Bonito-Bonito Airport-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BJP",
            "countryCode": "BR",
            "cityCode": "BJP",
            "cityName": "Braganca Paulista",
            "name": "[BJP] - Braganca Paulista - BJP - Braganca Paulista - Brazil",
            "searchName": "BJP-Braganca Paulista-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SRJ",
            "countryCode": "BO",
            "cityCode": "SRJ",
            "cityName": "San Borja",
            "name": "[SRJ] - Capitan G Q Guardia - SRJ - San Borja - Bolivia",
            "searchName": "SRJ-Capitan G Q Guardia-San Borja-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SNM",
            "countryCode": "BO",
            "cityCode": "SNM",
            "cityName": "San Ignacio De M",
            "name": "[SNM] - San Ignacio De M - SNM - San Ignacio De M - Bolivia",
            "searchName": "SNM-San Ignacio De M-San Ignacio de Moxos-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SNG",
            "countryCode": "BO",
            "cityCode": "SNG",
            "cityName": "San Ignacio De Velasco",
            "name": "[SNG] - San Ignacio De Velasco - SNG - San Ignacio De Velasco - Bolivia",
            "searchName": "SNG-San Ignacio De Velasco-San Ignacio de Velasco-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SRB",
            "countryCode": "BO",
            "cityCode": "SRB",
            "cityName": "Santa Rosa",
            "name": "[SRB] - Santa Rosa - SRB - Santa Rosa - Bolivia",
            "searchName": "SRB-Santa Rosa-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SRD",
            "countryCode": "BO",
            "cityCode": "SRD",
            "cityName": "San Ramon",
            "name": "[SRD] - San Ramon - SRD - San Ramon - Bolivia",
            "searchName": "SRD-San Ramon-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SRE",
            "countryCode": "BO",
            "cityCode": "SRE",
            "cityName": "Sucre",
            "name": "[SRE] - Juana Azurduy de Padilla - SRE - Sucre - Bolivia",
            "searchName": "SRE-Sucre-Juana Azurduy de Padilla-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "TDD",
            "countryCode": "BO",
            "cityCode": "TDD",
            "cityName": "Trinidad",
            "name": "[TDD] - Trinidad - TDD - Trinidad - Bolivia",
            "searchName": "TDD-Trinidad-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "TJA",
            "countryCode": "BO",
            "cityCode": "TJA",
            "cityName": "Tarija",
            "name": "[TJA] - Tarija - TJA - Tarija - Bolivia",
            "searchName": "TJA-Tirija-Tarija-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "VAH",
            "countryCode": "BO",
            "cityCode": "VAH",
            "cityName": "Vallegrande",
            "name": "[VAH] - Vallegrande - VAH - Vallegrande - Bolivia",
            "searchName": "VAH-Vallegrande-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "UYU",
            "countryCode": "BO",
            "cityCode": "UYU",
            "cityName": "Uyuni",
            "name": "[UYU] - Uyuni - UYU - Uyuni - Bolivia",
            "searchName": "UYU-Uyuni-Uyuni Airport-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "VLM",
            "countryCode": "BO",
            "cityCode": "VLM",
            "cityName": "Villamontes",
            "name": "[VLM] - Villamontes - VLM - Villamontes - Bolivia",
            "searchName": "VLM-Villamontes-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "MHW",
            "countryCode": "BO",
            "cityCode": "MHW",
            "cityName": "Monteagudo",
            "name": "[MHW] - Monteagudo - MHW - Monteagudo - Bolivia",
            "searchName": "MHW-Monteagudo-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "MGD",
            "countryCode": "BO",
            "cityCode": "MGD",
            "cityName": "Magdalena",
            "name": "[MGD] - Magdalena - MGD - Magdalena - Bolivia",
            "searchName": "MGD-Magdalena-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "MQK",
            "countryCode": "BO",
            "cityCode": "MQK",
            "cityName": "San Matias",
            "name": "[MQK] - San Matias - MQK - San Matias - Bolivia",
            "searchName": "MQK-San Matias-San Matias Havalimani-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "POI",
            "countryCode": "BO",
            "cityCode": "POI",
            "cityName": "Potosi",
            "name": "[POI] - Potosi - POI - Potosi - Bolivia",
            "searchName": "POI-Kommunaler Flughafen-Potosi-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "ORU",
            "countryCode": "BO",
            "cityCode": "ORU",
            "cityName": "Oruro",
            "name": "[ORU] - Oruro - ORU - Oruro - Bolivia",
            "searchName": "ORU-Oruro-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "PUR",
            "countryCode": "BO",
            "cityCode": "PUR",
            "cityName": "Puerto Rico",
            "name": "[PUR] - Puerto Rico - PUR - Puerto Rico - Bolivia",
            "searchName": "PUR-Puerto Rico-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "PSZ",
            "countryCode": "BO",
            "cityCode": "PSZ",
            "cityName": "Puerto Suarez",
            "name": "[PSZ] - Puerto Suarez - PSZ - Puerto Suarez - Bolivia",
            "searchName": "PSZ-Puerto Suarez-Puerto Suárez-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "RBO",
            "countryCode": "BO",
            "cityCode": "RBO",
            "cityName": "Robore",
            "name": "[RBO] - Robore - RBO - Robore - Bolivia",
            "searchName": "RBO-Robore-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "RBQ",
            "countryCode": "BO",
            "cityCode": "RBQ",
            "cityName": "Rurrenabaque",
            "name": "[RBQ] - Rurrenabaque - RBQ - Rurrenabaque - Bolivia",
            "searchName": "RBQ-Rurrenabaque-Rurrenabaque Havalimani-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "RIB",
            "countryCode": "BO",
            "cityCode": "RIB",
            "cityName": "Riberalta",
            "name": "[RIB] - Gen Buech - RIB - Riberalta - Bolivia",
            "searchName": "RIB-Gen Buech-Riberalta-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "REY",
            "countryCode": "BO",
            "cityCode": "REY",
            "cityName": "Reyes",
            "name": "[REY] - Reyes - REY - Reyes - Bolivia",
            "searchName": "REY-Reyes-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SAB",
            "countryCode": "BQ",
            "cityCode": "SAB",
            "cityName": "Saba Island",
            "name": "[SAB] - J. Yrausquin - SAB - Saba Island - Bonaire, Saint Eustatius and Saba ",
            "searchName": "SAB-Saba-J. Yrausquin-Juancho E. Yrausquin-Juancho E. Yrausquin Apt.-Saba Island--Bonaire Sint Eustatius and Saba-BES-Bonaire- Saint Eustatius and Saba -Hollanda Antilleri-Pays-Bas caribéens-Caribe neerlandés-Caraibi Olandesi-Bonaire, Saint Eustatius and Saba ",
            "priority": 0
        },
        {
            "iataCode": "EUX",
            "countryCode": "BQ",
            "cityCode": "EUX",
            "cityName": "St Eustatius",
            "name": "[EUX] - F D Roosevelt - EUX - St Eustatius - Bonaire, Saint Eustatius and Saba ",
            "searchName": "EUX-Saint Eustathius-F D Roosevelt-FD Roosevelt-St Eustatius-St. Eustatius-Bonaire Sint Eustatius and Saba-BES-Bonaire- Saint Eustatius and Saba -Hollanda Antilleri-Pays-Bas caribéens-Caribe neerlandés-Caraibi Olandesi-Bonaire, Saint Eustatius and Saba ",
            "priority": 0
        },
        {
            "iataCode": "BON",
            "countryCode": "BQ",
            "cityCode": "BON",
            "cityName": "Kralendijk",
            "name": "[BON] - Flamingo International - BON - Kralendijk - Bonaire, Saint Eustatius and Saba ",
            "searchName": "BON-Bonaire-Flamingo International-Flamingo Int.-Flamingo-Kralendijk-Bonaire Sint Eustatius and Saba-BES- Saint Eustatius and Saba -Hollanda Antilleri-Pays-Bas caribéens-Caribe neerlandés-Caraibi Olandesi-Bonaire, Saint Eustatius and Saba ",
            "priority": 0
        },
        {
            "iataCode": "BPS",
            "countryCode": "BR",
            "cityCode": "BPS",
            "cityName": "Porto Seguro",
            "name": "[BPS] - Porto Seguro - BPS - Porto Seguro - Brazil",
            "searchName": "BPS-Porto Seguro-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BPG",
            "countryCode": "BR",
            "cityCode": "BPG",
            "cityName": "Barra Do Garcas",
            "name": "[BPG] - Barra Do Garcas - BPG - Barra Do Garcas - Brazil",
            "searchName": "BPG-Barra Do Garcas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BNU",
            "countryCode": "BR",
            "cityCode": "BNU",
            "cityName": "Blumenau",
            "name": "[BNU] - Blumenau - BNU - Blumenau - Brazil",
            "searchName": "BNU-Blumenau-Blumenau Havalimani-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BQQ",
            "countryCode": "BR",
            "cityCode": "BQQ",
            "cityName": "Barra",
            "name": "[BQQ] - Barra - BQQ - Barra - Brazil",
            "searchName": "BQQ-Barra-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BRA",
            "countryCode": "BR",
            "cityCode": "BRA",
            "cityName": "Barreiras",
            "name": "[BRA] - Barreiras - BRA - Barreiras - Brazil",
            "searchName": "BRA-Barreiras-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BRB",
            "countryCode": "BR",
            "cityCode": "BRB",
            "cityName": "Barreirinhas",
            "name": "[BRB] - Barreirinhas - BRB - Barreirinhas - Brazil",
            "searchName": "BRB-Barreirinhas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PLU",
            "countryCode": "BR",
            "cityCode": "BHZ",
            "cityName": "Belo Horizonte",
            "name": "[PLU] - Pampulha - BHZ - Belo Horizonte - Brazil",
            "searchName": "PLU-BHZ-Belo Horizonte Pampulha-Pampulha-Belo Horizonte-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BMS",
            "countryCode": "BR",
            "cityCode": "BMS",
            "cityName": "Brumado",
            "name": "[BMS] - Brumado - BMS - Brumado - Brazil",
            "searchName": "BMS-Brumado-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BDC",
            "countryCode": "BR",
            "cityCode": "BDC",
            "cityName": "Barra Do Corda",
            "name": "[BDC] - Barra Do Corda - BDC - Barra Do Corda - Brazil",
            "searchName": "BDC-Barra Do Corda-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BCR",
            "countryCode": "BR",
            "cityCode": "BCR",
            "cityName": "Boca Do Acre",
            "name": "[BCR] - Boca Do Acre - BCR - Boca Do Acre - Brazil",
            "searchName": "BCR-Boca Do Acre-Boca do Acre-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BGV",
            "countryCode": "BR",
            "cityCode": "BGV",
            "cityName": "Bento Goncalves",
            "name": "[BGV] - Bento Goncalves - BGV - Bento Goncalves - Brazil",
            "searchName": "BGV-Bento Goncalves-Bento Goncalves Havalimani-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BGX",
            "countryCode": "BR",
            "cityCode": "BGX",
            "cityName": "Bage",
            "name": "[BGX] - Airport Cmdt. Gustavo Kraemer - BGX - Bage - Brazil",
            "searchName": "BGX-Airport Cmdt. Gustavo Kraemer-Bage-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BFH",
            "countryCode": "BR",
            "cityCode": "BFH",
            "cityName": "Curitiba.",
            "name": "[BFH] - Bacacheri - BFH - Curitiba. - Brazil",
            "searchName": "BFH-Bacacheri-Curitiba-Curitiba.-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BEL",
            "countryCode": "BR",
            "cityCode": "BEL",
            "cityName": "Belem",
            "name": "[BEL] - Val De Cans - BEL - Belem - Brazil",
            "searchName": "BEL-Belem-Val De Cans-Bel-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "APQ",
            "countryCode": "BR",
            "cityCode": "APQ",
            "cityName": "Arapiraca",
            "name": "[APQ] - Arapiraca - APQ - Arapiraca - Brazil",
            "searchName": "APQ-Arapiraca-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "APU",
            "countryCode": "BR",
            "cityCode": "APU",
            "cityName": "Apucarana",
            "name": "[APU] - Apucarana - APU - Apucarana - Brazil",
            "searchName": "APU-Apucarana-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ARS",
            "countryCode": "BR",
            "cityCode": "ARS",
            "cityName": "Aragarcas",
            "name": "[ARS] - Aragarcas - ARS - Aragarcas - Brazil",
            "searchName": "ARS-Aragarcas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ARU",
            "countryCode": "BR",
            "cityCode": "ARU",
            "cityName": "Aracatuba",
            "name": "[ARU] - Aracatuba - ARU - Aracatuba - Brazil",
            "searchName": "ARU-Aracatuba-Ara-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AQA",
            "countryCode": "BR",
            "cityCode": "AQA",
            "cityName": "Araraquara",
            "name": "[AQA] - Araraquara - AQA - Araraquara - Brazil",
            "searchName": "AQA-Araraquara-Araraquara Havalimani-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "APS",
            "countryCode": "BR",
            "cityCode": "APS",
            "cityName": "Anapolis",
            "name": "[APS] - Anapolis - APS - Anapolis - Brazil",
            "searchName": "APS-Anapolis-Anapolis Havalimani-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "APX",
            "countryCode": "BR",
            "cityCode": "APX",
            "cityName": "Arapongas",
            "name": "[APX] - Arapongas - APX - Arapongas - Brazil",
            "searchName": "APX-Arapongas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "APY",
            "countryCode": "BR",
            "cityCode": "APY",
            "cityName": "Alto Parnaiba",
            "name": "[APY] - Alto Parnaiba - APY - Alto Parnaiba - Brazil",
            "searchName": "APY-Alto Parnaiba-Alto Do Parnaiba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AQM",
            "countryCode": "BR",
            "cityCode": "AQM",
            "cityName": "Ariquemes",
            "name": "[AQM] - Ariquemes - AQM - Ariquemes - Brazil",
            "searchName": "AQM-Ariquemes-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BAT",
            "countryCode": "BR",
            "cityCode": "BAT",
            "cityName": "Barretos",
            "name": "[BAT] - Airport Chafei Amsei - BAT - Barretos - Brazil",
            "searchName": "BAT-Airport Chafei Amsei-Chafei Amsei Havalimani-Barretos-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "BAZ",
            "countryCode": "BR",
            "cityCode": "BAZ",
            "cityName": "Barbelos",
            "name": "[BAZ] - Barbelos - BAZ - Barbelos - Brazil",
            "searchName": "BAZ-Barbelos-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AZL",
            "countryCode": "BR",
            "cityCode": "AZL",
            "cityName": "Sapezal",
            "name": "[AZL] - Fazenda Tucunare - AZL - Sapezal - Brazil",
            "searchName": "AZL-Fazenda Tucunare-Sapezal-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AXE",
            "countryCode": "BR",
            "cityCode": "AXE",
            "cityName": "Xanxere",
            "name": "[AXE] - Xanxere - AXE - Xanxere - Brazil",
            "searchName": "AXE-Xanxere-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AUX",
            "countryCode": "BR",
            "cityCode": "AUX",
            "cityName": "Araguaina",
            "name": "[AUX] - Araguaina - AUX - Araguaina - Brazil",
            "searchName": "AUX-Araguaina-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AUB",
            "countryCode": "BR",
            "cityCode": "AUB",
            "cityName": "Itauba",
            "name": "[AUB] - Itauba - AUB - Itauba - Brazil",
            "searchName": "AUB-Itauba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ATM",
            "countryCode": "BR",
            "cityCode": "ATM",
            "cityName": "Altamira",
            "name": "[ATM] - Altamira - ATM - Altamira - Brazil",
            "searchName": "ATM-Altamira-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AMJ",
            "countryCode": "BR",
            "cityCode": "AMJ",
            "cityName": "Almenara",
            "name": "[AMJ] - Almenara - AMJ - Almenara - Brazil",
            "searchName": "AMJ-Almenara-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ALQ",
            "countryCode": "BR",
            "cityCode": "ALQ",
            "cityName": "Alegrete",
            "name": "[ALQ] - Federal - ALQ - Alegrete - Brazil",
            "searchName": "ALQ-Federal-Alegrete-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ALT",
            "countryCode": "BR",
            "cityCode": "ALT",
            "cityName": "Alenquer",
            "name": "[ALT] - Alenquer - ALT - Alenquer - Brazil",
            "searchName": "ALT-Alenquer-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AIF",
            "countryCode": "BR",
            "cityCode": "AIF",
            "cityName": "Assis",
            "name": "[AIF] - Assis - AIF - Assis - Brazil",
            "searchName": "AIF-Assis-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AIR",
            "countryCode": "BR",
            "cityCode": "AIR",
            "cityName": "Aripuana",
            "name": "[AIR] - Aripuana - AIR - Aripuana - Brazil",
            "searchName": "AIR-Aripuana-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AJU",
            "countryCode": "BR",
            "cityCode": "AJU",
            "cityName": "Aracaju",
            "name": "[AJU] - Santa Maria - AJU - Aracaju - Brazil",
            "searchName": "AJU-Aracaju-Santa Maria-Araca-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AAG",
            "countryCode": "BR",
            "cityCode": "AAG",
            "cityName": "Arapoti",
            "name": "[AAG] - Arapoti - AAG - Arapoti - Brazil",
            "searchName": "AAG-Arapoti-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AAI",
            "countryCode": "BR",
            "cityCode": "AAI",
            "cityName": "Arraias",
            "name": "[AAI] - Arraias - AAI - Arraias - Brazil",
            "searchName": "AAI-Arraias-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AAX",
            "countryCode": "BR",
            "cityCode": "AAX",
            "cityName": "Araxa",
            "name": "[AAX] - Araxa - AAX - Araxa - Brazil",
            "searchName": "AAX-Araxa-Araxa Airport-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "AFL",
            "countryCode": "BR",
            "cityCode": "AFL",
            "cityName": "Alta Floresta",
            "name": "[AFL] - Alta Floresta - AFL - Alta Floresta - Brazil",
            "searchName": "AFL-Alta Floresta-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "FBE",
            "countryCode": "BR",
            "cityCode": "FBE",
            "cityName": "Francisco Beltrao",
            "name": "[FBE] - Francisco Beltrao - FBE - Francisco Beltrao - Brazil",
            "searchName": "FBE-Francisco Beltrao-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "FBA",
            "countryCode": "BR",
            "cityCode": "FBA",
            "cityName": "Fonte Boa",
            "name": "[FBA] - Fonte Boa - FBA - Fonte Boa - Brazil",
            "searchName": "FBA-Fonte Boa-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ESI",
            "countryCode": "BR",
            "cityCode": "ESI",
            "cityName": "Espinosa",
            "name": "[ESI] - Espinosa - ESI - Espinosa - Brazil",
            "searchName": "ESI-Espinosa-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ERM",
            "countryCode": "BR",
            "cityCode": "ERM",
            "cityName": "Erechim",
            "name": "[ERM] - Comandante Kraemer - ERM - Erechim - Brazil",
            "searchName": "ERM-Comandante Kraemer-Erechim Havalimani-Erechim-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ERN",
            "countryCode": "BR",
            "cityCode": "ERN",
            "cityName": "Eirunepe",
            "name": "[ERN] - Eirunepe - ERN - Eirunepe - Brazil",
            "searchName": "ERN-Eirunepe-Amaury Feitosa Tomaz Havalimani-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "DIQ",
            "countryCode": "BR",
            "cityCode": "DIQ",
            "cityName": "Divinopolis",
            "name": "[DIQ] - Divinopolis - DIQ - Divinopolis - Brazil",
            "searchName": "DIQ-Divinopolis-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "DOU",
            "countryCode": "BR",
            "cityCode": "DOU",
            "cityName": "Dourados",
            "name": "[DOU] - Dourados - DOU - Dourados - Brazil",
            "searchName": "DOU-Dourados-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "DNO",
            "countryCode": "BR",
            "cityCode": "DNO",
            "cityName": "Dianopolis",
            "name": "[DNO] - Dianopolis - DNO - Dianopolis - Brazil",
            "searchName": "DNO-Dianopolis-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "DMT",
            "countryCode": "BR",
            "cityCode": "DMT",
            "cityName": "Diamantino",
            "name": "[DMT] - Diamantino - DMT - Diamantino - Brazil",
            "searchName": "DMT-Diamantino-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "DTI",
            "countryCode": "BR",
            "cityCode": "DTI",
            "cityName": "Diamantina",
            "name": "[DTI] - Diamantina - DTI - Diamantina - Brazil",
            "searchName": "DTI-Diamantina-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CFB",
            "countryCode": "BR",
            "cityCode": "CFB",
            "cityName": "Cabo Frio",
            "name": "[CFB] - Cabo Frio - CFB - Cabo Frio - Brazil",
            "searchName": "CFB-Cabo Frio-Cabo Frio Havalimani-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CFC",
            "countryCode": "BR",
            "cityCode": "CFC",
            "cityName": "Cacador",
            "name": "[CFC] - Cacador - CFC - Cacador - Brazil",
            "searchName": "CFC-Cacador-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CFO",
            "countryCode": "BR",
            "cityCode": "CFO",
            "cityName": "Confreza",
            "name": "[CFO] - Confreza - CFO - Confreza - Brazil",
            "searchName": "CFO-Confreza-Confreza Brazil-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CDI",
            "countryCode": "BR",
            "cityCode": "CDI",
            "cityName": "Cachoeiro Itapemirim",
            "name": "[CDI] - Cachoeiro Itapemirim - CDI - Cachoeiro Itapemirim - Brazil",
            "searchName": "CDI-Cachoeiro Itapemirim-Cachoeiro De Itapemirim Havalimani-Cachoeiro de Itapemirim-Cachoeiro De Itapemirim-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CDJ",
            "countryCode": "BR",
            "cityCode": "CDJ",
            "cityName": "Conceicao Do Araguaia",
            "name": "[CDJ] - Conceicao Do Araguaia - CDJ - Conceicao Do Araguaia - Brazil",
            "searchName": "CDJ-Conceicao Do Araguaia-Conceicao do Araguaia-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CCX",
            "countryCode": "BR",
            "cityCode": "CCX",
            "cityName": "Caceres",
            "name": "[CCX] - Caceres - CCX - Caceres - Brazil",
            "searchName": "CCX-Caceres-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CCQ",
            "countryCode": "BR",
            "cityCode": "CCQ",
            "cityName": "Cachoeira",
            "name": "[CCQ] - Cachoeira - CCQ - Cachoeira - Brazil",
            "searchName": "CCQ-Cachoeira-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CCM",
            "countryCode": "BR",
            "cityCode": "CCM",
            "cityName": "Criciuma",
            "name": "[CCM] - Criciuma - CCM - Criciuma - Brazil",
            "searchName": "CCM-Crisciuma-Criciuma-Crici-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CCI",
            "countryCode": "BR",
            "cityCode": "CCI",
            "cityName": "Concordia",
            "name": "[CCI] - Concordia - CCI - Concordia - Brazil",
            "searchName": "CCI-Concordia-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CAU",
            "countryCode": "BR",
            "cityCode": "CAU",
            "cityName": "Caruaru",
            "name": "[CAU] - Caruaru - CAU - Caruaru - Brazil",
            "searchName": "CAU-Caruaru-Caruaru Havalimani-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CBW",
            "countryCode": "BR",
            "cityCode": "CBW",
            "cityName": "Campo Mourao",
            "name": "[CBW] - Campo Mourao - CBW - Campo Mourao - Brazil",
            "searchName": "CBW-Campo Mourao-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CAW",
            "countryCode": "BR",
            "cityCode": "CAW",
            "cityName": "Campos",
            "name": "[CAW] - Bartolomeu Lisandro - CAW - Campos - Brazil",
            "searchName": "CAW-Bartolomeu Lisandro-Campos-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CAC",
            "countryCode": "BR",
            "cityCode": "CAC",
            "cityName": "Cascavel",
            "name": "[CAC] - Cascavel - CAC - Cascavel - Brazil",
            "searchName": "CAC-Cascavel-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CAF",
            "countryCode": "BR",
            "cityCode": "CAF",
            "cityName": "Carauari",
            "name": "[CAF] - Carauari - CAF - Carauari - Brazil",
            "searchName": "CAF-Carauari-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CGR",
            "countryCode": "BR",
            "cityCode": "CGR",
            "cityName": "Campo Grande",
            "name": "[CGR] - International Airport Campo Grande - CGR - Campo Grande - Brazil",
            "searchName": "CGR-Campo Grande-International Airport Campo Grande- Intl-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CGB",
            "countryCode": "BR",
            "cityCode": "CGB",
            "cityName": "Cuiaba",
            "name": "[CGB] - International Airport Marechal Rondon - CGB - Cuiaba - Brazil",
            "searchName": "CGB-Cuiaba-International Airport Marechal Rondon-M. Rondon-Cuiab-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CLN",
            "countryCode": "BR",
            "cityCode": "CLN",
            "cityName": "Carolina",
            "name": "[CLN] - Carolina - CLN - Carolina - Brazil",
            "searchName": "CLN-Carolina-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CLV",
            "countryCode": "BR",
            "cityCode": "CLV",
            "cityName": "Caldas Novas",
            "name": "[CLV] - Caldas Novas - CLV - Caldas Novas - Brazil",
            "searchName": "CLV-Caldas Novas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CMC",
            "countryCode": "BR",
            "cityCode": "CMC",
            "cityName": "Camocim",
            "name": "[CMC] - Camocim - CMC - Camocim - Brazil",
            "searchName": "CMC-Camocim-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CMG",
            "countryCode": "BR",
            "cityCode": "CMG",
            "cityName": "Corumba",
            "name": "[CMG] - International Airport Corumba - CMG - Corumba - Brazil",
            "searchName": "CMG-Corumba-International Airport Corumba- Intl-Corumb-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CKO",
            "countryCode": "BR",
            "cityCode": "CKO",
            "cityName": "Cornelio Procopio",
            "name": "[CKO] - Cornelio Procopio - CKO - Cornelio Procopio - Brazil",
            "searchName": "CKO-Cornelio Procopio-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CKS",
            "countryCode": "BR",
            "cityCode": "CKS",
            "cityName": "Carajas",
            "name": "[CKS] - Carajas - CKS - Carajas - Brazil",
            "searchName": "CKS-Carajas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CIZ",
            "countryCode": "BR",
            "cityCode": "CIZ",
            "cityName": "Coari",
            "name": "[CIZ] - Coari - CIZ - Coari - Brazil",
            "searchName": "CIZ-Coari-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CNV",
            "countryCode": "BR",
            "cityCode": "CNV",
            "cityName": "Canavieiras",
            "name": "[CNV] - Canavieiras - CNV - Canavieiras - Brazil",
            "searchName": "CNV-Canavieiras-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CMT",
            "countryCode": "BR",
            "cityCode": "CMT",
            "cityName": "Cameta",
            "name": "[CMT] - Cameta - CMT - Cameta - Brazil",
            "searchName": "CMT-Cameta-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CMP",
            "countryCode": "BR",
            "cityCode": "CMP",
            "cityName": "Santana Do Araguaia",
            "name": "[CMP] - Campo Alegre - CMP - Santana Do Araguaia - Brazil",
            "searchName": "CMP-Campo Alegre-Santana Do Araguaia-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CQS",
            "countryCode": "BR",
            "cityCode": "CQS",
            "cityName": "Costa Marques",
            "name": "[CQS] - Costa Marques - CQS - Costa Marques - Brazil",
            "searchName": "CQS-Costa Marques-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CPQ",
            "countryCode": "BR",
            "cityCode": "CPQ",
            "cityName": "Campinas",
            "name": "[CPQ] - International - CPQ - Campinas - Brazil",
            "searchName": "CPQ-Campinas-International-International Campinas-Sao Paulo-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CPU",
            "countryCode": "BR",
            "cityCode": "CPU",
            "cityName": "Cururupu",
            "name": "[CPU] - Cururupu - CPU - Cururupu - Brazil",
            "searchName": "CPU-Cururupu-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CPV",
            "countryCode": "BR",
            "cityCode": "CPV",
            "cityName": "Campina Grande",
            "name": "[CPV] - Joao Suassuna - CPV - Campina Grande - Brazil",
            "searchName": "CPV-Joao Suassuna-Campina Grande-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CQA",
            "countryCode": "BR",
            "cityCode": "CQA",
            "cityName": "Canarana",
            "name": "[CQA] - Canarana Airport - CQA - Canarana - Brazil",
            "searchName": "CQA-Canarana Airport-Canarana-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CSS",
            "countryCode": "BR",
            "cityCode": "CSS",
            "cityName": "Cassilandia",
            "name": "[CSS] - Cassilandia - CSS - Cassilandia - Brazil",
            "searchName": "CSS-Cassilandia-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CSU",
            "countryCode": "BR",
            "cityCode": "CSU",
            "cityName": "Santa Cruz Do Sul",
            "name": "[CSU] - Santa Cruz Do Sul - CSU - Santa Cruz Do Sul - Brazil",
            "searchName": "CSU-Santa Cruz Do Sul-Santa Cruz Do Sul Havalimani-Santa Cruz-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CSW",
            "countryCode": "BR",
            "cityCode": "CSW",
            "cityName": "Colorado do Oeste",
            "name": "[CSW] - Colorado do Oeste - CSW - Colorado do Oeste - Brazil",
            "searchName": "CSW-Colorado do Oeste-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CRQ",
            "countryCode": "BR",
            "cityCode": "CRQ",
            "cityName": "Caravelas",
            "name": "[CRQ] - Caravelas - CRQ - Caravelas - Brazil",
            "searchName": "CRQ-Caravelas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CXJ",
            "countryCode": "BR",
            "cityCode": "CXJ",
            "cityName": "Caxias Do Sul",
            "name": "[CXJ] - Campo Dos Bugres - CXJ - Caxias Do Sul - Brazil",
            "searchName": "CXJ-Caxias do Sul-Campo Dos Bugres-Caxias Do Sul-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CZB",
            "countryCode": "BR",
            "cityCode": "CZB",
            "cityName": "Cruz Alta",
            "name": "[CZB] - Carlos Ruhl - CZB - Cruz Alta - Brazil",
            "searchName": "CZB-Carlos Ruhl-Cruz Alta-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CZS",
            "countryCode": "BR",
            "cityCode": "CZS",
            "cityName": "Cruzeiro Do Sul",
            "name": "[CZS] - International Airport Cruzeiro Do Sul - CZS - Cruzeiro Do Sul - Brazil",
            "searchName": "CZS-Campo Intl-International Airport Cruzeiro Do Sul-Cruzeiro Do Sul-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CTP",
            "countryCode": "BR",
            "cityCode": "CTP",
            "cityName": "Carutapera",
            "name": "[CTP] - Carutapera - CTP - Carutapera - Brazil",
            "searchName": "CTP-Carutapera-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "CTQ",
            "countryCode": "BR",
            "cityCode": "CTQ",
            "cityName": "Santa Vitoria",
            "name": "[CTQ] - Do Palmar - CTQ - Santa Vitoria - Brazil",
            "searchName": "CTQ-Do Palmar-Santa Vitoria MG-Santa Vitoria-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "WZY",
            "countryCode": "BS",
            "cityCode": "NAS",
            "cityName": "Nassau",
            "name": "[WZY] - Paradise Island Seaplane Base - NAS - Nassau - Bahamas",
            "searchName": "WZY-NAS-Paradise Island Seaplane Base-Nassau-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "MAY",
            "countryCode": "BS",
            "cityCode": "MAY",
            "cityName": "Mangrove Cay",
            "name": "[MAY] - Mangrove Cay - MAY - Mangrove Cay - Bahamas",
            "searchName": "MAY-Mangrove Cay-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "MHH",
            "countryCode": "BS",
            "cityCode": "MHH",
            "cityName": "Marsh Harbour",
            "name": "[MHH] - International - MHH - Marsh Harbour - Bahamas",
            "searchName": "MHH-Marsh Harbour-International-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "MSK",
            "countryCode": "BS",
            "cityCode": "MSK",
            "cityName": "Mastic Point",
            "name": "[MSK] - Mastic Point - MSK - Mastic Point - Bahamas",
            "searchName": "MSK-Mastic Point-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "PPO",
            "countryCode": "BS",
            "cityCode": "PPO",
            "cityName": "Powell Point",
            "name": "[PPO] - Powell Point - PPO - Powell Point - Bahamas",
            "searchName": "PPO-Powell Point-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "PWN",
            "countryCode": "BS",
            "cityCode": "PWN",
            "cityName": "Pitts Town",
            "name": "[PWN] - Pitts Town - PWN - Pitts Town - Bahamas",
            "searchName": "PWN-Pitts Town-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "RCY",
            "countryCode": "BS",
            "cityCode": "RCY",
            "cityName": "Rum Cay",
            "name": "[RCY] - Rum Cay - RCY - Rum Cay - Bahamas",
            "searchName": "RCY-Rum Cay-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "RSD",
            "countryCode": "BS",
            "cityCode": "RSD",
            "cityName": "Rock Sound",
            "name": "[RSD] - S Eleuthera - RSD - Rock Sound - Bahamas",
            "searchName": "RSD-Rock Sound-S Eleuthera-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "LGI",
            "countryCode": "BS",
            "cityCode": "LGI",
            "cityName": "Long Island",
            "name": "[LGI] - Deadmans Cay - LGI - Long Island - Bahamas",
            "searchName": "LGI-Deadman s Cay-Deadmans Cay-Long Island-Long Island\\/Deadmans Cay-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "IGA",
            "countryCode": "BS",
            "cityCode": "IGA",
            "cityName": "Inagua",
            "name": "[IGA] - Inagua - IGA - Inagua - Bahamas",
            "searchName": "IGA-Inagua-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "HBI",
            "countryCode": "BS",
            "cityCode": "HBI",
            "cityName": "Harbour Island",
            "name": "[HBI] - Harbour Island - HBI - Harbour Island - Bahamas",
            "searchName": "HBI-Harbour Island-Harbour Adasi Havalimani-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "GBI",
            "countryCode": "BS",
            "cityCode": "GBI",
            "cityName": "Grand Bahama",
            "name": "[GBI] - Auxiliary Airfield - GBI - Grand Bahama - Bahamas",
            "searchName": "GBI-Aux Ab-Auxiliary Airfield-Grand Bahama Island-Grand Bahama-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "FPO",
            "countryCode": "BS",
            "cityCode": "FPO",
            "cityName": "Freeport",
            "name": "[FPO] - Grand Bahama International - FPO - Freeport - Bahamas",
            "searchName": "FPO-Freeport-Grand Bahama International-Grand Bahama Intl-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "GHB",
            "countryCode": "BS",
            "cityCode": "GHB",
            "cityName": "Governors Harbour",
            "name": "[GHB] - Governors Harbour - GHB - Governors Harbour - Bahamas",
            "searchName": "GHB-Governors Harbour-Governor s Harbour-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "GHC",
            "countryCode": "BS",
            "cityCode": "GHC",
            "cityName": "Great Harbour",
            "name": "[GHC] - Great Harbour - GHC - Great Harbour - Bahamas",
            "searchName": "GHC-Great Harbour-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "GGT",
            "countryCode": "BS",
            "cityCode": "GGT",
            "cityName": "George Town",
            "name": "[GGT] - Exuma International - GGT - George Town - Bahamas",
            "searchName": "GGT-George Town-Exuma International-Georgetown-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "GTC",
            "countryCode": "BS",
            "cityCode": "GTC",
            "cityName": "Green Turtle",
            "name": "[GTC] - Green Turtle - GTC - Green Turtle - Bahamas",
            "searchName": "GTC-Green Turtle-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "CXY",
            "countryCode": "BS",
            "cityCode": "CXY",
            "cityName": "Cat Cays",
            "name": "[CXY] - Cat Cays - CXY - Cat Cays - Bahamas",
            "searchName": "CXY-Cat Cays-Cat Cay-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "CRI",
            "countryCode": "BS",
            "cityCode": "CRI",
            "cityName": "Crooked Island",
            "name": "[CRI] - Crooked Island - CRI - Crooked Island - Bahamas",
            "searchName": "CRI-Crooked Island-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "COX",
            "countryCode": "BS",
            "cityCode": "COX",
            "cityName": "Congo Town",
            "name": "[COX] - Congo Town - COX - Congo Town - Bahamas",
            "searchName": "COX-Congo Town-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "CAT",
            "countryCode": "BS",
            "cityCode": "CAT",
            "cityName": "Cat Island",
            "name": "[CAT] - New Bight - CAT - Cat Island - Bahamas",
            "searchName": "CAT-Cat Island-New Bight-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "CCZ",
            "countryCode": "BS",
            "cityCode": "CCZ",
            "cityName": "Chub Cay",
            "name": "[CCZ] - Chub Cay - CCZ - Chub Cay - Bahamas",
            "searchName": "CCZ-Chub Cay-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "DCT",
            "countryCode": "BS",
            "cityCode": "DCT",
            "cityName": "Duncan Town",
            "name": "[DCT] - Duncan Town - DCT - Duncan Town - Bahamas",
            "searchName": "DCT-Duncan Town-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "ELH",
            "countryCode": "BS",
            "cityCode": "ELH",
            "cityName": "North Eleuthera",
            "name": "[ELH] - International - ELH - North Eleuthera - Bahamas",
            "searchName": "ELH-North Eleuthera-International-Bahamas-Kuzey Eleuthera-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "ATC",
            "countryCode": "BS",
            "cityCode": "ATC",
            "cityName": "Arthurs Town",
            "name": "[ATC] - Arthurs Town - ATC - Arthurs Town - Bahamas",
            "searchName": "ATC-Arthur s Town-Arthurs Town-Arthurs Town Airport-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "AXP",
            "countryCode": "BS",
            "cityCode": "AXP",
            "cityName": "Spring Point",
            "name": "[AXP] - Springpoint Airport - AXP - Spring Point - Bahamas",
            "searchName": "AXP-Springpoint Arpt-Springpoint Airport-Spring Point-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "ASD",
            "countryCode": "BS",
            "cityCode": "ASD",
            "cityName": "Andros Town",
            "name": "[ASD] - Andros Town - ASD - Andros Town - Bahamas",
            "searchName": "ASD-Andros Town-Andros Town Havalimani-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "BIM",
            "countryCode": "BS",
            "cityCode": "BIM",
            "cityName": "Bimini",
            "name": "[BIM] - International - BIM - Bimini - Bahamas",
            "searchName": "BIM-Bimini-International-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "NSB",
            "countryCode": "BS",
            "cityCode": "BIM",
            "cityName": "Bimini",
            "name": "[NSB] - North SPB - BIM - Bimini - Bahamas",
            "searchName": "NSB-BIM-North-North SPB-Bimini-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "PBH",
            "countryCode": "BT",
            "cityCode": "PBH",
            "cityName": "Paro",
            "name": "[PBH] - Paro - PBH - Paro - Bhutan",
            "searchName": "PBH-Paro-Bhutan-Bhután-Bhútán-Butan-Butanas-Butana-Kraljevina Butan-Bután-Bhoutan-Bu tan",
            "priority": 0
        },
        {
            "iataCode": "ORP",
            "countryCode": "BW",
            "cityCode": "ORP",
            "cityName": "Orapa",
            "name": "[ORP] - Orapa - ORP - Orapa - Botswana",
            "searchName": "ORP-Orapa-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "PKW",
            "countryCode": "BW",
            "cityCode": "PKW",
            "cityName": "Selebi-Phikwe",
            "name": "[PKW] - Selebi-Phikwe - PKW - Selebi-Phikwe - Botswana",
            "searchName": "PKW-Selebi-Phikwe-Selebi Phikwe Havalimani-Selebi Phikwe-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "MUB",
            "countryCode": "BW",
            "cityCode": "MUB",
            "cityName": "Maun",
            "name": "[MUB] - Maun - MUB - Maun - Botswana",
            "searchName": "MUB-Maun-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "TBY",
            "countryCode": "BW",
            "cityCode": "TBY",
            "cityName": "Tsabong",
            "name": "[TBY] - Tsabong - TBY - Tsabong - Botswana",
            "searchName": "TBY-Tsabong-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "SWX",
            "countryCode": "BW",
            "cityCode": "SWX",
            "cityName": "Shakawe",
            "name": "[SWX] - Shakawe - SWX - Shakawe - Botswana",
            "searchName": "SWX-Shakawe-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "SXN",
            "countryCode": "BW",
            "cityCode": "SXN",
            "cityName": "Suapan",
            "name": "[SXN] - Suapan - SXN - Suapan - Botswana",
            "searchName": "SXN-Suapan-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "TLD",
            "countryCode": "BW",
            "cityCode": "TLD",
            "cityName": "Tuli Block",
            "name": "[TLD] - Limpopo Valley - TLD - Tuli Block - Botswana",
            "searchName": "TLD-Limpopo Valley-Limpopo Valley Havalimani-Tuli Lodge-Tuli Block-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "SVT",
            "countryCode": "BW",
            "cityCode": "SVT",
            "cityName": "Savuti",
            "name": "[SVT] - Savuti - SVT - Savuti - Botswana",
            "searchName": "SVT-Savuti-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "BBK",
            "countryCode": "BW",
            "cityCode": "BBK",
            "cityName": "Kasane",
            "name": "[BBK] - Kasane - BBK - Kasane - Botswana",
            "searchName": "BBK-Kasane-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "GNZ",
            "countryCode": "BW",
            "cityCode": "GNZ",
            "cityName": "Ghanzi",
            "name": "[GNZ] - Ghanzi - GNZ - Ghanzi - Botswana",
            "searchName": "GNZ-Ghanzi-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "FRW",
            "countryCode": "BW",
            "cityCode": "FRW",
            "cityName": "Francistown",
            "name": "[FRW] - Francistown - FRW - Francistown - Botswana",
            "searchName": "FRW-Francistown-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "GBE",
            "countryCode": "BW",
            "cityCode": "GBE",
            "cityName": "Gaborone",
            "name": "[GBE] - Sir Seretse Khama International - GBE - Gaborone - Botswana",
            "searchName": "GBE-Gaborone-Sir Seretse Khama International-Sir Seretse Khama-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "HUK",
            "countryCode": "BW",
            "cityCode": "HUK",
            "cityName": "Hukuntsi",
            "name": "[HUK] - Hukuntsi - HUK - Hukuntsi - Botswana",
            "searchName": "HUK-Hukuntsi-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "LOQ",
            "countryCode": "BW",
            "cityCode": "LOQ",
            "cityName": "Lobatse",
            "name": "[LOQ] - Lobatse - LOQ - Lobatse - Botswana",
            "searchName": "LOQ-Lobatse-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "JWA",
            "countryCode": "BW",
            "cityCode": "JWA",
            "cityName": "Jwaneng",
            "name": "[JWA] - Jwaneng - JWA - Jwaneng - Botswana",
            "searchName": "JWA-Jwaneng-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "KHW",
            "countryCode": "BW",
            "cityCode": "KHW",
            "cityName": "Khwai River Lodge",
            "name": "[KHW] - Khwai River Lodge - KHW - Khwai River Lodge - Botswana",
            "searchName": "KHW-Khwai River Lodge-Botswana-Botsvana-Botsuana",
            "priority": 0
        },
        {
            "iataCode": "GNA",
            "countryCode": "BY",
            "cityCode": "GNA",
            "cityName": "Grodna",
            "name": "[GNA] - Grodna - GNA - Grodna - Belarus",
            "searchName": "GNA-Grodna-Grodna Havalimani-Weißrußland-Belarus-Beyaz Rusya-Bélarus-Bielorrusia-Bielorussia",
            "priority": 0
        },
        {
            "iataCode": "GME",
            "countryCode": "BY",
            "cityCode": "GME",
            "cityName": "Gomel",
            "name": "[GME] - Gomel - GME - Gomel - Belarus",
            "searchName": "GME-Homel-Gomel-Gomel Havalimani-Homiel-Weißrußland-Belarus-Beyaz Rusya-Bélarus-Bielorrusia-Bielorussia",
            "priority": 0
        },
        {
            "iataCode": "BQT",
            "countryCode": "BY",
            "cityCode": "BQT",
            "cityName": "Brest",
            "name": "[BQT] - Brest - BQT - Brest - Belarus",
            "searchName": "BQT-Brest-Brest Havalimani-Weißrußland-Belarus-Beyaz Rusya-Bélarus-Bielorrusia-Bielorussia",
            "priority": 0
        },
        {
            "iataCode": "VTB",
            "countryCode": "BY",
            "cityCode": "VTB",
            "cityName": "Vitebsk",
            "name": "[VTB] - Vitebsk - VTB - Vitebsk - Belarus",
            "searchName": "VTB-Vitebsk-Weißrußland-Belarus-Beyaz Rusya-Bélarus-Bielorrusia-Bielorussia",
            "priority": 0
        },
        {
            "iataCode": "MHP",
            "countryCode": "BY",
            "cityCode": "MSQ",
            "cityName": "Minsk",
            "name": "[MHP] - Minsk International 1 - MSQ - Minsk - Belarus",
            "searchName": "MHP-MSQ-Minsk Intl 1-Minsk International 1-Minsk-Weißrußland-Belarus-Beyaz Rusya-Bélarus-Bielorrusia-Bielorussia",
            "priority": 0
        },
        {
            "iataCode": "MSQ",
            "countryCode": "BY",
            "cityCode": "MSQ",
            "cityName": "Minsk",
            "name": "[MSQ] - Minsk National 2 - MSQ - Minsk - Belarus",
            "searchName": "MSQ-Minsk Nationale 2-Minsk National 2-Minsk Intl 2-Minsk International 2-Minsk-Weißrußland-Belarus-Beyaz Rusya-Bélarus-Bielorrusia-Bielorussia",
            "priority": 0
        },
        {
            "iataCode": "MVQ",
            "countryCode": "BY",
            "cityCode": "MVQ",
            "cityName": "Mogilev",
            "name": "[MVQ] - Mogilev - MVQ - Mogilev - Belarus",
            "searchName": "MVQ-Mogilev-Mogilev Havalimani-Weißrußland-Belarus-Beyaz Rusya-Bélarus-Bielorrusia-Bielorussia",
            "priority": 0
        },
        {
            "iataCode": "MZE",
            "countryCode": "BZ",
            "cityCode": "MZE",
            "cityName": "Manatee",
            "name": "[MZE] - Manatee - MZE - Manatee - Belize",
            "searchName": "MZE-Manatee-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "MDB",
            "countryCode": "BZ",
            "cityCode": "MDB",
            "cityName": "Melinda",
            "name": "[MDB] - Melinda - MDB - Melinda - Belize",
            "searchName": "MDB-Melinda-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "PLJ",
            "countryCode": "BZ",
            "cityCode": "PLJ",
            "cityName": "Placencia",
            "name": "[PLJ] - Placencia - PLJ - Placencia - Belize",
            "searchName": "PLJ-Placencia-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "PND",
            "countryCode": "BZ",
            "cityCode": "PND",
            "cityName": "Punta Gorda",
            "name": "[PND] - Punta Gorda - PND - Punta Gorda - Belize",
            "searchName": "PND-Punta Gorda-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "ORZ",
            "countryCode": "BZ",
            "cityCode": "ORZ",
            "cityName": "Orange Walk",
            "name": "[ORZ] - Orange Walk - ORZ - Orange Walk - Belize",
            "searchName": "ORZ-Orange Walk-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "SVK",
            "countryCode": "BZ",
            "cityCode": "SVK",
            "cityName": "Silver Creek",
            "name": "[SVK] - Silver Creek - SVK - Silver Creek - Belize",
            "searchName": "SVK-Silver Creek-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "STU",
            "countryCode": "BZ",
            "cityCode": "STU",
            "cityName": "Santa Cruz",
            "name": "[STU] - Santa Cruz - STU - Santa Cruz - Belize",
            "searchName": "STU-Santa Cruz-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "SQS",
            "countryCode": "BZ",
            "cityCode": "SQS",
            "cityName": "San Ignacio",
            "name": "[SQS] - Matthew Spain - SQS - San Ignacio - Belize",
            "searchName": "SQS-Matthew Spain-San Ignacio-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "SPR",
            "countryCode": "BZ",
            "cityCode": "SPR",
            "cityName": "San Pedro",
            "name": "[SPR] - San Pedro - SPR - San Pedro - Belize",
            "searchName": "SPR-San Pedro-San Pedro Havalimani-Ambergris Caye-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "SJX",
            "countryCode": "BZ",
            "cityCode": "SJX",
            "cityName": "Sartaneja",
            "name": "[SJX] - Sartaneja - SJX - Sartaneja - Belize",
            "searchName": "SJX-Sartaneja-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "BZE",
            "countryCode": "BZ",
            "cityCode": "BZE",
            "cityName": "Belize City",
            "name": "[BZE] - Philip S. W. Goldson International - BZE - Belize City - Belize",
            "searchName": "BZE-Belize Phillip Goldson Intl-Philip S. W. Goldson International-Philip S.W.Goldson-International-Belize Stadt-Belize City-Ciudad de Belice-Belize Ville-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "TZA",
            "countryCode": "BZ",
            "cityCode": "BZE",
            "cityName": "Belize City",
            "name": "[TZA] - Municipal - BZE - Belize City - Belize",
            "searchName": "TZA-BZE-Belize City Municipal-Municipal-Belize City-Belize Stadt-Ciudad de Belice-Belize Ville-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "BCV",
            "countryCode": "BZ",
            "cityCode": "BCV",
            "cityName": "Belmopan",
            "name": "[BCV] - Belmopan - BCV - Belmopan - Belize",
            "searchName": "BCV-Belmopan-Belmop-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "BGK",
            "countryCode": "BZ",
            "cityCode": "BGK",
            "cityName": "Big Creek",
            "name": "[BGK] - Big Creek - BGK - Big Creek - Belize",
            "searchName": "BGK-Big Creek-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "DGA",
            "countryCode": "BZ",
            "cityCode": "DGA",
            "cityName": "Dangriga",
            "name": "[DGA] - Dangriga - DGA - Dangriga - Belize",
            "searchName": "DGA-Dangriga-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "CYC",
            "countryCode": "BZ",
            "cityCode": "CYC",
            "cityName": "Caye Chapel",
            "name": "[CYC] - Caye Chapel - CYC - Caye Chapel - Belize",
            "searchName": "CYC-Caye Chapel-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "CZH",
            "countryCode": "BZ",
            "cityCode": "CZH",
            "cityName": "Corozal",
            "name": "[CZH] - Corozal - CZH - Corozal - Belize",
            "searchName": "CZH-Corozal-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "CUK",
            "countryCode": "BZ",
            "cityCode": "CUK",
            "cityName": "Caye Caulker",
            "name": "[CUK] - Caye Caulker - CUK - Caye Caulker - Belize",
            "searchName": "CUK-Caye Caulker-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "INB",
            "countryCode": "BZ",
            "cityCode": "INB",
            "cityName": "Independence",
            "name": "[INB] - Independence - INB - Independence - Belize",
            "searchName": "INB-Independence-Belize-Belice",
            "priority": 0
        },
        {
            "iataCode": "ILF",
            "countryCode": "CA",
            "cityCode": "ILF",
            "cityName": "Ilford",
            "name": "[ILF] - Ilford - ILF - Ilford - Canada",
            "searchName": "ILF-Ilford-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "HZP",
            "countryCode": "CA",
            "cityCode": "HZP",
            "cityName": "Fort MacKay",
            "name": "[HZP] - Horizon - HZP - Fort MacKay - Canada",
            "searchName": "HZP-Horizon-Fort MacKay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "JHL",
            "countryCode": "CA",
            "cityCode": "HZP",
            "cityName": "Fort MacKay",
            "name": "[JHL] - Albian - HZP - Fort MacKay - Canada",
            "searchName": "JHL-HZP-Albian-Albian Aerodrome-Fort MacKay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFI",
            "countryCode": "CA",
            "cityCode": "HZP",
            "cityName": "Fort MacKay",
            "name": "[YFI] - Firebag - HZP - Fort MacKay - Canada",
            "searchName": "YFI-HZP-Firebag-Fort MacKay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "IUM",
            "countryCode": "CA",
            "cityCode": "IUM",
            "cityName": "Summit Lake",
            "name": "[IUM] - Summit Lake - IUM - Summit Lake - Canada",
            "searchName": "IUM-Summit Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "GSL",
            "countryCode": "CA",
            "cityCode": "GSL",
            "cityName": "Taltheilei Narrows",
            "name": "[GSL] - Taltheilei Narrows - GSL - Taltheilei Narrows - Canada",
            "searchName": "GSL-Taltheilei Narrows-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "KIF",
            "countryCode": "CA",
            "cityCode": "KIF",
            "cityName": "Kingfisher Lake",
            "name": "[KIF] - Kingfisher Lake - KIF - Kingfisher Lake - Canada",
            "searchName": "KIF-Kingfisher Lake-Kingfisher G-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "KNV",
            "countryCode": "CA",
            "cityCode": "KNV",
            "cityName": "Knights Inlet",
            "name": "[KNV] - Knights Inlet - KNV - Knights Inlet - Canada",
            "searchName": "KNV-Knights Inlet-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "KNY",
            "countryCode": "CA",
            "cityCode": "KNY",
            "cityName": "Kinoosao",
            "name": "[KNY] - Kinoosao - KNY - Kinoosao - Canada",
            "searchName": "KNY-Kinoosao-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "KES",
            "countryCode": "CA",
            "cityCode": "KES",
            "cityName": "Kelsey",
            "name": "[KES] - Kelsey - KES - Kelsey - Canada",
            "searchName": "KES-Kelsey-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "KEW",
            "countryCode": "CA",
            "cityCode": "KEW",
            "cityName": "Keewaywin",
            "name": "[KEW] - Keewaywin - KEW - Keewaywin - Canada",
            "searchName": "KEW-Keewaywin-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "LRQ",
            "countryCode": "CA",
            "cityCode": "LRQ",
            "cityName": "Laurie River",
            "name": "[LRQ] - Laurie River - LRQ - Laurie River - Canada",
            "searchName": "LRQ-Laurie River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "LAK",
            "countryCode": "CA",
            "cityCode": "LAK",
            "cityName": "Aklavik",
            "name": "[LAK] - Aklavik - LAK - Aklavik - Canada",
            "searchName": "LAK-Aklavik-Aklavik\\/Freddie Carmichael Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "DAS",
            "countryCode": "CA",
            "cityCode": "DAS",
            "cityName": "Great Bear Lake",
            "name": "[DAS] - Great Bear Lake - DAS - Great Bear Lake - Canada",
            "searchName": "DAS-Great Bear Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "CJH",
            "countryCode": "CA",
            "cityCode": "CJH",
            "cityName": "Chilko Lake",
            "name": "[CJH] - Chilko Lake - CJH - Chilko Lake - Canada",
            "searchName": "CJH-Chilko Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "CFQ",
            "countryCode": "CA",
            "cityCode": "CFQ",
            "cityName": "Creston",
            "name": "[CFQ] - Creston Valley Airport - CFQ - Creston - Canada",
            "searchName": "CFQ-Creston Valley Airport-Creston Havalimani-Creston-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "DGF",
            "countryCode": "CA",
            "cityCode": "DGF",
            "cityName": "Douglas Lake",
            "name": "[DGF] - Douglas Lake - DGF - Douglas Lake - Canada",
            "searchName": "DGF-Douglas Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "DCP",
            "countryCode": "CA",
            "cityCode": "DCP",
            "cityName": "Cabin Plant",
            "name": "[DCP] - Cabin Plant Heliport - DCP - Cabin Plant - Canada",
            "searchName": "DCP-Cabin Plant Heliport-Cabin Plant-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "DHO",
            "countryCode": "CA",
            "cityCode": "DHO",
            "cityName": "Horn River",
            "name": "[DHO] - Horn River - DHO - Horn River - Canada",
            "searchName": "DHO-Horn River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "DKL",
            "countryCode": "CA",
            "cityCode": "DKL",
            "cityName": "Kiwigana Lodge",
            "name": "[DKL] - Kiwigana Lodge - DKL - Kiwigana Lodge - Canada",
            "searchName": "DKL-Kiwigana Lodge-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "DUQ",
            "countryCode": "CA",
            "cityCode": "DUQ",
            "cityName": "Duncan/Quam",
            "name": "[DUQ] - Duncan/Quam - DUQ - Duncan/Quam - Canada",
            "searchName": "DUQ-Duncan/Quam-Duncan\\/Quam-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "DVK",
            "countryCode": "CA",
            "cityCode": "DVK",
            "cityName": "Diavik",
            "name": "[DVK] - Diavik - DVK - Diavik - Canada",
            "searchName": "DVK-Diavik-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "AKV",
            "countryCode": "CA",
            "cityCode": "AKV",
            "cityName": "Akulivik",
            "name": "[AKV] - Akulivik - AKV - Akulivik - Canada",
            "searchName": "AKV-Akulivik-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "SSQ",
            "countryCode": "CA",
            "cityCode": "SSQ",
            "cityName": "La Sarre",
            "name": "[SSQ] - La Sarre - SSQ - La Sarre - Canada",
            "searchName": "SSQ-La Sarre-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "SUR",
            "countryCode": "CA",
            "cityCode": "SUR",
            "cityName": "Summer Beaver",
            "name": "[SUR] - Summer Beaver - SUR - Summer Beaver - Canada",
            "searchName": "SUR-Summer Beaver-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "TNS",
            "countryCode": "CA",
            "cityCode": "TNS",
            "cityName": "Tungsten",
            "name": "[TNS] - Tungsten - TNS - Tungsten - Canada",
            "searchName": "TNS-Tungsten-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "TUX",
            "countryCode": "CA",
            "cityCode": "TUX",
            "cityName": "Tumbler Ridge",
            "name": "[TUX] - Tumbler Ridge - TUX - Tumbler Ridge - Canada",
            "searchName": "TUX-Tumbler Ridge-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "SYF",
            "countryCode": "CA",
            "cityCode": "SYF",
            "cityName": "Silva Bay",
            "name": "[SYF] - Silva Bay - SYF - Silva Bay - Canada",
            "searchName": "SYF-Silva Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "TIL",
            "countryCode": "CA",
            "cityCode": "TIL",
            "cityName": "Inverlake",
            "name": "[TIL] - Inverlake - TIL - Inverlake - Canada",
            "searchName": "TIL-Inverlake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "WPC",
            "countryCode": "CA",
            "cityCode": "WPC",
            "cityName": "Pincher Creek",
            "name": "[WPC] - Pincher Creek - WPC - Pincher Creek - Canada",
            "searchName": "WPC-Pincher Creek-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "WPL",
            "countryCode": "CA",
            "cityCode": "WPL",
            "cityName": "Powell Lake",
            "name": "[WPL] - Powell Lake - WPL - Powell Lake - Canada",
            "searchName": "WPL-Powell Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "WNN",
            "countryCode": "CA",
            "cityCode": "WNN",
            "cityName": "Wunnummin Lake",
            "name": "[WNN] - Wunnummin Lake - WNN - Wunnummin Lake - Canada",
            "searchName": "WNN-Wunnummin Lake- Wunnummin Lake-Wunnummin G-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "UZM",
            "countryCode": "CA",
            "cityCode": "UZM",
            "cityName": "Hope Bay",
            "name": "[UZM] - Hope Bay - UZM - Hope Bay - Canada",
            "searchName": "UZM-Hope Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "JOJ",
            "countryCode": "CA",
            "cityCode": "UZM",
            "cityName": "Hope Bay",
            "name": "[JOJ] - Doris Lake - UZM - Hope Bay - Canada",
            "searchName": "JOJ-UZM-Doris Lake-Hope Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XAW",
            "countryCode": "CA",
            "cityCode": "XAW",
            "cityName": "Capreol",
            "name": "[XAW] - Capreol Railway Station - XAW - Capreol - Canada",
            "searchName": "XAW-Capreol Railway Station-Capreol-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XBR",
            "countryCode": "CA",
            "cityCode": "XBR",
            "cityName": "Brockville",
            "name": "[XBR] - Brockville - XBR - Brockville - Canada",
            "searchName": "XBR-Brockville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XBW",
            "countryCode": "CA",
            "cityCode": "XBW",
            "cityName": "Killineq",
            "name": "[XBW] - Killineq - XBW - Killineq - Canada",
            "searchName": "XBW-Killineq-Killineq Havalimani-Killineq Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XAZ",
            "countryCode": "CA",
            "cityCode": "XAZ",
            "cityName": "Campbellton",
            "name": "[XAZ] - Campbellton Railway Station - XAZ - Campbellton - Canada",
            "searchName": "XAZ-Campbellton Railway Station-Campbellton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XBB",
            "countryCode": "CA",
            "cityCode": "XBB",
            "cityName": "Blubber Bay",
            "name": "[XBB] - Blubber Bay - XBB - Blubber Bay - Canada",
            "searchName": "XBB-Blubber Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XBE",
            "countryCode": "CA",
            "cityCode": "XBE",
            "cityName": "Bearskin Lake",
            "name": "[XBE] - Bearskin Lake - XBE - Bearskin Lake - Canada",
            "searchName": "XBE-Bearskin Lake-Bearskin G-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XCI",
            "countryCode": "CA",
            "cityCode": "XCI",
            "cityName": "Chambord",
            "name": "[XCI] - Chambord Railway Station - XCI - Chambord - Canada",
            "searchName": "XCI-Chambord Railway Station-Chambord-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XCL",
            "countryCode": "CA",
            "cityCode": "XCL",
            "cityName": "Cluff Lake",
            "name": "[XCL] - Cluff Lake - XCL - Cluff Lake - Canada",
            "searchName": "XCL-Cluff Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XCM",
            "countryCode": "CA",
            "cityCode": "XCM",
            "cityName": "Chatham",
            "name": "[XCM] - Chatham - XCM - Chatham - Canada",
            "searchName": "XCM-Chatham-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XGJ",
            "countryCode": "CA",
            "cityCode": "XGJ",
            "cityName": "Cobourg",
            "name": "[XGJ] - Cobourg Railway Station - XGJ - Cobourg - Canada",
            "searchName": "XGJ-Cobourg Railway Station-Cobourg-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XGK",
            "countryCode": "CA",
            "cityCode": "XGK",
            "cityName": "Coteau",
            "name": "[XGK] - Coteau Railway Station - XGK - Coteau - Canada",
            "searchName": "XGK-Coteau Railway Station-Coteau-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XGL",
            "countryCode": "CA",
            "cityCode": "XGL",
            "cityName": "Granville Lake",
            "name": "[XGL] - Granville Lake - XGL - Granville Lake - Canada",
            "searchName": "XGL-Granville Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XGR",
            "countryCode": "CA",
            "cityCode": "XGR",
            "cityName": "Kangiqsualujjuaq",
            "name": "[XGR] - Kangiqsualujjuaq - XGR - Kangiqsualujjuaq - Canada",
            "searchName": "XGR-Kangiqsualujjuaq-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XGW",
            "countryCode": "CA",
            "cityCode": "XGW",
            "cityName": "Gananoque",
            "name": "[XGW] - Gananoque Railway Station - XGW - Gananoque - Canada",
            "searchName": "XGW-Gananoque Railway Station-Gananoque-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XHM",
            "countryCode": "CA",
            "cityCode": "XHM",
            "cityName": "Georgetown",
            "name": "[XHM] - Georgetown Railway Station - XHM - Georgetown - Canada",
            "searchName": "XHM-Georgetown Railway Station-Georgetown-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XHS",
            "countryCode": "CA",
            "cityCode": "XHS",
            "cityName": "Chemainus",
            "name": "[XHS] - Chemainus Railway Station - XHS - Chemainus - Canada",
            "searchName": "XHS-Chemainus Railway Station-Chemainus-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XIA",
            "countryCode": "CA",
            "cityCode": "XIA",
            "cityName": "Guelph",
            "name": "[XIA] - Guelph Railway Station - XIA - Guelph - Canada",
            "searchName": "XIA-Guelph Railway Station-Guelph-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XIB",
            "countryCode": "CA",
            "cityCode": "XIB",
            "cityName": "Ingersoll",
            "name": "[XIB] - Ingersoll Railway Station - XIB - Ingersoll - Canada",
            "searchName": "XIB-Ingersoll Railway Station-Ingersoll-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XID",
            "countryCode": "CA",
            "cityCode": "XID",
            "cityName": "Maxville",
            "name": "[XID] - Maxville Railway Station - XID - Maxville - Canada",
            "searchName": "XID-Maxville Railway Station-Maxville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFV",
            "countryCode": "CA",
            "cityCode": "XFV",
            "cityName": "Brantford",
            "name": "[XFV] - Brantford Railway Station - XFV - Brantford - Canada",
            "searchName": "XFV-Brantford Railway Station-Brantford-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFO",
            "countryCode": "CA",
            "cityCode": "XFO",
            "cityName": "Taschereau",
            "name": "[XFO] - Taschereau Railway Station - XFO - Taschereau - Canada",
            "searchName": "XFO-Taschereau Railway Station-Taschereau-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFQ",
            "countryCode": "CA",
            "cityCode": "XFQ",
            "cityName": "Weymont",
            "name": "[XFQ] - Weymont Railway Station - XFQ - Weymont - Canada",
            "searchName": "XFQ-Weymont Railway Station-Weymont-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFS",
            "countryCode": "CA",
            "cityCode": "XFS",
            "cityName": "Alexandria",
            "name": "[XFS] - Alexandria Railway Station - XFS - Alexandria - Canada",
            "searchName": "XFS-Alexandria Railway Station-Alexandria-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XEY",
            "countryCode": "CA",
            "cityCode": "XEY",
            "cityName": "Miramichi",
            "name": "[XEY] - Miramichi Railway Station - XEY - Miramichi - Canada",
            "searchName": "XEY-Miramichi Railway Station-Miramichi-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFC",
            "countryCode": "CA",
            "cityCode": "XFC",
            "cityName": "Fredericton Junction",
            "name": "[XFC] - Fredericton Junction Railway Station - XFC - Fredericton Junction - Canada",
            "searchName": "XFC-Fredericton Junction Railway Station-Fredericton Junction-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFD",
            "countryCode": "CA",
            "cityCode": "XFD",
            "cityName": "Stratford",
            "name": "[XFD] - Stratford Railway Station - XFD - Stratford - Canada",
            "searchName": "XFD-Stratford Railway Station-Stratford-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFE",
            "countryCode": "CA",
            "cityCode": "XFE",
            "cityName": "Parent",
            "name": "[XFE] - Parent Railway Station - XFE - Parent - Canada",
            "searchName": "XFE-Parent Railway Station-Parent-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFG",
            "countryCode": "CA",
            "cityCode": "XFG",
            "cityName": "Perce",
            "name": "[XFG] - Perce Railway Station - XFG - Perce - Canada",
            "searchName": "XFG-Perce Railway Station-Perce-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFI",
            "countryCode": "CA",
            "cityCode": "XFI",
            "cityName": "Port-Daniel",
            "name": "[XFI] - Port-Daniel Railway Station - XFI - Port-Daniel - Canada",
            "searchName": "XFI-Port-Daniel Railway Station-Daniel-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFK",
            "countryCode": "CA",
            "cityCode": "XFK",
            "cityName": "Senneterre",
            "name": "[XFK] - Senneterre Railway Station - XFK - Senneterre - Canada",
            "searchName": "XFK-Senneterre Railway Station-Senneterre-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFL",
            "countryCode": "CA",
            "cityCode": "XFL",
            "cityName": "Shawinigan",
            "name": "[XFL] - Shawinigan Railway Station - XFL - Shawinigan - Canada",
            "searchName": "XFL-Shawinigan Railway Station-Shawinigan-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFM",
            "countryCode": "CA",
            "cityCode": "XFM",
            "cityName": "Shawnigan",
            "name": "[XFM] - Shawnigan Railway Station - XFM - Shawnigan - Canada",
            "searchName": "XFM-Shawnigan Railway Station-Shawnigan-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XDL",
            "countryCode": "CA",
            "cityCode": "XDL",
            "cityName": "Chandler",
            "name": "[XDL] - Chandler Railway Station - XDL - Chandler - Canada",
            "searchName": "XDL-Chandler Railway Station-Chandler-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XDM",
            "countryCode": "CA",
            "cityCode": "XDM",
            "cityName": "Drummondville",
            "name": "[XDM] - Drummondville Railway Station - XDM - Drummondville - Canada",
            "searchName": "XDM-Drummondville Railway Station-Drummondville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XDO",
            "countryCode": "CA",
            "cityCode": "XDO",
            "cityName": "Grande-Riviere",
            "name": "[XDO] - Grande-Riviere Railway Station - XDO - Grande-Riviere - Canada",
            "searchName": "XDO-Grande-Riviere Railway Station-Riviere-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XDU",
            "countryCode": "CA",
            "cityCode": "XDU",
            "cityName": "Hervey",
            "name": "[XDU] - Hervey Railway Station - XDU - Hervey - Canada",
            "searchName": "XDU-Hervey Railway Station-Hervey-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XEE",
            "countryCode": "CA",
            "cityCode": "XEE",
            "cityName": "Lac Edouard",
            "name": "[XEE] - Lac Edouard Railway Station - XEE - Lac Edouard - Canada",
            "searchName": "XEE-Lac Edouard Railway Station-Lac Edouard-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XEH",
            "countryCode": "CA",
            "cityCode": "XEH",
            "cityName": "Ladysmith",
            "name": "[XEH] - Ladysmith Railway Station - XEH - Ladysmith - Canada",
            "searchName": "XEH-Ladysmith Railway Station-Ladysmith-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XEJ",
            "countryCode": "CA",
            "cityCode": "XEJ",
            "cityName": "Langford",
            "name": "[XEJ] - Langford Railway Station - XEJ - Langford - Canada",
            "searchName": "XEJ-Langford Railway Station-Langford-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XEK",
            "countryCode": "CA",
            "cityCode": "XEK",
            "cityName": "Melville",
            "name": "[XEK] - Melville Railway Station - XEK - Melville - Canada",
            "searchName": "XEK-Melville Railway Station-Melville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XEL",
            "countryCode": "CA",
            "cityCode": "XEL",
            "cityName": "New Carlisle",
            "name": "[XEL] - New Carlisle Railway Station - XEL - New Carlisle - Canada",
            "searchName": "XEL-New Carlisle Railway Station-New Carlisle-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XEM",
            "countryCode": "CA",
            "cityCode": "XEM",
            "cityName": "New Richmond",
            "name": "[XEM] - New Richmond Railway Station - XEM - New Richmond - Canada",
            "searchName": "XEM-New Richmond Railway Station-New Richmond-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XII",
            "countryCode": "CA",
            "cityCode": "XII",
            "cityName": "Prescott",
            "name": "[XII] - Prescott Railway Station - XII - Prescott - Canada",
            "searchName": "XII-Prescott Railway Station-Prescott-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XGY",
            "countryCode": "CA",
            "cityCode": "XGY",
            "cityName": "Grimsby",
            "name": "[XGY] - Grimsby Railway Station - XGY - Grimsby - Canada",
            "searchName": "XGY-Grimsby Railway Station-Grimsby-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XIF",
            "countryCode": "CA",
            "cityCode": "XIF",
            "cityName": "Napanee",
            "name": "[XIF] - Napanee Railway Station - XIF - Napanee - Canada",
            "searchName": "XIF-Napanee Railway Station-Napanee-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XIO",
            "countryCode": "CA",
            "cityCode": "XIO",
            "cityName": "St Marys",
            "name": "[XIO] - St Marys Railway Station - XIO - St Marys - Canada",
            "searchName": "XIO-St Marys Railway Station-St Marys-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XIP",
            "countryCode": "CA",
            "cityCode": "XIP",
            "cityName": "Woodstock",
            "name": "[XIP] - Woodstock Railway Station - XIP - Woodstock - Canada",
            "searchName": "XIP-Woodstock Railway Station-Woodstock-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XKS",
            "countryCode": "CA",
            "cityCode": "XKS",
            "cityName": "Kasabonika",
            "name": "[XKS] - Kasabonika - XKS - Kasabonika - Canada",
            "searchName": "XKS-Kasabonika-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XKV",
            "countryCode": "CA",
            "cityCode": "XKV",
            "cityName": "Sackville",
            "name": "[XKV] - Sackville Railway Station - XKV - Sackville - Canada",
            "searchName": "XKV-Sackville Railway Station-Sackville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XJQ",
            "countryCode": "CA",
            "cityCode": "XJQ",
            "cityName": "Jonquiere",
            "name": "[XJQ] - Jonquiere Railway Station - XJQ - Jonquiere - Canada",
            "searchName": "XJQ-Jonquiere Railway Station-Jonquiere-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XKO",
            "countryCode": "CA",
            "cityCode": "XKO",
            "cityName": "Kemano",
            "name": "[XKO] - Kemano - XKO - Kemano - Canada",
            "searchName": "XKO-Kemano-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XIM",
            "countryCode": "CA",
            "cityCode": "XIM",
            "cityName": "Saint Hyacinthe",
            "name": "[XIM] - Saint Hyacinthe Railway Station - XIM - Saint Hyacinthe - Canada",
            "searchName": "XIM-Saint Hyacinthe Railway Station-Saint Hyacinthe-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XJL",
            "countryCode": "CA",
            "cityCode": "XJL",
            "cityName": "Joliette",
            "name": "[XJL] - Joliette Railway Station - XJL - Joliette - Canada",
            "searchName": "XJL-Joliette Railway Station-Joliette-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XOK",
            "countryCode": "CA",
            "cityCode": "XOK",
            "cityName": "Oakville",
            "name": "[XOK] - Oakville Railway Station - XOK - Oakville - Canada",
            "searchName": "XOK-Oakville Railway Station-Oakville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XON",
            "countryCode": "CA",
            "cityCode": "XON",
            "cityName": "Carleton",
            "name": "[XON] - Carleton Railway Station - XON - Carleton - Canada",
            "searchName": "XON-Carleton Railway Station-Carleton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XLV",
            "countryCode": "CA",
            "cityCode": "XLV",
            "cityName": "Niagara Falls",
            "name": "[XLV] - Niagara Falls Railway Station - XLV - Niagara Falls - Canada",
            "searchName": "XLV-Niagara Falls Railway Station-Niagara Falls-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XLY",
            "countryCode": "CA",
            "cityCode": "XLY",
            "cityName": "Aldershot",
            "name": "[XLY] - Aldershot Railway Station - XLY - Aldershot - Canada",
            "searchName": "XLY-Aldershot Railway Station-Aldershot-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XLZ",
            "countryCode": "CA",
            "cityCode": "XLZ",
            "cityName": "Truro",
            "name": "[XLZ] - Truro Railway Station - XLZ - Truro - Canada",
            "searchName": "XLZ-Truro Railway Station-Truro-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XLB",
            "countryCode": "CA",
            "cityCode": "XLB",
            "cityName": "Lac Brochet",
            "name": "[XLB] - Lac Brochet - XLB - Lac Brochet - Canada",
            "searchName": "XLB-Lac Brochet-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XLF",
            "countryCode": "CA",
            "cityCode": "XLF",
            "cityName": "Leaf Bay",
            "name": "[XLF] - Leaf Bay - XLF - Leaf Bay - Canada",
            "searchName": "XLF-Leaf Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XLP",
            "countryCode": "CA",
            "cityCode": "XLP",
            "cityName": "Matapedia",
            "name": "[XLP] - Matapedia Railway Station - XLP - Matapedia - Canada",
            "searchName": "XLP-Matapedia Railway Station-Matapedia-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XMP",
            "countryCode": "CA",
            "cityCode": "XMP",
            "cityName": "Macmillan Pass",
            "name": "[XMP] - Macmillan Pass - XMP - Macmillan Pass - Canada",
            "searchName": "XMP-Macmillan Pass-MacMillan Pass-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XPB",
            "countryCode": "CA",
            "cityCode": "XPB",
            "cityName": "Parksville",
            "name": "[XPB] - Parksville Railway Station - XPB - Parksville - Canada",
            "searchName": "XPB-Parksville Railway Station-Parksville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XPH",
            "countryCode": "CA",
            "cityCode": "XPH",
            "cityName": "Port Hope",
            "name": "[XPH] - Port Hope Railway Station - XPH - Port Hope - Canada",
            "searchName": "XPH-Port Hope Railway Station-Port Hope-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XPK",
            "countryCode": "CA",
            "cityCode": "XPK",
            "cityName": "Pukatawagan",
            "name": "[XPK] - Pukatawagan - XPK - Pukatawagan - Canada",
            "searchName": "XPK-Pukatawagan-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XPN",
            "countryCode": "CA",
            "cityCode": "XPN",
            "cityName": "Brampton",
            "name": "[XPN] - Brampton Railway Station - XPN - Brampton - Canada",
            "searchName": "XPN-Brampton Railway Station-Brampton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XPP",
            "countryCode": "CA",
            "cityCode": "XPP",
            "cityName": "Poplar River",
            "name": "[XPP] - Poplar River - XPP - Poplar River - Canada",
            "searchName": "XPP-Poplar River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XPX",
            "countryCode": "CA",
            "cityCode": "XPX",
            "cityName": "Pointe-aux-Trembles",
            "name": "[XPX] - Pointe-aux-Trembles Railway Station - XPX - Pointe-aux-Trembles - Canada",
            "searchName": "XPX-Pointe-aux-Trembles Railway Station-Trembles-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XQU",
            "countryCode": "CA",
            "cityCode": "XQU",
            "cityName": "Qualicum",
            "name": "[XQU] - Qualicum - XQU - Qualicum - Canada",
            "searchName": "XQU-Qualicum-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XSI",
            "countryCode": "CA",
            "cityCode": "XSI",
            "cityName": "South Indian Lake",
            "name": "[XSI] - South Indian Lake - XSI - South Indian Lake - Canada",
            "searchName": "XSI-South Indian Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XRP",
            "countryCode": "CA",
            "cityCode": "XRP",
            "cityName": "Riviere-a-Pierre",
            "name": "[XRP] - Riviere-a-Pierre Railway Station - XRP - Riviere-a-Pierre - Canada",
            "searchName": "XRP-Riviere-a-Pierre Railway Station-Pierre-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XRR",
            "countryCode": "CA",
            "cityCode": "XRR",
            "cityName": "Ross River",
            "name": "[XRR] - Ross River - XRR - Ross River - Canada",
            "searchName": "XRR-Ross River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XTY",
            "countryCode": "CA",
            "cityCode": "XTY",
            "cityName": "Strathroy",
            "name": "[XTY] - Strathroy Railway Station - XTY - Strathroy - Canada",
            "searchName": "XTY-Strathroy Railway Station-Strathroy-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XZB",
            "countryCode": "CA",
            "cityCode": "XZB",
            "cityName": "Casselman",
            "name": "[XZB] - Casselman Railway Station - XZB - Casselman - Canada",
            "searchName": "XZB-Casselman Railway Station-Casselman-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XZC",
            "countryCode": "CA",
            "cityCode": "XZC",
            "cityName": "Glencoe",
            "name": "[XZC] - Glencoe Railway Station - XZC - Glencoe - Canada",
            "searchName": "XZC-Glencoe Railway Station-Glencoe-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XZK",
            "countryCode": "CA",
            "cityCode": "XZK",
            "cityName": "Amherst",
            "name": "[XZK] - Amherst Railway Station - XZK - Amherst - Canada",
            "searchName": "XZK-Amherst Railway Station-Amherst-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAA",
            "countryCode": "CA",
            "cityCode": "YAA",
            "cityName": "Anahim Lake",
            "name": "[YAA] - Anahim Lake - YAA - Anahim Lake - Canada",
            "searchName": "YAA-Anahim Lake-Anahim G-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAB",
            "countryCode": "CA",
            "cityCode": "YAB",
            "cityName": "Arctic Bay",
            "name": "[YAB] - Arctic Bay - YAB - Arctic Bay - Canada",
            "searchName": "YAB-Arctic Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAC",
            "countryCode": "CA",
            "cityCode": "YAC",
            "cityName": "Cat Lake",
            "name": "[YAC] - Cat Lake - YAC - Cat Lake - Canada",
            "searchName": "YAC-Cat Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAE",
            "countryCode": "CA",
            "cityCode": "YAE",
            "cityName": "Alta Lake",
            "name": "[YAE] - Alta Lake - YAE - Alta Lake - Canada",
            "searchName": "YAE-Alta Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAF",
            "countryCode": "CA",
            "cityCode": "YAF",
            "cityName": "Asbestos Hill",
            "name": "[YAF] - Asbestos Hill - YAF - Asbestos Hill - Canada",
            "searchName": "YAF-Asbestos Hill-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAG",
            "countryCode": "CA",
            "cityCode": "YAG",
            "cityName": "Fort Frances",
            "name": "[YAG] - Fort Frances Municipal - YAG - Fort Frances - Canada",
            "searchName": "YAG-Fort Frankreichs-Fort Frances Municipal-Fort Frances-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAH",
            "countryCode": "CA",
            "cityCode": "YAH",
            "cityName": "Lagrande 4",
            "name": "[YAH] - Lagrande 4 - YAH - Lagrande 4 - Canada",
            "searchName": "YAH-Lagrande 4-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAT",
            "countryCode": "CA",
            "cityCode": "YAT",
            "cityName": "Attawapiskat",
            "name": "[YAT] - Attawapiskat - YAT - Attawapiskat - Canada",
            "searchName": "YAT-Attawapiskat-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAU",
            "countryCode": "CA",
            "cityCode": "YAU",
            "cityName": "Kattiniq",
            "name": "[YAU] - Kattiniq/donaldson - YAU - Kattiniq - Canada",
            "searchName": "YAU-Kattiniq/donaldson-Kattiniq\\/Donaldson Lake-Kattiniq-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAV",
            "countryCode": "CA",
            "cityCode": "YAV",
            "cityName": "Miners Bay",
            "name": "[YAV] - Miners Bay - YAV - Miners Bay - Canada",
            "searchName": "YAV-Miners Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAX",
            "countryCode": "CA",
            "cityCode": "YAX",
            "cityName": "Angling Lake",
            "name": "[YAX] - Angling Lake - YAX - Angling Lake - Canada",
            "searchName": "YAX-Angling Lake-Angling G-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAY",
            "countryCode": "CA",
            "cityCode": "YAY",
            "cityName": "St Anthony",
            "name": "[YAY] - St Anthony - YAY - St Anthony - Canada",
            "searchName": "YAY-St Anthony-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAZ",
            "countryCode": "CA",
            "cityCode": "YAZ",
            "cityName": "Tofino",
            "name": "[YAZ] - Tofino Airport - YAZ - Tofino - Canada",
            "searchName": "YAZ-Kommunaler Flughafen-Tofino Airport-Tofino-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTP",
            "countryCode": "CA",
            "cityCode": "YAZ",
            "cityName": "Tofino",
            "name": "[YTP] - Seaplane Base - YAZ - Tofino - Canada",
            "searchName": "YTP-YAZ-Seaplane Base-Tofino-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBA",
            "countryCode": "CA",
            "cityCode": "YBA",
            "cityName": "Banff",
            "name": "[YBA] - Banff - YBA - Banff - Canada",
            "searchName": "YBA-Banff-Banff Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBB",
            "countryCode": "CA",
            "cityCode": "YBB",
            "cityName": "Kugaaruk",
            "name": "[YBB] - Kugaaruk - YBB - Kugaaruk - Canada",
            "searchName": "YBB-Kugaaruk-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBC",
            "countryCode": "CA",
            "cityCode": "YBC",
            "cityName": "Baie Comeau",
            "name": "[YBC] - Baie Comeau - YBC - Baie Comeau - Canada",
            "searchName": "YBC-Baie Comeau-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBD",
            "countryCode": "CA",
            "cityCode": "YBD",
            "cityName": "New Westminster",
            "name": "[YBD] - New Westminster - YBD - New Westminster - Canada",
            "searchName": "YBD-New Westminster-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBE",
            "countryCode": "CA",
            "cityCode": "YBE",
            "cityName": "Uranium City",
            "name": "[YBE] - Uranium City - YBE - Uranium City - Canada",
            "searchName": "YBE-Uranium City-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBF",
            "countryCode": "CA",
            "cityCode": "YBF",
            "cityName": "Bamfield",
            "name": "[YBF] - Bamfield - YBF - Bamfield - Canada",
            "searchName": "YBF-Bamfield-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBG",
            "countryCode": "CA",
            "cityCode": "YBG",
            "cityName": "Bagotville",
            "name": "[YBG] - Bagotville - YBG - Bagotville - Canada",
            "searchName": "YBG-Bagotville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBH",
            "countryCode": "CA",
            "cityCode": "YBH",
            "cityName": "Bull Harbour",
            "name": "[YBH] - Bull Harbour - YBH - Bull Harbour - Canada",
            "searchName": "YBH-Bull Harbour-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBI",
            "countryCode": "CA",
            "cityCode": "YBI",
            "cityName": "Black Tickle",
            "name": "[YBI] - Black Tickle - YBI - Black Tickle - Canada",
            "searchName": "YBI-Black Tickle-Black Tickle NF-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBJ",
            "countryCode": "CA",
            "cityCode": "YBJ",
            "cityName": "Baie Johan Beetz",
            "name": "[YBJ] - Baie Johan Beetz - YBJ - Baie Johan Beetz - Canada",
            "searchName": "YBJ-Baie Johan Beetz-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBK",
            "countryCode": "CA",
            "cityCode": "YBK",
            "cityName": "Baker Lake",
            "name": "[YBK] - Baker Lake - YBK - Baker Lake - Canada",
            "searchName": "YBK-Baker Lake-Baker G-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBL",
            "countryCode": "CA",
            "cityCode": "YBL",
            "cityName": "Campbell River",
            "name": "[YBL] - Campbell River - YBL - Campbell River - Canada",
            "searchName": "YBL-Campbell River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHH",
            "countryCode": "CA",
            "cityCode": "YBL",
            "cityName": "Campbell River",
            "name": "[YHH] - Harbor SPB - YBL - Campbell River - Canada",
            "searchName": "YHH-YBL-Harbor SPB-Harbor Havalimani-Campbell River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBN",
            "countryCode": "CA",
            "cityCode": "YBN",
            "cityName": "Borden",
            "name": "[YBN] - Borden - YBN - Borden - Canada",
            "searchName": "YBN-Borden-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBO",
            "countryCode": "CA",
            "cityCode": "YBO",
            "cityName": "Bobquinn Lake",
            "name": "[YBO] - Bob Quinn Lake - YBO - Bobquinn Lake - Canada",
            "searchName": "YBO-Bobquinn Lake-Bob Quinn Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XTL",
            "countryCode": "CA",
            "cityCode": "XTL",
            "cityName": "Tadoule Lake",
            "name": "[XTL] - Tadoule Lake - XTL - Tadoule Lake - Canada",
            "searchName": "XTL-Tadoule Lake-Tadoule G-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XVV",
            "countryCode": "CA",
            "cityCode": "XVV",
            "cityName": "Belleville",
            "name": "[XVV] - Belleville Railway Station - XVV - Belleville - Canada",
            "searchName": "XVV-Belleville Railway Station-Belleville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XWA",
            "countryCode": "CA",
            "cityCode": "XWA",
            "cityName": "Watford",
            "name": "[XWA] - Watford Railway Station - XWA - Watford - Canada",
            "searchName": "XWA-Watford Railway Station-Watford-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XWY",
            "countryCode": "CA",
            "cityCode": "XWY",
            "cityName": "Wyoming",
            "name": "[XWY] - Wyoming Railway Station - XWY - Wyoming - Canada",
            "searchName": "XWY-Wyoming Railway Station-Wyoming-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBQ",
            "countryCode": "CA",
            "cityCode": "YBQ",
            "cityName": "Telegraph Harbour",
            "name": "[YBQ] - Telegraph Harbour - YBQ - Telegraph Harbour - Canada",
            "searchName": "YBQ-Telegraph Harbour-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBR",
            "countryCode": "CA",
            "cityCode": "YBR",
            "cityName": "Brandon",
            "name": "[YBR] - Brandon - YBR - Brandon - Canada",
            "searchName": "YBR-Brandon-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBS",
            "countryCode": "CA",
            "cityCode": "YBS",
            "cityName": "Opapamiska Lake",
            "name": "[YBS] - Musselwhite - YBS - Opapamiska Lake - Canada",
            "searchName": "YBS-Musselwhite-Opapamiska Lake-Opapamiska G-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBT",
            "countryCode": "CA",
            "cityCode": "YBT",
            "cityName": "Brochet",
            "name": "[YBT] - Brochet - YBT - Brochet - Canada",
            "searchName": "YBT-Brochet-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBV",
            "countryCode": "CA",
            "cityCode": "YBV",
            "cityName": "Berens River",
            "name": "[YBV] - Berens River - YBV - Berens River - Canada",
            "searchName": "YBV-Berens River-Berens Nehri-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBW",
            "countryCode": "CA",
            "cityCode": "YBW",
            "cityName": "Bedwell Harbor",
            "name": "[YBW] - Springbank - YBW - Bedwell Harbor - Canada",
            "searchName": "YBW-Bedwell Harbor-Springbank-Bedwell Harbor Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBX",
            "countryCode": "CA",
            "cityCode": "YBX",
            "cityName": "Blanc Sablon",
            "name": "[YBX] - Blanc Sablon - YBX - Blanc Sablon - Canada",
            "searchName": "YBX-Blanc Sablon-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YBY",
            "countryCode": "CA",
            "cityCode": "YBY",
            "cityName": "Bonnyville",
            "name": "[YBY] - Bonnyville - YBY - Bonnyville - Canada",
            "searchName": "YBY-Bonnyville-Bonnyville ON-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCA",
            "countryCode": "CA",
            "cityCode": "YCA",
            "cityName": "Courtenay",
            "name": "[YCA] - Courtenay - YCA - Courtenay - Canada",
            "searchName": "YCA-Courtenay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCB",
            "countryCode": "CA",
            "cityCode": "YCB",
            "cityName": "Cambridge Bay",
            "name": "[YCB] - Cambridge Bay - YCB - Cambridge Bay - Canada",
            "searchName": "YCB-Cambridge Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCC",
            "countryCode": "CA",
            "cityCode": "YCC",
            "cityName": "Cornwall",
            "name": "[YCC] - Regional - YCC - Cornwall - Canada",
            "searchName": "YCC-Regional-Cornwall-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCD",
            "countryCode": "CA",
            "cityCode": "YCD",
            "cityName": "Nanaimo",
            "name": "[YCD] - Nanaimo - YCD - Nanaimo - Canada",
            "searchName": "YCD-Nanaimo-Nanaimo Arpt-Cassidy Airport-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZNA",
            "countryCode": "CA",
            "cityCode": "YCD",
            "cityName": "Nanaimo",
            "name": "[ZNA] - Nanaimo Harbour - YCD - Nanaimo - Canada",
            "searchName": "ZNA-YCD-Harbour-Nanaimo Harbour-Harbour Airport-Nanaimo-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCE",
            "countryCode": "CA",
            "cityCode": "YCE",
            "cityName": "Centralia",
            "name": "[YCE] - Centralia - YCE - Centralia - Canada",
            "searchName": "YCE-Centralia-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCF",
            "countryCode": "CA",
            "cityCode": "YCF",
            "cityName": "Cortes Bay",
            "name": "[YCF] - Cortes Bay - YCF - Cortes Bay - Canada",
            "searchName": "YCF-Cortes Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCG",
            "countryCode": "CA",
            "cityCode": "YCG",
            "cityName": "Castlegar",
            "name": "[YCG] - Castlegar - YCG - Castlegar - Canada",
            "searchName": "YCG-Castlegar-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCH",
            "countryCode": "CA",
            "cityCode": "YCH",
            "cityName": "Miramichi",
            "name": "[YCH] - Miramichi - YCH - Miramichi - Canada",
            "searchName": "YCH-Miramichi-Miramichi Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCI",
            "countryCode": "CA",
            "cityCode": "YCI",
            "cityName": "Caribou Island",
            "name": "[YCI] - Caribou Island - YCI - Caribou Island - Canada",
            "searchName": "YCI-Caribou Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCJ",
            "countryCode": "CA",
            "cityCode": "YCJ",
            "cityName": "Cape St James",
            "name": "[YCJ] - Cape St James - YCJ - Cape St James - Canada",
            "searchName": "YCJ-Cape St James-Cape St. James-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCL",
            "countryCode": "CA",
            "cityCode": "YCL",
            "cityName": "Charlo",
            "name": "[YCL] - Charlo - YCL - Charlo - Canada",
            "searchName": "YCL-Charlo-Charlo  Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCM",
            "countryCode": "CA",
            "cityCode": "YCM",
            "cityName": "St Catharines",
            "name": "[YCM] - St Catharines - YCM - St Catharines - Canada",
            "searchName": "YCM-St Catharines-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCN",
            "countryCode": "CA",
            "cityCode": "YCN",
            "cityName": "Cochrane",
            "name": "[YCN] - Cochrane - YCN - Cochrane - Canada",
            "searchName": "YCN-Cochrane-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCO",
            "countryCode": "CA",
            "cityCode": "YCO",
            "cityName": "Kugluktuk",
            "name": "[YCO] - Kugluktuk - YCO - Kugluktuk - Canada",
            "searchName": "YCO-Kugluktuk-Kugluktuk\\/Coppermine-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCP",
            "countryCode": "CA",
            "cityCode": "YCP",
            "cityName": "Co-op Point",
            "name": "[YCP] - Co-op Point - YCP - Co-op Point - Canada",
            "searchName": "YCP-op Point MB - Flughafen-Co-op Point-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCQ",
            "countryCode": "CA",
            "cityCode": "YCQ",
            "cityName": "Chetwynd",
            "name": "[YCQ] - Chetwynd - YCQ - Chetwynd - Canada",
            "searchName": "YCQ-Chetwynd-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCR",
            "countryCode": "CA",
            "cityCode": "YCR",
            "cityName": "Cross Lake",
            "name": "[YCR] - Cross Lake - YCR - Cross Lake - Canada",
            "searchName": "YCR-Cross Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCS",
            "countryCode": "CA",
            "cityCode": "YCS",
            "cityName": "Chesterfield Inlet",
            "name": "[YCS] - Chesterfield Inlet - YCS - Chesterfield Inlet - Canada",
            "searchName": "YCS-Chesterfield Inlet-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCT",
            "countryCode": "CA",
            "cityCode": "YCT",
            "cityName": "Coronation",
            "name": "[YCT] - Coronation - YCT - Coronation - Canada",
            "searchName": "YCT-Coronation-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCW",
            "countryCode": "CA",
            "cityCode": "YCW",
            "cityName": "Chilliwack",
            "name": "[YCW] - Chilliwack Municipalcipal Airport - YCW - Chilliwack - Canada",
            "searchName": "YCW-Chilliwack Municipalcipal Airport-Chilliwack Havalimani-Chilliwack-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCX",
            "countryCode": "CA",
            "cityCode": "YCX",
            "cityName": "Gagetown",
            "name": "[YCX] - Gagetown - YCX - Gagetown - Canada",
            "searchName": "YCX-Gagetown-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCY",
            "countryCode": "CA",
            "cityCode": "YCY",
            "cityName": "Clyde River",
            "name": "[YCY] - Clyde River - YCY - Clyde River - Canada",
            "searchName": "YCY-Clyde River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YCZ",
            "countryCode": "CA",
            "cityCode": "YCZ",
            "cityName": "Fairmont Hot Springs",
            "name": "[YCZ] - Fairmont Hot Springs - YCZ - Fairmont Hot Springs - Canada",
            "searchName": "YCZ-Fairmont Hot Springs-Fairmont Springs Havalimani-Fairmont Springs-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDA",
            "countryCode": "CA",
            "cityCode": "YDA",
            "cityName": "Dawson City",
            "name": "[YDA] - Dawson City - YDA - Dawson City - Canada",
            "searchName": "YDA-Dawson City-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDB",
            "countryCode": "CA",
            "cityCode": "YDB",
            "cityName": "Burwash Landings",
            "name": "[YDB] - Burwash Landings - YDB - Burwash Landings - Canada",
            "searchName": "YDB-Burwash Landings-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDC",
            "countryCode": "CA",
            "cityCode": "YDC",
            "cityName": "Drayton Valley",
            "name": "[YDC] - Industrial Airport - YDC - Drayton Valley - Canada",
            "searchName": "YDC-Industrial Airport-Drayton Valley-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDF",
            "countryCode": "CA",
            "cityCode": "YDF",
            "cityName": "Deer Lake",
            "name": "[YDF] - Deer Lake - YDF - Deer Lake - Canada",
            "searchName": "YDF-Deer Lake Neufundland-Deer Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDG",
            "countryCode": "CA",
            "cityCode": "YDG",
            "cityName": "Digby",
            "name": "[YDG] - Digby - YDG - Digby - Canada",
            "searchName": "YDG-Digby-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDH",
            "countryCode": "CA",
            "cityCode": "YDH",
            "cityName": "Daniels Harbour",
            "name": "[YDH] - Daniels Harbour - YDH - Daniels Harbour - Canada",
            "searchName": "YDH-Daniels Harbour-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDI",
            "countryCode": "CA",
            "cityCode": "YDI",
            "cityName": "Davis Inlet",
            "name": "[YDI] - Davis Inlet - YDI - Davis Inlet - Canada",
            "searchName": "YDI-Davis Inlet-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDJ",
            "countryCode": "CA",
            "cityCode": "YDJ",
            "cityName": "Hatchet Lake",
            "name": "[YDJ] - Hatchet Lake - YDJ - Hatchet Lake - Canada",
            "searchName": "YDJ-Hatchet Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDK",
            "countryCode": "CA",
            "cityCode": "YDK",
            "cityName": "Main Duck Island",
            "name": "[YDK] - Main Duck Island - YDK - Main Duck Island - Canada",
            "searchName": "YDK-Main Duck Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDL",
            "countryCode": "CA",
            "cityCode": "YDL",
            "cityName": "Dease Lake",
            "name": "[YDL] - Dease Lake - YDL - Dease Lake - Canada",
            "searchName": "YDL-Dease Lake-Dease Lake Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDN",
            "countryCode": "CA",
            "cityCode": "YDN",
            "cityName": "Dauphin",
            "name": "[YDN] - Dauphin - YDN - Dauphin - Canada",
            "searchName": "YDN-Dauphin-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDO",
            "countryCode": "CA",
            "cityCode": "YDO",
            "cityName": "Dolbeau",
            "name": "[YDO] - St Methode - YDO - Dolbeau - Canada",
            "searchName": "YDO-St Methode-Dolbeau-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDP",
            "countryCode": "CA",
            "cityCode": "YDP",
            "cityName": "Nain",
            "name": "[YDP] - Nain - YDP - Nain - Canada",
            "searchName": "YDP-Nain-Nain Newfoundland and Labrador-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDQ",
            "countryCode": "CA",
            "cityCode": "YDQ",
            "cityName": "Dawson Creek",
            "name": "[YDQ] - Dawson Creek - YDQ - Dawson Creek - Canada",
            "searchName": "YDQ-Dawson Creek-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDR",
            "countryCode": "CA",
            "cityCode": "YDR",
            "cityName": "Broadview",
            "name": "[YDR] - Broadview - YDR - Broadview - Canada",
            "searchName": "YDR-Broadview-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDS",
            "countryCode": "CA",
            "cityCode": "YDS",
            "cityName": "Desolation Sound",
            "name": "[YDS] - Desolation Sound - YDS - Desolation Sound - Canada",
            "searchName": "YDS-Desolation Sound-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDU",
            "countryCode": "CA",
            "cityCode": "YDU",
            "cityName": "Kasba Lake",
            "name": "[YDU] - Kasba Lake - YDU - Kasba Lake - Canada",
            "searchName": "YDU-Kasba Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDV",
            "countryCode": "CA",
            "cityCode": "YDV",
            "cityName": "Bloodvein",
            "name": "[YDV] - Bloodvein - YDV - Bloodvein - Canada",
            "searchName": "YDV-Bloodvein-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDW",
            "countryCode": "CA",
            "cityCode": "YDW",
            "cityName": "Obre Lake",
            "name": "[YDW] - Obre Lake - YDW - Obre Lake - Canada",
            "searchName": "YDW-Obre Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDX",
            "countryCode": "CA",
            "cityCode": "YDX",
            "cityName": "Doc Creek",
            "name": "[YDX] - Doc Creek - YDX - Doc Creek - Canada",
            "searchName": "YDX-Doc Creek-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XZL",
            "countryCode": "CA",
            "cityCode": "YEA",
            "cityName": "Edmonton",
            "name": "[XZL] - Edmonton Railway Station - YEA - Edmonton - Canada",
            "searchName": "XZL-YEA-Edmonton Railway Station-Edmonton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YED",
            "countryCode": "CA",
            "cityCode": "YEA",
            "cityName": "Edmonton",
            "name": "[YED] - Namao Field - YEA - Edmonton - Canada",
            "searchName": "YED-YEA-Namao Field-Namao Field Havalimani-Edmonton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YEG",
            "countryCode": "CA",
            "cityCode": "YEA",
            "cityName": "Edmonton",
            "name": "[YEG] - Edmonton International Airport - YEA - Edmonton - Canada",
            "searchName": "YEG-YEA-Edmonton Intl-Edmonton International Airport-Edmonton-International-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YEB",
            "countryCode": "CA",
            "cityCode": "YEB",
            "cityName": "Bar River",
            "name": "[YEB] - Bar River - YEB - Bar River - Canada",
            "searchName": "YEB-Bar River-Bar River Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAJ",
            "countryCode": "CA",
            "cityCode": "YAJ",
            "cityName": "Lyall Harbour",
            "name": "[YAJ] - Lyall Harbour - YAJ - Lyall Harbour - Canada",
            "searchName": "YAJ-Lyall Harbour-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAL",
            "countryCode": "CA",
            "cityCode": "YAL",
            "cityName": "Alert Bay",
            "name": "[YAL] - Alert Bay - YAL - Alert Bay - Canada",
            "searchName": "YAL-Kommunaler Flughafen-Alert Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAM",
            "countryCode": "CA",
            "cityCode": "YAM",
            "cityName": "Sault Sainte Marie",
            "name": "[YAM] - Sault Sainte Marie - YAM - Sault Sainte Marie - Canada",
            "searchName": "YAM-Sault Sainte Marie Kanada-Sault Sainte Marie-Sault Ste Marie-Sault Ste-Marie-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "UZU",
            "countryCode": "AR",
            "cityCode": "UZU",
            "cityName": "Curuzu Cuatia",
            "name": "[UZU] - Curuzu Cuatia - UZU - Curuzu Cuatia - Argentina",
            "searchName": "UZU-Curuzu Cuatia-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "PEH",
            "countryCode": "AR",
            "cityCode": "PEH",
            "cityName": "Pehuajo",
            "name": "[PEH] - Pehuajo - PEH - Pehuajo - Argentina",
            "searchName": "PEH-Pehuajo-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "PMQ",
            "countryCode": "AR",
            "cityCode": "PMQ",
            "cityName": "Perito Moreno",
            "name": "[PMQ] - Perito Moreno - PMQ - Perito Moreno - Argentina",
            "searchName": "PMQ-Perito Moreno-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "PMY",
            "countryCode": "AR",
            "cityCode": "PMY",
            "cityName": "Puerto Madryn",
            "name": "[PMY] - El Tehuelche - PMY - Puerto Madryn - Argentina",
            "searchName": "PMY-El Tehuelche-Puerto Madryn-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "PRA",
            "countryCode": "AR",
            "cityCode": "PRA",
            "cityName": "Parana",
            "name": "[PRA] - Parana - PRA - Parana - Argentina",
            "searchName": "PRA-Parana-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "OVR",
            "countryCode": "AR",
            "cityCode": "OVR",
            "cityName": "Olavarria",
            "name": "[OVR] - Olavarria - OVR - Olavarria - Argentina",
            "searchName": "OVR-Olavarria-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "OYA",
            "countryCode": "AR",
            "cityCode": "OYA",
            "cityName": "Goya",
            "name": "[OYA] - Goya - OYA - Goya - Argentina",
            "searchName": "OYA-Goya-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "OYO",
            "countryCode": "AR",
            "cityCode": "OYO",
            "cityName": "Tres Arroyos",
            "name": "[OYO] - Tres Arroyos - OYO - Tres Arroyos - Argentina",
            "searchName": "OYO-Tres Arroyos-Tres Arroyos Havalimani-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "OLN",
            "countryCode": "AR",
            "cityCode": "OLN",
            "cityName": "Colonia Sarmiento",
            "name": "[OLN] - Colonia Sarmiento - OLN - Colonia Sarmiento - Argentina",
            "searchName": "OLN-Colonia Sarmiento-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "ORA",
            "countryCode": "AR",
            "cityCode": "ORA",
            "cityName": "Oran",
            "name": "[ORA] - Oran - ORA - Oran - Argentina",
            "searchName": "ORA-Oran-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RLO",
            "countryCode": "AR",
            "cityCode": "RLO",
            "cityName": "Merlo",
            "name": "[RLO] - Valle Del Conlara - RLO - Merlo - Argentina",
            "searchName": "RLO-Valle Del Conlara-Merlo-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RDS",
            "countryCode": "AR",
            "cityCode": "RDS",
            "cityName": "Rincon de los Sauces",
            "name": "[RDS] - Rincon de los Sauces - RDS - Rincon de los Sauces - Argentina",
            "searchName": "RDS-Rincon de los Sauces-Rincon De Los Sauces-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "REL",
            "countryCode": "AR",
            "cityCode": "REL",
            "cityName": "Trelew",
            "name": "[REL] - Almirante Zar - REL - Trelew - Argentina",
            "searchName": "REL-Trelew-Almirante Zar-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RES",
            "countryCode": "AR",
            "cityCode": "RES",
            "cityName": "Resistencia",
            "name": "[RES] - Resistencia - RES - Resistencia - Argentina",
            "searchName": "RES-Resistencia-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RGA",
            "countryCode": "AR",
            "cityCode": "RGA",
            "cityName": "Rio Grande",
            "name": "[RGA] - Rio Grande - RGA - Rio Grande - Argentina",
            "searchName": "RGA-Rio Grande Argentinien-Rio Grande-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RGL",
            "countryCode": "AR",
            "cityCode": "RGL",
            "cityName": "Rio Gallegos",
            "name": "[RGL] - Internacional - RGL - Rio Gallegos - Argentina",
            "searchName": "RGL-Rio Gallegos-Internacional- Intl-International-R-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RHD",
            "countryCode": "AR",
            "cityCode": "RHD",
            "cityName": "Rio Hondo",
            "name": "[RHD] - Rio Hondo - RHD - Rio Hondo - Argentina",
            "searchName": "RHD-Rio Hondo-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "ROS",
            "countryCode": "AR",
            "cityCode": "ROS",
            "cityName": "Rosario",
            "name": "[ROS] - Fisherton - ROS - Rosario - Argentina",
            "searchName": "ROS-Rosario Argentinien-Fisherton-Rosario-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "ROY",
            "countryCode": "AR",
            "cityCode": "ROY",
            "cityName": "Rio Mayo",
            "name": "[ROY] - Rio Mayo - ROY - Rio Mayo - Argentina",
            "searchName": "ROY-Rio Mayo-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RSA",
            "countryCode": "AR",
            "cityCode": "RSA",
            "cityName": "Santa Rosa",
            "name": "[RSA] - Santa Rosa - RSA - Santa Rosa - Argentina",
            "searchName": "RSA-Santa Rosa-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RYO",
            "countryCode": "AR",
            "cityCode": "RYO",
            "cityName": "Rio Turbio",
            "name": "[RYO] - Rio Turbio - RYO - Rio Turbio - Argentina",
            "searchName": "RYO-Rio Turbio-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RZA",
            "countryCode": "AR",
            "cityCode": "RZA",
            "cityName": "Santa Cruz",
            "name": "[RZA] - Santa Cruz - RZA - Santa Cruz - Argentina",
            "searchName": "RZA-Santa Cruz-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "PRQ",
            "countryCode": "AR",
            "cityCode": "PRQ",
            "cityName": "Pres. Roque Saenz Pena",
            "name": "[PRQ] - Pres. Roque Saenz Pena - PRQ - Pres. Roque Saenz Pena - Argentina",
            "searchName": "PRQ-Pres. Roque Saenz Pena-President Roque Saenz Pena-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "PSS",
            "countryCode": "AR",
            "cityCode": "PSS",
            "cityName": "Posadas",
            "name": "[PSS] - Posadas - PSS - Posadas - Argentina",
            "searchName": "PSS-Posadas-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "PUD",
            "countryCode": "AR",
            "cityCode": "PUD",
            "cityName": "Puerto Deseado",
            "name": "[PUD] - Puerto Deseado - PUD - Puerto Deseado - Argentina",
            "searchName": "PUD-Puerto Deseado-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RAF",
            "countryCode": "AR",
            "cityCode": "RAF",
            "cityName": "Rafaela",
            "name": "[RAF] - Rafaela - RAF - Rafaela - Argentina",
            "searchName": "RAF-Rafaela-Ras An Naqb-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RCQ",
            "countryCode": "AR",
            "cityCode": "RCQ",
            "cityName": "Reconquista",
            "name": "[RCQ] - Reconquista - RCQ - Reconquista - Argentina",
            "searchName": "RCQ-Reconquista-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "RCU",
            "countryCode": "AR",
            "cityCode": "RCU",
            "cityName": "Rio Cuarto",
            "name": "[RCU] - Rio Cuarto - RCU - Rio Cuarto - Argentina",
            "searchName": "RCU-Kommunaler Flughafen-Rio Cuarto-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "MDX",
            "countryCode": "AR",
            "cityCode": "MDX",
            "cityName": "Mercedes",
            "name": "[MDX] - Mercedes - MDX - Mercedes - Argentina",
            "searchName": "MDX-Mercedes-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "MCS",
            "countryCode": "AR",
            "cityCode": "MCS",
            "cityName": "Monte Caseros",
            "name": "[MCS] - Monte Caseros - MCS - Monte Caseros - Argentina",
            "searchName": "MCS-Monte Caseros-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "MDQ",
            "countryCode": "AR",
            "cityCode": "MDQ",
            "cityName": "Mar Del Plata",
            "name": "[MDQ] - Mar Del Plata - MDQ - Mar Del Plata - Argentina",
            "searchName": "MDQ-Mar Del Plata-Mar del Plata-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "MJR",
            "countryCode": "AR",
            "cityCode": "MJR",
            "cityName": "Miramar",
            "name": "[MJR] - Miramar - MJR - Miramar - Argentina",
            "searchName": "MJR-Miramar-Miramar Havalimani-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "MQD",
            "countryCode": "AR",
            "cityCode": "MQD",
            "cityName": "Maquinchao",
            "name": "[MQD] - Maquinchao - MQD - Maquinchao - Argentina",
            "searchName": "MQD-Maquinchao-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "NCJ",
            "countryCode": "AR",
            "cityCode": "NCJ",
            "cityName": "Sunchales",
            "name": "[NCJ] - Sunchales - NCJ - Sunchales - Argentina",
            "searchName": "NCJ-Sunchales-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "NEC",
            "countryCode": "AR",
            "cityCode": "NEC",
            "cityName": "Necochea",
            "name": "[NEC] - Necochea - NEC - Necochea - Argentina",
            "searchName": "NEC-Necochea-Beaulieu-sur-Mer - Cap Ferrat-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "NQN",
            "countryCode": "AR",
            "cityCode": "NQN",
            "cityName": "Neuquen",
            "name": "[NQN] - Neuquen - NQN - Neuquen - Argentina",
            "searchName": "NQN-Neuquen-Neuqu-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "OES",
            "countryCode": "AR",
            "cityCode": "OES",
            "cityName": "San Antonio Oeste",
            "name": "[OES] - San Antonio Oeste - OES - San Antonio Oeste - Argentina",
            "searchName": "OES-San Antonio Oeste-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "GGS",
            "countryCode": "AR",
            "cityCode": "GGS",
            "cityName": "Gobernador Gregores",
            "name": "[GGS] - Gobernador Gregores - GGS - Gobernador Gregores - Argentina",
            "searchName": "GGS-Gobernador Gregores-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "GHU",
            "countryCode": "AR",
            "cityCode": "GHU",
            "cityName": "Gualeguaychu",
            "name": "[GHU] - Gualeguaychu - GHU - Gualeguaychu - Argentina",
            "searchName": "GHU-Gualeguaychu-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "GNR",
            "countryCode": "AR",
            "cityCode": "GNR",
            "cityName": "General Roca",
            "name": "[GNR] - General Roca - GNR - General Roca - Argentina",
            "searchName": "GNR-General Roca-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "GPO",
            "countryCode": "AR",
            "cityCode": "GPO",
            "cityName": "General Pico",
            "name": "[GPO] - General Pico - GPO - General Pico - Argentina",
            "searchName": "GPO-General Pico-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "FMA",
            "countryCode": "AR",
            "cityCode": "FMA",
            "cityName": "Formosa",
            "name": "[FMA] - El Pucu - FMA - Formosa - Argentina",
            "searchName": "FMA-Formosa-El Pucu-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "FTE",
            "countryCode": "AR",
            "cityCode": "FTE",
            "cityName": "El Calafate",
            "name": "[FTE] - El Calafate - FTE - El Calafate - Argentina",
            "searchName": "FTE-El Calafate-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "IRJ",
            "countryCode": "AR",
            "cityCode": "IRJ",
            "cityName": "La Rioja",
            "name": "[IRJ] - Capitan Vicente Almando - IRJ - La Rioja - Argentina",
            "searchName": "IRJ-La Rioja-Capitan Vicente Almando-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "IGB",
            "countryCode": "AR",
            "cityCode": "IGB",
            "cityName": "Ingeniero Jacobacci",
            "name": "[IGB] - Ingeniero Jacobacci - IGB - Ingeniero Jacobacci - Argentina",
            "searchName": "IGB-Ingeniero Jacobacci-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "IGR",
            "countryCode": "AR",
            "cityCode": "IGR",
            "cityName": "Iguazu",
            "name": "[IGR] - Cataratas - IGR - Iguazu - Argentina",
            "searchName": "IGR-Iguazu-Cataratas-Igua-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "ING",
            "countryCode": "AR",
            "cityCode": "ING",
            "cityName": "Lago Argentino",
            "name": "[ING] - Lago Argentino - ING - Lago Argentino - Argentina",
            "searchName": "ING-Lago Argentino-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "HOS",
            "countryCode": "AR",
            "cityCode": "HOS",
            "cityName": "Chos Malal",
            "name": "[HOS] - Oscar Reguera - HOS - Chos Malal - Argentina",
            "searchName": "HOS-Oscar Reguera-Chos Malal-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "JNI",
            "countryCode": "AR",
            "cityCode": "JNI",
            "cityName": "Junin",
            "name": "[JNI] - Junin - JNI - Junin - Argentina",
            "searchName": "JNI-Junin-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "JSM",
            "countryCode": "AR",
            "cityCode": "JSM",
            "cityName": "Jose De San Martin",
            "name": "[JSM] - Jose De San Martin - JSM - Jose De San Martin - Argentina",
            "searchName": "JSM-Jose De San Martin-Jose de San Martin-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "JUJ",
            "countryCode": "AR",
            "cityCode": "JUJ",
            "cityName": "Jujuy",
            "name": "[JUJ] - El Cadillal - JUJ - Jujuy - Argentina",
            "searchName": "JUJ-Jujuy-El Cadillal-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "LCM",
            "countryCode": "AR",
            "cityCode": "LCM",
            "cityName": "La Cumbre",
            "name": "[LCM] - La Cumbre - LCM - La Cumbre - Argentina",
            "searchName": "LCM-La Cumbre-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "LCP",
            "countryCode": "AR",
            "cityCode": "LCP",
            "cityName": "Loncopue",
            "name": "[LCP] - Loncopue - LCP - Loncopue - Argentina",
            "searchName": "LCP-Loncopue-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "LGS",
            "countryCode": "AR",
            "cityCode": "LGS",
            "cityName": "Malargue",
            "name": "[LGS] - Malargue - LGS - Malargue - Argentina",
            "searchName": "LGS-Malargue-Malarg-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "LHS",
            "countryCode": "AR",
            "cityCode": "LHS",
            "cityName": "Las Heras",
            "name": "[LHS] - Las Heras - LHS - Las Heras - Argentina",
            "searchName": "LHS-Las Heras-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "LLS",
            "countryCode": "AR",
            "cityCode": "LLS",
            "cityName": "Las Lomitas",
            "name": "[LLS] - Las Lomitas - LLS - Las Lomitas - Argentina",
            "searchName": "LLS-Las Lomitas-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "LMD",
            "countryCode": "AR",
            "cityCode": "LMD",
            "cityName": "Los Menucos",
            "name": "[LMD] - Los Menucos - LMD - Los Menucos - Argentina",
            "searchName": "LMD-Los Menucos-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "LPG",
            "countryCode": "AR",
            "cityCode": "LPG",
            "cityName": "La Plata",
            "name": "[LPG] - La Plata - LPG - La Plata - Argentina",
            "searchName": "LPG-La Plata-La Plata Havalimani-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "LUQ",
            "countryCode": "AR",
            "cityCode": "LUQ",
            "cityName": "San Luis",
            "name": "[LUQ] - San Luis - LUQ - San Luis - Argentina",
            "searchName": "LUQ-San Luis-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CSZ",
            "countryCode": "AR",
            "cityCode": "CSZ",
            "cityName": "Coronel Suarez",
            "name": "[CSZ] - Brigadier Hector Ruiz - CSZ - Coronel Suarez - Argentina",
            "searchName": "CSZ-Brigadier Hector Ruiz-Coronel Suarez-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CTC",
            "countryCode": "AR",
            "cityCode": "CTC",
            "cityName": "Catamarca",
            "name": "[CTC] - Catamarca - CTC - Catamarca - Argentina",
            "searchName": "CTC-Catamarca-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CUT",
            "countryCode": "AR",
            "cityCode": "CUT",
            "cityName": "Cutral",
            "name": "[CUT] - Cutral - CUT - Cutral - Argentina",
            "searchName": "CUT-Cutral-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CVH",
            "countryCode": "AR",
            "cityCode": "CVH",
            "cityName": "Caviahue",
            "name": "[CVH] - Caviahue - CVH - Caviahue - Argentina",
            "searchName": "CVH-Caviahue-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CVI",
            "countryCode": "AR",
            "cityCode": "CVI",
            "cityName": "Caleta Olivia",
            "name": "[CVI] - Caleta Olivia - CVI - Caleta Olivia - Argentina",
            "searchName": "CVI-Caleta Olivia-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "COC",
            "countryCode": "AR",
            "cityCode": "COC",
            "cityName": "Concordia",
            "name": "[COC] - Concordia - COC - Concordia - Argentina",
            "searchName": "COC-Concordia-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CNT",
            "countryCode": "AR",
            "cityCode": "CNT",
            "cityName": "Charata",
            "name": "[CNT] - Charata - CNT - Charata - Argentina",
            "searchName": "CNT-Charata-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "AZZ",
            "countryCode": "AO",
            "cityCode": "AZZ",
            "cityName": "Ambriz",
            "name": "[AZZ] - Ambriz - AZZ - Ambriz - Angola",
            "searchName": "AZZ-Ambriz-Angola",
            "priority": 0
        },
        {
            "iataCode": "ANL",
            "countryCode": "AO",
            "cityCode": "ANL",
            "cityName": "Andulo",
            "name": "[ANL] - Andulo - ANL - Andulo - Angola",
            "searchName": "ANL-Andulo-Angola",
            "priority": 0
        },
        {
            "iataCode": "ARZ",
            "countryCode": "AO",
            "cityCode": "ARZ",
            "cityName": "Nzeto",
            "name": "[ARZ] - Nzeto - ARZ - Nzeto - Angola",
            "searchName": "ARZ-N zeto-Nzeto-Angola",
            "priority": 0
        },
        {
            "iataCode": "BUG",
            "countryCode": "AO",
            "cityCode": "BUG",
            "cityName": "Benguela",
            "name": "[BUG] - Gen V Deslandes - BUG - Benguela - Angola",
            "searchName": "BUG-Gen V Deslandes-Benguela-Angola",
            "priority": 0
        },
        {
            "iataCode": "DUE",
            "countryCode": "AO",
            "cityCode": "DUE",
            "cityName": "Dundo",
            "name": "[DUE] - Dundo - DUE - Dundo - Angola",
            "searchName": "DUE-Dundo-Angola",
            "priority": 0
        },
        {
            "iataCode": "DRC",
            "countryCode": "AO",
            "cityCode": "DRC",
            "cityName": "Dirico",
            "name": "[DRC] - Dirico - DRC - Dirico - Angola",
            "searchName": "DRC-Dirico-Angola",
            "priority": 0
        },
        {
            "iataCode": "CEO",
            "countryCode": "AO",
            "cityCode": "CEO",
            "cityName": "Waco Kungo",
            "name": "[CEO] - Waco Kungo - CEO - Waco Kungo - Angola",
            "searchName": "CEO-Waco Kungo-Angola",
            "priority": 0
        },
        {
            "iataCode": "CFF",
            "countryCode": "AO",
            "cityCode": "CFF",
            "cityName": "Cafunfo",
            "name": "[CFF] - Cafunfo - CFF - Cafunfo - Angola",
            "searchName": "CFF-Cafunfo-Angola",
            "priority": 0
        },
        {
            "iataCode": "CAB",
            "countryCode": "AO",
            "cityCode": "CAB",
            "cityName": "Cabinda",
            "name": "[CAB] - Cabinda - CAB - Cabinda - Angola",
            "searchName": "CAB-Cabinda-Angola",
            "priority": 0
        },
        {
            "iataCode": "CAV",
            "countryCode": "AO",
            "cityCode": "CAV",
            "cityName": "Cazombo",
            "name": "[CAV] - Cazombo - CAV - Cazombo - Angola",
            "searchName": "CAV-Cazombo-Angola",
            "priority": 0
        },
        {
            "iataCode": "CBT",
            "countryCode": "AO",
            "cityCode": "CBT",
            "cityName": "Catumbela",
            "name": "[CBT] - Catumbela - CBT - Catumbela - Angola",
            "searchName": "CBT-Catumbela-Angola",
            "priority": 0
        },
        {
            "iataCode": "CNZ",
            "countryCode": "AO",
            "cityCode": "CNZ",
            "cityName": "Cangamba",
            "name": "[CNZ] - Cangamba - CNZ - Cangamba - Angola",
            "searchName": "CNZ-Cangamba-Angola",
            "priority": 0
        },
        {
            "iataCode": "CTI",
            "countryCode": "AO",
            "cityCode": "CTI",
            "cityName": "Cuito Cuanavale",
            "name": "[CTI] - Cuito Cuanavale - CTI - Cuito Cuanavale - Angola",
            "searchName": "CTI-Cuito Cuanavale-Angola",
            "priority": 0
        },
        {
            "iataCode": "LZM",
            "countryCode": "AO",
            "cityCode": "LZM",
            "cityName": "Luzamba",
            "name": "[LZM] - Luzamba - LZM - Luzamba - Angola",
            "searchName": "LZM-Luzamba-Angola",
            "priority": 0
        },
        {
            "iataCode": "LUO",
            "countryCode": "AO",
            "cityCode": "LUO",
            "cityName": "Luena",
            "name": "[LUO] - Luena - LUO - Luena - Angola",
            "searchName": "LUO-Luena-Angola",
            "priority": 0
        },
        {
            "iataCode": "LLT",
            "countryCode": "AO",
            "cityCode": "LLT",
            "cityName": "Lobito",
            "name": "[LLT] - Lobito - LLT - Lobito - Angola",
            "searchName": "LLT-Lobito-Angola",
            "priority": 0
        },
        {
            "iataCode": "LAD",
            "countryCode": "AO",
            "cityCode": "LAD",
            "cityName": "Luanda",
            "name": "[LAD] - 4 de Fevereiro - LAD - Luanda - Angola",
            "searchName": "LAD-Luanda-4 de Fevereiro-Angola",
            "priority": 0
        },
        {
            "iataCode": "LBZ",
            "countryCode": "AO",
            "cityCode": "LBZ",
            "cityName": "Lukapa",
            "name": "[LBZ] - Lukapa - LBZ - Lukapa - Angola",
            "searchName": "LBZ-Lukapa-Angola",
            "priority": 0
        },
        {
            "iataCode": "KNP",
            "countryCode": "AO",
            "cityCode": "KNP",
            "cityName": "Capanda",
            "name": "[KNP] - Capanda - KNP - Capanda - Angola",
            "searchName": "KNP-Capanda-Angola",
            "priority": 0
        },
        {
            "iataCode": "JMB",
            "countryCode": "AO",
            "cityCode": "JMB",
            "cityName": "Jamba",
            "name": "[JMB] - Jamba - JMB - Jamba - Angola",
            "searchName": "JMB-Jamba-Angola",
            "priority": 0
        },
        {
            "iataCode": "GXG",
            "countryCode": "AO",
            "cityCode": "GXG",
            "cityName": "Negage",
            "name": "[GXG] - Negage - GXG - Negage - Angola",
            "searchName": "GXG-Negage-Angola",
            "priority": 0
        },
        {
            "iataCode": "GGC",
            "countryCode": "AO",
            "cityCode": "GGC",
            "cityName": "Lumbala",
            "name": "[GGC] - Lumbala - GGC - Lumbala - Angola",
            "searchName": "GGC-Lumbala-Angola",
            "priority": 0
        },
        {
            "iataCode": "SZA",
            "countryCode": "AO",
            "cityCode": "SZA",
            "cityName": "Soyo",
            "name": "[SZA] - Soyo - SZA - Soyo - Angola",
            "searchName": "SZA-Soyo-Angola",
            "priority": 0
        },
        {
            "iataCode": "SDD",
            "countryCode": "AO",
            "cityCode": "SDD",
            "cityName": "Lubango",
            "name": "[SDD] - Lubango - SDD - Lubango - Angola",
            "searchName": "SDD-Lubango-Lubango Airport-Angola",
            "priority": 0
        },
        {
            "iataCode": "SPP",
            "countryCode": "AO",
            "cityCode": "SPP",
            "cityName": "Menongue",
            "name": "[SPP] - Menongue - SPP - Menongue - Angola",
            "searchName": "SPP-Menongue-Angola",
            "priority": 0
        },
        {
            "iataCode": "SSY",
            "countryCode": "AO",
            "cityCode": "SSY",
            "cityName": "M banza Congo",
            "name": "[SSY] - Mbanza Congo - SSY - M banza Congo - Angola",
            "searchName": "SSY-M banza Congo-M Banza Congo-Mbanza Congo-Angola",
            "priority": 0
        },
        {
            "iataCode": "SVP",
            "countryCode": "AO",
            "cityCode": "SVP",
            "cityName": "Kuito",
            "name": "[SVP] - Kuito (Silva Porto) - SVP - Kuito - Angola",
            "searchName": "SVP-Kuito-Kuito (Silva Porto)-Angola",
            "priority": 0
        },
        {
            "iataCode": "VHC",
            "countryCode": "AO",
            "cityCode": "VHC",
            "cityName": "Saurimo",
            "name": "[VHC] - Saurimo - VHC - Saurimo - Angola",
            "searchName": "VHC-Saurimo-Angola",
            "priority": 0
        },
        {
            "iataCode": "UAL",
            "countryCode": "AO",
            "cityCode": "UAL",
            "cityName": "Luau",
            "name": "[UAL] - Luau - UAL - Luau - Angola",
            "searchName": "UAL-Luau-Angola",
            "priority": 0
        },
        {
            "iataCode": "UGO",
            "countryCode": "AO",
            "cityCode": "UGO",
            "cityName": "Uige",
            "name": "[UGO] - Uige - UGO - Uige - Angola",
            "searchName": "UGO-Uige-Angola",
            "priority": 0
        },
        {
            "iataCode": "VPE",
            "countryCode": "AO",
            "cityCode": "VPE",
            "cityName": "Ondjiva",
            "name": "[VPE] - Ondjiva - VPE - Ondjiva - Angola",
            "searchName": "VPE-Ondjiva-Ongiva-Angola",
            "priority": 0
        },
        {
            "iataCode": "XGN",
            "countryCode": "AO",
            "cityCode": "XGN",
            "cityName": "Xangongo",
            "name": "[XGN] - Xangongo - XGN - Xangongo - Angola",
            "searchName": "XGN-Xangongo-Angola",
            "priority": 0
        },
        {
            "iataCode": "NZA",
            "countryCode": "AO",
            "cityCode": "NZA",
            "cityName": "Nzagi",
            "name": "[NZA] - Nzagi - NZA - Nzagi - Angola",
            "searchName": "NZA-Nzagi-Angola",
            "priority": 0
        },
        {
            "iataCode": "NOV",
            "countryCode": "AO",
            "cityCode": "NOV",
            "cityName": "Huambo",
            "name": "[NOV] - Huambo - NOV - Huambo - Angola",
            "searchName": "NOV-Huambo-Angola",
            "priority": 0
        },
        {
            "iataCode": "NDF",
            "countryCode": "AO",
            "cityCode": "NDF",
            "cityName": "Ndalatandos",
            "name": "[NDF] - Ndalatandos - NDF - Ndalatandos - Angola",
            "searchName": "NDF-Ndalatandos-Angola",
            "priority": 0
        },
        {
            "iataCode": "NDD",
            "countryCode": "AO",
            "cityCode": "NDD",
            "cityName": "Sumbe",
            "name": "[NDD] - Sumbe - NDD - Sumbe - Angola",
            "searchName": "NDD-Sumbe-Angola",
            "priority": 0
        },
        {
            "iataCode": "MSZ",
            "countryCode": "AO",
            "cityCode": "MSZ",
            "cityName": "Namibe",
            "name": "[MSZ] - Namibe - MSZ - Namibe - Angola",
            "searchName": "MSZ-Namibe-Angola",
            "priority": 0
        },
        {
            "iataCode": "MEG",
            "countryCode": "AO",
            "cityCode": "MEG",
            "cityName": "Melanje",
            "name": "[MEG] - Malange - MEG - Melanje - Angola",
            "searchName": "MEG-Malange-Melanje-Angola",
            "priority": 0
        },
        {
            "iataCode": "PBN",
            "countryCode": "AO",
            "cityCode": "PBN",
            "cityName": "Porto Amboim",
            "name": "[PBN] - Porto Amboim - PBN - Porto Amboim - Angola",
            "searchName": "PBN-Porto Amboim-Angola",
            "priority": 0
        },
        {
            "iataCode": "PGI",
            "countryCode": "AO",
            "cityCode": "PGI",
            "cityName": "Chitato",
            "name": "[PGI] - Chitato - PGI - Chitato - Angola",
            "searchName": "PGI-Chitato-Angola",
            "priority": 0
        },
        {
            "iataCode": "TNM",
            "countryCode": "AQ",
            "cityCode": "TNM",
            "cityName": "Teniente R. Marsh Martin",
            "name": "[TNM] - Teniente R. Marsh Martin - TNM - Teniente R. Marsh Martin - Antarctica",
            "searchName": "TNM-Teniente R. Marsh Martin-Antarctica-Antarctique-Antártida-Antartide",
            "priority": 0
        },
        {
            "iataCode": "TTG",
            "countryCode": "AR",
            "cityCode": "TTG",
            "cityName": "Tartagal",
            "name": "[TTG] - Tartagal - TTG - Tartagal - Argentina",
            "searchName": "TTG-Tartagal-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "TUC",
            "countryCode": "AR",
            "cityCode": "TUC",
            "cityName": "Tucuman",
            "name": "[TUC] - Benj Matienzo - TUC - Tucuman - Argentina",
            "searchName": "TUC-Tucuman-Benj Matienzo-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "TDL",
            "countryCode": "AR",
            "cityCode": "TDL",
            "cityName": "Tandil",
            "name": "[TDL] - Tandil - TDL - Tandil - Argentina",
            "searchName": "TDL-Tandil-Tandil Havalimani-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "SST",
            "countryCode": "AR",
            "cityCode": "SST",
            "cityName": "Santa Teresita",
            "name": "[SST] - Santa Teresita - SST - Santa Teresita - Argentina",
            "searchName": "SST-Santa Teresita-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "SDE",
            "countryCode": "AR",
            "cityCode": "SDE",
            "cityName": "Santiago Del Estero",
            "name": "[SDE] - Santiago Del Estero - SDE - Santiago Del Estero - Argentina",
            "searchName": "SDE-Santiago Del Estero-Santiago Des Estero Havalimani-Santiago del Estero-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "SFN",
            "countryCode": "AR",
            "cityCode": "SFN",
            "cityName": "Santa Fe",
            "name": "[SFN] - Santa Fe - SFN - Santa Fe - Argentina",
            "searchName": "SFN-Santa Fe Argentinien-Santa Fe-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "SGV",
            "countryCode": "AR",
            "cityCode": "SGV",
            "cityName": "Sierra Grande",
            "name": "[SGV] - Sierra Grande - SGV - Sierra Grande - Argentina",
            "searchName": "SGV-Sierra Grande-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "SLA",
            "countryCode": "AR",
            "cityCode": "SLA",
            "cityName": "Salta",
            "name": "[SLA] - Gen Belgrano - SLA - Salta - Argentina",
            "searchName": "SLA-Salta-Gen Belgrano-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "VLG",
            "countryCode": "AR",
            "cityCode": "VLG",
            "cityName": "Villa Gesell",
            "name": "[VLG] - Villa Gesell - VLG - Villa Gesell - Argentina",
            "searchName": "VLG-Villa Gesell-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "VME",
            "countryCode": "AR",
            "cityCode": "VME",
            "cityName": "Villa Mercedes",
            "name": "[VME] - Villa Mercedes - VME - Villa Mercedes - Argentina",
            "searchName": "VME-Villa Mercedes-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "UAQ",
            "countryCode": "AR",
            "cityCode": "UAQ",
            "cityName": "San Juan",
            "name": "[UAQ] - San Juan - UAQ - San Juan - Argentina",
            "searchName": "UAQ-San Juan Argentinien-San Juan-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "ULA",
            "countryCode": "AR",
            "cityCode": "ULA",
            "cityName": "San Julian",
            "name": "[ULA] - San Julian - ULA - San Julian - Argentina",
            "searchName": "ULA-San Julian-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "VGS",
            "countryCode": "AR",
            "cityCode": "VGS",
            "cityName": "General Villegas",
            "name": "[VGS] - General Villegas - VGS - General Villegas - Argentina",
            "searchName": "VGS-General Villegas-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "VCF",
            "countryCode": "AR",
            "cityCode": "VCF",
            "cityName": "Valcheta",
            "name": "[VCF] - Valcheta - VCF - Valcheta - Argentina",
            "searchName": "VCF-Valcheta-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "VDM",
            "countryCode": "AR",
            "cityCode": "VDM",
            "cityName": "Viedma",
            "name": "[VDM] - Viedma - VDM - Viedma - Argentina",
            "searchName": "VDM-Viedma-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "VDR",
            "countryCode": "AR",
            "cityCode": "VDR",
            "cityName": "Villa Dolores",
            "name": "[VDR] - Villa Dolores - VDR - Villa Dolores - Argentina",
            "searchName": "VDR-Villa Dolores-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "FJR",
            "countryCode": "AE",
            "cityCode": "FJR",
            "cityName": "Al-fujairah",
            "name": "[FJR] - Al-Fujairah International - FJR - Al-fujairah - United Arab Emirates",
            "searchName": "FJR-Al-Fujairah International-Fujairah Intl Arpt-fujairah-Fujairah-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 0
        },
        {
            "iataCode": "RKT",
            "countryCode": "AE",
            "cityCode": "RKT",
            "cityName": "Ras Al Khaimah",
            "name": "[RKT] - Ras Al Khaimah - RKT - Ras Al Khaimah - United Arab Emirates",
            "searchName": "RKT-Ras Al Khaimah-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 0
        },
        {
            "iataCode": "SHJ",
            "countryCode": "AE",
            "cityCode": "SHJ",
            "cityName": "Sharjah",
            "name": "[SHJ] - Sharjah - SHJ - Sharjah - United Arab Emirates",
            "searchName": "SHJ-Sharjah-Schardscha-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 0
        },
        {
            "iataCode": "SBF",
            "countryCode": "AF",
            "cityCode": "SBF",
            "cityName": "Sardeh Band",
            "name": "[SBF] - Sardeh Band - SBF - Sardeh Band - Afghanistan",
            "searchName": "SBF-Sardeh Band-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "SGA",
            "countryCode": "AF",
            "cityCode": "SGA",
            "cityName": "Sheghnan",
            "name": "[SGA] - Sheghnan - SGA - Sheghnan - Afghanistan",
            "searchName": "SGA-Sheghnan-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "TII",
            "countryCode": "AF",
            "cityCode": "TII",
            "cityName": "Tirinkot",
            "name": "[TII] - Tirinkot - TII - Tirinkot - Afghanistan",
            "searchName": "TII-Tirinkot-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "TQN",
            "countryCode": "AF",
            "cityCode": "TQN",
            "cityName": "Taluqan",
            "name": "[TQN] - Taluqan - TQN - Taluqan - Afghanistan",
            "searchName": "TQN-Taluqan-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "UND",
            "countryCode": "AF",
            "cityCode": "UND",
            "cityName": "Kunduz",
            "name": "[UND] - Kunduz - UND - Kunduz - Afghanistan",
            "searchName": "UND-Kunduz-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "URN",
            "countryCode": "AF",
            "cityCode": "URN",
            "cityName": "Urgoon",
            "name": "[URN] - Urgoon - URN - Urgoon - Afghanistan",
            "searchName": "URN-Urgoon-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "URZ",
            "countryCode": "AF",
            "cityCode": "URZ",
            "cityName": "Uruzgan",
            "name": "[URZ] - Uruzgan - URZ - Uruzgan - Afghanistan",
            "searchName": "URZ-Uruzgan-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "OLR",
            "countryCode": "AF",
            "cityCode": "OLR",
            "cityName": "Salerno",
            "name": "[OLR] - Salerno Air Base - OLR - Salerno - Afghanistan",
            "searchName": "OLR-Salerno Air Base-Salerno-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "MMZ",
            "countryCode": "AF",
            "cityCode": "MMZ",
            "cityName": "Maimana",
            "name": "[MMZ] - Maimana - MMZ - Maimana - Afghanistan",
            "searchName": "MMZ-Maimana-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "MZR",
            "countryCode": "AF",
            "cityCode": "MZR",
            "cityName": "Mazar-i-sharif",
            "name": "[MZR] - Mazar-i-sharif - MZR - Mazar-i-sharif - Afghanistan",
            "searchName": "MZR-Mazar-I-Sharif-i-sharif-Mazar I Sharif Havalimani-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "OAH",
            "countryCode": "AF",
            "cityCode": "OAH",
            "cityName": "Shindand",
            "name": "[OAH] - Shindand Air Base - OAH - Shindand - Afghanistan",
            "searchName": "OAH-Shindand Air Base-Shindand-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "OAI",
            "countryCode": "AF",
            "cityCode": "OAI",
            "cityName": "Bagram",
            "name": "[OAI] - Bagram - OAI - Bagram - Afghanistan",
            "searchName": "OAI-Bagram-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "OAA",
            "countryCode": "AF",
            "cityCode": "OAA",
            "cityName": "Shank",
            "name": "[OAA] - Shank Air Base - OAA - Shank - Afghanistan",
            "searchName": "OAA-Shank Air Base-Shank-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "OAZ",
            "countryCode": "AF",
            "cityCode": "OAZ",
            "cityName": "Camp Bastion",
            "name": "[OAZ] - Camp Bastion - OAZ - Camp Bastion - Afghanistan",
            "searchName": "OAZ-Camp Bastion-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "OAS",
            "countryCode": "AF",
            "cityCode": "OAS",
            "cityName": "Sharana",
            "name": "[OAS] - Sharana Air Base - OAS - Sharana - Afghanistan",
            "searchName": "OAS-Sharana Air Base-Sharana-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "GRG",
            "countryCode": "AF",
            "cityCode": "GRG",
            "cityName": "Gardez",
            "name": "[GRG] - Gardez - GRG - Gardez - Afghanistan",
            "searchName": "GRG-Gardez-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "GZI",
            "countryCode": "AF",
            "cityCode": "GZI",
            "cityName": "Ghazni",
            "name": "[GZI] - Ghazni - GZI - Ghazni - Afghanistan",
            "searchName": "GZI-Ghazni-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "HEA",
            "countryCode": "AF",
            "cityCode": "HEA",
            "cityName": "Herat",
            "name": "[HEA] - Herat - HEA - Herat - Afghanistan",
            "searchName": "HEA-Herat-Herat Havalimani-H-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "JAA",
            "countryCode": "AF",
            "cityCode": "JAA",
            "cityName": "Jalalabad",
            "name": "[JAA] - Jalalabad - JAA - Jalalabad - Afghanistan",
            "searchName": "JAA-Jalalabad-Dschalalabad-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "KBL",
            "countryCode": "AF",
            "cityCode": "KBL",
            "cityName": "Kabul",
            "name": "[KBL] - Khwaja Rawash - KBL - Kabul - Afghanistan",
            "searchName": "KBL-Kabul-Khwaja Rawash-Kaboul-K-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "KDH",
            "countryCode": "AF",
            "cityCode": "KDH",
            "cityName": "Kandahar",
            "name": "[KDH] - Kandahar - KDH - Kandahar - Afghanistan",
            "searchName": "KDH-Kandahar-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "KHT",
            "countryCode": "AF",
            "cityCode": "KHT",
            "cityName": "Khost",
            "name": "[KHT] - Khost - KHT - Khost - Afghanistan",
            "searchName": "KHT-Khost-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "KUR",
            "countryCode": "AF",
            "cityCode": "KUR",
            "cityName": "Kuran-O-Munjan",
            "name": "[KUR] - Kuran-O-Munjan - KUR - Kuran-O-Munjan - Afghanistan",
            "searchName": "KUR-Kuran-O-Munjan-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "KWH",
            "countryCode": "AF",
            "cityCode": "KWH",
            "cityName": "Khwahan",
            "name": "[KWH] - Khwahan - KWH - Khwahan - Afghanistan",
            "searchName": "KWH-Khwahan-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "LQN",
            "countryCode": "AF",
            "cityCode": "LQN",
            "cityName": "Qala Nau",
            "name": "[LQN] - Qala Nau - LQN - Qala Nau - Afghanistan",
            "searchName": "LQN-Nau - Flughafen-Qala Nau-Qala-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "DWR",
            "countryCode": "AF",
            "cityCode": "DWR",
            "cityName": "Dwyer",
            "name": "[DWR] - Dwyer Air Base - DWR - Dwyer - Afghanistan",
            "searchName": "DWR-Dwyer Air Base-Dwyer-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "DAZ",
            "countryCode": "AF",
            "cityCode": "DAZ",
            "cityName": "Darwaz",
            "name": "[DAZ] - Darwaz - DAZ - Darwaz - Afghanistan",
            "searchName": "DAZ-Darwaz-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "FAH",
            "countryCode": "AF",
            "cityCode": "FAH",
            "cityName": "Farah",
            "name": "[FAH] - Farah - FAH - Farah - Afghanistan",
            "searchName": "FAH-Farah-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "FBD",
            "countryCode": "AF",
            "cityCode": "FBD",
            "cityName": "Faizabad",
            "name": "[FBD] - Faizabad - FBD - Faizabad - Afghanistan",
            "searchName": "FBD-Faizabad-Faizabad Havalimani-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "CCN",
            "countryCode": "AF",
            "cityCode": "CCN",
            "cityName": "Chakcharan",
            "name": "[CCN] - Chakcharan - CCN - Chakcharan - Afghanistan",
            "searchName": "CCN-Chakcharan-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "BIN",
            "countryCode": "AF",
            "cityCode": "BIN",
            "cityName": "Bamiyan",
            "name": "[BIN] - Bamiyan - BIN - Bamiyan - Afghanistan",
            "searchName": "BIN-Bamiyan-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "BST",
            "countryCode": "AF",
            "cityCode": "BST",
            "cityName": "Bost",
            "name": "[BST] - Bost - BST - Bost - Afghanistan",
            "searchName": "BST-Bost-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "ZAJ",
            "countryCode": "AF",
            "cityCode": "ZAJ",
            "cityName": "Zaranj",
            "name": "[ZAJ] - Zaranj - ZAJ - Zaranj - Afghanistan",
            "searchName": "ZAJ-Zaranj-Afghanistan-Afganistan-Afganistán",
            "priority": 0
        },
        {
            "iataCode": "BBQ",
            "countryCode": "AG",
            "cityCode": "BBQ",
            "cityName": "Barbuda",
            "name": "[BBQ] - Barbuda - BBQ - Barbuda - Antigua and Barbuda",
            "searchName": "BBQ-Barbuda-Antigua and Barbuda-Antigua und Barbuda-Antigua y Barbuda-Antigua-et-Antigua e Barbuda",
            "priority": 0
        },
        {
            "iataCode": "ANU",
            "countryCode": "AG",
            "cityCode": "ANU",
            "cityName": "St. John s",
            "name": "[ANU] - V. C. Bird International - ANU - St. John s - Antigua and Barbuda",
            "searchName": "ANU-Antigua-V. C. Bird International-V.C. Bird Intl-St. John s-Antigua & Barbuda-Antigua and Barbuda-Antigua und Barbuda-Antigua y Barbuda-et-Barbuda-Antigua e Barbuda",
            "priority": 0
        },
        {
            "iataCode": "AXA",
            "countryCode": "AI",
            "cityCode": "AXA",
            "cityName": "Anguilla",
            "name": "[AXA] - Wallblake - AXA - Anguilla - Anguilla",
            "searchName": "AXA-Anguilla-Wallblake-Wallblake Airport-The Valley-AIA-Anguila",
            "priority": 0
        },
        {
            "iataCode": "LWN",
            "countryCode": "AM",
            "cityCode": "LWN",
            "cityName": "Gyoumri",
            "name": "[LWN] - Gyoumri - LWN - Gyoumri - Armenia",
            "searchName": "LWN-Gyoumri-Gümrü-Gjumri-Gyumri-G-Örményország-Armènia-Ermenistan-Arménie-Armenia-Armenija-Armeenia--Armenien",
            "priority": 0
        },
        {
            "iataCode": "AAN",
            "countryCode": "AE",
            "cityCode": "AAN",
            "cityName": "Al Ain",
            "name": "[AAN] - Al Ain - AAN - Al Ain - United Arab Emirates",
            "searchName": "AAN-Al Ain-Al-Ain-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 0
        },
        {
            "iataCode": "ZVH",
            "countryCode": "AE",
            "cityCode": "AAN",
            "cityName": "Al Ain",
            "name": "[ZVH] - EK Bus Station - AAN - Al Ain - United Arab Emirates",
            "searchName": "ZVH-AAN-EK Bus Station-Al-Ain-Al Ain-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 0
        },
        {
            "iataCode": "AZI",
            "countryCode": "AE",
            "cityCode": "AUH",
            "cityName": "Abu Dhabi",
            "name": "[AZI] - Bateen - AUH - Abu Dhabi - United Arab Emirates",
            "searchName": "AZI-AUH-Bateen-Abu Dhabi-Abou Dhabi-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 0
        },
        {
            "iataCode": "DHF",
            "countryCode": "AE",
            "cityCode": "AUH",
            "cityName": "Abu Dhabi",
            "name": "[DHF] - Al Dhafra Military Airport - AUH - Abu Dhabi - United Arab Emirates",
            "searchName": "DHF-AUH-Al Dhafra Military Airport-Abu Dhabi-Abou Dhabi-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 0
        },
        {
            "iataCode": "ZVJ",
            "countryCode": "AE",
            "cityCode": "AUH",
            "cityName": "Abu Dhabi",
            "name": "[ZVJ] - EK Bus Station - AUH - Abu Dhabi - United Arab Emirates",
            "searchName": "ZVJ-AUH-EK Bus Station-Abu Dhabi-Abou Dhabi-Verenigde Arabische Emiraten-Emiriah Arab Bersatu-Förenade Arabemiraten-Emirados Árabes Unidos-United Arab Emirates-Vereinigte Arabische Emirate-Birlesik Arap Emirlikleri-Emiratos Árabes Unidos-Émirats arabes unis-Emirati Arabi Uniti",
            "priority": 0
        },
        {
            "iataCode": "CPC",
            "countryCode": "AR",
            "cityCode": "CPC",
            "cityName": "San Martin DeLos Andes",
            "name": "[CPC] - Chapelco - CPC - San Martin DeLos Andes - Argentina",
            "searchName": "CPC-San Martin de los Andes-Chapelco-San Martin DeLos Andes-San Martin De Los Andes-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CNQ",
            "countryCode": "AR",
            "cityCode": "CNQ",
            "cityName": "Corrientes",
            "name": "[CNQ] - Camba Punta - CNQ - Corrientes - Argentina",
            "searchName": "CNQ-Corrientes-Camba Punta-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CPG",
            "countryCode": "AR",
            "cityCode": "CPG",
            "cityName": "Carmen De Patagones",
            "name": "[CPG] - Carmen De Patagones - CPG - Carmen De Patagones - Argentina",
            "searchName": "CPG-Carmen De Patagones-Carmen de Patagones-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CRD",
            "countryCode": "AR",
            "cityCode": "CRD",
            "cityName": "Comodoro Rivadavia",
            "name": "[CRD] - Comodoro Rivadavia - CRD - Comodoro Rivadavia - Argentina",
            "searchName": "CRD-Comodoro Rivadavia-Camodoro-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CRR",
            "countryCode": "AR",
            "cityCode": "CRR",
            "cityName": "Ceres",
            "name": "[CRR] - Ceres - CRR - Ceres - Argentina",
            "searchName": "CRR-Ceres-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "CLX",
            "countryCode": "AR",
            "cityCode": "CLX",
            "cityName": "Clorinda",
            "name": "[CLX] - Clorinda - CLX - Clorinda - Argentina",
            "searchName": "CLX-Clorinda-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "EPA",
            "countryCode": "AR",
            "cityCode": "EPA",
            "cityName": "El Palomar",
            "name": "[EPA] - El Palomar - EPA - El Palomar - Argentina",
            "searchName": "EPA-El Palomar-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "EQS",
            "countryCode": "AR",
            "cityCode": "EQS",
            "cityName": "Esquel",
            "name": "[EQS] - Esquel - EQS - Esquel - Argentina",
            "searchName": "EQS-Esquel-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "EHL",
            "countryCode": "AR",
            "cityCode": "EHL",
            "cityName": "El Bolson",
            "name": "[EHL] - El Bolson - EHL - El Bolson - Argentina",
            "searchName": "EHL-El Bolson-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "ELO",
            "countryCode": "AR",
            "cityCode": "ELO",
            "cityName": "Eldorado",
            "name": "[ELO] - Eldorado - ELO - Eldorado - Argentina",
            "searchName": "ELO-Eldorado-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "EMX",
            "countryCode": "AR",
            "cityCode": "EMX",
            "cityName": "El Maiten",
            "name": "[EMX] - El Maiten - EMX - El Maiten - Argentina",
            "searchName": "EMX-El Maiten-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "BRC",
            "countryCode": "AR",
            "cityCode": "BRC",
            "cityName": "San Carlos DeBariloche",
            "name": "[BRC] - International - BRC - San Carlos DeBariloche - Argentina",
            "searchName": "BRC-San Carlos de Bariloche-International-Teniente Luis Candelaria-Debariloche-Bariloche-San Carlos DeBariloche-San Carlos-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "BHI",
            "countryCode": "AR",
            "cityCode": "BHI",
            "cityName": "Bahia Blanca",
            "name": "[BHI] - Comandante Espora - BHI - Bahia Blanca - Argentina",
            "searchName": "BHI-Bahia Blanca-Comandante Espora-Comandante-Bah-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "ARR",
            "countryCode": "AR",
            "cityCode": "ARR",
            "cityName": "Alto Rio Senguerr",
            "name": "[ARR] - Alto Rio Senguerr - ARR - Alto Rio Senguerr - Argentina",
            "searchName": "ARR-Alto Rio Senguerr-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "APZ",
            "countryCode": "AR",
            "cityCode": "APZ",
            "cityName": "Zapala",
            "name": "[APZ] - Zapala - APZ - Zapala - Argentina",
            "searchName": "APZ-Zapala-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "AOL",
            "countryCode": "AR",
            "cityCode": "AOL",
            "cityName": "Paso De Los Libres",
            "name": "[AOL] - Paso De Los Libres - AOL - Paso De Los Libres - Argentina",
            "searchName": "AOL-Paso De Los Libres-Paso de los Libres-Paso de Los Libres-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "AFA",
            "countryCode": "AR",
            "cityCode": "AFA",
            "cityName": "San Rafael",
            "name": "[AFA] - San Rafael - AFA - San Rafael - Argentina",
            "searchName": "AFA-San Rafael-Argentina-Argentiina-Arjantin-Argentína-Arge-Argentinien-Argentine",
            "priority": 0
        },
        {
            "iataCode": "FTI",
            "countryCode": "AS",
            "cityCode": "FTI",
            "cityName": "Fitiuta",
            "name": "[FTI] - Fitiuta - FTI - Fitiuta - American Samoa",
            "searchName": "FTI-Fitiuta-Amerikanisch-Samoa-American Samoa-Amerikan Samoasi-Samoa américaines-Samoa Americana-Samoa Americane",
            "priority": 0
        },
        {
            "iataCode": "OFU",
            "countryCode": "AS",
            "cityCode": "OFU",
            "cityName": "Vaoto",
            "name": "[OFU] - Airstrip - OFU - Vaoto - American Samoa",
            "searchName": "OFU-Airstrip-Ofu Island-Vaoto-Amerikanisch-Samoa-American Samoa-Amerikan Samoasi-Samoa américaines-Samoa Americana-Samoa Americane",
            "priority": 0
        },
        {
            "iataCode": "XGZ",
            "countryCode": "AT",
            "cityCode": "XGZ",
            "cityName": "Bregenz",
            "name": "[XGZ] - Bregenz Railway Station - XGZ - Bregenz - Austria",
            "searchName": "XGZ-Bregenz Railway Station-Bregenz-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "XJS",
            "countryCode": "AT",
            "cityCode": "XJS",
            "cityName": "Kitzbuehl",
            "name": "[XJS] - Kitzbuehl Railway - XJS - Kitzbuehl - Austria",
            "searchName": "XJS-Kitzbuehl Railway-Kitzbuehl-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "XOH",
            "countryCode": "AT",
            "cityCode": "XJS",
            "cityName": "Kitzbuehl",
            "name": "[XOH] - Kitzbuehel Bus Station - XJS - Kitzbuehl - Austria",
            "searchName": "XOH-XJS-Kitzbuehel Bus Station-Kitzbuehl-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "XOI",
            "countryCode": "AT",
            "cityCode": "XOI",
            "cityName": "Ischgl",
            "name": "[XOI] - Ischgl Bus Station - XOI - Ischgl - Austria",
            "searchName": "XOI-Ischgl Bus Station-Ischgl-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "XOW",
            "countryCode": "AT",
            "cityCode": "XOW",
            "cityName": "Seefeld",
            "name": "[XOW] - Seefeld Bus Station - XOW - Seefeld - Austria",
            "searchName": "XOW-Seefeld Bus Station-Seefeld-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "XOO",
            "countryCode": "AT",
            "cityCode": "XOO",
            "cityName": "Soelden",
            "name": "[XOO] - Soelden Bus Station - XOO - Soelden - Austria",
            "searchName": "XOO-Soelden Bus Station-Soelden-Austria-Áo-Österrike-Austrija-Oostenrijk-Áustria-Autriche-Østerrike-Østrig-Österreich-Avusturya",
            "priority": 0
        },
        {
            "iataCode": "ZII",
            "countryCode": "AU",
            "cityCode": "ADL",
            "cityName": "Adelaide",
            "name": "[ZII] - Adelaide Ferry Port - ADL - Adelaide - Australia",
            "searchName": "ZII-ADL-Adelaide Ferry Port-Adelaide-Adelaida-Ad-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XHX",
            "countryCode": "AU",
            "cityCode": "ADL",
            "cityName": "Adelaide",
            "name": "[XHX] - Adelaide Keswick Railway - ADL - Adelaide - Australia",
            "searchName": "XHX-ADL-Adelaide Keswick Railway-Adelaide-Adelaida-Ad-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ADO",
            "countryCode": "AU",
            "cityCode": "ADO",
            "cityName": "Andamooka",
            "name": "[ADO] - Andamooka - ADO - Andamooka - Australia",
            "searchName": "ADO-Andamooka-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AAB",
            "countryCode": "AU",
            "cityCode": "AAB",
            "cityName": "Arrabury",
            "name": "[AAB] - Arrabury - AAB - Arrabury - Australia",
            "searchName": "AAB-Arrabury-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ABG",
            "countryCode": "AU",
            "cityCode": "ABG",
            "cityName": "Abingdon",
            "name": "[ABG] - Abingdon - ABG - Abingdon - Australia",
            "searchName": "ABG-Abingdon-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ABH",
            "countryCode": "AU",
            "cityCode": "ABH",
            "cityName": "Alpha",
            "name": "[ABH] - Alpha - ABH - Alpha - Australia",
            "searchName": "ABH-Alpha-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ABM",
            "countryCode": "AU",
            "cityCode": "ABM",
            "cityName": "Bamaga",
            "name": "[ABM] - Bamaga Injinoo - ABM - Bamaga - Australia",
            "searchName": "ABM-Bamaga-Bamaga Injinoo-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ABX",
            "countryCode": "AU",
            "cityCode": "ABX",
            "cityName": "Albury",
            "name": "[ABX] - Albury - ABX - Albury - Australia",
            "searchName": "ABX-Albury-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AGW",
            "countryCode": "AU",
            "cityCode": "AGW",
            "cityName": "Agnew",
            "name": "[AGW] - Agnew - AGW - Agnew - Australia",
            "searchName": "AGW-Agnew-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ALH",
            "countryCode": "AU",
            "cityCode": "ALH",
            "cityName": "Albany",
            "name": "[ALH] - Albany - ALH - Albany - Australia",
            "searchName": "ALH-Albany Australien-Albany-Albany Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "AMT",
            "countryCode": "AU",
            "cityCode": "AMT",
            "cityName": "Amata",
            "name": "[AMT] - Amata - AMT - Amata - Australia",
            "searchName": "AMT-Amata-Amata NT-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BHQ",
            "countryCode": "AU",
            "cityCode": "BHQ",
            "cityName": "Broken Hill",
            "name": "[BHQ] - Broken Hill - BHQ - Broken Hill - Australia",
            "searchName": "BHQ-Broken Hill-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BHS",
            "countryCode": "AU",
            "cityCode": "BHS",
            "cityName": "Bathurst",
            "name": "[BHS] - Raglan - BHS - Bathurst - Australia",
            "searchName": "BHS-Raglan-Bathurst-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BHT",
            "countryCode": "AU",
            "cityCode": "BHT",
            "cityName": "Brighton Downs",
            "name": "[BHT] - Brighton Downs - BHT - Brighton Downs - Australia",
            "searchName": "BHT-Brighton Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BIP",
            "countryCode": "AU",
            "cityCode": "BIP",
            "cityName": "Bulimba",
            "name": "[BIP] - Bulimba - BIP - Bulimba - Australia",
            "searchName": "BIP-Bulimba-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BIW",
            "countryCode": "AU",
            "cityCode": "BIW",
            "cityName": "Billiluna",
            "name": "[BIW] - Billiluna - BIW - Billiluna - Australia",
            "searchName": "BIW-Billiluna-Billiluna Station-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BKP",
            "countryCode": "AU",
            "cityCode": "BKP",
            "cityName": "Barkly Downs",
            "name": "[BKP] - Barkly Downs - BKP - Barkly Downs - Australia",
            "searchName": "BKP-Barkly Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BKQ",
            "countryCode": "AU",
            "cityCode": "BKQ",
            "cityName": "Blackall",
            "name": "[BKQ] - Blackall - BKQ - Blackall - Australia",
            "searchName": "BKQ-Blackall-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BLN",
            "countryCode": "AU",
            "cityCode": "BLN",
            "cityName": "Benalla",
            "name": "[BLN] - Benalla - BLN - Benalla - Australia",
            "searchName": "BLN-Benalla-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BLS",
            "countryCode": "AU",
            "cityCode": "BLS",
            "cityName": "Bollon",
            "name": "[BLS] - Bollon - BLS - Bollon - Australia",
            "searchName": "BLS-Bollon-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BLT",
            "countryCode": "AU",
            "cityCode": "BLT",
            "cityName": "Blackwater",
            "name": "[BLT] - Blackwater - BLT - Blackwater - Australia",
            "searchName": "BLT-Blackwater-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BME",
            "countryCode": "AU",
            "cityCode": "BME",
            "cityName": "Broome",
            "name": "[BME] - Broome - BME - Broome - Australia",
            "searchName": "BME-Broome-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BMP",
            "countryCode": "AU",
            "cityCode": "BMP",
            "cityName": "Brampton Island",
            "name": "[BMP] - Brampton Island - BMP - Brampton Island - Australia",
            "searchName": "BMP-Brampton Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BCI",
            "countryCode": "AU",
            "cityCode": "BCI",
            "cityName": "Barcaldine",
            "name": "[BCI] - Barcaldine - BCI - Barcaldine - Australia",
            "searchName": "BCI-Barcaldine-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BCK",
            "countryCode": "AU",
            "cityCode": "BCK",
            "cityName": "Bolwarra",
            "name": "[BCK] - Bolwarra - BCK - Bolwarra - Australia",
            "searchName": "BCK-Bolwarra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BCZ",
            "countryCode": "AU",
            "cityCode": "BCZ",
            "cityName": "Bickerton Island",
            "name": "[BCZ] - Bickerton Island - BCZ - Bickerton Island - Australia",
            "searchName": "BCZ-Bickerton Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BDB",
            "countryCode": "AU",
            "cityCode": "BDB",
            "cityName": "Bundaberg",
            "name": "[BDB] - Bundaberg - BDB - Bundaberg - Australia",
            "searchName": "BDB-Bundaberg-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BDD",
            "countryCode": "AU",
            "cityCode": "BDD",
            "cityName": "Badu Island",
            "name": "[BDD] - Badu Island - BDD - Badu Island - Australia",
            "searchName": "BDD-Badu Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BDW",
            "countryCode": "AU",
            "cityCode": "BDW",
            "cityName": "Bedford Downs",
            "name": "[BDW] - Bedford Downs - BDW - Bedford Downs - Australia",
            "searchName": "BDW-Bedford Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BEE",
            "countryCode": "AU",
            "cityCode": "BEE",
            "cityName": "Beagle Bay",
            "name": "[BEE] - Beagle Bay - BEE - Beagle Bay - Australia",
            "searchName": "BEE-Beagle Bay-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BEU",
            "countryCode": "AU",
            "cityCode": "BEU",
            "cityName": "Bedourie",
            "name": "[BEU] - Bedourie - BEU - Bedourie - Australia",
            "searchName": "BEU-Bedourie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BFC",
            "countryCode": "AU",
            "cityCode": "BFC",
            "cityName": "Bloomfield",
            "name": "[BFC] - Bloomfield - BFC - Bloomfield - Australia",
            "searchName": "BFC-Bloomfield-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BQW",
            "countryCode": "AU",
            "cityCode": "BQW",
            "cityName": "Balgo Hills",
            "name": "[BQW] - Balgo Hills - BQW - Balgo Hills - Australia",
            "searchName": "BQW-Balgo Hills-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BQL",
            "countryCode": "AU",
            "cityCode": "BQL",
            "cityName": "Boulia",
            "name": "[BQL] - Boulia - BQL - Boulia - Australia",
            "searchName": "BQL-Boulia-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BRJ",
            "countryCode": "AU",
            "cityCode": "BRJ",
            "cityName": "Bright",
            "name": "[BRJ] - Bright - BRJ - Bright - Australia",
            "searchName": "BRJ-Bright-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BRK",
            "countryCode": "AU",
            "cityCode": "BRK",
            "cityName": "Bourke",
            "name": "[BRK] - Bourke - BRK - Bourke - Australia",
            "searchName": "BRK-Bourke-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BRT",
            "countryCode": "AU",
            "cityCode": "BRT",
            "cityName": "Bathurst Island",
            "name": "[BRT] - Bathurst Island - BRT - Bathurst Island - Australia",
            "searchName": "BRT-Bathurst Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BTD",
            "countryCode": "AU",
            "cityCode": "BTD",
            "cityName": "Brunette Downs",
            "name": "[BTD] - Brunette Downs - BTD - Brunette Downs - Australia",
            "searchName": "BTD-Brunette Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BSJ",
            "countryCode": "AU",
            "cityCode": "BSJ",
            "cityName": "Bairnsdale",
            "name": "[BSJ] - Bairnsdale - BSJ - Bairnsdale - Australia",
            "searchName": "BSJ-Bairnsdale-Bairnsdale Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XQN",
            "countryCode": "AU",
            "cityCode": "BNE",
            "cityName": "Brisbane",
            "name": "[XQN] - Brisbane Ferry Port - BNE - Brisbane - Australia",
            "searchName": "XQN-BNE-Brisbane Ferry Port-Brisbane-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BNK",
            "countryCode": "AU",
            "cityCode": "BNK",
            "cityName": "Ballina",
            "name": "[BNK] - Ballina Byron Gateway - BNK - Ballina - Australia",
            "searchName": "BNK-Ballina-Ballina Byron Gateway-Ballina Byron-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BOX",
            "countryCode": "AU",
            "cityCode": "BOX",
            "cityName": "Borroloola",
            "name": "[BOX] - Borroloola - BOX - Borroloola - Australia",
            "searchName": "BOX-Borroloola-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BQB",
            "countryCode": "AU",
            "cityCode": "BQB",
            "cityName": "Bussellton",
            "name": "[BQB] - Bussellton - BQB - Bussellton - Australia",
            "searchName": "BQB-Bussellton Airport-Bussellton-Busselton Havalimani-Busselton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BUC",
            "countryCode": "AU",
            "cityCode": "BUC",
            "cityName": "Burketown",
            "name": "[BUC] - Burketown - BUC - Burketown - Australia",
            "searchName": "BUC-Burketown-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BTX",
            "countryCode": "AU",
            "cityCode": "BTX",
            "cityName": "Betoota",
            "name": "[BTX] - Betoota - BTX - Betoota - Australia",
            "searchName": "BTX-Betoota-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BUY",
            "countryCode": "AU",
            "cityCode": "BUY",
            "cityName": "Bunbury",
            "name": "[BUY] - Bunbury - BUY - Bunbury - Australia",
            "searchName": "BUY-Bunbury-Bunbury Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BVI",
            "countryCode": "AU",
            "cityCode": "BVI",
            "cityName": "Birdsville",
            "name": "[BVI] - Birdsville - BVI - Birdsville - Australia",
            "searchName": "BVI-Birdsville-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BVZ",
            "countryCode": "AU",
            "cityCode": "BVZ",
            "cityName": "Beverley Springs",
            "name": "[BVZ] - Beverley Springs - BVZ - Beverley Springs - Australia",
            "searchName": "BVZ-Beverley Springs-Beverly Springs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BWB",
            "countryCode": "AU",
            "cityCode": "BWB",
            "cityName": "Barrow Island",
            "name": "[BWB] - Barrow Island - BWB - Barrow Island - Australia",
            "searchName": "BWB-Barrow Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BWQ",
            "countryCode": "AU",
            "cityCode": "BWQ",
            "cityName": "Brewarrina",
            "name": "[BWQ] - Brewarrina - BWQ - Brewarrina - Australia",
            "searchName": "BWQ-Brewarrina-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BWT",
            "countryCode": "AU",
            "cityCode": "BWT",
            "cityName": "Burnie",
            "name": "[BWT] - Burnie Wynyard - BWT - Burnie - Australia",
            "searchName": "BWT-Burnie-Burnie Wynyard-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BXF",
            "countryCode": "AU",
            "cityCode": "BXF",
            "cityName": "Belburn",
            "name": "[BXF] - Belburn - BXF - Belburn - Australia",
            "searchName": "BXF-Belburn-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BXG",
            "countryCode": "AU",
            "cityCode": "BXG",
            "cityName": "Bendigo",
            "name": "[BXG] - Bendigo - BXG - Bendigo - Australia",
            "searchName": "BXG-Bendigo-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BYX",
            "countryCode": "AU",
            "cityCode": "BYX",
            "cityName": "Baniyala",
            "name": "[BYX] - Baniyala - BYX - Baniyala - Australia",
            "searchName": "BYX-Baniyala-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BZD",
            "countryCode": "AU",
            "cityCode": "BZD",
            "cityName": "Balranald",
            "name": "[BZD] - Balranald - BZD - Balranald - Australia",
            "searchName": "BZD-Balranald-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ENB",
            "countryCode": "AU",
            "cityCode": "ENB",
            "cityName": "Eneabba West",
            "name": "[ENB] - Eneabba West - ENB - Eneabba West - Australia",
            "searchName": "ENB-Eneabba West-Eneabba-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "EMD",
            "countryCode": "AU",
            "cityCode": "EMD",
            "cityName": "Emerald",
            "name": "[EMD] - Emerald - EMD - Emerald - Australia",
            "searchName": "EMD-Emerald-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "EKD",
            "countryCode": "AU",
            "cityCode": "EKD",
            "cityName": "Elkedra",
            "name": "[EKD] - Elkedra - EKD - Elkedra - Australia",
            "searchName": "EKD-Elkedra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ELC",
            "countryCode": "AU",
            "cityCode": "ELC",
            "cityName": "Elcho Island",
            "name": "[ELC] - Elcho Island - ELC - Elcho Island - Australia",
            "searchName": "ELC-Elcho Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "EIH",
            "countryCode": "AU",
            "cityCode": "EIH",
            "cityName": "Einasleigh",
            "name": "[EIH] - Einasleigh - EIH - Einasleigh - Australia",
            "searchName": "EIH-Einasleigh-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "EDR",
            "countryCode": "AU",
            "cityCode": "EDR",
            "cityName": "Edward River",
            "name": "[EDR] - Edward River - EDR - Edward River - Australia",
            "searchName": "EDR-Edward River-Edward Nehri-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ECH",
            "countryCode": "AU",
            "cityCode": "ECH",
            "cityName": "Echuca",
            "name": "[ECH] - Echuca - ECH - Echuca - Australia",
            "searchName": "ECH-Echuca-Echuca Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "EDD",
            "countryCode": "AU",
            "cityCode": "EDD",
            "cityName": "Erldunda",
            "name": "[EDD] - Erldunda - EDD - Erldunda - Australia",
            "searchName": "EDD-Erldunda-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ERB",
            "countryCode": "AU",
            "cityCode": "ERB",
            "cityName": "Ernabella",
            "name": "[ERB] - Ernabella - ERB - Ernabella - Australia",
            "searchName": "ERB-Ernabella-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ERQ",
            "countryCode": "AU",
            "cityCode": "ERQ",
            "cityName": "Elrose Mine",
            "name": "[ERQ] - Elrose Mine - ERQ - Elrose Mine - Australia",
            "searchName": "ERQ-Elrose Mine-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "EPR",
            "countryCode": "AU",
            "cityCode": "EPR",
            "cityName": "Esperance",
            "name": "[EPR] - Esperance - EPR - Esperance - Australia",
            "searchName": "EPR-Esperance-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ETD",
            "countryCode": "AU",
            "cityCode": "ETD",
            "cityName": "Etadunna",
            "name": "[ETD] - Etadunna - ETD - Etadunna - Australia",
            "searchName": "ETD-Etadunna-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "EUC",
            "countryCode": "AU",
            "cityCode": "EUC",
            "cityName": "Eucla",
            "name": "[EUC] - Eucla - EUC - Eucla - Australia",
            "searchName": "EUC-Eucla-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "EVD",
            "countryCode": "AU",
            "cityCode": "EVD",
            "cityName": "Eva Downs",
            "name": "[EVD] - Eva Downs - EVD - Eva Downs - Australia",
            "searchName": "EVD-Eva Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "EVH",
            "countryCode": "AU",
            "cityCode": "EVH",
            "cityName": "Evans Head",
            "name": "[EVH] - Evans Head - EVH - Evans Head - Australia",
            "searchName": "EVH-Evans Head-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "EXM",
            "countryCode": "AU",
            "cityCode": "EXM",
            "cityName": "Exmouth Gulf",
            "name": "[EXM] - Exmouth Gulf - EXM - Exmouth Gulf - Australia",
            "searchName": "EXM-Exmouth Gulf-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DRD",
            "countryCode": "AU",
            "cityCode": "DRD",
            "cityName": "Dorunda Station",
            "name": "[DRD] - Dorunda Station - DRD - Dorunda Station - Australia",
            "searchName": "DRD-Dorunda Station-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DRB",
            "countryCode": "AU",
            "cityCode": "DRB",
            "cityName": "Derby",
            "name": "[DRB] - Derby - DRB - Derby - Australia",
            "searchName": "DRB-Derby-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DCN",
            "countryCode": "AU",
            "cityCode": "DRB",
            "cityName": "Derby",
            "name": "[DCN] - RAAF Curtin - DRB - Derby - Australia",
            "searchName": "DCN-DRB-RAAF Curtin-Derby Curtin-Derby-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DPO",
            "countryCode": "AU",
            "cityCode": "DPO",
            "cityName": "Devonport",
            "name": "[DPO] - Devonport - DPO - Devonport - Australia",
            "searchName": "DPO-Devonport-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DRN",
            "countryCode": "AU",
            "cityCode": "DRN",
            "cityName": "Dirranbandi",
            "name": "[DRN] - Dirranbandi - DRN - Dirranbandi - Australia",
            "searchName": "DRN-Dirranbandi-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DRR",
            "countryCode": "AU",
            "cityCode": "DRR",
            "cityName": "Durrie",
            "name": "[DRR] - Durrie - DRR - Durrie - Australia",
            "searchName": "DRR-Durrie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DRW",
            "countryCode": "AU",
            "cityCode": "DRW",
            "cityName": "Darwin",
            "name": "[DRW] - Darwin - DRW - Darwin - Australia",
            "searchName": "DRW-Darwin-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XJG",
            "countryCode": "AU",
            "cityCode": "DRW",
            "cityName": "Darwin",
            "name": "[XJG] - Darwin Railway - DRW - Darwin - Australia",
            "searchName": "XJG-DRW-Darwin Railway-Darwin-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DRY",
            "countryCode": "AU",
            "cityCode": "DRY",
            "cityName": "Drysdale River",
            "name": "[DRY] - Drysdale River - DRY - Drysdale River - Australia",
            "searchName": "DRY-Drysdale River-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DNM",
            "countryCode": "AU",
            "cityCode": "DNM",
            "cityName": "Denham",
            "name": "[DNM] - Denham - DNM - Denham - Australia",
            "searchName": "DNM-Denham-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DNQ",
            "countryCode": "AU",
            "cityCode": "DNQ",
            "cityName": "Deniliquin",
            "name": "[DNQ] - Deniliquin - DNQ - Deniliquin - Australia",
            "searchName": "DNQ-Deniliquin-Denilinquin Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DOX",
            "countryCode": "AU",
            "cityCode": "DOX",
            "cityName": "Dongara",
            "name": "[DOX] - Dongara - DOX - Dongara - Australia",
            "searchName": "DOX-Dongara-Dongara Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DVP",
            "countryCode": "AU",
            "cityCode": "DVP",
            "cityName": "Davenport Downs",
            "name": "[DVP] - Davenport Downs - DVP - Davenport Downs - Australia",
            "searchName": "DVP-Davenport Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DVR",
            "countryCode": "AU",
            "cityCode": "DVR",
            "cityName": "Daly River",
            "name": "[DVR] - Daly River - DVR - Daly River - Australia",
            "searchName": "DVR-Daly River-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DXD",
            "countryCode": "AU",
            "cityCode": "DXD",
            "cityName": "Dixie",
            "name": "[DXD] - Dixie - DXD - Dixie - Australia",
            "searchName": "DXD-Dixie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DYA",
            "countryCode": "AU",
            "cityCode": "DYA",
            "cityName": "Dysart",
            "name": "[DYA] - Dysart - DYA - Dysart - Australia",
            "searchName": "DYA-Dysart-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DYM",
            "countryCode": "AU",
            "cityCode": "DYM",
            "cityName": "Diamantina Lakes",
            "name": "[DYM] - Diamantina Lakes - DYM - Diamantina Lakes - Australia",
            "searchName": "DYM-Diamantina Lakes-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DYW",
            "countryCode": "AU",
            "cityCode": "DYW",
            "cityName": "Daly Waters",
            "name": "[DYW] - Daly Waters - DYW - Daly Waters - Australia",
            "searchName": "DYW-Daly Waters-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DBO",
            "countryCode": "AU",
            "cityCode": "DBO",
            "cityName": "Dubbo",
            "name": "[DBO] - Dubbo - DBO - Dubbo - Australia",
            "searchName": "DBO-Dubbo-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DBY",
            "countryCode": "AU",
            "cityCode": "DBY",
            "cityName": "Dalby",
            "name": "[DBY] - Dalby - DBY - Dalby - Australia",
            "searchName": "DBY-Dalby-Dalby Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DDI",
            "countryCode": "AU",
            "cityCode": "DDI",
            "cityName": "Daydream Island",
            "name": "[DDI] - Daydream Island - DDI - Daydream Island - Australia",
            "searchName": "DDI-Daydream Island-Daydream Is-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DDN",
            "countryCode": "AU",
            "cityCode": "DDN",
            "cityName": "Delta Downs",
            "name": "[DDN] - Delta Downs - DDN - Delta Downs - Australia",
            "searchName": "DDN-Delta Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DFP",
            "countryCode": "AU",
            "cityCode": "DFP",
            "cityName": "Drumduff",
            "name": "[DFP] - Drumduff - DFP - Drumduff - Australia",
            "searchName": "DFP-Drumduff-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DGD",
            "countryCode": "AU",
            "cityCode": "DGD",
            "cityName": "Dalgaranga",
            "name": "[DGD] - Dalgaranga - DGD - Dalgaranga - Australia",
            "searchName": "DGD-Dalgaranga-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DGE",
            "countryCode": "AU",
            "cityCode": "DGE",
            "cityName": "Mudgee",
            "name": "[DGE] - Mudgee - DGE - Mudgee - Australia",
            "searchName": "DGE-Mudgee-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DHD",
            "countryCode": "AU",
            "cityCode": "DHD",
            "cityName": "Durham Downs",
            "name": "[DHD] - Durham Downs - DHD - Durham Downs - Australia",
            "searchName": "DHD-Durham Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DKI",
            "countryCode": "AU",
            "cityCode": "DKI",
            "cityName": "Dunk Island",
            "name": "[DKI] - Dunk Island - DKI - Dunk Island - Australia",
            "searchName": "DKI-Dunk Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DKV",
            "countryCode": "AU",
            "cityCode": "DKV",
            "cityName": "Docker River",
            "name": "[DKV] - Docker River - DKV - Docker River - Australia",
            "searchName": "DKV-Docker River-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DLK",
            "countryCode": "AU",
            "cityCode": "DLK",
            "cityName": "Dulkaninna",
            "name": "[DLK] - Dulkaninna - DLK - Dulkaninna - Australia",
            "searchName": "DLK-Dulkaninna-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DLV",
            "countryCode": "AU",
            "cityCode": "DLV",
            "cityName": "Delissaville",
            "name": "[DLV] - Delissaville - DLV - Delissaville - Australia",
            "searchName": "DLV-Delissaville-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DMD",
            "countryCode": "AU",
            "cityCode": "DMD",
            "cityName": "Doomadgee",
            "name": "[DMD] - Doomadgee - DMD - Doomadgee - Australia",
            "searchName": "DMD-Doomadgee-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DNB",
            "countryCode": "AU",
            "cityCode": "DNB",
            "cityName": "Dunbar",
            "name": "[DNB] - Dunbar - DNB - Dunbar - Australia",
            "searchName": "DNB-Dunbar-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DNG",
            "countryCode": "AU",
            "cityCode": "DNG",
            "cityName": "Doongan",
            "name": "[DNG] - Doongan - DNG - Doongan - Australia",
            "searchName": "DNG-Doongan-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CMA",
            "countryCode": "AU",
            "cityCode": "CMA",
            "cityName": "Cunnamulla",
            "name": "[CMA] - Cunnamulla - CMA - Cunnamulla - Australia",
            "searchName": "CMA-Cunnamulla-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CKW",
            "countryCode": "AU",
            "cityCode": "CKW",
            "cityName": "Christmas Creek Mine",
            "name": "[CKW] - Christmas Creek Mine - CKW - Christmas Creek Mine - Australia",
            "searchName": "CKW-Christmas Creek Mine-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CLH",
            "countryCode": "AU",
            "cityCode": "CLH",
            "cityName": "Coolah",
            "name": "[CLH] - Coolah - CLH - Coolah - Australia",
            "searchName": "CLH-Coolah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CKI",
            "countryCode": "AU",
            "cityCode": "CKI",
            "cityName": "Croker Island",
            "name": "[CKI] - Croker Island - CKI - Croker Island - Australia",
            "searchName": "CKI-Croker Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CFP",
            "countryCode": "AU",
            "cityCode": "CFP",
            "cityName": "Carpentaria Downs",
            "name": "[CFP] - Carpentaria Downs - CFP - Carpentaria Downs - Australia",
            "searchName": "CFP-Carpentaria Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CFS",
            "countryCode": "AU",
            "cityCode": "CFS",
            "cityName": "Coffs Harbour",
            "name": "[CFS] - Coffs Harbour - CFS - Coffs Harbour - Australia",
            "searchName": "CFS-Coffs Harbour-Coffs Harbor-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CGV",
            "countryCode": "AU",
            "cityCode": "CGV",
            "cityName": "Caiguna",
            "name": "[CGV] - Caiguna - CGV - Caiguna - Australia",
            "searchName": "CGV-Caiguna-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CIE",
            "countryCode": "AU",
            "cityCode": "CIE",
            "cityName": "Collie",
            "name": "[CIE] - Collie - CIE - Collie - Australia",
            "searchName": "CIE-Collie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CBX",
            "countryCode": "AU",
            "cityCode": "CBX",
            "cityName": "Condobolin",
            "name": "[CBX] - Condobolin - CBX - Condobolin - Australia",
            "searchName": "CBX-Condobolin-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CBY",
            "countryCode": "AU",
            "cityCode": "CBY",
            "cityName": "Canobie",
            "name": "[CBY] - Canobie - CBY - Canobie - Australia",
            "searchName": "CBY-Canobie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CAZ",
            "countryCode": "AU",
            "cityCode": "CAZ",
            "cityName": "Cobar",
            "name": "[CAZ] - Cobar - CAZ - Cobar - Australia",
            "searchName": "CAZ-Cobar-Cobar Arpt-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CBC",
            "countryCode": "AU",
            "cityCode": "CBC",
            "cityName": "Cherrabun",
            "name": "[CBC] - Cherrabun - CBC - Cherrabun - Australia",
            "searchName": "CBC-Cherrabun-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CBI",
            "countryCode": "AU",
            "cityCode": "CBI",
            "cityName": "Cape Barren Island",
            "name": "[CBI] - Cape Barren Island - CBI - Cape Barren Island - Australia",
            "searchName": "CBI-Cape Barren Island-Isla Cape Barren-Cape Barren- Isola-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BZP",
            "countryCode": "AU",
            "cityCode": "BZP",
            "cityName": "Bizant",
            "name": "[BZP] - Bizant - BZP - Bizant - Australia",
            "searchName": "BZP-Bizant-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CFH",
            "countryCode": "AU",
            "cityCode": "CFH",
            "cityName": "Clifton Hills",
            "name": "[CFH] - Clifton Hills - CFH - Clifton Hills - Australia",
            "searchName": "CFH-Clifton Hills-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CFI",
            "countryCode": "AU",
            "cityCode": "CFI",
            "cityName": "Camfield",
            "name": "[CFI] - Camfield - CFI - Camfield - Australia",
            "searchName": "CFI-Camfield-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CED",
            "countryCode": "AU",
            "cityCode": "CED",
            "cityName": "Ceduna",
            "name": "[CED] - Ceduna - CED - Ceduna - Australia",
            "searchName": "CED-Ceduna-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CES",
            "countryCode": "AU",
            "cityCode": "CES",
            "cityName": "Cessnock",
            "name": "[CES] - Cessnock - CES - Cessnock - Australia",
            "searchName": "CES-Cessnock-Cessnock Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CCL",
            "countryCode": "AU",
            "cityCode": "CCL",
            "cityName": "Chinchilla",
            "name": "[CCL] - Chinchilla - CCL - Chinchilla - Australia",
            "searchName": "CCL-Chinchilla-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CCW",
            "countryCode": "AU",
            "cityCode": "CCW",
            "cityName": "Cowell",
            "name": "[CCW] - Cowell - CCW - Cowell - Australia",
            "searchName": "CCW-Cowell-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CDA",
            "countryCode": "AU",
            "cityCode": "CDA",
            "cityName": "Cooinda",
            "name": "[CDA] - Cooinda - CDA - Cooinda - Australia",
            "searchName": "CDA-Cooinda-Cooinda Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CDQ",
            "countryCode": "AU",
            "cityCode": "CDQ",
            "cityName": "Croydon",
            "name": "[CDQ] - Croydon - CDQ - Croydon - Australia",
            "searchName": "CDQ-Croydon-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CDU",
            "countryCode": "AU",
            "cityCode": "CDU",
            "cityName": "Camden",
            "name": "[CDU] - Camden - CDU - Camden - Australia",
            "searchName": "CDU-Camden-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CRY",
            "countryCode": "AU",
            "cityCode": "CRY",
            "cityName": "Carlton Hill",
            "name": "[CRY] - Carlton Hill - CRY - Carlton Hill - Australia",
            "searchName": "CRY-Carlton Hill-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CSD",
            "countryCode": "AU",
            "cityCode": "CSD",
            "cityName": "Cresswell Downs",
            "name": "[CSD] - Cresswell Downs - CSD - Cresswell Downs - Australia",
            "searchName": "CSD-Cresswell Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CSI",
            "countryCode": "AU",
            "cityCode": "CSI",
            "cityName": "Casino",
            "name": "[CSI] - Casino - CSI - Casino - Australia",
            "searchName": "CSI-Casino-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CRH",
            "countryCode": "AU",
            "cityCode": "CRH",
            "cityName": "Cherribah",
            "name": "[CRH] - Cherribah - CRH - Cherribah - Australia",
            "searchName": "CRH-Cherribah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CRJ",
            "countryCode": "AU",
            "cityCode": "CRJ",
            "cityName": "Coorabie",
            "name": "[CRJ] - Coorabie - CRJ - Coorabie - Australia",
            "searchName": "CRJ-Coorabie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CQP",
            "countryCode": "AU",
            "cityCode": "CQP",
            "cityName": "Cape Flattery",
            "name": "[CQP] - Cape Flattery - CQP - Cape Flattery - Australia",
            "searchName": "CQP-Cape Flattery-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CRB",
            "countryCode": "AU",
            "cityCode": "CRB",
            "cityName": "Collarenebri",
            "name": "[CRB] - Collarenebri - CRB - Collarenebri - Australia",
            "searchName": "CRB-Collarenebri-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CNS",
            "countryCode": "AU",
            "cityCode": "CNS",
            "cityName": "Cairns",
            "name": "[CNS] - Cairns - CNS - Cairns - Australia",
            "searchName": "CNS-Cairns-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CNJ",
            "countryCode": "AU",
            "cityCode": "CNJ",
            "cityName": "Cloncurry",
            "name": "[CNJ] - Cloncurry - CNJ - Cloncurry - Australia",
            "searchName": "CNJ-Cloncurry-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CNB",
            "countryCode": "AU",
            "cityCode": "CNB",
            "cityName": "Coonamble",
            "name": "[CNB] - Coonamble - CNB - Coonamble - Australia",
            "searchName": "CNB-Coonamble-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CNC",
            "countryCode": "AU",
            "cityCode": "CNC",
            "cityName": "Coconut Island",
            "name": "[CNC] - Coconut Island - CNC - Coconut Island - Australia",
            "searchName": "CNC-Coconut Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CMD",
            "countryCode": "AU",
            "cityCode": "CMD",
            "cityName": "Cootamundra",
            "name": "[CMD] - Cootamundra - CMD - Cootamundra - Australia",
            "searchName": "CMD-Cootamundra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CML",
            "countryCode": "AU",
            "cityCode": "CML",
            "cityName": "Camooweal",
            "name": "[CML] - Camooweal - CML - Camooweal - Australia",
            "searchName": "CML-Camooweal-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CMQ",
            "countryCode": "AU",
            "cityCode": "CMQ",
            "cityName": "Clermont",
            "name": "[CMQ] - Clermont - CMQ - Clermont - Australia",
            "searchName": "CMQ-Clermont-Clermont Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CPD",
            "countryCode": "AU",
            "cityCode": "CPD",
            "cityName": "Coober Pedy",
            "name": "[CPD] - Coober Pedy - CPD - Coober Pedy - Australia",
            "searchName": "CPD-Coober Pedy-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "COY",
            "countryCode": "AU",
            "cityCode": "COY",
            "cityName": "Coolawanyah",
            "name": "[COY] - Coolawanyah - COY - Coolawanyah - Australia",
            "searchName": "COY-Coolawanyah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "COB",
            "countryCode": "AU",
            "cityCode": "COB",
            "cityName": "Coolibah",
            "name": "[COB] - Coolibah - COB - Coolibah - Australia",
            "searchName": "COB-Coolibah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "COJ",
            "countryCode": "AU",
            "cityCode": "COJ",
            "cityName": "Coonabarabran",
            "name": "[COJ] - Coonabarabran - COJ - Coonabarabran - Australia",
            "searchName": "COJ-Coonabarabran-Coonabarabran Havalimani-Coonabarabrn-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CVC",
            "countryCode": "AU",
            "cityCode": "CVC",
            "cityName": "Cleve",
            "name": "[CVC] - Cleve - CVC - Cleve - Australia",
            "searchName": "CVC-Cleve-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CUY",
            "countryCode": "AU",
            "cityCode": "CUY",
            "cityName": "Cue",
            "name": "[CUY] - Cue - CUY - Cue - Australia",
            "searchName": "CUY-Cue-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CUQ",
            "countryCode": "AU",
            "cityCode": "CUQ",
            "cityName": "Coen",
            "name": "[CUQ] - Coen - CUQ - Coen - Australia",
            "searchName": "CUQ-Coen-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CVQ",
            "countryCode": "AU",
            "cityCode": "CVQ",
            "cityName": "Carnarvon",
            "name": "[CVQ] - Carnarvon - CVQ - Carnarvon - Australia",
            "searchName": "CVQ-Carnarvon-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CWT",
            "countryCode": "AU",
            "cityCode": "CWT",
            "cityName": "Cowra",
            "name": "[CWT] - Cowra - CWT - Cowra - Australia",
            "searchName": "CWT-Cowra-Cowra Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CWW",
            "countryCode": "AU",
            "cityCode": "CWW",
            "cityName": "Corowa",
            "name": "[CWW] - Corowa - CWW - Corowa - Australia",
            "searchName": "CWW-Corowa-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CTL",
            "countryCode": "AU",
            "cityCode": "CTL",
            "cityName": "Charleville",
            "name": "[CTL] - Charleville - CTL - Charleville - Australia",
            "searchName": "CTL-Charleville-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CTN",
            "countryCode": "AU",
            "cityCode": "CTN",
            "cityName": "Cooktown",
            "name": "[CTN] - Cooktown - CTN - Cooktown - Australia",
            "searchName": "CTN-Cooktown-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CTR",
            "countryCode": "AU",
            "cityCode": "CTR",
            "cityName": "Cattle Creek",
            "name": "[CTR] - Cattle Creek - CTR - Cattle Creek - Australia",
            "searchName": "CTR-Cattle Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CUD",
            "countryCode": "AU",
            "cityCode": "CUD",
            "cityName": "Caloundra",
            "name": "[CUD] - Caloundra - CUD - Caloundra - Australia",
            "searchName": "CUD-Caloundra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CUG",
            "countryCode": "AU",
            "cityCode": "CUG",
            "cityName": "Cudal",
            "name": "[CUG] - Cudal - CUG - Cudal - Australia",
            "searchName": "CUG-Cudal-Cudal Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CWR",
            "countryCode": "AU",
            "cityCode": "CWR",
            "cityName": "Cowarie",
            "name": "[CWR] - Cowarie - CWR - Cowarie - Australia",
            "searchName": "CWR-Cowarie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CXQ",
            "countryCode": "AU",
            "cityCode": "CXQ",
            "cityName": "Christmas Creek",
            "name": "[CXQ] - Christmas Creek - CXQ - Christmas Creek - Australia",
            "searchName": "CXQ-Christmas Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CXT",
            "countryCode": "AU",
            "cityCode": "CXT",
            "cityName": "Charters Towers",
            "name": "[CXT] - Charters Towers - CXT - Charters Towers - Australia",
            "searchName": "CXT-Charters Towers-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CYG",
            "countryCode": "AU",
            "cityCode": "CYG",
            "cityName": "Corryong",
            "name": "[CYG] - Corryong - CYG - Corryong - Australia",
            "searchName": "CYG-Corryong-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "CZY",
            "countryCode": "AU",
            "cityCode": "CZY",
            "cityName": "Cluny",
            "name": "[CZY] - Cluny - CZY - Cluny - Australia",
            "searchName": "CZY-Cluny-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "DAJ",
            "countryCode": "AU",
            "cityCode": "DAJ",
            "cityName": "Dauan Island",
            "name": "[DAJ] - Dauan Island - DAJ - Dauan Island - Australia",
            "searchName": "DAJ-Dauan Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KML",
            "countryCode": "AU",
            "cityCode": "KML",
            "cityName": "Kamileroi",
            "name": "[KML] - Kamileroi - KML - Kamileroi - Australia",
            "searchName": "KML-Kamileroi-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KKP",
            "countryCode": "AU",
            "cityCode": "KKP",
            "cityName": "Koolburra",
            "name": "[KKP] - Koolburra - KKP - Koolburra - Australia",
            "searchName": "KKP-Koolburra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KGC",
            "countryCode": "AU",
            "cityCode": "KGC",
            "cityName": "Kingscote",
            "name": "[KGC] - Kingscote - KGC - Kingscote - Australia",
            "searchName": "KGC-Kingscote-Kingscote Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KGI",
            "countryCode": "AU",
            "cityCode": "KGI",
            "cityName": "Kalgoorlie",
            "name": "[KGI] - Kalgoorlie - KGI - Kalgoorlie - Australia",
            "searchName": "KGI-Kalgoorlie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KGR",
            "countryCode": "AU",
            "cityCode": "KGR",
            "cityName": "Kulgera",
            "name": "[KGR] - Kulgera - KGR - Kulgera - Australia",
            "searchName": "KGR-Kulgera-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KGY",
            "countryCode": "AU",
            "cityCode": "KGY",
            "cityName": "Kingaroy",
            "name": "[KGY] - Kingaroy - KGY - Kingaroy - Australia",
            "searchName": "KGY-Kingaroy-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KNI",
            "countryCode": "AU",
            "cityCode": "KNI",
            "cityName": "Katanning",
            "name": "[KNI] - Katanning - KNI - Katanning - Australia",
            "searchName": "KNI-Katanning-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KNS",
            "countryCode": "AU",
            "cityCode": "KNS",
            "cityName": "King Island",
            "name": "[KNS] - King Island - KNS - King Island - Australia",
            "searchName": "KNS-King Island-King Adasi Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KNX",
            "countryCode": "AU",
            "cityCode": "KNX",
            "cityName": "Kununurra",
            "name": "[KNX] - Kununurra - KNX - Kununurra - Australia",
            "searchName": "KNX-Kununurra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KOH",
            "countryCode": "AU",
            "cityCode": "KOH",
            "cityName": "Koolatah",
            "name": "[KOH] - Koolatah - KOH - Koolatah - Australia",
            "searchName": "KOH-Koolatah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KPP",
            "countryCode": "AU",
            "cityCode": "KPP",
            "cityName": "Kalpowar",
            "name": "[KPP] - Kalpowar - KPP - Kalpowar - Australia",
            "searchName": "KPP-Kalpowar-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KPS",
            "countryCode": "AU",
            "cityCode": "KPS",
            "cityName": "Kempsey",
            "name": "[KPS] - Kempsey - KPS - Kempsey - Australia",
            "searchName": "KPS-Kempsey-Kempsey Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KRA",
            "countryCode": "AU",
            "cityCode": "KRA",
            "cityName": "Kerang",
            "name": "[KRA] - Kerang - KRA - Kerang - Australia",
            "searchName": "KRA-Kerang-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KRB",
            "countryCode": "AU",
            "cityCode": "KRB",
            "cityName": "Karumba",
            "name": "[KRB] - Karumba - KRB - Karumba - Australia",
            "searchName": "KRB-Karumba-Karumba Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KRD",
            "countryCode": "AU",
            "cityCode": "KRD",
            "cityName": "Kurundi",
            "name": "[KRD] - Kurundi - KRD - Kurundi - Australia",
            "searchName": "KRD-Kurundi-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KSV",
            "countryCode": "AU",
            "cityCode": "KSV",
            "cityName": "Springvale",
            "name": "[KSV] - Springvale - KSV - Springvale - Australia",
            "searchName": "KSV-Springvale-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KTA",
            "countryCode": "AU",
            "cityCode": "KTA",
            "cityName": "Karratha",
            "name": "[KTA] - Karratha - KTA - Karratha - Australia",
            "searchName": "KTA-Dampier-Karratha-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "JUN",
            "countryCode": "AU",
            "cityCode": "JUN",
            "cityName": "Jundah",
            "name": "[JUN] - Jundah - JUN - Jundah - Australia",
            "searchName": "JUN-Jundah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "JUR",
            "countryCode": "AU",
            "cityCode": "JUR",
            "cityName": "Jurien Bay",
            "name": "[JUR] - Jurien Bay - JUR - Jurien Bay - Australia",
            "searchName": "JUR-Jurien Bay-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KBD",
            "countryCode": "AU",
            "cityCode": "KBD",
            "cityName": "Kimberley Downs",
            "name": "[KBD] - Kimberley Downs - KBD - Kimberley Downs - Australia",
            "searchName": "KBD-Kimberley Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KBJ",
            "countryCode": "AU",
            "cityCode": "KBJ",
            "cityName": "Kings Canyon",
            "name": "[KBJ] - Kings Canyon - KBJ - Kings Canyon - Australia",
            "searchName": "KBJ-Kings Canyon-Kings Canyon Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KAX",
            "countryCode": "AU",
            "cityCode": "KAX",
            "cityName": "Kalbarri",
            "name": "[KAX] - Kalbarri - KAX - Kalbarri - Australia",
            "searchName": "KAX-Kalbarri-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KBB",
            "countryCode": "AU",
            "cityCode": "KBB",
            "cityName": "Kirkimbie",
            "name": "[KBB] - Kirkimbie - KBB - Kirkimbie - Australia",
            "searchName": "KBB-Kirkimbie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KCS",
            "countryCode": "AU",
            "cityCode": "KCS",
            "cityName": "Kings Creek Station",
            "name": "[KCS] - Kings Creek Station - KCS - Kings Creek Station - Australia",
            "searchName": "KCS-Kings Creek Station-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KDB",
            "countryCode": "AU",
            "cityCode": "KDB",
            "cityName": "Kambalda",
            "name": "[KDB] - Kambalda - KDB - Kambalda - Australia",
            "searchName": "KDB-Kambalda-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KBY",
            "countryCode": "AU",
            "cityCode": "KBY",
            "cityName": "Streaky Bay",
            "name": "[KBY] - Streaky Bay - KBY - Streaky Bay - Australia",
            "searchName": "KBY-Streaky Bay-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KCE",
            "countryCode": "AU",
            "cityCode": "KCE",
            "cityName": "Collinsville",
            "name": "[KCE] - Collinsville - KCE - Collinsville - Australia",
            "searchName": "KCE-Collinsville-Collinsville Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KDS",
            "countryCode": "AU",
            "cityCode": "KDS",
            "cityName": "Kamaran Downs",
            "name": "[KDS] - Kamaran Downs - KDS - Kamaran Downs - Australia",
            "searchName": "KDS-Kamaran Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KFE",
            "countryCode": "AU",
            "cityCode": "KFE",
            "cityName": "Cloudbreak",
            "name": "[KFE] - Fortescue Dave Forrest - KFE - Cloudbreak - Australia",
            "searchName": "KFE-Fortescue Dave Forrest-Cloudbreak-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KFG",
            "countryCode": "AU",
            "cityCode": "KFG",
            "cityName": "Kalkurung",
            "name": "[KFG] - Kalkurung - KFG - Kalkurung - Australia",
            "searchName": "KFG-Kalkurung-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "JCK",
            "countryCode": "AU",
            "cityCode": "JCK",
            "cityName": "Julia Creek",
            "name": "[JCK] - Julia Creek - JCK - Julia Creek - Australia",
            "searchName": "JCK-Julia Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "JFM",
            "countryCode": "AU",
            "cityCode": "JFM",
            "cityName": "Fremantle",
            "name": "[JFM] - Heliport - JFM - Fremantle - Australia",
            "searchName": "JFM-Hubschrauberlandeplatz-Heliport-Fremantle-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LOA",
            "countryCode": "AU",
            "cityCode": "LOA",
            "cityName": "Lorraine",
            "name": "[LOA] - Lorraine - LOA - Lorraine - Australia",
            "searchName": "LOA-Lorraine-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LOC",
            "countryCode": "AU",
            "cityCode": "LOC",
            "cityName": "Lock",
            "name": "[LOC] - Lock - LOC - Lock - Australia",
            "searchName": "LOC-Lock-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LNO",
            "countryCode": "AU",
            "cityCode": "LNO",
            "cityName": "Leonora",
            "name": "[LNO] - Leonora - LNO - Leonora - Australia",
            "searchName": "LNO-Leonora-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LLP",
            "countryCode": "AU",
            "cityCode": "LLP",
            "cityName": "Linda Downs",
            "name": "[LLP] - Linda Downs - LLP - Linda Downs - Australia",
            "searchName": "LLP-Linda Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LNH",
            "countryCode": "AU",
            "cityCode": "LNH",
            "cityName": "Lake Nash",
            "name": "[LNH] - Lake Nash - LNH - Lake Nash - Australia",
            "searchName": "LNH-Lake Nash-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LKD",
            "countryCode": "AU",
            "cityCode": "LKD",
            "cityName": "Lakeland Downs",
            "name": "[LKD] - Lakeland Downs - LKD - Lakeland Downs - Australia",
            "searchName": "LKD-Lakeland Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LLG",
            "countryCode": "AU",
            "cityCode": "LLG",
            "cityName": "Chillagoe",
            "name": "[LLG] - Chillagoe - LLG - Chillagoe - Australia",
            "searchName": "LLG-Chillagoe-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LLL",
            "countryCode": "AU",
            "cityCode": "LLL",
            "cityName": "Lissadell",
            "name": "[LLL] - Lissadell - LLL - Lissadell - Australia",
            "searchName": "LLL-Lissadell-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LUT",
            "countryCode": "AU",
            "cityCode": "LUT",
            "cityName": "Laura Station",
            "name": "[LUT] - Laura Station - LUT - Laura Station - Australia",
            "searchName": "LUT-Laura Station-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LUU",
            "countryCode": "AU",
            "cityCode": "LUU",
            "cityName": "Laura",
            "name": "[LUU] - Laura - LUU - Laura - Australia",
            "searchName": "LUU-Laura-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LVO",
            "countryCode": "AU",
            "cityCode": "LVO",
            "cityName": "Laverton",
            "name": "[LVO] - Laverton - LVO - Laverton - Australia",
            "searchName": "LVO-Laverton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LWH",
            "countryCode": "AU",
            "cityCode": "LWH",
            "cityName": "Lawn Hill",
            "name": "[LWH] - Lawn Hill - LWH - Lawn Hill - Australia",
            "searchName": "LWH-Lawn Hill-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LZR",
            "countryCode": "AU",
            "cityCode": "LZR",
            "cityName": "Lizard Island",
            "name": "[LZR] - Lizard Island - LZR - Lizard Island - Australia",
            "searchName": "LZR-Lizard Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LYT",
            "countryCode": "AU",
            "cityCode": "LYT",
            "cityName": "Lady Elliot Island",
            "name": "[LYT] - Lady Elliot Island - LYT - Lady Elliot Island - Australia",
            "searchName": "LYT-Lady Elliot Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LRE",
            "countryCode": "AU",
            "cityCode": "LRE",
            "cityName": "Longreach",
            "name": "[LRE] - Longreach - LRE - Longreach - Australia",
            "searchName": "LRE-Longreach-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LST",
            "countryCode": "AU",
            "cityCode": "LST",
            "cityName": "Launceston",
            "name": "[LST] - Launceston - LST - Launceston - Australia",
            "searchName": "LST-Launceston-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LSY",
            "countryCode": "AU",
            "cityCode": "LSY",
            "cityName": "Lismore",
            "name": "[LSY] - Lismore - LSY - Lismore - Australia",
            "searchName": "LSY-Lismore-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LTB",
            "countryCode": "AU",
            "cityCode": "LTB",
            "cityName": "Latrobe",
            "name": "[LTB] - Latrobe - LTB - Latrobe - Australia",
            "searchName": "LTB-Latrobe-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LTP",
            "countryCode": "AU",
            "cityCode": "LTP",
            "cityName": "Lyndhurst",
            "name": "[LTP] - Lyndhurst - LTP - Lyndhurst - Australia",
            "searchName": "LTP-Lyndhurst-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LTV",
            "countryCode": "AU",
            "cityCode": "LTV",
            "cityName": "Lotusvale",
            "name": "[LTV] - Lotusvale - LTV - Lotusvale - Australia",
            "searchName": "LTV-Lotusvale-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LIB",
            "countryCode": "AU",
            "cityCode": "LIB",
            "cityName": "Limbunya",
            "name": "[LIB] - Limbunya - LIB - Limbunya - Australia",
            "searchName": "LIB-Limbunya-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LHG",
            "countryCode": "AU",
            "cityCode": "LHG",
            "cityName": "Lightning Ridge",
            "name": "[LHG] - Lightning Ridge - LHG - Lightning Ridge - Australia",
            "searchName": "LHG-Lightning Ridge-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LGE",
            "countryCode": "AU",
            "cityCode": "LGE",
            "cityName": "Lake Gregory",
            "name": "[LGE] - Lake Gregory - LGE - Lake Gregory - Australia",
            "searchName": "LGE-Lake Gregory-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LGH",
            "countryCode": "AU",
            "cityCode": "LGH",
            "cityName": "Leigh Creek",
            "name": "[LGH] - Leigh Creek - LGH - Leigh Creek - Australia",
            "searchName": "LGH-Leigh Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LFP",
            "countryCode": "AU",
            "cityCode": "LFP",
            "cityName": "Lakefield",
            "name": "[LFP] - Lakefield - LFP - Lakefield - Australia",
            "searchName": "LFP-Lakefield-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LCN",
            "countryCode": "AU",
            "cityCode": "LCN",
            "cityName": "Balcanoona",
            "name": "[LCN] - Balcanoona - LCN - Balcanoona - Australia",
            "searchName": "LCN-Balcanoona-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LDC",
            "countryCode": "AU",
            "cityCode": "LDC",
            "cityName": "Lindeman Island",
            "name": "[LDC] - Lindeman Island - LDC - Lindeman Island - Australia",
            "searchName": "LDC-Lindeman Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LDH",
            "countryCode": "AU",
            "cityCode": "LDH",
            "cityName": "Lord Howe Island",
            "name": "[LDH] - Lord Howe Island - LDH - Lord Howe Island - Australia",
            "searchName": "LDH-Lord Howe Island--Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LDW",
            "countryCode": "AU",
            "cityCode": "LDW",
            "cityName": "Lansdowne",
            "name": "[LDW] - Lansdowne - LDW - Lansdowne - Australia",
            "searchName": "LDW-Lansdowne-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LEA",
            "countryCode": "AU",
            "cityCode": "LEA",
            "cityName": "Learmonth",
            "name": "[LEA] - Learmonth - LEA - Learmonth - Australia",
            "searchName": "LEA-Learmouth-Learmonth-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LEL",
            "countryCode": "AU",
            "cityCode": "LEL",
            "cityName": "Lake Evella",
            "name": "[LEL] - Lake Evella - LEL - Lake Evella - Australia",
            "searchName": "LEL-Lake Evella-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "LER",
            "countryCode": "AU",
            "cityCode": "LER",
            "cityName": "Leinster",
            "name": "[LER] - Leinster - LER - Leinster - Australia",
            "searchName": "LER-Leinster-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KYF",
            "countryCode": "AU",
            "cityCode": "KYF",
            "cityName": "Yeelirrie",
            "name": "[KYF] - Yeelirrie - KYF - Yeelirrie - Australia",
            "searchName": "KYF-Yeelirrie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KYI",
            "countryCode": "AU",
            "cityCode": "KYI",
            "cityName": "Yalata Mission",
            "name": "[KYI] - Yalata Mission - KYI - Yalata Mission - Australia",
            "searchName": "KYI-Yalata Mission-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KWM",
            "countryCode": "AU",
            "cityCode": "KWM",
            "cityName": "Kowanyama",
            "name": "[KWM] - Kowanyama - KWM - Kowanyama - Australia",
            "searchName": "KWM-Kowanyama-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KTR",
            "countryCode": "AU",
            "cityCode": "KTR",
            "cityName": "Katherine",
            "name": "[KTR] - Tindal - KTR - Katherine - Australia",
            "searchName": "KTR-Tindal-Katherine-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "KUG",
            "countryCode": "AU",
            "cityCode": "KUG",
            "cityName": "Kubin Island",
            "name": "[KUG] - Kubin Island - KUG - Kubin Island - Australia",
            "searchName": "KUG-Kubin Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "INJ",
            "countryCode": "AU",
            "cityCode": "INJ",
            "cityName": "Injune",
            "name": "[INJ] - Injune - INJ - Injune - Australia",
            "searchName": "INJ-Injune-Injune Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "INM",
            "countryCode": "AU",
            "cityCode": "INM",
            "cityName": "Innamincka",
            "name": "[INM] - Innamincka - INM - Innamincka - Australia",
            "searchName": "INM-Innamincka-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "IRG",
            "countryCode": "AU",
            "cityCode": "IRG",
            "cityName": "Lockhart River",
            "name": "[IRG] - Lockhart River - IRG - Lockhart River - Australia",
            "searchName": "IRG-Lockhart River-Lockhart Nehri-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ISA",
            "countryCode": "AU",
            "cityCode": "ISA",
            "cityName": "Mount Isa",
            "name": "[ISA] - Mount Isa - ISA - Mount Isa - Australia",
            "searchName": "ISA-Mount Isa-Mont Isa-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "JAB",
            "countryCode": "AU",
            "cityCode": "JAB",
            "cityName": "Jabiru",
            "name": "[JAB] - Jabiru - JAB - Jabiru - Australia",
            "searchName": "JAB-Jabiru-Jabiru Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ISI",
            "countryCode": "AU",
            "cityCode": "ISI",
            "cityName": "Isisford",
            "name": "[ISI] - Isisford - ISI - Isisford - Australia",
            "searchName": "ISI-Isisford-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "IVR",
            "countryCode": "AU",
            "cityCode": "IVR",
            "cityName": "Inverell",
            "name": "[IVR] - Inverell - IVR - Inverell - Australia",
            "searchName": "IVR-Inverell-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "IVW",
            "countryCode": "AU",
            "cityCode": "IVW",
            "cityName": "Inverway",
            "name": "[IVW] - Inverway - IVW - Inverway - Australia",
            "searchName": "IVW-Inverway-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "IKP",
            "countryCode": "AU",
            "cityCode": "IKP",
            "cityName": "Inkerman",
            "name": "[IKP] - Inkerman - IKP - Inkerman - Australia",
            "searchName": "IKP-Inkerman-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "IGH",
            "countryCode": "AU",
            "cityCode": "IGH",
            "cityName": "Ingham",
            "name": "[IGH] - Ingham - IGH - Ingham - Australia",
            "searchName": "IGH-Ingham-Ingham Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "IFF",
            "countryCode": "AU",
            "cityCode": "IFF",
            "cityName": "Iffley",
            "name": "[IFF] - Iffley - IFF - Iffley - Australia",
            "searchName": "IFF-Iffley-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "IFL",
            "countryCode": "AU",
            "cityCode": "IFL",
            "cityName": "Innisfail",
            "name": "[IFL] - Innisfail - IFL - Innisfail - Australia",
            "searchName": "IFL-Innisfail-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "IDK",
            "countryCode": "AU",
            "cityCode": "IDK",
            "cityName": "Indulkana",
            "name": "[IDK] - Indulkana - IDK - Indulkana - Australia",
            "searchName": "IDK-Indulkana-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HOK",
            "countryCode": "AU",
            "cityCode": "HOK",
            "cityName": "Hooker Creek",
            "name": "[HOK] - Hooker Creek - HOK - Hooker Creek - Australia",
            "searchName": "HOK-Hooker Creek-Hooker-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HPE",
            "countryCode": "AU",
            "cityCode": "HPE",
            "cityName": "Hope Vale",
            "name": "[HPE] - Hope Vale - HPE - Hope Vale - Australia",
            "searchName": "HPE-Hope Vale-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HRN",
            "countryCode": "AU",
            "cityCode": "HRN",
            "cityName": "Heron Island",
            "name": "[HRN] - Heliport - HRN - Heron Island - Australia",
            "searchName": "HRN-Heliport-Heron Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HRY",
            "countryCode": "AU",
            "cityCode": "HRY",
            "cityName": "Henbury",
            "name": "[HRY] - Henbury - HRY - Henbury - Australia",
            "searchName": "HRY-Henbury-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HSM",
            "countryCode": "AU",
            "cityCode": "HSM",
            "cityName": "Horsham",
            "name": "[HSM] - Horsham - HSM - Horsham - Australia",
            "searchName": "HSM-Horsham-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HTI",
            "countryCode": "AU",
            "cityCode": "HTI",
            "cityName": "Hamilton Island",
            "name": "[HTI] - Hamilton Island - HTI - Hamilton Island - Australia",
            "searchName": "HTI-Hamilton Island--Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HTU",
            "countryCode": "AU",
            "cityCode": "HTU",
            "cityName": "Hopetoun",
            "name": "[HTU] - Hopetoun - HTU - Hopetoun - Australia",
            "searchName": "HTU-Hopetoun-Hopetown-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HUB",
            "countryCode": "AU",
            "cityCode": "HUB",
            "cityName": "Humbert River",
            "name": "[HUB] - Humbert River - HUB - Humbert River - Australia",
            "searchName": "HUB-Humbert River-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HVB",
            "countryCode": "AU",
            "cityCode": "HVB",
            "cityName": "Hervey Bay",
            "name": "[HVB] - Hervey Bay - HVB - Hervey Bay - Australia",
            "searchName": "HVB-Hervey Bay-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HWK",
            "countryCode": "AU",
            "cityCode": "HWK",
            "cityName": "Hawker",
            "name": "[HWK] - Wilpena Pound - HWK - Hawker - Australia",
            "searchName": "HWK-Wilpena Pound-Hawker-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HXX",
            "countryCode": "AU",
            "cityCode": "HXX",
            "cityName": "Hay",
            "name": "[HXX] - Hay - HXX - Hay - Australia",
            "searchName": "HXX-Hay-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HGD",
            "countryCode": "AU",
            "cityCode": "HGD",
            "cityName": "Hughenden",
            "name": "[HGD] - Hughenden - HGD - Hughenden - Australia",
            "searchName": "HGD-Hughenden-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HBA",
            "countryCode": "AU",
            "cityCode": "HBA",
            "cityName": "Hobart",
            "name": "[HBA] - Hobart - HBA - Hobart - Australia",
            "searchName": "HBA-Hobart-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HCQ",
            "countryCode": "AU",
            "cityCode": "HCQ",
            "cityName": "Halls Creek",
            "name": "[HCQ] - Halls Creek - HCQ - Halls Creek - Australia",
            "searchName": "HCQ-Halls Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HID",
            "countryCode": "AU",
            "cityCode": "HID",
            "cityName": "Horn Island",
            "name": "[HID] - Horn Island - HID - Horn Island - Australia",
            "searchName": "HID-Horn Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HIG",
            "countryCode": "AU",
            "cityCode": "HIG",
            "cityName": "Highbury",
            "name": "[HIG] - Highbury - HIG - Highbury - Australia",
            "searchName": "HIG-Highbury-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HIH",
            "countryCode": "AU",
            "cityCode": "HIH",
            "cityName": "Hook Island",
            "name": "[HIH] - Hook Island - HIH - Hook Island - Australia",
            "searchName": "HIH-Hook Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HIP",
            "countryCode": "AU",
            "cityCode": "HIP",
            "cityName": "Headingly",
            "name": "[HIP] - Headingly - HIP - Headingly - Australia",
            "searchName": "HIP-Headingly-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HIS",
            "countryCode": "AU",
            "cityCode": "HIS",
            "cityName": "Hayman Island",
            "name": "[HIS] - Hayman Island - HIS - Hayman Island - Australia",
            "searchName": "HIS-Hayman Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HLL",
            "countryCode": "AU",
            "cityCode": "HLL",
            "cityName": "Hillside",
            "name": "[HLL] - Hillside - HLL - Hillside - Australia",
            "searchName": "HLL-Hillside-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HLS",
            "countryCode": "AU",
            "cityCode": "HLS",
            "cityName": "St Helens",
            "name": "[HLS] - St Helens - HLS - St Helens - Australia",
            "searchName": "HLS-St Helens-St Helens Havalimani-St. Helens-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HLT",
            "countryCode": "AU",
            "cityCode": "HLT",
            "cityName": "Hamilton",
            "name": "[HLT] - Hamilton - HLT - Hamilton - Australia",
            "searchName": "HLT-Hamilton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HLV",
            "countryCode": "AU",
            "cityCode": "HLV",
            "cityName": "Helenvale",
            "name": "[HLV] - Helenvale - HLV - Helenvale - Australia",
            "searchName": "HLV-Helenvale-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HMG",
            "countryCode": "AU",
            "cityCode": "HMG",
            "cityName": "Hermannsburg",
            "name": "[HMG] - Hermannsburg - HMG - Hermannsburg - Australia",
            "searchName": "HMG-Hermannsburg-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GSC",
            "countryCode": "AU",
            "cityCode": "GSC",
            "cityName": "Gascoyne Junction",
            "name": "[GSC] - Gascoyne Junction - GSC - Gascoyne Junction - Australia",
            "searchName": "GSC-Gascoyne Junction-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GSN",
            "countryCode": "AU",
            "cityCode": "GSN",
            "cityName": "Mount Gunson",
            "name": "[GSN] - Mount Gunson - GSN - Mount Gunson - Australia",
            "searchName": "GSN-Mount Gunson-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GPD",
            "countryCode": "AU",
            "cityCode": "GPD",
            "cityName": "Mount Gordon Mine",
            "name": "[GPD] - Mount Gordon Mine - GPD - Mount Gordon Mine - Australia",
            "searchName": "GPD-Mount Gordon Mine-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GPN",
            "countryCode": "AU",
            "cityCode": "GPN",
            "cityName": "Garden Point",
            "name": "[GPN] - Garden Point - GPN - Garden Point - Australia",
            "searchName": "GPN-Garden Point-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GTE",
            "countryCode": "AU",
            "cityCode": "GTE",
            "cityName": "Groote Eylandt",
            "name": "[GTE] - Alyangula - GTE - Groote Eylandt - Australia",
            "searchName": "GTE-Groote Eylandt-Alyangula-Alyangula Arpt-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GTS",
            "countryCode": "AU",
            "cityCode": "GTS",
            "cityName": "Granites",
            "name": "[GTS] - Granites - GTS - Granites - Australia",
            "searchName": "GTS-Granites-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GTT",
            "countryCode": "AU",
            "cityCode": "GTT",
            "cityName": "Georgetown",
            "name": "[GTT] - Georgetown - GTT - Georgetown - Australia",
            "searchName": "GTT-Georgetown-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GUL",
            "countryCode": "AU",
            "cityCode": "GUL",
            "cityName": "Goulburn",
            "name": "[GUL] - Goulburn - GUL - Goulburn - Australia",
            "searchName": "GUL-Goulburn-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GUH",
            "countryCode": "AU",
            "cityCode": "GUH",
            "cityName": "Gunnedah",
            "name": "[GUH] - Gunnedah - GUH - Gunnedah - Australia",
            "searchName": "GUH-Gunnedah-Gunnedah Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GYL",
            "countryCode": "AU",
            "cityCode": "GYL",
            "cityName": "Argyle",
            "name": "[GYL] - Argyle - GYL - Argyle - Australia",
            "searchName": "GYL-Argyle-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GVP",
            "countryCode": "AU",
            "cityCode": "GVP",
            "cityName": "Greenvale",
            "name": "[GVP] - Greenvale - GVP - Greenvale - Australia",
            "searchName": "GVP-Greenvale-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GYP",
            "countryCode": "AU",
            "cityCode": "GYP",
            "cityName": "Gympie",
            "name": "[GYP] - Gympie - GYP - Gympie - Australia",
            "searchName": "GYP-Gympie-Gympie Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "HAP",
            "countryCode": "AU",
            "cityCode": "HAP",
            "cityName": "Long Island",
            "name": "[HAP] - Long Island - HAP - Long Island - Australia",
            "searchName": "HAP-Long Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GOS",
            "countryCode": "AU",
            "cityCode": "GOS",
            "cityName": "Gosford",
            "name": "[GOS] - Gosford - GOS - Gosford - Australia",
            "searchName": "GOS-Gosford-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GOV",
            "countryCode": "AU",
            "cityCode": "GOV",
            "cityName": "Gove",
            "name": "[GOV] - Nhulunbuy - GOV - Gove - Australia",
            "searchName": "GOV-Nhulunbuy-Gove-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GOO",
            "countryCode": "AU",
            "cityCode": "GOO",
            "cityName": "Goondiwindi",
            "name": "[GOO] - Goondiwindi - GOO - Goondiwindi - Australia",
            "searchName": "GOO-Goondiwindi-Goondiwindi Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GLM",
            "countryCode": "AU",
            "cityCode": "GLM",
            "cityName": "Glenormiston",
            "name": "[GLM] - Glenormiston - GLM - Glenormiston - Australia",
            "searchName": "GLM-Glenormiston-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GLT",
            "countryCode": "AU",
            "cityCode": "GLT",
            "cityName": "Gladstone",
            "name": "[GLT] - Gladstone - GLT - Gladstone - Australia",
            "searchName": "GLT-Gladstone-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GLY",
            "countryCode": "AU",
            "cityCode": "GLY",
            "cityName": "Goldsworthy",
            "name": "[GLY] - Goldsworthy - GLY - Goldsworthy - Australia",
            "searchName": "GLY-Goldsworthy-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GGD",
            "countryCode": "AU",
            "cityCode": "GGD",
            "cityName": "Gregory Downs",
            "name": "[GGD] - Gregory Downs - GGD - Gregory Downs - Australia",
            "searchName": "GGD-Gregory Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GFN",
            "countryCode": "AU",
            "cityCode": "GFN",
            "cityName": "Grafton",
            "name": "[GFN] - Grafton - GFN - Grafton - Australia",
            "searchName": "GFN-Grafton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GEX",
            "countryCode": "AU",
            "cityCode": "GEX",
            "cityName": "Geelong",
            "name": "[GEX] - Geelong - GEX - Geelong - Australia",
            "searchName": "GEX-Geelong-Geelong Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GFE",
            "countryCode": "AU",
            "cityCode": "GFE",
            "cityName": "Grenfell",
            "name": "[GFE] - Grenfell - GFE - Grenfell - Australia",
            "searchName": "GFE-Grenfell-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GFF",
            "countryCode": "AU",
            "cityCode": "GFF",
            "cityName": "Griffith",
            "name": "[GFF] - Griffith - GFF - Griffith - Australia",
            "searchName": "GFF-Griffith-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GIC",
            "countryCode": "AU",
            "cityCode": "GIC",
            "cityName": "Boigu Island",
            "name": "[GIC] - Boigu Island - GIC - Boigu Island - Australia",
            "searchName": "GIC-Boigu Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GKL",
            "countryCode": "AU",
            "cityCode": "GKL",
            "cityName": "Great Keppel Island",
            "name": "[GKL] - Great Keppel Island - GKL - Great Keppel Island - Australia",
            "searchName": "GKL-Great Keppel Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GLG",
            "countryCode": "AU",
            "cityCode": "GLG",
            "cityName": "Glengyle",
            "name": "[GLG] - Glengyle - GLG - Glengyle - Australia",
            "searchName": "GLG-Glengyle-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GLI",
            "countryCode": "AU",
            "cityCode": "GLI",
            "cityName": "Glen Innes",
            "name": "[GLI] - Glen Innes - GLI - Glen Innes - Australia",
            "searchName": "GLI-Glen Innes-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FSL",
            "countryCode": "AU",
            "cityCode": "FSL",
            "cityName": "Fossil Downs",
            "name": "[FSL] - Fossil Downs - FSL - Fossil Downs - Australia",
            "searchName": "FSL-Fossil Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FVL",
            "countryCode": "AU",
            "cityCode": "FVL",
            "cityName": "Flora Valley",
            "name": "[FVL] - Flora Valey - FVL - Flora Valley - Australia",
            "searchName": "FVL-Flora Valey-Flora Valley-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FVR",
            "countryCode": "AU",
            "cityCode": "FVR",
            "cityName": "Forrest River",
            "name": "[FVR] - Forrest River Airport - FVR - Forrest River - Australia",
            "searchName": "FVR-Forrest River Airport-Forrest River-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GAH",
            "countryCode": "AU",
            "cityCode": "GAH",
            "cityName": "Gayndah",
            "name": "[GAH] - Gayndah - GAH - Gayndah - Australia",
            "searchName": "GAH-Gayndah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GBL",
            "countryCode": "AU",
            "cityCode": "GBL",
            "cityName": "Goulburn Island",
            "name": "[GBL] - Goulburn Island - GBL - Goulburn Island - Australia",
            "searchName": "GBL-Goulburn Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GBP",
            "countryCode": "AU",
            "cityCode": "GBP",
            "cityName": "Gamboola",
            "name": "[GBP] - Gamboola - GBP - Gamboola - Australia",
            "searchName": "GBP-Gamboola-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GBV",
            "countryCode": "AU",
            "cityCode": "GBV",
            "cityName": "Gibb River",
            "name": "[GBV] - Gibb River - GBV - Gibb River - Australia",
            "searchName": "GBV-Gibb River-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GDD",
            "countryCode": "AU",
            "cityCode": "GDD",
            "cityName": "Gordon Downs",
            "name": "[GDD] - Gordon Downs - GDD - Gordon Downs - Australia",
            "searchName": "GDD-Gordon Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GEE",
            "countryCode": "AU",
            "cityCode": "GEE",
            "cityName": "George Town",
            "name": "[GEE] - George Town - GEE - George Town - Australia",
            "searchName": "GEE-George Town-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "GET",
            "countryCode": "AU",
            "cityCode": "GET",
            "cityName": "Geraldton",
            "name": "[GET] - Geraldton - GET - Geraldton - Australia",
            "searchName": "GET-Geraldton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FLS",
            "countryCode": "AU",
            "cityCode": "FLS",
            "cityName": "Flinders Island",
            "name": "[FLS] - Flinders Island - FLS - Flinders Island - Australia",
            "searchName": "FLS-Flinders Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FLC",
            "countryCode": "AU",
            "cityCode": "FLC",
            "cityName": "Falls Creek",
            "name": "[FLC] - Falls Creek - FLC - Falls Creek - Australia",
            "searchName": "FLC-Falls Creek-Falls Creek Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FIZ",
            "countryCode": "AU",
            "cityCode": "FIZ",
            "cityName": "Fitzroy Crossing",
            "name": "[FIZ] - Fitzroy Crossing - FIZ - Fitzroy Crossing - Australia",
            "searchName": "FIZ-Fitzroy Crossing-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FIK",
            "countryCode": "AU",
            "cityCode": "FIK",
            "cityName": "Finke",
            "name": "[FIK] - Finke - FIK - Finke - Australia",
            "searchName": "FIK-Finke-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FLY",
            "countryCode": "AU",
            "cityCode": "FLY",
            "cityName": "Finley",
            "name": "[FLY] - Finley - FLY - Finley - Australia",
            "searchName": "FLY-Finley-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FOS",
            "countryCode": "AU",
            "cityCode": "FOS",
            "cityName": "Forrest",
            "name": "[FOS] - Forrest - FOS - Forrest - Australia",
            "searchName": "FOS-Forrest-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FOT",
            "countryCode": "AU",
            "cityCode": "FOT",
            "cityName": "Forster",
            "name": "[FOT] - Forster - FOT - Forster - Australia",
            "searchName": "FOT-Forster-Forster Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "FRB",
            "countryCode": "AU",
            "cityCode": "FRB",
            "cityName": "Forbes",
            "name": "[FRB] - Forbes - FRB - Forbes - Australia",
            "searchName": "FRB-Forbes-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SYU",
            "countryCode": "AU",
            "cityCode": "SYU",
            "cityName": "Sue Island",
            "name": "[SYU] - Warraber Island - SYU - Sue Island - Australia",
            "searchName": "SYU-Warraber Island-Sue Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SXE",
            "countryCode": "AU",
            "cityCode": "SXE",
            "cityName": "Sale",
            "name": "[SXE] - Sale - SXE - Sale - Australia",
            "searchName": "SXE-Sale-Sale Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TAN",
            "countryCode": "AU",
            "cityCode": "TAN",
            "cityName": "Tangalooma",
            "name": "[TAN] - Tangalooma - TAN - Tangalooma - Australia",
            "searchName": "TAN-Tangalooma-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TAQ",
            "countryCode": "AU",
            "cityCode": "TAQ",
            "cityName": "Tarcoola",
            "name": "[TAQ] - Tarcoola - TAQ - Tarcoola - Australia",
            "searchName": "TAQ-Tarcoola-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TBK",
            "countryCode": "AU",
            "cityCode": "TBK",
            "cityName": "Timber Creek",
            "name": "[TBK] - Timber Creek - TBK - Timber Creek - Australia",
            "searchName": "TBK-Timber Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TBL",
            "countryCode": "AU",
            "cityCode": "TBL",
            "cityName": "Tableland",
            "name": "[TBL] - Tableland - TBL - Tableland - Australia",
            "searchName": "TBL-Tableland-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TCA",
            "countryCode": "AU",
            "cityCode": "TCA",
            "cityName": "Tennant Creek",
            "name": "[TCA] - Tennant Creek - TCA - Tennant Creek - Australia",
            "searchName": "TCA-Tennant Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TCW",
            "countryCode": "AU",
            "cityCode": "TCW",
            "cityName": "Tocumwal",
            "name": "[TCW] - Tocumwal - TCW - Tocumwal - Australia",
            "searchName": "TCW-Tocumwal-Tocumwal Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TDN",
            "countryCode": "AU",
            "cityCode": "TDN",
            "cityName": "Theda",
            "name": "[TDN] - Theda Station - TDN - Theda - Australia",
            "searchName": "TDN-Theda Station-Theda-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TDR",
            "countryCode": "AU",
            "cityCode": "TDR",
            "cityName": "Theodore",
            "name": "[TDR] - Theodore - TDR - Theodore - Australia",
            "searchName": "TDR-Theodore-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TEF",
            "countryCode": "AU",
            "cityCode": "TEF",
            "cityName": "Telfer",
            "name": "[TEF] - Telfer - TEF - Telfer - Australia",
            "searchName": "TEF-Telfer-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TEM",
            "countryCode": "AU",
            "cityCode": "TEM",
            "cityName": "Temora",
            "name": "[TEM] - Temora - TEM - Temora - Australia",
            "searchName": "TEM-Temora-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TGN",
            "countryCode": "AU",
            "cityCode": "TGN",
            "cityName": "Traralgon",
            "name": "[TGN] - La Trobe Regional - TGN - Traralgon - Australia",
            "searchName": "TGN-La Trobe-La Trobe Regional-Traralgon-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "THG",
            "countryCode": "AU",
            "cityCode": "THG",
            "cityName": "Thangool",
            "name": "[THG] - Thangool - THG - Thangool - Australia",
            "searchName": "THG-Thangool-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TIS",
            "countryCode": "AU",
            "cityCode": "TIS",
            "cityName": "Thursday Island",
            "name": "[TIS] - Thursday Island - TIS - Thursday Island - Australia",
            "searchName": "TIS-Thursday Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TUM",
            "countryCode": "AU",
            "cityCode": "TUM",
            "cityName": "Tumut",
            "name": "[TUM] - Tumut - TUM - Tumut - Australia",
            "searchName": "TUM-Tumut-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TWB",
            "countryCode": "AU",
            "cityCode": "TWB",
            "cityName": "Toowoomba",
            "name": "[TWB] - Toowoomba - TWB - Toowoomba - Australia",
            "searchName": "TWB-Toowoomba-Toowoomba Arpt-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TWN",
            "countryCode": "AU",
            "cityCode": "TWN",
            "cityName": "Tewantin",
            "name": "[TWN] - Tewantin - TWN - Tewantin - Australia",
            "searchName": "TWN-Tewantin-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TWP",
            "countryCode": "AU",
            "cityCode": "TWP",
            "cityName": "Torwood",
            "name": "[TWP] - Torwood - TWP - Torwood - Australia",
            "searchName": "TWP-Torwood-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TXR",
            "countryCode": "AU",
            "cityCode": "TXR",
            "cityName": "Tanbar",
            "name": "[TXR] - Tanbar - TXR - Tanbar - Australia",
            "searchName": "TXR-Tanbar-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TYB",
            "countryCode": "AU",
            "cityCode": "TYB",
            "cityName": "Tibooburra",
            "name": "[TYB] - Tibooburra - TYB - Tibooburra - Australia",
            "searchName": "TYB-Tibooburra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TYG",
            "countryCode": "AU",
            "cityCode": "TYG",
            "cityName": "Thylungra",
            "name": "[TYG] - Thylungra - TYG - Thylungra - Australia",
            "searchName": "TYG-Thylungra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TYP",
            "countryCode": "AU",
            "cityCode": "TYP",
            "cityName": "Tobermorey",
            "name": "[TYP] - Tobermorey - TYP - Tobermorey - Australia",
            "searchName": "TYP-Tobermorey-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TSV",
            "countryCode": "AU",
            "cityCode": "TSV",
            "cityName": "Townsville",
            "name": "[TSV] - Townsville International - TSV - Townsville - Australia",
            "searchName": "TSV-Townsville-Townsville International-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TRO",
            "countryCode": "AU",
            "cityCode": "TRO",
            "cityName": "Taree",
            "name": "[TRO] - Taree - TRO - Taree - Australia",
            "searchName": "TRO-Taree-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TPR",
            "countryCode": "AU",
            "cityCode": "TPR",
            "cityName": "Tom Price",
            "name": "[TPR] - Tom Price - TPR - Tom Price - Australia",
            "searchName": "TPR-Tom Price-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TKY",
            "countryCode": "AU",
            "cityCode": "TKY",
            "cityName": "Turkey Creek",
            "name": "[TKY] - Turkey Creek - TKY - Turkey Creek - Australia",
            "searchName": "TKY-Turkey Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "TMW",
            "countryCode": "AU",
            "cityCode": "TMW",
            "cityName": "Tamworth",
            "name": "[TMW] - Tamworth Regional Airport - TMW - Tamworth - Australia",
            "searchName": "TMW-Tamworth-Tamworth Regional Airport-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SLJ",
            "countryCode": "AU",
            "cityCode": "SLJ",
            "cityName": "Chandler",
            "name": "[SLJ] - Stellar Air Park - SLJ - Chandler - Australia",
            "searchName": "SLJ-Stellar Air Park-Chandler-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SIX",
            "countryCode": "AU",
            "cityCode": "SIX",
            "cityName": "Singleton",
            "name": "[SIX] - Singleton - SIX - Singleton - Australia",
            "searchName": "SIX-Singleton-Singleton Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SGO",
            "countryCode": "AU",
            "cityCode": "SGO",
            "cityName": "St George",
            "name": "[SGO] - St George - SGO - St George - Australia",
            "searchName": "SGO-St George-St George Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SGP",
            "countryCode": "AU",
            "cityCode": "SGP",
            "cityName": "Shay Gap",
            "name": "[SGP] - Shay Gap - SGP - Shay Gap - Australia",
            "searchName": "SGP-Shay Gap-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SHQ",
            "countryCode": "AU",
            "cityCode": "SHQ",
            "cityName": "Southport",
            "name": "[SHQ] - Southport - SHQ - Southport - Australia",
            "searchName": "SHQ-Southport-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SHT",
            "countryCode": "AU",
            "cityCode": "SHT",
            "cityName": "Shepparton",
            "name": "[SHT] - Shepparton - SHT - Shepparton - Australia",
            "searchName": "SHT-Shepparton-Shepparton Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SHU",
            "countryCode": "AU",
            "cityCode": "SHU",
            "cityName": "Smith Point",
            "name": "[SHU] - Smith Point - SHU - Smith Point - Australia",
            "searchName": "SHU-Smith Point-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SIO",
            "countryCode": "AU",
            "cityCode": "SIO",
            "cityName": "Smithton",
            "name": "[SIO] - Smithton - SIO - Smithton - Australia",
            "searchName": "SIO-Smithton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SFP",
            "countryCode": "AU",
            "cityCode": "SFP",
            "cityName": "Surfers Paradise",
            "name": "[SFP] - Surfers Paradise - SFP - Surfers Paradise - Australia",
            "searchName": "SFP-Surfers Paradise-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SCG",
            "countryCode": "AU",
            "cityCode": "SCG",
            "cityName": "Spring Creek",
            "name": "[SCG] - Spring Creek - SCG - Spring Creek - Australia",
            "searchName": "SCG-Spring Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SBR",
            "countryCode": "AU",
            "cityCode": "SBR",
            "cityName": "Saibai Island",
            "name": "[SBR] - Saibai Island - SBR - Saibai Island - Australia",
            "searchName": "SBR-Saibai Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "STH",
            "countryCode": "AU",
            "cityCode": "STH",
            "cityName": "Strathmore",
            "name": "[STH] - Strathmore - STH - Strathmore - Australia",
            "searchName": "STH-Landeplatz Eckert-Strathmore-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "STF",
            "countryCode": "AU",
            "cityCode": "STF",
            "cityName": "Stephen Island",
            "name": "[STF] - Stephen Island - STF - Stephen Island - Australia",
            "searchName": "STF-Stephen Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SRM",
            "countryCode": "AU",
            "cityCode": "SRM",
            "cityName": "Sandringham",
            "name": "[SRM] - Sandringham - SRM - Sandringham - Australia",
            "searchName": "SRM-Sandringham-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SRN",
            "countryCode": "AU",
            "cityCode": "SRN",
            "cityName": "Strahan",
            "name": "[SRN] - Strahan - SRN - Strahan - Australia",
            "searchName": "SRN-Strahan-Strahan Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SRR",
            "countryCode": "AU",
            "cityCode": "SRR",
            "cityName": "Stradbroke Island",
            "name": "[SRR] - Dunwich - SRR - Stradbroke Island - Australia",
            "searchName": "SRR-Dunwich-Stradbroke Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SSK",
            "countryCode": "AU",
            "cityCode": "SSK",
            "cityName": "Sturt Creek",
            "name": "[SSK] - Sturt Creek - SSK - Sturt Creek - Australia",
            "searchName": "SSK-Sturt Creek-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SSP",
            "countryCode": "AU",
            "cityCode": "SSP",
            "cityName": "Silver Plains",
            "name": "[SSP] - Silver Plains - SSP - Silver Plains - Australia",
            "searchName": "SSP-Silver Plains-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SVM",
            "countryCode": "AU",
            "cityCode": "SVM",
            "cityName": "St Pauls",
            "name": "[SVM] - St Pauls - SVM - St Pauls - Australia",
            "searchName": "SVM-St Pauls-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SWB",
            "countryCode": "AU",
            "cityCode": "SWB",
            "cityName": "Shaw River",
            "name": "[SWB] - Shaw River - SWB - Shaw River - Australia",
            "searchName": "SWB-Shaw River-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SWC",
            "countryCode": "AU",
            "cityCode": "SWC",
            "cityName": "Stawell",
            "name": "[SWC] - Stawell - SWC - Stawell - Australia",
            "searchName": "SWC-Stawell-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SWH",
            "countryCode": "AU",
            "cityCode": "SWH",
            "cityName": "Swan Hill",
            "name": "[SWH] - Swan Hill - SWH - Swan Hill - Australia",
            "searchName": "SWH-Swan Hill-Swan Hill Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SOI",
            "countryCode": "AU",
            "cityCode": "SOI",
            "cityName": "South Molle Island",
            "name": "[SOI] - South Molle Island - SOI - South Molle Island - Australia",
            "searchName": "SOI-South Molle Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SQC",
            "countryCode": "AU",
            "cityCode": "SQC",
            "cityName": "Southern Cross",
            "name": "[SQC] - Southern Cross - SQC - Southern Cross - Australia",
            "searchName": "SQC-Southern Cross-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SNB",
            "countryCode": "AU",
            "cityCode": "SNB",
            "cityName": "Snake Bay",
            "name": "[SNB] - Snake Bay - SNB - Snake Bay - Australia",
            "searchName": "SNB-Snake Bay-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "SNH",
            "countryCode": "AU",
            "cityCode": "SNH",
            "cityName": "Stanthorpe",
            "name": "[SNH] - Stanthorpe - SNH - Stanthorpe - Australia",
            "searchName": "SNH-Stanthorpe-Stanthorpe Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XRH",
            "countryCode": "AU",
            "cityCode": "XRH",
            "cityName": "Richmond",
            "name": "[XRH] - Richmond AFB - XRH - Richmond - Australia",
            "searchName": "XRH-Richmond AFB-Richmond-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XTG",
            "countryCode": "AU",
            "cityCode": "XTG",
            "cityName": "Thargomindah",
            "name": "[XTG] - Thargomindah - XTG - Thargomindah - Australia",
            "searchName": "XTG-Thargomindah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XTO",
            "countryCode": "AU",
            "cityCode": "XTO",
            "cityName": "Taroom",
            "name": "[XTO] - Taroom - XTO - Taroom - Australia",
            "searchName": "XTO-Taroom-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XTR",
            "countryCode": "AU",
            "cityCode": "XTR",
            "cityName": "Tara",
            "name": "[XTR] - Tara - XTR - Tara - Australia",
            "searchName": "XTR-Tara-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XMY",
            "countryCode": "AU",
            "cityCode": "XMY",
            "cityName": "Yam Island",
            "name": "[XMY] - Yam Island - XMY - Yam Island - Australia",
            "searchName": "XMY-Yam Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XMC",
            "countryCode": "AU",
            "cityCode": "XMC",
            "cityName": "Mallacoota",
            "name": "[XMC] - Mallacoota - XMC - Mallacoota - Australia",
            "searchName": "XMC-Mallacoota-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XML",
            "countryCode": "AU",
            "cityCode": "XML",
            "cityName": "Minlaton",
            "name": "[XML] - Minlaton - XML - Minlaton - Australia",
            "searchName": "XML-Minlaton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XCO",
            "countryCode": "AU",
            "cityCode": "XCO",
            "cityName": "Colac",
            "name": "[XCO] - Colac - XCO - Colac - Australia",
            "searchName": "XCO-Colac-Colac Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WUD",
            "countryCode": "AU",
            "cityCode": "WUD",
            "cityName": "Wudinna",
            "name": "[WUD] - Wudinna - WUD - Wudinna - Australia",
            "searchName": "WUD-Wudinna-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WUN",
            "countryCode": "AU",
            "cityCode": "WUN",
            "cityName": "Wiluna",
            "name": "[WUN] - Wiluna - WUN - Wiluna - Australia",
            "searchName": "WUN-Wiluna-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WWI",
            "countryCode": "AU",
            "cityCode": "WWI",
            "cityName": "Woodie Woodie",
            "name": "[WWI] - Woodie Woodie - WWI - Woodie Woodie - Australia",
            "searchName": "WWI-Woodie Woodie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WWY",
            "countryCode": "AU",
            "cityCode": "WWY",
            "cityName": "West Wyalong",
            "name": "[WWY] - West Wyalong - WWY - West Wyalong - Australia",
            "searchName": "WWY-West Wyalong-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WYA",
            "countryCode": "AU",
            "cityCode": "WYA",
            "cityName": "Whyalla",
            "name": "[WYA] - Whyalla - WYA - Whyalla - Australia",
            "searchName": "WYA-Whyalla-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WYN",
            "countryCode": "AU",
            "cityCode": "WYN",
            "cityName": "Wyndham",
            "name": "[WYN] - Wyndham - WYN - Wyndham - Australia",
            "searchName": "WYN-Wyndham-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "VCD",
            "countryCode": "AU",
            "cityCode": "VCD",
            "cityName": "Victoria River Downs",
            "name": "[VCD] - Victoria River Downs - VCD - Victoria River Downs - Australia",
            "searchName": "VCD-Victoria River Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "USL",
            "countryCode": "AU",
            "cityCode": "USL",
            "cityName": "Useless Loop",
            "name": "[USL] - Useless Loop - USL - Useless Loop - Australia",
            "searchName": "USL-Useless Loop-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "UTB",
            "countryCode": "AU",
            "cityCode": "UTB",
            "cityName": "Muttaburra",
            "name": "[UTB] - Muttaburra - UTB - Muttaburra - Australia",
            "searchName": "UTB-Muttaburra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "UTD",
            "countryCode": "AU",
            "cityCode": "UTD",
            "cityName": "Nutwood Downs",
            "name": "[UTD] - Nutwood Downs - UTD - Nutwood Downs - Australia",
            "searchName": "UTD-Nutwood Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ULP",
            "countryCode": "AU",
            "cityCode": "ULP",
            "cityName": "Quilpie",
            "name": "[ULP] - Quilpie - ULP - Quilpie - Australia",
            "searchName": "ULP-Quilpie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "UMR",
            "countryCode": "AU",
            "cityCode": "UMR",
            "cityName": "Woomera",
            "name": "[UMR] - Woomera - UMR - Woomera - Australia",
            "searchName": "UMR-Woomera-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "UBB",
            "countryCode": "AU",
            "cityCode": "UBB",
            "cityName": "Mabuiag Island",
            "name": "[UBB] - Mabuiag Island - UBB - Mabuiag Island - Australia",
            "searchName": "UBB-Mabuiag Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "UBU",
            "countryCode": "AU",
            "cityCode": "UBU",
            "cityName": "Kalumburu",
            "name": "[UBU] - Kalumburu - UBU - Kalumburu - Australia",
            "searchName": "UBU-Kalumburu-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "UDA",
            "countryCode": "AU",
            "cityCode": "UDA",
            "cityName": "Undarra",
            "name": "[UDA] - Undarra - UDA - Undarra - Australia",
            "searchName": "UDA-Undarra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "UIR",
            "countryCode": "AU",
            "cityCode": "UIR",
            "cityName": "Quirindi",
            "name": "[UIR] - Quirindi - UIR - Quirindi - Australia",
            "searchName": "UIR-Quirindi-Quirindi Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "UEE",
            "countryCode": "AU",
            "cityCode": "UEE",
            "cityName": "Queenstown",
            "name": "[UEE] - Queenstown - UEE - Queenstown - Australia",
            "searchName": "UEE-Queenstown-Queenstown Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "VNR",
            "countryCode": "AU",
            "cityCode": "VNR",
            "cityName": "Vanrook",
            "name": "[VNR] - Vanrook - VNR - Vanrook - Australia",
            "searchName": "VNR-Vanrook-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WAN",
            "countryCode": "AU",
            "cityCode": "WAN",
            "cityName": "Waverney",
            "name": "[WAN] - Waverney - WAN - Waverney - Australia",
            "searchName": "WAN-Waverney-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WAU",
            "countryCode": "AU",
            "cityCode": "WAU",
            "cityName": "Wauchope",
            "name": "[WAU] - Wauchope - WAU - Wauchope - Australia",
            "searchName": "WAU-Wauchope-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WAV",
            "countryCode": "AU",
            "cityCode": "WAV",
            "cityName": "Wave Hill",
            "name": "[WAV] - Kalkgurung - WAV - Wave Hill - Australia",
            "searchName": "WAV-Kalkgurung-Wave Hill-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WAZ",
            "countryCode": "AU",
            "cityCode": "WAZ",
            "cityName": "Warwick",
            "name": "[WAZ] - Warwick - WAZ - Warwick - Australia",
            "searchName": "WAZ-Warwick-Warwick Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WDI",
            "countryCode": "AU",
            "cityCode": "WDI",
            "cityName": "Wondai",
            "name": "[WDI] - Wondai - WDI - Wondai - Australia",
            "searchName": "WDI-Wondai-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WEI",
            "countryCode": "AU",
            "cityCode": "WEI",
            "cityName": "Weipa",
            "name": "[WEI] - Weipa - WEI - Weipa - Australia",
            "searchName": "WEI-Weipa-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WEW",
            "countryCode": "AU",
            "cityCode": "WEW",
            "cityName": "Wee Waa",
            "name": "[WEW] - Wee Waa - WEW - Wee Waa - Australia",
            "searchName": "WEW-Wee Waa-Wee Waa Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WGA",
            "countryCode": "AU",
            "cityCode": "WGA",
            "cityName": "Wagga Wagga",
            "name": "[WGA] - Forest Hill - WGA - Wagga Wagga - Australia",
            "searchName": "WGA-Wagga Wagga-Forest Hill-Forrest Hill-Waga Waga-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WGE",
            "countryCode": "AU",
            "cityCode": "WGE",
            "cityName": "Walgett",
            "name": "[WGE] - Walgett - WGE - Walgett - Australia",
            "searchName": "WGE-Walgett-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WGT",
            "countryCode": "AU",
            "cityCode": "WGT",
            "cityName": "Wangaratta",
            "name": "[WGT] - Wangaratta - WGT - Wangaratta - Australia",
            "searchName": "WGT-Wangaratta-Wangaratta Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WHL",
            "countryCode": "AU",
            "cityCode": "WHL",
            "cityName": "Welshpool",
            "name": "[WHL] - Welshpool - WHL - Welshpool - Australia",
            "searchName": "WHL-Welshpool-Welshpool Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WIN",
            "countryCode": "AU",
            "cityCode": "WIN",
            "cityName": "Winton",
            "name": "[WIN] - Winton - WIN - Winton - Australia",
            "searchName": "WIN-Winton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WIO",
            "countryCode": "AU",
            "cityCode": "WIO",
            "cityName": "Wilcannia",
            "name": "[WIO] - Wilcannia - WIO - Wilcannia - Australia",
            "searchName": "WIO-Wilcannia-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WIT",
            "countryCode": "AU",
            "cityCode": "WIT",
            "cityName": "Wittenoom",
            "name": "[WIT] - Wittenoom - WIT - Wittenoom - Australia",
            "searchName": "WIT-Wittenoom-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WKB",
            "countryCode": "AU",
            "cityCode": "WKB",
            "cityName": "Warracknabeal",
            "name": "[WKB] - Warracknabeal - WKB - Warracknabeal - Australia",
            "searchName": "WKB-Warracknabeal-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WLA",
            "countryCode": "AU",
            "cityCode": "WLA",
            "cityName": "Wallal",
            "name": "[WLA] - Wallal - WLA - Wallal - Australia",
            "searchName": "WLA-Wallal-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WLC",
            "countryCode": "AU",
            "cityCode": "WLC",
            "cityName": "Walcha",
            "name": "[WLC] - Walcha - WLC - Walcha - Australia",
            "searchName": "WLC-Walcha-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WLL",
            "countryCode": "AU",
            "cityCode": "WLL",
            "cityName": "Wollogorang",
            "name": "[WLL] - Wollogorang - WLL - Wollogorang - Australia",
            "searchName": "WLL-Wollogorang-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WLO",
            "countryCode": "AU",
            "cityCode": "WLO",
            "cityName": "Waterloo",
            "name": "[WLO] - Waterloo - WLO - Waterloo - Australia",
            "searchName": "WLO-Waterloo-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WMB",
            "countryCode": "AU",
            "cityCode": "WMB",
            "cityName": "Warrnambool",
            "name": "[WMB] - Warrnambool - WMB - Warrnambool - Australia",
            "searchName": "WMB-Warrnambool-Warrnambool Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WME",
            "countryCode": "AU",
            "cityCode": "WME",
            "cityName": "Mount Keith",
            "name": "[WME] - Mount Keith - WME - Mount Keith - Australia",
            "searchName": "WME-Mount Keith-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WND",
            "countryCode": "AU",
            "cityCode": "WND",
            "cityName": "Windarra",
            "name": "[WND] - Windarra - WND - Windarra - Australia",
            "searchName": "WND-Windarra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WNR",
            "countryCode": "AU",
            "cityCode": "WNR",
            "cityName": "Windorah",
            "name": "[WNR] - Windorah - WNR - Windorah - Australia",
            "searchName": "WNR-Windorah-Windarra-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WOL",
            "countryCode": "AU",
            "cityCode": "WOL",
            "cityName": "Wollongong",
            "name": "[WOL] - Wollongong - WOL - Wollongong - Australia",
            "searchName": "WOL-Wollongong-Wollongong Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WON",
            "countryCode": "AU",
            "cityCode": "WON",
            "cityName": "Wondoola",
            "name": "[WON] - Wondoola - WON - Wondoola - Australia",
            "searchName": "WON-Wondoola-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WPK",
            "countryCode": "AU",
            "cityCode": "WPK",
            "cityName": "Wrotham Park",
            "name": "[WPK] - Wrotham Park - WPK - Wrotham Park - Australia",
            "searchName": "WPK-Park Flughafen-Wrotham Park-Wrotham-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "WRW",
            "countryCode": "AU",
            "cityCode": "WRW",
            "cityName": "Warrawagine",
            "name": "[WRW] - Warrawagine - WRW - Warrawagine - Australia",
            "searchName": "WRW-Warrawagine-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "QWL",
            "countryCode": "AU",
            "cityCode": "QWL",
            "cityName": "Crackenback Village",
            "name": "[QWL] - Crackenback Village Bus Station - QWL - Crackenback Village - Australia",
            "searchName": "QWL-Crackenback Village Bus Station-Crackenback Village-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "QTH",
            "countryCode": "AU",
            "cityCode": "QTH",
            "cityName": "Thredbo",
            "name": "[QTH] - Thredbo Bus Station - QTH - Thredbo - Australia",
            "searchName": "QTH-Thredbo Bus Station-Thredbo-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "QLE",
            "countryCode": "AU",
            "cityCode": "QLE",
            "cityName": "Leeton",
            "name": "[QLE] - Leeton Bus Station - QLE - Leeton - Australia",
            "searchName": "QLE-Leeton Bus Station-Leeton Havalimani-Leeton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "QPV",
            "countryCode": "AU",
            "cityCode": "QPV",
            "cityName": "Perisher Valley",
            "name": "[QPV] - Perisher Valley Bus Station - QPV - Perisher Valley - Australia",
            "searchName": "QPV-Perisher Valley Bus Station-Perisher Valley-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RDA",
            "countryCode": "AU",
            "cityCode": "RDA",
            "cityName": "Rockhampton Downs",
            "name": "[RDA] - Rockhampton Downs - RDA - Rockhampton Downs - Australia",
            "searchName": "RDA-Rockhampton Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RCM",
            "countryCode": "AU",
            "cityCode": "RCM",
            "cityName": "Richmond",
            "name": "[RCM] - Richmond - RCM - Richmond - Australia",
            "searchName": "RCM-Richmond-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RBS",
            "countryCode": "AU",
            "cityCode": "RBS",
            "cityName": "Orbost",
            "name": "[RBS] - Orbost - RBS - Orbost - Australia",
            "searchName": "RBS-Orbost-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RBU",
            "countryCode": "AU",
            "cityCode": "RBU",
            "cityName": "Roebourne",
            "name": "[RBU] - Roebourne - RBU - Roebourne - Australia",
            "searchName": "RBU-Roebourne-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RAM",
            "countryCode": "AU",
            "cityCode": "RAM",
            "cityName": "Ramingining",
            "name": "[RAM] - Ramingining - RAM - Ramingining - Australia",
            "searchName": "RAM-Ramingining-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RBC",
            "countryCode": "AU",
            "cityCode": "RBC",
            "cityName": "Robinvale",
            "name": "[RBC] - Robinvale - RBC - Robinvale - Australia",
            "searchName": "RBC-Robinvale-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "QZC",
            "countryCode": "AU",
            "cityCode": "QZC",
            "cityName": "Smiggin Holes",
            "name": "[QZC] - Smiggin Holes Bus Station - QZC - Smiggin Holes - Australia",
            "searchName": "QZC-Smiggin Holes Bus Station-Smiggin Holes-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "QZM",
            "countryCode": "AU",
            "cityCode": "QZM",
            "cityName": "Bullocks Flat",
            "name": "[QZM] - Bullocks Flat Bus Station - QZM - Bullocks Flat - Australia",
            "searchName": "QZM-Bullocks Flat Bus Station-Bullocks Flat-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PUG",
            "countryCode": "AU",
            "cityCode": "PUG",
            "cityName": "Port Augusta",
            "name": "[PUG] - Port Augusta - PUG - Port Augusta - Australia",
            "searchName": "PUG-Port Augusta-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PTI",
            "countryCode": "AU",
            "cityCode": "PTI",
            "cityName": "Port Douglas",
            "name": "[PTI] - Port Douglas - PTI - Port Douglas - Australia",
            "searchName": "PTI-Port Douglas-Port Douglas Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PTJ",
            "countryCode": "AU",
            "cityCode": "PTJ",
            "cityName": "Portland",
            "name": "[PTJ] - Portland - PTJ - Portland - Australia",
            "searchName": "PTJ-Portland-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PXH",
            "countryCode": "AU",
            "cityCode": "PXH",
            "cityName": "Prominent Hill",
            "name": "[PXH] - Prominent Hill - PXH - Prominent Hill - Australia",
            "searchName": "PXH-Prominent Hill-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RTP",
            "countryCode": "AU",
            "cityCode": "RTP",
            "cityName": "Rutland Plains",
            "name": "[RTP] - Rutland Plains - RTP - Rutland Plains - Australia",
            "searchName": "RTP-Rutland Plains-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RTS",
            "countryCode": "AU",
            "cityCode": "RTS",
            "cityName": "Rottnest Island",
            "name": "[RTS] - Rottnest Island - RTS - Rottnest Island - Australia",
            "searchName": "RTS-Rottnest Island-Rottnest Adasi Havalimani-Rottnest-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RTY",
            "countryCode": "AU",
            "cityCode": "RTY",
            "cityName": "Merty",
            "name": "[RTY] - Merty - RTY - Merty - Australia",
            "searchName": "RTY-Merty-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RVT",
            "countryCode": "AU",
            "cityCode": "RVT",
            "cityName": "Ravensthorpe",
            "name": "[RVT] - Ravensthorpe - RVT - Ravensthorpe - Australia",
            "searchName": "RVT-Ravensthorpe-Ravensthorpe Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RSB",
            "countryCode": "AU",
            "cityCode": "RSB",
            "cityName": "Roseberth",
            "name": "[RSB] - Roseberth - RSB - Roseberth - Australia",
            "searchName": "RSB-Roseberth-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RRV",
            "countryCode": "AU",
            "cityCode": "RRV",
            "cityName": "Robinson River",
            "name": "[RRV] - Robinson River - RRV - Robinson River - Australia",
            "searchName": "RRV-Robinson River-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RRE",
            "countryCode": "AU",
            "cityCode": "RRE",
            "cityName": "Marree",
            "name": "[RRE] - Marree - RRE - Marree - Australia",
            "searchName": "RRE-Marree-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RPV",
            "countryCode": "AU",
            "cityCode": "RPV",
            "cityName": "Roper Valley",
            "name": "[RPV] - Roper Valley - RPV - Roper Valley - Australia",
            "searchName": "RPV-Roper Valley-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RPB",
            "countryCode": "AU",
            "cityCode": "RPB",
            "cityName": "Roper Bar",
            "name": "[RPB] - Roper Bar - RPB - Roper Bar - Australia",
            "searchName": "RPB-Roper Bar-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RPM",
            "countryCode": "AU",
            "cityCode": "RPM",
            "cityName": "Ngukurr",
            "name": "[RPM] - Ngukurr - RPM - Ngukurr - Australia",
            "searchName": "RPM-Ngukurr-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ROH",
            "countryCode": "AU",
            "cityCode": "ROH",
            "cityName": "Robinhood",
            "name": "[ROH] - Robinhood - ROH - Robinhood - Australia",
            "searchName": "ROH-Robinhood-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ROK",
            "countryCode": "AU",
            "cityCode": "ROK",
            "cityName": "Rockhampton",
            "name": "[ROK] - Rockhampton - ROK - Rockhampton - Australia",
            "searchName": "ROK-Rockhampton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RHL",
            "countryCode": "AU",
            "cityCode": "RHL",
            "cityName": "Roy Hill",
            "name": "[RHL] - Roy Hill - RHL - Roy Hill - Australia",
            "searchName": "RHL-Roy Hill-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RLP",
            "countryCode": "AU",
            "cityCode": "RLP",
            "cityName": "Rosella Plains",
            "name": "[RLP] - Rosella Plains - RLP - Rosella Plains - Australia",
            "searchName": "RLP-Rosella Plains-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RMA",
            "countryCode": "AU",
            "cityCode": "RMA",
            "cityName": "Roma",
            "name": "[RMA] - Roma - RMA - Roma - Australia",
            "searchName": "RMA-Roma Australien-Roma-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RMK",
            "countryCode": "AU",
            "cityCode": "RMK",
            "cityName": "Renmark",
            "name": "[RMK] - Renmark - RMK - Renmark - Australia",
            "searchName": "RMK-Renmark-Renmark Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "RKY",
            "countryCode": "AU",
            "cityCode": "RKY",
            "cityName": "Rokeby",
            "name": "[RKY] - Rokeby - RKY - Rokeby - Australia",
            "searchName": "RKY-Rokeby-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PPI",
            "countryCode": "AU",
            "cityCode": "PPI",
            "cityName": "Port Pirie",
            "name": "[PPI] - Port Pirie - PPI - Port Pirie - Australia",
            "searchName": "PPI-Port Pirie-Port Porie Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PQQ",
            "countryCode": "AU",
            "cityCode": "PQQ",
            "cityName": "Port Macquarie",
            "name": "[PQQ] - Port Macquarie - PQQ - Port Macquarie - Australia",
            "searchName": "PQQ-Port Macquarie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PRD",
            "countryCode": "AU",
            "cityCode": "PRD",
            "cityName": "Pardoo",
            "name": "[PRD] - Pardoo - PRD - Pardoo - Australia",
            "searchName": "PRD-Pardoo-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PPP",
            "countryCode": "AU",
            "cityCode": "PPP",
            "cityName": "Proserpine",
            "name": "[PPP] - Whitsunday Coast - PPP - Proserpine - Australia",
            "searchName": "PPP-Proserpine-Whitsunday Coast-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PKT",
            "countryCode": "AU",
            "cityCode": "PKT",
            "cityName": "Port Keats",
            "name": "[PKT] - Port Keats - PKT - Port Keats - Australia",
            "searchName": "PKT-Port Keats-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PLO",
            "countryCode": "AU",
            "cityCode": "PLO",
            "cityName": "Port Lincoln",
            "name": "[PLO] - Port Lincoln - PLO - Port Lincoln - Australia",
            "searchName": "PLO-Port Lincoln-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PMK",
            "countryCode": "AU",
            "cityCode": "PMK",
            "cityName": "Palm Island",
            "name": "[PMK] - Palm Island - PMK - Palm Island - Australia",
            "searchName": "PMK-Palm Island-Palm Adasi Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PEA",
            "countryCode": "AU",
            "cityCode": "PEA",
            "cityName": "Penneshaw",
            "name": "[PEA] - Penneshaw - PEA - Penneshaw - Australia",
            "searchName": "PEA-Penneshaw-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PEP",
            "countryCode": "AU",
            "cityCode": "PEP",
            "cityName": "Peppimenarti",
            "name": "[PEP] - Peppimenarti - PEP - Peppimenarti - Australia",
            "searchName": "PEP-Peppimenarti-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PER",
            "countryCode": "AU",
            "cityCode": "PER",
            "cityName": "Perth",
            "name": "[PER] - Perth International - PER - Perth - Australia",
            "searchName": "PER-Perth-Perth International-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XPO",
            "countryCode": "AU",
            "cityCode": "PER",
            "cityName": "Perth",
            "name": "[XPO] - Perth Ferry Port - PER - Perth - Australia",
            "searchName": "XPO-PER-Perth Ferry Port-Perth-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "JAD",
            "countryCode": "AU",
            "cityCode": "PER",
            "cityName": "Perth",
            "name": "[JAD] - Jandakot - PER - Perth - Australia",
            "searchName": "JAD-PER-Jandakot-Perth-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PEY",
            "countryCode": "AU",
            "cityCode": "PEY",
            "cityName": "Penong",
            "name": "[PEY] - Penong - PEY - Penong - Australia",
            "searchName": "PEY-Penong-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PHE",
            "countryCode": "AU",
            "cityCode": "PHE",
            "cityName": "Port Hedland",
            "name": "[PHE] - Port Hedland - PHE - Port Hedland - Australia",
            "searchName": "PHE-Port Hedland-Port Headland-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PKE",
            "countryCode": "AU",
            "cityCode": "PKE",
            "cityName": "Parkes",
            "name": "[PKE] - Parkes - PKE - Parkes - Australia",
            "searchName": "PKE-Parkes-Parkes Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ORR",
            "countryCode": "AU",
            "cityCode": "ORR",
            "cityName": "Yorketown",
            "name": "[ORR] - Yorketown - ORR - Yorketown - Australia",
            "searchName": "ORR-Yorketown-Yorktown-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ORS",
            "countryCode": "AU",
            "cityCode": "ORS",
            "cityName": "Orpheus Island Resort",
            "name": "[ORS] - Waterport - ORS - Orpheus Island Resort - Australia",
            "searchName": "ORS-Waterport-Waterport Havalimani-Orpheus Island Resort-Orpheus Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OSO",
            "countryCode": "AU",
            "cityCode": "OSO",
            "cityName": "Osborne Mine",
            "name": "[OSO] - Osborne Mine - OSO - Osborne Mine - Australia",
            "searchName": "OSO-Osborne Mine-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OLP",
            "countryCode": "AU",
            "cityCode": "OLP",
            "cityName": "Olympic Dam",
            "name": "[OLP] - Olympic Dam - OLP - Olympic Dam - Australia",
            "searchName": "OLP-Olympic Dam-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ONG",
            "countryCode": "AU",
            "cityCode": "ONG",
            "cityName": "Mornington",
            "name": "[ONG] - Mornington - ONG - Mornington - Australia",
            "searchName": "ONG-Mornington-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ONR",
            "countryCode": "AU",
            "cityCode": "ONR",
            "cityName": "Monkira",
            "name": "[ONR] - Monkira - ONR - Monkira - Australia",
            "searchName": "ONR-Monkira-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ONS",
            "countryCode": "AU",
            "cityCode": "ONS",
            "cityName": "Onslow",
            "name": "[ONS] - Onslow - ONS - Onslow - Australia",
            "searchName": "ONS-Onslow-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OOL",
            "countryCode": "AU",
            "cityCode": "OOL",
            "cityName": "Gold Coast",
            "name": "[OOL] - Gold Coast (coolangatta) - OOL - Gold Coast - Australia",
            "searchName": "OOL-Gold Coast-Gold Coast (coolangatta)-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OOM",
            "countryCode": "AU",
            "cityCode": "OOM",
            "cityName": "Cooma",
            "name": "[OOM] - Cooma - OOM - Cooma - Australia",
            "searchName": "OOM-Cooma-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OOR",
            "countryCode": "AU",
            "cityCode": "OOR",
            "cityName": "Mooraberree",
            "name": "[OOR] - Mooraberree - OOR - Mooraberree - Australia",
            "searchName": "OOR-Mooraberree-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OPI",
            "countryCode": "AU",
            "cityCode": "OPI",
            "cityName": "Oenpelli",
            "name": "[OPI] - Oenpelli - OPI - Oenpelli - Australia",
            "searchName": "OPI-Oenpelli-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OXY",
            "countryCode": "AU",
            "cityCode": "OXY",
            "cityName": "Morney",
            "name": "[OXY] - Morney - OXY - Morney - Australia",
            "searchName": "OXY-Morney-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OYN",
            "countryCode": "AU",
            "cityCode": "OYN",
            "cityName": "Ouyen",
            "name": "[OYN] - Ouyen - OYN - Ouyen - Australia",
            "searchName": "OYN-Ouyen-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OXO",
            "countryCode": "AU",
            "cityCode": "OXO",
            "cityName": "Orientos",
            "name": "[OXO] - Orientos - OXO - Orientos - Australia",
            "searchName": "OXO-Orientos-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PBO",
            "countryCode": "AU",
            "cityCode": "PBO",
            "cityName": "Paraburdoo",
            "name": "[PBO] - Paraburdoo - PBO - Paraburdoo - Australia",
            "searchName": "PBO-Paraburdoo-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PBY",
            "countryCode": "AU",
            "cityCode": "PBY",
            "cityName": "Peppers Palm Bay",
            "name": "[PBY] - Hamilton/Proserpine - PBY - Peppers Palm Bay - Australia",
            "searchName": "PBY-Hamilton/Proserpine-Peppers Palm Bay-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PDE",
            "countryCode": "AU",
            "cityCode": "PDE",
            "cityName": "Pandie Pandie",
            "name": "[PDE] - Pandie Pandie - PDE - Pandie Pandie - Australia",
            "searchName": "PDE-Pandie Pandie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PDN",
            "countryCode": "AU",
            "cityCode": "PDN",
            "cityName": "Parndana",
            "name": "[PDN] - Parndana - PDN - Parndana - Australia",
            "searchName": "PDN-Parndana-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OKB",
            "countryCode": "AU",
            "cityCode": "OKB",
            "cityName": "Orchid Beach",
            "name": "[OKB] - Fraser Island - OKB - Orchid Beach - Australia",
            "searchName": "OKB-Fraser Island-Orchid Beach-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OKR",
            "countryCode": "AU",
            "cityCode": "OKR",
            "cityName": "Yorke Island",
            "name": "[OKR] - Yorke Island - OKR - Yorke Island - Australia",
            "searchName": "OKR-Yorke Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OKY",
            "countryCode": "AU",
            "cityCode": "OKY",
            "cityName": "Oakey",
            "name": "[OKY] - Oakey - OKY - Oakey - Australia",
            "searchName": "OKY-Oakey-Oakey Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NYN",
            "countryCode": "AU",
            "cityCode": "NYN",
            "cityName": "Nyngan",
            "name": "[NYN] - Nyngan - NYN - Nyngan - Australia",
            "searchName": "NYN-Nyngan-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OBA",
            "countryCode": "AU",
            "cityCode": "OBA",
            "cityName": "Oban",
            "name": "[OBA] - Oban - OBA - Oban - Australia",
            "searchName": "OBA-Oban-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "OAG",
            "countryCode": "AU",
            "cityCode": "OAG",
            "cityName": "Orange",
            "name": "[OAG] - Springhill - OAG - Orange - Australia",
            "searchName": "OAG-Springhill-Orange-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ODD",
            "countryCode": "AU",
            "cityCode": "ODD",
            "cityName": "Oodnadatta",
            "name": "[ODD] - Oodnadatta - ODD - Oodnadatta - Australia",
            "searchName": "ODD-Oodnadatta-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ODL",
            "countryCode": "AU",
            "cityCode": "ODL",
            "cityName": "Cordillo Downs",
            "name": "[ODL] - Cordillo Downs - ODL - Cordillo Downs - Australia",
            "searchName": "ODL-Cordillo Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "ODR",
            "countryCode": "AU",
            "cityCode": "ODR",
            "cityName": "Ord River",
            "name": "[ODR] - Ord River - ODR - Ord River - Australia",
            "searchName": "ODR-Ord River-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NRA",
            "countryCode": "AU",
            "cityCode": "NRA",
            "cityName": "Narrandera",
            "name": "[NRA] - Narrandera - NRA - Narrandera - Australia",
            "searchName": "NRA-Narrandera-Narrandera Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NPP",
            "countryCode": "AU",
            "cityCode": "NPP",
            "cityName": "Napperby",
            "name": "[NPP] - Napperby - NPP - Napperby - Australia",
            "searchName": "NPP-Napperby-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NRG",
            "countryCode": "AU",
            "cityCode": "NRG",
            "cityName": "Narrogin",
            "name": "[NRG] - Narrogin - NRG - Narrogin - Australia",
            "searchName": "NRG-Narrogin-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NRY",
            "countryCode": "AU",
            "cityCode": "NRY",
            "cityName": "Newry",
            "name": "[NRY] - Newry - NRY - Newry - Australia",
            "searchName": "NRY-Newry-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NSM",
            "countryCode": "AU",
            "cityCode": "NSM",
            "cityName": "Norseman",
            "name": "[NSM] - Norseman - NSM - Norseman - Australia",
            "searchName": "NSM-Norseman-Norseman Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NOA",
            "countryCode": "AU",
            "cityCode": "NOA",
            "cityName": "Nowra",
            "name": "[NOA] - Nowra - NOA - Nowra - Australia",
            "searchName": "NOA-Nowra-Nowra Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NSO",
            "countryCode": "AU",
            "cityCode": "NSO",
            "cityName": "Scone",
            "name": "[NSO] - Scone - NSO - Scone - Australia",
            "searchName": "NSO-Scone-Scone Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NSV",
            "countryCode": "AU",
            "cityCode": "NSV",
            "cityName": "Noosaville",
            "name": "[NSV] - Noosaville - NSV - Noosaville - Australia",
            "searchName": "NSV-Noosaville-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NTL",
            "countryCode": "AU",
            "cityCode": "NTL",
            "cityName": "Newcastle",
            "name": "[NTL] - Williamtown - NTL - Newcastle - Australia",
            "searchName": "NTL-Newcastle Australien-Williamtown-Newcastle-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "PHJ",
            "countryCode": "AU",
            "cityCode": "NTL",
            "cityName": "Newcastle",
            "name": "[PHJ] - Port Hunter - NTL - Newcastle - Australia",
            "searchName": "PHJ-NTL-Port Hunter-Newcastle-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "BEO",
            "countryCode": "AU",
            "cityCode": "NTL",
            "cityName": "Newcastle",
            "name": "[BEO] - Belmont - NTL - Newcastle - Australia",
            "searchName": "BEO-NTL-Belmont-Newcastle-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NTN",
            "countryCode": "AU",
            "cityCode": "NTN",
            "cityName": "Normanton",
            "name": "[NTN] - Normanton - NTN - Normanton - Australia",
            "searchName": "NTN-Normanton-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NUB",
            "countryCode": "AU",
            "cityCode": "NUB",
            "cityName": "Numbulwar",
            "name": "[NUB] - Numbulwar - NUB - Numbulwar - Australia",
            "searchName": "NUB-Numbulwar-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NUR",
            "countryCode": "AU",
            "cityCode": "NUR",
            "cityName": "Nullarbor",
            "name": "[NUR] - Nullarbor - NUR - Nullarbor - Australia",
            "searchName": "NUR-Nullarbor-Nullarbor Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NDS",
            "countryCode": "AU",
            "cityCode": "NDS",
            "cityName": "Sandstone",
            "name": "[NDS] - Sandstone - NDS - Sandstone - Australia",
            "searchName": "NDS-Sandstone-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NGA",
            "countryCode": "AU",
            "cityCode": "NGA",
            "cityName": "Young",
            "name": "[NGA] - Young - NGA - Young - Australia",
            "searchName": "NGA-Young-Young Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NBH",
            "countryCode": "AU",
            "cityCode": "NBH",
            "cityName": "Nambucca Heads",
            "name": "[NBH] - Nambucca Heads - NBH - Nambucca Heads - Australia",
            "searchName": "NBH-Nambucca Heads-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NIF",
            "countryCode": "AU",
            "cityCode": "NIF",
            "cityName": "Nifty",
            "name": "[NIF] - Nifty - NIF - Nifty - Australia",
            "searchName": "NIF-Nifty-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NLF",
            "countryCode": "AU",
            "cityCode": "NLF",
            "cityName": "Darnley Island",
            "name": "[NLF] - Darnley Island - NLF - Darnley Island - Australia",
            "searchName": "NLF-Darnley Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NLL",
            "countryCode": "AU",
            "cityCode": "NLL",
            "cityName": "Nullagine",
            "name": "[NLL] - Nullagine - NLL - Nullagine - Australia",
            "searchName": "NLL-Nullagine-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NLS",
            "countryCode": "AU",
            "cityCode": "NLS",
            "cityName": "Nicholson",
            "name": "[NLS] - Nicholson - NLS - Nicholson - Australia",
            "searchName": "NLS-Nicholson-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NMP",
            "countryCode": "AU",
            "cityCode": "NMP",
            "cityName": "New Moon",
            "name": "[NMP] - New Moon - NMP - New Moon - Australia",
            "searchName": "NMP-New Moon-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NMR",
            "countryCode": "AU",
            "cityCode": "NMR",
            "cityName": "Nappa Merry",
            "name": "[NMR] - Nappa Merry - NMR - Nappa Merry - Australia",
            "searchName": "NMR-Nappa Merry-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MYO",
            "countryCode": "AU",
            "cityCode": "MYO",
            "cityName": "Myroodah",
            "name": "[MYO] - Myroodah - MYO - Myroodah - Australia",
            "searchName": "MYO-Myroodah-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NAA",
            "countryCode": "AU",
            "cityCode": "NAA",
            "cityName": "Narrabri",
            "name": "[NAA] - Narrabri - NAA - Narrabri - Australia",
            "searchName": "NAA-Narrabri-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "NAC",
            "countryCode": "AU",
            "cityCode": "NAC",
            "cityName": "Naracoorte",
            "name": "[NAC] - Naracoorte - NAC - Naracoorte - Australia",
            "searchName": "NAC-Naracoorte-Naracoorte Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MVU",
            "countryCode": "AU",
            "cityCode": "MVU",
            "cityName": "Musgrave",
            "name": "[MVU] - Musgrave - MVU - Musgrave - Australia",
            "searchName": "MVU-Musgrave-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MWB",
            "countryCode": "AU",
            "cityCode": "MWB",
            "cityName": "Morawa",
            "name": "[MWB] - Morawa - MWB - Morawa - Australia",
            "searchName": "MWB-Morawa-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MWT",
            "countryCode": "AU",
            "cityCode": "MWT",
            "cityName": "Moolawatana",
            "name": "[MWT] - Moolawatana - MWT - Moolawatana - Australia",
            "searchName": "MWT-Moolawatana-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MWY",
            "countryCode": "AU",
            "cityCode": "MWY",
            "cityName": "Miranda Downs",
            "name": "[MWY] - Miranda Downs - MWY - Miranda Downs - Australia",
            "searchName": "MWY-Miranda Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MXD",
            "countryCode": "AU",
            "cityCode": "MXD",
            "cityName": "Marion Downs",
            "name": "[MXD] - Marion Downs - MXD - Marion Downs - Australia",
            "searchName": "MXD-Marion Downs-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MXU",
            "countryCode": "AU",
            "cityCode": "MXU",
            "cityName": "Mullewa",
            "name": "[MXU] - Mullewa - MXU - Mullewa - Australia",
            "searchName": "MXU-Mullewa-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MYA",
            "countryCode": "AU",
            "cityCode": "MYA",
            "cityName": "Moruya",
            "name": "[MYA] - Moruya - MYA - Moruya - Australia",
            "searchName": "MYA-Moruya-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MYI",
            "countryCode": "AU",
            "cityCode": "MYI",
            "cityName": "Murray Island",
            "name": "[MYI] - Murray Island - MYI - Murray Island - Australia",
            "searchName": "MYI-Murray Island-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MQE",
            "countryCode": "AU",
            "cityCode": "MQE",
            "cityName": "Marqua",
            "name": "[MQE] - Marqua - MQE - Marqua - Australia",
            "searchName": "MQE-Marqua-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MQA",
            "countryCode": "AU",
            "cityCode": "MQA",
            "cityName": "Mandora",
            "name": "[MQA] - Mandora - MQA - Mandora - Australia",
            "searchName": "MQA-Mandora-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MQL",
            "countryCode": "AU",
            "cityCode": "MQL",
            "cityName": "Mildura",
            "name": "[MQL] - Mildura - MQL - Mildura - Australia",
            "searchName": "MQL-Mildura-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MQZ",
            "countryCode": "AU",
            "cityCode": "MQZ",
            "cityName": "Margaret River",
            "name": "[MQZ] - Margaret River - MQZ - Margaret River - Australia",
            "searchName": "MQZ-Margaret River-Margaret River Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MRG",
            "countryCode": "AU",
            "cityCode": "MRG",
            "cityName": "Mareeba",
            "name": "[MRG] - Mareeba - MRG - Mareeba - Australia",
            "searchName": "MRG-Mareeba-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MRL",
            "countryCode": "AU",
            "cityCode": "MRL",
            "cityName": "Miners Lake",
            "name": "[MRL] - Miners Lake - MRL - Miners Lake - Australia",
            "searchName": "MRL-Miners Lake-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MRP",
            "countryCode": "AU",
            "cityCode": "MRP",
            "cityName": "Marla",
            "name": "[MRP] - Marla - MRP - Marla - Australia",
            "searchName": "MRP-Marla-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MRZ",
            "countryCode": "AU",
            "cityCode": "MRZ",
            "cityName": "Moree",
            "name": "[MRZ] - Moree - MRZ - Moree - Australia",
            "searchName": "MRZ-Moree-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MRT",
            "countryCode": "AU",
            "cityCode": "MRT",
            "cityName": "Moroak",
            "name": "[MRT] - Moroak - MRT - Moroak - Australia",
            "searchName": "MRT-Moroak-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MSF",
            "countryCode": "AU",
            "cityCode": "MSF",
            "cityName": "Mount Swan",
            "name": "[MSF] - Mount Swan - MSF - Mount Swan - Australia",
            "searchName": "MSF-Mount Swan-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MTD",
            "countryCode": "AU",
            "cityCode": "MTD",
            "cityName": "Mount Sandford",
            "name": "[MTD] - Mt Sandford - MTD - Mount Sandford - Australia",
            "searchName": "MTD-Mt Sandford-Mount Sandford-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MTL",
            "countryCode": "AU",
            "cityCode": "MTL",
            "cityName": "Maitland",
            "name": "[MTL] - Maitland - MTL - Maitland - Australia",
            "searchName": "MTL-Maitland-Maitland Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MTQ",
            "countryCode": "AU",
            "cityCode": "MTQ",
            "cityName": "Mitchell",
            "name": "[MTQ] - Mitchell - MTQ - Mitchell - Australia",
            "searchName": "MTQ-Mitchell-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MUQ",
            "countryCode": "AU",
            "cityCode": "MUQ",
            "cityName": "Muccan",
            "name": "[MUQ] - Muccan - MUQ - Muccan - Australia",
            "searchName": "MUQ-Muccan-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MVH",
            "countryCode": "AU",
            "cityCode": "MVH",
            "cityName": "Macksville",
            "name": "[MVH] - Macksville - MVH - Macksville - Australia",
            "searchName": "MVH-Macksville-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MVK",
            "countryCode": "AU",
            "cityCode": "MVK",
            "cityName": "Mulka",
            "name": "[MVK] - Mulka - MVK - Mulka - Australia",
            "searchName": "MVK-Mulka-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MNE",
            "countryCode": "AU",
            "cityCode": "MNE",
            "cityName": "Mungeranie",
            "name": "[MNE] - Mungeranie - MNE - Mungeranie - Australia",
            "searchName": "MNE-Mungeranie-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MNG",
            "countryCode": "AU",
            "cityCode": "MNG",
            "cityName": "Maningrida",
            "name": "[MNG] - Maningrida - MNG - Maningrida - Australia",
            "searchName": "MNG-Maningrida- Maningrida-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MMG",
            "countryCode": "AU",
            "cityCode": "MMG",
            "cityName": "Mount Magnet",
            "name": "[MMG] - Mount Magnet - MMG - Mount Magnet - Australia",
            "searchName": "MMG-Mount Magnet-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MMM",
            "countryCode": "AU",
            "cityCode": "MMM",
            "cityName": "Middlemount",
            "name": "[MMM] - Middlemount - MMM - Middlemount - Australia",
            "searchName": "MMM-Middlemount-Middlemount Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MKV",
            "countryCode": "AU",
            "cityCode": "MKV",
            "cityName": "Mount Cavenagh",
            "name": "[MKV] - Mt Cavenagh - MKV - Mount Cavenagh - Australia",
            "searchName": "MKV-Mt Cavenagh-Mount Cavenagh-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MKY",
            "countryCode": "AU",
            "cityCode": "MKY",
            "cityName": "Mackay",
            "name": "[MKY] - Mackay - MKY - Mackay - Australia",
            "searchName": "MKY-Mackay-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MLR",
            "countryCode": "AU",
            "cityCode": "MLR",
            "cityName": "Millicent",
            "name": "[MLR] - Millicent - MLR - Millicent - Australia",
            "searchName": "MLR-Millicent-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MLV",
            "countryCode": "AU",
            "cityCode": "MLV",
            "cityName": "Merluna",
            "name": "[MLV] - Merluna - MLV - Merluna - Australia",
            "searchName": "MLV-Merluna-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MNQ",
            "countryCode": "AU",
            "cityCode": "MNQ",
            "cityName": "Monto",
            "name": "[MNQ] - Monto - MNQ - Monto - Australia",
            "searchName": "MNQ-Monto-Monto Havalimani-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MNV",
            "countryCode": "AU",
            "cityCode": "MNV",
            "cityName": "Mountain Valley",
            "name": "[MNV] - Mountain Valley - MNV - Mountain Valley - Australia",
            "searchName": "MNV-Mountain Valley-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "MOO",
            "countryCode": "AU",
            "cityCode": "MOO",
            "cityName": "Moomba",
            "name": "[MOO] - Moomba - MOO - Moomba - Australia",
            "searchName": "MOO-Moomba-Austràlia-Ausztrália-Australia-Avustralya-Austrálie-Avstralija-Australija-Austraalia-Australien-Australie",
            "priority": 0
        },
        {
            "iataCode": "XFY",
            "countryCode": "CA",
            "cityCode": "YQB",
            "cityName": "Québec City",
            "name": "[XFY] - Sainte-Foy Railway - YQB - Québec City - Canada",
            "searchName": "XFY-YQB-Sainte-Foy Railway-Quebec-Qu-Québec City-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XFZ",
            "countryCode": "CA",
            "cityCode": "YQB",
            "cityName": "Québec City",
            "name": "[XFZ] - Charny - YQB - Québec City - Canada",
            "searchName": "XFZ-YQB-Charny-Quebec-Qu-Québec City-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XLJ",
            "countryCode": "CA",
            "cityCode": "YQB",
            "cityName": "Québec City",
            "name": "[XLJ] - Quebec Stn. Railway - YQB - Québec City - Canada",
            "searchName": "XLJ-YQB-Quebec Stn. Railway-Quebec-Qu-Québec City-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XLK",
            "countryCode": "CA",
            "cityCode": "YQB",
            "cityName": "Québec City",
            "name": "[XLK] - Levis Railway Station - YQB - Québec City - Canada",
            "searchName": "XLK-YQB-Levis Railway Station-Quebec-Qu-Québec City-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQC",
            "countryCode": "CA",
            "cityCode": "YQC",
            "cityName": "Quaqtaq",
            "name": "[YQC] - Quaqtaq - YQC - Quaqtaq - Canada",
            "searchName": "YQC-Quaqtaq-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQD",
            "countryCode": "CA",
            "cityCode": "YQD",
            "cityName": "The Pas",
            "name": "[YQD] - Clearwater - YQD - The Pas - Canada",
            "searchName": "YQD-The Pas-Clearwater-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XDZ",
            "countryCode": "CA",
            "cityCode": "YQD",
            "cityName": "The Pas",
            "name": "[XDZ] - The Pas Railway Station - YQD - The Pas - Canada",
            "searchName": "XDZ-YQD-The Pas Railway Station-The Pas-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQE",
            "countryCode": "CA",
            "cityCode": "YQE",
            "cityName": "Kimberley",
            "name": "[YQE] - Kimberley - YQE - Kimberley - Canada",
            "searchName": "YQE-Kimberley-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQF",
            "countryCode": "CA",
            "cityCode": "YQF",
            "cityName": "Red Deer",
            "name": "[YQF] - Red Deer - YQF - Red Deer - Canada",
            "searchName": "YQF-Red Deer-Red Deer Havalimani-Calgary-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQG",
            "countryCode": "CA",
            "cityCode": "YQG",
            "cityName": "Windsor",
            "name": "[YQG] - Windsor - YQG - Windsor - Canada",
            "searchName": "YQG-Windsor-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XEC",
            "countryCode": "CA",
            "cityCode": "YQG",
            "cityName": "Windsor",
            "name": "[XEC] - Windsor Railway Station - YQG - Windsor - Canada",
            "searchName": "XEC-YQG-Windsor Railway Station-Windsor-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQH",
            "countryCode": "CA",
            "cityCode": "YQH",
            "cityName": "Watson Lake",
            "name": "[YQH] - Watson Lake - YQH - Watson Lake - Canada",
            "searchName": "YQH-Watson Lake-Watson Lake Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQI",
            "countryCode": "CA",
            "cityCode": "YQI",
            "cityName": "Yarmouth",
            "name": "[YQI] - Yarmouth - YQI - Yarmouth - Canada",
            "searchName": "YQI-Yarmouth-Municipal Yarmouth-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQJ",
            "countryCode": "CA",
            "cityCode": "YQJ",
            "cityName": "Quadra Island",
            "name": "[YQJ] - April Point SPB - YQJ - Quadra Island - Canada",
            "searchName": "YQJ-April Point SPB-Quadra Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQK",
            "countryCode": "CA",
            "cityCode": "YQK",
            "cityName": "Kenora",
            "name": "[YQK] - Kenora - YQK - Kenora - Canada",
            "searchName": "YQK-Kenora-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQL",
            "countryCode": "CA",
            "cityCode": "YQL",
            "cityName": "Lethbridge",
            "name": "[YQL] - Lethbridge - YQL - Lethbridge - Canada",
            "searchName": "YQL-Lethbridge-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQM",
            "countryCode": "CA",
            "cityCode": "YQM",
            "cityName": "Moncton",
            "name": "[YQM] - Greater Moncton International Airport - YQM - Moncton - Canada",
            "searchName": "YQM-Moncton-Greater Moncton International Airport-Moncton Intl-Airport-Moncton New Brunswick-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XDP",
            "countryCode": "CA",
            "cityCode": "YQM",
            "cityName": "Moncton",
            "name": "[XDP] - Moncton Railway Station - YQM - Moncton - Canada",
            "searchName": "XDP-YQM-Moncton Railway Station-Moncton New Brunswick-Moncton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQN",
            "countryCode": "CA",
            "cityCode": "YQN",
            "cityName": "Nakina",
            "name": "[YQN] - Nakina - YQN - Nakina - Canada",
            "searchName": "YQN-Nakina-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQQ",
            "countryCode": "CA",
            "cityCode": "YQQ",
            "cityName": "Comox",
            "name": "[YQQ] - Comox - YQQ - Comox - Canada",
            "searchName": "YQQ-Comox-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQR",
            "countryCode": "CA",
            "cityCode": "YQR",
            "cityName": "Regina",
            "name": "[YQR] - Regina - YQR - Regina - Canada",
            "searchName": "YQR-Regina Kanada-Regina-R-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQS",
            "countryCode": "CA",
            "cityCode": "YQS",
            "cityName": "St Thomas",
            "name": "[YQS] - Pembroke Area Municipalcipal - YQS - St Thomas - Canada",
            "searchName": "YQS-Kommunaler Flughafen Pembroke Area-Pembroke Area Municipalcipal-Pembroke Area  Havalimani-St. Thomas-St Thomas-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQT",
            "countryCode": "CA",
            "cityCode": "YQT",
            "cityName": "Thunder Bay",
            "name": "[YQT] - Thunder Bay - YQT - Thunder Bay - Canada",
            "searchName": "YQT-Thunder Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQU",
            "countryCode": "CA",
            "cityCode": "YQU",
            "cityName": "Grande Prairie",
            "name": "[YQU] - Grande Prairie - YQU - Grande Prairie - Canada",
            "searchName": "YQU-Grande Prairie-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQV",
            "countryCode": "CA",
            "cityCode": "YQV",
            "cityName": "Yorkton",
            "name": "[YQV] - Yorkton - YQV - Yorkton - Canada",
            "searchName": "YQV-Yorkton-Yorkton Havalimani-Yoronjima-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQW",
            "countryCode": "CA",
            "cityCode": "YQW",
            "cityName": "North Battleford",
            "name": "[YQW] - North Battleford - YQW - North Battleford - Canada",
            "searchName": "YQW-North Battleford-North Battleford Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQX",
            "countryCode": "CA",
            "cityCode": "YQX",
            "cityName": "Gander",
            "name": "[YQX] - Gander International - YQX - Gander - Canada",
            "searchName": "YQX-Gander-Gander International-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQY",
            "countryCode": "CA",
            "cityCode": "YQY",
            "cityName": "Sydney",
            "name": "[YQY] - Sydney - YQY - Sydney - Canada",
            "searchName": "YQY-Sydney Kanada-Sydney-Cape Breton Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQZ",
            "countryCode": "CA",
            "cityCode": "YQZ",
            "cityName": "Quesnel",
            "name": "[YQZ] - Quesnel - YQZ - Quesnel - Canada",
            "searchName": "YQZ-Quesnel-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRA",
            "countryCode": "CA",
            "cityCode": "YRA",
            "cityName": "Rae Lakes",
            "name": "[YRA] - Rae Lakes - YRA - Rae Lakes - Canada",
            "searchName": "YRA-Rae Lakes-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRB",
            "countryCode": "CA",
            "cityCode": "YRB",
            "cityName": "Resolute",
            "name": "[YRB] - Resolute - YRB - Resolute - Canada",
            "searchName": "YRB-Resolute Bay-Resolute-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRC",
            "countryCode": "CA",
            "cityCode": "YRC",
            "cityName": "Refuge Cove",
            "name": "[YRC] - Refuge Cove SPB - YRC - Refuge Cove - Canada",
            "searchName": "YRC-Refuge Cove SPB-Refuge Cove-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRD",
            "countryCode": "CA",
            "cityCode": "YRD",
            "cityName": "Dean River",
            "name": "[YRD] - Dean River - YRD - Dean River - Canada",
            "searchName": "YRD-Kommunaler Flughafen-Dean River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRE",
            "countryCode": "CA",
            "cityCode": "YRE",
            "cityName": "Resolution Island",
            "name": "[YRE] - Resolution Island - YRE - Resolution Island - Canada",
            "searchName": "YRE-Resolution Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRF",
            "countryCode": "CA",
            "cityCode": "YRF",
            "cityName": "Cartwright",
            "name": "[YRF] - Cartwright - YRF - Cartwright - Canada",
            "searchName": "YRF-Cartwright-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRG",
            "countryCode": "CA",
            "cityCode": "YRG",
            "cityName": "Rigolet",
            "name": "[YRG] - Rigolet - YRG - Rigolet - Canada",
            "searchName": "YRG-Rigolet-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRI",
            "countryCode": "CA",
            "cityCode": "YRI",
            "cityName": "Riviere Du Loup",
            "name": "[YRI] - Riviere Du Loup - YRI - Riviere Du Loup - Canada",
            "searchName": "YRI-Riviere Du Loup-Riviere Du Loup Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRJ",
            "countryCode": "CA",
            "cityCode": "YRJ",
            "cityName": "Roberval",
            "name": "[YRJ] - Roberval - YRJ - Roberval - Canada",
            "searchName": "YRJ-Roberval-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRL",
            "countryCode": "CA",
            "cityCode": "YRL",
            "cityName": "Red Lake",
            "name": "[YRL] - Red Lake - YRL - Red Lake - Canada",
            "searchName": "YRL-Red Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRM",
            "countryCode": "CA",
            "cityCode": "YRM",
            "cityName": "Rocky Mountain House",
            "name": "[YRM] - Rocky Mountain House - YRM - Rocky Mountain House - Canada",
            "searchName": "YRM-Rocky Mountain House-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRN",
            "countryCode": "CA",
            "cityCode": "YRN",
            "cityName": "Owekeno Village",
            "name": "[YRN] - Rivers Inlet - YRN - Owekeno Village - Canada",
            "searchName": "YRN-Rivers Inlet-Owekeno Village-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRQ",
            "countryCode": "CA",
            "cityCode": "YRQ",
            "cityName": "Trois-Rivieres",
            "name": "[YRQ] - Trois-Rivieres - YRQ - Trois-Rivieres - Canada",
            "searchName": "YRQ-Rivieres QC - Flughafen-Trois-Rivieres-Three Rivers Havalimani-Trois Rivieres-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRR",
            "countryCode": "CA",
            "cityCode": "YRR",
            "cityName": "Stuart Island",
            "name": "[YRR] - Stuart Island - YRR - Stuart Island - Canada",
            "searchName": "YRR-Stuart Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRS",
            "countryCode": "CA",
            "cityCode": "YRS",
            "cityName": "Red Sucker Lake",
            "name": "[YRS] - Red Sucker Lake - YRS - Red Sucker Lake - Canada",
            "searchName": "YRS-Red Sucker Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRT",
            "countryCode": "CA",
            "cityCode": "YRT",
            "cityName": "Rankin Inlet",
            "name": "[YRT] - Nuuk - YRT - Rankin Inlet - Canada",
            "searchName": "YRT-Rankin Inlet-Nuuk-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRV",
            "countryCode": "CA",
            "cityCode": "YRV",
            "cityName": "Revelstoke",
            "name": "[YRV] - Revelstoke - YRV - Revelstoke - Canada",
            "searchName": "YRV-Revelstoke-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSA",
            "countryCode": "CA",
            "cityCode": "YSA",
            "cityName": "Sable Island",
            "name": "[YSA] - Sable Island - YSA - Sable Island - Canada",
            "searchName": "YSA-Sable Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSB",
            "countryCode": "CA",
            "cityCode": "YSB",
            "cityName": "Sudbury",
            "name": "[YSB] - Sudbury - YSB - Sudbury - Canada",
            "searchName": "YSB-Sudbury-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XDY",
            "countryCode": "CA",
            "cityCode": "YSB",
            "cityName": "Sudbury",
            "name": "[XDY] - Sudbury Jct Railway - YSB - Sudbury - Canada",
            "searchName": "XDY-YSB-Sudbury Jct Railway-Sudbury-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSC",
            "countryCode": "CA",
            "cityCode": "YSC",
            "cityName": "Sherbrooke",
            "name": "[YSC] - Sherbrooke - YSC - Sherbrooke - Canada",
            "searchName": "YSC-Sherbrooke-Sherbrooke Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSD",
            "countryCode": "CA",
            "cityCode": "YSD",
            "cityName": "Suffield",
            "name": "[YSD] - Suffield - YSD - Suffield - Canada",
            "searchName": "YSD-Suffield-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSE",
            "countryCode": "CA",
            "cityCode": "YSE",
            "cityName": "Squamish",
            "name": "[YSE] - Squamish - YSE - Squamish - Canada",
            "searchName": "YSE-Squamish-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSF",
            "countryCode": "CA",
            "cityCode": "YSF",
            "cityName": "Stony Rapids",
            "name": "[YSF] - Stony Rapids - YSF - Stony Rapids - Canada",
            "searchName": "YSF-Stony Rapids-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSG",
            "countryCode": "CA",
            "cityCode": "YSG",
            "cityName": "Lutselke",
            "name": "[YSG] - Lutselke - YSG - Lutselke - Canada",
            "searchName": "YSG-Lutselke-Lutselke/Snowdrift-Lutselke\\/Snowdrift-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSH",
            "countryCode": "CA",
            "cityCode": "YSH",
            "cityName": "Smith Falls",
            "name": "[YSH] - Smith Falls - YSH - Smith Falls - Canada",
            "searchName": "YSH-Smith Falls-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSI",
            "countryCode": "CA",
            "cityCode": "YSI",
            "cityName": "Sans Souci",
            "name": "[YSI] - Sans Souci - YSI - Sans Souci - Canada",
            "searchName": "YSI-Sans Souci-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSJ",
            "countryCode": "CA",
            "cityCode": "YSJ",
            "cityName": "Saint John",
            "name": "[YSJ] - Saint John - YSJ - Saint John - Canada",
            "searchName": "YSJ-Saint John-Saint-Jean-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSK",
            "countryCode": "CA",
            "cityCode": "YSK",
            "cityName": "Sanikiluaq",
            "name": "[YSK] - Sanikiluaq - YSK - Sanikiluaq - Canada",
            "searchName": "YSK-Sanikiluaq-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSL",
            "countryCode": "CA",
            "cityCode": "YSL",
            "cityName": "St Leonard",
            "name": "[YSL] - St Leonard - YSL - St Leonard - Canada",
            "searchName": "YSL-Edmunston-St Leonard-St. Leonard-Saint Leonard-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSM",
            "countryCode": "CA",
            "cityCode": "YSM",
            "cityName": "Fort Smith",
            "name": "[YSM] - Fort Smith - YSM - Fort Smith - Canada",
            "searchName": "YSM-Fort Smith-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSN",
            "countryCode": "CA",
            "cityCode": "YSN",
            "cityName": "Salmon Arm",
            "name": "[YSN] - Salmon Arm - YSN - Salmon Arm - Canada",
            "searchName": "YSN-Kommunaler Flughafen-Salmon Arm-Salmon Arm Belediyesi Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSO",
            "countryCode": "CA",
            "cityCode": "YSO",
            "cityName": "Postville",
            "name": "[YSO] - Postville - YSO - Postville - Canada",
            "searchName": "YSO-Postville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSP",
            "countryCode": "CA",
            "cityCode": "YSP",
            "cityName": "Marathon",
            "name": "[YSP] - Marathon - YSP - Marathon - Canada",
            "searchName": "YSP-Marathon-Marathon  Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSQ",
            "countryCode": "CA",
            "cityCode": "YSQ",
            "cityName": "Spring Island",
            "name": "[YSQ] - Spring Island - YSQ - Spring Island - Canada",
            "searchName": "YSQ-Spring Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSS",
            "countryCode": "CA",
            "cityCode": "YSS",
            "cityName": "Slate Island",
            "name": "[YSS] - Slate Island - YSS - Slate Island - Canada",
            "searchName": "YSS-Slate Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YST",
            "countryCode": "CA",
            "cityCode": "YST",
            "cityName": "Ste Therese Point",
            "name": "[YST] - Ste Therese Point - YST - Ste Therese Point - Canada",
            "searchName": "YST-Ste Therese Point-Sainte Therese Point-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSU",
            "countryCode": "CA",
            "cityCode": "YSU",
            "cityName": "Summerside",
            "name": "[YSU] - Summerside - YSU - Summerside - Canada",
            "searchName": "YSU-Summerside-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZG",
            "countryCode": "CA",
            "cityCode": "YSW",
            "cityName": "Salluit",
            "name": "[YZG] - Salluit - YSW - Salluit - Canada",
            "searchName": "YZG-YSW-Salluit-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSX",
            "countryCode": "CA",
            "cityCode": "YSX",
            "cityName": "Shearwater",
            "name": "[YSX] - Shearwater - YSX - Shearwater - Canada",
            "searchName": "YSX-Shearwater-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSY",
            "countryCode": "CA",
            "cityCode": "YSY",
            "cityName": "Sachs Harbour",
            "name": "[YSY] - Sachs Harbour - YSY - Sachs Harbour - Canada",
            "searchName": "YSY-Sachs Harbour-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YSZ",
            "countryCode": "CA",
            "cityCode": "YSZ",
            "cityName": "Squirrel Cove",
            "name": "[YSZ] - Squirrel Cove - YSZ - Squirrel Cove - Canada",
            "searchName": "YSZ-Squirrel Cove-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTA",
            "countryCode": "CA",
            "cityCode": "YTA",
            "cityName": "Pembroke",
            "name": "[YTA] - Pembroke And Area Airport - YTA - Pembroke - Canada",
            "searchName": "YTA-Pembroke And Area Airport-Pembroke-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTB",
            "countryCode": "CA",
            "cityCode": "YTB",
            "cityName": "Hartley Bay",
            "name": "[YTB] - Hartley Bay - YTB - Hartley Bay - Canada",
            "searchName": "YTB-Hartley Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTC",
            "countryCode": "CA",
            "cityCode": "YTC",
            "cityName": "Sturdee",
            "name": "[YTC] - Sturdee - YTC - Sturdee - Canada",
            "searchName": "YTC-Sturdee-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTD",
            "countryCode": "CA",
            "cityCode": "YTD",
            "cityName": "Thicket Portage",
            "name": "[YTD] - Thicket Portage - YTD - Thicket Portage - Canada",
            "searchName": "YTD-Thicket Portage-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTE",
            "countryCode": "CA",
            "cityCode": "YTE",
            "cityName": "Cape Dorset",
            "name": "[YTE] - Cape Dorset - YTE - Cape Dorset - Canada",
            "searchName": "YTE-Cape Dorset-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTF",
            "countryCode": "CA",
            "cityCode": "YTF",
            "cityName": "Alma",
            "name": "[YTF] - Alma - YTF - Alma - Canada",
            "searchName": "YTF-Alma-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTG",
            "countryCode": "CA",
            "cityCode": "YTG",
            "cityName": "Sullivan Bay",
            "name": "[YTG] - Sullivan Bay - YTG - Sullivan Bay - Canada",
            "searchName": "YTG-Sullivan Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTH",
            "countryCode": "CA",
            "cityCode": "YTH",
            "cityName": "Thompson",
            "name": "[YTH] - Thompson - YTH - Thompson - Canada",
            "searchName": "YTH-Thompson-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTI",
            "countryCode": "CA",
            "cityCode": "YTI",
            "cityName": "Triple Island",
            "name": "[YTI] - Triple Island - YTI - Triple Island - Canada",
            "searchName": "YTI-Triple Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTK",
            "countryCode": "CA",
            "cityCode": "YTK",
            "cityName": "Tulugak",
            "name": "[YTK] - Tulugak - YTK - Tulugak - Canada",
            "searchName": "YTK-Tulugak-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTL",
            "countryCode": "CA",
            "cityCode": "YTL",
            "cityName": "Big Trout",
            "name": "[YTL] - Big Trout - YTL - Big Trout - Canada",
            "searchName": "YTL-Big Trout-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTM",
            "countryCode": "CA",
            "cityCode": "YTM",
            "cityName": "Mont Tremblant",
            "name": "[YTM] - La Macaza - YTM - Mont Tremblant - Canada",
            "searchName": "YTM-La Macaza-Uluslararasi Havalimani-Mont Tremblant-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTN",
            "countryCode": "CA",
            "cityCode": "YTN",
            "cityName": "Riviere Au Tonnerre",
            "name": "[YTN] - Riviere Au Tonnerre - YTN - Riviere Au Tonnerre - Canada",
            "searchName": "YTN-Riviere Au Tonnerre-Riviere-Au-Tonnerre-Riviere au Tonnerre-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTQ",
            "countryCode": "CA",
            "cityCode": "YTQ",
            "cityName": "Tasiujuaq",
            "name": "[YTQ] - Tasiujuaq - YTQ - Tasiujuaq - Canada",
            "searchName": "YTQ-Tasiujuaq-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTR",
            "countryCode": "CA",
            "cityCode": "YTR",
            "cityName": "Trenton",
            "name": "[YTR] - Trenton - YTR - Trenton - Canada",
            "searchName": "YTR-Trenton-Trenton  Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTS",
            "countryCode": "CA",
            "cityCode": "YTS",
            "cityName": "Timmins",
            "name": "[YTS] - Timmins - YTS - Timmins - Canada",
            "searchName": "YTS-Timmins-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTT",
            "countryCode": "CA",
            "cityCode": "YTT",
            "cityName": "Tisdale",
            "name": "[YTT] - Tisdale - YTT - Tisdale - Canada",
            "searchName": "YTT-Tisdale-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YTX",
            "countryCode": "CA",
            "cityCode": "YTX",
            "cityName": "Telegraph Creek",
            "name": "[YTX] - Telegraph Creek - YTX - Telegraph Creek - Canada",
            "searchName": "YTX-Telegraph Creek-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "PIW",
            "countryCode": "CA",
            "cityCode": "PIW",
            "cityName": "Pikwitonei",
            "name": "[PIW] - Pikwitonei - PIW - Pikwitonei - Canada",
            "searchName": "PIW-Pikwitonei-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOO",
            "countryCode": "CA",
            "cityCode": "QWA",
            "cityName": "Oshawa",
            "name": "[YOO] - Oshawa - QWA - Oshawa - Canada",
            "searchName": "YOO-QWA-Oshawa-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "QBC",
            "countryCode": "CA",
            "cityCode": "QBC",
            "cityName": "Bella Coola",
            "name": "[QBC] - Bella Coola - QBC - Bella Coola - Canada",
            "searchName": "QBC-Bella Coola-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "MSA",
            "countryCode": "CA",
            "cityCode": "MSA",
            "cityName": "Muskrat Dam",
            "name": "[MSA] - Muskrat Dam - MSA - Muskrat Dam - Canada",
            "searchName": "MSA-Muskrat Dam-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "NML",
            "countryCode": "CA",
            "cityCode": "NML",
            "cityName": "Mildred Lake",
            "name": "[NML] - Mildred Lake - NML - Mildred Lake - Canada",
            "searchName": "NML-Mildred Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "NWP",
            "countryCode": "CA",
            "cityCode": "NWP",
            "cityName": "Argentia",
            "name": "[NWP] - NS - NWP - Argentia - Canada",
            "searchName": "NWP-NS-Argentia-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZRJ",
            "countryCode": "CA",
            "cityCode": "ZRJ",
            "cityName": "Round Lake",
            "name": "[ZRJ] - Round Lake - ZRJ - Round Lake - Canada",
            "searchName": "ZRJ-Round Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZSJ",
            "countryCode": "CA",
            "cityCode": "ZSJ",
            "cityName": "Sandy Lake",
            "name": "[ZSJ] - Sandy Lake - ZSJ - Sandy Lake - Canada",
            "searchName": "ZSJ-Sandy Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZSP",
            "countryCode": "CA",
            "cityCode": "ZSP",
            "cityName": "St Paul",
            "name": "[ZSP] - St Paul - ZSP - St Paul - Canada",
            "searchName": "ZSP-St Paul-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZST",
            "countryCode": "CA",
            "cityCode": "ZST",
            "cityName": "Stewart",
            "name": "[ZST] - Stewart - ZST - Stewart - Canada",
            "searchName": "ZST-Stewart-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZTB",
            "countryCode": "CA",
            "cityCode": "ZTB",
            "cityName": "Tete-a-La Baleine",
            "name": "[ZTB] - Tete-a-La Baleine - ZTB - Tete-a-La Baleine - Canada",
            "searchName": "ZTB-Tete-a-La Baleine-A-La-Baleine-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZTM",
            "countryCode": "CA",
            "cityCode": "ZTM",
            "cityName": "Shamattawa",
            "name": "[ZTM] - Shamattawa - ZTM - Shamattawa - Canada",
            "searchName": "ZTM-Shamattawa-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZNG",
            "countryCode": "CA",
            "cityCode": "ZNG",
            "cityName": "Negginan",
            "name": "[ZNG] - Negginan - ZNG - Negginan - Canada",
            "searchName": "ZNG-Negginan-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZNU",
            "countryCode": "CA",
            "cityCode": "ZNU",
            "cityName": "Namu",
            "name": "[ZNU] - Namu - ZNU - Namu - Canada",
            "searchName": "ZNU-Namu-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZOF",
            "countryCode": "CA",
            "cityCode": "ZOF",
            "cityName": "Ocean Falls",
            "name": "[ZOF] - Ocean Falls - ZOF - Ocean Falls - Canada",
            "searchName": "ZOF-Ocean Falls-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZPB",
            "countryCode": "CA",
            "cityCode": "ZPB",
            "cityName": "Sachigo Lake",
            "name": "[ZPB] - Sachigo Lake - ZPB - Sachigo Lake - Canada",
            "searchName": "ZPB-Sachigo Lake-Sachigo G-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZPO",
            "countryCode": "CA",
            "cityCode": "ZPO",
            "cityName": "Pine House",
            "name": "[ZPO] - Pine House - ZPO - Pine House - Canada",
            "searchName": "ZPO-Pine House-Pine House Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZQS",
            "countryCode": "CA",
            "cityCode": "ZQS",
            "cityName": "Queen Charlotte Island",
            "name": "[ZQS] - Queen Charlotte Island - ZQS - Queen Charlotte Island - Canada",
            "searchName": "ZQS-Queen Charlotte Island-Queen Charlotte Adasi Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZWL",
            "countryCode": "CA",
            "cityCode": "ZWL",
            "cityName": "Wollaston Lake",
            "name": "[ZWL] - Wollaston Lake - ZWL - Wollaston Lake - Canada",
            "searchName": "ZWL-Wollaston Lake-Wollaston G-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZTS",
            "countryCode": "CA",
            "cityCode": "ZTS",
            "cityName": "Tahsis",
            "name": "[ZTS] - Tahsis - ZTS - Tahsis - Canada",
            "searchName": "ZTS-Tahsis-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZUC",
            "countryCode": "CA",
            "cityCode": "ZUC",
            "cityName": "Ignace",
            "name": "[ZUC] - Ignace - ZUC - Ignace - Canada",
            "searchName": "ZUC-Ignace-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZUM",
            "countryCode": "CA",
            "cityCode": "ZUM",
            "cityName": "Churchill Falls",
            "name": "[ZUM] - Churchill Falls - ZUM - Churchill Falls - Canada",
            "searchName": "ZUM-Churchill Falls-Churchhill Falls-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZMH",
            "countryCode": "CA",
            "cityCode": "ZMH",
            "cityName": "108 Mile Ranch",
            "name": "[ZMH] - 108 Mile Ranch - ZMH - 108 Mile Ranch - Canada",
            "searchName": "ZMH-108 Mile Ranch-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZMT",
            "countryCode": "CA",
            "cityCode": "ZMT",
            "cityName": "Masset",
            "name": "[ZMT] - Masset - ZMT - Masset - Canada",
            "searchName": "ZMT-Masset-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZLT",
            "countryCode": "CA",
            "cityCode": "ZLT",
            "cityName": "La Tabatiere",
            "name": "[ZLT] - La Tabatiere - ZLT - La Tabatiere - Canada",
            "searchName": "ZLT-La Tabatiere-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZJG",
            "countryCode": "CA",
            "cityCode": "ZJG",
            "cityName": "Jenpeg",
            "name": "[ZJG] - Jenpeg - ZJG - Jenpeg - Canada",
            "searchName": "ZJG-Jenpeg-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZJN",
            "countryCode": "CA",
            "cityCode": "ZJN",
            "cityName": "Swan River",
            "name": "[ZJN] - Swan River - ZJN - Swan River - Canada",
            "searchName": "ZJN-Kommunaler Flughafen-Swan River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZKE",
            "countryCode": "CA",
            "cityCode": "ZKE",
            "cityName": "Kaschechewan",
            "name": "[ZKE] - Kaschechewan - ZKE - Kaschechewan - Canada",
            "searchName": "ZKE-Kaschechewan-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZKG",
            "countryCode": "CA",
            "cityCode": "ZKG",
            "cityName": "Kegaska",
            "name": "[ZKG] - Kegaska - ZKG - Kegaska - Canada",
            "searchName": "ZKG-Kegaska-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZFW",
            "countryCode": "CA",
            "cityCode": "ZFW",
            "cityName": "Fairview",
            "name": "[ZFW] - Fairview - ZFW - Fairview - Canada",
            "searchName": "ZFW-Fairview-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZGF",
            "countryCode": "CA",
            "cityCode": "ZGF",
            "cityName": "Grand Forks",
            "name": "[ZGF] - Grand Forks - ZGF - Grand Forks - Canada",
            "searchName": "ZGF-Grand Forks-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZGI",
            "countryCode": "CA",
            "cityCode": "ZGI",
            "cityName": "Gods River",
            "name": "[ZGI] - Gods River - ZGI - Gods River - Canada",
            "searchName": "ZGI-Gods River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZFA",
            "countryCode": "CA",
            "cityCode": "ZFA",
            "cityName": "Faro",
            "name": "[ZFA] - Faro - ZFA - Faro - Canada",
            "searchName": "ZFA-Faro-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZFB",
            "countryCode": "CA",
            "cityCode": "ZFB",
            "cityName": "Old Fort Bay",
            "name": "[ZFB] - Old Fort Bay - ZFB - Old Fort Bay - Canada",
            "searchName": "ZFB-Old Fort Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZFD",
            "countryCode": "CA",
            "cityCode": "ZFD",
            "cityName": "Fond Du Lac",
            "name": "[ZFD] - Fond Du Lac - ZFD - Fond Du Lac - Canada",
            "searchName": "ZFD-Fond Du Lac-Fond du Lac-Fond-Du-Lac-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZFL",
            "countryCode": "CA",
            "cityCode": "ZFL",
            "cityName": "South Trout Lake",
            "name": "[ZFL] - South Trout Lake - ZFL - South Trout Lake - Canada",
            "searchName": "ZFL-South Trout Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZFM",
            "countryCode": "CA",
            "cityCode": "ZFM",
            "cityName": "Fort Mcpherson",
            "name": "[ZFM] - Fort Mcpherson - ZFM - Fort Mcpherson - Canada",
            "searchName": "ZFM-Fort Mcpherson-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZFN",
            "countryCode": "CA",
            "cityCode": "ZFN",
            "cityName": "Tulita",
            "name": "[ZFN] - Tulita - ZFN - Tulita - Canada",
            "searchName": "ZFN-Tulita-Tulita/Fort Norman-Tulita\\/Fort Norman-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZEL",
            "countryCode": "CA",
            "cityCode": "ZEL",
            "cityName": "Bella Bella",
            "name": "[ZEL] - Bella Bella - ZEL - Bella Bella - Canada",
            "searchName": "ZEL-Bella Bella-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZEM",
            "countryCode": "CA",
            "cityCode": "ZEM",
            "cityName": "East Main",
            "name": "[ZEM] - East Main - ZEM - East Main - Canada",
            "searchName": "ZEM-East Main-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZHO",
            "countryCode": "CA",
            "cityCode": "ZHO",
            "cityName": "Houston",
            "name": "[ZHO] - Houston Bus Station - ZHO - Houston - Canada",
            "searchName": "ZHO-Houston Bus Station-Houston-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZHP",
            "countryCode": "CA",
            "cityCode": "ZHP",
            "cityName": "High Prairie",
            "name": "[ZHP] - High Prairie - ZHP - High Prairie - Canada",
            "searchName": "ZHP-High Prairie-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZGR",
            "countryCode": "CA",
            "cityCode": "ZGR",
            "cityName": "Little Grand Rapids",
            "name": "[ZGR] - Little Grand Rapids - ZGR - Little Grand Rapids - Canada",
            "searchName": "ZGR-Little Grand Rapids-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZGS",
            "countryCode": "CA",
            "cityCode": "ZGS",
            "cityName": "Gethsemani",
            "name": "[ZGS] - Gethsemani - ZGS - Gethsemani - Canada",
            "searchName": "ZGS-Gethsemani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNO",
            "countryCode": "CA",
            "cityCode": "YNO",
            "cityName": "North Spirit Lake",
            "name": "[YNO] - North Spirit Lake - YNO - North Spirit Lake - Canada",
            "searchName": "YNO-North Spirit Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNP",
            "countryCode": "CA",
            "cityCode": "YNP",
            "cityName": "Natuashish",
            "name": "[YNP] - Natuashish - YNP - Natuashish - Canada",
            "searchName": "YNP-Natuashish-Natuashish Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNR",
            "countryCode": "CA",
            "cityCode": "YNR",
            "cityName": "Arnes",
            "name": "[YNR] - Arnes - YNR - Arnes - Canada",
            "searchName": "YNR-Arnes-Arnes Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNS",
            "countryCode": "CA",
            "cityCode": "YNS",
            "cityName": "Nemiscau",
            "name": "[YNS] - Nemiscau - YNS - Nemiscau - Canada",
            "searchName": "YNS-Nemiscau-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNH",
            "countryCode": "CA",
            "cityCode": "YNH",
            "cityName": "Hudson s  Hope",
            "name": "[YNH] - Hudson Hope - YNH - Hudson s  Hope - Canada",
            "searchName": "YNH-Hudson Hope-Hudson s  Hope-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNI",
            "countryCode": "CA",
            "cityCode": "YNI",
            "cityName": "Nitchequon",
            "name": "[YNI] - Nitchequon - YNI - Nitchequon - Canada",
            "searchName": "YNI-Nitchequon-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNK",
            "countryCode": "CA",
            "cityCode": "YNK",
            "cityName": "Nootka Sound",
            "name": "[YNK] - Nootka Sound - YNK - Nootka Sound - Canada",
            "searchName": "YNK-Nootka Sound-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNL",
            "countryCode": "CA",
            "cityCode": "YNL",
            "cityName": "Points North Landing",
            "name": "[YNL] - Points North Landing - YNL - Points North Landing - Canada",
            "searchName": "YNL-Points North Landing-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNM",
            "countryCode": "CA",
            "cityCode": "YNM",
            "cityName": "Matagami",
            "name": "[YNM] - Matagami - YNM - Matagami - Canada",
            "searchName": "YNM-Matagami-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMT",
            "countryCode": "CA",
            "cityCode": "YMT",
            "cityName": "Chibougamau",
            "name": "[YMT] - Chibougamau - YMT - Chibougamau - Canada",
            "searchName": "YMT-Chibougamau-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMU",
            "countryCode": "CA",
            "cityCode": "YMU",
            "cityName": "Mansons Landing",
            "name": "[YMU] - Mansons Landing SPB - YMU - Mansons Landing - Canada",
            "searchName": "YMU-Mansons Landing SPB-Mansons Landing-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMV",
            "countryCode": "CA",
            "cityCode": "YMV",
            "cityName": "Mary River",
            "name": "[YMV] - Mary River - YMV - Mary River - Canada",
            "searchName": "YMV-Mary River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMW",
            "countryCode": "CA",
            "cityCode": "YMW",
            "cityName": "Maniwaki",
            "name": "[YMW] - Maniwaki - YMW - Maniwaki - Canada",
            "searchName": "YMW-Maniwaki-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNA",
            "countryCode": "CA",
            "cityCode": "YNA",
            "cityName": "Natashquan",
            "name": "[YNA] - Natashquan - YNA - Natashquan - Canada",
            "searchName": "YNA-Natashquan-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNC",
            "countryCode": "CA",
            "cityCode": "YNC",
            "cityName": "Wemindji",
            "name": "[YNC] - Wemindji - YNC - Wemindji - Canada",
            "searchName": "YNC-Wemindji-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNE",
            "countryCode": "CA",
            "cityCode": "YNE",
            "cityName": "Norway House",
            "name": "[YNE] - Norway House - YNE - Norway House - Canada",
            "searchName": "YNE-Norway House-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNF",
            "countryCode": "CA",
            "cityCode": "YNF",
            "cityName": "Corner Brook",
            "name": "[YNF] - Deer Lake/Stephenville - YNF - Corner Brook - Canada",
            "searchName": "YNF-Deer Lake/Stephenville-Corner Brook-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOA",
            "countryCode": "CA",
            "cityCode": "YOA",
            "cityName": "Ekati",
            "name": "[YOA] - Ekati - YOA - Ekati - Canada",
            "searchName": "YOA-Ekati-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOC",
            "countryCode": "CA",
            "cityCode": "YOC",
            "cityName": "Old Crow",
            "name": "[YOC] - Old Crow - YOC - Old Crow - Canada",
            "searchName": "YOC-Old Crow-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOD",
            "countryCode": "CA",
            "cityCode": "YOD",
            "cityName": "Cold Lake",
            "name": "[YOD] - Cold Lake - YOD - Cold Lake - Canada",
            "searchName": "YOD-Cold Lake-Cold Lake Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOE",
            "countryCode": "CA",
            "cityCode": "YOE",
            "cityName": "Falher",
            "name": "[YOE] - Falher - YOE - Falher - Canada",
            "searchName": "YOE-Falher-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOG",
            "countryCode": "CA",
            "cityCode": "YOG",
            "cityName": "Ogoki",
            "name": "[YOG] - Ogoki - YOG - Ogoki - Canada",
            "searchName": "YOG-Ogoki-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOH",
            "countryCode": "CA",
            "cityCode": "YOH",
            "cityName": "Oxford House",
            "name": "[YOH] - Oxford House - YOH - Oxford House - Canada",
            "searchName": "YOH-Oxford House-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOI",
            "countryCode": "CA",
            "cityCode": "YOI",
            "cityName": "Opinaca",
            "name": "[YOI] - Opinaca - YOI - Opinaca - Canada",
            "searchName": "YOI-Opinaca-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOJ",
            "countryCode": "CA",
            "cityCode": "YOJ",
            "cityName": "High Level",
            "name": "[YOJ] - Footner Lake - YOJ - High Level - Canada",
            "searchName": "YOJ-High Level-Footner Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YNX",
            "countryCode": "CA",
            "cityCode": "YNX",
            "cityName": "Snap Lake",
            "name": "[YNX] - Snap Lake - YNX - Snap Lake - Canada",
            "searchName": "YNX-Snap Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOP",
            "countryCode": "CA",
            "cityCode": "YOP",
            "cityName": "Rainbow Lake",
            "name": "[YOP] - Rainbow Lake - YOP - Rainbow Lake - Canada",
            "searchName": "YOP-Rainbow Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOS",
            "countryCode": "CA",
            "cityCode": "YOS",
            "cityName": "Owen Sound",
            "name": "[YOS] - Billy Bishop Regional - YOS - Owen Sound - Canada",
            "searchName": "YOS-Regionaler Flughafen Billy Bishop-Billy Bishop Regional-Billy Bishop Yerel Havalimani-Owen Sound-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YUB",
            "countryCode": "CA",
            "cityCode": "YUB",
            "cityName": "Tuktoyaktuk",
            "name": "[YUB] - Tuktoyaktuk - YUB - Tuktoyaktuk - Canada",
            "searchName": "YUB-Tuktoyaktuk-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YUD",
            "countryCode": "CA",
            "cityCode": "YUD",
            "cityName": "Umiujaq",
            "name": "[YUD] - Umiujaq - YUD - Umiujaq - Canada",
            "searchName": "YUD-Umiujaq-Umiujag-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLH",
            "countryCode": "CA",
            "cityCode": "YLH",
            "cityName": "Lansdowne House",
            "name": "[YLH] - Lansdowne House - YLH - Lansdowne House - Canada",
            "searchName": "YLH-Lansdowne House-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKQ",
            "countryCode": "CA",
            "cityCode": "YKQ",
            "cityName": "Waskaganish",
            "name": "[YKQ] - Waskaganish - YKQ - Waskaganish - Canada",
            "searchName": "YKQ-Waskaganish-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLJ",
            "countryCode": "CA",
            "cityCode": "YLJ",
            "cityName": "Meadow Lake",
            "name": "[YLJ] - Meadow Lake - YLJ - Meadow Lake - Canada",
            "searchName": "YLJ-Meadow Lake-Meadow Lake Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLK",
            "countryCode": "CA",
            "cityCode": "YLK",
            "cityName": "Oro Station",
            "name": "[YLK] - Lake Simcoe Regional - YLK - Oro Station - Canada",
            "searchName": "YLK-Lake Simcoe Regional-Oro Station-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLL",
            "countryCode": "CA",
            "cityCode": "YLL",
            "cityName": "Lloydminster",
            "name": "[YLL] - Lloydminster - YLL - Lloydminster - Canada",
            "searchName": "YLL-Lloydminster-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YML",
            "countryCode": "CA",
            "cityCode": "YML",
            "cityName": "Murray Bay",
            "name": "[YML] - Charlevoix - YML - Murray Bay - Canada",
            "searchName": "YML-Charlevoix-Murray Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMM",
            "countryCode": "CA",
            "cityCode": "YMM",
            "cityName": "Fort Mcmurray",
            "name": "[YMM] - Fort Mcmurray - YMM - Fort Mcmurray - Canada",
            "searchName": "YMM-Fort Mcmurray-Fort McMurray-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMN",
            "countryCode": "CA",
            "cityCode": "YMN",
            "cityName": "Makkovik",
            "name": "[YMN] - Makkovik - YMN - Makkovik - Canada",
            "searchName": "YMN-Makkovik-Makkovik Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMO",
            "countryCode": "CA",
            "cityCode": "YMO",
            "cityName": "Moosonee",
            "name": "[YMO] - Moosonee - YMO - Moosonee - Canada",
            "searchName": "YMO-Moosonee-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMP",
            "countryCode": "CA",
            "cityCode": "YMP",
            "cityName": "Port McNeil",
            "name": "[YMP] - Port McNeil - YMP - Port McNeil - Canada",
            "searchName": "YMP-Port McNeil-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHU",
            "countryCode": "CA",
            "cityCode": "YMQ",
            "cityName": "Montreal",
            "name": "[YHU] - St Hubert - YMQ - Montreal - Canada",
            "searchName": "YHU-YMQ-St Hubert-St Hubert Havalimani-Montreal-Montr-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMX",
            "countryCode": "CA",
            "cityCode": "YMQ",
            "cityName": "Montreal",
            "name": "[YMX] - Mirabel - YMQ - Montreal - Canada",
            "searchName": "YMX-YMQ-Internationaler Flughafen Mirabel-Mirabel-Montreal-Montr-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMY",
            "countryCode": "CA",
            "cityCode": "YMQ",
            "cityName": "Montreal",
            "name": "[YMY] - Downtown Railway Station - YMQ - Montreal - Canada",
            "searchName": "YMY-YMQ-Downtown Railway Station-Montreal-Montr-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "PPB",
            "countryCode": "BR",
            "cityCode": "PPB",
            "cityName": "Presidente Prudente",
            "name": "[PPB] - Adhemar De Barros - PPB - Presidente Prudente - Brazil",
            "searchName": "PPB-A. De Barros-Adhemar De Barros-Presidente Prudente-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PPY",
            "countryCode": "BR",
            "cityCode": "PPY",
            "cityName": "Pouso Alegre",
            "name": "[PPY] - Pouso Alegre - PPY - Pouso Alegre - Brazil",
            "searchName": "PPY-Pouso Alegre-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PMG",
            "countryCode": "BR",
            "cityCode": "PMG",
            "cityName": "Ponta Pora",
            "name": "[PMG] - International - PMG - Ponta Pora - Brazil",
            "searchName": "PMG-Internationaler Flughafen-International-Ponta Pora-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PLL",
            "countryCode": "BR",
            "cityCode": "PLL",
            "cityName": "Ponta Pelada",
            "name": "[PLL] - Ponta Pelada - PLL - Ponta Pelada - Brazil",
            "searchName": "PLL-Ponta Pelada-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PMW",
            "countryCode": "BR",
            "cityCode": "PMW",
            "cityName": "Palmas",
            "name": "[PMW] - Palmas - PMW - Palmas - Brazil",
            "searchName": "PMW-Palmas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PNB",
            "countryCode": "BR",
            "cityCode": "PNB",
            "cityName": "Porto Nacional",
            "name": "[PNB] - Porto Nacional - PNB - Porto Nacional - Brazil",
            "searchName": "PNB-Porto Nacional-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PNG",
            "countryCode": "BR",
            "cityCode": "PNG",
            "cityName": "Paranagua",
            "name": "[PNG] - Paranagua - PNG - Paranagua - Brazil",
            "searchName": "PNG-Paranagua-Paranagua Belediyesi Havalimani-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PIV",
            "countryCode": "BR",
            "cityCode": "PIV",
            "cityName": "Pirapora",
            "name": "[PIV] - Pirapora - PIV - Pirapora - Brazil",
            "searchName": "PIV-Pirapora-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PHI",
            "countryCode": "BR",
            "cityCode": "PHI",
            "cityName": "Pinheiro",
            "name": "[PHI] - Pinheiro - PHI - Pinheiro - Brazil",
            "searchName": "PHI-Pinheiro-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PGZ",
            "countryCode": "BR",
            "cityCode": "PGZ",
            "cityName": "Ponta Grossa",
            "name": "[PGZ] - Sant Ana - PGZ - Ponta Grossa - Brazil",
            "searchName": "PGZ-Flughafem Sant Ana-Sant Ana-Ponta Grossa-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PIG",
            "countryCode": "BR",
            "cityCode": "PIG",
            "cityName": "Pitinga",
            "name": "[PIG] - Pitinga - PIG - Pitinga - Brazil",
            "searchName": "PIG-Pitinga-Pitinga AM-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PIN",
            "countryCode": "BR",
            "cityCode": "PIN",
            "cityName": "Parintins",
            "name": "[PIN] - Parintins - PIN - Parintins - Brazil",
            "searchName": "PIN-Parintins-Parintins Airport-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PDR",
            "countryCode": "BR",
            "cityCode": "PDR",
            "cityName": "Presidente Dutra",
            "name": "[PDR] - Presidente Dutra - PDR - Presidente Dutra - Brazil",
            "searchName": "PDR-Presidente Dutra-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PFB",
            "countryCode": "BR",
            "cityCode": "PFB",
            "cityName": "Passo Fundo",
            "name": "[PFB] - Passo Fundo - PFB - Passo Fundo - Brazil",
            "searchName": "PFB-Passo Fundo-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PET",
            "countryCode": "BR",
            "cityCode": "PET",
            "cityName": "Pelotas",
            "name": "[PET] - Federal - PET - Pelotas - Brazil",
            "searchName": "PET-Federal-Pelotas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PHB",
            "countryCode": "BR",
            "cityCode": "PHB",
            "cityName": "Parnaiba",
            "name": "[PHB] - Santos Dumont - PHB - Parnaiba - Brazil",
            "searchName": "PHB-Santos Dumont-Parnaiba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PGG",
            "countryCode": "BR",
            "cityCode": "PGG",
            "cityName": "Progresso",
            "name": "[PGG] - Progresso - PGG - Progresso - Brazil",
            "searchName": "PGG-Progresso-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MQH",
            "countryCode": "BR",
            "cityCode": "MQH",
            "cityName": "Minacu",
            "name": "[MQH] - Municipal - MQH - Minacu - Brazil",
            "searchName": "MQH-Municipal-Minacu-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MVF",
            "countryCode": "BR",
            "cityCode": "MVF",
            "cityName": "Mossoro",
            "name": "[MVF] - Dixsept Rosado - MVF - Mossoro - Brazil",
            "searchName": "MVF-Dixsept Rosado-Dixsept Rosado Havalimani-Mossoro-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MTE",
            "countryCode": "BR",
            "cityCode": "MTE",
            "cityName": "Monte Alegre",
            "name": "[MTE] - Monte Alegre - MTE - Monte Alegre - Brazil",
            "searchName": "MTE-Monte Alegre-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MTG",
            "countryCode": "BR",
            "cityCode": "MTG",
            "cityName": "Mato Grosso",
            "name": "[MTG] - Mato Grosso - MTG - Mato Grosso - Brazil",
            "searchName": "MTG-Mato Grosso-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MNX",
            "countryCode": "BR",
            "cityCode": "MNX",
            "cityName": "Manicore",
            "name": "[MNX] - Manicore - MNX - Manicore - Brazil",
            "searchName": "MNX-Manicore-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MOC",
            "countryCode": "BR",
            "cityCode": "MOC",
            "cityName": "Montes Claros",
            "name": "[MOC] - Montes Claros - MOC - Montes Claros - Brazil",
            "searchName": "MOC-Montes Claros-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MGF",
            "countryCode": "BR",
            "cityCode": "MGF",
            "cityName": "Maringa",
            "name": "[MGF] - Regional De Maringa SBMG - MGF - Maringa - Brazil",
            "searchName": "MGF-Maringa-Regional De Maringa SBMG-Regional De Maringa-Maring-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MII",
            "countryCode": "BR",
            "cityCode": "MII",
            "cityName": "Marilia",
            "name": "[MII] - Dr Gastao Vidigal - MII - Marilia - Brazil",
            "searchName": "MII-Dr Gastao Vidigal-Mar-Marilia-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MBK",
            "countryCode": "BR",
            "cityCode": "MBK",
            "cityName": "Matupa",
            "name": "[MBK] - Matupa - MBK - Matupa - Brazil",
            "searchName": "MBK-Matupa-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MBZ",
            "countryCode": "BR",
            "cityCode": "MBZ",
            "cityName": "Maues",
            "name": "[MBZ] - Maues - MBZ - Maues - Brazil",
            "searchName": "MBZ-Maues-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MAB",
            "countryCode": "BR",
            "cityCode": "MAB",
            "cityName": "Maraba",
            "name": "[MAB] - Maraba - MAB - Maraba - Brazil",
            "searchName": "MAB-Maraba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MAO",
            "countryCode": "BR",
            "cityCode": "MAO",
            "cityName": "Manaus",
            "name": "[MAO] - Eduardo Gomes International - MAO - Manaus - Brazil",
            "searchName": "MAO-Manaus-Eduardo Gomes International-Eduardo Gomes Intl-Manaos-Palmia-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MEU",
            "countryCode": "BR",
            "cityCode": "MEU",
            "cityName": "Monte Dourado",
            "name": "[MEU] - Monte Dourado - MEU - Monte Dourado - Brazil",
            "searchName": "MEU-Monte Dourado-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MCZ",
            "countryCode": "BR",
            "cityCode": "MCZ",
            "cityName": "Maceio",
            "name": "[MCZ] - Palmares - MCZ - Maceio - Brazil",
            "searchName": "MCZ-Maceio-Palmares-Macei-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MCP",
            "countryCode": "BR",
            "cityCode": "MCP",
            "cityName": "Macapa",
            "name": "[MCP] - Internacional - MCP - Macapa - Brazil",
            "searchName": "MCP-Macapa-Internacional- Intl-Macap-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MEA",
            "countryCode": "BR",
            "cityCode": "MEA",
            "cityName": "Macae",
            "name": "[MEA] - Macae - MEA - Macae - Brazil",
            "searchName": "MEA-Macae-Macae Airport-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "NAT",
            "countryCode": "BR",
            "cityCode": "NAT",
            "cityName": "Natal",
            "name": "[NAT] - Augusto Severo - NAT - Natal - Brazil",
            "searchName": "NAT-Natal-Augusto Severo-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "MVS",
            "countryCode": "BR",
            "cityCode": "MVS",
            "cityName": "Mucuri",
            "name": "[MVS] - Aeroporto Max Feffer - MVS - Mucuri - Brazil",
            "searchName": "MVS-Aeroporto Max Feffer-Mucuri-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "NBV",
            "countryCode": "BR",
            "cityCode": "NBV",
            "cityName": "Cana Brava",
            "name": "[NBV] - Cana Brava - NBV - Cana Brava - Brazil",
            "searchName": "NBV-Cana Brava-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "OAL",
            "countryCode": "BR",
            "cityCode": "OAL",
            "cityName": "Cacoal",
            "name": "[OAL] - Cacoal - OAL - Cacoal - Brazil",
            "searchName": "OAL-Cacoal-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "OBI",
            "countryCode": "BR",
            "cityCode": "OBI",
            "cityName": "Obidos",
            "name": "[OBI] - Obidos - OBI - Obidos - Brazil",
            "searchName": "OBI-Obidos-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "OLC",
            "countryCode": "BR",
            "cityCode": "OLC",
            "cityName": "Sao Paulo de Olivenca",
            "name": "[OLC] - Sen. Eunice Micheles - OLC - Sao Paulo de Olivenca - Brazil",
            "searchName": "OLC-Sen. Eunice Micheles-São Paulo de Olivença-Sao Paulo de Olivenca-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "OIA",
            "countryCode": "BR",
            "cityCode": "OIA",
            "cityName": "Ourilandia",
            "name": "[OIA] - Ourilandia - OIA - Ourilandia - Brazil",
            "searchName": "OIA-Ourilandia-Ourilandia Havalimani-Ourilândia-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "NVT",
            "countryCode": "BR",
            "cityCode": "NVT",
            "cityName": "Navegantes",
            "name": "[NVT] - Navegantes - NVT - Navegantes - Brazil",
            "searchName": "NVT-Navegantes-Ministro V. Konder INTL Airport-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "NVP",
            "countryCode": "BR",
            "cityCode": "NVP",
            "cityName": "Novo Aripuana",
            "name": "[NVP] - Novo Aripuana - NVP - Novo Aripuana - Brazil",
            "searchName": "NVP-Novo Aripuana-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "NTM",
            "countryCode": "BR",
            "cityCode": "NTM",
            "cityName": "Miracema Do Norte",
            "name": "[NTM] - Miracema Do Norte - NTM - Miracema Do Norte - Brazil",
            "searchName": "NTM-Miracema Do Norte-Miracema do Norte-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "NNU",
            "countryCode": "BR",
            "cityCode": "NNU",
            "cityName": "Nanuque",
            "name": "[NNU] - Nanuque - NNU - Nanuque - Brazil",
            "searchName": "NNU-Nanuque-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "NOK",
            "countryCode": "BR",
            "cityCode": "NOK",
            "cityName": "Nova Xavantina",
            "name": "[NOK] - Nova Xavantina - NOK - Nova Xavantina - Brazil",
            "searchName": "NOK-Nova Xavantina-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "NPR",
            "countryCode": "BR",
            "cityCode": "NPR",
            "cityName": "Novo Progresso",
            "name": "[NPR] - Novo Progresso Municipal - NPR - Novo Progresso - Brazil",
            "searchName": "NPR-Novo Progresso Municipal-Novo Progresso-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "NQL",
            "countryCode": "BR",
            "cityCode": "NQL",
            "cityName": "Niquelandia",
            "name": "[NQL] - Niquelandia - NQL - Niquelandia - Brazil",
            "searchName": "NQL-Niquelandia-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "VLP",
            "countryCode": "BR",
            "cityCode": "VLP",
            "cityName": "Vila Rica",
            "name": "[VLP] - Vila Rica - VLP - Vila Rica - Brazil",
            "searchName": "VLP-Vila Rica-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "VIX",
            "countryCode": "BR",
            "cityCode": "VIX",
            "cityName": "Vitoria",
            "name": "[VIX] - Eurico Sales - VIX - Vitoria - Brazil",
            "searchName": "VIX-Vitoria Brasilien-Eurico Sales-Eurico Sales Airport-Vit-Vitoria-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "VOT",
            "countryCode": "BR",
            "cityCode": "VOT",
            "cityName": "Votuporanga",
            "name": "[VOT] - Votuporanga - VOT - Votuporanga - Brazil",
            "searchName": "VOT-Votuporanga-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "VAL",
            "countryCode": "BR",
            "cityCode": "VAL",
            "cityName": "Valenca",
            "name": "[VAL] - Valenca - VAL - Valenca - Brazil",
            "searchName": "VAL-Valenca-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "VAG",
            "countryCode": "BR",
            "cityCode": "VAG",
            "cityName": "Varginha",
            "name": "[VAG] - Maj. Brig. Trompowsky - VAG - Varginha - Brazil",
            "searchName": "VAG-Trompowsky-Maj. Brig. Trompowsky-Varginha-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "URG",
            "countryCode": "BR",
            "cityCode": "URG",
            "cityName": "Uruguaiana",
            "name": "[URG] - Ruben Berta - URG - Uruguaiana - Brazil",
            "searchName": "URG-Ruben Berta-Uruguaiana-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "VDC",
            "countryCode": "BR",
            "cityCode": "VDC",
            "cityName": "Vitoria Da Conquista",
            "name": "[VDC] - Vitoria Da Conquista - VDC - Vitoria Da Conquista - Brazil",
            "searchName": "VDC-Vitoria Da Conquista-Vit-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "VIA",
            "countryCode": "BR",
            "cityCode": "VIA",
            "cityName": "Videira",
            "name": "[VIA] - Vienna - VIA - Videira - Brazil",
            "searchName": "VIA-Vienna-Videira-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "UDI",
            "countryCode": "BR",
            "cityCode": "UDI",
            "cityName": "Uberlandia",
            "name": "[UDI] - Eduardo Gomes - UDI - Uberlandia - Brazil",
            "searchName": "UDI-Uberlandia-Eduardo Gomes-Uberl-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "UBA",
            "countryCode": "BR",
            "cityCode": "UBA",
            "cityName": "Uberaba",
            "name": "[UBA] - Uberaba - UBA - Uberaba - Brazil",
            "searchName": "UBA-Uberaba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "UBT",
            "countryCode": "BR",
            "cityCode": "UBT",
            "cityName": "Ubatuba",
            "name": "[UBT] - Ubatuba - UBT - Ubatuba - Brazil",
            "searchName": "UBT-Ubatuba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "UMU",
            "countryCode": "BR",
            "cityCode": "UMU",
            "cityName": "Umuarama",
            "name": "[UMU] - Ernesto Geisel - UMU - Umuarama - Brazil",
            "searchName": "UMU-Ernesto Geisel-Ernesto Geisel Havalimani-Umuarama-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "UNA",
            "countryCode": "BR",
            "cityCode": "UNA",
            "cityName": "Una",
            "name": "[UNA] - Una - UNA - Una - Brazil",
            "searchName": "UNA-Una-Una Havalimani-Una SP-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "XIG",
            "countryCode": "BR",
            "cityCode": "XIG",
            "cityName": "Xinguara",
            "name": "[XIG] - Xinguara - XIG - Xinguara - Brazil",
            "searchName": "XIG-Xinguara-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "XAP",
            "countryCode": "BR",
            "cityCode": "XAP",
            "cityName": "Chapeco",
            "name": "[XAP] - Aeroporto Municipalcipal Chapeco - XAP - Chapeco - Brazil",
            "searchName": "XAP-Chapeco-Aeroporto Municipalcipal Chapeco-Chapec-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "THE",
            "countryCode": "BR",
            "cityCode": "THE",
            "cityName": "Teresina",
            "name": "[THE] - Senador PetrÃ´nio Portella - THE - Teresina - Brazil",
            "searchName": "THE-Teresina-Senador Petrônio Portella-Senador PetrÃ´nio Portella-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TEC",
            "countryCode": "BR",
            "cityCode": "TEC",
            "cityName": "Telemaco Borba",
            "name": "[TEC] - Telemaco Borba - TEC - Telemaco Borba - Brazil",
            "searchName": "TEC-Telemaco Borba-Telemaco Borba Havalimani-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TGQ",
            "countryCode": "BR",
            "cityCode": "TGQ",
            "cityName": "Tangara da Serra",
            "name": "[TGQ] - Tangara da Serra - TGQ - Tangara da Serra - Brazil",
            "searchName": "TGQ-Tangara da Serra-TANGARA DA SERRA-Tangara Da Serra-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TFF",
            "countryCode": "BR",
            "cityCode": "TFF",
            "cityName": "Tefe",
            "name": "[TFF] - Tefe - TFF - Tefe - Brazil",
            "searchName": "TFF-Tefe-Tefe Arpt-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TFL",
            "countryCode": "BR",
            "cityCode": "TFL",
            "cityName": "Teofilo Otoni",
            "name": "[TFL] - Teofilo Otoni - TFL - Teofilo Otoni - Brazil",
            "searchName": "TFL-Teofilo Otoni-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SXX",
            "countryCode": "BR",
            "cityCode": "SXX",
            "cityName": "Sao Felix Do Xingu",
            "name": "[SXX] - Sao Felix Do Xingu - SXX - Sao Felix Do Xingu - Brazil",
            "searchName": "SXX-Sao Felix Do Xingu-Sao Felix do Xingu-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SXO",
            "countryCode": "BR",
            "cityCode": "SXO",
            "cityName": "Sao Felix Do Araguaia",
            "name": "[SXO] - Sao Felix Do Araguaia - SXO - Sao Felix Do Araguaia - Brazil",
            "searchName": "SXO-Sao Felix Do Araguaia-Sao Felix do Araguaio-Sao Felix Araguaio-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TBT",
            "countryCode": "BR",
            "cityCode": "TBT",
            "cityName": "Tabatinga",
            "name": "[TBT] - Internacional - TBT - Tabatinga - Brazil",
            "searchName": "TBT-Intl-Internacional- Intl-Tabatinga-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TUR",
            "countryCode": "BR",
            "cityCode": "TUR",
            "cityName": "Tucurui",
            "name": "[TUR] - Tucurui - TUR - Tucurui - Brazil",
            "searchName": "TUR-Tucurui-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TXF",
            "countryCode": "BR",
            "cityCode": "TXF",
            "cityName": "Teixeira de Freitas",
            "name": "[TXF] - Teixeira de Freitas - TXF - Teixeira de Freitas - Brazil",
            "searchName": "TXF-Teixeira de Freitas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TRQ",
            "countryCode": "BR",
            "cityCode": "TRQ",
            "cityName": "Tarauaca",
            "name": "[TRQ] - Tarauaca - TRQ - Tarauaca - Brazil",
            "searchName": "TRQ-Tarauaca-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TSQ",
            "countryCode": "BR",
            "cityCode": "TSQ",
            "cityName": "Torres",
            "name": "[TSQ] - Torres - TSQ - Torres - Brazil",
            "searchName": "TSQ-Torres-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TMT",
            "countryCode": "BR",
            "cityCode": "TMT",
            "cityName": "Trombetas",
            "name": "[TMT] - Trombetas - TMT - Trombetas - Brazil",
            "searchName": "TMT-Porto Trombetas-Trombetas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TLZ",
            "countryCode": "BR",
            "cityCode": "TLZ",
            "cityName": "Catalao",
            "name": "[TLZ] - Catalao - TLZ - Catalao - Brazil",
            "searchName": "TLZ-Catalao-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "TOW",
            "countryCode": "BR",
            "cityCode": "TOW",
            "cityName": "Toledo",
            "name": "[TOW] - Toledo - TOW - Toledo - Brazil",
            "searchName": "TOW-Toledo-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SQX",
            "countryCode": "BR",
            "cityCode": "SQX",
            "cityName": "Sao Miguel do Oeste",
            "name": "[SQX] - Sao Miguel do Oeste - SQX - Sao Miguel do Oeste - Brazil",
            "searchName": "SQX-Sao Miguel do Oeste-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SQY",
            "countryCode": "BR",
            "cityCode": "SQY",
            "cityName": "Sao Lourenco Do Sul",
            "name": "[SQY] - Sao Lourenco Do Sul - SQY - Sao Lourenco Do Sul - Brazil",
            "searchName": "SQY-Sao Lourenco Do Sul-Sao Lourenco do Sul-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SQM",
            "countryCode": "BR",
            "cityCode": "SQM",
            "cityName": "São Miguel de Aragao",
            "name": "[SQM] - SÃ£o Miguel de Aragao - SQM - São Miguel de Aragao - Brazil",
            "searchName": "SQM-São Miguel de Aragao-SÃ£o Miguel de Aragao-Sao Miguel do Araguaia-S-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SOD",
            "countryCode": "BR",
            "cityCode": "SOD",
            "cityName": "Sorocaba",
            "name": "[SOD] - Sorocaba - SOD - Sorocaba - Brazil",
            "searchName": "SOD-Sorocaba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SNZ",
            "countryCode": "BR",
            "cityCode": "SNZ",
            "cityName": "Santa Cruz",
            "name": "[SNZ] - Santa Cruz - SNZ - Santa Cruz - Brazil",
            "searchName": "SNZ-Santa Cruz-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SLZ",
            "countryCode": "BR",
            "cityCode": "SLZ",
            "cityName": "São Luis",
            "name": "[SLZ] - Marechal Cunha Machado - SLZ - São Luis - Brazil",
            "searchName": "SLZ-Sao Luis-Marechal Cunha Machado-Mal. Cunha Machado-Sao Luiz-Marechal Cunha Machado INTL-S-São Luis-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SRA",
            "countryCode": "BR",
            "cityCode": "SRA",
            "cityName": "Santa Rosa",
            "name": "[SRA] - Santa Rosa - SRA - Santa Rosa - Brazil",
            "searchName": "SRA-Santa Rosa-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SSA",
            "countryCode": "BR",
            "cityCode": "SSA",
            "cityName": "Salvador",
            "name": "[SSA] - Luis Eduardo Magalhaes - SSA - Salvador - Brazil",
            "searchName": "SSA-Salvador de Bahia-Luis Eduardo Magalhaes-Luis R. Magalhaes-Salvador-Salvadore-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "STM",
            "countryCode": "BR",
            "cityCode": "STM",
            "cityName": "Santarem",
            "name": "[STM] - Eduardo Gomes - STM - Santarem - Brazil",
            "searchName": "STM-Santarem-Eduardo Gomes-Santar-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SSO",
            "countryCode": "BR",
            "cityCode": "SSO",
            "cityName": "Sao Lourenco",
            "name": "[SSO] - Sao Lourenco - SSO - Sao Lourenco - Brazil",
            "searchName": "SSO-Sao Lourenco-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SSZ",
            "countryCode": "BR",
            "cityCode": "SSZ",
            "cityName": "Santos",
            "name": "[SSZ] - Santos - SSZ - Santos - Brazil",
            "searchName": "SSZ-Santos-Santos Airport-Sao Paulo-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "STZ",
            "countryCode": "BR",
            "cityCode": "STZ",
            "cityName": "Santa Terezinha",
            "name": "[STZ] - Confresa - STZ - Santa Terezinha - Brazil",
            "searchName": "STZ-Confresa-Santa Terezinha-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SJK",
            "countryCode": "BR",
            "cityCode": "SJK",
            "cityName": "Sao Jose Dos Campos",
            "name": "[SJK] - Sao Jose Dos Campos - SJK - Sao Jose Dos Campos - Brazil",
            "searchName": "SJK-Sao Jose Dos Campos-Sao Jose Dos Campos Airport-Professor Urbano E. Stumpf Airport-S-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SJL",
            "countryCode": "BR",
            "cityCode": "SJL",
            "cityName": "Sao Gabriel",
            "name": "[SJL] - Da Cachoeira - SJL - Sao Gabriel - Brazil",
            "searchName": "SJL-Da Cachoeira-Sao Gabriel-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SJP",
            "countryCode": "BR",
            "cityCode": "SJP",
            "cityName": "Sao Jose Do Rio Preto",
            "name": "[SJP] - Sao Jose Do Rio Preto - SJP - Sao Jose Do Rio Preto - Brazil",
            "searchName": "SJP-Sao Jose Do Rio Preto-S-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SBJ",
            "countryCode": "BR",
            "cityCode": "SBJ",
            "cityName": "Sao Mateus",
            "name": "[SBJ] - Sao Mateus - SBJ - Sao Mateus - Brazil",
            "searchName": "SBJ-Sao Mateus-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SFV",
            "countryCode": "BR",
            "cityCode": "SFV",
            "cityName": "Santa Fe Do Sul",
            "name": "[SFV] - Santa Fe Do Sul - SFV - Santa Fe Do Sul - Brazil",
            "searchName": "SFV-Santa Fe Do Sul-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SFK",
            "countryCode": "BR",
            "cityCode": "SFK",
            "cityName": "Soure",
            "name": "[SFK] - Soure - SFK - Soure - Brazil",
            "searchName": "SFK-Soure-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "SEI",
            "countryCode": "BR",
            "cityCode": "SEI",
            "cityName": "Senhor Do Bonfim",
            "name": "[SEI] - Senhor Do Bonfim - SEI - Senhor Do Bonfim - Brazil",
            "searchName": "SEI-Senhor Do Bonfim-Senhor do Bonfim-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ZFU",
            "countryCode": "BR",
            "cityCode": "ZFU",
            "cityName": "Aruja",
            "name": "[ZFU] - Aruja - ZFU - Aruja - Brazil",
            "searchName": "ZFU-Aruja-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ZMD",
            "countryCode": "BR",
            "cityCode": "ZMD",
            "cityName": "Sena Madureira",
            "name": "[ZMD] - Sena Madureira - ZMD - Sena Madureira - Brazil",
            "searchName": "ZMD-Sena Madureira-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ZSA",
            "countryCode": "BS",
            "cityCode": "ZSA",
            "cityName": "San Salvador",
            "name": "[ZSA] - San Salvador - ZSA - San Salvador - Bahamas",
            "searchName": "ZSA-San Salvador Bahamas-San Salvador-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "SAQ",
            "countryCode": "BS",
            "cityCode": "SAQ",
            "cityName": "San Andros",
            "name": "[SAQ] - San Andros - SAQ - San Andros - Bahamas",
            "searchName": "SAQ-San Andros-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "SWL",
            "countryCode": "BS",
            "cityCode": "SWL",
            "cityName": "Spanish Wells",
            "name": "[SWL] - Spanish Wells - SWL - Spanish Wells - Bahamas",
            "searchName": "SWL-Spanish Wells-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "SML",
            "countryCode": "BS",
            "cityCode": "SML",
            "cityName": "Stella Maris",
            "name": "[SML] - Estate Airstrip - SML - Stella Maris - Bahamas",
            "searchName": "SML-Stella Maris-Estate Airstrip-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "TYM",
            "countryCode": "BS",
            "cityCode": "TYM",
            "cityName": "Staniel Cay",
            "name": "[TYM] - Staniel Cay - TYM - Staniel Cay - Bahamas",
            "searchName": "TYM-Staniel Cay-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "TCB",
            "countryCode": "BS",
            "cityCode": "TCB",
            "cityName": "Treasure Cay",
            "name": "[TCB] - Treasure Cay - TCB - Treasure Cay - Bahamas",
            "searchName": "TCB-Treasure Cay-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "TBI",
            "countryCode": "BS",
            "cityCode": "TBI",
            "cityName": "The Bight",
            "name": "[TBI] - New Bright - TBI - The Bight - Bahamas",
            "searchName": "TBI-The Bight-New Bright-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "WTD",
            "countryCode": "BS",
            "cityCode": "WTD",
            "cityName": "West End",
            "name": "[WTD] - West End - WTD - West End - Bahamas",
            "searchName": "WTD-West End-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "TZN",
            "countryCode": "BS",
            "cityCode": "TZN",
            "cityName": "South Andros",
            "name": "[TZN] - South Andros - TZN - South Andros - Bahamas",
            "searchName": "TZN-South Andros-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "WKR",
            "countryCode": "BS",
            "cityCode": "WKR",
            "cityName": "Walker s Cay",
            "name": "[WKR] - Walkers Cay - WKR - Walker s Cay - Bahamas",
            "searchName": "WKR-Walker s Cay-Walkers Cay-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "NET",
            "countryCode": "BS",
            "cityCode": "NET",
            "cityName": "New Bight",
            "name": "[NET] - New Bight - NET - New Bight - Bahamas",
            "searchName": "NET-New Bight-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "NMC",
            "countryCode": "BS",
            "cityCode": "NMC",
            "cityName": "Normans Cay",
            "name": "[NMC] - Normans Cay - NMC - Normans Cay - Bahamas",
            "searchName": "NMC-Norman s Cay-Normans Cay-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "MYG",
            "countryCode": "BS",
            "cityCode": "MYG",
            "cityName": "Mayaguana",
            "name": "[MYG] - Mayaguana - MYG - Mayaguana - Bahamas",
            "searchName": "MYG-Mayaguana-Bahamas-Bahama-szigetek-Bahames-Bahamalar-Bahamy-Bahami-Bahamos-Bahamu salas",
            "priority": 0
        },
        {
            "iataCode": "GUZ",
            "countryCode": "BR",
            "cityCode": "QDL",
            "cityName": "Guarapari",
            "name": "[GUZ] - Guarapari - QDL - Guarapari - Brazil",
            "searchName": "GUZ-QDL-Guarapari-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "POJ",
            "countryCode": "BR",
            "cityCode": "QPT",
            "cityName": "Patos De Minas",
            "name": "[POJ] - Patos De Minas - QPT - Patos De Minas - Brazil",
            "searchName": "POJ-QPT-Patos De Minas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "QSC",
            "countryCode": "BR",
            "cityCode": "QSC",
            "cityName": "Sao Carlos",
            "name": "[QSC] - Sao Carlos - QSC - Sao Carlos - Brazil",
            "searchName": "QSC-Sao Carlos-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PTQ",
            "countryCode": "BR",
            "cityCode": "PTQ",
            "cityName": "Porto de Moz",
            "name": "[PTQ] - Porto de Moz - PTQ - Porto de Moz - Brazil",
            "searchName": "PTQ-Porto de Moz-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PTO",
            "countryCode": "BR",
            "cityCode": "PTO",
            "cityName": "Pato Branco",
            "name": "[PTO] - Pato Branco - PTO - Pato Branco - Brazil",
            "searchName": "PTO-Pato Branco-Pato Branco Belediyesi Havalimani-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PSW",
            "countryCode": "BR",
            "cityCode": "PSW",
            "cityName": "Passos",
            "name": "[PSW] - Passos - PSW - Passos - Brazil",
            "searchName": "PSW-Passos-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PVH",
            "countryCode": "BR",
            "cityCode": "PVH",
            "cityName": "Porto Velho",
            "name": "[PVH] - Governador Jorge Teixeira - PVH - Porto Velho - Brazil",
            "searchName": "PVH-Porto Velho-Governador Jorge Teixeira-Belmonte-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PVI",
            "countryCode": "BR",
            "cityCode": "PVI",
            "cityName": "Paranavai",
            "name": "[PVI] - Edu Chaves - PVI - Paranavai - Brazil",
            "searchName": "PVI-Edu Chaves-Paranavai-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ORX",
            "countryCode": "BR",
            "cityCode": "ORX",
            "cityName": "Oriximina",
            "name": "[ORX] - Oriximina - ORX - Oriximina - Brazil",
            "searchName": "ORX-Oriximina-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "OTT",
            "countryCode": "BR",
            "cityCode": "OTT",
            "cityName": "Cotriguacu",
            "name": "[OTT] - Andre Maggi - OTT - Cotriguacu - Brazil",
            "searchName": "OTT-Andre Maggi-Cotriguacu-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "OPS",
            "countryCode": "BR",
            "cityCode": "OPS",
            "cityName": "Sinop",
            "name": "[OPS] - Sinop - OPS - Sinop - Brazil",
            "searchName": "OPS-Sinop Airport-Sinop-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PDF",
            "countryCode": "BR",
            "cityCode": "PDF",
            "cityName": "Prado",
            "name": "[PDF] - Prado - PDF - Prado - Brazil",
            "searchName": "PDF-Prado-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PCS",
            "countryCode": "BR",
            "cityCode": "PCS",
            "cityName": "Picos",
            "name": "[PCS] - Picos - PCS - Picos - Brazil",
            "searchName": "PCS-Picos-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PBX",
            "countryCode": "BR",
            "cityCode": "PBX",
            "cityName": "Porto Alegre Do Norte",
            "name": "[PBX] - Porto Alegre Do Norte - PBX - Porto Alegre Do Norte - Brazil",
            "searchName": "PBX-Porto Alegre Do Norte-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PBV",
            "countryCode": "BR",
            "cityCode": "PBV",
            "cityName": "Porto Dos Gauchos",
            "name": "[PBV] - Porto Dos Gauchos - PBV - Porto Dos Gauchos - Brazil",
            "searchName": "PBV-Porto Dos Gauchos-Portos Dos Gauchos-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PBQ",
            "countryCode": "BR",
            "cityCode": "PBQ",
            "cityName": "Pimenta Bueno",
            "name": "[PBQ] - Pimenta Bueno - PBQ - Pimenta Bueno - Brazil",
            "searchName": "PBQ-Pimenta Bueno-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PAV",
            "countryCode": "BR",
            "cityCode": "PAV",
            "cityName": "Paulo Afonso",
            "name": "[PAV] - Paulo Afonso - PAV - Paulo Afonso - Brazil",
            "searchName": "PAV-Paulo Afonso-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PBB",
            "countryCode": "BR",
            "cityCode": "PBB",
            "cityName": "Paranaiba",
            "name": "[PBB] - Paranaiba - PBB - Paranaiba - Brazil",
            "searchName": "PBB-Paranaiba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "OYK",
            "countryCode": "BR",
            "cityCode": "OYK",
            "cityName": "Oiapoque",
            "name": "[OYK] - Oiapoque - OYK - Oiapoque - Brazil",
            "searchName": "OYK-Oiapoque-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "OUS",
            "countryCode": "BR",
            "cityCode": "OUS",
            "cityName": "Ourinhos",
            "name": "[OUS] - Ourinhos - OUS - Ourinhos - Brazil",
            "searchName": "OUS-Ourinhos-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "POO",
            "countryCode": "BR",
            "cityCode": "POO",
            "cityName": "Pocos De Caldas",
            "name": "[POO] - Pocos De Caldas - POO - Pocos De Caldas - Brazil",
            "searchName": "POO-Pocos De Caldas-Pocos de Caldas-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "PNZ",
            "countryCode": "BR",
            "cityCode": "PNZ",
            "cityName": "Petrolina",
            "name": "[PNZ] - Internacional - PNZ - Petrolina - Brazil",
            "searchName": "PNZ-Petrolina-Internacional- Intl-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "VCP",
            "countryCode": "BR",
            "cityCode": "SAO",
            "cityName": "Sao Paulo",
            "name": "[VCP] - Viracopos (Campinas International) - SAO - Sao Paulo - Brazil",
            "searchName": "VCP-SAO-Sao Paulo Viracopos-Viracopos (Campinas International)-Viracopos-Sao Paulo-S-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "RVD",
            "countryCode": "BR",
            "cityCode": "RVD",
            "cityName": "Rio Verde",
            "name": "[RVD] - Rio Verde - RVD - Rio Verde - Brazil",
            "searchName": "RVD-Rio Verde-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "ROO",
            "countryCode": "BR",
            "cityCode": "ROO",
            "cityName": "Rondonopolis",
            "name": "[ROO] - Rondonopolis - ROO - Rondonopolis - Brazil",
            "searchName": "ROO-Rondonopolis-Rondonopolis Airport-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "RRN",
            "countryCode": "BR",
            "cityCode": "RRN",
            "cityName": "Serra Norte",
            "name": "[RRN] - Serra Norte - RRN - Serra Norte - Brazil",
            "searchName": "RRN-Serra Norte-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "RSG",
            "countryCode": "BR",
            "cityCode": "RSG",
            "cityName": "Serra Pelada",
            "name": "[RSG] - Serra Pelada - RSG - Serra Pelada - Brazil",
            "searchName": "RSG-Serra Pelada-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "REZ",
            "countryCode": "BR",
            "cityCode": "REZ",
            "cityName": "Resende",
            "name": "[REZ] - Resende - REZ - Resende - Brazil",
            "searchName": "REZ-Resende-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "RDC",
            "countryCode": "BR",
            "cityCode": "RDC",
            "cityName": "Redencao",
            "name": "[RDC] - Redencao - RDC - Redencao - Brazil",
            "searchName": "RDC-Redencao-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "REC",
            "countryCode": "BR",
            "cityCode": "REC",
            "cityName": "Recife",
            "name": "[REC] - Guararapes International - REC - Recife - Brazil",
            "searchName": "REC-Recife-Guararapes International-Guararapes Intl-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "RIG",
            "countryCode": "BR",
            "cityCode": "RIG",
            "cityName": "Rio Grande",
            "name": "[RIG] - Rio Grande - RIG - Rio Grande - Brazil",
            "searchName": "RIG-Rio Grande-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "RIA",
            "countryCode": "BR",
            "cityCode": "RIA",
            "cityName": "Santa Maria",
            "name": "[RIA] - Base Aerea De Santa Maria - RIA - Santa Maria - Brazil",
            "searchName": "RIA-Base Aerea-Base Aerea De Santa Maria-Santa Maria-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "GVR",
            "countryCode": "BR",
            "cityCode": "GVR",
            "cityName": "Governador Valadares",
            "name": "[GVR] - Governador Valadares - GVR - Governador Valadares - Brazil",
            "searchName": "GVR-Governador Valadares-Governador Valadares Arpt-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "GUJ",
            "countryCode": "BR",
            "cityCode": "GUJ",
            "cityName": "Guaratingueta",
            "name": "[GUJ] - Guaratingueta - GUJ - Guaratingueta - Brazil",
            "searchName": "GUJ-Guaratingueta-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "GYN",
            "countryCode": "BR",
            "cityCode": "GYN",
            "cityName": "Goiania",
            "name": "[GYN] - Santa Genoveva - GYN - Goiania - Brazil",
            "searchName": "GYN-Goiania-Santa Genoveva-Goi-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "GRP",
            "countryCode": "BR",
            "cityCode": "GRP",
            "cityName": "Gurupi",
            "name": "[GRP] - Gurupi - GRP - Gurupi - Brazil",
            "searchName": "GRP-Kommunaler Flughafen-Gurupi-Gurupi\u00A0 Havalimani-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "GJM",
            "countryCode": "BR",
            "cityCode": "GJM",
            "cityName": "Guajara-Mirim",
            "name": "[GJM] - Guajara-Mirim - GJM - Guajara-Mirim - Brazil",
            "searchName": "GJM-Guajara-Mirim-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "GPB",
            "countryCode": "BR",
            "cityCode": "GPB",
            "cityName": "Guarapuava",
            "name": "[GPB] - Tancredo Thomaz Faria - GPB - Guarapuava - Brazil",
            "searchName": "GPB-Tancredo Thomaz Faria-Guarapuava-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "GNM",
            "countryCode": "BR",
            "cityCode": "GNM",
            "cityName": "Guanambi",
            "name": "[GNM] - Guanambi - GNM - Guanambi - Brazil",
            "searchName": "GNM-Guanambi-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "GMS",
            "countryCode": "BR",
            "cityCode": "GMS",
            "cityName": "Guimaraes",
            "name": "[GMS] - Guimaraes - GMS - Guimaraes - Brazil",
            "searchName": "GMS-Guimaraes-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "FOR",
            "countryCode": "BR",
            "cityCode": "FOR",
            "cityName": "Fortaleza",
            "name": "[FOR] - Pinto Martins - FOR - Fortaleza - Brazil",
            "searchName": "FOR-Fortaleza-Pinto Martins-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "FRC",
            "countryCode": "BR",
            "cityCode": "FRC",
            "cityName": "Franca",
            "name": "[FRC] - Franca - FRC - Franca - Brazil",
            "searchName": "FRC-Franca-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "FEC",
            "countryCode": "BR",
            "cityCode": "FEC",
            "cityName": "Feira De Santana",
            "name": "[FEC] - Feira De Santana - FEC - Feira De Santana - Brazil",
            "searchName": "FEC-Feira De Santana-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "FEN",
            "countryCode": "BR",
            "cityCode": "FEN",
            "cityName": "Fernando De Noronha",
            "name": "[FEN] - Fernando De Noronha - FEN - Fernando De Noronha - Brazil",
            "searchName": "FEN-Fernando De Noronha-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "FLB",
            "countryCode": "BR",
            "cityCode": "FLB",
            "cityName": "Floriano",
            "name": "[FLB] - Cangapara - FLB - Floriano - Brazil",
            "searchName": "FLB-Cangapara-Cangapara Havalimani-Floriano-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "IMP",
            "countryCode": "BR",
            "cityCode": "IMP",
            "cityName": "Imperatriz",
            "name": "[IMP] - Imperatriz - IMP - Imperatriz - Brazil",
            "searchName": "IMP-Imperatriz-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LOI",
            "countryCode": "BR",
            "cityCode": "LOI",
            "cityName": "Lontras",
            "name": "[LOI] - Helmuth Baungartem - LOI - Lontras - Brazil",
            "searchName": "LOI-Helmuth Baungartem-Lontras-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LVR",
            "countryCode": "BR",
            "cityCode": "LVR",
            "cityName": "Lucas Do Rio Verde",
            "name": "[LVR] - Mun. Do Bom Futuro - LVR - Lucas Do Rio Verde - Brazil",
            "searchName": "LVR-Mun. Do Bom Futuro-Lucas Do Rio Verde-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LVB",
            "countryCode": "BR",
            "cityCode": "LVB",
            "cityName": "Livramento",
            "name": "[LVB] - Dos Galpoes - LVB - Livramento - Brazil",
            "searchName": "LVB-Dos Galpoes-Livramento-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LIP",
            "countryCode": "BR",
            "cityCode": "LIP",
            "cityName": "Lins",
            "name": "[LIP] - Lins - LIP - Lins - Brazil",
            "searchName": "LIP-Lins-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LEP",
            "countryCode": "BR",
            "cityCode": "LEP",
            "cityName": "Leopoldina",
            "name": "[LEP] - Leopoldina - LEP - Leopoldina - Brazil",
            "searchName": "LEP-Leopoldina-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LEC",
            "countryCode": "BR",
            "cityCode": "LEC",
            "cityName": "Lencois",
            "name": "[LEC] - Chapada Diamantina - LEC - Lencois - Brazil",
            "searchName": "LEC-Chapada Diamantina-Coronel Horácio de Mattos Havalimani-Lencois-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LDB",
            "countryCode": "BR",
            "cityCode": "LDB",
            "cityName": "Londrina",
            "name": "[LDB] - Londrina - LDB - Londrina - Brazil",
            "searchName": "LDB-Londrina-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LCB",
            "countryCode": "BR",
            "cityCode": "LCB",
            "cityName": "Pontes e Lacerda",
            "name": "[LCB] - Pontes e Lacerda - LCB - Pontes e Lacerda - Brazil",
            "searchName": "LCB-Pontes e Lacerda-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LAJ",
            "countryCode": "BR",
            "cityCode": "LAJ",
            "cityName": "Lages",
            "name": "[LAJ] - Lages - LAJ - Lages - Brazil",
            "searchName": "LAJ-Lages-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LAZ",
            "countryCode": "BR",
            "cityCode": "LAZ",
            "cityName": "Bom Jesus Da Lapa",
            "name": "[LAZ] - Bom Jesus Da Lapa - LAZ - Bom Jesus Da Lapa - Brazil",
            "searchName": "LAZ-Bom Jesus Da Lapa-Bom Jesus da Lapa Havalimani-Bom Jesus da Lapa-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "LBR",
            "countryCode": "BR",
            "cityCode": "LBR",
            "cityName": "Labrea",
            "name": "[LBR] - Labrea - LBR - Labrea - Brazil",
            "searchName": "LBR-Labrea-Labrea Havalimani-L-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JUA",
            "countryCode": "BR",
            "cityCode": "JUA",
            "cityName": "Juara",
            "name": "[JUA] - Juara - JUA - Juara - Brazil",
            "searchName": "JUA-Juara-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JTC",
            "countryCode": "BR",
            "cityCode": "JTC",
            "cityName": "Bauru",
            "name": "[JTC] - Arealva - JTC - Bauru - Brazil",
            "searchName": "JTC-Bauru-Arealva-Arealva Airport-Bauru\\/Arealva-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JTI",
            "countryCode": "BR",
            "cityCode": "JTI",
            "cityName": "Jatai",
            "name": "[JTI] - Jatai - JTI - Jatai - Brazil",
            "searchName": "JTI-Jatai-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JRN",
            "countryCode": "BR",
            "cityCode": "JRN",
            "cityName": "Juruena",
            "name": "[JRN] - Juruena - JRN - Juruena - Brazil",
            "searchName": "JRN-Juruena-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JOI",
            "countryCode": "BR",
            "cityCode": "JOI",
            "cityName": "Joinville",
            "name": "[JOI] - Lauro Carneiro De Loyola - JOI - Joinville - Brazil",
            "searchName": "JOI-Joinville-Lauro Carneiro De Loyola-Cubatao-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JPR",
            "countryCode": "BR",
            "cityCode": "JPR",
            "cityName": "Ji-Parana",
            "name": "[JPR] - Ji-Parana - JPR - Ji-Parana - Brazil",
            "searchName": "JPR-Ji-Parana-Ji Parana-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JPA",
            "countryCode": "BR",
            "cityCode": "JPA",
            "cityName": "Joao Pessoa",
            "name": "[JPA] - Castro Pinto - JPA - Joao Pessoa - Brazil",
            "searchName": "JPA-Joao Pessoa-Castro Pinto-Jo-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JNA",
            "countryCode": "BR",
            "cityCode": "JNA",
            "cityName": "Januaria",
            "name": "[JNA] - Januaria - JNA - Januaria - Brazil",
            "searchName": "JNA-Januaria-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JLS",
            "countryCode": "BR",
            "cityCode": "JLS",
            "cityName": "Jales",
            "name": "[JLS] - Jales - JLS - Jales - Brazil",
            "searchName": "JLS-Jales-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JEQ",
            "countryCode": "BR",
            "cityCode": "JEQ",
            "cityName": "Jequie",
            "name": "[JEQ] - Jequie - JEQ - Jequie - Brazil",
            "searchName": "JEQ-Jequie-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JIA",
            "countryCode": "BR",
            "cityCode": "JIA",
            "cityName": "Juina",
            "name": "[JIA] - Juina - JIA - Juina - Brazil",
            "searchName": "JIA-Juina-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JCM",
            "countryCode": "BR",
            "cityCode": "JCM",
            "cityName": "Jacobina",
            "name": "[JCM] - Jacobina - JCM - Jacobina - Brazil",
            "searchName": "JCM-Jacobina-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JCB",
            "countryCode": "BR",
            "cityCode": "JCB",
            "cityName": "Joacaba",
            "name": "[JCB] - Joacaba - JCB - Joacaba - Brazil",
            "searchName": "JCB-Joacaba-Joacaba Havalimani-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JCR",
            "countryCode": "BR",
            "cityCode": "JCR",
            "cityName": "Jacareacanga",
            "name": "[JCR] - Jacareacanga - JCR - Jacareacanga - Brazil",
            "searchName": "JCR-Jacareacanga-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JDF",
            "countryCode": "BR",
            "cityCode": "JDF",
            "cityName": "Juiz De Fora",
            "name": "[JDF] - Francisco De Assis - JDF - Juiz De Fora - Brazil",
            "searchName": "JDF-Francisco De Assis-Juiz De Fora-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "IZA",
            "countryCode": "BR",
            "cityCode": "JDF",
            "cityName": "Juiz De Fora",
            "name": "[IZA] - Reg. da Zona da Mata - JDF - Juiz De Fora - Brazil",
            "searchName": "IZA-JDF-Zona da Mata-Reg. da Zona da Mata-Juiz De Fora-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JDO",
            "countryCode": "BR",
            "cityCode": "JDO",
            "cityName": "Juazeiro Do Norte",
            "name": "[JDO] - Regional Do Cariri - JDO - Juazeiro Do Norte - Brazil",
            "searchName": "JDO-Juazeiro do Norte-Regional Do Cariri-Juazeiro Do Norte-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "JDR",
            "countryCode": "BR",
            "cityCode": "JDR",
            "cityName": "Sao Joao Del Rei",
            "name": "[JDR] - Sao Joao Del Rei - JDR - Sao Joao Del Rei - Brazil",
            "searchName": "JDR-Sao Joao del Rei-Sao Joao Del Rei-S-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "RWS",
            "countryCode": "BR",
            "cityCode": "RWS",
            "cityName": "Sumare",
            "name": "[RWS] - Sumare - RWS - Sumare - Brazil",
            "searchName": "RWS-Sumare-Sumare Havalimani-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "YND",
            "countryCode": "CA",
            "cityCode": "YOW",
            "cityName": "Ottawa",
            "name": "[YND] - Executive Gatineau-Ottawa - YOW - Ottawa - Canada",
            "searchName": "YND-YOW-Gatineau-Executive Gatineau-Ottawa-Gatineau Hull  Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YRO",
            "countryCode": "CA",
            "cityCode": "YOW",
            "cityName": "Ottawa",
            "name": "[YRO] - Rockcliffe St - YOW - Ottawa - Canada",
            "searchName": "YRO-YOW-Rockcliffe St-Ottawa-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XGE",
            "countryCode": "CA",
            "cityCode": "YOW",
            "cityName": "Ottawa",
            "name": "[XGE] - Ottawa Bus Station - YOW - Ottawa - Canada",
            "searchName": "XGE-YOW-Ottawa Bus Station-Ottawa-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XHG",
            "countryCode": "CA",
            "cityCode": "YOW",
            "cityName": "Ottawa",
            "name": "[XHG] - Ottawa Bus Station - YOW - Ottawa - Canada",
            "searchName": "XHG-YOW-Ottawa Bus Station-Ottawa-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XDS",
            "countryCode": "CA",
            "cityCode": "YOW",
            "cityName": "Ottawa",
            "name": "[XDS] - Ottawa Railway Station - YOW - Ottawa - Canada",
            "searchName": "XDS-YOW-Ottawa Railway Station-Rail Station-Ottawa-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZIF",
            "countryCode": "CA",
            "cityCode": "YOW",
            "cityName": "Ottawa",
            "name": "[ZIF] - Fallowfield Railway - YOW - Ottawa - Canada",
            "searchName": "ZIF-YOW-Fallowfield Railway-Ottawa-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YOY",
            "countryCode": "CA",
            "cityCode": "YOY",
            "cityName": "Valcartier",
            "name": "[YOY] - Valcartier - YOY - Valcartier - Canada",
            "searchName": "YOY-Valcartier-Valcartier Canada-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPA",
            "countryCode": "CA",
            "cityCode": "YPA",
            "cityName": "Prince Albert",
            "name": "[YPA] - Glass Field - YPA - Prince Albert - Canada",
            "searchName": "YPA-Glass Field-Yap-Prince Albert-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPB",
            "countryCode": "CA",
            "cityCode": "YPB",
            "cityName": "Port Alberni",
            "name": "[YPB] - Port Alberni - YPB - Port Alberni - Canada",
            "searchName": "YPB-Port Alberni-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPC",
            "countryCode": "CA",
            "cityCode": "YPC",
            "cityName": "Paulatuk",
            "name": "[YPC] - Paulatuk - YPC - Paulatuk - Canada",
            "searchName": "YPC-Paulatuk-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPD",
            "countryCode": "CA",
            "cityCode": "YPD",
            "cityName": "Parry Sound",
            "name": "[YPD] - Parry Sound - YPD - Parry Sound - Canada",
            "searchName": "YPD-Parry Sound-Parry Sound  Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPE",
            "countryCode": "CA",
            "cityCode": "YPE",
            "cityName": "Peace River",
            "name": "[YPE] - Peace River - YPE - Peace River - Canada",
            "searchName": "YPE-Peace River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPF",
            "countryCode": "CA",
            "cityCode": "YPF",
            "cityName": "Esquimalt",
            "name": "[YPF] - Esquimalt - YPF - Esquimalt - Canada",
            "searchName": "YPF-Esquimalt-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPG",
            "countryCode": "CA",
            "cityCode": "YPG",
            "cityName": "Portage La Prairie",
            "name": "[YPG] - Portage La Prairie - YPG - Portage La Prairie - Canada",
            "searchName": "YPG-Portage La Prairie-Portage La Prairie Tren Istasyonu Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPH",
            "countryCode": "CA",
            "cityCode": "YPH",
            "cityName": "Inukjuak",
            "name": "[YPH] - Inukjuak - YPH - Inukjuak - Canada",
            "searchName": "YPH-Inukjuak-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPJ",
            "countryCode": "CA",
            "cityCode": "YPJ",
            "cityName": "Aupaluk",
            "name": "[YPJ] - Aupaluk - YPJ - Aupaluk - Canada",
            "searchName": "YPJ-Aupaluk-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPL",
            "countryCode": "CA",
            "cityCode": "YPL",
            "cityName": "Pickle Lake",
            "name": "[YPL] - Pickle Lake - YPL - Pickle Lake - Canada",
            "searchName": "YPL-Pickle Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPM",
            "countryCode": "CA",
            "cityCode": "YPM",
            "cityName": "Pikangikum",
            "name": "[YPM] - Pikangikum - YPM - Pikangikum - Canada",
            "searchName": "YPM-Pikangikum-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPN",
            "countryCode": "CA",
            "cityCode": "YPN",
            "cityName": "Port Menier",
            "name": "[YPN] - Port Menier - YPN - Port Menier - Canada",
            "searchName": "YPN-Port Menier-Port Meiner-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPO",
            "countryCode": "CA",
            "cityCode": "YPO",
            "cityName": "Peawanuck",
            "name": "[YPO] - Peawanuck - YPO - Peawanuck - Canada",
            "searchName": "YPO-Peawanuck-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPP",
            "countryCode": "CA",
            "cityCode": "YPP",
            "cityName": "Pine Point",
            "name": "[YPP] - Pine Point - YPP - Pine Point - Canada",
            "searchName": "YPP-Pine Point-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPQ",
            "countryCode": "CA",
            "cityCode": "YPQ",
            "cityName": "Peterborough",
            "name": "[YPQ] - Peterborough - YPQ - Peterborough - Canada",
            "searchName": "YPQ-Peterborough-Peterborough Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPR",
            "countryCode": "CA",
            "cityCode": "YPR",
            "cityName": "Prince Rupert",
            "name": "[YPR] - Digby Island - YPR - Prince Rupert - Canada",
            "searchName": "YPR-Prince Rupert-Digby Island-Digby Island Airport-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XDW",
            "countryCode": "CA",
            "cityCode": "YPR",
            "cityName": "Prince Rupert",
            "name": "[XDW] - Prince Rupert Railway Station - YPR - Prince Rupert - Canada",
            "searchName": "XDW-YPR-Prince Rupert Railway Station-Prince Rupert-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZSW",
            "countryCode": "CA",
            "cityCode": "YPR",
            "cityName": "Prince Rupert",
            "name": "[ZSW] - Seal Cove - YPR - Prince Rupert - Canada",
            "searchName": "ZSW-YPR-Seal Cove-Seal Cove Havalimani-Prince Rupert-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPS",
            "countryCode": "CA",
            "cityCode": "YPS",
            "cityName": "Port Hawkesbury",
            "name": "[YPS] - Port Hawkesbury - YPS - Port Hawkesbury - Canada",
            "searchName": "YPS-Port Hawkesbury-Port Hawkesbury Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPT",
            "countryCode": "CA",
            "cityCode": "YPT",
            "cityName": "Pender Harbor",
            "name": "[YPT] - Pender Harbor - YPT - Pender Harbor - Canada",
            "searchName": "YPT-Pender Harbor-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPW",
            "countryCode": "CA",
            "cityCode": "YPW",
            "cityName": "Powell River",
            "name": "[YPW] - Powell River - YPW - Powell River - Canada",
            "searchName": "YPW-Powell River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPX",
            "countryCode": "CA",
            "cityCode": "YPX",
            "cityName": "Povungnituk",
            "name": "[YPX] - Puvirnituq - YPX - Povungnituk - Canada",
            "searchName": "YPX-Puvirnituq-Povungnituk-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPY",
            "countryCode": "CA",
            "cityCode": "YPY",
            "cityName": "Fort Chipewyan",
            "name": "[YPY] - Fort Chipewyan - YPY - Fort Chipewyan - Canada",
            "searchName": "YPY-Fort Chipewyan-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YPZ",
            "countryCode": "CA",
            "cityCode": "YPZ",
            "cityName": "Burns Lake",
            "name": "[YPZ] - Burns Lake - YPZ - Burns Lake - Canada",
            "searchName": "YPZ-Burns Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YQA",
            "countryCode": "CA",
            "cityCode": "YQA",
            "cityName": "Muskoka",
            "name": "[YQA] - Muskoka - YQA - Muskoka - Canada",
            "searchName": "YQA-Muskoka-Muskoka Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YDT",
            "countryCode": "CA",
            "cityCode": "YVR",
            "cityName": "Vancouver",
            "name": "[YDT] - Boundary Bay - YVR - Vancouver - Canada",
            "searchName": "YDT-YVR-Boundary Bay-Boundary Bay Havalimani-Vancouver-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XEA",
            "countryCode": "CA",
            "cityCode": "YVR",
            "cityName": "Vancouver",
            "name": "[XEA] - Vancouver Railway. - YVR - Vancouver - Canada",
            "searchName": "XEA-YVR-Vancouver Railway.-Vancouver-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "CXH",
            "countryCode": "CA",
            "cityCode": "YVR",
            "cityName": "Vancouver",
            "name": "[CXH] - Coal Harbour - YVR - Vancouver - Canada",
            "searchName": "CXH-YVR-Vancouver Coal Harbour-Coal Harbour-Vancouver-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVE",
            "countryCode": "CA",
            "cityCode": "YVE",
            "cityName": "Vernon",
            "name": "[YVE] - Vernon Regional Airport - YVE - Vernon - Canada",
            "searchName": "YVE-Vernon Regional Airport-Vernon  Havalimani-Vernon-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVG",
            "countryCode": "CA",
            "cityCode": "YVG",
            "cityName": "Vermilion",
            "name": "[YVG] - Vermilion - YVG - Vermilion - Canada",
            "searchName": "YVG-Vermilion-Vermilion Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZZ",
            "countryCode": "CA",
            "cityCode": "YZZ",
            "cityName": "Trail",
            "name": "[YZZ] - Trail Regional Airport - YZZ - Trail - Canada",
            "searchName": "YZZ-Trail Airport-Trail Regional Airport-Trail Havalimani-Trail-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZAC",
            "countryCode": "CA",
            "cityCode": "ZAC",
            "cityName": "York Landing",
            "name": "[ZAC] - York Landing - ZAC - York Landing - Canada",
            "searchName": "ZAC-York Landing-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVT",
            "countryCode": "CA",
            "cityCode": "YVT",
            "cityName": "Buffalo Narrows",
            "name": "[YVT] - Buffalo Narrows - YVT - Buffalo Narrows - Canada",
            "searchName": "YVT-Buffalo Narrows-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVV",
            "countryCode": "CA",
            "cityCode": "YVV",
            "cityName": "Wiarton",
            "name": "[YVV] - Wiarton/Keppel Airport - YVV - Wiarton - Canada",
            "searchName": "YVV-Wiarton/Keppel Airport-Wiarton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVZ",
            "countryCode": "CA",
            "cityCode": "YVZ",
            "cityName": "Deer Lake",
            "name": "[YVZ] - Deer Lake - YVZ - Deer Lake - Canada",
            "searchName": "YVZ-Deer Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWA",
            "countryCode": "CA",
            "cityCode": "YWA",
            "cityName": "Petawawa",
            "name": "[YWA] - Petawawa - YWA - Petawawa - Canada",
            "searchName": "YWA-Petawawa-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWB",
            "countryCode": "CA",
            "cityCode": "YWB",
            "cityName": "Kangiqsujuaq",
            "name": "[YWB] - Kangiqsujuaq - YWB - Kangiqsujuaq - Canada",
            "searchName": "YWB-Kangiqsujuaq-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XEF",
            "countryCode": "CA",
            "cityCode": "YWG",
            "cityName": "Winnipeg",
            "name": "[XEF] - Winnipeg Railway Station - YWG - Winnipeg - Canada",
            "searchName": "XEF-YWG-Winnipeg Railway Station-Winnipeg-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWG",
            "countryCode": "CA",
            "cityCode": "YWG",
            "cityName": "Winnipeg",
            "name": "[YWG] - Winnipeg - YWG - Winnipeg - Canada",
            "searchName": "YWG-Winnipeg-International-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWJ",
            "countryCode": "CA",
            "cityCode": "YWJ",
            "cityName": "Deline",
            "name": "[YWJ] - Deline - YWJ - Deline - Canada",
            "searchName": "YWJ-Deline-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWK",
            "countryCode": "CA",
            "cityCode": "YWK",
            "cityName": "Wabush",
            "name": "[YWK] - Wabush - YWK - Wabush - Canada",
            "searchName": "YWK-Wabush-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWL",
            "countryCode": "CA",
            "cityCode": "YWL",
            "cityName": "Williams Lake",
            "name": "[YWL] - Williams Lake - YWL - Williams Lake - Canada",
            "searchName": "YWL-Williams Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWM",
            "countryCode": "CA",
            "cityCode": "YWM",
            "cityName": "Williams Harbour",
            "name": "[YWM] - Williams Harbour - YWM - Williams Harbour - Canada",
            "searchName": "YWM-Williams Harbour-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWP",
            "countryCode": "CA",
            "cityCode": "YWP",
            "cityName": "Webequie",
            "name": "[YWP] - Webequie - YWP - Webequie - Canada",
            "searchName": "YWP-Webequie-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWQ",
            "countryCode": "CA",
            "cityCode": "YWQ",
            "cityName": "Chute-Des-Passes",
            "name": "[YWQ] - Chute-Des-Passes - YWQ - Chute-Des-Passes - Canada",
            "searchName": "YWQ-Chute-Des-Passes-Chute des Passes-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWR",
            "countryCode": "CA",
            "cityCode": "YWR",
            "cityName": "White River",
            "name": "[YWR] - White River - YWR - White River - Canada",
            "searchName": "YWR-White River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWS",
            "countryCode": "CA",
            "cityCode": "YWS",
            "cityName": "Whistler",
            "name": "[YWS] - Whistler - YWS - Whistler - Canada",
            "searchName": "YWS-Whistler-Whistler Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWY",
            "countryCode": "CA",
            "cityCode": "YWY",
            "cityName": "Wrigley",
            "name": "[YWY] - Wrigley - YWY - Wrigley - Canada",
            "searchName": "YWY-Wrigley-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXC",
            "countryCode": "CA",
            "cityCode": "YXC",
            "cityName": "Cranbrook",
            "name": "[YXC] - Cranbrook - YXC - Cranbrook - Canada",
            "searchName": "YXC-Cranbrook-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXE",
            "countryCode": "CA",
            "cityCode": "YXE",
            "cityName": "Saskatoon",
            "name": "[YXE] - John G. Diefenbaker International - YXE - Saskatoon - Canada",
            "searchName": "YXE-Saskatoon-John G. Diefenbaker International-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXF",
            "countryCode": "CA",
            "cityCode": "YXF",
            "cityName": "Snake River",
            "name": "[YXF] - Snake River - YXF - Snake River - Canada",
            "searchName": "YXF-Snake River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXH",
            "countryCode": "CA",
            "cityCode": "YXH",
            "cityName": "Medicine Hat",
            "name": "[YXH] - Medicine Hat - YXH - Medicine Hat - Canada",
            "searchName": "YXH-Medicine Hat-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXJ",
            "countryCode": "CA",
            "cityCode": "YXJ",
            "cityName": "Fort St John",
            "name": "[YXJ] - Fort St John - YXJ - Fort St John - Canada",
            "searchName": "YXJ-Fort Saint John-Fort St John-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXK",
            "countryCode": "CA",
            "cityCode": "YXK",
            "cityName": "Rimouski",
            "name": "[YXK] - Rimouski - YXK - Rimouski - Canada",
            "searchName": "YXK-Rimouski-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXL",
            "countryCode": "CA",
            "cityCode": "YXL",
            "cityName": "Sioux Lookout",
            "name": "[YXL] - Sioux Lookout - YXL - Sioux Lookout - Canada",
            "searchName": "YXL-Sioux Lookout-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXN",
            "countryCode": "CA",
            "cityCode": "YXN",
            "cityName": "Whale Cove",
            "name": "[YXN] - Whale Cove - YXN - Whale Cove - Canada",
            "searchName": "YXN-Whale Cove-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXP",
            "countryCode": "CA",
            "cityCode": "YXP",
            "cityName": "Pangnirtung",
            "name": "[YXP] - Pangnirtung - YXP - Pangnirtung - Canada",
            "searchName": "YXP-Pangnirtung-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXQ",
            "countryCode": "CA",
            "cityCode": "YXQ",
            "cityName": "Beaver Creek",
            "name": "[YXQ] - Beaver Creek - YXQ - Beaver Creek - Canada",
            "searchName": "YXQ-Beaver Creek-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXR",
            "countryCode": "CA",
            "cityCode": "YXR",
            "cityName": "Earlton",
            "name": "[YXR] - Earlton - YXR - Earlton - Canada",
            "searchName": "YXR-Earlton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXS",
            "countryCode": "CA",
            "cityCode": "YXS",
            "cityName": "Prince George",
            "name": "[YXS] - Prince George - YXS - Prince George - Canada",
            "searchName": "YXS-Prince George-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XDV",
            "countryCode": "CA",
            "cityCode": "YXS",
            "cityName": "Prince George",
            "name": "[XDV] - Pr. George Railway - YXS - Prince George - Canada",
            "searchName": "XDV-YXS-Pr. George Railway-Prince George-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXT",
            "countryCode": "CA",
            "cityCode": "YXT",
            "cityName": "Terrace",
            "name": "[YXT] - Terrace - YXT - Terrace - Canada",
            "searchName": "YXT-Terrace-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XAX",
            "countryCode": "CA",
            "cityCode": "YMQ",
            "cityName": "Montreal",
            "name": "[XAX] - Dorval Railway Station - YMQ - Montreal - Canada",
            "searchName": "XAX-YMQ-Dorval Railway Station-Montreal-Montr-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XLM",
            "countryCode": "CA",
            "cityCode": "YMQ",
            "cityName": "Montreal",
            "name": "[XLM] - St Lambert Railway - YMQ - Montreal - Canada",
            "searchName": "XLM-YMQ-St Lambert Railway-Montreal-Montr-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMR",
            "countryCode": "CA",
            "cityCode": "YMR",
            "cityName": "Merry Island",
            "name": "[YMR] - Merry Island - YMR - Merry Island - Canada",
            "searchName": "YMR-Merry Island-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLO",
            "countryCode": "CA",
            "cityCode": "YLO",
            "cityName": "Shilo",
            "name": "[YLO] - Shilo - YLO - Shilo - Canada",
            "searchName": "YLO-Shilo-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLP",
            "countryCode": "CA",
            "cityCode": "YLP",
            "cityName": "Mingan",
            "name": "[YLP] - Mingan - YLP - Mingan - Canada",
            "searchName": "YLP-Mingan-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLQ",
            "countryCode": "CA",
            "cityCode": "YLQ",
            "cityName": "La Tuque",
            "name": "[YLQ] - La Tuque - YLQ - La Tuque - Canada",
            "searchName": "YLQ-La Tuque-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLR",
            "countryCode": "CA",
            "cityCode": "YLR",
            "cityName": "Leaf Rapids",
            "name": "[YLR] - Leaf Rapids - YLR - Leaf Rapids - Canada",
            "searchName": "YLR-Leaf Rapids-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLS",
            "countryCode": "CA",
            "cityCode": "YLS",
            "cityName": "Lebel-Sur-Quevillon",
            "name": "[YLS] - Lebel-Sur-Quevillon - YLS - Lebel-Sur-Quevillon - Canada",
            "searchName": "YLS-Sur-Quevillon QC - Flughafen-Lebel-Quevillon-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLT",
            "countryCode": "CA",
            "cityCode": "YLT",
            "cityName": "Alert",
            "name": "[YLT] - Alert - YLT - Alert - Canada",
            "searchName": "YLT-Alert-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLW",
            "countryCode": "CA",
            "cityCode": "YLW",
            "cityName": "Kelowna",
            "name": "[YLW] - Kelowna Airport - YLW - Kelowna - Canada",
            "searchName": "YLW-Kelowna-Kelowna Airport-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLX",
            "countryCode": "CA",
            "cityCode": "YLX",
            "cityName": "Long Point",
            "name": "[YLX] - Long Point - YLX - Long Point - Canada",
            "searchName": "YLX-Long Point-Longpoint Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLY",
            "countryCode": "CA",
            "cityCode": "YLY",
            "cityName": "Langley",
            "name": "[YLY] - Langley Regional - YLY - Langley - Canada",
            "searchName": "YLY-Langley Regional-Langley Yerel Havalimani-Langley-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMA",
            "countryCode": "CA",
            "cityCode": "YMA",
            "cityName": "Mayo",
            "name": "[YMA] - Mayo - YMA - Mayo - Canada",
            "searchName": "YMA-Mayo-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMB",
            "countryCode": "CA",
            "cityCode": "YMB",
            "cityName": "Merritt",
            "name": "[YMB] - Merritt - YMB - Merritt - Canada",
            "searchName": "YMB-Merritt-Merritt Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMC",
            "countryCode": "CA",
            "cityCode": "YMC",
            "cityName": "Maricourt Airstrip",
            "name": "[YMC] - Maricourt Airstrip - YMC - Maricourt Airstrip - Canada",
            "searchName": "YMC-Maricourt Airstrip-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YME",
            "countryCode": "CA",
            "cityCode": "YME",
            "cityName": "Matane",
            "name": "[YME] - Matane - YME - Matane - Canada",
            "searchName": "YME-Matane-Matane Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMF",
            "countryCode": "CA",
            "cityCode": "YMF",
            "cityName": "Montagne Harbor",
            "name": "[YMF] - Montagne Harbor - YMF - Montagne Harbor - Canada",
            "searchName": "YMF-Montagne Harbor-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMG",
            "countryCode": "CA",
            "cityCode": "YMG",
            "cityName": "Manitouwadge",
            "name": "[YMG] - Manitouwadge - YMG - Manitouwadge - Canada",
            "searchName": "YMG-Manitouwadge-Manitouwadge  Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMH",
            "countryCode": "CA",
            "cityCode": "YMH",
            "cityName": "Mary s Harbour",
            "name": "[YMH] - Marys Harbour - YMH - Mary s Harbour - Canada",
            "searchName": "YMH-Mary s Harbour-Marys Harbour-Harbour-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMI",
            "countryCode": "CA",
            "cityCode": "YMI",
            "cityName": "Minaki",
            "name": "[YMI] - Minaki - YMI - Minaki - Canada",
            "searchName": "YMI-Minaki-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YMJ",
            "countryCode": "CA",
            "cityCode": "YMJ",
            "cityName": "Moose Jaw",
            "name": "[YMJ] - Moose Jaw / Air Vice Marshal C. M. Mcewen Airport - YMJ - Moose Jaw - Canada",
            "searchName": "YMJ-Moose Jaw / Air Vice Marshal C. M. Mcewen Airport-Moose Jaw Havalimani-Moose Jaw-Regina-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGK",
            "countryCode": "CA",
            "cityCode": "YGK",
            "cityName": "Kingston",
            "name": "[YGK] - Kingston - YGK - Kingston - Canada",
            "searchName": "YGK-Kingston Kanada-Kingston-Norman Rogers Airport-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XEG",
            "countryCode": "CA",
            "cityCode": "YGK",
            "cityName": "Kingston",
            "name": "[XEG] - Kingston Railway Station - YGK - Kingston - Canada",
            "searchName": "XEG-YGK-Kingston Railway Station-Kingston-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGL",
            "countryCode": "CA",
            "cityCode": "YGL",
            "cityName": "La Grande",
            "name": "[YGL] - La Grande - YGL - La Grande - Canada",
            "searchName": "YGL-La Grande-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGM",
            "countryCode": "CA",
            "cityCode": "YGM",
            "cityName": "Gimli",
            "name": "[YGM] - Gimli - YGM - Gimli - Canada",
            "searchName": "YGM-Gimli-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGN",
            "countryCode": "CA",
            "cityCode": "YGN",
            "cityName": "Greenway Sound",
            "name": "[YGN] - Greenway Sound - YGN - Greenway Sound - Canada",
            "searchName": "YGN-Greenway Sound-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGO",
            "countryCode": "CA",
            "cityCode": "YGO",
            "cityName": "Gods Narrows",
            "name": "[YGO] - Gods Narrows - YGO - Gods Narrows - Canada",
            "searchName": "YGO-Gods Narrows-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGP",
            "countryCode": "CA",
            "cityCode": "YGP",
            "cityName": "Gaspe",
            "name": "[YGP] - Michel Pouliot - YGP - Gaspe - Canada",
            "searchName": "YGP-Gaspe-Michel Pouliot-Gasp-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XDD",
            "countryCode": "CA",
            "cityCode": "YGP",
            "cityName": "Gaspe",
            "name": "[XDD] - Gaspe Railway Station - YGP - Gaspe - Canada",
            "searchName": "XDD-YGP-Gaspe Railway Station-Gasp-Gaspe-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGQ",
            "countryCode": "CA",
            "cityCode": "YGQ",
            "cityName": "Geraldton",
            "name": "[YGQ] - Geraldton - YGQ - Geraldton - Canada",
            "searchName": "YGQ-Geraldton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGR",
            "countryCode": "CA",
            "cityCode": "YGR",
            "cityName": "Iles De La Madeleine",
            "name": "[YGR] - Iles De La Madeleine - YGR - Iles De La Madeleine - Canada",
            "searchName": "YGR-Iles De La Madeleine-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGT",
            "countryCode": "CA",
            "cityCode": "YGT",
            "cityName": "Igloolik",
            "name": "[YGT] - Igloolik - YGT - Igloolik - Canada",
            "searchName": "YGT-Igloolik-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGV",
            "countryCode": "CA",
            "cityCode": "YGV",
            "cityName": "Havre St Pierre",
            "name": "[YGV] - Havre St Pierre - YGV - Havre St Pierre - Canada",
            "searchName": "YGV-Havre St. Pierre-Havre St Pierre-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGW",
            "countryCode": "CA",
            "cityCode": "YGW",
            "cityName": "Kuujjuarapik",
            "name": "[YGW] - Kuujjuarapik - YGW - Kuujjuarapik - Canada",
            "searchName": "YGW-Kuujjuarapik-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGX",
            "countryCode": "CA",
            "cityCode": "YGX",
            "cityName": "Gillam",
            "name": "[YGX] - Gillam - YGX - Gillam - Canada",
            "searchName": "YGX-Gillam-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGZ",
            "countryCode": "CA",
            "cityCode": "YGZ",
            "cityName": "Grise Fiord",
            "name": "[YGZ] - Grise Fiord - YGZ - Grise Fiord - Canada",
            "searchName": "YGZ-Grise Fiord-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHA",
            "countryCode": "CA",
            "cityCode": "YHA",
            "cityName": "Port Hope Simpson",
            "name": "[YHA] - Port Hope Simpson - YHA - Port Hope Simpson - Canada",
            "searchName": "YHA-Port Hope Simpson-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHB",
            "countryCode": "CA",
            "cityCode": "YHB",
            "cityName": "Hudson Bay",
            "name": "[YHB] - Hudson Bay - YHB - Hudson Bay - Canada",
            "searchName": "YHB-Hudson Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHC",
            "countryCode": "CA",
            "cityCode": "YHC",
            "cityName": "Hakai Pass",
            "name": "[YHC] - Hakai Pass - YHC - Hakai Pass - Canada",
            "searchName": "YHC-Hakai Pass-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHD",
            "countryCode": "CA",
            "cityCode": "YHD",
            "cityName": "Dryden",
            "name": "[YHD] - Dryden Municipal - YHD - Dryden - Canada",
            "searchName": "YHD-Dryden-Dryden Municipal-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHE",
            "countryCode": "CA",
            "cityCode": "YHE",
            "cityName": "Hope",
            "name": "[YHE] - Hope - YHE - Hope - Canada",
            "searchName": "YHE-Hope-Hope Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHF",
            "countryCode": "CA",
            "cityCode": "YHF",
            "cityName": "Hearst",
            "name": "[YHF] - Hearst - YHF - Hearst - Canada",
            "searchName": "YHF-Hearst-Hearst  Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHG",
            "countryCode": "CA",
            "cityCode": "YHG",
            "cityName": "Charlottetown",
            "name": "[YHG] - Charlottetown - YHG - Charlottetown - Canada",
            "searchName": "YHG-Charlottetown-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHI",
            "countryCode": "CA",
            "cityCode": "YHI",
            "cityName": "Holman",
            "name": "[YHI] - Holman - YHI - Holman - Canada",
            "searchName": "YHI-Holman-Ulukaktok-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHK",
            "countryCode": "CA",
            "cityCode": "YHK",
            "cityName": "Gjoa Haven",
            "name": "[YHK] - Gjoa Haven - YHK - Gjoa Haven - Canada",
            "searchName": "YHK-Gjoa Haven-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHN",
            "countryCode": "CA",
            "cityCode": "YHN",
            "cityName": "Hornepayne",
            "name": "[YHN] - Hornepayne - YHN - Hornepayne - Canada",
            "searchName": "YHN-Hornepayne-Hornepayne Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHO",
            "countryCode": "CA",
            "cityCode": "YHO",
            "cityName": "Hopedale",
            "name": "[YHO] - Hopedale - YHO - Hopedale - Canada",
            "searchName": "YHO-Hopedale-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHP",
            "countryCode": "CA",
            "cityCode": "YHP",
            "cityName": "Poplar Hill",
            "name": "[YHP] - Poplar Hill - YHP - Poplar Hill - Canada",
            "searchName": "YHP-Poplar Hill-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHR",
            "countryCode": "CA",
            "cityCode": "YHR",
            "cityName": "Chevery",
            "name": "[YHR] - Chevery - YHR - Chevery - Canada",
            "searchName": "YHR-Chevery-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHS",
            "countryCode": "CA",
            "cityCode": "YHS",
            "cityName": "Sechelt",
            "name": "[YHS] - Sechelt - YHS - Sechelt - Canada",
            "searchName": "YHS-Sechelt-Sechelt Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHT",
            "countryCode": "CA",
            "cityCode": "YHT",
            "cityName": "Haines Junction",
            "name": "[YHT] - Haines Junction - YHT - Haines Junction - Canada",
            "searchName": "YHT-Haines Junction-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHY",
            "countryCode": "CA",
            "cityCode": "YHY",
            "cityName": "Hay River",
            "name": "[YHY] - Hay River - YHY - Hay River - Canada",
            "searchName": "YHY-Hay River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YHZ",
            "countryCode": "CA",
            "cityCode": "YHZ",
            "cityName": "Halifax",
            "name": "[YHZ] - Halifax International - YHZ - Halifax - Canada",
            "searchName": "YHZ-Halifax-Halifax International-Halifax Intl-International-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAW",
            "countryCode": "CA",
            "cityCode": "YHZ",
            "cityName": "Halifax",
            "name": "[YAW] - Shearwater - YHZ - Halifax - Canada",
            "searchName": "YAW-YHZ-Shearwater-Halifax-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XDG",
            "countryCode": "CA",
            "cityCode": "YHZ",
            "cityName": "Halifax",
            "name": "[XDG] - Halifax Railway Station - YHZ - Halifax - Canada",
            "searchName": "XDG-YHZ-Halifax Railway Station-Halifax-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWF",
            "countryCode": "CA",
            "cityCode": "YHZ",
            "cityName": "Halifax",
            "name": "[YWF] - Downtown Waterfront Heliport - YHZ - Halifax - Canada",
            "searchName": "YWF-YHZ-Downtown Waterfront Heliport-Halifax-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YIB",
            "countryCode": "CA",
            "cityCode": "YIB",
            "cityName": "Atikokan",
            "name": "[YIB] - Atikokan - YIB - Atikokan - Canada",
            "searchName": "YIB-Kommunaler Flughafen-Atikokan-Atikokan  Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFX",
            "countryCode": "CA",
            "cityCode": "YFX",
            "cityName": "Fox Harbour (St Lewis)",
            "name": "[YFX] - Fox Harbour (St Lewis) - YFX - Fox Harbour (St Lewis) - Canada",
            "searchName": "YFX-Fox Harbour (St Lewis)-Fox Harbour\\/St Lewis-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGB",
            "countryCode": "CA",
            "cityCode": "YGB",
            "cityName": "Gillies Bay",
            "name": "[YGB] - Gillies Bay - YGB - Gillies Bay - Canada",
            "searchName": "YGB-Gillies Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGC",
            "countryCode": "CA",
            "cityCode": "YGC",
            "cityName": "Grande Cache",
            "name": "[YGC] - Grande Cache - YGC - Grande Cache - Canada",
            "searchName": "YGC-Grande Cache-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGE",
            "countryCode": "CA",
            "cityCode": "YGE",
            "cityName": "Gorge Harbor",
            "name": "[YGE] - Gorge Harbor - YGE - Gorge Harbor - Canada",
            "searchName": "YGE-Gorge Harbor-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGG",
            "countryCode": "CA",
            "cityCode": "YGG",
            "cityName": "Ganges Harbor",
            "name": "[YGG] - Ganges Harbor - YGG - Ganges Harbor - Canada",
            "searchName": "YGG-Ganges Harbor-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YGH",
            "countryCode": "CA",
            "cityCode": "YGH",
            "cityName": "Fort Good Hope",
            "name": "[YGH] - Fort Good Hope - YGH - Fort Good Hope - Canada",
            "searchName": "YGH-Fort Good Hope-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YER",
            "countryCode": "CA",
            "cityCode": "YER",
            "cityName": "Fort Severn",
            "name": "[YER] - Fort Severn - YER - Fort Severn - Canada",
            "searchName": "YER-Fort Severn-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YIF",
            "countryCode": "CA",
            "cityCode": "YIF",
            "cityName": "Pakuashipi",
            "name": "[YIF] - Pakuashipi - YIF - Pakuashipi - Canada",
            "searchName": "YIF-Pakuashipi-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YIG",
            "countryCode": "CA",
            "cityCode": "YIG",
            "cityName": "Big Bay Marina",
            "name": "[YIG] - Big Bay Marina - YIG - Big Bay Marina - Canada",
            "searchName": "YIG-Big Bay Marina-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YIK",
            "countryCode": "CA",
            "cityCode": "YIK",
            "cityName": "Ivujivik",
            "name": "[YIK] - Ivujivik - YIK - Ivujivik - Canada",
            "searchName": "YIK-Ivujivik-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YIO",
            "countryCode": "CA",
            "cityCode": "YIO",
            "cityName": "Pond Inlet",
            "name": "[YIO] - Pond Inlet - YIO - Pond Inlet - Canada",
            "searchName": "YIO-Pond Inlet-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YIV",
            "countryCode": "CA",
            "cityCode": "YIV",
            "cityName": "Island Lake",
            "name": "[YIV] - Island Lk/Garden Hill - YIV - Island Lake - Canada",
            "searchName": "YIV-Island Lk/Garden Hill-Island Lake-Island Lk-Island Lake\\/Garden Hill-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XDH",
            "countryCode": "CA",
            "cityCode": "YJA",
            "cityName": "Jasper",
            "name": "[XDH] - Jasper Railway Station - YJA - Jasper - Canada",
            "searchName": "XDH-YJA-Jasper Railway Station-Jasper-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YJF",
            "countryCode": "CA",
            "cityCode": "YJF",
            "cityName": "Fort Liard",
            "name": "[YJF] - Fort Liard - YJF - Fort Liard - Canada",
            "searchName": "YJF-Fort Liard-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YJN",
            "countryCode": "CA",
            "cityCode": "YJN",
            "cityName": "St Jean",
            "name": "[YJN] - St Jean - YJN - St Jean - Canada",
            "searchName": "YJN-St Jean-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YJO",
            "countryCode": "CA",
            "cityCode": "YJO",
            "cityName": "Johnny Mountain",
            "name": "[YJO] - Johnny Mountain - YJO - Johnny Mountain - Canada",
            "searchName": "YJO-Johnny Mountain-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YJP",
            "countryCode": "CA",
            "cityCode": "YJP",
            "cityName": "Jasper-hinton",
            "name": "[YJP] - Jasper-hinton - YJP - Jasper-hinton - Canada",
            "searchName": "YJP-Jasper-hinton-Jasper Hinton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YJT",
            "countryCode": "CA",
            "cityCode": "YJT",
            "cityName": "Stephenville",
            "name": "[YJT] - Stephenville - YJT - Stephenville - Canada",
            "searchName": "YJT-Stephenville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKA",
            "countryCode": "CA",
            "cityCode": "YKA",
            "cityName": "Kamloops",
            "name": "[YKA] - Kamloops - YKA - Kamloops - Canada",
            "searchName": "YKA-Kamloops-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKC",
            "countryCode": "CA",
            "cityCode": "YKC",
            "cityName": "Collins Bay",
            "name": "[YKC] - Collins Bay - YKC - Collins Bay - Canada",
            "searchName": "YKC-Collins Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKD",
            "countryCode": "CA",
            "cityCode": "YKD",
            "cityName": "Kincardine",
            "name": "[YKD] - Township Airport - YKD - Kincardine - Canada",
            "searchName": "YKD-Township Airport-Kincardine-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKE",
            "countryCode": "CA",
            "cityCode": "YKE",
            "cityName": "Knee Lake",
            "name": "[YKE] - Knee Lake - YKE - Knee Lake - Canada",
            "searchName": "YKE-Knee Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKF",
            "countryCode": "CA",
            "cityCode": "YKF",
            "cityName": "Kitchener-Waterloo",
            "name": "[YKF] - Kitchener-Waterloo Regional - YKF - Kitchener-Waterloo - Canada",
            "searchName": "YKF-Kitchener Lake-Kitchener-Waterloo Regional-Waterloo-Kitchener/Waterloo-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKG",
            "countryCode": "CA",
            "cityCode": "YKG",
            "cityName": "Kangirsuk",
            "name": "[YKG] - Kangirsuk - YKG - Kangirsuk - Canada",
            "searchName": "YKG-Kangirsuk-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKI",
            "countryCode": "CA",
            "cityCode": "YKI",
            "cityName": "Kennosao Lake",
            "name": "[YKI] - Kennosao Lake - YKI - Kennosao Lake - Canada",
            "searchName": "YKI-Kennosao Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKJ",
            "countryCode": "CA",
            "cityCode": "YKJ",
            "cityName": "Key Lake",
            "name": "[YKJ] - Key Lake - YKJ - Key Lake - Canada",
            "searchName": "YKJ-Key Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKK",
            "countryCode": "CA",
            "cityCode": "YKK",
            "cityName": "Kitkatla",
            "name": "[YKK] - Kitkatla - YKK - Kitkatla - Canada",
            "searchName": "YKK-Kitkatla-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKL",
            "countryCode": "CA",
            "cityCode": "YKL",
            "cityName": "Schefferville",
            "name": "[YKL] - Schefferville - YKL - Schefferville - Canada",
            "searchName": "YKL-Schefferville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKT",
            "countryCode": "CA",
            "cityCode": "YKT",
            "cityName": "Klemtu",
            "name": "[YKT] - Klemtu - YKT - Klemtu - Canada",
            "searchName": "YKT-Klemtu-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKU",
            "countryCode": "CA",
            "cityCode": "YKU",
            "cityName": "Chisasibi",
            "name": "[YKU] - Chisasibi - YKU - Chisasibi - Canada",
            "searchName": "YKU-Chisasibi-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKX",
            "countryCode": "CA",
            "cityCode": "YKX",
            "cityName": "Kirkland Lake",
            "name": "[YKX] - Kirkland Lake - YKX - Kirkland Lake - Canada",
            "searchName": "YKX-Kirkland Lake-Kirkland Lake  Havalimani-Kirkland-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YKY",
            "countryCode": "CA",
            "cityCode": "YKY",
            "cityName": "Kindersley",
            "name": "[YKY] - Kindersley - YKY - Kindersley - Canada",
            "searchName": "YKY-Kindersley-Kindersley Havalimani-Saskatoon-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLA",
            "countryCode": "CA",
            "cityCode": "YLA",
            "cityName": "Langara",
            "name": "[YLA] - Langara - YLA - Langara - Canada",
            "searchName": "YLA-Langara-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLB",
            "countryCode": "CA",
            "cityCode": "YLB",
            "cityName": "Lac Biche",
            "name": "[YLB] - Lac Biche - YLB - Lac Biche - Canada",
            "searchName": "YLB-Lac Biche-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLC",
            "countryCode": "CA",
            "cityCode": "YLC",
            "cityName": "Kimmirut",
            "name": "[YLC] - Kimmirut - YLC - Kimmirut - Canada",
            "searchName": "YLC-Kimmirut-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLD",
            "countryCode": "CA",
            "cityCode": "YLD",
            "cityName": "Chapleau",
            "name": "[YLD] - Chapleau - YLD - Chapleau - Canada",
            "searchName": "YLD-Chapleau-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLE",
            "countryCode": "CA",
            "cityCode": "YLE",
            "cityName": "Wha Ti",
            "name": "[YLE] - Wha Ti - YLE - Wha Ti - Canada",
            "searchName": "YLE-Wha Ti-Wha Ti\\/Lac La Martre-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YLF",
            "countryCode": "CA",
            "cityCode": "YLF",
            "cityName": "LaForges",
            "name": "[YLF] - LaForges - YLF - LaForges - Canada",
            "searchName": "YLF-LaForges-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZBM",
            "countryCode": "CA",
            "cityCode": "ZBM",
            "cityName": "Bromont",
            "name": "[ZBM] - Bromont - ZBM - Bromont - Canada",
            "searchName": "ZBM-Bromont-Bromont Yerel Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZBF",
            "countryCode": "CA",
            "cityCode": "ZBF",
            "cityName": "Bathurst",
            "name": "[ZBF] - Bathurst - ZBF - Bathurst - Canada",
            "searchName": "ZBF-Bathurst Kanada-Bathurst-Bathhurst-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVB",
            "countryCode": "CA",
            "cityCode": "YVB",
            "cityName": "Bonaventure",
            "name": "[YVB] - Bonaventure - YVB - Bonaventure - Canada",
            "searchName": "YVB-Bonaventure-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVC",
            "countryCode": "CA",
            "cityCode": "YVC",
            "cityName": "La Ronge",
            "name": "[YVC] - La Ronge - YVC - La Ronge - Canada",
            "searchName": "YVC-La Ronge-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YUT",
            "countryCode": "CA",
            "cityCode": "YUT",
            "cityName": "Repulse Bay",
            "name": "[YUT] - Repulse Bay - YUT - Repulse Bay - Canada",
            "searchName": "YUT-Repulse Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YUX",
            "countryCode": "CA",
            "cityCode": "YUX",
            "cityName": "Hall Beach",
            "name": "[YUX] - Hall Beach - YUX - Hall Beach - Canada",
            "searchName": "YUX-Hall Beach-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YUY",
            "countryCode": "CA",
            "cityCode": "YUY",
            "cityName": "Rouyn-Noranda",
            "name": "[YUY] - Rouyn-Noranda - YUY - Rouyn-Noranda - Canada",
            "searchName": "YUY-Rouyn-Noranda-Noranda\\/Rouyn-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVM",
            "countryCode": "CA",
            "cityCode": "YVM",
            "cityName": "Qikiqtarjuaq",
            "name": "[YVM] - Qikiqtarjuaq - YVM - Qikiqtarjuaq - Canada",
            "searchName": "YVM-Qikiqtarjuaq-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVN",
            "countryCode": "CA",
            "cityCode": "YVN",
            "cityName": "Cape Dyer",
            "name": "[YVN] - Cape Dyer - YVN - Cape Dyer - Canada",
            "searchName": "YVN-Cape Dyer-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVO",
            "countryCode": "CA",
            "cityCode": "YVO",
            "cityName": "Val-d or",
            "name": "[YVO] - Airoport Regional De Val-dor - YVO - Val-d or - Canada",
            "searchName": "YVO-Val D Or-Aéroport Régional De Val-d or-Airoport Regional De Val-dor-Val d Or-Val-d Or-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVP",
            "countryCode": "CA",
            "cityCode": "YVP",
            "cityName": "Kuujjuaq",
            "name": "[YVP] - Kuujjuaq - YVP - Kuujjuaq - Canada",
            "searchName": "YVP-Kuujjuaq-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YVQ",
            "countryCode": "CA",
            "cityCode": "YVQ",
            "cityName": "Norman Wells",
            "name": "[YVQ] - Norman Wells - YVQ - Norman Wells - Canada",
            "searchName": "YVQ-Norman Wells-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XDQ",
            "countryCode": "CA",
            "cityCode": "YXU",
            "cityName": "London",
            "name": "[XDQ] - London Railway Station - YXU - London - Canada",
            "searchName": "XDQ-YXU-London Railway Station-London-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXX",
            "countryCode": "CA",
            "cityCode": "YXX",
            "cityName": "Abbotsford",
            "name": "[YXX] - Abbotsford - YXX - Abbotsford - Canada",
            "searchName": "YXX-Abbotsford-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXY",
            "countryCode": "CA",
            "cityCode": "YXY",
            "cityName": "Whitehorse",
            "name": "[YXY] - Whitehorse International - YXY - Whitehorse - Canada",
            "searchName": "YXY-Whitehorse-Whitehorse International-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YXZ",
            "countryCode": "CA",
            "cityCode": "YXZ",
            "cityName": "Wawa",
            "name": "[YXZ] - Wawa Airport - YXZ - Wawa - Canada",
            "searchName": "YXZ-Wawa Airport-Wawa  Havalimani-Wawa-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYA",
            "countryCode": "CA",
            "cityCode": "YYA",
            "cityName": "Big Bay Yacht Club",
            "name": "[YYA] - Big Bay Yacht Club - YYA - Big Bay Yacht Club - Canada",
            "searchName": "YYA-Big Bay Yacht Club-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYB",
            "countryCode": "CA",
            "cityCode": "YYB",
            "cityName": "North Bay",
            "name": "[YYB] - North Bay - YYB - North Bay - Canada",
            "searchName": "YYB-North Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYC",
            "countryCode": "CA",
            "cityCode": "YYC",
            "cityName": "Calgary",
            "name": "[YYC] - Calgary International Airport - YYC - Calgary - Canada",
            "searchName": "YYC-Calgary-Calgary International Airport-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYD",
            "countryCode": "CA",
            "cityCode": "YYD",
            "cityName": "Smithers",
            "name": "[YYD] - Smithers - YYD - Smithers - Canada",
            "searchName": "YYD-Smithers-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYE",
            "countryCode": "CA",
            "cityCode": "YYE",
            "cityName": "Fort Nelson",
            "name": "[YYE] - Fort Nelson - YYE - Fort Nelson - Canada",
            "searchName": "YYE-Fort Nelson-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYF",
            "countryCode": "CA",
            "cityCode": "YYF",
            "cityName": "Penticton",
            "name": "[YYF] - Penticton - YYF - Penticton - Canada",
            "searchName": "YYF-Penticton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYG",
            "countryCode": "CA",
            "cityCode": "YYG",
            "cityName": "Charlottetown",
            "name": "[YYG] - Charlottetown - YYG - Charlottetown - Canada",
            "searchName": "YYG-Charlottetown Prinz Edw Insel-Charlottetown-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYH",
            "countryCode": "CA",
            "cityCode": "YYH",
            "cityName": "Taloyoak",
            "name": "[YYH] - Taloyoak - YYH - Taloyoak - Canada",
            "searchName": "YYH-Taloyoak-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYJ",
            "countryCode": "CA",
            "cityCode": "YYJ",
            "cityName": "Victoria",
            "name": "[YYJ] - Victoria International - YYJ - Victoria - Canada",
            "searchName": "YYJ-Victoria Intl-Victoria International-International-Victoria-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YWH",
            "countryCode": "CA",
            "cityCode": "YYJ",
            "cityName": "Victoria",
            "name": "[YWH] - Victoria Inner Harbor - YYJ - Victoria - Canada",
            "searchName": "YWH-YYJ-Victoria-Victoria Inner Harbor-Inner Harbor-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYL",
            "countryCode": "CA",
            "cityCode": "YYL",
            "cityName": "Lynn Lake",
            "name": "[YYL] - Lynn Lake - YYL - Lynn Lake - Canada",
            "searchName": "YYL-Lynn Lake-Lynn Lake Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYM",
            "countryCode": "CA",
            "cityCode": "YYM",
            "cityName": "Cowley",
            "name": "[YYM] - Cowley - YYM - Cowley - Canada",
            "searchName": "YYM-Cowley-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYN",
            "countryCode": "CA",
            "cityCode": "YYN",
            "cityName": "Swift Current",
            "name": "[YYN] - Swift Current - YYN - Swift Current - Canada",
            "searchName": "YYN-Swift Current-Swift Current Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYQ",
            "countryCode": "CA",
            "cityCode": "YYQ",
            "cityName": "Churchill",
            "name": "[YYQ] - Churchill Airport - YYQ - Churchill - Canada",
            "searchName": "YYQ-Churchill-Churchill Airport-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XAD",
            "countryCode": "CA",
            "cityCode": "YYQ",
            "cityName": "Churchill",
            "name": "[XAD] - Churchill Railway Station - YYQ - Churchill - Canada",
            "searchName": "XAD-YYQ-Churchill Railway Station-Churchill-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYR",
            "countryCode": "CA",
            "cityCode": "YYR",
            "cityName": "Happy Valley-Goose Bay",
            "name": "[YYR] - Goose Bay - YYR - Happy Valley-Goose Bay - Canada",
            "searchName": "YYR-Goose Bay-Happy Valley-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYT",
            "countryCode": "CA",
            "cityCode": "YYT",
            "cityName": "St. John s",
            "name": "[YYT] - St. Johns International - YYT - St. John s - Canada",
            "searchName": "YYT-Saint John s-St. John s International-St John s-St. Johns International-Saint Johns-St. John s-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYU",
            "countryCode": "CA",
            "cityCode": "YYU",
            "cityName": "Kapuskasing",
            "name": "[YYU] - Kapuskasing - YYU - Kapuskasing - Canada",
            "searchName": "YYU-Kapuskasing-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYW",
            "countryCode": "CA",
            "cityCode": "YYW",
            "cityName": "Armstrong",
            "name": "[YYW] - Armstrong - YYW - Armstrong - Canada",
            "searchName": "YYW-Armstrong-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YYY",
            "countryCode": "CA",
            "cityCode": "YYY",
            "cityName": "Mont Joli",
            "name": "[YYY] - Mont Joli - YYY - Mont Joli - Canada",
            "searchName": "YYY-Mont Joli-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZA",
            "countryCode": "CA",
            "cityCode": "YZA",
            "cityName": "Ashcroft",
            "name": "[YZA] - Ashcroft - YZA - Ashcroft - Canada",
            "searchName": "YZA-Ashcroft-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZC",
            "countryCode": "CA",
            "cityCode": "YZC",
            "cityName": "Beatton River",
            "name": "[YZC] - Beatton River - YZC - Beatton River - Canada",
            "searchName": "YZC-Beatton River-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZE",
            "countryCode": "CA",
            "cityCode": "YZE",
            "cityName": "Gore Bay",
            "name": "[YZE] - Gore Bay - YZE - Gore Bay - Canada",
            "searchName": "YZE-Gore Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZF",
            "countryCode": "CA",
            "cityCode": "YZF",
            "cityName": "Yellowknife",
            "name": "[YZF] - Yellowknife - YZF - Yellowknife - Canada",
            "searchName": "YZF-Yellowknife-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZH",
            "countryCode": "CA",
            "cityCode": "YZH",
            "cityName": "Slave Lake",
            "name": "[YZH] - Slave Lake - YZH - Slave Lake - Canada",
            "searchName": "YZH-Slave Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZM",
            "countryCode": "CA",
            "cityCode": "YZM",
            "cityName": "Buchans",
            "name": "[YZM] - Buchans - YZM - Buchans - Canada",
            "searchName": "YZM-Buchans-Buchans NF-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZP",
            "countryCode": "CA",
            "cityCode": "YZP",
            "cityName": "Sandspit",
            "name": "[YZP] - Sandspit - YZP - Sandspit - Canada",
            "searchName": "YZP-Sandspit-Federal Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZR",
            "countryCode": "CA",
            "cityCode": "YZR",
            "cityName": "Sarnia",
            "name": "[YZR] - Sarnia - YZR - Sarnia - Canada",
            "searchName": "YZR-Sarnia-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "XDX",
            "countryCode": "CA",
            "cityCode": "YZR",
            "cityName": "Sarnia",
            "name": "[XDX] - Sarnia Railway Station - YZR - Sarnia - Canada",
            "searchName": "XDX-YZR-Sarnia Railway Station-Sarnia-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZS",
            "countryCode": "CA",
            "cityCode": "YZS",
            "cityName": "Coral Harbour",
            "name": "[YZS] - Coral Harbour - YZS - Coral Harbour - Canada",
            "searchName": "YZS-Coral Harbour-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZT",
            "countryCode": "CA",
            "cityCode": "YZT",
            "cityName": "Port Hardy",
            "name": "[YZT] - Port Hardy - YZT - Port Hardy - Canada",
            "searchName": "YZT-Port Hardy-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZU",
            "countryCode": "CA",
            "cityCode": "YZU",
            "cityName": "Whitecourt",
            "name": "[YZU] - Whitecourt - YZU - Whitecourt - Canada",
            "searchName": "YZU-Whitecourt-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZV",
            "countryCode": "CA",
            "cityCode": "YZV",
            "cityName": "Sept-Iles",
            "name": "[YZV] - Sept-Iles - YZV - Sept-Iles - Canada",
            "searchName": "YZV-Sept-Iles-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZW",
            "countryCode": "CA",
            "cityCode": "YZW",
            "cityName": "Teslin",
            "name": "[YZW] - Teslin - YZW - Teslin - Canada",
            "searchName": "YZW-Teslin-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YZX",
            "countryCode": "CA",
            "cityCode": "YZX",
            "cityName": "Greenwood",
            "name": "[YZX] - Greenwood - YZX - Greenwood - Canada",
            "searchName": "YZX-Greenwood-Greenwood Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "CCK",
            "countryCode": "CC",
            "cityCode": "CCK",
            "cityName": "Cocos Islands",
            "name": "[CCK] - Cocos Islands - CCK - Cocos Islands - Cocos Islands",
            "searchName": "CCK-Cocos Islands-Cocos (Keeling) Islands-Islas Cocos-Îles Cocos-Isole Cocos",
            "priority": 0
        },
        {
            "iataCode": "BZU",
            "countryCode": "CD",
            "cityCode": "BZU",
            "cityName": "Buta",
            "name": "[BZU] - Buta - BZU - Buta - Democratic Republic of the Congo",
            "searchName": "BZU-Buta-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "FBM",
            "countryCode": "CD",
            "cityCode": "FBM",
            "cityName": "Lubumbashi",
            "name": "[FBM] - Luano - FBM - Lubumbashi - Democratic Republic of the Congo",
            "searchName": "FBM-Lubumbashi-Luano-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "BAN",
            "countryCode": "CD",
            "cityCode": "BAN",
            "cityName": "Basongo",
            "name": "[BAN] - Basongo - BAN - Basongo - Democratic Republic of the Congo",
            "searchName": "BAN-Basongo-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "BDT",
            "countryCode": "CD",
            "cityCode": "BDT",
            "cityName": "Gbadolite",
            "name": "[BDT] - Gbadolite - BDT - Gbadolite - Democratic Republic of the Congo",
            "searchName": "BDT-Gbadolite-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "BDV",
            "countryCode": "CD",
            "cityCode": "BDV",
            "cityName": "Moba",
            "name": "[BDV] - Moba - BDV - Moba - Democratic Republic of the Congo",
            "searchName": "BDV-Moba-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "BMB",
            "countryCode": "CD",
            "cityCode": "BMB",
            "cityName": "Bumba",
            "name": "[BMB] - Bumba - BMB - Bumba - Democratic Republic of the Congo",
            "searchName": "BMB-Bumba-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "BKY",
            "countryCode": "CD",
            "cityCode": "BKY",
            "cityName": "Bukavu",
            "name": "[BKY] - Kavumu - BKY - Bukavu - Democratic Republic of the Congo",
            "searchName": "BKY-Kamenbe-Kavumu-Bukavu-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "BUX",
            "countryCode": "CD",
            "cityCode": "BUX",
            "cityName": "Bunia",
            "name": "[BUX] - Bunia - BUX - Bunia - Democratic Republic of the Congo",
            "searchName": "BUX-Bunia-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "BSU",
            "countryCode": "CD",
            "cityCode": "BSU",
            "cityName": "Basankusu",
            "name": "[BSU] - Basankusu - BSU - Basankusu - Democratic Republic of the Congo",
            "searchName": "BSU-Basankusu-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "BOA",
            "countryCode": "CD",
            "cityCode": "BOA",
            "cityName": "Boma",
            "name": "[BOA] - Boma - BOA - Boma - Democratic Republic of the Congo",
            "searchName": "BOA-Boma-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "BNB",
            "countryCode": "CD",
            "cityCode": "BNB",
            "cityName": "Boende",
            "name": "[BNB] - Boende - BNB - Boende - Democratic Republic of the Congo",
            "searchName": "BNB-Boende-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "BNC",
            "countryCode": "CD",
            "cityCode": "BNC",
            "cityName": "Beni",
            "name": "[BNC] - Beni - BNC - Beni - Democratic Republic of the Congo",
            "searchName": "BNC-Beni-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "LBO",
            "countryCode": "CD",
            "cityCode": "LBO",
            "cityName": "Lusambo",
            "name": "[LBO] - Lusambo - LBO - Lusambo - Democratic Republic of the Congo",
            "searchName": "LBO-Lusambo-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KWZ",
            "countryCode": "CD",
            "cityCode": "KWZ",
            "cityName": "Kolwezi",
            "name": "[KWZ] - Kolwezi - KWZ - Kolwezi - Democratic Republic of the Congo",
            "searchName": "KWZ-Kolwezi-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "LIQ",
            "countryCode": "CD",
            "cityCode": "LIQ",
            "cityName": "Lisala",
            "name": "[LIQ] - Lisala - LIQ - Lisala - Democratic Republic of the Congo",
            "searchName": "LIQ-Lisala-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "LIE",
            "countryCode": "CD",
            "cityCode": "LIE",
            "cityName": "Libenge",
            "name": "[LIE] - Libenge - LIE - Libenge - Democratic Republic of the Congo",
            "searchName": "LIE-Libenge-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "LUS",
            "countryCode": "CD",
            "cityCode": "LUS",
            "cityName": "Lusanga",
            "name": "[LUS] - Lusanga - LUS - Lusanga - Democratic Republic of the Congo",
            "searchName": "LUS-Lusanga-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "LZA",
            "countryCode": "CD",
            "cityCode": "LZA",
            "cityName": "Luiza",
            "name": "[LZA] - Luiza - LZA - Luiza - Democratic Republic of the Congo",
            "searchName": "LZA-Luiza-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "LZI",
            "countryCode": "CD",
            "cityCode": "LZI",
            "cityName": "Luozi",
            "name": "[LZI] - Luozi - LZI - Luozi - Democratic Republic of the Congo",
            "searchName": "LZI-Luozi-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "LJA",
            "countryCode": "CD",
            "cityCode": "LJA",
            "cityName": "Lodja",
            "name": "[LJA] - Lodja - LJA - Lodja - Democratic Republic of the Congo",
            "searchName": "LJA-Lodja-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KEC",
            "countryCode": "CD",
            "cityCode": "KEC",
            "cityName": "Kasenga",
            "name": "[KEC] - Kasenga - KEC - Kasenga - Democratic Republic of the Congo",
            "searchName": "KEC-Kasenga-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KAP",
            "countryCode": "CD",
            "cityCode": "KAP",
            "cityName": "Kapanga",
            "name": "[KAP] - Kapanga - KAP - Kapanga - Democratic Republic of the Congo",
            "searchName": "KAP-Kapanga-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KBN",
            "countryCode": "CD",
            "cityCode": "KBN",
            "cityName": "Kabinda",
            "name": "[KBN] - Kabinda - KBN - Kabinda - Democratic Republic of the Congo",
            "searchName": "KBN-Kabinda-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KBO",
            "countryCode": "CD",
            "cityCode": "KBO",
            "cityName": "Kabalo",
            "name": "[KBO] - Kabalo - KBO - Kabalo - Democratic Republic of the Congo",
            "searchName": "KBO-Kabalo-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KNM",
            "countryCode": "CD",
            "cityCode": "KNM",
            "cityName": "Kaniama",
            "name": "[KNM] - Kaniama - KNM - Kaniama - Democratic Republic of the Congo",
            "searchName": "KNM-Kaniama-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KND",
            "countryCode": "CD",
            "cityCode": "KND",
            "cityName": "Kindu",
            "name": "[KND] - Kindu - KND - Kindu - Democratic Republic of the Congo",
            "searchName": "KND-Kindu-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KOO",
            "countryCode": "CD",
            "cityCode": "KOO",
            "cityName": "Kongolo",
            "name": "[KOO] - Kongolo - KOO - Kongolo - Democratic Republic of the Congo",
            "searchName": "KOO-Kongolo-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KRZ",
            "countryCode": "CD",
            "cityCode": "KRZ",
            "cityName": "Kiri",
            "name": "[KRZ] - Kiri - KRZ - Kiri - Democratic Republic of the Congo",
            "searchName": "KRZ-Kiri-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KIL",
            "countryCode": "CD",
            "cityCode": "KIL",
            "cityName": "Kilwa",
            "name": "[KIL] - Kilwa - KIL - Kilwa - Democratic Republic of the Congo",
            "searchName": "KIL-Kilwa-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KGN",
            "countryCode": "CD",
            "cityCode": "KGN",
            "cityName": "Kasongo Lunda",
            "name": "[KGN] - Kasongo Lunda - KGN - Kasongo Lunda - Democratic Republic of the Congo",
            "searchName": "KGN-Kasongo Lunda-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KGA",
            "countryCode": "CD",
            "cityCode": "KGA",
            "cityName": "Kananga",
            "name": "[KGA] - Kananga - KGA - Kananga - Democratic Republic of the Congo",
            "searchName": "KGA-Kananga-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KKW",
            "countryCode": "CD",
            "cityCode": "KKW",
            "cityName": "Kikwit",
            "name": "[KKW] - Kikwit - KKW - Kikwit - Democratic Republic of the Congo",
            "searchName": "KKW-Kikwit-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KLI",
            "countryCode": "CD",
            "cityCode": "KLI",
            "cityName": "Kota Koli",
            "name": "[KLI] - Kota Koli - KLI - Kota Koli - Democratic Republic of the Congo",
            "searchName": "KLI-Kota Koli-Kotakoli-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KMN",
            "countryCode": "CD",
            "cityCode": "KMN",
            "cityName": "Kamina",
            "name": "[KMN] - Kamina - KMN - Kamina - Democratic Republic of the Congo",
            "searchName": "KMN-Kamina-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "KLY",
            "countryCode": "CD",
            "cityCode": "KLY",
            "cityName": "Kalima",
            "name": "[KLY] - Kalima - KLY - Kalima - Democratic Republic of the Congo",
            "searchName": "KLY-Kalima-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "GMA",
            "countryCode": "CD",
            "cityCode": "GMA",
            "cityName": "Gemena",
            "name": "[GMA] - Gemena - GMA - Gemena - Democratic Republic of the Congo",
            "searchName": "GMA-Gemena-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "GOM",
            "countryCode": "CD",
            "cityCode": "GOM",
            "cityName": "Goma",
            "name": "[GOM] - Goma - GOM - Goma - Democratic Republic of the Congo",
            "searchName": "GOM-Goma-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "GDJ",
            "countryCode": "CD",
            "cityCode": "GDJ",
            "cityName": "Gandajika",
            "name": "[GDJ] - Gandajika - GDJ - Gandajika - Democratic Republic of the Congo",
            "searchName": "GDJ-Gandajika-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "FMI",
            "countryCode": "CD",
            "cityCode": "FMI",
            "cityName": "Kalemie",
            "name": "[FMI] - Kalemie - FMI - Kalemie - Democratic Republic of the Congo",
            "searchName": "FMI-Kalemie-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "FKI",
            "countryCode": "CD",
            "cityCode": "FKI",
            "cityName": "Kisangani",
            "name": "[FKI] - Kisangani - FKI - Kisangani - Democratic Republic of the Congo",
            "searchName": "FKI-Kisangani-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "FDU",
            "countryCode": "CD",
            "cityCode": "FDU",
            "cityName": "Bandundu",
            "name": "[FDU] - Bandundu - FDU - Bandundu - Democratic Republic of the Congo",
            "searchName": "FDU-Bandundu-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "FIH",
            "countryCode": "CD",
            "cityCode": "FIH",
            "cityName": "Kinshasa",
            "name": "[FIH] - NÂ’djili International - FIH - Kinshasa - Democratic Republic of the Congo",
            "searchName": "FIH-Kinshasa N Djili-N Djili-NÂ’djili International-Kinshasa-Kinsasa-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "NLO",
            "countryCode": "CD",
            "cityCode": "FIH",
            "cityName": "Kinshasa",
            "name": "[NLO] - Ndolo - FIH - Kinshasa - Democratic Republic of the Congo",
            "searchName": "NLO-FIH-N dolo-N Dolo Havalimani-Ndolo-Kinshasa-Kinsasa-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "INO",
            "countryCode": "CD",
            "cityCode": "INO",
            "cityName": "Inongo",
            "name": "[INO] - Inongo - INO - Inongo - Democratic Republic of the Congo",
            "searchName": "INO-Inongo-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "IRP",
            "countryCode": "CD",
            "cityCode": "IRP",
            "cityName": "Isiro",
            "name": "[IRP] - Matari - IRP - Isiro - Democratic Republic of the Congo",
            "searchName": "IRP-Matari-Isiro-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "IDF",
            "countryCode": "CD",
            "cityCode": "IDF",
            "cityName": "Idiofa",
            "name": "[IDF] - Idiofa - IDF - Idiofa - Democratic Republic of the Congo",
            "searchName": "IDF-Idiofa-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "IKL",
            "countryCode": "CD",
            "cityCode": "IKL",
            "cityName": "Ikela",
            "name": "[IKL] - Ikela - IKL - Ikela - Democratic Republic of the Congo",
            "searchName": "IKL-Ikela-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "NKL",
            "countryCode": "CD",
            "cityCode": "NKL",
            "cityName": "Nkolo",
            "name": "[NKL] - Nkolo - NKL - Nkolo - Democratic Republic of the Congo",
            "searchName": "NKL-Nkolo-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "NIO",
            "countryCode": "CD",
            "cityCode": "NIO",
            "cityName": "Nioki",
            "name": "[NIO] - Nioki - NIO - Nioki - Democratic Republic of the Congo",
            "searchName": "NIO-Nioki-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "MSM",
            "countryCode": "CD",
            "cityCode": "MSM",
            "cityName": "Masi Manimba",
            "name": "[MSM] - Masi Manimba - MSM - Masi Manimba - Democratic Republic of the Congo",
            "searchName": "MSM-Masi Manimba-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "MNO",
            "countryCode": "CD",
            "cityCode": "MNO",
            "cityName": "Manono",
            "name": "[MNO] - Manono - MNO - Manono - Democratic Republic of the Congo",
            "searchName": "MNO-Manono-Manono Havalimani-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "MNB",
            "countryCode": "CD",
            "cityCode": "MNB",
            "cityName": "Moanda",
            "name": "[MNB] - Moanda - MNB - Moanda - Democratic Republic of the Congo",
            "searchName": "MNB-Moanda-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "MDK",
            "countryCode": "CD",
            "cityCode": "MDK",
            "cityName": "Mbandaka",
            "name": "[MDK] - Mbandaka - MDK - Mbandaka - Democratic Republic of the Congo",
            "searchName": "MDK-Mbandaka-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "MEW",
            "countryCode": "CD",
            "cityCode": "MEW",
            "cityName": "Mweka",
            "name": "[MEW] - Mweka - MEW - Mweka - Democratic Republic of the Congo",
            "searchName": "MEW-Mweka-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "MAT",
            "countryCode": "CD",
            "cityCode": "MAT",
            "cityName": "Matadi",
            "name": "[MAT] - Matadi - MAT - Matadi - Democratic Republic of the Congo",
            "searchName": "MAT-Matadi-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "MJM",
            "countryCode": "CD",
            "cityCode": "MJM",
            "cityName": "Mbuji-Mayi",
            "name": "[MJM] - Mbuji-Mayi - MJM - Mbuji-Mayi - Democratic Republic of the Congo",
            "searchName": "MJM-Mbuji-Mayi-Mbujimayi-Mbuji Mayi-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "PWO",
            "countryCode": "CD",
            "cityCode": "PWO",
            "cityName": "Pweto",
            "name": "[PWO] - Pweto - PWO - Pweto - Democratic Republic of the Congo",
            "searchName": "PWO-Pweto-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "PUN",
            "countryCode": "CD",
            "cityCode": "PUN",
            "cityName": "Punia",
            "name": "[PUN] - Punia - PUN - Punia - Democratic Republic of the Congo",
            "searchName": "PUN-Punia-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "RUE",
            "countryCode": "CD",
            "cityCode": "RUE",
            "cityName": "Butembo",
            "name": "[RUE] - Butembo - RUE - Butembo - Democratic Republic of the Congo",
            "searchName": "RUE-Butembo-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "PFR",
            "countryCode": "CD",
            "cityCode": "PFR",
            "cityName": "Ilebo",
            "name": "[PFR] - Ilebo - PFR - Ilebo - Democratic Republic of the Congo",
            "searchName": "PFR-Ilebo-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "YAN",
            "countryCode": "CD",
            "cityCode": "YAN",
            "cityName": "Yangambi",
            "name": "[YAN] - Yangambi - YAN - Yangambi - Democratic Republic of the Congo",
            "searchName": "YAN-Yangambi-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "TSH",
            "countryCode": "CD",
            "cityCode": "TSH",
            "cityName": "Tshikapa",
            "name": "[TSH] - Tshikapa - TSH - Tshikapa - Democratic Republic of the Congo",
            "searchName": "TSH-Tshikapa-Kongo Demokratische Republik-Democratic Republic of the Congo-Demokratik Kongo Cumhuriyeti-Congo (RDC)-República Democrática del Congo-Congo - RDC",
            "priority": 0
        },
        {
            "iataCode": "RFA",
            "countryCode": "CF",
            "cityCode": "RFA",
            "cityName": "Rafai",
            "name": "[RFA] - Rafai - RFA - Rafai - Central African Republic",
            "searchName": "RFA-Rafai-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "MKI",
            "countryCode": "CF",
            "cityCode": "MKI",
            "cityName": "Obo",
            "name": "[MKI] - Mboki - MKI - Obo - Central African Republic",
            "searchName": "MKI-Mboki-Obo-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "NDL",
            "countryCode": "CF",
            "cityCode": "NDL",
            "cityName": "Ndele",
            "name": "[NDL] - Ndele - NDL - Ndele - Central African Republic",
            "searchName": "NDL-Ndele-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "ODA",
            "countryCode": "CF",
            "cityCode": "ODA",
            "cityName": "Ouadda",
            "name": "[ODA] - Ouadda - ODA - Ouadda - Central African Republic",
            "searchName": "ODA-Ouadda-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "ODJ",
            "countryCode": "CF",
            "cityCode": "ODJ",
            "cityName": "Ouanda Djalle",
            "name": "[ODJ] - Ouanda Djalle - ODJ - Ouanda Djalle - Central African Republic",
            "searchName": "ODJ-Ouanda Djalle-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "IMO",
            "countryCode": "CF",
            "cityCode": "IMO",
            "cityName": "Zemio",
            "name": "[IMO] - Zemio - IMO - Zemio - Central African Republic",
            "searchName": "IMO-Zemio-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "IRO",
            "countryCode": "CF",
            "cityCode": "IRO",
            "cityName": "Birao",
            "name": "[IRO] - Birao - IRO - Birao - Central African Republic",
            "searchName": "IRO-Birao-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "GDA",
            "countryCode": "CF",
            "cityCode": "GDA",
            "cityName": "Gounda",
            "name": "[GDA] - Gounda - GDA - Gounda - Central African Republic",
            "searchName": "GDA-Gounda-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "GDI",
            "countryCode": "CF",
            "cityCode": "GDI",
            "cityName": "Gordil",
            "name": "[GDI] - Gordil - GDI - Gordil - Central African Republic",
            "searchName": "GDI-Gordil-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "KWD",
            "countryCode": "CF",
            "cityCode": "KWD",
            "cityName": "Kawadjia",
            "name": "[KWD] - Kawadjia - KWD - Kawadjia - Central African Republic",
            "searchName": "KWD-Kawadjia-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BOZ",
            "countryCode": "CF",
            "cityCode": "BOZ",
            "cityName": "Bozoum",
            "name": "[BOZ] - Bozoum - BOZ - Bozoum - Central African Republic",
            "searchName": "BOZ-Bozoum-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BOP",
            "countryCode": "CF",
            "cityCode": "BOP",
            "cityName": "Bouar",
            "name": "[BOP] - Bouar - BOP - Bouar - Central African Republic",
            "searchName": "BOP-Bouar-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BTG",
            "countryCode": "CF",
            "cityCode": "BTG",
            "cityName": "Batangafo",
            "name": "[BTG] - Batangafo - BTG - Batangafo - Central African Republic",
            "searchName": "BTG-Batangafo-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BSN",
            "countryCode": "CF",
            "cityCode": "BSN",
            "cityName": "Bossangoa",
            "name": "[BSN] - Bossangoa - BSN - Bossangoa - Central African Republic",
            "searchName": "BSN-Bossangoa-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BMF",
            "countryCode": "CF",
            "cityCode": "BMF",
            "cityName": "Bakouma",
            "name": "[BMF] - Bakouma - BMF - Bakouma - Central African Republic",
            "searchName": "BMF-Bakouma-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BGU",
            "countryCode": "CF",
            "cityCode": "BGU",
            "cityName": "Bangassou",
            "name": "[BGU] - Bangassou - BGU - Bangassou - Central African Republic",
            "searchName": "BGU-Bangassou-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BIV",
            "countryCode": "CF",
            "cityCode": "BIV",
            "cityName": "Bria",
            "name": "[BIV] - Bria - BIV - Bria - Central African Republic",
            "searchName": "BIV-Bria-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BBT",
            "countryCode": "CF",
            "cityCode": "BBT",
            "cityName": "Berberati",
            "name": "[BBT] - Berberati - BBT - Berberati - Central African Republic",
            "searchName": "BBT-Berberati-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BBY",
            "countryCode": "CF",
            "cityCode": "BBY",
            "cityName": "Bambari",
            "name": "[BBY] - Bambari - BBY - Bambari - Central African Republic",
            "searchName": "BBY-Bambari-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BCF",
            "countryCode": "CF",
            "cityCode": "BCF",
            "cityName": "Bouca",
            "name": "[BCF] - Bouca - BCF - Bouca - Central African Republic",
            "searchName": "BCF-Bouca-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BGF",
            "countryCode": "CF",
            "cityCode": "BGF",
            "cityName": "Bangui",
            "name": "[BGF] - Bangui - BGF - Bangui - Central African Republic",
            "searchName": "BGF-Bangui-Banqui-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "AIG",
            "countryCode": "CF",
            "cityCode": "AIG",
            "cityName": "Yalinga",
            "name": "[AIG] - Yalinga - AIG - Yalinga - Central African Republic",
            "searchName": "AIG-Yalinga-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "CRF",
            "countryCode": "CF",
            "cityCode": "CRF",
            "cityName": "Carnot",
            "name": "[CRF] - Carnot - CRF - Carnot - Central African Republic",
            "searchName": "CRF-Carnot-Central African Republic-Zentralafrikanische Republik-República Centroafricana-République centrafricaine-Repubblica Centrafricana",
            "priority": 0
        },
        {
            "iataCode": "BZV",
            "countryCode": "CG",
            "cityCode": "BZV",
            "cityName": "Brazzaville",
            "name": "[BZV] - Maya Maya - BZV - Brazzaville - Republic of the Congo",
            "searchName": "BZV-Brazzaville-Maya Maya-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "EWO",
            "countryCode": "CG",
            "cityCode": "EWO",
            "cityName": "Ewo",
            "name": "[EWO] - Ewo - EWO - Ewo - Republic of the Congo",
            "searchName": "EWO-Ewo-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "EPN",
            "countryCode": "CG",
            "cityCode": "EPN",
            "cityName": "Epena",
            "name": "[EPN] - Epena - EPN - Epena - Republic of the Congo",
            "searchName": "EPN-Epena-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "DIS",
            "countryCode": "CG",
            "cityCode": "DIS",
            "cityName": "Loubomo",
            "name": "[DIS] - Loubomo - DIS - Loubomo - Republic of the Congo",
            "searchName": "DIS-Loubomo-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "DJM",
            "countryCode": "CG",
            "cityCode": "DJM",
            "cityName": "Djambala",
            "name": "[DJM] - Djambala - DJM - Djambala - Republic of the Congo",
            "searchName": "DJM-Djambala-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "ANJ",
            "countryCode": "CG",
            "cityCode": "ANJ",
            "cityName": "Zanaga",
            "name": "[ANJ] - Zanaga - ANJ - Zanaga - Republic of the Congo",
            "searchName": "ANJ-Zanaga-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "BTB",
            "countryCode": "CG",
            "cityCode": "BTB",
            "cityName": "Betou",
            "name": "[BTB] - Betou - BTB - Betou - Republic of the Congo",
            "searchName": "BTB-Betou-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "BOE",
            "countryCode": "CG",
            "cityCode": "BOE",
            "cityName": "Boundji",
            "name": "[BOE] - Boundji - BOE - Boundji - Republic of the Congo",
            "searchName": "BOE-Boundji-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "LCO",
            "countryCode": "CG",
            "cityCode": "LCO",
            "cityName": "Lague",
            "name": "[LCO] - Lague - LCO - Lague - Republic of the Congo",
            "searchName": "LCO-Lague-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "LKC",
            "countryCode": "CG",
            "cityCode": "LKC",
            "cityName": "Lekana",
            "name": "[LKC] - Lekana - LKC - Lekana - Republic of the Congo",
            "searchName": "LKC-Lekana-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "KMK",
            "countryCode": "CG",
            "cityCode": "KMK",
            "cityName": "Makabana",
            "name": "[KMK] - Makabana - KMK - Makabana - Republic of the Congo",
            "searchName": "KMK-Makabana-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "KNJ",
            "countryCode": "CG",
            "cityCode": "KNJ",
            "cityName": "Kindamba",
            "name": "[KNJ] - Kindamba - KNJ - Kindamba - Republic of the Congo",
            "searchName": "KNJ-Kindamba-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "KEE",
            "countryCode": "CG",
            "cityCode": "KEE",
            "cityName": "Kelle",
            "name": "[KEE] - Kelle - KEE - Kelle - Republic of the Congo",
            "searchName": "KEE-Kelle-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "FTX",
            "countryCode": "CG",
            "cityCode": "FTX",
            "cityName": "Owando",
            "name": "[FTX] - Owando - FTX - Owando - Republic of the Congo",
            "searchName": "FTX-Owando-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "GMM",
            "countryCode": "CG",
            "cityCode": "GMM",
            "cityName": "Gamboma",
            "name": "[GMM] - Gamboma - GMM - Gamboma - Republic of the Congo",
            "searchName": "GMM-Gamboma-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "ION",
            "countryCode": "CG",
            "cityCode": "ION",
            "cityName": "Impfondo",
            "name": "[ION] - Impfondo - ION - Impfondo - Republic of the Congo",
            "searchName": "ION-Impfondo-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "OKG",
            "countryCode": "CG",
            "cityCode": "OKG",
            "cityName": "Okoyo",
            "name": "[OKG] - Okoyo - OKG - Okoyo - Republic of the Congo",
            "searchName": "OKG-Okoyo-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "NKY",
            "countryCode": "CG",
            "cityCode": "NKY",
            "cityName": "Nkayi",
            "name": "[NKY] - Nkayi - NKY - Nkayi - Republic of the Congo",
            "searchName": "NKY-Nkayi-Yokangassi Airport-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "MKJ",
            "countryCode": "CG",
            "cityCode": "MKJ",
            "cityName": "Makoua",
            "name": "[MKJ] - Makoua - MKJ - Makoua - Republic of the Congo",
            "searchName": "MKJ-Makoua-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "MUY",
            "countryCode": "CG",
            "cityCode": "MUY",
            "cityName": "Mouyondzi",
            "name": "[MUY] - Mouyondzi - MUY - Mouyondzi - Republic of the Congo",
            "searchName": "MUY-Mouyondzi-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "MSX",
            "countryCode": "CG",
            "cityCode": "MSX",
            "cityName": "Mossendjo",
            "name": "[MSX] - Mossendjo - MSX - Mossendjo - Republic of the Congo",
            "searchName": "MSX-Mossendjo-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "PNR",
            "countryCode": "CG",
            "cityCode": "PNR",
            "cityName": "Pointe-Noire",
            "name": "[PNR] - Pointe-Noire - PNR - Pointe-Noire - Republic of the Congo",
            "searchName": "PNR-Pointe-Noire-Pointe Noire-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "OUE",
            "countryCode": "CG",
            "cityCode": "OUE",
            "cityName": "Ouesso",
            "name": "[OUE] - Ouesso - OUE - Ouesso - Republic of the Congo",
            "searchName": "OUE-Ouesso-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "OLL",
            "countryCode": "CG",
            "cityCode": "OLL",
            "cityName": "Oyo",
            "name": "[OLL] - Ollombo - OLL - Oyo - Republic of the Congo",
            "searchName": "OLL-Ollombo-Oyo-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "SOE",
            "countryCode": "CG",
            "cityCode": "SOE",
            "cityName": "Souanke",
            "name": "[SOE] - Souanke - SOE - Souanke - Republic of the Congo",
            "searchName": "SOE-Souanke-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "SIB",
            "countryCode": "CG",
            "cityCode": "SIB",
            "cityName": "Sibiti",
            "name": "[SIB] - Sibiti - SIB - Sibiti - Republic of the Congo",
            "searchName": "SIB-Sibiti-Republic of the Congo-Kongo-Kongo Cumhuriyeti-República del Congo-République du Congo-Repubblica del Congo",
            "priority": 0
        },
        {
            "iataCode": "SIR",
            "countryCode": "CH",
            "cityCode": "SIR",
            "cityName": "Sion",
            "name": "[SIR] - Sion - SIR - Sion - Switzerland",
            "searchName": "SIR-Sion-Sion Havalimani-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "SMV",
            "countryCode": "CH",
            "cityCode": "SMV",
            "cityName": "St Moritz",
            "name": "[SMV] - Samedan - SMV - St Moritz - Switzerland",
            "searchName": "SMV-Samedan-Samedan Havalimani-St Moritz-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKH",
            "countryCode": "CH",
            "cityCode": "SMV",
            "cityName": "St Moritz",
            "name": "[ZKH] - St Moritz Railway Station - SMV - St Moritz - Switzerland",
            "searchName": "ZKH-SMV-St Moritz Railway Station-St Moritz-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "QEQ",
            "countryCode": "CH",
            "cityCode": "QEQ",
            "cityName": "Embrach/Rorbas",
            "name": "[QEQ] - Embrach/Rorbas Railway Station - QEQ - Embrach/Rorbas - Switzerland",
            "searchName": "QEQ-Embrach/Rorbas Railway Station-Embrach/Rorbas-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "QGL",
            "countryCode": "CH",
            "cityCode": "QGL",
            "cityName": "St Gallen",
            "name": "[QGL] - St Gallen Railway Station - QGL - St Gallen - Switzerland",
            "searchName": "QGL-St Gallen Railway Station-St Gallen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "QLJ",
            "countryCode": "CH",
            "cityCode": "QLJ",
            "cityName": "Lucerne",
            "name": "[QLJ] - Lucerne Railway Station - QLJ - Lucerne - Switzerland",
            "searchName": "QLJ-Lucerne Railway Station-Lucerne-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "QLS",
            "countryCode": "CH",
            "cityCode": "QLS",
            "cityName": "Lausanne",
            "name": "[QLS] - Lausanne - QLS - Lausanne - Switzerland",
            "searchName": "QLS-Lausanne-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "QZB",
            "countryCode": "CH",
            "cityCode": "QZB",
            "cityName": "Zermatt",
            "name": "[QZB] - Zermatt Railway Station - QZB - Zermatt - Switzerland",
            "searchName": "QZB-Zermatt Railway Station-Zermatt-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "MLH",
            "countryCode": "CH",
            "cityCode": "EAP",
            "cityName": "Mulhouse",
            "name": "[MLH] - Euroairport France - EAP - Mulhouse - Switzerland",
            "searchName": "MLH-EAP-Mulhouse-Euroairport France-Mulhouse EuroAirport-Basel/Mulhouse-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDH",
            "countryCode": "CH",
            "cityCode": "EAP",
            "cityName": "Mulhouse",
            "name": "[ZDH] - Basel SBB station - EAP - Mulhouse - Switzerland",
            "searchName": "ZDH-EAP-Basel SBB station-SBB Railway Service-Basel/Mulhouse-Mulhouse-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZBA",
            "countryCode": "CH",
            "cityCode": "EAP",
            "cityName": "Mulhouse",
            "name": "[ZBA] - Basel Bad Railway Station - EAP - Mulhouse - Switzerland",
            "searchName": "ZBA-EAP-Basel Bad Railway Station-Basel/Mulhouse-Mulhouse-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "EML",
            "countryCode": "CH",
            "cityCode": "EML",
            "cityName": "Luzern",
            "name": "[EML] - Emmen - EML - Luzern - Switzerland",
            "searchName": "EML-Emmen-Luzern-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZAP",
            "countryCode": "CH",
            "cityCode": "ZAP",
            "cityName": "Appenzell",
            "name": "[ZAP] - Appenzell Railway Station - ZAP - Appenzell - Switzerland",
            "searchName": "ZAP-Appenzell Railway Station-Appenzell-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDA",
            "countryCode": "CH",
            "cityCode": "ZDA",
            "cityName": "Aarau",
            "name": "[ZDA] - Aarau Railway Station - ZDA - Aarau - Switzerland",
            "searchName": "ZDA-Aarau Railway Station-Aarau-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDB",
            "countryCode": "CH",
            "cityCode": "ZDB",
            "cityName": "Adelboden",
            "name": "[ZDB] - Adelboden Bus Station - ZDB - Adelboden - Switzerland",
            "searchName": "ZDB-Adelboden Bus Station-Adelboden-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDC",
            "countryCode": "CH",
            "cityCode": "ZDC",
            "cityName": "Aigle",
            "name": "[ZDC] - Aigle Railway Station - ZDC - Aigle - Switzerland",
            "searchName": "ZDC-Aigle Railway Station-Aigle-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDD",
            "countryCode": "CH",
            "cityCode": "ZDD",
            "cityName": "Arbon",
            "name": "[ZDD] - Arbon Railway Station - ZDD - Arbon - Switzerland",
            "searchName": "ZDD-Arbon Railway Station-Arbon-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDE",
            "countryCode": "CH",
            "cityCode": "ZDE",
            "cityName": "Arosa",
            "name": "[ZDE] - Arosa Railway Station - ZDE - Arosa - Switzerland",
            "searchName": "ZDE-Arosa Railway Station-Arosa-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDG",
            "countryCode": "CH",
            "cityCode": "ZDG",
            "cityName": "Baden",
            "name": "[ZDG] - Baden Railway Station - ZDG - Baden - Switzerland",
            "searchName": "ZDG-Baden Railway Station-Baden-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDI",
            "countryCode": "CH",
            "cityCode": "ZDI",
            "cityName": "Bellinzona",
            "name": "[ZDI] - Bellinzona Railway Station - ZDI - Bellinzona - Switzerland",
            "searchName": "ZDI-Bellinzona Railway Station-Bellinzona-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDK",
            "countryCode": "CH",
            "cityCode": "ZDK",
            "cityName": "Biel/Bienne",
            "name": "[ZDK] - Biel/Bienne Railway Station - ZDK - Biel/Bienne - Switzerland",
            "searchName": "ZDK-Biel/Bienne Railway Station-Biel/Bienne-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDL",
            "countryCode": "CH",
            "cityCode": "ZDL",
            "cityName": "Brig",
            "name": "[ZDL] - Brig Railway Station - ZDL - Brig - Switzerland",
            "searchName": "ZDL-Brig Railway Station-Brig-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDO",
            "countryCode": "CH",
            "cityCode": "ZDO",
            "cityName": "Buchs SG",
            "name": "[ZDO] - Buchs SG Railway Station - ZDO - Buchs SG - Switzerland",
            "searchName": "ZDO-Buchs SG Railway Station-Buchs SG-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDP",
            "countryCode": "CH",
            "cityCode": "ZDP",
            "cityName": "Burgdorf",
            "name": "[ZDP] - Burgdorf Railway Station - ZDP - Burgdorf - Switzerland",
            "searchName": "ZDP-Burgdorf Railway Station-Burgdorf-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDQ",
            "countryCode": "CH",
            "cityCode": "ZDQ",
            "cityName": "Champery",
            "name": "[ZDQ] - Champery Railway Station - ZDQ - Champery - Switzerland",
            "searchName": "ZDQ-Champery Railway Station-Champery-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDR",
            "countryCode": "CH",
            "cityCode": "ZDR",
            "cityName": "Chateau-d Oex",
            "name": "[ZDR] - Chateau-dOex Railway Station - ZDR - Chateau-d Oex - Switzerland",
            "searchName": "ZDR-Chateau-d Oex Railway Station-dOex Railway Station-d Oex-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDS",
            "countryCode": "CH",
            "cityCode": "ZDS",
            "cityName": "Chiasso",
            "name": "[ZDS] - Chiasso Railway Station - ZDS - Chiasso - Switzerland",
            "searchName": "ZDS-Chiasso Railway Station-Chiasso-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDT",
            "countryCode": "CH",
            "cityCode": "ZDT",
            "cityName": "Chur",
            "name": "[ZDT] - Chur Railway Station - ZDT - Chur - Switzerland",
            "searchName": "ZDT-Chur Railway Station-Chur-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDV",
            "countryCode": "CH",
            "cityCode": "ZDV",
            "cityName": "Davos",
            "name": "[ZDV] - Davos Dorf Railway Station - ZDV - Davos - Switzerland",
            "searchName": "ZDV-Davos Dorf Railway Station-Davos-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDW",
            "countryCode": "CH",
            "cityCode": "ZDW",
            "cityName": "Delemont",
            "name": "[ZDW] - Delemont Railway Station - ZDW - Delemont - Switzerland",
            "searchName": "ZDW-Delemont Railway Station-Delemont-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDX",
            "countryCode": "CH",
            "cityCode": "ZDX",
            "cityName": "Dietikon",
            "name": "[ZDX] - Dietikon Railway Station - ZDX - Dietikon - Switzerland",
            "searchName": "ZDX-Dietikon Railway Station-Dietikon-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZDZ",
            "countryCode": "CH",
            "cityCode": "ZDZ",
            "cityName": "Einsiedeln",
            "name": "[ZDZ] - Einsiedeln Railway Station - ZDZ - Einsiedeln - Switzerland",
            "searchName": "ZDZ-Einsiedeln Railway Station-Einsiedeln-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHB",
            "countryCode": "CH",
            "cityCode": "ZHB",
            "cityName": "Engelberg",
            "name": "[ZHB] - Engelberg Railway Station - ZHB - Engelberg - Switzerland",
            "searchName": "ZHB-Engelberg Railway Station-Engelberg-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHD",
            "countryCode": "CH",
            "cityCode": "ZHD",
            "cityName": "Fluelen",
            "name": "[ZHD] - Fluelen Railway Station - ZHD - Fluelen - Switzerland",
            "searchName": "ZHD-Fluelen Railway Station-Fluelen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHE",
            "countryCode": "CH",
            "cityCode": "ZHE",
            "cityName": "Frauenfeld",
            "name": "[ZHE] - Frauenfeld Railway Station - ZHE - Frauenfeld - Switzerland",
            "searchName": "ZHE-Frauenfeld Railway Station-Frauenfeld-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHF",
            "countryCode": "CH",
            "cityCode": "ZHF",
            "cityName": "Fribourg",
            "name": "[ZHF] - Fribourg Railway Station - ZHF - Fribourg - Switzerland",
            "searchName": "ZHF-Fribourg Railway Station-Fribourg-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHG",
            "countryCode": "CH",
            "cityCode": "ZHG",
            "cityName": "Glarus",
            "name": "[ZHG] - Glarus Railway Station - ZHG - Glarus - Switzerland",
            "searchName": "ZHG-Glarus Railway Station-Glarus-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHH",
            "countryCode": "CH",
            "cityCode": "ZHH",
            "cityName": "Gossau SG",
            "name": "[ZHH] - Gossau SG Railway Station - ZHH - Gossau SG - Switzerland",
            "searchName": "ZHH-Gossau SG Railway Station-Gossau SG-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHI",
            "countryCode": "CH",
            "cityCode": "ZHI",
            "cityName": "Grenchen",
            "name": "[ZHI] - Grenchen - ZHI - Grenchen - Switzerland",
            "searchName": "ZHI-Grenchen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHJ",
            "countryCode": "CH",
            "cityCode": "ZHJ",
            "cityName": "Grindelwald",
            "name": "[ZHJ] - Grindelwald Railway Station - ZHJ - Grindelwald - Switzerland",
            "searchName": "ZHJ-Grindelwald Railway Station-Grindelwald-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHK",
            "countryCode": "CH",
            "cityCode": "ZHK",
            "cityName": "Gstaad",
            "name": "[ZHK] - Gstaad Railway Station - ZHK - Gstaad - Switzerland",
            "searchName": "ZHK-Gstaad Railway Station-Gstaad-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHL",
            "countryCode": "CH",
            "cityCode": "ZHL",
            "cityName": "Heerbrugg",
            "name": "[ZHL] - Heerbrugg Railway Station - ZHL - Heerbrugg - Switzerland",
            "searchName": "ZHL-Heerbrugg Railway Station-Heerbrugg-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHR",
            "countryCode": "CH",
            "cityCode": "ZHR",
            "cityName": "Kandersteg",
            "name": "[ZHR] - Kandersteg Railway Station - ZHR - Kandersteg - Switzerland",
            "searchName": "ZHR-Kandersteg Railway Station-Kandersteg-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHS",
            "countryCode": "CH",
            "cityCode": "ZHS",
            "cityName": "Klosters",
            "name": "[ZHS] - Klosters Railway Station - ZHS - Klosters - Switzerland",
            "searchName": "ZHS-Klosters Railway Station-Klosters-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHU",
            "countryCode": "CH",
            "cityCode": "ZHU",
            "cityName": "Kreuzlingen",
            "name": "[ZHU] - Kreuzlingen Railway Station - ZHU - Kreuzlingen - Switzerland",
            "searchName": "ZHU-Kreuzlingen Railway Station-Kreuzlingen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHV",
            "countryCode": "CH",
            "cityCode": "ZHV",
            "cityName": "La Chaux-de-Fonds",
            "name": "[ZHV] - La Chaux-de-Fonds Railway Station - ZHV - La Chaux-de-Fonds - Switzerland",
            "searchName": "ZHV-La Chaux-de-Fonds Railway Station-Fonds-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHW",
            "countryCode": "CH",
            "cityCode": "ZHW",
            "cityName": "Langenthal",
            "name": "[ZHW] - Langenthal Railway Station - ZHW - Langenthal - Switzerland",
            "searchName": "ZHW-Langenthal Railway Station-Langenthal-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZHN",
            "countryCode": "CH",
            "cityCode": "ZHN",
            "cityName": "Herzogenbuchsee",
            "name": "[ZHN] - Herzogenbuchsee Railway Station - ZHN - Herzogenbuchsee - Switzerland",
            "searchName": "ZHN-Herzogenbuchsee Railway Station-Herzogenbuchsee-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKI",
            "countryCode": "CH",
            "cityCode": "ZKI",
            "cityName": "Saas-Fee",
            "name": "[ZKI] - Saas-Fee Bus Station - ZKI - Saas-Fee - Switzerland",
            "searchName": "ZKI-Saas-Fee Bus Station-Fee-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKJ",
            "countryCode": "CH",
            "cityCode": "ZKJ",
            "cityName": "Schaffhausen",
            "name": "[ZKJ] - Schaffhausen Railway Station - ZKJ - Schaffhausen - Switzerland",
            "searchName": "ZKJ-Schaffhausen Railway Station-Schaffhausen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKK",
            "countryCode": "CH",
            "cityCode": "ZKK",
            "cityName": "Schwyz",
            "name": "[ZKK] - Schwyz Railway Station - ZKK - Schwyz - Switzerland",
            "searchName": "ZKK-Schwyz Railway Station-Schwyz-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKF",
            "countryCode": "CH",
            "cityCode": "ZKF",
            "cityName": "St Margrethen",
            "name": "[ZKF] - St Margrethen Railway Station - ZKF - St Margrethen - Switzerland",
            "searchName": "ZKF-St Margrethen Railway Station-St Margrethen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJZ",
            "countryCode": "CH",
            "cityCode": "ZJZ",
            "cityName": "Rorschach",
            "name": "[ZJZ] - Rorschach Railway Station - ZJZ - Rorschach - Switzerland",
            "searchName": "ZJZ-Rorschach Railway Station-Rorschach-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKA",
            "countryCode": "CH",
            "cityCode": "ZKA",
            "cityName": "Sargans",
            "name": "[ZKA] - Sargans Railway Station - ZKA - Sargans - Switzerland",
            "searchName": "ZKA-Sargans Railway Station-Sargans-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJL",
            "countryCode": "CH",
            "cityCode": "ZJL",
            "cityName": "Lyss",
            "name": "[ZJL] - Lyss Railway Station - ZJL - Lyss - Switzerland",
            "searchName": "ZJL-Lyss Railway Station-Lyss-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJM",
            "countryCode": "CH",
            "cityCode": "ZJM",
            "cityName": "Martigny",
            "name": "[ZJM] - Martigny Railway Station - ZJM - Martigny - Switzerland",
            "searchName": "ZJM-Martigny Railway Station-Martigny-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJU",
            "countryCode": "CH",
            "cityCode": "ZJU",
            "cityName": "Olten",
            "name": "[ZJU] - Olten Railway Station - ZJU - Olten - Switzerland",
            "searchName": "ZJU-Olten Railway Station-Olten-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJV",
            "countryCode": "CH",
            "cityCode": "ZJV",
            "cityName": "Pontresina",
            "name": "[ZJV] - Pontresina Railway Station - ZJV - Pontresina - Switzerland",
            "searchName": "ZJV-Pontresina Railway Station-Pontresina-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJW",
            "countryCode": "CH",
            "cityCode": "ZJW",
            "cityName": "Rapperswil",
            "name": "[ZJW] - Rapperswil Railway Station - ZJW - Rapperswil - Switzerland",
            "searchName": "ZJW-Rapperswil Railway Station-Rapperswil-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJP",
            "countryCode": "CH",
            "cityCode": "ZJP",
            "cityName": "Montreux",
            "name": "[ZJP] - Montreux Railway Station - ZJP - Montreux - Switzerland",
            "searchName": "ZJP-Montreux Railway Station-Montreux-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJQ",
            "countryCode": "CH",
            "cityCode": "ZJQ",
            "cityName": "Morges",
            "name": "[ZJQ] - Morges Railway Station - ZJQ - Morges - Switzerland",
            "searchName": "ZJQ-Morges Railway Station-Morges-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJC",
            "countryCode": "CH",
            "cityCode": "ZJC",
            "cityName": "Lenzburg",
            "name": "[ZJC] - Lenzburg Railway Station - ZJC - Lenzburg - Switzerland",
            "searchName": "ZJC-Lenzburg Railway Station-Lenzburg-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJD",
            "countryCode": "CH",
            "cityCode": "ZJD",
            "cityName": "Lenzerheide/Lai",
            "name": "[ZJD] - Lenzerheide/Lai Bus Station - ZJD - Lenzerheide/Lai - Switzerland",
            "searchName": "ZJD-Lenzerheide/Lai Bus Station-Lenzerheide/Lai-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZJA",
            "countryCode": "CH",
            "cityCode": "ZJA",
            "cityName": "Le Locle",
            "name": "[ZJA] - Le Locle Railway Station - ZJA - Le Locle - Switzerland",
            "searchName": "ZJA-Le Locle Railway Station-Le Locle-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKC",
            "countryCode": "CH",
            "cityCode": "ZKC",
            "cityName": "Sarnen",
            "name": "[ZKC] - Sarnen Railway Station - ZKC - Sarnen - Switzerland",
            "searchName": "ZKC-Sarnen Railway Station-Sarnen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKO",
            "countryCode": "CH",
            "cityCode": "ZKO",
            "cityName": "Sierre/Siders",
            "name": "[ZKO] - Sierre/Siders Railway Station - ZKO - Sierre/Siders - Switzerland",
            "searchName": "ZKO-Sierre/Siders Railway Station-Sierre/Siders-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKS",
            "countryCode": "CH",
            "cityCode": "ZKS",
            "cityName": "Solothurn",
            "name": "[ZKS] - Solothurn Railway Station - ZKS - Solothurn - Switzerland",
            "searchName": "ZKS-Solothurn Railway Station-Solothurn-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKU",
            "countryCode": "CH",
            "cityCode": "ZKU",
            "cityName": "Sursee",
            "name": "[ZKU] - Sursee Railway Station - ZKU - Sursee - Switzerland",
            "searchName": "ZKU-Sursee Railway Station-Sursee-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKV",
            "countryCode": "CH",
            "cityCode": "ZKV",
            "cityName": "Thalwil",
            "name": "[ZKV] - Thalwil Railway Station - ZKV - Thalwil - Switzerland",
            "searchName": "ZKV-Thalwil Railway Station-Thalwil-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKW",
            "countryCode": "CH",
            "cityCode": "ZKW",
            "cityName": "Wetzikon",
            "name": "[ZKW] - Wetzikon Railway Station - ZKW - Wetzikon - Switzerland",
            "searchName": "ZKW-Wetzikon Railway Station-Wetzikon-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKX",
            "countryCode": "CH",
            "cityCode": "ZKX",
            "cityName": "Uzwil",
            "name": "[ZKX] - Uzwil Railway Station - ZKX - Uzwil - Switzerland",
            "searchName": "ZKX-Uzwil Railway Station-Uzwil-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKY",
            "countryCode": "CH",
            "cityCode": "ZKY",
            "cityName": "Verbier",
            "name": "[ZKY] - Verbier Bus Station - ZKY - Verbier - Switzerland",
            "searchName": "ZKY-Verbier Bus Station-Verbier-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZKZ",
            "countryCode": "CH",
            "cityCode": "ZKZ",
            "cityName": "Vevey",
            "name": "[ZKZ] - Vevey Railway Station - ZKZ - Vevey - Switzerland",
            "searchName": "ZKZ-Vevey Railway Station-Vevey-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLA",
            "countryCode": "CH",
            "cityCode": "ZLA",
            "cityName": "Villars-sur-Ollon",
            "name": "[ZLA] - Villars-sur-Ollon Railway Station - ZLA - Villars-sur-Ollon - Switzerland",
            "searchName": "ZLA-Villars-sur-Ollon Railway Station-Ollon-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLB",
            "countryCode": "CH",
            "cityCode": "ZLB",
            "cityName": "Visp",
            "name": "[ZLB] - Visp Railway Station - ZLB - Visp - Switzerland",
            "searchName": "ZLB-Visp Railway Station-Visp-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLC",
            "countryCode": "CH",
            "cityCode": "ZLC",
            "cityName": "Waedenswil",
            "name": "[ZLC] - Waedenswil Railway Station - ZLC - Waedenswil - Switzerland",
            "searchName": "ZLC-Waedenswil Railway Station-Waedenswil-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLD",
            "countryCode": "CH",
            "cityCode": "ZLD",
            "cityName": "Weinfelden",
            "name": "[ZLD] - Weinfelden Railway Station - ZLD - Weinfelden - Switzerland",
            "searchName": "ZLD-Weinfelden Railway Station-Weinfelden-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLE",
            "countryCode": "CH",
            "cityCode": "ZLE",
            "cityName": "Wengen",
            "name": "[ZLE] - Wengen Railway Station - ZLE - Wengen - Switzerland",
            "searchName": "ZLE-Wengen Railway Station-Wengen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLF",
            "countryCode": "CH",
            "cityCode": "ZLF",
            "cityName": "Wettingen",
            "name": "[ZLF] - Wettingen Railway Station - ZLF - Wettingen - Switzerland",
            "searchName": "ZLF-Wettingen Railway Station-Wettingen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLH",
            "countryCode": "CH",
            "cityCode": "ZLH",
            "cityName": "Wil",
            "name": "[ZLH] - Wil Railway Station - ZLH - Wil - Switzerland",
            "searchName": "ZLH-Wil Railway Station-Wil-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLI",
            "countryCode": "CH",
            "cityCode": "ZLI",
            "cityName": "Winterthur",
            "name": "[ZLI] - Winterthur Railway Station - ZLI - Winterthur - Switzerland",
            "searchName": "ZLI-Winterthur Railway Station-Winterthur-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLJ",
            "countryCode": "CH",
            "cityCode": "ZLJ",
            "cityName": "Yverdon-les-Bains",
            "name": "[ZLJ] - Yverdon-les-Bains Railway Station - ZLJ - Yverdon-les-Bains - Switzerland",
            "searchName": "ZLJ-Yverdon-les-Bains Railway Station-Bains-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLL",
            "countryCode": "CH",
            "cityCode": "ZLL",
            "cityName": "Zofingen",
            "name": "[ZLL] - Zofingen Railway Station - ZLL - Zofingen - Switzerland",
            "searchName": "ZLL-Zofingen Railway Station-Zofingen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLM",
            "countryCode": "CH",
            "cityCode": "ZLM",
            "cityName": "Zug",
            "name": "[ZLM] - Zug Railway Station - ZLM - Zug - Switzerland",
            "searchName": "ZLM-Zug Railway Station-Zug-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZTK",
            "countryCode": "CH",
            "cityCode": "ZTK",
            "cityName": "Thun",
            "name": "[ZTK] - Thun Railway Station - ZTK - Thun - Switzerland",
            "searchName": "ZTK-Thun Railway Station-Thun-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "YEL",
            "countryCode": "CA",
            "cityCode": "YEL",
            "cityName": "Elliot Lake",
            "name": "[YEL] - Elliot Lake - YEL - Elliot Lake - Canada",
            "searchName": "YEL-Elliot Lake-Elliot Lake Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YEM",
            "countryCode": "CA",
            "cityCode": "YEM",
            "cityName": "Manitowaning",
            "name": "[YEM] - East Manitoulin - YEM - Manitowaning - Canada",
            "searchName": "YEM-East Manitoulin-Manitowaning-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YEN",
            "countryCode": "CA",
            "cityCode": "YEN",
            "cityName": "Estevan",
            "name": "[YEN] - Estevan - YEN - Estevan - Canada",
            "searchName": "YEN-Estevan-Estevan Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAQ",
            "countryCode": "CA",
            "cityCode": "YAQ",
            "cityName": "Maple Bay",
            "name": "[YAQ] - Maple Bay - YAQ - Maple Bay - Canada",
            "searchName": "YAQ-Maple Bay-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YAR",
            "countryCode": "CA",
            "cityCode": "YAR",
            "cityName": "Lagrande 3",
            "name": "[YAR] - Lagrande 3 - YAR - Lagrande 3 - Canada",
            "searchName": "YAR-Lagrande 3-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YEP",
            "countryCode": "CA",
            "cityCode": "YEP",
            "cityName": "Estevan Point",
            "name": "[YEP] - Estevan Point - YEP - Estevan Point - Canada",
            "searchName": "YEP-Estevan Point-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YET",
            "countryCode": "CA",
            "cityCode": "YET",
            "cityName": "Edson",
            "name": "[YET] - Edson - YET - Edson - Canada",
            "searchName": "YET-Edson-Edson Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YEU",
            "countryCode": "CA",
            "cityCode": "YEU",
            "cityName": "Eureka",
            "name": "[YEU] - Eureka - YEU - Eureka - Canada",
            "searchName": "YEU-Eureka-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YEV",
            "countryCode": "CA",
            "cityCode": "YEV",
            "cityName": "Inuvik",
            "name": "[YEV] - Inuvik/mike Zubko - YEV - Inuvik - Canada",
            "searchName": "YEV-Inuvik/Mike Zubko-Inuvik/mike Zubko-Inuvik-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YEY",
            "countryCode": "CA",
            "cityCode": "YEY",
            "cityName": "Amos",
            "name": "[YEY] - Amos - YEY - Amos - Canada",
            "searchName": "YEY-Amos-Amos Havalimani-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFA",
            "countryCode": "CA",
            "cityCode": "YFA",
            "cityName": "Fort Albany",
            "name": "[YFA] - Fort Albany - YFA - Fort Albany - Canada",
            "searchName": "YFA-Fort Albany-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFB",
            "countryCode": "CA",
            "cityCode": "YFB",
            "cityName": "Iqaluit",
            "name": "[YFB] - Iqaluit - YFB - Iqaluit - Canada",
            "searchName": "YFB-Iqaluit-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFC",
            "countryCode": "CA",
            "cityCode": "YFC",
            "cityName": "Fredericton",
            "name": "[YFC] - Fredericton - YFC - Fredericton - Canada",
            "searchName": "YFC-Fredericton-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFE",
            "countryCode": "CA",
            "cityCode": "YFE",
            "cityName": "Forestville",
            "name": "[YFE] - Forestville - YFE - Forestville - Canada",
            "searchName": "YFE-Forestville-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFG",
            "countryCode": "CA",
            "cityCode": "YFG",
            "cityName": "Fontanges",
            "name": "[YFG] - Fontanges - YFG - Fontanges - Canada",
            "searchName": "YFG-Fontanges-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFH",
            "countryCode": "CA",
            "cityCode": "YFH",
            "cityName": "Fort Hope",
            "name": "[YFH] - Fort Hope - YFH - Fort Hope - Canada",
            "searchName": "YFH-Fort Hope-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFJ",
            "countryCode": "CA",
            "cityCode": "YFJ",
            "cityName": "Snare Lake",
            "name": "[YFJ] - Snare Lake - YFJ - Snare Lake - Canada",
            "searchName": "YFJ-Snare Lake-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFL",
            "countryCode": "CA",
            "cityCode": "YFL",
            "cityName": "Fort Reliance",
            "name": "[YFL] - Fort Reliance - YFL - Fort Reliance - Canada",
            "searchName": "YFL-Fort Reliance-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFO",
            "countryCode": "CA",
            "cityCode": "YFO",
            "cityName": "Flin Flon",
            "name": "[YFO] - Flin Flon - YFO - Flin Flon - Canada",
            "searchName": "YFO-Flin Flon-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFR",
            "countryCode": "CA",
            "cityCode": "YFR",
            "cityName": "Fort Resolution",
            "name": "[YFR] - Fort Resolution - YFR - Fort Resolution - Canada",
            "searchName": "YFR-Fort Resolution-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "YFS",
            "countryCode": "CA",
            "cityCode": "YFS",
            "cityName": "Fort Simpson",
            "name": "[YFS] - Fort Simpson - YFS - Fort Simpson - Canada",
            "searchName": "YFS-Fort Simpson-Canadà-Kanada-Canada-Ca na da-Canadá",
            "priority": 0
        },
        {
            "iataCode": "ZHT",
            "countryCode": "CH",
            "cityCode": "GVA",
            "cityName": "Geneva",
            "name": "[ZHT] - Geneva Railway Station - GVA - Geneva - Switzerland",
            "searchName": "ZHT-GVA-Geneva Railway Station-Genf-Geneva-Ginebra-Gen-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "RBR",
            "countryCode": "BR",
            "cityCode": "RBR",
            "cityName": "Rio Branco",
            "name": "[RBR] - Pres. Medici - RBR - Rio Branco - Brazil",
            "searchName": "RBR-Rio Branco-Pres. Medici-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "RBB",
            "countryCode": "BR",
            "cityCode": "RBB",
            "cityName": "Borba",
            "name": "[RBB] - Borba - RBB - Borba - Brazil",
            "searchName": "RBB-Borba-Brasil-Brazilië-Brasile-Brasilien-Brésil-Brazil-Brazilia-Brazilija-Brasiilia",
            "priority": 0
        },
        {
            "iataCode": "GYA",
            "countryCode": "BO",
            "cityCode": "GYA",
            "cityName": "Guayaramerin",
            "name": "[GYA] - Guayaramerin - GYA - Guayaramerin - Bolivia",
            "searchName": "GYA-Guayaramerin-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SBL",
            "countryCode": "BO",
            "cityCode": "SBL",
            "cityName": "Santa Ana",
            "name": "[SBL] - Yacuma - SBL - Santa Ana - Bolivia",
            "searchName": "SBL-Yacuma-Santa Ana-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SJS",
            "countryCode": "BO",
            "cityCode": "SJS",
            "cityName": "San Jose",
            "name": "[SJS] - San Jose - SJS - San Jose - Bolivia",
            "searchName": "SJS-San Jose-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SJV",
            "countryCode": "BO",
            "cityCode": "SJV",
            "cityName": "San Javier",
            "name": "[SJV] - San Javier - SJV - San Javier - Bolivia",
            "searchName": "SJV-San Javier-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SJB",
            "countryCode": "BO",
            "cityCode": "SJB",
            "cityName": "San Joaquin",
            "name": "[SJB] - San Joaquin - SJB - San Joaquin - Bolivia",
            "searchName": "SJB-San Joaquin-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "SRZ",
            "countryCode": "BO",
            "cityCode": "SRZ",
            "cityName": "Santa Cruz",
            "name": "[SRZ] - El Trompillo - SRZ - Santa Cruz - Bolivia",
            "searchName": "SRZ-El Trompillo-tüm havaalanlari-Santa Cruz-Bolívia-Bolivia-Bô li via-Bolivien-Bolivie-Boliwia-Boliivia-Bolivija-Bol",
            "priority": 0
        },
        {
            "iataCode": "ABO",
            "countryCode": "CI",
            "cityCode": "ABO",
            "cityName": "Aboisso",
            "name": "[ABO] - Aboisso - ABO - Aboisso - Ivory Coast",
            "searchName": "ABO-Aboisso-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "ASK",
            "countryCode": "CI",
            "cityCode": "ASK",
            "cityName": "Yamoussoukro",
            "name": "[ASK] - Yamoussoukro - ASK - Yamoussoukro - Ivory Coast",
            "searchName": "ASK-Yamoussoukro-Yamoussoukro Havalimani-Yamoussouro-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "BXI",
            "countryCode": "CI",
            "cityCode": "BXI",
            "cityName": "Boundiali",
            "name": "[BXI] - Boundiali - BXI - Boundiali - Ivory Coast",
            "searchName": "BXI-Boundiali-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "BYK",
            "countryCode": "CI",
            "cityCode": "BYK",
            "cityName": "Bouake",
            "name": "[BYK] - Bouake - BYK - Bouake - Ivory Coast",
            "searchName": "BYK-Bouake-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "BQO",
            "countryCode": "CI",
            "cityCode": "BQO",
            "cityName": "Bouna",
            "name": "[BQO] - Bouna - BQO - Bouna - Ivory Coast",
            "searchName": "BQO-Bouna-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "BBV",
            "countryCode": "CI",
            "cityCode": "BBV",
            "cityName": "Bereby",
            "name": "[BBV] - Hkg - BBV - Bereby - Ivory Coast",
            "searchName": "BBV-Hkg-Nero-Mer Airport-Bereby-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "BDK",
            "countryCode": "CI",
            "cityCode": "BDK",
            "cityName": "Bondoukou",
            "name": "[BDK] - Bondoukou - BDK - Bondoukou - Ivory Coast",
            "searchName": "BDK-Bondoukou-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "KEO",
            "countryCode": "CI",
            "cityCode": "KEO",
            "cityName": "Odienne",
            "name": "[KEO] - Odienne - KEO - Odienne - Ivory Coast",
            "searchName": "KEO-Odienne-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "KTC",
            "countryCode": "CI",
            "cityCode": "KTC",
            "cityName": "Katiola",
            "name": "[KTC] - Katiola - KTC - Katiola - Ivory Coast",
            "searchName": "KTC-Katiola-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "GGN",
            "countryCode": "CI",
            "cityCode": "GGN",
            "cityName": "Gagnoa",
            "name": "[GGN] - Gagnoa - GGN - Gagnoa - Ivory Coast",
            "searchName": "GGN-Gagnoa-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "GGO",
            "countryCode": "CI",
            "cityCode": "GGO",
            "cityName": "Guiglo",
            "name": "[GGO] - Guiglo - GGO - Guiglo - Ivory Coast",
            "searchName": "GGO-Guiglo-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "FEK",
            "countryCode": "CI",
            "cityCode": "FEK",
            "cityName": "Ferkessedougou",
            "name": "[FEK] - Ferkessedougou - FEK - Ferkessedougou - Ivory Coast",
            "searchName": "FEK-Ferkessedougou-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "HGO",
            "countryCode": "CI",
            "cityCode": "HGO",
            "cityName": "Korhogo",
            "name": "[HGO] - Korhogo - HGO - Korhogo - Ivory Coast",
            "searchName": "HGO-Korhogo-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "MJC",
            "countryCode": "CI",
            "cityCode": "MJC",
            "cityName": "Man",
            "name": "[MJC] - Man - MJC - Man - Ivory Coast",
            "searchName": "MJC-Man-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "OFI",
            "countryCode": "CI",
            "cityCode": "OFI",
            "cityName": "Ouango Fitini",
            "name": "[OFI] - Ouango Fitini - OFI - Ouango Fitini - Ivory Coast",
            "searchName": "OFI-Fitini - Flughafen-Ouango Fitini-Ouango-Fitini-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "OGO",
            "countryCode": "CI",
            "cityCode": "OGO",
            "cityName": "Abengourou",
            "name": "[OGO] - Abengourou - OGO - Abengourou - Ivory Coast",
            "searchName": "OGO-Abengourou-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "SPY",
            "countryCode": "CI",
            "cityCode": "SPY",
            "cityName": "San Pedro",
            "name": "[SPY] - San Pedro - SPY - San Pedro - Ivory Coast",
            "searchName": "SPY-San Pedro-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "SEO",
            "countryCode": "CI",
            "cityCode": "SEO",
            "cityName": "Seguela",
            "name": "[SEO] - Seguela - SEO - Seguela - Ivory Coast",
            "searchName": "SEO-Seguela-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "TXU",
            "countryCode": "CI",
            "cityCode": "TXU",
            "cityName": "Tabou",
            "name": "[TXU] - Tabou - TXU - Tabou - Ivory Coast",
            "searchName": "TXU-Tabou-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "TOZ",
            "countryCode": "CI",
            "cityCode": "TOZ",
            "cityName": "Touba",
            "name": "[TOZ] - Touba - TOZ - Touba - Ivory Coast",
            "searchName": "TOZ-Touba-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "MGS",
            "countryCode": "CK",
            "cityCode": "MGS",
            "cityName": "Mangaia Island",
            "name": "[MGS] - Mangaia Island - MGS - Mangaia Island - Cook Islands",
            "searchName": "MGS-Mangaia Island--Ilhas Cook-Cook-eilanden-Kepulauan Cook-Cooki saared-Islas Cook-Isole Cook-Inseln-Îles Cook-Cook Islands-Cookinseln-Cook Adalari",
            "priority": 0
        },
        {
            "iataCode": "MHX",
            "countryCode": "CK",
            "cityCode": "MHX",
            "cityName": "Manihiki Island",
            "name": "[MHX] - Manihiki Island - MHX - Manihiki Island - Cook Islands",
            "searchName": "MHX-Manihiki Island-Ilhas Cook-Cook-eilanden-Kepulauan Cook-Cooki saared-Islas Cook-Isole Cook-Inseln-Îles Cook-Cook Islands-Cookinseln-Cook Adalari",
            "priority": 0
        },
        {
            "iataCode": "MUK",
            "countryCode": "CK",
            "cityCode": "MUK",
            "cityName": "Mauke Island",
            "name": "[MUK] - Mauke Island - MUK - Mauke Island - Cook Islands",
            "searchName": "MUK-Mauke Island--Ilhas Cook-Cook-eilanden-Kepulauan Cook-Cooki saared-Islas Cook-Isole Cook-Inseln-Îles Cook-Cook Islands-Cookinseln-Cook Adalari",
            "priority": 0
        },
        {
            "iataCode": "MOI",
            "countryCode": "CK",
            "cityCode": "MOI",
            "cityName": "Mitiaro Island",
            "name": "[MOI] - Mitiaro Island - MOI - Mitiaro Island - Cook Islands",
            "searchName": "MOI-Mitiaro Island--Ilhas Cook-Cook-eilanden-Kepulauan Cook-Cooki saared-Islas Cook-Isole Cook-Inseln-Îles Cook-Cook Islands-Cookinseln-Cook Adalari",
            "priority": 0
        },
        {
            "iataCode": "RAR",
            "countryCode": "CK",
            "cityCode": "RAR",
            "cityName": "Rarotonga",
            "name": "[RAR] - Rarotonga - RAR - Rarotonga - Cook Islands",
            "searchName": "RAR-Rarotonga-Ilhas Cook-Cook-eilanden-Kepulauan Cook-Cooki saared-Islas Cook-Isole Cook-Inseln-Îles Cook-Cook Islands-Cookinseln-Cook Adalari",
            "priority": 0
        },
        {
            "iataCode": "PZK",
            "countryCode": "CK",
            "cityCode": "PZK",
            "cityName": "Puka Puka",
            "name": "[PZK] - Puka Puka Island - PZK - Puka Puka - Cook Islands",
            "searchName": "PZK-Puka Puka Island-Puka Puka-Ilhas Cook-Cook-eilanden-Kepulauan Cook-Cooki saared-Islas Cook-Isole Cook-Inseln-Îles Cook-Cook Islands-Cookinseln-Cook Adalari",
            "priority": 0
        },
        {
            "iataCode": "PYE",
            "countryCode": "CK",
            "cityCode": "PYE",
            "cityName": "Penrhyn Island",
            "name": "[PYE] - Penrhyn Island - PYE - Penrhyn Island - Cook Islands",
            "searchName": "PYE-Penrhyn Island-Ilhas Cook-Cook-eilanden-Kepulauan Cook-Cooki saared-Islas Cook-Isole Cook-Inseln-Îles Cook-Cook Islands-Cookinseln-Cook Adalari",
            "priority": 0
        },
        {
            "iataCode": "AIT",
            "countryCode": "CK",
            "cityCode": "AIT",
            "cityName": "Aitutaki",
            "name": "[AIT] - Aitutaki - AIT - Aitutaki - Cook Islands",
            "searchName": "AIT-Aitutaki-Ilhas Cook-Cook-eilanden-Kepulauan Cook-Cooki saared-Islas Cook-Isole Cook-Inseln-Îles Cook-Cook Islands-Cookinseln-Cook Adalari",
            "priority": 0
        },
        {
            "iataCode": "AIU",
            "countryCode": "CK",
            "cityCode": "AIU",
            "cityName": "Atiu Island",
            "name": "[AIU] - Atiu Island - AIU - Atiu Island - Cook Islands",
            "searchName": "AIU-Atiu Island-Enua Airport-Ilhas Cook-Cook-eilanden-Kepulauan Cook-Cooki saared-Islas Cook-Isole Cook-Inseln-Îles Cook-Cook Islands-Cookinseln-Cook Adalari",
            "priority": 0
        },
        {
            "iataCode": "ZDJ",
            "countryCode": "CH",
            "cityCode": "BRN",
            "cityName": "Berne",
            "name": "[ZDJ] - Bern Railway Station - BRN - Berne - Switzerland",
            "searchName": "ZDJ-BRN-Bern Railway Station-Bern-Berne-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "BXO",
            "countryCode": "CH",
            "cityCode": "BXO",
            "cityName": "Buochs",
            "name": "[BXO] - Buochs - BXO - Buochs - Switzerland",
            "searchName": "BXO-Buochs-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ACH",
            "countryCode": "CH",
            "cityCode": "ACH",
            "cityName": "Altenrhein",
            "name": "[ACH] - Altenrhein - ACH - Altenrhein - Switzerland",
            "searchName": "ACH-Sankt Gallen-Altenrhein-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ANF",
            "countryCode": "CL",
            "cityCode": "ANF",
            "cityName": "Antofagasta",
            "name": "[ANF] - Cerro Moreno - ANF - Antofagasta - Chile",
            "searchName": "ANF-Antofagasta-Cerro Moreno-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "BBA",
            "countryCode": "CL",
            "cityCode": "BBA",
            "cityName": "Balmaceda",
            "name": "[BBA] - Teniente Vidal - BBA - Balmaceda - Chile",
            "searchName": "BBA-Balmaceda-Teniente Vidal-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "ESR",
            "countryCode": "CL",
            "cityCode": "ESR",
            "cityName": "El Salvador",
            "name": "[ESR] - El Salvador - ESR - El Salvador - Chile",
            "searchName": "ESR-El Salvador Chile-El Salvador-Salvador-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "CCH",
            "countryCode": "CL",
            "cityCode": "CCH",
            "cityName": "Chile Chico",
            "name": "[CCH] - Chile Chico - CCH - Chile Chico - Chile",
            "searchName": "CCH-Chile Chico-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "CCP",
            "countryCode": "CL",
            "cityCode": "CCP",
            "cityName": "Concepcion",
            "name": "[CCP] - Carriel Sur - CCP - Concepcion - Chile",
            "searchName": "CCP-Concepcion Chile-Carriel Sur-Concepci-Concepcion-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "CJC",
            "countryCode": "CL",
            "cityCode": "CJC",
            "cityName": "Calama",
            "name": "[CJC] - El Loa - CJC - Calama - Chile",
            "searchName": "CJC-Calama-El Loa-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "ZLP",
            "countryCode": "CH",
            "cityCode": "ZRH",
            "cityName": "Zurich",
            "name": "[ZLP] - HB Railway Station - ZRH - Zurich - Switzerland",
            "searchName": "ZLP-ZRH-HB Railway Station-Z-Zurich-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZLQ",
            "countryCode": "CH",
            "cityCode": "ZRH",
            "cityName": "Zurich",
            "name": "[ZLQ] - Oerlikon Railway Station - ZRH - Zurich - Switzerland",
            "searchName": "ZLQ-ZRH-Oerlikon Railway Station-Z-Zurich-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZRN",
            "countryCode": "CH",
            "cityCode": "ZRN",
            "cityName": "Nyon",
            "name": "[ZRN] - Nyon Railway Station - ZRN - Nyon - Switzerland",
            "searchName": "ZRN-Nyon Railway Station-Nyon-Schweiz-Switzerland-Zwitserland-Suíça-Suiza-Svizzera-Swiss-Szwajcaria-Sveits-Šveits-Švicarska-Šveice--Isviçre-Suisse",
            "priority": 0
        },
        {
            "iataCode": "ZSS",
            "countryCode": "CI",
            "cityCode": "ZSS",
            "cityName": "Sassandra",
            "name": "[ZSS] - Sassandra - ZSS - Sassandra - Ivory Coast",
            "searchName": "ZSS-Sassandra-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "DJO",
            "countryCode": "CI",
            "cityCode": "DJO",
            "cityName": "Daloa",
            "name": "[DJO] - Daloa - DJO - Daloa - Ivory Coast",
            "searchName": "DJO-Daloa-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "DIV",
            "countryCode": "CI",
            "cityCode": "DIV",
            "cityName": "Divo",
            "name": "[DIV] - Divo - DIV - Divo - Ivory Coast",
            "searchName": "DIV-Divo-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "DIM",
            "countryCode": "CI",
            "cityCode": "DIM",
            "cityName": "Dimbokro",
            "name": "[DIM] - Dimbokro - DIM - Dimbokro - Ivory Coast",
            "searchName": "DIM-Dimbokro-Côte d’Ivoire-Ivory Coast-Fildisi Sahili-Costa de Marfil-Costa d’Avorio",
            "priority": 0
        },
        {
            "iataCode": "LYA",
            "countryCode": "CN",
            "cityCode": "LYA",
            "cityName": "Luoyang",
            "name": "[LYA] - Luoyang - LYA - Luoyang - China",
            "searchName": "LYA-Luoyang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LYG",
            "countryCode": "CN",
            "cityCode": "LYG",
            "cityName": "Lianyungang",
            "name": "[LYG] - Lianyungang - LYG - Lianyungang - China",
            "searchName": "LYG-Lianyungang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LYI",
            "countryCode": "CN",
            "cityCode": "LYI",
            "cityName": "Linyi",
            "name": "[LYI] - Linyi - LYI - Linyi - China",
            "searchName": "LYI-Linyi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LUM",
            "countryCode": "CN",
            "cityCode": "LUM",
            "cityName": "Mangshi",
            "name": "[LUM] - Mangshi - LUM - Mangshi - China",
            "searchName": "LUM-Luxi-Mangshi-Dehong Mangshi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "MXZ",
            "countryCode": "CN",
            "cityCode": "MXZ",
            "cityName": "Meixian",
            "name": "[MXZ] - Meixian - MXZ - Meixian - China",
            "searchName": "MXZ-Meixian-Meizhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NAO",
            "countryCode": "CN",
            "cityCode": "NAO",
            "cityName": "Nanchong",
            "name": "[NAO] - Nanchong - NAO - Nanchong - China",
            "searchName": "NAO-Nanchong-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NGB",
            "countryCode": "CN",
            "cityCode": "NGB",
            "cityName": "Ningbo",
            "name": "[NGB] - Lishe International Airport - NGB - Ningbo - China",
            "searchName": "NGB-Ningbo-Lishe International Airport-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NDG",
            "countryCode": "CN",
            "cityCode": "NDG",
            "cityName": "Qiqihar",
            "name": "[NDG] - Qiqihar - NDG - Qiqihar - China",
            "searchName": "NDG-Qiqihar-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NBS",
            "countryCode": "CN",
            "cityCode": "NBS",
            "cityName": "Baishan",
            "name": "[NBS] - Changbaishan - NBS - Baishan - China",
            "searchName": "NBS-Changbaishan Airport-Changbaishan-Baishan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NLT",
            "countryCode": "CN",
            "cityCode": "NLT",
            "cityName": "Xinyuan",
            "name": "[NLT] - Nalati - NLT - Xinyuan - China",
            "searchName": "NLT-Nalati-Xinyuan City-Xinyuan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NKG",
            "countryCode": "CN",
            "cityCode": "NKG",
            "cityName": "Nanjing",
            "name": "[NKG] - Nanjing Lukou International Airport - NKG - Nanjing - China",
            "searchName": "NKG-Flughafen Nanjing Lukou-Nanjing Lukou International Airport-Aeropuerto Internacional Lukou Nankín-Aéroport international de Nankin Lukou-Aeroporto di Nanchino-Nanjing-Nank-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NGQ",
            "countryCode": "CN",
            "cityCode": "NGQ",
            "cityName": "Shiquanhe",
            "name": "[NGQ] - Ngari Gunsa/Ali Kunsha - NGQ - Shiquanhe - China",
            "searchName": "NGQ-Ngari Gunsa/Ali Kunsha-Shiquanhe-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "OHE",
            "countryCode": "CN",
            "cityCode": "OHE",
            "cityName": "Mohe",
            "name": "[OHE] - Gulian - OHE - Mohe - China",
            "searchName": "OHE-Gulian-Mohe-Mohe Gulian Havalimani-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NZH",
            "countryCode": "CN",
            "cityCode": "NZH",
            "cityName": "Manzhouli",
            "name": "[NZH] - Manzhouli - NZH - Manzhouli - China",
            "searchName": "NZH-Manzhouli Xijiao-Manzhouli-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NNY",
            "countryCode": "CN",
            "cityCode": "NNY",
            "cityName": "Nanyang",
            "name": "[NNY] - Nanyang - NNY - Nanyang - China",
            "searchName": "NNY-Nanyang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NNG",
            "countryCode": "CN",
            "cityCode": "NNG",
            "cityName": "Nanning",
            "name": "[NNG] - Nanning - NNG - Nanning - China",
            "searchName": "NNG-Nanning-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NTG",
            "countryCode": "CN",
            "cityCode": "NTG",
            "cityName": "Nantong",
            "name": "[NTG] - Nantong - NTG - Nantong - China",
            "searchName": "NTG-Nantong-NanTong-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NSZ",
            "countryCode": "CN",
            "cityCode": "NSZ",
            "cityName": "Nansha",
            "name": "[NSZ] - Nansha Ferry Port - NSZ - Nansha - China",
            "searchName": "NSZ-Nansha Ferry Port-Nansha-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "MIG",
            "countryCode": "CN",
            "cityCode": "MIG",
            "cityName": "Mian Yang",
            "name": "[MIG] - Mian Yang - MIG - Mian Yang - China",
            "searchName": "MIG-Mian Yang-Mianyang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LZY",
            "countryCode": "CN",
            "cityCode": "LZY",
            "cityName": "Lin Zhi",
            "name": "[LZY] - Nyingchi - LZY - Lin Zhi - China",
            "searchName": "LZY-Nyingchi-Lin Zhi-Nyingchi Mainling Havalimani-Mainling-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LZO",
            "countryCode": "CN",
            "cityCode": "LZO",
            "cityName": "Luzhou",
            "name": "[LZO] - Luzhou - LZO - Luzhou - China",
            "searchName": "LZO-Luzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "MDG",
            "countryCode": "CN",
            "cityCode": "MDG",
            "cityName": "Mudanjiang",
            "name": "[MDG] - Mudanjiang - MDG - Mudanjiang - China",
            "searchName": "MDG-Mudanjiang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "PNJ",
            "countryCode": "CN",
            "cityCode": "PNJ",
            "cityName": "Peng Lai",
            "name": "[PNJ] - Peng Lai/Sha He Kou - PNJ - Peng Lai - China",
            "searchName": "PNJ-Peng Lai/Sha He Kou-Sha He Kou Havalimani-Peng Lai-Penglai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "PZI",
            "countryCode": "CN",
            "cityCode": "PZI",
            "cityName": "Panzhihua",
            "name": "[PZI] - Pan Zhi Hua Bao An Ying - PZI - Panzhihua - China",
            "searchName": "PZI-Pan Zhi Hua Bao-Pan Zhi Hua Bao An Ying-Pan Zhi Hua Bao An Ying Havalimani-Pan Zhi Hua-Panzhihua-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "QHX",
            "countryCode": "CN",
            "cityCode": "QHX",
            "cityName": "Qionghai",
            "name": "[QHX] - Qionghai Railway Station - QHX - Qionghai - China",
            "searchName": "QHX-Qionghai Railway Station-Qionghai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "RKZ",
            "countryCode": "CN",
            "cityCode": "RKZ",
            "cityName": "Xigaze/Rikaze",
            "name": "[RKZ] - Peace - RKZ - Xigaze/Rikaze - China",
            "searchName": "RKZ-Peace-Shigatse Peace Airport-Xigaze/Rikaze-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "RLK",
            "countryCode": "CN",
            "cityCode": "RLK",
            "cityName": "Bayannur",
            "name": "[RLK] - Tianjitai - RLK - Bayannur - China",
            "searchName": "RLK-Tianjitai-Bayannur-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "RUG",
            "countryCode": "CN",
            "cityCode": "RUG",
            "cityName": "Rugao",
            "name": "[RUG] - Rugao - RUG - Rugao - China",
            "searchName": "RUG-Rugao-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "THQ",
            "countryCode": "CN",
            "cityCode": "THQ",
            "cityName": "Tianshui",
            "name": "[THQ] - Maijishan - THQ - Tianshui - China",
            "searchName": "THQ-Maijishan-Tianshui\u00A0 Havalimani-Tianshui-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TGO",
            "countryCode": "CN",
            "cityCode": "TGO",
            "cityName": "Tongliao",
            "name": "[TGO] - Tongliao - TGO - Tongliao - China",
            "searchName": "TGO-Tongliao-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TEN",
            "countryCode": "CN",
            "cityCode": "TEN",
            "cityName": "Tongren",
            "name": "[TEN] - Tongren - TEN - Tongren - China",
            "searchName": "TEN-Tongren-Tongren Havalimani-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "SXJ",
            "countryCode": "CN",
            "cityCode": "SXJ",
            "cityName": "Shanshan",
            "name": "[SXJ] - Shanshan - SXJ - Shanshan - China",
            "searchName": "SXJ-Shanshan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HET",
            "countryCode": "CN",
            "cityCode": "HET",
            "cityName": "Hohhot",
            "name": "[HET] - Hohhot - HET - Hohhot - China",
            "searchName": "HET-Hohhot-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HFE",
            "countryCode": "CN",
            "cityCode": "HFE",
            "cityName": "Hefei (Luogang Airport)",
            "name": "[HFE] - Shanghai - HFE - Hefei (Luogang Airport) - China",
            "searchName": "HFE-Hefei-Shanghai-Hefei (Luogang Airport)-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HEK",
            "countryCode": "CN",
            "cityCode": "HEK",
            "cityName": "Heihe",
            "name": "[HEK] - Heihe - HEK - Heihe - China",
            "searchName": "HEK-Heihe-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HDG",
            "countryCode": "CN",
            "cityCode": "HDG",
            "cityName": "Handan",
            "name": "[HDG] - Handan - HDG - Handan - China",
            "searchName": "HDG-Handan-Hebei Handan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HJJ",
            "countryCode": "CN",
            "cityCode": "HJJ",
            "cityName": "Zhi Jiang",
            "name": "[HJJ] - Zhi Jiang - HJJ - Zhi Jiang - China",
            "searchName": "HJJ-Zhijiang Airport-Zhi Jiang-Zhijiang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HIA",
            "countryCode": "CN",
            "cityCode": "HIA",
            "cityName": "Huaian",
            "name": "[HIA] - Lianshui - HIA - Huaian - China",
            "searchName": "HIA-Huaiyin Lianshui-Lianshui-Don Muang-Huaiyin-Huai an-Huaian-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HMI",
            "countryCode": "CN",
            "cityCode": "HMI",
            "cityName": "Hami",
            "name": "[HMI] - Hami - HMI - Hami - China",
            "searchName": "HMI-Hami-Kumul-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HLH",
            "countryCode": "CN",
            "cityCode": "HLH",
            "cityName": "Ulanhot",
            "name": "[HLH] - Ulanhot - HLH - Ulanhot - China",
            "searchName": "HLH-Wulanhaote-Ulanhot-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HLD",
            "countryCode": "CN",
            "cityCode": "HLD",
            "cityName": "Hailar",
            "name": "[HLD] - Hailar - HLD - Hailar - China",
            "searchName": "HLD-Hailar-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HTN",
            "countryCode": "CN",
            "cityCode": "HTN",
            "cityName": "Hotan",
            "name": "[HTN] - Hotan - HTN - Hotan - China",
            "searchName": "HTN-Hotan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HSN",
            "countryCode": "CN",
            "cityCode": "HSN",
            "cityName": "Zhoushan",
            "name": "[HSN] - Zhoushan - HSN - Zhoushan - China",
            "searchName": "HSN-Zhoushan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HYN",
            "countryCode": "CN",
            "cityCode": "HYN",
            "cityName": "Taizhou",
            "name": "[HYN] - Huangyan - HYN - Taizhou - China",
            "searchName": "HYN-Huangyan-Taizhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HZG",
            "countryCode": "CN",
            "cityCode": "HZG",
            "cityName": "Hanzhong",
            "name": "[HZG] - Hanzhong - HZG - Hanzhong - China",
            "searchName": "HZG-Hanzhong-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HZH",
            "countryCode": "CN",
            "cityCode": "HZH",
            "cityName": "Liping City",
            "name": "[HZH] - Liping - HZH - Liping City - China",
            "searchName": "HZH-Liping-Liping City-Liping Havalimani-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HUZ",
            "countryCode": "CN",
            "cityCode": "HUZ",
            "cityName": "Huizhou",
            "name": "[HUZ] - Huizhou - HUZ - Huizhou - China",
            "searchName": "HUZ-Huizhou-Huizhou Havalimani-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HSC",
            "countryCode": "CN",
            "cityCode": "HSC",
            "cityName": "Shaoguan",
            "name": "[HSC] - Shaoguan - HSC - Shaoguan - China",
            "searchName": "HSC-Shaoguan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HRB",
            "countryCode": "CN",
            "cityCode": "HRB",
            "cityName": "Harbin",
            "name": "[HRB] - Harbin - HRB - Harbin - China",
            "searchName": "HRB-Harbin-Haerbin-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HNY",
            "countryCode": "CN",
            "cityCode": "HNY",
            "cityName": "Hengyang",
            "name": "[HNY] - Hengyang - HNY - Hengyang - China",
            "searchName": "HNY-Hengyang-Hengyang Havalimani-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JXA",
            "countryCode": "CN",
            "cityCode": "JXA",
            "cityName": "Jixi",
            "name": "[JXA] - Xingkaihu - JXA - Jixi - China",
            "searchName": "JXA-Xingkaihu-Jixi Khanka Lake-Jixi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JZH",
            "countryCode": "CN",
            "cityCode": "JZH",
            "cityName": "Song Pan",
            "name": "[JZH] - Jiu Zhai Huang Long - JZH - Song Pan - China",
            "searchName": "JZH-Song Pan-Jiu Zhai Huang Long-Jiu Zhai Huang Long Airport-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JUH",
            "countryCode": "CN",
            "cityCode": "JUH",
            "cityName": "Chizhou",
            "name": "[JUH] - Jiuhuashan - JUH - Chizhou - China",
            "searchName": "JUH-Jiuhuashan-Chizhou Jiuhuashan-Chizhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JUZ",
            "countryCode": "CN",
            "cityCode": "JUZ",
            "cityName": "Juzhou",
            "name": "[JUZ] - Juzhou - JUZ - Juzhou - China",
            "searchName": "JUZ-Juzhou-Quzhou Airport-Quzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KCA",
            "countryCode": "CN",
            "cityCode": "KCA",
            "cityName": "Kuqa",
            "name": "[KCA] - Kuqa - KCA - Kuqa - China",
            "searchName": "KCA-Kuqa-Kuqa Qiuci Havalimani-Koutcha-Kuga-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JDZ",
            "countryCode": "CN",
            "cityCode": "JDZ",
            "cityName": "Jingdezhen",
            "name": "[JDZ] - Jingdezhen - JDZ - Jingdezhen - China",
            "searchName": "JDZ-Jingdezhen-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JIC",
            "countryCode": "CN",
            "cityCode": "JIC",
            "cityName": "Jinchang",
            "name": "[JIC] - Jinchuan - JIC - Jinchang - China",
            "searchName": "JIC-Jinchuan-Jinchang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JIL",
            "countryCode": "CN",
            "cityCode": "JIL",
            "cityName": "Jilin",
            "name": "[JIL] - Jilin - JIL - Jilin - China",
            "searchName": "JIL-Jilin-Jilin Havalimani-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JIQ",
            "countryCode": "CN",
            "cityCode": "JIQ",
            "cityName": "Qianjiang",
            "name": "[JIQ] - Wulingshan - JIQ - Qianjiang - China",
            "searchName": "JIQ-Wulingshan-Qianjiang Wulingshan-Qianjiang Wulingshan\\/Zhoubai-Qianjiang-Youyang-Zhoubai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JIU",
            "countryCode": "CN",
            "cityCode": "JIU",
            "cityName": "Jiujiang",
            "name": "[JIU] - Jiujiang - JIU - Jiujiang - China",
            "searchName": "JIU-Jiujiang-Jiujiang Airport-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JJN",
            "countryCode": "CN",
            "cityCode": "JJN",
            "cityName": "Jinjiang",
            "name": "[JJN] - Jinjiang - JJN - Jinjiang - China",
            "searchName": "JJN-Jinjiang-Quanzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JGD",
            "countryCode": "CN",
            "cityCode": "JGD",
            "cityName": "Jiagedaqi",
            "name": "[JGD] - Jiagedaqi - JGD - Jiagedaqi - China",
            "searchName": "JGD-Jiagedaqi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JGN",
            "countryCode": "CN",
            "cityCode": "JGN",
            "cityName": "Jiayuguan",
            "name": "[JGN] - Jiayuguan - JGN - Jiayuguan - China",
            "searchName": "JGN-Jiayuguan-Jiuquan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JGS",
            "countryCode": "CN",
            "cityCode": "JGS",
            "cityName": "Ji An",
            "name": "[JGS] - Ji An/Jing Gang Shan - JGS - Ji An - China",
            "searchName": "JGS-Jinggangshan-Ji An/Jing Gang Shan-Jing Gang Shan-Ji An-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JHG",
            "countryCode": "CN",
            "cityCode": "JHG",
            "cityName": "Jinghong",
            "name": "[JHG] - Gasa - JHG - Jinghong - China",
            "searchName": "JHG-Jinghong-Gasa-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JMU",
            "countryCode": "CN",
            "cityCode": "JMU",
            "cityName": "Jiamusi",
            "name": "[JMU] - Jiamusi - JMU - Jiamusi - China",
            "searchName": "JMU-Jiamusi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JNG",
            "countryCode": "CN",
            "cityCode": "JNG",
            "cityName": "Jining",
            "name": "[JNG] - Jining - JNG - Jining - China",
            "searchName": "JNG-Jining-Jining Airport-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JNZ",
            "countryCode": "CN",
            "cityCode": "JNZ",
            "cityName": "Jinzhou",
            "name": "[JNZ] - Jinzhou - JNZ - Jinzhou - China",
            "searchName": "JNZ-Jinzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KJI",
            "countryCode": "CN",
            "cityCode": "KJI",
            "cityName": "Burqin",
            "name": "[KJI] - Kanas - KJI - Burqin - China",
            "searchName": "KJI-Kanas-Burqin-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KMG",
            "countryCode": "CN",
            "cityCode": "KMG",
            "cityName": "Kunming",
            "name": "[KMG] - Kunming - KMG - Kunming - China",
            "searchName": "KMG-Kunming-Kunming Changshui-Kunming Airport-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KGT",
            "countryCode": "CN",
            "cityCode": "KGT",
            "cityName": "Kangding",
            "name": "[KGT] - Kangding - KGT - Kangding - China",
            "searchName": "KGT-Kangding-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KHG",
            "countryCode": "CN",
            "cityCode": "KHG",
            "cityName": "Kashi",
            "name": "[KHG] - Kashi - KHG - Kashi - China",
            "searchName": "KHG-Kashi-Kashi Havalimani-Kaschgar-Kashgar-Kachgar-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KHN",
            "countryCode": "CN",
            "cityCode": "KHN",
            "cityName": "Nanchang",
            "name": "[KHN] - Nanchang - KHN - Nanchang - China",
            "searchName": "KHN-Nanchang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KOW",
            "countryCode": "CN",
            "cityCode": "KOW",
            "cityName": "Ganzhou",
            "name": "[KOW] - Ganzhou - KOW - Ganzhou - China",
            "searchName": "KOW-Ganzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KRY",
            "countryCode": "CN",
            "cityCode": "KRY",
            "cityName": "Karamay",
            "name": "[KRY] - Karamay - KRY - Karamay - China",
            "searchName": "KRY-Karamay-Karamay Havalimani-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KYY",
            "countryCode": "CN",
            "cityCode": "KRY",
            "cityName": "Karamay",
            "name": "[KYY] - Karamay Bus Station - KRY - Karamay - China",
            "searchName": "KYY-KRY-Karamay Bus Station-Karamay-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KRL",
            "countryCode": "CN",
            "cityCode": "KRL",
            "cityName": "Korla",
            "name": "[KRL] - Korla - KRL - Korla - China",
            "searchName": "KRL-Korla-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LHK",
            "countryCode": "CN",
            "cityCode": "LHK",
            "cityName": "Guanghua",
            "name": "[LHK] - Guanghua - LHK - Guanghua - China",
            "searchName": "LHK-Guanghua-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LIA",
            "countryCode": "CN",
            "cityCode": "LIA",
            "cityName": "Liangping",
            "name": "[LIA] - Liangping - LIA - Liangping - China",
            "searchName": "LIA-Liangping-Lianping Havalimani-Lianping-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LCX",
            "countryCode": "CN",
            "cityCode": "LCX",
            "cityName": "Longyan",
            "name": "[LCX] - Liancheng - LCX - Longyan - China",
            "searchName": "LCX-Liancheng-Longyan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LDS",
            "countryCode": "CN",
            "cityCode": "LDS",
            "cityName": "Yichun",
            "name": "[LDS] - Lindu - LDS - Yichun - China",
            "searchName": "LDS-Yichun Lindu-Lindu-Yichun Shi-Yichun-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KWE",
            "countryCode": "CN",
            "cityCode": "KWE",
            "cityName": "Guiyang",
            "name": "[KWE] - Guiyang - KWE - Guiyang - China",
            "searchName": "KWE-Guiyang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KWL",
            "countryCode": "CN",
            "cityCode": "KWL",
            "cityName": "Guilin",
            "name": "[KWL] - Guilin - KWL - Guilin - China",
            "searchName": "KWL-Guilin-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LNJ",
            "countryCode": "CN",
            "cityCode": "LNJ",
            "cityName": "Lincang",
            "name": "[LNJ] - Lincang - LNJ - Lincang - China",
            "searchName": "LNJ-Lincang-Lincang Havalimani-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LLF",
            "countryCode": "CN",
            "cityCode": "LLF",
            "cityName": "Lingling",
            "name": "[LLF] - LingLing - LLF - Lingling - China",
            "searchName": "LLF-LingLing-Yongzhou Havalimani-Lingling-Ling Ling-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LLB",
            "countryCode": "CN",
            "cityCode": "LLB",
            "cityName": "Libo",
            "name": "[LLB] - Libo - LLB - Libo - China",
            "searchName": "LLB-Libo-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LJG",
            "countryCode": "CN",
            "cityCode": "LJG",
            "cityName": "Lijiang City",
            "name": "[LJG] - Lijiang - LJG - Lijiang City - China",
            "searchName": "LJG-Lijiang-Lijiang City-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LQS",
            "countryCode": "CN",
            "cityCode": "LQS",
            "cityName": "Lingshui",
            "name": "[LQS] - Lingshui Railway Station - LQS - Lingshui - China",
            "searchName": "LQS-Lingshui Railway Station-Lingshui-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LZH",
            "countryCode": "CN",
            "cityCode": "LZH",
            "cityName": "Liuzhou",
            "name": "[LZH] - Liuzhou - LZH - Liuzhou - China",
            "searchName": "LZH-Liuzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "BHY",
            "countryCode": "CN",
            "cityCode": "BHY",
            "cityName": "Beihai",
            "name": "[BHY] - Beihai - BHY - Beihai - China",
            "searchName": "BHY-Beihai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "BFU",
            "countryCode": "CN",
            "cityCode": "BFU",
            "cityName": "Bengbu",
            "name": "[BFU] - Bengbu - BFU - Bengbu - China",
            "searchName": "BFU-Bengbu-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "BAV",
            "countryCode": "CN",
            "cityCode": "BAV",
            "cityName": "Baotou",
            "name": "[BAV] - Baotou - BAV - Baotou - China",
            "searchName": "BAV-Baotou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "AYN",
            "countryCode": "CN",
            "cityCode": "AYN",
            "cityName": "Anyang",
            "name": "[AYN] - Anyang - AYN - Anyang - China",
            "searchName": "AYN-Anyang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "AVA",
            "countryCode": "CN",
            "cityCode": "AVA",
            "cityName": "An Shun",
            "name": "[AVA] - An Shun/Huang Guo Shu - AVA - An Shun - China",
            "searchName": "AVA-An Shun/Huang Guo-An Shun/Huang Guo Shu-An Shun-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "AOG",
            "countryCode": "CN",
            "cityCode": "AOG",
            "cityName": "Anshan",
            "name": "[AOG] - Anshan - AOG - Anshan - China",
            "searchName": "AOG-Anshan-Anshan Teng ao Havalimani-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "AQG",
            "countryCode": "CN",
            "cityCode": "AQG",
            "cityName": "Anqing",
            "name": "[AQG] - Anqing - AQG - Anqing - China",
            "searchName": "AQG-Anqing-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "AKA",
            "countryCode": "CN",
            "cityCode": "AKA",
            "cityName": "Ankang",
            "name": "[AKA] - Ankang - AKA - Ankang - China",
            "searchName": "AKA-Ankang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "AKU",
            "countryCode": "CN",
            "cityCode": "AKU",
            "cityName": "Aksu",
            "name": "[AKU] - Aksu - AKU - Aksu - China",
            "searchName": "AKU-Aksu-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "AAT",
            "countryCode": "CN",
            "cityCode": "AAT",
            "cityName": "Altay",
            "name": "[AAT] - Altay - AAT - Altay - China",
            "searchName": "AAT-Altay-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "AEB",
            "countryCode": "CN",
            "cityCode": "AEB",
            "cityName": "Baise",
            "name": "[AEB] - Youjiang - AEB - Baise - China",
            "searchName": "AEB-Youjiang-Baise-Baise Youjiang Havalimani-Baise Youjiang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ACX",
            "countryCode": "CN",
            "cityCode": "ACX",
            "cityName": "Xingyi",
            "name": "[ACX] - Xingyi - ACX - Xingyi - China",
            "searchName": "ACX-Xingyi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DQA",
            "countryCode": "CN",
            "cityCode": "DQA",
            "cityName": "Daqing",
            "name": "[DQA] - Sartu - DQA - Daqing - China",
            "searchName": "DQA-Daqing Shi-Sartu-Saertu Airport-Daqing-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DOY",
            "countryCode": "CN",
            "cityCode": "DOY",
            "cityName": "Dongying",
            "name": "[DOY] - Dongying - DOY - Dongying - China",
            "searchName": "DOY-Dongying-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DZU",
            "countryCode": "CN",
            "cityCode": "DZU",
            "cityName": "Dazu",
            "name": "[DZU] - Dazu - DZU - Dazu - China",
            "searchName": "DZU-Dazu-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DYG",
            "countryCode": "CN",
            "cityCode": "DYG",
            "cityName": "Dayong",
            "name": "[DYG] - Dayong - DYG - Dayong - China",
            "searchName": "DYG-Dayong-Dayang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DSN",
            "countryCode": "CN",
            "cityCode": "DSN",
            "cityName": "Ordos (Dongsheng)",
            "name": "[DSN] - Dongsheng - DSN - Ordos (Dongsheng) - China",
            "searchName": "DSN-Dongsheng-Ordos-Ordos (Dongsheng)-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DLC",
            "countryCode": "CN",
            "cityCode": "DLC",
            "cityName": "Dalian",
            "name": "[DLC] - Dalian - DLC - Dalian - China",
            "searchName": "DLC-Dalian-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DNH",
            "countryCode": "CN",
            "cityCode": "DNH",
            "cityName": "Dunhuang",
            "name": "[DNH] - Dunhuang - DNH - Dunhuang - China",
            "searchName": "DNH-Dunhuang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DLU",
            "countryCode": "CN",
            "cityCode": "DLU",
            "cityName": "Dali City",
            "name": "[DLU] - Dali - DLU - Dali City - China",
            "searchName": "DLU-Dali-Dali City-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DIG",
            "countryCode": "CN",
            "cityCode": "DIG",
            "cityName": "Diqing",
            "name": "[DIG] - Diqing - DIG - Diqing - China",
            "searchName": "DIG-Diqing-Diqing Airport-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DDG",
            "countryCode": "CN",
            "cityCode": "DDG",
            "cityName": "Dandong",
            "name": "[DDG] - Dandong - DDG - Dandong - China",
            "searchName": "DDG-Dandong-Dandong Airport-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DCY",
            "countryCode": "CN",
            "cityCode": "DCY",
            "cityName": "Daocheng",
            "name": "[DCY] - Yading - DCY - Daocheng - China",
            "searchName": "DCY-Yading-Daocheng-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DEY",
            "countryCode": "CN",
            "cityCode": "DEY",
            "cityName": "Deyang",
            "name": "[DEY] - Deyang Bus Station - DEY - Deyang - China",
            "searchName": "DEY-Deyang Bus Station-Deyang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ENH",
            "countryCode": "CN",
            "cityCode": "ENH",
            "cityName": "Enshi",
            "name": "[ENH] - Enshi - ENH - Enshi - China",
            "searchName": "ENH-Enshi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ENY",
            "countryCode": "CN",
            "cityCode": "ENY",
            "cityName": "Yanan",
            "name": "[ENY] - Ershilipu - ENY - Yanan - China",
            "searchName": "ENY-Yan an-Ershilipu-Yan an Airport-Yanan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ERL",
            "countryCode": "CN",
            "cityCode": "ERL",
            "cityName": "Erenhot",
            "name": "[ERL] - Saiwusu International - ERL - Erenhot - China",
            "searchName": "ERL-Saiwusu International-Erenhot-Erenhot Saiwusu Havalimani-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CNI",
            "countryCode": "CN",
            "cityCode": "CNI",
            "cityName": "Changhai",
            "name": "[CNI] - Changhai - CNI - Changhai - China",
            "searchName": "CNI-Changhai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CSX",
            "countryCode": "CN",
            "cityCode": "CSX",
            "cityName": "Changsha",
            "name": "[CSX] - Changsha - CSX - Changsha - China",
            "searchName": "CSX-Changsha-Changsha Airport-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DAT",
            "countryCode": "CN",
            "cityCode": "DAT",
            "cityName": "Datong",
            "name": "[DAT] - Datong - DAT - Datong - China",
            "searchName": "DAT-Datong-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DAX",
            "countryCode": "CN",
            "cityCode": "DAX",
            "cityName": "Daxian",
            "name": "[DAX] - Daxian - DAX - Daxian - China",
            "searchName": "DAX-Daxian-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CZX",
            "countryCode": "CN",
            "cityCode": "CZX",
            "cityName": "Changzhou",
            "name": "[CZX] - Changzhou - CZX - Changzhou - China",
            "searchName": "CZX-Changzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CTU",
            "countryCode": "CN",
            "cityCode": "CTU",
            "cityName": "Chengdu",
            "name": "[CTU] - Shuangliu - CTU - Chengdu - China",
            "searchName": "CTU-Chengdu-Shuangliu-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CKG",
            "countryCode": "CN",
            "cityCode": "CKG",
            "cityName": "Chongqing",
            "name": "[CKG] - Chongqing Jiangbei International - CKG - Chongqing - China",
            "searchName": "CKG-Chongqing-Chongqing Jiangbei International-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CGD",
            "countryCode": "CN",
            "cityCode": "CGD",
            "cityName": "Changde",
            "name": "[CGD] - Changde - CGD - Changde - China",
            "searchName": "CGD-Changde-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CGO",
            "countryCode": "CN",
            "cityCode": "CGO",
            "cityName": "Zhengzhou",
            "name": "[CGO] - Zhengzhou - CGO - Zhengzhou - China",
            "searchName": "CGO-Zhengzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CGQ",
            "countryCode": "CN",
            "cityCode": "CGQ",
            "cityName": "Changchun",
            "name": "[CGQ] - Changchun - CGQ - Changchun - China",
            "searchName": "CGQ-Changchun-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CHG",
            "countryCode": "CN",
            "cityCode": "CHG",
            "cityName": "Chaoyang",
            "name": "[CHG] - Chaoyang Airport - CHG - Chaoyang - China",
            "searchName": "CHG-Chaoyang Airport-Chaoyang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CIF",
            "countryCode": "CN",
            "cityCode": "CIF",
            "cityName": "Chifeng",
            "name": "[CIF] - Chifeng - CIF - Chifeng - China",
            "searchName": "CIF-Chifeng-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CIH",
            "countryCode": "CN",
            "cityCode": "CIH",
            "cityName": "Changzhi",
            "name": "[CIH] - Changzhi - CIH - Changzhi - China",
            "searchName": "CIH-Changzhi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "CHW",
            "countryCode": "CN",
            "cityCode": "CHW",
            "cityName": "Jiuquan",
            "name": "[CHW] - Jiuhuang - CHW - Jiuquan - China",
            "searchName": "CHW-Jiuhuang-Jiuquan Havalimani-Jiuquan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "SMB",
            "countryCode": "CL",
            "cityCode": "SMB",
            "cityName": "Cerro Sombrero",
            "name": "[SMB] - Cerro Sombrero - SMB - Cerro Sombrero - Chile",
            "searchName": "SMB-Cerro Sombrero-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "YAI",
            "countryCode": "CL",
            "cityCode": "YAI",
            "cityName": "Chillan",
            "name": "[YAI] - Chillan - YAI - Chillan - Chile",
            "searchName": "YAI-Chillan-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "VAP",
            "countryCode": "CL",
            "cityCode": "VAP",
            "cityName": "Valparaiso",
            "name": "[VAP] - Valparaiso - VAP - Valparaiso - Chile",
            "searchName": "VAP-Valparaiso-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "WPA",
            "countryCode": "CL",
            "cityCode": "WPA",
            "cityName": "Puerto Aisen",
            "name": "[WPA] - Puerto Aisen - WPA - Puerto Aisen - Chile",
            "searchName": "WPA-Puerto Aisen-Puerto Aisen Havalimani-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "WPR",
            "countryCode": "CL",
            "cityCode": "WPR",
            "cityName": "Porvenir",
            "name": "[WPR] - Porvenir - WPR - Porvenir - Chile",
            "searchName": "WPR-Porvenir-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "WPU",
            "countryCode": "CL",
            "cityCode": "WPU",
            "cityName": "Puerto Williams",
            "name": "[WPU] - Puerto Williams - WPU - Puerto Williams - Chile",
            "searchName": "WPU-Puerto Williams-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "WCA",
            "countryCode": "CL",
            "cityCode": "WCA",
            "cityName": "Castro",
            "name": "[WCA] - Gamboa - WCA - Castro - Chile",
            "searchName": "WCA-Gamboa-Castro-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "MHC",
            "countryCode": "CL",
            "cityCode": "WCA",
            "cityName": "Castro",
            "name": "[MHC] - Macmahon Camp 4 - WCA - Castro - Chile",
            "searchName": "MHC-WCA-Macmahon Camp 4-Castro-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "WCH",
            "countryCode": "CL",
            "cityCode": "WCH",
            "cityName": "Chaiten",
            "name": "[WCH] - Chaiten - WCH - Chaiten - Chile",
            "searchName": "WCH-Chaiten-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "WAP",
            "countryCode": "CL",
            "cityCode": "WAP",
            "cityName": "Alto Palena",
            "name": "[WAP] - Alto Palena - WAP - Alto Palena - Chile",
            "searchName": "WAP-Alto Palena-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "VLR",
            "countryCode": "CL",
            "cityCode": "VLR",
            "cityName": "Vallenar",
            "name": "[VLR] - Vallenar - VLR - Vallenar - Chile",
            "searchName": "VLR-Vallenar-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "ZOS",
            "countryCode": "CL",
            "cityCode": "ZOS",
            "cityName": "Osorno",
            "name": "[ZOS] - CaÃ±al Bajo - ZOS - Osorno - Chile",
            "searchName": "ZOS-Osorno-Cañal Bajo-Canal Balo-Cañal Bajo Airport-CaÃ±al Bajo-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "ZPC",
            "countryCode": "CL",
            "cityCode": "ZPC",
            "cityName": "Pucon",
            "name": "[ZPC] - Pucon - ZPC - Pucon - Chile",
            "searchName": "ZPC-Pucon-Pucon Airport-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "ZUD",
            "countryCode": "CL",
            "cityCode": "ZUD",
            "cityName": "Ancud",
            "name": "[ZUD] - Ancud - ZUD - Ancud - Chile",
            "searchName": "ZUD-Ancud-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "ZVC",
            "countryCode": "CL",
            "cityCode": "ZVC",
            "cityName": "Villarrica",
            "name": "[ZVC] - Villarrica Bus Station - ZVC - Villarrica - Chile",
            "searchName": "ZVC-Villarrica Bus Station-Villarrica-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "ZIC",
            "countryCode": "CL",
            "cityCode": "ZIC",
            "cityName": "Victoria",
            "name": "[ZIC] - Victoria - ZIC - Victoria - Chile",
            "searchName": "ZIC-Victoria-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "ZAL",
            "countryCode": "CL",
            "cityCode": "ZAL",
            "cityName": "Valdivia",
            "name": "[ZAL] - Pichoy - ZAL - Valdivia - Chile",
            "searchName": "ZAL-Valdivia-Pichoy-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "YAO",
            "countryCode": "CM",
            "cityCode": "YAO",
            "cityName": "Yaounde",
            "name": "[YAO] - Yaounde Airport - YAO - Yaounde - Cameroon",
            "searchName": "YAO-Yaounde Airport-Yaounde Nsimalen-Yaound-Yaounde-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "NSI",
            "countryCode": "CM",
            "cityCode": "YAO",
            "cityName": "Yaounde",
            "name": "[NSI] - Nsimalen International - YAO - Yaounde - Cameroon",
            "searchName": "NSI-YAO-Yaounde Nsimalen-Nsimalen International-Nsimalen-Nsimalen Airport-Yaound-Yaounde-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "TKC",
            "countryCode": "CM",
            "cityCode": "TKC",
            "cityName": "Tiko",
            "name": "[TKC] - Tiko - TKC - Tiko - Cameroon",
            "searchName": "TKC-Tiko-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "OUR",
            "countryCode": "CM",
            "cityCode": "OUR",
            "cityName": "Batouri",
            "name": "[OUR] - Batouri - OUR - Batouri - Cameroon",
            "searchName": "OUR-Batouri-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "MMF",
            "countryCode": "CM",
            "cityCode": "MMF",
            "cityName": "Mamfe",
            "name": "[MMF] - Mamfe - MMF - Mamfe - Cameroon",
            "searchName": "MMF-Mamfe-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "NKS",
            "countryCode": "CM",
            "cityCode": "NKS",
            "cityName": "Nkongsamba",
            "name": "[NKS] - Nkongsamba - NKS - Nkongsamba - Cameroon",
            "searchName": "NKS-Nkongsamba-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "NGE",
            "countryCode": "CM",
            "cityCode": "NGE",
            "cityName": "Ngaoundéré",
            "name": "[NGE] - NgaoundÃ©rÃ© - NGE - Ngaoundéré - Cameroon",
            "searchName": "NGE-Ngaoundéré-NgaoundÃ©rÃ©-Ngaound-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "MVR",
            "countryCode": "CM",
            "cityCode": "MVR",
            "cityName": "Maroua",
            "name": "[MVR] - Salam - MVR - Maroua - Cameroon",
            "searchName": "MVR-Salam-Maroua-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "KLE",
            "countryCode": "CM",
            "cityCode": "KLE",
            "cityName": "Kaele",
            "name": "[KLE] - Kaele - KLE - Kaele - Cameroon",
            "searchName": "KLE-Kaele-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "KBI",
            "countryCode": "CM",
            "cityCode": "KBI",
            "cityName": "Kribi",
            "name": "[KBI] - Kribi - KBI - Kribi - Cameroon",
            "searchName": "KBI-Kribi-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "GXX",
            "countryCode": "CM",
            "cityCode": "GXX",
            "cityName": "Yagoua",
            "name": "[GXX] - Yagoua - GXX - Yagoua - Cameroon",
            "searchName": "GXX-Yagoua-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "GOU",
            "countryCode": "CM",
            "cityCode": "GOU",
            "cityName": "Garoua",
            "name": "[GOU] - Garoua - GOU - Garoua - Cameroon",
            "searchName": "GOU-Garoua-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "FOM",
            "countryCode": "CM",
            "cityCode": "FOM",
            "cityName": "Foumban",
            "name": "[FOM] - Foumban - FOM - Foumban - Cameroon",
            "searchName": "FOM-Foumban-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "EBW",
            "countryCode": "CM",
            "cityCode": "EBW",
            "cityName": "Ebolowa",
            "name": "[EBW] - Ebolowa - EBW - Ebolowa - Cameroon",
            "searchName": "EBW-Ebolowa-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "DLA",
            "countryCode": "CM",
            "cityCode": "DLA",
            "cityName": "Douala",
            "name": "[DLA] - Douala - DLA - Douala - Cameroon",
            "searchName": "DLA-Douala-Duala-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "DSC",
            "countryCode": "CM",
            "cityCode": "DSC",
            "cityName": "Dschang",
            "name": "[DSC] - Dschang - DSC - Dschang - Cameroon",
            "searchName": "DSC-Dschang-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "BFX",
            "countryCode": "CM",
            "cityCode": "BFX",
            "cityName": "Bafoussam",
            "name": "[BFX] - Bafoussam - BFX - Bafoussam - Cameroon",
            "searchName": "BFX-Bafoussam-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "BLC",
            "countryCode": "CM",
            "cityCode": "BLC",
            "cityName": "Bali",
            "name": "[BLC] - Bamenda - BLC - Bali - Cameroon",
            "searchName": "BLC-Bamenda-Bali-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "BPC",
            "countryCode": "CM",
            "cityCode": "BPC",
            "cityName": "Bamenda",
            "name": "[BPC] - Bamenda - BPC - Bamenda - Cameroon",
            "searchName": "BPC-Bamenda-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "BTA",
            "countryCode": "CM",
            "cityCode": "BTA",
            "cityName": "Yaoundé",
            "name": "[BTA] - YaoundÃ© - BTA - Yaoundé - Cameroon",
            "searchName": "BTA-Yaoundé-YaoundÃ©-Bertoua-Yaound-Kameroen-Cameroon-Ca mo run-Kamerun-Camarões-Camerun-Cameroun-Camerún-Kameruna-Kamerunas",
            "priority": 0
        },
        {
            "iataCode": "BPL",
            "countryCode": "CN",
            "cityCode": "BPL",
            "cityName": "Bole",
            "name": "[BPL] - Alashankou - BPL - Bole - China",
            "searchName": "BPL-Alashankou-Bole Alashankou-Bole Alashankou Havalimani-Bole-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "BPO",
            "countryCode": "CN",
            "cityCode": "BPO",
            "cityName": "Boao",
            "name": "[BPO] - Boao Railway Station - BPO - Boao - China",
            "searchName": "BPO-Bo ao Railway Station-Boao Railway Station-Bo ao-Boao-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "BPX",
            "countryCode": "CN",
            "cityCode": "BPX",
            "cityName": "Bangda",
            "name": "[BPX] - Bangda - BPX - Bangda - China",
            "searchName": "BPX-Bangda-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "BSD",
            "countryCode": "CN",
            "cityCode": "BSD",
            "cityName": "Baoshan",
            "name": "[BSD] - Baoshan - BSD - Baoshan - China",
            "searchName": "BSD-Baoshan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "SHE",
            "countryCode": "CN",
            "cityCode": "SHE",
            "cityName": "Shenyang",
            "name": "[SHE] - Shenyang - SHE - Shenyang - China",
            "searchName": "SHE-Shenyang-Shenyang Airport-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "SJW",
            "countryCode": "CN",
            "cityCode": "SJW",
            "cityName": "Shijiazhuang",
            "name": "[SJW] - Daguocun - SJW - Shijiazhuang - China",
            "searchName": "SJW-Shijiazhuang-Daguocun-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YCU",
            "countryCode": "CN",
            "cityCode": "YCU",
            "cityName": "Yun Cheng",
            "name": "[YCU] - Yun Cheng - YCU - Yun Cheng - China",
            "searchName": "YCU-Yun Cheng-Yuncheng-Cullaton G-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YBP",
            "countryCode": "CN",
            "cityCode": "YBP",
            "cityName": "Yibin",
            "name": "[YBP] - Yibin - YBP - Yibin - China",
            "searchName": "YBP-Yibin-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YTY",
            "countryCode": "CN",
            "cityCode": "YTY",
            "cityName": "Yangzhou",
            "name": "[YTY] - Taizhou - YTY - Yangzhou - China",
            "searchName": "YTY-Taizhou-Yangzhou Taizhou-Yangzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YUA",
            "countryCode": "CN",
            "cityCode": "YUA",
            "cityName": "Yuanmou",
            "name": "[YUA] - Yuanmou - YUA - Yuanmou - China",
            "searchName": "YUA-Yuanmou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XUZ",
            "countryCode": "CN",
            "cityCode": "XUZ",
            "cityName": "Xuzhou",
            "name": "[XUZ] - Xuzhou - XUZ - Xuzhou - China",
            "searchName": "XUZ-Xuzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XMN",
            "countryCode": "CN",
            "cityCode": "XMN",
            "cityName": "Xiamen",
            "name": "[XMN] - Xiamen - XMN - Xiamen - China",
            "searchName": "XMN-Xiamen-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XNT",
            "countryCode": "CN",
            "cityCode": "XNT",
            "cityName": "Xingtai",
            "name": "[XNT] - Xingtai - XNT - Xingtai - China",
            "searchName": "XNT-Xingtai-Xingtai Havalimani-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XNN",
            "countryCode": "CN",
            "cityCode": "XNN",
            "cityName": "Xining",
            "name": "[XNN] - Xining - XNN - Xining - China",
            "searchName": "XNN-Xining-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XIL",
            "countryCode": "CN",
            "cityCode": "XIL",
            "cityName": "Xilinhot",
            "name": "[XIL] - Xilinhot - XIL - Xilinhot - China",
            "searchName": "XIL-Xilinhot-Xilin Hot-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XIN",
            "countryCode": "CN",
            "cityCode": "XIN",
            "cityName": "Xingning",
            "name": "[XIN] - Xingning - XIN - Xingning - China",
            "searchName": "XIN-Xingning-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XEN",
            "countryCode": "CN",
            "cityCode": "XEN",
            "cityName": "Xingcheng",
            "name": "[XEN] - Xingcheng - XEN - Xingcheng - China",
            "searchName": "XEN-Xingcheng-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XFN",
            "countryCode": "CN",
            "cityCode": "XFN",
            "cityName": "Xiangfan",
            "name": "[XFN] - Xiangfan - XFN - Xiangfan - China",
            "searchName": "XFN-Xiangfan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XIC",
            "countryCode": "CN",
            "cityCode": "XIC",
            "cityName": "Xichang",
            "name": "[XIC] - Xichang - XIC - Xichang - China",
            "searchName": "XIC-Xichang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XHO",
            "countryCode": "CN",
            "cityCode": "XHO",
            "cityName": "Dongguan",
            "name": "[XHO] - City Bus Station - XHO - Dongguan - China",
            "searchName": "XHO-City Bus Station-Dongguan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "DDB",
            "countryCode": "CN",
            "cityCode": "XHO",
            "cityName": "Dongguan",
            "name": "[DDB] - CZ Bus Station - XHO - Dongguan - China",
            "searchName": "DDB-XHO-CZ Bus Station-Dongguan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WXJ",
            "countryCode": "CN",
            "cityCode": "WXJ",
            "cityName": "Wanning",
            "name": "[WXJ] - Wanning Railway Station - WXJ - Wanning - China",
            "searchName": "WXJ-Wanning Railway Station-Wanning-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WXN",
            "countryCode": "CN",
            "cityCode": "WXN",
            "cityName": "Wanxian",
            "name": "[WXN] - Wanxian - WXN - Wanxian - China",
            "searchName": "WXN-Wanxian-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WUA",
            "countryCode": "CN",
            "cityCode": "WUA",
            "cityName": "Wu Hai",
            "name": "[WUA] - Wu Hai - WUA - Wu Hai - China",
            "searchName": "WUA-Wu Hai-Wuhai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WUS",
            "countryCode": "CN",
            "cityCode": "WUS",
            "cityName": "Wuyishan",
            "name": "[WUS] - Wuyishan - WUS - Wuyishan - China",
            "searchName": "WUS-Wuyishan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WUH",
            "countryCode": "CN",
            "cityCode": "WUH",
            "cityName": "Wuhan",
            "name": "[WUH] - Tianhe International - WUH - Wuhan - China",
            "searchName": "WUH-Wuhan-Tianhe International-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WUX",
            "countryCode": "CN",
            "cityCode": "WUX",
            "cityName": "Wuxi",
            "name": "[WUX] - Wuxi - WUX - Wuxi - China",
            "searchName": "WUX-Wuxi-Wuxi Jiangsu-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WUZ",
            "countryCode": "CN",
            "cityCode": "WUZ",
            "cityName": "Wuzhou",
            "name": "[WUZ] - Changzhoudao - WUZ - Wuzhou - China",
            "searchName": "WUZ-Changzhoudao-Wuzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WNH",
            "countryCode": "CN",
            "cityCode": "WNH",
            "cityName": "Wenshan",
            "name": "[WNH] - Puzhehei - WNH - Wenshan - China",
            "searchName": "WNH-Puzhehei-Wenshan Puzhehei-Wenshan Puzhehei Havalimani-Wenshan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WNZ",
            "countryCode": "CN",
            "cityCode": "WNZ",
            "cityName": "Wenzhou",
            "name": "[WNZ] - Wenzhou - WNZ - Wenzhou - China",
            "searchName": "WNZ-Wenzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WHU",
            "countryCode": "CN",
            "cityCode": "WHU",
            "cityName": "Wuhu",
            "name": "[WHU] - Wuhu - WHU - Wuhu - China",
            "searchName": "WHU-Wuhu-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WEF",
            "countryCode": "CN",
            "cityCode": "WEF",
            "cityName": "Weifang",
            "name": "[WEF] - Weifang - WEF - Weifang - China",
            "searchName": "WEF-Weifang-Weifang Havalimani-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WEH",
            "countryCode": "CN",
            "cityCode": "WEH",
            "cityName": "Weihai",
            "name": "[WEH] - Weihai - WEH - Weihai - China",
            "searchName": "WEH-Weihai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "WEC",
            "countryCode": "CN",
            "cityCode": "WEC",
            "cityName": "Wenchang",
            "name": "[WEC] - Wenchang Railway Station - WEC - Wenchang - China",
            "searchName": "WEC-Wenchang Railway Station-Wenchang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "UYN",
            "countryCode": "CN",
            "cityCode": "UYN",
            "cityName": "Yulin",
            "name": "[UYN] - Yulin - UYN - Yulin - China",
            "searchName": "UYN-Yulin-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "URC",
            "countryCode": "CN",
            "cityCode": "URC",
            "cityName": "Urumqi",
            "name": "[URC] - Urumqi - URC - Urumqi - China",
            "searchName": "URC-Urumqi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YUS",
            "countryCode": "CN",
            "cityCode": "YUS",
            "cityName": "Yushu",
            "name": "[YUS] - Batang - YUS - Yushu - China",
            "searchName": "YUS-Yushu Batang-Batang-Yushu-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZAS",
            "countryCode": "CN",
            "cityCode": "ZAS",
            "cityName": "Shunde",
            "name": "[ZAS] - Shunde Ferry - ZAS - Shunde - China",
            "searchName": "ZAS-Shunde Ferry-Shunde-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NDH",
            "countryCode": "CN",
            "cityCode": "ZAS",
            "cityName": "Shunde",
            "name": "[NDH] - CZ Bus Station - ZAS - Shunde - China",
            "searchName": "NDH-ZAS-CZ Bus Station-Shunde-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZAT",
            "countryCode": "CN",
            "cityCode": "ZAT",
            "cityName": "Zhaotong",
            "name": "[ZAT] - Zhaotong - ZAT - Zhaotong - China",
            "searchName": "ZAT-Zhaotong-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZAX",
            "countryCode": "CN",
            "cityCode": "ZAX",
            "cityName": "Pan Yu",
            "name": "[ZAX] - Pan Yu Ferry - ZAX - Pan Yu - China",
            "searchName": "ZAX-Busbahnhof Ferry Port-Pan Yu Ferry-Pan Yu-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZBD",
            "countryCode": "CN",
            "cityCode": "ZBD",
            "cityName": "Jiang Men",
            "name": "[ZBD] - Jiang Men Ferry - ZBD - Jiang Men - China",
            "searchName": "ZBD-Jiang Men Ferry-Jiang Men-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "JBD",
            "countryCode": "CN",
            "cityCode": "ZBD",
            "cityName": "Jiang Men",
            "name": "[JBD] - CZ Bus Station - ZBD - Jiang Men - China",
            "searchName": "JBD-ZBD-CZ Bus Station-Jiang Men-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YZY",
            "countryCode": "CN",
            "cityCode": "YZY",
            "cityName": "Zhangye",
            "name": "[YZY] - Ganzhou - YZY - Zhangye - China",
            "searchName": "YZY-Ganzhou-Zhangye-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YIW",
            "countryCode": "CN",
            "cityCode": "YIW",
            "cityName": "Yiwu",
            "name": "[YIW] - Yiwu - YIW - Yiwu - China",
            "searchName": "YIW-Yiwu-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YIN",
            "countryCode": "CN",
            "cityCode": "YIN",
            "cityName": "Yining",
            "name": "[YIN] - Yining - YIN - Yining - China",
            "searchName": "YIN-Yining-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YIH",
            "countryCode": "CN",
            "cityCode": "YIH",
            "cityName": "Yichang",
            "name": "[YIH] - Yichang - YIH - Yichang - China",
            "searchName": "YIH-Yichang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YIE",
            "countryCode": "CN",
            "cityCode": "YIE",
            "cityName": "Aershan",
            "name": "[YIE] - Yiershi - YIE - Aershan - China",
            "searchName": "YIE-Yiershi-Arxan Airport-Aershan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YLN",
            "countryCode": "CN",
            "cityCode": "YLN",
            "cityName": "Yilan",
            "name": "[YLN] - Yilan - YLN - Yilan - China",
            "searchName": "YLN-Yilan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YNZ",
            "countryCode": "CN",
            "cityCode": "YNZ",
            "cityName": "Yancheng",
            "name": "[YNZ] - Yancheng - YNZ - Yancheng - China",
            "searchName": "YNZ-Yanchen-Yancheng-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YNJ",
            "countryCode": "CN",
            "cityCode": "YNJ",
            "cityName": "Yanji",
            "name": "[YNJ] - Yanji - YNJ - Yanji - China",
            "searchName": "YNJ-Yanji-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "YNT",
            "countryCode": "CN",
            "cityCode": "YNT",
            "cityName": "Yantai",
            "name": "[YNT] - Yantai - YNT - Yantai - China",
            "searchName": "YNT-Yantai-Laishan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZHA",
            "countryCode": "CN",
            "cityCode": "ZHA",
            "cityName": "Zhanjiang",
            "name": "[ZHA] - Zhanjiang - ZHA - Zhanjiang - China",
            "searchName": "ZHA-Zhanjiang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZHY",
            "countryCode": "CN",
            "cityCode": "ZHY",
            "cityName": "Zhongwei",
            "name": "[ZHY] - Xiangshan - ZHY - Zhongwei - China",
            "searchName": "ZHY-Zhongwei Xiangshan-Xiangshan-Zhongwei-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZGN",
            "countryCode": "CN",
            "cityCode": "ZGN",
            "cityName": "Zhongshan",
            "name": "[ZGN] - Zhongshan Ferry Port - ZGN - Zhongshan - China",
            "searchName": "ZGN-Zhongshan Ferry Port-Zhongshan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HHS",
            "countryCode": "CN",
            "cityCode": "ZGN",
            "cityName": "Zhongshan",
            "name": "[HHS] - CZ Bus Station - ZGN - Zhongshan - China",
            "searchName": "HHS-ZGN-CZ Bus Station-Zhongshan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZBZ",
            "countryCode": "CN",
            "cityCode": "ZBZ",
            "cityName": "Xin Hui",
            "name": "[ZBZ] - Xin Hui Ferry - ZBZ - Xin Hui - China",
            "searchName": "ZBZ-Xin Hui Ferry-Xin Hui-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZCA",
            "countryCode": "CN",
            "cityCode": "ZCA",
            "cityName": "Tai Shan",
            "name": "[ZCA] - Tai Shan - ZCA - Tai Shan - China",
            "searchName": "ZCA-Tai Shan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "THJ",
            "countryCode": "CN",
            "cityCode": "ZCA",
            "cityName": "Tai Shan",
            "name": "[THJ] - Taishan Bus Station - ZCA - Tai Shan - China",
            "searchName": "THJ-ZCA-Taishan Bus Station-Tai Shan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZEF",
            "countryCode": "CN",
            "cityCode": "ZEF",
            "cityName": "Nanhai",
            "name": "[ZEF] - Nanhai Ferry - ZEF - Nanhai - China",
            "searchName": "ZEF-Nanhai Ferry-Nanhai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NFF",
            "countryCode": "CN",
            "cityCode": "ZEF",
            "cityName": "Nanhai",
            "name": "[NFF] - CZ Bus Station - ZEF - Nanhai - China",
            "searchName": "NFF-ZEF-CZ Bus Station-Nanhai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZIY",
            "countryCode": "CN",
            "cityCode": "ZIY",
            "cityName": "Lianhuashan",
            "name": "[ZIY] - Lianhuashan Port - ZIY - Lianhuashan - China",
            "searchName": "ZIY-Lianhuashan Port-Lianhuashan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZUH",
            "countryCode": "CN",
            "cityCode": "ZUH",
            "cityName": "Zhuhai",
            "name": "[ZUH] - Zhuhai Airport - ZUH - Zhuhai - China",
            "searchName": "ZUH-Zhuhai-Zhuhai Airport-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZUI",
            "countryCode": "CN",
            "cityCode": "ZUH",
            "cityName": "Zhuhai",
            "name": "[ZUI] - Jiuzhou Ferry Port - ZUH - Zhuhai - China",
            "searchName": "ZUI-ZUH-Jiuzhou Ferry Port-Zhuhai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZIU",
            "countryCode": "CN",
            "cityCode": "ZUH",
            "cityName": "Zhuhai",
            "name": "[ZIU] - Railway - ZUH - Zhuhai - China",
            "searchName": "ZIU-ZUH-Railway-Zhuhai-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZYI",
            "countryCode": "CN",
            "cityCode": "ZYI",
            "cityName": "Zunyi",
            "name": "[ZYI] - Zunyi - ZYI - Zunyi - China",
            "searchName": "ZYI-Zunyi-Zunyi Havalimani-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZYK",
            "countryCode": "CN",
            "cityCode": "ZYK",
            "cityName": "Shekou",
            "name": "[ZYK] - Shekou Ferry Port - ZYK - Shekou - China",
            "searchName": "ZYK-Shekou Ferry Port-Shekou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZMY",
            "countryCode": "CN",
            "cityCode": "ZMY",
            "cityName": "Huangpu",
            "name": "[ZMY] - Harbour - ZMY - Huangpu - China",
            "searchName": "ZMY-Harbour-Huangpu-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZQZ",
            "countryCode": "CN",
            "cityCode": "ZQZ",
            "cityName": "Zhangjiakou",
            "name": "[ZQZ] - Zhangjiakou - ZQZ - Zhangjiakou - China",
            "searchName": "ZQZ-Zhangjiakou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZTI",
            "countryCode": "CN",
            "cityCode": "ZTI",
            "cityName": "Humen",
            "name": "[ZTI] - Humen Port - ZTI - Humen - China",
            "searchName": "ZTI-Humen Port-Humen-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "UNC",
            "countryCode": "CO",
            "cityCode": "UNC",
            "cityName": "Unguia",
            "name": "[UNC] - Unguia - UNC - Unguia - Colombia",
            "searchName": "UNC-Unguia-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ULQ",
            "countryCode": "CO",
            "cityCode": "ULQ",
            "cityName": "Tulua",
            "name": "[ULQ] - Farfan - ULQ - Tulua - Colombia",
            "searchName": "ULQ-Farfan-Tulua-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ULS",
            "countryCode": "CO",
            "cityCode": "ULS",
            "cityName": "Mulatos",
            "name": "[ULS] - Mulatos - ULS - Mulatos - Colombia",
            "searchName": "ULS-Mulatos-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "UIB",
            "countryCode": "CO",
            "cityCode": "UIB",
            "cityName": "Quibdo",
            "name": "[UIB] - Quibdo - UIB - Quibdo - Colombia",
            "searchName": "UIB-Quibdo-Quibd-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "VAB",
            "countryCode": "CO",
            "cityCode": "VAB",
            "cityName": "Yavarate",
            "name": "[VAB] - Yavarate - VAB - Yavarate - Colombia",
            "searchName": "VAB-Yavarate-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "URI",
            "countryCode": "CO",
            "cityCode": "URI",
            "cityName": "Uribe",
            "name": "[URI] - Uribe - URI - Uribe - Colombia",
            "searchName": "URI-Uribe-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "URR",
            "countryCode": "CO",
            "cityCode": "URR",
            "cityName": "Urrao",
            "name": "[URR] - Urrao - URR - Urrao - Colombia",
            "searchName": "URR-Urrao-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "VGZ",
            "countryCode": "CO",
            "cityCode": "VGZ",
            "cityName": "Villagarzon",
            "name": "[VGZ] - Villagarzon - VGZ - Villagarzon - Colombia",
            "searchName": "VGZ-Villagarzon-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "VUP",
            "countryCode": "CO",
            "cityCode": "VUP",
            "cityName": "Valledupar",
            "name": "[VUP] - Valledupar - VUP - Valledupar - Colombia",
            "searchName": "VUP-Valledupar-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "VVC",
            "countryCode": "CO",
            "cityCode": "VVC",
            "cityName": "Villavicencio",
            "name": "[VVC] - La Vanguardia - VVC - Villavicencio - Colombia",
            "searchName": "VVC-Villavicencio-La Vanguardia-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SJE",
            "countryCode": "CO",
            "cityCode": "SJE",
            "cityName": "San Jose Del Gua",
            "name": "[SJE] - San Jose Del Gua - SJE - San Jose Del Gua - Colombia",
            "searchName": "SJE-San Jose Del Gua-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SJG",
            "countryCode": "CO",
            "cityCode": "SJG",
            "cityName": "San Pedro Jagua",
            "name": "[SJG] - San Pedro Jagua - SJG - San Pedro Jagua - Colombia",
            "searchName": "SJG-San Pedro Jagua-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SJH",
            "countryCode": "CO",
            "cityCode": "SJH",
            "cityName": "San Juan Del Cesar",
            "name": "[SJH] - San Juan Del Cesar - SJH - San Juan Del Cesar - Colombia",
            "searchName": "SJH-San Juan Del Cesar-San Juan del Cesar-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SJR",
            "countryCode": "CO",
            "cityCode": "SJR",
            "cityName": "San Juan D Ur",
            "name": "[SJR] - San Juan D Ur - SJR - San Juan D Ur - Colombia",
            "searchName": "SJR-San Juan D Ur-San Juan de Uraba-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SCA",
            "countryCode": "CO",
            "cityCode": "SCA",
            "cityName": "Santa Catalina",
            "name": "[SCA] - Santa Catalina - SCA - Santa Catalina - Colombia",
            "searchName": "SCA-Santa Catalina-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SVI",
            "countryCode": "CO",
            "cityCode": "SVI",
            "cityName": "San Vicente",
            "name": "[SVI] - San Vicente - SVI - San Vicente - Colombia",
            "searchName": "SVI-San Vicente-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SSL",
            "countryCode": "CO",
            "cityCode": "SSL",
            "cityName": "Santa Rosalia",
            "name": "[SSL] - Santa Rosalia - SSL - Santa Rosalia - Colombia",
            "searchName": "SSL-Santa Rosalia-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SSD",
            "countryCode": "CO",
            "cityCode": "SSD",
            "cityName": "San Felipe",
            "name": "[SSD] - San Felipe - SSD - San Felipe - Colombia",
            "searchName": "SSD-San Felipe-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SRO",
            "countryCode": "CO",
            "cityCode": "SRO",
            "cityName": "Santana Ramos",
            "name": "[SRO] - Santana Ramos - SRO - Santana Ramos - Colombia",
            "searchName": "SRO-Santana Ramos-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SRS",
            "countryCode": "CO",
            "cityCode": "SRS",
            "cityName": "San Marcos",
            "name": "[SRS] - Cartagena - SRS - San Marcos - Colombia",
            "searchName": "SRS-Cartagena-San Marcos-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SMC",
            "countryCode": "CO",
            "cityCode": "SMC",
            "cityName": "Santa Maria",
            "name": "[SMC] - Santa Maria - SMC - Santa Maria - Colombia",
            "searchName": "SMC-Santa Maria-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SMR",
            "countryCode": "CO",
            "cityCode": "SMR",
            "cityName": "Santa Marta",
            "name": "[SMR] - Simon Bolivar - SMR - Santa Marta - Colombia",
            "searchName": "SMR-Santa Marta-Simon Bolivar-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SNT",
            "countryCode": "CO",
            "cityCode": "SNT",
            "cityName": "Sabana De Torres",
            "name": "[SNT] - Sabana De Torres - SNT - Sabana De Torres - Colombia",
            "searchName": "SNT-Sabana De Torres-Sabana de Torres-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SQE",
            "countryCode": "CO",
            "cityCode": "SQE",
            "cityName": "San Luis De Pale",
            "name": "[SQE] - San Luis De Pale - SQE - San Luis De Pale - Colombia",
            "searchName": "SQE-San Luis De Pale-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SQF",
            "countryCode": "CO",
            "cityCode": "SQF",
            "cityName": "Solano",
            "name": "[SQF] - Solano - SQF - Solano - Colombia",
            "searchName": "SQF-Solano-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SQB",
            "countryCode": "CO",
            "cityCode": "SQB",
            "cityName": "Santa Ana",
            "name": "[SQB] - Santa Ana - SQB - Santa Ana - Colombia",
            "searchName": "SQB-Santa Ana-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SOH",
            "countryCode": "CO",
            "cityCode": "SOH",
            "cityName": "Solita",
            "name": "[SOH] - Solita - SOH - Solita - Colombia",
            "searchName": "SOH-Solita-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SOX",
            "countryCode": "CO",
            "cityCode": "SOX",
            "cityName": "Sogamoso",
            "name": "[SOX] - Alberto Lleras Camargo - SOX - Sogamoso - Colombia",
            "searchName": "SOX-Alberto Lleras Camargo-Sogamoso-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "TLU",
            "countryCode": "CO",
            "cityCode": "TLU",
            "cityName": "Tolu",
            "name": "[TLU] - Tolu - TLU - Tolu - Colombia",
            "searchName": "TLU-Tolu-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "TME",
            "countryCode": "CO",
            "cityCode": "TME",
            "cityName": "Tame",
            "name": "[TME] - Tame - TME - Tame - Colombia",
            "searchName": "TME-Tame-Gabriel Vargas Santos Havalimani-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "TTM",
            "countryCode": "CO",
            "cityCode": "TTM",
            "cityName": "Tablon De Tamara",
            "name": "[TTM] - Tablon De Tamara - TTM - Tablon De Tamara - Colombia",
            "searchName": "TTM-Tablon De Tamara-Tablon de Tamara-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "TRB",
            "countryCode": "CO",
            "cityCode": "TRB",
            "cityName": "Turbo",
            "name": "[TRB] - Gonzalo - TRB - Turbo - Colombia",
            "searchName": "TRB-Gonzalo-Turbo-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "TQS",
            "countryCode": "CO",
            "cityCode": "TQS",
            "cityName": "Tres Esquinas",
            "name": "[TQS] - Tres Esquinas - TQS - Tres Esquinas - Colombia",
            "searchName": "TQS-Tres Esquinas-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "TAU",
            "countryCode": "CO",
            "cityCode": "TAU",
            "cityName": "Tauramena",
            "name": "[TAU] - Tauramena - TAU - Tauramena - Colombia",
            "searchName": "TAU-Tauramena-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "TBD",
            "countryCode": "CO",
            "cityCode": "TBD",
            "cityName": "Timbiqui",
            "name": "[TBD] - Timbiqui - TBD - Timbiqui - Colombia",
            "searchName": "TBD-Timbiqui-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "TCO",
            "countryCode": "CO",
            "cityCode": "TCO",
            "cityName": "Tumaco",
            "name": "[TCO] - La Florida - TCO - Tumaco - Colombia",
            "searchName": "TCO-Tumaco-La Florida-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "TDA",
            "countryCode": "CO",
            "cityCode": "TDA",
            "cityName": "Trinidad",
            "name": "[TDA] - Trinidad - TDA - Trinidad - Colombia",
            "searchName": "TDA-Trinidad-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "TCD",
            "countryCode": "CO",
            "cityCode": "TCD",
            "cityName": "Tarapaca",
            "name": "[TCD] - Tarapaca - TCD - Tarapaca - Colombia",
            "searchName": "TCD-Tarapaca-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "TIB",
            "countryCode": "CO",
            "cityCode": "TIB",
            "cityName": "Tibu",
            "name": "[TIB] - Tibu - TIB - Tibu - Colombia",
            "searchName": "TIB-Tibu-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "RVE",
            "countryCode": "CO",
            "cityCode": "RVE",
            "cityName": "Saravena",
            "name": "[RVE] - Los Colonizadores - RVE - Saravena - Colombia",
            "searchName": "RVE-Saravena-Los Colonizadores-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "RON",
            "countryCode": "CO",
            "cityCode": "RON",
            "cityName": "Rondon",
            "name": "[RON] - Rondon - RON - Rondon - Colombia",
            "searchName": "RON-Rondon-Rondon Havalimani-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "RAV",
            "countryCode": "CO",
            "cityCode": "RAV",
            "cityName": "Cravo Norte",
            "name": "[RAV] - Cravo Norte - RAV - Cravo Norte - Colombia",
            "searchName": "RAV-Cravo Norte-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "RCH",
            "countryCode": "CO",
            "cityCode": "RCH",
            "cityName": "Riohacha",
            "name": "[RCH] - Riohacha - RCH - Riohacha - Colombia",
            "searchName": "RCH-Riohacha-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PZA",
            "countryCode": "CO",
            "cityCode": "PZA",
            "cityName": "Paz De Ariporo",
            "name": "[PZA] - Casanare - PZA - Paz De Ariporo - Colombia",
            "searchName": "PZA-Casanare-Paz de Ariporo-Paz De Ariporo-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PYA",
            "countryCode": "CO",
            "cityCode": "PYA",
            "cityName": "Puerto Boyaca",
            "name": "[PYA] - Puerto Boyaca - PYA - Puerto Boyaca - Colombia",
            "searchName": "PYA-Puerto Boyaca-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PYN",
            "countryCode": "CO",
            "cityCode": "PYN",
            "cityName": "Payan",
            "name": "[PYN] - Payan - PYN - Payan - Colombia",
            "searchName": "PYN-Payan-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PUU",
            "countryCode": "CO",
            "cityCode": "PUU",
            "cityName": "Puerto Asis",
            "name": "[PUU] - Puerto Asis - PUU - Puerto Asis - Colombia",
            "searchName": "PUU-Puerto Asis-Tres de Mayo Havalimani-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PVA",
            "countryCode": "CO",
            "cityCode": "PVA",
            "cityName": "Providencia",
            "name": "[PVA] - Providencia - PVA - Providencia - Colombia",
            "searchName": "PVA-Providencia-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PSO",
            "countryCode": "CO",
            "cityCode": "PSO",
            "cityName": "Pasto",
            "name": "[PSO] - Cano - PSO - Pasto - Colombia",
            "searchName": "PSO-Pasto-Cano-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PTX",
            "countryCode": "CO",
            "cityCode": "PTX",
            "cityName": "Pitalito",
            "name": "[PTX] - Pitalito - PTX - Pitalito - Colombia",
            "searchName": "PTX-Pitalito-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PLT",
            "countryCode": "CO",
            "cityCode": "PLT",
            "cityName": "Plato",
            "name": "[PLT] - Plato - PLT - Plato - Colombia",
            "searchName": "PLT-Plato-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PLA",
            "countryCode": "CO",
            "cityCode": "PLA",
            "cityName": "Planadas",
            "name": "[PLA] - Planadas - PLA - Planadas - Colombia",
            "searchName": "PLA-Planadas-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PLC",
            "countryCode": "CO",
            "cityCode": "PLC",
            "cityName": "Planeta Rica",
            "name": "[PLC] - Planeta Rica - PLC - Planeta Rica - Colombia",
            "searchName": "PLC-Planeta Rica-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PPN",
            "countryCode": "CO",
            "cityCode": "PPN",
            "cityName": "Popayan",
            "name": "[PPN] - Machangara - PPN - Popayan - Colombia",
            "searchName": "PPN-Popayan-Machangara-Machangara Havalimani-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PRE",
            "countryCode": "CO",
            "cityCode": "PRE",
            "cityName": "Pore",
            "name": "[PRE] - Pore - PRE - Pore - Colombia",
            "searchName": "PRE-Pore-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PEI",
            "countryCode": "CO",
            "cityCode": "PEI",
            "cityName": "Pereira",
            "name": "[PEI] - Matecana - PEI - Pereira - Colombia",
            "searchName": "PEI-Pereira-Matecana-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "OTU",
            "countryCode": "CO",
            "cityCode": "OTU",
            "cityName": "Otu",
            "name": "[OTU] - Otu - OTU - Otu - Colombia",
            "searchName": "OTU-Otu-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ORC",
            "countryCode": "CO",
            "cityCode": "ORC",
            "cityName": "Orocue",
            "name": "[ORC] - Orocue - ORC - Orocue - Colombia",
            "searchName": "ORC-Orocue-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PAL",
            "countryCode": "CO",
            "cityCode": "PAL",
            "cityName": "Palanquero",
            "name": "[PAL] - Palanquero - PAL - Palanquero - Colombia",
            "searchName": "PAL-Palanquero-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PBE",
            "countryCode": "CO",
            "cityCode": "PBE",
            "cityName": "Puerto Berrio",
            "name": "[PBE] - Puerto Berrio - PBE - Puerto Berrio - Colombia",
            "searchName": "PBE-Puerto Berrio-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PCR",
            "countryCode": "CO",
            "cityCode": "PCR",
            "cityName": "Puerto Carreno",
            "name": "[PCR] - Puerto Carreno - PCR - Puerto Carreno - Colombia",
            "searchName": "PCR-Puerto Carreno-Puerto Carre-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PDA",
            "countryCode": "CO",
            "cityCode": "PDA",
            "cityName": "Puerto Inirida",
            "name": "[PDA] - Puerto Inirida - PDA - Puerto Inirida - Colombia",
            "searchName": "PDA-Puerto Inirida-Obando Havalimani-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "PCC",
            "countryCode": "CO",
            "cityCode": "PCC",
            "cityName": "Puerto Rico",
            "name": "[PCC] - Puerto Rico - PCC - Puerto Rico - Colombia",
            "searchName": "PCC-Puerto Rico-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "EOH",
            "countryCode": "CO",
            "cityCode": "MDE",
            "cityName": "Medellin",
            "name": "[EOH] - Enrique Olaya Herrera International Airport - MDE - Medellin - Colombia",
            "searchName": "EOH-MDE-Medellin Enrique Olaya Herrera-Enrique Olaya Herrera International Airport-Enrique Olaya-Enrique Olaya Herrera-Medell-Medellin-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MFB",
            "countryCode": "CO",
            "cityCode": "MFB",
            "cityName": "Monfort",
            "name": "[MFB] - Monfort - MFB - Monfort - Colombia",
            "searchName": "MFB-Monfort-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MCJ",
            "countryCode": "CO",
            "cityCode": "MCJ",
            "cityName": "Maicao",
            "name": "[MCJ] - Maicao - MCJ - Maicao - Colombia",
            "searchName": "MCJ-Maicao-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MIX",
            "countryCode": "CO",
            "cityCode": "MIX",
            "cityName": "Miriti",
            "name": "[MIX] - Miriti - MIX - Miriti - Colombia",
            "searchName": "MIX-Miriti-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MHF",
            "countryCode": "CO",
            "cityCode": "MHF",
            "cityName": "Morichal",
            "name": "[MHF] - Morichal - MHF - Morichal - Colombia",
            "searchName": "MHF-Morichal-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MGN",
            "countryCode": "CO",
            "cityCode": "MGN",
            "cityName": "Magangue",
            "name": "[MGN] - Baracoa - MGN - Magangue - Colombia",
            "searchName": "MGN-Baracoa-Magangue-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MFS",
            "countryCode": "CO",
            "cityCode": "MFS",
            "cityName": "Miraflores",
            "name": "[MFS] - Miraflores - MFS - Miraflores - Colombia",
            "searchName": "MFS-Miraflores-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MMP",
            "countryCode": "CO",
            "cityCode": "MMP",
            "cityName": "Mompos",
            "name": "[MMP] - Mompos - MMP - Mompos - Colombia",
            "searchName": "MMP-Mompos-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MND",
            "countryCode": "CO",
            "cityCode": "MND",
            "cityName": "Medina",
            "name": "[MND] - Medina - MND - Medina - Colombia",
            "searchName": "MND-Medina-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MOY",
            "countryCode": "CO",
            "cityCode": "MOY",
            "cityName": "Monterrey",
            "name": "[MOY] - Monterrey - MOY - Monterrey - Colombia",
            "searchName": "MOY-Monterrey-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MTB",
            "countryCode": "CO",
            "cityCode": "MTB",
            "cityName": "Monte Libano",
            "name": "[MTB] - Monte Libano - MTB - Monte Libano - Colombia",
            "searchName": "MTB-Monte Libano-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MTR",
            "countryCode": "CO",
            "cityCode": "MTR",
            "cityName": "Monteria",
            "name": "[MTR] - S. Jeronimo - MTR - Monteria - Colombia",
            "searchName": "MTR-Monteria-S. Jeronimo-Monter-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MQR",
            "countryCode": "CO",
            "cityCode": "MQR",
            "cityName": "Mosquera",
            "name": "[MQR] - Mosquera - MQR - Mosquera - Colombia",
            "searchName": "MQR-Mosquera-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MQU",
            "countryCode": "CO",
            "cityCode": "MQU",
            "cityName": "Mariquita",
            "name": "[MQU] - Mariquita - MQU - Mariquita - Colombia",
            "searchName": "MQU-Mariquita-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "NUH",
            "countryCode": "CO",
            "cityCode": "NUH",
            "cityName": "Nunchia",
            "name": "[NUH] - Nunchia - NUH - Nunchia - Colombia",
            "searchName": "NUH-Nunchia-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "NVA",
            "countryCode": "CO",
            "cityCode": "NVA",
            "cityName": "Neiva",
            "name": "[NVA] - La Marguita - NVA - Neiva - Colombia",
            "searchName": "NVA-Neiva-La Marguita-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "NPU",
            "countryCode": "CO",
            "cityCode": "NPU",
            "cityName": "San Pedro Uraba",
            "name": "[NPU] - San Pedro Uraba - NPU - San Pedro Uraba - Colombia",
            "searchName": "NPU-San Pedro Uraba-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "NQU",
            "countryCode": "CO",
            "cityCode": "NQU",
            "cityName": "Nuqui",
            "name": "[NQU] - Nuqui - NQU - Nuqui - Colombia",
            "searchName": "NQU-Nuqui-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "OCV",
            "countryCode": "CO",
            "cityCode": "OCV",
            "cityName": "Ocana",
            "name": "[OCV] - Aguasclaras - OCV - Ocana - Colombia",
            "searchName": "OCV-Aguasclaras-Ocana-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "NCI",
            "countryCode": "CO",
            "cityCode": "NCI",
            "cityName": "Necocli",
            "name": "[NCI] - Necocli - NCI - Necocli - Colombia",
            "searchName": "NCI-Kommunaler Flughafen-Necocli-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "NAR",
            "countryCode": "CO",
            "cityCode": "NAR",
            "cityName": "Nare",
            "name": "[NAR] - Nare - NAR - Nare - Colombia",
            "searchName": "NAR-Nare-Nare Havalimani-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "NBB",
            "countryCode": "CO",
            "cityCode": "NBB",
            "cityName": "Barrancominas",
            "name": "[NBB] - Barrancominas - NBB - Barrancominas - Colombia",
            "searchName": "NBB-Barrancominas-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "NAD",
            "countryCode": "CO",
            "cityCode": "NAD",
            "cityName": "Macanal",
            "name": "[NAD] - Macanal - NAD - Macanal - Colombia",
            "searchName": "NAD-Macanal-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MZL",
            "countryCode": "CO",
            "cityCode": "MZL",
            "cityName": "Manizales",
            "name": "[MZL] - Santaguida - MZL - Manizales - Colombia",
            "searchName": "MZL-Manizales-Santaguida-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "MVP",
            "countryCode": "CO",
            "cityCode": "MVP",
            "cityName": "Mitu",
            "name": "[MVP] - Mitu - MVP - Mitu - Colombia",
            "searchName": "MVP-Mitu-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "LQM",
            "countryCode": "CO",
            "cityCode": "LQM",
            "cityName": "Puerto Leguizamo",
            "name": "[LQM] - Puerto Leguizamo - LQM - Puerto Leguizamo - Colombia",
            "searchName": "LQM-Puerto Leguizamo-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "LRI",
            "countryCode": "CO",
            "cityCode": "LRI",
            "cityName": "Lorica",
            "name": "[LRI] - Lorica - LRI - Lorica - Colombia",
            "searchName": "LRI-Lorica-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "LMX",
            "countryCode": "CO",
            "cityCode": "LMX",
            "cityName": "Lopez De Micay",
            "name": "[LMX] - Lopez De Micay - LMX - Lopez De Micay - Colombia",
            "searchName": "LMX-Lopez De Micay-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "LMC",
            "countryCode": "CO",
            "cityCode": "LMC",
            "cityName": "Lamacarena",
            "name": "[LMC] - Lamacarena - LMC - Lamacarena - Colombia",
            "searchName": "LMC-Lamacarena-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "LPD",
            "countryCode": "CO",
            "cityCode": "LPD",
            "cityName": "La Pedrera",
            "name": "[LPD] - La Pedrera - LPD - La Pedrera - Colombia",
            "searchName": "LPD-La Pedrera-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "LPE",
            "countryCode": "CO",
            "cityCode": "LPE",
            "cityName": "La Primavera",
            "name": "[LPE] - La Primavera - LPE - La Primavera - Colombia",
            "searchName": "LPE-La Primavera-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "LAT",
            "countryCode": "CO",
            "cityCode": "LAT",
            "cityName": "La Uribe",
            "name": "[LAT] - La Uribe - LAT - La Uribe - Colombia",
            "searchName": "LAT-La Uribe-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "LCR",
            "countryCode": "CO",
            "cityCode": "LCR",
            "cityName": "La Chorrera",
            "name": "[LCR] - La Chorrera - LCR - La Chorrera - Colombia",
            "searchName": "LCR-La Chorrera-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "LGZ",
            "countryCode": "CO",
            "cityCode": "LGZ",
            "cityName": "Leguizamo",
            "name": "[LGZ] - Leguizamo - LGZ - Leguizamo - Colombia",
            "searchName": "LGZ-Leguizamo-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "LGT",
            "countryCode": "CO",
            "cityCode": "LGT",
            "cityName": "Las Gaviotas",
            "name": "[LGT] - Las Gaviotas - LGT - Las Gaviotas - Colombia",
            "searchName": "LGT-Las Gaviotas-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "LET",
            "countryCode": "CO",
            "cityCode": "LET",
            "cityName": "Leticia",
            "name": "[LET] - Gen. A.V. Cobo - LET - Leticia - Colombia",
            "searchName": "LET-Leticia-Gen. A.V. Cobo-L-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "JUO",
            "countryCode": "CO",
            "cityCode": "JUO",
            "cityName": "Jurado",
            "name": "[JUO] - Jurado - JUO - Jurado - Colombia",
            "searchName": "JUO-Jurado-Jurado Havalimani-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "HRR",
            "countryCode": "CO",
            "cityCode": "HRR",
            "cityName": "Herrera",
            "name": "[HRR] - Herrera - HRR - Herrera - Colombia",
            "searchName": "HRR-Herrera-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "HTZ",
            "countryCode": "CO",
            "cityCode": "HTZ",
            "cityName": "Hato Corozal",
            "name": "[HTZ] - Hato Corozal - HTZ - Hato Corozal - Colombia",
            "searchName": "HTZ-Hato Corozal-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "IGO",
            "countryCode": "CO",
            "cityCode": "IGO",
            "cityName": "Chigorodo",
            "name": "[IGO] - Chigorodo - IGO - Chigorodo - Colombia",
            "searchName": "IGO-Chigorodo-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "IBE",
            "countryCode": "CO",
            "cityCode": "IBE",
            "cityName": "Ibague",
            "name": "[IBE] - Ibague - IBE - Ibague - Colombia",
            "searchName": "IBE-Ibague-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "IPI",
            "countryCode": "CO",
            "cityCode": "IPI",
            "cityName": "Ipiales",
            "name": "[IPI] - San Luis - IPI - Ipiales - Colombia",
            "searchName": "IPI-San Luis-Ipiales-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ISD",
            "countryCode": "CO",
            "cityCode": "ISD",
            "cityName": "Iscuande",
            "name": "[ISD] - Iscuande - ISD - Iscuande - Colombia",
            "searchName": "ISD-Iscuande-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "IVO",
            "countryCode": "CO",
            "cityCode": "IVO",
            "cityName": "Chivolo",
            "name": "[IVO] - Chivolo - IVO - Chivolo - Colombia",
            "searchName": "IVO-Chivolo-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "GPI",
            "countryCode": "CO",
            "cityCode": "GPI",
            "cityName": "Guapi",
            "name": "[GPI] - Guapi - GPI - Guapi - Colombia",
            "searchName": "GPI-Guapi-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "GRA",
            "countryCode": "CO",
            "cityCode": "GRA",
            "cityName": "Gamarra",
            "name": "[GRA] - Gamarra - GRA - Gamarra - Colombia",
            "searchName": "GRA-Gamarra-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "GGL",
            "countryCode": "CO",
            "cityCode": "GGL",
            "cityName": "Gilgal",
            "name": "[GGL] - Gilgal - GGL - Gilgal - Colombia",
            "searchName": "GGL-Gilgal-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "GIR",
            "countryCode": "CO",
            "cityCode": "GIR",
            "cityName": "Girardot",
            "name": "[GIR] - Girardot - GIR - Girardot - Colombia",
            "searchName": "GIR-Girardot-Girardot Havalimani-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "GMC",
            "countryCode": "CO",
            "cityCode": "GMC",
            "cityName": "Guerima",
            "name": "[GMC] - Guerima - GMC - Guerima - Colombia",
            "searchName": "GMC-Guerima-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "GAA",
            "countryCode": "CO",
            "cityCode": "GAA",
            "cityName": "Guamal",
            "name": "[GAA] - Guamal - GAA - Guamal - Colombia",
            "searchName": "GAA-Guamal-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "GCA",
            "countryCode": "CO",
            "cityCode": "GCA",
            "cityName": "Guacamaya",
            "name": "[GCA] - Guacamaya - GCA - Guacamaya - Colombia",
            "searchName": "GCA-Guacamaya-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "FLA",
            "countryCode": "CO",
            "cityCode": "FLA",
            "cityName": "Florencia",
            "name": "[FLA] - Capitolio - FLA - Florencia - Colombia",
            "searchName": "FLA-Capitolio-Florencia-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CAQ",
            "countryCode": "CO",
            "cityCode": "CAQ",
            "cityName": "Caucasia",
            "name": "[CAQ] - Caucasia - CAQ - Caucasia - Colombia",
            "searchName": "CAQ-Kommunaler Flughafen-Caucasia-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CCO",
            "countryCode": "CO",
            "cityCode": "CCO",
            "cityName": "Carimagua",
            "name": "[CCO] - Carimagua - CCO - Carimagua - Colombia",
            "searchName": "CCO-Carimagua-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CIM",
            "countryCode": "CO",
            "cityCode": "CIM",
            "cityName": "Cimitarra",
            "name": "[CIM] - Cimitarra - CIM - Cimitarra - Colombia",
            "searchName": "CIM-Cimitarra-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CJD",
            "countryCode": "CO",
            "cityCode": "CJD",
            "cityName": "Candilejas",
            "name": "[CJD] - Candilejas - CJD - Candilejas - Colombia",
            "searchName": "CJD-Candilejas-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SYX",
            "countryCode": "CN",
            "cityCode": "SYX",
            "cityName": "Sanya",
            "name": "[SYX] - Fenghuang International (Phoenix International) - SYX - Sanya - China",
            "searchName": "SYX-Sanya-Fenghuang International (Phoenix International)-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "SYM",
            "countryCode": "CN",
            "cityCode": "SYM",
            "cityName": "Simao",
            "name": "[SYM] - Simao - SYM - Simao - China",
            "searchName": "SYM-Simao-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TCZ",
            "countryCode": "CN",
            "cityCode": "TCZ",
            "cityName": "Tengchong",
            "name": "[TCZ] - Tuofeng - TCZ - Tengchong - China",
            "searchName": "TCZ-Tuofeng-Tengchong Tuofeng-Tengchong Tuofeng Airport-Tengchong-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TCG",
            "countryCode": "CN",
            "cityCode": "TCG",
            "cityName": "Tacheng",
            "name": "[TCG] - Tacheng - TCG - Tacheng - China",
            "searchName": "TCG-Tacheng-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TAO",
            "countryCode": "CN",
            "cityCode": "TAO",
            "cityName": "Qingdao",
            "name": "[TAO] - Qingdao - TAO - Qingdao - China",
            "searchName": "TAO-Qingdao-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "SZV",
            "countryCode": "CN",
            "cityCode": "SZV",
            "cityName": "Suzhou",
            "name": "[SZV] - Suzhou - SZV - Suzhou - China",
            "searchName": "SZV-Suzhou-Suzhou Havalimani-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TSN",
            "countryCode": "CN",
            "cityCode": "TSN",
            "cityName": "Tianjin",
            "name": "[TSN] - Tianjin Binhai International - TSN - Tianjin - China",
            "searchName": "TSN-Tianjin-Tianjin Binhai International-Tianjn-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TJJ",
            "countryCode": "CN",
            "cityCode": "TSN",
            "cityName": "Tianjin",
            "name": "[TJJ] - Tianjin Bus Station - TSN - Tianjin - China",
            "searchName": "TJJ-TSN-Tianjin Bus Station-Tianjin-Tianjn-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TXN",
            "countryCode": "CN",
            "cityCode": "TXN",
            "cityName": "Tunxi",
            "name": "[TXN] - Tunxi - TXN - Tunxi - China",
            "searchName": "TXN-Huangshan-Tunxi-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TYN",
            "countryCode": "CN",
            "cityCode": "TYN",
            "cityName": "Taiyuan",
            "name": "[TYN] - Taiyuan - TYN - Taiyuan - China",
            "searchName": "TYN-Taiyuan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TVS",
            "countryCode": "CN",
            "cityCode": "TVS",
            "cityName": "Tangshan",
            "name": "[TVS] - Sannuhe - TVS - Tangshan - China",
            "searchName": "TVS-Sannuhe-Tangshan Sannuhe-Tangshan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TNA",
            "countryCode": "CN",
            "cityCode": "TNA",
            "cityName": "Jinan",
            "name": "[TNA] - Jinan - TNA - Jinan - China",
            "searchName": "TNA-Jinan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TLQ",
            "countryCode": "CN",
            "cityCode": "TLQ",
            "cityName": "Turpan",
            "name": "[TLQ] - Jiaohe - TLQ - Turpan - China",
            "searchName": "TLQ-Jiaohe-Turpan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "TNH",
            "countryCode": "CN",
            "cityCode": "TNH",
            "cityName": "Tonghua",
            "name": "[TNH] - Tonghua Liuhe - TNH - Tonghua - China",
            "searchName": "TNH-Liuhe-Tonghua Liuhe-Tonghua-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "SWA",
            "countryCode": "CN",
            "cityCode": "SWA",
            "cityName": "Shantou",
            "name": "[SWA] - Shantou - SWA - Shantou - China",
            "searchName": "SWA-Shantou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XIY",
            "countryCode": "CN",
            "cityCode": "SIA",
            "cityName": "Xi an",
            "name": "[XIY] - Xianyang - SIA - Xi an - China",
            "searchName": "XIY-SIA-Xian Xianyang-Xianyang-Xi an Xianyang Havalimani-Xian-Xi an-Xi An-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "SHS",
            "countryCode": "CN",
            "cityCode": "SHS",
            "cityName": "Shashi",
            "name": "[SHS] - Shashi - SHS - Shashi - China",
            "searchName": "SHS-Shashi-Shashi Havalimani-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "SHP",
            "countryCode": "CN",
            "cityCode": "SHP",
            "cityName": "Qinhuangdao",
            "name": "[SHP] - Qinhuangdao - SHP - Qinhuangdao - China",
            "searchName": "SHP-Qinhuangdao-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "FFU",
            "countryCode": "CL",
            "cityCode": "FFU",
            "cityName": "Futaleufu",
            "name": "[FFU] - Futaleufu - FFU - Futaleufu - Chile",
            "searchName": "FFU-Futaleufu-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "FRT",
            "countryCode": "CL",
            "cityCode": "FRT",
            "cityName": "Frutillar",
            "name": "[FRT] - Frutillar - FRT - Frutillar - Chile",
            "searchName": "FRT-Frutillar-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "GXQ",
            "countryCode": "CL",
            "cityCode": "GXQ",
            "cityName": "Coyhaique",
            "name": "[GXQ] - Ten. Vidal - GXQ - Coyhaique - Chile",
            "searchName": "GXQ-Teniente Vidal-Ten. Vidal-Teniente Vidal Havalimani-Coyhaique-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "KNA",
            "countryCode": "CL",
            "cityCode": "KNA",
            "cityName": "Vina del Mar",
            "name": "[KNA] - Vina del Mar - KNA - Vina del Mar - Chile",
            "searchName": "KNA-Vina del Mar-Kona-Vina Del Mar Havalimani-Vina Del Mar-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "LSC",
            "countryCode": "CL",
            "cityCode": "LSC",
            "cityName": "La Serena",
            "name": "[LSC] - La Florida - LSC - La Serena - Chile",
            "searchName": "LSC-La Serena-La Florida-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "LSQ",
            "countryCode": "CL",
            "cityCode": "LSQ",
            "cityName": "Los Angeles",
            "name": "[LSQ] - Los Angeles - LSQ - Los Angeles - Chile",
            "searchName": "LSQ-Los Angeles-Maria Dolores Havalimani-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "LOB",
            "countryCode": "CL",
            "cityCode": "LOB",
            "cityName": "Los Andes",
            "name": "[LOB] - Los Andes - LOB - Los Andes - Chile",
            "searchName": "LOB-Los Andes-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "LGR",
            "countryCode": "CL",
            "cityCode": "LGR",
            "cityName": "Cochrane",
            "name": "[LGR] - Cochrane - LGR - Cochrane - Chile",
            "searchName": "LGR-Cochrane-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "PUQ",
            "countryCode": "CL",
            "cityCode": "PUQ",
            "cityName": "Punta Arenas",
            "name": "[PUQ] - Pres Ibanez - PUQ - Punta Arenas - Chile",
            "searchName": "PUQ-Punta Arenas-Pres Ibanez-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "PUX",
            "countryCode": "CL",
            "cityCode": "PUX",
            "cityName": "Puerto Varas",
            "name": "[PUX] - Puerto Varas - PUX - Puerto Varas - Chile",
            "searchName": "PUX-Puerto Varas-Puerto Varas Havalimani-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "OVL",
            "countryCode": "CL",
            "cityCode": "OVL",
            "cityName": "Ovalle",
            "name": "[OVL] - Ovalle - OVL - Ovalle - Chile",
            "searchName": "OVL-Ovalle-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "PNT",
            "countryCode": "CL",
            "cityCode": "PNT",
            "cityName": "Puerto Natales",
            "name": "[PNT] - Teniente J. Gallardo - PNT - Puerto Natales - Chile",
            "searchName": "PNT-Teniente J Gallardo-Teniente J. Gallardo-Puerto Natales-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "PMC",
            "countryCode": "CL",
            "cityCode": "PMC",
            "cityName": "Puerto Montt",
            "name": "[PMC] - Tepual - PMC - Puerto Montt - Chile",
            "searchName": "PMC-Puerto Montt-Tepual-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "TOQ",
            "countryCode": "CL",
            "cityCode": "TOQ",
            "cityName": "Tocopilla",
            "name": "[TOQ] - Barriles - TOQ - Tocopilla - Chile",
            "searchName": "TOQ-Barriles-Tocopilla-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "TLX",
            "countryCode": "CL",
            "cityCode": "TLX",
            "cityName": "Talca",
            "name": "[TLX] - Talca - TLX - Talca - Chile",
            "searchName": "TLX-Talca-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "TTC",
            "countryCode": "CL",
            "cityCode": "TTC",
            "cityName": "Taltal",
            "name": "[TTC] - Taltal - TTC - Taltal - Chile",
            "searchName": "TTC-Taltal-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "CUO",
            "countryCode": "CO",
            "cityCode": "CUO",
            "cityName": "Caruru",
            "name": "[CUO] - Caruru - CUO - Caruru - Colombia",
            "searchName": "CUO-Caruru-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CVE",
            "countryCode": "CO",
            "cityCode": "CVE",
            "cityName": "Covenas",
            "name": "[CVE] - Covenas - CVE - Covenas - Colombia",
            "searchName": "CVE-Covenas-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CZU",
            "countryCode": "CO",
            "cityCode": "CZU",
            "cityName": "Corozal",
            "name": "[CZU] - Corozal - CZU - Corozal - Colombia",
            "searchName": "CZU-Corozal-Las Brujas Havalimani-Las Brujas-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CSR",
            "countryCode": "CO",
            "cityCode": "CSR",
            "cityName": "Casuarito",
            "name": "[CSR] - Casuarito - CSR - Casuarito - Colombia",
            "searchName": "CSR-Casuarito-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CRC",
            "countryCode": "CO",
            "cityCode": "CRC",
            "cityName": "Cartago",
            "name": "[CRC] - Cartago - CRC - Cartago - Colombia",
            "searchName": "CRC-Cartago-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CQT",
            "countryCode": "CO",
            "cityCode": "CQT",
            "cityName": "Caquetania",
            "name": "[CQT] - Caquetania - CQT - Caquetania - Colombia",
            "searchName": "CQT-Caquetania-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CPL",
            "countryCode": "CO",
            "cityCode": "CPL",
            "cityName": "Chaparral",
            "name": "[CPL] - Chaparral - CPL - Chaparral - Colombia",
            "searchName": "CPL-Chaparral-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CPB",
            "countryCode": "CO",
            "cityCode": "CPB",
            "cityName": "Capurgana",
            "name": "[CPB] - Capurgana - CPB - Capurgana - Colombia",
            "searchName": "CPB-Capurgana-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "COG",
            "countryCode": "CO",
            "cityCode": "COG",
            "cityName": "Condoto",
            "name": "[COG] - Mandinga - COG - Condoto - Colombia",
            "searchName": "COG-Mandinga-Condoto-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "EYP",
            "countryCode": "CO",
            "cityCode": "EYP",
            "cityName": "El Yopal",
            "name": "[EYP] - El Yopal - EYP - El Yopal - Colombia",
            "searchName": "EYP-El Yopal-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "EUO",
            "countryCode": "CO",
            "cityCode": "EUO",
            "cityName": "Paratebueno",
            "name": "[EUO] - Paratebueno - EUO - Paratebueno - Colombia",
            "searchName": "EUO-Paratebueno-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ELJ",
            "countryCode": "CO",
            "cityCode": "ELJ",
            "cityName": "El Recreo",
            "name": "[ELJ] - El Recreo - ELJ - El Recreo - Colombia",
            "searchName": "ELJ-El Recreo-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ELB",
            "countryCode": "CO",
            "cityCode": "ELB",
            "cityName": "El Banco",
            "name": "[ELB] - San Bernado - ELB - El Banco - Colombia",
            "searchName": "ELB-San Bernado-El Banco-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "EJA",
            "countryCode": "CO",
            "cityCode": "EJA",
            "cityName": "Barrancabermeja",
            "name": "[EJA] - Variguies - EJA - Barrancabermeja - Colombia",
            "searchName": "EJA-Barrancabermeja-Variguies-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ECO",
            "countryCode": "CO",
            "cityCode": "ECO",
            "cityName": "El Encanto",
            "name": "[ECO] - El Encanto - ECO - El Encanto - Colombia",
            "searchName": "ECO-El Encanto-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ECR",
            "countryCode": "CO",
            "cityCode": "ECR",
            "cityName": "El Charco",
            "name": "[ECR] - El Charco - ECR - El Charco - Colombia",
            "searchName": "ECR-El Charco-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "EBG",
            "countryCode": "CO",
            "cityCode": "EBG",
            "cityName": "El Bagre",
            "name": "[EBG] - El Bagre - EBG - El Bagre - Colombia",
            "searchName": "EBG-El Bagre-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "DZI",
            "countryCode": "CO",
            "cityCode": "DZI",
            "cityName": "Codazzi",
            "name": "[DZI] - Codazzi - DZI - Codazzi - Colombia",
            "searchName": "DZI-Codazzi-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ACR",
            "countryCode": "CO",
            "cityCode": "ACR",
            "cityName": "Araracuara",
            "name": "[ACR] - Araracuara - ACR - Araracuara - Colombia",
            "searchName": "ACR-Araracuara-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ADN",
            "countryCode": "CO",
            "cityCode": "ADN",
            "cityName": "Andes",
            "name": "[ADN] - Andes - ADN - Andes - Colombia",
            "searchName": "ADN-Andes-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ADZ",
            "countryCode": "CO",
            "cityCode": "ADZ",
            "cityName": "San Andres Island",
            "name": "[ADZ] - San Andres Island - ADZ - San Andres Island - Colombia",
            "searchName": "ADZ-San Andres Island--Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "AFI",
            "countryCode": "CO",
            "cityCode": "AFI",
            "cityName": "Amalfi",
            "name": "[AFI] - Amalfi - AFI - Amalfi - Colombia",
            "searchName": "AFI-Amalfi-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ACD",
            "countryCode": "CO",
            "cityCode": "ACD",
            "cityName": "Acandi",
            "name": "[ACD] - Acandi - ACD - Acandi - Colombia",
            "searchName": "ACD-Kommunaler Flughafen-Acandi-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ACL",
            "countryCode": "CO",
            "cityCode": "ACL",
            "cityName": "Aguaclara",
            "name": "[ACL] - Aguaclara - ACL - Aguaclara - Colombia",
            "searchName": "ACL-Aguaclara-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ACM",
            "countryCode": "CO",
            "cityCode": "ACM",
            "cityName": "Arica",
            "name": "[ACM] - Arica - ACM - Arica - Colombia",
            "searchName": "ACM-Arica-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ARF",
            "countryCode": "CO",
            "cityCode": "ARF",
            "cityName": "Acaricuara",
            "name": "[ARF] - Acaricuara - ARF - Acaricuara - Colombia",
            "searchName": "ARF-Acaricuara-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ARO",
            "countryCode": "CO",
            "cityCode": "ARO",
            "cityName": "Arboletas",
            "name": "[ARO] - Arboletas - ARO - Arboletas - Colombia",
            "searchName": "ARO-Arboletas-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "ARQ",
            "countryCode": "CO",
            "cityCode": "ARQ",
            "cityName": "Arauquita",
            "name": "[ARQ] - Arauquita - ARQ - Arauquita - Colombia",
            "searchName": "ARQ-Arauquita-El Troncal-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "APO",
            "countryCode": "CO",
            "cityCode": "APO",
            "cityName": "Apartado",
            "name": "[APO] - Apartado - APO - Apartado - Colombia",
            "searchName": "APO-Apartado-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "API",
            "countryCode": "CO",
            "cityCode": "API",
            "cityName": "Apiay",
            "name": "[API] - Apiay - API - Apiay - Colombia",
            "searchName": "API-Apiay-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "AUC",
            "countryCode": "CO",
            "cityCode": "AUC",
            "cityName": "Arauca",
            "name": "[AUC] - Arauca - AUC - Arauca - Colombia",
            "searchName": "AUC-Arauca-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "AYG",
            "countryCode": "CO",
            "cityCode": "AYG",
            "cityName": "Yaguara",
            "name": "[AYG] - Yaguara - AYG - Yaguara - Colombia",
            "searchName": "AYG-Yaguara-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "AYI",
            "countryCode": "CO",
            "cityCode": "AYI",
            "cityName": "Yari",
            "name": "[AYI] - Yari - AYI - Yari - Colombia",
            "searchName": "AYI-Yari-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "AYA",
            "countryCode": "CO",
            "cityCode": "AYA",
            "cityName": "Ayapel",
            "name": "[AYA] - Ayapel - AYA - Ayapel - Colombia",
            "searchName": "AYA-Ayapel-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "AYC",
            "countryCode": "CO",
            "cityCode": "AYC",
            "cityName": "Ayacucho",
            "name": "[AYC] - Ayacucho - AYC - Ayacucho - Colombia",
            "searchName": "AYC-Ayacucho-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "AXM",
            "countryCode": "CO",
            "cityCode": "AXM",
            "cityName": "Armenia",
            "name": "[AXM] - El Eden - AXM - Armenia - Colombia",
            "searchName": "AXM-Armenia-El Eden-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "BAQ",
            "countryCode": "CO",
            "cityCode": "BAQ",
            "cityName": "Barranquilla",
            "name": "[BAQ] - E Cortissoz - BAQ - Barranquilla - Colombia",
            "searchName": "BAQ-Barranquilla-E Cortissoz-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "AZT",
            "countryCode": "CO",
            "cityCode": "AZT",
            "cityName": "Zapatoca",
            "name": "[AZT] - Zapatoca - AZT - Zapatoca - Colombia",
            "searchName": "AZT-Zapatoca-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "BAC",
            "countryCode": "CO",
            "cityCode": "BAC",
            "cityName": "Barranca De Upia",
            "name": "[BAC] - Barranca De Upia - BAC - Barranca De Upia - Colombia",
            "searchName": "BAC-Barranca De Upia-Barranca de Upia-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "BGA",
            "countryCode": "CO",
            "cityCode": "BGA",
            "cityName": "Bucaramanga",
            "name": "[BGA] - Palo Negro - BGA - Bucaramanga - Colombia",
            "searchName": "BGA-Bucaramanga-Palo Negro-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "BHF",
            "countryCode": "CO",
            "cityCode": "BHF",
            "cityName": "Bahia Cupica",
            "name": "[BHF] - Bahia Cupica - BHF - Bahia Cupica - Colombia",
            "searchName": "BHF-Bahia Cupica-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "BSC",
            "countryCode": "CO",
            "cityCode": "BSC",
            "cityName": "Bahia Solano",
            "name": "[BSC] - Bahia Solano - BSC - Bahia Solano - Colombia",
            "searchName": "BSC-Bahia Solano-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "FOC",
            "countryCode": "CN",
            "cityCode": "FOC",
            "cityName": "Fuzhou",
            "name": "[FOC] - Fuzhou - FOC - Fuzhou - China",
            "searchName": "FOC-Fuzhou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "FUG",
            "countryCode": "CN",
            "cityCode": "FUG",
            "cityName": "Fuyang",
            "name": "[FUG] - Fuyang - FUG - Fuyang - China",
            "searchName": "FUG-Fuyang-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "FUO",
            "countryCode": "CN",
            "cityCode": "FUO",
            "cityName": "Fuoshan",
            "name": "[FUO] - Fuoshan - FUO - Fuoshan - China",
            "searchName": "FUO-Fuoshan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "FSA",
            "countryCode": "CN",
            "cityCode": "FUO",
            "cityName": "Fuoshan",
            "name": "[FSA] - CZ Bus Station - FUO - Fuoshan - China",
            "searchName": "FSA-FUO-CZ Bus Station-Fuoshan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZCP",
            "countryCode": "CN",
            "cityCode": "FUO",
            "cityName": "Fuoshan",
            "name": "[ZCP] - Foshan Ferry - FUO - Fuoshan - China",
            "searchName": "ZCP-FUO-Foshan Ferry-Fuoshan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "GOQ",
            "countryCode": "CN",
            "cityCode": "GOQ",
            "cityName": "Golmud",
            "name": "[GOQ] - Golmud - GOQ - Golmud - China",
            "searchName": "GOQ-Germu-Golmud-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "GHN",
            "countryCode": "CN",
            "cityCode": "GHN",
            "cityName": "Guanghan",
            "name": "[GHN] - Guanghan - GHN - Guanghan - China",
            "searchName": "GHN-Guanghan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "GYS",
            "countryCode": "CN",
            "cityCode": "GYS",
            "cityName": "Guang Yuan",
            "name": "[GYS] - Guang Yuan - GYS - Guang Yuan - China",
            "searchName": "GYS-Guang Yuan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "GYU",
            "countryCode": "CN",
            "cityCode": "GYU",
            "cityName": "Guyuan",
            "name": "[GYU] - Liupanshan - GYU - Guyuan - China",
            "searchName": "GYU-Liupanshan-Guyuan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HAK",
            "countryCode": "CN",
            "cityCode": "HAK",
            "cityName": "Haikou",
            "name": "[HAK] - Haikou - HAK - Haikou - China",
            "searchName": "HAK-Haikou-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "IQM",
            "countryCode": "CN",
            "cityCode": "IQM",
            "cityName": "Qiemo",
            "name": "[IQM] - Qiemo - IQM - Qiemo - China",
            "searchName": "IQM-Qiemo-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "IQN",
            "countryCode": "CN",
            "cityCode": "IQN",
            "cityName": "Qingyang",
            "name": "[IQN] - Qingyang - IQN - Qingyang - China",
            "searchName": "IQN-Qingyang-Qingyang Havalimani-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "INC",
            "countryCode": "CN",
            "cityCode": "INC",
            "cityName": "Yinchuan",
            "name": "[INC] - Yinchuan - INC - Yinchuan - China",
            "searchName": "INC-Yinchuan-China-Kina-Chine-Cina-Kiina-Kinija-Hiina-Kitajska-Chiny-Cína--Çin Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "UPL",
            "countryCode": "CR",
            "cityCode": "UPL",
            "cityName": "Upala",
            "name": "[UPL] - Upala - UPL - Upala - Costa Rica",
            "searchName": "UPL-Upala-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "XQP",
            "countryCode": "CR",
            "cityCode": "XQP",
            "cityName": "Pavones",
            "name": "[XQP] - Quepos - XQP - Pavones - Costa Rica",
            "searchName": "XQP-Quepos-Pavones-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "UPA",
            "countryCode": "CU",
            "cityCode": "UPA",
            "cityName": "Punta Alegre",
            "name": "[UPA] - Punta Alegre - UPA - Punta Alegre - Cuba",
            "searchName": "UPA-Punta Alegre-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "UPB",
            "countryCode": "CU",
            "cityCode": "UPB",
            "cityName": "Havana",
            "name": "[UPB] - Playa Baracoa - UPB - Havana - Cuba",
            "searchName": "UPB-Playa Baracoa-Havana-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "UMA",
            "countryCode": "CU",
            "cityCode": "UMA",
            "cityName": "Punta De Maisi",
            "name": "[UMA] - Punta De Maisi - UMA - Punta De Maisi - Cuba",
            "searchName": "UMA-Punta De Maisi-Punta de Maisi-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "USS",
            "countryCode": "CU",
            "cityCode": "USS",
            "cityName": "Sancti Spiritus",
            "name": "[USS] - Sancti Spiritus - USS - Sancti Spiritus - Cuba",
            "searchName": "USS-Sancti Spiritus-Sancti Spiritus Havalimani-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "VTU",
            "countryCode": "CU",
            "cityCode": "VTU",
            "cityName": "Las Tunas",
            "name": "[VTU] - Las Tunas - VTU - Las Tunas - Cuba",
            "searchName": "VTU-Las Tunas-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "BUN",
            "countryCode": "CO",
            "cityCode": "BUN",
            "cityName": "Buenaventura",
            "name": "[BUN] - Buenaventura - BUN - Buenaventura - Colombia",
            "searchName": "BUN-Buenaventura-Gerardo Tobar López Havalimani-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "BCL",
            "countryCode": "CR",
            "cityCode": "BCL",
            "cityName": "Barra Colorado",
            "name": "[BCL] - Barra Colorado - BCL - Barra Colorado - Costa Rica",
            "searchName": "BCL-Barra Colorado-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "BAI",
            "countryCode": "CR",
            "cityCode": "BAI",
            "cityName": "Buenos Aires",
            "name": "[BAI] - Buenos Aires - BAI - Buenos Aires - Costa Rica",
            "searchName": "BAI-Buenos Aires-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "DRK",
            "countryCode": "CR",
            "cityCode": "DRK",
            "cityName": "Drake Bay",
            "name": "[DRK] - Drake Bay - DRK - Drake Bay - Costa Rica",
            "searchName": "DRK-Drake Bay-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "CSC",
            "countryCode": "CR",
            "cityCode": "CSC",
            "cityName": "Canas",
            "name": "[CSC] - Canas - CSC - Canas - Costa Rica",
            "searchName": "CSC-Canas-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "FON",
            "countryCode": "CR",
            "cityCode": "FON",
            "cityName": "Fortuna",
            "name": "[FON] - Fortuna - FON - Fortuna - Costa Rica",
            "searchName": "FON-Fortuna-La Fortuna-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "FMG",
            "countryCode": "CR",
            "cityCode": "FMG",
            "cityName": "Flamingo",
            "name": "[FMG] - Flamingo - FMG - Flamingo - Costa Rica",
            "searchName": "FMG-Flamingo-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "GLF",
            "countryCode": "CR",
            "cityCode": "GLF",
            "cityName": "Golfito",
            "name": "[GLF] - Golfito - GLF - Golfito - Costa Rica",
            "searchName": "GLF-Golfito-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "GPL",
            "countryCode": "CR",
            "cityCode": "GPL",
            "cityName": "Guapiles",
            "name": "[GPL] - Guapiles - GPL - Guapiles - Costa Rica",
            "searchName": "GPL-Guapiles-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "JAP",
            "countryCode": "CR",
            "cityCode": "JAP",
            "cityName": "Punta Renes",
            "name": "[JAP] - Punta Renes - JAP - Punta Renes - Costa Rica",
            "searchName": "JAP-Punta Renes-Punta Renes Havalimani-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "LIR",
            "countryCode": "CR",
            "cityCode": "LIR",
            "cityName": "Liberia",
            "name": "[LIR] - Daniel Oduber International - LIR - Liberia - Costa Rica",
            "searchName": "LIR-Rica Liberia-Daniel Oduber International-Liberia-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "LCS",
            "countryCode": "CR",
            "cityCode": "LCS",
            "cityName": "Las Canas",
            "name": "[LCS] - Las Canas - LCS - Las Canas - Costa Rica",
            "searchName": "LCS-Las Canas-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "LIO",
            "countryCode": "CR",
            "cityCode": "LIO",
            "cityName": "Limon",
            "name": "[LIO] - Limon International Airport - LIO - Limon - Costa Rica",
            "searchName": "LIO-Limon International Airport-Limon Havalimani-Limon-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "LSL",
            "countryCode": "CR",
            "cityCode": "LSL",
            "cityName": "Los Chiles",
            "name": "[LSL] - Los Chiles - LSL - Los Chiles - Costa Rica",
            "searchName": "LSL-Los Chiles-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "NCT",
            "countryCode": "CR",
            "cityCode": "NCT",
            "cityName": "Nicoya",
            "name": "[NCT] - Guanacaste - NCT - Nicoya - Costa Rica",
            "searchName": "NCT-Guanacaste-Nicoya-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "NOB",
            "countryCode": "CR",
            "cityCode": "NOB",
            "cityName": "Nosara Beach",
            "name": "[NOB] - Nosara Beach - NOB - Nosara Beach - Costa Rica",
            "searchName": "NOB-Nosara Beach-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "PBP",
            "countryCode": "CR",
            "cityCode": "PBP",
            "cityName": "Punta Islita",
            "name": "[PBP] - Punta Islita - PBP - Punta Islita - Costa Rica",
            "searchName": "PBP-Punta Islita-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "OTR",
            "countryCode": "CR",
            "cityCode": "OTR",
            "cityName": "Coto 47",
            "name": "[OTR] - Coto 47 - OTR - Coto 47 - Costa Rica",
            "searchName": "OTR-Coto 47-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "PJM",
            "countryCode": "CR",
            "cityCode": "PJM",
            "cityName": "Puerto Jimenez",
            "name": "[PJM] - Puerto Jimenez - PJM - Puerto Jimenez - Costa Rica",
            "searchName": "PJM-Puerto Jimenez-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "PLD",
            "countryCode": "CR",
            "cityCode": "PLD",
            "cityName": "Playa Samara",
            "name": "[PLD] - Playa Samara - PLD - Playa Samara - Costa Rica",
            "searchName": "PLD-Playa Samara-Playa Samara Havalimani-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "PMZ",
            "countryCode": "CR",
            "cityCode": "PMZ",
            "cityName": "Palmar",
            "name": "[PMZ] - Palmar Sur - PMZ - Palmar - Costa Rica",
            "searchName": "PMZ-Palmar Sur-Palmar-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "RIK",
            "countryCode": "CR",
            "cityCode": "RIK",
            "cityName": "Carrillo",
            "name": "[RIK] - Carrillo - RIK - Carrillo - Costa Rica",
            "searchName": "RIK-Carrillo-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "RFR",
            "countryCode": "CR",
            "cityCode": "RFR",
            "cityName": "Rio Frio",
            "name": "[RFR] - Rio Frio - RFR - Rio Frio - Costa Rica",
            "searchName": "RFR-Rio Frio-Rio Frio Havalimani-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "SYQ",
            "countryCode": "CR",
            "cityCode": "SYQ",
            "cityName": "San Jose",
            "name": "[SYQ] - Tobias BolaÃ±os International - SYQ - San Jose - Costa Rica",
            "searchName": "SYQ-San Jose Pavas Costa Rica-Tobias Bolaños International-Tobias Bolanos Intl-Tobias Bolanos Int l-Tobias BolaÃ±os International-San Jos-San Jose-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "SZC",
            "countryCode": "CR",
            "cityCode": "SZC",
            "cityName": "Santa Cruz",
            "name": "[SZC] - Guanacaste - SZC - Santa Cruz - Costa Rica",
            "searchName": "SZC-Guanacaste-Guanacaste Havalimani-Santa Cruz-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "TTQ",
            "countryCode": "CR",
            "cityCode": "TTQ",
            "cityName": "Tortuquero",
            "name": "[TTQ] - Tortuquero - TTQ - Tortuquero - Costa Rica",
            "searchName": "TTQ-Tortuquero-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "TMU",
            "countryCode": "CR",
            "cityCode": "TMU",
            "cityName": "Tambor",
            "name": "[TMU] - Tambor - TMU - Tambor - Costa Rica",
            "searchName": "TMU-Tambor-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "TNO",
            "countryCode": "CR",
            "cityCode": "TNO",
            "cityName": "Tamarindo",
            "name": "[TNO] - Tamarindo - TNO - Tamarindo - Costa Rica",
            "searchName": "TNO-Tamarindo-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "TOO",
            "countryCode": "CR",
            "cityCode": "TOO",
            "cityName": "San Vito",
            "name": "[TOO] - San Vito - TOO - San Vito - Costa Rica",
            "searchName": "TOO-San Vito-Costa Rica-Kosta Rika-Kostarika-Kostaryka-Kost",
            "priority": 0
        },
        {
            "iataCode": "XJV",
            "countryCode": "CZ",
            "cityCode": "OSR",
            "cityName": "Ostrava",
            "name": "[XJV] - Ostrava Main Railway Station - OSR - Ostrava - Czech Republic",
            "searchName": "XJV-OSR-Ostrava Main Railway Station-Ostrava-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "OLO",
            "countryCode": "CZ",
            "cityCode": "OLO",
            "cityName": "Olomouc",
            "name": "[OLO] - Olomouc - OLO - Olomouc - Czech Republic",
            "searchName": "OLO-Olomouc-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "XJC",
            "countryCode": "CZ",
            "cityCode": "OLO",
            "cityName": "Olomouc",
            "name": "[XJC] - Olomouc Main Railway Station - OLO - Olomouc - Czech Republic",
            "searchName": "XJC-OLO-Olomouc Main Railway Station-Olomouc-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "PRV",
            "countryCode": "CZ",
            "cityCode": "PRV",
            "cityName": "Prerov",
            "name": "[PRV] - Prerov - PRV - Prerov - Czech Republic",
            "searchName": "PRV-Prerov-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "MKA",
            "countryCode": "CZ",
            "cityCode": "MKA",
            "cityName": "Marianske Lazne",
            "name": "[MKA] - Marianske Lazne - MKA - Marianske Lazne - Czech Republic",
            "searchName": "MKA-Marianske Lazne-Marianske Lazne Havalimani-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "UHE",
            "countryCode": "CZ",
            "cityCode": "UHE",
            "cityName": "Uherske Hradiste",
            "name": "[UHE] - Kunovice - UHE - Uherske Hradiste - Czech Republic",
            "searchName": "UHE-Kunovice-Uherske Hradiste Kunovice Havalimani-Uherske Hradiste-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "ZBE",
            "countryCode": "CZ",
            "cityCode": "ZBE",
            "cityName": "Zabreh",
            "name": "[ZBE] - Dolni Benesov - ZBE - Zabreh - Czech Republic",
            "searchName": "ZBE-Dolni Benesov-Zabreh-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "ZAU",
            "countryCode": "DE",
            "cityCode": "ZAU",
            "cityName": "Aue",
            "name": "[ZAU] - Sachsen Railway - ZAU - Aue - Germany",
            "searchName": "ZAU-Sachsen Railway-Aue-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZSU",
            "countryCode": "DE",
            "cityCode": "ZSU",
            "cityName": "Dessau",
            "name": "[ZSU] - Dessau Railway - ZSU - Dessau - Germany",
            "searchName": "ZSU-Dessau Railway-Dessau-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZSQ",
            "countryCode": "DE",
            "cityCode": "ZSQ",
            "cityName": "Salzwedel",
            "name": "[ZSQ] - Salzwedel Railway Station - ZSQ - Salzwedel - Germany",
            "searchName": "ZSQ-Salzwedel Railway Station-Salzwedel-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZSK",
            "countryCode": "DE",
            "cityCode": "ZSK",
            "cityName": "Pasewalk",
            "name": "[ZSK] - Pasewalk Railway Station - ZSK - Pasewalk - Germany",
            "searchName": "ZSK-Pasewalk Railway Station-Pasewalk-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZSN",
            "countryCode": "DE",
            "cityCode": "ZSN",
            "cityName": "Stendal",
            "name": "[ZSN] - Stendal Railway Station - ZSN - Stendal - Germany",
            "searchName": "ZSN-Stendal Railway Station-Stendal-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZSO",
            "countryCode": "DE",
            "cityCode": "ZSO",
            "cityName": "Suhl",
            "name": "[ZSO] - Suhl Railway Station - ZSO - Suhl - Germany",
            "searchName": "ZSO-Suhl Railway Station-Suhl-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZRW",
            "countryCode": "DE",
            "cityCode": "ZRW",
            "cityName": "Rastatt",
            "name": "[ZRW] - Rastatt Bus Station - ZRW - Rastatt - Germany",
            "searchName": "ZRW-Rastatt Bus Station-Rastatt-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZSC",
            "countryCode": "DE",
            "cityCode": "ZSC",
            "cityName": "Schoena",
            "name": "[ZSC] - Schoena Railway Station - ZSC - Schoena - Germany",
            "searchName": "ZSC-Schoena Railway Station-Schoena-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZSD",
            "countryCode": "DE",
            "cityCode": "ZSD",
            "cityName": "Schwanheide",
            "name": "[ZSD] - Schwanheide Railway Station - ZSD - Schwanheide - Germany",
            "searchName": "ZSD-Schwanheide Railway Station-Schwanheide-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZSG",
            "countryCode": "DE",
            "cityCode": "ZSG",
            "cityName": "Sonneberg",
            "name": "[ZSG] - Sonneberg Hbf Railway Station - ZSG - Sonneberg - Germany",
            "searchName": "ZSG-Sonneberg Hbf Railway Station-Sonneberg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZSI",
            "countryCode": "DE",
            "cityCode": "ZSI",
            "cityName": "Sassnitz",
            "name": "[ZSI] - Sassnitz Railway Station - ZSI - Sassnitz - Germany",
            "searchName": "ZSI-Sassnitz Railway Station-Sassnitz-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZQV",
            "countryCode": "DE",
            "cityCode": "ZQV",
            "cityName": "Worms",
            "name": "[ZQV] - Worms Hbf Railway Station - ZQV - Worms - Germany",
            "searchName": "ZQV-Worms Hbf Railway Station-Worms-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZQY",
            "countryCode": "DE",
            "cityCode": "ZQY",
            "cityName": "Giessen",
            "name": "[ZQY] - Giessen Bus Station - ZQY - Giessen - Germany",
            "searchName": "ZQY-Giessen Bus Station-Giessen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZOY",
            "countryCode": "DE",
            "cityCode": "ZOY",
            "cityName": "Oberhausen",
            "name": "[ZOY] - Oberhausen Hbf Railway Station - ZOY - Oberhausen - Germany",
            "searchName": "ZOY-Oberhausen Hbf Railway Station-Oberhausen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZPA",
            "countryCode": "DE",
            "cityCode": "ZPA",
            "cityName": "Offenburg",
            "name": "[ZPA] - Offenburg Railway Station - ZPA - Offenburg - Germany",
            "searchName": "ZPA-Offenburg Railway Station-Offenburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZPL",
            "countryCode": "DE",
            "cityCode": "ZPL",
            "cityName": "Recklinghausen",
            "name": "[ZPL] - Recklinghausen Hbf Railway Station - ZPL - Recklinghausen - Germany",
            "searchName": "ZPL-Recklinghausen Hbf Railway Station-Recklinghausen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZPM",
            "countryCode": "DE",
            "cityCode": "ZPM",
            "cityName": "Regensburg",
            "name": "[ZPM] - Regensburg Hbf Railway Station - ZPM - Regensburg - Germany",
            "searchName": "ZPM-Regensburg Hbf Railway Station-Regensburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZPY",
            "countryCode": "DE",
            "cityCode": "ZPY",
            "cityName": "Siegburg",
            "name": "[ZPY] - Siegburg Railway Station - ZPY - Siegburg - Germany",
            "searchName": "ZPY-Siegburg Railway Station-Siegburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZQF",
            "countryCode": "DE",
            "cityCode": "ZQF",
            "cityName": "Trier",
            "name": "[ZQF] - Fohren - ZQF - Trier - Germany",
            "searchName": "ZQF-Bahnhof-Fohren-Trier Havalimani-Trier-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZNB",
            "countryCode": "DE",
            "cityCode": "ZNB",
            "cityName": "Hamm",
            "name": "[ZNB] - Hamm Railway Station - ZNB - Hamm - Germany",
            "searchName": "ZNB-Bahnhof-Hamm Railway Station-Hamm-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZNV",
            "countryCode": "DE",
            "cityCode": "ZNV",
            "cityName": "Koblenz",
            "name": "[ZNV] - Koblenz Bus Station - ZNV - Koblenz - Germany",
            "searchName": "ZNV-Koblenz Bus Station-Koblenz-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZNW",
            "countryCode": "DE",
            "cityCode": "ZNW",
            "cityName": "Limburg",
            "name": "[ZNW] - Limburg Railway Station - ZNW - Limburg - Germany",
            "searchName": "ZNW-Limburg Railway Station-Limburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZPD",
            "countryCode": "DE",
            "cityCode": "ZPD",
            "cityName": "Oldenburg",
            "name": "[ZPD] - Oldenburg Railway Station - ZPD - Oldenburg - Germany",
            "searchName": "ZPD-Oldenburg Railway Station-Oldenburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZPF",
            "countryCode": "DE",
            "cityCode": "ZPF",
            "cityName": "Passau",
            "name": "[ZPF] - Passau Hbf Railway Station - ZPF - Passau - Germany",
            "searchName": "ZPF-Passau Hbf Railway Station-Passau-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZOG",
            "countryCode": "DE",
            "cityCode": "ZOG",
            "cityName": "Lueneburg",
            "name": "[ZOG] - Lueneburg Railway Station - ZOG - Lueneburg - Germany",
            "searchName": "ZOG-Lueneburg Railway Station-Lueneburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZOM",
            "countryCode": "DE",
            "cityCode": "ZOM",
            "cityName": "Minden",
            "name": "[ZOM] - Minden Railway Station - ZOM - Minden - Germany",
            "searchName": "ZOM-Minden Railway Station-Minden-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZOO",
            "countryCode": "DE",
            "cityCode": "ZOO",
            "cityName": "Muelheim an der Ruhr",
            "name": "[ZOO] - Muelheim an der Ruhr Hbf Railway Station - ZOO - Muelheim an der Ruhr - Germany",
            "searchName": "ZOO-Muelheim an der Ruhr Hbf Railway Station-Muelheim an der Ruhr-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZTZ",
            "countryCode": "DE",
            "cityCode": "ZTZ",
            "cityName": "Chemnitz",
            "name": "[ZTZ] - Chemnitz Railway - ZTZ - Chemnitz - Germany",
            "searchName": "ZTZ-Chemnitz Railway-Chemnitz-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZWM",
            "countryCode": "DE",
            "cityCode": "ZWM",
            "cityName": "Wismar",
            "name": "[ZWM] - Wismar Railway Station - ZWM - Wismar - Germany",
            "searchName": "ZWM-Wismar Railway Station-Wismar-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZWN",
            "countryCode": "DE",
            "cityCode": "ZWN",
            "cityName": "Wittenberge",
            "name": "[ZWN] - Wittenberge Railway Station - ZWN - Wittenberge - Germany",
            "searchName": "ZWN-Wittenberge Railway Station-Wittenberge-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZWD",
            "countryCode": "DE",
            "cityCode": "ZWD",
            "cityName": "Warnemuende",
            "name": "[ZWD] - Warnemuende Railway Station - ZWD - Warnemuende - Germany",
            "searchName": "ZWD-Warnemuende Railway Station-Warnemuende-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZWT",
            "countryCode": "DE",
            "cityCode": "ZWT",
            "cityName": "Lutherstadt Wittenberg",
            "name": "[ZWT] - Lutherstadt Wittenberg Railway Station - ZWT - Lutherstadt Wittenberg - Germany",
            "searchName": "ZWT-Lutherstadt Wittenberg Railway Station-Lutherstadt Wittenberg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZIO",
            "countryCode": "DE",
            "cityCode": "ZIO",
            "cityName": "Solingen",
            "name": "[ZIO] - Solingen Hbf Railway Station - ZIO - Solingen - Germany",
            "searchName": "ZIO-Solingen Hbf Railway Station-Solingen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZIT",
            "countryCode": "DE",
            "cityCode": "ZIT",
            "cityName": "Zittau",
            "name": "[ZIT] - Zittau Railway Station - ZIT - Zittau - Germany",
            "searchName": "ZIT-Zittau Railway Station-Zittau-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZJS",
            "countryCode": "DE",
            "cityCode": "ZJS",
            "cityName": "Jena",
            "name": "[ZJS] - Jena Railway - ZJS - Jena - Germany",
            "searchName": "ZJS-Jena Railway-Jena-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZLU",
            "countryCode": "DE",
            "cityCode": "ZLU",
            "cityName": "Ludwigslust",
            "name": "[ZLU] - Ludwigslust Railway Station - ZLU - Ludwigslust - Germany",
            "searchName": "ZLU-Ludwigslust Railway Station-Ludwigslust-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZCB",
            "countryCode": "DE",
            "cityCode": "ZCB",
            "cityName": "Aschaffenburg",
            "name": "[ZCB] - Aschaffenburg Hbf Railway Station - ZCB - Aschaffenburg - Germany",
            "searchName": "ZCB-Aschaffenburg Hbf Railway Station-Aschaffenburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZEI",
            "countryCode": "DE",
            "cityCode": "ZEI",
            "cityName": "Garmisch-Partenkirchen",
            "name": "[ZEI] - Garmisch-Partenkirchen Railway Station - ZEI - Garmisch-Partenkirchen - Germany",
            "searchName": "ZEI-Garmisch-Partenkirchen Railway Station-Partenkirchen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZEJ",
            "countryCode": "DE",
            "cityCode": "ZEJ",
            "cityName": "Gelsenkirchen",
            "name": "[ZEJ] - Gelsenkirchen Hbf Railway Station - ZEJ - Gelsenkirchen - Germany",
            "searchName": "ZEJ-Gelsenkirchen Hbf Railway Station-Gelsenkirchen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZEU",
            "countryCode": "DE",
            "cityCode": "ZEU",
            "cityName": "Goettingen",
            "name": "[ZEU] - Goettingen Railway Station - ZEU - Goettingen - Germany",
            "searchName": "ZEU-Goettingen Railway Station-Goettingen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZEY",
            "countryCode": "DE",
            "cityCode": "ZEY",
            "cityName": "Hagen",
            "name": "[ZEY] - Hagen Hbf Railway Station - ZEY - Hagen - Germany",
            "searchName": "ZEY-Hagen Hbf Railway Station-Hagen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZFR",
            "countryCode": "DE",
            "cityCode": "ZFR",
            "cityName": "Frankfurt (Oder)",
            "name": "[ZFR] - Frankfurt/Oder Railway - ZFR - Frankfurt (Oder) - Germany",
            "searchName": "ZFR-Frankfurt/Oder Railway-Frankfurt (Oder)-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZEE",
            "countryCode": "DE",
            "cityCode": "ZEE",
            "cityName": "Fulda",
            "name": "[ZEE] - Fulda Railway Station - ZEE - Fulda - Germany",
            "searchName": "ZEE-Fulda Railway Station-Fulda-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZGA",
            "countryCode": "DE",
            "cityCode": "ZGA",
            "cityName": "Gera",
            "name": "[ZGA] - Gera Railway - ZGA - Gera - Germany",
            "searchName": "ZGA-Gera Railway-Gera-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZGE",
            "countryCode": "DE",
            "cityCode": "ZGE",
            "cityName": "Goerlitz",
            "name": "[ZGE] - Goerlitz Railway - ZGE - Goerlitz - Germany",
            "searchName": "ZGE-Goerlitz Railway-Goerlitz-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZGO",
            "countryCode": "DE",
            "cityCode": "ZGO",
            "cityName": "Gotha",
            "name": "[ZGO] - Gotha Railway - ZGO - Gotha - Germany",
            "searchName": "ZGO-Gotha Railway-Gotha-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZGW",
            "countryCode": "DE",
            "cityCode": "ZGW",
            "cityName": "Greifswald",
            "name": "[ZGW] - Greifswald Railway - ZGW - Greifswald - Germany",
            "searchName": "ZGW-Greifswald Railway-Greifswald-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "URD",
            "countryCode": "DE",
            "cityCode": "URD",
            "cityName": "Burg Feuerstein",
            "name": "[URD] - Burg Feuerstein - URD - Burg Feuerstein - Germany",
            "searchName": "URD-Burg Feuerstein-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "VAC",
            "countryCode": "DE",
            "cityCode": "VAC",
            "cityName": "Varrelbusch",
            "name": "[VAC] - Varrelbusch - VAC - Varrelbusch - Germany",
            "searchName": "VAC-Varrelbusch-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "UWP",
            "countryCode": "DE",
            "cityCode": "UWP",
            "cityName": "Wuppertal",
            "name": "[UWP] - Wuppertal Hbf Railway Station - UWP - Wuppertal - Germany",
            "searchName": "UWP-Wuppertal Hbf Railway Station-Wuppertal-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "WBG",
            "countryCode": "DE",
            "cityCode": "WBG",
            "cityName": "Schleswig-jagel",
            "name": "[WBG] - Schleswig-jagel - WBG - Schleswig-jagel - Germany",
            "searchName": "WBG-Schleswig-jagel-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "WIE",
            "countryCode": "DE",
            "cityCode": "WIE",
            "cityName": "Wiesbaden",
            "name": "[WIE] - Air Base - WIE - Wiesbaden - Germany",
            "searchName": "WIE-Air Base-Wiesbaden-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "UWE",
            "countryCode": "DE",
            "cityCode": "WIE",
            "cityName": "Wiesbaden",
            "name": "[UWE] - Wiesbaden Hbf Railway Station - WIE - Wiesbaden - Germany",
            "searchName": "UWE-WIE-Wiesbaden Hbf Railway Station-Wiesbaden-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "WVN",
            "countryCode": "DE",
            "cityCode": "WVN",
            "cityName": "Wilhelmshaven",
            "name": "[WVN] - Wilhelmshaven - WVN - Wilhelmshaven - Germany",
            "searchName": "WVN-Flugplatz-Wilhelmshaven-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "XHI",
            "countryCode": "DE",
            "cityCode": "XHI",
            "cityName": "Herzogenaurach",
            "name": "[XHI] - Herzogenaurach Bus Station - XHI - Herzogenaurach - Germany",
            "searchName": "XHI-Herzogenaurach Bus Station-Herzogenaurach-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "XLW",
            "countryCode": "DE",
            "cityCode": "XLW",
            "cityName": "Lemwerder",
            "name": "[XLW] - Lemwerder - XLW - Lemwerder - Germany",
            "searchName": "XLW-Lemwerder-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "SGE",
            "countryCode": "DE",
            "cityCode": "SGE",
            "cityName": "Siegen",
            "name": "[SGE] - Siegerland Airport - SGE - Siegen - Germany",
            "searchName": "SGE-Siegerland Airport-Siegerland Havalimani-Siegen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "SCN",
            "countryCode": "DE",
            "cityCode": "SCN",
            "cityName": "Saarbruecken",
            "name": "[SCN] - Ensheim - SCN - Saarbruecken - Germany",
            "searchName": "SCN-Saarbrücken-Ensheim-Ensheim Airport-Saarbr-Saarbruecken-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "QFZ",
            "countryCode": "DE",
            "cityCode": "SCN",
            "cityName": "Saarbruecken",
            "name": "[QFZ] - Saarbruecken Hbf Railway Station - SCN - Saarbruecken - Germany",
            "searchName": "QFZ-SCN-Saarbruecken Hbf Railway Station-Saarbr-Saarbruecken-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "SPM",
            "countryCode": "DE",
            "cityCode": "SPM",
            "cityName": "Spangdahlem",
            "name": "[SPM] - Spangdahlem - SPM - Spangdahlem - Germany",
            "searchName": "SPM-Spangdahlem-Spangdahlem Hava Üssü Havalimani-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "CUR",
            "countryCode": "CW",
            "cityCode": "CUR",
            "cityName": "Curacao",
            "name": "[CUR] - Hato International Airport - CUR - Curacao - Curacao",
            "searchName": "CUR-Curacao-Hato International Airport-Hato Int. Airport-Willemstad-C-Curaçao-Hollanda Antilleri-Curazao",
            "priority": 0
        },
        {
            "iataCode": "XCH",
            "countryCode": "CX",
            "cityCode": "XCH",
            "cityName": "Christmas Island",
            "name": "[XCH] - Christmas Island - XCH - Christmas Island - Christmas Island",
            "searchName": "XCH-Christmas Island--Christmas Adasi-Isla Christmas-Île Christmas-Isola di Christmas",
            "priority": 0
        },
        {
            "iataCode": "GER",
            "countryCode": "CU",
            "cityCode": "GER",
            "cityName": "Nueva Gerona",
            "name": "[GER] - Rafael Cabrera - GER - Nueva Gerona - Cuba",
            "searchName": "GER-Rafael Cabrera-Nueva Gerona-Rafael Perez-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "GAO",
            "countryCode": "CU",
            "cityCode": "GAO",
            "cityName": "Guantanamo",
            "name": "[GAO] - Los Canos - GAO - Guantanamo - Cuba",
            "searchName": "GAO-Los Canos-Guantanamo Los Canos-Guant-Guantanamo-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "NBW",
            "countryCode": "CU",
            "cityCode": "GAO",
            "cityName": "Guantanamo",
            "name": "[NBW] - Guantanamo NAS - GAO - Guantanamo - Cuba",
            "searchName": "NBW-GAO-Guantanamo NAS-Guant-Guantanamo-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "CMW",
            "countryCode": "CU",
            "cityCode": "CMW",
            "cityName": "Camaguey",
            "name": "[CMW] - Ign Agramonte International - CMW - Camaguey - Cuba",
            "searchName": "CMW-Ign Agramonte Intl-Ign Agramonte International-Camagüey-Camag-Camaguey-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "CYO",
            "countryCode": "CU",
            "cityCode": "CYO",
            "cityName": "Cayo Largo Del Sur",
            "name": "[CYO] - Cayo Largo Del Sur - CYO - Cayo Largo Del Sur - Cuba",
            "searchName": "CYO-Cayo Largo Del Sur-Vilo Acuna-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "AVI",
            "countryCode": "CU",
            "cityCode": "AVI",
            "cityName": "Ciego De Avila",
            "name": "[AVI] - Maximo Gomez - AVI - Ciego De Avila - Cuba",
            "searchName": "AVI-Maximo Gomez-Ciego De Avila-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "BCA",
            "countryCode": "CU",
            "cityCode": "BCA",
            "cityName": "Baracoa",
            "name": "[BCA] - Baracoa - BCA - Baracoa - Cuba",
            "searchName": "BCA-Baracoa-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "BYM",
            "countryCode": "CU",
            "cityCode": "BYM",
            "cityName": "Bayamo",
            "name": "[BYM] - C.M. de Cespedes - BYM - Bayamo - Cuba",
            "searchName": "BYM-C.M. de Cespedes-Bayamo-Carlos M. Cespedes-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "BWW",
            "countryCode": "CU",
            "cityCode": "BWW",
            "cityName": "Cayo Las Brujas",
            "name": "[BWW] - Las Brujas - BWW - Cayo Las Brujas - Cuba",
            "searchName": "BWW-Las Brujas-Cayo Las Brujas-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "BVC",
            "countryCode": "CV",
            "cityCode": "BVC",
            "cityName": "Boa Vista",
            "name": "[BVC] - Rabil - BVC - Boa Vista - Cape Verde",
            "searchName": "BVC-Boavista-Rabil-Boa Vista-Kap Verde-Cape Verde-Yesil Burun Adalari-Cap-Vert-Cabo Verde-Capo Verde",
            "priority": 0
        },
        {
            "iataCode": "BVR",
            "countryCode": "CV",
            "cityCode": "BVR",
            "cityName": "Brava",
            "name": "[BVR] - Esperadinha - BVR - Brava - Cape Verde",
            "searchName": "BVR-Esperadinha-Esperadinha Havalimani-Brava-Kap Verde-Cape Verde-Yesil Burun Adalari-Cap-Vert-Cabo Verde-Capo Verde",
            "priority": 0
        },
        {
            "iataCode": "MMO",
            "countryCode": "CV",
            "cityCode": "MMO",
            "cityName": "Maio",
            "name": "[MMO] - Vila Do Maio - MMO - Maio - Cape Verde",
            "searchName": "MMO-Vila Do Maio-Maio-Kap Verde-Cape Verde-Yesil Burun Adalari-Cap-Vert-Cabo Verde-Capo Verde",
            "priority": 0
        },
        {
            "iataCode": "MTI",
            "countryCode": "CV",
            "cityCode": "MTI",
            "cityName": "Mosteiros",
            "name": "[MTI] - Mosteiros - MTI - Mosteiros - Cape Verde",
            "searchName": "MTI-Mosteiros-Kap Verde-Cape Verde-Yesil Burun Adalari-Cap-Vert-Cabo Verde-Capo Verde",
            "priority": 0
        },
        {
            "iataCode": "NTO",
            "countryCode": "CV",
            "cityCode": "NTO",
            "cityName": "Santo Antao",
            "name": "[NTO] - Santo Antao - NTO - Santo Antao - Cape Verde",
            "searchName": "NTO-Santo Antao-Kap Verde-Cape Verde-Yesil Burun Adalari-Cap-Vert-Cabo Verde-Capo Verde",
            "priority": 0
        },
        {
            "iataCode": "RAI",
            "countryCode": "CV",
            "cityCode": "RAI",
            "cityName": "Praia",
            "name": "[RAI] - Francisco Mendes - RAI - Praia - Cape Verde",
            "searchName": "RAI-Praia-Francisco Mendes-Kap Verde-Cape Verde-Yesil Burun Adalari-Cap-Vert-Cabo Verde-Capo Verde",
            "priority": 0
        },
        {
            "iataCode": "SNE",
            "countryCode": "CV",
            "cityCode": "SNE",
            "cityName": "Sao Nicolau",
            "name": "[SNE] - Preguica - SNE - Sao Nicolau - Cape Verde",
            "searchName": "SNE-Preguica-Sao Nicolau-Kap Verde-Cape Verde-Yesil Burun Adalari-Cap-Vert-Cabo Verde-Capo Verde",
            "priority": 0
        },
        {
            "iataCode": "SFL",
            "countryCode": "CV",
            "cityCode": "SFL",
            "cityName": "São Filipe",
            "name": "[SFL] - Sao Filipe - SFL - São Filipe - Cape Verde",
            "searchName": "SFL-Sao Felipe-Sao Filipe-Sao Filipe Havalimani-S-São Filipe-Kap Verde-Cape Verde-Yesil Burun Adalari-Cap-Vert-Cabo Verde-Capo Verde",
            "priority": 0
        },
        {
            "iataCode": "COW",
            "countryCode": "CL",
            "cityCode": "COW",
            "cityName": "Coquimbo",
            "name": "[COW] - Coquimbo - COW - Coquimbo - Chile",
            "searchName": "COW-Coquimbo-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "CNR",
            "countryCode": "CL",
            "cityCode": "CNR",
            "cityName": "Chanaral",
            "name": "[CNR] - Chanaral - CNR - Chanaral - Chile",
            "searchName": "CNR-Chanaral-Chile-Xile-Sili-Cile-Chili-Tšiili-Chi lê",
            "priority": 0
        },
        {
            "iataCode": "VRO",
            "countryCode": "CU",
            "cityCode": "VRO",
            "cityName": "Matanzas",
            "name": "[VRO] - Kawama - VRO - Matanzas - Cuba",
            "searchName": "VRO-Kawama-Kawama Havalimani-Matanzas-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "CUC",
            "countryCode": "CO",
            "cityCode": "CUC",
            "cityName": "Cucuta",
            "name": "[CUC] - Camilo Dazo - CUC - Cucuta - Colombia",
            "searchName": "CUC-Cucuta-Camilo Dazo-Camilo Daza-C-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "CUI",
            "countryCode": "CO",
            "cityCode": "CUI",
            "cityName": "Currillo",
            "name": "[CUI] - Currillo - CUI - Currillo - Colombia",
            "searchName": "CUI-Currillo-Colômbia-Colombia-Colombie-Kolumbien-Cô lôm bia-Kolumbija",
            "priority": 0
        },
        {
            "iataCode": "SNU",
            "countryCode": "CU",
            "cityCode": "SNU",
            "cityName": "Santa Clara",
            "name": "[SNU] - Abel Santa Maria - SNU - Santa Clara - Cuba",
            "searchName": "SNU-Santa Clara-Abel Santa Maria-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "SNJ",
            "countryCode": "CU",
            "cityCode": "SNJ",
            "cityName": "San Julian",
            "name": "[SNJ] - San Julian - SNJ - San Julian - Cuba",
            "searchName": "SNJ-San Julian-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "TND",
            "countryCode": "CU",
            "cityCode": "TND",
            "cityName": "Trinidad",
            "name": "[TND] - Trinidad - TND - Trinidad - Cuba",
            "searchName": "TND-Trinidad-Trinidad CU Havalimani-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "SZJ",
            "countryCode": "CU",
            "cityCode": "SZJ",
            "cityName": "Siguanea",
            "name": "[SZJ] - Siguanea - SZJ - Siguanea - Cuba",
            "searchName": "SZJ-Siguanea-Siguanea Havalimani-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "PST",
            "countryCode": "CU",
            "cityCode": "PST",
            "cityName": "Preston",
            "name": "[PST] - Preston - PST - Preston - Cuba",
            "searchName": "PST-Preston-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "MZO",
            "countryCode": "CU",
            "cityCode": "MZO",
            "cityName": "Manzanillo",
            "name": "[MZO] - Sierra Maestra - MZO - Manzanillo - Cuba",
            "searchName": "MZO-Sierra Maestra-Manzanillo-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "MOA",
            "countryCode": "CU",
            "cityCode": "MOA",
            "cityName": "Moa",
            "name": "[MOA] - Orestes Acosta - MOA - Moa - Cuba",
            "searchName": "MOA-Orestes Acosta-Moa-Dinornithidae-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "MJG",
            "countryCode": "CU",
            "cityCode": "MJG",
            "cityName": "Mayajigua",
            "name": "[MJG] - Mayajigua - MJG - Mayajigua - Cuba",
            "searchName": "MJG-Mayajigua-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "LCL",
            "countryCode": "CU",
            "cityCode": "LCL",
            "cityName": "La Coloma",
            "name": "[LCL] - La Coloma - LCL - La Coloma - Cuba",
            "searchName": "LCL-La Coloma-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "ICR",
            "countryCode": "CU",
            "cityCode": "ICR",
            "cityName": "Nicaro",
            "name": "[ICR] - Nicaro - ICR - Nicaro - Cuba",
            "searchName": "ICR-Nicaro-Kuba-Cuba",
            "priority": 0
        },
        {
            "iataCode": "ZAQ",
            "countryCode": "DE",
            "cityCode": "NUE",
            "cityName": "Nürnberg",
            "name": "[ZAQ] - Nuremberg Hbf Railway Station - NUE - Nürnberg - Germany",
            "searchName": "ZAQ-NUE-Nuremberg Hbf Railway Station-N-Nürnberg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "NOD",
            "countryCode": "DE",
            "cityCode": "NOD",
            "cityName": "Norden",
            "name": "[NOD] - Norden - NOD - Norden - Germany",
            "searchName": "NOD-Norden-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "NRD",
            "countryCode": "DE",
            "cityCode": "NRD",
            "cityName": "Norderney",
            "name": "[NRD] - Norderney - NRD - Norderney - Germany",
            "searchName": "NRD-Norderney-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "OBF",
            "countryCode": "DE",
            "cityCode": "OBF",
            "cityName": "Oberpfaffenhofen",
            "name": "[OBF] - Oberpfaffenhofen - OBF - Oberpfaffenhofen - Germany",
            "searchName": "OBF-Oberpfaffenhofen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "OHR",
            "countryCode": "DE",
            "cityCode": "OHR",
            "cityName": "Wyk Auf Foehr",
            "name": "[OHR] - Wyk Auf Foehr - OHR - Wyk Auf Foehr - Germany",
            "searchName": "OHR-Wyk Auf Foehr-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "PSH",
            "countryCode": "DE",
            "cityCode": "PSH",
            "cityName": "St Peter",
            "name": "[PSH] - St Peter - PSH - St Peter - Germany",
            "searchName": "PSH-St Peter-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "QBO",
            "countryCode": "DE",
            "cityCode": "QBO",
            "cityName": "Bochum",
            "name": "[QBO] - Bochum Hbf Railway Station - QBO - Bochum - Germany",
            "searchName": "QBO-Bochum Hbf Railway Station-Bochum-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "RBM",
            "countryCode": "DE",
            "cityCode": "RBM",
            "cityName": "Straubing",
            "name": "[RBM] - Wallmuhle - RBM - Straubing - Germany",
            "searchName": "RBM-Wallmühle-Wallmuhle-Wallmuhle Havalimani-Straubing-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "QII",
            "countryCode": "DE",
            "cityCode": "QII",
            "cityName": "Lindau",
            "name": "[QII] - Lindau Hbf Railway Station - QII - Lindau - Germany",
            "searchName": "QII-Lindau Hbf Railway Station-Lindau-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "QFB",
            "countryCode": "DE",
            "cityCode": "QFB",
            "cityName": "Freiburg",
            "name": "[QFB] - Freiburg Hbf Railway Station - QFB - Freiburg - Germany",
            "searchName": "QFB-Freiburg Hbf Railway Station-Freiburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "QHU",
            "countryCode": "DE",
            "cityCode": "QHU",
            "cityName": "Husum",
            "name": "[QHU] - Husum - QHU - Husum - Germany",
            "searchName": "QHU-Husum-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "QWU",
            "countryCode": "DE",
            "cityCode": "QWU",
            "cityName": "Wuerzburg",
            "name": "[QWU] - Wuerzburg Hbf Railway Station - QWU - Wuerzburg - Germany",
            "searchName": "QWU-Wuerzburg Hbf Railway Station-Wuerzburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "QUL",
            "countryCode": "DE",
            "cityCode": "QUL",
            "cityName": "Ulm",
            "name": "[QUL] - Ulm Hbf Railway Station - QUL - Ulm - Germany",
            "searchName": "QUL-Ulm Hbf Railway Station-Ulm-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "REB",
            "countryCode": "DE",
            "cityCode": "REB",
            "cityName": "Rechlin",
            "name": "[REB] - Rechlin - REB - Rechlin - Germany",
            "searchName": "REB-Rechlin-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "RLG",
            "countryCode": "DE",
            "cityCode": "RLG",
            "cityName": "Rostock-laage",
            "name": "[RLG] - Laage - RLG - Rostock-laage - Germany",
            "searchName": "RLG-Rostock-Laage-laage-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "RMS",
            "countryCode": "DE",
            "cityCode": "RMS",
            "cityName": "Ramstein",
            "name": "[RMS] - Ramstein - RMS - Ramstein - Germany",
            "searchName": "RMS-Ramstein-Ramsteim Havalimani-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "PAD",
            "countryCode": "DE",
            "cityCode": "PAD",
            "cityName": "Paderborn",
            "name": "[PAD] - Paderborn/lippstadt - PAD - Paderborn - Germany",
            "searchName": "PAD-Paderborn-Paderborn/lippstadt-Paderborn\\/Lippstadt-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "PEF",
            "countryCode": "DE",
            "cityCode": "PEF",
            "cityName": "Peenemuende",
            "name": "[PEF] - Peenemuende - PEF - Peenemuende - Germany",
            "searchName": "PEF-Peenemuende-Peenemuende Havalimani-Peenem-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "BRV",
            "countryCode": "DE",
            "cityCode": "BRV",
            "cityName": "Bremerhaven",
            "name": "[BRV] - Bremerhaven - BRV - Bremerhaven - Germany",
            "searchName": "BRV-Bremerhaven-Braavos-Bremerhaven Havalimani-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "BRE",
            "countryCode": "DE",
            "cityCode": "BRE",
            "cityName": "Bremen",
            "name": "[BRE] - Bremen - BRE - Bremen - Germany",
            "searchName": "BRE-Bremen-Bremen Airport-Br-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "BMR",
            "countryCode": "DE",
            "cityCode": "BMR",
            "cityName": "Baltrum Island",
            "name": "[BMR] - Baltrum - BMR - Baltrum Island - Germany",
            "searchName": "BMR-Flugplatz-Baltrum-Baltrum Island-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "BWE",
            "countryCode": "DE",
            "cityCode": "BWE",
            "cityName": "Braunschweig",
            "name": "[BWE] - Braunschweig - BWE - Braunschweig - Germany",
            "searchName": "BWE-Braunschweig-Wolfsburg-Braunschweig Havalimani-Brunswick-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZQU",
            "countryCode": "DE",
            "cityCode": "BWE",
            "cityName": "Braunschweig",
            "name": "[ZQU] - Wolfsburg Rail Station - BWE - Braunschweig - Germany",
            "searchName": "ZQU-BWE-Hauptbahnhof-Wolfsburg Rail Station-Braunschweig-Brunswick-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "BYU",
            "countryCode": "DE",
            "cityCode": "BYU",
            "cityName": "Bayreuth",
            "name": "[BYU] - Bindlacher-Berg - BYU - Bayreuth - Germany",
            "searchName": "BYU-Flugplatz Bindlacher Berg-Bindlacher-Berg-Bayreuth-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "BBJ",
            "countryCode": "DE",
            "cityCode": "BBJ",
            "cityName": "Bitburg",
            "name": "[BBJ] - Bitburg Air Base - BBJ - Bitburg - Germany",
            "searchName": "BBJ-Flugplatz-Bitburg Air Base-Bitburg Hava Üssü Havalimani-Bitburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "QWB",
            "countryCode": "DE",
            "cityCode": "BER",
            "cityName": "Berlin",
            "name": "[QWB] - Ostbahnhof Railway Station - BER - Berlin - Germany",
            "searchName": "QWB-BER-Ostbahnhof Railway Station-Berlin-Berl-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "QWC",
            "countryCode": "DE",
            "cityCode": "BER",
            "cityName": "Berlin",
            "name": "[QWC] - Zoo Railway Station - BER - Berlin - Germany",
            "searchName": "QWC-BER-Zoo Railway Station-Berlin-Berl-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "QPP",
            "countryCode": "DE",
            "cityCode": "BER",
            "cityName": "Berlin",
            "name": "[QPP] - Berlin Hbf Railway Station - BER - Berlin - Germany",
            "searchName": "QPP-BER-Berlin Hbf Railway Station-Berlin-Berl-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "SXF",
            "countryCode": "DE",
            "cityCode": "BER",
            "cityName": "Berlin",
            "name": "[SXF] - Berlin-Schoenefeld - BER - Berlin - Germany",
            "searchName": "SXF-BER-Berlin Schönefeld-Berlin-Schoenefeld-Schönefeld-Berl-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "BFE",
            "countryCode": "DE",
            "cityCode": "BFE",
            "cityName": "Bielefeld",
            "name": "[BFE] - Bielefeld - BFE - Bielefeld - Germany",
            "searchName": "BFE-Bielefeld-Bielefeld Havalimani-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "BMK",
            "countryCode": "DE",
            "cityCode": "BMK",
            "cityName": "Borkum",
            "name": "[BMK] - Borkum - BMK - Borkum - Germany",
            "searchName": "BMK-Borkum-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "AGE",
            "countryCode": "DE",
            "cityCode": "AGE",
            "cityName": "Wangerooge",
            "name": "[AGE] - Flugplatz - AGE - Wangerooge - Germany",
            "searchName": "AGE-Flugplatz-Wangerooge-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "AAH",
            "countryCode": "DE",
            "cityCode": "AAH",
            "cityName": "Aachen",
            "name": "[AAH] - Aachen/Merzbruck - AAH - Aachen - Germany",
            "searchName": "AAH-Aachen/Merzbruck-Aachen Merzbruck Havalimani-Aachen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "XHJ",
            "countryCode": "DE",
            "cityCode": "AAH",
            "cityName": "Aachen",
            "name": "[XHJ] - Aachen Hbf Railway Station - AAH - Aachen - Germany",
            "searchName": "XHJ-AAH-Aachen Hbf Railway Station-Aachen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "BBH",
            "countryCode": "DE",
            "cityCode": "BBH",
            "cityName": "Barth",
            "name": "[BBH] - Barth - BBH - Barth - Germany",
            "searchName": "BBH-Barth-Stralsund-Barth Havalimani-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZSX",
            "countryCode": "DE",
            "cityCode": "BBH",
            "cityName": "Barth",
            "name": "[ZSX] - Stralsund Railway Station - BBH - Barth - Germany",
            "searchName": "ZSX-BBH-Stralsund Railway Station-Barth-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "AOC",
            "countryCode": "DE",
            "cityCode": "AOC",
            "cityName": "Altenburg",
            "name": "[AOC] - Altenburg Nobitz - AOC - Altenburg - Germany",
            "searchName": "AOC-Altenburg-Nobitz-Altenburg Nobitz-Altenburg Nobitz Havalimani-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "EIB",
            "countryCode": "DE",
            "cityCode": "EIB",
            "cityName": "Eisenach",
            "name": "[EIB] - Eisenach - EIB - Eisenach - Germany",
            "searchName": "EIB-Verkehrslandeplatz Kindel-Eisenach-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "EME",
            "countryCode": "DE",
            "cityCode": "EME",
            "cityName": "Emden",
            "name": "[EME] - Emden - EME - Emden - Germany",
            "searchName": "EME-Flugplatz-Emden-Emden Havalimani-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "FCN",
            "countryCode": "DE",
            "cityCode": "FCN",
            "cityName": "Cuxhaven",
            "name": "[FCN] - Cuxhaven/Nordholz - FCN - Cuxhaven - Germany",
            "searchName": "FCN-Cuxhaven/Nordholz-Cuxhaven Nordholz Havalimani-Cuxhaven-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ERF",
            "countryCode": "DE",
            "cityCode": "ERF",
            "cityName": "Erfurt",
            "name": "[ERF] - Erfurt - ERF - Erfurt - Germany",
            "searchName": "ERF-Erfurt-Weimar--Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija-Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "XIU",
            "countryCode": "DE",
            "cityCode": "ERF",
            "cityName": "Erfurt",
            "name": "[XIU] - Erfurt Hbf Railway Station - ERF - Erfurt - Germany",
            "searchName": "XIU-ERF-Erfurt Hbf Railway Station-Erfurt--Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija-Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "EUM",
            "countryCode": "DE",
            "cityCode": "EUM",
            "cityName": "Neumuenster",
            "name": "[EUM] - Neumuenster - EUM - Neumuenster - Germany",
            "searchName": "EUM-Neumuenster-Neum-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "DRS",
            "countryCode": "DE",
            "cityCode": "DRS",
            "cityName": "Dresden",
            "name": "[DRS] - Dresden Airport - DRS - Dresden - Germany",
            "searchName": "DRS-Dresden-Dresden Airport-Dresden Arpt-Dresden Klotzsche Apt.-Dresde-Dresda-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "DRX",
            "countryCode": "DE",
            "cityCode": "DRS",
            "cityName": "Dresden",
            "name": "[DRX] - Dresden Bus Station - DRS - Dresden - Germany",
            "searchName": "DRX-DRS-Dresden Bus Station-Dresden-Dresde-Dresda-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "XIR",
            "countryCode": "DE",
            "cityCode": "DRS",
            "cityName": "Dresden",
            "name": "[XIR] - Dresden Hbf Railway Station - DRS - Dresden - Germany",
            "searchName": "XIR-DRS-Dresden Hbf Railway Station-Dresden-Dresde-Dresda-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZWS",
            "countryCode": "DE",
            "cityCode": "STR",
            "cityName": "Stuttgart",
            "name": "[ZWS] - Stuttgart Hauptbahnhof - STR - Stuttgart - Germany",
            "searchName": "ZWS-STR-Stuttgart Hauptbahnhof-Railway Service-Stuttgart-Stoccarda-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "SZW",
            "countryCode": "DE",
            "cityCode": "SZW",
            "cityName": "Schwerin",
            "name": "[SZW] - Parchim Airport - SZW - Schwerin - Germany",
            "searchName": "SZW-Parchim-Parchim Airport-Aeropuerto Parchim-Aéroport Parchim-Parchim Aeroporto-Parchim Havalimani-Schwerin-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZSR",
            "countryCode": "DE",
            "cityCode": "SZW",
            "cityName": "Schwerin",
            "name": "[ZSR] - Schwerin Hbf Railway Station - SZW - Schwerin - Germany",
            "searchName": "ZSR-SZW-Hauptbahnhof-Schwerin Hbf Railway Station-Schwerin-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "MHG",
            "countryCode": "DE",
            "cityCode": "MHG",
            "cityName": "Mannheim",
            "name": "[MHG] - Mannheim Airport - MHG - Mannheim - Germany",
            "searchName": "MHG-Mannheim Airport-Mannheim-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "AKT",
            "countryCode": "CY",
            "cityCode": "AKT",
            "cityName": "Akrotiri",
            "name": "[AKT] - Raf Akrotiri - AKT - Akrotiri - Cyprus",
            "searchName": "AKT-Militärflughafen-Raf Akrotiri-Akrotiri Raf-Akrotiri-Ciprus-Cypr-Siprus-Cyprus-Síp-Kipra-Kypros-Küpros-Cipar-Xipre-Kypr-Cypern-Chipre-Zypern-Chypre-Kibris-Cipru-Kipras-Cipro",
            "priority": 0
        },
        {
            "iataCode": "GEC",
            "countryCode": "CY",
            "cityCode": "GEC",
            "cityName": "Gecitkale",
            "name": "[GEC] - Gecitkale - GEC - Gecitkale - Cyprus",
            "searchName": "GEC-Gecitkale-Ciprus-Cypr-Siprus-Cyprus-Síp-Kipra-Kypros-Küpros-Cipar-Xipre-Kypr-Cypern-Chipre-Zypern-Chypre-Kibris-Cipru-Kipras-Cipro",
            "priority": 0
        },
        {
            "iataCode": "ZIK",
            "countryCode": "CZ",
            "cityCode": "KLV",
            "cityName": "Karlovy Vary",
            "name": "[ZIK] - Karlovy Vary Bus Station - KLV - Karlovy Vary - Czech Republic",
            "searchName": "ZIK-KLV-Karlovy Vary Bus Station-Karlsbad-Karlovy Vary-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "BRQ",
            "countryCode": "CZ",
            "cityCode": "BRQ",
            "cityName": "Brno",
            "name": "[BRQ] - Turany - BRQ - Brno - Czech Republic",
            "searchName": "BRQ-Brünn-Turany-Brno Turany Havalimani-Brno-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "ZDN",
            "countryCode": "CZ",
            "cityCode": "BRQ",
            "cityName": "Brno",
            "name": "[ZDN] - Brno Bus Station - BRQ - Brno - Czech Republic",
            "searchName": "ZDN-BRQ-Brno Bus Station-Brno-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "PED",
            "countryCode": "CZ",
            "cityCode": "PED",
            "cityName": "Pardubice",
            "name": "[PED] - Pardubice - PED - Pardubice - Czech Republic",
            "searchName": "PED-Pardubice-Pardubice Havalimani-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "XJP",
            "countryCode": "CZ",
            "cityCode": "PED",
            "cityName": "Pardubice",
            "name": "[XJP] - Pardubice Main Railway Station - PED - Pardubice - Czech Republic",
            "searchName": "XJP-PED-Pardubice Main Railway Station-Pardubice-Tšehhi-Republik Ceko-Tjeckien-Republik Czech-Tsjechië-República Che-Czech Republic-Tschechische Republik-Çek Cumhuriyeti-República Checa-République tchèque-Repubblica Ceca",
            "priority": 0
        },
        {
            "iataCode": "QKL",
            "countryCode": "DE",
            "cityCode": "CGN",
            "cityName": "Cologne",
            "name": "[QKL] - Koeln Hauptbahnhof - CGN - Cologne - Germany",
            "searchName": "QKL-CGN-Koeln Hauptbahnhof-Railway Service-K-Cologne-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "QKU",
            "countryCode": "DE",
            "cityCode": "CGN",
            "cityName": "Cologne",
            "name": "[QKU] - Messe/Deutz Rail Station - CGN - Cologne - Germany",
            "searchName": "QKU-CGN-Messe/Deutz Rail Station-K-Cologne-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "KLT",
            "countryCode": "DE",
            "cityCode": "KLT",
            "cityName": "Kaiserslautern",
            "name": "[KLT] - Kaiserslautern - KLT - Kaiserslautern - Germany",
            "searchName": "KLT-Kaiserslautern-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "KSF",
            "countryCode": "DE",
            "cityCode": "KSF",
            "cityName": "Kassel",
            "name": "[KSF] - Kassel-Calden - KSF - Kassel - Germany",
            "searchName": "KSF-Calden-Kassel-Kassel Calden Havalimani-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "KOQ",
            "countryCode": "DE",
            "cityCode": "KOQ",
            "cityName": "Koethen",
            "name": "[KOQ] - Koethen - KOQ - Koethen - Germany",
            "searchName": "KOQ-Koethen-K-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "JUI",
            "countryCode": "DE",
            "cityCode": "JUI",
            "cityName": "Juist",
            "name": "[JUI] - Juist - JUI - Juist - Germany",
            "searchName": "JUI-Juist-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "KEL",
            "countryCode": "DE",
            "cityCode": "KEL",
            "cityName": "Kiel",
            "name": "[KEL] - Kiel-Holtenau - KEL - Kiel - Germany",
            "searchName": "KEL-Holtenau-Kiel-Holtenau Havalimani-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "LEJ",
            "countryCode": "DE",
            "cityCode": "LEJ",
            "cityName": "Leipzig-Halle",
            "name": "[LEJ] - Halle - LEJ - Leipzig-Halle - Germany",
            "searchName": "LEJ-Leipzig Halle-Halle-Leipzig/Halle-Leipzig-Lipsia-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZHZ",
            "countryCode": "DE",
            "cityCode": "LEJ",
            "cityName": "Leipzig-Halle",
            "name": "[ZHZ] - Halle Railway - LEJ - Leipzig-Halle - Germany",
            "searchName": "ZHZ-LEJ-Halle Railway-Leipzig/Halle-Leipzig-Halle-Lipsia-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "XIT",
            "countryCode": "DE",
            "cityCode": "LEJ",
            "cityName": "Leipzig-Halle",
            "name": "[XIT] - Leipzig Hbf Rail Station - LEJ - Leipzig-Halle - Germany",
            "searchName": "XIT-LEJ-Leipzig Hbf Rail Station-Leipzig/Halle-Leipzig-Halle-Lipsia-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "LHA",
            "countryCode": "DE",
            "cityCode": "LHA",
            "cityName": "Lahr",
            "name": "[LHA] - Black Forest - LHA - Lahr - Germany",
            "searchName": "LHA-Black Forest-Lahr-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "LGO",
            "countryCode": "DE",
            "cityCode": "LGO",
            "cityName": "Langeoog",
            "name": "[LGO] - Langeoog - LGO - Langeoog - Germany",
            "searchName": "LGO-Langeoog-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "KZG",
            "countryCode": "DE",
            "cityCode": "KZG",
            "cityName": "Kitzingen",
            "name": "[KZG] - Kitzingen - KZG - Kitzingen - Germany",
            "searchName": "KZG-Kitzingen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "FMM",
            "countryCode": "DE",
            "cityCode": "FMM",
            "cityName": "Memmingen",
            "name": "[FMM] - Memmingen-AllgÃ¤u - FMM - Memmingen - Germany",
            "searchName": "FMM-Memmingen-Allgäu-Allgau-Memmingen Airport-AllgÃ¤u-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "FMO",
            "countryCode": "DE",
            "cityCode": "FMO",
            "cityName": "Greven",
            "name": "[FMO] - MÃ¼nster OsnabrÃ¼ck International - FMO - Greven - Germany",
            "searchName": "FMO-Münster-Münster Osnabrück International-Greven-MÃ¼nster OsnabrÃ¼ck International-M-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZPE",
            "countryCode": "DE",
            "cityCode": "FMO",
            "cityName": "Greven",
            "name": "[ZPE] - Osnabrueck Hbf Rail Station - FMO - Greven - Germany",
            "searchName": "ZPE-FMO-Osnabrueck Hbf Rail Station-M-Greven-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "FNB",
            "countryCode": "DE",
            "cityCode": "FNB",
            "cityName": "Neubrandenburg",
            "name": "[FNB] - Neubrandenburg - FNB - Neubrandenburg - Germany",
            "searchName": "FNB-Neubrandenburg-Neubandenburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "FRZ",
            "countryCode": "DE",
            "cityCode": "FRZ",
            "cityName": "Fritzlar",
            "name": "[FRZ] - Fritzlar Airbase - FRZ - Fritzlar - Germany",
            "searchName": "FRZ-Fritzlar Airbase-Fritzlar-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "LBC",
            "countryCode": "DE",
            "cityCode": "HAM",
            "cityName": "Hamburg",
            "name": "[LBC] - LÃ¼beck Blankensee - HAM - Hamburg - Germany",
            "searchName": "LBC-HAM-Lübeck-Lübeck Blankensee-Luebeck Blankensee-LÃ¼beck Blankensee-Hamburg-Hambourg-Amburgo-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZMB",
            "countryCode": "DE",
            "cityCode": "HAM",
            "cityName": "Hamburg",
            "name": "[ZMB] - Hamburg Hbf Railway Station - HAM - Hamburg - Germany",
            "searchName": "ZMB-HAM-Hamburg Hbf Railway Station-Hamburg-Hambourg-Amburgo-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "XFW",
            "countryCode": "DE",
            "cityCode": "HAM",
            "cityName": "Hamburg",
            "name": "[XFW] - Finkenwerder - HAM - Hamburg - Germany",
            "searchName": "XFW-HAM-Flugplatz-Finkenwerder-Hamburg-Hambourg-Amburgo-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "OBZ",
            "countryCode": "DE",
            "cityCode": "HAM",
            "cityName": "Hamburg",
            "name": "[OBZ] - Hamburg Bus Station - HAM - Hamburg - Germany",
            "searchName": "OBZ-HAM-Hamburg Bus Station-Hamburg-Hambourg-Amburgo-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "GWT",
            "countryCode": "DE",
            "cityCode": "GWT",
            "cityName": "Westerland",
            "name": "[GWT] - Westerland - Sylt - GWT - Westerland - Germany",
            "searchName": "GWT-Westerland Sylt-Westerland - Sylt-Westerland-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "GUT",
            "countryCode": "DE",
            "cityCode": "GUT",
            "cityName": "Guetersloh",
            "name": "[GUT] - Guetersloh - GUT - Guetersloh - Germany",
            "searchName": "GUT-Guetersloh-Guetersloh Havalimani-Gutersloh-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZEX",
            "countryCode": "DE",
            "cityCode": "GUT",
            "cityName": "Guetersloh",
            "name": "[ZEX] - Guetersloh Hbf Railway Station - GUT - Guetersloh - Germany",
            "searchName": "ZEX-GUT-Guetersloh Hbf Railway Station-Guetersloh-Gutersloh-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "GKE",
            "countryCode": "DE",
            "cityCode": "GKE",
            "cityName": "Geilenkirchen",
            "name": "[GKE] - Geilenkirchen - GKE - Geilenkirchen - Germany",
            "searchName": "GKE-Geilenkirchen-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "HDF",
            "countryCode": "DE",
            "cityCode": "HDF",
            "cityName": "Heringsdorf",
            "name": "[HDF] - Heringsdorf - HDF - Heringsdorf - Germany",
            "searchName": "HDF-Heringsdorf-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "QHD",
            "countryCode": "DE",
            "cityCode": "HDB",
            "cityName": "Heidelberg",
            "name": "[QHD] - Heidelberg Hbf Railway Station - HDB - Heidelberg - Germany",
            "searchName": "QHD-HDB-Heidelberg Hbf Railway Station-Heidelberg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "HGL",
            "countryCode": "DE",
            "cityCode": "HGL",
            "cityName": "Helgoland",
            "name": "[HGL] - Helgoland - HGL - Helgoland - Germany",
            "searchName": "HGL-Helgoland-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "HEI",
            "countryCode": "DE",
            "cityCode": "HEI",
            "cityName": "Heide-Buesum",
            "name": "[HEI] - Heide-Buesum - HEI - Heide-Buesum - Germany",
            "searchName": "HEI-Flugplatz-Heide-Buesum-Heide Havalimani-Heide\\/ B-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "HOQ",
            "countryCode": "DE",
            "cityCode": "HOQ",
            "cityName": "Hof",
            "name": "[HOQ] - Hof - HOQ - Hof - Germany",
            "searchName": "HOQ-Plauen-Hof-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "IES",
            "countryCode": "DE",
            "cityCode": "IES",
            "cityName": "Riesa",
            "name": "[IES] - Riesa - IES - Riesa - Germany",
            "searchName": "IES-Göhlis-Riesa-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZRX",
            "countryCode": "DE",
            "cityCode": "IES",
            "cityName": "Riesa",
            "name": "[ZRX] - Riesa Railway Station - IES - Riesa - Germany",
            "searchName": "ZRX-IES-Riesa Railway Station-Riesa-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "IGS",
            "countryCode": "DE",
            "cityCode": "IGS",
            "cityName": "Ingolstadt-manching",
            "name": "[IGS] - Ingolstadt-manching - IGS - Ingolstadt-manching - Germany",
            "searchName": "IGS-Flugplatz Manching-Ingolstadt-manching-Ingolstadt Havalimani-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZNQ",
            "countryCode": "DE",
            "cityCode": "IGS",
            "cityName": "Ingolstadt-manching",
            "name": "[ZNQ] - Ingolstadt Hbf Railway Station - IGS - Ingolstadt-manching - Germany",
            "searchName": "ZNQ-IGS-Ingolstadt Hbf Railway Station-Ingolstadt-manching-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ILH",
            "countryCode": "DE",
            "cityCode": "ILH",
            "cityName": "Illisheim",
            "name": "[ILH] - Illis Airbase - ILH - Illisheim - Germany",
            "searchName": "ILH-Illis Airbase-Illisheim-Illishiem-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "JIB",
            "countryCode": "DJ",
            "cityCode": "JIB",
            "cityName": "Djibouti",
            "name": "[JIB] - Ambouli - JIB - Djibouti - Djibouti",
            "searchName": "JIB-Djibouti-Ambouli-Dschibuti-Yibuti-Gibuti-Cibuti-Džibutija-Džibutsko-Dzibuti-Džibutis-Džibuti-Dzsibuti-",
            "priority": 0
        },
        {
            "iataCode": "AII",
            "countryCode": "DJ",
            "cityCode": "AII",
            "cityName": "Alisabieh",
            "name": "[AII] - Alisabieh - AII - Alisabieh - Djibouti",
            "searchName": "AII-Alisabieh-Ali Sabieh-Djibouti-Džibutija-Cibuti-Džibutsko-Dzibuti-Džibutis-Džibuti-Dzsibuti-Dschibuti-Yibuti--Gibuti",
            "priority": 0
        },
        {
            "iataCode": "OBC",
            "countryCode": "DJ",
            "cityCode": "OBC",
            "cityName": "Obock",
            "name": "[OBC] - Obock - OBC - Obock - Djibouti",
            "searchName": "OBC-Obock-Djibouti-Džibutija-Cibuti-Džibutsko-Dzibuti-Džibutis-Džibuti-Dzsibuti-Dschibuti-Yibuti--Gibuti",
            "priority": 0
        },
        {
            "iataCode": "MHI",
            "countryCode": "DJ",
            "cityCode": "MHI",
            "cityName": "Musha",
            "name": "[MHI] - Musha - MHI - Musha - Djibouti",
            "searchName": "MHI-Musha-Djibouti-Džibutija-Cibuti-Džibutsko-Dzibuti-Džibutis-Džibuti-Dzsibuti-Dschibuti-Yibuti--Gibuti",
            "priority": 0
        },
        {
            "iataCode": "TDJ",
            "countryCode": "DJ",
            "cityCode": "TDJ",
            "cityName": "Tadjoura",
            "name": "[TDJ] - Tadjoura - TDJ - Tadjoura - Djibouti",
            "searchName": "TDJ-Tadjoura-Djibouti-Džibutija-Cibuti-Džibutsko-Dzibuti-Džibutis-Džibuti-Dzsibuti-Dschibuti-Yibuti--Gibuti",
            "priority": 0
        },
        {
            "iataCode": "TED",
            "countryCode": "DK",
            "cityCode": "TED",
            "cityName": "Thisted",
            "name": "[TED] - Thisted - TED - Thisted - Denmark",
            "searchName": "TED-Thisted-Thisted Havalimani-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "STA",
            "countryCode": "DK",
            "cityCode": "STA",
            "cityName": "Skjern",
            "name": "[STA] - Stauning Vestjyllands Airport - STA - Skjern - Denmark",
            "searchName": "STA-Stauning Vestjyllands Airport-Skjern-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "SQW",
            "countryCode": "DK",
            "cityCode": "SQW",
            "cityName": "Skive",
            "name": "[SQW] - Skive Airport - SQW - Skive - Denmark",
            "searchName": "SQW-Skive Airport-Skive Havalimani-Skive-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZKN",
            "countryCode": "DK",
            "cityCode": "SQW",
            "cityName": "Skive",
            "name": "[ZKN] - Skive Railway Station - SQW - Skive - Denmark",
            "searchName": "ZKN-SQW-Skive Railway Station-Skive-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "SGD",
            "countryCode": "DK",
            "cityCode": "SGD",
            "cityName": "Sonderborg",
            "name": "[SGD] - Sonderborg - SGD - Sonderborg - Denmark",
            "searchName": "SGD-Sonderborg-Sonderborg Havalimani-Sonderburg-S-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "QSG",
            "countryCode": "DK",
            "cityCode": "SGD",
            "cityName": "Sonderborg",
            "name": "[QSG] - Sonderborg - SGD - Sonderborg - Denmark",
            "searchName": "QSG-SGD-Sonderborg-Sonderburg-S-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "SKS",
            "countryCode": "DK",
            "cityCode": "SKS",
            "cityName": "Vojens",
            "name": "[SKS] - Vojens - SKS - Vojens - Denmark",
            "searchName": "SKS-Vojens-Skrydstrup Havalimani-Skrydstrup-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "XJE",
            "countryCode": "DK",
            "cityCode": "SKS",
            "cityName": "Vojens",
            "name": "[XJE] - Railway Service - SKS - Vojens - Denmark",
            "searchName": "XJE-SKS-Railway Service-Vojens-Skrydstrup-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "XNR",
            "countryCode": "DK",
            "cityCode": "XNR",
            "cityName": "Aabenraa",
            "name": "[XNR] - Aabenraa Bus Station - XNR - Aabenraa - Denmark",
            "searchName": "XNR-Aabenraa Bus Station-Aabenraa-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "XOA",
            "countryCode": "DK",
            "cityCode": "XOA",
            "cityName": "Aalbaek",
            "name": "[XOA] - Aalbaek Bus Station - XOA - Aalbaek - Denmark",
            "searchName": "XOA-Aalbaek Bus Station-Aalbaek-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "XRA",
            "countryCode": "DK",
            "cityCode": "XRA",
            "cityName": "Graasten",
            "name": "[XRA] - Graasten Bus Station - XRA - Graasten - Denmark",
            "searchName": "XRA-Graasten Bus Station-Graasten-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "XAQ",
            "countryCode": "DK",
            "cityCode": "XAQ",
            "cityName": "Bronderslev",
            "name": "[XAQ] - Bronderslev Bus Station - XAQ - Bronderslev - Denmark",
            "searchName": "XAQ-Bronderslev Bus Station-Bronderslev-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "XAH",
            "countryCode": "DK",
            "cityCode": "XAH",
            "cityName": "Silkeborg",
            "name": "[XAH] - Silkeborg Railway Station - XAH - Silkeborg - Denmark",
            "searchName": "XAH-Silkeborg Railway Station-Silkeborg-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "XAJ",
            "countryCode": "DK",
            "cityCode": "XAJ",
            "cityName": "Hirtshals",
            "name": "[XAJ] - Hirtshals Bus Station - XAJ - Hirtshals - Denmark",
            "searchName": "XAJ-Hirtshals Bus Station-Hirtshals-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "MRW",
            "countryCode": "DK",
            "cityCode": "MRW",
            "cityName": "Maribo",
            "name": "[MRW] - Maribo - MRW - Maribo - Denmark",
            "searchName": "MRW-Maribo-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ODE",
            "countryCode": "DK",
            "cityCode": "ODE",
            "cityName": "Odense",
            "name": "[ODE] - Beldringe - ODE - Odense - Denmark",
            "searchName": "ODE-Beldringe-Odense Havalimani-Odense-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZBQ",
            "countryCode": "DK",
            "cityCode": "ODE",
            "cityName": "Odense",
            "name": "[ZBQ] - Odense Railway Station - ODE - Odense - Denmark",
            "searchName": "ZBQ-ODE-Odense Railway Station-Odense-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZIM",
            "countryCode": "DK",
            "cityCode": "ODE",
            "cityName": "Odense",
            "name": "[ZIM] - Odense Bus Station - ODE - Odense - Denmark",
            "searchName": "ZIM-ODE-Odense Bus Station-Odense-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "RNN",
            "countryCode": "DK",
            "cityCode": "RNN",
            "cityName": "Rønne",
            "name": "[RNN] - Bornholm - RNN - Rønne - Denmark",
            "searchName": "RNN-Bornholm-R-Rønne-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "QWO",
            "countryCode": "DK",
            "cityCode": "QWO",
            "cityName": "Holstebro",
            "name": "[QWO] - Holstebro Bus Station - QWO - Holstebro - Denmark",
            "searchName": "QWO-Holstebro Bus Station-Holstebro-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "QXF",
            "countryCode": "DK",
            "cityCode": "QXF",
            "cityName": "Vestbjerg",
            "name": "[QXF] - Vestbjerg Bus Station - QXF - Vestbjerg - Denmark",
            "searchName": "QXF-Vestbjerg Bus Station-Vestbjerg-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "QWQ",
            "countryCode": "DK",
            "cityCode": "QWQ",
            "cityName": "Struer",
            "name": "[QWQ] - Struer Bus Station - QWQ - Struer - Denmark",
            "searchName": "QWQ-Struer Bus Station-Struer-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "QXV",
            "countryCode": "DK",
            "cityCode": "QXV",
            "cityName": "Svendborg",
            "name": "[QXV] - Svendborg Railway Station - QXV - Svendborg - Denmark",
            "searchName": "QXV-Svendborg Railway Station-Svendborg-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "QHJ",
            "countryCode": "DK",
            "cityCode": "QHJ",
            "cityName": "Hjorring",
            "name": "[QHJ] - Hjorring Bus Station - QHJ - Hjorring - Denmark",
            "searchName": "QHJ-Hjorring Bus Station-Hjorring-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "QLZ",
            "countryCode": "DK",
            "cityCode": "QLZ",
            "cityName": "Ikast",
            "name": "[QLZ] - Ikast Bus Station - QLZ - Ikast - Denmark",
            "searchName": "QLZ-Ikast Bus Station-Ikast-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "QJV",
            "countryCode": "DK",
            "cityCode": "QJV",
            "cityName": "Skagen Z7",
            "name": "[QJV] - Limousine Service - QJV - Skagen Z7 - Denmark",
            "searchName": "QJV-Limousine Service-Skagen Z7-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "QJW",
            "countryCode": "DK",
            "cityCode": "QJW",
            "cityName": "Kjellerup",
            "name": "[QJW] - Kjellerup Bus Station - QJW - Kjellerup - Denmark",
            "searchName": "QJW-Kjellerup Bus Station-Kjellerup-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "QFH",
            "countryCode": "DK",
            "cityCode": "QFH",
            "cityName": "Frederikshavn",
            "name": "[QFH] - Frederikshavn Bus Station - QFH - Frederikshavn - Denmark",
            "searchName": "QFH-Busbahnhof-Frederikshavn Bus Station-Frederikshavn-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "QYQ",
            "countryCode": "DK",
            "cityCode": "QYQ",
            "cityName": "Sulsted",
            "name": "[QYQ] - Sulsted Bus Station - QYQ - Sulsted - Denmark",
            "searchName": "QYQ-Sulsted Bus Station-Sulsted-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "AAL",
            "countryCode": "DK",
            "cityCode": "AAL",
            "cityName": "Aalborg",
            "name": "[AAL] - Aalborg - AAL - Aalborg - Denmark",
            "searchName": "AAL-Aalborg-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZTG",
            "countryCode": "DK",
            "cityCode": "AAL",
            "cityName": "Aalborg",
            "name": "[ZTG] - Aalborg Railway Station - AAL - Aalborg - Denmark",
            "searchName": "ZTG-AAL-Aalborg Railway Station-Aalborg-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZCC",
            "countryCode": "DE",
            "cityCode": "FKB",
            "cityName": "Karlsruhe",
            "name": "[ZCC] - Baden-Baden Railway Station - FKB - Karlsruhe - Germany",
            "searchName": "ZCC-FKB-Baden-Baden Railway Station-Karlsruhe/Baden Baden-Karlsruhe-Karlsruhe\\/Baden Baden-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "XET",
            "countryCode": "DE",
            "cityCode": "FKB",
            "cityName": "Karlsruhe",
            "name": "[XET] - Karlsruhe Bus Station - FKB - Karlsruhe - Germany",
            "searchName": "XET-FKB-Karlsruhe Bus Station-Karlsruhe/Baden Baden-Karlsruhe-Karlsruhe\\/Baden Baden-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "FLF",
            "countryCode": "DE",
            "cityCode": "FLF",
            "cityName": "Flensburg",
            "name": "[FLF] - Schaferhaus - FLF - Flensburg - Germany",
            "searchName": "FLF-Schaferhaus-Flensburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "FEL",
            "countryCode": "DE",
            "cityCode": "FEL",
            "cityName": "Fuerstenfeldbruck",
            "name": "[FEL] - Fuerstenfeldbruck - FEL - Fuerstenfeldbruck - Germany",
            "searchName": "FEL-Fuerstenfeldbruck-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "FDH",
            "countryCode": "DE",
            "cityCode": "FDH",
            "cityName": "Friedrichshafen",
            "name": "[FDH] - Friedrichshafen - FDH - Friedrichshafen - Germany",
            "searchName": "FDH-Friedrichshafen-Friedrichshafen Int. Apt-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "GTI",
            "countryCode": "DE",
            "cityCode": "GTI",
            "cityName": "Guettin",
            "name": "[GTI] - Guettin - GTI - Guettin - Germany",
            "searchName": "GTI-Guettin-Guettin Havalimani-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "QDU",
            "countryCode": "DE",
            "cityCode": "DUS",
            "cityName": "Dusseldorf",
            "name": "[QDU] - Station - DUS - Dusseldorf - Germany",
            "searchName": "QDU-DUS-Station-D-Dusseldorf-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "NRN",
            "countryCode": "DE",
            "cityCode": "DUS",
            "cityName": "Dusseldorf",
            "name": "[NRN] - Weeze - DUS - Dusseldorf - Germany",
            "searchName": "NRN-DUS-Weeze Niederrhein-Weeze-Niederrhein-Dusseldorf-D-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZVM",
            "countryCode": "DE",
            "cityCode": "HAJ",
            "cityName": "Hanover",
            "name": "[ZVM] - Messe-BF Railway Servi - HAJ - Hanover - Germany",
            "searchName": "ZVM-HAJ-Messe-BF Railway Servi-Hannover-Hanover-Han-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZVR",
            "countryCode": "DE",
            "cityCode": "HAJ",
            "cityName": "Hanover",
            "name": "[ZVR] - Hanover Railway - HAJ - Hanover - Germany",
            "searchName": "ZVR-HAJ-Hanover Railway-Hannover-Hanover-Han-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "MGL",
            "countryCode": "DE",
            "cityCode": "DUS",
            "cityName": "Dusseldorf",
            "name": "[MGL] - Moenchen-gl. - Dus Exp - DUS - Dusseldorf - Germany",
            "searchName": "MGL-DUS-Moenchen-gl. - Dus Exp-Gladbach-D-Dusseldorf-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "DUI",
            "countryCode": "DE",
            "cityCode": "DUI",
            "cityName": "Duisburg",
            "name": "[DUI] - Duisburg Hbf Railway Station - DUI - Duisburg - Germany",
            "searchName": "DUI-Duisburg Hbf Railway Station-Duisburg-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "CSO",
            "countryCode": "DE",
            "cityCode": "CSO",
            "cityName": "Cochstedt",
            "name": "[CSO] - Cochstedt - CSO - Cochstedt - Germany",
            "searchName": "CSO-Magdeburg Cochstedt-Cochstedt-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "CBU",
            "countryCode": "DE",
            "cityCode": "CBU",
            "cityName": "Cottbus",
            "name": "[CBU] - Cottbus - CBU - Cottbus - Germany",
            "searchName": "CBU-Flugplatz Drewitz-Cottbus-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZTT",
            "countryCode": "DE",
            "cityCode": "CBU",
            "cityName": "Cottbus",
            "name": "[ZTT] - Cottbus Railway - CBU - Cottbus - Germany",
            "searchName": "ZTT-CBU-Cottbus Railway-Cottbus-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "HHN",
            "countryCode": "DE",
            "cityCode": "FRA",
            "cityName": "Frankfurt",
            "name": "[HHN] - Frankfurt-Hahn - FRA - Frankfurt - Germany",
            "searchName": "HHN-FRA-Frankfurt Hahn-Frankfurt-Hahn-Frankfurt am Main-Francfort-Francoforte-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "QGV",
            "countryCode": "DE",
            "cityCode": "FRA",
            "cityName": "Frankfurt",
            "name": "[QGV] - Neu Isenburg - FRA - Frankfurt - Germany",
            "searchName": "QGV-FRA-Neu Isenburg-Neu Isenburg Havalimani-Frankfurt am Main-Frankfurt-Francfort-Francoforte-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "ZRB",
            "countryCode": "DE",
            "cityCode": "FRA",
            "cityName": "Frankfurt",
            "name": "[ZRB] - Frankfurt Hbf Railway Station - FRA - Frankfurt - Germany",
            "searchName": "ZRB-FRA-Frankfurt Hbf Railway Station-Frankfurt am Main-Frankfurt-Francfort-Francoforte-Allemagne-Duitsland-Alemanha-Saksamaa-Alemania-Germany-Germania-Jerman-Tyskland-Vacija-Vokietija--Deutschland-Almanya",
            "priority": 0
        },
        {
            "iataCode": "RKE",
            "countryCode": "DK",
            "cityCode": "CPH",
            "cityName": "Copenhagen",
            "name": "[RKE] - Roskilde Airport - CPH - Copenhagen - Denmark",
            "searchName": "RKE-CPH-Roskilde-Roskilde Airport-Roskilde Havalimani-Kastrup-Copenhagen-Copenhague-Copenaghen-Copenhag-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZGH",
            "countryCode": "DK",
            "cityCode": "CPH",
            "cityName": "Copenhagen",
            "name": "[ZGH] - Copenhagen H Rail Station - CPH - Copenhagen - Denmark",
            "searchName": "ZGH-CPH-Copenhagen H Rail Station-Kastrup-Copenhagen-Copenhague-Copenaghen-Copenhag-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "CNL",
            "countryCode": "DK",
            "cityCode": "CNL",
            "cityName": "Sindal",
            "name": "[CNL] - Sindal - CNL - Sindal - Denmark",
            "searchName": "CNL-Sindal-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "EBJ",
            "countryCode": "DK",
            "cityCode": "EBJ",
            "cityName": "Esbjerg",
            "name": "[EBJ] - Esbjerg - EBJ - Esbjerg - Denmark",
            "searchName": "EBJ-Esbjerg-Esbjerg Airport-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZBB",
            "countryCode": "DK",
            "cityCode": "EBJ",
            "cityName": "Esbjerg",
            "name": "[ZBB] - Esbjerg Railway Station - EBJ - Esbjerg - Denmark",
            "searchName": "ZBB-EBJ-Esbjerg Railway Station-Esbjerg-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "KRP",
            "countryCode": "DK",
            "cityCode": "KRP",
            "cityName": "Karup",
            "name": "[KRP] - Karup - KRP - Karup - Denmark",
            "searchName": "KRP-Karup-Karup Havalimani-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZGX",
            "countryCode": "DK",
            "cityCode": "ZGX",
            "cityName": "Viborg",
            "name": "[ZGX] - Viborg Railway Station - ZGX - Viborg - Denmark",
            "searchName": "ZGX-Viborg Railway Station-Viborg-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZIB",
            "countryCode": "DK",
            "cityCode": "ZIB",
            "cityName": "Nyborg",
            "name": "[ZIB] - Nyborg Railway Station - ZIB - Nyborg - Denmark",
            "searchName": "ZIB-Nyborg Railway Station-Nyborg-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZIJ",
            "countryCode": "DK",
            "cityCode": "ZIJ",
            "cityName": "Nykobing Sjaelland",
            "name": "[ZIJ] - Nykobing Sjaelland Railway Station - ZIJ - Nykobing Sjaelland - Denmark",
            "searchName": "ZIJ-Nykobing Sjaelland Railway Station-Nykobing Sjaelland-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZIL",
            "countryCode": "DK",
            "cityCode": "ZIL",
            "cityName": "Horsens",
            "name": "[ZIL] - Horsens Bus Station - ZIL - Horsens - Denmark",
            "searchName": "ZIL-Horsens Bus Station-Horsens-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZFK",
            "countryCode": "DK",
            "cityCode": "ZFK",
            "cityName": "Guderup",
            "name": "[ZFK] - Guderup Bus Station - ZFK - Guderup - Denmark",
            "searchName": "ZFK-Guderup Bus Station-Guderup-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZBT",
            "countryCode": "DK",
            "cityCode": "ZBT",
            "cityName": "Kolding",
            "name": "[ZBT] - Kolding Railway Station - ZBT - Kolding - Denmark",
            "searchName": "ZBT-Kolding Railway Station-Kolding-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZMW",
            "countryCode": "DK",
            "cityCode": "ZMW",
            "cityName": "Brande",
            "name": "[ZMW] - Brande Bus Station - ZMW - Brande - Denmark",
            "searchName": "ZMW-Brande Bus Station-Brande-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZIR",
            "countryCode": "DK",
            "cityCode": "ZIR",
            "cityName": "Randers",
            "name": "[ZIR] - Randers Railway Station - ZIR - Randers - Denmark",
            "searchName": "ZIR-Randers Railway Station-Randers-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZRY",
            "countryCode": "DK",
            "cityCode": "ZRY",
            "cityName": "Herning",
            "name": "[ZRY] - Herning Bus Station - ZRY - Herning - Denmark",
            "searchName": "ZRY-Herning Bus Station-Herning-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "XAK",
            "countryCode": "DK",
            "cityCode": "ZRY",
            "cityName": "Herning",
            "name": "[XAK] - Herning Railway Station - ZRY - Herning - Denmark",
            "searchName": "XAK-ZRY-Herning Railway Station-Herning-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZAW",
            "countryCode": "DK",
            "cityCode": "ZAW",
            "cityName": "Nykobing Mors",
            "name": "[ZAW] - Nykobing Mors Bus Station - ZAW - Nykobing Mors - Denmark",
            "searchName": "ZAW-Nykobing Mors Bus Station-Nykobing Mors-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZBJ",
            "countryCode": "DK",
            "cityCode": "ZBJ",
            "cityName": "Fredericia",
            "name": "[ZBJ] - Fredericia Railway Station - ZBJ - Fredericia - Denmark",
            "searchName": "ZBJ-Fredericia Railway Station-Fredericia-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "XVX",
            "countryCode": "DK",
            "cityCode": "XVX",
            "cityName": "Vejle",
            "name": "[XVX] - Vejle Railway Station - XVX - Vejle - Denmark",
            "searchName": "XVX-Vejle Railway Station-Vejle-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "EPS",
            "countryCode": "DO",
            "cityCode": "EPS",
            "cityName": "Samana",
            "name": "[EPS] - Arroyo Barril International - EPS - Samana - Dominican Republic",
            "searchName": "EPS-Arroyo Barril International-El Portillo-Samana-El Portillo\\/Samana-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "COZ",
            "countryCode": "DO",
            "cityCode": "COZ",
            "cityName": "Constanza",
            "name": "[COZ] - Constanza - COZ - Constanza - Dominican Republic",
            "searchName": "COZ-Constanza-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "CBJ",
            "countryCode": "DO",
            "cityCode": "CBJ",
            "cityName": "Cabo Rojo",
            "name": "[CBJ] - Cabo Rojo - CBJ - Cabo Rojo - Dominican Republic",
            "searchName": "CBJ-Cabo Rojo-Cabo Rojo National Havalimani-Pedernales-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "BRX",
            "countryCode": "DO",
            "cityCode": "BRX",
            "cityName": "Barahona",
            "name": "[BRX] - Barahona - BRX - Barahona - Dominican Republic",
            "searchName": "BRX-Barahona-María Montez Ululararasi Havalimani-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "AZS",
            "countryCode": "DO",
            "cityCode": "AZS",
            "cityName": "El Catey",
            "name": "[AZS] - SamanÃ¡ El Catey International - AZS - El Catey - Dominican Republic",
            "searchName": "AZS-Samana-Samaná El Catey International-El Catey Intl-Samaná El Catey-SamanÃ¡ El Catey International-Sanchez-El Catey-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "JBQ",
            "countryCode": "DO",
            "cityCode": "JBQ",
            "cityName": "Higuero",
            "name": "[JBQ] - Dr.Joaquin Balaguer - JBQ - Higuero - Dominican Republic",
            "searchName": "JBQ-Dr.Joaquin Balaguer-Dr Joaquin Balaguer Havalimani-Higuero-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "LRM",
            "countryCode": "DO",
            "cityCode": "LRM",
            "cityName": "La Romana",
            "name": "[LRM] - Casa de Campo International - LRM - La Romana - Dominican Republic",
            "searchName": "LRM-La Romana-Casa de Campo International-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "ZBU",
            "countryCode": "DK",
            "cityCode": "AAR",
            "cityName": "Aarhus",
            "name": "[ZBU] - Aarhus Limo - AAR - Aarhus - Denmark",
            "searchName": "ZBU-AAR-Aarhus Limo-Aarhus-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZID",
            "countryCode": "DK",
            "cityCode": "AAR",
            "cityName": "Aarhus",
            "name": "[ZID] - Aarhus Bus Station - AAR - Aarhus - Denmark",
            "searchName": "ZID-AAR-Busbahnhof-Aarhus Bus Station-Aarhus-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "ZJH",
            "countryCode": "DK",
            "cityCode": "AAR",
            "cityName": "Aarhus",
            "name": "[ZJH] - Aarhus H Railway Station - AAR - Aarhus - Denmark",
            "searchName": "ZJH-AAR-Aarhus H Railway Station-Aarhus-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "BLL",
            "countryCode": "DK",
            "cityCode": "BLL",
            "cityName": "Billund",
            "name": "[BLL] - Billund - BLL - Billund - Denmark",
            "searchName": "BLL-Billund-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "BYR",
            "countryCode": "DK",
            "cityCode": "BYR",
            "cityName": "Laeso Island",
            "name": "[BYR] - Laeso Airport - BYR - Laeso Island - Denmark",
            "searchName": "BYR-Laeso Airport-Laeso Havalimani-Laeso Island-Denmark-Danemarca-Dänemark-Dinamarca-Taani-Danmark-Tanska-Dánsko-Danimarka-Dánia-Danska-Danija-Dania-Danemark-Danimarca",
            "priority": 0
        },
        {
            "iataCode": "POP",
            "countryCode": "DO",
            "cityCode": "POP",
            "cityName": "Puerto Plata",
            "name": "[POP] - La Union - POP - Puerto Plata - Dominican Republic",
            "searchName": "POP-Puerto Plata-La Union-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "SNX",
            "countryCode": "DO",
            "cityCode": "SNX",
            "cityName": "Sabana De Mar",
            "name": "[SNX] - Sabana De Mar - SNX - Sabana De Mar - Dominican Republic",
            "searchName": "SNX-Sabana De Mar-Sabana de La Mar-República Dominicana-Dominicaanse Republiek-Republik Dominika--Dominican Republic-Dominikanische Republik-République dominicaine-Repubblica Dominicana",
            "priority": 0
        },
        {
            "iataCode": "TAF",
            "countryCode": "DZ",
            "cityCode": "ORN",
            "cityName": "Oran",
            "name": "[TAF] - Tafaraoui - ORN - Oran - Algeria",
            "searchName": "TAF-ORN-Tafaraoui-Oran-Or-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "QSF",
            "countryCode": "DZ",
            "cityCode": "QSF",
            "cityName": "Sétif",
            "name": "[QSF] - Setif - QSF - Sétif - Algeria",
            "searchName": "QSF-Setif-Setif Arpt-S-Sétif-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "OGX",
            "countryCode": "DZ",
            "cityCode": "OGX",
            "cityName": "Ouargla",
            "name": "[OGX] - Ain Beida - OGX - Ouargla - Algeria",
            "searchName": "OGX-Ain Beida-Ouargla-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "MZW",
            "countryCode": "DZ",
            "cityCode": "MZW",
            "cityName": "Mechria",
            "name": "[MZW] - Mechria - MZW - Mechria - Algeria",
            "searchName": "MZW-Mechria-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "MQV",
            "countryCode": "DZ",
            "cityCode": "MQV",
            "cityName": "Mostaganem",
            "name": "[MQV] - Mostaganem - MQV - Mostaganem - Algeria",
            "searchName": "MQV-Mostaganem-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "MUW",
            "countryCode": "DZ",
            "cityCode": "MUW",
            "cityName": "Mascara-Ghriss",
            "name": "[MUW] - Mascara-Ghriss - MUW - Mascara-Ghriss - Algeria",
            "searchName": "MUW-Mascara-Ghriss-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "LOO",
            "countryCode": "DZ",
            "cityCode": "LOO",
            "cityName": "Laghouat",
            "name": "[LOO] - LMekrareg - LOO - Laghouat - Algeria",
            "searchName": "LOO-L Mekrareg-LMekrareg-Laghouat-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "IAM",
            "countryCode": "DZ",
            "cityCode": "IAM",
            "cityName": "In Amenas",
            "name": "[IAM] - In Amenas - IAM - In Amenas - Algeria",
            "searchName": "IAM-In Amenas-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "INF",
            "countryCode": "DZ",
            "cityCode": "INF",
            "cityName": "In Guezzam",
            "name": "[INF] - Newark Liberty International - INF - In Guezzam - Algeria",
            "searchName": "INF-Guezzam Airport-Newark Liberty International-In Guezzam-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "INZ",
            "countryCode": "DZ",
            "cityCode": "INZ",
            "cityName": "In Salah",
            "name": "[INZ] - In Salah - INZ - In Salah - Algeria",
            "searchName": "INZ-In Salah-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "HRM",
            "countryCode": "DZ",
            "cityCode": "HRM",
            "cityName": "Hassi R mel",
            "name": "[HRM] - Tilrempt - HRM - Hassi R mel - Algeria",
            "searchName": "HRM-Flugafen Tilrempt-Tilrempt-Hassi R Mel-Hassi R mel-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "HME",
            "countryCode": "DZ",
            "cityCode": "HME",
            "cityName": "Hassi Messaoud",
            "name": "[HME] - Oued Irara Airport - HME - Hassi Messaoud - Algeria",
            "searchName": "HME-Hassi Messaoud-Oued Irara Airport-Oued Irara Apt-Oued Irara-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "GJL",
            "countryCode": "DZ",
            "cityCode": "GJL",
            "cityName": "Jijel",
            "name": "[GJL] - Jijel - GJL - Jijel - Algeria",
            "searchName": "GJL-Jijel-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "GHA",
            "countryCode": "DZ",
            "cityCode": "GHA",
            "cityName": "Ghardaïa",
            "name": "[GHA] - Noumerate - GHA - Ghardaïa - Algeria",
            "searchName": "GHA-Noumerate-Ghardaia-Gharda-Ghardaïa-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "AZR",
            "countryCode": "DZ",
            "cityCode": "AZR",
            "cityName": "Adrar",
            "name": "[AZR] - Adrar - AZR - Adrar - Algeria",
            "searchName": "AZR-Adrar-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "AAE",
            "countryCode": "DZ",
            "cityCode": "AAE",
            "cityName": "Annaba",
            "name": "[AAE] - Les Salines - AAE - Annaba - Algeria",
            "searchName": "AAE-Annaba-Les Salines-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "ENC",
            "countryCode": "FR",
            "cityCode": "ETZ",
            "cityName": "Metz",
            "name": "[ENC] - Essey - ETZ - Metz - France",
            "searchName": "ENC-ETZ-Essey-Essey Havalimani-Metz/Nancy-Metz-Nancy/Metz-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "MZM",
            "countryCode": "FR",
            "cityCode": "ETZ",
            "cityName": "Metz",
            "name": "[MZM] - Frescaty - ETZ - Metz - France",
            "searchName": "MZM-ETZ-Frescaty-Frescaty Havalimani-Metz/Nancy-Metz-Nancy/Metz-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XZI",
            "countryCode": "FR",
            "cityCode": "ETZ",
            "cityName": "Metz",
            "name": "[XZI] - Lorraine TGV Rail Station - ETZ - Metz - France",
            "searchName": "XZI-ETZ-Lorraine TGV Rail Station-Gare de Lorraine TGV-Metz/Nancy-Metz-Nancy/Metz-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "EPL",
            "countryCode": "FR",
            "cityCode": "EPL",
            "cityName": "Epinal",
            "name": "[EPL] - Mirecourt - EPL - Epinal - France",
            "searchName": "EPL-Mirecourt-Epinal-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "EGC",
            "countryCode": "FR",
            "cityCode": "EGC",
            "cityName": "Bergerac",
            "name": "[EGC] - Roumanieres - EGC - Bergerac - France",
            "searchName": "EGC-Bergerac-Roumanieres-Bergerac Roumaniere Apt-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "EBU",
            "countryCode": "FR",
            "cityCode": "EBU",
            "cityName": "St Etienne",
            "name": "[EBU] - Boutheon - EBU - St Etienne - France",
            "searchName": "EBU-Saint Etienne-Boutheon-St Etienne-Saint-Etienne-de-Valoux--France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "EDM",
            "countryCode": "FR",
            "cityCode": "EDM",
            "cityName": "La Roche",
            "name": "[EDM] - Les Ajoncs - EDM - La Roche - France",
            "searchName": "EDM-Les Ajoncs-Les Ajoncs Havalimani-La Roche-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "DPE",
            "countryCode": "FR",
            "cityCode": "DPE",
            "cityName": "Dieppe",
            "name": "[DPE] - Dieppe - DPE - Dieppe - France",
            "searchName": "DPE-Dieppe-Dieppe Havalimani-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "DOL",
            "countryCode": "FR",
            "cityCode": "DOL",
            "cityName": "Deauville",
            "name": "[DOL] - St Gatien - DOL - Deauville - France",
            "searchName": "DOL-Deauville-St Gatien-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "DNR",
            "countryCode": "FR",
            "cityCode": "DNR",
            "cityName": "Dinard",
            "name": "[DNR] - Pleurtuit - DNR - Dinard - France",
            "searchName": "DNR-Saint Malo Dinard-Pleurtuit-Dinard Plertuit Apt.-Dinard-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "DIJ",
            "countryCode": "FR",
            "cityCode": "DIJ",
            "cityName": "Dijon",
            "name": "[DIJ] - Dijon - DIJ - Dijon - France",
            "searchName": "DIJ-Dijon-Digione-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "DLE",
            "countryCode": "FR",
            "cityCode": "DLE",
            "cityName": "Dole",
            "name": "[DLE] - Tavaux - DLE - Dole - France",
            "searchName": "DLE-Tavaux-Dole-Jura Havalimani-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "DCM",
            "countryCode": "FR",
            "cityCode": "DCM",
            "cityName": "Castres",
            "name": "[DCM] - Mazamet - DCM - Castres - France",
            "searchName": "DCM-Castres-Mazamet-Mazamet Apt-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CSF",
            "countryCode": "FR",
            "cityCode": "CSF",
            "cityName": "Creil",
            "name": "[CSF] - Creil - CSF - Creil - France",
            "searchName": "CSF-Creil-Creil Havalimani-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CQF",
            "countryCode": "FR",
            "cityCode": "CQF",
            "cityName": "Calais",
            "name": "[CQF] - Calais - CQF - Calais - France",
            "searchName": "CQF-Calais-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XFF",
            "countryCode": "FR",
            "cityCode": "CQF",
            "cityName": "Calais",
            "name": "[XFF] - Calais-Frethun Rail Station - CQF - Calais - France",
            "searchName": "XFF-CQF-Calais-Frethun Rail Station-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CNG",
            "countryCode": "FR",
            "cityCode": "CNG",
            "cityName": "Cognac",
            "name": "[CNG] - Parvaud - CNG - Cognac - France",
            "searchName": "CNG-Parvaud-Parvaud Havalimani-Cognac-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CMR",
            "countryCode": "FR",
            "cityCode": "CMR",
            "cityName": "Colmar",
            "name": "[CMR] - Colmar-houssen - CMR - Colmar - France",
            "searchName": "CMR-Colmar-houssen-Colmar Houssen Havalimani-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CMF",
            "countryCode": "FR",
            "cityCode": "CMF",
            "cityName": "Chambery",
            "name": "[CMF] - Chambery - CMF - Chambery - France",
            "searchName": "CMF-Chambery-Chamb-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CTT",
            "countryCode": "FR",
            "cityCode": "CTT",
            "cityName": "Le Castellet",
            "name": "[CTT] - Le Castellet - CTT - Le Castellet - France",
            "searchName": "CTT-Le Castellet-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CVF",
            "countryCode": "FR",
            "cityCode": "CVF",
            "cityName": "Courchevel",
            "name": "[CVF] - Courchevel - CVF - Courchevel - France",
            "searchName": "CVF-Courchevel-Courchevel Havalimani-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CET",
            "countryCode": "FR",
            "cityCode": "CET",
            "cityName": "Cholet",
            "name": "[CET] - Le Pontreau - CET - Cholet - France",
            "searchName": "CET-Le Pontreau-Le Pontreau Havalimani-Cholet-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CFE",
            "countryCode": "FR",
            "cityCode": "CFE",
            "cityName": "Clermont-Ferrand",
            "name": "[CFE] - Clermont/Ferrand Auvergne International (Aulnat) - CFE - Clermont-Ferrand - France",
            "searchName": "CFE-Clermont-Ferrand-Clermont/Ferrand Auvergne International (Aulnat)-Aulnat-Auvergne Apt.-ferrand-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CEQ",
            "countryCode": "FR",
            "cityCode": "CEQ",
            "cityName": "Cannes",
            "name": "[CEQ] - Mandelieu - CEQ - Cannes - France",
            "searchName": "CEQ-Mandelieu-Cannes-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "JCA",
            "countryCode": "FR",
            "cityCode": "CEQ",
            "cityName": "Cannes",
            "name": "[JCA] - Croisette Heliport - CEQ - Cannes - France",
            "searchName": "JCA-CEQ-Croisette Heliport-Coisette Heliport-Cannes-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "QYW",
            "countryCode": "FR",
            "cityCode": "CEQ",
            "cityName": "Cannes",
            "name": "[QYW] - Vieux Port - CEQ - Cannes - France",
            "searchName": "QYW-CEQ-Vieux Port-Cannes-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CER",
            "countryCode": "FR",
            "cityCode": "CER",
            "cityName": "Cherbourg",
            "name": "[CER] - Maupertus - CER - Cherbourg - France",
            "searchName": "CER-Maupertus-Maupertius Havalimani-Cherbourg-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "BZR",
            "countryCode": "FR",
            "cityCode": "BZR",
            "cityName": "Beziers",
            "name": "[BZR] - Beziers Vias - BZR - Beziers - France",
            "searchName": "BZR-Beziers-Beziers Vias-Beziers Vias Apt-B-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CCF",
            "countryCode": "FR",
            "cityCode": "CCF",
            "cityName": "Carcassonne",
            "name": "[CCF] - Salvaza - CCF - Carcassonne - France",
            "searchName": "CCF-Carcassonne-Salvaza-Carcasona-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CFR",
            "countryCode": "FR",
            "cityCode": "CFR",
            "cityName": "Caen",
            "name": "[CFR] - Carpiquet - CFR - Caen - France",
            "searchName": "CFR-Caen-Carpiquet-Carpiquet Apt.-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CHR",
            "countryCode": "FR",
            "cityCode": "CHR",
            "cityName": "Chateauroux",
            "name": "[CHR] - Chateauroux - CHR - Chateauroux - France",
            "searchName": "CHR-Chateauroux-Chateauroux Havalimani-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "CLY",
            "countryCode": "FR",
            "cityCode": "CLY",
            "cityName": "Calvi",
            "name": "[CLY] - Ste Catherine - CLY - Calvi - France",
            "searchName": "CLY-Calvi-Ste Catherine-Sainte-Catherine Apt.-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "BYF",
            "countryCode": "FR",
            "cityCode": "BYF",
            "cityName": "Albert",
            "name": "[BYF] - Bray - BYF - Albert - France",
            "searchName": "BYF-Bray-Albert Havalimani-Albert-Meaulte-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "BVE",
            "countryCode": "FR",
            "cityCode": "BVE",
            "cityName": "Brive-La-Gaillarde",
            "name": "[BVE] - Laroche - BVE - Brive-La-Gaillarde - France",
            "searchName": "BVE-Brive-la-Gaillarde-Laroche-La-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "TVU",
            "countryCode": "FJ",
            "cityCode": "TVU",
            "cityName": "Taveuni",
            "name": "[TVU] - Matei - TVU - Taveuni - Fiji",
            "searchName": "TVU-Taveuni-Matei-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "TTL",
            "countryCode": "FJ",
            "cityCode": "TTL",
            "cityName": "Turtle Island",
            "name": "[TTL] - Turtle Island - TTL - Turtle Island - Fiji",
            "searchName": "TTL-Turtle Island-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "KAY",
            "countryCode": "FJ",
            "cityCode": "KAY",
            "cityName": "Wakaya Island",
            "name": "[KAY] - Wakaya Island - KAY - Wakaya Island - Fiji",
            "searchName": "KAY-Wakaya Island-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "KDV",
            "countryCode": "FJ",
            "cityCode": "KDV",
            "cityName": "Kandavu",
            "name": "[KDV] - Kandavu - KDV - Kandavu - Fiji",
            "searchName": "KDV-Kandavu-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "KXF",
            "countryCode": "FJ",
            "cityCode": "KXF",
            "cityName": "Koro Island",
            "name": "[KXF] - Koro Island - KXF - Koro Island - Fiji",
            "searchName": "KXF-Koro Island-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "KVU",
            "countryCode": "FJ",
            "cityCode": "KVU",
            "cityName": "Korolevu",
            "name": "[KVU] - Korolevu - KVU - Korolevu - Fiji",
            "searchName": "KVU-Korolevu-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "LBS",
            "countryCode": "FJ",
            "cityCode": "LBS",
            "cityName": "Labasa",
            "name": "[LBS] - Labasa - LBS - Labasa - Fiji",
            "searchName": "LBS-Labasa-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "LEV",
            "countryCode": "FJ",
            "cityCode": "LEV",
            "cityName": "Bureta",
            "name": "[LEV] - Levuka Airfield - LEV - Bureta - Fiji",
            "searchName": "LEV-Levuka Airfield-Cuneo Levaldigi-Coni-Bureta-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "LKB",
            "countryCode": "FJ",
            "cityCode": "LKB",
            "cityName": "Lakeba",
            "name": "[LKB] - Lakeba - LKB - Lakeba - Fiji",
            "searchName": "LKB-Lakeba-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "LUC",
            "countryCode": "FJ",
            "cityCode": "LUC",
            "cityName": "Laucala Island",
            "name": "[LUC] - Laucala Island - LUC - Laucala Island - Fiji",
            "searchName": "LUC-Laucala Island-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "ICI",
            "countryCode": "FJ",
            "cityCode": "ICI",
            "cityName": "Cicia",
            "name": "[ICI] - Cicia - ICI - Cicia - Fiji",
            "searchName": "ICI-Cicia-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "CST",
            "countryCode": "FJ",
            "cityCode": "CST",
            "cityName": "Castaway",
            "name": "[CST] - Castaway - CST - Castaway - Fiji",
            "searchName": "CST-Castaway-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "BXL",
            "countryCode": "FJ",
            "cityCode": "BXL",
            "cityName": "Blue Lagoon",
            "name": "[BXL] - Blue Lagoon - BXL - Blue Lagoon - Fiji",
            "searchName": "BXL-Blue Lagoon-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "MPN",
            "countryCode": "FK",
            "cityCode": "MPN",
            "cityName": "Mount Pleasant",
            "name": "[MPN] - Mount Pleasant - MPN - Mount Pleasant - Falkland Islands",
            "searchName": "MPN-Mount Pleasant-Falkland Islands-Falklandinseln-Falkland Adalari-Islas Malvinas-Îles Malouines-Isole Falkland",
            "priority": 0
        },
        {
            "iataCode": "PSY",
            "countryCode": "FK",
            "cityCode": "PSY",
            "cityName": "Port Stanley",
            "name": "[PSY] - Port Stanley - PSY - Port Stanley - Falkland Islands",
            "searchName": "PSY-Port Stanley-Falkland Islands-Falklandinseln-Falkland Adalari-Islas Malvinas-Îles Malouines-Isole Falkland",
            "priority": 0
        },
        {
            "iataCode": "PNI",
            "countryCode": "FM",
            "cityCode": "PNI",
            "cityName": "Pohnpei",
            "name": "[PNI] - Pohnpei - PNI - Pohnpei - Micronesia",
            "searchName": "PNI-Pohnpei Island-Pohnpei-Mikronézia-Micronèsia-Mikronezya-Mikronésie-Mikronesia-Mikronesien-Micronezia-Mikroneesia-Mikronezija-FSM-Micronesia-États fédérés de Micronésie",
            "priority": 0
        },
        {
            "iataCode": "TKK",
            "countryCode": "FM",
            "cityCode": "TKK",
            "cityName": "Truk",
            "name": "[TKK] - Truk - TKK - Truk - Micronesia",
            "searchName": "TKK-Truk Island-Truk-Mikronézia-Micronèsia-Mikronezya-Mikronésie-Mikronesia-Mikronesien-Micronezia-Mikroneesia-Mikronezija-FSM-Micronesia-États fédérés de Micronésie",
            "priority": 0
        },
        {
            "iataCode": "ULI",
            "countryCode": "FM",
            "cityCode": "ULI",
            "cityName": "Ulithi",
            "name": "[ULI] - Ulithi - ULI - Ulithi - Micronesia",
            "searchName": "ULI-Ulithi-Mikronézia-Micronèsia-Mikronezya-Mikronésie-Mikronesia-Mikronesien-Micronezia-Mikroneesia-Mikronezija-FSM-Micronesia-États fédérés de Micronésie",
            "priority": 0
        },
        {
            "iataCode": "YAP",
            "countryCode": "FM",
            "cityCode": "YAP",
            "cityName": "Yap",
            "name": "[YAP] - Yap International - YAP - Yap - Micronesia",
            "searchName": "YAP-Colonia Mikronesien-Yap International-Yap-Mikronézia-Micronèsia-Mikronezya-Mikronésie-Mikronesia-Mikronesien-Micronezia-Mikroneesia-Mikronezija-FSM-Micronesia-États fédérés de Micronésie",
            "priority": 0
        },
        {
            "iataCode": "KSA",
            "countryCode": "FM",
            "cityCode": "KSA",
            "cityName": "Kosrae",
            "name": "[KSA] - Kosrae - KSA - Kosrae - Micronesia",
            "searchName": "KSA-Kosrae Island-Kosrae-Mikronézia-Micronèsia-Mikronezya-Mikronésie-Mikronesia-Mikronesien-Micronezia-Mikroneesia-Mikronezija-FSM-Micronesia-États fédérés de Micronésie",
            "priority": 0
        },
        {
            "iataCode": "FAE",
            "countryCode": "FO",
            "cityCode": "FAE",
            "cityName": "Sorvágur",
            "name": "[FAE] - Vagar - FAE - Sorvágur - Faroe Islands",
            "searchName": "FAE-Sorvag-Vagar-Faroe Islands-Sorv-Sorvágur-Faroerinseln-Faroe Adalari-Islas Feroe-Îles Féroé-Isole Faroe",
            "priority": 0
        },
        {
            "iataCode": "EVX",
            "countryCode": "FR",
            "cityCode": "EVX",
            "cityName": "Evreux",
            "name": "[EVX] - Evreux - EVX - Evreux - France",
            "searchName": "EVX-Evreux-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XUT",
            "countryCode": "ES",
            "cityCode": "MJV",
            "cityName": "Murcia",
            "name": "[XUT] - Murcia Railway Station - MJV - Murcia - Spain",
            "searchName": "XUT-MJV-Murcia Railway Station-Murcia-Murcie-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "ODB",
            "countryCode": "ES",
            "cityCode": "ODB",
            "cityName": "Cordoba",
            "name": "[ODB] - Cordoba - ODB - Cordoba - Spain",
            "searchName": "ODB-Cordoba-Cordoba Havalimani-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "XOJ",
            "countryCode": "ES",
            "cityCode": "ODB",
            "cityName": "Cordoba",
            "name": "[XOJ] - Cordoba Central Railway Station - ODB - Cordoba - Spain",
            "searchName": "XOJ-ODB-Cordoba Central Railway Station-Cordoba-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "NDO",
            "countryCode": "ES",
            "cityCode": "NDO",
            "cityName": "La Palma Del Condado",
            "name": "[NDO] - La Palma Del Condado - NDO - La Palma Del Condado - Spain",
            "searchName": "NDO-La Palma Del Condado-La Palma del Condado-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "RMU",
            "countryCode": "ES",
            "cityCode": "RMU",
            "cityName": "Corvera",
            "name": "[RMU] - Corvera International - RMU - Corvera - Spain",
            "searchName": "RMU-Corvera International-Corvera-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "RJL",
            "countryCode": "ES",
            "cityCode": "RJL",
            "cityName": "Logrono",
            "name": "[RJL] - Agoncillo - RJL - Logrono - Spain",
            "searchName": "RJL-Logroño-Agoncillo-Agoncillo Havalimani-Logro-Logrono-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "REU",
            "countryCode": "ES",
            "cityCode": "REU",
            "cityName": "Reus",
            "name": "[REU] - Reus - REU - Reus - Spain",
            "searchName": "REU-Barcelona Reus-Reus-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "EER",
            "countryCode": "ES",
            "cityCode": "REU",
            "cityName": "Reus",
            "name": "[EER] - Reus Railway Station - REU - Reus - Spain",
            "searchName": "EER-REU-Reus Railway Station-Reus-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "RGS",
            "countryCode": "ES",
            "cityCode": "RGS",
            "cityName": "Burgos",
            "name": "[RGS] - Burgos - RGS - Burgos - Spain",
            "searchName": "RGS-Burgos-Austin-Bergstrom International-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "XJU",
            "countryCode": "ES",
            "cityCode": "RGS",
            "cityName": "Burgos",
            "name": "[XJU] - Burgos Bus Station - RGS - Burgos - Spain",
            "searchName": "XJU-RGS-Burgos Bus Station-Burgos-Austin-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "ROZ",
            "countryCode": "ES",
            "cityCode": "ROZ",
            "cityName": "Rota",
            "name": "[ROZ] - US Naval Station - ROZ - Rota - Spain",
            "searchName": "ROZ-US Naval Station-Rota-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "RRA",
            "countryCode": "ES",
            "cityCode": "RRA",
            "cityName": "Ronda",
            "name": "[RRA] - Ronda - RRA - Ronda - Spain",
            "searchName": "RRA-Ronda-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "PXS",
            "countryCode": "ES",
            "cityCode": "PXS",
            "cityName": "Puerto De Santa Maria",
            "name": "[PXS] - Puerto De Santa Maria - PXS - Puerto De Santa Maria - Spain",
            "searchName": "PXS-Puerto De Santa Maria-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "QGN",
            "countryCode": "ES",
            "cityCode": "QGN",
            "cityName": "Tarragona",
            "name": "[QGN] - Tarragona/Camp Rail Station - QGN - Tarragona - Spain",
            "searchName": "QGN-Tarragona/Camp Rail Station-Tarragona-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "OZI",
            "countryCode": "ES",
            "cityCode": "OZI",
            "cityName": "Bobadilla",
            "name": "[OZI] - Bobadilla - OZI - Bobadilla - Spain",
            "searchName": "OZI-Bobadilla-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "OZL",
            "countryCode": "ES",
            "cityCode": "OZL",
            "cityName": "Orzola",
            "name": "[OZL] - Orzola Ferry Port - OZL - Orzola - Spain",
            "searchName": "OZL-Orzola Ferry Port-Orzola-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "OZP",
            "countryCode": "ES",
            "cityCode": "OZP",
            "cityName": "Moron",
            "name": "[OZP] - Moron - OZP - Moron - Spain",
            "searchName": "OZP-Moron-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "OZU",
            "countryCode": "ES",
            "cityCode": "OZU",
            "cityName": "Montilla",
            "name": "[OZU] - Montilla - OZU - Montilla - Spain",
            "searchName": "OZU-Montilla-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "OVD",
            "countryCode": "ES",
            "cityCode": "OVD",
            "cityName": "Oviedo",
            "name": "[OVD] - Asturias - OVD - Oviedo - Spain",
            "searchName": "OVD-Oviedo-Asturias-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "PNA",
            "countryCode": "ES",
            "cityCode": "PNA",
            "cityName": "Pamplona",
            "name": "[PNA] - Pamplona - PNA - Pamplona - Spain",
            "searchName": "PNA-Pamplona-Pampelune-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "KTQ",
            "countryCode": "FI",
            "cityCode": "KTQ",
            "cityName": "Kitee",
            "name": "[KTQ] - Kitee - KTQ - Kitee - Finland",
            "searchName": "KTQ-Kitee-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "KUO",
            "countryCode": "FI",
            "cityCode": "KUO",
            "cityName": "Kuopio",
            "name": "[KUO] - Kuopio - KUO - Kuopio - Finland",
            "searchName": "KUO-Kuopio-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "JOE",
            "countryCode": "FI",
            "cityCode": "JOE",
            "cityName": "Joensuu",
            "name": "[JOE] - Joensuu - JOE - Joensuu - Finland",
            "searchName": "JOE-Joensuu-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "KEM",
            "countryCode": "FI",
            "cityCode": "KEM",
            "cityName": "Kemi",
            "name": "[KEM] - Kemi/Tornio - KEM - Kemi - Finland",
            "searchName": "KEM-Kemi-Kemi/Tornio-Kemi Tornio Havalimani-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "JYV",
            "countryCode": "FI",
            "cityCode": "JYV",
            "cityName": "Jyvaskyla",
            "name": "[JYV] - Jyvaskyla - JYV - Jyvaskyla - Finland",
            "searchName": "JYV-Jyvaskyla-Jyv-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "KAJ",
            "countryCode": "FI",
            "cityCode": "KAJ",
            "cityName": "Kajaani",
            "name": "[KAJ] - Kajaani - KAJ - Kajaani - Finland",
            "searchName": "KAJ-Kajaani-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "KAO",
            "countryCode": "FI",
            "cityCode": "KAO",
            "cityName": "Kuusamo",
            "name": "[KAO] - Kuusamo - KAO - Kuusamo - Finland",
            "searchName": "KAO-Kuusamo-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "KAU",
            "countryCode": "FI",
            "cityCode": "KAU",
            "cityName": "Kauhava",
            "name": "[KAU] - Kauhava - KAU - Kauhava - Finland",
            "searchName": "KAU-Kauhava-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "KOK",
            "countryCode": "FI",
            "cityCode": "KOK",
            "cityName": "Kokkola",
            "name": "[KOK] - Kruunupyy - KOK - Kokkola - Finland",
            "searchName": "KOK-Kokkola-Kruunupyy-Kruunupyy Apt-Kokkola/Pietarsaari-Kokkola\\/Pietarsaari-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "KHJ",
            "countryCode": "FI",
            "cityCode": "KHJ",
            "cityName": "Kauhajoki",
            "name": "[KHJ] - Kauhajoki - KHJ - Kauhajoki - Finland",
            "searchName": "KHJ-Kauhajoki-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "KEV",
            "countryCode": "FI",
            "cityCode": "KEV",
            "cityName": "Kuorevesi",
            "name": "[KEV] - Halli - KEV - Kuorevesi - Finland",
            "searchName": "KEV-Halli-Kuorevesi-Kuorevisi-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "TKU",
            "countryCode": "FI",
            "cityCode": "TKU",
            "cityName": "Turku",
            "name": "[TKU] - Turku - TKU - Turku - Finland",
            "searchName": "TKU-Turku-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "TMP",
            "countryCode": "FI",
            "cityCode": "TMP",
            "cityName": "Tampere",
            "name": "[TMP] - Tampere-pirkkala - TMP - Tampere - Finland",
            "searchName": "TMP-Tampere-pirkkala-Pirkkala-Pirkkala Apt-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "XTP",
            "countryCode": "FI",
            "cityCode": "TMP",
            "cityName": "Tampere",
            "name": "[XTP] - Tampere Bus Station - TMP - Tampere - Finland",
            "searchName": "XTP-TMP-Tampere Bus Station-Tampere-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "SJY",
            "countryCode": "FI",
            "cityCode": "SJY",
            "cityName": "Seinajoki",
            "name": "[SJY] - Ilmajoki - SJY - Seinajoki - Finland",
            "searchName": "SJY-Seinäjoki-Ilmajoki-Ilmajoki Airport-Sein-Seinajoki-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "SOT",
            "countryCode": "FI",
            "cityCode": "SOT",
            "cityName": "Sodankyla",
            "name": "[SOT] - Sodankyla - SOT - Sodankyla - Finland",
            "searchName": "SOT-Sodankyla-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "SVL",
            "countryCode": "FI",
            "cityCode": "SVL",
            "cityName": "Savonlinna",
            "name": "[SVL] - Savonlinna - SVL - Savonlinna - Finland",
            "searchName": "SVL-Savonlinna-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "VRK",
            "countryCode": "FI",
            "cityCode": "VRK",
            "cityName": "Joroinen",
            "name": "[VRK] - Varkaus - VRK - Joroinen - Finland",
            "searchName": "VRK-Varkaus-Joroinen-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "UTI",
            "countryCode": "FI",
            "cityCode": "UTI",
            "cityName": "Valkeala",
            "name": "[UTI] - Utti - UTI - Valkeala - Finland",
            "searchName": "UTI-Utti-Valkeala-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "VAA",
            "countryCode": "FI",
            "cityCode": "VAA",
            "cityName": "Vaasa",
            "name": "[VAA] - Vaasa - VAA - Vaasa - Finland",
            "searchName": "VAA-Vaasa-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "XGX",
            "countryCode": "FI",
            "cityCode": "XGX",
            "cityName": "Levi",
            "name": "[XGX] - Levi Bus Station - XGX - Levi - Finland",
            "searchName": "XGX-Levi Bus Station-Levi-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "XGQ",
            "countryCode": "FI",
            "cityCode": "XGQ",
            "cityName": "Saariselka",
            "name": "[XGQ] - Saariselka Bus Station - XGQ - Saariselka - Finland",
            "searchName": "XGQ-Saariselka Bus Station-Saariselka-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "XHA",
            "countryCode": "FI",
            "cityCode": "XHA",
            "cityName": "Yllasjarvi",
            "name": "[XHA] - Yllasjarvi Bus Station - XHA - Yllasjarvi - Finland",
            "searchName": "XHA-Yllasjarvi Bus Station-Yllasjarvi-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "XHC",
            "countryCode": "FI",
            "cityCode": "XHC",
            "cityName": "Akaslompolo",
            "name": "[XHC] - Akaslompolo Bus Station - XHC - Akaslompolo - Finland",
            "searchName": "XHC-Akaslompolo Bus Station-Akaslompolo-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "XHD",
            "countryCode": "FI",
            "cityCode": "XHD",
            "cityName": "Ruka",
            "name": "[XHD] - Ruka Bus Station - XHD - Ruka - Finland",
            "searchName": "XHD-Ruka Bus Station-Ruka-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "XQS",
            "countryCode": "FI",
            "cityCode": "XQS",
            "cityName": "Sotkamo",
            "name": "[XQS] - Sotkamo Bus Station - XQS - Sotkamo - Finland",
            "searchName": "XQS-Sotkamo Bus Station-Sotkamo-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "XPY",
            "countryCode": "FI",
            "cityCode": "XPY",
            "cityName": "Pyha",
            "name": "[XPY] - Pyha Bus Station - XPY - Pyha - Finland",
            "searchName": "XPY-Pyha Bus Station-Pyha-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "XVM",
            "countryCode": "FI",
            "cityCode": "XVM",
            "cityName": "Vuokatti",
            "name": "[XVM] - Vuokatti Bus Station - XVM - Vuokatti - Finland",
            "searchName": "XVM-Vuokatti Bus Station-Vuokatti-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "MIK",
            "countryCode": "FI",
            "cityCode": "MIK",
            "cityName": "Mikkeli",
            "name": "[MIK] - Mikkeli - MIK - Mikkeli - Finland",
            "searchName": "MIK-Mikkeli-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "QVY",
            "countryCode": "FI",
            "cityCode": "QVY",
            "cityName": "Kouvola",
            "name": "[QVY] - Kouvola Bus Station - QVY - Kouvola - Finland",
            "searchName": "QVY-Kouvola Bus Station-Kouvola-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "RVN",
            "countryCode": "FI",
            "cityCode": "RVN",
            "cityName": "Rovaniemi",
            "name": "[RVN] - Rovaniemi - RVN - Rovaniemi - Finland",
            "searchName": "RVN-Rovaniemi-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "OUL",
            "countryCode": "FI",
            "cityCode": "OUL",
            "cityName": "Oulu",
            "name": "[OUL] - Oulu - OUL - Oulu - Finland",
            "searchName": "OUL-Oulu-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "POR",
            "countryCode": "FI",
            "cityCode": "POR",
            "cityName": "Pori",
            "name": "[POR] - Pori - POR - Pori - Finland",
            "searchName": "POR-Pori-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "ZLV",
            "countryCode": "FI",
            "cityCode": "ZLV",
            "cityName": "Luosto",
            "name": "[ZLV] - Luosto Bus Station - ZLV - Luosto - Finland",
            "searchName": "ZLV-Luosto Bus Station-Luosto-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "YLI",
            "countryCode": "FI",
            "cityCode": "YLI",
            "cityName": "Ylivieska",
            "name": "[YLI] - Ylivieska - YLI - Ylivieska - Finland",
            "searchName": "YLI-Ylivieska-Ylivieska Havalimani-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "PHR",
            "countryCode": "FJ",
            "cityCode": "PHR",
            "cityName": "Pacific Harbor",
            "name": "[PHR] - Pacific Harbor - PHR - Pacific Harbor - Fiji",
            "searchName": "PHR-Pacific Harbor-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "ONU",
            "countryCode": "FJ",
            "cityCode": "ONU",
            "cityName": "Ono I Lau",
            "name": "[ONU] - Ono I Lau - ONU - Ono I Lau - Fiji",
            "searchName": "ONU-Ono I Lau-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "RTA",
            "countryCode": "FJ",
            "cityCode": "RTA",
            "cityName": "Rotuma Island",
            "name": "[RTA] - Rotuma Island - RTA - Rotuma Island - Fiji",
            "searchName": "RTA-Rotuma Island--Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "RBI",
            "countryCode": "FJ",
            "cityCode": "RBI",
            "cityName": "Rabi",
            "name": "[RBI] - Rabi - RBI - Rabi - Fiji",
            "searchName": "RBI-Rabi-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "PTF",
            "countryCode": "FJ",
            "cityCode": "PTF",
            "cityName": "Malololailai",
            "name": "[PTF] - Malololailai - PTF - Malololailai - Fiji",
            "searchName": "PTF-Malololailai-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "MFJ",
            "countryCode": "FJ",
            "cityCode": "MFJ",
            "cityName": "Moala",
            "name": "[MFJ] - Moala - MFJ - Moala - Fiji",
            "searchName": "MFJ-Moala-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "MNF",
            "countryCode": "FJ",
            "cityCode": "MNF",
            "cityName": "Mana Island",
            "name": "[MNF] - Mana Island Airstrip - MNF - Mana Island - Fiji",
            "searchName": "MNF-Mana Island Airstrip-Mana Island-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "NAN",
            "countryCode": "FJ",
            "cityCode": "NAN",
            "cityName": "Nadi",
            "name": "[NAN] - Nadi International - NAN - Nadi - Fiji",
            "searchName": "NAN-Nadi-Nadi International-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "NGI",
            "countryCode": "FJ",
            "cityCode": "NGI",
            "cityName": "Ngau Island",
            "name": "[NGI] - Ngau Island - NGI - Ngau Island - Fiji",
            "searchName": "NGI-Ngau Island-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "YAS",
            "countryCode": "FJ",
            "cityCode": "YAS",
            "cityName": "Yasawa Island",
            "name": "[YAS] - Yasawa Island - YAS - Yasawa Island - Fiji",
            "searchName": "YAS-Yasawa Island-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "VAU",
            "countryCode": "FJ",
            "cityCode": "VAU",
            "cityName": "Vatukoula",
            "name": "[VAU] - Vatukoula - VAU - Vatukoula - Fiji",
            "searchName": "VAU-Vatukoula-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "VBV",
            "countryCode": "FJ",
            "cityCode": "VBV",
            "cityName": "Vanuabalavu",
            "name": "[VBV] - Vanuabalavu - VBV - Vanuabalavu - Fiji",
            "searchName": "VBV-Vanuabalavu-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "VTF",
            "countryCode": "FJ",
            "cityCode": "VTF",
            "cityName": "Vatulele",
            "name": "[VTF] - Vatulele - VTF - Vatulele - Fiji",
            "searchName": "VTF-Vatulele-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "SVU",
            "countryCode": "FJ",
            "cityCode": "SVU",
            "cityName": "Savusavu",
            "name": "[SVU] - Savusavu - SVU - Savusavu - Fiji",
            "searchName": "SVU-Savusavu-Fiji-Fidži-Fiyi-Figi-Fidschi-Fidji-Fidzi-Fidžis-Fidzsi-szigetek-F",
            "priority": 0
        },
        {
            "iataCode": "ZAZ",
            "countryCode": "ES",
            "cityCode": "ZAZ",
            "cityName": "Zaragoza",
            "name": "[ZAZ] - Zaragoza - ZAZ - Zaragoza - Spain",
            "searchName": "ZAZ-Zaragoza-Saragossa-Saragosse-Saragozza-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "XZZ",
            "countryCode": "ES",
            "cityCode": "ZAZ",
            "cityName": "Zaragoza",
            "name": "[XZZ] - Delicias Railway St. - ZAZ - Zaragoza - Spain",
            "searchName": "XZZ-ZAZ-Delicias Railway St.-Saragossa-Zaragoza-Saragosse-Saragozza-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "ZRC",
            "countryCode": "ES",
            "cityCode": "ZRC",
            "cityName": "San Pedro de Alcantara",
            "name": "[ZRC] - San Pedro de Alcantara Bus Station - ZRC - San Pedro de Alcantara - Spain",
            "searchName": "ZRC-San Pedro de Alcantara Bus Station-San Pedro de Alcantara-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "OTA",
            "countryCode": "ET",
            "cityCode": "OTA",
            "cityName": "Mota",
            "name": "[OTA] - Mota - OTA - Mota - Ethiopia",
            "searchName": "OTA-Mota-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "PWI",
            "countryCode": "ET",
            "cityCode": "PWI",
            "cityName": "Pawi",
            "name": "[PWI] - Beles - PWI - Pawi - Ethiopia",
            "searchName": "PWI-Beles-Pawi-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "NEJ",
            "countryCode": "ET",
            "cityCode": "NEJ",
            "cityName": "Nejjo",
            "name": "[NEJ] - Nejjo - NEJ - Nejjo - Ethiopia",
            "searchName": "NEJ-Nejjo-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "NEK",
            "countryCode": "ET",
            "cityCode": "NEK",
            "cityName": "Nekemt",
            "name": "[NEK] - Nekemt - NEK - Nekemt - Ethiopia",
            "searchName": "NEK-Nekemt-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "NDM",
            "countryCode": "ET",
            "cityCode": "NDM",
            "cityName": "Mendi",
            "name": "[NDM] - Mendi - NDM - Mendi - Ethiopia",
            "searchName": "NDM-Mendi-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "MZX",
            "countryCode": "ET",
            "cityCode": "MZX",
            "cityName": "Mena",
            "name": "[MZX] - Mena - MZX - Mena - Ethiopia",
            "searchName": "MZX-Mena-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "MKS",
            "countryCode": "ET",
            "cityCode": "MKS",
            "cityName": "Mekane Selam",
            "name": "[MKS] - Mekane Selam - MKS - Mekane Selam - Ethiopia",
            "searchName": "MKS-Mekane Selam-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "MTF",
            "countryCode": "ET",
            "cityCode": "MTF",
            "cityName": "Mizan Teferi",
            "name": "[MTF] - Mizan Teferi - MTF - Mizan Teferi - Ethiopia",
            "searchName": "MTF-Mizan Teferi-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "MUJ",
            "countryCode": "ET",
            "cityCode": "MUJ",
            "cityName": "Mui",
            "name": "[MUJ] - Mui - MUJ - Mui - Ethiopia",
            "searchName": "MUJ-Mui-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "MQX",
            "countryCode": "ET",
            "cityCode": "MQX",
            "cityName": "Makale",
            "name": "[MQX] - Makale - MQX - Makale - Ethiopia",
            "searchName": "MQX-Makale-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "XBL",
            "countryCode": "ET",
            "cityCode": "XBL",
            "cityName": "Buno Bedelle",
            "name": "[XBL] - Buno Bedelle - XBL - Buno Bedelle - Ethiopia",
            "searchName": "XBL-Buno Bedelle-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "WAC",
            "countryCode": "ET",
            "cityCode": "WAC",
            "cityName": "Waca",
            "name": "[WAC] - Waca - WAC - Waca - Ethiopia",
            "searchName": "WAC-Waca-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "WRA",
            "countryCode": "ET",
            "cityCode": "WRA",
            "cityName": "Warder",
            "name": "[WRA] - Warder - WRA - Warder - Ethiopia",
            "searchName": "WRA-Warder-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "SKR",
            "countryCode": "ET",
            "cityCode": "SKR",
            "cityName": "Shakiso",
            "name": "[SKR] - Shakiso - SKR - Shakiso - Ethiopia",
            "searchName": "SKR-Shakiso-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "SHC",
            "countryCode": "ET",
            "cityCode": "SHC",
            "cityName": "Indaselassie",
            "name": "[SHC] - Indaselassie - SHC - Indaselassie - Ethiopia",
            "searchName": "SHC-Indaselassie-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "SZE",
            "countryCode": "ET",
            "cityCode": "SZE",
            "cityName": "Semera",
            "name": "[SZE] - Semera Airport - SZE - Semera - Ethiopia",
            "searchName": "SZE-Semera Airport-Semera-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "SXU",
            "countryCode": "ET",
            "cityCode": "SXU",
            "cityName": "Soddu",
            "name": "[SXU] - Soddu - SXU - Soddu - Ethiopia",
            "searchName": "SXU-Soddu-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "TIE",
            "countryCode": "ET",
            "cityCode": "TIE",
            "cityName": "Tippi",
            "name": "[TIE] - Tippi - TIE - Tippi - Ethiopia",
            "searchName": "TIE-Tippi-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "TUJ",
            "countryCode": "ET",
            "cityCode": "TUJ",
            "cityName": "Tum",
            "name": "[TUJ] - Tum - TUJ - Tum - Ethiopia",
            "searchName": "TUJ-Tum-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "JIM",
            "countryCode": "ET",
            "cityCode": "JIM",
            "cityName": "Jimma",
            "name": "[JIM] - Jimma - JIM - Jimma - Ethiopia",
            "searchName": "JIM-Jimma-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "JIJ",
            "countryCode": "ET",
            "cityCode": "JIJ",
            "cityName": "Jijiga",
            "name": "[JIJ] - Jigiga - JIJ - Jijiga - Ethiopia",
            "searchName": "JIJ-Jijiga-Jigiga-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "LFO",
            "countryCode": "ET",
            "cityCode": "LFO",
            "cityName": "Kelafo",
            "name": "[LFO] - Kelafo - LFO - Kelafo - Ethiopia",
            "searchName": "LFO-Kelafo-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "LLI",
            "countryCode": "ET",
            "cityCode": "LLI",
            "cityName": "Lalibela",
            "name": "[LLI] - Lalibela - LLI - Lalibela - Ethiopia",
            "searchName": "LLI-Lalibella-Lalibela-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "HIL",
            "countryCode": "ET",
            "cityCode": "HIL",
            "cityName": "Shillavo",
            "name": "[HIL] - Shillavo - HIL - Shillavo - Ethiopia",
            "searchName": "HIL-Shillavo-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "HUE",
            "countryCode": "ET",
            "cityCode": "HUE",
            "cityName": "Humera",
            "name": "[HUE] - Humera - HUE - Humera - Ethiopia",
            "searchName": "HUE-Humera-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "GDE",
            "countryCode": "ET",
            "cityCode": "GDE",
            "cityName": "Gode",
            "name": "[GDE] - Gode/Iddidole - GDE - Gode - Ethiopia",
            "searchName": "GDE-Gode-Gode/Iddidole-Gode\\/Iddidole-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "GDQ",
            "countryCode": "ET",
            "cityCode": "GDQ",
            "cityName": "Gondar",
            "name": "[GDQ] - Gondar - GDQ - Gondar - Ethiopia",
            "searchName": "GDQ-Gondar-Gonder-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "FNH",
            "countryCode": "ET",
            "cityCode": "FNH",
            "cityName": "Fincha",
            "name": "[FNH] - Fincha - FNH - Fincha - Ethiopia",
            "searchName": "FNH-Fincha-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "GLC",
            "countryCode": "ET",
            "cityCode": "GLC",
            "cityName": "Geladi",
            "name": "[GLC] - Geladi - GLC - Geladi - Ethiopia",
            "searchName": "GLC-Geladi-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "GMB",
            "countryCode": "ET",
            "cityCode": "GMB",
            "cityName": "Gambela",
            "name": "[GMB] - Gambela - GMB - Gambela - Ethiopia",
            "searchName": "GMB-Gambela-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "GOR",
            "countryCode": "ET",
            "cityCode": "GOR",
            "cityName": "Gore",
            "name": "[GOR] - Gore - GOR - Gore - Ethiopia",
            "searchName": "GOR-Gore-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "GNN",
            "countryCode": "ET",
            "cityCode": "GNN",
            "cityName": "Ghinnir",
            "name": "[GNN] - Ghinnir - GNN - Ghinnir - Ethiopia",
            "searchName": "GNN-Ghinnir-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "GOB",
            "countryCode": "ET",
            "cityCode": "GOB",
            "cityName": "Goba",
            "name": "[GOB] - Goba - GOB - Goba - Ethiopia",
            "searchName": "GOB-Goba-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "EGL",
            "countryCode": "ET",
            "cityCode": "EGL",
            "cityName": "Neghelli",
            "name": "[EGL] - Neghelli - EGL - Neghelli - Ethiopia",
            "searchName": "EGL-Neghelli-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "ETE",
            "countryCode": "ET",
            "cityCode": "ETE",
            "cityName": "Genda Wuha",
            "name": "[ETE] - Genda Wuha - ETE - Genda Wuha - Ethiopia",
            "searchName": "ETE-Genda Wuha-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "DBM",
            "countryCode": "ET",
            "cityCode": "DBM",
            "cityName": "Debra Marcos",
            "name": "[DBM] - Debra Marcos - DBM - Debra Marcos - Ethiopia",
            "searchName": "DBM-Debra Marcos-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "DBT",
            "countryCode": "ET",
            "cityCode": "DBT",
            "cityName": "Debra Tabor",
            "name": "[DBT] - Debra Tabor - DBT - Debra Tabor - Ethiopia",
            "searchName": "DBT-Debra Tabor-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "DEM",
            "countryCode": "ET",
            "cityCode": "DEM",
            "cityName": "Dembidollo",
            "name": "[DEM] - Dembidollo - DEM - Dembidollo - Ethiopia",
            "searchName": "DEM-Dembidollo-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "DIR",
            "countryCode": "ET",
            "cityCode": "DIR",
            "cityName": "Dire Dawa",
            "name": "[DIR] - Aba Tenna D Yilma - DIR - Dire Dawa - Ethiopia",
            "searchName": "DIR-Dire Dawa-Aba Tenna D Yilma-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "DSE",
            "countryCode": "ET",
            "cityCode": "DSE",
            "cityName": "Dessie",
            "name": "[DSE] - Combolcha - DSE - Dessie - Ethiopia",
            "searchName": "DSE-Combolcha-Dessie-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "BJR",
            "countryCode": "ET",
            "cityCode": "BJR",
            "cityName": "Bahar Dar",
            "name": "[BJR] - Bahar Dar - BJR - Bahar Dar - Ethiopia",
            "searchName": "BJR-Bahar Dar-Bahir Dar-Baher Dar-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "BCO",
            "countryCode": "ET",
            "cityCode": "BCO",
            "cityName": "Jinka",
            "name": "[BCO] - Jinka - BCO - Jinka - Ethiopia",
            "searchName": "BCO-Jinka-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "BCY",
            "countryCode": "ET",
            "cityCode": "BCY",
            "cityName": "Bulchi",
            "name": "[BCY] - Bulchi - BCY - Bulchi - Ethiopia",
            "searchName": "BCY-Bulchi-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "BEI",
            "countryCode": "ET",
            "cityCode": "BEI",
            "cityName": "Beica",
            "name": "[BEI] - Beica - BEI - Beica - Ethiopia",
            "searchName": "BEI-Beica-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "ALK",
            "countryCode": "ET",
            "cityCode": "ALK",
            "cityName": "Asela",
            "name": "[ALK] - Asela - ALK - Asela - Ethiopia",
            "searchName": "ALK-Asela-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "AMH",
            "countryCode": "ET",
            "cityCode": "AMH",
            "cityName": "Arba Mintch",
            "name": "[AMH] - Arba Mintch - AMH - Arba Mintch - Ethiopia",
            "searchName": "AMH-Arba Mintch-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "ADD",
            "countryCode": "ET",
            "cityCode": "ADD",
            "cityName": "Addis Ababa",
            "name": "[ADD] - Bole International - ADD - Addis Ababa - Ethiopia",
            "searchName": "ADD-Addis Ababa-Bole International-Bole-Addis Abeba-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "ABK",
            "countryCode": "ET",
            "cityCode": "ABK",
            "cityName": "Kabri Dar",
            "name": "[ABK] - Kabri Dar - ABK - Kabri Dar - Ethiopia",
            "searchName": "ABK-Kabri Dar-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "ASO",
            "countryCode": "ET",
            "cityCode": "ASO",
            "cityName": "Asosa",
            "name": "[ASO] - Asosa - ASO - Asosa - Ethiopia",
            "searchName": "ASO-Asosa-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "AWA",
            "countryCode": "ET",
            "cityCode": "AWA",
            "cityName": "Awassa",
            "name": "[AWA] - Awassa - AWA - Awassa - Ethiopia",
            "searchName": "AWA-Awassa-Awasa-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "AXU",
            "countryCode": "ET",
            "cityCode": "AXU",
            "cityName": "Axum",
            "name": "[AXU] - Axum - AXU - Axum - Ethiopia",
            "searchName": "AXU-Axum-Aksum-Aksoum-Etiòpia-Ethiopia-Etiopia-Etiopien-Etiopie-Etiyopya-Etiópia-Etiopija-Ê thi ô pia-Habsyah-E-Äthiopien-Éthiopie-Etiopía",
            "priority": 0
        },
        {
            "iataCode": "ENF",
            "countryCode": "FI",
            "cityCode": "ENF",
            "cityName": "Enontekio",
            "name": "[ENF] - Enontekio - ENF - Enontekio - Finland",
            "searchName": "ENF-Enontekio-Enonteki-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "HYV",
            "countryCode": "FI",
            "cityCode": "HYV",
            "cityName": "Hyvinkaa",
            "name": "[HYV] - Hyvinkaa - HYV - Hyvinkaa - Finland",
            "searchName": "HYV-Hyvinkaa-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "TEE",
            "countryCode": "DZ",
            "cityCode": "TEE",
            "cityName": "Tbessa",
            "name": "[TEE] - Tbessa - TEE - Tbessa - Algeria",
            "searchName": "TEE-Tbessa-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "TGR",
            "countryCode": "DZ",
            "cityCode": "TGR",
            "cityName": "Touggourt",
            "name": "[TGR] - Touggourt - TGR - Touggourt - Algeria",
            "searchName": "TGR-Touggourt-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "TID",
            "countryCode": "DZ",
            "cityCode": "TID",
            "cityName": "Tiaret",
            "name": "[TID] - Bouchekif Abde Al-hafidh Boussof - TID - Tiaret - Algeria",
            "searchName": "TID-Bouchekif-Bouchekif Abde Al-hafidh Boussof-Tiaret-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "TIN",
            "countryCode": "DZ",
            "cityCode": "TIN",
            "cityName": "Tindouf",
            "name": "[TIN] - Tindouf - TIN - Tindouf - Algeria",
            "searchName": "TIN-Tindouf-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "TMR",
            "countryCode": "DZ",
            "cityCode": "TMR",
            "cityName": "Tamanrasset",
            "name": "[TMR] - Aguemar - TMR - Tamanrasset - Algeria",
            "searchName": "TMR-Aguemar-Tamanrasset-Tamanghasset-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "TMX",
            "countryCode": "DZ",
            "cityCode": "TMX",
            "cityName": "Timimoun",
            "name": "[TMX] - Timimoun - TMX - Timimoun - Algeria",
            "searchName": "TMX-Timimoun-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "TLM",
            "countryCode": "DZ",
            "cityCode": "TLM",
            "cityName": "Tlemcen",
            "name": "[TLM] - Zenata - TLM - Tlemcen - Algeria",
            "searchName": "TLM-Tlemcen-Zenata-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "VVZ",
            "countryCode": "DZ",
            "cityCode": "VVZ",
            "cityName": "Illizi",
            "name": "[VVZ] - Illizi - VVZ - Illizi - Algeria",
            "searchName": "VVZ-Illizi-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "IBB",
            "countryCode": "EC",
            "cityCode": "IBB",
            "cityName": "Isabela Island",
            "name": "[IBB] - General Villamil - IBB - Isabela Island - Ecuador",
            "searchName": "IBB-General Villamil-Isabela Island-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "LOH",
            "countryCode": "EC",
            "cityCode": "LOH",
            "cityName": "Catamayo",
            "name": "[LOH] - Camilo Ponce EnrÃ­quez Airport - LOH - Catamayo - Ecuador",
            "searchName": "LOH-Loja-Camilo Ponce Enríquez Airport-Camilo Ponce EnrÃ­quez Airport-Catamayo-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "LTX",
            "countryCode": "EC",
            "cityCode": "LTX",
            "cityName": "Latacunga",
            "name": "[LTX] - Cotopaxi International - LTX - Latacunga - Ecuador",
            "searchName": "LTX-Cotapaxi Intl-Cotopaxi International-Cotapaxi Uluslararasi Havalimani-Latacunga-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "LGQ",
            "countryCode": "EC",
            "cityCode": "LGQ",
            "cityName": "Lago Agrio",
            "name": "[LGQ] - Lago Agrio - LGQ - Lago Agrio - Ecuador",
            "searchName": "LGQ-Lago Agrio-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "JIP",
            "countryCode": "EC",
            "cityCode": "JIP",
            "cityName": "Jipijapa",
            "name": "[JIP] - Jipijapa - JIP - Jipijapa - Ecuador",
            "searchName": "JIP-Jipijapa-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "MRR",
            "countryCode": "EC",
            "cityCode": "MRR",
            "cityName": "Macara",
            "name": "[MRR] - Macara - MRR - Macara - Ecuador",
            "searchName": "MRR-Macara-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "MCH",
            "countryCode": "EC",
            "cityCode": "MCH",
            "cityName": "Machala",
            "name": "[MCH] - Machala - MCH - Machala - Ecuador",
            "searchName": "MCH-Machala-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "MEC",
            "countryCode": "EC",
            "cityCode": "MEC",
            "cityName": "Manta",
            "name": "[MEC] - Manta - MEC - Manta - Ecuador",
            "searchName": "MEC-Manta-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "MZD",
            "countryCode": "EC",
            "cityCode": "MZD",
            "cityName": "Mendez",
            "name": "[MZD] - Mendez - MZD - Mendez - Ecuador",
            "searchName": "MZD-Mendez-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "OCC",
            "countryCode": "EC",
            "cityCode": "OCC",
            "cityName": "Coca",
            "name": "[OCC] - Coca - OCC - Coca - Ecuador",
            "searchName": "OCC-Coca-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "PTZ",
            "countryCode": "EC",
            "cityCode": "PTZ",
            "cityName": "Pastaza",
            "name": "[PTZ] - Pastaza - PTZ - Pastaza - Ecuador",
            "searchName": "PTZ-Pastaza-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "PYO",
            "countryCode": "EC",
            "cityCode": "PYO",
            "cityName": "Putumayo",
            "name": "[PYO] - Putumayo - PYO - Putumayo - Ecuador",
            "searchName": "PYO-Putumayo-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "PVO",
            "countryCode": "EC",
            "cityCode": "PVO",
            "cityName": "Portoviejo",
            "name": "[PVO] - Reales Tamarindos - PVO - Portoviejo - Ecuador",
            "searchName": "PVO-Reales Tamarindos-Portoviejo-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "BSK",
            "countryCode": "DZ",
            "cityCode": "BSK",
            "cityName": "Biskra",
            "name": "[BSK] - Mohamed Boudiaf - BSK - Biskra - Algeria",
            "searchName": "BSK-Biskra-Mohamed Boudiaf-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "BUJ",
            "countryCode": "DZ",
            "cityCode": "BUJ",
            "cityName": "Boussaada",
            "name": "[BUJ] - Ain Eddis - BUJ - Boussaada - Algeria",
            "searchName": "BUJ-Ain Eddis-Boussaada-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "BMW",
            "countryCode": "DZ",
            "cityCode": "BMW",
            "cityName": "Bordj Badji Mokhtar",
            "name": "[BMW] - Bordj Badji Mokhtar - BMW - Bordj Badji Mokhtar - Algeria",
            "searchName": "BMW-Bordj Badji Mokhtar-Bordj Mokhtar Havalimani-Borj Badji Mokhtar-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "BLJ",
            "countryCode": "DZ",
            "cityCode": "BLJ",
            "cityName": "Batna",
            "name": "[BLJ] - Batna - BLJ - Batna - Algeria",
            "searchName": "BLJ-Batna-Batna Arpt-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "BJA",
            "countryCode": "DZ",
            "cityCode": "BJA",
            "cityName": "Bejaia",
            "name": "[BJA] - Bejaia - BJA - Bejaia - Algeria",
            "searchName": "BJA-Bejaia-Bug-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "BFW",
            "countryCode": "DZ",
            "cityCode": "BFW",
            "cityName": "Sidi Belabbes",
            "name": "[BFW] - Sidi Belabbes - BFW - Sidi Belabbes - Algeria",
            "searchName": "BFW-Sidi Belabbes-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "CBH",
            "countryCode": "DZ",
            "cityCode": "CBH",
            "cityName": "Béchar",
            "name": "[CBH] - Leger - CBH - Béchar - Algeria",
            "searchName": "CBH-Leger-Bechar-B-Béchar-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "CFK",
            "countryCode": "DZ",
            "cityCode": "CFK",
            "cityName": "Chlef",
            "name": "[CFK] - Aboubakr Belkaid - CFK - Chlef - Algeria",
            "searchName": "CFK-Chlef-Aboubakr Belkaid-Chlef Ululararasi Havalimani-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "CZL",
            "countryCode": "DZ",
            "cityCode": "CZL",
            "cityName": "Constantine",
            "name": "[CZL] - Ain El Bey - CZL - Constantine - Algeria",
            "searchName": "CZL-Constantine-Ain El Bey-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "EBH",
            "countryCode": "DZ",
            "cityCode": "EBH",
            "cityName": "El Bayadh",
            "name": "[EBH] - El Bayadh - EBH - El Bayadh - Algeria",
            "searchName": "EBH-El Bayadh-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "ELU",
            "countryCode": "DZ",
            "cityCode": "ELU",
            "cityName": "El Oued",
            "name": "[ELU] - Guemar - ELU - El Oued - Algeria",
            "searchName": "ELU-Guemar-El Oued-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "ELG",
            "countryCode": "DZ",
            "cityCode": "ELG",
            "cityName": "El Golea",
            "name": "[ELG] - Djamet - ELG - El Golea - Algeria",
            "searchName": "ELG-Djamet-El Golea-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "DJG",
            "countryCode": "DZ",
            "cityCode": "DJG",
            "cityName": "Djanet",
            "name": "[DJG] - Inedbirenne - DJG - Djanet - Algeria",
            "searchName": "DJG-Inedbirenne-Djanet-Argélia-Algerije-Argelia-Algeria-Algerien-Algérie-An giê ri-Algeriet-Alžirija-Cezayir",
            "priority": 0
        },
        {
            "iataCode": "ETR",
            "countryCode": "EC",
            "cityCode": "ETR",
            "cityName": "Santa Rosa",
            "name": "[ETR] - Santa Rosa Regional - ETR - Santa Rosa - Ecuador",
            "searchName": "ETR-Santa Rosa Regional-Santa Rosa-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "ESM",
            "countryCode": "EC",
            "cityCode": "ESM",
            "cityName": "Esmeraldas",
            "name": "[ESM] - Esmeraldas - ESM - Esmeraldas - Ecuador",
            "searchName": "ESM-Esmeraldas-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "CHE",
            "countryCode": "EE",
            "cityCode": "TLL",
            "cityName": "Tallinn",
            "name": "[CHE] - Reenroe - TLL - Tallinn - Estonia",
            "searchName": "CHE-TLL-Reenroe-Tallinn-Tallin-Estônia-Estland-Estonia-Estonie-Eesti-Estonya",
            "priority": 0
        },
        {
            "iataCode": "TAY",
            "countryCode": "EE",
            "cityCode": "TAY",
            "cityName": "Tartu",
            "name": "[TAY] - Tartu - TAY - Tartu - Estonia",
            "searchName": "TAY-Tartu-Estônia-Estland-Estonia-Estonie-Eesti-Estonya",
            "priority": 0
        },
        {
            "iataCode": "URE",
            "countryCode": "EE",
            "cityCode": "URE",
            "cityName": "Kuressaare",
            "name": "[URE] - Kuressaare - URE - Kuressaare - Estonia",
            "searchName": "URE-Kuressaare-Kuressaare Havalimani-Estônia-Estland-Estonia-Estonie-Eesti-Estonya",
            "priority": 0
        },
        {
            "iataCode": "KDL",
            "countryCode": "EE",
            "cityCode": "KDL",
            "cityName": "Kardla",
            "name": "[KDL] - Kardla - KDL - Kardla - Estonia",
            "searchName": "KDL-Kardla-Estônia-Estland-Estonia-Estonie-Eesti-Estonya",
            "priority": 0
        },
        {
            "iataCode": "EPU",
            "countryCode": "EE",
            "cityCode": "EPU",
            "cityName": "Parnu",
            "name": "[EPU] - Parnu - EPU - Parnu - Estonia",
            "searchName": "EPU-Parnu-Parnu Havalimani-Estônia-Estland-Estonia-Estonie-Eesti-Estonya",
            "priority": 0
        },
        {
            "iataCode": "ELT",
            "countryCode": "EG",
            "cityCode": "ELT",
            "cityName": "Tour Sinai City",
            "name": "[ELT] - Tour Sinai City - ELT - Tour Sinai City - Egypt",
            "searchName": "ELT-Tour Sinai City-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "EMY",
            "countryCode": "EG",
            "cityCode": "EMY",
            "cityName": "El Minya",
            "name": "[EMY] - El Minya - EMY - El Minya - Egypt",
            "searchName": "EMY-El Minya-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "DBB",
            "countryCode": "EG",
            "cityCode": "DBB",
            "cityName": "Dabaa City",
            "name": "[DBB] - Alalamain International - DBB - Dabaa City - Egypt",
            "searchName": "DBB-Alalamain International-Dabaa City-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "DAK",
            "countryCode": "EG",
            "cityCode": "DAK",
            "cityName": "Dakhla Oasis",
            "name": "[DAK] - Dakhla - DAK - Dakhla Oasis - Egypt",
            "searchName": "DAK-Dakhla-Dakhla Havalimani-Dakhla Oasis-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "XMS",
            "countryCode": "EC",
            "cityCode": "XMS",
            "cityName": "Macas",
            "name": "[XMS] - Macas - XMS - Macas - Ecuador",
            "searchName": "XMS-Macas-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "TNW",
            "countryCode": "EC",
            "cityCode": "TNW",
            "cityName": "Tena",
            "name": "[TNW] - Jumandy - TNW - Tena - Ecuador",
            "searchName": "TNW-Jumandy-Tena-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "TPC",
            "countryCode": "EC",
            "cityCode": "TPC",
            "cityName": "Tarapoa",
            "name": "[TPC] - Tarapoa - TPC - Tarapoa - Ecuador",
            "searchName": "TPC-Tarapoa-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "TPN",
            "countryCode": "EC",
            "cityCode": "TPN",
            "cityName": "Tiputini",
            "name": "[TPN] - Tiputini - TPN - Tiputini - Ecuador",
            "searchName": "TPN-Tiputini-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "TSC",
            "countryCode": "EC",
            "cityCode": "TSC",
            "cityName": "Taisha",
            "name": "[TSC] - Taisha - TSC - Taisha - Ecuador",
            "searchName": "TSC-Taisha-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "TUA",
            "countryCode": "EC",
            "cityCode": "TUA",
            "cityName": "Tulcan",
            "name": "[TUA] - Tulcan - TUA - Tulcan - Ecuador",
            "searchName": "TUA-Tulcan-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "SUQ",
            "countryCode": "EC",
            "cityCode": "SUQ",
            "cityName": "Sucua",
            "name": "[SUQ] - Sucua - SUQ - Sucua - Ecuador",
            "searchName": "SUQ-Sucua-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "SNC",
            "countryCode": "EC",
            "cityCode": "SNC",
            "cityName": "Salinas",
            "name": "[SNC] - Salinas - SNC - Salinas - Ecuador",
            "searchName": "SNC-Salinas-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "SCY",
            "countryCode": "EC",
            "cityCode": "SCY",
            "cityName": "Puerto Baquerizo Moreno",
            "name": "[SCY] - San Cristobal - SCY - Puerto Baquerizo Moreno - Ecuador",
            "searchName": "SCY-San Cristobal-San Cristobal Havalimani-Puerto Baquerizo Moreno-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "GSQ",
            "countryCode": "EG",
            "cityCode": "GSQ",
            "cityName": "Shark Elowainat",
            "name": "[GSQ] - Shark Elowainat - GSQ - Shark Elowainat - Egypt",
            "searchName": "GSQ-Shark Elowainat-Sharq Al-Owainat Havalimani-Shark Alowainat-Owainat-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "UVL",
            "countryCode": "EG",
            "cityCode": "UVL",
            "cityName": "Kharga",
            "name": "[UVL] - Kharga - UVL - Kharga - Egypt",
            "searchName": "UVL-Kharga-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "TCP",
            "countryCode": "EG",
            "cityCode": "TCP",
            "cityName": "Taba",
            "name": "[TCP] - Taba International - TCP - Taba - Egypt",
            "searchName": "TCP-Taba-Taba International-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "TFR",
            "countryCode": "EG",
            "cityCode": "TFR",
            "cityName": "Ramadan",
            "name": "[TFR] - Ramadan - TFR - Ramadan - Egypt",
            "searchName": "TFR-Ramadan-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "SEW",
            "countryCode": "EG",
            "cityCode": "SEW",
            "cityName": "Siwa",
            "name": "[SEW] - Siwa - SEW - Siwa - Egypt",
            "searchName": "SEW-Siwa-Siwa Havalimani-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "SKV",
            "countryCode": "EG",
            "cityCode": "SKV",
            "cityName": "Santa Katarina",
            "name": "[SKV] - Mount Sinai - SKV - Santa Katarina - Egypt",
            "searchName": "SKV-Mount Sinai-Santa Katarina-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "SQK",
            "countryCode": "EG",
            "cityCode": "SQK",
            "cityName": "Sidi Barani",
            "name": "[SQK] - Sidi Barani - SQK - Sidi Barani - Egypt",
            "searchName": "SQK-Sidi Barani-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "AUE",
            "countryCode": "EG",
            "cityCode": "AUE",
            "cityName": "Abu Rudeis",
            "name": "[AUE] - Abu Rudeis - AUE - Abu Rudeis - Egypt",
            "searchName": "AUE-Abu Rudeis-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "ATZ",
            "countryCode": "EG",
            "cityCode": "ATZ",
            "cityName": "Assiut",
            "name": "[ATZ] - Assiut - ATZ - Assiut - Egypt",
            "searchName": "ATZ-Assiut-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "ASW",
            "countryCode": "EG",
            "cityCode": "ASW",
            "cityName": "Aswan",
            "name": "[ASW] - Aswan - ASW - Aswan - Egypt",
            "searchName": "ASW-Assuan-Aswan-Asu-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "ALY",
            "countryCode": "EG",
            "cityCode": "ALY",
            "cityName": "Alexandria",
            "name": "[ALY] - El Nohza - ALY - Alexandria - Egypt",
            "searchName": "ALY-Alexandria-El Nohza-El Nozha-Alejandr-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "HBE",
            "countryCode": "EG",
            "cityCode": "ALY",
            "cityName": "Alexandria",
            "name": "[HBE] - Borg el Arab - ALY - Alexandria - Egypt",
            "searchName": "HBE-ALY-Borg El Arab-Borg el Arab-Alexandria-Alejandr-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "AAC",
            "countryCode": "EG",
            "cityCode": "AAC",
            "cityName": "El Arish",
            "name": "[AAC] - El Arish International Airport - AAC - El Arish - Egypt",
            "searchName": "AAC-El Arish International Airport-El Arish-Al Arish-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "ABS",
            "countryCode": "EG",
            "cityCode": "ABS",
            "cityName": "Abu Simbel",
            "name": "[ABS] - Abu Simbel - ABS - Abu Simbel - Egypt",
            "searchName": "ABS-Abu Simbel-Abou Simbel-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "BHA",
            "countryCode": "EC",
            "cityCode": "BHA",
            "cityName": "Bahia De Caraquez",
            "name": "[BHA] - Bahia De Caraquez - BHA - Bahia De Caraquez - Ecuador",
            "searchName": "BHA-Bahia De Caraquez-Bahia de Caraquez-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "ATF",
            "countryCode": "EC",
            "cityCode": "ATF",
            "cityName": "Ambato",
            "name": "[ATF] - Chachoan - ATF - Ambato - Ecuador",
            "searchName": "ATF-Chachoan-Ambato-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "GPS",
            "countryCode": "EC",
            "cityCode": "GPS",
            "cityName": "Galapagos Island",
            "name": "[GPS] - Baltra - GPS - Galapagos Island - Ecuador",
            "searchName": "GPS-Galapagos Islands-Baltra-Galapagos Is-Galapagos Island-Galapagos-Equador-Ecuador-Ê cua do-Ekvadoras-Équateur-Ekuador-Ekwador",
            "priority": 0
        },
        {
            "iataCode": "HMB",
            "countryCode": "EG",
            "cityCode": "HMB",
            "cityName": "Sohag",
            "name": "[HMB] - Mubarak International - HMB - Sohag - Egypt",
            "searchName": "HMB-Sohag International-Mubarak International-Sohag International Airport-Elmendorf Afb AK-Sohag-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "PSD",
            "countryCode": "EG",
            "cityCode": "PSD",
            "cityName": "Port Said",
            "name": "[PSD] - Port Said - PSD - Port Said - Egypt",
            "searchName": "PSD-Port Said-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "MUH",
            "countryCode": "EG",
            "cityCode": "MUH",
            "cityName": "Mersa Matruh",
            "name": "[MUH] - Mersa Matruh - MUH - Mersa Matruh - Egypt",
            "searchName": "MUH-Mersa Matruh-Egipt-Egypten-Egypti-Egypt-Egipte-Egyiptom-Egiptus-Egipat-Egiptas-Mesir-Egipto-Ägypten-Égypte-Egitto-Misir-Ai C",
            "priority": 0
        },
        {
            "iataCode": "MSW",
            "countryCode": "ER",
            "cityCode": "MSW",
            "cityName": "Massawa",
            "name": "[MSW] - Massawa International - MSW - Massawa - Eritrea",
            "searchName": "MSW-Massawa International-Massawa Intl Havalimani-Massawa-Eritrea-Eritre-Érythrée",
            "priority": 0
        },
        {
            "iataCode": "TES",
            "countryCode": "ER",
            "cityCode": "TES",
            "cityName": "Tessenei",
            "name": "[TES] - Tessenei - TES - Tessenei - Eritrea",
            "searchName": "TES-Tessenei-Eritrea-Eritre-Érythrée",
            "priority": 0
        },
        {
            "iataCode": "ASM",
            "countryCode": "ER",
            "cityCode": "ASM",
            "cityName": "Asmara",
            "name": "[ASM] - Yohannes IV - ASM - Asmara - Eritrea",
            "searchName": "ASM-Asmara-Yohannes IV-Asmara International-Eritrea-Eritre-Érythrée",
            "priority": 0
        },
        {
            "iataCode": "ASA",
            "countryCode": "ER",
            "cityCode": "ASA",
            "cityName": "Assab",
            "name": "[ASA] - Assab International - ASA - Assab - Eritrea",
            "searchName": "ASA-Assab International-Assab-Eritrea-Eritre-Érythrée",
            "priority": 0
        },
        {
            "iataCode": "ABC",
            "countryCode": "ES",
            "cityCode": "ABC",
            "cityName": "Albacete",
            "name": "[ABC] - Los Llanos - ABC - Albacete - Spain",
            "searchName": "ABC-Albacete-Los Llanos-Los Llanos Havalimani-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "EEM",
            "countryCode": "ES",
            "cityCode": "ABC",
            "cityName": "Albacete",
            "name": "[EEM] - Albacete Railway Station - ABC - Albacete - Spain",
            "searchName": "EEM-ABC-Albacete Railway Station-Albacete-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "XJJ",
            "countryCode": "ES",
            "cityCode": "ABC",
            "cityName": "Albacete",
            "name": "[XJJ] - Albacete Bus Station - ABC - Albacete - Spain",
            "searchName": "XJJ-ABC-Albacete Bus Station-Albacete-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "AEI",
            "countryCode": "ES",
            "cityCode": "AEI",
            "cityName": "Algeciras",
            "name": "[AEI] - Algeciras - AEI - Algeciras - Spain",
            "searchName": "AEI-Algeciras-Algeciras Havalimani-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "YJI",
            "countryCode": "ES",
            "cityCode": "BIO",
            "cityName": "Bilbao",
            "name": "[YJI] - Abando Railway Station - BIO - Bilbao - Spain",
            "searchName": "YJI-BIO-Abando Railway Station-Bilbao-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "BJZ",
            "countryCode": "ES",
            "cityCode": "BJZ",
            "cityName": "Badajoz",
            "name": "[BJZ] - Talaveral La Real - BJZ - Badajoz - Spain",
            "searchName": "BJZ-Badajoz-Talaveral La Real-Talavera La Real Apt-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "CDZ",
            "countryCode": "ES",
            "cityCode": "CDZ",
            "cityName": "Cadiz",
            "name": "[CDZ] - Cadiz - CDZ - Cadiz - Spain",
            "searchName": "CDZ-Cadiz-C-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "CDT",
            "countryCode": "ES",
            "cityCode": "CDT",
            "cityName": "Castellon de la Plana",
            "name": "[CDT] - Castellon - CDT - Castellon de la Plana - Spain",
            "searchName": "CDT-Castellon-Castellon de la Plana-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "XJI",
            "countryCode": "ES",
            "cityCode": "CQM",
            "cityName": "Ciudad Real",
            "name": "[XJI] - Ciudad Real Railway Station - CQM - Ciudad Real - Spain",
            "searchName": "XJI-CQM-Ciudad Real Railway Station-Ciudad Real-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "EAS",
            "countryCode": "ES",
            "cityCode": "EAS",
            "cityName": "San Sebastian",
            "name": "[EAS] - Donostia - San Sebastian - EAS - San Sebastian - Spain",
            "searchName": "EAS-San Sebastian-Donostia - San Sebastian-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "YJH",
            "countryCode": "ES",
            "cityCode": "EAS",
            "cityName": "San Sebastian",
            "name": "[YJH] - San Sebastian Railway Station - EAS - San Sebastian - Spain",
            "searchName": "YJH-EAS-San Sebastian Railway Station-San Sebastian-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "EEL",
            "countryCode": "ES",
            "cityCode": "EEL",
            "cityName": "Tudela",
            "name": "[EEL] - Tudela Railway Station - EEL - Tudela - Spain",
            "searchName": "EEL-Tudela Railway Station-Tudela-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "GRO",
            "countryCode": "ES",
            "cityCode": "GRO",
            "cityName": "Gerona",
            "name": "[GRO] - Girona-Costa Brava - GRO - Gerona - Spain",
            "searchName": "GRO-Barcelona Gerona-Girona-Costa Brava-Gerona-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "GRX",
            "countryCode": "ES",
            "cityCode": "GRX",
            "cityName": "Granada",
            "name": "[GRX] - Granada - GRX - Granada - Spain",
            "searchName": "GRX-Granada-Federico Garcia Lorca-Granada Int. Apt-Granade-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "YJG",
            "countryCode": "ES",
            "cityCode": "GRX",
            "cityName": "Granada",
            "name": "[YJG] - Granada Railway Station - GRX - Granada - Spain",
            "searchName": "YJG-GRX-Granada Railway Station-Granada-Granade-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "GMZ",
            "countryCode": "ES",
            "cityCode": "GMZ",
            "cityName": "San Sebas De La Gomera",
            "name": "[GMZ] - La Gomera - GMZ - San Sebas De La Gomera - Spain",
            "searchName": "GMZ-San Sebastian Gomera-La Gomera-San Sebas de la Gomera-San Sebas De La Gomera-San Sebastian de la Gomera-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "GGA",
            "countryCode": "ES",
            "cityCode": "GGA",
            "cityName": "La Graciosa",
            "name": "[GGA] - La Graciosa Ferry Port - GGA - La Graciosa - Spain",
            "searchName": "GGA-La Graciosa Ferry Port-La Graciosa-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "FES",
            "countryCode": "ES",
            "cityCode": "FES",
            "cityName": "San Fernando",
            "name": "[FES] - San Fernando - FES - San Fernando - Spain",
            "searchName": "FES-San Fernando-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "FGR",
            "countryCode": "ES",
            "cityCode": "FGR",
            "cityName": "Fuengirola",
            "name": "[FGR] - Fuengirola - FGR - Fuengirola - Spain",
            "searchName": "FGR-Fuengirola-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "GEN",
            "countryCode": "ES",
            "cityCode": "GEN",
            "cityName": "Puente Genil",
            "name": "[GEN] - Oslo - Gardermoen Airport - GEN - Puente Genil - Spain",
            "searchName": "GEN-Puente Genil-Oslo - Gardermoen Airport-Ginebra-Genève-Ginevra-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "FUE",
            "countryCode": "ES",
            "cityCode": "FUE",
            "cityName": "Puerto del Rosario",
            "name": "[FUE] - Fuerteventura - FUE - Puerto del Rosario - Spain",
            "searchName": "FUE-Puerto del Rosario-Fuerteventura-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "HSK",
            "countryCode": "ES",
            "cityCode": "HSK",
            "cityName": "Huesca",
            "name": "[HSK] - Huesca-Pirineos - HSK - Huesca - Spain",
            "searchName": "HSK-Huesca-Pirineos-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "XUA",
            "countryCode": "ES",
            "cityCode": "HSK",
            "cityName": "Huesca",
            "name": "[XUA] - Huesca Railway Station - HSK - Huesca - Spain",
            "searchName": "XUA-HSK-Huesca Railway Station-Huesca-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "HEV",
            "countryCode": "ES",
            "cityCode": "HEV",
            "cityName": "Huelva",
            "name": "[HEV] - Huelva - HEV - Huelva - Spain",
            "searchName": "HEV-Huelva-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "LEU",
            "countryCode": "ES",
            "cityCode": "LEU",
            "cityName": "Seo de Urgel",
            "name": "[LEU] - Seo de Urgel - LEU - Seo de Urgel - Spain",
            "searchName": "LEU-Seo de Urgel-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "ILD",
            "countryCode": "ES",
            "cityCode": "ILD",
            "cityName": "Lleida",
            "name": "[ILD] - Alguaire - ILD - Lleida - Spain",
            "searchName": "ILD-Lleida-Alguaire-Alguaire Airport-L-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "QLQ",
            "countryCode": "ES",
            "cityCode": "ILD",
            "cityName": "Lleida",
            "name": "[QLQ] - Lleida Railway Station - ILD - Lleida - Spain",
            "searchName": "QLQ-ILD-Lleida Railway Station-Lleida-L-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "YJC",
            "countryCode": "ES",
            "cityCode": "LCG",
            "cityName": "La Coruna",
            "name": "[YJC] - A Coruna Railway Station - LCG - La Coruna - Spain",
            "searchName": "YJC-LCG-A Coruna Railway Station-La Coruna-La Corogne-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "JCU",
            "countryCode": "ES",
            "cityCode": "JCU",
            "cityName": "Ceuta",
            "name": "[JCU] - Ceuta Heliport - JCU - Ceuta - Spain",
            "searchName": "JCU-Ceuta Heliport-Ceuta-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "TFS",
            "countryCode": "ES",
            "cityCode": "TCI",
            "cityName": "Tenerife",
            "name": "[TFS] - Sur Reina Sofia - TCI - Tenerife - Spain",
            "searchName": "TFS-TCI-Teneriffa Süd-Sur Reina Sofia-Reina Sofia-Teneriffa-Tenerife-Teneriffe-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "YJE",
            "countryCode": "ES",
            "cityCode": "ALC",
            "cityName": "Alicante",
            "name": "[YJE] - Alicante Railway Station - ALC - Alicante - Spain",
            "searchName": "YJE-ALC-Alicante Railway Station-Alicante-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "AGP",
            "countryCode": "ES",
            "cityCode": "AGP",
            "cityName": "Malaga",
            "name": "[AGP] - Pablo Ruiz Picasso - AGP - Malaga - Spain",
            "searchName": "AGP-Malaga-Pablo Ruiz Picasso-Pablo Picasso Apt-M-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "YJM",
            "countryCode": "ES",
            "cityCode": "AGP",
            "cityName": "Malaga",
            "name": "[YJM] - Malaga Railway Station - AGP - Malaga - Spain",
            "searchName": "YJM-AGP-Fort St.James-Malaga Railway Station-Malaga Railway Stn-Fort St James(Perison)-M-Malaga-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "XQA",
            "countryCode": "ES",
            "cityCode": "SVQ",
            "cityName": "Sevilla",
            "name": "[XQA] - Santa Justa Railway Station - SVQ - Sevilla - Spain",
            "searchName": "XQA-SVQ-Santa Justa Railway Station-Sevilla-S-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "SPO",
            "countryCode": "ES",
            "cityCode": "SPO",
            "cityName": "San Pablo",
            "name": "[SPO] - San Pablo - SPO - San Pablo - Spain",
            "searchName": "SPO-San Pablo-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "SPC",
            "countryCode": "ES",
            "cityCode": "SPC",
            "cityName": "Santa Cruz De La Palma",
            "name": "[SPC] - La Palma - SPC - Santa Cruz De La Palma - Spain",
            "searchName": "SPC-Santa Cruz de la Palma-La Palma-Santa Cruz de La Palma-Santa Cruz De La Palma-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "SLM",
            "countryCode": "ES",
            "cityCode": "SLM",
            "cityName": "Salamanca",
            "name": "[SLM] - Matacan - SLM - Salamanca - Spain",
            "searchName": "SLM-Salamanca-Matacan-Matacan Airport-Salamanque-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "SDR",
            "countryCode": "ES",
            "cityCode": "SDR",
            "cityName": "Santander",
            "name": "[SDR] - Santander - SDR - Santander - Spain",
            "searchName": "SDR-Santander-Santander Airport-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "YJL",
            "countryCode": "ES",
            "cityCode": "SDR",
            "cityName": "Santander",
            "name": "[YJL] - Santander Railway Station - SDR - Santander - Spain",
            "searchName": "YJL-SDR-Santander Railway Station-Santander-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "SCQ",
            "countryCode": "ES",
            "cityCode": "SCQ",
            "cityName": "Santiago De Compostela",
            "name": "[SCQ] - Santiago De Compostela - SCQ - Santiago De Compostela - Spain",
            "searchName": "SCQ-Santiago De Compostela-SANTIAGO AIRPORT-Santiago de Compostela-St Jacques de Compostelle-Santiago di Compostela-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "UTL",
            "countryCode": "ES",
            "cityCode": "UTL",
            "cityName": "Torremolinos",
            "name": "[UTL] - Torremolinos - UTL - Torremolinos - Spain",
            "searchName": "UTL-Torremolinos-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "VGO",
            "countryCode": "ES",
            "cityCode": "VGO",
            "cityName": "Vigo",
            "name": "[VGO] - Vigo - VGO - Vigo - Spain",
            "searchName": "VGO-Vigo Peinador-Vigo-Peinador Apt.-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "YJR",
            "countryCode": "ES",
            "cityCode": "VGO",
            "cityName": "Vigo",
            "name": "[YJR] - Vigo Railway Station - VGO - Vigo - Spain",
            "searchName": "YJR-VGO-Vigo Railway Station-Vigo-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "VDE",
            "countryCode": "ES",
            "cityCode": "VDE",
            "cityName": "Valverde",
            "name": "[VDE] - Hierro - VDE - Valverde - Spain",
            "searchName": "VDE-Valverde-Hierro-El Hierro-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "UER",
            "countryCode": "ES",
            "cityCode": "UER",
            "cityName": "Puertollano",
            "name": "[UER] - Puertollano - UER - Puertollano - Spain",
            "searchName": "UER-Puertollano-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "VLL",
            "countryCode": "ES",
            "cityCode": "VLL",
            "cityName": "Valladolid",
            "name": "[VLL] - Valladolid - VLL - Valladolid - Spain",
            "searchName": "VLL-Valladolid-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "XIV",
            "countryCode": "ES",
            "cityCode": "VLL",
            "cityName": "Valladolid",
            "name": "[XIV] - Campo Grande Rail Station - VLL - Valladolid - Spain",
            "searchName": "XIV-VLL-Campo Grande Rail Station-Valladolid-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "XJN",
            "countryCode": "ES",
            "cityCode": "VLL",
            "cityName": "Valladolid",
            "name": "[XJN] - Valladolid Bus Station - VLL - Valladolid - Spain",
            "searchName": "XJN-VLL-Valladolid Bus Station-Valladolid-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "VIT",
            "countryCode": "ES",
            "cityCode": "VIT",
            "cityName": "Vitoria",
            "name": "[VIT] - Vitoria - VIT - Vitoria - Spain",
            "searchName": "VIT-Vitoria Spanien-Vitoria-gasteiz-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "JSH",
            "countryCode": "GR",
            "cityCode": "JSH",
            "cityName": "Sitia",
            "name": "[JSH] - Sitia - JSH - Sitia - Greece",
            "searchName": "JSH-Sitia-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "JSI",
            "countryCode": "GR",
            "cityCode": "JSI",
            "cityName": "Skiathos",
            "name": "[JSI] - Skiathos - JSI - Skiathos - Greece",
            "searchName": "JSI-Skiathos-Skiathos Airport-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "JNX",
            "countryCode": "GR",
            "cityCode": "JNX",
            "cityName": "Naxos",
            "name": "[JNX] - Naxos Airport - JNX - Naxos - Greece",
            "searchName": "JNX-Naxos-Naxos Airport-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "JKL",
            "countryCode": "GR",
            "cityCode": "JKL",
            "cityName": "Kalymnos Island",
            "name": "[JKL] - Kalymnos Island - JKL - Kalymnos Island - Greece",
            "searchName": "JKL-Kalymnos-Kalymnos Island-Kalymnos Airport-Kalimnos-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "IOA",
            "countryCode": "GR",
            "cityCode": "IOA",
            "cityName": "Ioannina",
            "name": "[IOA] - Ioannina - IOA - Ioannina - Greece",
            "searchName": "IOA-Ioannina-Ioannina Airport-Ioanina-Giannina-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "GPA",
            "countryCode": "GR",
            "cityCode": "GPA",
            "cityName": "Patras",
            "name": "[GPA] - Araxos Airport - GPA - Patras - Greece",
            "searchName": "GPA-Araxos-Araxos Airport-Patras-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "IMB",
            "countryCode": "GY",
            "cityCode": "IMB",
            "cityName": "Imbaimadai",
            "name": "[IMB] - Imbaimadai - IMB - Imbaimadai - Guyana",
            "searchName": "IMB-Imbaimadai-Guyana",
            "priority": 0
        },
        {
            "iataCode": "LUB",
            "countryCode": "GY",
            "cityCode": "LUB",
            "cityName": "Lumid Pau",
            "name": "[LUB] - Lumid Pau - LUB - Lumid Pau - Guyana",
            "searchName": "LUB-Lumid Pau-Guyana",
            "priority": 0
        },
        {
            "iataCode": "LTM",
            "countryCode": "GY",
            "cityCode": "LTM",
            "cityName": "Lethem",
            "name": "[LTM] - Lethem - LTM - Lethem - Guyana",
            "searchName": "LTM-Lethem-Guyana",
            "priority": 0
        },
        {
            "iataCode": "KAI",
            "countryCode": "GY",
            "cityCode": "KAI",
            "cityName": "Kaieteur",
            "name": "[KAI] - Kaieteur - KAI - Kaieteur - Guyana",
            "searchName": "KAI-Kaieteur-Guyana",
            "priority": 0
        },
        {
            "iataCode": "KAR",
            "countryCode": "GY",
            "cityCode": "KAR",
            "cityName": "Kamarang",
            "name": "[KAR] - Kamarang - KAR - Kamarang - Guyana",
            "searchName": "KAR-Kamarang-Guyana",
            "priority": 0
        },
        {
            "iataCode": "KKG",
            "countryCode": "GY",
            "cityCode": "KKG",
            "cityName": "Konawaruk",
            "name": "[KKG] - Konawaruk - KKG - Konawaruk - Guyana",
            "searchName": "KKG-Konawaruk-Guyana",
            "priority": 0
        },
        {
            "iataCode": "KTO",
            "countryCode": "GY",
            "cityCode": "KTO",
            "cityName": "Kato",
            "name": "[KTO] - Kato - KTO - Kato - Guyana",
            "searchName": "KTO-Kato-Guyana",
            "priority": 0
        },
        {
            "iataCode": "KRM",
            "countryCode": "GY",
            "cityCode": "KRM",
            "cityName": "Karanambo",
            "name": "[KRM] - Karanambo - KRM - Karanambo - Guyana",
            "searchName": "KRM-Karanambo-Guyana",
            "priority": 0
        },
        {
            "iataCode": "KRG",
            "countryCode": "GY",
            "cityCode": "KRG",
            "cityName": "Karasabai",
            "name": "[KRG] - Karasabai - KRG - Karasabai - Guyana",
            "searchName": "KRG-Karasabai-Guyana",
            "priority": 0
        },
        {
            "iataCode": "KPG",
            "countryCode": "GY",
            "cityCode": "KPG",
            "cityName": "Kurupung",
            "name": "[KPG] - Kurupung - KPG - Kurupung - Guyana",
            "searchName": "KPG-Kurupung-Guyana",
            "priority": 0
        },
        {
            "iataCode": "LRA",
            "countryCode": "GR",
            "cityCode": "LRA",
            "cityName": "Larisa",
            "name": "[LRA] - Larisa - LRA - Larisa - Greece",
            "searchName": "LRA-Larisa-Larisa Havalimani-Larissa-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "LRS",
            "countryCode": "GR",
            "cityCode": "LRS",
            "cityName": "Leros",
            "name": "[LRS] - Leros - LRS - Leros - Greece",
            "searchName": "LRS-Leros-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "LXS",
            "countryCode": "GR",
            "cityCode": "LXS",
            "cityName": "Limnos",
            "name": "[LXS] - Limnos - LXS - Limnos - Greece",
            "searchName": "LXS-Lemnos-Limnos-Limnos Airport-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "KVA",
            "countryCode": "GR",
            "cityCode": "KVA",
            "cityName": "Kavala",
            "name": "[KVA] - Megas Alexandros International - KVA - Kavala - Greece",
            "searchName": "KVA-Kavala-Megas Alexandros International-Megas Alexandros-Megas Alexandros Airport-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "KZI",
            "countryCode": "GR",
            "cityCode": "KZI",
            "cityName": "Kozani",
            "name": "[KZI] - Philippos Airport - KZI - Kozani - Greece",
            "searchName": "KZI-Kozani-Philippos Airport-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "KZS",
            "countryCode": "GR",
            "cityCode": "KZS",
            "cityName": "Kastelorizo",
            "name": "[KZS] - Kastelorizo - KZS - Kastelorizo - Greece",
            "searchName": "KZS-Kastellorizo-Kastelorizo-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "KSJ",
            "countryCode": "GR",
            "cityCode": "KSJ",
            "cityName": "Kasos Island",
            "name": "[KSJ] - Kasos Island - KSJ - Kasos Island - Greece",
            "searchName": "KSJ-Kasos-Kasos Island-Kasos Airport-Kasos Inseln--Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland-Greece",
            "priority": 0
        },
        {
            "iataCode": "KSO",
            "countryCode": "GR",
            "cityCode": "KSO",
            "cityName": "Kastoria",
            "name": "[KSO] - Aristoteles Airport - KSO - Kastoria - Greece",
            "searchName": "KSO-Kastoria-Aristoteles Airport-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "KLX",
            "countryCode": "GR",
            "cityCode": "KLX",
            "cityName": "Kalamata",
            "name": "[KLX] - Kalamata - KLX - Kalamata - Greece",
            "searchName": "KLX-Kalamata-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "KGS",
            "countryCode": "GR",
            "cityCode": "KGS",
            "cityName": "Kos",
            "name": "[KGS] - Kos Island International Airport - KGS - Kos - Greece",
            "searchName": "KGS-Kos Griechenland-Kos Island International Airport-Kos-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "KIT",
            "countryCode": "GR",
            "cityCode": "KIT",
            "cityName": "Kithira",
            "name": "[KIT] - Kithira - KIT - Kithira - Greece",
            "searchName": "KIT-Kithira-Kythira (-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "JIK",
            "countryCode": "GR",
            "cityCode": "JIK",
            "cityName": "Ikaria Island",
            "name": "[JIK] - Ikaria - JIK - Ikaria Island - Greece",
            "searchName": "JIK-Ikaria-Ikaria Airport-Ikaria Inseln-Ikaria Island--Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland-Greece",
            "priority": 0
        },
        {
            "iataCode": "JSY",
            "countryCode": "GR",
            "cityCode": "JSY",
            "cityName": "Syros Island",
            "name": "[JSY] - Syros Island - JSY - Syros Island - Greece",
            "searchName": "JSY-Syros-Syros Island-Syros Inseln--Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland-Greece",
            "priority": 0
        },
        {
            "iataCode": "JTR",
            "countryCode": "GR",
            "cityCode": "JTR",
            "cityName": "Santorini (Thira)",
            "name": "[JTR] - Santorini (Thira) - JTR - Santorini (Thira) - Greece",
            "searchName": "JTR-Santorini-Santorini (Thira)-Santorini/Thira-Santorini Airport-Fira-Thira-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "ZTW",
            "countryCode": "HK",
            "cityCode": "ZTW",
            "cityName": "Tsuen Wan",
            "name": "[ZTW] - Tsuen Wan Railway Station - ZTW - Tsuen Wan - Hong Kong",
            "searchName": "ZTW-Tsuen Wan Railway Station-Tsuen Wan-Hongkong-Hong Kong-Honkonga-Honkongas--Hong-Kong",
            "priority": 0
        },
        {
            "iataCode": "IRN",
            "countryCode": "HN",
            "cityCode": "IRN",
            "cityName": "Iriona",
            "name": "[IRN] - Iriona - IRN - Iriona - Honduras",
            "searchName": "IRN-Iriona-Honduras",
            "priority": 0
        },
        {
            "iataCode": "GAC",
            "countryCode": "HN",
            "cityCode": "GAC",
            "cityName": "Gracias",
            "name": "[GAC] - Gracias - GAC - Gracias - Honduras",
            "searchName": "GAC-Gracias-Honduras",
            "priority": 0
        },
        {
            "iataCode": "GJA",
            "countryCode": "HN",
            "cityCode": "GJA",
            "cityName": "Guanaja",
            "name": "[GJA] - Guanaja - GJA - Guanaja - Honduras",
            "searchName": "GJA-Guanaja-Honduras",
            "priority": 0
        },
        {
            "iataCode": "GUO",
            "countryCode": "HN",
            "cityCode": "GUO",
            "cityName": "Gualaco",
            "name": "[GUO] - Gualaco - GUO - Gualaco - Honduras",
            "searchName": "GUO-Gualaco-Honduras",
            "priority": 0
        },
        {
            "iataCode": "JUT",
            "countryCode": "HN",
            "cityCode": "JUT",
            "cityName": "Juticalpa",
            "name": "[JUT] - Juticalpa - JUT - Juticalpa - Honduras",
            "searchName": "JUT-Juticalpa-Honduras",
            "priority": 0
        },
        {
            "iataCode": "LUI",
            "countryCode": "HN",
            "cityCode": "LUI",
            "cityName": "La Union",
            "name": "[LUI] - La Union - LUI - La Union - Honduras",
            "searchName": "LUI-La Union-Honduras",
            "priority": 0
        },
        {
            "iataCode": "LMH",
            "countryCode": "HN",
            "cityCode": "LMH",
            "cityName": "Limon",
            "name": "[LMH] - Limon - LMH - Limon - Honduras",
            "searchName": "LMH-Limon-Honduras",
            "priority": 0
        },
        {
            "iataCode": "LLH",
            "countryCode": "HN",
            "cityCode": "LLH",
            "cityName": "Las Limas",
            "name": "[LLH] - Las Limas - LLH - Las Limas - Honduras",
            "searchName": "LLH-Las Limas-Honduras",
            "priority": 0
        },
        {
            "iataCode": "LCE",
            "countryCode": "HN",
            "cityCode": "LCE",
            "cityName": "La Ceiba",
            "name": "[LCE] - Goloson International - LCE - La Ceiba - Honduras",
            "searchName": "LCE-La Ceiba-Goloson International-Goloson Intl-Honduras",
            "priority": 0
        },
        {
            "iataCode": "LEZ",
            "countryCode": "HN",
            "cityCode": "LEZ",
            "cityName": "La Esperanza",
            "name": "[LEZ] - La Esperanza - LEZ - La Esperanza - Honduras",
            "searchName": "LEZ-La Esperanza-Honduras",
            "priority": 0
        },
        {
            "iataCode": "ENQ",
            "countryCode": "HN",
            "cityCode": "ENQ",
            "cityName": "Coronel E Soto Cano AB",
            "name": "[ENQ] - Coronel E Soto Cano AB - ENQ - Coronel E Soto Cano AB - Honduras",
            "searchName": "ENQ-Coronel E Soto Cano AB-Honduras",
            "priority": 0
        },
        {
            "iataCode": "EDQ",
            "countryCode": "HN",
            "cityCode": "EDQ",
            "cityName": "Erandique",
            "name": "[EDQ] - Erandique - EDQ - Erandique - Honduras",
            "searchName": "EDQ-Erandique-Honduras",
            "priority": 0
        },
        {
            "iataCode": "CYL",
            "countryCode": "HN",
            "cityCode": "CYL",
            "cityName": "Coyoles",
            "name": "[CYL] - Coyoles - CYL - Coyoles - Honduras",
            "searchName": "CYL-Coyoles-Honduras",
            "priority": 0
        },
        {
            "iataCode": "CAA",
            "countryCode": "HN",
            "cityCode": "CAA",
            "cityName": "Catacamas",
            "name": "[CAA] - Catacamas - CAA - Catacamas - Honduras",
            "searchName": "CAA-Catacamas-Honduras",
            "priority": 0
        },
        {
            "iataCode": "CDD",
            "countryCode": "HN",
            "cityCode": "CDD",
            "cityName": "Cauquira",
            "name": "[CDD] - Cauquira - CDD - Cauquira - Honduras",
            "searchName": "CDD-Cauquira-Cauquira Havalimani-Honduras",
            "priority": 0
        },
        {
            "iataCode": "AHS",
            "countryCode": "HN",
            "cityCode": "AHS",
            "cityName": "Ahuas",
            "name": "[AHS] - Ahuas - AHS - Ahuas - Honduras",
            "searchName": "AHS-Ahuas-Honduras",
            "priority": 0
        },
        {
            "iataCode": "BHG",
            "countryCode": "HN",
            "cityCode": "BHG",
            "cityName": "Brus Laguna",
            "name": "[BHG] - Brus Laguna - BHG - Brus Laguna - Honduras",
            "searchName": "BHG-Brus Laguna-Honduras",
            "priority": 0
        },
        {
            "iataCode": "UII",
            "countryCode": "HN",
            "cityCode": "UII",
            "cityName": "Utila",
            "name": "[UII] - Utila - UII - Utila - Honduras",
            "searchName": "UII-Utila-Honduras",
            "priority": 0
        },
        {
            "iataCode": "VTA",
            "countryCode": "HN",
            "cityCode": "VTA",
            "cityName": "Victoria",
            "name": "[VTA] - Victoria - VTA - Victoria - Honduras",
            "searchName": "VTA-Victoria-Honduras",
            "priority": 0
        },
        {
            "iataCode": "XPL",
            "countryCode": "HN",
            "cityCode": "XPL",
            "cityName": "Comayagua",
            "name": "[XPL] - Coronel Enrique Soto Cano AB - XPL - Comayagua - Honduras",
            "searchName": "XPL-Palmerola-Coronel Enrique Soto Cano AB-Palmerola Hava Üssü Havalimani-Comayagua-Honduras",
            "priority": 0
        },
        {
            "iataCode": "SET",
            "countryCode": "HN",
            "cityCode": "SET",
            "cityName": "San Esteban",
            "name": "[SET] - San Esteban - SET - San Esteban - Honduras",
            "searchName": "SET-San Esteban-Honduras",
            "priority": 0
        },
        {
            "iataCode": "SCD",
            "countryCode": "HN",
            "cityCode": "SCD",
            "cityName": "Sulaco",
            "name": "[SCD] - Sulaco - SCD - Sulaco - Honduras",
            "searchName": "SCD-Sulaco-Honduras",
            "priority": 0
        },
        {
            "iataCode": "TJI",
            "countryCode": "HN",
            "cityCode": "TJI",
            "cityName": "Trujillo",
            "name": "[TJI] - Capiro - TJI - Trujillo - Honduras",
            "searchName": "TJI-Capiro-Trujillo-Honduras",
            "priority": 0
        },
        {
            "iataCode": "TEA",
            "countryCode": "HN",
            "cityCode": "TEA",
            "cityName": "Tela",
            "name": "[TEA] - Tela - TEA - Tela - Honduras",
            "searchName": "TEA-Tela-Tela Havalimani-Honduras",
            "priority": 0
        },
        {
            "iataCode": "TGU",
            "countryCode": "HN",
            "cityCode": "TGU",
            "cityName": "Tegucigalpa",
            "name": "[TGU] - Toncontin - TGU - Tegucigalpa - Honduras",
            "searchName": "TGU-Tegucigalpa-Toncontin-Honduras",
            "priority": 0
        },
        {
            "iataCode": "TCF",
            "countryCode": "HN",
            "cityCode": "TCF",
            "cityName": "Tocoa",
            "name": "[TCF] - Tocoa - TCF - Tocoa - Honduras",
            "searchName": "TCF-Tocoa-Honduras",
            "priority": 0
        },
        {
            "iataCode": "OAN",
            "countryCode": "HN",
            "cityCode": "OAN",
            "cityName": "Olanchito",
            "name": "[OAN] - Olanchito - OAN - Olanchito - Honduras",
            "searchName": "OAN-Olanchito-Honduras",
            "priority": 0
        },
        {
            "iataCode": "RUY",
            "countryCode": "HN",
            "cityCode": "RUY",
            "cityName": "Copan",
            "name": "[RUY] - Copan - RUY - Copan - Honduras",
            "searchName": "RUY-Copan-Copan Havalimani-Honduras",
            "priority": 0
        },
        {
            "iataCode": "SAP",
            "countryCode": "HN",
            "cityCode": "SAP",
            "cityName": "San Pedro Sula",
            "name": "[SAP] - Ramon Villeda Morales International - SAP - San Pedro Sula - Honduras",
            "searchName": "SAP-San Pedro Sula-Ramon Villeda Morales International-Ramon Villeda-Honduras",
            "priority": 0
        },
        {
            "iataCode": "RTB",
            "countryCode": "HN",
            "cityCode": "RTB",
            "cityName": "Roatan",
            "name": "[RTB] - Roatan - RTB - Roatan - Honduras",
            "searchName": "RTB-Roatan-Honduras",
            "priority": 0
        },
        {
            "iataCode": "PEU",
            "countryCode": "HN",
            "cityCode": "PEU",
            "cityName": "Puerto Lempira",
            "name": "[PEU] - Puerto Lempira - PEU - Puerto Lempira - Honduras",
            "searchName": "PEU-Puerto Lempira-Honduras",
            "priority": 0
        },
        {
            "iataCode": "ORO",
            "countryCode": "HN",
            "cityCode": "ORO",
            "cityName": "Yoro",
            "name": "[ORO] - Yoro - ORO - Yoro - Honduras",
            "searchName": "ORO-Yoro-Honduras",
            "priority": 0
        },
        {
            "iataCode": "PCH",
            "countryCode": "HN",
            "cityCode": "PCH",
            "cityName": "Palacios",
            "name": "[PCH] - Palacios - PCH - Palacios - Honduras",
            "searchName": "PCH-Palacios-Honduras",
            "priority": 0
        },
        {
            "iataCode": "OSI",
            "countryCode": "HR",
            "cityCode": "OSI",
            "cityName": "Osijek",
            "name": "[OSI] - Osijek - OSI - Osijek - Croatia",
            "searchName": "OSI-Osijek-Kroatië-Kroatia-Chorvatsko-Hirvatistan-Croàcia-Horvátország-Croatia-Hrvaška-Kroatija-Horvatija-Hrvatska-Horvaatia-Chorwacja-Kroasia-Kroatien-Croatie-Croacia-Croazia",
            "priority": 0
        },
        {
            "iataCode": "RJK",
            "countryCode": "HR",
            "cityCode": "RJK",
            "cityName": "Rijeka",
            "name": "[RJK] - Rijeka - RJK - Rijeka - Croatia",
            "searchName": "RJK-Rijeka-Fiume-Rijeka  Apt-Kroatië-Kroatia-Chorvatsko-Hirvatistan-Croàcia-Horvátország-Croatia-Hrvaška-Kroatija-Horvatija-Hrvatska-Horvaatia-Chorwacja-Kroasia-Kroatien-Croatie-Croacia-Croazia",
            "priority": 0
        },
        {
            "iataCode": "JOG",
            "countryCode": "ID",
            "cityCode": "JOG",
            "cityName": "Yogyakarta",
            "name": "[JOG] - Adisutjipto - JOG - Yogyakarta - Indonesia",
            "searchName": "JOG-Yogyakarta-Adisutjipto-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KAZ",
            "countryCode": "ID",
            "cityCode": "KAZ",
            "cityName": "Kau",
            "name": "[KAZ] - Kau - KAZ - Kau - Indonesia",
            "searchName": "KAZ-Kau-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KBF",
            "countryCode": "ID",
            "cityCode": "KBF",
            "cityName": "Karubaga",
            "name": "[KBF] - Karubaga - KBF - Karubaga - Indonesia",
            "searchName": "KBF-Karubaga-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KDI",
            "countryCode": "ID",
            "cityCode": "KDI",
            "cityName": "Kendari",
            "name": "[KDI] - Wolter Monginsidi - KDI - Kendari - Indonesia",
            "searchName": "KDI-Wolter Monginsidi-Kendari-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KBX",
            "countryCode": "ID",
            "cityCode": "KBX",
            "cityName": "Kambuaya",
            "name": "[KBX] - Kambuaya - KBX - Kambuaya - Indonesia",
            "searchName": "KBX-Kambuaya-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KCD",
            "countryCode": "ID",
            "cityCode": "KCD",
            "cityName": "Kamur",
            "name": "[KCD] - Kamur - KCD - Kamur - Indonesia",
            "searchName": "KCD-Kamur-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KBU",
            "countryCode": "ID",
            "cityCode": "KBU",
            "cityName": "Kotabaru",
            "name": "[KBU] - Kotabaru - KBU - Kotabaru - Indonesia",
            "searchName": "KBU-Kotabaru-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KCI",
            "countryCode": "ID",
            "cityCode": "KCI",
            "cityName": "Kon",
            "name": "[KCI] - Kon - KCI - Kon - Indonesia",
            "searchName": "KCI-Kon-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KEA",
            "countryCode": "ID",
            "cityCode": "KEA",
            "cityName": "Keisah",
            "name": "[KEA] - Keisah - KEA - Keisah - Indonesia",
            "searchName": "KEA-Keisah-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KEI",
            "countryCode": "ID",
            "cityCode": "KEI",
            "cityName": "Kepi",
            "name": "[KEI] - Kepi - KEI - Kepi - Indonesia",
            "searchName": "KEI-Kepi-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KEQ",
            "countryCode": "ID",
            "cityCode": "KEQ",
            "cityName": "Kebar",
            "name": "[KEQ] - Kebar - KEQ - Kebar - Indonesia",
            "searchName": "KEQ-Kebar-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KOX",
            "countryCode": "ID",
            "cityCode": "KOX",
            "cityName": "Kokonao",
            "name": "[KOX] - Kokonao - KOX - Kokonao - Indonesia",
            "searchName": "KOX-Kokonao-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KNG",
            "countryCode": "ID",
            "cityCode": "KNG",
            "cityName": "Kaimana",
            "name": "[KNG] - Kaimana - KNG - Kaimana - Indonesia",
            "searchName": "KNG-Kaimana-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KOD",
            "countryCode": "ID",
            "cityCode": "KOD",
            "cityName": "Kotabangun",
            "name": "[KOD] - Kotabangun - KOD - Kotabangun - Indonesia",
            "searchName": "KOD-Kotabangun-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KRC",
            "countryCode": "ID",
            "cityCode": "KRC",
            "cityName": "Kerinci",
            "name": "[KRC] - Kerinci - KRC - Kerinci - Indonesia",
            "searchName": "KRC-Kerinci-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KTG",
            "countryCode": "ID",
            "cityCode": "KTG",
            "cityName": "Ketapang",
            "name": "[KTG] - Ketapang - KTG - Ketapang - Indonesia",
            "searchName": "KTG-Ketapang-Ketapang Havalimani-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KLQ",
            "countryCode": "ID",
            "cityCode": "KLQ",
            "cityName": "Keluang",
            "name": "[KLQ] - Keluang - KLQ - Keluang - Indonesia",
            "searchName": "KLQ-Keluang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KMM",
            "countryCode": "ID",
            "cityCode": "KMM",
            "cityName": "Kimam",
            "name": "[KMM] - Kimam - KMM - Kimam - Indonesia",
            "searchName": "KMM-Kimam-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LSW",
            "countryCode": "ID",
            "cityCode": "LSW",
            "cityName": "Lhoksumawe",
            "name": "[LSW] - Lhoksumawe - LSW - Lhoksumawe - Indonesia",
            "searchName": "LSW-Lhoksumawe-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LSX",
            "countryCode": "ID",
            "cityCode": "LSX",
            "cityName": "Lhok Sukon",
            "name": "[LSX] - Lhok Sukon - LSX - Lhok Sukon - Indonesia",
            "searchName": "LSX-Lhok Sukon-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LUV",
            "countryCode": "ID",
            "cityCode": "LUV",
            "cityName": "Langgur",
            "name": "[LUV] - Langgur - LUV - Langgur - Indonesia",
            "searchName": "LUV-Langgur-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LUW",
            "countryCode": "ID",
            "cityCode": "LUW",
            "cityName": "Luwuk",
            "name": "[LUW] - Bubung / Aminuddin Amir - LUW - Luwuk - Indonesia",
            "searchName": "LUW-Luwuk-Bubung / Aminuddin Amir-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LWE",
            "countryCode": "ID",
            "cityCode": "LWE",
            "cityName": "Lewoleba",
            "name": "[LWE] - Lewoleba - LWE - Lewoleba - Indonesia",
            "searchName": "LWE-Lewoleba-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LYK",
            "countryCode": "ID",
            "cityCode": "LYK",
            "cityName": "Lunyuk",
            "name": "[LYK] - Lunyuk - LYK - Lunyuk - Indonesia",
            "searchName": "LYK-Lunyuk-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LKA",
            "countryCode": "ID",
            "cityCode": "LKA",
            "cityName": "Larantuka",
            "name": "[LKA] - Larantuka - LKA - Larantuka - Indonesia",
            "searchName": "LKA-Larantuka-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LLN",
            "countryCode": "ID",
            "cityCode": "LLN",
            "cityName": "Kelila",
            "name": "[LLN] - Kelila - LLN - Kelila - Indonesia",
            "searchName": "LLN-Kelila-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LOP",
            "countryCode": "ID",
            "cityCode": "LOP",
            "cityName": "Praya",
            "name": "[LOP] - Lombok International - LOP - Praya - Indonesia",
            "searchName": "LOP-Lombok International-Lombok International Airport-Lombok-Praya-Mataram-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LPU",
            "countryCode": "ID",
            "cityCode": "LPU",
            "cityName": "Long Apung",
            "name": "[LPU] - Long Apung - LPU - Long Apung - Indonesia",
            "searchName": "LPU-Long Apung-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LII",
            "countryCode": "ID",
            "cityCode": "LII",
            "cityName": "Mulia",
            "name": "[LII] - Mulia - LII - Mulia - Indonesia",
            "searchName": "LII-Mulia-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LHI",
            "countryCode": "ID",
            "cityCode": "LHI",
            "cityName": "Lereh",
            "name": "[LHI] - Lereh - LHI - Lereh - Indonesia",
            "searchName": "LHI-Lereh-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LBW",
            "countryCode": "ID",
            "cityCode": "LBW",
            "cityName": "Long Bawan",
            "name": "[LBW] - Long Bawan - LBW - Long Bawan - Indonesia",
            "searchName": "LBW-Long Bawan-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LAH",
            "countryCode": "ID",
            "cityCode": "LAH",
            "cityName": "Labuha",
            "name": "[LAH] - Labuha - LAH - Labuha - Indonesia",
            "searchName": "LAH-Labuha-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "LBJ",
            "countryCode": "ID",
            "cityCode": "LBJ",
            "cityName": "Labuan Bajo",
            "name": "[LBJ] - Mutiara - LBJ - Labuan Bajo - Indonesia",
            "searchName": "LBJ-Mutiara-Labuan Bajo-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KWB",
            "countryCode": "ID",
            "cityCode": "KWB",
            "cityName": "Karimunjawa",
            "name": "[KWB] - Karimunjawa - KWB - Karimunjawa - Indonesia",
            "searchName": "KWB-Karimunjawa-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BTW",
            "countryCode": "ID",
            "cityCode": "BTW",
            "cityName": "Batu Licin",
            "name": "[BTW] - Batu Licin - BTW - Batu Licin - Indonesia",
            "searchName": "BTW-Batu Licin-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BTJ",
            "countryCode": "ID",
            "cityCode": "BTJ",
            "cityName": "Banda Aceh",
            "name": "[BTJ] - Sultan Iskandarmuda Airport - BTJ - Banda Aceh - Indonesia",
            "searchName": "BTJ-Banda Aceh-Sultan Iskandarmuda Airport-Blang Bintang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BUI",
            "countryCode": "ID",
            "cityCode": "BUI",
            "cityName": "Bokondini",
            "name": "[BUI] - Bokondini - BUI - Bokondini - Indonesia",
            "searchName": "BUI-Bokondini-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BUW",
            "countryCode": "ID",
            "cityCode": "BUW",
            "cityName": "Baubau",
            "name": "[BUW] - Baubau - BUW - Baubau - Indonesia",
            "searchName": "BUW-Baubau-Betoambari Airport-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BXM",
            "countryCode": "ID",
            "cityCode": "BXM",
            "cityName": "Batom",
            "name": "[BXM] - Batom - BXM - Batom - Indonesia",
            "searchName": "BXM-Batom-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BXT",
            "countryCode": "ID",
            "cityCode": "BXT",
            "cityName": "Bontang",
            "name": "[BXT] - Bontang - BXT - Bontang - Indonesia",
            "searchName": "BXT-Bontang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BXB",
            "countryCode": "ID",
            "cityCode": "BXB",
            "cityName": "Babo",
            "name": "[BXB] - Babo - BXB - Babo - Indonesia",
            "searchName": "BXB-Babo-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BXD",
            "countryCode": "ID",
            "cityCode": "BXD",
            "cityName": "Bade",
            "name": "[BXD] - Bade - BXD - Bade - Indonesia",
            "searchName": "BXD-Bade-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BYQ",
            "countryCode": "ID",
            "cityCode": "BYQ",
            "cityName": "Bunyu",
            "name": "[BYQ] - Bunyu - BYQ - Bunyu - Indonesia",
            "searchName": "BYQ-Bunyu-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BTH",
            "countryCode": "ID",
            "cityCode": "BTH",
            "cityName": "Batam",
            "name": "[BTH] - Hang Nadim - BTH - Batam - Indonesia",
            "searchName": "BTH-Batam-Hang Nadim-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BIK",
            "countryCode": "ID",
            "cityCode": "BIK",
            "cityName": "Biak",
            "name": "[BIK] - Frans Kaisepo - BIK - Biak - Indonesia",
            "searchName": "BIK-Biak-Frans Kaisepo-Frans Kaisiepo-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BJK",
            "countryCode": "ID",
            "cityCode": "BJK",
            "cityName": "Benjina",
            "name": "[BJK] - Benjina - BJK - Benjina - Indonesia",
            "searchName": "BJK-Benjina-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BJG",
            "countryCode": "ID",
            "cityCode": "BJG",
            "cityName": "Bolaang",
            "name": "[BJG] - Bolaang - BJG - Bolaang - Indonesia",
            "searchName": "BJG-Bolaang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BMU",
            "countryCode": "ID",
            "cityCode": "BMU",
            "cityName": "Bima",
            "name": "[BMU] - Bima - BMU - Bima - Indonesia",
            "searchName": "BMU-Bima-Sultan M. Salahuddin-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BJW",
            "countryCode": "ID",
            "cityCode": "BJW",
            "cityName": "Bajawa",
            "name": "[BJW] - Bajawa - BJW - Bajawa - Indonesia",
            "searchName": "BJW-Bajawa-Soa Airport-Badjawa-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BKS",
            "countryCode": "ID",
            "cityCode": "BKS",
            "cityName": "Bengkulu",
            "name": "[BKS] - Padangkemiling - BKS - Bengkulu - Indonesia",
            "searchName": "BKS-Padangkemiling-Fatmawati Soekarno-Bengkulu-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BDO",
            "countryCode": "ID",
            "cityCode": "BDO",
            "cityName": "Bandung",
            "name": "[BDO] - Husein Sastranegara - BDO - Bandung - Indonesia",
            "searchName": "BDO-Bandung-Husein Sastranegara-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BDJ",
            "countryCode": "ID",
            "cityCode": "BDJ",
            "cityName": "Banjarmasin",
            "name": "[BDJ] - Sjamsudin Noor - BDJ - Banjarmasin - Indonesia",
            "searchName": "BDJ-Sjamsudin Noor-Syamsudin Noor-Banjarmasin-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "BEJ",
            "countryCode": "ID",
            "cityCode": "BEJ",
            "cityName": "Tanjung Redep",
            "name": "[BEJ] - Kalimaru - BEJ - Tanjung Redep - Indonesia",
            "searchName": "BEJ-Berau-Kalimaru-Kalimarau Airport-Tanjung Redep-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "AHI",
            "countryCode": "ID",
            "cityCode": "AHI",
            "cityName": "Amahai",
            "name": "[AHI] - Amahai - AHI - Amahai - Indonesia",
            "searchName": "AHI-Amahai-Amahai Havalimani-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "AKQ",
            "countryCode": "ID",
            "cityCode": "AKQ",
            "cityName": "Astraksetra",
            "name": "[AKQ] - Gunung Batin - AKQ - Astraksetra - Indonesia",
            "searchName": "AKQ-Gunung Batin-Astraksetra-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "AMQ",
            "countryCode": "ID",
            "cityCode": "AMQ",
            "cityName": "Ambon",
            "name": "[AMQ] - Pattimura - AMQ - Ambon - Indonesia",
            "searchName": "AMQ-Pattimura-Ambon-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "AAS",
            "countryCode": "ID",
            "cityCode": "AAS",
            "cityName": "Apalapsili",
            "name": "[AAS] - Apalapsili - AAS - Apalapsili - Indonesia",
            "searchName": "AAS-Apalapsili-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ABU",
            "countryCode": "ID",
            "cityCode": "ABU",
            "cityName": "Atambua",
            "name": "[ABU] - Atambua - ABU - Atambua - Indonesia",
            "searchName": "ABU-Atambua-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "AEG",
            "countryCode": "ID",
            "cityCode": "AEG",
            "cityName": "Aek Godang",
            "name": "[AEG] - Aek Godang - AEG - Aek Godang - Indonesia",
            "searchName": "AEG-Aek Godang-Aek Godang Havalimani-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "AGD",
            "countryCode": "ID",
            "cityCode": "AGD",
            "cityName": "Anggi",
            "name": "[AGD] - Anggi - AGD - Anggi - Indonesia",
            "searchName": "AGD-Anggi-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ARD",
            "countryCode": "ID",
            "cityCode": "ARD",
            "cityName": "Alor Island",
            "name": "[ARD] - Alor Island - ARD - Alor Island - Indonesia",
            "searchName": "ARD-Alor Island-Mali Airport-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ARJ",
            "countryCode": "ID",
            "cityCode": "ARJ",
            "cityName": "Arso",
            "name": "[ARJ] - Arso - ARJ - Arso - Indonesia",
            "searchName": "ARJ-Arso-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "AUT",
            "countryCode": "ID",
            "cityCode": "AUT",
            "cityName": "Atauro",
            "name": "[AUT] - Atauro - AUT - Atauro - Indonesia",
            "searchName": "AUT-Atauro-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "AYW",
            "countryCode": "ID",
            "cityCode": "AYW",
            "cityName": "Ayawasi",
            "name": "[AYW] - Ayawasi - AYW - Ayawasi - Indonesia",
            "searchName": "AYW-Ayawasi-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "DJB",
            "countryCode": "ID",
            "cityCode": "DJB",
            "cityName": "Jambi",
            "name": "[DJB] - Sultan Taha Syarifudn - DJB - Jambi - Indonesia",
            "searchName": "DJB-Sultan T. Syarifudn-Sultan Taha Syarifudn-Jambi-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "DJJ",
            "countryCode": "ID",
            "cityCode": "DJJ",
            "cityName": "Jayapura",
            "name": "[DJJ] - Sentani - DJJ - Jayapura - Indonesia",
            "searchName": "DJJ-Sentani-Jayapura-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "DOB",
            "countryCode": "ID",
            "cityCode": "DOB",
            "cityName": "Dobo",
            "name": "[DOB] - Dobo Airport - DOB - Dobo - Indonesia",
            "searchName": "DOB-Dobo Airport-Dobo-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "XNM",
            "countryCode": "GB",
            "cityCode": "NQT",
            "cityName": "Nottingham",
            "name": "[XNM] - Nottingham Railway Station - NQT - Nottingham - United Kingdom",
            "searchName": "XNM-NQT-Nottingham Railway Station-Nottingham-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ZGB",
            "countryCode": "GB",
            "cityCode": "NQT",
            "cityName": "Nottingham",
            "name": "[ZGB] - Nottingham Bus Station - NQT - Nottingham - United Kingdom",
            "searchName": "ZGB-NQT-Nottingham Bus Station-Nottingham-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ZMA",
            "countryCode": "GB",
            "cityCode": "ZMA",
            "cityName": "Mansfield",
            "name": "[ZMA] - Mansfield Bus Station - ZMA - Mansfield - United Kingdom",
            "searchName": "ZMA-Mansfield Bus Station-Mansfield-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ZEQ",
            "countryCode": "GB",
            "cityCode": "ZEQ",
            "cityName": "Dewsbury",
            "name": "[ZEQ] - Dewsbury Bus Station - ZEQ - Dewsbury - United Kingdom",
            "searchName": "ZEQ-Dewsbury Bus Station-Dewsbury-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ZFI",
            "countryCode": "GB",
            "cityCode": "ZFI",
            "cityName": "Chesterfield",
            "name": "[ZFI] - Chesterfield Bus Station - ZFI - Chesterfield - United Kingdom",
            "searchName": "ZFI-Chesterfield Bus Station-Chesterfield-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "GND",
            "countryCode": "GD",
            "cityCode": "GND",
            "cityName": "St. Georges",
            "name": "[GND] - Point Salines International - GND - St. Georges - Grenada",
            "searchName": "GND-Saint Georges-Point Salines International-Point Saline Intl-Grenada-St. Georges-Grenade-Granada",
            "priority": 0
        },
        {
            "iataCode": "CRU",
            "countryCode": "GD",
            "cityCode": "CRU",
            "cityName": "Carriacou Island",
            "name": "[CRU] - Carriacou Island - CRU - Carriacou Island - Grenada",
            "searchName": "CRU-Carriacou Island-Grenada-Grenade-Granada",
            "priority": 0
        },
        {
            "iataCode": "NDY",
            "countryCode": "GB",
            "cityCode": "NDY",
            "cityName": "Sanday",
            "name": "[NDY] - Sanday - NDY - Sanday - United Kingdom",
            "searchName": "NDY-Sanday-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "OKH",
            "countryCode": "GB",
            "cityCode": "OKH",
            "cityName": "Oakham",
            "name": "[OKH] - Cottesmor RAF - OKH - Oakham - United Kingdom",
            "searchName": "OKH-Cottesmor RAF-Oakham-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ODH",
            "countryCode": "GB",
            "cityCode": "ODH",
            "cityName": "Odiham",
            "name": "[ODH] - RAF Station - ODH - Odiham - United Kingdom",
            "searchName": "ODH-RAF Station-Odiham-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "OBN",
            "countryCode": "GB",
            "cityCode": "OBN",
            "cityName": "Oban",
            "name": "[OBN] - Oban Airport - OBN - Oban - United Kingdom",
            "searchName": "OBN-Oban Airport-Oban-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "NWI",
            "countryCode": "GB",
            "cityCode": "NWI",
            "cityName": "Norwich",
            "name": "[NWI] - Norwich International Airport - NWI - Norwich - United Kingdom",
            "searchName": "NWI-Norwich-Norwich International Airport-Norwich Arpt-Norwich Int. Apt-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "NRL",
            "countryCode": "GB",
            "cityCode": "NRL",
            "cityName": "North Ronaldsay",
            "name": "[NRL] - North Ronaldsay - NRL - North Ronaldsay - United Kingdom",
            "searchName": "NRL-North Ronaldsay-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "NQY",
            "countryCode": "GB",
            "cityCode": "NQY",
            "cityName": "Newquay",
            "name": "[NQY] - Newquay Cornwall Airport - NQY - Newquay - United Kingdom",
            "searchName": "NQY-Newquay-Newquay Cornwall Airport-St Mawgan-Newquay Int. Apt-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "NQT",
            "countryCode": "GB",
            "cityCode": "NQT",
            "cityName": "Nottingham",
            "name": "[NQT] - Nottingham Airport - NQT - Nottingham - United Kingdom",
            "searchName": "NQT-Nottingham Airport-tüm havaalanlari-Nottingham-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "AOK",
            "countryCode": "GR",
            "cityCode": "AOK",
            "cityName": "Karpathos",
            "name": "[AOK] - Karpathos - AOK - Karpathos - Greece",
            "searchName": "AOK-Karpathos-Karpathos Airport-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "EFL",
            "countryCode": "GR",
            "cityCode": "EFL",
            "cityName": "Kefalonia",
            "name": "[EFL] - Kefalonia Istland International Airport - EFL - Kefalonia - Greece",
            "searchName": "EFL-Kephallonia-Kefalonia Istland International Airport-Argostolion-Argostoli-Kefallinia-Kefalonia-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "KUT",
            "countryCode": "GE",
            "cityCode": "KUT",
            "cityName": "Kutaisi",
            "name": "[KUT] - Kopitnari - KUT - Kutaisi - Georgia",
            "searchName": "KUT-Kopitnari-Kutaisi-Georgia-Georgien-Gruzie-Gürcistan-Geòrgia-Grúzia-Gruzija-Gruusia-Gruzja-Géo-Géorgie",
            "priority": 0
        },
        {
            "iataCode": "SUI",
            "countryCode": "GE",
            "cityCode": "SUI",
            "cityName": "Sukhumi",
            "name": "[SUI] - Babusheri - SUI - Sukhumi - Georgia",
            "searchName": "SUI-Babusheri-Sukhumi-Georgia-Georgien-Gruzie-Gürcistan-Geòrgia-Grúzia-Gruzija-Gruusia-Gruzja-Géo-Géorgie",
            "priority": 0
        },
        {
            "iataCode": "SKU",
            "countryCode": "GR",
            "cityCode": "SKU",
            "cityName": "Skiros",
            "name": "[SKU] - Skiros - SKU - Skiros - Greece",
            "searchName": "SKU-Skiros-Skiros Arpt-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "VOL",
            "countryCode": "GR",
            "cityCode": "VOL",
            "cityName": "Volos",
            "name": "[VOL] - Nea Anchialos - VOL - Volos - Greece",
            "searchName": "VOL-Volos Anchialos-Nea Anchialos-Nea Anchialos Airport-Volos-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "PYR",
            "countryCode": "GR",
            "cityCode": "PYR",
            "cityName": "Pyrgos",
            "name": "[PYR] - Andravida - PYR - Pyrgos - Greece",
            "searchName": "PYR-Andravida-Andravida Airport-Pyrgos-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "PVK",
            "countryCode": "GR",
            "cityCode": "PVK",
            "cityName": "Preveza",
            "name": "[PVK] - Aktion - PVK - Preveza - Greece",
            "searchName": "PVK-Preveza-Aktion-Aktion Airport-Preveza/Lefkas-Pr-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "RHO",
            "countryCode": "GR",
            "cityCode": "RHO",
            "cityName": "Rhodes",
            "name": "[RHO] - Diagoras - RHO - Rhodes - Greece",
            "searchName": "RHO-Rhodos-Diagoras-Diagoras Airport-Rhodes-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "PKH",
            "countryCode": "GR",
            "cityCode": "PKH",
            "cityName": "Porto Kheli",
            "name": "[PKH] - Alexion - PKH - Porto Kheli - Greece",
            "searchName": "PKH-Alexion-Porto Kheli-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "PAS",
            "countryCode": "GR",
            "cityCode": "PAS",
            "cityName": "Paros",
            "name": "[PAS] - Paros - PAS - Paros - Greece",
            "searchName": "PAS-Paros-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "MJT",
            "countryCode": "GR",
            "cityCode": "MJT",
            "cityName": "Mytilene",
            "name": "[MJT] - Mytilene International Airport - MJT - Mytilene - Greece",
            "searchName": "MJT-Mytilene-Mytilene International Airport-Midilli-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "MLO",
            "countryCode": "GR",
            "cityCode": "MLO",
            "cityName": "Milos",
            "name": "[MLO] - Milos - MLO - Milos - Greece",
            "searchName": "MLO-Milos-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "AXD",
            "countryCode": "GR",
            "cityCode": "AXD",
            "cityName": "Alexandroupolis",
            "name": "[AXD] - Dimokritos - AXD - Alexandroupolis - Greece",
            "searchName": "AXD-Alexandroupolis-Dimokritos-Demokritos Airport-Dedeagaç-Alejandr-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "FRS",
            "countryCode": "GT",
            "cityCode": "FRS",
            "cityName": "Flores",
            "name": "[FRS] - Santa Elena - FRS - Flores - Guatemala",
            "searchName": "FRS-Flores-Santa Elena-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "HUG",
            "countryCode": "GT",
            "cityCode": "HUG",
            "cityName": "Huehuetenango",
            "name": "[HUG] - Huehuetenango - HUG - Huehuetenango - Guatemala",
            "searchName": "HUG-Huehuetenango-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "LCF",
            "countryCode": "GT",
            "cityCode": "LCF",
            "cityName": "Rio Dulce",
            "name": "[LCF] - Las Vegas - LCF - Rio Dulce - Guatemala",
            "searchName": "LCF-Las Vegas-Rio Dulce Havalimani-Rio Dulce-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "LOX",
            "countryCode": "GT",
            "cityCode": "LOX",
            "cityName": "Los Tablones",
            "name": "[LOX] - Los Tablones Airport - LOX - Los Tablones - Guatemala",
            "searchName": "LOX-Los Tablones Airport-Los Tablones-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "CIQ",
            "countryCode": "GT",
            "cityCode": "CIQ",
            "cityName": "Chiquimula",
            "name": "[CIQ] - Chiquimula - CIQ - Chiquimula - Guatemala",
            "searchName": "CIQ-Chiquimula-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "CBV",
            "countryCode": "GT",
            "cityCode": "CBV",
            "cityName": "Coban",
            "name": "[CBV] - Coban - CBV - Coban - Guatemala",
            "searchName": "CBV-Coban-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "CMM",
            "countryCode": "GT",
            "cityCode": "CMM",
            "cityName": "Carmelita",
            "name": "[CMM] - Carmelita - CMM - Carmelita - Guatemala",
            "searchName": "CMM-Carmelita-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "CTF",
            "countryCode": "GT",
            "cityCode": "CTF",
            "cityName": "Coatepeque",
            "name": "[CTF] - Coatepeque Airport - CTF - Coatepeque - Guatemala",
            "searchName": "CTF-Coatepeque Airport-Coatepeque-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "ENJ",
            "countryCode": "GT",
            "cityCode": "ENJ",
            "cityName": "El Naranjo",
            "name": "[ENJ] - El Naranjo - ENJ - El Naranjo - Guatemala",
            "searchName": "ENJ-El Naranjo-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "DON",
            "countryCode": "GT",
            "cityCode": "DON",
            "cityName": "Dos Lagunas",
            "name": "[DON] - Dos Lagunas - DON - Dos Lagunas - Guatemala",
            "searchName": "DON-Dos Lagunas-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "AQB",
            "countryCode": "GT",
            "cityCode": "AQB",
            "cityName": "Quiche",
            "name": "[AQB] - Quiche Airport - AQB - Quiche - Guatemala",
            "searchName": "AQB-Quiche Airport-Quiche-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "AAZ",
            "countryCode": "GT",
            "cityCode": "AAZ",
            "cityName": "Quetzaltenango",
            "name": "[AAZ] - Quetzaltenango - AAZ - Quetzaltenango - Guatemala",
            "searchName": "AAZ-Quetzaltenango-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "MCR",
            "countryCode": "GT",
            "cityCode": "MCR",
            "cityName": "Melchor De Menco",
            "name": "[MCR] - Melchor De Menco - MCR - Melchor De Menco - Guatemala",
            "searchName": "MCR-Melchor De Menco-Melchor de Mencos-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "PBR",
            "countryCode": "GT",
            "cityCode": "PBR",
            "cityName": "Puerto Barrios",
            "name": "[PBR] - Puerto Barrios - PBR - Puerto Barrios - Guatemala",
            "searchName": "PBR-Puerto Barrios-Puerto Barrios Havalimani-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "PCG",
            "countryCode": "GT",
            "cityCode": "PCG",
            "cityName": "Paso Caballos",
            "name": "[PCG] - Paso Caballos - PCG - Paso Caballos - Guatemala",
            "searchName": "PCG-Paso Caballos-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "PKJ",
            "countryCode": "GT",
            "cityCode": "PKJ",
            "cityName": "Playa Grande",
            "name": "[PKJ] - Playa Grande - PKJ - Playa Grande - Guatemala",
            "searchName": "PKJ-Playa Grande-Playa Grand Havalimani-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "PON",
            "countryCode": "GT",
            "cityCode": "PON",
            "cityName": "Poptun",
            "name": "[PON] - Poptun - PON - Poptun - Guatemala",
            "searchName": "PON-Poptun-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "RER",
            "countryCode": "GT",
            "cityCode": "RER",
            "cityName": "Retalhuleu",
            "name": "[RER] - Base Aerea Del Sur - RER - Retalhuleu - Guatemala",
            "searchName": "RER-Base Aerea del Sur-Base Aerea Del Sur-Base Aerea Del Sur Havalimani-Retalhuleu-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "RUV",
            "countryCode": "GT",
            "cityCode": "RUV",
            "cityName": "Rubelsanto",
            "name": "[RUV] - Rubelsanto - RUV - Rubelsanto - Guatemala",
            "searchName": "RUV-Rubelsanto-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "UAX",
            "countryCode": "GT",
            "cityCode": "UAX",
            "cityName": "Uaxactun",
            "name": "[UAX] - Uaxactun - UAX - Uaxactun - Guatemala",
            "searchName": "UAX-Uaxactun-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "TKM",
            "countryCode": "GT",
            "cityCode": "TKM",
            "cityName": "Tikal",
            "name": "[TKM] - El Peten - TKM - Tikal - Guatemala",
            "searchName": "TKM-El Peten-El Peten Havalimani-Tikal-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "GUM",
            "countryCode": "GU",
            "cityCode": "GUM",
            "cityName": "Agana",
            "name": "[GUM] - Guam International - GUM - Agana - Guam",
            "searchName": "GUM-Guam Intl-Guam International-Tamuning-Agana-Guam-Guama-Guamas",
            "priority": 0
        },
        {
            "iataCode": "UAM",
            "countryCode": "GU",
            "cityCode": "GUM",
            "cityName": "Agana",
            "name": "[UAM] - Andersen AFB - GUM - Agana - Guam",
            "searchName": "UAM-GUM-Andersen AFB-Anderson Air Force Base Havalimani-Tamuning-Agana-Guam-Guama-Guamas",
            "priority": 0
        },
        {
            "iataCode": "BQE",
            "countryCode": "GW",
            "cityCode": "BQE",
            "cityName": "Bubaque",
            "name": "[BQE] - Bubaque - BQE - Bubaque - Guinea-Bissau",
            "searchName": "BQE-Bubaque-Guinea-Bissau-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "OXB",
            "countryCode": "GW",
            "cityCode": "OXB",
            "cityName": "Bissau",
            "name": "[OXB] - Osvaldo Vieira International - OXB - Bissau - Guinea-Bissau",
            "searchName": "OXB-Bissau-Osvaldo Vieira International-Osvaldo Vieira-Biss-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "ORJ",
            "countryCode": "GY",
            "cityCode": "ORJ",
            "cityName": "Orinduik",
            "name": "[ORJ] - Orinduik - ORJ - Orinduik - Guyana",
            "searchName": "ORJ-Orinduik-Guyana",
            "priority": 0
        },
        {
            "iataCode": "PMT",
            "countryCode": "GY",
            "cityCode": "PMT",
            "cityName": "Paramakotoi",
            "name": "[PMT] - Paramakotoi - PMT - Paramakotoi - Guyana",
            "searchName": "PMT-Paramakotoi-Guyana",
            "priority": 0
        },
        {
            "iataCode": "PKM",
            "countryCode": "GY",
            "cityCode": "PKM",
            "cityName": "Port Kaituma",
            "name": "[PKM] - Port Kaituma - PKM - Port Kaituma - Guyana",
            "searchName": "PKM-Port Kaituma-Guyana",
            "priority": 0
        },
        {
            "iataCode": "PIQ",
            "countryCode": "GY",
            "cityCode": "PIQ",
            "cityName": "Pipillipai",
            "name": "[PIQ] - Pipillipai - PIQ - Pipillipai - Guyana",
            "searchName": "PIQ-Pipillipai-Guyana",
            "priority": 0
        },
        {
            "iataCode": "PRR",
            "countryCode": "GY",
            "cityCode": "PRR",
            "cityName": "Paruima",
            "name": "[PRR] - Paruima - PRR - Paruima - Guyana",
            "searchName": "PRR-Paruima-Guyana",
            "priority": 0
        },
        {
            "iataCode": "MHA",
            "countryCode": "GY",
            "cityCode": "MHA",
            "cityName": "Mahdia",
            "name": "[MHA] - Mahdia - MHA - Mahdia - Guyana",
            "searchName": "MHA-Mahdia-Guyana",
            "priority": 0
        },
        {
            "iataCode": "OGL",
            "countryCode": "GY",
            "cityCode": "OGL",
            "cityName": "Ogle",
            "name": "[OGL] - Ogle - OGL - Ogle - Guyana",
            "searchName": "OGL-Ogle-Guyana",
            "priority": 0
        },
        {
            "iataCode": "NAI",
            "countryCode": "GY",
            "cityCode": "NAI",
            "cityName": "Annai",
            "name": "[NAI] - Annai - NAI - Annai - Guyana",
            "searchName": "NAI-Annai-Guyana",
            "priority": 0
        },
        {
            "iataCode": "MYM",
            "countryCode": "GY",
            "cityCode": "MYM",
            "cityName": "Monkey Mountain",
            "name": "[MYM] - Monkey Mountain - MYM - Monkey Mountain - Guyana",
            "searchName": "MYM-Monkey Mountain-Guyana",
            "priority": 0
        },
        {
            "iataCode": "MWJ",
            "countryCode": "GY",
            "cityCode": "MWJ",
            "cityName": "Matthews Ridge",
            "name": "[MWJ] - Matthews Ridge - MWJ - Matthews Ridge - Guyana",
            "searchName": "MWJ-Matthews Ridge-Guyana",
            "priority": 0
        },
        {
            "iataCode": "SKM",
            "countryCode": "GY",
            "cityCode": "SKM",
            "cityName": "Skeldon",
            "name": "[SKM] - Skeldon - SKM - Skeldon - Guyana",
            "searchName": "SKM-Skeldon-Guyana",
            "priority": 0
        },
        {
            "iataCode": "SDC",
            "countryCode": "GY",
            "cityCode": "SDC",
            "cityName": "Sandcreek",
            "name": "[SDC] - Sandcreek - SDC - Sandcreek - Guyana",
            "searchName": "SDC-Sandcreek-Guyana",
            "priority": 0
        },
        {
            "iataCode": "USI",
            "countryCode": "GY",
            "cityCode": "USI",
            "cityName": "Mabaruma",
            "name": "[USI] - Mabaruma - USI - Mabaruma - Guyana",
            "searchName": "USI-Mabaruma-Guyana",
            "priority": 0
        },
        {
            "iataCode": "VEG",
            "countryCode": "GY",
            "cityCode": "VEG",
            "cityName": "Maikwak",
            "name": "[VEG] - Maikwak - VEG - Maikwak - Guyana",
            "searchName": "VEG-Maikwak-Guyana",
            "priority": 0
        },
        {
            "iataCode": "BCG",
            "countryCode": "GY",
            "cityCode": "BCG",
            "cityName": "Bemichi",
            "name": "[BCG] - Bemichi - BCG - Bemichi - Guyana",
            "searchName": "BCG-Bemichi-Guyana",
            "priority": 0
        },
        {
            "iataCode": "BMJ",
            "countryCode": "GY",
            "cityCode": "BMJ",
            "cityName": "Baramita",
            "name": "[BMJ] - Baramita - BMJ - Baramita - Guyana",
            "searchName": "BMJ-Baramita-Guyana",
            "priority": 0
        },
        {
            "iataCode": "AHL",
            "countryCode": "GY",
            "cityCode": "AHL",
            "cityName": "Aishalton",
            "name": "[AHL] - Aishalton - AHL - Aishalton - Guyana",
            "searchName": "AHL-Aishalton-Guyana",
            "priority": 0
        },
        {
            "iataCode": "EKE",
            "countryCode": "GY",
            "cityCode": "EKE",
            "cityName": "Ekereku",
            "name": "[EKE] - Ekereku - EKE - Ekereku - Guyana",
            "searchName": "EKE-Ekereku-Guyana",
            "priority": 0
        },
        {
            "iataCode": "GFO",
            "countryCode": "GY",
            "cityCode": "GFO",
            "cityName": "Bartica",
            "name": "[GFO] - Bartica - GFO - Bartica - Guyana",
            "searchName": "GFO-Bartica-Guyana",
            "priority": 0
        },
        {
            "iataCode": "XAU",
            "countryCode": "GF",
            "cityCode": "XAU",
            "cityName": "Saul",
            "name": "[XAU] - Saul - XAU - Saul - French Guiana",
            "searchName": "XAU-Saul-Französisch-Guayana-French Guiana-Fransiz Guyanasi-Guyane française-Guayana Francesa-Guiana Francese",
            "priority": 0
        },
        {
            "iataCode": "MPY",
            "countryCode": "GF",
            "cityCode": "MPY",
            "cityName": "Maripasoula",
            "name": "[MPY] - Maripasoula - MPY - Maripasoula - French Guiana",
            "searchName": "MPY-Maripasoula-Französisch-Guayana-French Guiana-Fransiz Guyanasi-Guyane française-Guayana Francesa-Guiana Francese",
            "priority": 0
        },
        {
            "iataCode": "REI",
            "countryCode": "GF",
            "cityCode": "REI",
            "cityName": "Regina",
            "name": "[REI] - Regina - REI - Regina - French Guiana",
            "searchName": "REI-Regina-Französisch-Guayana-French Guiana-Fransiz Guyanasi-Guyane française-Guayana Francesa-Guiana Francese",
            "priority": 0
        },
        {
            "iataCode": "OYP",
            "countryCode": "GF",
            "cityCode": "OYP",
            "cityName": "St Georges de lOyapock",
            "name": "[OYP] - St-Georges de lOyapock - OYP - St Georges de lOyapock - French Guiana",
            "searchName": "OYP-St-Georges de lOyapock-Saint Georges de Loy\\/ Oyapock-St Georges de lOyapock-Französisch-Guayana-French Guiana-Fransiz Guyanasi-Guyane française-Guayana Francesa-Guiana Francese",
            "priority": 0
        },
        {
            "iataCode": "LDX",
            "countryCode": "GF",
            "cityCode": "LDX",
            "cityName": "St Laurent du Maroni",
            "name": "[LDX] - St-Laurent du Maroni - LDX - St Laurent du Maroni - French Guiana",
            "searchName": "LDX-St-Laurent du Maroni-Saint Laurent du Maroni Havalimani-St Laurent du Maroni-Saint Laurent du Maroni-Französisch-Guayana-French Guiana-Fransiz Guyanasi-Guyane française-Guayana Francesa-Guiana Francese",
            "priority": 0
        },
        {
            "iataCode": "CAY",
            "countryCode": "GF",
            "cityCode": "CAY",
            "cityName": "Cayenne",
            "name": "[CAY] - Rochambeau - CAY - Cayenne - French Guiana",
            "searchName": "CAY-Cayenne-Rochambeau-Caienna-Französisch-Guayana-French Guiana-Fransiz Guyanasi-Guyane française-Guayana Francesa-Guiana Francese",
            "priority": 0
        },
        {
            "iataCode": "ACI",
            "countryCode": "GG",
            "cityCode": "ACI",
            "cityName": "Alderney",
            "name": "[ACI] - The Blaye - ACI - Alderney - Guernsey",
            "searchName": "ACI-Alderney-The Blaye-Guernsey-Großbritannien-Birlesik Krallik-Guernesey",
            "priority": 0
        },
        {
            "iataCode": "GCI",
            "countryCode": "GG",
            "cityCode": "GCI",
            "cityName": "Guernsey",
            "name": "[GCI] - Guernsey - GCI - Guernsey - Guernsey",
            "searchName": "GCI-Guernsey-Großbritannien-Birlesik Krallik-Guernesey",
            "priority": 0
        },
        {
            "iataCode": "KMS",
            "countryCode": "GH",
            "cityCode": "KMS",
            "cityName": "Kumasi",
            "name": "[KMS] - Kumasi - KMS - Kumasi - Ghana",
            "searchName": "KMS-Kumasi-Ghána-Ghana-Gana",
            "priority": 0
        },
        {
            "iataCode": "ACC",
            "countryCode": "GH",
            "cityCode": "ACC",
            "cityName": "Accra",
            "name": "[ACC] - Kotoka - ACC - Accra - Ghana",
            "searchName": "ACC-Accra-Kotoka-Ghána-Ghana-Gana",
            "priority": 0
        },
        {
            "iataCode": "NYI",
            "countryCode": "GH",
            "cityCode": "NYI",
            "cityName": "Sunyani",
            "name": "[NYI] - Sunyani - NYI - Sunyani - Ghana",
            "searchName": "NYI-Sunyani-Sunyani\u00A0 Havalimani-Ghána-Ghana-Gana",
            "priority": 0
        },
        {
            "iataCode": "TKD",
            "countryCode": "GH",
            "cityCode": "TKD",
            "cityName": "Takoradi",
            "name": "[TKD] - Takoradi - TKD - Takoradi - Ghana",
            "searchName": "TKD-Takoradi-Takoradi\u00A0 Havalimani-Sekondi-Ghána-Ghana-Gana",
            "priority": 0
        },
        {
            "iataCode": "TML",
            "countryCode": "GH",
            "cityCode": "TML",
            "cityName": "Tamale",
            "name": "[TML] - Tamale - TML - Tamale - Ghana",
            "searchName": "TML-Tamale-Tamale Havalimani-Ghána-Ghana-Gana",
            "priority": 0
        },
        {
            "iataCode": "GIB",
            "countryCode": "GI",
            "cityCode": "GIB",
            "cityName": "Gibraltar",
            "name": "[GIB] - North Front - GIB - Gibraltar - Gibraltar",
            "searchName": "GIB-Gibraltar North Front-North Front-North Front Apt-Gibraltar-Gibilterra-Cebelitarik",
            "priority": 0
        },
        {
            "iataCode": "GOH",
            "countryCode": "GL",
            "cityCode": "GOH",
            "cityName": "Nuuk",
            "name": "[GOH] - Nuuk - GOH - Nuuk - Greenland",
            "searchName": "GOH-Godthab Nuuk-Nuuk-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JUV",
            "countryCode": "GL",
            "cityCode": "JUV",
            "cityName": "Upernavik",
            "name": "[JUV] - Heliport - JUV - Upernavik - Greenland",
            "searchName": "JUV-Heliport-Upernavik-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JNS",
            "countryCode": "GL",
            "cityCode": "JNS",
            "cityName": "Narsaq",
            "name": "[JNS] - Heliport - JNS - Narsaq - Greenland",
            "searchName": "JNS-Heliport-Narsaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JNN",
            "countryCode": "GL",
            "cityCode": "JNN",
            "cityName": "Nanortalik",
            "name": "[JNN] - Nanortalik - JNN - Nanortalik - Greenland",
            "searchName": "JNN-Nanortalik-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JRK",
            "countryCode": "GL",
            "cityCode": "JRK",
            "cityName": "Arsuk",
            "name": "[JRK] - Arsuk - JRK - Arsuk - Greenland",
            "searchName": "JRK-Arsuk-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JQA",
            "countryCode": "GL",
            "cityCode": "JQA",
            "cityName": "Qaarsut",
            "name": "[JQA] - Qaarsut - JQA - Qaarsut - Greenland",
            "searchName": "JQA-Qaarsut-Qaarsut Havalimani-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JSU",
            "countryCode": "GL",
            "cityCode": "JSU",
            "cityName": "Maniitsoq",
            "name": "[JSU] - Heliport - JSU - Maniitsoq - Greenland",
            "searchName": "JSU-Sukkertoppen-Heliport-Maniitsoq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JHS",
            "countryCode": "GL",
            "cityCode": "JHS",
            "cityName": "Sisimiut",
            "name": "[JHS] - Sisimiut - JHS - Sisimiut - Greenland",
            "searchName": "JHS-Sisimiut-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JJU",
            "countryCode": "GL",
            "cityCode": "JJU",
            "cityName": "Qaqortoq",
            "name": "[JJU] - Heliport - JJU - Qaqortoq - Greenland",
            "searchName": "JJU-Hubschrauberlandeplatz-Heliport-Qaqortoq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JGO",
            "countryCode": "GL",
            "cityCode": "JGO",
            "cityName": "Qeqertarsuaq",
            "name": "[JGO] - Qeqertarsuaq - JGO - Qeqertarsuaq - Greenland",
            "searchName": "JGO-Qeqertarsuaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JGR",
            "countryCode": "GL",
            "cityCode": "JGR",
            "cityName": "Groennedal",
            "name": "[JGR] - Heliport - JGR - Groennedal - Greenland",
            "searchName": "JGR-Hubschrauberlandeplatz-Heliport-Gronnedal-Groennedal-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JFR",
            "countryCode": "GL",
            "cityCode": "JFR",
            "cityName": "Paamiut",
            "name": "[JFR] - Frederikshab - JFR - Paamiut - Greenland",
            "searchName": "JFR-Paamiut-Frederikshab-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JEG",
            "countryCode": "GL",
            "cityCode": "JEG",
            "cityName": "Aasiaat",
            "name": "[JEG] - Aasiaat - JEG - Aasiaat - Greenland",
            "searchName": "JEG-Aasiaat-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JCH",
            "countryCode": "GL",
            "cityCode": "JCH",
            "cityName": "Qasigiannguit",
            "name": "[JCH] - Qasigiannguit - JCH - Qasigiannguit - Greenland",
            "searchName": "JCH-Qasigiannguit-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "JAV",
            "countryCode": "GL",
            "cityCode": "JAV",
            "cityName": "Ilulissat",
            "name": "[JAV] - Ilulissat - JAV - Ilulissat - Greenland",
            "searchName": "JAV-Ilulissat-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "KUS",
            "countryCode": "GL",
            "cityCode": "KUS",
            "cityName": "Kulusuk",
            "name": "[KUS] - Kulusuk - KUS - Kulusuk - Greenland",
            "searchName": "KUS-Kulusuk-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "LLU",
            "countryCode": "GL",
            "cityCode": "LLU",
            "cityName": "Alluitsup Paa",
            "name": "[LLU] - Alluitsup Paa - LLU - Alluitsup Paa - Greenland",
            "searchName": "LLU-Alluitsup Paa-Alluitsup Paa Havalimani-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "AGM",
            "countryCode": "GL",
            "cityCode": "AGM",
            "cityName": "Tasiilaq",
            "name": "[AGM] - Tasiilaq - AGM - Tasiilaq - Greenland",
            "searchName": "AGM-Tasiilaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "CNP",
            "countryCode": "GL",
            "cityCode": "CNP",
            "cityName": "Neerlerit Inaat",
            "name": "[CNP] - Neerlerit Inaat - CNP - Neerlerit Inaat - Greenland",
            "searchName": "CNP-Neerlerit Inaat-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "THU",
            "countryCode": "GL",
            "cityCode": "THU",
            "cityName": "Pituffik",
            "name": "[THU] - Pituffik - THU - Pituffik - Greenland",
            "searchName": "THU-Pituffik-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "SFJ",
            "countryCode": "GL",
            "cityCode": "SFJ",
            "cityName": "Kangerlussuaq",
            "name": "[SFJ] - Kangerlussuaq - SFJ - Kangerlussuaq - Greenland",
            "searchName": "SFJ-Kangerlussuaq-Sondre Stromfjord Havalimani-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "XEO",
            "countryCode": "GL",
            "cityCode": "XEO",
            "cityName": "Oqatsut",
            "name": "[XEO] - Harbour - XEO - Oqatsut - Greenland",
            "searchName": "XEO-Harbour-Oqatsut-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "XEQ",
            "countryCode": "GL",
            "cityCode": "XEQ",
            "cityName": "Tasiuasaq",
            "name": "[XEQ] - Harbour - XEQ - Tasiuasaq - Greenland",
            "searchName": "XEQ-Harbour-Tasiuasaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "XIQ",
            "countryCode": "GL",
            "cityCode": "XIQ",
            "cityName": "Ilimanaq",
            "name": "[XIQ] - Harbour - XIQ - Ilimanaq - Greenland",
            "searchName": "XIQ-Harbour-Ilimanaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "UMD",
            "countryCode": "GL",
            "cityCode": "UMD",
            "cityName": "Uummannaq",
            "name": "[UMD] - Uummannaq - UMD - Uummannaq - Greenland",
            "searchName": "UMD-Uummannaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "UAK",
            "countryCode": "GL",
            "cityCode": "UAK",
            "cityName": "Narsarsuaq",
            "name": "[UAK] - Narsarsuaq - UAK - Narsarsuaq - Greenland",
            "searchName": "UAK-Narsarsuaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "OBY",
            "countryCode": "GL",
            "cityCode": "OBY",
            "cityName": "Ittoqqortoormiit",
            "name": "[OBY] - Ittoqqortoormiit - OBY - Ittoqqortoormiit - Greenland",
            "searchName": "OBY-Ittoqqortoormiit-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "NAQ",
            "countryCode": "GL",
            "cityCode": "NAQ",
            "cityName": "Qaanaaq",
            "name": "[NAQ] - Qaanaaq - NAQ - Qaanaaq - Greenland",
            "searchName": "NAQ-Qaanaaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "PQT",
            "countryCode": "GL",
            "cityCode": "PQT",
            "cityName": "Qeqertaq",
            "name": "[PQT] - Heliport - PQT - Qeqertaq - Greenland",
            "searchName": "PQT-Heliport-Qeqertaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "QCU",
            "countryCode": "GL",
            "cityCode": "QCU",
            "cityName": "Akunnaaq",
            "name": "[QCU] - Heliport - QCU - Akunnaaq - Greenland",
            "searchName": "QCU-Heliport-Akunnaaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "QFI",
            "countryCode": "GL",
            "cityCode": "QFI",
            "cityName": "Iginniarfik",
            "name": "[QFI] - Heliport - QFI - Iginniarfik - Greenland",
            "searchName": "QFI-Heliport-Iginniarfik-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "QGQ",
            "countryCode": "GL",
            "cityCode": "QGQ",
            "cityName": "Attu",
            "name": "[QGQ] - Heliport - QGQ - Attu - Greenland",
            "searchName": "QGQ-Heliport-Attu-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "QJE",
            "countryCode": "GL",
            "cityCode": "QJE",
            "cityName": "Kitsissuarsuit",
            "name": "[QJE] - Heliport - QJE - Kitsissuarsuit - Greenland",
            "searchName": "QJE-Heliport-Kitsissuarsuit-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "QJI",
            "countryCode": "GL",
            "cityCode": "QJI",
            "cityName": "Ikamiut",
            "name": "[QJI] - Heliport - QJI - Ikamiut - Greenland",
            "searchName": "QJI-Heliport-Ikamiut-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "QMK",
            "countryCode": "GL",
            "cityCode": "QMK",
            "cityName": "Niaqornaarsuk",
            "name": "[QMK] - Heliport - QMK - Niaqornaarsuk - Greenland",
            "searchName": "QMK-Heliport-Niaqornaarsuk-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "QPW",
            "countryCode": "GL",
            "cityCode": "QPW",
            "cityName": "Kangaatsiaq",
            "name": "[QPW] - Heliport - QPW - Kangaatsiaq - Greenland",
            "searchName": "QPW-Heliport-Kangaatsiaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "QRY",
            "countryCode": "GL",
            "cityCode": "QRY",
            "cityName": "Ikerasaarsuk",
            "name": "[QRY] - Heliport - QRY - Ikerasaarsuk - Greenland",
            "searchName": "QRY-Heliport-Ikerasaarsuk-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "QUP",
            "countryCode": "GL",
            "cityCode": "QUP",
            "cityName": "Saqqaq",
            "name": "[QUP] - Heliport - QUP - Saqqaq - Greenland",
            "searchName": "QUP-Heliport-Saqqaq-Greenland-Grönland-Groenlandia-Groenland",
            "priority": 0
        },
        {
            "iataCode": "BJL",
            "countryCode": "GM",
            "cityCode": "BJL",
            "cityName": "Banjul",
            "name": "[BJL] - Yundum International - BJL - Banjul - Gambia",
            "searchName": "BJL-Banjul-Yundum International-Gambia-Gambie",
            "priority": 0
        },
        {
            "iataCode": "BKJ",
            "countryCode": "GN",
            "cityCode": "BKJ",
            "cityName": "Boke",
            "name": "[BKJ] - Boke - BKJ - Boke - Guinea",
            "searchName": "BKJ-Boke-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "CKY",
            "countryCode": "GN",
            "cityCode": "CKY",
            "cityName": "Conakry",
            "name": "[CKY] - Conakry - CKY - Conakry - Guinea",
            "searchName": "CKY-Conakry-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "FAA",
            "countryCode": "GN",
            "cityCode": "FAA",
            "cityName": "Faranah",
            "name": "[FAA] - Faranah - FAA - Faranah - Guinea",
            "searchName": "FAA-Faranah-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "LEK",
            "countryCode": "GN",
            "cityCode": "LEK",
            "cityName": "Labe",
            "name": "[LEK] - Labe - LEK - Labe - Guinea",
            "searchName": "LEK-Labe-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "KNN",
            "countryCode": "GN",
            "cityCode": "KNN",
            "cityName": "Kankan",
            "name": "[KNN] - Kankan - KNN - Kankan - Guinea",
            "searchName": "KNN-Kankan-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "KSI",
            "countryCode": "GN",
            "cityCode": "KSI",
            "cityName": "Kissidougou",
            "name": "[KSI] - Kissidougou - KSI - Kissidougou - Guinea",
            "searchName": "KSI-Kissidougou-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "GII",
            "countryCode": "GN",
            "cityCode": "GII",
            "cityName": "Siguiri",
            "name": "[GII] - Siguiri - GII - Siguiri - Guinea",
            "searchName": "GII-Siguiri-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "FIG",
            "countryCode": "GN",
            "cityCode": "FIG",
            "cityName": "Fria",
            "name": "[FIG] - Fria - FIG - Fria - Guinea",
            "searchName": "FIG-Fria-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "NZE",
            "countryCode": "GN",
            "cityCode": "NZE",
            "cityName": "Nzerekore",
            "name": "[NZE] - Nzerekore - NZE - Nzerekore - Guinea",
            "searchName": "NZE-Nzerekore-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "MCA",
            "countryCode": "GN",
            "cityCode": "MCA",
            "cityName": "Macenta",
            "name": "[MCA] - Macenta - MCA - Macenta - Guinea",
            "searchName": "MCA-Macenta-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "SBI",
            "countryCode": "GN",
            "cityCode": "SBI",
            "cityName": "Koundara",
            "name": "[SBI] - Sambailo - SBI - Koundara - Guinea",
            "searchName": "SBI-Sambailo-Koundara-Guinea-Gine-Guinée",
            "priority": 0
        },
        {
            "iataCode": "SFC",
            "countryCode": "GP",
            "cityCode": "SFC",
            "cityName": "St Francois",
            "name": "[SFC] - St Francois - SFC - St Francois - Guadeloupe",
            "searchName": "SFC-St Francois-Guadeloupe-Guadalupe-Guadalupa",
            "priority": 0
        },
        {
            "iataCode": "PTP",
            "countryCode": "GP",
            "cityCode": "PTP",
            "cityName": "Pointe-a-pitre",
            "name": "[PTP] - Le Raizet - PTP - Pointe-a-pitre - Guadeloupe",
            "searchName": "PTP-Pointe-à-Pitre-Le Raizet--a-pitre-Guadeloupe-Guadalupe-Guadalupa",
            "priority": 0
        },
        {
            "iataCode": "GBJ",
            "countryCode": "GP",
            "cityCode": "GBJ",
            "cityName": "Marie Galante",
            "name": "[GBJ] - Les Bases - GBJ - Marie Galante - Guadeloupe",
            "searchName": "GBJ-Les Bases-Marie Galante-Guadeloupe-Guadalupe-Guadalupa",
            "priority": 0
        },
        {
            "iataCode": "HTB",
            "countryCode": "GP",
            "cityCode": "HTB",
            "cityName": "Terre-de-Bas",
            "name": "[HTB] - Terre-de-Bas - HTB - Terre-de-Bas - Guadeloupe",
            "searchName": "HTB-de-Bas - Flughafen-Terre-Bas-Guadeloupe-Guadalupe-Guadalupa",
            "priority": 0
        },
        {
            "iataCode": "LSS",
            "countryCode": "GP",
            "cityCode": "LSS",
            "cityName": "Terre-de-Haut",
            "name": "[LSS] - Terre-de-Haut - LSS - Terre-de-Haut - Guadeloupe",
            "searchName": "LSS-Terre-de-Haut-Guadeloupe-Guadalupe-Guadalupa",
            "priority": 0
        },
        {
            "iataCode": "DSD",
            "countryCode": "GP",
            "cityCode": "DSD",
            "cityName": "La Desirade",
            "name": "[DSD] - La Desirade - DSD - La Desirade - Guadeloupe",
            "searchName": "DSD-La Desirade-La Desirade Havalimani-Guadeloupe-Guadalupe-Guadalupa",
            "priority": 0
        },
        {
            "iataCode": "BBR",
            "countryCode": "GP",
            "cityCode": "BBR",
            "cityName": "Basse Terre",
            "name": "[BBR] - Baillif - BBR - Basse Terre - Guadeloupe",
            "searchName": "BBR-Baillif-Baillif Havalimani-Basse Terre-Guadeloupe-Guadalupe-Guadalupa",
            "priority": 0
        },
        {
            "iataCode": "BSG",
            "countryCode": "GQ",
            "cityCode": "BSG",
            "cityName": "Bata",
            "name": "[BSG] - Bata - BSG - Bata - Equatorial Guinea",
            "searchName": "BSG-Bata-Äquatorialguinea-Equatorial Guinea-Ekvator Ginesi-Guinée équatoriale-Guinea Ecuatorial-Guinea Equatoriale",
            "priority": 0
        },
        {
            "iataCode": "SSG",
            "countryCode": "GQ",
            "cityCode": "SSG",
            "cityName": "Malabo",
            "name": "[SSG] - Malbo - SSG - Malabo - Equatorial Guinea",
            "searchName": "SSG-Malabo-Malbo-Santa Isabel-Äquatorialguinea-Equatorial Guinea-Ekvator Ginesi-Guinée équatoriale-Guinea Ecuatorial-Guinea Equatoriale",
            "priority": 0
        },
        {
            "iataCode": "SPJ",
            "countryCode": "GR",
            "cityCode": "SPJ",
            "cityName": "Sparta",
            "name": "[SPJ] - Eleftherios Venizelos - SPJ - Sparta - Greece",
            "searchName": "SPJ-Eleftherios Venizelos-Sparta Havalimani-Sparta-Recko-Yunanistan-Kreikka-Görögország-Grecia-Grècia-Grcija-Graikija-Grcka-Kreeka-Grækenland-Hellas-Grecja-Grèce-Griechenland--Greece",
            "priority": 0
        },
        {
            "iataCode": "XOF",
            "countryCode": "GB",
            "cityCode": "LON",
            "cityName": "Londres",
            "name": "[XOF] - Stratford International Rail Station - LON - Londres - United Kingdom",
            "searchName": "XOF-LON-Stratford International Rail Station-London-Londres-Londra-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XQE",
            "countryCode": "GB",
            "cityCode": "LON",
            "cityName": "Londres",
            "name": "[XQE] - Ebbsfleet International Rail Station - LON - Londres - United Kingdom",
            "searchName": "XQE-LON-Ebbsfleet Station-Ebbsfleet International Rail Station-London-Londres-Londra-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QQP",
            "countryCode": "GB",
            "cityCode": "LON",
            "cityName": "Londres",
            "name": "[QQP] - Paddington Railway Station - LON - Londres - United Kingdom",
            "searchName": "QQP-LON-Paddington Railway Station-London-Londres-Londra-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QQK",
            "countryCode": "GB",
            "cityCode": "LON",
            "cityName": "Londres",
            "name": "[QQK] - Kings Cross Rail Station - LON - Londres - United Kingdom",
            "searchName": "QQK-LON-Kings Cross Rail Station-London-Londres-Londra-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QQS",
            "countryCode": "GB",
            "cityCode": "LON",
            "cityName": "Londres",
            "name": "[QQS] - St Pancras International RailSt - LON - Londres - United Kingdom",
            "searchName": "QQS-LON-St. Pancras Station-St Pancras International RailSt-London-Londres-Londra-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QQU",
            "countryCode": "GB",
            "cityCode": "LON",
            "cityName": "Londres",
            "name": "[QQU] - Euston Railway Station - LON - Londres - United Kingdom",
            "searchName": "QQU-LON-Euston Railway Station-London-Londres-Londra-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QQW",
            "countryCode": "GB",
            "cityCode": "LON",
            "cityName": "Londres",
            "name": "[QQW] - London-Waterloo - LON - Londres - United Kingdom",
            "searchName": "QQW-LON-London-Waterloo-Londres-Londra-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "STN",
            "countryCode": "GB",
            "cityCode": "LON",
            "cityName": "Londres",
            "name": "[STN] - Stansted - LON - Londres - United Kingdom",
            "searchName": "STN-LON-London Stansted-Stansted-London-Londres-Londra-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "SEN",
            "countryCode": "GB",
            "cityCode": "LON",
            "cityName": "Londres",
            "name": "[SEN] - Southend - LON - Londres - United Kingdom",
            "searchName": "SEN-LON-Southend On Sea-Southend-London Southend Airport-London-Londres-Londra-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "LEQ",
            "countryCode": "GB",
            "cityCode": "LEQ",
            "cityName": "Lands End",
            "name": "[LEQ] - Lands End - LEQ - Lands End - United Kingdom",
            "searchName": "LEQ-Lands End-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "NIT",
            "countryCode": "FR",
            "cityCode": "NIT",
            "cityName": "Niort",
            "name": "[NIT] - Niort - NIT - Niort - France",
            "searchName": "NIT-Niort-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "MVV",
            "countryCode": "FR",
            "cityCode": "MVV",
            "cityName": "Megeve",
            "name": "[MVV] - Megeve - MVV - Megeve - France",
            "searchName": "MVV-Megeve-Megeve Havalimani-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "MXN",
            "countryCode": "FR",
            "cityCode": "MXN",
            "cityName": "Morlaix",
            "name": "[MXN] - Morlaix - MXN - Morlaix - France",
            "searchName": "MXN-Morlaix-Morlaix Havalimani-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "OBS",
            "countryCode": "FR",
            "cityCode": "OBS",
            "cityName": "Aubenas",
            "name": "[OBS] - Vals-Lanas - OBS - Aubenas - France",
            "searchName": "OBS-Vals-Lanas-Vals Lanas Havalimani-Aubenas-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LTN",
            "countryCode": "GB",
            "cityCode": "LON",
            "cityName": "Londres",
            "name": "[LTN] - Luton Airport - LON - Londres - United Kingdom",
            "searchName": "LTN-LON-London Luton-Luton Airport-Luton-London-Londres-Londra-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BQH",
            "countryCode": "GB",
            "cityCode": "LON",
            "cityName": "Londres",
            "name": "[BQH] - Biggin Hill - LON - Londres - United Kingdom",
            "searchName": "BQH-LON-Biggin Hill-London-Londres-Londra-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ZLS",
            "countryCode": "GB",
            "cityCode": "LON",
            "cityName": "Londres",
            "name": "[ZLS] - Liverpool St Rail Station - LON - Londres - United Kingdom",
            "searchName": "ZLS-LON-Liverpool St Rail Station-London-Londres-Londra-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "HRT",
            "countryCode": "GB",
            "cityCode": "HRT",
            "cityName": "Harrogate",
            "name": "[HRT] - Linton-On-Ouse - HRT - Harrogate - United Kingdom",
            "searchName": "HRT-Linton on Ouse RAF-Linton-On-Ouse-Linton On Ouse Havalimani-Harrogate FL-Harrogate-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "HOY",
            "countryCode": "GB",
            "cityCode": "HOY",
            "cityName": "Hoy Island",
            "name": "[HOY] - Hoy Island - HOY - Hoy Island - United Kingdom",
            "searchName": "HOY-Hoy Island-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "HAW",
            "countryCode": "GB",
            "cityCode": "HAW",
            "cityName": "Haverfordwest",
            "name": "[HAW] - Haverfordwest - HAW - Haverfordwest - United Kingdom",
            "searchName": "HAW-Haverfordwest-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "HLY",
            "countryCode": "GB",
            "cityCode": "HLY",
            "cityName": "Holyhead",
            "name": "[HLY] - Anglesey Airport - HLY - Holyhead - United Kingdom",
            "searchName": "HLY-Anglesey Airport-Valley-Holyhead-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QQM",
            "countryCode": "GB",
            "cityCode": "MAN",
            "cityName": "Manchester",
            "name": "[QQM] - Piccadilly Railway Station - MAN - Manchester - United Kingdom",
            "searchName": "QQM-MAN-Piccadilly Railway Station-Manchester-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ZMP",
            "countryCode": "GB",
            "cityCode": "MAN",
            "cityName": "Manchester",
            "name": "[ZMP] - Manchester Bus Station - MAN - Manchester - United Kingdom",
            "searchName": "ZMP-MAN-Manchester Bus Station-Manchester-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "NHT",
            "countryCode": "GB",
            "cityCode": "NHT",
            "cityName": "Northolt",
            "name": "[NHT] - Northolt - NHT - Northolt - United Kingdom",
            "searchName": "NHT-Northolt-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "KYN",
            "countryCode": "GB",
            "cityCode": "KYN",
            "cityName": "Milton Keynes",
            "name": "[KYN] - Milton Keynes - KYN - Milton Keynes - United Kingdom",
            "searchName": "KYN-Milton Keynes-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ZFH",
            "countryCode": "GB",
            "cityCode": "KYN",
            "cityName": "Milton Keynes",
            "name": "[ZFH] - Milton Keynes Bus Station - KYN - Milton Keynes - United Kingdom",
            "searchName": "ZFH-KYN-Milton Keynes Bus Station-Milton Keynes-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "LBA",
            "countryCode": "GB",
            "cityCode": "LBA",
            "cityName": "Leeds",
            "name": "[LBA] - Leeds/bradford - LBA - Leeds - United Kingdom",
            "searchName": "LBA-Leeds Bradford-Leeds/bradford-Leeds/Bradford-Leeds Bradford Int. Apt-Leeds-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BRF",
            "countryCode": "GB",
            "cityCode": "LBA",
            "cityName": "Leeds",
            "name": "[BRF] - Bradford - LBA - Leeds - United Kingdom",
            "searchName": "BRF-LBA-Bradford-Leeds-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ZFC",
            "countryCode": "GB",
            "cityCode": "LBA",
            "cityName": "Leeds",
            "name": "[ZFC] - Bradford Bus Station - LBA - Leeds - United Kingdom",
            "searchName": "ZFC-LBA-Bradford Bus Station-Leeds-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ZLZ",
            "countryCode": "GB",
            "cityCode": "LBA",
            "cityName": "Leeds",
            "name": "[ZLZ] - Leeds Bus Station - LBA - Leeds - United Kingdom",
            "searchName": "ZLZ-LBA-Leeds Bus Station-Leeds-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ILY",
            "countryCode": "GB",
            "cityCode": "ILY",
            "cityName": "Islay",
            "name": "[ILY] - Glenegedale - ILY - Islay - United Kingdom",
            "searchName": "ILY-Islay-Glenegedale-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "INV",
            "countryCode": "GB",
            "cityCode": "INV",
            "cityName": "Inverness",
            "name": "[INV] - Inverness - INV - Inverness - United Kingdom",
            "searchName": "INV-Inverness-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ZIV",
            "countryCode": "GB",
            "cityCode": "INV",
            "cityName": "Inverness",
            "name": "[ZIV] - ScotRailway - INV - Inverness - United Kingdom",
            "searchName": "ZIV-INV-ScotRailway-Inverness-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "IPW",
            "countryCode": "GB",
            "cityCode": "IPW",
            "cityName": "Ipswich",
            "name": "[IPW] - Ipswich - IPW - Ipswich - United Kingdom",
            "searchName": "IPW-Ipswich-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ISC",
            "countryCode": "GB",
            "cityCode": "ISC",
            "cityName": "St Marys, Isles Of Scilly",
            "name": "[ISC] - St Marys - ISC - St Marys, Isles Of Scilly - United Kingdom",
            "searchName": "ISC-St Marys-Islas Sorlingas-St. Mary s-Îles de Scilly St. Mary s-Isole di Scilly St. Mary s-Isles Of Scilly- Isles Of Scilly-Isles of Scilly-St Marys, Isles Of Scilly-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "TSO",
            "countryCode": "GB",
            "cityCode": "ISC",
            "cityName": "St Marys, Isles Of Scilly",
            "name": "[TSO] - Tresco - ISC - St Marys, Isles Of Scilly - United Kingdom",
            "searchName": "TSO-ISC-Tresco-Isles Of Scilly-St Marys- Isles Of Scilly-Isles of Scilly-St Marys, Isles Of Scilly-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "HYC",
            "countryCode": "GB",
            "cityCode": "HYC",
            "cityName": "High Wycombe",
            "name": "[HYC] - High Wycombe - HYC - High Wycombe - United Kingdom",
            "searchName": "HYC-High Wycombe-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QQN",
            "countryCode": "GB",
            "cityCode": "BHX",
            "cityName": "Birmingham",
            "name": "[QQN] - New Street Rail Station - BHX - Birmingham - United Kingdom",
            "searchName": "QQN-BHX-New Street Rail Station-Birmingham-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ZBC",
            "countryCode": "GB",
            "cityCode": "BHX",
            "cityName": "Birmingham",
            "name": "[ZBC] - Colmore Row Bus Station - BHX - Birmingham - United Kingdom",
            "searchName": "ZBC-BHX-Colmore Row Bus Station-Birmingham-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BOL",
            "countryCode": "GB",
            "cityCode": "BOL",
            "cityName": "Ballykelly",
            "name": "[BOL] - Ballykelly - BOL - Ballykelly - United Kingdom",
            "searchName": "BOL-Ballykelly-Bally Kelly-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BOH",
            "countryCode": "GB",
            "cityCode": "BOH",
            "cityName": "Bournemouth",
            "name": "[BOH] - Bournemouth International - BOH - Bournemouth - United Kingdom",
            "searchName": "BOH-Bournemouth-Bournemouth International-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BSH",
            "countryCode": "GB",
            "cityCode": "BSH",
            "cityName": "Brighton",
            "name": "[BSH] - Brighton - BSH - Brighton - United Kingdom",
            "searchName": "BSH-Brighton-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BRR",
            "countryCode": "GB",
            "cityCode": "BRR",
            "cityName": "Barra",
            "name": "[BRR] - North Bay - BRR - Barra - United Kingdom",
            "searchName": "BRR-Barra GB-North Bay-Barra-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BRS",
            "countryCode": "GB",
            "cityCode": "BRS",
            "cityName": "Bristol",
            "name": "[BRS] - Bristol - BRS - Bristol - United Kingdom",
            "searchName": "BRS-Bristol-BristolBristol Int. Apt-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BWF",
            "countryCode": "GB",
            "cityCode": "BWF",
            "cityName": "Barrow-In-Furness",
            "name": "[BWF] - Walney Island - BWF - Barrow-In-Furness - United Kingdom",
            "searchName": "BWF-Walney Island-Walney Adasi Havalimani-Barrow-In-Furness-Barrow In Furness-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ZXE",
            "countryCode": "GB",
            "cityCode": "EDI",
            "cityName": "Edinburgh",
            "name": "[ZXE] - Waverley Railway Station - EDI - Edinburgh - United Kingdom",
            "searchName": "ZXE-EDI-Waverley Railway Station-Edinburgh-Edimburgo-Edinbourg-Edinburg-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ENK",
            "countryCode": "GB",
            "cityCode": "ENK",
            "cityName": "Enniskillen",
            "name": "[ENK] - Enniskillen St. Angelo - ENK - Enniskillen - United Kingdom",
            "searchName": "ENK-Enniskillen St. Angelo-St Angelo Havalimani-Enniskillen-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "EOI",
            "countryCode": "GB",
            "cityCode": "EOI",
            "cityName": "Eday",
            "name": "[EOI] - Eday - EOI - Eday - United Kingdom",
            "searchName": "EOI-Eday-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ESH",
            "countryCode": "GB",
            "cityCode": "ESH",
            "cityName": "Shoreham By Sea",
            "name": "[ESH] - Shoreham - ESH - Shoreham By Sea - United Kingdom",
            "searchName": "ESH-Shoreham-Shoreham By Sea-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "EXT",
            "countryCode": "GB",
            "cityCode": "EXT",
            "cityName": "Exeter",
            "name": "[EXT] - Exeter - EXT - Exeter - United Kingdom",
            "searchName": "EXT-Exeter-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "FAB",
            "countryCode": "GB",
            "cityCode": "FAB",
            "cityName": "Farnborough",
            "name": "[FAB] - Farnborough - FAB - Farnborough - United Kingdom",
            "searchName": "FAB-Farnborough-Farnborough Havalimani-Farnborough Hampshire-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "KNF",
            "countryCode": "GB",
            "cityCode": "KNF",
            "cityName": "Kings Lynn",
            "name": "[KNF] - Marham RAF - KNF - Kings Lynn - United Kingdom",
            "searchName": "KNF-Marham RAF-Kings Lynn-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "KOI",
            "countryCode": "GB",
            "cityCode": "KOI",
            "cityName": "Orkney Island",
            "name": "[KOI] - Orkney Island - KOI - Orkney Island - United Kingdom",
            "searchName": "KOI-Kirkwall-Orkney Island-Kirkwall Arpt-Kirkwall Airport-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "LYE",
            "countryCode": "GB",
            "cityCode": "LYE",
            "cityName": "Lyneham",
            "name": "[LYE] - RAF Station - LYE - Lyneham - United Kingdom",
            "searchName": "LYE-RAF Station-Lyneham-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "LYX",
            "countryCode": "GB",
            "cityCode": "LYX",
            "cityName": "Lydd",
            "name": "[LYX] - London Ashford International (Ferryfield) - LYX - Lydd - United Kingdom",
            "searchName": "LYX-London Ashford International (Ferryfield)-Lydd Uluslararasi Havalimani-Lydd-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "LMO",
            "countryCode": "GB",
            "cityCode": "LMO",
            "cityName": "Lossiemouth",
            "name": "[LMO] - RAF Station - LMO - Lossiemouth - United Kingdom",
            "searchName": "LMO-RAF Station-Lossiemouth-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "LKZ",
            "countryCode": "GB",
            "cityCode": "LKZ",
            "cityName": "Brandon",
            "name": "[LKZ] - RAF Lakenheath - LKZ - Brandon - United Kingdom",
            "searchName": "LKZ-Lakenheath RAF-RAF Lakenheath-Brandon-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "LPL",
            "countryCode": "GB",
            "cityCode": "LPL",
            "cityName": "Liverpool",
            "name": "[LPL] - Liverpool John Lennon - LPL - Liverpool - United Kingdom",
            "searchName": "LPL-Liverpool-Liverpool John Lennon-Liverpool J. Lennon-John Lennon-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ZXA",
            "countryCode": "GB",
            "cityCode": "ABZ",
            "cityName": "Aberdeen",
            "name": "[ZXA] - Aberdeen Railway Station - ABZ - Aberdeen - United Kingdom",
            "searchName": "ZXA-ABZ-Aberdeen Railway Station-Aberdeen-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ADX",
            "countryCode": "GB",
            "cityCode": "ADX",
            "cityName": "St Andrews",
            "name": "[ADX] - Leuchars - ADX - St Andrews - United Kingdom",
            "searchName": "ADX-Leuchars-Leuchars Havalimani-St Andrews-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "CBG",
            "countryCode": "GB",
            "cityCode": "CBG",
            "cityName": "Cambridge",
            "name": "[CBG] - Cambridge - CBG - Cambridge - United Kingdom",
            "searchName": "CBG-Cambridge-Cambridge City Apt-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BZZ",
            "countryCode": "GB",
            "cityCode": "BZZ",
            "cityName": "Brize Norton",
            "name": "[BZZ] - Raf Brize Norton - BZZ - Brize Norton - United Kingdom",
            "searchName": "BZZ-Brize Norton-Raf Brize Norton-Brize Norton Raf Station Havalimani-Oxford-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "CAL",
            "countryCode": "GB",
            "cityCode": "CAL",
            "cityName": "Campbeltown",
            "name": "[CAL] - Machrihanish - CAL - Campbeltown - United Kingdom",
            "searchName": "CAL-Campbeltown-Machrihanish-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "CAX",
            "countryCode": "GB",
            "cityCode": "CAX",
            "cityName": "Carlisle",
            "name": "[CAX] - Carlisle - CAX - Carlisle - United Kingdom",
            "searchName": "CAX-Carlisle-Carlisle Havalimani-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "CEG",
            "countryCode": "GB",
            "cityCode": "CEG",
            "cityName": "Chester",
            "name": "[CEG] - Chester - CEG - Chester - United Kingdom",
            "searchName": "CEG-Chester-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "CVT",
            "countryCode": "GB",
            "cityCode": "CVT",
            "cityName": "Coventry",
            "name": "[CVT] - West Midlands International - CVT - Coventry - United Kingdom",
            "searchName": "CVT-Baginton-West Midlands International-Baginton Airport-Coventry-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "CWL",
            "countryCode": "GB",
            "cityCode": "CWL",
            "cityName": "Cardiff",
            "name": "[CWL] - Cardiff - CWL - Cardiff - United Kingdom",
            "searchName": "CWL-Cardiff-Wales Arpt-Cardiff Int. Apt-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "COL",
            "countryCode": "GB",
            "cityCode": "COL",
            "cityName": "Isle Of Coll",
            "name": "[COL] - Isle Of Coll - COL - Isle Of Coll - United Kingdom",
            "searchName": "COL-Isle Of Coll-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "CSA",
            "countryCode": "GB",
            "cityCode": "CSA",
            "cityName": "Isle Of Colonsay",
            "name": "[CSA] - Isle Of Colonsay - CSA - Isle Of Colonsay - United Kingdom",
            "searchName": "CSA-Isle Of Colonsay-Colonsay Island-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "DND",
            "countryCode": "GB",
            "cityCode": "DND",
            "cityName": "Dundee",
            "name": "[DND] - Dundee - DND - Dundee - United Kingdom",
            "searchName": "DND-Dundee-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ZDU",
            "countryCode": "GB",
            "cityCode": "DND",
            "cityName": "Dundee",
            "name": "[ZDU] - ScotRailway - DND - Dundee - United Kingdom",
            "searchName": "ZDU-DND-ScotRailway-Dundee-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "DOC",
            "countryCode": "GB",
            "cityCode": "DOC",
            "cityName": "Dornoch",
            "name": "[DOC] - Dornoch - DOC - Dornoch - United Kingdom",
            "searchName": "DOC-Dornoch-Dornoch Havalimani-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "DSA",
            "countryCode": "GB",
            "cityCode": "DSA",
            "cityName": "Doncaster",
            "name": "[DSA] - Robin Hood - DSA - Doncaster - United Kingdom",
            "searchName": "DSA-Doncaster Sheffield-Robin Hood-Robin Hood Airport-Doncaster-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ZFG",
            "countryCode": "GB",
            "cityCode": "DSA",
            "cityName": "Doncaster",
            "name": "[ZFG] - Sheffield Bus Station - DSA - Doncaster - United Kingdom",
            "searchName": "ZFG-DSA-Sheffield Bus Station-Doncaster-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "PIK",
            "countryCode": "GB",
            "cityCode": "GLA",
            "cityName": "Glasgow",
            "name": "[PIK] - Prestwick - GLA - Glasgow - United Kingdom",
            "searchName": "PIK-GLA-Glasgow Prestwick-Prestwick-Glasgow-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "GLO",
            "countryCode": "GB",
            "cityCode": "GLO",
            "cityName": "Gloucester",
            "name": "[GLO] - Gloucestershire - GLO - Gloucester - United Kingdom",
            "searchName": "GLO-Gloucestershire-Gloucestershire Havalimani-Gloucester-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "FOA",
            "countryCode": "GB",
            "cityCode": "FOA",
            "cityName": "Foula",
            "name": "[FOA] - Foula - FOA - Foula - United Kingdom",
            "searchName": "FOA-Foula-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "FFD",
            "countryCode": "GB",
            "cityCode": "FFD",
            "cityName": "Fairford",
            "name": "[FFD] - RAF Station - FFD - Fairford - United Kingdom",
            "searchName": "FFD-RAF Station-Fairford-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "FIE",
            "countryCode": "GB",
            "cityCode": "FIE",
            "cityName": "Fair Isle",
            "name": "[FIE] - Fair Isle - FIE - Fair Isle - United Kingdom",
            "searchName": "FIE-Fair Isle-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "FEA",
            "countryCode": "GB",
            "cityCode": "FEA",
            "cityName": "Fetlar",
            "name": "[FEA] - Fetlar - FEA - Fetlar - United Kingdom",
            "searchName": "FEA-Fetlar-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "FLH",
            "countryCode": "GB",
            "cityCode": "FLH",
            "cityName": "Flotta",
            "name": "[FLH] - Flotta - FLH - Flotta - United Kingdom",
            "searchName": "FLH-Flotta-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "FWM",
            "countryCode": "GB",
            "cityCode": "FWM",
            "cityName": "Fort William",
            "name": "[FWM] - Heliport - FWM - Fort William - United Kingdom",
            "searchName": "FWM-Heliport-Fort William-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "FSS",
            "countryCode": "GB",
            "cityCode": "FSS",
            "cityName": "Forres",
            "name": "[FSS] - Kinloss - FSS - Forres - United Kingdom",
            "searchName": "FSS-Kinloss-Forres-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "WRY",
            "countryCode": "GB",
            "cityCode": "WRY",
            "cityName": "Westray",
            "name": "[WRY] - Westray - WRY - Westray - United Kingdom",
            "searchName": "WRY-Westray-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "WIC",
            "countryCode": "GB",
            "cityCode": "WIC",
            "cityName": "Wick",
            "name": "[WIC] - Wick - WIC - Wick - United Kingdom",
            "searchName": "WIC-Wick-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "WHS",
            "countryCode": "GB",
            "cityCode": "WHS",
            "cityName": "Whalsay",
            "name": "[WHS] - Whalsay - WHS - Whalsay - United Kingdom",
            "searchName": "WHS-Whalsay-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "WFD",
            "countryCode": "GB",
            "cityCode": "WFD",
            "cityName": "Woodford",
            "name": "[WFD] - Woodford - WFD - Woodford - United Kingdom",
            "searchName": "WFD-Woodford-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "VLY",
            "countryCode": "GB",
            "cityCode": "VLY",
            "cityName": "Anglesey",
            "name": "[VLY] - Valley - VLY - Anglesey - United Kingdom",
            "searchName": "VLY-Valley-Anglesey-Anglesey Havalimani-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ULL",
            "countryCode": "GB",
            "cityCode": "ULL",
            "cityName": "Mull",
            "name": "[ULL] - Mull - ULL - Mull - United Kingdom",
            "searchName": "ULL-Mull-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "UPV",
            "countryCode": "GB",
            "cityCode": "UPV",
            "cityName": "Upavon",
            "name": "[UPV] - Upavon - UPV - Upavon - United Kingdom",
            "searchName": "UPV-Upavon-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "UNT",
            "countryCode": "GB",
            "cityCode": "UNT",
            "cityName": "Unst Shetland Island",
            "name": "[UNT] - Baltasound - UNT - Unst Shetland Island - United Kingdom",
            "searchName": "UNT-Baltasound-Baltasound Havalimani-Unst-Unst Shetland Island-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "WTN",
            "countryCode": "GB",
            "cityCode": "WTN",
            "cityName": "Waddington",
            "name": "[WTN] - RAF Station - WTN - Waddington - United Kingdom",
            "searchName": "WTN-RAF Station-Waddington-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XGM",
            "countryCode": "GB",
            "cityCode": "XGM",
            "cityName": "Grantham",
            "name": "[XGM] - Grantham Railway Station - XGM - Grantham - United Kingdom",
            "searchName": "XGM-Grantham Railway Station-Grantham-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XHU",
            "countryCode": "GB",
            "cityCode": "XHU",
            "cityName": "Huntingdon",
            "name": "[XHU] - Huntingdon Railway Station - XHU - Huntingdon - United Kingdom",
            "searchName": "XHU-Huntingdon Railway Station-Huntingdon-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XQB",
            "countryCode": "GB",
            "cityCode": "XQB",
            "cityName": "Basingstoke",
            "name": "[XQB] - Basingstoke Railway Station - XQB - Basingstoke - United Kingdom",
            "searchName": "XQB-Basingstoke Railway Station-Basingstoke-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XQD",
            "countryCode": "GB",
            "cityCode": "XQD",
            "cityName": "Bedford",
            "name": "[XQD] - Bedford Railway Station - XQD - Bedford - United Kingdom",
            "searchName": "XQD-Bedford Railway Station-Bedford-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XQG",
            "countryCode": "GB",
            "cityCode": "XQG",
            "cityName": "Berwick-upon-Tweed",
            "name": "[XQG] - Berwick-upon-Tweed Railway Station - XQG - Berwick-upon-Tweed - United Kingdom",
            "searchName": "XQG-Berwick-upon-Tweed Railway Station-Tweed-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XQH",
            "countryCode": "GB",
            "cityCode": "XQH",
            "cityName": "Derby",
            "name": "[XQH] - Derby Railway Station - XQH - Derby - United Kingdom",
            "searchName": "XQH-Derby Railway Station-Derby-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XQI",
            "countryCode": "GB",
            "cityCode": "XQI",
            "cityName": "Loughborough",
            "name": "[XQI] - Loughborough Railway Station - XQI - Loughborough - United Kingdom",
            "searchName": "XQI-Loughborough Railway Station-Loughborough-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XQL",
            "countryCode": "GB",
            "cityCode": "XQL",
            "cityName": "Lancaster",
            "name": "[XQL] - Lancaster Railway Station - XQL - Lancaster - United Kingdom",
            "searchName": "XQL-Lancaster Railway Station-Lancaster-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XQM",
            "countryCode": "GB",
            "cityCode": "XQM",
            "cityName": "Market Harborough",
            "name": "[XQM] - Market Harborough Railway Station - XQM - Market Harborough - United Kingdom",
            "searchName": "XQM-Market Harborough Railway Station-Market Harborough-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XQT",
            "countryCode": "GB",
            "cityCode": "XQT",
            "cityName": "Lichfield",
            "name": "[XQT] - Trent Valley Rail Station - XQT - Lichfield - United Kingdom",
            "searchName": "XQT-Trent Valley Rail Station-Lichfield-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XQW",
            "countryCode": "GB",
            "cityCode": "XQW",
            "cityName": "Motherwell",
            "name": "[XQW] - Motherwell Railway Station - XQW - Motherwell - United Kingdom",
            "searchName": "XQW-Motherwell Railway Station-Motherwell-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XRC",
            "countryCode": "GB",
            "cityCode": "XRC",
            "cityName": "Runcorn",
            "name": "[XRC] - Runcorn Railway Station - XRC - Runcorn - United Kingdom",
            "searchName": "XRC-Runcorn Railway Station-Runcorn-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XRE",
            "countryCode": "GB",
            "cityCode": "XRE",
            "cityName": "Reading",
            "name": "[XRE] - Railway Service - XRE - Reading - United Kingdom",
            "searchName": "XRE-Railway Service-Reading-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XRG",
            "countryCode": "GB",
            "cityCode": "XRG",
            "cityName": "Rugeley",
            "name": "[XRG] - Trent Valley  Rail Station - XRG - Rugeley - United Kingdom",
            "searchName": "XRG-Trent Valley  Rail Station-Rugeley-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XPW",
            "countryCode": "GB",
            "cityCode": "XPW",
            "cityName": "Didcot",
            "name": "[XPW] - Parkway Railway Station - XPW - Didcot - United Kingdom",
            "searchName": "XPW-Parkway Railway Station-Didcot-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XPT",
            "countryCode": "GB",
            "cityCode": "XPT",
            "cityName": "Preston",
            "name": "[XPT] - Preston Railway Station - XPT - Preston - United Kingdom",
            "searchName": "XPT-Preston Railway Station-Preston-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XPF",
            "countryCode": "GB",
            "cityCode": "XPF",
            "cityName": "Penrith",
            "name": "[XPF] - Penrith Railway Station - XPF - Penrith - United Kingdom",
            "searchName": "XPF-Penrith Railway Station-Penrith-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XRU",
            "countryCode": "GB",
            "cityCode": "XRU",
            "cityName": "Rugby",
            "name": "[XRU] - Rugby Railway Station - XRU - Rugby - United Kingdom",
            "searchName": "XRU-Rugby Railway Station-Rugby-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XTK",
            "countryCode": "GB",
            "cityCode": "XTK",
            "cityName": "Thirsk",
            "name": "[XTK] - Thirsk Railway Station - XTK - Thirsk - United Kingdom",
            "searchName": "XTK-Thirsk Railway Station-Thirsk-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XSR",
            "countryCode": "GB",
            "cityCode": "XSR",
            "cityName": "Salisbury",
            "name": "[XSR] - Salisbury Railway Station - XSR - Salisbury - United Kingdom",
            "searchName": "XSR-Salisbury Railway Station-Salisbury-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XVA",
            "countryCode": "GB",
            "cityCode": "XVA",
            "cityName": "Stockport",
            "name": "[XVA] - Stockport Railway Station - XVA - Stockport - United Kingdom",
            "searchName": "XVA-Stockport Railway Station-Stockport-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XVB",
            "countryCode": "GB",
            "cityCode": "XVB",
            "cityName": "Stafford",
            "name": "[XVB] - Stafford Railway Station - XVB - Stafford - United Kingdom",
            "searchName": "XVB-Stafford Railway Station-Stafford-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XVC",
            "countryCode": "GB",
            "cityCode": "XVC",
            "cityName": "Crewe",
            "name": "[XVC] - Crewe Railway Station - XVC - Crewe - United Kingdom",
            "searchName": "XVC-Crewe Railway Station-Crewe-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XVG",
            "countryCode": "GB",
            "cityCode": "XVG",
            "cityName": "Darlington",
            "name": "[XVG] - Darlington Railway Station - XVG - Darlington - United Kingdom",
            "searchName": "XVG-Darlington Railway Station-Darlington-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XVH",
            "countryCode": "GB",
            "cityCode": "XVH",
            "cityName": "Peterborough",
            "name": "[XVH] - Peterborough Railway Station - XVH - Peterborough - United Kingdom",
            "searchName": "XVH-Peterborough Railway Station-Peterborough-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XVJ",
            "countryCode": "GB",
            "cityCode": "XVJ",
            "cityName": "Stevenage",
            "name": "[XVJ] - Stevenage Railway Station - XVJ - Stevenage - United Kingdom",
            "searchName": "XVJ-Stevenage Railway Station-Stevenage-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XNV",
            "countryCode": "GB",
            "cityCode": "XNV",
            "cityName": "Nuneaton",
            "name": "[XNV] - Nuneaton Railway Station - XNV - Nuneaton - United Kingdom",
            "searchName": "XNV-Nuneaton Railway Station-Nuneaton-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XNO",
            "countryCode": "GB",
            "cityCode": "XNO",
            "cityName": "Northallerton",
            "name": "[XNO] - Northallerton Railway Station - XNO - Northallerton - United Kingdom",
            "searchName": "XNO-Northallerton Railway Station-Northallerton-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XMZ",
            "countryCode": "GB",
            "cityCode": "XMZ",
            "cityName": "Macclesfield",
            "name": "[XMZ] - Macclesfield Railway Station - XMZ - Macclesfield - United Kingdom",
            "searchName": "XMZ-Macclesfield Railway Station-Macclesfield-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XNE",
            "countryCode": "GB",
            "cityCode": "XNE",
            "cityName": "Newport",
            "name": "[XNE] - Newport Railway Station - XNE - Newport - United Kingdom",
            "searchName": "XNE-Newport Railway Station-Newport-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "YEO",
            "countryCode": "GB",
            "cityCode": "YEO",
            "cityName": "Yeovilton",
            "name": "[YEO] - Yeovilton - YEO - Yeovilton - United Kingdom",
            "searchName": "YEO-Yeovilton-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XVW",
            "countryCode": "GB",
            "cityCode": "XVW",
            "cityName": "Wolverhampton",
            "name": "[XVW] - Wolverhampton Railway Station - XVW - Wolverhampton - United Kingdom",
            "searchName": "XVW-Wolverhampton Railway Station-Wolverhampton-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XWB",
            "countryCode": "GB",
            "cityCode": "XWB",
            "cityName": "Stirling",
            "name": "[XWB] - Stirling Railway Station - XWB - Stirling - United Kingdom",
            "searchName": "XWB-Stirling Railway Station-Stirling-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XWD",
            "countryCode": "GB",
            "cityCode": "XWD",
            "cityName": "Wakefield",
            "name": "[XWD] - Westgate Railway Station - XWD - Wakefield - United Kingdom",
            "searchName": "XWD-Westgate Railway Station-Wakefield-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XWE",
            "countryCode": "GB",
            "cityCode": "XWE",
            "cityName": "Wellingborough",
            "name": "[XWE] - Wellingborough Railway Station - XWE - Wellingborough - United Kingdom",
            "searchName": "XWE-Wellingborough Railway Station-Wellingborough-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XWH",
            "countryCode": "GB",
            "cityCode": "XWH",
            "cityName": "Stoke-on-Trent",
            "name": "[XWH] - Stoke-on-Trent Railway Station - XWH - Stoke-on-Trent - United Kingdom",
            "searchName": "XWH-Stoke-on-Trent Railway Station-Trent-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XWI",
            "countryCode": "GB",
            "cityCode": "XWI",
            "cityName": "Wigan",
            "name": "[XWI] - North Wstern Rail Station - XWI - Wigan - United Kingdom",
            "searchName": "XWI-North Wstern Rail Station-Wigan-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XWS",
            "countryCode": "GB",
            "cityCode": "XWS",
            "cityName": "Swindon",
            "name": "[XWS] - Swindon Railway Station - XWS - Swindon - United Kingdom",
            "searchName": "XWS-Swindon Railway Station-Swindon-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XWN",
            "countryCode": "GB",
            "cityCode": "XWN",
            "cityName": "Warrington",
            "name": "[XWN] - Bank Quay Rail Station - XWN - Warrington - United Kingdom",
            "searchName": "XWN-Bank Quay Rail Station-Warrington-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XWO",
            "countryCode": "GB",
            "cityCode": "XWO",
            "cityName": "Woking",
            "name": "[XWO] - Woking Railway Station - XWO - Woking - United Kingdom",
            "searchName": "XWO-Woking Railway Station-Woking-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "SYY",
            "countryCode": "GB",
            "cityCode": "SYY",
            "cityName": "Stornoway",
            "name": "[SYY] - Stornoway - SYY - Stornoway - United Kingdom",
            "searchName": "SYY-Stornoway-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "TTK",
            "countryCode": "GB",
            "cityCode": "TTK",
            "cityName": "Tottenham",
            "name": "[TTK] - Hale Railway Station - TTK - Tottenham - United Kingdom",
            "searchName": "TTK-Hale Railway Station-Tottenham-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "TRE",
            "countryCode": "GB",
            "cityCode": "TRE",
            "cityName": "Tiree",
            "name": "[TRE] - Tiree - TRE - Tiree - United Kingdom",
            "searchName": "TRE-Tiree Island-Tiree-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "SCS",
            "countryCode": "GB",
            "cityCode": "SDZ",
            "cityName": "Shetland Islands",
            "name": "[SCS] - Scatsta - SDZ - Shetland Islands - United Kingdom",
            "searchName": "SCS-SDZ-Scatsta-Scatsta Havalimani-Shetland Islands-Lerwick-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "LSI",
            "countryCode": "GB",
            "cityCode": "SDZ",
            "cityName": "Shetland Islands",
            "name": "[LSI] - Sumburgh - SDZ - Shetland Islands - United Kingdom",
            "searchName": "LSI-SDZ-Lerwick-Sumburgh-Shetland Islands-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "LWK",
            "countryCode": "GB",
            "cityCode": "SDZ",
            "cityName": "Shetland Islands",
            "name": "[LWK] - Lerwick/Tingwall - SDZ - Shetland Islands - United Kingdom",
            "searchName": "LWK-SDZ-Lerwick\\/ Tingwall-Lerwick/Tingwall-Lerwick\\/Tingwall-Shetland Islands-Lerwick-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "SKL",
            "countryCode": "GB",
            "cityCode": "SKL",
            "cityName": "Isle Of Skye",
            "name": "[SKL] - Broadford - SKL - Isle Of Skye - United Kingdom",
            "searchName": "SKL-Broadford-Broadford Havalimani-Isle of Skye-Isle Of Skye-Isle Of Skye Hebrides Islands-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "SOU",
            "countryCode": "GB",
            "cityCode": "SOU",
            "cityName": "Eastleigh near Southampton",
            "name": "[SOU] - Southampton Airport - SOU - Eastleigh near Southampton - United Kingdom",
            "searchName": "SOU-Southampton-Southampton Airport-Eastleigh-Southampton  Apt-Eastleigh near Southampton-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "SOY",
            "countryCode": "GB",
            "cityCode": "SOY",
            "cityName": "Stronsay",
            "name": "[SOY] - Stronsay - SOY - Stronsay - United Kingdom",
            "searchName": "SOY-Stronsay-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "SQZ",
            "countryCode": "GB",
            "cityCode": "SQZ",
            "cityName": "Scampton",
            "name": "[SQZ] - RAF Station - SQZ - Scampton - United Kingdom",
            "searchName": "SQZ-RAF Station-Scampton-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "SWS",
            "countryCode": "GB",
            "cityCode": "SWS",
            "cityName": "Swansea",
            "name": "[SWS] - Fairwood Comm - SWS - Swansea - United Kingdom",
            "searchName": "SWS-Fairwood Common-Fairwood Comm-Fairwood Comm Havalimani-Swansea-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "PPW",
            "countryCode": "GB",
            "cityCode": "PPW",
            "cityName": "Papa Westray",
            "name": "[PPW] - Papa Westray - PPW - Papa Westray - United Kingdom",
            "searchName": "PPW-Papa Westray-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "PME",
            "countryCode": "GB",
            "cityCode": "PME",
            "cityName": "Portsmouth",
            "name": "[PME] - Portsmouth - PME - Portsmouth - United Kingdom",
            "searchName": "PME-Portsmouth-Portsmouth Havalimani-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "OUK",
            "countryCode": "GB",
            "cityCode": "OUK",
            "cityName": "Outer Skerries",
            "name": "[OUK] - Outer Skerries - OUK - Outer Skerries - United Kingdom",
            "searchName": "OUK-Outer Skerries-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ORM",
            "countryCode": "GB",
            "cityCode": "ORM",
            "cityName": "Northampton",
            "name": "[ORM] - Northampton - ORM - Northampton - United Kingdom",
            "searchName": "ORM-Northampton-Northampton Tren Istasyonu Havalimani-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "OXF",
            "countryCode": "GB",
            "cityCode": "OXF",
            "cityName": "Oxford",
            "name": "[OXF] - Oxford (Kidlington) - OXF - Oxford - United Kingdom",
            "searchName": "OXF-Oxford-Oxford (Kidlington)-Kidlington-tüm havaalanlari-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QUY",
            "countryCode": "GB",
            "cityCode": "QUY",
            "cityName": "Wyton",
            "name": "[QUY] - Wyton RAF Station - QUY - Wyton - United Kingdom",
            "searchName": "QUY-Wyton RAF Station-Wyton-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QQD",
            "countryCode": "GB",
            "cityCode": "QQD",
            "cityName": "Dover",
            "name": "[QQD] - Priory Railway Station - QQD - Dover - United Kingdom",
            "searchName": "QQD-Priory Railway Station-Dover-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QQH",
            "countryCode": "GB",
            "cityCode": "QQH",
            "cityName": "Harwich",
            "name": "[QQH] - Harwich Town Rail Station - QQH - Harwich - United Kingdom",
            "searchName": "QQH-Harwich Town Rail Station-Harwich-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QQR",
            "countryCode": "GB",
            "cityCode": "QQR",
            "cityName": "Ramsgate",
            "name": "[QQR] - Ramsgate Railway Station - QQR - Ramsgate - United Kingdom",
            "searchName": "QQR-Ramsgate Railway Station-Ramsgate-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QQX",
            "countryCode": "GB",
            "cityCode": "QQX",
            "cityName": "Bath",
            "name": "[QQX] - Bath Spa Railway Station - QQX - Bath - United Kingdom",
            "searchName": "QQX-Bath Spa Railway Station-Bath-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QQY",
            "countryCode": "GB",
            "cityCode": "QQY",
            "cityName": "York",
            "name": "[QQY] - York Railway Station - QQY - York - United Kingdom",
            "searchName": "QQY-York Railway Station-York-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QUG",
            "countryCode": "GB",
            "cityCode": "QUG",
            "cityName": "Chichester",
            "name": "[QUG] - Goodwood - QUG - Chichester - United Kingdom",
            "searchName": "QUG-Goodwood-Chichester-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QEW",
            "countryCode": "GB",
            "cityCode": "QEW",
            "cityName": "Leicester",
            "name": "[QEW] - Leicester Railway Station - QEW - Leicester - United Kingdom",
            "searchName": "QEW-Leicester Railway Station-Leicester-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QCY",
            "countryCode": "GB",
            "cityCode": "QCY",
            "cityName": "Coningsby",
            "name": "[QCY] - Coningsby RAF Station - QCY - Coningsby - United Kingdom",
            "searchName": "QCY-Coningsby RAF Station-Coningsby-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "QDH",
            "countryCode": "GB",
            "cityCode": "QDH",
            "cityName": "Ashford",
            "name": "[QDH] - International Rail Station - QDH - Ashford - United Kingdom",
            "searchName": "QDH-International Rail Station-Ashford-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "RCS",
            "countryCode": "GB",
            "cityCode": "RCS",
            "cityName": "Rochester",
            "name": "[RCS] - Rochester - RCS - Rochester - United Kingdom",
            "searchName": "RCS-Rochester-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "PSL",
            "countryCode": "GB",
            "cityCode": "PSL",
            "cityName": "Perth",
            "name": "[PSL] - Perth - PSL - Perth - United Kingdom",
            "searchName": "PSL-Perth-Perth Havalimani-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "ZXP",
            "countryCode": "GB",
            "cityCode": "PSL",
            "cityName": "Perth",
            "name": "[ZXP] - ScotRailway - PSL - Perth - United Kingdom",
            "searchName": "ZXP-PSL-ScotRailway-Perth-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "PSV",
            "countryCode": "GB",
            "cityCode": "PSV",
            "cityName": "Papa Stour",
            "name": "[PSV] - Papa Stour - PSV - Papa Stour - United Kingdom",
            "searchName": "PSV-Papa Stour-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "MME",
            "countryCode": "GB",
            "cityCode": "MME",
            "cityName": "Teesside",
            "name": "[MME] - Tees Valley - MME - Teesside - United Kingdom",
            "searchName": "MME-Durham Tees Valley-Tees Valley-Teesside-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XVU",
            "countryCode": "GB",
            "cityCode": "MME",
            "cityName": "Teesside",
            "name": "[XVU] - Durham Railway Station - MME - Teesside - United Kingdom",
            "searchName": "XVU-MME-Durham Railway Station-Teesside-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "MHZ",
            "countryCode": "GB",
            "cityCode": "MHZ",
            "cityName": "Mildenhall",
            "name": "[MHZ] - Mildenhall Airport - MHZ - Mildenhall - United Kingdom",
            "searchName": "MHZ-Mildenhall Airport-Mildenhall Havalimani-Mildenhall-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "LBI",
            "countryCode": "FR",
            "cityCode": "LBI",
            "cityName": "Albi",
            "name": "[LBI] - Le Sequestre - LBI - Albi - France",
            "searchName": "LBI-Le Sequestre-Le Sequestre Havalimani-Albi-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LAI",
            "countryCode": "FR",
            "cityCode": "LAI",
            "cityName": "Lannion",
            "name": "[LAI] - Servel - LAI - Lannion - France",
            "searchName": "LAI-Lannion-Servel-Servel Apt-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "IDY",
            "countryCode": "FR",
            "cityCode": "IDY",
            "cityName": "Ile dYeu",
            "name": "[IDY] - Ile Yeu - IDY - Ile dYeu - France",
            "searchName": "IDY-Ile d Yeu-Ile Yeu-Ile dYeu-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "HZB",
            "countryCode": "FR",
            "cityCode": "HZB",
            "cityName": "Hazebrouck",
            "name": "[HZB] - Merville/Calonne - HZB - Hazebrouck - France",
            "searchName": "HZB-Merville/Calonne-Merville Calonne Havalimani-Hazebrouck-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "GFR",
            "countryCode": "FR",
            "cityCode": "GFR",
            "cityName": "Granville",
            "name": "[GFR] - Granville - GFR - Granville - France",
            "searchName": "GFR-Granville-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "FNI",
            "countryCode": "FR",
            "cityCode": "FNI",
            "cityName": "Nimes",
            "name": "[FNI] - Garons - FNI - Nimes - France",
            "searchName": "FNI-Nimes-Garons-N-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "ZYN",
            "countryCode": "FR",
            "cityCode": "FNI",
            "cityName": "Nimes",
            "name": "[ZYN] - Railway - FNI - Nimes - France",
            "searchName": "ZYN-FNI-Railway-N-Nimes-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "FSC",
            "countryCode": "FR",
            "cityCode": "FSC",
            "cityName": "Figari",
            "name": "[FSC] - Sud Corse - FSC - Figari - France",
            "searchName": "FSC-Figari-Sud Corse-Sud Corse Apt-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "GAT",
            "countryCode": "FR",
            "cityCode": "GAT",
            "cityName": "Gap",
            "name": "[GAT] - Tallard - GAT - Gap - France",
            "searchName": "GAT-Tallard-Tallard Havalimani-Gap-Gap France-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XED",
            "countryCode": "FR",
            "cityCode": "XED",
            "cityName": "Disneyland Paris",
            "name": "[XED] - Marne/Chessy Rail Station - XED - Disneyland Paris - France",
            "searchName": "XED-Marne/Chessy Rail Station-Disneyland Paris-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XCC",
            "countryCode": "FR",
            "cityCode": "XCC",
            "cityName": "Le Creusot/Montceau",
            "name": "[XCC] - Le Creusot/Montceau TGV Railway Station - XCC - Le Creusot/Montceau - France",
            "searchName": "XCC-Le Creusot/Montceau TGV Railway Station-Le Creusot/Montceau-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "UIP",
            "countryCode": "FR",
            "cityCode": "UIP",
            "cityName": "Quimper",
            "name": "[UIP] - Pluguffan - UIP - Quimper - France",
            "searchName": "UIP-Quimper-Pluguffan-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "VHY",
            "countryCode": "FR",
            "cityCode": "VHY",
            "cityName": "Vichy",
            "name": "[VHY] - Charmeil - VHY - Vichy - France",
            "searchName": "VHY-Charmeil-Charmeil Havalimani-Vichy-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "VAF",
            "countryCode": "FR",
            "cityCode": "VAF",
            "cityName": "Valence",
            "name": "[VAF] - Chabeuil - VAF - Valence - France",
            "searchName": "VAF-Chabeuil-Chabeuil Havalimani-Valence-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XHK",
            "countryCode": "FR",
            "cityCode": "VAF",
            "cityName": "Valence",
            "name": "[XHK] - Valence TGV Railway Station - VAF - Valence - France",
            "searchName": "XHK-VAF-Valence TGV Railway Station-Valence-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "URO",
            "countryCode": "FR",
            "cityCode": "URO",
            "cityName": "Rouen",
            "name": "[URO] - Boos - URO - Rouen - France",
            "searchName": "URO-Boos-Boos Apt-Rouen-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "VTL",
            "countryCode": "FR",
            "cityCode": "VTL",
            "cityName": "Vittel",
            "name": "[VTL] - Vittel - VTL - Vittel - France",
            "searchName": "VTL-Vittel-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "VNE",
            "countryCode": "FR",
            "cityCode": "VNE",
            "cityName": "Vannes",
            "name": "[VNE] - Meucon - VNE - Vannes - France",
            "searchName": "VNE-Meucon-Muecon Havalimani-Vannes-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "SXB",
            "countryCode": "FR",
            "cityCode": "SXB",
            "cityName": "Strasbourg",
            "name": "[SXB] - Entzheim - SXB - Strasbourg - France",
            "searchName": "SXB-Strassburg-Entzheim-Stra-Strasbourg-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XWG",
            "countryCode": "FR",
            "cityCode": "SXB",
            "cityName": "Strasbourg",
            "name": "[XWG] - Strasbourg Railway Station - SXB - Strasbourg - France",
            "searchName": "XWG-SXB-Strasbourg Railway Station-Stra-Strasbourg-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XER",
            "countryCode": "FR",
            "cityCode": "SXB",
            "cityName": "Strasbourg",
            "name": "[XER] - Strasbourg Bus Service - SXB - Strasbourg - France",
            "searchName": "XER-SXB-Busbahnhof-Strasbourg Bus Service-Bus Service-Stra-Strasbourg-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "SYT",
            "countryCode": "FR",
            "cityCode": "SYT",
            "cityName": "Saint Yan",
            "name": "[SYT] - Charolais Bourgogne S. - SYT - Saint Yan - France",
            "searchName": "SYT-Charolais Bourgogne Sud-Charolais Bourgogne S.-St. Yan-Saint Yan-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "TUF",
            "countryCode": "FR",
            "cityCode": "TUF",
            "cityName": "Tours",
            "name": "[TUF] - St Symphorien - TUF - Tours - France",
            "searchName": "TUF-Tours-St Symphorien-St. Symphorien  Apt-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XSH",
            "countryCode": "FR",
            "cityCode": "TUF",
            "cityName": "Tours",
            "name": "[XSH] - St-Pierre-Corps RailSt - TUF - Tours - France",
            "searchName": "XSH-TUF-St-Pierre-Corps RailSt-Tours-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XJT",
            "countryCode": "FR",
            "cityCode": "TUF",
            "cityName": "Tours",
            "name": "[XJT] - Tours Railway Station - TUF - Tours - France",
            "searchName": "XJT-TUF-Tours Railway Station-Tours-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "TNF",
            "countryCode": "FR",
            "cityCode": "TNF",
            "cityName": "Toussus-Le-Noble",
            "name": "[TNF] - Toussus-le-noble - TNF - Toussus-Le-Noble - France",
            "searchName": "TNF-Toussus-le-noble-Toussus Le Noble Havalimani-Le-Noble-Toussus Le Noble-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XZV",
            "countryCode": "FR",
            "cityCode": "TLN",
            "cityName": "Hyeres",
            "name": "[XZV] - Toulon Railway Station - TLN - Hyeres - France",
            "searchName": "XZV-TLN-Toulon Railway Station-Toulon-Hyeres-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "TLS",
            "countryCode": "FR",
            "cityCode": "TLS",
            "cityName": "Toulouse",
            "name": "[TLS] - Blagnac - TLS - Toulouse - France",
            "searchName": "TLS-Toulouse-Blagnac-Toulouse Blagnac Int. Apt-Tolosa-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "SOZ",
            "countryCode": "FR",
            "cityCode": "SOZ",
            "cityName": "Solenzara",
            "name": "[SOZ] - Solenzara - SOZ - Solenzara - France",
            "searchName": "SOZ-Solenzara-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "SNR",
            "countryCode": "FR",
            "cityCode": "SNR",
            "cityName": "St Nazaire",
            "name": "[SNR] - Montoir - SNR - St Nazaire - France",
            "searchName": "SNR-Saint Nazaire-Montoir-Montoir Havalimani-St Nazaire-Saint-Nazaire-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "SCP",
            "countryCode": "FR",
            "cityCode": "SCP",
            "cityName": "St Crepin",
            "name": "[SCP] - St Crepin - SCP - St Crepin - France",
            "searchName": "SCP-St Crepin-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "SBK",
            "countryCode": "FR",
            "cityCode": "SBK",
            "cityName": "Tremuson",
            "name": "[SBK] - Saint Brieuc-Armor - SBK - Tremuson - France",
            "searchName": "SBK-Saint Brieuc-Armor-Tremuson Armor Havalimani-Tremuson-St Brieuc-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "PIS",
            "countryCode": "FR",
            "cityCode": "PIS",
            "cityName": "Poitiers",
            "name": "[PIS] - Biard - PIS - Poitiers - France",
            "searchName": "PIS-Poitiers-Biard-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XOP",
            "countryCode": "FR",
            "cityCode": "PIS",
            "cityName": "Poitiers",
            "name": "[XOP] - Poitiers Railway Station - PIS - Poitiers - France",
            "searchName": "XOP-PIS-Poitiers Railway Station-Poitiers-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "PGF",
            "countryCode": "FR",
            "cityCode": "PGF",
            "cityName": "Perpignan",
            "name": "[PGF] - Llabanere - PGF - Perpignan - France",
            "searchName": "PGF-Perpignan-Llabanere-Rivesaltes Apt-Perpi-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "PGX",
            "countryCode": "FR",
            "cityCode": "PGX",
            "cityName": "Perigueux",
            "name": "[PGX] - Bassillac - PGX - Perigueux - France",
            "searchName": "PGX-Perigueux-Bassillac-Bassillac Apt.-P-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "ORE",
            "countryCode": "FR",
            "cityCode": "ORE",
            "cityName": "Orleans",
            "name": "[ORE] - Orleans - ORE - Orleans - France",
            "searchName": "ORE-Orleans-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XYD",
            "countryCode": "FR",
            "cityCode": "LYS",
            "cityName": "Lyon",
            "name": "[XYD] - Lyon Part-Dieu - LYS - Lyon - France",
            "searchName": "XYD-LYS-Lyon Part-Dieu-Dieu Rail-Lyon-Lione-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XYL",
            "countryCode": "FR",
            "cityCode": "LYS",
            "cityName": "Lyon",
            "name": "[XYL] - Lyon Perrache RailwaySvc - LYS - Lyon - France",
            "searchName": "XYL-LYS-Lyon Perrache RailwaySvc-Lyon-Lione-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LVA",
            "countryCode": "FR",
            "cityCode": "LVA",
            "cityName": "Laval",
            "name": "[LVA] - Entrammes - LVA - Laval - France",
            "searchName": "LVA-Entrammes-Laval-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LME",
            "countryCode": "FR",
            "cityCode": "LME",
            "cityName": "Le Mans",
            "name": "[LME] - Arnage - LME - Le Mans - France",
            "searchName": "LME-Arnage-Arnage Havalimani-Le Mans-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "ZLN",
            "countryCode": "FR",
            "cityCode": "LME",
            "cityName": "Le Mans",
            "name": "[ZLN] - Le Mans Railway Station - LME - Le Mans - France",
            "searchName": "ZLN-LME-Le Mans Railway Station-Le Mans-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LIG",
            "countryCode": "FR",
            "cityCode": "LIG",
            "cityName": "Limoges",
            "name": "[LIG] - Bellegarde - LIG - Limoges - France",
            "searchName": "LIG-Limoges-Bellegarde-Bellegarde Apt.-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LIL",
            "countryCode": "FR",
            "cityCode": "LIL",
            "cityName": "Lille",
            "name": "[LIL] - Lesquin - LIL - Lille - France",
            "searchName": "LIL-Lille-Lesquin-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XFA",
            "countryCode": "FR",
            "cityCode": "LIL",
            "cityName": "Lille",
            "name": "[XFA] - Lille Flanders RailwaySv - LIL - Lille - France",
            "searchName": "XFA-LIL-Lille Flanders RailwaySv-Lille-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XDB",
            "countryCode": "FR",
            "cityCode": "LIL",
            "cityName": "Lille",
            "name": "[XDB] - Europe Railway Station - LIL - Lille - France",
            "searchName": "XDB-LIL-Gare de Lille-Europe-Europe Railway Station-Lille-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LBY",
            "countryCode": "FR",
            "cityCode": "LBY",
            "cityName": "La Baule-Escoublac",
            "name": "[LBY] - Montoir - LBY - La Baule-Escoublac - France",
            "searchName": "LBY-Montoir-Montoir Havalimani-La Baule-Escoublac-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LDE",
            "countryCode": "FR",
            "cityCode": "LDE",
            "cityName": "Lourdes",
            "name": "[LDE] - Tarbes Ossun Lourdes - LDE - Lourdes - France",
            "searchName": "LDE-Lourdes Tarbes-Tarbes Ossun Lourdes-Lourdes-Tarbess Int. Apt-Lourdes/Tarbes-Lourdes\\/Tarbes-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LDV",
            "countryCode": "FR",
            "cityCode": "LDV",
            "cityName": "Landivisiau",
            "name": "[LDV] - Landivisiau - LDV - Landivisiau - France",
            "searchName": "LDV-Landivisiau-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LEN",
            "countryCode": "ES",
            "cityCode": "LEN",
            "cityName": "Leon",
            "name": "[LEN] - Leon - LEN - Leon - Spain",
            "searchName": "LEN-Leon-Leon Airport-Le-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "EEU",
            "countryCode": "ES",
            "cityCode": "LEN",
            "cityName": "Leon",
            "name": "[EEU] - Leon Railway Station - LEN - Leon - Spain",
            "searchName": "EEU-LEN-Leon Railway Station-Leon-Le-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "LYN",
            "countryCode": "FR",
            "cityCode": "LYS",
            "cityName": "Lyon",
            "name": "[LYN] - Bron - LYS - Lyon - France",
            "searchName": "LYN-LYS-Bron-Bron Havalimani-Lyon-Lione-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "HEM",
            "countryCode": "FI",
            "cityCode": "HEL",
            "cityName": "Helsinki",
            "name": "[HEM] - Helsinki-malmi - HEL - Helsinki - Finland",
            "searchName": "HEM-HEL-Helsinki-malmi-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "HEN",
            "countryCode": "FI",
            "cityCode": "HEL",
            "cityName": "Helsinki",
            "name": "[HEN] - Hendon - HEL - Helsinki - Finland",
            "searchName": "HEN-HEL-Hendon-Helsinki-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "IVL",
            "countryCode": "FI",
            "cityCode": "IVL",
            "cityName": "Ivalo",
            "name": "[IVL] - Ivalo - IVL - Ivalo - Finland",
            "searchName": "IVL-Ivalo-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "LPP",
            "countryCode": "FI",
            "cityCode": "LPP",
            "cityName": "Lappeenranta",
            "name": "[LPP] - Lappeenranta - LPP - Lappeenranta - Finland",
            "searchName": "LPP-Lappeenranta-Finlandia-Finland-Finlândia-Finnland-Finlande-Suomi-Soome-Finska-Som-Finlandiya",
            "priority": 0
        },
        {
            "iataCode": "YJV",
            "countryCode": "ES",
            "cityCode": "VLC",
            "cityName": "Valencia",
            "name": "[YJV] - Valencia Railway Station - VLC - Valencia - Spain",
            "searchName": "YJV-VLC-Valencia Railway Station-Valencia-Manises-Valence-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "XJO",
            "countryCode": "ES",
            "cityCode": "XJO",
            "cityName": "Soria",
            "name": "[XJO] - Soria Bus Station - XJO - Soria - Spain",
            "searchName": "XJO-Soria Bus Station-Soria-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "XJR",
            "countryCode": "ES",
            "cityCode": "XJR",
            "cityName": "Lorca",
            "name": "[XJR] - Lorca Bus Station - XJR - Lorca - Spain",
            "searchName": "XJR-Lorca Bus Station-Lorca-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "XOU",
            "countryCode": "ES",
            "cityCode": "XOU",
            "cityName": "Segovia",
            "name": "[XOU] - Guiomar Railway Station - XOU - Segovia - Spain",
            "searchName": "XOU-Guiomar Railway Station-Segovia-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "XUF",
            "countryCode": "ES",
            "cityCode": "XUF",
            "cityName": "Cartagena",
            "name": "[XUF] - Cartagena Railway Station - XUF - Cartagena - Spain",
            "searchName": "XUF-Cartagena Railway Station-Cartagena-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "XTJ",
            "countryCode": "ES",
            "cityCode": "XTJ",
            "cityName": "Toledo",
            "name": "[XTJ] - Toledo Railway Station - XTJ - Toledo - Spain",
            "searchName": "XTJ-Toledo Railway Station-Toledo-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "XRY",
            "countryCode": "ES",
            "cityCode": "XRY",
            "cityName": "Jerez De La Frontera",
            "name": "[XRY] - Jerez De La Frontera - XRY - Jerez De La Frontera - Spain",
            "searchName": "XRY-Jerez de la Frontera-Jerez De La Frontera-La Parra-La Parra Apt-X-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "YJW",
            "countryCode": "ES",
            "cityCode": "XRY",
            "cityName": "Jerez De La Frontera",
            "name": "[YJW] - Jerez Railway Station - XRY - Jerez De La Frontera - Spain",
            "searchName": "YJW-XRY-Jerez Railway Station-Jerez de la Frontera-Jerez De La Frontera-X-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "MLN",
            "countryCode": "ES",
            "cityCode": "MLN",
            "cityName": "Melilla",
            "name": "[MLN] - Melilla - MLN - Melilla - Spain",
            "searchName": "MLN-Melilla-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "MAH",
            "countryCode": "ES",
            "cityCode": "MAH",
            "cityName": "Menorca",
            "name": "[MAH] - Mahon - MAH - Menorca - Spain",
            "searchName": "MAH-Menorca-Mahon-Menorca/Mahon-Mah-Spania-Espagne-Spanien-Espanja-Španelsko-Ispanya-Espanya-Spanyolország-Spain-Španija-Ispanija-Spanija-Španjolska-Hiszpania-Spanyol-España-Spagna",
            "priority": 0
        },
        {
            "iataCode": "ZFQ",
            "countryCode": "FR",
            "cityCode": "BOD",
            "cityName": "Bordeaux",
            "name": "[ZFQ] - Gare de Bordeaux - BOD - Bordeaux - France",
            "searchName": "ZFQ-BOD-Gare de Bordeaux-Bordeaux-Burdeos-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "BOU",
            "countryCode": "FR",
            "cityCode": "BOU",
            "cityName": "Bourges",
            "name": "[BOU] - Bourges - BOU - Bourges - France",
            "searchName": "BOU-Bourges-Bourges Havalimani-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "BES",
            "countryCode": "FR",
            "cityCode": "BES",
            "cityName": "Brest",
            "name": "[BES] - Brest Lesquin - BES - Brest - France",
            "searchName": "BES-Brest-Brest Lesquin-Guipavas-Guipavas Apt-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "BIQ",
            "countryCode": "FR",
            "cityCode": "BIQ",
            "cityName": "Biarritz",
            "name": "[BIQ] - Biarritz Parme - BIQ - Biarritz - France",
            "searchName": "BIQ-Biarritz-Biarritz Parme-Anglet-Bayonee Apt.-Biarriz-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "BIA",
            "countryCode": "FR",
            "cityCode": "BIA",
            "cityName": "Bastia",
            "name": "[BIA] - Poretta - BIA - Bastia - France",
            "searchName": "BIA-Bastia-Poretta-Poretta Apt-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "BAE",
            "countryCode": "FR",
            "cityCode": "BAE",
            "cityName": "Barcelonnette",
            "name": "[BAE] - Barcelonnette - BAE - Barcelonnette - France",
            "searchName": "BAE-Barcelonnette-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "AVN",
            "countryCode": "FR",
            "cityCode": "AVN",
            "cityName": "Avignon",
            "name": "[AVN] - Avignon-Caumont - AVN - Avignon - France",
            "searchName": "AVN-Avignon-Caumont-Caum-Caumont Apt-Avi-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XZN",
            "countryCode": "FR",
            "cityCode": "AVN",
            "cityName": "Avignon",
            "name": "[XZN] - Avignon Railway - AVN - Avignon - France",
            "searchName": "XZN-AVN-Avignon Centre Station-Avignon Railway-Avignon-Avi-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "AUR",
            "countryCode": "FR",
            "cityCode": "AUR",
            "cityName": "Aurillac",
            "name": "[AUR] - Aurillac - AUR - Aurillac - France",
            "searchName": "AUR-Aurillac-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "AUF",
            "countryCode": "FR",
            "cityCode": "AUF",
            "cityName": "Auxerre",
            "name": "[AUF] - Auxerre Branches - AUF - Auxerre - France",
            "searchName": "AUF-Auxerre Branches-Auxerre-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "ANG",
            "countryCode": "FR",
            "cityCode": "ANG",
            "cityName": "Angouleme",
            "name": "[ANG] - Brie-Champniers - ANG - Angouleme - France",
            "searchName": "ANG-Brie-Champniers-Angouleme-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "ANE",
            "countryCode": "FR",
            "cityCode": "ANE",
            "cityName": "Angers",
            "name": "[ANE] - Marce - ANE - Angers - France",
            "searchName": "ANE-Angers-Marce-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "QXG",
            "countryCode": "FR",
            "cityCode": "ANE",
            "cityName": "Angers",
            "name": "[QXG] - Angers Railway Station - ANE - Angers - France",
            "searchName": "QXG-ANE-Angers Railway Station-Angers-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "AGF",
            "countryCode": "FR",
            "cityCode": "AGF",
            "cityName": "Agen",
            "name": "[AGF] - La Garenne - AGF - Agen - France",
            "searchName": "AGF-Agen-La Garenne-La Garenne Apt-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "AHZ",
            "countryCode": "FR",
            "cityCode": "AHZ",
            "cityName": "Alpe D Huez",
            "name": "[AHZ] - Alpe D Huez - AHZ - Alpe D Huez - France",
            "searchName": "AHZ-Alpe D Huez-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "AJA",
            "countryCode": "FR",
            "cityCode": "AJA",
            "cityName": "Ajaccio",
            "name": "[AJA] - Campo Dell Oro - AJA - Ajaccio - France",
            "searchName": "AJA-Ajaccio-Campo Dell Oro-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LTT",
            "countryCode": "FR",
            "cityCode": "LTT",
            "cityName": "Saint Tropez",
            "name": "[LTT] - La Mole - LTT - Saint Tropez - France",
            "searchName": "LTT-Saint Tropez-La Mole-Saint-Tropez-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XPZ",
            "countryCode": "FR",
            "cityCode": "LTT",
            "cityName": "Saint Tropez",
            "name": "[XPZ] - Harbour - LTT - Saint Tropez - France",
            "searchName": "XPZ-LTT-Harbour-Saint Tropez-Saint-Tropez-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LTQ",
            "countryCode": "FR",
            "cityCode": "LTQ",
            "cityName": "Le Touquet",
            "name": "[LTQ] - Le Touquet - LTQ - Le Touquet - France",
            "searchName": "LTQ-Le Touquet-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LRT",
            "countryCode": "FR",
            "cityCode": "LRT",
            "cityName": "Lorient",
            "name": "[LRT] - Lann Bihoue - LRT - Lorient - France",
            "searchName": "LRT-Lorient-Lann Bihoue-Lann Bihoue Apt.-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LSO",
            "countryCode": "FR",
            "cityCode": "LSO",
            "cityName": "Les Sables",
            "name": "[LSO] - Talmont - LSO - Les Sables - France",
            "searchName": "LSO-Talmont-Talmont Havalimani-Les Sables-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LRH",
            "countryCode": "FR",
            "cityCode": "LRH",
            "cityName": "La Rochelle",
            "name": "[LRH] - Laleu - LRH - La Rochelle - France",
            "searchName": "LRH-La Rochelle-Laleu-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LPY",
            "countryCode": "FR",
            "cityCode": "LPY",
            "cityName": "Le Puy",
            "name": "[LPY] - Loudes - LPY - Le Puy - France",
            "searchName": "LPY-Le Puy-Loudes-Loudes Apt.-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "QJZ",
            "countryCode": "FR",
            "cityCode": "NTE",
            "cityName": "Nantes",
            "name": "[QJZ] - Nantes Railway Station - NTE - Nantes - France",
            "searchName": "QJZ-NTE-Nantes Railway Station-Gare de Nantes TGV-Nantes-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "NVS",
            "countryCode": "FR",
            "cityCode": "NVS",
            "cityName": "Nevers",
            "name": "[NVS] - Nevers - NVS - Nevers - France",
            "searchName": "NVS-Nevers-Nevers Havalimani-Neuwied-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "ZBH",
            "countryCode": "FR",
            "cityCode": "ZBH",
            "cityName": "Severac-le-Chateau",
            "name": "[ZBH] - Severac-le-Chateau Railway Station - ZBH - Severac-le-Chateau - France",
            "searchName": "ZBH-Severac-le-Chateau Railway Station-Chateau-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "ZAO",
            "countryCode": "FR",
            "cityCode": "ZAO",
            "cityName": "Cahors",
            "name": "[ZAO] - Laberandie - ZAO - Cahors - France",
            "searchName": "ZAO-Laberandie-Laberandie Havalimani-Cahors-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "ZAF",
            "countryCode": "FR",
            "cityCode": "ZAF",
            "cityName": "Arles",
            "name": "[ZAF] - Arles Railway Station - ZAF - Arles - France",
            "searchName": "ZAF-Arles Railway Station-Arles-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "ZKM",
            "countryCode": "GA",
            "cityCode": "ZKM",
            "cityName": "Sette Cama",
            "name": "[ZKM] - Sette Cama - ZKM - Sette Cama - Gabon",
            "searchName": "ZKM-Sette Cama-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "OKN",
            "countryCode": "GA",
            "cityCode": "OKN",
            "cityName": "Okondja",
            "name": "[OKN] - Okondja - OKN - Okondja - Gabon",
            "searchName": "OKN-Okondja-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MYB",
            "countryCode": "GA",
            "cityCode": "MYB",
            "cityName": "Mayoumba",
            "name": "[MYB] - Mayoumba - MYB - Mayoumba - Gabon",
            "searchName": "MYB-Mayoumba-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MVX",
            "countryCode": "GA",
            "cityCode": "MVX",
            "cityName": "Minvoul",
            "name": "[MVX] - Minvoul - MVX - Minvoul - Gabon",
            "searchName": "MVX-Minvoul-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MZC",
            "countryCode": "GA",
            "cityCode": "MZC",
            "cityName": "Mitzic",
            "name": "[MZC] - Mitzic - MZC - Mitzic - Gabon",
            "searchName": "MZC-Mitzic-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "NKA",
            "countryCode": "GA",
            "cityCode": "NKA",
            "cityName": "Nkan",
            "name": "[NKA] - Nkan - NKA - Nkan - Gabon",
            "searchName": "NKA-Nkan-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MDV",
            "countryCode": "GA",
            "cityCode": "MDV",
            "cityName": "Medouneu",
            "name": "[MDV] - Medouneu - MDV - Medouneu - Gabon",
            "searchName": "MDV-Medouneu-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MBC",
            "countryCode": "GA",
            "cityCode": "MBC",
            "cityName": "Mbigou",
            "name": "[MBC] - Mbigou - MBC - Mbigou - Gabon",
            "searchName": "MBC-Mbigou-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MFF",
            "countryCode": "GA",
            "cityCode": "MFF",
            "cityName": "Moanda",
            "name": "[MFF] - Moanda - MFF - Moanda - Gabon",
            "searchName": "MFF-Moanda-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MGX",
            "countryCode": "GA",
            "cityCode": "MGX",
            "cityName": "Moabi",
            "name": "[MGX] - Moabi - MGX - Moabi - Gabon",
            "searchName": "MGX-Moabi-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MGO",
            "countryCode": "GA",
            "cityCode": "MGO",
            "cityName": "Manega",
            "name": "[MGO] - Manega - MGO - Manega - Gabon",
            "searchName": "MGO-Manega-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MJL",
            "countryCode": "GA",
            "cityCode": "MJL",
            "cityName": "Mouila",
            "name": "[MJL] - Mouila - MJL - Mouila - Gabon",
            "searchName": "MJL-Mouila-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MKB",
            "countryCode": "GA",
            "cityCode": "MKB",
            "cityName": "Mekambo",
            "name": "[MKB] - Mekambo - MKB - Mekambo - Gabon",
            "searchName": "MKB-Mekambo-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MVB",
            "countryCode": "GA",
            "cityCode": "MVB",
            "cityName": "Franceville",
            "name": "[MVB] - Franceville/Mvengue - MVB - Franceville - Gabon",
            "searchName": "MVB-Franceville\\/Mvengue-Franceville/Mvengue-Franceville-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MVG",
            "countryCode": "GA",
            "cityCode": "MVG",
            "cityName": "Mevang",
            "name": "[MVG] - Mevang - MVG - Mevang - Gabon",
            "searchName": "MVG-Mevang-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "MKU",
            "countryCode": "GA",
            "cityCode": "MKU",
            "cityName": "Makokou",
            "name": "[MKU] - Makokou - MKU - Makokou - Gabon",
            "searchName": "MKU-Makokou-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "OUU",
            "countryCode": "GA",
            "cityCode": "OUU",
            "cityName": "Ouanga",
            "name": "[OUU] - Ouanga - OUU - Ouanga - Gabon",
            "searchName": "OUU-Ouanga-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "OWE",
            "countryCode": "GA",
            "cityCode": "OWE",
            "cityName": "Owendo",
            "name": "[OWE] - Owendo - OWE - Owendo - Gabon",
            "searchName": "OWE-Owendo-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "OYE",
            "countryCode": "GA",
            "cityCode": "OYE",
            "cityName": "Oyem",
            "name": "[OYE] - Oyem - OYE - Oyem - Gabon",
            "searchName": "OYE-Oyem-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "OMB",
            "countryCode": "GA",
            "cityCode": "OMB",
            "cityName": "Omboue",
            "name": "[OMB] - Omboue - OMB - Omboue - Gabon",
            "searchName": "OMB-Omboue-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "POG",
            "countryCode": "GA",
            "cityCode": "POG",
            "cityName": "Port Gentil",
            "name": "[POG] - Port Gentil - POG - Port Gentil - Gabon",
            "searchName": "POG-Port Gentil-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "TCH",
            "countryCode": "GA",
            "cityCode": "TCH",
            "cityName": "Tchibanga",
            "name": "[TCH] - Tchibanga - TCH - Tchibanga - Gabon",
            "searchName": "TCH-Tchibanga-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "WNE",
            "countryCode": "GA",
            "cityCode": "WNE",
            "cityName": "Wora Na Ye",
            "name": "[WNE] - Wora Na Ye - WNE - Wora Na Ye - Gabon",
            "searchName": "WNE-Wora Na Ye-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "GAX",
            "countryCode": "GA",
            "cityCode": "GAX",
            "cityName": "Gamba",
            "name": "[GAX] - Gamba - GAX - Gamba - Gabon",
            "searchName": "GAX-Gamba-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "FOU",
            "countryCode": "GA",
            "cityCode": "FOU",
            "cityName": "Fougamou",
            "name": "[FOU] - Fougamou - FOU - Fougamou - Gabon",
            "searchName": "FOU-Fougamou-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "GKO",
            "countryCode": "GA",
            "cityCode": "GKO",
            "cityName": "Kongoboumba",
            "name": "[GKO] - Kongoboumba - GKO - Kongoboumba - Gabon",
            "searchName": "GKO-Kongoboumba-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "GIM",
            "countryCode": "GA",
            "cityCode": "GIM",
            "cityName": "Miele Mimbale",
            "name": "[GIM] - Miele Mimbale - GIM - Miele Mimbale - Gabon",
            "searchName": "GIM-Miele Mimbale-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "IGE",
            "countryCode": "GA",
            "cityCode": "IGE",
            "cityName": "Iguela",
            "name": "[IGE] - Iguela - IGE - Iguela - Gabon",
            "searchName": "IGE-Iguela-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "LEO",
            "countryCode": "GA",
            "cityCode": "LEO",
            "cityName": "Leconi",
            "name": "[LEO] - Leconi - LEO - Leconi - Gabon",
            "searchName": "LEO-Leconi-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "LBQ",
            "countryCode": "GA",
            "cityCode": "LBQ",
            "cityName": "Lambarene",
            "name": "[LBQ] - Lambarene - LBQ - Lambarene - Gabon",
            "searchName": "LBQ-Lambarene-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "LBV",
            "countryCode": "GA",
            "cityCode": "LBV",
            "cityName": "Libreville",
            "name": "[LBV] - Libreville - LBV - Libreville - Gabon",
            "searchName": "LBV-Libreville-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "LTL",
            "countryCode": "GA",
            "cityCode": "LTL",
            "cityName": "Lastourville",
            "name": "[LTL] - Lastourville - LTL - Lastourville - Gabon",
            "searchName": "LTL-Lastourville-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "KOU",
            "countryCode": "GA",
            "cityCode": "KOU",
            "cityName": "Koulamoutou",
            "name": "[KOU] - Koulamoutou - KOU - Koulamoutou - Gabon",
            "searchName": "KOU-Koulamoutou-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "KDJ",
            "countryCode": "GA",
            "cityCode": "KDJ",
            "cityName": "Ndjole",
            "name": "[KDJ] - Ndjole-Ville - KDJ - Ndjole - Gabon",
            "searchName": "KDJ-Ndjole-Ville-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "KDN",
            "countryCode": "GA",
            "cityCode": "KDN",
            "cityName": "Ndende",
            "name": "[KDN] - Ndende - KDN - Ndende - Gabon",
            "searchName": "KDN-Ndende-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "AKE",
            "countryCode": "GA",
            "cityCode": "AKE",
            "cityName": "Akieni",
            "name": "[AKE] - Akieni - AKE - Akieni - Gabon",
            "searchName": "AKE-Akieni-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "AWE",
            "countryCode": "GA",
            "cityCode": "AWE",
            "cityName": "Alowe",
            "name": "[AWE] - Alowe - AWE - Alowe - Gabon",
            "searchName": "AWE-Alowe-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "BAW",
            "countryCode": "GA",
            "cityCode": "BAW",
            "cityName": "Biawonque",
            "name": "[BAW] - Biawonque - BAW - Biawonque - Gabon",
            "searchName": "BAW-Biawonque-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "BMM",
            "countryCode": "GA",
            "cityCode": "BMM",
            "cityName": "Bitam",
            "name": "[BMM] - Bitam - BMM - Bitam - Gabon",
            "searchName": "BMM-Bitam-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "BGP",
            "countryCode": "GA",
            "cityCode": "BGP",
            "cityName": "Bongo",
            "name": "[BGP] - Bongo - BGP - Bongo - Gabon",
            "searchName": "BGP-Bongo-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "BGB",
            "countryCode": "GA",
            "cityCode": "BGB",
            "cityName": "Booue",
            "name": "[BGB] - Booue - BGB - Booue - Gabon",
            "searchName": "BGB-Booue-Gabun-Gabon-Gabón",
            "priority": 0
        },
        {
            "iataCode": "ZFJ",
            "countryCode": "FR",
            "cityCode": "RNS",
            "cityName": "Rennes",
            "name": "[ZFJ] - Rennes - RNS - Rennes - France",
            "searchName": "ZFJ-RNS-Rennes-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "RYN",
            "countryCode": "FR",
            "cityCode": "RYN",
            "cityName": "Royan",
            "name": "[RYN] - Medis - RYN - Royan - France",
            "searchName": "RYN-Medis-Royan-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "RHE",
            "countryCode": "FR",
            "cityCode": "RHE",
            "cityName": "Reims",
            "name": "[RHE] - Reims - RHE - Reims - France",
            "searchName": "RHE-Reims-Champagne Havalimani-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XIZ",
            "countryCode": "FR",
            "cityCode": "RHE",
            "cityName": "Reims",
            "name": "[XIZ] - Champagne-Ardenne TGV - RHE - Reims - France",
            "searchName": "XIZ-RHE-Champagne-Ardenne TGV-Gare de Reims TGV-Reims-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "RDZ",
            "countryCode": "FR",
            "cityCode": "RDZ",
            "cityName": "Rodez",
            "name": "[RDZ] - Marcillac - RDZ - Rodez - France",
            "searchName": "RDZ-Rodez-Marcillac-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "RNE",
            "countryCode": "FR",
            "cityCode": "RNE",
            "cityName": "Roanne",
            "name": "[RNE] - Renaison - RNE - Roanne - France",
            "searchName": "RNE-Renaison-Roanne-Roane-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "MPL",
            "countryCode": "FR",
            "cityCode": "MPL",
            "cityName": "Montpellier",
            "name": "[MPL] - Montpellier MÃ©diterranÃ©e - MPL - Montpellier - France",
            "searchName": "MPL-Montpellier-Montpellier Méditerranée-Meditarranee-Mediterranee Apt-Montpellier MÃ©diterranÃ©e-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XPJ",
            "countryCode": "FR",
            "cityCode": "MPL",
            "cityName": "Montpellier",
            "name": "[XPJ] - Montpellier Railway - MPL - Montpellier - France",
            "searchName": "XPJ-MPL-Montpellier Railway-Montpellier-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "POX",
            "countryCode": "FR",
            "cityCode": "PAR",
            "cityName": "Paris",
            "name": "[POX] - Paris Cergy Pontoise - PAR - Paris - France",
            "searchName": "POX-PAR-Paris Cergy Pontoise-Cormeille En Vexin Havalimani-Paris-Par-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XJY",
            "countryCode": "FR",
            "cityCode": "PAR",
            "cityName": "Paris",
            "name": "[XJY] - Massy TGV Rail Station - PAR - Paris - France",
            "searchName": "XJY-PAR-Massy TGV Rail Station-Massy Railway Station-Paris-Par-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XHP",
            "countryCode": "FR",
            "cityCode": "PAR",
            "cityName": "Paris",
            "name": "[XHP] - Gare de lEst Rail Station - PAR - Paris - France",
            "searchName": "XHP-PAR-Gare de l Est Rail Station-Gare de lEst Rail Station-Paris-Par-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XGB",
            "countryCode": "FR",
            "cityCode": "PAR",
            "cityName": "Paris",
            "name": "[XGB] - Gare Montparnasse - PAR - Paris - France",
            "searchName": "XGB-PAR-Gare Montparnasse-Paris-Par-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XCR",
            "countryCode": "FR",
            "cityCode": "PAR",
            "cityName": "Paris",
            "name": "[XCR] - Vatry International - PAR - Paris - France",
            "searchName": "XCR-PAR-Paris Vatry-Vatry International-Chalons-Vatry-Aéroport de Vatry-Vatry International Airport-Paris-Par-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XEX",
            "countryCode": "FR",
            "cityCode": "PAR",
            "cityName": "Paris",
            "name": "[XEX] - Aerogare - PAR - Paris - France",
            "searchName": "XEX-PAR-Aerogare-Paris-Par-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "VIY",
            "countryCode": "FR",
            "cityCode": "PAR",
            "cityName": "Paris",
            "name": "[VIY] - Villa Coublay - PAR - Paris - France",
            "searchName": "VIY-PAR-Villa Coublay-Paris-Par-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XTT",
            "countryCode": "FR",
            "cityCode": "PAR",
            "cityName": "Paris",
            "name": "[XTT] - Etoile - PAR - Paris - France",
            "searchName": "XTT-PAR-Etoile-Paris-Par-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "XPG",
            "countryCode": "FR",
            "cityCode": "PAR",
            "cityName": "Paris",
            "name": "[XPG] - Gare du Nord Railway - PAR - Paris - France",
            "searchName": "XPG-PAR-Gare du Nord-Gare du Nord Railway-Paris-Par-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "LBG",
            "countryCode": "FR",
            "cityCode": "PAR",
            "cityName": "Paris",
            "name": "[LBG] - Le Bourget - PAR - Paris - France",
            "searchName": "LBG-PAR-La Bourget-Le Bourget-Le Bourget Havalimani-Paris-Par-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "JDP",
            "countryCode": "FR",
            "cityCode": "PAR",
            "cityName": "Paris",
            "name": "[JDP] - Heliport De Paris - PAR - Paris - France",
            "searchName": "JDP-PAR-Heliport De Paris-Paris-Par-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "JPU",
            "countryCode": "FR",
            "cityCode": "PAR",
            "cityName": "Paris",
            "name": "[JPU] - La Defense Heliport - PAR - Paris - France",
            "searchName": "JPU-PAR-La Defense Heliport-Paris-Par-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "BVA",
            "countryCode": "FR",
            "cityCode": "PAR",
            "cityName": "Paris",
            "name": "[BVA] - Beauvais-Tille - PAR - Paris - France",
            "searchName": "BVA-PAR-Paris Beauvais Tille-Beauvais-Tille-Paris-Par-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "BHD",
            "countryCode": "GB",
            "cityCode": "BFS",
            "cityName": "Belfast",
            "name": "[BHD] - George Best Belfast City - BFS - Belfast - United Kingdom",
            "searchName": "BHD-BFS-Belfast City-George Best Belfast City-Belfast City Airport-George Best City Apt-Belfast-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BEQ",
            "countryCode": "GB",
            "cityCode": "BEQ",
            "cityName": "Bury St Edmunds",
            "name": "[BEQ] - Honington - BEQ - Bury St Edmunds - United Kingdom",
            "searchName": "BEQ-Honington-Bury Saint Edmunds-Bury St Edmunds-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BEX",
            "countryCode": "GB",
            "cityCode": "BEX",
            "cityName": "Benson",
            "name": "[BEX] - Benson (RAF Station) - BEX - Benson - United Kingdom",
            "searchName": "BEX-Luftwaffenstützpunkt-Benson (RAF Station)-Benson-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BEB",
            "countryCode": "GB",
            "cityCode": "BEB",
            "cityName": "Benbecula",
            "name": "[BEB] - Benbecula - BEB - Benbecula - United Kingdom",
            "searchName": "BEB-Benbecula-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BBP",
            "countryCode": "GB",
            "cityCode": "BBP",
            "cityName": "Bembridge",
            "name": "[BBP] - Bembridge - BBP - Bembridge - United Kingdom",
            "searchName": "BBP-Bembridge-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BBS",
            "countryCode": "GB",
            "cityCode": "BBS",
            "cityName": "Yateley",
            "name": "[BBS] - Blackbushe - BBS - Yateley - United Kingdom",
            "searchName": "BBS-Blackbushe-Blackbush-Yateley-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "BLK",
            "countryCode": "GB",
            "cityCode": "BLK",
            "cityName": "Blackpool",
            "name": "[BLK] - Blackpool Airport - BLK - Blackpool - United Kingdom",
            "searchName": "BLK-Blackpool-Blackpool Airport-Blackpool Int. Apt.-Regne Unit-Ingiltere-Velká Británie-Iso-Britannia-Storbritannien-Storbritannia-Wielka Brytania-Egyesült Királyság-Regatul Unit-Velika Britanija-United Kingdom-Großbritannien-Birlesik Krallik-Reino Unido-Royaume-Uni-Regno Unito",
            "priority": 0
        },
        {
            "iataCode": "XRF",
            "countryCode": "FR",
            "cityCode": "MRS",
            "cityName": "Marseille",
            "name": "[XRF] - Marseille Railway - MRS - Marseille - France",
            "searchName": "XRF-MRS-Marseille Railway-Marseille-Marsella-Marsiglia-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "MFX",
            "countryCode": "FR",
            "cityCode": "MFX",
            "cityName": "Meribel",
            "name": "[MFX] - Meribel - MFX - Meribel - France",
            "searchName": "MFX-Meribel-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "MCU",
            "countryCode": "FR",
            "cityCode": "MCU",
            "cityName": "Montlucon",
            "name": "[MCU] - Gueret (Lepaud) - MCU - Montlucon - France",
            "searchName": "MCU-Gueret-Gueret (Lepaud)-Gueret Havalimani-Montlucon-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "MEN",
            "countryCode": "FR",
            "cityCode": "MEN",
            "cityName": "Mende",
            "name": "[MEN] - Brenoux - MEN - Mende - France",
            "searchName": "MEN-Brenoux-Mende-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "NCY",
            "countryCode": "FR",
            "cityCode": "NCY",
            "cityName": "Annecy",
            "name": "[NCY] - Annecy-Meythe - NCY - Annecy - France",
            "searchName": "NCY-Annecy-Meythe-Meythet Apt-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "PUF",
            "countryCode": "FR",
            "cityCode": "PUF",
            "cityName": "Pau",
            "name": "[PUF] - Pau-PyrÃ©nÃ©es - PUF - Pau - France",
            "searchName": "PUF-Pau Frankreich-Pau-Pyrénées-Uzein-PyrÃ©nÃ©es-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "PRP",
            "countryCode": "FR",
            "cityCode": "PRP",
            "cityName": "Propriano",
            "name": "[PRP] - Propriano - PRP - Propriano - France",
            "searchName": "PRP-Propriano-Propriano Havalimani-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "RCO",
            "countryCode": "FR",
            "cityCode": "RCO",
            "cityName": "Rochefort",
            "name": "[RCO] - Saint Agnant - RCO - Rochefort - France",
            "searchName": "RCO-Saint Agnant-St Agnant Havalimani-Rochefort-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "QZF",
            "countryCode": "FR",
            "cityCode": "QZF",
            "cityName": "Font-Romeu",
            "name": "[QZF] - Font-Romeu Bus Station - QZF - Font-Romeu - France",
            "searchName": "QZF-Font-Romeu Bus Station-Romeu-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "QXK",
            "countryCode": "FR",
            "cityCode": "QXK",
            "cityName": "St-Genis",
            "name": "[QXK] - St-Genis Bus Station - QXK - St-Genis - France",
            "searchName": "QXK-St-Genis Bus Station-Genis-France-Prancis-Frankrike-Pháp-Perancis-Frankrijk-França-Francia-Frankreich-Francja-Frankrig-Francie-Prancuzija-Pran",
            "priority": 0
        },
        {
            "iataCode": "IXA",
            "countryCode": "IN",
            "cityCode": "IXA",
            "cityName": "Agartala",
            "name": "[IXA] - Singerbhil - IXA - Agartala - India",
            "searchName": "IXA-Agartala-Singerbhil-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "RHA",
            "countryCode": "IS",
            "cityCode": "RHA",
            "cityName": "Reykholar",
            "name": "[RHA] - Reykholar - RHA - Reykholar - Iceland",
            "searchName": "RHA-Reykholar-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "RFN",
            "countryCode": "IS",
            "cityCode": "RFN",
            "cityName": "Raufarhofn",
            "name": "[RFN] - Raufarhofn - RFN - Raufarhofn - Iceland",
            "searchName": "RFN-Raufarhofn-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "BZO",
            "countryCode": "IT",
            "cityCode": "BZO",
            "cityName": "Bolzano",
            "name": "[BZO] - Bolzano - BZO - Bolzano - Italy",
            "searchName": "BZO-Bozen-Bolzano-Bolzano Bozen Apt.-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "BZQ",
            "countryCode": "IT",
            "cityCode": "BZO",
            "cityName": "Bolzano",
            "name": "[BZQ] - Bolzano/Bozen Railway Station - BZO - Bolzano - Italy",
            "searchName": "BZQ-BZO-Bolzano/Bozen Railway Station-Bozen-Bolzano-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZBN",
            "countryCode": "IT",
            "cityCode": "BZO",
            "cityName": "Bolzano",
            "name": "[ZBN] - Bolzano/Bozen Bus Station - BZO - Bolzano - Italy",
            "searchName": "ZBN-BZO-Bolzano/Bozen Bus Station-Bozen-Bolzano-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "CRV",
            "countryCode": "IT",
            "cityCode": "CRV",
            "cityName": "Crotone",
            "name": "[CRV] - Crotone - CRV - Crotone - Italy",
            "searchName": "CRV-Crotone-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "CUF",
            "countryCode": "IT",
            "cityCode": "CUF",
            "cityName": "Cuneo",
            "name": "[CUF] - Levaldigi - CUF - Cuneo - Italy",
            "searchName": "CUF-Cuneo Levaldigi-Levaldigi-Levaldigi Havalimani-Cuneo-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "CTJ",
            "countryCode": "IT",
            "cityCode": "CTJ",
            "cityName": "Carrollton",
            "name": "[CTJ] - Caserta Railway Station - CTJ - Carrollton - Italy",
            "searchName": "CTJ-Caserta Railway Station-Carrollton-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "GRY",
            "countryCode": "IS",
            "cityCode": "GRY",
            "cityName": "Grimsey",
            "name": "[GRY] - Grimsey - GRY - Grimsey - Iceland",
            "searchName": "GRY-Grimsey-Grímsey-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "GUU",
            "countryCode": "IS",
            "cityCode": "GUU",
            "cityName": "Grundarfjordur",
            "name": "[GUU] - Grundarfjordur - GUU - Grundarfjordur - Iceland",
            "searchName": "GUU-Grundarfjordur-Grundarfjordur airport-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "GJR",
            "countryCode": "IS",
            "cityCode": "GJR",
            "cityName": "Gjogur",
            "name": "[GJR] - Gjogur - GJR - Gjogur - Iceland",
            "searchName": "GJR-Gjogur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "FLI",
            "countryCode": "IS",
            "cityCode": "FLI",
            "cityName": "Flateyri",
            "name": "[FLI] - Flateyri - FLI - Flateyri - Iceland",
            "searchName": "FLI-Flateyri-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "HFN",
            "countryCode": "IS",
            "cityCode": "HFN",
            "cityName": "Hornafjordur",
            "name": "[HFN] - Hornafjordur - HFN - Hornafjordur - Iceland",
            "searchName": "HFN-Hornafjordur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "HZK",
            "countryCode": "IS",
            "cityCode": "HZK",
            "cityName": "Husavik",
            "name": "[HZK] - Husavik - HZK - Husavik - Iceland",
            "searchName": "HZK-Husavik-Husavik Havalimani-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "HVK",
            "countryCode": "IS",
            "cityCode": "HVK",
            "cityName": "Holmavik",
            "name": "[HVK] - Holmavik - HVK - Holmavik - Iceland",
            "searchName": "HVK-Holmavik-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "IFJ",
            "countryCode": "IS",
            "cityCode": "IFJ",
            "cityName": "Isafjordur",
            "name": "[IFJ] - Isafjordur - IFJ - Isafjordur - Iceland",
            "searchName": "IFJ-Isafjordur-IIsafjordur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "ISH",
            "countryCode": "IT",
            "cityCode": "ISH",
            "cityName": "Ischia",
            "name": "[ISH] - Ischia - ISH - Ischia - Italy",
            "searchName": "ISH-Ischia-Ischia Havalimani-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "BDS",
            "countryCode": "IT",
            "cityCode": "BDS",
            "cityName": "Brindisi",
            "name": "[BDS] - Papola Casale - BDS - Brindisi - Italy",
            "searchName": "BDS-Brindisi-Papola Casale-Papola Casale Apt-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "BLX",
            "countryCode": "IT",
            "cityCode": "BLX",
            "cityName": "Belluno",
            "name": "[BLX] - Belluno - BLX - Belluno - Italy",
            "searchName": "BLX-Belluno-Belluno Havalimani-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "IBT",
            "countryCode": "IT",
            "cityCode": "BLQ",
            "cityName": "Bologna",
            "name": "[IBT] - Bologna Central Railway Station - BLQ - Bologna - Italy",
            "searchName": "IBT-BLQ-Bologna Central Railway Station-Bologna-Bolonia-Bologne-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "BRZ",
            "countryCode": "IT",
            "cityCode": "BRZ",
            "cityName": "Borotou",
            "name": "[BRZ] - Borotou - BRZ - Borotou - Italy",
            "searchName": "BRZ-Borotou-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "IRR",
            "countryCode": "IT",
            "cityCode": "ROM",
            "cityName": "Roma",
            "name": "[IRR] - Ostiense Railway Station - ROM - Roma - Italy",
            "searchName": "IRR-ROM-Ostiense Railway Station-Rom-Rome-Roma-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "IRT",
            "countryCode": "IT",
            "cityCode": "ROM",
            "cityName": "Roma",
            "name": "[IRT] - Tiburtina Railway Station - ROM - Roma - Italy",
            "searchName": "IRT-ROM-Tiburtina Railway Station-Rom-Rome-Roma-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "BAU",
            "countryCode": "IT",
            "cityCode": "BRI",
            "cityName": "Bari",
            "name": "[BAU] - Bauru - BRI - Bari - Italy",
            "searchName": "BAU-BRI-Bauru-Bari-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ALL",
            "countryCode": "IT",
            "cityCode": "ALL",
            "cityName": "Albenga",
            "name": "[ALL] - Albenga - ALL - Albenga - Italy",
            "searchName": "ALL-Albenga-Albenga Havalimani-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "AHO",
            "countryCode": "IT",
            "cityCode": "AHO",
            "cityName": "Alghero",
            "name": "[AHO] - Fertilia - AHO - Alghero - Italy",
            "searchName": "AHO-Alghero-Fertilia-Fertilia Apt.-Alguer-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "AVB",
            "countryCode": "IT",
            "cityCode": "AVB",
            "cityName": "Aviano",
            "name": "[AVB] - Aviano - AVB - Aviano - Italy",
            "searchName": "AVB-Aviano-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "AOT",
            "countryCode": "IT",
            "cityCode": "AOT",
            "cityName": "Aosta",
            "name": "[AOT] - Corrado Gex - AOT - Aosta - Italy",
            "searchName": "AOT-Corrado Gex-Aosta-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "AOI",
            "countryCode": "IT",
            "cityCode": "AOI",
            "cityName": "Ancona",
            "name": "[AOI] - Falconara - AOI - Ancona - Italy",
            "searchName": "AOI-Ancona-Falconara-Falconara Apt-Anc-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "REG",
            "countryCode": "IT",
            "cityCode": "REG",
            "cityName": "Reggio Calabria",
            "name": "[REG] - Tito Menniti - REG - Reggio Calabria - Italy",
            "searchName": "REG-Reggio Calabria-Tito Menniti-Reggio de Calabre-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "RCC",
            "countryCode": "IT",
            "cityCode": "REG",
            "cityName": "Reggio Calabria",
            "name": "[RCC] - Reggio di Calabria Central Railway Station - REG - Reggio Calabria - Italy",
            "searchName": "RCC-REG-Reggio di Calabria Central Railway Station-Reggio Calabria-Reggio de Calabre-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "BXV",
            "countryCode": "IS",
            "cityCode": "BXV",
            "cityName": "Breiddalsvik",
            "name": "[BXV] - Breiddalsvik - BXV - Breiddalsvik - Iceland",
            "searchName": "BXV-Breiddalsvik-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "BJD",
            "countryCode": "IS",
            "cityCode": "BJD",
            "cityName": "Bakkafjordur",
            "name": "[BJD] - Bakkafjordur - BJD - Bakkafjordur - Iceland",
            "searchName": "BJD-Bakkafjordur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "BIU",
            "countryCode": "IS",
            "cityCode": "BIU",
            "cityName": "Bildudalur",
            "name": "[BIU] - Bildudalur - BIU - Bildudalur - Iceland",
            "searchName": "BIU-Bildudalur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "BLO",
            "countryCode": "IS",
            "cityCode": "BLO",
            "cityName": "Blonduos",
            "name": "[BLO] - Blonduos - BLO - Blonduos - Iceland",
            "searchName": "BLO-Blonduos-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "BGJ",
            "countryCode": "IS",
            "cityCode": "BGJ",
            "cityName": "Borgarfjordur Eystri",
            "name": "[BGJ] - Borgarfjordur Eystri - BGJ - Borgarfjordur Eystri - Iceland",
            "searchName": "BGJ-Borgarfjordur Eystri-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "DJU",
            "countryCode": "IS",
            "cityCode": "DJU",
            "cityName": "Djupivogur",
            "name": "[DJU] - Djupivogur - DJU - Djupivogur - Iceland",
            "searchName": "DJU-Djupivogur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "FAG",
            "countryCode": "IS",
            "cityCode": "FAG",
            "cityName": "Fagurholsmyri",
            "name": "[FAG] - Fagurholsmyri - FAG - Fagurholsmyri - Iceland",
            "searchName": "FAG-Fagurholsmyri-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "FAS",
            "countryCode": "IS",
            "cityCode": "FAS",
            "cityName": "Faskrudsfjordur",
            "name": "[FAS] - Faskrudsfjordur - FAS - Faskrudsfjordur - Iceland",
            "searchName": "FAS-Faskrudsfjordur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "BKB",
            "countryCode": "IN",
            "cityCode": "BKB",
            "cityName": "Bikaner",
            "name": "[BKB] - Bikaner - BKB - Bikaner - India",
            "searchName": "BKB-Bikaner-Bikaner Havalimani-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "BHJ",
            "countryCode": "IN",
            "cityCode": "BHJ",
            "cityName": "Bhuj",
            "name": "[BHJ] - Rudra Mata - BHJ - Bhuj - India",
            "searchName": "BHJ-Bhuj-Rudra Mata-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "BHU",
            "countryCode": "IN",
            "cityCode": "BHU",
            "cityName": "Bhavnagar",
            "name": "[BHU] - Bhavnagar - BHU - Bhavnagar - India",
            "searchName": "BHU-Bhavnagar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "BHO",
            "countryCode": "IN",
            "cityCode": "BHO",
            "cityName": "Bhopal",
            "name": "[BHO] - Bhopal - BHO - Bhopal - India",
            "searchName": "BHO-Bhopal-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "BUP",
            "countryCode": "IN",
            "cityCode": "BUP",
            "cityName": "Bhatinda",
            "name": "[BUP] - Bhatinda - BUP - Bhatinda - India",
            "searchName": "BUP-Bhatinda-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "KSW",
            "countryCode": "IL",
            "cityCode": "KSW",
            "cityName": "Kiryat Shmona",
            "name": "[KSW] - Kiryat Shmona - KSW - Kiryat Shmona - Israel",
            "searchName": "KSW-Kiryat Shmona-Kiryat Shmona Havalimani-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "BEV",
            "countryCode": "IL",
            "cityCode": "BEV",
            "cityName": "Beer Sheba",
            "name": "[BEV] - Beer Sheba - BEV - Beer Sheba - Israel",
            "searchName": "BEV-Beer Sheba-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "EIY",
            "countryCode": "IL",
            "cityCode": "EIY",
            "cityName": "Ein Yahav",
            "name": "[EIY] - Ein Yahav - EIY - Ein Yahav - Israel",
            "searchName": "EIY-Ein Yahav-Sapir Airfield Havalimani-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "SED",
            "countryCode": "IL",
            "cityCode": "SED",
            "cityName": "Sedom",
            "name": "[SED] - Minhat Hashnayim - SED - Sedom - Israel",
            "searchName": "SED-Min hat Hashnayim-Minhat Hashnayim-Sedom-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "FNU",
            "countryCode": "IT",
            "cityCode": "FNU",
            "cityName": "Oristano",
            "name": "[FNU] - Fenosu - FNU - Oristano - Italy",
            "searchName": "FNU-Fenosu-Oristano-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "FOG",
            "countryCode": "IT",
            "cityCode": "FOG",
            "cityName": "Foggia",
            "name": "[FOG] - Gino Lisa - FOG - Foggia - Italy",
            "searchName": "FOG-Gino Lisa-Foggia-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "FOI",
            "countryCode": "IT",
            "cityCode": "FOG",
            "cityName": "Foggia",
            "name": "[FOI] - Foggia Railway Station - FOG - Foggia - Italy",
            "searchName": "FOI-FOG-Foggia Railway Station-Foggia-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "FRL",
            "countryCode": "IT",
            "cityCode": "FRL",
            "cityName": "Forli",
            "name": "[FRL] - Luigi Ridolfi - FRL - Forli - Italy",
            "searchName": "FRL-Forli-Luigi Ridolfi-Forl-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "GBN",
            "countryCode": "IT",
            "cityCode": "GBN",
            "cityName": "San Giovanni Rotondo",
            "name": "[GBN] - San Giovanni Rotondo - GBN - San Giovanni Rotondo - Italy",
            "searchName": "GBN-Hubschrauberlandeplatz-San Giovanni Rotondo-San Giovanni Rotondo Havalimani-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "GOA",
            "countryCode": "IT",
            "cityCode": "GOA",
            "cityName": "Genova",
            "name": "[GOA] - Cristoforo Colombo - GOA - Genova - Italy",
            "searchName": "GOA-Genua-Cristoforo Colombo-Cristoforo Colombo Apt-Genova-Genoa-G-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "GRS",
            "countryCode": "IT",
            "cityCode": "GRS",
            "cityName": "Grosseto",
            "name": "[GRS] - Baccarini - GRS - Grosseto - Italy",
            "searchName": "GRS-Baccarini-Baccarini Havalimani-Grosseto-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "LMP",
            "countryCode": "IT",
            "cityCode": "LMP",
            "cityName": "Lampedusa",
            "name": "[LMP] - Lampedusa - LMP - Lampedusa - Italy",
            "searchName": "LMP-Lampedusa-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "LCV",
            "countryCode": "IT",
            "cityCode": "LCV",
            "cityName": "Lucca",
            "name": "[LCV] - Lucca - LCV - Lucca - Italy",
            "searchName": "LCV-Lucca-Lucca Havalimani-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "LCC",
            "countryCode": "IT",
            "cityCode": "LCC",
            "cityName": "Lecce",
            "name": "[LCC] - Galatina - LCC - Lecce - Italy",
            "searchName": "LCC-Galatina-Galatina Havalimani-Lecce-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "LCZ",
            "countryCode": "IT",
            "cityCode": "LCC",
            "cityName": "Lecce",
            "name": "[LCZ] - Lecce Railway Station - LCC - Lecce - Italy",
            "searchName": "LCZ-LCC-Lecce Railway Station-Lecce-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "EBA",
            "countryCode": "IT",
            "cityCode": "EBA",
            "cityName": "Elba Island",
            "name": "[EBA] - Marina Di Campo - EBA - Elba Island - Italy",
            "searchName": "EBA-Elba-Marina Di Campo-Marina di Campo Apt-Elba Island--Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie",
            "priority": 0
        },
        {
            "iataCode": "DCI",
            "countryCode": "IT",
            "cityCode": "DCI",
            "cityName": "Decimomannu",
            "name": "[DCI] - Rafsu Decimomannu - DCI - Decimomannu - Italy",
            "searchName": "DCI-Rafsu Decimomannu-Decimomannu-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "CIY",
            "countryCode": "IT",
            "cityCode": "CIY",
            "cityName": "Comiso",
            "name": "[CIY] - Comiso - CIY - Comiso - Italy",
            "searchName": "CIY-Comiso-Comiso Havalimani-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ADJ",
            "countryCode": "JO",
            "cityCode": "AMM",
            "cityName": "Amman",
            "name": "[ADJ] - Marka International Airport - AMM - Amman - Jordan",
            "searchName": "ADJ-AMM-Civil Marka-Marka International Airport-Civil\\/Marka Airport-Amman-Amm-Jordánia-Jordània-Ürdün-Jordan-Jordánsko-Jordania-Jordaania-Jordanija-Iordania-Yordania-Jordanien-Jordânia-Jordani-Jordanie-Giordania",
            "priority": 0
        },
        {
            "iataCode": "AKJ",
            "countryCode": "JP",
            "cityCode": "AKJ",
            "cityName": "Asahikawa",
            "name": "[AKJ] - Asahikawa - AKJ - Asahikawa - Japan",
            "searchName": "AKJ-Asahikawa-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "AGJ",
            "countryCode": "JP",
            "cityCode": "AGJ",
            "cityName": "Aguni",
            "name": "[AGJ] - Aguni - AGJ - Aguni - Japan",
            "searchName": "AGJ-Aguni-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "ASJ",
            "countryCode": "JP",
            "cityCode": "ASJ",
            "cityName": "Amami",
            "name": "[ASJ] - Amami - ASJ - Amami - Japan",
            "searchName": "ASJ-Amami O Shima-Amami-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "AOJ",
            "countryCode": "JP",
            "cityCode": "AOJ",
            "cityName": "Aomori",
            "name": "[AOJ] - Aomori - AOJ - Aomori - Japan",
            "searchName": "AOJ-Aomori-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "AXT",
            "countryCode": "JP",
            "cityCode": "AXT",
            "cityName": "Akita",
            "name": "[AXT] - Akita - AXT - Akita - Japan",
            "searchName": "AXT-Akita-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "BPU",
            "countryCode": "JP",
            "cityCode": "BPU",
            "cityName": "Beppu",
            "name": "[BPU] - Beppu - BPU - Beppu - Japan",
            "searchName": "BPU-Beppu-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KIJ",
            "countryCode": "JP",
            "cityCode": "KIJ",
            "cityName": "Niigata",
            "name": "[KIJ] - Niigata - KIJ - Niigata - Japan",
            "searchName": "KIJ-Niigata-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KMI",
            "countryCode": "JP",
            "cityCode": "KMI",
            "cityName": "Miyazaki",
            "name": "[KMI] - Miyazaki - KMI - Miyazaki - Japan",
            "searchName": "KMI-Miyazaki-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KMJ",
            "countryCode": "JP",
            "cityCode": "KMJ",
            "cityName": "Kumamoto",
            "name": "[KMJ] - Kumamoto - KMJ - Kumamoto - Japan",
            "searchName": "KMJ-Kumamoto-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KMQ",
            "countryCode": "JP",
            "cityCode": "KMQ",
            "cityName": "Komatsu",
            "name": "[KMQ] - Komatsu - KMQ - Komatsu - Japan",
            "searchName": "KMQ-Komatsu-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KKX",
            "countryCode": "JP",
            "cityCode": "KKX",
            "cityName": "Kikaiga Shima",
            "name": "[KKX] - Kikaiga Shima - KKX - Kikaiga Shima - Japan",
            "searchName": "KKX-Kikaiga Shima-Kikai Havalimani-Kikai-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KKJ",
            "countryCode": "JP",
            "cityCode": "KKJ",
            "cityName": "Kita Kyushu",
            "name": "[KKJ] - Kita Kyushu - KKJ - Kita Kyushu - Japan",
            "searchName": "KKJ-Kita Kyushu-Kokura-Kokura Havalimani-Kitakyushu-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KJP",
            "countryCode": "JP",
            "cityCode": "KJP",
            "cityName": "Kerama",
            "name": "[KJP] - Kerama - KJP - Kerama - Japan",
            "searchName": "KJP-Kerama-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KTD",
            "countryCode": "JP",
            "cityCode": "KTD",
            "cityName": "Kitadaito",
            "name": "[KTD] - Kitadaito - KTD - Kitadaito - Japan",
            "searchName": "KTD-Kitadaito-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KOJ",
            "countryCode": "JP",
            "cityCode": "KOJ",
            "cityName": "Kagoshima",
            "name": "[KOJ] - Kagoshima - KOJ - Kagoshima - Japan",
            "searchName": "KOJ-Kagoshima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KCZ",
            "countryCode": "JP",
            "cityCode": "KCZ",
            "cityName": "Kochi",
            "name": "[KCZ] - Kochi - KCZ - Kochi - Japan",
            "searchName": "KCZ-Kochi-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KUH",
            "countryCode": "JP",
            "cityCode": "KUH",
            "cityName": "Kushiro",
            "name": "[KUH] - Kushiro - KUH - Kushiro - Japan",
            "searchName": "KUH-Kushiro-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KUJ",
            "countryCode": "JP",
            "cityCode": "KUJ",
            "cityName": "Kushimoto",
            "name": "[KUJ] - Kushimoto - KUJ - Kushimoto - Japan",
            "searchName": "KUJ-Kushimoto-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KUM",
            "countryCode": "JP",
            "cityCode": "KUM",
            "cityName": "Yakushima",
            "name": "[KUM] - Yakushima - KUM - Yakushima - Japan",
            "searchName": "KUM-Yakushima-Yakushima Havalimani-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "HAC",
            "countryCode": "JP",
            "cityCode": "HAC",
            "cityName": "Hachijo Jima",
            "name": "[HAC] - Hachijo Jima - HAC - Hachijo Jima - Japan",
            "searchName": "HAC-Hachijo Jima Island-Hachijo Jima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "GAJ",
            "countryCode": "JP",
            "cityCode": "GAJ",
            "cityName": "Yamagata",
            "name": "[GAJ] - Junmachi - GAJ - Yamagata - Japan",
            "searchName": "GAJ-Yamagata-Junmachi-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "FSZ",
            "countryCode": "JP",
            "cityCode": "FSZ",
            "cityName": "Shizuoka",
            "name": "[FSZ] - Mount Fuji - FSZ - Shizuoka - Japan",
            "searchName": "FSZ-Mt Fuji Shizuoka-Mount Fuji-Mt. Fuji Shizuoka Airport-Shizuoka-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "FUJ",
            "countryCode": "JP",
            "cityCode": "FUJ",
            "cityName": "Fukue",
            "name": "[FUJ] - Fukue - FUJ - Fukue - Japan",
            "searchName": "FUJ-Fukue-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "FUK",
            "countryCode": "JP",
            "cityCode": "FUK",
            "cityName": "Fukuoka",
            "name": "[FUK] - Fukuoka - FUK - Fukuoka - Japan",
            "searchName": "FUK-Fukuoka-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "FKS",
            "countryCode": "JP",
            "cityCode": "FKS",
            "cityName": "Fukushima",
            "name": "[FKS] - Fukushima Airport - FKS - Fukushima - Japan",
            "searchName": "FKS-Fukushima-Fukushima Airport-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "FKJ",
            "countryCode": "JP",
            "cityCode": "FKJ",
            "cityName": "Fukui",
            "name": "[FKJ] - Fukui - FKJ - Fukui - Japan",
            "searchName": "FKJ-Fukui-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "ISG",
            "countryCode": "JP",
            "cityCode": "ISG",
            "cityName": "Ishigaki",
            "name": "[ISG] - Ishigaki - ISG - Ishigaki - Japan",
            "searchName": "ISG-Ishigaki-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "IWJ",
            "countryCode": "JP",
            "cityCode": "IWJ",
            "cityName": "Iwami",
            "name": "[IWJ] - Iwami - IWJ - Iwami - Japan",
            "searchName": "IWJ-Iwami-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "IWK",
            "countryCode": "JP",
            "cityCode": "IWK",
            "cityName": "Iwakuni",
            "name": "[IWK] - Iwakuni - IWK - Iwakuni - Japan",
            "searchName": "IWK-Iwakuni-MCAS Iwakuni-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "IWO",
            "countryCode": "JP",
            "cityCode": "IWO",
            "cityName": "Iwo Jima Vol",
            "name": "[IWO] - Iwo Jima Airbase - IWO - Iwo Jima Vol - Japan",
            "searchName": "IWO-Iwo Jima Airbase-Iwo Jima-Iwo Jima Vol-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "IZO",
            "countryCode": "JP",
            "cityCode": "IZO",
            "cityName": "Izumo",
            "name": "[IZO] - Izumo - IZO - Izumo - Japan",
            "searchName": "IZO-Izumo-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "IEJ",
            "countryCode": "JP",
            "cityCode": "IEJ",
            "cityName": "Iejima",
            "name": "[IEJ] - Iejima - IEJ - Iejima - Japan",
            "searchName": "IEJ-Iejima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "IBR",
            "countryCode": "JP",
            "cityCode": "IBR",
            "cityName": "Ibaraki",
            "name": "[IBR] - Ibaraki - IBR - Ibaraki - Japan",
            "searchName": "IBR-Ibaraki Airport-Ibaraki-Omitama-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "IIN",
            "countryCode": "JP",
            "cityCode": "IIN",
            "cityName": "Nishinoomote",
            "name": "[IIN] - Nishinoomote - IIN - Nishinoomote - Japan",
            "searchName": "IIN-Omote - Flughafen-Nishinoomote-Nishino-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "IHA",
            "countryCode": "JP",
            "cityCode": "IHA",
            "cityName": "Niihama",
            "name": "[IHA] - Niihama - IHA - Niihama - Japan",
            "searchName": "IHA-Niihama-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "IKI",
            "countryCode": "JP",
            "cityCode": "IKI",
            "cityName": "Iki",
            "name": "[IKI] - Iki - IKI - Iki - Japan",
            "searchName": "IKI-Iki-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "HTR",
            "countryCode": "JP",
            "cityCode": "HTR",
            "cityName": "Hateruma",
            "name": "[HTR] - Hateruma - HTR - Hateruma - Japan",
            "searchName": "HTR-Hateruma-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "HSG",
            "countryCode": "JP",
            "cityCode": "HSG",
            "cityName": "Saga",
            "name": "[HSG] - Saga - HSG - Saga - Japan",
            "searchName": "HSG-Saga-Saga Havalimani-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "HIJ",
            "countryCode": "JP",
            "cityCode": "HIJ",
            "cityName": "Hiroshima",
            "name": "[HIJ] - International - HIJ - Hiroshima - Japan",
            "searchName": "HIJ-Hiroshima Intl-International-Hiroshima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "HIW",
            "countryCode": "JP",
            "cityCode": "HIJ",
            "cityName": "Hiroshima",
            "name": "[HIW] - Hiroshima West - HIJ - Hiroshima - Japan",
            "searchName": "HIW-HIJ-Hiroshima West-Hiroshima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "HHE",
            "countryCode": "JP",
            "cityCode": "HHE",
            "cityName": "Hachinohe",
            "name": "[HHE] - Hachinohe - HHE - Hachinohe - Japan",
            "searchName": "HHE-Hachinohe-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "HKD",
            "countryCode": "JP",
            "cityCode": "HKD",
            "cityName": "Hakodate",
            "name": "[HKD] - Hakodate - HKD - Hakodate - Japan",
            "searchName": "HKD-Hakodate-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "HNA",
            "countryCode": "JP",
            "cityCode": "HNA",
            "cityName": "Hanamaki",
            "name": "[HNA] - Hanamaki - HNA - Hanamaki - Japan",
            "searchName": "HNA-Hanamaki-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "MMY",
            "countryCode": "JP",
            "cityCode": "MMY",
            "cityName": "Miyako Jima",
            "name": "[MMY] - Hirara - MMY - Miyako Jima - Japan",
            "searchName": "MMY-Miyako Jima-Hirara-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "MMD",
            "countryCode": "JP",
            "cityCode": "MMD",
            "cityName": "Minami Daito",
            "name": "[MMD] - Maridor - MMD - Minami Daito - Japan",
            "searchName": "MMD-Maridor-Minami Daito-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "MMB",
            "countryCode": "JP",
            "cityCode": "MMB",
            "cityName": "Memanbetsu",
            "name": "[MMB] - Memanbetsu - MMB - Memanbetsu - Japan",
            "searchName": "MMB-Memanbetsu-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "MMJ",
            "countryCode": "JP",
            "cityCode": "MMJ",
            "cityName": "Matsumoto",
            "name": "[MMJ] - Matsumoto - MMJ - Matsumoto - Japan",
            "searchName": "MMJ-Matsumoto-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "MUS",
            "countryCode": "JP",
            "cityCode": "MUS",
            "cityName": "Marcus Island",
            "name": "[MUS] - Marcus Island - MUS - Marcus Island - Japan",
            "searchName": "MUS-Marcus Island-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "MSJ",
            "countryCode": "JP",
            "cityCode": "MSJ",
            "cityName": "Misawa",
            "name": "[MSJ] - Misawa - MSJ - Misawa - Japan",
            "searchName": "MSJ-Misawa-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "MBE",
            "countryCode": "JP",
            "cityCode": "MBE",
            "cityName": "Monbetsu",
            "name": "[MBE] - Monbetsu - MBE - Monbetsu - Japan",
            "searchName": "MBE-Monbetsu-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "OBO",
            "countryCode": "JP",
            "cityCode": "OBO",
            "cityName": "Obihiro",
            "name": "[OBO] - Obihiro - OBO - Obihiro - Japan",
            "searchName": "OBO-Obihiro-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "OKI",
            "countryCode": "JP",
            "cityCode": "OKI",
            "cityName": "Oki Island",
            "name": "[OKI] - Oki Island - OKI - Oki Island - Japan",
            "searchName": "OKI-Oki Island-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "OKJ",
            "countryCode": "JP",
            "cityCode": "OKJ",
            "cityName": "Okayama",
            "name": "[OKJ] - Okayama - OKJ - Okayama - Japan",
            "searchName": "OKJ-Okayama-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "OKE",
            "countryCode": "JP",
            "cityCode": "OKE",
            "cityName": "Okino Erabu",
            "name": "[OKE] - Okino Erabu - OKE - Okino Erabu - Japan",
            "searchName": "OKE-Okino Erabu-Okino Erabu Havalimani-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "OGN",
            "countryCode": "JP",
            "cityCode": "OGN",
            "cityName": "Yonaguni Jima",
            "name": "[OGN] - Yonaguni Jima - OGN - Yonaguni Jima - Japan",
            "searchName": "OGN-Yonaguni Jima-Yonaguni-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "OIM",
            "countryCode": "JP",
            "cityCode": "OIM",
            "cityName": "Oshima",
            "name": "[OIM] - Oshima - OIM - Oshima - Japan",
            "searchName": "OIM-Oshima Island-Oshima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "OIR",
            "countryCode": "JP",
            "cityCode": "OIR",
            "cityName": "Okushiri",
            "name": "[OIR] - Okushiri - OIR - Okushiri - Japan",
            "searchName": "OIR-Okushiri-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "OIT",
            "countryCode": "JP",
            "cityCode": "OIT",
            "cityName": "Oita",
            "name": "[OIT] - Oita - OIT - Oita - Japan",
            "searchName": "OIT-Oita-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "OKA",
            "countryCode": "JP",
            "cityCode": "OKA",
            "cityName": "Okinawa",
            "name": "[OKA] - Naha - OKA - Okinawa - Japan",
            "searchName": "OKA-Flughafen Naha-Naha-Okinawa-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "DNA",
            "countryCode": "JP",
            "cityCode": "OKA",
            "cityName": "Okinawa",
            "name": "[DNA] - Kadena AB - OKA - Okinawa - Japan",
            "searchName": "DNA-OKA-Kadena AB-Okinawa-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "AHA",
            "countryCode": "JP",
            "cityCode": "OKA",
            "cityName": "Okinawa",
            "name": "[AHA] - Naha AFB - OKA - Okinawa - Japan",
            "searchName": "AHA-OKA-Naha AFB-Okinawa-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "NTQ",
            "countryCode": "JP",
            "cityCode": "NTQ",
            "cityName": "Wajima",
            "name": "[NTQ] - Noto Airport - NTQ - Wajima - Japan",
            "searchName": "NTQ-Wajima-Noto Airport-Noto Havalimani-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "MWV",
            "countryCode": "KH",
            "cityCode": "MWV",
            "cityName": "Mundulkiri",
            "name": "[MWV] - Mundulkiri - MWV - Mundulkiri - Cambodia",
            "searchName": "MWV-Mundulkiri-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "TNX",
            "countryCode": "KH",
            "cityCode": "TNX",
            "cityName": "Stung Treng",
            "name": "[TNX] - Stung Treng - TNX - Stung Treng - Cambodia",
            "searchName": "TNX-Stung Treng-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "KKZ",
            "countryCode": "KH",
            "cityCode": "KKZ",
            "cityName": "Koh Kong",
            "name": "[KKZ] - Koh Kong - KKZ - Koh Kong - Cambodia",
            "searchName": "KKZ-Koh Kong-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "KTI",
            "countryCode": "KH",
            "cityCode": "KTI",
            "cityName": "Kratie",
            "name": "[KTI] - Kratie - KTI - Kratie - Cambodia",
            "searchName": "KTI-Kratie-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "KOS",
            "countryCode": "KH",
            "cityCode": "KOS",
            "cityName": "Sihanoukville",
            "name": "[KOS] - Sihanoukville - KOS - Sihanoukville - Cambodia",
            "searchName": "KOS-Sihanoukville-Sihanoukville Havalimani-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "KZC",
            "countryCode": "KH",
            "cityCode": "KZC",
            "cityName": "Kompong-Chhna",
            "name": "[KZC] - Kompong-Chhna - KZC - Kompong-Chhna - Cambodia",
            "searchName": "KZC-Kompong-Chhna-Kampong Chhnang-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "KZD",
            "countryCode": "KH",
            "cityCode": "KZD",
            "cityName": "Krakor",
            "name": "[KZD] - Krakor - KZD - Krakor - Cambodia",
            "searchName": "KZD-Krakor-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "BBM",
            "countryCode": "KH",
            "cityCode": "BBM",
            "cityName": "Battambang",
            "name": "[BBM] - Battambang - BBM - Battambang - Cambodia",
            "searchName": "BBM-Battambang-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "BBG",
            "countryCode": "KI",
            "cityCode": "BBG",
            "cityName": "Butaritari",
            "name": "[BBG] - Butaritari - BBG - Butaritari - Kiribati",
            "searchName": "BBG-Butaritari-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "BEZ",
            "countryCode": "KI",
            "cityCode": "BEZ",
            "cityName": "Beru",
            "name": "[BEZ] - Beru - BEZ - Beru - Kiribati",
            "searchName": "BEZ-Beru-Beru Havalimani-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "AIS",
            "countryCode": "KI",
            "cityCode": "AIS",
            "cityName": "Arorae Island",
            "name": "[AIS] - Arorae Island - AIS - Arorae Island - Kiribati",
            "searchName": "AIS-Arorae Island-Arorae Island\u00A0 Havalimani-Arorae-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "AEA",
            "countryCode": "KI",
            "cityCode": "AEA",
            "cityName": "Abemama Atoll",
            "name": "[AEA] - Abemama Atoll - AEA - Abemama Atoll - Kiribati",
            "searchName": "AEA-Abemama Atoll-Abemama Atoll Havalimani-Abemama-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "AAK",
            "countryCode": "KI",
            "cityCode": "AAK",
            "cityName": "Aranuka",
            "name": "[AAK] - Aranuka - AAK - Aranuka - Kiribati",
            "searchName": "AAK-Aranuka-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "ABF",
            "countryCode": "KI",
            "cityCode": "ABF",
            "cityName": "Abaiang",
            "name": "[ABF] - Abaiang - ABF - Abaiang - Kiribati",
            "searchName": "ABF-Abaiang-Abaiang Atoll Havalimani-Abaiang Atoll-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "CXI",
            "countryCode": "KI",
            "cityCode": "CXI",
            "cityName": "Christmas Island",
            "name": "[CXI] - Cassidy International Airport - CXI - Christmas Island - Kiribati",
            "searchName": "CXI-Christmas Island-Cassidy International Airport--Kiribati",
            "priority": 0
        },
        {
            "iataCode": "CIS",
            "countryCode": "KI",
            "cityCode": "CIS",
            "cityName": "Canton Island",
            "name": "[CIS] - Canton Island - CIS - Canton Island - Kiribati",
            "searchName": "CIS-Canton Island-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "KUC",
            "countryCode": "KI",
            "cityCode": "KUC",
            "cityName": "Kuria",
            "name": "[KUC] - Kuria - KUC - Kuria - Kiribati",
            "searchName": "KUC-Kuria-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "TNQ",
            "countryCode": "KI",
            "cityCode": "TNQ",
            "cityName": "Teraina",
            "name": "[TNQ] - Teraina - TNQ - Teraina - Kiribati",
            "searchName": "TNQ-Washington Island-Teraina-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "TNV",
            "countryCode": "KI",
            "cityCode": "TNV",
            "cityName": "Tabuaeran",
            "name": "[TNV] - Tabuaeran - TNV - Tabuaeran - Kiribati",
            "searchName": "TNV-Fanning Island-Tabuaeran-Taubuaeran-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "TMN",
            "countryCode": "KI",
            "cityCode": "TMN",
            "cityName": "Tamana Island",
            "name": "[TMN] - Tamana Island - TMN - Tamana Island - Kiribati",
            "searchName": "TMN-Tamana Island-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "TRW",
            "countryCode": "KI",
            "cityCode": "TRW",
            "cityName": "Tarawa",
            "name": "[TRW] - Bonriki - TRW - Tarawa - Kiribati",
            "searchName": "TRW-Tarawa-Bonriki-Bonriki International Airport-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "TSU",
            "countryCode": "KI",
            "cityCode": "TSU",
            "cityName": "Tabiteuea South",
            "name": "[TSU] - Tabiteuea South - TSU - Tabiteuea South - Kiribati",
            "searchName": "TSU-Tabiteuea South-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "TBF",
            "countryCode": "KI",
            "cityCode": "TBF",
            "cityName": "Tabiteuea North",
            "name": "[TBF] - Tabiteuea North - TBF - Tabiteuea North - Kiribati",
            "searchName": "TBF-Tabiteuea North-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "MZK",
            "countryCode": "KI",
            "cityCode": "MZK",
            "cityName": "Marakei",
            "name": "[MZK] - Marakei - MZK - Marakei - Kiribati",
            "searchName": "MZK-Marakei-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "NIG",
            "countryCode": "KI",
            "cityCode": "NIG",
            "cityName": "Nikunau",
            "name": "[NIG] - Nikunau - NIG - Nikunau - Kiribati",
            "searchName": "NIG-Nikunau-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "NON",
            "countryCode": "KI",
            "cityCode": "NON",
            "cityName": "Rotima",
            "name": "[NON] - Nonouti - NON - Rotima - Kiribati",
            "searchName": "NON-Nonouti-Rotima-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "MTK",
            "countryCode": "KI",
            "cityCode": "MTK",
            "cityName": "Makin Island",
            "name": "[MTK] - Makin Island - MTK - Makin Island - Kiribati",
            "searchName": "MTK-Makin Island-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "MNK",
            "countryCode": "KI",
            "cityCode": "MNK",
            "cityName": "Maiana",
            "name": "[MNK] - Maiana - MNK - Maiana - Kiribati",
            "searchName": "MNK-Maiana-Maiana Havalimani-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "OOT",
            "countryCode": "KI",
            "cityCode": "OOT",
            "cityName": "Onotoa",
            "name": "[OOT] - Onotoa - OOT - Onotoa - Kiribati",
            "searchName": "OOT-Onotoa-Kiribati",
            "priority": 0
        },
        {
            "iataCode": "NWA",
            "countryCode": "KM",
            "cityCode": "NWA",
            "cityName": "Moheli",
            "name": "[NWA] - Moheli - NWA - Moheli - Comoros",
            "searchName": "NWA-Moheli-Komoren-Comoros-Komorlar-Comores-Comoras-Comore",
            "priority": 0
        },
        {
            "iataCode": "AJN",
            "countryCode": "KM",
            "cityCode": "AJN",
            "cityName": "Anjouan",
            "name": "[AJN] - Ouani - AJN - Anjouan - Comoros",
            "searchName": "AJN-Ouani-Anjouan-Komoren-Comoros-Komorlar-Comores-Comoras-Comore",
            "priority": 0
        },
        {
            "iataCode": "YVA",
            "countryCode": "KM",
            "cityCode": "YVA",
            "cityName": "Moroni",
            "name": "[YVA] - Iconi - YVA - Moroni - Comoros",
            "searchName": "YVA-Iconi-Moroni Iconi-Moroni-Komoren-Comoros-Komorlar-Comores-Comoras-Comore",
            "priority": 0
        },
        {
            "iataCode": "HAH",
            "countryCode": "KM",
            "cityCode": "YVA",
            "cityName": "Moroni",
            "name": "[HAH] - Prince Said Ibrahim In - YVA - Moroni - Comoros",
            "searchName": "HAH-YVA-Moroni Prince Said Ibrahim-Prince Said Ibrahim In-Prince Said Ibrahim-Moroni-Komoren-Comoros-Komorlar-Comores-Comoras-Comore",
            "priority": 0
        },
        {
            "iataCode": "NEV",
            "countryCode": "KN",
            "cityCode": "NEV",
            "cityName": "Nevis",
            "name": "[NEV] - Vance Winkworth Amory International - NEV - Nevis - Saint Kitts and Nevis",
            "searchName": "NEV-Charlestown-Vance Winkworth Amory International-Newcastle-Nevis-Saint Kitts und Nevis-Saint Kitts and Nevis-Saint Kitts ve Nevis-Saint-Kitts-et-San Cristóbal y Nieves-Saint Kitts e Nevis",
            "priority": 0
        },
        {
            "iataCode": "SKB",
            "countryCode": "KN",
            "cityCode": "SKB",
            "cityName": "Basseterre",
            "name": "[SKB] - Robert L Bradshaw International - SKB - Basseterre - Saint Kitts and Nevis",
            "searchName": "SKB-Nevis Basseterre-Robert L Bradshaw International-Robert Bradshaw Intl-Robert L Bradshaw INTL-Saint Kitts-Basseterre-St Kitts-Saint Kitts und Nevis-Saint Kitts and Nevis-Saint Kitts ve Nevis-Saint-Kitts-et-Nevis-San Cristóbal y Nieves-Saint Kitts e Nevis",
            "priority": 0
        },
        {
            "iataCode": "WOS",
            "countryCode": "KP",
            "cityCode": "WOS",
            "cityName": "Wonsan",
            "name": "[WOS] - Wonsan - WOS - Wonsan - North Korea",
            "searchName": "WOS-Wonsan-North Korea-Nordkorea-Kuzey Kore-Corée du Nord-Corea del Norte-Corea del Nord",
            "priority": 0
        },
        {
            "iataCode": "RGO",
            "countryCode": "KP",
            "cityCode": "RGO",
            "cityName": "Orang",
            "name": "[RGO] - Orang - RGO - Orang - North Korea",
            "searchName": "RGO-Orang-North Korea-Nordkorea-Kuzey Kore-Corée du Nord-Corea del Norte-Corea del Nord",
            "priority": 0
        },
        {
            "iataCode": "DSO",
            "countryCode": "KP",
            "cityCode": "DSO",
            "cityName": "Sondok",
            "name": "[DSO] - Sondok - DSO - Sondok - North Korea",
            "searchName": "DSO-Sondok-North Korea-Nordkorea-Kuzey Kore-Corée du Nord-Corea del Norte-Corea del Nord",
            "priority": 0
        },
        {
            "iataCode": "FNJ",
            "countryCode": "KP",
            "cityCode": "FNJ",
            "cityName": "Pyongyang",
            "name": "[FNJ] - Sunan International - FNJ - Pyongyang - North Korea",
            "searchName": "FNJ-Pyongyang-Sunan International-Sunan-North Korea-Nordkorea-Kuzey Kore-Corée du Nord-Corea del Norte-Corea del Nord",
            "priority": 0
        },
        {
            "iataCode": "YJS",
            "countryCode": "KP",
            "cityCode": "YJS",
            "cityName": "Samjiyon",
            "name": "[YJS] - Samjiyon - YJS - Samjiyon - North Korea",
            "searchName": "YJS-Samjiyon-North Korea-Nordkorea-Kuzey Kore-Corée du Nord-Corea del Norte-Corea del Nord",
            "priority": 0
        },
        {
            "iataCode": "YNY",
            "countryCode": "KR",
            "cityCode": "YNY",
            "cityName": "Yangyang",
            "name": "[YNY] - Yangyang - YNY - Yangyang - South Korea",
            "searchName": "YNY-Yangyang-Comté de Yangyang-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "HIN",
            "countryCode": "KR",
            "cityCode": "HIN",
            "cityName": "Jinju",
            "name": "[HIN] - Sacheon - HIN - Jinju - South Korea",
            "searchName": "HIN-Chinju-Sacheon-Sancheon-Jinju-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "KUV",
            "countryCode": "KR",
            "cityCode": "KUV",
            "cityName": "Gunsan",
            "name": "[KUV] - Gunsan - KUV - Gunsan - South Korea",
            "searchName": "KUV-Kunsan-Gunsan-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "KUZ",
            "countryCode": "KR",
            "cityCode": "KUZ",
            "cityName": "Gunsan AB",
            "name": "[KUZ] - Gunsan Airbase - KUZ - Gunsan AB - South Korea",
            "searchName": "KUZ-Gunsan Airbase-Gunsan-Gunsan AB-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "KWJ",
            "countryCode": "KR",
            "cityCode": "KWJ",
            "cityName": "Gwangju",
            "name": "[KWJ] - Gwangju - KWJ - Gwangju - South Korea",
            "searchName": "KWJ-Kwangju-Gwangju-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "KPO",
            "countryCode": "KR",
            "cityCode": "KPO",
            "cityName": "Pohang",
            "name": "[KPO] - Pohang - KPO - Pohang - South Korea",
            "searchName": "KPO-Pohang-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "JGE",
            "countryCode": "KR",
            "cityCode": "JGE",
            "cityName": "Geoje",
            "name": "[JGE] - Heliport - JGE - Geoje - South Korea",
            "searchName": "JGE-Hubschrauberlandeplatz-Heliport-Geoje-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "JCN",
            "countryCode": "KR",
            "cityCode": "JCN",
            "cityName": "Incheon",
            "name": "[JCN] - Incheon Heliport - JCN - Incheon - South Korea",
            "searchName": "JCN-Incheon Heliport-Incheon-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "JDG",
            "countryCode": "KR",
            "cityCode": "JDG",
            "cityName": "Jeongseok",
            "name": "[JDG] - Jeongseok - JDG - Jeongseok - South Korea",
            "searchName": "JDG-Jeongseok-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "KAG",
            "countryCode": "KR",
            "cityCode": "KAG",
            "cityName": "Gangneung",
            "name": "[KAG] - Gangneung - KAG - Gangneung - South Korea",
            "searchName": "KAG-Gangneung-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "CJJ",
            "countryCode": "KR",
            "cityCode": "CJJ",
            "cityName": "Cheongju",
            "name": "[CJJ] - Cheongju - CJJ - Cheongju - South Korea",
            "searchName": "CJJ-Cheongju-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "CJU",
            "countryCode": "KR",
            "cityCode": "CJU",
            "cityName": "Jeju",
            "name": "[CJU] - Jeju Airport - CJU - Jeju - South Korea",
            "searchName": "CJU-Cheju-Jeju Airport-Jeju-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "JCJ",
            "countryCode": "KR",
            "cityCode": "CJU",
            "cityName": "Jeju",
            "name": "[JCJ] - Chuja Heliport - CJU - Jeju - South Korea",
            "searchName": "JCJ-CJU-Chuja Heliport-Jeju-Cheju-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "JSP",
            "countryCode": "KR",
            "cityCode": "CJU",
            "cityName": "Jeju",
            "name": "[JSP] - Seogwipo Heliport - CJU - Jeju - South Korea",
            "searchName": "JSP-CJU-Seogwipo Heliport-Jeju-Cheju-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "CHN",
            "countryCode": "KR",
            "cityCode": "CHN",
            "cityName": "Jeonju",
            "name": "[CHN] - Jeonju - CHN - Jeonju - South Korea",
            "searchName": "CHN-Jeonju-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "CHF",
            "countryCode": "KR",
            "cityCode": "CHF",
            "cityName": "Jinhae",
            "name": "[CHF] - Jinhae - CHF - Jinhae - South Korea",
            "searchName": "CHF-Jinhae-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "RSU",
            "countryCode": "KR",
            "cityCode": "RSU",
            "cityName": "Yeosu",
            "name": "[RSU] - Yeosu - RSU - Yeosu - South Korea",
            "searchName": "RSU-Yosu-Yeosu-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "PUS",
            "countryCode": "KR",
            "cityCode": "PUS",
            "cityName": "Busan",
            "name": "[PUS] - Gimhae - PUS - Busan - South Korea",
            "searchName": "PUS-Pusan-Gimhae-Busan-Bus-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "OSN",
            "countryCode": "KR",
            "cityCode": "OSN",
            "cityName": "Osan",
            "name": "[OSN] - Osan AB - OSN - Osan - South Korea",
            "searchName": "OSN-Osan AB-Osan-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "MWX",
            "countryCode": "KR",
            "cityCode": "MWX",
            "cityName": "Muan",
            "name": "[MWX] - Muan International - MWX - Muan - South Korea",
            "searchName": "MWX-Intl Airport-Muan International- Intl Airport-Muan International Airport-Muan-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "MPK",
            "countryCode": "KR",
            "cityCode": "MPK",
            "cityName": "Mokpo",
            "name": "[MPK] - Mokpo - MPK - Mokpo - South Korea",
            "searchName": "MPK-Mokpo-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "WJU",
            "countryCode": "KR",
            "cityCode": "WJU",
            "cityName": "WonJu",
            "name": "[WJU] - WonJu - WJU - WonJu - South Korea",
            "searchName": "WJU-WonJu-Wonju-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "UJN",
            "countryCode": "KR",
            "cityCode": "UJN",
            "cityName": "Uljin",
            "name": "[UJN] - Uljin - UJN - Uljin - South Korea",
            "searchName": "UJN-Uljin-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "USN",
            "countryCode": "KR",
            "cityCode": "USN",
            "cityName": "Ulsan",
            "name": "[USN] - Ulsan - USN - Ulsan - South Korea",
            "searchName": "USN-Ulsan-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "YEC",
            "countryCode": "KR",
            "cityCode": "YEC",
            "cityName": "Yecheon",
            "name": "[YEC] - Yecheon - YEC - Yecheon - South Korea",
            "searchName": "YEC-Yecheon-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "SHO",
            "countryCode": "KR",
            "cityCode": "SHO",
            "cityName": "Sokcho",
            "name": "[SHO] - Seolak - SHO - Sokcho - South Korea",
            "searchName": "SHO-Seolak-Seolak Havalimani-Sokcho-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "NKM",
            "countryCode": "JP",
            "cityCode": "NGO",
            "cityName": "Nagoya",
            "name": "[NKM] - Komaki AFB - NGO - Nagoya - Japan",
            "searchName": "NKM-NGO-Nagoya Komaki-Komaki AFB-Nagoya Airfield-Nagoya Komaki APT-Nagoya-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "NJA",
            "countryCode": "JP",
            "cityCode": "NJA",
            "cityName": "Atsugi",
            "name": "[NJA] - Atsugi NAS - NJA - Atsugi - Japan",
            "searchName": "NJA-Atsugi NAS-Atsugi-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "NGS",
            "countryCode": "JP",
            "cityCode": "NGS",
            "cityName": "Nagasaki",
            "name": "[NGS] - Nagasaki - NGS - Nagasaki - Japan",
            "searchName": "NGS-Nagasaki-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "MYE",
            "countryCode": "JP",
            "cityCode": "MYE",
            "cityName": "Miyake Jima",
            "name": "[MYE] - Miyake Jima - MYE - Miyake Jima - Japan",
            "searchName": "MYE-Miyake Jima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "MYJ",
            "countryCode": "JP",
            "cityCode": "MYJ",
            "cityName": "Matsuyama",
            "name": "[MYJ] - Matsuyama - MYJ - Matsuyama - Japan",
            "searchName": "MYJ-Matsuyama-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "OMJ",
            "countryCode": "JP",
            "cityCode": "OMJ",
            "cityName": "Omura",
            "name": "[OMJ] - Omura - OMJ - Omura - Japan",
            "searchName": "OMJ-Omura-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "ONJ",
            "countryCode": "JP",
            "cityCode": "ONJ",
            "cityName": "Odate Noshiro",
            "name": "[ONJ] - Odate Noshiro - ONJ - Odate Noshiro - Japan",
            "searchName": "ONJ-Odate Noshiro-Odate Noshiro Havalimani-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "UKB",
            "countryCode": "JP",
            "cityCode": "OSA",
            "cityName": "Osaka",
            "name": "[UKB] - Kobe - OSA - Osaka - Japan",
            "searchName": "UKB-OSA-Kobe-Kobe Airport-Osaka-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "KIX",
            "countryCode": "JP",
            "cityCode": "OSA",
            "cityName": "Osaka",
            "name": "[KIX] - Kansai International - OSA - Osaka - Japan",
            "searchName": "KIX-OSA-Osaka Kansai-Kansai International-Kansai Intl-Osaka-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "ITM",
            "countryCode": "JP",
            "cityCode": "OSA",
            "cityName": "Osaka",
            "name": "[ITM] - Itami - OSA - Osaka - Japan",
            "searchName": "ITM-OSA-Osaka Itami-Itami-Osaka-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "RBJ",
            "countryCode": "JP",
            "cityCode": "RBJ",
            "cityName": "Rebun",
            "name": "[RBJ] - Rebun - RBJ - Rebun - Japan",
            "searchName": "RBJ-Rebun-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "RNJ",
            "countryCode": "JP",
            "cityCode": "RNJ",
            "cityName": "Yoronjima",
            "name": "[RNJ] - Yoronjima - RNJ - Yoronjima - Japan",
            "searchName": "RNJ-Yoronjima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "RIS",
            "countryCode": "JP",
            "cityCode": "RIS",
            "cityName": "Rishiri",
            "name": "[RIS] - Rishiri - RIS - Rishiri - Japan",
            "searchName": "RIS-Rishiri-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "WKJ",
            "countryCode": "JP",
            "cityCode": "WKJ",
            "cityName": "Wakkanai",
            "name": "[WKJ] - Hokkaido - WKJ - Wakkanai - Japan",
            "searchName": "WKJ-Wakkanai-Hokkaido-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "UEO",
            "countryCode": "JP",
            "cityCode": "UEO",
            "cityName": "Kumejima",
            "name": "[UEO] - Kumejima - UEO - Kumejima - Japan",
            "searchName": "UEO-Kume Jima-Kumejima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "UBJ",
            "countryCode": "JP",
            "cityCode": "UBJ",
            "cityName": "Ube",
            "name": "[UBJ] - Ube - UBJ - Ube - Japan",
            "searchName": "UBJ-Ube Japan-Ube-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "UKY",
            "countryCode": "JP",
            "cityCode": "UKY",
            "cityName": "Kyoto",
            "name": "[UKY] - Kyoto - UKY - Kyoto - Japan",
            "searchName": "UKY-Kyoto-Kyoto Havalimani-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "OKD",
            "countryCode": "JP",
            "cityCode": "SPK",
            "cityName": "Sapporo",
            "name": "[OKD] - Okadama - SPK - Sapporo - Japan",
            "searchName": "OKD-SPK-Okadama-Sapporo Okadama-Sapporo-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "CTS",
            "countryCode": "JP",
            "cityCode": "SPK",
            "cityName": "Sapporo",
            "name": "[CTS] - Chitose - SPK - Sapporo - Japan",
            "searchName": "CTS-SPK-Sapporo Chitose-Chitose-Sapporo-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "SDJ",
            "countryCode": "JP",
            "cityCode": "SDJ",
            "cityName": "Sendai",
            "name": "[SDJ] - Sendai - SDJ - Sendai - Japan",
            "searchName": "SDJ-Sendai-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "SDS",
            "countryCode": "JP",
            "cityCode": "SDS",
            "cityName": "Sado Shima",
            "name": "[SDS] - Sado Shima - SDS - Sado Shima - Japan",
            "searchName": "SDS-Sado Shima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "SDO",
            "countryCode": "JP",
            "cityCode": "SDO",
            "cityName": "Ryotsu Sado Island",
            "name": "[SDO] - Ryotsu Sado Island - SDO - Ryotsu Sado Island - Japan",
            "searchName": "SDO-Ryotsu Sado Island-Ryotsy-Ryotsu Sado island-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "SHM",
            "countryCode": "JP",
            "cityCode": "SHM",
            "cityName": "Shirahama",
            "name": "[SHM] - Shirahama - SHM - Shirahama - Japan",
            "searchName": "SHM-Nanki Shirahana-Shirahama-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "SHI",
            "countryCode": "JP",
            "cityCode": "SHI",
            "cityName": "Shimojishima",
            "name": "[SHI] - Shimojishima - SHI - Shimojishima - Japan",
            "searchName": "SHI-Shimojishima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "SHB",
            "countryCode": "JP",
            "cityCode": "SHB",
            "cityName": "Nakashibetsu",
            "name": "[SHB] - Nakashibetsu - SHB - Nakashibetsu - Japan",
            "searchName": "SHB-Nakashibetsu-Nakashibetsu Havalimani-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "TSJ",
            "countryCode": "JP",
            "cityCode": "TSJ",
            "cityName": "Tsushima",
            "name": "[TSJ] - Tsushima - TSJ - Tsushima - Japan",
            "searchName": "TSJ-Tsushima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "TTJ",
            "countryCode": "JP",
            "cityCode": "TTJ",
            "cityName": "Tottori",
            "name": "[TTJ] - Tottori - TTJ - Tottori - Japan",
            "searchName": "TTJ-Tottori-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "TRA",
            "countryCode": "JP",
            "cityCode": "TRA",
            "cityName": "Taramajima",
            "name": "[TRA] - Tarama - TRA - Taramajima - Japan",
            "searchName": "TRA-Tarama-Taramajima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "OKO",
            "countryCode": "JP",
            "cityCode": "TYO",
            "cityName": "Tokyo",
            "name": "[OKO] - Yokota AFB - TYO - Tokyo - Japan",
            "searchName": "OKO-TYO-Yokota AFB-Tokio-Tokyo-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "VBS",
            "countryCode": "IT",
            "cityCode": "VRN",
            "cityName": "Verona",
            "name": "[VBS] - Brescia Montichiari - VRN - Verona - Italy",
            "searchName": "VBS-VRN-Montichiari-Brescia Montichiari-Brescia-Verona-V-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "XIX",
            "countryCode": "IT",
            "cityCode": "VRN",
            "cityName": "Verona",
            "name": "[XIX] - Porta Nuova Railway - VRN - Verona - Italy",
            "searchName": "XIX-VRN-Porta Nuova Railway-Verona-V-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZAK",
            "countryCode": "IT",
            "cityCode": "ZAK",
            "cityName": "Chiusa/Klausen",
            "name": "[ZAK] - Chiusa/Klausen Bus Station - ZAK - Chiusa/Klausen - Italy",
            "searchName": "ZAK-Chiusa/Klausen Bus Station-Chiusa/Klausen-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZIE",
            "countryCode": "IT",
            "cityCode": "ZIE",
            "cityName": "Vulcano",
            "name": "[ZIE] - Harbour (Eolie Island) - ZIE - Vulcano - Italy",
            "searchName": "ZIE-Harbour (Eolie Island)-Vulcano-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZJX",
            "countryCode": "IT",
            "cityCode": "ZJX",
            "cityName": "Stromboli",
            "name": "[ZJX] - Harbour (Eolie Island) - ZJX - Stromboli - Italy",
            "searchName": "ZJX-Harbour (Eolie Island)-Stromboli-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZJY",
            "countryCode": "IT",
            "cityCode": "ZJY",
            "cityName": "Ponza",
            "name": "[ZJY] - Harbour - ZJY - Ponza - Italy",
            "searchName": "ZJY-Harbour-Ponza-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZIP",
            "countryCode": "IT",
            "cityCode": "ZIP",
            "cityName": "Lipari",
            "name": "[ZIP] - Harbour (Eolie Island) - ZIP - Lipari - Italy",
            "searchName": "ZIP-Harbour (Eolie Island)-Lipari-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZIQ",
            "countryCode": "IT",
            "cityCode": "ZIQ",
            "cityName": "Salina Island",
            "name": "[ZIQ] - Harbour (Eolie Island) - ZIQ - Salina Island - Italy",
            "searchName": "ZIQ-Harbour (Eolie Island)-Salina Island-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZJE",
            "countryCode": "IT",
            "cityCode": "ZJE",
            "cityName": "Panarea",
            "name": "[ZJE] - Harbour (Eolie Island) - ZJE - Panarea - Italy",
            "searchName": "ZJE-Harbour (Eolie Island)-Panarea-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZJJ",
            "countryCode": "IT",
            "cityCode": "ZJJ",
            "cityName": "Procida",
            "name": "[ZJJ] - Harbour - ZJJ - Procida - Italy",
            "searchName": "ZJJ-Harbour-Procida-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZMR",
            "countryCode": "IT",
            "cityCode": "ZMR",
            "cityName": "Merano/Meran",
            "name": "[ZMR] - Merano/Meran Bus Station - ZMR - Merano/Meran - Italy",
            "searchName": "ZMR-Merano/Meran Bus Station-Merano/Meran-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZMO",
            "countryCode": "IT",
            "cityCode": "ZMO",
            "cityName": "Modena",
            "name": "[ZMO] - Modena Bus Station - ZMO - Modena - Italy",
            "searchName": "ZMO-Modena Bus Station-Modena-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZRO",
            "countryCode": "IT",
            "cityCode": "ZRO",
            "cityName": "Reggio nell Emilia",
            "name": "[ZRO] - Reggio nellEmilia Bus Station - ZRO - Reggio nell Emilia - Italy",
            "searchName": "ZRO-Reggio nell Emilia Bus Station-Reggio nellEmilia Bus Station-Reggio nell Emilia-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZRR",
            "countryCode": "IT",
            "cityCode": "ZRR",
            "cityName": "Fiumicino",
            "name": "[ZRR] - Harbour - ZRR - Fiumicino - Italy",
            "searchName": "ZRR-Harbour-Fiumicino-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "JER",
            "countryCode": "JE",
            "cityCode": "JER",
            "cityName": "Jersey",
            "name": "[JER] - Jersey - JER - Jersey - Jersey",
            "searchName": "JER-Jersey-States-Channel Islands-British Isles-Großbritannien",
            "priority": 0
        },
        {
            "iataCode": "PSR",
            "countryCode": "IT",
            "cityCode": "PSR",
            "cityName": "Pescara",
            "name": "[PSR] - Liberi - PSR - Pescara - Italy",
            "searchName": "PSR-Pescara-Liberi-Liberi Apt.-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "RAN",
            "countryCode": "IT",
            "cityCode": "RAN",
            "cityName": "Ravenna",
            "name": "[RAN] - La Spreta - RAN - Ravenna - Italy",
            "searchName": "RAN-La Spreta-Ravenna-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "QSR",
            "countryCode": "IT",
            "cityCode": "QSR",
            "cityName": "Salerno",
            "name": "[QSR] - Pontecagnano - QSR - Salerno - Italy",
            "searchName": "QSR-Salerno Pontecagnano-Pontecagnano-Salerno-Salerne-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ISR",
            "countryCode": "IT",
            "cityCode": "QSR",
            "cityName": "Salerno",
            "name": "[ISR] - Salerno Railway Station - QSR - Salerno - Italy",
            "searchName": "ISR-QSR-Salerno Railway Station-Salerno-Salerne-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "PEG",
            "countryCode": "IT",
            "cityCode": "PEG",
            "cityName": "Perugia",
            "name": "[PEG] - Sant Egidio - PEG - Perugia - Italy",
            "searchName": "PEG-Perugia-Sant Egidio-Sant Egidio Apt-P-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "PEJ",
            "countryCode": "IT",
            "cityCode": "PEJ",
            "cityName": "Peschiei",
            "name": "[PEJ] - Peschiei - PEJ - Peschiei - Italy",
            "searchName": "PEJ-Hubschrauberlandeplatz-Peschiei-Peschiei Havalimani-Peschici-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "PNL",
            "countryCode": "IT",
            "cityCode": "PNL",
            "cityName": "Pantelleria",
            "name": "[PNL] - Pantelleria - PNL - Pantelleria - Italy",
            "searchName": "PNL-Pantelleria-Pantelaria-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "PMO",
            "countryCode": "IT",
            "cityCode": "PMO",
            "cityName": "Palermo",
            "name": "[PMO] - Punta Raisi - PMO - Palermo - Italy",
            "searchName": "PMO-Palermo-Punta Raisi-Palerme-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "PRJ",
            "countryCode": "IT",
            "cityCode": "PRJ",
            "cityName": "Capri",
            "name": "[PRJ] - Capri - PRJ - Capri - Italy",
            "searchName": "PRJ-Capri-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "INP",
            "countryCode": "IT",
            "cityCode": "NAP",
            "cityName": "Napoli",
            "name": "[INP] - Naples Central Railway Station - NAP - Napoli - Italy",
            "searchName": "INP-NAP-Naples Central Railway Station-Neapel-Napoli-N-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZMI",
            "countryCode": "IT",
            "cityCode": "NAP",
            "cityName": "Napoli",
            "name": "[ZMI] - Mergellina Railway - NAP - Napoli - Italy",
            "searchName": "ZMI-NAP-Mergellina Railway-Neapel-Napoli-N-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZMJ",
            "countryCode": "IT",
            "cityCode": "NAP",
            "cityName": "Napoli",
            "name": "[ZMJ] - Mergellina Harbour - NAP - Napoli - Italy",
            "searchName": "ZMJ-NAP-Mergellina Harbour-Neapel-Napoli-N-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZMF",
            "countryCode": "IT",
            "cityCode": "NAP",
            "cityName": "Napoli",
            "name": "[ZMF] - Beverello Harbor - NAP - Napoli - Italy",
            "searchName": "ZMF-NAP-Beverello Harbor-Neapel-Napoli-N-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "RRO",
            "countryCode": "IT",
            "cityCode": "RRO",
            "cityName": "Naples",
            "name": "[RRO] - Sorrento - RRO - Naples - Italy",
            "searchName": "RRO-Sorrento-Sorrento Havalimani-Naples-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "XRJ",
            "countryCode": "IT",
            "cityCode": "ROM",
            "cityName": "Roma",
            "name": "[XRJ] - Termini Railway. - ROM - Roma - Italy",
            "searchName": "XRJ-ROM-Termini Railway.-Rom-Rome-Roma-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "NSY",
            "countryCode": "IT",
            "cityCode": "NSY",
            "cityName": "Sigonella",
            "name": "[NSY] - NAS Sigonella - NSY - Sigonella - Italy",
            "searchName": "NSY-Marinefliegerstützpunkt-NAS Sigonella-Base Aérea de Sigonella-Base aérienne de Sigonella-Base aerea di Sigonella-Sigonella-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "TFC",
            "countryCode": "IT",
            "cityCode": "TFC",
            "cityName": "Taormina",
            "name": "[TFC] - Taormina Harbour - TFC - Taormina - Italy",
            "searchName": "TFC-Taormina Harbour-Taormina-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "TAR",
            "countryCode": "IT",
            "cityCode": "TAR",
            "cityName": "Taranto",
            "name": "[TAR] - M. A. Grottag - TAR - Taranto - Italy",
            "searchName": "TAR-M. A. Grottag-Taranto-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "TQR",
            "countryCode": "IT",
            "cityCode": "TQR",
            "cityName": "San Domino Island",
            "name": "[TQR] - San Domino Island - TQR - San Domino Island - Italy",
            "searchName": "TQR-San Domino Island-San Domino Adasi Havalimani-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "TPS",
            "countryCode": "IT",
            "cityCode": "TPS",
            "cityName": "Trapani",
            "name": "[TPS] - Birgi - TPS - Trapani - Italy",
            "searchName": "TPS-Trapani Birgi-Birgi-Trapani-Birgi Apt-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "TRS",
            "countryCode": "IT",
            "cityCode": "TRS",
            "cityName": "Trieste",
            "name": "[TRS] - Ronchi Dei Legionari - TRS - Trieste - Italy",
            "searchName": "TRS-Triest-Ronchi Dei Legionari-Dei Legionari-Ronchi dei Legionari  Apt-Trieste-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "TPY",
            "countryCode": "IT",
            "cityCode": "TRN",
            "cityName": "Turin",
            "name": "[TPY] - Porta Nuova Railway Station - TRN - Turin - Italy",
            "searchName": "TPY-TRN-Porta Nuova Railway Station-Turin-Tur-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ZTC",
            "countryCode": "IT",
            "cityCode": "TRN",
            "cityName": "Turin",
            "name": "[ZTC] - Turin Bus Station - TRN - Turin - Italy",
            "searchName": "ZTC-TRN-Turin Bus Station-Turin-Tur-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "ITT",
            "countryCode": "IT",
            "cityCode": "TRN",
            "cityName": "Turin",
            "name": "[ITT] - Porta Susa Railway Station - TRN - Turin - Italy",
            "searchName": "ITT-TRN-Porta Susa Railway Station-Ittoqqortoormiit-Turin-Tur-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "TTB",
            "countryCode": "IT",
            "cityCode": "TTB",
            "cityName": "Tortoli",
            "name": "[TTB] - Arbatax - TTB - Tortoli - Italy",
            "searchName": "TTB-Arbatax-Tortoli-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "SAY",
            "countryCode": "IT",
            "cityCode": "SAY",
            "cityName": "Siena",
            "name": "[SAY] - Siena - SAY - Siena - Italy",
            "searchName": "SAY-Siena-Siena Havalimani-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "SUF",
            "countryCode": "IT",
            "cityCode": "SUF",
            "cityName": "Lamezia Terme",
            "name": "[SUF] - Lamezia Terme - SUF - Lamezia Terme - Italy",
            "searchName": "SUF-Lamezia Terme-S Eufemia-S Eufemia Apt-Lamezia-Terme-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "LTZ",
            "countryCode": "IT",
            "cityCode": "SUF",
            "cityName": "Lamezia Terme",
            "name": "[LTZ] - Lamezia Terme Central Railway Station - SUF - Lamezia Terme - Italy",
            "searchName": "LTZ-SUF-Lamezia Terme Central Railway Station-Lamezia Terme-Lamezia-Terme-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "VIC",
            "countryCode": "IT",
            "cityCode": "VIC",
            "cityName": "Vicenza",
            "name": "[VIC] - Trissino - VIC - Vicenza - Italy",
            "searchName": "VIC-Trissino-Vicenza Havalimani-Vicenza-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "VNZ",
            "countryCode": "IT",
            "cityCode": "VIC",
            "cityName": "Vicenza",
            "name": "[VNZ] - Vicenza Railway Station - VIC - Vicenza - Italy",
            "searchName": "VNZ-VIC-Vicenza Railway Station-Vicenza-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "VIF",
            "countryCode": "IT",
            "cityCode": "VIF",
            "cityName": "Vieste",
            "name": "[VIF] - Vieste - VIF - Vieste - Italy",
            "searchName": "VIF-Hubschrauberlandeplatz-Vieste-Vieste Havalimani-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "IXN",
            "countryCode": "IN",
            "cityCode": "IXN",
            "cityName": "Khowai",
            "name": "[IXN] - Khowai - IXN - Khowai - India",
            "searchName": "IXN-Khowai-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXP",
            "countryCode": "IN",
            "cityCode": "IXP",
            "cityName": "Pathankot",
            "name": "[IXP] - Pathankot - IXP - Pathankot - India",
            "searchName": "IXP-Pathankot-Pathankot Havalimani-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXQ",
            "countryCode": "IN",
            "cityCode": "IXQ",
            "cityName": "Kamalpur",
            "name": "[IXQ] - Kamalpur - IXQ - Kamalpur - India",
            "searchName": "IXQ-Kamalpur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXR",
            "countryCode": "IN",
            "cityCode": "IXR",
            "cityName": "Ranchi",
            "name": "[IXR] - Birsa Munda International - IXR - Ranchi - India",
            "searchName": "IXR-Ranchi-Birsa Munda International-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXS",
            "countryCode": "IN",
            "cityCode": "IXS",
            "cityName": "Silchar",
            "name": "[IXS] - Kumbhirgram - IXS - Silchar - India",
            "searchName": "IXS-Silchar-Kumbhirgram-Silchan-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXT",
            "countryCode": "IN",
            "cityCode": "IXT",
            "cityName": "Pasighat",
            "name": "[IXT] - Pasighat - IXT - Pasighat - India",
            "searchName": "IXT-Pasighat-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXU",
            "countryCode": "IN",
            "cityCode": "IXU",
            "cityName": "Aurangabad",
            "name": "[IXU] - Chikkalthana - IXU - Aurangabad - India",
            "searchName": "IXU-Aurangabad-Chikkalthana-Aurang-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXV",
            "countryCode": "IN",
            "cityCode": "IXV",
            "cityName": "Along",
            "name": "[IXV] - Along - IXV - Along - India",
            "searchName": "IXV-Along-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXW",
            "countryCode": "IN",
            "cityCode": "IXW",
            "cityName": "Jamshedpur",
            "name": "[IXW] - Sonari - IXW - Jamshedpur - India",
            "searchName": "IXW-Sonari-Sonari Havalimani-Jamshedpur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXY",
            "countryCode": "IN",
            "cityCode": "IXY",
            "cityName": "Kandla",
            "name": "[IXY] - Kandla - IXY - Kandla - India",
            "searchName": "IXY-Kandla/Gandhidham-Kandla-Kandla Havalimani-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXZ",
            "countryCode": "IN",
            "cityCode": "IXZ",
            "cityName": "Port Blair",
            "name": "[IXZ] - Port Blair - IXZ - Port Blair - India",
            "searchName": "IXZ-Port Blair-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IMF",
            "countryCode": "IN",
            "cityCode": "IMF",
            "cityName": "Imphal",
            "name": "[IMF] - Municipal - IMF - Imphal - India",
            "searchName": "IMF-Imphal-Municipal-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IDR",
            "countryCode": "IN",
            "cityCode": "IDR",
            "cityName": "Indore",
            "name": "[IDR] - Devi Ahilyabai Holkar - IDR - Indore - India",
            "searchName": "IDR-Indore-Devi Ahilyabai Holkar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "HBX",
            "countryCode": "IN",
            "cityCode": "HBX",
            "cityName": "Hubli",
            "name": "[HBX] - Hubli - HBX - Hubli - India",
            "searchName": "HBX-Hubli-Hubli Airport-Dharwad-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "HJR",
            "countryCode": "IN",
            "cityCode": "HJR",
            "cityName": "Khajuraho",
            "name": "[HJR] - Khajuraho - HJR - Khajuraho - India",
            "searchName": "HJR-Khajuraho-Khajur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "HYD",
            "countryCode": "IN",
            "cityCode": "HYD",
            "cityName": "Hyderabad",
            "name": "[HYD] - Hyderabad Airport - HYD - Hyderabad - India",
            "searchName": "HYD-Hyderabad Indien-Hyderabad Airport-Rajiv Gandhi Intl-Hyderabad-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "BPM",
            "countryCode": "IN",
            "cityCode": "HYD",
            "cityName": "Hyderabad",
            "name": "[BPM] - Begumpet - HYD - Hyderabad - India",
            "searchName": "BPM-HYD-Begumpet-Hyderabad-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "HSS",
            "countryCode": "IN",
            "cityCode": "HSS",
            "cityName": "Hissar",
            "name": "[HSS] - Hissar - HSS - Hissar - India",
            "searchName": "HSS-Hissar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "GWL",
            "countryCode": "IN",
            "cityCode": "GWL",
            "cityName": "Gwalior",
            "name": "[GWL] - Gwalior - GWL - Gwalior - India",
            "searchName": "GWL-Gwalior-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "GUX",
            "countryCode": "IN",
            "cityCode": "GUX",
            "cityName": "Guna",
            "name": "[GUX] - Guna - GUX - Guna - India",
            "searchName": "GUX-Guna-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "SDV",
            "countryCode": "IL",
            "cityCode": "TLV",
            "cityName": "Tel Aviv Yafo",
            "name": "[SDV] - Sde Dov - TLV - Tel Aviv Yafo - Israel",
            "searchName": "SDV-TLV-Tel Aviv Sde Dov-Sde Dov-Tel Aviv Yafo-Tel Aviv-Yafo-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "VTM",
            "countryCode": "IL",
            "cityCode": "VTM",
            "cityName": "Nevatim",
            "name": "[VTM] - Nevatim AFB - VTM - Nevatim - Israel",
            "searchName": "VTM-Nevatim AFB-Nevatim-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "VDA",
            "countryCode": "IL",
            "cityCode": "VDA",
            "cityName": "Ovda",
            "name": "[VDA] - Ovda - VDA - Ovda - Israel",
            "searchName": "VDA-Ovda-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "MTZ",
            "countryCode": "IL",
            "cityCode": "MTZ",
            "cityName": "Masada",
            "name": "[MTZ] - Masada - MTZ - Masada - Israel",
            "searchName": "MTZ-Masada-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "MIP",
            "countryCode": "IL",
            "cityCode": "MIP",
            "cityName": "Mitspeh Ramon",
            "name": "[MIP] - Mitspeh Ramon - MIP - Mitspeh Ramon - Israel",
            "searchName": "MIP-Mitspeh Ramon-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "RPN",
            "countryCode": "IL",
            "cityCode": "RPN",
            "cityName": "Rosh Pina",
            "name": "[RPN] - Rosh Pina - RPN - Rosh Pina - Israel",
            "searchName": "RPN-Rosh Pina-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "YOT",
            "countryCode": "IL",
            "cityCode": "YOT",
            "cityName": "Yotvata",
            "name": "[YOT] - Yotvata - YOT - Yotvata - Israel",
            "searchName": "YOT-Yotvata-Yotvata Havalimani-Israel-Izrael-Israil-Izraela-Israël-Iisrael-Israele-",
            "priority": 0
        },
        {
            "iataCode": "IOM",
            "countryCode": "IM",
            "cityCode": "IOM",
            "cityName": "Isle Of Man",
            "name": "[IOM] - Ronaldsway - IOM - Isle Of Man - Isle of Man",
            "searchName": "IOM-Isle of Man-Ronaldsway-Ronaldsway Apt-Isle Of Man-Ile de Man-Großbritannien-Birlesik Krallik-Isla de Man-Île de Man-Isola di Man",
            "priority": 0
        },
        {
            "iataCode": "ISK",
            "countryCode": "IN",
            "cityCode": "ISK",
            "cityName": "Nasik",
            "name": "[ISK] - Gandhinagar Airport - ISK - Nasik - India",
            "searchName": "ISK-Gandhinagar Airport-Nasik-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "BLY",
            "countryCode": "IE",
            "cityCode": "BLY",
            "cityName": "Belmullet",
            "name": "[BLY] - Belmullet - BLY - Belmullet - Ireland",
            "searchName": "BLY-Belmullet-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "BYT",
            "countryCode": "IE",
            "cityCode": "BYT",
            "cityName": "Bantry",
            "name": "[BYT] - Bantry - BYT - Bantry - Ireland",
            "searchName": "BYT-Bantry-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "LTR",
            "countryCode": "IE",
            "cityCode": "LTR",
            "cityName": "Letterkenny",
            "name": "[LTR] - Letterkenny - LTR - Letterkenny - Ireland",
            "searchName": "LTR-Letterkenny-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "KKY",
            "countryCode": "IE",
            "cityCode": "KKY",
            "cityName": "Kilkenny",
            "name": "[KKY] - Kilkenny - KKY - Kilkenny - Ireland",
            "searchName": "KKY-Kilkenny-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "KIR",
            "countryCode": "IE",
            "cityCode": "KIR",
            "cityName": "Farranfore",
            "name": "[KIR] - Kerry - KIR - Farranfore - Ireland",
            "searchName": "KIR-Kerry-Kerry County Airport-Farranfore Havalimani-Kerry County-Farranfore-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "GWY",
            "countryCode": "IE",
            "cityCode": "GWY",
            "cityName": "Galway",
            "name": "[GWY] - Carnmore - GWY - Galway - Ireland",
            "searchName": "GWY-Carnmore-Galway-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "IIA",
            "countryCode": "IE",
            "cityCode": "IIA",
            "cityName": "Galloway",
            "name": "[IIA] - Inishmaan - IIA - Galloway - Ireland",
            "searchName": "IIA-Inishmaan-Galloway-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "INQ",
            "countryCode": "IE",
            "cityCode": "INQ",
            "cityName": "Inisheer",
            "name": "[INQ] - Inisheer - INQ - Inisheer - Ireland",
            "searchName": "INQ-Inisheer-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "IOR",
            "countryCode": "IE",
            "cityCode": "IOR",
            "cityName": "Inishmore",
            "name": "[IOR] - Kilronan - IOR - Inishmore - Ireland",
            "searchName": "IOR-Kilronan-Inishmore-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "JEE",
            "countryCode": "HT",
            "cityCode": "JEE",
            "cityName": "Jeremie",
            "name": "[JEE] - Jeremie - JEE - Jeremie - Haiti",
            "searchName": "JEE-Jeremie-Haiti-Haïti-Haití",
            "priority": 0
        },
        {
            "iataCode": "JAK",
            "countryCode": "HT",
            "cityCode": "JAK",
            "cityName": "Jacmel",
            "name": "[JAK] - Jacmel Regional Airport - JAK - Jacmel - Haiti",
            "searchName": "JAK-Jacmel Regional Airport-Jacmel-Haiti-Haïti-Haití",
            "priority": 0
        },
        {
            "iataCode": "CAP",
            "countryCode": "HT",
            "cityCode": "CAP",
            "cityName": "Cap Haitien",
            "name": "[CAP] - Cap Haitien - CAP - Cap Haitien - Haiti",
            "searchName": "CAP-Cap Haitien-Haiti-Haïti-Haití",
            "priority": 0
        },
        {
            "iataCode": "CYA",
            "countryCode": "HT",
            "cityCode": "CYA",
            "cityName": "Les Cayes",
            "name": "[CYA] - Les Cayes - CYA - Les Cayes - Haiti",
            "searchName": "CYA-Les Cayes-Haiti-Haïti-Haití",
            "priority": 0
        },
        {
            "iataCode": "PAP",
            "countryCode": "HT",
            "cityCode": "PAP",
            "cityName": "Port Au Prince",
            "name": "[PAP] - Toussaint Louverture International - PAP - Port Au Prince - Haiti",
            "searchName": "PAP-Port au Prince-Toussaint Louverture International-Mais Gate-Port Au Prince-Haiti-Haïti-Haití",
            "priority": 0
        },
        {
            "iataCode": "PAX",
            "countryCode": "HT",
            "cityCode": "PAX",
            "cityName": "Port De Paix",
            "name": "[PAX] - Port De Paix - PAX - Port De Paix - Haiti",
            "searchName": "PAX-Port De Paix-Port de Paix-Haiti-Haïti-Haití",
            "priority": 0
        },
        {
            "iataCode": "PEV",
            "countryCode": "HU",
            "cityCode": "PEV",
            "cityName": "Pecs",
            "name": "[PEV] - Pecs Pogany - PEV - Pecs - Hungary",
            "searchName": "PEV-Pecs Pogany-Pecs Havalimani-Pecs/Pecs-Pecs-Hongarije-Hungary-Hung ga ry-Ungern-Wegry-Hongaria-Hungria-Ungarn-Hungría-Ungheria-Vengrija-Ungarija-Madarska-Ungar-Macaristan-Hongrie",
            "priority": 0
        },
        {
            "iataCode": "QGY",
            "countryCode": "HU",
            "cityCode": "QGY",
            "cityName": "Per",
            "name": "[QGY] - Gyor-Per - QGY - Per - Hungary",
            "searchName": "QGY-Gyor-Per-Hongarije-Hungary-Hung ga ry-Ungern-Wegry-Hongaria-Hungria-Ungarn-Hungría-Ungheria-Vengrija-Ungarija-Madarska-Ungar-Macaristan-Hongrie",
            "priority": 0
        },
        {
            "iataCode": "QZD",
            "countryCode": "HU",
            "cityCode": "QZD",
            "cityName": "Szeged",
            "name": "[QZD] - Szeged - QZD - Szeged - Hungary",
            "searchName": "QZD-Szeged Airport-Szeged-Szeged/Szeged-Hongarije-Hungary-Hung ga ry-Ungern-Wegry-Hongaria-Hungria-Ungarn-Hungría-Ungheria-Vengrija-Ungarija-Madarska-Ungar-Macaristan-Hongrie",
            "priority": 0
        },
        {
            "iataCode": "MCQ",
            "countryCode": "HU",
            "cityCode": "MCQ",
            "cityName": "Miskolc",
            "name": "[MCQ] - Miskolc - MCQ - Miskolc - Hungary",
            "searchName": "MCQ-Miskolc-Miskolc Havalimani-Hongarije-Hungary-Hung ga ry-Ungern-Wegry-Hongaria-Hungria-Ungarn-Hungría-Ungheria-Vengrija-Ungarija-Madarska-Ungar-Macaristan-Hongrie",
            "priority": 0
        },
        {
            "iataCode": "SOB",
            "countryCode": "HU",
            "cityCode": "SOB",
            "cityName": "Sármellék",
            "name": "[SOB] - SÃ¡rmellÃ©k/Balaton - SOB - Sármellék - Hungary",
            "searchName": "SOB-Saarmelleek\\/Balaton-Sármellék/Balaton-Saarmelleek/Balaton-Saarmelleek Balaton Havalimani-SÃ¡rmellÃ©k/Balaton-Sarmellek-S-Sármellék-Hongarije-Hungary-Hung ga ry-Ungern-Wegry-Hongaria-Hungria-Ungarn-Hungría-Ungheria-Vengrija-Ungarija-Madarska-Ungar-Macaristan-Hongrie",
            "priority": 0
        },
        {
            "iataCode": "DEB",
            "countryCode": "HU",
            "cityCode": "DEB",
            "cityName": "Debrecen",
            "name": "[DEB] - Debrecen - DEB - Debrecen - Hungary",
            "searchName": "DEB-Debrecen-Debrecen Havalimani-Hongarije-Hungary-Hung ga ry-Ungern-Wegry-Hongaria-Hungria-Ungarn-Hungría-Ungheria-Vengrija-Ungarija-Madarska-Ungar-Macaristan-Hongrie",
            "priority": 0
        },
        {
            "iataCode": "GSJ",
            "countryCode": "GT",
            "cityCode": "GSJ",
            "cityName": "Puerto San Jose",
            "name": "[GSJ] - Puerto San Jose - GSJ - Puerto San Jose - Guatemala",
            "searchName": "GSJ-Puerto San Jose-Guatemala",
            "priority": 0
        },
        {
            "iataCode": "LSZ",
            "countryCode": "HR",
            "cityCode": "LSZ",
            "cityName": "Mali Losinj",
            "name": "[LSZ] - Losinj Airport - LSZ - Mali Losinj - Croatia",
            "searchName": "LSZ-Losinj Airport-Mali Losinj-Kroatië-Kroatia-Chorvatsko-Hirvatistan-Croàcia-Horvátország-Croatia-Hrvaška-Kroatija-Horvatija-Hrvatska-Horvaatia-Chorwacja-Kroasia-Kroatien-Croatie-Croacia-Croazia",
            "priority": 0
        },
        {
            "iataCode": "BWK",
            "countryCode": "HR",
            "cityCode": "BWK",
            "cityName": "Bol",
            "name": "[BWK] - Brac - BWK - Bol - Croatia",
            "searchName": "BWK-Bol Kroatien-Brac-Bol-Kroatië-Kroatia-Chorvatsko-Hirvatistan-Croàcia-Horvátország-Croatia-Hrvaška-Kroatija-Horvatija-Hrvatska-Horvaatia-Chorwacja-Kroasia-Kroatien-Croatie-Croacia-Croazia",
            "priority": 0
        },
        {
            "iataCode": "NNR",
            "countryCode": "IE",
            "cityCode": "NNR",
            "cityName": "Spiddal",
            "name": "[NNR] - Connemara - NNR - Spiddal - Ireland",
            "searchName": "NNR-Connemara-Connemara Havalimani-Spiddal-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "NOC",
            "countryCode": "IE",
            "cityCode": "NOC",
            "cityName": "Knock",
            "name": "[NOC] - Ireland West Airport Knock - NOC - Knock - Ireland",
            "searchName": "NOC-Knock-Ireland West Airport Knock-Knock International-Knock Int. Apt-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "WAT",
            "countryCode": "IE",
            "cityCode": "WAT",
            "cityName": "Waterford",
            "name": "[WAT] - Waterford - WAT - Waterford - Ireland",
            "searchName": "WAT-Waterford-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "SXL",
            "countryCode": "IE",
            "cityCode": "SXL",
            "cityName": "Sligo",
            "name": "[SXL] - Sligo - SXL - Sligo - Ireland",
            "searchName": "SXL-Collooney-Sligo-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "ZSL",
            "countryCode": "IE",
            "cityCode": "SXL",
            "cityName": "Sligo",
            "name": "[ZSL] - Sligo Bus Station - SXL - Sligo - Ireland",
            "searchName": "ZSL-SXL-Sligo Bus Station-Sligo-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "SNN",
            "countryCode": "IE",
            "cityCode": "SNN",
            "cityName": "Shannon",
            "name": "[SNN] - Shannon - SNN - Shannon - Ireland",
            "searchName": "SNN-Shannon-Shannon Int. Apt-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "CFN",
            "countryCode": "IE",
            "cityCode": "CFN",
            "cityName": "Carrickfinn",
            "name": "[CFN] - Donegal - CFN - Carrickfinn - Ireland",
            "searchName": "CFN-Donegal Carrickfinn-Donegal-Carrickfinn-Irska-Irlanda-Írország-Irland-Airija-Irija-Irlande-Irlandia-Ireland-Iirimaa-Irsko-Irlanti",
            "priority": 0
        },
        {
            "iataCode": "HVZ",
            "countryCode": "HU",
            "cityCode": "HVZ",
            "cityName": "Heviz",
            "name": "[HVZ] - MA Bus Station - HVZ - Heviz - Hungary",
            "searchName": "HVZ-MA Bus Station-Heviz-Hongarije-Hungary-Hung ga ry-Ungern-Wegry-Hongaria-Hungria-Ungarn-Hungría-Ungheria-Vengrija-Ungarija-Madarska-Ungar-Macaristan-Hongrie",
            "priority": 0
        },
        {
            "iataCode": "IUL",
            "countryCode": "ID",
            "cityCode": "IUL",
            "cityName": "Ilu",
            "name": "[IUL] - Ilu - IUL - Ilu - Indonesia",
            "searchName": "IUL-Ilu-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "INX",
            "countryCode": "ID",
            "cityCode": "INX",
            "cityName": "Inanwatan",
            "name": "[INX] - Inanwatan - INX - Inanwatan - Indonesia",
            "searchName": "INX-Inanwatan-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ILA",
            "countryCode": "ID",
            "cityCode": "ILA",
            "cityName": "Illaga",
            "name": "[ILA] - Illaga - ILA - Illaga - Indonesia",
            "searchName": "ILA-Illaga-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "GTO",
            "countryCode": "ID",
            "cityCode": "GTO",
            "cityName": "Gorontalo",
            "name": "[GTO] - Tolotio - GTO - Gorontalo - Indonesia",
            "searchName": "GTO-Gorontalo-Tolotio-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "GNS",
            "countryCode": "ID",
            "cityCode": "GNS",
            "cityName": "Gunungsitoli",
            "name": "[GNS] - Binaka - GNS - Gunungsitoli - Indonesia",
            "searchName": "GNS-Gunungsitoli-Binaka-Binaka Havalimani-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "GLX",
            "countryCode": "ID",
            "cityCode": "GLX",
            "cityName": "Galela",
            "name": "[GLX] - Galela - GLX - Galela - Indonesia",
            "searchName": "GLX-Galela-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "GEB",
            "countryCode": "ID",
            "cityCode": "GEB",
            "cityName": "Gebe",
            "name": "[GEB] - Gebe - GEB - Gebe - Indonesia",
            "searchName": "GEB-Gebe-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "GAV",
            "countryCode": "ID",
            "cityCode": "GAV",
            "cityName": "Gag Island",
            "name": "[GAV] - Gag Island - GAV - Gag Island - Indonesia",
            "searchName": "GAV-Gag Island-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "FKQ",
            "countryCode": "ID",
            "cityCode": "FKQ",
            "cityName": "Fak Fak",
            "name": "[FKQ] - Fak Fak - FKQ - Fak Fak - Indonesia",
            "searchName": "FKQ-FakFak-Fak Fak-Fakfak-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "FOO",
            "countryCode": "ID",
            "cityCode": "FOO",
            "cityName": "Numfoor",
            "name": "[FOO] - Numfoor - FOO - Numfoor - Indonesia",
            "searchName": "FOO-Numfoor-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "HLP",
            "countryCode": "ID",
            "cityCode": "JKT",
            "cityName": "Jakarta",
            "name": "[HLP] - Halim Perdana Kusuma - JKT - Jakarta - Indonesia",
            "searchName": "HLP-JKT-Halim Perdana Kusuma-Halim Perdana Kusuma Havalimani-Jakarta-Yakarta-Giakarta-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "DRH",
            "countryCode": "ID",
            "cityCode": "DRH",
            "cityName": "Dabra",
            "name": "[DRH] - Dabra - DRH - Dabra - Indonesia",
            "searchName": "DRH-Dabra-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "DUM",
            "countryCode": "ID",
            "cityCode": "DUM",
            "cityName": "Dumai",
            "name": "[DUM] - Pinang Kampai - DUM - Dumai - Indonesia",
            "searchName": "DUM-Pinang Kampai-Dumai-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "DTD",
            "countryCode": "ID",
            "cityCode": "DTD",
            "cityName": "Datadawai",
            "name": "[DTD] - Datadawai - DTD - Datadawai - Indonesia",
            "searchName": "DTD-Datadawai-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ENE",
            "countryCode": "ID",
            "cityCode": "ENE",
            "cityName": "Ende",
            "name": "[ENE] - Ende - ENE - Ende - Indonesia",
            "searchName": "ENE-Ende-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ELR",
            "countryCode": "ID",
            "cityCode": "ELR",
            "cityName": "Elelim",
            "name": "[ELR] - Elelim - ELR - Elelim - Indonesia",
            "searchName": "ELR-Elelim-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "EWE",
            "countryCode": "ID",
            "cityCode": "EWE",
            "cityName": "Ewer",
            "name": "[EWE] - Ewer - EWE - Ewer - Indonesia",
            "searchName": "EWE-Ewer-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "EWI",
            "countryCode": "ID",
            "cityCode": "EWI",
            "cityName": "Enarotali",
            "name": "[EWI] - Enarotali - EWI - Enarotali - Indonesia",
            "searchName": "EWI-Enarotali-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "CXP",
            "countryCode": "ID",
            "cityCode": "CXP",
            "cityName": "Cilacap",
            "name": "[CXP] - Tunggul Wulung - CXP - Cilacap - Indonesia",
            "searchName": "CXP-Tunggul Wulung-Tunggul Wulung Havalimani-Cilacap-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "CPF",
            "countryCode": "ID",
            "cityCode": "CPF",
            "cityName": "Cepu",
            "name": "[CPF] - Cepu - CPF - Cepu - Indonesia",
            "searchName": "CPF-Cepu-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "CBN",
            "countryCode": "ID",
            "cityCode": "CBN",
            "cityName": "Cirebon",
            "name": "[CBN] - Penggung - CBN - Cirebon - Indonesia",
            "searchName": "CBN-Penggung-Penggung Havalimani-Cirebon-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SOC",
            "countryCode": "ID",
            "cityCode": "SOC",
            "cityName": "Solo City",
            "name": "[SOC] - Adi Sumarmo - SOC - Solo City - Indonesia",
            "searchName": "SOC-Solo City-Adi Sumarmo-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SMQ",
            "countryCode": "ID",
            "cityCode": "SMQ",
            "cityName": "Sampit",
            "name": "[SMQ] - Sampit - SMQ - Sampit - Indonesia",
            "searchName": "SMQ-Sampit-Sampit\u00A0 Havalimani-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SQG",
            "countryCode": "ID",
            "cityCode": "SQG",
            "cityName": "Sintang",
            "name": "[SQG] - Sintang - SQG - Sintang - Indonesia",
            "searchName": "SQG-Sintang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SQN",
            "countryCode": "ID",
            "cityCode": "SQN",
            "cityName": "Sanana",
            "name": "[SQN] - Sanana - SQN - Sanana - Indonesia",
            "searchName": "SQN-Sanana-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SQR",
            "countryCode": "ID",
            "cityCode": "SQR",
            "cityName": "Soroako",
            "name": "[SQR] - Inco Soroako Waws - SQR - Soroako - Indonesia",
            "searchName": "SQR-Inco Soroako Waws-Soroako-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SOQ",
            "countryCode": "ID",
            "cityCode": "SOQ",
            "cityName": "Sorong",
            "name": "[SOQ] - Jefman - SOQ - Sorong - Indonesia",
            "searchName": "SOQ-Jefman-Domine Edward Osok Havalimani-Sorong-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SRI",
            "countryCode": "ID",
            "cityCode": "SRI",
            "cityName": "Samarinda",
            "name": "[SRI] - Samarinda - SRI - Samarinda - Indonesia",
            "searchName": "SRI-Samarinda-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SRG",
            "countryCode": "ID",
            "cityCode": "SRG",
            "cityName": "Semarang",
            "name": "[SRG] - Achmad Yani - SRG - Semarang - Indonesia",
            "searchName": "SRG-Semarang-Achmad Yani-Achmad Uani-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SUB",
            "countryCode": "ID",
            "cityCode": "SUB",
            "cityName": "Surabaya",
            "name": "[SUB] - Juanda - SUB - Surabaya - Indonesia",
            "searchName": "SUB-Surabaya-Juanda-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SUP",
            "countryCode": "ID",
            "cityCode": "SUP",
            "cityName": "Sumenep",
            "name": "[SUP] - Trunojoyo - SUP - Sumenep - Indonesia",
            "searchName": "SUP-Trunojoyo-Sumenep-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SWQ",
            "countryCode": "ID",
            "cityCode": "SWQ",
            "cityName": "Sumbawa",
            "name": "[SWQ] - Brang Bidji - SWQ - Sumbawa - Indonesia",
            "searchName": "SWQ-Brang Bidji-Sumbawa-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SBG",
            "countryCode": "ID",
            "cityCode": "SBG",
            "cityName": "Sabang",
            "name": "[SBG] - Maimun Saleh - SBG - Sabang - Indonesia",
            "searchName": "SBG-Maimun Saleh-Sabang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SAU",
            "countryCode": "ID",
            "cityCode": "SAU",
            "cityName": "Sawu",
            "name": "[SAU] - Sawu - SAU - Sawu - Indonesia",
            "searchName": "SAU-Sawu-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SEH",
            "countryCode": "ID",
            "cityCode": "SEH",
            "cityName": "Senggeh",
            "name": "[SEH] - Senggeh - SEH - Senggeh - Indonesia",
            "searchName": "SEH-Senggeh-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SEQ",
            "countryCode": "ID",
            "cityCode": "SEQ",
            "cityName": "Sungai Pakning",
            "name": "[SEQ] - Sungai Pakning - SEQ - Sungai Pakning - Indonesia",
            "searchName": "SEQ-Sungai Pakning-Sungaipinang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SIW",
            "countryCode": "ID",
            "cityCode": "SIW",
            "cityName": "Sibisa",
            "name": "[SIW] - Sibisa - SIW - Sibisa - Indonesia",
            "searchName": "SIW-Sibisa-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SGQ",
            "countryCode": "ID",
            "cityCode": "SGQ",
            "cityName": "Sanggata",
            "name": "[SGQ] - Sanggata - SGQ - Sanggata - Indonesia",
            "searchName": "SGQ-Sanggata-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SIQ",
            "countryCode": "ID",
            "cityCode": "SIQ",
            "cityName": "Singkep",
            "name": "[SIQ] - Dabo - SIQ - Singkep - Indonesia",
            "searchName": "SIQ-Dabo-Singkep Island-Singkep-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TAX",
            "countryCode": "ID",
            "cityCode": "TAX",
            "cityName": "Taliabu",
            "name": "[TAX] - Taliabu - TAX - Taliabu - Indonesia",
            "searchName": "TAX-Taliabu-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TBM",
            "countryCode": "ID",
            "cityCode": "TBM",
            "cityName": "Tumbang Samba",
            "name": "[TBM] - Tumbang Samba - TBM - Tumbang Samba - Indonesia",
            "searchName": "TBM-Tumbang Samba-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SZH",
            "countryCode": "ID",
            "cityCode": "SZH",
            "cityName": "Senipah",
            "name": "[SZH] - Senipah - SZH - Senipah - Indonesia",
            "searchName": "SZH-Senipah-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SXK",
            "countryCode": "ID",
            "cityCode": "SXK",
            "cityName": "Saumlaki",
            "name": "[SXK] - Saumlaki - SXK - Saumlaki - Indonesia",
            "searchName": "SXK-Saumlaki-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TJB",
            "countryCode": "ID",
            "cityCode": "TJB",
            "cityName": "Tanjung Balai",
            "name": "[TJB] - Tanjung Balai - TJB - Tanjung Balai - Indonesia",
            "searchName": "TJB-Tanjung Balai-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TJG",
            "countryCode": "ID",
            "cityCode": "TJG",
            "cityName": "Tanjung Warukin",
            "name": "[TJG] - Tanjung Warukin - TJG - Tanjung Warukin - Indonesia",
            "searchName": "TJG-Tanjung Warukin-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TJQ",
            "countryCode": "ID",
            "cityCode": "TJQ",
            "cityName": "Tanjung Pandan",
            "name": "[TJQ] - Bulutumbang - TJQ - Tanjung Pandan - Indonesia",
            "searchName": "TJQ-Bulutumbang-Tanjung Pandan-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TJS",
            "countryCode": "ID",
            "cityCode": "TJS",
            "cityName": "Tanjung Selor",
            "name": "[TJS] - Tanjung Selor - TJS - Tanjung Selor - Indonesia",
            "searchName": "TJS-Tanjung Selor-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TKG",
            "countryCode": "ID",
            "cityCode": "TKG",
            "cityName": "Bandar Lampung",
            "name": "[TKG] - Branti - TKG - Bandar Lampung - Indonesia",
            "searchName": "TKG-Bandar Lampung-Branti-Radin Inten II-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TIM",
            "countryCode": "ID",
            "cityCode": "TIM",
            "cityName": "Tembagapura",
            "name": "[TIM] - Timika - TIM - Tembagapura - Indonesia",
            "searchName": "TIM-Timika-Tembagapura-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TLI",
            "countryCode": "ID",
            "cityCode": "TLI",
            "cityName": "Tolitoli",
            "name": "[TLI] - Tolitoli - TLI - Tolitoli - Indonesia",
            "searchName": "TLI-Tolitoli-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TMH",
            "countryCode": "ID",
            "cityCode": "TMH",
            "cityName": "Tanahmerah",
            "name": "[TMH] - Tanahmerah - TMH - Tanahmerah - Indonesia",
            "searchName": "TMH-Tanahmerah-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TMC",
            "countryCode": "ID",
            "cityCode": "TMC",
            "cityName": "Tambolaka",
            "name": "[TMC] - Tjilik Riwut - TMC - Tambolaka - Indonesia",
            "searchName": "TMC-Tambolaka-Tjilik Riwut-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TNB",
            "countryCode": "ID",
            "cityCode": "TNB",
            "cityName": "Tanah Grogot",
            "name": "[TNB] - Tanah Grogot - TNB - Tanah Grogot - Indonesia",
            "searchName": "TNB-Tanah Grogot-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TMY",
            "countryCode": "ID",
            "cityCode": "TMY",
            "cityName": "Tiom",
            "name": "[TMY] - Tiom - TMY - Tiom - Indonesia",
            "searchName": "TMY-Tiom-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TPK",
            "countryCode": "ID",
            "cityCode": "TPK",
            "cityName": "Tapaktuan",
            "name": "[TPK] - Tapaktuan - TPK - Tapaktuan - Indonesia",
            "searchName": "TPK-Tapaktuan-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TNJ",
            "countryCode": "ID",
            "cityCode": "TNJ",
            "cityName": "Tanjung Pinang",
            "name": "[TNJ] - Kidjang - TNJ - Tanjung Pinang - Indonesia",
            "searchName": "TNJ-Kidjang-Raja Haji Fisabilillah Havalimani-Tanjung Pinang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TRK",
            "countryCode": "ID",
            "cityCode": "TRK",
            "cityName": "Tarakan",
            "name": "[TRK] - Juwata - TRK - Tarakan - Indonesia",
            "searchName": "TRK-Tarakan-Juwata-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TTE",
            "countryCode": "ID",
            "cityCode": "TTE",
            "cityName": "Ternate",
            "name": "[TTE] - Babullah - TTE - Ternate - Indonesia",
            "searchName": "TTE-Babullah-Ternate-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TTR",
            "countryCode": "ID",
            "cityCode": "TTR",
            "cityName": "Tana Toraja",
            "name": "[TTR] - Tana Toraja - TTR - Tana Toraja - Indonesia",
            "searchName": "TTR-Tana Toraja-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TSX",
            "countryCode": "ID",
            "cityCode": "TSX",
            "cityName": "Tanjung Santan",
            "name": "[TSX] - Tanjung Santan - TSX - Tanjung Santan - Indonesia",
            "searchName": "TSX-Tanjung Santan-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TSY",
            "countryCode": "ID",
            "cityCode": "TSY",
            "cityName": "Tasikmalaya",
            "name": "[TSY] - Tasikmalaya - TSY - Tasikmalaya - Indonesia",
            "searchName": "TSY-Tasikmalaya-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "TXM",
            "countryCode": "ID",
            "cityCode": "TXM",
            "cityName": "Teminabuan",
            "name": "[TXM] - Teminabuan - TXM - Teminabuan - Indonesia",
            "searchName": "TXM-Teminabuan-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "WBA",
            "countryCode": "ID",
            "cityCode": "WBA",
            "cityName": "Wahai",
            "name": "[WBA] - Wahai - WBA - Wahai - Indonesia",
            "searchName": "WBA-Wahai-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "WAR",
            "countryCode": "ID",
            "cityCode": "WAR",
            "cityName": "Waris",
            "name": "[WAR] - Waris - WAR - Waris - Indonesia",
            "searchName": "WAR-Waris-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "WGP",
            "countryCode": "ID",
            "cityCode": "WGP",
            "cityName": "Waingapu",
            "name": "[WGP] - Waingapu - WGP - Waingapu - Indonesia",
            "searchName": "WGP-Waingapu-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "WET",
            "countryCode": "ID",
            "cityCode": "WET",
            "cityName": "Wagethe",
            "name": "[WET] - Wagethe - WET - Wagethe - Indonesia",
            "searchName": "WET-Wagethe-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "WSR",
            "countryCode": "ID",
            "cityCode": "WSR",
            "cityName": "Wasior",
            "name": "[WSR] - Wasior - WSR - Wasior - Indonesia",
            "searchName": "WSR-Wasior-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "WMX",
            "countryCode": "ID",
            "cityCode": "WMX",
            "cityName": "Wamena",
            "name": "[WMX] - Wamena - WMX - Wamena - Indonesia",
            "searchName": "WMX-Wamena-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "UGU",
            "countryCode": "ID",
            "cityCode": "UGU",
            "cityName": "Zugapa",
            "name": "[UGU] - Zugapa - UGU - Zugapa - Indonesia",
            "searchName": "UGU-Zugapa-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "UBR",
            "countryCode": "ID",
            "cityCode": "UBR",
            "cityName": "Ubrub",
            "name": "[UBR] - Ubrub - UBR - Ubrub - Indonesia",
            "searchName": "UBR-Ubrub-UBRUB IRIAN JAYA AIRPORT-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "UPG",
            "countryCode": "ID",
            "cityCode": "UPG",
            "cityName": "Ujung Pandang",
            "name": "[UPG] - Hasanudin - UPG - Ujung Pandang - Indonesia",
            "searchName": "UPG-Ujung Pandang-Hasanudin-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "UOL",
            "countryCode": "ID",
            "cityCode": "UOL",
            "cityName": "Buol",
            "name": "[UOL] - Buol - UOL - Buol - Indonesia",
            "searchName": "UOL-Buol-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MDC",
            "countryCode": "ID",
            "cityCode": "MDC",
            "cityName": "Manado",
            "name": "[MDC] - Samratulangi - MDC - Manado - Indonesia",
            "searchName": "MDC-Manado-Samratulangi-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MDP",
            "countryCode": "ID",
            "cityCode": "MDP",
            "cityName": "Mindiptana",
            "name": "[MDP] - Mindiptana - MDP - Mindiptana - Indonesia",
            "searchName": "MDP-Mindiptana-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MEQ",
            "countryCode": "ID",
            "cityCode": "MEQ",
            "cityName": "Meulaboh",
            "name": "[MEQ] - Seunagan - MEQ - Meulaboh - Indonesia",
            "searchName": "MEQ-Seunagan-Meulaboh-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MES",
            "countryCode": "ID",
            "cityCode": "MES",
            "cityName": "Medan",
            "name": "[MES] - Polonia - MES - Medan - Indonesia",
            "searchName": "MES-Medan-Polonia-Polania-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "KNO",
            "countryCode": "ID",
            "cityCode": "MES",
            "cityName": "Medan",
            "name": "[KNO] - Kuala Namu International Airport - MES - Medan - Indonesia",
            "searchName": "KNO-MES-Internationaler Flughafen Kuala Namu-Kuala Namu International Airport-Aeropuerto internacional de Kuala Namu-Aéroport international de Kuala Namu-Aeroporto internazionale di Kuala Namu -Medan-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MAL",
            "countryCode": "ID",
            "cityCode": "MAL",
            "cityName": "Mangole",
            "name": "[MAL] - Mangole - MAL - Mangole - Indonesia",
            "searchName": "MAL-Mangole-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MJU",
            "countryCode": "ID",
            "cityCode": "MJU",
            "cityName": "Mamuju",
            "name": "[MJU] - Mamuju - MJU - Mamuju - Indonesia",
            "searchName": "MJU-Mamuju-Tampa Padang Havalimani-Mamujui-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MJY",
            "countryCode": "ID",
            "cityCode": "MJY",
            "cityName": "Mangunjaya",
            "name": "[MJY] - Mangunjaya - MJY - Mangunjaya - Indonesia",
            "searchName": "MJY-Mangunjaya-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MKQ",
            "countryCode": "ID",
            "cityCode": "MKQ",
            "cityName": "Merauke",
            "name": "[MKQ] - Mopah - MKQ - Merauke - Indonesia",
            "searchName": "MKQ-Mopah-Merauke-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MKW",
            "countryCode": "ID",
            "cityCode": "MKW",
            "cityName": "Manokwari",
            "name": "[MKW] - Rendani - MKW - Manokwari - Indonesia",
            "searchName": "MKW-Rendani-Manokwari-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MLG",
            "countryCode": "ID",
            "cityCode": "MLG",
            "cityName": "Malang",
            "name": "[MLG] - Abdul Rahman Saleh - MLG - Malang - Indonesia",
            "searchName": "MLG-Malang-Abdul Rahman Saleh-Abdul Rachman Saleh Havalimani-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MNA",
            "countryCode": "ID",
            "cityCode": "MNA",
            "cityName": "Melangguane",
            "name": "[MNA] - Melangguane - MNA - Melangguane - Indonesia",
            "searchName": "MNA-Melangguane-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MPC",
            "countryCode": "ID",
            "cityCode": "MPC",
            "cityName": "Muko-Muko",
            "name": "[MPC] - Muko-Muko - MPC - Muko-Muko - Indonesia",
            "searchName": "MPC-Muko - Flughafen-Muko-Muko Muko-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MOF",
            "countryCode": "ID",
            "cityCode": "MOF",
            "cityName": "Maumere",
            "name": "[MOF] - Waioti - MOF - Maumere - Indonesia",
            "searchName": "MOF-Waioti-Maumere-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MUF",
            "countryCode": "ID",
            "cityCode": "MUF",
            "cityName": "Muting",
            "name": "[MUF] - Muting - MUF - Muting - Indonesia",
            "searchName": "MUF-Muting-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MSI",
            "countryCode": "ID",
            "cityCode": "MSI",
            "cityName": "Masalembo",
            "name": "[MSI] - Masalembo - MSI - Masalembo - Indonesia",
            "searchName": "MSI-Masalembo-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "OBD",
            "countryCode": "ID",
            "cityCode": "OBD",
            "cityName": "Obano",
            "name": "[OBD] - Obano - OBD - Obano - Indonesia",
            "searchName": "OBD-Obano-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "OKL",
            "countryCode": "ID",
            "cityCode": "OKL",
            "cityName": "Oksibil",
            "name": "[OKL] - Oksibil - OKL - Oksibil - Indonesia",
            "searchName": "OKL-Oksibil-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "OKQ",
            "countryCode": "ID",
            "cityCode": "OKQ",
            "cityName": "Okaba",
            "name": "[OKQ] - Okaba - OKQ - Okaba - Indonesia",
            "searchName": "OKQ-Okaba-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "NPO",
            "countryCode": "ID",
            "cityCode": "NPO",
            "cityName": "Nangapinoh",
            "name": "[NPO] - Nangapinoh - NPO - Nangapinoh - Indonesia",
            "searchName": "NPO-Nangapinoh-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "NRE",
            "countryCode": "ID",
            "cityCode": "NRE",
            "cityName": "Namrole",
            "name": "[NRE] - Namrole - NRE - Namrole - Indonesia",
            "searchName": "NRE-Namrole-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "NNX",
            "countryCode": "ID",
            "cityCode": "NNX",
            "cityName": "Nunukan",
            "name": "[NNX] - Nunukan - NNX - Nunukan - Indonesia",
            "searchName": "NNX-Nunukan-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "NTI",
            "countryCode": "ID",
            "cityCode": "NTI",
            "cityName": "Bintuni",
            "name": "[NTI] - Bintuni - NTI - Bintuni - Indonesia",
            "searchName": "NTI-Bintuni-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "NTX",
            "countryCode": "ID",
            "cityCode": "NTX",
            "cityName": "Natuna Ranai",
            "name": "[NTX] - Natuna Ranai - NTX - Natuna Ranai - Indonesia",
            "searchName": "NTX-Natuna Ranai-Ranai-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MWK",
            "countryCode": "ID",
            "cityCode": "MWK",
            "cityName": "Matak",
            "name": "[MWK] - Matak - MWK - Matak - Indonesia",
            "searchName": "MWK-Matak-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "MXB",
            "countryCode": "ID",
            "cityCode": "MXB",
            "cityName": "Masamba",
            "name": "[MXB] - Masamba - MXB - Masamba - Indonesia",
            "searchName": "MXB-Masamba-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "NAM",
            "countryCode": "ID",
            "cityCode": "NAM",
            "cityName": "Namlea",
            "name": "[NAM] - Namlea - NAM - Namlea - Indonesia",
            "searchName": "NAM-Namlea-Namlea Havalimani-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "NAH",
            "countryCode": "ID",
            "cityCode": "NAH",
            "cityName": "Naha",
            "name": "[NAH] - Naha - NAH - Naha - Indonesia",
            "searchName": "NAH-Naha-Naha Havalimani-Tahuna-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "NAF",
            "countryCode": "ID",
            "cityCode": "NAF",
            "cityName": "Banaina",
            "name": "[NAF] - Banaina - NAF - Banaina - Indonesia",
            "searchName": "NAF-Banaina-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "NBX",
            "countryCode": "ID",
            "cityCode": "NBX",
            "cityName": "Nabire",
            "name": "[NBX] - Nabire - NBX - Nabire - Indonesia",
            "searchName": "NBX-Nabire-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "NDA",
            "countryCode": "ID",
            "cityCode": "NDA",
            "cityName": "Bandanaira",
            "name": "[NDA] - Bandanaira - NDA - Bandanaira - Indonesia",
            "searchName": "NDA-Bandanaira-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "NKD",
            "countryCode": "ID",
            "cityCode": "NKD",
            "cityName": "Sinak",
            "name": "[NKD] - Sinak - NKD - Sinak - Indonesia",
            "searchName": "NKD-Sinak-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "RAQ",
            "countryCode": "ID",
            "cityCode": "RAQ",
            "cityName": "Raha",
            "name": "[RAQ] - Sugimanuru - RAQ - Raha - Indonesia",
            "searchName": "RAQ-Sugimanuru-Raha-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PUM",
            "countryCode": "ID",
            "cityCode": "PUM",
            "cityName": "Pomala",
            "name": "[PUM] - Pomala - PUM - Pomala - Indonesia",
            "searchName": "PUM-Pomala-Pomala Havalimani-Pomalaa-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PWL",
            "countryCode": "ID",
            "cityCode": "PWL",
            "cityName": "Purwokerto",
            "name": "[PWL] - Purwokerto - PWL - Purwokerto - Indonesia",
            "searchName": "PWL-Purwokerto-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PSU",
            "countryCode": "ID",
            "cityCode": "PSU",
            "cityName": "Putussibau",
            "name": "[PSU] - Putussibau - PSU - Putussibau - Indonesia",
            "searchName": "PSU-Putussibau-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PSJ",
            "countryCode": "ID",
            "cityCode": "PSJ",
            "cityName": "Poso",
            "name": "[PSJ] - Poso - PSJ - Poso - Indonesia",
            "searchName": "PSJ-Poso-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "RKI",
            "countryCode": "ID",
            "cityCode": "RKI",
            "cityName": "Rokot",
            "name": "[RKI] - Rokot - RKI - Rokot - Indonesia",
            "searchName": "RKI-Rokot-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "RKO",
            "countryCode": "ID",
            "cityCode": "RKO",
            "cityName": "Sipora",
            "name": "[RKO] - Sipora - RKO - Sipora - Indonesia",
            "searchName": "RKO-Sipora-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "RDE",
            "countryCode": "ID",
            "cityCode": "RDE",
            "cityName": "Merdey",
            "name": "[RDE] - Merdey - RDE - Merdey - Indonesia",
            "searchName": "RDE-Merdey-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "RGT",
            "countryCode": "ID",
            "cityCode": "RGT",
            "cityName": "Rengat",
            "name": "[RGT] - Japura - RGT - Rengat - Indonesia",
            "searchName": "RGT-Japura-Rengat-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "RTG",
            "countryCode": "ID",
            "cityCode": "RTG",
            "cityName": "Ruteng",
            "name": "[RTG] - Ruteng - RTG - Ruteng - Indonesia",
            "searchName": "RTG-Ruteng-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "RTI",
            "countryCode": "ID",
            "cityCode": "RTI",
            "cityName": "Roti",
            "name": "[RTI] - Roti - RTI - Roti - Indonesia",
            "searchName": "RTI-Roti-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "RSK",
            "countryCode": "ID",
            "cityCode": "RSK",
            "cityName": "Ransiki",
            "name": "[RSK] - Ransiki - RSK - Ransiki - Indonesia",
            "searchName": "RSK-Ransiki-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "SAE",
            "countryCode": "ID",
            "cityCode": "SAE",
            "cityName": "Sangir",
            "name": "[SAE] - Sangir - SAE - Sangir - Indonesia",
            "searchName": "SAE-Sangir-Daqing-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "RUF",
            "countryCode": "ID",
            "cityCode": "RUF",
            "cityName": "Yuruf",
            "name": "[RUF] - Yuruf - RUF - Yuruf - Indonesia",
            "searchName": "RUF-Yuruf-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PGK",
            "countryCode": "ID",
            "cityCode": "PGK",
            "cityName": "Pangkalpinang",
            "name": "[PGK] - Pangkalpinang - PGK - Pangkalpinang - Indonesia",
            "searchName": "PGK-Pangkalpinang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PKN",
            "countryCode": "ID",
            "cityCode": "PKN",
            "cityName": "Pangkalanbuun",
            "name": "[PKN] - Pangkalanbuun - PKN - Pangkalanbuun - Indonesia",
            "searchName": "PKN-Pangkalanbuun-Pangkalanboun-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PNK",
            "countryCode": "ID",
            "cityCode": "PNK",
            "cityName": "Pontianak",
            "name": "[PNK] - Supadio - PNK - Pontianak - Indonesia",
            "searchName": "PNK-Supadio-Pontianak-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PLW",
            "countryCode": "ID",
            "cityCode": "PLW",
            "cityName": "Palu",
            "name": "[PLW] - Mutiara - PLW - Palu - Indonesia",
            "searchName": "PLW-Mutiara-Palu-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PKY",
            "countryCode": "ID",
            "cityCode": "PKY",
            "cityName": "Palangkaraya",
            "name": "[PKY] - Cilik Riwut (Tjilik Riwoet) - PKY - Palangkaraya - Indonesia",
            "searchName": "PKY-Palangkaraya-Cilik Riwut (Tjilik Riwoet)-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PKU",
            "countryCode": "ID",
            "cityCode": "PKU",
            "cityName": "Pekanbaru",
            "name": "[PKU] - Sultan Syarif Kasim Ii - PKU - Pekanbaru - Indonesia",
            "searchName": "PKU-Pekanbaru-Sultan Syarif Kasim Ii-Simpang Tiga-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PLM",
            "countryCode": "ID",
            "cityCode": "PLM",
            "cityName": "Palembang",
            "name": "[PLM] - Mahmud Badaruddin Ii - PLM - Palembang - Indonesia",
            "searchName": "PLM-Palembang-Mahmud Badaruddin Ii-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PPJ",
            "countryCode": "ID",
            "cityCode": "PPJ",
            "cityName": "Pulau Panjang",
            "name": "[PPJ] - Pulau Panjang - PPJ - Pulau Panjang - Indonesia",
            "searchName": "PPJ-Pulau Panjang-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PPR",
            "countryCode": "ID",
            "cityCode": "PPR",
            "cityName": "Pasir Pangarayan",
            "name": "[PPR] - Pasir Pangarayan - PPR - Pasir Pangarayan - Indonesia",
            "searchName": "PPR-Pasir Pangarayan-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PBW",
            "countryCode": "ID",
            "cityCode": "PBW",
            "cityName": "Palibelo",
            "name": "[PBW] - Palibelo - PBW - Palibelo - Indonesia",
            "searchName": "PBW-Palibelo-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PCB",
            "countryCode": "ID",
            "cityCode": "PCB",
            "cityName": "Pondok Cabe",
            "name": "[PCB] - Pondok Cabe - PCB - Pondok Cabe - Indonesia",
            "searchName": "PCB-Pondok Cabe-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PDG",
            "countryCode": "ID",
            "cityCode": "PDG",
            "cityName": "Padang",
            "name": "[PDG] - Minangkabau International Airport - PDG - Padang - Indonesia",
            "searchName": "PDG-Padang-Minangkabau International Airport-Tabing-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PDO",
            "countryCode": "ID",
            "cityCode": "PDO",
            "cityName": "Pendopo",
            "name": "[PDO] - Pendopo - PDO - Pendopo - Indonesia",
            "searchName": "PDO-Pendopo-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "OTI",
            "countryCode": "ID",
            "cityCode": "OTI",
            "cityName": "Pitu",
            "name": "[OTI] - Pitu - OTI - Pitu - Indonesia",
            "searchName": "OTI-Pitu-Morotai Island-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ONI",
            "countryCode": "ID",
            "cityCode": "ONI",
            "cityName": "Moanamani",
            "name": "[ONI] - Moanamani - ONI - Moanamani - Indonesia",
            "searchName": "ONI-Moanamani-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ZRM",
            "countryCode": "ID",
            "cityCode": "ZRM",
            "cityName": "Sarmi",
            "name": "[ZRM] - Sarmi - ZRM - Sarmi - Indonesia",
            "searchName": "ZRM-Sarmi-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ZRI",
            "countryCode": "ID",
            "cityCode": "ZRI",
            "cityName": "Serui",
            "name": "[ZRI] - Serui - ZRI - Serui - Indonesia",
            "searchName": "ZRI-Serui-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ZEG",
            "countryCode": "ID",
            "cityCode": "ZEG",
            "cityName": "Senggo",
            "name": "[ZEG] - Senggo - ZEG - Senggo - Indonesia",
            "searchName": "ZEG-Senggo-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "ZKL",
            "countryCode": "ID",
            "cityCode": "ZKL",
            "cityName": "Steenkool",
            "name": "[ZKL] - Steenkool - ZKL - Steenkool - Indonesia",
            "searchName": "ZKL-Steenkool-Indonézia-Indonèsia-Endonezya-Indonésie-Indonesia-Indonezia-Indonezija-Indonesien-Indoneesia-Indonesië-Indones",
            "priority": 0
        },
        {
            "iataCode": "PGH",
            "countryCode": "IN",
            "cityCode": "PGH",
            "cityName": "Pantnagar",
            "name": "[PGH] - Pantnagar - PGH - Pantnagar - India",
            "searchName": "PGH-Pantnagar-Pantnagar Havalimani-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "MAA",
            "countryCode": "IN",
            "cityCode": "MAA",
            "cityName": "Chennai",
            "name": "[MAA] - Madras International (Meenambakkam) - MAA - Chennai - India",
            "searchName": "MAA-Chennai International-Madras International (Meenambakkam)-Chennai-Madr-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "NVY",
            "countryCode": "IN",
            "cityCode": "NVY",
            "cityName": "Neyveli",
            "name": "[NVY] - Neyveli - NVY - Neyveli - India",
            "searchName": "NVY-Neyveli-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "NMB",
            "countryCode": "IN",
            "cityCode": "NMB",
            "cityName": "Daman",
            "name": "[NMB] - Daman - NMB - Daman - India",
            "searchName": "NMB-Daman-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "NDC",
            "countryCode": "IN",
            "cityCode": "NDC",
            "cityName": "Nanded",
            "name": "[NDC] - Nanded - NDC - Nanded - India",
            "searchName": "NDC-Nanded-Shri Guru Gobind Singh Ji Havalimani-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "NAG",
            "countryCode": "IN",
            "cityCode": "NAG",
            "cityName": "Nagpur",
            "name": "[NAG] - Dr. Babasaheb Ambedkar International - NAG - Nagpur - India",
            "searchName": "NAG-Nagpur-Dr. Babasaheb Ambedkar International-Sonegaon-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "MZU",
            "countryCode": "IN",
            "cityCode": "MZU",
            "cityName": "Muzaffarpur",
            "name": "[MZU] - Muzaffarpur - MZU - Muzaffarpur - India",
            "searchName": "MZU-Muzaffarpur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "MYQ",
            "countryCode": "IN",
            "cityCode": "MYQ",
            "cityName": "Mysore",
            "name": "[MYQ] - Mysore - MYQ - Mysore - India",
            "searchName": "MYQ-Mysore-Mysore Havalimani-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "VDY",
            "countryCode": "IN",
            "cityCode": "VDY",
            "cityName": "Vidyanagar",
            "name": "[VDY] - Jindal - VDY - Vidyanagar - India",
            "searchName": "VDY-Jindal-Vidyanagar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "VGA",
            "countryCode": "IN",
            "cityCode": "VGA",
            "cityName": "Vijayawada",
            "name": "[VGA] - Vijayawada - VGA - Vijayawada - India",
            "searchName": "VGA-Vijayawada-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "UDR",
            "countryCode": "IN",
            "cityCode": "UDR",
            "cityName": "Udaipur",
            "name": "[UDR] - Dabok - UDR - Udaipur - India",
            "searchName": "UDR-Udaipur-Dabok-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "VTZ",
            "countryCode": "IN",
            "cityCode": "VTZ",
            "cityName": "Vishakhapatnam",
            "name": "[VTZ] - Vishakhapatnam - VTZ - Vishakhapatnam - India",
            "searchName": "VTZ-Vishakhapatnam-Visakhapatnam-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "VNS",
            "countryCode": "IN",
            "cityCode": "VNS",
            "cityName": "Varanasi",
            "name": "[VNS] - Varanasi - VNS - Varanasi - India",
            "searchName": "VNS-Varanasi-Benar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "WGC",
            "countryCode": "IN",
            "cityCode": "WGC",
            "cityName": "Warangal",
            "name": "[WGC] - Warangal - WGC - Warangal - India",
            "searchName": "WGC-Warangal-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "TNI",
            "countryCode": "IN",
            "cityCode": "TNI",
            "cityName": "Satna",
            "name": "[TNI] - Satna - TNI - Satna - India",
            "searchName": "TNI-Satna-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "BBI",
            "countryCode": "IN",
            "cityCode": "BBI",
            "cityName": "Bhubaneswar",
            "name": "[BBI] - Bhubaneswar - BBI - Bhubaneswar - India",
            "searchName": "BBI-Bhubaneswar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "ATQ",
            "countryCode": "IN",
            "cityCode": "ATQ",
            "cityName": "Amritsar",
            "name": "[ATQ] - Raja Sansi International Airport - ATQ - Amritsar - India",
            "searchName": "ATQ-Amritsar-Raja Sansi International Airport-Raja Sansi-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "AMD",
            "countryCode": "IN",
            "cityCode": "AMD",
            "cityName": "Ahmedabad",
            "name": "[AMD] - Ahmedabad - AMD - Ahmedabad - India",
            "searchName": "AMD-Ahmedabad-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "AKD",
            "countryCode": "IN",
            "cityCode": "AKD",
            "cityName": "Akola",
            "name": "[AKD] - Akola - AKD - Akola - India",
            "searchName": "AKD-Akola-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "AGX",
            "countryCode": "IN",
            "cityCode": "AGX",
            "cityName": "Agatti Island",
            "name": "[AGX] - Agatti Island - AGX - Agatti Island - India",
            "searchName": "AGX-Agatti Island-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "AGR",
            "countryCode": "IN",
            "cityCode": "AGR",
            "cityName": "Agra",
            "name": "[AGR] - Kheria - AGR - Agra - India",
            "searchName": "AGR-Kheria-Agra--India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "AJL",
            "countryCode": "IN",
            "cityCode": "AJL",
            "cityName": "Aizawl",
            "name": "[AJL] - Aizawl - AJL - Aizawl - India",
            "searchName": "AJL-Aizawl-Lengpui Airport-Aizol-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "RPR",
            "countryCode": "IN",
            "cityCode": "RPR",
            "cityName": "Raipur",
            "name": "[RPR] - Raipur - RPR - Raipur - India",
            "searchName": "RPR-Raipur-Raipur Airport-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "RTC",
            "countryCode": "IN",
            "cityCode": "RTC",
            "cityName": "Ratnagiri",
            "name": "[RTC] - Ratnagiri - RTC - Ratnagiri - India",
            "searchName": "RTC-Ratnagiri-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "RRK",
            "countryCode": "IN",
            "cityCode": "RRK",
            "cityName": "Rourkela",
            "name": "[RRK] - Rourkela - RRK - Rourkela - India",
            "searchName": "RRK-Rourkela-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "RUP",
            "countryCode": "IN",
            "cityCode": "RUP",
            "cityName": "Rupsi",
            "name": "[RUP] - Rupsi - RUP - Rupsi - India",
            "searchName": "RUP-Rupsi-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "RGH",
            "countryCode": "IN",
            "cityCode": "RGH",
            "cityName": "Balurghat",
            "name": "[RGH] - Balurghat - RGH - Balurghat - India",
            "searchName": "RGH-Balurghat-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "REW",
            "countryCode": "IN",
            "cityCode": "REW",
            "cityName": "Rewa",
            "name": "[REW] - Rewa - REW - Rewa - India",
            "searchName": "REW-Rewa-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "RJI",
            "countryCode": "IN",
            "cityCode": "RJI",
            "cityName": "Rajouri",
            "name": "[RJI] - Rajouri - RJI - Rajouri - India",
            "searchName": "RJI-Rajouri-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "RJA",
            "countryCode": "IN",
            "cityCode": "RJA",
            "cityName": "Rajahmundry",
            "name": "[RJA] - Rajahmundry - RJA - Rajahmundry - India",
            "searchName": "RJA-Rajahmundry-Rajahmundry Airport-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "RMD",
            "countryCode": "IN",
            "cityCode": "RMD",
            "cityName": "Ramagundam",
            "name": "[RMD] - Ramagundam - RMD - Ramagundam - India",
            "searchName": "RMD-Ramagundam-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "PUT",
            "countryCode": "IN",
            "cityCode": "PUT",
            "cityName": "Puttaparthi",
            "name": "[PUT] - Puttaprathe - PUT - Puttaparthi - India",
            "searchName": "PUT-Puttaprathe-Puttaparthi-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "PYB",
            "countryCode": "IN",
            "cityCode": "PYB",
            "cityName": "Jeypore",
            "name": "[PYB] - Jeypore - PYB - Jeypore - India",
            "searchName": "PYB-Jeypore-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "RAJ",
            "countryCode": "IN",
            "cityCode": "RAJ",
            "cityName": "Rajkot",
            "name": "[RAJ] - Civil - RAJ - Rajkot - India",
            "searchName": "RAJ-Rajkot-Civil-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "PBD",
            "countryCode": "IN",
            "cityCode": "PBD",
            "cityName": "Porbandar",
            "name": "[PBD] - Porbandar - PBD - Porbandar - India",
            "searchName": "PBD-Porbandar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "PAT",
            "countryCode": "IN",
            "cityCode": "PAT",
            "cityName": "Patna",
            "name": "[PAT] - Patna - PAT - Patna - India",
            "searchName": "PAT-Patna-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "PAB",
            "countryCode": "IN",
            "cityCode": "PAB",
            "cityName": "Bilaspur",
            "name": "[PAB] - Bilaspur - PAB - Bilaspur - India",
            "searchName": "PAB-Bilaspur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "PNY",
            "countryCode": "IN",
            "cityCode": "PNY",
            "cityName": "Pondicherry",
            "name": "[PNY] - Pondicherry - PNY - Pondicherry - India",
            "searchName": "PNY-Pondicherry-Puducherry-Pondich-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXC",
            "countryCode": "IN",
            "cityCode": "IXC",
            "cityName": "Chandigarh",
            "name": "[IXC] - Chandigarh - IXC - Chandigarh - India",
            "searchName": "IXC-Chandigarh-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXD",
            "countryCode": "IN",
            "cityCode": "IXD",
            "cityName": "Allahabad",
            "name": "[IXD] - Bamrauli - IXD - Allahabad - India",
            "searchName": "IXD-Allahabad-Bamrauli-Bamrauli Havalimani-All-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXE",
            "countryCode": "IN",
            "cityCode": "IXE",
            "cityName": "Mangalore",
            "name": "[IXE] - Bajpe - IXE - Mangalore - India",
            "searchName": "IXE-Mangalore-Bajpe-Mangaluru-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXG",
            "countryCode": "IN",
            "cityCode": "IXG",
            "cityName": "Belgaum",
            "name": "[IXG] - Sambre - IXG - Belgaum - India",
            "searchName": "IXG-Sambre-Sambre Havalimani-Belgaum-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXH",
            "countryCode": "IN",
            "cityCode": "IXH",
            "cityName": "Kailashahar",
            "name": "[IXH] - Kailashahar - IXH - Kailashahar - India",
            "searchName": "IXH-Kailashahar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXI",
            "countryCode": "IN",
            "cityCode": "IXI",
            "cityName": "Lilabari",
            "name": "[IXI] - North Lakhimpur - IXI - Lilabari - India",
            "searchName": "IXI-Lilabari-North Lakhimpur-Jixi Khanka Lake-Lilabari Havalimani-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXJ",
            "countryCode": "IN",
            "cityCode": "IXJ",
            "cityName": "Jammu",
            "name": "[IXJ] - Satwari - IXJ - Jammu - India",
            "searchName": "IXJ-Jammu-Satwari-Cammu-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "IXK",
            "countryCode": "IN",
            "cityCode": "IXK",
            "cityName": "Keshod",
            "name": "[IXK] - Keshod - IXK - Keshod - India",
            "searchName": "IXK-Keshod-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "DEP",
            "countryCode": "IN",
            "cityCode": "DEP",
            "cityName": "Deparizo",
            "name": "[DEP] - Deparizo - DEP - Deparizo - India",
            "searchName": "DEP-Deparizo-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "CDP",
            "countryCode": "IN",
            "cityCode": "CDP",
            "cityName": "Cuddapah",
            "name": "[CDP] - Cuddapah - CDP - Cuddapah - India",
            "searchName": "CDP-Cuddapah-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "CCJ",
            "countryCode": "IN",
            "cityCode": "CCJ",
            "cityName": "Kozhikode",
            "name": "[CCJ] - Kozhikode Airport - CCJ - Kozhikode - India",
            "searchName": "CCJ-Kozhikode-Kozhikode Airport-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "CCU",
            "countryCode": "IN",
            "cityCode": "CCU",
            "cityName": "Kolkata",
            "name": "[CCU] - Netaji Subhas Chandra - CCU - Kolkata - India",
            "searchName": "CCU-Kalkutta-Netaji Subhas Chandra-Netaji S. Chandra-Kolkata-Calcuta-Calcutta-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "CBD",
            "countryCode": "IN",
            "cityCode": "CBD",
            "cityName": "Car Nicobar",
            "name": "[CBD] - Car Nicobar - CBD - Car Nicobar - India",
            "searchName": "CBD-Car Nicobar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "CJB",
            "countryCode": "IN",
            "cityCode": "CJB",
            "cityName": "Coimbatore",
            "name": "[CJB] - Peelamedu - CJB - Coimbatore - India",
            "searchName": "CJB-Coimbatore-Peelamedu-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "COK",
            "countryCode": "IN",
            "cityCode": "COK",
            "cityName": "Kochi",
            "name": "[COK] - Cochin International - COK - Kochi - India",
            "searchName": "COK-Cochin-Cochin International-Kochi-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "COH",
            "countryCode": "IN",
            "cityCode": "COH",
            "cityName": "Cooch Behar",
            "name": "[COH] - Cooch Behar - COH - Cooch Behar - India",
            "searchName": "COH-Cooch Behar-Cooch Behar Havalimani-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "BEP",
            "countryCode": "IN",
            "cityCode": "BEP",
            "cityName": "Bellary",
            "name": "[BEP] - Bellary - BEP - Bellary - India",
            "searchName": "BEP-Bellary-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "BEK",
            "countryCode": "IN",
            "cityCode": "BEK",
            "cityName": "Bareli",
            "name": "[BEK] - Bareli - BEK - Bareli - India",
            "searchName": "BEK-Bareli-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "BDQ",
            "countryCode": "IN",
            "cityCode": "BDQ",
            "cityName": "Vadodara",
            "name": "[BDQ] - Vadodara - BDQ - Vadodara - India",
            "searchName": "BDQ-Baronda-Vadodara-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "GOP",
            "countryCode": "IN",
            "cityCode": "GOP",
            "cityName": "Gorakhpur",
            "name": "[GOP] - Gorakhpur - GOP - Gorakhpur - India",
            "searchName": "GOP-Gorakhpur-Gorakhpur Havalimani-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "GAY",
            "countryCode": "IN",
            "cityCode": "GAY",
            "cityName": "Gaya",
            "name": "[GAY] - Gaya - GAY - Gaya - India",
            "searchName": "GAY-Gaya-Gaya Airport-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "GAU",
            "countryCode": "IN",
            "cityCode": "GAU",
            "cityName": "Gawahati",
            "name": "[GAU] - Borjhar - GAU - Gawahati - India",
            "searchName": "GAU-Guwahati-Borjhar-Gawahati-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "KNU",
            "countryCode": "IN",
            "cityCode": "KNU",
            "cityName": "Kanpur",
            "name": "[KNU] - Kanpur - KNU - Kanpur - India",
            "searchName": "KNU-Kanpur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "KLH",
            "countryCode": "IN",
            "cityCode": "KLH",
            "cityName": "Kolhapur",
            "name": "[KLH] - Kolhapur - KLH - Kolhapur - India",
            "searchName": "KLH-Kolhapur-Kolhapur Havalimani-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "JLR",
            "countryCode": "IN",
            "cityCode": "JLR",
            "cityName": "Jabalpur",
            "name": "[JLR] - Jabalpur - JLR - Jabalpur - India",
            "searchName": "JLR-Jabalpur-Jabalpur Dumna Airport-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "JSA",
            "countryCode": "IN",
            "cityCode": "JSA",
            "cityName": "Jaisalmer",
            "name": "[JSA] - Jaisalmer - JSA - Jaisalmer - India",
            "searchName": "JSA-Jaisalmer-Jaisalmer Havalimani-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "JRH",
            "countryCode": "IN",
            "cityCode": "JRH",
            "cityName": "Jorhat",
            "name": "[JRH] - Rowriah - JRH - Jorhat - India",
            "searchName": "JRH-Jorhat-Rowriah-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "JDH",
            "countryCode": "IN",
            "cityCode": "JDH",
            "cityName": "Jodhpur",
            "name": "[JDH] - Jodhpur - JDH - Jodhpur - India",
            "searchName": "JDH-Jodhpur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "JGA",
            "countryCode": "IN",
            "cityCode": "JGA",
            "cityName": "Jamnagar",
            "name": "[JGA] - Govardhanpur - JGA - Jamnagar - India",
            "searchName": "JGA-Jamnagar-Govardhanpur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "JGB",
            "countryCode": "IN",
            "cityCode": "JGB",
            "cityName": "Jagdalpur",
            "name": "[JGB] - Jagdalpur - JGB - Jagdalpur - India",
            "searchName": "JGB-Jagdalpur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "LTU",
            "countryCode": "IN",
            "cityCode": "LTU",
            "cityName": "Latur",
            "name": "[LTU] - Latur - LTU - Latur - India",
            "searchName": "LTU-Latur-Latur Havalimani-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "LUH",
            "countryCode": "IN",
            "cityCode": "LUH",
            "cityName": "Ludhiana",
            "name": "[LUH] - Ludhiana Sahnewal Airport - LUH - Ludhiana - India",
            "searchName": "LUH-Ludhiana-Ludhiana Sahnewal Airport-Ludhiana Havalimani-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "LKO",
            "countryCode": "IN",
            "cityCode": "LKO",
            "cityName": "Lucknow",
            "name": "[LKO] - Amausi - LKO - Lucknow - India",
            "searchName": "LKO-Lucknow-Amausi-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "KUU",
            "countryCode": "IN",
            "cityCode": "KUU",
            "cityName": "Bhuntar Kullu",
            "name": "[KUU] - Kullu Manali - KUU - Bhuntar Kullu - India",
            "searchName": "KUU-Kulu-Kullu Manali-Bhuntar Havalimani-Bhuntar Kullu-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "KTU",
            "countryCode": "IN",
            "cityCode": "KTU",
            "cityName": "Kota",
            "name": "[KTU] - Kota - KTU - Kota - India",
            "searchName": "KTU-Kota-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "LDA",
            "countryCode": "IN",
            "cityCode": "LDA",
            "cityName": "Malda",
            "name": "[LDA] - Malda - LDA - Malda - India",
            "searchName": "LDA-Malda-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "DMU",
            "countryCode": "IN",
            "cityCode": "DMU",
            "cityName": "Dimapur",
            "name": "[DMU] - Dimapur - DMU - Dimapur - India",
            "searchName": "DMU-Dimapur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "DIU",
            "countryCode": "IN",
            "cityCode": "DIU",
            "cityName": "Diu",
            "name": "[DIU] - Diu - DIU - Diu - India",
            "searchName": "DIU-Diu Indien-Diu-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "DIB",
            "countryCode": "IN",
            "cityCode": "DIB",
            "cityName": "Dibrugarh",
            "name": "[DIB] - Dibrugarh - DIB - Dibrugarh - India",
            "searchName": "DIB-Dibrugarh-Chabua-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "DHM",
            "countryCode": "IN",
            "cityCode": "DHM",
            "cityName": "Dharamsala",
            "name": "[DHM] - Gaggal Airport - DHM - Dharamsala - India",
            "searchName": "DHM-Gaggal Airport-Dharamsala-Gaggal Havalimani-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "DED",
            "countryCode": "IN",
            "cityCode": "DED",
            "cityName": "Dehra Dun",
            "name": "[DED] - Dehra Dun - DED - Dehra Dun - India",
            "searchName": "DED-Dehradun-Dehra Dun-Jolly Grant Havalimani-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "DBD",
            "countryCode": "IN",
            "cityCode": "DBD",
            "cityName": "Dhanbad",
            "name": "[DBD] - Dhanbad - DBD - Dhanbad - India",
            "searchName": "DBD-Dhanbad-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "TRZ",
            "countryCode": "IN",
            "cityCode": "TRZ",
            "cityName": "Tiruchirapally",
            "name": "[TRZ] - Civil - TRZ - Tiruchirapally - India",
            "searchName": "TRZ-Tiruchirapally-Civil-Tiruchirappalli-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "SXR",
            "countryCode": "IN",
            "cityCode": "SXR",
            "cityName": "Srinagar",
            "name": "[SXR] - Srinagar - SXR - Srinagar - India",
            "searchName": "SXR-Srinagar-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "SXV",
            "countryCode": "IN",
            "cityCode": "SXV",
            "cityName": "Salem",
            "name": "[SXV] - Salem - SXV - Salem - India",
            "searchName": "SXV-Salem-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "TCR",
            "countryCode": "IN",
            "cityCode": "TCR",
            "cityName": "Tuticorin",
            "name": "[TCR] - Tuticorin - TCR - Tuticorin - India",
            "searchName": "TCR-Tuticorin-Tuticorin Havalimani-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "TJV",
            "countryCode": "IN",
            "cityCode": "TJV",
            "cityName": "Thanjavur",
            "name": "[TJV] - Thanjavur - TJV - Thanjavur - India",
            "searchName": "TJV-Thanjavur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "TIR",
            "countryCode": "IN",
            "cityCode": "TIR",
            "cityName": "Tirupati",
            "name": "[TIR] - Tirupati - TIR - Tirupati - India",
            "searchName": "TIR-Tirupati-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "TEZ",
            "countryCode": "IN",
            "cityCode": "TEZ",
            "cityName": "Tezpur",
            "name": "[TEZ] - Salonibari - TEZ - Tezpur - India",
            "searchName": "TEZ-Salonibari-Tezpur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "TEI",
            "countryCode": "IN",
            "cityCode": "TEI",
            "cityName": "Tezu",
            "name": "[TEI] - Tezu - TEI - Tezu - India",
            "searchName": "TEI-Tezu-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "SHL",
            "countryCode": "IN",
            "cityCode": "SHL",
            "cityName": "Shillong",
            "name": "[SHL] - Barapani - SHL - Shillong - India",
            "searchName": "SHL-Shillong-Barapani-Shillong\u00A0 Havalimani-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "SLV",
            "countryCode": "IN",
            "cityCode": "SLV",
            "cityName": "Simla",
            "name": "[SLV] - Simla - SLV - Simla - India",
            "searchName": "SLV-Simla-Shimla-Simla Havalimani-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "SSE",
            "countryCode": "IN",
            "cityCode": "SSE",
            "cityName": "Sholapur",
            "name": "[SSE] - Sholapur - SSE - Sholapur - India",
            "searchName": "SSE-Sholapur-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "STV",
            "countryCode": "IN",
            "cityCode": "STV",
            "cityName": "Surat",
            "name": "[STV] - Surat - STV - Surat - India",
            "searchName": "STV-Surat-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "ZER",
            "countryCode": "IN",
            "cityCode": "ZER",
            "cityName": "Zero",
            "name": "[ZER] - Zero - ZER - Zero - India",
            "searchName": "ZER-Zero-India-Índia-Indien-Indija-Indie-Hindistan-Intia-Inde",
            "priority": 0
        },
        {
            "iataCode": "TQD",
            "countryCode": "IQ",
            "cityCode": "TQD",
            "cityName": "Al Fallujah",
            "name": "[TQD] - Al Taqaddum AB - TQD - Al Fallujah - Iraq",
            "searchName": "TQD-Al Taqaddum AB-Al Fallujah-Irak-Iraq",
            "priority": 0
        },
        {
            "iataCode": "NJF",
            "countryCode": "IQ",
            "cityCode": "NJF",
            "cityName": "Al Najaf",
            "name": "[NJF] - Al-Ashraf International - NJF - Al Najaf - Iraq",
            "searchName": "NJF-Al Najaf Intl-Al-Ashraf International-AL Ashraf International Airport-Bagdad-Al Najaf-Nayaf-Nadjaf-Najaf-Irak-Iraq",
            "priority": 0
        },
        {
            "iataCode": "OSM",
            "countryCode": "IQ",
            "cityCode": "OSM",
            "cityName": "Mosul",
            "name": "[OSM] - Mosul - OSM - Mosul - Iraq",
            "searchName": "OSM-Mosul-Mossoul-Mossul-Irak-Iraq",
            "priority": 0
        },
        {
            "iataCode": "RQW",
            "countryCode": "IQ",
            "cityCode": "RQW",
            "cityName": "Qayyarah",
            "name": "[RQW] - West - RQW - Qayyarah - Iraq",
            "searchName": "RQW-West-Qayyarah-Irak-Iraq",
            "priority": 0
        },
        {
            "iataCode": "BSR",
            "countryCode": "IQ",
            "cityCode": "BSR",
            "cityName": "Basra",
            "name": "[BSR] - International - BSR - Basra - Iraq",
            "searchName": "BSR-Basra-International-Basra International Airport-Basora-Bassorah-B-Irak-Iraq",
            "priority": 0
        },
        {
            "iataCode": "BMN",
            "countryCode": "IQ",
            "cityCode": "BMN",
            "cityName": "Bamerny",
            "name": "[BMN] - Bamerny - BMN - Bamerny - Iraq",
            "searchName": "BMN-Bamerny-Irak-Iraq",
            "priority": 0
        },
        {
            "iataCode": "BGW",
            "countryCode": "IQ",
            "cityCode": "BGW",
            "cityName": "Baghdad",
            "name": "[BGW] - Baghdad International - BGW - Baghdad - Iraq",
            "searchName": "BGW-Baghdad Al Muthana-Baghdad International-Baghdad Intl-Baghdad International Airport-Bagdad-Baghdad-Irak-Iraq",
            "priority": 0
        },
        {
            "iataCode": "EBL",
            "countryCode": "IQ",
            "cityCode": "EBL",
            "cityName": "Erbil",
            "name": "[EBL] - Erbil International (irbil Northwest) - EBL - Erbil - Iraq",
            "searchName": "EBL-Arbil-Erbil International (irbil Northwest)-Erbil International-Erbil International Airport-Erbil-Irak-Iraq",
            "priority": 0
        },
        {
            "iataCode": "KIK",
            "countryCode": "IQ",
            "cityCode": "KIK",
            "cityName": "Kirkuk",
            "name": "[KIK] - Kirkuk - KIK - Kirkuk - Iraq",
            "searchName": "KIK-Kirkuk-Irak-Iraq",
            "priority": 0
        },
        {
            "iataCode": "ISU",
            "countryCode": "IQ",
            "cityCode": "ISU",
            "cityName": "Sulaimaniyah",
            "name": "[ISU] - Sulaimaniyah International - ISU - Sulaimaniyah - Iraq",
            "searchName": "ISU-Sulaimaniyya-Sulaimaniyah International-Sulaymaniyah Int.-Sulaimaniyah International Airport-Silemani-Sulaimaniyah-Suleimaniya-Souleymaniah-Sulaymaniyah-Irak-Iraq",
            "priority": 0
        },
        {
            "iataCode": "IQA",
            "countryCode": "IQ",
            "cityCode": "IQA",
            "cityName": "Al Asad",
            "name": "[IQA] - Al Asad Air Base - IQA - Al Asad - Iraq",
            "searchName": "IQA-Al Asad Air Base-Al Asad-Irak-Iraq",
            "priority": 0
        },
        {
            "iataCode": "IFH",
            "countryCode": "IR",
            "cityCode": "IFH",
            "cityName": "Hesa",
            "name": "[IFH] - Hesa - IFH - Hesa - Iran",
            "searchName": "IFH-Hesa-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "IFN",
            "countryCode": "IR",
            "cityCode": "IFN",
            "cityName": "Isfahan",
            "name": "[IFN] - Isfahan International - IFN - Isfahan - Iran",
            "searchName": "IFN-Isfahan-Isfahan International-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "IAQ",
            "countryCode": "IR",
            "cityCode": "IAQ",
            "cityName": "Bahregan",
            "name": "[IAQ] - Bahregan - IAQ - Bahregan - Iran",
            "searchName": "IAQ-Bahregan-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "IIL",
            "countryCode": "IR",
            "cityCode": "IIL",
            "cityName": "Ilaam",
            "name": "[IIL] - Ilaam - IIL - Ilaam - Iran",
            "searchName": "IIL-Ilaam-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "IHR",
            "countryCode": "IR",
            "cityCode": "IHR",
            "cityName": "Iran Shahr",
            "name": "[IHR] - Iran Shahr - IHR - Iran Shahr - Iran",
            "searchName": "IHR-Iran Shahr-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "HDM",
            "countryCode": "IR",
            "cityCode": "HDM",
            "cityName": "Hamadan",
            "name": "[HDM] - Hamadan - HDM - Hamadan - Iran",
            "searchName": "HDM-Hamadan-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "HDR",
            "countryCode": "IR",
            "cityCode": "HDR",
            "cityName": "Havadarya",
            "name": "[HDR] - Havadarya - HDR - Havadarya - Iran",
            "searchName": "HDR-Havadarya-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "GCH",
            "countryCode": "IR",
            "cityCode": "GCH",
            "cityName": "Gachsaran",
            "name": "[GCH] - Gachsaran - GCH - Gachsaran - Iran",
            "searchName": "GCH-Gachsaran-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "GBT",
            "countryCode": "IR",
            "cityCode": "GBT",
            "cityName": "Gorgan",
            "name": "[GBT] - Gorgan - GBT - Gorgan - Iran",
            "searchName": "GBT-Gorgan-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "GZW",
            "countryCode": "IR",
            "cityCode": "GZW",
            "cityName": "Ghazvin",
            "name": "[GZW] - Ghazvin - GZW - Ghazvin - Iran",
            "searchName": "GZW-Ghazvin-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "GSM",
            "countryCode": "IR",
            "cityCode": "GSM",
            "cityName": "Gheshm",
            "name": "[GSM] - Gheshm - GSM - Gheshm - Iran",
            "searchName": "GSM-Gheshm-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "KHY",
            "countryCode": "IR",
            "cityCode": "KHY",
            "cityName": "Khoy",
            "name": "[KHY] - Khoy - KHY - Khoy - Iran",
            "searchName": "KHY-Khoy-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "KIH",
            "countryCode": "IR",
            "cityCode": "KIH",
            "cityName": "Kish Island",
            "name": "[KIH] - Kish Island - KIH - Kish Island - Iran",
            "searchName": "KIH-Kish Island-Kish-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "KHK",
            "countryCode": "IR",
            "cityCode": "KHK",
            "cityName": "Khark Island",
            "name": "[KHK] - Khark Island - KHK - Khark Island - Iran",
            "searchName": "KHK-Khark Island-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "KHD",
            "countryCode": "IR",
            "cityCode": "KHD",
            "cityName": "Khorramabad",
            "name": "[KHD] - Khorramabad - KHD - Khorramabad - Iran",
            "searchName": "KHD-Khorramabad-Hürremabad Havalimani-H-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "KHA",
            "countryCode": "IR",
            "cityCode": "KHA",
            "cityName": "Khaneh",
            "name": "[KHA] - Khaneh - KHA - Khaneh - Iran",
            "searchName": "KHA-Khaneh-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "KLM",
            "countryCode": "IR",
            "cityCode": "KLM",
            "cityName": "Kalaleh",
            "name": "[KLM] - Kalaleh - KLM - Kalaleh - Iran",
            "searchName": "KLM-Kalaleh-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "KNR",
            "countryCode": "IR",
            "cityCode": "KNR",
            "cityName": "Kangan",
            "name": "[KNR] - Jam - KNR - Kangan - Iran",
            "searchName": "KNR-Jam-Kangan-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "KSH",
            "countryCode": "IR",
            "cityCode": "KSH",
            "cityName": "Kermanshah",
            "name": "[KSH] - Kermanshah - KSH - Kermanshah - Iran",
            "searchName": "KSH-Kermanshah-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "JAR",
            "countryCode": "IR",
            "cityCode": "JAR",
            "cityName": "Jahrom",
            "name": "[JAR] - Jahrom - JAR - Jahrom - Iran",
            "searchName": "JAR-Jahrom-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "KER",
            "countryCode": "IR",
            "cityCode": "KER",
            "cityName": "Kerman",
            "name": "[KER] - Kerman - KER - Kerman - Iran",
            "searchName": "KER-Kerman-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "JYR",
            "countryCode": "IR",
            "cityCode": "JYR",
            "cityName": "Jiroft",
            "name": "[JYR] - Jiroft - JYR - Jiroft - Iran",
            "searchName": "JYR-Jiroft-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "JWN",
            "countryCode": "IR",
            "cityCode": "JWN",
            "cityName": "Zanjan",
            "name": "[JWN] - Zanjan - JWN - Zanjan - Iran",
            "searchName": "JWN-Zanjan-Zanjan Havalimani-Zencan-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "LFM",
            "countryCode": "IR",
            "cityCode": "LFM",
            "cityName": "Lamerd",
            "name": "[LFM] - Lamerd - LFM - Lamerd - Iran",
            "searchName": "LFM-Lamerd-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "LRR",
            "countryCode": "IR",
            "cityCode": "LRR",
            "cityName": "Lar",
            "name": "[LRR] - Lar Airport - LRR - Lar - Iran",
            "searchName": "LRR-Lar A/P-Lar Airport-Lar-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "LVP",
            "countryCode": "IR",
            "cityCode": "LVP",
            "cityName": "Lavan",
            "name": "[LVP] - Lavan - LVP - Lavan - Iran",
            "searchName": "LVP-Lavan-Lavan Island-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "FAZ",
            "countryCode": "IR",
            "cityCode": "FAZ",
            "cityName": "Fasa",
            "name": "[FAZ] - Fasa - FAZ - Fasa - Iran",
            "searchName": "FAZ-Fasa-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "DEF",
            "countryCode": "IR",
            "cityCode": "DEF",
            "cityName": "Dezful",
            "name": "[DEF] - Dezful - DEF - Dezful - Iran",
            "searchName": "DEF-Dezful-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "CQD",
            "countryCode": "IR",
            "cityCode": "CQD",
            "cityName": "Shahre-kord",
            "name": "[CQD] - Shahre-kord - CQD - Shahre-kord - Iran",
            "searchName": "CQD-Shahre-Kord-kord-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "CKT",
            "countryCode": "IR",
            "cityCode": "CKT",
            "cityName": "Sarakhs",
            "name": "[CKT] - Sarakhs - CKT - Sarakhs - Iran",
            "searchName": "CKT-Sarakhs-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "BDH",
            "countryCode": "IR",
            "cityCode": "BDH",
            "cityName": "Bandar Lengeh",
            "name": "[BDH] - Bandar Lengeh - BDH - Bandar Lengeh - Iran",
            "searchName": "BDH-Bandar Lengeh-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "BJB",
            "countryCode": "IR",
            "cityCode": "BJB",
            "cityName": "Bojnord",
            "name": "[BJB] - Bojnord - BJB - Bojnord - Iran",
            "searchName": "BJB-Bojnord-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "BSM",
            "countryCode": "IR",
            "cityCode": "BSM",
            "cityName": "Bishe-kola",
            "name": "[BSM] - Bishe-kola - BSM - Bishe-kola - Iran",
            "searchName": "BSM-Bishe-kola-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "BND",
            "countryCode": "IR",
            "cityCode": "BND",
            "cityName": "Bandar Abbas",
            "name": "[BND] - Bandar Abbas - BND - Bandar Abbas - Iran",
            "searchName": "BND-Bandar Abbas-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "BUZ",
            "countryCode": "IR",
            "cityCode": "BUZ",
            "cityName": "Bushehr",
            "name": "[BUZ] - Bushehr - BUZ - Bushehr - Iran",
            "searchName": "BUZ-Bushehr-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "BXR",
            "countryCode": "IR",
            "cityCode": "BXR",
            "cityName": "Bam",
            "name": "[BXR] - Bam - BXR - Bam - Iran",
            "searchName": "BXR-Bam Iran-Bam-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "AJK",
            "countryCode": "IR",
            "cityCode": "AJK",
            "cityName": "Araak",
            "name": "[AJK] - Araak - AJK - Araak - Iran",
            "searchName": "AJK-Araak-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "AKW",
            "countryCode": "IR",
            "cityCode": "AKW",
            "cityName": "Aghajari",
            "name": "[AKW] - Aghajari - AKW - Aghajari - Iran",
            "searchName": "AKW-Aghajari-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "AFZ",
            "countryCode": "IR",
            "cityCode": "AFZ",
            "cityName": "Sabzevar",
            "name": "[AFZ] - Sabzevar - AFZ - Sabzevar - Iran",
            "searchName": "AFZ-Sabzevar-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "AEU",
            "countryCode": "IR",
            "cityCode": "AEU",
            "cityName": "Abu Musa",
            "name": "[AEU] - Abu Musa - AEU - Abu Musa - Iran",
            "searchName": "AEU-Abu Musa-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "ADU",
            "countryCode": "IR",
            "cityCode": "ADU",
            "cityName": "Ardabil",
            "name": "[ADU] - Ardabil - ADU - Ardabil - Iran",
            "searchName": "ADU-Ardabil-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "ACP",
            "countryCode": "IR",
            "cityCode": "ACP",
            "cityName": "Sahand",
            "name": "[ACP] - Sahand - ACP - Sahand - Iran",
            "searchName": "ACP-Sahand-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "ACZ",
            "countryCode": "IR",
            "cityCode": "ACZ",
            "cityName": "Zabol",
            "name": "[ACZ] - Zabol Airport - ACZ - Zabol - Iran",
            "searchName": "ACZ-Zabol Airport-Zabol-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "ABD",
            "countryCode": "IR",
            "cityCode": "ABD",
            "cityName": "Abadan",
            "name": "[ABD] - Abadan - ABD - Abadan - Iran",
            "searchName": "ABD-Abadan-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "AWZ",
            "countryCode": "IR",
            "cityCode": "AWZ",
            "cityName": "Ahwaz",
            "name": "[AWZ] - Ahwaz - AWZ - Ahwaz - Iran",
            "searchName": "AWZ-Ahwaz-Ahvaz-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "AZD",
            "countryCode": "IR",
            "cityCode": "AZD",
            "cityName": "Yazd",
            "name": "[AZD] - Yazd - AZD - Yazd - Iran",
            "searchName": "AZD-Yazd-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "RUD",
            "countryCode": "IR",
            "cityCode": "RUD",
            "cityName": "Shahrud",
            "name": "[RUD] - Shahrud - RUD - Shahrud - Iran",
            "searchName": "RUD-Shahrud-Shahroud-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "RZR",
            "countryCode": "IR",
            "cityCode": "RZR",
            "cityName": "Ramsar",
            "name": "[RZR] - Ramsar - RZR - Ramsar - Iran",
            "searchName": "RZR-Ramsar-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "RJN",
            "countryCode": "IR",
            "cityCode": "RJN",
            "cityName": "Rafsanjan",
            "name": "[RJN] - Rafsanjan - RJN - Rafsanjan - Iran",
            "searchName": "RJN-Rafsanjan-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "RAS",
            "countryCode": "IR",
            "cityCode": "RAS",
            "cityName": "Rasht",
            "name": "[RAS] - Rasht - RAS - Rasht - Iran",
            "searchName": "RAS-Rasht-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "PYK",
            "countryCode": "IR",
            "cityCode": "PYK",
            "cityName": "Karaj",
            "name": "[PYK] - Payam - PYK - Karaj - Iran",
            "searchName": "PYK-Payam-Pyke-Karaj-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "OMH",
            "countryCode": "IR",
            "cityCode": "OMH",
            "cityName": "Urmieh",
            "name": "[OMH] - Urmieh - OMH - Urmieh - Iran",
            "searchName": "OMH-Urmieh-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "OMI",
            "countryCode": "IR",
            "cityCode": "OMI",
            "cityName": "Omidieh",
            "name": "[OMI] - Omidieh - OMI - Omidieh - Iran",
            "searchName": "OMI-Omidieh-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "PFQ",
            "countryCode": "IR",
            "cityCode": "PFQ",
            "cityName": "Parsabad",
            "name": "[PFQ] - Parsabad - PFQ - Parsabad - Iran",
            "searchName": "PFQ-Parsabad-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "PGU",
            "countryCode": "IR",
            "cityCode": "PGU",
            "cityName": "Asaloyeh",
            "name": "[PGU] - Persian Gulf International - PGU - Asaloyeh - Iran",
            "searchName": "PGU-Persian Gulf International-Asaloyeh-Golfo Pérsico Internacional-Asaloyeh International-Asaloyeh Persian Gulf International-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "NUJ",
            "countryCode": "IR",
            "cityCode": "NUJ",
            "cityName": "Nojeh",
            "name": "[NUJ] - Nojeh - NUJ - Nojeh - Iran",
            "searchName": "NUJ-Nojeh-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "NSH",
            "countryCode": "IR",
            "cityCode": "NSH",
            "cityName": "Now Shahr",
            "name": "[NSH] - Now Shahr - NSH - Now Shahr - Iran",
            "searchName": "NSH-Now Shahr-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "MHD",
            "countryCode": "IR",
            "cityCode": "MHD",
            "cityName": "Mashad",
            "name": "[MHD] - Mashad - MHD - Mashad - Iran",
            "searchName": "MHD-Mashad-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "MRX",
            "countryCode": "IR",
            "cityCode": "MRX",
            "cityName": "Bandar Mahshahr",
            "name": "[MRX] - Mahshahr - MRX - Bandar Mahshahr - Iran",
            "searchName": "MRX-Mahshahr-Bandar Mahshahr-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "THR",
            "countryCode": "IR",
            "cityCode": "THR",
            "cityName": "Tehran",
            "name": "[THR] - Mehrabad (Qualeh Morgeh) - THR - Tehran - Iran",
            "searchName": "THR-Tehran-Mehrabad (Qualeh Morgeh)-Mehrabad-Teheran-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "IKA",
            "countryCode": "IR",
            "cityCode": "THR",
            "cityName": "Tehran",
            "name": "[IKA] - Imam Khomeini Airport - THR - Tehran - Iran",
            "searchName": "IKA-THR-Imam Khomeini-Imam Khomeini Airport-Teheran-Tehran-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "TCX",
            "countryCode": "IR",
            "cityCode": "TCX",
            "cityName": "Tabas",
            "name": "[TCX] - Tabas - TCX - Tabas - Iran",
            "searchName": "TCX-Tabas-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "TBZ",
            "countryCode": "IR",
            "cityCode": "TBZ",
            "cityName": "Tabriz",
            "name": "[TBZ] - Tabriz - TBZ - Tabriz - Iran",
            "searchName": "TBZ-Tabriz-T-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "SYJ",
            "countryCode": "IR",
            "cityCode": "SYJ",
            "cityName": "Sirjan",
            "name": "[SYJ] - Sirjan - SYJ - Sirjan - Iran",
            "searchName": "SYJ-Sirjan-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "SXI",
            "countryCode": "IR",
            "cityCode": "SXI",
            "cityName": "Sirri Island",
            "name": "[SXI] - Sirri Island - SXI - Sirri Island - Iran",
            "searchName": "SXI-Sirri Island-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "SYZ",
            "countryCode": "IR",
            "cityCode": "SYZ",
            "cityName": "Shiraz",
            "name": "[SYZ] - Shahid Dastghaib International - SYZ - Shiraz - Iran",
            "searchName": "SYZ-Shiraz-Shahid Dastghaib International-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "SRY",
            "countryCode": "IR",
            "cityCode": "SRY",
            "cityName": "Sary",
            "name": "[SRY] - Dashte Naz - SRY - Sary - Iran",
            "searchName": "SRY-Dashte Naz-Sari-Sary-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "SDG",
            "countryCode": "IR",
            "cityCode": "SDG",
            "cityName": "Sanandaj",
            "name": "[SDG] - Sanandaj - SDG - Sanandaj - Iran",
            "searchName": "SDG-Sanandaj-Sanandadsch-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "XBJ",
            "countryCode": "IR",
            "cityCode": "XBJ",
            "cityName": "Birjand",
            "name": "[XBJ] - Birjand - XBJ - Birjand - Iran",
            "searchName": "XBJ-Birjand-Birjand Airport-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "YES",
            "countryCode": "IR",
            "cityCode": "YES",
            "cityName": "Yasouj",
            "name": "[YES] - Yasouj - YES - Yasouj - Iran",
            "searchName": "YES-Yasouj-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "ZAH",
            "countryCode": "IR",
            "cityCode": "ZAH",
            "cityName": "Zahedan",
            "name": "[ZAH] - Zahedan - ZAH - Zahedan - Iran",
            "searchName": "ZAH-Zahedan-Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "ZBR",
            "countryCode": "IR",
            "cityCode": "ZBR",
            "cityName": "Chah-bahar",
            "name": "[ZBR] - Chah- Bahar - ZBR - Chah-bahar - Iran",
            "searchName": "ZBR-Chah-Bahar- Bahar-bahar--Iran-Irán",
            "priority": 0
        },
        {
            "iataCode": "VPN",
            "countryCode": "IS",
            "cityCode": "VPN",
            "cityName": "Vopnafjordur",
            "name": "[VPN] - Vopnafjordur - VPN - Vopnafjordur - Iceland",
            "searchName": "VPN-Vopnafjordur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "VEY",
            "countryCode": "IS",
            "cityCode": "VEY",
            "cityName": "Vestmannaeyjar",
            "name": "[VEY] - Vestmannaeyjar - VEY - Vestmannaeyjar - Iceland",
            "searchName": "VEY-Vestmannaeyjar-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "SAK",
            "countryCode": "IS",
            "cityCode": "SAK",
            "cityName": "Saudarkrokur",
            "name": "[SAK] - Saudarkrokur - SAK - Saudarkrokur - Iceland",
            "searchName": "SAK-Saudarkrokur-Saudarkrokur Havalimani-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "SIJ",
            "countryCode": "IS",
            "cityCode": "SIJ",
            "cityName": "Siglufjordur",
            "name": "[SIJ] - Siglufjordur - SIJ - Siglufjordur - Iceland",
            "searchName": "SIJ-Siglufjordur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "SYK",
            "countryCode": "IS",
            "cityCode": "SYK",
            "cityName": "Stykkisholmur",
            "name": "[SYK] - Stykkisholmur - SYK - Stykkisholmur - Iceland",
            "searchName": "SYK-Stykkisholmur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "THO",
            "countryCode": "IS",
            "cityCode": "THO",
            "cityName": "Thorshofn",
            "name": "[THO] - Thorshofn - THO - Thorshofn - Iceland",
            "searchName": "THO-Thorshofn-Saint Thomas-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "TEY",
            "countryCode": "IS",
            "cityCode": "TEY",
            "cityName": "Thingeyri",
            "name": "[TEY] - Thingeyri - TEY - Thingeyri - Iceland",
            "searchName": "TEY-Thingeyri-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "MVA",
            "countryCode": "IS",
            "cityCode": "MVA",
            "cityName": "Myvatn",
            "name": "[MVA] - Reykiahlid - MVA - Myvatn - Iceland",
            "searchName": "MVA-Reykiahlid-Myvatn-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "NOR",
            "countryCode": "IS",
            "cityCode": "NOR",
            "cityName": "Nordfjordur",
            "name": "[NOR] - Nordfjordur - NOR - Nordfjordur - Iceland",
            "searchName": "NOR-Nordfjordur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "OLI",
            "countryCode": "IS",
            "cityCode": "OLI",
            "cityName": "Olafsvik",
            "name": "[OLI] - Rif - OLI - Olafsvik - Iceland",
            "searchName": "OLI-Rif-Olafsvik-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "OFJ",
            "countryCode": "IS",
            "cityCode": "OFJ",
            "cityName": "Olafsfjordur",
            "name": "[OFJ] - Olafsfjordur - OFJ - Olafsfjordur - Iceland",
            "searchName": "OFJ-Olafsfjordur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "PFJ",
            "countryCode": "IS",
            "cityCode": "PFJ",
            "cityName": "Patreksfjordur",
            "name": "[PFJ] - Patreksfjordur - PFJ - Patreksfjordur - Iceland",
            "searchName": "PFJ-Patreksfjordur-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "OPA",
            "countryCode": "IS",
            "cityCode": "OPA",
            "cityName": "Kopasker",
            "name": "[OPA] - Kopasker - OPA - Kopasker - Iceland",
            "searchName": "OPA-Kopasker-Kopasker Havalimani-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "RKV",
            "countryCode": "IS",
            "cityCode": "REK",
            "cityName": "Reykjavik",
            "name": "[RKV] - Reykjavik Domestic - REK - Reykjavik - Iceland",
            "searchName": "RKV-REK-Reykjavik Domestic-Reykjavik Domestic Apt.-Reykjav-Reykjavik-Izland-Islàndia-Izlanda-Island-Islanti-Islanda-Islande-Islandija-Islandia-Iceland",
            "priority": 0
        },
        {
            "iataCode": "RBE",
            "countryCode": "KH",
            "cityCode": "RBE",
            "cityName": "Ratanakiri",
            "name": "[RBE] - Ratanakiri - RBE - Ratanakiri - Cambodia",
            "searchName": "RBE-Ratanakiri-Ratanankiri-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "SQQ",
            "countryCode": "LT",
            "cityCode": "SQQ",
            "cityName": "Siauliai",
            "name": "[SQQ] - Siauliai International - SQQ - Siauliai - Lithuania",
            "searchName": "SQQ-Siauliai International-Siauliai-Lituânia-Litouwen-Lithuania-Litauen-Lituanie-Lituania-Litwa-Leedu-Li-Litvanya",
            "priority": 0
        },
        {
            "iataCode": "LPX",
            "countryCode": "LV",
            "cityCode": "LPX",
            "cityName": "Liepaja",
            "name": "[LPX] - Liepaja - LPX - Liepaja - Latvia",
            "searchName": "LPX-Liepaja-Liepaya Havalimani-Liepaya-Lettország-Letònia-Letonya-Lotyšsko-Latvia-Letland-Lotwa-Läti-Latvija-Lát via-Letonia-Lettland-Lettonia-L-Lettonie",
            "priority": 0
        },
        {
            "iataCode": "DGP",
            "countryCode": "LV",
            "cityCode": "DGP",
            "cityName": "Daugavpils",
            "name": "[DGP] - Daugavpils - DGP - Daugavpils - Latvia",
            "searchName": "DGP-Daugavpils-Daugavpils Havalimani-Lettország-Letònia-Letonya-Lotyšsko-Latvia-Letland-Lotwa-Läti-Latvija-Lát via-Letonia-Lettland-Lettonia-L-Lettonie",
            "priority": 0
        },
        {
            "iataCode": "VNT",
            "countryCode": "LV",
            "cityCode": "VNT",
            "cityName": "Ventspils",
            "name": "[VNT] - Ventspils - VNT - Ventspils - Latvia",
            "searchName": "VNT-Ventspils-Ventspils Uluslararasi Havalimani-Lettország-Letònia-Letonya-Lotyšsko-Latvia-Letland-Lotwa-Läti-Latvija-Lát via-Letonia-Lettland-Lettonia-L-Lettonie",
            "priority": 0
        },
        {
            "iataCode": "MSG",
            "countryCode": "LS",
            "cityCode": "MSG",
            "cityName": "Matsaile",
            "name": "[MSG] - Matsaile - MSG - Matsaile - Lesotho",
            "searchName": "MSG-Matsaile-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "MFC",
            "countryCode": "LS",
            "cityCode": "MFC",
            "cityName": "Mafeteng",
            "name": "[MFC] - Mafeteng - MFC - Mafeteng - Lesotho",
            "searchName": "MFC-Mafeteng-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "LEF",
            "countryCode": "LS",
            "cityCode": "LEF",
            "cityName": "Lebakeng",
            "name": "[LEF] - Lebakeng - LEF - Lebakeng - Lesotho",
            "searchName": "LEF-Lebakeng-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "LES",
            "countryCode": "LS",
            "cityCode": "LES",
            "cityName": "Lesobeng",
            "name": "[LES] - Lesobeng - LES - Lesobeng - Lesotho",
            "searchName": "LES-Lesobeng-Lesobeng Havalimani-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "LRB",
            "countryCode": "LS",
            "cityCode": "LRB",
            "cityName": "Leribe",
            "name": "[LRB] - Leribe - LRB - Leribe - Lesotho",
            "searchName": "LRB-Leribe-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "PLQ",
            "countryCode": "LT",
            "cityCode": "PLQ",
            "cityName": "Klaipeda",
            "name": "[PLQ] - Palanga International - PLQ - Klaipeda - Lithuania",
            "searchName": "PLQ-Palanga-Palanga International-Palanga Intl-Palanga Int. Apt-Klaipeda/Palanga-Klaipeda-Lituânia-Litouwen-Lithuania-Litauen-Lituanie-Lituania-Litwa-Leedu-Li-Litvanya",
            "priority": 0
        },
        {
            "iataCode": "XJK",
            "countryCode": "LT",
            "cityCode": "PLQ",
            "cityName": "Klaipeda",
            "name": "[XJK] - Klaipeda Bus Station - PLQ - Klaipeda - Lithuania",
            "searchName": "XJK-PLQ-Klaipeda Bus Station-Klaipeda/Palanga-Klaipeda-Palanga-Lituânia-Litouwen-Lithuania-Litauen-Lituanie-Lituania-Litwa-Leedu-Li-Litvanya",
            "priority": 0
        },
        {
            "iataCode": "PNV",
            "countryCode": "LT",
            "cityCode": "PNV",
            "cityName": "Panevezys",
            "name": "[PNV] - Panevezys - PNV - Panevezys - Lithuania",
            "searchName": "PNV-Panevezys-Lituânia-Litouwen-Lithuania-Litauen-Lituanie-Lituania-Litwa-Leedu-Li-Litvanya",
            "priority": 0
        },
        {
            "iataCode": "BYV",
            "countryCode": "LK",
            "cityCode": "CMB",
            "cityName": "Colombo",
            "name": "[BYV] - Beira Lake SPB - CMB - Colombo - Sri Lanka",
            "searchName": "BYV-CMB-Beira Lake SPB-Colombo-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "RML",
            "countryCode": "LK",
            "cityCode": "CMB",
            "cityName": "Colombo",
            "name": "[RML] - Ratmalana - CMB - Colombo - Sri Lanka",
            "searchName": "RML-CMB-Ratmalana-Colombo-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "CPA",
            "countryCode": "LR",
            "cityCode": "CPA",
            "cityName": "Cape Palmas",
            "name": "[CPA] - A. Tubman - CPA - Cape Palmas - Liberia",
            "searchName": "CPA-A Tubman-A. Tubman-Cape Palmas-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "BYL",
            "countryCode": "LR",
            "cityCode": "BYL",
            "cityName": "Bella Yella",
            "name": "[BYL] - Bella Yella - BYL - Bella Yella - Liberia",
            "searchName": "BYL-Bella Yella-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "GRC",
            "countryCode": "LR",
            "cityCode": "GRC",
            "cityName": "Grand Cess",
            "name": "[GRC] - Grand Cess - GRC - Grand Cess - Liberia",
            "searchName": "GRC-Grand Cess-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "FOY",
            "countryCode": "LR",
            "cityCode": "FOY",
            "cityName": "Foya",
            "name": "[FOY] - Foya - FOY - Foya - Liberia",
            "searchName": "FOY-Foya-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "MLW",
            "countryCode": "LR",
            "cityCode": "MLW",
            "cityName": "Monrovia",
            "name": "[MLW] - Sprigg Payne - MLW - Monrovia - Liberia",
            "searchName": "MLW-Sprigg-Payne-Sprigg Payne-Sprigg Payne Airport-Monrovia-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "ROB",
            "countryCode": "LR",
            "cityCode": "MLW",
            "cityName": "Monrovia",
            "name": "[ROB] - Roberts International - MLW - Monrovia - Liberia",
            "searchName": "ROB-MLW-Monrovia Roberts-Roberts International-Roberts Intl-Monrovia-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "NIA",
            "countryCode": "LR",
            "cityCode": "NIA",
            "cityName": "Nimba",
            "name": "[NIA] - Nimba - NIA - Nimba - Liberia",
            "searchName": "NIA-Nimba-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "RVC",
            "countryCode": "LR",
            "cityCode": "RVC",
            "cityName": "Rivercess",
            "name": "[RVC] - Rivercess - RVC - Rivercess - Liberia",
            "searchName": "RVC-Rivercess-River Cess-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "VOI",
            "countryCode": "LR",
            "cityCode": "VOI",
            "cityName": "Voinjama",
            "name": "[VOI] - Voinjama - VOI - Voinjama - Liberia",
            "searchName": "VOI-Voinjama-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "UCN",
            "countryCode": "LR",
            "cityCode": "UCN",
            "cityName": "Buchanan",
            "name": "[UCN] - Buchanan - UCN - Buchanan - Liberia",
            "searchName": "UCN-Buchanan-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "TPT",
            "countryCode": "LR",
            "cityCode": "TPT",
            "cityName": "Tapeta",
            "name": "[TPT] - Tapeta - TPT - Tapeta - Liberia",
            "searchName": "TPT-Tapeta-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "THC",
            "countryCode": "LR",
            "cityCode": "THC",
            "cityName": "Tchien",
            "name": "[THC] - Tchien - THC - Tchien - Liberia",
            "searchName": "THC-Tchien-Tchien Havalimani-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "SNI",
            "countryCode": "LR",
            "cityCode": "SNI",
            "cityName": "Sinoe",
            "name": "[SNI] - R.E. Murray - SNI - Sinoe - Liberia",
            "searchName": "SNI-R E Murray-R.E. Murray-R E Murray Havalimani-Sinoe-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "SAZ",
            "countryCode": "LR",
            "cityCode": "SAZ",
            "cityName": "Sasstown",
            "name": "[SAZ] - Sasstown - SAZ - Sasstown - Liberia",
            "searchName": "SAZ-Sasstown-Sasstown Havalimani-Liberia-Liberya-Libéria",
            "priority": 0
        },
        {
            "iataCode": "SHZ",
            "countryCode": "LS",
            "cityCode": "SHZ",
            "cityName": "Seshutes",
            "name": "[SHZ] - Seshutes - SHZ - Seshutes - Lesotho",
            "searchName": "SHZ-Seshutes-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "SHK",
            "countryCode": "LS",
            "cityCode": "SHK",
            "cityName": "Sehonghong",
            "name": "[SHK] - Sehonghong - SHK - Sehonghong - Lesotho",
            "searchName": "SHK-Sehonghong-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "SKQ",
            "countryCode": "LS",
            "cityCode": "SKQ",
            "cityName": "Sekakes",
            "name": "[SKQ] - Sekakes - SKQ - Sekakes - Lesotho",
            "searchName": "SKQ-Sekakes-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "SOK",
            "countryCode": "LS",
            "cityCode": "SOK",
            "cityName": "Semongkong",
            "name": "[SOK] - Semongkong - SOK - Semongkong - Lesotho",
            "searchName": "SOK-Semongkong-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "THB",
            "countryCode": "LS",
            "cityCode": "THB",
            "cityName": "Thaba-Tseka",
            "name": "[THB] - Thaba-Tseka - THB - Thaba-Tseka - Lesotho",
            "searchName": "THB-Tseka - Flughafen-Thaba-Tseka-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "TKO",
            "countryCode": "LS",
            "cityCode": "TKO",
            "cityName": "Tlokoeng",
            "name": "[TKO] - Tlokoeng - TKO - Tlokoeng - Lesotho",
            "searchName": "TKO-Tlokoeng-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "UNE",
            "countryCode": "LS",
            "cityCode": "UNE",
            "cityName": "Qachas Nek",
            "name": "[UNE] - Qachas Nek - UNE - Qachas Nek - Lesotho",
            "searchName": "UNE-Qachas Nek-Qachas-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "UTG",
            "countryCode": "LS",
            "cityCode": "UTG",
            "cityName": "Quthing",
            "name": "[UTG] - Quthing - UTG - Quthing - Lesotho",
            "searchName": "UTG-Quthing-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "PEL",
            "countryCode": "LS",
            "cityCode": "PEL",
            "cityName": "Pelaneng",
            "name": "[PEL] - Pelaneng - PEL - Pelaneng - Lesotho",
            "searchName": "PEL-Pelaneng-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "NKU",
            "countryCode": "LS",
            "cityCode": "NKU",
            "cityName": "Nkaus",
            "name": "[NKU] - Nkaus - NKU - Nkaus - Lesotho",
            "searchName": "NKU-Nkaus-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "MKH",
            "countryCode": "LS",
            "cityCode": "MKH",
            "cityName": "Mokhotlong",
            "name": "[MKH] - Mokhotlong - MKH - Mokhotlong - Lesotho",
            "searchName": "MKH-Mokhotlong-Lesotho-Lê sô thô-Lesoto-Lesotas-",
            "priority": 0
        },
        {
            "iataCode": "BZY",
            "countryCode": "MD",
            "cityCode": "BZY",
            "cityName": "Beltsy",
            "name": "[BZY] - Beltsy - BZY - Beltsy - Moldova",
            "searchName": "BZY-Beltsy-Moldova-Moldàvia-Moldavsko-Moldawia-Moldavien-Moldovos-Moldavija-Moldawien-Moldavia-Moldavie",
            "priority": 0
        },
        {
            "iataCode": "IVG",
            "countryCode": "ME",
            "cityCode": "IVG",
            "cityName": "Berane",
            "name": "[IVG] - Ivangrad - IVG - Berane - Montenegro",
            "searchName": "IVG-Berane-Ivangrad-Montenegro-Cerná Hora-Karadag-Montenegró-Monténégro-Muntenegru-Crna Gora-Juodkalnija-Melnkalne",
            "priority": 0
        },
        {
            "iataCode": "RBA",
            "countryCode": "MA",
            "cityCode": "RBA",
            "cityName": "Rabat",
            "name": "[RBA] - Sale - RBA - Rabat - Morocco",
            "searchName": "RBA-Rabat-Sale-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "OZG",
            "countryCode": "MA",
            "cityCode": "OZG",
            "cityName": "Zagora",
            "name": "[OZG] - Zagora - OZG - Zagora - Morocco",
            "searchName": "OZG-Zagora-Zagora Airport-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "OZZ",
            "countryCode": "MA",
            "cityCode": "OZZ",
            "cityName": "Ouarzazate",
            "name": "[OZZ] - Ouarzazate - OZZ - Ouarzazate - Morocco",
            "searchName": "OZZ-Ouarzazate-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "OUD",
            "countryCode": "MA",
            "cityCode": "OUD",
            "cityName": "Oujda",
            "name": "[OUD] - Les Anglades - OUD - Oujda - Morocco",
            "searchName": "OUD-Oujda-Les Anglades-Les Angades-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "TNG",
            "countryCode": "MA",
            "cityCode": "TNG",
            "cityName": "Tangier",
            "name": "[TNG] - Boukhalef - TNG - Tangier - Morocco",
            "searchName": "TNG-Tangier-Boukhalef-Tanger-T-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "TTU",
            "countryCode": "MA",
            "cityCode": "TTU",
            "cityName": "Tetuan",
            "name": "[TTU] - Sania Ramel - TTU - Tetuan - Morocco",
            "searchName": "TTU-Tetouan-Sania Ramel-T-Tetuan-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "TTA",
            "countryCode": "MA",
            "cityCode": "TTA",
            "cityName": "Tan Tan",
            "name": "[TTA] - Tan Tan - TTA - Tan Tan - Morocco",
            "searchName": "TTA-Tan Tan-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "SII",
            "countryCode": "MA",
            "cityCode": "SII",
            "cityName": "Sidi Ifni",
            "name": "[SII] - Sidi Ifni - SII - Sidi Ifni - Morocco",
            "searchName": "SII-Sidi Ifni-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "SMW",
            "countryCode": "MA",
            "cityCode": "SMW",
            "cityName": "Smara",
            "name": "[SMW] - Smara - SMW - Smara - Morocco",
            "searchName": "SMW-Smara-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "VIL",
            "countryCode": "MA",
            "cityCode": "VIL",
            "cityName": "Dakhla",
            "name": "[VIL] - Dakhla - VIL - Dakhla - Morocco",
            "searchName": "VIL-Dakhla-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "UAR",
            "countryCode": "MA",
            "cityCode": "UAR",
            "cityName": "Bouarfa",
            "name": "[UAR] - Bouarfa - UAR - Bouarfa - Morocco",
            "searchName": "UAR-Bouarfa-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "NDR",
            "countryCode": "MA",
            "cityCode": "NDR",
            "cityName": "Nador",
            "name": "[NDR] - Nador - NDR - Nador - Morocco",
            "searchName": "NDR-Nador-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "NNA",
            "countryCode": "MA",
            "cityCode": "NNA",
            "cityName": "Kenitra",
            "name": "[NNA] - NAF - NNA - Kenitra - Morocco",
            "searchName": "NNA-Marinefliegerstützpunkt-NAF-Kenitra-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "MEK",
            "countryCode": "MA",
            "cityCode": "MEK",
            "cityName": "Meknes",
            "name": "[MEK] - Meknes - MEK - Meknes - Morocco",
            "searchName": "MEK-Meknes-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "QUB",
            "countryCode": "LY",
            "cityCode": "QUB",
            "cityName": "Ubari",
            "name": "[QUB] - Ubari - QUB - Ubari - Libya",
            "searchName": "QUB-Ubari-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "MRA",
            "countryCode": "LY",
            "cityCode": "MRA",
            "cityName": "Misurata",
            "name": "[MRA] - Misurata - MRA - Misurata - Libya",
            "searchName": "MRA-Misurata-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "NFR",
            "countryCode": "LY",
            "cityCode": "NFR",
            "cityName": "Nafoora",
            "name": "[NFR] - Nafoora - NFR - Nafoora - Libya",
            "searchName": "NFR-Nafoora-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "WAX",
            "countryCode": "LY",
            "cityCode": "WAX",
            "cityName": "Zwara",
            "name": "[WAX] - Zwara - WAX - Zwara - Libya",
            "searchName": "WAX-Zwara-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "SRX",
            "countryCode": "LY",
            "cityCode": "SRX",
            "cityName": "Sert",
            "name": "[SRX] - Sert - SRX - Sert - Libya",
            "searchName": "SRX-Sert-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "SEB",
            "countryCode": "LY",
            "cityCode": "SEB",
            "cityName": "Sebha",
            "name": "[SEB] - Sebha - SEB - Sebha - Libya",
            "searchName": "SEB-Sebha-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "TOB",
            "countryCode": "LY",
            "cityCode": "TOB",
            "cityName": "Tobruk",
            "name": "[TOB] - Tobruk - TOB - Tobruk - Libya",
            "searchName": "TOB-Tobruk-Tobrouk-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "MJI",
            "countryCode": "LY",
            "cityCode": "TIP",
            "cityName": "Tripoli",
            "name": "[MJI] - Mitiga - TIP - Tripoli - Libya",
            "searchName": "MJI-TIP-Mitiga-Tripoli-Tr-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "DNF",
            "countryCode": "LY",
            "cityCode": "DNF",
            "cityName": "Derna",
            "name": "[DNF] - Martuba - DNF - Derna - Libya",
            "searchName": "DNF-Martuba-Derna-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "BEN",
            "countryCode": "LY",
            "cityCode": "BEN",
            "cityName": "Benghazi",
            "name": "[BEN] - Benina International - BEN - Benghazi - Libya",
            "searchName": "BEN-Benghazi-Benina International-Benina Intl-Banghazi-Bengasi-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "BCQ",
            "countryCode": "LY",
            "cityCode": "BCQ",
            "cityName": "Brack",
            "name": "[BCQ] - Brack - BCQ - Brack - Libya",
            "searchName": "BCQ-Brack-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "AKF",
            "countryCode": "LY",
            "cityCode": "AKF",
            "cityName": "Kufrah",
            "name": "[AKF] - Kufrah - AKF - Kufrah - Libya",
            "searchName": "AKF-Kufrah-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "LMQ",
            "countryCode": "LY",
            "cityCode": "LMQ",
            "cityName": "Marsa Brega",
            "name": "[LMQ] - Marsa Brega - LMQ - Marsa Brega - Libya",
            "searchName": "LMQ-Marsa Brega-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "LTD",
            "countryCode": "LY",
            "cityCode": "LTD",
            "cityName": "Ghadames",
            "name": "[LTD] - Ghadames - LTD - Ghadames - Libya",
            "searchName": "LTD-Ghadames-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "LAQ",
            "countryCode": "LY",
            "cityCode": "LAQ",
            "cityName": "Beida",
            "name": "[LAQ] - La Braq - LAQ - Beida - Libya",
            "searchName": "LAQ-La Braq-Beida-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "GHT",
            "countryCode": "LY",
            "cityCode": "GHT",
            "cityName": "Ghat",
            "name": "[GHT] - Ghat - GHT - Ghat - Libya",
            "searchName": "GHT-Ghat-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "HUQ",
            "countryCode": "LY",
            "cityCode": "HUQ",
            "cityName": "Houn",
            "name": "[HUQ] - Houn - HUQ - Houn - Libya",
            "searchName": "HUQ-Houn-Libyen-Libya-Libye-Libia",
            "priority": 0
        },
        {
            "iataCode": "GLN",
            "countryCode": "MA",
            "cityCode": "GLN",
            "cityName": "Goulimime",
            "name": "[GLN] - Goulimime - GLN - Goulimime - Morocco",
            "searchName": "GLN-Goulimime-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "GMD",
            "countryCode": "MA",
            "cityCode": "GMD",
            "cityName": "Ben Slimane",
            "name": "[GMD] - Ben Slimane - GMD - Ben Slimane - Morocco",
            "searchName": "GMD-Ben Slimane-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "FEZ",
            "countryCode": "MA",
            "cityCode": "FEZ",
            "cityName": "Fez",
            "name": "[FEZ] - Sais - FEZ - Fez - Morocco",
            "searchName": "FEZ-Fes Marokko-Sais-F-Fez-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "AHU",
            "countryCode": "MA",
            "cityCode": "AHU",
            "cityName": "Al Hoceima",
            "name": "[AHU] - Charif Al Idrissi - AHU - Al Hoceima - Morocco",
            "searchName": "AHU-Al Hoceima-Charif Al Idrissi-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "AGA",
            "countryCode": "MA",
            "cityCode": "AGA",
            "cityName": "Agadir",
            "name": "[AGA] - Agadir Almassira - AGA - Agadir - Morocco",
            "searchName": "AGA-Agadir-Agadir Almassira-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "EUN",
            "countryCode": "MA",
            "cityCode": "EUN",
            "cityName": "Laayoune",
            "name": "[EUN] - Hassan I - EUN - Laayoune - Morocco",
            "searchName": "EUN-Laayoune-Hassan I-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "ESU",
            "countryCode": "MA",
            "cityCode": "ESU",
            "cityName": "Essaouira",
            "name": "[ESU] - Essaouira - ESU - Essaouira - Morocco",
            "searchName": "ESU-Essaouira-Mogador Havalimani-Suvayr-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "ERH",
            "countryCode": "MA",
            "cityCode": "ERH",
            "cityName": "Er-Rachidia",
            "name": "[ERH] - Moulay Ali Cherif - ERH - Er-Rachidia - Morocco",
            "searchName": "ERH-Moulay Ali Cherif-Errachidia-Moulay Ali Cherif Havalimani-Er-Rachidia-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "CAS",
            "countryCode": "MA",
            "cityCode": "CAS",
            "cityName": "Casablanca",
            "name": "[CAS] - Anfa - CAS - Casablanca - Morocco",
            "searchName": "CAS-Anfa-Casablanca-Maroko-Marokko-Marocko-Maghribi-Marrocos-Morocco-Marruecos-Marocco-Maroc-Marokas-M-Fas",
            "priority": 0
        },
        {
            "iataCode": "ZBK",
            "countryCode": "ME",
            "cityCode": "ZBK",
            "cityName": "Zabljak",
            "name": "[ZBK] - Zabljak - ZBK - Zabljak - Montenegro",
            "searchName": "ZBK-Zabljak-Montenegro-Cerná Hora-Karadag-Montenegró-Monténégro-Muntenegru-Crna Gora-Juodkalnija-Melnkalne",
            "priority": 0
        },
        {
            "iataCode": "SFG",
            "countryCode": "MF",
            "cityCode": "SFG",
            "cityName": "St Martin",
            "name": "[SFG] - Esperance - SFG - St Martin - Saint Martin",
            "searchName": "SFG-Saint Martin Esperance-Esperance-L Esperance-Saint Martin-St Martin-Grand Case-San Martín-Saint-Martin (partie française)",
            "priority": 0
        },
        {
            "iataCode": "MSB",
            "countryCode": "MF",
            "cityCode": "SFG",
            "cityName": "St Martin",
            "name": "[MSB] - Marigot SPB - SFG - St Martin - Saint Martin",
            "searchName": "MSB-SFG-Marigot SPB-Marigot Seaplane Base Havalimani-Saint Martin-St Martin-Grand Case-San Martín-Saint-Martin (partie française)",
            "priority": 0
        },
        {
            "iataCode": "CCE",
            "countryCode": "MF",
            "cityCode": "SFG",
            "cityName": "St Martin",
            "name": "[CCE] - Grand Case - SFG - St Martin - Saint Martin",
            "searchName": "CCE-SFG-Grand Case-Grand Chase Havalimani-Saint Martin-St Martin-San Martín-Saint-Martin (partie française)",
            "priority": 0
        },
        {
            "iataCode": "SMS",
            "countryCode": "MG",
            "cityCode": "SMS",
            "cityName": "Sainte Marie",
            "name": "[SMS] - Sainte Marie - SMS - Sainte Marie - Madagascar",
            "searchName": "SMS-Sainte Marie-Ile Sainte-Marie-Sante Marie-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "SVB",
            "countryCode": "MG",
            "cityCode": "SVB",
            "cityName": "Sambava",
            "name": "[SVB] - Sambava - SVB - Sambava - Madagascar",
            "searchName": "SVB-Sambava-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "TDV",
            "countryCode": "MG",
            "cityCode": "TDV",
            "cityName": "Tanandava",
            "name": "[TDV] - Tanandava - TDV - Tanandava - Madagascar",
            "searchName": "TDV-Tanandava-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "TTS",
            "countryCode": "MG",
            "cityCode": "TTS",
            "cityName": "Tsaratanana",
            "name": "[TTS] - Tsaratanana - TTS - Tsaratanana - Madagascar",
            "searchName": "TTS-Tsaratanana-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "TVA",
            "countryCode": "MG",
            "cityCode": "TVA",
            "cityName": "Morafenobe",
            "name": "[TVA] - Morafenobe - TVA - Morafenobe - Madagascar",
            "searchName": "TVA-Morafenobe-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "TMM",
            "countryCode": "MG",
            "cityCode": "TMM",
            "cityName": "Tamatave",
            "name": "[TMM] - Tamatave - TMM - Tamatave - Madagascar",
            "searchName": "TMM-Tamatave-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "TLE",
            "countryCode": "MG",
            "cityCode": "TLE",
            "cityName": "Tulear",
            "name": "[TLE] - Tulear - TLE - Tulear - Madagascar",
            "searchName": "TLE-Tulear-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "TNR",
            "countryCode": "MG",
            "cityCode": "TNR",
            "cityName": "Antananarivo",
            "name": "[TNR] - Ivato International Airport - TNR - Antananarivo - Madagascar",
            "searchName": "TNR-Antananarivo-Ivato International Airport-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "TZO",
            "countryCode": "MG",
            "cityCode": "TZO",
            "cityName": "Ankisatra",
            "name": "[TZO] - Tsimiroro - TZO - Ankisatra - Madagascar",
            "searchName": "TZO-Tsimiroro-Ankisatra-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "VAT",
            "countryCode": "MG",
            "cityCode": "VAT",
            "cityName": "Vatomandry",
            "name": "[VAT] - Vatomandry - VAT - Vatomandry - Madagascar",
            "searchName": "VAT-Vatomandry-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WBD",
            "countryCode": "MG",
            "cityCode": "WBD",
            "cityName": "Befandriana",
            "name": "[WBD] - Befandriana - WBD - Befandriana - Madagascar",
            "searchName": "WBD-Befandriana-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WBE",
            "countryCode": "MG",
            "cityCode": "WBE",
            "cityName": "Bealanana",
            "name": "[WBE] - Bealanana - WBE - Bealanana - Madagascar",
            "searchName": "WBE-Bealanana-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WBO",
            "countryCode": "MG",
            "cityCode": "WBO",
            "cityName": "Beroroha",
            "name": "[WBO] - Beroroha - WBO - Beroroha - Madagascar",
            "searchName": "WBO-Beroroha-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WAM",
            "countryCode": "MG",
            "cityCode": "WAM",
            "cityName": "Ambatondrazaka",
            "name": "[WAM] - Ambatondrazaka - WAM - Ambatondrazaka - Madagascar",
            "searchName": "WAM-Ambatondrazaka-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WAQ",
            "countryCode": "MG",
            "cityCode": "WAQ",
            "cityName": "Antsalova",
            "name": "[WAQ] - Antsalova - WAQ - Antsalova - Madagascar",
            "searchName": "WAQ-Antsalova-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WAI",
            "countryCode": "MG",
            "cityCode": "WAI",
            "cityName": "Antsohihy",
            "name": "[WAI] - Antsohihy - WAI - Antsohihy - Madagascar",
            "searchName": "WAI-Antsohihy-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WAK",
            "countryCode": "MG",
            "cityCode": "WAK",
            "cityName": "Ankazoabo",
            "name": "[WAK] - Ankazoabo - WAK - Ankazoabo - Madagascar",
            "searchName": "WAK-Ankazoabo-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WAD",
            "countryCode": "MG",
            "cityCode": "WAD",
            "cityName": "Andriamena",
            "name": "[WAD] - Andriamena - WAD - Andriamena - Madagascar",
            "searchName": "WAD-Andriamena-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "VVB",
            "countryCode": "MG",
            "cityCode": "VVB",
            "cityName": "Mahanoro",
            "name": "[VVB] - Mahanoro - VVB - Mahanoro - Madagascar",
            "searchName": "VVB-Mahanoro-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "VND",
            "countryCode": "MG",
            "cityCode": "VND",
            "cityName": "Vangaindrano",
            "name": "[VND] - Vangaindrano - VND - Vangaindrano - Madagascar",
            "searchName": "VND-Vangaindrano-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "VOH",
            "countryCode": "MG",
            "cityCode": "VOH",
            "cityName": "Vohemar",
            "name": "[VOH] - Vohemar - VOH - Vohemar - Madagascar",
            "searchName": "VOH-Vohemar-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WOR",
            "countryCode": "MG",
            "cityCode": "WOR",
            "cityName": "Ankorefo",
            "name": "[WOR] - Moramba - WOR - Ankorefo - Madagascar",
            "searchName": "WOR-Moramba-Ankorefo-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WPB",
            "countryCode": "MG",
            "cityCode": "WPB",
            "cityName": "Port Berge",
            "name": "[WPB] - Port Berge - WPB - Port Berge - Madagascar",
            "searchName": "WPB-Port Berge-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WML",
            "countryCode": "MG",
            "cityCode": "WML",
            "cityName": "Malaimbandy",
            "name": "[WML] - Malaimbandy - WML - Malaimbandy - Madagascar",
            "searchName": "WML-Malaimbandy-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WMN",
            "countryCode": "MG",
            "cityCode": "WMN",
            "cityName": "Maroantsetra",
            "name": "[WMN] - Maroantsetra - WMN - Maroantsetra - Madagascar",
            "searchName": "WMN-Maroantsetra-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WMP",
            "countryCode": "MG",
            "cityCode": "WMP",
            "cityName": "Mampikony",
            "name": "[WMP] - Mampikony - WMP - Mampikony - Madagascar",
            "searchName": "WMP-Mampikony-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WMR",
            "countryCode": "MG",
            "cityCode": "WMR",
            "cityName": "Mananara",
            "name": "[WMR] - Mananara - WMR - Mananara - Madagascar",
            "searchName": "WMR-Mananara-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WMV",
            "countryCode": "MG",
            "cityCode": "WMV",
            "cityName": "Madirovalo",
            "name": "[WMV] - Madirovalo - WMV - Madirovalo - Madagascar",
            "searchName": "WMV-Madirovalo-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WMA",
            "countryCode": "MG",
            "cityCode": "WMA",
            "cityName": "Mandritsara",
            "name": "[WMA] - Mandritsara - WMA - Mandritsara - Madagascar",
            "searchName": "WMA-Mandritsara-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WMD",
            "countryCode": "MG",
            "cityCode": "WMD",
            "cityName": "Mandabe",
            "name": "[WMD] - Mandabe - WMD - Mandabe - Madagascar",
            "searchName": "WMD-Mandabe-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WFI",
            "countryCode": "MG",
            "cityCode": "WFI",
            "cityName": "Fianarantsoa",
            "name": "[WFI] - Fianarantsoa - WFI - Fianarantsoa - Madagascar",
            "searchName": "WFI-Fianarantsoa-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WTA",
            "countryCode": "MG",
            "cityCode": "WTA",
            "cityName": "Tambohorano",
            "name": "[WTA] - Tambohorano - WTA - Tambohorano - Madagascar",
            "searchName": "WTA-Tambohorano-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WTS",
            "countryCode": "MG",
            "cityCode": "WTS",
            "cityName": "Tsiroanomandidy",
            "name": "[WTS] - Tsiroanomandidy - WTS - Tsiroanomandidy - Madagascar",
            "searchName": "WTS-Tsiroanomandidy-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "WVK",
            "countryCode": "MG",
            "cityCode": "WVK",
            "cityName": "Manakara",
            "name": "[WVK] - Manakara - WVK - Manakara - Madagascar",
            "searchName": "WVK-Manakara-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "MJA",
            "countryCode": "MG",
            "cityCode": "MJA",
            "cityName": "Manja",
            "name": "[MJA] - Manja - MJA - Manja - Madagascar",
            "searchName": "MJA-Manja-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "MJN",
            "countryCode": "MG",
            "cityCode": "MJN",
            "cityName": "Majunga",
            "name": "[MJN] - Amborovy - MJN - Majunga - Madagascar",
            "searchName": "MJN-Mahajanga-Amborovy-Majunga-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "MNJ",
            "countryCode": "MG",
            "cityCode": "MNJ",
            "cityName": "Mananjary",
            "name": "[MNJ] - Mananjary - MNJ - Mananjary - Madagascar",
            "searchName": "MNJ-Mananjary-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "MOQ",
            "countryCode": "MG",
            "cityCode": "MOQ",
            "cityName": "Morondava",
            "name": "[MOQ] - Morondava - MOQ - Morondava - Madagascar",
            "searchName": "MOQ-Morondava-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "NOS",
            "countryCode": "MG",
            "cityCode": "NOS",
            "cityName": "Nossi-Be",
            "name": "[NOS] - Fascene - NOS - Nossi-Be - Madagascar",
            "searchName": "NOS-Nossi-Fascene-be-Be-Nosy Be-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "OHB",
            "countryCode": "MG",
            "cityCode": "OHB",
            "cityName": "Moramanga",
            "name": "[OHB] - Ambohibary - OHB - Moramanga - Madagascar",
            "searchName": "OHB-Ambohibary-Moramanga-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "NKO",
            "countryCode": "MG",
            "cityCode": "NKO",
            "cityName": "Ankokoambo",
            "name": "[NKO] - Ankokoambo - NKO - Ankokoambo - Madagascar",
            "searchName": "NKO-Ankokoambo-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "MXM",
            "countryCode": "MG",
            "cityCode": "MXM",
            "cityName": "Morombe",
            "name": "[MXM] - Morombe - MXM - Morombe - Madagascar",
            "searchName": "MXM-Morombe-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "MXT",
            "countryCode": "MG",
            "cityCode": "MXT",
            "cityName": "Maintirano",
            "name": "[MXT] - Maintirano - MXT - Maintirano - Madagascar",
            "searchName": "MXT-Maintirano-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "OVA",
            "countryCode": "MG",
            "cityCode": "OVA",
            "cityName": "Bekily",
            "name": "[OVA] - Bekily - OVA - Bekily - Madagascar",
            "searchName": "OVA-Bekily-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "RLR",
            "countryCode": "MG",
            "cityCode": "RLR",
            "cityName": "Isalo",
            "name": "[RLR] - Relais de la Reine - RLR - Isalo - Madagascar",
            "searchName": "RLR-Relais de la Reine-Isalo-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "RVA",
            "countryCode": "MG",
            "cityCode": "RVA",
            "cityName": "Farafangana",
            "name": "[RVA] - Farafangana - RVA - Farafangana - Madagascar",
            "searchName": "RVA-Farafangana-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "IVA",
            "countryCode": "MG",
            "cityCode": "IVA",
            "cityName": "Ambanja",
            "name": "[IVA] - Ambanja - IVA - Ambanja - Madagascar",
            "searchName": "IVA-Ambanja-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "ILK",
            "countryCode": "MG",
            "cityCode": "ILK",
            "cityName": "Ilaka",
            "name": "[ILK] - Ilaka - ILK - Ilaka - Madagascar",
            "searchName": "ILK-Ilaka-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "IHO",
            "countryCode": "MG",
            "cityCode": "IHO",
            "cityName": "Ihosy",
            "name": "[IHO] - Ihosy - IHO - Ihosy - Madagascar",
            "searchName": "IHO-Ihosy-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "HVA",
            "countryCode": "MG",
            "cityCode": "HVA",
            "cityName": "Analalava",
            "name": "[HVA] - Analalava - HVA - Analalava - Madagascar",
            "searchName": "HVA-Analalava-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "FTU",
            "countryCode": "MG",
            "cityCode": "FTU",
            "cityName": "Fort Dauphin",
            "name": "[FTU] - Marillac - FTU - Fort Dauphin - Madagascar",
            "searchName": "FTU-Fort Dauphin-Marillac-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "JVA",
            "countryCode": "MG",
            "cityCode": "JVA",
            "cityName": "Ankavandra",
            "name": "[JVA] - Ankavandra - JVA - Ankavandra - Madagascar",
            "searchName": "JVA-Ankavandra-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "BZM",
            "countryCode": "MG",
            "cityCode": "BZM",
            "cityName": "Bergen Op Zoom",
            "name": "[BZM] - Woensdrecht - BZM - Bergen Op Zoom - Madagascar",
            "searchName": "BZM-Woensdrecht-Bergen Op Zoom-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "DIE",
            "countryCode": "MG",
            "cityCode": "DIE",
            "cityName": "Antsiranana",
            "name": "[DIE] - Antsiranana/Arrachart - DIE - Antsiranana - Madagascar",
            "searchName": "DIE-Antsiranana-Antsiranana/Arrachart-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "DOA",
            "countryCode": "MG",
            "cityCode": "DOA",
            "cityName": "Doany",
            "name": "[DOA] - Doany - DOA - Doany - Madagascar",
            "searchName": "DOA-Doany-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "DWB",
            "countryCode": "MG",
            "cityCode": "DWB",
            "cityName": "Soalala",
            "name": "[DWB] - Soalala - DWB - Soalala - Madagascar",
            "searchName": "DWB-Soalala-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "DVD",
            "countryCode": "MG",
            "cityCode": "DVD",
            "cityName": "Andavadoaka",
            "name": "[DVD] - Andavadoaka - DVD - Andavadoaka - Madagascar",
            "searchName": "DVD-Andavadoaka-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "AHY",
            "countryCode": "MG",
            "cityCode": "AHY",
            "cityName": "Ambatolahy",
            "name": "[AHY] - Ambatolahy - AHY - Ambatolahy - Madagascar",
            "searchName": "AHY-Ambatolahy-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "AMB",
            "countryCode": "MG",
            "cityCode": "AMB",
            "cityName": "Ambilobe",
            "name": "[AMB] - Ambilobe - AMB - Ambilobe - Madagascar",
            "searchName": "AMB-Ambilobe-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "AMP",
            "countryCode": "MG",
            "cityCode": "AMP",
            "cityName": "Ampanihy",
            "name": "[AMP] - Ampanihy - AMP - Ampanihy - Madagascar",
            "searchName": "AMP-Ampanihy-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "ANM",
            "countryCode": "MG",
            "cityCode": "ANM",
            "cityName": "Antalaha",
            "name": "[ANM] - Antsirabato - ANM - Antalaha - Madagascar",
            "searchName": "ANM-Antalaha-Antsirabato-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "AMY",
            "countryCode": "MG",
            "cityCode": "AMY",
            "cityName": "Ambatomainty",
            "name": "[AMY] - Ambatomainty - AMY - Ambatomainty - Madagascar",
            "searchName": "AMY-Ambatomainty-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "ATJ",
            "countryCode": "MG",
            "cityCode": "ATJ",
            "cityName": "Antsirabe",
            "name": "[ATJ] - Antsirabe - ATJ - Antsirabe - Madagascar",
            "searchName": "ATJ-Antsirabe-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "BMD",
            "countryCode": "MG",
            "cityCode": "BMD",
            "cityName": "Belo",
            "name": "[BMD] - Belo - BMD - Belo - Madagascar",
            "searchName": "BMD-Belo-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "BKU",
            "countryCode": "MG",
            "cityCode": "BKU",
            "cityName": "Betioky",
            "name": "[BKU] - Betioky - BKU - Betioky - Madagascar",
            "searchName": "BKU-Betioky-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "BSV",
            "countryCode": "MG",
            "cityCode": "BSV",
            "cityName": "Besakoa",
            "name": "[BSV] - Besakoa - BSV - Besakoa - Madagascar",
            "searchName": "BSV-Besakoa-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "BPY",
            "countryCode": "MG",
            "cityCode": "BPY",
            "cityName": "Besalampy",
            "name": "[BPY] - Besalampy - BPY - Besalampy - Madagascar",
            "searchName": "BPY-Besalampy-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "ZWA",
            "countryCode": "MG",
            "cityCode": "ZWA",
            "cityName": "Andapa",
            "name": "[ZWA] - Andapa - ZWA - Andapa - Madagascar",
            "searchName": "ZWA-Andapa-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "ZVA",
            "countryCode": "MG",
            "cityCode": "ZVA",
            "cityName": "Miandrivazo",
            "name": "[ZVA] - Miandrivazo - ZVA - Miandrivazo - Madagascar",
            "searchName": "ZVA-Miandrivazo-Madagascar-Madagaskar-Madagaszkár-Madagaskaras",
            "priority": 0
        },
        {
            "iataCode": "BII",
            "countryCode": "MH",
            "cityCode": "BII",
            "cityName": "Bikini Atoll",
            "name": "[BII] - Enyu Airfield - BII - Bikini Atoll - Marshall Islands",
            "searchName": "BII-Flugplatz Enyu-Enyu Airfield-Bikini Atoll-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "AUL",
            "countryCode": "MH",
            "cityCode": "AUL",
            "cityName": "Aur Island",
            "name": "[AUL] - Aur Island - AUL - Aur Island - Marshall Islands",
            "searchName": "AUL-Aur Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "AMR",
            "countryCode": "MH",
            "cityCode": "AMR",
            "cityName": "Arno",
            "name": "[AMR] - Arno - AMR - Arno - Marshall Islands",
            "searchName": "AMR-Arno-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "AIP",
            "countryCode": "MH",
            "cityCode": "AIP",
            "cityName": "Ailinglapalap Island",
            "name": "[AIP] - Ailinglapalap Island - AIP - Ailinglapalap Island - Marshall Islands",
            "searchName": "AIP-Ailinglapalap Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "AIM",
            "countryCode": "MH",
            "cityCode": "AIM",
            "cityName": "Ailuk Island",
            "name": "[AIM] - Ailuk Island - AIM - Ailuk Island - Marshall Islands",
            "searchName": "AIM-Ailuk Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "AIC",
            "countryCode": "MH",
            "cityCode": "AIC",
            "cityName": "Airok",
            "name": "[AIC] - Airok - AIC - Airok - Marshall Islands",
            "searchName": "AIC-Airok-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "EAL",
            "countryCode": "MH",
            "cityCode": "EAL",
            "cityName": "Kwajalein Atoll",
            "name": "[EAL] - Elenak - EAL - Kwajalein Atoll - Marshall Islands",
            "searchName": "EAL-Elenak-Vila Real-Kwajalein Atoll-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "EJT",
            "countryCode": "MH",
            "cityCode": "EJT",
            "cityName": "Mili Atoll",
            "name": "[EJT] - Enijet - EJT - Mili Atoll - Marshall Islands",
            "searchName": "EJT-Enijet-Mili Atoll-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "ENT",
            "countryCode": "MH",
            "cityCode": "ENT",
            "cityName": "Enewetak Island",
            "name": "[ENT] - Enewetak Island - ENT - Enewetak Island - Marshall Islands",
            "searchName": "ENT-Enewetak Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "EBN",
            "countryCode": "MH",
            "cityCode": "EBN",
            "cityName": "Ebadon",
            "name": "[EBN] - Ebadon - EBN - Ebadon - Marshall Islands",
            "searchName": "EBN-Ebadon-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "EBO",
            "countryCode": "MH",
            "cityCode": "EBO",
            "cityName": "Ebon",
            "name": "[EBO] - Ebon Airport - EBO - Ebon - Marshall Islands",
            "searchName": "EBO-Ebon Airport-Ebon-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "KBT",
            "countryCode": "MH",
            "cityCode": "KBT",
            "cityName": "Kaben",
            "name": "[KBT] - Kaben - KBT - Kaben - Marshall Islands",
            "searchName": "KBT-Kaben-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "JEJ",
            "countryCode": "MH",
            "cityCode": "JEJ",
            "cityName": "Jeh",
            "name": "[JEJ] - Jeh - JEJ - Jeh - Marshall Islands",
            "searchName": "JEJ-Jeh-Jeju-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "JAT",
            "countryCode": "MH",
            "cityCode": "JAT",
            "cityName": "Jabot",
            "name": "[JAT] - Jabot - JAT - Jabot - Marshall Islands",
            "searchName": "JAT-Jabot-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "KIO",
            "countryCode": "MH",
            "cityCode": "KIO",
            "cityName": "Kili Island",
            "name": "[KIO] - Kili Island - KIO - Kili Island - Marshall Islands",
            "searchName": "KIO-Kili Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "KWA",
            "countryCode": "MH",
            "cityCode": "KWA",
            "cityName": "Kwajalein",
            "name": "[KWA] - Kwajalein - KWA - Kwajalein - Marshall Islands",
            "searchName": "KWA-Kwajalein-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "LIK",
            "countryCode": "MH",
            "cityCode": "LIK",
            "cityName": "Likiep Island",
            "name": "[LIK] - Likiep Island - LIK - Likiep Island - Marshall Islands",
            "searchName": "LIK-Likiep Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "LML",
            "countryCode": "MH",
            "cityCode": "LML",
            "cityName": "Lae Island",
            "name": "[LML] - Lae Island - LML - Lae Island - Marshall Islands",
            "searchName": "LML-Lae Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "LOF",
            "countryCode": "MH",
            "cityCode": "LOF",
            "cityName": "Loen",
            "name": "[LOF] - Loen - LOF - Loen - Marshall Islands",
            "searchName": "LOF-Loen-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "IMI",
            "countryCode": "MH",
            "cityCode": "IMI",
            "cityName": "Ine Island",
            "name": "[IMI] - Ine Island - IMI - Ine Island - Marshall Islands",
            "searchName": "IMI-Ine Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "RNP",
            "countryCode": "MH",
            "cityCode": "RNP",
            "cityName": "Rongelap Island",
            "name": "[RNP] - Rongelap Island - RNP - Rongelap Island - Marshall Islands",
            "searchName": "RNP-Rongelap Island-Rongelap-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "NDK",
            "countryCode": "MH",
            "cityCode": "NDK",
            "cityName": "Namdrik Island",
            "name": "[NDK] - Namdrik Island - NDK - Namdrik Island - Marshall Islands",
            "searchName": "NDK-Namdrik Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "NMU",
            "countryCode": "MH",
            "cityCode": "NMU",
            "cityName": "Namu",
            "name": "[NMU] - Namu - NMU - Namu - Marshall Islands",
            "searchName": "NMU-Namu-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "MJB",
            "countryCode": "MH",
            "cityCode": "MJB",
            "cityName": "Mejit Island",
            "name": "[MJB] - Mejit Island - MJB - Mejit Island - Marshall Islands",
            "searchName": "MJB-Mejit Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "MJE",
            "countryCode": "MH",
            "cityCode": "MJE",
            "cityName": "Majkin",
            "name": "[MJE] - Majkin - MJE - Majkin - Marshall Islands",
            "searchName": "MJE-Majkin-Majkin Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "MIJ",
            "countryCode": "MH",
            "cityCode": "MIJ",
            "cityName": "Mili Island",
            "name": "[MIJ] - Mili Island - MIJ - Mili Island - Marshall Islands",
            "searchName": "MIJ-Mili Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "MAJ",
            "countryCode": "MH",
            "cityCode": "MAJ",
            "cityName": "Majuro",
            "name": "[MAJ] - Amata Kabua International - MAJ - Majuro - Marshall Islands",
            "searchName": "MAJ-Majuro-Amata Kabua International-Amata Kabua Intl-Uliga-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "MAV",
            "countryCode": "MH",
            "cityCode": "MAV",
            "cityName": "Maloelap Island",
            "name": "[MAV] - Maloelap Island - MAV - Maloelap Island - Marshall Islands",
            "searchName": "MAV-Maloelap Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "WTO",
            "countryCode": "MH",
            "cityCode": "WTO",
            "cityName": "Wotho Island",
            "name": "[WTO] - Wotho Island - WTO - Wotho Island - Marshall Islands",
            "searchName": "WTO-Wotho Island-Wotho-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "WTE",
            "countryCode": "MH",
            "cityCode": "WTE",
            "cityName": "Wotje Island",
            "name": "[WTE] - Wotje Island - WTE - Wotje Island - Marshall Islands",
            "searchName": "WTE-Wotje Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "WJA",
            "countryCode": "MH",
            "cityCode": "WJA",
            "cityName": "Woja",
            "name": "[WJA] - Woja - WJA - Woja - Marshall Islands",
            "searchName": "WJA-Woja-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "UTK",
            "countryCode": "MH",
            "cityCode": "UTK",
            "cityName": "Utirik Island",
            "name": "[UTK] - Utirik Island - UTK - Utirik Island - Marshall Islands",
            "searchName": "UTK-Utirik Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "UIT",
            "countryCode": "MH",
            "cityCode": "UIT",
            "cityName": "Jaluit Island",
            "name": "[UIT] - Jaluit Island - UIT - Jaluit Island - Marshall Islands",
            "searchName": "UIT-Jaluit Island-Jaluit-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "UJE",
            "countryCode": "MH",
            "cityCode": "UJE",
            "cityName": "Ujae Island",
            "name": "[UJE] - Ujae Island - UJE - Ujae Island - Marshall Islands",
            "searchName": "UJE-Ujae Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "TIC",
            "countryCode": "MH",
            "cityCode": "TIC",
            "cityName": "Tinak Island",
            "name": "[TIC] - Tinak Island - TIC - Tinak Island - Marshall Islands",
            "searchName": "TIC-Tinak Island-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "TBV",
            "countryCode": "MH",
            "cityCode": "TBV",
            "cityName": "Tabal",
            "name": "[TBV] - Tabal - TBV - Tabal - Marshall Islands",
            "searchName": "TBV-Tabal-Marshall Islands-Marshallinseln-Marshall Adalari-Islas Marshall-Îles Marshall-Isole Marshall",
            "priority": 0
        },
        {
            "iataCode": "MRE",
            "countryCode": "KE",
            "cityCode": "MRE",
            "cityName": "Mara Lodges",
            "name": "[MRE] - Mara Lodges - MRE - Mara Lodges - Kenya",
            "searchName": "MRE-Mara Lodges-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "HOA",
            "countryCode": "KE",
            "cityCode": "HOA",
            "cityName": "Hola",
            "name": "[HOA] - Hola - HOA - Hola - Kenya",
            "searchName": "HOA-Hola-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "ILU",
            "countryCode": "KE",
            "cityCode": "ILU",
            "cityName": "Kilaguni",
            "name": "[ILU] - Kilaguni - ILU - Kilaguni - Kenya",
            "searchName": "ILU-Kilaguni-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "GAS",
            "countryCode": "KE",
            "cityCode": "GAS",
            "cityName": "Garissa",
            "name": "[GAS] - Garissa - GAS - Garissa - Kenya",
            "searchName": "GAS-Garissa-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "GGM",
            "countryCode": "KE",
            "cityCode": "GGM",
            "cityName": "Kakamega",
            "name": "[GGM] - Kakamega - GGM - Kakamega - Kenya",
            "searchName": "GGM-Kakamega Airport-Kakamega-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "LBK",
            "countryCode": "KE",
            "cityCode": "LBK",
            "cityName": "Liboi",
            "name": "[LBK] - Liboi - LBK - Liboi - Kenya",
            "searchName": "LBK-Liboi-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "LBN",
            "countryCode": "KE",
            "cityCode": "LBN",
            "cityName": "Lake Baringo",
            "name": "[LBN] - Lake Baringo - LBN - Lake Baringo - Kenya",
            "searchName": "LBN-Lake Baringo-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "LAU",
            "countryCode": "KE",
            "cityCode": "LAU",
            "cityName": "Lamu",
            "name": "[LAU] - Lamu - LAU - Lamu - Kenya",
            "searchName": "LAU-Lamu-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "KWY",
            "countryCode": "KE",
            "cityCode": "KWY",
            "cityName": "Kiwayu",
            "name": "[KWY] - Kiwayu - KWY - Kiwayu - Kenya",
            "searchName": "KWY-Kiwayu-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "LKG",
            "countryCode": "KE",
            "cityCode": "LKG",
            "cityName": "Lokichoggio",
            "name": "[LKG] - Lokichoggio - LKG - Lokichoggio - Kenya",
            "searchName": "LKG-Lokichoggio-Lokichogio-Lokichogio Havalimani-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "LKU",
            "countryCode": "KE",
            "cityCode": "LKU",
            "cityName": "Lake Rudolf",
            "name": "[LKU] - Lake Rudolf - LKU - Lake Rudolf - Kenya",
            "searchName": "LKU-Lake Rudolf-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "LOK",
            "countryCode": "KE",
            "cityCode": "LOK",
            "cityName": "Lodwar",
            "name": "[LOK] - Lodwar - LOK - Lodwar - Kenya",
            "searchName": "LOK-Lodwar-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "LOY",
            "countryCode": "KE",
            "cityCode": "LOY",
            "cityName": "Loyangalani",
            "name": "[LOY] - Loyangalani - LOY - Loyangalani - Kenya",
            "searchName": "LOY-Loyangalani-Loiyangalani-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "KEY",
            "countryCode": "KE",
            "cityCode": "KEY",
            "cityName": "Kericho",
            "name": "[KEY] - Kericho - KEY - Kericho - Kenya",
            "searchName": "KEY-Kericho-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "JJM",
            "countryCode": "KE",
            "cityCode": "JJM",
            "cityName": "Meru-Kinna",
            "name": "[JJM] - Mulika Lodge - JJM - Meru-Kinna - Kenya",
            "searchName": "JJM-Mulika Lodge-Meru-Kinna-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "KTL",
            "countryCode": "KE",
            "cityCode": "KTL",
            "cityName": "Kitale",
            "name": "[KTL] - Kitale - KTL - Kitale - Kenya",
            "searchName": "KTL-Kitale-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "KRV",
            "countryCode": "KE",
            "cityCode": "KRV",
            "cityName": "Kerio Valley",
            "name": "[KRV] - Kerio Valley - KRV - Kerio Valley - Kenya",
            "searchName": "KRV-Kerio Valley-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "KLK",
            "countryCode": "KE",
            "cityCode": "KLK",
            "cityName": "Kalokol",
            "name": "[KLK] - Kalokol - KLK - Kalokol - Kenya",
            "searchName": "KLK-Kalokol-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "KIS",
            "countryCode": "KE",
            "cityCode": "KIS",
            "cityName": "Kisumu",
            "name": "[KIS] - Kisumu - KIS - Kisumu - Kenya",
            "searchName": "KIS-Kisumu-Kisumu Havalimani-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "KIU",
            "countryCode": "KE",
            "cityCode": "KIU",
            "cityName": "Kiunga",
            "name": "[KIU] - Kiunga - KIU - Kiunga - Kenya",
            "searchName": "KIU-Kiunga-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "BMQ",
            "countryCode": "KE",
            "cityCode": "BMQ",
            "cityName": "Bamburi",
            "name": "[BMQ] - Bamburi - BMQ - Bamburi - Kenya",
            "searchName": "BMQ-Bamburi-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "ASV",
            "countryCode": "KE",
            "cityCode": "ASV",
            "cityName": "Amboseli",
            "name": "[ASV] - Amboseli - ASV - Amboseli - Kenya",
            "searchName": "ASV-Amboseli-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "EDL",
            "countryCode": "KE",
            "cityCode": "EDL",
            "cityName": "Eldoret",
            "name": "[EDL] - Eldoret - EDL - Eldoret - Kenya",
            "searchName": "EDL-Eldoret-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "EYS",
            "countryCode": "KE",
            "cityCode": "EYS",
            "cityName": "Eliye Springs",
            "name": "[EYS] - Eliye Springs - EYS - Eliye Springs - Kenya",
            "searchName": "EYS-Eliye Springs-Elive Springs-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "HND",
            "countryCode": "JP",
            "cityCode": "TYO",
            "cityName": "Tokyo",
            "name": "[HND] - Haneda Airport - TYO - Tokyo - Japan",
            "searchName": "HND-TYO-Tokio Haneda-Haneda Airport-Haneda-Tokio-Tokyo-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "LMJ",
            "countryCode": "JP",
            "cityCode": "TYO",
            "cityName": "Tokyo",
            "name": "[LMJ] - Tokyo Bus Station - TYO - Tokyo - Japan",
            "searchName": "LMJ-TYO-Tokyo Bus Station-Tokio-Tokyo-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "TOY",
            "countryCode": "JP",
            "cityCode": "TOY",
            "cityName": "Toyama",
            "name": "[TOY] - Toyama - TOY - Toyama - Japan",
            "searchName": "TOY-Toyama-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "TKN",
            "countryCode": "JP",
            "cityCode": "TKN",
            "cityName": "Tokunoshima",
            "name": "[TKN] - Tokunoshima - TKN - Tokunoshima - Japan",
            "searchName": "TKN-Tokuno Shima-Tokunoshima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "TKS",
            "countryCode": "JP",
            "cityCode": "TKS",
            "cityName": "Tokushima",
            "name": "[TKS] - Tokushima - TKS - Tokushima - Japan",
            "searchName": "TKS-Tokushima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "TNE",
            "countryCode": "JP",
            "cityCode": "TNE",
            "cityName": "Tanegashima",
            "name": "[TNE] - Tanegashima - TNE - Tanegashima - Japan",
            "searchName": "TNE-Tanegashima-Tanega Shima Havalimani-Tanega Shima-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "TAK",
            "countryCode": "JP",
            "cityCode": "TAK",
            "cityName": "Takamatsu",
            "name": "[TAK] - Takamatsu - TAK - Takamatsu - Japan",
            "searchName": "TAK-Takamatsu-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "SYO",
            "countryCode": "JP",
            "cityCode": "SYO",
            "cityName": "Shonai",
            "name": "[SYO] - Shonai - SYO - Shonai - Japan",
            "searchName": "SYO-Shonai-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "TJH",
            "countryCode": "JP",
            "cityCode": "TJH",
            "cityName": "Toyooka",
            "name": "[TJH] - Tajima - TJH - Toyooka - Japan",
            "searchName": "TJH-Tajima-Kounotori Tajima Havalimani-Toyooka-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "YGJ",
            "countryCode": "JP",
            "cityCode": "YGJ",
            "cityName": "Yonago",
            "name": "[YGJ] - Miho - YGJ - Yonago - Japan",
            "searchName": "YGJ-Yonago-Miho-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "YOK",
            "countryCode": "JP",
            "cityCode": "YOK",
            "cityName": "Yokohama",
            "name": "[YOK] - Yokohama - YOK - Yokohama - Japan",
            "searchName": "YOK-Yokohama-Japó-Japonya-Japonsko-Japani-Japan-Japonia-Jepang-Japán-Jaapan-Japana-Japonija-Japonska-Japón-Giappone-Japon-Jepun-",
            "priority": 0
        },
        {
            "iataCode": "UKA",
            "countryCode": "KE",
            "cityCode": "UKA",
            "cityName": "Ukunda",
            "name": "[UKA] - Ukunda - UKA - Ukunda - Kenya",
            "searchName": "UKA-Ukunda-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "UAS",
            "countryCode": "KE",
            "cityCode": "UAS",
            "cityName": "Samburu",
            "name": "[UAS] - Samburu - UAS - Samburu - Kenya",
            "searchName": "UAS-Samburu-Samburu National Reserve-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "WJR",
            "countryCode": "KE",
            "cityCode": "WJR",
            "cityName": "Wajir",
            "name": "[WJR] - Wajir - WJR - Wajir - Kenya",
            "searchName": "WJR-Wajir-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "VPG",
            "countryCode": "KE",
            "cityCode": "VPG",
            "cityName": "Vipingo",
            "name": "[VPG] - Vipingo - VPG - Vipingo - Kenya",
            "searchName": "VPG-Vipingo-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "RBT",
            "countryCode": "KE",
            "cityCode": "RBT",
            "cityName": "Marsabit",
            "name": "[RBT] - Marsabit - RBT - Marsabit - Kenya",
            "searchName": "RBT-Marsabit-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "OYL",
            "countryCode": "KE",
            "cityCode": "OYL",
            "cityName": "Moyale",
            "name": "[OYL] - Moyale - OYL - Moyale - Kenya",
            "searchName": "OYL-Moyale-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "MYD",
            "countryCode": "KE",
            "cityCode": "MYD",
            "cityName": "Malindi",
            "name": "[MYD] - Malindi - MYD - Malindi - Kenya",
            "searchName": "MYD-Malindi-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "NDE",
            "countryCode": "KE",
            "cityCode": "NDE",
            "cityName": "Mandera",
            "name": "[NDE] - Mandera - NDE - Mandera - Kenya",
            "searchName": "NDE-Mandera-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "LYB",
            "countryCode": "KY",
            "cityCode": "LYB",
            "cityName": "Little Cayman",
            "name": "[LYB] - Little Cayman - LYB - Little Cayman - Cayman Islands",
            "searchName": "LYB-Little Cayman-Little Cayman Airport-Cayman Islands-Kaimaninseln-Cayman Adalari-Islas Caimán-Îles Caïmans-Isole Cayman",
            "priority": 0
        },
        {
            "iataCode": "GCM",
            "countryCode": "KY",
            "cityCode": "GCM",
            "cityName": "Georgetown",
            "name": "[GCM] - Owen Roberts International - GCM - Georgetown - Cayman Islands",
            "searchName": "GCM-Grand Cayman-Owen Roberts International-Owen Roberts Intl-Grand Cayman Island-Georgetown--Cayman Islands-Kaimaninseln-Cayman Adalari-Islas Caimán-Îles Caïmans-Isole Cayman",
            "priority": 0
        },
        {
            "iataCode": "CYB",
            "countryCode": "KY",
            "cityCode": "CYB",
            "cityName": "Cayman Brac Island",
            "name": "[CYB] - Gerrard-Smith - CYB - Cayman Brac Island - Cayman Islands",
            "searchName": "CYB-Cayman Brac-Gerrard-Smith-Cayman Brac Island-Cayman Brac Is-Cayman Islands-Kaimaninseln-Cayman Adalari-Islas Caimán-Îles Caïmans-Isole Cayman",
            "priority": 0
        },
        {
            "iataCode": "CIT",
            "countryCode": "KZ",
            "cityCode": "CIT",
            "cityName": "Shimkent",
            "name": "[CIT] - Shimkent - CIT - Shimkent - Kazakhstan",
            "searchName": "CIT-Shimkent-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "DZN",
            "countryCode": "KZ",
            "cityCode": "DZN",
            "cityName": "Zhezkazgan",
            "name": "[DZN] - Zhezhazgan - DZN - Zhezkazgan - Kazakhstan",
            "searchName": "DZN-Zhezqazghan-Zhezhazgan-Zhezkazgan Havalimani-Zhezkazgan-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "DMB",
            "countryCode": "KZ",
            "cityCode": "DMB",
            "cityName": "Zhambyl",
            "name": "[DMB] - Zhambyl - DMB - Zhambyl - Kazakhstan",
            "searchName": "DMB-Zhambyl-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "EKB",
            "countryCode": "KZ",
            "cityCode": "EKB",
            "cityName": "Ekibastuz",
            "name": "[EKB] - Ekibastuz - EKB - Ekibastuz - Kazakhstan",
            "searchName": "EKB-Ekibastuz-Ekibastuz Havalimani-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "AKX",
            "countryCode": "KZ",
            "cityCode": "AKX",
            "cityName": "Aktyubinsk",
            "name": "[AKX] - Aktyubinsk - AKX - Aktyubinsk - Kazakhstan",
            "searchName": "AKX-Aqtobe-Aktyubinsk-Aktepe-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "SUK",
            "countryCode": "KR",
            "cityCode": "SUK",
            "cityName": "Samcheok",
            "name": "[SUK] - Samcheok - SUK - Samcheok - South Korea",
            "searchName": "SUK-Samcheok-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "SWU",
            "countryCode": "KR",
            "cityCode": "SWU",
            "cityName": "Suwon",
            "name": "[SWU] - Suwon - SWU - Suwon - South Korea",
            "searchName": "SWU-Suwon-Suwon Havalimani-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "TAE",
            "countryCode": "KR",
            "cityCode": "TAE",
            "cityName": "Daegu",
            "name": "[TAE] - Daegu - TAE - Daegu - South Korea",
            "searchName": "TAE-Taegu-Daegu-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "SYS",
            "countryCode": "KR",
            "cityCode": "SYS",
            "cityName": "Suncheon",
            "name": "[SYS] - Yeosu - SYS - Suncheon - South Korea",
            "searchName": "SYS-Yeosu-Suncheon-Coréia do Sul-Zuid-Korea-Korea Selatan-South Korea-Corée du Sud-Südkorea-Corea del Sud-Corea del Sur-Sydkorea-Južna K-Güney Kore",
            "priority": 0
        },
        {
            "iataCode": "XIJ",
            "countryCode": "KW",
            "cityCode": "XIJ",
            "cityName": "Ahmed Al Jaber",
            "name": "[XIJ] - Ahmed Al Jaber - XIJ - Ahmed Al Jaber - Kuwait",
            "searchName": "XIJ-Ahmed Al Jaber-Kuwait-Koeweit-Koweït-Kuvajt-Kuveitas-Kuweit-Kuveyt",
            "priority": 0
        },
        {
            "iataCode": "WIL",
            "countryCode": "KE",
            "cityCode": "NBO",
            "cityName": "Nairobi",
            "name": "[WIL] - Wilson - NBO - Nairobi - Kenya",
            "searchName": "WIL-NBO-Nairobi Wilson-Wilson-Nairobi-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "NUU",
            "countryCode": "KE",
            "cityCode": "NUU",
            "cityName": "Nakuru",
            "name": "[NUU] - Nakuru - NUU - Nakuru - Kenya",
            "searchName": "NUU-Nakuru-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "NYK",
            "countryCode": "KE",
            "cityCode": "NYK",
            "cityName": "Nanyuki",
            "name": "[NYK] - Nanyuki - NYK - Nanyuki - Kenya",
            "searchName": "NYK-Nanyuki-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "NYE",
            "countryCode": "KE",
            "cityCode": "NYE",
            "cityName": "Nyeri",
            "name": "[NYE] - Nyeri - NYE - Nyeri - Kenya",
            "searchName": "NYE-Nyeri-Nyeri Havalimani-Kenia-Kenya-Kê nya-Quênia-Kenija",
            "priority": 0
        },
        {
            "iataCode": "KTP",
            "countryCode": "JM",
            "cityCode": "KIN",
            "cityName": "Kingston",
            "name": "[KTP] - Tinson - KIN - Kingston - Jamaica",
            "searchName": "KTP-KIN-Tinson-Kingston-Jamaica-Jamaika-Jamajka-Jamaïque-Gia-Giamaica",
            "priority": 0
        },
        {
            "iataCode": "OSS",
            "countryCode": "KG",
            "cityCode": "OSS",
            "cityName": "Osh",
            "name": "[OSS] - Osh International Airport - OSS - Osh - Kyrgyzstan",
            "searchName": "OSS-Osh Kirgistan-Osh International Airport-Osh-Kirgisistan-Kyrgyzstan-Kirgizistan-Kirghizistan-Kirguistán",
            "priority": 0
        },
        {
            "iataCode": "OMY",
            "countryCode": "KH",
            "cityCode": "OMY",
            "cityName": "Oddor Meanche",
            "name": "[OMY] - Oddor Meanche - OMY - Oddor Meanche - Cambodia",
            "searchName": "OMY-Oddor Meanche-Oddor Meanchey-Cambodia-Kamboja-Kambodja-Cam pu chia-Cambodja-Camboja-Camboya-Cambogia-Kambodscha-Cambodge-Kambodza-Kambodža-Ka-Kamboçya",
            "priority": 0
        },
        {
            "iataCode": "NEG",
            "countryCode": "JM",
            "cityCode": "NEG",
            "cityName": "Negril",
            "name": "[NEG] - Negril - NEG - Negril - Jamaica",
            "searchName": "NEG-Negril-Jamaica-Jamaika-Jamajka-Jamaïque-Gia-Giamaica",
            "priority": 0
        },
        {
            "iataCode": "OCJ",
            "countryCode": "JM",
            "cityCode": "OCJ",
            "cityName": "Ocho Rios",
            "name": "[OCJ] - Boscobel - OCJ - Ocho Rios - Jamaica",
            "searchName": "OCJ-Boscobel-Ocho Rios-Jamaica-Jamaika-Jamajka-Jamaïque-Gia-Giamaica",
            "priority": 0
        },
        {
            "iataCode": "POT",
            "countryCode": "JM",
            "cityCode": "POT",
            "cityName": "Port Antonio",
            "name": "[POT] - Ken Jones - POT - Port Antonio - Jamaica",
            "searchName": "POT-Ken Jones-Port Antonio-Jamaica-Jamaika-Jamajka-Jamaïque-Gia-Giamaica",
            "priority": 0
        },
        {
            "iataCode": "OMF",
            "countryCode": "JO",
            "cityCode": "OMF",
            "cityName": "Mafraq",
            "name": "[OMF] - King Hussein - OMF - Mafraq - Jordan",
            "searchName": "OMF-King Hussein-Mafraq-Jordánia-Jordània-Ürdün-Jordan-Jordánsko-Jordania-Jordaania-Jordanija-Iordania-Yordania-Jordanien-Jordânia-Jordani-Jordanie-Giordania",
            "priority": 0
        },
        {
            "iataCode": "MPQ",
            "countryCode": "JO",
            "cityCode": "MPQ",
            "cityName": "Maan",
            "name": "[MPQ] - Maan - MPQ - Maan - Jordan",
            "searchName": "MPQ-Maan-Maan Havalimani-Jordánia-Jordània-Ürdün-Jordan-Jordánsko-Jordania-Jordaania-Jordanija-Iordania-Yordania-Jordanien-Jordânia-Jordani-Jordanie-Giordania",
            "priority": 0
        },
        {
            "iataCode": "UDN",
            "countryCode": "IT",
            "cityCode": "UDN",
            "cityName": "Udine",
            "name": "[UDN] - Airfield - UDN - Udine - Italy",
            "searchName": "UDN-Airfield-Campoformido Havalimani-Udine-Italy-Itaalia-Wlochy-Italia-Italien-Itálie-Italya-Itàlia-Olaszország-Italija-Itália-Italie-",
            "priority": 0
        },
        {
            "iataCode": "UKK",
            "countryCode": "KZ",
            "cityCode": "UKK",
            "cityName": "Ust-kamenogorsk",
            "name": "[UKK] - Ust-kamenogorsk - UKK - Ust-kamenogorsk - Kazakhstan",
            "searchName": "UKK-Öskemen-Ust-kamenogorsk-Kamenogorsk-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "URA",
            "countryCode": "KZ",
            "cityCode": "URA",
            "cityName": "Uralsk",
            "name": "[URA] - Uralsk / Podstepnyy - URA - Uralsk - Kazakhstan",
            "searchName": "URA-Oral-Uralsk / Podstepnyy-Uralsk-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "SZI",
            "countryCode": "KZ",
            "cityCode": "SZI",
            "cityName": "Zaisan",
            "name": "[SZI] - Zaisan - SZI - Zaisan - Kazakhstan",
            "searchName": "SZI-Zaisan-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "TDK",
            "countryCode": "KZ",
            "cityCode": "TDK",
            "cityName": "Taldy-Kurgan",
            "name": "[TDK] - Taldy-Kurgan - TDK - Taldy-Kurgan - Kazakhstan",
            "searchName": "TDK-Taldy-Kurgan-Taldy Kurgan Havalimani-Taldy Kurgan-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "SCO",
            "countryCode": "KZ",
            "cityCode": "SCO",
            "cityName": "Aktau",
            "name": "[SCO] - Shevchenko - SCO - Aktau - Kazakhstan",
            "searchName": "SCO-Aqtan-Shevchenko-Aktau-Shevchenko Airport-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "PLX",
            "countryCode": "KZ",
            "cityCode": "PLX",
            "cityName": "Semipalatinsk",
            "name": "[PLX] - Semipalatinsk - PLX - Semipalatinsk - Kazakhstan",
            "searchName": "PLX-Semey-Semipalatinsk-Semipalatinsk Airport-Semei-Seme-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "PPK",
            "countryCode": "KZ",
            "cityCode": "PPK",
            "cityName": "Petropavlovsk",
            "name": "[PPK] - Petropavlovsk - PPK - Petropavlovsk - Kazakhstan",
            "searchName": "PPK-Petropawl-Petropavlovsk-Petropavl-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "BXJ",
            "countryCode": "KZ",
            "cityCode": "ALA",
            "cityName": "Almaty",
            "name": "[BXJ] - Burundai - ALA - Almaty - Kazakhstan",
            "searchName": "BXJ-ALA-Burundai-Almaty-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "AYK",
            "countryCode": "KZ",
            "cityCode": "AYK",
            "cityName": "Arkalyk",
            "name": "[AYK] - Arkalyk - AYK - Arkalyk - Kazakhstan",
            "searchName": "AYK-Arkalyk-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "ATX",
            "countryCode": "KZ",
            "cityCode": "ATX",
            "cityName": "Atbasar",
            "name": "[ATX] - Atbasar - ATX - Atbasar - Kazakhstan",
            "searchName": "ATX-Atbasar-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "BXH",
            "countryCode": "KZ",
            "cityCode": "BXH",
            "cityName": "Balhash",
            "name": "[BXH] - Balhash - BXH - Balhash - Kazakhstan",
            "searchName": "BXH-Balhash-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "GUW",
            "countryCode": "KZ",
            "cityCode": "GUW",
            "cityName": "Atyrau",
            "name": "[GUW] - Atyrau International - GUW - Atyrau - Kazakhstan",
            "searchName": "GUW-Atyrau-Atyrau International-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "HRC",
            "countryCode": "KZ",
            "cityCode": "HRC",
            "cityName": "Zhairem",
            "name": "[HRC] - Zhairem - HRC - Zhairem - Kazakhstan",
            "searchName": "HRC-Zhairem-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "KZO",
            "countryCode": "KZ",
            "cityCode": "KZO",
            "cityName": "Kzyl-Orda",
            "name": "[KZO] - Kzyl-Orda - KZO - Kzyl-Orda - Kazakhstan",
            "searchName": "KZO-Kzyl Orda-Kzyl-Orda-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "KSN",
            "countryCode": "KZ",
            "cityCode": "KSN",
            "cityName": "Kostanay",
            "name": "[KSN] - Kostanay - KSN - Kostanay - Kazakhstan",
            "searchName": "KSN-Qostanay-Kostanay-Kostanai-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "KOV",
            "countryCode": "KZ",
            "cityCode": "KOV",
            "cityName": "Kokshetau",
            "name": "[KOV] - Kokshetau - KOV - Kokshetau - Kazakhstan",
            "searchName": "KOV-Kokshetau-Kokshetau Havalimani-Kazakhstan-Kazakstan-Kazajstán-Kazachstan-Kasakhstan-K-Kasachstan-Kazakistan-Kazajistán",
            "priority": 0
        },
        {
            "iataCode": "PKZ",
            "countryCode": "LA",
            "cityCode": "PKZ",
            "cityName": "Pakse",
            "name": "[PKZ] - Pakse - PKZ - Pakse - Laos",
            "searchName": "PKZ-Pakse-Pakx-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "PKS",
            "countryCode": "LA",
            "cityCode": "PKS",
            "cityName": "Paksane",
            "name": "[PKS] - Paksane - PKS - Paksane - Laos",
            "searchName": "PKS-Paksane-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "OUI",
            "countryCode": "LA",
            "cityCode": "OUI",
            "cityName": "Ban Houei",
            "name": "[OUI] - Ban Houei - OUI - Ban Houei - Laos",
            "searchName": "OUI-Ban Houei-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "PCQ",
            "countryCode": "LA",
            "cityCode": "PCQ",
            "cityName": "Phongsaly",
            "name": "[PCQ] - Bounneua - PCQ - Phongsaly - Laos",
            "searchName": "PCQ-Bounneua-Phongsaly-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "NEU",
            "countryCode": "LA",
            "cityCode": "NEU",
            "cityName": "Sam Neua",
            "name": "[NEU] - Sam Neua - NEU - Sam Neua - Laos",
            "searchName": "NEU-Sam Neua-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ODY",
            "countryCode": "LA",
            "cityCode": "ODY",
            "cityName": "Oudomxay",
            "name": "[ODY] - Oudomxai - ODY - Oudomxay - Laos",
            "searchName": "ODY-Oudomxay-Oudomxai-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "SND",
            "countryCode": "LA",
            "cityCode": "SND",
            "cityName": "Seno",
            "name": "[SND] - Seno - SND - Seno - Laos",
            "searchName": "SND-Seno-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "THK",
            "countryCode": "LA",
            "cityCode": "THK",
            "cityName": "Thakhek",
            "name": "[THK] - Thakhek - THK - Thakhek - Laos",
            "searchName": "THK-Thakhek-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "UON",
            "countryCode": "LA",
            "cityCode": "UON",
            "cityName": "Muong Sai",
            "name": "[UON] - Muong Sai - UON - Muong Sai - Laos",
            "searchName": "UON-Muong Sai-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "UDO",
            "countryCode": "LA",
            "cityCode": "UDO",
            "cityName": "Udomxay",
            "name": "[UDO] - Udomxay - UDO - Udomxay - Laos",
            "searchName": "UDO-Udomxay-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "VGG",
            "countryCode": "LA",
            "cityCode": "VGG",
            "cityName": "Vangrieng",
            "name": "[VGG] - Vangrieng - VGG - Vangrieng - Laos",
            "searchName": "VGG-Vangrieng-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "VNG",
            "countryCode": "LA",
            "cityCode": "VNG",
            "cityName": "Viengxay",
            "name": "[VNG] - Viengxay - VNG - Viengxay - Laos",
            "searchName": "VNG-Viengxay-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "VNA",
            "countryCode": "LA",
            "cityCode": "VNA",
            "cityName": "Saravane",
            "name": "[VNA] - Saravane - VNA - Saravane - Laos",
            "searchName": "VNA-Saravane-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "VTE",
            "countryCode": "LA",
            "cityCode": "VTE",
            "cityName": "Vientiane",
            "name": "[VTE] - Wattay - VTE - Vientiane - Laos",
            "searchName": "VTE-Vientiane-Wattay-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XKH",
            "countryCode": "LA",
            "cityCode": "XKH",
            "cityName": "Xieng Khouang",
            "name": "[XKH] - Xieng Khouang - XKH - Xieng Khouang - Laos",
            "searchName": "XKH-Xieng Khouang-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XAY",
            "countryCode": "LA",
            "cityCode": "XAY",
            "cityName": "Xayabury",
            "name": "[XAY] - Xayabury - XAY - Xayabury - Laos",
            "searchName": "XAY-Xayabury-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "XIE",
            "countryCode": "LA",
            "cityCode": "XIE",
            "cityName": "Xienglom",
            "name": "[XIE] - Xienglom - XIE - Xienglom - Laos",
            "searchName": "XIE-Xienglom-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KOG",
            "countryCode": "LA",
            "cityCode": "KOG",
            "cityName": "Khong",
            "name": "[KOG] - Khong - KOG - Khong - Laos",
            "searchName": "KOG-Khong-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LXG",
            "countryCode": "LA",
            "cityCode": "LXG",
            "cityName": "Luang Namtha",
            "name": "[LXG] - Luang Namtha - LXG - Luang Namtha - Laos",
            "searchName": "LXG-Luang Namtha-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "LPQ",
            "countryCode": "LA",
            "cityCode": "LPQ",
            "cityName": "Luang Prabang",
            "name": "[LPQ] - Luang Prabang - LPQ - Luang Prabang - Laos",
            "searchName": "LPQ-Luang Prabang-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "HOE",
            "countryCode": "LA",
            "cityCode": "HOE",
            "cityName": "Houeisay",
            "name": "[HOE] - Houeisay - HOE - Houeisay - Laos",
            "searchName": "HOE-Houeisay-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "AOU",
            "countryCode": "LA",
            "cityCode": "AOU",
            "cityName": "Attopeu",
            "name": "[AOU] - Attopeu - AOU - Attopeu - Laos",
            "searchName": "AOU-Attopeu-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZVK",
            "countryCode": "LA",
            "cityCode": "ZVK",
            "cityName": "Savannakhet",
            "name": "[ZVK] - Savannakhet - ZVK - Savannakhet - Laos",
            "searchName": "ZVK-Savannakhet-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "ZBY",
            "countryCode": "LA",
            "cityCode": "ZBY",
            "cityName": "Sayaboury",
            "name": "[ZBY] - Sayaboury - ZBY - Sayaboury - Laos",
            "searchName": "ZBY-Sayaboury-Laos-Laosz-Laosa-Lào-Laosas-Lao Demokratik Halk Cumhuriyeti",
            "priority": 0
        },
        {
            "iataCode": "KYE",
            "countryCode": "LB",
            "cityCode": "KYE",
            "cityName": "Tripoli",
            "name": "[KYE] - Kleyate - KYE - Tripoli - Lebanon",
            "searchName": "KYE-Kleyate-Tripoli-Liibanon-Libanon-Lübnan-Líban-Liban-Libanas-Libana-Lebanon-Libano-Líbano",
            "priority": 0
        },
        {
            "iataCode": "SLU",
            "countryCode": "LC",
            "cityCode": "SLU",
            "cityName": "Castries",
            "name": "[SLU] - George F L Charles - SLU - Castries - Saint Lucia",
            "searchName": "SLU-Saint Lucia Vigie Field-George F L Charles-George F. L. Charle-Vigie-St Lucia-Castries-Saint Lucia-St. Lucia-Sainte-Lucie-Santa Lucía",
            "priority": 0
        },
        {
            "iataCode": "UVF",
            "countryCode": "LC",
            "cityCode": "SLU",
            "cityName": "Castries",
            "name": "[UVF] - Hewanorra - SLU - Castries - Saint Lucia",
            "searchName": "UVF-SLU-Saint Lucia Hewanorra-Hewanorra-St Lucia-Castries-Saint Lucia-St. Lucia-Sainte-Lucie-Santa Lucía",
            "priority": 0
        },
        {
            "iataCode": "TRR",
            "countryCode": "LK",
            "cityCode": "TRR",
            "cityName": "Trincomalee",
            "name": "[TRR] - China Bay - TRR - Trincomalee - Sri Lanka",
            "searchName": "TRR-China Bay-Trincomalee-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "THW",
            "countryCode": "LK",
            "cityCode": "TRR",
            "cityName": "Trincomalee",
            "name": "[THW] - Harbour SPB - TRR - Trincomalee - Sri Lanka",
            "searchName": "THW-TRR-Harbour SPB-Trincomalee-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "TTW",
            "countryCode": "LK",
            "cityCode": "TTW",
            "cityName": "Tissa Tank",
            "name": "[TTW] - Tissa Tank - TTW - Tissa Tank - Sri Lanka",
            "searchName": "TTW-Tissa Tank-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "WRZ",
            "countryCode": "LK",
            "cityCode": "WRZ",
            "cityName": "Wirawila",
            "name": "[WRZ] - Wirawila - WRZ - Wirawila - Sri Lanka",
            "searchName": "WRZ-Wirawila-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "NUA",
            "countryCode": "LK",
            "cityCode": "NUA",
            "cityName": "Nuwara Eliya",
            "name": "[NUA] - Gregorys Lake - NUA - Nuwara Eliya - Sri Lanka",
            "searchName": "NUA-Gregory s Lake-Gregorys Lake-Nuwara Eliya-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "NUF",
            "countryCode": "LK",
            "cityCode": "NUF",
            "cityName": "Hatton",
            "name": "[NUF] - Castlereigh Reservoir - NUF - Hatton - Sri Lanka",
            "searchName": "NUF-Castlereigh Reservoir-Hatton-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "MNH",
            "countryCode": "LK",
            "cityCode": "MNH",
            "cityName": "Minneriya",
            "name": "[MNH] - Minneriya - MNH - Minneriya - Sri Lanka",
            "searchName": "MNH-Minneriya-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "KTY",
            "countryCode": "LK",
            "cityCode": "KTY",
            "cityName": "Katukurunda",
            "name": "[KTY] - Katukurunda Slaf Base - KTY - Katukurunda - Sri Lanka",
            "searchName": "KTY-Katukurunda Slaf Base-Katukurunda-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "KEZ",
            "countryCode": "LK",
            "cityCode": "KEZ",
            "cityName": "Kelaniya",
            "name": "[KEZ] - Kelaniya River - KEZ - Kelaniya - Sri Lanka",
            "searchName": "KEZ-Kelaniya River-Kelaniya-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "KDY",
            "countryCode": "LK",
            "cityCode": "KDY",
            "cityName": "Mahaweli",
            "name": "[KDY] - Mahaweli - KDY - Mahaweli - Sri Lanka",
            "searchName": "KDY-Mahaweli-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "KDZ",
            "countryCode": "LK",
            "cityCode": "KDZ",
            "cityName": "Katugastota",
            "name": "[KDZ] - Polgolla Reservoir - KDZ - Katugastota - Sri Lanka",
            "searchName": "KDZ-Polgolla Reservoir-Katugastota-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "KDW",
            "countryCode": "LK",
            "cityCode": "KDW",
            "cityName": "Kandy",
            "name": "[KDW] - Vic. Resevour Kandy - KDW - Kandy - Sri Lanka",
            "searchName": "KDW-Vic. Resevour Kandy-Kandy-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "KCT",
            "countryCode": "LK",
            "cityCode": "KCT",
            "cityName": "Koggala",
            "name": "[KCT] - Koggala - KCT - Koggala - Sri Lanka",
            "searchName": "KCT-Koggala-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "HRI",
            "countryCode": "LK",
            "cityCode": "HRI",
            "cityName": "Hambantota",
            "name": "[HRI] - Mattala Rajapaksa International Airport - HRI - Hambantota - Sri Lanka",
            "searchName": "HRI-Mattala Rajapaksa International Airport-Hafr Albatin-Hambantota-Hafar Albatin-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "HBT",
            "countryCode": "LK",
            "cityCode": "HRI",
            "cityName": "Hambantota",
            "name": "[HBT] - Hafr Albatin - HRI - Hambantota - Sri Lanka",
            "searchName": "HBT-HRI-Hafr Albatin-Hafr Al-Batin Havalimani-Hambantota-Hafar Albatin-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "HIM",
            "countryCode": "LK",
            "cityCode": "HIM",
            "cityName": "Hingurakgoda",
            "name": "[HIM] - Hingurakgoda - HIM - Hingurakgoda - Sri Lanka",
            "searchName": "HIM-Hingurakgoda-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "JAF",
            "countryCode": "LK",
            "cityCode": "JAF",
            "cityName": "Jaffna",
            "name": "[JAF] - Kankesanturai - JAF - Jaffna - Sri Lanka",
            "searchName": "JAF-Kankesanturai-Jaffna-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "IRU",
            "countryCode": "LK",
            "cityCode": "IRU",
            "cityName": "Iranamadu",
            "name": "[IRU] - Iranamadu SPB - IRU - Iranamadu - Sri Lanka",
            "searchName": "IRU-Iranamadu SPB-Iranamadu-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "GIU",
            "countryCode": "LK",
            "cityCode": "GIU",
            "cityName": "Sigiriya",
            "name": "[GIU] - Sigiriya Slaf Base - GIU - Sigiriya - Sri Lanka",
            "searchName": "GIU-Sigiriya Slaf Base-Sigiriya-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "GOY",
            "countryCode": "LK",
            "cityCode": "GOY",
            "cityName": "Gal Oya",
            "name": "[GOY] - Amparai - GOY - Gal Oya - Sri Lanka",
            "searchName": "GOY-Amparai-Gal Oya-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "BJT",
            "countryCode": "LK",
            "cityCode": "BJT",
            "cityName": "Bentota River",
            "name": "[BJT] - Bentota River - BJT - Bentota River - Sri Lanka",
            "searchName": "BJT-Bentota River-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "BTC",
            "countryCode": "LK",
            "cityCode": "BTC",
            "cityName": "Batticaloa",
            "name": "[BTC] - Batticaloa - BTC - Batticaloa - Sri Lanka",
            "searchName": "BTC-Batticaloa-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "PQD",
            "countryCode": "LK",
            "cityCode": "BTC",
            "cityName": "Batticaloa",
            "name": "[PQD] - Pasikudha SPB - BTC - Batticaloa - Sri Lanka",
            "searchName": "PQD-BTC-Pasikudha SPB-Batticaloa-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "AYY",
            "countryCode": "LK",
            "cityCode": "AYY",
            "cityName": "Pothuvil",
            "name": "[AYY] - Arugam Bay SPB - AYY - Pothuvil - Sri Lanka",
            "searchName": "AYY-Arugam Bay SPB-Pothuvil-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "ACJ",
            "countryCode": "LK",
            "cityCode": "ACJ",
            "cityName": "Anuradhapura",
            "name": "[ACJ] - Anuradhapura - ACJ - Anuradhapura - Sri Lanka",
            "searchName": "ACJ-Anuradhapura-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "ADP",
            "countryCode": "LK",
            "cityCode": "ADP",
            "cityName": "Ampara",
            "name": "[ADP] - Ampara - ADP - Ampara - Sri Lanka",
            "searchName": "ADP-Ampara-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "AFK",
            "countryCode": "LK",
            "cityCode": "ADP",
            "cityName": "Ampara",
            "name": "[AFK] - Kondavattavan Tank - ADP - Ampara - Sri Lanka",
            "searchName": "AFK-ADP-Kondavattavan Tank-Kondavattavan Tank Havalimani-Ampara-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "DBU",
            "countryCode": "LK",
            "cityCode": "DBU",
            "cityName": "Dambula",
            "name": "[DBU] - Dambulu Oya Tank - DBU - Dambula - Sri Lanka",
            "searchName": "DBU-Dambulu Oya Tank-Dambula-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "DGM",
            "countryCode": "LK",
            "cityCode": "DGM",
            "cityName": "Dongguan",
            "name": "[DGM] - Dongguan - DGM - Dongguan - Sri Lanka",
            "searchName": "DGM-Dongguan-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "DIW",
            "countryCode": "LK",
            "cityCode": "DIW",
            "cityName": "Dickwella",
            "name": "[DIW] - Mawella Lagoon - DIW - Dickwella - Sri Lanka",
            "searchName": "DIW-Mawella Lagoon-Dickwella-Srí Lanka-Sri Lanka-Šri Lanka-Šrilanka-",
            "priority": 0
        },
        {
            "iataCode": "KCH",
            "countryCode": "MY",
            "cityCode": "KCH",
            "cityName": "Kuching",
            "name": "[KCH] - Kuching - KCH - Kuching - Malaysia",
            "searchName": "KCH-Kuching-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "JHB",
            "countryCode": "MY",
            "cityCode": "JHB",
            "cityName": "Johor Bahru",
            "name": "[JHB] - Sultan Ismail International - JHB - Johor Bahru - Malaysia",
            "searchName": "JHB-Johor Bahru-Sultan Ismail International-Sultan Ismail Intl-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "SMM",
            "countryCode": "MY",
            "cityCode": "SMM",
            "cityName": "Semporna",
            "name": "[SMM] - Semporna - SMM - Semporna - Malaysia",
            "searchName": "SMM-Semporna-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "SPE",
            "countryCode": "MY",
            "cityCode": "SPE",
            "cityName": "Sepulot",
            "name": "[SPE] - Sepulot - SPE - Sepulot - Malaysia",
            "searchName": "SPE-Sepulot-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "SPT",
            "countryCode": "MY",
            "cityCode": "SPT",
            "cityName": "Sipitang",
            "name": "[SPT] - Sipitang - SPT - Sipitang - Malaysia",
            "searchName": "SPT-Sipitang-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "SGG",
            "countryCode": "MY",
            "cityCode": "SGG",
            "cityName": "Simanggang",
            "name": "[SGG] - Simanggang - SGG - Simanggang - Malaysia",
            "searchName": "SGG-Simanggang-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "SBW",
            "countryCode": "MY",
            "cityCode": "SBW",
            "cityName": "Sibu",
            "name": "[SBW] - Sibu - SBW - Sibu - Malaysia",
            "searchName": "SBW-Sibu-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "SDK",
            "countryCode": "MY",
            "cityCode": "SDK",
            "cityName": "Sandakan",
            "name": "[SDK] - Sandakan - SDK - Sandakan - Malaysia",
            "searchName": "SDK-Sandakan-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "SWY",
            "countryCode": "MY",
            "cityCode": "SWY",
            "cityName": "Sitiawan",
            "name": "[SWY] - Sitiawan - SWY - Sitiawan - Malaysia",
            "searchName": "SWY-Sitiawan-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "SXS",
            "countryCode": "MY",
            "cityCode": "SXS",
            "cityName": "Sahabat 16",
            "name": "[SXS] - Sahabat 16 - SXS - Sahabat 16 - Malaysia",
            "searchName": "SXS-Sahabat 16-Sahabat-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "SXT",
            "countryCode": "MY",
            "cityCode": "SXT",
            "cityName": "Taman Negara",
            "name": "[SXT] - Taman Negara - SXT - Taman Negara - Malaysia",
            "searchName": "SXT-Taman Negara-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "TGC",
            "countryCode": "MY",
            "cityCode": "TGC",
            "cityName": "Tanjung Manis",
            "name": "[TGC] - Tanjung Manis - TGC - Tanjung Manis - Malaysia",
            "searchName": "TGC-Tanjung Manis-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "TGG",
            "countryCode": "MY",
            "cityCode": "TGG",
            "cityName": "Kuala Terengganu",
            "name": "[TGG] - Sultan Mahmood - TGG - Kuala Terengganu - Malaysia",
            "searchName": "TGG-Kuala Terengganu-Sultan Mahmood-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "TEL",
            "countryCode": "MY",
            "cityCode": "TEL",
            "cityName": "Telupid",
            "name": "[TEL] - Telupid - TEL - Telupid - Malaysia",
            "searchName": "TEL-Telupid-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "TWU",
            "countryCode": "MY",
            "cityCode": "TWU",
            "cityName": "Tawau",
            "name": "[TWU] - Tawau - TWU - Tawau - Malaysia",
            "searchName": "TWU-Tawau-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "TPG",
            "countryCode": "MY",
            "cityCode": "TPG",
            "cityName": "Taiping",
            "name": "[TPG] - Taiping - TPG - Taiping - Malaysia",
            "searchName": "TPG-Taiping-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "TOD",
            "countryCode": "MY",
            "cityCode": "TOD",
            "cityName": "Tioman",
            "name": "[TOD] - Tioman - TOD - Tioman - Malaysia",
            "searchName": "TOD-Tioman-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "TMG",
            "countryCode": "MY",
            "cityCode": "TMG",
            "cityName": "Tomanggong",
            "name": "[TMG] - Tomanggong - TMG - Tomanggong - Malaysia",
            "searchName": "TMG-Tomanggong-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "XPQ",
            "countryCode": "MY",
            "cityCode": "XPQ",
            "cityName": "Port Klang",
            "name": "[XPQ] - Ferry - XPQ - Port Klang - Malaysia",
            "searchName": "XPQ-Ferry-Port Klang-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "PEN",
            "countryCode": "MY",
            "cityCode": "PEN",
            "cityName": "Penang",
            "name": "[PEN] - Penang International - PEN - Penang - Malaysia",
            "searchName": "PEN-Penang-Penang International-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "PKG",
            "countryCode": "MY",
            "cityCode": "PKG",
            "cityName": "Pangkor",
            "name": "[PKG] - Pangkor Airport - PKG - Pangkor - Malaysia",
            "searchName": "PKG-Pangkor Airport-Pangkor Havalimani-Pangkor-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "PAY",
            "countryCode": "MY",
            "cityCode": "PAY",
            "cityName": "Pamol",
            "name": "[PAY] - Pamol - PAY - Pamol - Malaysia",
            "searchName": "PAY-Pamol-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "RDN",
            "countryCode": "MY",
            "cityCode": "RDN",
            "cityName": "Redang",
            "name": "[RDN] - LTS Pulau Redang - RDN - Redang - Malaysia",
            "searchName": "RDN-LTS Pulau Redang-Lts Pulau Redang Havalimani-Redang-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "RNU",
            "countryCode": "MY",
            "cityCode": "RNU",
            "cityName": "Ranau",
            "name": "[RNU] - Ranau - RNU - Ranau - Malaysia",
            "searchName": "RNU-Ranau-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "MUR",
            "countryCode": "MY",
            "cityCode": "MUR",
            "cityName": "Marudi",
            "name": "[MUR] - Marudi - MUR - Marudi - Malaysia",
            "searchName": "MUR-Marudi-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "MKZ",
            "countryCode": "MY",
            "cityCode": "MKZ",
            "cityName": "Malacca",
            "name": "[MKZ] - Batu Berendam - MKZ - Malacca - Malaysia",
            "searchName": "MKZ-Batu Berendum-Batu Berendam-Malacca-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "MEP",
            "countryCode": "MY",
            "cityCode": "MEP",
            "cityName": "Mersing",
            "name": "[MEP] - Mersing - MEP - Mersing - Malaysia",
            "searchName": "MEP-Mersing-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "MKM",
            "countryCode": "MY",
            "cityCode": "MKM",
            "cityName": "Mukah",
            "name": "[MKM] - Mukah - MKM - Mukah - Malaysia",
            "searchName": "MKM-Mukah-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "ODN",
            "countryCode": "MY",
            "cityCode": "ODN",
            "cityName": "Long Seridan",
            "name": "[ODN] - Long Seridan - ODN - Long Seridan - Malaysia",
            "searchName": "ODN-Long Seridan-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "MZS",
            "countryCode": "MY",
            "cityCode": "MZS",
            "cityName": "Mostyn",
            "name": "[MZS] - Mostyn - MZS - Mostyn - Malaysia",
            "searchName": "MZS-Mostyn-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "MYY",
            "countryCode": "MY",
            "cityCode": "MYY",
            "cityName": "Miri",
            "name": "[MYY] - Miri - MYY - Miri - Malaysia",
            "searchName": "MYY-Miri-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "MZV",
            "countryCode": "MY",
            "cityCode": "MZV",
            "cityName": "Mulu",
            "name": "[MZV] - Mulu, - MZV - Mulu - Malaysia",
            "searchName": "MZV-Mulu--Mulu,-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "ZMK",
            "countryCode": "MY",
            "cityCode": "ZMK",
            "cityName": "Bintulu",
            "name": "[ZMK] - Bintulu Port - ZMK - Bintulu - Malaysia",
            "searchName": "ZMK-Bintulu Port-Bintulu-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "ZLW",
            "countryCode": "MY",
            "cityCode": "ZLW",
            "cityName": "Pasir Gudang",
            "name": "[ZLW] - Pasir Gudang Port - ZLW - Pasir Gudang - Malaysia",
            "searchName": "ZLW-Pasir Gudang Port-Pasir Gudang-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "ZJR",
            "countryCode": "MY",
            "cityCode": "ZJR",
            "cityName": "Penang",
            "name": "[ZJR] - Penang Port - ZJR - Penang - Malaysia",
            "searchName": "ZJR-Penang Port-Penang-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "ZJB",
            "countryCode": "MY",
            "cityCode": "ZJB",
            "cityName": "Kuantan",
            "name": "[ZJB] - Kuantan Port - ZJB - Kuantan - Malaysia",
            "searchName": "ZJB-Kuantan Port-Kuantan-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "ZJT",
            "countryCode": "MY",
            "cityCode": "ZJT",
            "cityName": "Tanjung Pelepas",
            "name": "[ZJT] - Tanjung Pelepas Port - ZJT - Tanjung Pelepas - Malaysia",
            "searchName": "ZJT-Tanjung Pelepas Port-Tanjung Pelepas-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "ZGY",
            "countryCode": "MY",
            "cityCode": "ZGY",
            "cityName": "Kuching",
            "name": "[ZGY] - Kuching Port - ZGY - Kuching - Malaysia",
            "searchName": "ZGY-Kuching Port-Kuching-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "ZWR",
            "countryCode": "MY",
            "cityCode": "ZWR",
            "cityName": "Kota Kinabalu",
            "name": "[ZWR] - Kota Kinabalu Port - ZWR - Kota Kinabalu - Malaysia",
            "searchName": "ZWR-Kota Kinabalu Port-Kota Kinabalu-Malaysia-Malàisia-Malásia-Malasia-Malesia-Malezja-Malaisia-Ma-Malezya-Malaisie",
            "priority": 0
        },
        {
            "iataCode": "MZB",
            "countryCode": "MZ",
            "cityCode": "MZB",
            "cityName": "Mocimboa Praia",
            "name": "[MZB] - Mocimboa Praia - MZB - Mocimboa Praia - Mozambique",
            "searchName": "MZB-Mocimboa Praia-Mocimboa da Praia-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "NND",
            "countryCode": "MZ",
            "cityCode": "NND",
            "cityName": "Nangade",
            "name": "[NND] - Nangade - NND - Nangade - Mozambique",
            "searchName": "NND-Nangade-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "NTC",
            "countryCode": "MZ",
            "cityCode": "NTC",
            "cityName": "Santa Carolina",
            "name": "[NTC] - Santa Carolina - NTC - Santa Carolina - Mozambique",
            "searchName": "NTC-Santa Carolina-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "MJS",
            "countryCode": "MZ",
            "cityCode": "MJS",
            "cityName": "Maganja Da Costa",
            "name": "[MJS] - Maganja Da Costa - MJS - Maganja Da Costa - Mozambique",
            "searchName": "MJS-Maganja Da Costa-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "MNC",
            "countryCode": "MZ",
            "cityCode": "MNC",
            "cityName": "Nacala",
            "name": "[MNC] - Nacala - MNC - Nacala - Mozambique",
            "searchName": "MNC-Nacala-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "MMW",
            "countryCode": "MZ",
            "cityCode": "MMW",
            "cityName": "Moma",
            "name": "[MMW] - Moma - MMW - Moma - Mozambique",
            "searchName": "MMW-Moma-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "MPM",
            "countryCode": "MZ",
            "cityCode": "MPM",
            "cityName": "Maputo",
            "name": "[MPM] - Maputo International - MPM - Maputo - Mozambique",
            "searchName": "MPM-Maputo-Maputo International-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "MUD",
            "countryCode": "MZ",
            "cityCode": "MUD",
            "cityName": "Mueda",
            "name": "[MUD] - Mueda - MUD - Mueda - Mozambique",
            "searchName": "MUD-Mueda-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "MTU",
            "countryCode": "MZ",
            "cityCode": "MTU",
            "cityName": "Montepuez",
            "name": "[MTU] - Montepuez - MTU - Montepuez - Mozambique",
            "searchName": "MTU-Montepuez-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "RRM",
            "countryCode": "MZ",
            "cityCode": "RRM",
            "cityName": "Marromeu",
            "name": "[RRM] - Marromeu - RRM - Marromeu - Mozambique",
            "searchName": "RRM-Marromeu-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "PDD",
            "countryCode": "MZ",
            "cityCode": "PDD",
            "cityName": "Ponta de Ouro",
            "name": "[PDD] - Ponta de Ouro - PDD - Ponta de Ouro - Mozambique",
            "searchName": "PDD-Ponta de Ouro-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "PEB",
            "countryCode": "MZ",
            "cityCode": "PEB",
            "cityName": "Pebane",
            "name": "[PEB] - Pebane - PEB - Pebane - Mozambique",
            "searchName": "PEB-Pebane-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "POL",
            "countryCode": "MZ",
            "cityCode": "POL",
            "cityName": "Pemba",
            "name": "[POL] - Porto Amelia - POL - Pemba - Mozambique",
            "searchName": "POL-Pemba-Porto Amelia-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "UEL",
            "countryCode": "MZ",
            "cityCode": "UEL",
            "cityName": "Quelimane",
            "name": "[UEL] - Quelimane - UEL - Quelimane - Mozambique",
            "searchName": "UEL-Quelimane-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "VXC",
            "countryCode": "MZ",
            "cityCode": "VXC",
            "cityName": "Lichinga",
            "name": "[VXC] - Lichinga - VXC - Lichinga - Mozambique",
            "searchName": "VXC-Lichinga-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "VNX",
            "countryCode": "MZ",
            "cityCode": "VNX",
            "cityName": "Vilanculos",
            "name": "[VNX] - Vilanculos - VNX - Vilanculos - Mozambique",
            "searchName": "VNX-Vilanculos-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "VPY",
            "countryCode": "MZ",
            "cityCode": "VPY",
            "cityName": "Chimoio",
            "name": "[VPY] - Chimoio - VPY - Chimoio - Mozambique",
            "searchName": "VPY-Chimoio-Chimoio Airport-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "VJB",
            "countryCode": "MZ",
            "cityCode": "VJB",
            "cityName": "Xai Xai",
            "name": "[VJB] - Xai Xai - VJB - Xai Xai - Mozambique",
            "searchName": "VJB-Xai Xai-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "VJQ",
            "countryCode": "MZ",
            "cityCode": "VJQ",
            "cityName": "Gurue",
            "name": "[VJQ] - Gurue - VJQ - Gurue - Mozambique",
            "searchName": "VJQ-Gurue-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "TET",
            "countryCode": "MZ",
            "cityCode": "TET",
            "cityName": "Tete",
            "name": "[TET] - Matundo - TET - Tete - Mozambique",
            "searchName": "TET-Tete Matundo-Matundo-Matunda-Tete-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "TGS",
            "countryCode": "MZ",
            "cityCode": "TGS",
            "cityName": "Chokwe",
            "name": "[TGS] - Chokwe - TGS - Chokwe - Mozambique",
            "searchName": "TGS-Chokwe-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "LMZ",
            "countryCode": "MZ",
            "cityCode": "LMZ",
            "cityName": "Palma",
            "name": "[LMZ] - Palma - LMZ - Palma - Mozambique",
            "searchName": "LMZ-Palma-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "LFB",
            "countryCode": "MZ",
            "cityCode": "LFB",
            "cityName": "Lumbo",
            "name": "[LFB] - Lumbo - LFB - Lumbo - Mozambique",
            "searchName": "LFB-Lumbo-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "LBM",
            "countryCode": "MZ",
            "cityCode": "LBM",
            "cityName": "Luabo",
            "name": "[LBM] - Luabo - LBM - Luabo - Mozambique",
            "searchName": "LBM-Luabo-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "FXO",
            "countryCode": "MZ",
            "cityCode": "FXO",
            "cityName": "Cuamba",
            "name": "[FXO] - Cuamba - FXO - Cuamba - Mozambique",
            "searchName": "FXO-Cuamba-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "IMG",
            "countryCode": "MZ",
            "cityCode": "IMG",
            "cityName": "Inhaminga",
            "name": "[IMG] - Inhaminga - IMG - Inhaminga - Mozambique",
            "searchName": "IMG-Inhaminga-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "INH",
            "countryCode": "MZ",
            "cityCode": "INH",
            "cityName": "Inhambane",
            "name": "[INH] - Inhambane - INH - Inhambane - Mozambique",
            "searchName": "INH-Inhambane-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "INE",
            "countryCode": "MZ",
            "cityCode": "INE",
            "cityName": "Chinde",
            "name": "[INE] - Chinde - INE - Chinde - Mozambique",
            "searchName": "INE-Chinde-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "IHC",
            "countryCode": "MZ",
            "cityCode": "IHC",
            "cityName": "Inhaca",
            "name": "[IHC] - Inhaca - IHC - Inhaca - Mozambique",
            "searchName": "IHC-Inhaca-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "IBL",
            "countryCode": "MZ",
            "cityCode": "IBL",
            "cityName": "Indigo Bay Lodge",
            "name": "[IBL] - Indigo Bay Lodge - IBL - Indigo Bay Lodge - Mozambique",
            "searchName": "IBL-Indigo Bay Lodge-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "IBO",
            "countryCode": "MZ",
            "cityCode": "IBO",
            "cityName": "Ibo",
            "name": "[IBO] - Ibo - IBO - Ibo - Mozambique",
            "searchName": "IBO-Ibo-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "IDC",
            "countryCode": "MZ",
            "cityCode": "IDC",
            "cityName": "Ila Da Chilonzuene",
            "name": "[IDC] - Ila Da Chilonzuene - IDC - Ila Da Chilonzuene - Mozambique",
            "searchName": "IDC-Ila Da Chilonzuene-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "BZB",
            "countryCode": "MZ",
            "cityCode": "BZB",
            "cityName": "Bazaruto Island",
            "name": "[BZB] - Bazaruto Island - BZB - Bazaruto Island - Mozambique",
            "searchName": "BZB-Bazaruto Island-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "BCW",
            "countryCode": "MZ",
            "cityCode": "BCW",
            "cityName": "Benguera Island",
            "name": "[BCW] - Benguera Island - BCW - Benguera Island - Mozambique",
            "searchName": "BCW-Benguera Island-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "BEW",
            "countryCode": "MZ",
            "cityCode": "BEW",
            "cityName": "Beira",
            "name": "[BEW] - Beira - BEW - Beira - Mozambique",
            "searchName": "BEW-Beira-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "BJN",
            "countryCode": "MZ",
            "cityCode": "BJN",
            "cityName": "Bajone",
            "name": "[BJN] - Bajone - BJN - Bajone - Mozambique",
            "searchName": "BJN-Bajone-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "APL",
            "countryCode": "MZ",
            "cityCode": "APL",
            "cityName": "Nampula",
            "name": "[APL] - Nampula - APL - Nampula - Mozambique",
            "searchName": "APL-Nampula-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "ANO",
            "countryCode": "MZ",
            "cityCode": "ANO",
            "cityName": "Angoche",
            "name": "[ANO] - Angoche - ANO - Angoche - Mozambique",
            "searchName": "ANO-Angoche-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "AME",
            "countryCode": "MZ",
            "cityCode": "AME",
            "cityName": "Alto Molocue",
            "name": "[AME] - Alto Molocue - AME - Alto Molocue - Mozambique",
            "searchName": "AME-Alto Molocue-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "DGK",
            "countryCode": "MZ",
            "cityCode": "DGK",
            "cityName": "Dugong",
            "name": "[DGK] - Dugong - DGK - Dugong - Mozambique",
            "searchName": "DGK-Dugong-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "CMZ",
            "countryCode": "MZ",
            "cityCode": "CMZ",
            "cityName": "Caia",
            "name": "[CMZ] - Caia - CMZ - Caia - Mozambique",
            "searchName": "CMZ-Caia-Mozambique-Mozambik-Moçambic-Mosambik-Mozambic-Mozambikas-Mozambika-Mosambiik-Mozambico",
            "priority": 0
        },
        {
            "iataCode": "AIW",
            "countryCode": "NA",
            "cityCode": "AIW",
            "cityName": "Ai-ais",
            "name": "[AIW] - Ai-ais - AIW - Ai-ais - Namibia",
            "searchName": "AIW-Ai-ais-Ai Ais-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "ADI",
            "countryCode": "NA",
            "cityCode": "ADI",
            "cityName": "Arandis",
            "name": "[ADI] - Arandis - ADI - Arandis - Namibia",
            "searchName": "ADI-Arandis-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "BQI",
            "countryCode": "NA",
            "cityCode": "BQI",
            "cityName": "Bagani",
            "name": "[BQI] - Bagani - BQI - Bagani - Namibia",
            "searchName": "BQI-Bagani-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "HAL",
            "countryCode": "NA",
            "cityCode": "HAL",
            "cityName": "Halali",
            "name": "[HAL] - Halali - HAL - Halali - Namibia",
            "searchName": "HAL-Halali-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "GOG",
            "countryCode": "NA",
            "cityCode": "GOG",
            "cityName": "Gobabis",
            "name": "[GOG] - Gobabis - GOG - Gobabis - Namibia",
            "searchName": "GOG-Gobabis-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "GFY",
            "countryCode": "NA",
            "cityCode": "GFY",
            "cityName": "Grootfontein",
            "name": "[GFY] - Grootfontein - GFY - Grootfontein - Namibia",
            "searchName": "GFY-Grootfontein-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "LHU",
            "countryCode": "NA",
            "cityCode": "LHU",
            "cityName": "Caprivi",
            "name": "[LHU] - Lianshulu - LHU - Caprivi - Namibia",
            "searchName": "LHU-Lianshulu-Caprivi-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "LUD",
            "countryCode": "NA",
            "cityCode": "LUD",
            "cityName": "Luderitz",
            "name": "[LUD] - Luderitz - LUD - Luderitz - Namibia",
            "searchName": "LUD-Luderitz-L-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "KAS",
            "countryCode": "NA",
            "cityCode": "KAS",
            "cityName": "Karasburg",
            "name": "[KAS] - Karasburg - KAS - Karasburg - Namibia",
            "searchName": "KAS-Karasburg-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "KMP",
            "countryCode": "NA",
            "cityCode": "KMP",
            "cityName": "Keetmanshoop",
            "name": "[KMP] - J.G.H. Van Der Wath - KMP - Keetmanshoop - Namibia",
            "searchName": "KMP-J.G.H. Van Der Wath-Keetmanshoop-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "SZM",
            "countryCode": "NA",
            "cityCode": "SZM",
            "cityName": "Sesriem",
            "name": "[SZM] - Sesriem - SZM - Sesriem - Namibia",
            "searchName": "SZM-Sesriem-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "TCY",
            "countryCode": "NA",
            "cityCode": "TCY",
            "cityName": "Terrace Bay",
            "name": "[TCY] - Terrace Bay - TCY - Terrace Bay - Namibia",
            "searchName": "TCY-Terrace Bay-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "TSB",
            "countryCode": "NA",
            "cityCode": "TSB",
            "cityName": "Tsumeb",
            "name": "[TSB] - Tsumeb - TSB - Tsumeb - Namibia",
            "searchName": "TSB-Tsumeb-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "SWP",
            "countryCode": "NA",
            "cityCode": "SWP",
            "cityName": "Swakopmund",
            "name": "[SWP] - Swakopmund - SWP - Swakopmund - Namibia",
            "searchName": "SWP-Swakopmund-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "ZSZ",
            "countryCode": "NA",
            "cityCode": "SWP",
            "cityName": "Swakopmund",
            "name": "[ZSZ] - Swakopmund Railway Station - SWP - Swakopmund - Namibia",
            "searchName": "ZSZ-SWP-Swakopmund Railway Station-Swakopmund-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "ERS",
            "countryCode": "NA",
            "cityCode": "WDH",
            "cityName": "Windhoek",
            "name": "[ERS] - Eros - WDH - Windhoek - Namibia",
            "searchName": "ERS-WDH-Windhoek Eros-Eros-Eros Airport-Windhuk-Windhoek-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "WDH",
            "countryCode": "NA",
            "cityCode": "WDH",
            "cityName": "Windhoek",
            "name": "[WDH] - Hosea Kutako International (J. G. Strijdom) - WDH - Windhoek - Namibia",
            "searchName": "WDH-Windhoek Hosea Kutako-Hosea Kutako International (J. G. Strijdom)-Hosea Kutako Intl-Hosea Kutako International Airport-Windhuk-Windhoek-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "ZWH",
            "countryCode": "NA",
            "cityCode": "WDH",
            "cityName": "Windhoek",
            "name": "[ZWH] - Windhoek Railway Station - WDH - Windhoek - Namibia",
            "searchName": "ZWH-WDH-Windhoek Railway Station-Windhuk-Windhoek-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "WVB",
            "countryCode": "NA",
            "cityCode": "WVB",
            "cityName": "Walvis Bay",
            "name": "[WVB] - Rooikop - WVB - Walvis Bay - Namibia",
            "searchName": "WVB-Walvis Bay-Rooikop-Walfischbucht-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "OTJ",
            "countryCode": "NA",
            "cityCode": "OTJ",
            "cityName": "Otjiwarongo",
            "name": "[OTJ] - Otjiwarongo - OTJ - Otjiwarongo - Namibia",
            "searchName": "OTJ-Otjiwarongo-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "OPW",
            "countryCode": "NA",
            "cityCode": "OPW",
            "cityName": "Opuwa",
            "name": "[OPW] - Opuwa - OPW - Opuwa - Namibia",
            "searchName": "OPW-Opuwa-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "OND",
            "countryCode": "NA",
            "cityCode": "OND",
            "cityName": "Ondangwa",
            "name": "[OND] - Ondangwa - OND - Ondangwa - Namibia",
            "searchName": "OND-Ondangwa-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "OMG",
            "countryCode": "NA",
            "cityCode": "OMG",
            "cityName": "Omega",
            "name": "[OMG] - Omega - OMG - Omega - Namibia",
            "searchName": "OMG-Omega-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "OMD",
            "countryCode": "NA",
            "cityCode": "OMD",
            "cityName": "Oranjemund",
            "name": "[OMD] - Oranjemund - OMD - Oranjemund - Namibia",
            "searchName": "OMD-Oranjemund-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "RHN",
            "countryCode": "NA",
            "cityCode": "RHN",
            "cityName": "Rosh Pina",
            "name": "[RHN] - Rosh Pina - RHN - Rosh Pina - Namibia",
            "searchName": "RHN-Rosh Pina-Rosh Pina Havalimani-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "MQG",
            "countryCode": "NA",
            "cityCode": "MQG",
            "cityName": "Midgard",
            "name": "[MQG] - Midgard - MQG - Midgard - Namibia",
            "searchName": "MQG-Midgard-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "MPA",
            "countryCode": "NA",
            "cityCode": "MPA",
            "cityName": "Mpacha",
            "name": "[MPA] - Mpacha - MPA - Mpacha - Namibia",
            "searchName": "MPA-Mpacha-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "MJO",
            "countryCode": "NA",
            "cityCode": "MJO",
            "cityName": "Mount Etjo Lodge",
            "name": "[MJO] - Mount Etjo Lodge - MJO - Mount Etjo Lodge - Namibia",
            "searchName": "MJO-Mount Etjo Lodge-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "NNI",
            "countryCode": "NA",
            "cityCode": "NNI",
            "cityName": "Namutoni",
            "name": "[NNI] - Namutoni - NNI - Namutoni - Namibia",
            "searchName": "NNI-Namutoni-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "OKU",
            "countryCode": "NA",
            "cityCode": "OKU",
            "cityName": "Mokuti Lodge",
            "name": "[OKU] - Mokuti Lodge - OKU - Mokuti Lodge - Namibia",
            "searchName": "OKU-Mokuti Lodge-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "OKF",
            "countryCode": "NA",
            "cityCode": "OKF",
            "cityName": "Okaukuejo",
            "name": "[OKF] - Okaukuejo - OKF - Okaukuejo - Namibia",
            "searchName": "OKF-Okaukuejo-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "OGV",
            "countryCode": "NA",
            "cityCode": "OGV",
            "cityName": "Ongava Game Reserve",
            "name": "[OGV] - Ongava Game Reserve - OGV - Ongava Game Reserve - Namibia",
            "searchName": "OGV-Ongava Game Reserve-Ongavia Game Reserve-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "OHI",
            "countryCode": "NA",
            "cityCode": "OHI",
            "cityName": "Oshakati",
            "name": "[OHI] - Oshakati - OHI - Oshakati - Namibia",
            "searchName": "OHI-Oshakati-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "NDU",
            "countryCode": "NA",
            "cityCode": "NDU",
            "cityName": "Rundu",
            "name": "[NDU] - Rundu - NDU - Rundu - Namibia",
            "searchName": "NDU-Rundu-Namibië-Namibia-Namíbia-Namibij-Namibie",
            "priority": 0
        },
        {
            "iataCode": "NOU",
            "countryCode": "NC",
            "cityCode": "NOU",
            "cityName": "Noumea",
            "name": "[NOU] - Tontouta - NOU - Noumea - New Caledonia",
            "searchName": "NOU-Noumea Tontouta-Tontouta-Noum-Noumea-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "GEA",
            "countryCode": "NC",
            "cityCode": "NOU",
            "cityName": "Noumea",
            "name": "[GEA] - Magenta - NOU - Noumea - New Caledonia",
            "searchName": "GEA-NOU-Noumea Magenta-Magenta-Noum-Noumea-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "MEE",
            "countryCode": "NC",
            "cityCode": "MEE",
            "cityName": "Mare",
            "name": "[MEE] - Mare - MEE - Mare - New Caledonia",
            "searchName": "MEE-Mare-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "PUV",
            "countryCode": "NC",
            "cityCode": "PUV",
            "cityName": "Poum",
            "name": "[PUV] - Poum - PUV - Poum - New Caledonia",
            "searchName": "PUV-Poum-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "PDC",
            "countryCode": "NC",
            "cityCode": "PDC",
            "cityName": "Mueo",
            "name": "[PDC] - Mueo - PDC - Mueo - New Caledonia",
            "searchName": "PDC-Mueo-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "UVE",
            "countryCode": "NC",
            "cityCode": "UVE",
            "cityName": "Ouvea",
            "name": "[UVE] - Ouvea - UVE - Ouvea - New Caledonia",
            "searchName": "UVE-Ouvea-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "TOU",
            "countryCode": "NC",
            "cityCode": "TOU",
            "cityName": "Touho",
            "name": "[TOU] - Touho - TOU - Touho - New Caledonia",
            "searchName": "TOU-Touho-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "TGJ",
            "countryCode": "NC",
            "cityCode": "TGJ",
            "cityName": "Tiga",
            "name": "[TGJ] - Tiga - TGJ - Tiga - New Caledonia",
            "searchName": "TGJ-Tiga-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "KNQ",
            "countryCode": "NC",
            "cityCode": "KNQ",
            "cityName": "Kone",
            "name": "[KNQ] - Kone - KNQ - Kone - New Caledonia",
            "searchName": "KNQ-Kone-Kone Havalimani-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "KOC",
            "countryCode": "NC",
            "cityCode": "KOC",
            "cityName": "Koumac",
            "name": "[KOC] - Koumac - KOC - Koumac - New Caledonia",
            "searchName": "KOC-Koumac-Koumac Havalimani-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "LIF",
            "countryCode": "NC",
            "cityCode": "LIF",
            "cityName": "Lifou",
            "name": "[LIF] - Lifou - LIF - Lifou - New Caledonia",
            "searchName": "LIF-Lifou-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "ILP",
            "countryCode": "NC",
            "cityCode": "ILP",
            "cityName": "Ile Des Pins",
            "name": "[ILP] - Ile Des Pins - ILP - Ile Des Pins - New Caledonia",
            "searchName": "ILP-Ile Des Pins-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "IOU",
            "countryCode": "NC",
            "cityCode": "IOU",
            "cityName": "Ile Ouen",
            "name": "[IOU] - Ile Ouen - IOU - Ile Ouen - New Caledonia",
            "searchName": "IOU-Ile Ouen-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "HLU",
            "countryCode": "NC",
            "cityCode": "HLU",
            "cityName": "Houailou",
            "name": "[HLU] - Houailou - HLU - Houailou - New Caledonia",
            "searchName": "HLU-Houailou-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "HNG",
            "countryCode": "NC",
            "cityCode": "HNG",
            "cityName": "Hienghene",
            "name": "[HNG] - Hienghene - HNG - Hienghene - New Caledonia",
            "searchName": "HNG-Hienghene-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "BMY",
            "countryCode": "NC",
            "cityCode": "BMY",
            "cityName": "Belep Island",
            "name": "[BMY] - Belep Island - BMY - Belep Island - New Caledonia",
            "searchName": "BMY-Belep Island-Belep Inseln-Új-Kaledónia-Nova Caledònia-Yeni Kaledonya-Nová Kaledonie-Uusi-Kaledonia-Ny Kaledonien-Ny-Caledonia-Uus-Kaledoonia-Nova Kaledonija-Jaunkaledonija-Naujoji Kaledonija-Neukaledonien-New Caledonia-Nouvelle-Calédonie-Nueva Caledonia-Nuova Caledonia",
            "priority": 0
        },
        {
            "iataCode": "AJY",
            "countryCode": "NE",
            "cityCode": "AJY",
            "cityName": "Agades",
            "name": "[AJY] - Agades - AJY - Agades - Niger",
            "searchName": "AJY-Agades-Niger-Níger",
            "priority": 0
        },
        {
            "iataCode": "THZ",
            "countryCode": "NE",
            "cityCode": "THZ",
            "cityName": "Tahoua",
            "name": "[THZ] - Tahoua - THZ - Tahoua - Niger",
            "searchName": "THZ-Tahoua-Niger-Níger",
            "priority": 0
        },
        {
            "iataCode": "RLT",
            "countryCode": "NE",
            "cityCode": "RLT",
            "cityName": "Arlit",
            "name": "[RLT] - Arlit - RLT - Arlit - Niger",
            "searchName": "RLT-Arlit-Niger-Níger",
            "priority": 0
        },
        {
            "iataCode": "MFQ",
            "countryCode": "NE",
            "cityCode": "MFQ",
            "cityName": "Maradi",
            "name": "[MFQ] - Maradi - MFQ - Maradi - Niger",
            "searchName": "MFQ-Maradi-Niger-Níger",
            "priority": 0
        },
        {
            "iataCode": "NIM",
            "countryCode": "NE",
            "cityCode": "NIM",
            "cityName": "Niamey",
            "name": "[NIM] - Niamey - NIM - Niamey - Niger",
            "searchName": "NIM-Niamey-Niger-Níger",
            "priority": 0
        },
        {
            "iataCode": "ZND",
            "countryCode": "NE",
            "cityCode": "ZND",
            "cityName": "Zinder",
            "name": "[ZND] - Zinder - ZND - Zinder - Niger",
            "searchName": "ZND-Zinder-Niger-Níger",
            "priority": 0
        },
        {
            "iataCode": "NLK",
            "countryCode": "NF",
            "cityCode": "NLK",
            "cityName": "Norfolk Island",
            "name": "[NLK] - Norfolk Island - NLK - Norfolk Island - Norfolk Island",
            "searchName": "NLK-Norfolk Island-Norfolk Inseln--Norfolkeiland-Pulau Norfolk-Norfolkön-Kepulauan Norfolk-Wyspa Norfolk-Ilha de Norfolk-Isola Norfolk-Norfolk (isla)-Norfolk-Inseln-Insel-Norfolk Adasi-Isla Norfolk-Île Norfolk",
            "priority": 0
        },
        {
            "iataCode": "MXJ",
            "countryCode": "NG",
            "cityCode": "MXJ",
            "cityName": "Minna",
            "name": "[MXJ] - Minna - MXJ - Minna - Nigeria",
            "searchName": "MXJ-Minna-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "MIU",
            "countryCode": "NG",
            "cityCode": "MIU",
            "cityName": "Maiduguri",
            "name": "[MIU] - Maiduguri - MIU - Maiduguri - Nigeria",
            "searchName": "MIU-Maiduguri-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "MDI",
            "countryCode": "NG",
            "cityCode": "MDI",
            "cityName": "Makurdi",
            "name": "[MDI] - Makurdi - MDI - Makurdi - Nigeria",
            "searchName": "MDI-Makurdi-Makurdi Havalimani-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "QRW",
            "countryCode": "NG",
            "cityCode": "QRW",
            "cityName": "Warri",
            "name": "[QRW] - Warri - QRW - Warri - Nigeria",
            "searchName": "QRW-Warri-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "QUO",
            "countryCode": "NG",
            "cityCode": "QUO",
            "cityName": "Uyo",
            "name": "[QUO] - Akwa Ibom International - QUO - Uyo - Nigeria",
            "searchName": "QUO-Akwa Ibom Intl-Akwa Ibom International-Akwa Ibom Airport-Uyo/Uyo-Uyo-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "QOW",
            "countryCode": "NG",
            "cityCode": "QOW",
            "cityName": "Owerri",
            "name": "[QOW] - Sam Mbakwe International - QOW - Owerri - Nigeria",
            "searchName": "QOW-Sam Mbakwe International-Owerri-Sam Mbakwe International Airport-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "PHC",
            "countryCode": "NG",
            "cityCode": "PHC",
            "cityName": "Port Harcourt",
            "name": "[PHC] - Port Harcourt - PHC - Port Harcourt - Nigeria",
            "searchName": "PHC-Port Harcourt-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "PHG",
            "countryCode": "NG",
            "cityCode": "PHC",
            "cityName": "Port Harcourt",
            "name": "[PHG] - Port Harcourt City - PHC - Port Harcourt - Nigeria",
            "searchName": "PHG-PHC-Port Harcourt City-Port Harcourt City Havalimani-Port Harcourt-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "SKO",
            "countryCode": "NG",
            "cityCode": "SKO",
            "cityName": "Sokoto",
            "name": "[SKO] - Sokoto - SKO - Sokoto - Nigeria",
            "searchName": "SKO-Sadiq Abubakar III-Sokoto-Sultan Saddik Abubakar Havalimani-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "AKR",
            "countryCode": "NG",
            "cityCode": "AKR",
            "cityName": "Akure",
            "name": "[AKR] - Akure - AKR - Akure - Nigeria",
            "searchName": "AKR-Akure-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "ABV",
            "countryCode": "NG",
            "cityCode": "ABV",
            "cityName": "Abuja",
            "name": "[ABV] - Nnamdi Azikiwe International Airport - ABV - Abuja - Nigeria",
            "searchName": "ABV-Abuja-Nnamdi Azikiwe International Airport-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "ABB",
            "countryCode": "NG",
            "cityCode": "ABB",
            "cityName": "Asaba",
            "name": "[ABB] - RAF Station - ABB - Asaba - Nigeria",
            "searchName": "ABB-Asaba International-RAF Station-Asaba-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "BNI",
            "countryCode": "NG",
            "cityCode": "BNI",
            "cityName": "Benin City",
            "name": "[BNI] - Benin City - BNI - Benin City - Nigeria",
            "searchName": "BNI-Benin City-Benin Stadt-Benin Ville-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "BCU",
            "countryCode": "NG",
            "cityCode": "BCU",
            "cityName": "Bauchi",
            "name": "[BCU] - Bauchi - BCU - Bauchi - Nigeria",
            "searchName": "BCU-Bauchi-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "CBQ",
            "countryCode": "NG",
            "cityCode": "CBQ",
            "cityName": "Calabar",
            "name": "[CBQ] - Calabar - CBQ - Calabar - Nigeria",
            "searchName": "CBQ-Calabar-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "DKA",
            "countryCode": "NG",
            "cityCode": "DKA",
            "cityName": "Katsina",
            "name": "[DKA] - Katsina - DKA - Katsina - Nigeria",
            "searchName": "DKA-Katsina-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "ENU",
            "countryCode": "NG",
            "cityCode": "ENU",
            "cityName": "Enugu",
            "name": "[ENU] - Enugu - ENU - Enugu - Nigeria",
            "searchName": "ENU-Enugu-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "ILR",
            "countryCode": "NG",
            "cityCode": "ILR",
            "cityName": "Ilorin",
            "name": "[ILR] - Ilorin - ILR - Ilorin - Nigeria",
            "searchName": "ILR-Ilorin-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "IBA",
            "countryCode": "NG",
            "cityCode": "IBA",
            "cityName": "Ibadan",
            "name": "[IBA] - Ibadan - IBA - Ibadan - Nigeria",
            "searchName": "IBA-Ibadan-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "GMO",
            "countryCode": "NG",
            "cityCode": "GMO",
            "cityName": "Gombe",
            "name": "[GMO] - Lawanti - GMO - Gombe - Nigeria",
            "searchName": "GMO-Lawanti-Gombe Lawanti-Gombe-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "LOS",
            "countryCode": "NG",
            "cityCode": "LOS",
            "cityName": "Lagos",
            "name": "[LOS] - Murtala Muhammed - LOS - Lagos - Nigeria",
            "searchName": "LOS-Lagos-Murtala Muhammed-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "KAN",
            "countryCode": "NG",
            "cityCode": "KAN",
            "cityName": "Kano",
            "name": "[KAN] - Aminu Kano International Airport - KAN - Kano - Nigeria",
            "searchName": "KAN-Kano-Aminu Kano International Airport-Aminu Kano Intl Apt-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "KAD",
            "countryCode": "NG",
            "cityCode": "KAD",
            "cityName": "Kaduna",
            "name": "[KAD] - Kaduna - KAD - Kaduna - Nigeria",
            "searchName": "KAD-Kaduna-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "JOS",
            "countryCode": "NG",
            "cityCode": "JOS",
            "cityName": "Jos",
            "name": "[JOS] - Jos - JOS - Jos - Nigeria",
            "searchName": "JOS-Jos-Yakubu Gowon Havalimani-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "ZAR",
            "countryCode": "NG",
            "cityCode": "ZAR",
            "cityName": "Zaria",
            "name": "[ZAR] - Zaria - ZAR - Zaria - Nigeria",
            "searchName": "ZAR-Zaria-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "YOL",
            "countryCode": "NG",
            "cityCode": "YOL",
            "cityName": "Yola",
            "name": "[YOL] - Yola - YOL - Yola - Nigeria",
            "searchName": "YOL-Yola-Nigeria-Ni giê ria-Nigéria-Nigeeria-Nigerija-Nigerij-Nijerya",
            "priority": 0
        },
        {
            "iataCode": "BEF",
            "countryCode": "NI",
            "cityCode": "BEF",
            "cityName": "Bluefields",
            "name": "[BEF] - Bluefields - BEF - Bluefields - Nicaragua",
            "searchName": "BEF-Bluefields-Nicaragua-Nikaragua-Nikaragva",
            "priority": 0
        },
        {
            "iataCode": "BZA",
            "countryCode": "NI",
            "cityCode": "BZA",
            "cityName": "Bonanza",
            "name": "[BZA] - San Pedro - BZA - Bonanza - Nicaragua",
            "searchName": "BZA-San Pedro-Bonanza-Nicaragua-Nikaragua-Nikaragva",
            "priority": 0
        },
        {
            "iataCode": "SIU",
            "countryCode": "NI",
            "cityCode": "SIU",
            "cityName": "Siuna",
            "name": "[SIU] - Siuna - SIU - Siuna - Nicaragua",
            "searchName": "SIU-Siuna-Nicaragua-Nikaragua-Nikaragva",
            "priority": 0
        },
        {
            "iataCode": "WSP",
            "countryCode": "NI",
            "cityCode": "WSP",
            "cityName": "Waspam",
            "name": "[WSP] - Waspam - WSP - Waspam - Nicaragua",
            "searchName": "WSP-Waspam-Nicaragua-Nikaragua-Nikaragva",
            "priority": 0
        },
        {
            "iataCode": "PUZ",
            "countryCode": "NI",
            "cityCode": "PUZ",
            "cityName": "Puerto Cabezas",
            "name": "[PUZ] - Puerto Cabezas - PUZ - Puerto Cabezas - Nicaragua",
            "searchName": "PUZ-Puerto Cabezas-Nicaragua-Nikaragua-Nikaragva",
            "priority": 0
        },
        {
            "iataCode": "RNI",
            "countryCode": "NI",
            "cityCode": "RNI",
            "cityName": "Corn Island",
            "name": "[RNI] - Corn Island - RNI - Corn Island - Nicaragua",
            "searchName": "RNI-Corn Island-Nicaragua-Nikaragua-Nikaragva",
            "priority": 0
        },
        {
            "iataCode": "RFS",
            "countryCode": "NI",
            "cityCode": "RFS",
            "cityName": "Rosita",
            "name": "[RFS] - Rosita - RFS - Rosita - Nicaragua",
            "searchName": "RFS-Rosita-Nicaragua-Nikaragua-Nikaragva",
            "priority": 0
        },
        {
            "iataCode": "OHD",
            "countryCode": "MK",
            "cityCode": "OHD",
            "cityName": "Ohrid",
            "name": "[OHD] - Ohrid - OHD - Ohrid - Macedonia",
            "searchName": "OHD-Ohrid-Makedonia-Macedonia-Makedonya-Macedònia-Macedónia-Makedonija-Makedonien-Mazedonien-Macédoine--Fyrom-Repubblica ex Jugoslava di Macedonia",
            "priority": 0
        },
        {
            "iataCode": "NRM",
            "countryCode": "ML",
            "cityCode": "NRM",
            "cityName": "Nara",
            "name": "[NRM] - Nara - NRM - Nara - Mali",
            "searchName": "NRM-Nara-Mali",
            "priority": 0
        },
        {
            "iataCode": "NIX",
            "countryCode": "ML",
            "cityCode": "NIX",
            "cityName": "Nioro",
            "name": "[NIX] - Nioro - NIX - Nioro - Mali",
            "searchName": "NIX-Nioro-Mali",
            "priority": 0
        },
        {
            "iataCode": "MZI",
            "countryCode": "ML",
            "cityCode": "MZI",
            "cityName": "Mopti",
            "name": "[MZI] - Mopti - MZI - Mopti - Mali",
            "searchName": "MZI-Mopti-Mali",
            "priority": 0
        },
        {
            "iataCode": "SZU",
            "countryCode": "ML",
            "cityCode": "SZU",
            "cityName": "Segou",
            "name": "[SZU] - Segou - SZU - Segou - Mali",
            "searchName": "SZU-Segou-Mali",
            "priority": 0
        },
        {
            "iataCode": "TOM",
            "countryCode": "ML",
            "cityCode": "TOM",
            "cityName": "Tombouctou",
            "name": "[TOM] - Tombouctou - TOM - Tombouctou - Mali",
            "searchName": "TOM-Tombouctou-Timbuktu-Mali",
            "priority": 0
        },
        {
            "iataCode": "GAQ",
            "countryCode": "ML",
            "cityCode": "GAQ",
            "cityName": "Gao",
            "name": "[GAQ] - Gao - GAQ - Gao - Mali",
            "searchName": "GAQ-Gao-Mali",
            "priority": 0
        },
        {
            "iataCode": "GUD",
            "countryCode": "ML",
            "cityCode": "GUD",
            "cityName": "Goundam",
            "name": "[GUD] - Goundam - GUD - Goundam - Mali",
            "searchName": "GUD-Goundam-Mali",
            "priority": 0
        },
        {
            "iataCode": "KTX",
            "countryCode": "ML",
            "cityCode": "KTX",
            "cityName": "Koutiala",
            "name": "[KTX] - Koutiala - KTX - Koutiala - Mali",
            "searchName": "KTX-Koutiala-Mali",
            "priority": 0
        },
        {
            "iataCode": "KYS",
            "countryCode": "ML",
            "cityCode": "KYS",
            "cityName": "Kayes",
            "name": "[KYS] - Kayes - KYS - Kayes - Mali",
            "searchName": "KYS-Kayes-Mali",
            "priority": 0
        },
        {
            "iataCode": "KNZ",
            "countryCode": "ML",
            "cityCode": "KNZ",
            "cityName": "Kenieba",
            "name": "[KNZ] - Kenieba - KNZ - Kenieba - Mali",
            "searchName": "KNZ-Kenieba-Mali",
            "priority": 0
        },
        {
            "iataCode": "KSS",
            "countryCode": "ML",
            "cityCode": "KSS",
            "cityName": "Sikasso",
            "name": "[KSS] - Sikasso - KSS - Sikasso - Mali",
            "searchName": "KSS-Sikasso-Mali",
            "priority": 0
        },
        {
            "iataCode": "EYL",
            "countryCode": "ML",
            "cityCode": "EYL",
            "cityName": "Yelimane",
            "name": "[EYL] - Yelimane - EYL - Yelimane - Mali",
            "searchName": "EYL-Yelimane-Mali",
            "priority": 0
        },
        {
            "iataCode": "BKO",
            "countryCode": "ML",
            "cityCode": "BKO",
            "cityName": "Bamako",
            "name": "[BKO] - Bamako - BKO - Bamako - Mali",
            "searchName": "BKO-Bamako-Mali",
            "priority": 0
        },
        {
            "iataCode": "BMO",
            "countryCode": "MM",
            "cityCode": "BMO",
            "cityName": "Bhamo",
            "name": "[BMO] - Bhamo - BMO - Bhamo - Myanmar",
            "searchName": "BMO-Bhamo-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "BPE",
            "countryCode": "MM",
            "cityCode": "BPE",
            "cityName": "Bagan",
            "name": "[BPE] - Bagan - BPE - Bagan - Myanmar",
            "searchName": "BPE-Bagan-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "BSX",
            "countryCode": "MM",
            "cityCode": "BSX",
            "cityName": "Bassein",
            "name": "[BSX] - Bassein - BSX - Bassein - Myanmar",
            "searchName": "BSX-Bassein-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "AKY",
            "countryCode": "MM",
            "cityCode": "AKY",
            "cityName": "Sittwe",
            "name": "[AKY] - Civil - AKY - Sittwe - Myanmar",
            "searchName": "AKY-Civil-Sittwe-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "KMV",
            "countryCode": "MM",
            "cityCode": "KMV",
            "cityName": "Kalaymyo",
            "name": "[KMV] - Kalemyo - KMV - Kalaymyo - Myanmar",
            "searchName": "KMV-Kalemyo-Kalaymyo-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "KHM",
            "countryCode": "MM",
            "cityCode": "KHM",
            "cityName": "Khamti",
            "name": "[KHM] - Khamti - KHM - Khamti - Myanmar",
            "searchName": "KHM-Khamti-Khamtis-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "KAW",
            "countryCode": "MM",
            "cityCode": "KAW",
            "cityName": "Kawthaung",
            "name": "[KAW] - Kawthaung - KAW - Kawthaung - Myanmar",
            "searchName": "KAW-Kawthaung-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "KET",
            "countryCode": "MM",
            "cityCode": "KET",
            "cityName": "Keng Tung",
            "name": "[KET] - Keng Tung - KET - Keng Tung - Myanmar",
            "searchName": "KET-Keng Tung-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "KYT",
            "countryCode": "MM",
            "cityCode": "KYT",
            "cityName": "Kyauktaw",
            "name": "[KYT] - Kyauktaw - KYT - Kyauktaw - Myanmar",
            "searchName": "KYT-Kyauktaw-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "KYP",
            "countryCode": "MM",
            "cityCode": "KYP",
            "cityName": "Kyaukpyu",
            "name": "[KYP] - Kyaukpyu - KYP - Kyaukpyu - Myanmar",
            "searchName": "KYP-Kyaukpyu-Kyaukpyu Havalimani-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "LIW",
            "countryCode": "MM",
            "cityCode": "LIW",
            "cityName": "Loikaw",
            "name": "[LIW] - Loikaw - LIW - Loikaw - Myanmar",
            "searchName": "LIW-Loikaw-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "LSH",
            "countryCode": "MM",
            "cityCode": "LSH",
            "cityName": "Lashio",
            "name": "[LSH] - Lashio - LSH - Lashio - Myanmar",
            "searchName": "LSH-Lashio-Lashio Havalimani-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "GWA",
            "countryCode": "MM",
            "cityCode": "GWA",
            "cityName": "Gwa",
            "name": "[GWA] - Gwa - GWA - Gwa - Myanmar",
            "searchName": "GWA-Gwa-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "GAW",
            "countryCode": "MM",
            "cityCode": "GAW",
            "cityName": "Gangaw",
            "name": "[GAW] - Gangaw - GAW - Gangaw - Myanmar",
            "searchName": "GAW-Gangaw-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "HOX",
            "countryCode": "MM",
            "cityCode": "HOX",
            "cityName": "Homalin",
            "name": "[HOX] - Homalin - HOX - Homalin - Myanmar",
            "searchName": "HOX-Homalin-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "HEH",
            "countryCode": "MM",
            "cityCode": "HEH",
            "cityName": "Heho",
            "name": "[HEH] - Heho - HEH - Heho - Myanmar",
            "searchName": "HEH-Heho-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "HEB",
            "countryCode": "MM",
            "cityCode": "HEB",
            "cityName": "Henzada",
            "name": "[HEB] - Henzada - HEB - Henzada - Myanmar",
            "searchName": "HEB-Henzada-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "TVY",
            "countryCode": "MM",
            "cityCode": "TVY",
            "cityName": "Dawe",
            "name": "[TVY] - Dawe - TVY - Dawe - Myanmar",
            "searchName": "TVY-Dawe-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "TIO",
            "countryCode": "MM",
            "cityCode": "TIO",
            "cityName": "Tilin",
            "name": "[TIO] - Tilin - TIO - Tilin - Myanmar",
            "searchName": "TIO-Tilin-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "THL",
            "countryCode": "MM",
            "cityCode": "THL",
            "cityName": "Tachilek",
            "name": "[THL] - Tachilek - THL - Tachilek - Myanmar",
            "searchName": "THL-Tachilek-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "SNW",
            "countryCode": "MM",
            "cityCode": "SNW",
            "cityName": "Thandwe",
            "name": "[SNW] - Thandwe - SNW - Thandwe - Myanmar",
            "searchName": "SNW-Thandwe-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "XYE",
            "countryCode": "MM",
            "cityCode": "XYE",
            "cityName": "Ye",
            "name": "[XYE] - Ye - XYE - Ye - Myanmar",
            "searchName": "XYE-Ye-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "MYT",
            "countryCode": "MM",
            "cityCode": "MYT",
            "cityName": "Myitkyina",
            "name": "[MYT] - Myitkyina - MYT - Myitkyina - Myanmar",
            "searchName": "MYT-Myitkyina-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "MWQ",
            "countryCode": "MM",
            "cityCode": "MWQ",
            "cityName": "Magwe",
            "name": "[MWQ] - Magwe - MWQ - Magwe - Myanmar",
            "searchName": "MWQ-Magwe-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "NMS",
            "countryCode": "MM",
            "cityCode": "NMS",
            "cityName": "Namsang",
            "name": "[NMS] - Namsang - NMS - Namsang - Myanmar",
            "searchName": "NMS-Namsang-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "NMT",
            "countryCode": "MM",
            "cityCode": "NMT",
            "cityName": "Namtu",
            "name": "[NMT] - Namtu - NMT - Namtu - Myanmar",
            "searchName": "NMT-Namtu-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "NYT",
            "countryCode": "MM",
            "cityCode": "NYT",
            "cityName": "Nay Pyi Taw",
            "name": "[NYT] - Nay Pyi Taw - NYT - Nay Pyi Taw - Myanmar",
            "searchName": "NYT-Nay Pyi Taw-Naypyidaw Intl Havalimani-Naypyidaw-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "NYU",
            "countryCode": "MM",
            "cityCode": "NYU",
            "cityName": "Nyaung-u",
            "name": "[NYU] - Nyaung-u - NYU - Nyaung-u - Myanmar",
            "searchName": "NYU-Nyaung-u-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "MDL",
            "countryCode": "MM",
            "cityCode": "MDL",
            "cityName": "Mandalay",
            "name": "[MDL] - Mandalay International - MDL - Mandalay - Myanmar",
            "searchName": "MDL-Mandalay-Mandalay International-Annisaton-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "MGZ",
            "countryCode": "MM",
            "cityCode": "MGZ",
            "cityName": "Myeik",
            "name": "[MGZ] - Myeik - MGZ - Myeik - Myanmar",
            "searchName": "MGZ-Myeik-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "MGU",
            "countryCode": "MM",
            "cityCode": "MGU",
            "cityName": "Manaung",
            "name": "[MGU] - Manaung - MGU - Manaung - Myanmar",
            "searchName": "MGU-Manaung-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "MGK",
            "countryCode": "MM",
            "cityCode": "MGK",
            "cityName": "Mong Ton",
            "name": "[MGK] - Mong Ton - MGK - Mong Ton - Myanmar",
            "searchName": "MGK-Mong Ton-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "MOG",
            "countryCode": "MM",
            "cityCode": "MOG",
            "cityName": "Mong Hsat",
            "name": "[MOG] - Mong Hsat - MOG - Mong Hsat - Myanmar",
            "searchName": "MOG-Mong Hsat-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "MNU",
            "countryCode": "MM",
            "cityCode": "MNU",
            "cityName": "Maulmyine",
            "name": "[MNU] - Maulmyine - MNU - Maulmyine - Myanmar",
            "searchName": "MNU-Maulmyine- Maulmyine-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "MOE",
            "countryCode": "MM",
            "cityCode": "MOE",
            "cityName": "Momeik",
            "name": "[MOE] - Momeik - MOE - Momeik - Myanmar",
            "searchName": "MOE-Momeik-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "RGN",
            "countryCode": "MM",
            "cityCode": "RGN",
            "cityName": "Yangon",
            "name": "[RGN] - Mingaladon - RGN - Yangon - Myanmar",
            "searchName": "RGN-Yangoon-Mingaladon-Rangun-Yangon-Rangoon-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "PRU",
            "countryCode": "MM",
            "cityCode": "PRU",
            "cityName": "Prome",
            "name": "[PRU] - Prome - PRU - Prome - Myanmar",
            "searchName": "PRU-Prome-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "PAA",
            "countryCode": "MM",
            "cityCode": "PAA",
            "cityName": "Pa-an",
            "name": "[PAA] - Pa-an - PAA - Pa-an - Myanmar",
            "searchName": "PAA-Pa-an-Pa An-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "PAU",
            "countryCode": "MM",
            "cityCode": "PAU",
            "cityName": "Pauk",
            "name": "[PAU] - Pauk - PAU - Pauk - Myanmar",
            "searchName": "PAU-Pauk-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "PBU",
            "countryCode": "MM",
            "cityCode": "PBU",
            "cityName": "Putao",
            "name": "[PBU] - Putao - PBU - Putao - Myanmar",
            "searchName": "PBU-Putao-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "PPU",
            "countryCode": "MM",
            "cityCode": "PPU",
            "cityName": "Papun",
            "name": "[PPU] - Papun - PPU - Papun - Myanmar",
            "searchName": "PPU-Papun-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "PKK",
            "countryCode": "MM",
            "cityCode": "PKK",
            "cityName": "Pakokku",
            "name": "[PKK] - Pakokku - PKK - Pakokku - Myanmar",
            "searchName": "PKK-Pakokku-Myanmar-Mianmar-Mjanmar-Mianmaras-Mjanma-Birma (Myanmar)",
            "priority": 0
        },
        {
            "iataCode": "MXV",
            "countryCode": "MN",
            "cityCode": "MXV",
            "cityName": "Moron",
            "name": "[MXV] - Moron - MXV - Moron - Mongolia",
            "searchName": "MXV-Moron-Moron Havalimani-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "MXW",
            "countryCode": "MN",
            "cityCode": "MXW",
            "cityName": "Mandalgobi",
            "name": "[MXW] - Mandalgobi - MXW - Mandalgobi - Mongolia",
            "searchName": "MXW-Mandalgobi-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "UGT",
            "countryCode": "MN",
            "cityCode": "UGT",
            "cityName": "Umnugobitour",
            "name": "[UGT] - Umnugobitour - UGT - Umnugobitour - Mongolia",
            "searchName": "UGT-Umnugobitour-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "UGA",
            "countryCode": "MN",
            "cityCode": "UGA",
            "cityName": "Bulgan",
            "name": "[UGA] - Bulgan - UGA - Bulgan - Mongolia",
            "searchName": "UGA-Bulgan-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "UNR",
            "countryCode": "MN",
            "cityCode": "UNR",
            "cityName": "Underkhaan",
            "name": "[UNR] - Underkhaan - UNR - Underkhaan - Mongolia",
            "searchName": "UNR-Underkhaan-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "ULG",
            "countryCode": "MN",
            "cityCode": "ULG",
            "cityName": "Ulgit",
            "name": "[ULG] - Ulgit - ULG - Ulgit - Mongolia",
            "searchName": "ULG-Ulgit-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "ULZ",
            "countryCode": "MN",
            "cityCode": "ULZ",
            "cityName": "Uliastai",
            "name": "[ULZ] - Uliastai - ULZ - Uliastai - Mongolia",
            "searchName": "ULZ-Uliastai-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "ULO",
            "countryCode": "MN",
            "cityCode": "ULO",
            "cityName": "Ulaangom",
            "name": "[ULO] - Ulaangom - ULO - Ulaangom - Mongolia",
            "searchName": "ULO-Ulaangom-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "UUN",
            "countryCode": "MN",
            "cityCode": "UUN",
            "cityName": "Baruun-Urt",
            "name": "[UUN] - Baruun-Urt - UUN - Baruun-Urt - Mongolia",
            "searchName": "UUN-urt - Flughafen-Baruun-Urt-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "TSZ",
            "countryCode": "MN",
            "cityCode": "TSZ",
            "cityName": "Tsetserleg",
            "name": "[TSZ] - Tsetserleg - TSZ - Tsetserleg - Mongolia",
            "searchName": "TSZ-Tsetserleg-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "TNZ",
            "countryCode": "MN",
            "cityCode": "TNZ",
            "cityName": "Tosontsengel",
            "name": "[TNZ] - Tosontsengel - TNZ - Tosontsengel - Mongolia",
            "searchName": "TNZ-Tosontsengel-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "HBU",
            "countryCode": "MN",
            "cityCode": "HBU",
            "cityName": "Bulgan, Hovd",
            "name": "[HBU] - Bulgan, Hovd - HBU - Bulgan, Hovd - Mongolia",
            "searchName": "HBU-Bulgan- Hovd-Bulgan, Hovd-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "HJT",
            "countryCode": "MN",
            "cityCode": "HJT",
            "cityName": "Khujirt",
            "name": "[HJT] - Khujirt - HJT - Khujirt - Mongolia",
            "searchName": "HJT-Khujirt-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "HVD",
            "countryCode": "MN",
            "cityCode": "HVD",
            "cityName": "Khovd",
            "name": "[HVD] - Khovd - HVD - Khovd - Mongolia",
            "searchName": "HVD-Hovd-Khovd-Khovd Havalimani-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "HTM",
            "countryCode": "MN",
            "cityCode": "HTM",
            "cityName": "Khatgal",
            "name": "[HTM] - Khatgal - HTM - Khatgal - Mongolia",
            "searchName": "HTM-Khatgal-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "LTI",
            "countryCode": "MN",
            "cityCode": "LTI",
            "cityName": "Altai",
            "name": "[LTI] - Altai - LTI - Altai - Mongolia",
            "searchName": "LTI-Altai-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "KHR",
            "countryCode": "MN",
            "cityCode": "KHR",
            "cityName": "Kharkhorin",
            "name": "[KHR] - Kharkhorin - KHR - Kharkhorin - Mongolia",
            "searchName": "KHR-Kharkhorin-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "AVK",
            "countryCode": "MN",
            "cityCode": "AVK",
            "cityName": "Arvaikheer",
            "name": "[AVK] - Arvaikheer - AVK - Arvaikheer - Mongolia",
            "searchName": "AVK-Arvaikheer-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "BYN",
            "countryCode": "MN",
            "cityCode": "BYN",
            "cityName": "Bayankhongor",
            "name": "[BYN] - Bayankhongor - BYN - Bayankhongor - Mongolia",
            "searchName": "BYN-Bayankhongor-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "ERT",
            "countryCode": "MN",
            "cityCode": "ERT",
            "cityName": "Erdenet",
            "name": "[ERT] - Erdenet - ERT - Erdenet - Mongolia",
            "searchName": "ERT-Erdenet-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "DLZ",
            "countryCode": "MN",
            "cityCode": "DLZ",
            "cityName": "Dalanzadgad",
            "name": "[DLZ] - Dalanzadgad - DLZ - Dalanzadgad - Mongolia",
            "searchName": "DLZ-Dalanzadgad-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "COQ",
            "countryCode": "MN",
            "cityCode": "COQ",
            "cityName": "Choibalsan",
            "name": "[COQ] - Choibalsan - COQ - Choibalsan - Mongolia",
            "searchName": "COQ-Choibalsan-Mongolia-Mongólia-Mongòlia-Mogolistan-Mongolsko-Mongolija-Mongolie-Mongoolia-Mongoliet--Mongolei",
            "priority": 0
        },
        {
            "iataCode": "YFT",
            "countryCode": "MO",
            "cityCode": "YFT",
            "cityName": "Taipa",
            "name": "[YFT] - Taipa Ferry Port - YFT - Taipa - Macao",
            "searchName": "YFT-Taipa Ferry Port-Taipa-Macao-Makau-Ma Cao-Macau-Makao-Maka-China",
            "priority": 0
        },
        {
            "iataCode": "ROP",
            "countryCode": "MP",
            "cityCode": "ROP",
            "cityName": "Rota",
            "name": "[ROP] - Rota - ROP - Rota - Northern Mariana Islands",
            "searchName": "ROP-Rota Island-Rota-Kepulauan Mariana Utara-Ilhas Marianas do Norte-Noorderlijke Marianen-Northern Mariana Islands-MNP-Kuzey Mariana Adalari-Islas Marianas del Norte-Îles Mariannes du Nord-Isole Marianne Settentrionali",
            "priority": 0
        },
        {
            "iataCode": "TIQ",
            "countryCode": "MP",
            "cityCode": "TIQ",
            "cityName": "Tinian",
            "name": "[TIQ] - Tinian International - TIQ - Tinian - Northern Mariana Islands",
            "searchName": "TIQ-Tinian-Tinian International-Kepulauan Mariana Utara-Ilhas Marianas do Norte-Noorderlijke Marianen-Northern Mariana Islands-MNP-Kuzey Mariana Adalari-Islas Marianas del Norte-Îles Mariannes du Nord-Isole Marianne Settentrionali",
            "priority": 0
        },
        {
            "iataCode": "SPN",
            "countryCode": "MP",
            "cityCode": "SPN",
            "cityName": "Saipan",
            "name": "[SPN] - Saipan International - SPN - Saipan - Northern Mariana Islands",
            "searchName": "SPN-Saipan-Saipan International-Kepulauan Mariana Utara-Ilhas Marianas do Norte-Noorderlijke Marianen-Northern Mariana Islands-MNP-Kuzey Mariana Adalari-Islas Marianas del Norte-Îles Mariannes du Nord-Isole Marianne Settentrionali",
            "priority": 0
        },
        {
            "iataCode": "FDF",
            "countryCode": "MQ",
            "cityCode": "FDF",
            "cityName": "Fort De France",
            "name": "[FDF] - Lamentin - FDF - Fort De France - Martinique",
            "searchName": "FDF-Fort de France-Lamentin-Fort De France-Martinique-Martinik-Martinica",
            "priority": 0
        },
        {
            "iataCode": "FGD",
            "countryCode": "MR",
            "cityCode": "FGD",
            "cityName": "Fderik",
            "name": "[FGD] - Fderik - FGD - Fderik - Mauritania",
            "searchName": "FGD-Fderik-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "KFA",
            "countryCode": "MR",
            "cityCode": "KFA",
            "cityName": "Kiffa",
            "name": "[KFA] - Kiffa - KFA - Kiffa - Mauritania",
            "searchName": "KFA-Kiffa-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "KED",
            "countryCode": "MR",
            "cityCode": "KED",
            "cityName": "Kaedi",
            "name": "[KED] - Kaedi - KED - Kaedi - Mauritania",
            "searchName": "KED-Kaedi-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "LEG",
            "countryCode": "MR",
            "cityCode": "LEG",
            "cityName": "Aleg",
            "name": "[LEG] - Aleg - LEG - Aleg - Mauritania",
            "searchName": "LEG-Aleg-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "CGT",
            "countryCode": "MR",
            "cityCode": "CGT",
            "cityName": "Chinguitti",
            "name": "[CGT] - Chinguitti - CGT - Chinguitti - Mauritania",
            "searchName": "CGT-Chinguitti-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "EMN",
            "countryCode": "MR",
            "cityCode": "EMN",
            "cityName": "Nema",
            "name": "[EMN] - Nema - EMN - Nema - Mauritania",
            "searchName": "EMN-Nema-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "BGH",
            "countryCode": "MR",
            "cityCode": "BGH",
            "cityName": "Boghe",
            "name": "[BGH] - Abbaye - BGH - Boghe - Mauritania",
            "searchName": "BGH-Abbaye-Boghe-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "ATR",
            "countryCode": "MR",
            "cityCode": "ATR",
            "cityName": "Atar",
            "name": "[ATR] - Mouakchott - ATR - Atar - Mauritania",
            "searchName": "ATR-Mouakchott-Atar Uluslararasi Havalimani-Atar-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "AJJ",
            "countryCode": "MR",
            "cityCode": "AJJ",
            "cityName": "Akjoujt",
            "name": "[AJJ] - Akjoujt - AJJ - Akjoujt - Mauritania",
            "searchName": "AJJ-Akjoujt-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "AEO",
            "countryCode": "MR",
            "cityCode": "AEO",
            "cityName": "Aioun El Atrouss",
            "name": "[AEO] - Aioun El Atrouss - AEO - Aioun El Atrouss - Mauritania",
            "searchName": "AEO-Aioun El Atrouss-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "SEY",
            "countryCode": "MR",
            "cityCode": "SEY",
            "cityName": "Selibaby",
            "name": "[SEY] - Selibaby - SEY - Selibaby - Mauritania",
            "searchName": "SEY-Selibaby-Selibaby Havalimani-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "THI",
            "countryCode": "MR",
            "cityCode": "THI",
            "cityName": "Tichitt",
            "name": "[THI] - Tichitt - THI - Tichitt - Mauritania",
            "searchName": "THI-Tichitt-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "THT",
            "countryCode": "MR",
            "cityCode": "THT",
            "cityName": "Tamchakett",
            "name": "[THT] - Tamchakett - THT - Tamchakett - Mauritania",
            "searchName": "THT-Tamchakett-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "TIY",
            "countryCode": "MR",
            "cityCode": "TIY",
            "cityName": "Tidjikja",
            "name": "[TIY] - Tidjikja - TIY - Tidjikja - Mauritania",
            "searchName": "TIY-Tidjikja-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "TMD",
            "countryCode": "MR",
            "cityCode": "TMD",
            "cityName": "Timbedra",
            "name": "[TMD] - Timbedra - TMD - Timbedra - Mauritania",
            "searchName": "TMD-Timbedra-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "OUZ",
            "countryCode": "MR",
            "cityCode": "OUZ",
            "cityName": "Zouerate",
            "name": "[OUZ] - Zouerate - OUZ - Zouerate - Mauritania",
            "searchName": "OUZ-Zouerate-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "OTL",
            "countryCode": "MR",
            "cityCode": "OTL",
            "cityName": "Boutilimit",
            "name": "[OTL] - Boutilimit - OTL - Boutilimit - Mauritania",
            "searchName": "OTL-Boutilimit-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "MBR",
            "countryCode": "MR",
            "cityCode": "MBR",
            "cityName": "Mbout",
            "name": "[MBR] - Mbout - MBR - Mbout - Mauritania",
            "searchName": "MBR-Mbout-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "MOM",
            "countryCode": "MR",
            "cityCode": "MOM",
            "cityName": "Moudjeria",
            "name": "[MOM] - Moudjeria - MOM - Moudjeria - Mauritania",
            "searchName": "MOM-Moudjeria-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "NKC",
            "countryCode": "MR",
            "cityCode": "NKC",
            "cityName": "Nouakchott",
            "name": "[NKC] - Nouakchott - NKC - Nouakchott - Mauritania",
            "searchName": "NKC-Nouakchott-Nuakchott-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "NDB",
            "countryCode": "MR",
            "cityCode": "NDB",
            "cityName": "Nouadhibou",
            "name": "[NDB] - Nouadhibou - NDB - Nouadhibou - Mauritania",
            "searchName": "NDB-Nouadhibou-Mauritanien-Mauritania-Moritanya-Mauritanie",
            "priority": 0
        },
        {
            "iataCode": "MNI",
            "countryCode": "MS",
            "cityCode": "MNI",
            "cityName": "Gerald s Park",
            "name": "[MNI] - Geralds Airport - MNI - Gerald s Park - Montserrat",
            "searchName": "MNI-Montserrat Bramble-Gerald s Airport-Bramble-Geralds Airport-Montserrat-Gerald s Park",
            "priority": 0
        },
        {
            "iataCode": "VAM",
            "countryCode": "MV",
            "cityCode": "VAM",
            "cityName": "Maamigili",
            "name": "[VAM] - Maamigili - VAM - Maamigili - Maldives",
            "searchName": "VAM-Maamigili-Maldives-Maldív-szigetek-Maldivler-Maledivy-Malediivit-Maldiverne-Maldive-Maldivi-Maldyvai-Maldivija-Maldiivid-Maldivene-Malediwy-Maladewa-Malediven-Maldivas",
            "priority": 0
        },
        {
            "iataCode": "HAQ",
            "countryCode": "MV",
            "cityCode": "HAQ",
            "cityName": "Hanimaadhoo",
            "name": "[HAQ] - Hanimaadhoo - HAQ - Hanimaadhoo - Maldives",
            "searchName": "HAQ-Hanimaadhoo-Maldives-Maldív-szigetek-Maldivler-Maledivy-Malediivit-Maldiverne-Maldive-Maldivi-Maldyvai-Maldivija-Maldiivid-Maldivene-Malediwy-Maladewa-Malediven-Maldivas",
            "priority": 0
        },
        {
            "iataCode": "GAN",
            "countryCode": "MV",
            "cityCode": "GAN",
            "cityName": "Gan Island",
            "name": "[GAN] - Gan Airport - GAN - Gan Island - Maldives",
            "searchName": "GAN-Gan/Seenu-Gan Airport-Gan Island--Maldives-Maldív-szigetek-Maldivler-Maledivy-Malediivit-Maldiverne-Maldive-Maldivi-Maldyvai-Maldivija-Maldiivid-Maldivene-Malediwy-Maladewa-Malediven-Maldivas",
            "priority": 0
        },
        {
            "iataCode": "FVM",
            "countryCode": "MV",
            "cityCode": "FVM",
            "cityName": "Fuvahmulak",
            "name": "[FVM] - Fuvahmulak - FVM - Fuvahmulak - Maldives",
            "searchName": "FVM-Fuvahmulak-Maldives-Maldív-szigetek-Maldivler-Maledivy-Malediivit-Maldiverne-Maldive-Maldivi-Maldyvai-Maldivija-Maldiivid-Maldivene-Malediwy-Maladewa-Malediven-Maldivas",
            "priority": 0
        },
        {
            "iataCode": "KDM",
            "countryCode": "MV",
            "cityCode": "KDM",
            "cityName": "Kaadedhdhoo",
            "name": "[KDM] - Kaadedhdhoo - KDM - Kaadedhdhoo - Maldives",
            "searchName": "KDM-Kaadedhdhoo-Maldives-Maldív-szigetek-Maldivler-Maledivy-Malediivit-Maldiverne-Maldive-Maldivi-Maldyvai-Maldivija-Maldiivid-Maldivene-Malediwy-Maladewa-Malediven-Maldivas",
            "priority": 0
        },
        {
            "iataCode": "KDO",
            "countryCode": "MV",
            "cityCode": "KDO",
            "cityName": "Kadhdhoo",
            "name": "[KDO] - Kadhdhoo - KDO - Kadhdhoo - Maldives",
            "searchName": "KDO-Kadhdhoo-Kadhonoo-Maldives-Maldív-szigetek-Maldivler-Maledivy-Malediivit-Maldiverne-Maldive-Maldivi-Maldyvai-Maldivija-Maldiivid-Maldivene-Malediwy-Maladewa-Malediven-Maldivas",
            "priority": 0
        },
        {
            "iataCode": "DRV",
            "countryCode": "MV",
            "cityCode": "DRV",
            "cityName": "Dharavandhoo Island",
            "name": "[DRV] - Dharavandhoo Island - DRV - Dharavandhoo Island - Maldives",
            "searchName": "DRV-Dharavandhoo Island-Maldives-Maldív-szigetek-Maldivler-Maledivy-Malediivit-Maldiverne-Maldive-Maldivi-Maldyvai-Maldivija-Maldiivid-Maldivene-Malediwy-Maladewa-Malediven-Maldivas",
            "priority": 0
        },
        {
            "iataCode": "DWA",
            "countryCode": "MW",
            "cityCode": "DWA",
            "cityName": "Dwangwa",
            "name": "[DWA] - Dwangwa - DWA - Dwangwa - Malawi",
            "searchName": "DWA-Dwangwa-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "CEH",
            "countryCode": "MW",
            "cityCode": "CEH",
            "cityName": "Chelinda",
            "name": "[CEH] - Chelinda - CEH - Chelinda - Malawi",
            "searchName": "CEH-Chelinda-Chelinda Havalimani-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "CMK",
            "countryCode": "MW",
            "cityCode": "CMK",
            "cityName": "Club Makokola",
            "name": "[CMK] - Club Makokola - CMK - Club Makokola - Malawi",
            "searchName": "CMK-Club Makokola-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "BLZ",
            "countryCode": "MW",
            "cityCode": "BLZ",
            "cityName": "Blantyre",
            "name": "[BLZ] - Chileka - BLZ - Blantyre - Malawi",
            "searchName": "BLZ-Blantyre-Chileka-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "KBQ",
            "countryCode": "MW",
            "cityCode": "KBQ",
            "cityName": "Kasungu",
            "name": "[KBQ] - Kasungu - KBQ - Kasungu - Malawi",
            "searchName": "KBQ-Kasungu-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "KGJ",
            "countryCode": "MW",
            "cityCode": "KGJ",
            "cityName": "Karonga",
            "name": "[KGJ] - Karonga - KGJ - Karonga - Malawi",
            "searchName": "KGJ-Karonga-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "LIX",
            "countryCode": "MW",
            "cityCode": "LIX",
            "cityName": "Likoma Island",
            "name": "[LIX] - Likoma - LIX - Likoma Island - Malawi",
            "searchName": "LIX-Likoma-Likoma Island-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "LMB",
            "countryCode": "MW",
            "cityCode": "LMB",
            "cityName": "Salima",
            "name": "[LMB] - Salima - LMB - Salima - Malawi",
            "searchName": "LMB-Salima-Salima Havalimani-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "LLW",
            "countryCode": "MW",
            "cityCode": "LLW",
            "cityName": "Lilongwe",
            "name": "[LLW] - Kumuzu International Airport - LLW - Lilongwe - Malawi",
            "searchName": "LLW-Lilongwe-Kumuzu International Airport-Lilongwe Intl-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "VUU",
            "countryCode": "MW",
            "cityCode": "VUU",
            "cityName": "Mvuu Camp",
            "name": "[VUU] - Mvuu Camp - VUU - Mvuu Camp - Malawi",
            "searchName": "VUU-Mvuu Camp-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "MAI",
            "countryCode": "MW",
            "cityCode": "MAI",
            "cityName": "Mangochi",
            "name": "[MAI] - Mangochi - MAI - Mangochi - Malawi",
            "searchName": "MAI-Mangochi-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "MYZ",
            "countryCode": "MW",
            "cityCode": "MYZ",
            "cityName": "Monkey Bay",
            "name": "[MYZ] - Monkey Bay - MYZ - Monkey Bay - Malawi",
            "searchName": "MYZ-Monkey Bay-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "ZZU",
            "countryCode": "MW",
            "cityCode": "ZZU",
            "cityName": "Mzuzu",
            "name": "[ZZU] - Mzuzu - ZZU - Mzuzu - Malawi",
            "searchName": "ZZU-Mzuzu-Malawi-Malaui",
            "priority": 0
        },
        {
            "iataCode": "ZCL",
            "countryCode": "MX",
            "cityCode": "ZCL",
            "cityName": "Zacatecas",
            "name": "[ZCL] - La Calera - ZCL - Zacatecas - Mexico",
            "searchName": "ZCL-Zacatecas-La Calera-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "ZIH",
            "countryCode": "MX",
            "cityCode": "ZIH",
            "cityName": "Ixtapa",
            "name": "[ZIH] - Internacional - ZIH - Ixtapa - Mexico",
            "searchName": "ZIH-Ixtapa Zihuatanejo-Internacional- Intl-Zihuatanejo-Ixtapa/Zihuatanejo-Ixtapa-Ixtapa\\/Zihuatanejo-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "ZMM",
            "countryCode": "MX",
            "cityCode": "ZMM",
            "cityName": "Zamora",
            "name": "[ZMM] - Zamora - ZMM - Zamora - Mexico",
            "searchName": "ZMM-Zamora-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "ZLO",
            "countryCode": "MX",
            "cityCode": "ZLO",
            "cityName": "Manzanillo",
            "name": "[ZLO] - Manzanillo - ZLO - Manzanillo - Mexico",
            "searchName": "ZLO-Manzanillo Mexiko-Manzanillo-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "MZT",
            "countryCode": "MX",
            "cityCode": "MZT",
            "cityName": "Mazatlan",
            "name": "[MZT] - Gen Rafael Buelna - MZT - Mazatlan - Mexico",
            "searchName": "MZT-Mazatlan-Gen Rafael Buelna-Mazatl-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "MXL",
            "countryCode": "MX",
            "cityCode": "MXL",
            "cityName": "Mexicali",
            "name": "[MXL] - Mexicali - MXL - Mexicali - Mexico",
            "searchName": "MXL-Mexicali-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "NCG",
            "countryCode": "MX",
            "cityCode": "NCG",
            "cityName": "Paloma Grande",
            "name": "[NCG] - Nueva Casas Grandes - NCG - Paloma Grande - Mexico",
            "searchName": "NCG-Nueva Casas Grandes-Nuevas Casas Grandes-Paloma Grande-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "NLD",
            "countryCode": "MX",
            "cityCode": "NLD",
            "cityName": "Nuevo Laredo",
            "name": "[NLD] - International Quetzalcoatl - NLD - Nuevo Laredo - Mexico",
            "searchName": "NLD-Nuevo Laredo-International Quetzalcoatl-Intl Quetzalcoatl-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "OAX",
            "countryCode": "MX",
            "cityCode": "OAX",
            "cityName": "Oaxaca",
            "name": "[OAX] - Xoxocotlan - OAX - Oaxaca - Mexico",
            "searchName": "OAX-Oaxaca-Xoxocotlan-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "NOG",
            "countryCode": "MX",
            "cityCode": "NOG",
            "cityName": "Nogales",
            "name": "[NOG] - Nogales - NOG - Nogales - Mexico",
            "searchName": "NOG-Nogales-Nogales Havalimani-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        },
        {
            "iataCode": "MAM",
            "countryCode": "MX",
            "cityCode": "MAM",
            "cityName": "Matamoros",
            "name": "[MAM] - Matamoros - MAM - Matamoros - Mexico",
            "searchName": "MAM-Matamoros-Servando Canales-Mexique-Meksiko-Mexiko-Mêhicô-Mexico-México-Messico-Meksyk-Mehhiko-Meksika-",
            "priority": 0
        }
    ]
}