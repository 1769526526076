import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { CodeInputComponent } from 'angular-code-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { PackageService } from 'src/app/core/services/package.service';
import { SessionService } from 'src/app/core/services/session.service';
import { DialogFinishComponent } from '../dialog-finish/dialog-finish.component';
import { DialogRegisterComponent } from '../dialog-register/dialog-register.component';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-dialog-valid-phone',
  templateUrl: './dialog-valid-phone.component.html',
  styleUrls: ['./dialog-valid-phone.component.css'],
  providers: [PackageService]
})


export class DialogValidPhoneComponent implements OnInit {

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    this.validarcodigo();
  }
  correoExist;
  validcode;
  codigo;
  numero;
  cunt;
  validcunt = false;
  validCodigo;
  constructor(private snackBar: MatSnackBar,private sessionService: SessionService,private spinner: NgxSpinnerService, private packageService: PackageService,public dialog: MatDialog
    , public dialogRef: MatDialogRef<DialogValidPhoneComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.numero = this.data.numero;
  }

  onCodeCompleted(code: string) {
    //console.log(code);
    
    this.codigo = crypto.SHA256(code).toString() ;
    if(this.data.data === this.codigo){
      this.validcode = true;

    } else {
      this.validcode = false;
    }
    
  }

  onCodeCompletedAgain() {
    if(this.data.data === this.codigo){
      this.validcode = true;

    } else {
      this.validcode = false;
    }
    
  }

  startCountDown(seconds) {
    this.cunt = 0;
    var counter = seconds;
    var interval = setInterval(() => {
      counter--;
      this.cunt = counter;
      if (counter === 0) {
        let adas = document.getElementById("again");
        adas.style.pointerEvents = "visible";
        this.validcunt = false;
      }

    }, 1000);
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 5000,
    });
  }

  sendAgain(){
    this.spinner.show();
    var codeIn = new CodeInputComponent();
    codeIn.reset();
    this.packageService.volverEnviar(this.numero,2).subscribe(
      x=>{
  
        if(x.status === 200){
          this.data.data = x.code;
          this.openSnackBar(x.message);
          let adas = document.getElementById("again");
          adas.style.pointerEvents = "none";
          
          this.startCountDown(60);
          this.validcunt = true;
          this.onCodeCompletedAgain();
        }
        this.spinner.hide();
      }
    )
  }

  isTrue(){
    this.spinner.show();
    this.packageService.LoginAccessByPhone(this.numero).subscribe(
      x=>{
        //console.log(x);
        if (x.status === 500) {
          this.openSnackBar(x.message);
        } else {
          this.dialogRef.close();
          const obj = {
            userId: x.odata.userID,
            email: x.odata.email,
            nombre: x.odata.name,
            apellido: x.odata.lastName,
            phone: x.odata.phone,
            genero: x.odata.gender,
            countryCode: x.odata.countryOrigin,
            documentNumber: x.odata.lpersonDocument[0].documentNumber,
            typeDocument: x.odata.lpersonDocument[0].documentID,
            birthDate: x.odata.birthDate
          }
          this.sessionService.setPartnerClub(x.odata.isPartnerClub);
          this.sessionService.setSelected(x.odata.oenterprise.id);
          this.sessionService.setEmailFinish(obj);
          this.openDialogExiste();
        }
        this.spinner.hide();
      }
    )
  }

  validarcodigo(){
    if(!this.validcode || this.validcode === undefined){
      return;
    }
    if(this.data.validPhone){
      this.isTrue();
    } else {
      this.openDialogRegister();
    }
   
  }

  onClose() {
    this.dialogRef.close();
  }

  openDialogRegister() {
    this.dialogRef.close();
    this.sessionService.setCorreo(null);
    const dialogRef = this.dialog.open(DialogRegisterComponent, {
      data: this.data.numero,
      panelClass: 'custom-dialog-container',
      maxWidth: 800,
    
    });

    dialogRef.afterClosed().subscribe(result => {
      /* //console.log('The dialog was closed'); */
      if (result) {
        this.correoExist = result;
      }
    });
  }

  

  openDialogExiste() {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(DialogFinishComponent, {
      data: '',
      panelClass: 'custom-dialog-container',
      maxWidth: 800,
    
    });

    dialogRef.afterClosed().subscribe(result => {
      /* //console.log('The dialog was closed'); */
      if (result) {
        this.correoExist = result;
      }
    });
  }

}
