import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { CouponComponent } from '../coupon.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AES, enc } from 'crypto-js';
import * as crypto from 'crypto-js';
import { CodeInputComponent } from 'angular-code-input';
import { PackageService } from 'src/app/core/services/package.service';
import { MatSnackBar } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-coupon-validate-phone',
  templateUrl: './coupon-validate-phone.component.html',
  styleUrls: ['./coupon-validate-phone.component.css'],
  providers: [PackageService, NgxSpinnerService]
})
export class CouponValidatePhoneComponent implements OnInit {
  numero;
  data;
  codigo;
  validcunt = false;
  cunt;
  validcode;
  rpta;
  constructor(private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private packageService: PackageService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.ver();
  }


  onCodeCompleted(code: string) {
    //console.log(code);

    this.codigo = crypto.SHA256(code).toString();
    if (this.data.code === this.codigo) {
      this.validcode = true;

    } else {
      this.validcode = false;
    }

  }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 5000,
    });
  }

  sendAgain() {
    this.spinner.show();
    var codeIn = new CodeInputComponent();
    codeIn.reset();
    this.numero = "51" + this.numero;
    this.packageService.volverEnviar(this.numero, 2).subscribe(
      x => {

        if (x.status === 200) {
          this.data = x.odata;
          this.openSnackBar(x.message);
          let adas = document.getElementById("again");
          adas.style.pointerEvents = "none";

          this.startCountDown(60);
          this.validcunt = true;
          this.onCodeCompletedAgain();
        }
        this.spinner.hide();
      }
    )
  }

  onCodeCompletedAgain() {
    if (this.data.code === this.codigo) {
      this.validcode = true;

    } else {
      this.validcode = false;
    }

  }

  startCountDown(seconds) {
    this.cunt = 0;
    var counter = seconds;
    var interval = setInterval(() => {
      counter--;
      this.cunt = counter;
      if (counter === 0) {
        let adas = document.getElementById("again");
        adas.style.pointerEvents = "visible";
        this.validcunt = false;
      }

    }, 1000);
  }

  continuar() {
    if (!this.validcode || this.validcode === undefined) {
      return;
    }
    const encryptionKey = 'domiruth';
    const objetoEncriptado = AES.encrypt(JSON.stringify(this.rpta), encryptionKey).toString();
    this.router.navigate(['auth/coupon-register'], { queryParams: { data: objetoEncriptado } });

  }


  ver() {
    this.route.queryParams.subscribe(params => {
      const serializedData = params['data'];
      if (serializedData) {
        let objetoDesencriptado = null;
        const bytes = AES.decrypt(serializedData, "domiruth");
        objetoDesencriptado = JSON.parse(bytes.toString(enc.Utf8));
        this.rpta = objetoDesencriptado;
        this.numero = objetoDesencriptado.number;
        this.data = objetoDesencriptado.data;
      }
    });
  }

}
