// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'http://52.142.32.21/vacation/api/',
  // baseUrl: 'http://52.190.24.114/vacation/api/',

  

  /* newBaseUrl: 'https://domiruth-uat.azure-api.net/flight/v1/',
  baseUrl: 'https://uat-api.goarrival.com/vacation/v1/',
  baseHotelUrl: 'https://uat-api.goarrival.com/hotel/v1/',
  urlSecurity: 'https://uat-api.goarrival.com/security/v1/',
  baseCharter: 'https://uat-api.goarrival.com/charter/v1/',
  urlIcons: 'https://vacationadminuatsa.blob.core.windows.net/icons/',
  urlIp: 'https://api.ipify.org/?format=json',
  subsKey: 'eb85131bc9d94c02840aa6961e7f77e9', 
  urlVisa: 'https://domiruthvisauat.azurewebsites.net/visa/',
  urlVisaSource: 'https://static-content-qas.vnforapps.com/v2/js/checkout.js?qa=true',
  urlFlight: 'https://uat-api.goarrival.com/flight/v1/',
  urlCustom : 'https://uat-api.goarrival.com/customer/v1/',
  urlNewVisa: 'https://uat-api.goarrival.com/visa/v1/',
  urlViewContent: 'https://uat-api.goarrival.com/viewcontent/v1/',
  urlInsurance: 'https://uat-api.goarrival.com/insurance/v1/' */

 
  baseUrl: "https://prd-api.goarrival.com/vacation/v1/",
  urlIcons: "https://vacationadminprdsa.blob.core.windows.net/icons/",
  urlIp: "https://api.ipify.org/?format=json",
  subsKey: "8c2cd760943f4058b07cd69a3fff4789",
  urlVisa: "https://domiruthvisa.azurewebsites.net/visa/",
  urlVisaSource: "https://static-content.vnforapps.com/v2/js/checkout.js",
  urlViewContent: 'https://prd-api.goarrival.com/viewcontent/v1/',
  baseHotelUrl: "https://prd-api.goarrival.com/hotel/v1/",
  baseCharter: "https://prd-api.goarrival.com/charter/",
  urlFlight: "https://prd-api.goarrival.com/flight/v1/",
  urlCustom: "https://prd-api.goarrival.com/customer/v1/",
  urlSecurity: "https://prd-api.goarrival.com/security/v1/",
  urlNewVisa: 'https://prd-api.goarrival.com/visa/v1/',
  urlInsurance: 'https://prd-api.goarrival.com/insurance/v1/'

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
