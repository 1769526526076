import { Injectable } from '@angular/core';
import { VisaToken } from 'src/app/shared/models/visa/visa-token.model';
import { JsonPipe } from '@angular/common';
import { FilterFlight } from 'src/app/flights/shared/filter-flight.model';
import { FilterFlightWrapper } from 'src/app/flights/shared/filter-flight-wrapper.model';
import { FlightBooking } from 'src/app/flights/flight-booking/shared/flight-booking.model';
import { FlightPreBooking } from 'src/app/flights/flight-booking/shared/flight-pre-booking.model';
import { FtTransaction } from 'src/app/flights/shared/models/flight-transaction/ft-transaction.model';
import { FilterSearch } from 'src/app/hotels/hotel-filter-search/shared/filter-search.model';
import { Reservation } from 'src/app/packages/shared/models/reservation.model';
import { TransactionResult } from 'src/app/packages/shared/models/transaction-result.model';
import { PackageFilter } from 'src/app/packages/shared/models/package-filter.model';
import { Recommendation } from 'src/app/flights/shared/models/recommendation.model';
import { HotelFilter } from 'src/app/hotels/shared/hotel-filter-model';
import { FlightBookingCharter } from 'src/app/hotels/hotel-flight-booking/shared/flight-bookingCharter.model';
import { FlightResponse } from 'src/app/flights/shared/models/flights/flight-response.model';

const PRE_BOOKING = 'preBooking';
const BOOKING = 'booking';
const PACKAGE_ID = 'packageId';
const NAME_URL = 'nameURL';
const PACKAGE_PRICE_ID = 'packagePriceId';
const CURRENCY_RATE = 'currencyRate';
const HOME_FILTER = 'filter';
const VISA_TOKEN = 'visaToken';
const PACK_SUMMARY = 'packageSummary';
const PURCHASE_NUMBER = 'purchase';
const RESULT_FLAG = 'resultFlag';
const FILTER_FLIGHT = 'filterFlight';
const SET_INSURANCE = 'insurance';
const FILTER_FLIGHT_WRAPPER = 'filterFlightWrapper';
const FILTER_VH = 'filterVH';
const VUELOS_HOTELS = 'vuelosHotels'
const FILTER_FLIGHT_WRAPPER_HOTEL = 'filterFlightWrapperHotel';

const FLIGHT_AVAILABILITY = 'flight-availability';
const FLIGHT_BOOKING = 'flight-booking';
const DATA_HOTEL_RESER = 'dh';
const FLIGHT_PRE_BOOKING = 'flight-pre-booking';
const FLIGHT_TRANSACTION = 'flight-transaction';

const VACATION_MODULE = 'vacationModule';
const RECOMENDATIOS = 'recomendations';
const ACCOUNT = 'ac-id';
const ORIGINS = 'origins';
const SEPARAR = 'separados';
const FILTER_HOTEL_WRAPPER = 'filterHotel';
const FLIGHT_HOTEL_DYN = 'flightHD';
const FLIGHTS = 'glithst';
const HOTEL_OFILTER = 'hotel-filter-model';
const FILTER_CHOOSE = 'filterChoose';
const FILTER_CHOOSEH = 'filterChooseH';
const SET_OFFLINE = 'setOffline';
const VALID_ROOM = 'validRoom';
const CARD = 'card';
const HOTEL_SELECT = 'hotel_select';
const PARTNER_CLUB = 'partner_club';
const SET_SELECTED = 'set_seelected';
const PRODUCTO_GOOGLE = 'productgoogle';
const INIT_SESTION = 'setsuobn';
const RELOAD = 'setreload';
const INIT_GOOGLE = 'setgoogle';
const CORREO = 'correo';
const PHONE = 'phone';
const URL = 'url';
const PASS_CARD = 'passengerCard';
const PASAJEROS_ASSIST = 'pasajerosAssist';
const SELECT_CARD = 'select-card';
const EMAIL_FINISH = 'email-finish';
const LLENARDATOS = 'writeDate';
const ASSIST_RESERVATION = 'reservation-assist';
const EDAD = 'edades';
const HOTEL_FLIGHT_RECOMMENDATIONS = 'hotel-flight-recommendations';
const HOTEL_FLIGHT_CHANGE = 'hotel-flight-change';
const FLIGHT_HOTEL = 'flight-hotel';
const FLIGHT_CAMBIADO = 'flight-cambiado';
const ONLY_HOTEL = 'only-hotel';
const INFO_HABI = 'info-habi';
const INFO_RESERVA = 'info-reserva';
const VALID_ONLY_HOTEL = 'valid_only_hotel';
const MODULE_NAME = 'module_name';
const SELECT_ROOM = 'select-room';
const PRICE_ROOM = 'price-room';
const FIRST_HABI = 'first-habi';
const PAGE_HOTEL_FLIGTH = 'page-hotel-fligth';
const CHANGE_FLIGTH_INITIAL = 'change-fligth-initial';
const CONTADOR = 'contador';
const FLIGHT_FINALLY = 'flight-finally';
const VALIDATION_ROOM = 'validation-room';
const VALIDATION_SESION = 'validation-sesion';
const PRICE_UPDATE = 'price-update';
const BOOKING_PRODUCT = 'booking-product';
const NEW_PRICE = 'new-price';
const HOTEL_AND_FLIGHT = 'hotel_and_flight';
const GASTOS_HOTEL = 'gastos_hotel';
const PNR_FLIGHT = 'pnr_flight';
const PAGO_EFECTIVO = 'pago_efectivo';
const HOTEL_RQ = 'hotel_rq';
const PRICE_END = 'price_end';
const TRIP_ADVISOR = 'TripAdvisor';
const VALID_FLIGHT = 'valid_flight';
const CHANGE_FLIGHT = 'change_flight';
const CHARTER_DETAIL = 'charter_detail';
const CHARTER_FINALLY = 'charter_finally';
const IS_CHARTER = 'is_charter';
const HEADER = 'header';
const CHARTER_DATA = 'charter_data';
const IMAGE = 'image';
const IMAGEHotel = 'image_hotel';
const FILE = 'files';
const PARTNER = 'partner';
const LOGIN_DATA = 'login_data';
const NUMBER = 'number';
const DISCOUNT_FLIGHT = 'discount_flight';
const SECCION = 'seccion';
const DISCOUNT_FLIGHT_HOTEL = 'discount_flight_hotel';
const USER_DATA = 'user_data';
const INFO_PRICE = 'info_price';
const CUOTEALO = 'cuotealo';
const VOUCHERS = 'vouchers';
const MEDAL_BOOKING = 'medal_booking';
const RQ_VIEW = 'view_content';
const VALID_MAPA = 'valid_mapa';
const PROMOTIONAL_CODE = 'promo_code';
const OFFER_ID = 'offert_id';

@Injectable({
    providedIn: 'root'
}
)
export class SessionService {
    constructor() {}

    clearSession() {
        sessionStorage.clear();
    }

    clearLocal() {
        localStorage.clear();
    }

    mandarfiles(files: any) {
        localStorage.setItem(FILE, files);
    }

    setPackageId(id: string) {
        sessionStorage.setItem(PACKAGE_ID, id);
    }

    getPackageId() {
        return sessionStorage.getItem(PACKAGE_ID);
    }

    setNameUrl(id: string) {
        sessionStorage.setItem(NAME_URL, id);
    }

    getNameUrl() {
        return sessionStorage.getItem(NAME_URL);
    }

    setValidMapa(id: any) {
        sessionStorage.setItem(VALID_MAPA, id);
    }

    getValidMapa() {
        return sessionStorage.getItem(VALID_MAPA);
    }

    setPackagePriceId(id: string) {
        sessionStorage.setItem(PACKAGE_PRICE_ID, id);
    }

    getPackagePriceId() {
        return sessionStorage.getItem(PACKAGE_PRICE_ID);
    }

    setBooking(reservation: Reservation) {
        sessionStorage.setItem(BOOKING, JSON.stringify(reservation));
    }

    getBooking() {
        return JSON.parse(sessionStorage.getItem(BOOKING));
    }

    clearBooking() {
        sessionStorage.removeItem(BOOKING);
    }

    setVisaToken(token: VisaToken) {
        sessionStorage.setItem(VISA_TOKEN, JSON.stringify(token));
    }

    getVisaToken() {
        return JSON.parse(sessionStorage.getItem(VISA_TOKEN));
    }

    clearVisaToken() {
        sessionStorage.removeItem(VISA_TOKEN);
    }

    setPreBooking(reservation: Reservation) {
        sessionStorage.setItem(PRE_BOOKING, JSON.stringify(reservation));
    }

    getPreBooking() {
        return JSON.parse(sessionStorage.getItem(PRE_BOOKING));
    }

    clearPreBooking() {
        sessionStorage.removeItem(PRE_BOOKING);
    }

    setCurrencyRate(currency: number) {
        sessionStorage.setItem(CURRENCY_RATE, currency.toString());
    }

    getCurrencyRate() {
        return parseFloat(sessionStorage.getItem(CURRENCY_RATE));
    }

    setHomeFilter(filter: PackageFilter) {
        sessionStorage.setItem(HOME_FILTER, JSON.stringify(filter));
    }

    getHomeFilter() {
        return JSON.parse(sessionStorage.getItem(HOME_FILTER));
    }

    setPackageSummary(summary: TransactionResult) {
        sessionStorage.setItem(PACK_SUMMARY, JSON.stringify(summary));
    }

    getPackageSummary() {
        return JSON.parse(sessionStorage.getItem(PACK_SUMMARY));
    }

    clearPackageSummary() {
        sessionStorage.removeItem(PACK_SUMMARY);
    }

    setPurchaseNumber(purchaseNumber: string) {
        sessionStorage.setItem(PURCHASE_NUMBER, purchaseNumber);
    }

    getPuchaseNumber() {
        return sessionStorage.getItem(PURCHASE_NUMBER);
    }

    clearPuchaseNumber() {
        sessionStorage.removeItem(PURCHASE_NUMBER);
    }

    setResultFlag(result: string) {
        sessionStorage.setItem(RESULT_FLAG, result);
    }

    getResultFlag() {
        return sessionStorage.getItem(RESULT_FLAG);
    }

    clearResultFlag() {
        sessionStorage.removeItem(RESULT_FLAG);
    }

    setFilterFlight(filter: FilterFlight) {
        sessionStorage.setItem(FILTER_FLIGHT, JSON.stringify(filter));
    }

    getFilterFlight() {
        return JSON.parse(sessionStorage.getItem(FILTER_FLIGHT));
    }

    setFilterFlightWrapper(filter: any) {
        localStorage.setItem(FILTER_FLIGHT_WRAPPER, JSON.stringify(filter));
    }

    getFilterFlightWrapper(): any {
        return JSON.parse(localStorage.getItem(FILTER_FLIGHT_WRAPPER));
    }

    setFilterVH(filter) {
        localStorage.setItem(FILTER_VH, JSON.stringify(filter));
    }

    getFilterVH() {
        return JSON.parse(localStorage.getItem(FILTER_VH));
    }

    setVuelosHotels(data) {
        localStorage.setItem(VUELOS_HOTELS, JSON.stringify(data));
    }

    getVuelosHotels() {
        return JSON.parse(localStorage.getItem(VUELOS_HOTELS));
    }

    setInsurance(filter: any) {
        sessionStorage.setItem(SET_INSURANCE, JSON.stringify(filter));
    }

    getInsurance(): any {
        return JSON.parse(sessionStorage.getItem(SET_INSURANCE));
    }

    

    setFlightAvailability(availability) {
        sessionStorage.setItem(FLIGHT_AVAILABILITY, JSON.stringify(availability));
    }

    getFlightAvailability() {
        return JSON.parse(sessionStorage.getItem(FLIGHT_AVAILABILITY));
    }

    setFlightBooking(flightBooking: FlightBooking) {
        sessionStorage.setItem(FLIGHT_BOOKING, JSON.stringify(flightBooking));
    }

    getFlightBooking(): FlightBooking {
        return JSON.parse(sessionStorage.getItem(FLIGHT_BOOKING));
    }

    setDataHotelReser(flightBooking: FlightBooking) {
        sessionStorage.setItem(DATA_HOTEL_RESER, JSON.stringify(flightBooking));
    }

    getDataHotelReser(): FlightBooking {
        return JSON.parse(sessionStorage.getItem(DATA_HOTEL_RESER));
    }

    setFlightBookingCharter(flightBooking: FlightBookingCharter) {
        sessionStorage.setItem(FLIGHT_BOOKING, JSON.stringify(flightBooking));
    }

    getFlightBookingCharter(): FlightBookingCharter {
        return JSON.parse(sessionStorage.getItem(FLIGHT_BOOKING));
    }

    setCharter(flightBooking) {
        sessionStorage.setItem(IS_CHARTER, JSON.stringify(flightBooking));
    }

    getCharter() {
        return JSON.parse(sessionStorage.getItem(IS_CHARTER));
    }

    setCharterData(flightBooking) {
        sessionStorage.setItem(CHARTER_DATA, JSON.stringify(flightBooking));
    }

    getCharterData() {
        return JSON.parse(sessionStorage.getItem(CHARTER_DATA));
    }

    setIndiceImage(flightBooking) {
        sessionStorage.setItem(IMAGE, JSON.stringify(flightBooking));
    }

    getIndiceImage() {
        return JSON.parse(sessionStorage.getItem(IMAGE));
    }

    setIndiceImageHotel(flightBooking) {
        sessionStorage.setItem(IMAGEHotel, JSON.stringify(flightBooking));
    }

    getIndiceImageHotel() {
        return JSON.parse(sessionStorage.getItem(IMAGEHotel));
    }

    setCharterFinally(charter) {
        sessionStorage.setItem(CHARTER_FINALLY, JSON.stringify(charter));
    }

    getCharterFinally() {
        return JSON.parse(sessionStorage.getItem(CHARTER_FINALLY));
    }

    clearFlightBooking() {
        sessionStorage.removeItem(FLIGHT_BOOKING);
    }

    setFlightPreBooking(flightPreBooking: FlightPreBooking) {
        sessionStorage.setItem(FLIGHT_PRE_BOOKING, JSON.stringify(flightPreBooking));
    }

    getFlightPreBooking(): FlightPreBooking {
        return JSON.parse(sessionStorage.getItem(FLIGHT_PRE_BOOKING));
    }

    clearFlightPreBooking() {
        sessionStorage.removeItem(FLIGHT_PRE_BOOKING);
    }

    setFlightTransaction(flightTransaction: FtTransaction) {
        sessionStorage.setItem(FLIGHT_TRANSACTION, JSON.stringify(flightTransaction));
    }

    getFlightTransaction(): FtTransaction {
        return JSON.parse(sessionStorage.getItem(FLIGHT_TRANSACTION));
    }

    clearFlightTransaction() {
        sessionStorage.removeItem(FLIGHT_TRANSACTION);
    }

    setModule(module: string) {
        sessionStorage.setItem(VACATION_MODULE, module);
    }

    getModule() {
        return sessionStorage.getItem(VACATION_MODULE);
    }

    setOffertId(offertid: string) {
        sessionStorage.setItem(OFFER_ID, offertid);
    }

    getOffertId() {
        return sessionStorage.getItem(OFFER_ID);
    }

    clearOffertId() {
        sessionStorage.removeItem(OFFER_ID);
    }

    setRecomendations(origins: FlightResponse) {
        sessionStorage.setItem(RECOMENDATIOS, JSON.stringify(origins));
    }

    getRecomendations(): FlightResponse {
        return JSON.parse(sessionStorage.getItem(RECOMENDATIOS));
    }

    setPromotionalCode(valor) {
        localStorage.setItem(PROMOTIONAL_CODE, valor);
    }

    getPromotionalCode() {
        return localStorage.getItem(PROMOTIONAL_CODE);
    }


    clearModule() {
        sessionStorage.removeItem(VACATION_MODULE);
    }

    setOrigins(origins) {
        localStorage.setItem(ORIGINS, JSON.stringify(origins));
    }

    getOrigins(): any[] {
        return JSON.parse(localStorage.getItem(ORIGINS));
    }

    setCombinados(origins: any[]) {
        sessionStorage.setItem(SEPARAR, JSON.stringify(origins));
    }

    getCombinados(): any[] {
        return JSON.parse(sessionStorage.getItem(SEPARAR));
    }

    setAccount(account: string) {
        localStorage.setItem(ACCOUNT, account);
    }

    getAccount() {
        return localStorage.getItem(ACCOUNT);
    }

    clearAccount() {
        localStorage.removeItem(ACCOUNT);
    }

    setHotelFilter(filter: any) {
        localStorage.setItem(FILTER_HOTEL_WRAPPER, JSON.stringify(filter));
    }

    getHotelFilter(): any {
        return JSON.parse(localStorage.getItem(FILTER_HOTEL_WRAPPER));
    }

    setHotelFlightDyn(filter: any) {
        localStorage.setItem(FLIGHT_HOTEL_DYN, filter);
    }

    getHotelFlightDyn(): any {
        return JSON.parse(localStorage.getItem(FLIGHT_HOTEL_DYN));
    }

    setFlights(filter: any) {
        localStorage.setItem(FLIGHTS, JSON.stringify(filter));
    }

    getFlights(): any {
        return JSON.parse(localStorage.getItem(FLIGHTS));
    }

    setHotelFlightRecommendations(recommendations: any) {
        sessionStorage.setItem(HOTEL_FLIGHT_RECOMMENDATIONS, JSON.stringify(recommendations));
    }

    setHotelFlightChange(recommendation: any) {
        sessionStorage.setItem(HOTEL_FLIGHT_CHANGE, JSON.stringify(recommendation));
    }

    setHotelOFilter(hotelOFilter: HotelFilter) {
        sessionStorage.setItem(HOTEL_OFILTER, JSON.stringify(hotelOFilter));
    }

    setFilterChoose(value: any) {
        sessionStorage.setItem(FILTER_CHOOSE, JSON.stringify(value));
    }

    getFilterChoose(): any {
        return JSON.parse(sessionStorage.getItem(FILTER_CHOOSE));
    }

    setFilterChooseH(value: any) {
        sessionStorage.setItem(FILTER_CHOOSEH, JSON.stringify(value));
    }

    getFilterChooseH(): any {
        return JSON.parse(sessionStorage.getItem(FILTER_CHOOSEH));
    }

    setOffline(value: any) {
        sessionStorage.setItem(SET_OFFLINE, JSON.stringify(value));
    }

    getOffline(): any {
        return JSON.parse(sessionStorage.getItem(SET_OFFLINE));
    }

    setValidRoom(value: any) {
        sessionStorage.setItem(VALID_ROOM, JSON.stringify(value));
    }

    getValidRoom(): any {
        return JSON.parse(sessionStorage.getItem(VALID_ROOM));
    }

    setCard(value: any) {
        sessionStorage.setItem(CARD, JSON.stringify(value));
    }

    getCard(): any {
        return JSON.parse(sessionStorage.getItem(CARD));
    }

    setHotelSelect(value: any) {
        sessionStorage.setItem(HOTEL_SELECT, JSON.stringify(value));
    }

    getHotelSelect(): any {
        return JSON.parse(sessionStorage.getItem(HOTEL_SELECT));
    }

    setPartnerClub(value: any) {
        localStorage.setItem(PARTNER_CLUB, JSON.stringify(value));
    }

    getPartnerClub(): any {
        return JSON.parse(localStorage.getItem(PARTNER_CLUB));
    }

    setSelected(value: any) {
        localStorage.setItem(SET_SELECTED, JSON.stringify(value));
    }

    getSelected(): any {
        return JSON.parse(localStorage.getItem(SET_SELECTED));
    }

    setEmailFinish(value: any) {
        localStorage.setItem(EMAIL_FINISH, JSON.stringify(value));
    }

    getEmailFinish(): any {
        return JSON.parse(localStorage.getItem(EMAIL_FINISH));
    }

    setIniciarSesion(value: any) {
        localStorage.setItem(INIT_SESTION, JSON.stringify(value));
    }

    getIniciarSesion(): any {
        return JSON.parse(localStorage.getItem(INIT_SESTION));
    }

    setReload(value: any) {
        sessionStorage.setItem(RELOAD, JSON.stringify(value));
    }

    getRelaod(): any {
        return JSON.parse(sessionStorage.getItem(RELOAD));
    }

    setIniciarSesionGoogle(value: any) {
        sessionStorage.setItem(INIT_GOOGLE, JSON.stringify(value));
    }

    getIniciarSesionGoogle(): any {
        return JSON.parse(sessionStorage.getItem(INIT_GOOGLE));
    }

    setCorreo(value: any) {
        sessionStorage.setItem(CARD, JSON.stringify(value));
    }

    getCorreo(): any {
        return JSON.parse(sessionStorage.getItem(CARD));
    }

    setProductGoogle(value: any) {
        sessionStorage.setItem(PRODUCTO_GOOGLE, JSON.stringify(value));
    }

    getProductGoogle(): any {
        return JSON.parse(sessionStorage.getItem(PRODUCTO_GOOGLE));
    }

    setPhone(value: any) {
        sessionStorage.setItem(PHONE, JSON.stringify(value));
    }

    getPhone(): any {
        return JSON.parse(sessionStorage.getItem(PHONE));
    }

    setURL(value: any) {
        sessionStorage.setItem(URL, JSON.stringify(value));
    }

    getURL(): any {
        return JSON.parse(sessionStorage.getItem(URL));
    }

    setSelectCard(value: any) {
        sessionStorage.setItem(SELECT_CARD, JSON.stringify(value));
    }

    getSelectCard(): any {
        return JSON.parse(sessionStorage.getItem(SELECT_CARD));
    }

    setPassengerCard(value: any[]) {
        sessionStorage.setItem(PASS_CARD, JSON.stringify(value));
    }

    getPassengerCard(): any {
        return JSON.parse(sessionStorage.getItem(PASS_CARD));
    }

    setPasajerosAssist(value: any[]) {
        sessionStorage.setItem(PASAJEROS_ASSIST, JSON.stringify(value));
    }

    getPasajerosAssist(): any {
        return JSON.parse(sessionStorage.getItem(PASAJEROS_ASSIST));
    }

    
    setLlenarDatos(value: any) {
        sessionStorage.setItem(LLENARDATOS, JSON.stringify(value));
    }

    getLlenarDatos(): any {
        return JSON.parse(sessionStorage.getItem(LLENARDATOS));
    }

    setAssistEnd(value: any) {
        sessionStorage.setItem(ASSIST_RESERVATION, JSON.stringify(value));
    }

    getAssistEnd(): any {
        return JSON.parse(sessionStorage.getItem(ASSIST_RESERVATION));
    }

    setEdades(value: any[]) {
        sessionStorage.setItem(EDAD, JSON.stringify(value));
    }

    getEdades(): any {
        return JSON.parse(sessionStorage.getItem(EDAD));
    }

    getHotelOFilter(): HotelFilter {
        return JSON.parse(sessionStorage.getItem(HOTEL_OFILTER));
    }

    getHotelFlightRecommendations(): any[] {
        return JSON.parse(sessionStorage.getItem(HOTEL_FLIGHT_RECOMMENDATIONS));
    }

    getHotelFlightChange(): any {
        return JSON.parse(sessionStorage.getItem(HOTEL_FLIGHT_CHANGE));
    }

    setFlightHotel(value: any) {
        sessionStorage.setItem(FLIGHT_HOTEL, JSON.stringify(value));
    }

    getFlightHotel(): any {
        return JSON.parse(sessionStorage.getItem(FLIGHT_HOTEL));
    }

    setFlightCambiado(value: any) {
        sessionStorage.setItem(FLIGHT_CAMBIADO, JSON.stringify(value));
    }

    getFlightCambiado(): any {
        return JSON.parse(sessionStorage.getItem(FLIGHT_CAMBIADO));
    }

    setOnlyHotel(value: any) {
        sessionStorage.setItem(ONLY_HOTEL, JSON.stringify(value));
    }

    getOnlyHotel(): any {
        return JSON.parse(sessionStorage.getItem(ONLY_HOTEL));
    }

    setInfoHabi(value: any) {
        sessionStorage.setItem(INFO_HABI, JSON.stringify(value));
    }

    getInfoHabi(): any {
        return JSON.parse(sessionStorage.getItem(INFO_HABI));
    }

    setInfoReserva(value: any) {
        sessionStorage.setItem(INFO_RESERVA, JSON.stringify(value));
    }

    getInfoReserva(): any {
        return JSON.parse(sessionStorage.getItem(INFO_RESERVA));
    }

    setValidOnlyHotel(value: any) {
        sessionStorage.setItem(VALID_ONLY_HOTEL, JSON.stringify(value));
    }

    getValidOnlyHotel(): any {
        return JSON.parse(sessionStorage.getItem(VALID_ONLY_HOTEL));
    }

    setModuleName(value: any) {
        sessionStorage.setItem(MODULE_NAME, JSON.stringify(value));
    }

    getModuleName(): any {
        return JSON.parse(sessionStorage.getItem(MODULE_NAME));
    }

    setSelectRoom(value: any) {
        sessionStorage.setItem(SELECT_ROOM, JSON.stringify(value));
    }

    getSelectRoom(): any {
        return JSON.parse(sessionStorage.getItem(SELECT_ROOM));
    }

    setPriceOnlyRoom(value: any) {
        sessionStorage.setItem(PRICE_ROOM, JSON.stringify(value));
    }

    getPriceOnlyRoom(): any {
        return JSON.parse(sessionStorage.getItem(PRICE_ROOM));
    }

    setFirstHabi(value: any) {
        sessionStorage.setItem(FIRST_HABI, JSON.stringify(value));
    }

    getFirstHabi(): any {
        return JSON.parse(sessionStorage.getItem(FIRST_HABI));
    }

    setPageHotelFligth(value: boolean) {
        sessionStorage.setItem(PAGE_HOTEL_FLIGTH, JSON.stringify(value));
    }

    getPageHotelFligth(): boolean {
        return JSON.parse(sessionStorage.getItem(PAGE_HOTEL_FLIGTH));
    }

    setChangePageInitial(value: boolean) {
        sessionStorage.setItem(CHANGE_FLIGTH_INITIAL, JSON.stringify(value));
    }

    getChangePageInitial(): boolean {
        return JSON.parse(sessionStorage.getItem(CHANGE_FLIGTH_INITIAL));
    }

    setContador(value: any) {
        sessionStorage.setItem(CONTADOR, JSON.stringify(value));
    }

    getContador(): any {
        return JSON.parse(sessionStorage.getItem(CONTADOR));
    }

    setFlightFinally(value: any) {
        sessionStorage.setItem(FLIGHT_FINALLY, JSON.stringify(value));
    }

    getFlightFinally(): any {
        return JSON.parse(sessionStorage.getItem(FLIGHT_FINALLY));
    }

    setValidationRoom(value: any) {
        sessionStorage.setItem(VALIDATION_ROOM, JSON.stringify(value));
    }

    getValidationRoom(): any {
        return JSON.parse(sessionStorage.getItem(VALIDATION_ROOM));
    }

    setValidationSesion(value: any) {
        sessionStorage.setItem(VALIDATION_SESION, JSON.stringify(value));
    }

    getValidationSesion(): any {
        return JSON.parse(sessionStorage.getItem(VALIDATION_SESION));
    }

    setPriceUpdated(value: any) {
        sessionStorage.setItem(PRICE_UPDATE, JSON.stringify(value));
    }

    getPriceUpdated(): any {
        return JSON.parse(sessionStorage.getItem(PRICE_UPDATE));
    }

    setBookingProduct(value: any) {
        localStorage.setItem(BOOKING_PRODUCT, JSON.stringify(value));
    }

    getBookingProduct(): any {
        return JSON.parse(localStorage.getItem(BOOKING_PRODUCT));
    }

    setObjNewPrice(value: any) {
        sessionStorage.setItem(NEW_PRICE, JSON.stringify(value));
    }

    getObjNewPrice(): any {
        return JSON.parse(sessionStorage.getItem(NEW_PRICE));
    }

    setHotelAndFlight(value: any) {
        sessionStorage.setItem(HOTEL_AND_FLIGHT, JSON.stringify(value));
    }

    getHotelAndFlight(): any {
        return JSON.parse(sessionStorage.getItem(HOTEL_AND_FLIGHT));
    }

    setGastosHotel(value: any) {
        sessionStorage.setItem(GASTOS_HOTEL, JSON.stringify(value));
    }

    getGastosHotel(): any {
        return JSON.parse(sessionStorage.getItem(GASTOS_HOTEL));
    }

    setPNRFlight(value: any) {
        sessionStorage.setItem(PNR_FLIGHT, JSON.stringify(value));
    }

    getPRNFlight(): any {
        return JSON.parse(sessionStorage.getItem(PNR_FLIGHT));
    }

    setPagoEfectivo(value: any) {
        sessionStorage.setItem(PAGO_EFECTIVO, JSON.stringify(value));
    }

    getPagoEfectivo(): any {
        return JSON.parse(sessionStorage.getItem(PAGO_EFECTIVO));
    }

    setHotelRQ(value: any) {
        sessionStorage.setItem(HOTEL_RQ, JSON.stringify(value));
    }

    getHotelRQ(): any {
        return JSON.parse(sessionStorage.getItem(HOTEL_RQ));
    }

    setPriceEnd(value: any) {
        sessionStorage.setItem(PRICE_END, JSON.stringify(value));
    }

    getPriceEnd(): any {
        return JSON.parse(sessionStorage.getItem(PRICE_END));
    }

    setTripAdvisor(value: any) {
        sessionStorage.setItem(TRIP_ADVISOR, JSON.stringify(value));
    }

    getTripAdvisor(): any {
        return JSON.parse(sessionStorage.getItem(TRIP_ADVISOR));
    }

    setValidFlight(value: any) {
        sessionStorage.setItem(VALID_FLIGHT, JSON.stringify(value));
    }

    getValidFlight(): any {
        return JSON.parse(sessionStorage.getItem(VALID_FLIGHT));
    }

    setChangeFlight(value: any) {
        sessionStorage.setItem(CHANGE_FLIGHT, JSON.stringify(value));
    }

    getChangeFlight(): any {
        return JSON.parse(sessionStorage.getItem(CHANGE_FLIGHT));
    }

    setHeader(value: any) {
        sessionStorage.setItem(HEADER, JSON.stringify(value));
    }

    getHeader(): any {
        return JSON.parse(sessionStorage.getItem(HEADER));
    }

    setCharterDetail(value: any) {
        sessionStorage.setItem(CHARTER_DETAIL, JSON.stringify(value));
    }

    getCharterDetail(): any {
        return JSON.parse(sessionStorage.getItem(CHARTER_DETAIL));
    }

    setPartner(value: any) {
        sessionStorage.setItem(PARTNER, JSON.stringify(value));
    }

    getPartner(): any {
        return JSON.parse(sessionStorage.getItem(PARTNER));
    }

    setLoginData(value: any) {
        sessionStorage.setItem(LOGIN_DATA, JSON.stringify(value));
    }

    getLoginData(): any {
        return JSON.parse(sessionStorage.getItem(LOGIN_DATA));
    }

    setNumber(value: any) {
        sessionStorage.setItem(NUMBER, JSON.stringify(value));
    }

    getNumber(): any {
        return JSON.parse(sessionStorage.getItem(NUMBER));
    }

    setDiscountFlight(value: any) {
        sessionStorage.setItem(DISCOUNT_FLIGHT, JSON.stringify(value));
    }

    getDiscountFlight(): any {
        return JSON.parse(sessionStorage.getItem(DISCOUNT_FLIGHT));
    }

    setSeccion(value: any) {
        sessionStorage.setItem(SECCION, JSON.stringify(value));
    }

    getSeccion(): any {
        return JSON.parse(sessionStorage.getItem(SECCION));
    }

    setDiscountFlightHotel(value: any) {
        sessionStorage.setItem(DISCOUNT_FLIGHT_HOTEL, JSON.stringify(value));
    }

    getDiscountFlightHotel(): any {
        return JSON.parse(sessionStorage.getItem(DISCOUNT_FLIGHT_HOTEL));
    }

    setUserData(value: any) {
        sessionStorage.setItem(USER_DATA, JSON.stringify(value));
    }

    getUserData(): any {
        return JSON.parse(sessionStorage.getItem(USER_DATA));
    }

    setInfoPrice(value: any) {
        sessionStorage.setItem(INFO_PRICE, JSON.stringify(value));
    }

    getInfoPrice(): any {
        return JSON.parse(sessionStorage.getItem(INFO_PRICE));
    }

    setCuotealo(value: any) {
        sessionStorage.setItem(CUOTEALO, JSON.stringify(value));
    }

    getCuotealo(): any {
        return JSON.parse(sessionStorage.getItem(CUOTEALO));
    }

    setVouchers(value: any) {
        sessionStorage.setItem(VOUCHERS, JSON.stringify(value));
    }

    getVouchers(): any {
        return JSON.parse(sessionStorage.getItem(VOUCHERS));
    }

    setMedalBooking(value: any) {
        sessionStorage.setItem(MEDAL_BOOKING, JSON.stringify(value));
    }

    getMedalBooking(): any {
        return JSON.parse(sessionStorage.getItem(MEDAL_BOOKING));
    }

    setRqView(value: any) {
        localStorage.setItem(RQ_VIEW, JSON.stringify(value));
    }

    getRqView(): any {
        return JSON.parse(localStorage.getItem(RQ_VIEW));
    }

    setFilterFlightWrapperHotel(value: any) {
        sessionStorage.setItem(FILTER_FLIGHT_WRAPPER_HOTEL, JSON.stringify(value));
    }

    getFilterFlightWrapperHotel(): any {
        return JSON.parse(sessionStorage.getItem(FILTER_FLIGHT_WRAPPER_HOTEL));
    }
}

