import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dialog-phone-contact',
  templateUrl: './dialog-phone-contact.component.html',
  styleUrls: ['./dialog-phone-contact.component.css']
})
export class DialogPhoneContactComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogPhoneContactComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    protected sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
  ) {
    // iconRegistry.addSvgIcon(
    //   'whatsapp',
    //   sanitizer.bypassSecurityTrustResourceUrl('../../assets/images/whatsapp.svg'));
   }

  ngOnInit() {
    window.scroll(0, 0);
  }

  onClose() {
    this.dialogRef.close();
  }

}
