import { Component, OnInit } from '@angular/core';
import { PackageService } from 'src/app/core/services/package.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../../core/services/login.service';
import * as crypto from 'crypto-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { DialogNotificationComponent } from 'src/app/shared/components/dialog-notification/dialog-notification.component';
import { DialogPartnerComponent } from 'src/app/shared/components/dialog-partner/dialog-partner.component';
import { DialogRedirectionComponent } from 'src/app/shared/components/dialog-redirection/dialog-redirection.component';
import { LoginAccessComponent } from '../login-access/login-access.component';
import { SessionService } from 'src/app/core/services/session.service';
import { DialogFinishComponent } from 'src/app/auth/dialog-social-login/dialog-finish/dialog-finish.component';

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.css'],
  providers: [PackageService, LoginService]
})
export class LoginRegisterComponent implements OnInit {


  documentType: any[] = [];
  bookingForm: FormGroup;
  hide = true;
  usuario = 'ADS23112SA';
  url;
  path;
  countryCodes: any[] = [];
  texto = 'Usted no esta permitido ingresar a esta pagina.';
  textoError = '';
  showTextError = false;
  constructor(private sessionService: SessionService,private formBuilder: FormBuilder, private packageService: PackageService, private router: Router,
              private loginService: LoginService,private spinner: NgxSpinnerService,public dialog: MatDialog) { }

  ngOnInit() {
    this.url = window.location.href;
    this.validateActivation();
  }

  validateActivation(){
    this.spinner.show();
    this.url = this.url.split('user=');
    this.url = this.url[1];
    this.loginService.validateActivation(this.url).subscribe(
      x => {
        if(x.ouserLogin != null){
          this.sessionService.setLoginData(x);
          if(x.ouserLogin.partnerClub){
            this.sessionService.setPartnerClub(true);
          } else {
            this.sessionService.setPartnerClub(false);
          }
          
          this.spinner.hide();
          this.openDialogExiste();
          
        }
        this.spinner.hide();
      }
    )
  }

  openDialogExiste() {
    const dialogRef = this.dialog.open(DialogFinishComponent, {
      data: '',
      panelClass: 'custom-dialog-container',
      maxWidth: 800,
    
    });

    dialogRef.afterClosed().subscribe(result => {
      /* //console.log('The dialog was closed'); */
      if (result) {
        
      }
    });
  }

  

  getUrl() {
    this.spinner.show();
    this.url = this.url.split('user=');
    this.url = this.url[1];
    this.loginService.GetUser(this.url).subscribe(
      x => {
        this.spinner.hide();
        if (x === null) {
          this.showDialogNotification(this.texto);
          return;
        }
        
      }
    )
  }

  getCountryCodes() {
    this.packageService.getCountryCodes().subscribe(
      result => {
        if (result.confirmation && result.data) {
          this.countryCodes = result.data.filter(x => x.id !== null && x.id !== '');
        }
      },
      err => {
        
      },
      () => {
        
      }
    );
  }

  showDialogNotification(message: string) {
    const dialogRef = this.dialog.open(DialogPartnerComponent, {
      disableClose: true,
      data: message
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  showDialogRedirection(message: any) {
    const dialogRef = this.dialog.open(DialogRedirectionComponent, {
      disableClose: true,
      data: message
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  createAccount(){
    if (this.bookingForm.invalid) {
      return;
    } else {
      this.showTextError = false;
      this.spinner.show();
      const password = this.bookingForm.controls.password.value;
      const data = {
        LoginUser: this.url,
        PersonalEmail: this.bookingForm.controls.Mail.value,
        PersonalPhone: this.bookingForm.controls.Phone.value,
        Password: crypto.SHA256(password).toString(),
        AppId: 3
      };
      this.loginService.activationPartner(data).subscribe(
        x => {
          if (x.oerror === null) {
            this.showDialogRedirection(data);
            this.showTextError = false;
            this.spinner.hide();
          } else {
            this.showTextError = true;
            this.textoError = x.oerror.message;
            this.spinner.hide();
          }
        }
      );
    }
  }

  back() {
    this.router.navigate(['login-initial']);
  }

  getDocumentType() {
    this.packageService.getDocumentType().subscribe(
      x => {
        if (x.confirmation && x.data) {
          this.documentType = x.data;
        }
      },
      err => {
       /*  console.log('Error: ' + err); */
      },
      () => {

      }
    );
  }

  initForm() {
    this.bookingForm = this.formBuilder.group({
      usuario: new FormControl({ value: this.url, disabled: true }),
      Mail: new FormControl('', [Validators.required, Validators.email]),
      codeCountry: new FormControl('', [Validators.required]),
      Phone: new FormControl('', [Validators.required, Validators.pattern("[0-9 ]*")]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)])
    });
  }

}
