import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FilterFlightWrapper } from '../../../flights/shared/filter-flight-wrapper.model';
import { Section } from 'src/app/flights/shared/models/flights/section.model';
import { Baggage } from 'src/app/flights/shared/models/flights/baggage.model';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dm-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css']
})
export class SectionComponent implements OnInit {

  @Input() section: any;
  @Input() index: number;
  @Input() type: string;
  @Input() recommendationId: number;
  baggageInfo: Baggage;
  TYPE_ONE_WAY = FilterFlightWrapper.TYPE_ONE_WAY;
  TYPE_ROUND_TRIP = FilterFlightWrapper.TYPE_ROUND_TRIP;
  TYPE_MULTI = FilterFlightWrapper.TYPE_MULTI;

  constructor() { }

  ngOnInit() {
    // this.section.segmentIndex = -1;
    if (this.section.lschedule && this.section.lschedule.length >= 0) {
      this.baggageInfo = this.section.lschedule[0].obaggage || {
        hasBagagge: false,
        quantity: 0,
        hasCarryOn: false,
        reference: "-1"
      };
      // this.section.segmentIndex = 0;
    }
  }

  selectSegment(segmentId: number, segmentIndex: number) {
    
    for (let index = 0; index < this.section.lschedule.length; index++) {
      const element = this.section.lschedule[index];
      if (index === segmentIndex) {
        element.selected = true;
      } else {
        element.selected = false;
      }
    }
  }
}

